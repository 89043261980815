import React, { useMemo } from 'react';
import { ListItem, ListItemText } from '@mui/material';
import Visible from './Visible';
import {
    renderChilds,
} from '../helper';

const ContentItem = ({
    children,
    onClick = () => {},
    fullWidth = false,
    style = {},
    isMobile = false,
}) => {
    const childProps = useMemo(() => {
        const widthOne = `${100/React.Children.count(children)}%`;
        return fullWidth ? { width: widthOne } : {};
    }, [children, fullWidth]);

    const {
        elements,
        actions,
    } = useMemo(() => {
        const items = Array.isArray(children) ? children : [children];

        return items.reduce((r, item) => {

            if (!item?.props?.isActions) {
                r.elements.push(item);
            } else {
                r.actions.push(item);
            }

            return r;
        }, {
            elements: [],
            actions: [],
        });
    }, [children]);

    // во всю ширину
    if (fullWidth) {
        return (
            <ListItem
                className="list_item"
                onClick={onClick}
                style={style}
            >
                <ListItemText>
                    {isMobile
                        ? (
                            <div className="row noMargin justify-content-between">
                                {renderChilds(elements, 'auto')}
                            </div>
                        )
                        : (
                            <>
                                <Visible show="desktop">
                                    <div className="row noMargin justify-content-between align-items-center">
                                        {renderChilds(elements, childProps)}
                                        {renderChilds(actions, childProps)}
                                    </div>
                                </Visible>
                                <Visible show="mobile">
                                    <div className="row noMargin justify-content-between">
                                        {renderChilds(elements, 'auto')}
                                    </div>
                                </Visible>
                            </>
                        )
                    }

                </ListItemText>
                <Visible show="mobile">
                    <div>
                        {renderChilds(actions, 'auto')}
                    </div>
                </Visible>
            </ListItem>
        );
    }

    return (
        <ListItem
            className="list_item"
            onClick={onClick}
            style={style}
        >
            <ListItemText>
                {isMobile
                    ? (
                        <div className="noMargin">
                            {renderChilds(elements, { isMobile })}
                        </div>
                    )
                    : (
                        <>
                            <Visible show="desktop">
                                <div className="row noMargin align-items-center">
                                    {renderChilds(elements)}
                                </div>
                            </Visible>
                            <Visible show="mobile">
                                <div className="row noMargin">
                                    {renderChilds(elements)}
                                </div>
                            </Visible>
                        </>
                    )
                }
            </ListItemText>
            <div>
                {renderChilds(actions, 'auto')}
            </div>
        </ListItem>
    );
};

export default ContentItem;