import React from 'react';
import {
    ListItemIcon,
    Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    root: {
        minWidth: '30px',
        fontSize: '18px',
    },
});

const Lock = ({ onClick, isLocked = false }) => {
    const classes = useStyles();

    const handleLockClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
    };

    return (
        <ListItemIcon onClick={handleLockClick} classes={{ root: classes.root }}>
            <Tooltip
                title={isLocked
                    ? 'Открепить'
                    : 'Закрепить'
                }
            >
                {isLocked
                    ? <i className="far fa-lock-alt" />
                    : <i className="far fa-lock-open-alt" />
                }
            </Tooltip>
        </ListItemIcon>
    );
};

export default Lock;