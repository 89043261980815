import React from 'react';
import styles from './mediaModal.module.scss';

function PrintInfo({ activeItem, renderLabels }) {

    return (
        <div className={styles.printInfo}>
            <div className={styles.img}>
                <img
                    src={activeItem?.src}
                    alt={activeItem?.violation}
                />
            </div>
            <div className={styles.footer}>
                {renderLabels}
            </div>
        </div>
    );
}

export default PrintInfo;