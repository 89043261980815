import { IconButton, ListItemText, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import DragAndDrop from '../../../../common/DragAndDrop';
import { makeStyles } from '@mui/styles';
import SelectDashboard from './SelectDashboard';
import titles from '../../../../../helpers/constants/titles';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDelete from '../../../../common/ConfirmModal';

const useStyles = makeStyles({
    item: {
        textAlign: 'left',
        marginLeft: '1rem',
        flex: 1,
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 13
    }
});

const SelectedDashboards = ({ selected, onChange, handleSave }) => {
    const styles = useStyles();

    const [openSelectModal, setOpenSelectModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const deleteDashboard = (id) => {
        const newData = selected.filter(item => item.id !== id);
        onChange(newData);
    };

    const listRender = (item, index) => (
        <>
            <ListItemText disableTypography className={styles.row}>
                <span>{index+1}</span>
                <span className={styles.item}>{item.name}</span>
            </ListItemText>
            <Tooltip title={titles.DELETE}>
                <IconButton size="small" onClick={() => setOpenDeleteModal(item.id)}>
                    <DeleteIcon fontSize={'inherit'}/>
                </IconButton>
            </Tooltip>
        </>
    );

    return (
        <>
            <h2>Список выбранных дашбордов :</h2>

            {selected?.length > 0 && (
                <DragAndDrop
                    list={selected}
                    onChange={onChange}
                    itemsRender={listRender}
                    withSelected
                />
            )}

            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    noPadding
                    positionLeft
                    noMarginLeft
                    buttons={[selected?.length > 0
                        ? {
                            ...buttonsTypes.change,
                            color: 'inherit',
                            onClick: () => setOpenSelectModal(true)
                        }
                        : {
                            ...buttonsTypes.select,
                            name: 'Выбрать дашборд',
                            color: 'inherit',
                            size: 'small',
                            onClick: () => setOpenSelectModal(true)
                        }
                    ]}
                />
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.save,
                            onClick: handleSave
                        }
                    ]}
                />
            </div>
            
            {openSelectModal
                && <SelectDashboard
                    isOpen={openSelectModal}
                    onClose={() => setOpenSelectModal(false)}
                    onChange={onChange}
                    selected={selected}
                />
            }
            {!!openDeleteModal
                && <ConfirmDelete
                    open={!!openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onSuccess={() => {
                        deleteDashboard(openDeleteModal);
                        setOpenDeleteModal(false);
                    }}
                />
            }
        </>
    );
};

export default SelectedDashboards;
