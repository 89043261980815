import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    frame_block: {
        padding: 10,
        position: 'absolute',
        zIndex: 1001,
        top: 60,
        right: 5,
        width: 300,
        maxHeight: '90%',
        backgroundColor: 'gray',
        overflowY: 'scroll',
        overflowX: 'hidden',
        color: '#fff',
    },
    frameItem: {
        width: 300,
        margin: 'auto',
        marginBottom: 15
    },
    frameButtons: {
        textAlign: 'right',
    },
    imgStyle: {
        width: 260,
        height: 150
    },
    imgHeader: {
        width: 200,
        display: 'flex',
        flexWrap: 'wrap'
    }
});

export default useStyles;