import * as types from './types';

/**
 * Actions
 **/

// остановки
// coords = [ [lon, lat], ... ]
export const loadOsrmCar = (coords, callback) => ({
    type: types.LOAD_OSRM_CAR,
    payload: {
        coords
    },
    callback,
});

export const loadingOsrmCar = (bool = false) => ({
    type: types.LOADING_OSRM_CAR,
    payload: bool,
});

// export const loadedOsrmCar = (data) => ({
//     type: types.LOADED_OSRM_CAR,
//     payload: data,
// });