/**
 * Sagas
 * **/
import { all, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import api from './api.methods';
import * as types from './types';
import { showMessage } from '../Message/actions';
import messageTypes from '../Message/messagetypes';
import * as actions from './actions';
import messages from '../../helpers/constants/messages';


// настройки
function* loadSettingsSaga({ payload }) {
    yield put(actions.loadingSettings(true));

    const response = yield call(api.getSettings, payload);
    if (response?.success) {
        yield put(actions.loadedSettings(response));
    }
    yield put(actions.loadingSettings(false));
}

function* editSettingsSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.editSettings, id, data);
    if (response?.success) {
        yield put(actions.loadSettings());
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
}

function* editSettingsListSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.editSettingsList, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }

    yield put(actions.loadingButton(false));
}

function* loadGroupGraphSaga({ payload }) {
    yield put(actions.loadingGroupGraph(true));

    const response = yield call(api.getGraphList, payload);
    if (response) {
        yield put(actions.loadedGroupGraph(response.data || []));
    }
    yield put(actions.loadingGroupGraph(false));
}

function* loadOneGraphSaga({ payload }) {
    yield put(actions.loadingOneGraph(true));

    const response = yield call(api.getOneGraph, payload);
    if (response?.success) {
        yield put(actions.loadedOneGraph(response));
    }
    yield put(actions.loadingOneGraph(false));
}

function* loadDashboardSaga({ payload }) {
    yield put(actions.loadingDashboard(true));

    const response = yield call(api.getDashboard, payload);
    if (response?.success) {
        yield put(actions.loadedDashboard(response?.data || []));
    }
    yield put(actions.loadingDashboard(false));
}

function* loadEfficiencyDtpSaga({ payload }) {
    yield put(actions.loadingEfficiencyDtp(true));

    const response = yield call(api.getEfficiencyDtp, payload);
    if (response?.success) {
        yield put(actions.loadedEfficiencyDtp(response?.data || []));
    }
    yield put(actions.loadingEfficiencyDtp(false));
}

function* loadGraphListSaga({ payload }) {
    yield put(actions.loadingGraphList(true));

    const response = yield call(api.getFullGraphList, payload);
    if (response?.success) {
        yield put(actions.loadedGraphList(response?.data || []));
    }
    yield put(actions.loadingGraphList(false));
}

function* loadGraphPeriodListSaga({ payload }) {
    yield put(actions.loadingGraphPeriodList(true));

    const response = yield call(api.getFullGraphPeriodList, payload);
    if (response?.success) {
        yield put(actions.loadedGraphPeriodList(response?.data || []));
    }
    yield put(actions.loadingGraphPeriodList(false));
}

function* loadEfficiencyTrafficSaga({ payload }) {
    yield put(actions.loadingEfficiencyTraffic(true));

    const response = yield call(api.getEfficiencyTraffic, payload);
    if (response?.success) {
        yield put(actions.loadedEfficiencyTraffic(response?.data || {}));
    }
    yield put(actions.loadingEfficiencyTraffic(false));
}

function* loadUserAvailableDashboardListSaga({ payload }) {
    yield put(actions.loadingUserAvailableDashboardList(true));

    const response = yield call(api.getUserAvailableDashboardList, payload);
    if (response?.success) {
        yield put(actions.loadedUserAvailableDashboardList(response?.data));
    }
    yield put(actions.loadingUserAvailableDashboardList(false));
}

function* loadUserDashboardSaga({ payload }) {
    yield put(actions.loadingUserDashboard(true));

    const response = yield call(api.getUserDashboard, payload);
    if (response?.success) {
        yield put(actions.loadedUserDashboard(response?.data));
    }
    yield put(actions.loadingUserDashboard(false));
}

function* editUserDashboardSaga({ payload, callback }) {
    const { data } = payload;
    const response = yield call(api.editUserDashboard, data);
    if (response?.success) {
        yield put(actions.loadUserDashboard());
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
}

function* loadTrafficForecastSaga({ payload }) {
    yield put(actions.loadingTrafficForecast(true));
    const response = yield call(api.getTrafficForecast, payload);
    if (response?.success) {
        yield put(actions.loadedTrafficForecast(response?.data));
    }
    yield put(actions.loadingTrafficForecast(false));
}

function* loadCriticalSettingsSaga({ payload }) {
    const { page, limit, data } = payload;

    yield put(actions.loadingCriticalSettings(true));
    const response = yield call(api.getCriticalSettings, page, limit, data);
    if (response?.success) {
        yield put(actions.loadedCriticalSettings(response));
    }
    yield put(actions.loadingCriticalSettings(false));
}

function* editCriticalSettingsSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.editCriticalSettings, payload);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
    }
    yield put(actions.loadingButton(false));
}

function* deleteCriticalSettingsSaga({ payload, callback }) {
    const { id } = payload;

    const response = yield call(api.deleteCriticalSettings, id );
    if (response?.success) {
        yield callback();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
}

function* createCriticalSettingsSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createCriticalSetting, payload);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
    yield put(actions.loadingButton(false));
}

function* loadCriticalSettingsKeysSaga() {
    const response = yield call(api.getCriticalSettingsKeys);
    if (response?.success) {
        yield put(actions.loadedCriticalSettingsKeys(response));
    }
}

function* loadReportEfficincyReportSaga({ payload }) {
    yield put(actions.loadingReportsEfficiencyDtpReport(true));

    const response = yield call(api.reportsEfficiencyDtpReport, payload);
    if (response?.success) {
        yield put(actions.loadedReportsEfficiencyDtpReport(response?.data));
    }

    yield put(actions.loadingReportsEfficiencyDtpReport(false));
}

function* loadShowcaseSaga() {
    yield put(actions.loadingShowcase(true));
    const response = yield call(api.loadShowcase);
    if (response?.success) {
        yield put(actions.loadedShowcase(response.data));
    }
    yield put(actions.loadingShowcase(false));
}

// settings group
function* loadSettingsGroupSaga() {
    yield put(actions.loadingSettingsGroup(true));
    const response = yield call(api.getSettingsGroup);
    if (response?.success) {
        yield put(actions.loadedSettingsGroup(response));
    }
    yield put(actions.loadingSettingsGroup(false));
}

function* editSettingsGroupSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.editSettingsGroup, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* loadItsDevelopmentLevelsSaga({ payload }) {
    yield put(actions.loadingItsDevelopmentLevels(true));
    const response = yield call(api.loadItsDevelopmentLevels, payload);
    if (response?.success) {
        yield put(actions.loadedItsDevelopmentLevels(response));
    }
    yield put(actions.loadingItsDevelopmentLevels(false));
}

function* editItsDevelopmentLevelsSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingItsDevelopmentLevels(true));
    const response = yield call(api.editItsDevelopmentLevels, id, data);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingItsDevelopmentLevels(false));
}

function* loadInfrastructureStatisticsSaga({ payload }) {
    yield put(actions.loadingInfrastructureStatistics(true));
    const response = yield call(api.loadInfrastructureStatistics, payload);
    if (response?.success) {
        yield put(actions.loadedInfrastructureStatistics(response));
    }
    yield put(actions.loadingInfrastructureStatistics(false));
}

function* editInfrastructureStatisticsSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingInfrastructureStatistics(true));
    const response = yield call(api.editInfrastructureStatistics, id, data);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingInfrastructureStatistics(false));
}

function* loadDashboardsSaga({ payload }) {
    yield put(actions.loadingDashboard(true));
    const response = yield call(api.getDashboards, payload);
    if (response?.success) {
        yield put(actions.loadedDashboards(response.data));
    }
    yield put(actions.loadingDashboard(false));
}

function* loadDashboardsItemSaga({ payload, callback }) {
    yield put(actions.loadingDashboard(true));
    const response = yield call(api.getDashboardsItem, payload);
    if (response?.success) {
        if (callback) {
            yield callback(response.data);
        } else {
            yield put(actions.loadedDashboardsItem(response));
        }
    }
    yield put(actions.loadingDashboard(false));
}

function* loadDashboardItemUserSaga({ payload, callback }) {
    yield put(actions.loadingDashboardItemUser(true));
    const response = yield call(api.getDashboardItemUser, payload);
    if (response?.success) {
        if (callback) {
            yield callback(response.data);
        } else {
            yield put(actions.loadedDashboardItemUser(response.data));
        }
    }
    yield put(actions.loadingDashboardItemUser(false));
}

function* createDashboardItemUserSaga({ payload, callback }) {
    yield put(actions.loadingDashboardItemUser(true));
    const response = yield call(api.createDashboardItemUser, payload);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingDashboardItemUser(false));
}

function* editDashboardItemUserSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingDashboardItemUser(true));
    const response = yield call(api.editDashboardItemUser, id, data);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingDashboardItemUser(false));
}

function* editDashboardItemUserMultiplySaga({ payload, callback }) {
    yield put(actions.loadingDashboardItemUser(true));
    const response = yield call(api.editDashboardItemUserMultiply, payload);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingDashboardItemUser(false));
}

function* deleteDashboardItemUserSaga({ payload, callback }) {
    yield put(actions.loadingDashboardItemUser(true));
    const response = yield call(api.deleteDashboardItemUser, payload);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, messages.DELETE_SUCCESS);
    }
    yield put(actions.loadingDashboardItemUser(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_SETTINGS, loadSettingsSaga),
        takeLatest(types.EDIT_SETTINGS, editSettingsSaga),
        takeLatest(types.EDIT_SETTINGS_LIST, editSettingsListSaga),
        takeLatest(types.LOAD_GROUP_GRAPH, loadGroupGraphSaga),
        takeLatest(types.LOAD_ONE_GRAPH, loadOneGraphSaga),
        takeLatest(types.LOAD_DASHBOARD, loadDashboardSaga),
        takeLatest(types.LOAD_EFFICIENCY_DTP, loadEfficiencyDtpSaga),
        takeLatest(types.LOAD_GRAPH_LIST, loadGraphListSaga),
        takeLatest(types.LOAD_GRAPH_PERIOD_LIST, loadGraphPeriodListSaga),

        takeLatest(types.LOAD_EFFICIENCY_TRAFFIC, loadEfficiencyTrafficSaga),

        takeLatest(types.LOAD_USER_AVAILABLE_DASHBOARD_LIST, loadUserAvailableDashboardListSaga),
        takeLatest(types.LOAD_USER_DASHBOARD, loadUserDashboardSaga),
        takeLatest(types.EDIT_USER_DASHBOARD, editUserDashboardSaga),

        takeLatest(types.LOAD_TRAFFIC_FORECAST, loadTrafficForecastSaga),

        takeLatest(types.LOAD_CRITICAL_SETTINGS, loadCriticalSettingsSaga),
        takeLatest(types.EDIT_CRITICAL_SETTING, editCriticalSettingsSaga),
        takeLatest(types.DELETE_CRITICAL_SETTING, deleteCriticalSettingsSaga),

        takeLatest(types.CREATE_CRITICAL_SETTING, createCriticalSettingsSaga),

        takeLatest(types.LOAD_CRITICAL_SETTINGS_KEYS, loadCriticalSettingsKeysSaga),

        takeLatest(types.LOAD_REPORT_EFFICIENCY_DTP_REPORT, loadReportEfficincyReportSaga),

        takeLatest(types.LOAD_SHOWCASE, loadShowcaseSaga),

        takeLatest(types.LOAD_SETTINGS_GROUP, loadSettingsGroupSaga),
        takeLatest(types.EDIT_SETTINGS_GROUP, editSettingsGroupSaga),

        takeLatest(types.LOAD_ITS_DEVELOPMENT_LEVELS, loadItsDevelopmentLevelsSaga),
        takeLatest(types.EDIT_ITS_DEVELOPMENT_LEVELS, editItsDevelopmentLevelsSaga),

        takeLatest(types.LOAD_INFRASTRUCTURE_STATISTICS, loadInfrastructureStatisticsSaga),
        takeLatest(types.EDIT_INFRASTRUCTURE_STATISTICS, editInfrastructureStatisticsSaga),

        takeLatest(types.LOAD_DASHBOARDS, loadDashboardsSaga),
        takeLatest(types.LOAD_DASHBOARDS_ITEM, loadDashboardsItemSaga),

        takeLatest(types.LOAD_DASHBOARD_ITEM_USER, loadDashboardItemUserSaga),
        takeLatest(types.CREATE_DASHBOARD_ITEM_USER, createDashboardItemUserSaga),
        takeLatest(types.EDIT_DASHBOARD_ITEM_USER, editDashboardItemUserSaga),
        takeLatest(types.EDIT_DASHBOARD_ITEM_USER_MULTIPLY, editDashboardItemUserMultiplySaga),
        takeLatest(types.DELETE_DASHBOARD_ITEM_USER, deleteDashboardItemUserSaga),
    ]);
}
