import { memo, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import styles from '../../messages.module.scss';
import { Delete } from '@mui/icons-material';
import buttons from '../../../../../../../helpers/constants/buttons';
import CommonImage from 'components/common/Image';

export const UploadImage = memo((props) => {
    const { base64, board, template, onChange, validation } = props;
    const supportedImageType = 'image/png';
    const [error, setError] = useState(null);

    const handleChangeImage = (e) => {
        const { files } = e.target;
        const [ file ] = files;

        if (file?.type === supportedImageType) {
            const reader = new FileReader();
            const blobFile = new Blob(files, { type: supportedImageType });
            const img = new Image();

            img.onload = function(){
                // if (img.width <= board.width && img.height <= board.height) {
                onChange(img.src);
                // setError(null);
                //     validation.deleteKey('base64');
                // } else {
                //     onChange('');
                //     setError('Изображения должно быть размером не более 384x96');
                // }
            };

            reader.onloadend = () => {
                img.src = reader.result;
            };

            reader.readAsDataURL(blobFile);
        }
    };

    const handleDelete = () => {
        // нужно очистить загруженный файл из инпута, т.к. иначе инпут будет считать что в нем ничего не изменилось
        const fileInput = document.getElementById('upload-image');
        fileInput.value = [];
        onChange(null);
    };

    return (
        <div>
            <label htmlFor="upload-image" className={styles.upload_label}>Загрузить изображение</label>
            {/* {!template && <div>(доступно для загрузки изображения размера {board?.width}x{board?.height})</div>} */}
            <input
                onChange={handleChangeImage}
                type="file"
                id="upload-image"
                className={styles.upload_image}
                accept={supportedImageType}
            />
            {base64 && (
                <div className="row">
                    <CommonImage
                        style={{ width: '80%', height: 'auto' }}
                        src={base64}
                        alt="preview"
                    />
                    <IconButton onClick={handleDelete}>
                        <Tooltip title={buttons.DELETE}>
                            <Delete />
                        </Tooltip>
                    </IconButton>
                </div>
            )}
            {/* <FormHelperText error>{validation.get('base64') || error} {validation.get('boards.0')}</FormHelperText> */}
        </div>
    );
});