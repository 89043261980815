import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadCompanies } from 'redux/Companies/actions';
import titles from 'helpers/constants/titles';
import PassportTabs from 'components/common/PassportTabs';

import Workorders from '../../WorkOrders';

import OrderStage from './OrderStage/OrderStage';
import BasicData from './BasicData/index';
import WorkByStages from './WorkByStages';

const PassportPage = ({ currentOrder, reloadList = () => {}, tab, vehicle = null }) => {
    const dispatch = useDispatch();
    const [order, setOrder] = useState(currentOrder);

    useEffect(() => {
        // если поменялась заявка в списке
        if (currentOrder) {
            setOrder(currentOrder);
        }

    }, [currentOrder]);

    useEffect(() => {
        if (currentOrder && currentOrder.organization_ids?.length > 0) {
            // проверяем, если id есть в заявке, но объекта этой компании нет
            const id_list = currentOrder.organization_ids?.reduce((res, id) => {
                if (currentOrder.organizations?.findIndex(el => el?.id === id) < 0) { 
                    res.push(id);
                }
                return res;
            }, []);

            if (id_list.length > 0) {
                dispatch(loadCompanies(
                    1, 
                    id_list.length,
                    { id_list, withDeleted: 1 }, 
                    (organizations = []) => {
                        setOrder((prev) => ({
                            ...prev,
                            organizations: [
                                ...currentOrder.organizations.filter(el => el), // на случай если у нас [null, null]
                                ...organizations,
                            ],
                        }));
                    }
                ));
            }
        }
    }, [currentOrder, dispatch]);

    return (
        <PassportTabs 
            tabs={[
                {
                    value: 'data',
                    label: titles.BASIC_DATA,
                    icon: <i className="fal fa-info-square"/>,
                    contentComponent: <BasicData currentOrder={order} reloadList={reloadList} key={order.id} />,
                },
                {
                    value: 'executionStages',
                    label: 'Этапы выполнения',
                    icon: <i className="fal fa-info-square"/>,
                    contentComponent: <OrderStage currentOrder={order} vehicle={vehicle} />,
                },
                {
                    value: 'workByStages',
                    label: 'Работы по этапам', 
                    icon: <i className="fal fa-info-square"/>,
                    contentComponent: <WorkByStages  currentOrder={order} />,
                },
                {
                    value: 'workorders',
                    label: 'Наряды',
                    icon: <i className="fal fa-info-square"/>,
                    contentComponent: <Workorders currentOrder={order} tab="workorder" />,
                },
            ]}
            initialTab={tab}
            appBarStyle={{ zIndex: 2 }}
        />
    );
};

export default PassportPage;