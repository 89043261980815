import React, { useContext, useEffect, useState } from 'react';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import CustomPagination from '../../../../common/CustomPagination';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';
import messages from '../../../../../helpers/constants/messages';
import { loadEvents } from '../../../../../redux/SituationalPlans/actions';
import ModalForm from './ModalForm';
import Item from './Item';
import Filter from './Filter';
import Loading from '../../../../common/Loading';
import Context from '../../../../../helpers/context';
import { SituationalPlansSelectors } from '../../../../../redux/SituationalPlans';

const Events = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const data = useSelector(SituationalPlansSelectors.eventsData);
    const meta = useSelector(SituationalPlansSelectors.eventsMeta);
    const loadingEvents = useSelector(SituationalPlansSelectors.loadingEvents);

    const [openModal, setOpenModal] = useState(false);
    const [params, setParams] = useState('');
    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);

    useEffect(() => {
        dispatch(loadEvents(1, limit));
    },[]);

    return (
        <div>
            <h1>Мероприятия</h1>
            <Filter
                loadParams={(value) => setParams(value)}/>
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenModal(true),
                            disabled: !permissions?.is_create,
                        }
                    ]}
                />
            </div>
            {loadingEvents && <Loading circular={true}/>}
            {openModal
                && <ModalForm
                    isOpen={openModal}
                    onClose={setOpenModal}
                    isNew={true}
                />
            }
            {data?.length > 0
                ? (<>
                    <List className="list">
                        {data
                            ?.map((item) => <Item
                                key={item.id + item.name}
                                item={item}
                            />)
                        }
                    </List>
                    <CustomPagination
                        loadList={(page, limit) => dispatch(loadEvents(page, limit, params ))}
                        list={meta}
                        limit={limit} 
                        setLimit={setLimit}
                    />
                </>)
                : (!loadingEvents && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </div>
    );
};

export default Events;
