import React from 'react';
import { editComplex } from '../../../../../../../redux/DorisControl/actions';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../vfcomplex.module.scss';
import Item from './Item';
// import messages from '../../../../../../../helpers/constants/messages';
import Loading from '../../../../../../common/Loading';
import usePDDViolations from '../../../../../../../helpers/hooks/usePddViolations';
import removeEmptyFields from '../../../../../../../helpers/removeEmptyFields';
import { dorisControlSelectors } from '../../../../../../../redux/DorisControl';

const Uploading = (props) => {
    const dispatch = useDispatch();
    const {
        complex_id,
        readOnly = false,
        onChange, // произошло изменение
        itemComplex
    } = props;

    const data = useSelector(dorisControlSelectors.complexesData);
    const loading_complexes = useSelector(dorisControlSelectors.loading_complexes);

    const currentComplex = itemComplex || data.find(el => el.id === complex_id);

    const violations = usePDDViolations(currentComplex);

    const handleChange = (_comment, status) => {
        const prepareData = removeEmptyFields({
            ...currentComplex,
            _comment,
            pdd_violations: {
                ...currentComplex.pdd_violations,
                ...status,
            }
        }, false);

        dispatch(editComplex(complex_id, prepareData, () => onChange && onChange()));
    };

    return (
        <div className={styles.container}>
            {loading_complexes && <Loading circular={true}/>}
            <>
                {violations.length > 0
                    ? (
                        <div className="form">
                            {violations.map((pdd) => {
                                return (
                                    <Item
                                        key={pdd.id}
                                        checked={currentComplex.pdd_violations[pdd.id]}
                                        onChange={(comment, status) => handleChange(comment, { [pdd.id]: status })}
                                        pdd={pdd}
                                        readOnly={readOnly}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <> Не настроено оборудование или не настроен перечень статей КОАП</>
                    )}
            </>
        </div>
    );
};

export default Uploading;
