// иконка для карты
const iconMap = ({ fill = '#000', innerFill = '#fff' }) => {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 107.000000 130.000000">
            <g>
                <rect fill="none" id="canvas_background" height="132" width="109" y="-1" x="-1"/>
            </g>
            <g>
                <g id="svg_battery_1" transform="translate(0,130) scale(0.10000000149011612,-0.10000000149011612) " fill="${fill}">
                    <path id="svg_battery_2" d="m445,1286c-100,-19 -206,-75 -281,-150c-104,-104 -154,-228 -154,-381c0,-144 38,-245 149,-392c64,-85 297,-316 348,-345c30,-17 31,-17 71,12c68,49 237,216 302,299c157,200 210,350 181,517c-25,142 -121,287 -241,364c-108,70 -251,98 -375,76z"/>
                </g>
                <g fill="${innerFill}"stroke="null" id="svg_battery_7">
                    <path stroke="null" fill="#fff" id="svg_battery_6" d="m82.276894,43.559856l0,7.793401l3.896701,0l0,7.793401l-3.896701,0l0,7.793401l-58.450508,0l0,-23.380203l58.450508,0m1.94835,-7.793401l-62.347208,0c-3.228173,0 -5.845051,2.616878 -5.845051,5.845051l0,27.276904c0,3.228173 2.616878,5.845051 5.845051,5.845051l62.347208,0c3.228173,0 5.845051,-2.616878 5.845051,-5.845051l0,-1.94835l0.974175,0c1.614086,0 2.922525,-1.308439 2.922525,-2.922525l0,-17.535152c0,-1.614086 -1.308439,-2.922525 -2.922525,-2.922525l-0.974175,0l0,-1.94835c0,-3.228173 -2.616878,-5.845051 -5.845051,-5.845051zm-5.845051,11.690102l-50.657107,0l0,15.586802l50.657107,0l0,-15.586802z"/>
                </g>
            </g>
        </svg>
    `;
};

export default iconMap;