import { useState } from 'react';
import { useDispatch } from 'react-redux';

import ConfirmDelete from 'components/common/ConfirmModal';
import {
    ActionMore,
    ActionsButtons,
    LSContentColumn,
    LSContentItem
} from 'components/common/List';
import messages from 'helpers/constants/messages';
import { deleteCompany } from 'redux/Companies/actions';
import { buttonsTypes } from 'components/common/FormButtons';

import StandardizedCompanyForm from './StandardizedCompanyForm';
import Info from './info';
import Documents from './Documents';
import Card from './Card';
import RolesContractsModal from './RolesContracts';
import { Tooltip } from '@mui/material';

const Item = ({ item, reloadList }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isOpenDocumentsList, setIsOpenDocumentsList] = useState(false);
    const [isOpenCard, setIsOpenCard] = useState(false);
    const [isOpenRolesContracts, setIsOpenRolesContracts] = useState(false);

    const toggleOpen = (e) => {
        e && e.stopPropagation();
        setModalOpen(!modalOpen);
    };

    const toggleDeleteOpen = (e) => {
        e && e.stopPropagation();
        setDeleteModalOpen(!deleteModalOpen);
    };

    const itemClickHandle = () => {
        setOpen(!open);
    };

    const handleDelete = () => {
        dispatch(deleteCompany(item.id, () => {
            reloadList(true);
            toggleDeleteOpen();
        }));
    };

    const openDocumentsList = (e) => {
        e && e.stopPropagation();
        setIsOpenDocumentsList(true);
    };

    const closeDocumentsList = (e) => {
        e && e.stopPropagation();
        setIsOpenDocumentsList(false);
    };

    const openRolesContracts = (e) => {
        e && e.stopPropagation();
        setIsOpenRolesContracts(true);
    };

    return (
        <>
            {modalOpen
                && <StandardizedCompanyForm
                    setOpenModal={setModalOpen}
                    open={modalOpen}
                    item={item}
                    reloadList={reloadList}
                />
            }

            {isOpenCard
                && <Card
                    item={item}
                    isOpen={isOpenCard}
                    onClose={() => setIsOpenCard(false)}
                />
            }

            <LSContentItem style={{ cursor: 'pointer' }} onClick={itemClickHandle}>
                <LSContentColumn>
                    {item?.attachments?.length > 0
                     && <Tooltip title=" Есть документы на перевозку">
                         <i className="fas fa-bus"/>
                     </Tooltip>
                    }
                </LSContentColumn>

                <LSContentColumn>{item.full_name || messages.NO_DATA}</LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            ...(item?.attachments?.length > 0
                                ? [{
                                    icon: <i className="fab fa-wpforms"/>,
                                    name: 'Учетная карточка организации',
                                    size: 'small',
                                    onClick: (e) => {e.stopPropagation(); setIsOpenCard(true);},
                                }]
                                :[]
                            ),
                            ...(!item.deleted_at
                                ? [
                                    {
                                        icon: <i className="fas fa-file-user"/>,
                                        name: 'Роли и договора',
                                        size: 'small',
                                        onClick: openRolesContracts,
                                        accessProp: 'is_update',
                                    },
                                    {
                                        icon: <i className="fas fa-file-alt"/>,
                                        name: 'Документы на перевозку',
                                        size: 'small',
                                        onClick: openDocumentsList,
                                        accessProp: 'is_update',
                                    },
                                    {
                                        ...buttonsTypes.editIcon,
                                        onClick: toggleOpen,
                                    },
                                    {
                                        ...buttonsTypes.deleteIcon,
                                        onClick: toggleDeleteOpen,
                                    },
                                ]
                                :[]
                            ),

                        ]}
                    />

                    <ActionMore isOpen={open} />
                </LSContentColumn>
            </LSContentItem>

            {open && <Info isOpen={open} item={item} />}
            {deleteModalOpen && (
                <ConfirmDelete
                    open={deleteModalOpen}
                    message={messages.CONFIRM_DELETE}
                    onClose={toggleDeleteOpen}
                    onSuccess={handleDelete}
                />
            )}
            {isOpenDocumentsList && (
                <Documents
                    isOpen={isOpenDocumentsList}
                    onClose={closeDocumentsList}
                    item={item}
                />
            )}
            {isOpenRolesContracts && (
                <RolesContractsModal
                    isOpen={isOpenRolesContracts}
                    onClose={() => setIsOpenRolesContracts(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default Item;
