import Modal from '../Modal';
import FormButtons, { buttonsTypes } from '../FormButtons';
import InformPanel from 'components/layout/PageLayout/InformPanel';
import titles from 'helpers/constants/titles';

const LinkModal = (
    {
        name,
        isOpen,
        onClose,
        linkObject,
        onSave,
        onChange,
        disabled = false,
        children,
        changeBtnDisable = false,
        topComponent = null
    }) => {
    

    return (
        <Modal
            title={'Назначение связей объектов'}
            onClose={onClose}
            noPadding
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    // {
                    //     ...buttonsTypes.addOrDeleteLinks,
                    //     onClick: openBoardsList,
                    //     disabled: isLoading
                    // },
                    {
                        ...buttonsTypes.save,
                        onClick: onSave,
                        disabled
                    },
                ]}
            />}
        >
            {name 
                && <div style={{ paddingLeft: '1.2rem' }}>
                    <strong>{titles.NAME}:</strong> {name}
                </div>
            }
            {topComponent && topComponent}
            <InformPanel
                buttons={
                    <FormButtons
                        noPadding
                        noMarginLeft
                        buttons={[
                            {
                                ...buttonsTypes.change,
                                color: 'infoButton',
                                onClick: onChange,
                                disabled: changeBtnDisable
                            },
                        ]}
                    />
                }
            />
            {linkObject && <h2 style={{ padding: '0 0 0 1.2rem' }}>{linkObject}:</h2>}
            {children}
        </Modal>
    );
};

export default LinkModal;