import L from 'leaflet';
import circleColorIcon from './circleColorIcon';

const createIcon = (count = 1, colors = { '#000': 100 }) => {
    // размер иконки
    const iconSize = count < 100 ? 40 : count < 1000 ? 60 : 80;

    return new L.DivIcon({
        html: `
            ${circleColorIcon(colors)}
            <div class="count">
                <span>${count}</span>
            </div>
        `,
        className: 'marker-cluster',
        iconSize: new L.Point(iconSize, iconSize)
    });
};

export default createIcon;
