import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { useSelector, useDispatch } from 'react-redux';
import { showGSAndCSByTL } from '../../../../../redux/TrafficLights/actions';
import makeStyles from '@mui/styles/makeStyles';
import { trafficLightsSelectors } from '../../../../../redux/TrafficLights';

const useStyles = makeStyles({
    root: {
        '& .MuiFormControlLabel-label': {
            fontSize: '.9rem !important'
        }
    }
});

// галочка Показать ГК и ЗУ для выбранных светофоров
const CheckBox = () => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const showGSAndCG = useSelector(trafficLightsSelectors.showGSAndCG);

    const handleChecked = () => {
        dispatch(showGSAndCSByTL(!showGSAndCG));
    };

    return (
        <div
            style={{
                margin: '1rem'
            }}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        checked={showGSAndCG}
                        onClick={handleChecked}
                        size="small"
                    />
                }
                label="Показать ГК и ЗУ для выбранных светофоров"
                className={classes.root}
            />
        </div>
    );
};

export default CheckBox;