export const parking = (state) => state.parking;

export const sidebar = (state) => parking(state).sidebar;
export const sidebarLoading = (state) => parking(state).sidebarLoading;

export const polygon = (state) => parking(state).polygon;
export const polygonLoading = (state) => parking(state).polygonLoading;

export const active = (state) => parking(state).active;
export const filters = (state) => parking(state).filters;

export const saved = (state) => parking(state).saved;
export const deleteForm = (state) => parking(state).deleteForm;
export const editForm = (state) => parking(state).editForm;

export const statusStatistic = (state) => parking(state).statusStatistic;

export const parkingListLoading = (state) => parking(state).parkingLoading;
export const parkingList = (state) => parking(state).parkingList;
export const parkingListData = (state) => parkingList(state).data;
export const parkingListMeta = (state) => parkingList(state).meta;
