import { all, put, call, takeLatest } from 'redux-saga/effects';
import api from './api.methods';
import * as types from './types';
import * as actions from './actions';
import { setValidation } from '../Validation/actions';
import { messageTypes, showMessage } from '../Message/actions';
import messages from '../../helpers/constants/messages';

function* loadWorkRequestListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingWorkRequestList(true));
    const response = yield call(api.getWorkRequestList , page, limit, query);
    if (response) {
        yield put(actions.loadedWorkRequestList(response));
    }
    yield put(actions.loadingWorkRequestList(false));
}

function* createWorkRequestSaga({ payload: data }) {
    const response = yield call(api.createWorkRequest, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, 'Заявка успешно создана');
    }
}

function* editWorkRequestSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.editWorkRequest, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, 'Заявка успешно изменена');
    }
}

function* deleteWorkRequestSaga({ payload: id, callback }) {
    const response = yield call(api.deleteWorkRequest, id);
    if (response?.success) {
        yield showMessage(messageTypes.success, 'Заявка успешно удалена');
        yield callback?.();
    }
}

function* loadWorkRequestStatusesSaga() {
    const response = yield call(api.getWorkRequestStatuses);
    if (response?.success) {
        yield put(actions.loadedWorkRequestStatuses(response.data));
    }
}

function* changeWorkRequestStatusSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.changeWorkRequestStatus, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, 'Статус заявки успешно изменен');
    }
}

function* appointWorkRequestSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.appointWorkRequest, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_WORK_REQUEST_LIST, loadWorkRequestListSaga),
        takeLatest(types.CREATE_WORK_REQUEST, createWorkRequestSaga),
        takeLatest(types.EDIT_WORK_REQUEST, editWorkRequestSaga),
        takeLatest(types.DELETE_WORK_REQUEST, deleteWorkRequestSaga),
        takeLatest(types.LOAD_WORK_REQUEST_STATUSES, loadWorkRequestStatusesSaga),
        takeLatest(types.CHANGE_WORK_REQUEST_STATUS, changeWorkRequestStatusSaga),
        takeLatest(types.APPOINT_WORK_REQUEST, appointWorkRequestSaga),
    ]);
}