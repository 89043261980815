import * as types from './types';

const initialState = {
    loadingCompanies: false,
    companies: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    standardizedCompanies:{
        data:[],
        meta: {
            last_page: 0
        }
    },
    currentCompany: {},
    company: {},
    loadingCompany: false,
    currentFiolations: {},
    loadingCurrentFiliations: false,
    companyRoles: {
        data:[],
        meta: {
            last_page: 0
        }
    },
    loadingCompanyRoles: false,

    showcase: [],
    loadingShowcase: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADING_COMPANIES:
            return {
                ...state,
                loadingCompanies: payload,
            };
        case types.LOADED_COMPANIES:
            return {
                ...state,
                companies: payload,
            };
        case types.GOT_STANDARDIZED_COMPANIES_LIST:
            return {
                ...state,
                standardizedCompanies: payload,
            };
        case types.LOADED_CURRENT_COMPANY:
            return {
                ...state,
                currentCompany: { ...state.currentCompany, [payload.id]: payload }
            };
        case types.LOADED_ORGANIZATION: {
            return {
                ...state,
                company: { ...state.company, ...payload }
            };
        }
        case types.LOADING_ORGANIZATION: {
            return {
                ...state,
                loadingCompany: payload
            };
        }
        case types.CLEAR_STANDARDIZED_COMPANIES_LIST: {
            return {
                ...state,
                standardizedCompanies:{
                    data:[],
                    meta: {
                        last_page: 0
                    }
                },
            };
        }
        case types.LOADED_CURRENT_FILIATION_LIST: {
            return {
                ...state,
                currentFiolations: {
                    ...state.currentFiolations,
                    ...payload
                }
            };
        }
        case types.LOADING_CURRENT_FILIATION_LIST: {
            return {
                ...state,
                loadingCurrentFiliations: payload
            };
        }
        case types.LOADED_COMPANY_ROLE_LIST: {
            return {
                ...state,
                companyRoles: payload
            };
        }
        case types.LOADING_COMPANY_ROLE_LIST: {
            return {
                ...state,
                loadingCompanyRoles: payload
            };
        }

        case types.LOADED_SHOWCASE: {
            return {
                ...state,
                showcase: payload
            };
        }
        case types.LOADING_SHOWCASE: {
            return {
                ...state,
                loadingShowcase: payload
            };
        }
        
        default:
            return state;
    }
}