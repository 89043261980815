import React, { memo, useMemo } from 'react';
import messages from '../../../../../helpers/constants/messages';
import { LSContentColumn, LSContentItem } from '../../../../common/ListStyle';
import { ItemPropTypes } from './types';
import { getHumanDate } from '../../../../../helpers/date.config';

const Item = ({ item, category }: ItemPropTypes) => {
    const currentCategory = useMemo(() => {
        return category.data.find((el: { id: number; }) => el.id === item.category_id);
    }, [category.data, item.category_id]);

    const categoryName = currentCategory ? currentCategory.name : messages.NO_DATA;

    return (
        <LSContentItem>
            <LSContentColumn title="Дата" width="200px">
                {getHumanDate(item.created_at) || messages.NO_DATA}
            </LSContentColumn>
            <LSContentColumn title="ГРЗ" width="200px">
                {item.number || messages.NO_DATA}
            </LSContentColumn>
            <LSContentColumn title="Вид ТС" width="200px">
                {categoryName}
            </LSContentColumn>
            <LSContentColumn title="Модель" width="200px">
                {item.model.name_ru || messages.NO_DATA}
            </LSContentColumn>
            <LSContentColumn title="Марка" width="200px">
                {item?.model.brand.name_ru || messages.NO_DATA}
            </LSContentColumn>
            <LSContentColumn title="Пробег, км" width="200px" /*align="right" isActions*/>
                {item?.mileage || messages.NO_DATA}
            </LSContentColumn>
        </LSContentItem>
    );
};

export default memo(Item);