import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';
import styles from './videoFixation.module.scss';
import messages from '../../../../helpers/constants/messages';
import Item from './Item';
import CustomPagination from '../../../common/CustomPagination';
import Filters from './Filters';
import _ from 'lodash';
import Loading from '../../../common/Loading';
import {
    loadCameraEvents,
    loadCameraEventsGroup,
    loadCameraViolations
} from '../../../../redux/DorisControl/actions';
import { fullDateTimeWithoutTimeZone } from '../../../../helpers/date.config';
import { dorisControlSelectors } from '../../../../redux/DorisControl';

// Эта страница возможно устарела, подлежит проверке!!!
function VideoFixation() {
    const dispatch = useDispatch();

    const violations = useSelector(dorisControlSelectors.violations);
    const eventsGroup = useSelector(dorisControlSelectors.eventsGroup);
    const events = useSelector(dorisControlSelectors.events);
    const eventsLoading = useSelector(dorisControlSelectors.eventsLoading);

    const [filter, setFilter] = React.useState({});
    const [page, setPage] = React.useState(1);
    
    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = React.useState(storageLimit);

    useEffect(() => {
        if (events.data?.length === 0) {
            dispatch(loadCameraEvents());
        }
    }, [dispatch]);

    useEffect(() => {
        if (Object.keys(violations)?.length === 0) {
            dispatch(loadCameraViolations());
        }
    }, [dispatch, violations]);

    useEffect(() => {
        if (eventsGroup?.length === 0) {
            dispatch(loadCameraEventsGroup());
        }
    }, [dispatch]);

    useEffect( () => {
        const paramsWithoutEmpty = _.reduce(
            filter,
            (res, el, key) => {
                if (Array.isArray(el) && el?.length) {
                    res[key] = el.map(item => item.value);
                } else if (el?.hasOwnProperty('id') && el?.id) {
                    res[key] = el.id;
                }
                else if (el instanceof Date) {
                    res[key] = fullDateTimeWithoutTimeZone(el);
                }
                return res;
            },{});
        dispatch(loadCameraEvents(page, limit, paramsWithoutEmpty));
        dispatch(loadCameraEventsGroup(paramsWithoutEmpty));
    }, [filter, page, limit]);

    return (
        <div className={styles.videoFixation}>
            <h1>Отчет по фиксации нарушений ПДД</h1>
            <Filters
                setFilter={setFilter}/>
            {eventsLoading && <Loading circular={true}/>}
            {events.data.length > 0
                ? (
                    <>
                        <List>
                            {events.data.map((item) => (
                                <Item key={item.uuid} item={item} />
                            ))}
                        </List>
                        <CustomPagination
                            loadList={(page) => setPage(page)}
                            list={events.meta}
                            limit={limit} 
                            setLimit={setLimit}
                        />
                    </>
                )
                : (
                    !eventsLoading && <div>{messages.DATA_IS_NOT_FOUND}</div>
                )}
        </div>
    );
}

export default VideoFixation;
