import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadModels } from 'redux/TransportSpecial/actions';
import { transportSpecialSelectors } from 'redux/TransportSpecial';
import Context from 'helpers/context';
import messages from 'helpers/constants/messages';
import Loading from 'components/common/Loading';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import ModelListHeader from 'components/common/Transport/ModelList/Header';
import PageLayout from 'components/layout/PageLayout';
import ModelFilters from 'components/common/Transport/ModelList/ModelFilters';

import ModalForm from './ModalForm';
import Item from './Item';

const Models = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const list = useSelector(transportSpecialSelectors.modelsData);
    const meta = useSelector(transportSpecialSelectors.modelsMeta);
    const loading = useSelector(transportSpecialSelectors.loadingModels);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openAddModal, setOpenAddModal] = useState(false);

    const reloadList = (isDelete) => {
        const page = isDelete && list?.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadModels(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return (
            <>
                {loading && <Loading circular={true}/>}
                {list?.length > 0
                    ? <ModelListHeader>
                        {list.map((item) => (
                            <Item
                                key={item.id}
                                item={item}
                                reloadList={reloadList}
                                permissions={permissions}
                            />
                        ))}
                    </ModelListHeader>
                    : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
                }
            </>
        );
    };
    
    return (
        <>
            <PageLayout
                header="Модели"
                filters={<ModelFilters setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenAddModal(true)
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: meta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />
            {openAddModal && (
                <ModalForm
                    open={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default Models;