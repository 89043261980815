import { Collapse } from '@mui/material';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { humanDateWithoutTZ } from 'helpers/date.config';
import type { PakItem } from 'types/RoadNetwork';

interface InfoProps {
    isOpen: boolean,
    data: PakItem
}

const Info = ({ isOpen, data }: InfoProps) => {
    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            <div>
                <strong>{titles.IP_ADDRESS}: </strong>
                {data.ip_route || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>МАС адрес: </strong>
                {data.mac || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>{titles.SERIAL_NUMBER}: </strong>
                {data.serial_number || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>Доп. информация: </strong>
                {data.additional_info || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>Комната: </strong>
                {data.room_name || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>Стойка: </strong>
                {data.rack_name || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>Собственник оборудования: </strong>
                {data.organization_contacts?.title || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>Номер договора поставки: </strong>
                {data.supply_agreement_number || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>Срок действия гарантии на оборудование: </strong>
                {humanDateWithoutTZ(data.equipment_guarantee) || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>Срок действия гарантии на СМР: </strong>
                {humanDateWithoutTZ(data.cmp_guarantee) || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>Договор на ТО: </strong>
                {data.maintenance_agreement || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>Срок действия договора на ТО: </strong>
                {humanDateWithoutTZ(data.maintenance_agreement_date) || messages.INFO_IS_NOT_FOUND}
            </div>
        </Collapse>
    );
};

export default Info;
