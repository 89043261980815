import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteCommutator } from 'redux/RoadNetwork/actions';
import { Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { fullDateTime } from 'helpers/date.config';
import MapForm from 'components/pages/Dictionaries/DorisControl/VFComplex/MapForm';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import BasicDataForm from './BasicDataForm';
import type { CommutatorItem } from 'types';

interface BasicDataProps {
    currentCommutator: CommutatorItem;
    loading: boolean;
    onEdited: () => void;
    onDeleted: () => void;
    callback?: () => void;
}

const BasicData = ({
    currentCommutator,
    loading = false,
    onEdited = () => {},
    onDeleted = () => {},
}: BasicDataProps) => {
    const dispatch = useDispatch();

    const [editMode, setEditMode] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const currentButtons = [
        {
            ...buttonsTypes.edit,
            icon: <EditIcon/>,
            color: 'default',
            size: 'small',
            tooltipTitle: 'Редактировать',
            onClick: () => setEditMode(true),
        },
        {
            ...buttonsTypes.delete,
            icon: <DeleteIcon/>,
            color: 'default',
            size: 'small',
            tooltipTitle: 'Удалить',
            onClick: () => setOpenDeleteModal(true)
        }
    ];

    return (
        <div style={{ margin: '1rem' }}>
            {loading && <Loading linear />}
            <Grid container direction="row" spacing={1} alignItems="flex-start">
                {Object.keys(currentCommutator).length > 0
                    ? <>
                        <Grid item xs={6}>
                            <Divider style={{ margin: '5px 0' }}/>
                            <div className="filter filter__wrap__btn">
                                <FormButtons
                                    buttons={currentButtons}
                                />
                            </div>
                            {currentCommutator?.name && (
                                <Typography variant="body2">
                                    <span><b>Наименование: </b> {currentCommutator?.name}</span>
                                </Typography>
                            )}
                            {currentCommutator?.serial_number && (
                                <Typography variant="body2">
                                    <span><b>Серийный №: </b> {currentCommutator?.serial_number}</span>
                                </Typography>
                            )}
                            <Typography variant="body2">
                                <span><b>IP: </b> {currentCommutator?.ip_route}</span>
                            </Typography>
                            <Typography style={{ margin: '10px 0' }} variant="body2">
                                <strong>Адрес: </strong>
                                <span>{currentCommutator?.address_text}</span>
                            </Typography>
                            <Typography variant="body2">
                                <strong>Координаты</strong>
                            </Typography>
                            <Typography variant="body2">
                                {currentCommutator?.lat && <span><b>Широта: </b> {currentCommutator?.lat}</span>}
                            </Typography>
                            <Typography variant="body2">
                                {currentCommutator?.lon && <span><b>Долгота: </b> {currentCommutator?.lon}</span>}
                            </Typography>
                            <Typography variant="body2">
                                {currentCommutator?.updated_at
                                    && <span>
                                        <b>Последнее изменение данных: </b>
                                        {fullDateTime(currentCommutator?.updated_at)}
                                    </span>}
                            </Typography>
                            <Divider style={{ margin: '10px 0' }}/>
                        </Grid>
                        <Grid item xs={6}>
                            {currentCommutator?.lat && currentCommutator?.lon
                                && <MapForm
                                    latlon={[currentCommutator?.lat, currentCommutator?.lon]}
                                    readOnly={true}
                                />
                            }
                        </Grid>
                        {editMode && (
                            <BasicDataForm
                                isOpen={editMode}
                                onClose={() => setEditMode(false)}
                                item={currentCommutator}
                                isNew={false}
                                onEdited={onEdited}
                            />
                        )}
                        {openDeleteModal && (
                            <ConfirmDelete
                                open={openDeleteModal}
                                onClose={() => setOpenDeleteModal(false)}
                                onSuccess={() => dispatch(deleteCommutator(currentCommutator.id, onDeleted))}
                            />
                        )}
                    </>
                    : !loading && <div>Комплекс не найден</div>
                }
            </Grid>
        </div>
    );

};

export default BasicData;
