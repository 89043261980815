import React, { useContext, useEffect, useState } from 'react';
import { List } from '@mui/material';
import Item from './Item';
import messages from '../../../../../helpers/constants/messages';
import Loading from '../../../../common/Loading';
// import Context from '../../../../../helpers/context';
import {
    useGroupGraph
} from '../../../../../helpers/hooks/Efficiency';


function AllReportsEfficient() {
    const groupGraphProvider = useGroupGraph();

    // const { permissions } = useContext(Context);

    useEffect(() => {
        groupGraphProvider.load();

        return () => {
            groupGraphProvider.clear();
        };
    }, []);

    return (
        <div>
            <h1>Все отчеты (контроль эффективности ИТС)</h1>
            {groupGraphProvider.loading && <Loading circular={true} />}
            {groupGraphProvider.data?.length > 0
                ? (
                    <>
                        <List className="list">
                            {groupGraphProvider?.data?.map((item, index) => (
                                <Item
                                    key={`item_${index}`}
                                    item={item}
                                />
                            ))}
                        </List>
                    </>
                ) : (!groupGraphProvider.loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </div>
    );
}

export default AllReportsEfficient;
