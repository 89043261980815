import {
    List,
    ListItem,
    Typography,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import messages from 'helpers/constants/messages';
// import useCheckUserOrganizations from 'helpers/hooks/useCheckUserOrganizations';
import Loading from 'components/common/Loading';

const OrderOrganizations = ({ list = [], loading = false }) => {
    // const companies = useCheckUserOrganizations(list);

    return (
        <>
            <Typography variant="body2"><strong>Организации: </strong></Typography>
            {loading && <Loading linear/>}
            {list?.length > 0
                ? <List dense>
                    {list.map((el, i) => (
                        <ListItem key={el?.id || i}>
                            <ListItemIcon>
                                {i + 1}.
                            </ListItemIcon>
                            <ListItemText>
                                <span>{el?.title}</span>
                            </ListItemText>
                        </ListItem>))
                    }
                </List>
                : (!loading && <Typography variant="body2">{messages.NO_DATA}</Typography>)
            }
        </>
    );
};

export default OrderOrganizations;
