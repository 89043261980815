import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/DorisControl/actions';
import { usePrevious } from '../index';
import { useEffect, useState } from 'react';
import { dorisControlSelectors } from '../../../redux/DorisControl';

const useComplexById = () => {
    const dispatch = useDispatch();
    const complexById = useSelector(dorisControlSelectors.complexById);
    const loadingComplexById = useSelector(dorisControlSelectors.loadingComplexById);

    const prevLoading = usePrevious(loadingComplexById);

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (prevLoading === true && loadingComplexById === false) {
            setLoaded(true);
        }
    }, [loadingComplexById, prevLoading]);

    return {
        isLoaded: loaded,
        // loading: loadingComplexById,
        data: complexById || {},
        load: (id) => {
            setLoaded(false);
            dispatch(actions.loadComplexById(id));
        },
        clear: () => {
            setLoaded(false);
            dispatch(actions.clearComplexById());
        },
    };
};

export default useComplexById;
