import React from 'react';
import { useSelector } from 'react-redux';

import { dorisControlSelectors } from 'redux/DorisControl';

import Page from './Page';

const CameraEventsFrame = () => {
    const showDorisCameraEvents = useSelector(dorisControlSelectors.showDorisCameraEvents);

    return (
        <>
            {showDorisCameraEvents
                && <Page/>
            }
        </>
    );
};

export default CameraEventsFrame;
