import { useMemo, useState } from 'react';

import { fullDateTime } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import Image from 'components/common/Image';
import prepareCoords from 'helpers/prepareCoords';

import MediaModal from '../MediaModal';

const Info = ({ data = {} }) => {
    const columns = useMemo(() => [
        ['date', 'name', 'address_text', 'lat', 'violation_type_text'],
        ['number', 'direction', 'line', 'speed']
    ],[]);

    const labels = useMemo(() => ({
        date: {
            title: 'Дата/время фиксации',
            value: (value) => fullDateTime(value)
        },
        name: {
            title: 'Комплекс',
        },
        address_text: {
            title: 'Адрес',
        },
        lat: {
            title: 'Координаты (lat/lon)',
            value: () => `${prepareCoords(Number(data?.lat), 4)}/${prepareCoords(Number(data?.lon), 4)}`
        },
        violation_type_text: {
            title: 'Тип события',
            value: (value) => (value || []).join(', ')
        },
        number: {
            title: 'Номер ТС',
        },
        direction: {
            title: 'Направление',
        },
        line: {
            title: 'Полоса движения',
        },
        speed: {
            title: 'Скорость',
            value: (value) => value && `${value} км/ч`
        },
    }),[data?.lat, data?.lon]);

    const renderItems= (column) => (
        column.map(key => {
            const title = labels[key]?.title;
            const value = labels[key]?.value;
            const field = data?.[key];
    
            return (
                <div key={key} className="row">
                    <strong>{title}:&nbsp;</strong>
                    <span>{(value && value(field)) || field || messages.NO_DATA}</span>
                </div>
            );
        })
    );

    const selectedPhotos = useMemo(() => {
        const photos = data?.photos || {};
        return Object
            .keys(photos)
            .reduce((r, k) => r.concat(photos?.[k]?.images || []), []);
    }, [data]);

    const [showModal, setShowModal] = useState(false);

    const handleSetShow = (bool = false) => () => setShowModal(bool);

    return (
        <>
            <div className="row">
                {columns.map((column) => (
                    <div key={column} style={{ width: '40%' }}>
                        {renderItems(column)}
                    </div>
                ))}

                {selectedPhotos?.length > 0 && (
                    <Image
                        onClick={handleSetShow(true)}
                        src={selectedPhotos[0]}
                        style={{ 
                            cursor: 'pointer',
                            width: 'auto',
                            minWidth: '100px',
                            height: '100px' 
                        }}
                    />
                )}
            </div>

            {showModal && (
                <MediaModal
                    isOpen={showModal}
                    onClose={handleSetShow(false)}
                    images={selectedPhotos}
                    renderLabels={() => (
                        <div className="row">
                            {columns.map((column) => (
                                <div key={column} style={{ width: '50%' }}>
                                    {renderItems(column)}
                                </div>
                            ))}
                        </div>
                    )}
                />
            )}
        </>
    );
};

export default Info;
