import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TFilter from './TFilter';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import TModal from './TModal';
import { useStoreProp } from '../../../../../helpers/hooks';
import messages from '../../../../../helpers/constants/messages';
import TAccordion from './TAccordion';
import Loading from '../../../../common/Loading';
import {
    loadRoutesTransportations,
    loadTariffsList
} from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import Context from '../../../../../helpers/context';

const Tariffs = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const tsTypes = useStoreProp(
        loadRoutesTransportations,
        'transportPassenger',
        'transportations'
    );

    const tariffs = useSelector(transportPassengerSelectors.tariffs);
    const loadingTariffs = useSelector(transportPassengerSelectors.loadingTariffs);

    const [params, setParams] = useState({});
    const [typeQuery, setTypeQuery] = useState({ type_transportation: 1 });
    const [openAddModal, setOpenAddModal] = useState(false);

    useEffect(() => {
        dispatch(loadTariffsList());
    }, [dispatch]);

    return (
        <>
            {openAddModal && (
                <TModal
                    isOpen={openAddModal}
                    isNew={true}
                    onClose={() => setOpenAddModal(false)}
                    tsTypes={tsTypes}
                    typeQuery={typeQuery}
                />
            )}
            <h1>Тарифы</h1>
            <TFilter
                typeQuery={typeQuery}
                setParams={setParams}
            />
            <div className="filter__wrap__btn">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenAddModal(true),
                            disabled: !permissions?.is_create,
                        }
                    ]}
                />
            </div>
            {loadingTariffs && <Loading linear={true}/>}
            {Object.keys(tariffs)?.length > 0
                ? <TAccordion
                    tariffs_data={tariffs}
                    tsTypes={tsTypes}
                    setTypeQuery={setTypeQuery}
                    params={params}
                    typeQuery={typeQuery}
                />
                : (!loadingTariffs && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>
    );
};

export default Tariffs;