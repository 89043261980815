import React from 'react';
import cn from 'classnames';

// элемент списка
const Item = ({
    children,
    className,
}) => (
    <div className={cn('item', className)}>
        {children}
    </div>
);

export default Item;