import { debounce } from 'lodash';

// обработка кликов
const handleClick = (e) => {
    const { cluster, layer } = e;

    // если в кластере - приближаем - отображаем
    if (cluster && cluster?.hasLayer) {
        const markerInCluster = cluster?.hasLayer(layer) || false;
        if (markerInCluster) {
            // todo подумать (слой участки)
            try {
                // показать маркер в кластере
                cluster?.zoomToShowLayer(layer, () =>
                    setTimeout(() => {
                        layer?.fire('click').closeTooltip();
                    }, 300)
                );
            }
            catch (e) {
                // если выше ошибка - то делаем простой клик
                layer?.fire('click').closeTooltip();
            }
        } else {
            layer?.fire('click').closeTooltip();
        }
    } else {
        layer?.fire('click').closeTooltip();
    }
};

// export default handleClick;
export default debounce(handleClick, 200);