import TrafficFlow from '../../pages/Dictionaries/TrafficFlow';
import PUIDSettings from '../../pages/Dictionaries/Puid/Settings';

const routes = [
    {
        path: '/dictionaries/puid/devices',
        component: TrafficFlow,
        exact: true
    },
    {
        path: '/dictionaries/puid/settings',
        component: PUIDSettings,
        exact: true
    },
];

export default routes;