import React, { useEffect, useState } from 'react';
import PhaseGeoJson from './PhaseGeoJson';
import { usePrevious } from '../../../../../helpers/hooks';
import { isEqual } from 'lodash';
import {
    GeoJson,
} from '../../../leaflet/';


const Phases = ({ wsData, polygon, ...props }) => {
    const prevWsData = usePrevious(wsData);
    const [directionsGeoJson, setDirectionsGeoJson] = useState([]);
    const [directionsGeoJsonZebra, setDirectionsGeoJsonZebra] = useState([]);


    useEffect(() => {
        if (!isEqual(prevWsData, wsData)) {
            // пешеходные переходы
            const zebraList = [];
            // направления движений
            const directionsList = [];
            /*
                данные из канала
                tloId(pin):"75b66a40-7f83-4e89-82ce-75ab514d501f"
                ctrlStateId(pin):"d2d74cdb-98d1-48ad-8252-50e4a300f1a2"
                ctrlTypeId(pin):"513eade8-8b89-11e6-9f62-9f4b288b0ae4"
                passedTime(pin):19
                curPhNum(pin):3
                isTransit(pin):false
                isHold(pin):0
                leftTime(pin):4
                cycleDur(pin):87
                progNum(pin):"1"
                progId(pin):"d32725e6-f81e-424e-b4a0-ef2152652ed0"
            */

            Object.keys(wsData).map((key) => {
                const {
                    curPhNum,
                    progId,
                    tloId,
                } = wsData[key];

                // ищем светофор в полигоне
                const light = polygon?.find(({ external_id }) => external_id === tloId) || {};
                // ищем программу в светофоре
                const program = light?.programs?.find(({ external_id }) => external_id === progId) || {};
                // фазы
                const { phases = [] } = program;

                phases.map((phase) => {
                    const { directions, number } = phase;

                    if (number === curPhNum) {
                        directions.map((direction) => {
                            const { geo_data, type_id } = direction;
                            if (type_id === 'ce0f62b2-cd89-4162-9df7-41c7aee17545') {
                                // направление движения
                                directionsList.push(geo_data.geometry);
                            } else if (type_id === 'fa26e21b-ae0c-4641-96ca-dbfe746cead3') {
                                // пешеходное направление
                                zebraList.push(geo_data.geometry);
                            }
                        }, []);
                    }
                });
            });

            setDirectionsGeoJson(directionsList);
            setDirectionsGeoJsonZebra(zebraList);
        }
    }, [wsData, prevWsData, polygon]);


    // useEffect(() => {
    //     if (!isEqual(prevWsData, wsData)) {
    //         const zebraList = [];
    //         const directionsList = [];
    //
    //         Object.keys(wsData).map((key) => {
    //             const { program, curPhNum } = wsData[key];
    //             const { phases } = program;
    //
    //             phases.map((phase) => {
    //                 const { directions, number } = phase;
    //                 if (number === curPhNum) {
    //                     directions.map((direction) => {
    //                         const { geo_data, type_id } = direction;
    //                         if (type_id === 'ce0f62b2-cd89-4162-9df7-41c7aee17545') {
    //                             // направление движения
    //                             directionsList.push(geo_data.geometry);
    //                         } else if (type_id === 'fa26e21b-ae0c-4641-96ca-dbfe746cead3') {
    //                             // пешеходное направление
    //                             zebraList.push(geo_data.geometry);
    //                         }
    //                     }, []);
    //                 }
    //             });
    //         });
    //
    //         setDirectionsGeoJson(directionsList);
    //         setDirectionsGeoJsonZebra(zebraList);
    //     }
    // }, [wsData, prevWsData]);


    useEffect(() => {
        return () => {
            setDirectionsGeoJson([]);
            setDirectionsGeoJsonZebra([]);
        };
    }, []);

    return (
        <>
            {/* направления */}
            <PhaseGeoJson
                {...props}
                data={directionsGeoJson}
            />
            {/* пешеходные переходы */}
            <GeoJson
                {...{
                    ...props,
                    parent: props.map
                }}
                data={directionsGeoJsonZebra}
                style={{ className: 'tl-zebra' }}
            />
        </>
    );
};

export default Phases;
