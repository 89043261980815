import React from 'react';
import { NotificationContainer } from 'react-notifications';
import './style.scss';

const Message = () => {
    return (
        <NotificationContainer />
    );
};

export default Message;
