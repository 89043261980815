import React, { useMemo } from 'react';
import { Pie as GPie } from 'react-chartjs-2';
// import hexToRgba from './hexToRgba';


const Pie = ({
    data = [],
    width = 200,
    height = 200,
}) => {
    const callbacks = {
        // label: (context) => `${context?.label} ${context?.formattedValue}%`,
        label: (context) => `${context?.label}`,
        title: () => ''
    };

    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        redraw: false,
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks
            },
            legend: {
                align: 'top',
                position: 'left',
                labels: {
                    boxWidth: 20,
                }
            }
        },
    };

    const dataG = useMemo(() => {

        const {
            colors,
            labels,
            values,
        } = data.reduce((res, { name, count, color }) => ({
            colors: [
                ...res.colors || [],
                color,
            ],
            labels: [
                ...res.labels || [],
                ` ${name} (${count})`,
            ],
            values: [
                ...res.values || [],
                count,
            ],
        }), {});

        const datasets = {
            data: values,
            maxBarThickness: 80,
            backgroundColor: colors, //.map((color) => hexToRgba(color, .8)),
            borderColor: '#fff',
        };

        return {
            labels: labels,
            datasets: [datasets],
        };
    }, [data]);

    return (
        <div>
            <GPie
                width={width}
                height={height}
                data={dataG}
                options={options}
                type="Pie"
            />
        </div>
    );
};

export default Pie;
