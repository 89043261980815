import * as types from './types';

const initialState = {
    stationStatuses: [],

    stationList: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingStationList: false,

    stationSidebar: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingStationSidebar: false,

    active: {},
    filters: {},

    vestibulePolygon: [],
    vestibulePolygonLoading: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_STATION_STATUSES:
            return {
                ...state,
                stationStatuses: payload
            };

        case types.LOADED_STATION_LIST:
            return {
                ...state,
                stationList: payload
            };

        case types.LOADING_STATION_LIST:
            return {
                ...state,
                loadingStationList: payload
            };

        case types.LOADED_STATION_SIDEBAR: {
            const { data, isReplace } = payload;

            if (isReplace) {
                return {
                    ...state,
                    stationSidebar: data,
                };
            }

            return {
                ...state,
                stationSidebar: {
                    ...state.stationSidebar,
                    ...data,
                    data: [
                        ...state.stationSidebar.data,
                        ...data.data,
                    ],
                },
            };
        }

        case types.LOADING_STATION_SIDEBAR:
            return {
                ...state,
                loadingStationSidebar: payload,
            };

        case types.CLEAR_STATION_SIDEBAR:
            return {
                ...state,
                stationSidebar: initialState.sidebar,
            };

        case types.SET_ACTIVE:
            return {
                ...state,
                active: payload,
            };

        case types.SET_FILTERS:
            return {
                ...state,
                filters: payload
            };

        case types.LOADED_VESTIBULE_POLYGON: {
            return {
                ...state,
                vestibulePolygon: payload,
            };
        }

        case types.LOADED_VESTIBULE_POLYGON_LOADING: {
            return {
                ...state,
                vestibulePolygonLoading: payload,
            };
        }

        default:
            return state;
    }
}
