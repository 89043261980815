import Card from './Components/Card';
import Table from './Components/Table';
import PieNew from './Components/PieNew';
import Bar from './Components/Bar';
import Doughnut from './Components/Doughnut';
// import Line from './Components/Line';
// import Pie from './Components/Pie';
// import Doughnut from '../../../../common/Showcase/TypeComponent/components/Doughnut';
import types from './types';

const ComponentByType = ({ type = '', data, max }) => {
    switch (type) {
        // линейный грфик
        // case types.LINE:
        //     return <Line data={data} max={max} />;
        //
        // // с заполнением
        // case types.AREA:
        //     return <Line data={data} fill={true} max={max} />;

        // вертикальный бар
        case types.VERTICAL_BAR:
            return <Bar data={data} max={max} />;

        // горизонтальный бар
        case types.HORIZONTAL_BAR:
            return <Bar data={data} horizontal={true} max={max} />;

        // круглый график
        case types.PIE:
            return <PieNew data={data} max={max} />;

        // таблица
        case types.TABLE:
            return <Table data={data} max={max} />;

        case types.CARD:
            return <Card data={data} max={max} />;

        case types.DOUGHNUT:
            return <Doughnut data={data} customLegend flexStyle />;

        // нет такого
        default:
            return <div>Данного графика не найдено</div>;
    }
};

export default ComponentByType;
