import React from 'react';

export const renderChilds = (list, props = {}) => list
    .map((child, index) => child?.type
        ? (
            <child.type
                key={index}
                {...props}
                {...child.props}
            />
        )
        : child
    );
