import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../../../../components/common/Loading';
import {
    clearDeviceDataMeteo,
    getDeviceDataMeteo
} from '../../../../../redux/Meteo/actions';
import { usePrevious } from '../../../../../helpers/hooks';
import messages from '../../../../../helpers/constants/messages';
import titles from '../../../../../helpers/constants/titles';
import { fullDateTimeWithoutSeconds } from '../../../../../helpers/date.config';
import { meteoSelectors } from '../../../../../redux/Meteo';
import { isValue } from '../../../../../helpers/checkValue';

const TabInformation = (props) => {
    const { external_id, readOnly = false, style = {} } = props;

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);

    const dataDevice = useSelector(meteoSelectors.dataDevice);
    const loading = useSelector(meteoSelectors.loading);
    const prevLoading = usePrevious(loading);

    useEffect(() => {
        dispatch(getDeviceDataMeteo(external_id));
        return () => {
            dispatch(clearDeviceDataMeteo());
        };
    }, []);

    useEffect(() => {
        if (prevLoading === true && loading === false) {
            setIsOpen(true);
        }
    }, [loading]);

    // склонение
    const declOfNum = (number, titles) => {
        const cases = [2, 0, 1, 1, 1, 2];
        return titles[
            (number % 100 > 4 && number % 100 < 20)
                ? 2
                : cases[
                    (number % 10 < 5) ? number % 10 : 5
                ]
        ];
    };

    const RenderList = () => {
        const device_data = dataDevice?.[0]?.device_data || [];

        if (!device_data.length) {
            return <div className="not-found">{messages.INFO_IS_NOT_FOUND}</div>;
        }

        return (
            <div>
                <h3 style={{ marginLeft: '1rem', marginBottom: '.5rem' }}>
                    {titles.DATA_FROM} {fullDateTimeWithoutSeconds(dataDevice[0]?.created_at)}
                </h3>

                <table className="table-information">
                    <tbody>
                        <tr>
                            <th>Наименование</th>
                            <th>Текущее значение</th>
                        </tr>

                        {device_data.map((field, index) => {
                            // если только чтение и нет данных - не показывать
                            if (readOnly && !isValue(field.value)) return null;

                            return (
                                <tr key={`tr_${index}`}>
                                    <td>
                                        {field?.name}
                                    </td>
                                    <td>
                                        <div>{isValue(field.value) ? `${field.value} ${field.unit}` : messages.INFO_IS_NOT_FOUND}</div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div>
            {loading && (
                <div>
                    <Loading size={50} className="preloader center" />
                </div>
            )}

            {isOpen && (
                <Scrollbars style={{ height: '210px', ...style }}>
                    <RenderList />
                </Scrollbars>
            )}
        </div>
    );
};

export default TabInformation;
