import { all, put, call, takeLatest } from 'redux-saga/effects';
import api from './api.methods';
import * as types from './types';
import { showMessage } from '../Message/actions';
import messageTypes from '../Message/messagetypes';
import { loadComplexes } from '../DorisControl/actions';
import messages from '../../helpers/constants/messages/common';
import { setUploadRoadWorkFile, setUploadTransportIncidentFile, setUploadVehicleFile, uploading, loadedCSODDFile, loadedRoadLabTypes } from './actions';

function* uploadToStorageSaga({ payload, callback }) {
    const response = yield call(api.uploadFileToStorage, payload);
    if (response?.success) {
        yield callback(response.data);
    } else {
        yield put(showMessage(
            messageTypes.error,
            'Загрузка не удалась. Попробуйте еще раз позднее'
        ));
    }
}

function* createComplexByUploadingFileSaga({ payload }) {
    const response = yield call(api.createComplexByUploadingFile, payload);
    if (response?.success) {
        yield put(loadComplexes());
        yield put(showMessage(
            messageTypes.success,
            messages.SAVE_SUCCESS
        ));
    } else {
        yield put(showMessage(
            messageTypes.error,
            messages.TRY_LATER
        ));
    }
}

function* uploadVehicleSaga({ payload }) {
    yield put(uploading(true));
    yield put(setUploadVehicleFile(payload));
    const response = yield call(api.uploadVehicleFile, payload);
    if (response?.success) {
        yield put(showMessage(
            messageTypes.success,
            messages.SAVE_SUCCESS
        ));
        yield put(setUploadVehicleFile(null));
    }
    yield put(uploading(false));
}

function* uploadRoadWorkSaga({ payload }) {
    yield put(uploading(true));
    yield put(setUploadRoadWorkFile(payload));
    const response = yield call(api.uploadRWFile, payload);
    if (response?.success) {
        yield put(showMessage(
            messageTypes.success,
            messages.SAVE_SUCCESS
        ));
        yield put(setUploadRoadWorkFile(null));
    }
    yield put(uploading(false));
}

function* uploadTransportIncidentFileSaga({ payload }) {
    yield put(uploading(true));
    yield put(setUploadTransportIncidentFile(payload));
    const response = yield call(api.uploadTransportIncidentFile, payload);
    if (response?.success) {
        yield put(showMessage(
            messageTypes.success,
            messages.SAVE_SUCCESS
        ));
        yield put(setUploadTransportIncidentFile(null));
    }
    yield put(uploading(false));
}

function* uploadCSODDFileSaga({ payload, callback }) {
    yield put(uploading(true));
    const response = yield call(api.uploadCSODDFile, payload);
    if (response?.success) {
        yield put(showMessage(
            messageTypes.success,
            messages.SAVE_SUCCESS
        ));
        callback && callback();
    }
    yield put(uploading(false));
}

function* getCSODDFileSaga() {
    yield put(uploading(true));
    const response = yield call(api.getCSODDFile);
    if (response?.success) {
        yield put(loadedCSODDFile(response.data));
    } else {
        yield put(loadedCSODDFile(null));
    }
    yield put(uploading(false));
}

function* deleteCSODDFilesSaga({ payload, callback }) {
    yield put(uploading(true));
    const response = yield call(api.deleteCSODDFile, payload);
    if (response?.success) {
        yield put(showMessage(
            messageTypes.success,
            messages.DELETE_SUCCESS,

        ));
    } else {
        yield put(showMessage(
            messageTypes.warning,
            messages.SOMETHING_WENT_WRONG,
        ));
    }
    callback && callback();
    yield put(uploading(false));
}

function* getRoadLabTypesSaga() {
    const response = yield call(api.getRoadLabTypes);
    if (response?.success) {
        yield put(loadedRoadLabTypes(response.data));
    } else {
        yield put(loadedRoadLabTypes([]));
    }
}

function* uploadRoadLabTypesSaga({ payload }) {
    const response = yield call(api.uploadRoadLabTypes, payload);
    if (response?.success) {
        yield put(showMessage(
            messageTypes.success,
            messages.SAVE_SUCCESS
        ));
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.UPLOAD_FILE_TO_STORAGE, uploadToStorageSaga),
        takeLatest(types.CREATE_COMPLEX_BY_UPLOADING_FILE, createComplexByUploadingFileSaga),
        takeLatest(types.UPLOAD_VEHICLE_FILE, uploadVehicleSaga),
        takeLatest(types.UPLOAD_ROADWORK_FILE, uploadRoadWorkSaga),
        takeLatest(types.UPLOAD_TRANSPORT_INCIDENT_FILE, uploadTransportIncidentFileSaga),
        takeLatest(types.UPLOAD_CSODD_FILE, uploadCSODDFileSaga),
        takeLatest(types.LOAD_CSODD_FILE, getCSODDFileSaga),
        takeLatest(types.DELETE_CSODD_FILE, deleteCSODDFilesSaga),
        takeLatest(types.LOAD_ROAD_LAB_TYPES, getRoadLabTypesSaga),
        takeLatest(types.UPLOAD_ROAD_LAB, uploadRoadLabTypesSaga)
    ]);
}
