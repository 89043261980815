import { Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { fullDateTimeWithoutSeconds } from '../../../../../helpers/date.config';
import { Count } from './types';

interface InfoProps {
    isOpen: boolean;
    item: Count;
}

const Info = ({ isOpen, item }: InfoProps) => {
    return (
        <Collapse
            style={{ backgroundColor: 'transparent' }}
            in={isOpen}
            timeout="auto"
            unmountOnExit
            className="info"
        >
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Время начала фиксации</TableCell>
                            <TableCell align="left">Время окончания фиксации</TableCell>
                            <TableCell align="left">Пассажиропоток</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {item.list.map((item, index) => (
                            <TableRow key={index} style={{ backgroundColor: index % 2 ? 'rgba(155,157,176,0.08)' : 'inherit' }}>
                                <TableCell>
                                    {fullDateTimeWithoutSeconds(item.date_start)}
                                </TableCell>
                                
                                <TableCell align="left">
                                    {fullDateTimeWithoutSeconds(item.date_end)}
                                </TableCell>
                                
                                <TableCell align="left">
                                    {item.count_passengers}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Collapse>
    );
};

export default Info;