import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidation } from '../../../../helpers/hooks';
import titles from '../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../FormButtons';
import { TextField } from '@mui/material';
import { addPositions, editPositions } from '../../../../redux/TransportPassenger/actions';
import Modal from '../../Modal';
import Loading from '../../Loading';


function ModalAddEdit({ 
    item, 
    isOpen, 
    isNew,  
    onClose, 
    reloadList,
    actions, 
    loading, 
    storeName,
}) {
    const dispatch = useDispatch();

    const validation = useValidation();

    const [position, setPosition] = useState({ name: item?.name || '' });

    const handleChange = ({ target: { name, value } }) => {
        setPosition({ [name]: value });
        validation.deleteKey(name);
    };

    const onAccept = () => {
        isNew
            ? dispatch(actions.addPositions(position, () => {reloadList(); onClose();}))
            : dispatch(actions.editPositions(item?.id, position, () => {reloadList(); onClose();}));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? `${titles.ADD} должности` : `${titles.EDIT} должности`}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAccept,
                        disabled: position.name === '' || validation.isValidation()
                    }
                ]}
            />}
            noPadding
        >
            {loading && <Loading circular />}
            <form className="modal__form">
                <div className="block">
                    <TextField
                        value={position.name}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        label={titles.POSITION}
                        name="name"
                        error={validation.isKey('name')}
                        helperText={validation.get('name')}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                    />
                </div>
            </form>
        </Modal>
    );
}

export default ModalAddEdit;
