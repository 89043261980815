import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { createNewDtp, loadDtpOnRadius, loadedDtpOnRadius } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import renderAddress from 'helpers/renderAddress';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import { FeatureGroup, Map, MapControl, Marker, PopUp } from 'components/MapComponents/leaflet';
import { createIconMarker, getColor } from 'components/MapComponents/Layers/Incidents/helper';
import Loading from 'components/common/Loading';
import ModalForm from 'components/MapComponents/Layers/Incidents/ModalForm';
import PopUpDtp from 'components/MapComponents/Layers/Incidents/PopUp';

const DtpModal = ({ isOpen, onClose, tiData }) => {
    const dispatch = useDispatch();

    const nearDtpList = useSelector(incidentsSelectors.dtpOnRadius);
    const loading = useSelector(incidentsSelectors.loadingDtpOnRadius);
    const types = useSelector(incidentsSelectors.dtpTypesData);

    const [selected, setSelected] = useState(null);
    const [dtpInfo, setDtpInfo] = useState(null);
    const [openDtpForm, setOpenDtpForm] = useState(false);

    const nearDtpMarkers = useMemo(() => {
        const color = '#676a679e';

        return nearDtpList.map((item) => (
            <Marker
                key={item.id}
                icon={createIconMarker(getColor(item, types))}
                latlng={[item.lat, item.lon]}
                attribution={{ color }}
                onClick={() => setSelected([item.lat, item.lon])}
            >
                <PopUp >
                    <PopUpDtp
                        dtpData={item}
                        readOnly
                    />
                </PopUp>
            </Marker>
        ),[]);
    }, [nearDtpList, types]);

    const onAcceptDtpForm = (data) => {
        const callback = (new_id) => {
            setOpenDtpForm(false); 
            onClose(new_id);
        };

        dispatch(createNewDtp(data, callback));
    };

    useEffect(() => {
        const { lat, lon } = tiData;
        dispatch(loadDtpOnRadius({ lat, lon, radius: 500 }));

        return () => {
            dispatch(loadedDtpOnRadius([]));
        };
    }, []);

    return (
        <Modal
            title="Создать ДТП"
            onClose={onClose}
            medium
            noPadding
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: () => onClose(null)
                    },
                    {
                        ...buttonsTypes.save,
                        name: 'Далее',
                        onClick: () => setOpenDtpForm(true)
                    }
                ]}
            />}
        >
            <div className="map-form-wrap__container" style={{ maxHeight: '600px' }}>
                <Map
                    center={selected || [tiData.lat, tiData.lon]}
                    zoom={15}
                >
                    <MapControl>
                        <FeatureGroup>
                            {nearDtpMarkers}

                            {[tiData.lat, tiData.lon]?.length && (
                                <Marker latlng={[tiData.lat, tiData.lon]}/>
                            )}
                        </FeatureGroup>
                    </MapControl>
                </Map>
            </div>
            <h2 style={{ marginLeft: '1rem' }}>Ближайшие ДТП:</h2>
            {loading && <Loading linear/>}
            {nearDtpList.length 
                ? (
                    <>
                        <TableContainer sx={{ maxHeight: '40vh' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{titles.DATE_TIME_DTP}</TableCell>
                                        <TableCell>{titles.ADDRESS}</TableCell>
                                        <TableCell>Расстояние до события</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {nearDtpList.map((dtp, index) => (
                                        <TableRow 
                                            key={dtp.id} 
                                            style={{ backgroundColor: index % 2 ? 'rgba(155,157,176,0.08)' : 'inherit' }}
                                            onClick={() => setSelected([dtp.lat, dtp.lon])}
                                            sx={{ cursor: 'pointer' }}
                                            tabIndex={-1}
                                        >
                                            <TableCell>
                                                {dtp?.dtp_at
                                                    ? fullDateTimeWithoutSeconds(dtp?.dtp_at)
                                                    : messages.NO_DATA
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {dtp?.address_text || Object.keys(dtp?.address || {}).length > 0
                                                    ? dtp?.address_text || renderAddress(dtp.address)
                                                    : messages.NO_DATA
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {dtp?.distance_to_marker || messages.NO_DATA}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={(e) => setDtpInfo({ anchorEl: e.currentTarget, dtp })}
                                                    size="small"
                                                >
                                                    <i className="far fa-info-circle"/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Popover
                            open={!!dtpInfo}
                            anchorEl={dtpInfo?.anchorEl || null}
                            onClose={() => setDtpInfo(null)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                        >
                            <PopUpDtp dtpData={dtpInfo?.dtp || {}} readOnly/>
                        </Popover>
                    </>
                    
                )
                : !loading && <div>{messages.DATA_IS_NOT_FOUND}</div>
            }
            {openDtpForm && (
                <ModalForm
                    open={openDtpForm}
                    onClose={() => {setOpenDtpForm(false); onClose();}}
                    onAccept={onAcceptDtpForm}
                    data={{ 
                        lat: tiData.lat,
                        lon: tiData.lon,
                        address: tiData.address,
                        address_text: tiData.address_text,
                        type_load: 4,
                        ti_id: tiData.id,         
                        ti_name: tiData.name,         
                    }}
                    isTi
                />
            )}
        </Modal>
    );
};

export default DtpModal;
