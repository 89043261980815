import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { loadDtpTypes } from 'redux/Incidents/actions';
import UniversalSelect from 'components/common/UniversalSelect';
import titles from 'helpers/constants/titles';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

export interface DTPType {
    id: number;
    name: string;
}

type Excludes =
    | 'onReset'
    | 'renderLabel'
    | 'inputName'
    | 'onShowList'
    | 'options'
    | 'filterSelectedOptions'
    | 'isOptionEqualToValue'
    | 'readOnly';

interface TypesProps<T> extends Omit<CommonAutocompleteProps<T>, Excludes> {
    // Здесь можно добавлять пропсы для этого компонента
}

export const Types = ({
    multiple,
    selected = multiple ? [] : null,
    onChange,
    required,
    disabled,
    label = titles.TYPE,
    error,
    helperText = '',
    limitTags = 1,
}: TypesProps<DTPType>) => {
    const dispatch = useDispatch();

    const prevData = useRef(selected);

    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const getFields = () => {
        dispatch(loadDtpTypes());
    };

    const handleClose = () => {
        setShowList(false);
    };

    const handleAccept = (data: DTPType | DTPType[]) => {
        onChange(data);
        setShowList(false);
    };

    const onReset = () => {
        onChange(multiple ? [] : null);
    };

    return (
        <>
            <CommonAutocomplete<DTPType>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                disabled={disabled}
                onReset={onReset}
                renderLabel={(option) => option?.name || ''}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
                isOpen={showList}
            />

            {showList && (
                <UniversalSelect
                    multiple={multiple}
                    fetchList={getFields}
                    storeName="incidents"
                    storeNameProps="dtp_types"
                    keyProp="id"
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div>{el.name}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    small
                    title={titles.SELECT_TYPE}
                />
            )}
        </>
    );
};
