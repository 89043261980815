import React from 'react';

const HeaderColumn = ({
    isActions = false,
    width = 'auto',
    align = 'left',
    children,
}) => (
    <div
        className="title_item"
        style={{
            width,
            textAlign: align,
        }}
    >
        <strong>{children}</strong>
    </div>
);

export default HeaderColumn;