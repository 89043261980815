import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import queryString from 'query-string';
import { Grid } from '@mui/material';

import { clearRoadWorkOrders, loadOrderTypes, loadRoadWorkOrders } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { parseParamsFromUrl } from 'helpers/doris.control/helper';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';

import AddEditModal from './AddEditModal';
import PassportPage from './PassportPage';
import Item from './Item';
import Filters from './Filters';

function Orders() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { search, state } = useLocation();
    const list = useSelector(roadworksSelectors.ordersData);
    const meta = useSelector(roadworksSelectors.ordersMeta);
    const loadingOrders = useSelector(roadworksSelectors.loadingOrders);

    useEffect(() => {
        dispatch(loadOrderTypes());
    }, [dispatch]);

    const [openModal, setOpenModal] = useState(false);
    const [currentOrder, setCurrentOrder] = useState(null);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({
        page: 1,
        data: {
            ...(state?.id && { ids: [state?.id] })
        },
        limit,
    });

    const reloadList = (isDelete) => {
        isDelete && list?.length === 1 && meta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadRoadWorkOrders(params.page, limit, params.data));
    };

    const filterFunc = (params) => {
        const asArray = Object.entries(params).filter(([_, value]) => value);
        return Object.fromEntries(asArray);
    };

    // превращем params в search и добавляем в урл
    const stringifyUrlFunc = (param = {}) => {
        const stringifyUrl = (queryString.stringify(filterFunc(param), { arrayFormat: 'bracket' }));
        if (stringifyUrl) {
            history.replace({ pathname: '/dictionaries/roadworks/orders', search: stringifyUrl });
        }
    };

    const changePage = (page, limit) => {
        setParams({ ...params, page, limit });
        stringifyUrlFunc({ page, limit });
    };

    const handleClick = (item) => {
        const { page, limit } = params;

        if (item.id === currentOrder?.id) {
            stringifyUrlFunc({ page, limit });
            setCurrentOrder(null);
        } else {
            stringifyUrlFunc({ page, limit, order_id: item.id });
            setCurrentOrder(item);
        }
        // stringifyUrlFunc({
        //     ...(!state?.id && { page: params.page }),
        //     ...(!state?.id && { limit }),
        //     ...((!isEqual && !state?.id) && { order_id: item.id })
        //     // state?.id - если мы перешли на конкретную заявку с другой страницы,
        //     // нам не надо менять  url иначе сбросится url state
        // });
    };

    useEffect(() => {
        // в случае если мы перешли с урла или обновился список
        // нам нужно обновить сам currentOrder новыми данными
        const { order_id } = parseParamsFromUrl(search);
        if (list?.length > 0 && order_id) {
            const order = order_id ? list.find(item => item.id === Number(order_id)) : null;
            setCurrentOrder(order);
        }
    }, [list, search]);

    useEffect(() => {
        dispatch(loadRoadWorkOrders(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    useEffect(() => {
        return () => {
            dispatch(clearRoadWorkOrders());
        };
    }, [dispatch]);

    const orderByState = useMemo(() => {
        return state?.id
            ? list?.find(item => item.id === Number(state?.id))
            : {};
    }, [state?.id, list]);

    const isSelected = Boolean(currentOrder?.id);

    const renderContent = () => {
        const renderList = () => (
            <LSContainer
                onSort={(sort) => setParams(prev => ({ ...prev, data: { ...prev.data, sort } }))}
                isMobile={isSelected}
                headers={[
                    { title: '', width: '50px' },
                    { title: titles.NUM, width: '5%', isHide: isSelected },
                    { title: titles.TYPE, width: 'calc(20% - 50px)' },
                    { title: !isSelected ? titles.NAME : '', width: '15%' },
                    {
                        title: titles.DATE_OF_REGISTRATION,
                        width: '12%',
                        isHide: isSelected,
                        options: { sortable: true, field: 'register_at' }
                    },
                    {
                        title: <span>План. дата<br/> начала выполнения</span>,
                        width: '18%' ,
                        isHide: isSelected,
                    },
                    {
                        title: <span>План. дата<br/> окончания выполнения</span>,
                        width: '20%',
                        isHide: isSelected,
                    },
                    { title: titles.ACTIONS, isActions: true },
                ]}
            >
                {list.map(item => (
                    <Item
                        key={item?.id}
                        data={item}
                        onClickItem={() => handleClick(item)}
                        selectedId={item?.id === currentOrder?.id}
                        isSelected={!!currentOrder?.id}
                    />
                ))}
            </LSContainer>
        );

        return (
            <div style={{
                minWidth: '60%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                zIndex: 1,
                ...(state?.id && {
                    marginTop: 20,
                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                    borderRadius: 8
                })
            }}>
                {list?.length > 0
                    ? (
                        <Grid container style={isSelected ? { height: '100%' } : {}}>
                            <Grid item xs={isSelected ? 3 : 12 } style={{ height: '100%' }}>
                                {isSelected
                                    ? (
                                        <Scrollbars style={{ height: '100%' }} >
                                            {renderList()}
                                        </Scrollbars>
                                    )
                                    : renderList()
                                }
                            </Grid>

                            {isSelected && (
                                <Grid item xs={9}>
                                    <PassportPage
                                        currentOrder={currentOrder}
                                        reloadList={reloadList}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )
                    : (!loadingOrders && messages.DATA_IS_NOT_FOUND)
                }
            </div>
        );
    };

    return (
        <>
            <PageLayout
                additionalButtons={<ReturnToButton/>}
                header="Заявки"
                filters={<Filters
                    order={orderByState}
                    setParams={(data) => {
                        setParams(prev => ({ ...prev, page: 1, data }));
                        !state?.id && stringifyUrlFunc({ page: 1, limit });
                    }}
                />}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            positionLeft
                            noPadding
                            buttons={!state?.id
                                ? [{
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                }]
                                : []
                            }
                        />
                    ),
                    total: meta?.total
                }}
                loading={loadingOrders}
                content={renderContent}
                paginationProps={!state?.id && {
                    loadList: changePage,
                    list: meta,
                    limit: limit,
                    setLimit: setLimit,
                }}
                customStyles={{
                    overflowY: currentOrder ? 'hidden' : 'scroll',
                    ...(currentOrder && { padding: 0 })
                }}
            />
            {openModal && (
                <AddEditModal
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    isNew={true}
                    reloadList={reloadList}
                />
            )}
        </>
    );
}

export default Orders;
