import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SketchPicker } from 'react-color';
import { TextField, } from '@mui/material';
import { createThreatLevel, editThreatLevel } from '../../../../../../redux/SituationalPlans/actions';
import Modal from '../../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import titles from '../../../../../../helpers/constants/titles';
import { useValidation } from '../../../../../../helpers/hooks';

const ModalForm = ({ isOpen, onClose, isNew = false, item = {} }) => {
    const dispatch = useDispatch();
    const id = item?.id;

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const [data, setData] = useState({
        name: item.name || '',
        color: item.color || '#000000',
    });

    const closeForm = () => onClose();

    const onAcceptModal = () => {
        isNew
            ? dispatch(createThreatLevel(data))
            : dispatch(editThreatLevel(id, data));
    };

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
        }
    }, [requestStatus.success, onClose, validation]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? titles.CREATE : titles.EDIT}
            noPadding={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: closeForm
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: data.name === '' || data.color === '' || validation.isValidation()
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <div className="row">
                        <i
                            className="fas fa-square-full"
                            style={{ color: data?.color, fontSize: '38px', marginRight: '3px', marginTop: '2px' }}>
                        </i>
                        <TextField
                            className="row__item"
                            value={data.name}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            variant="outlined"
                            size="small"
                            label={titles.NAME}
                            name="name"
                            error={validation.isKey('name')}
                            helperText={validation.get('name')}
                            required
                        />
                    </div>
                </div>
                <SketchPicker
                    color={data.color}
                    onChange={(e) => onChange('color', e.hex)}
                />
            </form>
        </Modal>
    );
};

export default ModalForm;
