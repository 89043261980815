import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadCheckPoints } from 'redux/TransportRoad/actions';
import { transportRoadSelectors } from 'redux/TransportRoad';
import PageLayout from 'components/layout/PageLayout';
import ButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';

import Item from './Item';
import ModalForm from './ModalForm';
import Filter from './Filter';

const CheckPoints = () => {
    const dispatch = useDispatch();

    const data = useSelector(transportRoadSelectors.checkPointsData);
    const meta = useSelector(transportRoadSelectors.checkPointsMeta);
    const loading = useSelector(transportRoadSelectors.checkPointsLoading);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, query: {} });
    const [openAddModal, setOpenAddModal] = useState(false);

    const reloadList = (isDelete) => {
        const page = isDelete && data?.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadCheckPoints(params.page, limit, params.query));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return (
            data?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: 'ID', width: '10%' },
                            { title: titles.NAME, width: '20%' },
                            { title: 'Участок', width: '20%' },
                            { title: 'Адрес участка', width: '30%' },
                            { title: 'Действия', align: 'right', isActions: true }
                        ]}
                    >
                        {data?.map((item) => (
                            <Item
                                key={item.id}
                                item={item}
                                reloadList={reloadList}
                            />
                        ))}
                    </LSContainer>
                )
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <>
            <PageLayout
                header="Контрольные точки"
                filters={<Filter setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <ButtonsComponent 
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenAddModal(true)
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />
            {openAddModal && (
                <ModalForm
                    open={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default CheckPoints;
