import
{ useState } from 'react';
import { useDispatch } from 'react-redux';

import ConfirmDelete from 'components/common/ConfirmModal';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import GeoJSONMap from 'components/common/Location/MapGeoJson';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import CircleStatus from 'components/common/CircleStatus';
import NoValue from 'components/common/List/Components/NoValue';
import {
    editRoadSection,
    deleteRoadSection,
    setEditForm,
} from 'redux/RoadNetwork/actions';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { getHumanDate } from 'helpers/date.config';
import renderAddress from 'helpers/renderAddress';

import Info from './Info';
import EditorForm from './EditorForm';

const Item = ({ item, reloadList, type, category, status = {}, inPrint = false }) => {
    const dispatch = useDispatch();

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [openMap, setOpenMap] = useState(false);
    const [isInfo, setIsInfo] = useState(false);

    const handleCloseEditForm = () => {
        dispatch(setEditForm());
        setEditOpen(false);
    };

    const handleInfo = () => {
        setIsInfo(!isInfo);
    };

    const handleDelete = () => {
        dispatch(deleteRoadSection(item.id, () => {
            reloadList();
            setConfirmOpen(false);
        }));
    };

    return (
        <>
            <Modal
                isOpen={openMap}
                onClose={() => setOpenMap(false)}
                noPadding
                title={item?.name || ''}
                buttons={
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: () => setOpenMap(false)
                            },
                        ]}
                    />
                }
            >
                <GeoJSONMap
                    geometry={item?.geometry}
                    readOnly={true}
                />
            </Modal>
            {confirmOpen && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={confirmOpen}
                    onSuccess={handleDelete}
                    onClose={() => setConfirmOpen(false)}
                />
            )}
            {editOpen && (
                <EditorForm
                    noPadding
                    title={titles.EDIT}
                    isOpen={editOpen}
                    dataForm={item}
                    onClose={handleCloseEditForm}
                    onSuccess={(editValue) => {
                        dispatch(editRoadSection(editValue.id, editValue, () => {
                            reloadList();
                            setEditOpen(false);
                        }));
                    }}
                    inModal
                    isNew={false}
                />
            )}
            <LSContentItem onClick={handleInfo}>
                <LSContentColumn>
                    {inPrint
                        ? status.name
                        : <CircleStatus title={status.name} color={status.color} />
                    }
                </LSContentColumn>
                <LSContentColumn>
                    {item.name || <NoValue />}
                </LSContentColumn>
                <LSContentColumn>
                    {category || <NoValue />}
                </LSContentColumn>
                <LSContentColumn>
                    {type || <NoValue />}
                </LSContentColumn>
                <LSContentColumn>
                    {item.organization?.title || <NoValue />}
                </LSContentColumn>
                <LSContentColumn>
                    {item.address ? renderAddress(item.address) : <NoValue />}
                </LSContentColumn>
                <LSContentColumn>
                    {item.created_at ? getHumanDate(item.created_at) : <NoValue />}
                </LSContentColumn>
                <LSContentColumn>
                    {item.fixated_at ? getHumanDate(new Date(item.fixated_at)) : <NoValue />}
                </LSContentColumn>
                {!inPrint
                    && <LSContentColumn isActions>
                        <ActionsButtons
                            buttons={[
                                {
                                    icon: <i className="fas fa-map-marked"/>,
                                    name: 'Посмотреть на карте',
                                    onClick: () => setOpenMap(true),
                                },
                                {
                                    ...buttonsTypes.editIcon,
                                    onClick: () => setEditOpen(true),
                                    disabled: item.status === 3
                                },
                                {
                                    ...buttonsTypes.deleteIcon,
                                    onClick: () => setConfirmOpen(true),
                                }
                            ]}
                        />
                        <ActionMore
                            isOpen={isInfo}
                            onClick={handleInfo}
                        />
                    </LSContentColumn>
                }
            </LSContentItem>
            {(isInfo && !editOpen) && (
                <Info
                    isOpen={isInfo && !editOpen}
                    data={item}
                />
            )}
        </>
    );
};

export default Item;
