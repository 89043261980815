import { all, put, call, takeLatest } from 'redux-saga/effects';
import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

function* getStatisticSaga({ payload }) {
    yield put(actions.loadingVoteStatistics(true));
    const response = yield call(api.getStatistic, payload);
    if (response) {
        yield put(actions.loadedVoteStatistics(response?.data));
    }
    yield put(actions.loadingVoteStatistics(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_VOTE_STATISTIC, getStatisticSaga),
    ]);
}
