import common from './common';
import routes from './routes';
import company from './company';
import map from './map';

const buttons = {
    ...common,
    ...routes,
    ...company,
    ...map
};

export default buttons;
