import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, } from '@mui/material';

import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { deleteInfrastructure, editInfrastructure } from 'redux/RoadNetwork/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import FieldsModal from 'components/common/Location/FieldsModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import Modal from './Modal';

const Item = ({ el, reloadList }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

    const handleConfirmDelete = () => {
        dispatch(deleteInfrastructure(el.id, () => {
            reloadList();
            setOpenDeleteModal(false);
        }));
    };
    const onSaveData = (data) => {
        const newData = {
            ...removeEmptyFields(data, true),
            repair_objects: data.repair_objects
        };
        dispatch(editInfrastructure(el.id, newData, () => {
            reloadList();
            setOpenEditModal(false);
        }));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {el.name || messages.NO_DATA}
                </LSContentColumn>

                <LSContentColumn>
                    {el.type?.name || messages.NO_DATA}
                </LSContentColumn>

                <LSContentColumn>
                    {el.organization?.id && el.organization.title}
                </LSContentColumn>

                <LSContentColumn>
                    {el?.description && el.description}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <FieldsModal
                        hideButtonAccept={true}
                        title={titles.SEE_ON_MAP}
                        iconButton={
                            <Tooltip title={buttons.SHOW_ON_MAP}>
                                <i className="fas fa-map-marked" />
                            </Tooltip>
                        }
                    >
                        <MapGeoJson
                            geometry={el?.geometry || {}}
                            readOnly={true}
                        />
                    </FieldsModal>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            },
                        ]}
                    />

                </LSContentColumn>
            </LSContentItem>

            {openEditModal && (
                <Modal
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    el={el}
                    onAcceptModal={onSaveData}
                />
            )}

            {isOpenDeleteModal &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={isOpenDeleteModal}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
