import moduleName from './module';

const prefix = `${moduleName}`;

export const GET_POLYGON = `${prefix}/GET_POLYGON`;
export const POLYGON_LOADED = `${prefix}/POLYGON_LOADED`;
export const LOADING_POLYGON = `${prefix}/LOADING_POLYGON`;
export const LOAD_ROADWORKS = `${prefix}/LOAD_ROADWORKS`;
export const LOADING_ROADWORKS = `${prefix}/LOADING_ROADWORKS`;
export const LOADED_ROADWORKS = `${prefix}/LOADED_ROADWORKS`;
export const CREATE_ROADWORK = `${prefix}/CREATE_ROADWORK`;
export const EDIT_ROADWORK = `${prefix}/EDIT_ROADWORK`;
export const DELETE_ROADWORK = `${prefix}/DELETE_ROADWORK`;
export const SAVED = `${prefix}/SAVED`;
export const GET_HISTORY = `${prefix}/GET_HISTORY`;
export const HISTORY_LOADED = `${prefix}/HISTORY_LOADED`;
export const RESET_FILTER = `${prefix}/RESET_FILTER`;
export const FILTERED_LOADED = `${prefix}/FILTERED_LOADED`;
export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;
export const RESET_ACTIVE = `${prefix}/RESET_ACTIVE`;
export const SET_EDIT = `${prefix}/SET_EDIT`;
export const GET_STATUSES = `${prefix}/GET_STATUSES`;
export const STATUSES_LOADED = `${prefix}/STATUSES_LOADED`;
export const LOAD_ORDERS_REPORT = `${prefix}/LOAD/ORDERS/REPORT`;
export const GET_ORDERS = `${prefix}/GET_ORDERS`;
export const ORDERS_LOADED = `${prefix}/ORDERS_LOADED`;
export const GET_ORDERS_REPORT_FILTER = `${prefix}/GET_ORDERS_REPORT_FILTER`;
export const CLEAR_ORDERS_REPORT_FILTER = `${prefix}/CLEAR_ORDERS_REPORT_FILTER`;
export const SET_FILTER = `${prefix}/SET_FILTER`;
export const GET_REPORT = `${prefix}/GET_REPORT`;

export const SET_DELETE_FORM = `${prefix}SET_DELETE_FORM`;
export const SET_EDIT_FORM = `${prefix}SET_EDIT_FORM`;
export const CHANHE_LIST_ITEM = `${prefix}CHANHE_LIST_ITEM`;
export const GET_FILTERED_LIST = `${prefix}/GET_FILTERED_LIST`;

export const LOAD_ROADWORK_ORDERS = `${prefix}/LOAD/ROADWORK/ORDERS`;
export const CREATE_ROADWORK_ORDER = `${prefix}/CREATE/ROADWORK/ORDER`;
export const EDIT_ROADWORK_ORDER = `${prefix}/EDIT/ROADWORK/ORDER`;
export const DELETE_ROADWORK_ORDER = `${prefix}/DELETE/ROADWORK/ORDER`;
export const LOADING_ROADWORK_ORDERS = `${prefix}/LOADING/ROADWORK/ORDERS`;
export const LOADED_ROADWORK_ORDERS = `${prefix}/LOADED/ROADWORK/ORDERS`;
export const CLEAR_ROADWORK_ORDERS = `${prefix}/CLEAR/ROADWORK/ORDERS`;

export const LOAD_ORDER_STATUSES = `${prefix}/LOAD/ORDER/STATUSES`;
export const LOADED_ORDER_STATUSES = `${prefix}/LOADED/ORDER/STATUSES`;
export const LOAD_ORDER_DIRECTIONS = `${prefix}/LOAD/ORDER/DIRECTIONS`;
export const LOADED_ORDER_DIRECTIONS = `${prefix}/LOADED/ORDER/DIRECTIONS`;
//STAGES
export const LOAD_STAGES_STATUSES = `${prefix}/LOAD/STAGES/STATUSES`;
export const LOADED_STAGES_STATUSES = `${prefix}/LOADED/STAGES/STATUSES`;
export const LOAD_STAGE_CARGO_TYPES = `${prefix}/LOAD/STAGE/CARGO/TYPES`;
export const LOADED_STAGE_CARGO_TYPES = `${prefix}/LOADED/STAGE/CARGO/TYPES`;
export const LOAD_STAGES_LIST = `${prefix}/LOAD/STAGES/LIST`;
export const LOADING_STAGES_LIST = `${prefix}/LOADING/STAGES/LIST`;
export const LOADED_STAGES_LIST = `${prefix}/LOADED/STAGES/LIST`;
export const CREATE_STAGE = `${prefix}/CREATE/STAGE`;
export const EDIT_SEQUENCE_STAGES = `${prefix}/EDIT/SEQUENCE/STAGES`;
export const EDIT_STAGE = `${prefix}/EDIT/STAGE`;
export const DELETE_STAGE = `${prefix}/DELETE/STAGE`;
// DEFECT_GROUPS
export const LOAD_DEFECT_GROUP = `${prefix}/LOAD/ROAD/INFRASTRUCTURE/SHORTCOMINGS`;
export const LOADED_DEFECT_GROUP = `${prefix}/LOADED/ROAD/INFRASTRUCTURE/SHORTCOMINGS`;
export const CREATE_DEFECT_GROUP = `${prefix}/CREATE/DEFECT/GROUP`;
export const EDIT_DEFECT_GROUP = `${prefix}/EDIT/DEFECT/GROUP`;
export const DELETE_DEFECT_GROUP = `${prefix}/DELETE/DEFECT/GROUP`;
// DEFECT_TYPES
export const LOAD_DEFECT_TYPES = `${prefix}/LOAD/DEFECT/TYPES`;
export const LOADING_DEFECT_TYPES = `${prefix}/LOADING/DEFECT/TYPES`;
export const LOADED_DEFECT_TYPES = `${prefix}/LOADED/DEFECT/TYPES`;
export const CREATE_DEFECT_TYPES = `${prefix}/CREATE/DEFECT/TYPES`;
export const EDIT_DEFECT_TYPES = `${prefix}/EDIT/DEFECT/TYPES`;
export const DELETE_DEFECT_TYPES = `${prefix}/DELETE/DEFECT/TYPES`;

//DEFECTIONS
export const LOAD_TYPE_OF_ROAD_DEFECTIONS = `${prefix}/LOAD_TYPE_OF_ROAD_DEFECTIONS`;
export const LOADED_TYPE_OF_ROAD_DEFECTIONS = `${prefix}/LOADED_TYPE_OF_ROAD_DEFECTIONS`;
export const LOADING_TYPE_OF_ROAD_DEFECTIONS = `${prefix}/LOADING_TYPE_OF_ROAD_DEFECTIONS`;

export const LOAD_TYPE_CREATION = `${prefix}/LOAD_TYPE_CREATION`;
export const LOADED_TYPE_CREATION = `${prefix}/LOADED_TYPE_CREATION`;

//UNITS
export const LOAD_UNITS = `${prefix}/LOAD_UNITS`;
export const LOADED_UNITS = `${prefix}/LOADED_UNITS`;
export const LOADING_UNITS = `${prefix}/LOADING_UNITS`;
export const DELETE_UNIT = `${prefix}/DELETE_UNIT`;
export const EDIT_UNIT = `${prefix}/EDIT_UNIT`;
export const CREATE_UNIT = `${prefix}/CREATE_UNIT`;

// GROUPS
export const LOAD_ROADWORKS_GROUPS = `${prefix}/LOAD/ROADWORKS/GROUPS`;
export const LOADING_ROADWORKS_GROUPS = `${prefix}/LOADING/ROADWORKS/GROUPS`;
export const LOADED_ROADWORKS_GROUPS = `${prefix}/LOADED/ROADWORKS/GROUPS`;
export const CREATE_ROADWORKS_GROUP = `${prefix}/CREATE/ROADWORKS/GROUP`;
export const EDIT_ROADWORKS_GROUP = `${prefix}/EDIT/ROADWORKS/GROUP`;
export const DELETE_ROADWORKS_GROUP = `${prefix}/DELETE/ROADWORKS/GROUP`;

export const SET_ROADWORKS_WS = `${prefix}/SET_ROADWORKS_WS`;

// WORKS
export const LOAD_WORKS = `${prefix}/LOAD/WORKS`;
export const LOADING_WORKS = `${prefix}/LOADING/WORKS`;
export const LOADED_WORKS = `${prefix}/LOADED/WORKS`;
export const CREATE_WORK = `${prefix}/CREATE/WORK`;
export const EDIT_WORK = `${prefix}/EDIT/WORK`;
export const DELETE_WORK = `${prefix}/DELETE/WORK`;

export const SET_SIDEBAR_PARAMS = `${prefix}/SET_SIDEBAR_PARAMS`;

export const STAGE_SYNC_ROADWORK = `${prefix}/STAGE/SYNC/ROADWORK`;
export const STAGE_SYNC_ROADWORK_ARRAY = `${prefix}/STAGE/SYNC/ROADWORK/ARRAY`;

export const GET_CONSOLIDATE_REPORT = `${prefix}/GET_CONSOLIDATE_REPORT`;

export const GET_STAGE_SCHEDULE = `${prefix}/GET_STAGE_SCHEDULE`;
export const LOADING_STAGE_SCHEDULE = `${prefix}/LOADING_STAGE_SCHEDULE`;
export const CREATE_STAGE_SCHEDULE = `${prefix}/CREATE_STAGE_SCHEDULE`;
export const LOADED_STAGE_SCHEDULE = `${prefix}/LOADED_STAGE_SCHEDULE`;

export const GET_KDM = `${prefix}/GET_KDM`;
export const LOADED_KDM = `${prefix}/LOADED_KDM`;
export const GET_KDM_REPORT = `${prefix}/GET_KDM_REPORT`;

export const LOAD_PLAN_FACT_REPORT = `${prefix}/LOAD/PLAN/FACT/REPORT`;
export const LOADED_PLAN_FACT_REPORT = `${prefix}/LOADED/PLAN/FACT/REPORT`;
export const LOADING_PLAN_FACT_REPORT = `${prefix}/LOADING/PLAN/FACT/REPORT`;


export const LOAD_DEFECTS = `${prefix}/LOAD_DEFECTS`;
export const LOADED_DEFECTS = `${prefix}/LOADED_DEFECTS`;
export const LOAD_DEFECTS_SIDEBAR = `${prefix}/LOAD_DEFECTS_SIDEBAR`;
export const LOADED_DEFECTS_SIDEBAR = `${prefix}/LOADED_DEFECTS_SIDEBAR`;
export const LOADING_DEFECTS_SIDEBAR = `${prefix}/LOADING_DEFECTS_SIDEBAR`;
export const LOAD_DEFECTS_POLYGON = `${prefix}/LOAD_DEFECTS_POLYGON`;
export const LOADED_DEFECTS_POLYGON = `${prefix}/LOADED_DEFECTS_POLYGON`;
export const CLEAR_DEFECTS_POLYGON = `${prefix}/CLEAR_DEFECTS_POLYGON`;
export const CREATE_DEFECT = `${prefix}/CREATE_DEFECT`;
export const EDIT_DEFECT = `${prefix}/EDIT_DEFECT`;
export const DELETE_DEFECT = `${prefix}/DELETE_DEFECT`;
export const SET_DEFECTS_FILTER = `${prefix}/SET_DEFECTS_FILTER`;
export const CLEAR_DEFECTS_FILTER = `${prefix}/CLEAR_DEFECTS_FILTER`;
export const SET_DEFECTS_ACTIVE = `${prefix}/SET_DEFECTS_ACTIVE`;
export const LOADING_DEFECTS_POLYGON = `${prefix}/LOADING_DEFECTS_POLYGON`;
export const SET_DELETE_FORM_DEFECTS = `${prefix}/SET_DELETE_FORM_DEFECTS`;
export const SET_EDIT_FORM_DEFECTS = `${prefix}/SET_EDIT_FORM_DEFECTS`;
export const DEFECTS_SAVED = `${prefix}/DEFECTS_SAVED`;
export const LOADING_DEFECTS = `${prefix}/LOADING_DEFECTS`;

export const LOAD_DEFECT_HEAT_MAP = `${prefix}/LOAD_DEFECT_HEAT_MAP`;
export const LOADED_DEFECT_HEAT_MAP = `${prefix}/LOADED_DEFECT_HEAT_MAP`;
export const LOADING_DEFECT_HEAT_MAP = `${prefix}/LOADING_DEFECT_HEAT_MAP`;
export const FILTER_DEFECT_HEAT_MAP = `${prefix}/FILTER_DEFECT_HEAT_MAP`;

export const LOAD_DEFECT_STATUSES = `${prefix}/LOAD_DEFECT_STATUSES`;
export const LOADED_DEFECT_STATUSES = `${prefix}/LOADED_DEFECT_STATUSES`;

export const LOAD_NEAR_DEFECTS = `${prefix}LOAD_NEAR_DEFECTS`;
export const LOADED_NEAR_DEFECTS = `${prefix}LOADED_NEAR_DEFECTS`;

export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;


//TRANSPORT_SETTINGS
export const LOAD_SETTINGS = `${prefix}LOAD_SETTINGS`;
export const LOADING_SETTINGS = `${prefix}LOADING_SETTINGS`;
export const LOADED_SETTINGS = `${prefix}LOADED_SETTINGS`;
export const EDIT_SETTING = `${prefix}/EDIT_SETTING`;
export const EDIT_SETTINGS_LIST = `${prefix}/EDIT_SETTINGS_LIST`;

//SHOWCASE
export const LOAD_SHOWCASE = `${prefix}/LOAD_SHOWCASE`;
export const LOADING_SHOWCASE = `${prefix}/LOADING_SHOWCASE`;
export const LOADED_SHOWCASE = `${prefix}/LOADED_SHOWCASE`;

// SETTINGS GROUP
export const LOAD_SETTINGS_GROUP = `${prefix}/LOAD_SETTINGS_GROUP`;
export const LOADING_SETTINGS_GROUP = `${prefix}/LOADING_SETTINGS_GROUP`;
export const LOADED_SETTINGS_GROUP = `${prefix}/LOADED_SETTINGS_GROUP`;
export const EDIT_SETTINGS_GROUP = `${prefix}/EDIT_SETTINGS_GROUP`;

// ORDER TYPES
export const LOAD_ORDER_TYPES = `${prefix}/LOAD/ORDER/TYPES`;
export const LOADING_ORDER_TYPES = `${prefix}/LOADING/ORDER/TYPES`;
export const LOADED_ORDER_TYPES = `${prefix}/LOADED/ORDER/TYPES`;
export const CREATE_ORDER_TYPE = `${prefix}/CREATE/ORDER/TYPE`;
export const EDIT_ORDER_TYPE = `${prefix}/EDIT/ORDER/TYPE`;
export const DELETE_ORDER_TYPE = `${prefix}/DELETE/ORDER/TYPE`;

export const LOAD_NEAR_ROAD_WORKS = `${prefix}LOAD_NEAR_ROAD_WORKS`;
export const LOADING_NEAR_ROAD_WORKS = `${prefix}LOADING_NEAR_ROAD_WORKS`;
export const LOADED_NEAR_ROAD_WORKS = `${prefix}LOADED_NEAR_ROAD_WORKS`;

export const LOAD_REPAIR_OBJECT_INDICATORS = `${prefix}LOAD_REPAIR_OBJECT_INDICATORS`;
export const LOADING_REPAIR_OBJECT_INDICATORS = `${prefix}LOADING_REPAIR_OBJECT_INDICATORS`;
export const LOADED_REPAIR_OBJECT_INDICATORS = `${prefix}LOADED_REPAIR_OBJECT_INDICATORS`;
export const CREATE_REPAIR_OBJECT_INDICATOR = `${prefix}/CREATE_REPAIR_OBJECT_INDICATOR`;
export const EDIT_REPAIR_OBJECT_INDICATOR = `${prefix}/EDIT_REPAIR_OBJECT_INDICATOR`;
export const DELETE_REPAIR_OBJECT_INDICATOR = `${prefix}/DELETE_REPAIR_OBJECT_INDICATOR`;

// REPAIR OBJECTS
export const LOAD_REPAIR_OBJECTS = `${prefix}/LOAD_REPAIR_OBJECTS`;
export const LOADING_REPAIR_OBJECTS = `${prefix}/LOADING_REPAIR_OBJECTS`;
export const LOADED_REPAIR_OBJECTS = `${prefix}/LOADED_REPAIR_OBJECTS`;
export const CREATE_REPAIR_OBJECT = `${prefix}/CREATE_REPAIR_OBJECT`;
export const EDIT_REPAIR_OBJECT = `${prefix}/EDIT_REPAIR_OBJECT`;
export const DELETE_REPAIR_OBJECT = `${prefix}/DELETE_REPAIR_OBJECT`;

// REPAIR FREQUENCY
export const LOAD_REPAIR_FREQUENCY = `${prefix}/LOAD_REPAIR_FREQUENCY`;
export const LOADING_REPAIR_FREQUENCY = `${prefix}/LOADING_REPAIR_FREQUENCY`;
export const LOADED_REPAIR_FREQUENCY = `${prefix}/LOADED_REPAIR_FREQUENCY`;

// REPAIR TYPES
export const LOAD_REPAIR_TYPES = `${prefix}/LOAD_REPAIR_TYPES`;
export const LOADING_REPAIR_TYPES = `${prefix}/LOADING_REPAIR_TYPES`;
export const LOADED_REPAIR_TYPES = `${prefix}/LOADED_REPAIR_TYPES`;

export const LOAD_SHEETS_ASSESSMENT_ROAD = `${prefix}LOAD_SHEETS_ASSESSMENT_ROAD`;
export const LOADING_SHEETS_ASSESSMENT_ROAD = `${prefix}LOADING_SHEETS_ASSESSMENT_ROAD`;
export const LOADED_SHEETS_ASSESSMENT_ROAD = `${prefix}LOADED_SHEETS_ASSESSMENT_ROAD`;
export const CREATE_SHEETS_ASSESSMENT_ROAD = `${prefix}CREATE_SHEETS_ASSESSMENT_ROAD`;
export const EDIT_SHEETS_ASSESSMENT_ROAD = `${prefix}EDIT_SHEETS_ASSESSMENT_ROAD`;
export const DELETE_SHEETS_ASSESSMENT_ROAD = `${prefix}DELETE_SHEETS_ASSESSMENT_ROAD`;
export const LOADING_SHEETS_ASSESSMENT_ROAD_FORM = `${prefix}LOADING_SHEETS_ASSESSMENT_ROAD_FORM`;

export const LOAD_ROAD_WORKS_COLORS = `${prefix}LOAD_ROAD_WORKS_COLORS`;
export const LOADED_ROAD_WORKS_COLORS = `${prefix}LOADED_ROAD_WORKS_COLORS`;

export const LOAD_SHOWCASE_ORDERS = `${prefix}LOAD_SHOWCASE_ORDERS`;
export const LOADING_SHOWCASE_ORDERS = `${prefix}LOADING_SHOWCASE_ORDERS`;
export const LOADED_SHOWCASE_ORDERS = `${prefix}LOADED_SHOWCASE_ORDERS`;

export const LOAD_SHOWCASE_ORDERS_REPORT = `${prefix}LOAD_SHOWCASE_ORDERS_REPORT`;
export const LOADING_SHOWCASE_ORDERS_REPORT = `${prefix}LOADING_SHOWCASE_ORDERS_REPORT`;
export const LOADED_SHOWCASE_ORDERS_REPORT = `${prefix}LOADED_SHOWCASE_ORDERS_REPORT`;

export const LOAD_ROAR_WORKS_REPORT_ORDERS = `${prefix}LOAD_ROAR_WORKS_REPORT_ORDERS`;
export const LOADED_ROAR_WORKS_REPORT_ORDERS = `${prefix}LOADED_ROAR_WORKS_REPORT_ORDERS`;
export const LOADING_ROAR_WORKS_REPORT_ORDERS = `${prefix}LOADING_ROAR_WORKS_REPORT_ORDERS`;

export const LOAD_PLAN_FACT_REPORT_TYPES = `${prefix}LOAD_PLAN_FACT_REPORT_TYPES`;
export const LOADED_PLAN_FACT_REPORT_TYPES = `${prefix}LOADED_PLAN_FACT_REPORT_TYPES`;

export const LOAD_RW_ORGANIZATIONS_BY_ORDER_ID = `${prefix}LOAD_RW_ORGANIZATIONS_BY_ORDER_ID`;

