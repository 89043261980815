import { all, put, call, takeLatest } from 'redux-saga/effects';
import api from './api.methods';
import * as types from './types';
import * as actions from './actions';
import { showMessage } from '../Message/actions';
import messages from '../../helpers/constants/messages';
import messagetypes from '../Message/messagetypes';
import { messageHelper } from '../../helpers/messageHelper';


function* getList({ payload }) {
    yield put(actions.loadingList(true));
    const response = yield call(api.getList, payload);
    if (response) {
        yield put(actions.loadedList(response));
    }
    yield put(actions.loadingList(false));
}

function* getFormats({ payload }) {
    const response = yield call(api.getFormats, payload);
    if (response?.data) {
        yield put(actions.loadedFormats(response.data));
    }
}
function* getFormatsNewSaga({ payload }) {
    const response = yield call(api.getFormatsNew, payload);
    if (response?.data) {
        const data = Array.isArray(response.data)
            ? response.data.reduce((res, el) => {
                res[el] = el;
                return res;
            }, {})
            : response.data;
        yield put(actions.loadedFormats(data));
    }
}

function* getTemplates() {
    const response = yield call(api.getTemplates);
    if (response?.data) {
        yield put(actions.loadedTemplates(response.data));
    }
}

function* deleteReportSaga({ payload, callbackSuccess }) {
    yield put(actions.loadingList(true));
    const response = yield call(api.deleteReport, payload);
    if (response.success) {
        callbackSuccess && callbackSuccess();
    }
    yield put(actions.loadingList(false));
}

function* deleteAllReportsSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.deleteAllReports, payload);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
    }

    yield put(actions.loadingButton(false));
}

function* loadScheduledReportSaga({ payload }) {
    yield put(actions.loadingScheduledReport(true));
    const response = yield call(api.loadScheduledReport, payload);
    if (response?.success) {
        yield put(actions.loadedScheduledReport(response));
    }
    yield put(actions.loadingScheduledReport(false));
}

function* createScheduledReportSaga({ payload, callback }) {
    yield put(actions.loadingScheduledReportForm(true));
    const response = yield call(api.createScheduledReport, payload);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
    yield put(actions.loadingScheduledReportForm(false));
}

function* editScheduledReportSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingScheduledReportForm(true));
    const response = yield call(api.editScheduledReport, id, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
    yield put(actions.loadingScheduledReportForm(false));
}

function* deleteScheduledReportSaga({ payload, callback }) {
    yield put(actions.loadingScheduledReportForm(true));
    const response = yield call(api.deleteScheduledReport, payload);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
    }
    yield put(actions.loadingScheduledReportForm(false));
}

function* loadScheduledReportTemplateSaga() {
    const response = yield call(api.loadScheduledReportTemplate);
    if (response?.success) {
        yield put(actions.loadedScheduledReportTemplate(response.data));
    }
}

function* loadPuidTFIndicatorsSaga({ payload }) {
    yield put(actions.loadingPuid(true));
    const response = yield call(api.loadPuidTFIndicators, payload);
    if (response?.success) {
        yield messageHelper(response);
    }
    yield put(actions.loadingPuid(false));
}

function* loadPuidTFIndicatorsConsolidateSaga({ payload }) {
    yield put(actions.loadingPuid(true));
    const response = yield call(api.loadPuidTFIndicatorsConsolidate, payload);
    if (response?.success) {
        yield messageHelper(response);
    }
    yield put(actions.loadingPuid(false));
}

function* loadReportTemplateFrontendLinksSaga() {
    yield put(actions.loadingReportTemplateFrontendLinks(true));
    const response = yield call(api.loadReportTemplateFrontendLinks);
    if (response?.success) {
        yield put(actions.loadedReportTemplateFrontendLinks(response?.data || {}));
    }
    yield put(actions.loadingReportTemplateFrontendLinks(false));
}

function* loadApiReportTemplateFrontendLinkSaga({ payload, callback }) {
    const {
        url,
        filter,
        formats
    } = payload;
    yield put(actions.loadingApiReportReportTemplateFrontendLink(true));
    const response = yield call(api.loadApiReportTemplateFrontendLinkSaga, url, { ...filter, formats });
    if (response?.success) {
        yield messageHelper(response);
        yield callback?.();
    }
    yield put(actions.loadingApiReportReportTemplateFrontendLink(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_LIST, getList),
        takeLatest(types.LOAD_FORMATS, getFormats),
        takeLatest(types.LOAD_FORMATS_NEW, getFormatsNewSaga),
        takeLatest(types.LOAD_TEMPLATES, getTemplates),
        takeLatest(types.DELETE_REPORT, deleteReportSaga),
        takeLatest(types.DELETE_ALL_REPORTS, deleteAllReportsSaga),

        takeLatest(types.LOAD_SCHEDULE_REPORT, loadScheduledReportSaga),
        takeLatest(types.CREATE_SCHEDULE_REPORT, createScheduledReportSaga),
        takeLatest(types.EDIT_SCHEDULE_REPORT, editScheduledReportSaga),
        takeLatest(types.DELETE_SCHEDULE_REPORT, deleteScheduledReportSaga),
        takeLatest(types.LOAD_SCHEDULE_REPORT_TEMPLATE, loadScheduledReportTemplateSaga),

        takeLatest(types.LOAD_PUID_TF_INDICATORS, loadPuidTFIndicatorsSaga),
        takeLatest(types.LOAD_PUID_TF_INDICATORS_CONSOLIDATE, loadPuidTFIndicatorsConsolidateSaga),

        takeLatest(types.LOAD_REPORT_TEMPLATE_FRONTEND_LINKS, loadReportTemplateFrontendLinksSaga),
        takeLatest(types.LOAD_API_REPORT_TEMPLATE_FRONTEND_LINK, loadApiReportTemplateFrontendLinkSaga),
    ]);
}
