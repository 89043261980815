import { useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import * as actions from 'redux/TransportSpecial/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import ReportModal from 'components/common/Transport/ReportModal/ReportModal';
import SelectDateHistory from 'components/common/Transport/TransportHistory/SelectDateHistory';
import {
    LSContentItem,
    LSContentColumn,
    ActionsButtons,
    ActionMore,
} from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import AddEditModal from './AddEditModal';
import RegistrationCard from './RegistrationCard';
import VehiclesInfo from './VehiclesInfo';

const Item = ({ item, reloadList, categories }) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [showHistoryRange, setShowHistoryRange] = useState(false);

    const [isOpenRegistrationCard, setIsOpenRegistrationCard] = useState(false);
    const [openReportModal, setOpenReportModal] = useState(false);

    const toggleOpenDelModal = (e) => {
        e.stopPropagation();
        setOpenDeleteModal(!openDeleteModal);
    };

    const handleDeleteVehicle = () => {
        dispatch(actions.deleteVehicle(item.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    const openRegistrationCard = (e) => {
        e.stopPropagation();
        setIsOpenRegistrationCard(true);
    };

    const closeRegistrationCard = () => {
        setIsOpenRegistrationCard(false);
    };

    const titleHistory = () => {
        const title = [];
        const currentCategory = categories?.find(({ id }) => id === item?.category_id);
        const categoryName = currentCategory?.name || null;
        if (categoryName) {
            title.push(categoryName);

            const garage_number = item?.garage_number || null;
            if (garage_number) {
                title.push(`номер ${garage_number}`);
            }
        } else if (item?.uid) {
            title.push(item?.uid);
        }
        return title.join(', ');
    };

    return (
        <>
            <LSContentItem onClick={() => setOpen(prev => !prev)} >
                <LSContentColumn>
                    {item?.number || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.brand_name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.model_name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.historyIcon,
                                name: 'История перемещений',
                                onClick: () => setShowHistoryRange(true)
                            },
                            {
                                ...buttonsTypes.reportIcon,
                                onClick: () => setOpenReportModal(true),
                            },
                            // {
                            //     icon: <i className="fas fa-clipboard"/>,       Attention: не удалять заблокировано по задаче
                            //     name: 'Учетная карточка ТС',
                            //     onClick: openRegistrationCard,
                            // },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: toggleOpenDelModal,
                            }
                        ]}
                    />
                    <ActionMore isOpen={open} />
                </LSContentColumn>
            </LSContentItem>

            {showHistoryRange
                && <SelectDateHistory
                    uuid={item?.id}
                    title={titleHistory()}
                    isIconButton
                    actions={{
                        storeProp: 'transportSpecial',
                        history: {
                            action: actions.loadVehicleHistoryModal,
                            clear: actions.clearVehicleHistoryModal,
                            loadingProp: 'historyModalLoading',
                            dataProp: 'historyModal',
                        },
                        speedList: {
                            action: actions.loadColorSpeed,
                            dataProp: 'colorSpeed',
                        }
                    }}
                    needButton={false}
                    isOpen={showHistoryRange}
                    onClose={() => setShowHistoryRange(false)}
                />
            }

            {/* {isOpenRegistrationCard && (         Attention: не удалять заблокировано по задаче
                <RegistrationCard
                    isOpen={isOpenRegistrationCard}
                    onClose={closeRegistrationCard}
                    item={item}
                    categories={categories}
                />
            )} */}

            {openEditModal && (
                <AddEditModal
                    open={openEditModal}
                    item={item}
                    onClose={() => setOpenEditModal(false)}
                    reloadList={reloadList}
                />
            )}

            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onClose={toggleOpenDelModal}
                    onSuccess={handleDeleteVehicle}
                    message={messages.CONFIRM_DELETE}
                />
            )}

            {open && (
                <VehiclesInfo
                    isOpen={open}
                    info={item}
                />
            )}

            {openReportModal && (
                <ReportModal
                    open={openReportModal}
                    onClose={() => setOpenReportModal(false)}
                    item={item}
                    urlTemplate="/dictionaries/transport-special/vehicles"
                />
            )}
        </>
    );
};

export default Item;
