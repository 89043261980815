import { useMemo } from 'react';

import useStoreFromSelector from './useStoreFromSelector';

// селектор для статусов и типов формата { id, name, color, ... }
const useStoreFromSelectorListToObject = (
    // запрос на получение данных
    fetchAction,
    // селектор (данные - массив объектов типа { id, name, color, ... })
    selector,
    // итератор для ключа
    iterator = i => i?.id,
) => {
    const data = useStoreFromSelector(fetchAction, selector);

    const toObject = useMemo(() => {
        if (Array.isArray(data)) {
            return data
                ?.reduce((res, item) => ({
                    ...res,
                    [iterator(item)]: item,
                }), {});
        }
        return {};
    }, [data, iterator]);

    return {
        // изначальный список
        list: data,
        // список объектом (ключ берется из функции iterator)
        listObject: toObject,
        // получить элемент по id
        get: (key) => toObject[key] || {},
    };
};

export default useStoreFromSelectorListToObject;
