import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    Checkbox,
} from '@mui/material';

import {
    loadCameraReportGroups,
    loadCameraReportTypes,
} from 'redux/VideoAnalytics/actions';
import { menuSelectors } from 'redux/Menu';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import {
    fullDateTimeWithoutTimeZone,
    getDateWithDuration,
} from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import DateRange from 'components/common/Dates/DateRange';
import SelectCameras from 'components/common/Autocomplete/CameraVideoanalytics';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import { renderError } from 'components/common/PuidCommonFilter/helpers';

import { week, initialState, typeOptions } from './objects';

function Filters({
    setParams,
    setReportId,
    resetParams,
}) {
    const reportGroups = useStoreProp(loadCameraReportGroups, 'videoAnalytics', 'reportGroups');
    const reportTypes = useStoreProp(loadCameraReportTypes, 'videoAnalytics', 'reportTypes');
    const showReturnTo = useSelector(menuSelectors.showReturnTo);

    const [formValues, setFormValues] = useState(initialState);

    const disableRequestBtn = () => {
        const result = paramsWithoutEmpty(formValues);
        const {
            type,
            group,
            start_date,
            end_date,
            camera_id = [],
            week_day,
            parameters = []
        } = result;

        return !type
            || !group
            || !start_date
            || !end_date
            || camera_id?.length === 0
            || (parameters.length === 3 && !week_day)
            || renderError(formValues, 'start_date', 'end_date', parameters, reportTypes)?.error;
    };

    const handleChangeSelects = (e) => {
        const { value, name } = e.target;
        const currentObj = {
            ...formValues,
            [name]: value,
            ...(name === 'group' && value === 'dtp' && { with_line: false }),
        };
        setFormValues(currentObj);
        if(name === 'group'){
            switch(value) {
                case 'intensity':
                    return setReportId(5);
                case 'speed':
                    return setReportId(6);
                case 'dtp':
                    return setReportId(7);
                default:
                    return setReportId('');
            }
        }
    };

    const handleChangeType = (e) => {
        const { value, name } = e.target;
        const type = reportTypes.find(el => el.value === value);
        const currentObj = {
            ...formValues,
            [name]: value,
            parameters: type?.parameters || [],
        };
        setFormValues(currentObj);
    };

    const handleChangeStartDate = (value) => {
        const addDate = typeOptions[formValues.type];
        const { count, unit } = addDate;
        // console.log('count, unit ', count, unit );
        const currentObj = {
            ...formValues,
            start_date: value,
            end_date: getDateWithDuration({ [unit]: count, second: -1 }, value),
        };
        setFormValues(currentObj);
    };

    const handleChangeEndDate = (value) => setFormValues({ ...formValues, end_date: value });

    const handleChangeCamera = (values) => {
        const currentObj = {
            ...formValues,
            cameras: values,
            camera_id: values.map((el) => el.id)
        };
        setFormValues(currentObj);
    };

    const resetFilters = (needReload) => {
        setFormValues(initialState);
        if (needReload) {
            resetParams();
        }
    };

    const handleChangeWithLine = (event) => {
        setFormValues({ ...formValues, with_line: event.target.checked });
    };


    const updateFilter = (filters) => {
        setFormValues({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            handleSearch({
                ...initialState,
                ...filters,
            });
        }
    };

    const paramsWithoutEmpty = (data = formValues) => {
        const result = {
            ...data,
            start_date: fullDateTimeWithoutTimeZone(data.start_date),
            end_date: fullDateTimeWithoutTimeZone(data.end_date),
            week_day: data.parameters.length > 2 ? data.week_day : '',
        };
        return removeEmptyFields(result);
    };
    const handleSearch = (data) => {
        setParams(paramsWithoutEmpty(data));
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={handleSearch}
            filter={formValues}
            filterException={['start_date', 'end_date']}
            countException={['cameras', 'parameters']}
            setUserFilter={updateFilter}
            disabled={disableRequestBtn()}
            isFilterVisible
        >
            <LayoutFilter.Visible>
                <div>
                    <SelectCameras
                        multiple
                        selected={formValues.cameras}
                        onChange={handleChangeCamera}
                        required
                        error={ !!showReturnTo?.link && formValues.cameras.length === 0 }
                    />
                </div>
                <FormControl variant="outlined" size="small">
                    <InputLabel>Вид отчета</InputLabel>
                    <Select
                        label="Вид отчета"
                        name="group"
                        value={formValues.group}
                        onChange={handleChangeSelects}
                    >
                        {reportGroups.length > 0
                            ? reportGroups.map(item =>
                                <MenuItem key={item.key} value={item.value}>
                                    {item.description}
                                </MenuItem>
                            )
                            : <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>
                        }
                    </Select>
                </FormControl>
                <FormControl variant="outlined" size="small">
                    <InputLabel>Тип отчета</InputLabel>
                    <Select
                        label="Тип отчета"
                        name="type"
                        value={formValues.type}
                        onChange={handleChangeType}
                    >
                        {reportTypes.length > 0
                            ? reportTypes.map(item => {
                                const { description, hint, value, key } = item;
                                return (
                                    <MenuItem
                                        key={key}
                                        value={value}
                                    >
                                        {description}({hint})
                                    </MenuItem>
                                );
                            })
                            : <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                {
                    ( (formValues.parameters.includes('start_date') && formValues.parameters.includes('end_date'))
                    || formValues.parameters.includes('week_day'))
                    && <>
                        {formValues.parameters.includes('start_date') && formValues.parameters.includes('end_date')
                            && <DateRange
                                // pickersFormat={dateFormat.DATE_WITH_HOUR_MIN_SECONDS} FULL_DATE
                                // views={['day', 'hours', 'minutes', 'seconds']}
                                valueStartDate={formValues.start_date}
                                valueEndDate={formValues.end_date}
                                handleDate={handleChangeStartDate}
                                handleEndDate={handleChangeEndDate}
                                renderError={() => renderError(formValues, 'start_date', 'end_date', formValues.parameters, reportTypes)?.error}
                                errorMessage={() => renderError(formValues, 'start_date', 'end_date', formValues.parameters, reportTypes)?.message}
                            />
                        }
                        {formValues.parameters.includes('week_day')
                            && <FormControl variant="outlined" size="small">
                                <InputLabel>День недели</InputLabel>
                                <Select
                                    label="День недели"
                                    name="week_day"
                                    value={formValues.week_day}
                                    onChange={handleChangeSelects}
                                >
                                    {Object.keys(week).map(key =>
                                        <MenuItem key={key} value={key}>
                                            {week[key]}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        }
                    </>
                }
                {formValues.group !== 'dtp'
                    && <div className="grid-column-1-3">
                        <FormControlLabel
                            style={{ pointerEvents: 'none' }}
                            control={
                                <Checkbox
                                    checked={formValues.with_line}
                                    onChange={handleChangeWithLine}
                                    name="with_line"
                                    color="primary"
                                    style={{ pointerEvents: 'auto' }}
                                />
                            }
                            label="Показать разделение по полосам"
                        />
                    </div>
                }
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
}

export default Filters;
