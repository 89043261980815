import React, { useEffect } from 'react';
import {
    Grid,
    Paper,
    LinearProgress,
    Typography,
} from '@mui/material';
import Layer from '../../../MapComponents/Layers/Incidents/layer';
import layerConfig from '../../../MapComponents/Layers/Incidents/config';
import CardWidget from '../../../common/Showcase/CardWidget';
import {
    loadShowcase,
    clearShowcase,
} from '../../../../redux/Incidents/actions';
import { incidentsSelectors } from '../../../../redux/Incidents';
import { useDispatch, useSelector } from 'react-redux';
import TypeComponent from '../../../common/Showcase/TypeComponent';
import Table from '../../Reports/Efficiency/DTPReport/TabComponents/DtpByPeriod/Table';
import Legend from '../../Reports/Efficiency/DTPReport/TabComponents/DtpByPeriod/Legend';
import GraphicLineBar from '../../Reports/Efficiency/DTPReport/TabComponents/DtpGraph/GraphicLineBar';
import Graph from '../../Reports/DTP/DtpByArea/Graph';
import CardMap from '../../../common/Showcase/CardMap';

// страница
const Page = () => {
    const dispatch = useDispatch();

    const showCase = useSelector(incidentsSelectors.showcase);
    const loadingShowcase = useSelector(incidentsSelectors.loadingShowcase);
    const {
        graph = [],
        table = [],
    } = showCase;
    const list = [...(Array.isArray(graph) ? graph : [graph]), ...(Array.isArray(table) ? table : [table])];
    const isList = list?.length > 0;

    useEffect(() => {
        dispatch(loadShowcase());
        return () => dispatch(clearShowcase());
    }, [dispatch]);

    const renderContent = (data) => {
        if (data.type === 5) {
            const { cities, legend } = data?.data[0];
            return (<>
                <Typography variant="h6" style={{ textAlign: 'center' }}>
                    Данные по районам города
                </Typography>
                <Table data={cities} date={new Date()} />
                <Legend data={legend} />
            </>);
        } else if (data.type === 1) {
            return (<>
                <GraphicLineBar
                    item={data.data}
                />   
            </>
            );
        } else if (data.type === 4) {
            return <Graph datas={data.data} />;
        }
        return (
            <TypeComponent
                {...data}                            
            />
        );
    };

    const getWidth = (type) => {
        if (type === 5) return 5;
        else if (type === 1) return 4;
        else if (type === 2) return 4;
        else if (type === 4) return 7;
    };

    return (
        <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={4} >
                <CardMap
                    fillOthers
                    isCluster
                >
                    <Layer readOnly />
                </CardMap>
            </Grid>
            {isList && (
                list.map((item, index) => (
                    <Grid
                        key={index}
                        item
                        xs={getWidth(item.type)}
                        style={{
                            display: 'flex',
                            flex: '1 1 1 1',
                        }}
                    >
                        {loadingShowcase && <LinearProgress/>}

                        <CardWidget
                            icon={layerConfig.layerIcon}
                            title={item.name}
                            marginBottom={index < list.length - 1}
                            fillOthers
                            small
                        >          
                            {renderContent(item)}
                        </CardWidget>
                    </Grid>
                ))
            )}
        </Grid>
    );
};

export default Page;