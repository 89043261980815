import { useState } from 'react';
import { LSContentColumn, LSContentItem } from '../../../../common/ListStyle';
import Info from './Info';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';
import { getTitle } from './helper';
import { ListItem, Station } from './types';

interface ItemProps {
    list: ListItem[];
    station: Station;
    currentOption: { format: Function };
}

const Item = ({ list, station, currentOption }: ItemProps) => {

    const [isOpenInfo, setIsOpenInfo] = useState(false);

    return (
        <>
            <LSContentItem onClick={() => setIsOpenInfo(prev => !prev)}>
                <LSContentColumn>
                    <span>Остановка {getTitle(station)}</span>
                </LSContentColumn>

                <LSContentColumn title="Действия" align="right" isActions>
                    <IconButton size="small">
                        {isOpenInfo
                            ? <ExpandLess fontSize="inherit" />
                            : <ExpandMore fontSize="inherit" />
                        }
                    </IconButton>
                </LSContentColumn>
            </LSContentItem>

            {isOpenInfo && (
                <Info
                    isOpen={isOpenInfo}
                    currentOption={currentOption}
                    list={list}
                />
            )}
        </>
    );
};

export default Item;