import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteCheckPoint } from 'redux/TransportRoad/actions';
import messages from 'helpers/constants/messages';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';

import ModalForm from './ModalForm';

const Item = ({ item, reloadList }) => {
    const dispatch = useDispatch();

    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [openMap, setOpenMap] = useState(false);

    const handleDelete = () => {
        dispatch(deleteCheckPoint(item.id, () => {
            reloadList(true);
            setDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item.id || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item.road_work?.name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item.road_work?.address_text || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.mapIcon,
                                onClick: () => setOpenMap(true),
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setDeleteModal(true),
                            }
                        ]}
                    />

                </LSContentColumn>
            </LSContentItem>

            {openMap && (
                <Modal
                    isOpen={openMap}
                    onClose={() => setOpenMap(false)}
                    noPadding
                    title="Участки работ"
                    buttons={
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: () => setOpenMap(false),
                                },
                            ]}
                        />
                    }
                >
                    <MapGeoJson geometry={item.geometry} readOnly={true} />
                </Modal>
            )}

            {editModal && (
                <ModalForm
                    open={editModal}
                    onClose={() => setEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />)
            }
            {deleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={deleteModal}
                    onSuccess={handleDelete}
                    onClose={() => setDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
