import useShowcase from 'helpers/hooks/useShowcase';
import MapLayer from 'components/MapComponents/MapLayer';
import HomePage from 'components/pages/Showcase/HomePage';

const Home = (props) => {
    const isShowCase = useShowcase();

    // витрина
    if (isShowCase) {
        return <HomePage {...props} />;
    }
    // карта
    return <MapLayer {...props} />;
};

export default Home;
