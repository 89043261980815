export const ecology = (state) => state.ecology;

export const list = (state) => ecology(state).list;
export const data = (state) => list(state).data;
export const meta = (state) => list(state).meta;

export const loadingButton = (state) => ecology(state).loadingButton;

export const loading = (state) => ecology(state).loading;
export const listLoading = (state) => ecology(state).listLoading;
export const dataDevice =  (state) => ecology(state).dataDevice;
export const companyLink = (state) => ecology(state).companyLink;
export const eventsDevice = (state) => ecology(state).eventsDevice;
export const projects = (state) => ecology(state).projects;
export const statuses = (state) => ecology(state).statuses;
export const types = (state) => ecology(state).types;
export const polygon = (state) => ecology(state).polygon;
export const active = (state) => ecology(state).active;
export const filters = (state) => ecology(state).filters;
export const ecologySettings = (state) => ecology(state).ecologySettings;
export const loadingEcologySettings = (state) => ecology(state).loadingEcologySettings;
export const alerts = (state) => ecology(state).alerts;
export const ecologyOutputParams = (state) => ecology(state).ecologyOutputParams;
export const ecologyOutputSettings = (state) => ecology(state).ecologyOutputSettings;
export const loadingEcologyOutputSettings = (state) => ecology(state).loadingEcologyOutputSettings;
export const devicesGroup = (state) => ecology(state).devicesGroup;
export const loadingDevicesGroup = (state) => ecology(state).loadingDevicesGroup;

export const showcase = (state) => ecology(state).showcase;
export const loadingShowcase = (state) => ecology(state).loadingShowcase;

export const deviceStatuses = (state) => ecology(state).deviceStatuses;
