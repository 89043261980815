import { config } from '../../config';

const apiTransportRoadUrl = () => config.get('urls').apiTransportRoadUrl;

const apiUrls = {

    getSidebar: () => `${apiTransportRoadUrl()}/vehicle`,

    getCoords: () => `${apiTransportRoadUrl()}/vehicle/coords`,

    getAllVehicle: () => `${apiTransportRoadUrl()}/vehicle/coords`,

    getTypes: () => `${apiTransportRoadUrl()}/vehicle/types`,

    getHistory: (vehicleId) => `${apiTransportRoadUrl()}/vehicle/history/${vehicleId}`, //

    /* color speed */
    getColorSpeedList: () => `${apiTransportRoadUrl()}/color-speed`,

    addColorSpeed: () => `${apiTransportRoadUrl()}/color-speed`,

    editColorSpeed: (id) => `${apiTransportRoadUrl()}/color-speed/${id}`,

    deleteColorSpeed: (id) => `${apiTransportRoadUrl()}/color-speed/${id}`,

    getCategories: () => `${apiTransportRoadUrl()}/vehicle/categories`,

    findVehicles: () => `${apiTransportRoadUrl()}/report/nearest`,

    loadParks: () => `${apiTransportRoadUrl()}/park`,

    createPark: () => `${apiTransportRoadUrl()}/park/`,

    editPark: (id) => `${apiTransportRoadUrl()}/park/${id}`,

    deletePark: (id) => `${apiTransportRoadUrl()}/park/${id}`,

    getBrandList: () => `${apiTransportRoadUrl()}/brand`,

    createBrand: () => `${apiTransportRoadUrl()}/brand`,

    editBrand: (id) => `${apiTransportRoadUrl()}/brand/${id}`,

    getModelList: () => `${apiTransportRoadUrl()}/model`,

    createModel: () => `${apiTransportRoadUrl()}/model`,

    editModel: (id) => `${apiTransportRoadUrl()}/model/${id}`,

    getCompanyList: () => `${apiTransportRoadUrl()}/organization`,

    createCompany: () => `${apiTransportRoadUrl()}/organization/`,

    editCompany: (id) => `${apiTransportRoadUrl()}/organization/${id}`,

    //getCompanies: () => `${apiDadataUrl()}/its/companies`, // TODO сервис dadata вынести в отдельный модуль

    getVehicleList: () => `${apiTransportRoadUrl()}/vehicle`,

    deleteVehicle: (id) => `${apiTransportRoadUrl()}/vehicle/${id}`,

    createNewVehicle: () => `${apiTransportRoadUrl()}/vehicle`, // not allowed

    editVehicle: (id) => `${apiTransportRoadUrl()}/vehicle/${id}`,

    getVehicleReport: () => `${apiTransportRoadUrl()}/report/vehicles`,

    createCategory: () => `${apiTransportRoadUrl()}/vehicle_types`,

    editCategory: (id) => `${apiTransportRoadUrl()}/vehicle_types/${id}`,

    deleteCategory: (id) => `${apiTransportRoadUrl()}/vehicle_types/${id}`,

    getEmployees: () => `${apiTransportRoadUrl()}/employees`,

    addEmployees: () => `${apiTransportRoadUrl()}/employees`,

    editEmployees: (id) => `${apiTransportRoadUrl()}/employees/${id}`,

    deleteEmployees: (id) => `${apiTransportRoadUrl()}/employees/${id}`,

    getPositions: () => `${apiTransportRoadUrl()}/positions`,

    addPositions: () => `${apiTransportRoadUrl()}/positions`,

    editPositions: (id) => `${apiTransportRoadUrl()}/positions/${id}`,

    deletePositions: (id) => `${apiTransportRoadUrl()}/positions/${id}`,

    getBrigades: () => `${apiTransportRoadUrl()}/brigades`,

    addBrigades: () => `${apiTransportRoadUrl()}/brigades`,

    editBrigades: (id) => `${apiTransportRoadUrl()}/brigades/${id}`,

    deleteBrigades: (id) => `${apiTransportRoadUrl()}/brigades/${id}`,

    getVehicleDowntime: (id) => `${apiTransportRoadUrl()}/vehicle/downtime/${id}`,

    getReportFuel: () => `${apiTransportRoadUrl()}/telemetry/report/fuel`,

    createRoute: () => `${apiTransportRoadUrl()}/route`,

    deleteRoute: (id) => `${apiTransportRoadUrl()}/route/${id}`,

    getRoutes: () => `${apiTransportRoadUrl()}/route`,

    changeRoute: (uuid) => `${apiTransportRoadUrl()}/route/${uuid}`,

    getRouteTypes: () => `${apiTransportRoadUrl()}/route/types`,

    getRouteStatuses: () => `${apiTransportRoadUrl()}/route/statuses`,

    getRouteCheckpoints: (id) => `${apiTransportRoadUrl()}/route/${id}/check-points`,

    saveRouteCheckpoints: (id) => `${apiTransportRoadUrl()}/route/${id}/check-points`,

    getTelemetryReceivers: () => `${apiTransportRoadUrl()}/egts-receiver`,

    addTelemetryReceiver: () => `${apiTransportRoadUrl()}/egts-receiver`,

    editTelemetryReceiver: (id) => `${apiTransportRoadUrl()}/egts-receiver/${id}`,

    deleteTelemetryReceiver: (id) => `${apiTransportRoadUrl()}/egts-receiver/${id}`,

    getTelemetryReceiverTypes: () => `${apiTransportRoadUrl()}//egts-receiver/types`,

    getWorkorders: () => `${apiTransportRoadUrl()}/workorder`,

    getWorkorderStatuses: () => `${apiTransportRoadUrl()}/workorder/statuses`,

    getWorkordersByDate: () => `${apiTransportRoadUrl()}/workorder/bydate`,

    editWorkorders: (id) => `${apiTransportRoadUrl()}/workorder/${id}`,

    getWorkorderLogs: (id) => `${apiTransportRoadUrl()}/workorder/${id}/events`,

    getWorkorderLogManualStatus: () => `${apiTransportRoadUrl()}/work-order-event/manual-statuses`,

    addWorkorderLogManualStatus: (id) => `${apiTransportRoadUrl()}/work-order-event/${id}/status`,

    editWorkorderLogManualStatus: (id) => `${apiTransportRoadUrl()}/work-order-event/${id}/status`,

    deleteWorkorderLogManualStatus: (id) => `${apiTransportRoadUrl()}/work-order-event/${id}/status`,

    getCheckPoints: () => `${apiTransportRoadUrl()}/check-point/`,

    createCheckPoint: () => `${apiTransportRoadUrl()}/check-point`,

    editCheckPoint: (id) => `${apiTransportRoadUrl()}/check-point/${id}`,

    deleteCheckPoint: (id) => `${apiTransportRoadUrl()}/check-point/${id}`,

    getReportByVehicles: () => `${apiTransportRoadUrl()}/report/vehicles`,

    getCheckPointPolygon: () => `${apiTransportRoadUrl()}/check-point/polygon`,

    changeRouteStatus: (id, status) => `${apiTransportRoadUrl()}/route/${id}/change-status/${status}`,

    loadVehicleStatistics: () => `${apiTransportRoadUrl()}/vehicle/statistics`,

    loadWorkorderStatistics: () => `${apiTransportRoadUrl()}/workorder/statistics`,

    loadEgtsTransmitters: () => `${apiTransportRoadUrl()}/egts-transmitter`,

    addEgtsTransmitter: () => `${apiTransportRoadUrl()}/egts-transmitter`,

    editEgtsTransmitter: (id) => `${apiTransportRoadUrl()}/egts-transmitter/${id}`,

    deleteEgtsTransmitter: (id) => `${apiTransportRoadUrl()}/egts-transmitter/${id}`,

};

export default apiUrls;
