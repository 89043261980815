import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import { stringifyRenderData } from 'helpers/stringifyRenderData';
import useCompany from 'helpers/hooks/useCompany';
import { loadEquipmentsList } from 'redux/TransportPassenger/actions';

// инфо для пассажирки!
export const VehicleInfo = ({ info, storeName, loadVehicleCategories, loadVehiclesTypes }) => {
    const vehiclesCategories = useStoreProp(loadVehicleCategories, 'transportPassenger', 'vehicleCategories');
    const vehiclesTypes = useStoreProp(loadVehiclesTypes, 'transportPassenger', 'vehiclesTypes');
    const characteristics = useStoreProp(loadEquipmentsList, 'transportPassenger', 'equipments');
    const company = useCompany(info.organization_id, info.organization);

    const category = vehiclesCategories.find(el => el.id === info.category_id);

    return (
        <div>
            <b>{titles.GRZ}:</b> {info.number}
            <div><b>{titles.GARAGE_NUM}:</b> {info.garage_number}</div>
            <div><b>{titles.TYPE_OF_VEHICLE}:</b> {category?.name || ''}</div>
            <b>{titles.TYPE}:</b> {vehiclesTypes[info.type]}
            <div>
                <b>{titles.OPTIONAL_EQUIPMENT}:</b>
                {Object.keys(info.characteristics)?.length > 0
                    ? (Object.keys(info.characteristics).map((key, index) => {
                        const equipments = typeof info.characteristics[key] == 'boolean'
                            ? info.characteristics[key] ? 'Да' : 'Нет' || messages.INFO_IS_NOT_FOUND
                            : info.characteristics[key] || messages.INFO_IS_NOT_FOUND;
                        return <div key={index}>{characteristics[key]}: {equipments}</div>;
                    }))
                    : <div>{messages.INFO_IS_NOT_FOUND}</div>
                }
            </div>

            {company
                && <div>
                    <h3>Организация:</h3>
                    <div className="info" style={{ paddingLeft: 10 }}>
                        <p><b>{company.title}</b></p>
                        <p><b>{titles.INN}:</b> {company?.inn ?? messages.INFO_IS_NOT_FOUND}</p>
                        <p>
                            <b>{titles.PHONES}:</b> {stringifyRenderData(company?.phones)}
                        </p>
                        <p>
                            <b>{titles.EMAIL}:</b> {stringifyRenderData(company?.emails)}
                        </p>
                    </div>
                </div>
            }
        </div>
    );
};
