import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { LocalizationProvider } from '@mui/lab';
import { pick } from 'lodash';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { theme } from 'App';

import * as actionsDorisControl from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import { setShowReturnTo } from 'redux/Menu/actions';
import {
    useStoreProp
} from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import PassportTabs from 'components/pages/Dictionaries/DorisControl/VFComplex/PassportPage/PassportTabs';

const Information = (props) => {
    const {
        data,
        history,
        showButtons = true,
        disabledButtons = false,
        onEdited,
    } = props;
    const location = history?.location;

    const dispatch = useDispatch();

    const dorisComplexTypes = useStoreProp(actionsDorisControl.loadComplexTypes, 'dorisControl', 'doris_complex_types');
    const unitTypes = useStoreProp(actionsDorisControl.loadComplexUnitTypes, 'dorisControl', 'doris_complex_unit_types');
    const active = useSelector(dorisControlSelectors.active);

    const fieldsData = {
        serial_number: {
            name: 'Серийный номер',
        },
        // name: {
        //     name: 'Наименование комплекса',
        // },
        // unit_types: {
        //     name: 'Вид комплекса',
        // },
        // species_id: {
        //     name: 'Вид комплекса',
        //     f: ({ species_id }) => unitTypes[species_id] || messages.INFO_IS_NOT_FOUND
        // },
        // type_id: {
        //     name: 'Тип комплекса',
        //     f: ({ type_id }) => dorisComplexTypes[type_id] || messages.INFO_IS_NOT_FOUND
        // },
        species_id_type_id: {
            name: 'Вид комплекса / Тип комплекса',
            f: ({ species_id, type_id }) => {
                // видимо сменился формат типов ([{ id: 8, name: 'Автодория' }, ...])
                const complexType = dorisComplexTypes
                    ?.find(({ id }) => id === type_id)
                    ?.name
                    || messages.INFO_IS_NOT_FOUND;
                return `${unitTypes[species_id] || messages.INFO_IS_NOT_FOUND} / ${complexType}`;
            }
        },
        // ip_route: {
        //     name:'IP-адрес',
        // },
        // mask: {
        //     name: 'Маска подсети',
        // },
        // ip_route_mask: {
        //     name: 'IP-адрес / Маска подсети',
        //     f: ({ ip_route, mask }) => `${ip_route} / ${mask}`
        // },
        lat_lon: {
            name: 'Координаты (lat, lon)',
            f: ({ lat, lon }) => `${lat}, ${lon}`
        },
        address_text: {
            name: 'Адрес',
        },
        // wwindspeed: {
        //     name: 'Дата окончания поверки комплекса',
        // },
    };

    // выводимые поля
    const fields = showButtons
        ? fieldsData
        : pick(fieldsData, [
            'serial_number',
            'species_id_type_id',
            // 'ip_route_mask',
            'lat_lon',
            'address_text',
        ]);

    const [activeTab, setIsActiveTab] = useState(null);
    const [isOpenPassport, setIsOpenPassport] = useState(false);
    const closePassport = () => {
        setIsOpenPassport(false);
        setIsActiveTab(null);
    };

    // открываем комплекс по tab
    useEffect(() => {
        const tab = active.tab;
        if (isOpenPassport === false && tab) {
            setIsActiveTab(tab);
            setIsOpenPassport(true);
            dispatch(actionsDorisControl.setActive({
                ...active,
                tab: null
            }));
        }
    },[active, isOpenPassport, data, dispatch]);

    const RenderList = () => {

        if (!data) {
            return <div className="not-found">{messages.INFO_IS_NOT_FOUND}</div>;
        }

        return (
            <div>
                <div className="information">
                    {Object.keys(fields).map((field) => {
                        const f = fields[field].f ? fields[field].f(data) : null;
                        const value = f || data[field] || (data[field] === 0 && '0') || null;
                        const name = fields[field].name || '';

                        if (value) {
                            return (
                                <div className="info__item" key={`information_${field}`}>
                                    <span className="info__item-title">
                                        {name}
                                        {': '}
                                    </span>
                                    <span className="info__item-content">
                                        {value || messages.INFO_IS_NOT_FOUND}
                                    </span>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        );
    };

    const onClickShowReport= () => {
        dispatch(
            setShowReturnTo(
                location?.pathname,
                'Вернуться в КФВФ',
                location?.search
            )
        );

        history?.push({
            pathname: '/reports/doris-control/complex-events',
            search: `complex_id=${data.id}`
        });
        setIsOpenPassport(false);
    };

    const buttons = showButtons
        ? {
            buttons: [
                {
                    ...buttonsTypes.add,
                    name: 'Паспорт',
                    onClick: () => {
                        // setIsActiveTab('data');
                        setIsOpenPassport(true);
                    },
                    disabled: disabledButtons
                },
                {
                    ...buttonsTypes.add,
                    name: 'Отчет по событиям',
                    // onClick: onClickShowReport, // переход на страницу Отчеты с id комплекса в урле
                    onClick: () => {
                        setIsActiveTab('report');
                        setIsOpenPassport(true);
                    },
                    disabled: disabledButtons
                }
            ]
        }
        : {};

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} locale={'ru'}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <div>
                        <RenderList />
                        {/*<Scrollbars style={{ height: '210px' }}>*/}
                        {/*    <RenderList />*/}
                        {/*</Scrollbars>*/}

                        <div
                            className="filter__wrap__btn filter__btn__column"
                            style={{
                                paddingLeft: '1rem',
                                paddingRight: '1rem',
                            }}
                        >
                            <FormButtons
                                {...buttons}
                            />
                            {isOpenPassport && (
                                <Modal
                                    isOpen={isOpenPassport}
                                    onClose={closePassport}
                                    noPadding={true}
                                    title={data?.name}
                                    fullWidth
                                    showCloseInTitle
                                    buttons={<FormButtons
                                        buttons={[
                                            {
                                                ...buttonsTypes.close,
                                                onClick: closePassport
                                            },
                                        ]}
                                    />}
                                >
                                    <PassportTabs
                                        callback={closePassport}
                                        history={history}
                                        item={data}
                                        tab={ activeTab }
                                        onEdited={onEdited}
                                        readOnly={data.external_id}
                                    />
                                </Modal>
                            )}
                        </div>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        </LocalizationProvider>
    );
};

export default Information;
