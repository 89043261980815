import React from 'react';
import {
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useValidation from '../../../../../helpers/hooks/useValidation';
import { RenderColorPicker, AlignPosition, PaddingPosition } from './ModalForm/Fields';
import { UploadImage } from './ModalForm/Fields';


const RenderImageMessage = ({ item, onChange }) => {
    const { 
        color = '#000000',
        image = null,
        x = 0,
        y = 0,
        'align-x': alignX = 'center',
        'align-y': alignY = 'top',
    } = item;
    const validation = useValidation();

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        onChange({ 
            ...item, 
            [name]: type === 'number' 
                ? value < 0 ? 0 : Number(value)
                : value 
        });
    };
    
    return (
        <div className="block">
            <div className="row">
                <div className="row__item">
                    <UploadImage
                        base64={image}
                        onChange={(val) => onChange({ ...item, image: val })}
                    />
                </div>
                <RenderColorPicker
                    required
                    color={color} 
                    onChange={handleChange} 
                    title="Цвет фона" 
                    error={!color || validation.isKey(`text.${item.messageIndex}.color`)}
                    helperText={validation.get(`text.${item.messageIndex}.color`)}
                />
            </div>
            <AlignPosition alignX={alignX} alignY={alignY} handleChange={handleChange} />                
            <PaddingPosition x={x} y={y} handleChange={handleChange} />                
        </div>
    );
};

export default RenderImageMessage;