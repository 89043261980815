// иконка для списка
const iconCluster = (props) => {
    return `
        <svg viewBox="0 0 94.000000 103.000000" xmlns="http://www.w3.org/2000/svg" fill="${props?.fill || '#e7e7e7'}">
         <g>
          <g id="svg_defects_c_1">
           <rect x="-1" y="-1" width="96" height="105" id="canvas_background" fill="none"/>
          </g>
          <g id="svg_defects_c_2">
           <g transform="translate(0,103) scale(0.10000000149011612,-0.10000000149011612) " id="svg_defects_c_rw_2">
            <circle cx="470" cy="550" r="450" id="svg_defects_c_rw_3"/>
           </g>
          </g>
          <g stroke="null" id="svg_defects_c_7">
           <path stroke="null" fill="${props?.fillInner || 'rgba(0, 0, 0, 0.54)'}" id="svg_defects_c_6" d="m41.52856,59.13421l13.23486,-22.94789a7.72981,7.72981 0 0 1 6.76735,-3.90814l0,-0.53105a6.01542,6.01542 0 0 0 -6.01542,-6.01542l-30.0771,0a6.01542,6.01542 0 0 0 -6.01542,6.01542l0,30.0771a6.01542,6.01542 0 0 0 6.01542,6.01542l16.77832,0c-0.23216,-0.29607 -0.49251,-0.56395 -0.68519,-0.89667a7.72793,7.72793 0 0 1 -0.00282,-7.80877zm-13.0826,-10.84467a1.50385,1.50385 0 0 1 -3.00771,0l0,-15.03855a1.50385,1.50385 0 0 1 3.00771,0l0,15.03855zm6.01542,0a1.50385,1.50385 0 0 1 -3.00771,0l0,-15.03855a1.50385,1.50385 0 0 1 3.00771,0l0,15.03855zm4.51156,1.50385a1.50385,1.50385 0 0 1 -1.50385,-1.50385l0,-15.03855a1.50385,1.50385 0 0 1 3.00771,0l0,15.03855a1.50385,1.50385 0 0 1 -1.50385,1.50385zm39.95461,10.84373l-13.2358,-22.94789c-1.84786,-3.20415 -6.47316,-3.20415 -8.32102,0l-13.23674,22.94695c-1.84692,3.20227 0.46432,7.20347 4.16098,7.20347l26.47161,0c3.69666,0 6.0079,-4.00119 4.16098,-7.20253zm-17.39678,2.69096a2.25578,2.25578 0 1 1 2.25578,-2.25578a2.25578,2.25578 0 0 1 -2.25578,2.25578zm1.79335,-7.78245a1.12789,1.12789 0 0 1 -1.12225,1.0151l-1.34125,0a1.12789,1.12789 0 0 1 -1.12319,-1.0151l-0.90137,-9.02313a1.12789,1.12789 0 0 1 1.12225,-1.24068l3.14588,0a1.12789,1.12789 0 0 1 1.12225,1.24068l-0.90231,9.02313z"/>
          </g>
         </g>
        </svg>
    `;
};

export default iconCluster;
