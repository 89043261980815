import React from 'react';
import { IconButton, Link } from '@mui/material';
import messages from '../../../../../../helpers/constants/messages';
import { fullDateTime } from '../../../../../../helpers/date.config';

const HistoryItemInfo = ({ item }) => {

    return (
        <>
            {item?.data?.length > 0
                ? item?.data.map((el, index) => (
                    <div key={index}>
                        <h3>{el?.name}: </h3>
                        <div>
                            <i>Новое значение</i>
                            <span>
                                {' - '}
                                {Array.isArray(el?.new)
                                    ? el?.name === 'Маршруты' && el?.new?.length > 0
                                        ? el?.new?.map((route, index) => <div key={route?.id}>{index+1}. {route?.name}, маршрут №: {route?.num}</div>)
                                        : el?.name === 'Вложения' && el?.new?.length > 0
                                            ? el?.new?.map((file,ind) =>
                                                <IconButton size={'small'} key={ind}>
                                                    <Link href={file} target="_blank"> <i className="fal fa-file-alt"/> </Link>
                                                </IconButton>)
                                            : el?.name === 'ГРЗ авто' && el?.new?.length > 0
                                                ? el?.new?.map(grz => <div key={grz?.id}>Номер: {grz?.number}, марка: {grz?.mark}, модель: {grz?.model}</div>)
                                                : ''
                                    : !Array.isArray(el?.new) && el?.new?.hasOwnProperty('id')
                                        ? el?.new?.first_name || el?.new?.name || el?.new?.title
                                        : !Array.isArray(el?.new) && el?.new?.hasOwnProperty('geometry')
                                            ? 'Данные изменены'
                                            : (el?.name === 'Дата решения план' || el?.name === 'Дата решения факт' || el?.name === 'Время регистрации') && el?.new?.length > 0
                                                ? fullDateTime(el?.new)
                                                : (el?.name === 'Отметка о выполнении')
                                                    ? el?.new ? 'Да' : 'Нет'
                                                    : el?.new?.length > 0 || typeof el?.new === 'number'
                                                        ? el?.new
                                                        : messages.NO_DATA
                                }
                            </span>
                        </div>
                        <div>
                            <i>Старое значение</i>
                            <span>
                                {' - '}
                                {Array.isArray(el?.old)
                                    ? el?.name === 'Маршруты' && el?.old?.length > 0
                                        ? el?.old?.map((route, index) => <div key={route?.id}>{index+1}. {route?.name}, маршрут №: {route?.num}</div>)
                                        : el?.name === 'Вложения' && el?.old?.length > 0
                                            ? el?.old?.map((file, ind) =>
                                                <IconButton size={'small'} key={ind}>
                                                    <Link href={file} target="_blank"> <i className="fal fa-file-alt"/> </Link>
                                                </IconButton>)
                                            : el?.name === 'ГРЗ авто' && el?.old?.length > 0
                                                ? el?.old?.map(grz => <div key={grz?.id}>Номер: {grz?.number}, марка: {grz?.mark}, модель: {grz?.model}</div>)
                                                : ''
                                    : !Array.isArray(el?.old) && el?.old?.hasOwnProperty('id')
                                        ? el?.old?.first_name || el?.old?.name || el?.old?.title
                                        : !Array.isArray(el?.old) && el?.old?.hasOwnProperty('geometry')
                                            ? 'Данные изменены'
                                            : (el?.name === 'Дата решения план' || el?.name === 'Дата решения факт' || el?.name === 'Время регистрации') && el?.old?.length > 0
                                                ? fullDateTime(el?.old)
                                                : (el?.name === 'Отметка о выполнении')
                                                    ? el?.old ? 'Да' : 'Нет'
                                                    : el?.old?.length > 0 || typeof el?.old === 'number'
                                                        ? el?.old
                                                        : messages.NO_DATA
                                }
                            </span>
                        </div>
                    </div>
                ))
                : <span>{messages.NO_DATA}</span>
            }
        </>
    );
};

export default HistoryItemInfo;