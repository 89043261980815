import { loadFormattedComplexHistory } from '../../../../../../../redux/DorisControl/actions';
import styles from '../../vfcomplex.module.scss';
import HistoryItemInfo from './HistoryItemInfo';
import HistoryList from '../../../../../../common/HistoryList';

const History = ({ complex_id, onChange }) => {

    return (
        <div className={styles.container}>
            <HistoryList
                storeName="dorisControl"
                storeProp="formattedComplexHistory"
                loadingProp="loadingFormattedComplexHistory"
                loadAction={loadFormattedComplexHistory}
                id={complex_id}
                ItemInfo={HistoryItemInfo}
            />
        </div>
    );

};

export default History;
