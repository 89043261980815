// иконка для карты
const iconMap = ({ fill = '#000', innerFill = '#fff' }) => {
    return `
        <svg viewBox="0 0 107.000000 130.000000" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
         <g>
          <g id="svg_metro_3">
           <rect fill="none" id="canvas_background" height="132" width="109" y="-1" x="-1"/>
          </g>
          <g id="svg_metro_4">
           <g fill="${fill}" id="svg_metro_1" transform="translate(0,130) scale(0.10000000149011612,-0.10000000149011612)">
            <path id="svg_metro_2" d="m445,1286c-100,-19 -206,-75 -281,-150c-104,-104 -154,-228 -154,-381c0,-144 38,-245 149,-392c64,-85 297,-316 348,-345c30,-17 31,-17 71,12c68,49 237,216 302,299c157,200 210,350 181,517c-25,142 -121,287 -241,364c-108,70 -251,98 -375,76z"/>
           </g>
          </g>
          <g fill="${innerFill}" stroke="null" id="svg_metro_9">
           <path stroke="null" id="svg_metro_8" d="m34.96,76.75c-1.23,0 -2.45,-0.47 -3.49,-1.32c-7.68,-6.64 -12.06,-16.3 -11.92,-26.53c0.09,-8.44 3.2,-16.49 8.77,-22.71c1.04,-1.13 2.45,-1.79 3.96,-1.84c1.51,-0.05 2.92,0.57 3.96,1.65l17.15,17.72l17.86,-17.39c1.08,-1.04 2.5,-1.65 4.01,-1.56c1.46,0.09 2.87,0.75 3.86,1.88c5.47,6.36 8.44,14.51 8.34,22.95c-0.09,10.13 -4.57,19.6 -12.3,26.11c-2.31,1.93 -5.75,1.6 -7.63,-0.75c-1.93,-2.31 -1.6,-5.8 0.71,-7.73c5.23,-4.43 8.25,-10.89 8.34,-17.72c0.05,-3.77 -0.8,-7.45 -2.45,-10.74l-17.11,16.68c-2.12,2.07 -5.56,2.03 -7.63,-0.14l-16.35,-16.87c-1.65,3.25 -2.59,6.83 -2.64,10.56c-0.05,6.97 2.92,13.52 8.15,18.05c2.31,1.98 2.54,5.47 0.61,7.78c-1.08,1.27 -2.64,1.93 -4.19,1.93z"/>           
           </g>
         </g>
        </svg>
    `;
};

export default iconMap;
