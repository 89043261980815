import TransportIncidentsTypes from '../../pages/Dictionaries/SituationalPlans/TransportIncidentsTypes';
import Events from '../../pages/Dictionaries/SituationalPlans/Events';
import KeyWords from '../../pages/Dictionaries/SituationalPlans/KeyWords';
import Dangers from '../../pages/Dictionaries/SituationalPlans/Dangers';
import IncidentsDangers from '../../pages/Dictionaries/SituationalPlans/TransportIncidents/Dangers';
import TypicalOperations from '../../pages/Dictionaries/SituationalPlans/TypicalOperations';
import EventObjects from '../../pages/Dictionaries/SituationalPlans/EventObjects';
import ResponseScenarios from '../../pages/Dictionaries/SituationalPlans/ResponseScenarios';
import TransportIncidents from '../../pages/Dictionaries/SituationalPlans/TransportIncidents';
import InfoPanel from '../../pages/Dictionaries/SituationalPlans/InfoPanel';
import SituationalPlansSettings from '../../pages/Dictionaries/SituationalPlans/Settings';

const routes = [
    {
        path: '/dictionaries/situational-plans/transport-incidents-types',
        component: TransportIncidentsTypes,
        exact: true,
    },
    {
        path: '/dictionaries/situational-plans/events',
        component: Events,
        exact: true,
    },
    {
        path: '/dictionaries/situational-plans/keywords',
        component: KeyWords,
        exact: true
    },
    {
        path: '/dictionaries/situational-plans/dangers',
        component: Dangers,
        exact: true
    },
    {
        path: '/dictionaries/situational-plans/transport-incidents-dangers',
        component: IncidentsDangers,
        exact: true
    },
    {
        path: '/dictionaries/situational-plans/typical-operations',
        component: TypicalOperations,
        exact: true
    },
    {
        path: '/dictionaries/situational-plans/event-objects',
        component: EventObjects,
        exact: true,
    },
    {
        path: '/dictionaries/situational-plans/response-scenarios',
        component: ResponseScenarios,
        exact: true,
    },
    {
        path: '/dictionaries/situational-plans/transport-incidents',
        component: TransportIncidents,
        exact: true,
    },
    {
        path: '/dictionaries/situational-plans/infopanel',
        component: InfoPanel,
        exact: true,
    },
    {
        path: '/doris-react/settings',
        component: SituationalPlansSettings,
        exact: true,
    },
];

export default routes;