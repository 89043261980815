import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearDitLink, loadBoardsFromLinks, loadDitLink } from '../../../redux/Boards/actions';
import { boardsSelectors } from '../../../redux/Boards';

// компонент для отрисовки списка связанных ДИТ
// тут же мы загружаем эти связанные ДИТ по id и microservice
const useLoadBoardsList = (id, entity_name, microservice, needUpdate = false, dit_list) => {
    const dispatch = useDispatch();
    const currentId = useRef(null); // сохраняем сюда текущий айди entity
    const ditLink = useSelector(boardsSelectors.ditLink);
    const boardsFromLink = useSelector(boardsSelectors.boardsFromLink);
    const [isNeedUpdate, setIsNeedUpdate] = useState(false); //чекаем надо ли обновить данные
    const [isSaveCurrentBoards, setIsSaveCurrentBoards] = useState(false); // загрузка линков была
    const [isSaveCurrentLinks, setIsSaveCurrentLinks] = useState(false); // загрузка дитов была
    const [dit_links, setDitLinks] = useState({});
    const [currentIds, setCurrentIds] = useState([]); // список айди дитов для текущего entity

    // функция очистки линков из стора
    const onClear = useCallback(() => {
        dispatch(clearDitLink());
    },[dispatch]);

    useEffect(() => {
        // устанавливаем needUpdate если true или если изменился id
        if (needUpdate || currentId.current !== id) {
            setIsNeedUpdate(true);
            currentId.current = id;
            // если изменился id очищаем список линков и локальный стейт сбрасываем на наальное значение
            onClear();
            setDitLinks((prev) => ({
                ...prev,
                [id]: prev[id] || dit_list || []
            }));
        }
    }, [needUpdate, id, dit_list, onClear]);
    
    useEffect(() => {
        if (id && entity_name && microservice && isNeedUpdate) {
            // если нет переданного списка дитов, загружаем связи дит для конкретного entity
            // в колбэк передаем что всё успешно загружено
            if (!dit_list) {
                dispatch(loadDitLink({
                    entity_ids: [id],
                    entity_name: entity_name,
                }, microservice, 
                () => setIsSaveCurrentLinks(true)));
            }
            setIsNeedUpdate(false);
        }
    },[id, entity_name, microservice, dit_list, dispatch, isNeedUpdate ]);
    
    useEffect(() => {
        // чтобы отрисовать название ДИТ нужно их загрузить по id дитов
        if (isSaveCurrentLinks) {
            const ids = ditLink?.map(({ dit_id }) => dit_id);
            // сохраняем айдишники дитов для текущего entity
            setCurrentIds(ids);
            // некоторые ДИТ могут уже быть загружены, выбираем только уникальные айди, которых еще нет в списке
            const uniqIds = ids.filter(eId => !boardsFromLink[eId]);
            // если есть уникальные - грузим для них диты
            if (uniqIds.length > 0) {
                dispatch(loadBoardsFromLinks({ ids }, () => setIsSaveCurrentBoards(true)));
            } else {
                setIsSaveCurrentBoards(true);
            }
            // очищаем загруженные связи
            onClear();
            setIsSaveCurrentLinks(false);
        } 
    },[isSaveCurrentLinks, ditLink, dispatch, onClear, boardsFromLink]);

    useEffect(() => {
        // если диты для текущих линков загружены, надо их сохранить в локальный стейт
        if (isSaveCurrentBoards) {
            const filteredBoards = currentIds.map(eId => boardsFromLink[eId]);
            setIsSaveCurrentBoards(false);
            setDitLinks((prev) => ({
                ...prev,
                [currentId.current]: filteredBoards
            }));
        }
    },[isSaveCurrentBoards, boardsFromLink, currentIds]);

    return dit_links[id] || [];
};

export default useLoadBoardsList;