import { config } from '../../config';

const apiReports = () => config.get('urls').apiReports;

const apiUrls = {
    getList: () => `${apiReports()}/report`,

    getFormats: () => `${apiReports()}/report/formats`,
    
    getFormatsNew: (id) => `${apiReports()}/report/${id}/formats`,

    getTemplates: () => `${apiReports()}/report/templates`,

    deleteReport: (id) => `${apiReports()}/report/${id}`,

    deleteAllReports: () => `${apiReports()}/report/delete-by-user`,

    loadScheduledReport: () => `${apiReports()}/scheduled_report`,

    createScheduledReport: () => `${apiReports()}/scheduled_report`,

    editScheduledReport: (id) => `${apiReports()}/scheduled_report/${id}`,

    deleteScheduledReport: (id) => `${apiReports()}/scheduled_report/${id}`,

    loadScheduledReportTemplate: () => `${apiReports()}/scheduled_report/templates`,

    loadPuidTFIndicators: () => `${apiReports()}/traffic-flow/indicators`,

    loadPuidTFIndicatorsConsolidate: () => `${apiReports()}/traffic-flow/indicators-consolidated`,

    loadReportTemplateFrontendLinks: () => `${apiReports()}/report/template-frontend-links`,

};

export default apiUrls;
