import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    loadReportTransportIncidents,
    loadTransportIncidentStatuses
} from '../../../../redux/SituationalPlans/actions';
import Loading from '../../../common/Loading';
import messages from '../../../../helpers/constants/messages';
import CustomPagination from '../../../common/CustomPagination';
import Item from './Item';
import { List } from '@mui/material';
import Filter from './Filter';
import { sub } from 'date-fns';
import { fullDateTimeWithTimeZone } from '../../../../helpers/date.config';
import { useStoreProp } from '../../../../helpers/hooks';
import { SituationalPlansSelectors } from '../../../../redux/SituationalPlans';

const TransportIncidentReport = () => {
    const dispatch = useDispatch();

    const data = useSelector(SituationalPlansSelectors.reportTransportIncidentsData);
    const meta = useSelector(SituationalPlansSelectors.reportTransportIncidentsMeta);
    const loadingReportTransportIncidents = useSelector(SituationalPlansSelectors.loadingReportTransportIncidents);

    const [params, setParams] = useState({ page: 1, data: { 
        start_date: fullDateTimeWithTimeZone(sub(new Date(), { months: 1 })),
        end_date: fullDateTimeWithTimeZone(new Date())
    } });
    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);

    const statuses = useStoreProp(loadTransportIncidentStatuses, 'SituationalPlans', 'transportIncidentStatuses');

    useEffect(() => {
        dispatch(loadReportTransportIncidents(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    return (
        <div>
            <h1>Отчет о состоянии событий</h1>
            <Filter setParams={setParams} isEmpty={!!data.length}/>
            {loadingReportTransportIncidents && <Loading circular={true}/>}
            {data?.length > 0
                ? (<>
                    <List className="list">
                        {data
                            .map((item) => <Item
                                key={item.id}
                                item={item}
                                statuses={statuses}
                            />)
                        }
                    </List>
                    <CustomPagination
                        loadList={(page) => setParams({ ...params, page })}
                        list={meta}
                        limit={limit} 
                        setLimit={setLimit}
                    />
                </>)
                : (!loadingReportTransportIncidents && <div style={{ marginTop: 20 }}>{messages.DATA_IS_NOT_FOUND}</div>)}
        </div>
    );
};

export default TransportIncidentReport;
