import { Tooltip } from '@mui/material';

const CircleStatus = ({ title = '', color = '#ccc', styles = {} }) => {
    return (
        <Tooltip title={title || 'Не указан'}>
            <div style={{ backgroundColor: color, width: 20, height: 20, borderRadius: '50%', ...styles }}></div>
        </Tooltip>
    );
};

export default CircleStatus;
