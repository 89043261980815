import { all } from 'redux-saga/effects';

import lazyFeatureLoad from './reducersLazyLoad';

const sagasFromReduxFolder = [];
const added = {};
lazyFeatureLoad((module) => {
    if (module.saga && !added[module.moduleName]) {
        // костыль от задвоения запросов
        added[module.moduleName] = true;
        sagasFromReduxFolder.push(module.saga());
    }
});

const allSagas = function* () {
    yield all(sagasFromReduxFolder);
};

export default allSagas;
