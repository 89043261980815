import moduleName from './module';

const prefix = `${moduleName}`;

// INFRASTRUCTURE
export const LOADING_INFRASTRUCTURE = `${prefix}/LOADING_INFRASTRUCTURE`;
export const LOAD_INFRASTRUCTURE = `${prefix}/LOAD_INFRASTRUCTURE`;
export const LOADED_INFRASTRUCTURE = `${prefix}/LOADED_INFRASTRUCTURE`;
export const CREATE_INFRASTRUCTURE = `${prefix}/CREATE_INFRASTRUCTURE`;
export const EDIT_INFRASTRUCTURE = `${prefix}/EDIT_INFRASTRUCTURE`;
export const DELETE_INFRASTRUCTURE = `${prefix}/DELETE_INFRASTRUCTURE`;
// INFRASTRUCTURE_TYPES
export const LOAD_INFRASTRUCTURE_TYPES = `${prefix}/LOAD_INFRASTRUCTURE_TYPES`;
export const LOADING_INFRASTRUCTURE_TYPES = `${prefix}/LOADING/INFRASTRUCTURE/TYPES`;
export const LOADED_INFRASTRUCTURE_TYPES = `${prefix}/LOADED_INFRASTRUCTURE_TYPES`;
export const CREATE_INFRASTRUCTURE_TYPES = `${prefix}/CREATE/INFRASTRUCTURE/TYPES`;
export const EDIT_INFRASTRUCTURE_TYPES = `${prefix}/EDIT/INFRASTRUCTURE/TYPES`;
export const DELETE_INFRASTRUCTURE_TYPES = `${prefix}/DELETE/INFRASTRUCTURE/TYPES`;

export const LOAD_ROAD_SECTION_TYPES = `${prefix}LOAD_ROAD_SECTION_TYPES`;
export const LOADED_ROAD_SECTION_TYPES = `${prefix}LOADED_ROAD_SECTION_TYPES`;
export const LOAD_ROAD_SECTION_CATEGORIES = `${prefix}LOAD_ROAD_SECTION_CATEGORIES`;
export const LOADED_ROAD_SECTION_CATEGORIES = `${prefix}LOADED_ROAD_SECTION_CATEGORIES`;
export const LOAD_ROAD_SECTIONS_REPORT = `${prefix}LOAD_ROAD_SECTIONS_REPORT`;
export const LOAD_ROAD_SECTIONS = `${prefix}LOAD_ROAD_SECTIONS`;
export const LOADED_ROAD_SECTIONS = `${prefix}LOADED_ROAD_SECTIONS`;
export const LOADING_ROAD_SECTIONS = `${prefix}LOADING_ROAD_SECTIONS`;
export const CREATE_ROAD_SECTION = `${prefix}CREATE_ROAD_SECTION`;
export const EDIT_ROAD_SECTION = `${prefix}EDIT_ROAD_SECTION`;
export const DELETE_ROAD_SECTION = `${prefix}DELETE_ROAD_SECTION`;

export const LOAD_POLYGON_ROAD_SECTION = `${prefix}LOAD_POLYGON_ROAD_SECTION`;
export const LOADED_POLYGON_LOADED_ROAD_SECTION = `${prefix}LOADED_POLYGON_LOADED_ROAD_SECTION`;
export const LOADING_POLYGON_LOADED_ROAD_SECTION = `${prefix}LOADING_POLYGON_LOADED_ROAD_SECTION`;
export const SET_ACTIVE_ROAD_SECTION = `${prefix}SET_ACTIVE_ROAD_SECTION`;
export const SET_FILTERS_ROAD_SECTION = `${prefix}SET_FILTERS_ROAD_SECTION`;
export const LOAD_SIDEBAR_ROAD_SECTION = `${prefix}LOAD_SIDEBAR_ROAD_SECTION`;
export const LOADED_SIDEBAR_ROAD_SECTION = `${prefix}LOADED_SIDEBAR_ROAD_SECTION`;
export const LOADING_SIDEBAR_ROAD_SECTION = `${prefix}LOADING_SIDEBAR_ROAD_SECTION`;
export const CLEAR_SIDEBAR_ROAD_SECTION = `${prefix}CLEAR_SIDEBAR_ROAD_SECTION`;
export const SET_DELETE_FORM_ROAD_SECTION = `${prefix}SET_DELETE_FORM_ROAD_SECTION`;
export const SET_EDIT_FORM_ROAD_SECTION = `${prefix}SET_EDIT_FORM_ROAD_SECTION`;
export const SET_SAVED_ROAD_SECTION = `${prefix}SET_SAVED_ROAD_SECTION`;
export const LOAD_STATUSES_ROAD_SECTION = `${prefix}LOAD_STATUSES_ROAD_SECTION`;
export const LOADED_STATUSES_ROAD_SECTION = `${prefix}LOADED_STATUSES_ROAD_SECTION`;
export const SET_EDIT_FORM = `${prefix}SET_EDIT_FORM`;

export const LOAD_ROAD_SECTION_RELATION = `${prefix}LOAD_ROAD_SECTION_RELATION`;
export const LOADING_ROAD_SECTION_RELATION = `${prefix}LOADING_ROAD_SECTION_RELATION`;
export const LOAD_ROAD_SECTION_RELATION_TYPES = `${prefix}LOAD_ROAD_SECTION_RELATION_TYPES`;
export const LOADED_ROAD_SECTION_RELATION_TYPES = `${prefix}LOADED_ROAD_SECTION_RELATION_TYPES`;
export const CREATE_ROAD_SECTION_RELATION = `${prefix}CREATE_ROAD_SECTION_RELATION`;
export const DELETE_ROAD_SECTION_RELATION = `${prefix}DELETE_ROAD_SECTION_RELATION`;
export const LOAD_ROAD_SECTION_ALL_RELATION = `${prefix}LOAD_ROAD_SECTION_ALL_RELATION`;
export const CREATE_MULTIPLE_ROAD_SECTION_RELATION = `${prefix}CREATE_MULTIPLE_ROAD_SECTION_RELATION`;

export const LOAD_ROAD_SECTION_BY_RADIUS = `${prefix}/LOAD_ROAD_SECTION_BY_RADIUS`;
export const LOADED_ROAD_SECTION_BY_RADIUS = `${prefix}/LOADED_ROAD_SECTION_BY_RADIUS`;

export const LOAD_INFRASTRUCTURE_BY_RADIUS = `${prefix}/LOAD_INFRASTRUCTURE_BY_RADIUS`;
export const LOADED_INFRASTRUCTURE_BY_RADIUS = `${prefix}/LOADED_INFRASTRUCTURE_BY_RADIUS`;

export const LOAD_OVERLAP_POLYGON= `${prefix}/LOAD_OVERLAP_POLYGON`;
export const LOADING_OVERLAP_POLYGON = `${prefix}/LOADING_OVERLAP_POLYGON`;
export const LOADED_OVERLAP_POLYGON = `${prefix}/LOADED_OVERLAP_POLYGON`;
export const SET_FILTER_OVERLAP = `${prefix}/SET_FILTER_OVERLAP`;
export const SET_ACTIVE_ROAD_OVERLAP = `${prefix}/SET_ACTIVE_ROAD_OVERLAP`;
export const SET_ACTIVE_OVERLAP = `${prefix}/SET_ACTIVE_OVERLAP`;
export const LOAD_OVERLAP_LIST = `${prefix}/LOAD_OVERLAP_LIST`;
export const LOADED_OVERLAP_LIST = `${prefix}/LOADED_OVERLAP_LIST`;
export const LOADING_OVERLAP_LIST = `${prefix}/LOADING_OVERLAP_LIST`;
export const CLEAR_OVERLAP_LIST = `${prefix}/CLEAR_OVERLAP_LIST`;

export const LOAD_POLYGON_INFRASTRUCTURE = `${prefix}/LOAD_POLYGON_INFRASTRUCTURE`;
export const LOADING_POLYGON_INFRASTRUCTURE = `${prefix}/LOADING_POLYGON_INFRASTRUCTURE`;
export const LOADED_POLYGON_INFRASTRUCTURE = `${prefix}/LOADED_POLYGON_INFRASTRUCTURE`;
export const SET_FILTER_POLYGON_INFRASTRUCTURE = `${prefix}/SET_FILTER_POLYGON_INFRASTRUCTURE`;
export const SET_ACTIVE_POLYGON_INFRASTRUCTURE = `${prefix}/SET_ACTIVE_POLYGON_INFRASTRUCTURE`;

export const LOAD_INFRASTRUCTURE_SIDEBAR = `${prefix}/LOAD_INFRASTRUCTURE_SIDEBAR`;
export const LOADING_INFRASTRUCTURE_SIDEBAR = `${prefix}/LOADING_INFRASTRUCTURE_SIDEBAR`;
export const LOADED_INFRASTRUCTURE_SIDEBAR = `${prefix}/LOADED_INFRASTRUCTURE_SIDEBAR`;
export const CLEAR_INFRASTRUCTURE_SIDEBAR = `${prefix}/CLEAR_INFRASTRUCTURE_SIDEBAR`;

export const SAVED_INFRASTRUCTURE = `${prefix}/SAVED_INFRASTRUCTURE`;
export const LOAD_OVERLAP_TYPES = `${prefix}/LOAD_OVERLAP_TYPES`;
export const LOADED_OVERLAP_TYPES = `${prefix}/LOADED_OVERLAP_TYPES`;

export const LOAD_OVERLAP_SOURCE = `${prefix}/LOAD_OVERLAP_SOURCE`;
export const LOADED_OVERLAP_SOURCE = `${prefix}/LOADED_OVERLAP_SOURCE`;


export const LOAD_STREET_LIST = `${prefix}LOAD_STREET_LIST`;
export const LOADED_STREET_LIST = `${prefix}LOADED_STREET_LIST`;
export const LOADING_STREET_LIST = `${prefix}LOADING_STREET_LIST`;
export const CLEAR_STREET_LIST = `${prefix}CLEAR_STREET_LIST`;

export const LOAD_CATEGORY_TYPES = `${prefix}/LOAD_CATEGORY_TYPES`;
export const LOADED_CATEGORY_TYPES = `${prefix}/LOADED_CATEGORY_TYPES`;

// Словари для городских видеокамер
export const LOAD_VIDEO_CAMERAS_DICTIONARY_INFRASTRUCTURE_TYPES = `${prefix}/LOAD_VIDEO_CAMERAS_DICTIONARY_INFRASTRUCTURE_TYPES`;
export const LOADING_VIDEO_CAMERAS_DICTIONARY_INFRASTRUCTURE_TYPES = `${prefix}/LOADING_VIDEO_CAMERAS_DICTIONARY_INFRASTRUCTURE_TYPES`;
export const LOADED_VIDEO_CAMERAS_DICTIONARY_INFRASTRUCTURE_TYPES = `${prefix}/LOADED_VIDEO_CAMERAS_DICTIONARY_INFRASTRUCTURE_TYPES`;

export const LOAD_VIDEO_CAMERAS_DICTIONARY_COMMUTATOR = `${prefix}/LOAD_VIDEO_CAMERAS_DICTIONARY_COMMUTATOR`;
export const LOADING_VIDEO_CAMERAS_DICTIONARY_COMMUTATOR = `${prefix}/LOADING_VIDEO_CAMERAS_DICTIONARY_COMMUTATOR`;
export const LOADED_VIDEO_CAMERAS_DICTIONARY_COMMUTATOR = `${prefix}/LOADED_VIDEO_CAMERAS_DICTIONARY_COMMUTATOR`;

export const LOAD_VIDEO_CAMERAS_DICTIONARY_CONNECTION_LINE = `${prefix}/LOAD_VIDEO_CAMERAS_DICTIONARY_CONNECTION_LINE`;
export const LOADING_VIDEO_CAMERAS_DICTIONARY_CONNECTION_LINE = `${prefix}/LOADING_VIDEO_CAMERAS_DICTIONARY_CONNECTION_LINE`;
export const LOADED_VIDEO_CAMERAS_DICTIONARY_CONNECTION_LINE = `${prefix}/LOADED_VIDEO_CAMERAS_DICTIONARY_CONNECTION_LINE`;

export const LOAD_VIDEO_CAMERAS_DICTIONARY_CONNECTION = `${prefix}/LOAD_VIDEO_CAMERAS_DICTIONARY_CONNECTION`;
export const LOADING_VIDEO_CAMERAS_DICTIONARY_CONNECTION = `${prefix}/LOADING_VIDEO_CAMERAS_DICTIONARY_CONNECTION`;
export const LOADED_VIDEO_CAMERAS_DICTIONARY_CONNECTION = `${prefix}/LOADED_VIDEO_CAMERAS_DICTIONARY_CONNECTION`;

export const LOAD_VIDEO_CAMERAS_DICTIONARY_ELECTRICAL_OPTIONAL_SYSTEM = `${prefix}/LOAD_VIDEO_CAMERAS_DICTIONARY_ELECTRICAL_OPTIONAL_SYSTEM`;
export const LOADING_VIDEO_CAMERAS_DICTIONARY_ELECTRICAL_OPTIONAL_SYSTEM = `${prefix}/LOADING_VIDEO_CAMERAS_DICTIONARY_ELECTRICAL_OPTIONAL_SYSTEM`;
export const LOADED_VIDEO_CAMERAS_DICTIONARY_ELECTRICAL_OPTIONAL_SYSTEM = `${prefix}/LOADED_VIDEO_CAMERAS_DICTIONARY_ELECTRICAL_OPTIONAL_SYSTEM`;

export const LOAD_VIDEO_CAMERAS_DICTIONARY_DOCUMENT = `${prefix}/LOAD_VIDEO_CAMERAS_DICTIONARY_DOCUMENT`;
export const LOADING_VIDEO_CAMERAS_DICTIONARY_DOCUMENT = `${prefix}/LOADING_VIDEO_CAMERAS_DICTIONARY_DOCUMENT`;
export const LOADED_VIDEO_CAMERAS_DICTIONARY_DOCUMENT = `${prefix}/LOADED_VIDEO_CAMERAS_DICTIONARY_DOCUMENT`;

export const LOAD_VIDEO_CAMERAS_DICTIONARY_POWER = `${prefix}/LOAD_VIDEO_CAMERAS_DICTIONARY_POWER`;
export const LOADING_VIDEO_CAMERAS_DICTIONARY_POWER = `${prefix}/LOADING_VIDEO_CAMERAS_DICTIONARY_POWER`;
export const LOADED_VIDEO_CAMERAS_DICTIONARY_POWER = `${prefix}/LOADED_VIDEO_CAMERAS_DICTIONARY_POWER`;

export const LOAD_VIDEO_CAMERAS_DICTIONARY_RELIANCE = `${prefix}/LOAD_VIDEO_CAMERAS_DICTIONARY_RELIANCE`;
export const LOADING_VIDEO_CAMERAS_DICTIONARY_RELIANCE = `${prefix}/LOADING_VIDEO_CAMERAS_DICTIONARY_RELIANCE`;
export const LOADED_VIDEO_CAMERAS_DICTIONARY_RELIANCE = `${prefix}/LOADED_VIDEO_CAMERAS_DICTIONARY_RELIANCE`;

export const LOAD_COMMUTATORS = `${prefix}/LOAD_COMMUTATORS`;
export const LOADED_COMMUTATORS = `${prefix}/LOADED_COMMUTATORS`;
export const LOADING_COMMUTATORS = `${prefix}/LOADING_COMMUTATORS`;
export const CREATE_COMMUTATOR = `${prefix}/CREATE_COMMUTATOR`;
export const EDIT_COMMUTATOR = `${prefix}/EDIT_COMMUTATOR`;
export const DELETE_COMMUTATOR = `${prefix}/LOADING_COMMUTATOR`;
export const LOAD_COMMUTATOR_TYPES = `${prefix}/LOAD_COMMUTATOR_TYPES`;
export const LOADED_COMMUTATOR_TYPES = `${prefix}/LOADED_COMMUTATOR_TYPES`;

export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;

export const LOAD_CONNECTION_LINES = `${prefix}/LOAD_CONNECTION_LINES`;
export const LOADED_CONNECTION_LINES = `${prefix}/LOADED_CONNECTION_LINES`;
export const LOADING_CONNECTION_LINES = `${prefix}/LOADING_CONNECTION_LINES`;
export const CREATE_CONNECTION_LINE = `${prefix}/CREATE_CONNECTION_LINE`;
export const EDIT_CONNECTION_LINE = `${prefix}/EDIT_CONNECTION_LINE`;
export const DELETE_CONNECTION_LINE = `${prefix}/DELETE_CONNECTION_LINE`;

export const LOAD_CAMERAS = `${prefix}/LOAD_CAMERAS`;
export const LOADING_CAMERAS = `${prefix}/LOADING_CAMERAS`;
export const LOADED_CAMERAS = `${prefix}/LOADED_CAMERAS`;
export const CREATE_CAMERA = `${prefix}/CREATE_CAMERA`;
export const EDIT_CAMERA = `${prefix}/EDIT_CAMERA`;
export const DELETE_CAMERA = `${prefix}/DELETE_CAMERA`;

export const LOADING_CAMERA_TYPES = `${prefix}/LOADING_CAMERA_TYPES`;
export const LOAD_CAMERA_TYPES = `${prefix}/LOAD_CAMERA_TYPES`;
export const LOADED_CAMERA_TYPES = `${prefix}/LOADED_CAMERA_TYPES`;

export const LOAD_CAMERA_MODEL = `${prefix}/LOAD_CAMERA_MODEL`;
export const LOADING_CAMERA_MODEL = `${prefix}/LOADING_CAMERA_MODEL`;
export const LOADED_CAMERA_MODEL= `${prefix}/LOADED_CAMERA_MODEL`;
export const CREATE_CAMERA_MODEL = `${prefix}/CREATE_CAMERA_MODEL`;
export const EDIT_CAMERA_MODEL = `${prefix}/EDIT_CAMERA_MODEL`;
export const DELETE_CAMERA_MODEL = `${prefix}/DELETE_CAMERA_MODEL`;

export const LOAD_CAMERA_VENDOR = `${prefix}/LOAD_CAMERA_VENDOR`;
export const LOADING_CAMERA_VENDOR = `${prefix}/LOADING_CAMERA_VENDOR`;
export const LOADED_CAMERA_VENDOR= `${prefix}/LOADED_CAMERA_VENDOR`;
export const CREATE_CAMERA_VENDOR = `${prefix}/CREATE_CAMERA_VENDOR`;
export const EDIT_CAMERA_VENDOR = `${prefix}/EDIT_CAMERA_VENDOR`;
export const DELETE_CAMERA_VENDOR = `${prefix}/DELETE_CAMERA_VENDOR`;

export const LOAD_CONNECTION_POINTS = `${prefix}/LOAD_CONNECTION_POINTS`;
export const LOADED_CONNECTION_POINTS = `${prefix}/LOADED_CONNECTION_POINTS`;
export const LOADING_CONNECTION_POINTS = `${prefix}/LOADING_CONNECTION_POINTS`;
export const CREATE_CONNECTION_POINTS = `${prefix}/CREATE_CONNECTION_POINTS`;
export const EDIT_CONNECTION_POINTS = `${prefix}/EDIT_CONNECTION_POINTS`;
export const DELETE_CONNECTION_POINTS = `${prefix}/DELETE_CONNECTION_POINTS`;
export const LOAD_CONNECTION_POINT_TYPES = `${prefix}/LOAD_CONNECTION_POINT_TYPES`;
export const LOADED_CONNECTION_POINT_TYPES = `${prefix}/LOADED_CONNECTION_POINT_TYPES`;

export const LOAD_PILLARS = `${prefix}/LOAD_PILLARS`;
export const LOADED_PILLARS = `${prefix}/LOADED_PILLARS`;
export const LOADING_PILLARS = `${prefix}/LOADING_PILLARS`;
export const CREATE_PILLAR = `${prefix}/CREATE_PILLAR`;
export const EDIT_PILLAR = `${prefix}/EDIT_PILLAR`;
export const DELETE_PILLAR = `${prefix}/DELETE_PILLAR`;

export const LOAD_ELECTRICAL_OPTICAL_SYSTEMS = `${prefix}/LOAD_ELECTRICAL_OPTICAL_SYSTEMS`;
export const LOADED_ELECTRICAL_OPTICAL_SYSTEMS = `${prefix}/LOADED_ELECTRICAL_OPTICAL_SYSTEMS`;
export const LOADING_ELECTRICAL_OPTICAL_SYSTEMS = `${prefix}/LOADING_ELECTRICAL_OPTICAL_SYSTEMS`;
export const CREATE_ELECTRICAL_OPTICAL_SYSTEMS = `${prefix}/CREATE_ELECTRICAL_OPTICAL_SYSTEMS`;
export const EDIT_ELECTRICAL_OPTICAL_SYSTEMS = `${prefix}/EDIT_ELECTRICAL_OPTICAL_SYSTEMS`;
export const DELETE_ELECTRICAL_OPTICAL_SYSTEMS = `${prefix}/DELETE_ELECTRICAL_OPTICAL_SYSTEMS`;

export const LOAD_PAK_VENDORS = `${prefix}/LOAD_PAK_VENDORS`;
export const LOADED_PAK_VENDORS = `${prefix}/LOADED_PAK_VENDORS`;
export const LOADING_PAK_VENDORS = `${prefix}/LOADING_PAK_VENDORS`;
export const CREATE_PAK_VENDOR = `${prefix}/CREATE_PAK_VENDOR`;
export const EDIT_PAK_VENDOR = `${prefix}/EDIT_PAK_VENDOR`;
export const DELETE_PAK_VENDOR = `${prefix}/DELETE_PAK_VENDOR`;

export const LOAD_PAK_MODELS = `${prefix}/LOAD_PAK_MODELS`;
export const LOADED_PAK_MODELS = `${prefix}/LOADED_PAK_MODELS`;
export const LOADING_PAK_MODELS = `${prefix}/LOADING_PAK_MODELS`;
export const CREATE_PAK_MODEL = `${prefix}/CREATE_PAK_MODEL`;
export const EDIT_PAK_MODEL = `${prefix}/EDIT_PAK_MODEL`;
export const DELETE_PAK_MODEL = `${prefix}/DELETE_PAK_MODEL`;

export const LOAD_PAK_LIST = `${prefix}/LOAD_PAK_LIST`;
export const LOADED_PAK_LIST = `${prefix}/LOADED_PAK_LIST`;
export const LOADING_PAK_LIST = `${prefix}/LOADING_PAK_LIST`;
export const CREATE_PAK = `${prefix}/CREATE_PAK`;
export const EDIT_PAK = `${prefix}/EDIT_PAK`;
export const DELETE_PAK = `${prefix}/DELETE_PAK`;

export const LOAD_DVR = `${prefix}/LOAD_DVR`;
export const LOADING_DVR = `${prefix}/LOADING_DVR`;
export const LOADED_DVR = `${prefix}/LOADED_DVR`;
export const CREATE_DVR = `${prefix}/CREATE_DVR`;
export const EDIT_DVR = `${prefix}/EDIT_DVR`;
export const DELETE_DVR = `${prefix}/DELETE_DVR`;

export const LOAD_INFRASTRUCTURE_BY_POLYGON = `${prefix}/LOAD_INFRASTRUCTURE_BY_POLYGON`;
export const LOADING_INFRASTRUCTURE_BY_POLYGON = `${prefix}/LOADING_INFRASTRUCTURE_BY_POLYGON`;
export const LOADED_INFRASTRUCTURE_BY_POLYGON = `${prefix}/LOADED_INFRASTRUCTURE_BY_POLYGON`;

export const LOAD_INFRASTRUCTURE_LIST = `${prefix}/LOAD_INFRASTRUCTURE_LIST`;
export const LOADING_INFRASTRUCTURE_LIST = `${prefix}/LOADING_INFRASTRUCTURE_LIST`;
export const LOADED_INFRASTRUCTURE_LIST = `${prefix}/LOADED_INFRASTRUCTURE_LIST`;
export const SET_ACTIVE_INFRASTRUCTURE_LAYER = `${prefix}/SET_ACTIVE_INFRASTRUCTURE_LAYER`;
