import { useDispatch, useSelector } from 'react-redux';
import {
    loadDefectHeatMap,
    clearDefectHeatMap,
    filterDefectHeatMap,
} from '../../../redux/RoadWorks/actions';
import { roadworksSelectors } from '../../../redux/RoadWorks';


// хук - данные для тепловой карты
const useDefectHeatMap = () => {
    const dispatch = useDispatch();

    const defectHeatMap = useSelector(roadworksSelectors.defectHeatMap);
    const defectHeatMapLoading = useSelector(roadworksSelectors.defectHeatMapLoading);
    const defectHeatMapFilter = useSelector(roadworksSelectors.defectHeatMapFilter);

    return {
        // данные
        data: defectHeatMap,
        isData: defectHeatMap.length > 0,
        loading: defectHeatMapLoading,
        load: (params) => {
            dispatch(loadDefectHeatMap(params));
        },
        clear: () => {
            dispatch(clearDefectHeatMap());
        },
        // фильтр
        filter: defectHeatMapFilter,
        setFilter: (filter) => {
            dispatch(filterDefectHeatMap(filter));
        },
    };
};

export default useDefectHeatMap;
