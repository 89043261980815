import { useEffect, useState } from 'react';

enum showType {
    mobile = 'mobile',
    desktop = 'desktop',
}

interface inputProps {
    [show:string]: showType,
    children: any,
}

const Visible = ({
    show,
    children,
}: inputProps) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const width = document.documentElement.clientWidth;
            if (show === showType.mobile) {
                setVisible(width <= 900);
            }
            else if (show === showType.desktop) {
                setVisible(width > 900);
            }
            else setVisible(false);
        };

        window.addEventListener('resize', handleResize, true);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return visible
        ? children
        : null;
};

export default Visible;