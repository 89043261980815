import { useSelector } from 'react-redux';

import colorExtend from 'helpers/mapHelper/colorExtend';
import { trafficLightsSelectors } from 'redux/TrafficLights';
import icon from '../icons/icon';
import Information from './Information';

const PopUp = (props) => {
    const { external_id } = props;
    const polygon = useSelector(trafficLightsSelectors.polygon);
    const data = polygon.find(({ id }) => external_id === id) || {};
    const { name, color } = data;

    return (
        <div className="map-popup">
            <div
                className="map-popup_header"
                style={{
                    backgroundColor: colorExtend(color)
                }}
            >
                <div className="map-popup_header_">
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                    <span>
                        {name}
                    </span>
                </div>
            </div>
            <div className="map-popup_body">
                <Information
                    data={data}
                />
            </div>
        </div>
    );
};

export default PopUp;
