import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { pick } from 'lodash';

import * as actions from '../../../redux/TransportPassenger/actions';

// подгрузить контрольные точки
const useLoadRouteCheckPoints = (callback = () => {}) => {
    const dispatch = useDispatch();
    const {
        routeWithCheckPoints,
        loadingRouteWithCheckPoints,
    } = useSelector(
        ({ transportPassenger }) =>
            pick(
                transportPassenger,
                [
                    'routeWithCheckPoints',
                    'loadingRouteWithCheckPoints'
                ]
            )
    );
    const [id, setId] = useState(0);

    useEffect(() => {
        if (id) {
            const item = routeWithCheckPoints[id] || null;
            if (item) {
                setId(0);
                callback(item);
            }
        }
    },[routeWithCheckPoints, id]);

    const findByRoute = (route) => {
        const item = routeWithCheckPoints[route.id] || null;
        if (item) {
            callback(item);
        } else {
            setId(route.id);
            dispatch(
                actions.loadRouteWithCheckPoints(
                    { num: route.num, category_id: route.category_id }
                )
            );
        }
    };

    const findByRouteList = (route) => {
        dispatch(
            actions.loadRouteWithCheckPoints(
                { num: route.num, category_id: route.category_id },
                callback
            )
        );
    };

    const loading = loadingRouteWithCheckPoints;

    const clear = () => {
        dispatch(actions.clearRouteWithCheckPoints());
    };

    return {
        findByRoute,
        findByRouteList,
        loading,
        clear,
        id,
    };
};

export default useLoadRouteCheckPoints;
