import { useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import {
    deleteTicketPoint,
    loadTicketPointTypes
} from 'redux/TransportPassenger/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import AddEditModal from './AddEditModal';
import TicketPointMapModal from './TicketPointMapModal';

import type { IPermissions } from 'types';
import type { Ticket } from './types';

interface ItemProps {
    data: Ticket;
    reloadList: () => void;
    permissions: IPermissions
}

function Item ({ data, reloadList, permissions }: ItemProps) {
    const dispatch = useDispatch();

    const ticketPointTypes = useStoreProp(
        loadTicketPointTypes,
        'transportPassenger',
        'ticketPointTypes'
    );

    const [modalOpen, setModalOpen] = useState(false);
    const [mapModalOpen, setMapModalOpen] = useState(false);
    const [confirmDeleteOpen, setConfirmOpen] = useState(false);

    const handleDelete = () => dispatch(deleteTicketPoint(data?.id, reloadList));

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {data?.name || messages.INFO_IS_NOT_FOUND}
                </LSContentColumn>

                <LSContentColumn>
                    {data?.phone || messages.INFO_IS_NOT_FOUND}
                </LSContentColumn>

                <LSContentColumn>
                    {data?.mode || messages.INFO_IS_NOT_FOUND}
                </LSContentColumn>

                <LSContentColumn>
                    {ticketPointTypes[data?.type] || messages.INFO_IS_NOT_FOUND}
                </LSContentColumn>

                <LSContentColumn>
                    {data?.address_text || messages.INFO_IS_NOT_FOUND}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <i className="fas fa-map-marked-alt"/>,
                                name: titles.SHOW_ON_MAP,
                                onClick: () => setMapModalOpen(true),
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setModalOpen(true),
                                disabled: !permissions.is_update
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setConfirmOpen(true),
                                disabled: !permissions.is_delete
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {modalOpen
                && <AddEditModal
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    data={data}
                    reloadList={reloadList}
                />
            }
            {mapModalOpen && (
                <TicketPointMapModal
                    isOpen={mapModalOpen}
                    onClose={() => setMapModalOpen(false)}
                    address_text={data?.address_text}
                    latlon={[data.address.geo_lat, data.address.geo_lon]}
                />
            )}
            {confirmDeleteOpen && (
                <ConfirmDelete
                    open={confirmDeleteOpen}
                    message={messages.CONFIRM_DELETE}
                    onClose={() => setConfirmOpen(false)}
                    onSuccess={handleDelete}
                />
            )}
        </>
    );
}

export default Item;
