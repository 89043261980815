import React from 'react';
import { ListItem } from '@mui/material';
import styles from './videoFixation.module.scss';
import { numberValidator } from '../../../../helpers/numberValidator';
import { useSelector } from 'react-redux';
import messages from '../../../../helpers/constants/messages';


function Item({ item }) {
    const { violations } = useSelector(({ dorisControl }) => dorisControl);

    return (
        <ListItem className={styles.listItem}>
            <div className={styles.listItem__content}>
                <div className={styles.listItem__text_block}>
                    <div className={styles.text}>
                        {item?.created_at || messages.INFO_IS_NOT_FOUND}
                    </div>
                    <div className={styles.text}>
                        <span>Номер ТС: </span>
                        {/* {numberValidator(item.grz) || messages.INFO_IS_NOT_FOUND} */}
                        {item.grz || messages.INFO_IS_NOT_FOUND}
                    </div>
                    <div className={styles.text}>
                        <span>Камера: </span>
                        {item.camera?.name || messages.INFO_IS_NOT_FOUND}
                    </div>
                </div>
                <div className={styles.listItem__text_block}>
                    <div className={styles.text}>
                        <span>Марка ТС: </span>
                        {item.vehicle_brand || messages.INFO_IS_NOT_FOUND}
                    </div>
                    <div className={styles.text}>
                        <span>Модель ТС: </span>
                        {item.vehicle_model || messages.INFO_IS_NOT_FOUND}
                    </div>
                    <div className={styles.text}>
                        <span>Цвет ТС: </span>
                        {item.vehicle_color || messages.INFO_IS_NOT_FOUND}
                    </div>
                    <div className={styles.text}>
                        <span>Тип события: </span>
                        {violations[item.violation_type] || messages.INFO_IS_NOT_FOUND}
                    </div>
                </div>
            </div>
        </ListItem>
    );
}

export default Item;