import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';

import Modal from '../Modal';
import FormButtons, { buttonsTypes } from '../FormButtons';

interface ConfirmModalProps {
    open: boolean,
    onClose: () => void,
    onSuccess: () => void,
    message?: string,
    onlyClose?: boolean,
    loadingButton?: boolean,
    test_id_prefix?: string,
}

const ConfirmModal = ({
    open,
    onClose,
    onSuccess,
    message = messages.CONFIRM_DELETE,
    onlyClose,
    loadingButton,
    test_id_prefix = '',
}: ConfirmModalProps) => {
    return (
        <Modal
            onClose={onClose}
            isOpen={open}
            title={message}
            buttons={
                <FormButtons
                    buttons={onlyClose
                        ? [
                            {
                                ...buttonsTypes.close,
                                name: buttons.CLOSE,
                                onClick: onClose,
                                testName: 'close',
                            }]
                        : [
                            {
                                ...buttonsTypes.close,
                                name: buttons.NO,
                                disabled: loadingButton,
                                onClick: onClose,
                                testName: 'close',
                            },
                            {
                                ...buttonsTypes.apply,
                                name: buttons.YES,
                                onClick: onSuccess,
                                disabled: loadingButton,
                                loading: loadingButton,
                                testName: 'apply',
                            }]
                    }
                    test_id_prefix={test_id_prefix}
                />
            }
        />
    );
};

export default ConfirmModal;
