import L from 'leaflet';
import React, { useEffect, useRef } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from '../../../../store';
import { v4 as uuid_v4 } from 'uuid';


// попап для кластера
export const ClusterPopUp = ({
    map,
    parent,
    children,
    minWidth = 500,
    closeOnClick = true,
    className = 'custom-popup-wrapper',
    cluster = null, // кластер
    isMoveOnClick = false,
    offset,
}) => {
    const refId = useRef(null);
    const refPopup = useRef(null);

    const context = (clusterProp) => (
        <Provider store={store}>
            {React.cloneElement(children, {
                cluster: clusterProp.layer
            })}
        </Provider>
    );

    const handleShow = (clusterProp) => {

        if (isMoveOnClick) {
            map.setView(clusterProp.latlng);
        }

        setTimeout(() => {
            const options = {
                minWidth,
                closeOnClick,
                className: `${className}`,
            };
            if (offset) options.offset = offset;

            const popup = L.popup(options)
                .setLatLng(clusterProp.latlng)
                .setContent(`<div class="popup-route" id="${refId.current}"></div>`)
                .openOn(map);
            refPopup.current = popup;

            setTimeout(() => {
                if (document.getElementById(refId.current)){
                    document.getElementById(refId.current) && unmountComponentAtNode(document.getElementById(refId.current));
                    render(
                        context(clusterProp),
                        document.getElementById(refId.current)
                    );
                }
            }, 300);
        }, isMoveOnClick ? 400 : 1);
    };

    const handleClose = () => {
        document.getElementById(refId.current) && unmountComponentAtNode(document.getElementById(refId.current));
    };

    const handleClosePopupAll = () => {
        if (map && refPopup.current) {
            handleClose();
            refPopup.current.remove();
        }
    };

    // закрытие модалки
    useEffect(() => {
        if (map && refId.current) {
            map.on('popupclose', handleClose);

            return () => {
                map.off('popupclose', handleClose);
            };
        }
    }, [map, refId.current]);

    // клик по кластеру
    useEffect(() => {
        if (cluster && refId.current) {
            cluster.on('clusterclick', handleShow);
            cluster.on('animationend', handleClosePopupAll);

            return () => {
                cluster.off('clusterclick', handleShow);
                cluster.off('animationend', handleClosePopupAll);
            };
        }
    }, [cluster, refId.current]);

    useEffect(() => {
        if (map && refId.current) {
            map.on('close_popup_all', handleClosePopupAll);

            return () => {
                map && map.on('close_popup_all', handleClosePopupAll);
            };
        }
    }, [map, refId.current]);

    useEffect(() => {
        refId.current = uuid_v4();

        return () => {
            if (refPopup.current) {
                handleClose();
                refPopup.current.remove();
            }
        };
    }, []);

    return null;
};

export default ClusterPopUp;
