import { ListItem, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import messages from 'helpers/constants/messages';
import { getTimeWithoutSecond } from 'helpers/date.config';

const useStyles = makeStyles({
    item_text: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    item: {
        textAlign: 'left',
        marginLeft: '1.5rem',
        flex: 1,
    }
});

const StationItem = ({ item = {}, setOpenEditStation, index }) => {
    const styles = useStyles();

    const TimeComponent = ({ value }) => <span className={styles.item}>
        {getTimeWithoutSecond(value) || <span className="error link" onClick={setOpenEditStation}>{messages.NOT_CHOSEN}</span>}
    </span>;

    return (
        <ListItem dense>
            <ListItemText className={styles.item_text} disableTypography>
                <span style={{ width: 20 }} >{index + 1}</span>
                <span style={{ wordBreak: 'break-word' }} className={styles.item}>{item?.station?.name || item?.check_point?.name}</span>
                <TimeComponent value={item?.time_start} />
                <TimeComponent value={item?.time_end} />
                <span className={styles.item}>{item.mileage} км.</span>
            </ListItemText>
        </ListItem>
    );
};

export default StationItem;
