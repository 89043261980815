import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import api from './api.methods';
import * as types from './types';
import { showMessage } from '../Message/actions';
import messagetypes from '../Message/messagetypes';
import messages from '../../helpers/constants/messages';


function* loadGovernmentContractSaga({ payload, callback }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingGovernmentContract(true));
    const response = yield call(api.getGovernmentContracts, page, limit, params);
    if (response?.success) {
        if (callback) {
            // если храним ГК только в локальном стейте
            callback(response.data);
        } else {
            yield put(actions.loadedGovernmentContacts(response));
        }
    }
    yield put(actions.loadingGovernmentContract(false));
}

function* loadGovernmentContractByIdSaga({ payload, callback }) {
    yield put(actions.loadingGovernmentContractById(true));
    const response = yield call(api.getGovernmentContractById, payload);
    if (response?.success) {
        if (callback) {
            // если храним ГК только в локальном стейте
            callback(response.data);
        } else {
            yield put(actions.loadedGovernmentContactById(response?.data));
        }
    }
    yield put(actions.loadingGovernmentContractById(false));
}

function* createGovernmentContractSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createGovernmentContract, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* editGovernmentContractSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.editGovernmentContract, id, data);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.EDIT_SUCCESS));
        callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* deleteGovernmentContractSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.deleteGovernmentContract, id, data);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
        callback?.(true);
    }
    yield put(actions.loadingButton(false));
}

function* loadGovernmentContractHistorySaga({ payload }) {
    const { id, page, limit } = payload;
    yield put(actions.loadingGovernmentContract(true));
    const response = yield call(api.getGovernmentContractHistory, id, page, limit);
    if (response?.success) {
        yield put(actions.loadedGovernmentContactHistory(response));
    }
    yield put(actions.loadingGovernmentContract(false));
}

function* loadContractEntitiesByNameSaga({ payload, callback }) {
    const { id, entity_name } = payload;
    yield put(actions.loadingContractEntitiesByName(true));
    const response = yield call(api.getContractEntitiesByName, id, entity_name);
    if (response?.success) {
        callback?.(response?.data);
    }
    yield put(actions.loadingContractEntitiesByName(false));
}

function* createContractEntitiesByNameSaga({ payload, callback }) {
    const { id, entity_name, params } = payload;
    const response = yield call(api.createContractEntitiesByName, id, entity_name, params);
    if (response?.success) {
        callback?.();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

function* loadContractEntitiesTypesSaga({ callback }) {
    const response = yield call(api.getContractEntitiesTypes);
    if (response?.success) {
        callback
            ? callback?.(response?.data)
            : yield put(actions.loadedContractEntitiesTypes(response?.data));
    }
}

function* loadContractLinkObjectsSaga({ payload, callback }) {
    const { url, page, limit, params } = payload;

    yield put(actions.loadingContractLinkObjects(true));
    const response = yield call(api.getContractLinkObjects, url, page, limit, params);
    if (response?.success) {
        yield put(actions.loadedContractLinkObjects(response));
    }
    yield put(actions.loadingContractLinkObjects(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_GOVERNMENT_CONTRACTS, loadGovernmentContractSaga),
        takeLatest(types.LOAD_GOVERNMENT_CONTRACT_BY_ID, loadGovernmentContractByIdSaga),
        takeLatest(types.CREATE_GOVERNMENT_CONTRACTS, createGovernmentContractSaga),
        takeLatest(types.EDIT_GOVERNMENT_CONTRACTS, editGovernmentContractSaga),
        takeLatest(types.DELETE_GOVERNMENT_CONTRACTS, deleteGovernmentContractSaga),
        takeLatest(types.LOAD_GOVERNMENT_CONTRACT_HISTORY, loadGovernmentContractHistorySaga),
        
        takeLatest(types.LOAD_CONTRACT_ENTITIES_BY_NAME, loadContractEntitiesByNameSaga),
        takeLatest(types.CREATE_CONTRACT_ENTITIES_BY_NAME, createContractEntitiesByNameSaga),
        takeLatest(types.LOAD_CONTRACT_ENTITIES_TYPES, loadContractEntitiesTypesSaga),
        takeLatest(types.LOAD_CONTRACT_LINK_OBJECTS, loadContractLinkObjectsSaga),

    ]);
}