import L from 'leaflet';
import React, { useEffect, useState } from 'react';
import 'leaflet.marker.slideto';
import { usePrevious } from '../../../../helpers/hooks';
import { isEqual } from 'lodash';
import renderChild from './helper/renderChild';

// маркер карты
const CircleMarker = ({
    map,
    area = null,
    parent,
    latlng,
    // attribution = null,
    onClick = null,
    children,
    onDragEnd = null,
    onInit,
    moveToAfterInit = false,
    radius = 6,
    weight = 1,
    color = '#000',
    fillColor = '#f00',
    fillOpacity = 1,
    parentType = '',
    ...prop
}) => {
    const prevLatLng = usePrevious(latlng);
    const [marker, setMarker] = useState(null);

    // меркер передвинут
    const onMarkerDrag = ({
        target: {
            _latlng: { lat, lng: lon },
        }
    }) => {
        onDragEnd({ lat, lon });
    };

    const handleClick = () => {
        onClick(marker.getLatLng());
    };

    useEffect(() => {
        if (marker && onDragEnd) {
            marker.on('dragend', onMarkerDrag);
            return () => {
                marker.off('dragend', onMarkerDrag);
            };
        }
    }, [onDragEnd, marker]);

    useEffect(() => {
        const newMarker = new L
            .CircleMarker(latlng, {
                radius,
                weight,
                color,
                fillColor,
                fillOpacity
            });

        if (area && parentType === 'circle-marker') {
            parent.addEventParent(newMarker);
            area.addLayer(newMarker);
        } else {
            parent.addLayer(newMarker);
        }

        setMarker(newMarker);

        onInit && onInit(newMarker);

        // сдвинуть карту к метке после инициализации
        if (moveToAfterInit) {
            const { zoom = 15, center = null } = typeof moveToAfterInit === 'object' ? moveToAfterInit : {};
            map.setView(center || newMarker.getLatLng(), zoom);
        }

        return () => {
            if (area && parentType === 'circle-marker') {
                area.removeLayer(newMarker);
            } else {
                parent.removeLayer(newMarker);
            }
            newMarker.remove();
            setMarker(null);
        };
    }, [parent]);

    useEffect(() => {
        if (onClick && marker) {
            marker.on('click', handleClick);

            return () => {
                marker.off('click', handleClick);
            };
        }
    }, [onClick, marker]);

    useEffect(() => {
        if (marker && !isEqual(prevLatLng, latlng)) {
            marker.setLatLng(latlng);
        }
    }, [latlng, marker]);

    return marker
        ? renderChild(
            children,
            {
                map,
                ...prop,
                parent: marker,
                parentType: 'circle-marker',
                area: parent
            }
        )
        : null;
};

export default CircleMarker;
