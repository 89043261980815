import { config } from '../../../../../config';
import { Button } from '@mui/material';

const Subscribe = () => {
    const telegramLink = config.get('overlapSubscribeLink') || null;

    const goToLink = () => {
        window.open(telegramLink, '_blank');
    };

    if (telegramLink) {
        return (
            <Button onClick={goToLink} type="text" variant="outlined" size="small">
                Подписка
            </Button>
        );
    }
    return null;
};

export default Subscribe;