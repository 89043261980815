import { useStoreFromSelector } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import { getFullDateTimeWithTextMonth } from 'helpers/date.config';
import { loadStatusesVideoCamera } from 'redux/VideoCameras/actions';
import { videoCamerasSelectors } from 'redux/VideoCameras';


const Information = (props) => {
    const { data } = props;
    const statuses = useStoreFromSelector(loadStatusesVideoCamera, videoCamerasSelectors.videoCameraStatuses);

    const fields = {
        name: {
            name: 'Название',
        },
        extrenal_id: {
            name: 'Внешний идентификатор',
        },
        status: {
            name: 'Статус',
            f: ({ status }) => statuses.length === 0 ? messages.LOADING : (statuses.find(st => st.id === status)?.name || messages.INFO_IS_NOT_FOUND)
        },
        address_text: {
            name: 'Адрес',
        },

        created_at: {
            name: 'Создано',
            f: ({ created_at }) => getFullDateTimeWithTextMonth(created_at) || messages.INFO_IS_NOT_FOUND
        },

        updated_at: {
            name: 'Изменено',
            f: ({ created_at }) => getFullDateTimeWithTextMonth(created_at) || messages.INFO_IS_NOT_FOUND
        },
        lat_lon: {
            name: 'Координаты (lat, lon)',
            f: ({ lat, lon }) => `${lat} ${lon}`
        },
    };

    const RenderList = () => {
        if (!data) {
            return <div className="not-found">{messages.INFO_IS_NOT_FOUND}</div>;
        }

        return (
            <div className="information scroll">
                {Object.keys(fields).map((field) => {
                    const f = fields[field].f ? fields[field].f(data) : null;
                    const value = f || data[field] || null;
                    const name = fields[field].name || '';

                    return (
                        <div className="info__item" key={`information_${field}`}>
                            <span className="info__item-title">
                                {name}
                                {': '}
                            </span>
                            <span>
                                {value || messages.INFO_IS_NOT_FOUND}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div>
            {/*<Scrollbars style={{ height: '210px' }}>*/}
            <RenderList />
            {/*</Scrollbars>*/}
        </div>
    );
};

export default Information;
