import React, { useState } from 'react';
import FormButtons, { buttonsTypes } from '../../../../../../common/FormButtons';
import Modal from '../../../../../../common/Modal';
import DragAndDrop from '../../../../../../common/DragAndDrop';
import messages from '../../../../../../../helpers/constants/messages';
import { useDispatch } from 'react-redux';
import { editSequenceStages } from '../../../../../../../redux/RoadWorks/actions';
import makeStyles from '@mui/styles/makeStyles';
import { ListItemText } from '@mui/material';

const useStyles = makeStyles({
    index: {
        marginRight: 20,
        color: 'red',
        fontSize: '1rem'
    },
    name: {
        fontWeight: 'bold',
        fontSize: '1rem'
    }
});

const OrderStageSequencesModal = ({ onClose, isOpen, currentStages, reloadList }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [data, setData] = useState(currentStages);

    const onChange = (newItems) => setData(newItems.map((item, index) => ({ ...item, number: index + 1 })));

    const handleEditSequencesStage = () => {
        const result = data.reduce((acc, item) => {
            return { ...acc, [item.id]: item.number };
        }, {});

        dispatch(editSequenceStages({ 'numbers': result }, () => {
            reloadList();
            onClose();
        }));
    };

    const listRender = (item, index) => {
        return (
            <ListItemText key={item.id}>
                <span className={classes.index}>{index + 1}</span>
                <span className={classes.name}>{item.name}</span>
            </ListItemText>
        );
    };

    return (
        <Modal
            title="Изменить порядок последовательности этапов"
            onClose={onClose}
            maxWidthProp={'sm'}
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleEditSequencesStage
                    }
                ]}
            />}
        >
            {currentStages
                ? <DragAndDrop
                    list={data}
                    itemsRender={listRender}
                    onChange={onChange}
                />
                : <h2>{messages.DATA_IS_NOT_FOUND}</h2>
            }

        </Modal>
    );
};

export default OrderStageSequencesModal;
