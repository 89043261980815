import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SelectRoutes from 'components/common/Autocomplete/PassengerTransport/Routes';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import formatDate from 'helpers/constants/dateFormat';

const Filter = ({ setParams, initialState, params, handleFind }) => {
    
    const resetFilter = (needRefresh) => {
        if (needRefresh) {
            handleFind(initialState);
        }
        setParams(initialState);
    };

    const handleChange = (value, name) => {
        setParams({
            ...params, [name]: value
        });
    };

    const updateFilter = (filters) => {
        setParams({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            handleFind({
                ...initialState,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={handleFind} 
            filter={params} 
            setUserFilter={updateFilter}
            disabled={!params.date}
            filterException={['date']}
        >
            <LayoutFilter.Visible>
                <SelectCompany
                    selected={params.organization_id}
                    onChange={(value) => handleChange(value, 'organization_id')}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                />
                <SelectRoutes
                    selected={params.route_id_list}
                    onChange={(value) => handleChange(value, 'route_id_list')}
                    multiple={true}
                />
                <SingleKeyboardDateTimePicker
                    label={'Дата'}
                    onChange={(value) => handleChange(value, 'date')}
                    value={params.date}
                    pickerFormat={formatDate.DATE_FOR_PICKER}
                    dateOnly={true}
                    required={true}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
