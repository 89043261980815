import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import { roadworksSelectors } from 'redux/RoadWorks';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import GroupAndWorksModal from './GroupAndWorksModal';

const GroupAndWorks = ({ works, handleClickSave, stage }) => {
    // это компонент со списком групп и работ, и модалкой для их редактирования

    const [modalOpen, setModalOpen] = useState(false);
    const stage_schedule = useSelector(roadworksSelectors.stage_schedule);

    const stageScheduleObj = stage_schedule.reduce((res, el) => {
        const { stage_road_work_id, amount_plan, amount, date_at } = el;

        if (date_at >= dateWithDashYYYYMMDD(stage.start_date_plan) && date_at <= dateWithDashYYYYMMDD(stage.end_date_plan)) {
            res[stage_road_work_id] = {
                plan: (res[stage_road_work_id]?.plan || 0) + Number(amount_plan),
                fact: (res[stage_road_work_id]?.fact || 0) + Number(amount)
            };
        }
    
        return res;
    }, {});

    return (
        <>
            {/* если не предусмотрен како-либо функционал а просто отрисовать список */}
            {modalOpen && handleClickSave && (
                <GroupAndWorksModal
                    workData={works}
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    handleClickSave={(data) => {
                        handleClickSave(data);
                        setModalOpen(false);
                    }}
                />
            )}
            <>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            {''}
                        </ListItemIcon>
                        <ListItemText disableTypography  className="row">
                            {/* <h3 className="row__item">Группа Работ</h3> */}
                            <h3 className="row__item">Работа</h3>
                            <h3 className="row__item">План</h3>
                            <h3 className="row__item">Факт</h3>
                            <h3 className="row__item">Единицы измерения</h3>
                        </ListItemText>
                    </ListItem>
                    {works.map((el, i) => 
                        <ListItem key={el.work?.id}>
                            <ListItemIcon>
                                {i + 1}
                            </ListItemIcon>
                            <ListItemText disableTypography className="row">
                                {/* <span className="row__item">
                                    {el.group?.name || messages.INFO_IS_NOT_FOUND}
                                </span> */}
                                <span  className="row__item">
                                    {el.work?.name || messages.INFO_IS_NOT_FOUND}
                                </span>
                                <span  className="row__item">
                                    {stageScheduleObj[el.work?.stage_road_work_id]?.plan ?? messages.INFO_IS_NOT_FOUND}
                                </span>
                                <span  className="row__item">
                                    {stageScheduleObj[el.work?.stage_road_work_id]?.fact ?? messages.INFO_IS_NOT_FOUND}
                                </span>
                                <span  className="row__item">
                                    {el.units?.long_name || messages.INFO_IS_NOT_FOUND}
                                </span>
                            </ListItemText>
                        </ListItem>
                    )}
                </List>
                <div className="filter filter__wrap__btn">
                    <FormButtons
                        buttons={[{
                            ...buttonsTypes.defaultInfo,
                            name: 'Изменить',
                            size: 'small',
                            onClick: ()=> setModalOpen(true),
                        }]}
                    /> 
                </div>
            </>
        </>
    );
};

export default GroupAndWorks;