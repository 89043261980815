import { config } from '../../config';

const apiMeteoUrl = () => config.get('urls').apiMeteoUrl;

const apiUrls = {
    // проекты
    getProjects: () => `${apiMeteoUrl()}/projects`,

    // типы
    getDeviceTypes: () => `${apiMeteoUrl()}/device/types`,

    // список
    getDeviceList: () => `${apiMeteoUrl()}/devices`,

    getDitLink: () => `${apiMeteoUrl()}/dit_link`,

    // связи с организациями
    postDeviceCompanyLink: (id) => `${apiMeteoUrl()}/device/${id}/organizations`,

    deleteDeviceCompanyLink: (id) => `${apiMeteoUrl()}/device/${id}/organizations`,

    getDeviceCompanyLink: (id) => `${apiMeteoUrl()}/device/${id}/organizations`,

    // полигон
    getForPolygonDevices: () => `${apiMeteoUrl()}/polygon/devices`,

    // данные
    getDeviceData: () => `${apiMeteoUrl()}/device/data`,

    //события
    getEvents: () => `${apiMeteoUrl()}/events`,

    // статусы
    getStatuses: () => `${apiMeteoUrl()}/status`,

    getMeteoSettings: () => `${apiMeteoUrl()}/settings`,

    editMeteoSettings: (id) => `${apiMeteoUrl()}/settings/${id}`,

    editSettingsList: () => `${apiMeteoUrl()}/settings/list`,

    // оповещении
    getAlerts: () => `${apiMeteoUrl()}/device/organizations/alerts`,

    // настройки вывода параметров
    getMeteoOutputParams: () => `${apiMeteoUrl()}/device/params`,

    getMeteoOutputSettings: () => `${apiMeteoUrl()}/device/output_settings`,

    editMeteoOutputSettings: (id) => `${apiMeteoUrl()}/device/output_settings/${id}`,

    deleteMeteoOutputSettings: (id) => `${apiMeteoUrl()}/device/output_settings/${id}`,

    getDevicesGroup: () => `${apiMeteoUrl()}/devices_group`,

    createDevicesGroup: () => `${apiMeteoUrl()}/devices_group`,

    editDevicesGroup: (id) => `${apiMeteoUrl()}/devices_group/${id}`,

    deleteDevicesGroup: (id) => `${apiMeteoUrl()}/devices_group/${id}`,

    loadShowcase: () => `${apiMeteoUrl()}/showcase/layer2`,

    loadMeteoByRadius: () => `${apiMeteoUrl()}/device/radius`,

    getSettingsGroup: () => `${apiMeteoUrl()}/settings_group`,

    editSettingsGroup: (id) => `${apiMeteoUrl()}/settings_group/${id}/settings/update`,

    getDeviceStatuses: () => `${apiMeteoUrl()}/v2/device/statuses`,
};

export default apiUrls;
