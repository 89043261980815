import Modal from '../../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import { loadWorkCalendarHistory } from '../../../../../../redux/TransportPassenger/actions';
import Loading from '../../../../../common/Loading';
import { transportPassengerSelectors } from '../../../../../../redux/TransportPassenger';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import CalendarSection from './CalendarSection';
import messages from '../../../../../../helpers/constants/messages';
import HistoryList from './HistoryList';

const HistoryModal = ({ isOpen, onClose, params, monthsNames }) => {
    const dispatch = useDispatch();

    const data = useSelector(transportPassengerSelectors.workCalendarHistory);
    const loading = useSelector(transportPassengerSelectors.workCalendarHistoryLoading);

    useEffect(() => {
        dispatch(loadWorkCalendarHistory({ year: params?.year }));
    }, [dispatch, params?.year]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={'История изменений'}
            noPadding
            fullWidth
            // maxWidthProp={'xl'}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    }
                ]}
            />}
        >
            <div className="modal__form" style={{ background: '#f5f5f5' }}>
                {loading && <Loading circular={true}/>}
                {Object.keys(data).length > 0 
                    ? (
                        <>
                            <CalendarSection
                                title="Календарь до изменений"
                                calendarData={data.initial}
                                monthsNames={monthsNames}
                            />
                            <CalendarSection
                                title="Календарь после изменений"
                                calendarData={data.current}
                                monthsNames={monthsNames}
                                history={data?.history}
                            />
                            <HistoryList
                                history={data?.history}
                                year={params?.year}
                            />
                        </>
                    )
                    : !loading && messages.DATA_IS_NOT_FOUND
                }
               
            </div>
        </Modal>
    );
};

export default HistoryModal;
