import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { loadRoutes, loadVehicleCategories } from 'redux/TransportPassenger/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const SelectRoutes = ({
    multiple = false,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label = titles.ROUTE,
    filter = {}, // параметры для запроса на АПИ
    error = false,
    helperText = '',
    isClone = false,
    routeForTrafficSchedule = false,
    limitTags = 1,
    test_id_prefix = '', // для автотестов
}) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    const handleClose = () => setShowList(false);

    const categories = useStoreProp(
        loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    );

    const getCategory = (id) => {
        const res = categories.find((item) => item.id === id);
        return res?.name || '';
    };

    const getRoutes = (params) => {
        const { page, limit, query: num } = params;
        const paramsWithoutEmpty = removeEmptyFields({
            ...(num && { num }),
            status: [2],
            ...filter,
        });
        dispatch(loadRoutes(page, limit, paramsWithoutEmpty, routeForTrafficSchedule));
    };

    const onReset = () => {
        const initialState = multiple ? [] : '';
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="route_id"
                disabled={disabled || isClone}
                onReset={onReset}
                renderLabel={(option) => `${option?.category_id ? (getCategory(option?.category_id) + ' ') : ''}${option?.num ? ('№' + option?.num + ' ') : ''}${option?.name ? option?.name : ''}`}
                limitTags={limitTags}
                label={label}
                onShowList={!isClone && (() => setShowList(true))}
                error={error}
                helperText={helperText}
                test_id_prefix={test_id_prefix}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getRoutes}
                    storeName="transportPassenger"
                    storeNameProps={routeForTrafficSchedule ? 'routesForTS' : 'routes'}
                    storeLoadingProps={'loadingRoutes'}
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div>
                        <strong>{getCategory(el.category_id)} №{el.num}</strong>
                        <span> {el.name}</span>{' '}
                        {el.is_direct_text && <span> <strong>тип:</strong> {el.is_direct_text}</span>}
                    </div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={titles.SELECT_ROUTE}
                    test_id_prefix={test_id_prefix}
                />
            }
        </>
    );
};

export default SelectRoutes;
