import { Collapse, List, ListItem } from '@mui/material';
import messages from 'helpers/constants/messages';

const ItemInfo = ({ isOpen, item, company = {} }) => {
    return (
        <Collapse in={isOpen} timeout="auto" unmountOnExit className="info">
            <div className="block">
                <div className="row">
                    <b>Организация:</b>
                    {company?.title}
                </div>
                <div className="block">
                    <b>Сотрудники (ФИО и должность):</b>
                    {item?.employees.length > 0
                        ? <List>
                            {item?.employees?.map(item => {
                                return (
                                    <ListItem key={item?.id}>
                                        {item?.fio_full}{', '}{item?.position?.name}
                                    </ListItem>
                                );
                            })}

                        </List>
                        : <div>{messages.NO_DATA}</div>
                    }
                </div>
            </div>
        </Collapse>
    );
};

export default ItemInfo;
