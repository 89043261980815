import React, { useEffect, useState } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Button
} from '@mui/material/';
import { userSignIn } from '../../../redux/Auth/actions.js';
import { clearValidation, setValidation } from '../../../redux/Validation/actions';
import titles from '../../../helpers/constants/titles';
import Loading from '../../common/Loading';
import { buttonsTypes } from '../../common/FormButtons';
import { authSelectors } from '../../../redux/Auth';

const SignIn = ({ history }) => {
    const dispatch = useDispatch();
    const [remember, setRemember] = useState(true);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const disabledBtn = !username || !password;

    const loading = useSelector(authSelectors.loading);
    const authUser = useSelector(authSelectors.authUser);
    const validation = useSelector(({ validation }) => validation);

    useEffect(() => {
        dispatch(clearValidation());
    }, [dispatch]);

    useEffect(() => {
        if (authUser) {
            history.push('/');
        }
        //dispatch(clearValidation());
    }, [authUser]);

    const handleClick = (e) => {
        e.preventDefault();
        dispatch(clearValidation());
        dispatch(
            userSignIn({
                username,
                password,
                remember
            })
        );
    };

    const handleChange = (event, setValue) => {
        event.preventDefault();
        const { name, value } =  event.target;
        setValue(value);
        if (validation[name]) delete validation[name];
        dispatch(setValidation({
            ...validation
        }));
    };

    return (
        <div className="signin">
            <div className="signin-form">
                <h2>Вход</h2>
                <FormGroup>
                    <TextField
                        label="Логин"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => handleChange(e, setUsername)}
                        defaultValue={username}
                        margin="dense"
                        className="mt-1 my-sm-3"
                        name="username"
                        error={!!validation.username}
                        helperText={validation.username}
                    />
                    <TextField
                        type="password"
                        variant="outlined"
                        label="Пароль"
                        fullWidth
                        onChange={(e) => handleChange(e, setPassword)}
                        defaultValue={password}
                        name="password"
                        margin="dense"
                        className="mt-1 my-sm-3"
                        error={!!validation.password}
                        helperText={validation.password}
                    />
                </FormGroup>
                <div
                    className="links"
                >
                    <FormControlLabel
                        control={(
                            <Checkbox
                                color="primary"
                                checked={remember}
                                onChange={(event) => setRemember(event.target.checked)}
                            />
                        )}
                        label="Запомнить меня"
                    />
                    {/* <Link
                        to="/reset-password"
                        title={titles.RESET_PASSWORD}
                    >
                        {titles.RESET_PASSWORD}
                    </Link> */}
                </div>
                <div className="login_button">
                    <Button
                        {...buttonsTypes.defaultPrimary}
                        onClick={handleClick}
                        disabled={disabledBtn}
                    >
                        {titles.SIGN_IN}
                    </Button>
                </div>
                {loading && <Loading circular={true}/>}
            </div>
        </div>
    );
};

export default SignIn;
