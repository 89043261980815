import { useState } from 'react';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import {
    loadThreatLevels,
    loadTransportIncidentTypeCreation,
} from 'redux/SituationalPlans/actions';
import { fullDateTimeWithTimeZone, } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import useStoreProp from 'helpers/hooks/useStoreProp';
import SelectTransportIncidentsTypes from 'components/common/Autocomplete/DorisReact/TransportIncidentsTypes';
import SelectScenarios from 'components/common/Autocomplete/DorisReact/Scenarios';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';


const Filter = ({
    params,
    isEventPlans,
    setParams,
    onReset,
    statuses,
    initialState,
    id
}) => {
    const threatLevels = useStoreProp(loadThreatLevels, 'SituationalPlans', 'threatLevels');
    const typeCreation = useStoreProp(loadTransportIncidentTypeCreation, 'SituationalPlans', 'transportIncidentTypeCreation');

    const [filter, setFilter] = useState(initialState);

    const handleChange = (newValue) => {
        setFilter({ ...filter, ...newValue });
    };

    const resetFilter = (needRefresh) => {
        setFilter(initialState);
        onReset(needRefresh);
    };

    const updateFilter = (filters) => { 
        if (Object.keys(filters).length) {
            const newFilters = {
                ...initialState,
                ...filters
            };

            setFilter(newFilters);
            // обновляем фильтр, только если у нас не переход по id
            if (!id) {
                setParams(newFilters);
            }
        }
    };
  
    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={() => setParams(filter)}
            filter={filter} 
            filterException={['start_date', 'end_date']} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            countException={['scenario', 'incidentType']}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filter?.start_date}
                    valueEndDate={filter?.end_date}
                    handleDate={(value) => handleChange({ start_date: fullDateTimeWithTimeZone(value) })}
                    handleEndDate={(value) => handleChange({ end_date:  fullDateTimeWithTimeZone(value) })}
                    startLabel={'Начало периода'}
                    endLabel={'Конец периода'}
                />

                <TextField
                    label={titles.NAME}
                    size="small"
                    value={filter.name}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={(e) => handleChange({ [e.target.name]: e.target.value })}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <FormControl size="small" variant="outlined">
                    <TextField
                        label={titles.ADDRESS}
                        variant="outlined"
                        size="small"
                        value={filter.address_text}
                        name="address_text"
                        onChange={(e) => handleChange({ [e.target.name]: e.target.value })}
                        type="text"
                    />
                </FormControl>
                <FormControl variant="outlined" size="small">
                    <InputLabel>{titles.THREAT_LEVEL}</InputLabel>
                    <Select
                        label={titles.THREAT_LEVEL}
                        onChange={(e) => handleChange({ [e.target.name]: e.target.value })}
                        name="threat_level_id"
                        value={filter?.threat_level_id}
                    >
                        {threatLevels?.data?.map(item => <MenuItem key={item.id} value={item}>{item.name}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" size="small">
                    <Autocomplete
                        noOptionsText={titles.NO_RESULTS}
                        multiple
                        value={filter?.status_id_list}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        options={statuses}
                        filterSelectedOptions={true}
                        getOptionLabel={(option) => option?.name}
                        onChange={(_, value) => handleChange({ status_id_list:  value })}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label={titles.STATUS}
                            />
                        )}
                    />
                </FormControl>

                {!isEventPlans
                    && <SelectTransportIncidentsTypes
                        selected={filter?.incidentType}
                        onChange={(val) => {
                            handleChange({
                                incidentType: val,
                                type_id: val.id
                            });
                        }}
                        label="Тип"
                    />
                }

                <SelectScenarios
                    selected={filter?.scenario}
                    onChange={(val) => {
                        handleChange({
                            scenario: val,
                            response_scenario_id: val.id
                        });
                    }}
                />
                <FormControl variant="outlined" size="small">
                    <InputLabel>Способ создания</InputLabel>
                    <Select
                        label="Способ создания"
                        onChange={(e) => handleChange({ [e.target.name]: e.target.value })}
                        name="type_creation"
                        value={filter?.type_creation}
                    >
                        {Object.keys(typeCreation)?.map(key => <MenuItem key={key} value={key}>{typeCreation[key]}</MenuItem>)}
                    </Select>
                </FormControl>
            </LayoutFilter.Invisible>
        </LayoutFilter>



    // <div className="filter__wrap filter__wrap__btn">
    //     <GetReportFields
    //         additionalParameters={additionalParameters}
    //         handleDownload={handleDownload}
    //     />

    //     <FormButtons
    //         buttons={[
    //             {
    //                 ...buttonsTypes.resetFilter,
    //                 onClick: handleResetFilter,
    //             },
    //             {
    //                 ...buttonsTypes.search,
    //                 onClick: handleSearch,
    //                 disabled: isDisabled
    //             }
    //         ]}
    //     />
    // </div>
    );
};

export default Filter;
