const transportTypes = {
    1: {
        name: 'Автобус',
        iconName: '3',
        color: '#4b9c4b',
        fill: '#67E186',
        outline: '#00693A',
        type: 'Bus',
    },
    2: {
        name: 'Троллейбус',
        iconName: '1',
        color: '#0fa3b0',
        fill: '#87D4D6',
        outline: '#006782',
        type: 'Trolleybus',
    },
    3: {
        name: 'Трамвай',
        iconName: '2',
        color: '#da573e',
        fill: '#FF7F48',
        outline: '#743B00',
        type: 'Tram',
    },
    4: {
        name: 'Маршрутное такси',
        iconName: '2',
        color: '#c88500',
        fill: '#ffa500',
        outline: '#c17f0e',
        type: 'Shuttlebus',
    },
    default: {
        fill: '#000',
        outline: '#fff'
    }
};

export default transportTypes;

// проверка есть ли вообще картинка ил есть ли у нее base64 префикс
export const checkBase64 = (img) => {
    if (img && img.indexOf('base64,') < 0) {
        return `data:image/png;base64,${img}`;
    }
    return img;
};
