import React from 'react';
import {
    Typography,
} from '@mui/material';

import Item from './Item';
import './style.scss';

const SortCheckbox = ({
    data = [], 
    sortedList = {}, 
    onChange,
    disabled = false,
    test_id_prefix = ''
}) => {    
    const {
        ASC,
        DESC,
        title = '',
    } = sortedList;
    
    const [ ascSort ] = ASC || [];
    const [ descSort ] = DESC || [];
        
    const newList = ascSort
        ?.fields
        ?.reduce((res, value) => {
            const isValueInDesc = descSort?.fields?.find(el => el.name === value.name);
            res.push({
                ...value,
                sort: [
                    {
                        name: ascSort.name,
                        name_text: ascSort.name_text
                    },
                    (isValueInDesc && {
                        name: descSort.name,
                        name_text: descSort.name_text
                    }),
                ]
            });
            return res;
        }, []);
    
    
    const handleChange = (field, direction) => {
        const fieldIndex = data.findIndex(el => (el.field === field));
        const fieldAndDirectionIndex = data.findIndex(el => (el.field === field && el.direction === direction));

        onChange(fieldIndex < 0
            ? [
                ...data,
                { field, direction },

            ]
            : [
                ...data.slice(0, fieldIndex),
                ...(fieldAndDirectionIndex < 0 ? [{ field, direction }] : []),
                ...data.slice(fieldIndex + 1)
            ]
        );
    };
    const renderTitle = () => title
        ? (
            <Typography
                variant="subtitle2"
                component="span"
                className="name"
            >
                {title}:
            </Typography>
        )
        : null;

    return (
        <div
            className="sort-checkbox row align-items-center"
            data-testid={`${test_id_prefix}:sort/checkbox`}
        >
            {renderTitle()}
            {newList?.length
                && newList.map((field, index) => (
                    <Item
                        key={field.name ?? index}
                        selected={data.some(el => el.field === field.name)}
                        field={field}
                        onChange={(sortName) => handleChange(field.name, sortName)}
                        checkedItems={data.find(el => el.field === field.name)}
                        disabled={disabled}
                    />
                ))
            }            
        </div>
    );
};

export default SortCheckbox;
