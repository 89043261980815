import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { loadParksList, loadScheduleTemplateCheckpoints, loadTrafficScheduleListOfEvents } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { getTimeWithoutSecond, getTimeWithoutSeconds, isDateValid } from 'helpers/date.config';
import formatDate from 'helpers/constants/dateFormat';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import SelectScheduleTemplate from 'components/common/Autocomplete/PassengerTransport/ScheduleTemplate';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import UniversalSelect from 'components/common/UniversalSelect';
import Loading from 'components/common/Loading';

const useStyles = makeStyles({
    root: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red'
        }
    },
});

const AddEventItemModal = ({ 
    isOpen, 
    onClose, 
    data, 
    setData,
    temporaryData,
    setTemporaryData,
    indexOfElement,
    setIndexOfElement,
    initialState,
    recalculation,
    routeDirectNum, 
    routeBackNum
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const scheduleTemplateCheckpoints = useSelector(transportPassengerSelectors.scheduleTemplateCheckpoints);
    const loadingScheduleTemplateCheckpoints = useSelector(transportPassengerSelectors.loadingScheduleTemplateCheckpoints);

    // справочник видов событий
    const events = useStoreProp(
        loadTrafficScheduleListOfEvents, 
        'transportPassenger', 
        'trafficScheduleListOfEvents'
    );

    const [type, setType] = useState(''); //записываем тип события: Событие, Рейс, Выезд из парка, Заезд в парк
    const [placeOfAdding, setPlaceOfAdding] = useState(null); //расположение нового элемента относительно выбранной строки: выше или ниже
    const [scheduleTemplate, setScheduleTemplate] = useState({});

    const addNewItem = () => {
        const prepareData = {
            duration: type === 'Рейс' ? data.duration : getTimeWithoutSeconds(data.duration),
            entity_id: data.entity_id,
            entity_list: type === 'Рейс'
                ? data.entity_list
                    .map((el) => {
                        return {
                            duration: el?.duration,
                            entity_id: 0,
                            entity_list: [],
                            entity_type: 'App\\Models\\Station',
                            event_id: 0,
                            event_text: '',
                            is_direct: false,
                            interval: el?.interval,
                            mileage: el?.mileage,
                            name: el?.station?.name ?? el?.check_point?.name,
                            traffic_schedule_id: data.traffic_schedule_id,
                            time_start: el?.time_start,
                            time_end: el?.time_end,
                        };
                    })
                : [],
            entity_type: data.entity_type,
            event_id: Number(data?.event_id), //даже если будет пустая строка - получим 0, нам этого достаточно
            event_text: type === 'Выезд из парка' || type === 'Заезд в парк'
                ? type
                : type === 'Событие'
                    ? events[data.event_id]
                    : '',
            is_direct: data.is_direct,
            mileage: data.mileage,
            name: data?.name,
            traffic_schedule_id: data.traffic_schedule_id,
            is_new: true //для окрашиваний новой строки, обратно с бэка не возвращается
        };

        const array = [ ...temporaryData ];
        array.splice(indexOfElement, 0, { ...prepareData });
        const newArray = recalculation(array);

        setTemporaryData(newArray);

        closeForm();
    };

    //блок работы с датой
    const [isErrorDate, setIsErrorDate] = useState(false); //для сохранения ошибок в дате
    const checkInvalidDate = useCallback((date) => {
        return date && !isDateValid(date);
    }, []);

    useEffect(() => {
        setIsErrorDate(!!checkInvalidDate(data?.duration));
        
    }, [checkInvalidDate, data?.duration]);

    //Выбор парка
    const [park, setPark] = useState({});

    const [isOpenParksUniversalSelect, setIsOpenParksUniversalSelect] = useState(false);

    const getParks = (params) => {
        const { page, limit, query } = params;
        dispatch(loadParksList(page, limit, { name: query || null }));
    };
    
    const handleSelectPark = (event) => {
        setPark({ ...event });
        setData({
            ...data,
            name: event.name,
            entity_type: 'App\\Models\\Park',
            event_id: type === 'Выезд из парка' ? 1 : 10, //выезд из парка 1, заезд в парк 10
            event_text: type,
        });
        setIsOpenParksUniversalSelect(false);
    };

    const handleSelectScheduleTemplate = (event) => {
        setScheduleTemplate({ ...event });
        setData({
            ...data,
            duration: event?.duration,
            entity_type: 'App\\Models\\Flight',
            mileage: event?.mileage,
            name: event?.name, // temporaryData?.find(el => el?.entity_type === 'App\\Models\\Flight')?.name, - было до
            is_direct: event?.route?.is_direct,
            event_id: 0
        });
        dispatch(loadScheduleTemplateCheckpoints(event?.id));
    };

    const onChangeData = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
            entity_type: name === 'event_id'
                ? 'App\\Models\\ScheduleEvent'
                : data.entity_type,
            event_text: name === 'event_id'
                ? events[data.event_id]
                : data.event_text
        });
    };

    const closeForm = () => {
        setType('');
        setScheduleTemplate({});
        setPark({});
        setPlaceOfAdding(null);
        onClose();
    };

    // подгружаем чек-поинты и остановки:
    useEffect(() => {
        if (scheduleTemplateCheckpoints[scheduleTemplate?.id]) {
            setData((prevData) => {
                return {
                    ...prevData,
                    entity_list: [...scheduleTemplateCheckpoints[scheduleTemplate?.id]]
                };
            });
        }
    },[scheduleTemplateCheckpoints, scheduleTemplate?.id]);

    const isDisabled = type === 'Событие'
        ? !data?.event_id
        || !data?.duration
        || data?.duration === 'Invalid Date'
        || isErrorDate
        // || !data.mileage
        || !placeOfAdding
        : type === 'Рейс'
            ? !scheduleTemplate?.name
            || loadingScheduleTemplateCheckpoints
            || !placeOfAdding
            : type === 'Выезд из парка' || type === 'Заезд в парк'
                ? !park
                || !data?.duration
                || data?.duration === 'Invalid Date'
                || isErrorDate
                // || !data.mileage
                || !placeOfAdding
                : !type;


    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title="Добавить событие"
            small={true}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: closeForm
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: addNewItem,
                            disabled: isDisabled
                        },
                    ]}
                />
            }
        >
            <form className="modal__form">
                <FormControl className="block" size="small" variant="outlined" required >
                    <InputLabel style={ placeOfAdding ? { color: 'rgba(0, 0, 0, 0.54)' } : { color: '#ff0000' } }>Выберите место добавления</InputLabel>
                    <Select
                        value={placeOfAdding || ''}
                        onChange={(e) => {
                            setPlaceOfAdding(e.target.value);
                            setIndexOfElement(e.target.value === 'up' ? indexOfElement : indexOfElement + 1);
                        }}
                        label="Выберите место добавления"
                        name="size"
                        error={!placeOfAdding}
                        required
                    >
                        <MenuItem value="up">
                            Выше выбранной строки
                        </MenuItem>
                        <MenuItem value="down">
                            Ниже выбранной строки
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl className="block" size="small" variant="outlined" required >
                    <InputLabel style={ type ? { color: 'rgba(0, 0, 0, 0.54)' } : { color: '#ff0000' } }>Выберите тип события</InputLabel>
                    <Select
                        value={type || ''}
                        onChange={(e) => {
                            setType(e.target.value);
                            setData(initialState);
                            setScheduleTemplate({});
                            setPark({});
                        }}
                        label="Выберите тип события"
                        name="size"
                        error={!type}
                        required
                    >
                        <MenuItem value="Событие">
                            Событие
                        </MenuItem>
                        <MenuItem value="Рейс">
                            Рейс
                        </MenuItem>
                        <MenuItem value="Выезд из парка">
                            Выезд из парка
                        </MenuItem>
                        <MenuItem value="Заезд в парк">
                            Заезд в парк
                        </MenuItem>
                    </Select>
                </FormControl>
                {type === 'Событие' && (
                    <>
                        <FormControl className="block" size="small" variant="outlined" required >
                            <InputLabel style={ data.event_id ? { color: 'rgba(0, 0, 0, 0.54)' } : { color: '#ff0000' }}>Выберите событие</InputLabel>
                            <Select
                                value={data.event_id}
                                onChange={onChangeData}
                                label="Выберите событие"
                                name="event_id"
                                error={!data.event_id}
                                required={true}
                            >
                                {Object.keys(events)?.length > 0
                                    ? Object.keys(events)?.map((key) =>
                                        <MenuItem
                                            disabled={Number(key) === 1 || Number(key) === 10}
                                            value={key}
                                            key={key}
                                        >
                                            {events[key]}
                                        </MenuItem>
                                    )
                                    : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                                }
                            </Select>
                        </FormControl>
                        <SingleKeyboardDateTimePicker
                            timeOnly
                            label="Продолжительность (ЧЧ:ММ)"
                            format={formatDate.HH_MM}
                            value={data?.duration || null}
                            required
                            onChange={(e) => setData({
                                ...data,
                                duration: e
                            })}
                            error={checkInvalidDate(data?.duration) || !data?.duration}
                        />
                        <TextField
                            className="block"
                            label="Пробег (км)"
                            size="small"
                            value={data.mileage}
                            variant="outlined"
                            name="mileage"
                            type="text"
                            // error={!data.mileage}
                            onChange={onChangeData}
                            // required={true}
                        />
                    </>
                )}
                {type === 'Рейс' && (
                    <>
                        {loadingScheduleTemplateCheckpoints && <Loading circular={true}/>}
                        <SelectScheduleTemplate
                            selected={scheduleTemplate}
                            required
                            onChange={handleSelectScheduleTemplate}
                            filter={{
                                nums: [routeDirectNum, routeBackNum],
                                with_stations: true
                            }}

                            error={!scheduleTemplate?.name}
                            renderDirect
                        />
                        {scheduleTemplate?.duration && <FormHelperText>Продолжительность рейса (чч:мм): {getTimeWithoutSecond(scheduleTemplate?.duration)}</FormHelperText>}
                    </>
                )}
                {(type === 'Выезд из парка' || type === 'Заезд в парк') && (
                    <>
                        <div className="block">
                            <TextField
                                className={(type === 'Выезд из парка' || type === 'Заезд в парк') && data.name ? '' : classes.root}
                                value={data?.name || ''}
                                label="Парк"
                                variant="outlined"
                                size="small"
                                disabled={true}
                                required={true}
                                error={!data?.name}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={titles.CLICK_TO_SELECT}>
                                                <IconButton size="small" onClick={() => setIsOpenParksUniversalSelect(true)}>
                                                    <i className="fas fa-search-plus"/>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <SingleKeyboardDateTimePicker
                                timeOnly
                                label="Продолжительность (ЧЧ:ММ)"
                                format={formatDate.HH_MM}
                                value={data?.duration || null}
                                required
                                onChange={(e) => setData({
                                    ...data,
                                    duration: e
                                })}
                                error={checkInvalidDate(data?.duration) || !data?.duration}
                            />
                            <TextField
                                className="block"
                                label="Пробег (км)"
                                size="small"
                                value={data.mileage}
                                variant="outlined"
                                name="mileage"
                                type="text"
                                onChange={onChangeData}
                            />
                        </div>
                        {isOpenParksUniversalSelect && (
                            <Modal
                                isOpen={isOpenParksUniversalSelect}
                                onClose={() => setIsOpenParksUniversalSelect(false)}
                                noPadding={true}
                                label="Парк"
                                small={true}
                                buttons={
                                    <FormButtons
                                        buttons={[
                                            {
                                                ...buttonsTypes.close,
                                                onClick: () => setIsOpenParksUniversalSelect(false)
                                            },
                                        ]}
                                    />
                                }
                            >
                                <UniversalSelect
                                    storeName="transportPassenger"
                                    storeNameProps="parks"
                                    visibleProp="name"
                                    keyProp="id"
                                    fetchList={getParks}
                                    withSearch={true}
                                    isSelected={true}
                                    onChange={handleSelectPark}
                                    selected={park}
                                    searchTitle="Поиск по названию"
                                />
                            </Modal>
                        )}
                    </>
                )}
            </form>
        </Modal>
    );
};

export default AddEventItemModal;
