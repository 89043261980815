import React, { useEffect } from 'react';
import { List } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loadRouteNums } from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import RouteItem from './RouteItem';
import messages from '../../../../../helpers/constants/messages';
import makeStyles from '@mui/styles/makeStyles';
import Loading from '../../../../common/Loading';

const useStyles = makeStyles({
    title: {
        paddingBottom: 25
    }
});

const VehicleRoute = () => {
    const routeNums = useSelector( transportPassengerSelectors.routeNums);
    const routeNumsLoading = useSelector( transportPassengerSelectors.routeNumsLoading);
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect( () => {
        if (Object.keys(routeNums).length === 0){
            dispatch(loadRouteNums());
        }
    }, [dispatch]);

    const newNums = Object.keys(routeNums).reduce((res, type) => ([
        ...res,
        ...routeNums[type].map(
            (item) => ({ ...item, type })
        )
    ]), []);

    return (
        <>
            <h1 className={classes.title}>Присвоение ТС маршруту</h1>
            {routeNumsLoading && <Loading linear={true}/>}
            <List>
                {newNums?.length > 0
                    ? (newNums.map((item) => (
                        <RouteItem item={item} key={`${item.num}_${item.type}`}  />
                    )))
                    : (!routeNumsLoading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
                }
            </List>
        </>
    );
};

export default VehicleRoute;
