import * as types from './types';

const initialState = {
    // car: {},
    carLoading: false
};

export default function reducer (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        // case types.LOADED_OSRM_CAR:
        //     return {
        //         ...state,
        //         car: payload,
        //     };

        case types.LOADING_OSRM_CAR:
            return {
                ...state,
                carLoading: payload,
            };

        default:
            return state;
    }
};
