import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';
import { renderToString } from 'react-dom/server';
import { useHistory } from 'react-router-dom';
import { Point } from 'leaflet/dist/leaflet-src.esm';
import { isEqual } from 'lodash';

import { useWsSubscribe } from 'helpers/ws/hooks';
import useTransportCoords from 'helpers/hooks/Transport/useTransportCoords';
import useTransportCategory from 'helpers/hooks/Transport/useTransportCategory';
import { usePrevious } from 'helpers/hooks';
import HistoryMap from 'components/common/Transport/TransportHistory/HistoryMap';
import MapLegends from 'components/common/Transport/MapLegends';
import MapLegendList from 'components/common/Transport/MapLegendList';
import getFilters from 'components/MapComponents/helpers/getFilters';
import * as actions from 'redux/TransportRoad/actions';
import { showMessage, messageTypes } from 'redux/Message/actions';
import { setWsVehicle as setWsVehicleTR, setFilterWsOrganizations } from 'redux/TransportRoad/actions';
import { transportRoadSelectors } from 'redux/TransportRoad';
import { authSelectors } from 'redux/Auth';
import Markers from './Markers';
import config from './config';
import CollectorForms from './CollectorForms';
import CPopUp from './PopUp';
import icon from './icons/icon';
import './style.scss';

const Layer = (props) => {
    const {
        map,
        messageNotCurrentVehicle = 'В данный момент выбранного транспорта нет на карте.',
        centeredByVehicle,
        readOnly = false
    } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const active = useSelector(transportRoadSelectors.active);
    const filters = useSelector(transportRoadSelectors.filters);
    const wsData = useSelector(transportRoadSelectors.wsData);
    const routesHistory = useSelector(transportRoadSelectors.routesHistory);
    const coordsLoading = useSelector(transportRoadSelectors.coordsLoading);

    const profileOrganizationIdList = useSelector((state) => authSelectors.authUserInfo(state)?.organization_id_list || []);
    const profileFilters = useSelector((state) => authSelectors.authUserInfo(state)?.filters || null);

    // фильтруем по организациям из профиля если нужно
    useEffect(() => {
        const isOrganizationIdList = profileFilters?.organization_id_list || false;
        if (isOrganizationIdList) {
            dispatch(setFilterWsOrganizations(
                profileOrganizationIdList,
                isOrganizationIdList
            ));
        }
    }, [profileOrganizationIdList, profileFilters]);

    // сбрасываем фильтрацию
    useEffect(() => () => {
        dispatch(setFilterWsOrganizations(
            [],
            false
        ));
    }, []);

    const iconSize = new Point(30, 30);

    const prevFilters = usePrevious(filters);

    const isCenteredByVehicle = useRef(false);

    const getTransportCategory = useTransportCategory(
        actions.loadCategoriesVehicle,
        'transportRoad',
        'categoriesVehicle'
    );
    // const categoryTS = useStoreProp(
    //     actions.loadCategoriesVehicle,
    //     'transportRoad',
    //     'categoriesVehicle'
    // );

    useWsSubscribe('transport-road_vehicle_update_model_v2', (events) => {
        dispatch(setWsVehicleTR(events));
    });

    const specialCoords = useTransportCoords(
        actions.loadCoords,
        actions.clearCoords,
        actions.setMarkers,
        'transportRoad.coords'
    );

    const handleShow = (e) => {
        const { lat, lng } = e.latlng;
        dispatch(actions.setShowFindVehicles({ lat, lon: lng }));
        map.fire('context_menu_close');
    };

    useEffect(() => {
        map.on(config.mapContextMenu.event, handleShow);

        return () => {
            map.off(config.mapContextMenu.event, handleShow);
        };
    }, [map]);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            const filter = getFilters(filters);
            specialCoords.load(filter);
        }
    }, [filters]);

    useEffect(() => {
        const filter = getFilters(filters);
        specialCoords.load(filter);

        return () => {
            map.closeContextMenu();
            dispatch(actions.clearWsTelemetry());
        };
    }, []);

    useEffect(() => {
        // сдвигаем карту к машине
        if (
            active
            && Object.keys(active).length > 0
            && !active.isClick
            && coordsLoading === false
        ) {
            const number = active?.characteristics?.serial_egts !== '' ? `${active?.characteristics?.serial_egts}`
                : (active?.characteristics?.serial_yandex !== '' ? `${active?.characteristics?.serial_yandex}` : 0);

            const current = active?.findVehicle && active?.lat && active?.lon
                ? active
                : wsData[number] || null;

            if (current) {
                const { lat, lon } = current;
                if (lat && lon) {
                    map.setView([lat, lon], 14, {
                        animate: false
                    });
                }
            } else {
                dispatch(
                    showMessage(
                        messageTypes.warning,
                        '',
                        messageNotCurrentVehicle,
                        null,
                        2000
                    )
                );
            }
        }
    }, [active, coordsLoading]);

    useEffect(() => {
        if (centeredByVehicle && isCenteredByVehicle.current === false) {
            const number = active?.characteristics?.serial_egts !== '' ? `${active?.characteristics?.serial_egts}`
                : (active?.characteristics?.serial_yandex !== '' ? `${active?.characteristics?.serial_yandex}` : 0);
            const current = wsData[number] || null;
            if (current) {
                const { lat = null, lon = null } = current;
                if (lat && lon) {
                    map.setView([lat, lon], 14);
                }
                isCenteredByVehicle.current = true;
            }
        }
    }, [centeredByVehicle, active, wsData]);

    const createIcon = ({ currentShow, in_registry, in_work_order, category_id, isBorder }) => {
        const {
            getImageTpl,
            color,
        } = getTransportCategory
            ?.getHelper
            ?.getCategory(
                category_id,
                in_registry,
                in_work_order
            );

        const style = { background: color };
        //&& isBorder
        // if (currentShow) style.boxShadow = '0 0 5px 5px #fff';


        const imageTpl = (
            <div className="circle" style={style}>
                <div className="icon">
                    {getImageTpl('image-inner margin', icon({ fill: '#000' }))}
                </div>
            </div>
        );

        return L.divIcon({
            html: renderToString(imageTpl),
            // className: `transport-special-marker-icon ${currentShow ? 'active' : ''}`,
            className: 'transport-special-marker-icon',
            iconSize
        });
    };

    if (getTransportCategory?.isLoaded === false) return null;

    return (
        <>
            {/* машинки */}
            <Markers
                {...props}
                // data={markers}
                icon={createIcon}
                popUpTemplate={(key) => (
                    <CPopUp
                        uuid={key}
                        history={history}
                        readOnly={readOnly}
                    />
                )}
                toolTipTemplate={({ external_id, vehicle = null }) => (
                    <div>
                        {vehicle?.number ? <span>ГРЗ Номер: {vehicle?.number}</span> : <span><strong>Идентификатор:</strong>&nbsp;{external_id}</span>}
                    </div>
                )}
                toolTipOption={{
                    offset: [0, -15],
                    direction: 'top'
                }}
                onDestroy={actions.clearWsTelemetry}
                onClosePopUp={() => {
                    dispatch(actions.clearActive());
                    dispatch(actions.loadedVehicleActive({}));
                }}
            />

            {/* история маршрута - новая */}
            {Object.keys(routesHistory).map((key) => (
                <HistoryMap
                    key={key}
                    {...props}
                    data={routesHistory[key]}
                />
            ))}

            <CollectorForms />

            {/* легенда */}
            <MapLegends
                // category={getTransportCategory?.categories}
                layer="roadwork-transport"
                visibleRequired={readOnly}
                // isVisible={getTransportCategory?.categories?.length > 0}
            >
                <MapLegendList
                    category={getTransportCategory?.categories}
                    noImage={{
                        color: '#8c8c8c',
                        name: 'нет иконки в категории',
                        image: icon({ fill: '#000' })
                    }}
                    fromService={'roadWorks'}
                />
            </MapLegends>
        </>
    );
};

export default Layer;
