import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';

import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';
import Loading from 'components/common/Loading';
import { loadWorkorderEvents } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';

import WorkorderControlMap from './EventMap';
import EventItem from './EventItem';
import TableHeader from './TableHeader';

const renderDaysOfWeek = (str) => {
    const week = {
        0: { name: 'пн' },
        1: { name: 'вт' },
        2: { name: 'ср' },
        3: { name: 'чт' },
        4: { name: 'пт' },
        5: { name: 'сб' },
        6: { name: 'вс' },
    };

    const newArr = [];
    str.split('').forEach((day, index) => day === '1' ? newArr.push(week[index]?.name) : null);

    return newArr;
};

const WorkorderEvents = ({ workOrderId, workOrder, work_order_event_id }) => {
    const dispatch = useDispatch();

    const loadingWorkorderEvents = useSelector(transportPassengerSelectors.loadingWorkorderEvents);
    const workorderEvents = useSelector(transportPassengerSelectors.workorderEvents);

    const [selectedEvent, setSelectedEvent] = useState({});

    const handleSelectEvent = (value) => {
        setSelectedEvent(prevState => prevState?.id === value?.id ? {} : value);
    };

    useEffect(() => {
        if (workOrderId) {
            dispatch(loadWorkorderEvents(workOrderId));
        }
    }, [workOrderId, dispatch]);

    const renderTrafficScheduleInfo = () => (
        <div>
            <div>
                <span><b>Смена</b>: {workOrder?.traffic_schedule?.shift || messages.INFO_IS_NOT_FOUND} </span>
                <span><b>Бригада</b>:</span>{' '}
                {workOrder?.brigades?.length > 0
                    ? workOrder?.brigades?.map(item => <span key={item.id}>{item?.name}</span>)
                    : messages.INFO_IS_NOT_FOUND
                }
            </div>
            <div>
                <span>
                    <b>Начало графика</b>: {workOrder?.traffic_schedule?.start_at
                        ? fullDateTime(workOrder?.traffic_schedule?.start_at)
                        : messages.INFO_IS_NOT_FOUND
                    }&nbsp;
                </span>
                <span>
                    <b>Окончание графика</b>: {workOrder?.traffic_schedule?.end_at
                        ? fullDateTime(workOrder?.traffic_schedule?.end_at)
                        : messages.INFO_IS_NOT_FOUND
                    }
                </span>
            </div>
            <div><span><b>Тип: </b> {workOrder?.traffic_schedule?.type_text}</span></div>
            <div>
                <span><b>Дни недели: </b> {workOrder?.traffic_schedule?.days_week?.length > 0
                    ? renderDaysOfWeek(workOrder?.traffic_schedule?.days_week)
                        ?.map((render,index) =>
                            <span key={render}>
                                {render}
                                {(renderDaysOfWeek(workOrder?.traffic_schedule?.days_week)?.length - 1) !== index
                                    ? ', '
                                    : ''
                                }
                            </span>)
                    : '–'} </span>
                <span><b>Праздничные дни: </b> {workOrder?.traffic_schedule?.is_holiday ? 'да' : 'нет'} </span>
            </div>
        </div>
    );

    return (
        <>
            {loadingWorkorderEvents && <Loading linear={true}/>}

            {workorderEvents[workOrderId] && workorderEvents[workOrderId]?.length > 0
                ? <>
                    {renderTrafficScheduleInfo()}
                    <List className="list">
                        <TableHeader />
                        {workorderEvents[workOrderId]?.map((item, i) =>
                            <EventItem
                                item={item}
                                index={i}
                                key={item.id}
                                workOrder={workOrder}
                                work_order_event_id={work_order_event_id}
                            />
                        )}
                    </List>
                    {!!Object.keys(selectedEvent)?.length && selectedEvent?.lat && selectedEvent?.lon
                        && <WorkorderControlMap latlon={[selectedEvent?.lat, selectedEvent?.lon]} />
                    }
                </>
                : (!loadingWorkorderEvents && messages.WORKORDER_EVENTS_ARE_NOT_FOUND)
            }
        </>
    );
};

export default WorkorderEvents;
