import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidation } from '../../../../../helpers/hooks';
import { createModel, editModel, loadBrands } from '../../../../../redux/TransportSpecial/actions';
import { transportSpecialSelectors } from '../../../../../redux/TransportSpecial';
import titles from '../../../../../helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import { TextField } from '@mui/material';
import Modal from '../../../../common/Modal';
import MarkSelect from '../../../../common/Autocomplete/MarkSelect';
import FormInfoWrapper from '../../../../common/FormInfoWrapper';

const ModalForm = ({ item, isNew, open, onClose, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingButton = useSelector(transportSpecialSelectors.loadingButton);

    const initialState = {
        brand: item?.brand || {},
        brand_id: item?.brand_id || '',
        name_ru: item?.name_ru || '',
        name_en: item?.name_en || '',
        slug: item?.slug || '',
    };

    const [formData, setFormData] = useState(initialState);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        const result = {
            ...formData,
            brand_id: formData?.brand?.id
        };

        dispatch(isNew
            ? createModel(result, callback)
            : editModel(item.id, result, callback)
        );
    };

    const isDisabled = !formData.name_ru || !formData.name_en || !formData.slug;

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled,
                        loading: loadingButton
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <div className="block">
                    <MarkSelect
                        value={formData.brand?.name_ru || ''}
                        moduleName={'transportSpecial'}
                        action={loadBrands}
                        selected={formData.brand}
                        required
                        onChange={value => setFormData({ ...formData, brand: value })}
                        error={validation.isKey('brand_id')}
                        helperText={validation.get('brand_id')}
                    />
                </div>
                <FormInfoWrapper error={validation.isKey('name_ru')} helperText={validation.get('name_ru')}>
                    <TextField
                        value={formData.name_ru || ''}
                        name={'name_ru'}
                        onChange={handleChange}
                        className="block"
                        label={'Название модели(Ru)'}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={validation.isKey('name_ru')}
                        helperText={validation.get('name_ru')}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper error={validation.isKey('name_en')} helperText={validation.get('name_en')}>
                    <TextField
                        value={formData.name_en || ''}
                        name={'name_en'}
                        onChange={handleChange}
                        className="block"
                        label={'Название модели(En)'}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={validation.isKey('name_en')}
                        helperText={validation.get('name_en')}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper error={validation.isKey('slug')} helperText={validation.get('slug')}>
                    <TextField
                        value={formData.slug || ''}
                        name={'slug'}
                        onChange={handleChange}
                        className="block"
                        label={'Алиас'}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={validation.isKey('slug')}
                        helperText={validation.get('slug')}
                    />
                </FormInfoWrapper>
            </div>
        </Modal>
    );
};

export default ModalForm;