import { Virtuoso } from 'react-virtuoso';
import { Radio } from '@mui/material';

import {
    LSContainer,
    LSContentColumn,
    LSContentItem,
} from 'components/common/List';
import { fullDateTime } from 'helpers/date.config';
import messages from 'helpers/constants/messages';

const List = ({
    list = [],
    selected = {},
    onSelected = () => {},
}) => {
    const style = {
        overflow: 'hidden',
        overflowY: 'hidden',
        maxHeight: '400px',
    };

    return (
        <div style={style}>
            <LSContainer
                headers={[
                    { title: '', width: '10%' },
                    { title: 'Дата/время', width: '30%' },
                    { title: 'Название', width: '30%' },
                    { title: 'Местонахождение', width: '30%' },
                ]}
            >
                <Virtuoso
                    data={list}
                    style={{ height: 365 }}
                    itemContent={(_, item) => (
                        <LSContentItem key={item?.id} onClick={() => onSelected?.(item)}>
                            <LSContentColumn>
                                <Radio
                                    checked={item?.id === selected?.id}
                                    onClick={() => onSelected?.(item)}
                                    size="small"
                                />
                            </LSContentColumn>
                            <LSContentColumn title="Дата/время" style={{ fontSize: '0.85rem' }}>
                                {item.date ? fullDateTime(item.date) : messages.NO_DATA}
                            </LSContentColumn>
                            <LSContentColumn title="Название" style={{ fontSize: '0.85rem' }}>
                                {item.name || messages.NO_DATA}
                            </LSContentColumn>
                            <LSContentColumn title="Местонахождение" style={{ fontSize: '0.85rem' }}>
                                {item.address_text || `Широта: ${item.lat || ''}, Долгота: ${item.lon || ''}`}
                            </LSContentColumn>
                        </LSContentItem>
                    )}
                />
                {/* {list?.map(item => (
                    <LSContentItem key={item?.id} fullWidth onClick={() => onSelected?.(item?.id)}>
                        <LSContentColumn width="30px">
                            <Radio
                                checked={item?.id === selected}
                                onClick={() => onSelected?.(item?.id)}
                                size="small"
                            />
                        </LSContentColumn>
                        <LSContentColumn title="Дата/время">
                            {fullDateTime(item.date) || messages.NO_DATA}
                        </LSContentColumn>
                        <LSContentColumn title="Название">
                            {item.name || messages.NO_DATA}
                        </LSContentColumn>
                        <LSContentColumn title="Местонахождение">
                            {item.address_text || `Широта: ${item.lat || ''}, Долгота: ${item.lon || ''}`}
                        </LSContentColumn>
                    </LSContentItem>
                ))} */}
            </LSContainer>
        </div>
    );
};

export default List;