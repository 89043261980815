import React, { useEffect, useRef, useState, useMemo } from 'react';
import IncidentsLayer from '../Incidents/layer';
import RoadWorks from '../RoadWorks/layer';
import * as actions from '../../../../redux/RoadNetwork/actions';
import { setFilter as setFilterRW } from '../../../../redux/RoadWorks/actions';
import { setFilters as setFilterI } from '../../../../redux/Incidents/actions';
import { useDispatch, useSelector } from 'react-redux';
import { roadNetworksSelectors } from '../../../../redux/RoadNetwork';
import { roadworksSelectors } from '../../../../redux/RoadWorks';
import { incidentsSelectors } from '../../../../redux/Incidents';
import { usePrevious } from '../../../../helpers/hooks';
import getFilters from '../../helpers/getFilters';
import { createIconMarker, getFilter, getColor } from './helper';
import mapHelper from '../../../../helpers/mapHelper';
import { isEqual } from 'lodash';
import { GeoJsonNew, Marker, PopUp, ToolTip } from '../../leaflet';
import PopUpComponent from './PopUp';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import useLoadPolygonLayer from '../../../../helpers/hooks/useLoadPolygonLayer';
import './style.scss';
import config from '../RoadNetwork/config';


const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();

    const polygon = useSelector(roadNetworksSelectors.overlapPolygon);
    const filters = useSelector(roadNetworksSelectors.filterOverlap);
    const active = useSelector(roadNetworksSelectors.activeOverlap);

    const [visible, setVisible] = useState(false);

    const filtersRW = useSelector(roadworksSelectors.filters);
    const filtersI = useSelector(incidentsSelectors.filters);

    const prevFilters = usePrevious(filters);
    const filter = getFilters(filters, getFilter);
    const previewId = useRef(0);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadOverlapPolygon(
            polygon,
            filter
        ));
    };
    const fetchProvider = useLoadPolygonLayer(fetchPolygon);
    const handleFetchPolygon = () => fetchProvider.load();

    const handlePUO = () => {
        fetchProvider.lock();
    };
    const handlePUC = () => {
        fetchProvider.unLock();
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            fetchProvider.load();
            fetchProvider.unLock();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon)
            .on('popupopen', handlePUO)
            .on('popupclose', handlePUC);

        return () => {
            dispatch(actions.clearOverlapPolygon());
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon)
                .off('popupopen', handlePUO)
                .off('popupclose', handlePUC);
        };
    }, [filters, prevFilters]);

    const clickPopUp = (id) => () => {
        if (previewId.current === id) {
            dispatch(actions.setActiveOverlap());
        }
    };

    useEffect(() => {
        const frw = { ...filtersRW };
        const fi = { ...filtersI };

        return () => {
            dispatch(setFilterRW(frw));
            dispatch(setFilterI(fi));
        };
    }, []);

    useEffect(() => {
        const {
            start_at = null,
            end_at = null,
            // ...others
        } = filters;

        // установка фильтров доп слоев
        // дорожные работы
        dispatch(setFilterRW(removeEmptyFields({
            // ...others,
            date_start: start_at,
            date_end: end_at
        }, false)));

        // дтп
        dispatch(setFilterI(removeEmptyFields({
            // ...others,
            start_date: start_at,
            end_date: end_at
        }, false)));

        setVisible(true);
    }, [dispatch, filters]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (active?.lat && active?.lon) {
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 15);
            }

            previewId.current = active?.id;
        }
    }, [active]);

    const activeId = active.id ?? 0;

    const geometry = useMemo(() => polygon
        ?.map(({ id, name, type, geojson = {} }) => ({
            ...geojson,
            properties: {
                ...geojson?.properties,
                id,
                name,
                color: getColor(type),
            },
            style: {
                color: getColor(type),
                weight: 7
            }
        }))
    , [polygon]);

    if (visible) {
        return (
            <>
                <GeoJsonNew
                    {...props}
                    data={geometry}
                    toolTipTemplate={({ name = '' }) => <div>{name || ''}</div>}
                    icon={({ type }) => createIconMarker(type)}
                    idPrefix={config.slug}
                    toolTipOptions={{
                        direction: 'top',
                        offset: [0, -5],
                        sticky: true
                    }}
                    popUpTemplate={({ id }) => <PopUpComponent uuid={id} />}
                />

                {/* ДТП */}
                <IncidentsLayer {...props} readOnly isTrafficBlocking />

                {/* Дорожные работы */}
                <RoadWorks {...props} readOnly hideEmpty />

                {/* Перекрытие движения */}
                {polygon?.map(item => {
                    const currentShow = activeId === item.id;

                    return (
                        <Marker
                            {...props}
                            key={item.id}
                            onClick={latlng => {
                                map.setView(latlng);
                            }}
                            latlng={[item.lat, item.lon]}
                            icon={createIconMarker(item.type)}
                        >
                            <ToolTip
                                offset={[0, -20]}
                                direction="top"
                            >
                                {item?.name || `${item.lat} / ${item.lon}`}
                            </ToolTip>
                            <PopUp
                                onClose={clickPopUp(item.id)}
                                show={currentShow}
                            >
                                <PopUpComponent
                                    uuid={item.id}
                                />
                            </PopUp>
                        </Marker>
                    );
                })}
            </>
        );
    }
    return null;
};

export default Layer;