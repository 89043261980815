import { useSelector } from 'react-redux';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import useStoreProp from 'helpers/hooks/useStoreProp';
import { getHumanDate } from 'helpers/date.config';
import colorExtend from 'helpers/mapHelper/colorExtend';
import { loadDefectStatuses } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import TabInformation from './TabInformation';
import { getDefaultColor } from '../helper';
import icon from '../icons/icon';


const PopUp = (props) => {
    // забираем данные из полигона
    const defectsPolygon = useSelector(roadworksSelectors.defectsPolygon);
    const data = defectsPolygon?.find(({ id }) => props.uuid === id) || {};
    const color = data?.type?.color
        ? colorExtend(data?.type?.color)
        : getDefaultColor();
    const name = data?.name;

    const defectStatuses = useStoreProp(loadDefectStatuses, 'roadworks', 'defectStatuses');

    return (
        <div className="map-popup">
            <div
                className="map-popup_header"
                style={{ backgroundColor: colorExtend(color) }}
            >
                <div>
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                    <span>{name}</span>
                </div>
            </div>
            <div className="map-popup_body">

                <TabInformation
                    data={data}
                    title={{
                        name: {
                            title: titles.NAME,
                        },
                        comment: {
                            title: titles.COMMENT,
                        },
                        status: {
                            title: 'Статус',
                            value: ({ status, status_text }) => {

                                const statusObject = defectStatuses?.find(({ status_id }) => status_id === status) || null;

                                if (statusObject) {
                                    return (
                                        <span style={{ color: statusObject.color }}>{statusObject.name}</span>
                                    );
                                } else {
                                    return (
                                        <span>{status_text || messages.NO_DATA}</span>
                                    );
                                }
                            },
                        },
                        address_text: {
                            title: 'Адрес',
                            value: ({ address_text }) =>
                                address_text
                                    ? address_text
                                    : messages.INFO_IS_NOT_FOUND
                        },
                        liquidated_at: {
                            title: 'Фактическая дата устранения',
                            value: ({ liquidated_at }) =>
                                liquidated_at
                                    ? getHumanDate(liquidated_at)
                                    : messages.INFO_IS_NOT_FOUND
                        },
                        organization_id: {
                            title: 'Организация',
                            value: ({ organization }) =>
                                organization
                                    ? organization.title
                                    : messages.INFO_IS_NOT_FOUND
                        },
                        group_id: {
                            title: 'Группа',
                            value: ({ group }) =>
                                group
                                    ? group?.name
                                    : messages.INFO_IS_NOT_FOUND
                        },
                        type_id: {
                            title: 'Тип',
                            value: ({ type }) =>
                                type
                                    ? type?.name
                                    : messages.INFO_IS_NOT_FOUND
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default PopUp;
