import moduleName from './module';
const prefix = `${moduleName}`;

/**
 * Constants
 * */

export const LOAD_SETTINGS = `${prefix}LOAD_SETTINGS`;
export const LOADED_SETTINGS = `${prefix}LOADED_SETTINGS`;
export const LOADING_SETTINGS = `${prefix}LOADING_SETTINGS`;
export const EDIT_SETTINGS = `${prefix}EDIT_SETTINGS`;
export const EDIT_SETTINGS_LIST = `${prefix}/EDIT_SETTINGS_LIST`;

export const LOAD_GROUP_GRAPH = `${prefix}LOAD_GROUP_GRAPH`;
export const LOADED_GROUP_GRAPH = `${prefix}LOADED_GROUP_GRAPH`;
export const LOADING_GROUP_GRAPH = `${prefix}LOADING_GROUP_GRAPH`;
export const CLEAR_GROUP_GRAPH = `${prefix}CLEAR_GROUP_GRAPH`;

export const LOAD_ONE_GRAPH = `${prefix}LOAD_ONE_GRAPH`;
export const LOADED_ONE_GRAPH = `${prefix}LOADED_ONE_GRAPH`;
export const CLEAR_ONE_GRAPH = `${prefix}CLEAR_ONE_GRAPH`;
export const LOADING_ONE_GRAPH = `${prefix}LOADING_ONE_GRAPH`;

export const LOAD_DASHBOARD = `${prefix}LOAD_DASHBOARD`;
export const LOADED_DASHBOARD = `${prefix}LOADED_DASHBOARD`;
export const CLEAR_DASHBOARD = `${prefix}CLEAR_DASHBOARD`;
export const LOADING_DASHBOARD = `${prefix}LOADING_DASHBOARD`;

export const LOAD_EFFICIENCY_DTP = `${prefix}LOAD_EFFICIENCY_DTP`;
export const LOADED_EFFICIENCY_DTP = `${prefix}LOADED_EFFICIENCY_DTP`;
export const CLEAR_EFFICIENCY_DTP = `${prefix}CLEAR_EFFICIENCY_DTP`;
export const LOADING_EFFICIENCY_DTP = `${prefix}LOADING_EFFICIENCY_DTP`;
export const SET_FILTER_EFFICIENCY_DTP = `${prefix}SET_FILTER_EFFICIENCY_DTP`;

export const LOAD_GRAPH_LIST = `${prefix}LOAD_GRAPH_LIST`;
export const LOADING_GRAPH_LIST = `${prefix}LOADING_GRAPH_LIST`;
export const LOADED_GRAPH_LIST = `${prefix}LOADED_GRAPH_LIST`;


export const LOAD_EFFICIENCY_TRAFFIC = `${prefix}LOAD_EFFICIENCY_TRAFFIC`;
export const LOADING_EFFICIENCY_TRAFFIC = `${prefix}LOADING_EFFICIENCY_TRAFFIC`;
export const LOADED_EFFICIENCY_TRAFFIC = `${prefix}LOADED_EFFICIENCY_TRAFFIC`;

export const LOAD_USER_AVAILABLE_DASHBOARD_LIST = `${prefix}LOAD_USER_AVAILABLE_DASHBOARD_LIST`;
export const LOADED_USER_AVAILABLE_DASHBOARD_LIST = `${prefix}LOADED_USER_AVAILABLE_DASHBOARD_LIST`;
export const LOADING_USER_AVAILABLE_DASHBOARD_LIST = `${prefix}LOADING_USER_AVAILABLE_DASHBOARD_LIST`;

export const LOAD_USER_DASHBOARD = `${prefix}LOAD_USER_DASHBOARD`;
export const LOADED_USER_DASHBOARD = `${prefix}LOADED_USER_DASHBOARD`;
export const LOADING_USER_DASHBOARD = `${prefix}LOADING_USER_DASHBOARD`;
export const EDIT_USER_DASHBOARD = `${prefix}EDIT_USER_DASHBOARD`;

export const LOAD_TRAFFIC_FORECAST = `${prefix}LOAD_TRAFFIC_FORECAST`;
export const LOADED_TRAFFIC_FORECAST = `${prefix}LOADED_TRAFFIC_FORECAST`;
export const LOADING_TRAFFIC_FORECAST = `${prefix}LOADING_TRAFFIC_FORECAST`;

export const LOAD_CRITICAL_SETTINGS = `${prefix}LOAD_CRITICAL_SETTINGS`;
export const LOADED_CRITICAL_SETTINGS = `${prefix}LOADED_CRITICAL_SETTINGS`;
export const LOADING_CRITICAL_SETTINGS = `${prefix}LOADING_CRITICAL_SETTINGS`;
export const EDIT_CRITICAL_SETTING = `${prefix}EDIT_CRITICAL_SETTING`;
export const DELETE_CRITICAL_SETTING = `${prefix}DELETE_CRITICAL_SETTING`;
export const CREATE_CRITICAL_SETTING = `${prefix}CREATE_CRITICAL_SETTING`;

export const LOAD_GRAPH_PERIOD_LIST = `${prefix}LOAD_GRAPH_PERIOD_LIST`;
export const LOADING_GRAPH_PERIOD_LIST = `${prefix}LOADING_GRAPH_PERIOD_LIST`;
export const LOADED_GRAPH_PERIOD_LIST = `${prefix}LOADED_GRAPH_PERIOD_LIST`;

export const LOAD_CRITICAL_SETTINGS_KEYS = `${prefix}LOAD_CRITICAL_SETTINGS_KEYS`;
export const LOADED_CRITICAL_SETTINGS_KEYS = `${prefix}LOADED_CRITICAL_SETTINGS_KEYS`;

export const LOADING_BUTTON = `${prefix}LOADING_BUTTON`;

export const LOAD_REPORT_EFFICIENCY_DTP_REPORT = `${prefix}LOAD_REPORT_EFFICIENCY_DTP_REPORT`;
export const LOADED_REPORT_EFFICIENCY_DTP_REPORT = `${prefix}LOADED_REPORT_EFFICIENCY_DTP_REPORT`;
export const LOADING_REPORT_EFFICIENCY_DTP_REPORT = `${prefix}LOADING_REPORT_EFFICIENCY_DTP_REPORT`;
export const SET_FILTER_REPORT_EFFICIENCY_DTP_REPORT = `${prefix}SET_FILTER_REPORT_EFFICIENCY_DTP_REPORT`;

export const LOAD_SHOWCASE = `${prefix}/LOAD_SHOWCASE`;
export const LOADING_SHOWCASE = `${prefix}/LOADING_SHOWCASE`;
export const LOADED_SHOWCASE = `${prefix}/LOADED_SHOWCASE`;

export const LOAD_SETTINGS_GROUP = `${prefix}/LOAD_SETTINGS_GROUP`;
export const LOADING_SETTINGS_GROUP = `${prefix}/LOADING_SETTINGS_GROUP`;
export const LOADED_SETTINGS_GROUP = `${prefix}/LOADED_SETTINGS_GROUP`;
export const EDIT_SETTINGS_GROUP = `${prefix}/EDIT_SETTINGS_GROUP`;

export const LOAD_ITS_DEVELOPMENT_LEVELS = `${prefix}/LOAD_ITS_DEVELOPMENT_LEVELS`;
export const LOADED_ITS_DEVELOPMENT_LEVELS = `${prefix}/LOADED_ITS_DEVELOPMENT_LEVELS`;
export const LOADING_ITS_DEVELOPMENT_LEVELS = `${prefix}/LOADING_ITS_DEVELOPMENT_LEVELS`;
export const CLEAR_ITS_DEVELOPMENT_LEVELS = `${prefix}/CLEAR_ITS_DEVELOPMENT_LEVELS`;
export const EDIT_ITS_DEVELOPMENT_LEVELS = `${prefix}/EDIT_ITS_DEVELOPMENT_LEVELS`;

export const LOAD_INFRASTRUCTURE_STATISTICS = `${prefix}/LOAD_INFRASTRUCTURE_STATISTICS`;
export const LOADED_INFRASTRUCTURE_STATISTICS = `${prefix}/LOADED_INFRASTRUCTURE_STATISTICS`;
export const LOADING_INFRASTRUCTURE_STATISTICS = `${prefix}/LOADING_INFRASTRUCTURE_STATISTICS`;
export const CLEAR_INFRASTRUCTURE_STATISTICS = `${prefix}/CLEAR_INFRASTRUCTURE_STATISTICS`;
export const EDIT_INFRASTRUCTURE_STATISTICS = `${prefix}/EDIT_INFRASTRUCTURE_STATISTICS`;

export const LOAD_DASHBOARDS = `${prefix}/LOAD_DASHBOARDS`;
export const LOADED_DASHBOARDS = `${prefix}/LOADED_DASHBOARDS`;
export const LOAD_DASHBOARDS_ITEM = `${prefix}/LOAD_DASHBOARDS_ITEM`;
export const LOADED_DASHBOARDS_ITEM = `${prefix}/LOADED_DASHBOARDS_ITEM`;
export const LOADING_DASHBOARDS = `${prefix}/LOADING_DASHBOARDS`;

export const LOAD_DASHBOARD_ITEM_USER = `${prefix}/LOAD_DASHBOARD_ITEM_USER`;
export const LOADED_DASHBOARD_ITEM_USER = `${prefix}/LOADED_DASHBOARD_ITEM_USER`;
export const CLEAR_DASHBOARD_ITEM_USER = `${prefix}/CLEAR_DASHBOARD_ITEM_USER`;
export const CREATE_DASHBOARD_ITEM_USER = `${prefix}/CREATE_DASHBOARD_ITEM_USER`;
export const EDIT_DASHBOARD_ITEM_USER = `${prefix}/EDIT_DASHBOARD_ITEM_USER`;
export const EDIT_DASHBOARD_ITEM_USER_MULTIPLY = `${prefix}/EDIT_DASHBOARD_ITEM_USER_MULTIPLY`;
export const DELETE_DASHBOARD_ITEM_USER = `${prefix}/DELETE_DASHBOARD_ITEM_USER`;
export const LOADING_DASHBOARD_ITEM_USER = `${prefix}/LOADING_DASHBOARD_ITEM_USER`;
