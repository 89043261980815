import PageLayout from '../../../../layout/PageLayout';
import Filter from './Filter';

const ReportCPVFByComplex = () => {
    return (<PageLayout
        header={'Отчёт по времени работы КФВФ в часах по комплексам'}
        filters={<Filter />}
    />);
};

export default ReportCPVFByComplex;