import { Divider, Grid, Typography } from '@mui/material';

import messages from 'helpers/constants/messages';

import MapForm from '../../../DorisControl/VFComplex/MapForm';

const BasicData = ({ currentAip }) => {
    return (
        <Grid container direction="row" spacing={1} justify="center" alignItems="flex-start">
            {Object.keys(currentAip).length > 0
                ? <>
                    <Grid item xs={6}>
                        <Typography variant="body2">
                            <strong>Наименование АИП: </strong>
                            <span>{currentAip?.name}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Тип АИП: </strong>
                            <span>{currentAip?.type_text || messages.NO_DATA}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Серийный номер: </strong>
                            <span>{currentAip?.serial_number}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Серийный номер КФВФ: </strong>
                            <span>{currentAip?.complex_serial_number}</span>
                        </Typography>
                        <Divider style={{ margin: '10px 0' }}/>
                        <Typography style={{ margin: '10px 0' }} variant="body2">
                            <strong>Адрес: </strong>
                            <span>{currentAip?.address_text}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Координаты:</strong>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Широта: </strong>
                            <span>{currentAip?.lat}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Долгота: </strong>
                            <span>{currentAip?.lon}</span>
                        </Typography>
                        <Divider style={{ margin: '10px 0' }}/>
                        <Typography variant="body2">
                            <strong>IP адрес: </strong>
                            <span>{currentAip?.ip_route || messages.NO_DATA}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>imei: </strong>
                            <span>{currentAip?.imei || messages.NO_DATA}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Моб. телефон: </strong>
                            <span>{currentAip?.phone || messages.NO_DATA}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Оператор сети: </strong>
                            <span>{currentAip?.phone_operator_text || messages.NO_DATA}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>icc SIM-карты: </strong>
                            <span>{currentAip?.icc || messages.NO_DATA}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {currentAip?.lat && currentAip?.lon
                        && <MapForm
                            readOnly
                            latlon={[currentAip?.lat, currentAip?.lon]}
                        />}
                    </Grid>
                </>
                : <div>АИП не найден</div>
            }
        </Grid>
    );
};

export default BasicData;
