import React, { useEffect } from 'react';
import {
    Grid,
    Paper,
    LinearProgress,
} from '@mui/material';
import {
    Cluster,
    Map,
    MapControl,
} from '../../../MapComponents/leaflet';
import Layer from '../../../MapComponents/Layers/TrafficFlow/layer';
import HeatLayer from '../../../MapComponents/leaflet/components/HeatLayer';
import layerConfig from '../../../MapComponents/Layers/TrafficFlow/config';
import CardWidget from '../../../common/Showcase/CardWidget';
import { config } from '../../../../config';
import { iconCreateFunctionSimple } from '../../../MapComponents/helpers/iconCreateFunction';
import {
    loadShowcase,
    clearShowcase,
    loadHeatMap
} from '../../../../redux/TrafficFlow/actions';
import { trafficFlowSelectors } from '../../../../redux/TrafficFlow';
import { useDispatch, useSelector } from 'react-redux';
import TypeComponent from '../../../common/Showcase/TypeComponent';
import {
    getDateToFormat,
    dateWithDashYYYYMMDD,
    getDateWithDuration,
} from '../../../../helpers/date.config';

// страница
const Page = () => {
    const dispatch = useDispatch();

    const showCase = useSelector(trafficFlowSelectors.showcase);
    const loadingShowcase = useSelector(trafficFlowSelectors.loadingShowcase);
    const heatMap = useSelector(trafficFlowSelectors.heatMap);
    const heatMapLoading = useSelector(trafficFlowSelectors.heatMapLoading);

    const filter = {
        start_date: dateWithDashYYYYMMDD(getDateWithDuration({ days: -1 })),
        end_date: dateWithDashYYYYMMDD(new Date())
    };

    const {
        graph = [],
        table = [],
    } = showCase;

    useEffect(() => {
        dispatch(loadShowcase());
        dispatch(loadHeatMap(filter));
        return () => dispatch(clearShowcase());
    } ,[]);

    const CreateLayer = (props) => {
        return <Cluster
            {...props}
            iconCreateFunction={iconCreateFunctionSimple}
            maxClusterRadius={40}
        >
            <Layer />
        </Cluster>;
    };

    return (
        <Grid container spacing={2} style={{ height: '100%' }}>
            {(loadingShowcase || heatMapLoading) && <LinearProgress/>}
            <Grid
                item
                xs={12}
                md={6}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1',
                }}
            >
                <Paper sx={{ height: '100%', minHeight: '300px', }}>
                    <Map
                        center={config.get('mapCenter')}
                        isHideUnidentifiedPlaces
                    >
                        <MapControl>
                            <Cluster iconCreateFunction={iconCreateFunctionSimple}>
                                <Layer readOnly/>
                            </Cluster>
                        </MapControl>
                    </Map>
                </Paper>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1',
                }}
            >

                <Paper sx={{ height: '100%', minHeight: '300px', }}>
                    <Map
                        center={config.get('mapCenter')}
                        isHideUnidentifiedPlaces
                    >
                        <MapControl>
                            {/* тепловая */}
                            <HeatLayer
                                json={heatMap}
                            />

                            {/* слой c маркерами*/}
                            {/* <CreateLayer/> */}
                        </MapControl>
                    </Map>
                </Paper>
            </Grid>

            {graph?.length > 0 
                && <Grid item container spacing={2} xs={12}>
                    {graph.map((item, index) => (
                        <Grid item xs={12} md={12 / graph.length} key={index}>
                            <CardWidget
                                icon={layerConfig.layerIcon}
                                title={item.name}
                                marginBottom
                                small
                            >
                                <TypeComponent
                                    {...item}
                                    indexColor={index}
                                    labelFormat={(value) => getDateToFormat(value, 'dd.MM.yy HH:mm')}
                                />
                            </CardWidget>
                        </Grid>
                    ))}
                </Grid>
            }
        </Grid>
    );
};

export default Page;
