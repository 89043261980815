import { Collapse, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import messages from 'helpers/constants/messages';
import { CheckListGroupInfoProps } from './types';

const notInfo = messages.NO_DATA;

const CheckListGroupInfo = ({ isOpen, item }: CheckListGroupInfoProps) => {
    return (
        <Collapse in={isOpen} timeout={'auto'} className="info">
            <h3>Статьи КОАП</h3>
            {item?.duplo_codes[0]?.koap_name?.length && item?.duplo_codes[0]?.koap_name?.length > 0
                ? <List className="list">
                    <ListItem dense>
                        {item?.duplo_codes[0]?.koap_name}: {item?.duplo_codes[0]?.table_2}
                    </ListItem>
                    <ListItem dense>
                        
                    </ListItem>
                </List>
                : <div>{notInfo}</div>
            }
            <h3>Пункты чек-листов</h3>
            
            {item.checkboxes && item.checkboxes.length > 0
                ? <List className="list" disablePadding>
                    {
                        item?.checkboxes?.map((el) => {
                            return <ListItem dense key={el.id} >
                                <ListItemIcon style={{ minWidth: '20px' }}>-</ListItemIcon>
                                <Typography variant="body2">{el.name}</Typography>
                            </ListItem>;
                        })}
                </List>
                : <div>{notInfo}</div>
            }
        </Collapse>
    );
};

export default CheckListGroupInfo;
