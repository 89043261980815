import { config } from '../../config';

const apiOsrmUrl = () => config.get('urls').apiOsrmUrl;

const apiUrls = {
    getOsrmCar: (coords) => `${apiOsrmUrl()}/cars/route/v1/driving/${coords}`,
    // getOsrmCar: (coords) => `http://91.227.17.182/doris-services/osrm/cars/route/v1/driving/${coords}`,
};

export default apiUrls;
