import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadDangersList } from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import FormButtonsComponent from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';


import DangerItem from './DangerItem';
import DangerModal from './DangerModal';
import Filters from './Filters';

const Dangers = () => {
    const dispatch = useDispatch();

    const dangersList = useSelector(SituationalPlansSelectors.dangersList);
    const loadingDangersList = useSelector(SituationalPlansSelectors.loadingDangersList);

    const [openModal, setOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = (isDelete) => {
        const page = isDelete && dangersList?.data?.length === 1 && dangersList?.meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadDangersList(params.page, limit, params.data));
    },[dispatch, limit, params]);

    const renderContent = () => {
        return <>
            {dangersList.data?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: titles.NAME, width: '70%' },
                            { title: 'Действия', isActions: true }
                        ]}
                    >
                        {dangersList.data.map((item) => (
                            <DangerItem
                                key={item.id}
                                el={item}
                                reloadList={reloadList}
                            />
                        ))}
                    </LSContainer>
                )
                : (!loadingDangersList && <div>{messages.NO_VALUE}</div>)
            }
        </>;
    };

    return (
        <>
            <PageLayout
                header="Угрозы и риски"
                loading={loadingDangersList}
                filters={<Filters setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenModal(!openModal),
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    
                    total: dangersList.meta.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: dangersList.meta,
                    limit: limit,
                    setLimit: setLimit
                }}
                customStyles={{ padding: 0 }}
            />
            {openModal
            && <DangerModal
                isOpen={openModal}
                isNew={true}
                onClose={() => setOpenModal(false)}
                reloadList={() => setParams({ page: 1, data: {} })}
            />}
        </>
    );
};

export default Dangers;
