import React, { useState } from 'react';

import { getCurrentSpecialReport } from 'redux/TransportSpecial/actions';
import { getCurrentPassengerReport } from 'redux/TransportPassenger/actions';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import DateRange from 'components/common/Dates/DateRange';
import { PageReportFields, useReportTemplatesFrontendLinks } from 'components/common/PageReport';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
// todo

const ReportModal = ({
    open,
    onClose,
    item,
    urlTemplate = null,
    test_id_prefix = '',
}) => {
    const templateProvider = useReportTemplatesFrontendLinks();
    const reportFilter = templateProvider.filter;

    const initialState = {
        vehicle_id: item.id,
        start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1) || null,
        end_date: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59)
    };

    const [boxFilters, setBoxFilters] = useState(initialState);

    const handleDownloadReport = () => {
        const filter = {
            ...boxFilters,
            start_date: fullDateTimeWithTimeZone(boxFilters.start_date),
            end_date: fullDateTimeWithTimeZone(boxFilters.end_date),
        };

        const {
            url,
            formats,
        } = reportFilter.getToApi();

        templateProvider.loadReport(
            url,
            formats,
            filter,
            onClose
        );
    };

    return (
        <Modal
            onClose={onClose}
            isOpen={open}
            title={titles.CURRENT_TRANSPORT_REPORT}
            maxWidth="md"
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose,
                            testName: 'cancel',
                        },
                        {
                            ...buttonsTypes.getReport,
                            onClick: () => handleDownloadReport(),
                            disabled: reportFilter.isDisabled,
                            testName: 'report',
                        }
                    ]}
                    test_id_prefix={test_id_prefix}
                />
            }
        >
            <div className="row">
                <DateRange
                    className="row__item"
                    handleDate={ (newDate) => {
                        setBoxFilters({
                            ...boxFilters,
                            start_date: newDate
                        });
                    }}
                    handleEndDate={ (newDate) => {
                        setBoxFilters({
                            ...boxFilters,
                            end_date: newDate
                        });
                    }}
                    valueStartDate={boxFilters.start_date}
                    valueEndDate={boxFilters.end_date}
                    required={true}
                    test_id_prefix={test_id_prefix}
                />
            </div>

            <PageReportFields
                reportType={reportFilter.type}
                reportFormats={reportFilter.formats}
                onReportType={reportFilter.setType}
                onReportFormat={reportFilter.setFormats}
                isOneTypeAuto
                urlTemplate={urlTemplate}
                test_id_prefix={test_id_prefix}
            />
        </Modal>
    );
};

export default ReportModal;
