import { config } from '../../config';

const apiUrl = () => config.get('urls').apiTelegramUrl;

const apiUrls = {

    loadTelegramId: () => `${apiUrl()}/subscribe/hash`,

};

export default apiUrls;
