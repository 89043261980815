import { config } from '../../config';

const apiDadataUrl = () => config.get('urls').apiDadataUrl;

const apiUrls = {
    getCompanies: () => `${apiDadataUrl()}/its/companies`,

    getAddressList: () => `${apiDadataUrl()}/its`,

    getAddressAutocomplete: () => `${apiDadataUrl()}/geocode/address/autocomplete`,

    getAddressByCoordinates: () => `${apiDadataUrl()}/geolocate/address`,

    getAddressDadata: () => `${apiDadataUrl()}/its/address`,

    getGeocodeAddress: () => `${apiDadataUrl()}/geocode/addresses`,

    getRegions: () => `${apiDadataUrl()}/address-areas`,

    getGeocodeAddressItem: () => `${apiDadataUrl()}/geocode/address`,
    
    getAddressAreasAutocomplete: () => `${apiDadataUrl()}/geocode/address/areas-autocomplete`,
};

export default apiUrls;
