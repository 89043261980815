import { 
    Cluster,
    FeatureGroup,
    Map,
    MapControl,
    Marker,
    ToolTip
} from '../../../../../../../MapComponents/leaflet';
import { config } from '../../../../../../../../config';
import LightsPolygon from './Polygon';
import { iconCreateFunctionSimple } from '../../../../../../../MapComponents/helpers/iconCreateFunction';

const MapForm = ({
    latlng,
    selectedLights,
    setSelectedLights
}) => {
    return (
        <div style={{ minWidth: '60%', minHeight: '400px', height: '100%', marginRight: 20 }}>
            <Map
                center={latlng || config.get('mapCenter')}
                zoom={16}
            >
                <MapControl>
                    <FeatureGroup>
                        {latlng?.length && (
                            <Marker latlng={latlng}>
                                <ToolTip direction="top" offset={[0, -42]}>
                                    Событие
                                </ToolTip>
                            </Marker>
                        )}
                        
                        <Cluster iconCreateFunction={iconCreateFunctionSimple}> 
                            <LightsPolygon
                                selectedLights={selectedLights}
                                setSelectedLights={setSelectedLights}
                            />
                        </Cluster>
                    </FeatureGroup>
                </MapControl>
            </Map>
        </div>
    );
};

export default MapForm;
