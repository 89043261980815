import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import messages from 'helpers/constants/messages';
import { efficiencySelectors } from 'redux/Efficiency';
import {
    loadItsDevelopmentLevels,
    clearItsDevelopmentLevels,
} from 'redux/Efficiency/actions';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import Filter from './Filter';
import Item from './Item';

const ItsDevelopmentLevels = () => {
    const dispatch = useDispatch();

    const data = useSelector(efficiencySelectors.itsDevelopmentLevelsData);
    const meta = useSelector(efficiencySelectors.itsDevelopmentLevelsMeta);
    const loading = useSelector(efficiencySelectors.loadingItsDevelopmentLevels);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({
        page: 1,
        filter: {},
    });

    const loadList = useCallback((shouldGoToPreviousPage = false) => {
        if (shouldGoToPreviousPage) {
            setParams((prev) => ({ ...prev, page: prev.page - 1 }));
        } else {
            dispatch(loadItsDevelopmentLevels(params.page, limit, params.filter));
        }
    }, [dispatch, params, limit]);

    useEffect(() => {
        loadList();
    }, [loadList]);

    useEffect(() => () => {
        dispatch(clearItsDevelopmentLevels());
    }, []);

    return (
        <PageLayout
            header="Уровень зрелости ИТС"
            filters={<Filter setParams={setParams}/>}
            informPanelProps={{
                total: meta?.total
            }}
            content={() => (
                <>
                    {data?.length > 0
                        ? (
                            <LSContainer
                                headers={[
                                    { title: 'Название параметра', width: '70%' },
                                    { title: 'Значение', width: '12%' },
                                    { title: 'Дашборд', width: '12%' },
                                    { title: 'Действия', align: 'right', isActions: true }
                                ]}
                            >
                                {data.map((item) => (
                                    <Item
                                        key={item.id}
                                        item={item}
                                        loadList={(isDelete) => loadList(isDelete && data.length === 1)}
                                    />
                                ))}
                            </LSContainer>
                        )
                        : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
                    }
                </>
            )}
            loading={loading}
            paginationProps={{
                loadList: (page) => {
                    setParams((prev) => ({ ...prev, page }));
                },
                list: meta,
                limit,
                setLimit
            }}
        />
    );
};

export default ItsDevelopmentLevels;
