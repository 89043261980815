import Settings from '../../../../common/Settings';
import {
    editSettingsGroup,
    editSettingsList,
    loadSettingsGroup
} from '../../../../../redux/Incidents/actions';

const DTPSettings = () => {
    return (
        <Settings
            title="Настройки – ДТП"
            storeProp="incidents"
            listName="settingsGroup"
            loadingName="loadingSettingsGroup"
            loadSettingsGroupAction={loadSettingsGroup}
            editSettingsListAction={editSettingsList}
            editSettingsGroupAction={editSettingsGroup}
        />
    );
};

export default DTPSettings;