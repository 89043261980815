// иконка для карты
const iconMap = ({ fill = '#000', innerFill = '#fff' }) => {
    return `

        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 107.000000 130.000000">
            <g>
                <rect fill="none" id="canvas_background" height="132" width="109" y="-1" x="-1"/>
            </g>
        
            <g>
                <g fill="${fill}" id="svg_meteo_1" transform="translate(0,130) scale(0.10000000149011612,-0.10000000149011612) ">
                    <path id="svg_meteo_2" d="m440.348843,1285.999999c-100,-19 -206,-75 -281,-150c-104,-104 -154,-228 -154,-381c0,-144 38,-245 149,-392c64,-85 297,-316 348,-345c30,-17 31,-17 71,12c68,49 237,216 302,299c157,200 210,350 181,517c-25,142 -121,287 -241,364c-108,70 -251,98 -375,76z"/>
                </g>
                <g fill="${innerFill}" stroke="null" id="svg_meteo_9">
                    <path stroke="null" id="svg_meteo_8" d="m80.295927,56.993668c0.022549,-0.214217 0.090196,-0.417159 0.090196,-0.631375c0,-3.979919 -3.235798,-7.215717 -7.215717,-7.215717c-1.420594,0 -2.728443,0.428433 -3.844624,1.127456c-1.984322,-4.374528 -6.370125,-7.441208 -11.488774,-7.441208c-6.967677,0 -12.627504,5.648553 -12.627504,12.627504c0,0.338237 0.078922,0.653924 0.101471,0.980886c-5.592181,0.417159 -10.023082,5.039727 -10.023082,10.744653c0,5.975515 4.84806,10.823575 10.823575,10.823575l30.666796,0c5.975515,0 10.823575,-4.84806 10.823575,-10.823575c0,-4.746589 -3.06668,-8.726507 -7.305913,-10.1922zm-48.525695,-2.54805c-4.926982,-4.926982 -4.926982,-12.931917 0,-17.847624c4.926982,-4.926982 12.931917,-4.926982 17.858899,0c1.093632,1.093632 1.9054,2.356383 2.514226,3.68678c1.104907,-0.417159 2.266186,-0.676473 3.461289,-0.845592l3.359818,-10.023082c0.450982,-1.341672 -0.823043,-2.604423 -2.164715,-2.164715l-9.899061,3.303445l-4.678941,-9.335334c-0.620101,-1.26275 -2.42403,-1.26275 -3.055405,0l-4.667667,9.335334l-9.910336,-3.303445c-1.330398,-0.439708 -2.604423,0.834317 -2.164715,2.164715l3.303445,9.899061l-9.335334,4.678941c-1.26275,0.631375 -1.26275,2.42403 0,3.055405l9.335334,4.667667l-3.303445,9.899061c-0.450982,1.341672 0.823043,2.604423 2.164715,2.164715l8.579938,-2.852463c0.687748,-1.398045 1.578438,-2.67207 2.660796,-3.776977c-1.476967,-0.608826 -2.863738,-1.510791 -4.058841,-2.705894zm-0.541179,-8.92945c0,4.600019 3.303445,8.433369 7.655425,9.278961c0.901965,-0.529904 1.837753,-0.992161 2.841188,-1.319123c0.608826,-4.994629 3.495113,-9.30151 7.599052,-11.838285c-1.488242,-3.280896 -4.791687,-5.580906 -8.625036,-5.580906c-5.22012,0 -9.470628,4.239234 -9.470628,9.459354z"/>
                </g>
            </g>
        </svg>
    `;
};

export default iconMap;
