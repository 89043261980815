import L from 'leaflet';
import { fullDateTimeWithTimeZone } from '../../../../helpers/date.config';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';

// иконка маркера
export const createIconMarker = (type) => {
    if (type === 1) {
        return L.divIcon({
            className: 'marker',
            iconSize: [20, 20],
            iconAnchor: [10, 20],
            popupAnchor: [0, -20],
            html: '<i class="fas fa-do-not-enter" style="color:#ff0000;font-size:20px;background:#fff;border-radius:50%;"/>',
        });
    }
    return L.divIcon({
        className: 'marker',
        iconSize: [25, 22],
        iconAnchor: [12, 22],
        popupAnchor: [0, -22],
        html: '<i class="overlap_img_warning"/>',
    });
};

export const getColor = (type) => {
    return type === 1 ? '#ff0000' : '#d266ce';
    // return type === 1 ? '#ff0000' : '#f8d522';
};

export const getFilter = (filter) => {
    const filters = {
        ...filter,
        start_at: filter?.start_at ? fullDateTimeWithTimeZone(filter.start_at) : null,
        end_at: filter?.end_at ? fullDateTimeWithTimeZone(filter.end_at) : null,
    };

    return removeEmptyFields(filters, false);
};