import React from 'react';
// import {Button} from '@mui/material';

// Кнопка для контекст меню
export const ContextMenuItem = ({
    onCloseMenu,
    e = {},
    value = '',
    className = '',
    onClick = null
}) => {
    const handleClick = () => {
        // закрыть меню
        onCloseMenu();
        onClick && onClick(e || null);
    };

    // <Button
    //     variant="contained"
    //     color="primary"
    //     type="submit"
    //     onClick={handleClick}
    // >
    //     {value}
    // </Button>

    return (
        <div
            onClick={handleClick}
            className={`button ${className}`}
        >
            {value}
        </div>
    );
};

export default ContextMenuItem;
