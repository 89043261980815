import { PickersDay, StaticDatePicker } from '@mui/lab';
import { Badge, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import ChangeCircleRoundedIcon from '@mui/icons-material/ChangeCircleRounded';

const useStyles = makeStyles({
    datePickerContainer: {
        '& .css-1dozdou': {
            display: 'none !important',
        },
    },
});

const RenderMonthForHistory = ({ year, monthNun, monthData, monthName, history }) => {
    const classes = useStyles();

    const renderDay = (date, _, dayProps) => {
        const isWeekend = monthData.weekends?.includes(date.day) || monthData.holidays?.includes(date.day);
        const isChanged = history && !!history[monthNun]?.[date.day];

        const updatedDayProps = { 
            ...dayProps, 
            style: { borderRadius: 1, ...(isWeekend && { backgroundColor: 'red' }) },
        };

        return isChanged
            ? <Badge overlap="circular" color="primary" variant="dot" key={String(date.day)}>
                <PickersDay {...updatedDayProps} selected={false}/>
            </Badge>
            : <PickersDay {...updatedDayProps} selected={false}/>;
    };
    
    return (
        <Grid item xs={3} className={classes.datePickerContainer}>
            <StaticDatePicker
                ToolbarComponent={() => (
                    <Typography variant="h6" align="center" m={1}>
                        {monthName}
                    </Typography>
                )}
                onChange={() => {}}
                value={new Date(Date.UTC(year, monthNun - 1, 1))}
                renderInput={(props) => <input {...props} size={'small'}/>}
                renderDay={renderDay}
            />
        </Grid>
        
    );
};

export default RenderMonthForHistory;