import React, { useState, useEffect } from 'react';
import { Autocomplete, IconButton, InputAdornment, TextField } from '@mui/material';
import Modal from '../../../Modal';
import FormButtons, { buttonsTypes } from '../../../FormButtons';
import UniversalSelect from '../../../UniversalSelect';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    input: {
        paddingRight: '54px !important',
    },
    adornment: {
        position: 'absolute',
        right: '6px',
    },
});

const WantedBrand = ({
    selected = {},
    wantedList = [],
    onChange = () => {},
    required = false,
    disabled = false,
    label= 'Марка',
    error = false,
    helperText = '',
}) => {
    const styles = useStyles();
    const [showList, setShowList] = useState(false);
    const [sortedList, setSortedList] = useState(wantedList);

    useEffect(() => {
        if (wantedList.length > 0) {
            setSortedList(wantedList);
        }
    }, [wantedList]);
    
    const autocompleteChange = (e, value) => {
        if (e?.key !== 'Enter') onChange(value);
    };

    const getBrands = (params) => {
        const { query } = params;

        const filterBrands = query 
            ? wantedList.filter(item => item?.brand?.toLowerCase().includes(query?.toLowerCase())) 
            : wantedList;
        setSortedList(filterBrands);
    };

    const handleAccept = (data) => {
        onChange(data.brand);
        setShowList(false);
    };

    return (
        <>
            <Autocomplete
                multiple={false}
                value={selected}
                options={[]}
                getOptionLabel={(option) => option?.brand || ''}
                onChange={autocompleteChange}
                freeSolo
                size="small"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={error}
                        size="small"
                        variant="outlined"
                        required={required}
                        name="type"
                        label={label}
                        helperText={helperText}
                        onChange={(e) => onChange(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            classes: { root: styles.input },
                            endAdornment: (
                                <InputAdornment position={'end'} classes={{ root: styles.adornment }}>
                                    {selected && selected?.brand?.length > 0
                                            && <IconButton size="small" onClick={() => onChange('')}>
                                                <i className="far fa-times"/>
                                            </IconButton>
                                    }
                                    <IconButton disabled={disabled} size="small" onClick={() => setShowList(true)}>
                                        <i className="fas fa-search-plus"/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                )}
            />
            {showList 
                && <UniversalSelect
                    multiple={false}
                    fetchList={getBrands}
                    sortedList={sortedList}
                    keyProp="brand"
                    withSearch
                    isSelected
                    selected={selected}
                    renderProps={(el) => <div>{el?.brand}</div>}
                    isOpen={showList}
                    onClose={() => setShowList(false)}
                    onAccept={handleAccept}
                    noPadding
                    small
                    title={'Добавление'}
                />
            }
        </>
    );
};

export default WantedBrand;