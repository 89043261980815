import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';

import { createStageSchedule } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import buttons from 'helpers/constants/buttons';
import { dateWithDashYYYYMMDD, getDaysOfInterval } from 'helpers/date.config';

import TableDates from './TableDates';
import TableAmount from './TableAmount';

const ModalSchedule = ({ 
    open, 
    onCloseModal, 
    works, 
    siteId, 
    stage, 
    reloadStageSchedule = () => {} 
}) => {
    const dispatch = useDispatch();
    const stage_schedule = useSelector(roadworksSelectors.stage_schedule);
    const loading_stage_schedule = useSelector(roadworksSelectors.loading_stage_schedule);
    const initialDates = useCallback(() => {
        const dateArray = getDaysOfInterval(stage?.start_date_plan, stage?.end_date_plan) || [];
        return dateArray.map(dt => dateWithDashYYYYMMDD(dt));
    }, [stage]);

    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({});

    const createEmptyItem = useCallback((date, item) => {
        return {
            amount: '0',
            amount_plan: '0',
            date_at: date,
            road_work_id: siteId,
            stage_id: stage.id,
            stage_road_work_id: item?.group?.stage_road_work_id,
        };
    },[siteId, stage.id]);

    useEffect(()=> {
        // начальная установка данных
        // собираем данные по датам в массив по столбцам будущей таблицы
        // const stageScheduleUniqueDate = Array.from(new Set(stage_schedule?.map(item => item.date_at)));
        const newUniqDates = Array.from(new Set([
            ...initialDates(),
            // ...stageScheduleUniqueDate
        ])).sort();
        const newData = newUniqDates.reduce((result, date) => {
            const work = works.map((item) => {
                // надо найти работу соответствуюущую связи в группе 
                const scheduleData = stage_schedule?.find((el) => {
                    return (el.amount_plan !== '0' || el.amount !== '0') && el?.stage_road_work_id === item?.group?.stage_road_work_id && el?.date_at === date;
                });
                return scheduleData || null;
            });
            if (work[0]) {
                result[date] = work;
            }
            return result;
        }, {});
        setData(newData);
    }, [stage_schedule, createEmptyItem, works]);

    const onSubmit = () => {
        const dataForSend = { 
            road_work_id: siteId,
            stage_id: stage.id,
            data: Object.values(data).reduce((res, arr) => {
                res = res.concat(arr);
                return res;
            }, [])
        };

        const callback = () => {
            reloadStageSchedule();
            onCloseModal();
        };

        dispatch(createStageSchedule(dataForSend, callback));
        setDisabled(true);
    };

    const createNewColumn = (date) => {
        return works.map((item) => createEmptyItem(date, item));
    };

    const sortAndSaveDatesFunc = (datesObj) => {
        setData(Object.keys(datesObj).sort().reduce((obj, key) => {
            obj[key] = datesObj[key];
            return obj;
        }, {}));
    };

    const addColumnStart = () => {
        const newDate = initialDates().filter(initDate => !data[initDate])[0];
        const newColumn = createNewColumn(dateWithDashYYYYMMDD(newDate));
        sortAndSaveDatesFunc({
            ...data,
            [dateWithDashYYYYMMDD(newDate)]: newColumn,
        });
    };

    const addColumnEnd = () => {
        const newUniqDates = initialDates().filter(initDate => !data[initDate]);
        const newDate = newUniqDates[newUniqDates.length - 1];
        const newColumn = createNewColumn(dateWithDashYYYYMMDD(newDate));
        sortAndSaveDatesFunc({
            ...data,
            [dateWithDashYYYYMMDD(newDate)]: newColumn,
        });
    };

    const handleChangeAmount = (date, index, e) => {
        const { name, value } = e.target;
        const el = data[date][index];
        const newData = {
            ...data,
            [date]: [
                ...data[date].slice(0, index),
                {
                    ...el,
                    [name]: value < 0 ? value * (-1) : Number(value), // если руками вбили минус но выкидываем его
                },
                ...data[date].slice(index + 1)
            ],
        };
        setData(newData);
        setDisabled(false);
    };

    const handleChangeDate = (date, val) => {
        const newData = {
            ...data,
            [dateWithDashYYYYMMDD(val)]: [{
                ...data[date][0],
                date_at: dateWithDashYYYYMMDD(val)
            }]
        };
        handleDeleteDate(newData, date);
    };

    const handleDeleteDate = (dataObj, date) => {
        delete dataObj[date];
        sortAndSaveDatesFunc(dataObj);
        setDisabled(false);
    };

    const isAddBtnDisable = () => {
        return initialDates().filter(initDate => !data[initDate]).length === 0;
    };

    return (
        <>
            <Modal
                isOpen={open}
                onClose={onCloseModal}
                title={'График проведения работ'}
                fullWidth
                buttons={
                    <FormButtons
                        buttons={
                            [
                                {
                                    ...buttonsTypes.cancel,
                                    name: buttons.CANCEL,
                                    onClick: onCloseModal
                                },
                                {
                                    ...buttonsTypes.save,
                                    name: buttons.SAVE,
                                    onClick: onSubmit,
                                    disabled: disabled
                                },
                            ]
                        }
                    />
                }
            >
                <div className="row">
                    <div style={{ width: '20%' }} className="flex-end">
                        <Tooltip title={'Добавить столбец'}>
                            <IconButton 
                                size="small" 
                                onClick={addColumnStart}
                                disabled={isAddBtnDisable()}
                            >
                                <Icon
                                    className={'far fa-plus'}
                                    size="small"
                                    style={{ color: isAddBtnDisable() ? '#b5b5b5' : '#0576f7' }}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="row__item flex-end">
                        {Object.keys(data).length !== 0
                            && <Tooltip title={'Добавить столбец'}>
                                <IconButton 
                                    size="small" 
                                    onClick={addColumnEnd}
                                    disabled={isAddBtnDisable()}
                                >
                                    <Icon
                                        className={'far fa-plus'}
                                        size="small"
                                        style={{ color: isAddBtnDisable() ? '#b5b5b5' : '#0576f7' }}
                                    />
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                </div>
                <div style={{ overflowX: 'auto' }}>
                    <TableContainer sx={{ width: 'max-content', overflow: 'initial' }}>
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        sx={{ width: '260px', position: 'sticky',  left: 0, background: 'white', zIndex: 111 }}
                                    >
                                        <p>Группа</p>
                                        <p>Работа/Ед.изм.</p>
                                    </TableCell>
                                    {Object.keys(data).map((date) => (
                                        <TableDates   
                                            key={date}
                                            item={date} 
                                            handleDelete={() => handleDeleteDate(data, date)}
                                            onChange={(val)=> handleChangeDate(date, val)}
                                            stage={stage}
                                        />
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {works.map((work) => (
                                    <TableRow key={work?.work?.id}>
                                        <TableCell
                                            variant="head"
                                            align="center"
                                            sx={{ width: '260px', position: 'sticky',  left: 0, background: 'white', zIndex: 111 }}
                                        >
                                            <p>{work?.group?.name}</p>
                                            <p>{work?.work?.name}</p>
                                            <p>{work?.units?.long_name}</p>
                                        </TableCell>
                                        {Object.entries(data).map(([date, items]) => {
                                            const elIndex = items.findIndex(el => el?.stage_road_work_id === work?.group?.stage_road_work_id);
                                            return (
                                                <TableAmount
                                                    key={date}
                                                    el={items[elIndex]}
                                                    onChange={(e)=> handleChangeAmount(date, elIndex, e)}
                                                    cellWidth={`${80 / Object.keys(data).length}%`}
                                                />
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                {loading_stage_schedule 
                    && <Loading linear />}
            </Modal>
        </>
    );
};

export default ModalSchedule;