import { useState } from 'react';
import { TextField } from '@mui/material';

import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import SelectRoutes from 'components/common/Autocomplete/PassengerTransport/Routes';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const STFilters = ({ hasCurrentRoute, setParams }) => {

    const initialState = {
        name: '',
        route: {}
    };

    const [data, setData] = useState(initialState);

    const onChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const handleResetFilter = (needRefresh) => {
        setData(initialState);
        if (needRefresh) {
            setParams(prev => ({ page: 1, data: { ...(hasCurrentRoute && { route_id: prev.data.route_id }) } }));
        }
    };

    const search = (filters = data) => {
        const prepareData = removeEmptyFields({
            ...filters,
            route_id: filters.route?.id,
            route: null,
        }, false);

        setParams(prev => ({ page: 1, data: { ...prev.data, ...prepareData } }));
    };

    const isDisabled = !(data.name.trim() || data.route?.id);

    const updateFilter = (filters) => {
        setData({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            search({
                ...initialState,
                ...filters,
            });
        }
    };


    return (
        <LayoutFilter
            onResetFilter={handleResetFilter} 
            onSearch={search} 
            filter={data} 
            setUserFilter={updateFilter}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                {!hasCurrentRoute && (
                    <SelectRoutes
                        selected={data.route}
                        onChange={(value) => onChange('route', value)}
                    />
                )}
                <TextField
                    variant="outlined"
                    value={data.name}
                    onChange={(e) => onChange('name', e.target.value)}
                    label={titles.NAME}
                    size="small"
                />

            </LayoutFilter.Visible>
        </LayoutFilter>

    );
};

export default STFilters;
