import { useState } from 'react';
import {
    List,
    Typography,
    IconButton, 
    Collapse,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import messages from 'helpers/constants/messages';
import RoadWorkSiteItem from './RoadWorkSiteItem';

const RoadWorkSites = ({ 
    list = [], 
    collapsedList, // разворачиваем ли мы список участков по клику на заголовок "Участки", или список сразу показан
    works = {},
    onSaveGroupWorks = () => {},
    // children,
    showInfo, // разворачиваем ли мы подробную инфу по группам и работам внутри участка
    stage = {},
    reloadStageSchedule = () => {}
}) => {
    const [isOpenRoadWork, setIsOpenRoadWork] = useState(false);
    const saveSite = (siteId, groupWorks) => {
        // мы получили обновленный список групп-работ для одного участка
        // обновляем список всех участков
        const newWorkData = {
            ...works,
            [siteId]: groupWorks
        };
        // проходим теперь по измененному списку и преобразуем его в нужный нам массив
        const road_works = Object.entries(newWorkData).map(([site_id, item]) => {
            return item.map((el) => {
                return {
                    road_work_id: site_id,
                    road_work_group_id: el.group.id,
                    work_id: el.work.id,
                    units_of_measurement_id: el.units.id
                };
            });
        }).flat();
        // сохраняем
        onSaveGroupWorks(road_works);
    };

    const renderList = (arr) => (
        arr?.length > 0 
            ? <List dense className="list">
                {arr.map((el, i) => <RoadWorkSiteItem 
                    key={el.id} 
                    item={el} 
                    index={i}
                    groupWorks={works[el.id]} // группы и работы этого учаська
                    onSaveSite={(val) => saveSite(el.id, val)}
                    showInfo={showInfo}
                    stage={stage}
                    reloadStageSchedule={reloadStageSchedule}
                />)}
            </List>  
            : <Typography variant="body2">{messages.NO_DATA}</Typography>
    );

    return (
        <>
            <Typography variant="body2">
                <strong>Участки работ:</strong>
                {collapsedList 
                    && <IconButton size="small" onClick={()=> setIsOpenRoadWork(!isOpenRoadWork)}>
                        {isOpenRoadWork
                            ? <ExpandLess fontSize="inherit" />
                            : <ExpandMore fontSize="inherit" />
                        }
                    </IconButton>
                }
            </Typography>
            {collapsedList
                ? <Collapse in={isOpenRoadWork} timeout={'auto'} unmountOnExit>
                    {renderList(list)}
                </Collapse>
                : <>{renderList(list)}</>
            }
        </>
    );
};

export default RoadWorkSites;