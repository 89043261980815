import CompaniesComponent from '../../pages/Dictionaries/Companies';
import CompaniesRoles from '../../pages/Dictionaries/CompaniesRoles';

const routes = [
    {
        path: '/dictionaries/companies',
        component: CompaniesComponent,
        exact: true,
    },
    {
        path: '/dictionaries/companies-roles',
        component: CompaniesRoles,
        exact: true,
    },
];

export default routes;