import React from 'react';
import {
    TextField,
} from '@mui/material';

export const FontSize = ({ size = '', handleChange, required, helperText, error }) => {
    return (
        <TextField
            value={size}
            onChange={handleChange}
            label="Размер шрифта (pt)"
            name="size"
            error={error}
            required={required}
            size="small"
            type="number"
            className="row__item"
            inputProps={{ min: 0 }}
            helperText={helperText}
        />
    );
};