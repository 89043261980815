import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadParks } from 'redux/TransportRoad/actions';
import { transportRoadSelectors } from 'redux/TransportRoad';
import messages from 'helpers/constants/messages';
import Context from 'helpers/context';
import titles from 'helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';

import PSEItem from './Item';
import PSEModal from './Modal';
import Filters from './Filters';

const ParkSpecialTransport = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const parksData = useSelector(transportRoadSelectors.parksData);
    const parksMeta = useSelector(transportRoadSelectors.parksMeta);
    const loadingParks = useSelector(transportRoadSelectors.loadingParks);

    const [openAddModal, setOpenAddModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = useCallback((shouldGoToPreviousPage = false) => {
        if (shouldGoToPreviousPage) {
            setParams({ ...params, page: params.page - 1 });
        } else {
            dispatch(loadParks(params.page, limit, params.data));
        }
    }, [dispatch, limit, params]);

    useEffect( () => {
        reloadList();
    }, [reloadList]);

    const renderContent = () => {
        return (
            <>
                {parksData?.length > 0
                    ? <LSContainer
                        headers={[
                            { title: titles.NAME, width: '50%' },
                            { title: 'Действия', align: 'right', isActions: true }
                        ]}
                    >
                        {parksData.map(el => (
                            <PSEItem
                                el={el}
                                key={el.id}
                                reloadList={(isDelete) => reloadList(isDelete && (parksData.length === 1 && parksMeta?.last_page > 1))}
                                permissions={permissions}
                            />
                        ))}
                    </LSContainer>
                    : !loadingParks && <div>{messages.DATA_IS_NOT_FOUND}</div>
                }
            </>
        );
    };

    return (
        <>
            <PageLayout
                header="Парки"
                filters={<Filters setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenAddModal(true),
                                        disabled: !permissions?.is_create,
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: parksMeta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: parksMeta,
                    limit,
                    setLimit
                }}
            />

            {openAddModal && (
                <PSEModal
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default ParkSpecialTransport;
