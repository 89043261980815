import { useSelector } from 'react-redux';
import { loadCategoryTypes } from 'redux/RoadNetwork/actions';
import { roadNetworksSelectors } from 'redux/RoadNetwork';
import { useStoreProp } from '../index';

// категории типы
const useCategoryTypes = () => {
    const categoryTypes = useStoreProp(loadCategoryTypes, 'roadNetworks', 'categoryTypes');
    const loadedCategoryTypes = useSelector(roadNetworksSelectors.loadedCategoryTypes);

    return {
        // загружено
        isLoaded: loadedCategoryTypes,
        // категории
        getCategories: () => categoryTypes,
        // категория по id
        getCategoryById: (category_id) => categoryTypes
            ?.find(({ id }) => id === category_id),
        // типы от категорий
        getTypes: (category_id) => categoryTypes
            ?.find(({ id }) => id === category_id)
            ?.types
            || [],
        // типы по категориям с категорией (для группировки)
        getTypesWithCategory: (category_ids) => categoryTypes
            ?.filter(({ id }) => category_ids?.includes?.(id))
            ?.reduce((res, category) => {
                return [
                    ...res,
                    ...category?.types?.map(i => ({
                        ...i,
                        category_name: category.name,
                        category_id: category.id
                    })) || []
                ];
            }, [])
            || [],
        // есть такая категория и тип
        isValid: (category_id, type_id) => !!(
            categoryTypes
                ?.find(({ id }) => id === category_id)
                ?.types
                   || []
        )?.find(({ id }) => id === type_id),
    };
};

export default useCategoryTypes;
