import { useState } from 'react';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import SingleKeyboardDateTimePicker from '../../../../common/Dates/SingleKeyboardDateTimePicker';
import { getYear, getYearDiff } from '../../../../../helpers/date.config';
import titles from '../../../../../helpers/constants/titles';

const Filter = ({ setParams }) => {
    const [data, setData] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    const handleSearch = () => {
        setParams((prev) => ({ ...prev, page: 1, query: data }));
        setIsSearch(true);
    };

    const handleResetFilter = () => {
        setData({});
        if(isSearch){
            setParams((prev) => ({ ...prev, page: 1, query: {} }));
            setIsSearch(false);
        }
    };

    return (
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        value={data.year || null}
                        onChange={(newDate) => {
                            setData({
                                ...data,
                                year: getYear(newDate)
                            });
                        }}
                        label={titles.YEAR}
                        dateOnly={true}
                        views={['year']}
                        pickerFormat="yyyy"
                        disableFuture
                        minDate={{ year: getYearDiff(50) }}
                    />
                </div>
            </div>
            <div className="filter__wrap filter__wrap__btn">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: handleResetFilter,
                            disabled: !data?.year && !isSearch
                        },
                        {
                            ...buttonsTypes.find,
                            disabled: !data.year,
                            onClick: handleSearch
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;
