import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import messages from '../../../../../helpers/constants/messages';
import titles from '../../../../../helpers/constants/titles';


const Information = (props) => {
    const { data } = props;

    const fields = {
        address: {
            name: titles.ADDRESS,
        },
        event: {
            name: titles.EVENTS,
            f: ({ event }) => event?.name || messages.INFO_IS_NOT_FOUND
        },
        type_name: {
            name: titles.TYPE,
        },
        keyword_list:{
            name: titles.KEYWORDS,
            f: ({ keyword_list }) => keyword_list?.map(({ name }) => name)?.join(', ') || messages.INFO_IS_NOT_FOUND
        },
        route: {
            name: titles.ROUTE,
            f: ({ route }) => {
                const { from = null, to = null, name = null } = route || {};
                return from || to || name ? `<strong>${name || ''}:</strong> ${from || ''} - ${to || ''}` : messages.INFO_IS_NOT_FOUND;
            }
        }
    };

    const RenderList = () => {
        if (!data) {
            return <div className="not-found">{messages.INFO_IS_NOT_FOUND}</div>;
        }

        return (
            <div className="information">
                {Object.keys(fields).map((field) => {
                    const f = fields[field].f ? fields[field].f(data) : null;
                    const value = f || data[field] || null;
                    const name = fields[field].name || '';

                    return (
                        <div className="info__item" key={`information_${field}`}>
                            <div className="info__item-title">
                                {name}
                                {': '}
                            </div>
                            <div
                                dangerouslySetInnerHTML={{ __html: value || messages.INFO_IS_NOT_FOUND }}
                            >
                                {/*{value || messages.INFO_IS_NOT_FOUND}*/}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div>
            <Scrollbars style={{ height: '200px' }}>
                <RenderList />
            </Scrollbars>
        </div>
    );
};

export default Information;
