import L from 'leaflet';

import { dateWithDashYYYYMMDD, getDateObjectFromFormat } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import iconMap from './icons/iconMap';
import layerConfig from './config';

export const getDefaultColor = () => layerConfig.defaultColors;

// получить иконку маркера
export const createIconMarker = (color) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: iconMap({ fill: color || getDefaultColor() })
    });
};

export const isShowJson = (zoom) => {
    return zoom >= 14;
};

export const getFilter = (filter) => {
    const formatDate = (date) => dateWithDashYYYYMMDD(getDateObjectFromFormat(date, 'dd.MM.yyyy'));

    const filters = {
        ...filter,
        registration_at_start: filter.registration_at_start ? formatDate(filter.registration_at_start, ) : null,
        registration_at_end: filter.registration_at_end ? formatDate(filter.registration_at_end) : null,
        liquidated_at_start: filter.liquidated_at_start ? formatDate(filter.liquidated_at_start) : null,
        liquidated_at_end: filter.liquidated_at_end ? formatDate(filter.liquidated_at_end) : null,
    };

    return removeEmptyFields(filters, false);
};
