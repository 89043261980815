import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import mapHelper from 'helpers/mapHelper';
import { useDebounce, usePrevious } from 'helpers/hooks';
import {
    Marker,
    PopUp,
    ContextMenu,
    ContextMenuItem,
    ToolTip,
} from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import MapLegends from 'components/common/Transport/MapLegends';
import * as actions from 'redux/VideoCameras/actions';

import CCPopup from './PopUp';
import config from './config.js';
import { createIconMarker } from './helper';
import CollectorForms from './CollectorForms';
import Legend from './Legend';


const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();
    const {
        polygon,
        active,
        savedCamera,
        filters,
    } = useSelector(({ videoCameras }) => videoCameras);
    const activeId = Object.keys(active || {}).length > 0 ? active.id : 0;

    const prevFilters = usePrevious(filters);

    useEffect(() => {
        if (savedCamera) {
            map.closeContextMenu();
            // map.closePopup();
            fetchPolygon();
            dispatch(actions.setEditForm());
            dispatch(actions.saveStatus(false));
        }
    }, [savedCamera]);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getPolygon(map);
        dispatch(actions.getForPolygon(polygon, getFilters(filters)));
    };

    const loadCityCameras = () => {
        dispatch(actions.loadSidebar(
            1,
            25,
            getFilters(filters)
        ));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();

    // добавить новый
    const handleAdd = ({ lat, lng }) => {
        dispatch(actions.setEditForm({
            lat,
            lon: lng,
        }));
    };

    // сдвинуться к маркеру
    const setMapToMarker = () => {
        if (Object.keys(active).length > 0) {
            const { lat, lon } = active;
            if (Math.abs(lat) && Math.abs(lon)) {
                setTimeout(() => {
                    map.setView([lat, lon]);
                }, 200);
            }
        }
    };

    useEffect(() => {
        fetchPolygon();
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        setMapToMarker();

        return () => {
            dispatch(actions.setActive({}));
            dispatch(actions.clearForPolygon());
            map.closeContextMenu();
            //map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters]);

    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, (e) => {
            //map.fire('context_menu_close');
            handleAdd(e.latlng);
        });

        return () => {
            map.off(config.mapContextMenu.event);
        };
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 15);
            }
        }
    }, [active]);

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProp }) => {
        return (
            <div>
                {!(Number(item.type) === 2 || Number(item.type) === 3)
                    && <ContextMenuItem
                        {...rcmProp}
                        value="Редактировать"
                        onClick={() => {
                            //map.fire('context_menu_close');
                            dispatch(actions.setEditForm(item));
                        }}
                    />
                }
                <ContextMenuItem
                    {...rcmProp}
                    value="Удалить"
                    onClick={() => {
                        //map.fire('context_menu_close');
                        dispatch(actions.setDeleteForm(item.id));
                    }}
                    className="red"
                />
            </div>
        );
    };

    return (
        <>
            {polygon.map((item) => {
                const { id, lat, lon } = item;
                const componentProps = {
                    ...props,
                    key: `item_${id}`,
                    attribution: {
                        slug: config.slug,
                        color: item.status_color
                    },
                    onClick: (latlng) => {
                        map.setView(latlng);
                        dispatch(actions.resetActive());
                    },
                };

                const currentShow = activeId === id;

                return (
                    <Marker
                        {...componentProps}
                        latlng={[lat, lon]}
                        icon={createIconMarker(item.status_color)}
                    >
                        <PopUp
                            // minWidth={600}
                            show={currentShow}
                            onClose={() => {
                                if (currentShow) {
                                    dispatch(actions.resetActive());
                                }
                            }}
                        >
                            <CCPopup
                                external_id={id}
                            />
                        </PopUp>

                        <ToolTip
                            offset={[0, -40]}
                            direction="top"
                        >
                            <div>
                                <b>Камера:</b>&nbsp;{item.name}
                            </div>
                        </ToolTip>

                        <ContextMenu>
                            <RenderContextMenu item={item} />
                        </ContextMenu>
                    </Marker>
                );
            })}

            <CollectorForms reloadList={loadCityCameras} />

            <MapLegends layer={config.slug}>
                <Legend/>
            </MapLegends>
        </>
    );
};

export default Layer;
