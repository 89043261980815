const getAddressStringFromObject = (
    addressObject,
    fields = [
        'area',
        'city_name',
        'settlement',
        'district',
        'street',
        'house',
    ]
) => {
    const a = fields?.reduce((res, key) => {
        if (addressObject?.[key]) {
            return [
                ...res,
                addressObject?.[key]
            ];
        }
        return res;
    }, []);
    return a.join(', ');
};

export default getAddressStringFromObject;