import React from 'react';
import Employees from '../../../../common/Transport/Employees';

const EmployeesList = () => {
    return (
        <>
            <Employees 
                storeName="transportPassenger"
            />
        </>
    );
};

export default EmployeesList;