import React, { Component, useEffect, useState } from 'react';
import Loading from '../../../../../components/common/Loading';
import { Scrollbars } from 'react-custom-scrollbars';
import {
    getEventsMeteo,
    clearEventsMeteo
} from '../../../../../redux/Meteo/actions';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '../../../../../helpers/hooks';
import messages from '../../../../../helpers/constants/messages';
import { fullDateTime } from '../../../../../helpers/date.config';
import { meteoSelectors } from '../../../../../redux/Meteo';

const TabEvents = (props) => {
    const { external_id } = props;
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const loading = useSelector(meteoSelectors.loading);
    const eventsDevice = useSelector(meteoSelectors.eventsDevice);
    const prevLoading = usePrevious(loading);

    useEffect(() => {
        if (page > 1) {
            dispatch(getEventsMeteo(external_id, page, limit, false));
        }
    }, [page, limit]);

    useEffect(() => {
        if (prevLoading === true && loading === false) {
            setIsOpen(true);
        }
    }, [loading]);

    useEffect(() => {
        dispatch(clearEventsMeteo());
        dispatch(getEventsMeteo(external_id, page, limit, false));
        return () => {
            dispatch(clearEventsMeteo());
        };
    }, []);

    const total = (eventsDevice.meta && eventsDevice.meta.last_page) || 0;

    const scrollHandleUpdate = (values) => {
        if (values.top > 0.85 && loading === false) {
            if (total && page < total) {
                setPage(page + 1);
            }
        }
    };

    return (
        <div>
            {loading && total === 0 && <Loading size={50} className="preloader center" />}

            {isOpen && (
                <>
                    {eventsDevice.data.length > 0 
                        ? <Scrollbars style={{ height: 210 }} onUpdate={scrollHandleUpdate}>
                            <table className="table-information">
                                <tbody>
                                    <tr>
                                        <th>Событие</th>
                                        <th>Дата</th>
                                    </tr>
                                    {eventsDevice.data.map(
                                        (
                                            {
                                                event_text,
                                                external_id,
                                                event_id,
                                                date_time_uts
                                            },
                                            index
                                        ) => (
                                            <tr key={`event_${external_id}_${event_id}_${index}`}>
                                                <td>
                                                    {event_text}
                                                </td>
                                                <td>
                                                    {fullDateTime(date_time_uts)}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table> 

                            {((total && page < total) || loading) && (
                                <div>
                                    <Loading className="preloader center" />
                                </div>
                            )}
                        </Scrollbars>
                        : <div className="not-found">{messages.EVENTS_IS_NOT_FOUND}</div>
                    }
                </>
            )}
        </div>
    );
};

export default TabEvents;
