import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';
import { getRoles } from '../../../../redux/Admin/actions';
import Form from './EditorForm';
import RoleItem from './RoleItem';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import Context from '../../../../helpers/context';
import { adminSelectors } from '../../../../redux/Admin';
import PageLayout from '../../../layout/PageLayout';
import NoData from '../../../common/Information/NoData';
import Filter from './Filter';

const RoleManagement = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const roles = useSelector(adminSelectors.roles);
    const loading = useSelector(adminSelectors.loading);

    const [modalVisible, setModalVisible] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = () => dispatch(getRoles(params.page, limit, params.data));

    useEffect(() => {
        dispatch(getRoles(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return (
            <>
                {roles?.data?.length > 0
                    ? (<>
                        <List className="list">
                            {roles?.data.map((item) => (
                                <RoleItem
                                    key={item.id}
                                    role={item}
                                    reloadList={reloadList}
                                />
                            ))}
                        </List>
                    </>)
                    : (!loading && <NoData/>)
                }
            </>
        );
    };

    return (
        <>
            <PageLayout
                header="Управление ролями"
                filters={<Filter onFilterChanged={(filter) => setParams({ page: 1, data: filter })}/>}
                informPanelProps={{ 
                    buttons: (
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setModalVisible(true),
                                    disabled: !permissions?.is_create
                                }
                            ]}
                            noPadding
                            noMarginLeft
                        />
                    ),
                    total: roles?.meta?.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: roles?.meta,
                    limit,
                    setLimit
                }}
            />

            {modalVisible && (
                <Form
                    onClose={() => setModalVisible(false)}
                    isOpen={modalVisible}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default RoleManagement;
