import { useCallback, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';

import SidebarItem from 'components/MapComponents/SidebarItem';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import getFilters from 'components/MapComponents/helpers/getFilters';
import Loading from 'components/common/Loading';
import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import colorExtend from 'helpers/mapHelper/colorExtend';
import {
    clearFilters,
    setFilter,
    setSidebarParamsRW,
} from 'redux/RoadWorks/actions';
import {
    loadRoadWorks,
    setEditForm,
    setDeleteForm,
    setActiveRW,
} from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import Form from './Form';
import Icon from '../icons/icon';
import Item from './Item';
import config from '../config';


const SideBarRW = (props) => {
    const dispatch = useDispatch();

    const list = useSelector(roadworksSelectors.list);
    const loading = useSelector(roadworksSelectors.loading);
    const activeRW = useSelector(roadworksSelectors.activeRW);
    const filters = useSelector(roadworksSelectors.filters);
    const sidebarParams = useSelector(roadworksSelectors.sidebarParams);

    const activeRWId = Object.keys(activeRW).length > 0 ? activeRW.id : 0;

    useEffect(() => {
        return () => {
            dispatch(setSidebarParamsRW({
                ...sidebarParams,
                page: 1,
            }));
        };
    }, []);

    const handleScrollUpdate = (values) => {

        const last_page = list?.meta?.last_page || 0;
        if (
            values.top > 0.98
            && loading === false
            && sidebarParams.page < last_page
        ) {
            dispatch(setSidebarParamsRW({
                ...sidebarParams,
                page: sidebarParams.page + 1,
            }));
        }
    };

    const fetchList = useCallback(() => {
        dispatch(loadRoadWorks({
            ...sidebarParams,
            ...getFilters(filters),
        }, sidebarParams.page === 1));
    }, [sidebarParams, filters, dispatch]);

    // отслеживаем изменения для перезапроса списка
    useEffect(() => {
        fetchList();
    }, [sidebarParams, fetchList]);

    useEffect(() => {
        dispatch(setSidebarParamsRW({
            ...sidebarParams,
            page: 1,
        }));
    }, [filters]);

    const renderList = (dataList) => (
        <>
            {dataList?.map((item) => {
                return (
                    <SidebarItem
                        key={item.id}
                        active={activeRWId === item.id}
                        icon={
                            Icon({
                                fill: colorExtend(item?.color)
                            })
                        }
                        buttons={{
                            ...(item.status !== 3 ? {
                                edit: {
                                    title: buttons.EDIT,
                                    icon: <i className="fas fa-pen"/>,
                                    onClick: () => {
                                        dispatch(setEditForm(item));
                                    }
                                },
                            } : {}),
                            delete: {
                                title: buttons.DELETE,
                                icon: <i className="fas fa-trash-alt" />,
                                onClick: () => {
                                    dispatch(setDeleteForm(item.id));
                                }
                            }
                        }}
                        onClick={() => {
                            dispatch(setActiveRW(item));
                        }}
                    >
                        <Item {...item} />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                onSetFilter={(filter) => dispatch(setFilter({ ...filter }))}
                resetListPage={() => dispatch(setSidebarParamsRW({ ...sidebarParams, page: 1 }))}
                onClearFilter={() => dispatch(clearFilters())}
                content={({ data, onChange }) => <Form data={data} onChange={onChange} />}
                layer={config.slug}
            />

            {loading && list?.data?.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title="Участки"
                            // title={titles.ROAD_WORKS}
                            list={list}
                        />
                        {list?.data?.length === 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList(list?.data)}
                    </div>
                    {loading && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
};

export default SideBarRW;
