import { useState } from 'react';
import { Tooltip } from '@mui/material';

import FrameModal from 'components/common/FrameVideoModal/FrameModal';
import ModalForm from 'components/pages/Dictionaries/VideoAnalytics/VACameras/ModalForm';

const Item = (props) => {
    const {
        id,
        itemID,
        name,
        status,
        status_text,
        // status_color,
        // statusesOb,
        url_player,
        setItemID
    } = props;

    const [openFrameModal, setOpenFrameModal] = useState(false);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <div className="description">
                    {name}
                </div>

                {/* статус */}
                {status
                    && <div className="status">
                        {status_text}
                        {/*{statusesOb?.get(status)?.name || ''}*/}
                    </div>
                }
            </div>
            <div
                style={ !url_player
                    ? {
                        pointerEvents: 'none',
                        opacity: '0.5'
                    }
                    : {}
                }
            >
                <Tooltip title="Просмотр видео">
                    <i className="far fa-play-circle"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setOpenFrameModal(true);
                        }}
                    ></i>
                </Tooltip>
            </div>

            {openFrameModal && (
                <FrameModal
                    isOpen={openFrameModal}
                    onClose={() => setOpenFrameModal(false)}
                    link={url_player}
                    title={name}
                />
            )}

            {(itemID === id) && (
                <ModalForm
                    isOpen={!!itemID}
                    onClose={() => setItemID(null)}
                    item={props}
                    reloadList={() => {}}
                />
            )}
        </div>
    );
};

export default Item;
