import { renderToString } from 'react-dom/server';
import icons from '../../../../MapComponents/Layers/TransportPassenger/icons/icons';
import L from 'leaflet';

export const createTransportIcon = (category_id, in_registry, getTransportCategory) => {
    const {
        getImageTpl,
        color,
    } = getTransportCategory
        ?.getHelper
        ?.getCategory(
            parseInt(category_id),
            in_registry
        );

    const imageTpl = (
        <div className="circle" style={{ background: color }}>
            <div className="icon">
                {getImageTpl('image-inner margin', icons({ fill: '#000' }))}
            </div>
        </div>
    );

    return L.divIcon({
        html: renderToString(imageTpl),
        className: 'transport-passenger-marker-icon',
        iconSize: [30, 30]
    });
};