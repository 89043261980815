import { IconButton, Link, ListItem, ListItemText, Tooltip } from '@mui/material';
import { useState } from 'react';
import titles from '../../../../../helpers/constants/titles';
import { Delete } from '@mui/icons-material';
import ConfirmDelete from '../../../../common/ConfirmModal';
import { useDispatch } from 'react-redux';
import { deleteCSODDFile } from '../../../../../redux/Upload/actions';

interface ItemProps {
    item: {
        id: string,
        filename: string,
        link: string,
    },
    loadList: () => void
}

const Item = ({ item, loadList }: ItemProps) => {
    const dispatch = useDispatch();

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const callback = () => {
        loadList();
        setOpenDeleteModal(false);
    };

    return (
        <>
            <ListItem className="list_item">
                <ListItemText>
                    <Link href={item.link} target="_blank">{item.filename}</Link>
                </ListItemText>
                <Tooltip title={titles.DELETE}>
                    <IconButton onClick={() => setOpenDeleteModal(true)} size="small">
                        <Delete fontSize="inherit" />
                    </IconButton>
                </Tooltip>
                {openDeleteModal &&  (
                    <ConfirmDelete
                        open={openDeleteModal}
                        onSuccess={() => dispatch(deleteCSODDFile(item.id, callback))}
                        onClose={() => setOpenDeleteModal(false)}
                        onlyClose={false}
                        loadingButton={false} 
                    />
                )}
            </ListItem>
        </>
    );
};

export default Item;
