import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';

import * as actions from 'redux/DorisControl/actions';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import SelectCheckList from 'components/common/Autocomplete/DorisControl/CheckList';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import SelectDuploCodes from 'components/common/Autocomplete/DorisControl/DuploCodes';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import { useStoreProp, useValidation } from 'helpers/hooks';

import type { DuploCodesItem } from '../DuploCodes/types';
import type { AddEditModalProps, DuploCode, ItemType } from './types';


const AddEditModal = ({
    item,
    isOpen = false,
    isNew = false,
    onClose,
    reloadList,
}: AddEditModalProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const complexTypes = useStoreProp(actions.loadComplexTypes, 'dorisControl', 'doris_complex_types');
    
    const [data, setData] = useState({
        name: item?.name || '',
        complex_types: item?.complex_types?.map(( el, index) => ({
            id: el,
            name: item?.complex_types_text?.[index] || ''
        })) || [],
        duplo_codes: item?.duplo_codes || [],
        duplo_code_violations: item?.duplo_code_violations || [],
        checkboxes: item?.checkboxes || []
    });

    const handleChange = <T,>(name: string, value: T) => {
        setData(prev => ({
            ...prev,
            [name]: value
        }));
        validation.deleteKey(name);
    };


    const onChangeDuploCodes = (value: ItemType<DuploCodesItem>) => {
        if (Array.isArray(value)) {
            const firstItem = value.find(item => typeof item === 'object' && item !== null) as DuploCode | undefined;
            setData(prev => ({
                ...prev,
                duplo_codes: firstItem ? [firstItem] : [],
                duplo_code_violations: firstItem?.pdd_violations?.map(v => v.id) || []
            }));
        } else if (typeof value === 'object' && value !== null) {
            setData(prev => ({
                ...prev,
                duplo_codes: [value],
                duplo_code_violations: [value?.id] || []
            }));
        } else {
            setData(prev => ({
                ...prev,
                duplo_codes: [],
                duplo_code_violations: []
            }));
        }
    };

    const onAcceptModal = () => {
        const newData = {
            ...data,
            complex_types: data?.complex_types?.map(el => el.id),
            checkboxes: data?.checkboxes?.map((el: { id: number }) => el.id),
        };

        dispatch(isNew 
            ? actions.createCheckListGroup(newData, () => {
                onClose();
                reloadList();
            }) 
            : actions.editCheckListGroup(item?.id, newData, () => {
                onClose();
                reloadList();
            }));
    };

    const isDisabled = !data.name && data.duplo_code_violations?.length === 0 && data?.checkboxes?.length === 0;
    
    return (<Modal
        isOpen={isOpen}
        title={isNew ? 'Добавить чек-лист' : 'Редактировать чек-лист'}
        onClose={onClose}
        noPadding={true}
        buttons={<FormButtonsComponent
            buttons={[
                {
                    ...buttonsTypes.close,
                    onClick: onClose
                },
                {
                    ...buttonsTypes.save,
                    onClick: onAcceptModal,
                    disabled: isDisabled
                }
            ]}
        />}
    >
        <form className="modal__form">
            <FormInfoWrapper 
                error={validation.isKey('name')}
                helperText={validation.get('name')}
                className="block"
            >
                <TextField
                    label="Название группы"
                    size="small"
                    value={data.name}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                    required
                    error={validation.isKey('name')}
                />
            </FormInfoWrapper>

            <div className="block">
                <CommonAutocomplete
                    multiple={true}
                    selected={data.complex_types}
                    onChange={(value) => handleChange('complex_types', value)}
                    onReset={() => handleChange('complex_types', [])}
                    inputName={'Типы комплексов'}
                    limitTags={1}
                    label={'Типы комплексов'}
                    options={complexTypes}
                    filterSelectedOptions={true}
                    renderLabel={(option) => option?.name  || ''}
                    error={validation.isKey('complex_types')}
                    helperText={validation.get('complex_types') || 'Если Тип комплекса не выбран, данный чек-лист будет применяться для всех типов'}
                    showAsSelect
                />
            </div>

            <div className="block">
                <SelectDuploCodes
                    label="Код ДУПЛО"
                    required
                    selected={data.duplo_codes[0] || {}}
                    onChange={onChangeDuploCodes}
                    filter={{ is_otk: 1 }}
                    error={validation.isKey('duplo_code_violations')}
                    helperText={validation.get('duplo_code_violations')}
                />
            </div>

            <div className="block">
                <SelectCheckList
                    multiple
                    selected={data.checkboxes}
                    onChange={(value) => handleChange('checkboxes', value)}
                    required
                    error={validation.isKey('checkboxes')}
                    helperText={validation.get('checkboxes')}
                />
            </div>
        </form>
    </Modal>);
};

export default AddEditModal;