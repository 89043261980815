import { useSelector } from 'react-redux';
import { Collapse } from '@mui/material';

import { transportPassengerSelectors } from 'redux/TransportPassenger';
import Loading from 'components/common/Loading';

import EventTable from './EditTrafficScheduleTable/EventTable';

const renderDaysOfWeek = (str) => {

    const week = {
        0: { name: 'пн' },
        1: { name: 'вт' },
        2: { name: 'ср' },
        3: { name: 'чт' },
        4: { name: 'пт' },
        5: { name: 'сб' },
        6: { name: 'вс' },
    };

    const newArr = [];
    str.split('').forEach((day, index) => day === '1' ? newArr.push(index) : null);

    const arrOfName = [];
    newArr.forEach((day) => arrOfName.push(week[day]?.name));

    return arrOfName;
};

const Info = ({ isOpen, data, id, item }) => {

    const trafficScheduleEventsLoading = useSelector(transportPassengerSelectors.trafficScheduleEventsLoading);

    return (
        <Collapse
            className="info"
            in={isOpen}
            timeout={'auto'}
            style={{ backgroundColor: 'transparent' }}
        >
            <div style={{ margin: '10px 10px 20px' }}>
                {item?.route_direct
                    && <div>
                        <span className="margin-right"><b>№ марш: </b> {item?.route_direct?.num} </span>
                        <span className="margin-right"><b>Название: </b> {item?.route_direct?.name} </span>
                        <span className="margin-right"><b>Тип маршрута: </b> {item?.route_direct?.is_direct_text} </span>
                    </div>
                }
                {item?.route_back
                    && <div>
                        <span className="margin-right"><b>№ марш: </b> {item?.route_back?.num} </span>
                        <span className="margin-right"><b>Название: </b> {item?.route_back?.name} </span>
                        <span className="margin-right"><b>Тип маршрута: </b> {item?.route_back?.is_direct_text} </span>
                    </div>
                }

            </div>
            <div style={{ margin: '10px 10px 20px' }}>
                <span className="margin-right"><b>№ смены: </b> {item?.shift} </span>

                <span className="margin-right"><b>Дни недели: </b> {renderDaysOfWeek(item?.days_week)?.length > 0
                    ? renderDaysOfWeek(item?.days_week)
                        ?.map((render,index) =>
                            <span key={render}>
                                {render}
                                {(renderDaysOfWeek(item?.days_week)?.length - 1) !== index
                                    ? ', '
                                    : ''
                                }
                            </span>)
                    : '–'} </span>
                <span className="margin-right"><b>Праздничные дни: </b> {item?.is_holiday ? 'да' : 'нет'} </span>

                {item?.organization?.title && (
                    <span><b>Организация-перевозчик: </b> {item.organization.title} </span>
                )}
            </div>

            {trafficScheduleEventsLoading
                ? <Loading linear={true}/>
                : <EventTable data={data} id={id} hideIcons={true}/>
            }
        </Collapse>
    );
};

export default Info;