import * as types from './types';

/**
 * Actions
 **/

export const loadVersion = () => ({
    type: types.LOAD_VERSION,
});

export const showVersion = (bool = false) => ({
    type: types.SHOW_VERSION,
    payload: bool,
});
