import L from 'leaflet';

export const serviceList = {
    transportPassenger: 'transportPassenger',
    transportRoad: 'transportRoad'
};

export const options = {
    basicData: 'BasicData',
    modalForm: 'ModalForm',
};

// иконка
export const icon = (color, isMoove = false) => {
    return L.divIcon({
        style: {
            backgroundColor: 'inherit',
        },
        className: 'marker',
        iconSize: [23, 30],
        iconAnchor: [12, 30],
        popupAnchor: [0, -30],
        html: `
            <div style="color: ${color}; cursor: ${isMoove ? 'move' : 'hand'}">
                <i style="font-size: 30px" class="fa fa-map-marker" aria-hidden="true"></i>
            </div>
        `
    });
};

export const colors = {
    disabled: '#676a679e',
    isStation: '#3399fe',
    isCheckPoint: '#da573e',
};
