import React, { useState, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { dateWithDashYYYYMMDD } from '../../../../../helpers/date.config';
import { loadReportByPeriod } from '../../../../../redux/DorisControl/actions';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import GetReports from '../../../../common/GetReports';
import GovernmentContractList from '../../../../common/Autocomplete/GovernmentContract';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';
import DateRange from '../../../../common/Dates/DateRange';

interface FilterState {
    start_date: Date | null;
    end_date: Date | null;
    organization_id: number | null;
    contract_id_list: Array<{ id: number, number: number }>;
    formats: Array<{ id: number }>;
    company: { id?: number };
}

const Filter: React.FC = () => {
    const dispatch = useDispatch();

    const initialState: FilterState = {
        start_date: null,
        end_date: null,
        organization_id: null,
        contract_id_list: [],
        formats: [],
        company: {},
    };

    const [filters, setFilters] = useState<FilterState>(initialState);

    const handleChange = (name: keyof FilterState, value: any) => {
        setFilters({ ...filters, [name]: value });
    };

    const download = () => {
        const newData = {
            ...filters,
            start_date: dateWithDashYYYYMMDD(filters.start_date),
            end_date: dateWithDashYYYYMMDD(filters.end_date),
            organization_id: filters?.company?.id,
            contract_id_list: filters?.contract_id_list?.map(({ id }) => id),
            formats: filters.formats?.map(({ id }) => id),
        };
        dispatch(loadReportByPeriod(removeEmptyFields(newData)));
    };

    const resetFilter = () => setFilters(initialState);

    const updateFilter = (filter: SetStateAction<FilterState> = initialState) => {
        setFilters({
            ...initialState,
            ...filter
        });
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            buttonType="getReport"
            onSearch={download}
            filter={filters}
            filterException={['start_date', 'end_date']}
            setUserFilter={updateFilter}
            isFilterVisible={true}
            disabled={!(filters?.start_date && filters?.end_date && filters?.formats?.length)}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filters.start_date}
                    valueEndDate={filters.end_date}
                    handleDate={(date: Date | null) => handleChange('start_date', date)}
                    handleEndDate={(date: Date | null) => handleChange('end_date', date)}
                    dateOnly
                />
                <SelectCompany
                    selected={filters.company}
                    onChange={(company) => handleChange('company', company)}
                    label="Балансодержатель"
                    withRole={false}
                    filter={{ withDeleted: 1 }}
                    selectDeletedCompanies
                />
                <GovernmentContractList
                    label="Госконтракт"
                    multiple
                    selected={filters?.contract_id_list || []}
                    onChange={(value) => handleChange('contract_id_list', value)}
                />
                <GetReports
                    selected={filters.formats}
                    onChange={(formats: Array<{ id: number }>) => handleChange('formats', formats)}
                    reportId={2}
                    onDownload={undefined}
                    helperText={undefined}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
