import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadDadataAddressByCoordinates,
    clearDadataAddressByCoordinates,
} from '../../../../redux/Dadata/actions';
import getAddressStringFromObject from './getAddressStringFromObject';


// компонент адрес зависит от координаты
const LoadAddressByCoords = ({
    // изменение
    onChange = (arg) => {},
    // реагировать на изменение lat и lon
    isLoadOnLatLon = true,
    // реагировать на изменение в дочерних компонентах
    isLoadOnChange = false,
    // дочерние компоненты
    children,
    // остальные пропсы
    ...props
}) => {
    const {
        lat = '',
        lon = '',
        // address = {},
        address_text = '',
    } = props;

    const dispatch = useDispatch();
    const addressByCoordinates = useSelector(({ dadata }) => dadata?.addressByCoordinates);
    const isLatLon = useRef(false);

    // запрос адреса, если изменились координаты
    useEffect(() => {
        if (
            isLoadOnLatLon
            && lat
            && lon
            && isLatLon.current
        ) {
            dispatch(loadDadataAddressByCoordinates(lat, lon));
        }
        isLatLon.current = true;
    }, [isLoadOnLatLon, lon, lat, dispatch]);

    // если изначально нет адреса но есть координаты
    useEffect(() => {
        if (
            isLoadOnLatLon
            && !address_text
            && lat
            && lon
        ) {
            dispatch(loadDadataAddressByCoordinates(lat, lon));
        }
    }, []);

    useEffect(() => {
        // адрес загружен от координат
        if (Object.keys(addressByCoordinates).length > 0) {
            const parsed_address_text = getAddressStringFromObject(addressByCoordinates);

            if (address_text !== parsed_address_text) {
                onChange({
                    address_text: parsed_address_text,
                    address: addressByCoordinates
                });
            }
            dispatch(clearDadataAddressByCoordinates());
        }
    },[addressByCoordinates, address_text, onChange, dispatch]);

    const handleChange = (obj) => {
        if (
            isLoadOnChange
            && obj.hasOwnProperty('lat')
            && obj.hasOwnProperty('lon')
        ) {
            dispatch(loadDadataAddressByCoordinates(obj?.lat, obj?.lon));
        }

        onChange(obj);
    };

    // рендерим детей с новыми параметрами
    return React.Children
        .map(children, (child) => React.cloneElement(child, { ...props, onChange: handleChange }));
};

export default LoadAddressByCoords;