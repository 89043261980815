// иконка для кластера
const iconCluster = (props) => {
    return `<svg
                xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 107.000000 107.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <defs>
                    <symbol xmlns="http://www.w3.org/2000/svg" className="svg-inline--fa fa-car-bump fa-w-18"
                            data-icon="car-bump" data-prefix="far" id="svg_ti_6" viewBox="0 0 576 512">
                        <path
                            d="M101.98,308.12C84.29,316.14 77.99,332.93 86.21,350.08C94.43,367.23 111.71,373.32 129.39,365.3C147.08,357.28 174.98,348.1 166.76,330.95C158.54,313.79 119.67,300.09 101.98,308.12zM337.81,201.15C320.12,209.17 305.93,246.94 314.15,264.09C322.37,281.24 347.53,266.35 365.22,258.33C382.91,250.31 389.21,233.52 380.99,216.37C372.77,199.22 355.49,193.13 337.81,201.15zM116.19,450.03L440.45,302.95L450.76,324.46C456.45,336.34 470.97,341.47 483.18,335.94L497.92,329.25C510.13,323.71 515.41,309.59 509.72,297.72L465.05,204.53L451.3,175.86C437.28,146.61 409.85,127.7 379.47,122.56L303.32,53.32C279.08,31.28 243.5,25.78 213.43,39.42L54.57,111.46C24.5,125.1 5.96,155.15 7.73,187.38L13.31,288.63C-2.17,314.57 -4.91,347.17 9.12,376.42L67.54,498.28C73.23,510.16 87.75,515.3 99.96,509.76L114.7,503.07C126.91,497.53 132.19,483.41 126.5,471.54L116.19,450.03zM75.19,154.47L234.04,82.42C246.13,76.93 260.25,79.12 270,87.98L317.98,131.6L195.17,187.31L59.97,248.63L56.45,184.84C55.74,171.88 63.09,159.96 75.19,154.47zM95.57,407.02C87.44,410.71 77.75,407.27 73.96,399.37L53.34,356.36C41.97,332.64 52.56,304.35 76.94,293.29L342.24,172.96C366.62,161.9 395.71,172.2 407.07,195.91L427.69,238.92C431.48,246.83 427.95,256.26 419.82,259.94L95.57,407.02zM464,384C402.25,384 352,430.65 352,488C352,501.25 362.75,512 376,512S400,501.25 400,488C400,457.13 428.72,432 464,432S528,457.12 528,488C528,501.25 538.75,512 552,512S576,501.25 576,488C576,430.66 525.75,384 464,384z"
                            id="svg_ti_9"></path>
                    </symbol>
                </defs>
                <g style="pointer-events:all">
                    <g className="layer" id="svg_ti_10">
                        <g id="svg_ti_3">
                            <g id="svg_ti_4">
                                <g id="svg_ti_5">
                                    <g fill="${props?.fill || '#e7e7e7'}" id="svg_ti_1"
                                       transform="translate(0,107) scale(0.10000000149011612,-0.10000000149011612)">
                                        <path
                                            d="M404.52236,1047.19878C176.82012,992.02864 7.2973,774.35732 7.2973,535.62105C7.2973,249.73937 248.03976,10 533.92144,10C819.80311,10 1060.54558,250.74246 1060.54558,536.62414C1060.54558,774.35732 888.01348,994.03482 658.30504,1048.20188C579.06065,1066.25756 482.76366,1066.25756 404.52236,1047.19878z"
                                            id="svg_ti_2" stroke="null"></path>
                                    </g>
                                </g>
                            </g>
                            <g id="svg_ti_8"></g>
                            <use fill="${props?.innerFill || 'rgba(0, 0, 0, 0.54)'}" id="svg_ti_7" transform="matrix(0.685398 0 0 0.685398 1.79236 -4.91265)" x="22.489932"
                                 xlink:href="#svg_ti_6"
                                 y="19.612244"
                            ></use>
                        </g>
                    </g>
                </g>
            </svg>`;
};

export default iconCluster;
