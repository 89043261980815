import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { editRepairObject } from 'redux/RoadWorks/actions';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer, LSHeaderColumn, LSHeaderItem } from 'components/common/ListStyle';
import messages from 'helpers/constants/messages';

import AddEditModal from './AddEditModal';
import Item from './Item';

const Indicators = ({ currentObject, reloadList }) => {
    const dispatch = useDispatch();
    const indicators = currentObject?.indicators || [];

    const [openAddModal, setOpenAddModal] = useState(false);

    const saveChanges = (indicators, onClose) => {
        const callback = () => {onClose(); reloadList();};
        dispatch(editRepairObject(
            currentObject.id,
            { ...currentObject, indicators },
            callback
        ));
    };

    const handleAdd = (indicator, onClose) => {
        const newData = [...indicators, indicator];
        saveChanges(newData, onClose);
    };

    const handleEdit = (index, indicator, onClose) => {
        const newData = [
            ...indicators.slice(0, index),
            indicator,
            ...indicators.slice(index + 1)
        ];
        saveChanges(newData, onClose);
    };

    const handleDelete = (index, onClose) => {
        const newData = [
            ...indicators.slice(0, index),
            ...indicators.slice(index + 1),
        ];
        saveChanges(newData, onClose);
    };


    return (
        <>
            <FormButtons
                positionLeft
                buttons={[
                    {
                        ...buttonsTypes.add,
                        onClick: () => setOpenAddModal(true)

                    }
                ]}
            />
            {indicators?.length > 0
                ? <LSContainer>
                    <LSHeaderItem fullWidth>
                        <LSHeaderColumn width="30%">{'Показатель'}</LSHeaderColumn>
                        <LSHeaderColumn width="30%">{'Значение'}</LSHeaderColumn>
                        <LSHeaderColumn width="30%">{'Единица измерения'}</LSHeaderColumn>
                        <LSHeaderColumn align="right" isActions>Действия</LSHeaderColumn>
                    </LSHeaderItem>
                    {indicators?.map((item, index) => (
                        <Item
                            key={index}
                            index={index}
                            item={item}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    ))}
                </LSContainer>

                : <div>{messages.DATA_IS_NOT_FOUND}</div>
            }
            {openAddModal
                && <AddEditModal
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    onAccept={handleAdd}
                    index={indicators?.length}
                />
            }
        </>
    );
};

export default Indicators;
