import BoardAndSigns from '../../pages/Dictionaries/Boards/BoardAndSigns';
import MessageTemplates from '../../pages/Dictionaries/Boards/MessageTemplates';
import Signs from '../../pages/Dictionaries/Boards/Signs';
import AlertTemplates from '../../pages/Dictionaries/Boards/AlertTemplates';

const routes = [
    {
        path: '/dictionaries/board/:id?',
        component: BoardAndSigns,
        exact: true,
    },
    {
        path: '/dictionaries/board-message-templates',
        component: MessageTemplates,
        exact: true
    },
    {
        path: '/dictionaries/board-signs',
        component: Signs,
        exact: true
    },
    {
        path: '/dictionaries/board-alert-templates',
        component: AlertTemplates,
        exact: true
    },
];

export default routes;