import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    LinearProgress,
} from '@mui/material';

import Layer from '../../../MapComponents/Layers/Meteo/layer';
import layerConfig from '../../../MapComponents/Layers/Meteo/config';
import CardWidget from '../../../common/Showcase/CardWidget';
import {
    clearShowcase,
    loadShowcase,
} from '../../../../redux/Meteo/actions';
import { meteoSelectors } from '../../../../redux/Meteo';
import TypeComponent from '../../../common/Showcase/TypeComponent';
import {
    fullDateTimeWithoutSeconds,
} from '../../../../helpers/date.config';
import useShowcase from '../../../../helpers/hooks/useShowcase';
import CardMap from '../../../common/Showcase/CardMap';

// страница
const Page = () => {
    const dispatch = useDispatch();
    const isShowcase = useShowcase();

    const showCase = useSelector(meteoSelectors.showcase);
    const loadingShowcase = useSelector(meteoSelectors.loadingShowcase);

    const {
        graph = [],
        table = [],
    } = showCase;
    const isGraph = graph?.length > 0;
    const isTable = table?.length > 0;

    useEffect(() => {
        dispatch(loadShowcase());
        return () => dispatch(clearShowcase());
    }, []);

    return (
        <>
            <Grid container spacing={2} sx={{ height: '100%' }}>
                {isTable && (
                    <Grid
                        item
                        xs={12}
                        md={4}
                        className="showcase_grid_flex-column"
                    >
                        {loadingShowcase && <LinearProgress/>}

                        {table.map((item, index) => (
                            <CardWidget
                                key={index}
                                icon={layerConfig.layerIcon}
                                title={item.name}
                                marginBottom
                                fillOthers
                                small
                            >
                                <TypeComponent
                                    {...item}
                                    indexColor={index}
                                    labelFormat={(value) => fullDateTimeWithoutSeconds(value)}
                                />
                            </CardWidget>
                        ))}
                    </Grid>
                )}

                <Grid
                    item
                    xs={12}
                    {...isTable ? { md: 8 } : {}}
                    className="showcase_grid_flex-column"
                >
                    <CardMap
                        fillOthers
                        isCluster
                    >
                        <Layer readOnly={isShowcase} visibleLegend />
                    </CardMap>
                </Grid>

                {isGraph && (
                    <Grid item container spacing={2} xs={12}>
                        {graph.map((item, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                <CardWidget
                                    icon={layerConfig.layerIcon}
                                    title={item.name}
                                    marginBottom
                                    small
                                >
                                    <TypeComponent
                                        {...item}
                                        indexColor={index}
                                        labelFormat={(value) => fullDateTimeWithoutSeconds(value)}
                                    />
                                </CardWidget>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>

        </>
    );
};

export default Page;
