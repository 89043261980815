import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Modal from '../../../../common/Modal';
import { TextField } from '@mui/material';
import { createEvent, editEvent } from '../../../../../redux/SituationalPlans/actions';
import DateRange from '../../../../common/Dates/DateRange';
import titles from '../../../../../helpers/constants/titles';
import { useValidation } from '../../../../../helpers/hooks';
import { fullDateTimeWithTimeZone } from '../../../../../helpers/date.config';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';

const ModalForm = ({ isOpen, onClose, isNew = false, item={} }) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const [data, setData] = useState({
        name: item?.name || '',
        short_name: item?.short_name || '',
        international_name: item?.international_name || '',
        date_start: item?.date_start || null,
        date_end: item?.date_end || null,
    });

    const closeForm = () => onClose(false);

    const onAcceptModal = () => {
        const correctStartDate = fullDateTimeWithTimeZone(data.date_start);
        const correctEndDate = fullDateTimeWithTimeZone(data.date_end);
        const resultData = removeEmptyFields({
            ...data,
            date_start: correctStartDate,
            date_end: correctEndDate
        });
        isNew
            ? dispatch(createEvent(resultData))
            : dispatch(editEvent(item?.id, resultData));
    };

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const isDisabled = !data.name || !data.short_name || !data.date_start
        || !data.date_end || new Date(data.date_start) >= new Date(data.date_end) || validation.isValidation();

    useEffect(() => {
        if (requestStatus.success) {
            onClose(false);
            validation.clear();
        }
    }, [requestStatus.success, onClose, validation]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title={isNew ? titles.CREATE : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: closeForm
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <TextField
                        label={titles.NAME}
                        size="small"
                        value={data.name}
                        variant="outlined"
                        name="name"
                        type="text"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        required
                        error={!data.name || validation.isKey('name')}
                        helperText={validation.get('name')}
                    />
                </div>
                <div className="block">
                    <TextField
                        label={titles.SHORT_TITLE}
                        size="small"
                        value={data.short_name}
                        variant="outlined"
                        name="short_name"
                        type="text"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        required
                        error={!data.short_name || validation.isKey('short_name')}
                        helperText={validation.get('short_name')}
                    />
                </div>
                <div className="block">
                    <TextField
                        label={titles.INTERNATIONAL_NAME}
                        size="small"
                        value={data.international_name}
                        variant="outlined"
                        name="international_name"
                        type="text"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        error={validation.isKey('international_name')}
                        helperText={validation.get('international_name')}
                    />
                </div>
                <div className="row">
                    <DateRange
                        className="row__item"
                        handleDate={(e) => setData({ ...data, date_start: e })}
                        handleEndDate={(e) => setData({ ...data, date_end: e })}
                        valueStartDate={data.date_start}
                        valueEndDate={data.date_end}
                        startLabel={titles.START_DATE}
                        endLabel={titles.END_DATE}
                        required={true}
                        disableFuture={false}
                    />
                </div>
            </form>
        </Modal>
    );
};

export default ModalForm;
