import React, { useEffect, useState } from 'react';
import { useValidation } from '../../../../helpers/hooks';
import titles from '../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../FormButtons';
import { TextField, Typography } from '@mui/material';
import Modal from '../../Modal';
import { SketchPicker } from 'react-color';


function ModalForm(props) {
    const {
        data = {},
        isOpen = false,
        onClose,
        onChanged,
        colorProvider,
    } = props;

    const validation = useValidation();
    const [fields, setFields] = useState(data);
    const isNew = !fields?.id;

    const handleChange = (name, value) => {
        setFields((old) => ({
            ...old,
            [name]: value,
        }));
        validation.deleteKey(name);
    };

    const callback = (status) => {
        if (status) {
            setFields({});
            onClose && onClose();
            onChanged && onChanged();
        }
    };

    const onAccept = () => {
        isNew
            ? colorProvider.add(fields, callback)
            : colorProvider.edit(fields.id, fields, callback);
    };

    useEffect(() => {
        return () => {
            validation.clear();
        };
    }, []);

    return (
        <Modal
            small
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? `${titles.ADD}` : `${titles.EDIT}`}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAccept,
                        disabled: validation.isValidation()
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <TextField
                        required
                        value={fields?.speed || ''}
                        onChange={(e) => {
                            const { name, value } = e.target;
                            handleChange(name, value);
                        }}
                        variant="outlined"
                        size="small"
                        label="Скорость"
                        name="speed"
                        error={validation.isKey('speed')}
                        helperText={validation.get('speed')}
                    />
                </div>

                <div className="block">
                    <label>Цвет:</label>
                    {/*<Typography>Цвет:</Typography>*/}
                    <SketchPicker
                        width={'calc(100% - 20px)'}
                        color={fields?.color || ''}
                        onChange={({ hex }) => handleChange('color', hex)}
                    />
                    {validation.isKey('color') && (
                        <div className="error">
                            {validation.get('color')}
                        </div>
                    )}
                </div>
            </form>
        </Modal>
    );
}

export default ModalForm;
