import * as types from './types';

export const getStatuses = () => ({
    type: types.GET_STATUSES,
});

export const saveStatuses = (statuses) => ({
    type: types.STATUSES_LOADED,
    payload: statuses,
});

export const loading = (bool = false) => ({
    type: types.LOADING,
    payload: bool,
});

export const getUsers = (params = { page: 1, limit: 25 }) => ({
    type: types.GET_USERS,
    payload: params,
});

export const saveUsers = (users) => ({
    type: types.USERS_LOADED,
    payload: users,
});

export const getRoles = (page = 1, limit = 25, name = '') => ({
    type: types.GET_ROLES,
    payload: { page, limit, name },
});

export const saveRoles = (roles) => ({
    type: types.ROLES_LOADED,
    payload: roles,
});

export const userRegister = (user) => ({ // регистрация юзера из формы логина, в ЕПУТС нет такго функционала
    type: types.REGISTER_NEW_USER,
    payload: user,
});

export const createUser = (user, callback) => ({
    type: types.CREATE_NEW_USER,
    payload: user,
    callback
});

export const editUser = (user, callback) => ({
    type: types.EDIT_USER,
    payload: user,
    callback
});

export const deleteUser = (id, callback) => ({
    type: types.DELETE_USER,
    payload: id,
    callback
});

export const getPermissions = (name = '') => ({
    type: types.GET_PERMISSIONS,
    payload: name
});

export const savePermissions = (permissions) => ({
    type: types.PERMISSIONS_LOADED,
    payload: permissions,
});

export const createRole = (role, callback) => ({
    type: types.CREATE_ROLE,
    payload: role,
    callback,
});

export const editRole = (role, callback) => ({
    type: types.EDIT_ROLE,
    payload: role,
    callback,
});

export const deleteRole = (id, callback) => ({
    type: types.DELETE_ROLE,
    payload: id,
    callback
});

export const loadAdminDtpSettings = (page = 1, limit = 100) => ({
    type: types.LOAD_DTP_SETTINGS,
    payload: { page, limit }
});

export const loadingAdminDtpSettings = (bool = false) => ({
    type: types.LOADING_DTP_SETTINGS,
    payload: bool
});

export const loadedAdminDtpSettings = (data) => ({
    type: types.LOADED_DTP_SETTINGS,
    payload: data
});

export const editAdminDtpSettings = (data) => ({
    type: types.EDIT_DTP_SETTINGS,
    payload: data
});

export const loadCurrentUser = (id) => ({
    type: types.LOAD_CURRENT_USER,
    payload: id
});

export const loadingCurrentUser = (bool = false) => ({
    type: types.LOADING_CURRENT_USER,
    payload: bool
});

export const loadedCurrentUser = (data) => ({
    type: types.LOADED_CURRENT_USER,
    payload: data
});

export const resetPassword = (data) => ({
    type: types.RESET_PASSWORD,
    payload: data
});

export const resettingPassword = (bool = false) => ({
    type: types.RESETTING_PASSWORD,
    payload: bool
});

export const resetedPassword = (data) => ({
    type: types.RESETED_PASSWORD,
    payload: data
});

export const loadSettings = (page, limit, data) => ({
    type: types.LOAD_SETTINGS,
    payload: { page, limit, data },
});

export const loadingSettings = (bool) => ({
    type: types.LOADING_SETTINGS,
    payload: bool
});

export const loadedSettings = (data) => ({
    type: types.LOADED_SETTINGS,
    payload: data
});

export const editSetting = (id, params, callback) => ({
    type: types.EDIT_SETTING,
    payload: { id, params },
    callback,
});

export const editSettingsList = (data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_LIST,
    payload: data,
    callback
});

export const loadUserActivity = (page, limit, data) => ({
    type: types.LOAD_USER_ACTIVITY,
    payload: { page, limit, data }
});

export const loadingUserActivity = (bool) => ({
    type: types.LOADING_USER_ACTIVITY,
    payload: bool
});

export const loadedUserActivity = (response) => ({
    type: types.LOADED_USER_ACTIVITY,
    payload: response
});

export const loadMethods = () => ({
    type: types.LOAD_METHODS,
});

export const loadedMethods = (response) => ({
    type: types.LOADED_METHODS,
    payload: response
});

export const loadMicroservices = () => ({
    type: types.LOAD_MICROSERVICES,
});

export const loadedMicroservices = (response) => ({
    type: types.LOADED_MICROSERVICES,
    payload: response
});

export const loadActions = () => ({
    type: types.LOAD_ACTIONS,
});

export const loadedActions = (response) => ({
    type: types.LOADED_ACTIONS,
    payload: response
});

// Settings Group
export const loadSettingsGroup = () => ({
    type: types.LOAD_SETTINGS_GROUP,
});

export const loadingSettingsGroup = (bool) => ({
    type: types.LOADING_SETTINGS_GROUP,
    payload: bool
});

export const loadedSettingsGroup = (data) => ({
    type: types.LOADED_SETTINGS_GROUP,
    payload: data
});

export const editSettingsGroup = (id, data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_GROUP,
    payload: { id, data },
    callback
});

export const loadingButton = (bool = false) => ({
    type: types.LOADING_BUTTON,
    payload: bool,
});