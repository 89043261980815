import { PickersDay, StaticDatePicker } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useState } from 'react';
import ModalForm from './ModalForm';

const useStyles = makeStyles({
    datePickerContainer: {
        '& .css-1dozdou': {
            display: 'none !important',
        },
    },
});

const RenderMonth = ({ year, monthNun, monthData, monthName, loadCalendar }) => {
    const classes = useStyles();
    const [openChangeModal, setOpenChangeModal] = useState(null);

    const isWeekend = useCallback((day) => {
        return monthData.weekends?.includes(day) || monthData.holidays?.includes(day);
    },[monthData]);

    const handleDayClick = ({ day, month }) => {
        const dayObj = {
            year, 
            day, 
            month, 
            type_id: isWeekend(day) ? 2 : 1,
            is_manual: 1
        };

        setOpenChangeModal(dayObj);
    };

    const renderDay = (date, _, dayProps) => {
        const updatedDayProps = { 
            ...dayProps, 
            style: { borderRadius: 1, ...(isWeekend(date.day) && { backgroundColor: 'red' }) },
        };

        return <PickersDay {...updatedDayProps} selected={false}/>;
    };
    
    return (
        <Grid item sm={'auto'} md={3} className={classes.datePickerContainer}>
            <StaticDatePicker
                ToolbarComponent={() => (
                    <Typography variant="h6" align="center" m={1}>
                        {monthName}
                    </Typography>
                )}
                value={new Date(Date.UTC(year, monthNun - 1, 1))}
                onChange={handleDayClick}
                renderInput={(props) => <input {...props} />}
                renderDay={renderDay}
            />
            {!!openChangeModal && (
                <ModalForm
                    isOpen={!!openChangeModal}
                    onClose={() => setOpenChangeModal(null)}
                    item={openChangeModal}
                    loadCalendar={loadCalendar}
                />
            )}
        </Grid>
        
    );
};

export default RenderMonth;