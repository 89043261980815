import { REPORT_FILE_TYPES, REPORT_FILE_TYPES_SMALL, REPORT_FILE_TYPES_SMALL_GRAPH } from './mime-types';

export const REPORT_FORMATS = REPORT_FILE_TYPES.reduce((res, el) => {
    res[el.name] = el.name;
    return res;
}, {});
export const REPORT_FORMATS_SMALL = REPORT_FILE_TYPES_SMALL.reduce((res, el) => {
    res[el.name] = el.name;
    return res;
}, {});
export const REPORT_FILE_SMALL_GRAPH = REPORT_FILE_TYPES_SMALL_GRAPH.reduce((res, el) => {
    res[el.name] = el.title || el.name;
    return res;
}, {});