import React, { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const initialState = {
    name: ''
};

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{ page: number; data: {} }>>;
}

const Filter = ({ setParams }: FilterProps) => {
    const [filter, setFilter] = useState(initialState);

    const handleChangeFilter = (name: string, value: string) => {
        setFilter({ ...filter, [name]: value });
    };

    const handleResetFilter = (needRefresh: boolean) => {
        setFilter(initialState);

        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }
    };

    const handleSearch = () => {
        setParams({ page: 1, data: filter });
    };

    const handleUpdateFilter = (filters: { name?: string }) => {
        setFilter({ ...initialState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({ page: 1, data: filters });
        }
    };

    return (
        <LayoutFilter
            filter={filter}
            onSearch={handleSearch}
            onResetFilter={handleResetFilter}
            setUserFilter={handleUpdateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={filter.name}
                    onChange={(e) => handleChangeFilter('name', e.target.value)}
                    label={titles.NAME}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
