import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';

import { deleteWorkorder, editWorkorder } from 'redux/TransportRoad/actions';
import { loadCompanies } from 'redux/Companies/actions';
import messages from 'helpers/constants/messages';
import { humanDateWithoutTZ } from 'helpers/date.config';
import parseByLink from 'helpers/parseByLink';
import colorStatus from 'helpers/workorder.config';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';

import AddEditRequisites from './AddEditRequisites/AddEditRequisites';
import AddEditModal from './AddEditModal';
import { roadworksworkordersURI } from './moduleConfig';

const test_id_prefix = roadworksworkordersURI; // префикс для полей для автотестов

const Item = ({ currentOrder = {}, item, reloadList, }) => {
    const dispatch = useDispatch();
    const [currentItem, setCurrentItem] = useState({});
    const [company, setCompany] = useState({});

    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [openRequisites, setOpenRequisites] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditStatusModal, setEditStatus] = useState(false);

    useEffect(() => {
        // нужно обновить если пришел новый item
        if (item.id) {
            setCurrentItem((old) => ({
                ...old,
                ...item
            }));
        }
    }, [item]);

    useEffect(() => {
        // нужно обновить компанию в наряде, если мы открыли новую заявку в списке
        if (item.organization) {
            setCompany(item.organization);
        }else {
            // есть есть айди огранизации, загружаем ее чтобы отобразить в таблице
            // используем загрузку списка т.к. там можно подтянуть и удаленные
            dispatch(loadCompanies(1, 1, { id_list: [item.organization_id], withDeleted: 1 },
                (organization = []) => {
                    setCompany(organization[0]);
                }
            ));
        }
    }, [item?.organization_id, item.organization, dispatch]);


    const deleteItem = () => {
        dispatch(deleteWorkorder(item.id, () => {
            setOpenDeleteModal(false);
            reloadList();
        }));
    };

    const onSave = (id, data) => {
        dispatch(editWorkorder(id, data, () =>  {
            setOpenAddEdit(false);
            reloadList();
        }));
    };

    const onAcceptStatusChange = () => {
        dispatch(editWorkorder(item.id, {
            ...item,
            status: 5
        }, () => {
            setEditStatus(false);
            reloadList();
        }));
    };
    const statusRender = () => (item.status
        ? <Tooltip title={item.status_text}>
            <div style={{ backgroundColor: colorStatus(item.status), width: 20, height: 20, borderRadius: '50%' }}></div>
        </Tooltip>
        : messages.NO_VALUE
    );

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {statusRender()}
                </LSContentColumn>
                <LSContentColumn>
                    {currentItem.date_at ? humanDateWithoutTZ(currentItem.date_at) : messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {currentItem.id || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {parseByLink(company)('title', messages.NO_VALUE)}
                </LSContentColumn>
                <LSContentColumn>
                    {parseByLink(currentItem)('route.num', messages.NO_VALUE)}
                </LSContentColumn>
                <LSContentColumn>
                    {parseByLink(currentItem)('route.name', messages.NO_VALUE)}
                </LSContentColumn>
                <LSContentColumn>
                    {parseByLink(currentItem)('vehicle.number', messages.NO_VALUE)}
                </LSContentColumn>
                <LSContentColumn>
                    {currentOrder?.name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        uri={roadworksworkordersURI}
                        buttons={[
                            // {
                            //     icon: <ListAltIcon />,
                            //     name: 'Реквизиты заказа-наряда',
                            //     onClick: () => setOpenRequisites(true),
                            // },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenAddEdit(true),
                                disabled: currentItem.status !== 1
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            },
                            {
                                ...buttonsTypes.changeStatusIcon,
                                name: 'Перевести в статус Отменен',
                                disabled: currentItem.status !== 1,
                                onClick: () => setEditStatus(true),

                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
            {openAddEdit
                && <AddEditModal
                    isNew={false}
                    // isNew={item?.isTraffic}
                    data={currentItem}
                    isOpen={openAddEdit}
                    onClose={() => setOpenAddEdit(false)}
                    onSave={(data) => onSave(currentItem.id, data)}
                    currentOrder={currentOrder}
                />
            }
            {openRequisites
                && <AddEditRequisites
                    data={currentItem}
                    isOpen={openRequisites}
                    onClose={() => setOpenRequisites(false)}
                />
            }

            {openDeleteModal
                && <ConfirmDelete
                    onClose={() => setOpenDeleteModal(false)}
                    onSuccess={deleteItem}
                    open={openDeleteModal}
                />
            }
            {openEditStatusModal &&  (
                <ConfirmDelete
                    message='Вы точно хотите изменить текущий статус на "Отменен"'
                    open={openEditStatusModal}
                    onSuccess={onAcceptStatusChange}
                    onClose={() => setEditStatus(false)}
                />
            )}
            {/* <TableContainer className="table_container">
                <Table className={classes.table} size="small">

                    <TableHead>
                        <TableRow>
                            {labels?.map(({ title }, i) =>
                                <TableCell
                                    key={`wo_title_${i}`}
                                    className={i === labels.length - 1 && data?.length
                                        ? classes.stickyColumn
                                        : classes.cells
                                    }
                                    align="left"
                                >
                                    {title}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data?.map((item) => (
                            <Row
                                key={item.id}
                                labels={labels}
                                item={item}
                                currentOrder={currentOrder}
                                reloadList={reloadList}
                            />
                        ))}
                    </TableBody>

                </Table>
            </TableContainer> */}
        </>
    );
};

export default Item;
