import React, { useEffect } from 'react';
import { loadVehicleInfo } from '../../../../redux/WantedCars/actions';
import WantedBrand from './WantedBrand';
import WantedColor from './WantedColor';
import WantedModel from './WantedModel';
import { useDispatch, useSelector } from 'react-redux';

const WantedCars = ({ 
    selectedBrand, 
    selectedModel,
    selectedColor,
    onChange,
    brandError = false,
    colorError = false,
    modelError = false,
    validation = {}
}) => {
    const dispatch = useDispatch();
    const { vehicleWanted } = useSelector(({ carsWanted }) => carsWanted);

    const changeBrand = (newBrand) => {
        onChange(
            {
                brand: newBrand,
                model: '',
                color: selectedColor.color || '',
            },
        );
    };

    const changeModel = (newModel) => {
        onChange(
            {
                brand: selectedBrand.brand || '',
                model: newModel,
                color: selectedColor.color || '',
            },
        );
    };

    const changeColor = (newColor) => {
        onChange(
            {
                brand: selectedBrand.brand || '',
                model: selectedModel.model || '',
                color: newColor
            },
        );
    };

    useEffect(()=> {
        dispatch(loadVehicleInfo());
    }, []);

    return (
        <div className="row">
            <div className="row__item">
                <WantedBrand
                    selected={selectedBrand || {}}
                    wantedList={Object.keys(vehicleWanted.brand_and_model).map((item) => ({ brand: item }))}
                    onChange={changeBrand}
                    error={validation?.isKey?.('brand')}
                    helperText={validation?.get?.('brand')}
                />
            </div>
            <div className="row__item">
                <WantedModel
                    selected={selectedModel || {}}
                    selectedBrand={selectedBrand || {}}
                    wantedList={vehicleWanted.brand_and_model?.[selectedBrand.brand]?.map(item => ({ model: item }))}
                    onChange={changeModel}
                    error={validation?.isKey?.('model')}
                    helperText={validation?.get?.('model')}
                />
            </div>
            <div className="row__item">
                <WantedColor
                    selected={selectedColor || {}}
                    wantedList={vehicleWanted.color.map((item) => ({ color: item }))}
                    onChange={changeColor}
                    error={validation?.isKey?.('color')}
                    helperText={validation?.get?.('color')}
                />
            </div>
        </div>
    );
};

export default WantedCars;