import React, { useEffect, useState } from 'react';
import SideBar from '../SideBar';
import { Drawer, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import makeStyles from '@mui/styles/makeStyles';
import useShowcase from '../../../helpers/hooks/useShowcase';

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        backgroundColor: 'white',
        minWidth: 50,
        minHeight: 50,
        zIndex: 5,
        left: 10,
        boxShadow: '5px 5px 13px #c7c7c7, -5px -5px 13px #f9f9f9',
        '&:hover' : {
            backgroundColor: '#e1dfdf'
        }
    }
});

const AppSidebar = (props) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // useEffect(() => {
    //     window.addEventListener('resize',() => setWindowWidth(window.innerWidth));

    //     return () => window.removeEventListener('resize', () => setWindowWidth(window.innerWidth));
    // }, []);

    // витрина (не выводится меню)
    const isShowCase = useShowcase();
    if (isShowCase) return null;

    return (
        <>
            <SideBar {...props} />
            {/* {windowWidth > 1280
                ? <SideBar {...props} />
                : (
                    <>
                        <IconButton
                            className={classes.root}
                            onClick={() => setOpen(!open)}
                            style={{ ...(props.headerHeight && { top: `calc((${props.headerHeight}px - 50px) / 2)` }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer open={open} onClose={() => setOpen(false)}>
                            <SideBar {...props} />
                        </Drawer>
                    </>
                )
            } */}
        </>
    );
};

export default AppSidebar;
