import iconCluster from './icons/iconCluster';
// import { clearFilters } from '../../../../redux/RoadWorks/actions';

const config = {
    name: 'Комплексы фото видео фиксации',
    slug: 'camera',
    mapContextMenu: {
        title: 'Добавить КФВФ',
        event: 'add_cpvf',
        // className: styles.button,
        minWidth: 210,
    },
    mapMarkersColors: { // от статуса
        1: '#daa655', // Включена
        2: '#444', // Имеются неполадки
        3: '#ad1818', // Выключена
        4: '#2e4ac4', // "В Сервисном обслуживании",
        5: '#000', //"Нет данных"
        default: '#31bebe',
    },
    clusterIcon: iconCluster,
    // onAfterActivateAction: () => clearFilters(),
    layerIcon: 'fas fa-camera-alt',
};
// добавление на карте
export default config;
