import { useState } from 'react';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import { TextField } from '@mui/material';
import titles from '../../../../helpers/constants/titles';
import SelectCompany from '../../../common/Autocomplete/Companies';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({ setParams }) => {
    const initialState = {
        first_name: '',
        last_name: '',
        middle_name: '',
        email: '',
        org_ids: [],
    };

    const [filters, setFilters] = useState(initialState);

    const onChange = (name, value) => {
        setFilters({ ...filters, [name]: value });
    };

    const handleSearch = (data = filters) => {
        const prepareData = removeEmptyFields(
            { ...data, org_ids: data.org_ids.map((item) => item?.id) },
            false
        );

        setParams({ page: 1, data: prepareData });
    };

    const handleReset = (needRefresh) => {
        setFilters(initialState);
        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }
    };

    const updateFilter = (filters) => {
        setFilters({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            handleSearch({
                ...initialState,
                ...filters,
            });
        }
    };

    const isDisabled = Object.keys(removeEmptyFields(filters)).length === 0;

    return (
        <LayoutFilter
            onResetFilter={handleReset} 
            onSearch={handleSearch} 
            filter={filters} 
            setUserFilter={updateFilter}  // если у пользователя сохранен фильтр для этой страницы, мы его из общего компонента сетаем сюда
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={filters.last_name}
                    onChange={(e) => onChange('last_name', e.target.value)}
                    label={titles.LAST_NAME}
                    variant="outlined"
                    size="small"
                />
                <TextField
                    value={filters.first_name}
                    onChange={(e) => onChange('first_name', e.target.value)}
                    label={titles.FIRST_NAME}
                    variant="outlined"
                    size="small"
                />
                <TextField
                    value={filters.middle_name}
                    onChange={(e) => onChange('middle_name', e.target.value)}
                    label={titles.PATRONYMIC}
                    variant="outlined"
                    size="small"
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <TextField
                    value={filters.email}
                    onChange={(e) => onChange('email', e.target.value)}
                    label={titles.EMAIL}
                    variant="outlined"
                    size="small"
                />
                <SelectCompany
                    multiple
                    selected={filters.org_ids}
                    onChange={(value) => onChange('org_ids', value)}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;