import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { loadCameras } from 'redux/VideoAnalytics/actions';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import renderAddress from 'helpers/renderAddress';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const SelectCameras = ({
    multiple = false,
    selected = multiple ? [] : '',
    onChange = (value) => {},
    required = false,
    disabled = false,
    optionWithAddress = false,
    label = titles.CAMERAS,
    params = {}, // параметры для запроса на АПИ
    error = false,
    helperText = '',
    loadAction = null,
    limitTags = 1,
}) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);

    useEffect(() => {
        // например сброс фильтра - selected изменился нужно обновить selectedItems
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    const getList = ({ page, limit, query: name }) => {
        const paramsWithoutEmpty = removeEmptyFields({ name, ...params });

        loadAction
            ? dispatch(loadAction(paramsWithoutEmpty))
            : dispatch(loadCameras(page, limit, paramsWithoutEmpty));
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                error={error}
                helperText={helperText}
                required={required}
                inputName="route_id"
                disabled={disabled}
                onReset={() => onChange(multiple ? [] : {})}
                renderLabel={(option) => option?.name}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    storeName={'videoAnalytics'}
                    storeNameProps={'cameras'}
                    keyProp={'id'}
                    fetchList={getList}
                    withSearch={true}
                    multiple={multiple}
                    isSelected={true}
                    // onChange={handleChange}
                    selected={selectedItems}
                    renderProps={({ name, address }) => (
                        optionWithAddress
                            ? (
                                <div className="block">
                                    <span>{name}</span>
                                    <span>{renderAddress(address)}</span>
                                </div>
                            )
                            : <span>{name}</span>
                    )}
                    isOpen={showList}
                    onClose={() => setShowList(false)}
                    onAccept={handleAccept}
                    noPadding
                    small
                    title={titles.CAMERAS}
                />
            }
        </>
    );
};

export default SelectCameras;
