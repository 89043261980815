import { ListItem, ListItemText } from '@mui/material';

import { useStyles } from './index';
import { serviceList } from '../helper';

import type { Checkpoint } from '../types';

interface ItemProps {
    item: Checkpoint;
    service: string;
}

const Item = ({ item, service }: ItemProps) => {
    const styles = useStyles();

    return (
        <ListItem className={styles.item_text} disableGutters>
            <ListItemText disableTypography className={styles.row}>
                <span style={{ minWidth: 16 }}>{item.number}</span>

                <span className={styles.item} style={{ wordBreak: 'break-word' }}>
                    {item.station?.name || item.check_point?.name}
                </span>

                {service === serviceList.transportPassenger && (
                    <>
                        <span className={styles.item}>{item.mileage || 0} км</span>
                        <span className={styles.item}>{item.interval || '-'}</span>
                        <span className={styles.item}>{item.station ? 'ОСТ' : 'КТ'}</span>
                    </>
                )}
            </ListItemText>
        </ListItem>
    );
};

export default Item;
