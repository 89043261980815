import moduleName from './module';

const prefix = `${moduleName}`;

/**
 * Constants
 * */
export const LOADING = `${prefix}/LOADING`;
export const SIGNIN_USER = `${prefix}/SIGNIN_USER`;
export const SAVE_USER_DATA = `${prefix}/SAVE_USER_DATA`;
export const SIGNUP_USER = `${prefix}/SIGNUP_USER`;
export const SIGNOUT_USER = `${prefix}/SIGNOUT_USER`;
export const SEND_TOKEN_REQUEST = `${prefix}/SEND_TOKEN_REQUEST`;
export const REFRESH_TOKEN = `${prefix}/REFRESH_TOKEN`;
export const REFRESH_TOKEN_SUCCESS = `${prefix}/REFRESH_TOKEN_SUCCESS`;
export const RESET_PASSWORD = `${prefix}/RESET_PASSWORD`;
export const RESET_PASSWORD_SUCCESS = `${prefix}/RESET_PASSWORD_SUCCESS`;
export const SAVE_USER = `${prefix}/SAVE_USER`;
export const GET_AUTH_USER = `${prefix}/GET_AUTH_USER`;
export const SAVE_AUTH_USER_DATA = `${prefix}/SAVE_AUTH_USER_DATA`;
export const CHANGE_REFRESH_TIME = `${prefix}/CHANGE_REFRESH_TIME`;
export const PUT_USER_NAME = `${prefix}/PUT_USER_NAME`;
export const SAVE_CURRENT_PROJECT = `${prefix}/SAVE_CURRENT_PROJECT`;
export const SAVE_USER_LAST_PROJECT = `${prefix}/SAVE_USER_LAST_PROJECT`;
export const GET_USER_SERVICES = `${prefix}/GET_USER_SERVICES`;
export const SAVE_USER_SERVICES = `${prefix}/SAVE_USER_SERVICES`;
export const CHANGE_PASSWORD = `${prefix}/CHANGE_PASSWORD`;
export const SET_PERMISSIONS = `${prefix}/SET_PERMISSIONS`;
export const LOGOUT = `${prefix}/LOGOUT`;

export const LOAD_MAP_LAYER_LIST = `${prefix}/LOAD_MAP_LAYER_LIST`;
export const LOADED_MAP_LAYER_LIST = `${prefix}/LOADED_MAP_LAYER_LIST`;
export const SET_MAP_LAYER = `${prefix}/SET_MAP_LAYER`;
export const CHANGED_MAP_LAYER = `${prefix}/CHANGED_MAP_LAYER`;

export const SAVE_USER_FILTERS = `${prefix}/SAVE_USER_FILTERS`;
export const DELETE_USER_FILTERS = `${prefix}/DELETE_USER_FILTERS`;
export const LOAD_USER_FILTERS = `${prefix}/LOAD_USER_FILTERS`;
export const LOADING_USER_FILTERS = `${prefix}/LOADING_USER_FILTERS`;
export const LOADED_USER_FILTERS = `${prefix}/LOADED_USER_FILTERS`;

export const LOAD_ACCESS_TEMPLATE = `${prefix}/LOAD_ACCESS_TEMPLATE`;

export const AUTH_BY_USER_ID = `${prefix}/AUTH_BY_USER_ID`;
export const SET_SUPER_USER = `${prefix}/SET_SUPER_USER`;
export const LOGOUT_CURRENT_USER = `${prefix}/LOGOUT_CURRENT_USER`;

export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;
