import FormButtonsComponent from '../../../../../../common/FormButtons';
import Paper from '@mui/material/Paper';

const AddressList = ({ list, onClick }) => {
    const createAddress = (item) => {
        return `${item.city_name || item.settlement || item.region || ''} ${item.street || ''} ${item.house || ''}`;
    };

    return (
        <Paper style={{ padding: 8, position: 'absolute', zIndex: 99, top: 40, width: '100%' }}>
            {list.map((item, index) => {
                const address = createAddress(item);
                return (
                    <div key={`${item.house}_${index}`}>
                        <FormButtonsComponent
                            justButton
                            buttons={[
                                {
                                    onClick: () => onClick(item, address),
                                    name: address
                                }
                            ]}
                        />
                    </div>
                );
            })}
        </Paper>
    );
};

export default AddressList;