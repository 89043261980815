import { useState } from 'react';
import { useDispatch } from 'react-redux';

import titles from 'helpers/constants/titles';
import { deletePopulation } from 'redux/Incidents/actions';
import { LSContentColumn, LSContentItem } from 'components/common/ListStyle';
import ConfirmModal from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';

const Item = ({ item, loadList }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return (
        <LSContentItem>
            <LSContentColumn title={titles.YEAR} width="100px">
                {item?.year ?? ''}
            </LSContentColumn>
            <LSContentColumn title={titles.POPULATION} width="200px">
                {item?.count ?? ''}
            </LSContentColumn>

            <LSContentColumn align="right" isActions>
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setOpenEditModal(true),
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setOpenDeleteModal(true),
                        },
                    ]}
                    noMarginLeft
                    justButton
                />
            </LSContentColumn>
            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    loadList={loadList}
                />
            )}
            {openDeleteModal && (
                <ConfirmModal
                    open={openDeleteModal}
                    onSuccess={() => dispatch(deletePopulation(item.id, () => loadList(true)))}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </LSContentItem>
    );
};

export default Item;
