import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { getCameraReport, loadCameraReportList } from '../../../../redux/DorisControl/actions';
import titles from '../../../../helpers/constants/titles';
import { REPORT_FORMATS } from '../../../../helpers/report.formats';
import GetReport from '../../../common/GetReport';
import { dorisControlSelectors } from '../../../../redux/DorisControl';

const useStyles = makeStyles({
    wrap: {
        maxWidth: 470,
        display: 'flex',
        alignItems: 'center',
        paddingTop: 20
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 20
    },
    title: {
        paddingTop: 5
    }
});

const CameraStatus = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const report_list = useSelector(dorisControlSelectors.reportList);

    useEffect( () => {
        dispatch(loadCameraReportList());
    }, [dispatch]);

    const formats = REPORT_FORMATS;

    const allComplex = report_list.length || 0;
    const onlineComplex = report_list.filter(({ status }) => status === 1).length || 0;
    const offlineComplex = report_list.filter(({ status }) => status === 2).length || 0;

    const handleDownloadReport = (format) => {
        dispatch(getCameraReport({ report: format }));
    };

    return (
        <>
            <h1>Отчет по статусу камер</h1>
            <GetReport formats={formats} handleDownload={handleDownloadReport}/>
            <div className={classes.list}>
                <span className={classes.title}><strong>{titles.ALL_COMPLEX}: </strong>{allComplex}</span>
                <span className={classes.title}><strong>{titles.ONLINE_COMPLEX}: </strong>{onlineComplex}</span>
                <span className={classes.title}><strong>{titles.OFFLINE_COMPLEX}: </strong>{offlineComplex}</span>
            </div>
        </>
    );
};

export default CameraStatus;
