import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormHelperText, IconButton, InputAdornment, TextField } from '@mui/material';
import Clear from '@mui/icons-material/Clear';

import { useValidation } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

function Search({
    loadParams = () => {},
    action,
    propName = 'name',
    isInform = false,
    resetAction,
    label='Название',
    setParams = () => {}
}) {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const searched = useRef(false);
    const validation = useValidation();

    const limit = Number(localStorage.getItem('limit') || 25);

    const handleChange = (e) => {
        setValue(e.target.value);
        if (searched.current && e.target.value === '') {
            resetSearch();
        }
        validation.clear();
    };

    const paramsHandler = (value) => loadParams(value);

    const search = () => {
        if (value) {
            searched.current = true;
            paramsHandler(value);
            action && dispatch(action(1, limit, { [propName] : value }));
            setParams({ page: 1, data: { [propName]: value } });
        }
        else {
            resetSearch();
        }
    };

    const resetSearch = () => {
        setValue('');
        paramsHandler();
        if (searched.current) {
            if(resetAction) {
                dispatch(resetAction());
            } else {
                action && dispatch(action(1, limit));
                setParams({ page: 1, data: {} });
            }
            searched.current = false;
        }
        validation.clear();
    };

    const endAdornment = (
        <InputAdornment position="end">
            <IconButton onClick={resetSearch} size="small">
                <Clear />
            </IconButton>
        </InputAdornment>
    );

    const handleEnter = (e) => {
        if (e.key === 'Enter') search();
    };

    return (<>
        <div className={'filter__wrap'} style={{ display: 'flex' }}>
            <TextField
                value={value}
                onChange={handleChange}
                label={label}
                variant="outlined"
                style={{ flex: 1 }}
                size="small"
                InputProps={value ? { endAdornment } : {}}
                inputProps={{ onKeyDown: handleEnter }}
                error={isInform && !!validation.isKey(propName)}
            />
            <div className="filter__wrap__btn">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.search,
                            onClick: search
                        }
                    ]}
                />
            </div>
        </div>
        {isInform
            && validation.isKey(propName)
                && <FormHelperText error>{validation.get(propName)}</FormHelperText>
        }

    </>
    );
}

export default Search;
