// генерируем маркер для кластера по цветам
// d - диаметр иконки
// colors - массив цветов
const circleColorIcon = (colors = {}, d = false) => {
    // начальные стили
    let style = '';
    if (d) {
        style += `
            width: ${d === false ? '100%' : `${d}px`};
            height: ${d === false ? '100%' : `${d}px`};
        `;
    }
    const colorsList = Object.keys(colors);

    if (colorsList.length === 1) {
        // цвет только 1 - просто закрашиваем
        style += `
            background: ${colorsList[0]};
        `;
    } else {
        // цветов больше 1
        // генерируем дополнительные разделения по цветам (когда > 2)
        const { colors: others } = colorsList
            .reduce((res, color) => {
                const newI = res.i + colors[color];
                return {
                    colors: [
                        ...res.colors,
                        `${color} ${res.i}% ${newI}%`
                    ],
                    i: newI
                };
            }
            , { colors: [], i: 0 });

        const gradient = others.join(',');

        // собираем стили
        style += `background: ${colorsList[0]} conic-gradient(${gradient});`;
    }

    // выводим новую иконку
    return `<div class="custom-cluster-marker" style="${style}" />`;
};

export default circleColorIcon;
