import { useState } from 'react';
import { Tooltip } from '@mui/material';

import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';
import { useStoreProp } from 'helpers/hooks';
import {
    loadOrderStatuses,
} from 'redux/RoadWorks/actions';
import { ActionMore, LSContentColumn, LSContentItem } from 'components/common/List';
import AddEditModal from 'components/pages/Dictionaries/RoadWorks/Orders/AddEditModal';
import styles from 'components/pages/Dictionaries/RoadWorks/Orders/orders.module.scss';
import CircleStatus from 'components/common/CircleStatus';

import StageOrders from './StageOrders';

const Item = ({ data = {}, companies }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [openStagesCollapse, setOpenStagesCollapse] = useState(false);

    const orderStatuses = useStoreProp(loadOrderStatuses, 'roadworks', 'orderStatuses');

    const itemStatus = orderStatuses.find((el) => Number(el.status_id) === Number(data.status)) || {};

    return (
        <>
            {modalOpen && (
                <AddEditModal
                    data={data}
                    onClose={setModalOpen}
                    isOpen={modalOpen}
                />
            )}
            <div className="list_item">
                <LSContentItem>
                    <LSContentColumn>
                        <CircleStatus title={itemStatus.name} color={itemStatus.color} />
                    </LSContentColumn>
                    <LSContentColumn>{data?.id || messages.NO_VALUE} </LSContentColumn>
                    <LSContentColumn>{data?.name || messages.NO_VALUE} </LSContentColumn>
                    <LSContentColumn>{data?.created_at ? fullDateTime(data?.created_at) : messages.NO_VALUE}</LSContentColumn>
                    <LSContentColumn>{data?.start_date_plan ? fullDateTime(data?.start_date_plan) : messages.NO_VALUE}</LSContentColumn>
                    <LSContentColumn>{data?.end_date_plan ? fullDateTime(data?.end_date_plan) : messages.NO_VALUE}</LSContentColumn>
                    <LSContentColumn>{data?.end_at ? fullDateTime(data?.end_at) : messages.NO_VALUE}</LSContentColumn>
                    <LSContentColumn>
                        {companies?.length > 0
                            ? (companies.map((el, i) => (
                                <div key={el?.id || i} className={styles.info_block__wrap_org}>
                                    <div className={styles.info_block__wrap_org_item}>
                                        <strong>{i + 1}. </strong>
                                        <span>{el?.title}</span>
                                    </div>
                                </div>
                            )))
                            : messages.NO_VALUE}
                    </LSContentColumn>
                    <LSContentColumn isActions>
                        <ActionMore
                            onClick={() => setOpenStagesCollapse(!openStagesCollapse)}
                            isOpen={openStagesCollapse}
                        />
                    </LSContentColumn>
                </LSContentItem>
                {openStagesCollapse && (
                    <StageOrders
                        isOpen={openStagesCollapse}
                        currentStages={data?.stages}
                    />
                )}
            </div>
        </>
    );
};

export default Item;
