import { useEffect, useRef, useState } from 'react';
import Filter from './Filter';
import { useDispatch } from 'react-redux';
import { getKDM, loadKDMReport } from '../../../../../redux/RoadWorks/actions';
import { dateWithDashYYYYMMDD } from '../../../../../helpers/date.config';
import useStoreProp from '../../../../../helpers/hooks/useStoreProp';
import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { useValidation } from '../../../../../helpers/hooks';
import buttons from '../../../../../helpers/constants/buttons';


const DepartureKDM = () => {
    const KDM_works = useStoreProp(getKDM,'roadworks','KDM_works');

    const dispatch = useDispatch();
    const validation = useValidation();
    
    const initState = {
        date: dateWithDashYYYYMMDD(new Date()),
        organization_ids: [],
        formats: [],
        works: [{
            disabled: true,
            group_id: 16,
            group_name: 'Погодные условия',
            unit_id: 8,
            unit_name: '°C',
            weather: true,
            work_id: 11,
            work_name: 'Погодные условия'
        }]
    };
    const initFilter = useRef(initState);

    const [params, setParams] = useState(initState);
    const [modalOpen, setModalOpen] = useState(false);
    const [isChanges, setIsChanges] = useState(false);

    const prepareData = (overwrite = false) => ({
        ...params, 
        overwrite,
        organization_ids: params?.organization_ids?.map(({ id }) => id),
        formats: params.formats.map(({ id }) => id),
        report: 1
    });

    const handleGetReport = () => {
        if (isChanges) {
            setModalOpen(true);
        } else {
            dispatch(loadKDMReport(prepareData()));
        }
    };

    const handleAddItem = () => {
        setParams({
            ...params,
            works: [
                ...params.works,
                {
                    disabled: false,
                    group_id: '',
                    group_name: '',
                    unit_id: '',
                    unit_name: '',
                    weather: false,
                    work_id: '',
                    work_name: '',
                }
            ]
        });
        setIsChanges(true);
    };

    const onClickDelete = (index) => {
        const newDataArray = [
            ...params.works.slice(0, index),
            ...params.works.slice(index + 1),
        ];
        setParams({
            ...params,
            works: newDataArray
        });
        setIsChanges(true);
    };

    const onChangeKdmList = (index, value) => {
        validation.clear();
        const newDataArray = [
            ...params.works.slice(0, index),
            value,
            ...params.works.slice(index + 1),
        ];
        setParams({
            ...params,
            works: newDataArray
        });
        setIsChanges(true);
    };

    const confirmModal = (overwrite) => {
        dispatch(loadKDMReport(prepareData(overwrite)));
        setModalOpen(false);
        setIsChanges(false);
    };

    useEffect(()=> {
        if(KDM_works.length > 0){
            setParams((prevParams)  => ({
                ...prevParams,
                works: KDM_works 
            }));

            initFilter.current = { ...initFilter.current,  works: KDM_works };
        }
    }, [KDM_works]);

    return (
        <>
            <Modal
                isOpen={modalOpen}
                onClose={confirmModal}
                noPadding={true}
                title={'Сохранить выбранные параметры для будущих отчетов?'}
                buttons={<FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            name: buttons.NO,
                            onClick: () => confirmModal(false)
                        },
                        {
                            ...buttonsTypes.save,
                            name: buttons.YES,
                            onClick: () => confirmModal(true)
                        },
                    ]}
                />}
            >
                <>
                </>
            </Modal>
            <div >
                <h1>Выезд КДМ</h1>
                <Filter
                    params={params}
                    setParams={setParams}
                    onChangeKdmList={onChangeKdmList}
                    handleAddItem={handleAddItem}
                    handleGetReport={handleGetReport}
                    onClickDelete={onClickDelete}
                    initFilter={initFilter}
                />
            </div>
        </>

    );
};

export default DepartureKDM;