import { TableCell, TextField } from '@mui/material';

import FormInfoWrapper from 'components/common/FormInfoWrapper';

const TableAmount = ({ el, onChange }) => {

    const helperMessage = (!Number(el?.amount_plan) && !Number(el?.amount)) ? 'Данные с нулевыми значениями не будут сохранены!' : '';

    return (
        <>
            <TableCell align="center" sx={{ width: 200 }}>
                <FormInfoWrapper helperText={helperMessage} btnStyle={{ alignSelf: 'center' }}>
                    <div>
                        <TextField
                            className="block"
                            label="план"
                            type="number"
                            size="small"
                            name="amount_plan"
                            value={el?.amount_plan}
                            onChange={onChange}
                            inputProps={{ min: 0 }}
                        />
                        <TextField
                            className="block"
                            label="факт"
                            type="number"
                            size="small"
                            name="amount"
                            value={el?.amount}
                            onChange={onChange}
                            inputProps={{ min: 0 }}
                        />
                    </div>
                    
                </FormInfoWrapper>
            </TableCell>
        </>
    );
};

export default TableAmount;