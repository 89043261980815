import { useCallback, useState } from 'react';
import SingleKeyboardDateTimePicker from '../../../../common/Dates/SingleKeyboardDateTimePicker';
import { getYear, getYearDiff } from '../../../../../helpers/date.config';
import titles from '../../../../../helpers/constants/titles';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';

const Filter = ({ initParams, setParams, params }) => {
    const [data, setData] = useState(initParams);
    
    const prepareData = useCallback((filter = data) => removeEmptyFields(filter), [data]);

    const handleSearch = () => {
        setParams((prev) => ({ ...prev, ...data }));
    };

    const handleResetFilter = (needRefresh) => {
        setData(initParams);

        if (needRefresh) {
            setParams(initParams);
        }
    };

    const updateFilter = (filters) => {
        setData({ ...initParams, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams(removeEmptyFields({
                ...initParams,
                ...prepareData(filters)
            }));
        }
    };

    const isDisabled = data.year === params.year;

    return (
        <LayoutFilter
            filter={data}
            onResetFilter={handleResetFilter}
            onSearch={handleSearch}
            setUserFilter={updateFilter}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <SingleKeyboardDateTimePicker
                    value={data.year ? String(data.year) : null}
                    onChange={(newDate) => {
                        setData({
                            ...data,
                            year: getYear(newDate),
                        });
                    }}
                    label={titles.YEAR}
                    dateOnly={true}
                    views={['year']}
                    pickerFormat="yyyy"
                    minDate={getYearDiff(10)}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
