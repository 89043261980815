import { useSelector, useDispatch } from 'react-redux';
import SelectPddViolation from '../../../../../../../common/Autocomplete/DorisControl/PddViolation';
import { dorisControlSelectors } from '../../../../../../../../redux/DorisControl';
import {
    loadPddViolations, 
} from '../../../../../../../../redux/DorisControl/actions';
import { useEffect } from 'react';

const RenderKoapSelect = ({ item = [], onChange, error = false, helperText = '', field }) => {
    const dispatch = useDispatch();
    const violations = useSelector(dorisControlSelectors.violationsById);

    const koaps = item.reduce((res, id) => {
        const violation = violations[id];
        if (violation) {
            res.push(violation);
        } else  {

        }
        return res;
    }, []);

    const handleChangeKoap = (value) => {
        onChange(value.map(({ id }) => id));
    };

    useEffect(() => {
        // загружаем все имеющиеся коап статьи чтобы отобразить их в форме редактирования
        if (item.length > 0){
            dispatch(loadPddViolations( 1, item.length, { ids: item }, true));
        }
    }, []);

    return (
        <SelectPddViolation
            multiple
            label={field.name}
            selected={koaps}
            onChange={handleChangeKoap}
            required={field.require}
            error={(koaps?.length === 0 && field.require) || error}
            helperText={helperText}
        />
    );
};

export default RenderKoapSelect;
