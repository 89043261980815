import React, { useEffect, useState } from 'react';
import SidebarItem from '../../../SidebarItem';
import SidebarFilter from '../../../SidebarFilter';
import { Scrollbars } from 'react-custom-scrollbars';
import * as actions from '../../../../../redux/Incidents/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../common/Loading';
import Icon from '../icons/icon';
import Item from './Item';
import { getColor, getFilter } from '../helper';
import buttons from '../../../../../helpers/constants/buttons';
import messages from '../../../../../helpers/constants/messages';
import FilterForm from './Filter';
import { isEqual } from 'lodash';
import { usePrevious } from '../../../../../helpers/hooks';
import titles from '../../../../../helpers/constants/titles';
import SidebarTitle from '../../../SidebarTitle';
import getFilters from '../../../helpers/getFilters';
import config from '../config';
import UploadFile from './UploadFile';
import { incidentsSelectors } from '../../../../../redux/Incidents';


const SideBar = (props) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const types = useSelector(incidentsSelectors.dtpTypesData);
    const list = useSelector(incidentsSelectors.list);
    const data = useSelector(incidentsSelectors.listData);
    const meta = useSelector(incidentsSelectors.listMeta);
    const loading = useSelector(incidentsSelectors.loading);
    const active = useSelector(incidentsSelectors.active);
    const saved = useSelector(incidentsSelectors.saved);
    const filters = useSelector(incidentsSelectors.filters);

    const prevFilters = usePrevious(filters);

    const activeId = Object.keys(active).length > 0 ? active.id : 0;
    //
    const fetchList = () => {
        const filter = getFilters(filters, getFilter);
        dispatch(actions.loadDtpList(
            page,
            limit,
            filter,
            page === 1
        ));
    };

    const handleScrollUpdate = (values) => {
        const {
            last_page = 0
        } = meta;
        if (
            values.top > 0.98
            && loading === false
            && page < last_page
        ) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            setPage(1);
        }
        fetchList();
    }, [page, limit, filters]);

    useEffect(() => {
        if (saved) {
            fetchList();
        }
    }, [saved]);

    const handleLoaded = () => {
        if (page > 1) {
            setPage(1);
        } else {
            fetchList();
        }
    };

    const renderList = (dataList) => (
        <>
            {dataList.map((item) => {
                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        icon={
                            Icon({
                                fill: getColor(item, types)
                            })
                        }
                        buttons={{
                            edit: {
                                title: buttons.EDIT,
                                icon: <i className="fas fa-pen" />,
                                onClick: () => {
                                    // редактирование
                                    dispatch(actions.setEditForm(item));
                                }
                            },
                            delete: {
                                title: buttons.DELETE,
                                icon: <i className="fas fa-trash-alt" />,
                                onClick: () => {
                                    // удаление
                                    dispatch(actions.setDeleteForm(item.id));
                                }
                            }
                        }}
                        onClick={() => {
                            dispatch(actions.setDtpActive(item));
                        }}
                    >
                        <Item {...item} />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={() => setPage(1)}
                onSetFilter={(filter) => dispatch(actions.setFilters(filter))}
                onClearFilter={() => dispatch(actions.clearFilters())}
                content={({ data, onChange }) => <FilterForm data={data} onChange={onChange} />}
                renderButtons={<UploadFile onLoaded={handleLoaded}/>}
                layer={config.slug}
            />

            {loading && data.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={config.name}
                            list={list}
                        />
                        {data.length === 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList(data)}
                    </div>
                    {loading && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
};

export default SideBar;
