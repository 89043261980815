import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { deleteRoadWorksGroup } from 'redux/RoadWorks/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import messages from 'helpers/constants/messages';

import RWModal from './AddEditModal';

const Item = ({ item, reloadList, permissions }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
            {openEditModal && (
                <RWModal
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}
            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onSuccess={() => dispatch(deleteRoadWorksGroup(item.id, () => {
                        reloadList(true);
                        setOpenDeleteModal(false);
                    }))}
                />
            )}
        </>
    );
};

export default Item;
