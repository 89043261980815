import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';

import messages from '../../../../../helpers/constants/messages';
import Context from '../../../../../helpers/context';
import { focusIncidentsSelectors } from '../../../../../redux/FocusIncidents';
import { createDtpFocus, getDtpFocus } from '../../../../../redux/FocusIncidents/actions';
import ModalForm from '../../../../MapComponents/Layers/DtpFocus/ModalForm';
import CustomPagination from '../../../../common/CustomPagination';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import PageLayout from '../../../../layout/PageLayout';

import Item from './Item';

function DtpFocus(props) {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const dtpFocusLoading = useSelector(focusIncidentsSelectors.dtpFocusLoading);
    const dtpFocusList = useSelector(focusIncidentsSelectors.dtpFocusList);
    const editForm = useSelector(focusIncidentsSelectors.editForm);
    
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;

    const [limit, setLimit] = useState(storageLimit);
    const [openModal, setOpenModal] = useState(false);

    const toggleOpenModal = () => setOpenModal(!openModal);

    useEffect(() => {
        dispatch(getDtpFocus(1, limit));
    }, [dispatch]);

    const renderContent = () => {
        return (dtpFocusList?.data?.length > 0
            ? (<>
                <List className="list">
                    {dtpFocusList.data.map((item) => (
                        <Item key={item.id} data={item} />
                    ))}
                </List>
                <CustomPagination
                    loadList={(page, limit) => dispatch(getDtpFocus(page, limit))}
                    list={dtpFocusList.meta}
                    limit={limit} 
                    setLimit={setLimit}
                />
            </>)
            : (!dtpFocusLoading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (<div>
        <PageLayout
            header="Отчет по очагам ДТП"
            informPanelProps={{
                buttons: (
                    <FormButtonsComponent
                        positionLeft
                        noPadding
                        buttons={[
                            {
                                ...buttonsTypes.add,
                                onClick: toggleOpenModal,
                                disabled: !permissions?.is_create,
                            }
                        ]}
                    />
                ),
                total: dtpFocusList.meta?.total
            }}
            loading={dtpFocusLoading}
            content={renderContent}
            paginationProps={{
                loadList: (page, limit) => dispatch(getDtpFocus(page, limit)),
                list: dtpFocusList.meta,
                limit: limit,
                setLimit: setLimit,
            }}
        />
        {openModal
            && <ModalForm
                data={editForm}
                onAccept={(newFields) => {
                    dispatch(createDtpFocus(newFields));
                }}
                open={openModal}
                onClose = {toggleOpenModal}
            />
        }
    </div>);
}

export default DtpFocus;