import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, ListItem, ListItemText, } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import messages from 'helpers/constants/messages';
import { deleteResponseScenario } from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';
import Info from './Info';

const Item = ({ item, reloadList }) => {
    const dispatch = useDispatch();

    const loadingButton = useSelector(SituationalPlansSelectors.loadingButton);

    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    const itemClickHandle = () => {
        setShowInfo(!showInfo);
    };

    const handleDelete = () => {
        dispatch(deleteResponseScenario(item?.id, () => {
            reloadList(true);
            setIsOpenDeleteModal(false);
        }));
    };

    return (
        <div className="list_item">
            <ListItem>
                <ListItemText onClick={itemClickHandle}>
                    <div>
                        {item?.name}&nbsp;
                        {!item?.is_typical
                            ? <span style={{ color: '#FF0000' }}>Нетиповой сценарий</span>
                            : <span style={{ color: '#008000' }}>типовой сценарий</span>}
                    </div>
                </ListItemText>
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setIsOpenEditModal(true),
                        },
                        {
                            ...buttonsTypes.copyIcon,
                            onClick: () => setIsOpenCopyModal(true),
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setIsOpenDeleteModal(true),
                        },
                    ]}
                    noMarginLeft
                    justButton
                />

                <IconButton size={'small'} onClick={itemClickHandle}>
                    {showInfo ? (
                        <ExpandLess fontSize={'inherit'}/>
                    ) : (
                        <ExpandMore fontSize={'inherit'}/>
                    )}
                </IconButton>
            </ListItem>

            {showInfo && <Info isOpen={showInfo} item={item}/>}

            {isOpenEditModal && (
                <ModalForm
                    isOpen={isOpenEditModal}
                    onClose={() => setIsOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}

            {isOpenCopyModal && (
                <ModalForm
                    isOpen={isOpenCopyModal}
                    onClose={() => setIsOpenCopyModal(false)}
                    isCopy={true}
                    item={item}
                    reloadList={reloadList}
                />
            )}

            {isOpenDeleteModal && (
                <ConfirmDelete
                    open={isOpenDeleteModal}
                    onClose={() => setIsOpenDeleteModal(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                    loadingButton={loadingButton}
                />
            )}
        </div>
    );
};

export default Item;
