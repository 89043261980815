import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';

import {
    clearStandardizedCompaniesList,
    getStandardizedCompaniesList,
    gotStandardizedCompaniesList
} from 'redux/Companies/actions';
import { companiesSelectors } from 'redux/Companies';
import messages from 'helpers/constants/messages';
import buttons from 'helpers/constants/buttons';
import titles from 'helpers/constants/titles';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Search from 'components/common/Search';

import StandardizedCompanyForm from './StandardizedCompanyForm';
import StandardizedCompanyItem from './StandardizedCompanyItem';

const ModalForm = ({ open, setOpenModal, closeModalForm, reloadList }) => {
    const dispatch = useDispatch();

    const standardizedCompanies = useSelector(companiesSelectors.standardizedCompanies);
    const loadingCompanies = useSelector(companiesSelectors.loadingCompanies);

    const closeForm = () => {
        setOpenModal(false);
        dispatch(gotStandardizedCompaniesList([]));
    };

    const [openForm, setOpenForm] = useState(false);
    const [params, setParams] = useState('');

    return (
        <Modal
            isOpen={open}
            onClose={closeForm}
            noPadding
            title={titles.COMPANY_SEARCH}
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: closeForm
                        },
                    ]}
                />
            }
        >
            <div className="modal__form">
                <Search
                    action={getStandardizedCompaniesList}
                    resetAction={clearStandardizedCompaniesList}
                    loadParams={(params) => setParams(params)}
                    propName="query"
                    isInform={true}
                    label={titles.SEARCH_BY_TITLE_OR_INN}
                />
                {standardizedCompanies?.data?.length > 0
                    ? <List className="list">
                        {standardizedCompanies?.data?.map((item) => 
                            <StandardizedCompanyItem
                                key={item.inn}
                                item={item}
                                isOpenModalForm={(status) => closeModalForm(status)}
                                reloadList={reloadList}
                            />
                        )}
                    </List>
                    : params !== '' && !loadingCompanies
                        && <div>
                            {messages.DATA_IS_NOT_FOUND}
                            {standardizedCompanies?.data?.length === 0
                                && <FormButtonsComponent
                                    noPadding
                                    noMarginLeft
                                    positionLeft
                                    buttons={[
                                        {
                                            type: 'submit',
                                            onClick: () => setOpenForm(true),
                                            name: buttons.ADD
                                        }
                                    ]}
                                />
                            }
                        </div>
                }
            </div>
            {openForm
                && <StandardizedCompanyForm
                    isNew={true}
                    open={openForm}
                    setOpenModal={setOpenForm}
                    reloadList={reloadList}
                />
            }
        </Modal>
    );
};

export default ModalForm;