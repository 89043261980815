import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { REPORT_FORMATS_SMALL } from '../../../../../helpers/report.formats';
import formatDate from '../../../../../helpers/constants/dateFormat';
import { dateWithDashYYYYMMDD } from '../../../../../helpers/date.config';
import { loadMonthlyReport  } from '../../../../../redux/DorisControl/actions';
import DateRange from '../../../../common/Dates/DateRange';
import differenceInDays from 'date-fns/differenceInDays';
import { FormHelperText } from '@mui/material';
import useValidation from '../../../../../helpers/hooks/useValidation';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import SelectComplex from '../../../../common/Autocomplete/DorisControl/Complex';


const Filter = () => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        time_start: null,
        time_end: null,
        organization_ids: [],
        complex_ids: [],
        report: REPORT_FORMATS_SMALL.XLS
    };

    const [filters, setFilters] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [complexes, setComplexes] = useState([]);

    const handleChangeDate = (name, value) => {
        setFilters({ ...filters, [name]: value });
        validation.deleteKey(name);
    };

    const download = () => {
        const newData = Object.keys(filters).reduce((res, key) => {
            if (key === 'time_start' || key === 'time_end') {
                res[key] = dateWithDashYYYYMMDD(filters[key]);
            } else {
                res[key] = filters[key];
            }
            return res;
        }, {});
        newData.organization_ids = companies.map(el => el.id);
        newData.complex_ids = complexes.map(el => el.id);
        dispatch(loadMonthlyReport({ ...newData }));
    };

    const checkInvalidPeriod = () => {
        return differenceInDays(filters.time_end, filters.time_start) > 31;
    };

    const disabledButton = checkInvalidPeriod()
        || isDisabled
        || !filters.time_start
        || !filters.time_end;

    const resetFilter = () => {
        setFilters(initialState);
        setCompanies([]);
        setComplexes([]);
    };

    return (
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">
                <DateRange
                    valueStartDate={filters.time_start}
                    valueEndDate={filters.time_end}
                    handleDate={(date) => handleChangeDate('time_start', date)}
                    handleEndDate={(date) => handleChangeDate('time_end', date)}
                    isDisabledHandler={setIsDisabled}
                    pickersFormat={formatDate.DATE_FOR_PICKER}
                    renderError={checkInvalidPeriod}
                    dateOnly={true}
                />
            </div>
            <div className="filter__wrap filter__wrap__more__field">
                <SelectCompany
                    multiple
                    selected={companies}
                    onChange={setCompanies}
                    label="Балансодержатель"
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                />
                <SelectComplex
                    selected={complexes}
                    onChange={setComplexes}
                    multiple={true}
                />
            </div>
            {checkInvalidPeriod() && <FormHelperText error>Вы выбрали период больше одного месяца</FormHelperText> }
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    noPadding={true}
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilter
                        },
                        {
                            ...buttonsTypes.getReport,
                            onClick: download,
                            disabled: disabledButton
                        }
                    ]}
                />
            </div>

        </div>
    );
};

export default Filter;
