import reducer from './reducers';

// модуль
export { default as moduleName } from './module';

// сага
export { default as saga } from './sagas';

export * as notificationsSelectors from './selectors';

export default reducer;
