import { config } from '../../config';

const apiTransportSpecialUrl = () => config.get('urls').apiTransportSpecialUrl;
// const apiTransportRoad = () => config.get('urls').apiTransportRoadUrl;

const apiUrls = {

    getSidebar: () => `${apiTransportSpecialUrl()}/vehicle`,

    getVehicles: () => `${apiTransportSpecialUrl()}/vehicle`,

    getVehicleCategories: () => `${apiTransportSpecialUrl()}/vehicle/categories`,

    createCategory: () => `${apiTransportSpecialUrl()}/vehicle_types`,

    editCategory: (id) => `${apiTransportSpecialUrl()}/vehicle_types/${id}`,

    deleteCategory: (id) => `${apiTransportSpecialUrl()}/vehicle_types/${id}`,

    getVehicleCharacteristics: () => `${apiTransportSpecialUrl()}/vehicle/characteristics`,

    getBrands: () => `${apiTransportSpecialUrl()}/brand`,

    getModels: () => `${apiTransportSpecialUrl()}/model`,

    getRadioTypes: () => `${apiTransportSpecialUrl()}/radio_type`,

    createVehicle: () => `${apiTransportSpecialUrl()}/vehicle`,

    editVehicle: (id) => `${apiTransportSpecialUrl()}/vehicle/${id}`,

    deleteVehicle: (id) => `${apiTransportSpecialUrl()}/vehicle/${id}`,

    createBrand: () => `${apiTransportSpecialUrl()}/brand`,

    editBrand: (id) => `${apiTransportSpecialUrl()}/brand/${id}`,

    deleteBrand: (id) => `${apiTransportSpecialUrl()}/brand/${id}`,

    createModel: () => `${apiTransportSpecialUrl()}/model`,

    editModel: (id) => `${apiTransportSpecialUrl()}/model/${id}`,

    deleteModel: (id) => `${apiTransportSpecialUrl()}/model/${id}`,

    // getCurrentSpecialReport: (id) => `${apiTransportSpecialUrl()}/vehicle/history/${id}`,

    getVehicleCard: (id) => `${apiTransportSpecialUrl()}/vehicle/${id}/card`,

    getCoords: () => `${apiTransportSpecialUrl()}/vehicle/coords`,

    getTypes: () => `${apiTransportSpecialUrl()}/vehicle/types`,

    getListTS: () => `${apiTransportSpecialUrl()}/vehicle`,

    getVehicleReport: () => `${apiTransportSpecialUrl()}/vehicle/report`,

    getHistory: (vehicleId) => `${apiTransportSpecialUrl()}/vehicle/history/${vehicleId}`,

    /* color speed */
    getColorSpeedList: () => `${apiTransportSpecialUrl()}/color-speed`,

    addColorSpeed: () => `${apiTransportSpecialUrl()}/color-speed`,

    editColorSpeed: (id) => `${apiTransportSpecialUrl()}/color-speed/${id}`,

    deleteColorSpeed: (id) => `${apiTransportSpecialUrl()}/color-speed/${id}`,

    getCategories: () => `${apiTransportSpecialUrl()}/vehicle/categories`,

    getTelemetryReceivers: () => `${apiTransportSpecialUrl()}/egts-receiver`,

    addTelemetryReceiver: () => `${apiTransportSpecialUrl()}/egts-receiver`,

    editTelemetryReceiver: (id) => `${apiTransportSpecialUrl()}/egts-receiver/${id}`,

    deleteTelemetryReceiver: (id) => `${apiTransportSpecialUrl()}/egts-receiver/${id}`,

    getTelemetryReceiverTypes: () => `${apiTransportSpecialUrl()}//egts-receiver/types`,

    getVehicleCategoryList: () => `${apiTransportSpecialUrl()}/vehicle/categories`,

    getReportByVehicles: () => `${apiTransportSpecialUrl()}/report/vehicles`,

    loadEgtsTransmitters: () => `${apiTransportSpecialUrl()}/egts-transmitter`,

    addEgtsTransmitter: () => `${apiTransportSpecialUrl()}/egts-transmitter`,

    editEgtsTransmitter: (id) => `${apiTransportSpecialUrl()}/egts-transmitter/${id}`,

    deleteEgtsTransmitter: (id) => `${apiTransportSpecialUrl()}/egts-transmitter/${id}`,
};

export default apiUrls;
