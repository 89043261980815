import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { roadworksActions, roadworksSelectors } from 'redux/RoadWorks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';

import AddEditModal from './AddEditModal';
import Filter from './Filter';
import Item from './Item';

import type { Meta } from 'types/pagination';
import type {  Work } from 'types/RoadWorks';

const Works = () => {
    const dispatch = useDispatch();

    const listData: Work[] = useSelector(roadworksSelectors.worksData);
    const listMeta: Meta = useSelector(roadworksSelectors.worksMeta);
    const loading: boolean = useSelector(roadworksSelectors.worksLoading);

    const [params, setParams] = useState({ page: 1 });
    const [openAddModal, setOpenAddModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const reloadList = useCallback(() => {
        dispatch(roadworksActions.loadWorks(removeEmptyFields({ ...params, limit })));
    }, [dispatch, params, limit]);

    useEffect(reloadList, [reloadList]);

    useEffect(() => { // preload for data that using in children components by useSelector
        dispatch(roadworksActions.loadRoadWorksGroups());
    }, [dispatch]);

    const renderContent = () => {
        return listData.length > 0
            ? (
                <LSContainer
                    headers={[
                        { title: titles.NAME, width: '25%' },
                        { title: 'Единицы измерения', width: '25%' },
                        { title: 'Группа дорожных работ', width: '25%' },
                        { title: 'Действия', align: 'right', isActions: true }
                    ]}
                >
                    {listData.map((item) => (
                        <Item
                            key={item.id}
                            item={item}
                            reloadList={reloadList}
                        />
                    ))}
                </LSContainer>
            )
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>);
    };

    return (
        <>
            <PageLayout
                header="Дорожные работы"
                filters={<Filter setParams={setParams} />}
                loading={loading}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenAddModal(true),
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: listMeta.total
                }}
                content={renderContent}
                paginationProps={{
                    list: listMeta,
                    limit: limit,
                    loadList: (page) => setParams({ ...params, page }),
                    setLimit
                }}
            />
            {openAddModal && (
                <AddEditModal
                    onClose={() => setOpenAddModal(false)}
                    reloadList={() => setParams({ ...params, page: 1 })}
                    isNew={true}
                />
            )}
        </>
    );
};

export default Works;
