import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Modal from '../../../../common/Modal';
import { useValidation } from '../../../../../helpers/hooks';
import { createTariff, editTariff } from '../../../../../redux/TransportPassenger/actions';
import handleLabelTypes from '../../../../../helpers/traffic.titles';
import { FormControl, TextField } from '@mui/material';
import SelectRoutes from '../../../../common/Autocomplete/PassengerTransport/Routes';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';

const TModal = ({
    el = {},
    isNew,
    onClose,
    isOpen,
    typeQuery
}) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const initialState = {
        route: Object.keys(el).length === 0 ? {} : { name: el.name_route },
        name_route: el?.name_route || '',
        num_route: el?.num_route || '',
        type_transportation: el?.type_transportation || typeQuery.type_transportation,
        tariff_passenger: el?.tariff_passenger || '',
        tariff_baggage: el?.tariff_baggage || ''
    };

    const [data, setData] = useState(initialState);

    const onChange = (e) => {
        const { name, value } = e.target;
        if (e.key === '-' || e.key === 'e') e.preventDefault();
        setData({
            ...data,
            [name]: value
        });
    };

    const handleChangeRoute = (route) => {
        setData({
            ...data,
            route,
            name_route: route.name,
            num_route: route.num,
            type_transportation: route.type_transportation
        });
    };

    const handleSendTariff = () => {
        const prepareData = {
            ...data,
            route: null,
            tariff_passenger: parseFloat(data.tariff_passenger),
            tariff_baggage: parseFloat(data.tariff_baggage)
        };

        const paramsWithoutEmpty = removeEmptyFields(prepareData);

        if (isNew) {
            dispatch(createTariff(paramsWithoutEmpty, typeQuery));
        } else {
            dispatch(editTariff(el.id, paramsWithoutEmpty, typeQuery));
        }
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
        }
    }, [validation, onClose, requestStatus]);

    const isDisabled = !data.name_route
        || !data.num_route
        || !data.type_transportation
        || !data.tariff_passenger
        || !data.tariff_baggage;

    return (
        <Modal
            title={isNew ? 'Добавить тариф' : 'Редактировать тариф'}
            onClose={onClose}
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...(isNew ? buttonsTypes.create : buttonsTypes.save),
                        onClick: handleSendTariff,
                        disabled: isDisabled
                    },
                ]}
            />}
        >
            <div className="modal__form">
                <div className="block">
                    <SelectRoutes
                        selected={data.route}
                        onChange={handleChangeRoute}
                        filter={{ type_transportation: typeQuery.type_transportation }}
                    />
                </div>
                <div className="row">
                    <FormControl
                        className="row__item"
                        size="small"
                        variant="outlined"
                    >
                        <TextField
                            label={'Пассажиров - руб за ' + handleLabelTypes(data.type_transportation).passenger}
                            variant="outlined"
                            size="small"
                            value={data.tariff_passenger || ''}
                            name="tariff_passenger"
                            onChange={onChange}
                            type="number"
                            onKeyDown={onChange}
                            helperText={validation.get('tariff_passenger')}
                            error={validation.isKey('tariff_passenger')}
                            InputProps={{ inputProps: { step: '0.01', min: 0, pattern: '[0-9]*' } }}
                        />
                    </FormControl>
                    <FormControl
                        className="row__item"
                        size="small"
                        variant="outlined"
                    >
                        <TextField
                            label={'Багажа, руб за ' + handleLabelTypes(data.type_transportation)?.baggage}
                            variant="outlined"
                            size="small"
                            value={data.tariff_baggage || ''}
                            name="tariff_baggage"
                            onChange={onChange}
                            type="number"
                            onKeyDown={onChange}
                            helperText={validation.get('tariff_baggage')}
                            InputProps={{ inputProps: { step: '0.01', min: 0, pattern: '[0-9]*' } }}
                            error={validation.isKey('tariff_baggage')}
                        />
                    </FormControl>
                </div>
            </div>
        </Modal>
    );
};

export default TModal;