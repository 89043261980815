import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import colorExtend from 'helpers/mapHelper/colorExtend';


const Item = (props) => {
    const {
        name,
        group,
        type,
        status,
        status_text,
        organization,
        statuses,
    } = props;

    const statusObject = statuses?.find(({ status_id }) => status_id === status) || null;

    return (
        <>
            <div className="description">
                {name}
            </div>
            <div className="date">
                <div>
                    <b>{titles.STATUS}: </b>

                    {statusObject ? (
                        <span style={{ color: colorExtend(statusObject.color) }}>{statusObject.name}</span>
                    ) : (
                        <span>{status_text}</span>
                    )}
                </div>

                {organization && (
                    <div>
                        <b>{titles.ORGANIZATION}: </b>{organization.title}
                    </div>
                )}

                <div>
                    <b>Группа: </b>{group ? group?.name : messages.NO_DATA}
                </div>
                <div>
                    <b>Тип: </b>{type ? type?.name : messages.NO_DATA}
                </div>
            </div>
        </>
    );
};

export default Item;
