import { TextField } from '@mui/material';
import React from 'react';
import { useValidation } from '../../../../../../../helpers/hooks';

const Situation = ({ fields, onChange }) => {

    const validation = useValidation();

    const handleEvent = (key) => (newValue) => {
        const { target: { value } } = newValue;

        if (newValue.target.type !== 'number' || Number(value) >= 0) {
            onChange({
                [key]: value
            });
        }

    };

    return (
        <div className="modal__form">
            <div className="block">
                <TextField
                    size="small"
                    label="Дорога"
                    variant="outlined"
                    value={fields.road || ''}
                    onChange={handleEvent('road')}
                    error={validation.isKey('road')}
                    helperText={validation.get('road')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label="Значение дороги"
                    variant="outlined"
                    value={fields.road_value || ''}
                    onChange={handleEvent('road_value')}
                    error={validation.isKey('road_value')}
                    helperText={validation.get('road_value')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label="Категория дороги"
                    variant="outlined"
                    value={fields.road_category || ''}
                    onChange={handleEvent('road_category')}
                    error={validation.isKey('road_category')}
                    helperText={validation.get('road_category')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label="Состояние проезжей части"
                    variant="outlined"
                    value={fields.road_condition || ''}
                    onChange={handleEvent('road_condition')}
                    error={validation.isKey('road_condition')}
                    helperText={validation.get('road_condition')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label="Категория улицы"
                    variant="outlined"
                    value={fields.street_category || ''}
                    onChange={handleEvent('street_category')}
                    error={validation.isKey('street_category')}
                    helperText={validation.get('street_category')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label="Погода"
                    variant="outlined"
                    value={fields.weather || ''}
                    onChange={handleEvent('weather')}
                    error={validation.isKey('weather')}
                    helperText={validation.get('weather')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label="Освещение"
                    variant="outlined"
                    value={fields.lighting || ''}
                    onChange={handleEvent('lighting')}
                    error={validation.isKey('lighting')}
                    helperText={validation.get('lighting')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label="Изменения в режиме движения"
                    variant="outlined"
                    value={fields.changes_driving || ''}
                    onChange={handleEvent('changes_driving')}
                    error={validation.isKey('changes_driving')}
                    helperText={validation.get('changes_driving')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label="Объекты УДС на месте (светофор на перекрестке)"
                    variant="outlined"
                    value={fields.device_traffic_light_crossroads || ''}
                    onChange={handleEvent('device_traffic_light_crossroads')}
                    error={validation.isKey('device_traffic_light_crossroads')}
                    helperText={validation.get('device_traffic_light_crossroads')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label="Объекты УДС на месте (светофор на пешеходном переходе)"
                    variant="outlined"
                    value={fields.device_traffic_light_crosswalk || ''}
                    onChange={handleEvent('device_traffic_light_crosswalk')}
                    error={validation.isKey('device_traffic_light_crosswalk')}
                    helperText={validation.get('device_traffic_light_crosswalk')}
                />
            </div>
        </div>
    );    
};

export default Situation;
