import { config } from '../../config';

const apiAuthUrl = () => config.getGeneral('urls').apiAuthUrl;
const apiMain = () => config.getGeneral('urls')?.apiMain;

const apiUrls = {
    getToken: () => `${apiAuthUrl()}/login`,

    refreshToken: () => `${apiAuthUrl()}/token/refresh`,

    getTokenByUser: (id) => `${apiAuthUrl()}/user/${id}/token`,

    resetPassword: () => `${apiAuthUrl()}/user/reset-password`,

    getAuthUser: () => `${apiAuthUrl()}/user/profile`,

    putUserName: (id) => `${apiAuthUrl()}/user/${id}`,

    changeUserLast: () => `${apiAuthUrl()}/user/service/last`,

    getUserServices: () => `${apiMain()}/services/available`,

    changePassword: () => `${apiAuthUrl()}/user/change-password`,

    logout: () => `${apiAuthUrl()}/user/logout`,

    deleteAllTokens: () => `${apiAuthUrl()}/user/token/all`,

    getMapLayers: () => `${apiAuthUrl()}/user/map_layers`,

    setMapLayer: () => `${apiAuthUrl()}/user/map_layer`,

    saveUserFilters: () =>  `${apiMain()}/filter`,

    loadUserFilters: () =>  `${apiMain()}/filter`,

    deleteUserFilters: () =>  `${apiMain()}/filter`,

    // getAccessTemplate: () =>  `${apiMain()}/access_template`,
    // getAccessTemplate: () =>  `${apiMain()}/v2/access-template`,
    getAccessTemplate: () =>  `${apiMain()}/v3/access-template`,
};

export default apiUrls;
