import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import VehiclePopup from './VehiclePopup';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import ConfirmDelete from '../../../../common/ConfirmModal';
import messages from '../../../../../helpers/constants/messages';
import { useDispatch } from 'react-redux';
import { editVehicleToRoute } from '../../../../../redux/TransportPassenger/actions';
import styles from '../Routes/routes.module.scss';

const useStyles = makeStyles({
    item: {
        paddingRight: 45,
        display: 'flex',
        alignItems: 'center',
    },
    number: {
        maxWidth: 120,
        width: '100%',
        marginRight: 10,
        cursor: 'pointer',
        padding: '7px 0',
    }
});

const CurrentRouteVehicle = ({ id, vehicle, handleShowInfo, openWindow, anchorEl, itemId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleDeleteCurrentVehicle = (id) => {
        dispatch(editVehicleToRoute(id, { ...vehicle, characteristics: { ...vehicle.characteristics, route: null } }, itemId));
        setDeleteModalOpen(false);
    };

    return (
        <div className={classes.item} aria-describedby={id} >
            <span className={classes.number}>{vehicle.number}</span>
            <IconButton onClick={handleShowInfo} size="small" className={styles.icons}>
                <i className="fal fa-info-circle"/>
            </IconButton>
            <IconButton onClick={() => setDeleteModalOpen(true)} size="small">
                <CloseIcon fontSize="inherit"/>
            </IconButton>
            <VehiclePopup
                info={vehicle}
                id={id}
                openWindow={openWindow}
                anchorEl={anchorEl}
            />
            {deleteModalOpen && (
                <ConfirmDelete
                    open={deleteModalOpen}
                    message={messages.CONFIRM_DELETE}
                    onClose={() => setDeleteModalOpen(false)}
                    onSuccess={() => handleDeleteCurrentVehicle(vehicle.uuid)}
                />
            )}
        </div>
    );
};

export default CurrentRouteVehicle;
