export const renderArticleNumbers = ({ article, section, part }) => (
    <>
        <span>{article}</span>
        {section && <span>.{section}</span>}
        {part && <span>.{part}</span>}
    </>
);

export const renderArticleName = (data) => (
    <div>
        {renderArticleNumbers(data)}
        {data?.violation && <span>&nbsp;- {data?.violation}</span>}
    </div>
);
