import { useDispatch } from 'react-redux';
import { Collapse } from '@mui/material';

import { fromDateToFormat } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import useStoreProp from 'helpers/hooks/useStoreProp';
import colorStatus from 'helpers/workorder.config';
import { loadVehicleCategories, loadWorkorderEvents } from 'redux/TransportPassenger/actions';
import { ActionMore, LSContentColumn, LSContentItem } from 'components/common/List';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import CircleStatus from 'components/common/CircleStatus';

import WorkorderEvents from './Events';

const Item = ({
    data,
    selectedId,
    setSelect,
    work_order_event_id
}) => {
    const dispatch = useDispatch();

    const categories = useStoreProp(loadVehicleCategories, 'transportPassenger', 'vehicleCategories');
    const category = categories.find(el => el.id === data?.vehicle?.category_id);

    const refreshScreen = (e) => {
        e.stopPropagation();
        dispatch(loadWorkorderEvents(data?.id));
    };

    return (
        <>
            <LSContentItem onClick={() => setSelect(data)}>
                <LSContentColumn>
                    <CircleStatus title={data?.status_text} color={colorStatus(data?.status)} />
                </LSContentColumn>

                <LSContentColumn>
                    {data?.id || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {data?.date_at ? fromDateToFormat(data?.date_at, 'dd.MM.yyyy') : messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {data?.traffic_schedule?.diagram || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {data?.traffic_schedule?.route_direct?.num || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {category?.name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {data?.vehicle?.number || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {data?.organization?.title || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <FormButtons
                        justButton
                        buttons={[
                            {
                                ...buttonsTypes.defaultPrimary,
                                name: 'Обновить',
                                icon: <i className="fas fa-redo-alt" />,
                                onClick: refreshScreen
                            }
                        ]}
                    />

                    <ActionMore isOpen={selectedId === data?.id}/>
                </LSContentColumn>
            </LSContentItem>

            {selectedId === data?.id && (
                <div style={{ padding: 20 }}>
                    <Collapse in={selectedId === data?.id} timeout="auto" unmountOnExit>
                        <WorkorderEvents
                            workOrder={data}
                            workOrderId={data?.id}
                            work_order_event_id={work_order_event_id}
                        />
                    </Collapse>
                </div>
            )}
        </>
    );
};

export default Item;
