import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import ConfirmDelete from 'components/common/ConfirmModal';
import DragAndDrop from 'components/common/DragAndDrop';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';

import Item from './Item';
import SortableItem from './SortableItem';
import IntervalMileageModal from '../IntervalMileageModal';
import { options, serviceList } from '../helper';

export const useStyles = makeStyles({
    item_text: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 0,
        paddingLeft: 0,
    },
    item: {
        textAlign: 'left',
        marginLeft: '1rem',
        flex: 1,
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '1rem'
    }
});

const CheckPointList = ({
    items,
    setItems,
    draggable = true,
    canEdit = true,
    canRemove = true,
    service = serviceList.transportPassenger,
    displayType = options.basicData,
}) => {
    const styles = useStyles();

    const [deletedCheckpoint, setDeletedCheckpoint] = useState(null);
    const [editedCheckpoint, setEditedCheckpoint] = useState(null);

    const listWithoutId = items.filter((el) => !el.id);

    const handleReorder = (list) => {
        const newData = list.map((item, index) => {
            const current = listWithoutId.find((el) => el.number === item.number);

            const { id, ...newItem } = item;

            return ({
                ...newItem,
                ...(!current && { id }),
                number: index + 1,
                ...(service === serviceList.transportPassenger
                    ? {
                        mileage: index === 0 ? 0 : newItem.mileage,
                        interval: index === 0 ? '00:00' : newItem.interval
                    }
                    : {}
                )
            });
        });

        setItems(newData);
    };

    let maxId = items.reduce((res, it) => {
        if (it.id > res) {
            return it.id;
        }
        return res;
    }, 0);

    const createNewMaxId = () => {
        maxId++;
        return maxId;
    };

    const checkpointsWithIds = items.map((item) => {
        return ({
            ...item,
            ...(!item.id
                ? { id: item.station?.id || item.station_id  || item.check_point_id || item.check_point?.id || createNewMaxId() }
                : { id: item.id }
            ),
        });
    });

    const deleteCheckPoint = (number) => {
        const newData = items.filter(item => item.number !== number);
        handleReorder(newData);
    };

    const changeCheckPoint = (value) => {
        const newData = items.map((item) => {
            if (item.number=== value.number) {
                return value;
            }
            return item;
        });
        handleReorder(newData);
    };

    const listRender = (item, index) => {
        return (
            <SortableItem
                onDeleteItem={() => setDeletedCheckpoint(item)}
                onChangeItem={() => setEditedCheckpoint(item)}
                item={item}
                canEdit={canEdit && index !== 0}
                canRemove={canRemove}
                service={service}
                displayType={displayType}
            />
        );
    };

    const listHeader = (
        <ListItem className={styles.item_text} divider>
            {draggable && <ListItemIcon style={{ minWidth: 38 }}/>}
            <ListItemText disableTypography className={styles.row}>
                <span style={{ width: 16 }}>{titles.NUM}</span>
                <span className={styles.item}>{titles.NAME}</span>
                {service === serviceList.transportPassenger && (
                    <>
                        {displayType === options.basicData && (
                            <>
                                <span className={styles.item}>{titles.MILEAGE_FROM_STATION} (км)</span>
                                <span className={styles.item}>{titles.INTERVAL_FROM_STATION} (часы, мин)</span>
                            </>
                        )}

                        <span className={styles.item}>Тип</span>
                    </>
                )}
            </ListItemText>

            {draggable && <ListItemIcon />}
        </ListItem>
    );

    return (
        <>
            {draggable
                ? <DragAndDrop
                    list={checkpointsWithIds}
                    itemsRender={listRender}
                    onChange={handleReorder}
                    listHeader={listHeader}
                />
                : (
                    <List>
                        {listHeader}
                        {items.map((item, index) =>
                            <Item
                                key={item.number}
                                item={item}
                                index={index}
                                service={service}
                            />
                        )}
                    </List>
                )
            }

            {!!deletedCheckpoint && (
                <ConfirmDelete
                    open={!!deletedCheckpoint}
                    onSuccess={() => {
                        deleteCheckPoint(deletedCheckpoint.number);
                        setDeletedCheckpoint(null);
                    }}
                    onClose={() => setDeletedCheckpoint(null)}
                    loadingButton={false}
                    message={messages.CONFIRM_DELETE}
                    onlyClose={false}
                />
            )}

            {!!editedCheckpoint
            && <IntervalMileageModal
                isOpen={!!editedCheckpoint}
                onClose={() => setEditedCheckpoint(null)}
                onAccept={(val) => {
                    changeCheckPoint(val);
                    setEditedCheckpoint(null);
                }}
                item={editedCheckpoint}
            />
            }
        </>
    );
};

export default CheckPointList;
