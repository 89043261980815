// сборщик событий
// -> вызов actionFunction(events) => {...}
// через timeout (3000мс)
const compareEventWs = () => {
    const instance = {};
    const timerIds = {};

    return (
        /// ключ доступа в структуре
        keyUniq,
        /// функция возврата
        actionFunction,
        /// ключ объединения записей
        keyInEvent,
        /// задержка мс
        timeout = 3000
    ) => {
        const doAction = () => {
            if (!timerIds[keyUniq]) {
                // таймер после получения 1 данных
                timerIds[keyUniq] = setTimeout(() => {
                    actionFunction(instance[keyUniq]);
                    delete instance[keyUniq];
                    delete timerIds[keyUniq];
                }, timeout);
            }
        };

        return {
            list: (
                // список событий
                events
            ) => {
                const list = events
                    ?.reduce((res, item) =>
                        item?.[keyInEvent]
                            ? ({
                                ...res,
                                [item[keyInEvent]]: item
                            })
                            : res
                    , {});
                if (Object.keys(list).length > 0) {
                    instance[keyUniq] = {
                        ...(instance[keyUniq] || {}),
                        ...list,
                    };

                    doAction();
                }
            },
            item: (
                // событие
                event
            ) => {
                if (event?.[keyInEvent]) {
                    instance[keyUniq] = {
                        ...(instance[keyUniq] || {}),
                        [event[keyInEvent]]: event,
                    };

                    doAction();
                }
            }
        };
    };
};


export default compareEventWs();
