import React from 'react';
import { changeCirclePercent } from '../helper';

// выводим фазу и время фазы
const Hint = (props) => {
    const { id, wsData } = props;
    const wsDataItem = wsData[id] || {};

    const {
        passedTime, //45
        curPhNum, //1
        leftTime, //18
        // cycleDur = '-', //88
        phases = [],
    } = wsDataItem;

    const phas = wsDataItem?.phases?.find(({ num }) => num === curPhNum) || {};
    const {
        dur
    } = phas;

    if (leftTime >= 0 && dur > 0) {
        const percent = (passedTime / dur) * 100;
        changeCirclePercent(percent >= 0 ? percent : 100, id);
    } else changeCirclePercent(0, id);

    // todo поменять условие
    const leftTimeFix = leftTime > 0 || leftTime === 'undefined' ? leftTime : 0;

    return (
        <div className="tl-hint">
            <div className="phases">
                <strong>Фаза:</strong> {curPhNum ?? '-'} / {phases?.length || '-'}
            </div>
            <div className="phasestime">
                <strong>Время:</strong> {leftTimeFix ?? '-'} / {dur ?? '-'}
            </div>
        </div>
    );
};

export default Hint;
