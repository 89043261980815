import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import {
    createVehicle,
    editVehicle,
    loadVehicleCategories,
} from 'redux/TransportSpecial/actions';
import { useStoreProp, useValidation } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import MarkModelComponent from 'components/common/Transport/MarkModelComponent';
import SelectCompany from 'components/common/Autocomplete/Companies';

const AddEditModal = ({ open, onClose, isNew, item, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const categories = useStoreProp(loadVehicleCategories, 'transportSpecial', 'vehicleCategories');

    const currentCategoryId = isNew ? '' : categories?.find(el => el.id === item?.category_id)?.id;

    const initialState = {
        number: item?.number || '',
        garage_number: item?.garage_number || '',
        brand: item?.brand || {},
        model_id: item?.model?.id || '',
        category_id: currentCategoryId,
        serial_egts: item?.characteristics?.serial_egts || '',
        serial_yandex: item?.characteristics?.serial_yandex || '',
        organization: item?.organization || {},
        // egts_receiver: item?.egts_receiver || {},
        // egts_receiver_id: item?.egts_receiver_id || ''
    };

    const [formData, setFormData] = useState(initialState);

    const onChange = (name, value) => {
        validation.deleteKey(name);
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const save = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            organization_id: formData.organization.id,
            organization: null,
            brand: null,
            characteristics: {
                ...item?.characteristics,
                ...(formData.serial_egts && { serial_egts: formData.serial_egts }),
                ...(formData.serial_yandex && { serial_yandex: formData.serial_yandex })
            }
        }, false);

        const callback = () => {
            reloadList();
            onClose();
        };

        dispatch(isNew
            ? createVehicle(prepareData, callback)
            : editVehicle(item.id, prepareData, callback)
        );
    };

    const isDisabledSaveButton = Object.values(removeEmptyFields({ ...formData, characteristics: {} })).length < 6;

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT }
            maxWidth="md"
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isDisabledSaveButton || !formData?.model_id,
                        onClick: save
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <div className="row">
                    <TextField
                        value={formData.number}
                        onChange={(e) => onChange('number', e.target.value)}
                        placeholder="А123БС 134RUS"
                        className="row__item"
                        label="Регистрационный номер ТС"
                        size="small"
                        required
                    />
                    <TextField
                        value={formData.garage_number}
                        onChange={(e) => onChange('garage_number', e.target.value)}
                        className="row__item"
                        label="Гаражный номер ТС"
                        size="small"
                        required
                    />
                </div>
                <div className="block">
                    <MarkModelComponent
                        formData={formData}
                        setFormData={setFormData}
                        moduleName="transportSpecial"
                        styleName="block"
                    />
                </div>
                <FormControl
                    variant="outlined"
                    size="small"
                    className="block"
                    required
                >
                    <InputLabel>{titles.TYPE_OF_VEHICLE}</InputLabel>
                    <Select
                        label={titles.TYPE_OF_VEHICLE}
                        value={formData.category_id}
                        name="category_id"
                        onChange={(e) => onChange('category_id', e.target.value)}
                    >
                        {categories?.length > 0
                            ? categories?.map((el) =>
                                <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                            )
                            : <MenuItem value=" ">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
                <TextField
                    placeholder="Серийный номер для EGTS"
                    className="block"
                    label="Серийный номер для EGTS"
                    variant="outlined"
                    size="small"
                    name="serial_egts"
                    value={formData.serial_egts}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                />
                <TextField
                    placeholder="Серийный номер для Яндекса"
                    className="block"
                    label="Серийный номер для Яндекса"
                    variant="outlined"
                    size="small"
                    name="serial_yandex"
                    value={formData.serial_yandex}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                />

                <div className="block">
                    <SelectCompany
                        filter={{ withDeleted: 1 }}
                        selected={formData.organization}
                        onChange={(value) => onChange('organization', value)}
                        helperText={validation.get('organization_id')}
                        error={validation.isKey('organization_id')}
                    />
                </div>
                {/* <FormInfoWrapper
                    className="block"
                    helperText={validation.get('egts_receiver_id')}
                    error={validation.isKey('egts_receiver_id')}
                >
                    <TelemetryReceiverOrganization
                        multiple={false}
                        selected={formData?.egts_receiver}
                        onChange={(egts_receiver) => setFormData({
                            ...formData,
                            egts_receiver: egts_receiver,
                            egts_receiver_id: egts_receiver.id
                        })}
                        disabled={false}
                        storeName="transportSpecial"
                    />
                </FormInfoWrapper> */}
            </div>
        </Modal>
    );
};

export default AddEditModal;
