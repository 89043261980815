import {
    loadColorSpeedList,
    addColorSpeed,
    editColorSpeed,
    deleteColorSpeed,
    setColorSpeedListProps,
    resetColorSpeedListProps
} from '../../../../redux/TransportRoad/actions';

const config = {
    store: 'transportRoad',
    load: loadColorSpeedList,
    add: addColorSpeed,
    edit: editColorSpeed,
    delete: deleteColorSpeed,
    setProps: setColorSpeedListProps,
    resetProps: resetColorSpeedListProps,
};

export default config;
