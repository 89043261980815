import React from 'react';
import Visible from './Visible';

const ContentColumn = ({
    isActions = false,
    width = 'auto',
    align = 'left',
    title = '',
    children,
    isMobile = false,
    wordBreak = 'normal',
    style = {},
}) => (
    <>
        {isMobile
            ? (
                <div
                    className="content_item"
                >
                    <div className="margin-right">
                        {!!title && (
                            <strong>{title}: </strong>
                        )}
                        {children}
                    </div>
                </div>
            )
            : (
                <>
                    <Visible show="desktop">
                        <div
                            className="content_item align-items-center"
                            style={{ width, wordBreak }}
                        >
                            <div
                                className="margin-right"
                                style={{ textAlign: align, ...style }}
                            >
                                {children}
                            </div>
                        </div>
                    </Visible>

                    <Visible show="mobile">
                        <div
                            className="content_item"
                        >
                            <div className="margin-right">
                                {!!title && (
                                    <strong>{title}: </strong>
                                )}
                                {children}
                            </div>
                        </div>
                    </Visible>
                </>
            )
        }

    </>
);

export default ContentColumn;