import React, { useMemo, useState } from 'react';
import { IconButton, InputAdornment, TextField, Autocomplete, Grid } from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import FormButtons, { buttonsTypes } from '../../FormButtons';
import makeStyles from '@mui/styles/makeStyles';
import DateRange from '../../Dates/DateRange';
import { fullDateTimeWithTimeZone } from '../../../../helpers/date.config';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import cn from 'classnames';
import titles from '../../../../helpers/constants/titles';
import useStoreFromSelector from '../../../../helpers/hooks/useStoreFromSelector';
import { loadWsAvailableChannels } from '../../../../redux/WS/actions';
import { wsSelectors } from '../../../../redux/WS';


const useStyles = makeStyles(() => ({
    rootPadding: {
        padding: '16px 6px'
    },
    rootMargin: {
        margin: '0 6px'
    }
}));

const Filter = ({ onChage, value = '' }) => {
    const classes = useStyles();

    const channelsByProject = useStoreFromSelector(loadWsAvailableChannels, wsSelectors.availableChannelsByProject);

    // оставляем только обязательные (уведомления)
    const listChannels = useMemo(() => {
        return Object
            .values(channelsByProject)
            .filter(({ required = false }) => required);
    }, [channelsByProject]);

    const initState = {
        query: '',
        channel_list: [],
        start_date: null,
        end_date: null
    };
    const [data, setData] = useState(initState);
    const [wasRequest, setWasRequest] = useState(false);

    const handleChange = (key, value) => {
        setData({ ...data, [key]: value });
    };

    const handleAutocomplete = (key) => (_, value) => {
        setData({ ...data, [key]: value });
    };

    const search = () => {
        const packageData = removeEmptyFields({
            ...data,
            start_date: data.start_date ? fullDateTimeWithTimeZone(data.start_date) : '',
            end_date: data.start_date ? fullDateTimeWithTimeZone(data.end_date) : '',
            channel_list: data.channel_list.map(item => item.name)
        });
        onChage(packageData);
        setWasRequest(true);
    };

    const resetSearch = () => {
        if (wasRequest) onChage({});
        setData(initState);
        setWasRequest(false);
    };

    const endAdornment = (
        <InputAdornment position="end">
            <IconButton onClick={resetSearch} size="small">
                <Clear />
            </IconButton>
        </InputAdornment>
    );

    const handleEnter = (e) => {
        if (e.code === 'Enter') search();
    };

    const dataHasValue = data.start_date || data.end_date || data.query || data.channel_list.length;

    return (<>
        <div className="filter">
            <div className={classes.rootPadding} >
                <TextField
                    fullWidth
                    value={data.query}
                    onChange={(e) => handleChange('query', e.target.value)}
                    label="Текст сообщений"
                    variant="outlined"
                    size="small"
                    InputProps={data.query ? { endAdornment } : {}}
                    inputProps={{ onKeyDown: handleEnter }}
                />
            </div>
            <div className={cn('filter__wrap', 'filter__wrap__more__field')}>
                <DateRange
                    className={classes.rootMargin}
                    handleDate={(value)=> handleChange('start_date', value)}
                    handleEndDate={(value)=> handleChange('end_date', value)}
                    valueStartDate={data.start_date}
                    valueEndDate={data.end_date}
                />
            </div>
            <div className={cn('filter__wrap', 'filter__wrap__one__field')}>
                <Autocomplete
                    multiple
                    noOptionsText={titles.NO_RESULTS}
                    value={data.channel_list}
                    className={classes.rootMargin}
                    options={listChannels}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.description}
                    onChange={handleAutocomplete('channel_list')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Источник"
                        />
                    )}
                />
            </div>
            <div className="filter__wrap__btn">
                <FormButtons
                    className={classes.rootMargin}
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            disabled: !dataHasValue,
                            onClick: resetSearch,
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: search
                        },
                    ]}
                />
            </div>
        </div>
    </>
    );
};

export default Filter;