import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { isEqual } from 'lodash';

import {
    clearDeviceList,
    clearFilters,
    getDeviceList,
    loadCameraStatuses,
    setActive,
    setFilters,
} from 'redux/VideoAnalytics/actions';
import { videoAnalyticsSelectors } from 'redux/VideoAnalytics';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import getFilters from 'components/MapComponents/helpers/getFilters';
import SidebarItem from 'components/MapComponents/SidebarItem';
import Loading from 'components/common/Loading';
import buttons from 'helpers/constants/buttons';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { usePrevious, useStoreFromSelectorListToObject } from 'helpers/hooks';
import colorExtend from 'helpers/mapHelper/colorExtend';

import { getFilter } from '../helper';
import Icon from '../icons/icon';
import config from '../config';

import Item from './Item';
import Form from './Filter/Form';


const SideBar = (props) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [itemID, setItemID] = useState(null);

    const list = useSelector(videoAnalyticsSelectors.list);
    const listLoading = useSelector(videoAnalyticsSelectors.listLoading);
    const active = useSelector(videoAnalyticsSelectors.active);
    const filters = useSelector(videoAnalyticsSelectors.filters);

    const prevFilters = usePrevious(filters);
    const activeId = Object.keys(active).length > 0 ? active.id : 0;
    const statusesOb = useStoreFromSelectorListToObject(loadCameraStatuses, videoAnalyticsSelectors.statuses);

    const fetchList = () => {
        const filter = getFilters(filters, getFilter);
        // оставляем только выбранные параметры
        // const smallFilter = pick(filter, ['camera_id_list']);
        dispatch(getDeviceList(
            page,
            limit,
            filter
        ));
    };

    const handleScrollUpdate = (values) => {
        const last_page = list?.meta?.last_page || 0;
        if (
            values.top > 0.98
            && listLoading === false
            && page < last_page
        ) {
            setPage(page + 1);
        }
    };

    // отслеживаем изменение проекта
    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            setPage(1);
        }
        fetchList();
    }, [page, limit, filters]);

    useEffect(() => {
        return () => {
            dispatch(clearDeviceList());
        };
    }, []);

    // вывести фильтр
    const renderFilter = () => (
        <SidebarFilter
            filters={filters}
            onSetFilter={(filter) => dispatch(setFilters(filter))}
            resetListPage={() => setPage(1)}
            onClearFilter={() => dispatch(clearFilters())}
            content={({ data, onChange }) => <Form data={data} onChange={onChange} />}
            layer={config.slug}
        />
    );

    const renderList = (dataList) => (
        <>
            {dataList.map((item) => {
                const id = item.id;
                return (
                    <SidebarItem
                        key={id}
                        active={activeId === id}
                        onClick={() => {
                            dispatch(setActive(item));
                        }}
                        icon={
                            Icon({
                                fill: item?.status_color
                                    ? colorExtend(item?.status_color)
                                    : colorExtend(statusesOb.get(item?.status)?.color)
                            })
                        }
                        buttons={{
                            edit: {
                                title: buttons.EDIT,
                                icon: <i className="fas fa-pen"/>,
                                onClick: () => {
                                    setItemID(id);
                                }
                            },
                        }}
                    >
                        <Item
                            {...item}
                            // statusesOb={statusesOb}
                            itemID={itemID}
                            setItemID={setItemID}
                        />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            {renderFilter()}

            <Scrollbars
                onUpdate={handleScrollUpdate}
                renderTrackHorizontal={(props) => (
                    <div {...props} className="track-horizontal"/>)}
            >
                <div className="layers-sidebar__layer">
                    <SidebarTitle
                        title={titles.VIDEOANALYTICS}
                        list={list}
                    />
                    {list.data.length === 0 && listLoading === false
                        ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                        : renderList(list.data)}
                </div>
            </Scrollbars>

            {listLoading && <Loading className="absolute bottom fill"/>}
        </div>
    );
};

export default SideBar;
