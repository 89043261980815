import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import { clearScheduleTemplateList, loadScheduleTemplateList } from 'redux/TransportPassenger/actions';
import UniversalSelect from 'components/common/UniversalSelect';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import type { GetListArguments, ScheduleTemplateItem } from 'types';

interface SelectScheduleTemplateProps<T> extends CommonAutocompleteProps<T> {
    filter?: Object;
    isClone?: boolean;
}

const SelectScheduleTemplate = ({
    multiple = false,
    selected,
    onChange = () => {},
    required = false,
    disabled = false,
    label='Шаблон расписания',
    className = '',
    error = false,
    helperText = '',
    renderLabel = (option: ScheduleTemplateItem | null) => option?.name || '',
    limitTags = 1,
    filter = {},
    isClone = false,
    test_id_prefix = ''  //TODO: прокинуть test_id_prefix после замены Autocomplete на CommonAutocomplete
}: SelectScheduleTemplateProps<ScheduleTemplateItem>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);

    const prevData = useRef(selected);

    useEffect(() => {
        if (!isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const getList = (params: GetListArguments) => {
        const { page, limit, query: name } = params;

        dispatch(loadScheduleTemplateList(page, limit, {
            ...(name && { name }),
            ...filter,
        }));
    };

    const handleAccept = (data: ScheduleTemplateItem | ScheduleTemplateItem[]) => {
        onChange(data);
        setShowList(false);
    };

    const handleClose = () => {
        dispatch(clearScheduleTemplateList());
        setShowList(false);
    };

    const onReset = () => {
        const initialState = multiple ? [] : {};
        onChange(initialState as ScheduleTemplateItem);
    };

    return (
        <>
            <CommonAutocomplete<ScheduleTemplateItem>
                multiple={multiple}
                className={className}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="schedule_template"
                disabled={disabled || isClone}
                onReset={onReset}
                label={label}
                renderLabel={renderLabel}
                limitTags={limitTags}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
                test_id_prefix={test_id_prefix}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    title={label}
                    fetchList={getList}
                    storeName="transportPassenger"
                    storeNameProps="scheduleTemplate"
                    storeLoadingProps="loadingScheduleTemplate"
                    keyProp="id"
                    searchTitle="поиск по названию"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    renderProps={(el) => (<>
                        <p><b>Название:</b> {el?.name},</p>
                        <p><b>Направление:</b> {el?.route?.is_direct_text}</p>
                        <p><b>По маршруту №</b> {el?.route?.num}</p>
                    </>
                    )}
                />
            }
        </>
    );
};

export default SelectScheduleTemplate;
