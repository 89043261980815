import React, { useEffect, useState } from 'react';
import {
    TextField,
} from '@mui/material';
import { loadStreetList } from '../../../../../../redux/Incidents/actions';
import DateRange from '../../../../../common/Dates/DateRange';
import titles from '../../../../../../helpers/constants/titles';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../../../../helpers/hooks';
import { isEqual } from 'lodash';
import SelectInfrastructureTypes from '../../../../../common/Autocomplete/RoadNetwork/InfrastructureTypes';
import SelectCompany from '../../../../../common/Autocomplete/Companies';


const Filter = (props) => {
    const dispatch = useDispatch();
    const { data, onChange } = props;

    const getValue = (key, def = '') => data[key] || def;

    const [street, setStreet] = useState([]);

    const handleLoadStreetList = (query) => {
        dispatch(loadStreetList(query));
    };
    const loadStreetListDebounce = useDebounce(handleLoadStreetList, 800);

    useEffect(() => {
        if (data.street && !isEqual(data.street || [], street)) {
            setStreet(data.street);
        }
    }, [data]);

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >

            <div className="block">
                <TextField
                    label={titles.NAME}
                    size="small"
                    value={getValue('name', [])}
                    onChange={(e) => onChange({ name: e.target.value })}
                />
            </div>

            <div className="block">
                <SelectInfrastructureTypes
                    multiple
                    onChange={(type_id_list) => {
                        onChange({ type_id_list });
                    }}
                    selected={getValue('type_id_list', [])}
                />
            </div>

            <div className="block">
                <SelectCompany
                    multiple
                    filter={{ withDeleted: 1 }}
                    selected={getValue('organization_id_list', [])}
                    onChange={(organization) => {
                        onChange({ organization_id_list: organization.map(item => ({ ...item, name: item.title })) });
                    }}
                />
            </div>

            <div className="row">
                <DateRange
                    handleDate={(newDate) => onChange({ start_at: newDate })}
                    handleEndDate={(newDate) => onChange({ end_at: newDate })}
                    valueStartDate={getValue('start_at', null)}
                    valueEndDate={getValue('end_at', null)}
                    startLabel={titles.START_PERIOD}
                    endLabel={titles.END_PERIOD}
                    dateOnly
                />
            </div>

        </form>
    );
};

export default Filter;
