import { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { videoAnalyticsSelectors } from 'redux/VideoAnalytics';
import { loadCameraStatuses } from 'redux/VideoAnalytics/actions';
import { useStoreFromSelector } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SelectCompany from 'components/common/Autocomplete/Companies';

const Filter = ({ setParams, search }) => {

    const statuses = useStoreFromSelector(loadCameraStatuses, videoAnalyticsSelectors.statuses);

    const initialState = {
        uuid: [],
        name: '',
        status_list: [],
        organization_id_list: []
    };

    const [formValues, setFormValues] = useState(initialState);

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSearch = () => {
        const prepareData = removeEmptyFields({
            ...formValues,
            organization_id_list: formValues.organization_id_list.map(item => item.id)
        });

        search(prepareData);
    };

    const resetSearch = (needRefresh) => {
        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }
        setFormValues(initialState);
    };

    const onChangeUuid = (name, newValue) => {
        setFormValues({ ...formValues, [name]: newValue });
    };

    const handleClickEnter = (e) => {
        if (e?.key === 'Enter') search();
    };

    const isDisabled = Object.keys(removeEmptyFields(formValues)).length === 0;

    const updateFilter = (filters) => {
        setFormValues({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            search({
                ...initialState,
                ...filters,
                ...(filters?.organization_id_list?.length > 0 && {
                    organization_id_list: filters.organization_id_list.map((item) => item.id),
                }),
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetSearch}
            onSearch={handleSearch}
            filter={formValues}
            setUserFilter={updateFilter}  // если у пользователя сохранен фильтр для этой страницы, мы его из общего компонента сетаем сюда
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <MultipleInput
                    value={formValues?.uuid}
                    onChange={(e) => onChangeUuid('uuid', e) }
                    label="UUID"
                    guideText="Добавление одного или нескольких элементов, разделяя запятой"
                />
                <TextField
                    variant="outlined"
                    value={formValues.name}
                    name="name"
                    onChange={handleChange}
                    label="Название"
                    size="small"
                    onKeyPress={handleClickEnter}
                />
                <FormInfoWrapper>
                    <Autocomplete
                        multiple
                        limitTags={1}
                        value={formValues.status_list || []}
                        options={statuses}
                        filterSelectedOptions={true}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.name || ''}
                        freeSolo
                        size="small"
                        onChange={(e, newValues) => setFormValues({
                            ...formValues,
                            status_list: newValues
                        })}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label="Статус"
                            />
                        )}
                    />
                </FormInfoWrapper>

                <SelectCompany
                    label="Балансодержатель"
                    multiple
                    selected={formValues.organization_id_list}
                    onChange={(value) => setFormValues({ ...formValues, organization_id_list: value })}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
