import React from 'react';
import Page from './Page';
import { HeaderPageVisible } from '../../../common/Showcase/HeaderPage';
import BoxContainer from '../../../common/Showcase/BoxContainer';
import HeaderPanel from '../../../common/Showcase/HeaderPanel';

// страница
const TrafficFlow = () => (
    <BoxContainer>

        {/* шапка */}
        <HeaderPageVisible/>

        {/* заголовок */}
        <HeaderPanel title="ПУИД"/>

        {/* содержимое страницы */}
        <Page/>

    </BoxContainer>
);

export default TrafficFlow;