import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiIncidentsMethods = {
    getDtpTypes: async (params) => {
        const response = await instance.get(apiUrls.getDtpTypes(), { params });
        return response?.data;
    },

    editDtpType: async (params) => {
        const response = await instance.put(apiUrls.editDtpType(), params);
        return response?.data;
    },

    getDtpList: async (config = {}) => {
        const response = await instance.get(apiUrls.getDtpList(), config);
        return response?.data;
    },

    getDtpTypeLoad: async () => {
        const response = await instance.get(apiUrls.getDtpTypeLoad());
        return response?.data;
    },

    getDtpSettings: async (page, limit) => {
        const response = await instance.get(apiUrls.getDtpSettings(), {
            params: { page, limit },
        });
        return response?.data;
    },

    getDtpSettingById: async (id) => {
        const response = await instance.get(apiUrls.getDtpSettingById(id));
        return response?.data;
    },

    editDtpSetting: async ({ id, data }) => {
        const response = await instance.put(apiUrls.editDtpSetting(id), { value: data.value });
        return response?.data;
    },

    editSettingsList: async (data) => {
        const response = await instance.put(apiUrls.editSettingsList(), data);
        return response?.data;
    },

    getDtpReport: async (config = {}) => {
        const response = await instance.get(apiUrls.getDtpReport(), config);
        return response.data;
    },

    getDtpStreets: async (data) => {
        const response = await instance.get(apiUrls.getDtpStreets(), {
            params: { query: data }
        });
        return response.data;
    },

    getPolygon: async (polygon, params) => {
        const response = await instance.post(apiUrls.getPolygon(), {
            polygon,
            ...params
        });
        return response.data;
    },

    createNewDtp: async (data) => {
        const response = await instance.post(apiUrls.createNewDtp(), data);
        return response.data;
    },

    editDtp: async (id, data) => {
        const response = await instance.put(apiUrls.editDtp(id), data);
        return response.data;
    },

    deleteDtp: async (id) => {
        const response = await instance.delete(apiUrls.deleteDtp(id));
        return response.data;
    },

    getDtpById: async (id) => {
        const response = await instance.get(apiUrls.getDtpById(id));
        return response.data;
    },

    loadDtpStreetList: async (params) => {
        const response = await instance.get(apiUrls.loadDtpStreetList(), {
            params
        });
        return response.data;
    },

    loadDtpDates: async () => {
        const response = await instance.get(apiUrls.loadDtpDates());
        return response.data;
    },

    getStatuses: async () => {
        const response = await instance.get(apiUrls.getStatuses());
        return response.data;
    },
    
    getSeverity: async () => {
        const response = await instance.get(apiUrls.getSeverity());
        return response.data;
    },

    getDtpReportByArea: async (params) => {
        const response = await instance.get(apiUrls.getDtpReportByArea(), {
            params
        });
        return response.data;
    },

    getDtpReportByPercent: async (config) => {
        const response = await instance.get(apiUrls.getDtpReportByPercent(), config);
        return response.data;
    },

    getDangerousArea: async (config) => {
        const response = await instance.get(apiUrls.getDangerousArea(), config);
        return response.data;
    },

    loadDtpFile: async (xls) => {
        const formData = new FormData();
        formData.append('xls', xls);
        formData.append('service', 'dtp');
        // formData.append('project', project);

        const response = await instance.post(apiUrls.loadDtpFile(), formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    },

    reportMonthAreas: async (config) => {
        const response = await instance.get(apiUrls.reportMonthAreas(), config);
        return response.data;
    },

    reportStatistic: async (data) => {
        const response = await instance.post(apiUrls.reportStatistic(), data);
        return response.data;
    },

    reportByCategory: async (data) => {
        const response = await instance.post(apiUrls.reportByCategory(), data);
        return response.data;
    },

    reportCategory: async () => {
        const response = await instance.get(apiUrls.reportCategory());
        return response.data;
    },

    reportCategoryTable: async (params) => {
        const response = await instance.post(apiUrls.reportCategoryTable(), params);
        return response.data;
    },

    editStatuses: async (params) => {
        const response = await instance.post(apiUrls.editStatuses(), params);
        return response?.data;
    },

    newMap: async (params, config) => {
        const response = await instance.post(apiUrls.newMap(), params, config);
        return response?.data;
    },

    getPopulation: async (params) => {
        const response = await instance.get(apiUrls.getPopulation(), { params });
        return response.data;
    },

    createPopulation: async (data) => {
        const response = await instance.post(apiUrls.createPopulation(), data);
        return response.data;
    },

    editPopulation: async (id, data) => {
        const response = await instance.put(apiUrls.editPopulation(id), data);
        return response.data;
    },

    deletePopulation: async (id) => {
        const response = await instance.delete(apiUrls.deletePopulation(id));
        return response.data;
    },

    getConcentrationArea: async (params) => {
        const response = await instance.get(apiUrls.getConcentrationArea(), { params });
        return response.data;
    },

    createConcentrationArea: async (data) => {
        const response = await instance.post(apiUrls.createConcentrationArea(), data);
        return response.data;
    },

    editConcentrationArea: async (id, data) => {
        const response = await instance.put(apiUrls.editConcentrationArea(id), data);
        return response.data;
    },

    deleteConcentrationArea: async (id) => {
        const response = await instance.delete(apiUrls.deleteConcentrationArea(id));
        return response.data;
    },

    getConcentrationAreaStats: async (params) => {
        const response = await instance.get(apiUrls.getConcentrationAreaStatistics(), { params });
        return response.data;
    },

    getConcentrationAreaTypes: async () => {
        const response = await instance.get(apiUrls.getConcentrationAreaTypes());
        return response.data;
    },

    getConcentrationAreaStatuses: async () => {
        const response = await instance.get(apiUrls.getConcentrationAreaStatuses());
        return response.data;
    },

    getDtpOnRadius: async (data) => {
        const response = await instance.post(apiUrls.getDtpOnRadius(), data);
        return response.data;
    },
    
    loadShowcase: async () => {
        const response = await instance.post(apiUrls.loadShowcase());
        return response?.data;
    },

    getDtpSettingByName: async (data) => {
        const response = await instance.get(apiUrls.getDtpSettingByName(), { params: { name : data } });
        return response.data;
    },

    getSettingsGroup: async () => {
        const response = await instance.get(apiUrls.getSettingsGroup());
        return response?.data;
    },

    editSettingsGroup: async (id, data) => {
        const response = await instance.put(apiUrls.editSettingsGroup(id), data);
        return response?.data;
    },

    getDtpByTime: async (data) => {
        const response = await instance.post(apiUrls.getDtpByTime(), data);
        return response?.data;
    },
};

export default ApiIncidentsMethods;
