import React, { useMemo } from 'react';
import { Line as GLine } from 'react-chartjs-2';
import hexToRgba from './hexToRgba';


const GraphicList = ({
    list = [],
    filter,
}) => {
    let unit = '';
    let max = 0;

    // конвертируем данные
    const convertDataItem = (item) => {
        return item?.reduce((res, item) => {
            const current = filter(item);
            if (unit === '') unit = current?.units || '';

            const value = current?.value || 0;

            // max value for graphics
            const roundValue = Math.ceil(value);
            if (roundValue > max) max = roundValue;

            return {
                labels: [
                    ...res?.labels,
                    `${item.date}`,
                ],
                dataset: [
                    ...res?.dataset,
                    value,
                ],
                units: [
                    ...res?.units,
                    `${current?.value ?? 0} ${current?.units ?? ''}`,
                ],
                color: res?.color || current?.color,
            };

        }, {
            labels: [],
            dataset: [],
            units: [],
            color: '',
        });
    };

    const listData = useMemo(() => {
        return list?.map((itemList) => {
            const {
                data = {},
                opacity = 1,
                title = '',
                labelAfter = '',
                yScale = 'left',
            } = itemList;

            const datas = data?.datas || [];

            const {
                labels,
                dataset,
                units,
                color,
            } = convertDataItem(datas);

            const newColor = color ? hexToRgba(color, opacity) : undefined;

            const labelsList = labels.map(i => `${i}${labelAfter}`);
            const reg =  /\.[\w\d\s.]*/;

            return {
                // labels: labelsList,
                // оставляем только день
                labels: labelsList?.map(i => i?.replace(reg, '')),
                datasets: [
                    {
                        label: title,
                        borderColor: newColor,
                        backgroundColor: newColor,
                        data: dataset,
                        units: units,
                        labelsPopUp: labelsList,
                    }
                ],
                yScale,
            };
        });
    }, [list, filter]);

    const options = (yScale) => ({
        // responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: true,
            },
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks: {
                    title: ({ 0: context }) => {
                        const dataIndex = context?.dataIndex;
                        return `${context?.dataset?.label} - ${context?.dataset?.labelsPopUp?.[dataIndex] || ''}`;
                        // return `${context?.dataset?.label} - ${context?.label}`;
                    },
                    label: (context) => {
                        const dataIndex = context?.dataIndex;
                        return ` ${context?.dataset?.units?.[dataIndex] || ''}`;
                    },
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: unit,
                },
                max: max || 1,
                ...(yScale === 'right' ? {
                    type: 'linear',
                    display: true,
                    position: 'right',
                } : {}),
            },
        },
    });

    return (
        <div
            style={{ marginBottom: '1rem' }}
            className="row"
        >

            {listData?.map((itemData, index) => (
                <div
                    key={index}
                    className="row__item"
                    style={{
                        marginRight: 0,
                    }}
                >
                    <GLine
                        options={options(itemData?.yScale || '')}
                        data={itemData}
                        style={{
                            maxHeight: '210px',
                            margin: 0,
                            padding: 0,
                        }}
                    />
                </div>
            ))}

        </div>
    );
};

export default GraphicList;