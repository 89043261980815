import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiEcologyMethods = {
    getList: async (params) => {
        const response = await instance.get(apiUrls.getList(), { params } );
        return response?.data;
    },

    getStatuses: async () => {
        const response = await instance.get(apiUrls.getStatuses());
        return response?.data;
    },
};

export default ApiEcologyMethods;
