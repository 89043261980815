import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { isEqual } from 'lodash';
import { Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import * as actions from 'redux/VideoCameras/actions';
import { usePrevious } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import getFilters from 'components/MapComponents/helpers/getFilters';
import SidebarItem from 'components/MapComponents/SidebarItem';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import ModalForm from 'components/pages/Dictionaries/CamerasGroup/ModalForm';
import ConfirmDelete from 'components/common/ConfirmModal';
import Loading from 'components/common/Loading';

import Icon from '../icons/icon';
import FrameButton from '../FrameButton';
import config from '../config';

import FilterForm from './Filter/index';
import Item from './Item';


const useStyle = makeStyles({
    tab: {
        minWidth: '50%',
        maxWidth: '50%'
    }
});

const SideBar = (props) => {
    const styles = useStyle();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const scrollRef = useRef(null);

    const {
        sidebar,
        sidebarLoading,
        active,
        savedCamera,
        filters,
        filtersGroup,
        cameraGroup,
        loadingCameraGroup
    } = useSelector(({ videoCameras }) => videoCameras);

    const prevFilters = usePrevious(filters);
    const prevFiltersGroup = usePrevious(filtersGroup);
    const activeId = Object.keys(active).length > 0 ? active.id : 0;

    const [toggleSideBar, setToggleSideBar] = useState(titles.CITY_CAMERAS);

    const list = toggleSideBar === titles.CITY_CAMERAS  ? sidebar : cameraGroup;
    const loading = toggleSideBar === titles.CITY_CAMERAS ? sidebarLoading : loadingCameraGroup;

    const handleChangeToggle = (e, value) => {
        setToggleSideBar(value);
        setPage(1);
    };

    const [selectedItem, setSelectedItem] = useState({});
    const [deleteItem, setDeleteItem] = useState('');

    const scroolTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    const fetchList = () => {
        toggleSideBar === titles.CITY_CAMERAS 
            ? dispatch(actions.loadSidebar(page, limit, getFilters(filters)))
            : dispatch(actions.loadCameraGroupList(page, limit, getFilters(filtersGroup)));
    };

    const handleScrollUpdate = (values) => {
        const last_page = list?.meta?.last_page || 0;
        if (
            values.top > 0.98
            && loading === false
            && page < last_page
        ) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters) || !isEqual(filtersGroup, prevFiltersGroup)) {
            scroolTop();
            setPage(1);
        }
        fetchList();
    }, [page, limit, filters, filtersGroup, toggleSideBar]);

    useEffect(() => {
        return () => {
            toggleSideBar === titles.CITY_CAMERAS
                ? dispatch(actions.clearSidebar())
                : dispatch(actions.clearCameraGroupList());
        };
    }, []);

    useEffect(() => {
        if (savedCamera) {
            scroolTop();
            if (page > 1) {
                setPage(1);
            } else {
                fetchList();
            }
        }
    }, [savedCamera]);

    const renderList = (dataList, name) => (
        <>
            {dataList?.map((item) => {
                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        icon={Icon({ fill: item.status_color })}
                        buttons={{
                            edit: !(Number(item.type) === 2 || Number(item.type) === 3)
                                ? {
                                    title: buttons.EDIT,
                                    icon: <i className="fas fa-pen" />,
                                    onClick: () => {
                                        // редактирование
                                        name === 'cameras'
                                            ? dispatch(actions.setEditForm(item))
                                            : setSelectedItem(item);
                                    }
                                }
                                : {},
                            delete: {
                                title: buttons.DELETE,
                                icon: <i className="fas fa-trash-alt" />,
                                onClick: () => {
                                    // удаление
                                    name === 'cameras'
                                        ? dispatch(actions.setDeleteForm(item.id))
                                        : setDeleteItem(item.id);
                                }
                            }
                        }}
                        onClick={() => {
                            name === 'cameras'
                                ? dispatch(actions.setActive(item))
                                : dispatch(actions.setActive(item.cameras?.[0] || {}));
                        }}
                        sideComponent={
                            <FrameButton 
                                cameraIds={name === 'cameras' 
                                    ? [item.id] 
                                    : item.cameras.map(item => item.id)} 
                            />
                        }
                    >
                        <Item {...item} checkValue={name}/>
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            {toggleSideBar === titles.CITY_CAMERAS
                ? <SidebarFilter
                    filters={filters}
                    onSetFilter={(filter) => dispatch(actions.setFilters(filter))}
                    resetListPage={() => setPage(1)}
                    onClearFilter={() => dispatch(actions.clearFilters())}
                    content={({ data, onChange }) => (
                        <FilterForm
                            data={data}
                            onChange={onChange}
                            isGroup={false}
                        />
                    )}
                    layer={config.slug}
                    key={config.slug}
                />
                : <SidebarFilter
                    filters={filtersGroup}
                    onSetFilter={(filter) => dispatch(actions.setFiltersGroup(filter))}
                    resetListPage={() => setPage(1)}
                    onClearFilter={() => dispatch(actions.clearFiltersGroup())}
                    content={({ data, onChange }) => (
                        <FilterForm
                            data={data}
                            onChange={onChange}
                            isGroup={true}
                        />
                    )}
                    layer={`${config.slug}-group`}
                    key={`${config.slug}-group`}
                />
            }

            {loading && list?.data?.length === 0 
                ? <Loading className="absolute bottom fill" />
                : <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                    ref={scrollRef}
                >
                    <div className="layers-sidebar__layer">
                        <Tabs
                            value={toggleSideBar}
                            onChange={handleChangeToggle}
                            indicatorColor="primary"
                            textColor="primary"
                            size="small"
                            aria-label="scrollable force tabs example"
                        >
                            <Tab
                                value={titles.CITY_CAMERAS}
                                label={<><div>Камеры</div><div>видеонаблюдения</div></>}
                                classes={{ root: styles.tab }}
                            />
                            <Tab
                                value={titles.CITY_CAMERAS_GROUP}
                                label={<><div>Группы</div><div>камер</div></>}
                                classes={{ root: styles.tab }}
                            />
                        </Tabs>
                        {list?.data?.length === 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList(list?.data, toggleSideBar === titles.CITY_CAMERAS ? 'cameras' : 'group')
                        }
                    </div>
                    {loading && <Loading className="center" />}
                </Scrollbars>
            }

            {Object.keys(selectedItem).length > 0
                && <ModalForm
                    isNew={false}
                    isOpen={Object.keys(selectedItem).length > 0}
                    onClose={() => setSelectedItem({})}
                    el={selectedItem}
                />
            }
            {!!deleteItem && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={!!deleteItem}
                    onSuccess={() => {
                        dispatch(actions.deleteCameraGroup(deleteItem));
                        setDeleteItem('');
                    }}
                    onClose={() => setDeleteItem('')}
                />
            )}
        </div>
    );
};

export default SideBar;
