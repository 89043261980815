
import React, { useEffect, useRef } from 'react';

import CustomPagination from 'components/common/CustomPagination';
import Loading from 'components/common/Loading';
import PageTitle from 'components/common/PageTitle';

import styles from './layout.module.scss';
import InformPanel from './InformPanel';

import type { InformPanelProps } from './InformPanel';
import type { PaginationProps } from './types';

interface PageLayoutProps {
    header?: React.ReactNode;
    subHeader?: React.ReactNode;
    content?: (ref?: unknown) => React.ReactNode;
    filters?: React.ReactNode;
    additionalButtons?: React.ReactNode;
    informPanelProps?: InformPanelProps;
    loading?: boolean;
    paginationProps?: PaginationProps;
    customStyles?: React.CSSProperties;
    actionPanel?: React.ReactNode;
    tab?: string | null,
}

const PageLayout = ({
    additionalButtons,  // например кнопка Вернуться в дашборд
    header, // заголовок страницы
    filters,
    paginationProps,
    informPanelProps,
    content, // функция которая возвращает jsx
    customStyles,
    loading,
    actionPanel, // панель под informPanel, всякие чекбоксы и т.п
    tab, // компонент внутри таба
    subHeader,
}: PageLayoutProps) => {
    const contentRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (contentRef.current) contentRef.current?.scrollTo({ top: 0 });
    }, [paginationProps?.list?.current_page]);

    return <div className={styles.layout}>
        {header && !tab
            && <div className={styles.header_panel}>
                <PageTitle title={header} />
                {additionalButtons}
            </div>
        }

        {subHeader && <h3>{subHeader}</h3>}

        {filters && filters}

        {Object.keys(informPanelProps || {}).length > 0 && <InformPanel {...informPanelProps} />}

        {actionPanel && <div className={styles.actionPanel}>{actionPanel}</div>}

        {loading && <Loading linear/>}

        {content
            ? <div className={styles.content} style={customStyles} ref={contentRef} >
                {content(contentRef?.current)}
                {/* передаем ref контента на случай если например будет нужна высота блока для скролла */}
            </div>
            : <div style={{ flex: 1 }}></div>
        }

        <CustomPagination {...paginationProps} />
    </div>;
};

export default PageLayout;
