import { useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import Print from '@mui/icons-material/Print';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import cn from 'classnames';

import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import Image from 'components/common/Image';

import styles from './mediaModal.module.scss';

// компонент с пролистыванием вперед-назад
// подгрузку новых делаем тут же и храним весь список локально
const MediaModal = ({
    isOpen, 
    onClose,
    images = [],
    renderLabels,
}) => {
    const footerComponent = useMemo(() => renderLabels?.() || '', [renderLabels]);
    const [imageIndex, setImageIndex] = useState(0);

    const componentRef = useRef();
    const printImg = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
            @media print {
                .pagebreak {
                    page-break-before: auto;
                }
            }
        `,
    });

    const openNewWindow = () => window.open(images?.[imageIndex] || '');

    return (
        <Modal
            fullWidth
            isOpen={isOpen}
            noPadding
            onClose={onClose}
            noOverflowY={window.innerHeight >= 1080}
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        }
                    ]}
                />
            }
        >
            <div style={{ display: 'flex', height: '100%' }}>
                {/*<Button*/}
                {/*    disabled={imageIndex <= 0}*/}
                {/*    onClick={() => setImageIndex(current => Math.max(0, current - 1))}*/}
                {/*>*/}
                {/*    <ArrowBackIosIcon />*/}
                {/*</Button>*/}

                <div className={styles.mediaModalContainer} style={{ flex: 1 }}>

                    <div className={styles.navigationBar}>
                        <ButtonGroup className={styles.navigationBar__item} size="small">
                            {/*<Tooltip title="Сохранить в архив">*/}
                            {/*    <span>*/}
                            {/*        <Button size="small">*/}
                            {/*            <Save />*/}
                            {/*        </Button>*/}
                            {/*    </span>*/}
                            {/*</Tooltip>*/}
                            <Tooltip title={'На печать'}>
                                <span>
                                    <Button
                                        onClick={printImg} size="small"
                                    >
                                        <Print />
                                    </Button>
                                </span>
                            </Tooltip>
                        </ButtonGroup>
                    </div>

                    <div className={styles.content}>

                        <div className={styles.sidebar}>
                            <h2 className={styles.sidebar__header}>Изображения</h2>
                            <div className={styles.sidebar__content}>
                                {images.map((image, index) => (
                                    <div
                                        className={cn(styles.mediaBlock, index === imageIndex && styles.active)}
                                        key={index}
                                        onClick={() => {
                                            setImageIndex(index);
                                        }}
                                    >
                                        <Image
                                            className={styles.media}
                                            src={image}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={styles.mainContentBlock}>
                            <Image src={images?.[imageIndex] || ''}/>

                            <FormButtonsComponent
                                noPadding
                                justButton
                                buttons={[
                                    {
                                        onClick: openNewWindow,
                                        name: buttons.OPEN_IN_NEW_WINDOW,
                                    }
                                ]}
                            />
                        </div>

                    </div>

                    <div className={styles.footer}>
                        {footerComponent}
                    </div>

                </div>
                
                {/*<Button*/}
                {/*    disabled={imageIndex >= images?.length - 1}*/}
                {/*    onClick={() => setImageIndex(current => Math.min(Math.max(0, images.length - 1), current + 1))}*/}
                {/*>*/}
                {/*    <ArrowForwardIosIcon />*/}
                {/*</Button>*/}
            </div>

            <div ref={componentRef}>
                <div className={styles.printInfo}>
                    <div>
                        <img src={images?.[imageIndex] || ''} alt={messages.NO_IMAGE}/>
                    </div>
                    <div className={styles.footer}>
                        {footerComponent}
                    </div>
                </div>
            </div>

        </Modal>
    );
};

export default MediaModal;