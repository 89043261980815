import type { SyntheticEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import * as passengerTransportActions from 'redux/TransportPassenger/actions';
import * as transportRoadActions from 'redux/TransportRoad/actions';
import * as specialTransportActions from 'redux/TransportSpecial/actions';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';

import type { ElTypes, SelectVehiclesProps } from './types';

const SelectVehicles = ({
    multiple = false,
    selected,
    onChange = () => {},
    error = false,
    helperText = '',
    required = false,
    disabled = false,
    label = titles.VEHICLE,
    filter = {},
    searchBy = 'number',
    storeName = 'transportPassenger',
    loadListAction, // если надо загрузить список из какого-то другого метоад (например не в нарядах)
    renderProps,
    renderOption,
    storeNameProps = 'vehicles',
    withSearch = true,
    limitTags
}: SelectVehiclesProps) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);

    useEffect(() => {
        // например сброс фильтра - selected изменился нужно обновить selectedItems
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleClose = () => setShowList(false);

    const handleAccept = (data: ElTypes | null) => {
        onChange(data);
        setShowList(false);
    };

    const actionList = {
        transportPassenger: passengerTransportActions,
        transportRoad: transportRoadActions,
        transportSpecial: specialTransportActions,
    };

    const actions = actionList[storeName];

    const categories = useStoreProp(
        actions.loadVehicleCategories,
        storeName,
        'vehicleCategories'
    );

    const getCurrentCategory = (el: ElTypes | null) => {
        return categories?.find((item: any) => item.id === el?.category_id);
    };

    const loadList = loadListAction || actions.loadVehicleList;

    const renderPropDefault = (el: ElTypes) => {
        const currentCategory = getCurrentCategory(el);
        return (
            (renderProps && renderProps(el, currentCategory)) || (
                <div>
                    <div>
                        <strong>ГРЗ:&nbsp;</strong>
                        {el?.number || messages.NO_DATA}
                    </div>
                    <div>
                        <strong>Марка ТС:&nbsp;</strong>
                        {el?.brand_name || messages.NO_DATA}
                    </div>
                    <div>
                        <strong>Модель ТС:&nbsp;</strong>
                        {el?.model_name || messages.NO_DATA}
                    </div>
                    <div>
                        <strong>{titles.TYPE_OF_VEHICLE}:&nbsp;</strong>
                        {currentCategory?.name || messages.NO_DATA}
                    </div>
                </div>
            )
        );
    };

    const autoCompleteVehicleChange = (
        e: SyntheticEvent | KeyboardEvent,
        value: Record<string, any>[] | Record<string, any>
    ) => {
        if (e instanceof KeyboardEvent && e.key !== 'Enter') {
            onChange(value);
        }
    };

    const getList = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query } = params;
        const data = {
            ...filter,
            ...{ [searchBy]: query },
        };
        const withoutEmpty = removeEmptyFields(data);
        dispatch(loadList(page, limit, withoutEmpty));
    };

    const renderOptionDefault = (option: ElTypes | null) => {
        const currentCategory = getCurrentCategory(option);
        return (
            (renderOption && renderOption(option, currentCategory))
            || (option?.number
                ? `${currentCategory ? currentCategory?.name : ''} ${option?.number}`
                : '')
        );
    };

    const onReset = () => {
        const initialState = multiple ? [] : '';
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={(option) => renderOptionDefault(option)}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error} 
                helperText={helperText}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName={storeName}
                    storeNameProps={storeNameProps}
                    keyProp="id"
                    withSearch={withSearch}
                    isSelected
                    selected={selectedItems}
                    renderProps={renderPropDefault}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={label}
                    small
                />
            }
        </>
    );
};

export default SelectVehicles;
