import * as types from './types';
import { getStartOf, getEndOf } from '../../helpers/date.config';

const initialState = {
    sidebar: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    sidebarLoading: false,
    polygon: [],
    polygonLoading: false,
    polygonVisible: [],
    active: {},
    // данные для удаления
    deleteForm: null,
    // данные для редактирования
    editForm: null,
    saved: false,
    filters: {
        start_date: getStartOf('year'),
        end_date: getEndOf('year'),
    },
    loading: false,
    dtpFocusList: [],
    dtpFocusLoading: false,
    statisticLoading: false,
    statistic: {},
    // тепловая
    heatMap: [],
    loadingHeatMap: false,
    heatMapFilter: {},
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.LOADING:
            return {
                ...state,
                loading: payload,
            };

        case types.LOADING_SIDEBAR:
            return {
                ...state,
                sidebarLoading: payload,
            };

        case types.LOADED_SIDEBAR:
            const { data, replaceStore } = payload;
            return {
                ...state,
                sidebar: replaceStore
                    ? data
                    : {
                        ...state.sidebar,
                        ...data,
                        data: [
                            ...state.sidebar.data,
                            ...data.data
                        ],
                        meta: {
                            ...state.sidebar.meta,
                            ...data.meta
                        },
                    }
            };

        case types.LOADED_POLYGON: {
            return {
                ...state,
                polygon: payload,
            };
        }

        case types.LOADING_DTP_POLYGON: {
            return {
                ...state,
                polygonLoading: payload,
            };
        }
        case types.SET_POLYGON_VISIBLE: {
            return {
                ...state,
                polygonVisible: payload,
            };
        }

        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }

        case types.RESET_ACTIVE: {
            return {
                ...state,
                active: {},
            };
        }

        case types.SET_DELETE_FORM: {
            return {
                ...state,
                deleteForm: payload,
            };
        }

        case types.SET_EDIT_FORM: {
            return {
                ...state,
                editForm: payload,
            };
        }

        case types.SAVE_DTP_FOCUS: {
            return {
                ...state,
                saved: payload,
            };
        }

        case types.SET_FILTERS: {
            return {
                ...state,
                filters: payload,
            };
        }

        case types.LOADING_DTP_FOCUS:
            return {
                ...state,
                dtpFocusLoading: payload,
            };

        case types.LOADED_DTP_FOCUS:
            return {
                ...state,
                dtpFocusList: payload
            };

        case types.LOADING_DTP_FOCUS_STATISTIC:
            return {
                ...state,
                statisticLoading: payload
            };

        case types.LOADED_DTP_FOCUS_STATISTIC:
            return {
                ...state,
                statistic: Array.isArray(payload) ? payload[0] : payload
            };

        case types.LOADED_HEAT_MAP:
            return {
                ...state,
                heatMap: payload
            };

        case types.LOADING_HEAT_MAP:
            return {
                ...state,
                loadingHeatMap: payload
            };

        case types.SET_FILTER_HEAT_MAP:
            return {
                ...state,
                heatMapFilter: payload
            };

        default:
            return state;
    }
};
