import messages from 'helpers/constants/messages';
import getAddressStringFromObject from 'components/common/Location/LoadAddressByCoords/getAddressStringFromObject';


const fields = (statuses, types ) => ({
    // created_at: {
    //     title: 'Дата и время',
    //     value: ({ created_at }) => fullDateTime(created_at),
    // },
    status: {
        title: 'Статус',
        value: ({ status }) => status ? statuses.find(i => i.id === status)?.name : messages.NO_DATA,
    },
    type_id: {
        title: 'Тип',
        value: ({ type_id }) => types ? types[type_id] : messages.NO_DATA,
    },
    address_text: {
        title: 'Адрес',
        value: ({ address_text, address }) => address_text || getAddressStringFromObject(address) || messages.NO_DATA,
    },
});

export default fields;
