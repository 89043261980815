// иконка для списка
const icon = ({ fill = '#fff' }) => {
    return `
        <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.8876 4.73483V16.9101C21.8876 18.0304 20.9685 18.9393 19.8357 18.9393H2.05196C0.919109 18.9393 0 18.0304 0 16.9101V4.73483C0 3.61453 0.919109 2.70562 2.05196 2.70562H5.8139L6.33971 1.31476C6.63896 0.524213 7.40417 0 8.25916 0H13.6242C14.4792 0 15.2444 0.524213 15.5436 1.31476L16.0737 2.70562H19.8357C20.9685 2.70562 21.8876 3.61453 21.8876 4.73483ZM16.0737 10.8225C16.0737 8.02384 13.7738 5.74943 10.9438 5.74943C8.11381 5.74943 5.8139 8.02384 5.8139 10.8225C5.8139 13.6211 8.11381 15.8955 10.9438 15.8955C13.7738 15.8955 16.0737 13.6211 16.0737 10.8225ZM14.7057 10.8225C14.7057 12.8728 13.0171 14.5427 10.9438 14.5427C8.87047 14.5427 7.18187 12.8728 7.18187 10.8225C7.18187 8.77211 8.87047 7.10224 10.9438 7.10224C13.0171 7.10224 14.7057 8.77211 14.7057 10.8225ZM9.57583 10.8225C9.57583 10.0784 10.1914 9.46966 10.9438 9.46966C11.32 9.46966 11.6278 9.16527 11.6278 8.79325C11.6278 8.42123 11.32 8.11685 10.9438 8.11685C9.43476 8.11685 8.20786 9.33015 8.20786 10.8225C8.20786 11.1945 8.51565 11.4989 8.89185 11.4989C9.26804 11.4989 9.57583 11.1945 9.57583 10.8225Z" fill="${fill}"/>
        </svg>
    `;
};

export default icon;
