import React from 'react';
import styles from './sidebar.module.scss';
import Menu from '../Menu';

const SideBar = (props) => {
    return (
        <div className={styles.sidebar}>
            <Menu />
        </div>
    );
};

export default SideBar;
