import {
    useContext,
    useEffect,
    useState
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    loadDefectTypes
} from '../../../../../redux/RoadWorks/actions';
import { roadworksSelectors } from '../../../../../redux/RoadWorks';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import Loading from '../../../../common/Loading';
import DTItem from './DTItem';
import DTModal from './DTModal';
import Context from '../../../../../helpers/context';
import { LSContainer } from '../../../../common/List';
import PageLayout from '../../../../layout/PageLayout';
import NoData from '../../../../common/Information/NoData';
import titles from '../../../../../helpers/constants/titles';
import Filters from './Filters';

const DefectTypes = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const defectTypesData = useSelector(roadworksSelectors.defectTypesData);
    const defectTypesMeta = useSelector(roadworksSelectors.defectTypesMeta);
    const loadingDefectTypes = useSelector(roadworksSelectors.loadingDefectTypes);

    const [openModal, setOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({
        page: 1,
        data: {}
    });

    const reloadList = (isDelete) => {
        isDelete && defectTypesData?.length === 1 && defectTypesMeta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadDefectTypes(params.page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadDefectTypes(params.page, limit, params.data));
    }, [dispatch, limit, params]);
    
    
    const renderContent = () => {
        if (loadingDefectTypes) return <Loading circular/>;
        
        return defectTypesData?.length > 0
            ? (
                <LSContainer
                    headers={[
                        { title: titles.NAME, width: '47%' },
                        { title: 'Группа', width: '47%' },
                        { title: 'Действия', align: 'right', isActions: true }
                    ]}
                >
                    {defectTypesData.map(elem => (
                        <DTItem
                            key={elem.id}
                            elem={elem}
                            reloadList={reloadList}
                            permissions={permissions}
                        />
                    ))}
                </LSContainer>
            )
            : !loadingDefectTypes && <NoData/>;
    };

    return (
        <>
            <PageLayout
                header="Типы недостатков"
                filters={<Filters setParams={setParams}/>}
                content={renderContent}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[
                                    ...(permissions?.is_create
                                        ? [{
                                            ...buttonsTypes.add,
                                            onClick: () => setOpenModal(true),
                                        }]
                                        : []
                                    ),
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: defectTypesMeta?.total
                }}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: defectTypesMeta,
                    limit,
                    setLimit: setLimit,
                }}
            />

            {openModal && (
                <DTModal
                    isNew={true}
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default DefectTypes;