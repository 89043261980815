import React, { useContext, useState } from 'react';
import { Edit, Delete } from '@mui/icons-material';
import { deleteCheckListGroup } from 'redux/DorisControl/actions';
import { useDispatch } from 'react-redux';
import Context from 'helpers/context';
import titles from 'helpers/constants/titles';
import ConfirmModal from 'components/common/ConfirmModal';
import {
    LSContentItem,
    LSContentColumn,
    ActionsButtons,
    ActionMore,
} from 'components/common/List';
import CheckListGroupInfo from './Info';
import { GroupItemProps, Permissions } from './types';
import AddEditModal from './AddEditModal';


const GroupItem = ({ item, reloadList }: GroupItemProps) => {
    const dispatch = useDispatch();
    const { permissions } = useContext<{ permissions?: Permissions }>(Context);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [isOpenInfo, setOpenInfo] = useState<boolean>(false);

    const handleDelete = () => {
        dispatch(deleteCheckListGroup(item.id, () => {
            setShowDeleteModal(false);
            reloadList();
        }));
    };

    return (
        <>
            <LSContentItem 
                onClick={() => setOpenInfo(!isOpenInfo)}
            >
                <LSContentColumn>
                    {item.name}{item.is_systemic && ' (системная) '}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <Edit fontSize="inherit" />,
                                name: titles.EDIT,
                                onClick: () => setShowModal(true),
                                disabled: !permissions?.is_update,
                            },
                            {
                                icon: <Delete fontSize="inherit" />,
                                name: titles.DELETE,
                                onClick: () => setShowDeleteModal(true),
                                disabled: !permissions?.is_delete,
                            },
                        ]}
                    />
                    <ActionMore
                        isOpen={isOpenInfo}
                        onClick={() => setOpenInfo(!isOpenInfo)}
                    />
                </LSContentColumn>
            </LSContentItem>

            {showModal
                && <AddEditModal
                    item={item}
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    reloadList={reloadList}
                    isNew={false}
                />
            }
            {showDeleteModal && (
                <ConfirmModal
                    open={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    onSuccess={handleDelete}
                />
            )}
            {isOpenInfo
                && <CheckListGroupInfo item={item} isOpen={isOpenInfo} />
            }
        </>
    );
};

export default GroupItem;
