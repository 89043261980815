import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import cn from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import PaperDraggableComponent from './PaperDraggableComponent';
import classes from './modal.module.scss';

const Modal = ({
    children, // всё что между тегами <Modal></Modal>
    isOpen, // триггер открытия/закрытия
    onClose,
    fullWidth, // модалка на весь экран (карты и тп)
    title,
    buttons,
    noPadding, // без отступов у ModalContent
    overflowVisible,
    small, // маленькая модалка - например confirmDelete
    maxWidthProp, // Максимальная ширина модалки
    showCloseInTitle,
    medium, // 50% ширина окна
    heightMedium,  // 55% высоты окна
    size, // предназначен задавать ширину модалки в процентах
    onBackdropClick = () => {}, //клик вне модалки
    noOverflowY = false, // если не нужна вертикальная прокуртка
    // todo dnd криво работает в модалке с react-draggable
    disabledDragDrop = false,
}) => {
    return (
        <Dialog
            open={isOpen}
            onClose={(e, r) => {
                if (r === 'escapeKeyDown' && r !== 'backdropClick') {
                    onClose();
                }
            }}
            onBackdropClick={onBackdropClick}
            maxWidth={maxWidthProp}
            fullWidth={fullWidth}
            PaperProps={{
                className:
                    cn(classes.root, {
                        [classes.noPadding]: noPadding,
                        [classes.overflowVisible]: overflowVisible,
                        [classes.fullWidth]: fullWidth,
                        [classes.small]: small,
                        [classes.medium]: medium,
                        [classes.heightMedium]: heightMedium,
                    }),
                style: { ...(size && {
                    width: size,
                    maxWidth: size,
                    // maxHeight: size
                }) }
            }}
            {...!disabledDragDrop ? { PaperComponent: PaperDraggableComponent } : {}}
            aria-labelledby="draggable-dialog-title"
        >
            {title
                && <div className={classes.titleContainer}
                    style={{ cursor: 'move' }}
                    id="draggable-dialog-title"
                >
                    <>
                        <DialogTitle classes={{ root: classes.title }}>
                            {title}
                        </DialogTitle>
                    </>
                </div>
            }
            {(showCloseInTitle || fullWidth)
                && (
                    <div className={classes.titleButton}>
                        <IconButton onClick={() => onClose && onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                )
            }
            {children
                && <DialogContent
                    classes={{ root: cn(classes.content, {
                        [classes.noPadding]: noPadding,
                        [classes.noOverflowY]: noOverflowY,
                    })
                    }}
                >
                    {children}
                </DialogContent>
            }
            {buttons
                && <DialogActions className={classes.actions}>
                    {buttons}
                </DialogActions>
            }
        </Dialog>
    );
};

export default Modal;

Modal.propTypes = {
    children: PropTypes.node, // всё что между тегами <Modal></Modal>
    isOpen: PropTypes.bool, // триггер открытия/закрытия
    onClose: PropTypes.func,
    fullWidth: PropTypes.bool, // модалка на весь экран (карты и тп)
    // title: PropTypes.string,
    buttons: PropTypes.node,
    noPadding: PropTypes.bool, // без отступов у ModalContent
    overflowVisible: PropTypes.any,
    small: PropTypes.bool, // маленькая модалка - например confirmDelete
    maxWidthProp: PropTypes.string, // Максимальная ширина модалки
    showCloseInTitle: PropTypes.any,
    medium: PropTypes.any, // 50% ширина окна
    heightMedium: PropTypes.any,  // 55% высоты окна
    size: PropTypes.string
};

Modal.defaultProps = {
    title: null,
    buttons: null,
};
