import React, { useState } from 'react';
import {
    ListItem,
    ListItemText,
    IconButton, Grid
} from '@mui/material';
// import Info from './Info';
// import { ExpandLess, ExpandMore } from '@material-ui/icons';
// import messages from '../../../../helpers/constants/messages';
import InformationList from './InformationList';
import titles from 'helpers/constants/titles';

const Item = ({ data, period }) => {
    const [open, setOpen] = useState(false);

    const currentData = {
        ...data,
        period,
    };

    const fields = {
        first: {
            period: {
                name: 'Период',
            },
            organization_title: {
                name: 'Организация',
            },
            route_num: {
                name: '№ маршрута',
            },
            route_name: {
                name: 'Наименование маршрута',
            },
            route_type: {
                name: 'Тип маршрута',
            },
            traffic_schedule_id: {
                name: '№ графика движения',
            },
            work_order_id: {
                name: 'Наряд',
            },
            date_at: {
                name: 'Дата наряда',
            },
            vehicle_number: {
                name: titles.GRZ,
            },
        },
        second: {
            // route_num: {
            //     name: 'смена',
            // },
            flight_id: {
                name: 'Рейс',
            },
            flight_start_scheduled_time: {
                name: 'Время начала план',
            },
            flight_start_actual_time: {
                name: 'Время начала факт',
            },
            flight_end_scheduled_time: {
                name: 'Время окончания план',
            },
            flight_end_actual_time: {
                name: 'Время окончания факт',
            },
            auto_status: {
                name: 'Статус рейса',
            },
            done: {
                name: 'Регулярность',
            },
            manual_status: {
                name: 'Статус диспетчера',
            },
            defect_group_name: {
                name: 'Причина',
            },
            defect_name: {
                name: 'Брак рейса',
            },
            manual_status_comment: {
                name: 'Комментарий',
            },
        },
    };

    return (
        <>
            <ListItem onClick={() => setOpen(!open)} className="list_item">
                <ListItemText>
                    <Grid container direction="row" spacing={3} justify="flex-start" alignItems="flex-start">
                        <Grid item xs={12} md={4}>
                            <InformationList
                                data={currentData}
                                fields={fields.first}
                                showNotFound={true}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <InformationList
                                data={currentData}
                                fields={fields.second}
                                showNotFound={true}
                            />
                        </Grid>
                    </Grid>
                </ListItemText>

                {/*<IconButton size="small">*/}
                {/*    {open ? <ExpandLess fontSize="inherit" /> : <ExpandMore fontSize="inherit" />}*/}
                {/*</IconButton>*/}
            </ListItem>

            {/*<Info*/}
            {/*    isOpen={open}*/}
            {/*    info={el}*/}
            {/*    content={*/}
            {/*        <InformationList*/}
            {/*            data={data}*/}
            {/*            fields={fields}*/}
            {/*        />*/}
            {/*    }*/}
            {/*/>*/}

        </>
    );
};

export default Item;
