import * as types from './types';

const initialState = {
    loading: false,
    companies: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    address: {},
    addressList: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    addressAutocomplete: {
        region: [],
        street: [],
        city: [],
    },
    addressAutocompleteLoading: false,
    addressByCoordinates: {},
    //geocodeAddress: {},
    geocodeAddress: {
        data: [],
    },
    loadingGeocodeAddress: false,

    //
    regions: {},
    loadingRegions: false,
    addressAreaslist: [],
    addressCitieslist: [],
    addressStritslist: [],
    addressHouseslist: [],
};

export default function reducer (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_DADATA_COMPANIES:
            return {
                ...state,
                companies: payload,
            };

        case types.LOADED_DADATA_ADDRESS_LIST:
            return {
                ...state,
                addressList: payload,
            };

        case types.SET_DADATA_LOADING:
            return {
                ...state,
                loading: payload,
            };

        case types.CLEAR_DADATA_ADDRESS_LIST:
            return {
                ...state,
                addressList: {
                    data: [],
                    meta: {
                        last_page: 0,
                    },
                },
            };

        case types.LOADED_AUTOCOMPLETE_ADDRESS:
            return {
                ...state,
                addressAutocomplete: {
                    region: [],
                    street: [],
                    city: [],
                    ...payload
                }
            };

        case types.LOADING_AUTOCOMPLETE_ADDRESS:
            return {
                ...state,
                addressAutocompleteLoading: payload
            };

        case types.LOADED_DADATA_ADDRESS_BY_COORDINATES:
            return {
                ...state,
                addressByCoordinates: payload,
            };

        case types.LOADED_DADATA_ADDRESS:
            return {
                ...state,
                address: payload,
            };

        case types.LOADED_GEOCODE_ADDRESS:
            return {
                ...state,
                geocodeAddress: payload,
            };

        case types.CLEAR_GEOCODE_ADDRESS:
            return {
                ...state,
                geocodeAddress: {
                    data: [],
                },
            };

        case types.LOADED_REGIONS:
            return {
                ...state,
                regions: payload,
            };
        case types.LOADING_REGIONS:
            return {
                ...state,
                loadingRegions: payload,
            };

        case types.LOADED_ADDRESS_AREAS_AUTOCOMPLETE:
            return {
                ...state,
                addressAreaslist: payload,
            };

        case types.LOADED_ADDRESS_CITIES_AUTOCOMPLETE:
            return {
                ...state,
                addressCitieslist: payload,
            };

        case types.LOADED_ADDRESS_STREETS_AUTOCOMPLETE:
            return {
                ...state,
                addressStritslist: payload,
            };

        case types.LOADED_ADDRESS_HOUSES_AUTOCOMPLETE:
            return {
                ...state,
                addressHouseslist: payload,
            };

        case types.LOADING_GEOCODE_ADDRESS:
            return {
                ...state,
                loadingGeocodeAddress: payload,
            };

        default:
            return state;
    }
}
