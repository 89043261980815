import { config } from '../../config';

// дорожные работы
const apiRwUrl = () => config.get('urls').apiRwUrl;

const apiUrls = {
    getStatusesRW: () => `${apiRwUrl()}/road-work/statuses`,

    getListRW: () => `${apiRwUrl()}/road-work`,

    createNewRW: () => `${apiRwUrl()}/road-work`,

    editRW: (id) => `${apiRwUrl()}/road-work/${id}`,

    deleteRW: (id) => `${apiRwUrl()}/road-work/${id}`,

    getForPolygonRW: () => `${apiRwUrl()}/road-work/polygon`,

    getHistoryRW: (id) => `${apiRwUrl()}/road-work/${id}/history`,

    getRwOrders: () => `${apiRwUrl()}/order`,

    getRwOrderStatuses: () => `${apiRwUrl()}/order/statuses`,

    getOrderDirections: () => `${apiRwUrl()}/order/order_directions`,

    getOrdersReport: () => `${apiRwUrl()}/order/report`,

    editRwOrder: (id) => `${apiRwUrl()}/order/${id}`,

    getStagesStatuses: () => `${apiRwUrl()}/stage/statuses`,

    getStageCargoTypes: () => `${apiRwUrl()}/stage/cargo_types`,

    getStagesList: () => `${apiRwUrl()}/stage`,

    createStage: () => `${apiRwUrl()}/stage/`,

    putStage: (id) => `${apiRwUrl()}/stage/${id}`,

    deleteStage: (id) => `${apiRwUrl()}/stage/${id}`,

    putSequenceStages: () => `${apiRwUrl()}/stage/numbers`,

    getDefectGroupList: () => `${apiRwUrl()}/group_of_road_defections`,

    createDefectGroup: () => `${apiRwUrl()}/group_of_road_defections/`,

    editDefectGroup: (id) => `${apiRwUrl()}/group_of_road_defections/${id}`,

    deleteDefectGroup: (id) => `${apiRwUrl()}/group_of_road_defections/${id}`,

    loadDefectTypes: () => `${apiRwUrl()}/type_of_road_defections`,

    createDefectTypes: () => `${apiRwUrl()}/type_of_road_defections/`,

    editDefectTypes: (id) => `${apiRwUrl()}/type_of_road_defections/${id}`,

    deleteDefectTypes: (id) => `${apiRwUrl()}/type_of_road_defections/${id}`,

    getTypeOfRoadDefections: () => `${apiRwUrl()}/type_of_road_defections`,

    getTypeCreation: () => `${apiRwUrl()}/road-work/type_creation`,

    createUnit: () => `${apiRwUrl()}/units-of-measurement`,

    getUnits: () => `${apiRwUrl()}/units-of-measurement`,

    editUnit: (id) => `${apiRwUrl()}/units-of-measurement/${id}`,

    deleteUnit: (id) => `${apiRwUrl()}/units-of-measurement/${id}`,

    getGroups: () => `${apiRwUrl()}/road-work-group`,

    createGroup: () => `${apiRwUrl()}/road-work-group`,

    editGroup: (id) => `${apiRwUrl()}/road-work-group/${id}`,

    deleteGroup: (id) => `${apiRwUrl()}/road-work-group/${id}`,

    getWorks: () => `${apiRwUrl()}/work`,

    createWork: () => `${apiRwUrl()}/work`,

    editWork: (id) => `${apiRwUrl()}/work/${id}`,

    deleteWork: (id) => `${apiRwUrl()}/work/${id}`,

    createStageSyncRoadWork: (id) => `${apiRwUrl()}/stage/${id}/sync_road_works`,

    getConsolidateReport: () => `${apiRwUrl()}/road-work/consolidate_report`,

    getStageSchedule: () => `${apiRwUrl()}/stage_schedule`,

    createStageSchedule: () => `${apiRwUrl()}/stage_schedule/sync`,

    getKDM: () => `${apiRwUrl()}/kdm/`,

    postKDM: () => `${apiRwUrl()}/kdm/report`,

    getPlanFactReport: () => `${apiRwUrl()}/report/plan-fact`,

    getPlanFactReportTypes: () => `${apiRwUrl()}/report/plan-fact/type`,

    getDefects: () => `${apiRwUrl()}/road-defect`,

    getDefectsSidebar: () => `${apiRwUrl()}/road-defect`,

    getDefectsPolygon: () => `${apiRwUrl()}/road-defect/polygon`,

    getDefectsGroups: () => `${apiRwUrl()}/group_of_road_defections`,

    getDefectsTypes: () => `${apiRwUrl()}/type_of_road_defections`,

    createDefect: () => `${apiRwUrl()}/road-defect`,

    editDefect: (id) => `${apiRwUrl()}/road-defect/${id}`,

    deleteDefect: (id) => `${apiRwUrl()}/road-defect/${id}`,

    defectHeatMap: () => `${apiRwUrl()}/road-defect/heat-map`,

    defectStatuses: () => `${apiRwUrl()}/road-defect/statuses`,

    getNearDefects: () => `${apiRwUrl()}/road-defect/in-radius`,

    getSettings: () => `${apiRwUrl()}/settings`,

    editSetting: (id) => `${apiRwUrl()}/settings/${id}`,

    editSettingsList: () => `${apiRwUrl()}/settings/list`,

    loadShowcase: () => `${apiRwUrl()}/showcase`,

    getSettingsGroup: () => `${apiRwUrl()}/settings_group`,

    editSettingsGroup: (id) => `${apiRwUrl()}/settings_group/${id}/settings/update`,

    getOrderTypes: () => `${apiRwUrl()}/order-type`,

    createOrderType: () => `${apiRwUrl()}/order-type`,

    editOrderType: (id) => `${apiRwUrl()}/order-type/${id}`,

    deleteOrderType: (id) => `${apiRwUrl()}/order-type/${id}`,

    getNearRoadWorks: (id) => `${apiRwUrl()}/road-work/near-to-geojson`,

    getRepairObjectIndicators: () => `${apiRwUrl()}/repair-object-indicator`,

    createRepairObjectIndicator: () => `${apiRwUrl()}/repair-object-indicator`,

    editRepairObjectIndicator: (id) => `${apiRwUrl()}/repair-object-indicator/${id}`,

    deleteRepairObjectIndicator: (id) => `${apiRwUrl()}/repair-object-indicator/${id}`,

    getRepairObjects: () => `${apiRwUrl()}/repair-object`,

    createRepairObject: () => `${apiRwUrl()}/repair-object`,

    editRepairObject: (id) => `${apiRwUrl()}/repair-object/${id}`,

    deleteRepairObject: (id) => `${apiRwUrl()}/repair-object/${id}`,

    getRepairFrequency: () => `${apiRwUrl()}/repair_frequency/`,

    getRepairTypes: () => `${apiRwUrl()}/repair_type/`,

    getSheetsAssessmentRoad: () => `${apiRwUrl()}/sheets-assessment-road`,

    createSheetsAssessmentRoad: () => `${apiRwUrl()}/sheets-assessment-road`,

    editSheetsAssessmentRoad: (id) => `${apiRwUrl()}/sheets-assessment-road/${id}`,

    deleteSheetsAssessmentRoad: (id) => `${apiRwUrl()}/sheets-assessment-road/${id}`,

    roadWorksColors: () => `${apiRwUrl()}/road-work/colors`,

    loadShowcaseOrders: () => `${apiRwUrl()}/showcase/orders`,

    loadShowcaseOrdersReport: () => `${apiRwUrl()}/showcase/orders/report`,

    loadRoadWorkReportOrders: () => `${apiRwUrl()}/report/orders`,

    loadOrganizationsByOrderId: (id) => `${apiRwUrl()}/order/${id}/organizations`,

};

export default apiUrls;
