import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import * as actions from 'redux/TrafficFlow/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import messages from 'helpers/constants/messages';
import { useStoreFromSelector } from 'helpers/hooks';
import { getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import { PageReportBlock, PageReportModal } from 'components/common/PageReport';
import PageLayout from 'components/layout/PageLayout';
import Loading from 'components/common/Loading';

import Filter from './Filter';
import { paramsWithoutEmpty } from './CommonFilter/helpers';
import { typeOptions } from './helper';
import Graphics from './Graphics';
import Tables from './Tables';

function Analytics() {
    const dispatch = useDispatch();
    const transportFlow = useSelector(trafficFlowSelectors.transportFlow);
    const loadingTransportFlow = useSelector(trafficFlowSelectors.loadingTransportFlow);

    const reportGroups = useStoreFromSelector(actions.loadTransportFlowGroups, trafficFlowSelectors.transportFlowGroups);
    const timeSlot = useStoreFromSelector(actions.loadReportTimeSlot, trafficFlowSelectors.reportTimeSlot);

    const initialState = {
        id_list: [],
        report_type: 'driving_intensity',
        time_slot: 'by_hour',
        start_date: getStartOf('day', getDateWithDuration({ days: -2 })),
        end_date: getEndOf('day'),
        type_id_list: [],
    };

    const[isLoaded, setIsLoaded] = useState(false);
    const[filter, setFilter] = useState(initialState);
    const[disabled, setDisabled] = useState(true);

    const [view, setView] = useState('table');

    useEffect(() => {
        return () => {
            dispatch(actions.clearTransportFlowReport());
            setIsLoaded(false);
        };
    }, []);

    // группа
    const currentGroup = useMemo(() => {
        return reportGroups?.find(({ value }) => value === filter?.report_type) || {};
    }, [filter?.report_type, reportGroups]);

    // тип
    const currentType = useMemo(() => {
        return timeSlot?.find(({ value }) => value === filter?.time_slot) || {};
    }, [filter?.time_slot, timeSlot]);

    const [puids, setPuids] = useState([]); // TODO временное решение см UAPTS-5693
    
    // выбранные пуид
    const currentPuids = useMemo(() => {
        const puidsForRender = filter?.id_list?.length > 0
            ?  filter?.id_list
            : puids;
        return puidsForRender?.reduce((res, item) => ({
            ...res,
            [item?.id]: item,
        }), {});
    }, [filter?.id_list, puids]);

    // опции по типу
    const currentOption = useMemo(() => {
        return typeOptions[currentType?.value] || typeOptions?.default;
    }, [currentType]);

    useEffect(() => {
        // TODO временное решение см UAPTS-5693
        if (Object.keys(currentPuids|| {}).length === 0 &&  Object.keys(transportFlow || {}).length > 0) {
            dispatch(actions.loadList(1,  Object.keys(transportFlow).length, { ids: Object.keys(transportFlow) }, (puid_list) => {
                setPuids(puid_list);
            }));
        }
    }, [currentPuids, transportFlow, dispatch]);

    const renderContent = () => (
        <>
            {loadingTransportFlow && <Loading circular={true}/>}
            {Object.keys(transportFlow || {}).length > 0
                ? (
                    <>
                        {view === 'table'
                            ? (
                                <Tables
                                    data={transportFlow}
                                    currentGroup={currentGroup}
                                    currentType={currentType}
                                    currentPuids={currentPuids}
                                    currentOption={currentOption}
                                />
                            )
                            : (
                                <Graphics
                                    data={transportFlow}
                                    currentGroup={currentGroup}
                                    currentType={currentType}
                                    currentPuids={currentPuids}
                                    currentOption={currentOption}
                                />
                            )
                        }
                    </>
                )
                : (!loadingTransportFlow && isLoaded && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>
    );

    return (
        <PageLayout
            header="Отчет по транспортному потоку"
            filters={
                <Filter
                    initialState={initialState}
                    reportGroups={reportGroups}
                    reportTypes={timeSlot}
                    onLoad={(filter) => {
                        dispatch(actions.clearTransportFlowReport());
                        dispatch(actions.loadTransportFlowReport(paramsWithoutEmpty(filter)));
                        setPuids([]);
                        setFilter(filter);
                    }}
                    showFieldPuid
                    onDisabled={setDisabled}
                    onReset={() => {
                        dispatch(actions.clearTransportFlowReport());
                        setFilter({});
                    }}
                />
            }
            content={renderContent}
            informPanelProps={{
                buttons: (
                    <PageReportModal>
                        <PageReportBlock
                            filter={paramsWithoutEmpty(filter)}
                        />
                    </PageReportModal>
                )
            }}
            actionPanel={
                Object.keys(transportFlow || {}).length > 0
                    ? <FormControl size="small">
                        <RadioGroup
                            row
                            name="row-radio-buttons-group"
                            size="small"
                            value={view}
                            onChange={(_, value) => setView(value)}
                        >
                            <FormControlLabel value="table" control={<Radio/>} label="Таблица" />
                            <FormControlLabel value="graph" control={<Radio/>} label="График" />
                        </RadioGroup>
                    </FormControl>
                    : null
            }
        />
    );
}

export default Analytics;
