import MeteoSettings from '../../pages/Dictionaries/Meteo/Settings';
import Devices from '../../pages/Dictionaries/Meteo/Devices';
import MeteoOutputSettings from '../../pages/Dictionaries/Meteo/OutputSettings/index.tsx';


const routes = [
    {
        path: '/dictionaries/meteo/settings',
        component: MeteoSettings,
        exact: true
    },
    {

        path: '/dictionaries/meteo/devices',
        component: Devices,
        exact: true
    },
    {
        path: '/dictionaries/meteo/output-settings',
        component: MeteoOutputSettings,
        exact: true
    },
];

export default routes;