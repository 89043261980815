import React, { useState, useEffect } from 'react';
import { FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { pick } from 'lodash';
import TrafficFlowMapByRadius from '../../../../../common/Autocomplete/TrafficFlowMapByRadius';


const Item = ({
    value = {},
    onChange,
    onDelete,
    index,
    lat,
    lon,
    selected = [],
}) => {

    const [operator, setOperator] = useState(null);
    const [puid, setPuid] = useState(null);

    useEffect(() => {
        const {
            operator,
            puid,
        } = value || {};
        setOperator(operator);
        setPuid(puid);
    }, [value]);

    const operations = [
        '+',
        '-'
    ];

    const handleChangeOperator = (e) => {
        const value = e.target.value;
        setOperator(value);
    };
    const handleChangePuid = (value) => {
        setPuid(value);
        onChange?.({
            operator,
            puid: pick(value, ['id', 'name', 'lat', 'lon'])
        });
    };
    const handleDelete = () => {
        onDelete?.();
    };

    return (
        <div style={{ display: 'flex', marginBottom: '1rem', alignItems: 'flex-start' }}>

            {index > 0 && (
                <FormControl
                    variant="outlined"
                    size="small"
                    style={{
                        width: '120px',
                        marginRight: '1rem'
                    }}
                >
                    <InputLabel>Оператор</InputLabel>
                    <Select
                        label="Оператор"
                        name="operator"
                        value={operator || ''}
                        onChange={handleChangeOperator}
                        disabled={!!operator}
                    >
                        {operations.map(key => (
                            <MenuItem key={key} value={key}>
                                {key}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            <div style={{ width: '100%' }}>
                <TrafficFlowMapByRadius
                    selected={puid}
                    onChange={handleChangePuid}
                    lat={lat}
                    lon={lon}
                    radius={1000}
                    selectedList={selected}
                    hideClear
                    helperText={!operator && index > 0 ? 'Выберите операцию' : ''}
                    disabled={(!operator && index > 0) || !!puid}
                />
            </div>

            <IconButton
                onClick={handleDelete}
            >
                <ClearIcon/>
            </IconButton>

        </div>
    );
};

export default Item;