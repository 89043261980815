/**
 * Sagas
 * **/
import { all, call, put, takeLatest, takeEvery, take } from 'redux-saga/effects';
import api from './api.methods';
import * as types from './types';
import * as actions from './actions';


function* loadOsrmCarSaga({ payload, callback }) {
    const { coords } = payload;
    const coordsString = coords.map(i => i.join(',')).join(';');
    yield put(actions.loadingOsrmCar(true));
    const request = yield call(api.getOsrmCar, coordsString);
    if (request) {
        callback(request);
        // callback
        //     ? callback(request)
        //     : yield put(actions.loadedOsrmCar(request));
    }
    yield put(actions.loadingOsrmCar(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_OSRM_CAR, loadOsrmCarSaga),
    ]);
}
