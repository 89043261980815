import L from 'leaflet';

import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';

// получить иконку маркера
export const createIconMarker = (icon) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: icon
    });
};

export const getFilter = (filter) => {
    const { checkboxes = [] } = filter;

    const filters = {
        ...filter,
        start_date: filter.start_date ? fullDateTimeWithTimeZone(filter.start_date) : null,
        end_date: filter.end_date ? fullDateTimeWithTimeZone(filter.end_date) : null,
    };

    if (filters.camera_id_list) filters.camera_id_list = filters.camera_id_list.map((item) => item.toString());

    if (filters.checkboxes) delete filters.checkboxes;
    checkboxes?.map((key) => {
        filters[key] = 1;
    });

    return removeEmptyFields(filters, false);
};
