import React, { useState } from 'react';
import {
    FeatureGroup,
    Map,
    Marker,
    ToolTip
} from '../leaflet';
import './style.scss';
import { config } from '../../../config';
import FormButtons, { buttonsTypes } from '../../common/FormButtons';
import titles from '../../../helpers/constants/titles';
import Modal from '../../common/Modal';

// универсальный компонент выбора на карте
const  MapForm = (props) => {
    const {
        latlon: propLatLon = null,//[]
        onChange = () => {},
        onAccept = () => {},
        onClose = () => {},
        title = titles.PUT_MOVE_MARK,
        readOnly = false,
        inModal = false,
        isOpen
    } = props;

    const isPropLatLon = () => {
        const { 0: lat = null, 1: lon = null } = propLatLon || [null, null];
        return lat && lon;
    };

    const [latlon, setLatLon] = useState(isPropLatLon() ? propLatLon : null);
    const [mapCenter] = useState(isPropLatLon() ? propLatLon : config.get('mapCenter'));
    const [mapZoom] = useState(isPropLatLon() ? 15 : 13);

    const handleMapClick = (e) => {
        const { lat, lng } = e.latlng;
        setLatLon([lat, lng]);
        onChange([lat, lng]);
    };

    const handleAccept = () => {
        onAccept(latlon);
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal
            noPadding={true}
            isOpen={isOpen}
            title={title}
            buttons={
                !readOnly
                    ?   <FormButtons
                        inModal={inModal}
                        className="row"
                        buttons={[
                            {
                                ...buttonsTypes.cancel,
                                onClick: () => handleClose()
                            },
                            {
                                ...buttonsTypes.apply,
                                disabled: latlon === null,
                                onClick: () => handleAccept()
                            }
                        ]}
                    />
                    :   <FormButtons
                        inModal={inModal}
                        className="row"
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: () => handleClose()
                            },
                        ]}
                    />
            }
        >
            <div>
                <div className="map-form-wrap">
                    <div className="map-form-wrap__container">
                        <Map
                            center={mapCenter}
                            zoom={mapZoom}
                            //onClick={handleMapClick}
                            {...(!readOnly
                                ? { onClick: handleMapClick }
                                : {})
                            }
                        >
                            <FeatureGroup>
                                {latlon
                                &&  <Marker
                                    latlng={latlon}
                                    {...(!readOnly
                                        ? { onDragEnd: (newLatLng) => {
                                            const { lat, lon } = newLatLng;
                                            setLatLon([lat, lon]);
                                            onChange([lat, lon]);
                                        } }
                                        : {})
                                    }
                                >
                                    {/* выводим подсказку сверху с координатами*/}
                                    <ToolTip
                                        offset={[0, -40]}
                                        permanent={true}
                                        direction="top"
                                    >
                                        <div className="toolTipWrap">
                                            <span className="toolTipTitle">
                                                <strong>Широта: </strong>
                                                {latlon[0]}
                                            </span>
                                            <span className="toolTipTitle">
                                                <strong>Долгота: </strong>
                                                {latlon[1]}
                                            </span>
                                        </div>
                                    </ToolTip>
                                </Marker>
                                }
                            </FeatureGroup>
                        </Map>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default MapForm;
