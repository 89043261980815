import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    inner: {
        display: 'flex', 
        border: '1px solid black',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

const Template = ({ sumCameras, columns, rows }) => {
    const styles = useStyles();

    const wrapper = {
        display: 'grid',
        gridTemplateColumns: `repeat(${columns},calc(100%/${columns}))`,
        gridTemplateRows: `repeat(${rows},calc(100%/${rows}))`,
        width:  `calc(100%/${rows/columns})`,
        height: `calc(100%/${columns/rows})`,
        maxHeight: 400,
        maxWidth: '100%',
        gap: 5,
        margin: 'auto'
    };

    return (
        <div style={wrapper} >
            {Array.from(Array(sumCameras)).map((_, index) => (
                <div className={styles.inner} key={index}>
                    <p>Камера {index+1}</p>
                </div>
            ))}
        </div>
    );
};

export default Template;
