import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import messages from '../../../../helpers/constants/messages';
import useTransportTypes from '../../../../helpers/hooks/useTransportTypes';
import cn from 'classnames';
import { loadRouteTypes } from '../../../../redux/TransportPassenger/actions';

const TransportTypesSelect = ({
    value,
    label,
    onChange,
    required = false,
    name = '',
    className = '',
    error = false,
    storeName = 'transportPassenger',
    keyName = 'types',
    action = loadRouteTypes
}) => {
    const types = useTransportTypes(storeName, keyName, action);

    return (
        <FormControl
            error={error}
            className={cn(className, 'row__item')}
            variant="outlined"
            size="small"
            required={required}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                value={value}
                onChange={onChange}
                name={name}
            >
                {!required && <MenuItem value="">{messages.NOT_CHOSEN}</MenuItem>}
                {types?.length > 0
                    ? types?.map((type) =>
                        <MenuItem key={type.id} value={type.id}>
                            {type.name}
                        </MenuItem>)
                    : <MenuItem>{messages.NO_DATA}</MenuItem>
                }
            </Select>
        </FormControl>
    );
};

export default TransportTypesSelect;
