const replaceForWsInRedux = (storeData, newData, findKey = 'id') => {
    if (newData?.[findKey]) {
        const index = storeData?.findIndex((item) => item?.[findKey] === newData?.[findKey]);

        // новый
        const isNew = newData?.created_at === newData?.updated_at;
        // удален
        const isDelete = !!newData?.deleted_at;

        if (index >= 0) {
            // запись удалена
            if (isDelete) {
                return [
                    ...storeData?.slice(0, index),
                    ...storeData?.slice(index + 1)
                ];
            } else {
                // изменение
                return [
                    ...storeData?.slice(0, index),
                    {
                        ...storeData?.[index],
                        ...newData
                    },
                    ...storeData.slice(index + 1)
                ];
            }
        } else if (isNew) {
            // добавление
            return [
                newData,
                ...storeData,
            ];
        }

        // ничего
        return [
            ...storeData,
        ];
    }
    return storeData;
};

export default replaceForWsInRedux;