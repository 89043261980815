import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiVideoAnalyticsMethods = {
    getCameras: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getCameras(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },
    editCamera: async (id, data) => {
        const response = await instance.put(apiUrls.editCamera(id), data);
        return response?.data;
    },
    createCamera: async (data) => {
        const response = await instance.post(apiUrls.createCamera(), data);
        return response?.data;
    },
    deleteCamera: async (id) => {
        const response = await instance.delete(apiUrls.deleteCamera(id));
        return response?.data;
    },
    getCameraSettings: async (page, limit) => {
        const response = await instance.get(apiUrls.getCameraSettings(), {
            params: { page, limit }
        });
        return response?.data;
    },
    editCameraSettings: async (id, params) => {
        const response = await instance.put(apiUrls.editCameraSettings(id), params);
        return response.data;
    },
    getInfoList: async () => {
        const response = await instance.get(apiUrls.getInfoList());
        return response.data;
    },
    getEvents: async (config = {}) => {
        const response = await instance.post(apiUrls.getEvents(), config);
        return response?.data;
    },
    getCameraViolations: async () => {
        const response = await instance.get(apiUrls.getCameraViolations());
        return response?.data;
    },

    getReportGroups: async () => {
        const response = await instance.get(apiUrls.getReportGroups());
        return response.data;
    },
    getReportTypes: async () => {
        const response = await instance.get(apiUrls.getReportTypes());
        return response.data;
    },
    getReport: async (config = {}) => {
        const response = await instance.get(apiUrls.getReport(), config);
        return response.data;
    },
    getInOutCamerasReport: async (params) => {
        const response = await instance.post(apiUrls.getInOutCamerasReport(), params);
        return response.data;
    },
    getForPolygon: async (params) => {
        const response = await instance.post(
            apiUrls.getPolygon(),
            params,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
        return response?.data?.data;
    },
    getDeviceList: async (params) => {
        const response = await instance.get(apiUrls.getDeviceList(), {
            params
        });
        return response?.data;
    },
    getEventsPopup: async (params = {}) => {
        const response = await instance.post(apiUrls.getEventsPopup(), params);
        return response?.data;
    },
    getViolations: async () => {
        const response = await instance.get(apiUrls.getViolations());
        return response?.data;
    },
    getStatuses: async () => {
        const response = await instance.get(apiUrls.getStatuses());
        return response?.data;
    },
    getVideoAnalyticGrzPointsGroup: async (params) => {
        const response = await instance.get(apiUrls.getVideoAnalyticGrzPointsGroup(), {
            params
        });
        return response.data;
    },

    downloadEvents: async (config) => {
        const response = await instance.get(apiUrls.downloadEvents(), config);
        return response;
    },

    getEventsFilters: async () => {
        const response = await instance.get(apiUrls.getEventsFilters());
        return response.data;
    },

    getCameraObjects: async ({ page, limit, params }) => {
        const response = await instance.get(apiUrls.getCameraObjects(), {
            params: { page, limit, ...params }
        });
        return response.data;
    },

    getPassengerTraffic: async (params) => {
        const response = await instance.get(apiUrls.getPassengerTraffic(), { params });
        return response.data;
    },

    getPassengerTrafficTypes: async () => {
        const response = await instance.get(apiUrls.getPassengerTrafficTypes());
        return response.data;
    },

    loadCamerasByRadius: async (params) => {
        const response = await instance.post(apiUrls.loadCamerasByRadius(), params);
        return response?.data;
    },

    loadShowcase: async () => {
        const response = await instance.post(apiUrls.loadShowcase());
        return response?.data;
    },
};

export default ApiVideoAnalyticsMethods;
