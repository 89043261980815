import { useSelector } from 'react-redux';
import messages from '../../../../../helpers/constants/messages';
import { useStoreProp } from '../../../../../helpers/hooks';
import {
    loadVehicleTelemetryStatistic,
    loadTelemetryStatistic
} from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import Loading from '../../../../common/Loading';

const TelemetryStatistics = () => {

    const loadingTransportSettings = useSelector(transportPassengerSelectors.loadingTransportSettings);

    const telemetryVehicleStatistic = useStoreProp(
        loadVehicleTelemetryStatistic,
        'transportPassenger',
        'telemetryVehicleStatistic'
    );
    const telemetryStatistic = useStoreProp(
        loadTelemetryStatistic,
        'transportPassenger',
        'telemetryStatistic'
    );

    return (
        <div>
            <h1>Телематическая платформа</h1>
            <h2>Сводная информация о статистических показателях работы подсистемы телеметрии</h2>
            <div style={{ paddingLeft: 15 }} className="filter">
                <h3>Количество телеметрии:</h3>
                {loadingTransportSettings && <Loading />}
                { Object.keys(telemetryStatistic).length > 0
                    ? (<div style={{ paddingLeft: 15 }}>
                        {Object.entries(telemetryStatistic).map(([key, value]) =>
                            <p key={key}>
                                <b>{key}:</b> {value}
                            </p>
                        )}
                    </div>)
                    : !loadingTransportSettings && <div>{messages.NO_DATA}</div>
                }
            </div>
            <div style={{ paddingLeft: 15 }} className="filter">
                <h3>Количество ТС, по которым получена телеметрия:</h3>
                {loadingTransportSettings && <Loading />}
                {Object.keys(telemetryVehicleStatistic).length > 0
                    ? (<div style={{ paddingLeft: 15 }}>
                        {Object.entries(telemetryVehicleStatistic).map(([key, value]) =>
                            <p key={key}>
                                <b>{key}:</b> {value}
                            </p>
                        )}
                    </div>)
                    : !loadingTransportSettings && <div>{messages.NO_DATA}</div>
                }
            </div>
        </div>
    );
};

export default TelemetryStatistics;