import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadBoardRotationMessages, loadBoardStatuses } from 'redux/Boards/actions';
import { boardsSelectors } from 'redux/Boards';
import { useStoreFromSelector } from 'helpers/hooks';

import { getCategoryIcon } from '../helper';

import Information from './Information';


const PopUp = (props) => {
    const { id: external_id, history, permissions, readOnly = false } = props;
    const dispatch = useDispatch();

    const statuses = useStoreFromSelector(loadBoardStatuses, boardsSelectors.boardStatuses);

    const polygon = useSelector(boardsSelectors.polygon);
    const boardRotationMessages = useSelector(boardsSelectors.boardRotationMessages);
    const loadingBoardRotationMessages = useSelector(boardsSelectors.loadingBoardRotationMessages);
    const data = polygon.find(({ id }) => external_id === id) || {};

    // const dataMemo = useMemo(() => {
    //     return polygon.find(({ id }) => external_id === id) || {};
    // }, [external_id, polygon]);

    useEffect(() => {
        if (external_id && !boardRotationMessages[external_id]) {
            dispatch(loadBoardRotationMessages(external_id));
        }
    },[external_id]);

    const status = data?.status || 0;
    const { name } = data;

    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: statuses?.find(i => i.id === status)?.color || '' }}>
                <div className="map-popup_header_">
                    <div className="img">
                        <i style={{ fontSize: '1.5rem' }} className={getCategoryIcon(data?.category_id)}/>
                    </div>
                    <span>
                        {name}
                    </span>
                </div>
            </div>
            <div className="map-popup_body">
                <Information
                    data={data}
                    history={history}
                    messages={boardRotationMessages[external_id] || []}
                    loadingMessages={loadingBoardRotationMessages}
                    permissions={permissions}
                    readOnly={readOnly}
                />
            </div>
        </div>
    );
};

export default PopUp;
