import { useState } from 'react';
import type { ChangeEvent, SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { 
    Autocomplete,  
    FormControl,  
    InputLabel,  
    MenuItem,  
    Select,  
    TextField 
} from '@mui/material';

import { 
    createMaterialExport,
    editMaterialExport, 
    loadMaterialExportProtocols, 
    loadMaterialExportTypes 
} from 'redux/DorisControl/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import { fullDateTimeWithTimeZone, getConfigForMinMaxDate } from 'helpers/date.config';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';
import DateRange from 'components/common/Dates/DateRange';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

import type { SelectChangeEvent } from '@mui/material';
import type { ModalFormProps } from './types';

const ModalForm = ({ isOpen, onClose, item, isNew, loadList }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const types = useStoreProp(loadMaterialExportTypes, 'dorisControl', 'materialExportTypes');
    const protocols = useStoreProp(loadMaterialExportProtocols, 'dorisControl', 'materialExportProtocols');

    const initState = {
        name: item?.name || '',
        destination: item?.destination || '',
        types: item?.types || [] as string[],
        complexes: item?.complexes || [],
        complex_id_list: item?.complex_id_list || [],
        enable: item?.enable || false,
        is_url_wsdl: item?.is_url_wsdl || false,
        start_date: item?.start_date || null,
        end_date: item?.end_date || null,
        protocol: item?.protocol || '',
    };

    const [data, setData] = useState(initState);

    const handleChange = (e: SelectChangeEvent | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
        validation.deleteKey(name);
    };

    const handleChangeDate = (name: string, value: Date | null) => {
        setData({
            ...data,
            [name]: fullDateTimeWithTimeZone(value)
        });
        validation.deleteKey(name);
    };

    const autoCompleteChange = (e: SyntheticEvent | KeyboardEvent, types: string[]) => {
        const keyboardEvent = e as KeyboardEvent;
        if (keyboardEvent?.key !== 'Enter') {
            setData({ ...data, types });
            validation.deleteKey('types');
        }
    };

    const handleSave = () => {
        const prepareData = {
            ...data,
            complex_id_list: data.complexes.map(({ id }: any) => Number(id)),
            types: data.types.map((id) => Number(id)),
            protocol: Number(data.protocol)
        };

        const callback = () => {
            onClose();
            loadList();
        };

        isNew
            ? dispatch(createMaterialExport(prepareData, callback))
            : dispatch(editMaterialExport(item?.id, prepareData, callback));
    };

    const isDisabled = !data.destination
        || !data.types.length
        || !data.complexes.length
        || !data.start_date
        || !data.end_date;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? 'Создание экспорта' : 'Редактирование экспорта'}
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <FormInfoWrapper
                    error={validation.isKey('destination')}
                    helperText={validation.get('destination')}
                    className="block"
                >
                    <TextField
                        label={'Назначение'}
                        className="block"
                        variant="outlined"
                        required
                        size="small"
                        value={data.destination}
                        name="destination"
                        onChange={handleChange}
                        error={!data.destination || validation.isKey('destination')}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper
                    className="block"
                    error={!data.types.length || validation.isKey('types')}
                    helperText={validation.get('types')}
                >
                    <Autocomplete
                        multiple
                        value={data.types}
                        noOptionsText={titles.NO_RESULTS}
                        isOptionEqualToValue={(option, value) => Number(option) === Number(value)}
                        options={Object.keys(types)}
                        filterSelectedOptions={true}
                        getOptionLabel={(option) => types[option] || option}
                        onChange={autoCompleteChange}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Тип экспорта"
                                size="small"
                                variant="outlined"
                                required
                                error={!data.types.length || validation.isKey('types')}
                            />
                        )}
                    />
                </FormInfoWrapper>
                <SelectComplex
                    className="block"
                    selected={data.complexes}
                    onChange={(complexes: any) => setData({ ...data, complexes })}
                    multiple
                    required
                    error={!data.complexes.length || validation.isKey('complex_id_list')}
                    helperText={validation.get('complex_id_list')} 
                />
                <FormControl 
                    size="small" 
                    variant="outlined" 
                    className="block"
                    required
                    error={!data.protocol}
                >
                    <InputLabel>Протокол</InputLabel>
                    <Select
                        value={data.protocol}
                        name="protocol"
                        onChange={handleChange}
                        label="Протокол"
                    >
                        {Object.keys(protocols).map((key) => (
                            <MenuItem key={key} value={key}>{protocols[key]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <DateRange
                    valueStartDate={data.start_date}
                    valueEndDate={data.end_date}
                    handleDate={(value) => handleChangeDate('start_date', value)}
                    handleEndDate={(value) => handleChangeDate('end_date', value)}
                    className="block"
                    maxDate={getConfigForMinMaxDate()}
                    maxTime={getConfigForMinMaxDate()}
                    helperText={validation.get('end_date')} 
                    required
                />
            </form>
        </Modal>
    );
};

export default ModalForm;
