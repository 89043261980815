import { useState } from 'react';
import { useDispatch } from 'react-redux';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Modal from '../../../../common/Modal';
import { Autocomplete, TextField, } from '@mui/material';
import _ from 'lodash';
import titles from '../../../../../helpers/constants/titles';
import { useValidation } from '../../../../../helpers/hooks';
import { createEventObjects, editEventObjects } from '../../../../../redux/SituationalPlans/actions';
import MapGeoJson from '../../../../common/Location/MapGeoJson';
import LoadAddressByCoords from '../../../../common/Location/LoadAddressByCoords';
import SelectRoutes from '../../../../common/Autocomplete/PassengerTransport/Routes';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import SelectEvents from '../../../../common/Autocomplete/DorisReact/Events';
import KeyWords from '../../../../common/Autocomplete/DorisReact/KeyWords';

const ModalForm = ({
    isOpen,
    onClose,
    isNew = false,
    item = {},
    types,
    reloadList = () => {}
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const typeObj = types?.map(key => ({
        id: key.id,
        label: key.name,
    }));

    const initialState = {
        lat: item.lat || '',
        lon: item.lon || '',
        name: item?.name || '',
        // event_id: item?.event || {},
        type_id: isNew ? {} : { id: item?.type_id, label: item?.type_name },
        address: item?.address || '',
        geometry: item?.geometry || {},
        keyword_id_list: item?.keyword_list || [],
        route_id: isNew ? { id: '', name: '' } : { id: item?.route?.id, name: item?.route?.name },
        route: item?.route || {},
    };

    const [data, setData] = useState(initialState);

    const handleChangeRoute = (route) => {
        setData({
            ...data,
            route,
            route_id: { id: route.id, name: route.name }

        });
    };

    const onAcceptModal = () => {
        const newData = {
            ...data,
        };
        delete newData.lat;
        delete newData.lon;

        const transformationDataForSend = removeEmptyFields(_.reduce(
            newData,
            (res, el, key) => {
                if (el?.hasOwnProperty('id') && el?.id && !Array.isArray(el) && key !== 'route') {
                    res[key] = el.id;
                } else if (Array.isArray(el) && el?.length > 0) {
                    res[key] = el.map(item => item.id);
                } else if (data?.type_id?.id !== 1 && (key === 'route' || key === 'route_id')) {
                    return res;
                } else {
                    res[key] = el;
                }
                return res;
            },
            {}
        ));

        const callback = () => {
            reloadList();
            onClose();
        };

        isNew
            ? dispatch(createEventObjects(transformationDataForSend, callback))
            : dispatch(editEventObjects(item?.id, transformationDataForSend, callback));
    };

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const handleChangeType = (_, newValue) => {
        setData({
            ...data,
            type_id: newValue,
            route_id: { id: '', name: '' },
            route: {}
        });
    };

    const onChangeGeoJson = (params) => {
        const { address, address_text, ...pr } = params;
        if (address_text) pr.address = address_text;

        setData({
            ...data,
            ...pr
        });
    };

    const isDisabled = !data?.name
        // || !data?.event_id?.id
        || !data?.type_id?.id
        || !data.address
        || (data?.type_id?.id === 1 && !data?.route_id?.id);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title={isNew ? titles.CREATE : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <TextField
                        label={titles.NAME}
                        size="small"
                        value={data.name}
                        variant="outlined"
                        name="name"
                        type="text"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        required
                        error={!data.name || validation.isKey('name')}
                        helperText={validation.get('name')}
                    />
                </div>
                {/*<div className="block">*/}
                {/*    <SelectEvents*/}
                {/*        required*/}
                {/*        onChange={(value) => setData({ ...data, event_id: value })}*/}
                {/*        selected={data.event_id}*/}
                {/*        error={_.isEmpty(data.event_id) || validation.isKey('event_id')}*/}
                {/*        helperText={validation.get('event_id')}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="block">
                    <Autocomplete
                        value={data.type_id}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={typeObj}
                        filterSelectedOptions={true}
                        getOptionLabel={(option) => option.label || ''}
                        onChange={handleChangeType}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label="Тип *"
                                error={JSON.stringify(data.type_id) === '{}' || Boolean(data.type_id) === false || validation.isKey('type_id')}
                                helperText={validation.get('type_id')}
                            />
                        )}
                    />
                </div>
                <div className="block">
                    <KeyWords
                        multiple
                        selected={data.keyword_id_list}
                        onChange={(value) => setData({
                            ...data,
                            keyword_id_list: value
                        })}
                    />
                </div>
                {data?.type_id?.id === 1 && (
                    <div className="block">
                        <SelectRoutes
                            selected={data.route}
                            onChange={handleChangeRoute}
                            required
                            error={_.isEmpty(data.route)}
                            filter={{ is_event: true }}

                        />
                    </div>
                )}
                <div className="block">
                    <TextField
                        required
                        label={titles.ADDRESS}
                        size="small"
                        value={data.address}
                        error={!data.address}
                        variant="outlined"
                        name="address"
                        type="text"
                        disabled
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                    />
                </div>
                <LoadAddressByCoords
                    {...data}
                    onChange={onChangeGeoJson}
                    isLoadOnLatLon={true}
                    isLoadOnChange={true}
                >
                    <MapGeoJson
                        // geometry={data.geometry}
                        // onChange={onChangeGeoJson}
                        circle={false}
                        rectangle={false}
                        polyline={true}
                        polygon={true}
                        marker={true}
                        returnLatLon={true}
                    />
                </LoadAddressByCoords>
            </form>
        </Modal>
    );
};

export default ModalForm;