import React, { useEffect } from 'react';
import {
    Grid,
    LinearProgress,
} from '@mui/material';
import rwLayerConfig from '../../../MapComponents/Layers/RoadWorks/config';
import CardWidget from '../../../common/Showcase/CardWidget';
import {
    // loadShowcase,
    // clearShowcase,

    loadShowcaseOrders,
    clearShowcaseOrders,

    loadShowcaseOrdersReport,
    clearShowcaseOrdersReport,
} from '../../../../redux/RoadWorks/actions';
import { roadworksSelectors } from '../../../../redux/RoadWorks';
import { useDispatch, useSelector } from 'react-redux';
import TypeComponent from '../../../common/Showcase/TypeComponent';
// import Level from '../../../common/Showcase/TypeComponent/components/Level';
// import LengthRoad from '../HeatGraphEfficiency/LengthRoad';

// страница
const Page = () => {
    const dispatch = useDispatch();

    const showcaseOrders = useSelector(roadworksSelectors.showcaseOrders);
    const loadingShowcaseOrders = useSelector(roadworksSelectors.loadingShowcaseOrders);

    const showcaseOrdersReport = useSelector(roadworksSelectors.showcaseOrdersReport);
    const loadingShowcaseOrdersReport = useSelector(roadworksSelectors.loadingShowcaseOrdersReport);

    useEffect(() => {
        dispatch(loadShowcaseOrders());
        dispatch(loadShowcaseOrdersReport());

        return () =>  {
            dispatch(clearShowcaseOrders());
            dispatch(clearShowcaseOrdersReport());
        };
    }, [dispatch]);

    // если потребуется
    // const listL = [
    //     {
    //         title: 'Протяженность участков дорожной сети, обслуживающихся по заявкам на дорожные работы',
    //         value: '7',
    //         unit: 'км',
    //     },
    //     {
    //         title: 'Объем',
    //         value: '453',
    //         unit: 'м',
    //         unitSup: '&sup3;',
    //         isBig: true
    //     }
    // ];

    return (
        <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={12} className="showcase_grid_flex-column">
                {(loadingShowcaseOrders || loadingShowcaseOrdersReport) && <LinearProgress/>}

                {Object.keys(showcaseOrders) && (
                    <CardWidget
                        icon={rwLayerConfig.layerIcon}
                        title={showcaseOrders.name}
                        marginBottom
                        fillOthers
                        small
                    >
                        <TypeComponent
                            flexStyle={true}
                            {...showcaseOrders}
                        />
                    </CardWidget>
                )}

                {Object.keys(showcaseOrdersReport) && (
                    <CardWidget
                        icon={rwLayerConfig.layerIcon}
                        title={showcaseOrdersReport.name}
                        fillOthers
                        small
                    >
                        <TypeComponent
                            flexStyle={true}
                            {...showcaseOrdersReport}
                        />
                    </CardWidget>
                )}
            </Grid>
        </Grid>
    );

    // return (
    //     <Grid container spacing={2} sx={{ height: '100%' }}>
    //         <Grid item xs={7} >
    //             {graph?.map((item, index) => (
    //                 <CardWidget
    //                     key={index}
    //                     icon={RWLayerConfig.layerIcon}
    //                     title={item.name}
    //                     marginBottom={index < graph.length - 1}
    //                     fillOthers
    //                     // fill
    //                 >
    //                     <TypeComponent
    //                         flexStyle={true}
    //                         // flexStyle={item?.type === 7 ? true : false }
    //                         {...item}
    //                     />
    //                 </CardWidget>
    //             ))}
    //         </Grid>
    //
    //         <Grid item xs={5} >
    //             {listL?.map( (item, index) => (
    //                 <Level
    //                     key={index}
    //                     {...item}
    //                     {...(index < listL.length ? { marginBottom: true } : {})}
    //                 />
    //             ))}
    //         </Grid>
    //     </Grid>
    // );
};

export default Page;