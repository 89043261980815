import { useState } from 'react';
import { ListItem, ListItemText, } from '@mui/material';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import { deleteUnit } from 'redux/RoadWorks/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import EditModal from './EditModal';

function Item({ item }) {
    const dispatch = useDispatch();

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const toggleDeleteOpen = (e) => {
        e && e.stopPropagation();
        setDeleteModalOpen(!deleteModalOpen);
    };

    const toggleOpen = (e) => {
        e && e.stopPropagation();
        setModalOpen(!modalOpen);
    };

    const handleDelete = () => {
        dispatch(deleteUnit(item.id));
        toggleDeleteOpen();
    };

    return (
        <div className="list_item">
            {modalOpen
                && <EditModal
                    isNew={false}
                    toggleOpen={toggleOpen}
                    open={modalOpen}
                    data={item}
                />
            }
            <ListItem>
                <ListItemText>
                    <div>
                        <strong>{item.id}, </strong>
                        <span>{item.long_name}, {item.short_name}</span>
                    </div>
                </ListItemText>
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: toggleOpen,
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: toggleDeleteOpen,
                        },
                    ]}
                    noMarginLeft
                    justButton
                />

            </ListItem>
            {deleteModalOpen && (
                <ConfirmDelete
                    open={deleteModalOpen}
                    message={messages.CONFIRM_DELETE}
                    onClose={toggleDeleteOpen}
                    onSuccess={handleDelete}
                />
            )}
        </div>
    );
}

export default Item;
