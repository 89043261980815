import React, { useMemo } from 'react';
import { groupBy, union } from 'lodash';

import { loadReportStatisticType, loadReportTimeSlot } from 'redux/TrafficFlow/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import { useStoreFromSelector } from 'helpers/hooks';
import {
    getDateToFormat,
} from 'helpers/date.config';
import getColor from 'helpers/getColor';
import getMonth from 'helpers/getMonth';

import Line from './Graphics/Line';

function Page({ data, filter }) {
    const reportGroups = useStoreFromSelector(loadReportStatisticType, trafficFlowSelectors.reportStatisticType);
    const reportTypes = useStoreFromSelector(loadReportTimeSlot, trafficFlowSelectors.reportTimeSlot);

    // группа
    const currentGroup = useMemo(() => {
        return reportGroups?.find(({ value }) => value === filter?.report_type) || {};
    }, [filter?.report_type, reportGroups]);

    // тип
    const currentType = useMemo(() => {
        return reportTypes?.find(({ value }) => value === filter?.time_slot) || {};
    }, [filter?.time_slot, reportTypes]);

    // {date: "2022-11-02 00:00:00", fact_delay: 0}
    // {date: "2022-11-01 23:00:00", road_load: 0}
    // {date: "2022-11-01 23:00:00", length_road_overload_mode: 0}

    // подпись
    const getLabel = (item) => {
        switch (filter?.time_slot) {
            case 'by_day':
                return getDateToFormat(item?.date, 'dd.MM.yyyy');
            case 'by_hour':
                return getDateToFormat(item?.date, 'HH:mm dd.MM.yyyy');
            case 'by_month':
                return getDateToFormat(item?.date, 'MM.yyyy');

            default : return '';
        }
    };

    const getThresHold = (item) => {
        return item?.threshold || 0;
    };

    // значение
    const getValue = (item) => item?.value;
    // const getValue = (item) => item?.fact_delay ?? item?.road_load ?? item?.length_road_overload_mode;

    // групприруем данные по
    const getGroup = (item) => {
        switch (filter?.time_slot) {
            case 'by_day':
                return getDateToFormat(item?.date, 'MM.yyyy');
            case 'by_hour':
                return getDateToFormat(item?.date, 'dd.MM.yyyy');
            case 'by_month':
                return getDateToFormat(item?.date, 'yyyy');

            default : return '';
        }
    };

    // подпись для группированного графика
    const getLabelGroup = (item) => {
        switch (filter?.time_slot) {
            case 'by_day':
                return getDateToFormat(item?.date, 'dd');
            case 'by_hour':
                return getDateToFormat(item?.date, 'HH:mm');
            case 'by_month':
                return getDateToFormat(item?.date, 'MM');

            default : return '';
        }
    };

    // разделитель для группированного графика
    const getUnit = () => {
        switch (filter?.time_slot) {
            case 'by_day':
                return '.';
            case 'by_hour':
                return ' ';
            case 'by_month':
                return ' ';
            default : return '';
        }
    };

    const dateList = useMemo(() => {
        // графики
        const graphList = [];

        // 1 график
        const title = `${currentGroup?.description} (${currentType?.description} - ${currentType?.hint})`;
        const threshold = getThresHold(data?.[0] || {});
        const graphic1 = {
            title: title,
            labels: data?.map(item => getLabel(item)),
            callbacks: {
                title: ({ 0: context }) => `${context?.dataset?.label || context?.label}`,
                label: (context) => `${context?.formattedValue} ${currentGroup?.unit || ''}`,
            },
            scales: {
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: currentGroup?.unit || ''
                    }
                },
            },
            isLegend: false,
            datasets: [
                {
                    backgroundColor: '#00ff00',
                    borderColor: '#00ff00',
                    data: data?.map(item => getValue(item)),
                }
            ]
        };
        // полоса "Критическое значение"
        if (threshold) {
            graphic1?.datasets?.push({
                label: 'Критическое значение',
                backgroundColor: '#ff0033',
                borderColor: '#ff0033',
                data: data?.map(() => threshold),
                pointRadius: 0,
                pointHoverRadius: 5,
                pointHitRadius: 5,
                borderDash: [10, 10],
                // borderDashOffset: 0,
            });
        }
        graphList.push(graphic1);

        // группируем данные
        const groupedData = groupBy(data, item => getGroup(item));
        // всевозможные labels
        const labels = Object
            .values(groupedData)
            .reduce((res, item) =>
                union(res, item?.map(i => getLabelGroup(i)))
            , [])
            .sort();

        // 2 график
        graphList.push({
            title: 'Диаграмма сравнений по каждому показателю за один и тот же период',
            labels: filter?.time_slot === 'by_month' ? labels?.map(i => getMonth(parseInt(i) - 1)) : labels,
            callbacks: {
                title: ({ 0: context }) => `${context?.label}${context?.dataset?.unit}${context?.dataset?.label}`,
                label: (context) => `${context?.formattedValue} ${currentGroup?.unit || ''}`,
                //label: (context) => ` ${context?.label}${context?.dataset?.unit}${context?.dataset?.label}: ${context?.formattedValue}`;
            },
            scales: {
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: currentGroup?.unit || ''
                    }
                },
            },
            datasets: Object // проходим по сгруппированным датам
                ?.keys(groupedData)
                ?.map(keyData => ({
                    label: keyData,
                    backgroundColor: getColor(keyData),
                    borderColor: getColor(keyData),
                    type: 'bar',
                    data: labels
                        ?.map(dataLabel => getValue(
                            groupedData[keyData]
                                ?.find((item) => getLabelGroup(item) === dataLabel)
                        ) || 0),
                    unit: getUnit(),
                }))
        });

        return graphList;
    }, [filter, data, reportGroups, reportTypes, currentGroup, currentType]);

    return (
        <>
            {dateList?.map((item, index) => {
                return (
                    <div key={index} style={{ marginBottom: '1rem' }}>
                        <h2>{item?.title}</h2>

                        <Line
                            isLegend={item?.isLegend ?? true}
                            labels={item?.labels}
                            datasets={item?.datasets}
                            callbacks={item?.callbacks || {}}
                            scales={item?.scales || {}}
                        />
                    </div>
                );
            })}
        </>
    );
}

export default Page;
