import { useState } from 'react';
import messages from '../../../../../helpers/constants/messages/common';
import useNewMap from '../../../../../helpers/hooks/Incidents/useNewMap';
import { config } from '../../../../../config';
import HeatLayer from '../../../../MapComponents/leaflet/components/HeatLayer';
import { Map, MapControl } from '../../../../MapComponents/leaflet';
import Filter from './Filter';
import PageLayout from '../../../../layout/PageLayout';


function HeatMap() {
    const dataProvider = useNewMap();
    const [loaded, setLoaded] = useState(false);

    const renderContent = () => {
        return dataProvider.isData
            ? (
                <div style={{ display: 'contents', minHeight: '400px' }}>
                    <Map
                        center={config.get('mapCenter')}
                    >
                        <MapControl>
                            <HeatLayer
                                json={dataProvider.data}
                            />
                        </MapControl>
                    </Map>
                </div>
            )
            : (loaded && !dataProvider.loading && <div>{messages.NO_DATA_FOR_PERIOD}</div>)
        ;
    };

    return (
        <PageLayout
            header="Тепловая карта"
            filters={
                <Filter
                    onFilter={(filter) => {
                        dataProvider.load(filter);
                        setLoaded(true);
                    }}
                    onReset={() => {
                        dataProvider.clear();
                        setLoaded(false);
                    }}
                />
            }
            content={renderContent}
            loading={dataProvider.loading}
        />
    );
}

export default HeatMap;