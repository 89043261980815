import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemText } from '@mui/material';

import messages from 'helpers/constants/messages';
import { deleteCameraTemplate } from 'redux/VideoCameras/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';

const Item = ({ item, loadList }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return (
        <>
            <ListItem className="list_item" >
                <ListItemText>
                    <span><strong>Название шаблона:</strong>&nbsp;{item.name || messages.NO_DATA}</span>
                </ListItemText>
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setOpenEditModal(true),
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setOpenDeleteModal(true),
                        },
                    ]}
                    noMarginLeft
                    justButton
                />
            </ListItem>
            {openEditModal
                && <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    loadList={loadList}
                />}
            {openDeleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteModal}
                    onSuccess={() => {
                        dispatch(deleteCameraTemplate(item.id, () => {
                            setOpenDeleteModal(false);
                            loadList(true);
                        }));
                    }}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
