import { IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';

import { loadActiveFrame } from 'redux/VideoCameras/actions';

const useStyles = makeStyles({
    frame_block: {
        padding: 10,
        position: 'absolute',
        zIndex: 1001,
        top: 60,
        right: 5,
        width: 340,
        maxHeight: 680,
        backgroundColor: 'gray',
        overflow: 'auto'
    },
    frameItem: {
        width: 300,
        margin: 'auto',
        marginBottom: 15
    },
    frameButtons: {
        textAlign: 'right'
    }
});

const CameraFrame = () => {
    const dispatch = useDispatch();
    const { activeFrames } = useSelector(({ videoCameras }) => videoCameras);
    
    const closeFrame = (id) => {
        const result = activeFrames?.filter(item => item.id !== id );
        dispatch(loadActiveFrame(result));
    };

    const filterActive = activeFrames?.filter( item => item.link);
   
    const openFrame = (link) => {
        window.open(link);
    };

    const classes = useStyles();
    return (<>
        {filterActive.length > 0
        && <div className={classes.frame_block}>
            {filterActive.map(item => (
                <div key={item.id} className={classes.frameItem}>
                    <div className={classes.frameButtons}>
                        <Tooltip title="Показать в отдельном окне">
                            <IconButton onClick={() => openFrame(item.link)} size="small">
                                <i className="far fa-external-link-alt" style={{ color: 'white' }}></i>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Закрыть">
                            <IconButton onClick={() => closeFrame(item.id)} size="small">
                                <CloseIcon style={{ color: 'white' }}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <iframe src={item.link} align="middle" title="camera">
                    </iframe>
                    {/* <video controls autoPlay>
                        <source src={item.link} />
                    </video> */}
                </div>
            ))}
        </div>
        }
    </>
    );
};

export default CameraFrame;
