import Filter from './Filter';
import PageLayout from '../../../../layout/PageLayout';

const MonthlyReportCPVF = () => {
    return (<PageLayout
        header={'Отчет по работе КВФВ по балансодержателям'}
        filters={<Filter />}
    />);
};

export default MonthlyReportCPVF;