// todo новая функция
const iconCreateFunction = (createIconFunction) => (cluster) => {
    // количество маркеров
    // const childCount = cluster.getChildCount();

    // получить маркера кластера
    const clustersMarkers = cluster.getAllChildMarkers();
    // const markersCount = clustersMarkers.length;

    // маркеры с аттрибутами
    const clustersMarkersAttr = clustersMarkers.map((item) => item.getAttribution() || {});

    // количество дтп
    const countDtp = clustersMarkersAttr.reduce((r, { id }) => r.includes(id)? r : [...r, id], []).length;

    // разбиваем по цветам и сколько раз встречается
    const colorCount = clustersMarkersAttr.reduce((res, attribution) => {
        const { color, count } = attribution;

        if (attribution.color) {
            return {
                ...res,
                [color]: (res[color] || 0) + (count || 0),
            };
        }

        return res;
    }, {});

    // общее количество
    const countForPercent = Object.values(colorCount).reduce((sum, count) => sum + count, 0);

    // заполнение по процентному соотношению
    const percentFill = Object.keys(colorCount).reduce((res, color) => {
        if (colorCount[color]) {
            return {
                ...res,
                [color]: Math.round((colorCount[color] / countForPercent) * 100)
            };
        }
        return res;
    }, {});

    // генерация иконки
    return createIconFunction(countDtp, percentFill);
};

export default iconCreateFunction;
