import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import DateRange from '../../../../common/Dates/DateRange';
import { loadDadataAddressList } from '../../../../../redux/Dadata/actions';
import titles from '../../../../../helpers/constants/titles';
import { Autocomplete } from '@mui/material';
import {
    loadAppealType,
    loadAppealStatus,
} from '../../../../../redux/TransportPassenger/actions';
import styles from './appeal.module.scss';
import useStoreProp from '../../../../../helpers/hooks/useStoreProp';
import SelectRoutes from '../../../../common/Autocomplete/PassengerTransport/Routes';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';

const Filters = ({
    initialFilter,
    filter,
    setFilter,
    onSearch
}) => {
    const dispatch = useDispatch();

    const { addressList, } = useSelector(({ dadata }) => dadata);
    const appealType = useStoreProp(loadAppealType, 'transportPassenger', 'appealType');
    const appealStatus = useStoreProp(loadAppealStatus, 'transportPassenger', 'appealStatus');

    const [isDisabled, setIsDisabled] = useState(false);
    const isDisabledHandler = (value) => { setIsDisabled(value); };

    const handleChange = (name, value) => {
        setFilter(prev => ({
            ...prev,
            [name]: value ? value : ''
        }));
    };

    const searchCity = ({ target: { value } }) => {
        if(value) {
            dispatch(loadDadataAddressList(10, value));
        }
    };

    const resetFilter = (needRefresh) => {
        setFilter(initialFilter);
        if (needRefresh) {
            onSearch(initialFilter);
        }
    };

    const updateFilter = (filters) => {
        const newFilter = { ...initialFilter, ...filters };
        setFilter(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };

    const handleClickEnter = (e) => {
        if (e?.key === 'Enter') onSearch();
    };

    return (
        <LayoutFilter
            filter={filter}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            disabled={isDisabled}
            filterException={['start_date', 'end_date']}
            countException={['organization, route']}
            isFilterVisible
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(value) => handleChange('start_date', value)}
                    handleEndDate={(value) => handleChange('end_date', value)}
                    valueStartDate={filter.start_date}
                    valueEndDate={filter.end_date}
                    isDisabledHandler={isDisabledHandler}
                    dateOnly={true}
                />

                <TextField
                    variant="outlined"
                    value={filter.title}
                    name="title"
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    label="Наименование"
                    size="small"
                    type="text"
                    onKeyPress={handleClickEnter}
                />
            </LayoutFilter.Visible>

            <LayoutFilter.Invisible>
                <TextField
                    variant="outlined"
                    className={styles.textFiledWithoutArrows}
                    value={filter.id}
                    name="id"
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    label="Номер"
                    onKeyPress={handleClickEnter}
                    size="small"
                    type="number"
                />

                <FormControl size={'small'} variant="outlined">
                    <InputLabel>Статус</InputLabel>
                    <Select
                        value={filter.status_id}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        label="Статус"
                        name="status_id"
                    >
                        <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                        {Object?.keys(appealStatus)?.map((key) => (
                            <MenuItem key={key} value={key}>{appealStatus[key]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl size={'small'} variant="outlined">
                    <InputLabel>Тип</InputLabel>
                    <Select
                        value={filter.type_id}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        label="Тип обращения"
                        name="type_id"
                    >
                        <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                        {Object?.keys(appealType)?.map((key) => (
                            <MenuItem key={key} value={key}>{appealType[key]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Autocomplete
                    value={filter.city}
                    options={addressList.data}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.city_name || ''}
                    onChange={(e, value) => handleChange('city', value)}
                    freeSolo={true}
                    size="small"
                    disabledItemsFocusable={true}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Населенный пункт"
                            name="city"
                            onChange={searchCity}
                            onKeyPress={handleClickEnter}
                        />
                    )}
                />

                <SelectCompany
                    selected={filter.organization}
                    onChange={(value) => handleChange('organization', value)}
                    label={titles.ORGANIZATION}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                />

                <SelectRoutes
                    selected={filter.route}
                    onChange={(value) => handleChange('route', value)}
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filters;
