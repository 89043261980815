import React, { useEffect, useState } from 'react';
import SidebarItem from '../../../SidebarItem';
import SidebarFilter from '../../../SidebarFilter';
import { Scrollbars } from 'react-custom-scrollbars';
import * as actions from '../../../../../redux/Parking/actions';
import { parkingSelectors } from '../../../../../redux/Parking';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../common/Loading';
import Item from './Item';
import { getFilter, getColor } from '../helper';
import buttons from '../../../../../helpers/constants/buttons';
import messages from '../../../../../helpers/constants/messages';
import { isEqual } from 'lodash';
import { usePrevious } from '../../../../../helpers/hooks';
import SidebarTitle from '../../../SidebarTitle';
import getFilters from '../../../helpers/getFilters';
import config from '../config';
import Form from './Form';
import useStoreProp from '../../../../../helpers/hooks/useStoreProp';
import { loadTypes } from '../../../../../redux/Parking/actions';


const SideBar = (props) => {
    const dispatch = useDispatch();
    const [parameters, setParameters] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
    });

    const sidebar = useSelector(parkingSelectors.sidebar);
    const loadingSidebar = useSelector(parkingSelectors.sidebarLoading);
    const filters = useSelector(parkingSelectors.filters);
    const active = useSelector(parkingSelectors.active);
    const saved = useSelector(parkingSelectors.saved);

    const prevFilters = usePrevious(filters);

    const types = useStoreProp(
        loadTypes,
        'parking',
        'types'
    );

    const activeId = active?.id ?? 0;

    const fetchList = () => {
        const filter = getFilters(filters, getFilter);
        dispatch(actions.loadSidebar(
            parameters.page,
            parameters.limit,
            filter
        ));
    };

    const handleScrollUpdate = (values) => {
        const {
            last_page = 0
        } = sidebar?.meta || {};
        if (
            values.top > 0.95
            && loadingSidebar === false
            && parameters.page < last_page
        ) {
            setParameters(old => ({
                ...old,
                page: old.page + 1,
            }));
        }
    };

    useEffect(() => () => {
        dispatch(actions.clearSidebar());
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            setParameters(old => ({
                ...old,
                page: 1,
            }));
        } else {
            fetchList();
        }
    }, [parameters, filters]);

    useEffect(() => {
        if (saved) {
            dispatch(actions.clearSidebar());
            setParameters(old => ({
                ...old,
                page: 1,
            }));
        }
    }, [saved]);

    const resetParameters = () => {
        setParameters(old => ({
            ...old,
            page: 1,
        }));
    };

    const renderList = (dataList) => (
        <>
            {dataList.map((item) => {
                const color = getColor();
                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        icon={`<i class="${config.layerIcon}" style="color: ${color}"/>`}
                        // buttons={{
                        //     edit: {
                        //         title: buttons.EDIT,
                        //         icon: <i className="fas fa-pen" />,
                        //         onClick: () => {
                        //             // редактирование
                        //             dispatch(actions.setEditForm(item));
                        //         }
                        //     },
                        //     delete: {
                        //         title: buttons.DELETE,
                        //         icon: <i className="fas fa-trash-alt" />,
                        //         onClick: () => {
                        //             // удаление
                        //             dispatch(actions.setDeleteForm(item.id));
                        //         }
                        //     }
                        // }}
                        onClick={() => {
                            dispatch(actions.setActive(item));
                        }}
                    >
                        <Item
                            {...item}
                            types={types}
                        />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={resetParameters}
                onSetFilter={(filter) => dispatch(actions.setFilters(filter))}
                onClearFilter={() => dispatch(actions.clearFilters())}
                content={({ data, onChange, onDelete }) => <Form data={data} onChange={onChange} onDelete={onDelete} />}
                layer={config.slug}
            />

            {loadingSidebar && sidebar?.data?.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={config.name}
                            list={sidebar}
                        />
                        {sidebar?.data?.length === 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList(sidebar?.data || [])}
                    </div>
                    {loadingSidebar && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
};

export default SideBar;
