import React, { useEffect, useRef } from 'react';
import { Provider, useSelector } from 'react-redux';
import L from 'leaflet';
import { v4 as uuid_v4 } from 'uuid';
import { render, unmountComponentAtNode } from 'react-dom';
import { store } from '../../../../store';
import UnidentifiedPlaces from './UnidentifiedPlaces';
import { authSelectors } from '../../../../redux/Auth';


const MapInterLayer = (props) => {
    const {
        map,
        children,
    } = props;

    const refId = useRef(uuid_v4());
    const code = useSelector(authSelectors.projectCode || '');

    useEffect(() => {
        // если уфа - выводим компонент с неопознанными местами
        if (map && code === '2_ufa' && refId.current) {

            // новый компонент управления
            const customControl = L.Control.extend({
                options: {
                    position: 'bottomright',
                },
                onAdd: function () {
                    const container = L.DomUtil.create(
                        'div',
                        'leaflet-control leaflet-control-custom leaflet-control-layers'
                    );
                    container.id = refId.current;
                    container.style = { background: 'transparent' };
                    // className && container.classList.add(className);

                    // отключаем скрол карты и клик
                    L.DomEvent.disableClickPropagation(container);
                    L.DomEvent.disableScrollPropagation(container);

                    return container;
                }
            });
            const newCustomControl = new customControl();
            map.addControl(newCustomControl);

            // рендерим
            const id = document.getElementById(refId.current);
            id && render(
                <Provider store={store}>
                    <UnidentifiedPlaces map={map} />
                </Provider>,
                id
            );

            // удаление
            return () => {
                const id = document.getElementById(refId.current);
                id && unmountComponentAtNode(id);
            };
        }
    }, [map, code, refId.current]);

    return children;
};

export default MapInterLayer;