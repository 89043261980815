import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
    setShowDorisCameraEvents,
} from '../../../../../redux/DorisControl/actions';
import { dorisControlSelectors } from '../../../../../redux/DorisControl';

const useStyles = makeStyles({
    root: {
        '& .MuiFormControlLabel-label': {
            fontSize: '.9rem !important'
        }
    }
});

const CheckBox = () => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const showDorisCameraEvents = useSelector(dorisControlSelectors.showDorisCameraEvents);

    const handleChecked = () => {
        dispatch(setShowDorisCameraEvents(!showDorisCameraEvents));
    };

    return (
        <div
            style={{
                margin: '1rem'
            }}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        checked={showDorisCameraEvents}
                        onClick={handleChecked}
                        size="small"
                    />
                }
                label="Показать события"
                className={classes.root}
            />
        </div>
    );
};

export default CheckBox;