import React, { useEffect, useState } from 'react';
// import SidebarItem from '../../../SidebarItem';
// import SidebarFilter from '../../../SidebarFilter';
// import { Scrollbars } from 'react-custom-scrollbars';
import * as actions from '../../../../../redux/Incidents/actions';
import { useDispatch, useSelector } from 'react-redux';
// import Loading from '../../../../common/Loading';
// import buttons from '../../../../../helpers/constants/buttons';
// import messages from '../../../../../helpers/constants/messages';
// import config from '../config';
// import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
    // Tooltip,
    // IconButton,
    // CircularProgress,
    LinearProgress,
} from '@mui/material';
import FileUploader from '../../../../../components/common/Upload/FileUploader';
// import titles from '../../../../../helpers/constants/titles';
// import {uploadFileToStorage} from "../../../../../redux/Upload/actions";
import { incidentsSelectors } from '../../../../../redux/Incidents';


const SideBar = (props) => {
    const {
        onLoaded,
    } = props;
    // const dispatch = useDispatch();

    const loadingDtpFile = useSelector(incidentsSelectors.loadingDtpFile);

    const handleLoaded = () => onLoaded && onLoaded();

    // const handleLoadFile = () => {
    //     if (loadingDtpFile === false) {
    //         dispatch(actions.loadDtpFile('', handleLoaded));
    //     }
    // };

    return (
        <div>
            <FileUploader
                onFileUpload={handleLoaded}
                service={'dtp'}
                title={'Загрузить файл'}
                action={actions.loadDtpFile}
            />
            {/*<IconButton onClick={handleLoadFile} disabled={loadingDtpFile}>*/}
            {/*    <Tooltip title="Загрузить файл">*/}
            {/*        <UploadFileIcon/>*/}
            {/*    </Tooltip>*/}
            {/*</IconButton>*/}

            {loadingDtpFile && <LinearProgress /> }
        </div>
    );
};

export default SideBar;
