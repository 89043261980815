// todo отрефакторить src/components/pages/Dictionaries/RoadNetwork/RoadSections/RelationModal/Map.js
export const iconClasses = {
    1: 'far fa-warehouse-alt', // infrastructure
    2: 'fas fa-camera-alt', // complex
    3: 'fas fa-cloud-sun', // meteo
    4: 'fas fa-leaf', // eco
    5: 'far fa-traffic-light-stop', // traffic light
    6: 'fas fa-cctv', // cameras
    7: 'fad fa-cars', // traffic flow
    8: 'far fa-road', // road section
    9: 'fas fa-video', // city cameras
};

export const createHtml = (color, iconClass = 'fa fa-map-marker' ) => {
    return `
        <div style="color: ${color}">
            <i style="font-size: 30px" class="${iconClass}" aria-hidden="true"></i>
        </div>
    `;
};

export const getCoordsFromGeometry = (coordArr) => {
    if (coordArr) {
        if (typeof coordArr?.[0] === 'number') {
            return [coordArr?.[1], coordArr?.[0]];
        }
        return getCoordsFromGeometry(coordArr?.[0]);
    }
    return [];
};

export const getCoords = (item) => {
    const ll = item?.lat
        ? [item?.lat, item?.lon]
        : (item.address
            ? [item.address.geo_lat, item.address.geo_lon]
            : getCoordsFromGeometry(item.geometry?.geometry?.coordinates));

    return ll[0] && ll[1] ? ll : null;
};