import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { loadRoutes, loadVehicleCategories } from 'redux/TransportRoad/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

import type { GetListArguments, RouteRW, VehicleCategory } from 'types';

interface Filters {
    status_id_list: number[];
}

interface Props<T> extends Omit<CommonAutocompleteProps<T>, 'onShowList' | 'inputName' | 'initialState'> {
    filter?: Filters;
    routeForTrafficSchedule?: boolean;
}

const SelectRoutesRW = ({
    multiple = false,
    selected = multiple ? [] : null,
    onChange = () => {},
    required = false,
    disabled = false,
    label = titles.ROUTE,
    filter = { status_id_list: [2] }, // параметры для запроса на АПИ
    error = false,
    helperText = '',
    routeForTrafficSchedule = false,
    limitTags = 1
}: Props<RouteRW>) => {
    
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);

    const prevData = useRef(selected);

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) { 
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleAccept = (data: RouteRW | RouteRW[]) => {
        onChange(data);
        setShowList(false);
    };

    const handleClose = () => setShowList(false);

    const categories: VehicleCategory[] = useStoreProp(
        loadVehicleCategories,
        'transportRoad',
        'vehicleCategories'
    );

    const getCategory = (id: number) => {
        const res = categories.find((item) => item.id === id);
        return res?.name;
    };

    const getRoutes = (params: GetListArguments) => {
        const { page, limit, query: num } = params;
        const paramsWithoutEmpty = removeEmptyFields({ 
            ...(num && { num }),
            ...filter,
        });
        dispatch(loadRoutes(page, limit, paramsWithoutEmpty));
    };

    const onReset = () => {
        const initialState = multiple ? [] : null;
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete<RouteRW>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={(option) => `${option?.category_id ? (getCategory(option?.category_id) + ' ') : ''}${option?.num ? ('№' + option?.num + ' ') : ''}${option?.name ? option?.name : ''}`}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error} 
                helperText={helperText}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getRoutes}
                    storeName="transportRoad"
                    storeNameProps={routeForTrafficSchedule ? 'routesForTS' : 'routes'}
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div>
                        <strong>{getCategory(el.category_id)} №{el.num}</strong>
                        <span> {el.name}</span>{' '}
                        {el.is_direct_text && <span> <strong>тип:</strong> {el.is_direct_text}</span>}
                    </div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={titles.SELECT_ROUTE}
                />
            }
        </>
    );
};

export default SelectRoutesRW;