import { getEndOf, getStartOf } from '../../../../../helpers/date.config';

export const initialState = {
    camera_id: [],
    cameras: [],
    group: '',
    type: '',
    start_date: getStartOf('day'),
    end_date: getEndOf('day'),
    week_day: '',
    format: '',
    with_line: false,
    parameters: []
};

export const week = {
    1: 'Пн',
    2: 'Вт',
    3: 'Ср',
    4: 'Чт',
    5: 'Пт',
    6: 'Сб',
    7: 'Вс'
};

export const typeOptions = {
    byHours:  { count: 1, unit: 'day' },
    byDays: { count: 1, unit: 'month' },
    byDayOfWeek: { count: 3, unit: 'month' },
    byMonth: { count: 1, unit: 'year' },
    byYears: { count: 3, unit: 'year' }
};
