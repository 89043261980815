import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormHelperText, TextField } from '@mui/material';

import { loadNearRoadWorks } from 'redux/RoadWorks/actions';
import { createCheckPoint, editCheckPoint } from 'redux/TransportRoad/actions';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import buttons from 'helpers/constants/buttons';
import MapForm from 'components/MapComponents/MapForm';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FieldsModal from 'components/common/Location/FieldsModal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import SelectRoadWorks from 'components/common/Autocomplete/RoadWorks/Sites';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import SelectCompany from 'components/common/Autocomplete/Companies';

const ModalForm = ({ item, isNew, open, onClose, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        ...item,
        name: item?.name || '',
        lat: item?.lat || '',
        lon: item?.lon || '',
        geometry: item?.geometry || {},
        road_work: item?.road_work || {},
        road_work_id: item?.road_work_id || null,
        organization: item?.organization || null,
        organization_id: item?.organization_id || 0,
    };

    const [data, setData] = useState(initialState);
    const [mapModal, setMapModal] = useState(false);

    const onChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        const removedFields = removeEmptyFields({
            ...data,
            organization_id: data.organization?.id,
        });
        removedFields.road_work = data.road_work;
        removedFields.road_work_id = data.road_work_id;

        isNew
            ? dispatch(createCheckPoint(removedFields, callback))
            : dispatch(editCheckPoint(data.id, removedFields, callback));
    };

    const getLatLonGeometry = data.lat && data.lon
        ? {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [
                    data.lon,
                    data.lat,
                ],
            }
        } : null;

    const isDisabled = !data.name
        || !data.lat
        || !data.lon
        || !Object.keys(data.geometry).length
        || !data.organization;

    return (
        <Modal
            title={isNew ? 'Создание КТ' : 'Редактирование КТ'}
            isOpen={open}
            onClose={onClose}
            noPadding={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormInfoWrapper
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                >
                    <TextField
                        value={data.name}
                        onChange={onChange}
                        className="block"
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        name="name"
                    />
                </FormInfoWrapper>

                <div className="row">
                    <FormInfoWrapper
                        error={validation.isKey('lat')}
                        helperText={validation.get('lat')}
                        className="row__item"
                    >
                        <TextField
                            label={titles.LAT}
                            variant="outlined"
                            size="small"
                            name="lat"
                            value={data.lat}
                            onChange={onChange}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        error={validation.isKey('lon')}
                        helperText={validation.get('lon')}
                        className="row__item"
                    >
                        <TextField
                            label={titles.LON}
                            variant="outlined"
                            size="small"
                            name="lon"
                            value={data.lon}
                            onChange={onChange}
                        />
                    </FormInfoWrapper>
                </div>

                <FormButtons
                    noPadding
                    positionLeft
                    noMarginLeft
                    buttons={[
                        {
                            ...buttonsTypes.selectOnMap,
                            onClick: () => setMapModal(true),
                            name: buttons.SELECT_ON_MAP,
                        },
                    ]}
                />
                {mapModal && (
                    <MapForm
                        isOpen={mapModal}
                        noPadding
                        latlon={[data.lat, data.lon]}
                        onAccept={({ 0: lat, 1: lon }) => {
                            setData({ ...data, lat, lon });
                            setMapModal(false);
                        }}
                        onClose={() => setMapModal(false)}
                    />
                )}

                <div className="block">
                    <h3>Зона действия КТ:</h3>
                    <FieldsModal
                        title="Выбрать область на карте"
                        fields={{ geometry: data?.geometry || {} }}
                        buttonText={buttons.SELECT_ON_MAP}
                        buttonType={buttonsTypes.selectOnMap}
                        buttonVariant="contained"
                        onChange={({ geometry }) => {
                            setData({ ...data, geometry });
                            validation.deleteKey('geometry');
                        }}
                    >
                        <MapGeoJson
                            visibleGeometry={getLatLonGeometry}
                            circle={true}
                            polygon={true}
                            polyline={false}
                            marker={false}
                            isHideValidation
                        />
                    </FieldsModal>
                    {validation.isKey('geometry')
                        ? <FormHelperText error>{validation.get('geometry')}</FormHelperText>
                        : null
                    }
                </div>

                <div className="block">
                    <SelectRoadWorks
                        label="Участок"
                        roadWorkList={data.road_work}
                        onRoadWorkChange={(road_work) => {
                            setData({
                                ...data,
                                road_work: road_work || {},
                                road_work_id: road_work?.id || null,
                            });
                        }}
                        disabled={!data?.lat || !data?.lon}
                        loadListAction={loadNearRoadWorks}
                        storeNameProps={'nearRoadWorks'}
                        storeLoadingProps={'loadingNearRoadWorks'}
                        withSearch
                        filter={{ geojson: getLatLonGeometry }}
                        renderOption={(option) => option?.name || ''}
                        error={validation.isKey('road_work_id')}
                        helperText={validation.get('road_work_id')}
                    />
                </div>

                <SelectCompany
                    selected={data.organization}
                    onChange={(value) => setData({ ...data, organization: value })}
                    required
                    error={validation.isKey('organization') || validation.isKey('organization_id')}
                    helperText={validation.get('organization') || validation.get('organization_id')}
                />
            </div>
        </Modal>
    );
};

export default ModalForm;
