import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import buttons from '../../../../helpers/constants/buttons';
import { adminSelectors } from '../../../../redux/Admin';
import { resetedPassword, resetPassword } from '../../../../redux/Admin/actions';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import Loading from '../../../common/Loading';
import Modal from '../../../common/Modal';

const ResetPassModal = ({
    isOpen = false,
    onClose = () => {},
    user = {}
}) => {
    const dispatch = useDispatch();

    const newPassword = useSelector(adminSelectors.newPassword);
    const resettingPassword = useSelector(adminSelectors.resettingPassword);

    const onChange = () => {
        dispatch(resetPassword({ username: user?.email }));
    };

    const handleClose = () => {
        dispatch(resetedPassword(''));
        onClose();
    };
    
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={newPassword?.password ? 'Пароль сброшен' : 'Сброс пароля'}
            noPadding
            buttons={<FormButtons
                buttons={newPassword?.password
                    ? [
                        {
                            ...buttonsTypes.close,
                            name: buttons.CLOSE,
                            onClick: handleClose,
                        }
                    ]:[
                        {
                            ...buttonsTypes.close,
                            name: buttons.NO,
                            onClick: onClose,
                            disabled: resettingPassword
                        },
                        {
                            ...buttonsTypes.apply,
                            name: buttons.YES,
                            onClick: onChange,
                            disabled: resettingPassword
                        }
                    ]
                }
            />}
        >
            <div className="modal__form">
                {resettingPassword && <Loading circular />}

                {newPassword?.password
                    ? (
                        <Typography variant="body2">
                            <span>Новый пароль:</span> <strong>{newPassword?.password}</strong><br />
                            <span>Сохраните его!</span>
                        </Typography>
                    ) : (
                        <Typography variant="body2">
                            <span>
                                Вы хотите сбросить пароль пользователю:{' '}
                                {user?.last_name || ''}{' '}
                                {user?.first_name || ''}{' '}
                                {user?.middle_name || ''} ?
                            </span>
                        </Typography>
                    )
                }
                  
            </div>                     
        </Modal>
    );
};

export default ResetPassModal;
