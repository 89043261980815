import React, { useState, useEffect, useMemo, useContext } from 'react';
import { List } from '@mui/material';
import { loadDocuments, loadDocumentTypes } from '../../../../../../../redux/DorisControl/actions';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../vfcomplex.module.scss';
import CustomPagination from '../../../../../../common/CustomPagination';
import { DocumentItem } from './DocumentItem';
import { DocumentsForm } from './DocumentsForm';
import Search from '../Search';
import FormButtons, { buttonsTypes } from '../../../../../../common/FormButtons';
import messages from '../../../../../../../helpers/constants/messages';
import { useStoreProp } from '../../../../../../../helpers/hooks';
import Loading from '../../../../../../common/Loading';
import Context from '../../../../../../../helpers/context';
import { dorisControlSelectors } from '../../../../../../../redux/DorisControl';

const Documents = ({ complex_id, readOnly = false, onChange }) => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const storageLimit = Number(localStorage.getItem('limit') || 25);
    
    const [params, setParams] = useState({
        page: 1,
        limit: storageLimit,
        complex_id,
        number: null
    });

    useEffect(() => {
        if (complex_id !== params.complex_id) {
            setParams((params) => ({
                ...params,
                complex_id
            }));
        }
    }, [complex_id]);

    const data = useSelector(dorisControlSelectors.documentsData);
    const meta = useSelector(dorisControlSelectors.documentsMeta);
    const loading_documents = useSelector(dorisControlSelectors.loading_documents);
    const document_types = useStoreProp(loadDocumentTypes, 'dorisControl', 'doris_complex_document_types');

    const [openDocumentModal, setOpenDocumentModal] = useState(false);

    useEffect(() => {
        const {
            page,
            limit,
            ...props
        } = params;
        dispatch(loadDocuments(page, limit, props));
    }, [params, dispatch]);

    const handleChangePage = (page, limit) => {
        setParams((props) => ({
            ...props,
            page,
            limit
        }));
    };

    const handleAdded = () => {
        onChange && onChange();

        setParams((props) => ({
            ...props,
            page: 1
        }));
    };

    const handleEdited = () => {
        onChange && onChange();

        setParams((props) => ({
            ...props,
        }));
    };

    const handleDeleted = () => {
        const { page } = params;
        onChange && onChange();

        setParams((props) => ({
            ...props,
            page: data?.length === 1 ? Math.max(1, page - 1) : page
        }));
    };

    return (
        <div className={styles.container}>
            <Search
                action={loadDocuments}
                loadParams={(newNumber) => setParams((params) => ({ ...params, number: newNumber }))}
                complex_id={complex_id}
            />
            <div className="filter__wrap__btn filter__btn__column">
                {(!readOnly && permissions?.is_create)
                    && <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.add,
                                onClick: () => setOpenDocumentModal(true),
                            },
                        ]}
                    />
                }
            </div>
            {loading_documents && <Loading circular={true}/> }
            { data.length > 0
                ? <>
                    <List className="list">
                        {data.map((item) => (
                            <DocumentItem
                                key={item.id}
                                item={item}
                                document_types={document_types}
                                complex_id={complex_id}
                                readOnly={readOnly}

                                onEdited={handleEdited}
                                // удаление
                                onDeleted={handleDeleted}
                            />
                        ))}
                    </List>
                    <CustomPagination
                        loadList={handleChangePage}
                        list={meta}
                        limit={params.limit} 
                        setLimit={(limit) => setParams({ ...params, limit })}
                    />
                </>
                : !loading_documents && messages.DATA_IS_NOT_FOUND
            }
            {openDocumentModal
                && <DocumentsForm
                    document_types={document_types}
                    isNew={true}
                    open={openDocumentModal}
                    setOpenModal={setOpenDocumentModal}
                    complex_id={complex_id}

                    // добавление или редактирование
                    onAdded={handleAdded}
                />
            }
        </div>
    );
};

export default Documents;
