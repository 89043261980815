import { Checkbox, List, ListItem, ListItemIcon } from '@mui/material';

import messages from 'helpers/constants/messages';

import Item from './Item';

const RenderContent = ({
    data,
    loading,
    complex_id,
    onCheckboxCheck,
    selectedEvents,
    isServiceBase,
    renderMediaModal,
}) => {
    return <>
        {data?.length === 0
            ? !loading && <div>{messages.NOTHING_FOUND}</div>
            : <>
                <List className="list">

                    {data?.map((item, index) => (
                        <ListItem dense divider key={item?.uid}>
                            {isServiceBase
                                && <ListItemIcon>
                                    <Checkbox
                                        checked={selectedEvents.includes(item?.uid)}
                                        onChange={(e) => onCheckboxCheck(e, item?.uid)}
                                        color="primary"
                                    />
                                </ListItemIcon>
                            }
                            <Item
                                item={item}
                                index={index}
                                complex_id={complex_id}
                                renderMediaModal={renderMediaModal}
                            />
                        </ListItem>
                    ))}
                </List>
            </>
        }

    </>;
};

export default RenderContent;
