import { useDispatch } from 'react-redux';
import { 
    Typography,
    ListItem,
    ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { getHumanDate } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import { createStageSyncRoadWork } from 'redux/RoadWorks/actions';

import RoadWorkSites from '../../SitesArea/RoadWorkSites';

const useStyles = makeStyles({
    root: {
        margin: '20px 10px'
    },
    marginLeft: {
        marginLeft: 20
    },
});

const Item = ({ currentStage, currentOrder, reloadStageSchedule }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    // мы находимся в конкретном этапе
    // берем участки в этом этапе и делаем из них объект с айди участка в качестве ключа
    // и массив групп и работ внутри
    const workData = currentStage?.works.reduce((result, item) => {
        // item.works это уже работы внутри участка, мы берем саму работу, ее группу, и связываем с id участка
        result[item.id] = item.works.reduce((accumulator, currentValue) => {
            const group = item.road_work_groups.find(el => {
                return el.id === currentValue.road_work_group_id && el.stage_road_work_id === currentValue.stage_road_work_id;
            });
            accumulator.push({ units: currentValue.unit_of_measurement_list?.[0], work: currentValue, group });
            return accumulator;
        }, []);
        return result;
    }, {});

    const saveData = (road_works) => {
        // мы сохраняем синхронизацию участков-групп-работ в Этапе т.к. нам нужно отправить 
        // на синхронизацию полный список всех участков-групп-работ
        dispatch(createStageSyncRoadWork(currentStage.id, currentOrder.id, road_works ));
    };

    return (
        <ListItem>
            <ListItemText>
                <h2>Этап №{currentStage.number} {currentStage.name}</h2>
                <div className={classes.marginLeft}>
                    <Typography variant="body2" >
                        <strong> Планируемая дата начала:</strong> <span>{ 
                            currentStage.start_date_plan 
                                ? getHumanDate(currentStage.start_date_plan) 
                                : messages.INFO_IS_NOT_FOUND }
                        </span>
                    </Typography>
                    <Typography variant="body2">
                        <strong>Планируемая дата окончания:</strong> <span>{
                            currentStage.end_date_plan
                                ? getHumanDate(currentStage.end_date_plan) 
                                : messages.INFO_IS_NOT_FOUND }
                        </span>
                    </Typography>
                    <RoadWorkSites 
                        list={currentStage?.works}
                        stage={currentStage}
                        collapsedList 
                        showInfo
                        works={workData}
                        onSaveGroupWorks={saveData} // понадобится для сохранения работ по участкам
                        reloadStageSchedule={reloadStageSchedule}
                    /> 
                </div>
            </ListItemText>
        </ListItem>
    );
};

export default Item;