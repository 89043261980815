import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import {
    FormControlLabel,
    Checkbox,
} from '@mui/material';

// неопознанные места
const UnidentifiedPlaces = (props) => {
    const {
        map,
    } = props;

    // отобразить
    const [showLayer, setShowLayer] = useState(false);
    // слой
    const geoJsonRef = useRef(null);

    const handleCheckbox = ({ target: { checked } }) => setShowLayer(checked || false);

    useEffect(() => {
        if (map && showLayer) {
            // грузим данные (чтобы не хранить)
            import('./blackGeoJsonUfa')
                .then(file => {
                    if (file && file?.default) {
                        const json = L.geoJSON(file?.default || {}, {
                            style: {
                                color: '#000000',
                                fill: '#000000',
                                fillOpacity: 1,
                            }
                        });
                        json.addTo(map);
                        geoJsonRef.current = json;
                    }
                });

            return () => {
                if (geoJsonRef.current) {
                    map.removeLayer(geoJsonRef.current);
                    geoJsonRef.current = null;
                }
            };
        }
    }, [map, showLayer]);

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={showLayer}
                    onChange={handleCheckbox}
                    color="primary"
                    size="small"
                />
            }
            label="неопознанные места"
            style={{
                margin: '0 .5rem 0 0',
                alignItems: 'center',
                display: 'flex'
            }}
        />
    );
};

export default UnidentifiedPlaces;