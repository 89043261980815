import Monitoring from '../pages/Monitoring';

const routes = [
    {
        path: '/monitoring',
        component: Monitoring,
        exact: true,
    },
];

export default routes;