import { useEffect, useRef, useState } from 'react';
import { useDispatch, } from 'react-redux';
import _ from 'lodash';

import { loadBoards } from 'redux/Boards/actions';
import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

export interface SelectBoardsTypes<T> extends CommonAutocompleteProps<T> {
    filter?: Object,
}

export interface Item {
    id?: number,
    name?: string,
}

const SelectBoards = ({
    multiple = false,
    selected,
    onChange = () => {},
    required = false,
    disabled = false,
    label = titles.SELECT_BOARD,
    error = false,
    helperText = '',
    filter = {},
    limitTags = 1
}: SelectBoardsTypes<Item>)=> {
    
    const [showList, setShowList] = useState(false);
    const dispatch = useDispatch();
    const prevData = useRef(selected);
    const [selectedItems, setSelected] = useState(selected);

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const getList = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query: name } = params;

        dispatch(loadBoards({
            page, 
            limit,
            ...(name && { name }),
            ...filter,
        }));
    };

    const handleClose = () => setShowList(false);

    const handleAccept = (data: any) => {
        onChange(data);
        handleClose();
    };

    const onReset = () => {
        const initialState = multiple ? [] : '';
        onChange(initialState);
    };

    return ( 
        <>
            <CommonAutocomplete<Item>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={(option) => option?.name || String(option?.id) || ''}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error} 
                helperText={helperText}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName={'boards'}
                    storeNameProps={'boards'}
                    keyProp={'id'}
                    withSearch
                    isSelected
                    selected={selectedItems}
                    storeLoadingProps={'loading'}
                    searchTitle="поиск по названию"
                    renderProps={(el) => <div>
                        {el.name}
                        <div>
                            <strong>
                                    серийный номер:&nbsp;
                            </strong>
                            {el.serial_number}
                        </div>
                        <strong>
                                адрес:&nbsp;
                        </strong>
                        {el.address_text}
                    </div>}
                    title={titles.SELECT_BOARD}
                    onAccept={handleAccept}
                    isOpen={showList}
                    onClose={handleClose}
                    noPadding
                    small
                />
            }
        </>
    );
};

export default SelectBoards;