import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Menu, MenuItem } from '@mui/material';
import cn from 'classnames';

import { logoutCurrentUser } from 'redux/Auth/actions';

import styles from './header.module.scss';
import ProfileModal from './ProfileModal';
import ChangePasswordModal from './ChangePasswordModal';
import EditModal from './EditModal';

interface MenuItemButtonProps {
    text: string
    icon: React.ReactNode
    onClick: () => void;
}

const MenuItemButton = ({ text, icon, onClick }: MenuItemButtonProps) => (
    <MenuItem onClick={onClick}>
        <Button className={styles.menu_item_button} color="inherit" startIcon={icon}>
            {text}
        </Button>
    </MenuItem>
);

interface ProfileMenuProps {
    anchorEl: null | Element | ((element: Element) => Element)
    onClose: () => void
    isSuperUser: boolean
}

const ProfileMenu = ({ anchorEl, onClose, isSuperUser }: ProfileMenuProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [isModalProfile, setIsModalProfile] = useState(false);
    const [isModalEdit, setIsModalEdit] = useState(false);
    const [isModalPassword, setIsModalPassword] = useState(false);

    const handleLogout = () => {
        history.push('/logout');
        onClose();
    };

    const handleLogoutAll = () => {
        history.push('/logout/all');
        onClose();
    };

    const handleGoToChangePassword = () => {
        // старую страницу не удалял
        // history.push('/profile/change-password');
        onClose();
        setIsModalPassword(true);
    };

    const handleGoToEditProfile = () => {
        // старую страницу не удалял
        // history.push('/profile/edit');
        onClose();
        setIsModalProfile(true);
    };

    const handleGoToEdit = () => {
        onClose();
        setIsModalEdit(true);
    };

    const handleLogOutCurrentUser = () => {
        dispatch(logoutCurrentUser());
        onClose();
    };

    return (
        <Menu
            sx={{ mt: '36px' }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
        >
            <MenuItemButton
                text="Сменить пароль"
                icon={<i className={cn(styles.menu_icon, 'fas fa-key')} />}
                onClick={handleGoToChangePassword}
            />

            {isModalPassword && (
                <ChangePasswordModal
                    isOpen={isModalPassword}
                    onClose={() => setIsModalPassword(false)}
                />
            )}

            <MenuItemButton
                text="Редактировать"
                icon={<i className={cn(styles.menu_icon, 'far fa-user-edit')}/>}
                onClick={handleGoToEditProfile}
            />
            {isModalProfile && (
                <ProfileModal
                    isOpen={isModalProfile}
                    onClose={() => setIsModalProfile(false)}
                />
            )}

            <MenuItemButton
                text="Настройки"
                icon={<i className={cn(styles.menu_icon, 'far fa-cog')}/>}
                onClick={handleGoToEdit}
            />
            {isModalEdit && (
                <EditModal
                    isOpen={isModalEdit}
                    onClose={() => setIsModalEdit(false)}
                />
            )}

            {!isSuperUser && (
                <MenuItemButton
                    text="Выход"
                    icon={<i className={cn(styles.menu_icon, 'fas fa-sign-out-alt')} />}
                    onClick={handleLogout}
                />
            )}

            {!isSuperUser && (
                <MenuItemButton
                    text="Выход со всех устройств"
                    icon={<i className={cn(styles.menu_icon, 'fas fa-sign-out-alt')} />}
                    onClick={handleLogoutAll}
                />
            )}

            {isSuperUser && (
                <MenuItemButton
                    text="Выйти из пользователя"
                    icon={<i className={cn(styles.menu_icon, 'fas fa-sign-out-alt')} />}
                    onClick={handleLogOutCurrentUser}
                />
            )}
        </Menu>
    );
};

export default ProfileMenu;
