
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import { boardsSelectors } from 'redux/Boards';
import { createDitLink } from 'redux/Boards/actions';
import { createStage, editStage } from 'redux/RoadWorks/actions';
import { useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import useLoadBoardsList from 'helpers/hooks/Boards/useLoadBoardList';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import SelectBoards from 'components/common/Autocomplete/Boards/BoardList';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SelectVehicles from 'components/common/Autocomplete/Transport/Vehicles';
import SelectRoadWorks from 'components/common/Autocomplete/RoadWorks/Sites';
import Attachments from 'components/common/Upload/Attachments';
import DateRange from 'components/common/Dates/DateRange';
import useOrganizationsByOrderId from 'helpers/hooks/Transport/useOrganizationsByOrderId';

import { TrafficScheme } from './TrafficScheme/';

const OrderStageModal = ({
    isNew,
    isOpen,
    onClose,
    statuses,
    item = {},
    currentOrder = {},
    reloadList
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const loadingDitLink = useSelector(boardsSelectors.loadingDitLink);
    const loading = useSelector(boardsSelectors.loading);

    // организации заявки
    const orderOrganizations = useOrganizationsByOrderId(currentOrder?.id);

    const organizationObj = currentOrder.organizations.find(elem => elem?.id === item?.organization_id);
    // todo откатывание
    // const availableCompanies = useCheckUserOrganizations(currentOrder?.organizations);
    // const availableCompanyIds = useCheckUserOrganizations(currentOrder?.organization_ids);

    const initialState = {
        order_id: currentOrder.id || '',
        name: item?.name || '',
        status: isNew ? 0 : item?.status,
        number: item?.number || currentOrder.stages?.length + 1,
        end_at: item?.end_at || null,
        works: item?.works || [],
        vehicles: item?.vehicles || [],
        attachments: item?.attachments || [],
        schema_doc: item?.schema_doc,
        schema_img: item?.schema_img,
        end_date_plan: item?.end_date_plan || null,
        start_date_plan: item?.start_date_plan || null,
        organization_id: item?.organization_id || '',
        organization: organizationObj || '',
        description: item.description || ''
    };

    const [data, setData] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);

    const onChange = (event) => {
        const { name, value } = event.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleChange = (value, key) => {
        if (key === 'organization') {
            setData({
                ...data,
                [key]: value,
                organization_id: value.id || '',
                vehicles: []
            });
        } else {
            setData({
                ...data,
                [key]: value
            });
        }
    };

    const dit_links = useLoadBoardsList(item.id, item.entity_name, 'stagesOfRoadWorks');
    const [boards, setBoards] = useState([]);

    useEffect(() => {
        if (dit_links.length > 0) {
            setBoards(dit_links);
        }
    }, [dit_links]);

    const onChangeBoards = (value) => setBoards(value);

    const noErrorCallback = ({ id, entity_name }) => {
        const ditLinksParams = {
            dit_ids: boards.map(el => el.id),
            entity_name: entity_name,
            entity_id: id.toString()
        };
        dispatch(createDitLink(ditLinksParams, onClear, 'stagesOfRoadWorks'));
    };

    const handleCreateOrderStage = () => {
        const result = removeEmptyFields({
            ...data,
            organization_id: data.organization?.id
        }, false);

        dispatch(createStage(result, (data) => {
            noErrorCallback(data);
            reloadList();
            onClose();
        }));
    };

    const handleEditOrderStage = () => {
        const result = {
            ...data,
            organization_id: data.organization?.id,
            works: data.works.map(item => ({ id: item.id }))
        };

        const createDitLinkCallback = () => {
            const ditLinksParams = {
                dit_ids: boards.map(el => el.id),
                entity_id: item.id.toString(),
                entity_name: item.entity_name,
            };
            dispatch(createDitLink(ditLinksParams, () => {}, 'stagesOfRoadWorks'));
        };

        dispatch(editStage(item?.id, result, () => {
            reloadList();
            onClear();
            createDitLinkCallback();
        }));
    };

    const disabled = !data.name
    || (!data.end_date_plan && !data.start_date_plan)
    || (data.status === 1 && !data.end_at)
    || loadingDitLink
    || !data.organization_id
    || isDisabled;

    const onClear = () => {
        // dispatch(clearBoards());
        // dispatch(clearDitLink());
        validation.clear();
        onClose();
    };

    return (
        <Modal
            title={isNew ? titles.ADD_STAGE: titles.EDIT_STAGE}
            onClose={onClose}
            maxWidthProp={'sm'}
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    isNew
                        ? {
                            ...buttonsTypes.create,
                            disabled: disabled,
                            onClick: handleCreateOrderStage,
                        }
                        : {
                            ...buttonsTypes.save,
                            disabled: disabled,
                            onClick: handleEditOrderStage
                        }
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl
                    required={true}
                    className="block"
                    size="small"
                    variant="outlined"
                >
                    <TextField
                        required={true}
                        label="Название этапа"
                        variant="outlined"
                        size="small"
                        name="name"
                        value={data.name}
                        onChange={onChange}
                        error={validation.isKey('name')}
                        helperText={validation.get('name')}
                    />
                </FormControl>
                <FormControl
                    className="block"
                    size="small"
                    variant="outlined"
                    required={true}
                    error={validation.isKey('status')}
                >
                    <InputLabel>{titles.STATUS}</InputLabel>
                    <Select
                        value={data.status}
                        onChange={onChange}
                        label={titles.STATUS}
                        required={true}
                        name="status"
                        error={validation.isKey('status')}
                    >
                        {statuses?.length > 0
                            ? (statuses.map((key, index) => (
                                <MenuItem key={index} value={index}>
                                    {statuses[index]}
                                </MenuItem>
                            )))
                            : (<MenuItem value=" ">{messages.NO_DATA}</MenuItem>)
                        }
                    </Select>
                    {validation.isKey('status') && (
                        <FormHelperText error>
                            {validation.get('status')}
                        </FormHelperText>
                    )}
                </FormControl>
                <div className="block">
                    <DateRange
                        handleDate={(value) => handleChange(fullDateTimeWithTimeZone(value), 'start_date_plan')}
                        handleEndDate={(value) => handleChange(fullDateTimeWithTimeZone(value), 'end_date_plan')}
                        valueStartDate={data.start_date_plan}
                        valueEndDate={data.end_date_plan}
                        isDisabledHandler={setIsDisabled}
                        startLabel={'Плановая дата начала выполнения'}
                        endLabel={'Плановая дата окончания выполнения'}
                        required
                        dateOnly
                        className={'block'}
                    />
                </div>
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        isDisabled={Number(data.status) === 0}
                        onChange={(value) => handleChange(fullDateTimeWithTimeZone(value), 'end_at')}
                        value={data.end_at}
                        label="Фактическая дата выполнения"
                        error={validation.isKey('end_at')}
                        helperText={validation.get('end_at')}
                        isDisabledHandler={setIsDisabled}
                        isRequired={Number(data.status) === 1}
                        dateOnly={true}
                    />
                </div>
                <div className="block">
                    <SelectRoadWorks
                        onRoadWorkChange={(value) => handleChange(value, 'works')}
                        roadWorkList={data.works}
                        error={validation.isKey('works')}
                        helperText={validation.get('works')}
                        multiple
                    />
                </div>
                <div className="block">
                    <SelectCompany
                        filter={{ id_list: currentOrder?.organization_ids, withDeleted: 1 }}
                        // filter={{ id_list: availableCompanyIds, withDeleted: 1 }}
                        onChange={(value) => handleChange(value, 'organization')}
                        selected={data.organization}
                        required
                        error={validation.isKey('organization_id')}
                        helperText={validation.get('organization_id')}
                        sortedList={orderOrganizations || null}
                        // sortedList={currentOrder ? currentOrder.organizations : null}
                        // sortedList={currentOrder ? availableCompanies : null}
                    />
                </div>
                <div className="block">
                    <SelectVehicles
                        multiple
                        filter={{ organization_id: [data.organization_id] }}
                        helperText={!data.organization_id && data.organization_id !== 0 && 'Укажите вначале организацию'}
                        disabled={!data.organization_id && data.organization_id !== 0}
                        onChange={(value) => handleChange(value, 'vehicles')}
                        storeName={'transportRoad'}
                        selected={data.vehicles}
                    />
                </div>
                <div className="block">
                    <TextField
                        value={data.description}
                        onChange={({ target: { value } }) => handleChange(value, 'description')}
                        variant="outlined"
                        size="small"
                        multiline={true}
                        rows={5}
                        label={titles.DESCRIPTION}
                        error={validation.isKey('description')}
                        helperText={validation.get('description')}
                    />
                </div>
                <Attachments
                    onChange={(files) => handleChange(files, 'attachments')}
                    files={data?.attachments}
                />
                <TrafficScheme
                    schema_doc={data?.schema_doc}
                    schema_img={data?.schema_img}
                    onChangeSchemaDoc={(value) => handleChange(value, 'schema_doc')}
                    onChangeSchemaImg={(value) => handleChange(value, 'schema_img')}
                />
                <div className="block">
                    <SelectBoards
                        onChange={onChangeBoards}
                        selected={boards}
                        multiple
                    />
                </div>
            </div>
        </Modal>
    );
};

export default OrderStageModal;
