import { useState } from 'react';
import {
    Collapse,
    IconButton,
    ListItem,
    ListItemIcon, 
    ListItemText,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { getStatusesRW } from 'redux/RoadWorks/actions';
import useStoreProp from 'helpers/hooks/useStoreProp';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import WorkSiteArea from './WorkSiteArea';
import ModalSchedule from './ModalSchedule';

const RoadWorkSiteItem = ({ 
    item = {}, 
    index, 
    groupWorks,
    onSaveSite,
    showInfo,
    stage,
    reloadStageSchedule
}) => {
    const roadWork_statuses = useStoreProp(getStatusesRW, 'roadworks', 'statuses');
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const [openModalSchedule, setOpenModalSchedule] = useState(false);
    
    return (
        <>
            <ListItem>
                <ListItemIcon>
                    {index + 1}. 
                </ListItemIcon>
                <ListItemText>
                    <strong>Название: </strong>{item.name}
                    {' '}
                    <strong>Адрес: </strong>{item.address_text}
                    {' '}
                    <strong>Статус: </strong>{roadWork_statuses[item.status]}
                </ListItemText>
                {showInfo
                    && <IconButton size="small" onClick={()=> setIsOpenInfo(!isOpenInfo)}>
                        {isOpenInfo
                            ? <ExpandLess fontSize="inherit" />
                            : <ExpandMore fontSize="inherit" />
                        }
                    </IconButton>
                }
            </ListItem>
            {isOpenInfo 
                && <Collapse in={isOpenInfo} timeout={'auto'} unmountOnExit className="info">
                    <WorkSiteArea 
                        site={item}
                        groupWorks={groupWorks}
                        onSave={onSaveSite}
                        stage={stage}
                    />
                    {groupWorks?.length > 0
                        &&  <FormButtons
                            buttons={[{
                                ...buttonsTypes.defaultInfo,
                                name: 'График',
                                onClick: () => setOpenModalSchedule(true),
                                size: 'small',
                                disabled: false,
                            }]}
                            positionLeft
                        />
                    }
                </Collapse>
            }
            {openModalSchedule 
                && <ModalSchedule 
                    siteId={item?.id} 
                    open={openModalSchedule} 
                    onCloseModal={() => setOpenModalSchedule(false)} 
                    works={groupWorks}
                    stage={stage}
                    reloadStageSchedule={reloadStageSchedule}
                />
            }
        </>
    );
};

export default RoadWorkSiteItem;