import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, ListItemIcon, Tooltip } from '@mui/material';

import { deleteBrand, loadVehicleCategories } from 'redux/TransportSpecial/actions';
import { transportSpecialSelectors } from 'redux/TransportSpecial';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import { checkBase64 } from 'helpers/transport.types';
import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';

const Item = ({ item, reloadList }) => {
    const dispatch = useDispatch();

    const categories = useStoreProp(loadVehicleCategories, 'transportSpecial', 'vehicleCategories');

    const loadingButton = useSelector(transportSpecialSelectors.loadingButton);

    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    const toggleDeleteOpen = (e) => {
        e && e.stopPropagation();
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const handleOpenEditModal = (e) => {
        e && e.stopPropagation();
        setIsOpenEditModal(true);
    };

    const handleDelete = () => {
        dispatch(deleteBrand(item.id, () => {
            reloadList(true);
            setIsOpenDeleteModal(false);
        }));
    };
    const category = categories?.find((el) => item.category_id === el.id);

    const imgUrl = checkBase64(category?.image_with_work_order);

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {imgUrl && (
                        <Tooltip title={category?.name || messages.NO_DATA}>
                            <ListItemIcon>
                                <Avatar sx={{ background: category?.color }}>
                                    <img
                                        style={{ width: 30, height: 30, margin: 0 }}
                                        src={imgUrl}
                                        alt={category?.name || ''}
                                    />
                                </Avatar>
                            </ListItemIcon>
                        </Tooltip>
                    )}
                </LSContentColumn>
                <LSContentColumn>
                    {item.name_en || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: handleOpenEditModal,
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: toggleDeleteOpen,
                            },
                        ]}/>
                </LSContentColumn>
            </LSContentItem>

            {isOpenEditModal && (
                <ModalForm
                    onClose={() => setIsOpenEditModal(false)}
                    open={isOpenEditModal}
                    data={item}
                    reloadList={reloadList}
                />
            )}
            {isOpenDeleteModal && (
                <ConfirmDelete
                    open={isOpenDeleteModal}
                    message={messages.CONFIRM_DELETE}
                    onClose={toggleDeleteOpen}
                    onSuccess={handleDelete}
                    loadingButton={loadingButton}
                />
            )}
        </>
    );
};

export default Item;
