import React, { useMemo } from 'react';
import {
    TableCell,
    Collapse,
} from '@mui/material';

const Inner = (props) => {
    const {
        children,
        open = false,
        width = undefined,
        align = 'left',
        colSpan = undefined,
    } = props;

    const styleHidden = {
        paddingBottom: 0,
        paddingTop: 0,
        borderBottom: 0,
    };

    // const dat = useMemo(() => {
    //     return children;
    // }, [children]);

    return (
        <TableCell
            style={open ? { background: 'rgba(0,0,0,.04)' } : styleHidden}
            width={width}
            align={align}
            colSpan={colSpan}
        >
            <Collapse in={open} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </TableCell>
    );
};

export default Inner;