import React from 'react';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import HeaderTitle from '../HeaderTitle';

const HeaderPanel = ({
    title,
}) => {
    return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* заголовок */}
        <HeaderTitle title={title} />
        
        <ReturnToButton />

    </div>;

};

export default HeaderPanel;