import React, { useState } from 'react';
import { IconButton, ListItemText, Tooltip } from '@mui/material';

import RenderEventInfo from 'components/common/DorisControl/RenderEventInfo';
import buttons from 'helpers/constants/buttons';

import MapModal from './MapModal';
import Preview from './Preview';


const Item = ({ item, index, complex_id, renderMediaModal, }) => {
    const [openMapModal, setOpenMapModal] = useState(false);
    const [openMediaModal, setOpenMediaModal] = useState();

    return (
        <>
            <ListItemText disableTypography>
                <RenderEventInfo
                    event={item}
                />
            </ListItemText>
            <Preview data={item} onOpenMedia={() => setOpenMediaModal(true)}/>
            {!complex_id
                && <Tooltip title={buttons.SHOW_ON_MAP}>
                    <IconButton onClick={() => setOpenMapModal(true)} size="large">
                        <i className="fal fa-map-marked-alt"/>
                    </IconButton>
                </Tooltip>
            }
            {openMapModal
                && <MapModal
                    onClose={setOpenMapModal}
                    isOpen={openMapModal}
                    address={item.complex?.address_text}
                    complexName={item.complex?.name}
                    lat={item.complex?.lat}
                    lon={item.complex?.lon}
                />
            }
            {openMediaModal
                && renderMediaModal({
                    item: item,
                    isOpen: openMediaModal,
                    itemNum: index,
                    onClose: () => setOpenMediaModal(false),
                    renderLabels: (data) => <RenderEventInfo event={data} />,
                })
            }
        </>
    );
};

export default Item;
