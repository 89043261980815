import { Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { fullDateTime, getTimeWithoutSeconds } from 'helpers/date.config';

const useStyles = makeStyles({
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    wrap: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 15
    }
});

const WaybillsInfo = ({ isOpen, item }) => {
    const classes = useStyles();

    const renderDeparture = (date, title) => `${date ? fullDateTime(date) : messages.NO_DATA} (${title})`;

    const currentEmails = [
        ...(item.organization?.emails || []),
        ...(item.organization?.persons || [])
    ];

    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit className="info">
            <div className={classes.column}>
                <strong>{titles.ORGANIZATION}: </strong>
                <div className={classes.wrap}>
                    <span><strong>{titles.NAME}: </strong>{item.organization?.title || messages.NO_DATA}</span>
                    <span><strong>{titles.INN}: </strong>{item.organization?.inn || messages.NO_DATA}</span>
                    <strong>{titles.EMAIL}: </strong>
                    <div className={classes.wrap}>
                        {currentEmails.length > 0
                            ? currentEmails.map((el, i) => (
                                <div key={i} className={classes.column}>
                                    <span><strong>- {titles.FIRST_NAME}: </strong>{el?.person || messages.NO_DATA}</span>
                                    <span><strong>- {titles.EMAIL}: </strong>{el?.data || el?.email|| messages.NO_DATA}</span>
                                </div>
                            ))
                            : <span>{messages.DATA_IS_NOT_FOUND}</span>
                        }
                    </div>
                    <strong>{titles.PHONE_NUMBER}: </strong>
                    <div className={classes.wrap}>
                        {item.organization?.phones?.length > 0
                            ? item.organization?.phones.map((el, i) => (
                                <div key={i} className={classes.column}>
                                    <span><strong>- {titles.FIRST_NAME}: </strong>{el?.person || messages.NO_DATA}</span>
                                    <span><strong>- {titles.PHONE_NUMBER}: </strong>{el?.data || messages.NO_DATA}</span>
                                </div>
                            ))
                            : <span>{messages.DATA_IS_NOT_FOUND}</span>
                        }
                    </div>
                </div>
                <strong>Доставка по запросу: </strong>
                {item.delivery_on_request && Object.keys(item.delivery_on_request)?.length > 0
                    ? <div className={classes.wrap}>
                        <span><strong>- Заказчик: </strong>{item.delivery_on_request.client || messages.NO_DATA}</span>
                        <span><strong>- Место подачи: </strong>{item.delivery_on_request.place || messages.NO_DATA}</span>
                        <span><strong>- Время начала: </strong>{getTimeWithoutSeconds(item.delivery_on_request.start) || messages.NO_DATA}</span>
                        <span><strong>- Время конца: </strong>{getTimeWithoutSeconds(item.delivery_on_request.end) || messages.NO_DATA}</span>
                    </div>
                    : <span>{messages.DATA_IS_NOT_FOUND}</span>
                }
                <strong>Выезд и возвращение: </strong>
                {item.departure_return && Object.keys(item.departure_return)?.length > 0
                    ? item.departure_return?.map((i, indeex) => (
                        <div className={classes.wrap}>
                            <span><strong>- Смена: </strong>{i.shift || messages.NO_DATA}</span>
                            <span>
                                <strong>- Выезд: </strong>
                                <div>
                                    <div>{renderDeparture(i.departure_plan, 'плановое')}</div>
                                    <div>{renderDeparture(i.departure_fact, 'фактическое')}</div>
                                </div>
                            </span>
                            <span>
                                <strong>- Возвращение: </strong>
                                <div>
                                    <div>{renderDeparture(i.return_plan, 'плановое')}</div>
                                    <div>{renderDeparture(i.return_fact, 'фактическое')}</div>
                                </div>
                            </span>
                        </div>
                    ))
                    : <span>{messages.DATA_IS_NOT_FOUND}</span>
                }
                <strong>Показания спидометра: </strong>
                <div className={classes.wrap}>
                    <span><strong>- При выезде: </strong>{item.speed_departure || messages.NO_DATA}</span>
                    <span><strong>- При возвращении: </strong>{item.speed_arrival || messages.NO_DATA}</span>
                </div>
                <span><strong>Водитель: </strong>{item.driver || messages.NO_DATA}</span>
                <span><strong>Диспетчер: </strong>{item.dispatcher || messages.NO_DATA}</span>
                <span><strong>Регистрационный №: </strong>{item.registration_number || messages.NO_DATA}</span>
                <span><strong>Лицензионная карточка: </strong>{item.licence_card_text || messages.NO_DATA}</span>
                <span><strong>Сведения о перевозке: </strong>{item.transportation_info || messages.NO_DATA}</span>
                <span><strong>Отметка о приеме автобуса при возвращении: </strong>{item.leasing_return_check || messages.NO_DATA}</span>
                <span><strong>Отметка о сдаче автобуса при смене водителя: </strong>{item.leasing_check || messages.NO_DATA}</span>
                <span><strong>Дата и время проведения предрейсового контроля: </strong>{fullDateTime(item.inspection_before) || messages.NO_DATA}</span>
                <span><strong>Предрейсовый контроль технического состояния пройден: </strong>{/*fullDateTime(item.inspection_done)*/item.inspection_done || messages.NO_DATA}</span>
                <span><strong>Cерия: </strong>{item.serial || messages.NO_DATA}</span>
                <div>
                    <span style={{ marginRight: 15 }}><strong>Марка ТС: </strong>{item.mark || messages.NO_DATA}</span>
                    {/*TODO откуда брать model?*/}
                    <span><strong>Номер ГРЗ: </strong>{item?.work_order?.vehicle?.number || messages.NO_DATA}</span>
                </div>
            </div>
        </Collapse>
    );
};

export default WaybillsInfo;
