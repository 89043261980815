import { useEffect, useState } from 'react';

import useTransportColorSpeed from 'helpers/hooks/TransportSpeedColor';
import messages from 'helpers/constants/messages';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout//PageLayout';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';
import Item from './Item';

// компонент страницы цвета скорости
const TransportSpeedColor = (props) => {
    // const { permissions } = useContext(Context);
    const {
        // микросервис конфиг
        microservice,
        // доступы
        // permissions,
    } = props;
    const colorProvider = useTransportColorSpeed(microservice);
    const [openModal, setOpenModal] = useState(false);

    const reloadList = (isDelete) => {
        isDelete && colorProvider?.list?.data?.length === 1 && colorProvider.props.page > 1
            ? colorProvider.load({ ...colorProvider.props, page: colorProvider.props.page - 1 })
            : colorProvider.reload();
    };

    useEffect(() => {
        colorProvider.load(colorProvider.props);
    }, [colorProvider.props]);

    const renderContent = () => {
        return (
            colorProvider?.list?.data?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: 'Цвет', width: '5%' },
                            { title: 'Скорость', width: '15%' },
                            { title: 'Действия', align: 'right', isActions: true }
                        ]}>

                        {colorProvider?.list?.data?.map(item => (
                            <Item
                                key={item?.id}
                                data={item}
                                colorProvider={colorProvider}
                                onChanged={() => colorProvider.reload()}
                                reloadList={reloadList}
                            />
                        ))}
                    </LSContainer>
                ) 
                : (!colorProvider.loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <>
            <PageLayout
                header="Цветовое обозначение скорости"
                // не по чему фильтровать
                // filters={<Filter colorProvider={colorProvider} />}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                    // todo права на добавление
                                    // disabled: !permissions?.is_create
                                },
                            ]}
                            positionLeft
                            noPadding
                        />

                    ),
                    total: colorProvider?.list?.meta?.total
                }}

                loading={colorProvider.loading}

                content={renderContent}

                paginationProps={{
                    loadList: (page, limit) => colorProvider.setProps({ ...colorProvider.props, page, limit }),
                    list: colorProvider?.list?.meta,
                    limit: colorProvider?.props?.limit,
                    setLimit: (limit) => colorProvider.setProps({ ...colorProvider.props, limit })
                }}
            />
        
            {openModal && (
                <ModalForm
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    onChanged={() => colorProvider.reload()}
                    colorProvider={colorProvider}
                />
            )}
        </>
    );
};

export default TransportSpeedColor;