import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import messages from 'helpers/constants/messages';
import { deleteRepairObject } from 'redux/RoadWorks/actions';
import { LSContentColumn, LSContentItem } from 'components/common/ListStyle';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';

const Item = ({ item, onClickItem, selectedItem, reloadList, isMobile }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleDelete = () => {
        dispatch(deleteRepairObject(item?.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem
                style={selectedItem ? { backgroundColor: '#f5f5f5' } : { backgroundColor: '' }}
                onClick={onClickItem}
                isMobile={isMobile}
            >
                <LSContentColumn title="Название" width="30%">
                    {item?.name || messages.INFO_IS_NOT_FOUND}
                </LSContentColumn>
                <LSContentColumn align="right" isActions>
                    <FormButtonsComponent
                        noMarginLeft
                        justButton
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    setOpenEditModal(true);
                                },
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    setOpenDeleteModal(true);
                                },
                            }
                        ]}
                    />
                    <IconButton size="small">
                        {selectedItem
                            ? <ArrowBackIosIcon fontSize="inherit"/>
                            : <ArrowForwardIosIcon fontSize="inherit"/>
                        }
                    </IconButton>
                </LSContentColumn>
            </LSContentItem>

            {openEditModal && (
                <ModalForm
                    open={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}
            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onSuccess={handleDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
