import { Collapse } from '@mui/material';
import RoadSectionRelationList from '../../../../common/RoadSectionRelationList';

const Info = ({ isOpen, data }) => {
    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            <h2>Описание:</h2>
            <div>{data?.description ? data?.description : 'Описание отсутствует'}</div>
            <RoadSectionRelationList roadSectionId={data?.id} readOnly />
        </Collapse>
    );
};

export default Info;