import Aip from '../../pages/Dictionaries/Aip';

const routes = [
    {
        path: '/dictionaries/aip',
        component: Aip,
        exact: true,
    }
];

export default routes;