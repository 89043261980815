// иконка для списка
const iconCluster = (props) => {
    return `
        <svg width="30" height="30" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.80571 0.356033C4.18476 1.71794 0 7.09127 0 12.9846C0 20.0417 5.94286 25.9598 13 25.9598C20.0571 25.9598 26 20.017 26 12.9598C26 7.09127 21.741 1.66841 16.0705 0.331269C14.1143 -0.114445 11.7371 -0.114444 9.80571 0.356033V0.356033Z" fill="${props?.fill || '#e7e7e7'}"/>
            <path d="M20.9183 9.44351V18.2983C20.9183 19.113 20.2498 19.774 19.4259 19.774H6.49234C5.66844 19.774 5 19.113 5 18.2983V9.44351C5 8.62875 5.66844 7.96772 6.49234 7.96772H9.22829L9.6107 6.95619C9.82833 6.38125 10.3849 6 11.0067 6H14.9085C15.5303 6 16.0868 6.38125 16.3045 6.95619L16.69 7.96772H19.4259C20.2498 7.96772 20.9183 8.62875 20.9183 9.44351ZM16.69 13.8709C16.69 11.8355 15.0173 10.1814 12.9591 10.1814C10.901 10.1814 9.22829 11.8355 9.22829 13.8709C9.22829 15.9062 10.901 17.5604 12.9591 17.5604C15.0173 17.5604 16.69 15.9062 16.69 13.8709ZM15.6951 13.8709C15.6951 15.362 14.467 16.5765 12.9591 16.5765C11.4513 16.5765 10.2232 15.362 10.2232 13.8709C10.2232 12.3797 11.4513 11.1653 12.9591 11.1653C14.467 11.1653 15.6951 12.3797 15.6951 13.8709ZM11.9642 13.8709C11.9642 13.3298 12.4119 12.887 12.9591 12.887C13.2327 12.887 13.4566 12.6657 13.4566 12.3951C13.4566 12.1245 13.2327 11.9032 12.9591 11.9032C11.8616 11.9032 10.9694 12.7856 10.9694 13.8709C10.9694 14.1414 11.1932 14.3628 11.4668 14.3628C11.7404 14.3628 11.9642 14.1414 11.9642 13.8709Z" fill="${props?.innerFill || 'rgba(0, 0, 0, 0.54)'}"/>
        </svg>
    `;
};

export default iconCluster;
