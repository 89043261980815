const mapTitles = {
    SELECT_MARKER: 'Выберите маркер',
    LOCATION: 'Местоположение',
    PUT_MOVE_MARK: 'Поставьте/передвиньте метку на карте',
    EDITING_MAP: 'Редактирование карты',
    COORDINATES: 'Координаты',
    MARKER_TYPE: 'Тип маркера',
    LAT: 'Широта(lat)',
    LON: 'Долгота(lon)',
    ONLY_WATCHING_MAP: 'Только просмотр карты',
    STOP_AREA: 'Зона действия',
    STOP_AREA_STATION: 'Зона действия остановки',
    SELECT_AREA_ON_MAP: 'Выбрать область на карте',
    CAMERA_LOCATION_ON_MAP:'Расположение камеры на карте'
};

export default mapTitles;
