import { useState } from 'react';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';
import { TextField } from '@mui/material';
import titles from '../../../../../helpers/constants/titles';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';

const Filter = ({ setParams, setCurrentObject }) => {
    const initState = {
        name: '',
    };
    
    const [data, setData] = useState(initState);

    const prepareData = (filter = data) => removeEmptyFields({
        ...filter,
    });

    const resetFilter = (needRefresh) => {
        needRefresh && setParams({ page: 1, query: prepareData(initState) });
        needRefresh && setCurrentObject(null);
        setData(initState);
    };

    const updateFilter = (filters) => {
        setData({ ...initState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({
                page: 1,
                query: prepareData({ ...initState, ...filters })
            });
            setCurrentObject(null);
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={() => {setParams({ page: 1, query: prepareData() }); setCurrentObject(null);}}
            filter={data}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={data?.name}
                    onChange={({ target : { name, value } }) => setData({ ...data, [name]: value })}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    name="name"
                    type="text"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;