import { clearFiltersRoadSection } from '../../../../redux/RoadNetwork/actions';

const config = {
    name: 'Дорожная сеть',
    slug: 'road-network',
    // от статуса маркера
    mapMarkersColors: {
        default: '#a5a5a5',
    },
    // ширина линии
    lineWidth: {
        default: 4,
        thin: 4,
        normal: 7,
        thick: 11,
    },
    mapContextMenu: {
        title: 'Добавить элемент сети',
        event: 'add_road_network',
        minWidth: 210
    },
    onAfterActivateAction: () => clearFiltersRoadSection(),
    layerIcon: 'far fa-road',
};
// добавление на карте
export default config;
