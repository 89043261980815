import { useEffect } from 'react';
import { Grid } from '@mui/material';
import Layer from '../../../MapComponents/Layers/Board/layer';
import layerConfig from '../../../MapComponents/Layers/Board/config';
import CardWidget from '../../../common/Showcase/CardWidget';
import { loadSidebar } from '../../../../redux/Boards/actions';
import { boardsSelectors } from '../../../../redux/Boards';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import Loading from '../../../common/Loading';
import BoardItem from './BoardItem';
import CardMap from '../../../common/Showcase/CardMap';

// страница
const Page = () => {
    const dispatch = useDispatch();

    const data = useSelector(boardsSelectors.sidebarData);
    const meta = useSelector(boardsSelectors.sidebarMeta);
    const loading = useSelector(boardsSelectors.sidebarLoading);

    const handleScrollUpdate = (values) => {
        const { current_page = 0, last_page = 0 } = meta;
        if (values.top > 0.98 && loading === false && current_page < last_page) {
            dispatch(loadSidebar(current_page + 1, 10));
        }
    };

    useEffect(() => {
        dispatch(loadSidebar(1, 10));
    }, [dispatch]);

    return (
        <>
            <Grid container spacing={2} sx={{ height: '100%' }}>
                <Grid item xs={12} sm={6} md={4} className="showcase_grid_flex-column">

                    <CardWidget
                        icon={layerConfig.layerIcon}
                        title={'ДИТ и ЗПИ'}
                        fillOthers
                        small
                    >
                        <Scrollbars
                            onUpdate={handleScrollUpdate}
                        >
                            {data.length > 0 && (
                                data.map(item => (
                                    <BoardItem
                                        key={item.id}
                                        data={item}
                                    />
                                ))
                            )}
                            {loading && <Loading className="center" />}
                        </Scrollbars>
                    </CardWidget>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={8}
                    className="showcase_grid_flex-column"
                >
                    <CardMap
                        fillOthers
                        isCluster
                    >
                        <Layer readOnly visibleLegend/>
                    </CardMap>
                </Grid>
            </Grid>
        </>
    );
};

export default Page;
