import { useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import { loadComplexTypeGrouping } from 'redux/DorisControl/actions';
import formatDate from 'helpers/constants/dateFormat';
import { dateWithDashYYYYMMDD, getEndOf, getStartOf } from 'helpers/date.config';
import useValidation from 'helpers/hooks/useValidation';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import removeEmptyFields from 'helpers/removeEmptyFields';
import DateRange from 'components/common/Dates/DateRange';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';
import GovernmentContractList from 'components/common/Autocomplete/GovernmentContract';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import { PageReportFields, useReportTemplatesFrontendLinks } from 'components/common/PageReport';

interface ItemType {
    id: number;
    number: number | string,
}

interface FilterState {
    start_date: Date | null;
    end_date: Date | null;
    complex_id_list: ItemType[];
    organization_id_list: ItemType[];
    contract_id_list: ItemType[];
    type_grouping: number | null;
    checked_only: boolean;
}

const initialState: FilterState = {
    start_date: getStartOf('month'),
    end_date: getEndOf('month'),
    complex_id_list: [],
    organization_id_list: [],
    contract_id_list: [],
    type_grouping: null,
    checked_only: false,
};

const Filter = () => {
    const validation = useValidation();
    const templateProvider = useReportTemplatesFrontendLinks();
    const reportFilter = templateProvider.filter;

    const [filters, setFilters] = useState<FilterState>(initialState);
    const [isDisabled, setIsDisabled] = useState(false);

    const complex_type_grouping = useStoreProp(loadComplexTypeGrouping, 'dorisControl', 'complex_type_grouping');

    const handleChange = <K extends keyof FilterState>(name: K, value: FilterState[K]) => {
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
        validation.deleteKey(name);
    };

    const download = () => {
        const filter = removeEmptyFields({
            ...filters,
            start_date: dateWithDashYYYYMMDD(filters.start_date),
            end_date: dateWithDashYYYYMMDD(filters.end_date),
            complex_id_list: filters.complex_id_list?.map(({ id }) => id),
            organization_id_list: filters.organization_id_list?.map(({ id }) => id),
            contract_id_list: filters.contract_id_list?.map(({ id }) => id),
        }, false);

        const { url, formats } = reportFilter.getToApi();

        templateProvider.loadReport(url, formats, filter);
    };

    const disabledButton = isDisabled
        || !filters.start_date
        || !filters.end_date
        || reportFilter.isDisabled;

    const resetFilter = () => {
        setFilters(initialState);
        reportFilter.setFormats([]);
        validation.clear();
    };

    const updateFilter = (filter: Partial<FilterState>) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            ...filter,
        }));
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            buttonType="getReport"
            onSearch={download}
            filter={filters}
            filterException={['start_date', 'end_date']}
            setUserFilter={updateFilter}
            disabled={disabledButton}
            isFilterVisible={true}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filters.start_date}
                    valueEndDate={filters.end_date}
                    handleDate={(date) => handleChange('start_date', date)}
                    handleEndDate={(date) => handleChange('end_date', date)}
                    isDisabledHandler={setIsDisabled}
                    pickersFormat={formatDate.DATE_FOR_PICKER}
                    renderError={() => (validation.isKey('start_date') || validation.isKey('end_date'))}
                    errorMessage={() => validation.get('start_date') || validation.get('end_date')}
                    dateOnly
                />
                <PageReportFields
                    reportType={reportFilter.type}
                    reportFormats={reportFilter.formats}
                    onReportType={reportFilter.setType}
                    onReportFormat={reportFilter.setFormats}
                    isOneTypeAuto
                    error={!reportFilter?.formats?.length}
                    required
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <SelectCompany
                    multiple
                    selected={filters.organization_id_list}
                    onChange={(value) => handleChange('organization_id_list', value as ItemType[])}
                    label="Балансодержатель"
                    filter={{ withDeleted: 1 }}
                />
                <SelectComplex
                    multiple
                    selected={filters.complex_id_list}
                    onChange={(value) => handleChange('complex_id_list', value as ItemType[])}
                />
                <GovernmentContractList
                    label="Госконтракт"
                    multiple
                    selected={filters.contract_id_list}
                    onChange={(value) => handleChange('contract_id_list', value as ItemType[])}
                />
                <FormControl size="small" variant="outlined" fullWidth>
                    <InputLabel>Тип группировки Комплекса</InputLabel>
                    <Select
                        value={filters.type_grouping || ''}
                        required
                        label="Тип группировки Комплекса"
                        onChange={(e) => handleChange('type_grouping', Number(e.target.value))}
                        name="type_grouping"
                    >
                        {Object.keys(complex_type_grouping)?.length
                            ? Object.keys(complex_type_grouping)?.map((key) => (
                                <MenuItem value={Number(key)} key={key}>{complex_type_grouping[key]}</MenuItem>
                            ))
                            : <MenuItem value="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters.checked_only}
                            onChange={(e) => handleChange('checked_only', e.target.checked)}
                            name="checked_only"
                        />
                    }
                    label="Принятые в эксплуатацию"
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
