import { getFullDateTimeWithTextMonth } from '../../../../../helpers/date.config';
import messages from '../../../../../helpers/constants/messages';
import config from '../config';

const Item = (props) => {
    const {
        name,
        registered_at,
        type_text,
        status_id,
        status_text,
        threat_level,
        address_text,
        statuses = {},
    } = props;

    const { name: threatName  = null, color: threatColor = null } = threat_level || {};

    return (
        <>
            <div>
                {registered_at ? getFullDateTimeWithTextMonth(registered_at) : ''}
            </div>

            <div>
                <strong>Наименование: </strong>
                <span>{name}</span>
            </div>

            <div>
                <strong>Адрес: </strong>
                <span>{address_text || messages.NO_DATA}</span>
            </div>

            <div>
                <strong>Тип: </strong>
                <span>{type_text}</span>
            </div>

            <div>
                <strong>Статус: </strong>
                <span style={{ color: statuses?.[status_id]?.color || config.mapMarkersColors.default }}>{status_text}</span>
            </div>

            <div>
                <strong>Уровень угрозы: </strong>
                <span style={{ color: threatColor }}>{threatName || messages.NO_DATA}</span>
            </div>

        </>
    );
};

export default Item;