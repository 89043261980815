import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';

import mapHelper from 'helpers/mapHelper';
import { usePrevious, useDebounce } from 'helpers/hooks';
import getFilters from 'components/MapComponents/helpers/getFilters';
import {
    ContextMenuItem,
    GeoJsonNew,
} from 'components/MapComponents/leaflet';
import * as actions from 'redux/Parking/actions';
import { parkingSelectors } from 'redux/Parking';
import { getFilter, getColor, createIconMarker } from './helper';
import config from './config.js';
import CollectorForms from './CollectorForms';
import ItemPopup from './PopUp';

const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();

    const polygon = useSelector(parkingSelectors.polygon);
    const active = useSelector(parkingSelectors.active);
    const filters = useSelector(parkingSelectors.filters);
    const saved = useSelector(parkingSelectors.saved);
    const loadingPolygon = useSelector(parkingSelectors.polygonLoading);
    const prevLoadingPolygon = usePrevious(loadingPolygon);

    const showPopup = useRef(0);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getPolygon(map);
        const filter = getFilters(filters, getFilter);
        dispatch(actions.loadPolygon(polygon, filter));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();

    // добавить новый
    // const handleAdd = ({ lat, lng }) => {
    //     dispatch(actions.setEditForm({
    //         // todo надо думать как координату использовать
    //         lat,
    //         lon: lng,
    //     }));
    // };

    useEffect(() => {
        if (saved) {
            map.closeContextMenu();
            fetchPolygon();
        }
    }, [saved]);

    useEffect(() => {
        fetchPolygon();
    }, [filters]);

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(actions.clearActive());

            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters]);

    useCallback(() => () => {
        dispatch(actions.clearPolygon());
        // dispatch(actions.clearActive());
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { location } = active;

            if (location && location?.geometry?.coordinates.length > 0) {
                showPopup.current = active.id;
                const b = L.geoJSON(location).getBounds();
                map.fitBounds(b);
            }

            // setSelectedItem(active?.id || null);
        }
    }, [active]);

    useEffect(() => {
        if (
            showPopup.current
            && prevLoadingPolygon === true
            && loadingPolygon === false
        ) {
            setTimeout(() => {
                map.fire(`showBy${config.slug}${showPopup.current}`);
                showPopup.current = 0;
            }, 200);
        }
    }, [loadingPolygon, prevLoadingPolygon]);

    // меню маркера, линии, полигона при клике
    const renderContextMenu = (item, rcmProps) => {
        return (
            <div>
                <ContextMenuItem
                    {...rcmProps}
                    value="Редактировать"
                    onClick={() => {
                        map.fire('context_menu_close');
                        const currentItem = polygon
                            ?.find(({ id }) => id === item.id) || null;
                        dispatch(actions.setEditForm(currentItem));
                    }}
                />
                <ContextMenuItem
                    {...rcmProps}
                    value="Удалить"
                    onClick={() => {
                        map.fire('context_menu_close');
                        dispatch(actions.setDeleteForm(item.id));
                    }}
                    className="red"
                />
            </div>
        );
    };

    // const geoItem = useMemo(() => {
    //     const item = polygon
    //         ?.find(({ id }) => id === selectedItem);
    //
    //     if (selectedItem && item) {
    //         const {
    //             location,
    //             id,
    //             name,
    //         } = item ||  {};
    //
    //         return {
    //             ...location,
    //             properties: {
    //                 ...location.properties,
    //                 id,
    //                 name,
    //                 // attribution: {
    //                 //     slug: config.slug,
    //                 //     color: getColorByStatus(props.status)
    //                 // },
    //             },
    //             // style: {
    //             //     color: getColorByStatus(props.status),
    //             //     weight: 7
    //             // }
    //         };
    //     }
    //     return null;
    // }, [selectedItem, polygon]);
    //
    // const handleClick = (id) => (e) => {
    //     setSelectedItem(id || null);
    // };

    // собираем геообъект
    const geometry = useMemo(() => polygon
        ?.map(({ id, name, location = {} }) => ({
            ...location,
            properties: {
                id,
                name,
                color: getColor(),
            },
            attribution: {
                slug: config.slug,
                color: getColor()
            },
            style: {
                color: getColor(),
            }
        }))
    , [polygon]);

    return (
        <>
            {/* геообъекты маркерами */}
            {/*{polygon?.map(item => {*/}
            {/*    // чистый маркер*/}
            {/*    if (*/}
            {/*        item?.location?.geometry?.type === 'Point'*/}
            {/*        && !item?.location?.properties?.radius*/}
            {/*    ) {*/}
            {/*        return (*/}
            {/*            <Marker*/}
            {/*                {...props}*/}
            {/*                icon={createIconMarker()}*/}
            {/*                key={item.id}*/}
            {/*                latlng={[item.lat, item.lon]}*/}
            {/*                onClick={handleClick(item?.id)}*/}
            {/*            >*/}
            {/*                <ToolTip*/}
            {/*                    direction="top"*/}
            {/*                    offset={[0, -40]}*/}
            {/*                >*/}
            {/*                    <div>{item.name}</div>*/}
            {/*                </ToolTip>*/}
            {/*                <PopUp>*/}
            {/*                    <ItemPopup id={item.id} />*/}
            {/*                </PopUp>*/}
            {/*            </Marker>*/}
            {/*        );*/}
            {/*    }*/}
            {/*    // маркер геообъекта если не выбран*/}
            {/*    else if (selectedItem !== item?.id) {*/}
            {/*        return (*/}
            {/*            <Marker*/}
            {/*                {...props}*/}
            {/*                icon={createIconMarker()}*/}
            {/*                key={item.id}*/}
            {/*                latlng={[item.lat, item.lon]}*/}
            {/*                onClick={handleClick(item?.id)}*/}
            {/*            >*/}
            {/*                <ToolTip*/}
            {/*                    direction="top"*/}
            {/*                    offset={[0, -40]}*/}
            {/*                >*/}
            {/*                    <div>Кликните, чтобы отобразить объект</div>*/}
            {/*                </ToolTip>*/}
            {/*            </Marker>*/}
            {/*        );*/}
            {/*    }*/}
            {/*    return null;*/}
            {/*})}*/}

            {/*/!* выбранный геообъект (линия, полигон, окружность) *!/*/}
            {/*{geoItem && (*/}
            {/*    <GeoJsonNew*/}
            {/*        {...props}*/}
            {/*        parent={map}*/}
            {/*        data={geoItem}*/}
            {/*        icon={({ status }) => createIconMarker(status)}*/}
            {/*        contextMenuTemplate={renderContextMenu}*/}
            {/*        popUpTemplate={(data) => <ItemPopup {...data} />}*/}
            {/*        toolTipTemplate={({ name }) => <div>{name}</div>}*/}
            {/*        toolTipOptions={{*/}
            {/*            direction: 'top',*/}
            {/*            offset: [0, 0],*/}
            {/*            sticky: true,*/}
            {/*        }}*/}
            {/*        onClosePopup={() => {*/}
            {/*            // dispatch(actions.resetActiveRW());*/}
            {/*        }}*/}
            {/*        idPrefix={config.slug}*/}
            {/*        centerAfter={true}*/}
            {/*    />*/}
            {/*)}*/}

            {/* просто вывод */}
            <GeoJsonNew
                {...props}
                data={geometry}
                toolTipTemplate={({ name = '' }) => <div>{name ?? ''}</div>}
                icon={({ color }) => createIconMarker(color)}
                // contextMenuTemplate={renderContextMenu}
                idPrefix={config.slug}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, -5]
                }}
                popUpTemplate={(data) => <ItemPopup {...data} />}
                // onClosePopup={() => {
                //     if (!showPopup.current) {
                //         // dispatch(actions.clearActive());
                //     }
                // }}
            />

            {/* формы */}
            <CollectorForms />
        </>
    );
};

export default Layer;
