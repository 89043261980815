const types = {
    LINE: 1,
    PIE: 2,
    VERTICAL_BAR: 3,
    HORIZONTAL_BAR: 4,
    TABLE: 5,
    CARD: 6,
    DOUGHNUT: 7,
};

export default types;