import { config } from '../../config';

const apiTrafficFlowUrl = () => config.get('urls').apiTrafficFlowUrl;

const ApiUrls = {
    getPolygon: () => `${apiTrafficFlowUrl()}/detector/polygon`,
    getStatuses: () => `${apiTrafficFlowUrl()}/v2/detector/statuses`,
    getTypes: () => `${apiTrafficFlowUrl()}/detector/types`,
    getList: () => `${apiTrafficFlowUrl()}/detector`,
    getSidebar: () => `${apiTrafficFlowUrl()}/detector`,
    getTraffic: () => `${apiTrafficFlowUrl()}/detector/traffic`,

    getTrafficInfo: (id) => `${apiTrafficFlowUrl()}/v2/detector/traffic/${id}`,

    getReport: () => `${apiTrafficFlowUrl()}/v2/report/statistic`,

    getReportGroups: () => `${apiTrafficFlowUrl()}/report/groups`,
    getReportTypes: () => `${apiTrafficFlowUrl()}/report/types`,
    // замещаются на
    getReportStatisticType: () => `${apiTrafficFlowUrl()}/v2/report/statistic/type`,
    getReportTimeSlot: () => `${apiTrafficFlowUrl()}/v2/report/time-slot`,

    getTransportFlowReport: () => `${apiTrafficFlowUrl()}/v2/report`,
    getTransportFlowGroups: () => `${apiTrafficFlowUrl()}/v2/report/type`, // `${apiTrafficFlowUrl()}/report/groups/new`
    // getTransportFlowTypes: () => `${apiTrafficFlowUrl()}/detector/traffic`,

    getThroughput: () => `${apiTrafficFlowUrl()}/graphs/bandwidth`,

    getHeatMap: () => `${apiTrafficFlowUrl()}/v2/report/heat-map`,

    loadShowcase: () => `${apiTrafficFlowUrl()}/showcase`,

    loadReportIndicators: () => `${apiTrafficFlowUrl()}/v2/report/indicators`,
    // loadReportIndicators: () => `${apiTrafficFlowUrl()}/report/indicators`,

    loadTrafficFlowByRadius: () => `${apiTrafficFlowUrl()}/detector/radius`,

    createLogicalPuid: () => `${apiTrafficFlowUrl()}/detector/create`,

    editLogicalPuid: (id) => `${apiTrafficFlowUrl()}/detector/${id}/update`,

    deleteLogicalPuid: (id) => `${apiTrafficFlowUrl()}/detector/${id}/delete`,

    loadPuidByIds: () => `${apiTrafficFlowUrl()}/detector`,

    loadPuidByRadius: () => `${apiTrafficFlowUrl()}/detector/radius`,

    getSettings: () => `${apiTrafficFlowUrl()}/settings`,

    editSettings: (id) => `${apiTrafficFlowUrl()}/settings/${id}`,

    editSettingsList: () => `${apiTrafficFlowUrl()}/settings/list`,

    getSettingsGroup: () => `${apiTrafficFlowUrl()}/settings_group`,

    editSettingsGroup: (id) => `${apiTrafficFlowUrl()}/settings_group/${id}/settings/update`,
};

export default ApiUrls;
