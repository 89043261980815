import React, { useState } from 'react';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import DefectList from './DefectList';
import DefectsModalForm from './DefectsModalForm';

const Defects = ({ site, onChange }) => {
    const [openDefectsModal, setOpenDefectsModal] = useState(false);

    return (
        <>
            <h2>Присвоенные недостатки:</h2>

            <DefectList
                items={site?.defections}
                setItems={onChange}
            />
            
            <div className="form__button">
                <FormButtons
                    buttons={[
                        {
                            ...(site?.defections.length ? buttonsTypes.change : buttonsTypes.add),
                            size: 'small',
                            disabled: !site.data,
                            onClick: () => setOpenDefectsModal(true),
                        },
                    ]}
                    positionLeft
                    noMarginLeft
                />
            </div>
            {openDefectsModal 
                && <DefectsModalForm
                    isOpen={openDefectsModal}
                    onClose={() => setOpenDefectsModal(false)}
                    site={site}
                    onChange={onChange}
                />
            }  
        </>
    );
};

export default Defects;
