import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiMethods = {

    getCompanies: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getCompanies(), {
            params: {
                page,
                limit,
                ...data,
            },
        });
        return response.data;
    },

    getAddressList: async (params) => {
        const response = await instance.get(apiUrls.getAddressList(),
            {
                params,
            }
        );
        return response.data;
    },

    getAddressAutocomplete: async (params) => {
        const response = await instance.get(apiUrls.getAddressAutocomplete(),
            {
                params
            }
        );
        return response.data;
    },

    getAddressByCoordinates: async (params) => {
        const response = await instance.get(apiUrls.getAddressByCoordinates(),
            {
                params
            }
        );
        return response.data;
    },

    getAddressDadata: async (params) => {
        const response = await instance.get(apiUrls.getAddressDadata(),
            {
                params
            }
        );
        return response.data;
    },

    getGeocodeAddress: async (params) => {
        const response = await instance.get(apiUrls.getGeocodeAddress(),
            {
                params
            }
        );
        return response.data;
    },

    getRegions: async (params) => {
        const response = await instance.get(apiUrls.getRegions(), { params });
        return response.data;
    },

    getGeocodeAddressItem: async (params) => {
        const response = await instance.get(apiUrls.getGeocodeAddressItem(), { params });
        return response.data;
    },

    getAddressAreasAutocomplete: async (params) => {
        const response = await instance.get(apiUrls.getAddressAreasAutocomplete(), { params: { ...params.query } });
        return response.data;
    },
};

export default ApiMethods;
