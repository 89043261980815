import createIconCluster from '../../helpers/createIconCluster';

const config = {
    name: 'Парковочное пространство',
    slug: 'parking',
    // mapContextMenu: {
    //     title: 'Добавить парковку',
    //     event: 'add_parking',
    //     minWidth: 210
    // },
    mapMarkersColors: { // от статуса маркера
        default: '#0081d6',
    },
    layerIcon: 'fas fa-parking',
    clusterIcon: () => createIconCluster('fas fa-parking'),
    // layerIcon: 'far fa-parking',
};
// добавление на карте
export default config;
