import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, ListItem, ListItemText, Tooltip, } from '@mui/material';
import { ExpandMore, ExpandLess, Comment } from '@mui/icons-material';

import messages from 'helpers/constants/messages';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import {
    deleteMessage
} from 'redux/Boards/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';
import PreviewMessage from '../MessageHelpers/PreviewMessage';
import Info from './Info';

const Item = ({ message = {}, reloadList, currentBoard }) => {
    const dispatch = useDispatch();
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const [isInfo, setIsInfo] = useState(false);

    const handleInfo = () => {
        setIsInfo(!isInfo);
    };

    const handleConfirmDelete = () => {
        const dit_ids = message?.boards?.map(({ id }) => id);
        dispatch(deleteMessage(message?.id, dit_ids));
        setIsDelete(false);
    };

    const [isShowPreviewMessage, setIsShowPreviewMessage] = useState(false);

    return (
        <>
            <ListItem className="list_item">
                <ListItemText onClick={handleInfo}>
                    <h2>
                        {message?.header || messages.INFO_IS_NOT_FOUND}
                    </h2>
                    <div>
                        <strong>
                            Начало показа:
                        </strong>&nbsp;
                        {fullDateTimeWithoutSeconds(message?.start_at) || messages.NO_DATA}
                    </div>
                    <div>
                        <strong>
                            Кол-во показов:
                        </strong>&nbsp;
                        {message?.text_count || messages.NO_DATA}
                        {' '}
                        <strong>
                        Время показа в секундах:
                        </strong>&nbsp;
                        {message?.duration || messages.NO_DATA}
                    </div>
                    <div>
                        <strong>
                            Приоритет:
                        </strong>&nbsp;
                        {message?.priority_text || messages.NO_DATA}
                    </div>
                </ListItemText>
                <Tooltip title="Просмотр сообщения">
                    <IconButton onClick={() => setIsShowPreviewMessage(true)} size="medium">
                        <Comment style={{ color: '#148709' }}  fontSize="inherit" />
                    </IconButton>
                </Tooltip>
                <FormButtonsComponent
                    noMarginLeft
                    justButton
                    buttons={[
                        {
                            ...buttonsTypes.copyIcon,
                            onClick: () => setIsCopy(true)
                        },
                        ...(!message?.service
                            ? [{
                                ...buttonsTypes.editIcon,
                                onClick: () => setIsEditMode(true)
                            }]
                            : []
                        ),
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick:() => setIsDelete(true)
                        }
                    ]}
                />
                <IconButton size="small" onClick={handleInfo}>
                    {isInfo
                        ? <ExpandLess fontSize="inherit" />
                        : <ExpandMore fontSize="inherit" />
                    }
                </IconButton>
            </ListItem>
            {isInfo && (
                <Info
                    isOpen={isInfo}
                    data={message}
                />
            )}
            {isDelete &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={isDelete}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setIsDelete(false)}
                />
            )}
            {isCopy && (
                <ModalForm
                    isOpen={isCopy}
                    onClose={() => setIsCopy(false)}
                    isNew={false}
                    isCopy={true}
                    // priorities={priorities}
                    item={message}
                    reloadList={reloadList}
                    boardsProp={currentBoard && [currentBoard]}
                />
            )}
            {isEditMode && (
                <ModalForm
                    isOpen={isEditMode}
                    onClose={() => setIsEditMode(false)}
                    isNew={false}
                    isCopy={false}
                    // priorities={priorities}
                    item={message}
                    reloadList={reloadList}
                    boardsProp={currentBoard && [currentBoard]}
                />
            )}
            {isShowPreviewMessage && (
                <PreviewMessage
                    isOpen={isShowPreviewMessage}
                    onClose={() => setIsShowPreviewMessage(false)}
                    imgPreview={message?.base64}
                    boardForPreview={message?.boards[0]}
                />
            )}
        </>
    );
};

export default Item;
