import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { getHumanDate } from 'helpers/date.config';
import renderAddress from 'helpers/renderAddress';
import { setShowReturnTo } from 'redux/Menu/actions';
import * as actions from 'redux/RoadWorks/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem, ActionMore } from 'components/common/List';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import Modal from 'components/common/Modal';
import Info from 'components/pages/Dictionaries/RoadWorks/Sites/Info';

import DatesModal from './DatesModal';
import EditorForm from './EditorForm';

const Item = ({ item, typeCreation, statuses, reloadList }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [openMap, setOpenMap] = useState(false);
    const [openDatesModal, setOpenDatesModal] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);

    const handleCloseEditForm = () => {
        dispatch(actions.setEditForm());
        setEditOpen(false);
    };

    const handleDelete = () => {
        dispatch(actions.deleteRW(item.id, () => {
            reloadList(true);
            setConfirmOpen(false);
        }));
    };

    const goToOrdersPage = (id) => {
        dispatch(setShowReturnTo(history?.location?.pathname || '/map', 'Назад к Участкам'));
        history.push({ pathname: '/dictionaries/roadworks/orders', state: { id } });
    };

    return (
        <>
            <LSContentItem style={{ cursor: 'pointer' }} onClick={() => setOpenInfo(!openInfo)}>
                <LSContentColumn>
                    <Tooltip title={statuses[item.status]}>
                        <i style={{ fontSize: 25, color: item?.color || '' }} className="fad fa-digging" />
                    </Tooltip>
                </LSContentColumn>

                <LSContentColumn>{item.name || messages.NO_VALUE}</LSContentColumn>

                <LSContentColumn>
                    {item?.address ? renderAddress(item?.address) : messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item?.fixated_at ? getHumanDate(item?.fixated_at) : messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item?.orders?.length > 0
                        ? item.orders.map(({ id }, index) => (
                            <Fragment key={id}>
                                <Tooltip title="Перейти в справочник">
                                    <Button
                                        style={{ minWidth: 'auto' }}
                                        size="small"
                                        onClick={() => goToOrdersPage(id)}
                                    >
                                        {id}
                                    </Button>
                                </Tooltip>

                                {index + 1 < item?.orders?.length ? ', ' : ''}
                            </Fragment>
                        ))
                        : messages.NO_VALUE
                    }
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <i className="fas fa-chart-bar"/>,
                                name: 'Анализ пропускной способности',
                                onClick: () => setOpenDatesModal(true),
                            },
                            {
                                ...buttonsTypes.mapIcon,
                                onClick: () => setOpenMap(true),
                            },
                            ...(item.status !== 3
                                ? [{
                                    ...buttonsTypes.editIcon,
                                    onClick: () => setEditOpen(true),
                                }]
                                : []
                            ),
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setConfirmOpen(true),
                            }
                        ]}
                    />

                    <ActionMore isOpen={openInfo} onClick={() => setOpenInfo(!openInfo)} />
                </LSContentColumn>
            </LSContentItem>

            {openMap && (
                <Modal
                    isOpen={openMap}
                    onClose={() => setOpenMap(false)}
                    noPadding
                    title="Участки работ"
                    buttons={
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: () => setOpenMap(false),
                                },
                            ]}
                        />
                    }
                >
                    <MapGeoJson geometry={item.data.geometry} readOnly={true} />
                </Modal>
            )}

            {openDatesModal && (
                <DatesModal
                    isOpen
                    onClose={() => setOpenDatesModal(false)}
                    detectors={item.detectors}
                />
            )}

            {confirmOpen && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={confirmOpen}
                    onSuccess={handleDelete}
                    onClose={() => setConfirmOpen(false)}
                />
            )}

            {editOpen && (
                <EditorForm
                    noPadding
                    title={titles.EDIT}
                    isOpen={editOpen}
                    dataForm={item}
                    onClose={handleCloseEditForm}
                    onSuccess={(editValue) => {
                        dispatch(
                            actions.editRW(editValue.id, editValue, () => {
                                reloadList();
                                setEditOpen(false);
                            })
                        );
                    }}
                    inModal
                    isNew={false}
                />
            )}

            {openInfo && <Info isOpen={openInfo} item={item} />}
        </>
    );
};

export default Item;
