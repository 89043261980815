import React from 'react';
import styles from './styles.module.scss';
import GraphicLineBar from './GraphicLineBar';

// страница всех графиков
const Graphic = ({
    list = [],
    title = '',
}) => {
    return (
        <div className={styles.card}>
            <h2 className={styles.header}>{title}</h2>
            <div className={styles.data}>
                {list?.map((item, index) => (
                    <GraphicLineBar key={index} item={item} />
                ))}
            </div>
        </div>
    );

};

export default Graphic;
