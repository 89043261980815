import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roadNetworksSelectors } from 'redux/RoadNetwork';
import { deletePAK } from 'redux/RoadNetwork/actions';
import {
    ActionMore,
    ActionsButtons,
    LSContentColumn,
    LSContentItem
} from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import messages from 'helpers/constants/messages';
import ModalForm from './ModalForm';
import type { PakItem } from 'types/RoadNetwork';
import Info from './Info';

interface ItemProps {
    item: PakItem
    reloadList: () => void
}

const Item = ({ item, reloadList }: ItemProps) => {
    const dispatch = useDispatch();
    const  loadingButton  = useSelector(roadNetworksSelectors.loadingButton);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isInfo, setIsInfo] = useState(false);

    const handleInfo = () => setIsInfo(!isInfo);

    const handleDelete = () => {
        const callback = () => {
            setIsDelete(false);
            reloadList();
        };

        dispatch(deletePAK(item.id, callback));
    };

    return (
        <>
            <LSContentItem
                //@ts-ignore
                onClick={handleInfo}
            >
                <LSContentColumn>
                    {item.custom_icon && <img
                        className="flex-center"
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 0,
                        }}
                        src={item.custom_icon}
                        alt="custom_icon"
                    />}
                </LSContentColumn>

                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item.model?.name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item.model?.vendor.name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item.address_text || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setIsDelete(true),
                            }
                        ]}
                    />

                    <ActionMore
                        isOpen={isInfo}
                        onClick={handleInfo}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isInfo && (
                <Info
                    isOpen={isInfo}
                    data={item}
                />
            )}

            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    onClose={()=> setIsDelete(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                    loadingButton={loadingButton}
                />
            )}

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default Item;
