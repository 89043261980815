import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadRoadSectionRelation } from '../../../redux/RoadNetwork/actions';
import InformPanel from 'components/layout/PageLayout/InformPanel';
import FormButtonsComponent, { buttonsTypes } from '../FormButtons';

const RoadSectionRelationList = ({ roadSectionId, isMap = false, openClick, readOnly = false }) => {
    const dispatch = useDispatch();

    const [relations, setRelations] = useState([]);

    useEffect(() => {
        if (roadSectionId) {
            dispatch(loadRoadSectionRelation({ road_section_id_list: [roadSectionId] }, setRelations));
        }
    }, [dispatch]);

    const renderAddress = (address) => {
        if (!address?.area) return 'Адрес отсутвует';
        const addressText = `${address?.area}, ${address?.city_name}, ${address?.district}, ${address?.street}`;
        return addressText;
    };

    const sortFunc = (arr = [], option) => {
        const sortArr = arr.sort((a, b) => {
            if (a[option] > b[option]) {
                return 1;
            } else if (a[option] < b[option]) {
                return -1;
            }
            return 0;
        });

        return sortArr;
    };

    const uniqueRelations = [...new Set(relations?.map(({ entity_type_text }) => entity_type_text))];

    const reducedRelations = uniqueRelations.reduce((acc, item) => {
        const filteredRelations = relations?.filter(({ entity_type_text }) => entity_type_text === item);
        const mapedRelations = filteredRelations.map(el => ({
            address: el.entity_data.address,
            address_text: el.entity_data.address_text,
            name: el.entity_data.name,
        }));
        const sorted = sortFunc(mapedRelations, 'name');

        acc.push({
            entity_type_text: item,
            data: sorted
        });
        return acc;
    }, []);

    const renderRelations = () => {
        const sortRelations = sortFunc(reducedRelations, 'entity_type_text');
        return sortRelations?.map((item, i) => 
            <div key={`${item.entity_type_text}_${i}`} >
                {isMap ? <p><strong>{item.entity_type_text}:</strong></p> : <h3>{item.entity_type_text}:</h3>}
                {item.data?.map((el, i) => 
                    <div key={i} style={{ marginLeft: 10 }}>
                        {i+1}. {el.name}; {el.address_text ? el.address_text : renderAddress(el.address)}
                    </div>
                )}
            </div>
        );
    };

    return (<div style={ isMap ? { marginLeft: 15 } : {}}>
        {isMap ? <h4>Присвоенные объекты:</h4> : <h2>Присвоенные объекты:</h2>}
        {(!isMap && !readOnly)
            && <InformPanel
                buttons={
                    <FormButtonsComponent
                        noPadding
                        noMarginLeft
                        buttons={[
                            {
                                ...buttonsTypes.change,
                                color: 'infoButton',
                                onClick: openClick,
                            },
                        ]}
                    />
                }
            />
        }
        <div style={{ marginLeft: 10 }}>
            {relations?.length > 0
                ? renderRelations()
                : 'Нет присвоенных объектов'
            }
        </div>
    </div>);
};

export default RoadSectionRelationList;