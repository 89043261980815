/**
 * Sagas
 * **/
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { isFunction } from 'lodash';

import { setValidation } from 'redux/Validation/actions';
import { showMessage } from 'redux/Message/actions';
import { setMapLoading } from 'redux/Map/actions';
import messagetypes from 'redux/Message/messagetypes';
import { messageHelper } from 'helpers/messageHelper';
import messages from 'helpers/constants/messages';
import { getReport } from 'helpers/getReport';

import * as actions from './actions';
import api from './api.methods';
import * as types from './types';

function* loadCamerasSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingCameras(true));
    const camerasList = yield call(api.getCameras, page, limit, query);
    if (camerasList) {
        yield put(actions.loadedCameras(camerasList));
    }
    yield put(actions.loadingCameras(false));
}

function* editCameraSaga({ payload: { id, data } }) {
    yield put(actions.loadingCameras(true));
    const response = yield call(api.editCamera, id, data);
    if (response?.success) {
        // yield put(loadCameras());
        yield put(setValidation({ success: true }));
        yield put(showMessage(messagetypes.success, 'Камера успешно изменена'));
    }
    yield put(actions.loadingCameras(false));
}

function* createCameraSaga({ payload: data }) {
    yield put(actions.loadingCameras(true));
    const response = yield call(api.createCamera, data);
    if (response?.success) {
        // yield put(loadCameras());
        yield put(setValidation({ success: true }));
        yield put(showMessage(messagetypes.success, 'Камера успешно создана'));
    }
    yield put(actions.loadingCameras(false));
}

function* loadCameraTypesSaga() {
    const typesList = yield call(api.getCameraTypes);
    if (typesList?.success) {
        yield put(actions.loadedCameraTypes(typesList.data));
    }
}

function* loadCameraComplexTypes() {
    const complexTypesList = yield call(api.getCameraComplexTypes);
    if (complexTypesList?.success) {
        yield put(actions.loadedCameraComplexTypes(complexTypesList.data));
    }
}

function* loadCameraProtoTextSaga() {
    const protoText = yield call(api.getCameraProtoText);
    if (protoText?.success) {
        yield put(actions.loadedCameraProtoText(protoText.data));
    }
}

function* loadCameraDirSaga() {
    const dirType = yield call(api.getCameraDirType);
    if (dirType?.success) {
        yield put(actions.loadedCameraDirType(dirType.data));
    }
}

function* loadCameraVideoTypeSaga() {
    const videoType = yield call(api.getCameraVideoType);
    if (videoType?.success) {
        yield put(actions.loadedCameraVideoType(videoType.data));
    }
}

function* loadCameraDataTypeSaga() {
    const dataType = yield call(api.getCameraDataType);
    if (dataType?.success) {
        yield put(actions.loadedCameraDataType(dataType.data));
    }
}

function* loadCameraEventsSaga({ payload }) {
    const { page, limit, data } = payload;
    const config = { params: { page, limit, ...data } };

    yield put(actions.loadingCameraEvents(true));
    const response = yield call(api.getEvents, config);

    if (response?.success) {
        yield put(actions.loadedCameraEvents(response));
    }
    yield put(actions.loadingCameraEvents(false));
}

function* loadCameraViolationsSaga() {
    const response = yield call(api.getCameraViolations);
    if (response?.success) {
        yield put(actions.loadedCameraViolations(response));
    }
}

function* loadCameraEventsGroupSaga({ payload }) {
    const response = yield call(api.getEventsGroup, payload);
    if (response?.success) {
        yield put(actions.loadedCameraEventsGroup(response));
    }
}

function* loadCameraReportListSaga() {
    const response = yield call(api.getCameraReportList);
    if (response?.data) {
        yield put(actions.loadedCameraReportList(response.data));
    }
}

function* getCameraReportSaga({ payload = {} }) {
    const { report } = payload;

    const response = yield call(api.getCameraReport, payload);

    if (response) {
        getReport(response, report);
    }
}

function* loadComplexesSaga({ payload, callback }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingComplexes(true));
    const response = yield call(api.getComplexes, page, limit, params);
    if (response?.success) {
        if (callback) {
            callback(response.data);
        } else {
            yield put(actions.loadedComplexes(response));
        }
    }
    yield put(actions.loadingComplexes(false));
}

function* loadComplexesTreeSaga({ payload }) {
    yield put(actions.loadingComplexesTree(true));
    const treeList = yield call(api.getComplexesTree, {
        ...payload,
        is_tree: true,
    });
    if (treeList) {
        yield put(actions.loadedComplexesTree(treeList));
    }
    yield put(actions.loadingComplexesTree(false));
}

function* createComplexSaga({ payload: data, callback }) {
    yield put(actions.loadingComplexes(true));
    const response = yield call(api.createComplex, data);
    if (response?.success) {
        // yield put(loadComplexes());
        // yield put(setValidation({ success: true }));
        yield put(showMessage(messagetypes.success, 'Комплекс успешно создан'));
        callback && callback();
    }
    yield put(actions.saveStatus(response?.success || false));
    yield put(actions.loadingComplexes(false));
}

function* loadComplexTypesSaga() {
    const complexTypesList = yield call(api.getComplexTypes);
    if (complexTypesList) {
        yield put(actions.loadedComplexTypes(complexTypesList.data));
    }
}

function* loadComplexUnitTypesSaga() {
    const complexUnitTypesList = yield call(api.getComplexUnitTypes);
    if (complexUnitTypesList) {
        yield put(actions.loadedComplexUnitTypes(complexUnitTypesList.data));
    }
}

function* loadComplexModelTypesSaga() {
    const complexModelTypesList = yield call(api.getComplexModelTypes);
    if (complexModelTypesList) {
        yield put(actions.loadedComplexModelTypes(complexModelTypesList.data));
    }
}

function* loadComplexEntitiesSaga({ payload }) {
    const { data } = payload;
    yield put(actions.loadingComplexEntities(true));
    const response = yield call(api.getComplexEntities, data);
    if (response?.success) {
        yield put(actions.loadedComplexEntities(response.data));
    }
    yield put(actions.loadingComplexEntities(false));
}

function* loadComplexEntitiesTypesSaga() {
    yield put(actions.loadingComplexEntityTypes(true));
    const response = yield call(api.getComplexEntitiesTypes);
    if (response?.success) {
        yield put(actions.loadedComplexEntityTypes(response));
    }
    yield put(actions.loadingComplexEntityTypes(false));
}

function* loadPddViolationsSaga({ payload, filtered }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingPddViolations(true));
    const response = yield call(api.getPddViolations, page, limit, data );
    if (response?.success) {
        filtered
            ? yield put(actions.loadedViolationsById(response.data))
            : yield put(actions.loadedPddViolations(response));
    }
    yield put(actions.loadingPddViolations(false));
}

function* editPddViolationSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editPddViolation, id, data);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback?.();
    }
}

function* loadCamerasPddViolationsSaga({ payload }) {
    const { id, data, page = 1, limit = 100 } = payload;
    yield put(actions.loadingCameraPddiolations({ [id]: true }));
    const response = yield call(api.getPddViolations, page, limit, data );
    if (response?.success) {
        yield put(actions.loadedCameraPddViolations(response.data));
    }
    yield put(actions.loadingCameraPddiolations({ [id]: false }));
}

function* postComplexEntityDataSaga({ payload: data, callback }) {
    const response = yield call(api.postComplexEntityData, data);
    if (response?.success) {
        // yield put(loadComplexEntities(1, 15, { complex_id: data.complex_id }));
        // yield put(loadComplexes());
        // yield put(setValidation({ success: true }));
        callback && callback();
        yield put(showMessage(messagetypes.success, 'Камера комплекса успешно обновлена'));
    }
    yield put(actions.saveStatus(response?.success || false));
}

function* editComplexEntityDataSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editComplexEntityData, id, data);
    if (response?.success) {
        // yield put(loadComplexEntities(1, 15, { complex_id: data.complex_id }));
        // yield put(loadComplexes());
        // yield put(setValidation({ success: true }));
        callback && callback();
        yield put(showMessage(messagetypes.success, 'Камера комплекса успешно изменена'));
    }
    yield put(actions.saveStatus(response?.success || false));
}

function* deleteComplexEntityDataSaga({ payload, callback }) {
    const { id, data, complex_id } = payload;
    const response = yield call(api.deleteComplexEntityData, id, data);
    if (response?.success) {
        // yield put(loadComplexEntities(1, 15, { complex_id } ));
        // yield put(loadComplexes());
        callback && callback();
        yield put(showMessage(messagetypes.success, 'Камера комплекса успешно удалена'));
    }
    // yield put(saveStatus(response?.success || false));
}

function* loadDocumentsSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingDocuments(true));
    const documentsList = yield call(api.getDocuments, page, limit, data);
    if (documentsList) {
        yield put(actions.loadedDocuments(documentsList));
    }
    yield put(actions.loadingDocuments(false));
}

function* loadDocumentTypesSaga() {
    const documentTypesList = yield call(api.getDocumentTypes);
    if (documentTypesList) {
        yield put(actions.loadedDocumentTypes(documentTypesList.data));
    }
}

function* createDocumentComplexSaga({ payload: data, callback }) {
    yield put(actions.loadingDocuments(true));
    const response = yield call(api.createDocumentComplex, data);
    if (response?.success) {
        // yield put(loadDocuments(1, 25, { complex_id: data.complex_id }));
        // yield put(loadComplexes());
        callback && callback();
        yield put(showMessage(messagetypes.success, 'Документ успешно создан'));
    }
    yield put(actions.loadingDocuments(false));
    yield put(actions.saveStatus(response?.success || false));
}

function* loadComplexStatusesSaga() {
    const complexStatusesList = yield call(api.getComplexStatuses);
    if (complexStatusesList) {
        yield put(actions.loadedComplexStatuses(complexStatusesList.data));
    }
}

function* deleteComplexDocumentSaga({ payload, callback }) {
    const { id, data, complex_id } = payload;
    const response = yield call(api.deleteComplexDocument, id, data);
    if (response?.success) {
        // yield put(loadDocuments(1, 25, { complex_id }));
        // yield put(loadComplexes());
        callback && callback();
        yield put(showMessage(messagetypes.success, 'Документ успешно удален'));
    }
    yield put(actions.saveStatus(response?.success || false));
}

function* editComplexDocumentSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editComplexDocument, id, data);
    if (response?.success) {
        // yield put(loadDocuments(1, 25, { complex_id: data.complex_id }));
        // yield put(loadComplexes());
        callback && callback();
        yield put(showMessage(messagetypes.success, 'Документ успешно изменен'));
    }
    yield put(actions.saveStatus(response?.success || false));
}

function* deleteComplex({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.deleteComplex, id, data);
    if (response?.success) {
        // yield put(loadComplexes());
        callback && callback();
        yield put(showMessage(messagetypes.success, 'Комплекс успешно удален'));
    }
    yield put(actions.saveStatus(response?.success || false));
}

function* getForPolygonSaga({ payload: params }) {
    yield put(actions.setLoadingPolygon(true));
    yield put(setMapLoading('dorisControl', true));
    const response = yield call(api.getForPolygon, params);
    if (response) {
        yield put(actions.saveForPolygon(response));
    }
    yield put(actions.setLoadingPolygon(false));
    yield put(setMapLoading('dorisControl', false));
}

function* loadComplexHistorySaga({ payload }) {
    const { page, limit, id } = payload;
    yield put(actions.loadingComplexHistory(true));
    const historyList = yield call(api.getComplexHistory, page, limit, id);
    if (historyList) {
        yield put(actions.loadedComplexHistory(historyList));
    }
    yield put(actions.loadingComplexHistory(false));
}

function* editComplexSaga({ payload, callback }) {
    const { id, data, params } = payload;
    yield put(actions.loadingComplexes(true));
    const response = yield call(api.editComplex, id, data);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, 'Комплекс успешно изменен'));
        callback && callback();
    }
    yield put(actions.saveStatus(response?.success || false));
    yield put(actions.loadingComplexes(false));
}

function* loadComplexEventsSaga({ payload, callback, }) {
    const { page, limit, data } = payload;
    const config = { page, limit, ...data };

    yield put(actions.loadingComplexEvents(true));
    const response = yield call(api.getComplexEvents, config);

    if (response) {
        callback?.(response.data);
        limit > 1
            ? yield put(actions.loadedComplexEvents(response))
            : yield put(actions.loadedCurrentEvent(response));
    }
    yield put(actions.loadingComplexEvents(false));
}

function* loadEventArchiveSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.getComplexEventArchive, id, { params: data, responseType: 'blob' });
    if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: 'application/x-tar' });
        if (blob.size > 0) {
            getReport(blob, 'tar');
        } else {
            console.error('Blob is empty or invalid');
        }
    }
}

function* changeComplexStatusSaga({ payload, callback }) {
    const { complex_id, data } = payload;
    const response = yield call(api.changeComplexStatus, complex_id, data);
    if (response?.success) {
        callback?.();
        yield put(setValidation({ success: true }));
        yield put(showMessage(messagetypes.success, 'Комплекс успешно изменен'));
    }
    yield put(actions.saveStatus(response?.success || false));
}

function* loadSidebarSaga({ payload }) {
    const params = payload;
    const page = params?.page || 1;
    yield put(actions.loadingSidebar(true));
    const complexesList = yield call(api.getSidebar, params);
    if (complexesList) {
        yield put(actions.loadedSidebar(complexesList, page));
    }
    yield put(actions.loadingSidebar(false));
}

function* loadEventErrorsSaga({ payload }) {
    const { page, limit, name } = payload;
    const response = yield call(api.getEventErrors, page, limit, name);
    if (response) {
        yield put(actions.loadedEventErrors(response?.data));
    }
}

function* editEventErrorsSaga({ payload }) {
    const { uuid, data } = payload;
    yield put(actions.editingEventErrors(true));
    const response = yield call(api.editEventErrors, uuid, data);
    if (response?.success) {
        yield put(setValidation({ success: true }));
        yield put(showMessage(messagetypes.success, 'Ошибки успешно добавлены'));
    }
    yield put(actions.editingEventErrors(false));
}

function* loadFormattedComplexHistorySaga({ payload }) {
    const { page, limit, id } = payload;
    yield put(actions.loadingFormattedComplexHistory(true));
    const response = yield call(api.getFormattedComplexHistory, page, limit, id);
    if (response) {
        yield put(actions.loadedFormattedComplexHistory(response));
    }
    yield put(actions.loadingFormattedComplexHistory(false));
}

function* loadComplexRulesSaga({ payload: id }) {
    const complexRules = yield call(api.getComplexRules, id);
    yield put(actions.loadingComplexRules(true));
    if (complexRules) {
        yield put(actions.loadedComplexRules(complexRules.data));
    }
    yield put(actions.loadingComplexRules(false));
}

// function* loadCurrentComplexSaga({ payload }) {
//     const params = payload;
//     const response = yield call(api.getCurrentComplexes, 1, 1, params);
//     if (response) {
//         yield put(loadedCurrentComplex(response.data?.[0] || {}));
//     }
// }

function* loadComplexTransferTypesSaga() {
    const complexTransferTypesList = yield call(api.getComplexTransferTypes);
    if (complexTransferTypesList) {
        yield put(actions.loadedComplexTransferTypes(complexTransferTypesList.data));
    }
}

function* loadCurrentComplexTransferTypesSaga({ payload }) {
    const response = yield call(api.getCurrentComplexTransferTypes, payload);
    if (response?.success) {
        yield put(actions.loadedCurrentComplexTransferTypes(response.data));
    }
}

function* loadComplexTransferRulesSaga({ payload: id }) {
    const rules = yield call(api.getComplexTransferRules, id);
    if (rules) {
        yield put(actions.loadedComplexTransferRules(rules.data));
    }
}

function* moveServiceEventsToMainSaga({ payload, callback }) {
    const response = yield call(api.moveServiceEventsToMain, payload);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

function* getComplexStatistic({ payload }) {
    const params = {};
    if (payload?.is_showcase) {
        params.is_showcase = 1;
    }
    const statistic = yield call(api.getComplexStatistic, params);
    if (statistic) {
        yield put(actions.loadedComplexStatistic(statistic.data));
    }
}

function* deleteFromServiceEventsSaga({ payload, callback }) {
    const response = yield call(api.deleteFromServiceEvents, payload);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
    }
}

function* getMonthlyReportSaga({ payload }) {
    const config = { params: { ...payload }, responseType: 'blob', timeout: 60000 };
    const response = yield call(api.getMonthlyReport, config);
    if (response) {
        getReport(response, payload.report);
    }
}

function* getMonthlyReportExpandedSaga({ payload }) {
    const config = payload;
    // ,
    // responseType: 'blob',
    // timeout: 60000
    // };
    const response = yield call(api.getMonthlyReportExpanded, config);
    if (response?.success) {
        yield messageHelper(response);
    }
}

function* getMonthlyReportByComplexSaga({ payload }) {
    const config = payload;
    const response = yield call(api.getMonthlyReportByComplex, config);
    if (response?.success) {
        yield messageHelper(response);
    }
}

function* getReportByPeriodSaga({ payload }) {
    // сообщение что отчет готовится - далее ждем в сокете сообщение (user.{id})
    // const config = {
    //     params: { ...payload },
    //     responseType: 'blob',
    //     timeout: 60000
    // };
    const response = yield call(api.getReportByPeriod, payload);
    if (response?.success) {
        yield messageHelper(response);
    }
}

function* getComparativeStatisticSaga({ payload }) {
    yield put(actions.loadingComparativeStatistic(true));
    const statistic = yield call(api.getComparativeStatistics, payload);
    if (statistic) {
        yield put(actions.loadedComparativeStatistic(statistic?.data));
    }
    yield put(actions.loadingComparativeStatistic(false));
}

function* getCoordinationHistorySaga({ payload }) {
    yield put(actions.loadingCoordinationHistory(true));
    const response = yield call(api.getCoordinationHistory, payload);
    if (response?.success) {
        yield put(actions.loadedCoordinationHistory(response?.data || []));
    }
    yield put(actions.loadingCoordinationHistory(false));
}

function* getComplexReportSaga({ payload }) {
    const config = { params: { ...payload } };
    const response = yield call(api.getComplexReport, config);
    if (response?.success) {
        yield messageHelper(response);
    }
}

function* getInaccuracySaga() {
    const response = yield call(api.getInaccuracy);
    if (response) {
        yield put(actions.loadedInaccuracy(response.data));
    }
}

//DORIS_CONTROL_SETTINGS
function* loadDorisControlSettingsSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingDorisControlSettings(true));
    const response = yield call(api.getDorisControlSettings, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedDorisControlSettings(response));
    }
    yield put(actions.loadingDorisControlSettings(false));
}

function* editDorisControlSettingSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.editDorisControlSetting, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response?.success }));
        yield put(actions.loadDorisControlSettings());
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

function* editSettingsListSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.editSettingsList, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }

    yield put(actions.loadingButton(false));
}

function* deleteComplexEventsSaga({ payload, callback }) {
    const response = yield call(api.deleteComplexEvents, payload);
    if (response?.success) {
        callback();
        // yield put(loadComplexEvents());
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
    }
}

function* getIncorrectComplexReportSaga({ payload }) {
    const config = { params: { ...payload } };
    const response = yield call(api.getIncorrectComplexReport, config);
    if (response?.success) {
        yield messageHelper(response);
    }
}

function* getTsCategoriesSaga() {
    const response = yield call(api.getTsCategories);
    if (response) {
        yield put(actions.loadedTsCategories(response.data));
    }
}

function* loadInspectionCertificateSaga({ payload }) {
    yield put(actions.loadingInspectionCertificate(true));

    const config = {
        params: payload,
    };
    const response = yield call(api.getInspectionCertificate, config);
    if (response?.success) {
        yield messageHelper(response);
    }
    yield put(actions.loadingInspectionCertificate(false));
}

function* eventSendingRequestSaga({ payload }) {
    const response = yield call(api.getEventSendingRequest, payload);
    // console.log(response);
}

function* loadFileSaga({ payload }) {
    yield put(actions.loadingFile(true));
    const config = { responseType: 'blob' };
    const response = yield call(api.loadFile, payload, config);
    if (response) {
        getReport(response, 'TAR');
    }
    yield put(actions.loadingFile(false));
}

function* load1CNamesSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loading1CNames(true));
    const response = yield call(api.get1CNames, page, limit, query);
    if (response?.success) {
        yield put(actions.loaded1CNames(response));
    }
    yield put(actions.loading1CNames(false));
}

function* create1CNameSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.create1CName, payload);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
    yield put(actions.loadingButton(false));
}

function* edit1CNameSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.edit1CName, payload);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messagetypes.success, messages.EDIT_SUCCESS));
    }

    yield put(actions.loadingButton(false));
}

function* delete1CNameSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.delete1CName, payload);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
    }
    yield put(actions.loadingButton(false));
}

function* load1CNamesReportSaga({ payload }) {
    const config = { params: { ...payload } };
    yield put(actions.loading1CNamesReport(true));
    const response = yield call(api.get1CNamesReport, config);
    if (response?.success) {
        yield messageHelper(response);
    }
    yield put(actions.loading1CNamesReport(false));
}

function* loadComplexByIdSaga({ payload, callback }) {
    yield put(actions.loadingComplexById(true));
    const response = yield call(api.getComplexById, payload.id);
    if (response && response?.data) {
        if (callback) {
            // сохранение данных в локальный стейт
            callback(response?.data[0]);
        } else {
            yield put(actions.loadedComplexById(response?.data[0] || {}));
        }
    }
    yield put(actions.loadingComplexById(false));
}

function* loadTagListSaga({ payload }) {
    const response = yield call(api.getTagList, payload);
    if (response?.success) {
        yield put(actions.loadedTagList(response));
    }
}

function* createTagSaga({ payload, callback }) {
    const response = yield call(api.createTag, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback();
    }
}

function* editTagSaga({ payload, callback }) {
    const response = yield call(api.editTag, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.EDIT_SUCCESS));
        callback();
    }
}

function* deleteTagSaga({ payload, callback }) {
    const response = yield call(api.deleteTag, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
        callback();
    }
}

function* complexSyncTagsSaga({ payload, callback }) {
    const response = yield call(api.complexSyncTags, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback();
    }
}

function* loadComplexTypeGroupingSaga() {
    const complexTypesGroupingList = yield call(api.getComplexTypeGrouping);
    if (complexTypesGroupingList) {
        yield put(actions.loadedComplexTypeGrouping(complexTypesGroupingList.data));
    }
}

function* loadComplexMeasuringSaga() {
    const complexMeasuringList = yield call(api.getComplexMeasuring);
    if (complexMeasuringList) {
        yield put(actions.loadedComplexMeasuring(complexMeasuringList.data));
    }
}

function* loadRecognitionCameraDirTypesSaga() {
    const response = yield call(api.getRecognitionCameraDirTypes);
    if (response) {
        yield put(actions.loadedRecognitionCameraDirTypes(response.data));
    }
}

function* createComplexEntitySaga({ payload, callback }) {
    const { url, data } = payload;
    const response = yield call(api.createComplexEntity, url, data);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback?.();
    }
}

function* editComplexEntitySaga({ payload, callback }) {
    const { url, data } = payload;
    const response = yield call(api.editComplexEntity, url, data);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.EDIT_SUCCESS));
        callback?.();
    }
}
function* deleteComplexEntitySaga({ payload, callback }) {
    const { url, data } = payload;
    const response = yield call(api.deleteComplexEntity, url, data);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
        callback?.();
    }
}

function* loadComputerTransferTypesSaga({ payload }) {
    const response = yield call(api.getComputerTransferTypes, payload);
    if (response) {
        yield put(actions.loadedComputerTransferTypes(response.data));
    }
}

function* loadComputerTransferRulesSaga({ payload: id }) {
    const response = yield call(api.getComputerTransferRules, id);
    if (response) {
        yield put(actions.loadedComputerTransferRules(response.data));
    }
}

function* loadDorisExportTypesSaga({ payload }) {
    const response = yield call(api.getDorisExportTypes, payload);
    if (response?.success) {
        yield put(actions.loadedDorisExportTypes(response.data));
    }
}

function* loadExclusiveFilesSaga({ payload, storeField }) {
    yield put(actions.loadingExclusiveFiles(true));
    const response = yield call(api.getExclusiveFiles, payload);
    if (response?.success) {
        yield put(actions.loadedExclusiveFiles(response, storeField));
    }
    yield put(actions.loadingExclusiveFiles(false));
}

function* loadExclusiveFilesHistoryListSaga({ payload }) {
    yield put(actions.loadingExclusiveFilesHistoryList(true));
    const response = yield call(api.getExclusiveFilesHistoryList, {
        ...payload,
        is_active: 0,
    });
    if (response?.success) {
        yield put(actions.loadedExclusiveFilesHistoryList(response));
    }
    yield put(actions.loadingExclusiveFilesHistoryList(false));
}

function* loadExclusiveFilesLogListSaga({ payload }) {
    yield put(actions.loadingExclusiveFilesLogList(true));
    const response = yield call(api.getExclusiveFilesLogList, payload);
    if (response?.success) {
        yield put(actions.loadedExclusiveFilesLogList(response));
    }
    yield put(actions.loadingExclusiveFilesLogList(false));
}

function* createExclusiveFileSaga({ payload, callback  }) {
    const response = yield call(api.createExclusiveFile, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback?.();
    }
}

function* editExclusiveFilesSaga({ payload, callback }) {
    const response = yield call(api.editExclusiveFile, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback?.();
    }
}

function* deleteExclusiveFilesSaga({ payload, callback }) {
    const response = yield call(api.deleteExclusiveFile, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
        callback?.();
    }
}

function* loadExclusiveFileHistorySaga({ payload }) {
    const { id, page, limit } = payload;
    yield put(actions.loadingExclusiveFileHistory(true));
    const response = yield call(api.getExclusiveFileHistory, page, limit, id);
    if (response?.success) {
        yield put(actions.loadedExclusiveFileHistory(response));
    }
    yield put(actions.loadingExclusiveFileHistory(false));
}

function* loadChecklistsSaga({ payload }) {
    const { page, limit, params } = payload;

    yield put(actions.loadingChecklists(true));
    const response = yield call(api.getChecklists, page, limit, params);
    if (response?.success) {
        yield put(actions.loadedChecklists(response));
    }
    yield put(actions.loadingChecklists(false));
}

function* createChecklistsSaga({ payload, callback }) {
    yield put(actions.loadingChecklists(true));
    const response = yield call(api.createChecklist, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback?.();
    }
    yield put(actions.loadingChecklists(false));
}

function* editChecklistsSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingChecklists(true));
    const response = yield call(api.editChecklist, id, data);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.EDIT_SUCCESS));
        callback?.();
    }
    yield put(actions.loadingChecklists(false));
}

function* deleteChecklistsSaga({ payload, callback }) {
    yield put(actions.loadingChecklists(true));
    const response = yield call(api.deleteChecklist, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
        callback?.();
    }
    yield put(actions.loadingChecklists(false));
}

function* loadMaterialStorageGroupsSaga({ payload }) {
    yield put(actions.loadingMaterialStorageGroups(true));
    const response = yield call(api.getMaterialStorageGroups, payload);
    if (response?.success) {
        yield put(actions.loadedMaterialStorageGroups(response));
    }
    yield put(actions.loadingMaterialStorageGroups(false));
}

function* createMaterialStorageGroupSaga({ payload, callback  }) {
    const response = yield call(api.createMaterialStorageGroup, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback?.();
    }
}

function* editMaterialStorageGroupSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editMaterialStorageGroup, id, data);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback?.();
    }
}

function* deleteMaterialStorageGroupSaga({ payload, callback }) {
    const response = yield call(api.deleteMaterialStorageGroup, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
        callback?.();
    }
}

function* loadCurrentEventListSaga({ payload, callback }) {
    const { page, limit, data } = payload;
    const config = { page, limit, ...data };

    yield put(actions.loadingComplexEvents(true));
    const response = yield call(api.getComplexEvents, config);
    if (response) {
        if (callback) {
            callback(response);
        }
    }
    yield put(actions.loadingComplexEvents(false));
}

function* loadEventObjectSaga({ payload, callback }) {
    const { uid, params } = payload;

    yield put(actions.loadingEvent(true));
    const response = yield call(api.getEventObject, uid, { ...params, uid });
    if (response?.success) {
        // обычно используем в модалке чтобы подгрузить полный объект. так что и храним внутри в модалке
        yield callback?.(response.data);
    }
    yield put(actions.loadingEvent(false));
}

function* loadViolationEventsSaga({ payload }) {
    yield put(actions.loadingViolationEvents(true));
    const response = yield call(api.getViolationEvents, payload);
    if (response?.success) {
        yield put(actions.loadedViolationEvents(response));
    }
    yield put(actions.loadingViolationEvents(false));
}

function* loadEventCheckListSaga({ payload, callback }) {
    const { base_name, params } = payload;

    yield put(actions.loadingEventCheckList(true));
    const response = yield call(api.getEventCheckList, base_name, { is_base_name: 1, ...params });
    if (response?.success) {
        yield callback?.(response.data);
    }
    yield put(actions.loadingEventCheckList(false));
}

function* loadEventPDDSSaga({ payload: data, callback }) {
    yield put(actions.loadingEventPDDS(true));
    const response = yield call(api.getEventPDDS, data);
    if (response?.success) {
        yield callback?.(response.data);
    }
    yield put(actions.loadingEventPDDS(false));
}

function* saveEventCheckListSaga({ payload, callback }) {
    const response = yield call(api.saveEventCheckList, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback?.();
    }
}

function* loadPddViolationsTreeSaga({ payload, callback }) {
    const { data } = payload;
    yield put(actions.loadingPddViolations(true));
    const response = yield call(api.getPddViolationsTree, data );
    if (response?.success) {
        callback && callback(response.data);
    } else {
        yield put(actions.loadingPddViolations(false));
    }
}

function* loadCheckListGroupSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingCheckListGroup(true));
    const response = yield call(api.getCheckListGroup, page, limit, params);
    if (response?.success) {
        yield put(actions.loadedCheckListGroup(response));
    }
    yield put(actions.loadingCheckListGroup(false));
}

function* createCheckListGroupSaga({ payload, callback }) {
    yield put(actions.loadingCheckListGroup(true));
    const response = yield call(api.createCheckListGroup, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback?.();
    }
    yield put(actions.loadingCheckListGroup(false));
}

function* editCheckListGroupSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingCheckListGroup(true));
    const response = yield call(api.editCheckListGroup, id, data);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.EDIT_SUCCESS));
        callback?.();
    }
    yield put(actions.loadingCheckListGroup(false));
}

function* deleteCheckListGroupSaga({ payload, callback }) {
    yield put(actions.loadingCheckListGroup(true));
    const response = yield call(api.deleteCheckListGroup, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
        callback?.();
    }
    yield put(actions.loadingCheckListGroup(false));
}

function* checkFgisSaga({ payload, callback }) {
    const response = yield call(api.checkFgis, payload);
    if (response?.success) {
        callback?.(response?.data);
    }
}

function* loadDocumentsHistorySaga({ payload }) {
    const { id, page, limit } = payload;
    yield put(actions.loadingDocumentsHistory(true));
    const response = yield call(api.getDocumentsHistory, id, page, limit);
    if (response?.success) {
        yield put(actions.loadedDocumentsHistory(response));
    }
    yield put(actions.loadingDocumentsHistory(false));
}

function* loadEventsStatisticSaga() {
    yield put(actions.loadingEventsStatistic(true));
    const response = yield call(api.getEventsStatistic);
    if (response?.success) {
        yield put(actions.loadedEventsStatistic(response?.data || {}));
    }
    yield put(actions.loadingEventsStatistic(false));
}

function* loadComplexByRadiusSaga({ payload, callback }) {
    const response = yield call(api.loadComplexByRadius, payload);
    if (response?.success) {
        yield put(actions.loadedComplexByRadius(response.data));
        callback(response.data);
    }
}

function* loadCameraViolationsTreeSaga() {
    yield put(actions.loadingCameraViolationsTree(true));
    const response = yield call(api.getCameraViolationsTree);
    if (response?.success) {
        yield put(actions.loadedCameraViolationsTree(response));
    }
    yield put(actions.loadingCameraViolationsTree(false));
}

function* getPddReportExpandedSaga({ payload }) {
    const config = { params: { ...payload },
        // responseType: 'blob',
        // timeout: 60000
    };
    const response = yield call(api.getPddReportExpanded, config);
    if (response && response?.data) {
        yield put(showMessage(messagetypes.success, response?.data?.join(' ')));
    }
}

function* loadMaterialExportGroupSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingMaterialExportGroup(true));
    const response = yield call(api.getMaterialExportGroup, page, limit, params);
    if (response?.success) {
        yield put(actions.loadedMaterialExportGroup(response));
    }
    yield put(actions.loadingMaterialExportGroup(false));
}

function* createMaterialExportGroupSaga({ payload, callback  }) {
    const response = yield call(api.createMaterialExportGroup, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback && callback();
    }
}

function* editMaterialExportGroupSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editMaterialExportGroup, id, data);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback && callback();
    }
}

function* deleteMaterialExportGroupSaga({ payload, callback }) {
    const response = yield call(api.deleteMaterialExportGroup, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
        callback && callback();
    }
}

function* loadMaterialExportGroupTypesSaga() {
    const response = yield call(api.getMaterialExportGroupTypes);
    if (response?.data) {
        yield put(actions.loadedMaterialExportGroupTypes(response.data));
    }
}

function* loadMaterialExportGroupProtocolsSaga() {
    const response = yield call(api.getMaterialExportGroupProtocols);
    if (response?.data) {
        yield put(actions.loadedMaterialExportGroupProtocols(response.data));
    }
}

function* loadTypeOfMistakesForCheckListSaga({ payload }) {
    yield put(actions.loadingTypeOfMistakesForCheckList(true));
    const response = yield call(api.getTypeOfMistakesForCheckList, payload);
    if (response?.success) {
        yield put(actions.loadedTypeOfMistakesForCheckList(response || {}));
    }
    yield put(actions.loadingTypeOfMistakesForCheckList(false));
}

function* createTypeOfMistakesForCheckListSaga({ payload, callback }) {
    yield put(actions.loadingTypeOfMistakesForCheckList(true));
    const response = yield call(api.createTypeOfMistakesForCheckList, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback?.();
    }
    yield put(actions.loadingTypeOfMistakesForCheckList(false));
}

function* deleteTypeOfMistakesForCheckListSaga({ payload, callback }) {
    yield put(actions.loadingTypeOfMistakesForCheckList(true));
    const response = yield call(api.deleteTypeOfMistakesForCheckList, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
        callback?.();
    }
    yield put(actions.loadingTypeOfMistakesForCheckList(false));
}

function* editTypeOfMistakesForCheckListSaga({ payload, callback }) {
    const { id, params } = payload;
    yield put(actions.loadingTypeOfMistakesForCheckList(true));
    const response = yield call(api.editTypeOfMistakesForCheckList, id, params);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        callback?.();
    }
    yield put(actions.loadingTypeOfMistakesForCheckList(false));
}

function* getRecentPddReportExpandedSaga({ payload }) {
    const config = { params: { ...payload } };
    const response = yield call(api.getRecentPddReportExpanded, config);
    if (response && response?.data) {
        yield put(showMessage(messagetypes.success, response?.data?.join(' ')));
    }
}

// settings group
function* loadSettingsGroupSaga() {
    yield put(actions.loadingSettingsGroup(true));
    const response = yield call(api.getSettingsGroup);
    if (response?.success) {
        yield put(actions.loadedSettingsGroup(response));
    }
    yield put(actions.loadingSettingsGroup(false));
}

function* editSettingsGroupSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.editSettingsGroup, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* loadShowCaseComplexStatusSaga() {
    yield put(actions.loadingShowCaseComplexStatus(true));
    const response = yield call(api.getShowCaseComplexStatus);
    if (response?.success) {
        yield put(actions.loadedShowCaseComplexStatus(response?.data));
    }
    yield put(actions.loadingShowCaseComplexStatus(false));
}

function* loadShowCaseDocumentsVerificationSaga() {
    yield put(actions.loadingShowCaseDocumentsVerification(true));
    const response = yield call(api.getShowCaseDocumentsVerification);
    if (response?.success) {
        yield put(actions.loadedShowCaseDocumentsVerification(response?.data || {}));
    }
    yield put(actions.loadingShowCaseDocumentsVerification(false));
}

function* loadDuploCodesSaga({ payload }) {
    yield put(actions.loadingDuploCodes(true));
    const config = { params: { ...payload } };
    const response = yield call(api.getDuploCodes, config);
    if (response?.success) {
        yield put(actions.loadedDuploCodes(response));
    }
    yield put(actions.loadingDuploCodes(false));
}

function* editDuploCodesSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.editDuploCodes, id, data);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* loadMaterialsSaga({ payload }) {
    yield put(actions.loadingMaterials(true));
    const response = yield call(api.getMaterials, payload);
    if (response?.success) {
        yield put(actions.loadedMaterials(response));
    }
    yield put(actions.loadingMaterials(false));
}

function* createMaterialSaga({ payload, callback  }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createMaterial, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* editMaterialSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.editMaterial, id, data);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* deleteMaterialSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deleteMaterial, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* moveComputerToOtherComplexSaga({ payload, callback  }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.moveComputerToOtherComplex, id, data);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        yield isFunction(callback) && callback();
    }
    yield put(actions.loadingButton(false));
}

function* loadMaterialExportSaga({ payload }) {
    yield put(actions.loadingMaterialExport(true));
    const response = yield call(api.getMaterialExport, payload);
    if (response?.success) {
        yield put(actions.loadedMaterialExport(response));
    }
    yield put(actions.loadingMaterialExport(false));
}

function* createMaterialExportSaga({ payload, callback }) {
    yield put(actions.loadingMaterialExport(true));
    const response = yield call(api.createMaterialExport, payload);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messagetypes.success, messages.SAVE_SUCCESS);
    }
    yield put(actions.loadingMaterialExport(false));
}

function* editMaterialExportSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingMaterialExport(true));
    const response = yield call(api.editMaterialExport, id, data);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingMaterialExport(false));
}

function* deleteMaterialExportSaga({ payload, callback }) {
    yield put(actions.loadingMaterialExport(true));
    const response = yield call(api.deleteMaterialExport, payload);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messagetypes.success, messages.DELETE_SUCCESS);
    }
    yield put(actions.loadingMaterialExport(false));
}

function* cancelMaterialExportSaga({ payload, callback }) {
    yield put(actions.loadingMaterialExport(true));
    const response = yield call(api.cancelMaterialExport, payload);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingMaterialExport(false));
}

function* loadMaterialExportTypesSaga() {
    const response = yield call(api.getMaterialExportTypes);
    if (response?.data) {
        yield put(actions.loadedMaterialExportTypes(response.data));
    }
}

function* loadMaterialExportProtocolsSaga() {
    const response = yield call(api.getMaterialExportProtocols);
    if (response?.data) {
        yield put(actions.loadedMaterialExportProtocols(response.data));
    }
}

function* loadComplexConditionsSaga() {
    const response = yield call(api.loadComplexConditions);
    if (response?.success) {
        yield put(actions.loadedComplexConditions(response.data || {}));
    }
}

function* loadMaterialExportStatusesSaga() {
    const response = yield call(api.getMaterialExportStatuses);
    if (response?.success) {
        yield put(actions.loadedMaterialExportStatuses(response.data));
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_CAMERAS, loadCamerasSaga),
        takeLatest(types.LOAD_CAMERA_TYPES, loadCameraTypesSaga),
        takeLatest(types.EDIT_CAMERA, editCameraSaga),
        takeLatest(types.CREATE_CAMERA, createCameraSaga),
        takeLatest(types.LOAD_CAMERA_COMPLEX_TYPES, loadCameraComplexTypes),
        takeLatest(types.LOAD_CAMERA_PROTO_TEXT, loadCameraProtoTextSaga),
        takeLatest(types.LOAD_CAMERA_DIR_TYPE, loadCameraDirSaga),
        takeLatest(types.LOAD_CAMERA_VIDEO_TYPE, loadCameraVideoTypeSaga),
        takeLatest(types.LOAD_CAMERA_DATA_TYPE, loadCameraDataTypeSaga),
        takeLatest(types.LOAD_CAMERA_EVENTS, loadCameraEventsSaga),
        takeLatest(types.LOAD_CAMERA_VIOLATIONS, loadCameraViolationsSaga),
        takeLatest(types.LOAD_CAMERA_EVENTS_GROUP, loadCameraEventsGroupSaga),
        takeLatest(types.LOAD_CAMERA_REPORT_LIST, loadCameraReportListSaga),
        takeLatest(types.GET_CAMERA_REPORT, getCameraReportSaga),
        takeLatest(types.LOAD_COMPLEXES, loadComplexesSaga),
        takeLatest(types.LOAD_COMPLEXES_TREE, loadComplexesTreeSaga),
        takeLatest(types.CREATE_COMPLEX, createComplexSaga),
        takeLatest(types.LOAD_COMPLEX_TYPES, loadComplexTypesSaga),
        takeLatest(types.LOAD_COMPLEX_UNIT_TYPES, loadComplexUnitTypesSaga),
        takeLatest(types.LOAD_COMPLEX_MODEL_TYPES, loadComplexModelTypesSaga),
        takeLatest(types.LOAD_COMPLEX_ENTITIES, loadComplexEntitiesSaga),
        takeLatest(types.LOAD_COMPLEX_ENTITY_TYPES, loadComplexEntitiesTypesSaga),
        takeLatest(types.POST_COMPLEX_ENTITY_DATA, postComplexEntityDataSaga),
        takeLatest(types.EDIT_COMPLEX_ENTITY_DATA, editComplexEntityDataSaga),
        takeLatest(types.DELETE_COMPLEX_ENTITY_DATA, deleteComplexEntityDataSaga),
        takeLatest(types.LOAD_DOCUMENTS, loadDocumentsSaga),
        takeLatest(types.LOAD_DOCUMENT_TYPES, loadDocumentTypesSaga),
        takeLatest(types.CREATE_DOCUMENT_COMPLEX, createDocumentComplexSaga),
        takeLatest(types.LOAD_COMPLEX_STATUSES, loadComplexStatusesSaga),
        takeLatest(types.LOAD_PDD_VIOLATIONS, loadPddViolationsSaga),
        takeLatest(types.EDIT_PDD_VIOLATION, editPddViolationSaga),
        takeLatest(types.LOAD_CAMERAS_PDD_VIOLATIONS, loadCamerasPddViolationsSaga),
        takeLatest(types.DELETE_COMPLEX_DOCUMENT, deleteComplexDocumentSaga),
        takeLatest(types.EDIT_COMPLEX_DOCUMENT, editComplexDocumentSaga),
        takeLatest(types.DELETE_COMPLEX, deleteComplex),
        takeLatest(types.LOAD_COMPLEX_HISTORY, loadComplexHistorySaga),
        takeLatest(types.EDIT_COMPLEX, editComplexSaga),
        takeLatest(types.GET_POLYGON, getForPolygonSaga),
        takeLatest(types.LOAD_COMPLEX_EVENTS, loadComplexEventsSaga),
        takeLatest(types.LOAD_COMPLEX_EVENT_ARCHIVE, loadEventArchiveSaga),
        takeLatest(types.CHANGE_COMPLEX_STATUS, changeComplexStatusSaga),
        takeLatest(types.LOAD_SIDEBAR, loadSidebarSaga),
        takeLatest(types.LOAD_EVENT_ERRORS, loadEventErrorsSaga),
        takeLatest(types.EDIT_EVENT_ERRORS, editEventErrorsSaga),
        takeLatest(types.LOAD_FORMATTED_COMPLEX_HISTORY, loadFormattedComplexHistorySaga),
        takeLatest(types.LOAD_COMPLEX_RULES, loadComplexRulesSaga),
        // takeLatest(types.LOAD_CURRENT_COMPLEX, loadCurrentComplexSaga),
        takeLatest(types.LOAD_COMPLEX_TRANSFER_TYPES, loadComplexTransferTypesSaga),
        takeLatest(types.LOAD_COMPLEX_TRANSFER_RULES, loadComplexTransferRulesSaga),
        takeLatest(types.MOVE_SERVICE_EVENTS_TO_MAIN, moveServiceEventsToMainSaga),
        takeLatest(types.LOAD_COMPLEX_STATISTIC, getComplexStatistic),
        takeLatest(types.DELETE_FROM_SERVICE_EVENTS, deleteFromServiceEventsSaga),
        takeLatest(types.LOAD_MONTHLY_REPORT, getMonthlyReportSaga),
        takeLatest(types.LOAD_MONTHLY_REPORT_EXPANDED, getMonthlyReportExpandedSaga),
        takeLatest(types.LOAD_MONTHLY_REPORT_BY_COMPLEX, getMonthlyReportByComplexSaga),

        takeLatest(types.LOAD_REPORT_BY_PERIOD, getReportByPeriodSaga),
        takeLatest(types.LOAD_COMPARATIVE_STATISTICS, getComparativeStatisticSaga),
        takeLatest(types.LOAD_CURRENT_COMPLEX_TRANSFER_TYPES, loadCurrentComplexTransferTypesSaga),
        takeLatest(types.LOAD_COORDINATION_HISTORY, getCoordinationHistorySaga),
        takeLatest(types.LOAD_COMPLEX_REPORT, getComplexReportSaga),
        takeLatest(types.LOAD_INACCURACY, getInaccuracySaga),
        takeLatest(types.LOAD_DORIS_CONTROL_SETTINGS, loadDorisControlSettingsSaga),
        takeLatest(types.EDIT_DORIS_CONTROL_SETTING, editDorisControlSettingSaga),
        takeLatest(types.EDIT_SETTINGS_LIST, editSettingsListSaga),
        takeLatest(types.DELETE_COMPLEX_EVENTS, deleteComplexEventsSaga),
        takeLatest(types.GET_INCORRECT_COMPLEX_REPORT, getIncorrectComplexReportSaga),
        takeLatest(types.LOAD_TS_CATEGORIES, getTsCategoriesSaga),
        takeLatest(types.LOAD_INSPECTION_CERTIFICATE, loadInspectionCertificateSaga),
        takeLatest(types.EVENT_SENDING_REQUEST, eventSendingRequestSaga),
        takeLatest(types.LOAD_FILE, loadFileSaga),
        takeLatest(types.LOAD_1C_NAMES, load1CNamesSaga),
        takeLatest(types.CREATE_1C_NAME, create1CNameSaga),
        takeLatest(types.EDIT_1C_NAME, edit1CNameSaga),
        takeLatest(types.DELETE_1C_NAME, delete1CNameSaga),
        takeLatest(types.LOAD_REPORT_BY_1C_NAMES, load1CNamesReportSaga),
        takeLatest(types.LOAD_COMPLEX_BY_ID, loadComplexByIdSaga),
        takeLatest(types.LOAD_TAG_LIST, loadTagListSaga),
        takeLatest(types.CREATE_TAG_LIST, createTagSaga),
        takeLatest(types.EDIT_TAG_LIST, editTagSaga),
        takeLatest(types.DELETE_TAG_LIST, deleteTagSaga),
        takeLatest(types.COMPLEX_SYNC_TAGS, complexSyncTagsSaga),
        takeLatest(types.LOAD_COMPLEX_TYPE_GROUPING, loadComplexTypeGroupingSaga),
        takeLatest(types.LOAD_COMPLEX_MEASURING, loadComplexMeasuringSaga),
        takeLatest(types.LOAD_RECOGNITION_CAMERA_DIR_TYPES, loadRecognitionCameraDirTypesSaga),
        takeLatest(types.CREATE_COMPLEX_ENTITY, createComplexEntitySaga),
        takeLatest(types.EDIT_COMPLEX_ENTITY, editComplexEntitySaga),
        takeLatest(types.DELETE_COMPLEX_ENTITY, deleteComplexEntitySaga),
        takeLatest(types.LOAD_COMPUTER_TRANSFER_TYPES, loadComputerTransferTypesSaga),
        takeLatest(types.LOAD_COMPUTER_TRANSFER_RULES, loadComputerTransferRulesSaga),
        takeLatest(types.LOAD_DORIS_EXPORT_TYPES, loadDorisExportTypesSaga),

        takeLatest(types.LOAD_EXCLUSIVE_FILES, loadExclusiveFilesSaga),
        takeLatest(types.LOAD_EXCLUSIVE_FILES_HISTORY_LIST, loadExclusiveFilesHistoryListSaga),
        takeLatest(types.LOAD_EXCLUSIVE_FILES_LOG_LIST, loadExclusiveFilesLogListSaga),
        takeLatest(types.CREATE_EXCLUSIVE_FILE, createExclusiveFileSaga),
        takeLatest(types.EDIT_EXCLUSIVE_FILE, editExclusiveFilesSaga),
        takeLatest(types.DELETE_EXCLUSIVE_FILE, deleteExclusiveFilesSaga),
        takeLatest(types.LOAD_EXCLUSIVE_FILE_HISTORY, loadExclusiveFileHistorySaga),
        takeLatest(types.LOAD_CHECKLISTS, loadChecklistsSaga),
        takeLatest(types.CREATE_CHECKLISTS, createChecklistsSaga),
        takeLatest(types.EDIT_CHECKLISTS, editChecklistsSaga),
        takeLatest(types.DELETE_CHECKLISTS, deleteChecklistsSaga),
        takeLatest(types.LOAD_MATERIAL_STORAGE_GROUPS, loadMaterialStorageGroupsSaga),
        takeLatest(types.CREATE_MATERIAL_STORAGE_GROUP, createMaterialStorageGroupSaga),
        takeLatest(types.EDIT_MATERIAL_STORAGE_GROUP, editMaterialStorageGroupSaga),
        takeLatest(types.DELETE_MATERIAL_STORAGE_GROUP, deleteMaterialStorageGroupSaga),
        takeLatest(types.LOAD_CURRENT_EVENT_LIST, loadCurrentEventListSaga),
        takeLatest(types.LOAD_EVENT, loadEventObjectSaga),
        takeLatest(types.LOAD_VIOLATION_EVENTS, loadViolationEventsSaga),
        takeLatest(types.LOAD_EVENT_CHECK_LIST, loadEventCheckListSaga),
        takeLatest(types.SAVE_EVENT_CHECK_LIST, saveEventCheckListSaga),
        takeLatest(types.LOAD_PDD_VIOLATIONS_TREE, loadPddViolationsTreeSaga),

        takeLatest(types.LOAD_CHECKLIST_GROUP, loadCheckListGroupSaga),
        takeLatest(types.CREATE_CHECKLIST_GROUP, createCheckListGroupSaga),
        takeLatest(types.EDIT_CHECKLIST_GROUP, editCheckListGroupSaga),
        takeLatest(types.DELETE_CHECKLIST_GROUP, deleteCheckListGroupSaga),

        takeLatest(types.CHECK_FGIS, checkFgisSaga),
        takeLatest(types.LOAD_DOCUMENTS_HISTORY, loadDocumentsHistorySaga),
        takeLatest(types.LOAD_EVENTS_STATISTICS, loadEventsStatisticSaga),

        takeLatest(types.LOAD_COMPLEX_BY_RADIUS, loadComplexByRadiusSaga),

        takeLatest(types.LOAD_CAMERA_VIOLATIONS_TREE, loadCameraViolationsTreeSaga),
        takeLatest(types.LOAD_PDD_REPORT_EXPANDED, getPddReportExpandedSaga),

        takeLatest(types.LOAD_MATERIAL_EXPORT_GROUP, loadMaterialExportGroupSaga),
        takeLatest(types.CREATE_MATERIAL_EXPORT_GROUP, createMaterialExportGroupSaga),
        takeLatest(types.EDIT_MATERIAL_EXPORT_GROUP, editMaterialExportGroupSaga),
        takeLatest(types.DELETE_MATERIAL_EXPORT_GROUP, deleteMaterialExportGroupSaga),

        takeLatest(types.LOAD_MATERIAL_EXPORT_GROUP_TYPES, loadMaterialExportGroupTypesSaga),
        takeLatest(types.LOAD_MATERIAL_EXPORT_GROUP_PROTOCOLS, loadMaterialExportGroupProtocolsSaga),

        takeLatest(types.LOAD_RECENT_PDD_REPORT_EXPANDED, getRecentPddReportExpandedSaga),

        takeLatest(types.LOAD_TYPE_OF_MISTAKES_FOR_CHECKLIST, loadTypeOfMistakesForCheckListSaga),
        takeLatest(types.CREATE_TYPE_OF_MISTAKES_FOR_CHECKLIST, createTypeOfMistakesForCheckListSaga),
        takeLatest(types.DELETE_TYPE_OF_MISTAKES_FOR_CHECKLIST, deleteTypeOfMistakesForCheckListSaga),
        takeLatest(types.EDIT_TYPE_OF_MISTAKES_FOR_CHECKLIST, editTypeOfMistakesForCheckListSaga),
        takeLatest(types.LOAD_EVENT_PDDS, loadEventPDDSSaga),
        takeLatest(types.LOAD_SETTINGS_GROUP, loadSettingsGroupSaga),
        takeLatest(types.EDIT_SETTINGS_GROUP, editSettingsGroupSaga),

        takeLatest(types.LOAD_SHOW_CASE_COMPLEX_STATUS, loadShowCaseComplexStatusSaga),
        takeLatest(types.LOAD_SHOW_CASE_DOCUMENTS_VERIFICATION, loadShowCaseDocumentsVerificationSaga),
        takeLatest(types.LOAD_DUPLO_CODES, loadDuploCodesSaga),
        takeLatest(types.EDIT_DUPLO_CODES, editDuploCodesSaga),

        takeLatest(types.LOAD_MATERIALS, loadMaterialsSaga),
        takeLatest(types.CREATE_MATERIAL, createMaterialSaga),
        takeLatest(types.EDIT_MATERIAL, editMaterialSaga),
        takeLatest(types.DELETE_MATERIAL, deleteMaterialSaga),

        takeLatest(types.MOVE_COMPUTER_TO_OTHER_COMPLEX, moveComputerToOtherComplexSaga),

        takeLatest(types.LOAD_MATERIAL_EXPORT, loadMaterialExportSaga),
        takeLatest(types.CREATE_MATERIAL_EXPORT, createMaterialExportSaga),
        takeLatest(types.EDIT_MATERIAL_EXPORT, editMaterialExportSaga),
        takeLatest(types.DELETE_MATERIAL_EXPORT, deleteMaterialExportSaga),
        takeLatest(types.CANCEL_MATERIAL_EXPORT, cancelMaterialExportSaga),
        takeLatest(types.LOAD_MATERIAL_EXPORT_TYPES, loadMaterialExportTypesSaga),
        takeLatest(types.LOAD_MATERIAL_EXPORT_PROTOCOLS, loadMaterialExportProtocolsSaga),
        takeLatest(types.LOAD_MATERIAL_EXPORT_STATUSES, loadMaterialExportStatusesSaga),

        takeLatest(types.LOAD_COMPLEX_CONDITIONS, loadComplexConditionsSaga),
    ]);
};
