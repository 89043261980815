import {
    ALL_REPORT_FILE_TYPES
} from './mime-types';

// формат от типов
const getFormatByName = (report) => {
    const formats = ALL_REPORT_FILE_TYPES.reduce((res, item) => {
        if (item.format) {
            return {
                ...res,
                [item.name]: item.format
            };
        }
        return res;
    }, {});

    return formats[report] || report;
};

export const getReport = (response, report, period) => {
    const link = document.createElement('a');
    const reportFormat = getFormatByName(report).toLowerCase();
    period
        ? (link.download = `report_${period}.${reportFormat}`)
        : (link.download = `report.${reportFormat}`);
    link.href = URL.createObjectURL(response);
    link.click();
    URL.revokeObjectURL(link.href);
};
