import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import messages from '../../../../helpers/constants/messages';
import { useValidation } from '../../../../helpers/hooks';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import Modal from '../../../common/Modal';
import SingleKeyboardDateTimePicker from '../../../common/Dates/SingleKeyboardDateTimePicker';
import { fullDateTimeWithTimeZone } from '../../../../helpers/date.config';

const StatusModal = ({ 
    open = false, 
    onClose = () => {}, 
    onSave = () => {}, 
    item = {}, 
    statuses = {}, 
    loadList = () => {} 
}) => {
    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const initialState = {
        status: item.status || '',
        cancel_comment: item.cancel_comment || '',
        date_fact: item?.date_fact || ''
    };

    const [formData, setFormData] = useState(initialState);

    const handleSave = () => {
        const prepareData = removeEmptyFields(formData);

        onSave(prepareData);
        validation.clear();
    };

    const onChangeDate = (value, key) => {
        setFormData({
            ...formData,
            [key]: fullDateTimeWithTimeZone(value)
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
        
    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
            loadList();
        }
    }, [onClose, loadList, requestStatus, validation]);

    const isDisabled = !formData.status
        || item.status === 5
        || (formData.status === 5 && !formData.cancel_comment);

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            noPadding
            title={'Изменение статуса'}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled 
                    },
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl
                    className="block" 
                    size="small" 
                    required 
                    variant="outlined"
                    error={validation.isValidation() && !requestStatus.success}
                    disabled={item.status === 5}
                >
                    <InputLabel>Статус</InputLabel>
                    <Select
                        value={formData?.status}
                        required
                        label="Статус"
                        onChange={handleChange}
                        name="status"
                    >
                        {Object.keys(statuses)?.length > 0
                            ? Object.keys(statuses)?.map((key) =>
                                <MenuItem key={key} value={Number(key)} disabled={key < 3}>{statuses[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                    {validation.isValidation() && !requestStatus.success
                            && Object.keys(validation.data).map(key => 
                                <FormHelperText key={key}>
                                    {validation.data[key]}
                                </FormHelperText> 
                            )
                    }
                </FormControl>
                {formData.status === 4 && (
                    <div className="block">
                        <SingleKeyboardDateTimePicker
                            onChange={(value) => onChangeDate(value, 'date_fact')}
                            value={formData.date_fact}
                            label={'Фактическая дата выполнения'}
                            required
                            error={validation.isKey('date_fact')}
                            helperText={validation.get('date_fact')}
                        />
                    </div>
                )}
                {formData?.status === 5 && (
                    <TextField
                        label="Причина отмены"
                        value={formData?.cancel_comment}
                        name="cancel_comment"
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        required={true}
                        error={validation.isKey('cancel_comment')}
                        helperText={validation.get('cancel_comment')}
                        disabled={item.status === 5}
                    />
                )}                
            </div>
        </Modal>
    );
};

export default StatusModal;
