import { HeaderPageVisible } from 'components/common/Showcase/HeaderPage';
import BoxContainer from 'components/common/Showcase/BoxContainer';
import Dashboard from 'components/pages/Reports/Efficiency/Dashboard';
// import Page from './Page';

// страница
const HomePage = (props) => (
    <BoxContainer style={{ padding: '1rem', backgroundColor: '#f5f5f5' }}>
        {/* шапка */}
        <HeaderPageVisible hideMain />

        {/* содержимое страницы */}
        {/* <Page/> */}
        <Dashboard/>
    </BoxContainer>
);

export default HomePage;