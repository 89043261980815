import L from 'leaflet';

const decoratorOptions = (color = '#3B9A58') => {
    return {
        patterns: [
            { 
                offset: '0%',
                repeat: 90,
                symbol: L.Symbol.arrowHead({ 
                    pixelSize: 15, 
                    pathOptions: { fillOpacity: 1, weight: 0, color },
                }) 
            }
        ]
    };
};

export default decoratorOptions;