import React, { useMemo } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';


function Select ({
    value,
    options, // [{ id, name }]
    onChange,
    multiple,
    label = '',
}) {
    const handleEvent = (_, value) => {
        onChange?.(value);
    };

    const currentValue = useMemo(() => {
        if (typeof value === 'object') {
            return value;
        }
        return options
            .find(({ id }) => id === value)
            || {};
    }, [value, options]);

    return (
        <FormControl variant="outlined" size="small">
            <Autocomplete
                value={currentValue}
                multiple={multiple}
                options={options}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions={true}
                getOptionLabel={(option) => option.name || ''}
                onChange={handleEvent}
                size="small"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label={label}
                    />
                )}
            />
        </FormControl>
    );
}

export default Select;
