import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useState } from 'react';
import messages from '../../../../../helpers/constants/messages';
import titles from '../../../../../helpers/constants/titles';
import { dateWithDashYYYYMMDD } from '../../../../../helpers/date.config';
import { useStoreProp } from '../../../../../helpers/hooks';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import { loadDefectStatuses } from '../../../../../redux/RoadWorks/actions';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import DefectGroups from '../../../../common/Autocomplete/RoadWorks/DefectGroups';
import DefectTypes from '../../../../common/Autocomplete/RoadWorks/DefectTypes';
import DateRange from '../../../../common/Dates/DateRange';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';

const Filter = ({ setParams }) => {
    const initialState = {
        name: '',
        status: [],
        description: '',
        address_text: '',
        registration_at_start: null,
        registration_at_end: null,
        liquidated_at_start: null,
        liquidated_at_end: null,
        organization_id_list: [],
        group_id_list: [],
        type_id_list: []
    };

    const statuses = useStoreProp(loadDefectStatuses, 'roadworks', 'defectStatuses');

    const [filterValues, setFilterValues] = useState(initialState);
    // const [isFiltered, setIsFiltered] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilterValues({
            ...filterValues,
            [name]: value
        });
    };
    
    const handleDate = (key) => (value) => {
        setFilterValues({
            ...filterValues,
            [key]: value
        });
    };

    const handleSearch = (params = filterValues) => {
        const newParams = {
            ...params,
            status: params?.status?.map(({ id }) => id),
            registration_at_start: params.registration_at_start && dateWithDashYYYYMMDD(params.registration_at_start),
            registration_at_end: params.registration_at_end && dateWithDashYYYYMMDD(params.registration_at_end),
            liquidated_at_start: params.liquidated_at_start && dateWithDashYYYYMMDD(params.liquidated_at_start),
            liquidated_at_end: params.liquidated_at_end && dateWithDashYYYYMMDD(params.liquidated_at_end),
            organization_id_list: params?.organization_id_list?.map(({ id }) => id),
            group_id_list: params?.group_id_list?.map(({ id }) => id),
            type_id_list: params?.type_id_list?.map(({ id }) => id)
        };
        setParams(newParams);
    };

    const resetFilter = (needRefresh) => {
        if (needRefresh) {
            setParams(initialState);
        }
        setFilterValues(initialState);
    };

    const handleClickEnter = (e) => {
        if (e?.key === 'Enter')  {
            const paramsWithoutEmpty = removeEmptyFields(filterValues);
            if (Object.keys(paramsWithoutEmpty).length > 0) {
                handleSearch();
            }
        }
    };

    const isDisabled = !filterValues.name
        && !filterValues.status.length
        && !filterValues.description
        && !filterValues.address_text
        && !filterValues.registration_at_start
        && !filterValues.registration_at_end
        && !filterValues.liquidated_at_start
        && !filterValues.liquidated_at_end
        && !filterValues.organization_id_list.length
        && !filterValues.group_id_list.length
        && !filterValues.type_id_list.length;

    const statuseArray = Array.isArray(statuses) 
        ? statuses 
        : Object.entries(statuses).map(([key, value]) => ({ status_id: key, name: value }));


    const updateFilter = (filters) => {
        setFilterValues({ // обновляем локальный стейт
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            handleSearch( removeEmptyFields({ // отправляем в родителя, чтобы произошел запрос
                ...initialState,
                ...filters,
            }));
        }
    };
    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={handleSearch} 
            filter={filterValues} 
            setUserFilter={updateFilter}  // если у пользователя сохранен фильтр для этой страницы, мы его из общего компонента сетаем сюда
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={filterValues?.name}
                    onChange={handleChange}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    name="name"
                    type="text"
                    onKeyPress={handleClickEnter}
                />
                <FormControl variant="outlined" size="small">
                    <Autocomplete
                        value={filterValues?.status || []}
                        options={statuseArray?.map(({ status_id, name }) => ({ id: status_id, label: name }))}
                        filterSelectedOptions={true}
                        getOptionLabel={(option) => option.label || ''}
                        noOptionsText={messages.NO_DATA}
                        size="small"
                        multiple
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        onChange={(e, newValues) => setFilterValues({
                            ...filterValues,
                            status: newValues
                        })}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label="Статус"
                            />
                        )}
                    />
                </FormControl>
                <TextField
                    value={filterValues?.address_text}
                    onChange={handleChange}
                    label={titles.ADDRESS}
                    variant="outlined"
                    size="small"
                    name="address_text"
                    type="text"
                    onKeyPress={handleClickEnter}
                /> 
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <DateRange
                    dateOnly={true}
                    valueStartDate={filterValues.registration_at_start}
                    valueEndDate={filterValues.registration_at_end}
                    handleDate={handleDate('registration_at_start')}
                    handleEndDate={handleDate('registration_at_end')}
                    startLabel="Дата регистрации от:"
                    endLabel="Дата регистрации до:"
                />
                <TextField
                    value={filterValues?.description}
                    onChange={handleChange}
                    label={titles.DESCRIPTION}
                    variant="outlined"
                    size="small"
                    name="description"
                    type="text"
                    onKeyPress={handleClickEnter}
                />
                <DateRange
                    dateOnly={true}
                    valueStartDate={filterValues.liquidated_at_start}
                    valueEndDate={filterValues.liquidated_at_end}
                    handleDate={handleDate('liquidated_at_start')}
                    handleEndDate={handleDate('liquidated_at_end')}
                    startLabel="Фактическая дата устранения от:"
                    endLabel="Фактическая дата устранения до:"
                />
                <SelectCompany
                    multiple
                    selected={filterValues.organization_id_list}
                    onChange={(value) => setFilterValues({ ...filterValues, organization_id_list: value })}
                    label={titles.ORGANIZATION}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                />
                <DefectGroups
                    selected={filterValues.group_id_list || []}
                    multiple
                    onChange={(value) => setFilterValues({ ...filterValues, group_id_list: value })}
                />
                <DefectTypes
                    selected={filterValues.type_id_list || []}
                    multiple
                    onChange={(value) => setFilterValues({ ...filterValues, type_id_list: value })}
                    filter={{ group_ids: filterValues?.group_id_list?.map(({ id }) => id) }}
                />
                
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
