import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    TextField,
    InputAdornment,
    IconButton,
} from '@mui/material/';
import { Autocomplete } from '@mui/material';
import {
    getRoles,
} from '../../../../redux/Admin/actions';
import UniversalSelect from '../../../common/UniversalSelect';
import makeStyles from '@mui/styles/makeStyles';
import titles from '../../../../helpers/constants/titles';

const useStyles = makeStyles({
    input: {
        paddingRight: '30px !important',
    },
    adornment: {
        position: 'absolute',
        right: '6px',
    },
});

const SelectRole = ({
    role_list = [],
    onRoleChange,
    validation,
    multiple = false,
    required = false,
    // handleChangeCodes = () => {}
}) => {
    const dispatch = useDispatch();
    const styles = useStyles();

    const [showList, setShowList] = useState(false);

    const handleChangeRoles = (role) => {
        onRoleChange(role);
        setShowList(false);
    };

    const autoCompleteRoleChange = (e, value) => {
        if (e?.key !== 'Enter') onRoleChange(value);
    };

    const getRoleList = (params) => {
        const { page, limit, query: name } = params;
        dispatch(getRoles( page, limit, name && { name } ));
    };

    return (
        <>
            <div className="block">
                <Autocomplete
                    multiple
                    value={role_list}
                    options={[]}
                    filterSelectedOptions
                    getOptionLabel={(option) => option.description}
                    freeSolo
                    size="small"
                    disabledItemsFocusable
                    onChange={autoCompleteRoleChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required={required}
                            size="small"
                            variant="outlined"
                            label="Роли"
                            error={!!validation}
                            helperText={validation}
                            InputProps={{
                                ...params.InputProps,
                                classes: { root: styles.input },
                                endAdornment: (
                                    <InputAdornment position={'end'} classes={{ root: styles.adornment }}>
                                        {role_list && Object.keys(role_list).length > 0 
                                                && <IconButton size="small" onClick={() => handleChangeRoles(multiple ? [] : {})}>
                                                    <i className="far fa-times"/>
                                                </IconButton>
                                        }
                                        <IconButton size="small" onClick={() => setShowList(true)}>
                                            <i className="fas fa-search-plus"/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
            </div>
            {showList
                && <UniversalSelect
                    fetchList={getRoleList}
                    onAccept={handleChangeRoles}
                    storeName="admin"
                    storeNameProps="roles"
                    keyProp="id"
                    renderKey={(el) => `${el.id}_${el.entity_code}`}
                    withSearch
                    isSelected
                    selected={role_list}
                    multiple={multiple}
                    renderProps={(el) => <div>{el.description}</div>}
                    isOpen={showList}
                    onClose={() => setShowList(false)}
                    noPadding
                    title={titles.CREATE_ROLE}
                    small
                />
            }
        </>
    );
};

export default SelectRole;