import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { isEqual } from 'lodash';

import { usePrevious } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import SidebarItem from 'components/MapComponents/SidebarItem';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import Loading from 'components/common/Loading';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import getFilters from 'components/MapComponents/helpers/getFilters';
import * as actions from 'modules/TransportWater/redux/actions';
import * as transportWaterSelector from 'modules/TransportWater/redux/selectors';

import { getAnchorIcon, getFilter } from '../helper';
import config from '../config';

import FilterForm from './Filter';
import Item from './Item';

const SideBar = (props) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const [data, setData] = useState([]);
    const [meta, setMeta] = useState({});

    const [loading, setLoading] = useState(false);

    const active = useSelector(transportWaterSelector.active);
    const dockColorRefresh = useSelector(transportWaterSelector.dockColorRefresh);

    const filters = useSelector(transportWaterSelector.filters);
    const prevFilters = usePrevious(filters);

    const activeId = Object.keys(active).length > 0 ? active.id : 0;

    const fetchList = () => {
        const filter = getFilters(filters, getFilter);
        dispatch(actions.loadDockSidebar(
            {
                ...filter,
                page,
                limit,
            },
            setLoading,
            (data) => {
                const current_page = data?.meta?.current_page || 1;
                setData(old => current_page === 1
                    ? data.data || []
                    : [...old, ...data.data]
                );
                setMeta(old => ({
                    ...old,
                    ...(data.meta || {})
                }));
            }
        ));
    };

    // обновление цвета
    useEffect(() => {
        const tmpKeys = Object.keys(dockColorRefresh);
        if (tmpKeys.length > 0) {
            // заменяем цвет
            setData(old => old.map(station => tmpKeys.includes(station.id)
                ? { ...station, color: dockColorRefresh[station.id] }
                : station
            ));
            dispatch(actions.setDockColorRefresh({}));
        }
    }, [dockColorRefresh]);

    const handleScrollUpdate = (values) => {
        const {
            last_page = 0
        } = meta;
        if (
            values.top > 0.98
            && loading === false
            && page < last_page
        ) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            setPage(1);
        }
        fetchList();
    }, [page, limit, filters, prevFilters]);

    const renderList = (dataList) => (
        <>
            {dataList.map((item) => {
                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        icon={`<i class="${getAnchorIcon}" style="color: ${item.color}"></i>`}
                        onClick={() => {
                            if (activeId) {
                                dispatch(actions.setActive({}));
                                setTimeout(() => {
                                    dispatch(actions.setActive(item));
                                }, 300);
                            } else {
                                dispatch(actions.setActive(item));
                            }
                        }}
                    >
                        <Item {...item} />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={() => setPage(1)}
                onSetFilter={(filter) => dispatch(actions.setFilters(filter))}
                onClearFilter={() => dispatch(actions.setFilters({}))}
                content={({ data, onChange }) => <FilterForm data={data} onChange={onChange} />}
                layer={config.slug}
            />

            {data.length > 0 && (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={config.name}
                            count={meta?.total}
                        />
                        {data.length === 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList(data)}
                    </div>
                </Scrollbars>
            )}

            {loading && <Loading className="absolute bottom fill" />}
        </div>
    );
};

export default SideBar;
