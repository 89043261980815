// иконка для карты
const iconMap = ({ fill = '#000', innerFill = '#fff' }) => {
    return `
        <svg viewBox="0 0 107.000000 130.000000" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
            <g>
                <g id="svg_tl_4">
                    <g fill="${fill}" id="svg_tl_1" transform="translate(0,130) scale(0.10000000149011612,-0.10000000149011612)">
                        <path id="svg_tl_2" d="m440.34884,1284.550725c-100,-19 -206,-75 -281,-150c-104,-104 -154,-228 -154,-381c0,-144 38,-245 149,-392c64,-85 297,-316 348,-345c30,-17 31,-17 71,12c68,49 237,216 302,299c157,200 210,350 181,517c-25,142 -121,287 -241,364c-108,70 -251,98 -375,76z"/>
                    </g>
                </g>
                <g fill="${innerFill}" stroke="null" id="svg_tl_9">
                    <path stroke="null" id="svg_tl_8" d="m80.342003,47.184648l-6.817191,0l0,-6.435144c4.057649,-2.361873 6.817191,-6.709252 6.817191,-11.744032l-6.817191,0l0,-4.544794c0,-2.509578 -2.035216,-4.544794 -4.544794,-4.544794l-31.813558,0c-2.509578,0 -4.544794,2.035216 -4.544794,4.544794l0,4.544794l-6.817191,0c0,5.033359 2.759542,9.380739 6.817191,11.744032l0,6.435144l-6.817191,0c0,5.033359 2.759542,9.380739 6.817191,11.744032l0,6.435144l-6.817191,0c0,5.358596 3.120285,9.948838 7.616791,12.177207c2.367554,8.677716 10.224366,15.091556 19.651973,15.091556s17.284419,-6.41384 19.651973,-15.091556c4.496506,-2.228369 7.616791,-6.818611 7.616791,-12.177207l-6.817191,0l0,-6.435144c4.057649,-2.363293 6.817191,-6.710672 6.817191,-11.744032zm-13.634382,24.996367c0,7.517373 -6.115588,13.634382 -13.634382,13.634382c-7.517373,0 -13.634382,-6.117009 -13.634382,-13.634382l0,-45.44794l27.268764,0l0,45.44794zm-13.634382,-27.268764c3.765078,0 6.817191,-3.052113 6.817191,-6.817191s-3.052113,-6.817191 -6.817191,-6.817191s-6.817191,3.052113 -6.817191,6.817191s3.052113,6.817191 6.817191,6.817191zm0,18.179176c3.765078,0 6.817191,-3.052113 6.817191,-6.817191s-3.052113,-6.817191 -6.817191,-6.817191s-6.817191,3.052113 -6.817191,6.817191s3.052113,6.817191 6.817191,6.817191zm0,-10.225786c1.878988,0 3.408595,1.529607 3.408595,3.408595s-1.529607,3.408595 -3.408595,3.408595s-3.408595,-1.529607 -3.408595,-3.408595s1.529607,-3.408595 3.408595,-3.408595zm0,28.404962c3.765078,0 6.817191,-3.052113 6.817191,-6.817191s-3.052113,-6.817191 -6.817191,-6.817191s-6.817191,3.052113 -6.817191,6.817191s3.052113,6.817191 6.817191,6.817191zm0,-10.225786c1.878988,0 3.408595,1.529607 3.408595,3.408595s-1.529607,3.408595 -3.408595,3.408595s-3.408595,-1.529607 -3.408595,-3.408595s1.529607,-3.408595 3.408595,-3.408595z"/>
                </g>
            </g>
        </svg>
    `;
};

export default iconMap;
