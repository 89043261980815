import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme
} from '@mui/material/styles';
import { Settings } from 'luxon';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import 'leaflet/dist/leaflet.css';
import 'react-datetime/css/react-datetime.css';
import 'react-tabs/style/react-tabs.css';

import RestrictedRoute from 'components/routes/RestrictedRoute';
import routes from 'components/routes/pages';
import appTheme from 'helpers/configs/theme';
import { useVersion } from 'helpers/hooks';
import { authSelectors } from 'redux/Auth';
import { config } from './config';
import TestNotify from './TestNotify';
// import '../node_modules/leaflet/dist/leaflet.css';
// import '../node_modules/react-datetime/css/react-datetime.css';

export const theme = createTheme(appTheme);

const App = (props) => {
    const projectTimeZone = config?.project?.timezoneTitle;
    // для глобального учета таймзоны проекта мы устанавливаем таймзону глобально через luxon
    Settings.defaultZone = projectTimeZone;

    const {
        match,
        // history
    } = props;

    const project = useSelector(authSelectors.project || {});

    // проверка версии приложения
    const version = useVersion();

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} locale={'ru'}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    {/* блок версии приложения */}
                    {version}

                    <TestNotify/>

                    <Switch key={project.code}>
                        {routes.map((route) => {
                            return route.restricted
                                ? (
                                    <RestrictedRoute
                                        key={`${route.path}_restricted`}
                                        {...route}
                                        path={`${match.url}`}
                                    />)
                                : (
                                    <Route key={route.path} {...route} />);
                        })}
                    </Switch>
                </ThemeProvider>
            </StyledEngineProvider>
        </LocalizationProvider>
    );
};

export default App;
