import L from 'leaflet';
import { render, unmountComponentAtNode } from 'react-dom';
import StoreProvider from './StoreProvider';


const handleClickPopUp = (data, newId, layer, layerProps) => () => {
    const {
        map,
        centerByClick,
        centerByClickBefore,
        centerByClickAfter,
        popUpTemplate,
        onClick,
    } = layerProps;

    if (centerByClick) {
        centerByClickBefore && centerByClickBefore();
        const geoJsonLayer = layer.toGeoJSON();
        const json = L.geoJSON(geoJsonLayer);
        const boundce = json.getBounds();
        map.fitBounds(boundce);
        centerByClickAfter && centerByClickAfter();
    }

    onClick && onClick(data);
    setTimeout(() => {
        const currentId = document.getElementById(newId);

        if (currentId) {
            currentId && unmountComponentAtNode(currentId);
            render(
                <StoreProvider>
                    {popUpTemplate(data)}
                </StoreProvider>,
                currentId
            );
        }
    }, 300);
};

export default handleClickPopUp;