import { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from 'redux/WantedCars/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import CommentAction from 'components/common/CommentAction';
import ConfirmModal from 'components/common/ConfirmModal';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import Info from './Info';
import ModalForm from './ModalForm';

const Item = ({ item, reloadList }) => {
    const { id, grz, reason, reason_stop_at } = item;

    const dispatch = useDispatch();

    const [showInfo, setShowInfo] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isOpenStopModal, setIsOpenStopModal] = useState(false);

    const handleDelete = () => {
        const callback = () => {
            setIsOpenDeleteModal(false);
            reloadList(true);
        };
        dispatch(actions.deleteWanted(id, callback));
    };

    const onStopWanted = (comment) => {
        const callback = () => {
            setIsOpenStopModal(false);
            reloadList();
        };
        dispatch(actions.stopWanted(id, comment, callback));
    };

    return (
        <>
            <LSContentItem onClick={() => setShowInfo(!showInfo)}>
                <LSContentColumn>{grz || ''}</LSContentColumn>

                <LSContentColumn>{reason || ''}</LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.removeIcon,
                                name: titles.STOP_WANTED,
                                onClick: () => setIsOpenStopModal(true),
                                disabled: reason_stop_at,
                                accessProp: 'is_update',
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setIsOpenModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setIsOpenDeleteModal(true),
                            },
                        ]}
                    />

                    <ActionMore isOpen={showInfo} />
                </LSContentColumn>
            </LSContentItem>

            {showInfo && <Info isOpen={showInfo} item={item} />}

            {isOpenModal && (
                <ModalForm
                    item={item}
                    isOpen={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    reloadList={reloadList}
                />
            )}

            {isOpenDeleteModal && (
                <ConfirmModal
                    message={messages.CONFIRM_DELETE}
                    open={isOpenDeleteModal}
                    onSuccess={handleDelete}
                    onClose={() => setIsOpenDeleteModal(false)}
                />
            )}

            {isOpenStopModal && (
                <CommentAction
                    open={isOpenStopModal}
                    title={titles.WANTED_STOP}
                    onClose={() => setIsOpenStopModal(false)}
                    onAccept={onStopWanted}
                    inputLabel={titles.INDICATE_REASON_STOPPING_SEARCH}
                />
            )}
        </>
    );
};

export default Item;
