import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import TableItem from './TableItem';

const useStyles = makeStyles({
    tableWrapper: {
        maxHeight: '100%',
        maxWidth: '100%',
        overflow: 'scroll',
    }
});

const OTKTable = ({ data, duploCodes, complexTypes }) => {
    const classes = useStyles();

    // объединяем все данные по одному комплексу в один объект
    const unitData = () => {
        return data.reduce((result, el) => {
            result[el.complex_id] = result[el.complex_id] || {};
            result[el.complex_id] = {
                complex: el.complex,
                data: [
                    ...(result[el.complex_id].data || []),
                    {
                        item: el,
                        code: el.code,
                        direction: el.direction,
                        events: el.events,
                    },
                ],
            };
            return result;

        }, {});
    };

    return (
        <>
            <TableContainer className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead >
                        <TableRow >
                            <TableCell align="center" rowSpan={2} colSpan={1} style={{
                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                left: 0,
                                position: 'sticky',
                                // maxWidth: '150px',
                                zIndex: 3
                            }}>
                                Камера КФВФ
                            </TableCell>
                            <TableCell align="center" rowSpan={2} colSpan={1} style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                Проезды
                            </TableCell>
                            <TableCell align="center" rowSpan={2} colSpan={1} style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                Технический материал
                            </TableCell>
                            <TableCell align="left" colSpan={duploCodes.length}>
                                Нарушения по кодам ДУПЛО
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {duploCodes.map((item) => (
                                <TableCell
                                    key={item.id}
                                    align="center"
                                    style={{ top: 54, borderRight: '1px solid rgba(224, 224, 224, 1)' }}
                                >
                                    {item.id+(item?.koap_name ? ` - ${item.koap_name}` : '')}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(unitData()).map(([key, item]) => (
                            <TableItem
                                duploCodes={duploCodes}
                                complexTypes={complexTypes}
                                item={item}
                                key={key}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
};

export default OTKTable;
