import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet.heat';

// тепловой слой
const HeatLayer = ({
    json = [],
    radius = 25,
    max = 12,
    minOpacity = 0.2,
    maxZoom = 16,
    gradient = null,
    parent,
    latGetProp= (m) => m.geometry.coordinates[1],
    lonGetProp= (m) => m.geometry.coordinates[0],
    intensityGetProp= (m) => m.properties.intensity,
}) => {
    /*[
        {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [77.75659494, 12.96821717]
            },
            properties: {
                intensity: 3,
                description: "описание"
            }
        },
        {}
    ]*/

    const layerRef = useRef(null);

    const configObj = {
        radius,
        max,
        minOpacity,
        maxZoom,
        gradient,
    };

    const config = Object.keys(configObj)
        .reduce((res, key) => {
            if (configObj[key]) {
                return {
                    ...res,
                    [key]: configObj[key]
                };
            }
            return res;
        }, {});

    // создание
    useEffect(() => {
        if (!layerRef.current) {
            const layer = L.heatLayer([], config).addTo(parent);
            layerRef.current = layer;


            return () => {
                parent.removeLayer(layer);
                layer.remove();
                layerRef.current = null;
            };
        }
    }, []);

    // обновление опций
    useEffect(() => {
        if (layerRef.current) {
            layerRef.current.setOptions(config);
        }
    }, [config]);

    // обновление данных
    useEffect(() => {
        if (layerRef.current) {
            const data = json.reduce((res, item) => ([
                ...res,
                [
                    latGetProp(item),
                    lonGetProp(item),
                    intensityGetProp(item)
                ]
            ]), []);
            layerRef.current.setLatLngs(data);
        }
    }, [layerRef.current, json]);

    return null;
};

export default HeatLayer;