import { useMemo } from 'react';

import { 
    Circle, 
    FeatureGroup, 
    Map, 
    MapControl, 
    Marker, 
    ToolTip 
} from 'components/MapComponents/leaflet';
import { createIconMarker } from 'components/MapComponents/Layers/Incidents/helper';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import titles from 'helpers/constants/titles';


const MapForm = ({ 
    latlng,
    selectedDtpList,
    setSelectedDtpList,
    nearDtpList,
    radius
}) => {
    
    const nearDtpMarkers = useMemo(() => {
        const color = '#676a679e';
        const selectDtpIds = selectedDtpList.reduce((res, item) => {
            res[item.id] = true;
            return res;
        },{});

        return nearDtpList.reduce((res, item) => {
            if (!selectDtpIds[item.id]){
                res.push((
                    <Marker
                        key={item.id}
                        icon={createIconMarker(color)}
                        latlng={[item.lat, item.lon]}
                        attribution={{ color }}
                        onClick={() => {
                            const newData = [...selectedDtpList, item];
                            setSelectedDtpList(newData);
                        }}
                    >
                        <ToolTip
                            offset={[0, -30]}
                            direction="top"
                        >
                            <div><strong>ID: </strong>{item.id || ''}</div>
                            <div><strong>{titles.DTP_TYPE}: </strong>{item.dtp_type_name || ''}</div>
                            <div><strong>Дата/время: </strong>{fullDateTimeWithoutSeconds(item.dtp_at) || ''}</div>
                        </ToolTip>
                    </Marker>
                ));
            }
            return res;
        },[]);
    }, [nearDtpList, selectedDtpList, setSelectedDtpList]);

    const selectedDtpMarkers = useMemo(() => {
        const color = '#4b9c4b';
        return selectedDtpList.map(item => {
            return (
                <Marker
                    key={item.id}
                    icon={createIconMarker(color)}
                    latlng={[item.lat, item.lon]}
                    attribution={{ color }}
                    onClick={() => {
                        const newData = selectedDtpList.filter(el => el.id !== item.id);
                        setSelectedDtpList(newData);
                    }}
                >
                    <ToolTip
                        offset={[0, -30]}
                        direction="top"
                    >
                        <div><strong>ID: </strong>{item.id || ''}</div>
                        <div><strong>{titles.DTP_TYPE}: </strong>{item.dtp_type_name || ''}</div>
                        <div><strong>Дата/время: </strong>{fullDateTimeWithoutSeconds(item.dtp_at) || ''}</div>
                    </ToolTip>
                </Marker>
            );
        });
    }, [selectedDtpList, setSelectedDtpList]);

    return (
        <div style={{ minWidth: '60%', minHeight: '400px', height: '100%', marginRight: 20 }}>
            <Map
                center={latlng}
                zoom={17}
            >
                <MapControl>
                    <FeatureGroup>
                        {/*<Cluster*/}
                        {/*    iconCreateFunction={iconCreateFunctionSimple}*/}
                        {/*>*/}
                        {/*    {nearDtpMarkers}*/}
                        {/*    {selectedDtpMarkers}*/}
                        {/*</Cluster>*/}

                        {nearDtpMarkers}
                        {selectedDtpMarkers}

                        {radius &&(
                            <Circle
                                latlng={latlng}
                                color={'#676a679e'}
                                radius={radius}
                            />
                        )}
                        {/* {latLon?.length && (
                            <Marker latlng={latLon}>
                                <ToolTip direction="top" offset={[0, -42]}>
                                    Место концентрации ДТП
                                </ToolTip>
                            </Marker>
                        )} */}
                    </FeatureGroup>
                </MapControl>
            </Map>
        </div>
    );
};

export default MapForm;
