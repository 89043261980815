// иконка для карты
const iconMap = ({ fill = '#000', innerFill = '#fff' }) => {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 107.000000 130.000000">
            <g>
                <g id="svg_cc_3">
                    <rect x="-1" y="-1" width="109" height="132" id="canvas_background" fill="none"/>
                </g>
                <g fill="${fill}" transform="translate(0,130) scale(0.10000000149011612,-0.10000000149011612) " id="svg_cc_1">
                    <path d="m440.34884,1286c-100,-19 -206,-75 -281,-150c-104,-104 -154,-228 -154,-381c0,-144 38,-245 149,-392c64,-85 297,-316 348,-345c30,-17 31,-17 71,12c68,49 237,216 302,299c157,200 210,350 181,517c-25,142 -121,287 -241,364c-108,70 -251,98 -375,76z" id="svg_cc_2"/>
                </g>
                <g fill="${innerFill}" stroke="null" id="svg_cc_9">
                    <path stroke="null" id="svg_cc_4" d="m85.779583,40.969929l-47.420744,-20.84978a4.012225,4.012225 0 0 0 -4.973906,1.735288l-11.953924,20.922502a4.012225,4.012225 0 0 0 2.112687,5.767574l19.631067,8.131026l-4.995221,13.309304l-9.254449,0l0,-4.012225a2.006113,2.006113 0 0 0 -2.006113,-2.006113l-4.012225,0a2.006113,2.006113 0 0 0 -2.006113,2.006113l0,16.048902a2.006113,2.006113 0 0 0 2.006113,2.006113l4.012225,0a2.006113,2.006113 0 0 0 2.006113,-2.006113l0,-4.012225l12.036676,0a4.012225,4.012225 0 0 0 3.761461,-2.604185l5.874149,-15.665233l8.591178,3.558342a4.012225,4.012225 0 0 0 3.496905,-0.368623l23.729806,-14.83395c2.784735,-1.740303 2.413604,-5.906748 -0.635687,-7.126715zm6.077268,13.190191l-4.444794,-1.954706l-17.352875,10.845547l14.993185,6.592588a2.113941,2.113941 0 0 0 2.785989,-1.084555l5.103049,-11.612885a2.112687,2.112687 0 0 0 -1.084555,-2.785989z"/>
                </g>
            </g>
        </svg>
    `;
};

export default iconMap;
