import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import type { EgtsTransmitter } from './types';

export interface ModalFormProps {
    isOpen: boolean
    onClose: () => void
    reloadList: (isDelete?: boolean) => void
    actions: { [key: string]: any }
    isNew?: boolean
    item?: EgtsTransmitter
}

const ModalForm = ({ isOpen, isNew, reloadList, onClose, item, actions }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        name: item?.name || '',
        port: item?.port || 0,
        ip: item?.ip || ''
    };

    const [data, setData] = useState(initialState);
    const [loading, setLoading] = useState(false);

    useEffect(() => () => {
        setLoading(false);
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        setData({
            ...data,
            [name]: value
        });
    };

    const onAcceptModal = () => {
        const result = removeEmptyFields(data);

        const callback = () => {
            reloadList();
            onClose();
        };

        dispatch(isNew
            ? actions.addEgtsTransmitter(result, setLoading, callback)
            : actions.editEgtsTransmitter(item?.id, result, setLoading, callback));
    };

    const isDisabled = !data.name.trim()
        || !data.port
        || !data.ip.trim();

    return (
        <Modal
            isOpen={isOpen}
            title={
                isNew
                    ? 'Добавить ретранслятор'
                    : 'Редактировать ретранслятор'
            }
            onClose={onClose}
            noPadding={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose,
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: isDisabled,
                        loading,
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <FormInfoWrapper
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                    className="block"
                >
                    <TextField
                        label={'Название ретранслятора'}
                        size="small"
                        value={data.name}
                        variant="outlined"
                        name="name"
                        type="text"
                        onChange={handleChange}
                        required
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    error={validation.isKey('port')}
                    helperText={validation.get('port')}
                    className="block"
                >
                    <TextField
                        label={'Порт'}
                        size="small"
                        value={data.port}
                        variant="outlined"
                        name="port"
                        type="number"
                        onChange={handleChange}
                        required
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    error={validation.isKey('ip')}
                    helperText={validation.get('ip')}
                    className="block"
                >
                    <TextField
                        label={'IP'}
                        size="small"
                        value={data.ip}
                        variant="outlined"
                        name="ip"
                        type="text"
                        onChange={handleChange}
                        required
                    />
                </FormInfoWrapper>
            </form>
        </Modal>
    );
};

export default ModalForm;
