import { useState } from 'react';
import { useSelector } from 'react-redux';

import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import messages from 'helpers/constants/messages/common';
import { getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import DistrictSwitch from 'components/common/DistrictSwitch';
import PageLayout from 'components/layout/PageLayout';

import Filters from './Filters';
import Graph from './Graph';

function DtpGraph() {
    const districtsByMonth = useSelector(SituationalPlansSelectors.districtsByMonth);
    const loadingDistrictsByMonth = useSelector(SituationalPlansSelectors.loadingDistrictsByMonth);

    const initialState = {
        start_date: getDateWithDuration({ month: -1 }, getStartOf('day')),
        end_date: getEndOf('day'),
        type: null,
        status: '',
        threat_level: null,
        type_creation: null,
        is_area: false,
    };

    const [filterValues, setFilterValues] = useState(initialState);

    const renderContent = () => {
        return Object.keys(districtsByMonth).length !== 0
            ? <Graph data={districtsByMonth} />
            : <div>{messages.NO_DATA_FOR_PERIOD}</div>;
    };

    const onChange = (value, keyName) => {
        setFilterValues({
            ...filterValues,
            [keyName]: value
        });
    };

    return (
        <PageLayout
            header="Отчет по событиям по районам и месяцам"
            additionalButtons={<ReturnToButton/>}
            filters={<Filters
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                initialState={initialState}
                onChange={onChange}
            />}
            content={renderContent}
            loading={loadingDistrictsByMonth}
            informPanelProps={{
                buttons: <DistrictSwitch
                    value={filterValues.is_area}
                    onChange={(value) => onChange(value, 'is_area')}
                />
            }}
        />
    );
}

export default DtpGraph;
