import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

import type { SetStateAction } from 'react';
import type { Status } from './types';

interface FiltersProps {
    setParams: React.Dispatch<SetStateAction<{ page: number; data: {} }>>;
    statuses: Status[];
}

const Filters = ({ setParams, statuses }: FiltersProps) => {
    const initialState = {
        device_name: '',
        status: ''
    };

    const [filters, setFilters] = React.useState(initialState);

    const handleChange = (name: string, value: string) => {
        setFilters({ ...filters, [name]: value });
    };

    const search = () => {
        const prepareData = removeEmptyFields(filters, false);
        setParams({ page: 1, data: prepareData });
    };

    const resetFilter = (needRefresh: boolean) => {
        setFilters(initialState);
        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }
    };

    const updateFilter = (filters = {}) => {
        setFilters({ ...initialState, ...filters });
        if (Object.keys(filters).length > 0) {
            setParams({ page: 1, data: { ...initialState, ...filters } });
        }
    };

    return (
        <LayoutFilter
            filter={filters}
            onSearch={search}
            onResetFilter={resetFilter}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    label={titles.NAME}
                    value={filters.device_name}
                    onChange={(e) => handleChange('device_name', e.target.value)}
                />
                <FormControl size={'small'} variant="outlined">
                    <InputLabel>{titles.STATUS}</InputLabel>
                    <Select
                        value={filters.status}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        label="Статус"
                        name="status"
                    >
                        <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                        {statuses?.map((key) => (
                            <MenuItem key={key.id} value={key.id}>{key.description}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;
