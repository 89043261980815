import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Checkbox, FormControlLabel, FormHelperText, List, ListItem, TextField } from '@mui/material';

import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import UploadImageField from 'components/common/Upload/UploadImageField';
import icon from 'components/MapComponents/Layers/TransportIncidents/icons/icon';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import { createTransportIncidentType, editTransportIncidentType } from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import titles from 'helpers/constants/titles';
import svgToBase64 from 'helpers/svgToBase64';
import { useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';

import Scenarios from './Scenarios';

const ModalForm = ({ isOpen, onClose, isNew = false, item = {}, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingButton = useSelector(SituationalPlansSelectors.loadingButton);

    const initState = {
        name: item.name || '',
        response_scenarios: item.response_scenarios || [],
        image: item.image || null,
        is_statistics: item?.is_statistics || false
    };

    const [formData, setFormData] = useState(initState);

    const onAcceptModal = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            is_statistics: +formData?.is_statistics
        });

        const callback = () => {
            reloadList();
            onClose();
        };

        isNew
            ? dispatch(createTransportIncidentType(prepareData, callback))
            : dispatch(editTransportIncidentType(item?.id, prepareData, callback));
    };

    const onChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose,
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onAcceptModal,
                            disabled: formData.name.trim().length === 0,
                            loading: loadingButton,
                        },
                    ]}
                />
            }
        >
            <form className="modal__form">
                <Grid
                    container
                    direction="row"
                    spacing={0}
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid item xs={12}>
                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('name')}
                            helperText={validation.get('name')}
                        >
                            <TextField
                                label={titles.NAME}
                                size="small"
                                value={formData.name}
                                variant="outlined"
                                name="name"
                                type="text"
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                required
                            />
                        </FormInfoWrapper>

                        <Scenarios
                            selected_scenarios={formData.response_scenarios}
                            onChange={(value) => onChange('response_scenarios', value)}
                        />

                        <h2>Изображение для обозначения на карте</h2>
                        <FormHelperText>
                            Максимальный размер изображения 70х70 пикселей
                        </FormHelperText>
                        <List>
                            <ListItem divider>
                                <UploadImageField
                                    value={formData.image || svgToBase64(icon({ fill: '#000' })) || ''}
                                    name="image"
                                    color="#fff"
                                    onChange={onChange}
                                    label="Изображение"
                                    isSelectBorder
                                    imageSize={40}
                                    acceptFiles=".png"
                                    helperText="Размер изображения не должен превышать 70х70 пикселей"
                                    imageErrorValidation={(img) =>
                                        img.width > 70 || img.height > 70
                                    }
                                    errorText={validation.get('image')}
                                />
                            </ListItem>
                        </List>
                        <FormControlLabel
                            control={<Checkbox
                                checked={formData.is_statistics}
                                // className={classes.checkbox}
                                onClick={(e) => onChange('is_statistics', e.target.checked)}
                                size="small"
                            />}
                            label={'Учитывать в статистике'}
                        />
                    </Grid>
                </Grid>
            </form>
        </Modal>
    );
};

export default ModalForm;
