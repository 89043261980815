import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import messages from 'helpers/constants/messages';
import { roadworksSelectors } from 'redux/RoadWorks';
import { deleteWork } from 'redux/RoadWorks/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import AddEditModal from './AddEditModal';
import type { Work } from 'types/RoadWorks';

interface Props {
    item: Work;
    reloadList: () => void;
}

const Item = ({ item, reloadList }: Props) => {
    const dispatch = useDispatch();

    const loadingButton: boolean = useSelector(roadworksSelectors.loadingButton);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleConfirmDelete = () => {
        dispatch(deleteWork(item.id, () => {
            reloadList();
            setOpenDeleteModal(false);
        }));
    };

    const itemUnitsList = item.unit_of_measurement_list
        .map((unit, index, arr) => <span key={unit.id}>{unit.short_name}{index !== arr.length - 1 && ', '}</span>);

    return (
        <>
            <LSContentItem>
                <LSContentColumn>{item.name || messages.NO_VALUE}</LSContentColumn>
                <LSContentColumn>{(itemUnitsList.length > 0 && itemUnitsList) || messages.NO_VALUE}</LSContentColumn>
                <LSContentColumn>{item.road_work_group.name || messages.NO_VALUE}</LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
            {openEditModal && (
                <AddEditModal
                    isNew={false}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={() => {
                        reloadList();
                        setOpenEditModal(false);
                    }}
                />
            )}
            {openDeleteModal &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteModal}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setOpenDeleteModal(false)}
                    loadingButton={loadingButton}
                />
            )}
        </>
    );
};

export default Item;
