import {
    ListItem,
    ListItemText,
} from '@mui/material';
import cn from 'classnames';
import styles from '../workOrderControl.module.scss';

const TableHeader = () => {
    return (
        <>
            <ListItem divider dense>
                <ListItemText className={cn(styles.info_block, styles.info_block__header)} disableTypography>
                    <div className={cn(styles.info_block__item, styles.info_block__item_first)}>№</div>
                    <div className={styles.info_block__item}>Контрольные точки</div>
                    <div className={styles.info_block__item}>Авто-статус</div>
                    <div className={styles.info_block__item}>Ручной статус от диспетчера</div>
                    <div className={styles.info_block__item}>Количество проездов КТ</div>
                    <div className={styles.info_block__item}>Фактическое время прибытия</div>
                    {/*<div className={cn(styles.info_block__item, styles.info_block__item_last)}>Действия</div>*/}
                </ListItemText>
            </ListItem>
        </>
    );
};

export default TableHeader;