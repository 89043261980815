import React from 'react';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import columns from './columns';


const List = ({ list }) => {

    const table = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };

    const header = () => {
        return (
            <TableRow>
                {columns?.map(({ key, label }) => (
                    <TableCell
                        key={key}
                        variant="head"
                        align="center"
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            backgroundColor: 'background.paper',
                            padding: '5px'
                        }}
                    >
                        {label}
                    </TableCell>
                ))}
            </TableRow>
        );
    };

    const row = (_index, row) => {
        return (
            <React.Fragment>
                {columns.map(({ key }) => (
                    <TableCell
                        key={`${key}_cell`}
                        align="center"
                    >
                        {row?.[key] || ''}
                    </TableCell>
                ))}
            </React.Fragment>
        );
    };


    return (
        <Paper
            // sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
            style={{ height: 400, width: '100%' }}
        >
            <TableVirtuoso
                data={list}
                components={table}
                fixedHeaderContent={header}
                itemContent={row}
            />
        </Paper>
    );
};

export default List;
