// иконка для карты
const iconMap = ({ fill = '#000', innerFill = '#fff' }) => {
    return `
        <svg width="30" height="37" viewBox="0 0 30 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_404_2)">
                <path d="M12.3462 0.392494C9.54248 0.925204 6.57052 2.4953 4.46772 4.5981C1.55183 7.51399 0.149963 10.9906 0.149963 15.2803C0.149963 19.3177 1.21538 22.1495 4.32753 26.271C6.12192 28.6542 12.6546 35.1308 14.0845 35.9439C14.9257 36.4205 14.9537 36.4205 16.0752 35.6074C17.9817 34.2336 22.7201 29.5514 24.5425 27.2243C28.9444 21.6168 30.4303 17.4112 29.6172 12.7289C28.9163 8.74763 26.2247 4.68221 22.8602 2.52333C19.8322 0.560718 15.8229 -0.224328 12.3462 0.392494V0.392494Z" fill="${fill}"/>
                <g clip-path="url(#clip1_404_2)">
                    <path d="M24.1568 9.92775V20.0277C24.1568 20.957 23.3943 21.711 22.4546 21.711H7.7022C6.76244 21.711 6 20.957 6 20.0277V9.92775C6 8.99842 6.76244 8.24443 7.7022 8.24443H10.8229L11.2591 7.09065C11.5073 6.43486 12.1421 6 12.8513 6H17.3019C18.0111 6 18.6459 6.43486 18.8941 7.09065L19.3339 8.24443H22.4546C23.3943 8.24443 24.1568 8.99842 24.1568 9.92775ZM19.3339 14.9777C19.3339 12.6561 17.426 10.7694 15.0784 10.7694C12.7308 10.7694 10.8229 12.6561 10.8229 14.9777C10.8229 17.2993 12.7308 19.186 15.0784 19.186C17.426 19.186 19.3339 17.2993 19.3339 14.9777ZM18.1991 14.9777C18.1991 16.6786 16.7983 18.0638 15.0784 18.0638C13.3585 18.0638 11.9577 16.6786 11.9577 14.9777C11.9577 13.2769 13.3585 11.8916 15.0784 11.8916C16.7983 11.8916 18.1991 13.2769 18.1991 14.9777ZM13.9436 14.9777C13.9436 14.3605 14.4542 13.8555 15.0784 13.8555C15.3905 13.8555 15.6458 13.603 15.6458 13.2944C15.6458 12.9858 15.3905 12.7333 15.0784 12.7333C13.8266 12.7333 12.8088 13.7398 12.8088 14.9777C12.8088 15.2863 13.0641 15.5388 13.3762 15.5388C13.6883 15.5388 13.9436 15.2863 13.9436 14.9777Z" fill="${innerFill}"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_404_2">
                    <rect width="30" height="36.4486" fill="white"/>
                </clipPath>
                <clipPath id="clip1_404_2">
                    <rect width="18.25" height="16.0039" fill="white" transform="translate(6 6)"/>
                </clipPath>
            </defs>
        </svg>
    `;
};

export default iconMap;
