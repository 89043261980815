import moduleName from './module';
const prefix = `${moduleName}`;

/**
 * Constants
 * */
export const LOAD_TRACKING_LIST = `${prefix}/LOAD/TRACKING/LIST`;
export const LOADING_TRACKING_LIST = `${prefix}/LOADING/TRACKING/LIST`;
export const LOADED_TRACKING_LIST = `${prefix}/LOADED/TRACKING/LIST`;
export const LOAD_TRACKING_MORE_INFO = `${prefix}/LOAD/TRACKING/MORE/INFO`;
export const LOADING_TRACKING_MORE_INFO = `${prefix}/LOADING/TRACKING/MORE/INFO`;
export const LOADED_TRACKING_MORE_INFO = `${prefix}/LOADED/TRACKING/MORE/INFO`;
export const LOAD_TRACKING_DEPLOYED_INFO = `${prefix}/LOAD/TRACKING/DEPLOYED/INFO`;
export const LOADING_TRACKING_DEPLOYED_INFO = `${prefix}/LOADING/TRACKING/DEPLOYED/INFO`;
export const LOADED_TRACKING_DEPLOYED_INFO = `${prefix}/LOADED/TRACKING/DEPLOYED/INFO`;
export const LOAD_TRACKING_STATUSES = `${prefix}/LOAD/TRACKING/STATUSES`;
export const LOADED_TRACKING_STATUSES = `${prefix}/LOADED/TRACKING/STATUSES`;
export const SET_LOADING = `${prefix}/SET/LOADING`;
export const CREATE_WANTED = `${prefix}/CREATE/WANTED`;
export const EDIT_WANTED = `${prefix}/EDIT/WANTED`;
export const DELETE_WANTED = `${prefix}/DELETE/WANTED`;
export const SET_STATUS_WANTED = `${prefix}/SET/STATUS/WANTED`;
export const LOAD_WANTED_LIST = `${prefix}/LOAD/WANTED/LIST`;
export const LOADED_WANTED_LIST = `${prefix}/LOADED/WANTED/LIST`;
export const LOAD_TYPES_BASE = `${prefix}/LOAD/TYPES/BASE`;
export const LOADED_TYPES_BASE = `${prefix}/LOADED/TYPES/BASE`;
export const CREATE_TRACKING = `${prefix}/CREATE/TRACKING`;
export const EDIT_TRACKING = `${prefix}/EDIT/TRACKING`;
export const DELETE_TRACKING = `${prefix}/DELETE/TRACKING`;
export const STOP_WANTED = `${prefix}/STOP/WANTED`;
export const STOPPED_WANTED = `${prefix}/STOPPED/WANTED`;
// events
export const LOADING_WANTED_EVENTS = `${prefix}/LOADING_WANTED_EVENTS`;
export const LOAD_WANTED_EVENTS = `${prefix}/LOAD_WANTED_EVENTS`;
export const LOADED_WANTED_EVENTS = `${prefix}/LOADED_WANTED_EVENTS`;
export const SET_WANTED_EVENTS_REPORT_FILTER = `${prefix}/SET_WANTED_EVENTS_REPORT_FILTER`;
export const CLEAR_WANTED_EVENTS_REPORT_FILTER = `${prefix}/CLEAR_WANTED_EVENTS_REPORT_FILTER`;
export const LOAD_VEHICLE_INFO = `${prefix}/LOAD_VEHICLE_INFO`;
export const LOADED_VEHICLE_INFO = `${prefix}/LOADED_VEHICLE_INFO`;

export const LOAD_EVENT_WANTED_TRACK = `${prefix}/LOAD_EVENT_WANTED_TRACK`;
export const LOADED_EVENT_WANTED_TRACK = `${prefix}/LOADED_EVENT_WANTED_TRACK`;
export const LOADING_EVENT_WANTED_TRACK = `${prefix}/LOADING_EVENT_WANTED_TRACK`;