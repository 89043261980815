import { fullDateTime } from 'helpers/date.config';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import type { ItemProps } from './types';

const Item = ({ item }: ItemProps) => {
    return (
        <LSContentItem>
            <LSContentColumn>
                {fullDateTime(item.date_at)}
            </LSContentColumn>
            <LSContentColumn>
                {item?.user?.first_name} {item?.user?.last_name}
            </LSContentColumn>
            <LSContentColumn>
                {item?.microservice}
            </LSContentColumn>
            <LSContentColumn>
                {item?.action}
            </LSContentColumn>
        </LSContentItem>
    );
};

export default Item;
