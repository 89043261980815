import { useState } from 'react';
import cn from 'classnames';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import SelectEmployees from 'components/common/Autocomplete/Transport/Employees';
import SelectCompany from 'components/common/Autocomplete/Companies';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import Loading from 'components/common/Loading';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import styles from './brigades.module.scss';

const ModalAddEdit = ({ 
    isNew, 
    isOpen, 
    onClose, 
    item = {},
    reloadList,
    loading,
    storeName,
    actions,
}) => {

    const dispatch = useDispatch();
    const validation = useValidation();

    const [formValues, setFormValues] = useState({
        ...item,
        name: item?.name || '',
        number: item?.number || '',
        organization: item?.organization || {},
        organization_id: item?.organization_id || null,
        employees: item?.employees || [],
    });

    const handleChange = ({ target: { name, value } }) => {
        setFormValues({ ...formValues, [name]:(name === 'number' && value < 0) ? 0 : value });
    };

    const organizationHandler = (value) => {
        setFormValues({
            ...formValues,
            organization: value,
            organization_id: value?.id,
            employees: []
        });
    };

    const handleChangeEmployees = (employees) => {
        setFormValues({ ...formValues, employees });
    };

    
    const onAccept = () => {
        const paramsWithoutEmpty = removeEmptyFields(formValues);
        isNew
            ? dispatch(actions.addBrigades(paramsWithoutEmpty, () => {reloadList(); onClose();}))
            : dispatch(actions.editBrigades(item?.id, paramsWithoutEmpty, () => {reloadList(); onClose();}));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? `${titles.ADD} бригады` : `${titles.EDIT} бригады`}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAccept,
                        disabled: !formValues?.name || !formValues?.number || !formValues?.organization_id
                    }
                ]}
            />}
            noPadding
        >
            {loading && <Loading circular />}
            <form className="modal__form">
                <div className="row">
                    <FormInfoWrapper className="row__item" error={!!validation.isKey('name')} helperText={!!validation.get('name')}>
                        <TextField
                            value={formValues.name}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            size="small"
                            label={titles.NAME}
                            name="name"
                            error={!!validation.isKey('name')}
                        />
                    </FormInfoWrapper>

                    <FormInfoWrapper className={cn('row__item', styles.textFiledWithoutArrows)} error={!!validation.isKey('number')} helperText={!!validation.get('number')}>
                        <TextField
                            value={formValues.number}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            size="small"
                            label={titles.NUMBER}
                            name="number"
                            type="number"
                            error={!!validation.isKey('number')}
                            inputProps={{ min: 0 }}
                        />
                    </FormInfoWrapper>
                </div>
                <div className="block">
                    <SelectCompany
                        filter={{ withDeleted: 1 }}
                        selected={formValues.organization}
                        onChange={organizationHandler}
                        helperText={validation.get('organization_id')}
                        error={validation.isKey('organization_id')}
                        required
                    />
                </div>
                <div className="block">
                    <FormInfoWrapper helperText={!formValues?.organization_id && 'Сперва выберите организацию'}>
                        <SelectEmployees 
                            onChange={handleChangeEmployees}
                            selected={formValues?.employees}
                            multiple
                            disabled={!formValues?.organization_id}
                            filter={{ organization_id: formValues?.organization_id }}
                            label={'Сотрудники'}
                            loadList={actions.loadEmployees}
                            storeName={storeName}
                        />
                    </FormInfoWrapper>
                </div>
            </form>
        </Modal>
    );
};

export default ModalAddEdit;
