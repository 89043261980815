import { fullDateTime } from '../../../../helpers/date.config';
import messages from '../../../../helpers/constants/messages';
import Media from '../../../common/MediaModal';

const MediaModal = ({ isOpen, onClose, item }) => {
    
    const labels = {
        created_at: {
            title: 'Дата фиксации',
            getValue: (value) => fullDateTime(value),
        },
        camera: {
            title: 'Камера',
            getValue: () => item.camera_name || messages.NO_DATA,
        },
        class_name_text: {
            title: 'Тип объекта',
            getValue: () => item.class_name_text || messages.NO_DATA,
        },
        lat: {
            title: 'Широта',
            getValue: () => item.lat || messages.NO_DATA,
        },
        lon: {
            title: 'Долгота',
            getValue: () => item.lon || messages.NO_DATA,
        },
    };

    const renderItems = (labels) => (
        <div style={{ maxWidth: '25%' }}>
            {Object.keys(labels).map(key => {
                const { title, getValue } = labels[key];

                return (
                    <p key={key} className="row">
                        <div style={{ width: '50%' }}>
                            <strong>{title}:</strong>
                        </div>
                        <div style={{ width: '50%' }}>
                            <span>{getValue(key) || messages.NO_DATA}</span>
                        </div>
                    </p>
                );
            })}
        </div>
    );

    return (
        <Media
            data={{
                files: {
                    images: { '0': [item.url_to_frame] },
                },
            }}
            isOpen={isOpen}
            onClose={onClose}
            renderLabels={() => renderItems(labels)}
        />
    );
};

export default MediaModal;
