import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiUploadMethods = {
    uploadFileToStorage: async (props) => {
        const { file, service } = props;
        const formData = new FormData();
        formData.append('doc', file);
        if (service) {
            formData.append('service', service);
        }
        const response = await instance.post(apiUrls.uploadDocumentToStorage(),
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
        return response.data;
    },
    createComplexByUploadingFile: async (props) => {
        const { file } = props;

        const read = (file) => new Promise((res, rej) => {
            const reader = new FileReader();
            reader.onload = (event) => res(event.target.result);
            reader.onerror = rej;
            reader.readAsText(file);
        });
        const result = await read(file);

        const response = await instance.post(apiUrls.createComplex(),
            result,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
        return response.data;
    },
    uploadVehicleFile: async (props) => {
        const { file, overwrite = 0 } = props;
        const formData = new FormData();
        formData.append('xls', file);
        formData.append('overwrite', overwrite);

        const response = await instance.post(apiUrls.uploadVehicle(),
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
        return response;
    },

    uploadRWFile: async ({ file, overwrite }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('overwrite', overwrite);

        const response = await instance.post(
            apiUrls.uploadRoadWork(),
            formData,
            { 
                headers: { 'Content-Type': 'multipart/form-data' },
            }
        );
        return response;
    },

    uploadTransportIncidentFile: async (props) => {
        const { file, overwrite = 0 } = props;
        const formData = new FormData();
        formData.append('xlsx', file);
        formData.append('overwrite', overwrite);

        const response = await instance.post(apiUrls.uploadTransportIncident(),
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
        return response;
    },

    uploadCSODDFile: async (data) => {
        const response = await instance.post(apiUrls.uploadCSODD(), data);
        return response.data;
    },

    getCSODDFile: async () => {
        const response = await instance.get(apiUrls.getCSODDFile());
        return response.data;
    },

    deleteCSODDFile: async (id) => {
        const response = await instance.delete(apiUrls.deleteCSODDFile(id));
        return response.data;
    },

    getRoadLabTypes: async () => {
        const response = await instance.get(apiUrls.getRoadLabTypes());
        return response.data;
    },

    uploadRoadLabTypes: async ({ file, data }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('lab', data.lab);
        formData.append('type', data.type);

        const response = await instance.post(apiUrls.uploadRoadLab(),
            formData,
            { 
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        );
        return response.data;
    },
};

export default ApiUploadMethods;
