import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { showMessage } from 'redux/Message/actions';
import messageTypes from 'redux/Message/messagetypes';
import { setMapLoading } from 'redux/Map/actions';
import messages from 'helpers/constants/messages';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

function* loadBoardStatusesSaga() {
    const response = yield call(api.getBoardStatus);
    if (response?.success) {
        yield put(actions.boardStatusesLoaded(response.data));
    }
}

function* loadBoardTypesSaga() {
    const response = yield call(api.getBoardTypes);
    if (response?.success) {
        yield put(actions.boardTypesLoaded(response.data));
    }
}

function* loadBoardsSaga({ payload, callback }) {
    yield put(actions.loading(true));
    const response = yield call(api.getBoards, payload);
    if (response) {
        callback
            ? yield callback(response.data)
            : yield put(actions.boardsLoaded(response));
    }
    yield put(actions.loading(false));
}

function* loadBoardBeforeSaga({ payload }) {
    yield put(actions.loadingBoardBefore(true));
    const response = yield call(api.getBoards, { ids: [payload], page: 1, limit: 1 });
    if (response) {
        yield put(actions.loadedBoardBefore(response));
    }
    yield put(actions.loadingBoardBefore(false));
}

function* createBoardSaga({ payload, callback }) {
    const response = yield call(api.createBoard, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
        yield callback?.();
    }
    yield put(actions.setSaved(response?.success || false));
}

function* editBoardSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editBoard, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
        yield callback?.();
    }
    yield put(actions.setSaved(response?.success || false));
}

function* deleteBoardSaga({ payload, callback }) {
    const response = yield call(api.deleteBoard, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
        yield callback?.();
    }
}

function* loadMessagePrioritiesSaga() {
    const response = yield call(api.getMessagePriorities);
    if (response?.success) {
        yield put(actions.loadedMessagePriorities(response.data));
    }
}

function* loadMessagesSaga({ payload }) {
    yield put(actions.loadingMessages(true));
    const response = yield call(api.getMessages, payload);
    if (response) {
        yield put(actions.loadedMessages(response));
    }
    yield put(actions.loadingMessages(false));
}

function* createMessageSaga({ payload, callback }) {
    const response = yield call(api.createMessage, payload);
    if (response?.success) {
        callback && callback();
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
        // yield put(actions.loadMessages({ page: 1, limit: 25, dit_ids: [payload.board_id] }));
        // yield put(setValidation({ success: response.success }));
    }
}

function* editMessageSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editMessage, id, data);
    if (response?.success) {
        callback && callback();
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
        // yield put(actions.loadMessages({ page: 1, limit: 25, dit_ids: [data?.board_id] }));
        // yield put(setValidation({ success: response.success }));
    }
}

function* deleteMessageSaga({ payload }) {
    const { id, dit_ids } = payload;
    const response = yield call(api.deleteMessage, id);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.DELETE_SUCCESS);
        yield put(actions.loadMessages({ page: 1, limit: 25, dit_ids }));
    }
}

function* loadBoardColorsSaga() {
    const response = yield call(api.getBoardColors);
    if (response?.success) {
        yield put(actions.loadedBoardColors(response.data));
    }
    yield put(actions.setSaved(response?.success || false));
}

function* getPolygonBoardSaga({ payload }) {
    const { polygon, params } = payload;
    yield put(setMapLoading('boards', true));
    yield put(actions.setPolygonLoading(true));
    const response = yield call(api.getForPolygon, polygon, params);
    if (response) {
        yield put(actions.saveForPolygon(response));
    }
    yield put(actions.setPolygonLoading(false));
    yield put(setMapLoading('boards', false));
}

function* loadSidebarBoardSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingSidebar(true));
    const stationsList = yield call(api.getSideBar, page, limit, params);
    if (stationsList) {
        yield put(actions.loadedSidebar(stationsList));
    }
    yield put(actions.loadingSidebar(false));
}

function* fetchMessagePreviewSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.fetchMessagePreview, id, data);
    if (response?.success) {
        yield put(actions.fetchedMessagePreview(response));
    }
}

function* fetchMessageJSONPreviewSaga({ payload }) {
    const { id, data } = payload;
    yield put(actions.fetchingMessageJSONPreview(true));
    const response = yield call(api.fetchMessageJSONPreview, id, data);
    if (response?.success) {
        yield put(actions.fetchedMessageJSONPreview(response.data));
    }
    yield put(actions.fetchingMessageJSONPreview(false));
}

function* fetchActiveBoardMessageSaga({ payload }) {
    const { id } = payload;
    yield put(actions.fetchingActiveBoardMessage(true));
    const response = yield call(api.fetchActiveBoardMessage, id);
    if (response?.success) {
        yield put(actions.fetchedActiveBoardMessage(response));
    }
    yield put(actions.fetchingActiveBoardMessage(false));
}

function* loadFontsListSaga({ payload }) {
    const response = yield call(api.getFontsList, payload);
    if (response?.success) {
        yield put(actions.loadedFontsList(response?.data));
    }
}

function* loadFontsListForTypeWithImgSaga() {
    const response = yield call(api.getFontsListForTypeWithImg);
    if (response?.success) {
        yield put(actions.loadedFontsListForTypeWithImg(response?.data));
    }
}

function* loadSignsListSaga() {
    const response = yield call(api.getSignsList);
    if (response?.success) {
        yield put(actions.loadedSignsList(response?.data));
    }
}

function* loadBoardRotationMessagesSaga({ payload }) {
    yield put(actions.loadingBoardRotationMessages(true));
    const response = yield call(api.getBoardRotationMessages, payload);
    if (response?.success) {
        yield put(actions.loadedBoardRotationMessages({ [payload]: response.data }));
    }
    yield put(actions.loadingBoardRotationMessages(false));
}

function* loadDitLinkSaga({ payload, callback }) {
    const { params, microservice } = payload;
    yield put(actions.loadingDitLink(true));
    const response = yield call(api.getDitLink, params, microservice);
    if (response.success) {
        yield put(actions.loadedDitLink(response.data));
        callback?.();
    }
    yield put(actions.loadingDitLink(false));
}

function* createDitLinkSaga({ payload, callback, microservice }) {
    yield put(actions.loadingDitLink(true));
    const response = yield call(api.createDitLink, payload, microservice);
    if (response?.success) {
        if (microservice !== 'stagesOfRoadWorks') yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
        yield callback();
    }
    yield put(actions.loadingDitLink(false));
}


function* loadBoardsFromLinkSaga({ payload, callback }) {
    yield put(actions.loadingBoardsFromLinks(true));
    const response = yield call(api.getBoards, payload);
    if (response.success) {
        yield put(actions.loadedBoardsFromLinks(response.data));
        callback?.();
    }
    yield put(actions.loadingBoardsFromLinks(false));
}

function* loadMessageTemplatesSaga({ payload, callback }) {
    yield put(actions.loadingMessageTemplates(true));
    const response = yield call(api.getMessageTemplates, payload);
    if (response) {
        callback
            ? yield callback(response.data)
            : yield put(actions.loadedMessageTemplates(response));
    }
    yield put(actions.loadingMessageTemplates(false));
}

function* createMessageTemplateSaga({ payload, callback }) {
    const response = yield call(api.createMessageTemplate, payload);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
    }
}

function* editMessageTemplateSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editMessageTemplate, id, data);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
}

function* deleteMessageTemplateSaga({ payload, callback }) {
    const response = yield call(api.deleteMessageTemplate, payload);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, messages.DELETE_SUCCESS);
    }
}

function* createMessageTemplateBoardsSaga({ payload, callback }) {
    const response = yield call(api.createMessageTemplateBoards, payload);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
    }
}


function* loadListTemplateImageSaga({ payload, callback }) {
    yield put(actions.loadingListTemplateImage(true));
    const response = yield call(api.listTemplateImage, payload);
    if (response?.success) {
        yield put(actions.loadedListTemplateImage(response));
        callback && callback(response.data);
        yield put(actions.loadedSigns(response.data)); // обновляем список хранимых в сторе знаков, чтобы не загружать лишний раз
    }
    yield put(actions.loadingListTemplateImage(false));
}

function* createTemplateImageSaga({ payload, callback }) {
    yield put(actions.loadingTemplateImage(true));
    const response = yield call(api.createTemplateImage, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
    }
    yield put(actions.loadingTemplateImage(false));
}

function* editTemplateImageSaga({ payload, callback }) {
    yield put(actions.loadingTemplateImage(true));
    const response = yield call(api.editTemplateImage, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
    }
    yield put(actions.loadingTemplateImage(false));
}

function* deleteTemplateImageSaga({ payload, callback }) {
    yield put(actions.loadingTemplateImage(true));
    const response = yield call(api.deleteTemplateImage, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, messages.DELETE_SUCCESS);
    }
    yield put(actions.loadingTemplateImage(false));
}

// шаблоны уведомлений
function* loadAlertTemplateFiledsSaga() {
    const response = yield call(api.getAlertTemplatesFields);
    if (response?.success) {
        yield put(actions.alertTemplateFiledsLoaded(response.data));
    }
}

function* loadAlertTemplateStatusesSaga() {
    const response = yield call(api.getAlertTemplatesStatuses);
    if (response?.success) {
        yield put(actions.alertTemplateStatusesLoaded(response.data));
    }
}
function* loadAlertTemplateTypesSaga() {
    const response = yield call(api.getAlertTemplatesTypes);
    if (response?.success) {
        yield put(actions.alertTemplateTypesLoaded(response.data));
    }
}
function* loadAlertTemplatesSaga({ payload }) {
    yield put(actions.loadingAlertTemplates(true));
    const response = yield call(api.getAlertTemplates, payload);
    if (response) {
        yield put(actions.alertTemplatesLoaded(response));
    }
    yield put(actions.loadingAlertTemplates(false));
}

function* createAlertTemplateSaga({ payload, callback }) {
    const response = yield call(api.createAlertTemplate, payload);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
    }
}

function* editAlertTemplateSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editAlertTemplate, id, data);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
}

function* deleteAlertTemplateSaga({ payload, callback }) {
    const response = yield call(api.deleteAlertTemplate, payload);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, messages.DELETE_SUCCESS);
    }
}

function* loadSignsSaga({ payload, callback }) {
    yield put(actions.loadingSigns(true));
    const response = yield call(api.listTemplateImage, payload);
    if (response?.success) {
        yield put(actions.loadedSigns(response.data));
        callback && callback(response.data);
    }
    yield put(actions.loadingSigns(false));
}

function* loadAlignHorizontalSaga() {
    const response = yield call(api.getAlignHorizontal);
    if (response?.success) {
        yield put(actions.loadedAlignHorizontal(response.data));
    }
}

function* loadAlignVerticalSaga() {
    const response = yield call(api.getAlignVertical);
    if (response?.success) {
        yield put(actions.loadedAlignVertical(response.data));
    }
}

function* loadShowcaseSaga() {
    yield put(actions.loadingShowcase(true));
    const response = yield call(api.loadShowcase);
    if (response?.success) {
        yield put(actions.loadedShowcase(response.data));
    }
    yield put(actions.loadingShowcase(false));
}

function* loadCategoriesSaga() {
    const response = yield call(api.getBoardCategories);
    if (response?.success) {
        yield put(actions.loadedCategories(response.data));
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_BOARD_STATUSES, loadBoardStatusesSaga),
        takeLatest(types.LOAD_BOARD_TYPES, loadBoardTypesSaga),
        takeLatest(types.LOAD_BOARDS, loadBoardsSaga),
        takeLatest(types.CREATE_BOARD, createBoardSaga),
        takeLatest(types.EDIT_BOARD, editBoardSaga),
        takeLatest(types.DELETE_BOARD, deleteBoardSaga),
        takeLatest(types.LOAD_MESSAGE_PRIORITIES, loadMessagePrioritiesSaga),
        takeLatest(types.LOAD_MESSAGES, loadMessagesSaga),
        takeLatest(types.CREATE_MESSAGE, createMessageSaga),
        takeLatest(types.EDIT_MESSAGE, editMessageSaga),
        takeLatest(types.DELETE_MESSAGE, deleteMessageSaga),
        takeLatest(types.LOAD_BOARD_COLORS, loadBoardColorsSaga),
        takeLatest(types.GET_POLYGON, getPolygonBoardSaga),
        takeLatest(types.LOAD_SIDEBAR, loadSidebarBoardSaga),
        takeLatest(types.FETCH_PREVIEW, fetchMessagePreviewSaga),
        takeLatest(types.FETCH_MESSAGE_JSON_PREVIEW, fetchMessageJSONPreviewSaga),
        takeLatest(types.FETCH_ACTIVE_BOARD_MESSAGE, fetchActiveBoardMessageSaga),
        takeLatest(types.LOAD_FONTS_LIST, loadFontsListSaga),
        takeLatest(types.LOAD_FONTS_LIST_FOR_TYPE_WITH_IMG, loadFontsListForTypeWithImgSaga),
        takeLatest(types.LOAD_SIGNS_LIST, loadSignsListSaga),
        takeLatest(types.LOAD_BOARD_ROTATION_MESSAGES, loadBoardRotationMessagesSaga),
        takeLatest(types.LOAD_DIT_LINK, loadDitLinkSaga),
        takeLatest(types.LOAD_BOARDS_FROM_LINK, loadBoardsFromLinkSaga),
        takeLatest(types.CREATE_DIT_LINK, createDitLinkSaga),
        takeLatest(types.LOAD_MESSAGE_TEMPLATES, loadMessageTemplatesSaga),
        takeLatest(types.CREATE_MESSAGE_TEMPLATE, createMessageTemplateSaga),
        takeLatest(types.EDIT_MESSAGE_TEMPLATE, editMessageTemplateSaga),
        takeLatest(types.DELETE_MESSAGE_TEMPLATE, deleteMessageTemplateSaga),
        takeLatest(types.CREATE_MESSAGE_TEMPLATE_BOARDS, createMessageTemplateBoardsSaga),

        takeLatest(types.LOAD_LIST_TEMPLATE_IMAGE, loadListTemplateImageSaga),
        takeLatest(types.CREATE_TEMPLATE_IMAGE, createTemplateImageSaga),
        takeLatest(types.EDIT_TEMPLATE_IMAGE, editTemplateImageSaga),
        takeLatest(types.DELETE_TEMPLATE_IMAGE, deleteTemplateImageSaga),

        takeLatest(types.LOAD_ALERT_TEMPLATE_FILEDS, loadAlertTemplateFiledsSaga),
        takeLatest(types.LOAD_ALERT_TEMPLATE_STATUSES, loadAlertTemplateStatusesSaga),
        takeLatest(types.LOAD_ALERT_TEMPLATE_TYPES, loadAlertTemplateTypesSaga),
        takeLatest(types.LOAD_ALERT_TEMPLATES, loadAlertTemplatesSaga),
        takeLatest(types.CREATE_ALERT_TEMPLATE, createAlertTemplateSaga),
        takeLatest(types.EDIT_ALERT_TEMPLATE, editAlertTemplateSaga),
        takeLatest(types.DELETE_ALERT_TEMPLATE, deleteAlertTemplateSaga),

        takeLatest(types.LOAD_SIGNS, loadSignsSaga),
        takeLatest(types.LOAD_ALIGN_HORIZONTAL, loadAlignHorizontalSaga),
        takeLatest(types.LOAD_ALIGN_VERTICAL, loadAlignVerticalSaga),

        takeLatest(types.LOAD_BOARD_BEFORE, loadBoardBeforeSaga),
        takeLatest(types.LOAD_SHOWCASE, loadShowcaseSaga),

        takeLatest(types.LOAD_CATEGORIES, loadCategoriesSaga),
    ]);
}
