import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as transportPassengerActions from 'redux/TransportPassenger/actions';
import * as transportRoadActions from 'redux/TransportRoad/actions';
import messages from 'helpers/constants/messages';
import Context from 'helpers/context';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';

import Item from './Item';
import ModalAddEdit from './ModalAddEdit';
import Filter from './Filter';

const Positions = ({ storeName }) => {
    const dispatch = useDispatch();

    const actionList = {
        transportPassenger: transportPassengerActions,
        transportRoad: transportRoadActions
    };
    const actions = actionList[storeName];

    const { permissions } = useContext(Context);
    const [modalOpen, setModalOpen] = useState(false);
    const {
        positions,
        positionsLoading: loading
    } = useSelector((store) => store[storeName]);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, filter: {} });

    const reloadList = (isDelete) => {
        isDelete && positions?.data?.length === 1 && positions?.meta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(actions.loadPositions(params.page, limit, params.filter));
    };

    useEffect(() => {
        dispatch(actions.loadPositions(params.page, limit, params.filter));
    }, [dispatch, limit, params, actions]);

    const renderContent = () => {
        return (
            positions?.data?.length > 0
                ? (<LSContainer headers={[
                    { title: 'Должность', },
                    { isActions: true },
                ]}>
                    {positions.data?.map(item =>
                        <Item
                            key={item?.id}
                            item={item}
                            reloadList={reloadList}
                            actions={actions}
                            loading={loading}
                            permissions={permissions}
                            storeName={storeName}
                        />
                    )}
                </LSContainer>)
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <>
            <PageLayout
                header="Список должностей"
                filters={<Filter onFilterChanged={(filter) => setParams({ page: 1, filter })}/>}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setModalOpen(true),
                                    disabled: !permissions?.is_create
                                },
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: positions?.meta?.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams(prev => ({ ...prev, page })),
                    list: positions?.meta,
                    limit,
                    setLimit
                }}
            />

            {modalOpen
                && <ModalAddEdit
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    isNew
                    reloadList={reloadList}
                    actions={actions}
                    loading={loading}
                    storeName={storeName}
                />
            }
        </>
    );
};

export default Positions;
