import { useSelector } from 'react-redux';

import {
    loadCameraViolations,
} from 'redux/VideoAnalytics/actions';
import { videoAnalyticsSelectors } from 'redux/VideoAnalytics';
import { fullDateTime } from 'helpers/date.config';
import useStoreProp from 'helpers/hooks/useStoreProp';
import messages from 'helpers/constants/messages';

import './style.scss';

const Events = ({ list = [] }) => {
    // подгружаем данные
    const violations = useStoreProp(loadCameraViolations, 'videoAnalytics', 'violations');
    const excludedKeys = useSelector(videoAnalyticsSelectors.excludedKeysForEventsLabels);

    const getCellValue = (key, item) => {
        const label = labels[key];
        const val = item[key];
        if (label.value) {
            return label.value(val);
        }
        return val || '';
    };

    const labels = {
        created_at: {
            title: 'Въезд в зону фиксации',
            value: (value) => fullDateTime(value)
        },
        stop_at: {
            title: 'Выезд из зоны фиксации',
            value: (value) => fullDateTime(value)
        },
        vehicle_direction_stop: {
            title: 'Направление движения'
        },
        vehicle_speed: {
            title: 'Скорость',
            value: (value) => typeof value === 'number' ? `${value} км/ч` : messages.NO_DATA
        },
        line: {
            title: 'Полоса движения',
            value: (value) => value ?? <span>{messages.NO_DATA}</span>
        },
        grz: {
            title: 'ГРЗ',
            value: (value) => value || <span>Не распознан</span>
        },
        class_name_ru: {
            title: 'Класс ТС',
            value: (value) => value || messages.NO_DATA
        },
        vehicle_brand: {
            title: 'Марка'
        },
        vehicle_model: {
            title: 'Модель'
        },
        vehicle_color: {
            title: 'Цвет'
        },
        violation_type: {
            title: 'Тип события',
            value: (value) =>violations[value] ||  messages.NO_DATA,
            // TODO уточнить соответствие - violation_type_text "нет нарушений", violations[0] - "Проезды"
        }
    };

    const filteredKeys = Object.keys(labels).filter(key => !excludedKeys.includes(key));

    return (
        <table className="vaa-table">
            <tbody>
                <tr>
                    {filteredKeys.map((key) => {
                        const { title } = labels[key];
                        return (
                            <th key={`title_${key}`}>{title}</th>
                        );
                    })}
                </tr>

                {list.data.map((item, index) => (
                    <tr key={`tr_${item.uuid}_${index}`}>
                        {filteredKeys.map((key) => (
                            <td key={`${item.uuid}_${key}_${index}`}>
                                {getCellValue(key, item)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Events;
