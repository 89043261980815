import { useCallback, useState } from 'react';
import PageLayout from '../../../../layout/PageLayout';
import { Filters, Reports } from './';
import { FilterState } from './types';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../redux/VideoAnalytics/actions';
import { 
    fullDateTimeWithTimeZone,
    getStartOf,
    getEndOf, 
} from '../../../../../helpers/date.config';
import { videoAnalyticsSelectors } from '../../../../../redux/VideoAnalytics';

const EntryExitStatistics = () => {
    const dispatch = useDispatch();
    const loading = useSelector<any, Boolean>(videoAnalyticsSelectors.inOutCamerasReportLoading);

    const initialState: FilterState = {
        cameras_in: [],
        cameras_out: [],
        start_date: getStartOf('day'),
        end_date: getEndOf('day'),
    };
    const [params, setParams] = useState(initialState);

    const resetFilters = () => {
        setParams(initialState);
        dispatch(actions.clearInOutCamerasReport());
    };

    const search = (filter: FilterState = params) => {
        const preparedFilter = {
            cameras_in: filter.cameras_in.map(({ id }) => id),
            cameras_out: filter.cameras_out.map(({ id }) => id),
            start_date: fullDateTimeWithTimeZone(filter.start_date),
            end_date: fullDateTimeWithTimeZone(filter.end_date)
        };

        dispatch(actions.loadInOutCamerasReport(preparedFilter));
    };

    const updateFilter = (filters: FilterState) => {
        if (Object.keys(filters).length > 0) {
            setParams((prev) => ({
                ...prev,
                ...filters
            }));
            search({
                ...params,
                ...filters
            });
        }
    };

    return (
        <PageLayout
            header="Статистика въезда-выезда"
            filters={
                <Filters 
                    filter={params}
                    setFilter={setParams} 
                    onSearch={search}
                    onReset={resetFilters}
                    onUpdate={updateFilter}
                />
            }
            loading={!!loading}
            content={Reports}
        />
    );
};

export default EntryExitStatistics;