import { TextField } from '@mui/material';
import { useState } from 'react';
import SelectTrafficSchedule from '../../../../common/Autocomplete/PassengerTransport/TrafficSchedule';
import DateRange from '../../../../common/Dates/DateRange';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';

const Filters = ({ params, onSearch, initialState }) => {
    const [isDateError, setIsDateError] = useState(false);

    const [filterValues, setFilterValues] = useState(params?.data);

    const resetFilters = (needRefresh) => {
        if (needRefresh) {
            onSearch(initialState?.data);
        }
        setFilterValues(initialState?.data);
    };

    const handleChange = (value, name) => {
        setFilterValues({ ...filterValues, [name]: value });
    };

    const updateFilter = (filters) => {
        setFilterValues({ // обновляем локальный стейт
            ...initialState?.data,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            onSearch({ // отправляем в родителя, чтобы произошел запрос
                ...initialState,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilters} 
            onSearch={() => onSearch(filterValues)} 
            filter={filterValues} 
            setUserFilter={updateFilter}
            disabled={isDateError}
            filterException={['start_date_at', 'end_date_at',]}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filterValues.start_date_at}
                    valueEndDate={filterValues.end_date_at}
                    handleDate={(value) => handleChange(value, 'start_date_at')}
                    handleEndDate={(value) => handleChange(value, 'end_date_at')}
                    dateOnly
                    isDisabledHandler={(error) => setIsDateError(error)}
                    equalDates
                />

                <SelectTrafficSchedule
                    selected={filterValues.traffic_schedule_ids}
                    onChange={(value) => handleChange(value, 'traffic_schedule_ids')}
                    params={{ status_ids: [1] }}
                    multiple
                />
                <TextField
                    value={filterValues.id}
                    onChange={({ target : { value, name } }) => handleChange(value, name)}
                    name="id"
                    label="ID наряда"
                    variant="outlined"
                    size="small"
                    type="number"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;
