import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import {
    createdNewVehicle,
    editVehicle,
    loadedBrandsList,
    loadedModelsList,
    loadEquipmentsList,
    loadVehicleCategories,
    loadVehicleClasses
} from 'redux/TransportPassenger/actions';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import removeEmptyFields from 'helpers/removeEmptyFields';
import useCompany from 'helpers/hooks/useCompany';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import MarkModelComponent from 'components/common/Transport/MarkModelComponent';
import SelectCompany from 'components/common/Autocomplete/Companies';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

import CharacteristicsFields from './Characteristics/CharacteristicsFields';

const VehiclesModal = ({
    isNew = false,
    isOpen,
    onClose,
    data,
    reloadList,
    test_id_prefix = '', // для авто тестирования элементов на разных страницах
}) => {
    const dispatch = useDispatch();

    const prefixTest = `${test_id_prefix}:${isNew ? 'add' : 'edit'}`;

    const equipments = useStoreProp(loadEquipmentsList, 'transportPassenger', 'equipments');
    const categories = useStoreProp(loadVehicleCategories, 'transportPassenger', 'vehicleCategories');
    const company = useCompany(data?.organization_id, data?.organization);

    useEffect(() => {
        if (company && Object.keys(company).length > 0) {
            setFormData((prev) => ({
                ...prev,
                organization: company
            }));
        }
    }, [company]);

    const vehicleClasses = useStoreProp(
        loadVehicleClasses,
        'transportPassenger',
        'vehicleClasses'
    );

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const currentCategoryId = isNew ? '' : categories?.find(item => item.id === data?.category_id)?.id;

    const characteristicsToString = () => {
        const characteristics = data?.characteristics || {};
        return Object.keys(characteristics).reduce((res, key) => ({
            ...res,
            [key]: characteristics[key].toString()
        }), {});
    };

    const initialState = {
        number: data?.number || '',
        garage_number: data?.garage_number || '',
        brand: data?.brand || {},
        class_id: data?.class_id || null,
        model_id: data?.model?.id || '',
        category_id: currentCategoryId,
        organization: data?.organization || {},
        organization_id: data?.organization_id || 0,
        characteristics: characteristicsToString(), //data?.characteristics?. || {}
        // egts_receiver_id: data?.egts_receiver_id || 0,
        // egts_receiver: data?.egts_receiver || {}
    };

    const [formData, setFormData] = useState(initialState);

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCloseModal = () => {
        onClose();
        dispatch(loadedBrandsList({
            data: [],
            meta: {
                last_page: 0,
            },
        }));
        dispatch(loadedModelsList({
            data: [],
            meta: {
                last_page: 0,
            },
        }));
    };

    const save = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            organization_id: formData.organization_id,
            organization: null,
            brand: null,
        }, false);

        dispatch(isNew ? createdNewVehicle(prepareData, reloadList) : editVehicle(data.id, prepareData, reloadList));
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
        }
    }, [requestStatus.success, onClose, validation]);

    const requiredFields = [
        'category_id',
        'garage_number',
        'model_id',
        'number',
        // 'egts_receiver_id'
    ];
    const isDisabledSaveButton = Object.entries(formData).reduce((result, [key, value]) => {
        if (requiredFields.includes(key)
            && ((Array.isArray(value) && value.length === 0)
                || (value === null || value === undefined || value === '')
                || (typeof value === 'object' && Object.keys(value).length === 0))) {
            result = true;
        }
        return result;
    }, false);

    return (
        <Modal
            onClose={handleCloseModal}
            isOpen={isOpen}
            title={isNew ? titles.ADD : titles.EDIT }
            maxWidth="md"
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: handleCloseModal,
                        testName: 'close',
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isDisabledSaveButton,
                        onClick: save,
                        testName: 'save',
                    }
                ]}
                test_id_prefix={prefixTest}
            />}
        >
            <div className="modal__form">
                <FormInfoWrapper error={validation.isKey('number')} helperText={validation.get('number')}>
                    <TextField
                        required={requiredFields.includes('number')}
                        placeholder="А123БС 134RUS"
                        className="block"
                        label="Регистрационный номер ТС"
                        variant="outlined"
                        size="small"
                        name="number"
                        value={formData.number}
                        onChange={onChange}
                        error={validation.isKey('number')}
                        inputProps={{
                            'data-testid': `${prefixTest}/number/input`
                        }}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper error={validation.isKey('garage_number')} helperText={validation.get('garage_number')}>
                    <TextField
                        required={requiredFields.includes('garage_number')}
                        className="block"
                        label="Гаражный номер ТС"
                        variant="outlined"
                        size="small"
                        name="garage_number"
                        value={formData.garage_number}
                        onChange={onChange}
                        error={validation.isKey('garage_number')}
                        inputProps={{
                            'data-testid': `${prefixTest}/garage_number/input`
                        }}
                    />
                </FormInfoWrapper>
                <MarkModelComponent
                    required={requiredFields.includes('model_id')}
                    formData={formData}
                    setFormData={setFormData}
                    moduleName="transportPassenger"
                    styleName="block"
                />
                <FormInfoWrapper error={validation.isKey('category')} helperText={validation.get('category')}>
                    <FormControl
                        variant="outlined"
                        size="small"
                        className="block"
                        error={validation.isKey('category')}
                        required={true}
                    >
                        <InputLabel>Вид ТС</InputLabel>
                        <Select
                            value={formData.category_id}
                            name="category_id"
                            onChange={onChange}
                            label="Вид ТС"
                            required={requiredFields.includes('category_id')}
                            inputProps={{
                                'data-testid': `${prefixTest}/category_id/input`
                            }}
                        >
                            {categories?.length > 0
                                ? categories?.map((el) =>
                                    <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                )
                                : <MenuItem value=" ">{messages.NO_DATA}</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </FormInfoWrapper>

                <FormInfoWrapper error={validation.isKey('class')} helperText={validation.get('class')}>
                    <FormControl
                        variant="outlined"
                        size="small"
                        className="block"
                        error={validation.isKey('class')}
                    >
                        <InputLabel>Класс ТС</InputLabel>
                        <Select
                            value={formData.class_id}
                            name="class_id"
                            onChange={onChange}
                            label="Класс ТС"
                            required={requiredFields.includes('class_id')}
                            inputProps={{
                                'data-testid': `${prefixTest}/class_id/input`
                            }}
                        >
                            {vehicleClasses?.length > 0
                                ? vehicleClasses.map(el => <MenuItem key={el?.id} value={el.id}>{el?.name}</MenuItem>)
                                : <MenuItem value=" ">{messages.NO_DATA}</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </FormInfoWrapper>
                <div className="block">
                    <SelectCompany
                        selected={formData.organization}
                        error={validation.isKey('organization_id')}
                        helperText={validation.get('organization_id')}
                        onChange={(company) => setFormData({
                            ...formData,
                            organization: company,
                            organization_id: company.id
                        })}
                        label="Организация"
                        filter={{ withDeleted: 1 }}
                        test_id_prefix={prefixTest}
                    />
                </div>
                {/* <div className="block">
                    <FormInfoWrapper error={validation.isKey('egts_receiver_id')} helperText={validation.get('egts_receiver_id')}>
                        <TelemetryReceiverOrganization
                            multiple={false}
                            selected={formData?.egts_receiver}
                            onChange={(egts_receiver) => setFormData({
                                ...formData,
                                egts_receiver: egts_receiver,
                                egts_receiver_id: egts_receiver.id
                            })}
                            disabled={false}
                            error={validation.isKey('egts_receiver_id')}
                            storeName="transportPassenger"
                            required
                        />
                    </FormInfoWrapper>
                </div> */}
                <h2>Дополнительные характеристики</h2>
                <CharacteristicsFields
                    prefix="characteristics"
                    equipments={equipments}
                    values={formData?.characteristics}
                    onChange={(chars) => setFormData({
                        ...formData,
                        characteristics: {
                            ...formData.characteristics,
                            ...chars
                        }
                    })}
                    test_id_prefix={prefixTest}
                />
            </div>
        </Modal>
    );
};

export default VehiclesModal;
