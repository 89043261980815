import React, { useMemo } from 'react';
import messages from '../../../helpers/constants/messages';

// data - данные
// title - что вывести
const Information = ({
    data,
    title = {},
    wrapperTpl = (key, title, value) => <div className="info__item" key={key} style={{ marginRight: '.5rem' }}>{title}{value}</div>,
    titleTpl = (value) => <b className="info__item-title">{value || ''}{': '}</b>,
    valueTpl = (value) => <span>{value}</span>,
    defaultValue = messages.NO_DATA,
    returnNull = false,
}) => {

    // выводимый список
    const list = useMemo(() => {
        return Object
            .keys(title)
            ?.reduce((res, key) => {
                const titleObject = title?.[key] || {};
                const value = titleObject?.value
                    ? titleObject.value(data)
                    : data?.[key];
                const id = `information_${key}`;

                // проверяем выводить это поле или нет
                if (value || returnNull) {
                    return [
                        ...res,
                        {
                            id,
                            title: titleObject?.title || '',
                            value: value || defaultValue,
                        }
                    ];
                }
                return res;
            }, []);
    }, [data, returnNull]);

    return (
        <>
            {list.length > 0
                ? list?.map(({ id, title, value }) => wrapperTpl(
                    id,
                    titleTpl(title),
                    valueTpl(value)
                ))
                : (
                    <div>{messages.NO_DATA}</div>
                )
            }
        </>
    );
};

export default Information;