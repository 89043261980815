import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useStoreProp } from 'helpers/hooks';
import useTransportCategory, { transportCategoryHelper } from 'helpers/hooks/Transport/useTransportCategory';
import AddEditCopyModal from 'components/pages/Dictionaries/PassengerTransport/Routes/AddEditCopyModal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { loadRoutesTransportations, loadVehicleCategories } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import icons from '../icons/icons';
import RouteData from './RouteData';


const RoutePopUp = (props) => {
    const {
        id: uuid,
        permissions,
        readOnly = false,
        // route,
        // map
    } = props;

    const getTransportCategory = useTransportCategory(
        loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    );

    const types = useSelector(transportPassengerSelectors.types);
    const active = useSelector(transportPassengerSelectors.active);

    const route = active?.find(({ id }) => uuid === id) || {};
    const {
        category_id,
        num
    } = route;
    const currentTransport = types.find(({ id }) => id === parseInt(category_id));

    const {
        color,
        getImageTpl,
    } = transportCategoryHelper(getTransportCategory)
        .getCategoryPassenger(category_id, true, true);

    const typeTransportation = useStoreProp(
        loadRoutesTransportations,
        'transportPassenger',
        'transportations'
    );

    const [modalOpen, setModalOpen] = useState(null);

    const handleCloseModal = () => {
        // делать запрос
        setModalOpen(null);
    };

    return (
        <div className="map-popup passanger-transport">
            <div
                className="map-popup_header"
                style={{ backgroundColor: color }}
            >
                <div>
                    <div className="img">
                        {getImageTpl('img', icons({ fill: '#fff' }))}
                    </div>

                    <span>Маршрут №{num}</span>
                </div>
                <div className="name after-name">
                    <span>
                        {currentTransport?.name || ''}
                    </span>
                </div>
            </div>
            <div className="map-popup_body">
                <RouteData
                    route={route}
                />

                {modalOpen
                    && <AddEditCopyModal
                        data={modalOpen}
                        isNew={false}
                        isOpen={!!modalOpen}
                        onClose={handleCloseModal}
                        typeTransportation={typeTransportation}
                        permissions={permissions}
                    />
                }

                {!readOnly && (
                    <div
                        className="filter__wrap__btn filter__btn__column"
                        style={{
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                        }}
                    >
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.edit,
                                    size: 'small',
                                    disabled: !permissions?.is_update,
                                    onClick: () => {
                                        setModalOpen({ ...route });
                                    }
                                }
                            ]}
                        />
                    </div>
                )}

            </div>
        </div>
    );
};

export default RoutePopUp;
