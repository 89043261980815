import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadTicketPoints } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import Context from 'helpers/context';
import titles from 'helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';

import Item from './Item';
import AddEditModal from './AddEditModal';
import Filters from './Filters';

import type { Ticket } from './types';

function TicketPoints() {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const ticketPointsData: Ticket[] = useSelector(transportPassengerSelectors.ticketPointsData);
    const ticketPointsMeta = useSelector(transportPassengerSelectors.ticketPointsMeta);
    const ticketPointsLoading: boolean = useSelector(transportPassengerSelectors.ticketPointsLoading);

    const [modalOpen, setModalOpen] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = useCallback(() => {
        dispatch(loadTicketPoints(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    return (
        <>
            <PageLayout
                header="Пункты продажи электронных билетов"
                filters={<Filters setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            {permissions?.is_create && (
                                <FormButtonsComponent 
                                    buttons={[
                                        {
                                            ...buttonsTypes.add,
                                            onClick: () => setModalOpen(true),
                                        }
                                    ]}
                                    positionLeft
                                    noPadding
                                />
                            )}
                        </>
                    ),
                    total: ticketPointsMeta?.total
                }}
                loading={ticketPointsLoading}
                content={() => (
                    <>
                        {ticketPointsData?.length > 0
                            ? (<>
                                <LSContainer
                                    headers={[
                                        { title: titles.NAME, width: '12%' },
                                        { title: '№ телефона', width: '15%' },
                                        { title: titles.MODE, width: '15%' },
                                        { title: titles.TYPE, width: '25%' },
                                        { title: titles.ADDRESS, width: '25%' },
                                        { title: 'Действия', align: 'right', isActions: true }
                                    ]}
                                >
                                    {ticketPointsData?.map(item => (
                                        <Item
                                            key={item?.id}
                                            data={item}
                                            reloadList={reloadList}
                                            permissions={permissions}
                                        />
                                    ))}
                                </LSContainer>
                            </>)
                            : !ticketPointsLoading && <NoData/>
                        }
                    </>
                )}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: ticketPointsMeta,
                    limit,
                    setLimit
                }}
            />

            {modalOpen && (
                <AddEditModal
                    isNew={true}
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
}

export default TicketPoints;