import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { Button, FormHelperText, Typography } from '@mui/material';
import cn from 'classnames';

import { companiesSelectors } from 'redux/Companies';
import { loadCurrentCompany } from 'redux/Companies/actions';
import { clearVehicleCard, loadVehicleCard } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import DateRange from 'components/common/Dates/DateRange';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import buttons from 'helpers/constants/buttons';
import { useValidation } from 'helpers/hooks';
import dateFormat from 'helpers/constants/dateFormat';

import RegistationCardInfo from './RegistationCardInfo';

const RegistrationCard = ({
    isOpen,
    onClose,
    item = {},
    categories,
    test_id_prefix = '',
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const currentCompany = useSelector(companiesSelectors.currentCompany);

    const vehicleCard = useSelector(transportPassengerSelectors.vehicleCard);
    const loadingVehicleCard = useSelector(transportPassengerSelectors.loadingVehicleCard);

    const company = currentCompany[item?.organization_id];
    const categoryName = categories.find(el => el?.id === item?.category_id)?.name;
    const initialState = {
        start_date: null,
        end_date: null,
    };
    const [data, setData] = useState(initialState);
    const [isDateError, setIsDateError] = useState(false);
    const [isPrintOpen, setOpenPrint] = useState(false);
    const isDisabled = isDateError || ((data.start_date || data.end_date) && (!data.start_date || !data.end_date));
    const docTitle = 'Регистрационная карточка транспортного средства';

    let componentRef = useRef();

    const getRoutes = () => {
        dispatch(loadVehicleCard(item?.id, data));
    };

    const onChange = (name) => (e) => {
        setData({
            ...data,
            [name]: e ? dateWithDashYYYYMMDD(e) : null
        });
        validation.deleteKey('start_date');
        validation.deleteKey('end_date');
    };

    const closeForm = () => {
        dispatch(clearVehicleCard());
        onClose();
    };

    const getReport = () => {
        dispatch(loadVehicleCard( item?.id, { ...data, report: 'XLS' }));
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef,
        pageStyle: `
            @media print {
                .pagebreak {
                    page-break-before: auto;
                }
            }
        `,
        onAfterPrint: () => setOpenPrint(false)
    });

    useEffect(() => {
        if (!currentCompany[item?.organization_id]) {
            dispatch(loadCurrentCompany(item?.organization_id));
        }
    }, [currentCompany, item?.organization_id]);

    useEffect(() => {
        if (isPrintOpen) {
            handlePrint();
        }
    }, [isPrintOpen]);

    const renderPrintTable = () => {
        return (<div ref={(el) => (componentRef = el)} style={{ padding: '20px' }}>
            <Typography style={{ fontSize: '18px', marginBottom: '10px' }}>
                <b>{docTitle}</b>
            </Typography>
            <RegistationCardInfo
                item={item}
                categoryName={categoryName}
                company={company}
                dates={vehicleCard?.route_dates}
                loading={loadingVehicleCard}
            />
        </div>);
    };

    return (
        <Modal
            title={docTitle}
            onClose={onClose}
            noPadding
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: closeForm,
                        testName: 'close',
                    }
                ]}
                beforeTemplate={
                    <>
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.defaultPrimary,
                                    name: buttons.PRINT,
                                    onClick: () => setOpenPrint(true),
                                    size: 'small',
                                    testName: 'print',
                                },
                                {
                                    ...buttonsTypes.defaultPrimary,
                                    name: 'ВЫГРУЗКА XLS',
                                    onClick: getReport,
                                    size: 'small',
                                    testName: 'load-xls',
                                },
                            ]}
                            test_id_prefix={test_id_prefix}
                        />
                    </>
                }
                test_id_prefix={test_id_prefix}
            />}
        >
            <div className="modal__form">
                <div className="filter">
                    <div className="filter__wrap filter__wrap__more__field">
                        <DateRange
                            valueStartDate={data.start_date}
                            valueEndDate={data.end_date}
                            handleDate={onChange('start_date')}
                            handleEndDate={onChange('end_date')}
                            isDisabledHandler={(error) => setIsDateError(error)}
                            required={data.start_date || data.end_date}
                            pickersFormat={dateFormat.DATE_FOR_PICKER}
                            dateOnly={true}
                            disableFuture={false}
                            test_id_prefix={test_id_prefix}
                        />
                    </div>
                    {validation.isKey('start_date') && (
                        <FormHelperText error>{validation.get('start_date')}</FormHelperText>
                    )}
                    {validation.isKey('end_date') && (
                        <FormHelperText error>{validation.get('end_date')}</FormHelperText>
                    )}
                    <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.showRoutes,
                                    disabled: isDisabled,
                                    onClick: getRoutes,
                                    testName: 'show-routes',
                                }
                            ]}
                            test_id_prefix={test_id_prefix}
                        />
                    </div>
                </div>
                <RegistationCardInfo
                    item={item}
                    categoryName={categoryName}
                    company={company}
                    dates={vehicleCard?.route_dates}
                    loading={loadingVehicleCard}
                />
                <div style={{ display: 'none' }}>
                    {renderPrintTable()}
                </div>
            </div>
        </Modal>
    );
};

export default RegistrationCard;
