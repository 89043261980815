import { unmountComponentAtNode } from 'react-dom';
// import refs from '../refs';


const handleCloseContextMenu = (newId, contextMenuRef) => () => {
    const current = document.getElementById(newId);
    if (current) {
        // clickEventData = null;
        current && unmountComponentAtNode(current);
        // contextPRef.current && contextPRef.current.remove();
        // if (refs.is('popup')) {
        //     refs.get('popup').remove();
        //     refs.delete('popup');
        // }
    }
    if (contextMenuRef?.current) {
        contextMenuRef.current.remove();
        contextMenuRef.current = null;
    }
};

export default handleCloseContextMenu;