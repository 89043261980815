import React from 'react';
import { useSelector } from 'react-redux';
import Graph from './Graph';
import TableData from './TableData';
import messages from '../../../../../helpers/constants/messages/common';

const RenderDTPReport = ({ is_graph, loading }) => { 
    const dtpGraph = useSelector(({ incidents }) => incidents?.dtpGraph);
    const dtpReportByArea = useSelector(({ incidents }) => incidents?.dtpReportByArea);

    return (
        <>
            {dtpGraph.length !== 0 && is_graph && <Graph datas={dtpGraph} />}
            {dtpReportByArea.length !== 0 && !is_graph && <TableData />}
            {!loading && (is_graph ?  dtpGraph.length === 0 : dtpReportByArea.length === 0)
                && <div>{messages.NO_DATA_FOR_PERIOD}</div>
            }
        </>
    );
};

export default RenderDTPReport;
