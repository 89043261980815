import { useEffect, useMemo, useState } from 'react';
import {
    CircleMarker,
    GeoJsonNew,
    ToolTip,
} from '../../../../MapComponents/leaflet';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../redux/TransportRoad/actions';
import { transportRoadSelectors } from '../../../../../redux/TransportRoad';


const Stations = (props) => {
    const {
        id,
        ...params
    } = props;
    const dispatch = useDispatch();
    const routeCheckpoints = useSelector(transportRoadSelectors.routeCheckpoints);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (id) {
            if (routeCheckpoints[id]) {
                setData(routeCheckpoints[id]);
            } else {
                dispatch(actions.loadRouteCheckpoints(id));
            }
        }
    }, [dispatch, id, routeCheckpoints]);

    const currentStations = useMemo(() => data.map(station => station.check_point), [data]);

    const areas = useMemo(() => 
        currentStations
            ?.filter(({ geometry }) => geometry)
            ?.map(({ geometry }) => geometry)
    , [currentStations]);

    return (
        <>
            {areas && (
                <GeoJsonNew
                    {...props}
                    data={areas}
                    style={{
                        color: '#02A600'
                    }}
                />
            )}

            {currentStations.map((item) => {
                const {
                    lat,
                    lon,
                    id,
                    name,
                } = item || {};

                return (
                    <CircleMarker
                        {...params}
                        key={`circlemarker_${id}`}
                        latlng={[lat, lon]}
                        radius={12}
                        weight={1}
                        color={'#00442C'}
                        fillColor={'#02A600'}
                        fillOpacity={1}
                    >
                        <CircleMarker
                            latlng={[lat, lon]}
                            radius={6}
                            weight={1}
                            color={'#00442C'}
                            fillColor="#fff"
                            fillOpacity={1}
                        >
                            <ToolTip
                                offset={[0, -5]}
                                direction="top"
                            >
                                <div>Остановка: {name}</div>
                            </ToolTip>
                        </CircleMarker>
                    </CircleMarker>
                );
            })}
        </>
    );
};

export default Stations;
