import { useState } from 'react';
import { useDispatch } from 'react-redux';

import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import ItemInfo from './ItemInfo';
import ModalAddEdit from './ModalAddEdit';

const Item = ({
    item,
    reloadList,
    loading,
    actions,
    storeName
}) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const onDelete = () => {
        dispatch(actions.deleteEmployees(item?.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    const openItemInfo = () => {
        setIsOpenInfo(!isOpenInfo);
    };

    const handleEdit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenEditModal(true);
    };

    const handleDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpenDeleteModal(true);
    };

    return (
        <>
            <LSContentItem onClick={openItemInfo}>
                <LSContentColumn>{item?.last_name || ''} </LSContentColumn>
                <LSContentColumn>{item?.first_name || ''}</LSContentColumn>
                <LSContentColumn>{item?.patronymic || ''}</LSContentColumn>
                <LSContentColumn>{item?.identification || ''}</LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: handleEdit,
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: handleDelete,
                            }
                        ]}
                    />
                    <ActionMore
                        isOpen={isOpenInfo}
                        onClick={openItemInfo}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isOpenInfo && <ItemInfo item={item} isOpen={isOpenInfo} />}

            {openEditModal && (
                <ModalAddEdit
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                    loading={loading}
                    storeName={storeName}
                    actions={actions}
                />
            )}

            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onSuccess={onDelete}
                />
            )}

        </>
    );
};

export default Item;
