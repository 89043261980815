import { boardsSelectors } from 'redux/Boards';
import { loadBoardStatuses } from 'redux/Boards/actions';
import colorExtend from 'helpers/mapHelper/colorExtend';
import { useStoreFromSelector } from 'helpers/hooks';

import config from './config';


const LegendsList = () => {

    // список статусов
    const statuses = useStoreFromSelector(loadBoardStatuses, boardsSelectors.boardStatuses);

    return (
        <div className="sidebar-tp-legend">
            <div className="item">
                <div className="title">
                    Статусы
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    {statuses.map((status) => (
                        <div
                            key={status.id}
                            className="flex"
                            style={{
                                width: '200px',
                                alignItems: 'start',
                            }}
                        >
                            <div
                                className="image"
                                style={{
                                    width: '15px',
                                    height: '15px',
                                    backgroundColor: colorExtend(status.color || '')
                                }}
                            />

                            <div className="small">
                                - {status.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LegendsList;
