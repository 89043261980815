import _ from 'lodash';
class Config  {
    project = null;
    configuration = null;
    public_url = process.env.PUBLIC_URL;
    cookieOptions = {
        path: '/',
        domain: process.env.PUBLIC_URL
    };

    setConfig(config) {
        if (this.configuration === null) {
            this.configuration = config;
        } else {
            throw new Error('Конфигурация должна быть инииализирована только один раз');
        }
    }

    getCookieOptions() {
        return this.cookieOptions;
    }

    get(paramPath) {
        if (paramPath === undefined) {
            paramPath = null;
        }

        if (this.project === null) {
            throw new Error('Проект не выбран');
        }
        return paramPath === null
            ? this.configuration[this.project.code]
            : _.get(this.configuration[this.project.code], paramPath);
    }

    getGeneral(paramPath) {
        return _.get(this.configuration?.general || {}, paramPath);
    }

    setProject(project) {
        this.project = project;
    }

    getMicroservicePath(microservice) {
        const api = {
            'ecology': this.get('urls').apiEcologyUrl,
            'meteo': this.get('urls').apiMeteoUrl,
            'dorisControl': this.get('urls').apiDorisControl,
            'stagesOfRoadWorks': this.get('urls').apiRwUrl,
            'videoAnalytics': this.get('urls').apiVideoAnaliticUrl,
        };

        return api[microservice];
    }
    getTimeZone() {
        return this.project?.timezoneTitle;
    }
}

export const config = new Config();
