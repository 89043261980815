import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { loadDefects, loadDefectStatuses } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import { loadRoadLabTypes, uploadRoadLab } from 'redux/Upload/actions';
import messages from 'helpers/constants/messages';
import { useStoreProp, useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import FileUploader from 'components/common/Upload/FileUploader';
import ModalForm from 'components/MapComponents/Layers/Defects/ModalForm';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import ButtonWithPopover from 'components/common/Buttons/ButtonWithPopover';
import UploadModal from 'components/pages/Dictionaries/RoadWorks/Sites/UploadModal';

import Filter from './Filter';
import Item from './Item';


const Defects = () => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const location = useLocation();

    const defectsData = useSelector(roadworksSelectors.defectsData);
    const defectsMeta = useSelector(roadworksSelectors.defectsMeta);
    const loadingDefects = useSelector(roadworksSelectors.loadingDefects);

    const { uploadFileError, uploadedRoadWorkFile, uploading } = useSelector(({ upload }) => upload);
    const statuses = useStoreProp(loadDefectStatuses, 'roadworks', 'defectStatuses');
    const roadLabTypeList = useStoreProp(loadRoadLabTypes, 'upload', 'roadLabTypeList');

    const [list] = roadLabTypeList?.filter(item => item.url === location.pathname);
    const labList = list?.labs ? Object.entries(list?.labs).map(([key, value]) => ({
        lab: key,
        title: value
    })) : [];

    const statusObj = useMemo(() => {
        return statuses.reduce((res, item) => { 
            res[item.status_id] = item;
            return res;
        }, {});
    }, [statuses]);

    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [page, setPage] = useState(1);

    const [params, setParams] = useState({});
    const [openAddModal, setOpenAddModal] = useState(false);

    const loadList = useCallback(() => {
        const paramsWithoutEmpty = removeEmptyFields(params);

        return  dispatch(loadDefects({ page, limit, ...paramsWithoutEmpty }));

    }, [dispatch, limit, page, params]);

    useEffect(() => {
        loadList();
    },[loadList]);

    useEffect(() => {
        if(uploadFileError || (Object.keys(validation?.data).length && uploadedRoadWorkFile?.file))
            setUploadModalOpen(true);
    }, [uploadFileError, validation.data, uploadedRoadWorkFile]);

    const closeUploadModal = () => {
        validation.clear();
        setUploadModalOpen(false);
    };

    const renderContent = () => {
        return <>
            {(loadingDefects || uploading) && <Loading circular={true}/>}
            {defectsData?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            {
                                title: '',
                                width: '50px'
                            },
                            {
                                title: 'Название',
                                width: '13%'
                            },
                            {
                                title: <>Дата и время<br/>регистрации</>,
                                width: '12%'
                            },
                            {
                                title: 'Группа',
                                width: '25%'
                            },
                            {
                                title: 'Тип',
                                width: '15%'
                            },
                            {
                                title: 'Адрес',
                                width: '25%'
                            },
                            {
                                title: 'Действия',
                                align: 'right',
                                isActions: true
                            }
                        ]}
                    >
                        {defectsData?.map(item => (
                            <Item
                                key={item.id}
                                item={item}
                                status={statusObj[item.status] || null}
                                loadList={loadList}
                            />
                        ))}
                    </LSContainer>
                ) : !loadingDefects && <div>{messages.DATA_IS_NOT_FOUND}</div>
            }
        </>;
    };
    
    return (
        <>
            <PageLayout
                header="Недостатки дорожной инфраструктуры"
                filters={<Filter setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenAddModal(true),
                                        // disabled: !permissions?.is_create,
                                    },
                                ]}
                                positionLeft
                                noPadding
                            />
                            <ButtonWithPopover 
                                buttonType="upload"
                                actions={(callback) => {
                                    return labList.map(item => <FileUploader
                                        key={item}
                                        callback={callback}
                                        title={item.title}
                                        action={uploadRoadLab}
                                        noPadding
                                        isButton
                                        noMarginLeft
                                        buttonType="defaultTextInfo"
                                        service={{ lab: item.lab, type: list.type }}
                                    />);
                                }}
                            />
                            {/* <FileUploader
                                title="Загрузить дор. лабораторию"
                                endIcon={<i className="far fa-angle-down" style={{ fontSize: '1rem' }}/>}
                            /> */}
                        </>),
                    total: defectsMeta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setPage(page),
                    list: defectsMeta,
                    limit,
                    setLimit
                }}
            />
            {openAddModal && (
                <ModalForm
                    open={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    loadList={loadList}
                />
            )}            
            {uploadModalOpen && (
                <UploadModal
                    onClose={closeUploadModal}
                    isOpen={uploadModalOpen}
                    data={uploadFileError}
                    validationData={validation.data}
                    reloadList={loadList}
                />
            )}
        </>
    );
};

export default Defects;
