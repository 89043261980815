import React, { useState } from 'react';
import Filter from './Filter';
import messages from '../../../../../helpers/constants/messages';
import PageLayout from '../../../../layout/PageLayout';

const AVK = () => {
    const [bool, setBool] = useState(false);

    return (
        <PageLayout
            header="Отчет по АВК"
            filters={<Filter setBool={setBool}/>}
            content={() => (<>
                {bool ? <div>{messages.DATA_IS_NOT_FOUND}</div> : ''}
            </>)}
        />
    );
};

export default AVK;