import React, { useContext, useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel, Typography,
} from '@mui/material';
import CommentAction from '../../../../../../common/CommentAction';
import titles from '../../../../../../../helpers/constants/titles';
import Context from '../../../../../../../helpers/context';


const Item = (props) => {
    const {
        checked = false,
        onChange = () => {},
        pdd = {},
        readOnly = false,
    } = props;

    const { permissions } = useContext(Context);

    const [isOpen, setIsOpen] = useState(false);

    const handleCheckbox = (e) => {
        setIsOpen(true);
    };

    const handleChange = (comment) => {
        onChange(comment, !checked);
    };

    const {
        article = '',
        part = '',
        section = '',
        violation = '',
    } = pdd;

    return (
        <>
            <FormControl className="block" variant="outlined">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!readOnly ? checked : false}
                            onChange={handleCheckbox}
                            color="primary"
                            disabled={readOnly || !permissions?.is_update}
                        />
                    }
                    label={
                        <Typography variant="body2">
                            {`${article}.${part}.${section} - ${violation}`}
                        </Typography>
                    }
                />
            </FormControl>

            {isOpen && (
                <CommentAction
                    open={isOpen}
                    title={titles.CHANGE_UPLOAD}
                    inputLabel={titles.STATE_REASON}
                    description={
                        <span>
                            Вы собираетесь выгружать данные по этому нарушению в
                            <strong> {!checked ? 'основную' : 'сервисную'} базу</strong>.
                            Укажите причину и подтвердите.
                        </span>
                    }
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    onAccept={handleChange}
                />
            )}
        </>
    );
};

export default Item;