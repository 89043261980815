import React from 'react';
import { getColorByStatus } from '../helper';
import icon from '../icons/icon';
import Information from './Information';

const PopUp = ({ data }) => {
    
    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: getColorByStatus(data.status) }}>
                <div className="map-popup_header_">
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                    <span>
                        {`${data.name} (${data.status_text})`}
                    </span>
                </div>
            </div>
            <div className="map-popup_body">
                <Information
                    data={data}
                />
            </div>
        </div>
    );
};

export default PopUp;
