import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemText, Typography } from '@mui/material';

import messages from 'helpers/constants/messages';
import { deleteTypicalOperation } from 'redux/SituationalPlans/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';

const Item = ({ item, reloadList }) => {
    const dispatch = useDispatch();
    const id = item?.id;

    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        dispatch(deleteTypicalOperation(id, () => {
            reloadList(true);
            setIsDelete(false);
        }));
    };

    return (
        <div className="list_item">
            <ListItem>
                <ListItemText>
                    <Typography>
                        {item?.name}
                    </Typography>
                </ListItemText>
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setIsEditMode(true),
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setIsDelete(true),
                        },
                    ]}
                    noMarginLeft
                    justButton
                />
            </ListItem>
            {isEditMode
            && <ModalForm
                isOpen={isEditMode}
                onClose={setIsEditMode}
                item={item}
                isNew={false}
                reloadList={reloadList}
            />
            }
            {isDelete
            && <ConfirmDelete
                open={isDelete}
                onClose={() => setIsDelete(false)}
                onSuccess={handleDelete}
                message={messages.CONFIRM_DELETE}
            />
            }
        </div>
    );
};

export default Item;
