import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import titles from '../../../../../helpers/constants/titles';
import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { useValidation } from '../../../../../helpers/hooks';
import { TextField } from '@mui/material';
import {
    createModel,
    editModel,
    loadBrandsList,
} from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import MarkSelect from '../../../../common/Autocomplete/MarkSelect';
import FormInfoWrapper from '../../../../common/FormInfoWrapper';

const ModelModal = ({ data, isNew, open, toggleOpen, reloadList }) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const loadingButton = useSelector(transportPassengerSelectors.loadingButton);

    const [formData, setFormData] = useState({ ...data });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        const callback = () => {
            reloadList();
            toggleOpen();
            validation.clear();
        };

        dispatch(isNew
            ? createModel(formData, callback)
            : editModel(data.id, formData, callback)
        );
    };

    const handleSelect = (brand) => {
        setFormData({ ...formData, brand_id: brand.id, brand });
    };

    return (
        <Modal
            isOpen={open}
            onClose={toggleOpen}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: toggleOpen
                    },
                    {
                        ...buttonsTypes.save,
                        loading: loadingButton,
                        disabled: loadingButton,
                        onClick: handleSave,
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <div className="block">
                    <MarkSelect 
                        title={'name_ru'}
                        selected={formData.brand}
                        required
                        error={validation.isKey('brand_id')}
                        helperText={validation.get('brand_id')}
                        onChange={handleSelect}
                        moduleName={'transportPassenger'}
                        action={loadBrandsList}
                    />
                </div>
                <FormInfoWrapper error={validation.isKey('name_ru')} helperText={validation.get('name_ru')}>
                    <TextField
                        value={formData.name_ru || ''}
                        name={'name_ru'}
                        onChange={handleChange}
                        className="block"
                        label={'Название модели(Ru)'}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={validation.isKey('name_ru')}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper error={validation.isKey('name_en')} helperText={validation.get('name_en')}>
                    <TextField
                        value={formData.name_en || ''}
                        name={'name_en'}
                        onChange={handleChange}
                        className="block"
                        label={'Название модели(En)'}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={validation.isKey('name_en')}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper error={validation.isKey('slug')} helperText={validation.get('slug')}>
                    <TextField
                        value={formData.slug || ''}
                        name={'slug'}
                        onChange={handleChange}
                        className="block"
                        label={'Алиас'}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={validation.isKey('slug')}
                    />
                </FormInfoWrapper>
            </div>
        </Modal>
    );
};

export default ModelModal;
