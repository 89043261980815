import React, { useState } from 'react';
import messages from '../../../../../helpers/constants/messages';
import DateRange from '../../../../common/Dates/DateRange';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import titles from '../../../../../helpers/constants/titles';
import ModalComponent from '../../../../common/Modal';
import GetReports from '../../../../common/GetReports';

const Modal = ({ handleCloseModal, data, open, filter, getReport }) => {
    const initialState = {
        report: 1,
        formats: [],
        start_at: filter.start_at || null,
        end_at: filter.end_at || null,
        grz: [data.garage_number]
    };

    const [boxFilters, setBoxFilters] = useState(initialState);

    const handleGetReport= () => {
        getReport({
            ...boxFilters,
            formats : boxFilters.formats.map(({ id }) => id)
        });
        handleCloseModal();
       
    };

    const handleResetFilter = () => {
        setBoxFilters(initialState);
    };

    const isDisabled = !boxFilters.start_at || !boxFilters.end_at || !boxFilters.formats.length;

    return (
        <ModalComponent
            onClose={handleCloseModal}
            isOpen={open}
            title={titles.CURRENT_TRANSPORT_REPORT}
            maxWidth="md"
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: handleCloseModal
                    },
                    {
                        ...buttonsTypes.resetFilter,
                        onClick: handleResetFilter
                    },
                    {
                        ...buttonsTypes.getReport,
                        onClick: handleGetReport,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <div><span><strong>Номер ТС: </strong>{data.garage_number || messages.NO_DATA}</span></div>
                <div><span><strong>Марка ТС: </strong>{data.mark || messages.NO_DATA}</span>, <span><strong>Модель ТС: </strong>{data.model || messages.NO_DATA}</span></div>
                <div>
                    <strong>Средняя скорость: </strong>{data.speed ? `${Number(data.speed).toFixed(2)} км/ч` : messages.NO_DATA}
                </div>
                <div style={{ marginTop: 25 }} className="row">
                    <DateRange
                        className="row__item"
                        handleDate={(newDate) => {
                            setBoxFilters({
                                ...boxFilters,
                                start_at: newDate
                            });
                        }}
                        handleEndDate={(newDate) => {
                            setBoxFilters({
                                ...boxFilters,
                                end_at: newDate
                            });
                        }}
                        valueStartDate={boxFilters.start_at}
                        valueEndDate={boxFilters.end_at}
                        required={true}
                    />
                </div>
                <div className="block">
                    <GetReports
                        selected={boxFilters.formats}
                        onChange={(formats) => setBoxFilters({ ...boxFilters, formats })}
                        reportId={24}
                    />
                </div>
            </div>
        </ModalComponent>
    );
};

export default Modal;
