import React, { useState } from 'react';
import CompanyList from '../CompanyList';
import LinkModal from 'components/common/LinkModal';

const CompanyListModal = ({ isOpen, onClose, microservice, item }) => {

    const [isOpenCompanyUniversalSelect, setIsOpenCompanyUniversalSelect] = useState(false);
    const [isSendData, setIsSendData] = useState(false);

    return (
        <>
            <LinkModal
                onClose={onClose}
                onSave={() => setIsSendData(true)}
                onChange={() => setIsOpenCompanyUniversalSelect(true)}
                name={item?.name}
                isOpen={isOpen}
                linkObject={'Организации'}
            >
                <CompanyList
                    isOpen={isOpenCompanyUniversalSelect}
                    onClose={onClose}
                    onCloseUniversalModal={() => setIsOpenCompanyUniversalSelect(false)}
                    microservice={microservice}
                    id={item?.id}
                    isSendData={isSendData}
                    setSendData={setIsSendData}
                />
            </LinkModal>
        </>
    );
};

export default CompanyListModal;