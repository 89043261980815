import common from './common';
import routes from './routes';
import cameraMessages from './camera';
import transport from './transport';

const messages = {
    ...common,
    ...routes,
    ...cameraMessages,
    ...transport,
};

export default messages;