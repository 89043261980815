import React from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover
} from '@mui/material';

import { menuSelectors } from 'redux/Menu';
import { authSelectors } from 'redux/Auth';
import layersLazyLoad from 'components/MapComponents/helpers/layersLazyLoad';

import Lock from '../Lock';


const useStyles = makeStyles({
    root: {
        minWidth: '30px',
        fontSize: '18px',
    },
    item: {
        fontSize: '0.875rem',
        lineHeight: 1.43
    },
    popover: {
        width: 280,
    }
});

const PopoverList =({
    id,
    onClose,
    anchorEl,
    open,
    layersList,
    handleChangeSelectedLayer,
    handleChangeAttachedList,
    selectedLayer
}) => {
    const classes = useStyles();
    const attachList = useSelector(menuSelectors.attachList);
    const activeLayers = useSelector(menuSelectors.activeLayers);
    const user = useSelector(authSelectors.authUserInfo);
    const layersConfig = layersLazyLoad();
    const blueColor = 'rgba(0, 0, 255, 0.54)';

    const isActive = (el) => activeLayers.includes(el.layer);

    return (
        <Popover
            open={open}
            id={id}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            onClose={onClose}
        >
            <List className={classes.popover}>
                {layersList.map((item) => {
                    return (
                        <ListItem
                            onClick={() => {
                                if (user.is_map_merge) {
                                    handleChangeAttachedList(item.layer);
                                }
                              
                                handleChangeSelectedLayer(item.layer);
                                onClose();
                            }}
                            selected={isActive(item) || selectedLayer === item.layer}
                            button
                            key={item.layer}
                        >
                            <ListItemIcon classes={{ root: classes.root }}>
                                <i
                                    style={{ color: selectedLayer === item.layer ? blueColor : 'rgba(0, 0, 0, 0.54)' }}
                                    className={layersConfig[item.layer]?.layerIcon || ''}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={layersConfig[item.layer]?.name || ''}
                                classes={{ primary: classes.item }}
                            />
                            <Lock
                                onClick={() => {
                                    handleChangeAttachedList(item.layer);
                                    // onClose();
                                }}
                                isLocked={attachList.includes(item.layer)}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Popover>
    );
};

export default PopoverList;