import { useState } from 'react';
import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { useDispatch } from 'react-redux';
import { useValidation } from '../../../../../helpers/hooks';
import { TextField } from '@mui/material';
import titles from '../../../../../helpers/constants/titles';
import FormInfoWrapper from '../../../../common/FormInfoWrapper';
import SelectUnits from '../../../../common/Autocomplete/RoadWorks/Units';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import { createRepairObjectIndicator, editRepairObjectIndicator } from '../../../../../redux/RoadWorks/actions';

const ModalForm = ({ item, isNew, open, onClose, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        ...item,
        name: item?.name || '',
        unit_of_measurement_list: item?.unit_of_measurement_list || [], 
        unit_of_measurement_id_list: item?.unit_of_measurement_id_list || []
    };

    const [data, setData] = useState(initialState);

    const onChange = (name, value) => {
        validation.deleteKey(name);
        setData(prev => ({
            ...prev,
            [name] : value
        }));
    };

    const handleSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        const prepareData = removeEmptyFields({
            ...data,
            unit_of_measurement_id_list: data.unit_of_measurement_list.map(({ id }) => id)
        });

        isNew
            ? dispatch(createRepairObjectIndicator(prepareData, callback))
            : dispatch(editRepairObjectIndicator(data.id, prepareData, callback));
    };

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            isOpen={open}
            onClose={onClose}
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: !data?.name || !data?.unit_of_measurement_list.length
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormInfoWrapper
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                >
                    <TextField
                        value={data.name}
                        required
                        onChange={(e) => onChange('name', e.target.value)}
                        className="block"
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        name="name"
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    error={validation.isKey('unit_of_measurement_id_list')}
                    helperText={validation.get('unit_of_measurement_id_list')}
                >
                    <SelectUnits
                        multiple
                        label="Единица измерения"
                        selected={data.unit_of_measurement_list}
                        onChange={(value) => onChange('unit_of_measurement_list', value)}
                        required
                    />
                </FormInfoWrapper>
            </div>
        </Modal>
    );
};

export default ModalForm;
