import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadBrandsList } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import Context from 'helpers/context';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer, LSHeaderColumn, LSHeaderItem } from 'components/common/ListStyle';

import ModalForm from './ModalForm';
import Filter from './Filter';
import Item from './Item';

function Brands() {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);
    
    const list = useSelector(transportPassengerSelectors.brandsData);
    const meta = useSelector(transportPassengerSelectors.brandsMeta);
    const loading = useSelector(transportPassengerSelectors.loading);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [modalOpen, setModalOpen] = useState(false);

    const reloadList = (isDelete) => {
        isDelete && list.length === 1 && params.page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadBrandsList(params.page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadBrandsList(params.page, limit, params.data));
    }, [dispatch, limit, params]);
    
    const renderContent = () => {
        return <>
            {loading && <Loading circular={true}/>}
            {list?.length > 0
                ? (
                    <LSContainer>
                        <LSHeaderItem>
                            <LSHeaderColumn width="100px">
                                {titles.TYPE_OF_VEHICLE}
                            </LSHeaderColumn>
                            <LSHeaderColumn width="300px">
                                {titles.NAME}
                            </LSHeaderColumn>
                            <LSHeaderColumn align="right" isActions>
                                Действия
                            </LSHeaderColumn>
                        </LSHeaderItem>

                        {list.map((item) => (
                            <Item
                                key={item.id}
                                item={item}
                                reloadList={reloadList}
                            />
                        ))}
                    </LSContainer>
                )
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>;
    };

    return (
        <>
            <PageLayout
                header="Марки"
                filters={<Filter setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[
                                    ...(permissions?.is_create
                                        ? [
                                            {
                                                ...buttonsTypes.add,
                                                onClick: () => setModalOpen(true),
                                            }
                                        ]
                                        : []),
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    
                    total: meta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />

            {modalOpen && (
                <ModalForm
                    open={modalOpen}
                    onClose={() => setModalOpen(false)} 
                    isNew={true}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
}

export default Brands;
