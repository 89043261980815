import { useMemo } from 'react';

import {
    loadRoadSectionCategories,
    loadRoadSectionTypes,
    loadStatusesRoadSection,
} from 'redux/RoadNetwork/actions';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import { LSContainer } from 'components/common/List';

import Item from './Item';

const List = ({ list = [], reloadList, inPrint = false }) => {
    const roadSectionTypes = useStoreProp(loadRoadSectionTypes, 'roadNetworks', 'roadSectionTypes');
    const roadSectionCategories = useStoreProp(loadRoadSectionCategories, 'roadNetworks', 'roadSectionCategories');
    const statusesList = useStoreProp(loadStatusesRoadSection, 'roadNetworks', 'statusesRoadSection');
    const statuses = useMemo(() => statusesList
        ?.reduce((res, el) => ({ ...res, [el.id]: el }), {})
        || {}
    , [statusesList]);
    return (
        <>
            <LSContainer
                headers={[
                    { title: inPrint ? 'Статус' : '', width: inPrint ? '13%' : '50px' },
                    { title: titles.NAME, width: '10%' },
                    { title: titles.CATEGORY, width: '12%' },
                    { title: titles.TYPE, width: '15%' },
                    { title: titles.ORGANIZATION, width: '15%' },
                    { title: titles.ADDRESS, width: '15%' },
                    { title: 'Дата создания', width: '11%' },
                    { title: 'Дата регистрации', width: '12%' },
                    ...(inPrint
                        ? []
                        : [{ title: 'Действия', align: 'right', isActions: true }]
                    )
                ]}
            >
                {list?.map(item => (
                    <Item
                        key={item?.id}
                        item={item}
                        type={roadSectionTypes[item.type]}
                        category={roadSectionCategories[item.category]}
                        reloadList={reloadList}
                        status={statuses[item.status]}
                        inPrint={inPrint}
                    />
                ))}
            </LSContainer>
        </>
    );
};

export default List;
