import Settings from '../../pages/Dictionaries/Efficiency/Settings';
import CriticalSettings from '../../pages/Dictionaries/Efficiency/CritialSettings';

const routes = [
    {
        path: '/dictionaries/efficiency/settings',
        component: Settings,
        exact: true
    },
    {
        path: '/dictionaries/efficiency/critical-settings',
        component: CriticalSettings,
        exact: true
    },
];

export default routes;