/**
 * Sagas
 * **/
import {
    all,
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import { messageTypes, showMessage } from 'redux/Message/actions';
import { changeMapCenter } from 'redux/Map/actions';
import { setAuthToken } from 'helpers/axios.config';
import messages from 'helpers/constants/messages';

import { config } from '../../config';

import api from './api.methods';
import * as types from './types';
import {
    changedMapLayer,
    changeRefreshTime,
    getAuthUser,
    loadedMapLayerList,
    loadedUserFilters,
    loading,
    loadingButton,
    loadingUserFilters,
    loadUserFilters,
    refreshToken,
    saveAuthUserData,
    saveCurrentProject,
    saveUser,
    saveUserData,
    saveUserLastProject,
    saveUserServices,
    setSuperUser,
    userSignOutSuccess,
} from './actions';
import { parseToken } from './helper';

// авторизация
function* signInUserWithEmailPasswordSaga({ payload }) {
    const { username, password, remember } = payload;
    yield put(loading(true));
    const user = yield call(api.getToken, username, password);
    const error = yield select(state => state.validation);
    if (user?.access_token && user?.expires_in) {
        yield put(saveUser({ user, remember }));
    }
    else if (!error?.password || !error?.username) {
        yield put(
            showMessage(
                messageTypes.error,
                'Что то пошло не так',
                'Повторите попытку'
            )
        );
    }
    yield put(loading(false));
}

// обновление токена
function* refreshTokenSaga({ payload }) {
    const { refresh_token } = payload;
    const user = yield call(api.refreshToken, refresh_token);
    if (user) {
        yield put(saveUser({ user, remember: true }));
    } else {
        yield put(userSignOutSuccess());
        yield put(changeRefreshTime(0));
    }
}

// сбросить пароль
function* resetPasswordSaga({ payload }) {
    const { username, history } = payload;
    yield put(loading(true));
    const response = yield call(api.resetPassword, username);
    if (response?.success) {
        // редиректим
        setTimeout(() => history.push('/signin'), 500);
        //yield put(resetPasswordSuccess());
        // сообщение
        yield put(
            showMessage(
                messageTypes.success,
                'Мы отправили вам новый пароль',
                'Проверьте свой email'
            )
        );
    }
    yield put(loading(false));
}

// сохраняем данные авторизации
function* saveUserSaga({ payload: { user, remember } }) {
    const { access_token, expires_in } = user;
    // установить токен в аксиос
    setAuthToken(access_token);
    // через какое время необходимо обновить токен
    const timeRefresh = expires_in * 1000 * 0.6;
    const needRefresh = Date.now() + timeRefresh;
    const timeLeaveCookie = Date.now() + expires_in * 1000 * 0.8;
    // запоминаем данные
    if (remember) {
        // cookie.save('authUser', user, {
        //     expires: new Date(timeLeaveCookie),
        //     ...config.getCookieOptions(),
        // });
        // cookie.save('needRefresh', needRefresh, {
        //     expires: new Date(timeLeaveCookie),
        //     ...config.getCookieOptions(),
        // });
        localStorage.setItem('authUser', JSON.stringify(user));
        localStorage.setItem('needRefresh', needRefresh);
    }

    // парсим данные с jwt
    user = parseToken(user);
    // положить пользователя в store
    yield put(saveUserData(user));
    // запросить данные пользователя
    yield put(getAuthUser());
    // установить время обновления и проверки
    yield put(changeRefreshTime(timeRefresh));
}

// получить данные авторизованного пользователя
function* getAuthUserSaga({ payload: access_token }) {
    yield put(loading(true));
    const response = yield call(api.getAuthUser, access_token);
    if (response?.success) {
        // получаем доступные каналы вебсокетов
        // yield put(getAvailableChannels());

        const user = response.data;
        const services = yield call(api.getUserServices);
        if (services?.data.length > 0) {
            const systemName = config.getGeneral('systemName');
            const currentService = services.data.find(el => el.service_name === systemName);
            // смотрим что текущий проект systemName доступен данному юзеру и что список проектов не пустой
            if (currentService && (currentService.entity_list?.length > 0) && (user.services?.length > 0)) {
                // записываем currentService и все их данные юзеру
                user.currentService = currentService || {};
                // смотрим если у юзера есть последний сохраненный проект и этот проект есть в доступных
                // либо у юзера нету последнего сохраненного
                const userLastProject = user.service_last?.[systemName]?.project;
                if (userLastProject) {
                    // если у юзера есть сохраненный проект
                    // находим в сервисе проект соответствующий последнему сохраненному у юзера
                    const currentProject = currentService.entity_list
                        ? currentService.entity_list.find(
                            (el) => el.code === user.service_last?.[systemName]?.project
                        )
                        : null;
                    if (currentProject) {
                        // найденный project записываем в store
                        yield put(saveCurrentProject(currentProject));
                        const { lat, lon } = currentProject;
                        //yield put(changeMapCenter([lat, lon], 10));
                        yield put(changeMapCenter(config.get('mapCenter'), config.get('mapMinZoom')));
                        // config.get('mapCenter') config.get('mapMinZoom')
                    } else {
                        // сообщение и разлогин
                        yield put(
                            showMessage(
                                messageTypes.error,
                                'К данной подсистеме нет доступа',
                            )
                        );
                        yield put(userSignOutSuccess());
                    }
                } else {
                    // если у юзера не сохранен последний project
                    // берем [0] из списка
                    const project = currentService.entity_list[0];
                    yield put(saveCurrentProject(project));
                    const { lat, lon } = project;
                    //yield put(changeMapCenter([lat, lon], 10));
                    yield put(changeMapCenter(config.get('mapCenter'), config.get('mapMinZoom')));
                    // config.get('mapCenter') config.get('mapMinZoom')
                    // сохраняем юзеру last project
                    yield put(saveUserLastProject(project));
                    // и обновляем данные в нашем авторизованном юзере
                    user.service_last = { [systemName]: project };
                }
                // сохраняем в стор доступные сервисы
                yield put(saveUserServices(services.data));
                // записываем полученные данные по юзеру в уже авторизованого юзера authUser
                yield put(saveAuthUserData(user));
            } else {
                // сообщение и разлогин
                yield put(
                    showMessage(
                        messageTypes.error,
                        'К данной подсистеме нет доступа',
                    )
                );
                yield put(userSignOutSuccess());
            }
        } else {
            // сообщение и разлогин
            yield put(
                showMessage(
                    messageTypes.error,
                    'К данной подсистеме нет доступа',
                )
            );
            yield put(userSignOutSuccess());
        }
    } else {
        // сообщение и разлогин
        yield put(showMessage(
            messageTypes.error,
            'К данной подсистеме нет доступа',
        ));
        yield put(userSignOutSuccess());
    }
    yield put(loadUserFilters());
    yield put(loading(false));
}

function* putUserNameSaga({ payload: data, id, callback }) {
    const user = yield select((state) => state.auth.authUser);
    const access_token = user.access_token || null;
    const refresh_token = user.refresh_token || null;
    const response = yield call(api.changeUserName, data, id);
    if (response) {
        yield put(
            refreshToken({
                refresh_token,
                access_token,
            })
        );
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        callback?.();
    }
}

// запрашиваем доступные юзеру сервисы и регионы
function* getUserServicesSaga() {
    const services = yield call(api.getUserServices);
    if (services) {
        yield put(saveUserServices(services.data));
    }
}

function* changePasswordSaga({ payload, callback }) {
    yield put(loading(true));
    const response = yield call(api.changePassword, payload);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
    yield put(loading(false));
}

function* saveCurrentProjectSaga({ payload }) {
    yield call(api.changeUserLast, {
        lasts: {
            eputs: { project: payload.code },
        },
    });
}

function* logoutSaga({ payload }) {
    const { callback, deleteAllTokens } = payload;
    yield put(loading(true));
    const response = deleteAllTokens ? yield call(api.deleteAllTokens) : yield call(api.logout);
    if (response) {
        yield callback?.();
    }
    yield put(loading(false));
}

function* loadMapLayerListSaga() {
    const response = yield call(api.getMapLayers);
    if (response) {
        yield put(loadedMapLayerList(response?.data || []));
    }
}

function* setMapLayerSaga({ payload }) {
    const response = yield call(api.setMapLayer, payload);
    if (response?.success) {
        yield put(changedMapLayer(payload));
    }
}

function* loadUserFiltersSaga({ payload }) {
    yield put(loadingUserFilters(true));
    const response = yield call(api.loadUserFilters, payload);
    if (response?.success) {
        yield put(loadedUserFilters(response.data));
    }
    yield put(loadingUserFilters(false));
}
function* saveUserFiltersSaga({ payload }) {
    const response = yield call(api.saveUserFilters, payload);
    if (response?.success) {
        yield put(loadUserFilters());
        yield put(showMessage(messageTypes.success, 'Фильтры сохранены'));
    }
}
function* deleteUserFiltersSaga({ payload }) {
    yield call(api.deleteUserFilters, payload);
    yield put(loadUserFilters());
}

function* loadAccessTemplateSaga({ payload , callback }) {
    const response = yield call(api.getAccessTemplate, payload.uri);
    if (response?.success) {
        callback?.(response?.data);
    }
}

function* authByUserIdSaga({ payload, callback }) {
    const { id } = payload;

    yield put(loadingButton(true));

    // запрашиваем токен юзера по его id
    const response = yield call(api.getTokenByUser, id);

    if (response) {
        // берем текущего (активного) юзера
        const user = yield select((state) => state.auth.authUser);
        // кладем его в новое поле в localStorage
        localStorage.setItem('authSuperUser', JSON.stringify(user));
        // кладем его в новое поле в стор
        yield put(setSuperUser(user));

        // ставим нового юзера как основного (у которого запросили токен по id)
        yield put(saveUser({ user: response }));
        // кладем его в localStorage
        localStorage.setItem('authUser', JSON.stringify(response));
        yield callback && callback(response);

        yield put(showMessage(messageTypes.success, 'Успешная авторизация'));
    }

    yield put(loadingButton(false));
}

function* logoutCurrentUserSaga() {
    localStorage.removeItem('authSuperUser');

    const user = yield select((state) => state.auth.authSuperUser);

    yield put(saveUser({ user: user }));
    yield put(setSuperUser(null));

    localStorage.setItem('authUser', JSON.stringify(user));

    yield put(showMessage(messageTypes.info, 'Пользователь сменен'));
}


export default function* saga() {
    yield all([
        takeLatest(types.SIGNIN_USER, signInUserWithEmailPasswordSaga),
        takeLatest(types.REFRESH_TOKEN, refreshTokenSaga),
        takeLatest(types.RESET_PASSWORD, resetPasswordSaga),
        takeLatest(types.SAVE_USER, saveUserSaga),
        takeLatest(types.GET_AUTH_USER, getAuthUserSaga),
        takeLatest(types.CHANGE_PASSWORD, changePasswordSaga),
        takeLatest(types.PUT_USER_NAME, putUserNameSaga),
        takeLatest(types.GET_USER_SERVICES, getUserServicesSaga),
        takeLatest(types.SAVE_USER_LAST_PROJECT, saveCurrentProjectSaga),
        takeLatest(types.LOGOUT, logoutSaga),
        takeLatest(types.LOAD_MAP_LAYER_LIST, loadMapLayerListSaga),
        takeLatest(types.SET_MAP_LAYER, setMapLayerSaga),
        takeLatest(types.SAVE_USER_FILTERS, saveUserFiltersSaga),
        takeLatest(types.DELETE_USER_FILTERS, deleteUserFiltersSaga),
        takeLatest(types.LOAD_USER_FILTERS, loadUserFiltersSaga),
        takeLatest(types.LOAD_ACCESS_TEMPLATE, loadAccessTemplateSaga),

        takeLatest(types.AUTH_BY_USER_ID, authByUserIdSaga),
        takeLatest(types.LOGOUT_CURRENT_USER, logoutCurrentUserSaga),
    ]);
}
