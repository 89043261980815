import Parking from '../../pages/Dictionaries/ParkingArea/Parking';

const routes = [
    {
        path: '/dictionaries/parking-area/parking',
        component: Parking,
        exact: true,
    },
];

export default routes;