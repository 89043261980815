import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import {
    loadDtpByArea,
    loadDtpGraph,
} from 'redux/Incidents/actions';
import PageLayout from 'components/layout/PageLayout';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import ReportBlock from 'components/common/Report/ReportBlock';
import DistrictSwitch from 'components/common/DistrictSwitch';
import buttons from 'helpers/constants/buttons';
import { 
    fullDateTimeWithTimeZone, 
    getDateWithDuration, 
    getEndOf,
    getStartOf, 
} from 'helpers/date.config';

import styles from './dtpGraph.module.scss';
import Page from './Page';
import Filters from './Filters';

const DtpGraph = ({ tab }) => {
    const dispatch =  useDispatch();
    const loadingGraph = useSelector(({ incidents }) => incidents?.dtpGraphLoading);
    const loadingByArea = useSelector(({ incidents }) => incidents?.loadingDtpReportByArea);

    // фильтр храним в индексе, т.к. он нужен для получения отчета
    const initialState = {
        start_date: fullDateTimeWithTimeZone(getStartOf('day', getDateWithDuration({ month: -1 }))),
        end_date: fullDateTimeWithTimeZone(getEndOf('day')),
        type_load_list: [],
        is_area: 0,
        is_graph: true
    };

    const [params, setParams] = useState(initialState);
    const [isGraph, setIsGraph] = useState(false);
    const [isArea, setIsArea] = useState(false);

    const paramsWithoutEmpty = useCallback((filter) => ({
        start_date: fullDateTimeWithTimeZone(filter.start_date),
        end_date: fullDateTimeWithTimeZone(filter.end_date),
        type_load_list: filter.type_load_list,
        is_area: Number(filter.is_area),
        is_graph: filter.is_graph
    }), [params]);

    const getReport = (format) => dispatch(loadDtpGraph({
        ...paramsWithoutEmpty(params),
        report: format,
    }));

    const onSearch = (filter = params) => {
        const filters = paramsWithoutEmpty(filter);
        params.is_graph
            ? dispatch(loadDtpGraph(filters))
            : dispatch(loadDtpByArea(filters));
    };

    useEffect(() => {
        onSearch();
    }, [params.is_graph, params.is_area]);
    
    const resetFilter = () => {
        onSearch(initialState);
    };

    const handleChangeGraph = (e) => {
        setParams({ ...params, is_graph: { true: true, false: false }[e.target.value] });
        setIsGraph(true);
    };

    const handleChangeArea = (bool) => {
        setParams({ ...params, is_area: bool ? 1 : 0 });
        setIsArea(true);
    };

    return (
        <PageLayout
            tab={tab}
            header="Отчет - ДТП по районам"
            filters={<Filters 
                filter={params}
                setFilter={setParams}
                onReset={resetFilter} 
                onSearch={onSearch}
                tab={tab}
                initialState={initialState}
                isArea={isArea}
                isGraph={isGraph}
            />}
            additionalButtons={<ReturnToButton />}
            content={() => <Page loading={loadingGraph || loadingByArea} is_graph={params.is_graph}/>}
            loading={loadingGraph || loadingByArea}
            informPanelProps={{
                buttons: <ReportBlock onGetReport={getReport} />
            }}
            actionPanel={
                <div className={styles.action_panel}>
                    <DistrictSwitch
                        key="is_area"
                        title1="По районам города"
                        title2="По районам области"
                        value={params.is_area}
                        onChange={handleChangeArea}
                    />
                    <RadioGroup
                        value={params.is_graph}
                        name="radio-buttons-group"
                        onChange={handleChangeGraph}
                        style={{  flexDirection: 'row' }}
                    >
                        <FormControlLabel value={'false'} control={<Radio />} label={buttons.TABLE} />
                        <FormControlLabel value={'true'} control={<Radio />} label={buttons.GRAPH} />
                    </RadioGroup>
                </div>
            }
        />
    );
};

export default DtpGraph;
