import React from 'react';
import { Bar as GBar } from 'react-chartjs-2';
import {
    getHumanDate,
} from '../../../../../helpers/date.config';
import styles from './styles.module.scss';

// страница всех графиков
const Graphic = ({
    item = {},
    legendPosition = 'bottom',
}) => {
    const data = item.datas.reduce((accum, el) => {
        accum.labels = Array.from(new Set([
            ...accum.labels,
            el.date
        ])).sort();

        accum.totals = {
            ...el.total,
            backgroundColor: el.total.color,
            label: el.total.name,
            borderColor: el.total.color,
            borderWidth: 2, // 3 by default
            fullData: [
                ...(accum.totals?.fullData || []),
                {
                    ...el.total,
                    date: el.date
                },
            ]
        };
        const dataByName = el.data.reduce((res, dt) => {
            res[dt.name] = {
                ...dt,
                backgroundColor: dt.color,
                label: dt.name,
                borderColor: '#000',
                maxBarThickness: 80,
                fullData: [
                    ...(accum.data[dt.name]?.fullData || []),
                    {
                        ...dt,
                        date: el.date,
                    }
                ]
            };
                
            return res;
        }, {});

        accum.data = {
            ...accum.data,
            ...dataByName
        };
            
        return accum;
    }, { data: {}, labels: [], totals: [] } );
  
    const dataForBar = (obj) => {
        if (obj.labels?.length > 0) {
            return ({
                labels: obj.labels.map(el => getHumanDate(el)),
                datasets: [
                    { 
                        type: 'line',
                        ...obj.totals,
                        data: obj.labels.map((date) => {
                            return obj.totals.fullData.find((el) => el.date === date)?.value;
                        }),
                    },
                    ...Object.values(obj.data).map((element) => {
                        return { 
                            type: 'bar',
                            ...element,
                            data: obj.labels.map((date) => {
                                return element.fullData.find((el) => el.date === date)?.value;
                            }),
                        };
                    }),
                ]
            });
        }
        return null;
    };

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
            },
            legend: {
                position: legendPosition,
            },
        },
        scales: {
            x: {
                // stacked: true,
            },
            y: {
                // stacked: true,
                title: {
                    display: true,
                    text: data.totals?.units
                }
            },
        },
    };

    const dataSet = dataForBar(data);
   
    return (
        <div className={styles.card}>
            <h2 className={styles.header}>{item.title}</h2>
            <div className={styles.data}>
                {dataSet !== null
                    ? <GBar
                        options={options}
                        data={dataSet}
                        style={{ maxHeight: '400px' }}
                    />
                    : <div>Нет данных для выбранных параметров</div>
                }
            </div>
        </div>
    );

};

export default Graphic;
