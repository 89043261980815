import messages from './constants/messages';

export const stringifyRenderData = (data) => {
    const isEmpty = (!data || (typeof data !== 'object')) 
        || (Array.isArray(data) && data.length === 0) 
        || (Object.keys(data).length === 0);

    if (isEmpty) return messages.NO_DATA;

    const formattedData = Array.isArray(data) 
        ? data.map(item => `${item.person ? item.person + ': ' : ''}${item.data || item.email || messages.NO_DATA}`) 
        : Object.entries(data).map(([key, value]) => `${key}: ${value}`);

    return formattedData.join(', ');
};