import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import reducersLazyLoad from './reducersLazyLoad';

const reducers = {};

reducersLazyLoad((module, path) => {
    // if (!module.moduleName) {
    //     throw new Error(`Cant find "moduleName" in ${path}`);
    // }
    // if (!module.default) {
    //     throw new Error(`Cant find "export default" for combining reducers in ${path}`);
    // }

    if (module.moduleName && (module.default || module.reducer)) {
        reducers[module.moduleName] = (module.default || module.reducer);
    }
});

const createRootReducer = (history) => {
    const appReducer = combineReducers({
        router: connectRouter(history),
        ...reducers
    });
    const rootReducer = (state, action) => {
        // если это разлогин то сбрасываем все стейты в начальное стостояние
        if (action.type === 'auth/SIGNOUT_USER') {
            return appReducer(undefined, action);
        }
        return appReducer(state, action);
    };
    return rootReducer;
};

export default createRootReducer;
