export const companiesState = (state) => state.companies;

export const loadingCompanies = (state) => companiesState(state).loadingCompanies;
export const companies = (state) => companiesState(state).companies;
export const companiesData = (state) => companies(state).data;
export const companiesMeta = (state) => companies(state).meta;

export const standardizedCompanies = (state) => companiesState(state).standardizedCompanies;
// export const standardizedCompaniesData = (state) => standardizedCompanies(state).data;
// export const standardizedCompaniesMeta = (state) => standardizedCompanies(state).meta;

export const currentCompany = (state) => companiesState(state).currentCompany;
export const company = (state) => companiesState(state).company;
export const loadingCompany = (state) => companiesState(state).loadingCompany;
export const currentFiolations = (state) => companiesState(state).currentFiolations;
export const loadingCurrentFiliations = (state) => companiesState(state).loadingCurrentFiliations;

export const companyRoles = (state) => companiesState(state).companyRoles;
export const companyRolesData = (state) => companyRoles(state).data;
export const companyRolesMeta = (state) => companyRoles(state).meta;
export const loadingCompanyRoles = (state) => companiesState(state).loadingCompanyRoles;

export const showcase = (state) => companiesState(state)?.showcase;
export const loadingShowcase = (state) => companiesState(state)?.loadingShowcase;