import UniversalSelect from '../../../../common/UniversalSelect';
import { useDispatch } from 'react-redux';
import { loadMessageTemplates } from '../../../../../redux/Boards/actions';

const SelectTemplate = ({ isOpen, onClose, setData, data }) => {
    const dispatch = useDispatch();

    const getTemplates = (params) => {
        const { page, limit } = params;
        dispatch(loadMessageTemplates({ page, limit }));
    };

    const handleAccept = (value) => {
        setData(value);
    };

    return (<>
        {isOpen
            && <UniversalSelect
                fetchList={getTemplates}
                storeName={'boards'}
                storeNameProps={'boardMessageTemplates'}
                keyProp={'id'}
                withSearch={true}
                isSelected={true}
                selected={[data]}
                renderProps={(el) => <div><span>{el.header}</span></div>}
                onChange={handleAccept}
                onAccept={handleAccept}
                isOpen={isOpen}
                onClose={onClose}
                noPadding={true}
                title="Выберите шаблон"
                small={true}
            />
        }
    </>);
};

export default SelectTemplate;