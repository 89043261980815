import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
    Avatar, 
    TextField,
    IconButton,
    Tooltip,
} from '@mui/material';
import style from './editProfileUser.module.scss';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { clearValidation } from '../../../../redux/Validation/actions';
import { putUserName } from '../../../../redux/Auth/actions';
import titles from '../../../../helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from '../../../common/FormButtons';
import { authSelectors } from '../../../../redux/Auth';
import useClipboard from 'react-use-clipboard';
import { showMessage } from '../../../../redux/Message/actions';
import messagetypes from '../../../../redux/Message/messagetypes';

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}));

const EditProfileUser = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(authSelectors.authUserInfo);
    const initialState = {
        first_name: user?.first_name || '',
        last_name: user?.last_name || ''
    };
    const [profile, setProfile] = useState(initialState);
    const [isCopied, setCopied] = useClipboard(user.telegram_unique, {
        successDuration: 6000,
    });
    const validation = useSelector(({ validation }) => validation);

    const handleCopyToClipboard = (e) => {
        setCopied(e);
        showMessage(messagetypes.success, 'Текст скопирован в буфер обмена');
    };

    const handleChangeProfile = (e) => {
        e.preventDefault();
        dispatch(clearValidation());
        const id = user.id;
        const data = { ...profile, full_name: `${profile.first_name} ${profile.last_name}` };
        dispatch(putUserName(data, id));
        // dispatch(getAuthUser());
    };
    const handleReset = () => {
        dispatch(clearValidation());
        setProfile(initialState);
    };

    useEffect(() => {
        setProfile((state) => ({
            ...state,
            first_name: user.first_name || '',
            last_name: user.last_name || ''
        }));
    }, [user]);

    return <>
        <div className={style.edit__wrap}>
            <div className={style.edit}>
                <h1>Редактировать профиль</h1>
                <div className={style.edit__profile}>
                    <Avatar className={classes.large}>{user.first_name?.charAt(0)}</Avatar>
                    <div className={style.edit__profile__wrap}>
                        <span>{titles.FIRST_NAME}: <strong>{user.first_name}</strong></span>
                        <span>{titles.LAST_NAME}: <strong>{user.last_name}</strong></span>
                        <span>{titles.EMAIL}: <strong>{user.email}</strong></span>
                        <span>{titles.TELEGRAM_ID}: <strong>{user.telegram_unique}</strong>
                            <Tooltip title={ isCopied ? 'Текст скопирован в буфер обмена' :  'Cкопировать' } >
                                <IconButton onClick = {handleCopyToClipboard} size="small">
                                    {isCopied ? <i className="fas fa-copy" /> : <i className="far fa-copy" />}
                                </IconButton>
                            </Tooltip>
                        </span>
                    </div>
                </div>
                <h3>Изменить Имя и Фамилию</h3>
                <form className={cn(classes.root, style.edit__form)} noValidate autoComplete="off">
                    <div className={style.edit__form__wrap}>
                        <TextField
                            className={style.edit__form__wrap__field}
                            name="first_name"
                            id="standard-search"
                            label={'Имя'}
                            variant="outlined"
                            value={profile.first_name}
                            error={!!validation.first_name}
                            helperText={validation.first_name}
                            type="text"
                            onChange={(e) => setProfile((state) => ({
                                ...state,
                                first_name: e.target.value
                            }))}
                        />
                        <TextField
                            className={style.edit__form__wrap__field}
                            name="last_name"
                            variant="outlined"
                            id="standard-search"
                            label={'Фамилия'}
                            type="text"
                            value={profile.last_name}
                            error={!!validation.last_name}
                            helperText={validation.last_name}
                            onChange={(e) => setProfile((state) => ({
                                ...state,
                                last_name: e.target.value
                            }))}
                        />
                    </div>
                    <div className={style.edit__form__submit}>
                        <FormButtonsComponent
                            className={style.edit__btn}
                            justButton
                            buttons={[
                                {
                                    ...buttonsTypes.cancel,
                                    onClick: handleReset,
                                    fullWidth: true,
                                    size: 'small'
                                },
                                {
                                    ...buttonsTypes.save,
                                    disabled: !profile.first_name || !profile.last_name,
                                    fullWidth: true,
                                    size: 'small',
                                    onClick: handleChangeProfile
                                }
                            ]}
                        />
                    </div>
                </form>
            </div>
        </div>
    </>;
};

export default EditProfileUser;
