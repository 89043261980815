import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

import TabInformation from 'components/MapComponents/Layers/Ecology/PopUp/TabInformation';
import MapForm from 'components/pages/Dictionaries/DorisControl/VFComplex/MapForm';

const BasicData = ({ item }) => {
    return (
        <Grid
            container
            direction="row"
            spacing={1}
            justify="center"
            alignItems="flex-start"
        >
            {Object.keys(item).length > 0
                ? <>
                    <Grid item xs={6}>
                        <Typography variant="body1">
                            <b>Название:</b> {item?.name && item.name}
                        </Typography>
                        <Typography variant="body1">
                            <b>Текущие параметры:</b>
                        </Typography>
                        <TabInformation
                            key={item.external_id}
                            external_id={item.external_id}
                            style={{ height: 375 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {(item?.lat && item?.lon) && (
                            <MapForm
                                latlon={[item?.lat, item?.lon]}
                                onClose={() => {}}
                                readOnly={true}
                                entities={item?.entities || []}
                            />
                        )}
                    </Grid>
                </>
                : <div>Станция не найдена</div>
            }
        </Grid>
    );

};

export default BasicData;
