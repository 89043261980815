import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { withStyles } from '@mui/styles';
import { Chip, Divider, Grid, List, ListItem, Tooltip, Typography } from '@mui/material';

import {
    deleteComplex,
    loadComplexEntities,
    loadComplexModelTypes,
    loadComplexStatuses,
    loadComplexTransferTypes,
    loadComplexTypes,
    loadComplexUnitTypes,
    loadInaccuracy,
    // loadComplexTypeGrouping,
    // loadComplexMeasuring
} from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import ConfirmDelete from 'components/common/ConfirmModal';
import CommentAction from 'components/common/CommentAction';
import { useStoreFromSelector, useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';
import titles from 'helpers/constants/titles';

import styles from '../../vfcomplex.module.scss';
import MapForm from '../../MapForm';

import StatusForm from './StatusForm';
import BasicDataForm from './BasicDataForm';
import SelectTagsModal from './SelectTagsModal';
import PrintFile from './PrintFile';
import ExportInfo from './ExportInfo';
import RenderPddViolationList from './PDDViolations';

const TooltipStyle = {
    tooltip: {
        borderRadius: 8,
        backgroundColor: '#4cad51'
    }
};

const CustomTooltip = withStyles(TooltipStyle)(Tooltip);

const BasicData = ({
    complex_id,
    readOnly = false,
    currentComplex = {},
    loading = false,
    onEdited = () => {},
    onDeleted = () => {},
    isButtonToGk,
    history, // history с карты
    callback, // например закрыть модалку на карте после перехода в события
}) => {
    const dispatch = useDispatch();

    const [editMode, setEditMode] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [isPrintOpen, setOpenPrint] = useState(false);
    const [openTagModal, setOpenTagModal] = useState(false);
    const [commentBeforeDelete, setCommentBeforeDelete] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const types = useStoreProp(loadComplexTypes, 'dorisControl', 'doris_complex_types');
    const unit_types = useStoreProp(loadComplexUnitTypes, 'dorisControl', 'doris_complex_unit_types');
    const model_types = useStoreProp(loadComplexModelTypes, 'dorisControl', 'doris_complex_model_types');
    const complexTransferTypes = useStoreProp(loadComplexTransferTypes, 'dorisControl', 'complexTransferTypes');
    const complexInaccuracy = useStoreProp(loadInaccuracy, 'dorisControl', 'complexInaccuracy');
    // const complex_type_grouping = useStoreProp(loadComplexTypeGrouping, 'dorisControl', 'complex_type_grouping');
    // const complex_measuring = useStoreProp(loadComplexMeasuring, 'dorisControl', 'complex_measuring');
    const complexById = useSelector(dorisControlSelectors.complexById);
    const entities = useSelector(dorisControlSelectors.entities);

    const complexStatuses = useStoreFromSelector(loadComplexStatuses, dorisControlSelectors.doris_complex_statuses);

    useEffect(() => {
        if (complex_id) {
            dispatch(loadComplexEntities({ complex_id }));
        }
    }, [dispatch, complex_id]);

    const currentInaccuracy = complexInaccuracy[currentComplex?.type_id];
    // const organization_id = currentComplex?.organization_id || 0;
    // const currentCompany = useSelector(companiesSelectors.currentCompany);

    // useEffect(() => {
    //     if (organization_id && !currentCompany.hasOwnProperty(organization_id)) {
    //         dispatch(loadCurrentCompany(organization_id));
    //     }
    // }, [organization_id]);

    const complex = { ...currentComplex, ...complexById };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
            @media print {
                .pagebreak {
                    page-break-before: auto;
                }
            }
        `,
        onAfterPrint: () => setOpenPrint(false)
    });

    useEffect(() => {
        if (isPrintOpen) {
            handlePrint();
        }
    }, [isPrintOpen, dispatch, handlePrint]);

    const componentRef = useRef();

    const renderPrintFile = () => {
        return (<div ref={componentRef} style={{ padding: '20px' }}>
            <PrintFile
                currentComplex={complex}
                currentInaccuracy={currentInaccuracy}
                unit_types={unit_types}
                types={types}
                model_types={model_types}
                complexTransferTypes={complexTransferTypes}
                currentCompany={complex.organization}
                organization_id={complex.organization_id}
            />
        </div>);
    };

    const currentButtons = [
        ...(readOnly
            ? []
            : [
                {
                    ...buttonsTypes.editIcon,
                    onClick: () => setEditMode(true),
                },
                {
                    ...buttonsTypes.deleteIcon,
                    onClick: () => setOpenDeleteModal(true)
                },
            ]
        ),
        {
            ...buttonsTypes.printIcon,
            onClick: () => setOpenPrint(true),
        }

    ];

    const currentStatus = useMemo(() => complexStatuses.find(i => i.id === complex?.status) || {}, [complexStatuses, complex?.status]);

    return (
        <div className={ styles.container }>
            {loading && <Loading linear />}
            <Grid container direction="row" spacing={1} justify="center" alignItems="flex-start">
                {Object.keys(complex).length > 0
                    ? <>
                        <Grid item xs={6}>
                            <Typography style={{ color: currentStatus?.color }} variant="body2">
                                {complex
                                    && <>
                                        <strong>Статус: </strong>
                                        {currentStatus?.name || ''}
                                        {!readOnly
                                            && <FormButtons
                                                buttons={[
                                                    {
                                                        ...buttonsTypes.changeStatusIcon,
                                                        onClick: () => setEditStatus(true),
                                                        style: { color: currentStatus.color },
                                                    }
                                                ]}
                                                noMarginLeft
                                                justButton
                                            />
                                        }
                                    </>
                                }
                            </Typography>
                            <Divider style={{ margin: '0.5rem 0' }}/>
                            <div className="filter filter__wrap__btn">
                                <FormButtons
                                    buttons={currentButtons}
                                />
                            </div>

                            {complex?.name && (
                                <Typography variant="body2">
                                    <span><b>Наименование: </b> {complex?.name}</span>
                                </Typography>
                            )}
                            <Typography variant="body2">
                                <span><b>{titles.EXTERNAL_ID}: </b> {complex?.external_id ? 'Есть' : 'Нет'}</span>
                            </Typography>
                            <span>
                                <b>Теги: </b>
                                {complex?.tags?.length > 0
                                    ? <span>
                                        {complex?.tags?.map(item => <Chip key={item.id} label={item.name} size="small"/>)}
                                        <FormButtons
                                            buttons={[
                                                {
                                                    ...buttonsTypes.editIcon,
                                                    onClick: (event) => {
                                                        event.stopPropagation();
                                                        setOpenTagModal(true);
                                                    },
                                                    style: { fontSize: '0.875rem' }
                                                }
                                            ]}
                                            noMarginLeft
                                            justButton
                                        />
                                    </span>
                                    : <FormButtons
                                        buttons={[
                                            {
                                                ...buttonsTypes.addIcon,
                                                name: titles.ADD_TAG,
                                                onClick: () => setOpenTagModal(true),
                                            }
                                        ]}
                                        noMarginLeft
                                        justButton
                                    />
                                }
                            </span>
                            {unit_types[complex?.species_id] && (
                                <Typography variant="body2">
                                    <span><b>Вид: </b> {unit_types[complex?.species_id]}</span>
                                </Typography>
                            )}
                            {types?.length > 0 && (
                                <Typography variant="body2">
                                    <span>
                                        <b>Тип: </b> {types.find(type => type.id === complex?.type_id)?.name}
                                    </span>
                                </Typography>
                            )}
                            {model_types.find(elem => elem?.id === complex?.model_id)?.description && (
                                <Typography variant="body2">
                                    <span><b>Модель: </b> {model_types.find(elem => elem?.id === complex?.model_id)?.description}</span>
                                </Typography>
                            )}
                            {complex?.serial_number && (
                                <Typography variant="body2">
                                    <span><b>Серийный №: </b> {complex?.serial_number}</span>
                                </Typography>
                            )}
                            {complex?.type_grouping_text && (
                                <Typography variant="body2">
                                    <span><b>Тип группировки: </b> {complex?.type_grouping_text}</span>
                                </Typography>
                            )}
                            {complex?.measuring_text && (
                                <Typography variant="body2">
                                    <span><b>Принцип измерения параметров: </b> {complex?.measuring_text}</span>
                                </Typography>
                            )}
                            {complex?.inventory_number && (
                                <Typography variant="body2">
                                    <span><b>Инвентарный номер: </b> {complex?.inventory_number}</span>
                                </Typography>
                            )}
                            {complex?.contract_number && (
                                <Typography variant="body2">
                                    <span><b>Номер оборудования по ГК: </b> {complex?.contract_number}</span>
                                </Typography>
                            )}

                            {complex?.contract_id && (
                                <Typography variant="body2">
                                    <span><b>Номер ГК: </b> {complex?.contract_id}</span>
                                </Typography>
                            )}
                            {complex?.external_id && (
                                <Typography variant="body2">
                                    <span><b>Внешний идентификатор: </b> {complex?.external_id}</span>
                                </Typography>
                            )}

                            {complex?.sign_imgs?.length > 0 && (
                                <RenderPddViolationList violations={complex?.sign_imgs} />
                            )}

                            <Divider style={{ margin: '10px 0' }}/>
                            <Typography style={{ margin: '10px 0' }} variant="body2">
                                <strong>Адрес: </strong>
                                <span>{complex?.address_text}</span>
                            </Typography>
                            {/* <Typography>
                            {complex?.address?.country_iso_code?.toUpperCase() === 'RU'
                            && <span><b>Страна: </b> Россия</span>}
                        </Typography>
                        <Typography>
                            {complex?.address?.region
                            && <span><b>Федеральный округ, область, край: </b> {complex?.address?.region}</span>}
                        </Typography>
                        <Typography>
                            {(complex?.address?.city_name || complex?.address?.settlement)
                            && <span><b>Населённый пункт, город, деревня: </b> {complex?.address?.city_name || complex?.address?.settlement}</span>}
                        </Typography>
                        <Typography>
                            {complex?.address?.area
                            && <span><b>Район: </b> {complex?.address?.area}</span>}
                        </Typography>
                        <Typography>
                            {complex?.address?.district
                            && <span><b>Район города: </b> {complex?.address?.district}</span>}
                        </Typography> */}
                            <Typography variant="body2">
                                <strong>Координаты:</strong>
                            </Typography>
                            <Typography variant="body2">
                                {complex?.lat && <span><b>Широта: </b> {complex?.lat}</span>}
                            </Typography>
                            <Typography variant="body2">
                                {complex?.lon && <span><b>Долгота: </b> {complex?.lon}</span>}
                            </Typography>

                            {complex?.computers?.length > 0
                            && <>
                                <h3>Вычислительные модули:</h3>
                                <List>
                                    {(complex?.computers || []).map(el => (
                                        <ListItem dense key={el.id}>
                                            <Typography variant="body2">
                                                <span><b>Название: </b> {el.name || messages.NO_DATA}, </span>
                                                <span><b>IP адрес: </b> {el?.ip_route || messages.NO_DATA}</span>
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </List>

                                <Divider style={{ margin: '10px 0' }}/>
                            </>
                            }

                            {/* <Typography variant="body2">
                                { complex?.ip_route && <span><b>IP адрес: </b> {complex?.ip_route}</span>}
                            </Typography> */}
                            {/* <Typography variant="body2">
                                <b>Маска подсети: </b>{ complex?.mask || messages.INFO_IS_NOT_FOUND}
                            </Typography> */}
                            <Typography variant="body2">
                                { complex?.data?.transfer_type
                            && <span><b>Источник получения данных: </b> {complexTransferTypes[complex?.data?.transfer_type]}</span> }
                            </Typography>
                            <Divider style={{ margin: '10px 0' }}/>
                            <ExportInfo
                                title={'ДУПЛО'}
                                values={complex?.duplo_export}
                            />
                            <ExportInfo
                                title={'ДОРИС'}
                                values={complex?.doris_export}
                            />
                            {/* <Typography variant="body2">
                                { <span><b>Выгрузка в Дупло: </b> {complex?.data?.export_duplo_enable ? 'да' : 'нет'}</span> }
                            </Typography>
                            <Typography variant="body2">
                                { complex?.data?.export_duplo_url
                            && <span><b>Адрес для выгрузки ДУПЛО: </b> {complex?.data?.export_duplo_url}</span> }
                            </Typography>
                            <Divider style={{ margin: '10px 0' }}/> */}
                            {/* <Typography variant="body2">
                                <span><b>Устройств в комплексе: </b> {complex?.entities?.length}</span>
                            </Typography> */}

                            {/* <Typography variant="body2">
                                <span><b>Разрешенная скорость: </b> {complex?.speed_limit || 0}</span>
                            </Typography> */}

                            {complex.organization
                                && (
                                    <>
                                        <Divider style={{ margin: '10px 0' }}/>

                                        <Typography variant="body2">
                                            <span><b>Балансодержатель (владелец) комплекса: </b> {complex.organization?.title || ''}</span>
                                        </Typography>
                                    </>
                                )
                            }
                        </Grid>
                        <Grid item xs={6}>
                            {complex?.lat && complex?.lon
                            && <MapForm
                                latlon={[complex?.lat, complex?.lon]}
                                onClose={() => {}}
                                readOnly={true}
                                entities={entities || []}
                                permanent={false}
                            />
                            }
                            {/* {complex?.id && <ComparativeStatistics complex_id={complex?.id} />} */}

                            {/* ПОСЛЕДНЕЕ ИЗМЕНЕНИЕ ДАННЫХ */}
                            <Typography variant="body2">
                                {complex?.updated_at
                                    && <span>
                                        <b>Последнее изменение данных: </b>
                                        {fullDateTime(complex?.updated_at)}
                                    </span>}
                            </Typography>
                            <Typography variant="body2">
                                {currentInaccuracy
                                    && <span>
                                        <b>Погрешность: </b>
                                        {currentInaccuracy.map(item => {
                                            return item.value
                                                ? `${item.type} ${item.value}`
                                                : item.description;
                                        })}
                                    </span>
                                }
                            </Typography>
                            <Divider style={{ margin: '10px 0' }}/>

                            {complex?.inspection?.length > 0 && (
                                <>
                                    <Typography variant="body2">
                                        <strong>Документы:</strong>
                                    </Typography>

                                    <Typography variant="body2">
                                        <span><b>Сертификат поверки: </b>
                                            Годен до {fullDateTime(complex?.inspection[0]?.validity)}
                                        </span>
                                    </Typography>
                                </>
                            )}

                            <Typography variant="body2">
                                <span>
                                    <b>Срок эксплуатации: </b>{' '}
                                    {complex?.is_lifetime
                                        ? 'Бессрочно'
                                        : 'На время действия контракта'}
                                </span>
                            </Typography>

                            <Divider style={{ margin: '10px 0' }}/>
                        </Grid>
                        {editMode && (
                            <BasicDataForm
                                isOpen={editMode}
                                onClose={() => setEditMode(false)}
                                item={complex}
                                isNew={false}
                                id={complex_id}
                                onEdited={onEdited}
                                isButtonToGk={isButtonToGk}
                            />
                        )}
                        {editStatus && (
                            <StatusForm
                                isOpen={editStatus}
                                onClose={() => setEditStatus(false)}
                                currentComplex={complex}
                                currentStatus={complex?.status}
                                // complex_statuses={complex_statuses}
                                complex_id={complex_id}
                                onEdited={onEdited}
                            />
                        )}
                        {openTagModal && (
                            <SelectTagsModal
                                open={openTagModal}
                                multiple
                                tagsList={complex?.tags}
                                onClose={() => setOpenTagModal(false)}
                                complexId={ complex_id }
                                onEdited={onEdited}
                            />
                        )}

                        {commentBeforeDelete && (
                            <CommentAction
                                title={titles.COMMENT_DELETE}
                                inputLabel={'Укажите причину удаления'}
                                open={commentBeforeDelete}
                                onClose={() => {
                                    setCommentBeforeDelete(false);
                                    setOpenDeleteModal(false);
                                }}
                                onAccept={(_comment) => {
                                    dispatch(deleteComplex(complex_id, { _comment }, onDeleted ));
                                }}
                            />
                        )}

                        {openDeleteModal && (
                            <ConfirmDelete
                                open={openDeleteModal}
                                onClose={() => setOpenDeleteModal(false)}
                                onSuccess={() => setCommentBeforeDelete(true)}
                            />
                        )}
                    </>
                    : !loading && <div>Комплекс не найден</div>
                }
            </Grid>
            <div style={{ height: 0, overflow: 'hidden' }}>{renderPrintFile()}</div>
        </div>
    );

};

export default BasicData;
