import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControlLabel,
    Checkbox,
} from '@mui/material';

import { fullDateTimeWithTimeZone, getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import messages from 'helpers/constants/messages/common';
import Loading from 'components/common/Loading';
import HeatLayer from 'components/MapComponents/leaflet/components/HeatLayer';
import {
    Map,
    MapControl,
    FeatureGroup,
} from 'components/MapComponents/leaflet';
import PageLayout from 'components/layout/PageLayout';
import { focusIncidentsSelectors } from 'redux/FocusIncidents';
import { loadHeatMap, } from 'redux/FocusIncidents/actions';
import { config } from 'config';

import DtpFocus from './layerHeatMap';
import Filter from './Filter';

// тепловая карта мкдтп
const HeatMap = () => {
    const dispatch = useDispatch();
    const [showLayer, setShowLayer] = useState(false);
    const heatMapData = useSelector(focusIncidentsSelectors.heatMap);
    const loadingHeatMap = useSelector(focusIncidentsSelectors.loadingHeatMap);
    const polygonLoading = useSelector(focusIncidentsSelectors.polygonLoading);

    const initialState = {
        start_date: getStartOf('day', getDateWithDuration({ month: -1 }, )),
        end_date: getEndOf('minute'),
        type: 2,
        checked: false,
    };

    const [data, setData] = useState(initialState);

    const getFilters = (filters = data) => {
        return removeEmptyFields({
            start_date: filters.start_date ? fullDateTimeWithTimeZone(filters.start_date) : null,
            end_date: filters.end_date ? fullDateTimeWithTimeZone(filters.end_date) : null,
            type: filters.type,
            status: filters.checked ? [ 3 ] : [],
            checked: filters.checked || false
        });
    };

    const resetFilter = () => {
        const initialFilter = getFilters(initialState);
        dispatch(loadHeatMap(initialFilter));
    };
    const loadData = (filter) => {
        const newFilter = getFilters(filter);
        dispatch(loadHeatMap(newFilter));
    };


    const renderContent = () => (
        <>
            {/* если делать лоадинг linear происходит бесконечная загрузка полигона т.к. карта всё время смещается */}
            {(loadingHeatMap || polygonLoading) && <Loading circular />}
            {heatMapData.length > 0
                ? (
                    <div style={{ display: 'contents', minHeight: '400px' }}>
                        <Map
                            center={config.get('mapCenter')}
                        >
                            <MapControl>
                                <FeatureGroup>
                                    {/* тепловая */}
                                    <HeatLayer
                                        json={heatMapData}
                                    />

                                    {/* слой */}
                                    {showLayer
                                        && (
                                            <DtpFocus
                                                readOnly
                                                maxClusterRadius={10}
                                            />
                                        )
                                    }
                                </FeatureGroup>
                            </MapControl>
                        </Map>
                    </div>
                )
                : (<>{!loadingHeatMap && <div>{messages.NO_DATA_FOR_PERIOD}</div>}</>)
            }
        </>
    );

    return (
        <PageLayout
            header="Тепловая карта по МКДТП"
            filters={
                <Filter
                    onFilter={loadData}
                    onReset={resetFilter}
                    data={data}
                    setData={setData}
                    initialState={initialState}
                />
            }
            actionPanel={
                <FormControlLabel
                    control={
                        <Checkbox
                            onClick={(e) => setShowLayer(e.target.checked)}
                            checked={showLayer}
                            size="small"
                        />
                    }
                    label="Показать иконки МКДТП"
                />
            }
            content={renderContent}
            customStyles={{
                overflow: 'auto',
                padding: 0,
                background: 'transparent'
            }}
        />
    );
};

export default HeatMap;
