import DateFilter from './DateFilter/DateFilter';
import Loading from '../../../../common/Loading';
import { getFullDateTimeWithTextMonth } from '../../../../../helpers/date.config';

const Item = (props) => {
    const {
        name,
        address_text,
        updated_at,
        onChangeDateHistory,
        date,
        loading,
        onClick,
    } = props;

    return (
        <>
            <div onClick={onClick}>
                <div className="description">
                    {name}&nbsp;
                </div>
                <div
                    className="address"
                    style={{
                        fontWeight: 700
                    }}
                >
                    {address_text}
                </div>
                <div className="date">
                    {updated_at ? getFullDateTimeWithTextMonth(updated_at) : ''}
                </div>
            </div>

            <DateFilter
                date={date}
                onChange={(newDate) => onChangeDateHistory({ date: newDate })}
            />

            {loading && <Loading className="absolute bottom fill" />}
        </>
    );
};

export default Item;
