import moduleName from './module';

const prefix = `${moduleName}`;

/**
 * Constants
 * */
// map filter and active
export const SET_FILTER = `${prefix}/SET_FILTER`;
export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;

//sidebar
export const LOAD_SIDEBAR = `${prefix}/LOAD_SIDEBAR`;
export const LOADED_SIDEBAR = `${prefix}/LOADED_SIDEBAR`;
export const LOADING_SIDEBAR = `${prefix}/LOADING_SIDEBAR`;
export const CLEAR_SIDEBAR = `${prefix}/CLEAR_SIDEBAR`;

//ws
export const SET_WS_DATA = `${prefix}/SET_WS_DATA`;
export const CLEAR_WS_DATA = `${prefix}/CLEAR_WS_DATA`;

// load coords
export const LOADED_COORDS = `${prefix}/LOADED_COORDS`;
export const LOADING_COORDS = `${prefix}/LOADING_COORDS`;
export const CLEAR_COORDS = `${prefix}/CLEAR_COORDS`;
export const LOAD_COORDS = `${prefix}/LOAD_COORDS`;

// all vehicles
export const LOAD_ALL_VEHICLES = `${prefix}/LOAD_ALL_VEHICLES`;
export const LOADED_ALL_VEHICLES = `${prefix}/LOADED_ALL_VEHICLES`;
export const LOADING_ALL_VEHICLES = `${prefix}/LOADING_ALL_VEHICLES`;
export const CLEAR_ALL_VEHICLES = `${prefix}/CLEAR_ALL_VEHICLES`;

export const LOAD_TYPES = `${prefix}/LOAD_TYPES`;
export const LOADED_TYPES = `${prefix}/LOADED_TYPES`;

// history
export const LOAD_HISTORY_VEHICLE = `${prefix}/LOAD_HISTORY_VEHICLE`;
export const LOADED_HISTORY_VEHICLE = `${prefix}/LOADED_HISTORY_VEHICLE`;
export const LOADING_HISTORY_VEHICLE = `${prefix}/LOADING_HISTORY_VEHICLE`;
export const CLEAR_HISTORY_VEHICLE = `${prefix}/CLEAR_HISTORY_VEHICLE`;

export const SET_ROUTES_VEHICLE = `${prefix}/SET_ROUTES_VEHICLE`;
export const CLEAR_ROUTES_VEHICLE = `${prefix}/CLEAR_ROUTES_VEHICLE`;
export const CLEAR_ROUTES_VEHICLE_BY_ID = `${prefix}/CLEAR_ROUTES_VEHICLE_BY_ID`;

export const SET_MARKERS = `${prefix}/SET_MARKERS`;
export const SET_SIDEBAR_PARAMS = `${prefix}/SET_SIDEBAR_PARAMS`;
export const SET_VEHICLES_WS = `${prefix}/SET_VEHICLES_WS`;

/* color speed */
export const LOAD_COLOR_SPEED_LIST = `${prefix}LOAD_COLOR_SPEED_LIST`;
export const LOADED_COLOR_SPEED_LIST = `${prefix}LOADED_COLOR_SPEED_LIST`;
export const LOADING_COLOR_SPEED_LIST = `${prefix}LOADING_COLOR_SPEED_LIST`;
export const SET_COLOR_SPEED_LIST_PROPS = `${prefix}SET_COLOR_SPEED_LIST_PROPS`;
export const RESET_COLOR_SPEED_LIST_PROPS = `${prefix}RESET_COLOR_SPEED_LIST_PROPS`;
export const ADD_COLOR_SPEED = `${prefix}ADD_COLOR_SPEED`;
export const EDIT_COLOR_SPEED = `${prefix}EDIT_COLOR_SPEED`;
export const DELETE_COLOR_SPEED = `${prefix}DELETE_COLOR_SPEED`;

export const LOAD_VEHICLE = `${prefix}LOAD_VEHICLE`;
export const LOADED_VEHICLE_ACTIVE = `${prefix}LOADED_VEHICLE_ACTIVE`;
export const LOAD_CATEGORIES_VEHICLE = `${prefix}LOAD_CATEGORIES_VEHICLE`;
export const LOADED_CATEGORIES_VEHICLE = `${prefix}LOADED_CATEGORIES_VEHICLE`;

export const LOAD_VEHICLE_HISTORY_MODAL = `${prefix}LOAD_VEHICLE_HISTORY_MODAL`;
export const LOADED_VEHICLE_HISTORY_MODAL = `${prefix}LOADED_VEHICLE_HISTORY_MODAL`;
export const LOADING_VEHICLE_HISTORY_MODAL = `${prefix}LOADING_VEHICLE_HISTORY_MODAL`;

export const LOAD_FIND_VEHICLES = `${prefix}LOAD_FIND_VEHICLES`;
export const LOADED_FIND_VEHICLES = `${prefix}LOADED_FIND_VEHICLES`;
export const LOADING_FIND_VEHICLES = `${prefix}LOADING_FIND_VEHICLES`;
export const SHOW_FIND_VEHICLES = `${prefix}SHOW_FIND_VEHICLES`;

export const LOAD_BRANDS = `${prefix}/LOAD/BRANDS`;
export const LOADED_BRANDS = `${prefix}/LOADED/BRANDS`;
export const LOADING_BRANDS = `${prefix}/LOADING_BRANDS`;

export const CREATE_BRAND = `${prefix}/CREATE/BRAND`;
export const EDIT_BRAND = `${prefix}/EDIT/BRAND`;
export const DELETE_BRAND = `${prefix}/DELETE/BRAND`;

export const LOAD_MODELS = `${prefix}/LOAD/MODELS`;
export const LOADED_MODELS = `${prefix}/LOADED/MODEL`;
export const LOADING_MODELS = `${prefix}/LOADING_MODELS`;
export const CREATE_MODEL = `${prefix}/CREATE/MODEL`;
export const EDIT_MODEL = `${prefix}/EDIT/MODEL`;
export const DELETE_MODEL = `${prefix}/DELETE/MODEL`;

export const LOAD_COMPANIES = `${prefix}/LOAD/COMPANIES`;
export const LOADED_COMPANIES = `${prefix}/LOADED/COMPANY`;
export const LOADING_COMPANIES = `${prefix}/LOADING_COMPANIES`;

export const CREATE_COMPANY = `${prefix}/CREATE/COMPANY`;
export const EDIT_COMPANY = `${prefix}/EDIT/COMPANY`;
export const DELETE_COMPANY = `${prefix}/DELETE/COMPANY`;

export const DELETE_VEHICLE = `${prefix}/VEHICLE/DELETE`;
export const CREATE_VEHICLE = `${prefix}/VEHICLE/CREATE`;
export const EDIT_VEHICLE = `${prefix}/VEHICLE/EDIT`;
export const LOADED_VEHICLES_LIST = `${prefix}/VEHICLES/LOADED`;
export const LOAD_VEHICLES_LIST = `${prefix}/VEHICLES/LOAD`;
export const LOADING_VEHICLES_LIST = `${prefix}/VEHICLES/LOADING`;

export const LOAD_VEHICLES_TYPES = `${prefix}/VEHICLES/TYPES/LOAD`;
export const LOADED_VEHICLES_TYPES = `${prefix}/VEHICLES/TYPES/LOADED`;
export const LOAD_VEHICLES_CATEGORIES = `${prefix}/VEHICLES/CATEGORIES/LOAD`;
export const LOADED_VEHICLES_CATEGORIES = `${prefix}/VEHICLES/CATEGORIES/LOADED`;

export const LOAD_VEHICLE_CATEGORY_LIST = `${prefix}/VEHICLE/CATEGORY/LIST/LOAD`;
export const LOADED_VEHICLE_CATEGORY_LIST = `${prefix}/VEHICLE/CATEGORY/LIST/LOADED`;
export const LOADING_VEHICLE_CATEGORY_LIST = `${prefix}/VEHICLE/CATEGORY/LIST/LOADING`;
export const CREATE_VEHICLE_CATEGORY = `${prefix}/VEHICLE/CATEGORY/CREATE`;
export const EDIT_VEHICLE_CATEGORY = `${prefix}/VEHICLE/CATEGORY/EDIT`;
export const DELETE_VEHICLE_CATEGORY = `${prefix}/VEHICLE/CATEGORY/DELETE`;

// отчет по транспортным средствам
export const LOAD_VEHICLE_REPORT = `${prefix}/LOAD/VEHICLE/REPORT`;
export const LOADED_VEHICLE_REPORT = `${prefix}/LOADED/VEHICLE/REPORT`;
export const LOADING_VEHICLE_REPORT = `${prefix}/LOADING/VEHICLE/REPORT`;
export const SET_VEHICLE_REPORT_FILTER = `${prefix}/SET/VEHICLE/REPORT/FILTER`;

// employees
export const LOAD_EMPLOYEES = `${prefix}/LOAD_EMPLOYEES`;
export const LOADING_EMPLOYEES = `${prefix}/LOADING_EMPLOYEES`;
export const LOADED_EMPLOYEES = `${prefix}/LOADED_EMPLOYEES`;
export const EDIT_EMPLOYEES = `${prefix}/EDIT_EMPLOYEES`;
export const ADD_EMPLOYEES = `${prefix}/ADD_EMPLOYEES`;
export const DELETE_EMPLOYEES = `${prefix}/DELETE_EMPLOYEES`;
// positions
export const LOAD_POSITIONS = `${prefix}/LOAD_POSITIONS`;
export const LOADING_POSITIONS = `${prefix}/LOADING_POSITIONS`;
export const LOADED_POSITIONS = `${prefix}/LOADED_POSITIONS`;
export const EDIT_POSITIONS = `${prefix}/EDIT_POSITIONS`;
export const ADD_POSITIONS = `${prefix}/ADD_POSITIONS`;
export const DELETE_POSITIONS = `${prefix}/DELETE_POSITIONS`;
// brigades
export const LOAD_BRIGADES = `${prefix}/LOAD_BRIGADES`;
export const LOADING_BRIGADES = `${prefix}/LOADING_BRIGADES`;
export const LOADED_BRIGADES = `${prefix}/LOADED_BRIGADES`;
export const EDIT_BRIGADES = `${prefix}/EDIT_BRIGADES`;
export const ADD_BRIGADES = `${prefix}/ADD_BRIGADES`;
export const DELETE_BRIGADES = `${prefix}/DELETE_BRIGADES`;

export const LOAD_PARKS = `${prefix}/LOAD_PARKS`;
export const LOADING_PARKS = `${prefix}/LOADING_PARKS`;
export const LOADED_PARKS = `${prefix}/LOADED_PARKS`;
export const CREATE_PARK = `${prefix}/CREATE_PARK`;
export const EDIT_PARK = `${prefix}/EDIT_PARK`;
export const DELETE_PARK = `${prefix}/DELETE_PARK`;

//Показ данных по простою
export const LOAD_VEHICLE_DOWNTIME = `${prefix}LOAD/VEHICLE/DOWNTIME`;
export const LOADED_VEHICLE_DOWNTIME = `${prefix}LOADED/VEHICLE/DOWNTIME`;

export const GET_REPORT_FUEL = `${prefix}GET_REPORT_FUEL`;
export const LOADING_REPORT_FUEL = `${prefix}LOADING_REPORT_FUEL`;
export const LOADED_REPORT_FUEL = `${prefix}LOADED_REPORT_FUEL`;

export const LOAD_ROUTES = `${prefix}/LOAD/ROUTES`;
export const LOADED_ROUTES = `${prefix}/LOADED/ROUTES`;
export const LOADING_ROUTES = `${prefix}/LOADING/ROUTES`;
export const SAVE_ROUTE = `${prefix}/SAVE/ROUTE`;
export const DELETE_ROUTE = `${prefix}/DELETE/ROUTE`;
export const CHANGE_ROUTE = `${prefix}/CHANGE_ROUTE`;
export const LOAD_ROUTE_TYPES = `${prefix}/LOAD/TYPES`;
export const LOADED_ROUTE_TYPES = `${prefix}/LOADED/TYPES`;
export const LOAD_ROUTE_STATUSES = `${prefix}/LOAD/ROUTE/STATUSES`;
export const LOADED_ROUTE_STATUSES = `${prefix}/LOADED/ROUTE/STATUSES`;
export const LOAD_ROUTE_CHECKPOINTS = `${prefix}/LOAD/ROUTE/CHECKPOINTS`;
export const LOADING_ROUTE_CHECKPOINTS = `${prefix}/LOADING/ROUTE/CHECKPOINTS`;
export const LOADED_ROUTE_CHECKPOINTS = `${prefix}/LOADED/ROUTE/CHECKPOINTS`;
export const SAVE_CHECKPOINTS_TO_ROUTE = `${prefix}/SAVE/CHECKPOINTS/TO/ROUTE`;

export const LOAD_COLOR_SPEED = `${prefix}LOAD_COLOR_SPEED`;
export const LOADED_COLOR_SPEED = `${prefix}LOADED_COLOR_SPEED`;

export const LOAD_TELEMETRY_RECEIVERS = `${prefix}LOAD_TELEMETRY_RECEIVERS`;
export const LOADED_TELEMETRY_RECEIVERS = `${prefix}LOADED_TELEMETRY_RECEIVERS`;
export const LOADING_TELEMETRY_RECEIVERS = `${prefix}LOADING_TELEMETRY_RECEIVERS`;
export const ADD_TELEMETRY_RECEIVER = `${prefix}ADD_TELEMETRY_RECEIVER`;
export const EDIT_TELEMETRY_RECEIVER = `${prefix}EDIT_TELEMETRY_RECEIVER`;
export const DELETE_TELEMETRY_RECEIVER = `${prefix}DELETE_TELEMETRY_RECEIVER`;

export const LOAD_TELEMETRY_RECEIVER_TYPES = `${prefix}LOAD_TELEMETRY_RECEIVER_TYPES`;
export const LOADED_TELEMETRY_RECEIVER_TYPES = `${prefix}LOADED_TELEMETRY_RECEIVER_TYPES`;

//Workorders
export const LOAD_WORKORDERS = `${prefix}/LOAD/WORKORDERS`;
export const LOADING_WORKORDERS = `${prefix}/LOADING/WORKORDERS`;
export const LOADED_WORKORDERS = `${prefix}/LOADED/WORKORDERS`;
export const LOAD_CURRENT_WORKORDER = `${prefix}/LOAD/CURRENT/WORKORDER`;
export const LOADING_CURRENT_WORKORDER = `${prefix}/LOADING/CURRENT/WORKORDER`;
export const LOADED_CURRENT_WORKORDER = `${prefix}/LOADED/CURRENT/WORKORDER`;
export const CLEAR_WORKORDERS = `${prefix}/CLEAR/WORKORDERS`;
export const CREATE_WORKORDER = `${prefix}/CREATE/WORKORDER`;
export const EDIT_WORKORDER = `${prefix}/EDIT/WORKORDER`;
export const DELETE_WORKORDER = `${prefix}/DELETE/WORKORDER`;
export const SET_SELECTED_WORKORDER_DATA = `${prefix}/SET/SELECTED/WORKORDER/DATA`;
export const LOAD_WORKORDERS_BY_DATE = `${prefix}/LOAD/WORKORDERS/BY/DATE`;
export const LOADING_WORKORDERS_BY_DATE = `${prefix}/LOADING/WORKORDERS/BY/DATE`;
export const LOADED_WORKORDERS_BY_DATE = `${prefix}/LOADED/WORKORDERS/BY/DATE`;
export const CLEAR_WORKORDERS_BY_DATE = `${prefix}/CLEAR/WORKORDERS/BY/DATE`;
export const LOAD_WORKORDER_STATUSES = `${prefix}/LOAD/WORKORDER/STATUSES`;
export const LOADED_WORKORDER_STATUSES = `${prefix}/LOADED/WORKORDER/STATUSES`;

export const LOAD_WORKORDER_LOGS = `${prefix}/LOAD_WORKORDER_LOGS`;
export const LOADING_WORKORDER_LOGS = `${prefix}/LOADING_WORKORDER_LOGS`;
export const LOADED_WORKORDER_LOGS = `${prefix}/LOADED_WORKORDER_LOGS`;

export const LOAD_WORKORDER_LOG_MANUAL_STATUS = `${prefix}LOAD_WORKORDER_LOG_MANUAL_STATUS`;
export const LOADED_WORKORDER_LOG_MANUAL_STATUS = `${prefix}LOADED_WORKORDER_LOG_MANUAL_STATUS`;
export const ADD_WORKORDER_LOG_MANUAL_STATUS = `${prefix}ADD_WORKORDER_LOG_MANUAL_STATUS`;
export const EDIT_WORKORDER_LOG_MANUAL_STATUS = `${prefix}EDIT_WORKORDER_LOG_MANUAL_STATUS`;
export const DELETE_WORKORDER_LOG_MANUAL_STATUS = `${prefix}DELETE_WORKORDER_LOG_MANUAL_STATUS`;

export const LOAD_CHECK_POINTS = `${prefix}LOAD_CHECK_POINTS`;
export const LOADED_CHECK_POINTS = `${prefix}LOADED_CHECK_POINTS`;
export const LOADING_CHECK_POINTS = `${prefix}LOADING_CHECK_POINTS`;
export const CREATE_CHECK_POINT = `${prefix}CREATE_CHECK_POINT`;
export const EDIT_CHECK_POINT = `${prefix}EDIT_CHECK_POINT`;
export const DELETE_CHECK_POINT = `${prefix}DELETE_CHECK_POINT`;

export const LOAD_REPORT_BY_VEHICLES = `${prefix}/LOAD_REPORT_BY_VEHICLES`;
export const LOADED_REPORT_BY_VEHICLES = `${prefix}/LOADED_REPORT_BY_VEHICLES`;
export const CLEAR_REPORT_BY_VEHICLES = `${prefix}/CLEAR_REPORT_BY_VEHICLES`;
export const LOADING_REPORT_BY_VEHICLES = `${prefix}/LOADING_REPORT_BY_VEHICLES`;
export const SET_HISTORY_OPTION = `${prefix}SET_HISTORY_OPTION`;

export const LOAD_CHECK_POINT_POLYGON = `${prefix}LOAD_CHECK_POINT_POLYGON`;
export const LOADED_CHECK_POINT_POLYGON = `${prefix}LOADED_CHECK_POINT_POLYGON`;

export const CHANGE_ROUTE_STATUS = `${prefix}/CHANGE_ROUTE_STATUS`;
export const LOADING_CHANGE_ROUTE_STATUS = `${prefix}/LOADING_CHANGE_ROUTE_STATUS`;

export const LOAD_VEHICLE_STATISTICS = `${prefix}/LOAD_VEHICLE_STATISTICS`;
export const LOADING_VEHICLE_STATISTICS = `${prefix}/LOADING_VEHICLE_STATISTICS`;
export const LOADED_VEHICLE_STATISTICS = `${prefix}/LOADED_VEHICLE_STATISTICS`;

export const LOAD_WORKORDER_STATISTICS = `${prefix}/LOAD_WORKORDER_STATISTICS`;
export const LOADING_WORKORDER_STATISTICS = `${prefix}/LOADING_WORKORDER_STATISTICS`;
export const LOADED_WORKORDER_STATISTICS = `${prefix}/LOADED_WORKORDER_STATISTICS`;


export const SET_FILTER_WS_ORGANIZATIONS = `${prefix}/SET_FILTER_WS_ORGANIZATIONS`;

export const LOAD_EGTS_TRANSMITTER = `${prefix}/LOAD_EGTS_TRANSMITTER`;
export const ADD_EGTS_TRANSMITTER = `${prefix}/ADD_EGTS_TRANSMITTER`;
export const EDIT_EGTS_TRANSMITTER = `${prefix}/EDIT_EGTS_TRANSMITTER`;
export const DELETE_EGTS_TRANSMITTER = `${prefix}/DELETE_EGTS_TRANSMITTER`;
