import * as types from './types';

const initialState = {
    active: {}, // активный элемент страницы
    availableChannels: {}, // список доступных каналов
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }

        case types.LOADED_WS_AVAILABLE_CHANNELS: {
            return {
                ...state,
                availableChannels: payload,
            };
        }

        default:
            return state;
    }
}
