import React from 'react';
import MaskedInput from 'react-text-mask';
// import PropTypes from 'prop-types';

// TODO Исправить поведение. Возможно, заменить react-text-mask на что-то другое
const TextMaskPhone = React.forwardRef((props, inputRef) => (
    <MaskedInput
        mask={value => value[0] === '+'
            ? [
                '+',
                /[1-9]/,
                ' ',
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/
            ]
            :[
                '+',
                '7',
                ' ',
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/
            ]
        }
        {...props}
        ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
        }}
        placeholder="+7(XXX) XXX-XX-XX"
    />
));

// TextMaskPhone.propTypes = {
//     inputRef: PropTypes.func.isRequired,
// };

export default TextMaskPhone;
