import React, { useEffect, useRef, useState } from 'react';
import {
    Card,
    CardContent,
    CardActions,
    Button,
} from '@mui/material';
import styles from './styles.module.scss';
import cn from 'classnames';

// виджет блока с информацией
const CardWidget = ({
    icon, // иконка
    title, // заголовок
    children,
    onClick,
    // buttons = [], // кнопки
    fill = false, // заполнить по высоте на 100%
    fillOthers = false, // заполнить оставшее место
    marginBottom = false, // отступ снизу
    small = false,
    renderContent,
    noPadding = false,
}) => {
    const cardRef = useRef(null);
    const [cardSize, setCardSize] = useState({});

    useEffect(() => {
        if (cardRef.current) {
            setCardSize({
                height: cardRef.current?.clientHeight,
                width: cardRef.current?.clientWidth,
            });
        }
    }, [cardRef]);
    
    return (
        <Card
            className={cn({
                [styles.card]: true,
                [styles.marginBottom]: marginBottom,
                [styles.fillOthers]: fillOthers,
                [styles.fill]: fill,
            })}
            ref={cardRef}
        >
            {title && icon 
            && <div
                className={cn({ [styles.cardHeader]: true, [styles.small]: small, [styles.onClick]: !!onClick })}
                onClick={onClick}
            >
                <span className={cn(styles.icon, { [styles.smallIcon]: small })}>
                    {icon && <i className={icon} /> }
                </span>
                <span>{title}</span>
            </div>
            }
            <CardContent
                className={cn({
                    [styles.cardContent]: true,
                    [styles.noPadding]: noPadding,
                })}
            >
                {renderContent && renderContent({ styles: cardSize, displayLabels: true })}
                {children && children}
            </CardContent>
            {/*{buttons.length > 0*/}
            {/*&& (*/}
            {/*    <CardActions>*/}
            {/*        {buttons.map((item, index) => (*/}
            {/*            <Button size="small" key={index} {...item}>{item.name}</Button>*/}
            {/*        ))}*/}
            {/*    </CardActions>*/}
            {/*)}*/}
        </Card>
    );
};

export default CardWidget;