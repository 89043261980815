import { useState } from 'react';

import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import buttons from 'helpers/constants/buttons';

import GroupAndWorksModalItem from './GroupAndWorksModalItem';

const GroupAndWorksModal = ({
    workData,
    open,
    onClose,
    handleClickSave
}) => {
    const [data, setData] = useState(workData);

    const handleClickDelete = (index) => {
        const newDataArray = [
            ...data.slice(0, index),
            ...data.slice(index + 1),
        ];
        setData(newDataArray);
    };

    const onChangeData = (value, index) => {
        // проверяем есть ли уже такая работа в списке, чтобы добавить ошибку
        const addedIndex = data.findIndex(obj => value.work.id && obj.work.id === value.work.id && obj.work.road_work_group_id === value.work.road_work_group_id);
        const newValue = {
            ...value,
            ...((addedIndex >= 0 && addedIndex !== index )&& { error: 'Такая работа уже есть в списке' }),
        };

        const newDataArray = [
            ...data.slice(0, index),
            newValue,
            ...data.slice(index + 1),
        ];
        setData(newDataArray);
    };

    const handleAddData = () => {
        const newDataArray = [
            ...data,
            { group: {}, work: {}, units: {} }
        ];
        setData(newDataArray);
    };

    const onSubmit = () => {
        handleClickSave(data);
    };

    // блокируем кнопку сохранить если не заполнены поля
    const checkEmptyFields = () => data.reduce((res, el) => {
        if (!el.group || Object.keys(el.group).length === 0
            || !el.work || Object.keys(el.work).length === 0
            || !el.units || Object.keys(el.units).length === 0
            || el.error) {
            res = true;
        };
        return res;
    }, false);

    return (
        <>
            <Modal
                isOpen={open}
                onClose={onClose}
                title={'Добавление/Редактирование Групп и работ'}
                buttons={
                    <FormButtons
                        buttons={
                            [
                                {
                                    ...buttonsTypes.cancel,
                                    name: buttons.CANCEL,
                                    onClick: onClose
                                },
                                {
                                    ...buttonsTypes.save,
                                    name: buttons.SAVE,
                                    onClick: onSubmit,
                                    disabled: checkEmptyFields()
                                },
                            ]
                        }
                    />
                }
            >
                <div className="filter">
                    {data.map((item, index) =>
                        <GroupAndWorksModalItem
                            key={index}
                            item={item}
                            onChangeData={(val) => onChangeData(val, index)}
                            handleClickDelete={() => handleClickDelete(index)}
                        />
                    )}
                    <FormButtons
                        positionLeft
                        buttons={
                            [
                                {
                                    ...buttonsTypes.add,
                                    onClick: handleAddData,
                                    accessProp: 'is_read', // костыль пока для нестандартных прав когда на всю страницу нет прав
                                },
                            ]
                        }
                    />
                </div>
            </Modal>
        </>

    );
};

export default GroupAndWorksModal;
