import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadRepairObjects } from 'redux/RoadWorks/actions';
import titles from 'helpers/constants/titles';
import buttonsTypes from 'components/common/FormButtons/buttonsTypes';
import FormButtonsComponent from 'components/common/FormButtons';
import UniversalSelect from 'components/common/UniversalSelect';
import { LSContainer, LSHeaderColumn, LSHeaderItem } from 'components/common/ListStyle';

import RepairObjectsItem from './RepairObjectsItem';

const RepairObjects = ({ repairObjects, setData }) => {
    const dispatch = useDispatch();
    const [openAdd, setOpenAdd] = useState(false);

    const repairObjsIds = useMemo(() => repairObjects.reduce((res, el) => {
        res[el.repair_object_id] = el;
        return res;
    },{}), [repairObjects]);

    const addRepairObject = (objs) => {
        const newData = objs.map(el => (
            repairObjsIds[el.id] || {
                repair_object_id: el.id,
                count: 1,
                data: el
            }
        ));
        setData((prev) => ({ ...prev, repair_objects: newData }));
        setOpenAdd(false);
    };

    const handleEdit = (index, item, event) => {
        const { name, value } = event.target;

        const newData = [
            ...repairObjects.slice(0, index),
            { ...item, [name]: value },
            ...repairObjects.slice(index + 1)
        ];

        setData((prev) => ({ ...prev, repair_objects: newData }));
    };

    const handleDelete = (index) => {
        const newData = [
            ...repairObjects.slice(0, index),
            ...repairObjects.slice(index + 1),
        ];

        setData((prev) => ({ ...prev, repair_objects: newData }));
    };

    const getRepairObjectsList = (params) => {
        const { page, limit, query: name } = params;

        dispatch(loadRepairObjects(page, limit, {
            ...(name && { name }),
        }));
    };

    return (
        <>
            <h2>Присвоенные объекты ремонтов:</h2>

            {repairObjects?.length > 0
                && <LSContainer>
                    <LSHeaderItem fullWidth>
                        <LSHeaderColumn width="7%">{'№'}</LSHeaderColumn>
                        <LSHeaderColumn width="11%">{'ID'}</LSHeaderColumn>
                        <LSHeaderColumn width="30%">{titles.NAME}</LSHeaderColumn>
                        <LSHeaderColumn width="20%">{titles.COUNT}</LSHeaderColumn>
                        <LSHeaderColumn width="12%">{'Ед. изм.'}</LSHeaderColumn>
                        <LSHeaderColumn align="right" isActions>Действия</LSHeaderColumn>
                    </LSHeaderItem>
                    {repairObjects?.map((item, index) => (
                        <RepairObjectsItem
                            key={index}
                            index={index}
                            item={item}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    ))}
                </LSContainer>
            }

            <div style={{ marginTop: 10 }}>
                <FormButtonsComponent
                    className="form__button"
                    justButton
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenAdd(true),
                        }
                    ]}
                />
            </div>

            {openAdd
                && <UniversalSelect
                    multiple
                    fetchList={getRepairObjectsList}
                    storeName="roadworks"
                    storeNameProps="repairObjects"
                    storeLoadingProps="loadingRepairObjects"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={repairObjects.map(el => el.data)}
                    renderProps={(el) => (
                        <div>
                            <div><b>ID:</b> {el.id}</div>
                            <div><b>Название:</b> {el.name}</div>
                        </div>
                    )}
                    isOpen={openAdd}
                    onClose={() => setOpenAdd(false)}
                    onAccept={addRepairObject}
                    noPadding
                    title={'Типовые объекты ремонта'}
                    small
                />
            }
        </>
    );
};

export default RepairObjects;
