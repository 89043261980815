import { useState, useRef, useEffect } from 'react';
import {
    Collapse,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';

import TableHeader from './TableHeader';
import ItemContent from './EventItemContent';

const EventItem = ({
    item,
    index,
    workOrder,
    // последнее событие передается из тс на карте - далее переходит плавно к нему
    work_order_event_id
}) => {
    const myRef = useRef(null);

    // поиск данного события для перехода из тс
    const isOpenDefault = item?.entity_list?.findIndex?.(el => el?.id === work_order_event_id) >= 0;
    const [openEntityList, setOpenEntityList] = useState(isOpenDefault || false);

    // реализация плавного перехода к элементу (по центру)
    useEffect(() => {
        if (isOpenDefault && myRef.current) {
            myRef.current.scrollIntoView({
                behavior: 'smooth', // плавный переход
                block: 'center' // по центру контента
            });
        }
    }, [isOpenDefault, myRef.current]);

    return (
        <>
            <ListItem
                divider
                dense
            >
                <ListItemText disableTypography>
                    <ItemContent
                        item={item}
                        index={index}
                        openEntityList={openEntityList}
                        setOpenEntityList={setOpenEntityList}
                        workOrder={workOrder}
                    />

                </ListItemText>
            </ListItem>
            {(item?.entity_list?.length > 0 && openEntityList) && (
                <Collapse className="info" style={{ padding: '0.5rem 1rem' }} in={openEntityList} timeout={'auto'}>
                    <List className="list">
                        <TableHeader isStations />
                        {item?.entity_list.map((el, i) => {
                            const isCurrent = el.id === work_order_event_id;
                            return (
                                <ListItem
                                    divider
                                    dense
                                    key={el?.id}
                                    // делаем ref для активного элемента при переходе из тс -> далее скрол
                                    {...(isCurrent ? { ref: myRef } : {})}
                                >
                                    <ItemContent
                                        index={i}
                                        item={el}
                                        isSelected={isCurrent}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </Collapse>
            )}
        </>
    );
};

export default EventItem;
