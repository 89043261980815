const stationTitles = {
    ADD_STATIONS: 'Добавить остановки',
    ADD_CHECKPOINTS: 'Добавить контрольные точки',
    EDIT_STATIONS_AND_CHECK_POINTS: 'Редактировать остановки и контрольные точки',
    ADD_STATION: 'Добавить остановку',
    EDIT_STATION: 'Редактировать остановку',
    STATIONS: 'Остановки',
    CHECK_POINTS: 'Контрольные точки',
    STATION: 'Остановка',
    CHECK_POINT: 'Контрольная точка',
    STATIONS_AND_CHECK_POINTS: 'Остановки и контрольные точки',
    MILEAGE_FROM_STATION: 'Пробег от предыдущей остановки',
    INTERVAL_FROM_STATION: 'Интервал от предыдущей остановки',
    MILEAGE: 'Пробег',
    INTERVAL: 'Интервал',
};

export default stationTitles;