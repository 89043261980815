import { LSContentColumn, LSContentItem } from '../../../common/List';

function Item ({ item }) {
    return (
        <LSContentItem>
            <LSContentColumn>{item?.route_num || ''}</LSContentColumn>
            <LSContentColumn>{item?.route_name || ''}</LSContentColumn>
            <LSContentColumn>{item?.route_type || ''}</LSContentColumn>
            <LSContentColumn>{item?.vehicle_type_name || ''}</LSContentColumn>
            <LSContentColumn>{item?.time_start || ''}</LSContentColumn>
        </LSContentItem>
    );
}

export default Item;
