import { all, put, call, takeLatest } from 'redux-saga/effects';
import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

function* loadWsAvailableChannelsSaga() {
    const response = yield call(api.getWsChannels);
    if (response) {
        yield put(actions.loadedWsAvailableChannels(response?.data || {}));
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_WS_AVAILABLE_CHANNELS, loadWsAvailableChannelsSaga),
    ]);
}