import { useMemo } from 'react';
import { Pie as GPie } from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Container, Item, Name, Value } from 'components/common/Showcase/List';


const Pie = ({
    data = [],
    // width = 200,
    // height = 200,
}) => {
    const callbacks = {
        // label: (context) => `${context?.label} ${context?.formattedValue}%`,
        label: (context) => `${context?.label}`,
        title: () => ''
    };

    const flex_style = {
        display: 'flex',
        justifyContent: 'space-around'
    };

    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        redraw: false,
        plugins: {
            tooltip: {
                // mode: 'point',
                // usePointStyle: true,
                // callbacks,
                enabled: false,
            },
            legend: {
                display: false
                // align: 'top',
                // position: 'left',
                // labels: {
                //     boxWidth: 20,
                // }
            },
            // datalabels: {
            //     color: '#fff',
            //     display: function(context) {
            //         return context.dataset.data[context.dataIndex] > 0;
            //     }
            // },
        },
    };

    const dataG = useMemo(() => {

        const {
            colors,
            labels,
            values,
        } = data.reduce((res, { label = '', value = '', color = '', isHidden = false }) => {
            if (!isHidden) {
                return {
                    colors: [
                        ...res.colors || [],
                        color,
                    ],
                    labels: [
                        ...res.labels || [],
                        ` ${label} (${value})`,
                    ],
                    values: [
                        ...res.values || [],
                        value,
                    ],
                };
            }
            return res;
        }, {});

        const datasets = {
            data: values,
            maxBarThickness: 80,
            backgroundColor: colors, //.map((color) => hexToRgba(color, .8)),
            borderColor: '#fff',
        };

        return {
            labels: labels,
            datasets: [datasets],
        };
    }, [data]);

    const legend = useMemo(() => {
        return (
            <Container style={{ marginBottom: '1rem' }}>
                {data?.map(({ label, value, color }, index) => (
                    <Item className="small no-margin-bottom-last" key={index}>
                        <Name>{label}</Name>
                        <Value style={{ color }} >{value}</Value>
                    </Item>
                ))}
            </Container>
        );
    }, [data]);

    return (
        <div style={flex_style}>
            {legend}
            <div
                style={{
                    maxWidth: '40%',
                    paddingLeft: '1rem',
                    marginBottom: '1rem'
                }}
            >
                <GPie
                    // width={width}
                    // height={height}
                    data={dataG}
                    options={options}
                    type="Pie"
                    // plugins={[ChartDataLabels]}
                />
            </div>
        </div>
    );

};

export default Pie;
