import {
    loadReportTemplateFrontendLinks,
    loadApiReportReportTemplateFrontendLink
} from 'redux/Reports/actions';
import { reportSelectors } from 'redux/Reports';
import useStoreFromSelector from '../useStoreFromSelector';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

/*
    // провайдер шаблонов
    const provider = useReportTemplatesFrontendLinks();

* */

// хук - шаблоны отчетов для страниц
const useReportTemplatesFrontendLinks = () => {
    const dispatch = useDispatch();
    // текушая страница
    const currentUrl = window?.location?.pathname || '';

    // объект шаблонов
    const templatesObject = useStoreFromSelector(
        loadReportTemplateFrontendLinks,
        reportSelectors.reportTemplateFrontendLinks
    );

    // загрузка шаблонов
    const loading = useSelector(reportSelectors.loadingReportTemplateFrontendLinks);

    // загрузка отчета
    const loadingApi = useSelector(reportSelectors.loadingApiReportTemplateFrontendLinks);

    /* фильтр-- */
    const [type, setType] = useState(null);
    const [formats, setFormats] = useState([]);

    // своеобразный фильтр
    const filterObj = {
        // тип и метод
        type, setType,
        // формат и метод
        formats, setFormats,
        // очистить
        clear: () => {
            setType(null);
            setFormats([]);
        },
        // валидация
        isDisabled: !type || formats?.length === 0,
        // преобразовать для запроса
        getToApi: () => ({
            url: type?.url,
            formats: formats?.map(({ id }) => id) || []
        }),
    };
    /* --фильтр */

    return {

        // лоадинг
        loading: loading || loadingApi,

        // объект шаблонов как есть
        get getObjects() { return templatesObject; },

        // получить список шаблонов по текущему url
        getTemplatesByUrl: (urlTemplate = null) => { return templatesObject?.[urlTemplate || currentUrl] || []; },

        // отправить запрос на формирование отчета
        loadReport: (url, formats, filter, fnCallback) => dispatch(loadApiReportReportTemplateFrontendLink(url, formats, filter, fnCallback)),

        // своеобразный фильтр
        filter: filterObj,

    };
};

export default useReportTemplatesFrontendLinks;
