import React, { useState } from 'react';
import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core';
import { List } from '@mui/material';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Item from './Item';
import SortableItem from './SortableItem';
import { DragOverEvent, DragStartEvent } from '@dnd-kit/core/dist/types';

export interface ObjectWithId {
  id: number;
  [key: string]: any;
}

interface DragAndDropProps {
  list: ObjectWithId[];
  onChange: (arr: ObjectWithId[]) => void;
  itemsRender: (item: ObjectWithId, index: number) => React.ReactElement;
  withSelected?: boolean;
  listHeader?: React.ReactNode;
}

const DragAndDrop = ({
    list,
    onChange,
    itemsRender,
    withSelected,
    listHeader,
}: DragAndDropProps) => {
    const [activeId, setActiveId] = useState<string | number | null>(null);

    function handleDragStart(event: DragStartEvent) {
        setActiveId(event.active.id);
    }

    function handleDragOver(event: DragOverEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const activeIndex = list.findIndex((item) => item.id === active.id);
            const overIndex = list.findIndex((item) => item.id === over?.id);

            onChange(arrayMove(list, activeIndex, overIndex));
        }
    }

    function handleDragEnd() {
        setActiveId(null);
    }

    const activeElementIndex = list.findIndex((item) => item.id === activeId);

    return (
        <List>
            {listHeader}
            <DndContext
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCenter}
                onDragCancel={handleDragEnd}
            >
                <SortableContext items={list} strategy={verticalListSortingStrategy}>
                    {list.map((item, index) => (
                        <SortableItem
                            key={item.id}
                            item={item}
                            index={index}
                            itemsRender={itemsRender}
                            withSelected={withSelected}
                        />
                    ))}
                </SortableContext>
                <DragOverlay>
                    {activeId
                        ? (
                            <Item
                                item={list[activeElementIndex]}
                                index={activeElementIndex}
                                itemsRender={itemsRender}
                                style={{
                                    border: 'none',
                                    backgroundColor: (withSelected && activeElementIndex % 2 === 0) ? '#F0F1FA' : 'white',
                                    borderRadius: 2,
                                    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px'
                                }}
                            />
                        )
                        : null}
                </DragOverlay>
            </DndContext>
        </List>
    );
};

export default DragAndDrop;
