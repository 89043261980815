import { useDispatch } from 'react-redux';
import { loadMapLayerList, setMapLayer } from '../../../../redux/Auth/actions';
import { authSelectors } from '../../../../redux/Auth';
import useStoreFromSelector from '../../../../helpers/hooks/useStoreFromSelector';

// хук слои карты тайлы
const useMapLayers = () => {
    const dispatch = useDispatch();
    // запрос слоев
    const mapLayers = useStoreFromSelector(loadMapLayerList, authSelectors.mapLayers);

    return {
        // список слоев - массив
        mapLayers,
        // установить слой
        setLayer: (layerId) => dispatch(setMapLayer(layerId)),
    };
};

export default useMapLayers;
