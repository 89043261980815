import {
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

import { 
    changeRoute, 
    loadRouteCheckpoints, 
    saveCheckPointsToRoute 
} from 'redux/TransportRoad/actions';
import { transportRoadSelectors } from 'redux/TransportRoad';
import Context from 'helpers/context';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import styles from '../../routes.module.scss';
import EditStatusModal from '../../EditStatusModal';
import AddEditCopyModal from '../../AddEditCopyModal';
import RoutePassport from '../../RoutePassport';
import RouteCheckPoints from '../../RouteCheckPoints';

import MapForm from './MapForm';

function RouteInfo({ currentRoute, reloadList }) {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);
    const routeCheckpoints = useSelector(transportRoadSelectors.routeCheckpoints);

    const [editModal, setEditModal] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [isPrintOpen, setOpenPrint] = useState(false);
    const [routePoints, setRouteCheckPoints] = useState([]);

    const calculateDuration = (result) => {
        return result?.reduce((acc, item) => {
            if (typeof item?.interval === 'string') {
                const getHourMin = item?.interval?.split(':');
                const [hour, min] = getHourMin;
                if (item.interval) {
                    return (+hour * 60) + +min + +acc;
                }
                return acc;
            }
            return acc;

        }, 0);
    };

    const calculateLength = (result) => {
        return  result?.reduce((acc, item) => {
            return Number(+acc + +(item.mileage || 0)).toFixed(2);
        }, 0);
    };

    const saveRoute = (value) => dispatch(changeRoute(currentRoute?.id, { ...currentRoute, ...value }, reloadList));

    // const handleSaveRoutes = (value) => {
    //     if (value) {
    //         const data = { road: value };
    //         saveRoute(data);
    //     }
    // };

    const saveCheckPoints = (data) => {
        const duration = calculateDuration(data);
        const length = calculateLength(data);
        const value = {
            duration: duration,
            length: length,
            road : currentRoute.road
        };
        const result = data.map((item, i) => ({
            ...item,
            mileage: +item.mileage,
            interval: item.interval === null ? '00:00' : item.interval
        }));

        dispatch(saveCheckPointsToRoute(currentRoute?.id, result, () => saveRoute(value)));

    };
    
    const handleCloseStatusModal = () => {
        setEditStatus(false);
    };

    const handleCloseEditModal = () => {
        setEditModal(false);
    };

    useEffect(() => {
        const selectedRouteCheckPoints = routeCheckpoints[currentRoute.id] || [];
        setRouteCheckPoints(selectedRouteCheckPoints);
    }, [routeCheckpoints, currentRoute.id]);

    useEffect(()=> {
        if (currentRoute.id) {
            dispatch(loadRouteCheckpoints(currentRoute.id));
        }
    }, [currentRoute.id, dispatch]);

    const currentButtons = {
        edit: [
            {
                ...buttonsTypes.edit,
                size: 'small',
                onClick: () => setEditModal(true),
                disabled: (currentRoute.status !== 1 || !permissions?.is_update)
            },
            {
                size: 'small',
                startIcon: <PrintIcon fontSize="inherit" />,
                onClick: () => setOpenPrint(true),
                name: 'Печать',
                variant: 'contained'
            }
        ]
    };

    const loadCheckPoints = useCallback(() => {
        if (currentRoute.id) {
            dispatch(loadRouteCheckpoints(currentRoute.id));
        }
        reloadList();
    }, [currentRoute.id, dispatch, reloadList]);

    return (
        <div className={ styles.container }>
            <Grid container direction="row" spacing={1} justify="center" alignItems="flex-start">
                <Grid item xs={12}>
                    <h1>Паспорт маршрута</h1>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2">
                        {currentRoute
                    && <> <strong style={{ color: '#000' }}>Статус: {currentRoute.statusText}</strong>{}
                        {true
                        && <Tooltip title={[1, 2].includes(currentRoute.status) ? 'Нажмите, чтобы изменить статус' : 'Нельзя редактировать'}>
                            <span>
                                <IconButton 
                                    onClick={() => {
                                        setEditStatus(true);
                                    }} 
                                    size="small"
                                    disabled={![1, 2, 4].includes(currentRoute.status)}
                                >
                                    <i className="fas fa-toggle-on" />
                                </IconButton>
                            </span>
                        </Tooltip>}
                    </>}
                    </Typography>
                    <Divider style={{ margin: '10px 0 0' }}/>
                    <div className="filter filter__wrap__btn">
                        <FormButtons
                            buttons={currentButtons.edit}
                        />
                    </div>
                    <RouteCheckPoints 
                        // currentRoute={currentRoute}
                        routePoints={routePoints}
                        canEdit={false}
                        canRemove={false}
                        draggable={false}
                        saveCheckPoints={saveCheckPoints}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MapForm 
                        currentRoute={currentRoute}
                        checkPoints={routePoints}
                    />
                </Grid>
            </Grid>
            {editStatus
                && <EditStatusModal
                    data={currentRoute}
                    isOpen={editStatus}
                    reloadList={reloadList}
                    onClose={handleCloseStatusModal}
                />
            }
            {editModal
                && <AddEditCopyModal
                    data={currentRoute}
                    isNew={false}
                    isOpen={editModal}
                    onClose={handleCloseEditModal}
                    permissions={permissions}
                    reloadList={loadCheckPoints}
                />
            }
            {isPrintOpen
                && <RoutePassport
                    data={currentRoute}
                    isOpen={isPrintOpen}
                    onClose={() => setOpenPrint(false)}
                />
            }
        </div>
    );
}

export default RouteInfo;
