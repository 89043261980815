import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    loadReportByCategory,
    clearReportByCategory,
} from '../../../redux/Incidents/actions';
import { isEqual } from 'lodash';
import { usePrevious } from '../index';
import { incidentsSelectors } from '../../../redux/Incidents';

// хук
const useReportByCategory = () => {
    const dispatch = useDispatch();

    const reportByCategory = useSelector(incidentsSelectors.reportByCategory);
    const loadingReportByCategory = useSelector(incidentsSelectors.loadingReportByCategory);
    const prevData = usePrevious(reportByCategory);

    useEffect(() => {
        return () => {
            dispatch(clearReportByCategory());
        };
    }, []);

    return {
        data: reportByCategory,
        isData: Object.keys(reportByCategory) > 0,
        isNewData: !isEqual(prevData, reportByCategory),
        load: (params) => {
            dispatch(loadReportByCategory(params));
        },
        // LoadReport: (params, format) => {
        //     dispatch(loadReportByCategory({
        //         ...params,
        //         report: format,
        //     }));
        // },
        clear: () => {
            dispatch(clearReportByCategory());
        },
        loading: loadingReportByCategory
    };
};

export default useReportByCategory;
