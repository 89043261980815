const clearLayer = (layerRef, decoratorRef, layerProps) => () => {
    const {
        parent,
        map,
    } = layerProps;

    if (layerRef.current) {
        parent.removeLayer(layerRef.current);
        layerRef.current.clearLayers();
    }
    if (decoratorRef.current.length) {
        decoratorRef.current.map((item) => {
            map.removeLayer(item);
        });
        decoratorRef.current = [];
    }
    //     if (currentIdsRef.current.length) {
    //         while (currentIdsRef.current.length > 0) {
    //             const id = currentIdsRef.current.pop();
    //             map.off(`showBy${idPrefix}${id}`);
    //         }
    //         // currentIdsRef.current.map((id) => map.off(`showBy${idPrefix}${id}`));
    //     }

    parent.off('contextmenu');
};

export default clearLayer;