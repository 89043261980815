import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import messages from 'helpers/constants/messages';
import { deleteVehicle } from 'modules/TransportWater/redux/actions';
import buttonsTypes from 'components/common/FormButtons/buttonsTypes';
import ConfirmModal from 'components/common/ConfirmModal';

import ModalForm from './ModalForm';

import type { Vehicle } from './types';

interface ItemProps {
    item: Vehicle;
    reloadList: (isDelete?: boolean) => void;
}

function Item({ item, reloadList }: ItemProps) {
    const dispatch = useDispatch();

    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    const handleDelete = () => {
        dispatch(deleteVehicle(item.id, () => {
            reloadList(true);
            setIsOpenDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>{item.name || messages.NO_VALUE}</LSContentColumn>

                <LSContentColumn>{item.type || messages.NO_VALUE}</LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setIsOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setIsOpenDeleteModal(true),
                            },

                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isOpenEditModal && (
                <ModalForm
                    isOpen={isOpenEditModal}
                    onClose={() => setIsOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}

            {isOpenDeleteModal && (
                <ConfirmModal
                    open={isOpenDeleteModal}
                    onClose={() => setIsOpenDeleteModal(false)}
                    onSuccess={handleDelete}
                />
            )}
        </>
    );
}

export default Item;
