import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidation } from '../../../../../helpers/hooks';
import titles from '../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { TextField } from '@mui/material';
import Modal from '../../../../common/Modal';
import {
    addScheduleEvent,
    editScheduleEvent
} from '../../../../../redux/TransportPassenger/actions';

function ModalAddEdit({ data, isOpen, isNew,  onClose }) {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const [event, setEvent] = useState(isNew ? '' : data?.name);

    const handleChange = ({ target: { value } }) => {
        setEvent(value);
        validation.deleteKey('name');
    };

    const onAccept = () => {
        isNew
            ? dispatch(addScheduleEvent({ name: event }))
            : dispatch(editScheduleEvent(data?.id, { name: event }));
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
        }
    }, [validation, onClose, requestStatus]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? `${titles.ADD} событий` : `${titles.EDIT} событий`}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAccept,
                        disabled: event === '' || validation.isValidation()
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <TextField
                        value={event}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        label={titles.EVENT}
                        name="name"
                        error={validation.isKey('name')}
                        helperText={validation.get('name')}
                    />
                </div>
            </form>
        </Modal>
    );
}

export default ModalAddEdit;
