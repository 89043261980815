import React, { useState } from 'react';
import cn from 'classnames';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { TextField } from '@mui/material';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import SelectBoards from '../../../../common/Autocomplete/Boards/BoardList';

function Filter({ setParams }) {
    const initialState = {
        header: '',
        text: '',
        count: '',
        duration: '',
    };
    
    const [data, setData] = useState(initialState);
    const [wasSearch, setWasSearch] = useState(false);
    const [selectedBoards, setSelectedBoards] = useState([]);

    const onChange = ({ target: { name, value } }) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const search = () => {
        if (selectedBoards.length > 0) {
            data.dit_ids = selectedBoards.map(el => el.id);
        }
        const paramsWithoutEmpty = removeEmptyFields(data);

        if (Object.keys(paramsWithoutEmpty).length > 0) {
            setWasSearch(true);
            setParams(paramsWithoutEmpty);
        }
    };

    const resetFilters = () => {
        if (wasSearch) {
            setWasSearch(false);
            setParams({});
        }
        setData(initialState);
    };

    const resetDisable = !data.header && !data.count && !data.duration && !data.text;

    const handleClickEnter = (e) => {
        if (e?.key === 'Enter') search();
    };

    return (
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">
                <TextField
                    label="Название сообщения"
                    size="small"
                    value={data.header}
                    variant="outlined"
                    name="header"
                    type="text"
                    onChange={onChange}
                    onKeyPress={handleClickEnter}
                />
                <TextField
                    label="Текст сообщения"
                    size="small"
                    value={data.text}
                    variant="outlined"
                    name="text"
                    type="text"
                    onChange={onChange}
                    onKeyPress={handleClickEnter}
                />
                <TextField
                    label="Кол-во показов"
                    variant="outlined"
                    size="small"
                    value={data.count}
                    name="count"
                    onChange={onChange}
                    type="number"
                    onKeyPress={handleClickEnter}
                    inputProps={{ min: 0 }}
                />
                <TextField
                    label="Время показа в секундах"
                    variant="outlined"
                    size="small"
                    value={data.duration}
                    name="duration"
                    onChange={onChange}
                    type="number"
                    inputProps={{ min: 0 }}
                    onKeyPress={handleClickEnter}
                />
                <SelectBoards 
                    multiple
                    selected={selectedBoards}
                    onChange={setSelectedBoards}
                />
            </div>
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilters,
                            disabled: resetDisable
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: search,
                        }
                    ]}
                />
            </div>
        </div>
    );
}

export default Filter;