import { useState } from 'react';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone } from '../../../../helpers/date.config';
import cn from 'classnames';
import { TextField } from '@mui/material';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import DateRange from '../../../common/Dates/DateRange';

export const Filter = ({ setParams }) => {

    const initialState = {
        route_num: '',
        start_date: null,
        end_date: null,
    };

    const [formData, setFormData] = useState(initialState);

    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const search = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            ...(formData.start_date && { start_date: fullDateTimeWithTimeZone(formData.start_date) }),
            ...(formData.end_date && { end_date: fullDateTimeWithTimeZone(formData.end_date) }),
        }, false);

        setParams(prepareData);
    };

    const resetSearch = () => {
        setFormData(initialState);
        setParams(initialState);
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') search();
    };

    const isDisabled = Object.keys(removeEmptyFields(formData)).length === 0;

    return (
        <div className="filter">
            <div className={cn('filter__wrap', 'filter__wrap__more__field')}>
                <TextField
                    value={formData.route_num}
                    onChange={(e) => handleChange('route_num', e.target.value)}
                    label="Номер маршрута"
                    variant="outlined"
                    size="small"
                    inputProps={{ onKeyDown: handleEnter }}
                />
                <DateRange
                    valueStartDate={formData.start_date}
                    valueEndDate={formData.end_date}
                    // dateOnly={true}
                    handleDate={(value) => handleChange('start_date', value)}
                    handleEndDate={(value) => handleChange('end_date', value)}
                />
            </div>
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetSearch
                        },
                        {
                            ...buttonsTypes.search,
                            disabled: isDisabled,
                            onClick: search
                        }
                    ]}
                />
            </div>
        </div>
    );
};