import React, { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';

const Filter = ({ setParams, setCurrentAip }) => {
    const [wasSearch, setWasSearch] = useState(false);

    const initialState = {
        name : '',
        ip_route : '',
        uuid: [],
    };

    const [data, setData] = useState(initialState);

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const handleSearch = () => {
        const TransformationDataForSend = Object.keys(data).reduce(
            (res, key) => {
                const el = data[key];
                if (el) {
                    res[key] = el;
                    return res;
                }
                return res;
            },
            {}
        );
        if (Object.keys(TransformationDataForSend).length > 0) {
            setWasSearch(true);
            setParams(TransformationDataForSend);
            setCurrentAip(null);
        }
    };

    const resetFilter = () => {
        setData(initialState);
        if (wasSearch) {
            setParams({});
            setWasSearch(false);
            setCurrentAip(null);
        }
    };

    const handleClickEnter = (e) => {
        if (e?.key === 'Enter') handleSearch();
    };

    return <>
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">
                <MultipleInput
                    value={data?.uuid}
                    onChange={(e) => onChange('uuid', e) }
                    label="UUID"
                    guideText="Добавление одного или нескольких элементов, разделяя запятой"
                />
                <TextField
                    value={data?.name}
                    onChange={({ target }) => onChange(target.name, target.value)}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    name="name"
                    type="text"
                    onKeyPress={handleClickEnter}
                />
                <TextField
                    value={data?.ip_route}
                    onChange={({ target }) => onChange(target.name, target.value)}
                    label={titles.IP_ADDRESS}
                    variant="outlined"
                    size="small"
                    name="ip_route"
                    type="text"
                    onKeyPress={handleClickEnter}
                />
            </div>

            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilter,
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: handleSearch
                        }
                    ]}
                />
            </div>
        </div>
    </>;
};

export default Filter;
