import { useRef } from 'react';

// выполнить запрос по времени
const useActionTime = (fn, wait = 10000) => {
    const timer = useRef(null);

    // метод очистить
    const clear = () => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
    };

    // запустить отсчет
    const mainFunction = () => {
        if (!timer.current) {
            timer.current = setTimeout(() => {
                if (fn) fn();
                timer.current = null;
            }, wait);
        }
    };

    // отменить отсчет
    mainFunction.clear = () => clear();

    return mainFunction;
};

export default useActionTime;
