import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Collapse } from '@mui/material';

import { deleteScheduleTemplate } from 'redux/TransportPassenger/actions';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';
import CircleStatus from 'components/common/CircleStatus';

import Info from './Info';
import styles from './scheduleTemlates.module.scss';
import STModal from './Modal';
import { scheduleTemplatesURI } from './moduleConfig';

const test_id_prefix = `${scheduleTemplatesURI}:item`;

const Item = ({
    status = {},
    item = {},
    currentRoute,
    handleClickSTIcon,
    reloadList
}) => {
    const dispatch = useDispatch();

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openCollapse, setOpenCollapse] = useState(false);
    const [openTemplateModal, setOpenTemplateModal] = useState(false);

    const handleDelete = () => {
        dispatch(deleteScheduleTemplate(item, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem onClick={() => setOpenCollapse(!openCollapse)}>
                <LSContentColumn>
                    <CircleStatus title={status.name} color={status.color} />
                </LSContentColumn>
                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        test_id_prefix={test_id_prefix}
                        buttons={[
                            {
                                icon: <i className={`fas fa-border-none ${styles.item_icon}`}/>,
                                name: 'Графики движения',
                                onClick: () => handleClickSTIcon(item),
                                disabled: !currentRoute.id,
                                testName: 'traffic-schedule',
                            },
                            {
                                ...buttonsTypes.copyIcon,
                                onClick: () => setOpenTemplateModal(true),
                                testName: 'copy',
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                                testName: 'edit',
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                                testName: 'delete',
                            }
                        ]}
                    />
                    <ActionMore
                        isOpen={openCollapse}
                        test_id_prefix={test_id_prefix}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openDeleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteModal}
                    onSuccess={handleDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
            {openCollapse && (
                <Collapse in={openCollapse} unmountOnExit timeout="auto">
                    <Info
                        item={item}
                        hasCurrentRoute={Boolean(currentRoute.id)}
                        reloadList={reloadList}
                    />
                </Collapse>
            )}
            {(openTemplateModal || openEditModal) && (
                <STModal
                    isNew={false}
                    isCopy={openTemplateModal}
                    isOpen={openTemplateModal || openEditModal}
                    onClose={() => openTemplateModal ? setOpenTemplateModal(false) : setOpenEditModal(false)}
                    el={item}
                    currentRoute={currentRoute}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default memo(Item);
