import React, { useState } from 'react';
import DateRange from '../../../../common/Dates/DateRange';
import titles from '../../../../../helpers/constants/titles';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import Vehicles from '../../../../common/Autocomplete/Transport/Vehicles';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';

const Filter = ({
    categories,
    setParams,
    initialStateFilter
}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [filterValues, setFilterValues] = useState(initialStateFilter);

    const handleChange = (name, value) => {
        const newFiler = {
            ...filterValues,
            [name]: value,
        };
        setFilterValues(newFiler);
    };

    const resetFilter = (needRefresh) => {
        if (needRefresh) {
            setParams(prev => ({ ...prev, data: initialStateFilter }));
        }
        setFilterValues(initialStateFilter);
    };

    const updateFilter = (filters) => {
        const newFilters = {
            ...initialStateFilter,
            ...filters,
        };

        setFilterValues(newFilters);

        if (Object.keys(filters).length > 0) {
            setParams(prev => ({ // отправляем в родителя, чтобы произошел запрос
                ...prev,
                data: newFilters
            }));
        }

    };
    
    return (
        <LayoutFilter 
            onResetFilter={resetFilter} 
            onSearch={() => setParams(prev => ({ ...prev, data: { ...prev.data, ...filterValues } }))}
            disabled={isDisabled}
            filter={filterValues} 
            filterException={['start_at', 'end_at',]} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <div className="row noMargin">
                    <DateRange
                        handleDate={ (newDate) => handleChange('start_at', newDate)}
                        handleEndDate={ (newDate) => handleChange('end_at', newDate)}
                        valueStartDate={filterValues.start_at}
                        valueEndDate={filterValues.end_at}
                        isDisabledHandler={(value) => setIsDisabled(value)}
                    />
                </div>
                <Vehicles
                    multiple
                    selected={filterValues.grz}
                    onChange={(value) => handleChange('grz', value)}
                    label="Номер ТС"
                    storeName="transportPassenger"
                />
                        
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <FormControl size="small" variant="outlined">
                    <InputLabel>{titles.TYPE_OF_VEHICLE}</InputLabel>

                    <Select
                        value={filterValues.category_id}
                        onChange={ (e) => handleChange('category_id', e.target.value)}
                        label={titles.TYPE_OF_VEHICLE}
                    >
                        <MenuItem value={''}>
                            {titles.NOT_CHOSEN}
                        </MenuItem>
                        {categories.map((item, index) => (
                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                        
                <SelectCompany
                    multiple
                    selected={filterValues.organization_id}
                    onChange={(value) => handleChange('organization_id', value)}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
