import { useMemo, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { meteoSelectors } from 'redux/Meteo';
import { loadDeviceStatuses } from 'redux/Meteo/actions';
import titles from 'helpers/constants/titles';
import colorExtend from 'helpers/mapHelper/colorExtend';
import { useStoreFromSelector } from 'helpers/hooks';

import icon from '../icons/icon';

import TabInformation from './TabInformation';
import TabEvents from './TabEvents';


const PopUp = (props) => {
    const { tab = 0, external_id, data = {}, readOnly = false, } = props;
    const { status, status_color } = data;
    const [tabIndex, setTabIndex] = useState(tab);

    const statuses = useStoreFromSelector(loadDeviceStatuses, meteoSelectors.deviceStatuses);
    const statusesObj = useMemo(() => statuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [statuses]);

    const tabList = [
        {
            key: 'information',
            title: titles.INFORMATION,
            component: <TabInformation external_id={external_id} readOnly={readOnly} />
        },
    ];

    // если только чтение - не показывать вкладку события
    if (readOnly === false) {
        tabList.push({
            key: 'events',
            title: titles.EVENTS,
            component: <TabEvents external_id={external_id} />
        });
    }

    const { name } = data;

    return (
        <div className="map-popup">
            <div
                className="map-popup_header"
                style={{ backgroundColor: colorExtend(status_color || statusesObj[status]?.color) }}
            >
                <div className="map-popup_header_">
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                    <span>
                        {name}
                    </span>
                </div>
            </div>
            <div className="map-popup_body">
                <Tabs
                    selectedIndex={tabIndex}
                    onSelect={(selectTabIndex) => {
                        setTabIndex(selectTabIndex);
                    }}>
                    <TabList>
                        {tabList.map(({ title, key }) => (
                            <Tab key={`tabtitle_${key}`}>{title}</Tab>
                        ))}
                    </TabList>

                    {tabList.map(({ component, key }, index) => (
                        <TabPanel key={key}>
                            {tabIndex === index && (
                                <div className="custom-popup-body">{component}</div>
                            )}
                        </TabPanel>
                    ))}
                </Tabs>
            </div>
        </div>
    );
};

export default PopUp;
