import iconCluster from './icons/iconCluster';
import { clearDefectsFilter } from '../../../../redux/RoadWorks/actions';

const config = {
    name: 'Недостатки Дороги',
    slug: 'defects',
    mapContextMenu: {
        title: 'Добавить недостаток',
        event: 'add_defects',
        // className: styles.button,
        minWidth: 210,
    },
    defaultColors: '#000',
    clusterIcon: iconCluster,
    onAfterActivateAction: () => clearDefectsFilter(),
    layerIcon: 'fal fa-sensor-alert',
};
// добавление на карте
export default config;
