import { useEffect, useState } from 'react';
import {
    Badge
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import style from './notification.module.scss';
import titles from '../../../helpers/constants/titles';
import { useNotifications } from '../../../helpers/hooks';

import {
    Popover,
    IconButton,
    Tooltip,
} from '@mui/material';
import Page from './Page/index';
import { useSelector } from 'react-redux';
import { notificationsSelectors } from '../../../redux/Notifications';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette?.background?.paper,
        width: 400,
        height: '100%',
    },
    popover: {
        height: '500px',
    },
    icon: {
        color: 'rgba(0,0,0, 0.54)',
        fontSize: 24,
    },
    paper: {
        overflow: 'hidden',
    },
}));

export const Notifications = () => {
    const classes = useStyles();

    // хук для нотификаций
    const notifications = useNotifications();
    const countActive = useSelector(notificationsSelectors.countActive);

    useEffect(() => {
        notifications.loadCountActive();
    }, []);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

        notifications.loadCountActive();
    };
    const onClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Badge
                overlap="circular"
                max={1000}
                badgeContent={countActive}
                color="error"
                aria-describedby={id}
                onClick={handleClick}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Tooltip title={titles.NOTIFICATIONS}>
                    <IconButton style={{ width: 34 }} size="small">
                        <i className={`${classes.icon} far fa-bell`}/>
                    </IconButton>
                </Tooltip>
            </Badge>

            <Popover
                className={style.notify__popover}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{ className: classes.paper }}
                {...{ id, open, anchorEl, onClose }}
            >
                <Page
                    onClose={onClose}
                />
            </Popover>
        </>
    );
};
