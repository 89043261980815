import * as types from './types';
import { keyBy } from 'lodash';

/**
 * Reducers
 * */
const initialState = {
    lightsStatuses: {},
    loadingLightList: false,
    lightStates: [],
    lightControlTypes: [],
    lightList: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    sidebar: {
        data: [],
    },
    sidebarLoading: false,
    // полигон
    polygon: [],
    // фильтры
    filters: {},
    // активный
    active: {},
    // данные для удаления
    deleteForm: false,
    // данные для редактирования
    editForm: false,
    // группы
    coordinationGroups: [],
    coordinationGroupsLoading: false,
    coordinationGroupsPolygon: {},
    // зеленые улицы
    greenStreets: [],
    greenStreetsLoading: false,
    greenStreetsPolygon: {},
    // Показать ГК и ЗУ для выбранных светофоров
    showGSAndCG: false,
    // фильтр для сокетов
    wsFilter: [],
    // данные из сокетов
    wsData: {},
    colorsForLegends: {},
    saved: false,
    showcase: {},
    loadingShowcase: false,
    lightListByRadius: [],

    settingsGroup: { data: [] },
    loadingSettingsGroup: false,

    loadingButton: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_TRAFFIC_LIGHTS_STATUSES:
            return {
                ...state,
                lightsStatuses: payload
            };
        case types.LOADING_TRAFFIC_LIGHTS_LIST:
            return {
                ...state,
                loadingLightList: payload
            };
        case types.LOADED_TRAFFIC_LIGHTS_LIST:
            return {
                ...state,
                lightList: payload
            };
        case types.LOADED_TRAFFIC_LIGHT_STATES:
            return {
                ...state,
                lightStates: payload
            };
        case types.LOADED_TRAFFIC_LIGHT_CONTROL_TYPES:
            return {
                ...state,
                lightControlTypes: payload
            };
        case types.SAVE_POLYGON: {
            const newData = Object.keys(payload || {}).reduce((res, item) => ([
                ...res,
                payload[item],
            ]), []);

            return {
                ...state,
                polygon: newData,
            };
        }
        case types.LOADED_SIDEBAR: {
            const {
                data,
                replace,
            } = payload;

            if (replace) {
                return {
                    ...state,
                    sidebar: data,
                };
            }

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...data,
                    data: [
                        ...state.sidebar.data,
                        ...data.data,
                    ],
                },
            };
        }

        case types.LOADING_SIDEBAR:
            return {
                ...state,
                sidebarLoading: payload,
            };
        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }
        case types.SET_FILTER: {
            return {
                ...state,
                filters: payload,
            };
        }
        case types.SET_DELETE_FORM: {
            return {
                ...state,
                deleteForm: payload,
            };
        }
        case types.SET_EDIT_FORM: {
            return {
                ...state,
                editForm: payload,
            };
        }
        case types.SAVED: {
            return {
                ...state,
                saved: payload,
            };
        }

        // группы координат
        case types.LOADED_COORDINATION_GROUP: {
            return {
                ...state,
                coordinationGroups: payload
            };
        }

        case types.COORDINATION_GROUP_LOADING: {
            return {
                ...state,
                coordinationGroupsLoading: payload
            };
        }

        case types.LOADED_COORDINATION_GROUP_POLYGON: {
            return {
                ...state,
                coordinationGroupsPolygon: payload
            };
        }

        // зеленая улица
        case types.LOADED_GREEN_STREET: {
            return {
                ...state,
                greenStreets: payload
            };
        }

        case types.GREEN_STREET_LOADING: {
            return {
                ...state,
                greenStreetsLoading: payload
            };
        }

        case types.LOADED_GREEN_STREET_POLYGON: {
            return {
                ...state,
                greenStreetsPolygon: payload
            };
        }

        // данные из сокета о светофорах
        case types.WS_REPLACE_DATA: {
            const ids = state.wsFilter || [];
            if (ids?.length === 0) return state;

            const replaceDataWs = payload?.reduce((res, itemWs) => {
                const { traffic_light_id } = itemWs;

                if (traffic_light_id && ids.includes(traffic_light_id)) {
                    return {
                        ...res,
                        [traffic_light_id]: itemWs,
                    };
                }
                return res;
            }, {});

            if (Object.keys(replaceDataWs).length > 0) {
                return {
                    ...state,
                    wsData: {
                        ...state.wsData,
                        ...replaceDataWs,
                    }
                };
            }

            return state;
        }

        // фильтр для данных чтобы каждый раз не проходить по полигону
        case types.WS_SET_FILTER: {
            const wsData = payload.reduce(
                (res, id) => state.wsData[id]
                    ? {
                        ...res,
                        [id]: state.wsData[id]
                    }
                    : res
                , {});

            return {
                ...state,
                wsFilter: payload,
                wsData
            };
        }

        case types.SHOW_GS_CS_BY_TRAFIC_LIGHTS: {
            return {
                ...state,
                showGSAndCG: payload,
            };
        }

        case types.LOADED_COLORS_FOR_LEGENDS: {
            return {
                ...state,
                colorsForLegends: payload,
            };
        }

        // пришли изменения по светофорам пачкой
        case types.WS_REPLACE_DATA_LIST: {
            if (payload?.length === 0) return state;

            const {
                polygon: newPolygon,
                sidebar: newSidebar,
            } = payload?.reduce((res, item) => {
                const {
                    polygon,
                    sidebar,
                } = res;
                const polygonIndex = polygon?.findIndex(({ id }) => id === item?.id);
                const sidebarIndex = sidebar?.findIndex(({ id }) => id === item?.id);

                const polygonList = polygonIndex >= 0
                    ? [
                        ...polygon.slice(0, polygonIndex),
                        {
                            ...polygon[polygonIndex],
                            ...item,
                        },
                        ...polygon.slice(polygonIndex + 1),
                    ] : polygon;

                const sidebarList = sidebarIndex >= 0
                    ? [
                        ...sidebar.slice(0, sidebarIndex),
                        {
                            ...sidebar[sidebarIndex],
                            ...item,
                        },
                        ...sidebar.slice(sidebarIndex + 1),
                    ] : sidebar;

                return {
                    polygon: polygonList,
                    sidebar: sidebarList,
                };
            }, {
                polygon: state.polygon || [],
                sidebar: state.sidebar?.data || [],
            });

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    data: newSidebar
                },
                polygon: newPolygon
            };
        }

        case types.LOADED_SHOWCASE: {
            return {
                ...state,
                showcase: payload
            };
        }

        case types.LOADING_SHOWCASE: {
            return {
                ...state,
                loadingShowcase: payload
            };
        }

        case types.LOADED_TRAFFIC_LIGHTS_BY_RADIUS: {
            return {
                ...state,
                lightListByRadius: payload
            };
        }

        case types.LOADED_SETTINGS_GROUP: {
            return {
                ...state,
                settingsGroup: payload
            };
        }

        case types.LOADING_SETTINGS_GROUP: {
            return {
                ...state,
                loadingSettingsGroup: payload
            };
        }

        case types.LOADING_BUTTON: {
            return {
                ...state,
                loadingButton: payload
            };
        }

        // пришли изменения по светофору
        // case types.WS_REPLACE_LIGHT: {
        //     const { one = null } = payload;
        //     // console.log('one', one);
        //     if (!one) return state;
        //
        //     // заменяем в полигоне
        //     const getPolygon = () => {
        //         const findIndex = state.polygon.findIndex(({ id }) => parseInt(id) === parseInt(one.id));
        //
        //         if (findIndex >= 0) {
        //             return [
        //                 ...state.polygon.slice(0, findIndex),
        //                 {
        //                     ...state.polygon[findIndex],
        //                     ...one
        //                 },
        //                 ...state.polygon.slice(findIndex + 1),
        //             ];
        //         }
        //         return state.polygon;
        //     };
        //
        //     // заменияем в сайдале список
        //     const getSidebar = () => {
        //         const findIndex = state.sidebar?.data.findIndex(({ id }) => parseInt(id) === parseInt(one.id));
        //
        //         if (findIndex >= 0) {
        //             return {
        //                 ...state.sidebar,
        //                 data: [
        //                     ...state.sidebar?.data?.slice(0, findIndex),
        //                     {
        //                         ...state.sidebar?.data[findIndex],
        //                         ...one
        //                     },
        //                     ...state.sidebar?.data?.slice(findIndex + 1),
        //                 ]
        //             };
        //         }
        //         return state.sidebar;
        //     };
        //
        //     return {
        //         ...state,
        //         sidebar: getSidebar(),
        //         polygon: getPolygon(),
        //     };
        // }

        default:
            return state;
    }
}
