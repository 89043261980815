import { IconButton, ListItem, ListItemText, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import messages from '../../../../../../../helpers/constants/messages';
import titles from '../../../../../../../helpers/constants/titles';
import ConfirmDelete from '../../../../../../common/ConfirmModal';
import EditModal from './AddEditModal';

const Item = ({
    index,
    vehicle,
    onChange,
    onDelete, }) => {

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        onDelete && onDelete(index);
        setIsDelete(false);
    };

    return (
        <ListItem className="list_item">
            <ListItemText>
                <div>
                    <strong>
                        ГРЗ:
                    </strong>&nbsp;
                    {vehicle?.grn || messages.NO_DATA}
                </div>
                <div>
                    <strong>
                        Марка:
                    </strong>&nbsp;
                    {vehicle?.brand || messages.NO_DATA}
                </div>
                <div>
                    <strong>
                        Модель:
                    </strong>&nbsp;
                    {vehicle?.model || messages.NO_DATA}
                </div>
                <div>
                    <strong>
                        Комментарий:
                    </strong>&nbsp;
                    {vehicle?.comment || messages.NO_DATA}
                </div>
            </ListItemText>
            <Tooltip title={titles.EDIT}>
                <IconButton onClick={() => setOpenEditModal(true)} size="small">
                    <EditIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Tooltip title={titles.DELETE}>
                <IconButton onClick={() => setIsDelete(true)} size="small">
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            {openEditModal &&  (
                <EditModal
                    vehicle={vehicle}
                    index={index}
                    isOpen={openEditModal} 
                    onClose={() => setOpenEditModal(false)}
                    onAccept={onChange}
                />
            )}
            {isDelete &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={isDelete}
                    onSuccess={handleDelete}
                    onClose={() => setIsDelete(false)}
                />
            )}
        </ListItem>
    );
};

export default Item;
