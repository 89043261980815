import React, { forwardRef, HTMLAttributes } from 'react';
import { IconButton, ListItem, ListItemIcon } from '@mui/material';
import { DraggableAttributes } from '@dnd-kit/core';
import { ObjectWithId } from './index';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

type ItemProps = {
  item: ObjectWithId;
  index: number;
  itemsRender: (item: ObjectWithId, index: number) => React.ReactElement;
  style?: React.CSSProperties;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
} & HTMLAttributes<HTMLLIElement>;

const Item = forwardRef<HTMLLIElement, ItemProps>(
    ({ item, index, itemsRender, style, listeners, attributes }, ref) => {
        return (
            <ListItem divider ref={ref} style={style} {...attributes}>
                <ListItemIcon {...listeners} style={{ minWidth: 'fit-content', paddingRight: 8 }}>
                    <IconButton size="small" style={{ width: 30, height: 30 }}>
                        <i className="fas fa-grip-vertical" />
                    </IconButton>
                </ListItemIcon>
                {itemsRender(item, index)}
            </ListItem>
        );
    }
);

export default Item;
