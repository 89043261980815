import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deletePark } from 'redux/TransportRoad/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import PSEModal from './Modal';

const PSEItem = ({ el, reloadList, }) => {
    const dispatch = useDispatch();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
    const [openMap, setOpenMap] = useState(false);

    const handleConfirmDelete = () => {
        dispatch(deletePark(el.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {el.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.mapIcon,
                                name: 'Показать зону действия',
                                onClick: () => setOpenMap(true),
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openMap
                && <Modal
                    isOpen={openMap}
                    onClose={() => setOpenMap(false)}
                    noPadding
                    title={titles.STOP_AREA}
                    buttons={
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: () => setOpenMap(false)
                                },
                            ]}
                        />
                    }
                >
                    <MapGeoJson
                        geometry={el?.check_point?.geometry || {}}
                        readOnly={true}
                    />
                </Modal>
            }

            {openEditModal && (
                <PSEModal
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    el={el}
                    reloadList={reloadList}
                />
            )}
            {isOpenDeleteModal &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={isOpenDeleteModal}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default PSEItem;
