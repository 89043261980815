// иконка для списка
const iconCluster = (props) => {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 107.000000 107.000000">
            <g>
                <g id="svg_va_3">
                    <rect x="-1" y="-1" width="108.999922" height="108.999922" id="canvas_background" fill="none"/>
                </g>
                <g id="svg_va_5">
                    <g fill="${props?.fill || '#e7e7e7'}" transform="translate(0,107) scale(0.10000000149011612,-0.10000000149011612) " id="svg_va_1">
                        <path d="m404.52236,1047.19878c-227.70224,-55.17014 -397.22506,-272.84146 -397.22506,-511.57773c0,-285.88168 240.74246,-525.62105 526.62414,-525.62105c285.88167,0 526.62414,240.74246 526.62414,526.62414c0,237.73318 -172.5321,457.41068 -402.24054,511.57774c-79.24439,18.05568 -175.54138,18.05568 -253.78268,-1.0031z" id="svg_va_2" stroke="null"/>
                    </g>
                    <g fill="${props?.innerFill || 'rgba(0, 0, 0, 0.54)'}" stroke="null" id="svg_va_9">
                        <path stroke="null" id="svg_va_4" d="m85.779583,40.969929l-47.420744,-20.84978a4.012225,4.012225 0 0 0 -4.973906,1.735288l-11.953924,20.922502a4.012225,4.012225 0 0 0 2.112687,5.767574l19.631067,8.131026l-4.995221,13.309304l-9.254449,0l0,-4.012225a2.006113,2.006113 0 0 0 -2.006113,-2.006113l-4.012225,0a2.006113,2.006113 0 0 0 -2.006113,2.006113l0,16.048902a2.006113,2.006113 0 0 0 2.006113,2.006113l4.012225,0a2.006113,2.006113 0 0 0 2.006113,-2.006113l0,-4.012225l12.036676,0a4.012225,4.012225 0 0 0 3.761461,-2.604185l5.874149,-15.665233l8.591178,3.558342a4.012225,4.012225 0 0 0 3.496905,-0.368623l23.729806,-14.83395c2.784735,-1.740303 2.413604,-5.906748 -0.635687,-7.126715l0.000002,-0.000002zm6.077268,13.190191l-4.444794,-1.954706l-17.352875,10.845547l14.993185,6.592588a2.113941,2.113941 0 0 0 2.785989,-1.084555l5.103049,-11.612885a2.112687,2.112687 0 0 0 -1.084555,-2.785989l0.000001,0z"/>
                    </g>
                </g>
            </g>
        </svg>
    `;
};

export default iconCluster;
