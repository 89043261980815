import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material';

import messages from 'helpers/constants/messages';
import {
    deleteTemplateImage,
} from 'redux/Boards/actions';
import Image from 'components/common/Image';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';

const Item = ({ data, reloadList }) => {
    const dispatch = useDispatch();

    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const {
        id,
        name,
        image,
        system
    } = data;

    const handleConfirmDelete = () => {
        dispatch(deleteTemplateImage(id, () => {
            setIsDelete(false);
            reloadList(true);
        }));
    };

    return (
        <>
            <ListItem className="list_item">
                <ListItemIcon>
                    <Image src={image} style={{ width: 'auto', height: '50px' }} alt="" />
                </ListItemIcon>
                <ListItemText>
                    <h2>
                        {name || messages.INFO_IS_NOT_FOUND}
                    </h2>
                </ListItemText>
                {!system && (
                    <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setIsEditMode(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setIsDelete(true),
                            },
                        ]}
                        noMarginLeft
                        justButton
                    />
                )}
            </ListItem>

            {isDelete &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={isDelete}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setIsDelete(false)}
                />
            )}

            {isEditMode && (
                <ModalForm
                    data={data}
                    isOpen={isEditMode}
                    onClose={() => setIsEditMode(false)}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default Item;
