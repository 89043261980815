import React from 'react';
import messages from '../../../../../helpers/constants/messages';
import CheckPointList from '../../../../common/Transport/StationRoute/CheckPointList';


const RouteCheckPoints = ({
    routePoints,
    canEdit,
    canRemove,
    draggable,
    saveCheckPoints,
    displayType
}) => {
    const calculateDuration = () => {
        return routePoints?.reduce((acc, item) => {
            if (typeof item?.interval === 'string') {
                const getHourMin = item?.interval?.split(':');
                const [hour, min] = getHourMin;
                if (item.interval) {
                    return (+hour * 60) + +min + +acc;
                }
                return acc;
            }
            return acc;

        }, 0);
    };

    const calculateLength = () => {
        return  routePoints?.reduce((acc, item) => {
            return Number(+acc + +(item.mileage || 0)).toFixed(2);
        }, 0);
    };

    return (
        <div className="form">
            <div className="block">
                <p>
                    <b>Общий пробег:</b> {calculateLength()} км
                </p>
                <p>
                    <b>Продолжительность маршрута:</b>  {calculateDuration()} мин
                </p>
            </div>
            <h2>Остановки и контрольные точки:</h2>
            {routePoints.length > 0
                ? <CheckPointList
                    items={routePoints}
                    setItems={saveCheckPoints}
                    draggable={draggable}
                    canEdit={canEdit}
                    canRemove={canRemove}
                    service="transportRoad"
                    displayType={displayType}
                />
                : <>{messages.NO_DATA}</>
            }
        </div>
    );
};

export default RouteCheckPoints;
