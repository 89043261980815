
import { useState } from 'react';
import { FormControl, TextField } from '@mui/material';

import SelectVehicles from 'components/common/Autocomplete/Transport/Vehicles';
import SelectCompany from 'components/common/Autocomplete/Companies';
import DateRange from 'components/common/Dates/DateRange';
import SelectRoutes from 'components/common/Autocomplete/RoadWorks/RoutesRW';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import titles from 'helpers/constants/titles';

import type { Filters } from './types';

interface FilterProps {
    setParams: (data: Filters) => void;
    initialState: Filters;
}

const Filter = ({ setParams, initialState }: FilterProps) => {
    const [filterValues, setFilterValues] = useState(initialState);
    const [isDateInvalid, setIsDateInvalid] = useState(false);

    const handleChange = (e: Date | string | boolean | object | null, name: string) => {
        setFilterValues({
            ...filterValues,
            [name]: e
        });
    };

    const resetFilters = (needRefresh: boolean) => {
        if (needRefresh) {
            setParams(initialState);
        }
        setFilterValues(initialState);
    };

    const updateFilter = (filters: Filters) => {
        setFilterValues({
            ...initialState,
            ...filters
        });
        if (Object.keys(filters).length > 0) {
            setParams({
                ...initialState,
                ...filters
            });
        }
    };


    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={() => setParams(filterValues)}
            filter={filterValues}
            setUserFilter={updateFilter}
            filterException={['start_date_at', 'end_date_at',]}
            disabled={isDateInvalid
                || !filterValues.start_date_at
                || !filterValues.end_date_at
            }
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(e: Date | null) => handleChange(e, 'start_date_at')}
                    handleEndDate={(e: Date | null) => handleChange(e, 'end_date_at')}
                    valueStartDate={filterValues.start_date_at}
                    valueEndDate={filterValues.end_date_at}
                    dateOnly
                    isDisabledHandler={(e: boolean) => setIsDateInvalid(e)}
                />
                <SelectRoutes
                    selected={filterValues.route_ids}
                    onChange={(e: any) => handleChange(e, 'route_ids')}
                    multiple
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <SelectVehicles
                    selected={filterValues.grz}
                    onChange={(e) => handleChange(e, 'grz')}
                    label={titles.GRZ}
                    storeNameProps="vehicles"
                    storeName="transportRoad"
                />
                <SelectCompany
                    multiple
                    selected={filterValues.organization_ids}
                    onChange={(e: any) => handleChange(e, 'organization_ids')}
                    selectDeletedCompanies
                />
                <FormControl variant="outlined" size="small">
                    <TextField
                        value={filterValues.id}
                        onChange={(e: any/*React.ChangeEvent<HTMLInputElement>*/) => handleChange(e.target.value, e.target.name)}
                        name="id"
                        label="ID наряда"
                        variant="outlined"
                        size="small"
                        type="number"
                    />
                </FormControl>
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
