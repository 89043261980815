import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import { fullDateTime } from '../../../../helpers/date.config';


const HistoryMap = (props) => {
    const { parent, data } = props;
    const polyRef = useRef([]);

    useEffect(() => {
        if (data.length > 0) {
            Object.keys(data).map(index => {
                if (index < data.length - 1) {
                    const {
                        lat, lon, color, speed, created_at
                    } = data[index];
                    const nextPoint = data[parseInt(index) + 1];

                    const oneLine = L
                        .polyline(
                            [
                                [lat, lon],
                                [nextPoint.lat, nextPoint.lon]
                            ],
                            {
                                color: color,
                                weight: 5,
                                smoothFactor: 1
                            }
                        );

                    const html = `
                        <div><strong>Дата, время: </strong>${created_at ? fullDateTime(created_at) : 'нет данных'}</div>
                        <div><strong>Скорость: </strong>${speed >= 0 ? `${speed} км/ч` : 'нет данных'}</div>
                    `;
                    const toolTip = L.tooltip({
                        sticky: true,
                        direction: 'top',
                        offset: [0, -5],
                    }).setContent(html);
                    oneLine.bindTooltip(toolTip);

                    parent.addLayer(oneLine);

                    polyRef.current.push(oneLine);
                }
            });

            return () => {
                while (polyRef.current.length > 0) {
                    const oneLine = polyRef.current.pop();
                    parent.removeLayer(oneLine);
                }
            };
        }
    }, [data]);

    return null;
};

export default HistoryMap;
