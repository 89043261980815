import moduleName from './module';
const prefix = `${moduleName}`;

/**
 * Constants
 * */
export const LOAD_LIST = `${prefix}/LOAD/LIST`;
export const LOADED_LIST = `${prefix}/LOADED/LIST`;
export const LOAD_COUNT_ACTIVE = `${prefix}/LOAD/COUNT/ACTIVE`;
export const LOADED_COUNT_ACTIVE = `${prefix}/LOADED/COUNT/ACTIVE`;
export const SET_LOADING = `${prefix}/SET/LOADING`;
export const SET_READ = `${prefix}/SET/READ`;
export const SET_READ_ALL = `${prefix}/SET/READ/ALL`;
export const CHANGE_READ = `${prefix}/CHANGE/READ`;
export const CHANGE_READ_ALL = `${prefix}/CHANGE/READ/ALL`;
export const SET_LAST_PAGE = `${prefix}/SET/LAST/PAGE`;
export const LOAD_SERVICE_LIST = `${prefix}/LOAD_SERVICE_LIST`;
export const LOADED_SERVICE_LIST = `${prefix}/LOADED_SERVICE_LIST`;
export const LOAD_FREE_SPACE_SERVER = `${prefix}/LOAD/FREE/SPACE/SERVER`;
export const LOADED_FREE_SPACE_SERVER = `${prefix}/LOADED/FREE/SPACE/SERVER`;
export const RELOAD_LIST_NOTIFICATION = `${prefix}RELOAD_LIST_NOTIFICATION`;
export const RELOADED_LIST_NOTIFICATION = `${prefix}RELOADED_LIST_NOTIFICATION`;
export const WS_RELOAD_NOTIFICATION = `${prefix}WS_RELOAD_NOTIFICATION`;
export const CLEAR_LIST = `${prefix}CLEAR_LIST`;
