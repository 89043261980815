import { useState } from 'react';
import { useDispatch } from 'react-redux';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { deleteRepairObjectIndicator } from 'redux/RoadWorks/actions';
import { LSContentColumn, LSContentItem } from 'components/common/ListStyle';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';

const Item = ({ item, reloadList }) => {
    const dispatch = useDispatch();

    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const handleDelete = () => {
        dispatch(deleteRepairObjectIndicator(item.id, () => {
            reloadList(true);
            setDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem fullWidth>
                <LSContentColumn title={titles.NAME} width="20%">
                    {item?.name || ''}
                </LSContentColumn>
                <LSContentColumn title="Единица измерения" width="20%">
                    {item?.unit_of_measurement_list.map(el => <div key={el.id}>{el?.long_name || ''}</div>)}
                </LSContentColumn>
                <LSContentColumn align="right" isActions >
                    <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setDeleteModal(true),
                            },
                        ]}
                        noMarginLeft
                        justButton
                    />
                </LSContentColumn>
            </LSContentItem>

            {editModal && (
                <ModalForm
                    open={editModal}
                    onClose={() => setEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />)
            }
            {deleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={deleteModal}
                    onSuccess={handleDelete}
                    onClose={() => setDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
