import React from 'react';
import { useSelector } from 'react-redux';
import {
    Grid, LinearProgress,
} from '@mui/material';

import Layer from '../../../MapComponents/Layers/DorisControl/layer';
import layerConfig from '../../../MapComponents/Layers/DorisControl/config';
import CardWidget from '../../../common/Showcase/CardWidget';
import { dorisControlSelectors } from '../../../../redux/DorisControl';
import useShowcase from '../../../../helpers/hooks/useShowcase';
import CardMap from '../../../common/Showcase/CardMap';

import Second from './Inner/Second';
import First from './Inner/First';

// страница
const Page = () => {
    const isShowcase = useShowcase();
    const loadingEventsStatistic = useSelector(dorisControlSelectors.loadingEventsStatistic);

    return (
        <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid
                item
                xs={12}
                sm={6}
                md={4}
                className="showcase_grid_flex-column"
            >
                {loadingEventsStatistic && <LinearProgress/>}
                <CardWidget
                    icon={layerConfig.layerIcon}
                    title="Отчет по событиям КФВФ"
                    fillOthers
                    marginBottom
                    small
                >
                    <First/>
                </CardWidget>

                <Second/>
            </Grid>
            <Grid item xs={12} sm={6} md={8} className="showcase_grid_flex-column">
                <CardMap
                    fillOthers
                    isCluster
                >
                    <Layer readOnly={isShowcase} visibleLegend={true} />
                </CardMap>
            </Grid>
        </Grid>
    );
};

export default Page;
