const config = {
    name: 'Дорожная техника',
    slug: 'roadwork-transport',
    // mapContextMenu: null,
    mapContextMenu: {
        title: 'Найти ближайшее ТС',
        event: 'find_road_work_transport',
        // className: styles.button,
        minWidth: 180,
    },
    mapMarkersColors: {
        default: '#D7DBDD',
        active: '#4b9c4b'
        // default: '#0fa3b0'
        // от статуса маркера
        // default: '#670926',
        // 1: '#d4a323',
        // 2: '#ad1818',
        // 3: '#51a351'
    },
    mapMarkersTypes: {
        dot: 2,
        line: 1,
        polygon: 3
    },
    layerIcon: 'fas fa-tractor',
};

export default config;
