import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'lodash';
import { useRef } from 'react';


// хук загрузить данные по транспорту
const useTransportSpeedColor = (microservice) => {
    const dispatch = useDispatch();

    // список
    const {
        colorSpeedList,
        colorSpeedListLoading,
        colorSpeedListProps,
    } = useSelector((store) =>
        pick(store[microservice.store], [
            'colorSpeedList',
            'colorSpeedListLoading',
            'colorSpeedListProps',
        ])
    );

    const prevProps = useRef({});

    return {
        list: colorSpeedList,
        loading: colorSpeedListLoading,
        props: colorSpeedListProps || {},

        load: (props = {}) => {
            dispatch(microservice.load(props));
            prevProps.current = props;
        },
        reload: () => {
            dispatch(microservice.load(prevProps.current));
        },
        setProps: (props) => {
            dispatch(microservice.setProps(props));
        },
        resetProps: () => {
            dispatch(microservice.resetProps());
        },

        add: (data, callback) => {
            dispatch(microservice.add(data, callback));
        },
        edit: (id, data, callback) => {
            dispatch(microservice.edit(id, data, callback));
        },
        delete: (id, callback) => {
            dispatch(microservice.delete(id, callback));
        },
    };
};

export default useTransportSpeedColor;
