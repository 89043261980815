import React, { useState } from 'react';
import {
    IconButton, 
    ListItem,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import messages from '../../../../../../../helpers/constants/messages';
import titles from '../../../../../../../helpers/constants/titles';
import VehiclesOnMapModal from './VehiclesOnMapModal';

const OrderStageVehiclesItem = ({ vehicle }) => {

    const [openMap, setOpenMap] = useState(false);

    const showTsOnMap = () => {
        setOpenMap(true);
    };

    return (
        <>
            <ListItem key={vehicle?.id} className="list_item">
                <ListItemText>
                    <Typography variant="body2">
                        <strong>{titles.NUMBER}: </strong>
                        {vehicle?.number || messages.INFO_IS_NOT_FOUND}
                    </Typography>
                    <Typography variant="body2">
                        <strong>{titles.GARAGE_NUM}: </strong>
                        {vehicle?.garage_number || messages.INFO_IS_NOT_FOUND}
                    </Typography>
                    <Typography variant="body2">
                        <strong>{titles.BRAND}: </strong>
                        {vehicle?.brand_name || messages.INFO_IS_NOT_FOUND}
                    </Typography>
                    <Typography variant="body2">
                        <strong>{titles.MODEL}: </strong>
                        {vehicle?.model_name || messages.INFO_IS_NOT_FOUND}
                    </Typography>
                </ListItemText>
                <Tooltip title={titles.SHOW_ON_MAP}>
                    <IconButton onClick={showTsOnMap} size="small">
                        <i className="fas fa-map-marked-alt"/>
                    </IconButton>
                </Tooltip>
            </ListItem>
            { openMap 
                && <VehiclesOnMapModal open={openMap} vehicle={vehicle} onClose={() => setOpenMap(false)}/>
            }
        </>
    );
};

export default OrderStageVehiclesItem;