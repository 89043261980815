import React from 'react';


function Dialog(props) {
    const {
        open = false,
        children,
    } = props;

    if (open) {
        return (
            <div className="map-search-control_list">
                {children}
            </div>
        );
    } else {
        return null;
    }
}

export default Dialog;
