import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import { loadStatusesVideoCamera } from 'redux/VideoCameras/actions';
import { videoCamerasSelectors } from 'redux/VideoCameras';
import { useStoreFromSelector } from 'helpers/hooks';
import titles from 'helpers/constants/titles';


const Filter = ({ data = {}, onChange, isGroup }) => {
    const statuses = useStoreFromSelector(loadStatusesVideoCamera, videoCamerasSelectors.videoCameraStatuses);

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({
            [name]: value,
        });
    };

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <FormControl required className="block" size="small" variant="outlined">
                <TextField
                    required
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    value={data?.name || ''}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                />
            </FormControl>

            {isGroup
                ? (<FormControl size="small" variant="outlined" className="block">
                    <TextField
                        required
                        label="Название камеры"
                        variant="outlined"
                        size="small"
                        value={data.camera_name || ''}
                        name={'camera_name'}
                        onChange={handleChange}
                        type="text"
                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                    />
                </FormControl>)
                : (
                    <FormControl size="small" variant="outlined" className="block">
                        <InputLabel>{titles.STATUS}</InputLabel>
                        <Select
                            value={data?.status || ''}
                            onChange={handleChange}
                            label={titles.STATUS}
                            name="status"
                        >
                            {statuses.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )
            }

        </form>
    );
};

export default Filter;
