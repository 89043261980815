import CloseIcon from '@mui/icons-material/Close';

export const NotificationComponent = ({ title, message, callback }) => {
    return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div onClick={callback} style={{ maxWidth: '95%' }}>
            {title 
                && <div>
                    <h2>{title}</h2>
                </div>
            }
            <span>{message}</span>
        </div>
        <div style={{ position: 'absolute', right: 5, top: 5 }}>
            <CloseIcon color="inherit" />
        </div>
    </div>;
};