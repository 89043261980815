import React, { useState } from 'react';
import formatDate from 'helpers/constants/dateFormat';
import { dateWithDashYYYYMMDD, getEndOf, getStartOf } from 'helpers/date.config';
import useValidation from 'helpers/hooks/useValidation';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import { PageReportFields, useReportTemplatesFrontendLinks } from 'components/common/PageReport';
import SelectCameras from 'components/common/Autocomplete/CameraVideoanalytics';

const Filter = () => {
    const validation = useValidation();

    const initialState = {
        start_date: getStartOf('month'),
        end_date: getEndOf('month'),
        id_list: [],
    };

    const [filters, setFilters] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);

    const templateProvider = useReportTemplatesFrontendLinks();
    const reportFilter = templateProvider.filter;

    const handleChange = (name, value) => {
        setFilters({ ...filters, [name]: value });
        validation.deleteKey(name);
    };

    const download = () => {
        const filter = {
            start_date: dateWithDashYYYYMMDD(filters.start_date),
            end_date: dateWithDashYYYYMMDD(filters.end_date),
            id_list: filters.id_list.map(({ id }) => id)
        };

        const {
            url,
            formats,
        } = reportFilter.getToApi();

        templateProvider.loadReport(
            url,
            formats,
            filter
        );
    };

    const disabledButton = isDisabled
        || !filters.start_date
        || !filters.end_date
        || reportFilter.isDisabled;

    const resetFilter = () => {
        setFilters(initialState);
        reportFilter.setFormats([]);
        validation.clear();
    };

    const updateFilter = (filter) => {
        setFilters({
            ...initialState,
            ...filter
        });
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            buttonType="getReport"
            onSearch={download}
            filter={filters}
            filterException={['start_date', 'end_date']}
            setUserFilter={updateFilter}
            disabled={disabledButton}
            isFilterVisible={true}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filters.start_date}
                    valueEndDate={filters.end_date}
                    handleDate={(date) => handleChange('start_date', date)}
                    handleEndDate={(date) => handleChange('end_date', date)}
                    isDisabledHandler={setIsDisabled}
                    pickersFormat={formatDate.DATE_FOR_PICKER}
                    renderError={() => (validation.isKey('start_date') || validation.isKey('end_date'))}
                    errorMessage={() => validation.get('start_date') || validation.get('end_date')}
                    dateOnly={true}
                />

                <SelectCameras
                    multiple
                    selected={filters.id_list}
                    onChange={values => handleChange('id_list', values)}
                    helperText={validation.get('id_list')}
                    error={validation.isKey('id_list')}
                />

                <PageReportFields
                    reportType={reportFilter.type}
                    reportFormats={reportFilter.formats}
                    onReportType={reportFilter.setType}
                    onReportFormat={reportFilter.setFormats}
                    isOneTypeAuto
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
