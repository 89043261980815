import { useDispatch, useSelector } from 'react-redux';
import {
    loadNewMap,
    clearNewMap,
} from '../../../redux/Incidents/actions';
import { incidentsSelectors } from '../../../redux/Incidents';


// хук - данные для тепловой карты
const useNewMap = () => {
    const dispatch = useDispatch();

    const newMap = useSelector(incidentsSelectors.newMap);
    const newMapLoading = useSelector(incidentsSelectors.newMapLoading);
    return {
        data: newMap,
        isData: newMap.length > 0,
        load: (params) => {
            dispatch(loadNewMap(params));
        },
        clear: () => {
            dispatch(clearNewMap());
        },
        loading: newMapLoading
    };
};

export default useNewMap;
