import { useEffect, useMemo } from 'react';
import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import messages from '../../../../../helpers/constants/messages';
import { List, ListItem, ListItemText } from '@mui/material';
import { loadCurrentTrafficScheduleEvents, loadTrafficSchedule, loadTrafficScheduleStatuses } from '../../../../../redux/TransportPassenger/actions';
import { useStoreProp } from '../../../../../helpers/hooks';
import { fullDateTimeWithoutSeconds } from '../../../../../helpers/date.config';
import { useDispatch, useSelector } from 'react-redux';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import Loading from '../../../../common/Loading';
import EventTable from '../TrafficSchedule/EditTrafficScheduleTable/EventTable';

const renderStatusColor = (status) => {
    const color = {
        1: { color: '#00ae5c' },
        2: { color: '#444' },
    };
    return color[status];
};

const renderDaysOfWeek = (str) => {
    const week = {
        0: { name: 'пн' },
        1: { name: 'вт' },
        2: { name: 'ср' },
        3: { name: 'чт' },
        4: { name: 'пт' },
        5: { name: 'сб' },
        6: { name: 'вс' },
    };

    const arrOfName = [];
    
    let i = 0;
    while (i < str.length) {
        str[i] === '1' && arrOfName.push(week[i]?.name);
        i++;
    }

    return arrOfName;
};

const TrafficScheduleModal = ({ isOpen, onClose, id }) => {
    const dispatch = useDispatch();
    const trafficScheduleData = useSelector(transportPassengerSelectors.trafficScheduleData);
    const loadingTrafficSchedule = useSelector(transportPassengerSelectors.loadingTrafficSchedule);
    const trafficScheduleEvents = useSelector(transportPassengerSelectors.trafficScheduleEvents);
    const trafficScheduleEventsLoading = useSelector(transportPassengerSelectors.trafficScheduleEventsLoading);
    const statuses = useStoreProp(loadTrafficScheduleStatuses, 'transportPassenger', 'trafficScheduleStatuses');

    const data = useMemo(() => trafficScheduleData.find((el) => el.id === id), [id, trafficScheduleData]);
    const events = useMemo(() => trafficScheduleEvents?.[data?.id], [data?.id, trafficScheduleEvents]);

    useEffect(() => {
        if (id && (id !== data?.id)) {
            dispatch(loadTrafficSchedule( 1, 25, { id }));
        }
    }, [data?.id, dispatch, id]);

    useEffect(() => {
        if (data?.id && !events) {
            dispatch(loadCurrentTrafficScheduleEvents(data?.id));
        }
    }, [data?.id, dispatch, events]);
    
    return (
        <Modal
            title={'График движения'}
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            maxWidthProp={'lg'}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    }
                ]}
            />}
        >
            <form className="modal__form">
                {loadingTrafficSchedule && <Loading linear={true}/>}
                {data?.id
                    ? <>
                        <List className="list">
                            <ListItem className="list_item">
                                <ListItemText>
                                    {data?.name && <div><strong>{data?.name}</strong></div>}
                                    <div>
                                        {data?.diagram && (
                                            <span><strong>№ графика:</strong> {data?.diagram}</span>
                                        )}{' '}
                                        {data?.shift && (
                                            <span><strong>№ смены:</strong> {data?.shift}</span>
                                        )}
                                    </div>
                                    <div>
                                        {data?.start_at && (
                                            <span><strong>Начало:</strong>&nbsp;
                                                {fullDateTimeWithoutSeconds(data?.start_at)}
                                            </span>
                                        )}{' '}
                                        {data?.end_at && (
                                            <span><strong>Окончание:</strong>&nbsp;
                                                {fullDateTimeWithoutSeconds(data?.end_at)}
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        {data?.type_text && (
                                            <span><strong>Тип:</strong>&nbsp;
                                                {data?.type_text}
                                            </span>
                                        )}{' '}
                                        {data?.days_week && (
                                            <>
                                                <strong>Дни недели:</strong>&nbsp;
                                                {renderDaysOfWeek(data?.days_week)?.length > 0
                                                    ? renderDaysOfWeek(data?.days_week)
                                                        ?.map((render,index) =>
                                                            <span key={render}>
                                                                {render}
                                                                {(renderDaysOfWeek(data?.days_week)?.length - 1) !== index
                                                                    ? ', '
                                                                    : ''
                                                                }
                                                            </span>)
                                                    : ' Дни недели не установлены'}
                                            </>
                                        )}{' '}
                                        {data?.is_holiday && (
                                            <span>
                                                <strong>Праздничные дни:</strong>&nbsp;
                                                {data?.is_holiday ? 'да' : 'нет'}
                                            </span>
                                        )}
                                    </div>
                                    {data?.organization?.title.length > 0 && (
                                        <div>
                                            <strong>Организация-перевозчик:</strong>&nbsp;
                                            {data?.organization?.title}
                                        </div>
                                    )}
                                    {data?.status && (
                                        <div>
                                            <strong>Статус:</strong>&nbsp;
                                            <span style={{ color: renderStatusColor(data?.status)?.color }}>
                                                {statuses[data?.status]}
                                            </span>
                                        </div>
                                    )}
                                </ListItemText>
                            </ListItem>
                        </List>

                        {trafficScheduleEventsLoading && <Loading linear={true}/>}
                        {events
                            ? <EventTable 
                                data={events} 
                                hideIcons={true}
                                hideChangeTypeIcon={true}
                                disableChange
                            />
                            : (!trafficScheduleEventsLoading && 'Нет событий в данном графике')
                        }
                    </>
                    : (!loadingTrafficSchedule && messages.NO_DATA)
                }
                
            </form>
        </Modal>
    );
};

export default TrafficScheduleModal;
