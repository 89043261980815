import { IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import messages from '../../../../../helpers/constants/messages';
import titles from '../../../../../helpers/constants/titles';
import CloseIcon from '@mui/icons-material/Close';

const SelectedCameras = ({ data, unselectCamera }) => {
    return (
        <List className="list">
            {data.map(el => (
                <ListItem
                    className="list_item"
                    key={el.id} 
                    el={el} 
                >
                    <ListItemText>
                        <div>
                            <span><strong>{titles.CAMERA}:</strong> {el.name || messages.NO_DATA}</span>
                        </div>
                    </ListItemText>
                    <Tooltip title="Убрать из группы">
                        <IconButton onClick={() => unselectCamera(el.id)} size="small">
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </ListItem>
            )
            )}
        </List>
    );
};

export default SelectedCameras;