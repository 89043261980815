import { LSContentColumn, LSContentItem } from 'components/common/List';
import { getHumanDate } from 'helpers/date.config';
import messages from 'helpers/constants/messages';

const Item = ({ item }) => {
    return (
        <LSContentItem>
            <LSContentColumn>{item?.vehicle_number || messages.NO_VALUE}</LSContentColumn>
            <LSContentColumn>{item.route_num || messages.NO_VALUE} – {item.route_name}</LSContentColumn>
            <LSContentColumn>{item.traffic_schedule_name || messages.NO_VALUE}</LSContentColumn>
            <LSContentColumn>{item?.flight_id || messages.NO_VALUE}</LSContentColumn>
            <LSContentColumn>{item.date_at ? getHumanDate(item.date_at) : messages.NO_VALUE}</LSContentColumn>
            <LSContentColumn>{item?.time_start || messages.NO_VALUE}</LSContentColumn>
            <LSContentColumn>{item?.time_end || messages.NO_VALUE}</LSContentColumn>
            <LSContentColumn>{item?.mileage || messages.NO_VALUE}</LSContentColumn>
        </LSContentItem>
    );
};

export default Item;
