import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import titles from '../../../../../helpers/constants/titles';
import { useValidation } from '../../../../../helpers/hooks';
import {
    createScheduledReport,
    editScheduledReport,
} from '../../../../../redux/Reports/actions';
import SingleKeyboardDateTimePicker from '../../../../common/Dates/SingleKeyboardDateTimePicker';
import Modal from '../../../../common/Modal';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import {
    fullDateTimeWithTimeZone
} from '../../../../../helpers/date.config';
import buttons from '../../../../../helpers/constants/buttons';
import ScheduledReportsTemplatesModal from '../ScheduledReportsTemplatesModal';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import { authSelectors } from '../../../../../redux/Auth';
import FormInfoWrapper from '../../../../common/FormInfoWrapper';


const ModalForm = ({ 
    isOpen = false,
    onClose = () => {},
    onSuccess = () => {},
    item = {},
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const userMail = useSelector(authSelectors.authUserInfo)?.email || '';

    const initialState = {
        ...item,
        email: item?.email || userMail,
        date_start: item?.date_start ? new Date(item?.date_start) : '',
        template_id: item?.template_id || '',
        // user_id: item?.user_id || '',
        // filter: item?.filter || {},
    };

    const [fields, setFields] = useState(initialState);

    const isDisabled = !fields.template_id;

    const isEdit = !!item?.id;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({ ...fields, [name]: value });
        validation.deleteKey(name);
    };

    const handleChangeF = (name) => (value) => {
        setFields(old => ({ ...old, [name]: value }));
        validation.deleteKey(name);
    };

    const handleSaved = () => {
        onClose?.();
        onSuccess?.();
    };

    const handleSave = () => {
        const newData = {
            ...fields,
            date_start: fields.date_start ? fullDateTimeWithTimeZone(fields.date_start) : '',
        };
        const remData = removeEmptyFields(newData, false);
        remData.filter = {};

        if (item.id) {
            dispatch(editScheduledReport(item.id, remData, handleSaved));
        } else {
            dispatch(createScheduledReport(remData, handleSaved));
        }
        validation.clear();
    };

    return <>
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={`${item.id ? buttons.EDIT : buttons.ADD} отчет`}
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSave,
                            disabled: isDisabled
                        }
                    ]}
                />
            }
        >
            <div className="modal__form">

                <div className="block">
                    <ScheduledReportsTemplatesModal
                        selected={fields.template_id}
                        onChange={handleChangeF('template_id')}
                        error={!fields.template_id || validation.isKey('template_id')}
                        helperText={validation.get('template_id')}
                        disabled={isEdit}
                    />
                </div>

                <FormInfoWrapper
                    error={validation.isKey('email')}
                    helperText={validation.get('email') || `По умолчанию: ${userMail}`}
                >
                    <TextField
                        value={fields.email}
                        name="email"
                        onChange={handleChange}
                        className="block"
                        label={titles.EMAIL}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <div className="block">
                    <SingleKeyboardDateTimePicker
                        onChange={(value) => setFields(old => ({ ...old, 'date_start': value }))}
                        value={fields.date_start}
                        dateOnly={true}
                        label="C какой даты"
                        error={validation.isKey('date_start')}
                        helperText={validation.get('date_start')}
                    />
                </div>

            </div>
        </Modal>
    </>;
};

export default ModalForm;
