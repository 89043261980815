import { useState, useEffect, useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, createUser } from '../../../../redux/Admin/actions';
import Form from './EditorForm';
import messages from '../../../../helpers/constants/messages';
import UserItem from './Item';
import FormButtons,{ buttonsTypes } from '../../../common/FormButtons';
import titles from '../../../../helpers/constants/titles';
import Context from '../../../../helpers/context';
import Filter from './Filter';
import { adminSelectors } from '../../../../redux/Admin';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';

const UserManagment = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const users = useSelector(adminSelectors.users);
    const loading = useSelector(adminSelectors.loading);
    
    const [modalVisible, setModalVisible] = useState(false);
    const userList = users.data;

    const [params, setParams] = useState({ page: 1, data: {} });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const loadUserList = useCallback(() => {
        dispatch(getUsers({ page: params.page, limit, ...params.data }));
    },[dispatch, params.page, limit, params.data]);

    useEffect(() => {
        loadUserList();
    }, [loadUserList]);

    const handleClose = () => {
        setModalVisible(false);
        loadUserList();
    };

    const renderContent = () => {
        return (userList.length > 0
            ? (<>
                <LSContainer
                    headers={[
                        { title: 'ФИО', width: '25%' },
                        { title: titles.POSITION, width: '30%' },
                        { title: titles.ORGANIZATION, width: '35%' },
                        { title: 'Действия', isActions: true }
                    ]}
                >
                    {userList.map((item) => (
                        <UserItem 
                            key={item.id} 
                            item={item}
                            loadUserList={loadUserList}
                        />
                    ))}
                </LSContainer>
            </>)
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <>
            <PageLayout
                header="Управление пользователями"
                filters={<Filter setParams={setParams} />}
                informPanelProps={{ 
                    buttons: (
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setModalVisible(true),
                                    disabled: !permissions?.is_create
                                }
                            ]}
                            noPadding
                            noMarginLeft
                        />
                    ),
                    total: users?.meta?.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: users?.meta,
                    limit,
                    setLimit
                }}
            />
            {/* модальное окно добавления нового элемента */}
            {modalVisible
                && <Form
                    onClose={handleClose}
                    isOpen={modalVisible}
                    onSuccess = {(newData) => dispatch(createUser(newData, handleClose))}
                    title={titles.CREATE_USER}
                />
            }
        </>
    );
};

export default UserManagment;
