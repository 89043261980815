import TrafficLights from '../../pages/Dictionaries/TrafficLights/TrafficLights';
import TrafficLightsSettings from '../../pages/Dictionaries/TrafficLights/Settings';

const routes = [
    {
        path: '/dictionaries/traffic-lights',
        component: TrafficLights,
        exact: true
    },
    {
        path: '/traffic-lights/settings',
        component: TrafficLightsSettings,
        exact: true
    },
];

export default routes;