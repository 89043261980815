import styles from './mobile-app.module.scss';
import { Button } from '@mui/material';

const ButtonsComponent = ({ title, data }) => {
    return (
        <>
            <h2>{title}</h2>
            <div className={styles.buttonsBlock}>
                {data.map((item, i) =>
                    <div
                        key={`${item.title}_${i}`}
                    >
                        <Button
                            className={item.title === 'Да' ? styles.rightButton : styles.leftButton}
                            variant={item.title === 'Да' ? 'contained' : 'outlined'}
                        >
                            {item.title}
                        </Button>
                        <div
                            className={item.title === 'Да' ? styles.text : styles.noText}
                        >
                            {item.count}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ButtonsComponent;