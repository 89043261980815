import React, { useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadAllTrafficSchedule, loadTrafficSchedule } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import titles from 'helpers/constants/titles';
import {  fullDateTime } from 'helpers/date.config';
import UniversalSelect from 'components/common/UniversalSelect/index-new';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const SelectTrafficSchedule = ({
    selected = [],
    onChange = () => {},
    multiple = false,
    filter = {},
    required = false,
    searchTitle = 'Найти по номеру и названию',
    isDisabled = false,
    label = titles.TRAFFIC_SCHEDULE,
    withSearch = true,
    readOnly = false,
    limitTags = 1,
    error= required && (multiple ? selected.length === 0 : !selected?.id),
    helperText = '',
    selectAll = false,
    is_simple = 1,
}) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [list, setList] = useState([]);
    const [meta, setMeta] = useState();
    const listLoading = useSelector(transportPassengerSelectors.loadingTrafficSchedule);
    const initialState = multiple ? [] : {};

    const getList = (params) => {
        const { page, query } = params;
        const data = {
            ...(query && { query }),
            ...filter,
            is_simple,
        };
        // убираем пока, т.к. несколько тысяч записей
        // if (selectAll) {
        //     dispatch(loadAllTrafficSchedule(data, ({ data }) => setList(data)));
        // }
        dispatch(loadTrafficSchedule(page, 25, data, ({ data, meta }) => {
            setList(data);
            setMeta(meta);
        }));
    };

    const handleClose = () =>{
        setShowList(false);
        setList([]);
        setMeta();
    };

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
        setList([]);
        setMeta();
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                error={error}
                helperText={helperText}
                required={required}
                inputName="traffic_schedule"
                disabled={isDisabled}
                onReset={() => onChange(initialState)}
                renderLabel={(option) => option?.name || ''}
                limitTags={limitTags}
                label={readOnly ? '' : label}
                onShowList={() => setShowList(true)}
                readOnly={readOnly}
                filterSelectedOptions
                isOpen={showList}
            />

            {showList
                && <UniversalSelect
                    list={list}
                    multiple={multiple}
                    fetchList={getList}
                    loading={listLoading}
                    keyProp="id"
                    withSearch={withSearch}
                    selected={selected}
                    renderProps={(el) =>
                        <div>
                            <h2>График №{el?.diagram}  -  Маршрут №{el?.route_direct?.num || el?.route_direct_num}  -  {el?.name}</h2>
                            <p><strong>Действует с </strong>
                                {el.start_at ? fullDateTime(el.start_at) : 'Нет информации'}
                                <strong> по </strong>
                                {el.end_at ? fullDateTime(el.end_at) : 'Нет информации'}
                            </p>
                        </div>
                    }
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    searchTitle={searchTitle}
                    readOnly={readOnly}
                    title={titles.SELECT_TRAFFIC_SCHEDULE}
                    meta={meta}
                />
            }
        </>
    );
};

export default SelectTrafficSchedule;
