import { HttpError } from './errorTypes/http.error';
import { HttpAuthError } from './errorTypes/http.auth.error';
import { ValidationError } from './errorTypes/validation.error';
import { ConnectionError } from './errorTypes/connection.error';
import { UploadError } from './errorTypes/http.upload.error';

const mapStatusToError = {
    401: () => new HttpAuthError(),
    409: (data) =>  new UploadError(data.errors),
    422: (data) => {
        const message = [data.error_description, data.hint].filter((a) => a).join(': ');
        return new ValidationError({ message, validationData: data.message });
    },
    // todo обрабатывать сообщения ?
    DEFAULT: (data, status) => new HttpError(data.message || data.error_description, status)
    // DEFAULT: (data, status) => new HttpError(data.error_description, status)
};

const createErrorFromAxiosError = (error) => {
    if (error.response) {
        const { data, status } = error.response;
        return (mapStatusToError[status] || mapStatusToError.DEFAULT)(data, status);
    } else if (error.request) {
        return new ConnectionError();
    }
    return error;
};

export default createErrorFromAxiosError;
