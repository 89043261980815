import React, { useEffect, useState } from 'react';
import SidebarItem from '../../../SidebarItem';
import SidebarFilter from '../../../SidebarFilter';
import { Scrollbars } from 'react-custom-scrollbars';
import * as actions from '../../../../../redux/RoadNetwork/actions';
import { roadNetworksSelectors } from '../../../../../redux/RoadNetwork';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../common/Loading';
import Item from './Item';
import { getFilter } from '../helper';
import messages from '../../../../../helpers/constants/messages';
import { isEqual } from 'lodash';
import { usePrevious } from '../../../../../helpers/hooks';
import SidebarTitle from '../../../SidebarTitle';
import getFilters from '../../../helpers/getFilters';
import config from '../config';
import Form from './Form';
import Subscribe from './Subscribe';


const SideBar = (props) => {
    const dispatch = useDispatch();
    const [parameters, setParameters] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
    });

    const sidebar = useSelector(roadNetworksSelectors.overlapList);
    const loading = useSelector(roadNetworksSelectors.loadingOverlapList);
    const filters = useSelector(roadNetworksSelectors.filterOverlap);

    const active = useSelector(roadNetworksSelectors.activeOverlap);

    const prevFilters = usePrevious(filters);

    const activeId = active?.id ?? 0;

    const fetchList = () => {
        const filter = getFilters(filters, getFilter);
        dispatch(actions.loadOverlapList({
            page: parameters.page,
            limit: parameters.limit,
            ...filter,
        }));
    };

    const handleScrollUpdate = (values) => {
        const {
            last_page = 0
        } = sidebar?.meta || {};
        if (
            values.top > 0.98
            && loading === false
            && parameters.page < last_page
        ) {
            setParameters(old => ({
                ...old,
                page: parameters.page + 1,
            }));
        }
    };

    useEffect(() => () => {
        // dispatch(actions.clearSidebarRoadSection());
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            setParameters(old => ({
                ...old,
                page: 1,
            }));
        } else {
            fetchList();
        }
    }, [parameters, filters]);

    // useEffect(() => {
    //     if (filters) {
    //         dispatch(actions.clearSidebarRoadSection());
    //         setParameters(old => ({
    //             ...old,
    //             page: 1,
    //         }));
    //     }
    // }, [filters]);

    const resetParameters = () => {
        setParameters(old => ({
            ...old,
            page: 1,
        }));
    };

    const renderList = (dataList) => (
        <>
            {dataList.map((item) => {
                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        icon={`<i class="${config.layerIcon}" style="color: #ff0000"/>`}
                        onClick={() => {
                            dispatch(actions.setActiveOverlap(item));
                        }}
                    >
                        <Item
                            {...item}
                        />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={resetParameters}
                onSetFilter={(filter) => {
                    // const filterNew = {
                    //     start_at: getStartOf(),
                    //     end_at: getEndOf(),
                    //     ...filter,
                    // };
                    dispatch(actions.setFilterOverlap(filter));
                }}
                onClearFilter={() => dispatch(actions.setFilterOverlap())}
                content={({ data, onChange }) => <Form data={data} onChange={onChange} />}
                layer={config.slug}
            />

            {loading && sidebar?.data?.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={config.name}
                            list={sidebar}
                            buttons={<Subscribe/>}
                        />
                        {sidebar?.data?.length === 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList(sidebar?.data || [])}
                    </div>
                    {loading && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
};

export default SideBar;
