import { useState } from 'react';
import {
    Button,
    Popper,
    MenuList,
    Paper,
} from '@mui/material';
import TableViewIcon from '@mui/icons-material/TableView';

import ConfirmModal from 'components/common/ConfirmModal';
import ActionList from 'components/common/List/Components/ActionList';
import { buttonsTypes } from 'components/common/FormButtons';
import { useFilterButtonsByPermissions } from 'helpers/hooks/Permissons';

import AddEditModal from '../Workorders/AddEditModal';
import TrafficScheduleModal from '../Workorders/TrafficScheduleModal';

const WorkOrderItem = ({ el, onSave, onDelete, anchorEl, openMenu, closeMenu }) => {
    const [openTrafficSchedule, setOpenTrafficSchedule] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const openConfirmDeleteModal = () => {
        closeMenu();
        setConfirmOpen(true);
    };

    const openEditModal = () => {
        closeMenu();
        setIsOpenEdit(true);
    };
    const closeEditModal = () => {
        closeMenu();
        setIsOpenEdit(false);
    };

    const openTrafficScheduleModal = () => {
        closeMenu();
        setOpenTrafficSchedule(true);
    };

    const onEdit = (data) => {
        onSave(el.id, data, closeEditModal);
    };

    const onDeleteEl = () => {
        setConfirmOpen(false);
        onDelete();
    };

    const buttons =[
        ...(el?.status === 1
            ? [{
                ...buttonsTypes.editIcon,
                onClick: openEditModal,
            }]
            : []
        ),
        {
            ...buttonsTypes.deleteIcon,
            onClick: openConfirmDeleteModal,
        },
        {
            icon: <TableViewIcon />,
            name: 'Просмотр графика',
            onClick: openTrafficScheduleModal,
        },
    ];

    const list = useFilterButtonsByPermissions(buttons);

    return (<>
        <Button
            onMouseOver={openMenu}
            size="small"
            variant="text"
            sx={{
                textDecoration: 'underline',
                minWidth: '10px',
                display: 'inline-block',
                fontSize: '1rem',
                padding: '0 4px',
                margin: '0'
            }}
        >
            {el.id}
        </Button>

        {isOpenEdit
            && <AddEditModal
                data={el}
                onClose={closeEditModal}
                isOpen={isOpenEdit}
                onSave={onEdit}
                isCalendar
                disablePeriod
            />
        }

        {openTrafficSchedule
            && <TrafficScheduleModal
                id={el?.traffic_schedule_id}
                isOpen={openTrafficSchedule}
                onClose={() => setOpenTrafficSchedule(false)}
            />
        }

        {confirmOpen
            && <ConfirmModal
                onClose={() => setConfirmOpen(false)}
                onSuccess={onDeleteEl}
                open={confirmOpen}
            />
        }

        {anchorEl
            && <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={closeMenu}
            >
                <Paper>
                    <MenuList>
                        <ActionList
                            handleClose={closeMenu}
                            list={list}
                        />
                    </MenuList>
                </Paper>
            </Popper>
        }
    </>
    );
};

export default WorkOrderItem;
