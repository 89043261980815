import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';
import _ from 'lodash';

import { efficiencySelectors } from 'redux/Efficiency';
import { 
    editCriticalSetting, 
    loadCriticalSettings, 
    loadCriticalSettingsKeys 
} from 'redux/Efficiency/actions';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';

import Item from './Item';
import ModalForm from './ModalForm';
import styles from './styles.module.scss';

const CriticalSettings = () => {
    const dispatch = useDispatch();

    const keys = useStoreProp(loadCriticalSettingsKeys, 'efficiency', 'criticalSettingsKeys');

    const list = useSelector(efficiencySelectors.criticalSettingsData);
    const meta = useSelector(efficiencySelectors.criticalSettingsMeta);
    const loading = useSelector(efficiencySelectors.loadingCriticalSettings);

    const [isOpenModalForm, setIsOpenModalForm] = useState(false);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [page, setPage] = useState(1);

    const reloadList = () => dispatch(loadCriticalSettings(page, limit));

    const [settingsState, setSettingsState] = useState([]);

    useEffect(() => {
        setSettingsState(list);
    }, [list]);

    useEffect(() => {
        reloadList();
    }, [dispatch, limit, page]);

    const handleSaveSetting = () => {
        dispatch(editCriticalSetting(settingsState, reloadList));
    };

    const handleChange = (newValue, id) => {
        // setValue(newValue);
        const newList = settingsState.map(el => el.id === id ? { ...el, value: newValue } : el);
        setSettingsState(newList);
    };

    // убираем из ключей (keys) поля, которые уже добавлены
    const filterKeys = useMemo(() => _.omit(keys?.data || {}, list?.map(item => item.key)), [keys?.data, list]);

    const isDisabled = Object.keys(filterKeys).length === 0;

    const renderContent = () => {
        return (settingsState?.length > 0
            ? (
                <div className={styles.block}>
                    <List>
                        {settingsState.map(item => (
                            <Item
                                key={item?.id}
                                item={item}
                                reloadList={reloadList}
                                handleChange={handleChange}
                            />
                        ))}
                    </List>
                    <div className={styles.btns}>
                        <FormButtons
                            positionLeft
                            noPadding
                            buttons={[
                                {
                                    ...buttonsTypes.cancel,
                                    // disabled: prevValue.current === value,
                                    onClick: () => setSettingsState(list)
                                },
                                {
                                    ...buttonsTypes.save,
                                    // disabled: item.value === value,
                                    onClick: handleSaveSetting
                                },
                            ]}
                        />
                    </div>
                </div>
            )
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <>
            <PageLayout
                header="Настройки критических показателей"
                loading={loading}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtons 
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setIsOpenModalForm(true),
                                        disabled: isDisabled
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>
                    ),
                    total: meta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setPage(page),
                    list: meta,
                    limit,
                    setLimit
                }}
            />

            {isOpenModalForm && (
                <ModalForm
                    isOpen={isOpenModalForm}
                    onClose={() => setIsOpenModalForm(false)}
                    data={filterKeys}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default CriticalSettings;