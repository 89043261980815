import { useState } from 'react';
import cn from 'classnames';
import {
    IconButton,
    ListItem,
    ListItemText,
    Typography
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import makeStyles from '@mui/styles/makeStyles';

import {
    editCamera
} from 'redux/DorisControl/actions';
import titles from 'helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import style from './cameras.module.scss';
import Info from './Info';
import EditorForm from './EditorForm';

const useStyles = makeStyles({
    root: {
        cursor: 'pointer',
    }
});

const CameraItem = ({ item, complexStatusesObj = {} }) => {
    const classes = useStyles();
    const [openToggle, setToggleOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleToggleOpen = () => {
        setToggleOpen(!openToggle);
    };

    const toggleModalWindow = (e) => {
        e && e.stopPropagation();
        setOpenModal(!openModal);
    };

    return (
        <div className={style.cameras__item}>
            {openModal
                && <EditorForm
                    isOpen={openModal}
                    title={titles.EDIT}
                    onClose={() => setOpenModal(false)}
                    onSave={(data) => editCamera(item.id, data)}
                    item={item}
                />
            }
            <ListItem onClick={handleToggleOpen} className={classes.root}>
                <ListItemText className={style.cameras__head}>
                    <div className={style.cameras__head__left}>
                        <i
                            className={cn('fas fa-cctv', style.cameras__head__title)}
                            style={{ color: complexStatusesObj[item.status]?.color || '' }}
                        />
                        <Typography
                            className={style.cameras__head__title}
                        >
                            <strong>{titles.NAME}: </strong>
                            {item.name}
                        </Typography>
                        <Typography
                            style={{ color: complexStatusesObj[item.status]?.color || '' }}
                            className={style.cameras__head__title}
                        >
                            <strong style={{ color: '#000' }}>{titles.CAMERA_STATUS}: </strong>
                            {item.statusText}
                        </Typography>
                    </div>
                </ListItemText>
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: toggleModalWindow,
                        },
                    ]}
                    noMarginLeft
                    justButton
                />
                <IconButton size={'small'}>
                    {openToggle ? (
                        <ExpandLess fontSize={'inherit'}/>
                    ) : (
                        <ExpandMore fontSize={'inherit'}/>
                    )}
                </IconButton>
            </ListItem>
            <Info isOpen={openToggle} item={item}/>
        </div>
    );
};

export default CameraItem;
