import L from 'leaflet';
import iconMap from './icons/iconMap';
import config from './config';
// import iconTypes from './icons/iconTypes';

// цвет иконки от статуса
export const getColorByStatus = (status) => {
    return config.mapMarkersColors[status] || config.mapMarkersColors.default;
};

// получить иконку маркера
export const createIconMarker = (status) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: iconMap({ fill: getColorByStatus(status) })
    });
};

export const getFilter = filter => ({
    ...filter,
    number: filter.number?.trim() || '',
});

// цвет
// export function getRouteColor(categoryId) {
//     switch (parseInt(categoryId)) {
//         case iconTypes.Bus:
//             return {
//                 fill: '#02A600',
//                 outline: '#00442C'
//             };
//         case iconTypes.Trolleybus:
//             return {
//                 fill: '#21AECE',
//                 outline: '#004141'
//             };
//         case iconTypes.Tram:
//             return {
//                 fill: '#E7532C',
//                 outline: '#632000'
//             };
//         default:
//             return {
//                 fill: '#000',
//                 outline: '#fff'
//             };
//     }
// };
