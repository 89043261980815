import React, { useEffect } from 'react';
import {
    RadioGroup,
    Radio,
    FormControlLabel,
} from '@mui/material';
import colorsCB from './colorsCB';

// слои
const HeadSelectedList = (props) => {
    const {
        data = [],
        value = '',
        onChange = (id) => {},
    } = props;

    useEffect(() => {
        if (
            !value
            && data.length > 0
        ) {
            onChange(data[0]?.id?.toString());
        }
    }, [data, value]);

    const handleChange = (name) => () => {
        onChange(name);
    };
    return (
        <RadioGroup
            value={value}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            style={{
                marginBottom: '.5rem',
                textAlign: 'center',
                justifyContent: 'center',
            }}
        >
            {data?.map((item) => (
                <FormControlLabel
                    key={item?.id}
                    value={item?.id}
                    control={
                        <Radio
                            style={{
                                pointerEvents: 'auto',
                                color: colorsCB[item?.id] || '',
                            }}
                        />
                    }
                    label={item?.title || ''}
                    onChange={handleChange(item?.id)}
                />
            ))}
        </RadioGroup>
    );
};

export default HeadSelectedList;