import React, { memo } from 'react';
import {
    IconButton,
    TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useValidation from '../../../../../helpers/hooks/useValidation';
import { RenderColorPicker, AlignPosition, PaddingPosition, LineSpacing, FontSize } from './ModalForm/Fields';
import SelectBoardFonts from '../../../../common/Autocomplete/Boards/Fonts';
import MentionEditor from '../AlertTemplates/AddEdit/MentionEditor';

const RenderTextMessage = memo(({ item, onChange, onDelete, board, serviceEventList }) => {
    const { 
        text = '', 
        color = '#fcff00', 
        font = null, 
        size = 24,
        x = 0,
        y = 0,
        'align-x': alignX = 'center',
        'align-y': alignY = 'center',
        'line-spacing': lineSpacing = 10
    } = item;
    const validation = useValidation();
    
    const handleChange = (e) => {
        const { name, value, type } = e.target;
        onChange({ 
            ...item, 
            [name]: type === 'number' && name !== 'line-spacing'
                ? value < 0 ? 0 : Number(value)
                : value 
        });
    };

    return (
        <div className="row align-items-center">
            <div className="block row__item">
                {/* <div className="block">
                    <TextField
                        required
                        value={text || ''}
                        name={'text'}
                        onChange={handleChange}
                        label="Текст сообщения"
                        variant={'outlined'}
                        size={'small'}
                        error={!text || validation.isKey(`text.${item.messageIndex}.text`)}
                        helperText={validation.get(`text.${item.messageIndex}.text`)}
                    />
                </div> */}
                <div className="block">
                    <MentionEditor
                        text={text}
                        onChange={(value) => handleChange({ target : { name: 'text', value } })}
                        serviceEventList={serviceEventList}
                    />
                </div>
                <div className="row">
                    <SelectBoardFonts
                        label={'Шрифт'}
                        selected={font}
                        filter={{ board_type: board?.type }}
                        onChange={(value) => handleChange({ target : { name: 'font', value } })}
                        required
                        className="row__item"
                        sortedList={board?.fonts}
                        error={!font || validation.isKey(`text.${item.messageIndex}.font`)}
                        helperText={validation.get(`text.${item.messageIndex}.font`)}
                    />
                    <RenderColorPicker color={color} onChange={handleChange} />
                </div>
                <div className="row">
                    <FontSize
                        required
                        handleChange={handleChange}
                        size={size}
                        error={(size === undefined) || validation.isKey(`text.${item.messageIndex}.size`)}
                        helperText={validation.get(`text.${item.messageIndex}.size`)}
                    />
                    <LineSpacing 
                        handleChange={handleChange}
                        lineSpacing={lineSpacing}
                        error={!lineSpacing || validation.isKey(`text.${item.messageIndex}.line-spacing`)}
                        helperText={validation.get(`text.${item.messageIndex}.line-spacing`)}
                    />
                </div>
                <AlignPosition 
                    alignX={alignX} 
                    alignY={alignY} 
                    handleChange={handleChange} 
                    helperTextX={validation.get(`text.${item.messageIndex}.align-x`)}
                    helperTextY={validation.get(`text.${item.messageIndex}.align-y`)}
                />                
                <PaddingPosition 
                    x={x} 
                    y={y} 
                    handleChange={handleChange} 
                    helperTextX={validation.get(`text.${item.messageIndex}.x`)}
                    helperTextY={validation.get(`text.${item.messageIndex}.y`)}
                />                
            </div>

            <IconButton onClick={onDelete} size="small">
                <CloseIcon />
            </IconButton>
        </div>
    );
});

export default RenderTextMessage;