import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import { usePrevious } from '../../../../../helpers/hooks';
import { isEqual } from 'lodash';
import './geoJson.scss';
import onEachFeature from './functions/onEachFeature';
import pointToLayer from './functions/pointToLayer';
import jsonStyle from './functions/style';
import clearLayer from './functions/clearLayer';

// самы новый компонент geoJson (отличие в данных)
const GeoJson = (props) => {
    const defaultProps = {
        data: {
            // type: 'Feature',
            // geometry: {
            //     coordinates: [36.197205, 51.712379],
            //     type: 'Point',
            // },
            // properties: {
            //     color: '#f00',
            //     description: 'test'
            // }
        },
        toolTipOptions: {
            direction: 'top',
            offset: [0, -40]
        },
        centerAfter: false,
        centerByClickBefore: null,
        centerByClickAfter: null,
        style: null, // стили
        idPrefix: '',
        onAfterBounds: null,
        selectedStyle: {
            color: '#00ff00',
        },
        selected: null,
        selectedFn: (properties, selected) => selected?.id === properties?.id || selected?.name === properties?.name,
    };

    const propsWithDefaults = {
        ...defaultProps,
        ...props,
    };

    // data props
    const {
        map,
        parent,
        data = {},
        centerAfter = false,
        onAfterBounds = null,
        selectedStyle,
        selected,
        selectedFn,
        style,
    } = propsWithDefaults;

    const prevData = usePrevious(data);

    // слой json
    const layerRef = useRef(null);
    // id данных для открытия
    const currentIdsRef = useRef([]);
    // context menu
    const contextMenuRef = useRef(null);

    // обработка geoJson объекта
    useEffect(() => {
        // только изменение
        if (!layerRef.current || !isEqual(data, prevData)) {
            clearLayer(layerRef, propsWithDefaults)();

            const json = L.geoJSON(data, {
                // стили
                style: jsonStyle,
                // маркеры + окружность
                pointToLayer: pointToLayer(propsWithDefaults),
                // проход по всем объектам
                onEachFeature: onEachFeature(propsWithDefaults, layerRef, currentIdsRef, contextMenuRef),
            });

            layerRef.current = json;
            json.addTo(parent);

            if (centerAfter) {
                setTimeout(() => {
                    const bounds = json?.getBounds() || null;
                    if (bounds && bounds?.isValid()) map.fitBounds(bounds);
                }, 200);
            }

            if (onAfterBounds) {
                const bounds = json?.getBounds() || null;
                if (bounds) onAfterBounds(json.getBounds());
            }
        }
    }, [data, prevData, map, parent/*, layerRef.current*/]);

    // изменения по стилям
    useEffect(() => {
        if (layerRef.current) {
            layerRef?.current?.resetStyle();
            // layerRef?.current?.setStyle({
            //     ...style
            // });
            // layerRef?.current?.setZIndex(100);
            // if (selected) {
            {
                layerRef?.current?.eachLayer(function (layer) {
                    const properties = layer?.feature?.properties || {};
                    const styleO = layer?.feature?.style || {};
                    layer?.setStyle?.({
                        ...style,
                        ...styleO,
                    });
                    if (selected && selectedFn(properties, selected)){
                        layer?.setStyle?.({
                            ...selectedStyle
                        });
                        // делаем поверх
                        layer?.bringToFront?.();
                    }
                });
            }
        }
    }, [layerRef.current, selected, selectedStyle, style]);

    // очистка слоя
    useEffect(() => {
        return () => {
            clearLayer(layerRef, propsWithDefaults)();
        };
    }, []);

    return null;
};

export default GeoJson;
