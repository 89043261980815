import React, { useEffect, useMemo, useRef } from 'react';
import { config } from '../../../../../config';
import HeatLayer from '../../../../MapComponents/leaflet/components/HeatLayer';
import {
    Map,
    MapControl,
    FeatureGroup,
} from '../../../../MapComponents/leaflet';

// тепловая карта
const HeatMap = ({
    // данные по слоям
    data = [],
    // какие слои отобразить
    showLayers = {},
    regionComponent = null,
}) => {
    const heatLayers = useMemo(() => {
        const isShowLayersId = Object
            .keys(showLayers)
            .reduce((res, id) => showLayers[id] ? [...res, id.toString()] : res, []);

        return data
            ?.filter(({ id }) => isShowLayersId.includes(id.toString()))
            ?.map(({ datas, ...data }, index) => {
                return (
                    <HeatLayer
                        key={index}
                        {...data}
                        json={datas?.data || []}
                        maxZoom={11}
                    />
                );
            });
    }, [showLayers, data]);

    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                marginBottom: '.5rem',
            }}
        >
            <div
                style={{
                    display: 'contents',
                    minHeight: '400px',
                }}
            >
                <Map
                    center={config.get('mapCenter')}
                >
                    <MapControl>

                        <FeatureGroup>
                            {/* регионы */}
                            {regionComponent}
                        </FeatureGroup>

                        <FeatureGroup>
                            {/* слои тепловой карты */}
                            {heatLayers}
                        </FeatureGroup>

                    </MapControl>
                </Map>
            </div>
        </div>
    );
};

export default HeatMap;