import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    stationLine: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
    },
    time: {
        display: 'flex',
        flexDirection: 'column',
        marginRight:'20px',
        marginBottom:'10px',
        minWidth: '200px',
    },
    table: {
        marginTop: '10px',
        borderRight: '2px solid rgba(0,0,0, 1)'
    },
    cells: {
        border: '1px solid rgba(0,0,0, 1)',
    },
});
const RenderTable = ({ currentScheduleEvents }) => {
    const classes = useStyles();

    const renderTimeBlock = (days) => {
        return (
            <>
                {currentScheduleEvents?.[days].map(event => <>
                    <strong>{days === 'week_days' ? 'Рабочие дни ' : 'Выходные и праздничные дни '}</strong>
                    {event?.days_week.map((weekDay, i) => <span key={weekDay}>{weekDay}{i < event?.days_week.length - 1 ? ', ' : ''} </span>)}
                    <div>
                        <TableContainer>
                            <Table className={classes.table} size="small">
                                <TableBody>
                                    {event?.station_list.map((el) => (
                                        <TableRow className={classes.cells} key={el.station_name}>
                                            <TableCell className={classes.cells} align="center">
                                                {el.station_name}
                                            </TableCell>
                                            {el?.time_list?.map((times, i) =>
                                                (<TableCell className={classes.cells} key={i} align="center" >
                                                    {times}
                                                </TableCell>)
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </>)}
            </>
        );
    };

    return (
        <>
            {currentScheduleEvents?.week_days && renderTimeBlock('week_days')}
            {currentScheduleEvents?.holidays && renderTimeBlock('holidays')}
        </>
    );
};

export default RenderTable;
