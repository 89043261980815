import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setValidation } from 'redux/Validation/actions';
import { showMessage } from 'redux/Message/actions';
import { setMapLoading } from 'redux/Map/actions';
import messagetypes from 'redux/Message/messagetypes';
import messages from 'helpers/constants/messages';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

// данные
function* getDeviceDataMeteoSaga({ payload }) {
    yield put(actions.loading(true));
    const response = yield call(api.getDeviceData, payload);
    if (response) {
        yield put(actions.saveDeviceDataMeteo(response));
    }
    yield put(actions.loading(false));
}

// события
function* getEventsMeteoSaga({ payload: { external_id, page, limit }, replaceStore }) {
    yield put(actions.loading(true));
    const response = yield call(api.getEvents, external_id, page, limit);
    if (response) {
        yield put(actions.saveEventsMeteo(response, replaceStore));
    }
    yield put(actions.loading(false));
}

// проекты
function* getProjectsMeteoSaga() {
    const response = yield call(api.getProjects);
    if (response?.data) {
        yield put(actions.saveProjectsMeteo(response.data));
    }
}

// подгрузить типы
function* getDeviceTypesMeteoSaga() {
    const response = yield call(api.getDeviceTypes);
    if (response?.data) {
        yield put(actions.saveDeviceTypesMeteo(response.data));
    }
}

// подгрузить статусы
function* getStatusesMeteo() {
    const response = yield call(api.getStatuses);
    if (response?.data) {
        yield put(actions.saveStatusesMeteo(response.data));
    }
}

// подгрузить полигон
function* getForPolygonMeteoSaga({ payload }) {
    yield put(setMapLoading('meteo', true));
    const response = yield call(api.getForPolygonDevices, payload);
    if (response) {
        yield put(actions.saveForPolygonMeteo(response));
    }
    yield put(setMapLoading('meteo', false));
}

function* getDeviceListMeteoSaga({ payload, isReplace }) {
    yield put(actions.deviceListLoading(true));
    const response = yield call(api.getDeviceList, payload);
    if (response) {
        yield put(actions.saveDeviceListMeteo(response, isReplace));
    }
    yield put(actions.deviceListLoading(false));
}

function* loadMeteoSettingsSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingMeteoSettings(true));
    const response = yield call(api.getMeteoSettings, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedMeteoSettings(response));
    }
    yield put(actions.loadingMeteoSettings(false));
}

function* editMeteoSettingSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.editMeteoSettings, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield put(actions.loadMeteoSettings());
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

function* editSettingsListSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.editSettingsList, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }

    yield put(actions.loadingButton(false));
}

// связи с организациями
function* getDeviceCompanyLinkSaga({ payload }) {
    yield put(actions.loading(true));
    const response = yield call(api.getDeviceCompanyLink, payload);
    if (response) {
        yield put(actions.saveDeviceCompanyLink(response.data));
    }
    yield put(actions.loading(false));
}

function* createDeviceCompanyLinkSaga({ payload }) {
    const { id, sendData, callback } = payload;
    const response = yield call(api.createDeviceCompanyLink, id, sendData);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

function* deleteDeviceCompanyLinkSaga({ payload }) {
    const { id, callback } = payload;
    const response = yield call(api.deleteDeviceCompanyLink, id);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

// подгрузить оповещении
function* loadMeteoAlertsSaga() {
    const response = yield call(api.getAlerts);
    if (response?.data) {
        yield put(actions.loadedMeteoAlerts(response.data));
    }
}

// настройки вывода параметров
function* loadMeteoOutputParamsSaga({ payload }) {
    const { page, limit, query } = payload;
    const response = yield call(api.getMeteoOutputParams, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedMeteoOutputParams(response));
    }
}

function* loadMeteoOutputSettingsSaga() {
    yield put(actions.loadingMeteoOutputSettings(true));
    const response = yield call(api.getMeteoOutputSettings);
    if (response?.data) {
        yield put(actions.loadedMeteoOutputSettings(response.data));
    }
    yield put(actions.loadingMeteoOutputSettings(false));
}

function* editMeteoOutputSettingsSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editMeteoOutputSettings, id, data);
    if (response?.success) {
        callback?.();
        yield put(actions.loadMeteoOutputSettings());
        yield put(showMessage(messagetypes.success, messages.EDIT_SUCCESS));
    }
}

function* deleteMeteoOutputSettingsSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.deleteMeteoOutputSettings, id, data);
    if (response?.success) {
        callback?.();
        yield put(actions.loadMeteoOutputSettings());
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
    }
}

function* loadDevicesGroupSaga({ payload }) {
    yield put(actions.loadingDevicesGroup(true));
    const response = yield call(api.getDevicesGroup, payload);
    if (response?.success) {
        yield put(actions.loadedDevicesGroup(response.data));
    }
    yield put(actions.loadingDevicesGroup(false));
}

function* createDevicesGroupSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createDevicesGroup, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        yield callback();
    }
    yield put(actions.loadingButton(false));
}

function* editDevicesGroupSaga({ payload, callback }) {
    const { id, params } = payload;
    console.log('par =', params);

    yield put(actions.loadingButton(true));
    const response = yield call(api.editDevicesGroup, id, params);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.EDIT_SUCCESS));
        yield callback();
    }
    yield put(actions.loadingButton(false));
}

function* deleteDevicesGroupSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deleteDevicesGroup, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
        yield callback();
    }
    yield put(actions.loadingButton(false));
}

function* loadShowcaseSaga() {
    yield put(actions.loadingShowcase(true));
    const response = yield call(api.loadShowcase);
    if (response?.success) {
        yield put(actions.loadedShowcase(response.data));
    }
    yield put(actions.loadingShowcase(false));
}

function* loadMeteoByRadiusSaga({ payload, callback }) {
    const response = yield call(api.loadMeteoByRadius, payload);
    if (response?.success) {
        yield put(actions.loadedMeteoByRadius(response.data));
        callback(response.data);
    }
}

// settings group
function* loadSettingsGroupSaga() {
    yield put(actions.loadingSettingsGroup(true));
    const response = yield call(api.getSettingsGroup);
    if (response?.success) {
        yield put(actions.loadedSettingsGroup(response));
    }
    yield put(actions.loadingSettingsGroup(false));
}

function* editSettingsGroupSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.editSettingsGroup, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* getDeviceStatusesSaga() {
    const response = yield call(api.getDeviceStatuses);
    if (response?.success) {
        yield put(actions.loadedDeviceStatuses(response.data));
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.GET_DEVICE_DATA, getDeviceDataMeteoSaga),
        takeLatest(types.GET_EVENTS, getEventsMeteoSaga),
        takeLatest(types.GET_PROJECTS, getProjectsMeteoSaga),
        takeLatest(types.GET_TYPES, getDeviceTypesMeteoSaga),
        takeLatest(types.GET_STATUSES, getStatusesMeteo),
        takeLatest(types.GET_POLYGON, getForPolygonMeteoSaga),
        takeLatest(types.GET_LIST, getDeviceListMeteoSaga),
        takeLatest(types.GET_DEVICE_COMPANY_LINK, getDeviceCompanyLinkSaga),
        takeLatest(types.CREATE_DEVICE_COMPANY_LINK, createDeviceCompanyLinkSaga),
        takeLatest(types.DELETE_DEVICE_COMPANY_LINK, deleteDeviceCompanyLinkSaga),
        takeLatest(types.LOAD_METEO_SETTINGS, loadMeteoSettingsSaga),
        takeLatest(types.EDIT_METEO_SETTING, editMeteoSettingSaga),
        takeLatest(types.EDIT_SETTINGS_LIST, editSettingsListSaga),
        takeLatest(types.LOAD_METEO_ALERTS, loadMeteoAlertsSaga),
        takeLatest(types.LOAD_METEO_OUTPUT_PARAMS, loadMeteoOutputParamsSaga),
        takeLatest(types.LOAD_METEO_OUTPUT_SETTINGS, loadMeteoOutputSettingsSaga),
        takeLatest(types.EDIT_METEO_OUTPUT_SETTINGS, editMeteoOutputSettingsSaga),
        takeLatest(types.DELETE_METEO_OUTPUT_SETTINGS, deleteMeteoOutputSettingsSaga),

        takeLatest(types.LOAD_DEVICES_GROUP, loadDevicesGroupSaga),
        takeLatest(types.CREATE_DEVICES_GROUP, createDevicesGroupSaga),
        takeLatest(types.EDIT_DEVICES_GROUP, editDevicesGroupSaga),
        takeLatest(types.DELETE_DEVICES_GROUP, deleteDevicesGroupSaga),
        takeLatest(types.LOAD_SHOWCASE, loadShowcaseSaga),
        takeLatest(types.LOAD_METEO_BY_RADIUS, loadMeteoByRadiusSaga),

        takeLatest(types.LOAD_SETTINGS_GROUP, loadSettingsGroupSaga),
        takeLatest(types.EDIT_SETTINGS_GROUP, editSettingsGroupSaga),

        takeLatest(types.LOAD_DEVICE_STATUSES, getDeviceStatusesSaga),
    ]);
}
