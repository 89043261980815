import Companies from '../../pages/Reports/Companies';

const routes = [
    {
        path: '/reports/companies',
        component: Companies,
        exact: true
    },
];

export default routes;