import { useState } from 'react';
import Modal from './Modal';
import styles from './vehicles.module.scss';
import VehicleListItem from 'components/common/Transport/VehicleReportList/VehicleListItem';

const Item = ({ item, filter, categories }) => {
    const [openModal, setOpenModal] = useState(false);
    const [currentData, setCurrentData] = useState({});

    const category = item.category_id && categories.find(category => category.id === item.category_id);

    return (
        <>
            {openModal && <Modal
                handleCloseModal={() => setOpenModal(false)}
                open={openModal}
                data={currentData}
                filter={filter}
            />}
            <VehicleListItem
                item={item}
                category={category}
                setOpenModal={setOpenModal}
                styles={styles}
                isSpecTransport={true}
                setCurrentData={setCurrentData}
            />
        </>
    );
};

export default Item;
