import { config } from '../../config';

const apiStateContracts = () => config.get('urls').stateContracts;
const apiBaseUrl = () => config.get('urls').apiBaseUrl;

const apiUrls = {

    getGovernmentContracts: () => `${apiStateContracts()}/state-contract`,

    getGovernmentContractById: (id) => `${apiStateContracts()}/state-contract/${id}`,

    createGovernmentContract: () => `${apiStateContracts()}/state-contract/`,

    editGovernmentContract: (id) => `${apiStateContracts()}/state-contract/${id}`,

    deleteGovernmentContract: (id) => `${apiStateContracts()}/state-contract/${id}`,

    getGovernmentContractHistory: (id) => `${apiStateContracts()}/state-contract/${id}/history`,

    getContractEntitiesByName: (id, entity_name) => `${apiStateContracts()}/state-contract/entities/${id}/${entity_name}`,

    getContractEntitiesTypes: () => `${apiStateContracts()}/state-contract/entities/types`,

    getContractLinkObjects: (url) => `${apiBaseUrl()}/${url}`

};

export default apiUrls;
