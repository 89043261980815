import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import formatDate from 'helpers/constants/dateFormat';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { getEndOf, getStartOf, fullDateTimeWithTimeZone } from 'helpers/date.config';
import SelectVehicles from 'components/common/Autocomplete/Transport/Vehicles';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import { clearReportByVehicles, loadReportByVehicles } from 'redux/TransportSpecial/actions';
import type { Filters } from './types';

const Filter = () => {
    const initialState = {
        start_date: getStartOf('month'),
        end_date: getEndOf('day'),
        vehicle_id_list: [],
        mileage_by_coordinates: 1,
    };

    const dispatch = useDispatch();
    const [fields, setFields] = useState<Filters>(initialState);
    const [isDisabledDate, setIsDisabledDate] = useState(false);

    const getFilter = useCallback((data) => {
        const newFilter = {
            ...data,
            start_date: fullDateTimeWithTimeZone(data.start_date),
            end_date: fullDateTimeWithTimeZone(data.end_date),
            vehicle_id_list: data.vehicle_id_list?.map(({ id }: { id: number }) => id) || null,
        };
        return removeEmptyFields(newFilter);
    }, []);

    const onAccept = useCallback((data) => {
        dispatch(loadReportByVehicles(getFilter(data)));
    }, [dispatch, getFilter]);

    const resetFilter = useCallback(() => {
        dispatch(clearReportByVehicles());
        setFields(initialState);
    }, []);

    const handleFind = useCallback(() => {
        onAccept && onAccept(fields);
    }, [fields, onAccept]);

    const handleChangeDate = useCallback((value, name) => {
        setFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    }, []);

    const errorDateRange = useCallback(() => {
        const { start_date = null, end_date = null } = fields;
        return !start_date || !end_date;
    }, [fields]);

    const isDisabled = useMemo(() => {
        const {
            vehicle_id_list = null
        } = fields;
        return !vehicle_id_list?.length || isDisabledDate;
    }, [fields, isDisabledDate]);

    const updateFilter = (filters: Filters) => {
        if (Object.keys(filters).length > 0) {
            const mergedFilters = { ...initialState, ...filters };

            setFields(mergedFilters);
            if (filters.vehicle_id_list.length > 0) {
                onAccept(mergedFilters);
            }
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={handleFind}
            filter={fields}
            setUserFilter={updateFilter}
            disabled={isDisabled}
            filterException={['start_date', 'end_date']}
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(newDate: Date | null) => {
                        handleChangeDate(newDate, 'start_date');
                    }}
                    handleEndDate={(newDate: Date | null) => {
                        handleChangeDate(newDate, 'end_date');
                    }}
                    valueStartDate={fields?.start_date || null}
                    valueEndDate={fields?.end_date || null}
                    isDisabledHandler={(value: boolean) => setIsDisabledDate(value)}
                    renderError={errorDateRange}
                    pickersFormat={formatDate.DT_EXCEPT_SECONDS}
                    required
                />
                <SelectVehicles
                    onChange={(value) => handleChangeDate(value, 'vehicle_id_list')}
                    selected={fields.vehicle_id_list || []}
                    multiple
                    storeName="transportSpecial"
                    required
                    error={fields?.vehicle_id_list?.length === 0}
                    limitTags={1}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default memo(Filter);
