import { useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import {
    closestCenter,
    DndContext, 
    DragOverlay,
    KeyboardSensor,
    MouseSensor, 
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import EventItem from './EventItem';

const EventTable = (
    {
        data = [],
        id,
        handleDelete = () => {},
        hideIcons = false,
        hideChangeTypeIcon = false,
        handleAdd = () => {},
        handleEdit = () => {},
        handleDragEnd = () => {},
        handleDragStart = () => {},
        handleDragCancel = () => {},
        activeId,
    }) => {

    const items = useMemo(() => data?.map(el => el.number), [data]);

    const selectedRow = useMemo(() => {
        if (!activeId) {
            return null;
        } else {
            return data.find((el) => el.number === activeId);
        }
    }, [activeId, data]);

    const sensors = useSensors(
        useSensor(PointerSensor,{}),
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {}),
    );

    return (
        <TableContainer component={Paper}>
            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                onDragCancel={handleDragCancel}
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis]}

            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">№</TableCell>
                            <TableCell align="center">Cобытиe</TableCell>
                            <TableCell align="center">Направление</TableCell>
                            <TableCell align="center">Тип рейса</TableCell>
                            <TableCell align="center">Начало</TableCell>
                            <TableCell align="center">Окончание</TableCell>
                            <TableCell align="center">Пробег, км</TableCell>
                            <TableCell align="center">Длительность, чч:мм</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <SortableContext
                            items={items}
                            strategy={verticalListSortingStrategy}
                        >
                            {data?.length > 0 && data?.map((row, index) => {
                                return (
                                    <EventItem
                                        key={row?.number}
                                        indexElement={index}
                                        row={row}
                                        handleDelete={handleDelete}
                                        handleAdd={handleAdd}
                                        handleEdit={handleEdit}
                                        hideIcons={hideIcons}
                                        hideChangeTypeIcon={hideChangeTypeIcon}
                                        lastElement={data.length === 1}
                                    />
                                );
                            })}
                        </SortableContext>
                    </TableBody>
                </Table>
                <DragOverlay>
                    {activeId && (
                        <EventItem
                            row={selectedRow}
                        />
                    )}
                </DragOverlay>
            </DndContext>
        </TableContainer>
    );
};

export default EventTable;