import React from 'react';
import renderAddress from '../../../../../helpers/renderAddress';
import { getHumanDate } from '../../../../../helpers/date.config';
import useStoreProp from '../../../../../helpers/hooks/useStoreProp';
import { loadStatuses } from '../../../../../redux/Parking/actions';
import messages from '../../../../../helpers/constants/messages';


const Item = (props) => {
    const {
        created_at,
        address_text,
        address = {},
        name,
        category_id,
        types,
    } = props;

    return (
        <>
            <div>
                <strong>Название:</strong> {name || messages.NO_DATA}
            </div>
            <div>
                <strong>Дата создания:</strong> {getHumanDate(created_at) || messages.NO_DATA}
            </div>
            {address_text || address
                ? (
                    <div>
                        <strong>Адрес дислокации:</strong> {address_text || renderAddress(address) || messages.NO_DATA}
                    </div>
                )
                : null
            }
            <div>
                <strong>Категория:</strong> {types?.[category_id] || messages.NO_DATA}
            </div>
        </>
    );
};

export default Item;
