import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from '@mui/material';

import { loadDtpLoadTypes, loadDtpStreets } from 'redux/Incidents/actions';
import { loadDtpStatuses } from 'redux/Incidents/actions';
import { loadTransportIncidents } from 'redux/SituationalPlans/actions';
import { incidentsSelectors } from 'redux/Incidents';
import titles from 'helpers/constants/titles';
import formatDate from 'helpers/constants/dateFormat';
import { useStoreProp, useValidation } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import buttons from 'helpers/constants/buttons';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import UniversalSelect from 'components/common/UniversalSelect';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import FieldsModal from 'components/common/Location/FieldsModal';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import AddressOne from 'components/common/Location/AddressOne';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import TransportIncidentInfoModal from 'components/pages/Reports/DTP/Incidents/TransportIncidentInfoModal';

const BasicData = (props) => {
    const {
        fields = {},
        onChange,
        requiredFields,
        typesList = [],
        isTi
    } = props;

    const dispatch = useDispatch();
    const validation = useValidation();

    const dtpStreets = useSelector(incidentsSelectors.dtpStreets);
    const dtpStreetsLoading = useSelector(incidentsSelectors.dtpStreetsLoading);
    const statuses = useStoreProp(loadDtpStatuses, 'incidents', 'statuses');
    const loadTypes = useStoreProp(loadDtpLoadTypes, 'incidents', 'loadTypes');

    // const [showList, setShowList] = useState(false);
    const [openTIInfo, setOpenTIInfo] = useState(false);

    const handleEvent = (key) => (newValue) => {
        const { target: { value } } = newValue;

        if (newValue.target.type !== 'number') {
            onChange({
                [key]: value
            });
        }
        if (newValue.target.type === 'number' && Number(value) >= 0) {
            onChange({
                [key]: Number(value)
            });
        }

    };

    const handleEventAC = (key) => (_, newValue) => {
        onChange({
            [key]: newValue
        });
    };

    const handleLocationChange = (props) => {
        onChange({
            ...props
        });
    };

    const handleDateChange = (key, value) => {
        onChange({
            [key]: value
        });
    };

    const getValue = (key, def = '') => fields[key] ? fields[key] : def;
    const getValueInt = (key) => fields[key] || '';

    const isErrorField = (key) => {
        return (requiredFields.includes(key) && !fields[key]) || validation.isKey(key);
    };

    const getErrorValidation = (key) => validation.get(key);

    // const getIncidents = (params) => {
    //     const { page, limit, query } = params;
    //     dispatch(loadTransportIncidents(page, limit, { name: query }));
    // };

    // const handleAccept = (data) => {
    //     onChange({
    //         ti_id: data,
    //         ti_name: data.name
    //     });
    //     setShowList(false);
    // };

    return <>
        <div className="modal__form">
            <div className="block">
                <FormControl
                    size="small"
                    variant="outlined"
                    error={!fields.status}
                    disabled={fields.type_load === 2}
                >
                    <InputLabel>{titles.STATUS}</InputLabel>
                    <Select
                        value={getValue('status')}
                        label={titles.STATUS}
                        onChange={(e) => onChange({
                            status: e.target.value,
                        })}

                    >
                        {Object.keys(statuses)?.length
                            ? Object.keys(statuses)?.map((key) =>
                                <MenuItem value={key} key={key}>{statuses[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </div>

            <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={getValue('dtp_type')}
                    isOptionEqualToValue={(option, value) => option.id === value}
                    options={typesList}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={handleEventAC('dtp_type')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.DTP_TYPE}
                            error={isErrorField('dtp_type') || params?.error}
                            helperText={getErrorValidation('dtp_type') || params?.helperText}
                        />
                    )}
                />
            </div>

            <div className="block">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={fields.manual_edit}
                            onChange={handleEventAC('manual_edit')}
                            color="primary"
                        />
                    }
                    label="Ручное редактирование"
                />
            </div>

            <div className="block">
                <SingleKeyboardDateTimePicker
                    onChange={(value) => handleDateChange('dtp_at', value)}
                    value={getValue('dtp_at', null)}
                    error={isErrorField('dtp_at')}
                    helperText={getErrorValidation('dtp_at')}
                    pickerFormat={formatDate.DT_EXCEPT_SECONDS}
                    isDisabled={!!fields?.ti_id}
                />
            </div>

            <FormControl className="block" variant="outlined">
                <LatLonCoordinates
                    {...fields}
                    onChange={handleLocationChange}
                    required
                />
            </FormControl>

            <FieldsModal
                title="Поставьте/передвиньте метку на карте"
                buttonText={buttons.SELECT_ON_MAP}
                buttonVariant="contained"
                fields={fields}
                onChange={handleLocationChange}
            >
                <MapDragMarker
                    required
                />
            </FieldsModal>

            <FormControl className="block" variant="outlined">
                <LoadAddressByCoords
                    {...fields}
                    onChange={handleLocationChange}
                >
                    <AddressOne
                        required
                    />
                </LoadAddressByCoords>
            </FormControl>

            <div className="block">
                <Autocomplete
                    value={getValue('intersection')}
                    options={dtpStreets}
                    onChange={(e, value) => onChange({ intersection: value })}
                    getOptionLabel={(option) => option}
                    freeSolo
                    autoSelect
                    loading={dtpStreetsLoading}
                    loadingText="Загрузка..."
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.INTERSECTION_STREET}
                            onChange={(e) => dispatch(loadDtpStreets(e.target.value))}
                        />
                    )}
                />
            </div>

            <div className="block">
                <TextField
                    size="small"
                    label="Километр (если авария на дороге)"
                    variant="outlined"
                    value={getValueInt('km')}
                    onChange={handleEvent('km')}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    error={isErrorField('km')}
                    helperText={getErrorValidation('km')}
                />
            </div>

            <div className="block">
                <TextField
                    size="small"
                    label="Метр (если авария на дороге)"
                    variant="outlined"
                    value={getValueInt('m')}
                    onChange={handleEvent('m')}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    error={isErrorField('m')}
                    helperText={getErrorValidation('m')}
                />
            </div>

            <div className="block">
                <TextField
                    multiline={true}
                    size="small"
                    label={titles.DTP_DESCRIPTION}
                    variant="outlined"
                    value={getValue('description')}
                    onChange={handleEvent('description')}
                    error={isErrorField('description')}
                    helperText={getErrorValidation('description')}
                />
            </div>

            <div className="block">
                <TextField
                    size="small"
                    label={titles.SCHEMA_DTP}
                    variant="outlined"
                    value={getValue('schema_img_url')}
                    onChange={handleEvent('schema_img_url')}
                    error={isErrorField('schema_img_url')}
                    helperText={getErrorValidation('schema_img_url')}
                />
            </div>

            <div className="block">
                <FormControl variant="outlined" size="small" >
                    <InputLabel disabled={isTi}>Источник загрузки данных</InputLabel>
                    <Select
                        value={getValue('type_load')}
                        label="Источник загрузки данных"
                        onChange={(e) => onChange({
                            type_load: e.target.value,
                            type_load_text: loadTypes[e.target.value ]
                        })}
                        disabled={isTi}
                    >
                        {Object.keys(loadTypes)?.length
                            ? Object.keys(loadTypes)?.map((key) =>
                                <MenuItem value={key} key={key}>{loadTypes[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </div>

            <div style={{ marginBottom: 10 }}>
                <strong>Связанное событие:</strong> {fields.ti_name || messages.NO_DATA}
                <Tooltip title={titles.SHOW}>
                    <span>
                        <IconButton onClick={() => setOpenTIInfo(true)} size="small" disabled={!fields.ti_id}>
                            <i className="far fa-arrows"/>
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
            {openTIInfo && (
                <TransportIncidentInfoModal
                    transportIncidentId={fields?.ti_id}
                    onClose={() => setOpenTIInfo(false)}
                />
            )}

            {/* <TextField
                className="block"
                value={getValue('ti_name')}
                label="Связанный транспортный инцидент"
                fullWidth={true}
                variant={'outlined'}
                disabled={true}
                size={'small'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={'end'}>
                            {getValue('ti_name') && Object.keys(getValue('ti_name')).length > 0
                                    && <IconButton size="small" onClick={() => onChange({
                                        ti_id: '',
                                        ti_name: ''
                                    })}>
                                        <i className="far fa-times"/>
                                    </IconButton>
                            }
                            <IconButton size="small" onClick={() => setShowList(true)}>
                                <i className="fas fa-search-plus"/>
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            {showList
                && <UniversalSelect
                    storeName={'SituationalPlans'}
                    storeNameProps={'transportIncidents'}
                    keyProp={'id'}
                    fetchList={getIncidents}
                    withSearch={true}
                    isSelected={true}
                    selected={fields.ti_id}
                    renderProps={(el) => <div><span>{el.name}</span></div>}
                    isOpen={showList}
                    onClose={() => setShowList(false)}
                    onAccept={handleAccept}
                    noPadding={true}
                    title={'Связанный транспортный инцидент'}
                />
            } */}

            <div className="block">
                <TextField
                    size="small"
                    label={titles.COUNT_DTP_MEMBERS}
                    variant="outlined"
                    value={getValueInt('count_members')}
                    onChange={handleEvent('count_members')}
                    error={isErrorField('count_members')}
                    type="number"
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    InputProps={{ inputProps: { min: 0 } }}
                    helperText={getErrorValidation('count_members')}
                />
            </div>

            <div className="block">
                <TextField
                    size="small"
                    label={titles.COUNT_DTP_DEAD}
                    variant="outlined"
                    value={getValueInt('dead')}
                    onChange={handleEvent('dead')}
                    error={isErrorField('dead')}
                    helperText={getErrorValidation('dead')}
                    type="number"
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    InputProps={{ inputProps: { min: 0 } }}
                />
            </div>

            <div className="block">
                <TextField
                    size="small"
                    label={titles.COUNT_DTP_WOUNDED}
                    variant="outlined"
                    value={getValueInt('wounded')}
                    onChange={handleEvent('wounded')}
                    error={isErrorField('wounded')}
                    helperText={getErrorValidation('wounded')}
                    type="number"
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    InputProps={{ inputProps: { min: 0 } }}
                />
            </div>

            <div className="block">
                <TextField
                    size="small"
                    label={titles.COUNT_DTP_DEAD_KIDS}
                    variant="outlined"
                    value={getValueInt('dead_child')}
                    onChange={handleEvent('dead_child')}
                    error={isErrorField('dead_child')}
                    helperText={getErrorValidation('dead_child')}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                />
            </div>

            <div className="block">
                <TextField
                    size="small"
                    label={titles.COUNT_DTP_WOUNDED_KIDS}
                    variant="outlined"
                    value={getValueInt('wounded_child')}
                    onChange={handleEvent('wounded_child')}
                    error={isErrorField('wounded_child')}
                    helperText={getErrorValidation('wounded_child')}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                />
            </div>

            <div className="block">
                <TextField
                    size="small"
                    label={titles.COUNT_DTP_TS}
                    variant="outlined"
                    value={getValueInt('count_ts')}
                    onChange={handleEvent('count_ts')}
                    error={isErrorField('count_ts')}
                    helperText={getErrorValidation('count_ts')}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                />
            </div>
        </div>
    </>;
};

export default BasicData;
