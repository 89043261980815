import React from 'react';
import { Collapse } from '@mui/material';
import { signsList } from '../MessageHelpers/signsList';
import messages from '../../../../../helpers/constants/messages/common';


const Info = ({ isOpen, data }) => {
    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            <h2>Cообщения:</h2>
            { data?.text?.length > 0
                ? data?.text
                    ?.map((txt,index) => <span key={index}>
                        {txt.sign
                            && <img
                                style={{ width:'30px', height:'30px', verticalAlign: 'middle' }}
                                src={signsList[txt?.sign]?.img}
                                alt={txt?.sign}
                            />
                        }
                        {txt?.text}{' '}
                    </span>)
                : data.base64
                    ? <img style={{ width: 'auto', height: '200px' }} src={data.base64} alt="preview"/>
                    : <span>{messages.NOTHING_FOUND}</span>
            }
        </Collapse>
    );
};

export default Info;