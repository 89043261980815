import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useStoreProp } from '../../../../../helpers/hooks';
import messages from '../../../../../helpers/constants/messages';
import * as actions from '../../../../../redux/TrafficLights/actions';
import { getFullDateTimeWithTextMonth } from '../../../../../helpers/date.config';


// import lightConfig from '../../../../../helpers/light.config';
// import { getColorByStatus } from '../helper';

const Information = (props) => {
    const { data } = props;

    const statuses = useStoreProp(actions.loadTrafficLightsStatuses, 'trafficLights', 'lightsStatuses');
    // const states = useStoreProp(actions.loadTrafficLightsStates, 'trafficLights', 'lightStates');

    const fields = {
        name: {
            name: 'Название',
        },
        status: {
            name: 'Статус',
            f: ({ status }) => Object.keys(statuses).length === 0 ? messages.LOADING : (
                <span
                    // style={{
                    //     // color: getColorByStatus(status)
                    // }}
                >
                    {statuses[status] || messages.INFO_IS_NOT_FOUND}
                </span>
            )
        },
        state: {
            name: 'Состояние',
            f: ({ state }) => state?.full_name || messages.INFO_IS_NOT_FOUND
        },
        control_type: {
            name: 'Тип контроля',
            f: ({ control_type }) => control_type?.full_name || messages.INFO_IS_NOT_FOUND
        },
        // state: {
        //     name: 'Состояние',
        //     f: ({ state }) => Object.keys(states).length === 0 ? messages.LOADING : (
        //         <span
        //             style={
        //                 state === 4
        //                     ? { animation: 'flicker 1s linear infinite' }
        //                     : { color: lightConfig(state).color }
        //             }
        //         >
        //             {states[state] || messages.INFO_IS_NOT_FOUND}
        //         </span>
        //     )
        // },
        desc: {
            name: 'Адрес',
        },
        created_at: {
            name: 'Создано',
            f: ({ created_at }) => getFullDateTimeWithTextMonth(created_at) || messages.INFO_IS_NOT_FOUND
        },
        updated_at: {
            name: 'Изменено',
            f: ({ updated_at }) => getFullDateTimeWithTextMonth(updated_at) || messages.INFO_IS_NOT_FOUND
        },
        lat_lon: {
            name: 'Координаты (lat, lon)',
            f: ({ lat, lon }) => `${lat} ${lon}`
        },
    };

    const RenderList = () => {
        if (!data) {
            return <div className="not-found">{messages.INFO_IS_NOT_FOUND}</div>;
        }

        return (
            <div className="information">
                {Object.keys(fields).map((field) => {
                    const f = fields[field].f ? fields[field].f(data) : null;
                    const value = f || data[field] || null;
                    const name = fields[field].name || '';

                    return (
                        <div className="info__item" key={`information_${field}`}>
                            <span className="info__item-title">
                                {name}
                                {': '}
                            </span>
                            <span
                                style={{
                                    paddingRight: '10px'
                                }}
                            >
                                {value || messages.INFO_IS_NOT_FOUND}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div>
            <Scrollbars style={{ height: '240px' }}>
                <RenderList />
            </Scrollbars>
        </div>
    );
};

export default Information;
