import L from 'leaflet';
import clusterIcon from './clusterIcon';

// функция генерации иконок для кластеров
export const iconCreateFunction = (layersList) => (cluster) => {
    // количество маркеров
    const childCount = cluster.getChildCount();
    // получить маркера кластера
    const clustersMarkers = cluster.getAllChildMarkers();

    // получить атрибуты маркеров
    const clusterMarkersTypes = clustersMarkers.map((item) => {
        return item.getAttribution() || { slug: null, color: null };

        //todo подумать
        // const clusterColor = item.getClusterColor ? item.getClusterColor() : null;
        // const clusterSlug = item.getClusterSlug ? item.getClusterSlug() : null;
        // const opt = item.getAttribution() || { slug: null, color: null };
        // if (clusterColor) {
        //     opt.color = clusterColor;
        // }
        // if (clusterSlug) {
        //     opt.slug = clusterSlug;
        // }
        // return opt;
    });

    const markersCount = clustersMarkers.length;
    // оставить уникальные слои и цвета
    const {
        groupClusterMarkersTypes,
        groupClusterMarkersColors
    } = clusterMarkersTypes.reduce(

        (res, { slug, color }) => ({
            // слои
            groupClusterMarkersTypes:
                (res.groupClusterMarkersTypes.includes(slug) || !slug)
                    ? res.groupClusterMarkersTypes
                    : [
                        ...res.groupClusterMarkersTypes,
                        slug
                    ],
            // цвета
            groupClusterMarkersColors: {
                ...res.groupClusterMarkersColors,
                [color]: (res.groupClusterMarkersColors[color] || 0) + 1
            }

            // groupClusterMarkersColors:
            //     (res.groupClusterMarkersColors.includes(color) || !color)
            //         ? res.groupClusterMarkersColors
            //         : [
            //             ...res.groupClusterMarkersColors,
            //             color
            //         ]
        }), {
            groupClusterMarkersTypes: [],
            groupClusterMarkersColors: {}
        });

    // переводим в проценты
    const groupClusterMarkersColorsPersent = Object.keys(groupClusterMarkersColors)
        .reduce((res, color) => ({
            ...res,
            [color]: (groupClusterMarkersColors[color] / markersCount) * 100
        }), {});

    // иконки для кластеров
    const iconsActiveLayers = groupClusterMarkersTypes
        .map((activeLayer) =>
            layersList[activeLayer]?.clusterIcon()
        ).join('');

    //const count = groupClusterMarkersTypes.length > 1 ? groupClusterMarkersTypes.length : childCount;
    const count = childCount;

    // 4 иконки в строку
    const countIcons = Math.min(groupClusterMarkersTypes.length, 4);
    const widthIcons = countIcons * 38;

    const styleIcons = `
        margin-left: -${widthIcons / 2}px;
        width: ${widthIcons}px;
    `;

    // размер иконки
    const iconSize = count < 100 ? 40 : count < 1000 ? 60 : 80;

    return childCount > 0 ? new L.DivIcon({
        html: `
            ${clusterIcon(groupClusterMarkersColorsPersent)}
            <div class="count">
                <span>${count}</span>
            </div>
            <div class="icons" style="${styleIcons}">
                ${iconsActiveLayers}
            </div>
        `,
        className: 'marker-cluster',
        iconSize: new L.Point(iconSize, iconSize)
    }) : null;
};

export const iconCreateFunctionSimple = (cluster) => {
    // количество маркеров
    const childCount = cluster.getChildCount();
    // получить маркера кластера
    const clustersMarkers = cluster.getAllChildMarkers();
    const markersCount = clustersMarkers.length;

    // получить атрибуты маркеров
    const clusterMarkersTypes = clustersMarkers.map((item) => item.getAttribution() || { color: null/*, count: 0*/ });
    // todo возможно возвращать общее количество

    // оставить уникальные слои и цвета
    const groupClusterMarkersColors = clusterMarkersTypes.reduce(
        (res, { color }) => {
            if (color) {
                return {
                    ...res,
                    [color]: (res[color] || 0) + 1
                };
            }
            return res;
        }, []);

    const groupClusterMarkersColorsPersent = Object.keys(groupClusterMarkersColors)
        .reduce((res, color) => ({
            ...res,
            [color]: (groupClusterMarkersColors[color] / markersCount) * 100
        }), {});

    // размер иконки
    const iconSize = childCount < 100 ? 40 : childCount < 1000 ? 60 : 80;

    return childCount > 0 ? new L.DivIcon({
        html: `
            ${clusterIcon(groupClusterMarkersColorsPersent)}
            <div class="count">
                <span>${childCount}</span>
            </div>
        `,
        className: 'marker-cluster',
        iconSize: new L.Point(iconSize, iconSize)
    }) : null;
};
