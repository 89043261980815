import { Collapse } from '@mui/material';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';
import { InfoProp } from './types';


const Info = ({ info, isOpen }: InfoProp) => {
    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit  className="info" style={{ padding: 20 }}>
            <div style={{ display: 'flex' }}>
                <b style={{ alignSelf: 'center' }}>Комплексы:</b>
                <SelectComplex
                    multiple
                    sortedList={info.complexes}
                    selected={info.complexes}
                    canReset={false}
                    withSearch={false}
                    readOnly
                    searchTitle={undefined}
                />
            </div>
            <div><b>Количество событий отправленных с ошибками:</b> {info.transmitted_with_error_count}</div>
            <div><b>Количество событий для отправки:</b> {info.count_to_transmit}</div>
            <div><b>Количество переданных записей:</b> {info.transmitted_count}</div>
        </Collapse>
    );
};

export default Info;
