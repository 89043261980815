import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { loadWorkorders } from 'redux/TransportRoad/actions';
import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import type { Item, SelectWorkordersRWProps } from './types';

const SelectWorkordersRW = ({
    multiple = false,
    selected,
    onChange = () => {},
    required = false,
    disabled = false,
    label = titles.WORK_ORDERS,
    error = false,
    helperText = '',
    limitTags = 1,
}: SelectWorkordersRWProps<Item>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);

    const prevData = useRef(selected);

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) { 
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleAccept = (data: any) => {        
        onChange(data);
        setShowList(false);
    };

    const handleClose = () => setShowList(false);

    const getList = (params: Record<string, any>) => {
        const { page, limit, query } = params;
        
        dispatch(loadWorkorders(page, limit, { id: query }));
    };

    const onReset = () => {
        const initialState = multiple ? [] : '';
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete<Item>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={(option) => option?.id ? `Номер: ${option?.id}, Название: ${option?.name}` : ''}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error} 
                helperText={helperText}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName="transportRoad"
                    storeNameProps={'workorders'}
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <span>Номер: {el.id}, Название: {el.name}</span>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={titles.WORK_ORDERS}
                    searchTitle="Найти по номеру"
                />
            }
        </>
    );
};

export default SelectWorkordersRW;