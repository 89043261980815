const handleLabelTypes = (type) => {
    const defaultType = {
        passenger: '1км пробега',
        baggage: '1км пробега'
    };
    const typeNumber = {
        5: {
            passenger: '1 поездку',
            baggage: '1 поездку'
        }
    };
    return typeNumber[type] || defaultType;
};

export default handleLabelTypes;