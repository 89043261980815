import { useSelector } from 'react-redux';

// хук селектор по ссылке типа ниже
// stateLinkProp = 'dadata.loading'
const useSelectorByLink = (stateLinkProp, defaultValue = null) => {
    const getFromStore = (store) => {
        const storeIterations = stateLinkProp.split('.') || [];
        return storeIterations.reduce((res, key) => res && res[key] ? res[key] : defaultValue, store);
    };

    return useSelector((store) => getFromStore(store));
};

export default useSelectorByLink;
