import React, { useEffect, useState } from 'react';
import titles from '../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { TextField } from '@mui/material';
import Modal from '../../../../common/Modal';
import { useDispatch } from 'react-redux';
import { useValidation } from '../../../../../helpers/hooks';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import { editCamera } from '../../../../../redux/VideoAnalytics/actions';
import { Camera } from './types';
import TextMaskIP from '../../../../common/TextMaskIP';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import useCompany from '../../../../../helpers/hooks/useCompany';

interface ModalFormProps {
    isOpen: boolean;
    onClose: () => void;
    reloadList: () => void;
    item: Camera;
}

const ModalForm = ({ isOpen, onClose, reloadList, item }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        ip_route: (item.ip_route && item.ip_route !== '0.0.0.0') ? item.ip_route : '',
        organization_id: item.organization_id || null,
        url_player: item.url_player || '',
    };

    const [formData, setFormData] = useState(initialState);

    const company = useCompany(item?.organization_id);

    useEffect(() => {
        if (company) {
            setFormData(prevState => ({ ...prevState, organization_id: company }));
        }
    }, [company]);

    const onChange = (name: string, value: any) => {
        setFormData({
            ...formData,
            [name]: value,
        });

        validation.deleteKey(name);
    };

    const onSave = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            organization_id: formData.organization_id?.id
        }, false);

        const callback = () => {
            reloadList();
            onClose();
        };

        dispatch(editCamera(item?.id, prepareData, callback));
    };

    const isDisabled = Object.values(removeEmptyFields(formData)).length === 0;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={`${titles.EDIT}: ${item.name}`}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose,
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onSave,
                            disabled: isDisabled,
                        },
                    ]}
                />
            }
        >
            <form className="modal__form">
                <div className="block">
                    <TextField
                        value={formData.ip_route}
                        onChange={(e) => onChange('ip_route', e.target.value)}
                        className="block"
                        label={titles.IP_ADDRESS}
                        variant={'outlined'}
                        size={'small'}
                        error={validation.isKey('ip_route')}
                        helperText={validation.get('ip_route')}
                        InputProps={{
                            inputComponent: TextMaskIP as any,
                        }}
                    />
                </div>
                <div className="block">
                    <SelectCompany
                        label="Балансодержатель"
                        selected={formData.organization_id}
                        onChange={(value) => onChange('organization_id', value)}
                    />
                </div>
                <div className="block">
                    <TextField
                        value={formData.url_player}
                        onChange={(e) => onChange('url_player', e.target.value)}
                        label="Ссылка на видео"
                        variant={'outlined'}
                        size={'small'}
                        error={validation.isKey('url_player')}
                        helperText={validation.get('url_player')}
                    />
                </div>
            </form>
        </Modal>
    );
};

export default ModalForm;
