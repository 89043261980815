import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from './Filter';
import Item from './Item';
import {
    loadCameraObjects,
    setFilterCameraObjects,
} from '../../../../redux/VideoAnalytics/actions';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import { isEqual } from 'lodash';
import { useWsActive } from '../../../../helpers/ws/hooks';
import { videoAnalyticsSelectors } from '../../../../redux/VideoAnalytics';
import PageLayout from '../../../layout/PageLayout';
import NoData from '../../../common/Information/NoData';
import { LSContainer } from '../../../common/List';


function ItemsLeftAtTheBusStop() {
    const dispatch = useDispatch();

    const cameraObjects = useSelector(videoAnalyticsSelectors.cameraObjects);
    const loadingCameraObjects = useSelector(videoAnalyticsSelectors.loadingCameraObjects);
    const filterCameraObjects = useSelector(videoAnalyticsSelectors.filterCameraObjects);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({
        page: 1,
        data: {
            ...filterCameraObjects,
        }
    });

    useWsActive((activeData) => {
        const result = {
            cameras: activeData?.camera_id && [{
                id: activeData?.camera_id,
                name: activeData?.camera_name,
            }],
            start_date: activeData?.start_time,
            end_date: activeData?.saved_at,
        };
        dispatch(setFilterCameraObjects(removeEmptyFields(result)));
    });

    useEffect(() => {
        if (
            Object.keys(filterCameraObjects).length > 0
            && !isEqual(filterCameraObjects, params?.data || {})
        ) {
            setParams({
                page: 1,
                data: {
                    ...filterCameraObjects,
                }
            });
        }
    }, [filterCameraObjects]);
    
    useEffect(() => {
        const fil = params?.data || {};
        const filter = removeEmptyFields({
            ...fil,
            camera_id: fil?.cameras?.map(item => item.id),
            cameras: null,
        }, false);
        dispatch(loadCameraObjects(params?.page, limit, filter));
    }, [dispatch, limit, params]);

    return (
        <PageLayout 
            header="Отчет по оставленным на остановке предметам" 
            filters={<Filter />}
            loading={loadingCameraObjects}
            informPanelProps={{ total: cameraObjects?.meta?.total }}
            content={() => (
                <>
                    {cameraObjects?.data?.length > 0
                        ? <LSContainer headers={[
                            {
                                title: 'Дата фиксации',
                                width: '20%'
                            },
                            {
                                title: 'Камера',
                                width: '20%'
                            },
                            {
                                title: 'Тип объекта',
                                width: '20%'
                            },
                            {
                                title: 'GPS координаты',
                                width: '20%'
                            },
                            {
                                title: 'Кадр детекции',
                            }
                        ]}>
                            {cameraObjects.data.map((item) => (
                                <Item
                                    key={item.base_name}
                                    item={item}
                                />
                            ))}
                        </LSContainer>
                        : (!loadingCameraObjects && <NoData/>)
                    }
                </>
            )}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: cameraObjects?.meta,
                limit,
                setLimit
            }}
        />
    );
}

export default ItemsLeftAtTheBusStop;
