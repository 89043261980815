// иконка для списка
const iconCluster = (props) => {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.000000 103.000000" fill="${props?.fill || '#e7e7e7'}">
            <g>
                <rect fill="none" id="canvas_background" height="105" width="96" y="-1" x="-1"/>
            </g>
            <g>
                <g id="svg_rw_2" transform="translate(0,103) scale(0.10000000149011612,-0.10000000149011612) ">
                    <circle id="svg_rw_3" r="450" cy="550" cx="470"/>
                </g>
                <g stroke="null" id="svg_rw_10">
                    <path fill="${props?.fillInner || 'rgba(0, 0, 0, 0.54)'}" stroke="null" id="svg_rw_9" d="m45.725841,28.791933a5.095008,5.095008 0 1 0 -5.095008,-5.095008a5.095008,5.095008 0 0 0 5.095008,5.095008zm-6.606526,27.742288q-4.107849,-2.736426 -8.443899,-5.104538l-7.595805,-4.139708l-6.094907,21.335334a3.396679,3.396679 0 1 0 6.534349,1.867113l3.884939,-13.603655l6.433503,4.289352l0,8.373842a3.396679,3.396679 0 0 0 6.793338,0l0,-10.189997a3.396679,3.396679 0 0 0 -1.511517,-2.827743zm10.753643,6.224402a3.396679,3.396679 0 0 0 -3.222586,2.322484l-2.622881,7.867533l33.966709,0l-10.819461,-21.90003c-1.19838,-2.39783 -4.5717,-2.527325 -5.951603,-0.228221l-3.381799,5.762671l-6.931332,-3.780923l-2.546444,-12.864886a13.775608,13.775608 0 0 0 -7.400493,-9.680498c-0.106156,-0.053078 -0.223961,-0.070057 -0.330116,-0.119945a3.290523,3.290523 0 0 0 -0.766368,-0.283418c-1.628272,-0.647493 -3.349961,-1.061456 -5.102438,-1.061456l-7.719989,0a3.396679,3.396679 0 0 0 -2.554944,1.159112l-5.944153,6.793338a2.748116,2.748116 0 0 0 -0.24415,0.335426c-0.937271,1.496658 -0.318427,3.487955 1.233429,4.33605l35.728696,19.562708l-1.045526,1.780056l-4.34455,0z"/>
                </g>
            </g>
        </svg>
    `;
    /*
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.000000 103.000000" fill="${props?.fill || '#e7e7e7'}">
            <g transform="translate(0.000000,103.000000) scale(0.100000,-0.100000)" stroke="none">
                <circle cx="470" cy="550" r="450" />
            </g>
            <svg x="11" y="-9" width="70" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="snowplow" className="svg-inline--fa fa-snowplow fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path fill="${props?.fillInner || "rgba(0, 0, 0, 0.54)"}" d="M120 376c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm80 0c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm80 0c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm80 0c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm238.6 49.4c-14.5-14.5-22.6-34.1-22.6-54.6V269.2c0-20.5 8.1-40.1 22.6-54.6l36.7-36.7c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0l-36.7 36.7c-26.5 26.5-41.4 62.4-41.4 99.9V288h-64v-50.9c0-8.7-1.8-17.2-5.2-25.2L364.5 29.1C356.9 11.4 339.6 0 320.3 0H176c-26.5 0-48 21.5-48 48v112h-16c-26.5 0-48 21.5-48 48v91.2C26.3 317.2 0 355.4 0 400c0 61.9 50.1 112 112 112h256c61.9 0 112-50.1 112-112 0-17.3-4.2-33.4-11.2-48H512v18.7c0 37.5 14.9 73.4 41.4 99.9l36.7 36.7c6.2 6.2 16.4 6.2 22.6 0l22.6-22.6c6.2-6.2 6.2-16.4 0-22.6l-36.7-36.7zM192 64h117.8l68.6 160H256l-64-64V64zm176 384H112c-26.5 0-48-21.5-48-48s21.5-48 48-48h256c26.5 0 48 21.5 48 48s-21.5 48-48 48z"></path>
            </svg>
        </svg>
     */
};

export default iconCluster;
