import L from 'leaflet';
import React, { useEffect, useState } from 'react';
import 'leaflet.marker.slideto';
import { usePrevious } from '../../../../helpers/hooks';
import { isEqual } from 'lodash';
import location from '../icon/location.png';
import renderChild from './helper/renderChild';

// иконка маркера
const defaultIcon = L.icon({
    iconUrl: location,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
});

// todo проверить
// маркер с плавным сдвигом
const SlideMarker = ({
    map,
    parent,
    icon = defaultIcon,
    latlng,
    onClick = null,
    children,
    ...prop
}) => {

    const prevLatlng = usePrevious(latlng);
    const prevIcon = usePrevious(icon);
    const [marker, setMarker] = useState(null);
    const [move, setMove] = useState(false);

    // сдвигаем маркер
    const slideTo = (newLatLng, keepAtCenter = false) => {
        marker.slideTo(newLatLng, {
            duration: 3000,
            keepAtCenter
        });
    };

    useEffect(() => {
        const newMarker = L
            .marker(latlng, {
                icon
            })
            .on('movestart', () => {
                setMove(true);
            })
            .on('moveend', () => {
                setMove(false);
            });
        parent?.addLayer(newMarker);

        setMarker(newMarker);

        onClick && newMarker.on('click', () => {
            onClick(newMarker.latlng);
        });

        return () => {
            parent?.removeLayer(newMarker);
            onClick && newMarker
                .off('movestart')
                .off('moveend')
                .off('click');
            newMarker.remove();
            setMarker(null);
        };
    }, []);

    useEffect(() => {
        if (marker && !isEqual(prevLatlng, latlng)) {
            if (move) marker.slideCancel();
            slideTo(latlng);
        }
    }, [latlng, prevLatlng, marker]);

    useEffect(() => {
        if (marker && !isEqual(prevIcon, icon)) {
            marker.setIcon(icon);
        }
    }, [icon, prevIcon, marker]);

    return marker
        ? renderChild(
            children,
            {
                map,
                parent: marker,
                ...prop
            }
        )
        : null;
};

export default SlideMarker;
