export const govermentContracts = (state) => state.govermentContracts;

export const governmentContractList = (state) => govermentContracts(state).governmentContractList;
export const governmentContractListData = (state) => governmentContractList(state).data;
export const governmentContractListMeta = (state) => governmentContractList(state).meta;
export const governmentContractLoading = (state) => govermentContracts(state).governmentContractLoading;

export const governmentContractById = (state) => govermentContracts(state).governmentContractById;
export const loadingGovernmentContractById = (state) => govermentContracts(state).loadingGovernmentContractById;
// export const governmentContractHistoryList = (state) => govermentContracts(state).governmentContractHistoryList;
// export const governmentContractHistoryListData = (state) => governmentContractHistoryList(state).data;
// export const governmentContractHistoryListMeta = (state) => governmentContractHistoryList(state).meta;

export const loadingContractByName = (state) => govermentContracts(state).loadingContractByName;

export const contractLinkObjects = (state) => govermentContracts(state).contractLinkObjects;
export const loadingLinkObjects = (state) => govermentContracts(state).loadingLinkObjects;

export const contractEntitiesTypes = (state) => govermentContracts(state).contractEntitiesTypes;
