import React, { useMemo } from 'react';
import { Doughnut as Component } from 'react-chartjs-2';

const Doughnut = ({
    data = [],
    width = 300,
    height = 300,
}) => {
    const callbacks = {
        // label: (context) => `${context?.label} ${context?.formattedValue}%`,
        // label: (context) => `${context?.label}`,
    };

    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        redraw: false,
        plugins: {
            // emptyDoughnut: {
            //     color: 'rgba(255, 128, 0, 0.5)',
            //     width: 2,
            //     radiusDecrease: 20
            // },
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks
            },
            legend: {
                // display: !customLegend,
                align: 'center',
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
            },
            labels: {
                render: 'label',
                precision: 1,
                arc: false,
                position: 'border',
                fontSize: 10,
                fontColor: '#000'
            }
        },
    };

    const dataG = useMemo(() => {
        const {
            colors,
            labels,
            values,
        } = Object.keys(data)
            .reduce((res, key) => {
                const {
                    count,
                    percent,
                    color
                } = data[key];

                return {
                    colors: [
                        ...res.colors || [],
                        color,
                    ],
                    labels: [
                        ...res.labels || [],
                        ` ${key} (${percent} %)`,
                    ],
                    values: [
                        ...res.values || [],
                        count,
                    ],
                };
            }, {});

        return {
            labels: labels,
            datasets: [
                {
                    data: values,
                    maxBarThickness: 80,
                    backgroundColor: colors,
                    borderColor: '#fff',
                }
            ],
        };
    }, [data]);

    return (
        <div>
            <Component
                width={width}
                height={height}
                data={dataG}
                options={options}
            />
        </div>
    );
};

export default Doughnut;
