import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { Grid } from '@mui/material';

import Loading from 'components/common/Loading';
import Information from 'components/common/Information';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import { loadVehiclesCB, setDockCurrentShow } from 'modules/TransportWater/redux/actions';
import { dockCurrentShow }  from 'modules/TransportWater/redux/selectors';

import { getShipColor, getShipIcon } from '../helper';
import messages from '../../../../helpers/constants/messages';

const PopUp = () => {
    const dispatch = useDispatch();

    const marker = useSelector(dockCurrentShow);

    const [vehicle, setVehicle] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (marker.vehicle_id) {
            const filter = { id_list: [marker.vehicle_id] };
            dispatch(loadVehiclesCB(
                1,
                1,
                filter,
                setLoading,
                (newData) => setVehicle(newData?.data?.[0] || {})
            ));
        }
    }, [dispatch, marker.vehicle_id]);

    useEffect(() => () => {
        setVehicle({});
        dispatch(setDockCurrentShow({}));
    }, []);

    const styleHeader = {
        padding: '1rem 1rem'
    };

    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: marker.color || getShipColor }}>
                <div>
                    <div className="img">
                        <i className={getShipIcon}></i>
                    </div>
                    <span>{marker?.vehicle?.name || ''}</span>
                </div>
            </div>
            <div className="map-popup_body">
                {loading && <Loading circular/>}

                <Scrollbars style={{ height: '300px' }}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <h2 style={styleHeader}>Местоположение</h2>
                            <Information
                                data={marker}
                                title={{
                                    short_name: {
                                        title: 'Позывной',
                                    },
                                    name: {
                                        title: 'Название',
                                    },
                                    type: {
                                        title: 'Тип судна и вид груза',
                                    },
                                    navigation_status_text: {
                                        title: 'Статус навигации',
                                    },
                                    turning_speed: {
                                        title: 'Скорость поворота',
                                    },
                                    speed: {
                                        title: 'Скорость движения',
                                    },
                                    navigation_precision_text: {
                                        title: 'Точность определения позиции',
                                    },
                                    lon: {
                                        title: 'Долгота',
                                    },
                                    lat: {
                                        title: 'Широта',
                                    },
                                    course: {
                                        title: 'Курс',
                                    },
                                    heading: {
                                        title: 'Истинный курс (HDG)',
                                    },
                                    timestamp: {
                                        title: 'Отметка времени',
                                    },
                                    maneuver_indicator_text: {
                                        title: 'Индикатор маневра',
                                    },
                                    raim_flag: {
                                        title: 'Флаг РАИМ',
                                        value: ({ raim_flag }) => raim_flag ? 'Да' : 'Нет'
                                    },
                                    radio_status: {
                                        title: 'Статус Радио',
                                    },
                                }}
                                // defaultValue={messages.NO_DATA}
                                defaultValue="-"
                                // returnNull
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <h2 style={styleHeader}>Свойства судна</h2>
                            <Information
                                data={vehicle}
                                title={{
                                    mmsi: {
                                        title: 'MMSI',
                                    },
                                    imo: {
                                        title: 'ИМО номер судна',
                                    },
                                    // 2: {
                                    //     title: 'Размер',
                                    // },
                                    distance_to_bow: {
                                        title: 'Размер до кормы',
                                    },
                                    distance_to_port: {
                                        title: 'Расстояние до порта',
                                    },
                                    distance_to_starboard: {
                                        title: 'Размер по правому борту',
                                    },
                                    position_fixation_type: {
                                        title: 'Тип фиксации положения',
                                    },
                                    arrival_time: {
                                        title: 'Время прибытия',
                                        value: ({ arrival_time }) => fullDateTimeWithoutSeconds(arrival_time),
                                    },
                                    draught: {
                                        title: 'Осадка (дм)',
                                    },
                                    destination: {
                                        title: 'Пункт назначения',
                                    },
                                }}
                                // defaultValue={messages.NO_DATA}
                                defaultValue="-"
                                // returnNull
                            />
                        </Grid>
                    </Grid>
                </Scrollbars>
            </div>
        </div>
    );
};

export default PopUp;
