import * as types from './types';

/**
 * Actions
 **/

export const loadVideoCamerasList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_VIDEO_CAMERAS_LIST,
    payload: { page, limit, query }
});

export const loadingVideoCamerasList = (bool = false) => ({
    type: types.LOADING_VIDEO_CAMERAS_LIST,
    payload: bool
});

export const loadedVideoCamerasList = (data) => ({
    type: types.LOADED_VIDEO_CAMERAS_LIST,
    payload: data
});

export const createVideoCamera = (data, callback = () => {}) => ({
    type: types.CREATE_VIDEO_CAMERA,
    payload: data,
    callback
});

export const editVideoCamera = (id, data, callback) => ({
    type: types.EDIT_VIDEO_CAMERA,
    payload: { id, data },
    callback
});

export const deleteVideoCamera = (id, callback) => ({
    type: types.DELETE_VIDEO_CAMERA,
    payload: id,
    callback
});

export const loadStatusesVideoCamera = () => ({
    type: types.LOAD_STATUSES_VIDEO_CAMERA
});

export const loadedStatusesVideoCamera = (data) => ({
    type: types.LOADED_STATUSES_VIDEO_CAMERA,
    payload: data
});

export const changeVideoCameraStatus = (id, data, callback) => ({
    type: types.CHANGE_VIDEO_CAMERA_STATUS,
    payload: { id, data },
    callback
});

export const loadTypesVideoCamera = () => ({
    type: types.LOAD_TYPES_VIDEO_CAMERA
});

export const loadedTypesVideoCamera = (data) => ({
    type: types.LOADED_TYPES_VIDEO_CAMERA,
    payload: data
});

/* полигон */
export const getForPolygon = (polygon, params = {}) => ({
    type: types.GET_POLYGON,
    payload: { polygon, ...params },
});

export const saveForPolygon = (data) => ({
    type: types.SAVE_POLYGON,
    payload: data,
});

export const clearForPolygon = () => ({
    type: types.SAVE_POLYGON,
    payload: [],
});

export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool
});

export const loadedSidebar = (data, replace = true) => ({
    type: types.LOADED_SIDEBAR,
    payload: {
        data,
        replace,
    }
});

export const loadSidebar = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_SIDEBAR,
    payload: {
        page,
        limit,
        query,
    },
});

export const clearSidebar = () => ({
    type: types.LOADED_SIDEBAR,
    payload: {
        data: {
            data: [],
            meta: {
                last_page: 0,
            },
        },
        replace: true,
    },
});

export const setActive = (item) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const resetActive = () => ({
    type: types.SET_ACTIVE,
    payload: {},
});

export const setDeleteForm = (data = false) => ({
    type: types.SET_DELETE_FORM,
    payload: data,
});

export const setEditForm = (data = false) => ({
    type: types.SET_EDIT_FORM,
    payload: data,
});

export const saveStatus = (bool = false) => ({
    type: types.SAVED,
    payload: bool,
});

export const saveStatusCamera = (bool = false) => ({
    type: types.SAVED_CAMERA,
    payload: bool,
});

export const setFilters = (filter) => ({
    type: types.SET_FILTER,
    payload: filter,
});
export const clearFilters = () => ({
    type: types.SET_FILTER,
    payload: {},
});

export const setFiltersGroup = (filter) => ({
    type: types.SET_FILTER_GROUP,
    payload: filter,
});
export const clearFiltersGroup = () => ({
    type: types.SET_FILTER_GROUP,
    payload: {},
});

export const loadVideoCameraFrame = (id, callback = () => {}) => ({
    type: types.LOAD_VIDEO_CAMERA_FRAME,
    payload: id,
    callback
});

export const loadedVideoCameraFrame = (data) => ({
    type: types.LOADED_VIDEO_CAMERA_FRAME,
    payload: data
});

export const loadCameraGroupList = (page = 1, limit = 25, query, replaceStore = true) => ({
    type: types.LOAD_CAMERA_GROUP_LIST,
    payload: { page, limit, query, replaceStore }
});

export const loadingCameraGroupList = (bool = false) => ({
    type: types.LOADING_CAMERA_GROUP_LIST,
    payload: bool
});

export const loadedCameraGroupList = (data, replace = true) => ({
    type: types.LOADED_CAMERA_GROUP_LIST,
    payload: { 
        data,
        replace
    }
});

export const clearCameraGroupList = () => ({
    type: types.LOADED_CAMERA_GROUP_LIST,
    payload: {
        data: {
            data: [],
            meta: {
                last_page: 0,
            },
        },
        replace: true,
    },
});

export const createCameraGroup = (data) => ({
    type: types.CREATE_CAMERA_GROUP,
    payload: data
});

export const editCameraGroup = (id, data) => ({
    type: types.EDIT_CAMERA_GROUP,
    payload: { id, data }
});

export const deleteCameraGroup = (id) => ({
    type: types.DELETE_CAMERA_GROUP,
    payload: id
});

export const loadingVideoCameraFrame = (bool = false) => ({
    type: types.LOADING_VIDEO_CAMERA_FRAME,
    payload: bool
});

export const loadActiveFrame = (data) => ({
    type: types.LOAD_ACTIVE_FRAME,
    payload: data
});

export const loadCameraTemplateList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_CAMERA_TEMPLATE_LIST,
    payload: { page, limit, query }
});

export const loadingCameraTemplateList = (bool = false) => ({
    type: types.LOADING_CAMERA_TEMPLATE_LIST,
    payload: bool
});

export const loadedCameraTemplateList = (data) => ({
    type: types.LOADED_CAMERA_TEMPLATE_LIST,
    payload: data
});

export const createCameraTemplate = (data, callback) => ({
    type: types.CREATE_CAMERA_TEMPLATE,
    payload: data,
    callback
});

export const editCameraTemplate = (id, data, callback) => ({
    type: types.EDIT_CAMERA_TEMPLATE,
    payload: { id, data },
    callback
});

export const deleteCameraTemplate = (id, callback) => ({
    type: types.DELETE_CAMERA_TEMPLATE,
    payload: id,
    callback
});


export const loadVideoCamerasByRadius = (data, callback = () => {}) => ({
    type: types.LOAD_VIDEOCAMERAS_BY_RADIUS,
    payload: data,
    callback
});

export const loadedVideoCamerasByRadius = (data) => ({
    type: types.LOADED_VIDEOCAMERAS_BY_RADIUS,
    payload: data
});