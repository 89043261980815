import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteStation } from 'redux/TransportPassenger/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import ConfirmDelete from 'components/common/ConfirmModal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import StationModal from './StationModal';

const StationItem = ({ item, reloadList }) => {
    const dispatch = useDispatch();

    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [openMap, setOpenMap] = useState(false);

    const handleDelete = () => {
        dispatch(deleteStation(item.id, () => {
            reloadList(true);
            setDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item?.id || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.type_list?.length > 0
                        ? item?.type_list?.map((item, index) => (
                            <div key={index}>{item.station_name}</div>
                        ))
                        : messages.NO_DATA
                    }
                </LSContentColumn>
                <LSContentColumn>
                    {item?.attribute_text || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.direction || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn align="right" isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <i className="fas fa-map-marked"/>,
                                name: 'Посмотреть на карте',
                                onClick: () => setOpenMap(true),
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setDeleteModal(true),
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openMap
                && <Modal
                    isOpen={openMap}
                    onClose={() => setOpenMap(false)}
                    noPadding
                    title={titles.STOP_AREA}
                    buttons={
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: () => setOpenMap(false)
                                },
                            ]}
                        />
                    }
                >
                    <MapGeoJson
                        geometry={item?.check_point?.geometry || {}}
                        readOnly={true}
                    />
                </Modal>
            }

            {editModal && (
                <StationModal
                    onClose={() => setEditModal(false)}
                    open={editModal}
                    data={item}
                    reloadList={reloadList}
                />)
            }
            {deleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={deleteModal}
                    onSuccess={handleDelete}
                    onClose={() => setDeleteModal(false)}
                />
            )}
        </>
    );
};

export default StationItem;
