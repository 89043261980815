// иконка для списка
const iconCluster = (props) => {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 107.000000 107.000000">
            <g>
                <rect x="-1" y="-1" width="109" height="109" id="canvas_background" fill="none"/>
            </g>
            <g>
                <g fill="${props?.fill || '#e7e7e7'}" transform="translate(0,107) scale(0.10000000149011612,-0.10000000149011612)" id="svg_meteo_1">
                    <path d="m404.52236,1047.19878c-227.70224,-55.17014 -397.22506,-272.84146 -397.22506,-511.57773c0,-285.88168 240.74246,-525.62105 526.62414,-525.62105c285.88167,0 526.62414,240.74246 526.62414,526.62414c0,237.73318 -172.5321,457.41068 -402.24054,511.57774c-79.24439,18.05568 -175.54138,18.05568 -253.78268,-1.0031z" id="svg_meteo_2" stroke="null"/>
                </g>
                <g fill="${props?.innerFill || 'rgba(0, 0, 0, 0.54)'}" stroke="null" id="svg_meteo_9">
                    <path stroke="null" id="svg_meteo_8" d="m80.925954,58.214845c0.023195,-0.220354 0.092781,-0.42911 0.092781,-0.649464c0,-4.093944 -3.328504,-7.422449 -7.422449,-7.422449c-1.461295,0 -2.806613,0.440708 -3.954774,1.159758c-2.041173,-4.49986 -6.552631,-7.6544 -11.81793,-7.6544c-7.167302,0 -12.989286,5.810386 -12.989286,12.989286c0,0.347927 0.081183,0.672659 0.104378,1.008989c-5.752398,0.42911 -10.310245,5.184117 -10.310245,11.05249c0,6.146715 4.986958,11.133673 11.133673,11.133673l31.545408,0c6.146715,0 11.133673,-4.986958 11.133673,-11.133673c0,-4.88258 -3.154541,-8.976524 -7.51523,-10.484209zm-49.915969,-2.621052c-5.068141,-5.068141 -5.068141,-13.30242 0,-18.358963c5.068141,-5.068141 13.30242,-5.068141 18.370561,0c1.124965,1.124965 1.95999,2.423893 2.58626,3.792407c1.136562,-0.42911 2.331113,-0.695855 3.560456,-0.869818l3.456078,-10.310245c0.463903,-1.380112 -0.846623,-2.67904 -2.226735,-2.226735l-10.182672,3.39809l-4.812994,-9.602793c-0.637867,-1.298929 -2.493479,-1.298929 -3.142943,0l-4.801397,9.602793l-10.19427,-3.39809c-1.368514,-0.452305 -2.67904,0.858221 -2.226735,2.226735l3.39809,10.182672l-9.602793,4.812994c-1.298929,0.649464 -1.298929,2.493479 0,3.142943l9.602793,4.801397l-3.39809,10.182672c-0.463903,1.380112 0.846623,2.67904 2.226735,2.226735l8.825756,-2.934187c0.707452,-1.438099 1.623661,-2.748626 2.737028,-3.885188c-1.519283,-0.626269 -2.945784,-1.554075 -4.175128,-2.783418zm-0.556684,-9.185281c0,4.731811 3.39809,8.674987 7.874754,9.544805c0.927806,-0.545086 1.890405,-1.020587 2.922589,-1.356916c0.626269,-5.137726 3.595249,-9.568001 7.816766,-12.177455c-1.53088,-3.374895 -4.92897,-5.7408 -8.872146,-5.7408c-5.369678,0 -9.741964,4.360689 -9.741964,9.730367z"/>
                </g>
            </g>
        </svg>
    `;
};

export default iconCluster;
