import React from 'react';

const createPointGJ = (lat, lon, properties = {}) => {
    const latFloat = parseFloat(lat);
    const lonFloat = parseFloat(lon);

    if (latFloat && lonFloat) {
        return (
            {
                type: 'Feature',
                properties,
                geometry: {
                    type: 'Point',
                    coordinates: [
                        lonFloat,
                        latFloat
                    ],
                },
            }
        );
    }
    return null;
};

export default createPointGJ;