import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { transportPassengerSelectors } from 'redux/TransportPassenger';
import {
    fetchSchedule,
    fetchScheduleStatuses,
    loadVehicleCategories,
} from 'redux/TransportPassenger/actions';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import { LSContainer } from 'components/common/List/index';
import PageLayout from 'components/layout/PageLayout/index';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons/index';
import titles from 'helpers/constants/titles';

import Filter from './Filter';
import Item from './Item';
import ModalForm from './ModalForm';

const Schedule = ({ currentRoute, currentTrafficSchedule }) => {
    const dispatch = useDispatch();

    const [isOpenModalForm, setIsOpenModalForm] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const schedulesData = useSelector(transportPassengerSelectors.schedulesData);
    const schedulesMeta = useSelector(transportPassengerSelectors.schedulesMeta);
    const loadingSchedules = useSelector(transportPassengerSelectors.loadingSchedules);

    const statuses = useStoreProp(
        fetchScheduleStatuses,
        'transportPassenger',
        'scheduleStatuses'
    );

    const vehicleCategories = useStoreProp(
        loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    );
 
    const reloadList = useCallback((shouldGoToPreviousPage = false) => {
        const data = {
            ...params.data,
            ...(!!currentRoute && {
                num_route: currentRoute?.num,
                transport_type: currentRoute?.category_id
            }),
            ...(currentTrafficSchedule?.id ? { traffic_schedule_id: currentTrafficSchedule.id } : {}),
        };

        if (shouldGoToPreviousPage) {
            setParams({ page: params.page - 1, data });
        } else {
            dispatch(fetchSchedule(params.page, limit, data));
        }
    }, [currentRoute, currentTrafficSchedule?.id, dispatch, limit, params.data, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    const onSearch = (data) => {
        setParams((prev) => ({
            ...prev,
            data
        }));
    };

    const renderContent = () => {
        return (
            <LSContainer
                headers={[
                    {
                        title: '',
                        width: '50px'
                    },
                    {
                        title: titles.NAME,
                        width: currentRoute ? '30%' : '40%'
                    },
                    {
                        title: titles.ROUTE_NUM,
                        width: '15%'
                    },
                    {
                        title: titles.START_DATE,
                        width: '17%'
                    },
                    {
                        title: titles.END_DATE,
                        width: '17%'
                    },

                    {
                        title: titles.ACTIONS,
                        isActions: true
                    }
                ]}
            >
                {schedulesData?.length > 0
                    ? (<>
                        {schedulesData
                            ?.map((el, _, arr) => <Item
                                item={el}
                                key={el.id}
                                statuses={statuses}
                                categories={vehicleCategories}
                                reloadList={(isDelete) => reloadList(isDelete && arr.length === 1)}
                            />)}
                    </>)
                    : (!loadingSchedules && <div>{messages.DATA_IS_NOT_FOUND}</div>)
                }
            </LSContainer>
        );
    };

    return (
        <>
            <PageLayout
                header={currentRoute ? '' : 'Расписания'}
                filters={
                    <Filter
                        onSearch={onSearch}
                        statuses={statuses}
                        categories={vehicleCategories}
                        currentRoute={currentRoute}
                    />
                }
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setIsOpenModalForm(true),
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: schedulesMeta?.total
                }}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: schedulesMeta,
                    limit,
                    setLimit
                }}
                loading={loadingSchedules}
                content={renderContent}
            />
            {isOpenModalForm && (
                <ModalForm
                    isOpen={isOpenModalForm}
                    onClose={() => setIsOpenModalForm(false)}
                    isNew={true}
                    statuses={statuses}
                    categories={vehicleCategories}
                    reloadList={() => setParams({
                        page: 1,
                        data: {
                            ...(!!currentRoute && {
                                num_route: currentRoute?.num,
                                transport_type: currentRoute?.category_id
                            }),
                            ...(currentTrafficSchedule?.id && { traffic_schedule_id: currentTrafficSchedule.id }), } })
                    }
                    currentRoute={currentRoute}
                />
            )}
        </>
    );
};

export default Schedule;
