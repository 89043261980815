import { useState } from 'react';
import FormButtons, { buttonsTypes } from '../../../../../../common/FormButtons';
import DragAndDrop from '../../../../../../common/DragAndDrop';
import ListRender from './ListRender';
import TypicalOperationsModal from './TypicalOperationsModal';
import NoneTypicalOperationsModal from './NoneTypicalOperationsModal';

const Operations = ({ data, onChange, isNew }) => {

    const [isOpenTypicalOperation, setIsOpenTypicalOperation] = useState(false);
    const [isOpenNoneTypicalOperation, setIsOpenNoneTypicalOperation] = useState(false);

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {data.operation_list?.length === 0 && (
                    <h2 style={{ color: 'red' }}>Выберите типовую операцию или добавьте нетиповую операцию *</h2>
                )}
            </div>

            <div className="row">
                <FormButtons
                    className="row__item"
                    justButton
                    buttons={[
                        {
                            ...buttonsTypes.defaultPrimary,
                            onClick: () => setIsOpenTypicalOperation(true),
                            name: 'Выбрать типовую операцию',
                            size: 'small'
                        },
                        {
                            ...buttonsTypes.defaultPrimary,
                            onClick: () => setIsOpenNoneTypicalOperation(true),
                            name: 'Выбрать нетиповую операцию',
                            size: 'small'
                        }
                    ]}
                />

                {isOpenTypicalOperation && (
                    <TypicalOperationsModal
                        isOpen={isOpenTypicalOperation}
                        onClose={() => setIsOpenTypicalOperation(false)}
                        onChange={onChange}
                        data={data}
                    />
                )}

                {isOpenNoneTypicalOperation && (
                    <NoneTypicalOperationsModal
                        isOpen={isOpenNoneTypicalOperation}
                        onClose={() => setIsOpenNoneTypicalOperation(false)}
                        onChange={onChange}
                        data={data}
                    />
                )}
            </div>

            <div className="row">
                <div className="row__item">
                    {data?.operation_list?.length > 0 && (
                        <DragAndDrop
                            list={data.operation_list}
                            onChange={(value) => onChange('operation_list', value)}
                            itemsRender={(item, index) => (
                                <ListRender
                                    item={item}
                                    index={index}
                                    onChange={onChange}
                                    data={data}
                                    isNew={isNew}
                                />
                            )}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Operations;