import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearSummarySchedule, loadReportSummarySchedule } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import messages from 'helpers/constants/messages';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import ReportBlock from 'components/common/Report/ReportBlock';

import Filter from './Filter';
import Item from './Item';

const initialFilter = {
    station_id: {},
    date: null
};

const SummarySchedule = () => {
    const dispatch = useDispatch();

    const summaryScheduleData = useSelector(transportPassengerSelectors.summaryScheduleData);
    const summaryScheduleMeta = useSelector(transportPassengerSelectors.summaryScheduleMeta);
    const loadingSummarySchedule = useSelector(transportPassengerSelectors.loadingSummarySchedule);

    const [filter, setFilter] = useState(initialFilter);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    useEffect(() => {
        return () => {
            dispatch(clearSummarySchedule());
        };
    }, [dispatch]);

    const prepareData = (data) => removeEmptyFields({
        ...data,
        station_id: data?.station_id?.id,
        date: dateWithDashYYYYMMDD(data?.date)
    });

    const onSearch = (newFilter = filter) => {
        dispatch(loadReportSummarySchedule(1, limit, prepareData(newFilter) ));
    };

    const getReport = (format) => {
        dispatch(loadReportSummarySchedule(1, limit, { ...prepareData(filter), report: format } ));
    };

    const renderContent = () => {
        return (
            summaryScheduleData?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: '№', width: '10%' },
                            { title: 'Маршрут', width: '30%' },
                            { title: 'Направление', width: '20%' },
                            { title: 'Вид ТС', width: '20%' },
                            { title: 'Время начала', width: '15%' },
                        ]}>

                        {summaryScheduleData.map((item, index) => (
                            <Item
                                key={index}
                                item={item}
                            />
                        ))}
                    </LSContainer>
                )
                : (!loadingSummarySchedule && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <PageLayout
            header="Сводное расписание"

            filters={
                <Filter
                    initialFilter={initialFilter}
                    filter={filter}
                    setFilter={setFilter}
                    onSearch={onSearch}
                />
            }

            informPanelProps={{
                buttons: (
                    <ReportBlock
                        onGetReport={getReport}
                        disabled={!filter?.station_id || !filter?.date}
                    />
                ),
                total: summaryScheduleMeta?.total
            }}

            loading={loadingSummarySchedule}

            content={renderContent}

            paginationProps={{
                loadList: (page, limit) => dispatch(loadReportSummarySchedule(page, limit, prepareData(filter))),
                list: summaryScheduleMeta,
                limit,
                setLimit
            }}
        />
    );
};

export default SummarySchedule;
