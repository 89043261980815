import React from 'react';
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

interface InputNumberProps {
    value: number | string;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    label?: string;
    className?: string;
    name?: string;
    required?: boolean;
    error?: boolean;
    helperText?: React.ReactNode;
}

const InputNumber = ({ 
    value,
    className,
    onChange,
    name,
    label,
    required,
    error,
    helperText
}: InputNumberProps) => {
    return (
        <NumericFormat
            value={value}
            label={label}
            customInput={TextField}
            size="small"
            className={className}
            required={required}
            allowNegative={false}
            onChange={onChange}
            name={name}
            error={error}
            helperText={helperText}
        />
    );
};

export default InputNumber;