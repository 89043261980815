import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { loadCameraInfo } from 'redux/VideoAnalytics/actions';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';

import type { InfoList } from '../types';

interface SelectVehicleBrandProps {
    value: string
    onChange: (value: string) => void;
}

const SelectVehicleBrand = ({ value, onChange }: SelectVehicleBrandProps) => {
    const infoList: InfoList = useStoreProp(loadCameraInfo, 'videoAnalytics', 'infoList');

    const vehicle_brand = infoList.brand_and_model
        ? Object.keys(infoList.brand_and_model)
        : [];

    return (
        <FormControl variant="outlined" size="small">
            <InputLabel>Марка ТС</InputLabel>
            <Select
                label="Марка ТС"
                name="vehicle_brand"
                value={value}
                onChange={(e) => onChange(e.target.value)}
            >
                {vehicle_brand?.length ? vehicle_brand.map(key =>
                    <MenuItem key={key} value={key}>{key}</MenuItem>
                ) : <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>}
            </Select>
        </FormControl>
    );
};

export default SelectVehicleBrand;
