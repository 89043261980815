import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { createWorkorder, loadWorkordersByDate } from 'redux/TransportPassenger/actions';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import messages from 'helpers/constants/messages';
import { dateWithDashYYYYMMDD, getDateWithDuration, getEndOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';

import AddEditModal from '../Workorders/AddEditModal';

import DataTable from './DataTable';
import Filters from './Filters';

const WorkordersCalendar = () => {
    const dispatch = useDispatch();

    const initialState = {
        start_date: dateWithDashYYYYMMDD(getDateWithDuration({ days: -7 })),
        end_date: dateWithDashYYYYMMDD(getEndOf('day')),
        status: '',
        garage_numbers: [],
        vehicle_id_list: [],
        ids: []
    };

    const { data, headers } = useSelector(transportPassengerSelectors.workordersByDateData);
    const meta = useSelector(transportPassengerSelectors.workordersByDateMeta);
    const loading = useSelector(transportPassengerSelectors.loadingWorkordersByDate);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: initialState });
    const [openAddModal, setOpenAddModal] = useState(false);

    const reloadData = () => find();

    const find = (filters = params?.data) => {
        const result = removeEmptyFields({
            ...filters,
            start_date: dateWithDashYYYYMMDD(filters?.start_date),
            end_date: dateWithDashYYYYMMDD(filters?.end_date),
            garage_numbers: filters.garage_numbers?.length ? filters.garage_numbers?.map(({ garage_number }) => garage_number) : [],
            vehicle_id_list: filters.vehicle_id_list?.length ? filters.vehicle_id_list?.map(({ id }) => id) : [],
            ids: filters.ids?.length ? filters.ids?.map(({ id }) => id) : [],
        });
        dispatch(loadWorkordersByDate(params.page, limit, result));
    };

    useEffect(() => {
        find();
    }, [dispatch, limit, params]);

    const onSave = (data) => {
        dispatch(createWorkorder(data, () => {
            setParams((old) => ({
                ...old,
                page: 1
            }));
            setOpenAddModal(false);
        }));
    };

    const renderContent = () => (
        data?.length > 0 || Object.keys(headers || {})?.length > 0
            ? (
                <DataTable
                    params={params}
                    reloadData={reloadData}
                />
            )
            : (!loading
                && Object.keys(params).length !== 0
                && messages.DATA_IS_NOT_FOUND_NEW
            )
    );

    return (
        <>
            <PageLayout
                header="Наряды (календарный план)"
                filters={<Filters
                    setParams={(data) => setParams({ ...params, data })}
                    initialState={initialState}
                />}
                loading={loading}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenAddModal(true),
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),

                    total: meta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
                customStyles={{ padding: 0, overflowY: 'hidden' }}
            />
            {openAddModal
                && <AddEditModal
                    isNew={true}
                    data={{
                        date_at: new Date(),
                    }}
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    onSave={onSave}
                />
            }
        </>
    );
};

export default WorkordersCalendar;
