// иконка для списка
const iconCluster = (props) => {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 107.000000 107.000000">
            <g>
                <rect x="-1" y="-1" width="109" height="109" id="canvas_background" fill="none"/>
            </g>
            <g>
                <g transform="translate(0,107) scale(0.10000000149011612,-0.10000000149011612) " fill="${props?.fill || '#000'}" id="svg_battery_1">
                    <path d="m404.52236,1047.19878c-227.70224,-55.17014 -397.22506,-272.84146 -397.22506,-511.57773c0,-285.88168 240.74246,-525.62105 526.62414,-525.62105c285.88167,0 526.62414,240.74246 526.62414,526.62414c0,237.73318 -172.5321,457.41068 -402.24054,511.57774c-79.24439,18.05568 -175.54138,18.05568 -253.78268,-1.0031z" id="svg_battery_2" stroke="null"/>
                </g>
                <g fill="${props?.innerFill || '#fff'}" stroke="null" id="svg_battery_7">
                    <path stroke="null" id="svg_battery_6" d="m34.41,80.37c-1.27,0 -2.52,-0.48 -3.59,-1.36c-7.91,-6.84 -12.42,-16.78 -12.27,-27.31c0.09,-8.69 3.29,-16.98 9.03,-23.38c1.07,-1.16 2.52,-1.84 4.08,-1.89c1.55,-0.05 3.01,0.59 4.08,1.7l17.66,18.24l18.39,-17.9c1.11,-1.07 2.57,-1.7 4.13,-1.61c1.5,0.09 2.95,0.77 3.97,1.94c5.63,6.55 8.69,14.94 8.59,23.63c-0.09,10.43 -4.71,20.18 -12.66,26.88c-2.38,1.99 -5.92,1.65 -7.86,-0.77c-1.99,-2.38 -1.65,-5.97 0.73,-7.96c5.38,-4.56 8.49,-11.21 8.59,-18.24c0.05,-3.88 -0.82,-7.67 -2.52,-11.06l-17.62,17.17c-2.18,2.13 -5.72,2.09 -7.86,-0.14l-16.83,-17.37c-1.7,3.35 -2.67,7.03 -2.72,10.87c-0.05,7.18 3.01,13.92 8.39,18.58c2.38,2.04 2.61,5.63 0.63,8.01c-1.11,1.31 -2.72,1.99 -4.31,1.99l-0.01,-0.01l0,0z"/>
                </g>
            </g>
        </svg>
    `;
};

export default iconCluster;
