import moduleName from './module';
const prefix = `${moduleName}`;

export const LOAD_COMPANIES = `${prefix}/LOAD_COMPANIES`;
export const LOADING_COMPANIES = `${prefix}/LOADING_COMPANIES`;
export const LOADED_COMPANIES = `${prefix}/LOADED_COMPANIES`;
export const GET_STANDARDIZED_COMPANIES_LIST = `${prefix}/GET_STANDARDIZED_COMPANIES_LIST`;
export const GOT_STANDARDIZED_COMPANIES_LIST = `${prefix}/GOT_STANDARDIZED_COMPANIES_LIST`;
export const CREATE_COMPANY = `${prefix}/CREATE_COMPANY`;
export const EDIT_COMPANY = `${prefix}/EDIT_COMPANY`;
export const DELETE_COMPANY = `${prefix}/DELETE_COMPANY`;
export const IS_SAVE_COMPANY = `${prefix}/IS_SAVE_COMPANY`;
export const LOAD_CURRENT_COMPANY = `${prefix}/LOAD_CURRENT_COMPANY`;
export const LOADED_CURRENT_COMPANY = `${prefix}/LOADED_CURRENT_COMPANY`;
export const LOAD_ORGANIZATION = `${prefix}/LOAD/ORGANIZATION`;
export const LOADING_ORGANIZATION = `${prefix}/LOADING/ORGANIZATION`;
export const LOADED_ORGANIZATION = `${prefix}/LOADED/ORGANIZATION`;
export const CLEAR_STANDARDIZED_COMPANIES_LIST =  `${prefix}/CLEAR_STANDARDIZED_COMPANIES_LIST`;
export const DOWNLOAD_ORGANIZATIONS_REPORT = `${prefix}/DOWNLOAD/ORGANIZATIONS/REPORT`;
export const LOAD_COMPANIES_FOR_ALL_ROLES = `${prefix}/LOAD_COMPANIES_FOR_ALL_ROLES`;
//filiations
export const LOAD_CURRENT_FILIATION_LIST = `${prefix}/LOAD_CURRENT_FILIATION_LIST`;
export const LOADING_CURRENT_FILIATION_LIST = `${prefix}/LOADING_CURRENT_FILIATION_LIST`;
export const LOADED_CURRENT_FILIATION_LIST = `${prefix}/LOADED_CURRENT_FILIATION_LIST`;
export const CREATE_FILIATION = `${prefix}/CREATE_FILIATION`;
export const EDIT_FILIATION = `${prefix}/EDIT_FILIATION`;
export const DELETE_FILIATION = `${prefix}/DELETE_FILIATION`;

export const LOAD_COMPANY_ROLE_LIST = `${prefix}/LOAD_COMPANY_ROLE_LIST`;
export const LOADING_COMPANY_ROLE_LIST = `${prefix}/LOADING_COMPANY_ROLE_LIST`;
export const LOADED_COMPANY_ROLE_LIST = `${prefix}/LOADED_COMPANY_ROLE_LIST`;
export const CREATE_COMPANY_ROLE = `${prefix}/CREATE_COMPANY_ROLE`;
export const EDIT_COMPANY_ROLE = `${prefix}/EDIT_COMPANY_ROLE`;
export const DELETE_COMPANY_ROLE = `${prefix}/DELETE_COMPANY_ROLE`;

export const CREATE_ROLES_CONTRACTS = `${prefix}/CREATE_ROLES_CONTRACTS`;
export const CHANGE_ROLES_CONTRACTS = `${prefix}/CHANGE_ROLES_CONTRACTS`;
export const DELETE_ROLES_CONTRACTS = `${prefix}/DELETE_ROLES_CONTRACTS`;

export const LOAD_SHOWCASE = `${prefix}/LOAD_SHOWCASE`;
export const LOADED_SHOWCASE = `${prefix}/LOADED_SHOWCASE`;
export const LOADING_SHOWCASE = `${prefix}/LOADING_SHOWCASE`;
