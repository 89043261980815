import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { loadCameraInfo } from 'redux/VideoAnalytics/actions';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';

import type { InfoList } from '../types';

interface SelectColorProps {
    value: string
    onChange: (value: string) => void
}

const SelectColor = ({ value, onChange }: SelectColorProps) => {
    const infoList: InfoList = useStoreProp(loadCameraInfo, 'videoAnalytics', 'infoList');

    const vehicle_colors = infoList?.color;

    return (
        <FormControl variant="outlined" size="small">
            <InputLabel>Цвет</InputLabel>
            <Select
                label="Цвет"
                onChange={e => onChange(e.target.value)}
                value={value}
                name="vehicle_color"
            >
                {vehicle_colors?.length ? (
                    vehicle_colors.map((item) => (
                        <MenuItem key={item} value={item}>
                            {item}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default SelectColor;
