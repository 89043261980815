import React from 'react';
import { VehicleInfo } from '../../../../common/Transport/VehicleInfo';
import { Collapse } from '@mui/material';
import {
    loadVehicleCategories,
    loadVehiclesTypes
} from '../../../../../redux/TransportPassenger/actions';

function VehiclesInfo ({ info, isOpen }) {
    const storeName = 'transportPassenger';
    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit className="info" style={{ paddingLeft: 20, paddingTop: 10 }}>
            <VehicleInfo
                info={info}
                storeName={storeName}
                loadVehicleCategories={loadVehicleCategories}
                loadVehiclesTypes={loadVehiclesTypes}
            />
        </Collapse>
    );
}

export default VehiclesInfo;
