import { useState } from 'react';
import { SketchPicker } from 'react-color';
import Modal from '../Modal';
import FormButtons, { buttonsTypes } from '../FormButtons';

interface ColorPickerProps {
    open: boolean
    color: string
    onChangeColor: (value: string) => void
    onClose: () => void
}

const ColorPicker = ({
    open,
    color,
    onChangeColor,
    onClose,
}: ColorPickerProps) => {

    const [colorPicker, setColorPicker] = useState(color);

    const handelSaveColor = () => {
        onChangeColor(colorPicker);
        onClose();
    };

    return (
        <Modal
            isOpen={open}
            small
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handelSaveColor
                    },
                ]}
            />}
        >
            <SketchPicker
                width={'calc(100% - 20px'}
                color={colorPicker}
                onChange={({ hex }) => setColorPicker(hex)}
            />
        </Modal>
    );
};

export default ColorPicker;
