import { List } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../helpers/constants/messages';
import Context from '../../../../helpers/context';
import { useStoreProp } from '../../../../helpers/hooks';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import { authSelectors } from '../../../../redux/Auth';
import { loadWorkRequestList, loadWorkRequestStatuses } from '../../../../redux/WorkRequests/actions';
import CustomPagination from '../../../common/CustomPagination';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import Loading from '../../../common/Loading';
import Filter from './Filter';
import Item from './Item';
import ModalForm from './ModalForm';

const WorkRequests = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const {
        list: { data, meta },
        listLoading,
    } = useSelector(({ workRequests }) => workRequests);

    const authUser = useSelector(authSelectors.authUser);

    const statuses = useStoreProp(loadWorkRequestStatuses, 'workRequests', 'statuses');

    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);
    const [page, setPage] = useState(1);

    const [params, setParams] = useState({});
    const [openAddModal, setOpenAddModal] = useState(false);

    const loadList = useCallback(() => {
        const paramsWithoutEmpty = removeEmptyFields(params);

        return  dispatch(loadWorkRequestList(page, limit, paramsWithoutEmpty));

    }, [dispatch, limit, page, params]);

    useEffect(() => {
        loadList();
    },[loadList]);

    return (
        <>
            <h1>Заявки</h1>
            <Filter 
                setParams={setParams}
                statuses={statuses}
            />
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenAddModal(true),
                            disabled: !permissions?.is_create
                        }
                    ]}
                />
            </div>
            {openAddModal 
            && <ModalForm 
                isNew={true} 
                isOpen={openAddModal}
                onClose={() => setOpenAddModal(false)} 
                loadList={loadList}
            />}

            {listLoading && <Loading circular={true}/>}

            {data?.length > 0
                ? <>
                    <List className="list">
                        {data?.length && data?.map(item =>
                            <Item
                                key={item.id}
                                item={item}
                                statuses={statuses}
                                loadList={loadList}
                                permissions={permissions}
                                userId={authUser?.info?.id}
                            />         
                        )}
                    </List>
                    <CustomPagination
                        loadList={(page) => setPage(page)}
                        list={meta}
                        limit={limit} 
                        setLimit={setLimit}
                    />
                </>
                : !listLoading && <div>{messages.DATA_IS_NOT_FOUND}</div>
            }
        </>
    );
};

export default WorkRequests;
