// собираем все слои
// todo переписать на hook
const getAllLayers = () => {
    const reduxContext = require.context(
        '../Layers',
        true,
        /^.\/\w+\/index\.js$/
    );

    const modulesContext = require.context(
        '../../../modules',
        true,
        /^\.\/[^/]+\/MapLayer\/index\.js$/
    );

    // проходим по модулям и собираем объект
    const getLayers = (context) => context.keys().reduce((res, moduleKey) => {
        const module = context(moduleKey);

        const moduleConfig = module?.config || {};
        const moduleName = moduleConfig?.name || false;

        if (moduleName) {
            return {
                ...res,
                [module.config.slug]: {
                    ...moduleConfig,
                    // название слоя
                    name: module.config.name,
                    // сам слой
                    Component: module.default,
                    // меню
                    menu: moduleConfig.mapContextMenu || false,
                    // кластер иконка
                    clusterIcon: moduleConfig.clusterIcon || null,
                    // сайдбар
                    SideBar: module.SideBar || false,
                    onAfterActivateAction: moduleConfig.onAfterActivateAction || false
                }
            };
        }
        return res;
    }, {});

    return { ...getLayers(reduxContext), ...getLayers(modulesContext) };
};

// export instance
export default function layersLazyLoad(field = null) {
    if ( typeof (layersLazyLoad.instance) == 'undefined' ) {
        layersLazyLoad.instance = getAllLayers();
    }

    if (field) {
        return Object
            .keys(layersLazyLoad.instance)
            .reduce((res, key) => ({
                ...res,
                [key]: layersLazyLoad.instance[key][field]
            }), {});
    }
    return layersLazyLoad.instance;
};
