import React, { useState } from 'react';
import { TextField } from '@mui/material';
import Modal from '../../../../common/Modal';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import { useDispatch } from 'react-redux';
import { useValidation } from '../../../../../helpers/hooks';
import { createTypeOfMistakesForCheckList, editTypeOfMistakesForCheckList } from '../../../../../redux/DorisControl/actions';
import { ModalFormProps } from './types';

const AddEditModal = ({
    item,
    isOpen, 
    isNew = false,
    onClose,
    reloadList,
}: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const [data, setData] = useState(item?.name || '');

    const handleChange = (e: { target: { name: string; value: string; }; }) => {
        const { name, value } = e.target;
        setData(value);
        validation.deleteKey(name);
    };

    const onAcceptModal = () => {
        dispatch(isNew 
            ? createTypeOfMistakesForCheckList({ name: data }, reloadList) 
            : editTypeOfMistakesForCheckList(item?.id, { name: data }, reloadList));
        onClose();
    };

    return (<Modal
        isOpen={isOpen}
        title={isNew ? 'Добавить тип ошибки' : 'Редактировать тип ошибки'}
        onClose={onClose}
        noPadding={true}
        buttons={<FormButtonsComponent
            buttons={[
                {
                    ...buttonsTypes.close,
                    onClick: onClose
                },
                {
                    ...buttonsTypes.save,
                    onClick: onAcceptModal,
                    disabled: !data.trim()
                }
            ]}
        />}
    >
        <form className="modal__form">
            <div className="block">
                <TextField
                    label="Тип ошибки"
                    size="small"
                    value={data}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={handleChange}
                    required
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                />
            </div>
        </form>
    </Modal>);
};

export default AddEditModal;