import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    FormControl,
    TextField,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import Attachments from 'components/common/Upload/Attachments';
import SelectRoutes from 'components/common/Autocomplete/PassengerTransport/Routes';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SelectEmployees from 'components/common/Autocomplete/Transport/Employees';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import titles from 'helpers/constants/titles';
import formatDate from 'helpers/constants/dateFormat';
import { useValidation, useStoreProp } from 'helpers/hooks';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import {
    createAppeal,
    editAppeal,
    loadAppealType,
    loadAppealStatus,
    loadEmployees
} from 'redux/TransportPassenger/actions';

import AddressField from './AddressField';
import { passengerTransportAppealsURI } from '../moduleConfig';

const test_id_prefix = passengerTransportAppealsURI; // префикс для полей для автотестов

const ModalForm = ({ isNew, isOpen, onClose, el= {}, reloadData, }) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const appealType = useStoreProp(loadAppealType, 'transportPassenger', 'appealType');
    const appealStatus = useStoreProp(loadAppealStatus, 'transportPassenger', 'appealStatus');

    const initialState = {
        type_id: el.type_id || '',
        title: el.title || '',
        decision: el.decision || '',
        status_id: el.status_id || '1',
        text: el.text || '',
        address: el.address || {},
        documents: el.documents || [],
        date_plan: el.date_plan || null,
        date_fact: el.date_fact || null,
        user: el.user || {},
        organization: el.organization || { },
        route: el.route || { },
        applicant: el.applicant || ''
    };

    const [formData, setFormData] = useState(initialState);

    const handleChange = (name, value, limit) => {
        if (limit) {
            setFormData((prevVal) => ({
                ...formData,
                [name]: value.length < limit ? value : prevVal[name]
            }));
        } else {
            setFormData({
                ...formData,
                [name]: value,
                ...(name === 'organization' && {
                    user: {},
                }),
            });
        }

        validation.deleteKey(name === 'route' ? 'route_id' : name);
    };

    const result = () => ({
        ...formData,
        date_plan: fullDateTimeWithTimeZone(formData.date_plan),
        date_fact: formData.date_fact && fullDateTimeWithTimeZone(formData.date_fact),
        organization_id: formData.organization.id || null,
        route_id: formData.route.id || null,
        user_id: formData.user.id || null
    });

    const handleCreateAppeal = () => {
        const paramsWithoutEmpty = removeEmptyFields(result());
        if (paramsWithoutEmpty.organization_id || paramsWithoutEmpty.user_id) {
            dispatch(createAppeal(
                {
                    ...paramsWithoutEmpty,
                    status_id: '2'
                },
                reloadData
            ));
        } else {
            dispatch(createAppeal(paramsWithoutEmpty, reloadData));
        }
    };

    const handleEditAppeal = () => dispatch(editAppeal(el.id, result()));

    const isDisabled = !formData.type_id
        || !formData.title
        || !formData.address
        || !formData.route?.name
        || !formData.text
        || !formData.status_id;

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
        }
    }, [validation, onClose, requestStatus]);

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose,
                        'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/buttons/cancel`
                    },
                    isNew
                        ? {
                            ...buttonsTypes.create,
                            onClick: handleCreateAppeal,
                            // disabled: isDisabled,
                            'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/buttons/save`
                        }
                        : {
                            ...buttonsTypes.save,
                            onClick: handleEditAppeal,
                            disabled: isDisabled,
                            'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/buttons/save`
                        }
                ]}
            />}
        >
            <div className="modal__form">
                <FormInfoWrapper
                    error={validation.isKey('title')}
                    helperText={validation.get('title') || 'Максимум 50 символов'}
                    className="block"
                    test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/title/input`}
                >
                    <TextField
                        required
                        label="Название обращения"
                        variant="outlined"
                        size="small"
                        value={formData.title || ''}
                        name="title"
                        onChange={(e) => handleChange(e.target.name, e.target.value, 50 )}
                        type="text"
                        error={validation.isKey('title')}
                        InputLabelProps={{ 'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/title/label` }}
                        inputProps={{
                            'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/title/input`
                        }}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    error={validation.isKey('applicant')}
                    helperText={validation.get('applicant')}
                    className="block"
                    test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/applicant/input`}
                >
                    <TextField
                        label="ФИО заявителя"
                        variant="outlined"
                        size="small"
                        name="applicant"
                        value={formData.applicant || ''}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        error={validation.isKey('applicant')}
                        InputLabelProps={{ 'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/applicant/label` }}
                        inputProps={{
                            'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/applicant/input`
                        }}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    error={validation.isKey('text')}
                    helperText={validation.get('text') || 'Максимум 300 символов'}
                    className="block"
                    test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/text/input`}
                >
                    <TextField
                        required
                        label="Текст обращения"
                        variant="outlined"
                        size="small"
                        value={formData.text || ''}
                        name="text"
                        onChange={(e) => handleChange(e.target.name, e.target.value, 300 )}
                        type="text"
                        error={validation.isKey('text')}
                        InputLabelProps={{ 'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/text/label` }}
                        inputProps={{
                            'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/text/input`
                        }}
                    />
                </FormInfoWrapper>

                <div className="block">
                    <AddressField
                        value={formData.address}
                        onChange={value => handleChange('address', value)}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}`}
                    />
                </div>

                <div className="block">
                    <SelectCompany
                        selected={formData.organization}
                        onChange={(value) => handleChange('organization', value)}
                        helperText={validation.get('organization')}
                        error={validation.isKey('organization')}
                        label={titles.SELECT_ORGANIZATION}
                        filter={{ withDeleted: 1 }}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/company`}
                    />
                </div>

                <div className="block">
                    <SelectEmployees
                        onChange={(value) => handleChange('user', value)}
                        selected={formData.user}
                        error={validation.isKey('user')}
                        helperText={validation.get('user')}
                        filter={{ organization_id: formData.organization?.id }}
                        disabled={!formData.organization?.id}
                        loadList={loadEmployees}
                        storeName="transportPassenger"
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/employees`} // TODO добавить в автокоплит когда его поменяют на новый
                    />
                </div>

                <div className="block">
                    <SelectRoutes
                        selected={formData.route}
                        onChange={(value) => handleChange('route', value)}
                        error={validation.isKey('route_id')}
                        helperText={validation.get('route_id')}
                        required={true}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/routes`}
                    />
                </div>

                <FormInfoWrapper
                    error={validation.isKey('type_id')}
                    helperText={validation.get('type_id')}
                    className="block"
                    test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/type_id/input`}
                >
                    <FormControl
                        error={validation.isKey('type_id')}
                        required
                        size="small"
                        variant="outlined"
                    >
                        <InputLabel data-testid={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/type_id/label`}>{'Тип обращения'}</InputLabel>
                        <Select
                            required
                            value={formData.type_id}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            label="Тип обращения"
                            name="type_id"
                            inputProps={{
                                'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/type_id/input`
                            }}
                        >
                            <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                            {Object?.keys(appealType)?.map((key) => (
                                <MenuItem key={key} value={key}>{appealType[key]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormInfoWrapper>

                <div className="block">
                    <SingleKeyboardDateTimePicker
                        onChange={(value) => handleChange('date_plan', value)}
                        value={formData.date_plan}
                        isRequired={true}
                        disableFuture={false}
                        label="Плановая дата выполнения"
                        pickerFormat={formatDate.DATE_FOR_PICKER}
                        dateOnly={true}
                        error={validation.isKey('date_plan')}
                        helperText={validation.get('date_plan')}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/date_plan`}
                    />
                </div>

                {!isNew
                    && <>
                        <FormInfoWrapper
                            error={validation.isKey('status_id')}
                            helperText={validation.get('status_id')}
                            className="block"
                            test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/status_id/input`}
                        >
                            <FormControl
                                error={validation.isKey('status_id')}
                                required
                                size="small"
                                variant="outlined"
                            >
                                <InputLabel data-testid={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/status_id/label`}>{'Статус обращения'}</InputLabel>
                                <Select
                                    required
                                    value={formData.status_id}
                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    label="Статус обращения"
                                    name="status_id"
                                    inputProps={{
                                        'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/status_id/input`
                                    }}
                                >
                                    <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                                    {Object?.keys(appealStatus)?.map((key) => (
                                        <MenuItem key={key} value={key}>{appealStatus[key]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormInfoWrapper>

                        {Number(formData.status_id) === 3
                        && <>
                            <FormInfoWrapper
                                error={validation.isKey('decision')}
                                helperText={validation.get('decision')}
                                className="block"
                                test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/decision/input`}
                            >
                                <TextField
                                    label="Решение"
                                    variant="outlined"
                                    size="small"
                                    value={formData.decision || ''}
                                    disabled={Number(formData.status_id) !== 3}
                                    required={Number(formData.status_id) === 3}
                                    name="decision"
                                    onChange={(e) => handleChange(e.target.name, e.target.value, 300 )}
                                    type="text"
                                    InputLabelProps={{ 'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/decision/label` }}
                                    error={validation.isKey('decision')}
                                    inputProps={{
                                        'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/decision/input`
                                    }}
                                />
                            </FormInfoWrapper>

                            <div className="block">
                                <SingleKeyboardDateTimePicker
                                    onChange={(value) => handleChange('date_fact', value)}
                                    value={formData.date_fact}
                                    label="Фактическая дата выполнения"
                                    pickerFormat={formatDate.DATE_FOR_PICKER}
                                    error={validation.isKey('date_fact')}
                                    helperText={validation.get('date_fact')}
                                    dateOnly={true}
                                    test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/date_fact`}
                                />
                            </div>
                        </>}
                    </>
                }

                <Attachments
                    onChange={(files) => handleChange('documents', files)}
                    files={formData?.documents}
                    test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}`}
                />
            </div>
        </Modal>
    );
};

export default ModalForm;
