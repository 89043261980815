import messages from 'helpers/constants/messages';

import labels from './labels';

const RenderEventInfo = ({ data }) => {
    const renderItems = (labels) => (
        <>
            {Object.keys(labels).map(key => {
                const { title, value } = labels[key];
                const val = data[key] === '' ? messages.NO_DATA : (data[key] ?? messages.NO_DATA);
                return (
                    <div key={key} className="row">
                        <div style={{ width: '45%' }}>
                            <strong>{title}:&nbsp;</strong>
                        </div>
                        <div style={{ width: '45%' }}>
                            <span>{(value && value(data[key])) || val}</span>
                        </div>
                    </div>
                );
            })}
        </>
    );
    return (
        <div className="row">
            <div style={{ marginRight: '2rem', minWidth: '40%', maxWidth: '50%' }}>{renderItems(labels.first)}</div>
            <div style={{ marginRight: '2rem', minWidth: '20%', maxWidth: '50%' }}>{renderItems(labels.second)}</div>
        </div>
    );
};

export default RenderEventInfo;
