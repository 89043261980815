import {
    getDateToFormat,
    getHumanDate,
    getMonthYear
} from '../../../../../helpers/date.config';

export const getTitle = (e) => {
    const addr = [
        e?.address?.city_name,
        e?.address?.street,
        e?.address?.house,
    ]
        .reduce((r, i) => i ? [...r, i] : r, []);
    return `${e?.name} ${addr.length > 0 ? `(${addr?.join(', ')})` : ''}`;
};

export const typeOptions = {
    byMinutes: {
        format: (date) => getDateToFormat(date, 'HH:mm dd.MM.yyyy'),
    },
    byHours: {
        format: (date) => getDateToFormat(date, 'HH:mm dd.MM.yyyy'),
    },
    byDays: {
        format: getHumanDate,
    },
    byMonth: {
        format: getMonthYear,
    },
    default: {
        format: getHumanDate,
    }
};