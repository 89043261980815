const messages = {
    LOADING: 'Загрузка...',
    NO_DATA: 'Нет данных',
    DATA_IS_NOT_FOUND: 'Данных не найдено',
    DATA_IS_NOT_FOUND_NEW: 'К сожалению, данные по вашему запросу отсутствуют. Попробуйте поменять параметры вывода с помощь фильтра.',
    WORKORDER_IS_NOT_FOUND_FOR_THE_DATE: 'Нет наряда на дату',
    WORKORDER_IS_NOT_FOUND_FOR_THE_PARAMS: 'По указанным параметрам наряды не найдены',
    WORKORDER_EVENTS_ARE_NOT_FOUND: 'Нет событий на наряд',
    NOTHING_FOUND: 'Ничего не найдено',
    EVENTS_IS_NOT_FOUND: 'События не найдены',
    INFO_IS_NOT_FOUND: 'Информация отсутствует',
    REQUEST_DATA_IS_NOT_FOUND: 'Данных по вашему запросу не найдено...',
    CONFIRM_DELETE: 'Вы действительно хотите удалить?',
    CONFIRM_SAVE: 'Вы действительно хотите сохранить изменения?',
    CONFIRM_CANCEL: 'Вы действительно хотите отменить?',
    INVALID_DATE_FORMAT: 'Неверный формат даты',
    INVALID_TIME_FORMAT: 'Неверный формат времени',
    NOT_CHOSEN: 'Не выбрано',
    ERROR_DATE_RANGE: 'Дата конца периода должна быть после даты начала периода',
    ERROR_TIME_RANGE: 'Время конца периода должно быть после времени начала периода',
    EDIT_SUCCESS: 'Данные были успешно изменены',
    DELETE_SUCCESS: 'Успешно удалено',
    NO_DATA_FOR_PERIOD: 'Нет данных на выбранный период',
    SAVE_SUCCESS: 'Данные были успешно сохранены',
    DECLARED_BY_MANUFACTURER: 'Согласно производителю комплекса',
    ERROR_VALIDITY_PERIOD: 'Срок действия документа не может быть меньше даты создания документа',
    TRY_LATER: 'Загрузка не удалась, попробуйте позднее',
    YES: 'Да',
    NO: 'Нет',
    INFO_NOT_AVAILABLE: 'Информация недоступна',
    SOMETHING_WENT_WRONG: 'Что то пошло не так попробуйте заново',
    SAVED: 'Сохранено',
    FIXED: 'Зафиксировано',
    COPY_SUCCESS: 'Копия успешно создана',
    NO_DATA_FOR_YOUR_REQUEST: 'К сожалению, данные по вашему запросу отсутствуют. Попробуйте поменять параметры вывода с помощью фильтра',
    NO_IMAGE: 'Изображение отсутствует',
    NO_VALUE: '-',
    NO_MATERIALS: 'Материалы отсутствуют',
};

export default messages;
