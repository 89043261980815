import React from 'react';
import cn from 'classnames';

// название в элементе
const Name = ({
    separator,
    children,
    className,
    style,
}) => (
    <span className={cn('name', className)} style={style}>
        {children}{separator}
    </span>
);

export default Name;