import { useState, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete } from '@mui/lab';
import { FormControl, TextField } from '@mui/material';

import { roadworksSelectors } from 'redux/RoadWorks';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import SelectUnits from 'components/common/Autocomplete/RoadWorks/Units';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import type { MeasurementUnit, RoadWorkGroup } from 'types/RoadWorks';

interface FiltersState {
    name: string;
    unit_of_measurement_list: MeasurementUnit[];
    road_work_group_list: RoadWorkGroup[];
}

interface Props {
    setParams: Dispatch<SetStateAction<{page: number }>>
}

const initialState: FiltersState = {
    name: '',
    unit_of_measurement_list: [],
    road_work_group_list: [],
};

const Filter = ({ setParams }: Props) => {
    const [filters, setFilters] = useState(initialState);

    const groups: RoadWorkGroup[] = useSelector(roadworksSelectors.roadWorksGroupsData);

    const onChange = <K extends keyof FiltersState>(name: K, value: FiltersState[K]) => {
        setFilters(filters => ({
            ...filters,
            [name]: value,
        }));
    };

    const resetFilters = (needRefresh: boolean) => {
        setFilters(initialState);
        if (needRefresh) {
            setParams({ page: 1 });
        }
    };


    const search = (mergedFilters?: FiltersState) => {
        const selectedFilters = mergedFilters ? mergedFilters : filters;

        setParams(() => {
            const { unit_of_measurement_list, road_work_group_list, ...restFilters } = selectedFilters;

            return {
                ...restFilters,
                page: 1,
                unit_of_measurement_id_list: unit_of_measurement_list.map(el => el.id),
                road_work_group_id_list: road_work_group_list.map(el => el.id)
            };
        });
    };


    const updateFilter = (filters: Partial<FiltersState>) => { // фильтры с backend
        if (Object.keys(filters).length > 0) {
            const mergedFilters = { ...initialState, ...filters };

            setFilters(mergedFilters);
            search(mergedFilters);
        }
    };

    const isDisabled = Object.values(removeEmptyFields(filters)).length === 0;

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={search}
            filter={filters}
            setUserFilter={updateFilter}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <TextField
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    value={filters.name}
                    name="name"
                    onChange={e => onChange('name', e.target.value)}
                    type="text"
                />
                <FormControl required size="small" variant="outlined">
                    <SelectUnits
                        multiple
                        selected={filters.unit_of_measurement_list}
                        onChange={(units: MeasurementUnit[]) => onChange('unit_of_measurement_list', units)}
                    />
                </FormControl>
                <FormControl required size="small" variant="outlined">
                    <Autocomplete
                        multiple
                        value={filters.road_work_group_list}
                        options={groups}
                        getOptionLabel={(option) => option.name}
                        size="small"
                        onChange={(_, value) => onChange('road_work_group_list', value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label="Группа дорожных работ"
                            />
                        )}
                    />
                </FormControl>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
