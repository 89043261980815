import { config } from '../../config';

const apiMonitoringUrl = () => config.get('urls').apiMonitoringUrl;

const apiUrls = {
    // список
    getList: () => `${apiMonitoringUrl()}/monitoring`,

    getStatuses: () => `${apiMonitoringUrl()}/monitoring/statuses`,
};

export default apiUrls;
