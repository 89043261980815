import PageLayout from 'components/layout/PageLayout';
import Filter from './Filter';

const ReportCPVFByComplex = () => {
    return (
        <PageLayout
            header="Отчет по времени работы ПУИД в часах"
            filters={<Filter />}
        />
    );
};

export default ReportCPVFByComplex;