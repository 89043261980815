import { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import mapHelper from 'helpers/mapHelper';
import { usePrevious, useDebounce } from 'helpers/hooks';
import { focusIncidentsSelectors } from 'redux/FocusIncidents';
import * as actions from 'redux/FocusIncidents/actions';
import config from 'components/MapComponents/Layers/DtpFocus/config.js';
import { createIconMarker, getColor } from 'components/MapComponents/Layers/DtpFocus/helper';
import {
    Marker,
    PopUp,
    ToolTip,
    Cluster,
} from 'components/MapComponents/leaflet';
import CollectorForms from 'components/MapComponents/Layers/DtpFocus/CollectorForms';
import PopUpDtp from 'components/MapComponents/Layers/DtpFocus/PopUp';
import { getFilter } from 'components/MapComponents/Layers/DtpFocus/helper';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { iconCreateFunctionSimple } from 'components/MapComponents/helpers/iconCreateFunction';

const Layer = (props) => {
    const {
        map,
        readOnly = false,
        maxClusterRadius = 50
    } = props;
    const dispatch = useDispatch();

    const polygon = useSelector(focusIncidentsSelectors.polygon);
    const active = useSelector(focusIncidentsSelectors.active);
    const saved = useSelector(focusIncidentsSelectors.saved);
    const filters = useSelector(focusIncidentsSelectors.heatMapFilter);

    const polygonLoading = useSelector(focusIncidentsSelectors.polygonLoading);
    const polygonVisible = useSelector(focusIncidentsSelectors.polygonVisible);

    const [isPopUp, setIsPopUp] = useState(false);
    const [isFirst, setIsFirst] = useState(true);

    const prevFilters = usePrevious(filters);
    const filter = getFilters(filters, getFilter);

    const previewId = useRef(0);

    const activeId = Object.keys(active || {}).length > 0 ? active.id : 0;

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadPolygon(
            polygon,
            {
                ...filter,
                with_dtp_list: 1
            }
        ));
    };

    // активный элемент есть в полигоне
    const issetActive = polygonVisible.findIndex(({ id }) => id === active?.id) >= 0;

    useEffect(() => {
        if (
            (
                isPopUp === false
                || (Object.keys(active).length > 0 && issetActive === false && isFirst)
            )
            && polygon.length > 0
            && polygonLoading === false
        ) {
            if (Object.keys(active).length > 0) setIsFirst(false);
            dispatch(actions.setPolygonVisible(polygon));
        }
    }, [polygon, polygonLoading, isPopUp, active, isFirst, issetActive, dispatch]);

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();

    // добавить новый
    // const handleAdd = ({ lat, lng }) => {
    //     dispatch(actions.setEditForm({
    //         lat,
    //         lon: lng,
    //     }));
    // };

    // сдвинуться к маркеру
    const setMapToMarker = () => {
        if (Object.keys(active).length > 0) {
            const { lat, lon } = active;
            if (Math.abs(lat) && Math.abs(lon)) {
                setTimeout(() => {
                    map.setView([lat, lon]);
                }, 200);
            }
        }
    };

    const handlePUO = () => {
        setIsPopUp(true);
    };
    const handlePUC = () => {
        setIsPopUp(false);
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon)
            .on('popupopen', handlePUO)
            .on('popupclose', handlePUC);

        setMapToMarker();

        return () => {
            dispatch(actions.resetActive());
            dispatch(actions.clearPolygon());
            // map.closeContextMenu();
            //map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon)
                .off('popupopen', handlePUO)
                .off('popupclose', handlePUC);
        };
    }, [filters]);


    // добавление нового элемента с карты
    useEffect(() => {
        // map.on(config.mapContextMenu.event, (e) => {
        //     //map.fire('context_menu_close');
        //     handleAdd(e.latlng);
        // });

        return () => {
            // map.off(config.mapContextMenu.event);
            // dispatch(actions.setHeatMapFilter());
            dispatch(actions.setPolygonVisible());
            dispatch(actions.clearPolygon());
            setIsFirst(true);
            setIsPopUp(false);
        };
    }, []);

    useEffect(() => {
        if (saved) {
            map.closeContextMenu();
            // dispatch(actions.setEditForm());
            fetchPolygon();
        }
    }, [saved]);

    useEffect(() => {
        fetchPolygon();
    },[]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            previewId.current = active?.id;
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 15);
            }
        }
    }, [active]);

    // меню маркера, линии, полигона при клике
    // const RenderContextMenu = ({ item, ...rcmProp }) => {
    //     return (
    //         <div>
    //             <ContextMenuItem
    //                 {...rcmProp}
    //                 value="Редактировать"
    //                 onClick={() => {
    //                     map.fire('context_menu_close');
    //                     dispatch(actions.setEditForm(item));
    //                 }}
    //             />
    //             <ContextMenuItem
    //                 {...rcmProp}
    //                 value="Удалить"
    //                 onClick={() => {
    //                     map.fire('context_menu_close');
    //                     dispatch(actions.setDeleteForm(item.id));
    //                 }}
    //                 className="red"
    //             />
    //         </div>
    //     );
    // };

    const clickPopUp = (id) => () => {
        // if (previewId.current === id) {
        //     // dispatch(actions.resetActive());
        // }
    };

    const createItem = (item) => {
        const { id, lat, lon } = item;
        const componentProps = {
            ...props,
            key: `${config.slug}_${id}`,
            // для кластера
            attribution: {
                slug: config.slug,
                color: getColor(item)
            },
            onClick: (latlng) => {
                map.setView(latlng);
                // dispatch(actions.resetActive());
            },
        };
        const currentShow = activeId === id;

        const popUpProps = {
            show: currentShow,
            onClose: clickPopUp(id),
            // onClose: () => {
            //     if (currentShow) {
            //         dispatch(actions.resetActive());
            //     }
            // }
        };

        return (
            <Marker
                {...componentProps}
                latlng={[lat, lon]}
                icon={createIconMarker(item)}
            >
                <PopUp {...popUpProps} minWidth={800} onClose={() => {
                    dispatch(actions.resetActive());
                }}
                >
                    <PopUpDtp
                        uuid={id}
                        isReport
                        selectedItem={item}
                    />
                </PopUp>

                <ToolTip
                    direction="top"
                    offset={[0, -35]}
                >
                    <div>{item.address_text || `${lat} / ${lon}`}</div>
                </ToolTip>

                {/*{readOnly === false*/}
                {/*&& (*/}
                {/*    <ContextMenu*/}
                {/*        //minWidth={155}*/}
                {/*    >*/}
                {/*        <RenderContextMenu item={item} />*/}
                {/*    </ContextMenu>*/}
                {/*)*/}
                {/*}*/}
            </Marker>
        );
    };

    // делим на очаги и предочаги
    // 1: "Предочаг", 2: "Очаг"
    const {
        1: pfocus,
        2: focus,
    } = useMemo(() => {
        return polygonVisible.reduce((res, item, i) => {
            // const index = i % 2 === 0 ? 0 : 1;
            // какой параметр для очага/предочага
            const index = item['type'] || 1;
            return {
                ...res,
                [index]: [
                    ...(res[index] || []),
                    createItem(item),
                ],
            };
        }, {});
    }, [polygonVisible, readOnly]);

    return (
        <>
            <Cluster
                {...props}
                iconCreateFunction={iconCreateFunctionSimple}
                maxClusterRadius={maxClusterRadius}
            >
                {focus}
            </Cluster>

            <Cluster
                {...props}
                iconCreateFunction={iconCreateFunctionSimple}
                maxClusterRadius={maxClusterRadius}
            >
                {pfocus}
            </Cluster>

            {/* формы */}
            <CollectorForms />
        </>
    );
};

export default Layer;
