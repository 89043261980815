import iconCluster from './icons/iconCluster';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    name: 'Справочник АИП',
    slug: 'aip',
    mapContextMenu: false,
    mapMarkersColors: { // от статуса маркера
        1: '#51a351',
        2: '#ad1818',
        3: '#d4a323'
    },
    clusterIcon: iconCluster,
    layerIcon: 'fas fa-battery-full'
};