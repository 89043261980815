import { Autocomplete, TextField, Checkbox, FormControlLabel } from '@mui/material';

import titles from 'helpers/constants/titles';
import DateRange from 'components/common/Dates/DateRange';
import SelectTransportIncidentsTypes from 'components/common/Autocomplete/DorisReact/TransportIncidentsTypes';
import SelectThreadLevel from 'components/common/Autocomplete/DorisReact/SelectThreadLevel';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import AddressList from 'components/common/Location/AddressList';

const Filter = ({ initialFilter, filter, setFilter, onSearch, statuses }) => {
    const onChange = (key, value) => {
        setFilter(prev => ({
            ...prev,
            [key]: value ? value : ''
        }));
    };

    const resetFilter = (needRefresh) => {
        setFilter(initialFilter);
        if (needRefresh) {
            onSearch(initialFilter);
        }
    };

    const updateFilter = (filters) => {
        const newFilter = { ...initialFilter, ...filters };
        setFilter(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };

    return (
        <LayoutFilter
            filter={filter}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            filterException={['start_date', 'end_date']}
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(value) => onChange('start_date', value)}
                    handleEndDate={(value) => onChange('end_date', value)}
                    valueStartDate={filter.start_date}
                    valueEndDate={filter.end_date}
                />
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={filter.status || null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={statuses}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => onChange('status', value)}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.STATUS}
                        />
                    )}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <SelectTransportIncidentsTypes
                    label={titles.TYPE}
                    selected={filter.type_ti}
                    onChange={(value) => onChange('type_ti', value)}
                />
                <SelectThreadLevel
                    selected={filter.threat_level}
                    onChange={(value) => onChange('threat_level', value)}
                />
                <AddressList
                    className={''}
                    address={filter?.address}
                    address_text={filter?.address}
                    onChange={(value) => onChange('address', value?.address_text)}
                />
                <FormControlLabel
                    checked={filter.overdue}
                    onChange={(e, checked) => onChange('overdue', checked)}
                    control={<Checkbox />}
                    label="Просроченные"
                    labelPlacement="end"
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;