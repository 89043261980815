import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import renderChild from './helper/renderChild';

// todo проверить
const FeatureGroup = ({
    map,
    parent,
    children,
    onBounds,
    mooveMapAfter = false,
    onAdd = null,
    ...prop
}) => {
    const [area, setArea] = useState(null);

    const handleAdd = (e) => {
        const bounds = e?.layer?.getBounds?.() || null;
        if (bounds && bounds?.isValid?.()) onAdd(bounds);
    };

    useEffect(() => {
        if (area) {
            area.clearLayers();
            map.removeLayer(area);
        }

        const featureGroup = new L.FeatureGroup();
        map.addLayer(featureGroup);
        setArea(featureGroup);

        onAdd && featureGroup.on('layeradd ', handleAdd);

        return () => {
            featureGroup.clearLayers();
            map.removeLayer(featureGroup);
            onAdd && featureGroup.off('layeradd ', handleAdd);
            setArea(null);
        };
    }, [parent]);

    useEffect(() => {
        if (onBounds && area && children) {
            onBounds(area.getBounds());
            // console.log('123', area.toGeoJSON());
        }
        if (mooveMapAfter && area && children) {
            area.getBounds?.()?.isValid?.() && map.fitBounds(area.getBounds());
        }
    }, [children]);

    return area
        ? renderChild(
            children,
            {
                ...prop,
                map,
                parent: area
            }
        )
        : null;
};

export default FeatureGroup;
