import Infrastructure from 'components/pages/Dictionaries/RoadNetwork/Infrastructure';
import InfrastructureTypes from 'components/pages/Dictionaries/RoadNetwork/InfrastructureTypes';
import RoadSections from 'components/pages/Dictionaries/RoadNetwork/RoadSections';
import Commutator from 'components/pages/Dictionaries/RoadNetwork/Commutator';
import ConnectionLine from 'components/pages/Dictionaries/RoadNetwork/ConnectionLine';
import RoadNetworkVideoCamera from 'components/pages/Dictionaries/RoadNetwork/VideoCamera';
import Pillars from 'components/pages/Dictionaries/RoadNetwork/Pillars';
import ElectricalOpticalSystems from 'components/pages/Dictionaries/RoadNetwork/ElectricalOpticalSystems';
import PakVendor from 'components/pages/Dictionaries/RoadNetwork/PakVendor';
import PakModel from 'components/pages/Dictionaries/RoadNetwork/PakModel';
import PAK from 'components/pages/Dictionaries/RoadNetwork/PAK';
import DVR from 'components/pages/Dictionaries/RoadNetwork/DVR';
import ConnectionPoint from 'components/pages/Dictionaries/RoadNetwork/ConnectionPoint';

const routes = [
    {
        path: '/dictionaries/roadnetwork/infrastructure',
        component: Infrastructure,
        exact: true
    },
    {
        path: '/dictionaries/roadnetwork/infrastructure-types',
        component: InfrastructureTypes,
        exact: true
    },
    {
        path: '/dictionaries/roadnetwork/road-sections',
        component: RoadSections,
        exact: true
    },
    {
        path: '/dictionaries/roadnetwork/commutator',
        component: Commutator,
    },
    {
        path: '/dictionaries/roadnetwork/connection-line',
        component: ConnectionLine,
        exact: true
    },
    {
        path: '/dictionaries/roadnetwork/video-cameras',
        component: RoadNetworkVideoCamera,
        exact: true
    },
    {
        path: '/dictionaries/roadnetwork/connection-point',
        component: ConnectionPoint,
        exact: true
    },
    {
        path: '/dictionaries/roadnetwork/pillars',
        component: Pillars,
        exact: true
    },
    {
        path: '/dictionaries/roadnetwork/electrical-optical-systems',
        component: ElectricalOpticalSystems,
        exact: true
    },
    {
        path: '/dictionaries/roadnetwork/pak-vendor',
        component: PakVendor,
        exact: true
    },
    {
        path: '/dictionaries/roadnetwork/pak-model',
        component: PakModel,
        exact: true
    },
    {
        path: '/dictionaries/roadnetwork/pak',
        component: PAK,
        exact: true
    },
    {
        path: '/dictionaries/roadnetwork/dvr',
        component: DVR,
        exact: true
    },
];

export default routes;
