import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadReportByVehicles } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import messages from 'helpers/constants/messages';
import { dateWithDashYYYYMMDD, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import titles from 'helpers/constants/titles';
import { PageReportBlock, PageReportModal } from 'components/common/PageReport';

import Filter from './Filter';
import Item from './Item';

const WorkordersByVehicles = () => {
    const dispatch = useDispatch();

    const reportByVehicles = useSelector(transportPassengerSelectors.reportByVehicles);
    const loadingReportByVehicles = useSelector(transportPassengerSelectors.loadingReportByVehicles);

    const initialState = {
        start_date: getStartOf('month'),
        end_date: getEndOf('month'),
        vehicle_id_list : [],
    };

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [page, setPage] = useState(1);
    const [fields, setFields] = useState(initialState);

    const getFilter = (data) => {
        const newFilter = {
            ...data,
            start_date: dateWithDashYYYYMMDD(data.start_date),
            end_date: dateWithDashYYYYMMDD(data.end_date),
            vehicle_id_list: data.vehicle_id_list?.map(({ id }) => id) || null,
        };

        return removeEmptyFields(newFilter);
    };

    useEffect(() => {
        handleAccess();
    }, [page, limit]);

    const handleGetReport = (formats) => {
        const filters = getFilter(fields);
        dispatch(loadReportByVehicles(page, limit, { ...filters, report: 1, formats }));
    };

    const handleAccess = (data = fields) => {
        const filters = getFilter(data);
        // setPage(1);
        dispatch(loadReportByVehicles(page, limit, filters));
    };

    const renderContent = () => (
        reportByVehicles.data?.length > 0 
            ? <LSContainer
                headers={[
                    { title: titles.GRZ, width: '10%' },
                    { title: titles.NUM_ROUTE, width: '22%' },
                    { title: titles.WORK_ORDER_BY_TRAFFIC_SCHEDULE, width: '20%' },
                    { title: titles.FLIGHT, width: '8%' },
                    { title: titles.DATE, width: '10%' },
                    { title: titles.START_TIME, width: '12%' },
                    { title: titles.END_TIME, width: '12%' },
                    { title: titles.MILEAGE, width: '6%' },
                ]}
            >
                {reportByVehicles.data.map((item, index) => (
                    <Item key={index} item={item} />
                ))}
            </LSContainer>
            : !loadingReportByVehicles && <div>{messages.DATA_IS_NOT_FOUND}</div>
    );
   
    return (
        <>
            <PageLayout
                header="Отчёт по пробегу ТС"
                filters={<Filter
                    initialData={initialState}
                    onAccept={handleAccess}
                    fields={fields}
                    setFields={setFields}
                />}
                informPanelProps={{
                    buttons: (
                        <PageReportModal>
                            <PageReportBlock filter={getFilter(fields)}/>
                        </PageReportModal>
                    ),
                    total: reportByVehicles?.meta?.total
                }}
                paginationProps={{
                    loadList: (page) => setPage(page),
                    list: reportByVehicles?.meta,
                    limit,
                    setLimit,
                }}
                content={renderContent} 
                loading={loadingReportByVehicles}
            />
        </>
    );
};

export default WorkordersByVehicles;