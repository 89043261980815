import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { isEqual } from 'lodash';

import Loading from 'components/common/Loading';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import getFilters from 'components/MapComponents/helpers/getFilters';
import messages from 'helpers/constants/messages';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import { usePrevious, useStoreProp } from 'helpers/hooks';
import useHistory from 'helpers/hooks/roadWorksTransport/useHistory';
import * as actions from 'redux/TransportRoad/actions';
// import { loadVehicleCategories } from 'redux/RoadWorks/actions';
import { transportRoadSelectors } from 'redux/TransportRoad';
import { getFilter } from '../helper';
import Item from './Item';
import config from '../config';
import Form from './Filter/Form';
import './style.scss';


const SideBar = (props) => {
    const dispatch = useDispatch();

    const active = useSelector(transportRoadSelectors.active);
    const filters = useSelector(transportRoadSelectors.filters);
    const list = useSelector(transportRoadSelectors.sidebar);
    const loading = useSelector(transportRoadSelectors.sidebarLoading);
    const allVehicleLoading = useSelector(transportRoadSelectors.allVehicleLoading);
    const sidebarParams = useSelector(transportRoadSelectors.sidebarParams);

    useEffect(() => {
        return () => {
            dispatch(actions.setSidebarParams({
                ...sidebarParams,
                page: 1
            }));
        };
    }, []);

    // const prevCoordsLoading = usePrevious(coordsLoading);
    // const prevAllVehicleLoading = usePrevious(allVehicleLoadin

    const categoryTS = useStoreProp(
        actions.loadCategoriesVehicle,
        'transportRoad',
        'categoriesVehicle'
    );

    const scrollRef = useRef(null);
    const prevFilters = usePrevious(filters);
    const prevActive = usePrevious(active);
    const [loadingItem, setLoadingItem] = useState(null);
    // const [page, setPage] = useState(1);
    // const limit = 10;

    const scroolTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    // хук по истории для спецтранспорта
    const history = useHistory();

    // const fetchCoords = () => dispatch(actions.loadCoords());

    // типы спецтранспорта
    const types = useStoreProp(actions.loadTypes, 'transportRoad', 'types');

    // показать все
    // useEffect(() => {
    //     if (
    //         allVehicleLoading === false
    //         && prevAllVehicleLoading === true
    //     ) {
    //         const { currentItem } = allVehicle;
    //
    //         const responseList = allVehicle.map(({ vehicle, ...item }) => ({
    //             ...vehicle,
    //             ...item,
    //             lock: true,
    //             date_history_start: null,
    //             date_history_end: null
    //         })) || [];
    //
    //         dispatch(actions.setActive(responseList));
    //     }
    // },[allVehicleLoading, prevAllVehicleLoading]);

    // обработка получения координат
    // useEffect(() => {
    //     if (
    //         coordsLoading === false
    //         && prevCoordsLoading === true
    //         && loadingItem
    //     ) {
    //         const { currentItem } = coords;
    //
    //         dispatch(actions.setActive([
    //             ...getLockActive(),
    //             {
    //                 ...loadingItem,
    //                 ...currentItem
    //             }
    //         ]));
    //         setLoadingItem(null);
    //     }
    // },[coordsLoading, prevCoordsLoading, loadingItem]);

    // обработка истории по транспорту
    useEffect(() => {
        if (history.isLoaded) {

            if (active?.id === loadingItem?.id) {
                const coords = history?.data?.reduce(
                    (res, { lat, lon, speed, color, created_at }) => [...res, { lat, lon, speed, color, created_at }],
                    []
                );
                coords.length && dispatch(actions.setRoutesVehicle(history.id, coords));
            }

            setLoadingItem(null);
        }
    },[history.isLoaded]);

    useEffect(() => {
        if (!isEqual(active, prevActive)) {
            dispatch(actions.clearRoutes());
            setLoadingItem(null);
        }
    }, [active]);

    const handleScrollUpdate = (values) => {
        const {
            //current_page = 0,
            last_page = 0
        } = list?.meta || {};
        if (
            values.top > 0.98
            && loading === false
            && sidebarParams.page < last_page
        ) {
            // setPage(page + 1);
            dispatch(actions.setSidebarParams({
                page: sidebarParams.page + 1
            }));
        }
    };

    const fetchList = () => {
        dispatch(actions.loadSidebar(
            sidebarParams.page,
            sidebarParams.limit,
            getFilters(filters, getFilter)
        ));
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            scroolTop();
            // setPage(1);
            dispatch(actions.clearActive());
            dispatch(actions.setSidebarParams({
                page: 1
            }));
        } else {
            fetchList();
        }
    }, [sidebarParams, filters, dispatch]);

    // отслеживаем изменение проекта
    useEffect(() => {
        //fetchList();

        return () => {
            dispatch(actions.clearSidebar());
            dispatch(actions.clearActive());
            dispatch(actions.clearRoutes());
        };
    }, []);

    // применить фильтр
    const handleFilterChange = (newFilter) => {
        dispatch(actions.setFilters(newFilter));
    };

    // сбросить фильтр
    const handleFilterReset = () => {
        dispatch(actions.clearFilters());
        dispatch(actions.setSidebarParams({
            page: 1
        }));
    };


    // вывести фильтр
    const renderFilter = () => (
        <div className="border-bottom flex">
            <SidebarFilter
                filters={filters}
                onSetFilter={handleFilterChange}
                onClearFilter={handleFilterReset}
                content={(props) => <Form {...props} /*data={data} onChange={onChange} types={types}*/ />}
                className=""
                layer={config.slug}
            />

            {/*<FormButtons*/}
            {/*    buttons={buttonsFilters}*/}
            {/*/>*/}
        </div>
    );

    const getLockActive = () => active.reduce(
        (res, el) => (el.lock ? [...res, { ...el }] : res),
        []
    );

    const handleClick = (item, lock = false) => {
        if (active?.id === item?.id) {
            dispatch(actions.clearActive());
        } else {
            dispatch(actions.setActive(item));
        }

        /*
        const { id } = item;
        const routeIndex = active.findIndex((el) => el.id === id);

        if (routeIndex === -1) {
            const uid = item?.characteristics?.uid || 0;

            if (uid) {
                // подгрузка
                setLoadingItem({
                    ...item,
                    lock, // блокируем показ
                    date_history_start: null, // дата истории начало
                    date_history_end: null, // дата истории конец
                });
                // setLoadingId(item.characteristics.uid);

                dispatch(actions.loadCoords([uid]));
            }

        } else {
            dispatch(actions.setActive([...getLockActive()]));
        }
        */
    };

    // const lockItem = (item) => {
    //     const { uuid } = item;
    //     const { selectedVehicles } = this.props.vehicles;
    //     const routeIndex = selectedVehicles.findIndex((el) => el.uuid === uuid);
    //     if (routeIndex >= 0) {
    //         dispatch(actions.setActive([
    //             ...selectedVehicles.slice(0, routeIndex),
    //             {
    //                 ...selectedVehicles[routeIndex],
    //                 lock: true
    //             },
    //             ...selectedVehicles.slice(routeIndex + 1)
    //         ]));
    //     } else {
    //         handleClick(item, true);
    //     }
    // };
    //
    // const unlockItem = (item) => {
    //     const { uuid } = item;
    //     const { selectedVehicles } = this.props.vehicles;
    //     const routeIndex = selectedVehicles.findIndex((el) => el.uuid === uuid);
    //     if (routeIndex >= 0) {
    //         dispatch(actions.setActive([
    //             ...selectedVehicles.slice(0, routeIndex),
    //             {
    //                 ...selectedVehicles[routeIndex],
    //                 lock: false
    //             },
    //             ...selectedVehicles.slice(routeIndex + 1)
    //         ]));
    //     }
    // };

    // дата истории
    const handleChangeDate = (item, start, end) => {
        if (item?.characteristics?.serial_egts && start && end) {
            setLoadingItem(item);
            history(
                item?.characteristics?.serial_egts,
                {
                    date_start: start ? fullDateTimeWithTimeZone(new Date(start)) : '',
                    date_end: end ? fullDateTimeWithTimeZone(new Date(end)) : ''
                }
            );
        } else {
            dispatch(actions.clearRoutes());
        }

    };
    const currentActive = active?.id || null;

    const renderList = (dataList) => (
        <div className="transport-special">
            {dataList.map((item) => {
                // const currentActive = active.find(({ id }) => id === item.id) || null;
                return (
                    <Item
                        active={currentActive}
                        key={item.id}
                        data={item}
                        onClick={handleClick}
                        // onLock={lockItem}
                        // onUnLock={unlockItem}
                        loading={loadingItem?.id === item.id}
                        types={types}
                        categoryTS={categoryTS}
                        onChangeDate={(start, end) => handleChangeDate(item, start, end)}
                    />
                );
            })}
        </div>
    );

    return (
        <div className="layers-sidebar__flex-column">
            {renderFilter()}

            {loading && Object.keys(list?.data || {}).length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                    ref={scrollRef}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={config.name}
                            list={list}
                        />
                        {Object.keys(list?.data || {}).length == 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList(list?.data)}
                    </div>
                    {(loading || allVehicleLoading) && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
};

export default SideBar;
