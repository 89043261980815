import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { List } from '@mui/material';

import { notificationsSelectors } from 'redux/Notifications';
import Loading from 'components/common/Loading';
import { useNotifications } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import PageLayout from 'components/layout/PageLayout';

import Filter from './Filter';
import Item from './Item';

import type { ItemTypes } from './types';

const OperatorLog = () => {
    const microservice = 'doris-control';
    const notifications = useNotifications({ microservice });
    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);
    const [params, setParams] = useState({});
    const loading = useSelector(notificationsSelectors.loading);
    const serviceList = useSelector(notificationsSelectors.serviceList);
    const list = serviceList?.[microservice] || {};

    const fetchList = (page = 1, limit: number | undefined, params: any) => notifications.loadServiceList(page, limit, params);

    const renderContent = () => {
        return (<>
            {loading && <Loading circular={true} />}
            {list.data?.length > 0
                ? (<List className="list">
                    {list.data.map((item: ItemTypes) => <Item key={item.id} item={item} />)}
                </List>)
                : (!loading && <div style={{ marginTop: 20 }}>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>);
    };

    useEffect(() => {
        fetchList(1, limit, params);
    }, [limit, params]);

    return (
        <PageLayout
            header="Журнал оператора"
            filters={<Filter setParams={setParams} />}
            informPanelProps={{                  
                total: list?.meta?.total
            }}
            paginationProps={{
                loadList: (page, limit) => fetchList(page, limit, params),
                list: list?.meta || {},
                limit: limit,
                setLimit: setLimit,
            }}
            content={renderContent}
        />
    );
};

export default OperatorLog;
