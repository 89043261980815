import React, { Fragment } from 'react';
import { Marker, PolyLine, ToolTip } from '../../../../../../../MapComponents/leaflet';
import { Point } from 'leaflet/dist/leaflet-src.esm';
import L from 'leaflet';
import cpvfCameraIcon from '../../../../../../../common/Icons/cpvfCameraIcon';
import { useValidation } from '../../../../../../../../helpers/hooks';
import MapDragMarker from '../../../../../../../common/Location/MapDragMarker';
import { FormControl } from '@mui/material';
import LatLonCoordinates from '../../../../../../../common/Location/LatLonCoordinates';

const Map = ({ data, setData }) => {
    const validation = useValidation();

    const onChangeDirectionArray = (dataValue) => {
        // градус отклонения один для всех направлений,
        // поэтому мы изменяем его один раз в инпуте но меняем во всех направлениях в массиве
        const { name, value } = dataValue;
        const currentValue = Math.min(Math.max(0, parseInt(value)), 360);

        setData({ 
            ...data, 
            direction: data.direction.map((el, index) => {
                return {
                    ...el,
                    [name]: currentValue,
                };
            }),
        });

        validation.deleteKey(name);
    };

    const getCircleCoords = (lat, lon, angle = 0, radius ) => {
        const rad = parseFloat(angle) * Math.PI / 180;
        const newLat = parseFloat(lat) + radius * .62 * Math.cos(rad);
        const newLon = parseFloat(lon) + radius * Math.sin(rad);
        return [newLat, newLon];
    };

    const editIcon = L.divIcon({
        html: '<div class="wrap"><div class="edit"></div></div>',
        className: 'cpvf-camera-icon',
        iconSize: [20, 20],
        iconAnchor: [10, 10]
    });

    const cameraIcon = (direction) => L.divIcon({
        html: `
            <div class="wrap">
                <div class="circle">
                    <i class="icon">${cpvfCameraIcon({ angleRotate: direction })}</i>
                </div>
            </div>
        `,
        className: 'cpvf-camera-icon',
        iconSize: [20, 20],
        iconAnchor: [10, 10]
    });

    const degreeData = data?.direction?.[0] || {};

    return (
        <>
            <FormControl className="block" variant="outlined">
                <LatLonCoordinates
                    {...data}
                    onChange={(params) => setData({ ...data, ...params })}
                    required
                />
            </FormControl>
            <MapDragMarker
                {...data}
                required
                {...((parseInt(data.entity_id) === 4 || parseInt(data.entity_id) === 5) 
                    ? {
                        icon: cameraIcon([degreeData.degree])
                    } 
                    : {})
                }
                offsetToolTip={[0, -10]}
                // showAddress={false}
                permanent={false}
                onChange={(params) => setData({ ...data, ...params })}
                dependence={false}
                innerComponentEvent={'zoomend'}
                innerComponent={(props) => {
                    if (
                        data.hasOwnProperty('lines')
                                && data.lat
                                && data.lon
                    ) {
                        // return data.direction.map((item, index) => {
                        // зависимость
                        const scale = Math.pow(2, props.map.getZoom());
                        const radius = 100 / scale;
                        const editPoint = getCircleCoords(data.lat, data.lon, degreeData.degree || 0, radius);
                        return (
                            <>
                                <Marker
                                    {...props}
                                    latlng={editPoint}
                                    isBlockEventMapOnDrag
                                    onDragEnd={(newLatLng) => {
                                        const { lat, lon } = newLatLng;

                                        // вектора
                                        const q2 = new Point((lat - parseFloat(data.lat)) * 1.55, lon - parseFloat(data.lon));
                                        const q1 = new Point(1, 0);

                                        // вычисляем функцию
                                        const f = (
                                            (q1.x * q2.x + q1.y * q2.y) / (
                                                Math.sqrt(q1.x * q1.x + q1.y * q1.y) * Math.sqrt(q2.x * q2.x + q2.y * q2.y)
                                            )
                                        );

                                        // радианы в градусы
                                        let angle = Math.acos(f) * 180 / Math.PI;

                                        // если угол другой
                                        if (lon < data.lon && angle < 180) {
                                            angle = 360 - angle;
                                        }

                                        onChangeDirectionArray({ name: 'degree', value: parseInt(angle) });
                                    }}
                                    icon={editIcon}
                                >
                                    <ToolTip>
                                        <div>
                                            {degreeData.degree}
                                        </div>
                                    </ToolTip>
                                </Marker>
                                <PolyLine
                                    {...props}
                                    coordinates={[[data.lat, data.lon], editPoint]}
                                    style={{
                                        color: '#00442C',
                                        weight: 3,
                                        // smoothFactor: 1,
                                        dashArray: '5,5'
                                    }}
                                />
                            </>
                        );
                        // });
                    } else return null;
                }}
            />
        </>
    );
};

export default Map;
