import {
    useState,
    useRef,
    ReactElement,
    ReactNode,
    SyntheticEvent,
    forwardRef,
    useImperativeHandle,
    CSSProperties
} from 'react';
import { AppBar, Card } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';

import { CustomTabs, CustomTab } from 'components/common/Tabs';

import styles from './PassportTabs.module.css';

interface Tab {
    value: string;
    label: string;
    icon: ReactElement;
    contentComponent: ReactNode;
}

interface Props {
    tabs: Tab[],
    initialTab?: string; // по-умолчанию откроется первый таб из массива tabs, нужно иначе
    appBarStyle?: CSSProperties; // дополнительный проп для стилей AppBar
}

export interface PassportTabsRef {
    changeTab: (tab: string) => void;
}

const PassportTabs = forwardRef<PassportTabsRef, Props>((props, ref) => {
    const { tabs, initialTab, appBarStyle } = props;
    const [selectedTab, setSelectedTab] = useState(initialTab || tabs[0].value);
    const scrollRef = useRef<Scrollbars>(null);

    // Добавляем возможность сменить таб вручную, из родительского компонента, для редких случаев, когда это делается не с помощью клика по табу
    useImperativeHandle(ref, () => ({
        changeTab: tab => {
            setSelectedTab(tab);
            scrollRef.current?.scrollTop(0);
        }
    }), []);

    const handleChange = (_: SyntheticEvent, tab: string) => {
        setSelectedTab(tab);
        scrollRef.current?.scrollTop(0);
    };

    const currentTab = tabs.find(tab => tab.value === selectedTab);
    const contentComponent = currentTab?.contentComponent;

    return (
        <Card variant="elevation" className={styles.cardStyle}>
            <AppBar position="sticky" color="default" style={appBarStyle || {}}>
                <CustomTabs
                    value={selectedTab}
                    onChange={handleChange}
                    textColor="primary"
                    variant="scrollable"
                >
                    {tabs.map(({ value, label, icon }) => (
                        <CustomTab
                            key={value}
                            iconPosition="start"
                            id={`scrollable-force-tab-${value}`}
                            aria-controls={`scrollable-force-tabpanel-${value}`}
                            value={value}
                            label={label}
                            icon={icon}
                        />
                    ))}
                </CustomTabs>
            </AppBar>
            <div className={styles.scrollbarsContainer}>
                <Scrollbars className={styles.scrollbars} ref={scrollRef}>
                    <div
                        role="tabpanel"
                        id={`scrollable-force-tabpanel-${selectedTab}`}
                        aria-labelledby={`scrollable-force-tab-${selectedTab}`}
                        className="tab__container"
                    >
                        {contentComponent}
                    </div>
                </Scrollbars>
            </div>
        </Card>
    );
});

export default PassportTabs;
//Todo: Убрать у ContentComponent`s контейнеры с с margins
