import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    FormControl,
    TextField,
} from '@mui/material';

import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import { editBoard } from 'redux/Boards/actions';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SelectBoardFonts from 'components/common/Autocomplete/Boards/Fonts';

function BoardSettings({ data, reloadList }) {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialValues = useMemo(() => {
        return {
            height: data?.height || '',
            width: data?.width || '',
            fonts: data?.fonts || [],
            ip: data?.ip || '',
            port: data?.port || '',
            serial_number: data?.serial_number || '',
        };
    }, [data]);

    const [formValues, setFormValues] = useState(initialValues);

    const onChangeFonts = (value) => {
        setFormValues({
            ...formValues,
            fonts: value,
            font_default: value[0]
        });
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === 'height' || name === 'width') {
            setFormValues({
                ...formValues,
                [name]: value < 0 ? 0 : value
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value
            });
        }
        validation.deleteKey(name);
    };

    useEffect(() => {
        setFormValues(initialValues);
    }, [initialValues]);

    const onSave = () => dispatch(editBoard(data.id, { ...data, ...formValues }, reloadList));

    return (
        <div  className="tab__container">

            <form className="form">
                <div className="block">
                    <SelectBoardFonts
                        multiple
                        required
                        onChange={onChangeFonts}
                        selected={formValues.fonts}
                        filter={{ board_type: data.type }}
                    />
                </div>
                <FormControl required className="row" size="small" variant="outlined">
                    <TextField
                        className="row__item"
                        label="Ширина (пиксели)"
                        size="small"
                        value={formValues.width}
                        variant="outlined"
                        name="width"
                        type="number"
                        onChange={onChange}
                        required={true}
                        error={!formValues.width || validation.isKey('width')}
                        helperText={validation.get('width')}
                    />
                    <TextField
                        className="row__item"
                        label="Высота (пиксели)"
                        size="small"
                        value={formValues.height}
                        variant="outlined"
                        name="height"
                        type="number"
                        onChange={onChange}
                        required={true}
                        error={!formValues.height || validation.isKey('height')}
                        helperText={validation.get('height')}
                    />
                </FormControl>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label={titles.IP_ADDRESS}
                        variant="outlined"
                        size="small"
                        value={formValues.ip}
                        name="ip"
                        onChange={onChange}
                        type="text"
                    />
                </FormControl>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label="Порт"
                        variant="outlined"
                        size="small"
                        value={formValues.port}
                        name="port"
                        onChange={onChange}
                        type="text"
                    />
                </FormControl>
                <FormControl required className="block" size="small" variant="outlined">
                    <TextField
                        required
                        label="Серийный номер"
                        variant="outlined"
                        size="small"
                        value={formValues.serial_number}
                        name="serial_number"
                        onChange={onChange}
                        type="text"
                        error={!formValues.serial_number || !!validation.isKey('serial_number')}
                        helperText={validation.get('serial_number')}
                    />
                </FormControl>
                <div className="block">
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.cancel,
                                onClick: () => setFormValues(initialValues)
                            },
                            {
                                ...buttonsTypes.save,
                                onClick: onSave
                            }
                        ]}
                    />
                </div>
            </form>
        </div>
    );
}

export default BoardSettings;
