import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import L from 'leaflet';

import * as actions from 'modules/Metro/redux/actions';
import { metroSelectors } from 'modules/Metro';
import mapHelper from 'helpers/mapHelper';
import { GeoJsonNew } from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';

import config from './config';
import { createIconMarker, getFilter } from './helper';
import PopUp from './PopUp';

const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();

    const vestibulePolygon = useSelector(metroSelectors.vestibulePolygon);
    const active = useSelector(metroSelectors.active);
    const filters = useSelector(metroSelectors.filters);

    const fetchPolygon = () => {
        const filter = getFilters(filters, getFilter);
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadVestibulePolygon(polygon, filter));
    };

    const debounceFetchPolygon = debounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();

    useEffect(() => {
        fetchPolygon();
    }, [filters]);

    useEffect(() => () => {
        dispatch(actions.clearActive());
        dispatch(actions.clearVestibulePolygon());
        map.fire('context_menu_close');
    }, []);

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [handleFetchPolygon]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { geometry } = active;
            if (geometry && geometry?.coordinates.length > 0) {
                const b = L.geoJSON(geometry).getBounds();
                map.fitBounds(b);
            }
        }
    }, [active]);

    const vestibuleGeometry = useMemo(() => {
        return vestibulePolygon?.reduce((res, item) => {
            const {
                id,
                name,
                geometry = {},
                color_status,
            } = item;

            res.push({
                ...geometry,
                properties: {
                    ...geometry?.properties,
                    id,
                    name,
                    color: color_status,
                },
                attribution: {
                    color: color_status,
                },
                style: {
                    color: color_status,
                },
            });

            return res;
        }, []);
    }, [vestibulePolygon]);

    return (
        <GeoJsonNew
            {...props}
            data={vestibuleGeometry}
            toolTipTemplate={({ name = '' }) => <div>{name ?? ''}</div>}
            icon={item => createIconMarker(item.color)}
            idPrefix={config.slug}
            toolTipOptions={{
                direction: 'top',
                offset: [0, -36]
            }}
            popUpTemplate={({ id }) => <PopUp id={id} />}
        />
    );
};

export default Layer;
