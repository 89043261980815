import React, { useEffect, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import titles from '../../../../../../helpers/constants/titles';
import { useStoreProp, useValidation } from '../../../../../../helpers/hooks';
import * as actions from '../../../../../../redux/TransportPassenger/actions';
import TextMaskPhone from '../../../../../common/TextMaskPhone';


function Charterer({ data, onChange = () => {} }) {
    const [fields, setFields] = useState({
        ...data,
    });

    useEffect(() => {
        setFields(data);
    }, [data]);

    const validation = useValidation();

    const types = useStoreProp(actions.loadWoRequisiteChartererTypes, 'transportPassenger', 'requisiteChartererTypes');

    const handleChangeSelect = (event) => {
        const {
            name,
            value,
        } = event.target;

        const nv = {
            [name]: value,
        };

        setFields(nv);
        onChange(nv);
    };

    const handleChangeTF = (event) => {
        const {
            name,
            value,
        } = event.target;

        const nv = {
            ...fields,
            [name]: value,
        };

        setFields(nv);
        onChange(nv);
        validation.deleteKey(`charterer.${name}`);
    };

    // const handleChangeAddress = (address) => {
    //     const nv = {
    //         ...fields,
    //         address,
    //     };
    //
    //     setFields(nv);
    //     onChange(getValues(nv));
    // };

    return (
        <div>
            <h3>Фрахтователь</h3>

            <div className="block">
                <FormControl size="small" variant="outlined">
                    <InputLabel>{titles.TYPE}</InputLabel>
                    <Select
                        value={parseInt(fields?.type_id) || ''}
                        onChange={handleChangeSelect}
                        name="type_id"
                        label={titles.TYPE}
                    >
                        <MenuItem value="">Не выбрано</MenuItem>
                        {Object.keys(types).map((key) => (
                            <MenuItem
                                key={`type_item_${key}`}
                                value={key}
                            >
                                {types[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            {parseInt(fields?.type_id) === 2 && (
                <>
                    <div className="row">
                        <TextField
                            className="row__item"
                            value={fields?.last_name || ''}
                            variant="outlined"
                            size="small"
                            label="Фамилия"
                            name="last_name"
                            onChange={handleChangeTF}
                            error={validation.isKey('charterer.last_name')}
                            helperText={validation.get('charterer.last_name')}
                        />
                        <TextField
                            className="row__item"
                            value={fields?.name || ''}
                            variant="outlined"
                            size="small"
                            label="Имя"
                            name="name"
                            onChange={handleChangeTF}
                            error={validation.isKey('charterer.name')}
                            helperText={validation.get('charterer.name')}
                        />
                    </div>
                    <div className="row">
                        <TextField
                            className="row__item"
                            value={fields?.patronymic || ''}
                            variant="outlined"
                            size="small"
                            label="Отчество"
                            name="patronymic"
                            onChange={handleChangeTF}
                            error={validation.isKey('charterer.patronymic')}
                            helperText={validation.get('charterer.patronymic')}
                        />
                        <TextField
                            className="row__item"
                            value={fields?.phone || ''}
                            variant="outlined"
                            size="small"
                            label={titles.PHONE_NUMBER}
                            name="phone"
                            onChange={handleChangeTF}
                            error={validation.isKey('charterer.phone')}
                            helperText={validation.get('charterer.phone')}
                            InputProps={{
                                inputComponent: TextMaskPhone,
                            }}
                        />
                    </div>

                    <div className="block">
                        <TextField
                            className="row__item"
                            value={fields?.address || ''}
                            variant="outlined"
                            size="small"
                            label="Полный адрес"
                            name="address"
                            onChange={handleChangeTF}
                            error={validation.isKey('charterer.address')}
                            helperText={validation.get('charterer.address')}
                        />
                    </div>
                </>
            )}

            {parseInt(fields?.type_id) === 1 && (
                <>
                    <div className="block">
                        <TextField
                            className="row__item"
                            value={fields?.name || ''}
                            variant="outlined"
                            size="small"
                            label="Полное название"
                            name="name"
                            onChange={handleChangeTF}
                            error={validation.isKey('charterer.name')}
                            helperText={validation.get('charterer.name')}
                        />
                    </div>
                    <div className="block">
                        <TextField
                            className="row__item"
                            value={fields?.phone || ''}
                            variant="outlined"
                            size="small"
                            label={titles.PHONE_NUMBER}
                            name="phone"
                            onChange={handleChangeTF}
                            error={validation.isKey('charterer.phone')}
                            helperText={validation.get('charterer.phone')}
                            InputProps={{
                                inputComponent: TextMaskPhone,
                            }}
                        />
                    </div>

                    <div className="block">
                        <TextField
                            className="row__item"
                            value={fields?.address || ''}
                            variant="outlined"
                            size="small"
                            label="Полный адрес"
                            name="address"
                            onChange={handleChangeTF}
                            error={validation.isKey('charterer.address')}
                            helperText={validation.get('charterer.address')}
                        />
                    </div>

                </>
            )}

        </div>
    );
}

export default Charterer;
