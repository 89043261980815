import { useState } from 'react';
import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useDispatch } from 'react-redux';
import { editWorkCalendar } from '../../../../../redux/TransportPassenger/actions';
import { getDateToFormat } from '../../../../../helpers/date.config';

const ModalForm = ({ isOpen, onClose, item = {}, loadCalendar }) => {
    const dispatch = useDispatch();

    const [data, setData] = useState(item);

    const handleChangeRadio = (e) => {
        const { value, name } = e.target;
        setData({ ...data, [name]: Number(value) });
    };

    const handleSave = () => {
        const callback = () => {
            onClose();
            loadCalendar();
        };
        dispatch(editWorkCalendar(data, callback));
    };

    const getTitle = () => {
        const { day, year, month } = item;
        return getDateToFormat(new Date(Date.UTC(year, month - 1, day)), 'd MMMM yyyy');
    };

    const isDisabled = item?.type_id === data.type_id;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={getTitle()}
            noPadding
            small
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <FormControl className="block">
                    <RadioGroup
                        onChange={handleChangeRadio}
                        value={data.type_id}
                        name="type_id"
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Рабочий день" />
                        <FormControlLabel value="2" control={<Radio />} label="Выходной день" />
                    </RadioGroup>
                </FormControl>
            </form>
        </Modal>
    );
};

export default ModalForm;
