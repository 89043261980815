import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import * as actions from 'redux/TrafficFlow/actions';
import { loadPuidTFIndicators, loadPuidTFIndicatorsConsolidate } from 'redux/Reports/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import PageLayout from 'components/layout/PageLayout';
import ReportBlock from 'components/common/Report/ReportBlock';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { fullDateTimeWithTimeZone, getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import messages from 'helpers/constants/messages';
import Filters from './Filters';
import Item from './Item';


const Indicators = () => {
    const dispatch = useDispatch();
    const reportIndicators = useSelector(trafficFlowSelectors.reportIndicators);
    const loadingReportIndicators = useSelector(trafficFlowSelectors.loadingReportIndicators);

    const initialState = {
        id_list: [],
        start_date: getStartOf('day', getDateWithDuration({ days: -2 })),
        end_date: getEndOf('day'),
        puid_type: '',
        is_consolidate: false,
    };

    const [isPrintOpen, setOpenPrint] = useState(false);
    const [formValues, setFormValues] = useState(initialState);
    const [isRequest, setIsRequest] = useState(false);

    const paramsWithoutEmpty = (filter = formValues) => {
        const result = {
            start_date: fullDateTimeWithTimeZone(filter.start_date),
            end_date: fullDateTimeWithTimeZone(filter.end_date),
            id_list: filter?.id_list?.map(({ id }) => id) || [],
            is_consolidate: filter?.is_consolidate || false,
        };

        return removeEmptyFields(result);
    };

    const handleFilter = (filter) => {
        const result = paramsWithoutEmpty(filter);
        setIsRequest(true);
        dispatch(actions.loadReportIndicators(result));
    };

    const disableRequestBtn = !formValues.start_date
    || !formValues.end_date;

    const handleReport = (formats) => {
        const result = {
            ...paramsWithoutEmpty(),
            formats,
        };

        if (formValues?.is_consolidate) {
            dispatch(loadPuidTFIndicatorsConsolidate({
                ...result,
                report: 5
            }));
        } else {
            dispatch(loadPuidTFIndicators({
                ...result,
                report: 6
            }));
        }
    };

    useEffect(() => {
        return () => {
            dispatch(actions.clearReportIndicators());
        };
    }, [dispatch]);

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
            @media print {
                .pagebreak {
                    page-break-before: auto;
                }
            }
        `,
        onAfterPrint: () => setOpenPrint(false)
    });

    useEffect(() => {
        if (isPrintOpen) {
            handlePrint();
        }
    }, [isPrintOpen, dispatch, handlePrint]);

    const printRef = useRef();

    const renderContent = () => {
        return (Object.keys(reportIndicators).length > 0
            ? (
                <>
                    {Array.isArray(reportIndicators)
                        ? (
                            <div ref={printRef}>
                                {reportIndicators.map((item, index) => (
                                    <Item
                                        key={item.puid_id || index}
                                        data={item}
                                    />
                                ))}
                            </div>
                        )
                        : (
                            <div ref={printRef}>
                                <Item
                                    data={reportIndicators}
                                />
                            </div>
                        )
                    }
                </>
            )
            : (!loadingReportIndicators && isRequest && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <PageLayout
            header="Отчет по показателям ПУИД"
            filters={<Filters
                onFilter={handleFilter}
                disableRequestBtn={disableRequestBtn}
                initialState={initialState}
                formValues={formValues}
                setFormValues={setFormValues}
            />}
            content={renderContent}
            loading={loadingReportIndicators}
            informPanelProps={{
                buttons: <>
                    <ReportBlock
                        onGetReport={handleReport}
                        reportId={formValues?.is_consolidate ? 6 : 5}
                        newApi
                    />
                    <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.print,
                                disabled: Object.keys(reportIndicators).length === 0,
                                onClick: () => setOpenPrint(true)
                            }
                        ]}
                        noPadding
                        positionLeft
                        showDisabled
                    />
                </>
            }}
        />
    );
};

export default Indicators;
