import React, { memo, useCallback, useMemo } from 'react';
import formatDate from '../../../../../helpers/constants/dateFormat';
import { useState } from 'react';
import SelectVehicles from '../../../../common/Autocomplete/Transport/Vehicles';
import DateRange from '../../../../common/Dates/DateRange';
import {
    getStartOf,
    getEndOf,
    fullDateTimeWithTimeZone
} from '../../../../../helpers/date.config';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';


const Filter = ({ setParams, setReset }) => {
    const initialState = {
        start_date: getStartOf('month'),
        end_date: getEndOf('day'),
        vehicle_id_list: [],
        mileage_by_coordinates: 1,
    };

    const [isDisabledDate, setIsDisabledDate] = useState(false);
    const [filterValues, setFilterValues] = useState(initialState);

    const errorDateRange = useCallback(() => {
        const { start_date = null, end_date = null } = filterValues;
        return !start_date || !end_date;
    }, [filterValues]);

    const isDisabled = useMemo(() => {
        return isDisabledDate;
    }, [filterValues, isDisabledDate]);

    const handleDate = (key) => (value) => {
        setFilterValues({
            ...filterValues,
            [key]: value
        });
    };

    const handleSearch = (params = filterValues) => {
        const newParams = {
            ...params,
            start_date: fullDateTimeWithTimeZone(params.start_date),
            end_date: fullDateTimeWithTimeZone(params.end_date),
            vehicle_id_list: params.vehicle_id_list?.map(({ id }) => id) || null,
        };
        setParams(newParams);
    };

    const resetFilter = (needRefresh) => {
        // if (needRefresh) {
        //     setParams(initialState);
        // }
        setFilterValues(initialState);
        setReset();
    };

    const updateFilter = (filters) => {
        setFilterValues({ // обновляем локальный стейт
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            handleSearch(
                removeEmptyFields({ // отправляем в родителя, чтобы произошел запрос
                    ...initialState,
                    ...filters,
                })
            );
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={handleSearch}
            filter={filterValues}
            setUserFilter={updateFilter}  // если у пользователя сохранен фильтр для этой страницы, мы его из общего компонента сетаем сюда
            disabled={isDisabled}
            filterException={['start_date', 'end_date', 'mileage_by_coordinates']}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filterValues.start_date}
                    valueEndDate={filterValues.end_date}
                    handleDate={handleDate('start_date')}
                    handleEndDate={handleDate('end_date')}
                    isDisabledHandler={(value) => setIsDisabledDate(value)}
                    renderError={errorDateRange}
                    pickersFormat={formatDate.DT_EXCEPT_SECONDS}
                    required
                />
                <SelectVehicles
                    onChange={(value) => handleDate('vehicle_id_list')(value)}
                    selected={filterValues.vehicle_id_list || []}
                    multiple
                    storeName="transportRoad"
                    // required
                    // error={filterValues?.vehicle_id_list?.length === 0}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default memo(Filter);