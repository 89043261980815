import React from 'react';
import { useSelector } from 'react-redux';
import Information from '../../../../common/Information';
import { getColor } from '../helper';
import messages from '../../../../../helpers/constants/messages';
import { roadNetworksSelectors } from '../../../../../redux/RoadNetwork';
import { createIcon } from '../helper';
import getAddressStringFromObject from '../../../../common/Location/LoadAddressByCoords/getAddressStringFromObject';
import titles from '../../../../../helpers/constants/titles';


const PopUp = ({ uuid, item = null, bottomText }) => {
    // забираем данные из полигона
    const polygon = useSelector(roadNetworksSelectors.polygonInfrastructure);
    const data = item
        || polygon.find(({ id }) => uuid === id)
        || {};
    const { address_text, address, name } = data;

    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: getColor(data) }}>
                <div>
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: createIcon('#fff') }}
                    />
                    <span>{name || address_text || getAddressStringFromObject(address) || messages.NO_DATA}</span>
                </div>
            </div>
            <div className="map-popup_body">
                <div className="scroll" style={{ height: 'auto', maxHeight: '300px' }}>
                    <Information
                        data={data}
                        title={{
                            name: {
                                title: 'Название',
                            },
                            type: {
                                title: 'Тип объекта',
                                value: ({ type }) => type?.name || messages.INFO_IS_NOT_FOUND
                            },
                            address: {
                                title: 'Адрес',
                                value: ({ address, address_text }) => {
                                    if (address) {
                                        return address_text || getAddressStringFromObject(address);
                                    }
                                    return null;
                                }
                            },
                            latlon: {
                                title: titles.COORDINATES,
                                value: ({ lat, lon }) => lat && lon ? `${lat} / ${lon}` : null
                            },
                            organization: {
                                title: titles.ORGANIZATION,
                                value: ({ organization }) => organization?.title
                            },
                            description: {
                                title: titles.DESCRIPTION,
                                value: ({ description }) => description
                            },
                            /*
                            created_at: {
                                title: 'Дата создания',
                                value: ({ created_at }) =>
                                    created_at
                                        ? getHumanDate(created_at)
                                        : messages.INFO_IS_NOT_FOUND,
                            },
                            fixated_at: {
                                title: 'Дата регистрации',
                                value: ({ fixated_at }) =>
                                    fixated_at
                                        ? getHumanDate(fixated_at)
                                        : messages.INFO_IS_NOT_FOUND,
                            },
                            */
                        }}
                    />
                </div>
                {bottomText && (
                    <div
                        style={{
                            margin: '.8rem 1rem 0',
                            textAlign: 'center'
                        }}
                    >
                        <strong>{bottomText}</strong>
                    </div>
                )}
            </div>

        </div>
    );
};

export default PopUp;