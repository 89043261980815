import React from 'react';
import Settings from '../../../../common/Settings';
import { loadSettingsGroup, editSettingsList, editSettingsGroup } from 'redux/TrafficFlow/actions';

const PUIDSettings = () => {
    return (
        <Settings
            title="Настройка интервала смены статуса"
            storeProp="trafficFlow"
            listName="settingsGroup"
            loadingName="loadingSettingsGroup"
            loadSettingsGroupAction={loadSettingsGroup}
            editSettingsListAction={editSettingsList}
            editSettingsGroupAction={editSettingsGroup}
        />
    );
};

export default PUIDSettings;
