import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import titles from 'helpers/constants/titles';
import {
    loadVehicleCategories,
    setActive,
} from 'redux/TransportSpecial/actions';
import { transportSpecialSelectors } from 'redux/TransportSpecial';
import * as actions from 'redux/TransportSpecial/actions';
import { loadCurrentCompany } from 'redux/Companies/actions';
import { companiesSelectors } from 'redux/Companies';
import { getHumanDate } from 'helpers/date.config';
import SelectDateHistory from 'components/common/Transport/TransportHistory/SelectDateHistory';
import messages from 'helpers/constants/messages';
import useTransportCategory from 'helpers/hooks/Transport/useTransportCategory';
import titles from 'helpers/constants/titles';

import TabInformation from './TabInformation';
import { getColorByStatus } from '../helper';
import icon from '../icons/icon';
import config from '../config';

const PopUp = (props) => {
    const dispatch = useDispatch();
    const { uuid, history } = props;

    const currentCompany = useSelector(companiesSelectors.currentCompany);

    const wsData = useSelector(transportSpecialSelectors.wsData);
    const active = useSelector(transportSpecialSelectors.active);
    const [telemetryData] = useSelector(transportSpecialSelectors.telemetryReceiversData);

    const data = wsData[uuid]?.vehicle || null;
    const vehicleId = data?.id || null;

    useEffect(() => {
        if(data?.egts_receiver_id) {
            dispatch(actions.loadTelemetryReceivers({ id: data.egts_receiver_id }));
        }
    }, []);

    const telemetry_receiver = telemetryData?.name || '';

    const {
        external_id,
        in_registry = false,
        in_work_order = false,
        speed = 0,
        navigation_at = null,
    } = wsData[uuid] || {};

    const category_id = data?.category_id;
    const getTransportCategory = useTransportCategory(
        loadVehicleCategories,
        'transportSpecial',
        'vehicleCategories'
    );

    const {
        getImageTpl,
        color: categoryColor,
    } = getTransportCategory
        .getHelper
        .getCategory(category_id, in_registry, in_work_order);

    const categoryName = getTransportCategory
        .getHelper
        .getName(category_id);

    const dataInfo = {
        ...data,
        external_id,
        speed,
        navigation_at,
        categoryTS: categoryName || '',
        telemetry_receiver
    };

    // todo не ясно будет эта связь
    // useEffect(() => {
    //     if (dataInfo?.number) {
    //         dispatch(loadRoadWorkOrders(1, 25, { grz: [dataInfo.number.trim()] }));
    //     }
    // }, [dispatch]);

    useEffect(() => {
        if (
            data
            && active?.characteristics?.serial_egts !== data?.characteristics?.serial_egts
            && active?.characteristics?.serial_yandex !== data?.characteristics?.serial_yandex
        ) {
            dispatch(setActive({
                ...data,
                isClick: true
            }));
        }
    }, []);

    useEffect(() => {
        if (!currentCompany[dataInfo?.organization_id] && dataInfo?.organization_id) {
            dispatch(loadCurrentCompany(dataInfo.organization_id));
        }
    }, [dispatch, currentCompany, dataInfo?.organization_id]);

    const currentShow = external_id === active?.characteristics?.serial_egts
        || external_id === active?.characteristics?.serial_yandex
        || null;

    const titleHistory = () => {
        const title = [];
        const categoryNameCurrent = categoryName || config.name;

        title.push(categoryNameCurrent);
        const garage_number = data?.garage_number || null;
        if (garage_number) {
            title.push(`номер ${garage_number}`);
        } else if (uuid) {
            title.push(uuid);
        }
        return title.join(', ');
    };

    return (
        <div className="map-popup">
            <div
                className="map-popup_header"
                style={{
                    backgroundColor: currentShow
                        ? getColorByStatus('active')
                        : categoryColor
                }}
            >
                <div>
                    <div className="img">
                        {getImageTpl('img', icon({ fill: '#000' }))}
                    </div>
                    <span>{config.name}</span>
                </div>
            </div>

            <div className="map-popup_body">
                <TabInformation
                    history={history}
                    data={dataInfo}
                    title={{
                        external_id: {
                            title: 'Идентификатор'
                        },
                        number: {
                            title: 'Номер',
                        },
                        garage_number: {
                            title: 'Гаражный номер',
                            // value: (value) =>
                            //     value ? (
                            //         <span>{props.statuses[value]}</span>
                            //     ) : (
                            //         messages.INFO_IS_NOT_FOUND
                            //     ),
                        },
                        // name: {
                        //     title: 'Название',
                        // },
                        brand_name: {
                            title: 'Марка',
                        },
                        model_name: {
                            title: 'Модель',
                        },
                        categoryTS: {
                            title: titles.TYPE_OF_VEHICLE,
                        },
                        organization_id: {
                            title: 'Организация',
                            value: ({ organization_id }) =>
                                organization_id
                                    ? (<span>{currentCompany[organization_id]?.title}</span>)
                                    : (messages.INFO_IS_NOT_FOUND),
                        },
                        telemetry_receiver: {
                            title: 'Приемник/организация телеметрии',
                        },
                        speed: {
                            title: 'Последняя скорость',
                            value: ({ speed, navigation_at }) => `${speed} км/ч ${navigation_at ? `(на ${getHumanDate(navigation_at)})` : ''}`
                        },
                    }}
                />
                {/*dd <div style={{
                        padding: '1rem',
                        fontSize: '16px',
                    }}>Данных не найдено</div>
                )*/}


                <SelectDateHistory
                    uuid={vehicleId}
                    title={titleHistory()}
                    actions={{
                        storeProp: 'transportSpecial',
                        history: {
                            action: actions.loadVehicleHistoryModal,
                            clear: actions.clearVehicleHistoryModal,
                            loadingProp: 'historyModalLoading',
                            dataProp: 'historyModal',
                        },
                        speedList: {
                            action: actions.loadColorSpeed,
                            dataProp: 'colorSpeed',
                        }
                    }}
                />

            </div>
        </div>
    );
};

export default PopUp;
