import { useContext, useEffect, useState } from 'react';
import messages from '../../../../../helpers/constants/messages';
import { LSContainer } from '../../../../common/List';
import { useDispatch, useSelector } from 'react-redux';
import { loadTransorganizations } from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import Item from './Item';
import Filter from './Filter';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import titles from '../../../../../helpers/constants/titles';
import PageLayout from '../../../../layout/PageLayout';
import Context from '../../../../../helpers/context';

const LinkOrganizations = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const list = useSelector(transportPassengerSelectors.transorganizations);
    const loading = useSelector(transportPassengerSelectors.loadingTransorganizations);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({});

    const reloadList = () => {
        const prepareData = removeEmptyFields(params, false);

        dispatch(loadTransorganizations(1, limit, prepareData));
    };

    useEffect(() => {
        const prepareData = removeEmptyFields(params, false);

        dispatch(loadTransorganizations(1, limit, prepareData));
    }, [dispatch, params]);

    const renderContent = () => {
        return list?.data?.length > 0
            ? (<>
                <LSContainer
                    headers={[
                        { title: 'ID', width: '5%' },
                        { title: titles.NAME, width: '25%' },
                        { title: titles.ORG_ID, width: '10%' },
                        { title: titles.ORGANIZATION, width: '35%' },
                        { title: 'Действия', align: 'right', isActions: true }
                    ]}
                >
                    {list.data.map((item) => (
                        <Item
                            key={item.id}
                            item={item}
                            callback={reloadList}
                            permissions={permissions}
                        />
                    ))}
                </LSContainer>
            </>)
            : !loading && <div>{messages.DATA_IS_NOT_FOUND}</div>;
    };

    return (
        <PageLayout
            header="Привязка организаций"
            filters={<Filter setParams={setParams}/>}
            informPanelProps={{ total: list?.meta?.total }}
            loading={!!loading}
            paginationProps={{
                loadList: (page, limit) => dispatch(loadTransorganizations(page, limit, params)),
                list: list.meta || {},
                limit: Number(limit),
                setLimit: setLimit,
            }}
            content={renderContent}
        />
    );
};

export default LinkOrganizations;