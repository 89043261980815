import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { List } from '@mui/material';

import {
    createCamera,
    loadCameraComplexTypes,
    loadCameraDataType,
    loadCameraDirType,
    loadCameraProtoText,
    loadCameras,
    loadCameraTypes,
    loadCameraVideoType,
    loadComplexStatuses
} from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import messages from 'helpers/constants/messages';
import Context from 'helpers/context';
import { useStoreFromSelector } from 'helpers/hooks';
import Search from 'components/common/Search';
import CustomPagination from 'components/common/CustomPagination';
import { buttonsTypes } from 'components/common/FormButtons';
import FormButtons from 'components/common/FormButtons';
import Loading from 'components/common/Loading';

import EditorForm from './EditorForm';
import CameraItem from './Item';
import style from './cameras.module.scss';

const Cameras = () => {
    const dispatch = useDispatch();

    const data = useSelector(dorisControlSelectors.camerasData);
    const meta = useSelector(dorisControlSelectors.camerasMeta);
    const loading = useSelector(dorisControlSelectors.loading);
    const types = useSelector(dorisControlSelectors.types);
    const complex_types = useSelector(dorisControlSelectors.complex_types);
    const proto = useSelector(dorisControlSelectors.proto);
    const dir_types = useSelector(dorisControlSelectors.dir_types);
    const video_types = useSelector(dorisControlSelectors.video_types);
    const data_types = useSelector(dorisControlSelectors.data_types);

    const complexStatuses = useStoreFromSelector(loadComplexStatuses, dorisControlSelectors.doris_complex_statuses);
    const complexStatusesObj = useMemo(() => complexStatuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [complexStatuses]);

    const [openModal, setOpenModal] = useState(false);
    const { permissions } = useContext(Context);

    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);

    useEffect(() => {
        dispatch(loadCameras(1, limit));
    }, [dispatch]);

    // подгружаем все справочники
    useEffect(() => {
        if (Object.keys(types).length === 0) {
            dispatch(loadCameraTypes());
        }
    }, [dispatch, types]);

    useEffect(() => {
        if (Object.keys(complex_types).length === 0) {
            dispatch(loadCameraComplexTypes());
        }
    }, [dispatch, complex_types]);

    useEffect(() => {
        if (Object.keys(proto).length === 0) {
            dispatch(loadCameraProtoText());
        }
    }, [dispatch, proto]);

    useEffect(() => {
        if (dir_types.length === 0) {
            dispatch(loadCameraDirType());
        }
    }, [dispatch, dir_types]);

    useEffect(() => {
        if (Object.keys(video_types).length === 0) {
            dispatch(loadCameraVideoType());
        }
    }, [dispatch, video_types]);

    useEffect(() => {
        if (Object.keys(data_types).length === 0) {
            dispatch(loadCameraDataType());
        }
    }, [dispatch, data_types]);

    const [params, setParams] = useState('');

    return (
        <div className={style.cameras}>
            {loading && <Loading circular={true}/>}
            <h1>Справочник видеокамер</h1>
            <Search
                action={loadCameras}
                loadParams={(params) => setParams(params)}
            />
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenModal(!openModal),
                            disabled: !permissions?.is_create
                        }
                    ]}
                />
            </div>
            {openModal
                && <EditorForm
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    onSave={(data) => createCamera(data)}
                />
            }
            {data?.length > 0
                ? (<>
                    <List>
                        {data.map((item) => (
                            <CameraItem item={item} key={item.id} complexStatusesObj={complexStatusesObj} />
                        ))}
                    </List>
                    <CustomPagination
                        loadList={(page, limit) => dispatch(loadCameras(page, limit, { query: params }))}
                        limit={limit}
                        setLimit={setLimit}
                        list={meta}
                    />
                </>)
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </div>
    );
};

export default Cameras;
