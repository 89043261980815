import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useState } from 'react';

const FieldDirection = ({ directions, value, directionId, fieldDirection, changeData, error,  helperText }) => {

    const [cameraId, setCameraId] = useState(directionId || directions[0].id || 0);

    const onChangeData = (cameraId) => {
        setCameraId(cameraId);
        const fieldDirectionValue = directions.length > 1
            ? directions.find(el => el.id === Number(cameraId))
            : directions[0];

        changeData({ [fieldDirection.field]: fieldDirectionValue?.direction, direction_id: fieldDirectionValue?.id  });
    };

    return (
        <>
            {directions?.length > 1 && (
                <RadioGroup
                    row
                    value={cameraId}
                    onChange={(e, value) => onChangeData(value)}
                >
                    <FormControlLabel
                        value={directions[0].id}
                        control={<Radio/>}
                        label="От камеры"
                    />
                    <FormControlLabel
                        value={directions[1].id}
                        control={<Radio/>}
                        label="К камере"
                    />
                </RadioGroup>
            )}

            <TextField
                variant="outlined"
                className="block"
                size="small"
                label={fieldDirection.name}
                required={fieldDirection.require}
                value={value}
                name={fieldDirection.field}
                type={fieldDirection.type}
                disabled
                error={(!value && fieldDirection.require) || error}
                helperText={helperText}
            />
        </>
    );
};

export default FieldDirection;