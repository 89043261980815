// import createSvgImage from '../helpers/createSvgImage';

import './style.scss';

// иконка транспорта
// export default createSvgImage(
//     'transport_passenger_marker',
//     '0 0 38 38',
//     // рендер иконки
//     (id, viewBox) => (
//         <svg xmlns="http://www.w3.org/2000/svg" display="none">
//             <symbol id={id} viewBox={viewBox}>
//                 <path filter={`url(#${id}_style)`} fillRule="evenodd" clipRule="evenodd" d="M11.222 29.837c4.296 4.217 11.26 4.217 15.556 0a10.659 10.659 0 0 0 0-15.27L19 6.5l-7.778 8.066a10.659 10.659 0 0 0 0 15.271z" />
//             </symbol>
//             <defs>
//                 <filter id={`${id}_style`} x="4" y="1.619" width="30" height="35.381" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
//                     <feFlood floodOpacity="0" result="BackgroundImageFix"/>
//                     <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
//                     <feOffset/>
//                     <feGaussianBlur stdDeviation="1"/>
//                     <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
//                     <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_23120_22023"/>
//                     <feBlend in="SourceGraphic" in2="effect1_dropShadow_23120_22023" result="shape"/>
//                 </filter>
//             </defs>
//         </svg>
//     )
// );

export function getTransportIcon(svgParams = {}) {
    const params = Object
        .entries({
            ...svgParams
        })
        .map(([ k, v ]) => `${k}="${v}"`)
        .join(' ');

    return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
            <path class="transport-icon-shadow" ${params} fill-rule="evenodd" clip-rule="evenodd" d="M11.222 29.837c4.296 4.217 11.26 4.217 15.556 0a10.659 10.659 0 0 0 0-15.27L19 6.5l-7.778 8.066a10.659 10.659 0 0 0 0 15.271z" />
        </svg>
    `;
};
