import React from 'react';
import cn from 'classnames';

// значение в элементе
const Value = ({
    children,
    className,
    style,
}) => (
    <span className={cn('value',className)} style={style}>
        {children}
    </span>
);

export default Value;