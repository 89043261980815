import { useSelector } from 'react-redux';
import { transportRoadSelectors } from '../../../../../redux/TransportRoad';
import HistoryPoints from './HistoryPoints';


const History = (props) => {
    const { ...params } = props;

    const vehicleHistory = useSelector(transportRoadSelectors.historyModal);

    return (
        <>
            {vehicleHistory?.length > 0 && (
                <HistoryPoints
                    {...params}
                    data={vehicleHistory || []}
                />
            )}
        </>
    );
};

export default History;
