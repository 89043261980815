import React, { useState } from 'react';
import CardWidget from './CardWidget';
// import Modal from '../../../../common/Modal';
// import Moore from './Moore';
import ComponentByType from './ComponentByType';


const Item = ({ item }) => {
    // const [moore, setMoore] = useState(false);

    const {
        url = '',
        type,
        name,
        data,
        max = 50,
    } = item;

    return (
        <CardWidget
            title={name}
            // buttons={url.length > 0
            //     ? [
            //         {
            //             name: 'Подробнее',
            //             onClick: () => { setMoore(true); },
            //         },
            //     ]
            //     : []
            // }
        >
            <ComponentByType
                type={type}
                data={data}
                max={max}
            />

            {/* модалка */}
            {/*{moore && (*/}
            {/*    <Modal*/}
            {/*        title={name}*/}
            {/*        isOpen={moore}*/}
            {/*        onClose={() => setMoore(false)}*/}
            {/*        showCloseInTitle*/}
            {/*    >*/}
            {/*        <Moore item={item} />*/}
            {/*    </Modal>*/}
            {/*)}*/}

        </CardWidget>
    );
};

export default Item;
