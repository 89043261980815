import { useContext, useEffect, useState } from 'react';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import { loadDefectGroup } from '../../../../../redux/RoadWorks/actions';
import { roadworksSelectors } from '../../../../../redux/RoadWorks';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../../helpers/constants/messages';
import Item from './Item';
import AddModal from './AddModal';
import Context from '../../../../../helpers/context';
import { LSContainer } from '../../../../common/List';
import PageLayout from '../../../../layout/PageLayout';
import titles from '../../../../../helpers/constants/titles';
import Filters from './Filters';

const DefectGroups = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const listData = useSelector(roadworksSelectors.defectGroupData);
    const listMeta = useSelector(roadworksSelectors.defectGroupMeta);
    const loading = useSelector(roadworksSelectors.loading);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [modalOpen, setModalOpen] = useState(false);
    const [params, setParams] = useState({
        page: 1,
        data: {}
    });

    const toggleOpen = () => setModalOpen(!modalOpen);

    const reloadList = (isDelete) => {
        isDelete && listData?.length === 1 && listMeta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadDefectGroup(params.page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadDefectGroup(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return <>
            { listData?.length > 0
                ? <LSContainer
                    headers={[
                        { title: titles.NAME, width: '53%' },
                        { title: 'Дата и время создания', width: '20%' },
                        { title: 'Дата и время изменения', width: '20%' },
                        { title: 'Действия', align: 'right', isActions: true }
                    ]}
                >
                    {listData.map((item) => (
                        <Item key={item.id} item={item} reloadList={reloadList} permissions={permissions} />
                    ))}
                </LSContainer>
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }

            {modalOpen && (
                <AddModal
                    isNew={true}
                    toggleOpen={toggleOpen}
                    open={modalOpen}
                    reloadList={() => {
                        setParams({ page: 1, data: {} });
                        toggleOpen();
                    }}
                />
            )}
        </>;
    };

    return (
        <PageLayout
            header="Группа недостатков"
            filters={<Filters setParams={setParams}/>}
            informPanelProps={{
                buttons: (
                    <>
                        <FormButtonsComponent
                            buttons={[
                                ...(permissions?.is_create
                                    ? [{
                                        ...buttonsTypes.add,
                                        onClick: toggleOpen,
                                    }]
                                    : []
                                ),
                            ]}
                            positionLeft
                            noPadding
                        />
                    </>),
                    
                total: listMeta?.total
            }}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: listMeta || {},
                limit: Number(limit),
                setLimit: setLimit,
            }}
            loading={loading}
            content={renderContent}
        />
    );
};

export default DefectGroups;