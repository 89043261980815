import { LSContainer } from 'components/common/List';
import Loading from 'components/common/Loading';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { config } from 'config';
import { useStoreProp, } from 'helpers/hooks';
import {
    loadEquipmentsList,
    loadVehicleCategories,
} from 'redux/TransportPassenger/actions';
import VehiclesItem from './VehiclesItem';

const RenderContent = ({ loading, data, reloadList, }) => {
    const equipments = useStoreProp(loadEquipmentsList, 'transportPassenger', 'equipments');
    const categories = useStoreProp(loadVehicleCategories, 'transportPassenger', 'vehicleCategories');

    return <>
        {loading && <Loading circular={true}/>}
        {data?.length > 0
            ? (<>
                <LSContainer
                    headers={[
                        { title: '', width: '10%' },
                        { title: titles.GRZ, width: '15%' },
                        { title: titles.BRAND, width: '15%' },
                        { title: titles.MODEL, width: '15%' },
                        { title: titles.TYPE_OF_VEHICLE, width: '15%' },
                        config?.project?.code === '34_vlg_vlz'
                            && ({ title: 'FortMonitor', width: '15%' }),
                        { title: 'Действия', align: 'right', isActions: true }
                    ]}
                >
                    {data?.map((item) =>
                        <VehiclesItem
                            key={item.id}
                            data={item}
                            equipments={equipments}
                            categories={categories}
                            reloadList={reloadList}
                        />
                    )}
                </LSContainer>
            </>)
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        }
    </>;
};

export default RenderContent;
