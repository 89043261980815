import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import {
    loadRoadSections,
    createRoadSection,
    setEditForm,
    loadRoadSectionsReport,
} from 'redux/RoadNetwork/actions';
import { roadNetworksSelectors } from 'redux/RoadNetwork';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { REPORT_FORMATS_SMALL, } from 'helpers/report.formats';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import PageLayout from 'components/layout/PageLayout';
import ReportBlock from 'components/common/Report/ReportBlock';
import GetReport from 'components/common/GetReport';

import Filters from './Filters';
import EditorForm from './EditorForm';
import List from './List';

const RoadSections = () => {
    const dispatch = useDispatch();

    const listData = useSelector(roadNetworksSelectors.roadSectionsData);
    const listMeta = useSelector(roadNetworksSelectors.roadSectionsMeta);
    const loading = useSelector(roadNetworksSelectors.loadingRoadSections);
    const editForm = useSelector(roadNetworksSelectors.editForm);

    const [openModal, setOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({
        page: 1,
        data: {}
    });
    const [isPrintOpen, setOpenPrint] = useState(false);

    const reloadList = () => dispatch(loadRoadSections({ page: params.page, limit, ...params.data }));

    const handleCloseEditForm = () => {
        dispatch(setEditForm());
        setOpenModal(false);
    };

    useEffect(() => {
        dispatch(loadRoadSections({ page: params.page, limit, ...params.data }));
    }, [dispatch, limit, params]);

    const createNewRoadSection = (value) => {
        dispatch(createRoadSection(value, () => {
            handleCloseEditForm();
            setParams({ page: 1, data: {} });
        }));
    };

    const handleDownload = (formats) => {
        dispatch(loadRoadSectionsReport({ ...params.data, report: 1, formats: [formats] }));
    };

    const renderContent = () => {
        return <>
            {loading && <Loading circular/>}
            {listData?.length > 0
                ? <List list={listData} reloadList={reloadList} />
                : (!loading && messages.DATA_IS_NOT_FOUND)
            }
            {openModal && (
                <EditorForm
                    isOpen={openModal}
                    noPadding
                    title={titles.ADD}
                    isNew={true}
                    dataForm={editForm}
                    onClose={handleCloseEditForm}
                    onSuccess={createNewRoadSection}
                    inModal
                />
            )}
        </>;
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
            @media print {
                .pagebreak {
                    page-break-before: auto;
                }
            }
        `,
        onAfterPrint: () => setOpenPrint(false)
    });

    useEffect(() => {
        if (isPrintOpen) {
            handlePrint();
        }
    }, [isPrintOpen, dispatch, handlePrint]);

    const componentRef = useRef();

    const renderPrintFile = () => {
        return (<div ref={componentRef} style={{ padding: '20px' }}>
            <h1>Элементы дорожной сети</h1>
            <List list={listData} reloadList={reloadList} inPrint />
        </div>);
    };

    return (<>
        <PageLayout
            header="Элементы дорожной сети"
            filters={<Filters setParams={setParams}/>}
            informPanelProps={{
                buttons: (
                    <>
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                        <ReportBlock
                            customComponents={(onclose) =>
                                <GetReport
                                    handleDownload={(formats) => {
                                        handleDownload(formats);
                                        onclose();
                                    }}
                                    formats={{ ...REPORT_FORMATS_SMALL, PDF: 'PDF' }}
                                />
                            }
                        />
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.print,
                                    onClick: () => setOpenPrint(true),
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    </>),
                total: listMeta?.total
            }}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: listMeta,
                limit: limit,
                setLimit: setLimit,
            }}
            content={renderContent}
        />
        <div style={{ height: 0, overflow: 'hidden' }}>{renderPrintFile()}</div>
    </>);
};

export default RoadSections;
