import React, { useState } from 'react';
import {
    FormGroup, FormHelperText,
} from '@mui/material';
import ColorPicker from '../../../../../../common/ColorPicker';
import FormButtonsComponent from '../../../../../../common/FormButtons/FormButtonsComponent';


// компонент выбора цвета для ДИТ любого типа, кроме Standart
export const RenderColorPicker = ({ 
    color = '', 
    onChange, 
    title = 'Цвет', 
    error, 
    helperText,
    required
}) => {
    const [openColorPicker, setOpenColorPicker] = useState(false);

    const handleChangeColor = (hexColor) => {
        onChange({ target: { name: 'color', value: hexColor } });
    };

    const closeColorPicker = () => {
        setOpenColorPicker(false);
    };

    return (
        <FormGroup className="row__item" required={required}>
            <FormButtonsComponent
                noPadding
                noMarginLeft
                buttons={[
                    {
                        onClick: () => setOpenColorPicker(true),
                        name: `${title}:`,
                        variant: 'contained',
                        color: 'inherit',
                        style: { width: '100%' }
                            
                    }
                ]}
                children={<div
                    style={{
                        height: 20,
                        width: 120,
                        backgroundColor: color,
                        borderRadius: 2,
                        marginLeft: 10
                    }}
                />}
            />

            <ColorPicker
                color={color}
                open={openColorPicker}
                onClose={closeColorPicker}
                onChangeColor={handleChangeColor}
            />
            {error && helperText && <FormHelperText error>{helperText}</FormHelperText>}
        </FormGroup>
    );
};