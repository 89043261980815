import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EditorState, convertToRaw, genKey, convertFromRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import editorStyles from './editor.module.scss';
import '@draft-js-plugins/mention/lib/plugin.css';
import Parser from 'simple-text-parser';
import { Typography } from '@mui/material';

const MentionEditor = ({ text, onChange, serviceEventList }) => {
    const ref = useRef(null);
    
    const mentions = useMemo(() => Object.keys(serviceEventList).map((key) => ({
        name: serviceEventList[key],
        value: `{${key}}`,
    })), [serviceEventList]);

    const [editorState, setEditorState] = useState(null);
    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin();
        // eslint-disable-next-line no-shadow
        const { MentionSuggestions } = mentionPlugin;
        // eslint-disable-next-line no-shadow
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);
    
    const onBlur = (e) => {
        const contentState = editorState.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        const newStringArray = rawContent.blocks.reduce((res, el) => {
            // устанавливаем начало строки откуда будет делать массив
            let count = 0;
            // проходим по массиву entityRange берем оттуда оступы и по ним делим строку на части, пушим в массив
            const replaceTags = el.entityRanges.reduce((result, item) => {
                result.push(el.text.slice(count, item.offset));
                result.push(rawContent.entityMap[item.key]?.data?.mention?.value);
                // меняем начало отсчета на индекс после добавленного элемента
                count = item.offset + item.length;
                return result;
            }, []);
            replaceTags.push(el.text.slice(count));
            res = res.concat(replaceTags);
            return res;
        }, []);
        const newString = newStringArray.join('');
        onChange(newString);
    };

    const prepareContent = useCallback((arr, parser) => {
        const rawContent = {
            blocks: [
                {
                    key: genKey(),
                    text: parser.render(text), 
                    type: 'unstyled',
                    inlineStyleRanges: [],
                    data: {},
                    depth: 0,
                    entityRanges: []
                }
            ],
            entityMap: []
        };

        arr.forEach((el, index) => {
            if (el.type === 'tag') {
                let offset = 0;
                for (let i = 0; i < index; i++) {
                    offset += arr[i]?.text?.length;
                }
                const length = el.text.length;
                const key = rawContent.entityMap.length;
                rawContent.entityMap.push({
                    type: 'mention',
                    mutability: 'SEGMENTED',
                    data: {
                        mention: { name: el.text, value: el.value },
                    },
                });
                rawContent.blocks[0].entityRanges.push({
                    offset,
                    length,
                    key,
                });
            }
        });
        const newEditorState = EditorState.createWithContent(convertFromRaw(rawContent));
        setEditorState(newEditorState);
    }, [text]);

    useEffect(() => {
        if (mentions.length > 0) {
            setSuggestions(mentions);
            const parser = new Parser();
            parser.addRule(/\{([\S]+)\}/, function(value, value_matched) {
                const string = mentions.find(el => el.value === `{${value_matched}}`)?.name;
                return { type: 'tag', text: string, value: value };
            });
            const dataForRender = () => parser.toTree(text);
            const parsedData = dataForRender(text) || [];
            prepareContent(parsedData, parser);
        } 
    }, [text, prepareContent, mentions]);

    const onEditorChange = (editorState) => {
        setEditorState(editorState);
    };

    const onSearchChange = useCallback(({ value }) => {
        const filteredMentions = mentions.filter((mention) =>
            mention.name.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(filteredMentions);
    }, [mentions]);

    return editorState && (
        <>
            <Typography variant="body2" color="error">
                Для добавления события в сообщение нажмите @, 
                на табло в этом месте будет выведено значение
            </Typography>
            <div
                onClick={() => ref.current.focus()}
                className={editorStyles.editor}
            >
                <Editor
                    ref={ref}
                    editorState={editorState}
                    onChange={onEditorChange}
                    plugins={plugins}
                    mentionPrefix={'@'}
                    onBlur={onBlur}

                />
                <MentionSuggestions
                    open={open}
                    onOpenChange={(bool) => setOpen(bool)}
                    suggestions={suggestions}
                    onSearchChange={onSearchChange}
                    onAddMention={({ value }) => {}}
                />
            </div>
        </>
    );
};

export default MentionEditor;