import { all, call, put, takeLatest } from 'redux-saga/effects';

import { messageTypes, showMessage } from 'redux/Message/actions';
import { setValidation } from 'redux/Validation/actions';
import { setMapLoading } from 'redux/Map/actions';
import messagetypes from 'redux/Message/messagetypes';
import messages from 'helpers/constants/messages';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';
/**
 * Sagas
 * **/

//Получение списка светофоров
function* loadTrafficLightsListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingTrafficLightsList(true));
    const response = yield call(api.getTrafficLightsList, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedTrafficLightsList(response));
    }
    yield put(actions.loadingTrafficLightsList(false));
}

//Получение статусов светофора
function* loadTrafficLightsStatusesSaga() {
    const response = yield call(api.getTrafficLightsStatuses);
    if (response?.success) {
        yield put(actions.loadedTrafficLightsStatuses(response?.data));
    }
}

//Добавление светофора
function* createTrafficLightsSaga({ payload }) {
    const response = yield call(api.postTrafficLight, payload);
    if (response?.success) {
        yield put(actions.loadTrafficLightsList());
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, 'Светофор создан'));
    }
    yield  put(actions.saveStatus(response?.success || false));
}

//Редактирование светофора
function* editTrafficLightsSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.editTrafficLight, id, data);
    if (response?.success) {
        yield put(actions.loadTrafficLightsList());
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, 'Светофор отредактирован'));
    }
    yield  put(actions.saveStatus(response?.success || false));
}

//Удаление светофора
function* deleteTrafficLightsSaga({ payload }) {
    const response = yield call(api.deleteTrafficLights, payload);
    if (response?.success) {
        yield put(actions.loadTrafficLightsList());
        yield put(showMessage(messageTypes.success, 'Светофор удален'));
    }
    yield  put(actions.saveStatus(response?.success || false));
}

//Получение состояний светофора
function* loadTrafficLightStatesSaga() {
    const response = yield call(api.getTrafficLightStates);
    if (response?.success) {
        yield put(actions.loadedTrafficLightsStates(response?.data));
    }
}

//Получение типов контроля светофора
function* loadTrafficLightControlTypesSaga() {
    const response = yield call(api.getTrafficLightControlTypes);
    if (response?.success) {
        yield put(actions.loadedTrafficLightControlTypes(response?.data));
    }
}

// Получение полигона
function* getTrafficLightForPolygonSaga({ payload: params }) {
    yield put(setMapLoading('tl_polygon', true));
    const response = yield call(api.getForPolygon, params);
    if (response) {
        yield put(actions.saveForPolygon(response));
    }
    yield put(setMapLoading('tl_polygon', false));
}

// Получение списка для сайдбара
function* loadTrafficLightSideBarSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingSidebar(true));
    const response = yield call(api.getSidebarList, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedSidebar(response, page === 1));
    }
    yield put(actions.loadingSidebar(false));
}



function* loadTrafficLightCoordinationGroupsSaga({ payload: params }) {
    yield put(actions.loadingCoordinationGroups(true));
    const response = yield call(api.getCoordinationGroups, params);
    if (response?.success) {
        yield put(actions.loadedCoordinationGroups(response));
    }
    yield put(actions.loadingCoordinationGroups(false));
}
function* loadTrafficLightCoordinationGroupsPolygonSaga({ payload: params }) {
    // yield put(actions.loadingSidebar(true));
    const response = yield call(api.getCoordinationGroupsPolygon, params);
    if (response?.success) {
        yield put(actions.loadedCoordinationGroups(response));
    }
    // yield put(actions.loadingSidebar(false));
}

function* loadTrafficLightGreenStreetsSaga({ payload: params }) {
    yield put(actions.loadingGreenStreets(true));
    const response = yield call(api.getGreenStreets, params);
    if (response?.success) {
        yield put(actions.loadedGreenStreets(response));
    }
    yield put(actions.loadingGreenStreets(false));
}
function* loadTrafficLightGreenStreetsPolygonSaga({ payload: params }) {
    // yield put(actions.loadingSidebar(true));
    const response = yield call(api.getGreenStreetsPolygon, params);
    if (response?.success) {
        yield put(actions.loadedGreenStreetsPolygon(response));
    }
    // yield put(actions.loadingSidebar(false));
}

function* loadColorsForLegendsSaga() {
    const response = yield call(api.getColorsForLegends);
    if (response?.success) {
        yield put(actions.loadedColorsForLegends(response?.data || {}));
    }
}

function* loadShowcaseSaga() {
    yield put(actions.loadingShowcase(true));
    const response = yield call(api.loadShowcase);
    if (response?.success) {
        yield put(actions.loadedShowcase(response.data));
    }
    yield put(actions.loadingShowcase(false));
}

function* loadTrafficLightByRadiusSaga({ payload, callback }) {
    const response = yield call(api.loadTrafficLightByRadius, payload);
    if (response?.success) {
        yield put(actions.loadedTrafficLightByRadius(response.data));
        callback(response.data);
    }
}

function* editSettingsListSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.editSettingsList, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }

    yield put(actions.loadingButton(false));
}

// settings group
function* loadSettingsGroupSaga() {
    yield put(actions.loadingSettingsGroup(true));
    const response = yield call(api.getSettingsGroup);
    if (response?.success) {
        yield put(actions.loadedSettingsGroup(response));
    }
    yield put(actions.loadingSettingsGroup(false));
}

function* editSettingsGroupSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.editSettingsGroup, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_TRAFFIC_LIGHTS_STATUSES, loadTrafficLightsStatusesSaga),
        takeLatest(types.LOAD_TRAFFIC_LIGHTS_LIST, loadTrafficLightsListSaga),
        takeLatest(types.CREATE_TRAFFIC_LIGHTS, createTrafficLightsSaga),
        takeLatest(types.EDIT_TRAFFIC_LIGHTS, editTrafficLightsSaga),
        takeLatest(types.DELETE_TRAFFIC_LIGHTS, deleteTrafficLightsSaga),
        takeLatest(types.LOAD_TRAFFIC_LIGHT_STATES, loadTrafficLightStatesSaga),
        takeLatest(types.LOAD_TRAFFIC_LIGHT_CONTROL_TYPES, loadTrafficLightControlTypesSaga),
        takeLatest(types.GET_POLYGON, getTrafficLightForPolygonSaga),
        takeLatest(types.LOAD_SIDEBAR, loadTrafficLightSideBarSaga),
        takeLatest(types.LOAD_COORDINATION_GROUP, loadTrafficLightCoordinationGroupsSaga),
        takeLatest(types.LOAD_COORDINATION_GROUP_POLYGON, loadTrafficLightCoordinationGroupsPolygonSaga),
        takeLatest(types.LOAD_GREEN_STREET, loadTrafficLightGreenStreetsSaga),
        takeLatest(types.LOAD_GREEN_STREET_POLYGON, loadTrafficLightGreenStreetsPolygonSaga),

        takeLatest(types.LOAD_COLORS_FOR_LEGENDS, loadColorsForLegendsSaga),
        takeLatest(types.LOAD_SHOWCASE, loadShowcaseSaga),
        takeLatest(types.LOAD_TRAFFIC_LIGHTS_BY_RADIUS, loadTrafficLightByRadiusSaga),

        takeLatest(types.EDIT_SETTINGS_LIST, editSettingsListSaga),

        takeLatest(types.LOAD_SETTINGS_GROUP, loadSettingsGroupSaga),
        takeLatest(types.EDIT_SETTINGS_GROUP, editSettingsGroupSaga),
    ]);
}
