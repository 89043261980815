import * as types from './types';

export const loadList = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_LIST,
    payload: {
        page,
        limit,
        ...params
    },
});

export const loadedList = (data) => ({
    type: types.LOADED_LIST,
    payload: data,
});

export const loadingList = (bool) => ({
    type: types.LOADING_LIST,
    payload: bool,
});


export const loadTemplates = () => ({
    type: types.LOAD_TEMPLATES,
});

export const loadedTemplates = (data) => ({
    type: types.LOADED_TEMPLATES,
    payload: data,
});

export const loadFormats = (params = {}) => ({
    type: types.LOAD_FORMATS,
    payload: params,
});
export const loadFormatsNew = (params = {}) => ({
    type: types.LOAD_FORMATS_NEW,
    payload: params,
});
export const loadedFormats = (data) => ({
    type: types.LOADED_FORMATS,
    payload: data,
});

// удаление отчета
export const deleteReportById = (id, callbackSuccess) => ({
    type: types.DELETE_REPORT,
    payload: id,
    callbackSuccess,
});

export const deleteAllReports = (id, callback = () => {}) => ({
    type: types.DELETE_ALL_REPORTS,
    payload: id,
    callback,
});

export const loadingButton = (loading = false) => ({
    type: types.LOADING_BUTTON,
    payload: loading
});

export const loadScheduledReport = (params = {}) => ({
    type: types.LOAD_SCHEDULE_REPORT,
    payload: params,
});
export const loadedScheduledReport = (params = {}) => ({
    type: types.LOADED_SCHEDULE_REPORT,
    payload: params,
});
export const loadingScheduledReport = (data) => ({
    type: types.LOADING_SCHEDULE_REPORT,
    payload: data,
});
export const createScheduledReport = (data = {}, callback) => ({
    type: types.CREATE_SCHEDULE_REPORT,
    payload: data,
    callback
});
export const editScheduledReport = (id, data = {}, callback) => ({
    type: types.EDIT_SCHEDULE_REPORT,
    payload: {
        id,
        data
    },
    callback
});
export const deleteScheduledReport = (id, callback) => ({
    type: types.DELETE_SCHEDULE_REPORT,
    payload: id,
    callback
});
export const loadingScheduledReportForm = (data) => ({
    type: types.LOADING_SCHEDULE_REPORT_FORM,
    payload: data,
});

export const loadScheduledReportTemplate = () => ({
    type: types.LOAD_SCHEDULE_REPORT_TEMPLATE,
});
export const loadedScheduledReportTemplate = (data) => ({
    type: types.LOADED_SCHEDULE_REPORT_TEMPLATE,
    payload: data,
});

export const loadPuidTFIndicators = (params = {}) => ({
    type: types.LOAD_PUID_TF_INDICATORS,
    payload: params,
});
export const loadPuidTFIndicatorsConsolidate = (params = {}) => ({
    type: types.LOAD_PUID_TF_INDICATORS_CONSOLIDATE,
    payload: params,
});
export const loadingPuid = (bool = false) => ({
    type: types.LOADING_PUID,
    payload: bool,
});

// список отчетов
export const loadReportTemplateFrontendLinks = () => ({
    type: types.LOAD_REPORT_TEMPLATE_FRONTEND_LINKS,
});
export const loadedReportTemplateFrontendLinks = (data) => ({
    type: types.LOADED_REPORT_TEMPLATE_FRONTEND_LINKS,
    payload: data,
});
export const loadingReportTemplateFrontendLinks = (bool = false) => ({
    type: types.LOADING_REPORT_TEMPLATE_FRONTEND_LINKS,
    payload: bool,
});

export const loadApiReportReportTemplateFrontendLink = (url, formats = [], filter = {}, callback) => ({
    type: types.LOAD_API_REPORT_TEMPLATE_FRONTEND_LINK,
    payload: {
        url,
        filter,
        formats
    },
    callback
});
export const loadingApiReportReportTemplateFrontendLink = (bool = false) => ({
    type: types.LOADING_API_REPORT_TEMPLATE_FRONTEND_LINK,
    payload: bool,
});
