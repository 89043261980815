import Loading from 'components/common/Loading';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import DateFilter from './DateFilter';

const Item = (props) => {
    const {
        data,
        onClick,
        // onLock,
        // onUnLock,
        loading = false,
        types = {},
        onChangeDate,
        active = null,
        categoryTS
    } = props;

    const {
        id,
        // mark,
        // model,
        brand_name,
        model_name,
        number,
        type,
        history = false,
        category_id,
        organization
    } = data;

    const currentTS = categoryTS.find(item => item.id === category_id);

    // const isLock= active?.lock || false;
    const isActive = active === id; //!!active || false;

    // const {
    //     routesHistory, // история
    // } = useSelector(({ transportRoad }) => transportRoad);
    // const {
    //     date_history_start = null,
    //     date_history_end = null,
    // } = active || {};

    // category_id: 1
    // characteristics: {serial_egts: 'Test_2', serial_yandex: 'Test_2'}
    // deleted: false
    // employees: []
    // garage_number: "Test_2"
    // history: false
    // id: 2
    // mark: "ХАНТ"
    // model: "8051B"
    // number: "Test_2"
    // organization_id: 48
    // project: "34_vlg_vlz"
    // type: "302"

    // клик
    const handleClick = () => {
        onClick(data);
    };

    // изменить блокировку
    // const handleChangeLock = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //
    //     if (history) {
    //         isLock ? onUnLock(data) : onLock(data);
    //     }
    // };

    // const {
    //     start_date = null,
    //     end_date = null,
    // } = routesHistory[serial_egts] || {};

    return (
        <div
            className={`layers-sidebar__layer__item${isActive ? ' active' : ''}`}
            style={{ display: 'block' }}
        >
            {/* название */}
            <div className="layers-sidebar__layer__item__content" onClick={handleClick}>
                <div className="description">
                    {'ГРЗ: '}
                    {number}
                    {/*{numberValidator(number) || 'Информация отсутствует'}*/}
                </div>
                <div className="">Марка и модель: {brand_name + ' ' + model_name}</div>
                <div>{titles.TYPE_OF_VEHICLE}: {currentTS?.name || messages.NO_DATA}</div>
                <div>Организация: {organization?.title || ''}</div>
            </div>

            {(isActive && history) && (
                <DateFilter
                    // start_date={start_date}
                    // end_date={end_date}
                    onChange={onChangeDate}
                />
            )}

            {/* иконка блокировки */}
            {/*<div className={`locked-wrap ${isLock ? 'show' : ''}`}>*/}
            {/*    <Tooltip title={isLock ? titles.UNLOCK : titles.LOCK}>*/}
            {/*        <IconButton*/}
            {/*            size="small"*/}
            {/*            onClick={handleChangeLock}*/}
            {/*            style={{ fontSize: '13px', padding: '5px' }}*/}
            {/*        >*/}
            {/*            {*/}
            {/*                isLock*/}
            {/*                    ? <i className="fas fa-lock" />*/}
            {/*                    : <i className="fas fa-lock-open" />*/}
            {/*            }*/}
            {/*        </IconButton>*/}
            {/*    </Tooltip>*/}
            {/*</div>*/}

            {/* лоадинг */}
            {loading && <Loading className="absolute bottom fill" />}

        </div>
    );
};

export default Item;
