import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { sortBy, values } from 'lodash';

import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import Loading from 'components/common/Loading';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import { loadInfopanel, loadThreatLevels } from 'redux/SituationalPlans/actions';

import PieItem from '../../Dictionaries/SituationalPlans/InfoPanel/Pie';

// страница
const Header = () => {
    const dispatch = useDispatch();

    const threatLevels = useSelector(SituationalPlansSelectors.threatLevels);
    const infopanel = useSelector(SituationalPlansSelectors.infopanel);
    const loadingInfopanel = useSelector(SituationalPlansSelectors.loadingInfopanel);

    useEffect(() => {
        const filter = {
            start_date: fullDateTimeWithTimeZone(getStartOf('year')),
            end_date: fullDateTimeWithTimeZone(getEndOf('day')),
        };

        dispatch(loadThreatLevels()); // Уровни угроз
        dispatch(loadInfopanel(filter)); // Данные для шапки
    }, [dispatch]);

    const pie1Data = useMemo(() => {
        const valuesObj = values(infopanel?.data)
            .reduce((res, item) => typeof item === 'object'
                ? {
                    ...res,
                    [item.threat_level_id]: item.total || 0
                }
                : res
            , {});
        return sortBy(threatLevels?.data, ({ id }) => id)
            .map(({ id = 0, name, color }) => ({
                id,
                name,
                value: valuesObj[id],
                color,
            }));
    },[infopanel?.data, threatLevels?.data]);

    const pie2Data = useMemo(() => {
        const labels = [
            // { name: 'Всего', key: 'total', color: '#cc0066' },
            { name: 'Решено', key: 'done', color: '#3333cc' },
            { name: 'В работе', key: 'in_work', color: '#0099cc' },
            { name: 'Просрочено', key: 'overdue', color: '#FF0000' },
            // { name: 'Всего ТИ', key: 'total', color: '#cc0066' },
            // { name: 'Решено', key: 'done', color: '#3333cc' },
            // { name: 'В работе', key: 'in_work', color: '#0099cc' },
            // { name: 'Просрочено', key: 'overdue', color: '#FF0000' },
            // { name: 'Сегодня', key: 'today', color: '#33cc33' },
        ];
        return labels
            .map(({ key, name, color }) => ({
                name,
                value: infopanel?.data?.[key],
                color,
            }));
    },[infopanel?.data]);

    return (
        <Grid container spacing={1} sx={{ height: '100%' }}>
            {loadingInfopanel
                ? <Grid item xs={12}><Loading circular={true}/></Grid>
                : (
                    <>
                        <Grid
                            item
                            xs={12}
                            md={6}
                        >
                            <PieItem
                                data={pie1Data}
                                title="Уровень угрозы"
                                style={{ width: '350px', margin: '0 auto' }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                        >
                            <PieItem
                                data={pie2Data}
                                title={`События (Всего: ${infopanel?.data?.total || 0})`}
                                style={{ width: '350px', margin: '0 auto' }}
                            />
                        </Grid>
                    </>
                )
            }
        </Grid>
    );
};

export default Header;
