import * as types from './types';

/**
 * Actions
 **/

//Получение списка светофоров
export const loadTrafficLightsList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_TRAFFIC_LIGHTS_LIST,
    payload: { page, limit, query }
});

export const loadingTrafficLightsList = (bool = false) => ({
    type: types.LOADING_TRAFFIC_LIGHTS_LIST,
    payload: bool
});

export const loadedTrafficLightsList = (data) => ({
    type: types.LOADED_TRAFFIC_LIGHTS_LIST,
    payload: data
});

//Получение статусов светофора
export const loadTrafficLightsStatuses = () => ({
    type: types.LOAD_TRAFFIC_LIGHTS_STATUSES
});

export const loadedTrafficLightsStatuses = (data) => ({
    type: types.LOADED_TRAFFIC_LIGHTS_STATUSES,
    payload: data
});

//Добавление светофора
export const createTrafficLights = (data) => ({
    type: types.CREATE_TRAFFIC_LIGHTS,
    payload: data
});

//Редактирование светофора
export const editTrafficLights = (id, data) => ({
    type: types.EDIT_TRAFFIC_LIGHTS,
    payload: { id, data }
});

//Удаление светофора
export const deleteTrafficLights = (id) => ({
    type: types.DELETE_TRAFFIC_LIGHTS,
    payload: id
});

//Получение состояний светофора
export const loadTrafficLightsStates = () => ({
    type: types.LOAD_TRAFFIC_LIGHT_STATES
});

export const loadedTrafficLightsStates = (data) => ({
    type: types.LOADED_TRAFFIC_LIGHT_STATES,
    payload: data
});

//Получение типов контроля светофора
export const loadTrafficLightControlTypes = () => ({
    type: types.LOAD_TRAFFIC_LIGHT_CONTROL_TYPES
});

export const loadedTrafficLightControlTypes = (data) => ({
    type: types.LOADED_TRAFFIC_LIGHT_CONTROL_TYPES,
    payload: data
});


/* полигон */
export const getForPolygon = (polygon, params = {}) => ({
    type: types.GET_POLYGON,
    payload: { polygon, ...params },
});

export const saveForPolygon = (data) => ({
    type: types.SAVE_POLYGON,
    payload: data,
});

export const clearForPolygon = () => ({
    type: types.SAVE_POLYGON,
    payload: [],
});

export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool
});

export const loadedSidebar = (data, replace = true) => ({
    type: types.LOADED_SIDEBAR,
    payload: {
        data,
        replace,
    }
});

export const loadSidebar = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_SIDEBAR,
    payload: {
        page,
        limit,
        query,
    },
});

export const clearSidebar = () => ({
    type: types.LOADED_SIDEBAR,
    payload: {
        data: {
            data: [],
            meta: {
                last_page: 0,
            },
        },
        replace: true,
    },
});

export const setActive = (item) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const resetActive = () => ({
    type: types.SET_ACTIVE,
    payload: {},
});

export const setDeleteForm = (data = false) => ({
    type: types.SET_DELETE_FORM,
    payload: data,
});

export const setEditForm = (data = false) => ({
    type: types.SET_EDIT_FORM,
    payload: data,
});

export const saveStatus = (bool = false) => ({
    type: types.SAVED,
    payload: bool,
});

export const setFilters = (filter) => ({
    type: types.SET_FILTER,
    payload: filter,
});
export const clearFilters = () => ({
    type: types.SET_FILTER,
    payload: {},
});




// группы
export const loadCoordinationGroups = (data = {}) => ({
    type: types.LOAD_COORDINATION_GROUP,
    payload: data
});
export const loadedCoordinationGroups = (data) => ({
    type: types.LOADED_COORDINATION_GROUP,
    payload: data
});
export const loadingCoordinationGroups = (bool = false) => ({
    type: types.COORDINATION_GROUP_LOADING,
    payload: bool
});

// полигон группы
export const loadCoordinationGroupsPolygon = (data = {}) => ({
    type: types.LOAD_COORDINATION_GROUP_POLYGON,
    payload: data
});
export const loadedCoordinationGroupsPolygon = (data) => ({
    type: types.LOADED_COORDINATION_GROUP_POLYGON,
    payload: data
});

// зеленые улицы
export const loadGreenStreets = (data = {}) => ({
    type: types.LOAD_GREEN_STREET,
    payload: data
});
export const loadingGreenStreets = (bool = false) => ({
    type: types.GREEN_STREET_LOADING,
    payload: bool
});
export const loadedGreenStreets = (data) => ({
    type: types.LOADED_GREEN_STREET,
    payload: data
});

// полигон зеленой улицы
export const loadGreenStreetsPolygon = (data = {}) => ({
    type: types.LOAD_GREEN_STREET_POLYGON,
    payload: data
});
export const loadedGreenStreetsPolygon = (data) => ({
    type: types.LOADED_GREEN_STREET_POLYGON,
    payload: data
});

export const wsReplaceData = (events) => ({
    type: types.WS_REPLACE_DATA,
    payload: events,
});
export const wsReplaceDataList = (events) => ({
    type: types.WS_REPLACE_DATA_LIST,
    payload: events,
});

// export const wsReplaceLight = (data) => ({
//     type: types.WS_REPLACE_LIGHT,
//     payload: data,
// });

export const wsSetFilter = (filter = []) => ({
    type: types.WS_SET_FILTER,
    payload: filter,
});


export const showGSAndCSByTL = (bool = false) => ({
    type: types.SHOW_GS_CS_BY_TRAFIC_LIGHTS,
    payload: bool,
});


export const loadColorsForLegends = () => ({
    type: types.LOAD_COLORS_FOR_LEGENDS,
});
export const loadedColorsForLegends = (data) => ({
    type: types.LOADED_COLORS_FOR_LEGENDS,
    payload: data,
});


export const loadShowcase = () => ({
    type: types.LOAD_SHOWCASE,
});
export const loadingShowcase = (bool = false) => ({
    type: types.LOADING_SHOWCASE,
    payload: bool
});
export const loadedShowcase = (data) => ({
    type: types.LOADED_SHOWCASE,
    payload: data
});
export const clearShowcase = () => ({
    type: types.LOADED_SHOWCASE,
    payload: {}
});

export const loadTrafficLightByRadius = (data, callback = () => {}) => ({
    type: types.LOAD_TRAFFIC_LIGHTS_BY_RADIUS,
    payload: data,
    callback
});

export const loadedTrafficLightByRadius = (data) => ({
    type: types.LOADED_TRAFFIC_LIGHTS_BY_RADIUS,
    payload: data
});

// Settings
export const editSettingsList = (data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_LIST,
    payload: data,
    callback
});

// Settings Group
export const loadSettingsGroup = () => ({
    type: types.LOAD_SETTINGS_GROUP,
});

export const loadingSettingsGroup = (bool) => ({
    type: types.LOADING_SETTINGS_GROUP,
    payload: bool
});

export const loadedSettingsGroup = (data) => ({
    type: types.LOADED_SETTINGS_GROUP,
    payload: data
});

export const editSettingsGroup = (id, data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_GROUP,
    payload: { id, data },
    callback
});

export const loadingButton = (loading = false) => ({
    type: types.LOADING_BUTTON,
    payload: loading
});
