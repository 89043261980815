import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { loadDtpFocusStatistic } from 'redux/FocusIncidents/actions';
import { focusIncidentsSelectors } from 'redux/FocusIncidents';

import getFilters from '../../../helpers/getFilters';
import { getFilter } from '../helper';

const Statistic = () => {
    const dispatch = useDispatch();
    const filters = useSelector(focusIncidentsSelectors.filters);
    const data = useSelector(focusIncidentsSelectors.statistic);

    useEffect(() => {
        const filter = getFilters(filters, getFilter);
        dispatch(loadDtpFocusStatistic(filter));
    }, [filters]);

    const {
        count_focus,
        count_confirmed_focus,
        count_primary_focus,
        count_confirmed_primary_focus,
    } = data;

    return (
        <div className="layers-sidebar__filter border-bottom">
            <Grid container spacing={0} style={{ fontSize: '0.82rem' }}>
                <Grid item xs={6}>
                    <span className="info__item-title">Очагов:</span> <span>{count_focus}</span>
                </Grid>
                <Grid item xs={6}>
                    <span className="info__item-title">Подтвержденных:</span> <span>{count_confirmed_focus}</span>
                </Grid>
                <Grid item xs={6}>
                    <span className="info__item-title">ПАОУ:</span> <span>{count_primary_focus}</span>
                </Grid>
                <Grid item xs={6}>
                    <span className="info__item-title">Подтвержденных:</span> <span>{count_confirmed_primary_focus}</span>
                </Grid>
            </Grid>
        </div>
    );
};

export default memo(Statistic);
