import React from 'react';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { theme } from '../../../../../App';

// todo мб вынести в общие
const Providers = ({ children }) => (
    <LocalizationProvider dateAdapter={AdapterLuxon} locale={'ru'}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    </LocalizationProvider>
);

export default Providers;
