import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Checkbox,
    FormControlLabel,
    IconButton,
    LinearProgress,
    Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
    setActiveEvent,
    setActive as setActiveVF,
    setShowDorisCameraEvents,
} from 'redux/DorisControl/actions';
import { addActiveLayer, setSelectedLayer } from 'redux/Menu/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import { menuSelectors } from 'redux/Menu';
import { useWsSubscribe } from 'helpers/ws/hooks';
import useDorisCompare from 'helpers/ws/helper/useDorisCompare';

import filterList from './filterList'; // список фильтрации
import EventsList from './EventsList';
import useStyles from './useStyles';

const CameraEventsFrame = (props) => {
    const {
        // загружаем
        countLoad = 10,
        // показываем
        countShow = 10,
    } = props || {};

    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [fouls, setFouls] = useState(true);
    const [driveways, setDriveways] = useState(true);

    const filters = useSelector(dorisControlSelectors.filters);
    const loadingComplexEvents = useSelector(dorisControlSelectors.loadingComplexEvents);
    const selectedLayer = useSelector(menuSelectors.selectedLayer);

    // фильтрованные события
    const [events, setEvents] = useState([]);

    // фильтр из слоя
    // { 'поле': (value) => ф-я фильтрации }
    const layerFilter = useMemo(() => {
        return Object
            .keys(filters)
            .reduce((res, key) => {
                const item = filterList[key];
                if (item) {
                    const filterValue = filters[key];
                    return {
                        ...res,
                        [item.key]: item.f
                            ? item.f(filterValue)
                            : (value) => value === filterValue,
                    };
                }
                return res;
            }, {});
    }, [filters]);

    // фильтрация
    const filterAndReplace = (events, replaceAll = false) => {
        const checkDF = (event) => {
            // если это типа нарушения 0, то либо Проезды должны быть включены, либо обе галки выключены
            // если не 0 - то всё показываем
            return event?.violation_type === 0 ? (driveways  || (!driveways && !fouls )) : true;
        };

        // проверка события фильтру
        const checkByFilter = (event) => Object
            .keys(layerFilter)
            .reduce((res, filterKey) => {
                // функция фильтра
                const filterFunction = layerFilter[filterKey];
                // значение для фильтра
                const value = event[filterKey] || null;
                // результат
                const resItem = value ? filterFunction(value) : true;

                return res && resItem;
            }, checkDF(event));

        // фильтруем список
        const list = events
            .reduce((res, item) => {
                if (
                    // отбираем сколько надо
                    res.length < countShow
                    && checkByFilter(item)
                ) {
                    return [
                        ...res,
                        item,
                    ];
                }
                return res;
            }, []);

        // добавляем/замещаем данные
        setEvents(old => {
            return replaceAll
                ? list
                : [
                    ...list,
                    ...old,
                ].slice(0, countShow);
        });
    };

    // объединение и вывод раз в 3 секунды
    const dorisCompare = useDorisCompare((events) => filterAndReplace(events));
    // события комплекса (фото справа)
    useWsSubscribe('doris-control_camera_events_telemetry_v2', dorisCompare);

    // фильтруем имеющиеся события
    useEffect(() => {
        filterAndReplace(events, true);
    }, [driveways, fouls, layerFilter]);

    const handleClickItem = (data) => {
        const extendsProps = {
            ...data,
        };

        if (data?.complex?.lat) extendsProps.lat = data?.complex?.lat;
        if (data?.complex?.lon) extendsProps.lon = data?.complex?.lon;
        if (location.pathname !== '/map' || selectedLayer !== 'camera') {
            // переходим на карту
            history.push({
                pathname: '/map',
                search: 'layers[]=camera',
            });
            // активный слой
            dispatch(addActiveLayer('camera'));
            dispatch(setSelectedLayer('camera'));
        }
        setTimeout(() => {
        // позиционируем и открываем конкретный комплекс
            dispatch(setActiveVF({
                ...extendsProps,
                id: parseInt(data?.complex_id),
            }));
        }, 500);

        dispatch(setActiveEvent(extendsProps));
    };

    const handleChecked = (e) => {
        const { name } = e.target;
        name === 'fouls'
            ? setFouls(!fouls)
            : setDriveways(!driveways);
    };

    const closeFrame = () => {
        dispatch(setShowDorisCameraEvents(false));
        setEvents([]);
    };

    return (
        <div className={classes.frame_block}>
            <div className={classes.frameButtons}>

                <div>
                    <Tooltip title="Закрыть">
                        <IconButton onClick={() => closeFrame()} size="small">
                            <CloseIcon style={{ color: 'white' }}/>
                        </IconButton>
                    </Tooltip>
                </div>

                <FormControlLabel
                    control={<Checkbox
                        name="driveways"
                        onClick={handleChecked}
                        checked={driveways}
                    />}
                    label="Проезды"
                />

                <FormControlLabel
                    control={<Checkbox
                        name="fouls"
                        onClick={handleChecked}
                        checked={fouls}
                    />}
                    label="Нарушения"
                />

            </div>

            {loadingComplexEvents
                && <LinearProgress style={{ marginBottom: '.5rem' }} />
            }

            <EventsList
                events={events}
                onClick={handleClickItem}
            />

            {(events.length === 0)
                && <h4>Ожидаются данные...</h4>
            }
        </div>
    );
};

export default CameraEventsFrame;
