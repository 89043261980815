import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiMethods = {
    fetchList: async (params) => {
        const response = await instance.get(apiUrls.fetchList(), {
            params
        });

        return response?.data;
    },

    setRead: async (id) => {
        const response = await instance.put(apiUrls.setRead(id));

        return response?.data;
    },

    setReadAll: async () => {
        const response = await instance.put(apiUrls.setReadAll());
        return response?.data;
    },

    fetchCountActive: async (params) => {
        const response = await instance.get(apiUrls.fetchCountActive(), {
            params
        });

        return response?.data;
    },

    loadFreeSpaceServer: async () => {
        const response = await instance.get(apiUrls.loadFreeSpaceServer());
        return response?.data;
    }
};

export default ApiMethods;
