import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Popper } from '@mui/material';
import { VehicleInfo } from '../../../../common/Transport/VehicleInfo';
import {
    loadVehicleCategories, loadVehiclesTypes
} from '../../../../../redux/TransportPassenger/actions';

const useStyles = makeStyles({
    paper: {
        padding: '10px 15px',
        background: 'rgba(220, 220, 220, 1.000)'
    }
});

const VehiclePopup = ({ info, id, openWindow, anchorEl }) => {
    const storeName = 'transportPassenger';
    const classes = useStyles();
    return (
        <Popper
            id={id}
            open={openWindow}
            transition
            anchorEl={anchorEl}
            placement="bottom-start"
            disablePortal={false}
            modifiers={{
                flip: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                }
            }}
        >
            <Paper className={classes.paper}>
                <VehicleInfo
                    info={info}
                    storeName={storeName}
                    loadVehicleCategories={loadVehicleCategories}
                    loadVehiclesTypes={loadVehiclesTypes}
                />
            </Paper>
        </Popper>
    );
};

export default VehiclePopup;
