/**
 * Sagas
 * **/
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { messageTypes, showMessage } from 'redux/Message/actions';
import { setMapLoading } from 'redux/Map/actions';
import messages from 'helpers/constants/messages';

import * as actions from './actions';
import api from './api.methods';
import * as types from './types';

function* loadSidebarSaga({ payload }) {
    const { page, limit, data, replaceStore } = payload;
    const params = { page, limit, ...data };
    yield put(actions.loadingSidebar(true));
    const response = yield call(api.getDtpFocus, params);
    if (response?.success) {
        yield put(actions.loadedSidebar(response, replaceStore));
    }
    yield put(actions.loadingSidebar(false));
}

function* loadPolygonSaga({ payload }) {
    const { polygon, params } = payload;
    yield put(actions.loadingDtpPolygon(true));
    yield put(setMapLoading('focusIncidents', true));
    const response = yield call(api.getDtpFocusPolygon, polygon, params);
    if (response?.success) {
        yield put(actions.loadedPolygon(response?.data || []));
    }
    yield put(actions.loadingDtpPolygon(false));
    yield put(setMapLoading('focusIncidents', false));
}

function* createDtpFocusSaga({ payload }) {
    const { data } = payload;
    yield put(actions.loading(true));
    const response = yield call(api.createDtpFocus, data);
    if (response?.success) {
        yield put(actions.saveDtpFocus(response.success));
        yield put(actions.getDtpFocus());
        yield put(showMessage(messageTypes.success, 'Данные сохранены'));
    }
    yield put(actions.loading(false));
}

function* editDtpFocusSaga({ payload }) {
    const { id, data } = payload;
    yield put(actions.loading(true));
    const response = yield call(api.editDtpFocus, id, data);
    if (response?.success) {
        yield put(actions.saveDtpFocus(response.success));
        yield put(actions.getDtpFocus());
        yield put(showMessage(messageTypes.success, 'Данные сохранены'));
    }
    yield put(actions.loading(false));
}

function* deleteDtpFocusSaga({ payload }) {
    const { id } = payload;
    yield put(actions.loading(true));
    const response = yield call(api.deleteDtpFocus, id);
    if (response?.success) {
        yield put(actions.saveDtpFocus(response.success));
        yield put(actions.getDtpFocus());
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
    yield put(actions.loading(false));
}

function* loadDtpFocusSaga({ payload }) {
    const { page, limit, data } = payload;
    const params = { page, limit, ...data };
    yield put(actions.loadingDtpFocus(true));
    const response = yield call(api.getDtpFocus, params);
    if (response?.success) {
        yield put(actions.loadedDtpFocus(response));
    }
    yield put(actions.loadingDtpFocus(false));
}

function* loadDtpFocusStatisticSaga({ payload }) {
    yield put(actions.loadingDtpFocusStatistic(true));
    const response = yield call(api.getDtpFocusStatistic, payload);
    if (response?.success) {
        yield put(actions.loadedDtpFocusStatistic(response.data));
    }
    yield put(actions.loadingDtpFocusStatistic(false));
}

function* loadHeatMapSaga({ payload }) {
    yield put(actions.loadingHeatMap(true));
    const response = yield call(api.loadHeatMapSaga, payload);
    if (response?.success) {
        yield put(actions.loadedHeatMap(response.data));
    }
    yield put(actions.loadingHeatMap(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_SIDEBAR, loadSidebarSaga),
        takeLatest(types.LOAD_POLYGON, loadPolygonSaga),
        takeLatest(types.CREATE_DTP_FOCUS, createDtpFocusSaga),
        takeLatest(types.EDIT_DTP_FOCUS, editDtpFocusSaga),
        takeLatest(types.DELETE_DTP_FOCUS, deleteDtpFocusSaga),
        takeLatest(types.LOAD_DTP_FOCUS, loadDtpFocusSaga),
        takeLatest(types.LOAD_DTP_FOCUS_STATISTIC, loadDtpFocusStatisticSaga),
        takeLatest(types.LOAD_HEAT_MAP, loadHeatMapSaga),
    ]);
};
