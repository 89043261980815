import React, { useEffect, useMemo, useState } from 'react';
import DateRange from '../../../../common/Dates/DateRange';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { Autocomplete } from '@mui/material';
import { loadDtpLoadTypes } from '../../../../../redux/Incidents/actions';
import {
    FormControl,
    TextField,
} from '@mui/material';
import { dateWithDashYYYYMMDD } from '../../../../../helpers/date.config';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import { useStoreProp } from '../../../../../helpers/hooks';
import titles from '../../../../../helpers/constants/titles';


function Filter({ onFilter, onReset }) {
    const loadTypes = useStoreProp(loadDtpLoadTypes, 'incidents', 'loadTypes');
    const [disabledButton, setDisabledButton] = useState(false);

    const initialState = {
        typeLoad: [],
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(),
    };

    const [data, setData] = useState({ ...initialState });
    const [isFiltered, setIsFiltered] = useState(false);

    const handleChangeData = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const filter = useMemo(() => {
        return removeEmptyFields({
            type_load: data.typeLoad?.map(item => item.id),
            start_date: data.startDate ? dateWithDashYYYYMMDD(data.startDate) : null,
            end_date: data.endDate ? dateWithDashYYYYMMDD(data.endDate) : null,
        });
    },[data]);

    const search = () => {
        setIsFiltered(true);
        onFilter(filter);
    };

    const resetSearch = () => {
        const resetData = {
            ...initialState,
        };
        if (loadTypes && loadTypes[2]) {
            resetData.typeLoad = [
                {
                    id: '2',
                    name: loadTypes[2]
                }
            ];
        }
        setData(resetData);

        if (isFiltered) {
            setIsFiltered(false);
            onReset();
        }
    };

    const loadOptions = Object
        .keys(loadTypes)
        .map((key) => ({
            id: key,
            name: loadTypes[key]
        }));

    useEffect(() => {
        if (loadTypes && loadTypes[2]) {
            setData((old) => ({
                ...old,
                typeLoad: [
                    {
                        id: '2',
                        name: loadTypes[2]
                    }
                ]
            }));
        }
    } ,[loadTypes]);

    return (
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">
                <FormControl variant="outlined" size="small" >
                    <Autocomplete
                        noOptionsText={titles.NO_RESULTS}
                        value={data.typeLoad || []}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={loadOptions}
                        filterSelectedOptions={true}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => handleChangeData('typeLoad', value)}
                        size="small"
                        multiple
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label="Источник создания"
                            />
                        )}
                    />
                </FormControl>
                <DateRange
                    handleDate={(value) => handleChangeData('startDate', value)}
                    handleEndDate={(value) => handleChangeData('endDate', value)}
                    valueStartDate={data.startDate}
                    valueEndDate={data.endDate}
                    dateOnly={true}
                    isDisabledHandler={setDisabledButton}
                />
            </div>
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetSearch
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: search,
                            disabled: Object.values(filter).length === 0 || disabledButton
                        },
                    ]}
                />
            </div>
        </div>
    );
}

export default Filter;
