import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getTemplate,
    loadVehicleList,
} from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { clearValidation } from 'redux/Validation/actions';
import { uploadVehicleFile } from 'redux/Upload/actions';
import { useValidation } from 'helpers/hooks';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import ButtonWithPopover from 'components/common/Buttons/ButtonWithPopover';
import FileUploader from 'components/common/Upload/FileUploader';

import VehiclesModal from './VehiclesModal';
import UploadModal from './UploadModal';
import RenderContent from './RenderContent';
import Filters from './Filters';
import { vehiclesTransportPassengerURI } from './moduleConfig';

const Vehicles = () => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const vehicleLoading = useSelector(transportPassengerSelectors.vehicleLoading);
    const vehiclesData = useSelector(transportPassengerSelectors.vehiclesData);
    const vehiclesMeta = useSelector(transportPassengerSelectors.vehiclesMeta);
    const loadingTemplate = useSelector(transportPassengerSelectors.loadingTemplate);
    const { uploadFileError, uploadedVehicleFile, uploading } = useSelector(({ upload }) => upload);

    const [modalOpen, setModalOpen] = useState(false);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const closeModal = () => {
        dispatch(clearValidation());
        setModalOpen(false);
    };

    const closeUploadModal = () => {
        validation.clear();
        setUploadModalOpen(false);
    };

    const handleGetTemplate = () => {
        dispatch(getTemplate('XLSX'));
    };

    useEffect(() => {
        dispatch(loadVehicleList(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    useEffect(() => {
        if(uploadFileError || (Object.keys(validation?.data).length && uploadedVehicleFile?.file))
            setUploadModalOpen(true);
    }, [uploadFileError, validation.data, uploadedVehicleFile]);

    const reloadList = (isDeleted) => {
        const page = isDeleted && vehiclesMeta?.last_page > 1 && vehiclesData.length === 1
            ? params.page - 1
            : params.page;

        dispatch(loadVehicleList(page, limit, params.data));
    };

    return (
        <>
            <PageLayout
                header={'Транспортные средства'}
                loading={vehicleLoading}
                filters={<Filters setParams={setParams} />}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setModalOpen(true),
                                        testName: 'modal'
                                    },
                                ]}
                                positionLeft
                                noPadding
                                test_id_prefix={`${vehiclesTransportPassengerURI}:create`}
                            />
                            <ButtonWithPopover
                                buttonType="download"
                                actions={(callback) => (
                                    <FormButtonsComponent
                                        noPadding
                                        noMarginLeft
                                        buttons={[
                                            {
                                                ...buttonsTypes.defaultTextInfo,
                                                name: 'Шаблон для загрузки ТС',
                                                onClick: ()  => {
                                                    handleGetTemplate();
                                                    callback && callback();
                                                },
                                                testName: 'template',
                                            }
                                        ]}
                                        test_id_prefix={`${vehiclesTransportPassengerURI}:download`}
                                    />
                                )}
                                testName={'download'}
                                test_id_prefix={`${vehiclesTransportPassengerURI}:popover`}
                            />
                            <ButtonWithPopover
                                buttonType="upload"
                                actions={(callback) => (
                                    <FileUploader
                                        isButton
                                        action={uploadVehicleFile}
                                        callback={callback}
                                        title="Список ТС"
                                        buttonType="defaultTextInfo"
                                        test_id_prefix={`${vehiclesTransportPassengerURI}:file`}
                                    />
                                )}
                                testName={'upload'}
                                test_id_prefix={`${vehiclesTransportPassengerURI}:popover`}
                            />
                        </>),

                    total: vehiclesMeta?.total
                }}
                content={() => <RenderContent
                    loading={loadingTemplate || uploading}
                    data={vehiclesData}
                    reloadList={reloadList}
                />}
                paginationProps={{
                    loadList: (page) => setParams(prev => ({ page, data: prev.data })),
                    list: vehiclesMeta,
                    limit,
                    setLimit
                }}
            />
            {modalOpen && (
                <VehiclesModal
                    onClose={closeModal}
                    isOpen={modalOpen}
                    isNew={true}
                    reloadList={reloadList}
                    test_id_prefix={vehiclesTransportPassengerURI}
                />
            )}
            {uploadModalOpen && (
                <UploadModal
                    onClose={closeUploadModal}
                    isOpen={uploadModalOpen}
                    data={uploadFileError}
                    validationData={validation.data}
                    test_id_prefix={vehiclesTransportPassengerURI}
                />
            )}
        </>
    );
};

export default Vehicles;
