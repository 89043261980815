import React, { useState } from 'react';
import Modal from '../../../../common/Modal';
import titles from '../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { FormControl, TextField } from '@mui/material';
import { createCameraGroup, editCameraGroup } from '../../../../../redux/VideoCameras/actions';
import { useDispatch, useSelector } from 'react-redux';
import ModalSideBar from './ModalSideBar';
import SelectedCameras from './SelectedCameras';
import CameraMap from './CameraMap';

const ModalForm = ({ isNew, isOpen, onClose, el= {} }) => {
    const dispatch = useDispatch();

    const { videoCameraList } = useSelector(({ videoCameras }) => videoCameras);

    const initialState = {
        name: el.name || '',
        cameras: el.cameras || []
    };

    const [formData, setFormData] = useState(initialState);

    const onChange = (e) => {
        setFormData({
            ...formData,
            name: e.target.value
        });
    };

    const camerasForGroup = (item) => {
        const isSelect = formData.cameras.find(camera => camera.id === item.id);
        if(!isSelect) {
            setFormData({
                ...formData,
                cameras: [
                    ...formData.cameras,
                    item
                ]
            });
        }
    };

    const unselectCamera = (id) => {
        const result = formData.cameras.filter(item => item.id !== id);
        setFormData({
            ...formData,
            cameras: result
        });
    };

    const handleCreateCameraGroup = () => {
        dispatch(createCameraGroup(formData));
        onClose();
    };

    const handleEditCameraGroup = () => {
        dispatch(editCameraGroup(el.id, formData));
        onClose();
    };

    const isDisabled = !formData.name || !formData.cameras.length;

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            maxWidthProp={'lg'}
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    isNew
                        ? {
                            ...buttonsTypes.create,
                            onClick: handleCreateCameraGroup,
                            disabled: isDisabled
                        }
                        : {
                            ...buttonsTypes.save,
                            onClick: handleEditCameraGroup,
                            disabled: isDisabled
                        }
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl required className="block" size="small" variant="outlined">
                    <TextField
                        required
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={formData.name || ''}
                        name={'name'}
                        onChange={onChange}
                        type="text"
                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                    />
                </FormControl>
                <div>
                    {formData.cameras?.length > 0 
                        && <SelectedCameras 
                            data={formData.cameras}
                            unselectCamera={unselectCamera}
                        />}
                </div>
                <div style={{ display: 'flex' }}>
                    <ModalSideBar
                        formData={formData}
                        camerasForGroup={camerasForGroup} 
                    />
                    {(videoCameraList?.data?.length > 0 && formData.cameras?.length > 0) && (
                        <CameraMap
                            videoCameraList={videoCameraList}
                            formData={formData}
                            unselectCamera={unselectCamera}
                            camerasForGroup={camerasForGroup}
                            isNew={isNew}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ModalForm;
