import { useEffect } from 'react';
import useChangeValue from './useChangeValue';
import { useSelector } from 'react-redux';

// изменение проекта
const useChangeProject = (handleFunction = () => {}, depts =  []) => {
    const { project } = useSelector(({ auth }) => auth);
    const isChangeProject = useChangeValue(project);
    const isChangeDepts = useChangeValue(depts);

    useEffect(() => {
        if (isChangeProject || isChangeDepts) {
            handleFunction();
        }
    }, [isChangeProject, depts]);
    return null;
};

export default useChangeProject;
