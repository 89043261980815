import { useEffect, useState } from 'react';

import ToolTip from 'components/MapComponents/leaflet/components/ToolTip';
import Marker from 'components/MapComponents/leaflet/components/Marker';
import FeatureGroup from 'components/MapComponents/leaflet/components/FeatureGroup';
import Map from 'components/MapComponents/leaflet/components/Map';
import { config } from 'config';

const ShowOnMap = ({ latlon }) => {
    const [mapCenter, setMapCenter] = useState(config.get('mapCenter'));

    useEffect(()=> {
        if (latlon[0] === '' && latlon[1] === '') {
            setMapCenter(config.get('mapCenter'));
        } else {
            setMapCenter(latlon);
        }
    },[latlon]);

    return (
        <div className="map-form-wrap__container">
            <Map
                center={mapCenter}
                zoom={15}
            >
                <FeatureGroup>
                    {latlon
                        && <Marker latlng={latlon}>
                            <ToolTip
                                offset={[0, -40]}
                                permanent={true}
                                direction="top"
                            >
                                <div>
                                    <span style={{ display: 'flex', padding: 2 }}>
                                        <strong>Широта:&nbsp;</strong>
                                        {latlon[0]}
                                    </span>
                                    <span style={{ display: 'flex', padding: 2 }}>
                                        <strong>Долгота:&nbsp;</strong>
                                        {latlon[1]}
                                    </span>
                                </div>
                            </ToolTip>
                        </Marker>
                    }
                </FeatureGroup>
            </Map>
        </div>
    );
};

export default ShowOnMap;
