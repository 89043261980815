import { FormControl, TextField } from '@mui/material';

const BrigadeEmployees = ({ brigades, brigadeEmployees, setData }) => {

    const handleChangeEmployees = (value) => {
        setData(prev => ({
            ...prev,
            brigade_employees: prev.brigade_employees.find(item => item.id === value.id)
                ? prev.brigade_employees.map(item => item.id === value.id
                    ? { ...item,  ...value }
                    : item
                )
                : [ ...prev.brigade_employees, value]
        }));
    };

    return (
        <>
            <h2 style={{ marginBottom: 15 }}>Сотрудники бригады:</h2>

            {brigades.map((el, i) => (
                <div key={el.id} style={{ display: 'flex', alignItems: 'center' }}>
                    <h2 style={{ marginRight: 30 }}>{i+1}</h2>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <FormControl className="block" size="small" variant="outlined">
                            <TextField
                                label="Название бригады"
                                size="small"
                                value={el.name}
                                variant="outlined"
                                disabled={true}
                                type="text"
                            />
                        </FormControl>
                        {el.employees?.length > 0
                            ? el.employees.map((item, i) => (
                                <FormControl key={item.id} className="block" size="small" variant="outlined">
                                    <TextField
                                        label="Сотрудник"
                                        size="small"
                                        value={item.first_name}
                                        variant="outlined"
                                        disabled={true}
                                        type="text"
                                    />
                                    <TextField
                                        label="Табельный номер"
                                        size="small"
                                        value={item.personnel_number}
                                        variant="outlined"
                                        disabled={true}
                                        type="text"
                                    />
                                    <TextField
                                        label="Удостоверение"
                                        size="small"
                                        value={item.identification}
                                        variant="outlined"
                                        disabled={true}
                                        type="text"
                                    />
                                    <TextField
                                        label="Билето-учетный лист"
                                        size="small"
                                        value={brigadeEmployees[i]?.ticket_checklist}
                                        onChange={(e) => handleChangeEmployees({
                                            id: i,
                                            personnel_number: item.personnel_number,
                                            identification: item.identification,
                                            ticket_checklist: e.target.value
                                        })}
                                        variant="outlined"
                                        name="ticket_checklist"
                                        type="text"
                                    />
                                </FormControl>
                            ))
                            : ''
                        }
                    </div>
                </div>
            ))}
        </>
    );
};

export default BrigadeEmployees;