import iconCluster from './icons/iconCluster';

const config = {
    name: 'ДИТ и ЗПИ',
    slug: 'board',
    mapContextMenu: {
        title: 'Добавить ДИТ и ЗПИ',
        event: 'add_dit',
        // className: styles.button,
        minWidth: 240,
    },
    mapMarkersColors: { // от статуса маркера
        default: '#ad1818',
        1: '#ad1818', // Выключен
        2: '#00ae5c', // Включен
        3: '#1936a0', // Сервисный режим
    },
    // todo потом переместить в метод список категорий
    categoryIcon: {
        1: 'fas fa-border-none',// Дит
        2: 'far fa-directions', // Зпи
    },
    clusterIcon: iconCluster,
    layerIcon: 'fas fa-border-none',
};
// добавление на карте
export default config;
