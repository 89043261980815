import React, { memo, useEffect, useState } from 'react';
import {
    Map,
    FeatureGroup,
    CustomControl
} from '../../../../MapComponents/leaflet';
import { config } from '../../../../../config';
import IntervalMileageModal from '../IntervalMileageModal';
import ConfirmDelete from '../../../ConfirmModal';
import makeStyles from '@mui/styles/makeStyles';
import Stations from './Stations';
import createPointGJ from '../../../../MapComponents/leaflet/helpers/createPointGJ';
import MapDragGeoJson from '../../../../MapComponents/leaflet/components/geoJSON/MapDragGeoJson';
import MapMinimize from '../../../MapContlols/MapMinimize';
import MapFinder from '../../../MapContlols/MapFinder';


const useStyles = makeStyles({
    mapIcon: {
        backgroundColor: 'inherit'
    }
});

const MapFromModal = ({
    points,
    setPoints,
    road,
    setRoad,
}) => {
    const styles = useStyles();
    const [mapCenter] = useState(config.get('mapCenter'));
    const [deletedItem, setDeletedItem] = useState(null);
    const [editedItem, setEditedItem] = useState(null);
    const [isInit, setIsInit] = useState(false);

    useEffect(() => {
        setTimeout(() => setIsInit(true), 1000);

        return () => setIsInit(false);
    }, []);

    const handleChangeRoad = (newRoad) => {
        setRoad?.(newRoad);
    };

    const handleChangeItemInfo = (value) => {
        const itemInList = points.find(item => item.number === value.number);
        const newData = itemInList
            ? points.map(item => {
                if (item.number === value.number){
                    return value;
                }
                return item;
            })
            : [
                ...points,
                {
                    ...value,
                    number: points.length + 1
                }
            ];

        setPoints(newData);
    };

    // добавление остановки
    const onAddStation = (item) => {
        const newStation = {
            // ...item,
            station: {
                ...item
            },
            check_point_id: item?.check_point_id,
            number: points.length + 1,
            interval: '00:00',
            mileage: 0,
        };
        delete newStation?.id;

        const newData = [
            ...points,
            newStation
        ];

        setPoints(newData);
    };

    // удаляем остановку или кт
    const handleDelete = (index) => {
        if (index >= 0) {
            const newData = [
                ...points.slice(0, index),
                ...points
                    ?.slice(index + 1)
                    ?.map((item, i) => ({
                        ...item,
                        // восстанавливаем порядковые номера
                        number: index + i + 1
                    })),
            ];
            setPoints(newData);

            // если мало точек - очищаем маршрут
            if (newData?.length <= 1) setRoad?.({});
        }
    };

    // +
    const handleAddCheckPoint = (latlon) => {
        const { lat, lon } = latlon;
        const newCheckPoint = {
            check_point: {
                lat,
                lon,
                geometry: createPointGJ(lat, lon),
                name: '',
            },
            interval: null,
            mileage: 0,
        };
        setEditedItem(newCheckPoint);
    };

    // изменение кт
    const handleDragEnd = (item, newLatLng) => {
        const { lat, lon } = newLatLng;

        const newData = {
            ...item,
            check_point: {
                ...item.check_point,
                lat,
                lon,
                geometry: createPointGJ(lat, lon),
            },
        };

        handleChangeItemInfo(newData);
        // setEditedItem(newData);
    };

    // поиск на карте
    const RightSearch = (prop) => {
        return (
            <CustomControl
                {...prop}
                position="topright"
            >
                <MapMinimize>
                    <MapFinder />
                </MapMinimize>
            </CustomControl>
        );
    };

    return(<>
        {!!editedItem
            && <IntervalMileageModal
                isOpen={!!editedItem}
                onClose={() => setEditedItem(null)}
                onAccept={(val) => {
                    handleChangeItemInfo(val);
                    setEditedItem(null);
                }}
                item={editedItem}
            />
        }
        {deletedItem !== null
            && <ConfirmDelete
                open={deletedItem !== null}
                onSuccess={() => {
                    const result = points.filter(item => item.number !== deletedItem);
                    setPoints(result);
                    setDeletedItem(null);
                }}
                onClose={() => setDeletedItem(null)}
            />
        }
        <div
            className={styles.map_container}
            style={{ minWidth: '60%', minHeight: '400px', height: '100%', marginRight: 20 }}
        >
            <Map
                center={mapCenter}
                zoom={10}
                // onClick={clickOnMap}
            >

                {/* редактирование маршрута */}
                <MapDragGeoJson
                    geometry={road}
                    onChange={handleChangeRoad}
                    // readOnly={readOnly}
                    polyline={false}
                    polygon={false}
                    marker={false}
                    showDeleteButton={false}
                    centeredAfter={!isInit}
                    style={() => ({ color: '#008a02', weight: 5 })}
                />

                {/* остановки и кт */}
                <FeatureGroup>
                    <Stations
                        selected={points}
                        onAddStation={onAddStation}
                        onAddCheckPoint={handleAddCheckPoint}
                        onDragEndCheckPoint={handleDragEnd}
                        onDelete={handleDelete}
                    />
                </FeatureGroup>

                <RightSearch/>
            </Map>
        </div>
    </>
    );
};

export default memo(MapFromModal);