import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteScheduleEvent } from '../../../../../redux/TransportPassenger/actions';
import { IconButton, ListItem, ListItemText, Tooltip } from '@mui/material';
import ConfirmDelete from '../../../../common/ConfirmModal';
import titles from '../../../../../helpers/constants/titles';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import ModalAddEdit from './ModalAddEdit';

function Item({ data }) {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const onAccept = () => dispatch(deleteScheduleEvent(data?.id));

    return (
        <ListItem className="list_item">
            {confirmOpen
                && <ConfirmDelete
                    onClose={() => setConfirmOpen(false)}
                    onSuccess={onAccept}
                    open={confirmOpen}
                />
            }
            {modalOpen
                && <ModalAddEdit
                    onClose={() => setModalOpen(false)}
                    isOpen={modalOpen}
                    data={data}
                />
            }
            <ListItemText>
                <b>{data?.name}</b>
            </ListItemText>
            <Tooltip title={titles.EDIT}>
                <IconButton onClick={() => setModalOpen(true)} size="small">
                    <Edit fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Tooltip title={titles.DELETE}>
                <IconButton
                    onClick={() => setConfirmOpen(true)}
                    size="small"
                >
                    <Delete fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </ListItem>
    );
}

export default Item;