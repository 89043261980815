import React from 'react';
import Positions from '../../../../common/Transport/Positions';

const PositionList = () => {
    return (
        <Positions 
            storeName="transportPassenger"
        />
    );
};

export default PositionList;