import React, { useState } from 'react';
import Filter from './Filter';
import { ContextMenu, ContextMenuItem, FeatureGroup, Map, MapControl } from '../../../MapComponents/leaflet';
import { config } from '../../../../config';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../redux/RoadNetwork/actions';
import OverlapLayer from '../../../MapComponents/Layers/Overlap/layer';
import Subscribe from './Subscribe';


// overlap
const Overlap = () => {
    const dispatch = useDispatch();

    const [visible, setVisible] = useState(false);
    const [subscribeLatLon, setSubscribeLatLon] = useState(null);

    return (
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
            <h1>Перекрытие движения</h1>

            <Filter
                onChange={(filter) => {
                    // установка фильтров
                    // Перекрытия
                    dispatch(actions.setFilterOverlap({ ...filter }));
                    setVisible(true);
                }}
            />

            {visible && (
                <div style={{ display: 'contents', minHeight: '400px' }}>
                    <Map center={config.get('mapCenter')}>
                        <MapControl>
                            {/* ДТП */}
                            {/*<IncidentsLayer readOnly isTrafficBlocking />*/}

                            {/* Дорожные работы */}
                            {/*<RoadWorks readOnly hideEmpty />*/}

                            {/* Перекрытие движения */}
                            {/*<Layer />*/}

                            {/* внутри все слои и применения фильтров */}
                            <FeatureGroup>
                                <OverlapLayer />
                            </FeatureGroup>

                            <ContextMenu
                                onClick={(latlon) => {
                                    setSubscribeLatLon(latlon);
                                }}
                            />

                            {/*<ContextMenu>*/}
                            {/*    <ContextMenuItem*/}
                            {/*        value="Подписка"*/}
                            {/*        onClick={(e) => {*/}
                            {/*            // console.log('ee', e);*/}
                            {/*            setSubscribeLatLon(e.latlng);*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</ContextMenu>*/}

                        </MapControl>
                    </Map>

                    {subscribeLatLon && (
                        <Subscribe
                            show={!!subscribeLatLon}
                            onClose={() => setSubscribeLatLon(null)}
                            latlon={subscribeLatLon}
                        />
                    )}
                </div>
            )}

        </div>
    );
};

export default Overlap;
