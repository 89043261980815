const countChanges = (current, prev, countException = []) => {
    return Object.entries(current).reduce((result, [key, value]) => {
        if ((JSON.stringify(prev[key]) !== JSON.stringify(value)) && countException.indexOf(key) < 0) {
            result += 1;
        }
        return result;
    }, 0);
};

export default countChanges;
