import React from 'react';
import types from './types';
import colors from './colors';
import Line from './components/Line';
import Pie from './components/Pie';
import Doughnut from './components/Doughnut';
import Bar from './components/Bar';
import Grid from './components/Grid';
import Table from './components/Table';
import Filter from './components/Filter';

const TypeComponent = ({
    type = '',
    unit = '',
    data = {},
    isStacked = false,
    indexColor = 0,
    labelFormat,
    flexStyle = false,
    ...props
}) => {
    switch (type) {
        // линейный грфик
        case types.LINE:
            return <Line unit={unit} color={colors?.[indexColor]} data={data} labelFormat={labelFormat} displayLabels={props?.displayLabels} />;
        // круглый график
        case types.PIE:
            return <Pie data={data} />;

        // вертикальный бар
        case types.VERTICAL_BAR:
            return <Bar data={data} isStacked={isStacked} displayLabels={props?.displayLabels} />;

            // горизонтальный бар
        case types.HORIZONTAL_BAR:
            return <Bar data={data} horizontal={true} isStacked={isStacked} displayLabels={props?.displayLabels} />;

        // таблица
        case types.TABLE:
            return <Table data={data} />;

        // грид
        case types.GRID:
            return <Grid data={data} />;

        // пончик
        case types.DOUGHNUT:
            return <Doughnut data={data} customLegend flexStyle={flexStyle} />;

        case types.FILTER:
            return <Filter data={data} {...props} />;

        // нет такого
        default:
            return null;
            // return <div>Данного графика не найдено</div>;
    }
};

export default TypeComponent;
