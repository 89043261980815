import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import { useDispatch, useSelector } from 'react-redux';
import { saveCurrentProject, saveUserLastProject, saveAuthUserData } from '../../../../redux/Auth/actions';
import { changeMapCenter, saveLastMapCenter } from '../../../../redux/Map/actions';
import { setSelectedLayer, saveActiveLayer, addAttachLayers } from '../../../../redux/Menu/actions';
import { IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { config } from '../../../../config';
import messages from '../../../../helpers/constants/messages';
import { useHistory } from 'react-router-dom';
import Loading from '../../Loading';
import { authSelectors } from '../../../../redux/Auth';
import cn from 'classnames';

const useStyles = makeStyles({
    icon: {
        color: 'rgba(0,0,0, 0.54)',
    },
});

// компонент смены региона
const SelectLocation = () => {
    const systemName = config.getGeneral('systemName');
    const dispatch = useDispatch();
    const classes = useStyles();

    const loading = useSelector(authSelectors.loading);
    const list = useSelector(authSelectors.services);
    const project = useSelector(authSelectors.project);
    
    const currentService = list?.find((item) => item.service_name === systemName) || {};
    const [anchorEl, setAnchorEl] = useState(null);

    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeProject = (current) => {
        // страсываем слои и url
        history.replace({ pathname: '/', search: '', state: {} });
        dispatch(setSelectedLayer());
        dispatch(saveActiveLayer());
        dispatch(addAttachLayers());

        // сохраняем проект в стор
        dispatch(saveCurrentProject(current));
        // сохраняем новый проект юзеру
        dispatch(saveUserLastProject(current));
        // и обновляем данные в нашем авторизованном юзере
        const service_last = { service_last: { [systemName]: { project: current?.code } } };
        dispatch(saveAuthUserData(service_last));
        // меняем центр карты
        const { lat, lon } = current;
        //dispatch(changeMapCenter([lat, lon], 10));
        dispatch(changeMapCenter(config.get('mapCenter'), config.get('mapMinZoom')));
        // очистить последние координаты
        dispatch(saveLastMapCenter());
    };

    return (
        <>
            <IconButton
                style={{ width: 28, marginLeft: 11 }}
                onClick={handleClick}
                size="small"
                disabled={currentService?.entity_list?.length <= 1}
            >
                <i className={cn('fal fa-map-marker-alt', classes.icon)} />
            </IconButton>

            <Popover
                sx={{ mt: '10px' }}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClick={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {loading
                    ? <Loading circular={true}/>
                    : (
                        <div>
                            {currentService.entity_list ? (
                                currentService.entity_list.map((item) => (
                                    <MenuItem
                                        value={item.id}
                                        key={item.id}
                                        onClick={() => changeProject(item)}
                                        disabled={item.code === project.code}>
                                        {item.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem>{messages.DATA_IS_NOT_FOUND}</MenuItem>
                            )}
                        </div>
                    )}
            </Popover>
        </>
    );
};

export default SelectLocation;