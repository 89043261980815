import React, { useEffect, useState } from 'react';
import {
    Tooltip,
    Checkbox,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    Typography,
    Icon,
} from '@mui/material';

const SortCheckItem = ({
    field,
    onChange,
    checkedItems = {},
    disabled,
}) => {
    const [showCheck, setShowCheck] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (disabled === true) {
            setAnchorEl(null);
            setShowCheck(false);
        }
    }, [disabled]);

    const handleOpen = (event) => {
        if (disabled === false) {
            setAnchorEl(event.currentTarget);
            setShowCheck(!showCheck);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setShowCheck(!showCheck);
    };

    const selectedDirection = field?.sort?.find(el => el.name === checkedItems.direction);

    const iconClass = (direction) => ({
        'desc': 'fas fa-long-arrow-alt-down',
        'asc': 'fas fa-long-arrow-alt-up',
    })
        [direction]
        || 'fas fa-sort-alt';

    return (
        <div className="item">
            <Typography
                variant="subtitle2"
                component="span"
            >
                {field?.name_text || ''}
            </Typography>

            <IconButton
                onClick={handleOpen}
                size="small"
                disabled={disabled}
                color="primary"
            >
                <Tooltip title={selectedDirection?.name_text || 'Выбрать сортировку'}>
                    <Icon
                        className={iconClass(checkedItems?.direction)}
                        fontSize="small"
                    />
                </Tooltip>
            </IconButton>

            <Popover
                open={!!showCheck}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List disablePadding>
                    {field.sort.map((item, sortIndex) => (
                        <ListItem
                            key={sortIndex}
                            button
                            onClick={() => onChange(item.name)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={item.name === checkedItems.direction}
                                    disableRipple
                                    size="small"
                                />
                            </ListItemIcon>
                            <ListItemText>
                                {item.name_text}
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Popover>

        </div>
    );
};

export default SortCheckItem;