import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getHashString from '../getHashString';

const mutex = {};

// получить поле из стора с загрузкой данных
const useStoreFromSelector = (
    // запрос на получение данных
    fetchAction,
    // селектор
    selector,
) => {
    const dispatch = useDispatch();
    const storeField = useSelector(selector);
    const selectorHash = useMemo(() => getHashString(selector.toString()), [selector]);

    useEffect(() => {
        // mutex[selectorHash] = mutex[selectorHash] || false;
        if (
            (
                (Array.isArray(storeField) && storeField?.length === 0)
                || (typeof storeField === 'object' && Object.keys(storeField).length === 0)
            )
            && !mutex[selectorHash]
        ) {
            mutex[selectorHash] = true; // для предотвращения бесконечных запросов если данных нет
            dispatch(fetchAction());
        }
    }, [storeField, dispatch, fetchAction, selectorHash]);

    useEffect(() => {
        return () => {
            mutex[selectorHash] = false;
        };
    }, []);

    return storeField;
};

export default useStoreFromSelector;
