import React, { useEffect, useState } from 'react';
import DateRange from '../../../common/Dates/DateRange';
import SelectCameras from '../../../common/Autocomplete/CameraVideoanalytics';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterCameraObjects } from '../../../../redux/VideoAnalytics/actions';
import { isEqual } from 'lodash';
import { videoAnalyticsSelectors } from '../../../../redux/VideoAnalytics';
import LayoutFilter from '../../../layout/PageLayout/LayoutFilter';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';

const Filter = () => {
    const dispatch = useDispatch();
    const filterCameraObjects = useSelector(videoAnalyticsSelectors.filterCameraObjects);

    const initialState = {
        start_date: null,
        end_date: null,
        cameras: [],
        format: '',
    };

    const [formValues, setFormValues] = useState({
        ...initialState,
        ...filterCameraObjects,
    });

    useEffect(() => {
        if (
            Object.keys(filterCameraObjects).length > 0
            && !isEqual(filterCameraObjects, initialState)
        ) {
            setFormValues({
                ...formValues,
                ...filterCameraObjects,
            });
        }
    }, [filterCameraObjects]);

    const onChange = (name, value) => {
        setFormValues({ ...formValues, [name]: value });
    };

    const resetFilters = (needRefresh) => {
        if (needRefresh) {
            dispatch(setFilterCameraObjects(initialState));
        }
        setFormValues(initialState);
    };

    const search = () => {
        dispatch(setFilterCameraObjects(formValues));
    };

    const uploadFilter = (filters) => {
        setFormValues({ ...initialState, ...filters });
        if (Object.keys(filters).length > 0) {
            dispatch(setFilterCameraObjects(removeEmptyFields({ ...initialState, ...filters }, false)));
        }
    };

    const isDisabled = !(formValues?.start_date || formValues?.end_date || formValues?.cameras?.length > 0);

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={search}
            filter={formValues}
            setUserFilter={uploadFilter}
            filterException={['start_date', 'end_date']}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <div className="row noMargin">
                    <DateRange
                        valueStartDate={formValues.start_date}
                        valueEndDate={formValues.end_date}
                        handleDate={(value) => onChange('start_date', value )}
                        handleEndDate={(value) => onChange('end_date', value)}
                    />
                </div>
                <SelectCameras
                    multiple
                    selected={formValues.cameras}
                    onChange={(value) => onChange('cameras', value)}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
