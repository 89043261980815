import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { useReactToPrint } from 'react-to-print';
import * as actionsPassenger from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../common/Loading';
import { Scrollbars } from 'react-custom-scrollbars';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    LinearProgress,
} from '@mui/material';
import messages from '../../../../../helpers/constants/messages';
import GetReports from '../../../../common/GetReports';
import { getHumanDate } from '../../../../../helpers/date.config';

const Card = (props) => {
    const {
        item: data,
        onClose,
        isOpen = false,
    } = props;

    const organizationInformation = useSelector(transportPassengerSelectors.organizationInformation);
    const loadingOrganizationInformation = useSelector(transportPassengerSelectors.loadingOrganizationInformation);

    const dispatch = useDispatch();
    const currentDate = new Date();

    useEffect(() => {
        dispatch(actionsPassenger.loadOrganizationInformation(data.id));
    }, [data]);

    useEffect(() => {
        return () => {
            dispatch(actionsPassenger.loadedOrganizationInformation({}));
        };
    }, []);

    const printContent = useRef();

    const handlePrints = useReactToPrint({
        content: () => printContent.current,
        pageStyle: `
            @media print {
                .pagebreak {
                    page-break-before: auto;
                }
            }   
        `,
        // onAfterPrint: () => setOpenPrint(false)
    });

    // сохранить в файл
    const [selectedReportFormats, setSelectedReportFormats] = useState([]);
  
    const getReport = (formats) => {
        dispatch(actionsPassenger.loadOrganizationInformation(data.id, 9, formats));
    };

    // печать
    const handlePrint = () => {
        handlePrints();
    };

    const fields = {
        company: {
            // data: {
            //     name: 'Дата',
            //     f: () => getHumanDate(currentDate)
            // },
            // title: {
            //     name: 'Организация',
            // },
            inn: {
                name: 'ИНН',
            },
            juristic_address: {
                name: 'Юридический адрес',
                style: { marginTop: '1rem' },
                keys: {
                    postal_code: {
                        name: 'Индекс'
                    },
                    region: {
                        name: 'Область'
                    },
                    city_name: {
                        name: 'Город'
                    },
                    street: {
                        name: 'Улица'
                    },
                    house: {
                        name: 'Дом'
                    }
                }
            },
        },

        filials: {
            short_title: {
                name: 'Название'
            },
            address: {
                name: 'Адрес',
                keys: {
                    region: {
                        name: 'Область'
                    }
                    ,
                    city_name: {
                        name: 'Город'
                    }
                    ,
                    postal_code: {
                        name: 'Индекс'
                    }
                    ,
                    street: {
                        name: 'Улица'
                    }
                    ,
                    house: {
                        name: 'Дом'
                    }
                }
            }
        },

        // перечень тс
        vehicles: {
            category: {
                name: 'Категория'
            },
            mark: {
                name: 'Марка'
            },
            model: {
                name: 'Модель'
            },
            count: {
                name: 'Количество'
            }
        }
    };

    const renderFields = (fields, current, prevKey = '') => (
        <>
            {Object.keys(fields).map((key, index, arr) => {
                const field = fields[key];

                if (field) {
                    const value = field.hasOwnProperty('f') ? field.f(current) : current[key];
                    const style = field.hasOwnProperty('style') ? field.style : {};

                    if (field.hasOwnProperty('keys')) {
                        return (
                            <React.Fragment key={`${prevKey}_${key}`}>
                                <div style={{ ...style, marginBottom: '.5rem' }}>
                                    <b>{field?.name || ''}</b>
                                </div>

                                {renderFields(field?.keys, current[key], `${prevKey}_${key}`)}
                            </React.Fragment>
                        );
                    }

                    return (prevKey === '_juristic_address'
                        ?  <span key={`${prevKey}_${key}`}>{value || messages.NO_DATA}{arr.length - 1 === index ? '' : ', '} </span>
                        : <div key={`${prevKey}_${key}`} style={{ ...style, marginBottom: '.5rem' }}>
                            <b>{field?.name || ''}: </b>
                            <span>{value || messages.NO_DATA}</span>
                        </div>
                    );
                }
            })}
        </>
    );

    const renderVehicles = (fields, data) => (
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    {Object.keys(fields).map((key) => (
                        <TableCell key={`column_${key}`}>{fields[key].name}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.map((item, index) => (
                    <TableRow key={`row_${index}`}>
                        {Object.keys(fields).map((key) => {
                            return (
                                <TableCell key={`${index}_${key}`}>
                                    {key === 'model' ? (item[key]?.name_ru || item[key]?.name_en) : item[key]}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );

    const isDisabled = loadingOrganizationInformation || Object.keys(organizationInformation).length === 0;

    const getTitleFunc = () => (
        <>
            Учетная карточка организации
            <div style={{ fontSize: 15 }}>
                {data.title} 
                <div>Дата: {getHumanDate(currentDate)}</div>
            </div>
        </>
    );

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            // noPadding
            title={getTitleFunc()}
            medium
            buttons={
                <>
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.defaultPrimary,
                                disabled: isDisabled,
                                name: 'Печать',
                                onClick: handlePrint
                            },
                            {
                                ...buttonsTypes.close,
                                onClick: onClose
                            },
                        ]}
                    />
                </>                
            }
        >
            {loadingOrganizationInformation && Object.keys(organizationInformation).length === 0
                ? <div style={{ textAlign: 'center' }}><Loading /></div>
                : Object.keys(organizationInformation).length > 0 ? (
                    <Scrollbars
                        style={{ height: '240px' }}
                    >
                        <div ref={printContent}>
                            {organizationInformation.hasOwnProperty('organization') && (
                                <div style={{ marginBottom: '1rem' }}>
                                    {renderFields(fields.company, organizationInformation.organization)}
                                </div>
                            )}

                            {organizationInformation.hasOwnProperty('filiations') && organizationInformation.filiations.length > 0 && (
                                <>
                                    {organizationInformation?.filiations?.map((filiation) => (
                                        <div style={{ marginBottom: '1rem' }} key={filiation.id}>
                                            <div style={{ marginBottom: '.5rem' }}><b>Филиал</b></div>
                                            {renderFields(fields.filials, filiation)}
                                        </div>
                                    ))}
                                </>
                            )}

                            {organizationInformation.hasOwnProperty('vehicles') && organizationInformation.vehicles.length > 0 && (
                                <div style={{ marginBottom: '1rem' }}>
                                    <div style={{ marginBottom: '.2rem' }}><b>Перечень ТС</b></div>
                                    {renderVehicles(fields.vehicles, organizationInformation?.vehicles)}
                                </div>
                            )}
                        </div>
                    </Scrollbars>
                ) : <div>{messages.DATA_IS_NOT_FOUND}</div>
            }
            <GetReports 
                onDownload={getReport}
                selected={selectedReportFormats}
                onChange={setSelectedReportFormats}
                reportId={9}                   
            />
            {loadingOrganizationInformation && Object.keys(organizationInformation).length > 0 && (
                <LinearProgress />
            )}
        </Modal>
    );
};

export default Card;
