import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, Autocomplete, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import UniversalSelect from '../../../../common/UniversalSelect';
import { loadCurrentVehicleType, saveSelectedVehicleToRoute } from '../../../../../redux/TransportPassenger/actions';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import titles from '../../../../../helpers/constants/titles';
import Modal from '../../../../common/Modal';

const AssignVehicleModal = ({ type, routeNum, isOpen, onClose, currentVehiclesList = [] }) => {
    const dispatch = useDispatch();

    const [selectedVehicle, setSelectedVehicle] = useState([]);
    const [showList, setShowList] = useState(false);

    useEffect(() => {
        setSelectedVehicle(currentVehiclesList);
    }, [currentVehiclesList]);

    const autoVehicleChange = (e, value) => {
        if (e?.key !== 'Enter') setSelectedVehicle(value);
    };

    const handleVehicleChange = (e) => {
        setSelectedVehicle(e);
        setShowList(false);
    };

    const getVehiclesList = (params) => {
        const { page, limit, query } = params;
        dispatch(loadCurrentVehicleType(page, limit, query , type ));
    };

    const handleSendVehicle = () => {
        const sortVehicle = selectedVehicle.reduce((acc, item) => {
            return { ...acc, [item.uuid]: routeNum };
        }, {});
        dispatch(saveSelectedVehicleToRoute(sortVehicle, routeNum));
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={titles.SELECT_VEHICLE}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSendVehicle,
                    }
                ]}
            />}
        >
            <Autocomplete
                multiple
                value={selectedVehicle || []}
                options={[]}
                filterSelectedOptions={true}
                getOptionLabel={(option) => option.number}
                freeSolo={true}
                size="small"
                disabledItemsFocusable={true}
                className="block"
                onChange={autoVehicleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Номер транспорта"
                        disabled
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position={'end'} >
                                    {selectedVehicle && Object.keys(selectedVehicle).length > 0 
                                            && <IconButton size="small" onClick={() => setSelectedVehicle([])}>
                                                <i className="far fa-times"/>
                                            </IconButton>
                                    }
                                    <IconButton  size="small" onClick={() => setShowList(true)}>
                                        <i className="fas fa-search-plus"/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                )}
            />
            {showList && <UniversalSelect
                storeName={'transportPassenger'}
                storeNameProps={'currentVehicleType'}
                keyProp={'uid'}
                fetchList={getVehiclesList}
                withSearch={true}
                multiple={true}
                isSelected={true}
                onAccept={handleVehicleChange}
                selected={selectedVehicle}
                renderProps={(el) => <div><span>{el.number}</span></div>}
                isOpen={showList}
                onClose={() => setShowList(false)}
                title={titles.SELECT_VEHICLE}
            />}
        </Modal>
    );
};

export default AssignVehicleModal;
