import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemText, Typography } from '@mui/material';

import { deleteThreatLevel } from 'redux/SituationalPlans/actions';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';

import ModalForm from './ModalForm';

const Item = ({ item }) => {
    const dispatch = useDispatch();
    const id = item?.id;

    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        dispatch(deleteThreatLevel(id));
        setIsDelete(false);
    };

    return (
        <div className="list_item">
            <ListItem>
                <ListItemText>
                    <Typography>
                        <i
                            className="fas fa-square-full"
                            style={{ color: item?.color }}>
                        </i>&nbsp;
                        {item?.name}
                    </Typography>
                </ListItemText>
            </ListItem>
            {isEditMode
            && <ModalForm
                isOpen={isEditMode}
                onClose={setIsEditMode}
                item={item}
                isNew={false}
            />
            }
            {isDelete
            && <ConfirmDelete
                open={isDelete}
                onClose={() => setIsDelete(false)}
                onSuccess={handleDelete}
                message={messages.CONFIRM_DELETE}
            />
            }
        </div>
    );
};

export default Item;
