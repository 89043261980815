import ConfirmDelete from '../../../../../../common/ConfirmModal';
import messages from '../../../../../../../helpers/constants/messages';
import { IconButton, ListItemText, Tooltip } from '@mui/material';
import titles from '../../../../../../../helpers/constants/titles';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import UniversalSelect from '../../../../../../common/UniversalSelect';
import { getCompaniesForAllRoles, loadCompanies } from '../../../../../../../redux/Companies/actions';
import { useDispatch, useSelector } from 'react-redux';
import { companiesSelectors } from '../../../../../../../redux/Companies';

const ListRender = ({
    item,
    index,
    onChange,
    data,
}) => {
    const dispatch = useDispatch();

    const companies = useSelector(companiesSelectors.companies);

    const [indexOfOperation, setIndexOfOperation] = useState('');
    const [indexOfDeletedElement, setIndexOfDeletedElement] = useState('');
    const [isUniversalSelectOrganizationOpen, setIsUniversalSelectOrganizationOpen] = useState(false);
    const [isDeleteOperationModalOpen, setIsDeleteOperationModalOpen] = useState(false);

    const addOrganizationHandler = (index) => {
        setIndexOfOperation(index);
        setIsUniversalSelectOrganizationOpen(true);
    };

    const handleOrganizationUniversalSelect = (value, keyName) => {
        const currentOperation = data[keyName].find((org, index) => index === Number(indexOfOperation));

        onChange(keyName, [
            ...data[keyName].slice(0, indexOfOperation),
            {
                ...currentOperation,
                organization_id: value.id,
                organization_name: value.title,
            },
            ...data[keyName].slice(indexOfOperation + 1)
        ]);

        setIndexOfOperation('');
        setIsUniversalSelectOrganizationOpen(false);
    };

    const deleteOperationHandler = (index) => {
        setIndexOfDeletedElement(index);
        setIsDeleteOperationModalOpen(true);
    };

    const deleteOperation = () => {
        const newOperations = data.operation_list.filter((el, index) => index !== Number(indexOfDeletedElement));

        onChange('operation_list', newOperations);

        setIndexOfDeletedElement('');
        setIsDeleteOperationModalOpen(false);
    };

    useEffect(() => {
        if (item.organization === null && item.organization_id) {
            dispatch(loadCompanies(1, 100, { id_list: [item?.organization_id], withDeleted: 1 }));
        }
    }, [dispatch, item.organization, item.organization_id]);

    const deletedOrganization = companies?.data?.find(company => item.organization_id === company.id);

    return (
        <>
            <ListItemText>
                <div>
                    <b>Операция:</b> {item?.name}
                </div>
                <div>
                    <b>Организация: </b>
                    {item?.organization_name
                        || item?.organization?.title
                        || deletedOrganization?.title
                        || <span style={{ color: 'red' }}>Выберите организацию</span>
                    }
                </div>
            </ListItemText>

            {!item?.organization_id
                ? (
                    <Tooltip title={titles.ADD_ORGANIZATION}>
                        <IconButton onClick={() => addOrganizationHandler(index)} size={'small'}>
                            <i className="fas fa-plus"/>
                        </IconButton>
                    </Tooltip>
                )
                : (
                    <Tooltip title={titles.CHANGE_ORGANIZATION}>
                        <IconButton onClick={() => addOrganizationHandler(index)} size={'small'}>
                            <i className="fas fa-exchange-alt"/>
                        </IconButton>
                    </Tooltip>
                )
            }

            <Tooltip title={titles.DELETE}>
                <IconButton onClick={() => deleteOperationHandler(index)} size={'small'}>
                    <DeleteIcon fontSize={'inherit'}/>
                </IconButton>
            </Tooltip>

            {isUniversalSelectOrganizationOpen && (
                <UniversalSelect
                    fetchList={({ page, limit, query }) => dispatch(getCompaniesForAllRoles(page, limit, { query }))}
                    storeName={'companies'}
                    storeNameProps={'companies'}
                    onAccept={(value) => handleOrganizationUniversalSelect(value, 'operation_list')}
                    keyProp={'id'}
                    withSearch={true}
                    isSelected={true}
                    selected={{
                        id: data.operation_list[indexOfOperation]?.['organization_id']
                    }}
                    renderProps={(el) => <div><span>{el.title}</span></div>}
                    isOpen={isUniversalSelectOrganizationOpen}
                    onClose={() => setIsUniversalSelectOrganizationOpen(false)}
                    noPadding={true}
                    title={titles.ADD}
                    small={true}
                />
            )}

            {isDeleteOperationModalOpen && (
                <ConfirmDelete
                    open={isDeleteOperationModalOpen}
                    onClose={() => setIsDeleteOperationModalOpen(false)}
                    onSuccess={deleteOperation}
                    message={messages.CONFIRM_DELETE}
                />
            )}
        </>
    );
};

export default ListRender;