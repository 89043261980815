import { ListItem, ListItemText, } from '@mui/material';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import messages from 'helpers/constants/messages/common';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { useStyles } from './DtpList';

const DtpListItem = ({
    item,
    index,
    draggable,
    canEdit,
    canRemove,
    onDeleteItem,
    onChangeItem,
}) => {
    const styles = useStyles();

    const renderItemText = () => (
        <ListItemText disableTypography className={styles.row}>
            <span style={{ minWidth: 16 }}>
                {index + 1}
            </span>
            <span className={styles.item}>
                {item?.id}
            </span>
            <span className={styles.item}>
                {fullDateTimeWithoutSeconds(item?.dtp_at)}
            </span>
            <span className={styles.item}>
                {item?.dtp_type_name}
            </span>
            <span className={styles.item}>
                {item?.address_text || messages.NO_DATA}
            </span>
        </ListItemText>
    );

    return (
        <>
            {draggable
                ? renderItemText()
                : (
                    <ListItem
                        className={styles.item_text}
                        selected={index % 2 === 0}
                        disableGutters
                    >
                        {renderItemText()}
                    </ListItem>
                )
            }
            <FormButtonsComponent
                noMarginLeft
                justButton
                buttons={[
                    ...(canEdit
                        ? [{
                            ...buttonsTypes.editIcon,
                            onClick: onChangeItem,
                        }]
                        : []
                    ),
                    ...(canRemove
                        ? [{
                            ...buttonsTypes.deleteIcon,
                            onClick: onDeleteItem
                        }]
                        : []
                    )
                ]}
            />
        </>
    );
};

export default DtpListItem;
