import { useDispatch } from 'react-redux';
import type { Dispatch, SetStateAction } from 'react';

import { clearDtpByTime } from 'redux/Incidents/actions';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import { convertDateToTimestamp, isDateValid } from 'helpers/date.config';

import type { FilterValues } from './types';

interface Filter {
    initialState: FilterValues,
    setParams: Dispatch<SetStateAction<FilterValues>>,
    onSearch: (filters: FilterValues) => void;
    onReset: () => void;
    params: FilterValues;
}

const Filters = ({ initialState, setParams, onSearch, onReset, params }: Filter) => {
    const dispatch = useDispatch();


    const handleChangeData = (name: string, value: FilterValues[keyof FilterValues]) => {
        setParams({ ...params, [name]: value });
    };

    const resetFilter = (needRefresh: boolean) => {
        setParams(initialState);
        onReset();
        if (needRefresh) {
            dispatch(clearDtpByTime());
        }
    };

    const updateFilter = (filters: FilterValues) => {
        setParams({
            ...initialState,
            ...filters,
        });
        if (filters?.start_time && filters?.end_time) {
            onSearch({
                ...initialState,
                ...filters,
            });
        }
    };

    const isDisabled = () => {
        return (params.start_time && isDateValid(params.start_time) && params.end_time && isDateValid(params.end_time))
            ? convertDateToTimestamp(params.start_time) > convertDateToTimestamp(params.end_time)
            : true;
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={onSearch}
            filter={params}
            setUserFilter={updateFilter}
            disabled={isDisabled()}
            filterException={['start_date', 'end_date']}
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(value) => handleChangeData('start_date', value)}
                    handleEndDate={(value) => handleChangeData('end_date', value)}
                    valueStartDate={params.start_date}
                    valueEndDate={params.end_date}
                    dateOnly
                    equalDates
                />
                <DateRange
                    handleDate={(value) => handleChangeData('start_time', value)}
                    handleEndDate={(value) => handleChangeData('end_time', value)}
                    valueStartDate={params.start_time}
                    valueEndDate={params.end_time}
                    timeOnly
                    equalDates
                    startLabel="Время с"
                    endLabel="Время до"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;
