import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { editFlightType, loadFlightTypes } from 'redux/TransportPassenger/actions';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';

// изменение типа рейса
const EditFlightType = ({ row, isOpen, onClose }) => {
    const dispatch = useDispatch();

    const flightTypes = useStoreProp(loadFlightTypes, 'transportPassenger', 'flightTypes');

    const [formData, setFormData] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        dispatch(editFlightType(row, formData));
    };

    return (
        <Modal
            title={'Выберите тип рейса'}
            onClose={onClose}
            maxWidthProp={'sm'}
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl variant="outlined" size="small">
                    <InputLabel id="ft-label">Тип рейса</InputLabel>
                    <Select
                        label="Тип рейса"
                        defaultValue={row.flight_type}
                        onChange={handleChange}
                        name="type"
                    >
                        {Object.keys(flightTypes)?.length > 0
                            ? Object.keys(flightTypes).map((key) =>
                                <MenuItem key={key} value={key}>{flightTypes[key]}</MenuItem>
                            )
                            : <MenuItem disabled={true} value="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </div>
        </Modal>
    );
};

export default EditFlightType;
