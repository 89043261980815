import iconCluster from './icons/iconCluster';

const config = {
    name: 'ПУИД',
    slug: 'puid',
    // mapMarkersColors: {
    //     1: '#51a351',
    //     2: '#ad1818',
    //     default: '#ad1818'
    // },
    mapContextMenu: {
        title: 'Добавить логический ПУИД',
        event: 'add_logical_puid',
        minWidth: 215,
    },
    clusterIcon: iconCluster,
    layerIcon: 'fad fa-cars',
};
// добавление на карте
export default config;
