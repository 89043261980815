import Settings from '../../../../common/Settings';
import {
    editSettingsGroup,
    editSettingsList,
    loadSettingsGroup
} from '../../../../../redux/Efficiency/actions';

const PageSettings = () => {
    return (
        <Settings
            title="Настройки – Контроль эффективности ИТС"
            storeProp="efficiency"
            listName="settingsGroup"
            loadingName="loadingSettingsGroup"
            loadSettingsGroupAction={loadSettingsGroup}
            editSettingsGroupAction={editSettingsGroup}
            editSettingsListAction={editSettingsList}
        />
    );
};

export default PageSettings;
