import { moduleName } from 'modules/TransportWater/module-config';

const prefix = `${moduleName}`;

export const LOAD_VEHICLES = `${prefix}/LOAD_VEHICLES`;
export const LOADING_VEHICLES = `${prefix}/LOADING_VEHICLES`;
export const LOADED_VEHICLES = `${prefix}/LOADED_VEHICLES`;
export const CREATE_VEHICLE = `${prefix}/CREATE_VEHICLE`;
export const EDIT_VEHICLE = `${prefix}/EDIT_VEHICLE`;
export const DELETE_VEHICLE = `${prefix}/DELETE_VEHICLE`;

export const LOAD_VEHICLE_TYPES = `${prefix}/LOAD_VEHICLE_TYPES`;
export const LOADING_VEHICLE_TYPES = `${prefix}/LOADING_VEHICLE_TYPES`;
export const LOADED_VEHICLE_TYPES = `${prefix}/LOADED_VEHICLE_TYPES`;

export const LOAD_DOCK_SIDEBAR = `${prefix}/LOAD_DOCK_SIDEBAR`;
export const LOAD_DOCK_POLYGON = `${prefix}/LOAD_DOCK_POLYGON`;
export const SET_FILTERS = `${prefix}/SET_FILTERS`;
export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;
export const LOAD_DOCK_COORDS = `${prefix}/LOAD_DOCK_COORDS`;
export const SET_DOCK_CURRENT_SHOW = `${prefix}/SET_DOCK_CURRENT_SHOW`;
export const LOAD_DOCK_EVENTS = `${prefix}/LOAD_DOCK_EVENTS`;
export const SET_DOCK_COLOR_REFRESH = `${prefix}/SET_DOCK_COLOR_REFRESH`;
