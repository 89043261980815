import React, { useEffect, useState } from 'react';
import { useDispatch, } from 'react-redux';
import { loadCurrentCompany } from '../../redux/Companies/actions';

// загрузка и сохранение компании по id
const companyList = {};
const useCompany = (id, companyObj) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id && !companyList[id] && !loading && (!companyObj || Object.keys(companyObj).length === 0)) {
            setLoading(true);
            dispatch(loadCurrentCompany(id, (company) => {
                companyList[id] = company;
                setLoading(false);
            }));
        }
        if (companyObj && Object.keys(companyObj).length > 0) {
            companyList[id] = companyObj;
        }
    }, [dispatch, id, loading, companyObj]);

    return companyList[id];
};

export default useCompany;