import React from 'react';
import Filter from './Filter';
import PageLayout from '../../../../layout/PageLayout';
// import ReturnToButton from '../../../../common/Buttons/ReturnToButton';
import useReportTemplates from '../../../../../helpers/hooks/Report/useReportTemplates';


const ConsolidateReport = () => {
    const templatesProvider = useReportTemplates();

    return (
        <PageLayout
            header={
                templatesProvider.templateByUrl.templateName
                || 'Сводный отчет'
            }
            // additionalButtons={<ReturnToButton/>}
            filters={
                <Filter
                    templateId={templatesProvider.templateByUrl.templateId}
                />
            }
        />
    );
};

export default ConsolidateReport;
