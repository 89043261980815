import { useState } from 'react';

import messages from 'helpers/constants/messages';
import { getFullTime, getHumanDate } from 'helpers/date.config';
import ModalForm from 'components/MapComponents/Layers/Defects/ModalForm';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';
import CircleStatus from 'components/common/CircleStatus';


const Item = ({ item, status, loadList }) => {

    const [openEditModal, setOpenEditModal] = useState(false);

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    <CircleStatus title={status.name} color={status?.color}/>
                    {/* <span style={{ color: status?.color }}>{status.name || messages.NO_VALUE}</span> */}
                </LSContentColumn>
                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.registration_at ? <>{getHumanDate(item.registration_at)}<br/>{getFullTime(item.registration_at)}</> : messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.group?.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.type?.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.address_text || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn align="right" isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
            {openEditModal && (
                <ModalForm
                    data={item}
                    open={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    loadList={loadList}
                />
            )}
        </>
    );
};

export default Item;
