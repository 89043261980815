import { useState } from 'react';
import Filter from './Filter';
import ViewTemplate from './ViewTemplate';

const CamerasView = () => {
    const [currentTemplate, setCurrentTemplate] = useState({});

    return (
        <>
            <h1>Просмотр камер</h1>
            <Filter 
                setCurrentTemplate={setCurrentTemplate}
            />
            {currentTemplate?.id && (
                <ViewTemplate 
                    template={currentTemplate} 
                />
            )}
        </>
    );
};

export default CamerasView;
