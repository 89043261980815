import { config } from '../../config';

const apiVideoAnaliticUrl = () => config.get('urls').apiVideoAnaliticUrl;

const apiUrls = {
    getCameras: () => `${apiVideoAnaliticUrl()}/camera`,

    editCamera: (id) => `${apiVideoAnaliticUrl()}/camera/${id}`,

    createCamera: () => `${apiVideoAnaliticUrl()}/camera`,

    deleteCamera: (id) => `${apiVideoAnaliticUrl()}/camera/${id}`,

    getCameraSettings: () => `${apiVideoAnaliticUrl()}/settings`,

    editCameraSettings: (id) => `${apiVideoAnaliticUrl()}/settings/${id}`,

    getInfoList: () => `${apiVideoAnaliticUrl()}/vehicle-info`,

    getEvents: () => `${apiVideoAnaliticUrl()}/v2/video-event`,

    getCameraViolations: () => `${apiVideoAnaliticUrl()}/camera/violations`,

    getReportGroups: () => `${apiVideoAnaliticUrl()}/report/groups`,

    getReportTypes: () => `${apiVideoAnaliticUrl()}/report/types`,

    getReport: () => `${apiVideoAnaliticUrl()}/report`,

    getInOutCamerasReport: () => `${apiVideoAnaliticUrl()}/report/in-out-cameras`,

    getPolygon: () => `${apiVideoAnaliticUrl()}/camera/polygon`,

    getDeviceList: () => `${apiVideoAnaliticUrl()}/camera`,

    // getEventsPopup: (page, limit) => `${apiVideoAnaliticUrl()}/camera/events?page=${page}&limit=${limit}&is_map=1`,
    getEventsPopup: () => `${apiVideoAnaliticUrl()}/v2/video-event`,

    getViolations: () => `${apiVideoAnaliticUrl()}/camera/violations`,

    getStatuses: () => `${apiVideoAnaliticUrl()}/v2/camera/statuses`,

    getVideoAnalyticGrzPointsGroup: () => `${apiVideoAnaliticUrl()}/camera/grz/points/group`,

    downloadEvents: () => `${apiVideoAnaliticUrl()}/camera/events`,

    getEventsFilters: () => `${apiVideoAnaliticUrl()}/camera/events/filters`,

    getCameraObjects: () => `${apiVideoAnaliticUrl()}/camera/objects`,

    getPassengerTraffic: () => `${apiVideoAnaliticUrl()}/camera/peoples/traffic`,

    getPassengerTrafficTypes: () => `${apiVideoAnaliticUrl()}/camera/peoples/traffic/types`,

    loadCamerasByRadius: () => `${apiVideoAnaliticUrl()}/camera/radius`,

    loadShowcase: () => `${apiVideoAnaliticUrl()}/showcase`,
};

export default apiUrls;
