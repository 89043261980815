import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';

import TextMaskPhone from 'components/common/TextMaskPhone';

import fieldsTypes from './fieldsTypes';
import USM from './USM';

const Field = (props) => {
    const {
        label,
        value,
        onChange,
        helperText,
        error,
        type,
        params,
        test_id_prefix = '',
    } = props;

    const handleChange = (pr) => (e) => {
        const small = pr?.small ?? null;
        const { type, value } = e.target;
        const smallD = Math.pow(10, small || 0);
        onChange(
            small !== null
                ? Math.round(parseFloat(value) * smallD) / smallD
                : (type === 'number' && (value < 0 || !value)) ? 0 : value
        );
    };

    const handleChangeCB = (e) => {
        const { checked } = e.target;
        onChange(checked);
    };

    const handleChangeRadio = (e) => {
        onChange(e.target.value);
    };

    switch (type) {
        case fieldsTypes.universalSelect: {
            return (
                <USM
                    {...params}
                    label={label}
                    helperText={helperText}
                    error={error}
                    value={value}
                    onChange={(value) => onChange(value)}
                    test_id_prefix={test_id_prefix}
                />
            );
        }

        case fieldsTypes.radioBox: {
            return (
                <FormControl component="fieldset">
                    <FormLabel component="legend">{label}</FormLabel>
                    <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue=""
                        value={value}
                        onChange={handleChangeRadio}
                        inputProps={`data-testid: ${test_id_prefix}/radio`}
                    >
                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Да" />
                        <FormControlLabel value="0" control={<Radio color="primary" />} label="Нет" />
                    </RadioGroup>
                    {!!helperText && (
                        <FormHelperText error>{helperText}</FormHelperText>
                    )}
                </FormControl>
            );
        }

        case fieldsTypes.checkBox: {
            return (
                <FormControl component="fieldset">
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                helperText={helperText}
                                error={error}
                                checked={value}
                                onChange={handleChangeCB}
                                inputProps={`data-testid: ${test_id_prefix}/checkbox`}
                            />
                        }
                        label={label}

                    />
                    {!!helperText && (
                        <FormHelperText error>{helperText}</FormHelperText>
                    )}
                </FormControl>
            );
        }

        case fieldsTypes.textFieldInteger: {
            return (
                <FormControl>
                    <TextField
                        type="number"
                        label={label}
                        helperText={helperText}
                        error={error}
                        value={value}
                        onChange={handleChange(params)}
                        variant="outlined"
                        size="small"
                        inputProps={{
                            min: 0,
                            'data-testid': `${test_id_prefix}/input`
                        }}
                    />
                </FormControl>
            );
        }

        case fieldsTypes.textPhoneMask: {
            return (
                <FormControl>
                    <TextField
                        label={label}
                        helperText={helperText}
                        error={error}
                        value={value || ''}
                        onChange={handleChange(params)}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            inputComponent: TextMaskPhone,
                            'data-testid': `${test_id_prefix}/input`
                        }}
                    />
                </FormControl>
            );
        }

        default: {
            return (
                <FormControl>
                    <TextField
                        label={label}
                        helperText={helperText}
                        error={error}
                        value={value}
                        onChange={handleChange()}
                        variant="outlined"
                        size="small"
                        inputProps={{
                            'data-testid': `${test_id_prefix}/input`
                        }}
                    />
                </FormControl>
            );
        }
    }
};

export default Field;
