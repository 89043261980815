import { AppBar } from '@mui/material';
import React, { useState } from 'react';
import { CustomTab, CustomTabs } from '../../../../../common/Tabs';
import BasicData from './BasicData';
import Members from './Members';
import Vehicles from './Vehicles';
import Situation from './Situation';
import Materials from './Materials';
import Loading from '../../../../../common/Loading';

const PassportTabs = ({
    fields,
    requiredFields,
    onChange,
    loading,
    typesList,
    tab,
    isTi
}) => {

    const tabPanelProps = (tabName) => ({
        role: 'tabpanel',
        id: `scrollable-force-tabpanel-${tabName}`,
        'aria-labelledby': `scrollable-force-tab-${tabName}`
    });

    const tabProps = (tabName) => ({
        id: `scrollable-force-tab-${tabName}`,
        'aria-controls': `scrollable-force-tabpanel-${tabName}`,
    });

    const [value, setValue] = useState( tab || 'basicData');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <AppBar position="sticky" color="default">
                <CustomTabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="scrollable force tabs example"
                >
                    <CustomTab
                        iconPosition="start"
                        value="basicData"
                        label={'Основные данные'}
                        {...tabProps('basicData')}
                    />
                    <CustomTab
                        iconPosition="start"
                        value="members"
                        label={'Участники ДТП'}
                        {...tabProps('members')}
                    />          
                    <CustomTab
                        iconPosition="start"
                        value="vehicles"
                        label={'Транспортные средства'}
                        {...tabProps('vehicles')}
                    />
                    <CustomTab
                        iconPosition="start"
                        value="situations"
                        label={'Обстановка'}
                        {...tabProps('situations')}
                    />
                    <CustomTab
                        iconPosition="start"
                        value="materials"
                        label={'Материалы'}
                        {...tabProps('Материалы')}
                    />
                </CustomTabs>
            </AppBar>
            {loading && <Loading linear={true}/>}
            {value === 'basicData'
                && <div {...tabPanelProps('basicData')}>
                    <BasicData
                        fields={fields}
                        requiredFields={requiredFields}
                        onChange={onChange}
                        typesList={typesList}
                        isTi={isTi}
                    />
                </div>
            }
            {value === 'members'
                && <div {...tabPanelProps('members')}>
                    <Members
                        fields={fields}
                        onChange={onChange}
                    />
                </div>
            }
            {value === 'vehicles'
                && <div {...tabPanelProps('vehicles')}>
                    <Vehicles
                        fields={fields}
                        onChange={onChange}
                    />
                </div>
            }
            {value === 'situations'
                && <div {...tabPanelProps('situations')}>
                    <Situation
                        fields={fields}
                        onChange={onChange}
                    />
                </div>
            }
            {value === 'materials'
                && <div {...tabPanelProps('materials')}>
                    <Materials 
                        fields={fields}
                        onChange={onChange}
                    />
                </div>
            }                      
        </>
    );
};

export default PassportTabs;
