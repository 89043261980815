import titles from '../../../../../../../helpers/constants/titles';
import UniversalSelect from '../../../../../../common/UniversalSelect';
import { loadTypicalOperations } from '../../../../../../../redux/SituationalPlans/actions';
import { useDispatch } from 'react-redux';

const TypicalOperationsModal = ({ isOpen, onClose, onChange, data }) => {
    const dispatch = useDispatch();

    const handleUniversalSelect = (value) => {
        onChange('operation_list', value);
        onClose();
    };

    return (
        <UniversalSelect
            fetchList={({ page, limit, query }) => dispatch(loadTypicalOperations(page, limit, { name: query }))}
            storeName={'SituationalPlans'}
            storeNameProps={'typicalOperations'}
            onChange={(value) => handleUniversalSelect(value, 'operation_list')}
            keyProp={'id'}
            withSearch={true}
            multiple={true}
            isSelected={true}
            selected={data.operation_list}
            renderProps={(el) => <div><span>{el.name}</span></div>}
            isOpen={isOpen}
            onAccept={handleUniversalSelect}
            onClose={onClose}
            noPadding={true}
            title={titles.ADD}
            small={true}
        />
    );
};

export default TypicalOperationsModal;