import * as types from './types';

const INIT_STATE = {};

export default function reducer (state = INIT_STATE, action) {
    switch (action.type) {
        case types.SET_VALIDATION: {
            return {
                ...state,
                ...(action.payload.data || {})
            };
        }
        case types.CLEAR_VALIDATION: {
            return {
                ...INIT_STATE
            };
        }
        case types.DELETE_VALIDATION: {
            const oldState = {
                ...state,
            };

            if (action.payload) {
                delete oldState[action.payload];
            }

            return {
                ...oldState,
            };
        }
        case types.DELETE_VALIDATION_ARR: {
            const newState = (action.payload || []).reduce((res, key) => {
                delete res[key];
                return res;
            }, state);

            return {
                ...newState,
            };
        }
        // case SET_VALIDATION: {
        //     return action.payload.data ? { ...action.payload.data } : null;
        // }
        // case CLEAR_VALIDATION: {
        //     return INIT_STATE;
        // }
        default:
            return state;
    }
};
