import DtpConcentrationArea from '../../pages/Dictionaries/Incidents/DtpConcentrationArea';
import DTPTypes from '../../pages/Dictionaries/Incidents/DTPTypes';
import Population from '../../pages/Dictionaries/Incidents/Population';
import DTPSettings from '../../pages/Dictionaries/Incidents/Settings';

const routes = [
    {
        path: '/dictionaries/incidents/dtp-types',
        component: DTPTypes,
        exact: true,
    },
    {
        path: '/dictionaries/dtp/settings',
        component: DTPSettings,
        exact: true,
    },
    {
        path: '/dictionaries/incidents/population',
        component: Population,
        exact: true,
    },
    {
        path: '/dictionaries/dtp/concentration-area',
        component: DtpConcentrationArea,
        exact: true,
    },
];

export default routes;