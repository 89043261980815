import React from 'react';
import messages from '../../../../helpers/constants/messages';
import titles from '../../../../helpers/constants/titles';

const MarkerToolTip = ({
    isStation,
    name,
    direction,
    mileage,
    interval
}) => (
    <>
        <div>
            <b>{isStation ? titles.STATION : titles.CHECK_POINT} </b>
            <span>{name || messages.INFO_IS_NOT_FOUND}</span>
        </div>
        {isStation && (
            <div>
                <b>Направление: </b>
                <span>{direction || messages.INFO_IS_NOT_FOUND}</span>
            </div>
        )}
        <div>
            {titles.MILEAGE_FROM_STATION} {mileage} (км)
        </div>
        <div>
            {titles.INTERVAL_FROM_STATION} {interval} (часы, мин)
        </div>
    </>
);

export default MarkerToolTip;