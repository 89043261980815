import * as types from './types';
import { MOVE_COMPUTER_TO_OTHER_COMPLEX } from './types';

/**
 * Actions
 **/
export const loadCameras = (page = 1, limit = 25, query) => ({
    type: types.LOAD_CAMERAS,
    payload: {
        page,
        limit,
        query
    }
});

export const loadingCameras = (bool = false) => ({
    type: types.LOADING_CAMERAS,
    payload: bool
});

export const loadedCameras = (data) => ({
    type: types.LOADED_CAMERAS,
    payload: data
});

export const loadCameraTypes = () => ({
    type: types.LOAD_CAMERA_TYPES
});

export const loadedCameraTypes = (data) => ({
    type: types.LOADED_CAMERA_TYPES,
    payload: data
});

export const editCamera = (id, data) => ({
    type: types.EDIT_CAMERA,
    payload: { id, data }
});

export const createCamera = (data) => ({
    type: types.CREATE_CAMERA,
    payload: data
});

export const loadCameraComplexTypes = () => ({
    type: types.LOAD_CAMERA_COMPLEX_TYPES,
});

export const loadedCameraComplexTypes = (data) => ({
    type: types.LOADED_CAMERA_COMPLEX_TYPES,
    payload: data
});

export const loadCameraProtoText = () => ({
    type: types.LOAD_CAMERA_PROTO_TEXT
});

export const loadedCameraProtoText = (data) => ({
    type: types.LOADED_CAMERA_PROTO_TEXT,
    payload: data
});

export const loadCameraDirType = () => ({
    type: types.LOAD_CAMERA_DIR_TYPE
});

export const loadedCameraDirType = (data) => ({
    type: types.LOADED_CAMERA_DIR_TYPE,
    payload: data
});

export const loadCameraVideoType = () => ({
    type: types.LOAD_CAMERA_VIDEO_TYPE
});

export const loadedCameraVideoType = (data) => ({
    type: types.LOADED_CAMERA_VIDEO_TYPE,
    payload: data
});

export const loadCameraDataType = () => ({
    type: types.LOAD_CAMERA_DATA_TYPE
});

export const loadedCameraDataType = (data) => ({
    type: types.LOADED_CAMERA_DATA_TYPE,
    payload: data
});

export const loadCameraViolations = () => ({
    type: types.LOAD_CAMERA_VIOLATIONS,
});

export const loadedCameraViolations = (data) => ({
    type: types.LOADED_CAMERA_VIOLATIONS,
    payload: data
});

export const loadedCameraEvents = (data) => ({
    type: types.LOADED_CAMERA_EVENTS,
    payload: data
});

export const loadedCameraEventsGroup = (data) => ({
    type: types.LOADED_CAMERA_EVENTS_GROUP,
    payload: data
});

export const loadCameraEventsGroup = (params) => ({
    type: types.LOAD_CAMERA_EVENTS_GROUP,
    payload: params
});

export const loadingCameraEvents = (bool = false) => ({
    type: types.LOADING_CAMERA_EVENTS,
    payload: bool
});

export const loadCameraEvents = (page = 1, limit = 25, data) => ({
    type: types.LOAD_CAMERA_EVENTS,
    payload: { page, limit, data }
});

export const loadCameraReportList = () => ({
    type: types.LOAD_CAMERA_REPORT_LIST
});

export const loadedCameraReportList = (data) => ({
    type: types.LOADED_CAMERA_REPORT_LIST,
    payload: data
});

export const getCameraReport = (params) => ({
    type: types.GET_CAMERA_REPORT,
    payload: params
});

export const loadingComplexes = (bool = false) => ({
    type: types.LOADING_COMPLEXES,
    payload: bool
});

export const loadedComplexes = (data) => ({
    type: types.LOADED_COMPLEXES,
    payload: data
});

export const loadComplexes = (page = 1, limit = 25, params) => ({
    type: types.LOAD_COMPLEXES,
    payload: {
        page,
        limit,
        params
    }
});

export const loadAllComplexes = (params, callback) => ({
    type: types.LOAD_COMPLEXES,
    payload: {
        params
    },
    callback
});

export const clearComplexes = () => ({
    type: types.LOADED_COMPLEXES,
    payload: {
        data:[],
        meta: {
            last_page: 0,
        }
    }
});

export const createComplex = (data, callback) => ({
    type: types.CREATE_COMPLEX,
    payload: data,
    callback
});

export const loadComplexTypes = () => ({
    type: types.LOAD_COMPLEX_TYPES
});

export const loadedComplexTypes = (data) => ({
    type: types.LOADED_COMPLEX_TYPES,
    payload: data
});

export const loadComplexUnitTypes = () => ({
    type: types.LOAD_COMPLEX_UNIT_TYPES
});

export const loadedComplexUnitTypes = (data) => ({
    type: types.LOADED_COMPLEX_UNIT_TYPES,
    payload: data
});

export const loadComplexModelTypes = () => ({
    type: types.LOAD_COMPLEX_MODEL_TYPES
});

export const loadedComplexModelTypes = (data) => ({
    type: types.LOADED_COMPLEX_MODEL_TYPES,
    payload: data
});

export const loadComplexEntities = (data) => ({ // page = 1, limit = 25 ---- приходит без пагинации
    type: types.LOAD_COMPLEX_ENTITIES,
    payload: {
        data
    }
});

export const loadedComplexEntities = (data) => ({
    type: types.LOADED_COMPLEX_ENTITIES,
    payload: data
});

export const loadingComplexEntities = (bool = false) => ({
    type: types.LOADING_COMPLEX_ENTITIES,
    payload: bool
});

export const loadComplexEntityTypes = () => ({
    type: types.LOAD_COMPLEX_ENTITY_TYPES,
});

export const loadingComplexEntityTypes = (bool) => ({
    type: types.LOADING_COMPLEX_ENTITY_TYPES,
    payload: bool
});

export const loadedComplexEntityTypes = (data) => ({
    type: types.LOADED_COMPLEX_ENTITY_TYPES,
    payload: data
});

export const loadPddViolations = (page, limit, data, filtered = false ) => ({
    type: types.LOAD_PDD_VIOLATIONS,
    payload: { ...(page && { page }), ...(limit && { limit }), data },
    filtered
});

export const loadingPddViolations = (bool = false) => ({
    type: types.LOADING_PDD_VIOLATIONS,
    payload: bool
});

export const loadedPddViolations = (data) => ({
    type: types.LOADED_PDD_VIOLATIONS,
    payload: data
});

export const loadedViolationsById = (data) => ({
    type: types.LOADED_FILTERED_PDD_VIOLATIONS,
    payload: data
});

export const editPddViolation = (id, data, callback = () => {}) => ({
    type: types.EDIT_PDD_VIOLATION,
    payload: { id, data },
    callback
});

// загрузить пдд нарушения по конкретной камере
export const loadCameraPddViolations = (id, data) => ({
    type: types.LOAD_CAMERAS_PDD_VIOLATIONS,
    payload: { id, data }
});

export const loadingCameraPddiolations = (data) => ({
    type: types.LOADING_CAMERAS_PDD_VIOLATIONS,
    payload: data
});
export const loadedCameraPddViolations = (data) => ({
    type: types.LOADED_CAMERAS_PDD_VIOLATIONS,
    payload: data
});

export const postComplexEntityData = (data, callback = () => {}) => ({
    type: types.POST_COMPLEX_ENTITY_DATA,
    payload: data,
    callback
});

export const editComplexEntityData = (id, data, callback = () => {}) => ({
    type: types.EDIT_COMPLEX_ENTITY_DATA,
    payload: { id, data },
    callback
});

export const deleteComplexEntityData = (id, data, complex_id, callback = () => {}) => ({
    type: types.DELETE_COMPLEX_ENTITY_DATA,
    payload: { id, data, complex_id },
    callback
});

export const loadDocuments = (page = 1, limit = 25, data) => ({
    type: types.LOAD_DOCUMENTS,
    payload: {
        page,
        limit,
        data
    }
});

export const loadingDocuments = (bool = false) => ({
    type: types.LOADING_DOCUMENTS,
    payload: bool
});

export const loadedDocuments = (data) => ({
    type: types.LOADED_DOCUMENTS,
    payload: data
});

export const loadDocumentTypes = () => ({
    type: types.LOAD_DOCUMENT_TYPES
});

export const loadedDocumentTypes = (data) => ({
    type: types.LOADED_DOCUMENT_TYPES,
    payload: data
});

export const createDocumentComplex = (data, callback = () => {}) => ({
    type: types.CREATE_DOCUMENT_COMPLEX,
    payload: data,
    callback
});

export const loadComplexStatuses = () => ({
    type: types.LOAD_COMPLEX_STATUSES
});

export const loadedComplexStatuses = (data) => ({
    type: types.LOADED_COMPLEX_STATUSES,
    payload: data
});

export const deleteComplexDocument = (id, data, complex_id, callback = () => {}) => ({
    type: types.DELETE_COMPLEX_DOCUMENT,
    payload: { id, data, complex_id },
    callback
});

export const editComplexDocument = (id, data, callback = () => {}) => ({
    type: types.EDIT_COMPLEX_DOCUMENT,
    payload: { id, data },
    callback
});

export const deleteComplex = (id, data = {}, callback = () => {}) => ({
    type: types.DELETE_COMPLEX,
    payload: { id, data },
    callback
});

export const loadComplexHistory = (page = 1, limit = 25, id) => ({
    type: types.LOAD_COMPLEX_HISTORY,
    payload: {
        page,
        limit,
        id
    }
});

export const loadedComplexHistory = (data) => ({
    type: types.LOADED_COMPLEX_HISTORY,
    payload: data
});

export const loadingComplexHistory = (bool = false) => ({
    type: types.LOADING_COMPLEX_HISTORY,
    payload: bool
});

export const editComplex = (id, data, callback, params = null) => ({
    type: types.EDIT_COMPLEX,
    payload: { id, data, params },
    callback
});

/* полигон */
export const getForPolygon = (polygon, params = {}) => ({
    type: types.GET_POLYGON,
    payload: { polygon, ...params },
});

export const setLoadingPolygon = (bool = false) => ({
    type: types.SET_POLYGON_LOADING,
    payload: bool,
});

export const saveForPolygon = (data) => ({
    type: types.SAVE_POLYGON,
    payload: data,
});

export const clearForPolygon = () => ({
    type: types.SAVE_POLYGON,
    payload: [],
});

export const setActive = (item) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const resetActive = () => ({
    type: types.SET_ACTIVE,
    payload: {},
});

export const setDeleteForm = (data = false) => ({
    type: types.SET_DELETE_FORM,
    payload: data
});

export const setEditForm = (data = false) => ({
    type: types.SET_EDIT_FORM,
    payload: data
});

export const saveStatus = (bool = false) => ({
    type: types.SAVED,
    payload: bool,
});

export const loadComplexEvents = (
    page = 1,
    limit = 25,
    data,
    callback
) => ({
    type: types.LOAD_COMPLEX_EVENTS,
    payload: {
        page,
        limit,
        data
    },
    callback
});

export const loadedComplexEvents = (data) => ({
    type: types.LOADED_COMPLEX_EVENTS,
    payload: data,
});
export const clearComplexEvents = (data) => ({
    type: types.CLEAR_COMPLEX_EVENTS,
});

export const loadingComplexEvents = (bool = false) => ({
    type: types.LOADING_COMPLEX_EVENTS,
    payload: bool,
});

export const loadComplexEventArchive = (id, data) => ({
    type: types.LOAD_COMPLEX_EVENT_ARCHIVE,
    payload: { id, data }
});

export const loadedCurrentEvent = (data) => ({
    type: types.LOADED_CURRENT_EVENT,
    payload: data
});
export const loadCurrentEventList = (page = 1, limit = 25, data, callback) => ({
    type: types.LOAD_CURRENT_EVENT_LIST,
    payload: { page, limit, data },
    callback
});
// получение полного объекта события кфвф
export const loadEventObject = (uid, params = {}, callback) => ({
    type: types.LOAD_EVENT,
    payload: { uid, params },
    callback
});
export const loadingEvent = (bool = false) => ({
    type: types.LOADING_EVENT,
    payload: bool
});

export const setComplexEventsReportFilter = (data) => ({
    type: types.SET_COMPLEX_EVENTS_REPORT_FILTER,
    payload: data,
});

export const clearComplexEventsReportFilter = () => ({
    type: types.CLEAR_COMPLEX_EVENTS_REPORT_FILTER,
});

export const changeStatus = (complex_id, data, callback) => ({
    type: types.CHANGE_COMPLEX_STATUS,
    payload: { complex_id, data },
    callback
});

export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool
});

export const loadedSidebar = (data, page) => ({
    type: types.LOADED_SIDEBAR,
    payload: {
        data,
        page,
    }
});

export const loadSidebar = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_SIDEBAR,
    payload: {
        page,
        limit,
        ...params
    }
});

export const clearSidebar = () => ({
    type: types.CLEAR_SIDEBAR
});

export const loadEventErrors = (page = 1, limit = 25, data) => ({
    type: types.LOAD_EVENT_ERRORS,
    payload: {
        page,
        limit,
        data
    }
});

export const loadedEventErrors = (data) => ({
    type: types.LOADED_EVENT_ERRORS,
    payload: data
});

export const editEventErrors = (uuid, data) => ({
    type: types.EDIT_EVENT_ERRORS,
    payload: { uuid, data }
});

export const editingEventErrors = (bool = false) => ({
    type: types.EDITING_EVENT_ERRORS,
    payload: bool
});

//COMPLEX
export const loadFormattedComplexHistory = (page = 1, limit = 25, id) => ({
    type: types.LOAD_FORMATTED_COMPLEX_HISTORY,
    payload: { page, limit, id }
});

export const loadingFormattedComplexHistory = (bool = false) => ({
    type: types.LOADING_FORMATTED_COMPLEX_HISTORY,
    payload: bool
});

export const loadedFormattedComplexHistory = (data) => ({
    type: types.LOADED_FORMATTED_COMPLEX_HISTORY,
    payload: data
});

export const loadComplexRules = (id) => ({
    type: types.LOAD_COMPLEX_RULES,
    payload: id
});

export const loadingComplexRules = (id) => ({
    type: types.LOADING_COMPLEX_RULES,
    payload: id
});

export const loadedComplexRules = (data) => ({
    type: types.LOADED_COMPLEX_RULES,
    payload: data
});

export const clearComplexRules = () => ({
    type: types.CLEAR_COMPLEX_RULES,
});

// export const loadCurrentComplex = (params) => ({
//     type: types.LOAD_CURRENT_COMPLEX,
//     //payload: id
//     payload: params
// });

// export const loadedCurrentComplex = (data) => ({
//     type: types.LOADED_CURRENT_COMPLEX,
//     payload: data
// });

export const loadComplexTransferTypes = () => ({
    type: types.LOAD_COMPLEX_TRANSFER_TYPES
});

export const loadCurrentComplexTransferTypes = (complex_id) => ({
    type: types.LOAD_CURRENT_COMPLEX_TRANSFER_TYPES,
    payload: complex_id
});

export const loadedCurrentComplexTransferTypes = (data) => ({
    type: types.LOADED_CURRENT_COMPLEX_TRANSFER_TYPES,
    payload: data
});

export const loadedComplexTransferTypes = (data) => ({
    type: types.LOADED_COMPLEX_TRANSFER_TYPES,
    payload: data
});

export const loadComplexTransferRules = (id) => ({
    type: types.LOAD_COMPLEX_TRANSFER_RULES,
    payload: id
});

export const loadedComplexTransferRules = (data) => ({
    type: types.LOADED_COMPLEX_TRANSFER_RULES,
    payload: data
});

export const clearComplexTransferRules = () => ({
    type: types.CLEAR_COMPLEX_TRANSFER_RULES,
});

export const moveServiceEventsToMain = (data, callback) => ({
    type: types.MOVE_SERVICE_EVENTS_TO_MAIN,
    payload: data,
    callback
});

export const loadComplexStatistic = (is_showcase) => ({
    type: types.LOAD_COMPLEX_STATISTIC,
    payload: {
        is_showcase
    }
});

export const loadedComplexStatistic = (data) => ({
    type: types.LOADED_COMPLEX_STATISTIC,
    payload: data
});

export const deleteFromServiceEvents = (data, callback) => ({
    type: types.DELETE_FROM_SERVICE_EVENTS,
    payload: data,
    callback
});

export const loadMonthlyReport = ( data) => ({
    type: types.LOAD_MONTHLY_REPORT,
    payload: data
});

export const loadMonthlyReportExpanded = ( data) => ({
    type: types.LOAD_MONTHLY_REPORT_EXPANDED,
    payload: data
});

export const loadMonthlyReportByComplex = ( data) => ({
    type: types.LOAD_MONTHLY_REPORT_BY_COMPLEX,
    payload: data
});

export const loadReportByPeriod = (data) => ({
    type: types.LOAD_REPORT_BY_PERIOD,
    payload: data
});

export const loadComparativeStatistic = (data) => ({
    type: types.LOAD_COMPARATIVE_STATISTICS,
    payload: data
});

export const loadingComparativeStatistic = (bool = false) => ({
    type: types.LOADING_COMPARATIVE_STATISTICS,
    payload: bool
});

export const loadedComparativeStatistic = (data) => ({
    type: types.LOADED_COMPARATIVE_STATISTICS,
    payload: data
});

// открыть модалку с событиями (справа)
export const setShowDorisCameraEvents = (bool) => ({
    type: types.SET_SHOW_DORIS_CAMERA_EVENTS,
    payload: bool
});

export const setActiveEvent = (data) => ({
    type: types.SET_ACTIVE_EVENT,
    payload: data
});

/* история комплекса */
export const loadCoordinationHistory = (complex_ids, date) => ({
    type: types.LOAD_COORDINATION_HISTORY,
    payload: {
        complex_ids,
        date,
    }
});

export const loadedCoordinationHistory = (data) => ({
    type: types.LOADED_COORDINATION_HISTORY,
    payload: data
});

export const loadingCoordinationHistory = (data) => ({
    type: types.LOADING_COORDINATION_HISTORY,
    payload: data
});

export const setCoordinationHistoryDates = (data = {}) => ({
    type: types.SET_COORDINATION_HISTORY_DATES,
    payload: data
});

export const setFilters = (filter) => ({
    type: types.SET_FILTERS,
    payload: filter
});
export const clearFilters = () => ({
    type: types.CLEAR_FILTERS,
});

export const loadComplexReport = (report) => ({
    type: types.LOAD_COMPLEX_REPORT,
    payload: report
});

export const loadInaccuracy = () => ({
    type: types.LOAD_INACCURACY,
});

export const loadedInaccuracy = (data) => ({
    type: types.LOADED_INACCURACY,
    payload: data
});

//DORIS_CONTROL_SETTINGS
export const loadDorisControlSettings = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_DORIS_CONTROL_SETTINGS,
    payload: { page, limit, query }
});

export const loadingDorisControlSettings = (bool = false) => ({
    type: types.LOADING_DORIS_CONTROL_SETTINGS,
    payload: bool
});

export const loadedDorisControlSettings = (data) => ({
    type: types.LOADED_DORIS_CONTROL_SETTINGS,
    payload: data
});

export const editDorisControlSetting = (id, data) => ({
    type: types.EDIT_DORIS_CONTROL_SETTING,
    payload: { id, data }
});

export const editSettingsList = (data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_LIST,
    payload: data,
    callback
});

export const deleteComplexEvents = (data, callback) => ({
    type: types.DELETE_COMPLEX_EVENTS,
    payload: data,
    callback
});

export const loadIncorrectComplexReport = (data) => ({
    type: types.GET_INCORRECT_COMPLEX_REPORT,
    payload: data
});

export const loadTsCategories = () => ({
    type: types.LOAD_TS_CATEGORIES,
});

export const loadedTsCategories = (data) => ({
    type: types.LOADED_TS_CATEGORIES,
    payload: data
});


export const loadInspectionCertificate = (params) => ({
    type: types.LOAD_INSPECTION_CERTIFICATE,
    payload: params
});
export const loadingInspectionCertificate = (bool = false) => ({
    type: types.LOADING_INSPECTION_CERTIFICATE,
    payload: bool
});

export const eventSendingRequest = (uuid) => ({
    type: types.EVENT_SENDING_REQUEST,
    payload: uuid
});

// загрузить файл
export const loadFile = (url) => ({
    type: types.LOAD_FILE,
    payload: url,
});
export const loadingFile = (bool = false) => ({
    type: types.LOADING_FILE,
    payload: bool,
});

export const load1CNames = (page = 1, limit = 25, query) => ({
    type: types.LOAD_1C_NAMES,
    payload: { page, limit, query }
});

export const loading1CNames = (isLoading) => ({
    type: types.LOADING_1C_NAMES,
    payload: isLoading
});

export const loaded1CNames = (data) => ({
    type: types.LOADED_1C_NAMES,
    payload: data
});

export const edit1CName = (data, id, callback) => ({
    type: types.EDIT_1C_NAME,
    payload: { data, id },
    callback
});

export const create1CName = (data, callback) => ({
    type: types.CREATE_1C_NAME,
    payload: data,
    callback
});

export const delete1CName = (id, callback) => ({
    type: types.DELETE_1C_NAME,
    payload: id,
    callback
});

export const clear1CNames = () => ({
    type: types.CLEAR_1C_NAMES,
});

export const load1CNamesReport = (params) => ({
    type: types.LOAD_REPORT_BY_1C_NAMES,
    payload: params
});

export const loading1CNamesReport = (bool = false) => ({
    type: types.LOADING_REPORT_BY_1C_NAMES,
    payload: bool
});

export const setOperatorLogReportFilter = (data) => ({
    type: types.SET_OPERATOR_LOG_REPORT_FILTER,
    payload: data
});

export const clearOperatorLogReportFilter = () => ({
    type: types.CLEAR_OPERATOR_LOG_REPORT_FILTER,
});

export const setDcWs = (events) => ({
    type: types.SET_DORIS_CONTROL_WS,
    payload: events,
});

export const setSidebarParamsDC = (params) => ({
    type: types.SET_SIDEBAR_PARAMS,
    payload: params,
});

export const loadComplexById = (id, callback) => ({
    type: types.LOAD_COMPLEX_BY_ID,
    payload: { id },
    callback
});

export const loadingComplexById = (isLoading) => ({
    type: types.LOADING_COMPLEX_BY_ID,
    payload: isLoading
});

export const loadedComplexById = (data) => ({
    type: types.LOADED_COMPLEX_BY_ID,
    payload: data
});

export const clearComplexById = () => ({
    type: types.LOADED_COMPLEX_BY_ID,
    payload: {}
});

export const loadTagList = (data) => ({
    type: types.LOAD_TAG_LIST,
    payload: data
});

export const loadedTagList = (data) => ({
    type: types.LOADED_TAG_LIST,
    payload: data
});

export const createTag = (data, callback) => ({
    type: types.CREATE_TAG_LIST,
    payload: data,
    callback
});

export const editTag = (data, id, callback) => ({
    type: types.EDIT_TAG_LIST,
    payload: { id, ...data },
    callback
});

export const deleteTag = (id, callback) => ({
    type: types.DELETE_TAG_LIST,
    payload: id,
    callback
});

export const complexSyncTags = (data, callback) => ({
    type: types.COMPLEX_SYNC_TAGS,
    payload: data,
    callback
});

export const loadExclusiveFiles = (page = 1, limit = 25, query = {}, storeField) => ({
    type: types.LOAD_EXCLUSIVE_FILES,
    payload: { page, limit, ...query },
    storeField
});

export const loadingExclusiveFiles = (bool = false) => ({
    type: types.LOADING_EXCLUSIVE_FILES,
    payload: bool
});

export const loadedExclusiveFiles = (data, storeField) => ({
    type: types.LOADED_EXCLUSIVE_FILES,
    payload: { data, storeField }
});

export const loadExclusiveFilesHistoryList = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_EXCLUSIVE_FILES_HISTORY_LIST,
    payload: { page, limit, ...query }
});
export const loadingExclusiveFilesHistoryList = (bool = false) => ({
    type: types.LOADING_EXCLUSIVE_FILES_HISTORY_LIST,
    payload: bool
});
export const loadedExclusiveFilesHistoryList = (data) => ({
    type: types.LOADED_EXCLUSIVE_FILES_HISTORY_LIST,
    payload: data
});
export const clearExclusiveFilesHistoryList = (data) => ({
    type: types.LOADED_EXCLUSIVE_FILES_HISTORY_LIST,
    payload: {
        data: [],
        meta: {},
    },
});

export const loadExclusiveFilesLogList = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_EXCLUSIVE_FILES_LOG_LIST,
    payload: { page, limit, ...query }
});

export const loadingExclusiveFilesLogList = (bool = false) => ({
    type: types.LOADING_EXCLUSIVE_FILES_LOG_LIST,
    payload: bool
});

export const loadedExclusiveFilesLogList = (data) => ({
    type: types.LOADED_EXCLUSIVE_FILES_LOG_LIST,
    payload: data
});

export const createExclusiveFile = (data, callback = () => {}) => ({
    type: types.CREATE_EXCLUSIVE_FILE,
    payload: data,
    callback
});

export const editExclusiveFile = (id, data, callback = () => {}) => ({
    type: types.EDIT_EXCLUSIVE_FILE,
    payload: { id, data },
    callback
});

export const deleteExclusiveFile = (id, callback = () => {}) => ({
    type: types.DELETE_EXCLUSIVE_FILE,
    payload: id,
    callback
});

export const loadExclusiveFileHistory = (page, limit, id) => ({
    type: types.LOAD_EXCLUSIVE_FILE_HISTORY,
    payload: { page, limit, id }
});

export const loadingExclusiveFileHistory = (bool = false) => ({
    type: types.LOADING_EXCLUSIVE_FILE_HISTORY,
    payload: bool
});

export const loadedExclusiveFileHistory = (data) => ({
    type: types.LOADED_EXCLUSIVE_FILE_HISTORY,
    payload: data
});

export const loadComplexTypeGrouping = () => ({
    type: types.LOAD_COMPLEX_TYPE_GROUPING
});

export const loadedComplexTypeGrouping = (data) => ({
    type: types.LOADED_COMPLEX_TYPE_GROUPING,
    payload: data
});

export const loadComplexMeasuring = () => ({
    type: types.LOAD_COMPLEX_MEASURING
});

export const loadedComplexMeasuring = (data) => ({
    type: types.LOADED_COMPLEX_MEASURING,
    payload: data
});

export const loadRecognitionCameraDirTypes = () => ({
    type: types.LOAD_RECOGNITION_CAMERA_DIR_TYPES
});

export const loadedRecognitionCameraDirTypes = (data) => ({
    type: types.LOADED_RECOGNITION_CAMERA_DIR_TYPES,
    payload: data
});

export const createComplexEntity = (url, data, callback) => ({
    type: types.CREATE_COMPLEX_ENTITY,
    payload: { url, data },
    callback
});

export const editComplexEntity = (url, data, callback) => ({
    type: types.EDIT_COMPLEX_ENTITY,
    payload: { url, data },
    callback
});
export const deleteComplexEntity = (url, data, callback) => ({
    type: types.DELETE_COMPLEX_ENTITY,
    payload: { url, data },
    callback
});

// transfer types для Вычислительного модуля конкретного типа комплекса
export const loadComputerTransferTypes = (params) => ({
    type: types.LOAD_COMPUTER_TRANSFER_TYPES,
    payload: params
});

export const loadedComputerTransferTypes = (data) => ({
    type: types.LOADED_COMPUTER_TRANSFER_TYPES,
    payload: data
});

export const loadComputerTransferRules = (id) => ({
    type: types.LOAD_COMPUTER_TRANSFER_RULES,
    payload: id
});

export const loadedComputerTransferRules = (data) => ({
    type: types.LOADED_COMPUTER_TRANSFER_RULES,
    payload: data
});

export const clearComputerTransferRules = () => ({
    type: types.CLEAR_COMPUTER_TRANSFER_RULES,
});

export const loadDorisExportTypes = () => ({
    type: types.LOAD_DORIS_EXPORT_TYPES,
});

export const loadedDorisExportTypes = (data) => ({
    type: types.LOADED_DORIS_EXPORT_TYPES,
    payload: data
});

export const loadChecklists= (params) => ({
    type: types.LOAD_CHECKLISTS,
    payload: params
});

export const loadingChecklists = (bool) => ({
    type: types.LOADING_CHECKLISTS,
    payload: bool
});

export const loadedChecklists = (params) => ({
    type: types.LOADED_CHECKLISTS,
    payload: params
});

export const createChecklists = (data, callback) => ({
    type: types.CREATE_CHECKLISTS,
    payload: data,
    callback
});

export const loadMaterialStorageGroups = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_MATERIAL_STORAGE_GROUPS,
    payload: { page, limit, ...params }
});

export const loadingMaterialStorageGroups = (bool) => ({
    type: types.LOADING_MATERIAL_STORAGE_GROUPS,
    payload: bool
});

export const loadedMaterialStorageGroups = (data) => ({
    type: types.LOADED_MATERIAL_STORAGE_GROUPS,
    payload: data
});

export const createMaterialStorageGroup = (data, callback) => ({
    type: types.CREATE_MATERIAL_STORAGE_GROUP,
    payload: data,
    callback
});

export const editChecklists = (id, data, callback) => ({
    type: types.EDIT_CHECKLISTS,
    payload: { id, data },
    callback
});

export const editMaterialStorageGroup = (id, data, callback) => ({
    type: types.EDIT_MATERIAL_STORAGE_GROUP,
    payload: { id, data },
    callback
});

export const deleteChecklists = (id, callback) => ({
    type: types.DELETE_CHECKLISTS,
    payload: id,
    callback
});

export const deleteMaterialStorageGroup = (id, callback) => ({
    type: types.DELETE_MATERIAL_STORAGE_GROUP,
    payload: id,
    callback
});

export const loadingButton = (bool) => ({
    type: types.LOADING_BUTTON,
    payload: bool
});

export const loadComplexesTree = (params) => ({
    type: types.LOAD_COMPLEXES_TREE,
    payload: params
});
export const loadedComplexesTree = (data) => ({
    type: types.LOADED_COMPLEXES_TREE,
    payload: data
});
export const loadingComplexesTree = (bool = false) => ({
    type: types.LOADING_COMPLEXES_TREE,
    payload: bool
});
export const clearComplexesTree = () => ({
    type: types.CLEAR_COMPLEXES_TREE,
});

export const loadViolationEvents = (page = 1, limit = 25, params) => ({
    type: types.LOAD_VIOLATION_EVENTS,
    payload: { page, limit, ...params }
});

export const loadingViolationEvents = (bool) => ({
    type: types.LOADING_VIOLATION_EVENTS,
    payload: bool
});

export const loadedViolationEvents = (data) => ({
    type: types.LOADED_VIOLATION_EVENTS,
    payload: data
});

export const loadEventCheckList = (base_name, params = {}, callback) => ({
    type: types.LOAD_EVENT_CHECK_LIST,
    payload: { base_name, params },
    callback
});

export const loadingEventCheckList = (bool) => ({
    type: types.LOADING_EVENT_CHECK_LIST,
    payload: bool
});

export const loadedEventCheckList = (data) => ({
    type: types.LOADED_EVENT_CHECK_LIST,
    payload: data
});

export const saveEventCheckList = (data, callback = () => {}) => ({
    type: types.SAVE_EVENT_CHECK_LIST,
    payload: data,
    callback
});

export const loadEventPDDS = (data, callback) => ({
    type: types.LOAD_EVENT_PDDS,
    payload: data,
    callback
});

export const loadingEventPDDS = (bool) => ({
    type: types.LOADING_EVENT_PDDS,
    payload: bool,
});

export const loadPddViolationsTree = (data, callback) => ({
    type: types.LOAD_PDD_VIOLATIONS_TREE,
    payload: { data },
    callback
});

export const loadCheckListGroup= (params) => ({
    type: types.LOAD_CHECKLIST_GROUP,
    payload: params
});

export const loadingCheckListGroup = (bool) => ({
    type: types.LOADING_CHECKLIST_GROUP,
    payload: bool
});

export const loadedCheckListGroup = (params) => ({
    type: types.LOADED_CHECKLIST_GROUP,
    payload: params
});

export const createCheckListGroup = (data, callback) => ({
    type: types.CREATE_CHECKLIST_GROUP,
    payload: data,
    callback
});

export const editCheckListGroup = (id, data, callback) => ({
    type: types.EDIT_CHECKLIST_GROUP,
    payload: { id, data },
    callback
});

export const deleteCheckListGroup = (id, callback) => ({
    type: types.DELETE_CHECKLIST_GROUP,
    payload: id,
    callback
});

/* check fgis link */
export const checkFgis = (link, callback) => ({
    type: types.CHECK_FGIS,
    payload: {
        link,
    },
    callback
});

export const loadDocumentsHistory = (page, limit, id) => ({
    type: types.LOAD_DOCUMENTS_HISTORY,
    payload: { id, page, limit },
});

export const loadedDocumentsHistory = (data) => ({
    type: types.LOADED_DOCUMENTS_HISTORY,
    payload: data,
});

export const loadingDocumentsHistory = (bool) => ({
    type: types.LOADING_DOCUMENTS_HISTORY,
    payload: bool,
});

export const loadEventsStatistic = () => ({
    type: types.LOAD_EVENTS_STATISTICS,
});
export const loadedEventsStatistic = (data) => ({
    type: types.LOADED_EVENTS_STATISTICS,
    payload: data,
});
export const loadingEventsStatistic = (bool) => ({
    type: types.LOADING_EVENTS_STATISTICS,
    payload: bool,
});

export const loadComplexByRadius = (data, callback = () => {}) => ({
    type: types.LOAD_COMPLEX_BY_RADIUS,
    payload: data,
    callback
});

export const loadedComplexByRadius = (data) => ({
    type: types.LOADED_COMPLEX_BY_RADIUS,
    payload: data
});

export const loadCameraViolationsTree = () => ({
    type: types.LOAD_CAMERA_VIOLATIONS_TREE,
});
export const loadedCameraViolationsTree = (data) => ({
    type: types.LOADED_CAMERA_VIOLATIONS_TREE,
    payload: data
});
export const loadingCameraViolationsTree = (bool = false) => ({
    type: types.LOADING_CAMERA_VIOLATIONS_TREE,
    payload: bool
});

export const loadPddReportExpanded = (data) => ({
    type: types.LOAD_PDD_REPORT_EXPANDED,
    payload: data
});

export const loadMaterialExportGroup = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_MATERIAL_EXPORT_GROUP,
    payload: { page, limit, params }
});

export const loadingMaterialExportGroup = (bool) => ({
    type: types.LOADING_MATERIAL_EXPORT_GROUP,
    payload: bool
});

export const loadedMaterialExportGroup = (data) => ({
    type: types.LOADED_MATERIAL_EXPORT_GROUP,
    payload: data
});

export const createMaterialExportGroup = (data, callback) => ({
    type: types.CREATE_MATERIAL_EXPORT_GROUP,
    payload: data,
    callback
});

export const editMaterialExportGroup = (id, data, callback ) => ({
    type: types.EDIT_MATERIAL_EXPORT_GROUP,
    payload: { id, data },
    callback
});

export const deleteMaterialExportGroup = (id, callback) => ({
    type: types.DELETE_MATERIAL_EXPORT_GROUP,
    payload: id,
    callback
});

export const loadMaterialExportGroupTypes = () => ({
    type: types.LOAD_MATERIAL_EXPORT_GROUP_TYPES,
});

export const loadedMaterialExportGroupTypes = (data) => ({
    type: types.LOADED_MATERIAL_EXPORT_GROUP_TYPES,
    payload: data
});

export const loadMaterialExportGroupProtocols = () => ({
    type: types.LOAD_MATERIAL_EXPORT_GROUP_PROTOCOLS
});

export const loadedMaterialExportGroupProtocols = (data) => ({
    type: types.LOADED_MATERIAL_EXPORT_GROUP_PROTOCOLS,
    payload: data
});

export const loadTypeOfMistakesForCheckList = (params) => ({
    type: types.LOAD_TYPE_OF_MISTAKES_FOR_CHECKLIST,
    payload: params
});

export const loadedTypeOfMistakesForCheckList = (params) => ({
    type: types.LOADED_TYPE_OF_MISTAKES_FOR_CHECKLIST,
    payload: params
});

export const loadingTypeOfMistakesForCheckList = (params) => ({
    type: types.LOADING_TYPE_OF_MISTAKES_FOR_CHECKLIST,
    payload: params
});

export const createTypeOfMistakesForCheckList = (params, callback) => ({
    type: types.CREATE_TYPE_OF_MISTAKES_FOR_CHECKLIST,
    payload: params,
    callback
});

export const editTypeOfMistakesForCheckList = (id, params, callback) => ({
    type: types.EDIT_TYPE_OF_MISTAKES_FOR_CHECKLIST,
    payload: { id, params },
    callback
});

export const deleteTypeOfMistakesForCheckList = (params, callback) => ({
    type: types.DELETE_TYPE_OF_MISTAKES_FOR_CHECKLIST,
    payload: params,
    callback
});

export const loadRecentPddReportExpanded = (data) => ({
    type: types.LOAD_RECENT_PDD_REPORT_EXPANDED,
    payload: data
});

// Settings Group
export const loadSettingsGroup = () => ({
    type: types.LOAD_SETTINGS_GROUP,
});

export const loadingSettingsGroup = (bool) => ({
    type: types.LOADING_SETTINGS_GROUP,
    payload: bool
});

export const loadedSettingsGroup = (data) => ({
    type: types.LOADED_SETTINGS_GROUP,
    payload: data
});

export const editSettingsGroup = (id, data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_GROUP,
    payload: { id, data },
    callback
});

export const loadShowCaseComplexStatus = () => ({
    type: types.LOAD_SHOW_CASE_COMPLEX_STATUS,
});
export const loadedShowCaseComplexStatus = (data) => ({
    type: types.LOADED_SHOW_CASE_COMPLEX_STATUS,
    payload: data
});
export const clearShowCaseComplexStatus = () => ({
    type: types.LOADED_SHOW_CASE_COMPLEX_STATUS,
    payload: {}
});
export const loadingShowCaseComplexStatus = (bool = false) => ({
    type: types.LOADING_SHOW_CASE_COMPLEX_STATUS,
    payload: bool
});

export const loadShowCaseDocumentsVerification = () => ({
    type: types.LOAD_SHOW_CASE_DOCUMENTS_VERIFICATION,
});
export const loadedShowCaseDocumentsVerification = (data) => ({
    type: types.LOADED_SHOW_CASE_DOCUMENTS_VERIFICATION,
    payload: data
});
export const clearShowCaseDocumentsVerification = () => ({
    type: types.LOADED_SHOW_CASE_DOCUMENTS_VERIFICATION,
    payload: {}
});
export const loadingShowCaseDocumentsVerification = (bool = false) => ({
    type: types.LOADING_SHOW_CASE_DOCUMENTS_VERIFICATION,
    payload: bool
});

export const loadDuploCodes = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_DUPLO_CODES,
    payload: { page, limit, ...params }
});

export const loadingDuploCodes = (bool) => ({
    type: types.LOADING_DUPLO_CODES,
    payload: bool
});

export const loadedDuploCodes = (data) => ({
    type: types.LOADED_DUPLO_CODES,
    payload: data
});

export const editDuploCodes = (id, data, callback = () => {}) => ({
    type: types.EDIT_DUPLO_CODES,
    payload: { id, data },
    callback
});

export const loadMaterials = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_MATERIALS,
    payload: { page, limit, ...params }
});

export const loadingMaterials = (bool) => ({
    type: types.LOADING_MATERIALS,
    payload: bool
});

export const loadedMaterials = (data) => ({
    type: types.LOADED_MATERIALS,
    payload: data
});

export const createMaterial = (data, callback) => ({
    type: types.CREATE_MATERIAL,
    payload: data,
    callback
});

export const editMaterial = (id, data, callback) => ({
    type: types.EDIT_MATERIAL,
    payload: { id, data },
    callback
});

export const deleteMaterial = (id, callback) => ({
    type: types.DELETE_MATERIAL,
    payload: id,
    callback
});

export const moveComputerToOtherComplex = (id, data, callback) => ({
    type: types.MOVE_COMPUTER_TO_OTHER_COMPLEX,
    payload: { id, data },
    callback
});

export const loadMaterialExport = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_MATERIAL_EXPORT,
    payload: { page, limit, ...params }
});

export const loadingMaterialExport = (bool) => ({
    type: types.LOADING_MATERIAL_EXPORT,
    payload: bool
});

export const loadedMaterialExport = (data) => ({
    type: types.LOADED_MATERIAL_EXPORT,
    payload: data
});

export const createMaterialExport = (data, callback) => ({
    type: types.CREATE_MATERIAL_EXPORT,
    payload: data,
    callback
});

export const editMaterialExport = (id, data, callback ) => ({
    type: types.EDIT_MATERIAL_EXPORT,
    payload: { id, data },
    callback
});

export const deleteMaterialExport = (id, callback) => ({
    type: types.DELETE_MATERIAL_EXPORT,
    payload: id,
    callback
});

export const cancelMaterialExport = (id, callback) => ({
    type: types.CANCEL_MATERIAL_EXPORT,
    payload: id,
    callback
});

export const loadMaterialExportTypes = () => ({
    type: types.LOAD_MATERIAL_EXPORT_TYPES,
});

export const loadedMaterialExportTypes = (data) => ({
    type: types.LOADED_MATERIAL_EXPORT_TYPES,
    payload: data
});

export const loadMaterialExportProtocols = () => ({
    type: types.LOAD_MATERIAL_EXPORT_PROTOCOLS
});

export const loadedMaterialExportProtocols = (data) => ({
    type: types.LOADED_MATERIAL_EXPORT_PROTOCOLS,
    payload: data
});

export const loadComplexConditions = () => ({
    type: types.LOAD_COMPLEX_CONDITIONS,
});

export const loadedComplexConditions = (data) => ({
    type: types.LOADED_COMPLEX_CONDITIONS,
    payload: data
});

export const loadMaterialExportStatuses = () => ({
    type: types.LOAD_MATERIAL_EXPORT_STATUSES,
});

export const loadedMaterialExportStatuses = (data) => ({
    type: types.LOADED_MATERIAL_EXPORT_STATUSES,
    payload: data
});
