import React, { useMemo } from 'react';
import { Line as GLine } from 'react-chartjs-2';

// линейный график
const Line = ({
    data = {},
    unit = '',
    color = '',
    labelFormat,
    styles = {}
}) => {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },

            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks: {
                    // title: ({ 0: context }) => {
                    //     const dataIndex = context?.dataIndex;
                    //     return `${context?.dataset?.label} - ${context?.dataset?.labelsPopUp?.[dataIndex] || ''}`;
                    //     // return `${context?.dataset?.label} - ${context?.label}`;
                    // },
                    label: (context) => {
                        const dataIndex = context?.dataIndex;
                        return ` ${context?.dataset?.data?.[dataIndex] ?? ''} ${context?.dataset?.unit || ''}`;
                    },
                },
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: unit,
                },
                beginAtZero: true,
            },
            x: {
                ticks: {
                    font: {
                        size: 10,
                    },
                },
            },
        },
        maintainAspectRatio: false,
    };

    const createDS = (values) => ({
        borderColor: color,
        backgroundColor: color,
        data: values,
        unit,
    });

    const defaultFormat = item => item;

    const dataG = useMemo(() => {
        const labels = Object.keys(data).map(labelFormat || defaultFormat);
        const values = Object.values(data);
        const datasets = [createDS(values)];
        return {
            labels,
            datasets
        };
    }, [data]);

    return (
        <GLine
            options={options}
            data={dataG}
            style={{ maxHeight: '100%', ...styles }}
        />
    );
};

export default Line;
