// иконка для кластера
const iconCluster = (props) => {
    return `
        <svg viewBox="0 0 107.000000 107.000000" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
            <g>
                <g id="svg_tl_4">
                    <g id="svg_tl_5">
                        <g fill="${props?.fill || '#e7e7e7'}" id="svg_tl_1" transform="translate(0,107) scale(0.10000000149011612,-0.10000000149011612)">
                            <path stroke="null" id="svg_tl_2" d="m404.52236,1047.19878c-227.70224,-55.17014 -397.22506,-272.84146 -397.22506,-511.57773c0,-285.88168 240.74246,-525.62105 526.62414,-525.62105c285.88167,0 526.62414,240.74246 526.62414,526.62414c0,237.73318 -172.5321,457.41068 -402.24054,511.57774c-79.24439,18.05568 -175.54138,18.05568 -253.78268,-1.0031z"/>
                        </g>
                    </g>
                </g>
                <g fill="${props?.innerFill || 'rgba(0, 0, 0, 0.54)'}" stroke="null" id="svg_tl_10">
                    <path stroke="null" id="svg_tl_9" d="m80.801532,44.50847l-6.825383,0l0,-6.442877c4.062525,-2.364711 6.825383,-6.717314 6.825383,-11.758144l-6.825383,0l0,-4.550255c0,-2.512594 -2.037661,-4.550255 -4.550255,-4.550255l-31.851787,0c-2.512594,0 -4.550255,2.037661 -4.550255,4.550255l0,4.550255l-6.825383,0c0,5.039408 2.762858,9.392011 6.825383,11.758144l0,6.442877l-6.825383,0c0,5.039408 2.762858,9.392011 6.825383,11.758144l0,6.442877l-6.825383,0c0,5.365035 3.124035,9.960793 7.625943,12.19184c2.370399,8.688144 10.236652,15.109692 19.675588,15.109692s17.30519,-6.421548 19.675588,-15.109692c4.501909,-2.231047 7.625943,-6.826805 7.625943,-12.19184l-6.825383,0l0,-6.442877c4.062525,-2.366133 6.825383,-6.718736 6.825383,-11.758144zm-13.650766,25.026404c0,7.526407 -6.122937,13.650766 -13.650766,13.650766c-7.526407,0 -13.650766,-6.124359 -13.650766,-13.650766l0,-45.502553l27.301532,0l0,45.502553zm-13.650766,-27.301532c3.769602,0 6.825383,-3.055781 6.825383,-6.825383s-3.055781,-6.825383 -6.825383,-6.825383s-6.825383,3.055781 -6.825383,6.825383s3.055781,6.825383 6.825383,6.825383zm0,18.201021c3.769602,0 6.825383,-3.055781 6.825383,-6.825383s-3.055781,-6.825383 -6.825383,-6.825383s-6.825383,3.055781 -6.825383,6.825383s3.055781,6.825383 6.825383,6.825383zm0,-10.238074c1.881246,0 3.412691,1.531445 3.412691,3.412691s-1.531445,3.412691 -3.412691,3.412691s-3.412691,-1.531445 -3.412691,-3.412691s1.531445,-3.412691 3.412691,-3.412691zm0,28.439096c3.769602,0 6.825383,-3.055781 6.825383,-6.825383s-3.055781,-6.825383 -6.825383,-6.825383s-6.825383,3.055781 -6.825383,6.825383s3.055781,6.825383 6.825383,6.825383zm0,-10.238074c1.881246,0 3.412691,1.531445 3.412691,3.412691s-1.531445,3.412691 -3.412691,3.412691s-3.412691,-1.531445 -3.412691,-3.412691s1.531445,-3.412691 3.412691,-3.412691z"/>
                </g>
            </g>
        </svg>
    `;
};

export default iconCluster;
