import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import { incidentsSelectors } from 'redux/Incidents';
import { loadReportCategory } from 'redux/Incidents/actions';
import Loading from 'components/common/Loading';
import icon from 'components/MapComponents/Layers/Incidents/icons/icon';
import {
    fullDateTimeWithoutSeconds,
} from 'helpers/date.config';
import useReportCategoryTable from 'helpers/hooks/Incidents/useReportCategoryTable';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import DtpInfoModal from 'components/pages/Dictionaries/SituationalPlans/TransportIncidents/DtpInfoModal';

import Item from './Item';

const ClusterInfo = (props) => {
    const {
        id, // id дтп
        cluster, // кластер по дтп
    } = props;

    const reportCategoryFilter = useSelector(incidentsSelectors.reportCategoryFilter);

    const [showDtpId, setShowDtpId] = useState(null);

    const category = reportCategoryFilter.category || [];
    const reportCategory = useStoreProp(loadReportCategory, 'incidents', 'reportCategory');
    const categoryIds = category.length > 0 ? category.map(({ id }) => id) : Object.keys(reportCategory);

    const tableProvider = useReportCategoryTable();

    useEffect(() => {
        return () => {
            tableProvider.clear();
        };
    }, []);
    // получаем данные по id
    useEffect(() => {
        if (id) {
            tableProvider.load({ ids: [id] });
        }
    }, [id]);

    // получаем данные по кластеру
    useEffect(() => {
        if (cluster && cluster.getChildCount() > 0) {
            const markers = cluster.getAllChildMarkers();
            const ids = markers.reduce((res, marker) => {
                const attribution = marker.getAttribution() || {};
                const dtpId = attribution.id || null;
                if (dtpId && !res.includes(dtpId)) {
                    return [
                        ...res,
                        dtpId,
                    ];
                }
                return res;
            }, []);
            tableProvider.load({ ids });
        }
    }, [cluster]);

    return (
        <div className="map-popup">
            <div
                className="map-popup_header"
                // style={{ backgroundColor: getColor(data, types) }}
            >
                <div>
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                    <span>{fullDateTimeWithoutSeconds(reportCategoryFilter.start_date)}</span>
                    <span> - </span>
                    <span>{fullDateTimeWithoutSeconds(reportCategoryFilter.end_date)}</span>
                </div>
            </div>
            <div className="map-popup_body">

                {tableProvider.loading ? (
                    <Loading className="center padding" />
                ) : (
                    <div>
                        {tableProvider.data.length > 0 ? (
                            <TableContainer
                                component={Paper}
                                style={{
                                    maxHeight: '280px',
                                    borderRadius: '0',
                                    boxShadow: 'none',
                                }}
                            >
                                <Table
                                    stickyHeader
                                    aria-label="sticky table"
                                    size="small"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={180}></TableCell>
                                            <TableCell align="center">{titles.DTP_COUNT}</TableCell>

                                            {categoryIds.map((catId) => (
                                                <TableCell align="center" key={catId}>{reportCategory[catId].name}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableProvider.data.map((row, indexRow) => {
                                            const {
                                                month,
                                                counts,
                                            } = row;

                                            return (
                                                <Item
                                                    key={`cluster-info-${indexRow}`}
                                                    month={month}
                                                    counts={counts}
                                                    categoryIds={categoryIds}
                                                    onShowDtp={(id) => setShowDtpId(id)}
                                                />
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <div>{messages.INFO_IS_NOT_FOUND}</div>
                        )}

                        {showDtpId && (
                            <DtpInfoModal
                                title={titles.DTP}
                                isOpen={!!showDtpId}
                                setOpen={() => setShowDtpId(null)}
                                dtpId={showDtpId}
                                // dtpId={1045837} // id для проверки
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ClusterInfo;
