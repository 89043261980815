import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemText, } from '@mui/material';
import { Comment } from '@mui/icons-material';

import {
    deleteMessageTemplate,
    loadMessageTemplates,
} from 'redux/Boards/actions';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from '../Messages/ModalForm';
import PreviewMessage from '../MessageHelpers/PreviewMessage';
import AddMessageToBoard from './ModalForm/AddMessageToBoard';

const Item = ({ el = {}, priorities = {}, }) => {
    const dispatch = useDispatch();
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const reloadData = () => dispatch(loadMessageTemplates({ page: 1, limit: 25 }));
    const handleConfirmDelete = () => {
        dispatch(deleteMessageTemplate(el.id, reloadData));
        setIsDelete(false);
    };

    const [isShowPreviewMessage, setIsShowPreviewMessage] = useState(false);
    const [boardForPreview, setBoardForPreview] = useState();
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <ListItem className="list_item">
                <ListItemText>
                    <h2>
                        {el?.header || messages.INFO_IS_NOT_FOUND}
                    </h2>
                    <div>
                        <strong>
                            Кол-во показов:
                        </strong>&nbsp;
                        {el?.count || messages.NO_DATA}
                        {' '}
                        <strong>
                        Время показа в секундах:
                        </strong>&nbsp;
                        {el?.duration || messages.NO_DATA}
                    </div>
                    <div>
                        <strong>
                            Приоритет:
                        </strong>&nbsp;
                        {el.priority_text || messages.NO_DATA}
                    </div>
                </ListItemText>

                <FormButtonsComponent
                    noMarginLeft
                    justButton
                    buttons={[
                        {
                            icon: <Comment />,
                            size: 'small',
                            name: 'Добавить сообщение на ДИТ',
                            onClick: () => setOpenModal(true),
                            accessProps: 'is_update',
                        },
                        {
                            icon: <Comment style={{ color: '#148709' }}  />,
                            size: 'small',
                            name: 'Просмотр сообщения',
                            onClick: () => setIsShowPreviewMessage(true),
                            accessProps: 'is_update',
                        },
                        ...(!el?.service
                            ? [{
                                ...buttonsTypes.editIcon,
                                onClick: () => setIsEditMode(true)
                            }]
                            : []
                        ),
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick:() => setIsDelete(true)
                        }
                    ]}
                />
            </ListItem>
            {openModal && (
                <AddMessageToBoard
                    item={el}
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                />
            )}
            {isDelete &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={isDelete}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setIsDelete(false)}
                />
            )}
            {isEditMode && (
                <ModalForm
                    isOpen={isEditMode}
                    onClose={() => setIsEditMode(false)}
                    isNew={false}
                    isCopy={false}
                    priorities={priorities}
                    item={el}
                    template={true}
                />
            )}
            {isShowPreviewMessage && (
                <PreviewMessage
                    isOpen={isShowPreviewMessage}
                    onClose={() => setIsShowPreviewMessage(false)}
                    template={true}
                    text={el?.text}
                    boardForPreview={boardForPreview}
                    setBoardForPreview={setBoardForPreview}
                />
            )}

        </>
    );
};

export default Item;
