import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { loadVideoCamerasDictionaryConnectionLine } from 'redux/RoadNetwork/actions';
import SelectCompany from 'components/common/Autocomplete/Companies';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{page: number, data: {}}>>
}

const initialState = { 
    name: '',
    type_id: '',
    organization_id_list: []
};

const Filter = ({ setParams }: FilterProps) => {
    const types = useStoreProp(
        loadVideoCamerasDictionaryConnectionLine, 
        'roadNetworks', 
        'videoCamerasDictionaryConnectionLine'
    );

    const [data, setData] = useState(initialState);

    const handleChange = (name: string, value: any) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const onSearch = (filters = data) => {
        const prepareData = {
            ...filters,
            organization_id_list: filters?.organization_id_list.map(({ id }) => id)
        };
        setParams({ page: 1, data: removeEmptyFields(prepareData) });
    };

    const resetFilter = (needRefresh: boolean) => {
        needRefresh && onSearch(initialState);
        setData(initialState);
    };

    const updateFilter = (filters: any) => {
        const newFilter = { ...initialState, ...filters };
        setData(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };

    return (
        <LayoutFilter
            filter={data}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={data.name}
                    name="name"
                    onChange={(e) => handleChange('name', e.target.value)}
                    label={titles.NAME}
                    size="small"
                    variant="outlined"
                />

                <FormControl size="small"  variant="outlined">
                    <InputLabel>{titles.TYPE}</InputLabel>
                    <Select
                        value={data.type_id}
                        name="type_id"
                        onChange={(e) => handleChange('type_id', e.target.value)}
                        label={titles.TYPE}
                    >
                        <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                        {Object.keys(types).map((key) => (
                            <MenuItem key={key} value={key}>{types[key]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <SelectCompany
                    multiple
                    label={'Собственник оборудования'}
                    selected={data?.organization_id_list}
                    onChange={(value) => handleChange('organization_id_list', value)}
                    selectDeletedCompanies
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;