import React, { useEffect, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import { loadWorkorderReport } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { REPORT_FORMATS } from 'helpers/report.formats';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { dateWithDashYYYYMMDD, getEndOf } from 'helpers/date.config';
import messages from 'helpers/constants/messages';

import Item from './Item';
import Filter from './Filter';

const WorkordersReport = () => {
    const dispatch = useDispatch();

    const initialState = {
        organization_id: '',
        route_id_list: [],
        date: getEndOf('day'),
    };

    const [params, setParams] = useState(initialState);

    const formats = REPORT_FORMATS;

    const workordersByReportData = useSelector(transportPassengerSelectors.workordersByReportData);
    const loading = useSelector(transportPassengerSelectors.loadingWorkordersByReport);

    const prepareData = (filters = params) => removeEmptyFields({
        organization_id: filters.organization_id?.id || '',
        route_id_list: filters.route_id_list.length > 0 ? filters.route_id_list?.map(item => item.id) : null,
        date: dateWithDashYYYYMMDD(filters.date),
    });

    const handleDownloadReport = () => {
        dispatch(loadWorkorderReport(1, 25, { ...prepareData(), report: formats.XLS } ));
    };

    const handleFind = (filters = params) => {
        dispatch(loadWorkorderReport(1, 25, prepareData(filters)));
    };

    useEffect(() => {
        handleFind();
    }, []);

    const isDisabledReport = !params.organization_id
    || !params.date || !params.route_id_list.length;

    const renderContent = () => {
        return <>
            {workordersByReportData?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: 'График №', width: '20%' },
                            { title: 'Действия', align: 'right', isActions: true }
                        ]}
                    >
                        <Virtuoso
                            data={workordersByReportData}
                            itemContent={(index, item) => 
                                <Item
                                    key={item.id}
                                    item={item}
                                    index={index}
                                />
                            }
                        />
                    </LSContainer>
                )
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>;
    };

    return (
        <PageLayout
            header="Отчет по нарядам"
            filters={<Filter
                setParams={setParams}
                prepareData={prepareData}
                initialState={initialState}
                params={params}
                handleFind={handleFind}
            />}
            loading={loading}
            informPanelProps={{
                buttons: <FormButtonsComponent
                    noPadding
                    positionLeft
                    showDisabled
                    buttons={[
                        {
                            ...buttonsTypes.report,
                            onClick: handleDownloadReport,
                            endIcon: null,
                            disabled: isDisabledReport
                        }
                    ]}
                />,
            }}
            content={renderContent}
            customStyles={{ padding: 0, display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
        />
    );

};

export default WorkordersReport;