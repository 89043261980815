import iconCluster from './icons/iconCluster';
import { clearFilters } from '../../../../redux/TrafficLights/actions';

const config = {
    name: 'Светофоры',
    slug: 'traffic-lights',
    // mapContextMenu: {
    //     title: 'Добавить светофор',
    //     event: 'add_traffic_light',
    //     // className: styles.button,
    //     minWidth: 210,
    // },
    // mapMarkersColors: { // от статуса
    //     1: '#00ae5c', // Включен
    //     2: '#808080', // Выключен
    //     default: '#ad1818',
    // },
    clusterIcon: iconCluster,
    onAfterActivateAction: () => clearFilters(),
    layerIcon: 'far fa-traffic-light-stop',
};
// добавление на карте
export default config;
