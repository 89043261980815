import React, { useEffect, useState } from 'react';
import {
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import titles from '../../../../../../helpers/constants/titles';
import Modal from '../../../../../common/Modal';
import FormButtonsComponent, { buttonsTypes } from '../../../../../common/FormButtons';
import { useValidation } from '../../../../../../helpers/hooks';
import SingleKeyboardDateTimePicker from '../../../../../common/Dates/SingleKeyboardDateTimePicker';
import * as actions from '../../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../../redux/TransportPassenger';
import UniversalSelect from '../../../../../common/UniversalSelect';
import { loadCompanies } from '../../../../../../redux/Companies/actions';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Loading from '../../../../../common/Loading';
import { pick, isEqual } from 'lodash';
import Charterer from './Charterer';
import {
    fullDateTimeWithTimeZone,
    fullDateTimeWithoutSeconds,
} from '../../../../../../helpers/date.config';


function AddEditRequisites({ data, onClose = () => {}, isOpen }) {
    const dispatch = useDispatch();

    const requisiteListData = useSelector(transportPassengerSelectors.requisiteListData);
    const loadingRequisite = useSelector(transportPassengerSelectors.loadingRequisite);

    useEffect(() => {
        if (data?.id) {
            dispatch(actions.loadWoRequisiteList(1, 1, { work_order_ids: [data.id] }));
        }
    }, [data?.id]);

    useEffect(() => {
        if (requisiteListData?.length > 0) {
            const currentItem = requisiteListData?.[0] || null;
            if (currentItem) {
                setFormValues((oldValues) => ({
                    ...oldValues,
                    ...currentItem
                }));
                seInitialFormValues((oldValues) => ({
                    ...oldValues,
                    ...currentItem
                }));
            }
            dispatch(actions.loadedWoRequisiteList());
        }
    }, [requisiteListData]);

    const validation = useValidation();

    const initialData = {
        // Идентификатор наряда
        work_order_id: data.id,
        // passengers_count: 0,
        // instance_number: '',
        // date: '',
        // number: '',
        // using_price: '',
    };

    const [formValues, setFormValues] = useState(initialData);
    const [initialFormValues, seInitialFormValues] = useState(initialData);
    const [organizationModal, setOrganizationModal] = useState(false);
    const [isOpenEmployeesSelect, setIsOpenEmployeesSelect] = useState(false);
    const [isOpenPositionsSelect, setIsOpenPositionsSelect] = useState(false);
    const [isOpenChartererSelect, setIsOpenChartererSelect] = useState(false);

    const handleChangeDate = (newDate) => {
        setFormValues((oldValue) => ({
            ...oldValue,
            date: newDate,
        }));
        validation.deleteKey('date');
    };

    const handleChangeTF = (event) => {
        const {
            name,
            value,
        } = event.target;

        setFormValues((oldValue) => ({
            ...oldValue,
            [name]: value,
        }));
        validation.deleteKey(name);
    };

    const getOrganizations = (params) => {
        const { page, limit, query } = params;
        dispatch(loadCompanies(page, limit, { title: query }));
    };

    const getEmployees = (params) => {
        const { page, limit, query } = params;
        dispatch(actions.loadEmployees(page, limit, { title: query }));
    };

    const getPositions = (params) => {
        const { page, limit, query } = params;
        dispatch(actions.loadPositions(page, limit, { title: query }));
    };

    const handleAccept = () => {
        const params = {
            ...formValues,
            freighter_id: formValues?.freighter?.id || null,
            date: formValues?.date ? fullDateTimeWithTimeZone(formValues?.date) : null,
            user_id: formValues?.user?.id || null,
        };

        //const woParams = Object.keys(params).reduce((res, key) => params[key] ? { ...res, [key]: params[key] } : res, {});

        if (formValues.id) {
            dispatch(actions.editWoRequisiteList(formValues.id, params, () => onClose()));
        } else {
            dispatch(actions.createWoRequisite(params, () => onClose()));
        }
    };

    // форма измененна
    const isChange = !isEqual(formValues, initialFormValues);

    return (
        <Modal
            // title={isNew ? titles.ADD_WORKORDER : titles.EDIT_WORKORDER}
            title={!formValues?.id ? 'Добавить реквизиты' : 'Редактировать реквизиты'}
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose,
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: !isChange,
                        onClick: handleAccept,
                    },
                ]}
            />}
        >
            <form className="modal__form">

                {/*date_plan_arrival, date_plan_departure,  drivers,  stations, vehicle_mark, vehicle_grz*/}

                {loadingRequisite && (
                    <Loading circular={true} />
                )}

                {/*Экземпляр No*/}
                <div className="block">
                    <TextField
                        className="row__item"
                        value={formValues?.instance_number || ''}
                        variant="outlined"
                        size="small"
                        label="Экземпляр No"
                        name="instance_number"
                        onChange={handleChangeTF}
                        error={validation.isKey('instance_number')}
                        helperText={validation.get('instance_number')}
                    />
                </div>

                {/*Дата*/}
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        onChange={handleChangeDate}
                        // label={titles.DATE}
                        label="Дата"
                        value={formValues.date}
                        error={validation.isKey('date')}
                        helperText={validation.get('date')}
                        pickerFormat={'dd.MM.yyyy HH:mm'}
                        // pickerFormat={dateFormat.DATE_FOR_PICKER}
                    />
                </div>

                {/*№*/}
                <div className="block">
                    <TextField
                        className="row__item"
                        value={formValues?.number || ''}
                        variant="outlined"
                        size="small"
                        label="№"
                        name="number"
                        onChange={handleChangeTF}
                        error={validation.isKey('number')}
                        helperText={validation.get('number')}
                    />
                </div>

                {/*Фрахтователь*/}
                {/*фамилия, имя, отчество (при наличии), адрес места жительства, номер телефона - для физического лица */}
                {/*полное наименование, адрес места нахождения, номер телефона - для юридического лица*/}
                <Charterer
                    data={formValues?.charterer || {}}
                    onChange={(newValues) => {
                        setFormValues((oldValue) => ({
                            ...oldValue,
                            charterer: newValues
                        }));
                    }}
                />

                {/*Фрахтовщик = ид организаци (из справочника) (Наименование организации, адрес, ИНН, телефон)*/}
                <div className="block">
                    <TextField
                        // className="row__item"
                        value={formValues?.freighter?.title || ''}
                        variant="outlined"
                        size="small"
                        label="Фрахтовщик"
                        name="freighter_id"
                        disabled={true}
                        error={validation.isKey('freighter_id')}
                        helperText={validation.get('freighter_id')}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {formValues?.freighter
                                        ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setFormValues((oldValue) => ({ ...oldValue, freighter: null, freighter_id: null }))}
                                                    edge="end"
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                        : null
                                    }
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setOrganizationModal(true)}
                                            edge="end"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                </>
                            )
                        }}
                    />

                    {formValues?.freighter && (
                        <>
                            <div className="block">
                                <TextField
                                    className="row__item"
                                    value={(() => {
                                        const currentData = formValues?.freighter?.real_address
                                            ? pick(formValues?.freighter?.real_address, ['region', 'city_name', 'street', 'house'])
                                            : {};
                                        return Object.keys(currentData).reduce((res, key) => currentData[key] ? [ ...res, currentData[key] ] : res, []).join(', ');
                                    })()}
                                    variant="outlined"
                                    size="small"
                                    label="Адрес"
                                    disabled={true}
                                />
                            </div>

                            <div className="block">
                                <TextField
                                    className="row__item"
                                    value={formValues?.freighter?.inn || ''}
                                    variant="outlined"
                                    size="small"
                                    label="ИНН"
                                    disabled={true}
                                />
                            </div>

                            <h3>Телефоны</h3>
                            {formValues?.freighter?.phones?.length > 0 && formValues?.freighter?.phones?.map(({ data, person }, index) =>
                                <div key={index} className="row">
                                    <TextField
                                        className="row__item"
                                        value={person}
                                        variant="outlined"
                                        size="small"
                                        label="Персона"
                                        disabled={true}
                                    />

                                    <TextField
                                        className="row__item"
                                        value={data}
                                        variant="outlined"
                                        size="small"
                                        label="Телефон"
                                        disabled={true}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>

                {/*Ответственный за перевозку - из сотрудников*/}
                <div className="block">
                    <TextField
                        className="row__item"
                        value={formValues?.user?.fio || ''}
                        variant="outlined"
                        size="small"
                        label="Ответственный за перевозку"
                        disabled={true}
                        error={validation.isKey('user_id')}
                        helperText={validation.get('user_id')}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {formValues?.user
                                        ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setFormValues((oldValue) => ({ ...oldValue, user: null, user_id: null }))}
                                                    edge="end"
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                        : null
                                    }
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setIsOpenEmployeesSelect(true)}
                                            edge="end"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                </>
                            )
                        }}
                    />
                </div>

                {/*Стоимость пользования ТС (заполняются вручную)*/}
                <div className="block">
                    <TextField
                        className="row__item"
                        value={formValues?.using_price || ''}
                        variant="outlined"
                        size="small"
                        label="Стоимость пользования ТС"
                        name="using_price"
                        onChange={handleChangeTF}
                        error={validation.isKey('using_price')}
                        helperText={validation.get('using_price')}
                        // disabled={true}
                    />
                </div>

                {/*Кол-во пассажиров (заполняются вручную)*/}
                <div className="block">
                    <TextField
                        className="row__item"
                        value={formValues?.passengers_count || ''}
                        variant="outlined"
                        size="small"
                        label="Кол-во пассажиров"
                        name="passengers_count"
                        onChange={handleChangeTF}
                        error={validation.isKey('passengers_count')}
                        helperText={validation.get('passengers_count')}
                        // disabled={true}
                    />
                </div>

                {/*должность уполномоченного Фрахтователя за проведение расчетов (выбирается из справочника должностей)*/}
                <div className="block">
                    <TextField
                        className="row__item"
                        value={formValues?.charterer_position || ''}
                        // value={formValues?.charterer_position_ob?.name || ''}
                        variant="outlined"
                        size="small"
                        label="Должность уполномоченого фрахтователя за проведение расчётов"
                        disabled={true}
                        error={validation.isKey('charterer_position')}
                        helperText={validation.get('charterer_position')}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {formValues?.charterer_position
                                        ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setFormValues((oldValue) => ({ ...oldValue, charterer_position: null }))}
                                                    // onClick={() => setFormValues({ ...formValues, charterer_position: null, charterer_position_ob: null })}
                                                    edge="end"
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                        : null
                                    }
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setIsOpenPositionsSelect(true)}
                                            edge="end"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                </>
                            )
                        }}
                    />
                </div>


                {/*ФИО уполномоченного Фрахтователя (выбирается из справочника сотрудников)*/}
                <div className="block">
                    <TextField
                        className="row__item"
                        value={formValues?.charterer_fio || ''}
                        variant="outlined"
                        size="small"
                        label="ФИО уполномоченного Фрахтователя"
                        name="charterer_fio"
                        disabled={true}
                        error={validation.isKey('charterer_fio')}
                        helperText={validation.get('charterer_fio')}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {formValues?.charterer_fio
                                        ? (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setFormValues((oldValue) => ({ ...oldValue, charterer_fio: null }))}
                                                    edge="end"
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                        : null
                                    }
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setIsOpenChartererSelect(true)}
                                            edge="end"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                </>
                            )
                        }}
                    />

                </div>


                {/* ТС */}
                <div className="row">
                    {formValues?.vehicle_mark && (
                        <TextField
                            className="row__item"
                            value={formValues?.vehicle_mark || ''}
                            variant="outlined"
                            size="small"
                            label="Марка ТС"
                            disabled={true}
                        />
                    )}
                    {formValues?.vehicle_grz && (
                        <TextField
                            className="row__item"
                            value={formValues?.vehicle_grz || ''}
                            variant="outlined"
                            size="small"
                            label="Регистрационный знак ТС"
                            disabled={true}
                        />
                    )}
                </div>

                {/* дата подачи тс */}
                {/*{formValues?.work_order?.date_at && (*/}
                {/*    <div className="block">*/}
                {/*        <TextField*/}
                {/*            className="row__item"*/}
                {/*            value={formValues?.work_order?.date_at || ''}*/}
                {/*            variant="outlined"*/}
                {/*            size="small"*/}
                {/*            label="Дата подачи ТС"*/}
                {/*            disabled={true}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*)}*/}

                {formValues?.date_plan_arrival && (
                    <div className="block">
                        <TextField
                            className="row__item"
                            value={fullDateTimeWithoutSeconds(formValues?.date_plan_arrival) || ''}
                            variant="outlined"
                            size="small"
                            label="Плановые часы и минуты прибытия ТС в пункт подачи"
                            disabled={true}
                        />
                    </div>
                )}

                {formValues?.date_plan_arrival && (
                    <div className="block">
                        <TextField
                            className="row__item"
                            value={fullDateTimeWithoutSeconds(formValues?.date_plan_departure) || ''}
                            variant="outlined"
                            size="small"
                            label="Плановые часы и минуты убытия ТС после завершения перевозки"
                            disabled={true}
                        />
                    </div>
                )}

                {/* остановки */}
                {formValues?.stations && (
                    <>
                        <h3>Пункты подачи ТС</h3>
                        {formValues?.stations?.map(({ id, name, pivot: { time_start, time_end } }, index) => (
                            <div key={`station_item_${id}_${index}`} className="row">
                                <TextField
                                    className="row__item"
                                    value={name || ''}
                                    variant="outlined"
                                    size="small"
                                    label="Остановка"
                                    disabled={true}
                                />
                                <TextField
                                    className="row__item"
                                    value={`${time_start} - ${time_end}`}
                                    variant="outlined"
                                    size="small"
                                    label="Время прибытия и отбытия"
                                    disabled={true}
                                />
                            </div>
                        ))}
                    </>
                )}

                {/* фамилии и инициалы водителей */}
                {formValues?.drivers?.length > 0 && (
                    <>
                        <h3>Водители</h3>
                        {formValues?.drivers?.map(({ fio_full, fio }, index) => (
                            <div key={`driver_item_${index}`} className="block">
                                <TextField
                                    className="row__item"
                                    value={fio}
                                    variant="outlined"
                                    size="small"
                                    label="Водитель"
                                    disabled={true}
                                />
                            </div>
                        ))}
                    </>
                )}

                {/* организации */}
                {organizationModal 
                    && <UniversalSelect
                        storeName={'companies'}
                        storeNameProps={'companies'}
                        keyProp={'id'}
                        fetchList={getOrganizations}
                        withSearch={true}
                        isSelected={true}
                        onAccept={(value) => {
                            setFormValues((oldValue) => ({
                                ...oldValue,
                                freighter: value
                            }));
                            setOrganizationModal(false);
                        }}
                        selected={formValues.freighter}
                        renderProps={(el) => <div><span>{el.title}</span></div>}
                        isOpen={organizationModal}
                        onClose={() => setOrganizationModal(false)}
                        noPadding={true}
                        title={titles.SELECT_ORGANIZATION}
                        small={true}
                    />
                }

                {/* сотрудники */}
                {isOpenEmployeesSelect
                    && <UniversalSelect
                        storeName={'transportPassenger'}
                        storeNameProps={'employees'}
                        keyProp={'id'}
                        fetchList={getEmployees}
                        withSearch={true}
                        isSelected={true}
                        onAccept={(value) => {
                            setFormValues((oldValue) => ({ ...oldValue, user: value }));
                            setIsOpenEmployeesSelect(false);
                        }}
                        selected={formValues.user}
                        renderProps={(el) => <div><span>{el.fio}</span></div>}
                        isOpen={isOpenEmployeesSelect}
                        onClose={() => setIsOpenEmployeesSelect(false)}
                        noPadding={true}
                        label={titles.EMPLOYEES}
                        small={true}
                    />
                }

                {/* сотрудники */}
                {isOpenChartererSelect
                    && <UniversalSelect
                        storeName={'transportPassenger'}
                        storeNameProps={'employees'}
                        keyProp={'id'}
                        fetchList={getEmployees}
                        withSearch={true}
                        // isSelected={true}
                        onAccept={(value) => {
                            setFormValues((oldValue) => ({ ...oldValue, charterer_fio: value.fio }));
                            setIsOpenChartererSelect(false);
                        }}
                        // selected={formValues.charterer_fio}
                        renderProps={(el) => <div><span>{el.fio}</span></div>}
                        isOpen={isOpenChartererSelect}
                        onClose={() => setIsOpenChartererSelect(false)}
                        noPadding={true}
                        label={titles.EMPLOYEES}
                        small={true}
                    />
                }

                {/* должности */}
                {isOpenPositionsSelect
                    && <UniversalSelect
                        storeName={'transportPassenger'}
                        storeNameProps={'positions'}
                        keyProp={'id'}
                        fetchList={getPositions}
                        withSearch={true}
                        // isSelected={true}
                        onAccept={(value) => {
                            setFormValues((oldValue) => ({ ...oldValue, charterer_position: value.name }));
                            setIsOpenPositionsSelect(false);
                        }}
                        // onChange={(value) => setFormValues({ ...formValues, charterer_position_ob: value })}
                        // selected={formValues.charterer_position_ob}
                        renderProps={(el) => <div><span>{el.name}</span></div>}
                        isOpen={isOpenPositionsSelect}
                        onClose={() => setIsOpenPositionsSelect(false)}
                        noPadding={true}
                        title={titles.POSITION}
                        small={true}
                    />
                }
            </form>
        </Modal>
    );
}

export default AddEditRequisites;
