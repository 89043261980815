import moduleName from './module';
const prefix = `${moduleName}`;

export const LOAD_LIST = `${prefix}/LOAD_LIST`;
export const LOADED_LIST = `${prefix}/LOADED_LIST`;
export const LOADING_LIST = `${prefix}/LOADING_LIST`;
export const LOAD_TEMPLATES = `${prefix}/LOAD_TEMPLATES`;
export const LOADED_TEMPLATES = `${prefix}/LOADED_TEMPLATES`;
export const LOAD_FORMATS = `${prefix}/LOAD_FORMATS`;
export const LOAD_FORMATS_NEW = `${prefix}/LOAD_FORMATS_NEW`;
export const LOADED_FORMATS = `${prefix}/LOADED_FORMATS`;
export const DELETE_REPORT = `${prefix}/DELETE_REPORT`;
export const DELETE_ALL_REPORTS = `${prefix}/DELETE_ALL_REPORTS`;

export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;

export const LOAD_SCHEDULE_REPORT = `${prefix}/LOAD_SCHEDULE_REPORT`;
export const LOADED_SCHEDULE_REPORT = `${prefix}/LOADED_SCHEDULE_REPORT`;
export const LOADING_SCHEDULE_REPORT = `${prefix}/LOADING_SCHEDULE_REPORT`;

export const CREATE_SCHEDULE_REPORT = `${prefix}/CREATE_SCHEDULE_REPORT`;
export const EDIT_SCHEDULE_REPORT = `${prefix}/EDIT_SCHEDULE_REPORT`;
export const DELETE_SCHEDULE_REPORT = `${prefix}/DELETE_SCHEDULE_REPORT`;
export const LOADING_SCHEDULE_REPORT_FORM = `${prefix}/LOADING_SCHEDULE_REPORT_FORM`;
export const LOAD_SCHEDULE_REPORT_TEMPLATE = `${prefix}/LOAD_SCHEDULE_REPORT_TEMPLATE`;
export const LOADED_SCHEDULE_REPORT_TEMPLATE = `${prefix}/LOADED_SCHEDULE_REPORT_TEMPLATE`;

export const LOAD_PUID_TF_INDICATORS = `${prefix}/LOAD_PUID_TF_INDICATORS`;
export const LOAD_PUID_TF_INDICATORS_CONSOLIDATE = `${prefix}/LOAD_PUID_TF_INDICATORS_CONSOLIDATE`;
export const LOADING_PUID = `${prefix}/LOADING_PUID`;

export const LOAD_REPORT_TEMPLATE_FRONTEND_LINKS = `${prefix}/LOAD_REPORT_TEMPLATE_FRONTEND_LINKS`;
export const LOADED_REPORT_TEMPLATE_FRONTEND_LINKS = `${prefix}/LOADED_REPORT_TEMPLATE_FRONTEND_LINKS`;
export const LOADING_REPORT_TEMPLATE_FRONTEND_LINKS = `${prefix}/LOADING_REPORT_TEMPLATE_FRONTEND_LINKS`;
export const LOAD_API_REPORT_TEMPLATE_FRONTEND_LINK = `${prefix}/LOAD_API_REPORT_TEMPLATE_FRONTEND_LINK`;
export const LOADING_API_REPORT_TEMPLATE_FRONTEND_LINK = `${prefix}/LOADING_API_REPORT_TEMPLATE_FRONTEND_LINK`;