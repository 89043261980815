const routes = {
    ADD_DIRECTION: 'Добавить направления',
    ADD_TIME: 'Добавить время',
    ADD_SCHEDULE: 'Добавить расписание',
    ASSIGN_VEHICLE: 'Присвоить ТС',
    SAVE_ROUTE: 'Сохранить маршрут',
    ADD_ROUTE: 'Выбрать маршрут',
    ADD_STATION: 'Добавить остановку',
    ADD_OR_DELETE_STATION: 'Добавить/Удалить остановку',
    ADD_CHECKPOINTS: 'Добавить контрольные точки'
};

export default routes;
