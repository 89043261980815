import { Typography, Tooltip } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import classNames from 'classnames';
import Image from 'components/common/Image';
import messages from 'helpers/constants/messages';

const useStyles = makeStyles({
    wrapper: {
        paddingLeft: '0.5rem', 
        display: 'grid', 
        alignItems: 'flex-start',
        gridRowGap: '0.6rem', 
        gridColumnGap: '0.6rem',
        gridTemplateColumns: 'repeat(auto-fit, minmax(110px, 1fr))',
        // flexWrap: 'wrap'
    },
    item: {
        minWidth:'80px', 
        fontSize: '0.75rem',
    }
});

const TooltipStyle = {
    tooltip: {
        borderRadius: 8,
        backgroundColor: '#4cad51'
    }
};

const CustomTooltip = withStyles(TooltipStyle)(Tooltip);

const RenderPddViolationList = ({ violations = [] }) => {
    const styles = useStyles();
    return (
        <>
            <Typography variant="body2">
                <b>Фиксируемые нарушения – Статьи:</b>
            </Typography>
            <div className={styles.wrapper}>
                {violations.map((item, index) => (
                    <div className="row align-items-center" key={index}>
                        <CustomTooltip
                            key={index}
                            placement="top"
                            title={<div>
                                {item.description}
                                {/* <p><b>Период:</b> {item.range?.time_start}-{item.range?.time_end}</p> */}
                                {/* {item.category && <p><b>Тип ТС:</b> {categoryTS[item.category]}</p>} */}
                            </div>}
                        >
                            <Image
                                key={`icon-${index}`} 
                                src={item.icon} 
                                alt={item.pdd_violation}
                                style={{ width: 40 }}
                            />
                        </CustomTooltip>
                        <div className={classNames(styles.item, 'row__item')}>
                            <p>{item.pdd_violation || messages.NO_DATA}</p>
                            {/* <p><b>Камера:</b> {item.serial || messages.INFO_IS_NOT_FOUND}</p> */}
                            {/* {item.direction !== null && item.direction !== undefined && (
                                                        <p><b>Направление:</b> {directionCameras[item.direction]}</p>
                                                    )} */}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default RenderPddViolationList;