import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { transportRoadSelectors } from 'redux/TransportRoad';
import { createWorkorder, loadWorkorders } from 'redux/TransportRoad/actions';
import { loadRoadWorkOrders } from 'redux/RoadWorks/actions';
import messages from 'helpers/constants/messages';
import { dateWithDashYYYYMMDD, getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';

import AddEditModal from './AddEditModal';
import Filters from './Filters';
import Item from './Item';
import { roadworksworkordersURI } from './moduleConfig';

const test_id_prefix = roadworksworkordersURI; // префикс для полей для автотестов

const Workorders = ({
    currentOrder = null,
    tab = null
}) => {
    const dispatch = useDispatch();

    const [openAddModal, setOpenAddModal] = useState(false);
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;

    const [limit, setLimit] = useState(storageLimit);
    const [orderList, setOrderList] = useState({});
    const workordersData = useSelector(transportRoadSelectors.workordersData);
    const workordersMeta = useSelector(transportRoadSelectors.workordersMeta);
    const loadingWorkorders = useSelector(transportRoadSelectors.loadingWorkorders);

    const initialState = useMemo(() => ({
        start_date_at: currentOrder ? getStartOf('year') : getStartOf('day', getDateWithDuration({ day: -1 })),
        end_date_at: getEndOf('day'),
        route_ids: [],
        organization_ids: [],
        organizations: [],
        grz: '',
        order_id: currentOrder?.id || '',
        order: currentOrder || {},
        id: ''
    }), [currentOrder]);

    const [params, setParams] = useState({
        page: 1,
        data: initialState
    });

    useEffect(() => {
        // если мы не в табах, где и так есть полный объект заявки
        // берем все айди заявок из нарядов, делаем из них массив уникальных и загружаем список заявок
        if (!currentOrder && workordersData.length > 0) {
            const id_list = [...new Set(workordersData?.reduce((res, el) => {
                if (el.order_id) {
                    res.push(el.order_id);
                }
                return res;
            }, []))];

            if (id_list.length > 0) {
                dispatch(loadRoadWorkOrders(1, id_list.length, { ids: id_list },
                    (orders = []) => {
                        setOrderList(orders.reduce((res, el) => {
                            res[el.id] = el;
                            return res;
                        }, {}));
                    }
                ));
            }
        }
    }, [workordersData, currentOrder, dispatch]);

    useEffect(() => {
        // если это табы в Завяках и поменялась заявка в списке, надо в фильтре наряда поменять выбранное
        // и запросить список нарядов для текущей заявки
        if (currentOrder && currentOrder.id !== params.data.order_id) {
            setParams({
                page: 1,
                data: initialState // initialState каждый раз меняется есть новый currentOrder
            });
        }

    }, [currentOrder, params.data.order_id, dispatch, initialState]);

    useEffect(() => {
        const result = prepareData(params.data);
        dispatch(loadWorkorders(params.page, limit, result));
    }, [dispatch, params, limit]);

    const prepareData = (filters) => {
        const newParams = {
            ...filters,
            route_ids: filters.route_ids.map(({ id }) => id),
            start_date_at: dateWithDashYYYYMMDD(filters.start_date_at),
            end_date_at: dateWithDashYYYYMMDD(filters.end_date_at),
            order_id: filters.order?.id,
            organization_ids: filters.organizations?.map(el => el.id)
        };
        delete newParams.organizations;
        delete newParams.order;
        return removeEmptyFields(newParams, false);
    };

    const onSave = (data) => {
        const result = removeEmptyFields(data, false);
        dispatch(createWorkorder(result, () => {
            setParams((old) => ({
                ...old,
                page: 1
            }));
            setOpenAddModal(false);
        }));
    };

    const changePageLimit = (page) => {
        setParams((old) => ({
            ...old,
            page,
        }));
    };

    const reloadList = () => {
        const result = prepareData(params.data);
        dispatch(loadWorkorders(params.page, limit, result));
    };

    const renderContent = () => {
        return (
            workordersData?.length > 0
                ? <LSContainer
                    headers={[
                        { title: '', width: '50px' },
                        { title: 'Дата', width: '11%' },
                        { title: 'ID', width: '5%' },
                        { title: titles.ORGANIZATION, width: '17%' },
                        { title: titles.ROUTE_NUM, width: '15%' },
                        { title: 'Название маршрута', width: '15%' },
                        { title: titles.GRZ, width: '12%' },
                        { title: 'Заявка', width: '10%' },
                        { title: 'Действия', align: 'right', isActions: true }
                    ]}
                >
                    {workordersData?.map(item =>
                        <Item
                            key={item.id}
                            loadList={changePageLimit}
                            params={params}
                            item={item}
                            reloadList={reloadList}
                            currentOrder={currentOrder || orderList[item.order_id]}
                        />
                    )}
                </LSContainer>
                : (!loadingWorkorders && messages.WORKORDER_IS_NOT_FOUND_FOR_THE_DATE)
        );
    };

    return (
        <>
            <PageLayout
                header={tab ? '' : 'Наряды'}
                filters={<Filters
                    setParams={(filters) => setParams(old => ({ ...old, data: filters }))}
                    initialState={initialState}
                    currentOrder={currentOrder}
                    tab={tab}
                />}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            uri={roadworksworkordersURI}
                            noPadding
                            positionLeft
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenAddModal(true),
                                }
                            ]}
                            noMarginLeft
                        />
                    ),
                    total: workordersMeta?.total
                }}
                loading={loadingWorkorders}
                content={renderContent}
                paginationProps={{
                    loadList: changePageLimit,
                    list: workordersMeta,
                    limit: limit,
                    setLimit,
                }}
            />
            {openAddModal
                && <AddEditModal
                    isNew={true}
                    data={{
                        date_at: new Date(),
                        // date_at: params.date_at,
                        ...(currentOrder
                            ? {
                                order: params.data.order,
                                order_id: params.data.order_id
                            }
                            : {})
                    }}
                    currentOrder={currentOrder}
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    onSave={onSave}
                />
            }
        </>
    );
};

export default Workorders;
