import LinkOrganizations from '../../pages/Dictionaries/Transnavigation/LinkOrganizations';

const routes = [
    {
        path: '/dictionaries/transnavigation/link-organizations',
        component: LinkOrganizations,
        exact: true
    },
];

export default routes;