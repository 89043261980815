import React, { useState } from 'react';
import { ModalFormProps } from './types';
import { TextField } from '@mui/material';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import { useValidation } from '../../../../../helpers/hooks';
import { useDispatch } from 'react-redux';
import { createOrderType, editOrderType } from '../../../../../redux/RoadWorks/actions';
import Modal from '../../../../common/Modal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const AddEditModal = ({
    item,
    isOpen, 
    isNew = false,
    onClose,
    reloadList
}: ModalFormProps) => {

    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        name: item?.name || '',
        description: item?.description || '',
    };

    const [data, setData] = useState(initialState);

    const handleChange = (e: { target: { name: string; value: string; }; }) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
        validation.deleteKey(name);
    };

    const actionCallback = () => {
        reloadList(false);
        onClose();
    };

    const onAcceptModal = () => {
        dispatch(isNew 
            ? createOrderType(data, actionCallback) 
            : editOrderType(item?.id, data, actionCallback));
    };

    return (<Modal
        isOpen={isOpen}
        title={isNew ? 'Добавление типа заявки' : 'Редактирование типа заявки'}
        onClose={onClose}
        noPadding={true}
        buttons={<FormButtonsComponent
            buttons={[
                {
                    ...buttonsTypes.close,
                    onClick: onClose
                },
                {
                    ...buttonsTypes.save,
                    onClick: onAcceptModal,
                    disabled: !data.name && !data.description
                }
            ]}
        />}
    >
        <div className="modal__form">
            <FormInfoWrapper
                helperText={validation.get('name')}
                error={validation.isKey('name')}
                className="block"
            >
                <TextField
                    label="Название типа"
                    size="small"
                    value={data.name}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={handleChange}
                    required
                    error={validation.isKey('name')}
                />
            </FormInfoWrapper>
            <FormInfoWrapper
                className="block"
                error={validation.isKey('error_description')}
                helperText={validation.get('error_description')}
            >
                <TextField
                    label="Описание типа"
                    size="small"
                    value={data.description}
                    variant="outlined"
                    name="description"
                    type="text"
                    onChange={handleChange}
                    error={validation.isKey('error_description')}
                />
            </FormInfoWrapper>
        </div>
    </Modal>);
};

export default AddEditModal;