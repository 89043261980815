import instance from '../../helpers/axios.config';

import apiUrls from './api.urls';

const ApiTransportRoadMethods = {
    getSideBar: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getSidebar(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },
    postCoords: async (uids) => {
        const response = await instance.get(apiUrls.getCoords(), { uids });
        return response.data;
    },
    getCoords: async (params) => {
        const response = await instance.get(apiUrls.getCoords(), { params });
        return response.data;
    },
    getAllVehicles: async () => {
        const response = await instance.get(apiUrls.getAllVehicle());
        return response.data;
    },
    getTypes: async () => {
        const response = await instance.get(apiUrls.getTypes());
        return response.data;
    },
    getHistory: async (vehicleId, params) => {
        const response = await instance.get(apiUrls.getHistory(vehicleId), {
            params
        });
        return response.data;
    },

    /* color speed */
    getColorSpeedList: async (params) => {
        const response = await instance.get(apiUrls.getColorSpeedList(), { params });
        return response?.data;
    },
    addColorSpeed: async (data) => {
        const response = await instance.post(apiUrls.addColorSpeed(), data);
        return response?.data;
    },
    editColorSpeed: async (id, data) => {
        const response = await instance.put(apiUrls.editColorSpeed(id), data);
        return response?.data;
    },
    deleteColorSpeed: async (id) => {
        const response = await instance.delete(apiUrls.deleteColorSpeed(id));
        return response?.data;
    },

    getCategories: async (params) => {
        const response = await instance.get(apiUrls.getCategories(), params);
        return response?.data;
    },

    // getCategories: async () => {
    //     const response = await instance.get(apiRwUrls.getCategories());
    //     return response?.data;
    // },

    findVehicles: async (params) => {
        const response = await instance.get(apiUrls.findVehicles(), { params });
        return response?.data;
    },








    getBrandList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getBrandList(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    createBrand: async (params) => {
        const response = await instance.post(apiUrls.createBrand(), params);
        return response?.data;
    },

    editBrand: async (id, data) => {
        const response = await instance.put(apiUrls.editBrand(id), data);
        return response?.data;
    },

    deleteBrand: async (id) => {
        const response = await instance.delete(apiUrls.editBrand(id));
        return response?.data;
    },

    getModelList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getModelList(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    createModel: async (params) => {
        const response = await instance.post(apiUrls.createModel(), params);
        return response?.data;
    },

    editModel: async (id, data) => {
        const response = await instance.put(apiUrls.editModel(id), data);
        return response?.data;
    },

    deleteModel: async (id) => {
        const response = await instance.delete(apiUrls.editModel(id));
        return response?.data;
    },

    getCompanyList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getCompanyList(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    createCompany: async (params) => {
        const response = await instance.post(apiUrls.createCompany(), params);
        return response?.data;
    },

    editCompany: async (params) => {
        const response = await instance.put(apiUrls.editCompany(params.uuid), params);
        return response?.data;
    },

    deleteCompany: async (id) => {
        const response = await instance.delete(apiUrls.editCompany(id));
        return response?.data;
    },

    getVehicleList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getVehicleList(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    editVehicle: async (id, data) => {
        const response = await instance.put(apiUrls.editVehicle(id), data);
        return response?.data;
    },

    deleteVehicle: async (id) => {
        const response = await instance.delete(apiUrls.deleteVehicle(id));
        return response?.data;
    },

    getVehicleReport: async (params = {}) => {
        const response = await instance.get(apiUrls.getVehicleReport(), {
            params
        });
        return response?.data;
    },

    getVehicleCategoryList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getCategories(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    createVehicleCategory: async (data) => {
        const response = await instance.post(apiUrls.createCategory(), data);
        return response?.data;
    },

    editVehicleCategory: async (id, data) => {
        const response = await instance.put(apiUrls.editCategory(id), data);
        return response?.data;
    },

    deleteVehicleCategory: async (id) => {
        const response = await instance.delete(apiUrls.deleteCategory(id));
        return response?.data;
    },

    createNewVehicle: async (data) => {
        const response = await instance.post(apiUrls.createNewVehicle(), data);
        return response?.data;
    },


    loadParks: async (page, limit, query) => {
        const response = await instance.get(apiUrls.loadParks(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    createPark: async (data) => {
        const response = await instance.post(apiUrls.createPark(), data);
        return response?.data;
    },

    editPark: async (id, data) => {
        const response = await instance.put(apiUrls.editPark(id), data);
        return response?.data;
    },

    deletePark: async (id) => {
        const response = await instance.delete(apiUrls.deletePark(id));
        return response?.data;
    },

    getEmployees: async (config = {}) => {
        const response = await instance.get(apiUrls.getEmployees(), config);
        return response?.data;
    },

    addEmployees: async (data) => {
        const response = await instance.post(apiUrls.addEmployees(), data);
        return response?.data;
    },

    editEmployees: async (id, data) => {
        const response = await instance.put(apiUrls.editEmployees(id), data);
        return response?.data;
    },

    deleteEmployees: async (id) => {
        const response = await instance.delete(apiUrls.deleteEmployees(id));
        return response?.data;
    },

    getPositions: async (params) => {
        const response = await instance.get(apiUrls.getPositions(), {
            params
        });
        return response?.data;
    },

    addPositions: async (data) => {
        const response = await instance.post(apiUrls.addPositions(), data);
        return response?.data;
    },

    editPositions: async (id, data) => {
        const response = await instance.put(apiUrls.editPositions(id), data);
        return response?.data;
    },

    deletePositions: async (id) => {
        const response = await instance.delete(apiUrls.deletePositions(id));
        return response?.data;
    },

    getBrigades: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getBrigades(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    addBrigades: async (data) => {
        const response = await instance.post(apiUrls.addBrigades(), data);
        return response?.data;
    },

    editBrigades: async (id, data) => {
        const response = await instance.put(apiUrls.editBrigades(id), data);
        return response?.data;
    },

    deleteBrigades: async (id) => {
        const response = await instance.delete(apiUrls.deleteBrigades(id));
        return response?.data;
    },

    getVehicleDowntime: async (id, query) => {
        const response = await instance.get(apiUrls.getVehicleDowntime(id), { params : query });
        return response?.data;
    },

    getReportFuel: async (data) => {
        const response = await instance.post(apiUrls.getReportFuel(), data);
        return response.data;
    },

    createRoute: async (data) => {
        const response = await instance.post(apiUrls.createRoute(), data);
        return response.data;
    },

    deleteRoute: async (id) => {
        const response = await instance.delete(apiUrls.deleteRoute(id));
        return response.data;
    },

    changeRoute: async (uuid, data) => {
        const response = await instance.put(apiUrls.changeRoute(uuid), data);
        return response.data;
    },

    getRoutes: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getRoutes(), {
            params: { page, limit, ...data }
        });
        return response.data;
    },

    getRouteStatuses: async () => {
        const response = await instance.get(apiUrls.getRouteStatuses());
        return response?.data;
    },

    getRouteTypes: async () => {
        const response = await instance.get(apiUrls.getRouteTypes());
        return response.data;
    },

    getRouteCheckpoints: async (id) => {
        const response = await instance.get(apiUrls.getRouteCheckpoints(id));
        return response?.data;
    },

    saveRouteCheckpoints: async (id, params) => {
        const response = await instance.post(apiUrls.saveRouteCheckpoints(id), params);
        return response?.data;
    },

    getTelemetryReceivers: async (params) => {
        const response = await instance.get(apiUrls.getTelemetryReceivers(), { params });
        return response.data;
    },

    addTelemetryReceiver: async (data) => {
        const response = await instance.post(apiUrls.addTelemetryReceiver(), data);
        return response.data;
    },

    editTelemetryReceiver: async (id, data) => {
        const response = await instance.put(apiUrls.editTelemetryReceiver(id), data);
        return response.data;
    },

    deleteTelemetryReceiver: async (id) => {
        const response = await instance.delete(apiUrls.deleteTelemetryReceiver(id));
        return response.data;
    },

    getTelemetryReceiverTypes: async () => {
        const response = await instance.get(apiUrls.getTelemetryReceiverTypes());
        return response.data;
    },

    // workorders
    getWorkorders: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getWorkorders(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    getWorkorderStatuses: async () => {
        const response = await instance.get(apiUrls.getWorkorderStatuses());
        return response?.data;
    },

    getWorkordersByDate: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getWorkordersByDate(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    saveWorkorder: async (data) => {
        const response = await instance.post(apiUrls.getWorkorders(), data);
        return response?.data;
    },

    editWorkorder: async (id, data) => {
        const response = await instance.put(apiUrls.editWorkorders(id), data);
        return response?.data;
    },

    deleteWorkorder: async (id) => {
        const response = await instance.delete(apiUrls.editWorkorders(id));
        return response?.data;
    },

    getWorkordersLogs: async (id) => {
        const response = await instance.get(apiUrls.getWorkorderLogs(id));
        return response.data;
    },

    getWorkorderLogManualStatus: async () => {
        const response = await instance.get(apiUrls.getWorkorderLogManualStatus());
        return response.data;
    },

    addWorkorderLogManualStatus: async (id, data) => {
        const response = await instance.post(apiUrls.addWorkorderLogManualStatus(id), data);
        return response.data;
    },

    editWorkorderLogManualStatus: async (id, data) => {
        const response = await instance.put(apiUrls.editWorkorderLogManualStatus(id), data);
        return response.data;
    },

    deleteWorkorderLogManualStatus: async (id) => {
        const response = await instance.delete(apiUrls.deleteWorkorderLogManualStatus(id));
        return response.data;
    },

    getCheckPoints: async (params) => {
        const response = await instance.get(apiUrls.getCheckPoints(), { params });
        return response.data;
    },

    createCheckPoint: async (data) => {
        const response = await instance.post(apiUrls.createCheckPoint(), data);
        return response.data;
    },

    editCheckPoint: async (id, data) => {
        const response = await instance.put(apiUrls.editCheckPoint(id), data);
        return response.data;
    },

    deleteCheckPoint: async (id) => {
        const response = await instance.delete(apiUrls.deleteCheckPoint(id));
        return response.data;
    },

    getReportByVehicles: async (params) => {
        const response = await instance.get(apiUrls.getReportByVehicles(), { params });
        return response.data;
    },

    loadCheckPointPolygon: async (params) => {
        const response = await instance.post(apiUrls.getCheckPointPolygon(), params);
        return response.data;
    },

    changeRouteStatus: async (id, status) => {
        const response = await instance.patch(apiUrls.changeRouteStatus(id, status));
        return response.data;
    },

    loadVehicleStatistics: async () => {
        const response = await instance.get(apiUrls.loadVehicleStatistics());
        return response?.data;
    },

    loadWorkorderStatistics: async () => {
        const response = await instance.get(apiUrls.loadWorkorderStatistics());
        return response?.data;
    },

    loadEgtsTransmitters: async (params) => {
        const response = await instance.get(apiUrls.loadEgtsTransmitters(), { params });
        return response.data;
    },

    addEgtsTransmitter: async (data) => {
        const response = await instance.post(apiUrls.addEgtsTransmitter(), data);
        return response.data;
    },

    editEgtsTransmitter: async (id, data) => {
        const response = await instance.put(apiUrls.editEgtsTransmitter(id), data);
        return response.data;
    },

    deleteEgtsTransmitter: async (id) => {
        const response = await instance.delete(apiUrls.deleteEgtsTransmitter(id));
        return response.data;
    },

};

export default ApiTransportRoadMethods;
