import React from 'react';
import PlatformPopUp from './PlatformPopUp';
import {
    getRouteColor
} from './helper';
import {
    PopUp,
    ToolTip,
    CircleMarker,
} from '../../leaflet';

const CheckPointsMarkers = ({ item, handleBounds, ...props }) => {
    const { category_id, num, check_points = [] } = item;
    const id = `${num}_${category_id}`;
    const color = getRouteColor(category_id);

    return (
        <>
            {check_points.map((station, index) => {
                const { lat, lon, id: idPoints, name } = station;

                if (!lat || !lon) return null;

                return (
                    <CircleMarker
                        {...props}
                        key={`circle_${id}_${idPoints}_${index}`}
                        latlng={[lat, lon]}
                        radius={6}
                        weight={1}
                        // color={color.checkPoint}
                        fillColor={color.checkPoint}
                        fillOpacity={1}
                    >
                        <CircleMarker
                            latlng={[lat, lon]}
                            radius={3}
                            weight={1}
                            // color={color.checkPoint}
                            fillColor="#fff"
                            fillOpacity={1}
                        >
                            {/* Информация - нечего показывать */}
                            {/*<PopUp>*/}
                            {/*    <PlatformPopUp*/}
                            {/*        map={props.map}*/}
                            {/*        station={station}*/}
                            {/*        isStation={false}*/}
                            {/*        route={num}*/}
                            {/*    />*/}
                            {/*</PopUp>*/}

                            <ToolTip
                                offset={[0, -5]}
                                //permanent={true}
                                direction="top"
                                //sticky={true}
                            >
                                <div>Контрольная точка{name ? `: ${name}` : ''}</div>
                            </ToolTip>
                        </CircleMarker>
                    </CircleMarker>
                );
            })}
        </>
    );
};

export default CheckPointsMarkers;
