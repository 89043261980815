import fastDiff from 'fast-diff';

export const diffString = (str1, str2) => {
    const differ = fastDiff(str1, str2);
    const diffArray1 = differ.filter((elem) => elem[0] !== 1);
    const diffArray2 = differ.filter((elem) => elem[0] !== -1);
    const diffStr1 = diffArray1.map((addr, i) => <span key={i}>
        <span style={{ 
            backgroundColor: addr[0] === -1 ? '#61b136bf' : 'inherit',
        }}>{addr[1]}</span>
    </span>);
    const diffStr2 = diffArray2.map((addr, i) => <span key={i}>
        <span style={{ 
            backgroundColor: addr[0] === 1 ? '#ee391d8c' : 'inherit',
        }}>{addr[1]}</span>
    </span>);
    return { str1: diffStr1, str2: diffStr2 };
};