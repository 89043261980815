import React from 'react';
import Brigades from '../../../../common/Transport/Brigades';

const BrigadeList = () => {
    return (
        <Brigades 
            storeName="transportPassenger"
        />
    );
};

export default BrigadeList;