import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, ListItem, ListItemText } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import { deleteCameraGroup } from 'redux/VideoCameras/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';
import Info from './Info';

const Item = ({ el }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openInfo, setOpenInfo ] = useState(false);

    return (
        <>
            {openDeleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteModal}
                    onSuccess={() => {
                        dispatch(deleteCameraGroup(el.id));
                        setOpenDeleteModal(false);
                    }}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
            {openEditModal
                && <ModalForm
                    isNew={false}
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    el={el}
                />}
            <ListItem className="list_item" onClick={() => setOpenInfo(!openInfo)}>
                <ListItemText>
                    <div>
                        <span><strong>{titles.NAME_GROUP}:</strong> {el.name || messages.NO_DATA}</span>
                    </div>
                </ListItemText>
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: (e) => {e.stopPropagation(); setOpenEditModal(true);},
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: (e) => {e.stopPropagation(); setOpenDeleteModal(true);},
                        },
                    ]}
                    noMarginLeft
                    justButton
                />

                <IconButton onClick={() => setOpenInfo(!openInfo)} size={'small'}>
                    {openInfo ? (
                        <ExpandLess fontSize={'inherit'}/>
                    ) : (
                        <ExpandMore fontSize={'inherit'}/>
                    )}
                </IconButton>
            </ListItem>
            {openInfo
                && <Info isOpen={openInfo} item={el} />}
        </>
    );
};

export default Item;
