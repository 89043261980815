export const dorisControl = (state) => state.dorisControl;

export const cameras = (state) => dorisControl(state).cameras;
export const camerasData = (state) => cameras(state).data;
export const camerasMeta = (state) => cameras(state).meta;

export const loading = (state) => dorisControl(state).loading;
export const types = (state) => dorisControl(state).types;
export const complex_types = (state) => dorisControl(state).complex_types;
export const proto = (state) => dorisControl(state).proto;
export const dir_types = (state) => dorisControl(state).dir_types;
export const video_types = (state) => dorisControl(state).video_types;
export const data_types = (state) => dorisControl(state).data_types;
export const violations = (state) => dorisControl(state).violations;
export const eventsGroup = (state) => dorisControl(state).eventsGroup;
export const eventsLoading = (state) => dorisControl(state).eventsLoading;
export const events = (state) => dorisControl(state).events;
export const reportList = (state) => dorisControl(state).report_list;

export const loading_complexes = (state) => dorisControl(state).loading_complexes;
export const complexes = (state) => dorisControl(state).complexes;
export const complexesData = (state) => complexes(state).data;
export const complexesMeta = (state) => complexes(state).meta;
export const complexRules = (state) => dorisControl(state).complexRules;
export const loadingComplexEvents = (state) => dorisControl(state).loadingComplexEvents;
export const loadingComplexRules = (state) => dorisControl(state).loadingComplexRules;
export const complex_events = (state) => dorisControl(state).complex_events;
export const complexEventsData = (state) => complex_events(state).data;
export const complexEventsMeta = (state) => complex_events(state).meta;
// export const complexEventsReportTypes = (state) => complex_events(state).meta.additional_parameters?.reports;
export const complexCurrentEvent = (state) => dorisControl(state).complexCurrentEvent;
export const complexCurrentEventData = (state) => complexCurrentEvent(state).data;
export const complexCurrentEventMeta = (state) => complexCurrentEvent(state).meta;

export const loadingEvent = (state) => dorisControl(state).loadingEvent;
// export const loadingFormattedComplexHistory = (state) => dorisControl(state).loadingFormattedComplexHistory;
// export const formattedComplexHistory = (state) => dorisControl(state).formattedComplexHistory;
// export const formattedComplexHistoryData = (state) => formattedComplexHistory(state).data;
// export const formattedComplexHistoryMeta = (state) => formattedComplexHistory(state).meta;
// export const complexTransferTypes = (state) => dorisControl(state).complexTransferTypes;
// export const complexTransferRules = (state) => dorisControl(state).complexTransferRules;
// export const currentComplexTransferTypes = (state) => dorisControl(state).currentComplexTransferTypes;
export const complexStatistic = (state) => dorisControl(state).complexStatistic;
// export const complex_history = (state) => dorisControl(state).complex_history;
// export const complex_historyData = (state) => complex_history(state).data;
// export const complex_historyMeta = (state) => complex_history(state).meta;
// export const loading_complex_history = (state) => dorisControl(state).loading_complex_history;
// export const doris_complex_types = (state) => dorisControl(state).doris_complex_types;
// export const doris_complex_unit_types = (state) => dorisControl(state).doris_complex_unit_types;
// export const doris_complex_model_types = (state) => dorisControl(state).doris_complex_model_types;
// export const doris_complex_document_types = (state) => dorisControl(state).doris_complex_document_types;
export const doris_complex_statuses = (state) => dorisControl(state).doris_complex_statuses;
// export const complexInaccuracy = (state) => dorisControl(state).complexInaccuracy;
export const complexById = (state) => dorisControl(state).complexById;
export const loadingComplexById = (state) => dorisControl(state).loadingComplexById;

export const complexEventsReportFilter = (state) => dorisControl(state).complexEventsReportFilter;

export const loading_entities = (state) => dorisControl(state).loading_entities;
export const entities = (state) => dorisControl(state).entities;
// export const loading_entities_types = (state) => dorisControl(state).loading_entities_types;
// export const entities_types = (state) => dorisControl(state).entities_types;

export const loading_documents = (state) => dorisControl(state).loading_documents;
export const documents = (state) => dorisControl(state).documents;
export const documentsData = (state) => documents(state).data;
export const documentsMeta = (state) => documents(state).meta;

export const loading_pdd_violations = (state) => dorisControl(state).loading_pdd_violations;
export const pdd_violations = (state) => dorisControl(state).pdd_violations;
export const pddViolationsData = (state) => pdd_violations(state).data;
export const pddViolationsMeta = (state) => pdd_violations(state).meta;
export const violationsById = (state) => dorisControl(state).violationsById;
export const cameras_pdd_violations = (state) => dorisControl(state).cameras_pdd_violations;
export const loading_cameras_pdd_violations = (state) => dorisControl(state).loading_cameras_pdd_violations;

export const polygon = (state) => dorisControl(state).polygon;
export const polygonLoading = (state) => dorisControl(state).polygonLoading;
export const active = (state) => dorisControl(state).active;
export const filters = (state) => dorisControl(state).filters;
export const saved = (state) => dorisControl(state).saved;
export const deleteForm = (state) => dorisControl(state).deleteForm;
export const editForm = (state) => dorisControl(state).editForm;
export const showDorisCameraEvents = (state) =>  dorisControl(state).showDorisCameraEvents;
export const activeEvent = (state) => dorisControl(state).activeEvent;

export const coordinationHistory = (state) => dorisControl(state).coordinationHistory;
export const loadingCoordinationHistory = (state) => dorisControl(state).loadingCoordinationHistory;
export const coordinationHistoryDates = (state) => dorisControl(state).coordinationHistoryDates;

export const sidebar = (state) => dorisControl(state).sidebar;
// export const sidebarData = (state) => sidebar(state).data;
// export const sidebarMeta = (state) => sidebar(state).meta;
export const sidebarLoading = (state) => dorisControl(state).sidebarLoading;
export const sidebarParams = (state) => dorisControl(state).sidebarParams;

export const loadingEditEventErrors = (state) => dorisControl(state).loadingEditEventErrors;
export const comparativeStatistics = (state) => dorisControl(state).comparativeStatistics;
export const loadingComparativeStatistics = (state) => dorisControl(state).loadingComparativeStatistics;

// export const dorisControlSettings = (state) => dorisControl(state).dorisControlSettings;
// export const loadingDorisControlSettings = (state) => dorisControl(state).loadingDorisControlSettings;
// export const tsCategories = (state) => dorisControl(state).tsCategories;
export const loadingInspectionCertificate = (state) => dorisControl(state).loadingInspectionCertificate;
// export const loadingFile = (state) => dorisControl(state).loadingFile;
export const namesIn1C = (state) => dorisControl(state).namesIn1C;
export const namesIn1CData = (state) => namesIn1C(state).data;
export const namesIn1CMeta = (state) => namesIn1C(state).meta;
export const loading1CNames = (state) => dorisControl(state).loading1CNames;
export const report1CNamesLoading = (state) => dorisControl(state).report1CNamesLoading;
export const operatorLogReportFilter = (state) => dorisControl(state).operatorLogReportFilter;
// export const complexTypeGrouping = (state) => dorisControl(state).complex_type_grouping;
// export const complexMeasuring = (state) => dorisControl(state).complex_measuring;
export const recognitionCameraDirTypes = (state) => dorisControl(state).recognitionCameraDirTypes;
export const computerTransferTypes = (state) => dorisControl(state).computerTransferTypes;
export const computerTransferRules = (state) => dorisControl(state).computerTransferRules;
// export const dorisExportTypes = (state) => dorisControl(state).dorisExportTypes;

export const tagList = (state) => dorisControl(state).tagList;
export const tagListData = (state) => tagList(state).data;
export const tagListMeta = (state) => tagList(state).meta;

export const loadingExclusiveFiles = (state) => dorisControl(state).loadingExclusiveFiles;
export const exclusiveFiles = (state) => dorisControl(state).exclusiveFiles;
export const exclusiveFilesData = (state) => exclusiveFiles(state).data;
export const exclusiveFilesMeta = (state) => exclusiveFiles(state).meta;

export const complexesTree = (state) => dorisControl(state).complexesTree;
export const loadingComplexesTree = (state) => dorisControl(state).loadingComplexesTree;

export const eventCheckList = (state) => dorisControl(state).eventCheckList;
export const loadingEventCheckList = (state) => dorisControl(state).loadingEventCheckList;
export const loadingEventPdds = (state) => dorisControl(state).loadingEventPdds;

export const violationEventsData = (state) => dorisControl(state).violationEvents?.data;
export const violationEventsMeta = (state) => dorisControl(state).violationEvents?.meta;
export const loadingViolationEvents = (state) => dorisControl(state).loadingViolationEvents;

export const loadingExclusiveFilesHistoryList = (state) => dorisControl(state).loadingExclusiveFilesHistoryList;
export const exclusiveFilesHistoryList = (state) => dorisControl(state).exclusiveFilesHistoryList;
export const exclusiveFilesHistoryListData = (state) => exclusiveFilesHistoryList(state).data;
export const exclusiveFilesHistoryListMeta = (state) => exclusiveFilesHistoryList(state).meta;

export const loadingExclusiveFilesLogList = (state) => dorisControl(state).loadingExclusiveFilesLogList;
export const exclusiveFilesLogList = (state) => dorisControl(state).exclusiveFilesLogList;
export const exclusiveFilesLogListData = (state) => exclusiveFilesLogList(state).data;
export const exclusiveFilesLogListMeta = (state) => exclusiveFilesLogList(state).meta;

export const loadingCheckListGroup = (state) => dorisControl(state).loadingCheckListGroup;
export const checkListGroup = (state) => dorisControl(state).checkListGroup;
export const checkListGrouptData = (state) => checkListGroup(state).data;
export const checkListGroupMeta = (state) => checkListGroup(state).meta;

export const eventsStatistic = (state) => dorisControl(state).eventsStatistic;
export const loadingEventsStatistic = (state) => dorisControl(state).loadingEventsStatistic;

export const violationsTree = (state) => dorisControl(state).violationsTree;
export const loadingViolationsTree = (state) => dorisControl(state).loadingViolationsTree;

export const materialExportGroup = (state) => dorisControl(state).materialExportGroup;
export const materialExportGroupData = (state) => materialExportGroup(state).data;
export const materialExportGroupMeta = (state) => materialExportGroup(state).meta;
export const loadingMaterialExportGroup = (state) => dorisControl(state).loadingMaterialExportGroup;

// export const materialExportGroupTypes = (state) => dorisControl(state).materialExportGroupTypes;
// export const materialExportGroupProtocols = (state) => dorisControl(state).materialExportGroupProtocols;

export const typeOfMistakesForChecklist = (state) => dorisControl(state).typeOfMistakesForChecklist;
export const typeOfMistakesForChecklistData = (state) => typeOfMistakesForChecklist(state).data;
export const typeOfMistakesForChecklistMeta = (state) => typeOfMistakesForChecklist(state).meta;
export const loadingTypeOfMistakesForChecklist = (state) => dorisControl(state).loadingTypeOfMistakesForChecklist;

export const showCaseComplexStatus = (state) => dorisControl(state)?.showCaseComplexStatus;
export const loadingShowCaseComplexStatus = (state) => dorisControl(state)?.loadingShowCaseComplexStatus;

export const showCaseDocumentsVerification = (state) => dorisControl(state)?.showCaseDocumentsVerification;
export const loadingShowCaseDocumentsVerification = (state) => dorisControl(state)?.loadingShowCaseDocumentsVerification;

export const duploCodes = (state) => dorisControl(state).duploCodes;
export const duploCodesData = (state) => duploCodes(state).data;
export const duploCodesMeta = (state) => duploCodes(state).meta;
export const loadingDuploCodes = (state) => dorisControl(state).loadingDuploCodes;

export const loadingButton = (state) => dorisControl(state).loadingButton;

export const materials = (state) => dorisControl(state).materials;
export const materialsData = (state) => materials(state).data;
export const materialsMeta = (state) => materials(state).meta;
export const loadingMaterials = (state) => dorisControl(state).loadingMaterials;

export const materialExport = (state) => dorisControl(state).materialExport;
export const materialExportData = (state) => materialExport(state).data;
export const materialExportMeta = (state) => materialExport(state).meta;
export const loadingMaterialExport = (state) => dorisControl(state).loadingMaterialExport;
export const materialExportStatuses = (state) => dorisControl(state).materialExportStatuses;

export const checklists = (state) => dorisControl(state).checklists;
export const checklistsData = (state) => checklists(state).data;
export const checklistsMeta = (state) => checklists(state).meta;
export const loadingChecklist = (state) => dorisControl(state).loadingChecklist;

export const complexConditions = (state) => dorisControl(state).complexConditions;
