import React, { useState } from 'react';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import { TextField } from '@mui/material';
import titles from '../../../../../helpers/constants/titles';
import { FilterData } from './types';

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{page: number, data: {}}>>
}

const initialState: FilterData = { name: '' };

const Filter = ({ setParams }: FilterProps) => {
    const [data, setData] = useState(initialState);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setData(prev => ({ ...prev, [name]: value }));
    };

    const onSearch = (filters = data) => {
        setParams({ page: 1, data: removeEmptyFields(filters) });
    };

    const resetFilter = (needRefresh: boolean) => {
        needRefresh && onSearch(initialState);
        setData(initialState);
    };

    const updateFilter = (filters: FilterData) => {
        const newFilter = { ...initialState, ...filters };
        setData(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };

    const handleClickEnter = (e: React.KeyboardEvent) => {
        if (e?.key === 'Enter') onSearch();
    };

    return (
        <LayoutFilter
            filter={data}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            disabled={!data.name}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={data?.name}
                    onChange={handleChange}
                    label={titles.NAME}
                    variant="outlined"
                    name="name"
                    type="text"
                    size="small"
                    onKeyPress={handleClickEnter}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
