import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import { changeRouteStatus, loadRoutesStatuses } from 'redux/TransportRoad/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import Modal from 'components/common/Modal';

const EditStatusModal = ({ data = {}, isOpen, onClose, reloadList }) => {
    const dispatch = useDispatch();

    const validation = useValidation();

    const [status, setStatus] = useState(data?.status);

    const statuses = useStoreProp(loadRoutesStatuses, 'transportRoad', 'routeStatuses');
    const filterStatuses = statuses; // parseInt(data?.status) === 2 ? { 3: statuses['3'], 2: statuses['2'] } : statuses; 

    const handleChange = (e) => {
        const { value } = e.target;
        validation.deleteKey('id');
        validation.deleteKey('status');
        setStatus(Number(value));
    };

    const checkDisabled = (option) => {
        return data?.status === option
            || (data?.status === 1 && option === 5)
            || (data?.status === 2 && option !== 4)
            || (data?.status === 4 && (option !== 3 && option !== 2))
            || data?.status === 5;
    };

    const submit = () => {
        dispatch(changeRouteStatus(data?.id, status, () => { reloadList(); onClose(false); }));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose(false)}
            noPadding
            title={titles.EDIT_STATUS}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: () => onClose(false)
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: !data?.id || data?.status === status,
                        onClick: submit
                    },
                ]}
            />}
        >
            <form className="modal__form">
                <FormInfoWrapper
                    error={validation.isKey('id') || validation.isKey('status')}
                    helperText={validation.get('id') || validation.get('status')}
                >
                    <FormControl className="block" size="small" variant="outlined">
                        <InputLabel>{titles.STATUS}</InputLabel>
                        <Select
                            value={status}
                            required
                            onChange={handleChange}
                            label={titles.STATUS}
                            name="status"
                            error={validation.isKey('id')}
                        >
                            {Object.keys(filterStatuses)?.map((key) => (
                                <MenuItem
                                    key={key}
                                    value={key}
                                    disabled={checkDisabled(Number(key))}

                                >
                                    {filterStatuses[key]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormInfoWrapper>
            </form>
        </Modal>
    );
};

export default EditStatusModal;