import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const mutex = {};

// получить поле из стора
const useStoreProp = (
    // запрос на получение данных
    fetchAction,
    // раздел стора
    storeName,
    // нужное поле
    storeProp
) => {
    const dispatch = useDispatch();
    const storeField = useSelector((store) => store?.[storeName]?.[storeProp]);

    useEffect(() => {
        mutex[storeName] = mutex[storeName] || {};

        if (((Array.isArray(storeField) &&  storeField.length === 0)
            || (typeof storeField === 'object' && Object.keys(storeField).length === 0))
            && !mutex[storeName][storeProp]) {
            mutex[storeName][storeProp] = true; // для предотвращения бесконечных запросов если данных нет
            dispatch(fetchAction());
        }
    }, [storeField, dispatch, fetchAction, storeProp, storeName]);

    useEffect(() => {
        return () => {
            mutex[storeName][storeProp] = false;
        };
    }, []);

    return storeField;
};

export default useStoreProp;
