import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import { usePrevious } from '../../../../../helpers/hooks';
import { isEqual } from 'lodash';
import './geoJson.scss';
import onEachFeature from './functions/onEachFeature';
import pointToLayer from './functions/pointToLayer';
import jsonStyle from './functions/style';
import clearLayer from './functions/clearLayer';


const GeoJson = (props) => {
    const defaultProps = {
        data: {
            // type: 'Feature',
            // geometry: {
            //     coordinates: [36.197205, 51.712379],
            //     type: 'Point',
            // },
            // properties: {
            //     color: '#f00',
            //     description: 'test'
            // }
        },
        toolTipOptions: {
            direction: 'top',
            offset: [0, -40]
        },
        centerAfter: false,
        centerByClickBefore: null,
        centerByClickAfter: null,
        style: null, // стили
        idPrefix: '',
        onAfterBounds: null,
        decoratorOptions: null
    };

    const propsWithDefaults = {
        ...defaultProps,
        ...props,
    };

    // data props
    const {
        map,
        parent,
        data = {},
        centerAfter = false,
        onAfterBounds = null,
        onClick = null,
    } = propsWithDefaults;

    const prevData = usePrevious(data);

    const handleClick = () => {
        onClick && onClick();
    };

    // слой json
    const layerRef = useRef(null);
    // id данных для открытия
    const currentIdsRef = useRef([]);
    // context menu
    const contextMenuRef = useRef(null);
    const decoratorRef = useRef([]);

    // обработка geoJson объекта
    useEffect(() => {
        // только изменение
        if (!layerRef.current || !isEqual(data, prevData)) {
            clearLayer(layerRef, decoratorRef, propsWithDefaults)();

            try{
                const json = L.geoJSON(data, {
                    // стили
                    style: jsonStyle,
                    // маркеры + окружность
                    pointToLayer: pointToLayer(propsWithDefaults),
                    // проход по всем объектам
                    onEachFeature: onEachFeature(propsWithDefaults, layerRef, currentIdsRef, contextMenuRef, decoratorRef),
                });
    
                layerRef.current = json;
    
                json.addTo(parent);
                json.on('click', handleClick);
    
                if (centerAfter) {
                    setTimeout(() => {
                        const bounds = json?.getBounds() || null;
                        if (bounds && bounds?.isValid?.()) map.fitBounds(bounds);
                    }, 200);
                }
    
                if (onAfterBounds) {
                    const bounds = json?.getBounds?.() || null;
                    if (bounds && bounds?.isValid?.()) onAfterBounds(bounds);
                }
            } catch (e) {
                // console.log(e);
            }

        }
    }, [data, prevData, map, parent/*, layerRef.current*/]);

    // очистка слоя
    useEffect(() => {
        return () => {
            clearLayer(layerRef, decoratorRef, propsWithDefaults)();
        };
    }, []);

    return null;
};

export default GeoJson;
