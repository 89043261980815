import {
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    FormHelperText,
} from '@mui/material';
import { useValidation } from '../../../../../helpers/hooks';
import React, { useState } from 'react';
import formatDate from '../../../../../helpers/constants/dateFormat';
import messages from '../../../../../helpers/constants/messages';
import DeleteIcon from '@mui/icons-material/Delete';
import titles from '../../../../../helpers/constants/titles';
import ConfirmDelete from '../../../../common/ConfirmModal';
import { TimePicker } from '@mui/lab';
import SingleKeyboardDateTimePicker from '../../../../common/Dates/SingleKeyboardDateTimePicker';

const LeaveArriveForm = ({ onChangeItem, onDeleteItem, index, item, shiftList, isClear = true }) => {
    const validation = useValidation(isClear);
    const [isDelete, setIsDelete] = useState(false);

    const changeDate = (value, name) => {
        onChangeItem({
            ...item,
            [name]: value
        });
        validation.deleteKey(`departure_return.${index}.${name}`);
    };

    const onChangeData = (e) => {
        const { name, value } = e.target;
        onChangeItem({
            ...item,
            [name]: value,
        });
        validation.deleteKey(`departure_return.${index}.${name}`);
    };

    return (<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ width: '93%' }}>
            <FormControl error={!item.shift} required variant="outlined" size="small" className="block">
                <InputLabel>Смена</InputLabel>
                <Select
                    error={!item.shift}
                    label="Смена"
                    value={item.shift || ''}
                    onChange={onChangeData}
                    name="shift"
                    required={true}
                    // error={shiftError}
                >
                    {shiftList.length > 0
                        ? shiftList?.map((key, i) =>
                            <MenuItem key={i} value={key}>{key}</MenuItem>
                        )
                        : <MenuItem disabled>{messages.NO_DATA}</MenuItem>
                    }
                </Select>

                {validation.isKey(`departure_return.${index}.shift`)
                    ? <FormHelperText error>{validation.get(`departure_return.${index}.shift`)}</FormHelperText>
                    : null
                }
            </FormControl>
            <div className="block">
                <SingleKeyboardDateTimePicker
                    timeOnly
                    required
                    label="Плановое время выезда"
                    pickerFormat={formatDate.HH_MM}
                    value={item.departure_plan || null}
                    onChange={(value) => changeDate(value, 'departure_plan')}
                    helperText={validation.get(`departure_return.${index}.departure_plan`)}
                    error={!item.departure_plan || validation.isKey(`departure_return.${index}.departure_plan`)}
                />
            </div>
            <div className="block">
                <SingleKeyboardDateTimePicker
                    timeOnly
                    required
                    label="Плановое время возвращения"
                    pickerFormat={formatDate.HH_MM}
                    value={item.return_plan || null}
                    onChange={(value) => changeDate(value, 'return_plan')}
                    helperText={validation.get(`departure_return.${index}.return_plan`)}
                    error={!item.return_plan || validation.isKey(`departure_return.${index}.return_plan`)}
                />
            </div>
            <div className="block">
                <SingleKeyboardDateTimePicker
                    timeOnly
                    required
                    label="Фактическое время выезда"
                    pickerFormat={formatDate.HH_MM}
                    value={item.departure_fact || null}
                    onChange={(value) => changeDate(value, 'departure_fact')}
                    helperText={validation.get(`departure_return.${index}.departure_fact`)}
                    error={!item.departure_fact || validation.isKey(`departure_return.${index}.departure_fact`)}
                />
            </div>
            <div className="block">
                <SingleKeyboardDateTimePicker
                    timeOnly
                    required
                    label="Фактическое время возвращения"
                    pickerFormat={formatDate.HH_MM}
                    value={item.return_fact || null}
                    onChange={(value) => changeDate(value, 'return_fact')}
                    helperText={validation.get(`departure_return.${index}.return_fact`)}
                    error={!item.return_fact || validation.isKey(`departure_return.${index}.return_fact`)}
                />
            </div>
        </div>
        <div>
            <Tooltip title={titles.DELETE}>
                <IconButton onClick={() => setIsDelete(true)} size={'small'}>
                    <DeleteIcon fontSize={'inherit'}/>
                </IconButton>
            </Tooltip>
        </div>
        <Divider style={{ margin: '5px 0' }}/>
        { isDelete
            && <ConfirmDelete
                open={isDelete}
                onClose={()=> setIsDelete(false)}
                onSuccess={onDeleteItem}
                message={messages.CONFIRM_DELETE}
            />
        }
    </div>);
};

export default LeaveArriveForm;