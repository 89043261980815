import React from 'react';
import './legend.scss';


function Legend({ reportCategory }) {
    return (
        <div className="cat_legend">
            {Object.keys(reportCategory).map((key) => {
                const item = reportCategory[key];
                const { name, color } = item;

                return (
                    <div className="item" key={`legend_${key}`}>
                        <i style={{ background: color }}></i>
                        <span>
                            {name}
                        </span>
                    </div>
                );
            })}
        </div>
    );
}

export default Legend;