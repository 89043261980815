import { useState } from 'react';

import { loadDtpLoadTypes } from 'redux/Incidents/actions';
import { useStoreProp } from 'helpers/hooks';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const Filters = ({ filterValues, setFilterValues, initialFilter, onSearch }) => {
    const dtpLoadTypes = useStoreProp(loadDtpLoadTypes, 'incidents', 'loadTypes');

    const [isDisabled, setIsDisabled] = useState(false);

    const onChange = (key, value) => {
        setFilterValues(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const resetFilter = (needRefresh) => {
        setFilterValues(initialFilter);
        if (needRefresh) {
            onSearch(initialFilter);
        }
    };

    const updateFilter = (filters) => {
        const newFilter = { ...initialFilter, ...filters };
        setFilterValues(newFilter);
        onSearch(newFilter);
    };

    return (
        <LayoutFilter
            filter={filterValues}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            filterException={['start_date', 'end_date']}
            disabled={isDisabled}
            isFilterVisible={true}
        >
            <LayoutFilter.Visible>
                <DateRange
                    dateOnly={true}
                    valueStartDate={filterValues.start_date}
                    valueEndDate={filterValues.end_date}
                    handleDate={(value) => onChange('start_date', value)}
                    handleEndDate={(value) => onChange('end_date', value)}
                    isDisabledHandler={(value) => setIsDisabled(value)}
                    
                />

                <CommonAutocomplete
                    multiple
                    label={'Источник создания'}
                    selected={filterValues.type_load || []}
                    options={Object.keys(dtpLoadTypes)}
                    onChange={(value) => onChange('type_load', value)}
                    onReset={() => onChange('type_load', [])}
                    inputName="type_load"
                    renderLabel={(option) => dtpLoadTypes[option] || ''}
                    limitTags={1}
                    filterSelectedOptions={true}
                    showAsSelect
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;
