import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { Grid } from '@mui/material';
import queryString from 'query-string';

import { loadRoutes } from 'redux/TransportRoad/actions';
import { transportRoadSelectors } from 'redux/TransportRoad';
import PageLayout from 'components/layout/PageLayout';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import NoData from 'components/common/Information/NoData';
import { LSContainer } from 'components/common/List';
import PassportTabs from 'components/common/PassportTabs';
import titles from 'helpers/constants/titles';
import Context from 'helpers/context';

import RouteItem from './Item';
import RouteInfo from './PassportPage/BasicData';
import Filters from './Filters';
import AddEditCopyModal from './AddEditCopyModal';

function Routes() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [modalOpen, setModalOpen] = useState(false);
    const [params, setParams] = useState({
        page: 1,
        limit: storageLimit,
        filter: {
            status_id_list: [1, 2]
        }
    });

    const [currentRoute, setCurrentRoute] = useState(null);

    const routesData = useSelector(transportRoadSelectors.routesData);
    const routesMeta = useSelector(transportRoadSelectors.routesMeta);
    const loadingRoutes = useSelector(transportRoadSelectors.loadingRoutes);
    const [focus, setFocus] = useState(null);

    const filterFunc = (params) => {
        const asArray = Object.entries(params).filter(([key, value]) => value);
        return Object.fromEntries(asArray);
    };

    // превращаем params в search и добавляем в урл
    const stringifyUrlFunc = (params = {}) => {
        const stringifyUrl = (queryString.stringify(filterFunc(params), { arrayFormat: 'bracket' }));
        history.replace({ pathname: '/dictionaries/roadworks/routes', search: stringifyUrl });
    };

    const handleClickRoute = (route) => {
        const { page, limit } = params;
        const { num, category_id } = queryString.parse(location.search, { arrayFormat: 'bracket' });
        // проверяем мы кликнули по тому же маршруту в списке? тогда просто его удаляем из урла
        setFocus(route.id);
        if (route.id === currentRoute?.id) {
            stringifyUrlFunc({ page, limit, num, category_id });
        } else {
            stringifyUrlFunc({ page, limit, num, category_id, route_id: route.id });
        }
    };

    const changePage = (page, limit) => {
        setParams({
            ...params,
            page,
            limit
        });
        stringifyUrlFunc({ page, limit });
    };

    useEffect(()=> {
        const { page, limit } = (queryString.parse(location.search, { arrayFormat: 'bracket' }));
        if (page || limit) {
            const filterParams = filterFunc({ page: Number(page), limit: Number(limit) });
            setParams((params)=> ({
                ...params,
                ...filterParams
            }));
        }
    }, []);

    useEffect(() => {
        if (routesData?.length > 0) {
            const { route_id } = queryString.parse(location.search, { arrayFormat: 'bracket' });
            const route = route_id ? routesData?.find(item => item.id === Number(route_id)) : null;
            setCurrentRoute(route);
        }
    }, [location.search, routesData]);

    const reloadList = () => dispatch(loadRoutes(params.page, params.limit, params.filter));

    useEffect(() => {
        dispatch(loadRoutes(params.page, params.limit, params.filter));
    }, [dispatch, params.filter, params.limit, params.page]);

    const groupRouteList = () => {
        return (
            <LSContainer
                headers={[
                    { title: titles.NUM, width: '20%' },
                    { title: titles.NAME, width: '20%' },
                    { title: titles.TYPE, width: '20%' },
                    { title: titles.STATUS, align: '20%' },
                    { title: 'Действия', align: 'right', isActions: true }
                ]}
                isMobile={!!currentRoute}
            >
                {routesData?.map((route, i) => (
                    <RouteItem
                        key={`${route?.num}${route.category_id}${i}`}
                        data={route}
                        onClickItem={(route) => handleClickRoute(route)}
                        // typeTransportation={typeTransportation}
                        reloadList={reloadList}
                        selectedId={route?.id === currentRoute?.id}
                        focus={focus}
                        checkRoute={!!currentRoute}
                    />
                ))}
            </LSContainer>
        );
    };

    const renderContent = () => (
        <div
            style={{
                ...(currentRoute
                    ? {}
                    : {
                        display: 'flex',
                        flexDirection: 'column',
                    }),
                height: '100%',
            }}
        >
            <div
                style={{
                    minWidth: '60%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {routesData?.length > 0 ? (
                    <>
                        <Grid container style={currentRoute ? { height: '100%' } : {}}>
                            <Grid item xs={currentRoute ? 3 : 12} style={{ height: '100%' }}>
                                {currentRoute ? (
                                    <Scrollbars style={{ height: '100%' }}>
                                        {groupRouteList()}
                                    </Scrollbars>
                                ) : (
                                    groupRouteList()
                                )}
                            </Grid>
                            {currentRoute && (
                                <Grid item xs={9}>
                                    <PassportTabs tabs={[
                                        {
                                            value: 'data',
                                            label: titles.BASIC_DATA,
                                            icon: <i className="fal fa-info-square"/>,
                                            contentComponent: <RouteInfo currentRoute={currentRoute} reloadList={reloadList}/>,
                                        },
                                    ]}/>
                                </Grid>
                            )}
                        </Grid>
                    </>
                ) : (
                    !loadingRoutes && <NoData/>
                )}
            </div>
        </div>
    );

    return (
        <>
            <PageLayout
                header={titles.ROUTES}
                filters={
                    <Filters
                        setParams={(newFilter) => {
                            setParams(old => ({
                                ...old,
                                filter: newFilter,
                                page: 1,
                            }));
                            // меняем урл, чтобы сбросить выбранный маршрут
                            stringifyUrlFunc({
                                page: 1,
                                limit: params.limit
                            });
                        }}
                    />
                }
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setModalOpen(true),
                                    disabled: !permissions?.is_create,
                                },
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: routesMeta?.total,
                }}
                loading={loadingRoutes}
                content={renderContent}
                paginationProps={{
                    loadList: changePage,
                    list: routesMeta,
                    limit: params.limit,
                    setLimit: (limit) => setParams({ ...params, limit }),
                }}
                customStyles={{ ...(currentRoute && { padding: 0 }) }}
            />

            {modalOpen && (
                <AddEditCopyModal
                    isOpen={modalOpen}
                    onClose={setModalOpen}
                    isNew
                    permissions={permissions}
                    reloadList={reloadList}
                />
            )}
        </>
    );
}

export default Routes;
