import replaceForWsInRedux from 'helpers/replaceForWsInRedux';

import * as types from './types';

const initialState = {
    loading: false,
    boardStatuses: [],
    boardTypes: {},
    boards: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    loadingBefore: false,
    loadingMessages: false,
    boardMessages: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    messagePriorities: {},
    boardColors: {},
    polygon: [],
    polygonLoading: false,
    active: {},
    filters: {},
    // данные для удаления
    deleteForm: false,
    // данные для редактирования
    editForm: false,
    sidebar: {
        data: [],
        meta: {
            last_page: 0,
            total: 0,
        }
    },
    sidebarLoading: false,
    sidebarParams: {
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25
    },
    saved: false,
    messagePreview: {},
    messageJSONPreview: null,
    loadingMessageJSONPreview: false,
    activeBoardMessage: {},
    loadingActiveBoardMessage: false,
    fontsList: [],
    fontsListForTypeWithImg: [],
    signsList: [],
    boardRotationMessages: {},
    loadingBoardRotationMessages: false,
    ditLink: [],
    loadingDitLink: false,
    loadingMessageTemplates: false,
    boardMessageTemplates: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    alertTemplateStatuses: [],
    alertTemplateTypes: [],
    alertTemplateFields: [],
    loadingAlertTemplates: false,
    alertTemplates: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    boardsFromLink: {},
    loadingBoardsFromLink: false,
    templateImageList: {
        data: []
    },
    templateImageListLoading: false,
    templateImageLoading: false,
    boardSigns: {}, // храним в сторе загруженных знаки, чтобы не загружать заново каждый раз
    boardSignsLoading: false,
    alignX: {},
    alignY: {},
    showcase: {},
    loadingShowcase: false,
    boadrCategories: {}
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADING: {
            return {
                ...state,
                loading: payload,
            };
        }

        case types.BOARD_STATUSES_LOADED: {
            return {
                ...state,
                boardStatuses: payload,
            };
        }

        case types.BOARD_TYPES_LOADED: {
            return {
                ...state,
                boardTypes: payload,
            };
        }

        case types.BOARDS_LOADED: {
            return {
                ...state,
                boards: payload,
            };
        }

        case types.CLEAR_BOARDS: {
            return {
                ...state,
                boards: {
                    data: [],
                    meta: {
                        last_page: 0
                    }
                },
            };
        }

        case types.LOADING_MESSAGES: {
            return {
                ...state,
                loadingMessages: payload,
            };
        }

        case types.LOADED_MESSAGES: {
            return {
                ...state,
                boardMessages: payload,
            };
        }

        case types.LOADED_MESSAGE_PRIORITIES: {
            return {
                ...state,
                messagePriorities: payload,
            };
        }

        case types.LOADED_BOARD_COLORS: {
            return {
                ...state,
                boardColors: payload,
            };
        }

        case types.POLYGON_LOADED: {
            return {
                ...state,
                polygon: payload,
            };
        }

        case types.POLYGON_LOADING: {
            return {
                ...state,
                polygonLoading: payload,
            };
        }

        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }

        case types.RESET_ACTIVE: {
            return {
                ...state,
                active: initialState.active,
            };
        }

        case types.SET_FILTERS: {
            return {
                ...state,
                filters: payload,
            };
        }

        case types.SET_DELETE_FORM: {
            return {
                ...state,
                deleteForm: payload,
            };
        }

        case types.SET_EDIT_FORM: {
            return {
                ...state,
                editForm: payload,
            };
        }

        case types.LOADED_SIDEBAR:
            const page = payload.meta?.current_page || 1;

            if (page === 1) {
                return {
                    ...state,
                    sidebar: payload,
                };
            }

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...payload,
                    data: [
                        ...state?.sidebar?.data,
                        ...payload?.data
                    ]
                },
            };

        case types.CLEAR_SIDEBAR:
            return {
                ...state,
                sidebar: initialState.sidebar,
            };

        case types.LOADING_SIDEBAR:
            return {
                ...state,
                sidebarLoading: payload,
            };

        case types.SET_SAVED:
            return {
                ...state,
                saved: payload,
            };

        case types.FETCHED_PREVIEW: {
            return {
                ...state,
                messagePreview: payload,
            };
        }

        case types.FETCHED_MESSAGE_JSON_PREVIEW: {
            return {
                ...state,
                messageJSONPreview: payload,
            };
        }

        case types.FETCHING_MESSAGE_JSON_PREVIEW: {
            return {
                ...state,
                loadingMessageJSONPreview: payload,
            };
        }

        case types.CLEAR_MESSAGE_JSON_PREVIEW: {
            return {
                ...state,
                messageJSONPreview: null,
            };
        }

        case types.FETCHED_ACTIVE_BOARD_MESSAGE: {
            return {
                ...state,
                activeBoardMessage: payload,
            };
        }

        case types.CLEAR_ACTIVE_BOARD_MESSAGE: {
            return {
                ...state,
                activeBoardMessage: {},
            };
        }

        case types.FETCHING_ACTIVE_BOARD_MESSAGE: {
            return {
                ...state,
                loadingActiveBoardMessage: payload,
            };
        }

        case types.LOADED_FONTS_LIST: {
            return {
                ...state,
                fontsList: payload
            };
        }

        case types.LOADED_SIGNS_LIST: {
            return {
                ...state,
                signsList: payload
            };
        }

        case types.LOADED_BOARD_ROTATION_MESSAGES: {
            return {
                ...state,
                boardRotationMessages: { ...state.boardRotationMessages, ...payload },
            };
        }

        case types.LOADING_BOARD_ROTATION_MESSAGES: {
            return {
                ...state,
                loadingBoardRotationMessages: payload,
            };
        }

        case types.LOADED_DIT_LINK: {
            return {
                ...state,
                ditLink: payload,
            };
        }

        case types.LOADING_DIT_LINK: {
            return {
                ...state,
                loadingDitLink: payload,
            };
        }

        case types.CLEAR_DIT_LINK: {
            return {
                ...state,
                ditLink: [],
            };
        }

        case types.LOADING_BOARDS_FROM_LINK: {

            return {
                ...state,
                loadingBoardsFromLink: payload
            };
        }

        case types.LOADED_BOARDS_FROM_LINK: {
            const boards = payload.reduce((res, el) => {
                res[el.id] = el;
                return res;
            }, {});
            return {
                ...state,
                boardsFromLink: {
                    ...state.boardsFromLink,
                    ...boards,
                },
            };
        }

        case types.LOADING_MESSAGE_TEMPLATES: {
            return {
                ...state,
                loadingMessageTemplates: payload,
            };
        }

        case types.LOADED_MESSAGE_TEMPLATES: {
            return {
                ...state,
                boardMessageTemplates: payload,
            };
        }

        case types.LOADED_FONTS_LIST_FOR_TYPE_WITH_IMG: {
            return {
                ...state,
                fontsListForTypeWithImg: payload
            };
        }

        case types.SET_WS_DIT: {
            const newPolygon = payload
                ?.reduce(
                    (res, item) => replaceForWsInRedux(res || [], item),
                    state.polygon
                );

            return {
                ...state,
                polygon: newPolygon,
                // polygon: replaceForWsInRedux(state.polygon || [], payload),
                sidebarParams: {
                    ...state.sidebarParams,
                    page: 1,
                }
                // sidebar: {
                //     ...state.sidebar,
                //     data: replaceForWsInRedux(state.sidebar?.data || [], payload, 'id', false),
                // },
            };
        }

        case types.SET_SIDEBAR_PARAMS: {
            return {
                ...state,
                sidebarParams: payload
            };
        }

        case types.LOADED_LIST_TEMPLATE_IMAGE: {
            return {
                ...state,
                templateImageList: payload,
            };
        }

        case types.LOADING_LIST_TEMPLATE_IMAGE: {
            return {
                ...state,
                templateImageListLoading: payload
            };
        }

        case types.LOADING_TEMPLATE_IMAGE: {
            return {
                ...state,
                templateImageLoading: payload
            };
        }
        case types.ALERT_TEMPLATE_FILEDS_LOADED: {
            return {
                ...state,
                alertTemplateFields: payload
            };
        }

        case types.ALERT_TEMPLATE_STATUSES_LOADED: {
            return {
                ...state,
                alertTemplateStatuses: payload
            };
        }

        case types.ALERT_TEMPLATE_TYPES_LOADED: {
            return {
                ...state,
                alertTemplateTypes: payload
            };
        }

        case types.LOADING_ALERT_TEMPLATES: {
            return {
                ...state,
                loadingAlertTemplates: payload
            };
        }

        case types.ALERT_TEMPLATES_LOADED: {
            return {
                ...state,
                alertTemplates: payload
            };
        }

        case types.LOADED_SIGNS: {
            const newData = payload.reduce((result, el) => {
                result[el.sign] = el;
                return result;
            }, {});

            return {
                ...state,
                boardSigns: {
                    ...state.boardSigns,
                    ...newData,
                },
            };
        }

        case types.LOADING_SIGNS: {
            return {
                ...state,
                boardSignsLoading: payload
            };
        }

        case types.LOADED_ALIGN_HORIZONTAL: {
            return {
                ...state,
                alignX: payload
            };
        }

        case types.LOADED_ALIGN_VERTICAL: {
            return {
                ...state,
                alignY: payload
            };
        }

        case types.LOADED_BOARD_BEFORE: {
            if (payload.data?.length > 0) {
                return {
                    ...state,
                    boards: {
                        ...state.boards,
                        data: [
                            ...payload.data,
                            ...state.boards?.data,
                        ],
                        meta: {
                            last_page: 0,
                            ...state.boards?.meta,
                        }
                    },
                };
            }
            return state;
        }

        case types.LOADING_BOARD_BEFORE: {
            return {
                ...state,
                loadingBefore: payload,
            };
        }

        case types.LOADED_SHOWCASE: {
            return {
                ...state,
                showcase: payload
            };
        }

        case types.LOADING_SHOWCASE: {
            return {
                ...state,
                loadingShowcase: payload
            };
        }

        case types.LOADED_CATEGORIES: {
            return {
                ...state,
                boadrCategories: payload
            };
        }

        default:
            return state;
    }
}
