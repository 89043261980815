import * as types from './types';

/**
 * Actions
 **/

export const loadSidebar = (page = 1, limit = 25, data, replaceStore = true) => ({
    type: types.LOAD_SIDEBAR,
    payload: {
        page,
        limit,
        data,
        replaceStore
    },
});
export const loadedSidebar = (data, replaceStore = true) => ({
    type: types.LOADED_SIDEBAR,
    payload: {
        data,
        replaceStore,
    }
});
export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool,
});


export const loadPolygon = (polygon, params = {}) => ({
    type: types.LOAD_POLYGON,
    payload: {
        polygon,
        params
    }
});
export const loadedPolygon = (data) => ({
    type: types.LOADED_POLYGON,
    payload: data
});
export const clearPolygon = () => ({
    type: types.LOADED_POLYGON,
    payload: []
});
export const loadingDtpPolygon = (bool = false) => ({
    type: types.LOADING_DTP_POLYGON,
    payload: bool
});

export const setActive = (item = {}) => ({
    type: types.SET_ACTIVE,
    payload: item,
});
export const resetActive = () => ({
    type: types.RESET_ACTIVE,
});


export const setDeleteForm = (data = null) => ({
    type: types.SET_DELETE_FORM,
    payload: data,
});
export const setEditForm = (data = null) => ({
    type: types.SET_EDIT_FORM,
    payload: data,
});


export const createDtpFocus = (data) => ({
    type: types.CREATE_DTP_FOCUS,
    payload: {
        data,
    },
});

export const editDtpFocus = (id, data) => ({
    type: types.EDIT_DTP_FOCUS,
    payload: {
        id,
        data,
    },
});

export const deleteDtpFocus = (id) => ({
    type: types.DELETE_DTP_FOCUS,
    payload: {
        id,
    },
});

export const saveDtpFocus = (bool = false) => ({
    type: types.SAVE_DTP_FOCUS,
    payload: bool,
});

export const setFilters = (data = {}) => ({
    type: types.SET_FILTERS,
    payload: data,
});

export const clearFilters = () => ({
    type: types.SET_FILTERS,
    payload: {},
});

export const loading = (bool = false) => ({
    type: types.LOADING,
    payload: bool,
});

export const getDtpFocus = (page = 1, limit = 25, data) => ({
    type: types.LOAD_DTP_FOCUS,
    payload: {
        page,
        limit,
        data,
    },
});

export const loadedDtpFocus = (data) => ({
    type: types.LOADED_DTP_FOCUS,
    payload: data
});

export const loadingDtpFocus = (bool = false) => ({
    type: types.LOADING_DTP_FOCUS,
    payload: bool,
});

export const loadDtpFocusStatistic = (filter) => ({
    type: types.LOAD_DTP_FOCUS_STATISTIC,
    payload: filter
});
export const loadedDtpFocusStatistic = (data) => ({
    type: types.LOADED_DTP_FOCUS_STATISTIC,
    payload: data
});

export const loadingDtpFocusStatistic = (bool = false) => ({
    type: types.LOADING_DTP_FOCUS_STATISTIC,
    payload: bool,
});

// тепловая карта
export const loadHeatMap = (filter = {}) => ({
    type: types.LOAD_HEAT_MAP,
    payload: filter
});
export const loadedHeatMap = (data) => ({
    type: types.LOADED_HEAT_MAP,
    payload: data
});
export const clearHeatMap = () => ({
    type: types.LOADED_HEAT_MAP,
    payload: []
});
export const loadingHeatMap = (bool = false) => ({
    type: types.LOADING_HEAT_MAP,
    payload: bool,
});
export const setHeatMapFilter = (filter = {}) => ({
    type: types.SET_FILTER_HEAT_MAP,
    payload: filter,
});

export const setPolygonVisible = (data = []) => ({
    type: types.SET_POLYGON_VISIBLE,
    payload: data
});
