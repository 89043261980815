import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import titles from '../../../../../helpers/constants/titles';
import cn from 'classnames';
import DateRange from '../../../../common/Dates/DateRange';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import buttons from '../../../../../helpers/constants/buttons';
import { getDateWithDuration, getEndOf, getStartOf } from '../../../../../helpers/date.config';

interface FilterType {
    setBool: Dispatch<SetStateAction<boolean>>
}

const Filter = ({ setBool }: FilterType) => {
    const initState = {
        avk: '',
        start_date: getStartOf('day', getDateWithDuration({ month: -1 })),
        end_date: getEndOf('day'),
    };

    const [data, setData] = useState(initState);

    const resetFilters = () => {
        console.log('resetFilters');
        setData(initState);
        setBool(false);
    };

    const handleChange = (value: object, name: string) => {        
        setData({
            ...data,
            [name]: value
        });
    };

    const handleSearch = () => {
        setBool(true);
    };

    return (
        <div className="filter">
            <div className={cn('filter__wrap', 'filter__wrap__more__field')}>
                <FormControl size="small" variant="outlined">
                    <InputLabel>{'АВК'}</InputLabel>
                    <Select
                        value={data.avk || ''}
                        onChange={() => {}}
                        label={'АВК'}
                    >
                        <MenuItem value="">
                            {titles.NOT_CHOSEN}
                        </MenuItem>
                        {[]?.map((item, index) => (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className={cn('filter__wrap', 'filter__wrap__more__field')}>
                <DateRange
                    valueStartDate={data.start_date}
                    valueEndDate={data.end_date}
                    handleDate={(value: any) => handleChange(value, 'start_date')}
                    handleEndDate={(value: any) => handleChange(value, 'end_date')}
                    views={undefined}
                />
            </div>
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilters,
                        },
                        {
                            ...buttonsTypes.find,
                            name: buttons.FIND,
                            onClick: handleSearch,
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;