import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStoreProp } from 'helpers/hooks';
import { load1CNamesReport, loadComplexTypes } from 'redux/DorisControl/actions';
import GetReports from 'components/common/GetReports/index';
import ComplexTypeSelect from 'components/common/SimpleSelect/index';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter/index';

interface Format {
    id: string; 
    name: string;
}

interface DataState {
    type_id: string;
    formats: Format[];
}

const Filter = () => {
    const dispatch = useDispatch();

    const complexTypes = useStoreProp(
        loadComplexTypes,
        'dorisControl',
        'doris_complex_types'
    );

    const initialState: DataState = {
        type_id: '',
        formats: [],
    };

    const [data, setData] = useState<DataState>(initialState);

    const handleLoadReport = () => {
        const paramsWithoutEmpty = Object.keys(data).reduce((res, key) => {
            const el = data[key as keyof DataState];
            if (el && !Array.isArray(el)) {
                res[key] = el;
            }
            if (Array.isArray(el)) {
                res[key] = el.map(item => item.id);
            }
            return res;
        }, {} as {[key: string]: string | string[]});
        dispatch(load1CNamesReport(paramsWithoutEmpty));
    };

    const resetFilter = () => {
        setData(initialState);
    };

    const updateFilter = (filter: Partial<DataState>) => {
        setData({
            ...initialState,
            ...filter
        });
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            buttonType="getReport"
            onSearch={handleLoadReport}
            filter={data}
            setUserFilter={updateFilter}
            disabled={(!data.type_id && !data.formats.length)}
            isFilterVisible={true}
        >
            <LayoutFilter.Visible>
                <ComplexTypeSelect
                    endpoint={complexTypes}
                    selected={data.type_id}
                    setSelected={(value: string)=> setData({ ...data, type_id: value })}
                    title="Тип комплекса"
                    optionKey="id"
                    optionLabel="name"
                    optionValue="id"
                />
                <GetReports 
                    selected={data.formats}
                    onChange={(values: Format[]) => setData({ ...data, formats: values })}
                    reportId={8} 
                    onDownload={undefined} 
                    helperText={undefined}                    
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
