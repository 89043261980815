import { List } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../../helpers/constants/messages';
import { loadExclusiveFiles } from '../../../../../redux/DorisControl/actions';
import CustomPagination from '../../../../common/CustomPagination';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Loading from '../../../../common/Loading';
import Item from './Item';
import ModalForm from './ModalForm';
// import History from './History';
import Logs from './Logs';

import { dorisControlSelectors } from '../../../../../redux/DorisControl';

const FTPUpload = () => {
    const dispatch = useDispatch();

    const data = useSelector(dorisControlSelectors.exclusiveFilesData);
    const meta = useSelector(dorisControlSelectors.exclusiveFilesMeta);
    const loading = useSelector(dorisControlSelectors.loadingExclusiveFiles);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, query: {} });
    const [openAddModal, setOpenAddModal] = useState(false);
    // const [openHistory, setOpenHistory] = useState(false);
    const [openLog, setOpenLog] = useState(false);

    const loadList = useCallback(() => {
        dispatch(loadExclusiveFiles(params.page, limit, params.query));
    }, [dispatch, limit, params.page, params.query]);

    useEffect(() => {
        dispatch(loadExclusiveFiles(params.page, limit, params.query));
    },[dispatch, limit, params]);

    return (
        <>
            <h1>Загрузка файлов на FTP</h1>
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.defaultPrimary,
                            name: 'Журнал загрузок',
                            onClick: () => setOpenLog(true),
                        },
                        // {
                        //     ...buttonsTypes.defaultPrimary,
                        //     name: 'История загрузки',
                        //     onClick: () => setOpenHistory(true),
                        // },
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenAddModal(true),
                        }
                    ]}
                />
            </div>
            {loading && <Loading circular/>}
            {data.length > 0
                ? (
                    <>
                        <List className="list">
                            {
                                data?.map(item => (
                                    <Item
                                        key={item.id}
                                        item={item}
                                        loadList={loadList}
                                    />
                                ))
                            }
                        </List>
                        <CustomPagination
                            loadList={(page) => setParams({ ...params, page })}
                            list={meta}
                            limit={limit}
                            setLimit={setLimit}
                        />
                    </>
                )
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    loadList={loadList}
                    isNew
                />
            )}

            {openLog && (
                <Logs
                    isOpen={openLog}
                    onClose={() => setOpenLog(false)}
                />
            )}

            {/*{openHistory && (*/}
            {/*    <History*/}
            {/*        isOpen={openHistory}*/}
            {/*        onClose={() => setOpenHistory(false)}*/}
            {/*    />*/}
            {/*)}*/}
        </>
    );
};

export default FTPUpload;