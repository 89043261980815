import * as types from './types';

// прочитано
export const changeReadNotification = (id) => ({ 
    type: types.CHANGE_READ,
    payload: {
        id,
    },
});

export const setReadNotification = (id) => ({
    type: types.SET_READ,
    payload: id,
});

// прочитаны все
export const changeReadAllNotification = () => ({
    type: types.CHANGE_READ_ALL,
});

export const setReadAllNotification = () => ({
    type: types.SET_READ_ALL,
});


export const setLoadingNotification = (bool = false) => ({
    type: types.SET_LOADING,
    payload: bool,
});

// подгрузить список
export const loadListNotification = (page = 1, limit = 10, params = {}) => ({ 
    type: types.LOAD_LIST,
    payload: {
        page,
        limit,
        ...params,
    },
});

export const loadedListNotification = (data, replace = false) => ({
    type: types.LOADED_LIST,
    payload: {
        data,
        replace,
    },
});
// загружаем события по конкретному сервису
export const loadServiceListNotification = (page = 1, limit = 10, params = {}) => ({ 
    type: types.LOAD_SERVICE_LIST,
    payload: {
        page,
        limit,
        ...params,
    },
});

export const loadedServiceListNotification = (data, service) => ({
    type: types.LOADED_SERVICE_LIST,
    payload: {
        data,
        service,
    },
});

// подгрузить количество не прочитанных
export const loadCountActiveNotification = () => ({
    type: types.LOAD_COUNT_ACTIVE,
});

export const loadedCountActiveNotification = (count) => ({
    type: types.LOADED_COUNT_ACTIVE,
    payload: count,
});

// установить последнюю загруженную страницу
export const setLastPageNotification = (page) => ({
    type: types.SET_LAST_PAGE,
    payload: page,
});

export const loadFreeSpaceServer = () => ({
    type: types.LOAD_FREE_SPACE_SERVER,
});

export const loadedFreeSpaceServer = (data) => ({
    type: types.LOADED_FREE_SPACE_SERVER,
    payload: data
});

export const reloadListNotification = (params) => ({
    type: types.RELOAD_LIST_NOTIFICATION,
    payload: params
});
export const reloadedListNotification = (data) => ({
    type: types.RELOADED_LIST_NOTIFICATION,
    payload: data
});

export const wsReloadNotification = (bool = true) => ({
    type: types.WS_RELOAD_NOTIFICATION,
    payload: bool
});

export const clearList = () => ({
    type: types.CLEAR_LIST,
});
