import React, { useEffect, useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem, InputAdornment, IconButton,
} from '@mui/material';
import useStoreProp from '../../../../../../helpers/hooks/useStoreProp';
import titles from '../../../../../../helpers/constants/titles';
import * as actions from '../../../../../../redux/TrafficLights/actions';
import Modal from '../../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import UniversalSelect from '../../../../../common/UniversalSelect';
import { useDebounce } from '../../../../../../helpers/hooks';
import { useDispatch } from 'react-redux';
import { Autocomplete } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles({
    adornment: {
        position: 'absolute',
        right: '16px',
    },
});

const Filter = (props) => {
    const {
        data,
        onChange,
        onChangeTextField,
    } = props;
    const dispatch = useDispatch();
    const styles = useStyles();
    const [openModalCG, setOpenModalCG] = useState(false);
    const [openModalGS, setOpenModalGS] = useState(false);

    const statuses = useStoreProp(actions.loadTrafficLightsStatuses, 'trafficLights', 'lightsStatuses');
    const states = useStoreProp(actions.loadTrafficLightsStates, 'trafficLights', 'lightStates');
    const controlTypes = useStoreProp(actions.loadTrafficLightControlTypes, 'trafficLights', 'lightControlTypes');

    const fetchCG = useDebounce((e) => {
        dispatch(actions.loadCoordinationGroups(e));
    }, 500);
    const handleFetchCG = ({ page, limit, query: name }) => fetchCG({ page, limit, name });

    const fetchGS = useDebounce((e) => {
        dispatch(actions.loadGreenStreets(e));
    }, 500);
    const handleFetchGS = ({ page, limit, query: name }) => fetchGS({ page, limit, name });

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({
            [name]: value,
        });
    };

    const handleEventAC = (key) => (_, newValue) => {
        onChange({
            [key]: newValue
        });
    };

    const handleAccept = (selectedItem) => {
        onChange({ cg_ids: selectedItem });
        setOpenModalCG(false);
    };

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <FormControl className="block" size="small" variant="outlined">
                <TextField
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    value={data.name || ''}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                />
            </FormControl>
            <FormControl size="small" variant="outlined" className="block">
                <InputLabel>{titles.STATUS}</InputLabel>
                <Select
                    value={data.status?.id || ''}
                    onChange={({ target: { value } }) => onChange({ status: { id: value, name: statuses[value] } })}
                    label={titles.STATUS}
                    name="status"
                >
                    {Object.keys(statuses).map((index) => (
                        <MenuItem key={index} value={index}>{statuses[index]}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="block">
                <Autocomplete
                    value={data.state_type_ids || null}
                    options={states}
                    noOptionsText={titles.NO_RESULTS}
                    isOptionEqualToValue={(option, value) => option.external_id === value.id?.join('')}
                    getOptionLabel={(option) => option.full_name || option.name}
                    onChange={(_, value) => onChange({ state_type_ids: { id: [value?.external_id], name: value?.full_name } })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.STATE}
                        />
                    )}
                />
            </div>
            <div className="block">
                <Autocomplete
                    value={data.control_type_ids || null}
                    options={controlTypes}
                    noOptionsText={titles.NO_RESULTS}
                    isOptionEqualToValue={(option, value) => option.external_id === value.id?.join('')}
                    getOptionLabel={(option) => option.full_name || option.name}
                    onChange={(_, value) => onChange({ control_type_ids: { id: [value?.external_id], name: value?.full_name } })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Тип контроля"
                        />
                    )}
                />
            </div>
          
            <FormControl size="small" variant="outlined" className="block">
                <TextField
                    value={data?.cg_ids?.map(({ name }) => name).join(', ') || ''}
                    label={titles.GROUP_COORDINATES}
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    disabled={true}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={'end'} classes={{ root: styles.adornment }}>
                                {data?.cg_ids && Object.keys(data?.cg_ids).length > 0 
                                        && <IconButton size="small" onClick={() => onChange({ cg_ids: [] })}>   
                                            <i className="far fa-times"/>
                                        </IconButton>
                                }
                                <IconButton size="small" onClick={() => setOpenModalCG(true)}>
                                    <i className="fas fa-search-plus"/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </FormControl>
            {openModalCG 
                && <UniversalSelect
                    storeName="trafficLights"
                    storeNameProps="coordinationGroups"
                    storeLoadingProps="coordinationGroupsLoading"
                    keyProp="id"
                    multiple
                    fetchList={handleFetchCG}
                    withSearch
                    isSelected
                    isClickAlways
                    selected={data.cg_ids || []}
                    renderProps={(el) => <div><span>{el.name}</span></div>}
                    isOpen={openModalCG}
                    onClose={() => setOpenModalCG(false)}
                    onAccept={handleAccept}
                    noPadding
                    title={titles.SELECT_GROUP_COORDINATES}
                    small
                />
            }

            <FormControl size="small" variant="outlined" className="block">
                <TextField
                    value={data?.gs_ids?.map(({ name }) => name).join(', ') || ''}
                    label={titles.GREEN_STREET}
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    disabled={true}
                    // className={!data?.type_id?.name ? classes.root : ''}
                    // error={data?.type_id?.name === '' || !!validation.isKey('type_id')}
                    // helperText={validation.get('type_id')}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment>
                                <IconButton size="small" onClick={() => setOpenModalGS(true)}>
                                    <i className="fas fa-search-plus"></i>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </FormControl>
            {openModalGS 
                && <UniversalSelect
                    storeName="trafficLights"
                    storeNameProps="greenStreets"
                    storeLoadingProps="greenStreetsLoading"
                    keyProp="id"
                    fetchList={handleFetchGS}
                    withSearch={true}
                    isSelected={true}
                    multiple={true}
                    isClickAlways={true}
                    onAccept={(selectedItem) => {
                        onChange({ gs_ids: selectedItem });
                        setOpenModalGS(false);
                    }}
                    selected={data.gs_ids || []}
                    renderProps={(el) => <div><span>{el.name}</span></div>}
                    isOpen={openModalGS}
                    onClose={() => setOpenModalGS(false)}
                    noPadding={true}
                    title={titles.SELECT_GREEN_STREET}
                    small={true}
                />
            }
        </form>
    );
};

export default Filter;
