import { useState } from 'react';
import { Button, IconButton, Tooltip, FormControl, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SpeedLimitItem from './SpeedLimitItem';
import ConfirmDelete from '../../../../../../../../common/ConfirmModal';
import messages from '../../../../../../../../../helpers/constants/messages';
import RenderKoapSelect from '../RenderKoapSelect';
import buttons from '../../../../../../../../../helpers/constants/buttons';
import FieldDirection from './FieldDirection';

const LineItem = ({ index, validation, changeLine, data, lines, line, fields, deleteLine }) => {

    const [showDelete, setShowDelete] = useState(false);

    const addSpeedLimit = () => {
        const newLine = { ...line, speed: [...line.speed, { type: '', speed: '' }] };

        changeLine(newLine);
    };

    const changeSpeedLimit = (value, index) => {
        validation.deleteKey(`lines.${index}.speed`);
        const newLine = { ...line, speed: line.speed.map((el, i) => i === index ? value : el ) };

        changeLine(newLine);
    };

    const deleteSpeedLimit = (index) => {
        const newLine = { ...line, speed: line.speed.filter((_, i) => i !== index) };

        changeLine(newLine);
    };

    const changeData = (e) => {
        const { name, value } = e.target;
        validation.deleteKey(`lines.${index}.${name}`);

        changeLine({ ...line, [name]: value });
    };

    const changeDirection = (direction) => {
        changeLine({ ...line, ...direction });
    };

    const changeKoapList = (el) => {
        validation.deleteKey(`lines.${index}.koap`);
        changeLine({ ...line, koap: el });
    };

    const fieldNumber = fields?.find(el => el.field === 'id') || {};
    const fieldDirection = fields?.find(el => el.field === 'direction');

    line.direction = line?.direction || data?.direction[0]?.direction || '';
    line.direction_id = line?.direction_id || 0;

    return (
        <div className="row" style={{ backgroundColor: '#ebebeb', padding: '0.5rem' }}>
            <div className="row__item">
                <div className="row">
                    <FormControl size="small" variant="outlined">
                        <TextField
                            required={fieldNumber.require}
                            label={fieldNumber.name || 'Номер полосы'}
                            variant="outlined"
                            size="small"
                            value={line.id}
                            name="id"
                            onChange={changeData}
                            type="text"
                            error={(!line.id && fieldNumber.require) || validation.isKey(`lines.${index}.id`)}
                            helperText={validation.get(`lines.${index}.id`)}
                        />
                    </FormControl>
                    <div className="row__item">
                        <RenderKoapSelect
                            item={line.koap}
                            onChange={changeKoapList}
                            field={fields?.find(el => el.field === 'koap') || {}}
                            error={validation.isKey(`lines.${index}.koap`)}
                            helperText={validation.get(`lines.${index}.koap`)}
                        />
                    </div>
                </div>

                {fieldDirection && (
                    <FieldDirection
                        directions={data?.direction}
                        value={line?.direction}
                        directionId={line?.direction_id}
                        fieldDirection={fieldDirection}
                        changeData={changeDirection}
                        error={validation.isKey(`lines.${index}.${fieldDirection.field}`)}
                        helperText={validation.get(`lines.${index}.${fieldDirection.field}`)}
                    />
                )}

                <div>
                    <h3>Фиксация скорости</h3>
                    {line?.speed?.map((item, i) => (
                        <SpeedLimitItem
                            key={i}
                            item={item}
                            field={fields?.find(el => el.field === 'speed') || {}}
                            onChangeItem={(value) => changeSpeedLimit(value, i)}
                            onDeleteItem={() => deleteSpeedLimit(i)}
                            error={(name) => validation.isKey(`lines.${i}.${name}`)}
                            helperText={(name) => validation.get(`lines.${i}.${name}`)}
                        />
                    ))}
                    <Button variant="text" onClick={addSpeedLimit} >
                        {buttons.ADD_SPEED}
                    </Button>
                </div>
            </div>
            <Tooltip title={'Удалить полосу'} style={{ margin: '1rem 0 0' }}>
                <IconButton onClick={() => setShowDelete(true)} size="small">
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            
            {showDelete
                && <ConfirmDelete
                    open={showDelete}
                    onClose={()=> setShowDelete(false)}
                    onSuccess={()=> {
                        setShowDelete(false);
                        deleteLine();
                    }}
                    message={messages.CONFIRM_DELETE}
                />
            }
        </div>
    );
};

export default LineItem;