const docs = {
    DOCUMENT: 'Документ',
    EDIT_DOCUMENT: 'Редактирование документа',
    CREATE_DOCUMENT: 'Создание документа',
    DELETE_DOCUMENT: 'Удлить документ',
    DOCUMENT_NUMBER: 'Документ №',
    DOCUMENTS: 'Документы',
    ADD_DOCUMENTS: 'Добавить документы',
};

export default docs;