import React from 'react';
import FormButtons from '../../../../../common/FormButtons';
import ConditionItem from './ConditionItem';

const RenderCondition = ({ 
    textData, 
    data = [],
    onChange,
    microservice = ''
}) => {

    const addNewCondition = () => {
        const newCondition =  {
            data: { [textData.type]: '' },
            param: '',
            value: '',
            operator: '',
            microservice
        };

        onChange({ 
            ...textData, 
            condition: [...data, newCondition]
        });
    };

    const onChangeCondition = (i, value) => {
        const newData = [
            ...data.slice(0, i),
            value,
            ...data.slice(i + 1)
        ];

        onChange({ 
            ...textData, 
            condition: newData
        });
    };

    const onDeleteCondition = (i) => {
        const newData = [
            ...data.slice(0, i),
            ...data.slice(i + 1)
        ];
        onChange({ 
            ...textData, 
            condition: newData
        });
    };

    return (
        <>
            {data.map((item, index) => 
                <ConditionItem
                    key={index}
                    item={item}
                    type={textData.type}
                    onChange={(value) => onChangeCondition(index, value)}
                    onDelete={() => onDeleteCondition(index)}
                    microservice={microservice}
                />
            )}
            <FormButtons
                noPadding
                positionLeft
                noMarginLeft
                buttons={[
                    {
                        onClick:addNewCondition,
                        name: 'Добавить условие'
                    }
                ]}
            /> 
        </>
    );
};

export default RenderCondition;
