import moduleName from './module';

const prefix = `${moduleName}`;

export const LOAD_BOARD_STATUSES = `${prefix}/LOAD_BOARD_STATUSES`;
export const BOARD_STATUSES_LOADED = `${prefix}/BOARD_STATUSES_LOADED`;
export const LOAD_BOARD_TYPES = `${prefix}/LOAD_BOARD_TYPES`;
export const BOARD_TYPES_LOADED = `${prefix}/BOARD_TYPES_LOADED`;
export const LOADING = `${prefix}/LOADING`;
export const LOAD_BOARDS = `${prefix}/LOAD_BOARDS`;
export const BOARDS_LOADED = `${prefix}/BOARDS_LOADED`;
export const CREATE_BOARD = `${prefix}/CREATE_BOARD`;
export const EDIT_BOARD = `${prefix}/EDIT_BOARD`;
export const DELETE_BOARD = `${prefix}/DELETE_BOARD`;
export const CLEAR_BOARDS = `${prefix}/CLEAR_BOARDS`;
export const LOAD_BOARD_COLORS = `${prefix}/LOAD_BOARD_COLORS`;
export const LOADED_BOARD_COLORS = `${prefix}/LOADED_BOARD_COLORS`;

export const LOAD_MESSAGE_PRIORITIES = `${prefix}/LOAD_MESSAGE_PRIORITIES`;
export const LOADED_MESSAGE_PRIORITIES = `${prefix}/LOADED_MESSAGE_PRIORITIES`;
export const LOAD_MESSAGES = `${prefix}/LOAD_MESSAGES`;
export const LOADING_MESSAGES = `${prefix}/LOADING_MESSAGES`;
export const LOADED_MESSAGES = `${prefix}/LOADED_MESSAGES`;
export const CREATE_MESSAGE = `${prefix}/CREATE_MESSAGE`;
export const EDIT_MESSAGE = `${prefix}/EDIT_MESSAGE`;
export const DELETE_MESSAGE = `${prefix}/DELETE_MESSAGE`;

// map
export const GET_POLYGON = `${prefix}/GET_POLYGON`;
export const POLYGON_LOADED = `${prefix}/POLYGON_LOADED`;
export const SET_DELETE_FORM = `${prefix}/SET_DELETE_FORM`;
export const SET_EDIT_FORM = `${prefix}/SET_EDIT_FORM`;
export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;
export const RESET_ACTIVE = `${prefix}/RESET_ACTIVE`;
export const SET_FILTERS = `${prefix}/SET_FILTERS`;
export const LOADING_SIDEBAR = `${prefix}/LOADING_SIDEBAR`;
export const LOADED_SIDEBAR = `${prefix}/LOADED_SIDEBAR`;
export const CLEAR_SIDEBAR = `${prefix}/CLEAR_SIDEBAR`;
export const LOAD_SIDEBAR = `${prefix}/LOAD_SIDEBAR`;
export const SET_SAVED = `${prefix}/SET_SAVED`;
export const POLYGON_LOADING = `${prefix}/POLYGON_LOADING`;

// export const LOAD_BOARD_SERVICE_INFO = `${prefix}/LOAD_BOARD_SERVICE_INFO`;
// export const LOADED_BOARD_SERVICE_INFO = `${prefix}/LOADED_BOARD_SERVICE_INFO`;

// export const FETCH_SERVICE_ENTITIES = `${prefix}/FETCH_SERVICE_ENTITIES`;
// export const FETCHING_SERVICE_ENTITIES = `${prefix}/FETCHING_SERVICE_ENTITIES`;
// export const FETCHED_SERVICE_ENTITIES = `${prefix}/FETCHED_SERVICE_ENTITIES`;

export const FETCH_PREVIEW = `${prefix}/FETCH_PREVIEW`;
export const FETCHED_PREVIEW = `${prefix}/FETCHED_PREVIEW`;

export const FETCH_MESSAGE_JSON_PREVIEW = `${prefix}/FETCH_MESSAGE_JSON_PREVIEW`;
export const FETCHING_MESSAGE_JSON_PREVIEW = `${prefix}/FETCHING_MESSAGE_JSON_PREVIEW`;
export const FETCHED_MESSAGE_JSON_PREVIEW = `${prefix}/FETCHED_MESSAGE_JSON_PREVIEW`;
export const CLEAR_MESSAGE_JSON_PREVIEW = `${prefix}/CLEAR_MESSAGE_JSON_PREVIEW`;

export const FETCH_ACTIVE_BOARD_MESSAGE = `${prefix}/FETCH_ACTIVE_BOARD_MESSAGE`;
export const FETCHING_ACTIVE_BOARD_MESSAGE = `${prefix}/FETCHING_ACTIVE_BOARD_MESSAGE`;
export const FETCHED_ACTIVE_BOARD_MESSAGE = `${prefix}/FETCHED_ACTIVE_BOARD_MESSAGE`;
export const CLEAR_ACTIVE_BOARD_MESSAGE = `${prefix}/CLEAR_ACTIVE_BOARD_MESSAGE`;

export const LOAD_FONTS_LIST = `${prefix}/LOAD_FONTS_LIST`;
export const LOADED_FONTS_LIST = `${prefix}/LOADED_FONTS_LIST`;

export const LOAD_FONTS_LIST_FOR_TYPE_WITH_IMG = `${prefix}/LOAD_FONTS_LIST_FOR_TYPE_WITH_IMG`;
export const LOADED_FONTS_LIST_FOR_TYPE_WITH_IMG = `${prefix}/LOADED_FONTS_LIST_FOR_TYPE_WITH_IMG`;

export const LOAD_SIGNS_LIST = `${prefix}/LOAD_SIGNS_LIST`;
export const LOADED_SIGNS_LIST = `${prefix}/LOADED_SIGNS_LIST`;

export const LOAD_BOARD_ROTATION_MESSAGES = `${prefix}/LOAD_BOARD_ROTATION_MESSAGES`;
export const LOADING_BOARD_ROTATION_MESSAGES = `${prefix}/LOADING_BOARD_ROTATION_MESSAGES`;
export const LOADED_BOARD_ROTATION_MESSAGES = `${prefix}/LOADED_BOARD_ROTATION_MESSAGES`;

export const LOAD_DIT_LINK = `${prefix}/LOAD_DIT_LINK`;
export const LOADING_DIT_LINK = `${prefix}/LOADING_DIT_LINK`;
export const LOADED_DIT_LINK = `${prefix}/LOADED_DIT_LINK`;
export const CLEAR_DIT_LINK = `${prefix}/CLEAR_DIT_LINK`;
export const CREATE_DIT_LINK = `${prefix}/CREATE_DIT_LINK`;
export const LOAD_BOARDS_FROM_LINK = `${prefix}/LOAD_BOARDS_FROM_LINK`;
export const LOADING_BOARDS_FROM_LINK = `${prefix}/LOADING_BOARDS_FROM_LINK`;
export const LOADED_BOARDS_FROM_LINK = `${prefix}/LOADED_BOARDS_FROM_LINK`;

export const LOAD_MESSAGE_TEMPLATES = `${prefix}/LOAD_MESSAGE_TEMPLATES`;
export const LOADING_MESSAGE_TEMPLATES = `${prefix}/LOADING_MESSAGE_TEMPLATES`;
export const LOADED_MESSAGE_TEMPLATES = `${prefix}/LOADED_MESSAGE_TEMPLATES`;
export const CREATE_MESSAGE_TEMPLATE = `${prefix}/CREATE_MESSAGE_TEMPLATE`;
export const EDIT_MESSAGE_TEMPLATE = `${prefix}/EDIT_MESSAGE_TEMPLATE`;
export const DELETE_MESSAGE_TEMPLATE = `${prefix}/DELETE_MESSAGE_TEMPLATE`;
export const CREATE_MESSAGE_TEMPLATE_BOARDS = `${prefix}/CREATE_MESSAGE_TEMPLATE_BOARDS`;

export const SET_WS_DIT = `${prefix}/SET_WS_DIT`;
export const SET_SIDEBAR_PARAMS = `${prefix}/SET_SIDEBAR_PARAMS`;

export const LOAD_LIST_TEMPLATE_IMAGE = `${prefix}/LOAD_LIST_TEMPLATE_IMAGE`;
export const LOADED_LIST_TEMPLATE_IMAGE = `${prefix}/LOADED_LIST_TEMPLATE_IMAGE`;
export const CREATE_TEMPLATE_IMAGE = `${prefix}/CREATE_TEMPLATE_IMAGE`;
export const EDIT_TEMPLATE_IMAGE = `${prefix}/EDIT_TEMPLATE_IMAGE`;
export const DELETE_TEMPLATE_IMAGE = `${prefix}/DELETE_TEMPLATE_IMAGE`;
export const LOADING_LIST_TEMPLATE_IMAGE = `${prefix}/LOADING_LIST_TEMPLATE_IMAGE`;
export const LOADING_TEMPLATE_IMAGE = `${prefix}/LOADING_TEMPLATE_IMAGE`;


export const LOAD_ALERT_TEMPLATE_FILEDS = `${prefix}/LOAD_ALERT_TEMPLATE_FILEDS`;
export const ALERT_TEMPLATE_FILEDS_LOADED = `${prefix}/ALERT_TEMPLATE_FILEDS_LOADED`;
export const LOAD_ALERT_TEMPLATE_STATUSES = `${prefix}/LOAD_ALERT_TEMPLATE_STATUSES`;
export const ALERT_TEMPLATE_STATUSES_LOADED = `${prefix}/ALERT_TEMPLATE_STATUSES_LOADED`;
export const LOAD_ALERT_TEMPLATE_TYPES = `${prefix}/LOAD_ALERT_TEMPLATE_TYPES`;
export const ALERT_TEMPLATE_TYPES_LOADED = `${prefix}/ALERT_TEMPLATE_TYPES_LOADED`;
export const LOADING_ALERT_TEMPLATES = `${prefix}/LOADING_ALERT_TEMPLATES`;
export const LOAD_ALERT_TEMPLATES = `${prefix}/LOAD_ALERT_TEMPLATES`;
export const ALERT_TEMPLATES_LOADED = `${prefix}/ALERT_TEMPLATES_LOADED`;
export const CREATE_ALERT_TEMPLATE = `${prefix}/CREATE_ALERT_TEMPLATE`;
export const EDIT_ALERT_TEMPLATE = `${prefix}/EDIT_ALERT_TEMPLATE`;
export const DELETE_ALERT_TEMPLATE = `${prefix}/DELETE_ALERT_TEMPLATE`;

export const LOAD_SIGNS = `${prefix}/LOAD_SIGNS`;
export const LOADING_SIGNS = `${prefix}/LOADING_SIGNS`;
export const LOADED_SIGNS = `${prefix}/LOADED_SIGNS`;
export const LOAD_ALIGN_HORIZONTAL = `${prefix}/LOAD_ALIGN_HORIZONTAL`;
export const LOADED_ALIGN_HORIZONTAL = `${prefix}/LOADED_ALIGN_HORIZONTAL`;
export const LOAD_ALIGN_VERTICAL = `${prefix}/LOAD_ALIGN_VERTICAL`;
export const LOADED_ALIGN_VERTICAL = `${prefix}/LOADED_ALIGN_VERTICAL`;

export const LOAD_BOARD_BEFORE = `${prefix}/LOAD_BOARD_BEFORE`;
export const LOADED_BOARD_BEFORE = `${prefix}/LOADED_BOARD_BEFORE`;
export const LOADING_BOARD_BEFORE = `${prefix}/LOADING_BOARD_BEFORE`;

export const LOAD_SHOWCASE = `${prefix}/LOAD_SHOWCASE`;
export const LOADING_SHOWCASE = `${prefix}/LOADING_SHOWCASE`;
export const LOADED_SHOWCASE = `${prefix}/LOADED_SHOWCASE`;

export const LOAD_CATEGORIES = `${prefix}/LOAD_CATEGORIES`;
export const LOADED_CATEGORIES = `${prefix}/LOADED_CATEGORIES`;
