import Settings from '../../../../common/Settings';
import {
    editSettingsGroup,
    editSettingsList,
    loadSettingsGroup
} from '../../../../../redux/RoadWorks/actions';

const RoadWorkSettings = () => {
    return (
        <Settings
            title="Настройки – Дорожные работы"
            storeProp="roadworks"
            listName="settingsGroup"
            loadingName="loadingSettingsGroup"
            loadSettingsGroupAction={loadSettingsGroup}
            editSettingsListAction={editSettingsList}
            editSettingsGroupAction={editSettingsGroup}
        />
    );
    
};

export default RoadWorkSettings;