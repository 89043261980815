import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadVehicles } from 'modules/TransportWater/redux/actions';
import { transportWaterSelectors } from 'modules/TransportWater';
import titles from 'helpers/constants/titles';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import { LSContainer } from 'components/common/List';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';
import Filter from './Filter';
import Item from './Item';

import type { Meta } from 'types/pagination';
import type { Vehicle } from './types';

function Vehicles() {
    const dispatch = useDispatch();

    const vehicles: Vehicle[] = useSelector(transportWaterSelectors.vehiclesList);
    const meta: Meta = useSelector(transportWaterSelectors.vehiclesMeta);
    const loading = useSelector(transportWaterSelectors.loadingVehicles);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [isOpenAddModal, setIsOpenAddModal] = useState(false);

    const reloadList = (isDelete = false) => {
        const page = isDelete && meta.last_page > 1 && vehicles.length === 1
            ? params.page - 1
            : params.page;

        dispatch(loadVehicles(page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadVehicles(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    const renderContent = () => {
        return vehicles.length > 0
            ? (
                <LSContainer
                    headers={[
                        { title: titles.NAME, width: '45%' },
                        { title: titles.TYPE, width: '45%' },
                        { isActions: true },
                    ]}
                >
                    {vehicles.map((item) => (
                        <Item
                            key={item.id}
                            item={item}
                            reloadList={reloadList}
                        />
                    ))}
                </LSContainer>
            )
            : !loading && <NoData />;
    };

    return (
        <>
            <PageLayout
                header="Транспортные средства"
                filters={<Filter setParams={setParams} />}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            noPadding
                            noMarginLeft
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setIsOpenAddModal(true)
                                }
                            ]} />
                    ),
                    total: meta?.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams(prev => ({ page, data: prev.data })),
                    list: meta,
                    limit,
                    setLimit,
                }}
            />

            {isOpenAddModal && (
                <ModalForm
                    isNew
                    isOpen={isOpenAddModal}
                    onClose={() => setIsOpenAddModal(false)}
                    reloadList={reloadList}
                />
            )}
        </>
    );
}

export default Vehicles;
