import { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import cn from 'classnames';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
// import SelectEvents from '../../../../common/Autocomplete/DorisReact/Events';
import KeyWords from '../../../../common/Autocomplete/DorisReact/KeyWords';
import titles from '../../../../../helpers/constants/titles';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';

const Filter = ({ setParams, types }) => {

    const [wasSearch, setWasSearch] = useState(false);

    const typeObj = types?.map(key => ({
        id: key.id,
        label: key.name,
    }));

    const initialState = {
        name: '',
        event: {},
        event_id: null,
        keyword_list: [],
        keyword_id_list: [],
        type_id: { id: '', name: '' },
        address: '',
    };

    const [data, setData] = useState(initialState);

    const handleResetFilter = () => {
        setData(initialState);
        if (wasSearch) {
            setParams({ page: 1, data: {} });
            setWasSearch(false);
        }
    };

    const handleSearch = () => {
        setWasSearch(true);

        const transformationDataForSend = removeEmptyFields({
            ...data,
            type_id: data.type_id.id,
            event: {},
            keyword_list: [],
        });

        setParams({ page: 1, data: transformationDataForSend });
    };

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const handleChangeType = (_, newValue) => {
        setData({
            ...data,
            type_id: newValue
        });
    };

    const handleClickEnter = (e) => {
        if (e?.key === 'Enter') handleSearch();
    };

    return (
        <div className="filter">
            <div className={cn('filter__wrap', 'filter__wrap__more__field')}>
                <TextField
                    label={titles.NAME}
                    size="small"
                    value={data.name}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    onKeyPress={handleClickEnter}
                />
                <Autocomplete
                    value={data.type_id}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={typeObj}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.label || ''}
                    onChange={handleChangeType}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.TYPE_EVENTS}
                        />
                    )}
                />
                {/*<SelectEvents*/}
                {/*    selected={data.event}*/}
                {/*    onChange={(value) => setData({*/}
                {/*        ...data,*/}
                {/*        event: value,*/}
                {/*        event_id: value?.id || null*/}
                {/*    })}*/}
                {/*    label={titles.EVENT}*/}
                {/*/>*/}
                <KeyWords
                    multiple
                    selected={data.keyword_list}
                    onChange={(value) => setData({
                        ...data,
                        keyword_list: value,
                        keyword_id_list: value.map(({ id }) => id)
                    })}
                />
                <TextField
                    label={titles.ADDRESS}
                    size="small"
                    value={data.address}
                    variant="outlined"
                    name="address"
                    type="text"
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    onKeyPress={handleClickEnter}
                />
            </div>
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: handleResetFilter,
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: handleSearch,
                            disabled: !data.address
                                && !data.keyword_id_list.length
                                && !data?.event_id
                                && !data.name
                                && !data.type_id?.id
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;