import React, { Fragment } from 'react';
import { Collapse } from '@mui/material';
import messages from '../../../../helpers/constants/messages';
import titles from '../../../../helpers/constants/titles';

const notInfo = messages.INFO_IS_NOT_FOUND;

const Info = ({ isOpen, item = {} }) => {
    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            <div>
                <strong>{titles.CAMERAS}: </strong>
                {item?.cameras.map(el => (<Fragment key={el.id}>
                    <br/><span style={{ marginLeft: 10 }}>{el.name || notInfo}</span> 
                </Fragment>))}
            </div>
        </Collapse>
    );
};

export default Info;