import * as types from './types';

/**
 * Actions
 **/

export const loadPolygon = (polygon, filter = {}) => ({
    type: types.LOAD_POLYGON,
    payload: {
        polygon,
        filter
    }
});
export const loadedPolygon = (data) => ({
    type: types.LOADED_POLYGON,
    payload: data
});
export const loadingPolygon = (bool = false) => ({
    type: types.LOADING_POLYGON,
    payload: bool
});
export const clearPolygon = () => ({
    type: types.LOADED_POLYGON,
    payload: []
});

export const setActive = (item = {}) => ({
    type: types.SET_ACTIVE,
    payload: item,
});
export const clearActive = () => ({
    type: types.RESET_ACTIVE,
});


export const setDeleteForm = (data = null) => ({
    type: types.SET_DELETE_FORM,
    payload: data,
});
export const setEditForm = (data = null) => ({
    type: types.SET_EDIT_FORM,
    payload: data,
});

export const setFilters = (data = {}) => ({
    type: types.SET_FILTERS,
    payload: data,
});
export const clearFilters = () => ({
    type: types.SET_FILTERS,
    payload: {},
});

// список сайдбара
export const loadSidebar = (page, limit, params = {}) => ({
    type: types.LOAD_SIDEBAR,
    payload: {
        page,
        limit,
        ...params,
    },
});

export const loadedSidebar = (data, isReplace) => ({
    type: types.LOADED_SIDEBAR,
    payload: {
        data,
        isReplace,
    },
});

export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool,
});

export const clearSidebar = () => ({
    type: types.CLEAR_SIDEBAR,
});

export const createPark = (data, callback) => ({
    type: types.CREATE_PARK,
    payload: data,
    callback
});

export const editPark = (id, data, callback) => ({
    type: types.EDIT_PARK,
    payload: { id, data },
    callback
});

export const deletePark = (id, callback) => ({
    type: types.DELETE_PARK,
    payload: id,
    callback
});

export const setSaved = (bool = false) => ({
    type: types.SET_SAVED,
    payload: bool
});

export const setLoading = (bool = null) => ({
    type: types.SET_LOADING,
    payload: bool
});

// статусы
export const loadStatuses = () => ({
    type: types.LOAD_STATUSES,
});

export const loadedStatuses = (data) => ({
    type: types.LOADED_STATUSES,
    payload: data
});

// типы
export const loadTypes = () => ({
    type: types.LOAD_TYPES,
});

export const loadedTypes = (data) => ({
    type: types.LOADED_TYPES,
    payload: data
});

// зоны
export const loadZones = () => ({
    type: types.LOAD_ZONES,
});

export const loadedZones = (data) => ({
    type: types.LOADED_ZONES,
    payload: data
});

// загрузить статистику
export const loadStatusStatistic = () => ({
    type: types.LOAD_STATUS_STATISTIC,
});

export const loadedStatusStatistic = (data) => ({
    type: types.LOADED_STATUS_STATISTIC,
    payload: data,
});

// load list
export const loadParkingList = (page, limit, params = {}) => ({
    type: types.LOAD_PARKING_LIST,
    payload: {
        page,
        limit,
        ...params,
    },
});

export const loadedParkingList = (data) => ({
    type: types.LOADED_PARKING_LIST,
    payload: data,
});

export const loadingParkingList = (bool = false) => ({
    type: types.LOADING_PARKING_LIST,
    payload: bool,
});

export const loadTariffList = (page, limit) => ({
    type: types.LOAD_TARIFF_LIST,
    payload: {
        page,
        limit,
    },
});

export const loadedTariffList = (data) => ({
    type: types.LOADED_TARIFF_LIST,
    payload: data,
});

export const loadCategories = (page, limit) => ({
    type: types.LOAD_CATEGORIES,
    payload: {
        page,
        limit,
    },
});

export const loadedCategories = (data) => ({
    type: types.LOADED_CATEGORIES,
    payload: data,
});