import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import {
    loadCurrentThreatLevel,
    // loadedCurrentThreatLevel,
    loadTransportIncidentById,
    loadedTransportIncident,
    // loadTransportIncidentStatuses,
    loadTransportIncidentTypeCreation
} from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import { humanDateTimeWithoutTZ } from 'helpers/date.config';

const TransportIncidentInfoModal = ({ onClose = () => null, transportIncidentId }) => {
    const dispatch = useDispatch();

    const currentThreatLevel = useSelector(SituationalPlansSelectors.currentThreatLevel);
    const transportIncident = useSelector(SituationalPlansSelectors.transportIncident);
    const loadingTransportIncident = useSelector(SituationalPlansSelectors.loadingTransportIncident);

    useEffect(() => {
        return () => {
            // dispatch(loadedCurrentThreatLevel({}));
            dispatch(loadedTransportIncident({}));
        };
    }, []);

    const tiData = transportIncident?.data || {};
    const threat_level = currentThreatLevel[tiData.threat_level_id] || {};

    // useEffect(() => {
    //     dispatch(loadTransportIncidentStatuses());
    // }, []);

    const transportIncidentTypeCreation = useStoreProp(
        loadTransportIncidentTypeCreation,
        'SituationalPlans',
        'transportIncidentTypeCreation'
    );

    useEffect(() => {
        if (transportIncidentId) {
            dispatch(loadTransportIncidentById(transportIncidentId));
        }
    }, [transportIncidentId, dispatch]);

    useEffect(() => {
        if (tiData?.threat_level_id) {
            dispatch(loadCurrentThreatLevel(tiData.threat_level_id));
        }
    }, [tiData, dispatch]);

    return (
        <Modal
            isOpen={true}
            onClose={onClose}
            title="Связанное событие"
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: () => onClose()
                    }
                ]}
            />}
        >
            {loadingTransportIncident ? (
                <div style={{ minHeight: 150 }}>
                    <Loading circular/>
                </div>
            ) : (
                <ul style={{ listStyle: 'none' }}>
                    <li><strong>Дата/время события: </strong><span>{humanDateTimeWithoutTZ(tiData?.created_at)}</span></li>
                    <li><strong>Адрес: </strong><span>{tiData?.address_text || messages.NO_DATA}</span></li>
                    <li><strong>Тип: </strong><span>{tiData?.type_text}</span></li>
                    <li><strong>Статус: </strong><span>{tiData?.status_text}</span></li>
                    <li>
                        <strong>Уровень угрозы: </strong>
                        <span style={{ color: threat_level?.color }}>
                            {threat_level?.name || messages.NO_DATA}
                        </span>
                    </li>
                    <li>
                        <strong>Способ создания события: </strong>
                        <span>
                            {transportIncidentTypeCreation[tiData?.type_creation] || ''}
                        </span>
                    </li>
                </ul>
            )}
        </Modal>
    );
};

export default TransportIncidentInfoModal;