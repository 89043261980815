import React, { useState } from 'react';
import Modal from '../../../../../../common/Modal';
import SelectTags from '../../../../../../common/Autocomplete/DorisControl/Tags';
import FormButtons, { buttonsTypes } from '../../../../../../common/FormButtons';
import { useDispatch } from 'react-redux';
import titles from '../../../../../../../helpers/constants/titles';
import { complexSyncTags } from '../../../../../../../redux/DorisControl/actions';

const SelectTagsModal = (props) => {
    const {
        open,
        multiple,
        tagsList = [],
        onClose = () => {},
        complexId,
        onEdited = () => {}
    } = props;
    const dispatch = useDispatch();
    const [data, setData] = useState(tagsList);

    const onChangeTags = (value) => {
        setData(value);
    };

    const handleCloseTags = () => {
        onClose();
        onEdited();
    };

    const onSyncTags = () => {
        dispatch(complexSyncTags(
            { complex_id: complexId, tags: data.map(item => item.id) }, 
            handleCloseTags
        ));
    };

    return <Modal
        isOpen={open}
        onClose={onClose}
        title={titles.SELECT_TAGS}
        buttons={
            <FormButtons
                buttons={[
                    {
                        ...buttonsTypes.save,
                        onClick: onSyncTags
                    },
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                ]}
            />
        }
    >
        <div className="modal__form">
            <SelectTags
                multiple={multiple}
                selected={data}
                onChange = {onChangeTags}
                validation = {false}
                required = {false}
                disabled = {false}
                label = {'Теги'}
            />
        </div>
    </Modal>;
};

export default SelectTagsModal;