import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { loadDtpSeverity } from 'redux/Incidents/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import { getYear, getYearDiff } from 'helpers/date.config';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import FormButtons,{ buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';

const AddEditModal = ({
    isOpen = false,
    onClose = () => {},
    member,
    index,
    onAccept }) => {

    const severityList = useStoreProp(loadDtpSeverity, 'incidents', 'severity');

    const severityOptions = Object
        .keys(severityList)
        .map((key) => ({
            id: key,
            name: severityList[key]
        }));

    const initialState = {
        fio: member?.fio || '',
        birth_year: member?.birth_year || null,
        severity: member?.severity ? severityOptions.find(({ id }) => parseInt(id) === parseInt(member.severity)) : '',
    };

    const [data, setData] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const onChangeSeverity = (e, value) => {
        if (e?.key !== 'Enter')
            setData({ ...data, severity: value });
    };

    const handleSuccess = () => {
        const newData = {
            ...data,
            severity: data.severity.id,
        };

        member
            ? onAccept(index, newData)
            : onAccept(newData);
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            small
            title={member ? 'Редактировать участника' : 'Добавить участника'}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSuccess,
                            disabled: !data.fio || !data.birth_year
                        }
                    ]}
                />
            }
        >
            <div className="modal__form">
                <div className="block">
                    <TextField
                        label="ФИО участника"
                        variant="outlined"
                        size="small"
                        className="block"
                        name="fio"
                        value={data.fio}
                        onChange={handleChange}
                        error={!data.fio}
                    />
                </div>
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        value={data.birth_year}
                        onChange={(newDate) => {
                            setData({
                                ...data,
                                birth_year: getYear(newDate)
                            });
                        }}
                        label={'Год рождения'}
                        dateOnly={true}
                        views={['year']}
                        pickerFormat="yyyy"
                        error={!data.birth_year}
                        minDate={{ year: getYearDiff(80) }} // минимальный возраст 80 лет
                    />
                </div>
                <div className="block">
                    <Autocomplete
                        noOptionsText={titles.NO_RESULTS}
                        value={data.severity}
                        isOptionEqualToValue={(option, value) => option.id === value}
                        options={severityOptions}
                        getOptionLabel={(option) => option.name || ''}
                        onChange={onChangeSeverity}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={!data.severity}
                                name={'severity'}
                                size="small"
                                variant="outlined"
                                label={titles.SEVERITY_AFTER_DTP}
                            />
                        )}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AddEditModal;
