import L from 'leaflet';
import React, { useContext, useEffect, useRef } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from '../../../../store';
import { v4 as uuid_v4 } from 'uuid';
import Context from '../../../../helpers/context';
import './popupStyle.scss';
import { __RouterContext } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';

// всплывающая информация
export const PopUp = ({
    map,
    parent,
    children,
    minWidth = 500,
    closeOnClick = true,
    className = 'custom-popup-wrapper',
    onClose = null,
    show = false,
    parentType = '',// тип родителя
    cluster = null, // кластер
    hideArrow,
    offset,
}) => {
    const userPermissions = useContext(Context); // передаем весь контекст вместе c layer и link
    const id = useRef(null);
    const router = useContext(__RouterContext);
    const location = router?.location;

    const context = () => (
        <Router>
            <Context.Provider value={{ 
                permissions: userPermissions.permissions || {}, 
                layer: userPermissions.layer || '',
                link: userPermissions.link || '',
            }}>
                <Provider store={store}>
                    {children}
                </Provider>
            </Context.Provider>
        </Router>
    );

    const handleClick = (e) => {
        setTimeout(() => {
            if (document.getElementById(id.current)){
                document.getElementById(id.current) && unmountComponentAtNode(document.getElementById(id.current));
                render(
                    context(),
                    document.getElementById(id.current)
                );
            }}, 300);
    };

    const handlePopupClose = () => {
        onClose && onClose();
        setTimeout(() => {
            //popUp && popUp.remove();
            document.getElementById(id.current) && unmountComponentAtNode(document.getElementById(id.current));
        }, 150);
    };

    // открыли меню - закрываем информацию
    const handleContextMenuOpen = () => {
        if (parent.isPopupOpen()) {
            parent.closePopup();
        }
    };

    const handleClosePopupAll = () => {
        if (parent && parent.isPopupOpen()) {
            parent.closePopup();
            handlePopupClose();
        }
    };

    useEffect(() => {
        const newId = uuid_v4();
        id.current = newId;

        const options = {
            minWidth,
            closeOnClick,
            className: `${className} ${hideArrow ? 'hide-arrow': ''}`,
        };
        if (offset) options.offset = offset;

        const popUp = L
            .popup(options)
            .setContent(`<div class="popup-route" id="${newId}"></div>`);
        parent
            .bindPopup(popUp);

        return () => {
            document.getElementById(newId) && unmountComponentAtNode(document.getElementById(newId));
            popUp && popUp.remove();
            id.current = null;
        };
    },[parent]);

    useEffect(() => {
        if (id.current) {
            parent
                .on('click', handleClick)
                .on('popupopen', handleClick)
                .on('popupclose', handlePopupClose)
                .on('context_menu_open', handleContextMenuOpen);

            map && map.on('close_popup_all', handleClosePopupAll);

            return () => {
                parent
                    .off('click', handleClick)
                    .off('popupopen', handleClick)
                    .off('popupclose', handlePopupClose)
                    .off('context_menu_open', handleContextMenuOpen);

                map && map.on('close_popup_all', handleClosePopupAll);
            };
        }
    }, [children, parent, id]);

    const showClick = (timeout = 1) => {
        setTimeout(() => {
            parent.openPopup();
            handleClick();
        }, timeout);
    };

    // отобразить
    useEffect(() => {
        if (parent && show && !parent.isPopupOpen()) {
            setTimeout(() => {
                // маркер в кластере
                if (parentType === 'marker' && cluster && cluster.hasLayer) {
                    const markerInCluster = cluster.hasLayer(parent) || false;
                    if (markerInCluster) {
                        // показать маркер в кластере
                        cluster.zoomToShowLayer(parent, () => showClick(300));
                    } else {
                        showClick();
                    }
                } else {
                    showClick();
                }
            }, 300);
        } else if (parent && !show && parent.isPopupOpen()) {
            parent.closePopup();
        }
    }, [show, parent, cluster]);

    return null;
};

export default PopUp;
