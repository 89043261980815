import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';

import { deleteTransportIncident } from 'redux/SituationalPlans/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import MapFormGeoJSON from 'components/MapComponents/leaflet/components/geoJSON/MapFormGeoJSON';
import messages from 'helpers/constants/messages';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';

import History from './History';
import DtpInfoModal from './DtpInfoModal';
import ModalForm from './ModalForm';
import Info from './Info';

const Item = ({ item = {}, reloadList, statuses, isMkDtp = false }) => {
    const {
        id, name, registered_at, date_plan, date_fact, address_text,
        type_text, threat_level, type_creation, status_id, dtp_id, geometry, is_system = false,
    } = item;

    const dispatch = useDispatch();

    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isInfo, setIsInfo] = useState(false);
    const [isOpenMap, setIsOpenMap] = useState(false);
    const [isOpenHistory, setIsOpenHistory] = useState(false);
    const [isOpenDTP, setIsOpenDTP] = useState(false);

    const isAutoCreated = type_creation === 2;

    const handleDelete = () => {
        dispatch(deleteTransportIncident(id, () => {
            reloadList();
            setIsDelete(false);
        }));
    };

    const handleInfo = () => {
        setIsInfo(!isInfo);
    };

    const statusObject = statuses.find(el => el.id === status_id);

    const statusRender = () => (status_id
        && <Tooltip title={statusObject.name}>
            <div style={{ backgroundColor: statusObject.color, width: 20, height: 20, borderRadius: '50%' }}></div>
        </Tooltip>
    );

    const renderThreatLevel = () => item?.threat_level?.color
        ? <span style={{ color: item?.threat_level.color }}>{ item?.threat_level?.name }</span>
        : item?.threat_level?.name;


    return (
        <>
            <LSContentItem onClick={handleInfo}>
                <LSContentColumn>
                    {statusRender() || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {fullDateTimeWithoutSeconds(registered_at) || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {fullDateTimeWithoutSeconds(date_plan) || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {fullDateTimeWithoutSeconds(date_fact) || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {address_text || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {type_text || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {threat_level ? renderThreatLevel() : messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.show,
                                icon: <i className="far fa-arrows"/>,
                                color: 'default',
                                name: 'Связанное ДТП',
                                disabled: !dtp_id,
                                onClick: () => setIsOpenDTP(true),
                            },
                            {
                                ...buttonsTypes.historyIcon,
                                onClick: () => setIsOpenHistory(true),
                            },
                            {
                                ...buttonsTypes.mapIcon,
                                onClick: () => setIsOpenMap(true),
                            },
                            ...(status_id !== 4
                                ? [{
                                    ...buttonsTypes.editIcon,
                                    onClick: () => setIsEditMode(true),
                                    disabled: status_id === 4
                                }]
                                :[]
                            ),
                            ...((!isAutoCreated && is_system === false && status_id !== 4)
                                ? [{
                                    ...buttonsTypes.deleteIcon,
                                    onClick: () => setIsDelete(true),
                                }]
                                : []
                            ),
                        ]}
                    />
                    <ActionMore
                        isOpen={isInfo}
                        onClick={handleInfo}
                    />
                </LSContentColumn>
            </LSContentItem>


            {isEditMode && (
                <ModalForm
                    isOpen={isEditMode}
                    onClose={() => setIsEditMode(false)}
                    item={item}
                    isNew={false}
                    reloadList={reloadList}
                    isMkDtp={isMkDtp}
                />
            )}
            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                />
            )}
            {isInfo && (
                <Info
                    isOpen={isInfo}
                    item={item}
                />
            )}
            {isOpenMap && (
                <Modal
                    isOpen={isOpenMap}
                    onClose={() => setIsOpenMap(false)}
                    noPadding
                    buttons={<FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: () => setIsOpenMap(false)
                            }
                        ]}
                    />}
                    title={name}
                >
                    <MapFormGeoJSON
                        geometry={geometry}
                        readOnly={true}
                        showButtons={false}
                    />
                </Modal>
            )}
            {isOpenDTP && (
                <DtpInfoModal
                    isOpen={isOpenDTP}
                    setOpen={setIsOpenDTP}
                    dtpId={dtp_id}
                />
            )}
            {isOpenHistory && (
                <History
                    isOpen={isOpenHistory}
                    onClose={setIsOpenHistory}
                    id={id}
                />
            )}
        </>
    );
};

export default Item;
