import { useDispatch } from 'react-redux';
import { setActive } from '../../../../redux/Boards/actions';
import { Divider } from '@mui/material';

const BoardItem = ({ data }) => {
    const dispatch = useDispatch();

    const clickableIframe = () => {
        return (
            <div style={{ position: 'relative' }}>
                <div 
                    onClick={() => dispatch(setActive(data))}
                    style={{ 
                        position: 'absolute', 
                        top: 0, zIndex: 100,
                        width: data.width, 
                        height: data.height, 
                        cursor: 'pointer' 
                    }}
                />
                <iframe 
                    src={data?.frame || ''}
                    title="active_message"
                    // +4px чтобы убрать скрол
                    width={data.width + 4}
                    height={data.height + 4}
                />
            </div>
        );
    };

    return (
        <div style={{ width: '98%' }}>
            <div><b>Название:</b> {data.name}</div>
            
            <div><b>Адрес:</b> {data.address_text}</div>

            {clickableIframe()}
            
            <Divider/>
        </div>
    );
};

export default BoardItem;
