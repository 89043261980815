import React from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
    AccordionSummary,
    AccordionDetails,
    Typography,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

import messages from 'helpers/constants/messages';
import ListInfo from './ListInfo';

const List = ({ data = [], companies = [] }) => {

    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

    // if (keys?.length === 0) {
    //     return (
    //         <div>
    //             {messages.DATA_IS_NOT_FOUND}
    //         </div>
    //     );
    // }

    return (
        <div style={{ padding: '8px 0' }}>
            {data?.map((item, index) => {
                // const company = companies.find(el => Number(el.id) === Number(companyId));

                return (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                        >
                            <Typography>
                                <strong>
                                    Наименование организации:
                                </strong>&nbsp;
                                {item?.organization || messages.INFO_IS_NOT_FOUND}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListInfo
                                data={item?.address_list || []}
                            />
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </div>
    );
};

export default List;
