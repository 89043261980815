import { config } from '../../config';

const apiDtpUrl = () => config.get('urls').apiDtpUrl;

const apiUrls = {
    getDtpFocus: () => `${apiDtpUrl()}/dtp-concentration-area`,

    getDtpFocusPolygon: () => `${apiDtpUrl()}/dtp-concentration-area/polygon`,

    createDtpFocus: () => `${apiDtpUrl()}/dtp-concentration-area`,

    editDtpFocus: (id) => `${apiDtpUrl()}/dtp-concentration-area/${id}`,

    deleteDtpFocus: (id) => `${apiDtpUrl()}/dtp-concentration-area/${id}`,

    getDtpFocusStatistic: () => `${apiDtpUrl()}/dtp-concentration-area/statistics`,

    loadHeatMapSaga: () => `${apiDtpUrl()}/dtp-concentration-area/heat-map`,
};

export default apiUrls;
