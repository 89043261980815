import { moduleName } from '../module-config';

const prefix = `${moduleName}`;

export const LOAD_STATION_STATUSES = `${prefix}/LOAD_STATION_STATUSES`;
export const LOADED_STATION_STATUSES = `${prefix}/LOADED_STATION_STATUSES`;

export const LOAD_STATION_LIST = `${prefix}/LOAD_STATION_LIST`;
export const LOADED_STATION_LIST = `${prefix}/LOADED_STATION_LIST`;
export const LOADING_STATION_LIST = `${prefix}/LOADING_STATION_LIST`;

export const LOAD_STATION_SIDEBAR = `${prefix}/LOAD_STATION_SIDEBAR`;
export const LOADED_STATION_SIDEBAR = `${prefix}/LOADED_STATION_SIDEBAR`;
export const LOADING_STATION_SIDEBAR = `${prefix}/LOADING_STATION_SIDEBAR`;
export const CLEAR_STATION_SIDEBAR = `${prefix}/CLEAR_STATION_SIDEBAR`;

export const SET_FILTERS = `${prefix}/SET_FILTERS`;
export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;

export const LOAD_VESTIBULE_POLYGON = `${prefix}/LOAD_VESTIBULE_POLYGON`;
export const LOADED_VESTIBULE_POLYGON = `${prefix}/LOADED_VESTIBULE_POLYGON`;
export const LOADED_VESTIBULE_POLYGON_LOADING = `${prefix}/LOADED_VESTIBULE_POLYGON_LOADING`;
