export const efficiency = (state) => state.efficiency;

// export const settings = (state) => efficiency(state).settings;
// export const loadingSettings = (state) => efficiency(state).loadingSettings;

export const oneGraph = (state) => efficiency(state).oneGraph;
export const loadingOneGraph = (state) => efficiency(state).loadingOneGraph;

export const groupGraph = (state) => efficiency(state).groupGraph;
export const loadingGroupGraph = (state) => efficiency(state).loadingGroupGraph;

export const dashboard = (state) => efficiency(state).dashboard;
export const dashboardLoading = (state) => efficiency(state).dashboardLoading;

export const efficiencyDtp = (state) => efficiency(state).efficiencyDtp;
export const loadingEfficiencyDtp = (state) => efficiency(state).loadingEfficiencyDtp;
export const filterEfficiencyDtp = (state) => efficiency(state).filterEfficiencyDtp;

export const loadingGraphList = (state) => efficiency(state).loadingGraphList;
export const graphList = (state) => efficiency(state).graphList;

export const loadingGraphPeriodList = (state) => efficiency(state).loadingGraphPeriodList;
export const graphPeriodList = (state) => efficiency(state).graphPeriodList;

export const efficiencyTraffic = (state) => efficiency(state).efficiencyTraffic;
export const loadingEfficiencyTraffic = (state) => efficiency(state).loadingEfficiencyTraffic;

// export const userAvailableDashboardList = (state) => efficiency(state).userAvailableDashboardList;
// export const loadingUserAvailableDashboardList = (state) => efficiency(state).loadingUserAvailableDashboardList;

export const userDashboard = (state) => efficiency(state).userDashboard;
export const loadingUserDashboard = (state) => efficiency(state).loadingUserDashboard;

export const trafficForecast = (state) => efficiency(state).trafficForecast;
export const loadingTrafficForecast = (state) => efficiency(state).loadingTrafficForecast;

export const criticalSettings = (state) => efficiency(state).criticalSettings;
export const criticalSettingsData = (state) => criticalSettings(state).data;
export const criticalSettingsMeta = (state) => criticalSettings(state).meta;
export const loadingCriticalSettings = (state) => efficiency(state).loadingCriticalSettings;
// export const criticalSettingsKeys = (state) => efficiency(state).criticalSettingsKeys;

export const loadingButton = (state) => efficiency(state).loadingButton;

export const reportEfficiencyDtpReport = (state) => efficiency(state).reportEfficiencyDtpReport;
export const loadingReportEfficiencyDtpReport = (state) => efficiency(state).loadingReportEfficiencyDtpReport;
export const filterReportEfficiencyDtpReport = (state) => efficiency(state).filterReportEfficiencyDtpReport;

export const showcase = (state) => efficiency(state).showcase;
export const loadingShowcase = (state) => efficiency(state).loadingShowcase;

export const itsDevelopmentLevels = (state) => efficiency(state).itsDevelopmentLevels;
export const itsDevelopmentLevelsData = (state) => itsDevelopmentLevels(state).data;
export const itsDevelopmentLevelsMeta = (state) => itsDevelopmentLevels(state).meta;
export const loadingItsDevelopmentLevels = (state) => efficiency(state).loadingItsDevelopmentLevels;

export const infrastructureStatistics = (state) => efficiency(state).infrastructureStatistics;
export const infrastructureStatisticsData = (state) => infrastructureStatistics(state).data;
export const infrastructureStatisticsMeta = (state) => infrastructureStatistics(state).meta;
export const loadingInfrastructureStatistics = (state) => efficiency(state).loadingInfrastructureStatistics;

export const dashboards = (state) => efficiency(state).dashboards;
export const dashboardsItem = (state) => efficiency(state).dashboardsItem;
export const loadingDashboards = (state) => efficiency(state).loadingDashboards;

export const dashboardItemUser = (state) => efficiency(state).dashboardItemUser;
export const loadingDashboardItemUser = (state) => efficiency(state).loadingDashboardItemUser;
