import L from 'leaflet';
import iconMap from './icons/iconMap';
// import config from './config';


export const changeCirclePercent = (percent, id) => {
    const perimetr = 2 * Math.PI * 48;
    const offset = perimetr - percent / 100 * perimetr;

    const current = document.getElementById(`circle_progress_${id}`);
    if (current) {
        if (percent === 0 || percent === 1)
            current.classList.add('no-transition');
        else
            current.classList.remove('no-transition');
        current.style.strokeDashoffset = offset;
    }
};

// цвет иконки от статуса
// export const getColorByStatus = (status) => {
//     return config.mapMarkersColors[status] || config.mapMarkersColors.default;
// };

const circleProgress = (id) => {
    const perimetr = 2 * Math.PI * 48;

    return `
        <svg width="100" height="100" class="tl-circleProgress">
            <circle
                id="circle_progress_${id}"
                r="48"
                cx="50"
                cy="50"
                fill="transparent"
                style="stroke-dasharray: ${perimetr} ${perimetr};stroke-dashoffset: ${perimetr};"
            />
        </svg>
    `;
};

// получить иконку маркера
export const createIconMarker = (color, id, isShowPhases) => {
    if (isShowPhases) {
        return L.divIcon({
            className: 'tl-marker',
            iconSize: [100, 100],
            iconAnchor: [50, 50],
            popupAnchor: [0, -50],
            html: `
                <i class="marker-circle"></i>
                ${circleProgress(id)}
            `
        });
    }

    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: iconMap({ fill: color /*getColorByStatus(status)*/ })
    });
};

// стрелка для направления
export const decoratorOptions = () => ({
    patterns: [
        {
            offset: '104%',
            repeat: 0,
            symbol: L.Symbol.arrowHead({
                pixelSize: 10,
                pathOptions: {
                    fillOpacity: 1,
                    weight: 0,
                    color: '#3B9A58'
                }
            })
        }
    ]
});

export const isShowPhases = (zoom) => {
    return zoom >= 16;
};
