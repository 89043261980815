import TransportFlow from '../../pages/Reports/Puid/TransportFlow';
import Indicators from '../../pages/Reports/Puid/Indicators';
import ByDevices from '../../pages/Reports/Puid/ByDevices';

const routes = [
    {
        path: '/reports/puid/transport-flow',
        component: TransportFlow,
        exact: true
    },
    {
        path: '/reports/puid/indicators',
        component: Indicators,
        exact: true
    },
    {
        path: '/reports/puid/by-devices',
        component: ByDevices,
        exact: true
    },
];

export default routes;