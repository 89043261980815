import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../redux/Reports/actions';
import Item from './Item';
import messages from '../../../../helpers/constants/messages';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import PageLayout from '../../../layout/PageLayout';
import { reportSelectors } from '../../../../redux/Reports';
import { authSelectors } from '../../../../redux/Auth';
import { LSContainer } from '../../../common/List';
import ModalForm from './ModalForm';
import Filter from './Filter';
import titles from '../../../../helpers/constants/titles';
import Context from '../../../../helpers/context';


const ScheduledReports = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const scheduledReport = useSelector(reportSelectors.scheduledReport);
    const loadingScheduledReport = useSelector(reportSelectors.loadingScheduledReport);
    const userMail = useSelector(authSelectors.authUserInfo)?.email || '';

    const [params, setParams] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
        filter: {}
    });

    const [addModal, setAddModal] = useState(false);

    const handleDelete = (id) => {
        dispatch(actions.deleteScheduledReport(id, () => {
            if (scheduledReport.length > 1 && params.page > 1) {
                fetchList();
            } else {
                setParams(old => ({
                    ...old,
                    page: params.page - 1
                }));
            }
        }));
    };

    const fetchList = useCallback(() => {
        dispatch(actions.loadScheduledReport({
            page: params.page,
            limit: params.limit,
            ...params.filter
        }));
    }, [dispatch, params]);

    useEffect(() => {
        fetchList();
    }, [fetchList]);

    const renderContent = () => {

        return scheduledReport?.data?.length
            ? (
                <LSContainer
                    headers={[
                        {
                            title: 'Отчет',
                            width: '20%'
                        },
                        {
                            title: titles.EMAIL,
                            width: '15%'
                        },
                        {
                            title: 'C какой даты',
                            width: '15%'
                        },
                        {
                            title: 'Действия',
                            align: 'right',
                            isActions: true
                        }
                    ]}
                >
                    {scheduledReport?.data?.map(item => (
                        <Item
                            key={item.id}
                            item={item}
                            onEdited={fetchList}
                            onDeleted={handleDelete}
                            userMail={userMail}
                            permissions={permissions}
                        />
                    ))}
                </LSContainer>
            )
            : !loadingScheduledReport && (
                <div className="flex-center" style={{ height: '100%' }}>
                    {messages.DATA_IS_NOT_FOUND}
                </div>
            );
    };
    
    return (
        <>
            <PageLayout
                header="Отчеты по расписанию"
                filters={<Filter setParams={filter => setParams(old => ({ ...old, page: 1, filter }))} />}
                loading={loadingScheduledReport}
                content={() => renderContent()}
                informPanelProps={{
                    buttons: (
                        <>
                            {permissions?.is_create && (
                                <FormButtons
                                    buttons={[
                                        {
                                            ...buttonsTypes.add,
                                            onClick: () => setAddModal(true),
                                            // disabled: !permissions?.is_create
                                        }
                                    ]}
                                    positionLeft
                                    noPadding
                                />
                            )}
                        </>
                    ),
                    total: scheduledReport?.meta?.total
                }}
                paginationProps={{
                    loadList: (page) => setParams(old => ({ ...old, page })),
                    list: scheduledReport?.meta,
                    limit: params.limit,
                    setLimit: (limit) => setParams(old => ({ ...old, limit })),
                }}
            />

            {addModal && (
                <ModalForm
                    isOpen={addModal}
                    onSuccess={() => fetchList()}
                    onClose={() => setAddModal(false)}
                />
            )}
        </>
    );
};

export default ScheduledReports;
