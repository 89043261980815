import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadRouteCheckpoints } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { getTimeWithoutSecond } from 'helpers/date.config';
import UniversalSelect from 'components/common/UniversalSelect';

import StationTimeModal from './StationTimeModal';

const AddStationModal = ({ template, selectedItems = [], isOpen, onClose, onSave }) => {
    const dispatch = useDispatch();
    const { route_id, station_stop_time } = template;
    const routeCheckpoints = useSelector(transportPassengerSelectors.routeCheckpoints);
    const checkPoints = routeCheckpoints[route_id] || [];

    const [items, setItems] = useState(selectedItems);
    const [openEditStation, setOpenEditStation] = useState(false);

    useEffect(() => {
        dispatch(loadRouteCheckpoints(route_id));
    }, [route_id, dispatch]);

    const saveItems = (data) => {
        // сортируем по номеру от меньшего к большему
        const sortedArray = [...data].sort((a, b) => a.number - b.number);
        // у первого элемента устанавливаем время начала на ноль
        sortedArray[0].time_start = '00:00';
        sortedArray[0].time_end = getTimeWithoutSecond(station_stop_time);
        setItems(sortedArray);
        setOpenEditStation(true);
    };

    const onStationsItemChanged = (newData) => {
        onSave(newData, () => {
            setOpenEditStation(false);
            onClose(false);
        });
    };

    return (
        <>
            {openEditStation 
                && <StationTimeModal
                    items={items}
                    isOpen={openEditStation}
                    onClose={() => setOpenEditStation(false)}
                    onSave={onStationsItemChanged}
                />
            }
            {isOpen 
                && <UniversalSelect
                    storeName={'transportPassenger'}
                    storeNameProps={'routeCheckpoints'}
                    renderProps={(item) => <span key={item}>{item.name || item.station?.name || item.check_point?.name}{item.direction}</span>}
                    keyProp={'check_point_id'}
                    onAccept={saveItems}
                    isSelected
                    selected={items}
                    sortedList={checkPoints}
                    multiple
                    isOpen={isOpen}
                    onClose={() => onClose(false)}
                    noPadding
                    title={'Выберите остановки или контрольные точки для маршрута'}
                />
            }
        </>
    );
};

export default AddStationModal;
