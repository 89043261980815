import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import { loadList } from 'redux/TrafficFlow/actions';
import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const SelectTrafficFlow = ({
    multiple = false,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label= 'ПУИД',
    error = false,
    helperText = '',
    filter = {},
    sortedList,
    typeId,
    selectAll,
    initialState = multiple ? [] : {},
    loadAction = null,
    limitTags = 1,
}) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);

    useEffect(() => {
        if (!isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const getList = (params) => {
        const { page, limit, query: name } = params;

        loadAction
            ? dispatch(loadAction(filter))
            : dispatch(loadList(page, limit, {
                ...(name && { name }),
                ...filter,
                ...(typeId && { type_id: typeId }),
            }));
    };

    const handleClose = () => setShowList(false);

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    const onReset = () => {
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete
                limitTags={limitTags}
                multiple={multiple}
                selected={selectedItems}
                disabled={disabled}
                renderLabel={(option) => option?.name || ''}
                onChange={onChange}
                onReset={onReset}
                onShowList={() => setShowList(true)}
                label={label}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={!sortedList && getList}
                    sortedList={sortedList}
                    storeName="trafficFlow"
                    storeNameProps="list"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div><strong>{titles.NAME}</strong>: {el.name}, <strong>{titles.ADDRESS}</strong>: {el.address_text}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={'Выберите ПУИД'}
                    small
                    selectAll={selectAll}
                    storeLoadingProps="listLoading"
                />
            }
        </>)
    ;
};

export default SelectTrafficFlow;
