import React, { memo, useMemo } from 'react';
import { GeoJsonNew } from '../../../../MapComponents/leaflet';
import { getTimeWithoutSeconds } from '../../../../../helpers/date.config';
import createPointGJ from '../../../../MapComponents/leaflet/helpers/createPointGJ';


const HistoryPoints = (props) => {
    const {
        data = [],
        ...params
    } = props;

    const geoJSON = useMemo(() => {

        return data.map(item => createPointGJ(item.lat, item.lon, { date: getTimeWithoutSeconds(item.received_at) }));

    }, [data]);

    return <GeoJsonNew
        {...params}
        data={geoJSON}
        toolTipTemplate={(data) => data?.date}
    />;
};

export default memo(HistoryPoints);