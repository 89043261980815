import { List, Typography } from '@mui/material';
import { Fragment } from 'react';
import Item from '../../../../../common/HistoryList/Item';
import messages from '../../../../../../helpers/constants/messages';
import { getDateToFormat } from '../../../../../../helpers/date.config';

const HistoryList = ({ history = {}, year }) => {

    const HistoryInfo = ({ item }) => (
        <>
            <h3>{item.name}:</h3>
            <div><i>Новое значение</i> - {item.new || messages.NO_DATA}</div>
            <div><i>Старое значение</i> - {item.old || messages.NO_DATA}</div>
        </>
    );

    return (
        <>
            <Typography variant="h5" align="center" m={2}>
                История изменений
            </Typography>
            {Object.entries(history).map(([monthNun, monthData]) => (
                Object.entries(monthData).map(([dayNun, { human: dayData }]) => (
                    <Fragment key={dayNun}>
                        <h2>{getDateToFormat(new Date(Date.UTC(year, monthNun - 1, dayNun)), 'd MMMM')}</h2>
                        <List className="list">
                            {dayData?.map((item) => (
                                item.event === 'updated'
                                    ? (
                                        <Item key={item.id} item={item}>
                                            <HistoryInfo item={item.data[0]}/>
                                        </Item>
                                    )
                                    : null
                            ))}
                        </List>
                    </Fragment>
                ))
            ))}
        </>
    );
};

export default HistoryList;
