import L from 'leaflet';
import React, { useEffect, useRef } from 'react';
import 'leaflet.marker.slideto';
import { useWsSubscribe } from '../../../../../helpers/ws/hooks';
import { isEqual } from 'lodash';
import 'leaflet.marker.slideto';
import { renderToString } from 'react-dom/server';
import { v4 as uuid_v4 } from 'uuid';
import icons from '../../../../MapComponents/Layers/TransportPassenger/icons/icons';
import compareEventWs from '../../../../../helpers/ws/helper/compareEventWs';
import { setWsTelemetry as setWsTelemetryTP } from '../../../../../redux/TransportPassenger/actions';
import { useDispatch } from 'react-redux';


export const getVehicleTypeOrId = (data = {}) => {
    return data?.vehicle?.category_id || data?.category_id || null;
};

const createIcon = (color, fill, category, in_registry) => {
    const backColor = color ? `background: ${color}` : '';

    return L.divIcon({
        html: `
                <div class="circle" style="${backColor}">
                    <div class="icon">
                        ${icons({ fill }, in_registry ? category : null)}
                    </div>
                </div>
            `,
        className: 'transport-passenger-marker-icon',
        iconSize: [30, 30]
    });
};

// todo этот компонент не используется (использовался тут - src\components\pages\Dictionaries\PassengerTransport\WorkordersControl\Events\EventItemContent.js)
// если будет использоваться - color и vehicle_color - бэк убрал из телеметрии
// маркер
const Ws = (props) => {
    const {
        uuid,
        map,
        parent,
        // icon,
        toolTipTemplate,
        toolTipOption = {},
        popUpTemplate,
    } = props;

    const dispatch = useDispatch();

    const wsItem = (event) => {
        const {
            external_id,
            source,
            color,
            vehicle_color,
            in_registry,
            lat,
            lon,
        } = event;

        if (`${uuid}` !== `${external_id}`) return;

        const vehicleIdOrType = getVehicleTypeOrId(event);
        const id = external_id;

        if (refKeys.current[id]) {
            const iconOption = { color, vehicle_color, in_registry, vehicleIdOrType };

            // меняем цвет иконки
            const currenIconOption = refKeysOptions.current[id];
            if (!isEqual(currenIconOption, iconOption)) {
                refKeys.current[id].setIcon(createIcon(color, vehicle_color, vehicleIdOrType, in_registry));
                refKeysOptions.current[id] = {
                    color,
                    vehicle_color,
                    in_registry,
                    vehicleIdOrType
                };
            }

            const item = refKeys.current[id];
            // latlonArr.current[id] = [lat,lon];

            item.slideTo([lat, lon], {
                duration: 5000,
                // keepAtCenter
            });

            const toolTip = refKeysTooltip.current[external_id] || null;
            if (toolTipTemplate && toolTip) {
                const tooltipContent = toolTipTemplate(event || {});
                const renderString = renderToString(tooltipContent);
                toolTip.setContent(renderString);
            }
        } else {
            const iconOption = {
                color,
                vehicle_color,
                in_registry,
                vehicleIdOrType
            };

            const newMarker = L
                .marker([lat, lon], {
                    icon: createIcon(color, vehicle_color, vehicleIdOrType, in_registry),
                });
            parent.addLayer(newMarker);
            refKeys.current[id] = newMarker;
            refKeysOptions.current[id] = iconOption;

            // tooltip
            if (toolTipTemplate) {
                const tooltipContent = toolTipTemplate(event || {});
                const renderString = renderToString(tooltipContent);
                const toolTip = L.tooltip({
                    ...toolTipOption,
                });
                toolTip.setContent(renderString);
                newMarker.bindTooltip(toolTip);

                refKeysTooltip.current[id] = toolTip;
            }

            // popup
            if (popUpTemplate) {
                const newId = uuid_v4();
                const popUp = L
                    .popup({
                        minWidth: 600,
                        closeOnClick: true,
                        className: 'custom-popup-wrapper',
                        offset: [0, -10],
                    })
                    .setContent(`<div class="popup-route" id="${newId}"></div>`);
                newMarker.bindPopup(popUp);
                //newMarker.on('click', handleShowPopup(newId, key, [lat,lon]));
                newMarker.on('click', handleShowPopup(newId, id, [lat, lon]));
            }
        }
    };

    // подключение к каналу
    useWsSubscribe('transport-passenger_telemetry_v2', (events) => {
        if (events?.length > 0) {
            // объединение
            compareEventWs(
                'transport-passenger_telemetry',
                (data) => dispatch(setWsTelemetryTP(data)),
                'external_id'
            ).list(events);

            events?.map((item) => wsItem(item));
        }
    });

    const refKeys = useRef({});
    const refKeysOptions = useRef({});
    const refKeysTooltip = useRef({});

    const handleShowPopup = (newId, key, latlon) => () => {
        // map.setView(latlonArr.current[key] || latlon);
        // setTimeout(() => {
        //     const currentId = document.getElementById(newId);
        //     if (currentId) {
        //         currentId && unmountComponentAtNode(currentId);
        //         render(
        //             <Provider store={store}>
        //                 {popUpTemplate(key)}
        //             </Provider>,
        //             currentId
        //         );
        //     }}, 300);
    };

    const handleDelete = (key) => {
        if (refKeys.current[key]) {
            const item = refKeys.current[key];
            item.off('click');
            parent.removeLayer(item);
            item.remove();
            delete refKeys.current[key];
            delete refKeysOptions.current[key];
            delete refKeysTooltip.current[key];
        }
    };

    useEffect(() => {
        return () => {
            Object.keys(refKeys.current).map((itemKey) => {
                handleDelete(itemKey);
            });
        };
    }, []);

    return null;
};

export default Ws;
