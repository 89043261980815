import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStoreProp, useValidation } from '../../../../../helpers/hooks';
import {
    loadRoutesStatuses,
    changeRouteStatus,
} from '../../../../../redux/TransportPassenger/actions';
import Modal from '../../../../common/Modal';
import titles from '../../../../../helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import styles from './routes.module.scss';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import FormInfoWrapper from '../../../../common/FormInfoWrapper';


const EditStatusModal = ({ data = {}, isOpen, onClose, reloadList }) => {
    const dispatch = useDispatch();

    const validation = useValidation();

    const [status, setStatus] = useState(data?.status);

    const loading = useSelector(transportPassengerSelectors.loading);
    const statuses = useStoreProp(loadRoutesStatuses, 'transportPassenger', 'statuses');
    const filterStatuses = statuses; //parseInt(data?.status) === 2 ? { 3: statuses['3'], 2: statuses['2'] } : statuses;

    const handleChange = (e) => {
        const { value } = e.target;
        setStatus(Number(value));

        validation.deleteKey('id');
        validation.deleteKey('status');
    };

    const checkDisabled = (option) => {
        return loading
            || data?.status === option
            || (data?.status === 1 && option === 5)
            || (data?.status === 2 && option !== 4)
            || (data?.status === 4 && (option !== 3 && option !== 2))
            || data?.status === 5;
    };

    const submit = () => {
        dispatch(changeRouteStatus(data?.id, status, () => { reloadList(); onClose(false); }));
    };

    const renderError = () =>
    `${validation.get('id') || ''} ${validation.get('status') || ''}`.trim();

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose(false)}
            noPadding
            title={titles.EDIT_STATUS}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: () => onClose(false)
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: !data?.id || data?.status === status,
                        loading,
                        onClick: submit,
                    },
                ]}
            />}
        >
            <form className="modal__form">
                <FormInfoWrapper
                    error={validation.isKey('id') || validation.isKey('status')}
                    helperText={renderError()}
                    className="row__item"
                >
                    <FormControl className="block" size="small" variant="outlined">
                        <InputLabel>{titles.STATUS}</InputLabel>
                        <Select
                            value={status}
                            required
                            onChange={handleChange}
                            label={titles.STATUS}
                            name="status"
                            error={validation.isKey('id')}
                        >
                            {Object.keys(filterStatuses)?.map((key) => (
                                <MenuItem
                                    key={key}
                                    value={key}
                                    disabled={checkDisabled(Number(key))}
                                >
                                    {filterStatuses[key]}
                                </MenuItem>
                            ))}
                        </Select>
                        {validation.isKey('id') && (
                            <FormHelperText className={styles.helperText} error>
                                {validation.get('id')}
                            </FormHelperText>
                        )}
                        {validation.isKey('status') && (
                            <FormHelperText className={styles.helperText} error>
                                {validation.get('status')}
                            </FormHelperText>
                        )}
                    </FormControl>
                </FormInfoWrapper>

            </form>
        </Modal>
    );
};

export default EditStatusModal;