import * as types from './types';

export const loadIncidents = (page, limit, params) => ({
    type: types.LOAD_INCIDENTS,
    payload: { page, limit, params }
});

export const loadingIncidents = (loading) => ({
    type: types.LOADING_INCIDENTS,
    payload: loading
});

export const loadedIncidents = (response) => ({
    type: types.LOADED_INCIDENTS,
    payload: response
});
