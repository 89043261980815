import React, { useMemo } from 'react';
import { Bar as GBar } from 'react-chartjs-2';
import {
    getHumanDate,
} from '../../../../../../../helpers/date.config';
import styles from './styles.module.scss';

// линейный график
const Graphic = ({
    data = [],
    legendPosition = 'bottom',
}) => {
    const dataG = useMemo(() => {
        const labels = data?.map(({ start_date, end_date }) => `${getHumanDate(start_date)} - ${getHumanDate(end_date)}`);

        const {
            units,
            colors
        } = data
            ?.[0]
            ?.data
            ?.reduce((res, { name, units, color }) => ({
                units: {
                    ...res?.units,
                    [name]: units,
                },
                colors: {
                    ...res?.colors,
                    [name]: color,
                },
            }), { colors: {}, units: {} });

        const datasets = data
            ?.reduce((res, { data }) => {
                const keysValue = data?.reduce((resI, { name, value }) => {
                    return {
                        ...resI,
                        [name]: value
                    };
                }, {});

                Object
                    .keys(keysValue)
                    .forEach((key) => res[key] = [...(res[key] || []), keysValue[key]]);

                return res;
            }, {});
       
        return {
            labels,
            datasets: Object
                .keys(datasets)
                .map((key) => ({
                    label: key,
                    backgroundColor: colors[key],
                    data: datasets[key],
                    unit: units[key],
                    barPercentage: 0.4,
                }))
        };
    }, [data]);

    const callbacks = {
        label: (context) => ` ${context?.dataset?.label}: ${context?.formattedValue} ${context?.dataset?.unit}`,
    };

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks
            },
            legend: {
                position: legendPosition,
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'шт'
                }
            },
        },
    };

    // {
    // start_date: "2022-01-01T00:00:00+03:00",
    // end_date: "2022-10-28T14:14:31+03:00",
    // data:[
    // {name: "ДТП всего", units: "шт", value: 1486, color: "#FFFF00"},
    // {name: "ДТП с раннеными", units: "шт", value: 1386, color: "#FF6600"},
    // {name: "ДТП с погибшими", units: "шт", value: 138, color: "#FF0000"},
    // {name: "Ранено", units: "чел.", value: 2202, color: "#9966FF"},
    // {name: "Погибло", units: "чел.", value: 171, color: "#FF00FF"},
    // }

    return (
        <div className={styles.card}>
            <h2 className={styles.header}>Сравнительные данные по периодам</h2>
            <div className={styles.data}>
                <GBar
                    options={options}
                    data={dataG}
                    style={{ maxHeight: '400px' }}
                />
            </div>
        </div>
    );

};

export default Graphic;
