import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function Row({ row }) {
    const classes = useRowStyles();

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell align="center">{row?.num}</TableCell>
                <TableCell align="center">{row?.name}</TableCell>
                <TableCell align="center" style={{ width: '110px' }}>{row?.start_date}</TableCell>
                <TableCell align="center" style={{ width: '110px' }}>{row?.end_date}</TableCell>
            </TableRow>
        </>
    );
}

function NoData() {

    return (
        <TableRow>
            <TableCell colSpan={4} align="center">Нет данных по маршрутам на выбранные даты</TableCell>
        </TableRow>
    );
}

const RoutesTable = ({ data = [] }) => {

    return (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Маршрут</TableCell>
                        <TableCell align="center">Наименование</TableCell>
                        <TableCell align="center">Дата начала</TableCell>
                        <TableCell align="center">Дата окончания</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.length > 0
                        ? data?.map((row,index) => (
                            <Row key={index} row={row} />))
                        : <NoData/>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RoutesTable;