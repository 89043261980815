import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';

import {
    getReportTransportIncidents,
    loadTransportIncidentStatuses,
} from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import { fullDateTimeWithTimeZone, getDateWithDuration, } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import DateRange from 'components/common/Dates/DateRange';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import GetReports from 'components/common/GetReports';
import SelectTransportIncidentsTypes from 'components/common/Autocomplete/DorisReact/TransportIncidentsTypes';
import SelectThreadLevel from 'components/common/Autocomplete/DorisReact/SelectThreadLevel';
import AddressList from 'components/common/Location/AddressList';

const Filter = ({ setParams }) => {
    const dispatch = useDispatch();

    const reportTransportIncidentsFilter = useSelector(SituationalPlansSelectors.reportTransportIncidentsFilter);

    const statuses = useStoreProp(loadTransportIncidentStatuses, 'SituationalPlans', 'transportIncidentStatuses');

    const [wasSearch, setWasSearch] = useState(false);
    const [selectedReportFormats, setSelectedReportFormats] = useState([]);

    const initialDate = {
        start_date: getDateWithDuration({ months: -1 }),
        end_date: new Date(),
    };

    const initState = {
        status: '',
        type_ti: '',
        threat_level: {},
        address: ''
    };

    const [data, setData] = useState(initState);
    const [selectedStartDate, handleStartDateChange] = useState(reportTransportIncidentsFilter.selectedStartDate || initialDate.start_date);
    const [selectedEndDate, handleEndDateChange] = useState(reportTransportIncidentsFilter.selectedEndDate || initialDate.end_date);

    const onChange = (key, value) => {
        setData({
            ...data,
            [key]: value
        });
    };

    const autoCompleteChange = (name) => (e, value) => {
        if (e?.key !== 'Enter') {
            setData({
                ...data,
                [name]: value
            });
        }
    };

    const resetFilter = () => {
        handleEndDateChange(initialDate.end_date);
        handleStartDateChange(initialDate.start_date);
        if (wasSearch) {
            const currentData = {
                ...initialDate,
                start_date: fullDateTimeWithTimeZone(selectedStartDate),
                end_date: fullDateTimeWithTimeZone(selectedEndDate)
            };

            setParams({ page: 1, data: currentData });
            setWasSearch(false);
        }
        setData(initState);
        setSelectedReportFormats([]);
    };

    const packageData = {
        start_date: fullDateTimeWithTimeZone(selectedStartDate),
        end_date: fullDateTimeWithTimeZone(selectedEndDate),
        type_ti: data?.type_ti?.id,
        threat_level: data?.threat_level?.id,
        status: data?.status?.id,
        address: data?.address
    };

    const handleFindReport = () => {
        const currentData = removeEmptyFields(packageData);
        setWasSearch(true);

        setParams({ page: 1, data: currentData });
    };

    const getReport = (formats) => {
        dispatch(getReportTransportIncidents(removeEmptyFields({
            report: 14,
            formats,
            ...packageData
        })));
    };

    return (
        <div className="filter">
            <div className="filter filter__wrap filter__wrap__more__field">
                <DateRange
                    handleDate={handleStartDateChange}
                    handleEndDate={handleEndDateChange}
                    valueStartDate={selectedStartDate}
                    valueEndDate={selectedEndDate}
                />
            </div>
            <div className="filter filter__wrap filter__wrap__more__field">
                <SelectTransportIncidentsTypes
                    selected={data.type_ti}
                    onChange={(value) => onChange('type_ti', value)}
                    label="Тип"
                />
                <SelectThreadLevel
                    selected={data.threat_level}
                    onChange={(value) => onChange('threat_level', value)}
                />
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={data.status || null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={statuses}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={autoCompleteChange('status')}
                    size="small"
                    openText={'Открыть'}
                    closeText={'Закрыть'}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.STATUS}
                        />
                    )}
                />
                <div>
                    <AddressList
                        address={data?.address}
                        address_text={data?.address}
                        onChange={(value) => onChange('address', value?.address_text)}
                    />
                </div>
            </div>
            <div className="filter__wrap__btn filter__btn__column">
                <GetReports
                    onDownload={getReport}
                    selected={selectedReportFormats}
                    onChange={setSelectedReportFormats}
                    reportId={14}                    
                />
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilter
                        },
                        {
                            ...buttonsTypes.find,
                            onClick: handleFindReport,
                        }
                    ]}
                />
            </div>
        </div>
    );

};

export default Filter;