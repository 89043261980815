import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import titles from '../../../../../../../helpers/constants/titles';
import DragAndDrop from '../../../../../../common/DragAndDrop';
import ConfirmDelete from '../../../../../../common/ConfirmModal';
import RenderListItem from './RenderListItem';

export const useStyles = makeStyles({
    item_text: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    item: {
        textAlign: 'left',
        marginLeft: '1rem',
        flex: 1,
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 13,
        padding: '0 8px'
    }
});

const RenderList = ({ 
    items, 
    setItems,
    draggable = false,
    canRemove = false,
}) => {
    const styles = useStyles();
    const [deletedItem, setDeletedItem] = useState(null);

    const listHeader = (
        <ListItem className={styles.item_text} divider>
            {draggable && <ListItemIcon style={{ minWidth: 38 }}/>}
            <ListItemText disableTypography className={styles.row}>
                <span style={{ width: 16 }}>{titles.NUM}</span>
                <span className={styles.item}>Название</span>
                <span className={styles.item}>Состояние</span>
                <span className={styles.item}>Адрес</span>
            </ListItemText>
            {canRemove && <ListItemIcon />}
        </ListItem>
    );

    const itemsRender = (item, index) => {
        return (
            <RenderListItem
                onDeleteItem={() => setDeletedItem(item)}
                item={item}
                index={index}
                draggable={draggable}
                canRemove={canRemove}
            />
        );
    };

    return (
        <>
            {draggable
                ? <DragAndDrop
                    list={items}
                    listHeader={listHeader}
                    itemsRender={itemsRender}
                    onChange={(items) => setItems(items)}
                />
                : (
                    <List>
                        {listHeader}
                        {items.map((item, index) =>
                            <RenderListItem
                                key={item.id}
                                item={item}
                                index={index}
                            />
                        )}
                    </List>
                )
            }
            {!!deletedItem && (
                <ConfirmDelete
                    open={!!deletedItem}
                    onSuccess={() => {
                        setItems(items.filter(item => item.id !== deletedItem.id));
                        setDeletedItem(null);
                    }}
                    onClose={() => setDeletedItem(null)}
                />
            )}
        </>
    );
};

export default RenderList;
