import HeaderPanel from 'components/common/Showcase/HeaderPanel';
import { HeaderPageVisible } from 'components/common/Showcase/HeaderPage';

import Tabs from './Tabs';
import tabList from './TabComponents';

function DTPReportsEfficient() {
    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <HeaderPageVisible/>

            <HeaderPanel title="Показатели ДТП" />

            <Tabs list={tabList}/>
        </div>
    );
}

export default DTPReportsEfficient;
