import React, { useEffect } from 'react';
import {
    Grid,
    LinearProgress,
} from '@mui/material';
import Layer from '../../../MapComponents/Layers/TrafficLights/layer';
import layerConfig from '../../../MapComponents/Layers/TrafficLights/config';
import CardWidget from '../../../common/Showcase/CardWidget';
import {
    loadShowcase,
    clearShowcase,
} from '../../../../redux/TrafficLights/actions';
import { trafficLightsSelectors } from '../../../../redux/TrafficLights';
import { useDispatch, useSelector } from 'react-redux';
import TypeComponent from '../../../common/Showcase/TypeComponent';
import CardMap from '../../../common/Showcase/CardMap';

// страница
const Page = () => {
    const dispatch = useDispatch();

    const showCase = useSelector(trafficLightsSelectors.showcase);
    const loadingShowcase = useSelector(trafficLightsSelectors.loadingShowcase);
    const {
        graph = [],
        table = [],
    } = showCase;
    const list = [...(Array.isArray(table) ? table : [table]), ...(Array.isArray(graph) ? graph : [graph])];
    const isList = list?.length > 0;

    useEffect(() => {
        dispatch(loadShowcase());
        return () => dispatch(clearShowcase());
    } ,[]);

    return (
        <Grid container spacing={2} sx={{ height: '100%' }}>
            {isList && (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className="showcase_grid_flex-column"
                >
                    {loadingShowcase && <LinearProgress/>}

                    {list.map((item, index) => (
                        <CardWidget
                            key={index}
                            icon={layerConfig.layerIcon}
                            title={item.name}
                            marginBottom={index < list.length - 1}
                            fillOthers
                            small
                        >
                            <TypeComponent
                                {...item}
                            />
                        </CardWidget>
                    ))}
                </Grid>
            )}
            <Grid
                item
                xs={12} {...isList ? { sm: 6, md: 8 } : {}}
                className="showcase_grid_flex-column"
            >
                <CardMap
                    fillOthers
                    isCluster
                >
                    <Layer readOnly />
                </CardMap>
            </Grid>
        </Grid>
    );
};

export default Page;