import React from 'react';
import {
    List,
    Typography,
    Collapse,
} from '@mui/material';
import messages from '../../../../../../../helpers/constants/messages';
import OrderStageVehiclesItem from './OrderStageVehiclesItem';

const OrderStageVehicles = ({ item, isOpen }) => {

    return (
        <>
            <Collapse in={isOpen} timeout={'auto'} unmountOnExit>
                {item?.vehicles?.length > 0
                    ? <List>
                        {item?.vehicles?.map(vehicle =>
                            <OrderStageVehiclesItem
                                key={vehicle.number}
                                vehicle={vehicle}
                            />
                        )}
                    </List>
                    : <Typography variant="body2">{messages.INFO_IS_NOT_FOUND}</Typography>}
            </Collapse>
        </>
    );
};

export default OrderStageVehicles;