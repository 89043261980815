import React, { useState, useEffect, useMemo } from 'react';
import {
    FormControl, IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Button,
} from '@mui/material';
import {
    getRangeYears,
    getRangeMonths,
} from '../helper';
import buttons from '../../../../../helpers/constants/buttons';
import ClearIcon from '@mui/icons-material/Clear';
import { 
    fullDateTimeWithTimeZone,
    getEndOf,
    getStartOf,
    getTimeUnitValue,
    setDateTime,
} from '../../../../../helpers/date.config';

const Filter = ({
    onChange,
    onChangeInline,
}) => {
    const [resetButton, setResetButton] = useState(true);
    const [isChanged, setIsChanged] = useState(false);

    const currentYear = getTimeUnitValue('year');
    const currentMonth = getTimeUnitValue('month');

    const initialState = {
        year: currentYear,
        month: currentMonth,
    };
    const [formValues, setFormValues] = useState(initialState);

    const getDates = (form) => {
        const {
            year,
            month,
        } = form || formValues;
        const start_date = getStartOf('month', setDateTime('', { 
            year,
            // выбранный месяц или январь, т.к если месяца нет, то получаем за год
            month: month || 1,
        }));
        const end_date = (month === currentMonth && year === currentYear)
            ? getEndOf('day') // если выбран текущий год и текущий месяц - конечная дата == Сегодня
            : getEndOf('month', setDateTime('', { 
                year,
                // выбранный месяц или декабрь, т.к если месяца нет, то получаем за год
                month: (year === currentYear && !month)
                    ? currentMonth // если текущий год но месяц не выбран
                    : month || 12,
            }));
           
        // формирование дат для запроса
        const format = () => ({
            start_date: fullDateTimeWithTimeZone(start_date),
            end_date: fullDateTimeWithTimeZone(end_date),
        });

        return {
            start_date,
            end_date,
            format,
        };
    };

    useEffect(() => {
        onChange(getDates().format());
        onChangeInline && onChangeInline(initialState);
    }, []);

    const handleChangeSelects = (e) => {
        const { value, name } = e.target;
        setIsChanged(true);
        setFormValues({
            ...formValues,
            [name]: Number(value),
            ...(name === 'year'
                ? { month: '', } 
                : {}
            ),
        });
    };

    const resetFilters = () => {
        setFormValues(initialState);
        setResetButton(true);
        setIsChanged(false);
        onChange(getDates(initialState).format());
        onChangeInline && onChangeInline(initialState);
    };

    const disableRequestBtn = () => {
        const { year, month } = formValues;
        return !year || !isChanged;
    };

    // отправить
    const send = () => {
        setIsChanged(false);
        setResetButton(false);

        onChange(getDates().format());
        onChangeInline && onChangeInline(formValues);
    };

    const years = getRangeYears();

    const monthArray = useMemo(() => getRangeMonths(formValues.year), [formValues]);

    return (
        <div className="filter">
            <div style={{ display: 'flex' }}>

                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    className="row__item"
                >
                    <InputLabel shrink id="create-label" style={{ backgroundColor: '#fff' }}>Год</InputLabel>
                    <Select
                        displayEmpty
                        fullWidth
                        labelId="create-label"
                        value={formValues?.year || ''}
                        name="year"
                        onChange={handleChangeSelects}
                        label="Год"
                        variant="outlined"
                    >
                        {/* список годов (с 2021) */}
                        {years?.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    className="row__item"
                >
                    <InputLabel shrink id="create-label" style={{ backgroundColor: '#fff' }}>Месяц</InputLabel>
                    <Select
                        displayEmpty
                        fullWidth
                        labelId="create-label"
                        value={formValues?.month || ''}
                        name="month"
                        onChange={handleChangeSelects}
                        label="Месяц"
                        variant="outlined"
                    >
                        <MenuItem value="">
                            Месяц
                        </MenuItem>
                        {Object.entries(monthArray)?.map(([key, month]) => (
                            <MenuItem key={key} value={key}>
                                {month}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {!resetButton && (
                    <Tooltip title={buttons.RESET_FILTER}>
                        <IconButton
                            onClick={resetFilters}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                )}

                {disableRequestBtn() 
                    ? <Button
                        onClick={send}
                        disabled={disableRequestBtn()}
                        variant="outlined"
                    >
                        OK
                    </Button>
                    : <Tooltip title={buttons.FIND}>
                        <Button
                            onClick={send}
                            disabled={disableRequestBtn()}
                            variant="outlined"
                        >
                            OK
                        </Button>
                    </Tooltip>
                }
            </div>
        </div>
    );
};

export default Filter;