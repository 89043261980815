import L from 'leaflet';
import defaultIcon from './defaultIcon';


const pointToLayer = (layerProps) => (feature, latlng) => {
    const icon = layerProps.icon || null;
    const radius = feature?.properties?.radius || false;

    return radius
        ? new L.Circle(latlng, {
            radius,
            attribution: feature?.properties?.attribution
        }) // окружность
        : L.marker( // маркер
            latlng, {
                icon: icon ? icon(feature?.properties?.data) : defaultIcon,
                attribution: feature?.properties?.attribution
            },
        );
};

export default pointToLayer;
