import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormHelperText, ListItemText, TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import common from 'helpers/constants/buttons/common';
import { useValidation } from 'helpers/hooks';
import { meteoSelectors } from 'redux/Meteo';
import { ecologySelectors } from 'redux/Ecology';
import UniversalSelect from 'components/common/UniversalSelect';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import DragAndDrop from 'components/common/DragAndDrop';

import DevicesAutocomplete from './DevicesAutocomplete';
import type { ModalFormProps, Param } from '../types';

const ModalForm = ({
    isOpen,
    onClose,
    item,
    isNew,
    reloadList,
    actions,
    storeName,
    loadParams,
}: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingButton: boolean = useSelector(
        storeName === 'meteo'
            ? meteoSelectors.loadingButton
            : ecologySelectors.loadingButton
    );

    const initialState = {
        name: item?.name || '',
        params: item?.params || [],
        device_ids: item?.devices || [],
    };

    const [formData, setFormData] = useState(initialState);
    const [isOpenParamsModal, setIsOpenParamsModal] = useState(false);

    const handleChange = (name: string, value: any) => {
        setFormData((prev) => ({ ...prev, [name]: value }));
        validation.deleteKey(name);
    };

    const handleReorder = (value: Object[]) => {
        const reorder = value.map((el: any, i: number) => ({
            ...el,
            display_order: i + 1,
        }));

        setFormData({ ...formData, params: reorder });
    };

    const handleSave = () => {
        const prepareData = {
            ...formData,
            params: formData.params.map((param) => ({
                param_id: param.id,
                display_order: param.display_order,
            })),
            device_ids: formData.device_ids.map((device) => device.id),
        };

        const callback = () => {
            onClose();
            reloadList();
        };

        isNew
            ? dispatch(actions.add(prepareData, callback))
            : dispatch(actions.edit({ id: item?.id, params: prepareData }, callback));
    };

    const handleSaveParams = (value: Param[]) => {
        const prepareData = value.map((param, i) => ({ ...param, display_order: i + 1 }));
        handleChange('params', prepareData);
        setIsOpenParamsModal(false);
    };

    const getList = (params: any) => {
        const { page, limit, query: name } = params;

        dispatch(loadParams(
            page,
            limit,
            (name && { name }),
        ));
    };

    const isDisabled = formData.name.trim().length === 0
        || formData.params.length === 0;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose,
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSave,
                            loading: loadingButton,
                            disabled: isDisabled,
                        },
                    ]}
                />
            }
        >
            <div className="modal__form">
                <TextField
                    label="Наименование"
                    className="block"
                    size="small"
                    value={formData.name}
                    disabled={item?.is_default}
                    onChange={(e) => handleChange('name', e.target.value)}
                    required
                    error={validation.isKey('name')}
                    helperText={validation.isKey('name') && validation.get('name')}
                />

                <div className="block">
                    <DragAndDrop
                        listHeader={<h2>Порядок вывода параметров*</h2>}
                        list={formData.params}
                        onChange={handleReorder}
                        itemsRender={(elem, index) => (
                            <ListItemText>
                                <span style={{ fontWeight: 500 }}>{index + 1}. </span>
                                <span> {elem.name}</span>
                            </ListItemText>
                        )}
                    />

                    {(isNew || !item?.is_default) && (
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes[formData.params.length === 0 ? 'add' : 'edit'],
                                    color: 'infoButton',
                                    onClick: () => setIsOpenParamsModal(true),
                                }
                            ]}
                        />
                    )}

                    {validation.isKey('params') && (
                        <FormHelperText error>{validation.get('params')}</FormHelperText>
                    )}
                </div>

                <DevicesAutocomplete
                    value={formData.device_ids}
                    onChange={(value: any) => handleChange('device_ids', value)}
                    actions={actions}
                    storeName={storeName}
                    required={false}
                />

                {isOpenParamsModal && (
                    <UniversalSelect
                        multiple
                        fetchList={getList}
                        storeName={storeName}
                        storeNameProps={`${storeName}OutputParams`}
                        keyProp="id"
                        withSearch
                        isSelected
                        selected={formData.params}
                        renderProps={(el) => `${el.name}`}
                        isOpen={isOpenParamsModal}
                        onClose={() => setIsOpenParamsModal(false)}
                        onAccept={handleSaveParams}
                        noPadding
                        title="Выбрать параметры"
                    />
                )}
            </div>
        </Modal>
    );
};

export default ModalForm;
