import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
    loadMaterialExport,
    loadMaterialExportStatuses,
    loadMaterialExportTypes
} from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';

import Filter from './Filter';
import ModalForm from './ModalForm';
import Item from './Item';

import type { ItemType } from './types';

const ReExportMaterials = () => {
    const dispatch = useDispatch();

    const data = useSelector(dorisControlSelectors.materialExportData);
    const meta = useSelector(dorisControlSelectors.materialExportMeta);
    const loading = useSelector(dorisControlSelectors.loadingMaterialExport);
    const types = useStoreProp(loadMaterialExportTypes, 'dorisControl', 'materialExportTypes');
    const status = useStoreProp(loadMaterialExportStatuses, 'dorisControl', 'materialExportStatuses');

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, query: {} });
    const [openAddModal, setOpenAddModal] = useState(false);

    const loadList = useCallback((shouldGoToPreviousPage = false) => {
        if (shouldGoToPreviousPage) {
            setParams((prev) => ({ ...prev, page: prev.page - 1 }));
        } else {
            dispatch(loadMaterialExport(params.page, limit, params.query));
        }
    }, [dispatch, params, limit]);

    useEffect(() => {
        loadList();
    }, [loadList]);
    
    const renderContent = () => data?.length > 0
        ? <LSContainer
            headers={[
                { title: '', width: '50px' },
                { title: 'Назначение', width: '16%' },
                { title: 'Тип экспорта', width: '12%' },
                { title: titles.START_PERIOD, width: '10%' },
                { title: titles.END_PERIOD, width: '10%' },
                { title: titles.USER, width: '14%' },
                { title: 'Дата регистрации', width: '11%' },
                { title: 'Процент выполнения', width: '13%' },
                { title: titles.ACTIONS, align: 'right', isActions: true }
            ]}
        >
            {data.map((item: ItemType) => (
                <Item
                    key={item.id}
                    item={item}
                    loadList={(isDelete?: boolean) => loadList(isDelete && data.length === 1)}
                    types={types}
                />
            ))}
        </LSContainer>
        : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>);

    return (
        <>
            <PageLayout
                header="Повторный экспорт материалов"
                filters={<Filter setParams={setParams} status={status} />}
                informPanelProps={{                
                    buttons: (
                        <FormButtonsComponent
                            buttons={[{
                                ...buttonsTypes.add,
                                onClick: () => setOpenAddModal(true),
                            }]
                            }
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams((prev) => ({ ...prev, page })),
                    list: meta,
                    limit,
                    setLimit
                }}
            />
            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    loadList={loadList}
                    isNew
                />
            )}
        </>
    );
};

export default ReExportMaterials;
