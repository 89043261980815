import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidation } from '../../../../helpers/hooks';
import titles from '../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../FormButtons';
import Modal from '../../Modal';
import formatDate from '../../../../helpers/constants/dateFormat';
import SingleKeyboardDateTimePicker from '../../Dates/SingleKeyboardDateTimePicker';
import {
    TextField,
    FormControl
} from '@mui/material';
import SelectCompany from '../../Autocomplete/Companies';
import TextMaskPhone from '../../TextMaskPhone';
import SelectPosition from '../../Autocomplete/Transport/Position';
import { fullDateTimeWithTimeZone } from '../../../../helpers/date.config';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import Loading from '../../Loading';

function ModalAddEdit({ 
    item, 
    isOpen, 
    isNew,  
    onClose, 
    reloadList,
    loading,
    actions,
    storeName,
}) {
    const dispatch = useDispatch();

    const validation = useValidation();

    const [formValues, setFormValues] = useState( {
        first_name: item?.first_name || '',
        patronymic: item?.patronymic || '',
        last_name: item?.last_name || '',
        identification: item?.identification || '',
        organization: item?.organization || {},
        position: item?.position || {},
        // organization_id: item?.organization_id,
        // position_id: item?.position_id,
        personnel_number: item?.personnel_number || '',
        phone_number: item?.phone_number,
        label: item?.label || '',
        inspection_before: item?.inspection_before || null,
        inspection_after: item?.inspection_after || null,
    });

    const handleChange = (name, value) => {
        setFormValues({
            ...formValues, 
            [name]: value
        });
        const validationKey = name === 'organization'
            ? 'organization_id'
            : name === 'position'
                ? 'position_id'
                : name;
        validation.deleteKey(validationKey);
    };

    const onAccept = () => {
        const result = {
            ...formValues,
            organization_id: formValues.organization?.id,
            position_id: formValues.position?.id,
            inspection_before: fullDateTimeWithTimeZone(formValues.inspection_before),
            inspection_after: fullDateTimeWithTimeZone(formValues.inspection_after),
        };
        const paramsWithoutEmpty = removeEmptyFields(result);

        isNew
            ? dispatch(actions.addEmployees(paramsWithoutEmpty, () => {reloadList(); onClose();}))
            : dispatch(actions.editEmployees(item?.id, paramsWithoutEmpty, () => {reloadList(); onClose();}));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            title={isNew ? `${titles.ADD} сотрудника` : `${titles.EDIT} сотрудника`}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAccept,
                        disabled: validation.isValidation()
                    }
                ]}
            />}
        >
            {loading && <Loading circular />}
            <form className="modal__form">
                <div className="row">
                    <FormControl
                        size="small"
                        variant="outlined"
                        className="row__item"
                    >
                        <TextField
                            size="small"
                            required
                            value={formValues.first_name}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            variant="outlined"
                            label={titles.FIRST_NAME}
                            name="first_name"
                            error={validation.isKey('first_name')}
                            helperText={validation.get('first_name')}
                        />
                    </FormControl>
                    <FormControl
                        size="small"
                        variant="outlined"
                        className="row__item"
                    >
                        <TextField
                            size="small"
                            required
                            className="row__item"
                            value={formValues.last_name}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            variant="outlined"
                            label={titles.LAST_NAME}
                            name="last_name"
                            error={validation.isKey('last_name')}
                            helperText={validation.get('last_name')}
                        />
                    </FormControl>
                </div>
                <div className="row">
                    <TextField
                        required
                        className="row__item"
                        value={formValues.patronymic}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        variant="outlined"
                        size="small"
                        label={titles.PATRONYMIC}
                        name="patronymic"
                        error={validation.isKey('patronymic')}
                        helperText={validation.get('patronymic')}
                    />
                    <TextField
                        className="row__item"
                        value={formValues.identification}
                        required
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        variant="outlined"
                        size="small"
                        label={titles.IDENTIFICATION}
                        name="identification"
                        error={validation.isKey('identification')}
                        helperText={validation.get('identification')}
                    />
                </div>
                <div className="block">
                    <SelectCompany
                        selected={formValues.organization}
                        onChange={(value) => handleChange('organization', value)}
                        helperText={validation.get('organization_id')}
                        error={validation.isKey('organization_id')}
                        required
                        filter={{ withDeleted: 1 }}
                    />
                </div>
                <div className="row">
                    <TextField
                        required
                        className="row__item"
                        value={formValues.personnel_number}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        variant="outlined"
                        size="small"
                        label={titles.PERSONNEL_NUMBER}
                        name="personnel_number"
                        error={validation.isKey('personnel_number')}
                        helperText={validation.get('personnel_number')}
                    />
                    <TextField
                        required
                        className="row__item"
                        value={formValues.label}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        variant="outlined"
                        size="small"
                        label={titles.LABEL}
                        name="label"
                        error={validation.isKey('label')}
                        helperText={validation.get('label')}
                    />
                </div>
                <div className="block">
                    <FormControl>
                        <TextField
                            required
                            error={validation.isKey('phone_number')}
                            helperText={validation.get('phone_number')}
                            label={titles.PHONE_NUMBER}
                            value={formValues.phone_number || ''}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            variant="outlined"
                            size="small"
                            name="phone_number"
                            InputProps={{
                                inputComponent: TextMaskPhone,
                            }}
                        />
                    </FormControl>
                </div>
                <div className="block">
                    <SelectPosition 
                        onChange={(value) => handleChange('position', value)}
                        selected={formValues.position}
                        required
                        error={validation.isKey('position_id')}
                        helperText={validation.get('position_id')}
                        loadList={actions.loadPositions}
                        storeName={storeName}
                    />
                </div>
                <div className="block">
                    <h3>Даты проведения предрейсового и послерейсового медицинского осмотра сотрудника</h3>
                </div>
                <div className="row">
                    <SingleKeyboardDateTimePicker
                        onChange={(value) => handleChange('inspection_before', value)}
                        value={formValues.inspection_before}
                        label="Предрейсовый"
                        pickerFormat={formatDate.DT_EXCEPT_SECONDS}
                        error={validation.isKey('inspection_before')}
                        helperText={validation.get('inspection_before')}
                        className="row__item"
                    />
                    <SingleKeyboardDateTimePicker
                        onChange={(value) => handleChange('inspection_after', value)}
                        value={formValues.inspection_after}
                        label="Послерейсовый"
                        pickerFormat={formatDate.DT_EXCEPT_SECONDS}
                        error={validation.isKey('inspection_after')}
                        helperText={validation.get('inspection_after')}
                        className="row__item"
                    />
                </div>
            </form>
        </Modal>
    );
}

export default ModalAddEdit;
