import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import cn from 'classnames';

import { setFilterList } from 'redux/TrafficFlow/actions';
import titles from 'helpers/constants/titles';
import FormButtons from 'components/common/FormButtons';
import { buttonsTypes } from 'components/common/FormButtons';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';


const Filter = ({ statuses, types }) => {
    const dispatch = useDispatch();
    const [clicked, setClicked] = useState(false);

    const initialState = {};

    const [data, setData] = useState(initialState);

    const onChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleFilteredList = () => {
        setClicked(true);
        dispatch(setFilterList({
            ...data
        }));
    };

    const onChangeUuid = (name, newValue) => {
        setData({ ...data, [name]: newValue });
    };

    const handleClickEnter = (e) => {
        if (e?.key === 'Enter') handleFilteredList();
    };

    return(
        <div className="filter">
            <div className={cn('filter__wrap', 'filter__wrap__more__field')}>
                <FormControl required size="small" variant="outlined">
                    <MultipleInput
                        value={data?.uuid}
                        onChange={(e) => onChangeUuid('uuid', e) }
                        label="UUID"
                        guideText="Добавление одного или нескольких элементов, разделяя запятой"
                    />
                </FormControl>

                <FormControl required size="small" variant="outlined">
                    <TextField
                        required
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={data.name || ''}
                        name={'name'}
                        onChange={onChange}
                        type="text"
                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                        onKeyPress={handleClickEnter}
                    />
                </FormControl>

                <FormControl size="small" variant="outlined">
                    <InputLabel>{titles.STATUS}</InputLabel>
                    <Select
                        value={data.status || ''}
                        onChange={(e) => setData({ ...data, status: e.target.value })}
                        label={titles.STATUS}
                    >
                        {statuses?.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>{name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl size="small" variant="outlined">
                    <InputLabel>{titles.TYPE}</InputLabel>
                    <Select
                        value={data?.type_id || ''}
                        onChange={(e) => setData({ ...data, type_id: e.target.value })}
                        label={titles.TYPE}
                    >
                        {Object.keys(types).map((index) => (
                            <MenuItem key={index} value={index}>{types[index]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: () => {
                                setData(initialState);
                                if (clicked) {
                                    dispatch(setFilterList({}));
                                    setClicked(false);
                                }
                            },
                            disabled: data.name === '' && data.status === '' && data.state === ''
                        },
                        {
                            ...buttonsTypes.find,
                            onClick: handleFilteredList
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;
