import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiTrafficLightsMethods = {
    //Получение списка светофоров
    getTrafficLightsList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getTrafficLightsList(),{
            params: { page, limit, ...query }
        });
        return response?.data;
    },
    //Получение статусов светофора
    getTrafficLightsStatuses: async () => {
        const response = await instance.get(apiUrls.getTrafficLightsStatuses());
        return response?.data;
    },
    //Добавление светофора
    postTrafficLight: async (data) => {
        const response = await instance.post(apiUrls.postTrafficLight(), data);
        return response?.data;
    },
    //Редактирование светофора
    editTrafficLight: async (id, data) => {
        const response = await instance.put(apiUrls.editTrafficLight(id), data);
        return response?.data;
    },
    //Удаление светофора
    deleteTrafficLights: async (id) => {
        const response = await instance.delete(apiUrls.deleteTrafficLights(id));
        return response?.data;
    },
    //Получение состояний светофора
    getTrafficLightStates: async () => {
        const response = await instance.get(apiUrls.getTrafficLightStates());
        return response?.data;
    },
    //Получение типов контроля светофора
    getTrafficLightControlTypes: async () => {
        const response = await instance.get(apiUrls.getTrafficLightControlTypes());
        return response?.data;
    },
    // получить полигон
    getForPolygon: async (params) => {
        const response = await instance.post(
            apiUrls.getPolygon(),
            params,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
        return response?.data?.data;
    },
    // получить список для сайдбара
    getSidebarList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getSidebarList(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    getCoordinationGroups: async (params) => {
        const response = await instance.get(apiUrls.getCoordinationGroups(), {
            params
        });
        return response?.data;
    },

    getCoordinationGroupsPolygon: async (params) => {
        const response = await instance.post(apiUrls.getCoordinationGroupsPolygon(), {
            ...params
        });
        return response?.data;
    },

    getGreenStreets: async (params) => {
        const response = await instance.get(apiUrls.getGreenStreets(), {
            params
        });
        return response?.data;
    },

    getGreenStreetsPolygon: async (params) => {
        const response = await instance.post(apiUrls.getGreenStreetsPolygon(), {
            ...params
        });
        return response?.data;
    },

    getColorsForLegends: async () => {
        const response = await instance.get(apiUrls.getColorsForLegends());
        return response?.data;
    },

    loadShowcase: async () => {
        const response = await instance.post(apiUrls.loadShowcase());
        return response?.data;
    },

    loadTrafficLightByRadius: async (params) => {
        const response = await instance.post(apiUrls.loadTrafficLightByRadius(), params);
        return response?.data;
    },

    editSettingsList: async (data) => {
        const response = await instance.put(apiUrls.editSettingsList(), data);
        return response?.data;
    },

    getSettingsGroup: async () => {
        const response = await instance.get(apiUrls.getSettingsGroup());
        return response?.data;
    },

    editSettingsGroup: async (id, data) => {
        const response = await instance.put(apiUrls.editSettingsGroup(id), data);
        return response?.data;
    },
};

export default ApiTrafficLightsMethods;
