export const pdf = {
    type: 'application/pdf',
    name: 'PDF'
};
export const doc = {
    type: 'application/msword',
    name: 'WORD',
    format: 'doc'
};
export const docx = {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    name: 'DOCX'
};
export const xls = {
    type: 'application/vnd.ms-excel',
    name: 'XLS',
    title: 'XLS',
};
export const xlsx = {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    name: 'xlsx'
};
export const xlsxGraph = {
    ...xlsx,
    name: 'XLS_GRAPH',
    title: 'XLS графики',
    format: 'xlsx'
};
export const txt = {
    type: 'text/plain',
    name: 'TXT'
};
export const csv = {
    type: 'text/csv',
    name: 'CSV',
    title: 'CSV',
};
export const json= {
    name: 'JSON',
    type: 'application/json'
};
export const jpeg = {
    type: 'image/jpeg',
    name: 'JPEG'
};
export const png = {
    type: 'image/png',
    name: 'PNG'
};
export const gif = {
    type: 'image/gif',
    name: 'GIF'
};
export const formData = {
    type: 'multipart/form-data',
    name: 'FORM-DATA'
};

export const tar = {
    ...xls,
    name: 'TAR',
    title: 'Выгрузить все фото в архив'
};

export const ACCEPTED_FILE_TYPES = [txt, doc, docx, xls, xlsx, pdf, jpeg, png, gif];
export const REPORT_FILE_TYPES = [pdf, doc, xls, csv];
export const REPORT_FILE_TYPES_SMALL = [csv, xls];
export const REPORT_FILE_TYPES_SMALL_GRAPH = [csv, xls, xlsxGraph];
export const IMAGES_FILE_TYPES = [jpeg, png, gif];
export const ALL_REPORT_FILE_TYPES = [pdf, doc, docx, xls, xlsx, csv, xlsxGraph];
