import React from 'react';
import { Collapse, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import messages from '../../../../helpers/constants/messages';
import titles from '../../../../helpers/constants/titles';
import { v4 as uuidv4 } from 'uuid';
import GetIcon from '../../../../img/GetIcon';
import makeStyles from '@mui/styles/makeStyles';
import transportTypes from '../../../../helpers/transport.types';

const notInfo = messages.INFO_IS_NOT_FOUND;

const useStyles = makeStyles({
    img: {
        padding: 7,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        width: 30,
        height: 30,
        borderRadius: '50%'
    },
});

const Info = ({ isOpen, item = {} }) => {
    const classes = useStyles();
    const types = transportTypes;

    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            <div><strong>{titles.COMPANY}: </strong>{item?.organization_id || notInfo}</div>
            <div>
                <strong>{titles.USER}: </strong>
                { item?.user_id || notInfo }
            </div>
            <div>
                <strong>{'Маршруты в районе события'}: </strong>
                {item?.route_list
                    ? item?.route_list?.split(';')?.map(r => 
                        <div style={{ borderBottom: '1px solid #d9d9d9' }} key={uuidv4()}>
                            <ListItem>
                                {/* <ListItemIcon>
                                    <div
                                        className={classes.img}
                                        style={{ backgroundColor: types[r?.category_id]?.color }}
                                    >
                                        <GetIcon
                                            iconName={types[r?.category_id]?.iconName}
                                            format="svg"
                                            category="transport"
                                        />
                                    </div>
                                </ListItemIcon> */}
                                <ListItemText>
                                    {/* <div>
                                        №{r.num} {r.name}({r.is_direct_text})
                                    </div> */}
                                    <div>
                                        {r}
                                    </div>
                                </ListItemText>
                            </ListItem>
                        </div>)
                    : notInfo 
                }
            </div>
        </Collapse>
    );
};

export default Info;