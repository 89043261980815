export default function (status) {
    const statusList = {
        'created': 'Создал',
        'updated': 'Изменил',
        'deleted': 'Удалил',
        'restored': 'Восстановил',
    };

    return statusList[status] || statusList['updated'];
};
