import React, { useEffect, useMemo, useState } from 'react';
import DateRange from '../../../common/Dates/DateRange';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import {
    loadOverlapTypes,
    loadOverlapSource,
} from '../../../../redux/RoadNetwork/actions';
import {
    getStartOf,
    getEndOf,
    getDateWithDuration,
} from '../../../../helpers/date.config';
import { config } from '../../../../config';
import Subscribe from './Subscribe';
import Select from './Select';
import { useStoreProp } from '../../../../helpers/hooks';


function Filter({
    onChange,
}) {
    const overlapTypes = useStoreProp(
        loadOverlapTypes,
        'roadNetworks',
        'overlapTypes'
    );

    const overlapSource = useStoreProp(
        loadOverlapSource,
        'roadNetworks',
        'overlapSource'
    );

    const typeList = useMemo(() => {
        return Object.keys(overlapTypes).map(id => ({ id, name: overlapTypes[id] }));
    }, [overlapTypes]);
    const sourceList = useMemo(() => {
        return Object.keys(overlapSource).map(id => ({ id, name: overlapSource[id] }));
    }, [overlapSource]);

    // const heatMapFilter = useSelector(roadNetworksSelectors.filterOverlap);

    const [disabledDate, setDisabledDate] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);

    const start = getStartOf('day', getDateWithDuration({ day: -10 }));

    const initialState = {
        // start_at: getStartOf('day', getDateWithDuration({ month: -1 })),
        // end_at: getEndOf(),
        start_at: start,
        end_at: getEndOf('day', getDateWithDuration({ month: 1 }, start)),
        type: '',
        source_creation: '',
        period: 'byMonth',
    };

    const [data, setData] = useState({
        ...initialState,
        // ...heatMapFilter,
    });

    const filter = useMemo(() => {
        return removeEmptyFields(data);
    },[data]);

    const search = () => {
        onChange(filter);
        setDisabledButton(true);
    };

    const resetSearch = () => {
        setData(initialState);
        onChange(initialState);
    };

    useEffect(() => {
        search();
    }, []);


    const periodOptions = {
        byDay: { name: 'день', count: 1, unit: 'day' },
        byWeek: { name: 'неделя', count: 1, unit: 'week' },
        byMonth: { name: 'месяц', count: 1, unit: 'month' },
        // all: { name: 'произвольный', },
    };

    const handleChangeData = (name, value) => {
        setDisabledButton(false);

        const { count, unit } = periodOptions[data.period] || {};

        if (name === 'start_at') {
            setData(old => ({
                ...old,
                [name]: value,
                ...(count && unit
                    ? {
                        end_at: getStartOf('day', getDateWithDuration({ [unit]: count }, value))
                    }
                    : {}
                )
            }));
        }
        if (name === 'end_at') {
            setData(old => ({
                ...old,
                [name]: value,
                ...(count && unit
                    ? {
                        start_at: getEndOf('day', getDateWithDuration({ [unit]: -count }, value))
                    }
                    : {}
                )
            }));
        }
    };

    const handleEvent = (name) => (value) => {
        console.log(name, value);

        const getDate = () => {
            console.log(name);
            if (name === 'period') {
                const { count, unit } = periodOptions[value?.id] || {};
                console.log(count, unit);

                if (count && unit) {
                    return {
                        start_at: getDateWithDuration(
                            { days: 1 },
                            getStartOf('day', getDateWithDuration({ [unit]: -count })),
                        ),
                        end_at: getEndOf('day'),
                    };
                }
            }
            return {};
        };

        setData(old => ({
            ...old,
            [name]: value?.id || '',
            ...getDate()
        }));

        setDisabledButton(false);
    };

    // const handleChangeSelects = (e) => {
    //     const { value, name } = e.target;
    //
    //     const addDate = periodOptions[value];
    //     const { count, unit } = addDate;
    //     setData({
    //         ...data,
    //         start_date: getDateWithDuration(
    //             { days: 1 },
    //             getStartOf('day', getDateWithDuration({ [unit]: -count })),
    //         ),
    //         end_date: getEndOf('day'),
    //         // [name]: value,
    //     });
    // };

    const telegramLink = config.get('overlapSubscribeLink') || null;

    const [showSubscribe, setShowSubscribe] = useState(false);

    const periodList = useMemo(() => {
        return Object
            .keys(periodOptions)
            ?.map(id => ({
                id,
                name: periodOptions[id].name
            }));
    }, [periodOptions]);

    return (
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">

                <Select
                    value={data?.type || null}
                    label="Категория ограничений"
                    options={typeList}
                    onChange={handleEvent('type')}
                />

                <Select
                    value={data?.source_creation || null}
                    label="Источник ограничений"
                    options={sourceList}
                    onChange={handleEvent('source_creation')}
                />

                <Select
                    value={data?.period || null}
                    label="Выберите период"
                    options={periodList}
                    onChange={handleEvent('period')}
                />

                <DateRange
                    handleDate={(value) => handleChangeData('start_at', value)}
                    handleEndDate={(value) => handleChangeData('end_at', value)}
                    valueStartDate={data.start_at}
                    valueEndDate={data.end_at}
                    isDisabledHandler={setDisabledDate}
                />

            </div>
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        ...telegramLink
                            ? [{
                                ...buttonsTypes.apply,
                                onClick: () => setShowSubscribe(true),
                                name: 'Подписка',
                            }]
                            : [],
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetSearch
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: search,
                            disabled: Object.values(filter).length === 0 || disabledDate || disabledButton
                        },
                    ]}
                />
            </div>

            {showSubscribe && (
                <Subscribe
                    show={showSubscribe}
                    onClose={() => setShowSubscribe(false)}
                />
            )}
        </div>
    );
}

export default Filter;
