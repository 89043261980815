import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { memo } from 'react';
import { FormSelectType } from './types';
import messages from '../../../../../../../helpers/constants/messages';

const FormSelect = memo(({ data, param, callback, required = false }: FormSelectType) => {

    return (
        <div>
            <FormControl
                variant="outlined"
                size="small"
                className="block"
                required={required}
            >
                <InputLabel>{param.name}</InputLabel>
                <Select
                    label={param.name}
                    // @ts-ignore
                    value={data[param.field] || ''}
                    onChange={callback}
                    name={param.field}
                >
                    {(Array.isArray(param.list) && param.list.length)
                        ? param.list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        ))
                        : (typeof param.list === 'object' && param.list !== null && Object.keys(param.list).length)
                            ? Object.keys(param.list).map((index: any) => (
                                <MenuItem key={index} value={index}>{param.list[index]}</MenuItem>
                            ))
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                    }
                </Select>
            </FormControl>
        </div>
    );
});

export default FormSelect;