import L from 'leaflet';
import React, { useEffect, useRef, useContext } from 'react';
import Context from '../../../../helpers/context';
import 'leaflet.marker.slideto';
import { usePrevious } from '../../../../helpers/hooks';
import { useWsSubscribe } from '../../../../helpers/ws/hooks';
import { difference, isEqual } from 'lodash';
import location from '../../leaflet/icon/location.png';
import 'leaflet.marker.slideto';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { renderToString } from 'react-dom/server';
import { v4 as uuid_v4 } from 'uuid';
import { render, unmountComponentAtNode } from 'react-dom';
import { store } from '../../../../store';
import config from './config';
import compareEventWs from '../../../../helpers/ws/helper/compareEventWs';
import { setWsTelemetry as setWsTelemetryTS } from '../../../../redux/TransportSpecial/actions';
import { transportSpecialSelectors } from '../../../../redux/TransportSpecial';

// иконка по умолчанию
const defaultIcon = L.icon({
    iconUrl: location,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
});

// маркер карты
const Markers = (props) => {
    const { permissions } = useContext(Context);

    const {
        map,
        // area = null,
        parent,
        icon,
        toolTipTemplate,
        toolTipOption = {},
        popUpTemplate,
        // channel = null,
        onDestroy = null,
        onClosePopUp = () => {},
    } = props;

    const dispatch = useDispatch();

    // подключение к каналу
    useWsSubscribe('transport-special_telemetry_v2', (events) => {
        if (events?.length > 0) {
            // объединение
            compareEventWs(
                'transport-special_telemetry',
                (data) => dispatch(setWsTelemetryTS(data)),
                'external_id'
            ).list(events);

            events?.length > 0 && events?.map((item) => wsItem(item));
        }
    });

    const handleClosePopUp = () => {
        onClosePopUp();
    };

    const wsItem = (event) => {
        const {
            external_id,
            source,
            direction,
            in_registry,
            in_work_order,
            lat,
            lon,
        } = event;

        const category_id = event?.vehicle?.category_id || null;

        const iconOption = {
            in_registry,
            category_id,
            in_work_order
        };

        // const key = `${source}_${client_full}`;
        const key = `${external_id}`;
        if (refKeys.current[key]) {
            const item = refKeys.current[key];
            // меняем цвет иконки
            const currenIconOption = refKeysOptions.current[key];

            if (!isEqual(currenIconOption, iconOption)) {
                const number = active?.characteristics?.serial_egts !== '' ? `${active?.characteristics?.serial_egts}`
                    : (active?.characteristics?.serial_yandex !== '' ? `${active?.characteristics?.serial_yandex}` : 0);

                const itemData = {
                    ...event,
                    currentShow: number === key,
                    category_id
                };

                refKeys.current[key].setIcon(icon ? icon(itemData) : defaultIcon);

                refKeysOptions.current[key] = {
                    ...iconOption
                };
            }

            // const id = `direction_${config.slug}_${key}`;
            // const current = document.getElementById(id);
            // if (current) {
            //     current.style.transform = `rotate(${direction}deg)`;
            // }
            latlonArr.current[key] = [lat,lon];

            item.slideTo([lat, lon], {
                duration: 5000,
                // keepAtCenter
            });

            const toolTip = refKeysTooltip.current[key] || null;
            if (toolTipTemplate && toolTip) {
                const tooltipContent = toolTipTemplate(event || {});
                const renderString = renderToString(tooltipContent);
                toolTip.setContent(renderString);
            }
        }
    };

    const data = useSelector(transportSpecialSelectors.wsData);
    const active = useSelector(transportSpecialSelectors.active);

    const refKeysOptions = useRef({});
    const refKeysTooltip = useRef({});
    const refKeys = useRef({});
    const prevData = usePrevious(data);

    const dataKeys = Object.keys(data);
    const prevDataKeys = Object.keys(prevData);

    const addKeys = difference(dataKeys, prevDataKeys);
    const deleteKeys= difference(prevDataKeys, dataKeys);
    const latlonArr = useRef([]);

    const handleShowPopup = (newId, key, latlon) => () => {
        map.setView(latlonArr.current[key] || latlon);
        setTimeout(() => {
            const currentId = document.getElementById(newId);
            if (currentId) {
                currentId && unmountComponentAtNode(currentId);
                render(
                    <Context.Provider value={{ permissions }}>
                        <Provider store={store}>
                            {popUpTemplate(key)}
                        </Provider>
                    </Context.Provider>,
                    currentId
                );
            }}, 300);
    };

    const handleAdd = (key) => {
        const item = refKeys.current[key];
        const {
            lat,
            lon,
            in_registry,
            in_work_order
        } = data[key];

        if (!item) {
            const category_id = data[key]?.vehicle?.category_id || null;

            const iconOption = {
                in_registry,
                category_id,
                in_work_order
            };

            const number = active?.characteristics?.serial_egts !== '' ? `${active?.characteristics?.serial_egts}`
                : (active?.characteristics?.serial_yandex !== '' ? `${active?.characteristics?.serial_yandex}` : 0);

            const itemData = {
                ...data[key],
                currentShow: number === key,
                category_id
            };
            const newMarker = L
                .marker([lat, lon], {
                    icon: icon ? icon(itemData) : defaultIcon
                });
            parent.addLayer(newMarker);
            refKeys.current[key] = newMarker;
            refKeysOptions.current[key] = iconOption;

            // tooltip
            if (toolTipTemplate) {
                const tooltipContent = toolTipTemplate(data[key] || {});
                const renderString = renderToString(tooltipContent);
                const toolTip = L.tooltip({
                    ...toolTipOption,
                });
                toolTip.setContent(renderString);
                newMarker.bindTooltip(toolTip);

                refKeysTooltip.current[key] = toolTip;
            }

            // popup
            if (popUpTemplate) {
                const newId = uuid_v4();
                const popUp = L
                    .popup({
                        minWidth: 600,
                        closeOnClick: true,
                        className: 'custom-popup-wrapper',
                        offset: [0, -10],
                    })
                    .setContent(`<div class="popup-route" id="${newId}"></div>`);
                newMarker.bindPopup(popUp);
                newMarker.on('click', handleShowPopup(newId, key, [lat, lon]));

                newMarker.on('popupclose', handleClosePopUp);

                // показываем активный
                setTimeout(() => {
                    const number = active?.characteristics?.serial_egts || 0;
                    if (number && number === key) {
                        newMarker.fire('click');
                        newMarker.closeTooltip();
                    }
                }, 500);
            }
        }
    };

    const handleDelete = (key) => {
        if (refKeys.current[key]) {
            const item = refKeys.current[key];
            item.off('click');
            item.off('popupclose', handleClosePopUp);
            parent.removeLayer(item);
            item.remove();
            delete refKeys.current[key];
            delete refKeysOptions.current[key];
            delete refKeysTooltip.current[key];
        }
    };

    useEffect(() => {
        // добавляем маркеры
        if (addKeys.length) {
            addKeys.map((itemKey) => {
                if (data[itemKey]) {
                    handleAdd(itemKey);
                }
            });
        }
    }, [addKeys]);

    useEffect(() => {
        // удаляем маркеры
        if (deleteKeys.length) {
            deleteKeys.map((itemKey) => {
                handleDelete(itemKey);
            });
        }
    }, [deleteKeys]);

    useEffect(() => {
        // удаляем маркеры
        return () => {
            Object.keys(refKeys.current).map((itemKey) => {
                handleDelete(itemKey);
            });
        };
    }, []);

    useEffect(() => {
        if (active && Object.keys(active).length > 0) {

            const number = active?.characteristics?.serial_egts !== '' ? `${active?.characteristics?.serial_egts}`
                : (active?.characteristics?.serial_yandex !== '' ? `${active?.characteristics?.serial_yandex}` : 0);

            const item = refKeys.current[number || 0] || null;

            // кликнули по маркеру
            const isClick = active?.isClick || false;

            if (item) {
                const dataItem = data[number];
                const category_id = data[number]?.vehicle?.category_id || null;

                item.setIcon(icon({
                    ...dataItem,
                    currentShow: true,
                    category_id,
                }));

                if (!isClick) {
                    setTimeout(() => {
                        item.fire('click');
                        item.closeTooltip();
                    }, 500);
                }
                return () => {
                    item.setIcon(icon({
                        ...dataItem,
                        currentShow: false,
                        category_id,
                    }));
                    item.isPopupOpen() && item.closePopup();
                    item.isTooltipOpen() && item.closeTooltip();
                };
            }
        }
    },[active]);

    return null;
};

export default Markers;
