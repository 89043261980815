import {
    keyBy,
    merge,
    values,
} from 'lodash';

import * as types from './types';

const initialState = {
    cameras: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loading: false,
    settings: [],
    settingsLoading: false,
    infoList: {},
    violations: {},
    events: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    eventsLoading: false,
    reportGroups: [],
    reportTypes: [],
    reportData: {},
    reportLoading: false,
    cameraReportFilter:{},
    active: {},
    filters: {
        // дефолтные данные
        // start_date: new Date(new Date().setHours(0,0,0,0)),
        // end_date: new Date(new Date().setHours(23,59,59,0)),
    },
    polygon: [],
    list: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    listLoading: false,
    eventsPopup: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingPopup: false,
    statuses: [],
    grzPointsGroup: [],
    loadingGrzPointsGroup: false,
    videoAnalyticsReportFilter: {},
    eventsFilters: [],
    cameraObjects: {},
    loadingCameraObjects: false,
    filterCameraObjects: {},
    passengerTrafficList: {
        data: {}
    },
    passengerTrafficTypesList: [],
    passengerTrafficFilter: [],
    passengerTrafficLoading: false,
    inOutCamerasReport: {},
    inOutCamerasReportLoading: false,
    camerasByRadius: [],
    showcase: {},
    loadingShowcase: false,
    excludedKeysForEventsLabels: []
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_CAMERAS:
            return {
                ...state,
                cameras: payload
            };
        case types.LOADING_CAMERAS:
            return {
                ...state,
                loading: payload
            };
        case types.LOADING_CAMERA_SETTINGS:
            return {
                ...state,
                settingsLoading: payload
            };
        case types.LOADED_CAMERA_SETTINGS:
            return {
                ...state,
                settings: payload.data
            };
        case types.LOADED_CAMERA_INFO:
            return {
                ...state,
                infoList: payload.data
            };
        case types.LOADED_CAMERA_VIOLATIONS:
            return {
                ...state,
                violations: payload?.data
            };
        case types.LOADING_CAMERA_EVENTS:
            return {
                ...state,
                eventsLoading: payload
            };
        case types.LOADED_CAMERA_EVENTS:
            return {
                ...state,
                events: payload
            };
        case types.LOADED_CAMERA_REPORT_GROUPS:
            return {
                ...state,
                reportGroups: payload.data
            };
        case types.LOADED_CAMERA_REPORT_TYPES:
            return {
                ...state,
                reportTypes: payload.data
            };
        case types.LOADED_CAMERA_REPORT:
            return {
                ...state,
                reportData: payload.data
            };
        case types.LOADING_CAMERA_REPORT:
            return {
                ...state,
                reportLoading: payload
            };
        // case types.CLEAR_CAMERA_REPORT:
        //     return {
        //         ...state,
        //         reportData: {}
        //     };
        // case types.SET_CAMERA_REPORT_FILTER:
        //     return {
        //         ...state,
        //         cameraReportFilter: payload
        //     };
        // case types.CLEAR_CAMERA_REPORT_FILTER:
        //     return {
        //         ...state,
        //         cameraReportFilter: {}
        //     };
        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }
        case types.SET_FILTER: {
            return {
                ...state,
                filter: payload,
            };
        }
        case types.SAVE_POLYGON: {
            return  {
                ...state,
                polygon: payload
            };
        }
        case types.SAVE_DEVICE_LIST: {
            const { data, replace } = payload;
            return {
                ...state,
                list: replace ? data : {
                    meta: data.meta,
                    // data: [
                    //     ...state.list.data,
                    //     ...data.data
                    // ],
                    // исключаю повторы
                    data: values(merge(
                        keyBy(state?.list?.data  || [], 'id'),
                        keyBy(data?.data || [], 'id')
                    ))
                }
            };
        }
        case types.CLEAR_DEVICE_LIST: {
            return {
                ...state,
                list: {
                    data: [],
                    meta: {
                        last_page: 0,
                    }
                }
            };
        }
        case types.SET_LIST_LOADING: {
            return  {
                ...state,
                listLoading: payload
            };
        }
        case types.SET_FILTERS: {
            return {
                ...state,
                filters: payload
            };
        }
        case types.SAVE_EVENTS_POPUP: {
            const { data, replace } = payload;
            return {
                ...state,
                eventsPopup: replace === false ? data : {
                    ...state.eventsPopup,
                    ...data,
                    data: [
                        ...state.eventsPopup.data,
                        ...data.data
                    ]
                }
            };
        }
        case types.CLEAR_EVENTS_POPUP: {
            return {
                ...state,
                eventsPopup: {
                    data: [],
                    meta: {
                        last_page: 0,
                    }
                },
            };
        }
        case types.SET_LOADING_POPUP: {
            return {
                ...state,
                loadingPopup: payload
            };
        }
        case types.LOADED_STATUSES: {
            return {
                ...state,
                statuses: payload,
            };
        }
        case types.LOADING_CAMERA_GRZ_POINTS: {
            return {
                ...state,
                loadingGrzPointsGroup: payload
            };
        }
        case types.LOADED_CAMERA_GRZ_POINTS: {
            return {
                ...state,
                grzPointsGroup: payload
            };
        }
        case types.CLEAR_CAMERA_GRZ_POINTS: {
            return {
                ...state,
                grzPointsGroup: []
            };
        }
        case types.WS_REPLACE_DATA: {
            // функция замена камеры
            const replaceCameraIn = (storeData, newData) => {
                const is_deleted = newData?.is_deleted || false;

                const index = storeData.findIndex(({ id }) => id === newData.id);

                if (index >= 0) {
                    // запись удалена
                    if (is_deleted) {
                        return [
                            ...storeData.slice(0, index),
                            ...storeData.slice(index + 1)
                        ];
                    } else {
                        return [
                            ...storeData.slice(0, index),
                            newData,
                            ...storeData.slice(index + 1)
                        ];
                    }
                }

                return [
                    newData,
                    ...storeData,
                ];
                // return storeData;
            };

            const replace = (defaultData) => payload?.length > 0
                ? payload?.reduce((res, item) => item ? replaceCameraIn(res, item) : res, defaultData)
                : [];

            //todo заменить данные?
            // cameras ?
            // list ?
            return {
                ...state,
                polygon: replace(state.polygon || []), // replaceCameraIn(state.polygon, payload),
                list: {
                    ...state.list,
                    data: replace(state.list?.data || []), // replaceCameraIn(state.list?.data, payload),
                },
            };
        }
        case types.SET_VA_REPORT_FILTER: {
            return {
                ...state,
                videoAnalyticsReportFilter: payload
            };
        }
        case types.CLEAR_VA_REPORT_FILTER: {
            return {
                ...state,
                videoAnalyticsReportFilter: {}
            };
        }
        case types.LOADED_EVENTS_FILTERS: {
            return {
                ...state,
                eventsFilters: payload
            };
        }

        case types.LOADED_CAMERA_OBJECTS: {
            return {
                ...state,
                cameraObjects: payload
            };
        }

        case types.LOADING_CAMERA_OBJECTS: {
            return {
                ...state,
                loadingCameraObjects: payload
            };
        }

        case types.SET_FILTER_CAMERA_OBJECTS: {
            return {
                ...state,
                filterCameraObjects: payload
            };
        }

        case types.LOADED_PASSENGER_TRAFFIC_TYPES: {
            return {
                ...state,
                passengerTrafficTypesList: payload
            };
        }

        case types.LOADED_PASSENGER_TRAFFIC: {
            return {
                ...state,
                passengerTrafficList: payload
            };
        }

        case types.SET_PASSENGER_TRAFFIC_FILTER: {
            return {
                ...state,
                passengerTrafficFilter: payload
            };
        }

        case types.LOADING_PASSENGER_TRAFFIC: {
            return {
                ...state,
                passengerTrafficLoading: payload
            };
        }

        case types.LOADING_IN_OUT_CAMERAS_REPORT: {
            return {
                ...state,
                inOutCamerasReportLoading: payload
            };
        }

        case types.LOADED_IN_OUT_CAMERAS_REPORT: {
            return {
                ...state,
                inOutCamerasReport: payload
            };
        }

        case types.CLEAR_IN_OUT_CAMERAS_REPORT: {
            return {
                ...state,
                inOutCamerasReport: initialState.inOutCamerasReport,
            };
        }

        case types.LOADED_CAMERAS_BY_RADIUS: {
            return {
                ...state,
                camerasByRadius: payload
            };
        }

        case types.LOADED_SHOWCASE: {
            return {
                ...state,
                showcase: payload,
            };
        }

        case types.LOADING_SHOWCASE: {
            return {
                ...state,
                loadingShowcase: payload,
            };
        }

        case types.SAVE_EXCLUDED_KEYS: {
            return {
                ...state,
                excludedKeysForEventsLabels: payload,
            };
        }

        default:
            return state;
    }
}
