import { useState } from 'react';
import {
    FormHelperText,
} from '@mui/material';

import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import WorksByGroupSelect from 'components/common/Autocomplete/RoadWorks/WorksByGroup';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import { roadworksOdersURI } from '../../moduleConfig';

const test_id_prefix = roadworksOdersURI; // префикс для полей для автотестов

const WorkAreaModalItem = ({ item, index, onChangeData, handleClickDelete }) => {
    const [confirmOpen, setConfirmOpen] = useState(false);

    return (
        <>
            {confirmOpen &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={confirmOpen}
                    onSuccess={() => {
                        handleClickDelete(index);
                        setConfirmOpen(false);
                    }}
                    onClose={() => setConfirmOpen(false)}
                />
            )}
            <div className="row align-items-center">
                <div className="row__item">
                    <WorksByGroupSelect
                        selectedGroup={item.group}
                        selectedWorks={item.work}
                        selectedUnits={item.units}
                        onChange={onChangeData}
                        test_id_prefix={`${test_id_prefix}/tab/workByStage/groupWork/edit/select`}
                    />
                    {item.error && <FormHelperText error>{item.error}</FormHelperText>}
                </div>
                <FormButtonsComponent
                    uri={roadworksOdersURI}
                    noMarginLeft
                    justButton
                    buttons={[
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setConfirmOpen(true),
                            accessProp: 'is_read',// костыль пока для нестандартных прав когда на всю страницу нет прав

                        }
                    ]}
                />
            </div>
        </>
    );
};

export default WorkAreaModalItem;
