import { config } from '../../config';

const apiEcologyUrl = () => config.get('urls').apiEcologyUrl;

const apiUrls = {
    // проекты
    getProjects: () => `${apiEcologyUrl()}/projects`,

    // типы
    getDeviceTypes: () => `${apiEcologyUrl()}/device/types`,

    // список
    getDeviceList: () => `${apiEcologyUrl()}/devices`,

    // связи с компаниями
    postDeviceCompanyLink: (id) => `${apiEcologyUrl()}/device/${id}/organizations`,

    deleteDeviceCompanyLink: (id) => `${apiEcologyUrl()}/device/${id}/organizations`,

    getDeviceCompanyLink: (id) => `${apiEcologyUrl()}/device/${id}/organizations`,

    // полигон
    getForPolygonDevices: () => `${apiEcologyUrl()}/polygon/devices`,

    // данные
    getDeviceData: () => `${apiEcologyUrl()}/device/data`,

    //события
    getEvents: () => `${apiEcologyUrl()}/events`,

    // статусы
    getStatuses: () => `${apiEcologyUrl()}/status`,

    // настройки
    getEcologySettings: () => `${apiEcologyUrl()}/settings`,

    editEcologySettings: (id) => `${apiEcologyUrl()}/settings/${id}`,

    editSettingsList: () => `${apiEcologyUrl()}/settings/list`,

    // оповещении
    getAlerts: () => `${apiEcologyUrl()}/device/organizations/alerts`,

    // настройки вывода параметров
    getEcologyOutputParams: () => `${apiEcologyUrl()}/device/params`,

    getEcologyOutputSettings: () => `${apiEcologyUrl()}/device/output_settings`,

    editEcologyOutputSettings: (id) => `${apiEcologyUrl()}/device/output_settings/${id}`,

    deleteEcologyOutputSettings: (id) => `${apiEcologyUrl()}/device/output_settings/${id}`,

    getDevicesGroup: () => `${apiEcologyUrl()}/devices_group`,

    createDevicesGroup: () => `${apiEcologyUrl()}/devices_group`,

    editDevicesGroup: (id) => `${apiEcologyUrl()}/devices_group/${id}`,

    deleteDevicesGroup: (id) => `${apiEcologyUrl()}/devices_group/${id}`,

    loadShowcase: () => `${apiEcologyUrl()}/showcase/layer2`,

    loadEcoByRadius: () => `${apiEcologyUrl()}/device/radius`,

    getSettingsGroup: () => `${apiEcologyUrl()}/settings_group`,

    editSettingsGroup: (id) => `${apiEcologyUrl()}/settings_group/${id}/settings/update`,

    getDeviceStatuses: () => `${apiEcologyUrl()}/v2/device/statuses`,
};

export default apiUrls;
