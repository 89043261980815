import type { ChangeEvent } from 'react';
import { useMemo, useState } from 'react';
import { TextField } from '@mui/material';

import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import DateRange from 'components/common/Dates/DateRange';
import SelectUsers from 'components/common/Autocomplete/Users';
import StatusesSelect from 'components/common/Autocomplete/Statuses';

import type { FilterProps } from './types';

interface initState {
    destination: string;
    complex_id_list: { id: number }[];
    start_date: Date | null;
    end_date: Date | null;
    created_by_list: any; //TODO
    status_list: [];
}

const Filter = ({ setParams, status }: FilterProps) => {

    const initState: initState = {
        destination: '',
        start_date: null,
        end_date: null,
        complex_id_list: [],
        created_by_list: [],
        status_list: []
    };

    const [data, setData] = useState(initState);

    const statusesList = useMemo(() => Object
        .entries(status)
        ?.map(([id, name]) => ({
            id: Number(id),
            name,
        }))
    , [status]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleChangeStatus = (key: keyof initState, value: any) => {
        setData({
            ...data,
            [key]: value
        });
    };

    const handleSearch = (filter = data) => {
        const prepareData = {
            ...filter,
            start_date: fullDateTimeWithTimeZone(filter.start_date),
            end_date: fullDateTimeWithTimeZone(filter.end_date),
            complex_id_list: filter.complex_id_list.map(({ id }) => +id),
            created_by_list: filter.created_by_list.map(({ id }: Record<'id', string | number>) => +id),
            status_list: filter.status_list.map(({ id }) => +id)
        };

        setParams({ page: 1, query: removeEmptyFields(prepareData) });
    };

    const handleResetFilter = (needRefresh: boolean) => {
        if (needRefresh) {
            setParams(({ page: 1, query: {} }));
        }
        setData(initState);
    };

    const updateFilter = (filters: { [key: string]: {id: number}[] }) => {
        const params = {
            ...initState,
            ...filters,
        };
        setData(params);
        if (Object.keys(filters).length > 0) {
            handleSearch(params);
        }
    };

    const isPeriodInvalid = !data?.start_date !== !data?.end_date;

    return (
        <LayoutFilter
            onResetFilter={handleResetFilter}
            onSearch={handleSearch}
            filter={data}
            setUserFilter={updateFilter}
            filterException={['start_date', 'end_date']}
            disabled={isPeriodInvalid}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={data?.start_date}
                    valueEndDate={data?.end_date}
                    handleDate={(start_date) => setData({ ...data, start_date })}
                    handleEndDate={(end_date) => setData({ ...data, end_date })}
                    renderError={() => isPeriodInvalid}
                    errorMessage={() => titles.SELECT_PERIOD}
                />
                <SelectComplex
                    selected={data.complex_id_list}
                    onChange={(complex_id_list) => setData({ ...data, complex_id_list })}
                    multiple
                />
                <TextField
                    label={'Назначение'}
                    variant="outlined"
                    size="small"
                    value={data.destination}
                    name="destination"
                    onChange={handleChange}
                />
                <SelectUsers
                    multiple={true}
                    selected={data.created_by_list}
                    label={titles.USERS}
                    onChange={(created_by_list) => setData({ ...data, created_by_list })}
                />
                <StatusesSelect
                    multiple
                    selected={data.status_list}
                    options={statusesList}
                    renderLabel={(option) => option?.name || ''}
                    onChange={(value) => handleChangeStatus('status_list', value)}
                    filterSelectedOptions
                    isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                    onReset={() => handleChangeStatus('status_list', [])}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
