import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSelectorByLink } from '../index';


// хук загрузить данные по транспорту
const useTransportCoords = (actionCoords, actionClearCoords, actionAfterLoad, stateCoordsProp) => {
    const dispatch = useDispatch();

    // список
    const list = useSelectorByLink(stateCoordsProp);

    useEffect(() => {
        return () => {
            actionClearCoords && dispatch(actionClearCoords());
        };
    }, []);

    useEffect(() => {
        if (Object.keys(list).length > 0) {
            dispatch(actionAfterLoad(list));
            actionClearCoords && dispatch(actionClearCoords());
        }
    }, [dispatch, list]);

    return {
        load: (props = {}) => {
            dispatch(actionCoords(props));
        },
    };
};

export default useTransportCoords;
