export const metro = (state) => state.metro;

export const stationStatuses = (state) => metro(state).stationStatuses;

export const stationList = (state) => metro(state).stationList;
export const stationListData = (state) => stationList(state).data;
export const stationListMeta = (state) => stationList(state).meta;
export const loadingStationList = (state) => metro(state).loadingStationList;

export const stationSidebar = (state) => metro(state).stationSidebar;
export const loadingStationSidebar = (state) => metro(state).loadingStationSidebar;

export const active = (state) => metro(state).active;
export const filters = (state) => metro(state).filters;

export const vestibulePolygon = (state) => metro(state).vestibulePolygon;
export const vestibulePolygonLoading = (state) => metro(state).vestibulePolygonLoading;
