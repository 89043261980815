// иконка для списка
const icon = (props, inRegistry = true) => {
    if (inRegistry) {
        return (
            <svg
                fill={props?.fill || '#fff'}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
            >
                <path
                    d="M112 160C112 151.2 119.2 144 128 144H176V96C176 87.16 183.2 80 192 80H224C232.8 80 240 87.16 240 96V144H288C296.8 144 304 151.2 304 160V192C304 200.8 296.8 208 288 208H240V256C240 264.8 232.8 272 224 272H192C183.2 272 176 264.8 176 256V208H128C119.2 208 112 200.8 112 192V160zM256 416C256 469 213 512 160 512C106.1 512 64 469 64 416C28.65 416 0 387.3 0 352V64C0 28.65 28.65 0 64 0H352C387.3 0 416 28.65 416 64V96H458.7C473.6 96 487.8 101.9 498.3 112.4L591.6 205.7C602.1 216.2 608 230.4 608 245.3V368H616C629.3 368 640 378.7 640 392C640 405.3 629.3 416 616 416H576C576 469 533 512 480 512C426.1 512 384 469 384 416H256zM352 48H64C55.16 48 48 55.16 48 64V352C48 360.8 55.16 368 64 368H76.84C93.44 339.3 124.5 320 160 320C195.5 320 226.6 339.3 243.2 368H352C360.8 368 368 360.8 368 352V64C368 55.16 360.8 48 352 48zM464.4 146.3C462.9 144.8 460.9 144 458.7 144H416V240H558C557.9 239.9 557.8 239.7 557.7 239.6L464.4 146.3zM160 368C133.5 368 112 389.5 112 416C112 442.5 133.5 464 160 464C186.5 464 208 442.5 208 416C208 389.5 186.5 368 160 368zM480 464C506.5 464 528 442.5 528 416C528 389.5 506.5 368 480 368C453.5 368 432 389.5 432 416C432 442.5 453.5 464 480 464z"
                />
            </svg>
        );

        // return `
        //     <svg fill="${props?.fill || '#fff'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M112 160C112 151.2 119.2 144 128 144H176V96C176 87.16 183.2 80 192 80H224C232.8 80 240 87.16 240 96V144H288C296.8 144 304 151.2 304 160V192C304 200.8 296.8 208 288 208H240V256C240 264.8 232.8 272 224 272H192C183.2 272 176 264.8 176 256V208H128C119.2 208 112 200.8 112 192V160zM256 416C256 469 213 512 160 512C106.1 512 64 469 64 416C28.65 416 0 387.3 0 352V64C0 28.65 28.65 0 64 0H352C387.3 0 416 28.65 416 64V96H458.7C473.6 96 487.8 101.9 498.3 112.4L591.6 205.7C602.1 216.2 608 230.4 608 245.3V368H616C629.3 368 640 378.7 640 392C640 405.3 629.3 416 616 416H576C576 469 533 512 480 512C426.1 512 384 469 384 416H256zM352 48H64C55.16 48 48 55.16 48 64V352C48 360.8 55.16 368 64 368H76.84C93.44 339.3 124.5 320 160 320C195.5 320 226.6 339.3 243.2 368H352C360.8 368 368 360.8 368 352V64C368 55.16 360.8 48 352 48zM464.4 146.3C462.9 144.8 460.9 144 458.7 144H416V240H558C557.9 239.9 557.8 239.7 557.7 239.6L464.4 146.3zM160 368C133.5 368 112 389.5 112 416C112 442.5 133.5 464 160 464C186.5 464 208 442.5 208 416C208 389.5 186.5 368 160 368zM480 464C506.5 464 528 442.5 528 416C528 389.5 506.5 368 480 368C453.5 368 432 389.5 432 416C432 442.5 453.5 464 480 464z"/></svg>
        // `;
    }
    return (
        <svg
            style={{ padding: '3px' }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="question"
            className="svg-inline--fa fa-question fa-w-12"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
        >
            <path
                fill={props?.fill || '#fff'}
                d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"
            />
        </svg>
    );

    // return `
    //     <svg style="padding: 3px;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question" class="svg-inline--fa fa-question fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
    //         <path fill="${props?.fill || '#fff'}" d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"></path>
    //     </svg>
    // `;
};

export default icon;
