import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import { render, unmountComponentAtNode } from 'react-dom';
import { v4 as uuid_v4 } from 'uuid';// генератор ключа
import { Provider } from 'react-redux';
import { store } from '../../../../store';


// собственный элемент управления
const CustomControl = ({
    children,
    map,
    position = 'topleft',
    className = null,
}) => {
    const id = useRef(null);

    const context = () => (
        <Provider store={store}>
            {React.cloneElement(children, {
                map
            })}
        </Provider>
    );

    const getElementById = () => {
        const { current } = id;
        return  document.getElementById(current);
    };

    const mountChildren = () => {
        const elementById = getElementById();
        if (elementById) {
            render(
                context(),
                elementById
            );
        }
    };

    const unmountChildren = () => {
        const elementById = getElementById();
        elementById && unmountComponentAtNode(elementById);
    };

    useEffect(() => {
        if (map) {
            const newId = uuid_v4();
            id.current = newId;
        }
    }, [map]);


    useEffect(() => {
        if (id.current && children) {
            // новый компонент управления
            const customControl = L.Control.extend({
                options: {
                    position,
                },
                onAdd: function (map) {
                    const container = L.DomUtil.create(
                        'div',
                        'leaflet-control leaflet-control-custom leaflet-control-layers' // leaflet-bar leaflet-disabled
                    );
                    className && container.classList.add(className);
                    container.id = id.current;

                    // отключаем скрол карты и клик
                    L.DomEvent.disableClickPropagation(container);
                    L.DomEvent.disableScrollPropagation(container);

                    return container;
                }
            });
            const newCustomControl = new customControl();
            map.addControl(newCustomControl);

            // монтирование компонента
            mountChildren();

            return () => {
                // очистка
                unmountChildren();
                map.removeControl(newCustomControl);
                newCustomControl.remove();
            };
        }
    }, [id]);
    //todo продумать обработку изменений children

    return null;
};

export default CustomControl;
