import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { loadThreatLevels } from 'redux/SituationalPlans/actions';
import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect/';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import type { CommonAutocompleteProps } from '../../CommonAutocomplete';
import type { GetListArguments, ThreadLevelItem } from 'types';

const SelectThreadLevel = ({
    multiple = false,
    selected,
    onChange = () => {},
    required = false,
    disabled = false,
    label = titles.THREAT_LEVEL,
    className = '',
    error = false,
    helperText = '',
    renderLabel = (option: ThreadLevelItem | null) => option?.name || '',
    limitTags = 1,
}: CommonAutocompleteProps<ThreadLevelItem>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);

    useEffect(() => {
        if (!isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);
    
    const getList = (params: GetListArguments) => {
        const { page, limit } = params;
        dispatch(loadThreatLevels(page, limit));
    };
        
    const handleAccept = (data: ThreadLevelItem | ThreadLevelItem[]) => {
        onChange(data);
        setShowList(false);
    };
            
    const handleClose = () => setShowList(false);
            
    const onReset = () => {
        const initialState = multiple ? [] : {};
        onChange(initialState as ThreadLevelItem);
    };

    return (
        <>
            <CommonAutocomplete<ThreadLevelItem>
                multiple={multiple}
                label={label}
                className={className}
                selected={selected}
                onChange={onChange}
                required={required}
                disabled={disabled}
                onReset={onReset}
                renderLabel={renderLabel}
                limitTags={limitTags}
                onShowList={() => setShowList(true)}
                error={error} 
                helperText={helperText}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    title={label}
                    fetchList={getList}
                    storeName="SituationalPlans"
                    storeNameProps="threatLevels"
                    storeLoadingProps="loadingThreatLevels"
                    keyProp="id"
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div><span>{el.name}</span></div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                />
            }
        </>
    );
};
export default SelectThreadLevel; 
