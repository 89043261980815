import colorExtend from 'helpers/mapHelper/colorExtend';
import { checkBase64 } from '../TransportPassenger/helper';
import icon from './icons/icon';


const LegendsList = ({ category = [], statuses = [] }) => {

    const renderList = () => {
        return (
            <div className="item">
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    {category.map(({ name, image }, index) => (
                        <div
                            key={index}
                            className="flex"
                            style={{
                                width: '200px',
                                alignItems: 'start',
                            }}
                        >
                            <div
                                className="image"
                                style={{
                                    alignItems: 'top',
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: image
                                        ? `<img src="${checkBase64(image)}" />`
                                        : icon({ fill: '#000' })
                                }}
                            >
                            </div>
                            <div className="small">
                                - {name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderStatusList = () => (
        <div className="item">
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
            >
                {statuses.map(({ name, color }, index) => (
                    <div
                        key={index}
                        className="flex"
                        style={{
                            width: '200px',
                            alignItems: 'start',
                        }}
                    >
                        <div
                            className="image"
                            style={{
                                alignItems: 'top',
                                background: colorExtend(color)
                            }}
                        >
                        </div>
                        <div className="small">
                            - {name}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <div className="sidebar-tp-legend">
            <div className="item">
                <div className="title">Типы событий</div>
                {renderList()}
            </div>
            {statuses?.length > 0 && (
                <div className="item">
                    <div className="title">Статусы</div>
                    {renderStatusList()}
                </div>
            )}
        </div>
    );
};

export default LegendsList;
