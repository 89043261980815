import React, { useEffect, useState } from 'react';
import DateRange from '../../../../common/Dates/DateRange';
import dateFormat from '../../../../../helpers/constants/dateFormat';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import messages from '../../../../../helpers/constants/messages';
import SelectStations from '../../../../common/Autocomplete/PassengerTransport/Stations';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import { 
    fullDateTimeWithTimeZone,
    getEndOf,
    getStartOf,
    getDateWithDuration,
    compareDatesByParams,
} from '../../../../../helpers/date.config';
import * as actions from '../../../../../redux/VideoAnalytics/actions';
import { useDispatch } from 'react-redux';

const Filters = (props) => {
    const {
        typesList,
        onTable,
        onClear
    } = props;

    const dispatch = useDispatch();

    const initialState = {
        station_id: [],
        type: 'byMinutes',
        start_date: getStartOf('day'),
        end_date: getEndOf('day'),
    };
    const [formValues, setFormValues] = useState(initialState);
    const [parameters, setParameters] = useState([]);

    const typeOptions = {
        byMinutes: { count: 1, unit: 'day' }, 
        byHours: { count: 1, unit: 'day' },
        byDays: { count: 1, unit: 'month' },
        byDayOfWeek: { count: 3, unit: 'month' },
    };

    useEffect(() => {
        if (parameters?.length === 0 && typesList?.length > 0) {
            const type = formValues?.type;
            const parametersCurrent = typesList?.find(({ value }) => value === type);
            setParameters(parametersCurrent?.parameters || []);
        }
    }, [typesList]);
    

    const handleChangeType = (e) => {
        const { value, name } = e.target;
        const addDate = typeOptions[value];
        const { count, unit } = addDate;
        setFormValues({
            ...formValues,
            start_date: getDateWithDuration(
                { days: 1 },
                getStartOf('day', getDateWithDuration({ [unit]: -count })),
            ),
            end_date: getEndOf('day'),
            [name]: value,
        });
        
    };

    const handleChangeStartDate = (value) => {
        const addDate = typeOptions[formValues.type];
        const { count, unit } = addDate;
        setFormValues({
            ...formValues,
            start_date: value,
            end_date: getDateWithDuration({ [unit]: count, second: -1 }, value),
        });
    };

    const handleChangeEndDate = (value) => setFormValues({ ...formValues, end_date: value });

    const renderError = () => {
        const { type, start_date, end_date } = formValues;
        if (parameters.length > 0) {
            if (parameters.includes('start_date') && parameters.includes('end_date')) {

                // дата не выбрана
                if (!start_date || !end_date)
                    return {
                        error: true,
                        message: 'Выберите дату и время'
                    };

                // дата начала больше даты конца
                if (start_date && end_date && new Date(start_date) >= new Date(end_date))
                    return {
                        error: true,
                        message: messages.ERROR_DATE_RANGE
                    };

                // сдвиг по дате
                const addDate = typeOptions[type];
                if (addDate) {
                    const { count, unit } = addDate;
                    const startDate = getDateWithDuration({ [unit]: count }, start_date);
                    const compare = compareDatesByParams(end_date, startDate);
                    // если разница start и end больше периода
                    if (compare.milliseconds >= 0) {
                        const currentType = typesList?.find(({ value }) => type === value);
                        return {
                            error: true,
                            message: `Разница между датой и временем конца и начала периода должна быть ${currentType.hint}`
                        };
                    }
                }
            } else if ((parameters.includes('start_date') && !start_date)
                || (parameters.includes('end_date') && !end_date)) {
                return {
                    error: true,
                    message: 'Выберите дату и время'
                };
            }
        }
        return {
            error: false
        };
    };

    // const errorDateRange = () => {
    //     const { type, start_date, end_date } = formValues;
    //     if (parameters.length > 0) {
    //         if (parameters.includes('start_date') && parameters.includes('end_date')) {

    //             // дата не выбрана
    //             if (!start_date || !end_date) return true;

    //             // дата начала больше даты конца
    //             if (start_date && end_date && new Date(start_date) >= new Date(end_date)) return true;

    //             // сдвиг по дате
    //             const addDate = typeOptions[type];
    //             if (addDate) {
    //                 const { 0: count, 1: unit } = addDate.split(' ');
    //                 const startDate = add(start_date, { [unit]: count });

    //                 return startDate <= end_date;
    //             }
    //         } else if (parameters.includes('start_date')) {
    //             // только 1 дата
    //             return !start_date;
    //         } else if (parameters.includes('end_date')) {
    //             return !end_date;
    //         }
    //     }
    //     return false;
    // };

    const paramsWithoutEmpty = () => {
        const result = {
            ...formValues,
            start_date: fullDateTimeWithTimeZone(formValues.start_date),
            end_date: fullDateTimeWithTimeZone(formValues.end_date),
            station_id: formValues.station_id.map(item => item.id)
        };

        return removeEmptyFields(result);
    };

    const resetFilters = () => {
        setFormValues(initialState);
        setParameters([]);
        dispatch(actions.setPassengerTrafficFilter(initialState));
        onClear && onClear();
    };

    const handleShowTable = () => {
        onTable && onTable(paramsWithoutEmpty());
        dispatch(actions.setPassengerTrafficFilter(formValues));
    };

    return (
        <div className="filter">
            <div className="filter filter__wrap filter__wrap__more__field">
                <SelectStations
                    multiple
                    error={!formValues.station_id.length}
                    selected={formValues.station_id}
                    onChange={(value) => {
                        setFormValues({
                            ...formValues,
                            station_id: value
                        });
                    }}
                />
                <DateRange
                    pickersFormat={dateFormat.DATE_WITH_HOUR_MIN_SECONDS}
                    views={['day', 'hours', 'minutes', 'seconds']}
                    valueStartDate={formValues.start_date}
                    valueEndDate={formValues.end_date}
                    handleDate={handleChangeStartDate}
                    handleEndDate={handleChangeEndDate}
                    renderError={() => renderError()?.error}
                    errorMessage={() => renderError()?.message}
                />
                <FormControl variant="outlined" size="small">
                    <InputLabel>Тип отчета</InputLabel>
                    <Select
                        label="Тип отчета"
                        name="type"
                        value={formValues?.type || ''}
                        onChange={handleChangeType}
                    >
                        {typesList.length > 0
                            ? typesList.map(item => {
                                const { description, hint, value, parameters, key } = item;
                                return (
                                    <MenuItem
                                        onClick={() => setParameters(parameters)}
                                        key={key}
                                        value={value}
                                    >
                                        {description}({hint})
                                    </MenuItem>
                                );
                            })
                            : <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </div>
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilters
                        },
                        {
                            ...buttonsTypes.find,
                            disabled: !formValues.station_id.length,
                            onClick: handleShowTable,
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default Filters;