import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiReactMethods = {
    getIncidents: async (page, limit, params) => {
        const response = await instance.get(apiUrls.getIncidents(), {
            params: { page, limit, ...params }
        });
        return response?.data;
    },

};

export default ApiReactMethods;
