import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { roadworksSelectors } from 'redux/RoadWorks';
import {
    createNewRW,
    getStatusesRW,
    loadRoadWorks,
    loadTypeCreation,
    setEditForm
} from 'redux/RoadWorks/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';

import EditorForm from './EditorForm';
import Filters from './Filters';
import Item from './Item';

const Sites = () => {
    const dispatch = useDispatch();

    const statuses = useStoreProp(getStatusesRW, 'roadworks', 'statuses');
    const typeCreation = useStoreProp(loadTypeCreation, 'roadworks', 'typeCreation');

    const listData = useSelector(roadworksSelectors.listData);
    const listMeta = useSelector(roadworksSelectors.listMeta);
    const loading = useSelector(roadworksSelectors.loading);
    const editForm = useSelector(roadworksSelectors.editForm || false);

    const [openModal, setOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = (isDelete) => {
        isDelete && listData?.length === 1 && listMeta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadRoadWorks({ page: params.page, limit, ...params.data }, true));
    };

    const handleCloseEditForm = () => {
        dispatch(setEditForm());
        setOpenModal(false);
    };

    useEffect(() => {
        dispatch(loadRoadWorks({ page: params.page, limit, ...params.data }, true));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return <>
            {listData?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: '', width: '50px' },
                            { title: titles.NAME, width: '20%' },
                            { title: titles.ADDRESS, width: 'calc(40% - 50px)' },
                            { title: 'Регистрация', width: '15%' },
                            { title: '№ Заявок', width: '15%' },
                            { title: 'Действия', align: 'right', isActions: true }
                        ]}
                    >
                        {listData.map((item) => (
                            <Item
                                key={item?.id}
                                item={item}
                                statuses={statuses}
                                typeCreation={typeCreation}
                                reloadList={reloadList}
                            />
                        ))}
                    </LSContainer>
                )
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>;
    };
 
    return (<>
        <PageLayout
            header="Участки"
            filters={<Filters setParams={setParams}/>}
            loading={loading}
            informPanelProps={{
                buttons: (
                    <>
                        <FormButtons 
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    </>),
                
                total: listMeta?.total
            }}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: listMeta,
                limit,
                setLimit
            }}
        />
        {openModal && (
            <EditorForm
                isOpen={openModal}
                noPadding
                title={titles.ADD}
                isNew={true}
                dataForm={editForm}
                onClose={handleCloseEditForm}
                onSuccess={(value) => {
                    dispatch(createNewRW(value, () => {
                        setOpenModal(false);
                        setParams({ page: 1, data: {} });
                    }));
                }}
                inModal
            />
        )}
    </>);
};

export default Sites;