import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import * as ecologyActions from 'redux/Ecology/actions';
import { ecologySelectors } from 'redux/Ecology';
import * as meteoActions from 'redux/Meteo/actions';
import { meteoSelectors } from 'redux/Meteo';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import messages from 'helpers/constants/messages';

import CompanyListModal from '../CompanyListModal';

const Organizations = ({
    item = {},
    microservice = 'ecology',
}) => {
    const config = {
        ecology: {
            actions: ecologyActions,
            selectors: ecologySelectors,
        },
        meteo: {
            actions: meteoActions,
            selectors: meteoSelectors,
        },
    };
    const currentConfig = config[microservice] || {};

    const dispatch = useDispatch();

    const companyLink = useSelector(currentConfig.selectors.companyLink);
    const loading = useSelector(currentConfig.selectors.loading);

    const [isOpenCompanyListModal, setIsOpenCompanyListModal] = useState(false);

    useEffect(() => {
        currentConfig?.actions?.getDeviceCompanyLink
            && dispatch(currentConfig?.actions?.getDeviceCompanyLink(item?.id));

        return () => {
            currentConfig?.actions?.clearCompanyLink
                && dispatch(currentConfig?.actions?.clearCompanyLink());
        };
    }, [dispatch, item?.id]);

    // можем показать список (не пустые организации)
    const isShowList = useMemo(() => {
        return companyLink.length
            ? companyLink.reduce((r, i) => r || !!i?.organization, false)
            : false;
    }, [companyLink]);

    let iterace = 0;

    return (
        <>
            <h2>{item?.name} - Связанные организации:</h2>

            {(loading && !isOpenCompanyListModal) && <Loading linear/>}

            {isShowList
                ? <List className="list">
                    {companyLink.map((elem) => {
                        if (!elem?.organization) return null;
                        return (
                            <ListItem
                                className="list_item"
                                key={elem.organization_id}
                            >
                                <ListItemIcon>
                                    {++iterace}.
                                </ListItemIcon>
                                <ListItemText>
                                    {elem?.organization?.title || ''}
                                </ListItemText>
                            </ListItem>
                        );
                    })}
                </List>
                : !loading && <div>{messages.NO_DATA}</div>
            }

            <FormButtons
                buttons={[
                    {
                        ...buttonsTypes.change,
                        onClick: () => setIsOpenCompanyListModal(true),
                        disabled: loading
                    },
                ]}
            />

            {isOpenCompanyListModal && (
                <CompanyListModal
                    isOpen={isOpenCompanyListModal}
                    onClose={() => setIsOpenCompanyListModal(false)}
                    microservice={microservice}
                    item={item}
                />
            )}
        </>
    );
};

export default Organizations;
