import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, ListItem, ListItemText } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import ConfirmDelete from 'components/common/ConfirmModal';

import Info from './Info';
import ModalForm from './ModalForm';
import type { DeviceGroup } from './types';

interface ItemProps {
    item: DeviceGroup;
    reloadList: Function;
    actions: { [key: string]: Function };
    storeName: 'meteo' | 'ecology';
    loadParams: Function;
}

const Item = ({ item, reloadList, actions, storeName, loadParams }: ItemProps) => {
    const dispatch = useDispatch();

    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isOpenInfo, setIsOpenInfo] = useState(false);

    const handleDelete = () => {
        const callback = () => {
            reloadList();
            setIsOpenDeleteModal(false);
        };

        dispatch(actions.delete(item.id, callback));
    };

    return (
        <>
            <ListItem
                style={{
                    background: isOpenInfo ? '#e5e5e5' : '',
                    borderRadius: 4,
                    cursor: 'pointer'
                }}
                className="list_item"
            >
                <ListItemText onClick={() => setIsOpenInfo((prev) => !prev)}>
                    <h2>{item.name}</h2>
                </ListItemText>

                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setIsOpenEditModal(true),
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setIsOpenDeleteModal(true),
                            disabled: item.is_default,
                        },
                    ]}
                    noMarginLeft
                    justButton
                />

                <IconButton
                    size={'small'}
                    onClick={() => setIsOpenInfo((prev) => !prev)}
                >
                    {isOpenInfo ? (
                        <ExpandLess fontSize={'inherit'} />
                    ) : (
                        <ExpandMore fontSize={'inherit'} />
                    )}
                </IconButton>
            </ListItem>

            {isOpenInfo && <Info isOpen={isOpenInfo} item={item} />}

            {isOpenEditModal && (
                <ModalForm
                    isOpen={isOpenEditModal}
                    onClose={() => setIsOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                    actions={actions}
                    storeName={storeName}
                    loadParams={loadParams}
                />
            )}

            {isOpenDeleteModal && (
                <ConfirmDelete
                    open={isOpenDeleteModal}
                    onClose={() => setIsOpenDeleteModal(false)}
                    onSuccess={handleDelete}
                    message={'Удалить'}
                    onlyClose={false}
                    loadingButton={false}
                />
            )}
        </>
    );
};

export default Item;
