import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadModelsList } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import messages from 'helpers/constants/messages';
import Context from 'helpers/context';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import PageLayout from 'components/layout/PageLayout';
import ModelListHeader from 'components/common/Transport/ModelList/Header';
import ModelFilters from 'components/common/Transport/ModelList/ModelFilters';

import ModelModal from './ModelModal';
import ModelItem from './ModelItem';

const Models = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const listData = useSelector(transportPassengerSelectors.modelsData);
    const listMeta = useSelector(transportPassengerSelectors.modelsMeta);
    const loading = useSelector(transportPassengerSelectors.loading);

    const [modalOpen, setModalOpen] = useState(false);
    const toggleOpen = () => setModalOpen(!modalOpen);
    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = (isDelete) => {
        isDelete && listData?.length === 1 && params.page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadModelsList(params.page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadModelsList(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return (
            <>
                {loading && <Loading circular={true}/>}
                {listData?.length > 0
                    ? <ModelListHeader>
                        {listData.map((item) => (
                            <ModelItem
                                key={item.id} 
                                item={item}
                                reloadList={reloadList}
                                permissions={permissions}
                            />
                        ))}
                    </ModelListHeader>
                    : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
                }
            </>
        );
    };

    return (
        <>  
            <PageLayout
                header="Модели"
                filters={<ModelFilters setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: toggleOpen,
                                        disabled: !permissions?.is_create
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: listMeta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: listMeta,
                    limit,
                    setLimit
                }}
            />
            {modalOpen && (
                <ModelModal
                    isNew={true}
                    toggleOpen={toggleOpen}
                    open={modalOpen}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default Models;
