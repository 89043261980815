import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import titles from '../../../../../helpers/constants/titles';
import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { useValidation } from '../../../../../helpers/hooks';
import {
    createUnit,
    editUnit,
} from '../../../../../redux/RoadWorks/actions';

function EditModal({ data, isNew, open, toggleOpen }) {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({ ...data });

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        dispatch(isNew
            ? createUnit(formData, toggleOpen)
            : editUnit(data.id, formData, toggleOpen)
        );
    };

    useEffect(() => {
        if (requestStatus.success) {
            toggleOpen();
            validation.clear();
        }
    }, [requestStatus.success, toggleOpen, validation]);

    return (
        <Modal
            isOpen={open}
            onClose={toggleOpen}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: toggleOpen
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <TextField
                    value={formData.long_name || ''}
                    name={'long_name'}
                    onChange={handleChange}
                    className="block"
                    label={'Полное название ЕИ'}
                    variant={'outlined'}
                    size={'small'}
                    required={true}
                    error={validation.isKey('long_name')}
                    helperText={validation.get('long_name')}
                />
                <TextField
                    value={formData.short_name || ''}
                    name={'short_name'}
                    onChange={handleChange}
                    className="block"
                    label={'Короткое название ЕИ'}
                    variant={'outlined'}
                    size={'small'}
                    required={true}
                    error={validation.isKey('short_name')}
                    helperText={validation.get('short_name')}
                />
            </form>
        </Modal>
    );
}

export default EditModal;