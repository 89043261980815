import SingleKeyboardDateTimePicker from '../../../../common/Dates/SingleKeyboardDateTimePicker';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import dateFormat from '../../../../../helpers/constants/dateFormat';
import { dateWithDashYYYYMMDD } from '../../../../../helpers/date.config';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import { FormHelperText, List } from '@mui/material';
import Item from './Item';
import styles from './styles.module.scss';
import { useValidation } from '../../../../../helpers/hooks';
import GetReports from '../../../../common/GetReports';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';
import { useMemo } from 'react';

const Filter = ({ 
    setParams, 
    params, 
    onChangeKdmList, 
    handleAddItem, 
    handleGetReport,
    onClickDelete,
    initFilter
}) => {
    const validation = useValidation();

    const handleChange = (name, value) => {
        setParams(prev => ({
            ...prev,
            [name]: value
        }));
    };
   
    const resetFilter = () => {
        setParams(initFilter.current);
    };

    const updateFilter = (filters) => {
        if (Object.keys(filters).length > 0) {
            setParams({ ...initFilter.current, ...filters });
        }
    };

    const worksIsValid = useMemo(() => params?.works?.every(el => (
        el.group_id && el.unit_id && el.work_id
    )), [params?.works]);

    const isDisabled = !params?.date || !params?.formats?.length || !worksIsValid;

    return (
        <LayoutFilter
            filter={params}
            onSearch={() => handleGetReport()}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            filterException={['formats', 'works',]}
            buttonType="getReport"
            disabled={isDisabled}
            isFilterVisible={true}
        >
            <LayoutFilter.Visible>
                <SingleKeyboardDateTimePicker
                    value={params.date}
                    onChange={(value) => handleChange('date', dateWithDashYYYYMMDD(value))}
                    pickersFormat={dateFormat.DATE_WITH_DASH}
                    required={true}
                    // dateOnly={true}
                />
                <SelectCompany 
                    multiple={true}
                    selected={params.organization_ids}
                    onChange={(value) => handleChange('organization_ids', value)}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                />
                <GetReports
                    selected={params.formats}
                    onChange={(value) => handleChange('formats', value)}
                    reportId={49}
                    required
                    helperText={validation.get('formats.0')}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <div className="grid-column-1-3">
                    <h2>Параметры для отчета:</h2>
                    <div>
                        <List className={styles.list_params}>
                            {params.works.map((item, i) => 
                                <Item 
                                    key={i} 
                                    index={i} 
                                    item={item} 
                                    onChangeKdmList={(value)=> onChangeKdmList(i, value)}
                                    onClickDelete={()=> onClickDelete(i)}
                                />
                            )}
                        </List>
                        {validation.get('works') && <FormHelperText error>{validation.get('works')}</FormHelperText>}
                        <FormButtons
                            positionLeft
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: handleAddItem,
                                }
                            ]}
                        />
                    </div>
                </div>
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;