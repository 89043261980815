import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'redux/Boards/actions';
import { boardsSelectors } from 'redux/Boards';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import ConfirmDelete from 'components/common/ConfirmModal';
import ModalForm from 'components/pages/Dictionaries/Boards/BoardAndSigns/Modal';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();
    const deleteForm = useSelector(boardsSelectors.deleteForm || false);
    const editForm = useSelector(boardsSelectors.editForm || false);
    const saved = useSelector(boardsSelectors.saved);

    const boardTypes = useStoreProp(
        actions.loadBoardTypes,
        'boards',
        'boardTypes'
    );

    const boardStatuses = useStoreProp(
        actions.loadBoardStatuses,
        'boards',
        'boardStatuses'
    );

    useEffect(() => {
        if (saved) {
            dispatch(actions.setSaved());
            dispatch(actions.setDeleteForm());
            dispatch(actions.setEditForm());
        }
    }, [saved]);

    return (
        <>
            {/* форма редактирования/добавления */}
            {editForm
                && <ModalForm
                    isOpen={!!editForm}
                    onClose={() => {
                        dispatch(actions.setEditForm());
                    }}
                    isNew={!editForm?.id}
                    el={editForm}
                    statuses={boardStatuses}
                    types={boardTypes}
                />
            }

            {/* удаление */}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!deleteForm}
                onSuccess={() => {
                    dispatch(actions.deleteBoard(
                        deleteForm,
                        () => dispatch(actions.setSaved(true))
                    ));
                    dispatch(actions.setDeleteForm());
                }}
                onClose={() => {
                    dispatch(actions.setDeleteForm());
                }}
            />
        </>
    );
};

export default CollectorForms;
