import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Item from './Item';
import Filter from './Filter';
import { loadPassengersCount } from '../../../../../redux/TransportPassenger/actions';
import { fullDateTimeWithTimeZone, getStartOf, dateNow } from '../../../../../helpers/date.config';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import PageLayout from '../../../../layout/PageLayout';
import NoData from '../../../../common/Information/NoData';
import { LSContainer } from '../../../../common/List';
import titles from '../../../../../helpers/constants/titles';

const PassengersCount = ({ check_point, title }) => {
    const dispatch = useDispatch();

    const list = useSelector(transportPassengerSelectors.passengersCountData);
    const meta = useSelector(transportPassengerSelectors.passengersCountMeta);
    const loading = useSelector(transportPassengerSelectors.loadingPassengersCount);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({
        page: 1,
        data: {
            date_start: fullDateTimeWithTimeZone(getStartOf()),
            date_end: fullDateTimeWithTimeZone(dateNow()),
            station_id_list: check_point ? [check_point?.id] : [],
        },
    });
    
    useEffect(() => {
        dispatch(loadPassengersCount(params.page, limit, params.data));
    }, [dispatch, limit, params.page, params.data]);

    return (
        <>
            <PageLayout
                header={`Отчет по пассажиропотоку ${title ? `по остановке ${title}` : ''}`}
                filters={<Filter check_point={check_point} setParams={setParams}/>}
                informPanelProps={{ total: meta?.total }}
                loading={loading}
                content={() => (
                    <>
                        {list?.length > 0
                            ? (
                                <LSContainer headers={[
                                    { title: titles.TITLE, width: '30%' },
                                    { title: titles.DIRECTION, width: '30%' },
                                    { title: 'Действия', isActions: true }
                                ]}>
                                    {list.map(item => (
                                        <Item
                                            key={item.id}
                                            item={item}
                                        />
                                    ))}
                                </LSContainer>
                            )
                            : !loading && <NoData/>
                        }
                    </>
                )}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit: limit,
                    setLimit: setLimit,
                }}
            />
        </>
    );
};

export default PassengersCount;
