import React from 'react';
import {
    TextField,
} from '@mui/material';
import useStoreProp from '../../../../../../helpers/hooks/useStoreProp';
import titles from '../../../../../../helpers/constants/titles';
import { Autocomplete } from '@mui/material';
import * as actions from '../../../../../../redux/Boards/actions';


const Filter = (props) => {
    const {
        data,
        onChange,
    } = props;

    const boardTypes = useStoreProp(
        actions.loadBoardTypes,
        'boards',
        'boardTypes'
    );

    const typeList = Object.keys(boardTypes).map((id) => ({ id, name: boardTypes[id] }));

    const handleEvent = (name) => (_, newValue) => {
        onChange({ [name]: newValue });
    };

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >

            <div className="block">
                <TextField
                    label={titles.NAME}
                    size="small"
                    value={data.name || ''}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                />
            </div>

            <div className="block">
                <TextField
                    label={titles.SERIAL_NUMBER}
                    size="small"
                    value={data.serial_number || ''}
                    variant="outlined"
                    name="serial_number"
                    type="text"
                    onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                />
            </div>

            {/*<div className="block">*/}
            {/*    <TextField*/}
            {/*        label={titles.ADDRESS}*/}
            {/*        size="small"*/}
            {/*        value={data.address || ''}*/}
            {/*        variant="outlined"*/}
            {/*        name="address"*/}
            {/*        type="text"*/}
            {/*        onChange={(e) => onChange({ [e.target.name]: e.target.value })}*/}
            {/*    />*/}
            {/*</div>*/}

            <div className="block">
                <Autocomplete
                    value={data?.type || null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={typeList}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={handleEvent('type')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.TYPE}
                        />
                    )}
                />
            </div>

        </form>
    );
};

export default Filter;
