import { clearFilters } from '../../../../redux/Incidents/actions';

const config = {
    name: 'Инфраструктура',
    slug: 'infrastructura',
    mapMarkersColors: {
        default: '#7e7e7e'
    },
    mapContextMenu: {
        title: 'Добавить инфраструктуру',
        event: 'add_infrastructura',
        // className: styles.button,
        minWidth: 210
    },
    onAfterActivateAction: () => clearFilters(),
    layerIcon: 'far fa-warehouse-alt',
};
// добавление на карте
export default config;
