import React, { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{ page: number; data: {} }>>;
}

function Filter({ setParams }: FilterProps) {
    const initialState = {
        name: '',
    };

    const [filter, setFilter] = useState(initialState);

    const handleChange = (name: keyof typeof initialState, value: any) => {
        setFilter((prev) => ({ ...prev, [name]: value }));
    };

    const handleSearch = () => {
        setParams({ page: 1, data: filter });
    };

    const handleReset = (needRefresh: boolean) => {
        setFilter(initialState);

        if (needRefresh) {
            setParams({ page: 1, data: initialState });
        }
    };

    const handleUpdate = (filters = {}) => {
        setFilter({ ...filter, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({
                page: 1,
                data: {
                    ...filter,
                    ...filters,
                },
            });
        }
    };

    return (
        <LayoutFilter
            filter={filter}
            onResetFilter={handleReset}
            onSearch={handleSearch}
            setUserFilter={handleUpdate}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={filter.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    label={titles.NAME}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
}

export default Filter;
