import { useState } from 'react';
import Modal from '../../../../../common/Modal';
import FormButtonsComponent, { buttonsTypes } from '../../../../../common/FormButtons';
import { 
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    TextField
} from '@mui/material';
import SelectBoards from '../../../../../common/Autocomplete/Boards/BoardList';
import { createMessageTemplateBoards } from '../../../../../../redux/Boards/actions';
import { useDispatch } from 'react-redux';
import removeEmptyFields from '../../../../../../helpers/removeEmptyFields';
import { useValidation } from '../../../../../../helpers/hooks';

interface TemplateProps {
    item: {
        id: number,
        count: number,
        duration: number,
    },
    open: boolean,
    onClose: () => void,
};

interface DataType {
    boards: Array<any>,
    template_id: number,
    count: number,
    duration: number
};

const AddMessageToBoard = ({ 
    item,
    open,
    onClose
}: TemplateProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const [data, setData] = useState<DataType>({
        boards: [],
        template_id: item?.id || 0,
        count: item?.count || 0,
        duration: item?.duration || 0
    });    
    const [selectBoards, setSelectBoards] = useState<boolean>(false);

    const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value === 'select') {
            setSelectBoards(true);
        } else {
            setSelectBoards(false);
            setData({
                ...data,
                boards: []
            });
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const onChange = (value: Array<object>) => {
        setData({
            ...data,
            boards: value
        });
    };

    const onSave = () => {
        const prepareData = {
            count: data.count,
            duration: data.duration,
            template_id: data.template_id,
            boards: data.boards.map((item): number => item.id),
        };

        const paramsWithoutEmpty = removeEmptyFields(prepareData);
        dispatch(createMessageTemplateBoards(paramsWithoutEmpty, onClose));
    };

    return (
        <Modal
            title={'Добавить сообщение на ДИТ'}
            onClose={onClose}
            noPadding
            isOpen={open}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.send,
                        disabled: false,
                        onClick: onSave
                    },
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl className="block">
                    <RadioGroup
                        row
                        onChange={handleChangeRadio}
                        defaultValue="all"
                    >
                        <FormControlLabel value="all" control={<Radio />} label="На все ДИТ" />
                        <FormControlLabel value="select" control={<Radio />} label="Выбрать ДИТ" />
                    </RadioGroup>
                </FormControl>
                <div className="block">
                    {selectBoards && <SelectBoards
                        selected={data.boards}
                        // @ts-ignore: error message
                        onChange={onChange}
                        multiple
                    />}
                </div>
                <div className="row">
                    <FormControl className="row__item" size="small" variant="outlined">
                        <TextField
                            label="Кол-во показов"
                            variant="outlined"
                            size="small"
                            value={data.count}
                            name="count"
                            onChange={handleChange}
                            type="number"
                            required
                            error={!data.count || validation.isKey('count')}
                        />
                    </FormControl>
                    <FormControl className="row__item" size="small" variant="outlined">
                        <TextField
                            label="Время показа в секундах"
                            variant="outlined"
                            size="small"
                            value={data.duration}
                            name="duration"
                            onChange={handleChange}
                            type="number"
                            required
                            error={!data.duration || validation.isKey('duration')}
                        />
                    </FormControl>
                </div>
            </div>
        </Modal>
    );
};

export default AddMessageToBoard;