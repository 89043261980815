import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteDefectGroup } from 'redux/RoadWorks/actions';
import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';
import ConfirmDelete from 'components/common/ConfirmModal';
import {
    LSContentItem,
    LSContentColumn,
    ActionsButtons,
} from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import AddModal from './AddModal';

const Item = ({ item, reloadList, permissions }) => {
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const toggleOpen = (e) => {
        e && e.stopPropagation();
        setModalOpen(!modalOpen);
    };

    const toggleDeleteOpen = (e) => {
        e && e.stopPropagation();
        setDeleteModalOpen(!deleteModalOpen);
    };

    const handleDelete = () => {
        dispatch(deleteDefectGroup(item.id, () => {
            reloadList(true);
            toggleDeleteOpen();
        }));
    };

    return (
        <>
            {modalOpen && (
                <AddModal
                    toggleOpen={toggleOpen}
                    open={modalOpen}
                    data={item}
                    reloadList={() => {
                        reloadList();
                        toggleOpen();
                    }}
                />
            )}
            <LSContentItem>
                <LSContentColumn>
                    {item?.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {fullDateTime(item.created_at) || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {fullDateTime(item.updated_at) || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: toggleOpen,
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: toggleDeleteOpen,
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {deleteModalOpen && (
                <ConfirmDelete
                    open={deleteModalOpen}
                    message={messages.CONFIRM_DELETE}
                    onClose={toggleDeleteOpen}
                    onSuccess={handleDelete}
                />
            )}
        </>
    );
};

export default Item;
