import * as types from './types';

const initialState = {
    governmentContractList: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    governmentContractById: {},
    loadingGovernmentContractById: false,
    governmentContractHistoryList: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    governmentContractLoading: false,
    loadingButton: false,
    loadingContractByName: false,
    contractLinkObjects: [],
    loadingLinkObjects: false,
    contractEntitiesTypes: []
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_GOVERNMENT_CONTRACTS:
            return {
                ...state,
                governmentContractList: payload
            };
        case types.LOADING_GOVERNMENT_CONTRACTS:
            return {
                ...state,
                governmentContractLoading: payload
            };

        case types.LOADED_GOVERNMENT_CONTRACT_BY_ID:
            return {
                ...state,
                governmentContractById: payload
            };

        case types.LOADING_GOVERNMENT_CONTRACT_BY_ID:
            return {
                ...state,
                loadingGovernmentContractById: payload
            };

        case types.LOADED_GOVERNMENT_CONTRACT_HISTORY:
            return {
                ...state,
                governmentContractHistoryList: payload
            };

        case types.LOADING_BUTTON:
            return {
                ...state,
                loadingButton: payload,
            };
        
        case types.LOADING_CONTRACT_ENTITIES_BY_NAME:
            return {
                ...state,
                loadingContractByName: payload,
            };

        case types.LOADED_CONTRACT_LINK_OBJECTS:
            return {
                ...state,
                contractLinkObjects: payload,
            };

        case types.LOADING_CONTRACT_LINK_OBJECTS:
            return {
                ...state,
                loadingLinkObjects: payload,
            };

        case types.LOADED_CONTRACT_ENTITIES_TYPES:
            return {
                ...state,
                contractEntitiesTypes: payload,
            };
                                    
        default:
            return state;
    }
}