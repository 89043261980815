import {
    FormControl,
    FormHelperText,
    IconButton,
    ListItem,
    ListItemText,
    Tooltip
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { fullDateTime } from '../../../../../helpers/date.config';
import { useStoreProp, useValidation } from '../../../../../helpers/hooks';
import { deleteCriticalSetting, loadCriticalSettingsKeys } from '../../../../../redux/Efficiency/actions';
import InputNumber from '../../../../common/Inputs/InputNumber';
import styles from './styles.module.scss';
import titles from '../../../../../helpers/constants/titles';
import CloseIcon from '@mui/icons-material/Close';

const Item = ({ item, reloadList, handleChange }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const keys = useStoreProp(loadCriticalSettingsKeys, 'efficiency', 'criticalSettingsKeys');

    const handleDelete = () => {
        dispatch(deleteCriticalSetting(item.id, reloadList));
    };

    return (
        <>
            <ListItem divider>
                <ListItemText disableTypography={true}>
                    <h3>{keys?.data?.[item?.key]}:</h3>
                    <div className={styles.item_row}>
                        <FormControl
                            // className="row__item"
                            variant="outlined"
                            className={styles.input}
                        >
                            <InputNumber
                                onChange={(e) => handleChange(e.target.value, item?.id)}
                                value={Number(item?.value)}
                                error={validation.isKey('value')}
                                helperText={`Обновлено: ${fullDateTime(item.updated_at)}`}
                            />
                            {validation.isKey('value')
                                && <FormHelperText error>{validation.get('value')}</FormHelperText>}
                        </FormControl>
                        <Tooltip title={titles.DELETE}>
                            <IconButton onClick={handleDelete}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </ListItemText>
            </ListItem>
        </>
    );
};

export default Item;