import { 
    Checkbox, 
    FormControl, 
    FormControlLabel, 
    FormHelperText, 
    IconButton, 
    InputAdornment, 
    InputLabel, 
    MenuItem, 
    Select, 
    TextField 
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { useValidation } from '../../../../../../../../helpers/hooks';
import TransferTypeForm from './TransferTypeForm';
import { useSelector } from 'react-redux';
import { dorisControlSelectors } from '../../../../../../../../redux/DorisControl';

const RenderFields = ({ item, data, setData, currentComplex }) => {
    const validation = useValidation();
    const entities = useSelector(dorisControlSelectors.entities);
    const [showPassword, setShowPassword] = useState(false);

    const onChangeTextField = (e) => {
        const { name, value } = e.target;
        // TODO поставить проверку если это number то удалять все минусы и плюсы
        // type === 'number ?  value < 0 ? 0 : Number(value)  : value
        setData({
            ...data, 
            [name]: value
        });
        validation.deleteKey(name);
    };

    const onChangeSelect = (e) => {
        const { name, value } = e.target;

        if(item.fields?.[data[item.field]]?.length > 0) {
            item?.fields[data?.[item.field]].forEach( el => (
                delete data[el.field]
            ));
        }

        setData({
            ...data, 
            [name]: value
        });
        validation.deleteKey(name);
    };

    const renderTextField = (el) => (
        <TextField
            key={el.field}
            className="block"
            size="small"
            variant="outlined"
            label={el.name}
            value={data?.[el.field] || ''}
            disabled={item?.field === 'serial' && Number(data?.entity_id) === 3} // ВМ поле серийный 
            onChange={onChangeTextField}
            name={el.field}
            type={el.type}
            required={el.require}
            error={(!data?.[el.field] && el?.require) || validation.isKey(el.field)}
            helperText={validation.get(el.field)}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        { el?.type === 'password' && (
                            <IconButton size="small" onClick={ () => setShowPassword(!showPassword) }>
                                { showPassword ? <Visibility/> : <VisibilityOff/> }
                            </IconButton>)
                        }
                    </InputAdornment>
                )
            }}
            inputProps={{ min: 0 }}
        />
    );

    // преобразуем объект с массивами в плоский массив обзорныз камер и ВМ
    // TODO это затем нужно в отдельных коипонентах для конкретного оборудования
    const entityList = Object.entries(entities).reduce((res, [entity_id, el]) => {
        if (entity_id === '5') {
            res['overview_camera_id'] = el;
        }
        if (entity_id === '3') {
            res['computer_id'] = el;
        }
        return res;
    }, {});

    if (item?.field === 'complex_id' 
        || item?.field === 'entity_id'
        || item?.field === 'lat' 
        || item?.field === 'lon' 
        || item.type === 'array' 
        || item.type === 'radio'
    ) {
        return null;
    }
    if (item?.field === 'transfer_type' && +data?.entity_id === 3 )  { // Вычислительный модуль
        return <TransferTypeForm
            data={data}
            setData={setData}
            currentComplex={currentComplex}
        />;
    }
    if (item?.type === 'boolean') {
        return (
            <div className="row align-items-center">
                <Checkbox
                    checked={data[item.field] || false}
                    onChange={(e) => setData({ ...data, [item.field]: e.target.checked })}
                    name={item.field}
                    variant="outlined"
                />
                <div>{item.name}</div>
                {validation.isKey(item.field) && (
                    <FormHelperText error>{validation.get(item.field)}</FormHelperText>
                )}
            </div>
        );
    }
    if (item?.field === 'overview_camera_id' || item?.field === 'computer_id') {
        return (
            entityList[item?.field]?.length > 0
                ?  <>
                    <FormControl
                        className="block" 
                        size="small" 
                        variant="outlined" 
                        required={item.require}
                        error={(!data?.[item.field] && item?.require) || validation.isKey(item.field)}
                    >
                        <InputLabel>{item?.name}</InputLabel>
                        <Select
                            value={data?.[item.field] || ''}
                            label={item?.name}
                            onChange={onChangeSelect}
                            name={item.field}
                        >
                            {entityList[item?.field]?.map((el) => (
                                <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                            ))}
                        </Select>
                        {validation.isKey(item.field) && (
                            <FormHelperText error>{validation.get(item.field)}</FormHelperText>
                        )}
                    </FormControl>
    
                    {item.fields?.[data[item.field]]?.length > 0 
                        && item?.fields[data?.[item.field]].map((field) => (
                            renderTextField(field)
                        ))
                    }
                </>
                : null
        );
    } else if (item?.select) {
        return (
            <>
                <FormControl
                    className="block" 
                    size="small" 
                    variant="outlined" 
                    required={item.require}
                    error={(!data?.[item.field] && item?.require) || validation.isKey(item.field)}
                >
                    <InputLabel>{item?.name}</InputLabel>
                    <Select
                        value={data?.[item.field] || ''}
                        label={item?.name}
                        onChange={onChangeSelect}
                        name={item.field}
                    >
                        {Object.keys(item.select)?.map((key) => (
                            <MenuItem key={key} value={key}>{item.select[key]}</MenuItem>
                        ))}
                    </Select>
                    {validation.isKey(item.field) && (
                        <FormHelperText error>{validation.get(item.field)}</FormHelperText>
                    )}
                </FormControl>

                {item.fields?.[data[item.field]]?.length > 0 
                    && item?.fields[data?.[item.field]].map((field) => (
                        renderTextField(field)
                    ))
                }
            </>
        );
    } else {
        return (
            renderTextField(item)
        );
    }
};

export default RenderFields;
