import React from 'react';
import {
    IconButton,
    TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AlignPosition, PaddingPosition,  } from './ModalForm/Fields';
import SelectSigns from '../../../../common/Autocomplete/Boards/Signs';
import useValidation from '../../../../../helpers/hooks/useValidation';

const RenderSign = ({ item, onChange, onDelete }) => {
    const { 
        size = '90', 
        sign = null, 
        x = 0, 
        y = 0, 
        'align-x': alignX = 'left',
        'align-y': alignY = 'center',
    } = item;
    const validation = useValidation();

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        onChange({ 
            ...item, 
            [name]: type === 'number' 
                ? value < 0 ? 0 : Number(value)
                : value  
        });
    };

    return (
        <div className="row align-items-center">
            <div className="row__item">
                <div className="block row">
                    <div className="row__item">
                        <SelectSigns
                            selected={sign}
                            required
                            onChange={(value) => onChange({ ...item, sign: value })}
                            error={!sign || validation.isKey(`text.${item.messageIndex}.sign`)}
                            helperText={validation.get(`text.${item.messageIndex}.sign`)}
                        />
                    </div>
                    <TextField
                        value={size}
                        onChange={handleChange}
                        label="Высота в % от высоты ДИТ"
                        name="size"
                        required
                        size="small"
                        type="number"
                        className="row__item"
                        inputProps={{ min: 0 }}
                        error={size === undefined || validation.isKey(`text.${item.messageIndex}.size`)}
                        helperText={validation.get(`text.${item.messageIndex}.size`)}
                    />
                </div>
                <PaddingPosition x={x} y={y} handleChange={handleChange} />
                <AlignPosition alignX={alignX} alignY={alignY} handleChange={handleChange} />
            </div>
            <IconButton onClick={onDelete} size="small">
                <CloseIcon />
            </IconButton>
        </div>
    );
};

export default RenderSign;