import * as types from './types';

/**
 * Actions
 **/
export const loadMenuData = () => ({
    type: types.LOAD_MENU
});

export const loading = (bool) => ({
    type: types.LOADING,
    payload: bool
});

export const loadedMenuData = (data) => ({
    type: types.LOADED,
    payload: data
});

export const getActiveMenuId = (id) => ({
    type: types.MENU_GET_ID,
    payload: id
});

export const saveActiveLayer = (layer) => ({
    type: types.SAVE_ACTIVE_LAYER,
    payload: layer
});
export const addActiveLayer = (layer) => ({
    type: types.ADD_ACTIVE_LAYER,
    payload: layer
});

export const setSelectedLayer = (layer = null) => ({
    type: types.SELECT_LAYER,
    payload: layer
});

export const addAttachLayers = (layers = []) => ({
    type: types.ADD_ATTACH_LAYERS,
    payload: layers
});


export const setShowReturnTo = (link = '', buttonText = '', search = '') => ({
    type: types.SET_SHOW_RETURN_TO,
    payload: link?.length > 0 && buttonText?.length > 0
        ? {
            link,
            buttonText,
            search,
        }
        : {},
});
