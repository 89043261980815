import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemText, Typography } from '@mui/material';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import { appointWorkRequest, changeWorkRequestStatus, deleteWorkRequest } from 'redux/WorkRequests/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';
import StatusModal from './StatusModal';
import AppointedModal from './AppointedModal';

const Item = ({ item, statuses, loadList, permissions, userId }) => {
    const dispatch = useDispatch();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [openAppointedModal, setOpenAppointedModal] = useState(false);

    const colorStatus = (type) => {
        const statuses = {
            1: '#777',
            2: '#31bebe',
            3: '#2e4ac4',
            4: '#00ae5c',
            5: '#ad1818',
            default: '#000'
        };
        const defaultConfig = statuses.default;
        return statuses[type] || defaultConfig;
    };

    const toggleChangeStatus = (e) => {
        e.stopPropagation();
        setOpenStatusModal(!openStatusModal);
    };

    return (
        <>
            <ListItem divider >
                <ListItemText>
                    <Typography variant="subtitle2">
                        <strong>
                            {titles.NAME}:
                        </strong>&nbsp;
                        {item.name || messages.NO_DATA}
                    </Typography>
                    <Typography variant="subtitle2">
                        <strong>
                            {titles.STATUS}:
                        </strong>&nbsp;
                        <span style={{ color: colorStatus(item.status) }}>{statuses[item.status] || messages.NO_DATA}</span>
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.changeStatusIcon,
                                    onClick: toggleChangeStatus,
                                    style: { color: colorStatus(item.status) }
                                },
                            ]}
                            noMarginLeft
                            justButton
                        />
                    </Typography>
                    <Typography variant="body2">
                        <strong>
                            {titles.ORGANIZATION}:
                        </strong>&nbsp;
                        {item.organization?.title || messages.NO_DATA}
                    </Typography>
                    <Typography variant="body2">
                        <strong>
                            Исполнитель:
                        </strong>&nbsp;
                        {item.appointed?.id
                            ? <span>
                                {item.appointed?.first_name || ''}{' '}
                                {item.appointed?.middle_name || ''}{' '}
                                {item.appointed?.last_name || ''}
                            </span>
                            : messages.NO_DATA
                        }
                    </Typography>
                    <Typography variant="body2">
                        <strong>
                            Плановая дата выполнения:
                        </strong>&nbsp;
                        {fullDateTimeWithoutSeconds(item?.date_plan) || messages.NO_DATA}
                    </Typography>
                    <Typography variant="body2">
                        <strong>
                            Фактическая дата выполнения:
                        </strong>&nbsp;
                        {fullDateTimeWithoutSeconds(item?.date_fact) || messages.NO_DATA}
                    </Typography>
                </ListItemText>
                <FormButtonsComponent
                    buttons={[
                        ...(item.status !== 5
                            ? [{
                                icon: <i className="fas fa-address-card" />,
                                name: 'Назначить исполнителя',
                                onClick: () => setOpenAppointedModal(true),
                                accessProp: 'is_update',
                            }]
                            : []
                        ),
                        ...((item.status === 1 && item.created_by === userId)
                            ? [{
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            }]
                            : []
                        ),
                        ...(item.created_by === userId
                            ? [{
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            }]
                            : []
                        ),
                    ]}
                    noMarginLeft
                    justButton
                />
            </ListItem>
            {openEditModal
            && <ModalForm
                item={item}
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
                loadList={loadList}
            />}
            {openDeleteModal
            && <ConfirmDelete
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onSuccess={() => {
                    dispatch(deleteWorkRequest(item.id, loadList));
                    setOpenDeleteModal(false);
                }}
                message={'Заявка будет удален, Вы уверены?'}
            />}
            {openStatusModal && (
                <StatusModal
                    open={openStatusModal}
                    onClose={() => setOpenStatusModal(false)}
                    onSave={(value) => dispatch(changeWorkRequestStatus(item.id, value))}
                    item={item}
                    statuses={statuses}
                    loadList={loadList}
                />
            )}
            {openAppointedModal && (
                <AppointedModal
                    open={openAppointedModal}
                    onClose={() => setOpenAppointedModal(false)}
                    onSave={(value) => dispatch(appointWorkRequest(item.id, value))}
                    item={item}
                    loadList={loadList}
                />
            )}
        </>
    );
};

export default Item;
