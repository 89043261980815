import React, { useContext, useEffect, useState } from 'react';
import Filter from './Filter';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { 
    loadAlertTemplates,
} from '../../../../../redux/Boards/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../common/Loading';
import { List } from '@mui/material';
import CustomPagination from '../../../../common/CustomPagination';
import Item from './Item';
import messages from '../../../../../helpers/constants/messages';
import ModalForm from './AddEdit/ModalForm';
import Context from '../../../../../helpers/context';
import { boardsSelectors } from '../../../../../redux/Boards';

const AlertTemplates = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);
    const [page, setPage] = useState(1);
    const [params, setParams] = useState({});
    const [isOpenModalForm, setIsOpenModalForm] = useState(false);

    const alertTemplates = useSelector(boardsSelectors.alertTemplates);
    const loadingAlertTemplates = useSelector(boardsSelectors.loadingAlertTemplates);

    useEffect(() => {
        dispatch(loadAlertTemplates({ page, limit, ...params }));
    }, [params, limit, page, dispatch]);

    return (
        <div>
            {isOpenModalForm && (
                <ModalForm
                    isOpen={isOpenModalForm}
                    onClose={() => setIsOpenModalForm(false)}
                    isNew={true}
                    reloadData={() => {
                        setIsOpenModalForm(false);
                        dispatch(loadAlertTemplates({ page: 1, limit, ...params }));
                    }}
                />
            )}
            <h1>Шаблоны увеломлений для смежных систем</h1>
            <Filter setParams={setParams} />
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setIsOpenModalForm(true),
                            // disabled: !permissions?.is_create
                        }
                    ]}
                />
            </div>
            {loadingAlertTemplates && <Loading linear={true}/>}
            <List className="list">
                {alertTemplates.data?.length > 0
                    ? (<>
                        {alertTemplates?.data
                            ?.map(el => <Item
                                el={el}
                                key={el.id}
                                reloadData={() => dispatch(loadAlertTemplates({ page, limit, ...params }))}
                            />)}
                        <CustomPagination
                            loadList={(page, limit) => setPage(page)}
                            list={alertTemplates.meta}
                            limit={limit} 
                            setLimit={setLimit}
                        />
                    </>)
                    : (!loadingAlertTemplates && <div>{messages.DATA_IS_NOT_FOUND}</div>)
                }
            </List>
        </div>
    );
};

export default AlertTemplates;