import React, { useState } from 'react';
import { IconButton, Link, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import titles from '../../../../helpers/constants/titles';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDelete from '../../ConfirmModal';
import messages from '../../../../helpers/constants/messages';

const Item = ({ item, index, onDelete = () => {}, readOnly, renderContent, itemTitle, divider = true }) => {
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        onDelete(index);
        setIsDelete(false);
    };
    const title = itemTitle ? itemTitle :  index >= 0 ? `${index + 1}.` : '';
    return (
        <ListItem divider={divider} dense>
            <ListItemIcon sx={{ minWidth: 'unset', mr: '5px' }}>
                {title}
            </ListItemIcon>
            <ListItemText>
                { typeof item === 'object' && item !== null
                    ? renderContent
                        ? renderContent(item)
                        : <Link href={item.public_link} target="_blank">{item.name}</Link>
                    : <Tooltip title={titles.CLICK_TO_OPEN}>
                        <IconButton size={'small'}>
                            <Link href={item} target="_blank"><i className="fal fa-file-alt"/> {titles.DOCUMENT}</Link>
                        </IconButton>
                    </Tooltip>
                }
            </ListItemText>
            {!readOnly && (
                <Tooltip title={titles.DELETE}>
                    <IconButton onClick={() => setIsDelete(true)} size={'small'}>
                        <DeleteIcon fontSize={'inherit'}/>
                    </IconButton>
                </Tooltip>
            )}
            { isDelete
                && <ConfirmDelete
                    open={isDelete}
                    onClose={()=> setIsDelete(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                />
            }
        </ListItem>
    );
};

export default Item;