import Image from 'components/common/Image';

import styles from './complexEventItem.module.scss';

const Preview = ({ data, onOpenMedia }) => {
    const thumb_img = data.photo_ts_thumb || null;
    const grzPreView =data.photo_grz || null;

    return (
        <div className={styles.picture}>
            <Image
                onClick={onOpenMedia}
                src={thumb_img}
                alt="grz"
            />
            {grzPreView
                ? <Image
                    src={grzPreView}
                    alt="grz"
                    className={styles.grz}
                />
                : (data.grz
                    && <div className={styles.grz} style={{
                        background: '#fff',
                        border: '1px solid',
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                        padding: '0 0 10px 0',
                    }}>
                        {data.grz}
                    </div>
                )
            }
            {data.vehicle_speed && <div className={styles.speed}>{data.vehicle_speed}</div>}
        </div>
    );
};

export default Preview;
