import Overlap from '../../pages/Dictionaries/Overlap';

const routes = [
    {
        path: '/overlap',
        component: Overlap,
        exact: true,
    },
];

export default routes;