import Devices from '../../pages/Dictionaries/Ecology/Devices';
import EcologySettings from '../../pages/Dictionaries/Ecology/Settings';
import EcologyOutputSettings from '../../pages/Dictionaries/Ecology/OutputSettings/index.tsx';


const routes = [
    {
        path: '/dictionaries/ecology/devices',
        component: Devices,
        exact: true
    },
    {
        path: '/dictionaries/ecology/settings',
        component: EcologySettings,
        exact: true
    },
    {
        path: '/dictionaries/ecology/output-settings',
        component: EcologyOutputSettings,
        exact: true
    }
];

export default routes;