import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadWantedEvents } from 'redux/WantedCars/actions';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';

import Filter from './Filter';
import Item from './Item';

const initialFilter = {
    start_date: getStartOf(),
    end_date: getEndOf(),
    query: [],
    vehicle_brand: '',
    vehicle_model: '',
    vehicle_color: ''
};

const WantedCarsEvents = () => {
    const dispatch = useDispatch();

    const { wantedEvents, eventLoading } = useSelector(({ carsWanted }) => carsWanted);
    const { data = [], meta = {} } = wantedEvents;

    const [params, setParams] = useState({ page: 1, filter: initialFilter });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    
    useEffect(() => {
        const { page, filter } = params;

        const prepareData = {
            ...filter,
            start_date: fullDateTimeWithTimeZone(filter.start_date),
            end_date: fullDateTimeWithTimeZone(filter.end_date),
        };

        dispatch(loadWantedEvents(page, limit, removeEmptyFields(prepareData)));
    }, [params, limit, dispatch]);

    return (
        <PageLayout
            header="Журнал мониторинга розыска"
            filters={ <Filter setParams={setParams} initialFilter={initialFilter} /> }
            informPanelProps={{ total: meta?.total }}
            loading={eventLoading}
            content={() => (
                data?.length > 0
                    ? <LSContainer>
                        {data?.map(item => <Item data={item} key={item?.uuid} />)}
                    </LSContainer>
                    : !eventLoading && <NoData/>
            )}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: meta,
                limit: limit,
                setLimit: setLimit,
            }}
            customStyles={{ padding: 0 }}
        />
    );
};

export default WantedCarsEvents;
