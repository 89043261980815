import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/TransportRoad/actions';
import { useEffect, useState } from 'react';
import { usePrevious } from '../index';
import { messageTypes, showMessage } from '../../../redux/Message/actions';
import { transportRoadSelectors } from '../../../redux/TransportRoad';

// хук истории
const useHistory = () => {
    const dataInitial = [];
    const dispatch = useDispatch();

    const history = useSelector(transportRoadSelectors.history);
    const historyLoading = useSelector(transportRoadSelectors.historyLoading);
    const prevHistoryLoading = usePrevious(historyLoading);

    const [data, setData] = useState(dataInitial);
    const [isLoaded, setIsLoaded] = useState(false);
    const [id, setId] = useState(-1);

    useEffect(() => {
        dispatch(actions.clearHistoryVehicle());

        return () => {
            dispatch(actions.clearHistoryVehicle());
            setData(dataInitial);
        };
    },[]);

    useEffect(() => {
        if (
            historyLoading === false
            && prevHistoryLoading === true
        ) {
            const data = history[id] || [];

            if (data.length === 0) {
                dispatch(showMessage( messageTypes.error, '', 'История ТС не найдена'));
            }

            setData(data);
            setIsLoaded(true);
            dispatch(actions.clearHistoryVehicle());
            // setId(-1);
        }
    },[history, historyLoading]);

    // загрузить
    const current = (vehicleId, params = {}) => {
        setId(vehicleId);
        setIsLoaded(false);
        setData(dataInitial);
        dispatch(actions.loadHistoryVehicle(vehicleId, params));
    };

    // данные
    current.data = data;

    // id
    current.id = id;

    // загрузка
    current.loading = historyLoading;

    // данные загружены
    current.isLoaded = isLoaded;

    // очистить
    current.clear = () => {
        setData(dataInitial);
        setIsLoaded(false);
        setId(-1);
    };

    return current;
};

export default useHistory;
