import React from 'react';
import {
    TreeView,
    TreeItem,
} from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { LSContainer } from '../../../../../common/List';
import titles from '../../../../../../helpers/constants/titles';

const Tree = ({
    expanded,
    selected,
    onNodeSelect,
    list,
    itemComponent,
}) => {
    return (
        <TreeView
            expanded={expanded}
            selected={selected}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeSelect={onNodeSelect}
        >
            {/* регионы */}
            {list?.map(({ region, data: regionData = [] }, regionIndex) => (
                <TreeItem
                    key={regionIndex}
                    nodeId={`${regionIndex + 1}`}
                    label={region || 'Нет региона'}
                    sx={{ padding: '.3rem 0' }}
                >
                    {/* контракты */}
                    {regionData?.map(({ contract, complexes = [] }, contractIndex) => (
                        <TreeItem
                            key={contractIndex}
                            nodeId={`${regionIndex + 1}_${contractIndex + 1}`}
                            label={
                                // /^[0-9]+$/.test(contract)
                                parseInt(contract) > 0
                                    ? `ГК №${contract}`
                                    : contract
                            }
                            sx={{ padding: '.5rem 0' }}
                        >
                            {/* комплексы */}
                            {itemComponent(complexes)}
                            {/* <LSContainer
                                headers={[
                                    { title: titles.NAME, width: '30%' },
                                    { title: titles.SERIAL_NUMBER, width: '20%' },
                                    { title: titles.STATUS, width: '10%' },
                                    { title: 'Сертификат поверки', width: '30%'  },
                                    { title: 'Действия', align: 'right', isActions: true }
                                ]}
                            >
                                {complexes?.map(oneComplex => (itemComponent(oneComplex)))}
                                {complexes?.map(oneComplex => (
                                    <TreeItem
                                        // style={{ padding: 0 }}
                                        key={oneComplex?.id}
                                        nodeId={`${regionIndex + 1}_${contractIndex + 1}_${oneComplex?.id}`}
                                        label={itemComponent(oneComplex)}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    />
                                ))}
                            </LSContainer> */}
                        </TreeItem>
                    ))}
                </TreeItem>
            ))}
        </TreeView>
    );

};

export default Tree;