import DateRange from '../../../../common/Dates/DateRange';
import {
    FormControl,
    TextField,
} from '@mui/material';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';
import formatDate from '../../../../../helpers/constants/dateFormat';
import { getEndOf, getStartOf } from '../../../../../helpers/date.config';
import { useEffect, useState } from 'react';

const Filter = ({ setFilter }) => {

    const initialState = {
        start_date: getStartOf('year'),
        end_date: getEndOf('day'),
        road_name: '',
    };
    const [data, setData] = useState(initialState);

    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        setFilter(initialState);
    }, []);

    const updateFilter = (filters) => {
        setData({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            setFilter({
                ...initialState,
                ...filters,
            });
        }
    };

    const resetFilter = (needRefresh) => {
        if (needRefresh) {
            setFilter(initialState);
        }
        setData(initialState);
    };

    const handleSetData = (key) => (value) => setData(old => ({ ...old, [key]: value }));

    const onSearch = () => {
        setFilter({
            ...initialState,
            ...data,
        });
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={onSearch}
            disabled={isDisabled}
            filter={data}
            filterException={['start_date', 'end_date',]} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={newDate => handleSetData('start_date')(newDate)}
                    handleEndDate={newDate => handleSetData('end_date')(newDate)}
                    valueStartDate={data.start_date}
                    valueEndDate={data.end_date}
                    isDisabledHandler={(value) => setIsDisabled(value)}
                    dateOnly={true}
                    pickersFormat={formatDate.DATE_FOR_PICKER}
                />

                <FormControl size="small" variant="outlined">
                    <TextField
                        size="small"
                        value={data.road_name}
                        onChange={ (e) => handleSetData('road_name')(e.target.value)}
                        label="Название автомобильной дороги"
                    />
                </FormControl>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
