import { useState } from 'react';
import formatDate from '../../../../helpers/constants/dateFormat';
import SelectVehicles from '../../../common/Autocomplete/Transport/Vehicles';
import DateRange from '../../../common/Dates/DateRange';
import LayoutFilter from '../../../layout/PageLayout/LayoutFilter';

const Filter = ({ initialData, onAccept, fields, setFields }) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const resetFilter = (needRefresh) => {
        if (needRefresh) {
            onAccept(initialData);
        }
        setFields(initialData);
    };

    const handleChangeDate = (value, name) => {
        setFields({
            ...fields, [name]: value
        });
    };

    const errorDateRange = () => {
        const {
            start_date,
            end_date,
        } = fields;

        return !start_date || !end_date;
    };

    const updateFilter = (filters) => {
        setFields({
            ...initialData,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            onAccept({ // отправляем в родителя, чтобы произошел запрос
                ...initialData,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={() => onAccept(fields)}
            disabled={isDisabled}
            filter={fields} 
            filterException={['start_date', 'end_date',]} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(newDate) => {
                        handleChangeDate(newDate, 'start_date');
                    }}
                    handleEndDate={(newDate) => {
                        handleChangeDate(newDate, 'end_date');
                    }}
                    valueStartDate={fields?.start_date}
                    valueEndDate={fields?.end_date}
                    isDisabledHandler={(value) => setIsDisabled(value)}
                    renderError={errorDateRange}
                    pickersFormat={formatDate.DATE_FOR_PICKER}
                    dateOnly
                    required
                />

                <SelectVehicles
                    onChange={(value) => {
                        handleChangeDate(value, 'vehicle_id_list');
                    }}
                    selected={fields.vehicle_id_list || []}
                    multiple
                    storeName="transportPassenger"
                    limitTags={1}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
