import passengerTransport from './transportPassenger';
import roadworkTransport from './transportRoad';
import specialTransport from './transportSpecial';

const microservices = {
    passengerTransport,
    roadworkTransport,
    specialTransport,
};

export default microservices;
