import { memo } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

export const PrioritySelect = memo((props) => {
    const { value, priorities, onChange, validation } = props;

    return (
        <FormControl required className="block" size="small" variant="outlined" error={!value || !!validation.isKey('priority')}>
            <InputLabel>Приоритет</InputLabel>
            <Select
                value={value}
                required
                onChange={onChange}
                label="Приоритет"
                name="priority"
                error={!value || !!validation.isKey('priority')}
            >
                {Object.keys(priorities)?.map((key) => (
                    <MenuItem key={key} value={key}>{priorities[key]}</MenuItem>
                ))}
            </Select>
            {validation.isKey('priority')
                && <FormHelperText error>{validation.get('priority')}</FormHelperText>}
        </FormControl>
    );
});