import React, { useState } from 'react';
import titles from '../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import Modal from '../../../common/Modal';
import { IconButton, TextField } from '@mui/material';
import buttons from '../../../../helpers/constants/buttons';
import Attachments from '../../../common/Upload/Attachments';
import CloseIcon from '@mui/icons-material/Close';
import { useValidation } from '../../../../helpers/hooks';
import { useDispatch } from 'react-redux';
import { createFiliation, editFiliation, loadCurrentFiliationList } from '../../../../redux/Companies/actions';
import { reduce } from 'lodash';
import FormButtonsComponent from '../../../common/FormButtons';

const formatObj = (obj = {}) => {
    if (Array.isArray(obj)) return obj;
    return Object.keys(obj).map((key) => {
        return { person: key, data: obj[key] };
    });
};

function BranchModal({ onClose, isOpen, isNew, organizationId, data }) {
    const dispatch = useDispatch();
    const validation = useValidation();

    const [formValues, setFormValues] = useState({
        organization_id: organizationId,
        title: data?.title || '',
        short_title: data?.short_title || '',
        phones: formatObj(data?.phones),
        emails: formatObj(data?.emails),
        appointment: data?.appointment || null,
        address: {
            city_name: data?.address?.city_name || '',
            region: data?.address?.region || '',
            postal_code: data?.address?.postal_code || '',
            street: data?.address?.street || '',
            house: data?.address?.house || '',
            comments: data?.address?.comments || ''
        },
    });

    const handleAddArrItem = (section) => () => {
        setFormValues({ ...formValues, [section]: [...formValues[section], {}] });
    };

    const removeItemFromArray = (section, index) => () => {
        setFormValues({
            ...formValues,
            [section]: [
                ...formValues[section].slice(0, index),
                ...formValues[section].slice(index + 1),
            ],
        });
    };

    const handleChangeItem = (section, index) => (event) => {
        const { name, value } = event.target;
        const newArr = [...formValues[section]];
        newArr[index] = { ...newArr[index], [name]: value };
        setFormValues({ ...formValues, [section]: newArr });
    };

    const renderDataArr = (label, name) => {
        return formValues[name]?.map(({ person, data }, index) => {
            const handleChange = handleChangeItem(name, index);
            return (
                <div key={index} className="row">
                    <TextField
                        value={person || ''}
                        name={'person'}
                        onChange={handleChange}
                        className="row__item"
                        label={'Ответственный'}
                        variant={'outlined'}
                        size={'small'}
                    />
                    <TextField
                        value={data || ''}
                        name={'data'}
                        onChange={handleChange}
                        className="row__item"
                        label={label}
                        variant={'outlined'}
                        size={'small'}
                    />
                    <IconButton onClick={removeItemFromArray(name, index)} size={'small'}>
                        <CloseIcon />
                    </IconButton>
                </div>
            );
        });
    };

    const handleChange = (e, isAddress) => {
        const { name, value } = e?.target;
        if (isAddress) {
            validation.deleteKey('address');
            return setFormValues({
                ...formValues,
                address: {
                    ...formValues.address,
                    [name]: value
                }
            });
        }
        setFormValues({ ...formValues, [name]: value });
        validation.deleteKey(name);
    };

    const handleChangeAppointment = (file) => {
        setFormValues({ ...formValues, appointment: file });
        validation.deleteKey('appointment');
    };

    const reloadData = () => {
        onClose();
        dispatch(loadCurrentFiliationList(1, 25, { organization_id: organizationId }));
    };

    const onSave = () => {
        const formatArr = (arr) => reduce(arr, (res, el) =>  {
            return { ...res, [el.person]: el.data };
        }, {});
        const result = {
            ...formValues,
            phones: formatArr(formValues.phones),
            emails: formatArr(formValues.emails),
        };

        isNew
            ? dispatch(createFiliation(result, reloadData))
            : dispatch(editFiliation(data?.id, result, reloadData));
    };

    const saveDisable = !formValues.title
        || !formValues.short_title
        || !formValues.appointment
        || !formValues.address.city_name
        || !formValues.address.street
        || !formValues.address.house;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            title={isNew ? titles.ADD_BRANCH : titles.EDIT_BRANCH}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: saveDisable,
                        onClick: onSave,
                    },
                ]}
            />}
        >
            <form className="modal__form">
                <TextField
                    onChange={handleChange}
                    className="block"
                    size="small"
                    variant="outlined"
                    label="Полное наименование"
                    name="title"
                    required
                    value={formValues.title}
                    error={!formValues?.title || validation.isKey('title')}
                    helperText={validation.get('title')}
                />
                <TextField
                    required
                    onChange={handleChange}
                    className="block"
                    size="small"
                    variant="outlined"
                    label="Краткое наименование"
                    name="short_title"
                    value={formValues.short_title}
                    error={!formValues?.short_title || validation.isKey('short_title')}
                    helperText={validation.get('short_title')}
                />
                <div className="block">
                    <Attachments
                        onlyLink={true}
                        singleFile={true}
                        isRequired={true}
                        label="Доверенность"
                        onChange={handleChangeAppointment}
                        files={formValues?.appointment ? [formValues?.appointment] : []}
                        helperText={validation.get('appointment')}
                        error={validation.isKey('appointment')}
                    />
                </div>
                <h3 className="block">Телефоны:</h3>
                {renderDataArr('Номер', 'phones')}
                <div className="row">
                    <FormButtonsComponent
                        noPadding
                        positionLeft
                        noMarginLeft
                        buttons={[
                            {
                                onClick: handleAddArrItem('phones'),
                                name: buttons.ADD
                            }
                        ]}
                    />
                </div>
                <h3 className="block">E-mail:</h3>
                {renderDataArr('E-mail', 'emails')}
                <div className="row">
                    <FormButtonsComponent
                        noPadding
                        positionLeft
                        noMarginLeft
                        buttons={[
                            {
                                onClick: handleAddArrItem('emails'),
                                name: buttons.ADD
                            }
                        ]}
                    />
                </div>
                <h3 className="block">Адрес филиала:</h3>
                <TextField
                    required
                    onChange={(e) => handleChange(e, true)}
                    className="block"
                    size="small"
                    variant="outlined"
                    label="Город"
                    name="city_name"
                    value={formValues.address.city_name}
                    error={!formValues?.address.city_name || !!validation.get('address')?.city_name}
                    helperText={validation.get('address')?.city_name}
                />
                <TextField
                    onChange={(e) => handleChange(e, true)}
                    className="block"
                    size="small"
                    variant="outlined"
                    label="Регион"
                    name="region"
                    value={formValues.address.region}
                />
                <TextField
                    onChange={(e) => handleChange(e, true)}
                    className="block"
                    size="small"
                    variant="outlined"
                    label="Индекс"
                    name="postal_code"
                    value={formValues.address.postal_code}
                />
                <TextField
                    required
                    onChange={(e) => handleChange(e, true)}
                    className="block"
                    size="small"
                    variant="outlined"
                    label="Улица"
                    name="street"
                    value={formValues.address.street}
                    error={!formValues?.address.street || !!validation.get('address')?.street}
                    helperText={validation.get('address')?.street}
                />
                <TextField
                    required
                    onChange={(e) => handleChange(e, true)}
                    className="block"
                    size="small"
                    variant="outlined"
                    label="Дом"
                    name="house"
                    value={formValues.address.house}
                    error={!formValues?.address.house || !!validation.get('address')?.house}
                    helperText={validation.get('address')?.house}
                />
                <TextField
                    onChange={(e) => handleChange(e, true)}
                    multiline
                    rows={3}
                    className="block"
                    size="small"
                    variant="outlined"
                    label="Дополнительная информация"
                    name="comments"
                    value={formValues.address.comments}
                />
            </form>
        </Modal>
    );
}

export default BranchModal;