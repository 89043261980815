import { useCallback, useEffect, useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import dateFormat from 'helpers/constants/dateFormat';
import DateRange from 'components/common/Dates/DateRange';
import SelectRoutes from 'components/common/Autocomplete/PassengerTransport/Routes';
import SelectCompany from 'components/common/Autocomplete/Companies';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({ setParams, currentRoute, currentTemplate, test_id_prefix }) => {

    const initialState = {
        route: {},
        organizations: [],
        date_start: null,
        date_end: null,
        diagram: '',
        name: ''
    };

    const [data, setData] = useState(initialState);

    const handleResetFilter = (needRefresh) => {
        setData(initialState);
        if (needRefresh) {
            setParams({ 
                page: 1, 
                data: { 
                    ...(currentRoute?.id && { route_id: currentRoute.id }), 
                    ...(currentTemplate?.id && { schedule_template_id_list: [currentTemplate.id] }) 
                } 
            });
        }
    };

    const handleSearch = (filter = data) => {

        const correctStartDate = dateWithDashYYYYMMDD(filter.date_start);
        const correctEndDate = dateWithDashYYYYMMDD(filter.date_end);

        const resultData = {
            ...filter,
            route_id: filter?.route?.id || null,
            organization_id_list: filter?.organizations.map(({ id }) => id),
            route: null,
            organizations: null,
            date_start: correctStartDate === 'Invalid date' ? null : correctStartDate,
            date_end: correctEndDate === 'Invalid date' ? null : correctEndDate
        };

        const paramsWithoutEmpty = Object.keys(resultData).reduce((res, key) => {
            if (resultData[key]) {
                res[key] = resultData[key];
            }
            return res;
        }, {});

        setParams({
            page: 1,
            data: {
                ...(currentRoute?.id && { route_id: currentRoute.id }),
                ...(currentTemplate?.id && { schedule_template_id_list: [currentTemplate.id] }),
                ...paramsWithoutEmpty
            }
        });

    };

    const onChange = (event, name) => {
        setData({ ...data, [name]: event.target.value });
    };

    const handleUniversalSelect = useCallback((key, value) => {
        setData((prevData) => ({ ...prevData, [key]: value }));
    }, []);

    const handleClickEnter = (e) => {
        if (e?.key === 'Enter') handleSearch();
    };
    
    useEffect(() => {
        if (currentRoute?.id) {
            handleUniversalSelect('route', currentRoute);
        }
    },[currentRoute, handleUniversalSelect]);

    const updateFilter = (filters) => {
        setData({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            handleSearch({
                ...initialState,
                ...filters,
            });
        }
    };

    const isDisabled = !data.route && !data.date_start && !data.date_end && !data.diagram;

    return (
        <LayoutFilter
            onResetFilter={handleResetFilter} 
            onSearch={handleSearch} 
            filter={data} 
            setUserFilter={updateFilter}
            disabled={isDisabled}
            filterException={['date_start', 'date_end',]}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={data.date_start}
                    valueEndDate={data.date_end}
                    handleDate={(e) => setData({ ...data, date_start: e })}
                    handleEndDate={(e) => setData({ ...data, date_end: e })}
                    startLabel={titles.START_DATE}
                    endLabel={titles.END_DATE}
                    pickersFormat={dateFormat.DATE_FOR_PICKER}
                    dateOnly={true}
                    disableFuture={false}
                />
                <SelectRoutes
                    disabled={!!currentRoute}
                    selected={data.route}
                    onChange={(value) => handleUniversalSelect('route', value)}
                    test_id_prefix={`${test_id_prefix}:route/input`}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <TextField
                    label={titles.NUMBER}
                    size="small"
                    value={data.diagram}
                    variant="outlined"
                    type="text"
                    name="diagram"
                    onChange={(e)=> onChange(e, 'diagram')}
                    onKeyPress={handleClickEnter}
                    InputLabelProps={{ 'data-testid': `${test_id_prefix}:diagram/label` }}
                    inputProps={{ 'data-testid': `${test_id_prefix}:diagram/input` }}
                />
                <TextField
                    label={titles.NAME}
                    size="small"
                    value={data.name}
                    variant="outlined"
                    type="text"
                    name="name"
                    onChange={(e)=> onChange(e, 'name')}
                    onKeyPress={handleClickEnter}
                    InputLabelProps={{ 'data-testid': `${test_id_prefix}:name/label` }}
                    inputProps={{ 'data-testid': `${test_id_prefix}:name/input` }}
                />
                {!currentRoute?.id 
                    && <SelectCompany
                        multiple
                        selected={data.organizations}
                        disabled={currentRoute?.id}
                        onChange={(value) => handleUniversalSelect('organizations', value)}
                        filter={{ withDeleted: 1 }}
                        selectDeletedCompanies
                        test_id_prefix={`${test_id_prefix}:organizations/input`}
                    />
                }
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
