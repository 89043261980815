import React, { useState } from 'react';
import {
    FormControl,
    IconButton,
    TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import ConfirmDelete from '../../../../common/ConfirmModal';
import messages from '../../../../../helpers/constants/messages';
import titles from '../../../../../helpers/constants/titles';
import TextMaskPhone from '../../../../common/TextMaskPhone';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';

const useStyles = makeStyles({
    field: {
        width: '100%',
        maxWidth: '90%'
    },
    title: {
        fontWeight: 'bold',
        fontSize: '18px',
        padding: '20px 0 10px 0'
    },
    button: {
        margin: '20px 0',
        maxWidth: '110px'
    }
});


const CameraIP = ({ onChange, ips = [] }) => {
    const classes = useStyles();
    const validation = useSelector(({ validation }) => validation);
    const formValidation = (key) => validation[key];

    const [deletedIndex, setDeletedIndex] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const ipLabels = {
        title: titles.IP_LIST,
        label: titles.ENTER_IP,
    };

    const handleConfirmDelete = (index) => {
        setDeletedIndex(index);
        setIsDeleteModalOpen(true);
    };

    const handleAddIp = () => {
        onChange([...ips, '']);
    };

    const handleChangeIp = (e, index) => {
        const ip = e.target.value;
        onChange(ips.map((item, i) => {
            if (i === index) {
                return ip;
            }
            return item;
        }));
    };

    return <>
        <h2>{titles.IP_LIST}</h2>
        {ips?.map((element, index) => {
            return (
                <div className={classes.card_line} key={index}>
                    <FormControl className={classes.field}>
                        <TextField
                            className={classes.field}
                            label={titles.ENTER_IP}
                            error={!!formValidation('ips')}
                            value={element || ''}
                            onChange={(e) => handleChangeIp(e, index)}
                            name="ip"
                            variant="outlined"
                            size="small"
                            InputProps={{
                                inputComponent: TextMaskPhone,
                                inputProps: {
                                    mask: [/\d/,/\d/,/\d/,'.',/\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/]
                                }
                            }}
                        />
                    </FormControl>


                    {/*<FormControl variant="outlined" className={classes.field} error={!!formValidation('ips')} >*/}
                    {/*    <InputLabel id="ip_label">{titles.ENTER_IP}</InputLabel>*/}
                    {/*    <Input*/}
                    {/*        className={classes.field}*/}
                    {/*        id="ip_label"*/}
                    {/*        variant="outlined"*/}
                    {/*        size="small"*/}
                    {/*        value={element || ''}*/}
                    {/*        onChange={(e) => handleChangeIp(e, index)}*/}
                    {/*        name="ip"*/}
                    {/*        inputComponent={TextMaskCustom}*/}
                    {/*    />*/}
                    {/*</FormControl>*/}
                    <IconButton
                        onClick={() => handleConfirmDelete(index)}
                        aria-label="delete"
                        size="large">
                        <DeleteIcon/>
                    </IconButton>
                </div>
            );})}
        <FormButtonsComponent 
            className={classes.button}         
            buttons={[
                {
                    ...buttonsTypes.add,
                    onClick: handleAddIp,
                }
            ]}
        />
        <ConfirmDelete
            message={messages.CONFIRM_DELETE}
            open={isDeleteModalOpen}
            onSuccess={() => {
                onChange(ips.filter((element, index) => index !== deletedIndex));
                setDeletedIndex(null);
                setIsDeleteModalOpen(false);
            }}
            onClose={() => {
                setDeletedIndex(null);
                setIsDeleteModalOpen(false);
            }}
        />
    </>;
};

export default CameraIP;
