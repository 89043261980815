import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, InputAdornment, TextField, Autocomplete } from '@mui/material';
import _ from 'lodash';
import UniversalSelect from '../../UniversalSelect';
import makeStyles from '@mui/styles/makeStyles';
import { loadTypeOfMistakesForCheckList } from '../../../../redux/DorisControl/actions';
import { TypesChecklistMistakesType } from './types';

const useStyles = makeStyles({
    input: {
        paddingRight: '54px !important',
        fontSize: '0.8rem',
    },
    label: {
        fontSize: '0.8rem',
    },
    adornment: {
        position: 'absolute',
        right: '6px',
    },
});

const TypesChecklistMistakes = ({
    multiple = false,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label = '',
    error = false,
    helperText = '',
    filter = {},
    sortedList,
    renderLabel,
}: TypesChecklistMistakesType ) => {    
    const dispatch = useDispatch();
    const styles = useStyles();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    
    const prevData = useRef(selected);

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const autoCompleteChange = (e: { key: string; }, value: any) => {
        if (e?.key !== 'Enter') onChange(value);
    };

    const getList = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query: name } = params;
        
        dispatch(loadTypeOfMistakesForCheckList({ 
            page, 
            limit,
            ...(name && { name }),
            ...filter
        }));
    };

    const handleClose = () => setShowList(false);

    const handleAccept = (data: any) => {
        onChange(data);
        setShowList(false);
    };

    return (
        <>
            <Autocomplete
                multiple={multiple}
                value={selected}
                options={[]}
                disabled={!multiple || disabled}
                // filterSelectedOptions
                getOptionLabel={(option: any) => renderLabel ? renderLabel(option) : option.name || ''}
                // @ts-ignore: error message
                onChange={autoCompleteChange}
                freeSolo
                size="small"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={error}
                        size="small"
                        variant="outlined"
                        required={required}
                        label={label}
                        helperText={helperText}
                        disabled={!multiple || disabled}
                        InputProps={{
                            ...params.InputProps,
                            classes: { root: styles.input },
                            endAdornment: (
                                <InputAdornment position={'end'} classes={{ root: styles.adornment }}>
                                    {selected && Object.keys(selected).length > 0 
                                            && <IconButton size="small"
                                                disabled={disabled}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onChange(multiple ? [] : {});
                                                }}
                                            >
                                                <i className="far fa-times"/>
                                            </IconButton>
                                    }
                                    <IconButton disabled={disabled} size="small" onClick={() => setShowList(true)}>
                                        <i className="fas fa-search-plus"/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{
                            classes: { root: styles.label }
                        }}
                    />
                )}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={!sortedList && getList}
                    sortedList={sortedList}
                    storeName="dorisControl"
                    storeNameProps="typeOfMistakesForChecklist"
                    keyProp="id"
                    isSelected
                    selected={selectedItems}
                    // @ts-ignore: error message
                    renderProps={(el) => <div>{el.name}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    // @ts-ignore: error message
                    onAccept={handleAccept}
                    noPadding
                    title={label}
                    withSearch
                />
            }
        </>
    );
};

export default TypesChecklistMistakes;
