import L from 'leaflet';

import { getMarker } from 'components/MapComponents/marker/Marker';
import removeEmptyFields from 'helpers/removeEmptyFields';

export const getFilter = (filter) => {
    const filters = {
        name: filter.nameAnchor || '',
    };
    return removeEmptyFields(filters, false);
};

export const isShowStations = (zoom) => zoom >= 16;

export const getAnchorIcon = 'fas fa-anchor';
export const getShipIcon = 'far fa-ship';

export const getShipColor = '#0066cc';

const styleIcon = 'width:100%;color:#fff;position:absolute;left:0;top:0;height:calc(100% - 5px);display:flex;justify-content:center;align-items:center;font-size:1.2rem;';

// причал
export const getAnchorMarker = (color) => L.divIcon({
    className: 'marker',
    iconSize: [30, 36],
    iconAnchor: [15, 36],
    popupAnchor: [0, -36],
    html: `
        ${getMarker(color)}
        <i class="${getAnchorIcon}" style="${styleIcon}"/>
    `,
});

// корабль
export const getShipMarker = (color) => L.divIcon({
    className: 'marker',
    iconSize: [30, 36],
    iconAnchor: [15, 36],
    popupAnchor: [0, -36],
    html: `
        ${getMarker(color)}
        <i class="${getShipIcon}" style="${styleIcon}"/>
    `,
});
