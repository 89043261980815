import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import messages from 'helpers/constants/messages';
import { usePrevious } from 'helpers/hooks';
import { durationFromObjectToFormat, fullDateTimeWithoutSeconds, fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import { LSContainer, LSContentColumn, LSContentItem } from 'components/common/List';
import Loading from 'components/common/Loading';
import { loadDockEvents } from 'modules/TransportWater/redux/actions';

import { getAnchorIcon } from '../helper';

const PopUp = ({
    item,
}) => {

    const {
        id,
        color,
        name,
    } = item || {};

    const prevId = usePrevious(id);

    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [{ data, meta }, setData] = useState({ data: [], meta: {} });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setPage(1);
    }, [id]);

    useEffect(() => {
        if (!id) return;
        if (id !== prevId) {
            setPage(1);
        } else {
            const start_date = fullDateTimeWithTimeZone(getStartOf());
            const end_date = fullDateTimeWithTimeZone(getEndOf());
            dispatch(loadDockEvents(
                id,
                {
                    page,
                    limit,
                    start_date,
                    end_date,
                },
                setLoading,
                (newData) => {
                    setData((old) => ({
                        ...old,
                        ...newData,
                        data: page === 1
                            ? newData.data
                            : [
                                ...old.data,
                                ...newData.data
                            ]
                    }));
                }
            ));
        }
    }, [page, limit, id]);

    const handleScrollUpdate = (values) => {
        const {
            last_page = 0
        } = meta;
        if (
            values.top > 0.98
            && loading === false
            && page < last_page
        ) {
            setPage(page + 1);
        }
    };

    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: color }}>
                <div>
                    <div className="img">
                        <i className={getAnchorIcon}></i>
                    </div>
                    <span>{name}</span>
                </div>
            </div>

            <div className="map-popup_body" style={{ minHeight: '50px' }}>
                {loading && <Loading circular/>}

                {data?.length > 0
                    ? (
                        <>
                            <h2 style={{ padding: '1rem 1rem 0' }}>Пришвартованные корабли:</h2>
                            <Scrollbars
                                style={{ height: '300px' }}
                                onUpdate={handleScrollUpdate}
                                renderTrackVertical={({ style, ...props }) =>
                                    <div
                                        {...props}
                                        style={{
                                            ...style,
                                            width: '6px',
                                            right: '2px',
                                            bottom: '2px',
                                            top: '2px',
                                            borderRadius: '3px',
                                            zIndex: 999,
                                        }}
                                    />
                                }
                                renderThumbVertical={({ style, ...props }) =>
                                    <div
                                        {...props}
                                        style={{
                                            ...style,
                                            position: 'relative',
                                            display: 'block',
                                            width: '100%',
                                            borderRadius: 'inherit',
                                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                            cursor: 'pointer'
                                        }}
                                    />
                                }
                            >
                                <LSContainer
                                    headers={[
                                        { title: 'Дата и время начала стоянки', width: '30%' },
                                        { title: 'Продолжительность', width: '20%' },
                                        { title: 'MMSI', width: '15%' },
                                        { title: 'Наименование', width: '20%' },
                                        { title: 'Позывной', width: '15%' },
                                        { title: '', isActions: true }
                                    ]}
                                    // style={{ paddingRight: '1rem' }}
                                >

                                    {data.map(({ id, start_at, vehicle = {}, end_at }) => (
                                        <LSContentItem key={id}>
                                            <LSContentColumn>{fullDateTimeWithoutSeconds(start_at)}</LSContentColumn>
                                            <LSContentColumn>
                                                {start_at && end_at
                                                    ? durationFromObjectToFormat({ milliseconds: new Date(end_at) - new Date(start_at) }, 'm') || '0'
                                                    : '0'
                                                }
                                            </LSContentColumn>
                                            <LSContentColumn>{vehicle.mmsi}</LSContentColumn>
                                            <LSContentColumn>{vehicle.name}</LSContentColumn>
                                            <LSContentColumn>{vehicle.short_name}</LSContentColumn>
                                            <LSContentColumn></LSContentColumn>
                                        </LSContentItem>
                                    ))}

                                </LSContainer>
                            </Scrollbars>
                        </>
                    )
                    : (
                        <div className="not-found">{messages.NO_DATA}</div>
                    )
                }
            </div>

        </div>
    );
};

export default PopUp;
