import TransportIncidentsReport from '../../pages/Reports/TransportIncidentsReport';
import TransportIncidentResolutionTime from '../../pages/Reports/TransportIncidentsResolutionTime';
import TransportIncidentsDistrictsByMonth from '../../pages/Reports/TransportIncidentsDistrictsByMonth';


const routes = [
    {
        path: '/reports/transport-incidents/status',
        component: TransportIncidentsReport,
        exact: true
    },
    {
        path: '/reports/transport-incidents/resolution-time',
        component: TransportIncidentResolutionTime,
        exact: true
    },
    {
        path: '/reports/transport-incidents/districts-by-month',
        component: TransportIncidentsDistrictsByMonth,
        exact: true
    },
];

export default routes;