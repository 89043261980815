import instance from 'helpers/axios.config';

import AuthUrls from './api.urls';

const ApiAuthMethods = {
    /*getToken: (username, password) =>
        instance.post(AuthUrls.getToken(), {
            username,
            password
        }).then(({ data }) => data),*/

    getToken: async (username, password) => {
        const response = await instance.post(AuthUrls.getToken(), {
            username,
            password,
        });
        return response?.data;
    },

    getTokenByUser: async (id) => {
        const response = await instance.get(AuthUrls.getTokenByUser(id));
        return response?.data;
    },

    refreshToken: async (refresh_token) => {
        const response = await instance.post(AuthUrls.refreshToken(), { refresh_token });
        return response?.data;
    },

    resetPassword: async (username) => {
        const response = await instance.post(AuthUrls.resetPassword(), {
            username,
        });
        return response?.data;
    },

    getAuthUser: async () => {
        const response = await instance.get(AuthUrls.getAuthUser());
        return response?.data;
    },

    changeUserName: async (data, id) => {
        const response = await instance.put(AuthUrls.putUserName(id), data);
        return response.data;
    },

    getUserServices: async () => {
        const response = await instance.get(AuthUrls.getUserServices());
        return response?.data;
    },

    changeUserLast: async (params) => {
        const response = await instance.put(AuthUrls.changeUserLast(), params);
        return response?.data;
    },

    changePassword: async (params) => {
        const response = await instance.post(AuthUrls.changePassword(), params);
        return response?.data;
    },

    logout: async () => {
        const response = await instance.post(AuthUrls.logout());
        return response?.data;
    },

    deleteAllTokens: async () => {
        const response = await instance.delete(AuthUrls.deleteAllTokens());
        return response?.data;
    },

    getMapLayers: async () => {
        const response = await instance.get(AuthUrls.getMapLayers());
        return response?.data;
    },

    setMapLayer: async (layerId) => {
        const response = await instance.put(AuthUrls.setMapLayer(), { 'map_layer': layerId });
        return response?.data;
    },
    saveUserFilters: async (params) => {
        const response = await instance.post(AuthUrls.saveUserFilters(), params);
        return response?.data;
    },
    loadUserFilters: async (params) => {
        const response = await instance.get(AuthUrls.loadUserFilters(), params);
        return response?.data;
    },
    deleteUserFilters: async (params) => {
        const response = await instance.delete(AuthUrls.deleteUserFilters(), { data: params });
        return response?.data;
    },

    getAccessTemplate: async (uri) => {
        const response = await instance.get(AuthUrls.getAccessTemplate(), {
            params: { uri }
        });
        return response?.data;
    },
};

export default ApiAuthMethods;
