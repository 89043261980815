import { useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import titles from '../../../../../helpers/constants/titles';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import { createCameraTemplate, editCameraTemplate } from '../../../../../redux/VideoCameras/actions';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Modal from '../../../../common/Modal';
import SelectedCameras from './SelectedCameras';
import Template from './Template';

const ModalForm = ({ 
    isNew, 
    isOpen, 
    onClose, 
    item = {}, 
    loadList 
}) => {
    const dispatch = useDispatch();

    const initialState = {
        name: item.name || '',
        column: item.column || 1,
        row: item.row || 1,
        camera_ids: item.camera_ids || [],
        cameras: item.cameras || []
    };
    
    const [formData, setFormData] = useState(initialState);
    
    const onChange = (e) => {
        const { name, value } = e.target;
        if(name === 'row' || name === 'column'){
            setFormData({
                ...formData,
                [name]: value,
                cameras: []
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSave = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            camera_ids: formData.cameras.map(({ id }) => id),
            cameras: null,
        }, false);

        const callback = () => {onClose(); loadList();};

        dispatch(isNew
            ? createCameraTemplate(prepareData, callback)
            : editCameraTemplate(item.id, prepareData, callback)
        );
    };

    const sumCameras = formData.column * formData.row;

    const isDisabled = !formData.name || !formData.cameras.length;

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            maxWidthProp={'lg'}
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            required
                            className="block"
                            label={titles.NAME}
                            variant="outlined"
                            size="small"
                            value={formData.name || ''}
                            type="text"
                            name={'name'}
                            onChange={onChange}
                        />
                        <FormControl required className="block" size="small" variant="outlined">
                            <InputLabel>Количество строк</InputLabel>
                            <Select
                                value={formData.row || 1}
                                label="Количество строк"
                                name={'row'}
                                onChange={onChange}
                            >
                                {Array.from(Array(4)).map((_, index) => (
                                    <MenuItem key={index} value={index+1}>{index+1}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl required className="block" size="small" variant="outlined">
                            <InputLabel>Количество колонок</InputLabel>
                            <Select
                                value={formData.column || 1}
                                label="Количество колонок"
                                name={'column'}
                                onChange={onChange}
                            >
                                {Array.from(Array(4)).map((_, index) => (
                                    <MenuItem key={index} value={index+1}>{index+1}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <SelectedCameras
                            selected={formData.cameras}
                            onChange={(cameras) => setFormData({ ...formData, cameras })}
                            sumCameras={sumCameras}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <Template
                            sumCameras={sumCameras}
                            columns={formData.column}
                            rows={formData.row}
                        />
                    </Grid>
                </Grid>
            </div>

            
        </Modal>
    );
};

export default ModalForm;
