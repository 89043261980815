import React from 'react';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import Modal from '../../../../common/Modal';
import L from 'leaflet';
import { Map, MapControl, Marker, ToolTip } from '../../../../MapComponents/leaflet';
import makeStyles from '@mui/styles/makeStyles';
import messages from '../../../../../helpers/constants/messages';

const useStyles = makeStyles({
    mapMarkerIcon: {
        fontSize: '35px',
        color: '#2c83ca'
    },
    mapSubTitle: {
        fontWeight: '400'
    }
});
function MapModal({ lat, lon, onClose, isOpen, address, complexName }) {
    const styles = useStyles();
    const createHtml = `<div class="${styles.mapMarkerIcon}"><i class="fas fa-map-marker-alt"/></div>`;

    const startMarkerIcon = L.divIcon({
        className: styles.mapMarkerIcon,
        html: createHtml,
        iconAnchor: [10, 40],
    });

    const renderTitle = (
        <div>
            <div>
                Комплекс: <span className={styles.mapSubTitle}>{complexName || messages.NO_DATA}</span>
            </div>
            <div>
                Адрес: <span className={styles.mapSubTitle}>{address || messages.NO_DATA}</span>
            </div>
        </div>
    );

    return (
        <Modal
            isOpen={isOpen}
            noPadding
            title={renderTitle}
            onClose={() => onClose(false)}
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: () => onClose('')
                        }
                    ]}
                />
            }
        >
            <div className="map-form-wrap__container">
                <Map>
                    <MapControl>
                        <Marker moveToAfterInit={true} icon={startMarkerIcon} latlng={[lat, lon]}>
                            <ToolTip offset={[3, -27]} permanent={true} direction="top">
                                <p><b>Широта:</b> {lat}</p>
                                <p><b>Долгота:</b> {lon}</p>
                            </ToolTip>
                        </Marker>
                    </MapControl>
                </Map>
            </div>
        </Modal>
    );
}

export default MapModal;