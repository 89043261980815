const colorObjectGlobal = {};

// key - ключ для цвета
// return - color
const getColor = (key) => {
    const createColor = () => '#' + ((1<<24)*Math.random() | 0).toString(16);

    if (colorObjectGlobal[key]) {
        return colorObjectGlobal[key];
    }
    const newColor = createColor();
    colorObjectGlobal[key] = newColor;
    return newColor;
};
export default getColor;