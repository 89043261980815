import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadRoadWorksGroups } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import Context from 'helpers/context';

import AddModal from './AddEditModal';
import Item from './Item';
import Filters from './Filters';

const RoadWorkGroups = () => {
    const dispatch = useDispatch();

    const { permissions } = useContext(Context);

    const listData = useSelector(roadworksSelectors.roadWorksGroupsData);
    const listMeta = useSelector(roadworksSelectors.roadWorksGroupsMeta);
    const loading = useSelector(roadworksSelectors.loadingRoadWorksGroups);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openModal, setOpenModal] = useState(false);

    const reloadList = (isDelete) => {
        isDelete && listData?.length && listMeta?.last_page > 1 && params.page !== 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadRoadWorksGroups(params.page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadRoadWorksGroups(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return (listData?.length > 0
            ? <LSContainer
                headers={[
                    { title: titles.NAME, width: '70%' },
                    { title: 'Действия', isActions: true }
                ]}
            >
                {listData.map((item) => (
                    <Item
                        key={item.id}
                        item={item}
                        reloadList={reloadList}
                        permissions={permissions}
                    />
                ))}
            </LSContainer>
            : !loading && <div>{messages.DATA_IS_NOT_FOUND}</div>
        );
    };

    return (
        <div>
            <PageLayout
                header="Группы дорожных работ"
                filters={<Filters setParams={setParams}/>}
                content={renderContent}
                loading={loading}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[
                                    ...(permissions?.is_create
                                        ? [{
                                            ...buttonsTypes.add,
                                            onClick: () => setOpenModal(true),
                                        }]
                                        : []
                                    ),
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: listMeta?.total
                }}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: listMeta,
                    limit,
                    setLimit: setLimit,
                }}
            />
            {openModal && (
                <AddModal
                    isNew
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </div>
    );
};

export default RoadWorkGroups;