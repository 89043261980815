import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiMethods = {
    getStatistic: async (params) => {
        const response = await instance.post(apiUrls.getStatistic(), params);
        return response?.data;
    },

};

export default ApiMethods;
