import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiTransportPassengerMethods = {
    getVehicles: async (page, limit, data) => {
        const response = await instance.post(apiUrls.getVehicles(), {
            page, limit, ...data,
        });
        return response.data;
    },

    getVehicleTypes: async () => {
        const response = await instance.get(apiUrls.getVehicleTypes());
        return response.data;
    },

    createVehicle: async (data) => {
        const response = await instance.post(apiUrls.createVehicle(), data);
        return response.data;
    },

    editVehicle: async (id, data) => {
        const response = await instance.put(apiUrls.editVehicle(id), data);
        return response.data;
    },

    deleteVehicle: async (id) => {
        const response = await instance.delete(apiUrls.deleteVehicle(id));
        return response.data;
    },

    getDockSidebar: async (params) => {
        const response = await instance.post(apiUrls.getDockSidebar(), params);
        return response.data;
    },

    getDockPolygon: async (params) => {
        const response = await instance.post(apiUrls.getDockPolygon(), params);
        return response.data;
    },

    getDockCoords: async (params) => {
        const response = await instance.post(apiUrls.getDockCoords(), params);
        return response.data;
    },

    getDockEvents: async (id, params) => {
        const response = await instance.post(apiUrls.getDockEvents(id), params);
        return response.data;
    },
};

export default ApiTransportPassengerMethods;
