// иконка для списка
const icon = ({ fill = '#fff' }) => {
    return `
        <svg fill="${fill}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cctv" class="svg-inline--fa fa-cctv fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M517.45 168.58L139.24 2.29a32 32 0 0 0-39.67 13.84L4.23 183a32 32 0 0 0 16.85 46l156.57 64.85L137.81 400H64v-32a16 16 0 0 0-16-16H16a16 16 0 0 0-16 16v128a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16v-32h96a32 32 0 0 0 30-20.77l46.85-124.94 68.52 28.38a32 32 0 0 0 27.89-2.94l189.26-118.31c22.21-13.88 19.25-47.11-5.07-56.84zm48.47 105.2l-35.45-15.59-138.4 86.5 119.58 52.58a16.86 16.86 0 0 0 22.22-8.65l40.7-92.62a16.85 16.85 0 0 0-8.65-22.22z"></path>
        </svg>
    `;
};

export default icon;
