import React, { useState } from 'react';
import titles from '../../../../helpers/constants/titles';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import './style.scss';

// свернуть компонент на карте
function MapMinimize(props) {
    const {
        map,
        defaultVisible = false,
        children,
        icon = null,
        tooltipOpen = titles.CLICK_TO_OPEN,
        tooltipHide = 'Нажмите, чтобы закрыть',
    } = props;

    const [visible, setVisible] = useState(defaultVisible);
    const handleChangeVisible = (visibleBool) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        setVisible(visibleBool);
    };

    return (
        <>
            {visible
                ? (
                    <div className="map-custom-minimize">
                        <div className="map-custom-minimize_close">
                            <Tooltip title={tooltipHide}>
                                <IconButton onClick={handleChangeVisible(false)} size="small">
                                    <ClearIcon />
                                </IconButton>
                            </Tooltip>
                        </div>

                        {React.Children.map(children,
                            (child) => React.cloneElement(child, { map })
                        )}
                    </div>
                )
                : (
                    <div>
                        <Tooltip title={tooltipOpen}>
                            <IconButton onClick={handleChangeVisible(true)} size="small">
                                {icon || <SearchIcon />}
                            </IconButton>
                        </Tooltip>
                    </div>
                )
            }
        </>
    );
}

export default MapMinimize;
