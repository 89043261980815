import messages from 'helpers/constants/messages';
import { ActionMore, LSContentColumn, LSContentItem } from 'components/common/List';
import CircleStatus from 'components/common/CircleStatus';

const Item = ({ el, onClick, selectedItem, boardStatus, focus }) => {

    return (
        <LSContentItem
            needFocus={focus === el?.id}
            onClick={() => onClick(el)}
            style={selectedItem?.id === el?.id ? { backgroundColor: '#d7d7d7' } : { backgroundColor: '' }}
            isMobile={!!selectedItem}
        >
            <LSContentColumn>
                <CircleStatus color={boardStatus?.color} title={el.status_text} />
            </LSContentColumn>
            <LSContentColumn>
                {el?.name || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {el?.category_text || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {el?.serial_number || messages.NO_VALUE}
            </LSContentColumn>

            <LSContentColumn align="right" isActions>
                <ActionMore isPassport isOpen={selectedItem && selectedItem?.id === el?.id} />
            </LSContentColumn>

        </LSContentItem>
    );
};

export default Item;
