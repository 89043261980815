import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import messages from 'helpers/constants/messages';
import ConfirmModal from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { deleteCompanyRole } from 'redux/Companies/actions';

import ModalForm from './ModalForm';

const Item = ({ item, loadList, permissions }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return (
        <>
            <LSContentItem style={{ cursor: 'pointer' }}>
                <LSContentColumn>{item.name || messages.NO_DATA}</LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick:() => setOpenEditModal(true)
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick:() => setOpenDeleteModal(true)
                            }
                        ]}
                    />

                </LSContentColumn>
            </LSContentItem>

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    loadList={loadList}
                />
            )}
            {openDeleteModal && (
                <ConfirmModal
                    open={openDeleteModal}
                    onSuccess={() => dispatch(deleteCompanyRole(item.id, () => loadList(true)))}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
