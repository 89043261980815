import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import messages from 'helpers/constants/messages/common';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import { changeScheduleStatus } from 'redux/TransportPassenger/actions';

const StatusForm = ({ isOpen, onClose, currentStatus, statuses, id, reloadList }) => {

    const dispatch = useDispatch();
    const validation = useValidation();

    const [data, setData] = useState({
        status: currentStatus,
    });

    const closeForm = () => {
        onClose(false);
    };

    const onAcceptModal = () => {
        dispatch(changeScheduleStatus(id, data.status, () => {
            reloadList();
            closeForm();
        }));
    };

    const onChangeNew = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
        validation.deleteKey(name);
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            small={true}
            title="Изменение статуса"
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: closeForm
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <FormControl className="block" size="small" required variant="outlined">
                    <InputLabel>{ titles.SELECT_STATUS }</InputLabel>
                    <Select
                        value={data.status}
                        required
                        label={ titles.SELECT_STATUS }
                        onChange={(e) => onChangeNew(e.target.name, e.target.value)}
                        defaultValue={statuses[currentStatus]}
                        name="status"
                    >
                        {Object.keys(statuses)?.length
                            ? Object.keys(statuses)?.map((key) =>
                                <MenuItem value={key} key={key}>{statuses[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </form>
        </Modal>
    );
};

export default StatusForm;