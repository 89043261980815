// import brightImg from '../../layersIcons/layer0.png';
// import cartoImg from '../../layersIcons/layer1.png';
import dgisImg from '../layersIcons/layer2.png';

// const lData = {
//     1: {
//         url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
//         image: brightImg,
//         // options: {
//         //     attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
//         // },
//     },
//     2: {
//         url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
//         image: cartoImg
//     },
//     3: {
//         url: 'http://tile3.maps.2gis.com/tiles?x={x}&y={y}&z={z}',
//         image: dgisImg
//     }
// };
const defaultLayer = {
    url: 'http://tile3.maps.2gis.com/tiles?x={x}&y={y}&z={z}',
    image: dgisImg,
    name: '2GIS',
    options: {
        maxZoom: 18
    }
};

// дефолтный слой если слои не смогли прогрузиться
export default defaultLayer;