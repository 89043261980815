import PickersDay, { PickersDayProps, pickersDayClasses } from '@mui/lab/PickersDay';
import { DateTime } from 'luxon';
import { isWeekend } from 'helpers/date.config';

export const renderDay = (
    date: DateTime,
    selectedDates: Array<DateTime | null>,
    pickersDayProps: PickersDayProps<DateTime>
) => {
    const weekendStyles = isWeekend(date) ? { color: 'red' } : {};

    return (
        <PickersDay
            {...pickersDayProps}
            sx={{
                ...weekendStyles,
                [`&&.${pickersDayClasses.selected}`]: {
                    backgroundColor: '#1976d2'
                }
            }}
        />
    );
};
