import * as types from './types';

const initialState = {
    statuses: {
        dtp: 2,
        meteo: 2,
        camera: 2,
        route: 2,
        vehicles: 2,
    },
    loading: false,
    users: {
        data: []
    },
    roles: {},
    // organizations: null,
    permissions: [],
    // organizationsById: {},
    dtpSettings: [],
    dtpSettingsLoading: false,
    currentUser: {},
    loadingCurrentUser: false,
    newPassword: '',
    resettingPassword: false,
    settings: [],
    loadingSettings: false,
    userActivity: {},
    loadingUserActivity: false,
    methods: {},
    microservices: {},
    actions: {},

    settingsGroup: { data: [] },
    loadingSettingsGroup: false,

    loadingButton: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.STATUSES_LOADED: {
            return {
                ...state,
                statuses: payload,
            };
        }

        case types.LOADING:
            return {
                ...state,
                loading: payload,
            };

        case types.USERS_LOADED: {
            return {
                ...state,
                users: payload,
            };
        }

        case types.ROLES_LOADED: {
            return {
                ...state,
                roles: payload,
            };
        }

        // case types.ORGANIZATIONS_LOADED: {
        //     return {
        //         ...state,
        //         organizations: payload,
        //     };
        // }

        case types.PERMISSIONS_LOADED: {
            return {
                ...state,
                permissions: payload,
            };
        }

        // case types.SET_ORGANIZATION: {
        //     return {
        //         ...state,
        //         organizationsById: { ...state.organizationsById, [payload.id]: payload },
        //     };
        // }

        case types.LOADED_DTP_SETTINGS: {
            return {
                ...state,
                dtpSettings: payload.data,
            };
        }

        case types.LOADING_DTP_SETTINGS: {
            return {
                ...state,
                dtpSettingsLoading: payload,
            };
        }

        case types.LOADED_CURRENT_USER: {
            return {
                ...state,
                currentUser: { ...state.currentUser, [payload.id]: payload },
            };
        }

        case types.LOADING_CURRENT_USER: {
            return {
                ...state,
                loadingCurrentUser: payload,
            };
        }
        
        case types.RESETED_PASSWORD: {
            return {
                ...state,
                newPassword: payload,
            };
        }

        case types.RESETTING_PASSWORD: {
            return {
                ...state,
                resettingPassword: payload,
            };
        }

        case types.LOADING_SETTINGS: {
            return {
                ...state,
                loadingSettings: payload,
            };
        }

        case types.LOADED_SETTINGS: {
            return {
                ...state,
                settings: payload,
            };
        }

        case types.LOADING_USER_ACTIVITY: {
            return {
                ...state,
                loadingUserActivity: payload,
            };
        }

        case types.LOADED_USER_ACTIVITY: {
            return {
                ...state,
                userActivity: payload,
            };
        }

        case types.LOADED_METHODS: {
            return {
                ...state,
                methods: payload,
            };
        }

        case types.LOADED_MICROSERVICES: {
            return {
                ...state,
                microservices: payload,
            };
        }

        case types.LOADED_ACTIONS: {
            return {
                ...state,
                actions: payload,
            };
        }

        case types.LOADED_SETTINGS_GROUP:
            return {
                ...state,
                settingsGroup: payload
            };

        case types.LOADING_SETTINGS_GROUP:
            return {
                ...state,
                loadingSettingsGroup: payload
            };

        case types.LOADING_BUTTON: {
            return {
                ...state,
                loadingButton: payload,
            };
        }

        default:
            return state;
    }
}
