import React, { useMemo } from 'react';
import { Line as GLine } from 'react-chartjs-2';
// import {
//     getHumanDate,
// } from '../../../../../helpers/date.config';


// линейный график
const Line = ({
    labels = [],
    datasets = [],
    legendPosition = 'bottom',
    isLegend = true,
    callbacks = {},
    scales = {},
}) => {

    /*
    * {
      label: key,
      backgroundColor: colors[key],
      data: datasets[key],
      unit: units[key],}
    *
    *
    * */


    // const callbacks = {
    //     label: (context) => ` ${context?.dataset?.label}: ${context?.formattedValue} ${context?.dataset?.unit}`,
    // };

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks
            },
            legend: {
                position: legendPosition,
                display: isLegend,
            },
        },
        scales
    };

    const data = {
        labels,
        datasets,
    };

    return (
        <GLine
            options={options}
            data={data}
            style={{ maxHeight: '400px' }}
        />
    );

};

export default Line;
