import { useDispatch } from 'react-redux';
import { checkFgis } from '../../redux/DorisControl/actions';
import { useState } from 'react';

const useCheckFgis = (callback) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const onResponse = (response) => {
        const value = response || {};
        setLoading(false);
        const isResponse = Object.keys(value).length > 0 || false;
        callback?.(value, isResponse);
    };

    const execute = (link) => {
        if (loading === false) {
            setLoading(true);
            dispatch(checkFgis(link, onResponse));
        }
    };
    execute.loading = loading;

    return execute;
};

export default useCheckFgis;
