import React, { useMemo } from 'react';
import {
    Autocomplete,
    IconButton,
    InputAdornment,
    TextField
} from '@mui/material';

import * as actions from 'redux/RoadNetwork/actions';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import useCategoryTypes from 'helpers/hooks/RoadNetwork/useCategoryTypes';
// todo RenderGroup перенести глобально для всех
import RenderGroup from 'components/common/DorisControl/FiltersCPVF/Group/RenderGroup';
import DateRange from 'components/common/Dates/DateRange';
import FormInfoWrapper from 'components/common/FormInfoWrapper';


const Form = ({ data, onChange }) => {

    // todo перепроверить в других местах
    // const roadSectionTypes = useStoreProp(actions.loadRoadSectionTypes, 'roadNetworks', 'roadSectionTypes');
    // const roadSectionCategories = useStoreProp(actions.loadRoadSectionCategories, 'roadNetworks', 'roadSectionCategories');
    const statuses = useStoreProp(actions.loadStatusesRoadSection, 'roadNetworks', 'statusesRoadSection');

    const categoryTypesProvider = useCategoryTypes();

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({
            [name]: value,
        });
    };

    // типы по категориям c категорией
    const typeList = useMemo(() => {
        const ids = data.category_list?.map(i => i.id);
        return categoryTypesProvider.getTypesWithCategory(ids);
    }, [data.category_list, categoryTypesProvider]);

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="block">
                <TextField
                    label="Название элемента сети"
                    value={data.name || ''}
                    onChange={handleChange}
                    name="name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {data?.name?.length > 0 ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => onChange({ name: '' })}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>✕</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />
            </div>

            <div className="block">
                <TextField
                    variant="outlined"
                    size="small"
                    name="description"
                    value={data.description}
                    label={titles.DESCRIPTION}
                    onChange={handleChange}
                />
            </div>

            <div className="block">
                <Autocomplete
                    value={data.status || null}
                    options={statuses}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name || ''}
                    noOptionsText={messages.NO_DATA}
                    size="small"
                    // multiple
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    onChange={(e, newValues) => onChange({
                        status: newValues
                    })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.STATUS}
                        />
                    )}
                />
            </div>

            <div className="block">
                <Autocomplete
                    multiple
                    value={data.category_list || []}
                    options={categoryTypesProvider.getCategories()}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name || ''}
                    noOptionsText={messages.NO_DATA}
                    size="small"
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    clearText="Очистить"
                    openText="Открыть"
                    closeText="Закрыть"
                    onChange={(e, newValues) => onChange({
                        category_list: newValues,
                        type_list: null,
                    })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.CATEGORY}
                        />
                    )}
                    limitTags={2}
                />
            </div>

            <FormInfoWrapper
                className="block"
                helperText={
                    !data.category_list
                    || data.category_list?.length === 0
                        ? 'Выберите сначала категорию!'
                        : ''
                }
            >
                <Autocomplete
                    disabled={!data?.category_list || typeList.length === 0 || !categoryTypesProvider.isLoaded}
                    multiple
                    value={data.type_list || []}
                    options={typeList}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name || ''}
                    noOptionsText={messages.NO_DATA}
                    size="small"
                    clearText="Очистить"
                    openText="Открыть"
                    closeText="Закрыть"
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    onChange={(e, newValues) => onChange({
                        type_list: newValues
                    })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.TYPE}
                        />
                    )}
                    groupBy={(option) => option.category_name}
                    renderGroup={RenderGroup}
                    limitTags={1}
                />
            </FormInfoWrapper>

            <div className="block">
                <TextField
                    label="Адрес"
                    value={data.address_text || ''}
                    onChange={handleChange}
                    name="address_text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {data?.address_text?.length > 0 ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => onChange({ address_text: '' })}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>x</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />
            </div>

            <div className="row">
                <DateRange
                    valueStartDate={data.start_at}
                    valueEndDate={data.end_at}
                    handleDate={(e) => onChange({ start_at: e })}
                    handleEndDate={(e) => onChange({ end_at: e })}
                    startLabel="Начало регистрации"
                    endLabel="Конец регистрации"
                    dateOnly
                />
            </div>

        </form>
    );
};

export default Form;
