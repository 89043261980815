import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { theme } from 'App';

import { trafficFlowSelectors } from 'redux/TrafficFlow';
import messages from 'helpers/constants/messages';
import colorExtend from 'helpers/mapHelper/colorExtend';
import Information from 'components/common/Information';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';


import icon from '../icons/icon';

import fields from './fields';
import Traffic from './Traffic';
import Events from './Events';

const PopUp = (props) => {
    const {
        statuses,
        types,
        uuid,
    } = props;

    const [showTraffic, setShowTraffic] = useState(false);
    const polygonVisible = useSelector(trafficFlowSelectors.polygonVisible);

    const currentData = useMemo(() => {
        if (uuid && polygonVisible) {
            const item = polygonVisible.find(({ id }) => id === uuid);
            return item || {};
        }
        return {};
    },[uuid, polygonVisible]);

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} locale={'ru'}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>

                    <div className="map-popup">
                        <div
                            className="map-popup_header"
                            style={{
                                backgroundColor: colorExtend(currentData.color)
                            }}
                        >
                            <div>
                                <div
                                    className="img"
                                    dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff', width: '20px' }) }}
                                />
                                <span>
                                    {currentData?.name || messages.INFO_IS_NOT_FOUND}
                                </span>
                            </div>
                        </div>
                        <div className="map-popup_body">
                            <div className="scroll">
                                <Information
                                    data={currentData}
                                    title={fields(statuses, types)}
                                />
                                <Traffic
                                    id={uuid}
                                />
                                <div className="row">
                                    <FormButtonsComponent
                                        buttons={[
                                            {
                                                onClick: () => setShowTraffic(true),
                                                name: 'Проезды',
                                                ...buttonsTypes.defaultPrimary,
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {showTraffic && (
                        <Events
                            id={uuid}
                            isOpen={showTraffic}
                            onClose={() => setShowTraffic(false)}
                        />
                    )}
                </ThemeProvider>
            </StyledEngineProvider>
        </LocalizationProvider>
    );
};

export default PopUp;
