import React, { useState } from 'react';
import { TextField } from '@mui/material';
import cn from 'classnames';
import FormButtonsComponent from '../../../FormButtons';
// import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

function CoordsSearch(props) {
    const { onChange } = props;
    const [lat, setLat] = useState('');
    const [lon, setLon] = useState('');

    const regField = new RegExp(/[0-9]+[,.]?[0-9]*/, 'gi');

    const handleChange = (set) => (e) => {
        const value = e.target.value;
        const valueList = value.match(regField);
        set(valueList ? valueList[0] : '');
    };

    return (
        <>
            <div className="field" >
                <TextField
                    className={cn('textBlock', lat.length > 0 ? 'active' : '')}
                    value={lat}
                    onChange={handleChange(setLat)}
                    label="Широта (lat)"
                    variant="outlined"
                    size="small"
                    error={!lat}
                />
                <TextField
                    className={cn('textBlock', lon.length > 0 ? 'active' : '')}
                    value={lon}
                    onChange={handleChange(setLon)}
                    label="Долгота (lon)"
                    variant="outlined"
                    size="small"
                    error={!lon}
                />

                {/*{lat && lon && (*/}
                {/*    <IconButton>*/}
                {/*        <ArrowCircleRightIcon*/}
                {/*            onClick={() => {*/}
                {/*                onChange([lat, lon]);*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </IconButton>*/}
                {/*)}*/}
            </div>

            {lat && lon && (
                <FormButtonsComponent
                    justButton
                    buttons={[
                        {
                            fullWidth: true,
                            size: 'small',
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onChange([parseFloat(lat), parseFloat(lon)]);
                            },
                            style: { marginBottom: '.5rem' },
                            name: 'Применить'
                        }
                    ]}
                />
            )}
        </>
    );
}

export default CoordsSearch;
