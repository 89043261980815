import messages from 'helpers/constants/messages';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import { getHumanDate } from 'helpers/date.config';

import type { DtpItem } from './types';

const Item = ({ item }: { item: DtpItem }) => {

    const renderValues = (value: number | null) => {
        if (value === null) return messages.NO_VALUE;
        return value;
    };

    return (
        <LSContentItem>
            <LSContentColumn>
                {getHumanDate(item.dtp_date) || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {renderValues(item.count_dtp)}
            </LSContentColumn>
            <LSContentColumn>
                {renderValues(item.count_dead)}
            </LSContentColumn>
            <LSContentColumn>
                {renderValues(item.count_wounded)}
            </LSContentColumn>
            <LSContentColumn>
                {renderValues(item.count_dead_child)}
            </LSContentColumn>
            <LSContentColumn>
                {renderValues(item.count_wounded_child)}
            </LSContentColumn>
        </LSContentItem>
    );
};

export default Item;
