import * as types from './types';
import { getEndOf, getStartOf } from '../../helpers/date.config';

const initialState = {
    loading: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.SET_LOADING:
            return {
                ...state,
                loading: payload
            };

        default:
            return state;
    }
}
