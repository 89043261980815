import React from 'react';
import ComplexEvents from '../../../../../Reports/DorisControl/ComplexEvents';
import styles from '../../vfcomplex.module.scss';

function Events({ complex_id, currentComplex = {}, tab }) {
    return (<div className={styles.container}>
        <ComplexEvents complex_id={complex_id} currentComplex={currentComplex} tab={tab} />
    </div>);
}

export default Events;
