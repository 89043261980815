import { useRef, useState } from 'react';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import DateRange from 'components/common/Dates/DateRange';
import SelectVehicles from 'components/common/Autocomplete/Transport/Vehicles';
import SelectRoutes from 'components/common/Autocomplete/PassengerTransport/Routes';
import formatDate from 'helpers/constants/dateFormat';

const Filter = ({ filter, setFilter, onSearch }) => {
    const initialFilter = useRef(filter);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleChange = (value, name) => {
        setFilter(prev => ({
            ...prev, 
            [name]: value
        }));
    };

    const errorDateRange = () => {
        const {
            date_start = null,
            date_end = null,
        } = filter;

        return !date_start || !date_end;
    };

    const resetFilter = (needRefresh) => {
        setFilter(initialFilter.current);
        needRefresh && onSearch(initialFilter.current);
    };

    const updateFilter = (filters) => {
        const newFilter = { ...initialFilter.current, ...filters };
        setFilter(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };

    return (
        <LayoutFilter
            filter={filter}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            disabled={isDisabled}
            filterException={['date_start', 'date_end']}
        >
            <LayoutFilter.Visible>
                <DateRange  
                    handleDate={(newDate) => {
                        handleChange(newDate, 'date_start');
                    }}
                    handleEndDate={(newDate) => {
                        handleChange(newDate, 'date_end');
                    }}
                    valueStartDate={filter?.date_start || null}
                    valueEndDate={filter?.date_end || null}
                    isDisabledHandler={(value) => setIsDisabled(value)}
                    renderError={errorDateRange}
                    pickersFormat={formatDate.DATE_FOR_PICKER}
                    dateOnly
                    required
                />
            </LayoutFilter.Visible>

            <LayoutFilter.Invisible>
                <SelectVehicles
                    selected={filter.grz}
                    onChange={(value) => {
                        handleChange((value.number ? value : ''), 'grz');
                    }}
                    storeName="transportPassenger"
                />

                <SelectRoutes
                    selected={filter.route_num}
                    onChange={(value) => {
                        handleChange(value || '', 'route_num');
                    }}
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
