import React from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import useStoreProp from '../../../../../../../helpers/hooks/useStoreProp';
import { loadAlignHorizontal, loadAlignVertical } from '../../../../../../../redux/Boards/actions';

export const AlignPosition = ({ alignX, alignY, handleChange, required, helperTextX, helperTextY }) => {
    const alignXList = useStoreProp(
        loadAlignHorizontal,
        'boards',
        'alignX'
    );
    const alignYList = useStoreProp(
        loadAlignVertical,
        'boards',
        'alignY'
    );

    return (
        <div  className="row">
            <FormControl size="small" variant="outlined" required={required} className="row__item">
                <InputLabel>Положение по горизонтали</InputLabel>
                <Select
                    value={alignX || ''}
                    onChange={handleChange}
                    label="Положение по горизонтали"
                    name="align-x"
                    error={!alignX}
                    required={required}
                    fullWidth
                >
                    {Object.entries(alignXList).map(([key, value]) => (
                        <MenuItem value={key} key={key} >
                            {value}
                        </MenuItem>
                    ))}
                </Select>
                {helperTextX && <FormHelperText error>{helperTextX}</FormHelperText>}
            </FormControl>
            <FormControl size="small" variant="outlined" required={required} className="row__item">
                <InputLabel>Положение по вертикали</InputLabel>
                <Select
                    value={alignY || ''}
                    onChange={handleChange}
                    label="Положение по вертикали"
                    name="align-y"
                    error={!alignY}
                    required={required}
                >
                    {Object.entries(alignYList).map(([key, value]) => (
                        <MenuItem value={key} key={key} >
                            {value}
                        </MenuItem>
                    ))}
                </Select>
                {helperTextY && <FormHelperText error>{helperTextY}</FormHelperText>}
            </FormControl>
        </div>
    );
};