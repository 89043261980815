import { useState } from 'react';
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';
import cn from 'classnames';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import titles from '../../../../../helpers/constants/titles';
import FormControl from '@mui/material/FormControl';
import KeyWords from '../../../../common/Autocomplete/DorisReact/KeyWords';
// import SelectEvents from '../../../../common/Autocomplete/DorisReact/Events';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
// import EventObjects from '../../../../common/Autocomplete/DorisReact/EventObjects';
import Operations from '../../../../common/Autocomplete/DorisReact/Operations';

const Filter = ({ setParams }) => {

    const initialState = {
        name: '',
        is_typical: '',
        category_id: '',
        // event: {},
        // event_id: null,
        // event_object: {},
        // event_object_id: null,
        keyword_list: [],
        keyword_id_list: [],
        operation: {},
        operation_name: null,
        comment: '',
    };

    const [filterData, setFilterData] = useState(initialState);
    const [wasSearch, setWasSearch] = useState(false);

    const handleResetFilter = () => {
        setFilterData(initialState);
        if (wasSearch) {
            setParams({ page: 1, data: {} });
            setWasSearch(false);
        }
    };

    const handleSearch = () => {
        setWasSearch(true);

        const transformationDataForSend = removeEmptyFields({
            ...filterData,
            // event: {},
            keyword_list: [],
            // event_object: {},
            operation: {},
        });

        setParams({ page: 1, data: transformationDataForSend });
    };

    const onChange = (name, value) => {
        setFilterData({
            ...filterData,
            [name]: value
        });
    };


    const handleClickEnter = (e) => {
        if (e?.key === 'Enter') handleSearch();
    };

    const isDisabled = Object.keys(removeEmptyFields(filterData, false)).length === 0;

    return (
        <div className="filter">
            <div className={cn('filter__wrap', 'filter__wrap__more__field')}>
                <TextField
                    label={titles.NAME}
                    size="small"
                    value={filterData.name}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    onKeyPress={handleClickEnter}
                />
                <FormControl
                    variant="outlined"
                    size="small"
                >
                    <InputLabel>Типовой/Нетиповой</InputLabel>
                    <Select
                        name="is_typical"
                        label="Типовой/Нетиповой"
                        value={filterData.is_typical}
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                    >
                        <MenuItem value="1">Типовой</MenuItem>
                        <MenuItem value="0">Нетиповой</MenuItem>
                    </Select>
                </FormControl>
                <KeyWords
                    multiple
                    selected={filterData.keyword_list}
                    onChange={(value) => setFilterData({
                        ...filterData,
                        keyword_list: value,
                        keyword_id_list: value.map(({ id }) => id)
                    })}
                />
                {/*<SelectEvents*/}
                {/*    selected={filterData.event}*/}
                {/*    onChange={(value) => setFilterData({*/}
                {/*        ...filterData,*/}
                {/*        event: value,*/}
                {/*        event_id: value?.id || null*/}
                {/*    })}*/}
                {/*    label={titles.EVENT}*/}
                {/*/>*/}
                {/*<EventObjects*/}
                {/*    selected={filterData.event_object}*/}
                {/*    onChange={(value) => setFilterData({*/}
                {/*        ...filterData,*/}
                {/*        event_object: value,*/}
                {/*        event_object_id: value?.id || null*/}
                {/*    })}*/}
                {/*/>*/}
                <Operations 
                    selected={filterData.operation}
                    onChange={(value) => setFilterData({
                        ...filterData,
                        operation: value,
                        operation_name: value?.name || null
                    })}
                />
                <TextField
                    label={titles.COMMENT}
                    size="small"
                    value={filterData.comment}
                    variant="outlined"
                    name="comment"
                    type="text"
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    onKeyPress={handleClickEnter}
                />
            </div>
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: handleResetFilter,
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: handleSearch,
                            disabled: isDisabled
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;
