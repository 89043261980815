import React, { useState } from 'react';
import styles from './routepopup.module.scss';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
} from '@mui/lab';
import { makeStyles } from '@mui/styles';
import messages from '../../../../../helpers/constants/messages';
import FormButtonsComponent from '../../../../common/FormButtons';

const useStyles = makeStyles({
    timelineItem: {
        minHeight: '30px',
        '&:before': {
            display: 'none'
        },
    },
    dot: {
        marginTop: 0,
        marginBottom: 0
    },
    content: {
        padding: '0 16px'
    }
});

const RouteData = (props) => {
    const classes = useStyles();
    const { route } = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    let vehicleListForward = [];
    let vehicleListBackward = [];

    // нет данных
    if (Array.isArray(route.directions) && route.directions[0]) {
        if (route.directions[0].vehicles && route.directions[0].vehicles.length !== 0) {
            vehicleListForward = route.directions[0].vehicles.map((v, i) => (
                <span key={i} className={styles.item}>
                    {v.vehicleLabel}
                </span>
            ));
        }
    }

    // нет данных
    if (Array.isArray(route?.directions) && route?.directions[1]) {
        if (route.directions[1].vehicles && route.directions[1].vehicles.length !== 0) {
            vehicleListBackward = route.directions[1].vehicles.map((v, i) => (
                <span key={i} className={styles.item}>
                    {v.vehicleLabel}
                </span>
            ));
        }
    }

    const carriers =Array.isArray(route?.enterprises)
        && route.enterprises.map((c) => c.title).join(', ');

    const renderStations = () => route?.stations?.map((el, index) => (
        <TimelineItem key={el.id} classes={{ root: classes.timelineItem }}>
            <TimelineSeparator>
                <TimelineDot classes={{ root: classes.dot }} variant="outlined" />
                {index !== (route.stations.length - 1) && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent classes={{ root: classes.content }}>{el.name}</TimelineContent>
        </TimelineItem>
    ));

    return (
        <div className={styles.route_popup}>
            <div className={styles.info__title}>{route?.name} {route?.is_direct_text && (<small>({route?.is_direct_text})</small>)}</div>
            <div className={styles.info__carriers}>
                <strong>Перевозчик(и):</strong> {carriers}
            </div>

            <FormButtonsComponent
                noPadding
                positionLeft
                noMarginLeft
                buttons={[
                    {
                        onClick: handleToggle,
                        name: <span>Трасса маршрута <i style={{ marginLeft: '.5rem' }} className={`fas fa-angle-${isOpen ? 'up' : 'down'}`}></i></span>
                    }
                ]}
            />

            {isOpen
             && (<section className={styles.routes_wrapper}>
                 {route?.stations?.length > 0
                     ? <Timeline>
                         {renderStations()}
                     </Timeline>
                     : <div>{messages.NO_DATA}</div>
                 }
             </section>)
            }
        </div>
    );
};

export default RouteData;
