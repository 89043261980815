import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    loadShowCaseComplexStatus,
    clearShowCaseComplexStatus
} from '../../../../../redux/DorisControl/actions';
import { dorisControlSelectors } from '../../../../../redux/DorisControl';
import ListSimple from '../../../../common/Showcase/ListSimple';


const First = () => {
    const dispatch = useDispatch();
    const complexStatistic = useSelector(dorisControlSelectors.showCaseComplexStatus);

    useEffect(() => {
        dispatch(loadShowCaseComplexStatus());
        return () => dispatch(clearShowCaseComplexStatus());
    }, []);

    return (
        <ListSimple
            data={complexStatistic}
            itemClass="small"
            // itemClass="big"
            // separator=":"
        />
    );
};

export default First;