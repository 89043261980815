import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    IconButton,
    StyledEngineProvider,
    ThemeProvider,
    Tooltip,
} from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { theme } from 'App';

import { roadworksSelectors } from 'redux/RoadWorks';
import messages from 'helpers/constants/messages';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import PassportPage from 'components/pages/Dictionaries/RoadWorks/Orders/PassportPage';


const TabInformation = ({ data, title, history, readOnly, reloadList }) => {
    const ordersData = useSelector(roadworksSelectors.ordersData);

    const [openedOrder, setOpenedOrder] = useState(null);

    useEffect(() => {
        // Обновить PassportPage при изменении ordersData
        setOpenedOrder(prev => prev ? ordersData.find(el => el.id === prev.id) : null);
    }, [ordersData]);

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} locale={'ru'}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <div className="scroll">
                        {Object.keys(title).map((key) => {
                            const value = data[key];

                            if ((value !== null && value !== undefined) || (key === 'engine' || key === 'fuel')) {
                                return (
                                    <div className="info__item" key={`information_${key}`}>
                                        <span className="info__item-title">
                                            {title[key].title || ''}
                                            {': '}
                                        </span>
                                        <span>
                                            {title[key]['value']
                                                ? title[key].value(data)
                                            // ? title[key].value(value)
                                                : value || messages.NO_DATA}
                                        </span>
                                    </div>
                                );
                            }
                            return null;
                        })}
                        {ordersData?.length > 0
                            && <>
                                <h2 style={{ marginLeft: 10 }}>Заявки: </h2>
                                {ordersData?.map(item =>
                                    <Fragment key={item.id}>
                                        <div className="info__item" style={{ justifyContent: 'space-between' }}>
                                            <div>
                                                <span className="info__item-title">
                                                    Дата:
                                                </span>
                                                {fullDateTimeWithoutSeconds(item.created_at)}{', '}
                                                <span className="info__item-title">
                                                    Номер:
                                                </span>
                                                {item.id}{', '}
                                                <span className="info__item-title">
                                                    Название:
                                                </span>
                                                {item.name}
                                            </div>
                                            {!readOnly
                                                && <Tooltip title="Открыть заявку">
                                                    {/* <IconButton size="small" onClick={() => routeToOrder(item.id)}>
                                                        <i className="fas fa-external-link-alt" />
                                                    </IconButton> */}
                                                    <IconButton size="small" onClick={() => setOpenedOrder(item)}>
                                                        <i className="fas fa-external-link-alt" />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </div>
                                        <hr/>
                                    </Fragment>
                                )}
                            </>
                        }
                        {
                            <Modal
                                isOpen={!!openedOrder}
                                onClose={() => setOpenedOrder(null)}
                                noPadding={true}
                                title={data?.name}
                                fullWidth
                                showCloseInTitle
                                buttons={<FormButtons
                                    buttons={[
                                        {
                                            ...buttonsTypes.close,
                                            onClick: () => setOpenedOrder(null)
                                        },
                                    ]}
                                />}
                            >
                                <PassportPage
                                    currentOrder={openedOrder}
                                    tab="executionStages"
                                    vehicle={data}
                                    reloadList={reloadList}
                                />
                            </Modal>
                        }

                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        </LocalizationProvider>
    );
};

export default TabInformation;
