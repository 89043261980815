import React, { memo } from 'react';
import RenderImageMessage from '../../RenderImageMessage';

export const Image = memo((props) => {
    const { onChange, data } = props;

    const handleChange = (i, el) => {
        const newData = [
            ...data.slice(0, i),
            el,
            ...data.slice(i + 1)
        ];
        onChange(newData);
    };

    return (
        <>
            <h2>Изображение и фон:</h2>
            {data.map((el, index) => (
                <RenderImageMessage
                    key={index}
                    item={el}
                    onChange={(item) => handleChange(index, item)}
                />
            ))}
        </>
    );
});