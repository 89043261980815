import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { authSelectors } from 'redux/Auth';
import { authByUserId } from 'redux/Auth/actions';
import ConfirmModal from 'components/common/ConfirmModal';

import type { User } from 'types/Admin';

interface AuthByUserProps {
    isOpen: boolean
    onClose: () => void
    user: User
}

function AuthByUser({ isOpen, onClose, user }: AuthByUserProps) {
    const history = useHistory();
    const dispatch = useDispatch();

    const loadingButton = useSelector(authSelectors.loadingButton);

    const handleAuth = () => {
        dispatch(authByUserId(user.id, () => {
            onClose();
            history.push('/');
        }));
    };

    const fullName = `${user.last_name || ''} ${user.first_name || ''} ${user.middle_name || ''}`;

    return (
        <>
            {isOpen && (
                <ConfirmModal
                    open={isOpen}
                    onClose={onClose}
                    message={`Вы действительно хотите войти пользователем ${fullName}`}
                    onSuccess={handleAuth}
                    loadingButton={loadingButton}
                />
            )}
        </>
    );
}

export default AuthByUser;
