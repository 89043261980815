import React from 'react';
import MaskedInput from 'react-text-mask';

const TextMaskIP = React.forwardRef((props, inputRef) => (
    <MaskedInput
        guide={false}
        mask={(value) => {
            const mask = Array(value.length).fill(/[\d.]/);
            const chunks = value.split('.');

            for (let i = 0; i < 4; ++i) {
                const chunk = (chunks[i] || '');

                if (255 % +chunk === 255) {
                    mask[value.length - 1] = '.';
                    mask[value.length] = /[\d.]/;
                }
            }

            return mask;
        }}
        pipe={(value) => {
            if (value === '.' || value.endsWith('..')) return false;

            const parts = value.split('.');
        
            if (parts.length > 4 || parts.some(part => part === '00' || part < 0 || part > 255)) 
            {
                return false;
            }
            return value;
        }}
        {...props}
        ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
        }}
        placeholder="0.0.0.0"
    />
));

export default TextMaskIP;
