import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';
// const tempMenuData = [
//     {
//         id: 1,
//         name: 'Карты',
//         icon: 'fas fa-map-marked-alt',
//         children: [
//             {
//                 id: 2,
//                 name: 'Пассажирский транспорт',
//                 icon: 'fas fa-bus-alt',
//                 link: '/map',
//                 layer: 'routes',
//             },
//             {
//                 id: 3,
//                 name: 'Дорожные работы',
//                 icon: 'fas fa-digging',
//                 link: '/map',
//                 layer: 'roadworks',
//             },
//             {
//                 id: 4,
//                 name: 'Дорожная техника',
//                 icon: 'fas fa-tractor',
//                 link: '/map',
//                 layer: 'roadwork-transport',
//             },
//             {
//                 id: 5,
//                 name: 'Транспортные инциденты',
//                 icon: 'fas fa-car-crash',
//                 link: '/map',
//                 layer: 'incidents',
//             },
//             {
//                 id: 6,
//                 name: 'Комплексы фото-видео фиксации',
//                 icon: 'fas fa-cctv',
//                 link: '/map',
//                 layer: 'camera',
//             },
//             {
//                 id: 7,
//                 name: 'Видеоаналитика',
//                 icon: 'fas fa-cctv',
//                 link: '/map',
//                 layer: 'videoanalytics',
//             },
//             {
//                 id: 8,
//                 name: 'Эко мониторинг',
//                 icon: 'fas fa-leaf',
//                 link: '/map',
//                 layer: 'ecology',
//             },
//             {
//                 id: 9,
//                 name: 'Метео мониторинг',
//                 icon: 'fas fa-cloud-sun',
//                 link: '/map',
//                 layer: 'meteo',
//             },
//         ],
//     },
//     {
//         id: 10,
//         name: 'Отчеты',
//         icon: 'fas fa-chart-bar',
//         children: [
//             {
//                 id: 11,
//                 name: 'Отчеты по дорожным работам',
//                 link: '/reports/roadworks',
//                 icon: 'fas fa-digging',
//             },
//             {
//                 id: 12,
//                 name: 'Отчеты по ДТП',
//                 link: '/reports/dtp',
//                 icon: 'fas fa-car-crash',
//             },
//             {
//                 id: 13,
//                 name: 'Отчеты по видеоаналитике',
//                 link: '/reports/videoanalytics',
//                 icon: 'fas fa-cctv',
//             },
//             {
//                 id: 14,
//                 name: 'Отчеты по организациям',
//                 link: '/reports/companies',
//                 icon: 'fas fa-building',
//             },
//             {
//                 id: 15,
//                 name: 'Отчеты по транспортным средствам',
//                 link: '/reports/vehicles',
//                 icon: 'fas fa-bus',
//             },
//         ],
//     },
//     {
//         id: 16,
//         name: 'Справочники',
//         icon: 'fas fa-list-alt',
//         children: [
//             {
//                 id: 17,
//                 name: 'Пассажирский транспорт',
//                 icon: 'fas fa-bus-alt',
//                 children: [
//                     {
//                         id: 18,
//                         name: 'Маршруты',
//                         link: '/dictionaries/passenger-transport/routes',
//                         icon: 'fas fa-route',
//                     },
//                     {
//                         id: 19,
//                         name: 'Остановки',
//                         link: '/dictionaries/passenger-transport/stations',
//                         icon: 'fas fa-sign',
//                     },
//                     {
//                         id: 20,
//                         name: 'Организации',
//                         link: '/dictionaries/passenger-transport/companies',
//                         icon: 'fas fa-building',
//                     },
//                     {
//                         id: 21,
//                         name: 'Транспортные средства',
//                         link: '/dictionaries/passenger-transport/vehicles',
//                         icon: 'fas fa-bus',
//                     },
//                     {
//                         id: 22,
//                         name: 'Марки',
//                         link: '/dictionaries/passenger-transport/brands',
//                         icon: 'far fa-steering-wheel',
//                     },
//                     {
//                         id: 23,
//                         name: 'Модели',
//                         link: '/dictionaries/passenger-transport/models',
//                         icon: 'fas fa-caret-right',
//                     },
//                 ],
//             },
//             {
//                 id: 24,
//                 name: 'Дорожные работы',
//                 icon: 'fas fa-digging', //fas fa-snowplow
//                 children: [
//                     {
//                         id: 25,
//                         name: 'Дорожная техника',
//                         link: '/dictionaries/roadworks/vehicles',
//                         icon: 'fas fa-tractor',
//                     },
//                     {
//                         id: 26,
//                         name: 'Обслуживающие организации',
//                         link: '/dictionaries/roadworks/companies',
//                         icon: 'fas fa-building',
//                     },
//                     {
//                         id: 27,
//                         name: 'Марки',
//                         link: '/dictionaries/roadworks/brands',
//                         icon: 'far fa-steering-wheel',
//                     },
//                     {
//                         id: 28,
//                         name: 'Модели',
//                         link: '/dictionaries/roadworks/models',
//                         icon: 'fas fa-caret-right',
//                     },
//                 ],
//             },
//             {
//                 id: 29,
//                 name: 'Транспортные инциденты',
//                 icon: 'fas fa-car-crash',
//                 children: [
//                     {
//                         id: 30,
//                         name: 'Типы транспортных инцидентов',
//                         link: '/dictionaries/incidents/types',
//                         icon: 'far fa-sensor-alert',
//                     },
//                     {
//                         id: 31,
//                         name: 'Типы ДТП',
//                         link: '/dictionaries/incidents/dtp-types',
//                         icon: 'far fa-car-bump',
//                     },
//                     {
//                         id: 32,
//                         name: ' Сценарии реагирования',
//                         link: '/dictionaries/incidents/schemas',
//                         icon: 'far fa-sitemap',
//                     },
//                     {
//                         id: 33,
//                         name: 'Ответственные организации',
//                         link: '/dictionaries/incidents/companies',
//                         icon: 'fas fa-building',
//                     },
//                 ],
//             },
//             {
//                 id: 34,
//                 name: 'Комплексы фото-видео фиксации',
//                 icon: 'fas fa-video',
//                 children: [
//                     {
//                         id: 35,
//                         name: 'Видеокамеры',
//                         link: '/dictionaries/video-fixation/cameras',
//                         icon: 'fas fa-cctv',
//                     },
//                 ],
//             },
//             {
//                 id: 49,
//                 name: 'Видеоаналитика',
//                 icon: 'fas fa-video',
//                 children: [
//                     {
//                         id: 50,
//                         name: 'Видеокамеры',
//                         link: '/dictionaries/video-analytics/cameras',
//                         icon: 'fas fa-cctv',
//                     },
//                 ],
//             },
//         ],
//     },
//     {
//         id: 36,
//         name: 'Администрирование',
//         icon: 'fas fa-user-shield',
//         children: [
//             {
//                 id: 37,
//                 name: 'Ведение транспортных инцедентов',
//                 link: '/admin/incidents-process',
//                 icon: 'fas fa-file-contract',
//             },
//             {
//                 id: 38,
//                 name: 'Управление ролями',
//                 link: '/admin/roles',
//                 icon: 'far fa-user-tag',
//             },
//             {
//                 id: 39,
//                 name: 'Управление пользователями',
//                 link: '/admin/users',
//                 icon: 'fas fa-users',
//             },
//             {
//                 id: 40,
//                 name: 'Управление меню',
//                 link: '/admin/menu',
//                 icon: 'fas fa-list-ul',
//             },
//             {
//                 id: 41,
//                 name: 'Статус сервисов',
//                 link: '/admin/services',
//                 icon: 'fas fa-battery-half',
//             },
//             {
//                 id: 42,
//                 name: 'Настройки отчета по ДТП',
//                 link: '/admin/dtp-settings',
//                 icon: 'fas fa-car-crash',
//             },
//         ],
//     },
//     {
//         id: 43,
//         name: 'Профиль',
//         icon: 'fas fa-user-circle',
//         children: [
//             {
//                 id: 44,
//                 name: 'Сменить пароль',
//                 link: '/profile/change-password',
//                 icon: 'fas fa-key',
//             },
//             {
//                 id: 45,
//                 name: 'Редактировать',
//                 link: '/profile/edit',
//                 icon: 'far fa-user-edit',
//             }
//         ]
//     },
//     {
//         id: 46,
//         name: 'Выход',
//         icon: 'fas fa-sign-out-alt',
//         children: [
//             {
//                 id: 47,
//                 name: 'Выход',
//                 link: '/logout',
//                 icon: 'fas fa-sign-out-alt',
//             },
//             {
//                 id: 48,
//                 name: 'Выход со всех устройств',
//                 link: '/logout/all',
//                 icon: 'fas fa-sign-out-alt',
//             }
//         ]
//     }
// ];

// export const getMenuData = () => {
//     return new Promise(
//         (resolve) =>
//             // setTimeout(() => {
//             resolve(tempMenuData)
//         // }, 2000)
//     ).then((r) => r);
// };

const apiMenuMethods = {
    getScenarios: async (access_token) => {
        const response = await instance.get(apiUrls.getScenarios());
        return response.data;
    },

    getScopes: async (scenario, access_token) => {
        const response = await instance.get(apiUrls.getScopes(scenario));
        return response.data;
    },

    getMenus: async (access_token, service) => {
        const response = await instance.get(apiUrls.getAllMenus(service));
        return response.data;
    },

    getMenusForScope: async (access_token, scope, service, scenario) => {
        const response = await instance.get(apiUrls.getMenusForScope(scope, service, scenario));
        return response.data;
    },

    createMenuItem: async (data, access_token) => {
        const response = await instance.post(apiUrls.createMenuItem(),
            data);
        return response.data;
    },

    editMenuItem: async (id, data, access_token) => {
        const response = await instance.put(apiUrls.editMenuItem(id),
            data);
        return response.data;
    },

    deleteMenuItem: async (id, access_token) => {
        const response = await instance.delete(apiUrls.deleteMenuItem(id));
        return response.data;
    }
};

export default apiMenuMethods;
