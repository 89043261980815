export const transportSpecial = (state) => state.transportSpecial;

export const sidebar = (state) => transportSpecial(state).sidebar;
// export const sidebarData = (state) => sidebar(state).data;
// export const sidebarMeta = (state) => sidebar(state).meta;
export const sidebarLoading = (state) => transportSpecial(state).sidebarLoading;

export const sidebarParams = (state) => transportSpecial(state).sidebarParams;

export const filters = (state) => transportSpecial(state).filters;
export const active = (state) => transportSpecial(state).active;
export const wsData = (state) => transportSpecial(state).wsData;

export const routesHistory = (state) => transportSpecial(state).routesHistory;

// export const coords = (state) => transportSpecial(state).coords;
// export const coordsLoading = (state) => transportSpecial(state).coordsLoading;

export const vehicles = (state) => transportSpecial(state).vehicles;
export const vehiclesData = (state) => vehicles(state).data;
export const vehiclesMeta = (state) => vehicles(state).meta;
export const loadingVehicles = (state) => transportSpecial(state).loadingVehicles;

// export const vehicleCategories = (state) => transportSpecial(state).vehicleCategories;
// export const vehicleCategoriesLoaded = (state) => transportSpecial(state).vehicleCategoriesLoaded;
// export const vehicleCharacteristics = (state) => transportSpecial(state).vehicleCharacteristics;

export const brands = (state) => transportSpecial(state).brands;
export const brandsData = (state) => brands(state).data;
export const brandsMeta = (state) => brands(state).meta;
export const loadingBrands = (state) => transportSpecial(state).loadingBrands;

export const models = (state) => transportSpecial(state).models;
export const modelsData = (state) => models(state).data;
export const modelsMeta = (state) => models(state).meta;
export const loadingModels = (state) => transportSpecial(state).loadingModels;

// export const radioTypes = (state) => transportSpecial(state).radioTypes;
// export const loadingRadioTypes = (state) => transportSpecial(state).loadingRadioTypes;

export const vehicleCard = (state) => transportSpecial(state).vehicleCard;
export const loadingVehicleCard = (state) => transportSpecial(state).loadingVehicleCard;

// export const vehicleTypes = (state) => transportSpecial(state).vehicleTypes;
// export const loadingVehicleTypes = (state) => transportSpecial(state).loadingVehicleTypes;

export const vehicleFilteredList = (state) => transportSpecial(state).vehicleFilteredList;
export const vehicleFilteredListData = (state) => vehicleFilteredList(state).data;
export const vehicleFilteredListMeta = (state) => vehicleFilteredList(state).meta;
export const loadingVehicleFilteredList = (state) => transportSpecial(state).loadingVehicleFilteredList;
export const vehicleFilteredListFilter = (state) => transportSpecial(state).vehicleFilteredListFilter;
// export const filter = (state) => transportSpecial(state).filter;

export const history = (state) => transportSpecial(state).history;
export const historyLoading = (state) => transportSpecial(state).historyLoading;

// export const colorSpeed = (state) => transportSpecial(state).colorSpeed;
// export const colorSpeedList = (state) => transportSpecial(state).colorSpeedList;
// export const colorSpeedListLoading = (state) => transportSpecial(state).colorSpeedListLoading;
// export const colorSpeedListProps = (state) => transportSpecial(state).colorSpeedListProps;
// export const historyModal = (state) => transportSpecial(state).historyModal;
// export const historyModalLoading = (state) => transportSpecial(state).historyModalLoading;

export const loadingButton = (state) => transportSpecial(state).loadingButton;

export const telemetryReceivers = (state) => transportSpecial(state).telemetryReceivers;
export const telemetryReceiversData = (state) => telemetryReceivers(state).data;
export const telemetryReceiversMeta = (state) => telemetryReceivers(state).meta;
// export const telemetryReceiversLoading = (state) => transportSpecial(state).telemetryReceiversLoading;

export const telemetryReceiverTypes = (state) => transportSpecial(state).telemetryReceiverTypes;

export const vehicleCategoryList = (state) => transportSpecial(state).vehicleCategoryList;
export const loadingVehicleCategoryList = (state) => transportSpecial(state).loadingVehicleCategoryList;

export const reportVehicleMileage = (state) => transportSpecial(state).reportVehicleMileage;
export const loadingReportByVehicles = (state) => transportSpecial(state).loadingReportByVehicles;