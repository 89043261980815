import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import messages from '../../../../../helpers/constants/messages/common';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from '../../../../../helpers/date.config';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import { incidentsSelectors } from '../../../../../redux/Incidents';
import { loadDtpByPercent } from '../../../../../redux/Incidents/actions';
import ReportBlock from '../../../../common/Report/ReportBlock';
import PageLayout from '../../../../layout/PageLayout';

import Filters from './Filters';
import Graph from './Graph';

function DtpReportTypes() {
    const dispatch =  useDispatch();

    const dtpReportByPercent = useSelector(incidentsSelectors.dtpReportByPercent);
    const loadingDtpReportByPercent = useSelector(incidentsSelectors.loadingDtpReportByPercent);

    const initialState = {
        start_date: getStartOf(),
        end_date: getEndOf(),
        dtp_type: [],
        type_load_list: [],
    };

    const [filterValues, setFilterValues] = useState(initialState);

    const getFilter = (filters) => {
        const result = {
            ...filters,
            start_date: fullDateTimeWithTimeZone(filters.start_date),
            end_date: fullDateTimeWithTimeZone(filters.end_date),
            dtp_type: filters.dtp_type.map(({ id }) => id),
            type_load_list: filters.type_load_list.map(({ id }) => id)
        };
        return removeEmptyFields(result);
    };

    const getGraph = (filter) => {
        const result = getFilter(filter);
        dispatch(loadDtpByPercent(result));
    };

    const getReport = (format) => {
        const result = getFilter(filterValues);
        dispatch(loadDtpByPercent({ ...result, report: format }));
    };

    const renderContent = () => {
        return dtpReportByPercent.length > 0
            ? <Graph />
            : (!loadingDtpReportByPercent && <div>{messages.REQUEST_DATA_IS_NOT_FOUND}</div>);
    };

    const resetFilters = (needRefresh) => {
        if (needRefresh) {
            getGraph(initialState);
        }
        setFilterValues(initialState);
    };

    return (
        <PageLayout
            header="Отчет по типам"
            filters={<Filters
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                initialState={initialState}
                getGraph={getGraph}
                resetFilters={resetFilters}
            />}
            content={renderContent}
            loading={loadingDtpReportByPercent}
            informPanelProps={{
                buttons: <ReportBlock
                    onGetReport={getReport}
                />
            }}
        />
    );
}

export default DtpReportTypes;