import * as types from './types';

const initialState = {
    settings: {
        data: [],
    },
    loadingSettings: false,
    oneGraph: {},
    loadingOneGraph: false,
    groupGraph: [],
    loadingGroupGraph: false,
    dashboard: [],
    dashboardLoading: false,

    efficiencyDtp: [],
    loadingEfficiencyDtp: false,
    filterEfficiencyDtp: {},
    loadingGraphList: false,
    graphList: [],

    loadingGraphPeriodList: false,
    graphPeriodList: [],
    //
    efficiencyTraffic: {},
    loadingEfficiencyTraffic: false,

    userAvailableDashboardList: {},
    loadingUserAvailableDashboardList: false,

    userDashboard: {},
    loadingUserDashboard: false,

    trafficForecast: [],
    loadingTrafficForecast: false,

    criticalSettings: {},
    loadingCriticalSettings: false,
    criticalSettingsKeys: {},

    loadingButton: false,

    reportEfficiencyDtpReport: [],
    loadingReportEfficiencyDtpReport: false,
    filterReportEfficiencyDtpReport: {},
    showcase: {},
    loadingShowcase: false,

    settingsGroup: { data: [] },
    loadingSettingsGroup: false,

    itsDevelopmentLevels: {
        data: [],
        meta: {}
    },
    loadingItsDevelopmentLevels: false,

    infrastructureStatistics: {
        data: [],
        meta: {}
    },
    loadingInfrastructureStatistics: false,

    dashboards: [],
    dashboardsItem: {
        data: [],
        meta: {}
    },
    loadingDashboards: false,

    dashboardItemUser: [],
    loadingDashboardItemUser: false,
};


export default function reducer (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.LOADED_SETTINGS:
            return {
                ...state,
                settings: payload,
            };

        case types.LOADING_SETTINGS:
            return {
                ...state,
                loadingSettings: payload,
            };

        case types.LOADED_ONE_GRAPH:
            return {
                ...state,
                oneGraph: payload,
            };
        case types.CLEAR_ONE_GRAPH:
            return {
                ...state,
                oneGraph: initialState.oneGraph,
                loadingOneGraph: initialState.loadingOneGraph,
            };
        case types.LOADING_ONE_GRAPH:
            return {
                ...state,
                loadingOneGraph: payload,
            };

        case types.LOADED_GROUP_GRAPH:
            return {
                ...state,
                groupGraph: payload,
            };
        case types.LOADING_GROUP_GRAPH:
            return {
                ...state,
                loadingGroupGraph: payload,
            };

        case types.CLEAR_GROUP_GRAPH:
            return {
                ...state,
                loadingGroupGraph: initialState.loadingGroupGraph,
                groupGraph: initialState.groupGraph,
            };

        case types.LOADED_DASHBOARD:
            return {
                ...state,
                dashboard: payload,
            };
        case types.LOADING_DASHBOARD:
            return {
                ...state,
                dashboardLoading: payload,
            };

        case types.LOADED_EFFICIENCY_DTP:
            return {
                ...state,
                efficiencyDtp: payload,
            };

        case types.LOADING_EFFICIENCY_DTP:
            return {
                ...state,
                loadingEfficiencyDtp: payload,
            };

        case types.SET_FILTER_EFFICIENCY_DTP:
            return {
                ...state,
                filterEfficiencyDtp: payload,
            };
        case types.LOADING_GRAPH_LIST:
            return {
                ...state,
                loadingGraphList: payload,
            };
        case types.LOADED_GRAPH_LIST:
            return {
                ...state,
                graphList: payload,
            };

        case types.LOADING_GRAPH_PERIOD_LIST:
            return {
                ...state,
                loadingGraphPeriodList: payload,
            };
        case types.LOADED_GRAPH_PERIOD_LIST:
            return {
                ...state,
                graphPeriodList: payload,
            };

        case types.LOADED_EFFICIENCY_TRAFFIC:
            return {
                ...state,
                efficiencyTraffic: payload,
            };
        case types.LOADING_EFFICIENCY_TRAFFIC:
            return {
                ...state,
                loadingEfficiencyTraffic: payload,
            };

        case types.LOADED_USER_AVAILABLE_DASHBOARD_LIST:
            return {
                ...state,
                userAvailableDashboardList: payload,
            };
        case types.LOADING_USER_AVAILABLE_DASHBOARD_LIST:
            return {
                ...state,
                loadingUserAvailableDashboardList: payload,
            };
        case types.LOADED_USER_DASHBOARD:
            return {
                ...state,
                userDashboard: payload,
            };
        case types.LOADING_USER_DASHBOARD:
            return {
                ...state,
                loadingUserDashboard: payload,
            };

        case types.LOADED_TRAFFIC_FORECAST:
            return {
                ...state,
                trafficForecast: payload,
            };
        case types.LOADING_TRAFFIC_FORECAST:
            return {
                ...state,
                loadingTrafficForecast: payload,
            };

        case types.LOADED_CRITICAL_SETTINGS:
            return {
                ...state,
                criticalSettings: payload,
            };

        case types.LOADING_CRITICAL_SETTINGS:
            return {
                ...state,
                loadingCriticalSettings: payload,
            };

        case types.LOADED_CRITICAL_SETTINGS_KEYS:
            return {
                ...state,
                criticalSettingsKeys: payload,
            };

        case types.LOADING_BUTTON: {
            return {
                ...state,
                loadingButton: payload,
            };
        }

        case types.LOADED_REPORT_EFFICIENCY_DTP_REPORT: {
            return {
                ...state,
                reportEfficiencyDtpReport: payload,
            };
        }
        case types.LOADING_REPORT_EFFICIENCY_DTP_REPORT: {
            return {
                ...state,
                loadingReportEfficiencyDtpReport: payload,
            };
        }
        case types.SET_FILTER_REPORT_EFFICIENCY_DTP_REPORT: {
            return {
                ...state,
                filterReportEfficiencyDtpReport: payload,
            };
        }

        case types.LOADED_SHOWCASE: {
            return {
                ...state,
                showcase: payload
            };
        }

        case types.LOADING_SHOWCASE: {
            return {
                ...state,
                loadingShowcase: payload
            };
        }

        case types.LOADED_SETTINGS_GROUP:
            return {
                ...state,
                settingsGroup: payload
            };

        case types.LOADING_SETTINGS_GROUP:
            return {
                ...state,
                loadingSettingsGroup: payload
            };

        case types.LOADED_ITS_DEVELOPMENT_LEVELS: {
            return {
                ...state,
                itsDevelopmentLevels: payload
            };
        }

        case types.CLEAR_ITS_DEVELOPMENT_LEVELS: {
            return {
                ...state,
                itsDevelopmentLevels: initialState.itsDevelopmentLevels,
                loadingItsDevelopmentLevels: initialState.loadingItsDevelopmentLevels,
            };
        }

        case types.LOADING_ITS_DEVELOPMENT_LEVELS: {
            return {
                ...state,
                loadingItsDevelopmentLevels: payload
            };
        }

        case types.LOADED_INFRASTRUCTURE_STATISTICS: {
            return {
                ...state,
                infrastructureStatistics: payload
            };
        }

        case types.CLEAR_INFRASTRUCTURE_STATISTICS: {
            return {
                ...state,
                infrastructureStatistics: initialState.infrastructureStatistics,
                loadingInfrastructureStatistics: initialState.loadingInfrastructureStatistics,
            };
        }

        case types.LOADING_INFRASTRUCTURE_STATISTICS: {
            return {
                ...state,
                loadingInfrastructureStatistics: payload
            };
        }

        case types.LOADED_DASHBOARDS: {
            return {
                ...state,
                dashboards: payload
            };
        }
        case types.LOADED_DASHBOARDS_ITEM: {
            return {
                ...state,
                dashboardsItem: payload
            };
        }
        case types.LOADING_DASHBOARDS: {
            return {
                ...state,
                loadingDashboards: payload
            };
        }
        case types.LOADED_DASHBOARD_ITEM_USER: {
            return {
                ...state,
                dashboardItemUser: payload
            };
        }
        case types.LOADING_DASHBOARD_ITEM_USER: {
            return {
                ...state,
                loadingDashboardItemUser: payload
            };
        }

        default:
            return state;
    }
};
