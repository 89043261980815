import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    wsSetActive,
} from '../../../redux/WS/actions';
import { wsSelectors } from '../../../redux/WS';

// хук применить активный элемент
const useWsActive = (callback) => {
    const dispatch = useDispatch();
    const active = useSelector(wsSelectors.active);

    useEffect(() => {
        if (Object.keys(active).length > 0 && callback) {
            callback(active);
            dispatch(wsSetActive({}));
        }
    }, [active, callback]);
};

export default useWsActive;