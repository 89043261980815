import React, { useCallback, useContext, useEffect, useState } from 'react';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import CustomPagination from '../../../../common/CustomPagination';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';
import messages from '../../../../../helpers/constants/messages';
import { loadTypicalOperations } from '../../../../../redux/SituationalPlans/actions';
import ModalForm from './ModalForm';
import Item from './Item';
import Search from '../../../../common/Search';
import Loading from '../../../../common/Loading';
import Context from '../../../../../helpers/context';
import { SituationalPlansSelectors } from '../../../../../redux/SituationalPlans';

const TypicalOperations = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const data = useSelector(SituationalPlansSelectors.typicalOperationsData);
    const meta = useSelector(SituationalPlansSelectors.typicalOperationsMeta);
    const loadingTypicalOperations = useSelector(SituationalPlansSelectors.loadingTypicalOperations);

    const [openModal, setOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = useCallback((shouldGoToPreviousPage = false) => {
        if (shouldGoToPreviousPage) {
            setParams({ page: params.page - 1, data: params.data });
        } else {
            dispatch(loadTypicalOperations(params.page, limit, params.data));
        }
    }, [dispatch, limit, params.data, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    return (
        <div>
            <h1>Типовые операции</h1>
            <Search setParams={setParams}/>
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenModal(true),
                            disabled: !permissions?.is_create,
                        }
                    ]}
                />
            </div>
            {loadingTypicalOperations && <Loading circular={true}/>}
            {openModal
            && <ModalForm
                isOpen={openModal}
                onClose={setOpenModal}
                isNew={true}
                reloadList={() => setParams({ page: 1, data: {} })}
            />
            }
            {data?.length > 0
                ? (<>
                    <List className="list">
                        {data
                            ?.map((item, _, arr) => <Item
                                key={item.id + item.name}
                                item={item}
                                reloadList={(isDelete) => reloadList(isDelete && arr.length === 1)}
                            />)
                        }
                    </List>
                    <CustomPagination
                        loadList={(page) => setParams({ ...params, page })}
                        list={meta}
                        limit={limit} 
                        setLimit={setLimit}
                    />
                </>)
                : (!loadingTypicalOperations && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </div>
    );
};

export default TypicalOperations;
