import React, { useEffect, useState } from 'react';
import { sub } from 'date-fns';
import DateRange from '../../../../common/Dates/DateRange';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import dateFormat from '../../../../../helpers/constants/dateFormat';
import { dateWithDashYYYYMMDD } from '../../../../../helpers/date.config';
// TODO проверить, если этот компонент будет использоваться 
// тут неправильные даты, надо привести к формату с таймзоной
const Filter = ({ setFilter }) => {
    const initialState = {
        start_date: dateWithDashYYYYMMDD(sub(new Date(), { months: 4 })), // месяц назад от текущей даты
        end_date: dateWithDashYYYYMMDD(new Date('2022-09-01T23:59:59+03:00')) // костыль пока нет данных позднее 1.09
    };
    useEffect(() => {
        setFilter(initialState);
    }, []);

    const [fields, setFields] = useState(initialState);

    const handleChangeDate = (name, value) => {
        setFields({
            ...fields,
            [name]: dateWithDashYYYYMMDD(value)
        });
    };

    const resetFilters = () => {
        setFields(initialState);
    };

    return (
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">
                <DateRange
                    required
                    pickersFormat={dateFormat.DATE}
                    valueStartDate={fields.start_date}
                    valueEndDate={fields.end_date}
                    handleDate={(date) => handleChangeDate('start_date', date)}
                    handleEndDate={(date) => handleChangeDate('end_date',date)}
                    dateOnly
                />
            </div>

            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilters
                        },
                        {
                            ...buttonsTypes.find,
                            onClick: () => setFilter(fields),
                            disabled: !fields.start_date || !fields.end_date
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;
