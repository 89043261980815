import { clearFilters } from '../../../../redux/Incidents/actions';

const config = {
    name: 'МКДТП',
    slug: 'dtp-focus',
    mapContextMenu: {
        title: 'Добавить МКДТП',
        event: 'add_focus_incident',
        // className: styles.button,
        minWidth: 210
    },
    onAfterActivateAction: () => clearFilters(),
    layerIcon: 'fas fa-car-tilt',
};
// добавление на карте
export default config;
