import { useMemo } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton } from '@mui/material';

import { loadOrderStatuses } from 'redux/RoadWorks/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { getHumanDate } from 'helpers/date.config';
import { useStoreProp } from 'helpers/hooks';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import CircleStatus from 'components/common/CircleStatus';

function Item({ data, onClickItem, selectedId, isSelected }) {
    const orderStatuses = useStoreProp(loadOrderStatuses, 'roadworks', 'orderStatuses');

    const currentStatus = useMemo(() => {
        return orderStatuses.find((el) => Number(el.status_id) === Number(data.status));
    }, [data.status, orderStatuses]);

    return (
        <LSContentItem
            style={{ backgroundColor: selectedId ? '#0000001f' : '' }}
            onClick={onClickItem}
            isMobile={isSelected}
        >
            <LSContentColumn>
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    {data.status
                        ? <CircleStatus
                            title={currentStatus?.name}
                            color={currentStatus?.color}
                        />
                        : messages.NO_VALUE}

                    {isSelected && <span><b>{titles.NUM}</b> {data.id}</span>}
                </div>
            </LSContentColumn>

            {!isSelected && (
                <LSContentColumn>
                    {data.id || messages.INFO_IS_NOT_FOUND}
                </LSContentColumn>
            )}

            <LSContentColumn>
                {data?.type_text || messages.INFO_IS_NOT_FOUND}
            </LSContentColumn>

            <LSContentColumn>
                {data?.name || messages.INFO_IS_NOT_FOUND}
            </LSContentColumn>

            {!isSelected && (
                <LSContentColumn>
                    {data?.register_at ? getHumanDate(data?.register_at) : messages.INFO_IS_NOT_FOUND}
                </LSContentColumn>
            )}

            {!isSelected && (
                <LSContentColumn>
                    {data.start_date_plan ? getHumanDate(data.start_date_plan) : messages.NO_VALUE}
                </LSContentColumn>
            )}

            {!isSelected && (
                <LSContentColumn>
                    {data.end_date_plan ? getHumanDate(data.end_date_plan) : messages.NO_VALUE}
                </LSContentColumn>
            )}

            <LSContentColumn isActions>
                <IconButton size="small">
                    {selectedId
                        ? <ArrowBackIosIcon fontSize="inherit"/>
                        : <ArrowForwardIosIcon fontSize="inherit"/>
                    }
                </IconButton>
            </LSContentColumn>
        </LSContentItem>
    );
}

export default Item;
