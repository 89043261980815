import React from 'react';
import { List } from '@mui/material';
import DefectItem from './DefectItem';

const DefectList = ({
    items = [],
    checkboxes = [],
    showCheckbox = false,
    setItems = () => {},
    onClick = () => {},
    currentItem = {},
}) => {

    const handleCheck = (check, value) => {
        if (check) {
            setItems([value, ...checkboxes]);
        } else {
            const newData = checkboxes.filter(item => item.id !== value.id);
            setItems(newData);
        }
    };

    return (
        <List>
            {items?.map((item, index) =>
                <DefectItem
                    key={item?.id}
                    checked={checkboxes.some(elem => elem.id === item.id)}
                    showCheckbox={showCheckbox}
                    item={item}
                    index={index}
                    handleCheck={handleCheck}
                    onClick={onClick}
                    currentItem={currentItem}
                />
            )}
        </List>
    );
};

export default DefectList;