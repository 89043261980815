import React from 'react';
import { IconButton } from '@mui/material';
import {
    LSContainer,
    LSHeaderItem,
    LSHeaderColumn,
    LSContentItem,
    LSContentColumn,
} from '../../../../../common/ListStyle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import {
    fullDateTime,
} from '../../../../../../helpers/date.config';
import messages from '../../../../../../helpers/constants/messages';

const List = ({
    data = [],
}) => {

    return (
        <LSContainer>
            <LSHeaderItem fullWidth>
                <LSHeaderColumn width="170px">
                    Дата
                </LSHeaderColumn>
                <LSHeaderColumn width="250px">
                    Комплекс
                </LSHeaderColumn>
                <LSHeaderColumn width="140px">
                    Вычислительный модуль
                </LSHeaderColumn>
                <LSHeaderColumn width="140px">
                    Файл
                </LSHeaderColumn>
                <LSHeaderColumn width="200px">
                    Сообщение
                </LSHeaderColumn>
                <LSHeaderColumn align="center">
                    Статус
                </LSHeaderColumn>
            </LSHeaderItem>
            <>
                {data?.map((item) => (
                    <LSContentItem
                        key={item.id}
                        fullWidth
                        style={{ cursorPointer: 'none' }}
                    >
                        <LSContentColumn width="170px" title="Дата">{fullDateTime(item.updated_at) || messages.NO_DATA}</LSContentColumn>
                        <LSContentColumn width="250px" title="Комплекс">{item.complex_computer?.name || messages.NO_DATA}</LSContentColumn>
                        <LSContentColumn width="140px" title="Вычислительный модуль">{item.complex_computer?.serial || messages.NO_DATA}</LSContentColumn>
                        <LSContentColumn width="140px" title="Файл">{item.exclusiveV_vehicle_file?.file_name || messages.NO_DATA}</LSContentColumn>
                        <LSContentColumn width="200px" title="Сообщение">{item.message || messages.NO_DATA}</LSContentColumn>
                        <LSContentColumn align="center" title="Статус">
                            <IconButton size="large">
                                {item.success 
                                    ? <CheckCircleOutlineIcon fontSize="inherit" color="primary" /> 
                                    : <DoDisturbAltIcon fontSize="inherit" color="error" />
                                }
                            </IconButton>
                        </LSContentColumn>
                    </LSContentItem>
                ))}
            </>
        </LSContainer>
    );
};

export default List;