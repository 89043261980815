import React, { useMemo, useRef, useState } from 'react';
import { Line as GLine } from 'react-chartjs-2';
import getMonth from '../../../../../helpers/getMonth';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Modal from '../../../../common/Modal';
import { Button } from '@mui/material';


const Line = ({
    item = {},
    filter,
    paramsInFilter = {},
    titleData = '',
}) => {
    const {
        title = '',
        datas = [],
        threshold,
    } = item;
    const unitRef = useRef('');

    // график в модалке
    const [showModal, setShowModal] = useState(false);

    const getDate = useMemo(() => {
        return paramsInFilter?.month
            ? `.${paramsInFilter?.month}.${paramsInFilter?.year || ''}`
            : ` ${paramsInFilter?.year || ''}`;
    }, [paramsInFilter]);

    const getDateHuman = useMemo(() => {
        return paramsInFilter?.month
            ? `${getMonth(paramsInFilter?.month - 1 || 0) || ''} ${paramsInFilter?.year || ''}`
            : `${paramsInFilter?.year || ''}`;
    }, [paramsInFilter]);

    const data = useMemo(() => {
        const {
            labels,
            dataset,
            units,
            color,
        } = datas?.reduce((res, item) => {

            const current = filter(item);
            if (unitRef.current === '') unitRef.current = current?.units || '';

            return {
                labels: [
                    ...res?.labels,
                    item.date,
                ],
                dataset: [
                    ...res?.dataset,
                    current?.value || 0,
                ],
                units: [
                    ...res?.units,
                    `${current?.value ?? 0} ${current?.units ?? ''}`,
                ],
                color: res?.color || current?.color,
            };

        }, {
            labels: [],
            dataset: [],
            units: [],
            color: '',
        });

        const graphData = {
            labels,
            datasets: [
                {
                    label: title,
                    borderColor: color,
                    backgroundColor: color,
                    data: dataset,
                    units,
                }
            ]
        };

        // полоса "Критическое значение"
        if (threshold) {
            graphData?.datasets?.push({
                label: 'Критическое значение',
                borderColor: '#ff0033',
                backgroundColor: '#ff0033',
                data: labels?.map(() => threshold),
                units: labels?.map(() => `${threshold} ${unitRef.current}`),
                threshold: true,
                pointRadius: 0,
                pointHoverRadius: 5,
                pointHitRadius: 5,
                borderDash: [10, 10],
                // borderDashOffset: 0,
            });
        }

        return graphData;
    }, [datas, filter]);

    const options = {
        plugins: {
            legend: {
                position: 'bottom',
                display: false,
            },
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks: {
                    title: ({ 0: context }) => {
                        if (context?.dataset?.threshold) {
                            return context?.dataset?.label;
                        }
                        return `${context?.label}${getDate}`;
                    },
                    label: (context) => {
                        const dataIndex = context?.dataIndex;
                        return ` ${context?.dataset?.units?.[dataIndex] || ''}`;
                    },
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: unitRef.current,
                },
            },
            x: {
                title: {
                    display: true,
                    text: getDateHuman,
                }
            }
        },
    };

    const handleChangeModal = (bool = false) => () => setShowModal(bool);

    return (
        <>
            {/* мини график */}
            <div style={{ marginBottom: '1rem' }}>
                <div style={{ textAlign: 'center' }}>{title}</div>
                <GLine
                    options={options}
                    data={data}
                    style={{
                        maxHeight: '200px',
                        cursor: 'pointer',
                    }}
                    onClick={handleChangeModal(true)}
                />

                {/*<div*/}
                {/*    className="row"*/}
                {/*    style={{*/}
                {/*        justifyContent: 'end',*/}
                {/*        margin: 0,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Button*/}
                {/*        size="small"*/}
                {/*        onClick={handleChangeModal(true)}*/}
                {/*    >*/}
                {/*        Подробнее*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </div>

            {/* большой график в модалке */}
            <Modal
                title={`${title} (${getDateHuman}) - ${titleData}`}
                isOpen={showModal}
                onClose={handleChangeModal(false)}
                maxWidthProp="xl"
                showCloseInTitle
                heightMedium
            >
                <div style={{ height: '100%' }}>
                    <GLine
                        options={options}
                        data={data}
                        style={{
                            width: '100%',
                            maxHeight: '100%',
                            minHeight: '250px',
                        }}
                    />
                </div>
            </Modal>
        </>
    );
};

export default Line;