import React, { useState } from 'react';
import styles from './videoFixation.module.scss';
import { Dialog, DialogContent, IconButton, TextField, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loadCameraEvents, loadCameraEventsGroup, loadCameras } from '../../../../redux/DorisControl/actions';
import UniversalSelect from '../../../common/UniversalSelect';
import Clear from '@mui/icons-material/Clear';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Add from '@mui/icons-material/Add';
import DateRange from '../../../common/Dates/DateRange';
import _ from 'lodash';
import MapModal from './MapModal';
import { Autocomplete } from '@mui/material';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import cn from 'classnames';
import titles from '../../../../helpers/constants/titles';
import { dateWithDashYYYYMMDD } from '../../../../helpers/date.config';
import { dorisControlSelectors } from '../../../../redux/DorisControl';

const Filters = ({ setFilter }) => {
    const dispatch = useDispatch();
    const [universalOpen, setUniversalOpen] = useState(false);
    const [mapOpen, setMapOpen] = useState(false);
    const [search, setSearch] = useState(false);
    const toggleUniOpen = () => setUniversalOpen(!universalOpen);
    const toggleMapOpen = () => setMapOpen(!mapOpen);

    const violations = useSelector(dorisControlSelectors.violations);
    const eventsGroup = useSelector(dorisControlSelectors.eventsGroup);

    const initialFiltersValue = {
        violation_type: [],
        query: [],
        camera_id: { name: '', id: '' },
        start_date: null,
        end_date: null,
    };

    const [formValue, setFormValue] = React.useState(initialFiltersValue);

    React.useEffect( () => {
        setFilter(formValue);
    }, [formValue]);

    const handleChangeStart = (value) => {
        setFormValue({
            ...formValue,
            start_date: value
        });
    };

    const handleChangeEnd = (value) => {
        setFormValue({
            ...formValue,
            end_date: value
        });
    };

    const handleChangeCamera = (value) => {
        setFormValue({
            ...formValue,
            camera_id: value
        });
        setUniversalOpen(false);
    };

    const resetCamera = () => handleChangeCamera({ name: '', id: '' });

    const getCameras = (params) => {
        const { page, limit, query } = params;
        dispatch(loadCameras(page, limit, { query }));
    };

    const eventTypes = Object.keys(violations)?.map(key => ({
        value: key,  label: violations[key]
    }));

    const handleEventTypes = (_, newValue) => {
        setFormValue({ ...formValue, violation_type: newValue });
    };

    const handleTsNums = (e, newValue) => {
        const hasValue = formValue.query.find(item => item.value === e.target.value);
        if (!hasValue) {
            const options = newValue.map(item => {
                if (typeof item === 'string') {
                    return { value: item, label: item };
                }
                return item;
            });
            setFormValue({
                ...formValue,
                query: options
            });
        }
    };

    const paramsWithoutEmpty = _.reduce(
        formValue,
        (res, el, key) => {
            if (Array.isArray(el) && el?.length) {
                res[key] = el.map(item => item.value);
            } else if (el?.hasOwnProperty('id') && el?.id) {
                res[key] = el.id;
            }
            else if (el instanceof Date) {
                res[key] = dateWithDashYYYYMMDD(el);
            }
            return res;
        },
        {}
    );

    const send = () => {
        if (Object.keys(paramsWithoutEmpty)?.length) {
            dispatch(loadCameraEventsGroup(paramsWithoutEmpty));
            dispatch(loadCameraEvents(
                1, 25, paramsWithoutEmpty
            ));
            setSearch(true);
        }
    };
    const disableOption = (option) => {
        const hasValue = formValue.query.find(item => item.value === option.value);
        return !!hasValue;
    };
    const resetFilters = () => {
        if (search){
            dispatch(loadCameraEvents());
            dispatch(loadCameraEventsGroup());
            setSearch(false);
        }
        setFormValue(initialFiltersValue);
    };

    const [isDisabled, setIsDisabled] = useState(false);
    const isDisabledHandler = (value) => {
        setIsDisabled(value);
    };

    return (
        <div className="filter">
            <div className={cn('filter__wrap', 'filter__wrap__more__field')}>
                <Autocomplete
                    multiple
                    value={formValue.violation_type}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    options={eventTypes}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.label}
                    onChange={handleEventTypes}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Тип событий"
                        />
                    )}
                />
                <div className={styles.tsNumField}>
                    <Autocomplete
                        multiple
                        value={formValue.query}
                        options={[]}
                        onChange={handleTsNums}
                        filterSelectedOptions={true}
                        getOptionLabel={(option) => option.label}
                        freeSolo={true}
                        getOptionDisabled={disableOption}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label="Гос номер ТС"
                            />
                        )}
                    />
                    <Tooltip
                        title={titles.WRITE_TRANSPORT_NUMBER}
                    >
                        <IconButton className={styles.btn} size="small">
                            <ErrorOutline/>
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={styles.addCameraBtn}>
                    <TextField
                        value={formValue.camera_id?.name}
                        size="small"
                        variant="outlined"
                        label="Камера"
                        disabled
                    />                   
                    <FormButtons
                        justButton
                        buttons={[
                            {
                                ...buttonsTypes.defaultSecondary,
                                onClick: formValue.camera_id.name ? resetCamera : toggleUniOpen,
                                size: 'small',
                                name: formValue.camera_id.name
                                    ? <Clear fontSize="small"/>
                                    : <Add fontSize="small" /> 
                            }
                        ]}
                    />
                </div>
                <Dialog onClose={toggleUniOpen} open={universalOpen}>
                    <DialogContent>
                        <UniversalSelect
                            storeName={'dorisControl'}
                            storeNameProps={'cameras'}
                            keyProp={'id'}
                            fetchList={getCameras}
                            withSearch={true}
                            onChange={handleChangeCamera}
                            renderProps={(el) => <div><span>{el.name}</span></div>}
                        />
                    </DialogContent>
                </Dialog>
                <DateRange
                    valueStartDate={formValue.start_date}
                    valueEndDate={formValue.end_date}
                    handleDate={handleChangeStart}
                    handleEndDate={handleChangeEnd}
                    isDisabledHandler={isDisabledHandler}
                />
            </div>
            <MapModal open={mapOpen} onClose={toggleMapOpen} data={eventsGroup} />
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilters
                        },
                        {
                            ...buttonsTypes.find,
                            onClick: send,
                            disabled: isDisabled
                        },
                        {
                            ...buttonsTypes.showOnMap,
                            onClick: toggleMapOpen,
                            disabled: (eventsGroup?.length === 0) || isDisabled
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filters;