import { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import { 
    getDateObjectFromFormat, 
    getFormatFromDate,
    isDateValid,
} from 'helpers/date.config';
import { useValidation } from 'helpers/hooks';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';

import styles from './scheduleTemlates.module.scss';

const StationTimeModal =({
    items = [], // контрольные точки добавленные в шаблон
    isOpen = false,
    onClose = () => {},
    onSave = () => {}
}) => {
    const validation = useValidation(false);
    // т.к. время приходит в формате 00:00:00 преобразуем его в Дату для датапикера
    const initialState = items?.map(({ time_start, time_end, ...item }) => {
        return {
            ...item,
            ...(time_start && time_end && {
                time_start: getDateObjectFromFormat(time_start, 'HH:mm'),
                time_end: getDateObjectFromFormat(time_end, 'HH:mm'),
            })
        };
    });

    const [data, setData] = useState(initialState);

    const handleChange = (value, name, index) => {
        validation.deleteKey(`${index}.${name}`);
        const changed = data?.map((item, i) => {
            if (index === i) {
                return {
                    ...item,
                    [name]: value
                };
            }
            return item;
        });
        setData(changed);
    };

    const handleEditStation = () => {
        const result = data?.map(item => ({
            ...item,
            time_start: getFormatFromDate(item.time_start, 'HH:mm'),
            time_end: getFormatFromDate(item.time_end, 'HH:mm')
        }));
        onSave(result);
    };

    const allDatesFilled = data.reduce((res, item) => {
        res = res && !!item.time_start && !!item.time_end;
        return res;
    }, true);

    const renderTimePicker = (value, name, label, index) => {
        return <SingleKeyboardDateTimePicker
            className="row__item"
            required
            timeOnly
            label={label}
            value={value || null}
            onChange={(value) => handleChange(value, name, index)}
            error={!value
                || validation.isKey(`${index}.${name}`)
                || !isDateValid(value)
            }
            helperText={validation.get(`${index}.${name}`)}
        />;
    };

    return (
        <Modal
            maxWidthProp="xl"
            title={titles.EDIT_STATIONS_AND_CHECK_POINTS}
            onClose={() => {
                validation.clear();
                onClose();
            }}
            noPadding
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: () => {
                            validation.clear();
                            onClose();
                        }
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleEditStation,
                        disabled: !allDatesFilled
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <div className="block">Укажите время в формате ЧЧ:ММ (часы:минуты):</div>
                {data?.length > 0
                    && data.map((el, i) =>
                        <div key={el?.check_point_id} className="row">
                            <b className="row__item">{el?.station?.name || el?.check_point?.name}:</b>
                            {renderTimePicker(el?.time_start, 'time_start', 'Время прибытия', i)}
                            {renderTimePicker(el?.time_end, 'time_end', 'Время убытия', i)}
                            <div className="row__item">
                                <TextField
                                    onChange={({ target: { value, name } }) => handleChange(value, name, i)}
                                    value={el?.mileage}
                                    type="number"
                                    className={styles.textFiledWithoutArrows}
                                    label={`${titles.MILEAGE} (км)`}
                                    name="mileage"
                                    variant="outlined"
                                    size="small"
                                />
                            </div>
                        </div>
                    )}
            </div>
        </Modal>
    );
};

export default StationTimeModal;