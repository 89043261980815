import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import * as actions from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import mapHelper from 'helpers/mapHelper';
import { useDebounce, usePrevious } from 'helpers/hooks';
import {
    ContextMenu,
    ContextMenuItem,
    Marker,
    PopUp,
} from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import MapLegends from 'components/common/Transport/MapLegends';

import { createIconMarker, getColor, getFilter } from './helper';
import config from './config.js';
import PopUpDtp from './PopUp';
import CollectorForms from './CollectorForms';
import LegendsList from './LegendsList';


const Layer = (props) => {
    const { map, readOnly = false, isTrafficBlocking = false, filter: filterProp = null } = props;
    const dispatch = useDispatch();
    
    const types = useSelector(incidentsSelectors.dtpTypesData);
    const polygon = useSelector(incidentsSelectors.polygon);
    const polygonLoading = useSelector(incidentsSelectors.polygonLoading);
    const polygonVisible = useSelector(incidentsSelectors.polygonVisible);
    const active = useSelector(incidentsSelectors.active);
    const saved = useSelector(incidentsSelectors.saved);
    const filtersSelector = useSelector(incidentsSelectors.filters);
    const filters = filterProp || filtersSelector;

    const prevFilters = usePrevious(filters);

    const filter = getFilters(filters, getFilter);

    const [isPopUp, setIsPopUp] = useState(false);
    const [isFirst, setIsFirst] = useState(true);

    const activeId = Object.keys(active || {}).length > 0 ? active.id : 0;

    // активный элемент есть в полигоне
    const issetActive = polygonVisible.findIndex(({ id }) => id === active?.id) >= 0;

    const previewId = useRef(0);

    useEffect(() => {
        if (
            (
                isPopUp === false
                || (Object.keys(active).length > 0 && issetActive === false && isFirst)
            )
            // && polygon.length > 0
            && polygonLoading === false
        ) {
            if (Object.keys(active).length > 0) setIsFirst(false);
            dispatch(actions.setDtpPolygonVisible(polygon));
        }
    }, [polygon, polygonLoading, isPopUp, active, isFirst, issetActive]);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadDtpPolygon(
            polygon,
            filter
        ));
    };
    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();

    // добавить новый
    const handleAdd = ({ lat, lng }) => {
        dispatch(actions.setEditForm({
            lat,
            lon: lng,
        }));
    };

    // сдвинуться к маркеру
    const setMapToMarker = () => {
        if (Object.keys(active).length > 0) {
            const { lat, lon } = active;
            if (Math.abs(lat) && Math.abs(lon)) {
                setTimeout(() => {
                    map.setView([lat, lon]);
                }, 200);
            }
        }
    };

    useEffect(() => {
        // ждем дат от фильтра - тут не грузим
        // debounceFetchPolygon();
        if (types.length === 0) {
            dispatch(actions.loadDtpTypes());
        }
        return () => {
            dispatch(actions.clearDtpPolygon());
        };
    }, []);

    useEffect(() => {
        if (
            readOnly === false
            && (
                !filters.start_date
                || !filters.end_date
            )
        ) {
            dispatch(actions.loadDtpDates());
        }

        if (readOnly) fetchPolygon();
    }, [readOnly]);

    const handlePUO = () => {
        setIsPopUp(true);
    };
    const handlePUC = () => {
        setIsPopUp(false);
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon)
            .on('popupopen', handlePUO)
            .on('popupclose', handlePUC);

        setMapToMarker();
        return () => {
            dispatch(actions.resetDtpActive());
            dispatch(actions.clearDtpPolygon());
            // map.closeContextMenu();
            //map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon)
                .off('popupopen', handlePUO)
                .off('popupclose', handlePUC);
        };
    }, [map, filters]);

    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, (e) => {
            //map.fire('context_menu_close');
            handleAdd(e.latlng);
        });

        return () => {
            map.off(config.mapContextMenu.event);
        };
    }, []);


    useEffect(() => {
        if (saved) {
            map.closeContextMenu();
            dispatch(actions.setEditForm());
            fetchPolygon();
        }
    }, [saved]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            previewId.current = active?.id;
            setIsFirst(true);

            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 15);
            }
        }
    }, [active]);

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProp }) => {
        return (
            <div>
                {item.status !== 3 ? (
                    <ContextMenuItem
                        {...rcmProp}
                        value="Редактировать"
                        onClick={() => {
                            map.fire('context_menu_close');
                            dispatch(actions.setEditForm(item));
                        }}
                    />
                ) : null}
                <ContextMenuItem
                    {...rcmProp}
                    value="Удалить"
                    onClick={() => {
                        map.fire('context_menu_close');
                        dispatch(actions.setDeleteForm(item.id));
                    }}
                    className="red"
                />
            </div>
        );
    };

    const clickPopUp = (id) => () => {
        if (previewId.current === id) {
            dispatch(actions.resetDtpActive());
        }
    };

    const createItem = (item) => {
        const { id, lat, lon } = item;
        const componentProps = {
            ...props,
            key: `${config.slug}_${id}`,
            // для кластера
            attribution: {
                slug: config.slug,
                color: getColor(item, types)
            },
            onClick: (latlng) => {
                map.setView(latlng);
                dispatch(actions.resetDtpActive());
                // dispatch(actions.setDtpActive(item));
            },
        };
        const currentShow = activeId === id;

        const popUpProps = {
            show: currentShow,
            onClose: clickPopUp(id),
            // onClose: () => {
            //     if (currentShow) {
            //         dispatch(actions.resetDtpActive());
            //     }
            // }
        };

        return (
            <Marker
                {...componentProps}
                latlng={[lat, lon]}
                icon={createIconMarker(getColor(item, types))}
            >
                <PopUp {...popUpProps}>
                    <PopUpDtp
                        uuid={id}
                        readOnly={readOnly}
                        isTrafficBlocking={isTrafficBlocking}
                    />
                </PopUp>

                {readOnly === false
                    && (
                        <ContextMenu
                            //minWidth={155}
                        >
                            <RenderContextMenu item={item} />
                        </ContextMenu>
                    )
                }
            </Marker>
        );
    };

    const polygonData = useMemo(() => {
        return polygonVisible.map((item) => createItem(item));
    }, [polygonVisible]);

    return (
        <>
            {polygonData}

            {/* формы */}
            {readOnly === false
                && (
                    <CollectorForms />
                )
            }

            <MapLegends
                layer="incidents"
                isVisible={types?.length > 0}
            >
                <LegendsList
                    types={types}
                />
            </MapLegends>
        </>
    );
};

export default Layer;
