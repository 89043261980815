import { Collapse } from '@mui/material';

import InfoItem from './InfoItem';

const InfoList = ({ list, isOpen }) => {
    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit className="info">
            <div>
                {list?.map((el) => (
                    <InfoItem
                        key={el.id}
                        data={el}
                    />
                ))}
            </div>
        </Collapse>
    );
};

export default InfoList;
