import { memo } from 'react';
import { FormHelperText } from '@mui/material';
import SingleKeyboardDateTimePicker from '../../../../../../common/Dates/SingleKeyboardDateTimePicker';
import formatDate from '../../../../../../../helpers/constants/dateFormat';

export const EndTime = memo(({
    value,
    onChange,
    validation,
    isDisabledHandler,
    error,
    helperText,
}) => (
    <div className="block">
        <SingleKeyboardDateTimePicker
            label={'Время окончания показа'}
            onChange={onChange}
            value={value}
            pickerFormat={formatDate.DT_EXCEPT_SECONDS}
            // required
            isDisabledHandler={isDisabledHandler}
            error={validation.isKey('end_at') || error}
        />
        {
            (validation.isKey('end_at') || helperText)
                ? (<FormHelperText error>{validation.get('end_at') || helperText}</FormHelperText>)
                : null
        }
    </div>
));