import React, { useContext, useEffect, useState } from 'react';
import Filter from './Filter';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { useStoreProp } from '../../../../../helpers/hooks';
import { loadMessagePriorities, loadMessageTemplates } from '../../../../../redux/Boards/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../common/Loading';
import { List } from '@mui/material';
import CustomPagination from '../../../../common/CustomPagination';
import Item from './Item';
import messages from '../../../../../helpers/constants/messages';
import ModalForm from '../Messages/ModalForm';
import Context from '../../../../../helpers/context';
import { boardsSelectors } from '../../../../../redux/Boards';

function MessageTemplates() {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);
    const [params, setParams] = useState({});
    const [isOpenModalForm, setIsOpenModalForm] = useState(false);

    const messagePriorities = useStoreProp(
        loadMessagePriorities,
        'boards',
        'messagePriorities'
    );

    const boardMessageTemplates = useSelector(boardsSelectors.boardMessageTemplates);
    const loadingMessageTemplates = useSelector(boardsSelectors.loadingMessageTemplates);

    useEffect(() => {
        dispatch(loadMessageTemplates({ page: 1, limit }));
    }, []);

    return (
        <div>
            {isOpenModalForm && (
                <ModalForm
                    isOpen={isOpenModalForm}
                    onClose={() => setIsOpenModalForm(false)}
                    isNew={true}
                    priorities={messagePriorities}
                    template={true}
                />
            )}
            <h1>Шаблоны сообщений для подсистемы ДИТ и ЗПИ</h1>
            <Filter setParams={setParams} />
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setIsOpenModalForm(true),
                            disabled: !permissions?.is_create
                        }
                    ]}
                />
            </div>
            {loadingMessageTemplates && <Loading linear={true}/>}
            <List className="list">
                {boardMessageTemplates.data?.length > 0
                    ? (<>
                        {boardMessageTemplates?.data
                            ?.map(el => <Item
                                el={el}
                                key={el.id}
                                priorities={messagePriorities}
                            />)}
                        <CustomPagination
                            loadList={(page, limit) => dispatch(loadMessageTemplates({ page, limit, ...params }))}
                            list={boardMessageTemplates.meta}
                            limit={limit} 
                            setLimit={setLimit}
                        />
                    </>)
                    : (!loadingMessageTemplates && <div>{messages.DATA_IS_NOT_FOUND}</div>)
                }
            </List>
        </div>
    );
}

export default MessageTemplates;