import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiMethods = {

    getVersion: async () => {
        const response = await instance.get(apiUrls.getVersion());
        const status = response?.status || null;
        // проверка статуса
        if (status === 200) {
            return response.data || null;
        }
        return null;
    },

};

export default ApiMethods;
