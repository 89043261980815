import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';

import messages from 'helpers/constants/messages';
import CustomPagination from 'components/common/CustomPagination';
import Loading from 'components/common/Loading';

import Item from './Item';

const HistoryList = ({
    storeName,
    storeProp,
    loadingProp,
    loadAction,
    id,
    ItemInfo
}) => {
    const dispatch = useDispatch();

    const [params, setParams] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
    });
    const {
        [storeProp]: list = {},
        [loadingProp]: loading
    } = useSelector(({ [storeName]: selector }) => selector);

    useEffect(() => {
        const { page, limit } = params;
        dispatch(loadAction(page, limit, id));
    }, [dispatch, id, loadAction, params]);

    return (
        <>
            {loading && <Loading circular={true}/>}

            {list?.data?.length > 0
                ? <>
                    <List className="list">
                        {list?.data?.map((item) => (
                            <Item key={item.id} item={item}>
                                {ItemInfo && <ItemInfo item={item}/>}
                            </Item>
                        ))}
                    </List>
                    <CustomPagination
                        loadList={(page, limit) => setParams(({ ...params, page, limit }))}
                        list={list?.meta}
                        limit={params?.limit}
                        setLimit={(limit) => setParams({ ...params, limit })}
                    />
                </>
                : !loading && messages.DATA_IS_NOT_FOUND
            }
        </>
    );
};

export default HistoryList;
