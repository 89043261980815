import React from 'react';
import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    TextField, Typography
} from '@mui/material';

import useTransportTypes from 'helpers/hooks/useTransportTypes';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import AutocompleteCompanies from 'components/common/Autocomplete/Companies';
import Vehicles from 'components/common/Autocomplete/Transport/Vehicles';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';

const Form = (props) => {
    const { data, onChange, onDelete, statuses } = props;
    const types = useTransportTypes();
    const getValue = (key) => data[key] || '';

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <TextField
                size="small"
                fullWidth
                className="block"
                label="№ маршрута"
                // label={titles.SELECT_ROUTE_NUM}
                id="outlined-margin-dense"
                value={getValue('num_list')}
                onChange={(e) => onChange({ [e.target.name]: [e.target.value] })}
                //helperText="Some important text"
                variant="outlined"
                name="num_list"
                InputProps={{
                    endAdornment: (
                        <>
                            {getValue('num_list').length > 0 ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            onChange({ num_list: [] });
                                        }}
                                        edge="end"
                                        size="large">
                                        <span style={{ fontSize: '1rem' }}>x</span>
                                    </IconButton>
                                </InputAdornment>
                            ) : ''}
                        </>
                    )
                }}
            />
            <FormControl
                fullWidth
                variant="outlined"
                size="small"
                className="block"
            >
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={data?.category_id_list || []}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    options={types}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(e, value) => onChange({ category_id_list: value })}
                    size="small"
                    multiple={true}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Тип ТС"
                            // label={titles.SELECT_ROUTE_CATEGORY}
                        />
                    )}
                />
            </FormControl>
            <div className="block">
                <AutocompleteCompanies
                    onChange={(value) => {
                        onChange({ 'organization_id_list': value.map((item) => ({ ...item, name: item.title })) });
                    }}
                    selected={data?.organization_id_list || []}
                    multiple
                    label={titles.ORGANIZATIONS}
                    // label={'Отфильтровать транспортные средства по организациям'}
                    selectDeletedCompanies
                    filter={{ withDeleted: 1 }}
                />
            </div>

            <div className="block">
                <Autocomplete
                    value={data.status_list || []}
                    options={Object.keys(statuses)?.map((id) => ({ id, name: statuses[id] }))}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name || ''}
                    noOptionsText={messages.NO_DATA}
                    size="small"
                    multiple
                    isOptionEqualToValue={(option, value) => option?.id === value.id}
                    onChange={(e, newValues) => onChange({
                        status_list: newValues
                    })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Статус"
                        />
                    )}
                />
            </div>

            <FormControl
                size="small"
                variant="outlined"
                className="block"
            >
                <FormInfoWrapper
                    helperText="Добавление одного или нескольких элементов, разделяя запятой"
                >
                    <MultipleInput
                        value={data?.serial_egts || []}
                        onChange={(e) => onChange({
                            serial_egts: e?.map(item => item?.trim())
                        })}
                        label="Серийный номер для EGTS"
                    />
                </FormInfoWrapper>
            </FormControl>

            <Vehicles
                label="ГРЗ"
                multiple
                selected={data?.grz_list?.map((item) => ({
                    ...item,
                    id: item?.ids,
                })) || []}
                onChange={value => onChange({
                    // id и name используются в фильтре
                    grz_list: value?.map(item => ({
                        ...item,
                        ids: item?.id,
                        id: item?.number,
                        name: item?.number,
                    })),
                })}
                renderProps={(el) => (
                    <div>
                        <strong>Гос номер ТС:&nbsp;</strong>
                        {el?.number || messages.NO_DATA}
                    </div>
                )}
            />

            <div className="row">

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data?.in_registry?.id || false}
                            onChange={(event) =>
                                (event.target.checked)
                                    ? onChange({
                                        in_registry: {
                                            id: event.target.checked || false,
                                            name: 'ТС в реестре'
                                        }
                                    })
                                    : onDelete('in_registry')
                            }
                            color="primary"
                        />
                    }
                    label={
                        <Typography variant="body2">
                            Показать ТС в реестре
                        </Typography>
                    }
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data?.in_work_order?.id || false}
                            onChange={(event) =>
                                (event.target.checked)
                                    ? onChange({
                                        in_work_order: {
                                            id: event.target.checked || false,
                                            name: 'ТС в нарядах'
                                        }
                                    })
                                    : onDelete('in_work_order')
                            }
                            color="primary"
                        />
                    }
                    label={
                        <Typography variant="body2">
                            Показать ТС в нарядах
                        </Typography>
                    }
                />

            </div>

        </form>
    );
};

export default Form;
