import React, { useState } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import { loadDocumentTypes } from 'redux/DorisControl/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import { getYear, isDateValid } from 'helpers/date.config';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import { PageReportFields, useReportTemplatesFrontendLinks } from 'components/common/PageReport';

interface initialState {
    year: string | null,
    type: number,
}

const Filter = () => {
    const document_types = useStoreProp(loadDocumentTypes, 'dorisControl', 'doris_complex_document_types');

    const templateProvider = useReportTemplatesFrontendLinks();
    const reportFilter = templateProvider.filter;
    
    const initialState: initialState = {
        year: getYear(new Date()),
        type: 2,
    };

    const [data, setData] = useState(initialState);

    const handleChange = (newData: object) => {
        setData({
            ...data,
            ...newData,
        });
    };

    const download = () => {
        const filter = {
            ...data,
            year: Number(getYear(data.year)),
            type: Number(data.type),
        };
        const { url, formats } = reportFilter.getToApi();

        templateProvider.loadReport(url, formats, filter);
    };

    const resetFilter = () => {
        setData(initialState);
        reportFilter.setFormats([]);
    };

    const updateFilter = (filter: React.SetStateAction<initialState>) => {
        setData({
            ...initialState,
            ...filter
        });
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            buttonType="getReport"
            onSearch={download}
            filter={data}
            filterException={['year']}
            setUserFilter={updateFilter}
            disabled={reportFilter?.formats?.length === 0 || !isDateValid(data.year)}
            isFilterVisible={true}
        >
            <LayoutFilter.Visible>
                <SingleKeyboardDateTimePicker
                    value={data.year}
                    onChange={(newDate) => {
                        handleChange({ year: getYear(newDate) });
                    }}
                    label={titles.YEAR}
                    views={['year']}
                    pickerFormat="yyyy"
                    disableFuture={false}
                />
                <FormControl size="small" variant="outlined">
                    <InputLabel>{titles.TYPE}</InputLabel>
                    <Select
                        value={data.type >= 0 ? data.type : ''}
                        onChange={(e) => handleChange({ type: e.target.value })}
                        label={titles.TYPE}
                    >
                        {Object.keys(document_types).map((index) => (
                            <MenuItem key={index} value={index}>{document_types[index]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <PageReportFields
                    reportType={reportFilter.type}
                    reportFormats={reportFilter.formats}
                    onReportType={reportFilter.setType}
                    onReportFormat={reportFilter.setFormats}
                    error={reportFilter?.formats?.length === 0}
                    isOneTypeAuto
                    required
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;