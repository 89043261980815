import React from 'react';
import { Button, Link, Menu, MenuItem } from '@mui/material';
import cn from 'classnames';
import styles from './header.module.scss';

interface HelpMenuProps {
    anchorEl: null | Element | ((element: Element) => Element)
    onClose: () => void
}

const HelpMenu = ({ anchorEl, onClose }: HelpMenuProps) => {
    return (
        <Menu
            sx={{ mt: '36px' }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            style={{ borderRadius: 16 }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
        >
            <MenuItem onClick={onClose}>
                <Link
                    target="_blank"
                    rel="noopener"
                    underline="none"
                    color="inherit"
                    href="https://npo-its.ru"
                >
                    <Button
                        className={styles.menu_item_button}
                        startIcon={<i className={cn(styles.menu_icon, 'far fa-link')} />}
                    >
                        Перейти на npo-its.ru
                    </Button>
                </Link>
            </MenuItem>
        </Menu>
    );
};

export default HelpMenu;
