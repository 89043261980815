import { useEffect, useState } from 'react';
import GetReports from '../../../../../common/GetReports';
import SimpleSelect from '../../../../../common/SimpleSelect';

interface Report {
    name: string;
    key: number;
    url: string;
}

interface GetReportFieldsProps {
    reports?: Report[];
    handleDownload: (arg: any, arg2: any) => void;
}

const GetReportFields = ({ reports, handleDownload }: GetReportFieldsProps) => {
    const [selectedType, setSelectedType] = useState('');
    const [selectedFormats, setSelectedFormats] = useState([]);

    const onDownload = (formats: unknown) => {
        handleDownload(formats, Number(selectedType));
        setSelectedType('');
        setSelectedFormats([]);
    };

    useEffect(() => {
        if (!selectedType) {
            setSelectedFormats([]);
        }
    }, [selectedType]);

    return (
        <div className="row noMargin">
            <SimpleSelect
                selected={selectedType}
                endpoint={reports || []}
                // @ts-ignore
                setSelected={setSelectedType}
                optionLabel="name"
                optionKey="key"
                optionValue="key"
                title="Тип отчета"
            />
            <GetReports
                onDownload={onDownload}
                disabled={selectedType.length === 0}
                onChange={setSelectedFormats}
                selected={selectedFormats}
                reportId={selectedType}
                helperText={undefined}
            />
        </div>
    );
};

export default GetReportFields;
