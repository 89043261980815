import moduleName from './module';
const prefix = `${moduleName}`;

/**
 * Constants
 * */

export const LOADING_SIDEBAR = `${prefix}/LOADING_SIDEBAR`;
export const LOAD_SIDEBAR = `${prefix}/LOAD_SIDEBAR`;
export const LOADED_SIDEBAR = `${prefix}/LOADED_SIDEBAR`;
export const LOAD_POLYGON = `${prefix}/LOAD_POLYGON`;
export const LOADED_POLYGON = `${prefix}/LOADED_POLYGON`;
export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;
export const SET_SAVE = `${prefix}/SET_SAVE`;
export const RESET_ACTIVE = `${prefix}/RESET_ACTIVE`;
export const SET_DELETE_FORM = `${prefix}/SET_DELETE_FORM`;
export const SET_EDIT_FORM = `${prefix}/SET_EDIT_FORM`;
export const CREATE_DTP_FOCUS = `${prefix}/CREATE_DTP_FOCUS`;
export const EDIT_DTP_FOCUS = `${prefix}/EDIT_DTP_FOCUS`;
export const SAVE_DTP_FOCUS = `${prefix}/SAVE_DTP_FOCUS`;
export const DELETE_DTP_FOCUS = `${prefix}/DELETE_DTP_FOCUS`;
export const SET_FILTERS = `${prefix}/SET_FILTERS`;
export const LOADING = `${prefix}/LOADING`;
export const LOADING_DTP_FOCUS = `${prefix}/LOADING_DTP_FOCUS`;
export const LOAD_DTP_FOCUS = `${prefix}/LOAD_DTP_FOCUS`;
export const LOADED_DTP_FOCUS = `${prefix}/LOADED_DTP_FOCUS`;
export const LOAD_DTP_FOCUS_STATISTIC = `${prefix}/LOAD_DTP_FOCUS_STATISTIC`;
export const LOADED_DTP_FOCUS_STATISTIC = `${prefix}/LOADED_DTP_FOCUS_STATISTIC`;
export const LOADING_DTP_FOCUS_STATISTIC = `${prefix}/LOADING_DTP_FOCUS_STATISTIC`;

export const LOAD_HEAT_MAP = `${prefix}/LOAD_HEAT_MAP`;
export const LOADED_HEAT_MAP = `${prefix}/LOADED_HEAT_MAP`;
export const LOADING_HEAT_MAP = `${prefix}/LOADING_HEAT_MAP`;
export const SET_FILTER_HEAT_MAP = `${prefix}/SET_FILTER_HEAT_MAP`;

export const LOADING_DTP_POLYGON = `${prefix}/LOADING_DTP_POLYGON`;
export const SET_POLYGON_VISIBLE = `${prefix}/SET_POLYGON_VISIBLE`;
