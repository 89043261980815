import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import colorExtend from 'helpers/mapHelper/colorExtend';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
// todo пройтись и посмотреть импорты -> вынести в общий
import { checkBase64 } from '../../TransportPassenger/helper';
import icon from '../icons/icon';
import config  from '../config';
import Information from './Information';


const PopUp = (props) => {
    const { id: external_id, readOnly = false, statusesObject = {}, images = {} } = props;

    const polygon = useSelector(SituationalPlansSelectors.polygon);
    // data - транспортный инцидент, на который кликнули, весь объект инцидента
    const data = polygon.find(({ id }) => external_id === id) || {};

    const status = data?.status_id || 0;
    const name = data?.name || '';

    const getColorByStatus = (status_id) => {
        return colorExtend(statusesObject?.[status_id]?.color || config.mapMarkersColors.default);
    };

    const createIcon = useCallback(() => {
        const type_id = data?.type_id || 0;
        const imageB64 = images?.[type_id] // иконка по типу
            || images?.[0] // иконка по умолчанию из даных
            || null; // нет иконки

        return imageB64 ? `<img class="image-inner" src="${checkBase64(imageB64)}">` : `${icon({ fill: '#000' })}`;
    }, [images, data]);

    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: getColorByStatus(status) }}>
                <div className="map-popup_header_">
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: createIcon() }}
                        // dangerouslySetInnerHTML={{ __html: icon({ fill: '#000' }) }}
                    />
                    <span>
                        {name}
                    </span>
                </div>
            </div>
            <div className="map-popup_body">
                <Information
                    data={data}
                    readOnly={readOnly}
                    statusesObject={statusesObject}
                />
            </div>
        </div>
    );
};

export default PopUp;
