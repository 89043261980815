import { useDispatch } from 'react-redux';
import UniversalSelect from 'components/common/UniversalSelect';
import { clearContractLinkObjects, loadContractLinkObjects } from 'redux/GovernmentContracts/actions';
import removeEmptyFields from 'helpers/removeEmptyFields';

interface EntityListModalProps {
    isOpen: boolean;
    onClose: () => void;
    onChange: (arg: any) => void;
    selected: any;
    url: string;
    idGC: number;
    entityName: string;
}

const EntityListModal = ({ isOpen, onClose, selected, onChange, url, idGC, entityName }: EntityListModalProps) => {
    const dispatch = useDispatch();

    const getLnkList = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query: name } = params;
        const currentParams = {
            name,
            simple: 1,
        };
        dispatch(loadContractLinkObjects(page, limit, url, removeEmptyFields(currentParams)));
    };

    const handleClose = () => {
        dispatch(clearContractLinkObjects());
        onClose();
    };

    const handleAccept = (value: any) => {
        onChange(value);
        handleClose();
    };

    const disableFunc = (el: any) => {
        if (el.contract_id && el.contract_id !== idGC) {
            return true;
        } else return false;
    };

    return (
        <UniversalSelect
            multiple
            fetchList={getLnkList}
            storeName="govermentContracts"
            storeNameProps="contractLinkObjects"
            storeLoadingProps="loadingLinkObjects"
            keyProp="id"
            withSearch
            isSelected
            medium
            selected={selected}
            renderProps={(el) => <div>
                <b>Название: </b>{el.name}
            </div>}
            isOpen={isOpen}
            onClose={handleClose}
            onAccept={handleAccept}
            noPadding
            title={entityName}
            disableFunc={disableFunc}
        />
    );
};

export default EntityListModal;