import React, { useState } from 'react';
import {
    Map,
    MapControl,
    Cluster,
    ClusterPopUp,
} from '../../../../../MapComponents/leaflet';
import Polygon from './Polygon';
import { config } from '../../../../../../config';
import iconCreateFunction from './icon/iconCreateFunction';
import createIcon from './icon/CreateIcon';
import ClusterInfo from './ClusterInfo';


const DtpMap = () => {
    const [mapCenter] = useState(config.get('mapCenter'));
    const [mapZoom] = useState( 10);

    return (

        <Map
            center={mapCenter}
            zoom={mapZoom}
        >
            <MapControl>
                <Cluster
                    iconCreateFunction={iconCreateFunction(createIcon)}
                    isClick
                >
                    <Polygon/>

                    <ClusterPopUp
                        minWidth={750}
                        isMoveOnClick={true}
                    >
                        <ClusterInfo />
                    </ClusterPopUp>
                </Cluster>
            </MapControl>
        </Map>
    );
};

export default DtpMap;