import React, { useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import cn from 'classnames';
import DateRange from '../../../../common/Dates/DateRange';
import titles from '../../../../../helpers/constants/titles';
import { loadTrackingList } from '../../../../../redux/WantedCars/actions';
import FormButtons from '../../../../common/FormButtons';
import { buttonsTypes } from '../../../../common/FormButtons';
import { useDispatch } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Filter = ({ status }) => {
    const dispatch = useDispatch();

    const [data, setData] = useState({
        grz: '',
        period_start: null,
        period_end: null,
        status: ''
    });

    const handleResetFilter = () => {
        setData({
            grz: '',
            period_start: null,
            period_end: null,
            status: ''
        });
        dispatch(loadTrackingList(1, 25));
    };

    const handleSearch = () => {
        const paramsWithoutEmpty = Object.keys(data).reduce((res, key) => {
            if (data[key]) {
                res[key] = data[key];
            } else if (typeof data[key] === 'number' && data[key] >= 0) {
                res[key] = data[key];
            }
            return res;
        }, {});
        dispatch(loadTrackingList(1, 25, paramsWithoutEmpty));
    };

    return (
        <div className="filter">
            <div className={cn('filter__wrap', 'filter__wrap__more__field')}>
                <FormControl size="small" variant="outlined">
                    <TextField
                        label="Гос номер ТС (ГРЗ)"
                        variant="outlined"
                        size="small"
                        value={data.grz}
                        name={'grz'}
                        onChange={(e) => setData({ ...data, grz: e.target.value })}
                        type="text"
                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                    />
                </FormControl>
                <DateRange
                    valueStartDate={data.period_start}
                    valueEndDate={data.period_end}
                    handleDate={(e) => setData({ ...data, period_start: e })}
                    handleEndDate={(e) => setData({ ...data, period_end: e })}
                    startLabel={titles.START_PERIOD}
                    endLabel={titles.END_PERIOD}
                />
                <FormControl size="small" variant="outlined">
                    <InputLabel>{titles.SELECT_STATUS}</InputLabel>
                    <Select
                        value={data.status >= 0 ? data.status : ''}
                        onChange={(e) => setData({ ...data, status: e.target.value })}
                        label={titles.SELECT_STATUS}
                    >
                        {Object.keys(status).map((index) => (
                            <MenuItem key={index} value={index}>{status[index]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: handleResetFilter
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: handleSearch
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;
