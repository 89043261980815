const wantedTitles = {
    DEPLOYED_INFO: 'Развернутая информация',
    CHANGE_UPLOAD: 'Изменить выгрузку',
    STATE_REASON: 'Укажите причину',
    STOP_WANTED: 'Остановить розыск',
    INDICATE_REASON_STOPPING_SEARCH: 'Укажите причину остановки розыска',
    BASIS_DEPOSIT: 'Основание внесения',
    DATE_START_SEARCH: 'Дата начала розыска',
    END_DATE_SEARCH: 'Дата окончания розыска',
    DATE_TIME_STOPPING_SEARCH: 'Дата и время остановки розыска',
    REASON_STOPPING_SEARCH: 'Причина остановки розыска',
    SEARCH_STOPPED: 'Розыск остановлен',
    WANTED_STOP: 'Остановка розыска',
};

export default wantedTitles;
