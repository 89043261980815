import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import titles from 'helpers/constants/titles';
import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import ModalAddEdit from './ModalAddEdit';

const Item = ({
    item,
    reloadList,
    actions,
    loading,
    permissions,
    storeName,
}) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const onAccept = () => dispatch(actions.deletePositions(item?.id, () => reloadList(true)));

    return (
        <>
            <LSContentItem>
                <LSContentColumn title={titles.NAME} width="200px">
                    {item?.name || ''}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setModalOpen(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setConfirmOpen(true),
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
            {confirmOpen
                && <ConfirmDelete
                    onClose={() => setConfirmOpen(false)}
                    onSuccess={onAccept}
                    open={confirmOpen}
                />
            }
            {modalOpen
                && <ModalAddEdit
                    onClose={() => setModalOpen(false)}
                    isOpen={modalOpen}
                    item={item}
                    reloadList={reloadList}
                    actions={actions}
                    loading={loading}
                    storeName={storeName}
                />
            }
        </>
    );
};

export default Item;
