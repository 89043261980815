import React from 'react';
import BoxContainer from '../../../common/Showcase/BoxContainer';
import { HeaderPageVisible } from '../../../common/Showcase/HeaderPage';
import HeaderPanel from '../../../common/Showcase/HeaderPanel';
import Page from './Page';

const EcologyMonitoring = () => {
    return (
        <BoxContainer>

            {/* шапка */}
            <HeaderPageVisible/>

            <HeaderPanel title="Статистика экомониторинг"/>

            {/* содержимое страницы */}
            <Page/>

        </BoxContainer>
    );
};

export default EcologyMonitoring;