import reducer from './reducers';

export { default as moduleName } from './module';

export { default as saga } from './sagas';

export * as companiesSelectors from './selectors';

export * as companiesActions from './actions';

export default reducer;
