import React from 'react';
import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import DateRange from '../../../../../../common/Dates/DateRange';
import Loading from '../../../../../../common/Loading';
import WantedCars from '../../../../../../common/Autocomplete/WantedCars';
import titles from '../../../../../../../helpers/constants/titles';


const Form = (props) => {
    const {
        data = {},
        onChange,
        requiredFields,
        validation,
        loading,
    } = props;

    // const typesBase = useStoreProp(loadTypesBase,'carsWanted', 'typesBase');

    const handleEvent = (event) => {
        const { target: { name, value } } = event;
        onChange({
            [name]: value
        });
    };

    const getValue = (key, def = '') => data[key] ? data[key] : def;

    const isErrorField = (key) => {
        return (requiredFields.includes(key) && !data[key]) || validation.isKey(key);
    };

    const getErrorValidation = (key) => validation.get(key);

    return (
        <>
            <form
                className="modal__form"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <div className="block">
                    <TextField
                        multiline={true}
                        size="small"
                        label={titles.GRZ}
                        variant="outlined"
                        value={getValue('grz')}
                        onChange={handleEvent}
                        name="grz"
                        error={isErrorField('grz')}
                        helperText={getErrorValidation('grz')}
                    />
                </div>
                <div className="block">
                    <TextField
                        required={true}
                        multiline={true}
                        size="small"
                        label={titles.BASIS_DEPOSIT}
                        variant="outlined"
                        value={getValue('reason')}
                        onChange={handleEvent}
                        name="reason"
                        error={isErrorField('reason')}
                        helperText={getErrorValidation('reason')}
                    />
                </div>
                <div className="row">
                    <DateRange
                        handleDate={(newDate) => onChange({ start_wanted_at: newDate })}
                        handleEndDate={(newDate) => onChange({ end_wanted_at: newDate })}
                        valueStartDate={getValue('start_wanted_at', null)}
                        valueEndDate={getValue('end_wanted_at', null)}
                        startLabel={titles.DATE_START_SEARCH}
                        endLabel={titles.END_DATE_SEARCH}
                    />
                </div>
                <div className="block">
                    <TextField
                        multiline={true}
                        size="small"
                        label={titles.DESCRIPTION}
                        variant="outlined"
                        value={getValue('description')}
                        onChange={handleEvent}
                        name="description"
                        error={isErrorField('description')}
                        helperText={getErrorValidation('description')}
                    />
                </div>
                <WantedCars 
                    selectedBrand={{ brand: getValue('brand') }}
                    selectedModel={{ model: getValue('model') }}
                    selectedColor={{ color: getValue('color') }}
                    onChange={(value) => onChange(value)}
                    validation={validation}
                />
                {/*<FormControl*/}
                {/*    disabled={!getValue('cars_wanted_type_id')}*/}
                {/*    variant="outlined"*/}
                {/*    size="small"*/}
                {/*    className="block"*/}
                {/*    error={isErrorField('cars_wanted_type_id')}*/}
                {/*>*/}
                {/*    <InputLabel>Тип Базы розыска</InputLabel>*/}
                {/*    <Select*/}
                {/*        labelWidth={90}*/}
                {/*        value={getValue('cars_wanted_type_id')}*/}
                {/*        onChange={handleEvent('cars_wanted_type_id')}*/}
                {/*    >*/}
                {/*        {Object.keys(typesBase).map(item =>*/}
                {/*            <MenuItem key={item} value={typesBase[item]}>{typesBase[item]}</MenuItem>*/}
                {/*        )}*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}
                <FormControlLabel
                    control={<Checkbox
                        name="is_private"
                        onClick={() => onChange({ is_private: !data.is_private })}
                        checked={data.is_private}
                    />}
                    label="Приватный поиск"
                />
                <div className="block">
                    <h2>Отправить уведомление о розыске</h2>
                    <FormControlLabel
                        control={<Checkbox
                            name="is_send_telegram"
                            onClick={() => onChange({ is_send_telegram: !data.is_send_telegram })}
                            checked={data.is_send_telegram}
                        />}
                        label="Телеграм"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            name="is_send_mail"
                            onClick={() => onChange({ is_send_mail: !data.is_send_mail })}
                            checked={data.is_send_mail}
                        />}
                        label="Почта"
                    />
                </div>
                

                {loading && <Loading linear={true}/>}
            </form>
        </>
    );
};

export default Form;
