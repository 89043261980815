const dtpStatusConfig = (status) => {
    const statuses = {
        1: {
            status: 1,
            status_text: 'Новое',
            icon: <i className="far fa-exclamation-circle"></i>,
            color: '#0fa3b0',
        },
        2: {
            status: 2,
            status_text: 'Подтвержденное',
            icon: <i className="far fa-check-circle"></i>,
            color: '#4b9c4b',
        },
        3: {
            status: 3,
            status_text: 'Не подтвержденное',
            icon: <i className="far fa-ban"></i>,
            color: '#da573e',
        }
    };
    return statuses[status] || 'Неизвестный статус';
};

export default dtpStatusConfig;