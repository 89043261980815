import ServiceStatus from '../pages/Admin/ServiceStatus';
import RoleManagement from '../pages/Admin/RoleManagement';
import UserManagment from '../pages/Admin/UserManagment';
import DtpSettings from '../pages/Admin/DtpSettings';
import CameraSettings from '../pages/Admin/CameraSettings';
import EmailSettings from '../pages/Admin/emailSettings';
import AdminSettings from '../pages/Admin/Settings';
import UserActivity from '../pages/Admin/UserActivity';

const routes = [
    {
        path: '/admin/services',
        component: ServiceStatus,
        exact: true,
    },
    {
        path: '/admin/roles',
        component: RoleManagement,
        exact: true,
    },
    {
        path: '/admin/users',
        component: UserManagment,
        exact: true,
    },
    {
        path: '/admin/dtp-settings',
        component: DtpSettings,
        exact: true,
    },
    {
        path: '/admin/camera-settings',
        component: CameraSettings,
        exact: true,
    },
    {
        path: '/admin/email-settings',
        component: EmailSettings,
        exact: true,
    },
    {
        path: '/admin/settings',
        component: AdminSettings,
        exact: true,
    },
    {
        path: '/admin/user-activity',
        component: UserActivity,
        exact: true,
    },
];

export default routes;
