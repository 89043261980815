import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { Grid, List } from '@mui/material';

import { loadAipList } from 'redux/Aip/actions';
import CustomPagination from 'components/common/CustomPagination';
import Loading from 'components/common/Loading';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PassportTabs from 'components/common/PassportTabs';
import messages from 'helpers/constants/messages';
import Context from 'helpers/context';
import titles from 'helpers/constants/titles';

import BasicData from './PassportPage/BasicData';
import Specifications from './PassportPage/Specifications';
import Monitoring from './PassportPage/Monitoring';
import ModalForm from './ModalForm';
import Filter from './Filter';
import Item from './Item';

const Aip = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);
    const scrollRef = useRef(null);

    const [params, setParams] = useState('');
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);

    const [currentAip, setCurrentAip] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const requestStatus = useSelector(({ validation }) => validation);

    const {
        list: { data, meta },
        loadingList,
    } = useSelector(({ aip }) => aip);

    useEffect(() => {
        dispatch(loadAipList( 1, limit, params ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, requestStatus.success]);

    const RenderList = () => (
        <List className="list" disablePadding={true}>
            {data?.length && data?.map(el =>
                <Item
                    key={el.id}
                    item={el}
                    selectedItem={el?.id === currentAip?.id}
                    onClickItem={() => {
                        setCurrentAip(el?.id === currentAip?.id ? null : el);
                    }}
                />
            )}
        </List>
    );

    return <>
        <h1>Справочник АИП</h1>

        <Filter
            setParams={setParams}
            setCurrentAip={setCurrentAip}
        />
        <div className="filter__wrap__btn filter__btn__column">
            <FormButtons
                buttons={[
                    {
                        ...buttonsTypes.add,
                        onClick: () => setOpenModal(true),
                        disabled: !permissions?.is_create
                    }
                ]}
            />
        </div>
        {openModal
            && <ModalForm
                isNew={true}
                onClose={() => setOpenModal(false)}
                isOpen={openModal}
            />}

        {loadingList && <Loading circular={true}/>}

        {data?.length > 0
            ? <div style={{
                minWidth: '60%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Grid container style={currentAip ? { height: '100%' } : {}}>
                    <Grid item xs={ currentAip ? 3 : 12 } style={{ height: '100%' }}>
                        {currentAip
                            ? <Scrollbars style={{ height: '100%' }} ref={scrollRef}>
                                {RenderList()}
                            </Scrollbars>
                            : RenderList()
                        }
                    </Grid>
                    {currentAip && (
                        <Grid item xs={9}>
                            <PassportTabs tabs={[
                                {
                                    value: 'data',
                                    label: titles.BASIC_DATA,
                                    icon: <i className="fal fa-info-square"/>,
                                    contentComponent: <BasicData currentAip={currentAip}/>,
                                },
                                {
                                    value: 'specifications',
                                    label: 'Технические параметры',
                                    icon: <i className="fal fa-file-alt"/>,
                                    contentComponent: <Specifications currentAip={currentAip}/>,
                                },
                                {
                                    value: 'monitoring',
                                    label: titles.STATE_MONITORING,
                                    icon: <i className="far fa-monitor-heart-rate"/>,
                                    contentComponent: <Monitoring currentAip={currentAip}/>,
                                },
                            ]}/>
                        </Grid>
                    )}
                </Grid>
                <CustomPagination
                    loadList={(page, limit) => dispatch(loadAipList( page, limit, params ), setCurrentAip(null))}
                    list={meta}
                    limit={limit}
                    setLimit={setLimit}
                />
            </div>
            : !loadingList && <div>{messages.DATA_IS_NOT_FOUND}</div>
        }
    </>;
};

export default Aip;
