import { Collapse } from '@mui/material';
import messages from 'helpers/constants/messages';
import { humanDateWithoutTZ } from 'helpers/date.config';
import type { PillarsItem } from 'types/RoadNetwork';

interface InfoProps {
    isOpen: boolean,
    data: PillarsItem
}

const Info = ({ isOpen, data }: InfoProps) => {
    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            <div>
                <strong>Доп. информация: </strong>
                {data.additional_info || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>Собственник: </strong>
                {data.organization_contacts?.title || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>Номер договора: </strong>
                {data.contract_number || messages.INFO_IS_NOT_FOUND}
            </div>

            <div>
                <strong>Срок действия договора: </strong>
                {humanDateWithoutTZ(data.contract_at) || messages.INFO_IS_NOT_FOUND}
            </div>
        </Collapse>
    );
};

export default Info;
