import { useState } from 'react';
import titles from '../../../../helpers/constants/titles';
import SelectCompany from '../../../common/Autocomplete/Companies';
import SelectRoutes from '../../../common/Autocomplete/PassengerTransport/Routes';
import SelectTrafficSchedule from '../../../common/Autocomplete/PassengerTransport/TrafficSchedule';
import DateRange from '../../../common/Dates/DateRange';
import LayoutFilter from '../../../layout/PageLayout/LayoutFilter';

const Filter = ({ data, initialData, onAccept, onClear }) => {
    const [fields, setFields] = useState(data);
    const [isDisabled, setIsDisabled] = useState(false);

    const resetFilter = (needRefresh) => {
        if (needRefresh) {
            onClear();
        }
        setFields(initialData);
    };

    const handleChange = (value, name) => {
        const newValue = {
            ...fields, 
            [name]: value
        };
        setFields(newValue);
    };

    const errorDateRange = () => {
        const {
            start_date = null,
            end_date = null,
        } = fields;

        return !start_date || !end_date;
    };

    const updateFilter = (filters) => {
        setFields({
            ...initialData,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            onAccept({
                ...initialData,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={() => onAccept(fields)} 
            filter={fields} 
            setUserFilter={updateFilter}
            disabled={isDisabled}
            filterException={['start_date', 'end_date',]}
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={ (newDate) => {
                        handleChange(newDate, 'start_date');
                    }}
                    handleEndDate={ (newDate) => {
                        handleChange(newDate, 'end_date');
                    }}
                    valueStartDate={fields?.start_date || null}
                    valueEndDate={fields?.end_date || null}
                    isDisabledHandler={(value) => setIsDisabled(value)}
                    renderError={errorDateRange}
                    errorMessage={() => titles.SELECT_PERIOD}
                    dateOnly
                />

                <SelectCompany
                    onChange={(value) => {
                        handleChange(value, 'organization_id_list');
                    }}
                    selected={fields.organization_id_list || []}
                    multiple
                    filter={{ withDeleted: 1 }}
                    selectDeletedCompanies
                    label={titles.ORGANIZATION}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <SelectRoutes
                    selected={fields.route_id_list}
                    onChange={
                        (value) => setFields((oldFields) => ({
                            ...oldFields,
                            route_id_list: value,
                            traffic_schedule_id_list: [],
                        }))
                    }
                />

                <SelectTrafficSchedule
                    multiple
                    selected={fields.traffic_schedule_id_list || []}
                    onChange={(value) => handleChange(value, 'traffic_schedule_id_list')}
                    filter={{ route_id: fields?.route_id_list?.id }} 
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;