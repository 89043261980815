import React from 'react';

const Information = ({ data }) => {
    return <>
        <div className="info__item">
            <span className="info__item-title">
                Наименование АИП:
            </span>
            <span className="info__item-content">
                {data.name}
            </span>
        </div>    
        <div className="info__item">
            <span className="info__item-title">
                Тип АИП:
            </span>
            <span className="info__item-content">
                {data.type_text}
            </span>
        </div>    
        <div className="info__item">
            <span className="info__item-title">
                Серийный номер:
            </span>
            <span className="info__item-content">
                {data.serial_number}
            </span>
        </div>    
        <div className="info__item">
            <span className="info__item-title">
                Серийный номер КФВФ:
            </span>
            <span className="info__item-content">
                {data.complex_serial_number}
            </span>
        </div>    
        <div className="info__item">
            <span className="info__item-title">
                Адрес:
            </span>
            <span className="info__item-content">
                {data.address_text}
            </span>
        </div>    
        <div className="info__item">
            <span className="info__item-title">
                Координаты (lat, lon):
            </span>
            <span className="info__item-content">
                {`${data.lat}, ${data.lon}`}
            </span>
        </div>    
        <div className="info__item">
            <span className="info__item-title">
                IP адрес:
            </span>
            <span className="info__item-content">
                {data.ip_route}
            </span>
        </div>        
        <div className="info__item">
            <span className="info__item-title">
                imei:
            </span>
            <span className="info__item-content">
                {data.imei}
            </span>
        </div>    
        <div className="info__item">
            <span className="info__item-title">
                Моб. телефон:
            </span>
            <span className="info__item-content">
                {data.phone}
            </span>
        </div>    
        <div className="info__item">
            <span className="info__item-title">
                Оператор сети:
            </span>
            <span className="info__item-content">
                {data.phone_operator_text}
            </span>
        </div>    
        <div className="info__item">
            <span className="info__item-title">
                icc SIM-карты:
            </span>
            <span className="info__item-content">
                {data.icc}
            </span>
        </div>    
    </>;
};

export default Information;
