import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { sumBy } from 'lodash';


const PieComponent = ({ title = '', data = [], onChangeVisible, style = {} }) => {
    const callbacks = {
        label: (context) => `${context?.label} ${context?.formattedValue}%`,
        title: () => ''
    };

    const [percent, setPercent] = useState([]);
    const [colors, setColors] = useState([]);
    const [labels, setLabels] = useState([]);
    const [labelsId, setLabelsId] = useState([]);

    useEffect(() => {
        if (data) {
            const sum = sumBy(data.map(({ value = 0 }) => value));

            const list = data?.reduce((res, { id = 0, name, color, value = 0 }) => ({
                colors: [
                    ...res.colors,
                    color,
                ],
                labels: [
                    ...res.labels,
                    `${name} (${value || 0})`
                ],
                id: [
                    ...res.id,
                    id
                ],
                percent: [
                    ...res.percent,
                    Math.round(((value || 0) * 100) / sum),
                ]
            }), {
                colors: [],
                labels: [],
                id: [],
                percent: [],
            });
            setColors(list.colors);
            setLabels(list.labels);
            setLabelsId(list.id);
            setPercent(list.percent);
        }
    }, [data]);

    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        redraw: false,
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks
            },
            legend: {
                // align: 'center',
                position: 'right',
                labels: {
                    boxWidth: 20,
                },
                onClick: function (e, legendItem, legend) {
                    const index = legendItem.index;
                    const visible = legendItem.hidden;

                    legend.chart.toggleDataVisibility(index);
                    legend.chart.update();

                    const id = labelsId[index] || undefined;
                    id && onChangeVisible && onChangeVisible(id, visible);
                }
            }
        }
    };

    const datasets = {
        data: percent,
        maxBarThickness: 80,
        backgroundColor: colors,
        borderColor: '#fff',
    };

    return (
        <div style={style}>
            <h4 style={{ textAlign: 'center' }}>{title}</h4>
            <div>
                <Pie
                    width={'150px'}
                    height={'150px'}
                    data={{
                        labels,
                        datasets: [datasets],
                    }}
                    options={options}
                    type="Pie"
                />
            </div>
        </div>
    );
};

export default PieComponent;
