import L from 'leaflet';
import iconMap from './icons/iconMap';

// получить иконку маркера
export const createIconMarker = (color) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: iconMap({ fill: color })
    });
};