import { config } from '../../config';

const apiMain = () => config.getGeneral('urls')?.apiMain;

const apiUrls = {

    getWsChannels: () => `${apiMain()}/websocket/channels/available`,

    getWsToken: () => `${apiMain()}/websocket/auth`,

    getWsTokenSubscribe: (channel) => `${apiMain()}/websocket/subscribe/${channel || ''}`,

};

export default apiUrls;
