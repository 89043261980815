import { Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
import styles from './styles.module.scss';

const StyledRating = styled(Rating)({
    '& .MuiRating-icon': {
        fontSize: 40,
    },
});

export const RatingComponent = ({ text, value }) => {
    return (
        <div className={styles.container}>
            <h2>{text}</h2>
            <div className={styles.block}>
                <StyledRating
                    name="simple-controlled"
                    value={value}
                    precision={0.1}
                    readOnly
                />
                <div className={styles.text}>{value}</div>
            </div>    
        </div>
    );
};