// иконка для списка
const iconCluster = (props) => {
    return `
        <svg viewBox="0 0 107.000000 107.000000" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
         <g>
          <g id="svg_eo_3">
           <rect fill="none" id="canvas_background" height="26.883714" width="26.883714" y="-1" x="-1"/>
          </g>
          <g id="svg_eo_4">
           <g id="svg_eo_1" fill="${props?.fill || '#e7e7e7'}" transform="translate(0,107) scale(0.10000000149011612,-0.10000000149011612) ">
            <path stroke="null" id="svg_eo_2" d="m404.522364,1047.198783c-227.702247,-55.170148 -397.225066,-272.841459 -397.225066,-511.577736c0,-285.881676 240.742464,-525.621047 526.62414,-525.621047c285.881676,0 526.62414,240.742464 526.62414,526.62414c0,237.733183 -172.532099,457.410682 -402.240534,511.577736c-79.244394,18.055685 -175.54138,18.055685 -253.782681,-1.003094l0.000001,0.000001z"/>
           </g>
          </g>
          <g fill="${props?.innerFill || 'rgba(0, 0, 0, 0.54)'}" stroke="null" id="svg_eo_9">
           <path stroke="null" id="svg_eo_8" d="m45.89194,61.31484l-1.146523,6.65959c-0.20735,1.207508 1.061143,2.097893 2.122287,1.536828l5.964358,-3.146839l5.964358,3.146839c1.061143,0.561064 2.329637,-0.341517 2.122287,-1.536828l-1.146523,-6.65959l4.830032,-4.708062c0.865991,-0.841597 0.390306,-2.31744 -0.805005,-2.500396l-6.671787,-0.975764l-2.988278,-6.049737c-0.53667,-1.073341 -2.085696,-1.097735 -2.622366,0l-2.988278,6.049737l-6.671787,0.975764c-1.195311,0.170759 -1.670996,1.646602 -0.805005,2.500396l4.842229,4.708062zm28.41913,-32.614915l-5.854585,0l0,-5.854585c0,-1.073341 -0.878188,-1.951528 -1.951528,-1.951528l-3.903056,0c-1.073341,0 -1.951528,0.878188 -1.951528,1.951528l0,5.854585l-15.612226,0l0,-5.854585c0,-1.073341 -0.878188,-1.951528 -1.951528,-1.951528l-3.903056,0c-1.073341,0 -1.951528,0.878188 -1.951528,1.951528l0,5.854585l-5.854585,0c-3.232219,0 -5.854585,2.622366 -5.854585,5.854585l0,42.933621c0,3.232219 2.622366,5.854585 5.854585,5.854585l42.933621,0c3.232219,0 5.854585,-2.622366 5.854585,-5.854585l0,-42.933621c0,-3.232219 -2.622366,-5.854585 -5.854585,-5.854585zm0,48.056383c0,0.402503 -0.32932,0.731823 -0.731823,0.731823l-41.469975,0c-0.402503,0 -0.731823,-0.32932 -0.731823,-0.731823l0,-36.347213l42.933621,0l0,36.347213z"/>
           </g>
         </g>
        </svg>
    `;
};

export default iconCluster;
