import React, { useEffect, useState } from 'react';
import { FeatureGroup, Map, Marker, ToolTip } from '../../../../../MapComponents/leaflet';
import { config } from '../../../../../../config';
// import styles from './workOrderControl.module.scss';

const EventMap = ({ latlon }) => {
    const [mapCenter, setMapCenter] = useState(config.get('mapCenter'));

    useEffect(()=> {
        if (!latlon[0] && !latlon[1]) {
            setMapCenter(config.get('mapCenter'));
        } else {
            setMapCenter(latlon);
        }
    },[latlon]);

    return (
        // <div className={styles.map}>
        <Map center={mapCenter}>
            <FeatureGroup>
                {latlon
                    &&  <Marker latlng={latlon}>
                        <ToolTip
                            offset={[0, -40]}
                            permanent={true}
                            direction="top"
                        >
                            <div>
                                <span style={{ display: 'flex', padding: 2 }}>
                                    <strong>Широта:&nbsp;</strong>
                                    {latlon[0]}
                                </span>
                                <span style={{ display: 'flex', padding: 2 }}>
                                    <strong>Долгота:&nbsp;</strong>
                                    {latlon[1]}
                                </span>
                            </div>
                        </ToolTip>
                    </Marker>
                }
            </FeatureGroup>
        </Map>
        // </div>
    );
};

export default EventMap;
