import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { loadTypeOfRoadDefections } from 'redux/RoadWorks/actions';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const DefectTypes = ({
    multiple = false,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label='Типы недостатков',
    error = false,
    helperText = '',
    withSearch = true,
    filter = {}, //group_ids
    limitTags = 1
}) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);
    
    useEffect(() => {
        // например сброс фильтра - selected изменился нужно обновить selectedItems
        if (!_.isEqual(prevData.current, selected)) { 
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const getList = (params) => {
        const { page, limit, query: name } = params;

        dispatch(loadTypeOfRoadDefections(page, limit,{
            ...(name && { name }),
            ...filter,
        }));
    };

    const handleClose = () => setShowList(false);

    const handleOpen = () => {
        if(!disabled){
            setShowList(true);
        };
    };

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    const onReset = () => {
        const initialState = multiple ? [] : {};
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selectedItems}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={(option) => option?.name || ''}
                limitTags={limitTags}
                label={label}
                onShowList={handleOpen}
                error={error}
                helperText={helperText}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    storeName={'roadworks'}
                    storeNameProps={'typeOfRoadDefections'}
                    keyProp={'id'}
                    fetchList={getList}
                    withSearch={withSearch}
                    multiple={multiple}
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div>{el.name}</div>}
                    isOpen={showList}
                    onAccept={handleAccept}
                    onClose={handleClose}
                    noPadding
                    title={label}
                />
            }
        </>
    );
};

export default DefectTypes;
