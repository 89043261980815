import { useState } from 'react';
import { TextField } from '@mui/material';

import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import titles from 'helpers/constants/titles';

const Filters = ({ setParams }) => {
    const [value, setValue] = useState({
        query: ''
    });

    const handleChange = (e) => {
        setValue({ query: e.target.value });
    };

    const search = (data = value) => {
        if (data.query) {
            setParams({ page: 1, data });
        }
        else {
            resetSearch();
        }
    };

    const resetSearch = (needRefresh) => {
        setValue({ query: '' });
        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }
    };

    const updateFilter = (filters) => {
        setValue(filters);
        if (Object.keys(filters).length > 0) {
            search(filters);
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetSearch} 
            onSearch={search} 
            filter={value} 
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={value.query || ''}
                    onChange={handleChange}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;