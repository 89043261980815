export const roadworks = (state) => state.roadworks;

export const statuses = (state) => roadworks(state).statuses;
// export const history = (state) => roadworks(state).history;

export const list = (state) => roadworks(state).list;
export const listData = (state) => list(state).data;
export const listMeta = (state) => list(state).meta;

export const sidebarParams = (state) => roadworks(state).sidebarParams;

export const polygon = (state) => roadworks(state).polygon;
export const loadingPolygon = (state) => roadworks(state).loadingPolygon;

export const loading = (state) => roadworks(state).loading;

// export const saved = (state) => roadworks(state).saved;

export const activeRW = (state) => roadworks(state).activeRW;
export const filters = (state) => roadworks(state).filters;
// export const editRW = (state) => roadworks(state).editRW;

export const deleteForm = (state) => roadworks(state).deleteForm;
export const editForm = (state) => roadworks(state).editForm;

// export const filter = (state) => roadworks(state).filter;

export const orders = (state) => roadworks(state).orders;
export const ordersData = (state) => orders(state).data;
export const ordersMeta = (state) => orders(state).meta;

export const loadingOrders = (state) => roadworks(state).loadingOrders;
export const listOrders = (state) => roadworks(state).listOrders;
export const listOrdersData = (state) => listOrders(state).data;
export const listOrdersMeta = (state) => listOrders(state).meta;

export const ordersReportFilter = (state) => roadworks(state).ordersReportFilter;

// export const orderStatuses = (state) => roadworks(state).orderStatuses;
// export const orderDirections = (state) => roadworks(state).orderDirections;

export const defectGroup = (state) => roadworks(state).defectGroup;
export const defectGroupData = (state) => defectGroup(state).data;
export const defectGroupMeta = (state) => defectGroup(state).meta;

export const loadingDefectTypes = (state) => roadworks(state).loadingDefectTypes;
export const defectTypes = (state) => roadworks(state).defectTypes;
export const defectTypesData = (state) => defectTypes(state).data;
export const defectTypesMeta = (state) => defectTypes(state).meta;

// export const stagesStatuses = (state) => roadworks(state).stagesStatuses;

export const loadingStages = (state) => roadworks(state).loadingStages;
export const stages = (state) => roadworks(state).stages;
export const stagesData = (state) => stages(state).data;
export const stagesMeta = (state) => stages(state).meta;

// export const stageCargoTypes = (state) => roadworks(state).stageCargoTypes;
// export const typeOfRoadDefections = (state) => roadworks(state).typeOfRoadDefections;
// export const loadingTypeOfRoadDefections = (state) => roadworks(state).loadingTypeOfRoadDefections;
// export const typeCreation = (state) => roadworks(state).typeCreation;

export const unitList = (state) => roadworks(state).unitList;
export const unitListData = (state) => unitList(state).data;
export const unitListMeta = (state) => unitList(state).meta;
export const loadingUnits = (state) => roadworks(state).loadingUnits;

export const roadWorksGroups = (state) => roadworks(state).roadWorksGroups;
export const roadWorksGroupsData = (state) => roadWorksGroups(state).data;
export const roadWorksGroupsMeta = (state) => roadWorksGroups(state).meta;
export const loadingRoadWorksGroups = (state) => roadworks(state).loadingRoadWorksGroups;

export const works = (state) => roadworks(state).works;
export const worksData = (state) => works(state).data;
export const worksMeta = (state) => works(state).meta;
export const worksLoading = (state) => roadworks(state).loadingWorks;

// export const road_works = (state) => roadworks(state).road_works;

export const loading_stage_schedule = (state) => roadworks(state).loading_stage_schedule;
export const stage_schedule = (state) => roadworks(state).stage_schedule;

// export const KDM_works = (state) => roadworks(state).KDM_works;

export const planFactReport = (state) => roadworks(state).planFactReport;
export const loadingPlanFactReport = (state) => roadworks(state).loadingPlanFactReport;

export const loadingDefects = (state) => roadworks(state).loadingDefects;
export const defects = (state) => roadworks(state).defects;
export const defectsData = (state) => defects(state).data;
export const defectsMeta = (state) => defects(state).meta;

export const defectsSidebar = (state) => roadworks(state).defectsSidebar;
export const defectsSidebarData = (state) => defectsSidebar(state).data;
export const defectsSidebarMeta = (state) => defectsSidebar(state).meta;
export const defectsSidebarLoading = (state) => roadworks(state).defectsSidebarLoading;

export const defectsFilters = (state) => roadworks(state).defectsFilters;

export const defectsPolygon = (state) => roadworks(state).defectsPolygon;
export const defectsPolygonLoading = (state) => roadworks(state).defectsPolygonLoading;

export const defectsActive = (state) => roadworks(state).defectsActive;
export const defectsSaved = (state) => roadworks(state).defectsSaved;

export const defectsDeleteForm = (state) => roadworks(state).defectsDeleteForm;
export const defectsEditForm = (state) => roadworks(state).defectsEditForm;

export const defectHeatMap = (state) => roadworks(state).defectHeatMap;
export const defectHeatMapLoading = (state) => roadworks(state).defectHeatMapLoading;
export const defectHeatMapFilter = (state) => roadworks(state).defectHeatMapFilter;

// export const defectStatuses = (state) => roadworks(state).defectStatuses;

export const nearDefects = (state) => roadworks(state).nearDefects;

export const loadingButton = (state) => roadworks(state).loadingButton;

export const loadingSettings = (state) => roadworks(state).loadingSettings;

//showcase
export const showcase = (state) => roadworks(state).showcase;
export const loadingShowcase = (state) => roadworks(state).loadingShowcase;

export const orderTypes = (state) => roadworks(state).orderTypes;
export const orderTypesData = (state) => orderTypes(state).data;
export const orderTypesMeta = (state) => orderTypes(state).meta;
export const orderTypesLoading = (state) => roadworks(state).orderTypesLoading;

export const repairObjectIndicators = (state) => roadworks(state).repairObjectIndicators;
export const repairObjectIndicatorsData = (state) => repairObjectIndicators(state).data;
export const repairObjectIndicatorsMeta = (state) => repairObjectIndicators(state).meta;
export const loadingRepairObjectIndicators = (state) => roadworks(state).loadingRepairObjectIndicators;

export const repairObjects = (state) => roadworks(state).repairObjects;
export const repairObjectsData = (state) => repairObjects(state).data;
export const repairObjectsMeta = (state) => repairObjects(state).meta;
export const loadingRepairObjects = (state) => roadworks(state).loadingRepairObjects;

export const repairObjectTypes = (state) => roadworks(state).repairObjectTypes;

export const sheetsAssessmentRoad = (state) => roadworks(state).sheetsAssessmentRoad;
export const sheetsAssessmentRoadData = (state) => sheetsAssessmentRoad(state).data;
export const sheetsAssessmentRoadMeta = (state) => sheetsAssessmentRoad(state).meta;
export const loadingSheetsAssessmentRoad = (state) => roadworks(state).loadingSheetsAssessmentRoad;
export const loadingSheetsAssessmentRoadForm = (state) => roadworks(state).loadingSheetsAssessmentRoadForm;

export const roadWorksColors = (state) => roadworks(state).roadWorksColors;

export const showcaseOrders = (state) => roadworks(state).showcaseOrders;
export const loadingShowcaseOrders = (state) => roadworks(state).loadingShowcaseOrders;

export const showcaseOrdersReport = (state) => roadworks(state).showcaseOrdersReport;
export const loadingShowcaseOrdersReport = (state) => roadworks(state).loadingShowcaseOrdersReport;

export const roadWorkReportOrders = (state) => roadworks(state).roadWorkReportOrders;
export const roadWorkReportOrdersData = (state) => roadWorkReportOrders(state).data;
export const roadWorkReportOrdersMeta = (state) => roadWorkReportOrders(state).meta;
export const loadingRoadWorkReportOrders = (state) => roadworks(state).loadingRoadWorkReportOrders;

export const planFactReportTypes = (state) => roadworks(state).planFactReportTypes;

