import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { loadedNearDefects, loadNearDefects } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import messages from 'helpers/constants/messages';
import DefectList from '../Defects/DefectList';
import type { Data } from '../../types';

interface NearDefectsProps {
    data: Data;
}

const NearDefects = ({ data }: NearDefectsProps) => {
    const dispatch = useDispatch();

    const nearDefects = useSelector(roadworksSelectors.nearDefects);

    useEffect(() => {
        if (data?.geometry) {
            dispatch(loadNearDefects({ geometry: data }));
        }

        return () => {
            dispatch(loadedNearDefects([]));
        };
    }, [dispatch, data]);

    return (
        <div className="block">
            <h2>Недостатки рядом (для отображения списка выберите сначала Зону действия участка):</h2>

            {nearDefects?.length > 0 
                ? <DefectList items={nearDefects}/>
                : <span style={{ paddingLeft: '0.2rem' }}>{messages.DATA_IS_NOT_FOUND}</span>
            }
        </div>
    );
};

export default NearDefects;