import React from 'react';
import { Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Attachments from '../../../../common/Upload/Attachments';

const useStyles = makeStyles({
    title: {
        marginRight: 15,
        display: 'flex'
    },
    wrap: {
        display: 'flex',
        flexDirection: 'column'
    },
    link: {
        display: 'flex',
        flexDirection: 'column'
    }
});

const RRItemInfo = ({ isOpen, el }) => {
    const classes = useStyles();

    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit className="info">
            <div className={classes.wrap}>
                <strong>Сведения об организации, подавшей заявку: </strong>
                <span className={classes.title}>
                    <strong>ИНН: </strong>{el.inn}
                </span>
                <span className={classes.title}>
                    <strong>Почтовый адрес: </strong>{el.mail_address}
                </span>
                <span className={classes.title}>
                    <strong>Юридический адрес: </strong>{el.juristic_address}
                </span>
                <span className={classes.title}>
                    <strong>E-mail: </strong>{el.email}
                </span>
                <span className={classes.title}>
                    <strong>Телефон: </strong>{el.phone}
                </span>
                {/* {(el.feasibility_study?.length > 0
                    || el.route_schedule_draft?.length > 0
                    || el.route_scheme_draft?.length > 0
                    || el.documents?.length > 0)
                    && <strong>Документы к заявке: </strong>
                } */}
                {el.documents?.length > 0
                && <Attachments
                    label={'Документы - основания'}
                    files={el?.documents || []}
                    readOnly
                />}
                {el.feasibility_study?.length > 0
                && <Attachments
                    label={'Технико-экономическое обоснование'}
                    files={el?.feasibility_study || []}
                    readOnly
                />}
                {el.route_schedule_draft?.length > 0
                &&<Attachments
                    label={'Проект расписания маршрута'}
                    files={el?.route_schedule_draft || []}
                    readOnly
                />}
                {el.route_scheme_draft?.length > 0
                && <Attachments
                    label={'Проект схемы маршрута с указанием остановочных пунктов'}
                    files={el?.route_scheme_draft || []}
                    readOnly
                />}
            </div>
        </Collapse>
    );
};

export default RRItemInfo;
