import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Tooltip
} from '@mui/material';

import {
    createConcentrationArea,
    editConcentrationArea,
    loadConcentrationAreaStatuses,
    loadConcentrationAreaTypes,
    setMKDTPModalData
} from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import { setShowReturnTo } from 'redux/Menu/actions';
import { menuSelectors } from 'redux/Menu';
import { useStoreProp, useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { fullDateTimeWithoutSeconds, fullDateTimeWithTimeZone } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import { GeoJsonNew, Marker, ToolTip } from 'components/MapComponents/leaflet';
import { createIconMarker } from 'components/MapComponents/Layers/Incidents/helper';

import SelectDtpModal from './SelectDtpModal';
import DtpList from './DtpList';

const ModalForm = ({ isNew, isOpen, onClose, item = {}, loadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const history = useHistory();

    const types = useStoreProp(loadConcentrationAreaTypes, 'incidents', 'concentrationAreaTypes');
    const statuses = useStoreProp(loadConcentrationAreaStatuses, 'incidents', 'concentrationAreaStatuses');
    const showReturnTo = useSelector(menuSelectors.showReturnTo || {});
    const loading = useSelector(incidentsSelectors.loadingCedConcentrationArea);

    // конвертация статусов
    const statusesList = useMemo(() => {
        return Object.keys(statuses).map(id => ({ id: parseInt(id), label: statuses[id] }));
    }, [statuses]);

    const initState = {
        id: item?.id || '',
        name: item?.name || '',
        type: isNew ? 1 : item?.type,
        status: isNew ? 1 : item?.status,
        address: item?.address || {},
        address_text: item?.address_text || '',
        lat: item?.lat || '',
        lon: item?.lon || '',
        created_at: item?.created_at || new Date(),
        confirmed_at: item?.confirmed_at || new Date(),
        transport_incident_id: item?.transport_incident_id || null,
        description: item?.description || '',
        dtp_ids: item?.dtp_ids || [],
        dtp_list: item?.dtp_list || [],
        polygon: item?.polygon || null
    };

    const [data, setData] = useState(initState);
    const [openSelectDtpModal, setOpenSelectDtpModal] = useState(false);
    const [dependence, setDependence] = useState(true);
    const [currentStatus, setCurrentStatus] = useState(null);
    // const [showTiModal, setShowTiModal] = useState(false);

    // собираем объект статус
    useEffect(() => {
        if (
            data?.status
            && !currentStatus
            && Object.keys(statuses).length > 0
        ) {
            setCurrentStatus({
                id: data?.status,
                label: statuses[data?.status]
            });
        }
    }, [data, statuses, currentStatus]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const onChangeCoordinates = (params) => {
        setData({ ...data, ...params });
        validation.deleteKeys(Object.keys(params));
    };

    const prepareData = (newData = data) => removeEmptyFields({
        ...newData,
        created_at: fullDateTimeWithTimeZone(newData?.created_at),
        confirmed_at: fullDateTimeWithTimeZone(newData?.confirmed_at),
        dtp_ids: newData?.dtp_list?.map(({ id }) => id),
        status: currentStatus.id,
    });

    const handleSave = () => {
        const callback = () => {
            onClose();
            loadList();
        };

        isNew
            ? dispatch(createConcentrationArea(prepareData(), callback))
            : dispatch(editConcentrationArea(item.id, prepareData(), callback));
    };

    const goToTI = () => {
        dispatch(
            setShowReturnTo(
                history?.location?.pathname || '/map',
                'Вернуться в МКДТП',
                history?.location?.search || ''
            )
        );
        dispatch(setMKDTPModalData(data));
        history?.push({
            pathname: '/dictionaries/situational-plans/transport-incidents',
            search: `id=${data?.transport_incident_id}`
        });
    };

    const isDisabled = !data?.name || data?.dtp_list?.length === 0 || !data?.confirmed_at;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={
                isNew
                    ? 'Добавить место концентрации ДТП'
                    : 'Редактировать место концентрации ДТП'
            }
            noPadding
            maxWidthProp={'md'}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled,
                        loading: loading
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={6}>
                        <div className="block">
                            <TextField
                                value={data?.id || ''}
                                variant="outlined"
                                disabled
                                size="small"
                                label={'ID'}
                            />
                        </div>
                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('name') || (!data?.name && data?.name !== 0)}
                            helperText={validation.get('name')}
                        >
                            <TextField
                                value={data?.name || ''}
                                onChange={handleChange}
                                variant="outlined"
                                size="small"
                                label={titles.NAME}
                                required
                                name="name"
                                error={validation.isKey('name') || (!data?.name && data?.name !== 0)}
                            />
                        </FormInfoWrapper>
                        <div className="block">
                            <TextField
                                value={types[data?.type] || ''}
                                variant="outlined"
                                disabled
                                size="small"
                                label={'Вид'}
                            />
                        </div>

                        <FormInfoWrapper
                            error={validation.isKey('status')}
                            helperText={validation.get('status')}
                            className="block"
                        >
                            <Autocomplete
                                disabled={isNew}
                                value={currentStatus}
                                options={statusesList}
                                disableClearable={true}
                                filterSelectedOptions={true}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.label || ''}
                                noOptionsText={messages.NO_DATA}
                                size="small"
                                onChange={(e, newValue) => {
                                    validation.deleteKey('status');
                                    setCurrentStatus(newValue);
                                }}
                                clearText="Очистить"
                                openText="Открыть"
                                closeText="Закрыть"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        label={titles.STATUS}
                                    />
                                )}
                            />
                        </FormInfoWrapper>

                        <SingleKeyboardDateTimePicker
                            className="block"
                            value={data?.created_at}
                            onChange={(created_at) => setData({ ...data, created_at })}
                            label={titles.DATE_TIME_CREATED}
                            error={validation.isKey('created_at')}
                            helperText={validation.get('created_at')}
                            isDisabled
                        />
                        <SingleKeyboardDateTimePicker
                            className="block"
                            value={data?.confirmed_at}
                            onChange={(confirmed_at) => setData({ ...data, confirmed_at })}
                            label={titles.DATE_TIME_CONFIRMED}
                            error={!data?.confirmed_at || validation.isKey('confirmed_at')}
                            helperText={validation.get('confirmed_at')}
                            required
                        />
                        <div className="block">
                            <TextField
                                value={data?.transport_incident_id || ''}
                                variant="outlined"
                                disabled
                                size="small"
                                label={'Событие'}
                                name="transport_incident_id"
                                InputProps={{
                                    endAdornment: (
                                        <Tooltip title={'Перейти к событию'}>
                                            <IconButton
                                                disabled={!data?.transport_incident_id
                                                || !!showReturnTo?.link
                                                || isNew
                                                }
                                                size="small"
                                                onClick={goToTI}
                                            >
                                                <i className="fas fa-arrow-right"/>
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }}
                            />
                        </div>

                        {/* модалка по транспортному инциденту */}
                        {/*{showTiModal*/}
                        {/*    && (*/}
                        {/*        <TransportIncidentsInfoModal*/}
                        {/*            id={data?.transport_incident_id}*/}
                        {/*            show={showTiModal}*/}
                        {/*            onClose={() => setShowTiModal(false)}*/}
                        {/*        />*/}
                        {/*    )*/}
                        {/*}*/}

                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('description')}
                            helperText={validation.get('description')}
                        >
                            <TextField
                                value={data?.description || ''}
                                onChange={handleChange}
                                variant="outlined"
                                size="small"
                                label={titles.DESCRIPTION}
                                name="description"
                                error={validation.isKey('description')}
                            />
                        </FormInfoWrapper>
                        <div>
                            <h2>Список ДТП:</h2>
                            {data?.dtp_list?.length > 0
                                ? (
                                    <DtpList
                                        items={data?.dtp_list}
                                    />
                                )
                                : messages.NO_DATA
                            }
                            <div className="form__button">
                                <FormButtons
                                    noMarginLeft
                                    noPadding
                                    positionLeft
                                    buttons={[
                                        {
                                            ...(data.dtp_list?.length > 0 ? buttonsTypes.change : buttonsTypes.add),
                                            color: 'infoButton',
                                            onClick: () => setOpenSelectDtpModal(true),
                                            size: 'small',
                                            disabled: !data?.lat && !data?.lon
                                        },
                                    ]}
                                />
                            </div>
                            {/* модалка выбора дтп */}
                            {openSelectDtpModal && (
                                <SelectDtpModal
                                    isOpen={openSelectDtpModal}
                                    onClose={() => setOpenSelectDtpModal(false)}
                                    dtpList={data?.dtp_list}
                                    setDtpList={(dtp_list) => {
                                        setData(prev => ({ ...prev, dtp_list }));
                                        setOpenSelectDtpModal(false);
                                    }}
                                    latlng={[data?.lat, data?.lon]}
                                />
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            style={{ pointerEvents: 'none' }}
                            control={
                                <Checkbox
                                    checked={dependence}
                                    onChange={(e) => setDependence(e.target.checked)}
                                    color="primary"
                                    style={{ pointerEvents: 'auto' }}
                                />
                            }
                            label="Адрес зависит от координат"
                        />
                        <FormControl className="block" variant="outlined">
                            <LatLonCoordinates
                                {...data}
                                onChange={onChangeCoordinates}
                                required
                            />
                        </FormControl>
                        <FormControl className="block" variant="outlined">
                            <LoadAddressByCoords
                                {...data}
                                onChange={onChangeCoordinates}
                                isLoadOnLatLon={dependence}
                                isLatLonColumnView={true} // такого поля нет
                            >
                                <AddressList required />
                            </LoadAddressByCoords>
                        </FormControl>
                        <FormControl className="block" variant="outlined">
                            <MapDragMarker
                                {...data}
                                onChange={onChangeCoordinates}
                                required
                                permanent={false}
                                innerComponent={(props) => {
                                    return [
                                        ...data?.dtp_list.map(item => {
                                            return (
                                                <Marker
                                                    {...props}
                                                    key={item.id}
                                                    icon={createIconMarker('#4b9c4b')}
                                                    latlng={[item.lat, item.lon]}
                                                    attribution={{ color: '#4b9c4b' }}
                                                >
                                                    <ToolTip
                                                        offset={[0, -30]}
                                                        direction="top"
                                                    >
                                                        <div><strong>ID: </strong>{item.id || ''}</div>
                                                        <div><strong>{titles.DTP_TYPE}: </strong>{item.dtp_type_name || ''}</div>
                                                        <div><strong>Дата/время: </strong>{fullDateTimeWithoutSeconds(item.dtp_at) || ''}</div>
                                                    </ToolTip>
                                                </Marker>
                                            );
                                        }), 
                                        data?.polygon && <GeoJsonNew
                                            key={data?.id}
                                            {...props}
                                            data={ data?.polygon }
                                        />
                                    ];
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </Modal>
    );
};

export default ModalForm;
