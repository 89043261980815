import instance from '../../helpers/axios.config';

import apiUrls from './api.urls';

const ApiEcologyMethods = {
    getProjects: async () => {
        const response = await instance.get(apiUrls.getProjects());
        return response?.data;
    },

    getDeviceTypes: async () => {
        const response = await instance.get(apiUrls.getDeviceTypes());
        return response?.data;
    },

    getDeviceList: async (params) => {
        const response = await instance.get(apiUrls.getDeviceList(), {
            params: {
                ...params,
                // type_device: 1
            }
        });
        return response?.data;
    },

    getDeviceCompanyLink: async (id) => {
        const response = await instance.get(apiUrls.getDeviceCompanyLink(id));
        return response.data;
    },

    createDeviceCompanyLink: async (id, sendData) => {
        const response = await instance.post(apiUrls.postDeviceCompanyLink(id),
            sendData
            // { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } }
        );
        return response.data;
    },

    deleteDeviceCompanyLink: async (id) => {
        const response = await instance.delete(apiUrls.deleteDeviceCompanyLink(id));
        return response.data;
    },

    getForPolygonDevices: async (polygon) => {
        const response = await instance.post(
            apiUrls.getForPolygonDevices(),
            {
                type_device: 1,
                polygon
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
        return response?.data?.data;
    },

    getDeviceData: async (device_external_id) => {
        const response = await instance.post(apiUrls.getDeviceData(), {
            ids: [device_external_id],
            // type_device: 1
        });
        return response?.data?.data;
    },

    getEvents: async (external_id, page, limit) => {
        const response = await instance.get(apiUrls.getEvents(external_id, page, limit), {
            params: {
                page,
                limit,
                external_id,
                // type_device: 1
            }
        });
        return response?.data;
    },

    getStatuses: async () => {
        const response = await instance.get(apiUrls.getStatuses());
        return response?.data;
    },

    getEcologySettings: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getEcologySettings(), {
            params: {
                page,
                limit,
                ...query
            }
        });
        return response?.data;
    },

    editEcologySettings: async (id, data) => {
        const response = await instance.put(apiUrls.editEcologySettings(id), data);
        return response?.data;
    },

    editSettingsList: async (data) => {
        const response = await instance.put(apiUrls.editSettingsList(), data);
        return response?.data;
    },

    getAlerts: async () => {
        const response = await instance.get(apiUrls.getAlerts());
        return response?.data;
    },

    getEcologyOutputParams: async () => {
        const response = await instance.get(apiUrls.getEcologyOutputParams());
        return response?.data;
    },

    getEcologyOutputSettings: async () => {
        const response = await instance.get(apiUrls.getEcologyOutputSettings());
        return response?.data;
    },

    editEcologyOutputSettings: async (id, data) => {
        const response = await instance.put(apiUrls.editEcologyOutputSettings(id), data);
        return response?.data;
    },

    deleteEcologyOutputSettings: async (id, data) => {
        const response = await instance.delete(apiUrls.deleteEcologyOutputSettings(id), { data });
        return response?.data;
    },

    getDevicesGroup: async (data) => {
        const response = await instance.get(apiUrls.getDevicesGroup(), {
            params: { data }
        });
        return response?.data;
    },

    createDevicesGroup: async (data) => {
        const response = await instance.post(apiUrls.createDevicesGroup(), data);
        return response?.data;
    },

    editDevicesGroup: async (id, data) => {
        const response = await instance.put(apiUrls.editDevicesGroup(id), data);
        return response?.data;
    },

    deleteDevicesGroup: async (id) => {
        const response = await instance.delete(apiUrls.deleteDevicesGroup(id));
        return response?.data;
    },

    loadShowcase: async () => {
        const response = await instance.post(apiUrls.loadShowcase());
        return response?.data;
    },

    loadEcoByRadius: async (params) => {
        const response = await instance.post(apiUrls.loadEcoByRadius(), params);
        return response?.data;
    },

    getSettingsGroup: async () => {
        const response = await instance.get(apiUrls.getSettingsGroup());
        return response?.data;
    },

    editSettingsGroup: async (id, data) => {
        const response = await instance.put(apiUrls.editSettingsGroup(id), data);
        return response?.data;
    },

    getDeviceStatuses: async () => {
        const response = await instance.get(apiUrls.getDeviceStatuses());
        return response?.data;
    },
};

export default ApiEcologyMethods;
