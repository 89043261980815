import { useEffect, useState } from 'react';
import { PlayArrow } from '@mui/icons-material';
import cn from 'classnames';

import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import FormButtons from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import CommonImage from 'components/common/Image';

import styles from './checkListModal.module.scss';

const MediaContent = ({ setaAtiveCollage, collage, allMedia }) => {
    const openBase64 = () => {
        var win = window.open();
        win.document.write('<iframe src="' + collage?.src  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    };
    const [activeItem, setActiveItem] = useState(allMedia[0]);

    const openNewWindow = () => window.open(activeItem?.src);
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (allMedia.length > 0) {
            setActiveItem(allMedia[0]);
            allMedia.map((el) => {
                const img = new Image();
                img.onload = function() {
                    el.width = img.width;
                };
                img.src = el.src;
                return el;
            });
        }
    }, [allMedia]);

    useEffect(() => {
        setActiveItem(collage);
    }, [collage]);

    return (
        <>
            <div className={styles.sidebar}>
                <h2 className={styles.sidebar__header}>
                    Фото/видео материалы
                </h2>
                <div className={styles.sidebar__content}>
                    {allMedia.length > 0
                        ? allMedia.map((item, i) => (
                            <div
                                className={cn(styles.mediaBlock, { [styles.active]: activeItemIndex === i })}
                                key={i}
                                onClick={() => {
                                    setLoading(true);
                                    setTimeout(() => {
                                        setaAtiveCollage(null);
                                        setActiveItemIndex(i);
                                        setActiveItem(item);
                                        setLoading(false);
                                    }, 500);
                                }}
                            >
                                {item.type === 'image'
                                    ? <CommonImage
                                        className={styles.media}
                                        src={item.src}
                                    />
                                    : item.type === 'video'
                                        ?<video
                                            src={item.src}
                                            className={styles.media}
                                        />
                                        : null
                                }
                                {item.type === 'video'
                                    && <div className={styles.playArrow}>
                                        <PlayArrow fontSize="large"/>
                                    </div>
                                }
                            </div>
                        ))
                        : <div>{messages.NO_MATERIALS}</div>
                    }
                </div>
            </div>
            <div className={styles.mainContentBlock}>
                {!activeItem && !collage
                    ? <div>{messages.NO_DATA}</div>
                    : loading
                        ? <Loading />
                        : activeItem?.type === 'image'
                            ?  <>
                                <CommonImage
                                    src={activeItem?.src}
                                    style={{ maxWidth: activeItem.width, cursor: 'pointer' }}
                                    onClick={collage ? openBase64 : openNewWindow}
                                />
                                {(collage?.src || activeItem?.src)
                                        && <FormButtons
                                            className={styles.openButton}
                                            noPadding
                                            justButton
                                            buttons={[
                                                {
                                                    onClick: collage ? openBase64 : openNewWindow,
                                                    name: buttons.OPEN_IN_NEW_WINDOW,
                                                    variant: 'contained',
                                                    color: 'infoButton',
                                                    type: 'button',
                                                }
                                            ]}
                                        />
                                }
                            </>
                            : activeItem?.type === 'video'
                                ? <video
                                    controls
                                    src={activeItem?.src}
                                />
                                : null
                }
            </div>

        </>
    );
};

export default MediaContent;
