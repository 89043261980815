// иконка для карты
const iconMap = ({ fill = '#000', innerFill = '#fff' }) => {
    return `
        <svg viewBox="0 0 107.000000 130.000000" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
         <g>
          <g id="svg_eo_3">
           <rect fill="none" id="canvas_background" height="132" width="109" y="-1" x="-1"/>
          </g>
          <g id="svg_eo_4">
           <g fill="${fill}" id="svg_eo_1" transform="translate(0,130) scale(0.10000000149011612,-0.10000000149011612)">
            <path id="svg_eo_2" d="m445,1286c-100,-19 -206,-75 -281,-150c-104,-104 -154,-228 -154,-381c0,-144 38,-245 149,-392c64,-85 297,-316 348,-345c30,-17 31,-17 71,12c68,49 237,216 302,299c157,200 210,350 181,517c-25,142 -121,287 -241,364c-108,70 -251,98 -375,76z"/>
           </g>
          </g>
          <g fill="${innerFill}" stroke="null" id="svg_eo_9">
           <path stroke="null" id="svg_eo_8" d="m46.360361,63.86394l-1.177415,6.839029c-0.212937,1.240044 1.089735,2.154419 2.179471,1.578237l6.125064,-3.231629l6.125064,3.231629c1.089735,0.576182 2.392407,-0.350719 2.179471,-1.578237l-1.177415,-6.839029l4.960175,-4.834918c0.889324,-0.864273 0.400822,-2.379882 -0.826696,-2.567767l-6.851554,-1.002055l-3.068795,-6.212744c-0.551131,-1.102261 -2.141894,-1.127312 -2.693024,0l-3.068795,6.212744l-6.851554,1.002055c-1.227518,0.17536 -1.71602,1.690969 -0.826696,2.567767l4.9727,4.834918zm29.184865,-33.493704l-6.012333,0l0,-6.012333c0,-1.102261 -0.90185,-2.004111 -2.004111,-2.004111l-4.008222,0c-1.102261,0 -2.004111,0.90185 -2.004111,2.004111l0,6.012333l-16.032887,0l0,-6.012333c0,-1.102261 -0.90185,-2.004111 -2.004111,-2.004111l-4.008222,0c-1.102261,0 -2.004111,0.90185 -2.004111,2.004111l0,6.012333l-6.012333,0c-3.319309,0 -6.012333,2.693024 -6.012333,6.012333l0,44.09044c0,3.319309 2.693024,6.012333 6.012333,6.012333l44.09044,0c3.319309,0 6.012333,-2.693024 6.012333,-6.012333l0,-44.09044c0,-3.319309 -2.693024,-6.012333 -6.012333,-6.012333zm0,49.351232c0,0.413348 -0.338194,0.751542 -0.751542,0.751542l-42.587357,0c-0.413348,0 -0.751542,-0.338194 -0.751542,-0.751542l0,-37.326566l44.09044,0l0,37.326566z"/>           
           </g>
         </g>
        </svg>
    `;
};

export default iconMap;
