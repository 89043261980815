// import DateRange from '../../../../../common/Dates/DateRange';
import titles from '../../../../../../helpers/constants/titles';
import React, { useEffect, useState } from 'react';
import Modal from '../../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import {
    IconButton,
    Tooltip,
} from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import formatDate from '../../../../../../helpers/constants/dateFormat';
import ClearIcon from '@mui/icons-material/Clear';
import SingleKeyboardDateTimePicker from '../../../../../common/Dates/SingleKeyboardDateTimePicker';
import './style.scss';
import { fullDateTime } from '../../../../../../helpers/date.config';


const DateFilter = (props) => {
    const {
        date = null,
        onChange,
    } = props;

    const [show, setShow] = useState(false);
    const [changeDate, setChangeDate] = useState(null);

    const handleClose = () => {
        setShow(false);
    };
    const handleApply = () => {
        setShow(false);

        onChange(changeDate);
    };

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShow(true);
    };

    useEffect(() => {
        if (date !== changeDate) {
            setChangeDate(date);
        }
    }, [date]);

    const handleClear = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setChangeDate(null);
        onChange(null);
    };

    return (
        <>
            <div style={{ fontWeight: 'bold', marginTop: '.5rem', color: 'rgb(177 134 30)' }} onClick={handleClick} >
                {/*{titles.SELECT_PERIOD}*/}
                История местоположения
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginTop: '.5rem' }}>

                <Tooltip title={'Выбрать дату и время'}>
                    <IconButton
                        size="small"
                        onClick={handleClick}
                    >
                        <DateRangeIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>

                {date && (
                    <Tooltip title={titles.CLEAR}>
                        <IconButton
                            size="small"
                            onClick={handleClear}
                        >
                            <ClearIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                )}

                {date ? (
                    <div style={{ marginLeft: '10px' }} onClick={handleClick}>
                        {fullDateTime(date)}
                    </div>
                ) : (
                    <div style={{ marginLeft: '10px' }} onClick={handleClick} >
                        {'Выбрать дату и время'}
                    </div>
                )}

            </div>

            <Modal
                // noPadding
                title={'Выбрать дату и время'}
                maxWidthProp={'xs'}
                small
                isOpen={show}
                onClose={handleClose}
                buttons={
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.apply,
                                onClick: handleApply,
                                name: 'Показать',
                                disabled: !changeDate || date === changeDate
                            },
                            {
                                ...buttonsTypes.close,
                                onClick: handleClose,
                            },
                        ]}
                    />
                }
            >
                <div className="dt-picker">
                    <SingleKeyboardDateTimePicker
                        label={'Дата и время'}
                        // label={titles.END_PERIOD}
                        onChange={(newValue) => setChangeDate(newValue)}
                        value={changeDate}
                        // error={checkError(valueEndDate)}
                        // pickerFormat={formatDate.DATE_FOR_PICKER}
                        pickerFormat={formatDate.DT_EXCEPT_SECONDS}
                    />
                </div>
            </Modal>
        </>
    );
};

export default DateFilter;



