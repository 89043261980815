import React, { memo } from 'react';
import FormButtonsComponent, { buttonsTypes } from '../../../../../../common/FormButtons';
import RenderSign from '../../RenderSign';
import RenderCondition from '../../../AlertTemplates/AddEdit/RenderCondition';

export const Signs = memo((props) => {
    const { onChange, data,  } = props;

    const addNewSign = () => {
        onChange([
            ...data,
            {
                type: 'sign',
                'align-x': 'left',
                'align-y': 'center',
                x: 0,
                y: 0,
                sign: null,
                size: '90'
            }
        ]);
    };

    const onChangeSign = (i, el) => {
        const newData = [
            ...data.slice(0, i),
            el,
            ...data.slice(i + 1)
        ];
        onChange(newData);
    };

    const onDeleteSign = (i) => {
        const newData = [
            ...data.slice(0, i),
            ...data.slice(i + 1)
        ];
        onChange(newData);
    };

    return (
        <>
            <h2>Знаки:</h2>
            {data.map((el, index) => (
                <div
                    key={index}
                    style={{
                        backgroundColor: index % 2 === 0 ? 'rgba(155,157,176,0.2)' : 'inherit',
                        padding: '0.5rem'
                    }}
                >
                    <RenderSign
                        key={index}
                        item={el}
                        onChange={(item) => onChangeSign(index, item)}
                        onDelete={() => onDeleteSign(index)}
                    />
                    <RenderCondition
                        textData={el}
                        data={el?.condition || []}
                        onChange={(val) => onChangeSign(index, val)}
                    />
                </div>
            ))}

            <div style={{ marginTop: 10 }}>
                <FormButtonsComponent
                    noPadding
                    positionLeft
                    noMarginLeft
                    buttons={[
                        {
                            ...buttonsTypes.defaultInfo,
                            onClick: addNewSign,
                            name: 'Добавить знак',
                        }
                    ]}
                />
            </div>
        </>
    );
});
