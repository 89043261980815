import {
    TextField,
    InputAdornment,
    IconButton,
    Autocomplete,
} from '@mui/material';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { getHumanDate, getDateObjectFromFormat } from 'helpers/date.config';
import DateRange from 'components/common/Dates/DateRange';
import SelectCompany from 'components/common/Autocomplete/Companies';
import DefectGroups from 'components/common/Autocomplete/RoadWorks/DefectGroups';
import DefectTypes from 'components/common/Autocomplete/RoadWorks/DefectTypes';


const Form = ({ data, onChange, statuses }) => {
    const getValue = (key) => data?.[key] || '';

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({
            [name]: value,
        });
    };

    const handleChangeGroup = (value) => {
        // id выбранных груп
        const groupIds = value.map(({ id }) => id) || [];
        // фильтруем типы от груп
        const newTypes = data
            ?.type_id_list
            ?.reduce((res, item) => groupIds.includes(item.group_id) ? [...res, item] : res, []);

        onChange({
            group_id_list: value,
            type_id_list: newTypes,
        });
    };

    const handleChangeType = (value) => {
        onChange({
            type_id_list: value,
        });
    };

    const toDate = (data) => data ? getDateObjectFromFormat(data, 'dd.MM.yyyy') : data;

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="block">
                <TextField
                    label="Название"
                    value={data?.name || ''}
                    onChange={handleChange}
                    //helperText="Some important text"
                    name="name"
                    fullWidth
                    className="row__item"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {getValue('name').length > 0 ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => onChange({ name: '' })}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>x</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />
            </div>

            <div className="block">
                <Autocomplete
                    value={data.status || []}
                    options={statuses?.map(({ status_id, name }) => ({ id: status_id, name }))}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name || ''}
                    noOptionsText={messages.NO_DATA}
                    size="small"
                    multiple
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    onChange={(e, newValues) => onChange({
                        status: newValues
                    })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Статус"
                        />
                    )}
                />
            </div>

            <div className="block">
                <TextField
                    value={data?.description || ''}
                    onChange={handleChange}
                    label={titles.DESCRIPTION}
                    variant="outlined"
                    size="small"
                    name="description"
                    type="text"
                />
            </div>

            <div className="block">
                <TextField
                    value={data?.address_text || ''}
                    onChange={handleChange}
                    label={titles.ADDRESS}
                    variant="outlined"
                    size="small"
                    name="address_text"
                    type="text"
                />
            </div>

            <div className="row">
                <DateRange
                    dateOnly={true}
                    valueStartDate={toDate(data?.registration_at_start)}
                    valueEndDate={toDate(data?.registration_at_end)}
                    handleDate={(value) => onChange({ registration_at_start: value ? getHumanDate(value) : value })}
                    handleEndDate={(value) => onChange({ registration_at_end: value ? getHumanDate(value) : value })}
                    startLabel={'Дата регистрации от:'}
                    endLabel={'Дата регистрации до:'}
                />
            </div>

            <div className="row">
                <DateRange
                    dateOnly={true}
                    valueStartDate={toDate(data.liquidated_at_start)}
                    valueEndDate={toDate(data.liquidated_at_end)}
                    handleDate={(value) => onChange({ liquidated_at_start: value ? getHumanDate(value) : value })}
                    handleEndDate={(value) => onChange({ liquidated_at_end: value ? getHumanDate(value) : value })}
                    startLabel={'Фактическая дата устранения от:'}
                    endLabel={'Фактическая дата устранения до:'}
                />
            </div>

            <div className="block">
                <SelectCompany
                    multiple
                    selectDeletedСompanies
                    filter={{ withDeleted: 1 }}
                    selected={data?.organization_id_list || []}
                    onChange={(value) => onChange({ organization_id_list: value?.map((item) => ({
                        ...item,
                        name: item.title
                    }))
                    })}
                    label={titles.ORGANIZATION}
                />
            </div>

            <div className="block">
                <DefectGroups
                    label="Группа недостатков"
                    selected={data?.group_id_list || []}
                    multiple
                    onChange={handleChangeGroup}
                />
            </div>

            <div className="block">
                <DefectTypes
                    selected={data?.type_id_list || []}
                    multiple
                    onChange={handleChangeType}
                    filter={{ group_ids: data?.group_id_list?.map(({ id }) => id) || [] }}
                />
            </div>

            {/*<div className="block">*/}
            {/*    <ACGroups*/}
            {/*        value={data?.group_id_list || []}*/}
            {/*        withSearch={false}*/}
            {/*        multiple={true}*/}
            {/*        onChange={handleChangeGroup}*/}
            {/*    />*/}
            {/*</div>*/}

            {/*<div className="block">*/}
            {/*    <ACTypes*/}
            {/*        value={data?.type_id_list || []}*/}
            {/*        withSearch={false}*/}
            {/*        multiple={true}*/}
            {/*        onChange={handleChangeType}*/}
            {/*        group_ids={data?.group_id_list?.map(({ id }) => id) || []}*/}
            {/*    />*/}
            {/*</div>*/}

        </form>
    );
};

export default Form;
