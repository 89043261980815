import React, { useState } from 'react';
import cn from 'classnames';
import FormButtons, { buttonsTypes } from '../../../../../../common/FormButtons';
import SelectComplex from '../../../../../../common/Autocomplete/DorisControl/Complex';
import SelectFiles from './SelectFiles';

interface InitState {
    complex_ids: { id: number }[];
    file_ids: { id: number; file_name: string }[];
}

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{ page: number; data: {} }>>;
}

const Filter = ({ setParams }: FilterProps) => {
    const initState: InitState = { complex_ids: [], file_ids: [] };

    const [isFiltered, setIsFiltered] = useState(false);
    const [filterData, setFilterData] = useState(initState);

    const onChange = (name: string, value: any) => {
        setFilterData({ ...filterData, [name]: value });
    };

    const onSearch = () => {
        const prepareData = {
            complex_ids: filterData.complex_ids.map((item) => item.id),
            file_ids: filterData.file_ids.map((item) => item.id),
        };

        setParams({ page: 1, data: prepareData });
        setIsFiltered(true);
    };

    const onReset = () => {
        setFilterData(initState);

        if (isFiltered) {
            setParams({ page: 1, data: {} });
            setIsFiltered(false);
        }
    };

    const isDisabled = filterData.complex_ids.length && filterData.file_ids.length;

    return (
        <div className="filter">
            <div className={cn('filter__wrap', 'filter__wrap__more__field')}>
                <SelectComplex
                    multiple
                    selected={filterData.complex_ids}
                    onChange={(value) => onChange('complex_ids', value)}
                    searchTitle={undefined}
                    sortedList={undefined}
                />
                <SelectFiles
                    onChange={(value: any) => onChange('file_ids', value)}
                    selected={filterData.file_ids}
                />
            </div>
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: onReset,
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: onSearch,
                            disabled: isDisabled,
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;
