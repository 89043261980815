import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import { createBoard, editBoard, loadCategories } from 'redux/Boards/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SelectBoardFonts from 'components/common/Autocomplete/Boards/Fonts';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const AddEditModal = ({
    isNew = false,
    onClose = () => {},
    el= {},
    isOpen = false,
    statuses = [],
    types,
    reloadList = () => {},
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const boadrCategories = useStoreProp(loadCategories, 'boards', 'boadrCategories');

    const initialState = {
        name: el?.name || '',
        geometry: el?.geometry || {},
        type: el?.type || '',
        ip: el?.ip || '',
        port: el?.port || '',
        serial_number: el?.serial_number || '',
        address_text: el?.address_text || '',
        address: el?.address || {},
        status: el?.status || '',
        lat: el?.lat || '',
        lon: el?.lon || '',
        height: el?.height || '',
        width: el?.width || '',
        service_entities: el?.service_entities || [],
        fonts: el?.fonts || [],
        speed_limit: el?.speed_limit || 60,
        category_id: el?.category_id || ''
    };
    const [data, setData] = useState(initialState);

    const onChange = (e) => {
        const { name, value, type } = e.target;
        if (type === 'number') {
            const parsedValue = name === 'speed_limit' ? Number.parseFloat(value).toFixed(0) : value;
            setData({
                ...data,
                [name]: value < 0 ? 0 : isNaN(parsedValue) ? '' : parsedValue
            });
        } else {
            setData({
                ...data,
                [name]: value
            });
        }
        validation.deleteKey(name);
    };

    const onSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        isNew
            ? dispatch(createBoard(data, callback))
            : dispatch(editBoard(el.id, data, callback));
    };

    const handleChangeGeometry = (params) => {
        const {
            lat,
            lon,
            address,
            address_text
        } = params;

        const newData = {
            ...data,
        };

        // пришел адрес
        if (address) {
            newData.address = address;
            newData.address_text = address_text;

            validation.deleteKey('address');
            validation.deleteKey('address_text');
        }

        // пришли координаты
        if (lat || lon) {
            // обновление координат
            const gLat = lat || data?.lat;
            const gLon = lon || data?.lon;

            // в lat lon и geometry одинаковые координаты
            newData.lat = gLat;
            newData.lon = gLon;

            newData.geometry = {
                type: 'Point',
                coordinates: [gLon, gLat]
            };
        }

        // заменяем данные
        setData(newData);

        validation.deleteKey('geometry');
    };

    //Добавление шрифтов
    const onChangeFonts = (value) => {
        setData({
            ...data,
            fonts: value
        });
    };

    const isSpeedLimitValid = data.speed_limit && data.speed_limit % 10 === 0;

    const isDisabled = !data.name
        || !data.type
        || !isSpeedLimitValid
        || !data.serial_number
        || !data.address_text
        || !data.status
        || data.fonts.length === 0
        || Object.keys(data.geometry).length === 0;

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            noPadding
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isDisabled,
                        onClick: onSave
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormInfoWrapper className="block" error={!data.name || !!validation.isKey('name')} helperText={validation.get('name')}>
                    <TextField
                        required
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={data.name}
                        name="name"
                        onChange={onChange}
                        type="text"
                        error={!data.name || !!validation.isKey('name')}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper className="block" error={!data.status || !!validation.isKey('status')} helperText={validation.get('status')}>
                    <FormControl size="small" variant="outlined">
                        <InputLabel error={!data.status || !!validation.isKey('status')}>{titles.STATUS}</InputLabel>
                        <Select
                            value={data?.status || ''}
                            required
                            onChange={onChange}
                            label={titles.STATUS}
                            name="status"
                            error={!data.status || !!validation.isKey('status')}
                        >
                            {statuses?.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>{name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormInfoWrapper>
                <FormInfoWrapper className="block" error={!data.category_id || !!validation.isKey('category_id')} >
                    <FormControl size="small" variant="outlined">
                        <InputLabel error={!data.category_id || !!validation.isKey('category_id')}>{titles.CATEGORY}</InputLabel>
                        <Select
                            required
                            value={data.category_id}
                            onChange={onChange}
                            label={titles.CATEGORY}
                            name="category_id"
                            error={!data.category_id || !!validation.isKey('category_id')}
                        >
                            {Object.keys(boadrCategories)?.map((key) => (
                                <MenuItem key={key} value={key}>{boadrCategories[key]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormInfoWrapper>
                <FormInfoWrapper className="block" error={!data.type || !!validation.isKey('type')} >
                    <FormControl size="small" variant="outlined">
                        <InputLabel error={!data.type || !!validation.isKey('type')}>Тип</InputLabel>
                        <Select
                            required
                            value={data.type}
                            onChange={onChange}
                            label="Тип"
                            name="type"
                            error={!data.type || !!validation.isKey('type')}
                        >
                            {Object.keys(types)?.map((key) => (
                                <MenuItem key={key} value={key}>{types[key]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormInfoWrapper>
                <div className="block">
                    <SelectBoardFonts
                        multiple
                        selected={data.fonts}
                        filter={{ board_type: data.type }}
                        onChange={onChangeFonts}
                        required
                        error={!data.fonts.length}
                        disabled={!data.type}
                        helperText={!data.type && 'Выберите тип'}
                    />
                </div>
                <FormInfoWrapper
                    className="block"
                    error={!data.speed_limit || !!validation.isKey('speed_limit')}
                    helperText={validation.get('speed_limit')}
                >
                    <TextField
                        required
                        label={titles.SPEED_LIMIT}
                        variant="outlined"
                        size="small"
                        value={data.speed_limit}
                        name="speed_limit"
                        onChange={onChange}
                        type="number"
                        inputProps={{ step: 10 }}
                        className="block"
                        error={!data.speed_limit || !!validation.isKey('speed_limit')}
                    />
                </FormInfoWrapper>
                <div className="row">
                    <FormInfoWrapper
                        className="row__item"
                        error={!data.width || validation.isKey('width')}
                        helperText={validation.get('width')}
                    >
                        <TextField
                            className="row__item"
                            label="Ширина"
                            size="small"
                            value={data.width}
                            variant="outlined"
                            name="width"
                            type="number"
                            onChange={onChange}
                            required={true}
                            error={!data.width || validation.isKey('width')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="row__item"
                        error={!data.height || validation.isKey('height')}
                        helperText={validation.get('height')}
                    >
                        <TextField
                            className="row__item"
                            label="Высота"
                            size="small"
                            value={data.height}
                            variant="outlined"
                            name="height"
                            type="number"
                            onChange={onChange}
                            required={true}
                            error={!data.height || validation.isKey('height')}
                            helperText={validation.get('height')}
                        />
                    </FormInfoWrapper>
                </div>
                <FormInfoWrapper className="block">
                    <TextField
                        label={titles.IP_ADDRESS}
                        variant="outlined"
                        size="small"
                        value={data.ip}
                        name="ip"
                        onChange={onChange}
                        type="text"
                    />
                </FormInfoWrapper>
                <FormInfoWrapper className="block">
                    <TextField
                        label="Порт"
                        variant="outlined"
                        size="small"
                        value={data.port}
                        name="port"
                        onChange={onChange}
                        type="text"
                    />
                </FormInfoWrapper>
                <FormInfoWrapper
                    className="block"
                    error={!data.serial_number || !!validation.isKey('serial_number')}
                    helperText={validation.get('serial_number')}
                >
                    <TextField
                        required
                        label="Серийный номер"
                        variant="outlined"
                        size="small"
                        value={data.serial_number}
                        name="serial_number"
                        onChange={onChange}
                        type="text"
                        error={!data.serial_number || !!validation.isKey('serial_number')}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper className="block">
                    <LatLonCoordinates
                        {...data}
                        onChange={handleChangeGeometry}
                        required
                    />
                </FormInfoWrapper>

                <FormInfoWrapper className="block">
                    <LoadAddressByCoords
                        {...data}
                        onChange={handleChangeGeometry}
                    >
                        <AddressList required />
                    </LoadAddressByCoords>
                </FormInfoWrapper>

                <FormInfoWrapper className="block">
                    <MapDragMarker
                        {...data}
                        onChange={handleChangeGeometry}
                        required
                    />
                </FormInfoWrapper>

            </div>
        </Modal>
    );
};

export default AddEditModal;
