import iconCluster from './icons/iconCluster';
// import { clearFilters } from '../../../../redux/SituationalPlans/actions';
const config = {
    name: 'События',
    slug: 'transport-incidents',
    mapContextMenu: {
        title: 'Добавить событие',
        event: 'add_transport_incidents',
        // className: styles.button,
        minWidth: 210,
    },
    mapMarkersColors: { // от статуса
        default: '#670926',
    },
    clusterIcon: iconCluster,
    // onAfterActivateAction: () => clearFilters(),
    layerIcon: 'far fa-car-bump',
};
// добавление на карте
export default config;
