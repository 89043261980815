import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteCamera } from 'redux/VideoAnalytics/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import useCompany from 'helpers/hooks/useCompany';
import colorExtend from 'helpers/mapHelper/colorExtend';
import ConfirmDelete from 'components/common/ConfirmModal';
import MapForm from 'components/MapComponents/MapForm';
import BoardsListModal from 'components/common/Boards/BoardsListModal';
import {
    ActionsButtons,
    LSContentColumn,
    LSContentItem,
} from 'components/common/List';
import FrameModal from 'components/common/FrameVideoModal/FrameModal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import CircleStatus from 'components/common/CircleStatus';

import ModalForm from './ModalForm';

const Item = ({ item, reloadList, statusesOb = {} }) => {
    const dispatch = useDispatch();

    const [isOpenMap, setIsOpenMap] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isOpenBoardsModal, setIsOpenBoardsModal] = useState(false);
    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [openFrameModal, setOpenFrameModal] = useState(false);

    const company = useCompany(item?.organization_id);

    const handleOpenBoardsModal = (e) => {
        e.stopPropagation();
        setIsOpenBoardsModal(true);
    };

    const handleOpenDeleteModal = (e) => {
        e.stopPropagation();
        setIsOpenDeleteModal(true);
    };

    const handleOpenEditModal = (e) => {
        e.stopPropagation();
        setIsOpenEditModal(true);
    };

    const handleOpenMap = (e) => {
        e.stopPropagation();
        setIsOpenMap(true);
    };

    const handleDelete = () => {
        dispatch(deleteCamera(item.id, () => {
            reloadList(true);
            setIsOpenDeleteModal(false);
        }));
    };

    const handleOpenCameraModal = (e) => {
        e.stopPropagation();
        setOpenFrameModal(true);
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    <CircleStatus
                        title={item?.status_text}
                        color={colorExtend(item?.status_color || statusesOb?.get?.(item.status)?.color)}
                    />
                </LSContentColumn>

                <LSContentColumn>{item.name || messages.NO_VALUE}</LSContentColumn>

                <LSContentColumn>
                    {item?.lat || item?.lon
                        ? `${item.lat || messages.NO_VALUE}/${item.lon || messages.NO_VALUE}`
                        : messages.NO_VALUE
                    }
                </LSContentColumn>

                <LSContentColumn>{item.ip_route
                    ? <a href={`https://${item.ip_route}`} target="_blank" rel="noopener noreferrer">
                        {item.ip_route}
                    </a>
                    : messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>{company?.title || messages.NO_VALUE}</LSContentColumn>

                <LSContentColumn isActions>
                    <FormButtons
                        justButton
                        buttons={[
                            {
                                name: 'Просмотр видео',
                                icon: <i className="far fa-play-circle"/>,
                                onClick: handleOpenCameraModal,
                                disabled: !item.url_player,
                            }
                        ]}
                    />

                    <ActionsButtons
                        buttons={[
                            {
                                icon: (
                                    <i
                                        className={
                                            item.is_link_dit
                                                ? 'fas fa-border-inner'
                                                : 'fas fa-border-none'
                                        }
                                        style={{ color: item.is_link_dit ? '#2e7d32' : '' }}
                                    />
                                ),
                                name: titles.BOARD_LINK,
                                color: item.is_link_dit ? 'success' : 'default',
                                onClick: handleOpenBoardsModal,
                            },
                            {
                                icon: <i className="fas fa-map-marked-alt" />,
                                name: titles.SHOW_ON_MAP,
                                onClick: handleOpenMap,
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: handleOpenEditModal,
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: handleOpenDeleteModal,
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isOpenBoardsModal && (
                <BoardsListModal
                    isOpen={isOpenBoardsModal}
                    id={item.id}
                    name={item.name}
                    entity_name={item.entity_name}
                    microservice="videoAnalytics"
                    onClose={() => setIsOpenBoardsModal(false)}
                />
            )}

            {isOpenMap && (
                <MapForm
                    readOnly={true}
                    isOpen={isOpenMap}
                    onClose={() => setIsOpenMap(false)}
                    latlon={[item.lat, item.lon]}
                    title={titles.CAMERA_LOCATION_ON_MAP}
                />
            )}

            {isOpenDeleteModal && (
                <ConfirmDelete
                    open={isOpenDeleteModal}
                    message={messages.CONFIRM_DELETE}
                    onClose={() => setIsOpenDeleteModal(false)}
                    onSuccess={handleDelete}
                />
            )}

            {isOpenEditModal && (
                <ModalForm
                    isOpen={isOpenEditModal}
                    onClose={() => setIsOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}

            {openFrameModal && (
                <FrameModal
                    isOpen={openFrameModal}
                    onClose={() => setOpenFrameModal(false)}
                    link={item.url_player}
                    title={item.name}
                />
            )}
        </>
    );
};

export default Item;
