import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStoreProp, useValidation } from '../../../../../../helpers/hooks';
import titles from '../../../../../../helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from '../../../../../common/FormButtons';
import Modal from '../../../../../common/Modal';
import {
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import {
    createAlertTemplate,
    editAlertTemplate,
    loadMessagePriorities,
    loadAlertTemplateTypes,
    loadAlertTemplateFileds,
} from '../../../../../../redux/Boards/actions';
import PreviewMessage from '../../MessageHelpers/PreviewMessage';
import RenderSigns from './RenderSigns';
import removeEmptyFields from '../../../../../../helpers/removeEmptyFields';
import SelectBoards from '../../../../../common/Autocomplete/Boards/BoardList';
import { CountShow, Header, PrioritySelect, TimeShow } from '../../Messages/ModalForm/Fields';
import RenderTextMessage from './RenderTextMessage';

const ModalForm = ({
    isNew = false,
    isCopy = false,
    onClose = () => {},
    isOpen = false,
    item = {},
    reloadData, 
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    
    const priorities = useStoreProp(
        loadMessagePriorities,
        'boards',
        'messagePriorities'
    );

    const alertTypes = useStoreProp(
        loadAlertTemplateTypes,
        'boards',
        'alertTemplateTypes'
    );
    
    const alertFields = useStoreProp(
        loadAlertTemplateFileds,
        'boards',
        'alertTemplateFields'
    );

    const initialState = {
        priority: item?.priority || '',
        service: item?.service || '',
        header: item?.header || '',
        start_at: item?.start_at || null,
        text: item?.text || [],
        boards: item?.boards || [],
        count: item?.count || 0,
        duration: item?.duration || '',
        type: item?.type || '',
        is_scaled: true
    };

    const [data, setData] = useState(initialState);
    const [boards, setBoards] = useState(data.boards);
    const [isShowPreviewMessage, setIsShowPreviewMessage] = useState(false);
    const [boardForPreview, setBoardForPreview] = useState({});

    const filteredAlerts = (array) => {
        // фильтруем уведомления на 2 массива - знаки и текст, 
        const newData = array.reduce((acc, el, index) => {
            if (!el.type) {
                // костыль на случай старых сообщений когда не было еще type
                el.type = el.text ? 'text' : 'sign';
            }
            acc[el.type] = [...acc[el.type], { ...el, messageIndex: index }];
            return acc;
        }, { sign: [], text: [] });
        return newData;
    };

    const [alerts, setAlerts] = useState(filteredAlerts(data.text));

    const onChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
            ...(name === 'type' && { text: [] })
        }));
        validation.deleteKey(name);
    };

    const convertAlertsToText = (obj) =>{
        // преобразуем объект с уведомлениями в один плоский массив
        return Object.entries(obj).reduce((acc, [key, el]) => {
            acc = acc.concat(el);
            return acc;
        }, []);
    };

    const onSave = () => {
        const prepareData = {
            ...data,
            count: Number(data.count),
            duration: Number(data.duration),
            boards,
            width: boards[0].width,
            height: boards[0].height,
        };

        const paramsWithoutEmpty = removeEmptyFields(prepareData, false, 'count');

        const callback = () => {
            onClose();
            reloadData();
        };

        isNew
            ? dispatch(createAlertTemplate(paramsWithoutEmpty, callback))
            : dispatch(editAlertTemplate(item.id, paramsWithoutEmpty, callback));
    };

    const handleChangeAlert = (key, value) => {
        const newAlerts = {
            ...alerts,
            [key]: value,
        };
        const text = convertAlertsToText(newAlerts);
        setAlerts(filteredAlerts(text)); // чтобы правильно поставить индексы если добавились новые объекты
        setData({ ...data, text });
    };

    const serviceFields = useMemo(() => (data.type === '1' || data.type === '2') 
        ? alertFields.ecologe 
        : alertFields.meteologe, [alertFields.ecologe, alertFields.meteologe, data.type]);

    const microservice = useMemo(() => (data.type === '1' || data.type === '2') 
        ? 'ecologe' 
        : 'meteologe', [data.type]);

    const isDisabled = !data.priority
        || !data.header
        || !data.duration
        || !data.type;

    const isDisabledPreview = false;
    
    return (
        <Modal
            title={isNew ? titles.CREATE : isCopy ? titles.COPY : titles.EDIT}
            onClose={onClose}
            noPadding
            maxWidthProp={'md'}
            isOpen={isOpen}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.preview,
                        disabled: isDisabledPreview,
                        onClick: () => setIsShowPreviewMessage(true)
                    },
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isDisabled,
                        onClick: onSave
                    },
                ]}
            />}
        >
            <div className="modal__form">
                <Header
                    value={data.header} 
                    onChange={onChange} 
                    validation={validation}
                />

                <PrioritySelect
                    value={data.priority} 
                    priorities={priorities} 
                    onChange={onChange} 
                    validation={validation}
                />

                <div className="row">
                    <CountShow value={data.count} onChange={onChange} validation={validation}/>
                    <TimeShow value={data.duration} onChange={onChange} validation={validation}/>
                </div>

                <SelectBoards
                    selected={boards}
                    onChange={setBoards}
                    // filter={{ status: 2 }}
                    multiple
                    required
                />

                <FormControl 
                    className="block" 
                    size="small" 
                    variant="outlined" 
                    required 
                    error={!data.type}
                >
                    <InputLabel>Тип сообщения</InputLabel>
                    <Select
                        value={data.type}
                        onChange={(e) => { onChange(e); setAlerts(filteredAlerts([])); }}
                        label="Тип сообщения"
                        name="type"
                                    
                    >
                        {Object.keys(alertTypes)?.map((key) => (
                            <MenuItem key={key} value={key} defaultValue={key}>
                                {alertTypes[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Divider/>

                {(boards?.length > 0 && data?.type) && (
                    <> 
                        <RenderTextMessage
                            data={alerts.text}
                            onChange={(newData) => handleChangeAlert('text', newData)}
                            board={boards[0]}
                            serviceFields={serviceFields}
                            microservice={microservice}
                        />
                        
                        <RenderSigns 
                            data={alerts.sign}
                            onChange={(newData) => handleChangeAlert('sign', newData)}
                            serviceFields={serviceFields}
                            microservice={microservice}
                        />
                    </>
                )}
            </div>
            
            {isShowPreviewMessage && (
                <PreviewMessage
                    sortedList={boards}
                    isOpen={isShowPreviewMessage}
                    onClose={() => {
                        setIsShowPreviewMessage(false); 
                        setBoardForPreview({});
                    }}
                    template
                    boardForPreview={boardForPreview}
                    setBoardForPreview={setBoardForPreview}
                    text={convertAlertsToText(alerts)}
                />
            )}
        </Modal>
    );
};

export default ModalForm;