import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiIncidentsMethods = {

    getDtpFocus: async (params = {}) => {
        const response = await instance.get(apiUrls.getDtpFocus(), { params });
        return response?.data;
    },

    getDtpFocusPolygon: async (polygon, params) => {
        const response = await instance.post(apiUrls.getDtpFocusPolygon(), {
            polygon,
            ...params
        });
        return response.data;
    },

    createDtpFocus: async (data) => {
        const response = await instance.post(apiUrls.createDtpFocus(), data);
        return response.data;
    },

    editDtpFocus: async (id, data) => {
        const response = await instance.put(apiUrls.editDtpFocus(id), data);
        return response.data;
    },

    deleteDtpFocus: async (id) => {
        const response = await instance.delete(apiUrls.deleteDtpFocus(id));
        return response.data;
    },

    getDtpFocusStatistic: async (filter) => {
        const response = await instance.get(apiUrls.getDtpFocusStatistic(), { params: filter });
        return response.data;
    },

    loadHeatMapSaga: async (filter) => {
        const response = await instance.post(apiUrls.loadHeatMapSaga(), filter);
        return response.data;
    },
};

export default ApiIncidentsMethods;
