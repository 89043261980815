import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import './style.scss';
import { setDragLangRu } from './lang';
import defaultIcon from './markerIcon';

// добавление и редактирование метки, линии, полигона с geoJson
const MapDragGeoJson = ({
    map,
    onChange,
    geometry = {},
    //
    circle = false,
    rectangle = false,
    polyline = true,
    polygon = true,
    marker = true,
    readOnly = false,
    visibleGeometry = null,
    showDeleteButton = true, // показывать кнопку удаления
    centeredAfter = true,
    style,
}) => {

    const oldLayer = useRef(null);
    const drawnItemsLayer = useRef(null);

    const getJson = (layer) => {
        const jeoJson = layer.toGeoJSON();

        // задаем радиус если он не 0
        if (layer instanceof L.Circle && layer.getRadius() > 0) {
            jeoJson.properties.radius = layer.getRadius();
        }
        return jeoJson;
    };

    // L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
    //     attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    // }).addTo(map);

    useEffect(() => {
        setDragLangRu();
    }, []);

    useEffect(() => {
        if (map) {
            const drawnItems = Object.keys(geometry).length > 0
                ? new L.geoJSON({
                    type: 'Feature', // задаем тип по умолчанию
                    ...geometry,
                }, {
                    pointToLayer: (feature, latlng) => {
                        if (feature?.properties?.radius) {
                            // окружность
                            return new L.Circle(latlng, feature.properties.radius);
                        } else {
                            // просто точка
                            return new L.Marker(latlng, { icon: defaultIcon });
                        }
                    },
                    //pointToLayer: (feature, latlng) => L.marker(latlng, { icon: defaultIcon }),
                    style,
                })
                :  new L.FeatureGroup();

            map.addLayer(drawnItems);

            drawnItemsLayer.current = drawnItems;

            const drawControl = new L.Control.Draw({
                draw: {
                    circle,
                    rectangle,
                    circlemarker: false,
                    polyline, // LineString
                    polygon, // Polygon
                    marker: marker // Point
                        ? {
                            icon: defaultIcon,
                        }
                        : false,
                },
                edit: {
                    featureGroup: drawnItems,
                    remove: showDeleteButton,
                }
            });

            // map.on(L.Draw.Event.CREATED, (event) => {
            //     // создание
            //     const { layer } = event;
            //     drawnItems.clearLayers();
            //     drawnItems.addLayer(layer);
            //     onChange(getJson(layer));
            // });
            //
            // map.on(L.Draw.Event.EDITED, (event) => {
            //     // если изменили
            //     const { layers: { _layers: layers } } = event;
            //     const keys = Object.keys(layers);
            //     if (keys.length > 0) {
            //         const layer = layers[keys[0]];
            //         onChange(getJson(layer));
            //     }
            // });
            //
            // map.on(L.Draw.Event.DELETED, () => {
            //     // удаление
            //     onChange({});
            // });

            if (!readOnly) {
                map.addControl(drawControl);
            }

            //map.addControl(drawControl);

            if (centeredAfter) {
                const bounds = drawnItems?.getBounds();
                if (Object.keys(geometry).length > 0 && Object.keys(bounds).length > 0) {
                    map.fitBounds(bounds);
                    const type = geometry?.geometry?.type || '';
                    const radius = geometry?.properties?.radius || false;
                    if (type === 'Point' && !radius) map.setView(
                        [geometry?.geometry?.coordinates[1], geometry?.geometry?.coordinates[0]],
                        15
                    );
                }
            }

            // if (geometry?.geometry?.type === 'Point'){
            //     map.setView(geometry?.geometry?.coordinates?.reverse(), 15);
            // }

            return () => {
                map.removeControl(drawControl);
                map.removeLayer(drawnItems);
                drawnItemsLayer.current = null;
            };
        }
    }, [map, geometry, readOnly, visibleGeometry, centeredAfter]);

    // подписывание на события
    useEffect(() => {
        if (map && drawnItemsLayer.current) {
            map.on(L.Draw.Event.CREATED, (event) => {
                // создание
                const { layer } = event;
                drawnItemsLayer.current.clearLayers();
                drawnItemsLayer.current.addLayer(layer);
                onChange(getJson(layer));
            });

            map.on(L.Draw.Event.EDITED, (event) => {
                // если изменили
                const { layers: { _layers: layers } } = event;
                const keys = Object.keys(layers);
                if (keys.length > 0) {
                    const layer = layers[keys[0]];
                    onChange(getJson(layer));
                }
            });

            map.on(L.Draw.Event.DELETED, () => {
                // удаление
                onChange({});
            });

            return () => {
                // map.off(L.Draw.Event.);
                map.off(L.Draw.Event.CREATED);
                map.off(L.Draw.Event.EDITED);
                map.off(L.Draw.Event.DELETED);
            };
        }
    }, [map, onChange, drawnItemsLayer]);

    // визуализация данных геометрии
    useEffect(() => {
        if (map && visibleGeometry) {
            const visible = new L.geoJSON(visibleGeometry, {
                pointToLayer: (feature, latlng) => {
                    if (feature?.properties?.radius) {
                        // окружность
                        return new L.Circle(latlng, feature.properties.radius);
                    } else {
                        // просто точка
                        return new L.Marker(latlng, { icon: defaultIcon });
                    }},
            });
            if (visible) {
                if (oldLayer.current) {
                    oldLayer.current.clearLayers();
                    map.removeLayer(oldLayer.current);
                }

                oldLayer.current = visible;
                map.addLayer(visible);
                const bounds = visible.getBounds();
                map.fitBounds(bounds);
            }

            return () => {
                if (oldLayer.current) {
                    oldLayer.current.clearLayers();
                    map.removeLayer(oldLayer.current);
                }
            };
        }
    }, [map, visibleGeometry]);

    // return children ? React.Children.map(children, (child, index) => React.cloneElement(child, { map, ...prop })) : null;
    return null;
};

export default MapDragGeoJson;
