import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import FormButtonsComponent from '../../FormButtons';


const List = (props) => {
    const {
        innerRef,
        list,
        onClick = () => {},
    } = props;

    return (
        <>
            {(list.length > 0) && (
                <div
                    style={{
                        position: 'absolute',
                        background: '#ffffff',
                        top:'100%',
                        left:'0',
                        right:'0',
                        zIndex:'10',
                    }}
                    ref={innerRef}
                >
                    <Scrollbars
                        style={{
                            height: '300px'
                        }}
                    >
                        {list.map((listItem, index) => {
                            const {
                                title,
                                geo_lat,
                                geo_lon,
                            } = listItem;

                            return (
                                <div
                                    key={ Math.round(geo_lat + geo_lon + index) + Math.random() }
                                    style={{
                                        display: 'block',
                                        border: '1px solid #d9d9d9' }}
                                >
                                    <FormButtonsComponent
                                        justButton
                                        buttons={[
                                            {
                                                onClick: () => {
                                                    onClick({ title, index, geo_lat, geo_lon });
                                                },
                                                name: title
                                            }
                                        ]}
                                    />
                                </div>
                            );
                        })}
                    </Scrollbars>
                </div>
            )}
        </>
    );
};

export default List;