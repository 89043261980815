import React from 'react';
import { Collapse } from '@mui/material';
import { fullDateTime } from '../../../../../helpers/date.config';
import titles from '../../../../../helpers/constants/titles';
import messages from '../../../../../helpers/constants/messages';

const Info = ({ item, isOpen }) => {
    const {
        grz,
        start_wanted_at,
        end_wanted_at,
        reason,
        description,
        reason_stop,
        reason_stop_at,
    } = item;

    const notInfo = messages.INFO_IS_NOT_FOUND;

    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            <div><strong>{titles.NUMBER}: </strong>{grz || notInfo}</div>
            <div><strong>{titles.BASIS_DEPOSIT}: </strong>{reason || notInfo}</div>
            <div>
                <strong>{titles.DATE_START_SEARCH}: </strong>
                {start_wanted_at ? fullDateTime(start_wanted_at) : notInfo}
            </div>
            <div>
                <strong>{titles.END_DATE_SEARCH}: </strong>
                {end_wanted_at ? fullDateTime(end_wanted_at) : notInfo}
            </div>
            <div><strong>{titles.DESCRIPTION}: </strong>{description || notInfo}</div>
            {reason_stop_at && (
                <div>
                    <strong>{titles.DATE_TIME_STOPPING_SEARCH}: </strong>
                    {fullDateTime(reason_stop_at) || notInfo}
                </div>
            )}
            {reason_stop && (
                <div>
                    <strong>{titles.REASON_STOPPING_SEARCH}: </strong>
                    {reason_stop}
                </div>
            )}
        </Collapse>
    );
};

export default Info;
