import { useState } from 'react';
import { TextField } from '@mui/material';
import SelectCompany from 'components/common/Autocomplete/Companies';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SelectPakModel from 'components/common/Autocomplete/RoadNetwork/PakModel';
import SelectPakVendor from 'components/common/Autocomplete/RoadNetwork/PakVendor';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{page: number, data: {}}>>
}

const initialState = { 
    name: '',
    vendor_model_id_list: [],
    vendor_id_list: [],
    organization_id_list: []
};

const Filter = ({ setParams }: FilterProps) => {
    const [data, setData] = useState(initialState);

    const handleChange = (name: string, value: any) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const onSearch = (filters = data) => {
        const prepareData = {
            ...filters,
            vendor_model_id_list: filters?.vendor_model_id_list.map(({ id }) => id),
            vendor_id_list: filters?.vendor_id_list.map(({ id }) => id),
            organization_id_list: filters?.organization_id_list.map(({ id }) => id)
        };
        setParams({ page: 1, data: removeEmptyFields(prepareData) });
    };

    const resetFilter = (needRefresh: boolean) => {
        needRefresh && onSearch(initialState);
        setData(initialState);
    };

    const updateFilter = (filters: typeof initialState) => {
        const newFilter = { ...initialState, ...filters };
        setData(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };

    return (
        <LayoutFilter
            filter={data}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={data.name}
                    name="name"
                    onChange={(e) => handleChange('name', e.target.value)}
                    label={titles.NAME}
                    size="small"
                    variant="outlined"
                />

                <SelectPakModel
                    multiple
                    label={'Модель оборудования'}
                    selected={data.vendor_model_id_list}
                    onChange={(value) => handleChange('vendor_model_id_list', value)}
                />

                <SelectPakVendor
                    multiple
                    label={'Вендор оборудования'}
                    selected={data.vendor_id_list}
                    onChange={(value) => handleChange('vendor_id_list', value)}
                />

                <SelectCompany
                    multiple
                    label={'Собственник оборудования'}
                    selected={data?.organization_id_list}
                    onChange={(value) => handleChange('organization_id_list', value)}
                    selectDeletedCompanies
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;