import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { boardsSelectors } from 'redux/Boards';
import { loadFontsList } from 'redux/Boards/actions';
import _ from 'lodash';
import {
    Autocomplete,
    Icon,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField
} from '@mui/material';
import removeEmptyFields from 'helpers/removeEmptyFields';

interface SelectBoardFontsProps {
    multiple?: boolean,
    selected: string | string[] | null,
    onChange: (value: string | string[] | null) => void,
    required?: boolean,
    disabled?: boolean,
    label?: string,
    error?: boolean,
    helperText?: string,
    className?: string,
    sortedList?: string[],
    filter?: Object,
    limitTags?: number,
}

const SelectBoardFonts = ({
    multiple = false,
    selected = multiple ? [] : null,
    onChange = () => {},
    required = false,
    disabled = false,
    label='Шрифты',
    error = false,
    helperText = '',
    className = '',
    sortedList = [], // список шрифтов из настроек ДИТ
    filter = {},
    limitTags = 1
}: SelectBoardFontsProps) => {
    const dispatch = useDispatch();
    
    const fontsList = useSelector(boardsSelectors.fontsList);
    const currentFilter = useRef({});
    
    const currentList = sortedList.length > 0
        ? sortedList.reduce((res: { [key: string]: string }, el: string) => {
            res[el] = fontsList[el] || el;
            return res;
        }, {})
        : fontsList;

    useEffect(() => {
        if (!_.isEqual(filter, currentFilter.current)) {
            const paramsWithoutEmpty = removeEmptyFields(filter);
            currentFilter.current = filter;

            dispatch(loadFontsList(paramsWithoutEmpty));
        }
    }, [dispatch, filter]);

    return (
        <Autocomplete
            className={className}
            multiple={multiple}
            value={selected}
            openText={'Открыть'}
            closeText={'Закрыть'}
            clearText={'Очистить'}
            options={Object.keys(currentList)}
            getOptionLabel={(option) => currentList[option] || option || ''}
            onChange={(_, value) => onChange(value)}
            onReset={() => onChange(multiple ? [] : null)}
            disableCloseOnSelect={multiple} // чтобы не закрывалось когда выбирают несколько шрифтов
            size="small"
            disabled={disabled}
            limitTags={limitTags}
            renderOption={(props, option, { selected }) => (
                <ListItem {...props}>
                    <ListItemIcon>
                        <Icon
                            className={selected ? 'far fa-check' : 'far fa-plus'}
                            sx={{ color: 'green' }}
                        />         
                    </ListItemIcon>
                    <ListItemText>
                        {currentList[option] || option || ''}
                    </ListItemText>                          
                </ListItem>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    required={required}
                    error={error}
                    helperText={helperText}
                />
            )}
        />
    );
};

export default SelectBoardFonts;
