import React from 'react';
import {
    TextField,
    // FormControl,
    // InputLabel,
    InputAdornment,
    IconButton,
    // Select,
    // MenuItem
} from '@mui/material';
// import { useStoreProp } from '../../../../../../helpers/hooks';
import titles from '../../../../../../helpers/constants/titles';
// import * as actions from '../../../../../../redux/TransportSpecial/actions';
// import { Autocomplete } from '@mui/lab';
import AutocompleteCompanies from '../../../../../common/Autocomplete/Companies';


const Form = (props) => {
    const { data, onChange, types = {} } = props;
    const getValue = (key) => data[key] || '';

    // const types = useStoreProp(actions.loadTypes, 'transportSpecial', 'types');
    const typesList = Object.keys(types).map((id) => ({ id, name: types[id] }));

    const handleEventAC = (key) => (_, newValue) => {
        console.log(newValue);
        onChange({
            [key]: newValue
        });
    };

    /*todo
    - заявка (выбор из справочника, номер заявки на дорожные работы)
    - наряд (выбор из справочника, номер наряда)
    - участок (выбор из справочника, номер участка)
    */

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <TextField
                size="small"
                fullWidth
                className="block"
                label={titles.VEHICLE_NUMBER}
                id="outlined-margin-dense"
                value={getValue('number')}
                onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                //helperText="Some important text"
                variant="outlined"
                name="number"
                InputProps={{
                    endAdornment: (
                        <>
                            {getValue('number').length > 0 ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            onChange({ number: '' });
                                        }}
                                        edge="end"
                                        size="large">
                                        <span style={{ fontSize: '1rem' }}>x</span>
                                    </IconButton>
                                </InputAdornment>
                            ) : ''}
                        </>
                    )
                }}
            />

            {/*<div className="block">*/}
            {/*    <Autocomplete*/}
            {/*        noOptionsText={titles.NO_RESULTS}*/}
            {/*        value={getValue('type') || null}*/}
            {/*        getOptionSelected={(option, value) => option.name === value.name}*/}
            {/*        options={typesList}*/}
            {/*        filterSelectedOptions={true}*/}
            {/*        getOptionLabel={(option) => option?.name || ''}*/}
            {/*        onChange={handleEventAC('type')}*/}
            {/*        size="small"*/}
            {/*        renderInput={(params) => (*/}
            {/*            <TextField*/}
            {/*                {...params}*/}
            {/*                size="small"*/}
            {/*                variant="outlined"*/}
            {/*                label={titles.TYPE}*/}
            {/*                // error={isErrorField('type') || params?.error}*/}
            {/*                // helperText={getErrorValidation('type') || params?.helperText}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    />*/}
            {/*</div>*/}

            <AutocompleteCompanies
                onChange={(value) => {
                    onChange({ 'organization_id': value.map((item) => ({ ...item, name: item.title })) });
                }}
                selected={data?.organization_id || []}
                multiple
                selectDeletedСompanies
                filter={{ withDeleted: 1 }}
                label={'Организация'}
            />

        </form>
    );
};

export default Form;
