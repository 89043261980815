const routeTitles = {
    ADD_ROUTE: 'Добавить маршрут',
    EDIT_ROUTE: 'Редактировать маршрут',
    SET_DIRECTION: 'Проложить маршрут',
    EDIT_DIRECTION: 'Редактировать направления',
    SEE_ON_MAP: 'Посмотреть на карте',
    PASSPORT_ROUTE: 'Паспорт маршрута',
    ROUTE: 'Маршрут',
    ROUTE_NUM: '№ марш.',
    NUM: '№',
    ROUTES: 'Маршруты',
    SELECT_ROUTE_NUM: 'Введите номер маршрута',
    SELECT_ROUTE_CATEGORY: 'Выберите тип ТС',
    SELECT_ROUTE: 'Выберите маршрут',
    ROUTE_LOCK: 'Закрепить маршрут',
    ROUTE_UNLOCK: 'Открепить маршрут',
    LOCK: 'Закрепить',
    UNLOCK: 'Открепить',
    ROUTE_TYPE: 'Тип маршрута',
    ROUTE_NAME: 'Название маршрута',
    NAME: 'Название',
    TYPE_OF_VEHICLE: 'Вид ТС',
    NUM_ROUTE: '№ марш.',
};

export default routeTitles;
