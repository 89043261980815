import moduleName from './module';
const prefix = `${moduleName}`;

/**
 * Constants
 * */
export const SET_VALIDATION = `${prefix}/SET_VALIDATION`;
export const CLEAR_VALIDATION = `${prefix}/CLEAR_VALIDATION`;
export const DELETE_VALIDATION = `${prefix}/DELETE_VALIDATION`;
export const DELETE_VALIDATION_ARR = `${prefix}/DELETE_VALIDATION_ARR`;
