import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    saveActiveLayer,
    setSelectedLayer,
    setShowReturnTo,
} from '../../../redux/Menu/actions';
import {
    Button,
} from '@mui/material';
import { menuSelectors } from '../../../redux/Menu';


const ReturnTo = ({ history }) => {
    const dispatch = useDispatch();
    const showReturnTo = useSelector(menuSelectors.showReturnTo);

    const {
        link = '',
        buttonText = '',
    } = showReturnTo || {};

    return showReturnTo?.link
        ? (
            <div>
                <Button
                    type="text"
                    onClick={() => {
                    // очищаем слои
                        dispatch(saveActiveLayer([]));
                        // и активный слой
                        dispatch(setSelectedLayer());
                        // переходим к дашборду
                        history.push({
                            pathname: link //'/reports/efficiency/dashboard',
                        });
                        dispatch(setShowReturnTo());
                    }}
                >
                    {buttonText}
                    {/*Вернуться в дашборд*/}
                </Button>
            </div>

        ) : null;
};

export default ReturnTo;