// @ts-nocheck
import SelectCameras from '../../../../common/Autocomplete/CameraVideoanalytics';
import DateRange from '../../../../common/Dates/DateRange';
import titles from '../../../../../helpers/constants/titles';
import { ICamera } from './types';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';


export const Filters = ({ filter, setFilter, onSearch, onReset, onUpdate }) => {
    const handleChange = (fieldName: string, value: Date | ICamera[]) => {
        setFilter({
            ...filter,
            [fieldName]: value
        });
    };

    const isDisabled = filter.cameras_in.length === 0
        || filter.cameras_out.length === 0;

    return (
        <LayoutFilter
            onResetFilter={onReset} 
            onSearch={onSearch}
            disabled={isDisabled}
            filter={filter} 
            filterException={['start_date', 'end_date',]} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={onUpdate}
            isFilterVisible={true}
        >
            <LayoutFilter.Visible>
                <SelectCameras
                    label={titles.CAMERAS_IN}
                    selected={filter.cameras_in}
                    onChange={(cameras_in) => handleChange('cameras_in', cameras_in)}
                    optionWithAddress
                    multiple
                    required
                />

                <SelectCameras
                    label={titles.CAMERAS_OUT}
                    selected={filter.cameras_out}
                    onChange={(cameras_out) => handleChange('cameras_out', cameras_out)}
                    optionWithAddress
                    multiple
                    required
                />

                <DateRange
                    valueStartDate={filter.start_date}
                    valueEndDate={filter.end_date}
                    handleDate={(start_date: Date) => handleChange('start_date', start_date)}
                    handleEndDate={(end_date: Date) => handleChange('end_date', end_date)}
                    required
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};