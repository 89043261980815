import React, { memo } from 'react';
import { FormControl, TextField } from '@mui/material';

interface TimeShowProps {
    value: string
    onChange: (arg: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    validation: any
}

export const TimeShow = memo(({ value, onChange, validation }: TimeShowProps) => {

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        const target = e.target as HTMLInputElement;

        if (key === 'e' || key === '+' || key === '-') {
            e.preventDefault();
        }
        if (target.value.length === 0 && (key === '.' || key === ',')) {
            e.preventDefault();
        }
    };

    return (
        <FormControl className="row__item" size="small" variant="outlined">
            <TextField
                label="Время показа в секундах"
                variant="outlined"
                size="small"
                value={value}
                name="duration"
                onKeyPress={handleKeyPress}
                onChange={e => Number(e.target.value) >= 0 && onChange(e)}
                type="number"
                required
                inputProps={{ min: 0 }}
                error={!value || validation.isKey('duration')}
            />
        </FormControl>
    );
});