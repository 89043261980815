import { useDispatch } from 'react-redux';
import titles from '../../../../../helpers/constants/titles';
import { loadVideoCamerasList } from '../../../../../redux/VideoCameras/actions';
import UniversalSelect from '../../../../common/UniversalSelect';

const SelectCameras = ({ isOpen, onChange, onClose, selected, sumCameras }) => {
    const dispatch = useDispatch();

    const handleAccept = (data) => {
        onChange(data);
        onClose();
    };

    const getList = ({ page, limit, query: name }) => {
        dispatch(loadVideoCamerasList(page, limit, { name }));
    };

    return <> 
        {isOpen 
            && <UniversalSelect
                storeName={'videoCameras'}
                storeNameProps={'videoCameraList'}
                keyProp={'id'}
                fetchList={getList}
                withSearch={true}
                multiple
                isSelected={true}
                onAccept={handleAccept}
                selected={selected}
                renderProps={(el) => <div>{el.name}</div>}
                isOpen={isOpen}
                onClose={onClose}
                noPadding
                title= {titles.CAMERAS}
                limitedNumberItems={sumCameras}
                dynamicTitle={true}
            />
        }
    </>;
};

export default SelectCameras;
