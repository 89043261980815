import React, { useMemo } from 'react';
import config from './config.js';
import { createIconMarker, getDefaultColor } from './helper';
import {
    Marker,
    ToolTip,
} from '../../leaflet';
import L from 'leaflet';


const MarkersClusters = (props) => {
    const { polygon = [], ...markerProps } = props;

    const markers = useMemo(() => {
        return polygon.map((item) => {
            const json = L.geoJSON(item?.geometry);
            const { lat, lng } = json.getBounds().getCenter();
            const color = item?.type?.color || getDefaultColor();

            return {
                id: item.id,
                key: item.id,
                name: item.name,
                icon: createIconMarker(color),
                latlng: [lat, lng],
                attribution: {
                    slug: config.slug,
                    color,
                }
            };
        });
    }, [polygon]);

    return (
        <>
            {markers.map((item) => {
                return (
                    <Marker
                        {...markerProps}
                        {...item}
                        onClick={(e) => {
                            if (e.lat && e.lng) {
                                props.map.setView([e.lat, e.lng], 15);
                                setTimeout(() => {
                                    props.map.fire(`showBy${config.slug}${item.id}`);
                                }, 1000);
                            }
                        }}
                    >
                        <ToolTip
                            direction="top"
                            offset={[0, -40]}
                        >
                            <div>
                                <div><strong>{item.name}</strong></div>
                                <div style={{ fontSize: '12px', color: '#7c7c7c' }}>(кликните, чтобы приблизить)</div>
                            </div>
                        </ToolTip>
                    </Marker>
                );

            })}
        </>
    );
};

export default MarkersClusters;
