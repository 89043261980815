import { useState } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';

import titles from '../../../../../helpers/constants/titles';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import Vehicles from '../../../../common/Autocomplete/Transport/Vehicles';
import DateRange from '../../../../common/Dates/DateRange';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';

const Filter = ({
    categories,
    setFilter,
    resetFilter,
    filter,
    onSearch,
    initialStateFilter
}) => {

    const [isDisabled, setIsDisabled] = useState(false);

    const updateFilter = (filters) => {
        setFilter({
            ...initialStateFilter,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            onSearch({ // отправляем в родителя, чтобы произошел запрос
                ...initialStateFilter,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={onSearch}
            disabled={isDisabled}
            filter={filter} 
            filterException={['date_start', 'date_end',]} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={ (newDate) => setFilter({ ...filter, date_start: newDate })}
                    handleEndDate={ (newDate) => setFilter({ ...filter, date_end: newDate })}
                    valueStartDate={filter.date_start}
                    valueEndDate={filter.date_end}
                    isDisabledHandler={(value) => setIsDisabled(value)}
                />
                <Vehicles
                    multiple
                    selected={filter.grz}
                    onChange={(value) => setFilter({ ...filter, grz: value })}
                    label="Номер ТС"
                    storeName="transportSpecial"
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <FormControl size="small" variant="outlined">
                    <InputLabel>{titles.CATEGORY_VEHICLE}</InputLabel>

                    <Select
                        value={filter.category_id}
                        onChange={ (e) => {
                            setFilter({ ...filter, category_id: e.target.value });
                        }}
                        label={titles.CATEGORY_VEHICLE}
                    >
                        <MenuItem value={''}>
                            {titles.NOT_CHOSEN}
                        </MenuItem>
                        {categories.map((item, index) => (
                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                
                <SelectCompany
                    multiple
                    selected={filter.organization_id}
                    onChange={(value) => setFilter({ ...filter, organization_id: value })}
                    filter={{ withDeleted: 1 }}
                    selectDeletedCompanies
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
