import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse } from '@mui/material';

import Loading from 'components/common/Loading';
import { fetchScheduleEvents } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';

import RenderTable from './RenderTable';

const Info = ({ isOpen, id, item }) => {
    const dispatch = useDispatch();

    const currentScheduleEvents = useSelector(transportPassengerSelectors.currentScheduleEvents);
    const loadingCurrentScheduleEvents = useSelector(transportPassengerSelectors.loadingCurrentScheduleEvents);

    useEffect(() => {
        dispatch(fetchScheduleEvents({ id }));
    }, [dispatch, id]);

    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            {loadingCurrentScheduleEvents && <Loading linear={true}/>}
            {item?.traffic_schedules.length > 0
                && <>
                    <strong>Графики движения:</strong>
                    <div className="info">
                        {item?.traffic_schedules.map(traffic => <div key={traffic.id}>
                            Номер: {traffic.diagram}, Название: {traffic.name}
                        </div>)}
                    </div>
                </>
            }
            <RenderTable currentScheduleEvents={currentScheduleEvents[id]} />
        </Collapse>
    );
};

export default Info;
