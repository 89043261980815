import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { monitoringSelectors } from 'redux/Monitoring';
import { getList, loadStatuses } from 'redux/Monitoring/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';

import Item from './Item';
import Filters from './Filters';

import type { Meta } from 'types/pagination';
import type { MonitoringEntity, Status } from './types';


const Monitoring = () => {
    const dispatch = useDispatch();

    const list: MonitoringEntity[] = useSelector(monitoringSelectors.data);
    const meta: Meta = useSelector(monitoringSelectors.meta);
    const loading: boolean = useSelector(monitoringSelectors.loading);

    const statuses: Status[] = useStoreProp(loadStatuses, 'monitoring', 'statuses');

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    useEffect(() => {
        dispatch(getList({ page: params.page, limit, ...params.data }));
    }, [dispatch, limit, params]);

    const renderList = () => (
        <>
            {list.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: '', width: '50px' },
                            { title: titles.NAME, width: '40%' },
                            { title: 'Тип оборудования', width: '20%' },
                            { title: titles.ADDRESS, width: 'calc(30% - 50px)' },
                            { title: 'Действия', isActions: true },
                        ]}
                    >
                        {list.map((item) => (
                            <Item
                                key={item.id}
                                item={item}
                                statuses={statuses}
                            />
                        ))}
                    </LSContainer>
                )
                : !loading && <NoData />
            }
        </>
    );

    return (
        <PageLayout
            header="Мониторинг оборудования"
            filters={<Filters setParams={setParams} statuses={statuses}/>}
            informPanelProps={{ total: meta.total }}
            loading={loading}
            content={renderList}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: meta,
                limit,
                setLimit,
            }}
        />
    );
};

export default Monitoring;
