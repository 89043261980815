import React from 'react';
import BoxContainer from '../../../common/Showcase/BoxContainer';
import Page from './Page';
import { HeaderPageVisible } from '../../../common/Showcase/HeaderPage';
import HeaderPanel from '../../../common/Showcase/HeaderPanel';

const VideoAnalytics = () => {
    return (
        <BoxContainer>

            {/* шапка */}
            <HeaderPageVisible/>

            <HeaderPanel title="Статистика по Видеоаналитике" />

            {/* содержимое страницы */}
            <Page />

        </BoxContainer>
    );
};

export default VideoAnalytics;