import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../common/Loading';
import { useHistory } from 'react-router-dom';
import messages from '../../../../../helpers/constants/messages';
import * as actions from '../../../../../redux/Efficiency/actions';
import { efficiencySelectors } from '../../../../../redux/Efficiency';
import Filter from './Filter';
import Graphic from './Graphic';
import ReturnToButton from '../../../../common/Buttons/ReturnToButton';

const GraphReports = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [params, setParams] = useState({});

    const graphList = useSelector(efficiencySelectors.graphList);
    const loadingGraphList = useSelector(efficiencySelectors.loadingGraphList);

    useEffect(() => {
        // загрузка списка, если поменялся фильтр - если он пустой, не загружаем
        if (Object.keys(params).length > 0) {
            dispatch(actions.loadGraphList(params));
        }
        return () => {
            dispatch(actions.loadedGraphList([]));
        };
    }, [params, dispatch]);

    return (
        <div>
            <ReturnToButton/>

            <h1>Статистика в графиках</h1>
            <Filter setFilter={setParams} />
            {loadingGraphList && <Loading circular={true}/>}
            {graphList?.length > 0
                ? graphList.map((item) => <Graphic key={item.title} item={item} />)
                : (!loadingGraphList && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </div>
    );
};

export default GraphReports;
