import * as types from './types';

// установить валидацию
export const setValidation = (data, message) => ({
    type: types.SET_VALIDATION,
    payload: { data, message }
});

// очистить валидацию
export const clearValidation = () => ({
    type: types.CLEAR_VALIDATION
});

// удалить поле из валидации
export const deleteValidation = (keyField) => ({
    type: types.DELETE_VALIDATION,
    payload: keyField
});

// удалить gjkz из валидации
export const deleteValidationArr = (keyFields = []) => ({
    type: types.DELETE_VALIDATION_ARR,
    payload: keyFields
});
