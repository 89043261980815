import React from 'react';
import {
    ListItem,
    ListItemText,
} from '@mui/material';
import cn from 'classnames';

import styles from '../workOrderControl.module.scss';

const TableHeader = ({ isStations }) => {
    return (
        <>
            <ListItem divider dense>
                <ListItemText className={cn(styles.info_block, styles.info_block__header)} disableTypography>
                    <div className={cn(styles.info_block__item, styles.info_block__item_first)}>№</div>
                    <div className={styles.info_block__item}>
                        {isStations
                            ? <>Остановки или контрольные точки</>
                            : <>Контрольные точки</>
                        }
                    </div>
                    <div className={styles.info_block__item}>
                        Контрольное событие
                    </div>
                    <div className={styles.info_block__item}>Авто-статус</div>
                    <div className={styles.info_block__item}>Ручной статус от диспетчера</div>
                    <div className={styles.info_block__item}>Время прибытия план</div>
                    <div className={styles.info_block__item}>Время прибытия факт</div>
                    <div className={styles.info_block__item}>Время отправления план</div>
                    <div className={styles.info_block__item}>Время отправления факт</div>
                    <div style={{ width: '1%' }}></div>
                </ListItemText>
            </ListItem>
        </>
    );
};

export default TableHeader;
