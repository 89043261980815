import Settings from '../../../../common/Settings';
import { editSettingsGroup, editSettingsList, loadSettingsGroup } from '../../../../../redux/Ecology/actions';

const EcologySettings = () => {
    return (
        <Settings
            title="Настройки – Экологический мониторинг"
            storeProp="ecology"
            listName="settingsGroup"
            loadingName="loadingSettingsGroup"
            loadSettingsGroupAction={loadSettingsGroup}
            editSettingsListAction={editSettingsList}
            editSettingsGroupAction={editSettingsGroup}
        />
    );
};

export default EcologySettings;