import { useMemo } from 'react';

import { loadCameraViolations } from 'redux/VideoAnalytics/actions';
import { useStoreProp } from 'helpers/hooks';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

import type { EventType } from '../types';

interface Violations {
    [key: string]: string;
}

const SelectViolation = ({ selected, onChange, label }: CommonAutocompleteProps<EventType>) => {
    const violations: Violations = useStoreProp(
        loadCameraViolations,
        'videoAnalytics',
        'violations'
    );

    const eventTypes: EventType[] = useMemo(() => {
        return Object.keys(violations)?.map((key) => ({
            value: key,
            label: violations[key],
        }));
    }, [violations]);

    return (
        <CommonAutocomplete
            multiple
            label={label}
            selected={selected}
            onChange={onChange}
            options={eventTypes}
            limitTags={1}
            filterSelectedOptions
            renderLabel={(option) => option?.label || ''}
            onReset={() => onChange([])}
        />
    );
};

export default SelectViolation;
