import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, LinearProgress } from '@mui/material';

import CardWidget from '../../../common/Showcase/CardWidget';
import layerConfig from '../../../MapComponents/Layers/Ecology/config';
import TypeComponent from '../../../common/Showcase/TypeComponent';
import { fullDateTimeWithoutSeconds } from '../../../../helpers/date.config';
import Layer from '../../../MapComponents/Layers/Ecology/layer';
import { ecologySelectors } from '../../../../redux/Ecology';
import { clearShowcase, loadShowcase } from '../../../../redux/Ecology/actions';
import CardMap from '../../../common/Showcase/CardMap';

const Page = () => {
    const dispatch = useDispatch();

    const showCase = useSelector(ecologySelectors.showcase);
    const loadingShowcase = useSelector(ecologySelectors.loadingShowcase);

    const {
        graph = [],
        table = [],
    } = showCase;

    const isGraph = graph?.length > 0;
    const isTable = table?.length > 0;

    useEffect(() => {
        dispatch(loadShowcase());
        return () => dispatch(clearShowcase());
    } ,[]);

    return (
        <Grid container spacing={2} sx={{ height: '100%' }} >
            {(isTable || isGraph) && (
                <Grid
                    item
                    xs={12}
                    md={4}
                    className="showcase_grid_flex-column"
                >
                    {loadingShowcase && <LinearProgress/>}

                    {isTable && table.map((item, index) => (
                        <CardWidget
                            key={index}
                            icon={layerConfig.layerIcon}
                            title={item.name}
                            marginBottom
                            fillOthers
                            small
                        >
                            <TypeComponent
                                {...item}
                            />
                        </CardWidget>
                    ))}

                    {isGraph && graph.map((item, index) => (
                        <div key={index}>
                            <CardWidget
                                icon={layerConfig.layerIcon}
                                title={item.name}
                                marginBottom
                                small
                            >
                                <TypeComponent
                                    {...item}
                                    indexColor={index}
                                    labelFormat={(value) => fullDateTimeWithoutSeconds(value)}
                                />
                            </CardWidget>
                        </div>
                    ))}
                </Grid>
            )}

            <Grid
                item
                xs={12}
                {...(isTable || isGraph) ? { md: 8 } : {}}
                className="showcase_grid_flex-column"
            >
                <CardMap
                    fillOthers
                    isCluster
                >
                    <Layer readOnly visibleLegend/>
                </CardMap>
            </Grid>
        </Grid>
    );
};

export default Page;
