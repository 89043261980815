import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';

import { editRoadWorkOrder, loadOrderStatuses } from 'redux/RoadWorks/actions';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import StatusesSelect from 'components/common/Autocomplete/Statuses';

const EditOrderStatusModal = ({ data = {}, isOpen, onClose, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const [order, setOrder] = useState(data);

    const order_statuses = useStoreProp(loadOrderStatuses, 'roadworks', 'orderStatuses');

    const handleChange = (value, name) => {
        setOrder({
            ...order,
            [name]: value
        });
    };

    const isDisabled = false;

    const submit = () => {
        dispatch(editRoadWorkOrder(order?.id, order, () => {
            reloadList();
            onClose();
        }));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            title={titles.EDIT_STATUS}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: !order?.id || isDisabled,
                        onClick: submit
                    },
                ]}
            />}
        >
            <form className="modal__form">
                <StatusesSelect
                    selected={order_statuses.find(status => status.id === order.status)}
                    options={order_statuses}
                    onChange={(value) => {
                        handleChange(value.id, 'status');
                    }}
                    required
                    error={validation.isKey('status')}
                    helperText={validation.get('status')}
                />
            </form>
        </Modal>
    );
};

export default EditOrderStatusModal;