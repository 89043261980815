const renderAddress = (
    address = {},
    addressKeys = [
        // 'area',
        'city_name',
        'street',
        'house'
    ]
) => addressKeys
    .reduce(
        (acc, key) => address[key] ? [...acc, address[key]] : acc
        , []
    )
    .join(', ');

export default renderAddress;
