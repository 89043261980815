import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { differenceInMonths } from 'date-fns';

import messages from 'helpers/constants/messages';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import { loadWorkorderStatuses } from 'redux/TransportPassenger/actions';
import SelectWorkorders from 'components/common/Autocomplete/PassengerTransport/Workorders';
import SelectVehicles from 'components/common/Autocomplete/Transport/Vehicles';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filters = ({ setParams, initialState }) => {

    const validation = useValidation();
    const statuses = useStoreProp(loadWorkorderStatuses, 'transportPassenger', 'workorderStatuses');

    const [filterValues, setFilterValues] = useState(initialState);

    const handleChange = (value, keyName) => {
        setFilterValues({
            ...filterValues,
            [keyName]: value
        });
        validation.clear();
    };

    const reset = (needRefresh) => {
        if (needRefresh) {
            setParams(initialState);
        }
        setFilterValues(initialState);
    };

    const [isDisabled, setIsDisabled] = useState(false);
    const isDisabledHandler = (value) => setIsDisabled(value);

    const buttonDisable = !filterValues.start_date || !filterValues.end_date || isDisabled;
    const isMoreThenMonth = differenceInMonths(filterValues.end_date, filterValues.start_date) > 0;

    const updateFilter = (filters) => {
        setFilterValues({
            ...initialState,
            ...filters
        });

        if (Object.keys(filters).length > 0) {
            setParams({
                ...initialState,
                ...filters
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={reset}
            onSearch={() => setParams(filterValues)}
            filter={filterValues}
            filterException={['start_date', 'end_date']}
            setUserFilter={updateFilter}
            disabled={buttonDisable}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filterValues.start_date}
                    valueEndDate={filterValues.end_date}
                    handleDate={(value) => handleChange(value, 'start_date')}
                    handleEndDate={(value) => handleChange(value, 'end_date')}
                    isDisabledHandler={isDisabledHandler}
                    errorMessage={() =>
                        <span>
                            {validation.get('start_date') || ''}
                            {validation.get('end_date') || ''}
                            {isMoreThenMonth && 'Разница между датами не может быть больше 1 месяца'}
                        </span>
                    }
                    renderError={() =>
                        validation.isKey('start_date')
                        || validation.isKey('start_date')
                        || isMoreThenMonth
                    }
                    dateOnly
                />
                <FormControl variant="outlined" size="small">
                    <InputLabel>Статус</InputLabel>
                    <Select
                        label="Статус"
                        value={filterValues.status}
                        onChange={({ target: { value } }) => handleChange(value, 'status')}
                    >
                        {Object.keys(statuses)?.length
                            && Object.keys(statuses)?.map(key =>
                                <MenuItem key={key} value={key}>{statuses[key]}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <SelectVehicles
                    label="Гос номер ТС"
                    limitTags={1}
                    selected={filterValues.vehicle_id_list || []}
                    multiple
                    onChange={(value) => handleChange(value, 'vehicle_id_list')}
                    storeName="transportPassenger"
                    renderProps={(el, cat) => (
                        <div>
                            <div>
                                <strong>Гос номер:&nbsp;</strong>
                                {el?.number || messages.NO_DATA}
                            </div>
                            <div>
                                <strong>{titles.TYPE_OF_VEHICLE}:&nbsp;</strong>
                                {cat?.name || messages.NO_DATA}
                            </div>
                        </div>
                    )}
                    renderOption={(opt, cat) => (
                        `${cat?.name || ''} ${opt?.number}`
                    )}
                />
                <SelectVehicles
                    label="Гаражный №"
                    limitTags={1}
                    selected={filterValues.garage_numbers || []}
                    multiple
                    onChange={(value) => handleChange(value, 'garage_numbers')}
                    storeName="transportPassenger"
                    searchBy={'garage_number'}
                    renderProps={(el, cat) => (
                        <div>
                            <div>
                                <strong>Гар. №:&nbsp;</strong>
                                {el?.garage_number || messages.NO_DATA}
                            </div>
                            <div>
                                <strong>{titles.TYPE_OF_VEHICLE}:&nbsp;</strong>
                                {cat?.name || messages.NO_DATA}
                            </div>
                        </div>
                    )}
                    renderOption={(opt, cat) => (
                        `${cat?.name || ''} ${opt?.garage_number}`
                    )}
                />
                <SelectWorkorders
                    multiple
                    limitTags={1}
                    selected={filterValues.ids || []}
                    onChange={(value) => handleChange(value, 'ids')}
                    label="ID наряда"
                    renderProps={(el) => (
                        <div>
                            <div><b>ID:</b> {el?.id || messages.NO_DATA}</div>
                            <div><b>Дата наряда:</b> {el?.date_at || messages.NO_DATA}</div>
                            <div><b>№ графика:</b> {el?.traffic_schedule?.diagram || messages.NO_DATA}</div>
                        </div>
                    )}
                    renderOption={(option) => option.id}
                    searchParam={'id'}
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filters;
