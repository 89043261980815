import AppBar from '@mui/material/AppBar';
import React, { useRef, useState } from 'react';
import { Card } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
import { CustomTabs, CustomTab } from '../../../../../common/Tabs';

const Tabs = (props) => {
    const {
        list = [],
    } = props;

    const scrollRef = useRef(null);

    const tabPanelProps = (tabName) => ({
        role: 'tabpanel',
        id: `scrollable-force-tabpanel-${tabName}`,
        'aria-labelledby': `scrollable-force-tab-${tabName}`,
        style: {
            padding: '1rem',
            height: '100%',
        }
    });

    const tabProps = (tabName) => ({
        id: `scrollable-force-tab-${tabName}`,
        'aria-controls': `scrollable-force-tabpanel-${tabName}`,
    });

    const [selectedTab, setSelectedTab] = useState( list?.[0]?.tab || '');

    const scrollTop = () => {
        if (scrollRef.current) scrollRef.current?.scrollTop(0);
    };

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
        scrollTop();
    };

    return (
        <Card
            variant="elevation"
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <AppBar position="sticky" color="default">
                <CustomTabs
                    value={selectedTab}
                    onChange={handleChange}
                    textColor="primary"
                    variant="scrollable"
                    aria-label="scrollable force tabs example"
                >
                    {list?.map((item, index) => (
                        <CustomTab
                            key={index}
                            icon={item?.icon}
                            iconPosition="start"
                            value={item?.tab}
                            label={item?.label}
                            {...tabProps(item?.tab)}
                        />
                    ))}
                </CustomTabs>
            </AppBar>

            <div
                style={{
                    height: '100%',
                    display: 'flex'
                }}
            >
                <Scrollbars
                    style={{
                        height: '100%',
                        minHeight: '400px',
                    }}
                    ref={scrollRef}
                >
                    {list?.map((item, index) => {
                        if (item?.tab === selectedTab) {
                            return (
                                <div
                                    key={index}
                                    {...tabPanelProps(item?.tab)}
                                >
                                    {item?.component}
                                </div>
                            );
                        }
                        return null;
                    })}
                </Scrollbars>
            </div>
        </Card>
    );
};

export default Tabs;
