import { useState } from 'react';

import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';
import Color from './Color';

function Item({ data, colorProvider, onChanged, reloadList }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const onAccept = () => {
        colorProvider.delete(data?.id, () => reloadList(true));
    };

    const { color, speed } = data;

    return (
        <>
            <LSContentItem>
                <LSContentColumn><Color color={color}/></LSContentColumn>
                <LSContentColumn>{speed} км/ч</LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setModalOpen(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setConfirmOpen(true),
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {confirmOpen && (
                <ConfirmDelete
                    onClose={() => setConfirmOpen(false)}
                    onSuccess={onAccept}
                    open={confirmOpen}
                />
            )}

            {modalOpen && (
                <ModalForm
                    onClose={() => setModalOpen(false)}
                    isOpen={modalOpen}
                    data={data}
                    colorProvider={colorProvider}
                    onChanged={onChanged}
                />
            )}
        </>
    );
}

export default Item;
