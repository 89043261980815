import { useEffect, useMemo, useState } from 'react';
import {
    CircleMarker,
    GeoJsonNew,
    ToolTip,
} from '../../../../MapComponents/leaflet';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import { getTimeWithoutSeconds } from '../../../../../helpers/date.config';
import messages from '../../../../../helpers/constants/messages';


const Stations = (props) => {
    const {
        id,
        entity_list = [],
        isArea = false,
        ...params
    } = props;

    const dispatch = useDispatch();
    const routeCheckpoints = useSelector(transportPassengerSelectors.routeCheckpoints);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (id) {
            if (routeCheckpoints[id]) {
                setData(routeCheckpoints[id]);
            } else {
                dispatch(actions.loadRouteCheckpoints(id));
            }
        }
    }, [id, routeCheckpoints]);

    // фильтруем что отображаем по остановкам
    const currentStations = useMemo(() => {
        return entity_list.reduce((r, station) => {
            const { entity_type, entity_id } = station;
            const isStation = entity_type === 'App\\Models\\Station';

            // область остановки
            // мб потом бэк сможет прокинуть в оставновки
            const stationFind = data
                ?.find(({ station_id }) => station_id === entity_id)
                || null;

            const {
                station: currentStation
            } = stationFind || {};
            // todo несовпадение координат в entity_list и в routeCheckpoints
            const {
                lat,
                lon,
                geometry,
            } = currentStation?.check_point || {};
            const name = currentStation?.name || '';

            if (
                lat && lon
                && isStation
                && entity_id
            ) {
                r.push({
                    ...station,
                    lat,
                    lon,
                    name,
                    geometry,
                });
            }
            return r;
        }, []);
    }, [entity_list, data]);

    const areas = useMemo(() => {
        if (!isArea) return null;

        return currentStations
            ?.filter(({ geometry }) => geometry)
            ?.map(({ geometry }) => geometry);
    }, [currentStations, isArea]);

    return (
        <>
            {areas && (
                <GeoJsonNew
                    {...props}
                    data={areas}
                    style={{
                        color: '#02A600'
                    }}
                />
            )}

            {currentStations.map((item) => {
                const {
                    lat,
                    lon,
                    entity_id,
                    name,
                    scheduled_time_start,
                } = item || {};

                return (
                    <CircleMarker
                        {...params}
                        key={`circlemarker_${entity_id}`}
                        latlng={[lat, lon]}
                        radius={12}
                        weight={1}
                        color={'#00442C'}
                        fillColor={'#02A600'}
                        fillOpacity={1}
                    >
                        {scheduled_time_start
                            ? (
                                <ToolTip
                                    offset={[13, 0]}
                                    direction="right"
                                    permanent={true}
                                    opacity={'0.7'}
                                    className={'tooltipBorderGreen'}
                                >
                                    <strong>{getTimeWithoutSeconds(scheduled_time_start) || messages.NO_DATA}</strong>
                                </ToolTip>
                            )
                            : <></>
                        }
                        <CircleMarker
                            latlng={[lat, lon]}
                            radius={6}
                            weight={1}
                            color={'#00442C'}
                            fillColor="#fff"
                            fillOpacity={1}
                        >
                            <ToolTip
                                offset={[0, -5]}
                                direction="top"
                            >
                                <div>Остановка: {name}</div>
                            </ToolTip>
                        </CircleMarker>
                    </CircleMarker>
                );
            })}
        </>
    );
};

export default Stations;
