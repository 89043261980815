import L from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { usePrevious } from '../../../../helpers/hooks';
import 'leaflet-polylinedecorator';
import renderChild from './helper/renderChild';

// линия
const PolyLine = ({
    map,
    area = null,
    parent,
    coordinates,
    style,
    children,
    attribution = null,
    onInit = null,
    onClick = null,
    moveToAfterInit = false,
    parentType = '',
    decoratorOption = null,
    ...prop
}) => {

    const prevCoordinates = usePrevious(coordinates);
    const prevStyle = usePrevious(style);
    const refDecorator = useRef(null);
    // const prevAttribution = usePrevious(attribution);

    const styleDefault = {
        color: '#d4a323',
        weight: 10,
        opacity: 0.8
    };

    const [current, setCurrent] = useState(null);

    const handleClick = () => {
        onClick(current.getCenter());
    };

    useEffect(() => {
        const newCurrent = L
            .polyline(
                coordinates,
                {
                    ...styleDefault,
                    ...style,
                    attribution
                }
            );

        setCurrent(newCurrent);

        if (area && parentType === 'polyline') {
            parent?.addEventParent(newCurrent);
            area?.addLayer(newCurrent);
        } else {
            parent?.addLayer(newCurrent);
        }

        onInit && onInit(newCurrent);

        // сдвинуть карту
        if (moveToAfterInit) {
            map.fitBounds(newCurrent.getBounds());
        }

        return () => {
            if (area && parentType === 'polyline') {
                area?.removeLayer(newCurrent);
            } else {
                parent?.removeLayer(newCurrent);
            }
            refDecorator.current && map.removeLayer(refDecorator.current);

            newCurrent.remove();
            setCurrent(null);
        };
    }, [parent]);

    useEffect(() => {
        if (onClick && current) {
            current.on('click', handleClick);

            return () => {
                current.off('click', handleClick);
                // map.removeLayer(current);
            };
        }
    }, [onClick, current]);

    // поменять координаты
    useEffect(() => {
        if (current && !isEqual(prevCoordinates, coordinates)) {
            current && current.setLatLngs(coordinates);
        }
    }, [coordinates, current]);

    // поменять стили
    useEffect(() => {
        if (current && !isEqual(prevStyle, style)) {
            current && current.setStyle(style);
        }
    }, [style, current]);

    // useEffect(() => {
    //     if (current && !isEqual(prevAttribution, attribution)) {
    //         current.setAttribution(attribution);
    //     }
    // }, [attribution, current]);

    useEffect(() => {
        if (decoratorOption && map && current) {
            const polylineArrow = new L.polylineDecorator(current, decoratorOption(style.color));
            polylineArrow.addTo(map);

            refDecorator.current = polylineArrow;

            return () => {
                if (polylineArrow) {
                    map.removeLayer(polylineArrow);
                    refDecorator.current = null;
                }
            };
        }
    }, [decoratorOption, map, current]);

    return current
        ? renderChild(
            children,
            {
                ...prop,
                map,
                parent: current,
                parentType: 'polyline',
                area: parent
            }
        )
        : null;
};

export default PolyLine;
