import * as types from './types';

/**
 * Actions
 **/

// настройки
export const loadSettings = () => ({
    type: types.LOAD_SETTINGS,
    payload: {}
});
export const loadedSettings = (data) => ({
    type: types.LOADED_SETTINGS,
    payload: data
});

export const loadingSettings = (bool = false) => ({
    type: types.LOADING_SETTINGS,
    payload: bool
});

export const editSetting = (id, data) => ({
    type: types.EDIT_SETTINGS,
    payload: {
        id, data
    }
});

export const editSettingsList = (data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_LIST,
    payload: data,
    callback
});

export const loadGroupGraph = () => ({
    type: types.LOAD_GROUP_GRAPH,
    payload: {}
});
export const loadedGroupGraph = (data) => ({
    type: types.LOADED_GROUP_GRAPH,
    payload: data
});
export const clearGroupGraph = (data) => ({
    type: types.CLEAR_GROUP_GRAPH,
});

export const loadingGroupGraph = (bool = false) => ({
    type: types.LOADING_GROUP_GRAPH,
    payload: bool
});

export const loadOneGraph = (url = '', params = {}) => ({
    type: types.LOAD_ONE_GRAPH,
    payload: {
        url,
        ...params
    }
});
export const loadedOneGraph = (data) => ({
    type: types.LOADED_ONE_GRAPH,
    payload: data
});
export const clearhOneGraph = () => ({
    type: types.CLEAR_ONE_GRAPH,
});

export const loadingOneGraph = (bool = false) => ({
    type: types.LOADING_ONE_GRAPH,
    payload: bool
});

// dashboard
export const loadDashboard = (props = {}) => ({
    type: types.LOAD_DASHBOARD,
    payload: props
});
export const loadedDashboard = (data) => ({
    type: types.LOADED_DASHBOARD,
    payload: data
});
export const clearDashboard = () => ({
    type: types.CLEAR_DASHBOARD,
});

export const loadingDashboard = (bool = false) => ({
    type: types.LOADING_DASHBOARD,
    payload: bool
});

export const loadEfficiencyDtp = (props = {}) => ({
    type: types.LOAD_EFFICIENCY_DTP,
    payload: props
});
export const loadedEfficiencyDtp = (data) => ({
    type: types.LOADED_EFFICIENCY_DTP,
    payload: data
});
export const clearEfficiencyDtp = () => ({
    type: types.CLEAR_EFFICIENCY_DTP,
});

export const loadingEfficiencyDtp = (bool = false) => ({
    type: types.LOADING_EFFICIENCY_DTP,
    payload: bool
});
export const setFilterEfficiencyDtp = (filter = {}) => ({
    type: types.SET_FILTER_EFFICIENCY_DTP,
    payload: filter
});
export const clearFilterEfficiencyDtp = () => ({
    type: types.SET_FILTER_EFFICIENCY_DTP,
    payload: {}
});

// страница все графики
export const loadGraphList = (props = {}) => ({
    type: types.LOAD_GRAPH_LIST,
    payload: props
});
export const loadingGraphList = (bool = false) => ({
    type: types.LOADING_GRAPH_LIST,
    payload: bool
});
export const loadedGraphList = (data) => ({
    type: types.LOADED_GRAPH_LIST,
    payload: data
});


export const loadGraphPeriodList = (props = {}) => ({
    type: types.LOAD_GRAPH_PERIOD_LIST,
    payload: props
});
export const loadingGraphPeriodList = (bool = false) => ({
    type: types.LOADING_GRAPH_PERIOD_LIST,
    payload: bool
});
export const loadedGraphPeriodList = (data) => ({
    type: types.LOADED_GRAPH_PERIOD_LIST,
    payload: data
});

//
export const loadEfficiencyTraffic = (props = {}) => ({
    type: types.LOAD_EFFICIENCY_TRAFFIC,
    payload: props
});
export const loadingEfficiencyTraffic = (bool = false) => ({
    type: types.LOADING_EFFICIENCY_TRAFFIC,
    payload: bool
});
export const loadedEfficiencyTraffic = (data) => ({
    type: types.LOADED_EFFICIENCY_TRAFFIC,
    payload: data
});

//user dashboard
export const loadUserAvailableDashboardList = (props = {}) => ({
    type: types.LOAD_USER_AVAILABLE_DASHBOARD_LIST,
    payload: props
});
export const loadedUserAvailableDashboardList = (data) => ({
    type: types.LOADED_USER_AVAILABLE_DASHBOARD_LIST,
    payload: data
});
export const loadingUserAvailableDashboardList = (bool = false) => ({
    type: types.LOADING_USER_AVAILABLE_DASHBOARD_LIST,
    payload: bool
});

export const loadUserDashboard = (props = {}) => ({
    type: types.LOAD_USER_DASHBOARD,
    payload: props
});
export const loadedUserDashboard = (data) => ({
    type: types.LOADED_USER_DASHBOARD,
    payload: data
});
export const loadingUserDashboard = (bool = false) => ({
    type: types.LOADING_USER_DASHBOARD,
    payload: bool
});

export const editUserDashboard = (data, callback = () => {}) => ({
    type: types.EDIT_USER_DASHBOARD,
    payload: { data },
    callback
});

// traffic forecast
export const loadTrafficForecast = (params) => ({
    type: types.LOAD_TRAFFIC_FORECAST,
    payload: params
});
export const loadedTrafficForecast = (data) => ({
    type: types.LOADED_TRAFFIC_FORECAST,
    payload: data
});
export const loadingTrafficForecast = (bool = false) => ({
    type: types.LOADING_TRAFFIC_FORECAST,
    payload: bool
});

export const loadCriticalSettings = (page = 1, limit = 25, params) => ({
    type: types.LOAD_CRITICAL_SETTINGS,
    payload: { page, limit, params }
});

export const loadedCriticalSettings = (response) => ({
    type: types.LOADED_CRITICAL_SETTINGS,
    payload: response
});

export const loadingCriticalSettings = (bool = false) => ({
    type: types.LOADING_CRITICAL_SETTINGS,
    payload: bool
});

export const editCriticalSetting = (data, callback = () => {}) => ({
    type: types.EDIT_CRITICAL_SETTING,
    payload: data,
    callback
});

export const deleteCriticalSetting = (id, callback = () => {}) => ({
    type: types.DELETE_CRITICAL_SETTING,
    payload: { id },
    callback
});

export const createCriticalSetting = (data, callback = () => {}) => ({
    type: types.CREATE_CRITICAL_SETTING,
    payload: data,
    callback
});

export const loadCriticalSettingsKeys = () => ({
    type: types.LOAD_CRITICAL_SETTINGS_KEYS,
});

export const loadedCriticalSettingsKeys = (response) => ({
    type: types.LOADED_CRITICAL_SETTINGS_KEYS,
    payload: response
});

export const loadingButton = (bool = false) => ({
    type: types.LOADING_BUTTON,
    payload: bool,
});

export const loadReportsEfficiencyDtpReport = (start_date, end_date) => ({
    type: types.LOAD_REPORT_EFFICIENCY_DTP_REPORT,
    payload: {
        start_date,
        end_date,
    },
});
export const clearReportsEfficiencyDtpReport = () => ({
    type: types.LOADED_REPORT_EFFICIENCY_DTP_REPORT,
    payload: [],
});
export const loadedReportsEfficiencyDtpReport = (data) => ({
    type: types.LOADED_REPORT_EFFICIENCY_DTP_REPORT,
    payload: data,
});
export const loadingReportsEfficiencyDtpReport = (bool = false) => ({
    type: types.LOADING_REPORT_EFFICIENCY_DTP_REPORT,
    payload: bool,
});
export const setFilterReportsEfficiencyDtpReport = (filter = {}) => ({
    type: types.SET_FILTER_REPORT_EFFICIENCY_DTP_REPORT,
    payload: filter,
});

export const loadShowcase = () => ({
    type: types.LOAD_SHOWCASE,
});
export const loadingShowcase = (bool = false) => ({
    type: types.LOADING_SHOWCASE,
    payload: bool
});
export const loadedShowcase = (data) => ({
    type: types.LOADED_SHOWCASE,
    payload: data
});
export const clearShowcase = () => ({
    type: types.LOADED_SHOWCASE,
    payload: {}
});

// Settings Group
export const loadSettingsGroup = () => ({
    type: types.LOAD_SETTINGS_GROUP,
});

export const loadingSettingsGroup = (bool) => ({
    type: types.LOADING_SETTINGS_GROUP,
    payload: bool
});

export const loadedSettingsGroup = (data) => ({
    type: types.LOADED_SETTINGS_GROUP,
    payload: data
});

export const editSettingsGroup = (id, data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_GROUP,
    payload: { id, data },
    callback
});

export const loadItsDevelopmentLevels = (page, limit, filter) => ({
    type: types.LOAD_ITS_DEVELOPMENT_LEVELS,
    payload: {
        page,
        limit,
        ...filter
    }
});
export const loadedItsDevelopmentLevels = (data) => ({
    type: types.LOADED_ITS_DEVELOPMENT_LEVELS,
    payload: data
});
export const loadingItsDevelopmentLevels = (bool = false) => ({
    type: types.LOADING_ITS_DEVELOPMENT_LEVELS,
    payload: bool
});
export const clearItsDevelopmentLevels = () => ({
    type: types.CLEAR_ITS_DEVELOPMENT_LEVELS
});

export const editItsDevelopmentLevels = (id, data, callback) => ({
    type: types.EDIT_ITS_DEVELOPMENT_LEVELS,
    payload: {
        id,
        data
    },
    callback
});


export const loadInfrastructureStatistics = (page, limit, filter) => ({
    type: types.LOAD_INFRASTRUCTURE_STATISTICS,
    payload: {
        page,
        limit,
        ...filter
    }
});
export const loadedInfrastructureStatistics = (data) => ({
    type: types.LOADED_INFRASTRUCTURE_STATISTICS,
    payload: data
});
export const loadingInfrastructureStatistics = (bool = false) => ({
    type: types.LOADING_INFRASTRUCTURE_STATISTICS,
    payload: bool
});
export const clearInfrastructureStatistics = () => ({
    type: types.CLEAR_INFRASTRUCTURE_STATISTICS
});

export const editInfrastructureStatistics = (id, data, callback) => ({
    type: types.EDIT_INFRASTRUCTURE_STATISTICS,
    payload: {
        id,
        data
    },
    callback
});

export const loadDashboards = (filter) => ({
    type: types.LOAD_DASHBOARDS,
    payload: filter
});
export const loadedDashboards = (data) => ({
    type: types.LOADED_DASHBOARDS,
    payload: data
});

export const loadDashboardsItem = (filter, callback) => ({
    type: types.LOAD_DASHBOARDS_ITEM,
    payload: filter,
    callback
});
export const loadedDashboardsItem = (data) => ({
    type: types.LOADED_DASHBOARDS_ITEM,
    payload: data
});
export const loadingDashboards = (bool = false) => ({
    type: types.LOADING_DASHBOARDS,
    payload: bool
});


export const loadDashboardItemUser = (filter, callback) => ({
    type: types.LOAD_DASHBOARD_ITEM_USER,
    payload: filter,
    callback
});
export const loadedDashboardItemUser = (data) => ({
    type: types.LOADED_DASHBOARD_ITEM_USER,
    payload: data
});
export const clearDashboardItemUser = () => ({
    type: types.CLEAR_DASHBOARD_ITEM_USER,
});
export const createDashboardItemUser = (data, callback) => ({
    type: types.CREATE_DASHBOARD_ITEM_USER,
    payload: data,
    callback
});
export const editDashboardItemUser = (id, data, callback) => ({
    type: types.EDIT_DASHBOARD_ITEM_USER,
    payload: {
        id,
        data
    },
    callback
});

export const editDashboardItemUserMultiply = (data, callback) => ({
    type: types.EDIT_DASHBOARD_ITEM_USER_MULTIPLY,
    payload: data,
    callback
});
export const deleteDashboardItemUser = (id, callback) => ({
    type: types.DELETE_DASHBOARD_ITEM_USER,
    payload: id,
    callback
});
export const loadingDashboardItemUser = (bool = false) => ({
    type: types.LOADING_DASHBOARD_ITEM_USER,
    payload: bool
});
