import style from './notification.module.scss';
import cn from 'classnames';
// import format from '../../../helpers/constants/dateFormat';
import { fullDateTimeWithoutSeconds } from '../../../helpers/date.config';
import { IconButton, Tooltip } from '@mui/material';
import { showMessage } from '../../../redux/Message/actions';
import messagetypes from '../../../redux/Message/messagetypes';
import useClipboard from 'react-use-clipboard';


const NotifyItem = (props) => {
    const {
        item,
        onClickItem = () => {},
        onClickItemRead = () => {},
    } = props;

    const {
        id,
        createdAt,
        viewed = false,
        // microservice = '',
        microservice_text = '',
        // всплывающее сообщение
        showAlert = {},
    } = item;
    const {
        title = '', // заголовок
        message = '', // сообщение (+ html)
    } = showAlert;

    const [isCopied, setCopied] = useClipboard(title || message, {
        successDuration: 6000,
    });

    const handleClickItem = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClickItem(item);
    };

    const handleClickItemRead = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClickItemRead(id);
    };

    const handleCopyToClipboard = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setCopied();
        showMessage(messagetypes.success, 'Текст скопирован в буфер обмена');
    };

    return (
        <span
            className={style.notify__card}
            onClick={handleClickItem}
        >
            <span className={style.notify__card__field}>
                <span
                    className={cn(style.notify__card__field__mark, {
                        [style.active]: viewed === false,
                    })}
                    onClick={handleClickItemRead}
                />
                <span className={style.notify__card__field__date}>
                    {createdAt && fullDateTimeWithoutSeconds(createdAt)}
                </span>
            </span>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span
                    className={style.notify__card__message}
                    // onClick={handleClickItem}
                    dangerouslySetInnerHTML={{ __html: title || message }}
                >
                    {/*{title || message}*/}
                </span>
                <Tooltip title="Скопировать текст">
                    <IconButton
                        onClick={handleCopyToClipboard}
                        style={{ fontSize: 20, padding: 8 }}
                    >
                        {isCopied ? <i className="far fa-check"/> : <i className="far fa-copy"/>}
                    </IconButton>
                </Tooltip>
            </div>
            <span className={style.notify__card__type}>
                {microservice_text}
            </span>
        </span>
    );
};

export default NotifyItem;
