import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../../../common/FormButtons';
import { config } from '../../../../../../../config';
import { useDispatch } from 'react-redux';
import {
    Map,
    FeatureGroup,
    MapControl,
} from '../../../../../../MapComponents/leaflet';
import Layer from '../../../../../../../components/MapComponents/Layers/RoadworkTransport';
import { setFilters, clearFilters, setActive, clearActive } from '../../../../../../../redux/TransportRoad/actions';


const VehiclesOnMapModal = ({ vehicle, open, onClose }) => {
    const dispatch = useDispatch();
    const mapCenter = config.get('mapCenter');
    const [mapZoom, setMapZoom] = useState(null);

    useEffect(() => {
        dispatch(setFilters({ query: vehicle?.number }));

        console.log(vehicle);
        setTimeout(() => {
            dispatch(setActive({ ...vehicle }));
        }, 2000);

        return () => {
            dispatch(clearFilters());
            dispatch(clearActive());
        };
     
    }, [vehicle]);

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            title={'Транспорт на карте'}
            buttons={
                <FormButtons
                    buttons={
                        [
                            {
                                ...buttonsTypes.close,
                                onClick: onClose
                            }
                        ]
                    }
                />
            }
        >
            <div className="map-form-wrap__container">
                <Map
                    center={mapCenter}
                    zoom={mapZoom}
                >
                    <MapControl>
                        <FeatureGroup>
                            <Layer
                                messageNotCurrentVehicle="За последние 15 минут телеметрия от данного ТС не поступала."
                                centeredByVehicle={true}
                            />
                        </FeatureGroup>
                    </MapControl>
                </Map>
            </div>
        </Modal>
    );
};

export default VehiclesOnMapModal;