import React, { useState, useEffect } from 'react';
import { getDateToFormat } from '../../../helpers/date.config';

export default function ReactLiveClock(props) {
    const {
        className,
        format = 'HH:mm',
        interval = 1000,
        style,
        timezone = null
    } = props;

    const [currentTime, setCurrentTime] = useState(Date.now());


    useEffect(() => {
        const tick = setInterval(() => {
            const now = Date.now();
            setCurrentTime(now);
        }, interval);

        return () => clearInterval(tick);

    }, [interval]);

    return (
        <time className={className} style={style}>
            {getDateToFormat(currentTime, format, timezone)}
        </time>
    );
}
