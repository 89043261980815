import { memo, useMemo } from 'react';

import messages from 'helpers/constants/messages';
import { getHumanDate } from 'helpers/date.config';
import { LSContentColumn, LSContentItem } from 'components/common/List';

import type { ItemPropTypes } from './types';

const Item = ({ item, category, index }: ItemPropTypes) => {
    const currentCategory = useMemo(() => {
        return category.data.find((el: { id: number; }) => el.id === item.category_id);
    }, [category.data, item.category_id]);

    return (
        <LSContentItem style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#f0f1fa' }}>
            <LSContentColumn>
                {getHumanDate(item.created_at) || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item.number || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {currentCategory ? currentCategory.name : messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item.model.name_ru || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item?.model.brand.name_ru || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item?.mileage || messages.NO_VALUE}
            </LSContentColumn>
        </LSContentItem>
    );
};

export default memo(Item);
