import moduleName from './module';

const prefix = `${moduleName}`;

/**
 * Constants
 * */

// map filter and active
export const SET_FILTER = `${prefix}/SET_FILTER`;
export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;

//sidebar
export const LOAD_SIDEBAR = `${prefix}/LOAD_SIDEBAR`;
export const LOADED_SIDEBAR = `${prefix}/LOADED_SIDEBAR`;
export const LOADING_SIDEBAR = `${prefix}/LOADING_SIDEBAR`;
export const CLEAR_SIDEBAR = `${prefix}/CLEAR_SIDEBAR`;

// VEHICLES
export const LOAD_VEHICLES = `${prefix}/LOAD_VEHICLES`;
export const LOADING_VEHICLES = `${prefix}/LOADING_VEHICLES`;
export const LOADED_VEHICLES = `${prefix}/LOADED_VEHICLES`;
export const CREATE_VEHICLE = `${prefix}/CREATE_VEHICLE`;
export const EDIT_VEHICLE = `${prefix}/EDIT_VEHICLE`;
export const DELETE_VEHICLE = `${prefix}/DELETE_VEHICLE`;
// export const GET_CURRENT_SPECIAL_REPORT = `${prefix}/GET/CURRENT/SPECIAL/REPORT`;

// VEHICLE CARD
export const LOAD_VEHICLE_CARD = `${prefix}/LOAD_VEHICLE_CARD`;
export const LOADING_VEHICLE_CARD = `${prefix}/LOADING_VEHICLE_CARD`;
export const LOADED_VEHICLE_CARD = `${prefix}/LOADED_VEHICLE_CARD`;
export const CLEAR_VEHICLE_CARD = `${prefix}/CLEAR_VEHICLE_CARD`;

// VEHICLE CATEGORIES
export const LOAD_VEHICLE_CATEGORIES = `${prefix}/LOAD_VEHICLE_CATEGORIES`;
export const LOADING_VEHICLE_CATEGORIES = `${prefix}/LOADING_VEHICLE_CATEGORIES`;
export const LOADED_VEHICLE_CATEGORIES = `${prefix}/LOADED_VEHICLE_CATEGORIES`;
export const CREATE_VEHICLE_CATEGORY = `${prefix}/VEHICLE/CATEGORY/CREATE`;
export const EDIT_VEHICLE_CATEGORY = `${prefix}/VEHICLE/CATEGORY/EDIT`;
export const DELETE_VEHICLE_CATEGORY = `${prefix}/VEHICLE/CATEGORY/DELETE`;

// VEHICLE CHARACTERISTICS
export const LOAD_VEHICLE_CHARACTERISTICS = `${prefix}/LOAD_VEHICLE_CHARACTERISTICS`;
export const LOADED_VEHICLE_CHARACTERISTICS = `${prefix}/LOADED_VEHICLE_CHARACTERISTICS`;

// BRANDS
export const LOAD_BRANDS = `${prefix}/LOAD_BRANDS`;
export const LOADING_BRANDS = `${prefix}/LOADING_BRANDS`;
export const LOADED_BRANDS = `${prefix}/LOADED_BRANDS`;
export const CREATE_BRAND = `${prefix}/CREATE_BRAND`;
export const EDIT_BRAND = `${prefix}/EDIT_BRAND`;
export const DELETE_BRAND = `${prefix}/DELETE_BRAND`;

// MODELS
export const LOAD_MODELS = `${prefix}/LOAD_MODELS`;
export const LOADING_MODELS = `${prefix}/LOADING_MODELS`;
export const LOADED_MODELS = `${prefix}/LOADED_MODELS`;
export const CREATE_MODEL = `${prefix}/CREATE_MODEL`;
export const EDIT_MODEL = `${prefix}/EDIT_MODEL`;
export const DELETE_MODEL = `${prefix}/DELETE_MODEL`;

// RADIO TYPES
export const LOAD_RADIO_TYPES = `${prefix}/LOAD_RADIO_TYPES`;
export const LOADING_RADIO_TYPES = `${prefix}/LOADING_RADIO_TYPES`;
export const LOADED_RADIO_TYPES = `${prefix}/LOADED_RADIO_TYPES`;

// VEHICLE TYPES
export const LOAD_VEHICLE_TYPES = `${prefix}/LOAD_VEHICLE_TYPES`;
export const LOADING_VEHICLE_TYPES = `${prefix}/LOADING_VEHICLE_TYPES`;
export const LOADED_VEHICLE_TYPES = `${prefix}/LOADED_VEHICLE_TYPES`;

// load coords
export const LOADED_COORDS = `${prefix}/LOADED_COORDS`;
export const LOADING_COORDS = `${prefix}/LOADING_COORDS`;
export const CLEAR_COORDS = `${prefix}/CLEAR_COORDS`;
export const LOAD_COORDS = `${prefix}/LOAD_COORDS`;

// types
export const SET_ROUTES_VEHICLE = `${prefix}/SET_ROUTES_VEHICLE`;
export const CLEAR_ROUTES_VEHICLE = `${prefix}/CLEAR_ROUTES_VEHICLE`;
export const CLEAR_ROUTES_VEHICLE_BY_ID = `${prefix}/CLEAR_ROUTES_VEHICLE_BY_ID`;

export const SET_MARKERS = `${prefix}/SET_MARKERS`;
export const SET_SIDEBAR_PARAMS = `${prefix}/SET_SIDEBAR_PARAMS`;

export const SET_WS_VEHICLE = `${prefix}/SET_WS_VEHICLE`;
export const SET_WS_TELEMETRY = `${prefix}/SET_WS_TELEMETRY`;
export const CLEAR_WS_TELEMETRY = `${prefix}/CLEAR_WS_TELEMETRY`;

export const GET_REPORT = `${prefix}/GET_REPORT`;

export const GET_FILTERED_LIST = `${prefix}/GET_FILTERED_LIST`;
export const LOADED_FILTERED_LIST = `${prefix}/LOADED_FILTERED_LIST`;
export const LOADING_FILTERED_LIST = `${prefix}/LOADING_FILTERED_LIST`;
export const SET_FILTERED_LIST_FILTER = `${prefix}/SET_FILTERED_LIST_FILTER`;
export const CLEAR_FILTERED_LIST_FILTER = `${prefix}/CLEAR_FILTERED_LIST_FILTER`;

export const LOAD_HISTORY_VEHICLE = `${prefix}/LOAD_HISTORY_VEHICLE`;
export const LOADED_HISTORY_VEHICLE = `${prefix}/LOADED_HISTORY_VEHICLE`;
export const LOADING_HISTORY_VEHICLE = `${prefix}/LOADING_HISTORY_VEHICLE`;
export const CLEAR_HISTORY_VEHICLE = `${prefix}/CLEAR_HISTORY_VEHICLE`;
/* color speed */
export const LOAD_COLOR_SPEED_LIST = `${prefix}LOAD_COLOR_SPEED_LIST`;
export const LOADED_COLOR_SPEED_LIST = `${prefix}LOADED_COLOR_SPEED_LIST`;
export const LOADING_COLOR_SPEED_LIST = `${prefix}LOADING_COLOR_SPEED_LIST`;
export const SET_COLOR_SPEED_LIST_PROPS = `${prefix}SET_COLOR_SPEED_LIST_PROPS`;
export const RESET_COLOR_SPEED_LIST_PROPS = `${prefix}RESET_COLOR_SPEED_LIST_PROPS`;
export const ADD_COLOR_SPEED = `${prefix}ADD_COLOR_SPEED`;
export const EDIT_COLOR_SPEED = `${prefix}EDIT_COLOR_SPEED`;
export const DELETE_COLOR_SPEED = `${prefix}DELETE_COLOR_SPEED`;

export const LOAD_VEHICLE_HISTORY_MODAL = `${prefix}LOAD_VEHICLE_HISTORY_MODAL`;
export const LOADED_VEHICLE_HISTORY_MODAL = `${prefix}LOADED_VEHICLE_HISTORY_MODAL`;
export const LOADING_VEHICLE_HISTORY_MODAL = `${prefix}LOADING_VEHICLE_HISTORY_MODAL`;

// export const LOAD_CATEGORIES_VEHICLE = `${prefix}LOAD_CATEGORIES_VEHICLE`;
// export const LOADED_CATEGORIES_VEHICLE = `${prefix}LOADED_CATEGORIES_VEHICLE`;

export const LOADING_BUTTON = `${prefix}LOADING_BUTTON`;

export const LOAD_COLOR_SPEED = `${prefix}LOAD_COLOR_SPEED`;
export const LOADED_COLOR_SPEED = `${prefix}LOADED_COLOR_SPEED`;

export const LOAD_TELEMETRY_RECEIVERS = `${prefix}LOAD_TELEMETRY_RECEIVERS`;
export const LOADED_TELEMETRY_RECEIVERS = `${prefix}LOADED_TELEMETRY_RECEIVERS`;
export const LOADING_TELEMETRY_RECEIVERS = `${prefix}LOADING_TELEMETRY_RECEIVERS`;
export const ADD_TELEMETRY_RECEIVER = `${prefix}ADD_TELEMETRY_RECEIVER`;
export const EDIT_TELEMETRY_RECEIVER = `${prefix}EDIT_TELEMETRY_RECEIVER`;
export const DELETE_TELEMETRY_RECEIVER = `${prefix}DELETE_TELEMETRY_RECEIVER`;

export const LOAD_TELEMETRY_RECEIVER_TYPES = `${prefix}LOAD_TELEMETRY_RECEIVER_TYPES`;
export const LOADED_TELEMETRY_RECEIVER_TYPES = `${prefix}LOADED_TELEMETRY_RECEIVER_TYPES`;

export const LOAD_VEHICLE_CATEGORY_LIST = `${prefix}LOAD_VEHICLE_CATEGORY_LIST`;
export const LOADED_VEHICLE_CATEGORY_LIST = `${prefix}LOADED_VEHICLE_CATEGORY_LIST`;
export const LOADING_VEHICLE_CATEGORY_LIST = `${prefix}LOADING_VEHICLE_CATEGORY_LIST`;

export const LOAD_REPORT_BY_VEHICLES = `${prefix}LOAD_REPORT_BY_VEHICLES`;
export const LOADED_REPORT_BY_VEHICLES = `${prefix}LOADED_REPORT_BY_VEHICLES`;
export const LOADING_REPORT_BY_VEHICLES = `${prefix}LOADING_REPORT_BY_VEHICLES`;

export const LOAD_EGTS_TRANSMITTER = `${prefix}/LOAD_EGTS_TRANSMITTER`;
export const ADD_EGTS_TRANSMITTER = `${prefix}/ADD_EGTS_TRANSMITTER`;
export const EDIT_EGTS_TRANSMITTER = `${prefix}/EDIT_EGTS_TRANSMITTER`;
export const DELETE_EGTS_TRANSMITTER = `${prefix}/DELETE_EGTS_TRANSMITTER`;
