import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    FormControl,
    TextField,
} from '@mui/material';

import {
    copyScheduleTemplate,
    createScheduleTemplate,
    editScheduleTemplate,
    loadScheduleTemplateStatuses,
} from 'redux/TransportPassenger/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import { getDateObjectFromFormat, getTimeWithoutSeconds, isDateValid } from 'helpers/date.config';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SelectRoutes from 'components/common/Autocomplete/PassengerTransport/Routes';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import StatusesSelect from 'components/common/Autocomplete/Statuses';

import { scheduleTemplatesURI } from './moduleConfig';

const test_id_prefix = scheduleTemplatesURI; // префикс для полей для автотестов

const STModal = ({
    onClose,
    isOpen,
    isNew,
    isCopy,
    el = {},
    selectedRoute,
    currentRoute,
    reloadList
}) => {
    const dispatch = useDispatch();
    const statuses = useStoreProp(
        loadScheduleTemplateStatuses,
        'transportPassenger',
        'scheduleTemplateStatuses'
    );
    const validation = useValidation();

    // const stationStopTime = el?.station_stop_time ? el?.station_stop_time?.split(':') : ['00', '00'];
    // const [hour, min] = stationStopTime;


    const initialState = {
        name: el?.name || '',
        status: isNew ? statuses[0] : statuses.find(elem => elem.id === el.status) || '',
        route_id: el?.route_id || '',
        route: (currentRoute?.id || !isNew) ? (el.route || currentRoute) : selectedRoute,
        station_stop_time: getDateObjectFromFormat(el?.station_stop_time || '00:01', 'HH:mm')
    };

    const [data, setData] = useState(initialState);

    const onChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleChangeRoute = (e) => setData({ ...data, 'route': e });

    const result = () => ({
        name: data?.name,
        status: data?.status.id,
        route_id: data?.route?.id,
        route: data?.route,
        station_stop_time: getTimeWithoutSeconds(data?.station_stop_time),
    });

    const callback = () => {
        reloadList();
        onClose();
    };

    const handleCreateScheduleTemplate = () => dispatch(createScheduleTemplate(result(), callback));
    const handleEditScheduleTemplate = () => dispatch(editScheduleTemplate(el, result(), callback));
    const handleCopyScheduleTemplate = () => dispatch(copyScheduleTemplate(el, result(), callback));

    const title = isNew ? titles.ADD_SCHEDULE_TEMPLATE : isCopy ? titles.COPY_SCHEDULE_TEMPLATE : titles.EDIT_SCHEDULE_TEMPLATE;
    const validationTime = isDateValid(data.station_stop_time)
        ? 'Укажите время в формате ЧЧ:ММ (часы:минуты)'
        : '';

    return (
        <Modal
            title={title}
            onClose={onClose}
            maxWidthProp={'sm'}
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose,
                        'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/buttons/cancel`
                    },
                    isNew || isCopy
                        ? {
                            ...buttonsTypes.create,
                            onClick: isCopy ? handleCopyScheduleTemplate : handleCreateScheduleTemplate,
                            disabled: !data.name || !data.station_stop_time || el.name === data.name,
                            'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/buttons/save`
                        }
                        : {
                            ...buttonsTypes.save,
                            onClick: handleEditScheduleTemplate,
                            'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/buttons/save`
                        }
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl
                    required
                    className="block"
                    size="small"
                    variant="outlined"
                >
                    <FormInfoWrapper
                        error={validation.isKey('name') || (isCopy && el.name === data.name)}
                        helperText={isCopy ? 'Для создания копии введите другое название' : validation.get('name')}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/name/input`}
                    >
                        <TextField
                            required
                            label={titles.NAME}
                            variant="outlined"
                            size="small"
                            value={data.name || ''}
                            name={'name'}
                            onChange={onChange}
                            type="text"
                            error={validation.isKey('name') || (isCopy && el.name === data.name)}
                            InputLabelProps={{
                                'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/name/label`
                            }}
                            inputProps={{
                                'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/name/input`
                            }}
                        />
                    </FormInfoWrapper>
                </FormControl>
                <StatusesSelect
                    selected={data.status}
                    onChange={(value) => setData({
                        ...data,
                        status: value
                    })}
                    test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : isCopy ? 'copy' : 'edit'}/status`}
                    label={titles.SELECT_STATUS}
                    options={statuses}
                    renderLabel={(el) => el.name}
                    error={validation.isKey('route_id')}
                    helperText={validation.get('route_id')}
                />
                <div className="block">
                    <FormInfoWrapper
                        error={validation.isKey('route_id')}
                        helperText={validation.get('route_id')}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/routes`}
                    >
                        <SelectRoutes
                            selected={data.route}
                            onChange={handleChangeRoute}
                            required={true}
                            disabled={(currentRoute.id && isNew) || isCopy}
                            filter={currentRoute?.id ? { num: currentRoute?.num, ...(!isCopy && { status: [2] }) } : {}}
                            error={validation.isKey('route_id')}
                            test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/routes`}
                        />
                    </FormInfoWrapper>
                </div>
                <SingleKeyboardDateTimePicker
                    label="Время на стоянки на остановках"
                    value={data.station_stop_time || null}
                    onChange={(value) => setData({ ...data, station_stop_time: value })}
                    timeOnly
                    required
                    helperText={validationTime}
                    error={validation.isKey('station_stop_time')}
                    test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/station_stop_time`}
                />
            </div>
        </Modal>
    );
};

export default STModal;
