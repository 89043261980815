import { useEffect } from 'react';
import {
    Grid,
    LinearProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import useShowcase from 'helpers/hooks/useShowcase';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { clearShowcase, loadShowcase } from 'redux/TransportPassenger/actions';
import layerConfig from 'components/MapComponents/Layers/TransportPassenger/config';
import Layer from 'components/MapComponents/Layers/TransportPassenger/layer';
import CardWidget from 'components/common/Showcase/CardWidget';
import TypeComponent from 'components/common/Showcase/TypeComponent';
import CardMap from 'components/common/Showcase/CardMap';

// страница
const Page = () => {
    const dispatch = useDispatch();
    const isShowcase = useShowcase();

    const showCase = useSelector(transportPassengerSelectors.showcase);
    const loadingShowcase = useSelector(transportPassengerSelectors.loadingShowcase);

    const {
        graph = [],
    } = showCase;
    const isGraph = graph?.length > 0;

    useEffect(() => {
        dispatch(loadShowcase());
        return () => dispatch(clearShowcase());
    }, []);

    return (
        <>
            <Grid container spacing={2} style={{ height: '100%' }}>
                {loadingShowcase && <LinearProgress/>}
                {isGraph && (
                    graph.map((item, index) => (
                        <Grid item xs={12} md={4} sm={6} key={index}>
                            <CardWidget
                                icon={layerConfig.layerIcon}
                                title={item.name}
                                marginBottom
                                small
                            >
                                <TypeComponent
                                    {...item}
                                />
                            </CardWidget>
                        </Grid>
                    ))
                )}

                <Grid
                    item
                    xs={12}
                    md={8}
                    sm={6}
                    className="showcase_grid_flex-column"
                >
                    <CardMap
                        fillOthers
                    >
                        <Layer
                            readOnly={isShowcase}
                            hideStationsPopup
                        />
                    </CardMap>
                </Grid>
            </Grid>
        </>
    );
};

export default Page;
