import { Paper } from '@mui/material';
import { Cluster, Map, MapControl } from '../../../MapComponents/leaflet';
import { config } from '../../../../config';
import { iconCreateFunctionSimple } from '../../../MapComponents/helpers/iconCreateFunction';
import CardWidget from '../CardWidget';
import React from 'react';

// оболочка для карты
const CardMap = ({
    isCluster = false,
    fillOthers,
    fill,
    children
}) => (
    <CardWidget
        icon="fas fa-map-marked-alt"
        title="На карте"
        marginBottom
        small
        noPadding
        fillOthers={fillOthers}
        fill={fill}
    >
        <Paper sx={{ height: '100%', minHeight: '300px', display: 'grid' }}>
            <Map
                center={config.get('mapCenter')}
                isHideUnidentifiedPlaces
            >
                <MapControl>
                    {isCluster
                        ? (
                            <Cluster iconCreateFunction={iconCreateFunctionSimple}>
                                {children}
                            </Cluster>
                        )
                        : children
                    }
                </MapControl>
            </Map>
        </Paper>
    </CardWidget>
);

export default CardMap;