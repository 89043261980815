import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CircularProgress,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import {
    clearComplexRules,
    createDocumentComplex,
    editComplexDocument,
    loadComplexRules,
    saveStatus
} from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import { fullDateTimeWithTimeZone, isDateBefore, isDateValid } from 'helpers/date.config';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import Attachments from 'components/common/Upload/Attachments';

import FgisField from './FgisField';

export const DocumentsForm = ({
    document_types,
    open,
    setOpenModal,
    item = {},
    complex_id,
    renderError = () => {},
    errorMessage,
    isNew = false,
    onAdded = () => {},
    onEdited = () => {},
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const saved = useSelector(dorisControlSelectors.saved);
    const complexRules = useSelector(dorisControlSelectors.complexRules);
    const loadingComplexRules = useSelector(dorisControlSelectors.loadingComplexRules);

    const initialState = {
        complex_id, //complex_id должен сохраняться
        document: '',
        number: '',
        type_id: '',
        date: null,
        author: '',
        validity: null,
        channel_type: '',
        data: {},
        filename: ''
    };

    const [data, setData] = useState({
        ...initialState,
        ...item,
    });

    const resetData = () => {
        setData(initialState);
        dispatch(saveStatus());
    };

    const onChange = (name, value, isData) => {
        if (isData) {
            // складываем данные в поле data
            return setData({
                ...data,
                data: {
                    ...data.data,
                    [name]: value
                }
            });
        }
        setData({
            ...data,
            [name]: value
        });
        validation.deleteKey(name);
    };

    const closeForm = () => {
        resetData();
        dispatch(clearComplexRules());
        setOpenModal(false);
    };

    const onAcceptModal = () => {
        const paramsWithoutEmpty = Object.entries(data).reduce((res, [ key, el ]) => {
            if (key === 'data' && Object.keys(el).length > 0) {
                res[key] = el;
            } else if (el && key !== 'data') {
                if (key === 'date' || key === 'validity') {
                    res[key] = fullDateTimeWithTimeZone(el);
                } else {
                    res[key] = el;
                }
            }
            return res;
        }, {});
        isNew
            ? dispatch(createDocumentComplex(paramsWithoutEmpty, () => { onAdded && onAdded(); closeForm(); }))
            : dispatch(editComplexDocument(item.id, paramsWithoutEmpty, () => { onEdited && onEdited(); closeForm(); }));
    };

    //убираем колесо прокрутки у поля 'Номер документа'
    const textFieldRef = React.useRef(null);

    useEffect(() => {
        const handleWheel = e => e.preventDefault();
        if (textFieldRef.current) {
            textFieldRef?.current?.addEventListener('wheel', handleWheel);
        }
        dispatch(saveStatus());

        return () => {
            textFieldRef?.current?.removeEventListener('wheel', handleWheel);
        };
    }, []);

    useEffect(() => {
        if (saved) {
            dispatch(saveStatus());
            closeForm();
        }
    }, [saved]);

    //блок проверки дат
    const isValidityBeforeDate = data.validity && data.date && isDateBefore(data.validity, data.date);
    const checkError = (date) => {
        return renderError() || !isDateValid(date) || isValidityBeforeDate;
    };

    const onlyRequire = complexRules.filter(item => item.require === true);

    const disabledCreateDocument = onlyRequire.map(item => !!data[item.field] || !!data.data[item.field])
        .includes(false) || !data.type_id;

    useEffect(() => {
        validation.clear();
        if (data.type_id) {
            dispatch(loadComplexRules(data.type_id));
        }
    },[data.type_id]);

    const changeDocumentType = (_, value) => {
        resetData();
        dispatch(clearComplexRules());
        onChange('type_id', value?.props?.value || '');
    };

    const uploadDoc = complexRules?.find(({ field }) => field === 'document');

    // замещение данными из fgis
    const onChangeFgisFields = (dataObj) => {
        const keys = Object.keys(dataObj);
        // собираем данные где должны быть
        setData(old => keys
            .reduce((r, name) => {
                const value = dataObj[name];
                const isData = complexRules
                    ?.find(({ field }) => field === name)
                    ?.data || false;

                return isData
                    ? {
                        ...r,
                        data: {
                            ...r.data,
                            [name]: value,
                        },
                    }
                    : {
                        ...r,
                        [name]: value
                    };
            }, old)
        );

        validation.deleteKeys(keys);
    };

    const renderFields = (element) => {
        if(element.field === 'type_id' || element.field === 'complex_id' || element.field === 'document') {
            return;
        } else if (element.type === 'date') {
            const val = element.data
                ? data.data[element.field]
                : data[element.field];
            return (
                <div className="block" key={element.field}>
                    <SingleKeyboardDateTimePicker
                        required={element?.require}
                        disableFuture={element.field !== 'validity'}
                        onChange={date => onChange(element.field, date, element.data)}
                        value={val}
                        pickerFormat="dd.MM.yyyy"
                        label={element.name}
                        dateOnly={true}
                        error={isDateValid(val) && isValidityBeforeDate}
                        helperText={isValidityBeforeDate && 'Дата окончания действия документа должна быть позже Даты ввода в эксплуатацию'}
                    />
                </div>
            );
        } if(element.field === 'link_fgis') {
            return (
                <FgisField
                    label={element.name}
                    size="small"
                    value={element.data
                        ? data.data[element.field] || ''
                        : data[element.field] || ''
                    }
                    variant="outlined"
                    name={element.field}
                    type={element.value}
                    onChange={data => onChange(element.field, data, element.data)}
                    onChangeFields={onChangeFgisFields}
                    required={element?.require}
                />
            );
        }
        return (
            <FormControl
                key={element.field}
                className="block"
                variant="outlined"
                size="small"
            >
                {element?.select
                    ? (<>
                        <InputLabel required={element?.require} >{element.name}</InputLabel>
                        <Select
                            value={element.data
                                ? data.data[element.field] || ''
                                : data[element.field] || ''
                            }
                            required={element?.require}
                            label={element.name}
                            onChange={(e) => onChange(element.field, e.target.value, element.data)}
                            name={element.field}
                        >
                            {element?.select.map(item => {
                                return <MenuItem value={item} key={item}>{item}</MenuItem>;
                            })}
                        </Select>
                        {validation.isKey(element.field)
                            && (<FormHelperText error>{validation.get(element.field)}</FormHelperText>)
                        }
                    </>)
                    : (
                        <TextField
                            label={element.name}
                            size="small"
                            value={element.data
                                ? data.data[element.field] || ''
                                : data[element.field] || ''
                            }
                            variant="outlined"
                            name={element.field}
                            type={element.value}
                            onChange={(e) => onChange(e.target.name, e.target.value, element.data)}
                            required={element?.require}
                        /> )
                }
            </FormControl>
        );
    };

    return (
        <Modal
            isOpen={open}
            onClose={closeForm}
            noPadding
            title={isNew ? titles.CREATE_DOCUMENT : titles.EDIT_DOCUMENT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: closeForm
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: disabledCreateDocument
                    },
                ]}
            />}
        >
            <form autoComplete="off" className="modal__form">
                <FormControl className="block" size="small" required variant="outlined">
                    <InputLabel>Тип документа</InputLabel>
                    <Select
                        value={data.type_id}
                        required
                        label="Тип документа"
                        onChange={changeDocumentType}
                        name="type_id"
                        error={!data.type_id || validation.isKey('type_id')}
                        disabled={!isNew}
                    >
                        {Object.keys(document_types)?.length
                            ? Object.keys(document_types)?.map((key) =>
                                <MenuItem value={key} key={key}>{document_types[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                    {validation.isKey('type_id')
                        ? <FormHelperText error>{validation.get('type_id')}</FormHelperText>
                        : null
                    }
                </FormControl>
                {loadingComplexRules && complexRules.length > 0
                    ? <CircularProgress />
                    : complexRules.map(rule => renderFields(rule))
                }
                {renderError()
                        && <small className="error" style={{ whiteSpace: 'nowrap' }}>
                            {errorMessage() || ''}
                        </small>
                }
                {uploadDoc
                    && <>
                        <Attachments
                            label={uploadDoc.name}
                            files={data.document ? [{ public_link: data.document, name: data.filename }] : []}
                            onChange={(url) => {
                                setData({
                                    ...data,
                                    document: url?.public_link,
                                    filename: url?.name
                                });
                            }}
                            singleFile
                            isRequired={uploadDoc.require}
                        />
                        {validation.isKey('document')
                            && (<FormHelperText error>{validation.get('document')}</FormHelperText>)
                        }
                    </>
                }
            </form>
        </Modal>
    );
};
