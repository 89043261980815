import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, FormControl, OutlinedInput } from '@mui/material/';
import { loadDtpSettings, editDtpSetting } from '../../../../redux/Incidents/actions';
import './style.scss';
import messages from '../../../../helpers/constants/messages';
import FormButtonsComponent, { buttonsTypes } from '../../../common/FormButtons';
import { incidentsSelectors } from '../../../../redux/Incidents';
import { authSelectors } from '../../../../redux/Auth';

const DtpSettings = () => {
    const dispatch = useDispatch();
    const settings = useSelector(incidentsSelectors.settings);
    const code = useSelector(authSelectors.projectCode);
    const [currentData, setCurrentData] = useState({});

    const handleChangeValue = (e) => setCurrentData({ ...currentData, value: Number.parseInt(e.target.value) });

    useEffect(() => {
        dispatch(loadDtpSettings());
    }, [dispatch]);

    useEffect(() => {
        if (settings.length > 0) {
            setCurrentData(settings.find(({ project, name }) => project === code && name === 'period'));
        }
    }, [settings, code]);

    const handleSend = (e) => {
        e.preventDefault();
        dispatch(editDtpSetting(currentData.id, { value: currentData.value }));
    };

    const handleCancel = (e) => {
        e.preventDefault();
        setCurrentData(settings.find(({ project, name }) => project === code && name === 'period'));
    };

    if (Object.keys(currentData).length === 0) {
        return (
            <div className="settings">
                <div className="form">
                    <div>
                        <h2>{messages.NO_DATA}</h2>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="settings">
            <div className="form">
                <div>
                    <h2>Период, за который показываются ДТП</h2>

                    <FormGroup>
                        <FormControl className="mt-1 my-sm-3" variant="outlined">
                            <OutlinedInput
                                type="text"
                                value={currentData?.value || 0}
                                onChange={handleChangeValue}
                                disabled={currentData?.is_visible !== true}
                            />
                        </FormControl>
                        <FormControl className="mt-1 my-sm-3" variant="outlined">
                            <OutlinedInput
                                type="text"
                                value={currentData?.measurement || ''}
                                onChange={() => {}}
                                disabled={true}
                            />
                        </FormControl>
                    </FormGroup>
                    <div className="buttons">
                        <FormButtonsComponent
                            noPadding
                            buttons={[
                                {
                                    ...buttonsTypes.cancel,
                                    onClick: handleCancel,
                                    size: 'small'
                                },
                                {
                                    ...buttonsTypes.save,
                                    onClick: handleSend,
                                    size: 'small'
                                }
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DtpSettings;
