import { useDispatch } from 'react-redux';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import * as actions from '../../../../../redux/TrafficFlow/actions';
import DateRange from '../../../../common/Dates/DateRange';
import dateFormat from '../../../../../helpers/constants/dateFormat';
import TrafficFlow from '../../../../common/Autocomplete/TrafficFlow';
import titles from '../../../../../helpers/constants/titles';
import { useStoreProp } from '../../../../../helpers/hooks';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';
import messages from '../../../../../helpers/constants/messages';

const Filters = ({
    onFilter,
    disableRequestBtn,
    formValues,
    setFormValues,
    initialState
}) => {
    const dispatch = useDispatch();

    const types = useStoreProp(actions.loadTypes, 'trafficFlow', 'types');

    const reportGroups = [
        {
            value: false,
            description: 'По полосам'
        },
        {
            value: true,
            description: 'Сводный'
        }
    ];

    const handleChangeSelects = (e) => {
        const { value, name } = e.target;
        setFormValues(old => ({ ...old, [name]: value }));
    };

    const handleChangeStartDate = (value) => {
        setFormValues({
            ...formValues,
            start_date: value,
        });
    };

    const handleChangeEndDate = (value) => setFormValues({ ...formValues, end_date: value });

    const handleChangePuid = (values) => {
        setFormValues({
            ...formValues,
            id_list: values,
        });
    };

    const resetFilters = (needRefresh) => {
        setFormValues(initialState);
        if (needRefresh) {
            dispatch(actions.clearReportIndicators());
        }
    };

    const updateFilter = (filters) => {
        const params = {
            ...initialState,
            ...filters,
        };

        setFormValues(params);
        if (Object.keys(filters).length > 0 && filters?.id_list?.length > 0) {
            onFilter(params);
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={onFilter}
            filter={formValues}
            filterException={['start_date', 'end_date']}
            setUserFilter={updateFilter}
            disabled={disableRequestBtn}
            isFilterVisible
        >
            <LayoutFilter.Visible>
                <DateRange
                    pickersFormat={dateFormat.DATE_WITH_HOUR_MIN_SECONDS}
                    views={['day', 'hours', 'minutes', 'seconds']}
                    valueStartDate={formValues.start_date}
                    valueEndDate={formValues.end_date}
                    handleDate={handleChangeStartDate}
                    handleEndDate={handleChangeEndDate}
                    // renderError={() => renderError()?.error}
                    // errorMessage={() => renderError()?.message}
                />
                <FormControl size="small" variant="outlined">
                    <InputLabel>Тип ПУИД</InputLabel>
                    <Select
                        value={formValues?.puid_type || ''}
                        onChange={(e) => {
                            setFormValues({
                                ...formValues,
                                puid_type: e.target.value,
                                id_list: [],
                            });
                        }}
                        label="Тип ПУИД"
                    >
                        <MenuItem value="">{titles.NOT_CHOSEN}</MenuItem>
                        {Object.keys(types).map((index) => (
                            <MenuItem key={index} value={index}>{types[index]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TrafficFlow
                    typeId={formValues?.puid_type || null}
                    multiple
                    selected={formValues?.id_list || []}
                    onChange={handleChangePuid}
                />
                <FormControl variant="outlined" size="small">
                    <InputLabel>Вид отчета</InputLabel>
                    <Select
                        label="Вид отчета"
                        name="is_consolidate"
                        value={formValues?.is_consolidate || false}
                        onChange={handleChangeSelects}
                    >
                        {reportGroups?.length > 0
                            ? reportGroups.map((item, index) =>
                                <MenuItem key={index} value={item.value}>
                                    {item.description}
                                </MenuItem>
                            )
                            : <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;
