import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, IconButton, InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import UniversalSelect from '../../../../../../common/UniversalSelect';
import { loadExclusiveFiles } from '../../../../../../../redux/DorisControl/actions';
import _ from 'lodash';

const useStyles = makeStyles({
    input: {
        paddingRight: '60px !important',
    },
    adornment: {
        position: 'absolute',
        right: '6px',
    },
});

const SelectFiles = ({ selected, onChange }) => {
    const dispatch = useDispatch();
    const styles = useStyles();

    const prevData = useRef(selected);

    const [selectedItems, setSelected] = useState(selected);
    const [showList, setShowList] = useState(false);

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    useEffect(() => {
        // например сброс фильтра - selected изменился нужно обновить selectedItems
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    return (
        <>
            <Autocomplete
                multiple
                value={selected}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={[]}
                size="small"
                onChange={(e, value) => onChange(value)}
                getOptionLabel={(option) => option?.file_name || ''}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        label="Файл"
                        InputProps={{
                            ...params.InputProps,
                            classes: { root: styles.input },
                            endAdornment: (
                                <InputAdornment
                                    position={'end'}
                                    classes={{ root: styles.adornment }}
                                >
                                    {selected && Object.keys(selected).length > 0 && (
                                        <IconButton size="small" onClick={() => onChange([])}>
                                            <i className="far fa-times" />
                                        </IconButton>
                                    )}
                                    <IconButton size="small" onClick={() => setShowList(true)}>
                                        <i className="fas fa-search-plus" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />

            {showList && (
                <UniversalSelect
                    multiple
                    fetchList={({ page, limit, query }) =>
                        dispatch(loadExclusiveFiles(page, limit, query, 'exclusiveFiles2'))
                    }
                    storeName="dorisControl"
                    storeNameProps="exclusiveFiles2"
                    storeLoadingProps="loadingExclusiveFiles"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <span>{el.file_name}</span>}
                    isOpen={showList}
                    onClose={() => setShowList(false)}
                    onAccept={handleAccept}
                    noPadding
                    title="Файл"
                />
            )}
        </>
    );
};

export default SelectFiles;
