import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import { companiesActions, companiesSelectors } from 'redux/Companies';
import { transportRoadActions } from 'redux/TransportRoad';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SelectCompany from 'components/common/Autocomplete/Companies';
import MarkModelComponent from 'components/common/Transport/MarkModelComponent';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import useStoreProp from 'helpers/hooks/useStoreProp';

const ModalForm = ({
    isOpen,
    onClose,
    item,
    isNew,
    reloadList
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const vehiclesCategories = useStoreProp(transportRoadActions.loadVehicleCategories, 'transportRoad', 'vehicleCategories');
    const currentCompany = useSelector(companiesSelectors.currentCompany);

    const initialState = {
        garage_number: item?.garage_number || '',
        number: item?.number || '',
        category_id: item?.category_id || '',
        brand: item?.brand || {},
        organization_id: item?.organization_id || null,
        model: item?.model || '',
        model_id: item?.model?.id || '',
        serial_egts: item?.characteristics?.serial_egts || '',
        serial_yandex: item?.characteristics?.serial_yandex || '',
        // egts_receiver: item?.egts_receiver || {},
        // egts_receiver_id: item?.egts_receiver_id || ''
    };

    const [formData, setFormData] = useState(initialState);
    const [company, setCompany] = useState({});

    const onChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleChangeCompany = (value) => {
        setFormData({
            ...formData,
            organization_id: value.id || null
        });
        setCompany(value);
    };

    const onSave = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            characteristics: {
                ...item?.characteristics,
                ...(formData.serial_egts?.length > 0 && { serial_egts: formData.serial_egts }),
                ...(formData.serial_yandex?.length > 0 && { serial_yandex: formData.serial_yandex }),
            }
        });

        const callback = () => {
            reloadList();
            onClose();
        };

        isNew
            ? dispatch(transportRoadActions.createdNewVehicle(prepareData, callback))
            : dispatch(transportRoadActions.editVehicle(item.id, prepareData, callback));
    };

    // загрузка компании если нет
    useEffect(() => {
        if (item?.organization_id) {
            if (currentCompany[item?.organization_id]) {
                setCompany(currentCompany[item?.organization_id]);
            } else {
                dispatch(companiesActions.loadCurrentCompany(item?.organization_id));
            }
        }
    },[item?.organization_id, currentCompany, dispatch]);

    const disabledSaveButton = !formData.category_id || !formData.model_id
        || !formData.number || !formData.garage_number
        || !formData.brand || !formData.organization_id;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: disabledSaveButton,
                        onClick: onSave
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <div className="row">
                    <FormInfoWrapper className="row__item" error={validation.isKey('number')} helperText={validation.get('number')}>
                        <TextField
                            required
                            placeholder="А123БС 134RUS"
                            label="Регистрационный номер ТС"
                            variant="outlined"
                            size="small"
                            value={formData.number}
                            onChange={onChange}
                            name="number"
                            error={validation.isKey('number')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper className="row__item" error={validation.isKey('garage_number')} helperText={validation.get('garage_number')}>
                        <TextField
                            required
                            label="Гаражный номер ТС"
                            variant="outlined"
                            size="small"
                            name="garage_number"
                            value={formData.garage_number}
                            onChange={onChange}
                            error={validation.isKey('garage_number')}
                        />
                    </FormInfoWrapper>
                </div>
                <MarkModelComponent
                    required
                    formData={formData}
                    setFormData={setFormData}
                    styleName="block"
                />
                <FormInfoWrapper className="block" error={validation.isKey('category')} helperText={validation.get('category')}>
                    <FormControl
                        variant="outlined"
                        size="small"
                        error={validation.isKey('category')}
                        required
                    >
                        <InputLabel>{titles.TYPE_OF_VEHICLE}</InputLabel>
                        <Select
                            label={titles.TYPE_OF_VEHICLE}
                            value={formData.category_id}
                            onChange={onChange}
                            name="category_id"
                        >
                            {vehiclesCategories.length > 0
                                ? vehiclesCategories?.map((el) =>
                                    <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                )
                                : <MenuItem value=" ">{messages.NO_DATA}</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </FormInfoWrapper>
                <TextField
                    placeholder="Серийный номер для EGTS"
                    className="block"
                    label="Серийный номер для EGTS"
                    variant="outlined"
                    size="small"
                    name="serial_egts"
                    value={formData.serial_egts}
                    onChange={onChange}
                />
                <TextField
                    placeholder="Серийный номер для Яндекса"
                    className="block"
                    label="Серийный номер для Яндекса"
                    variant="outlined"
                    size="small"
                    name="serial_yandex"
                    value={formData.serial_yandex}
                    onChange={onChange}
                />
                <div className="block">
                    <SelectCompany
                        filter={{ withDeleted: 1 }}
                        required
                        selected={company}
                        onChange={handleChangeCompany}
                        helperText={validation.get('organization_id')}
                        error={validation.isKey('organization_id')}
                    />
                </div>
                {/* <div className="block">
                    <TelemetryReceiverOrganization
                        selected={formData?.egts_receiver}
                        onChange={(egts_receiver) => setFormData({
                            ...formData,
                            egts_receiver: egts_receiver,
                            egts_receiver_id: egts_receiver.id
                        })}
                        helperText={validation.get('egts_receiver_id')}
                        error={validation.isKey('egts_receiver_id')}
                        storeName="transportRoad"
                    />
                </div> */}
            </div>
        </Modal>
    );
};

export default ModalForm;
