import React from 'react';
import Line from './Line';
import {
    Card,
    CardContent,
    CardHeader,
} from '@mui/material';


const Graph = ({
    list = [],
    title = '',
    action,
    filter,
    paramsInFilter,
}) => {
    if (list?.length > 0) {
        return (
            <Card sx={{ marginBottom: '1rem' }}>
                <CardHeader
                    title={title}
                    action={action}
                    sx={{
                        textAlign: 'center',
                    }}
                />
                <CardContent
                    sx={{
                        paddingTop: 0,
                    }}
                >
                    {list?.map((item, index) => (
                        <Line
                            titleData={title}
                            key={index}
                            item={item}
                            filter={filter}
                            paramsInFilter={paramsInFilter}
                        />
                    ))}
                </CardContent>
            </Card>
        );
    }
    return null;
};

export default Graph;