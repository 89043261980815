import { Children, useState } from 'react';
import { Popover } from '@mui/material';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

// модалка для отчета
const PageReportModal = ({
    disabled = false,
    children,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // открыть модалку
    const handleOpen = (event) => setAnchorEl(event.currentTarget);

    // закрыть модалку
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <FormButtonsComponent
                noPadding
                noMarginLeft
                buttons={[
                    {
                        ...buttonsTypes.report,
                        onClick: handleOpen,
                        disabled
                    },
                ]}
            />
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {children
                    && Children
                        .toArray(children)
                        .map((child, index) => (
                            <child.type
                                key={index}
                                {...child.props}
                                onClose={handleClose}
                            />
                        ))
                }
            </Popover>
        </>
    );
};

export default PageReportModal;
