import { useDispatch, useSelector } from 'react-redux';

import {
    setUploadFileError,
    setUploadTransportIncidentFile,
    uploadTransportIncidentFile
} from 'redux/Upload/actions';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';

const UploadModal = ({ isOpen, onClose, data, validationData, reloadList }) => {
    const dispatch = useDispatch();

    const { uploadedTransportIncidentFile } = useSelector(({ upload }) => upload);

    const handleCloseModal = () => {
        dispatch(setUploadFileError(null));
        dispatch(setUploadTransportIncidentFile(null));
        onClose();
    };

    const handleAccept = () => {
        dispatch(setUploadFileError(null));
        dispatch(uploadTransportIncidentFile(uploadedTransportIncidentFile.file, 1));
        reloadList?.();
        onClose();
    };

    return (
        <Modal
            title={data
                ? 'Указанные записи уже есть в базе и будут перезаписаны'
                : validationData['xlsx']
                    ? 'Неверный формат файла'
                    : 'В файле ошибки заполнения'
            }
            onClose={handleCloseModal}
            showCloseInTitle
            isOpen={isOpen}
            maxWidth="md"
            {...(data
                ? {
                    buttons: <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.cancel,
                                onClick: handleCloseModal
                            },
                            {
                                ...buttonsTypes.confirm,
                                onClick: handleAccept
                            }

                        ]}
                    />
                }
                : {}
            )}
        >
            <div className="modal__form">
                {data && data?.map((el, i ) =>
                    <span key={i}>
                        {el}
                    </span> )
                }
                {validationData && Object.keys(validationData)?.map((key) =>
                    <span key={key}>
                        {validationData[key]}
                    </span> )
                }
            </div>
        </Modal>
    );
};

export default UploadModal;
