import React, { useMemo } from 'react';
import {
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Paper,
} from '@mui/material';
import { isFloat } from '../../../../../../../helpers/validator';

const TableComponent = ({
    data = [],
}) => {
    const {
        headers = {},
        data: dataList = [],
        additional = [],
    } = data;

    const keysColumn = useMemo(() => {
        return Object.keys(headers);
    }, [headers]);

    const getAlign = (index) => index > 0 ? 'center' : 'left';
    const renderValue = (key, value) => {
        const newValue = value === null
            ? value
            : isFloat(value) 
                ? Number(value).toFixed(2)
                : value;
        return ['total'].includes(key) ? <strong>{newValue}</strong> : newValue;
    };

    return (
        <TableContainer component={Paper} className="tableCell list_style">
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow className="content_item">
                        {keysColumn.map((key, index) => (
                            <TableCell
                                key={key}
                                className="title_item"
                                align={getAlign(index)}
                                style={{
                                    fontSize: '.9rem'
                                }}
                            >
                                {headers[key]}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody className="content_item">
                    {dataList.map((item, index) => (
                        <TableRow
                            key={index}
                            className="list_item"
                            style={{
                                fontSize: '.9rem'
                            }}
                        >
                            {keysColumn.map((key, index) => (
                                <TableCell
                                    key={key}
                                    align={getAlign(index)}
                                >
                                    {renderValue(key, item[key])}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}

                    {additional?.length > 0 && (
                        <TableRow className="list_item">
                            <TableCell
                                align="right" colSpan={keysColumn.length || 1}
                                style={{
                                    fontSize: '.9rem'
                                }}
                            >
                                {additional?.map(({ params = '', value = 0, unit = '' }, index) => (
                                    <div key={index}>
                                        <strong>{params}:</strong>
                                        <span>&nbsp;{value} {unit}</span>
                                    </div>
                                ))}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableComponent;
