import { useState } from 'react';
import { LSContentColumn, LSContentItem } from '../../../../common/List';
import { DuploCodesItem } from './types';
import RenderDuploCodesItem from '../../../../common/DorisControl/RenderDuploCodesItem';
import { Checkbox } from '@mui/material';
import ConfirmModal from '../../../../common/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import { dorisControlSelectors } from '../../../../../redux/DorisControl';
import { editDuploCodes } from '../../../../../redux/DorisControl/actions';
import { IPermissions } from '../../../../../types/permissions';

interface ItemProps {
    item: DuploCodesItem;
    reloadList: () => void;
    permissions: IPermissions
}

const Item = ({ item, reloadList, permissions }: ItemProps) => {
    const dispatch = useDispatch();
    const  loadingButton  = useSelector(dorisControlSelectors.loadingButton);

    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const handleChangeIsOTK = () => {
        const callback = () => {
            setOpenConfirmModal(false);
            reloadList();
        };

        dispatch(editDuploCodes(item?.id, { is_otk: !item.is_otk }, callback));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    <Checkbox
                        color={'success'}
                        checked={item?.is_otk}
                        onChange={() => permissions?.is_update && setOpenConfirmModal(true)}
                    />
                </LSContentColumn>

                <LSContentColumn>
                    <RenderDuploCodesItem item={item}/>
                </LSContentColumn>
            </LSContentItem>

            {openConfirmModal && (
                <ConfirmModal
                    open={openConfirmModal}
                    onClose={() => setOpenConfirmModal(false)}
                    onSuccess={handleChangeIsOTK}
                    loadingButton={loadingButton}
                    message={`Вы уверены, что ${item?.is_otk ? 'не' : ''} хотите использовать этот код ДУПЛО в ОТК?`}
                />
            )}
        </>
    );

};

export default Item;
