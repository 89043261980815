import { useEffect } from 'react';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Loading from '../../../../common/Loading';
import styles from '../Messages/messages.module.scss';
import Modal from '../../../../common/Modal';
import { useSelector, useDispatch } from 'react-redux';
import {
    clearMessageJSONPreview,
    fetchMessageJSONPreview,
} from '../../../../../redux/Boards/actions';
import { SelectBoard } from '../Messages/ModalForm/Fields';
import { boardsSelectors } from '../../../../../redux/Boards';
import Image from 'components/common/Image';

const PreviewMessage = ({ text, isOpen, onClose, imgPreview, template, boardForPreview, setBoardForPreview, sortedList }) => {
    const dispatch = useDispatch();
    const messageJSONPreview = useSelector(boardsSelectors.messageJSONPreview);
    const loadingMessageJSONPreview = useSelector(boardsSelectors.loadingMessageJSONPreview);

    // const [boardForPreview, setBoardForPreview] = useState();
    // const board_id = boardForPreview?.id;
    
    const showMessagePreview =() => {
        if (!imgPreview) {
            dispatch(fetchMessageJSONPreview(boardForPreview?.id, { text }));
        }
    };

    const closePreviewMessageJSON = () => {
        dispatch(clearMessageJSONPreview());
        onClose(false);
    };

    useEffect(() => {
        // первоначальная загрузка превью если есть boardForPreview и нет base64 картинки 
        if (boardForPreview?.id && !imgPreview) {
            dispatch(fetchMessageJSONPreview(boardForPreview?.id, { text }));
        }
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title="Предпросмотр сообщения"
            buttons={<FormButtons
                buttons={[
                    ...(template ? [{
                        ...buttonsTypes.preview,
                        disabled: !boardForPreview?.id || (!!boardForPreview?.id && !!messageJSONPreview),
                        onClick: showMessagePreview
                    }]
                        : []),
                    {
                        ...buttonsTypes.close,
                        onClick: closePreviewMessageJSON
                    },
                ]}
            />}
        >
            <div style={{ textAlign: 'center' }}>
                <div className="modal__form">
                    {loadingMessageJSONPreview && <Loading linear={true}/>}
                    {template
                        && <>
                            <SelectBoard 
                                sortedList={sortedList}
                                boardForPreview={boardForPreview} 
                                setBoardForPreview={(board) => {
                                    setBoardForPreview(board);
                                    dispatch(clearMessageJSONPreview());
                                }} 
                            />
                        </>
                    }
                    {boardForPreview?.id
                        ? messageJSONPreview
                            ? <div
                                className={styles.preview}
                                dangerouslySetInnerHTML={{ __html: messageJSONPreview }}
                            />
                            : imgPreview 
                                ? <div className={styles.preview}>
                                    <Image src={imgPreview} alt="preview" />
                                </div>
                                : null
                        : null
                    }
                </div>
            </div>
        </Modal>
    );
};

export default PreviewMessage;