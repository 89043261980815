import {
    ListItem,
    ListItemText,
} from '@mui/material';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import styles from './traffic-lights.module.scss';

const Item = ({ el, statuses, types, onEdit, onDelete }) => {
    return (
        <>
            <ListItem className="list_item">
                <ListItemText className={styles.preview}>
                    <div className={styles.preview__wrap}>
                        <span className={styles.preview__wrap_title}><strong>Наименование: </strong>{el.name}</span>
                        {el.address_text && <span className={styles.preview__wrap_title}><strong>Адрес: </strong>{el.address_text}</span>}
                    </div>
                    <div className={styles.preview__wrap}>
                        <strong className={styles.preview__wrap_title}>Статус: <span
                            style={{ color: el.color || 'inherit' }}
                        >
                            {statuses[el.status] || ''}
                        </span>
                        </strong>

                        <strong className={styles.preview__wrap_title}>Тип: <span>
                            {types[el.type_id] || ''}
                        </span>
                        </strong>
                    </div>
                </ListItemText>
                {el?.type_id === 4
                    && <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    onEdit?.();
                                },
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    onDelete?.();
                                },
                            },
                        ]}
                        noMarginLeft
                        justButton
                    />
                }
            </ListItem>
        </>
    );
};

export default Item;
