import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadChecklists } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import titles from 'helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import { LSContainer } from 'components/common/List';

import Filter from './Filter';
import Item from './Item';
import ModalForm from './ModalForm';

import type { ElemProps } from './types';

const Checklists = () => {
    const dispatch = useDispatch();

    const data = useSelector(dorisControlSelectors.checklistsData);
    const meta = useSelector(dorisControlSelectors.checklistsMeta);
    const loading = useSelector(dorisControlSelectors.loadingChecklist);

    const [params, setParams] = useState({ page: 1, query: {} });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit') || 25));
    const [openAddModal, setOpenAddModal] = useState(false);

    const reloadList = (isDelete = false) => {
        const { page, query } = params;
        isDelete && data?.length === 1 && meta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadChecklists({ page, limit, params: query }));
    };

    useEffect(() => {
        const { page, query } = params;
        dispatch(loadChecklists({ page, limit, params: query }));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return (
            data?.length > 0
                ? <LSContainer
                    headers={[
                        { title: titles.NAME, width: '60%' },
                        { title: 'Действия', isActions: true },
                    ]}
                >
                    {data.map((elem: ElemProps) => (
                        <Item 
                            key={elem.id} 
                            item={elem} 
                            reloadList={reloadList}
                        />
                    ))}
                </LSContainer>
                : !loading && <NoData/>
        );
    };

    return (
        <>
            <PageLayout
                header="Пункты чек-листов"
                filters={<Filter setParams={setParams} />}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent 
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenAddModal(true)
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit: limit,
                    setLimit: setLimit,
                }}
            />

            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default Checklists;
