import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    loadShowCaseDocumentsVerification,
    clearShowCaseDocumentsVerification,
} from '../../../../../redux/DorisControl/actions';
import { dorisControlSelectors } from '../../../../../redux/DorisControl';
import CardWidget from '../../../../common/Showcase/CardWidget';
import TypeComponent from '../../../../common/Showcase/TypeComponent';


const First = () => {
    const dispatch = useDispatch();
    const eventsStatistic = useSelector(dorisControlSelectors.showCaseDocumentsVerification);

    useEffect(() => {
        dispatch(loadShowCaseDocumentsVerification());
        return () => dispatch(clearShowCaseDocumentsVerification());
    }, []);

    if (Object.keys(eventsStatistic).length > 0) {
        return (
            <CardWidget
                icon="fas fa-list"
                // icon={layerConfig.layerIcon}
                title={eventsStatistic.name}
                // marginBottom={index < list.length - 1}
                fillOthers
                small
            >
                <TypeComponent
                    {...eventsStatistic}
                />
            </CardWidget>
        );
    }
    return null;
};

export default First;