import React from 'react';
import { Grid } from '@mui/material';
import Doughnut from './Doughnut/index';
import Line from './Line/index';
import Table from './Table/index';
import renderAddress from '../../../../../../helpers/renderAddress';

// элемент
const Item = ({
    data,
}) => {
    const {
        puid_id,
        puid_name = '',
        puid_address_text = '',
        puid_address = {},
        graph: {
            0: doughnutG = {},
            1: lineG = {}
        } = [],
        table = []
    } = data;

    return (
        <div style={{ marginBottom: '2rem' }}>
            {puid_name && <h2 className="align-items-center">{puid_name} {puid_id && `(${puid_id})`}</h2>}
            {(puid_address_text || puid_address)
                ? <div style={{ marginBottom: '1rem' }}>{puid_address_text || renderAddress(puid_address)}</div>
                : null
            }

            <div style={{ textAlign: 'center' }}>
                <h1>{lineG.title}</h1>
            </div>

            <Grid container>
                <Grid item container style={{ marginBottom: '2rem' }}>
                    <Grid item xs={12} md={4}>
                        {/* бублик */}
                        <h3>{doughnutG.title}{doughnutG.total && ` (Всего: ${doughnutG.total} шт.)`}</h3>
                        <Doughnut data={doughnutG.data} />
                    </Grid>

                    <Grid item xs={12} md={8}>
                        {/* бар */}
                        <Line
                            data={lineG.data}
                            title={doughnutG.title}
                            // yText={`${start_date2} - ${end_date2}`}
                            yText="шт."
                        />
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item xs={12}>
                        {/* таблица */}
                        {table?.map?.((item, index) => (
                            <Table key={index} data={item} />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Item;