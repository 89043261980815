import { roadworks } from '../RoadWorks/selectors';

export const SituationalPlans = (state) => state.SituationalPlans;

export const transportIncidentTypes = (state) => SituationalPlans(state).transportIncidentTypes;
export const transportIncidentTypesData = (state) => transportIncidentTypes(state).data;
export const transportIncidentTypesMeta = (state) => transportIncidentTypes(state).meta;
export const loadingTransportIncidentTypes = (state) => SituationalPlans(state).loadingTransportIncidentTypes;

export const events = (state) => SituationalPlans(state).events;
export const eventsData = (state) => events(state).data;
export const eventsMeta = (state) => events(state).meta;
export const loadingEvents = (state) => SituationalPlans(state).loadingEvents;

export const keyWords = (state) => SituationalPlans(state).keyWords;
// export const keyWordsData = (state) => keyWords(state).data;
// export const keyWordsMeta = (state) => keyWords(state).meta;
export const keyWordsLoading = (state) => SituationalPlans(state).keyWordsLoading;

export const dangersList = (state) => SituationalPlans(state).dangersList;
// export const dangersListData = (state) => dangersList(state).data;
// export const dangersListMeta = (state) => dangersList(state).meta;
export const loadingDangersList = (state) => SituationalPlans(state).loadingDangersList;

// export const statusValidation = (state) => SituationalPlans(state).statusValidation;

export const threatLevels = (state) => SituationalPlans(state).threatLevels;
export const threatLevelsData = (state) => threatLevels(state).data;
export const threatLevelsMeta = (state) => threatLevels(state).meta;
export const loadingThreatLevels = (state) => SituationalPlans(state).loadingThreatLevels;

export const typicalOperations = (state) => SituationalPlans(state).typicalOperations;
export const typicalOperationsData = (state) => typicalOperations(state).data;
export const typicalOperationsMeta = (state) => typicalOperations(state).meta;
export const loadingTypicalOperations = (state) => SituationalPlans(state).loadingTypicalOperations;

export const responseScenarios = (state) => SituationalPlans(state).responseScenarios;
export const responseScenariosData = (state) => responseScenarios(state).data;
export const responseScenariosMeta = (state) => responseScenarios(state).meta;
export const loadingResponseScenarios = (state) => SituationalPlans(state).loadingResponseScenarios;

export const eventObjects = (state) => SituationalPlans(state).eventObjects;
export const eventObjectsData = (state) => eventObjects(state).data;
export const eventObjectsMeta = (state) => eventObjects(state).meta;
export const loadingEventObjects = (state) => SituationalPlans(state).loadingEventObjects;

// export const eventObjectsTypes = (state) => SituationalPlans(state).eventObjectsTypes;
// export const currentEvent = (state) => SituationalPlans(state).currentEvent;
// export const currentKeyword = (state) => SituationalPlans(state).currentKeyword;

export const transportIncidents = (state) => SituationalPlans(state).transportIncidents;
// export const transportIncidentsData = (state) => transportIncidents(state).data;
// export const transportIncidentsMeta = (state) => transportIncidents(state).meta;
export const loadingTransportIncidents = (state) => SituationalPlans(state).loadingTransportIncidents;

export const transportIncident = (state) => SituationalPlans(state).transportIncident;
export const loadingTransportIncident = (state) => SituationalPlans(state).loadingTransportIncident;

// export const currentCategory = (state) => SituationalPlans(state).currentCategory;
export const transportIncidentStatuses = (state) => SituationalPlans(state).transportIncidentStatuses;
// export const currentResponseScenario = (state) => SituationalPlans(state).currentResponseScenario;
// export const currentTransportIncidentType = (state) => SituationalPlans(state).currentTransportIncidentType;

export const currentThreatLevel = (state) => SituationalPlans(state).currentThreatLevel;
// export const loadingCurrentThreatLevel = (state) => SituationalPlans(state).loadingCurrentThreatLevel;
// export const loadingCurrentTransportIncidentType = (state) => SituationalPlans(state).loadingCurrentTransportIncidentType;
// export const loadingCurrentResponseScenario = (state) => SituationalPlans(state).loadingCurrentResponseScenario;

// export const loadingCurrentEvent = (state) => SituationalPlans(state).loadingCurrentEvent;
// export const currentEventObject = (state) => SituationalPlans(state).currentEventObject;
// export const arrayOfKeywords = (state) => SituationalPlans(state).arrayOfKeywords;

export const reportTransportIncidents = (state) => SituationalPlans(state).reportTransportIncidents;
export const reportTransportIncidentsData = (state) => reportTransportIncidents(state).data;
export const reportTransportIncidentsMeta = (state) => reportTransportIncidents(state).meta;
export const loadingReportTransportIncidents = (state) => SituationalPlans(state).loadingReportTransportIncidents;

export const reportTransportIncidentsFilter = (state) => SituationalPlans(state).reportTransportIncidentsFilter;

export const sidebar = (state) => SituationalPlans(state).sidebar;
// export const sidebarData = (state) => sidebar(state).data;
export const sidebarLoading = (state) => SituationalPlans(state).sidebarLoading;
export const sidebarParams = (state) => SituationalPlans(state).sidebarParams;

export const polygon = (state) => SituationalPlans(state).polygon;
export const polygonLoading = (state) => SituationalPlans(state).polygonLoading;

export const filters = (state) => SituationalPlans(state).filters;
export const active = (state) => SituationalPlans(state).active;
export const deleteForm = (state) => SituationalPlans(state).deleteForm;
export const editForm = (state) => SituationalPlans(state).editForm;
export const saved = (state) => SituationalPlans(state).saved;

export const reportTransportIncidentsResolutionTime = (state) => SituationalPlans(state).reportTransportIncidentsResolutionTime;
export const reportTransportIncidentsResolutionTimeData = (state) => reportTransportIncidentsResolutionTime(state).data;
export const reportTransportIncidentsResolutionTimeMeta = (state) => reportTransportIncidentsResolutionTime(state).meta;
export const loadingReportTransportIncidentsResolutionTime = (state) => SituationalPlans(state).loadingReportTransportIncidentsResolutionTime;

export const reportTransportIncidentsRTFilter = (state) => SituationalPlans(state).reportTransportIncidentsRTFilter;

export const sidebarEvents = (state) => SituationalPlans(state).sidebarEvents;
// export const sidebarEventsData = (state) => sidebarEvents(state).data;
// export const sidebarEventsMeta = (state) => sidebarEvents(state).meta;
export const sidebarLoadingEvents = (state) => SituationalPlans(state).sidebarLoadingEvents;

export const polygonEvents = (state) => SituationalPlans(state).polygonEvents;
export const polygonEventsLoading = (state) => SituationalPlans(state).polygonEventsLoading;
export const eventsProps = (state) => SituationalPlans(state).eventsProps;


export const filtersEvents = (state) => SituationalPlans(state).filtersEvents;
export const activeEvents = (state) => SituationalPlans(state).activeEvents;
export const deleteFormEvents = (state) => SituationalPlans(state).deleteFormEvents;
export const editFormEvents = (state) => SituationalPlans(state).editFormEvents;
export const savedEvents = (state) => SituationalPlans(state).savedEvents;

export const incidentOperations = (state) => SituationalPlans(state).incidentOperations;
export const loadingIncidentOperations = (state) => SituationalPlans(state).loadingIncidentOperations;

// export const loadingTransportIncidentHistory = (state) => SituationalPlans(state).loadingTransportIncidentHistory;
// export const transportIncidentHistory = (state) => SituationalPlans(state).transportIncidentHistory;
// export const transportIncidentHistoryData = (state) => transportIncidentHistory(state).data;
// export const transportIncidentHistoryMeta = (state) => transportIncidentHistory(state).meta;

// export const transportIncidentTypeCreation = (state) => SituationalPlans(state).transportIncidentTypeCreation;

export const infopanel = (state) => SituationalPlans(state).infopanel;
export const loadingInfopanel = (state) => SituationalPlans(state).loadingInfopanel;

export const districtsByMonth = (state) => SituationalPlans(state).districtsByMonth;
export const loadingDistrictsByMonth = (state) => SituationalPlans(state).loadingDistrictsByMonth;

export const filterDistrictsByMonth = (state) => SituationalPlans(state).filterDistrictsByMonth;
export const loadingButton = (state) => SituationalPlans(state).loadingButton;

export const transportIncidentModal = (state) => SituationalPlans(state).transportIncidentModal;
export const loadingTransportIncidentModal = (state) => SituationalPlans(state).loadingTransportIncidentModal;

export const tiModalData = (state) => SituationalPlans(state).tiModalData;
export const tiFilterParams = (state) => SituationalPlans(state).tiFilterParams;

//showcase
export const showcase = (state) => SituationalPlans(state).showcase;
export const loadingShowcase = (state) => SituationalPlans(state).loadingShowcase;

export const imagesLegends = (state) => SituationalPlans(state).imagesLegends;

export const reportByEventPlans = (state) => SituationalPlans(state).reportByEventPlans;
// export const reportByEventPlansData = (state) => reportByEventPlans(state).data;
// export const reportByEventPlansMeta = (state) => reportByEventPlans(state).meta;
export const loadingReportByEventPlans = (state) => SituationalPlans(state).loadingReportByEventPlans;