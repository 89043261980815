import { memo } from 'react';
import { Bar } from 'react-chartjs-2';

import getColor from 'helpers/getColor';

function Graph({ datas, height = 250, width = 500 }) {

    const data = datas?.map((item, i) => {
        const sum = item?.reduce((acc, sumItem) => sumItem.count + acc, 0);
        const label = item[0]?.area || 'не указано';
        return {
            data: [sum],
            maxBarThickness: 50,
            label,
            backgroundColor: getColor(label),
            borderColor: '#000'
        };
    });

    const getLabel = (context) => {
        const label = context?.formattedValue || '';
        return `Количество ДТП: ${label}`;
    };

    const toolTipTitle = (tooltipItems) => {
        return tooltipItems[0]?.dataset.label;
    };

    const options = {
        indexAxis: 'y',
        redraw: false,
        scales: {
            x: {
                ticks: {
                    precision: 0,
                },
                title: {
                    display: true,
                    text: 'Количество'
                }
            }
        },
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks: {
                    label: getLabel,
                    title: toolTipTitle
                }
            },
            legend: {
                align: 'center',
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                }
            }
        },
    };

    return (
        <div>
            <Bar
                height={height}
                width={width}
                data={{
                    labels: ['Районы'],
                    datasets: data,
                }}
                options={options}
                type="Bar"
            />
        </div>
    );
}

export default memo(Graph);