import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import L from 'leaflet';

import {
    Cluster,
    ContextMenuItem,
    GeoJsonNew,
} from 'components/MapComponents/leaflet';
import { iconCreateFunctionSimple } from 'components/MapComponents/helpers/iconCreateFunction';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { usePrevious, useDebounce } from 'helpers/hooks';
import mapHelper from 'helpers/mapHelper';
import colorExtend from 'helpers/mapHelper/colorExtend';
import * as actions from 'redux/RoadNetwork/actions';
import { roadNetworksSelectors } from 'redux/RoadNetwork';
import { loadRoadSectionRelation } from 'redux/RoadNetwork/actions';
import config from './config.js';
import { createMarker, getFilter } from './helper';
import PopUp from './PopUp';
import Relations from './Relations';
import CollectorForms from './CollectorForms';
import { getCoords } from './Relations/helper';


const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();

    const polygonRoadSection = useSelector(roadNetworksSelectors.polygonRoadSection);
    const loadingPolygonRoadSection = useSelector(roadNetworksSelectors.loadingPolygonRoadSection);
    const activeRoadSection = useSelector(roadNetworksSelectors.activeRoadSection);
    const filtersRoadSection = useSelector(roadNetworksSelectors.filtersRoadSection);
    const savedRoadSection = useSelector(roadNetworksSelectors.savedRoadSection);
    const prevLoadingPolygon = usePrevious(loadingPolygonRoadSection);

    const prevFilters = usePrevious(filtersRoadSection);
    const filter = getFilters(filtersRoadSection, getFilter);

    const [relations, setRelations] = useState([]);
    const [relationsLoaded, setRelationsLoaded] = useState(false);

    const showPopup = useRef(0);

    useEffect(() => {
        // грузим объекты сети
        if (activeRoadSection.id && activeRoadSection.isShowObjects) {
            setRelations([]);
            dispatch(
                loadRoadSectionRelation(
                    { road_section_id_list: [activeRoadSection.id] },
                    (data) => {
                        setRelations(data);
                        setRelationsLoaded(true);
                    }
                )
            );
        } else {
            setRelations([]);
        }
    }, [activeRoadSection.id, activeRoadSection.isShowObjects]);

    useEffect(() => () => {
        setRelations([]);
        setRelationsLoaded(false);
    }, []);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadPolygonRoadSection(polygon, filter));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();

    // добавить новый
    const handleAdd = ({ lat, lng: lon }) => {
        dispatch(actions.setEditFormRoadSection({
            lat,
            lon,
            // на карте не совпадает маркер
            // data: createPointGJ(lat, lon),
        }));
    };

    useEffect(() => {
        if (savedRoadSection) {
            map.closeContextMenu();
            fetchPolygon();
        }
    }, [savedRoadSection]);

    useEffect(() => {
        if (!isEqual(filtersRoadSection, prevFilters)) {
            debounceFetchPolygon();
        }
    }, [filtersRoadSection, prevFilters]);

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(actions.clearActiveRoadSection());
            dispatch(actions.clearPolygonRoadSection());

            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filtersRoadSection]);


    // добавление нового элемента с карты
    useEffect(() => {
        fetchPolygon();

        if (config.mapContextMenu) {
            map.on(config.mapContextMenu.event, (e) => {
                //map.fire('context_menu_close');
                handleAdd(e.latlng);
            });

            return () => {
                map.off(config.mapContextMenu.event);
                dispatch(actions.clearPolygonRoadSection());
            };
        }
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (
            Object.keys(activeRoadSection).length
            && !activeRoadSection?.isShowObjects
        ) {
            const { data } = activeRoadSection;

            if (data) {
                const b = L.geoJSON(data).getBounds();
                map.fitBounds(b);
                showPopup.current = activeRoadSection?.id;
            }
        }
    }, [activeRoadSection]);

    useEffect(() => {
        // сдвигаем карту к объектамм дорожной сети
        if (
            Object.keys(activeRoadSection).length
            && activeRoadSection?.isShowObjects
        ) {
            showPopup.current = 0;

            if (
                relationsLoaded === true
                && activeRoadSection?.data
            ) {
                const { data } = activeRoadSection;

                const itemBound = L.geoJSON(data).getBounds();

                const latLongs = relations?.reduce((r, i) => {
                    const latlon = getCoords({
                        ...i,
                        ...i?.entity_data,
                    });
                    if (latlon) {
                        r.push(latlon);
                    }
                    return r;
                }, []);

                const boundsExtend = new L.LatLngBounds(latLongs);
                boundsExtend.extend(itemBound);
                map.fitBounds(boundsExtend);
                setRelationsLoaded(false);
            }
        }
    }, [activeRoadSection, relations, relationsLoaded]);

    useEffect(() => {
        if (
            showPopup.current
            && prevLoadingPolygon === true
            && loadingPolygonRoadSection === false
        ) {
            setTimeout(() => {
                map.fire(`showBy${config.slug}${showPopup.current}`);
                showPopup.current = 0;
            }, 200);
        }
    }, [loadingPolygonRoadSection, prevLoadingPolygon]);

    // меню маркера, линии, полигона при клике
    const renderContextMenu = (item, rcmProps) => {
        return (
            <div>
                <ContextMenuItem
                    {...rcmProps}
                    value="Редактировать"
                    onClick={() => {
                        map.fire('context_menu_close');
                        const currentItem = polygonRoadSection
                            ?.find(({ id }) => id === item.id) || null;
                        dispatch(actions.setEditFormRoadSection(currentItem));
                    }}
                />
                <ContextMenuItem
                    {...rcmProps}
                    value="Удалить"
                    onClick={() => {
                        map.fire('context_menu_close');
                        dispatch(actions.setDeleteFormRoadSection(item.id));
                    }}
                    className="red"
                />
            </div>
        );
    };

    // собираем геообъект
    const geometry = useMemo(() => {
        const isShowObjects = activeRoadSection?.isShowObjects || false;
        return polygonRoadSection?.reduce((res, item) => {
            const {
                id,
                name,
                data = {},
                line_width,
                category,
                color
                /* status, geometry = {} */
            } = item;

            if (
                isShowObjects === false
                || (
                    isShowObjects === true
                    && activeRoadSection.id === item.id
                )
            ) {
                const currentColor = color ? colorExtend(color) : config.mapMarkersColors.default;
                res.push({
                    ...data,
                    properties: {
                        ...data?.properties,
                        id,
                        name,
                        color: currentColor,
                        category,
                    },
                    style: {
                        color: currentColor,
                        weight: config.lineWidth[line_width] || config.lineWidth.default,
                    },
                });
            }

            return res;
        }, []);
    }, [polygonRoadSection, activeRoadSection?.isShowObjects, relations]);

    return (
        <>
            <GeoJsonNew
                {...props}
                data={geometry}
                toolTipTemplate={({ name = '' }) => <div>{name ?? ''}</div>}
                icon={item => createMarker(item)}
                contextMenuTemplate={renderContextMenu}
                idPrefix={config.slug}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, -10]
                }}
                popUpTemplate={(data) => <PopUp data={data} />}
                onClosePopup={() => {
                    if (
                        !showPopup.current
                        && activeRoadSection?.isShowObjects === false
                    ) {
                        dispatch(actions.clearActiveRoadSection());
                    }
                }}
            />

            {/* выводим объекты */}
            {relations.length > 0 && (
                <Cluster
                    // кластер
                    {...props}
                    iconCreateFunction={iconCreateFunctionSimple}
                    maxClusterRadius={40}
                >
                    <Relations
                        title={activeRoadSection.name || ''}
                        list={relations}
                        {...props}
                    />
                </Cluster>
            )}

            {/* формы */}
            <CollectorForms />
        </>
    );
};

export default Layer;
