// стили в geoJson
const style = (feature) => {
    return {
        ...(feature.style || {}),
    };
};
/*style: function (feature) {
    return { color: feature.properties.color };
},*/

export default style;
