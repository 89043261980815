import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createWorkorder, loadWorkorders } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { 
    dateWithDashYYYYMMDD, 
    getDateWithDuration, 
    getEndOf, 
    getStartOf 
} from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import Context from 'helpers/context';
import titles from 'helpers/constants/titles';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';

import AddEditModal from './AddEditModal';
import ItemsList from './ItemsList';
import Filters from './Filters';

const Workorders = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);
    const [openAddModal, setOpenAddModal] = useState(false);
    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;

    const workordersData = useSelector(transportPassengerSelectors.workordersData);
    const workordersMeta = useSelector(transportPassengerSelectors.workordersMeta);
    const loadingWorkorders = useSelector(transportPassengerSelectors.loadingWorkorders);

    const initialState = {
        page: 1,
        limit: storageLimit,
        data: {
            start_date_at: getStartOf('day', getDateWithDuration({ days: -1 })),
            end_date_at: getEndOf('day'),
            traffic_schedule_ids: [],
            id: ''
        }
    };
    const [params, setParams] = useState(initialState);

    const onSearch = (data) => {
        setParams({
            ...params,
            data: {
                ...params.data,
                ...data
            },
            page: 1
        });
    };

    const fetchList = () => {
        const { page, limit, data } = params;
        const paramsWithoutEmpty = Object.entries(data).reduce((result, [key, value]) => {
            if (key === 'traffic_schedule_ids' && value.length > 0) {
                result[key] = value.map(({ id }) => id);
            } else if (['start_date_at', 'end_date_at'].includes(key)) {
                result[key] = dateWithDashYYYYMMDD(value);
            } else if(value){
                result[key] = value;
            }
            return result;
        }, {});

        dispatch(loadWorkorders(page, limit, paramsWithoutEmpty));
    };

    useEffect(() => {
        fetchList();
    }, [params, dispatch]);

    const onSave = (data) => {
        dispatch(createWorkorder(data, () => {
            setParams((old) => ({
                ...old,
                page: 1
            }));
            setOpenAddModal(false);
        }));
    };

    const changePageLimit = (page, limit) => {
        setParams((old) => ({
            ...old,
            page,
            limit
        }));
    };

    const setLimit = (limit) => {
        setParams((old) => ({
            ...old,
            limit,
            page: 1
        }));
    };

    const reloadList = () => fetchList();// dispatch(loadWorkorders(params.page, params.limit, params.data));

    const renderContent = () => {
        return <>
            {workordersData?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: 'Дата', width: '8%' },
                            { title: 'ID', width: '4%' },
                            { title: titles.STATUS, width: '12%' },
                            { title: titles.COMPANY, width: '11%' },
                            { title: titles.ROUTE, width: '8%' },
                            { title: titles.NAME, width: '12%' },
                            { title: '№ графика', width: '8%' },
                            { title: 'Гос. номер', width: '10%' },
                            { title: 'Начало', width: '10%' },
                            { title: 'Окончание', width: '10%' },
                            { title: 'Действия', align: 'right', isActions: true }
                        ]}
                    >
                        {workordersData.map((item) => (
                            <ItemsList
                                key={item?.id}
                                loadList={changePageLimit}
                                params={params}
                                data={item}
                                reloadList={reloadList}
                            />
                        ))}
                    </LSContainer>
                )
                : (!loadingWorkorders && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>;
    };

    return (
        <>
            <PageLayout
                header="Наряды"
                filters={<Filters
                    params={params}
                    onSearch={onSearch}
                    initialState={initialState}
                />}
                loading={loadingWorkorders}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtons 
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenAddModal(true),
                                        disabled: !permissions?.is_create,
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    
                    total: workordersMeta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: changePageLimit,
                    list: workordersMeta,
                    limit: params.limit,
                    setLimit
                }}
            />
            {openAddModal
                && <AddEditModal
                    isNew={true}
                    data={{
                        date_at: new Date(),
                        // date_at: params.date_at,
                        traffic_schedule_ids: params.data.traffic_schedule_ids[0],
                    }}
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    onSave={onSave}
                />
            }
        </>
    );
};

export default Workorders;