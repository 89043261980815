import L from 'leaflet';
// import config from './config';
import { checkBase64 } from '../TransportPassenger/helper';

// получить иконку маркера
// export const createIconMarker = (status, type) => {
//     return L.divIcon({
//         className: 'marker',
//         iconSize: [30, 36],
//         iconAnchor: [15, 36],
//         popupAnchor: [0, -36],
//         html: iconMap({ fill: getColorByStatus(status) })
//     });
// };

export const createIconMarker = (color, imageB64) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: `
            <svg viewBox="0 0 107.000000 130.000000" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid meet">
                <g fill="${color}" id="svg_1" transform="translate(0 130) scale(0.1 -0.1)">
                    <path d="m445.532653,1283.782703c-99.168694,-18.91732 -204.28751,-74.673633 -278.66403,-149.347267c-103.135442,-103.547438 -152.719789,-227.007845 -152.719789,-379.342057c0,-143.373376 37.684104,-243.933869 147.761354,-390.29419c63.467964,-84.630118 294.531021,-314.624908 345.107055,-343.498713c29.750608,-16.926024 30.742295,-16.926024 70.409773,11.947781c67.434712,48.786774 235.029805,215.060064 299.489456,297.698885c155.69485,199.129689 208.254257,348.476955 179.495336,514.750245c-24.792174,141.382079 -119.99412,285.751103 -238.996553,362.416034c-107.10219,69.695391 -248.913422,97.573547 -371.882603,75.669282z" id="svg_2"/>
                </g>
                 ${!imageB64 ? '<use fill="#000" id="svg_9" transform="matrix(0.695638 0 0 0.695638 -49.6099 -63.4566)" x="94.09438" xlink:href="#svg_8" y="95.207694"/>' : ''}
                <defs>
                    <symbol class="svg-inline--fa fa-car-bump fa-w-18" data-icon="car-bump" data-prefix="far" id="svg_8" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg">
                        <path d="m101.98,308.12c-17.69,8.02 -23.99,24.81 -15.77,41.96c8.22,17.15 25.5,23.24 43.18,15.22c17.69,-8.02 45.59,-17.2 37.37,-34.35c-8.22,-17.16 -47.09,-30.86 -64.78,-22.83zm235.83,-106.97c-17.69,8.02 -31.88,45.79 -23.66,62.94c8.22,17.15 33.38,2.26 51.07,-5.76c17.69,-8.02 23.99,-24.81 15.77,-41.96c-8.22,-17.15 -25.5,-23.24 -43.18,-15.22zm-221.62,248.88l324.26,-147.08l10.31,21.51c5.69,11.88 20.21,17.01 32.42,11.48l14.74,-6.69c12.21,-5.54 17.49,-19.66 11.8,-31.53l-44.67,-93.19l-13.75,-28.67c-14.02,-29.25 -41.45,-48.16 -71.83,-53.3l-76.15,-69.24c-24.24,-22.04 -59.82,-27.54 -89.89,-13.9l-158.86,72.04c-30.07,13.64 -48.61,43.69 -46.84,75.92l5.58,101.25c-15.48,25.94 -18.22,58.54 -4.19,87.79l58.42,121.86c5.69,11.88 20.21,17.02 32.42,11.48l14.74,-6.69c12.21,-5.54 17.49,-19.66 11.8,-31.53l-10.31,-21.51zm-41,-295.56l158.85,-72.05c12.09,-5.49 26.21,-3.3 35.96,5.56l47.98,43.62l-122.81,55.71l-135.2,61.32l-3.52,-63.79c-0.71,-12.96 6.64,-24.88 18.74,-30.37zm20.38,252.55c-8.13,3.69 -17.82,0.25 -21.61,-7.65l-20.62,-43.01c-11.37,-23.72 -0.78,-52.01 23.6,-63.07l265.3,-120.33c24.38,-11.06 53.47,-0.76 64.83,22.95l20.62,43.01c3.79,7.91 0.26,17.34 -7.87,21.02l-324.25,147.08zm368.43,-23.02c-61.75,0 -112,46.65 -112,104c0,13.25 10.75,24 24,24s24,-10.75 24,-24c0,-30.87 28.72,-56 64,-56s64,25.12 64,56c0,13.25 10.75,24 24,24s24,-10.75 24,-24c0,-57.34 -50.25,-104 -112,-104z" />
                    </symbol>
                </defs>
            </svg>      
            ${imageB64 ? `<i style="display:block;width:100%;top:0;position:absolute;"><img class="image-inner margin" src="${checkBase64(imageB64)}"/></i>` : ''}  
        `
    });
};
