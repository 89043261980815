import { styled } from '@mui/material/styles';
import { IconButton, IconButtonProps } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Кнопка иконка для раскрытия коллапс, инфо и т.п.
// Унаследован от IconButton, поэтому может принять все его пропсы
// Пример использования в шапке

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMoreButton = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

interface ExpandProps extends IconButtonProps {
    expand: boolean;
    fontSize?: 'inherit' | 'large' | 'medium' | 'small';
}

const Expand = ({ fontSize = 'inherit', expand, ...props }: ExpandProps) => {
    return (
        <ExpandMoreButton size="small" expand={expand} {...props}>
            <ExpandMoreIcon fontSize={fontSize} />
        </ExpandMoreButton>
    );
};

export default Expand;
