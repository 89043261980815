import titles from '../../../../../helpers/constants/titles';
import renderAddress from '../../../../../helpers/renderAddress';
import { IReport, ICamera, } from './types';
import { LSContainer, LSContentColumn, LSContentItem } from '../../../../common/List';

interface ReportItemProps {
    report: IReport
    label?: string
}

interface ItemProps {
    item: ICamera
    index: number
}

export const ReportItem = (props: ReportItemProps) => {
    const { report } = props;
    const { title, cameras, value, unit } = report;

    const Item = ({ item, index }: ItemProps) => {
        return (
            <LSContentItem>
                <LSContentColumn>{index + 1}</LSContentColumn>
                <LSContentColumn>{item.name}</LSContentColumn>
                <LSContentColumn>{renderAddress(item.address)}</LSContentColumn>
            </LSContentItem>
        );
    };

    return (
        <>
            <h2>{title} - {value} {unit}</h2>
            <LSContainer
                headers={[
                    { title: titles.NUM, width: '10%' },
                    { title: titles.NAME_CAMERA, width: '20%' },
                    { title: titles.ADDRESS, width: '40%' },
                ]}>

                {cameras.map((item: ICamera, i: number) => (
                    <Item
                        key={item?.id}
                        item={item}
                        index={i}
                    />
                ))}
            </LSContainer>
        </>
    );
};