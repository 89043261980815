import { loadTransportIncidentHistory } from '../../../../../../redux/SituationalPlans/actions';
import titles from '../../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import Modal from '../../../../../common/Modal';
import HistoryList from '../../../../../common/HistoryList';
import HistoryItemInfo from './HistoryItemInfo';

const History = ({ isOpen, onClose, id }) => {

    const closeForm = () => {
        onClose(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            title={titles.HISTORY_OF_CHANGES}
            onClose={closeForm}
            noPadding={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: closeForm
                    }
                ]}
            />}
        >      
            <div className="modal__form">
                <HistoryList
                    storeName="SituationalPlans"
                    storeProp="transportIncidentHistory"
                    loadingProp="loadingTransportIncidentHistory"
                    loadAction={loadTransportIncidentHistory}
                    id={id}
                    ItemInfo={HistoryItemInfo}
                />
            </div>
        </Modal>
    );
};

export default History;