import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiItsMethods = {

    getSettings: async (params) => {
        const response = await instance.get(apiUrls.getSettings(), {
            params,
        });
        return response.data;
    },

    editSettings: async (id, data) => {
        const response = await instance.put(apiUrls.editSettings(id), data);
        return response.data;
    },

    editSettingsList: async (data) => {
        const response = await instance.put(apiUrls.editSettingsList(), data);
        return response?.data;
    },

    getGraphList: async (params) => {
        const response = await instance.get(apiUrls.getGraphList(), { params });
        return response.data;
    },

    getOneGraph: async (params) => {
        const { url , ...props } = params;
        const response = await instance.get(url, { params: props });
        return response.data;
    },

    getDashboard: async (params) => {
        const response = await instance.get(apiUrls.getDashboard(), { params });
        return response.data;
    },

    getEfficiencyDtp: async (params) => {
        const response = await instance.post(apiUrls.getEfficiencyDtp(), { ...params });
        return response.data;
    },

    getFullGraphList: async (params) => {
        const response = await instance.get(apiUrls.getFullGraphList(), { params });
        return response.data;
    },

    getFullGraphPeriodList: async (params) => {
        const response = await instance.post(apiUrls.getFullGraphPeriodList(), { ...params });
        return response.data;
    },

    getEfficiencyTraffic: async (params) => {
        const response = await instance.get(apiUrls.getEfficiencyTraffic(), { params });
        return response.data;
    },

    getUserAvailableDashboardList: async (params) => {
        const response = await instance.get(apiUrls.getUserAvailableDashboardList(), { params });
        return response.data;
    },

    getUserDashboard: async (params) => {
        const response = await instance.get(apiUrls.getUserDashboard(), { params });
        return response.data;
    },

    editUserDashboard: async (data) => {
        const response = await instance.put(apiUrls.editUserDashboard(), data);
        return response.data;
    },

    getTrafficForecast: async (params) => {
        const response = await instance.get(apiUrls.getTrafficForecast(), { params });
        return response.data;
    },

    getCriticalSettings: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getCriticalSettings(), { params: { page, limit, ...data } });
        return response.data;
    },

    editCriticalSettings: async (data) => {
        const response = await instance.put(apiUrls.editCriticalSettings(), data);
        return response.data;
    },

    deleteCriticalSettings: async (id) => {
        const response = await instance.delete(apiUrls.deleteCriticalSettings(id));
        return response.data;
    },

    createCriticalSetting: async (data) => {
        const response = await instance.post(apiUrls.createCriticalSetting(), data);
        return response.data;
    },

    getCriticalSettingsKeys: async () => {
        const response = await instance.get(apiUrls.getCriticalSettingsKeys());
        return response.data;
    },

    reportsEfficiencyDtpReport: async (params) => {
        const response = await instance.get(apiUrls.reportsEfficiencyDtpReport(), { params });
        return response.data;
    },

    loadShowcase: async () => {
        const response = await instance.post(apiUrls.loadShowcase());
        return response?.data;
    },

    getSettingsGroup: async () => {
        const response = await instance.get(apiUrls.getSettingsGroup());
        return response?.data;
    },

    editSettingsGroup: async (id, data) => {
        const response = await instance.put(apiUrls.editSettingsGroup(id), data);
        return response?.data;
    },

    loadItsDevelopmentLevels: async (params) => {
        const response = await instance.get(apiUrls.loadItsDevelopmentLevels(), { params });
        return response?.data;
    },

    editItsDevelopmentLevels: async (id, data) => {
        const response = await instance.put(apiUrls.editItsDevelopmentLevels(id), data);
        return response?.data;
    },

    loadInfrastructureStatistics: async (params) => {
        const response = await instance.get(apiUrls.loadInfrastructureStatistics(), { params });
        return response?.data;
    },

    editInfrastructureStatistics: async (id, data) => {
        const response = await instance.put(apiUrls.editInfrastructureStatistics(id), data);
        return response?.data;
    },

    getDashboards: async (params) => {
        const response = await instance.get(apiUrls.getDashboards(), { params });
        return response?.data;
    },

    getDashboardsItem: async (params) => {
        const response = await instance.get(apiUrls.getDashboardsItem(), { params });
        return response?.data;
    },

    getDashboardItemUser: async (params) => {
        const response = await instance.get(apiUrls.getDashboardItemUser(),  { params });
        return response?.data;
    },

    createDashboardItemUser: async (data) => {
        const response = await instance.post(apiUrls.createDashboardItemUser(), data);
        return response?.data;
    },

    editDashboardItemUser: async (id, data) => {
        const response = await instance.put(apiUrls.editDashboardItemUser(id), data);
        return response?.data;
    },

    editDashboardItemUserMultiply: async (data) => {
        const response = await instance.put(apiUrls.editDashboardItemUserMultiply(), data);
        return response?.data;
    },

    deleteDashboardItemUser: async (id) => {
        const response = await instance.delete(apiUrls.deleteDashboardItemUser(id));
        return response?.data;
    },

};

export default ApiItsMethods;
