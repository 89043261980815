import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    changeTrafficScheduleStatus,
    loadCurrentTrafficScheduleEvents,
} from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { getHumanDate } from 'helpers/date.config';
import { useValidation } from 'helpers/hooks';
import {
    ActionMore,
    ActionsButtons,
    LSContentColumn,
    LSContentItem,
} from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import CircleStatus from 'components/common/CircleStatus';

import Info from './Info';
import ModalForm from './ModalForm';
import EditTrafficScheduleTable from './EditTrafficScheduleTable';

const archive = 'Вы действительно хотите удалить график?';
const error = 'По графику созданы наряды, удаление невозможно!';
const invalidStatus = 'Вы действительно хотите перевести статус графика в недействующий ?';
const validStatus = 'Вы действительно хотите перевести статус графика в действующий ?';

const renderStatusColor = (status) => {
    const color = {
        1: { color: '#00ae5c' },
        2: { color: '#444' },
    };
    return color[status];
};

const Item = ({
    item,
    statuses,
    types,
    currentData,
    switchToSchedule,
    reloadList,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const validation = useValidation();

    const trafficScheduleEvents = useSelector(transportPassengerSelectors.trafficScheduleEvents);
    const trafficScheduleEventsLoading = useSelector(transportPassengerSelectors.trafficScheduleEventsLoading);

    const [isEditStatus, setIsEditStatus] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isInfo, setIsInfo] = useState(false);
    const [isOpenModalCopy, setIsOpenModalCopy] = useState(false);

    const changeStatus = (event) => {
        event.stopPropagation();
        setIsEditStatus(true);
    };

    const openInfo = () => {
        setIsInfo(!isInfo);
    };

    const openEditTrafficScheduleTable = () => {
        // if (!trafficScheduleEvents[item?.id]) {
        //     dispatch(loadCurrentTrafficScheduleEvents(item?.id));
        // }
        dispatch(loadCurrentTrafficScheduleEvents(item?.id));
        setIsEdit(true);
    };

    const fetchTrafficScheduleEvents = () => {
        if (!isInfo) {
            dispatch(loadCurrentTrafficScheduleEvents(item?.id));
        }
        openInfo();
    };

    const routeToOrderControl = () => {
        history.push(`/dictionaries/passenger-transport/workorders-control/${item?.id}`);
    };

    const onCloseStatusForm = () => {
        validation.clear();
        if (isEditStatus) {
            setIsEditStatus(false);
        }
        if (isDelete) {
            setIsDelete(false);
        }
    };

    const onSuccessStatusForm = () => {
        if (isDelete) {
            dispatch(changeTrafficScheduleStatus(item?.id, { status: 3 }, () => {
                reloadList(true);
                setIsDelete(false);
            }));
        }

        const callback = () => {
            reloadList();
            setIsEditStatus(false);
        };

        if (isEditStatus) {
            item?.status === 1
                ? dispatch(changeTrafficScheduleStatus(item?.id, { status: 2 }, callback))
                : dispatch(changeTrafficScheduleStatus(item?.id, { status: 1 }, callback));
        }
    };

    return (
        <>
            <LSContentItem onClick={fetchTrafficScheduleEvents}>
                {/* параметры прокинутся автоматически из LSContainer */}
                <LSContentColumn>
                    <CircleStatus title={statuses[item?.status]} color={renderStatusColor(item?.status)?.color} />
                </LSContentColumn>
                <LSContentColumn>
                    {item?.name}
                </LSContentColumn>

                <LSContentColumn>
                    {item?.diagram}
                </LSContentColumn>

                <LSContentColumn>
                    {getHumanDate(item?.start_at)}
                </LSContentColumn>

                <LSContentColumn>
                    {getHumanDate(item?.end_at)}
                </LSContentColumn>

                <LSContentColumn>
                    {item?.type_text}
                </LSContentColumn>


                <LSContentColumn isActions>
                    {/* действия */}
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <i className="far fa-calendar-alt" />,
                                name: 'Расписание',
                                onClick: () => switchToSchedule(item, 'schedule'),
                                disabled: !currentData
                            },
                            {
                                icon: <i className="fas fa-tools"/>,
                                name: 'Контроль нарядов',
                                onClick: routeToOrderControl,
                            },
                            {
                                ...buttonsTypes.copyIcon,
                                onClick: () => setIsOpenModalCopy(true),
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: openEditTrafficScheduleTable,
                                disabled: item?.status !== 2
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setIsDelete(true),
                            },
                            {
                                ...buttonsTypes.changeStatusIcon,
                                onClick: changeStatus,
                            },
                        ]}
                    />

                    {/* иконка раскрытия */}
                    <ActionMore
                        isOpen={isInfo}
                        onClick={fetchTrafficScheduleEvents}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isOpenModalCopy && (
                <ModalForm
                    item={item}
                    isOpen={isOpenModalCopy}
                    onClose={() => setIsOpenModalCopy(false)}
                    isNew={true}
                    statuses={statuses}
                    types={types}
                    isClone={true}
                    reloadList={reloadList}
                />
            )}

            {isEdit && (
                <EditTrafficScheduleTable
                    id={item?.id}
                    title={item?.name}
                    loading={trafficScheduleEventsLoading}
                    tableData={trafficScheduleEvents[item?.id]}
                    routeDirectNum={item?.route_direct?.num}
                    routeBackNum={item?.route_back?.num}
                    trafficScheduleStartAt={item?.start_at}
                    isOpen={isEdit}
                    onClose={() => setIsEdit(false)}
                />
            )}
            {(isDelete || isEditStatus) && (
                <ConfirmDelete
                    open={isDelete || isEditStatus}
                    onClose={onCloseStatusForm}
                    onSuccess={onSuccessStatusForm}
                    message={isDelete && !validation.isKey('status')
                        ? archive
                        : isDelete && validation.isKey('status')
                            ? error
                            : isEditStatus && item?.status === 1
                                ? invalidStatus
                                : validStatus
                    }
                    onlyClose={isDelete && validation.isKey('status')}
                />
            )}
            {isInfo && (
                <Info
                    isOpen={isInfo}
                    data={trafficScheduleEvents[item?.id]}
                    id={item?.id}
                    item={item}
                />
            )}
        </>
    );
};

export default Item;
