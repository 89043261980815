import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStoreProp } from '../../../../../helpers/hooks';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import { editUserDashboard, loadUserAvailableDashboardList, loadUserDashboard } from '../../../../../redux/Efficiency/actions';
import { efficiencySelectors } from '../../../../../redux/Efficiency';
import Loading from '../../../../common/Loading';
import SelectedDashboards from './SelectedDashboards';
import Template from './Template';

const DashboardsSetting = () => {
    const dispatch = useDispatch();

    const initialState = {
        column_count: '',
        dashboards: [],
    };

    const [data, setData] = useState(initialState);
    const [updateData, setUpdateData] = useState(false);

    const userDashboard = useSelector(efficiencySelectors.userDashboard);
    const loading = useSelector(efficiencySelectors.loadingUserDashboard);

    const dashboardList = useStoreProp(loadUserAvailableDashboardList, 'efficiency', 'userAvailableDashboardList');

    const countArray = useMemo(() => [...Array(12).keys()].map(el => ++el), []);
    
    const onChange = (e) => {
        const { name, value } = e.target;
        
        setData({
            ...data,
            [name]: value,
        });
        
    };

    const handleSave = () => {
        const prepareData = {
            ...data,
            dashboards: data.dashboards.map(({ id }) => id)
        };

        const paramsWithoutEmpty = removeEmptyFields(prepareData);

        dispatch(editUserDashboard(paramsWithoutEmpty));
    };

    useEffect(() => {
        dispatch(loadUserDashboard());
        setUpdateData(true);
    }, [dispatch]);
    

    useEffect(() => {
        if(userDashboard?.dashboards && updateData){
            const dashboards = userDashboard?.dashboards?.length > 0 
                ? userDashboard?.dashboards 
                : Object.keys(dashboardList);
            
            setData({
                column_count: userDashboard?.column_count,
                dashboards: dashboards.map(item => ({ id: Number(item), name: dashboardList[item] }))
            });
            setUpdateData(false);
        }        
    }, [dashboardList, updateData, userDashboard]);

    return (
        <>
            <h1>Настройка дашбордов</h1>
            {loading && <Loading circular/>}
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl className="block" size="small" variant="outlined">
                        <InputLabel>Количество карточек в строке</InputLabel>
                        <Select
                            value={data.column_count || 4}
                            label="Количество карточек в строке"
                            name={'column_count'}
                            onChange={onChange}
                        >
                            {countArray.map((el) => (
                                <MenuItem key={el} value={el}>{el}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {userDashboard?.dashboards && (
                        <SelectedDashboards
                            handleSave={handleSave}
                            selected={data.dashboards}
                            onChange={(dashboards) => setData({ ...data, dashboards })}
                        />
                    )}
                </Grid>
                <Grid item xs={6}>
                    <Template
                        columns={data?.column_count}
                        dashboards={data?.dashboards}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default DashboardsSetting;
