import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowFindVehicles } from '../../../../redux/TransportRoad/actions';
import { transportRoadSelectors } from '../../../../redux/TransportRoad';
import FindTransport from './FindTransport';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();
    const showFindVehicles = useSelector(transportRoadSelectors.showFindVehicles);

    return (
        <>
            {/* форма поиска */}
            {showFindVehicles && (
                <FindTransport
                    latlng={showFindVehicles}
                    onClose={() => {
                        dispatch(setShowFindVehicles(null));
                    }}
                />
            )}
        </>
    );
};

export default CollectorForms;
