import React from 'react';

// компонент
const Marker = ({ color = '#000' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 107 130">
        <g transform="translate(0,130) scale(0.10000000149011612,-0.10000000149011612) " fill={color} id="svg_1">
            <path d="m445,1288.5c-100,-19 -206,-75 -281,-150c-104,-104 -154,-228 -154,-381c0,-144 38,-245 149,-392c64,-85 297,-316 348,-345c30,-17 31,-17 71,12c68,49 237,216 302,299c157,200 210,350 181,517c-25,142 -121,287 -241,364c-108,70 -251,98 -375,76z" id="svg_2"/>
        </g>
    </svg>
);

// html
export const getMarker = (color = '#000') => `
    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 107 130">
        <g transform="translate(0,130) scale(0.10000000149011612,-0.10000000149011612) " fill=${color}>
            <path d="m445,1288.5c-100,-19 -206,-75 -281,-150c-104,-104 -154,-228 -154,-381c0,-144 38,-245 149,-392c64,-85 297,-316 348,-345c30,-17 31,-17 71,12c68,49 237,216 302,299c157,200 210,350 181,517c-25,142 -121,287 -241,364c-108,70 -251,98 -375,76z"/>
        </g>
    </svg>`;

export default Marker;