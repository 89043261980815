import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';


const ModelListItem = ({ item, toggleOpenEdit, toggleOpenDelete, permissions }) => {
    return (
        <LSContentItem>
            <LSContentColumn title={titles.BRAND}>
                {item?.brand?.name_ru || messages.NO_DATA}
            </LSContentColumn>
            <LSContentColumn title={titles.MODEL}>
                {item?.name_ru || messages.NO_DATA}
            </LSContentColumn>
            <LSContentColumn isActions>
                <ActionsButtons
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: toggleOpenEdit,
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: toggleOpenDelete,
                        }
                    ]}
                />
            </LSContentColumn>
        </LSContentItem>
    );
};

export default ModelListItem;
