import messages from '../../../helpers/constants/messages';
import { 
    getDateWithDuration,
    compareDatesByParams,
    fullDateTimeWithTimeZone
} from '../../../helpers/date.config';
import removeEmptyFields from '../../../helpers/removeEmptyFields';

export const typeOptions = {
    by_hour: { count: 3, unit: 'day' },
    by_day: { count: 3, unit: 'month' },
    by_month: { count: 3, unit: 'year' },
};

export const renderError = (params, start, end, type, parameters, reportTypes) => {
    if (parameters.length > 0) {
        if (parameters.includes(start) && parameters.includes(end)) {

            // дата не выбрана
            if (!params[start] || !params[end]) {
                const error = {
                    error: true,
                    message: 'Выберите дату и время'
                };
                return error;
            }

            // дата начала больше даты конца
            if (params[start] && params[end] && new Date(params[start]) >= new Date(params[end])){
                const error = {
                    error: true,
                    message: messages.ERROR_DATE_RANGE
                };
                return error;
            }
            // сдвиг по дате
            const addDate = typeOptions[type];
            if (addDate) {
                const { count, unit } = addDate;
                const startDate = getDateWithDuration({ [unit]: count }, params[start]);
                const compare = compareDatesByParams(params[end], startDate);
                // если разница между start и end больше периода из count
                if (compare.milliseconds >= 0) {
                    const currentType = reportTypes?.find(({ value }) => type === value);
                    const error = {
                        error: true,
                        message: `Разница между датой и временем конца и начала периода должна быть ${currentType.hint}`
                    };
                    return error;
                }
            }
        } else if ((parameters.includes(start) && !params[start])
            || (parameters.includes(end) && !params[end])) {
            const error = {
                error: true,
                message: 'Выберите дату и время'
            };
            return error;
        }
    }
    return {
        error: false
    };
};

export const paramsWithoutEmpty = (formValues) => {
    const result = {
        ...formValues,
        start_date: fullDateTimeWithTimeZone(formValues.start_date),
        end_date: fullDateTimeWithTimeZone(formValues.end_date),
        id_list: formValues?.id_list?.map(({ id }) => id) || [],
    };

    return removeEmptyFields(result);
};