

import { config } from '../../config';

const apiMenuUrl = () => config.getGeneral('urls').apiMenuUrl;

const apiUrls = {
    // все возможные сценарии - eputs, bpm, zhkh и пр (пока не используется)
    getScenarios: () => `${apiMenuUrl()}/menu/scenario`,
    // роли для определенного сценария (пока не используется)
    getScopes: (scenario = 'default') => `${apiMenuUrl()}/menu/scopes/${scenario}`,

    getAllMenus: (service = 'eputs') => `${apiMenuUrl()}/menu/${service}`,

    createMenuItem: () => `${apiMenuUrl()}/menu`,

    editMenuItem: (id) => `${apiMenuUrl()}/menu/${id}`,

    deleteMenuItem: (id) => `${apiMenuUrl()}/menu/${id}`,
};

export default apiUrls;
