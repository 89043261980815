import { useCallback, useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';

import { getTitle } from './helper';

const Graphics = ({
    data,
    currentGroup,
    currentType,
    currentPuids,
    currentOption,
}) => {
    const [itemsToShow, setItemsToShow] = useState(1);
    const [displayKeys, setDisplayKeys] = useState(Object.keys(data).slice(0, itemsToShow));
    const containerRef = useRef(null);

    const loadMoreItems = useCallback(() => {
        setItemsToShow(prevItemsToShow => {
            const newItemsToShow = prevItemsToShow + 1;
            return newItemsToShow > Object.keys(data).length ? prevItemsToShow : newItemsToShow;
        });
    }, [data]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                loadMoreItems();
            }
        }
    }, [loadMoreItems]);

    useEffect(() => {
        setDisplayKeys(Object.keys(data).slice(0, itemsToShow));
    }, [data, itemsToShow]);

    return (
        <>
            <h2 style={{ marginBottom: '1rem' }}>{currentGroup?.description || ''} - {currentType?.description || ''}</h2>

            <div ref={containerRef} onScroll={handleScroll} style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {displayKeys.map(key => {
                    const list = data[key] || [];
                    const puid = currentPuids[key] || {};

                    const options = {
                        responsive: true,
                        plugins: {
                            tooltip: {
                                mode: 'point',
                                usePointStyle: true,
                                callbacks: {
                                    label: (context) => `${context?.formattedValue} ${currentGroup?.unit || ''}`,
                                },
                            },
                            legend: {
                                display: false,
                            },
                        },
                        scales: {
                            y: {
                                beginAtZero: true,
                                title: {
                                    display: true,
                                    text: currentGroup?.unit || ''
                                }
                            },
                        },
                    };

                    const {
                        labels,
                        dataset,
                    } = list?.reduce((res, item) => ({
                        labels: [
                            ...res.labels,
                            currentOption?.format(item?.date)
                        ],
                        dataset: [
                            ...res.dataset,
                            item?.value
                        ]
                    }), { labels: [], dataset: [] });

                    const gData = {
                        labels,
                        datasets: [
                            {
                                backgroundColor: '#00ff00',
                                borderColor: '#00ff00',
                                data: dataset,
                            }
                        ]
                    };

                    return (
                        <div key={key} style={{ marginBottom: '2rem' }}>
                            <h3>{getTitle(puid)}</h3>

                            {list?.length === 0
                                ? (
                                    <div>
                                        За указанный период нет данных
                                    </div>
                                )
                                : (
                                    <div>
                                        <Line
                                            style={{ maxHeight: '400px' }}
                                            options={options}
                                            data={gData}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Graphics;
