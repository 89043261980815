import { showMessage } from '../../redux/Message/actions';
import messagetypes from '../../redux/Message/messagetypes';

// получаем ответ, берем из него текст и выводим его как сообщение
export function messageHelper(response: any) {
    if (!response) return;

    let message = 'Отчет формируется, по его готовности придет уведомление';
    
    if (Array.isArray(response.data)) {
        // Если data является массивом
        message = response.data.join(' ');
    } else if (Array.isArray(response) && (typeof response[0] === 'string')) {
        // Если response является массивом со строкой
        message = response.join('');        
    } else if (typeof response === 'string') {
        // Если response является строкой
        message = response;
    } else if (typeof response.data === 'string') {
        // Если data является строкой
        message = response.data;
    } else if (typeof response.data === 'object' && response.data.message) {
        // Если data является объектом и содержит поле message, то берем сообщение из этого поля
        message = response.data.message;
    }

    showMessage(messagetypes.success, message);
}