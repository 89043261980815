import messages from '../../../helpers/constants/messages';

interface NoDataProps {
    message?: string;
}

const NoData = ({ message }: NoDataProps) => {
    return (
        <div style={{ height: '100%' }} className="flex-center">
            {message || messages.DATA_IS_NOT_FOUND}
        </div>
    );
};

export default NoData;