import { LSContentColumn, LSContentItem } from 'components/common/List';
import CircleStatus from 'components/common/CircleStatus';
import NoData from 'components/common/Information/NoData';

const EmployeesTable = ({ data }) => {

    return (
        data.map(item => 
            item?.flights?.length > 0
                ? <>
                    {item?.flights?.map((el, i) => (
                        <LSContentItem 
                            key={item.employee_name + el.flight_id + i}
                        >
                            <LSContentColumn>
                                <CircleStatus title={el.status} color={el.status === 'Выполнен' ? 'lightGreen' : '#e44141'} />
                            </LSContentColumn>
                            <LSContentColumn>
                                {item.employee_name}
                            </LSContentColumn>
                            <LSContentColumn>
                                {el.brigade_number}
                            </LSContentColumn>
                            <LSContentColumn>
                                {el.date_at}
                            </LSContentColumn>
                            <LSContentColumn>
                                {el.work_order_id}
                            </LSContentColumn>
                            <LSContentColumn>
                                {el.flight_id}
                            </LSContentColumn>
                            <LSContentColumn>
                                {el.coefficient}
                            </LSContentColumn>
                            <LSContentColumn>
                                {el.vehicle_number}
                            </LSContentColumn>
                            <LSContentColumn>
                                {el.flight_scheduled_time}
                            </LSContentColumn>
                            <LSContentColumn>
                                {el.flight_actual_time}
                            </LSContentColumn>
                            <LSContentColumn>
                                {el.mileage}
                            </LSContentColumn>
                            <LSContentColumn></LSContentColumn>
                            <LSContentColumn></LSContentColumn>
                        </LSContentItem>
                    ))}
                    <LSContentItem>
                        <LSContentColumn></LSContentColumn>
                        <LSContentColumn>
                            <b>Итого</b>
                        </LSContentColumn>
                        <LSContentColumn></LSContentColumn>
                        <LSContentColumn></LSContentColumn>
                        <LSContentColumn>
                            <b>{item.total?.flights}</b>
                        </LSContentColumn>
                        <LSContentColumn></LSContentColumn>
                        <LSContentColumn></LSContentColumn>
                        <LSContentColumn></LSContentColumn>
                        <LSContentColumn>
                            <b>{item.total?.flight_scheduled_time}</b>
                        </LSContentColumn>
                        <LSContentColumn></LSContentColumn>
                        <LSContentColumn>
                            <b>{item.total?.mileage}</b>
                        </LSContentColumn>
                        <LSContentColumn></LSContentColumn>
                        <LSContentColumn></LSContentColumn>
                    </LSContentItem>
                </>
                : <NoData/>
        )
    );
};

export default EmployeesTable;