import React, { useEffect } from 'react';
import icon from '../icons/icon';
import { getColorByStatus } from '../helper';
import Information from './Information';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from '../../../../../redux/SituationalPlans';


const PopUp = (props) => {
    const { id: external_id } = props;
    const dispatch = useDispatch();

    const polygonEvents = useSelector(SituationalPlansSelectors.polygonEvents);
    const active = useSelector(SituationalPlansSelectors.activeEvents);

    const data = polygonEvents.find(({ id }) => external_id === id) || {};

    const status = data?.type_id || 0;

    const { name } = data;

    useEffect(() => {
        if (active?.id !== data?.id) {
            dispatch(actions.setActiveEvents({
                ...data,
                isClick: true,
            }));
        }
    }, []);

    useEffect(() => {
        return () => {
            dispatch(actions.resetActiveEvents());
        };
    }, []);

    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: getColorByStatus(status) }}>
                <div className="map-popup_header_">
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                    <span>
                        {name}
                    </span>
                </div>
            </div>
            <div className="map-popup_body">
                <Information
                    data={data}
                />
            </div>
        </div>
    );
};

export default PopUp;
