import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../redux/TransportRoad/actions';
import { transportRoadSelectors } from '../../../../../redux/TransportRoad';
import L from 'leaflet';
import { fullDateTime, fullDateTimeWithTimeZone } from '../../../../../helpers/date.config';


const History = (props) => {
    const {
        uuid = null,
        data = null,
        date_start = new Date(),
        date_end = new Date(),
        moveToAfterInit = false,
        ...params
    } = props;

    const polyRef = useRef([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (uuid) {
            dispatch(actions.loadVehicleHistoryModal(uuid, {
                date_start: fullDateTimeWithTimeZone(date_start),
                date_end: fullDateTimeWithTimeZone(date_end),
            }));
        }
    }, [uuid]);

    useEffect(() => {
        return () => {
            dispatch(actions.clearVehicleHistoryModal());
        };
    }, []);

    const vehicleHistory = useSelector(transportRoadSelectors.historyModal);

    const currentData = useMemo(() => {
        if (uuid) {
            return vehicleHistory?.reduce((res, { lat, lon, color, speed, created_at }) => {
                return [
                    ...res,
                    { lat, lon, color, speed, created_at },
                ];
            }, []);
        }
        return data;
    }, [uuid, data, vehicleHistory]);

    useEffect(() => {
        if (currentData.length > 0) {
            const {
                parent,
                map,
            } = params;

            if (moveToAfterInit) {
                const allLine = L.polyline(currentData.map(({ lat, lon }) => ({ lat, lon })));
                const boundce = allLine.getBounds();
                map.fitBounds(boundce);
                allLine.remove();
            }

            Object.keys(currentData).map(index => {
                if (index < currentData.length - 1) {
                    const {
                        lat, lon, color = '#FF0000', speed, created_at
                    } = currentData[index];
                    const nextPoint = currentData[parseInt(index) + 1];

                    const oneLine = L
                        .polyline(
                            [
                                [lat, lon],
                                [nextPoint.lat, nextPoint.lon]
                            ],
                            {
                                color: '#FF0000',
                                weight: 5,
                                smoothFactor: 1
                            }
                        );

                    const html = `
                        <div><strong>Дата, время: </strong>${created_at ? fullDateTime(created_at) : 'нет данных'}</div>
                        <div><strong>Скорость: </strong>${speed >= 0 ? `${speed} км/ч` : 'нет данных'}</div>
                    `;
                    const toolTip = L.tooltip({
                        sticky: true,
                        direction: 'top',
                        offset: [0, -5],
                    }).setContent(html);
                    oneLine.bindTooltip(toolTip);

                    parent.addLayer(oneLine);

                    polyRef.current.push(oneLine);
                }
            });

            return () => {
                while (polyRef.current.length > 0) {
                    const oneLine = polyRef.current.pop();
                    parent.removeLayer(oneLine);
                }
            };
        }
    }, [currentData]);

    return null;
};

export default memo(History);
