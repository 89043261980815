import React, { useState } from 'react';
import {
    IconButton,
    InputAdornment,
    TextField,
    LinearProgress
    // Select
} from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import {
    useDebounce,
    // useValidation,
    useChangeProject
} from '../../../../helpers/hooks';
import { loadDadataAddressList, clearDadataAddressList } from '../../../../redux/Dadata/actions';
import './style.scss';
import titles from '../../../../helpers/constants/titles';
import List from './List';
import { authSelectors } from '../../../../redux/Auth';


function MapSearch(props) {
    const { map } = props;
    const dispatch = useDispatch();
    const [query, setQuery] = useState('');
    // const validation = useValidation();

    const {
        addressList,
        loading,
    } = useSelector(({ dadata }) => dadata);
    
    const projectName = useSelector(authSelectors.projectName);

    const fetchList = (query) => dispatch(loadDadataAddressList(20, `${projectName} ${query}`));
    const fetchListDebounce = useDebounce(fetchList, 800);
    const clearList = () => dispatch(clearDadataAddressList());

    const listConvert = addressList?.data?.reduce((res, item) => {
        const {
            city_name,
            settlement,
            street,
            house,
            fias,
            geo_lat,
            geo_lon
        } = item;

        const titleArray = [
            city_name,
            settlement,
            street,
            house
        ].reduce((res, item) => item ? [...res, item] : res, []);

        if (titleArray.length > 0 && geo_lat && geo_lon) {
            return [
                ...res,
                {
                    title: titleArray.join(', ') || '',
                    fias,
                    geo_lat,
                    geo_lon
                }
            ];
        }
        return res;
    }, []);

    const handleChange = (e) => {
        const { value } = e.target;
        setQuery(value);

        if (value.length > 3) {
            fetchListDebounce(query);
        } else {
            fetchListDebounce.clear();
        }

        clearList();
        // validation.clear();
    };

    const resetSearch = () => {
        setQuery('');
        fetchListDebounce.clear();
        clearList();
        // validation.clear();
    };

    const adornment = () => {
        const adornment = { };

        // if (loading) {
        //     adornment.startAdornment = (
        //         <InputAdornment position="start">
        //             <CircularProgress />
        //         </InputAdornment>
        //     );
        // }
        // adornment.startAdornment = (
        //     <InputAdornment position="start">
        //         <Filter />
        //     </InputAdornment>
        // );

        if (query.length) {
            adornment.endAdornment = (
                <InputAdornment position="end">
                    <IconButton
                        onClick={resetSearch}
                        size="small"
                        title={titles.CLEAR_FIELD}
                    >
                        <Clear />
                    </IconButton>
                </InputAdornment>
            );
        }

        return adornment;
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') fetchList(query);
    };

    useChangeProject(() => {
        resetSearch();
    });

    return (
        <div className="map-search-control">
            <div className="field">
                <TextField
                    className={cn('textBlock', query.length > 0 ? 'active' : '')}
                    value={query}
                    onChange={handleChange}
                    label={titles.SEARCH_BY_ADDRESS}
                    variant="outlined"
                    size="small"
                    InputProps={adornment()}
                    inputProps={{ onKeyDown: handleEnter }}
                    // error={validation.isKey(propName)}
                />
            </div>

            {loading && <div className="loading"><LinearProgress /></div>}

            <List
                list={listConvert}
                onClick={(item) => {
                    const { geo_lat, geo_lon, title } = item;
                    map.flyTo([geo_lat, geo_lon], 15);
                    // map.setView([geo_lat, geo_lon], 20);
                    setQuery(title);
                    clearList();
                }}
            />
        </div>
    );
}

export default MapSearch;
