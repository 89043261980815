import React, { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

import SelectType from './SelectType';

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{ page: number; data: {} }>>
}

const Filter = ({ setParams }: FilterProps) => {
    const initialState = {
        name: '',
        type_id: '',
        serial_number: '',
    };

    const [filterData, setFilterData] = useState(initialState);

    const handleChange = (name: string, value: unknown) => {
        setFilterData({ ...filterData, [name]: value });
    };

    const resetFilters = (needRefresh: boolean) => {
        setFilterData(initialState);

        if (needRefresh) {
            setParams((prev) => ({ ...prev, data: {} }));
        }
    };

    const search = () => {
        setParams({ page: 1, data: removeEmptyFields(filterData) });
    };

    const updateFilter = (filters: {}) => {
        setFilterData({ ...initialState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({
                page: 1,
                data: removeEmptyFields({
                    ...initialState,
                    ...filters,
                }),
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={search}
            filter={filterData}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    value={filterData.name}
                    name="name"
                    onChange={(e) => handleChange('name', e.target.value)}
                    type="text"
                />

                <SelectType
                    value={filterData.type_id}
                    onChange={(value) => handleChange('type_id', value)}
                />

                <TextField
                    label={titles.SERIAL_NUMBER}
                    variant="outlined"
                    size="small"
                    value={filterData.serial_number}
                    onChange={(e) => handleChange('serial_number', e.target.value)}
                    type="text"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
