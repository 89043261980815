import { all, put, call, takeLatest } from 'redux-saga/effects';
import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

function* getListSaga({ payload }) {
    yield put(actions.loadingList(true));
    const response = yield call(api.getList, payload);
    if (response) {
        yield put(actions.loadedList(response));
    }
    yield put(actions.loadingList(false));
}

function* loadStatusesSaga() {
    yield put(actions.loadingStatuses(true));
    const response = yield call(api.getStatuses);
    if (response?.success) {
        yield put(actions.loadedStatuses(response.data));
    }
    yield put(actions.loadingStatuses(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.GET_LIST, getListSaga),
        takeLatest(types.LOAD_STATUSES, loadStatusesSaga),
    ]);
}
