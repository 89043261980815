import * as types from './types';

const initialState = {
    sidebar: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    sidebarLoading: false,
    sidebarParams: {
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25
    },
    filters: {},
    active: {},
    wsData: {},
    routesHistory: {},
    coords: [],
    coordsLoading: false,

    vehicles: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingVehicles: false,
    vehicleCategories: [],
    vehicleCategoriesLoaded: false,
    // loadingVehicleCategories: false,
    vehicleCharacteristics: [],
    brands: [],
    loadingBrands: false,
    models: [],
    loadingModels: false,
    radioTypes: [],
    loadingRadioTypes: false,
    vehicleCard: {},
    loadingVehicleCard: false,
    vehicleTypes: {},
    loadingVehicleTypes: false,
    vehicleFilteredList: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    loadingVehicleFilteredList: false,
    vehicleFilteredListFilter: {},
    filter: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    history: {},
    historyLoading: false,

    /* color speed */
    colorSpeed: [],
    colorSpeedList: {
        data: []
    },
    colorSpeedListLoading: false,
    colorSpeedListProps: {
        page: 1,
        // limit: 2
        limit: Number(localStorage.getItem('limit')) || 25,
    },
    historyModal: [],
    historyModalLoading: false,
    // categoriesVehicle: [],
    // categoriesVehicleLoaded: false,
    loadingButton: false,
    telemetryReceivers: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    telemetryReceiversLoading: false,
    telemetryReceiverTypes: {},
    vehicleCategoryList: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    loadingVehicleCategoryList: false,
    reportVehicleMileage: {
        data: {}
    },
    loadingReportByVehicles: false
};

// фильтрация машин по сокетам
const filteredMarkers = (filters, markers) => {
    if (
        filters?.organization_id?.length > 0
        || filters?.number?.length > 0
    ) {
        const organizations = filters?.organization_id?.map(({ id }) => parseInt(id)) || [];
        const grz = filters?.number?.toUpperCase().trim() || null;
        // фильтруем
        return Object.keys(markers).reduce((res, key) => {
            const item = markers[key];
            const organization_id = parseInt(item?.vehicle?.organization_id) || null;
            const number = item?.vehicle?.number?.toUpperCase() || null;

            // проверка по номеру
            const isInGrz = grz
                ? number && number?.includes(grz)
                : true;

            // проверка по организации
            const isInOrganizations = organizations.length
                ? organization_id && organizations.includes(parseInt(organization_id))
                : true;

            // if (isInOrganizations) {
            if (isInOrganizations && isInGrz) {
                return {
                    ...res,
                    [key]: item,
                };
            }

            return res;
        }, {});
    }

    return null;
};

export default function reducer (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.LOADED_SIDEBAR:
            const page = payload.meta?.current_page || 1;

            if (page === 1) {
                return {
                    ...state,
                    sidebar: payload,
                };
            }

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...payload,
                    data: [
                        ...state?.sidebar?.data,
                        ...payload?.data
                    ]
                },
            };

        case types.CLEAR_SIDEBAR:
            return {
                ...state,
                sidebar: initialState.sidebar,
            };

        case types.LOADING_SIDEBAR:
            return {
                ...state,
                sidebarLoading: payload,
            };

        case types.LOADING_VEHICLES: {
            return {
                ...state,
                loadingVehicles: payload,
            };
        }

        case types.LOADED_VEHICLES: {
            return {
                ...state,
                vehicles: payload,
            };
        }

        case types.LOADING_VEHICLE_CATEGORIES: {
            return {
                ...state,
                loadingVehicleCategories: payload,
            };
        }

        case types.LOADED_VEHICLE_CATEGORIES: {
            return {
                ...state,
                vehicleCategories: payload?.data || [],
                vehicleCategoriesLoaded: true,
            };
        }

        case types.LOADED_VEHICLE_CHARACTERISTICS: {
            return {
                ...state,
                vehicleCharacteristics: payload,
            };
        }

        case types.LOADING_BRANDS: {
            return {
                ...state,
                loadingBrands: payload,
            };
        }

        case types.LOADED_BRANDS: {
            return {
                ...state,
                brands: payload,
            };
        }

        case types.LOADING_MODELS: {
            return {
                ...state,
                loadingModels: payload,
            };
        }

        case types.LOADED_MODELS: {
            return {
                ...state,
                models: payload,
            };
        }

        case types.LOADING_RADIO_TYPES: {
            return {
                ...state,
                loadingRadioTypes: payload,
            };
        }

        case types.LOADED_RADIO_TYPES: {
            return {
                ...state,
                radioTypes: payload,
            };
        }

        case types.LOADING_VEHICLE_CARD: {
            return {
                ...state,
                loadingVehicleCard: payload
            };
        }

        case types.LOADED_VEHICLE_CARD: {
            return {
                ...state,
                vehicleCard: payload
            };
        }

        case types.CLEAR_VEHICLE_CARD: {
            return {
                ...state,
                vehicleCard: {}
            };
        }
        case types.LOADING_VEHICLE_TYPES:
            return {
                ...state,
                loadingVehicleTypes: payload
            };
        case types.LOADED_VEHICLE_TYPES:
            return {
                ...state,
                vehicleTypes: payload
            };

        case types.SET_FILTER: {
            // фильтруем машины если есть по чему
            const wsData = filteredMarkers(
                payload,
                state.wsData
            );
            if (wsData) {
                return {
                    ...state,
                    filters: payload,
                    wsData
                };
            }
            return {
                ...state,
                filters: payload,
                sidebarParams: {
                    ...state.sidebarParams,
                    page: 1
                }
            };
        }

        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }

        case types.LOADED_COORDS:
            return {
                ...state,
                coords: payload,
            };

        case types.LOADING_COORDS:
            return {
                ...state,
                coordsLoading: payload,
            };

        case types.SET_WS_TELEMETRY: {
            if (Object.keys(payload).length) {
                const markers = filteredMarkers(
                    state.filters,
                    // payload // diffMarkers
                    {
                        ...state.wsData,
                        ...payload
                    }
                );

                if (markers) {
                    return {
                        ...state,
                        wsData: markers
                        // wsData: {
                        //     ...state.wsData,
                        //     ...markers,
                        // },
                    };
                }

                return {
                    ...state,
                    wsData: {
                        ...state.wsData,
                        ...payload //...diffMarkers,
                    },
                };
            }

            return state;
        }

        case types.CLEAR_WS_TELEMETRY: {
            return {
                ...state,
                wsData: initialState.wsData,
            };
        }

        case types.SET_ROUTES_VEHICLE: {
            const {
                id,
                data,
            } = payload;

            return {
                ...state,
                routesHistory: {
                    ...state.routesHistory,
                    [id]: data,
                },
            };
        }

        case types.CLEAR_ROUTES_VEHICLE: {
            return {
                ...state,
                routesHistory: initialState.routesHistory,
            };
        }

        case types.CLEAR_ROUTES_VEHICLE_BY_ID: {
            const newRoutes = {
                ...state.routesHistory,
            };

            delete newRoutes[payload];

            return {
                ...state,
                routesHistory: newRoutes,
            };
        }

        case types.CLEAR_COORDS: {
            return {
                ...state,
                coords: initialState.coords,
            };
        }

        // установка маркеров
        case types.SET_MARKERS: {
            const {
                data, useFilter
            } = payload;
            const { filters } = state;

            const newMarkers = data.reduce((res, item) => {
                if (!res.hasOwnProperty(item.external_id)) {
                    return {
                        ...res,
                        [item.external_id]: item,
                    };
                }
                return res;
            }, state.wsData);

            if (useFilter) {
                const markers = filteredMarkers(
                    filters,
                    newMarkers
                );

                if (markers) {
                    return {
                        ...state,
                        wsData: {
                            // ...state.markers,
                            ...markers,
                        },
                    };
                }
            }

            return {
                ...state,
                wsData: newMarkers,
            };
        }

        case types.SET_SIDEBAR_PARAMS: {
            return {
                ...state,
                sidebarParams: {
                    ...state.sidebarParams,
                    ...payload,
                }
            };
        }

        case types.SET_WS_VEHICLE: {
            return {
                ...state,
                sidebarParams: {
                    ...state.sidebarParams,
                    page: 1,
                }
            };
        }

        case types.GET_FILTERED_LIST:
            return {
                ...state,
                filter: payload,
            };

        case types.LOADED_FILTERED_LIST: {
            return {
                ...state,
                vehicleFilteredList: payload || initialState.vehicleFilteredList
            };
        }

        case types.LOADING_FILTERED_LIST: {
            return {
                ...state,
                loadingVehicleFilteredList: payload
            };
        }

        case types.SET_FILTERED_LIST_FILTER: {
            return {
                ...state,
                vehicleFilteredListFilter: payload
            };
        }

        case types.CLEAR_FILTERED_LIST_FILTER: {
            return {
                ...state,
                vehicleFilteredListFilter: {},
                vehicleFilteredList: initialState.vehicleFilteredList
            };
        }

        case types.LOADED_HISTORY_VEHICLE: {
            const {
                data,
                id
            } = payload;
            return {
                ...state,
                history: {
                    ...state.history,
                    [id]: data
                },
            };
        }

        case types.CLEAR_HISTORY_VEHICLE: {
            return {
                ...state,
                history: initialState.history,
            };
        }

        case types.LOADING_HISTORY_VEHICLE: {
            return {
                ...state,
                historyLoading: payload,
            };
        }

        /* color speed */
        case types.LOADED_COLOR_SPEED: {
            return {
                ...state,
                colorSpeed: payload
            };
        }
        case types.LOADED_COLOR_SPEED_LIST: {
            return {
                ...state,
                colorSpeedList: payload
            };
        }
        case types.LOADING_COLOR_SPEED_LIST: {
            return {
                ...state,
                colorSpeedListLoading: payload
            };
        }
        case types.SET_COLOR_SPEED_LIST_PROPS: {
            return {
                ...state,
                colorSpeedListProps: payload
            };
        }
        case types.RESET_COLOR_SPEED_LIST_PROPS: {
            return {
                ...state,
                colorSpeedListProps: initialState.colorSpeedListProps
            };
        }

        case types.LOADED_VEHICLE_HISTORY_MODAL: {
            return {
                ...state,
                historyModal: payload
            };
        }
        case types.LOADING_VEHICLE_HISTORY_MODAL: {
            return {
                ...state,
                historyModalLoading: payload
            };
        }

        // case types.LOADED_CATEGORIES_VEHICLE: {
        //     return {
        //         ...state,
        //         categoriesVehicle: payload,
        //         categoriesVehicleLoaded: true,
        //     };
        // }

        case types.LOADING_BUTTON: {
            return {
                ...state,
                loadingButton: payload
            };
        }

        case types.LOADED_TELEMETRY_RECEIVERS: {
            return {
                ...state,
                telemetryReceivers: payload,
            };
        }

        case types.LOADING_TELEMETRY_RECEIVERS: {
            return {
                ...state,
                telemetryReceiversLoading: payload,
            };
        }

        case types.LOADED_TELEMETRY_RECEIVER_TYPES: {
            return {
                ...state,
                telemetryReceiverTypes: payload,
            };
        }

        case types.LOADED_VEHICLE_CATEGORY_LIST: {
            return {
                ...state,
                vehicleCategoryList: payload,
            };
        }

        case types.LOADING_VEHICLE_CATEGORY_LIST: {
            return {
                ...state,
                loadingVehicleCategoryList: payload,
            };
        }

        case types.LOADED_REPORT_BY_VEHICLES: {
            return {
                ...state,
                reportVehicleMileage: payload,
            };
        }

        case types.LOADING_REPORT_BY_VEHICLES: {
            return {
                ...state,
                loadingReportByVehicles: payload,
            };
        }

        default:
            return state;
    }
};
