import { HttpError } from './http.error';
import _ from 'lodash';

class ValidationError extends HttpError {
    constructor({ message, validationData = {} }) {
        super(message || '', 422);
        this.className = 'ValidationError';
        // проходим по объекту validationData - если значение ключа массив, то проходим по нему и пытаемся
        // оттуда вытащить string сообщение об ошибке
        // если же там не строка а новый объект то запускаем  рекурсию и объединяем с прердыдущим значением
        // в результате получаем простой объект где ключами будут поля из формы, а значениями стоки с сообщением об ошибках
        // lodash использован для читабельности
        const createValidationMessage = (data) => _.reduce(data, (result, value, key) => {
            if (_.isArray(value)) {
                result[key] = value.reduce((res, el) => {
                    if (_.isString(el)) {
                        return `${res} ${el}`;
                    } else if (_.isObject(el) && el !== null && !_.isArray(el)) {
                        // если это объект но не null и не массив (т.к. у них также тип объект)
                        return { ...(res === '' ? {} : res), ...createValidationMessage(el) };
                    }
                    return res;
                }, '');
            }
            return result;
        }, {});
        const validationMessage = createValidationMessage(validationData);
        this.validationMessage = validationMessage;
        if (Error.captureStackTrace instanceof Function) {
            Error.captureStackTrace(this, ValidationError);
        }
    }
}

export { ValidationError };
