import { Collapse, ListItem, ListItemText, Typography } from '@mui/material';
import messages from '../../../../../helpers/constants/messages';

const Info = ({ isOpen, item = {} }) => {
    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            <div><b>Время хранения файлов, изображений, событий: </b></div>
            <div className="info">
                <div>с нарушениями <b>{item.violation_file_lifetime_days}</b> дней</div>
                <div>с проездами <b>{item.passage_file_lifetime_days}</b> дней</div>
            </div>
            <div>
                <b>Применяется для комплексов: </b>{
                    item.is_default 
                        ? 'не добавленных в группы'
                        : item.complexes.length > 0
                            ? item.complexes.map((item) => (
                                <ListItem key={item.id}style={{ backgroundColor: '#ebebeb' }}>
                                    <ListItemText>
                                        <Typography variant="body2">
                                            <b>Название:</b> {item?.name || messages.INFO_IS_NOT_FOUND}
                                        </Typography>
                                        <Typography variant="body2">
                                            <b>Адрес:</b> {item?.address_text || messages.INFO_IS_NOT_FOUND}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            )) : messages.INFO_IS_NOT_FOUND
                }
            </div>
        </Collapse>
    );
};

export default Info;
