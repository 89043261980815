import L from 'leaflet';

import iconMap from './icons/iconMap';
import config from './config';
import iconTypes from './icons/iconTypes';

// цвет иконки от статуса
export const getColorByStatus = (status) => {
    return config.mapMarkersColors[status] || config.mapMarkersColors.default;
};

// получить иконку маркера
export const createIconMarker = (status) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: iconMap({ fill: getColorByStatus(status) })
    });
};

export const lightenDarkenColor = (col, amt) => {
    let usePound = false;
    if (col[0] === '#') {
        col = col.slice(1);
        usePound = true;
    }
    const num = parseInt(col,16);
    let r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    let b = ((num >> 8) & 0x00FF) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    let g = (num & 0x0000FF) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

// цвет
export function getRouteColor(categoryId) {
    switch (parseInt(categoryId)) {
        case iconTypes.Bus:
            return {
                fill: '#02A600',
                outline: '#00442C',
                checkPoint: '#3e413e'
            };
        case iconTypes.Trolleybus:
            return {
                fill: '#21AECE',
                outline: '#004141',
                checkPoint: '#7a8d8d'
            };
        case iconTypes.Tram:
            return {
                fill: '#E7532C',
                outline: '#632000',
                checkPoint: '#796054'
            };

        case iconTypes.Shuttlebus:
            return {
                fill: '#E7532C',
                outline: '#FF7F48',
                checkPoint: '#743B00'
            };

        default:
            return {
                fill: '#797979',
                outline: 'rgba(0,0,0,0.2)',
                checkPoint: '#353535'
            };
    }
};

export const getVTName = (type, defaultText) => {
    switch (type) {
        case iconTypes.Bus:
            return 'Автобус';

        case iconTypes.Tram:
            return 'Трамвай';

        case iconTypes.Trolleybus:
            return 'Троллейбус';

        case iconTypes.Shuttlebus:
            return 'Маршрутка';

        default: return defaultText;
    }
};

export const getVehicleCategory = (data = {}) => {
    return data?.vehicle?.category_id || data?.category_id || null;
};
// проверка есть ли вообще картинка ил есть ли у нее base64 префикс
export const checkBase64 = (img) => {
    if (img && img.indexOf('data:image/png;base64,') < 0) {
        return `data:image/png;base64,${img}`;
    }
    return img;
};

// комбинированный ключ для хранения
export const getKey = (item) => item.vehicle?.id ? `v_${item.vehicle?.id}` : `c_${item.external_id}`;

export const isShowStations = (zoom) => {
    return zoom >= 16;
};
