import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';

import {
    getReportTransportIncidentsResolutionTime,
    loadReportTransportIncidentsResolutionTime,
    loadTransportIncidentStatuses
} from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import {
    fullDateTimeWithTimeZone,
    getDateWithDuration,
    getEndOf,
    getStartOf
} from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import PageLayout from 'components/layout/PageLayout';
import ReportBlock from 'components/common/Report/ReportBlock';
import NoData from 'components/common/Information/NoData';

import Item from './Item';
import Filter from './Filter';

const initialFilter = {
    start_date: getStartOf('day', getDateWithDuration({ months: -1 })),
    end_date: getEndOf('day'),
    status: '',
    type_ti: '',
    threat_level: '',
    address: '',
    overdue: false
};

const TransportIncidentResolutionTime = () => {
    const dispatch = useDispatch();

    const data = useSelector(SituationalPlansSelectors.reportTransportIncidentsResolutionTimeData);
    const meta = useSelector(SituationalPlansSelectors.reportTransportIncidentsResolutionTimeMeta);
    const loading = useSelector(SituationalPlansSelectors.loadingReportTransportIncidentsResolutionTime);

    const statuses = useStoreProp(
        loadTransportIncidentStatuses,
        'SituationalPlans',
        'transportIncidentStatuses'
    );

    const [params, setParams] = useState({ page: 1, data: prepareData(initialFilter) });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [filter, setFilter] = useState(initialFilter);

    useEffect(() => {
        dispatch(loadReportTransportIncidentsResolutionTime(params.page, limit, params.data));
    }, [dispatch, limit, params.page, params.data]);

    function prepareData (data) {
        return removeEmptyFields({
            ...data,
            start_date: fullDateTimeWithTimeZone(data.start_date),
            end_date: fullDateTimeWithTimeZone(data.end_date),
            status: data?.status?.id,
            type_ti: data?.type_ti?.id,
            threat_level: data?.threat_level?.id,
            overdue: Number(data?.overdue)
        });
    };

    const onSearch = (newFilter = filter) => {
        setParams({ page: 1, data: prepareData(newFilter) });
    };

    const getReport = (formats) => {
        dispatch(getReportTransportIncidentsResolutionTime({
            report: 15,
            formats,
            ...prepareData(params.data)
        }));
    };

    return (
        <PageLayout
            header="Скорость ликвидации событий"
            filters={
                <Filter
                    initialFilter={initialFilter}
                    filter={filter}
                    setFilter={setFilter}
                    onSearch={onSearch}
                    statuses={statuses}
                />
            }
            informPanelProps={{
                buttons: (
                    <ReportBlock
                        onGetReport={getReport}
                        reportId={15}
                    />
                ),
                total: meta?.total
            }}
            loading={loading}
            content={() => (
                data?.length > 0
                    ? <List className="list">
                        {data
                            .map((item) => <Item
                                key={item.id}
                                item={item}
                                statuses={statuses}
                            />)
                        }
                    </List>
                    : !loading && <NoData message={messages.NO_DATA_FOR_YOUR_REQUEST}/>
            )}
            paginationProps={{
                loadList: (page) => setParams(prev => ({ ...prev, page })),
                list: meta,
                limit,
                setLimit
            }}
        />
    );
};

export default TransportIncidentResolutionTime;