import moduleName from './module';
const prefix = `${moduleName}`;

/**
 * Constants
 * */

//Получение списка светофоров
export const LOAD_TRAFFIC_LIGHTS_LIST = `${prefix}/LOAD/TRAFFIC/LIGHTS/LIST`;
export const LOADING_TRAFFIC_LIGHTS_LIST = `${prefix}/LOADING/TRAFFIC/LIGHTS/LIST`;
export const LOADED_TRAFFIC_LIGHTS_LIST = `${prefix}/LOADED/TRAFFIC/LIGHTS/LIST`;
//Получение статусов светофора
export const LOAD_TRAFFIC_LIGHTS_STATUSES = `${prefix}/LOAD/TRAFFIC/LIGHTS/STATUSES`;
export const LOADED_TRAFFIC_LIGHTS_STATUSES = `${prefix}/LOADED/TRAFFIC/LIGHTS/STATUSES`;
//Получение состояний светофора
export const LOAD_TRAFFIC_LIGHT_STATES = `${prefix}/LOAD/TRAFFIC/LIGHTS/STATES`;
export const LOADED_TRAFFIC_LIGHT_STATES = `${prefix}/LOADED/TRAFFIC/LIGHTS/STATES`;
//Получение типов контроля светофора
export const LOAD_TRAFFIC_LIGHT_CONTROL_TYPES = `${prefix}/LOAD/TRAFFIC/LIGHTS/CONTROL/TYPES`;
export const LOADED_TRAFFIC_LIGHT_CONTROL_TYPES = `${prefix}/LOADED/TRAFFIC/LIGHTS/CONTROL/TYPES`;
//Добавление светофора
export const CREATE_TRAFFIC_LIGHTS = `${prefix}/CREATE/TRAFFIC/LIGHTS`;
//Редактирование светофора
export const EDIT_TRAFFIC_LIGHTS = `${prefix}/EDIT/TRAFFIC/LIGHTS`;
//Удаление светофора
export const DELETE_TRAFFIC_LIGHTS = `${prefix}/DELETE/TRAFFIC/LIGHTS`;

export const GET_POLYGON = `${prefix}/GET/POLYGON/TRAFFIC/LIGHTS`;
export const SAVE_POLYGON = `${prefix}/SAVE/POLYGON/TRAFFIC/LIGHTS`;
export const LOADING_SIDEBAR = `${prefix}/LOADING/SIDEBAR/TRAFFIC/LIGHTS`;
export const LOADED_SIDEBAR = `${prefix}/LOADED/SIDEBAR/TRAFFIC/LIGHTS`;
export const LOAD_SIDEBAR = `${prefix}/LOAD/SIDEBAR/TRAFFIC/LIGHTS`;
export const SET_ACTIVE = `${prefix}/SET/ACTIVE/TRAFFIC/LIGHTS`;
export const SET_DELETE_FORM = `${prefix}/SET/DELETE/FORM/TRAFFIC/LIGHTS`;
export const SET_EDIT_FORM = `${prefix}/SET/EDIT/FORM/TRAFFIC/LIGHTS`;
export const SAVED = `${prefix}/SAVED/TRAFFIC/LIGHTS`;
export const SET_FILTER = `${prefix}/SET/FILTER/TRAFFIC/LIGHTS`;

export const LOAD_COORDINATION_GROUP = `${prefix}/LOAD_COORDINATION_GROUP`;
export const LOADED_COORDINATION_GROUP = `${prefix}/LOADED_COORDINATION_GROUP`;
export const COORDINATION_GROUP_LOADING = `${prefix}/COORDINATION_GROUP_LOADING`;
export const LOAD_COORDINATION_GROUP_POLYGON = `${prefix}/LOAD_COORDINATION_GROUP_POLYGON`;
export const LOADED_COORDINATION_GROUP_POLYGON = `${prefix}/LOADED_COORDINATION_GROUP_POLYGON`;
export const LOAD_GREEN_STREET = `${prefix}/LOAD_GREEN_STREET`;
export const GREEN_STREET_LOADING = `${prefix}/GREEN_STREET_LOADING`;
export const LOADED_GREEN_STREET = `${prefix}/LOADED_GREEN_STREET`;
export const LOAD_GREEN_STREET_POLYGON = `${prefix}/LOAD_GREEN_STREET_POLYGON`;
export const LOADED_GREEN_STREET_POLYGON = `${prefix}/LOADED_GREEN_STREET_POLYGON`;
export const SHOW_GS_CS_BY_TRAFIC_LIGHTS = `${prefix}/SHOW_GS_CS_BY_TRAFIC_LIGHTS`;

export const WS_REPLACE_DATA = `${prefix}/WS_REPLACE_DATA`;
export const WS_REPLACE_LIGHT = `${prefix}/WS_REPLACE_LIGHT`;
export const WS_SET_FILTER = `${prefix}/WS_SET_FILTER`;
export const WS_REPLACE_DATA_LIST = `${prefix}/WS_REPLACE_DATA_LIST`;

export const LOAD_COLORS_FOR_LEGENDS = `${prefix}/LOAD_COLORS_FOR_LEGENDS`;
export const LOADED_COLORS_FOR_LEGENDS = `${prefix}/LOADED_COLORS_FOR_LEGENDS`;

export const LOAD_SHOWCASE = `${prefix}/LOAD_SHOWCASE`;
export const LOADING_SHOWCASE = `${prefix}/LOADING_SHOWCASE`;
export const LOADED_SHOWCASE = `${prefix}/LOADED_SHOWCASE`;

export const LOAD_TRAFFIC_LIGHTS_BY_RADIUS = `${prefix}/LOAD_TRAFFIC_LIGHTS_BY_RADIUS`;
export const LOADED_TRAFFIC_LIGHTS_BY_RADIUS = `${prefix}/LOADED_TRAFFIC_LIGHTS_BY_RADIUS`;

export const EDIT_SETTINGS_LIST = `${prefix}/EDIT_SETTINGS_LIST`;

export const LOAD_SETTINGS_GROUP = `${prefix}/LOAD_SETTINGS_GROUP`;
export const LOADING_SETTINGS_GROUP = `${prefix}/LOADING_SETTINGS_GROUP`;
export const LOADED_SETTINGS_GROUP = `${prefix}/LOADED_SETTINGS_GROUP`;
export const EDIT_SETTINGS_GROUP = `${prefix}/EDIT_SETTINGS_GROUP`;

export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;
