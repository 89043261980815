import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import SidebarItem from 'components/MapComponents/SidebarItem';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import Loading from 'components/common/Loading';
import getFilters from 'components/MapComponents/helpers/getFilters';
import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import colorExtend from 'helpers/mapHelper/colorExtend';
import { useStoreProp } from 'helpers/hooks';
import * as actions from 'redux/RoadWorks/actions';
import { loadDefectStatuses } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import Form from './Form';
import configLayer from '../config';
import { getDefaultColor, getFilter } from '../helper';
import Icon from '../icons/icon';
import Item from './Item';


const SideBar = (props) => {
    const dispatch = useDispatch();

    const defectsSidebar = useSelector(roadworksSelectors.defectsSidebar);
    const defectsSidebarLoading = useSelector(roadworksSelectors.defectsSidebarLoading);
    const defectsActive = useSelector(roadworksSelectors.defectsActive);
    const defectsFilters = useSelector(roadworksSelectors.defectsFilters);
    const defectsSaved = useSelector(roadworksSelectors.defectsSaved);

    const activeId = Object.keys(defectsActive).length > 0 ? defectsActive.id : 0;

    const statuses = useStoreProp(loadDefectStatuses, 'roadworks', 'defectStatuses');

    useEffect(() => {
        if (statuses?.length) {
            dispatch(actions.setDefectsFilter({
                status: statuses
                    .map(({ status_id, name }) => ({ id: status_id, name }))
                    .filter(({ id }) => [1,2].includes(id))
            }));
        }
    }, [statuses]);

    const [params, setParams] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25
    });

    useEffect(() => {
        return () => {
            setParams({
                ...params,
                page: 1
            });
        };
    }, []);

    useEffect(() => {
        if (defectsSaved) {
            fetchList();
        }
    }, [defectsSaved]);

    const handleScrollUpdate = (values) => {

        const last_page = defectsSidebar?.meta?.last_page || 0;
        if (
            values.top > 0.98
            && defectsSidebarLoading === false
            && params.page < last_page
        ) {
            setParams({
                ...params,
                page: params.page + 1,
            });
        }
    };

    const fetchList = () => {
        dispatch(actions.loadDefectsSidebar({
            ...params,
            ...getFilters(defectsFilters, getFilter),
            // withDeleted: 0
        }));
    };

    // отслеживаем изменения для перезапроса списка
    useEffect(() => {
        fetchList();
    }, [params, defectsFilters]);

    const renderList = (dataList) => (
        <>
            {dataList.map((item) => {
                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        icon={
                            Icon({
                                fill: item?.type?.color
                                    ? colorExtend(item?.type?.color)
                                    : getDefaultColor()
                            })
                        }
                        buttons={{
                            ...(item.status !== 3 ? {
                                edit: {
                                    title: buttons.EDIT,
                                    icon: <i className="fas fa-pen"/>,
                                    onClick: () => {
                                        dispatch(actions.setEditFormDefects(item));
                                    }
                                },
                            } : {}),
                            delete: {
                                title: buttons.DELETE,
                                icon: <i className="fas fa-trash-alt" />,
                                onClick: () => {
                                    dispatch(actions.setDeleteFormDefects(item.id));
                                }
                            }
                        }}
                        onClick={() => {
                            dispatch(actions.setDefectsActive(item));
                        }}
                    >
                        <Item
                            {...item}
                            statuses={statuses}
                        />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={defectsFilters}
                onSetFilter={(filter) => dispatch(actions.setDefectsFilter({ ...filter }))}
                resetListPage={() => setParams({ ...params, page: 1 })}
                onClearFilter={() => dispatch(actions.clearDefectsFilter())}
                content={({ data, onChange }) => <Form data={data} onChange={onChange} statuses={statuses} />}
                layer={configLayer.slug}
            />

            {defectsSidebarLoading && defectsSidebar.data.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={configLayer.name}
                            list={defectsSidebar}
                        />
                        {defectsSidebar.data.length === 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList(defectsSidebar.data)}
                    </div>
                    {defectsSidebarLoading && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
};

export default SideBar;
