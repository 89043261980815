import React, { useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';

import removeEmptyFields from 'helpers/removeEmptyFields';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

interface FilterPropTypes {
    setParams:  React.Dispatch<React.SetStateAction<{page: number; data: { is_simple: number; }}>>
}

const Filter = ({ setParams }: FilterPropTypes) => {
    const initialState = {
        name: '',
        number: '',
        serial_number: '',
        with_trashed: false,
    };

    const [data, setData] = useState(initialState);

    const handleResetFilter = (needRefresh: boolean) => {
        setData(initialState);

        if (needRefresh) {
            setParams({ page: 1, data: { is_simple: 1 } });
        }
    };

    const handleSearch = () => {
        const prepareData = {
            ...removeEmptyFields(data, false),
            is_simple: 1
        };
        setParams({ page: 1, data: prepareData });
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name, checked } = e.target;
        setData({
            ...data,
            [name]: name === 'with_trashed' ? checked : value,
        });
    };

    const updateFilter = (filters: {}) => {
        setData({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            setParams({
                page: 1,
                data: {
                    ...removeEmptyFields({ ...initialState, ...filters }),
                    is_simple: 1
                },
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={handleResetFilter}
            onSearch={handleSearch}
            filter={data}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={data.name}
                    onChange={onChange}
                    label="Название"
                    variant="outlined"
                    size="small"
                    name="name"
                    type="text"
                />
                <TextField
                    value={data.number}
                    onChange={onChange}
                    label="Номер"
                    variant="outlined"
                    size="small"
                    name="number"
                    type="text"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data.with_trashed}
                            onChange={onChange}
                            name="with_trashed"
                        />
                    }
                    label="Показывать удаленные"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
