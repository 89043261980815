import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setValidation } from 'redux/Validation/actions';
import messagetypes from 'redux/Message/messagetypes';
import { messageTypes, showMessage } from 'redux/Message/actions';
import { setMapLoading } from 'redux/Map/actions';
import messages from 'helpers/constants/messages';
import { messageHelper } from 'helpers/messageHelper';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';
/**
 * Sagas
 * **/

// список статусов
function* loadStatusSaga() {
    const response = yield call(api.getStatuses);
    if (response?.success) {
        yield put(actions.loadedStatus(response?.data));
    }
}

// типы
function* loadTypesSaga() {
    const response = yield call(api.getTypes);
    if (response?.success) {
        yield put(actions.loadedTypes(response?.data));
    }
}

// Получение полигона
function* getPolygonSaga({ payload: params }) {
    yield put(actions.loadingPolygon(true));
    yield put(setMapLoading('tf_puid', true));
    const response = yield call(api.getPolygon, params);
    if (response) {
        yield put(actions.saveForPolygon(response.data));
    }
    yield put(actions.loadingPolygon(false));
    yield put(setMapLoading('tf_puid', false));
}

// сайдбар
function* loadSideBarSaga({ payload }) {
    const page = payload.page || 1;
    yield put(actions.loadingSidebar(true));
    const response = yield call(api.getSidebar, payload);
    if (response?.success) {
        yield put(actions.loadedSidebar(response, page === 1));
    }
    yield put(actions.loadingSidebar(false));
}

// список
function* getListSaga({ payload: params, callback } ) {
    yield put(actions.loadingList(true));
    const response = yield call(api.getList, params);
    if (response?.success) {
        yield put(actions.loadedList(response));
        callback && callback(response.data);
    }
    yield put(actions.loadingList(false));
}

function* loadTrafficSaga({ payload: params }) {
    yield put(actions.loadingTraffic(true));
    const response = yield call(api.getTraffic, params);
    if (response?.success) {
        yield put(actions.loadedTraffic(response?.data));
    }
    yield put(actions.loadingTraffic(false));
}
function* loadTrafficInfoSaga({ payload, callback }) {
    const { id, params } = payload;
    yield put(actions.loadingTraffic(true));
    const response = yield call(api.getTrafficInfo, id, params);
    if (response?.success) {
        callback?.(response.data);
        yield put(actions.loadedTraffic(response?.data));
    }
    yield put(actions.loadingTraffic(false));
}

function* loadReportSaga({ payload }) {
    yield put(actions.loadingReport(true));

    const response = yield call(api.getReport, payload);
    if (response?.success) {
        yield put(actions.loadedReport(response?.data || []));
    }
    yield put(actions.loadingReport(false));
}
function* loadReportGroupsSaga() {
    const response = yield call(api.getReportGroups);
    if (response?.success) {
        yield put(actions.loadedReportGroups(response?.data || []));
    }
}
function* loadReportTypesSaga() {
    const response = yield call(api.getReportTypes);
    if (response?.success) {
        yield put(actions.loadedReportTypes(response?.data || []));
    }
}

function* loadTransportFlowReportSaga({ payload: params }) {
    yield put(actions.loadingTransportFlowReport(true));
    const formats = params?.formats;
    const response = yield call(api.getTransportFlowReport, params);
    if (response?.success) {
        if (formats) {
            yield messageHelper(response);
        } else {
            yield put(actions.loadedTransportFlowReport(response?.data || {}));
        }
    }
    yield put(actions.loadingTransportFlowReport(false));
}
function* loadTransportFlowGroupsSaga() {
    const response = yield call(api.getTransportFlowGroups);
    if (response?.success) {
        yield put(actions.loadedTransportFlowGroups(response?.data || []));
    }
}
// function* loadTransportFlowTypesSaga({ payload: params }) {
//     const response = yield call(api.getTransportFlowTypes, params);
//     if (response?.success) {
//         yield put(actions.loadedTransportFlowTypes(response));
//     }
// }


function* loadThroughputSaga({ payload, callback }) {
    yield put(actions.loadingThroughput(true));

    const response = yield call(api.getThroughput, payload);
    if (response?.success) {
        console.log('response', response?.data);
        yield put(actions.loadedThroughput(response?.data || {}));
        callback && callback();
    }
    yield put(actions.loadingThroughput(false));
}

function* loadHeatMapSaga({ payload }) {
    yield put(actions.loadingHeatMap(true));
    const response = yield call(api.getHeatMap, payload);
    if (response?.success) {
        yield put(actions.loadedHeatMap(response.data || []));
    }
    yield put(actions.loadingHeatMap(false));
}

function* loadShowcaseSaga() {
    yield put(actions.loadingShowcase(true));
    const response = yield call(api.loadShowcase);
    if (response?.success) {
        yield put(actions.loadedShowcase(response.data));
    }
    yield put(actions.loadingShowcase(false));
}

function* loadReportIndicatorsSaga({ payload }) {
    yield put(actions.loadingReportIndicators(true));
    const formats = payload?.formats;
    const response = yield call(api.loadReportIndicators, payload);
    if (formats) {
        const messageArray = response.data || response;
        yield put(showMessage(messageTypes.success, messageArray.join('')));
    } else {
        yield put(actions.loadedReportIndicators(response.data || {}));
    }
    yield put(actions.loadingReportIndicators(false));
}

function* loadTrafficFlowByRadiusSaga({ payload, callback }) {
    const response = yield call(api.loadTrafficFlowByRadius, payload);
    if (response?.success) {
        yield put(actions.loadedTrafficFlowByRadius(response.data));
        callback(response.data);
    }
}

// логические пуиды
function* createLogicalPuidSaga({ payload, callback }) {
    yield put(actions.loadingLogicalPuid(true));
    const response = yield call(api.createLogicalPuid, payload);
    if (response?.success) {
        callback?.();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
    yield put(actions.loadingLogicalPuid(false));
}

function* editLogicalPuidSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingLogicalPuid(true));
    const response = yield call(api.editLogicalPuid, id, data);
    if (response?.success) {
        callback?.();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
    yield put(actions.loadingLogicalPuid(false));
}

function* deleteLogicalPuidSaga({ payload, callback }) {
    yield put(actions.loadingLogicalPuid(true));
    const response = yield call(api.deleteLogicalPuid, payload);
    if (response?.success) {
        callback?.();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
    yield put(actions.loadingLogicalPuid(false));
}

function* loadPuidByIdsSaga({ payload, callback }) {
    const response = yield call(api.loadPuidByIds, payload);
    callback?.(
        response
            ?.data
            ?.reduce((r, i) => ({
                ...r,
                [i.id]: i
            }), {})
        || {}
    ) ;
}

function* loadPuidByRadiusSaga({ payload }) {
    yield put(actions.loadingPuidByRadius(true));

    const response = yield call(api.loadPuidByRadius, payload);
    if (response?.success) {
        yield put(actions.loadedPuidByRadius(response.data || []));
    }
    yield put(actions.loadingPuidByRadius(false));
}

function* loadSettingsSaga({ payload }) {
    const { page, limit, query } = payload;

    yield put(actions.loadingSettings(true));
    const response = yield call(api.getSettings, page, limit, query);

    if (response?.success) {
        yield put(actions.loadedSettings(response));
    }

    yield put(actions.loadingSettings(false));
}

function* editSettingSaga({ payload }) {
    const { id, data } = payload;

    const response = yield call(api.editSettings, id, data);

    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield put(actions.loadSettings());
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

function* editSettingsListSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.editSettingsList, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }

    yield put(actions.loadingButton(false));
}

// settings group
function* loadSettingsGroupSaga() {
    yield put(actions.loadingSettingsGroup(true));
    const response = yield call(api.getSettingsGroup);
    if (response?.success) {
        yield put(actions.loadedSettingsGroup(response));
    }
    yield put(actions.loadingSettingsGroup(false));
}

function* editSettingsGroupSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.editSettingsGroup, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* loadReportStatisticTypeSaga() {
    const response = yield call(api.getReportStatisticType);
    if (response?.success) {
        yield put(actions.loadedReportStatisticType(response.data || []));
    }
}

function* loadReportTimeSlotSaga() {
    const response = yield call(api.getReportTimeSlot);
    if (response?.success) {
        yield put(actions.loadedReportTimeSlot(response.data || []));
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_STATUS, loadStatusSaga),
        takeLatest(types.LOAD_TYPES, loadTypesSaga),
        takeLatest(types.GET_POLYGON, getPolygonSaga),
        takeLatest(types.LOAD_LIST, getListSaga),
        takeLatest(types.LOAD_SIDEBAR, loadSideBarSaga),
        takeLatest(types.LOAD_TRAFFIC, loadTrafficSaga),
        takeLatest(types.LOAD_TRAFFIC_INFO, loadTrafficInfoSaga),

        takeLatest(types.LOAD_REPORT, loadReportSaga),
        takeLatest(types.LOAD_REPORT_GROUPS, loadReportGroupsSaga),
        takeLatest(types.LOAD_REPORT_TYPES, loadReportTypesSaga),

        takeLatest(types.LOAD_TRANSPORT_FLOW_REPORT, loadTransportFlowReportSaga),
        takeLatest(types.LOAD_TRANSPORT_FLOW_GROUPS, loadTransportFlowGroupsSaga),
        // takeLatest(types.LOAD_TRANSPORT_FLOW_TYPES, loadTransportFlowTypesSaga),

        takeLatest(types.LOAD_THROUGHPUT, loadThroughputSaga),

        takeLatest(types.LOAD_HEAT_MAP, loadHeatMapSaga),
        takeLatest(types.LOAD_SHOWCASE, loadShowcaseSaga),
        takeLatest(types.LOAD_REPORT_INDICATORS, loadReportIndicatorsSaga),
        takeLatest(types.LOAD_TRANSPORT_FLOW_BY_RADIUS, loadTrafficFlowByRadiusSaga),
        takeLatest(types.CREATE_LOGICAL_PUID, createLogicalPuidSaga),
        takeLatest(types.EDIT_LOGICAL_PUID, editLogicalPuidSaga),
        takeLatest(types.DELETE_LOGICAL_PUID, deleteLogicalPuidSaga),
        takeLatest(types.LOAD_PUID_BY_IDS, loadPuidByIdsSaga),
        takeLatest(types.LOAD_PUID_BY_RADIUS, loadPuidByRadiusSaga),

        takeLatest(types.LOAD_SETTINGS, loadSettingsSaga),
        takeLatest(types.EDIT_SETTING, editSettingSaga),
        takeLatest(types.EDIT_SETTINGS_LIST, editSettingsListSaga),

        takeLatest(types.LOAD_SETTINGS_GROUP, loadSettingsGroupSaga),
        takeLatest(types.EDIT_SETTINGS_GROUP, editSettingsGroupSaga),

        takeLatest(types.LOAD_REPORT_STATISTIC_TYPES, loadReportStatisticTypeSaga),
        takeLatest(types.LOAD_REPORT_TIME_SLOT, loadReportTimeSlotSaga),
    ]);
}
