import React, { useEffect, useState } from 'react';
import Form from './Form';
import Modal from '../../../../../common/Modal';
import { useDispatch, useSelector } from 'react-redux';
import titles from '../../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import { editWanted, createWanted } from '../../../../../../redux/WantedCars/actions';
import { useValidation } from '../../../../../../helpers/hooks';
import removeEmptyFields from '../../../../../../helpers/removeEmptyFields';

const initialState = {
    id: null,
    cars_wanted_type_id: null,
    reason: '',
    description: '',
    start_wanted_at: null,
    end_wanted_at: null,
    brand: '',
    model: '',
    color: '',
    is_private: false,
    is_send_telegram: false,
    is_send_mail: false,
};

const ModalForm = ({
    item = {},
    onClose = () => {},
    isNew = false,
    isOpen = false,
    reloadList = () => {},
}) => {

    const dispatch = useDispatch();

    const {
        loading,
    } = useSelector(({ carsWanted }) => carsWanted);

    const validation = useValidation();

    const [fields, setFields] = useState({ ...initialState, ...item });

    const requiredFields = [
        'reason',
    ];

    const handleSuccess = () => {
        const newFields = {
            ...removeEmptyFields(fields),
            is_private: fields.is_private,
            is_send_telegram: fields.is_send_telegram,
            is_send_mail: fields.is_send_mail,
        };
        

        const callback = () => {
            reloadList();
            onClose();
        };
       
        if (isNew) {
            dispatch(createWanted(newFields, callback));
        } else {
            dispatch(editWanted(newFields.id, newFields, callback));
        }
    };

    const handleChange = (changeData) => {
        validation.deleteKeys(Object.keys(changeData));

        setFields({
            ...fields,
            ...changeData
        });
    };

    // const isFormValid = 

    {/* форма редактирования */}
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSuccess,
                            // disabled: !isFormValid
                        }
                    ]}
                />
            }
        >
            <Form
                data={fields}
                requiredFields={requiredFields}
                onChange={handleChange}
                validation={validation}
                loading={loading}
            />
        </Modal>
    );
};

export default ModalForm;
