import React, { useState, useRef } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux';
import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import cn from 'classnames';
import titles from '../../../../../../helpers/constants/titles';

const Search = ({ action, loadParams, complex_id }) => {
    const dispatch = useDispatch();
    
    const [value, setValue] = useState('');
    const [isSearch, setIsSearch] = useState(false);

    const handleChange = (e) => {
        setValue(e.target.value);
        if (isSearch && e.target.value === '') {
            resetSearch();
        }
    };

    const paramsHandler = (value) => loadParams && loadParams(value);

    const search = () => {
        if (value) {
            setIsSearch(true);
            paramsHandler(value);
            dispatch(action(1, 25, { number : value, complex_id }));
        } 
        else {
            resetSearch();
        }
    };

    const resetSearch = () => {
        setValue('');
        paramsHandler();
        if (isSearch) {
            dispatch(action(1, 25, { complex_id }));
            setIsSearch(false);
        }
    };

    const endAdornment = (
        <InputAdornment position="end">
            <IconButton onClick={resetSearch} size="small">
                <Clear />
            </IconButton>
        </InputAdornment>
    );

    const handleEnter = (e) => {
        if (e.key === 'Enter') search();
    };

    return (
        <div className={cn('filter__wrap', 'filter__wrap__one__field')}>
            <TextField
                value={value}
                onChange={handleChange}
                label={titles.NUMBER}
                variant="outlined"
                size="small"
                InputProps={value ? { endAdornment } : {}}
                inputProps={{ onKeyDown: handleEnter }}
            />
            <div className="filter__wrap__btn">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.search,
                            onClick: search
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Search;
