import React, { useState } from 'react';
import { IconButton, ListItem, ListItemText, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { loadDtpSeverity } from 'redux/Incidents/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import ConfirmDelete from 'components/common/ConfirmModal';

import EditModal from './AddEditModal';

const Item = ({
    index,
    member,
    onChange,
    onDelete }) => {

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        onDelete && onDelete(index);
        setIsDelete(false);
    };

    const severityList = useStoreProp(loadDtpSeverity, 'incidents', 'severity');

    return (
        <ListItem className="list_item">
            <ListItemText>
                <div>
                    <strong>
                        ФИО участника:
                    </strong>&nbsp;
                    {member?.fio || messages.NO_DATA}
                </div>
                <div>
                    <strong>
                        Год рождения:
                    </strong>&nbsp;
                    {member?.birth_year || messages.NO_DATA}
                </div>
                <div>
                    <strong>
                        {titles.SEVERITY_AFTER_DTP}:
                    </strong>&nbsp;
                    {member?.severity_text || severityList[member?.severity] || messages.NO_DATA}
                </div>
            </ListItemText>
            <Tooltip title={titles.EDIT}>
                <IconButton onClick={() => setOpenEditModal(true)} size="small">
                    <EditIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Tooltip title={titles.DELETE}>
                <IconButton onClick={() => setIsDelete(true)} size="small">
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            {openEditModal &&  (
                <EditModal
                    member={member}
                    index={index}
                    isOpen={openEditModal} 
                    onClose={() => setOpenEditModal(false)}
                    onAccept={onChange}
                />
            )}
            {isDelete &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={isDelete}
                    onSuccess={handleDelete}
                    onClose={() => setIsDelete(false)}
                />
            )}
        </ListItem>
    );
};

export default Item;
