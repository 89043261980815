import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    IconButton,
    Tooltip,
} from '@mui/material';

import { loadStationsList } from 'redux/TransportPassenger/actions';
import UniversalSelect from 'components/common/UniversalSelect';
import MapForm from 'components/pages/Dictionaries/DorisControl/VFComplex/MapForm';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import type { GetListArguments } from 'types/Autocomplete';
import type { StationsItem } from 'types/PassengerTransport';
import type { ItemGeometry, StationsTypes } from './types';


const SelectStations = ({
    multiple = false,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label='Остановки',
    error = false,
    helperText = '',
    limitTags = 1
}: StationsTypes<StationsItem>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [currentItemGeometry, setCurrentItemGeometry] = useState<ItemGeometry | null>(null);

    const getStationList = (params: GetListArguments) => {
        const { page, query: name } = params;

        dispatch(loadStationsList(page, 10, {
            ...(name && { name }),
        }));
    };

    const handleClose = () => setShowList(false);

    const handleAccept = (data: StationsItem | StationsItem[]) => {
        onChange(data);
        setShowList(false);
    };

    const handleShowMap = (item: { check_point?: ItemGeometry }) => {
        setCurrentItemGeometry(prev => prev ? null : item.check_point || null);
    };

    return (
        <>
            <CommonAutocomplete<StationsItem>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                error={error}
                helperText={helperText}
                required={required}
                disabled={disabled}
                onReset={() => onChange(multiple ? [] : {})}
                renderLabel={(option) => option?.name || ''}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getStationList}
                    storeName={'transportPassenger'}
                    storeNameProps={'stationsList'}
                    keyProp={'id'}
                    withSearch
                    isSelected
                    selected={selected}
                    searchTitle="поиск по названию"
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding={true}
                    title={'Выберите остановку'}
                    small={true}
                    renderProps={(el) => <>
                        <p>{el.name}{el?.type_list?.length > 0 && ` - ${el.type_list.map((item: { station_name: string; }) => item?.station_name)}`}</p>
                        {el.direction && <p>Направление: {el.direction}</p>}
                        {el.comment && <p>Комментарий: {el.comment}</p>}
                    </>
                    }
                    renderIconProps={(item) => (
                        <Tooltip title="Показать на карте">
                            <IconButton onClick={() => handleShowMap(item)} size="small">
                                <i className="fas fa-map-marked"/>
                            </IconButton>
                        </Tooltip>
                    )}
                />
            }
            {currentItemGeometry && (
                <Modal
                    isOpen={!!currentItemGeometry}
                    onClose={handleShowMap}
                    noPadding
                    title="Остановка"
                    buttons={
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: handleShowMap
                                },
                            ]}
                        />
                    }
                >
                    <MapForm
                        latlon={[currentItemGeometry?.lat, currentItemGeometry?.lon]}
                        readOnly={true}
                    />
                </Modal>
            )}
        </>
    );
};

export default SelectStations;