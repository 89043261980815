const fieldsTypes = {
    textField: 'textField',
    textFieldInteger: 'textFieldInteger',
    universalSelect: 'universalSelect',
    checkBox: 'checkBox',
    radioBox: 'radioBox',
    textPhoneMask: 'textPhoneMask',
};

export default fieldsTypes;
