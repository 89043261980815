import React from 'react';
import { Tooltip } from '@mui/material';

interface CircleStatusProps {
    title: string;
    color: string;
    styles?: React.CSSProperties;
}

// todo: удалить этот компонент, и использовать общий из common (пока не добавлен)
const CircleStatus = ({ title, color, styles }: CircleStatusProps) => {
    return (
        <Tooltip title={title}>
            <div
                style={{
                    backgroundColor: color,
                    width: 20,
                    height: 20,
                    borderRadius: '50%',
                    ...styles,
                }}
            />
        </Tooltip>
    );
};

export default CircleStatus;
