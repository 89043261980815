import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { loadCompanies } from '../../../../redux/Companies/actions';
import UniversalSelect from '../../UniversalSelect';
import messages from '../../../../helpers/constants/messages';
import Loading from '../../Loading';
import ConfirmDelete from '../../ConfirmModal';
import * as ecologyActions from '../../../../redux/Ecology/actions';
import * as meteoActions from '../../../../redux/Meteo/actions';
import { useStoreProp, useValidation } from '../../../../helpers/hooks';

import SelectAlerts from './SelectAlerts';

const CompanyList = ({
    isOpen,
    onClose,
    onCloseUniversalModal,
    microservice,
    id,
    isSendData,
    setSendData,
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const actions = {
        ecology: {
            alerts: ecologyActions.loadEcologyAlerts,
            getLink: ecologyActions.getDeviceCompanyLink,
            create: ecologyActions.createDeviceCompanyLink,
            delete: ecologyActions.deleteDeviceCompanyLink
        },
        meteo: {
            alerts: meteoActions.loadMeteoAlerts,
            getLink: meteoActions.getDeviceCompanyLink,
            create: meteoActions.createDeviceCompanyLink,
            delete: meteoActions.deleteDeviceCompanyLink
        }
    };

    const alerts = useStoreProp(actions[microservice].alerts, microservice, 'alerts');

    // приводим данные к 1 формату (когда нет данных приходит {})
    const alertsList = useMemo(() => {
        return Array.isArray(alerts)
            ? alerts
            : Object.keys(alerts).map(key => ({ id: Number(key), title: alerts[key] }));
    }, [alerts]);

    // todo: переписать selector когда рефакторинг meteo будет готово
    const list = useSelector((state) => state[microservice]?.companyLink);
    const loading = useSelector((state) => state[microservice]?.loading);

    const [indexOfDeletedCompanyLink, setIndexOfDeletedCompanyLink] = useState(null);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [data, setData] = useState([]);

    const selectedCompanies = data?.map(el => el.organization);

    useEffect(() => {
        setData(list);
    }, [list, id]);

    useEffect(() => {
        if (id) {
            dispatch(actions[microservice].getLink(id));
        }
    },[id]);

    useEffect(() => {
        if (isSendData) {
            // const sendData = reduce(
            //     data,
            //     (res, el, key) => {
            //         if (el && !Array.isArray(el)) {
            //             res[key] = el;
            //         }
            //         if (Array.isArray(el)) {
            //             res[key] = el.map(item => item.id);
            //         }
            //         return res;
            //     },
            //     {}
            // );

            const prepareData = { 
                device_id: id,
                organizations: data.map(({ organization_id, alerts }) => ({ organization_id,  alerts }))
            };

            const callback = () => {
                onClose();
                dispatch(actions[microservice].getLink(id));
            };

            prepareData.organizations.length > 0
                ? dispatch(actions[microservice].create(id, prepareData, callback))
                : dispatch(actions[microservice].delete(id, callback));

            setSendData && setSendData(false);
        }
    }, [isSendData]);

    const handleCompanyUniversalSelect = (value) => {
        const currentArr = value.map((item) => {
            const prevItem = data.find(el => el?.organization_id === item?.id);

            const obj = {
                device_id: id,
                organization: item,
                organization_id: item.id,
                alerts: prevItem
                    ? prevItem.alerts
                    : item?.emails.map(el => ({ email: el.email || el.data, alerts: [] }))
            };

            return obj;
        });
        
        setData(currentArr);

        onCloseUniversalModal();
    };

    const handleChangeAlerts = (index, alertsIndex, value) => {
        console.log(value);
        const newData = [
            ...data.slice(0, index),
            {
                ...data[index],
                alerts: [
                    ...data[index].alerts.slice(0, alertsIndex),
                    {
                        ...data[index].alerts[alertsIndex],
                        alerts: value
                    },
                    ...data[index].alerts.slice(alertsIndex + 1)
                ],
            },
            ...data.slice(index + 1)
        ];
        // очищаем валидацию
        validation.clear();
        setData(newData);
    };

    const handleLoadCompanies = ({ page, limit, query: title }) => {
        dispatch(loadCompanies(page, limit, { title, is_email: 1 }));
    };

    const handleDeleteCompanyLink = (index) => {
        setIndexOfDeletedCompanyLink(index);
        setIsOpenDeleteModal(true);
    };

    const deleteCompanyLink = () => {
        const newBoards = data.filter((el, index) => index !== Number(indexOfDeletedCompanyLink));
        setData(newBoards);
        setIndexOfDeletedCompanyLink(null);
        setIsOpenDeleteModal(false);
    };

    // можем показать список (не пустые организации)
    const isShowList = useMemo(() => {
        return data?.reduce((r, i) => r || !!i?.organization, false);
    }, [data]);

    let iterace = 0;

    return (
        <>
            {loading && <Loading circular/>}

            <List className="list">
                {isShowList
                    ? data.map((elem, index) => {
                        if (!elem.organization) return null;

                        // валидация по выбранным событиям
                        // const {
                        //     error,
                        //     helperText,
                        // } = Object.keys(elem.alerts)
                        //     ?.reduce((r, key) => {
                        //         // ключ валидации
                        //         const validKey = `${index}.alerts.${key}`;
                        //         // id события
                        //         const alId = elem?.alerts?.[key];
                        //
                        //         // есть ошибка
                        //         if (validation.isKey(validKey)) {
                        //             // название события
                        //             const currentError = alertsList?.find(({ alarm_type }) => alarm_type === alId)?.title || '';
                        //
                        //             return {
                        //                 error: true,
                        //                 helperText: [
                        //                     ...r.helperText,
                        //                     `${currentError} - ${validation.get(validKey)}`,
                        //                 ],
                        //             };
                        //         }
                        //
                        //         return r;
                        //     }, { error: false, helperText: [] });

                        return (
                            <ListItem className="list_item" key={elem.organization_id} divider>
                                <ListItemText>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        {++iterace}. {elem.organization?.title}
                                        <Tooltip title="Удалить связь">
                                            <IconButton
                                                onClick={() => handleDeleteCompanyLink(index)}
                                                size="small"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    {elem.alerts.length > 0
                                        ? (
                                            <>
                                                <h3>Получать уведомления по событиям:</h3>
                                                {elem?.alerts.map((el, alertsIndex) => (
                                                    <Fragment key={alertsIndex}>
                                                        <Typography variant="body2">
                                                            Эл. адрес: <b>{el.email}</b>
                                                        </Typography>
                                                        <SelectAlerts
                                                            multiple
                                                            alerts={alertsList}
                                                            selected={el.alerts || []}
                                                            onChange={(value) => handleChangeAlerts(index, alertsIndex, value)}
                                                            required
                                                            error={!el.alerts.length}
                                                            // helperText={helperText.join(', ')}
                                                        />
                                                    </Fragment>
                                                ))}
                                            </>
                                        )
                                        : <Typography variant="body2" color="red">
                                            Невозможно присвоить данную организацию, т.к. у нее не указан емейл
                                        </Typography>
                                    }
                                </ListItemText>
                                
                            </ListItem>
                        );
                    })
                    : !loading && (
                        <ListItem className="list_item">
                            <ListItemText>
                                {messages.NO_DATA}
                            </ListItemText>
                        </ListItem>)
                }
            </List>

            {isOpen && <form className="modal__form">
                <UniversalSelect
                    fetchList={handleLoadCompanies}
                    storeName="companies"
                    storeNameProps="companies"
                    onAccept={handleCompanyUniversalSelect}
                    onChange={handleCompanyUniversalSelect}
                    keyProp="id"
                    withSearch={true}
                    multiple={true}
                    isSelected={true}
                    selected={selectedCompanies}
                    renderProps={(el) =>
                        <div>
                            <strong>
                                {el?.title}
                            </strong>
                        </div>
                    }
                    isOpen={isOpen}
                    onClose={onCloseUniversalModal}
                    noPadding={true}
                    title="Список организаций"
                    small={true}
                />
            </form>}
            {isOpenDeleteModal && (
                <ConfirmDelete
                    open={isOpenDeleteModal}
                    onClose={() => setIsOpenDeleteModal(false)}
                    onSuccess={deleteCompanyLink}
                    message={messages.CONFIRM_DELETE}
                />
            )}
        </>
    );
};

export default CompanyList;