import React, { useState } from 'react';
import Modal from '../../../../../common/Modal';
import messages from '../../../../../../helpers/constants/messages';
import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import { ModalFormProps } from '../types';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { createChecklists, editChecklists } from '../../../../../../redux/DorisControl/actions';

const ModalForm = ({ isOpen, isNew, onClose, reloadList, item }: ModalFormProps) => {
    const dispatch = useDispatch();

    const [data, setData] = useState({ name: item?.name || '' });
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        
        setData({
            ...data,
            [name]: value
        });
    };

    const onAcceptModal = () => {
        dispatch(isNew 
            ? createChecklists(data , reloadList) 
            : editChecklists(item?.id, data, reloadList));
        onClose();
    };

    const isDisabled = !data.name.trim();

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? 'Добавить Чек-лист' : 'Редактировать Чек-лист'}
            onClose={onClose}
            noPadding={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <TextField
                        label={'Название'}
                        size="small"
                        value={data.name}
                        variant="outlined"
                        name="name"
                        type="text"
                        onChange={handleChange}
                        required
                    />
                </div>
            </form>
        </Modal>
    );
};

export default ModalForm;