import React, { useEffect, useState, useCallback } from 'react';
import FormButtonsComponent, { buttonsTypes } from '../../../../../common/FormButtons';
import Modal from '../../../../../common/Modal';
import titles from '../../../../../../helpers/constants/titles';
import { useDispatch, useSelector } from 'react-redux';
import { useValidation } from '../../../../../../helpers/hooks';
import DateRange from '../../../../../common/Dates/DateRange';
import transportTypes from '../../../../../../helpers/transport.types';
import SingleKeyboardDateTimePicker from '../../../../../common/Dates/SingleKeyboardDateTimePicker';
import formatDate from '../../../../../../helpers/constants/dateFormat';
import messages from '../../../../../../helpers/constants/messages';
import { fullDateTimeWithTimeZone } from '../../../../../../helpers/date.config';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import {
    createWaybills,
    editWaybills,
    loadEmployees,
} from '../../../../../../redux/TransportPassenger/actions';
import LeaveArriveItem from '../LeaveArriveItem';
import DowntimeItem from './DowntimeItem';
import BrigadeEmployees from './BrigadeEmployees';
import SelectEmployees from '../../../../../common/Autocomplete/Transport/Employees';
import SelectWorkorders from '../../../../../common/Autocomplete/PassengerTransport/Workorders';

const ModalForm = ({
    isOpen,
    isNew,
    onClose,
    el = {},
    licence
}) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const initialState = {
        number: el?.number || '',
        name: el?.name || '',
        date_start: el?.date_start || null,
        date_end: el?.date_end || null,
        work_order: el?.work_order || {},
        work_order_id: el?.work_order_id || '',
        organization_id: el?.organization_id || '',
        inspection_before: el?.inspection_before || null,
        inspection_done: el?.inspection_done || 'Выпуск на линию разрешен',
        driver: el?.driver || '',
        leasing_check: el?.leasing_check || 'Автобус сдан в соответствии с графиком',
        leasing_return_check: el?.leasing_return_check || 'Автобус сдан в соответствии с графиком по окончании смены',
        speed_departure: el?.speed_departure || '',
        speed_arrival: el?.speed_arrival || '',
        registration_number: el?.registration_number || '',
        licence_card_id: el?.licence_card_id || '',
        garage_number: el?.garage_number || '',
        serial: el?.serial || '',
        delivery_on_request: el?.delivery_on_request
            || {
                client: '',
                place: '',
                start: null,
                end: null
            },
        departure_return: el?.departure_return || [],
        dispatcher: el?.dispatcher || '',
        transportation_info: el?.transportation_info || '',
        okud_code: el?.okud_code || '0345006',
        health_check: el?.health_check || true,
        brigade_employees: el?.brigade_employees || [],
        downtime: el.downtime || []
    };

    const [data, setData] = useState(initialState);
    const [employeesSelect, setEmployeesSelect] = useState({});
    const [healthCheck, setHealthCheck] = useState(data.health_check ? 1 : 0);

    const healthText = {
        1: 'Прошел предрейсовый медицинский осмотр, к исполнению трудовых обязанностей допущен',
        0: 'Не прошел предрейсовый медицинский осмотр, к исполнению трудовых обязанностей не допущен'
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        if (e.key === '-' || e.key === 'e') e.preventDefault();
        setData({
            ...data,
            [name]: value
        });
        validation.deleteKey(name);
    };

    const onChangeObj = (e, el) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [el]: {
                ...data[el],
                [name]: value
            }
        });
        validation.deleteKey(el);
    };

    const handleSendData = () => {
        const result = {
            ...data,
            date_start: fullDateTimeWithTimeZone(data.date_start),
            date_end: fullDateTimeWithTimeZone(data.date_end),
            inspection_before: fullDateTimeWithTimeZone(data.inspection_before),
            delivery_on_request: {
                ...data.delivery_on_request,
                start: fullDateTimeWithTimeZone(data.delivery_on_request.start),
                end: fullDateTimeWithTimeZone(data.delivery_on_request.end),
            },
            departure_return: data.departure_return.map((el) => {
                return Object.entries(el).reduce((acc, [key, val]) => {
                    acc[key] = val instanceof Date ? fullDateTimeWithTimeZone(val) : val;
                    return acc;
                }, {});
            }),
            // mark: data?.work_order?.vehicle?.mark,
            mark: data?.work_order?.vehicle?.brand?.slug,
            brigade_id: data?.work_order?.brigades?.[0]?.id,
            route_id: data?.work_order?.traffic_schedule?.route_id_direct,
            health_check: !!healthCheck,
        };
        dispatch(isNew ? createWaybills(result) : editWaybills(el.id, result));
    };

    useEffect(() => {
        if (data.work_order) {
            setData((prevData) => ({
                ...prevData,
                organization_id: data?.work_order?.organization_id,
                work_order_id: data?.work_order?.id,
                garage_number: data?.work_order?.vehicle?.number,
                driver: employeesSelect?.fio,
                date_start: data?.work_order?.traffic_schedule?.start_at,
                date_end: data?.work_order?.traffic_schedule?.end_at,
            }));
        }
    }, [data.work_order, employeesSelect]);

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
        }
    }, [validation, onClose, requestStatus]);

    const downtimeInit = [{
        reason: 'Простой',
        date_start: null,
        date_end: null,
    }];

    const returnDepartureInit = [{
        shift: '',
        departure_plan: null,
        return_plan: null,
        departure_fact: null,
        return_fact: null,
    }];

    const [downtimeList, setDowntimeList] = useState(data.downtime.length > 0 
        ? data.downtime 
        : downtimeInit
    );
    const [returnDepartureList, setReturnDepartureList] = useState(data.departure_return.length > 0 
        ? data.departure_return
        : returnDepartureInit
    );
    const shiftList = data?.work_order?.brigades
        ? data?.work_order?.brigades
            .reduce((res, item) => (item.shift ? [...res, String(item.shift)] : res),[])
        : [];

    useEffect(() => {
        setData((prevData) => ({
            ...prevData,
            departure_return: returnDepartureList
        }));
    }, [returnDepartureList]);

    useEffect(() => {
        setData((prevData) => ({
            ...prevData,
            downtime: downtimeList
        }));
    }, [downtimeList]);

    const isDisabled = !data.name || !data.number || !data.date_start || !data.date_end
        || !data.dispatcher || !data.driver || !data.delivery_on_request.end
        || !data.delivery_on_request.start || !data.inspection_before 
        || !data.licence_card_id || !data.registration_number || !data.departure_return
        || !data.serial || !data.transportation_info || !data.downtime
        || !data.work_order_id;

    const renderTransport = () => {
        const values = [];
        if (data?.work_order && data?.work_order_id) {
            if (
                transportTypes
                && transportTypes[data?.work_order?.vehicle?.category_id]
            ) values.push(transportTypes[data?.work_order?.vehicle?.category_id]?.name);
            if (data?.work_order?.vehicle?.brand?.slug)
                values.push(`марка ${data?.work_order?.vehicle?.brand?.slug}`);
            if (data?.work_order?.vehicle?.model?.slug)
                values.push(`модель ${data?.work_order?.vehicle?.model?.slug}`);
            if (data?.work_order?.vehicle?.number)
                values.push(`номер ${data?.work_order?.vehicle?.number}`);
        }

        return (
            <FormControl className="block" size="small" variant="outlined">
                <TextField
                    label="Транспортное средство"
                    size="small"
                    multiline={true}
                    value={values.join(', ')}
                    variant="outlined"
                    disabled={true}
                    type="text"
                    onChange={onChange}
                />
            </FormControl>
        );
    };

    const renderOrganization = (organization) => {
        const values = [];
        if (organization?.full_name)
            values.push(organization?.full_name);
        if (organization?.juristic_address?.region)
            values.push(`адрес ${organization?.juristic_address?.region}`);
        if (organization?.juristic_address?.city_name)
            values.push(organization?.juristic_address?.city_name);
        if (organization?.juristic_address?.street)
            values.push(organization?.juristic_address?.street);
        if (organization?.phones?.length > 0)
            values.push(`${organization?.phones?.map((el, i) => i+1 + ' телефон ' + el.data || '')}`);

        return (
            <FormControl className="block" size="small" variant="outlined">
                <TextField
                    label="Организация"
                    size="small"
                    multiline={true}
                    value={values.join(', ')}
                    variant="outlined"
                    disabled={true}
                    type="text"
                    // убрал - поле disabled
                    // onChange={onChange}
                />
            </FormControl>
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            noPadding={true}
            onClose={onClose}
            title={isNew ? 'Создание путевого листа' : 'Редактирование путевого листа'}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    isNew
                        ? {
                            ...buttonsTypes.save,
                            // disabled: isDisabled,
                            onClick: handleSendData
                        }
                        : {
                            ...buttonsTypes.change,
                            disabled: isDisabled,
                            onClick: handleSendData
                        }
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label={titles.NUMBER}
                        size="small"
                        value={data.number}
                        variant="outlined"
                        name="number"
                        type="text"
                        onChange={onChange}
                        required={true}
                        error={!data.number || validation.isKey('number')}
                        helperText={validation.get('number')}
                    />
                </FormControl>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label={titles.NAME}
                        size="small"
                        value={data.name}
                        variant="outlined"
                        name="name"
                        type="text"
                        onChange={onChange}
                        required={true}
                        error={!data.name || validation.isKey('name')}
                        helperText={validation.get('name')}
                    />
                </FormControl>

                <div className="block">
                    <SelectWorkorders
                        label="Наряд"
                        selected={data.work_order}
                        onChange={value => {
                            setData(({
                                ...data,
                                work_order: value,
                            }));
                            validation.deleteKey('work_order');
                        }}
                        required={true}
                        error={Object.keys(data.work_order).length === 0 || validation.isKey('work_order')}
                        helperText={validation.get('work_order')}
                    />
                </div>

                <div className="row">
                    <DateRange
                        valueStartDate={data.date_start}
                        valueEndDate={data.date_end}
                        handleDate={(e) => {
                            setData({ ...data, date_start: e });
                            validation.deleteKey('date_start');
                        }}
                        handleEndDate={(e) => {
                            setData({ ...data, date_end: e });
                            validation.deleteKey('date_end');
                        }}
                        startLabel="Начало действия"
                        endLabel="Конец действия"
                        required={true}
                        disableStartField={true}
                        disableFuture={false}
                        errorMessage={() =>
                            <span>{validation.get('date_start') || ''} {validation.get('date_end') || ''}</span>
                        }
                        className="row__item"
                    />
                </div>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        disabled
                        label={titles.GRZ}
                        size="small"
                        value={data?.work_order?.vehicle ? data.work_order?.vehicle?.number : ''}
                        variant="outlined"
                        name="garage_number"
                        type="text"
                        onChange={onChange}
                    />
                </FormControl>
                {renderTransport()}
                {renderOrganization(data?.work_order?.organization)}
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        onChange={(value) => {
                            setData({ ...data, inspection_before: value });
                            validation.deleteKey('inspection_before');
                        }}
                        value={data.inspection_before}
                        error={!data.inspection_before || validation.isKey('inspection_before')}
                        helperText={validation.get('inspection_before')}
                        isRequired={true}
                        pickerFormat={formatDate.DT_EXCEPT_SECONDS}
                        label="Дата и время проведения предрейсового контроля"
                    />
                </div>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label="Предрейсовый контроль технического состояния пройден"
                        size="small"
                        value={data.inspection_done}
                        variant="outlined"
                        name="inspection_done"
                        type="text"
                        onChange={onChange}
                        required={true}
                        helperText='Пример: "Выпуск на линию разрешен"'
                        error={!data.inspection_done || validation.isKey('inspection_done')}
                    />
                </FormControl>

                <div className="block">
                    <SelectEmployees
                        label="Принял водитель"
                        selected={employeesSelect}
                        loadList={loadEmployees}
                        filter={{ organization_id: data.organization_id }}
                        storeLoadingProps="employeesLoading"
                        onChange={(data) => {
                            setEmployeesSelect(data);
                            validation.deleteKey('driver');
                        }}
                        required={true}
                        disabled={!data.work_order?.id}
                        error={validation.isKey('driver')}
                        helperText={validation.get('driver')}
                    />
                </div>

                <FormControl variant="outlined" size="small" className="block">
                    <InputLabel>Отметка о состоянии здоровья водителя</InputLabel>
                    <Select
                        label="Отметка о состоянии здоровья водителя"
                        value={healthCheck}
                        onChange={(e) => {
                            setHealthCheck(Number(e.target.value));
                            validation.deleteKey('health_check');
                        }}
                        name="health_check"
                        error={validation.isKey('health_check')}
                    >
                        {Object.keys(healthText)?.map(key =>
                            <MenuItem key={key} value={key}>{healthText[key]}</MenuItem>
                        )}
                    </Select>
                    {validation.isKey('health_check')
                        ? <FormHelperText error>{validation.get('health_check')}</FormHelperText>
                        : null
                    }
                </FormControl>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label="Отметка о сдаче автобуса при смене водителя"
                        size="small"
                        value={data.leasing_check}
                        variant="outlined"
                        name="leasing_check"
                        type="text"
                        onChange={onChange}
                        required={true}
                        helperText='Пример: "Автобус сдан в соответствии с графиком"'
                        error={!data.leasing_check || validation.isKey('leasing_check')}
                    />
                </FormControl>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label="Отметка о приеме автобуса при возвращении"
                        size="small"
                        value={data.leasing_return_check}
                        variant="outlined"
                        name="leasing_return_check"
                        type="text"
                        onChange={onChange}
                        required={true}
                        helperText='Пример: "Автобус сдан в соответствии с графиком по окончании смены"'
                        error={!data.leasing_return_check || validation.isKey('leasing_return_check')}
                    />
                </FormControl>
                <h2>Показания спидометра: </h2>
                <div className="row">
                    <FormControl className="row__item" size="small" variant="outlined">
                        <TextField
                            label="При выезде"
                            size="small"
                            value={data.speed_departure}
                            variant="outlined"
                            name="speed_departure"
                            type="number"
                            InputProps={{ inputProps: { step: '0.1', min: 0, pattern: '[0-9]*' } }}
                            onChange={onChange}
                            onKeyDown={onChange}
                            error={validation.isKey('speed_departure')}
                            helperText={validation.get('speed_departure')}
                        />
                    </FormControl>
                    <FormControl className="row__item" size="small" variant="outlined">
                        <TextField
                            label="При возвращении"
                            size="small"
                            value={data.speed_arrival || ''}
                            variant="outlined"
                            name="speed_arrival"
                            type="number"
                            InputProps={{ inputProps: { step: '0.1', min: 0, pattern: '[0-9]*' } }}
                            onChange={onChange}
                            onKeyDown={onChange}
                            error={validation.isKey('speed_arrival')}
                            helperText={validation.get('speed_arrival')}
                        />
                    </FormControl>
                </div>

                {/*TODO Отметка о состоянии здоровья водителя нет поля в свагере*/}
                {/*<FormControl className="block" size="small" variant="outlined">*/}
                {/*    <TextField*/}
                {/*        label="Отметка о состоянии здоровья водителя"*/}
                {/*        size="small"*/}
                {/*        value={''}*/}
                {/*        variant="outlined"*/}
                {/*        name=""*/}
                {/*        type="text"*/}
                {/*        onChange={onChange}*/}
                {/*        required={true}*/}
                {/*        helperText='Пример: "Прошел предрейсовый медицинский осмотр,*/}
                {/*             к исполнению трудовых обязанностей допущен"'*/}
                {/*    />*/}
                {/*</FormControl>*/}

                {data?.work_order?.brigades?.length > 0 && (
                    <BrigadeEmployees
                        brigades={data.work_order.brigades}
                        brigadeEmployees={data.brigade_employees}
                        setData={setData}
                    />
                )}

                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label="Наименование, номер маршрута"
                        size="small"
                        multiline={true}
                        value={data?.work_order && data.work_order_id
                            ? (`${transportTypes[data?.work_order?.vehicle?.category_id]?.name || ''}, `
                                    + `маршрут №${data?.work_order.traffic_schedule?.route_direct.num || ''}`
                            )
                            : ''
                        }
                        variant="outlined"
                        disabled={true}
                        type="text"
                        // поле disabled
                        // onChange={onChange}
                    />
                </FormControl>
                <FormControl required={true} variant="outlined" size="small" className="block">
                    <InputLabel>Лицензионная карточка</InputLabel>
                    <Select
                        label="Лицензионная карточка"
                        value={data.licence_card_id}
                        onChange={onChange}
                        name="licence_card_id"
                        required={true}
                        error={!data.licence_card_id || validation.isKey('licence_card_id')}
                    >
                        <MenuItem value="">{messages.NOT_CHOSEN}</MenuItem>
                        {Object.keys(licence)?.length
                            && Object.keys(licence)?.map(key =>
                                <MenuItem key={key} value={key}>{licence[key]}</MenuItem>
                            )
                        }
                    </Select>
                    {validation.isKey('licence_card_id') 
                        ? <FormHelperText error>{validation.get('licence_card_id')}</FormHelperText>
                        : null
                    }
                </FormControl>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label="Регистрационный №"
                        size="small"
                        value={data.registration_number}
                        variant="outlined"
                        name="registration_number"
                        type="text"
                        onChange={onChange}
                        required={true}
                        error={!data.registration_number || validation.isKey('registration_number')}
                        helperText={validation.get('registration_number')}
                    />
                </FormControl>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label="Cерия"
                        size="small"
                        value={data.serial || ''}
                        variant="outlined"
                        name="serial"
                        type="text"
                        onChange={onChange}
                        required={true}
                        error={!data.serial || validation.isKey('serial')}
                        helperText={validation.get('serial')}
                    />
                </FormControl>
                {/*TODO какой-то номер*/}
                {/*<FormControl className="block" size="small" variant="outlined">*/}
                {/*    <TextField*/}
                {/*        label="№"*/}
                {/*        size="small"*/}
                {/*        // value={data.serial}*/}
                {/*        variant="outlined"*/}
                {/*        // name="serial"*/}
                {/*        type="text"*/}
                {/*        disabled={true}*/}
                {/*        onChange={onChange}*/}
                {/*        error={!data.serial || validation.isKey('serial')}*/}
                {/*        helperText={validation.get('serial')}*/}
                {/*    />*/}
                {/*</FormControl>*/}
                <h2>Доставка по запросу: </h2>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label="Заказчик"
                        size="small"
                        value={data.delivery_on_request.client || ''}
                        variant="outlined"
                        name="client"
                        type="text"
                        onChange={(e) => onChangeObj(e, 'delivery_on_request')}
                        error={validation.isKey('delivery_on_request.client')}
                        helperText={validation.get('delivery_on_request.client')}
                    />
                </FormControl>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label="Место подачи"
                        size="small"
                        value={data.delivery_on_request.place || ''}
                        variant="outlined"
                        name="place"
                        type="text"
                        onChange={(e) => onChangeObj(e, 'delivery_on_request')}
                        error={validation.isKey('delivery_on_request.place')}
                        helperText={validation.get('delivery_on_request.place')}
                    />
                </FormControl>

                <div className="row">
                    <SingleKeyboardDateTimePicker
                        label="Время начала (ЧЧ:ММ)"
                        className="row__item"
                        required={true}
                        timeOnly={true}
                        value={data.delivery_on_request.start || null}
                        onChange={(event) => {
                            setData({
                                ...data,
                                delivery_on_request: {
                                    ...data.delivery_on_request,
                                    start: event
                                }
                            });
                            validation.deleteKey('delivery_on_request.start');
                        }}
                        error={validation.isKey('delivery_on_request.start')}
                        helperText={validation.get('delivery_on_request.start')}
                    />

                    <SingleKeyboardDateTimePicker
                        label="Время конца (ЧЧ:ММ)"
                        className="row__item"
                        required={true}
                        timeOnly={true}
                        value={data.delivery_on_request.end || null}
                        onChange={(event) => {
                            setData({
                                ...data,
                                delivery_on_request: {
                                    ...data.delivery_on_request,
                                    end: event
                                }
                            });
                            validation.deleteKey('delivery_on_request.end');
                        }}
                        error={validation.isKey('delivery_on_request.end')}
                        helperText={validation.get('delivery_on_request.end')}
                    />
                </div>

                {data.work_order?.brigades
                    && <LeaveArriveItem 
                        data={returnDepartureList}
                        onChange={setReturnDepartureList}
                        shiftList={shiftList}
                    />
                }
                <DowntimeItem
                    data={downtimeList}
                    onChange={setDowntimeList}
                />              
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label="Диспетчер"
                        size="small"
                        value={data.dispatcher || ''}
                        variant="outlined"
                        name="dispatcher"
                        type="text"
                        onChange={onChange}
                        required={true}
                        helperText='Пример: "ФИО Диспетчера"'
                        error={!data.dispatcher || validation.isKey('dispatcher')}
                    />
                </FormControl>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label="Сведения о перевозке"
                        size="small"
                        value={data.transportation_info || ''}
                        variant="outlined"
                        name="transportation_info"
                        type="text"
                        onChange={onChange}
                        required={true}
                        helperText='Пример: "Регулярная перевозка пассажиров и багажа в городском сообщении"'
                        error={!data.transportation_info || validation.isKey('transportation_info')}
                    />
                </FormControl>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label="Код формы по ОКУД"
                        size="small"
                        value={data.okud_code || ''}
                        variant="outlined"
                        name="okud_code"
                        type="text"
                        onChange={onChange}
                        required={true}
                        error={!data.okud_code || validation.isKey('okud_code')}
                    />
                </FormControl>
            </div>
        </Modal>
    );
};

export default ModalForm;