import { loadDtpLoadTypes } from 'redux/Incidents/actions';
import { useStoreProp } from 'helpers/hooks';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

interface LoadType {
    [key: string]: string;
}

interface ConvertedLoadType {
    id: string;
    name: string;
}

type Excludes =
    | 'onReset'
    | 'renderLabel'
    | 'inputName'
    | 'onShowList'
    | 'options'
    | 'filterSelectedOptions'
    | 'isOptionEqualToValue'
    | 'readOnly';

interface TypeLoadProps<T> extends Omit<CommonAutocompleteProps<T>, Excludes> {
    // Здесь можно добавлять пропсы для этого компонента
}

export const TypeLoad = ({
    multiple = false,
    selected = multiple ? [] : null, // полные объекты с типами или просто айдишники
    onChange,
    required,
    disabled,
    label = 'Источник создания',
    error,
    helperText = '',
    limitTags = 1,
    openOnFocus,
}: TypeLoadProps<ConvertedLoadType>) => {
    const loadTypes: LoadType = useStoreProp(loadDtpLoadTypes, 'incidents', 'loadTypes');

    const loadOptions: ConvertedLoadType[] = Object
        .keys(loadTypes)
        .map((key) => ({
            id: key,
            name: loadTypes[key]
        }));

    const selectedObjects = Array.isArray(selected) && selected.length > 0
        ? selected.map(el => {
            if (typeof el === 'object') return el;
            return loadOptions.find(item => item.id === el) || loadOptions[0];
        })
        : selected;

    return (
        <CommonAutocomplete<ConvertedLoadType>
            multiple={multiple}
            selected={selectedObjects}
            onChange={onChange}
            required={required}
            options={loadOptions}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => Number(option?.id) === Number(value?.id)}
            disabled={disabled}
            renderLabel={(option) => option?.name || ''}
            limitTags={limitTags}
            label={label}
            error={error}
            helperText={helperText}
            openOnFocus={openOnFocus}
            showAsSelect
        />
    );
};
