import React, { useMemo } from 'react';
import { isObject } from 'chart.js/helpers';
import { useValidation } from 'helpers/hooks';

const TitleValidator = (props) => {
    const validation = useValidation();

    const {
        fields = {},
        validationFields = [],
        validationKeys = [],
        children = 'Проложить маршрут',
    } = props;

    const keysForValidation = validationKeys?.length > 0
        ? validationKeys
        : validationFields;

    // const fieldsError = useMemo(() => validationFields
    //     ?.reduce((res, key) => {
    //         const value = fields[key];
    //         const isValue = isObject(value)
    //             ? Object.keys(value).length === 0
    //             : !value;

    //         return res || isValue;
    //     }, false)
    // , [fields, validationFields]);

    const validError = useMemo(() => keysForValidation
        ?.reduce((res, key) => {
            return res || validation.isKey(key);
        }, false)
    , [fields, keysForValidation]);

    // const isError = useMemo(() => fieldsError || validError, [fieldsError, validError]);
    const isError = useMemo(() => validError, [validError]);

    return (
        <h3 className={isError ? 'error' : ''}>
            {children}
        </h3>
    );
};

export default TitleValidator;