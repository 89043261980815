import React from 'react';
import { useSelector } from 'react-redux';

import { boardsSelectors } from 'redux/Boards';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';

import styles from '../../Messages/messages.module.scss';

const ActiveMessage = ({ isOpen, onClose, title }) => {

    const activeBoardMessage = useSelector(boardsSelectors.activeBoardMessage);
    const loadingActiveBoardMessage = useSelector(boardsSelectors.loadingActiveBoardMessage);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    }
                ]}
            />}
            title={title}
        >
            <div className="modal__form">
                {loadingActiveBoardMessage && <Loading linear={true}/>}
                {activeBoardMessage?.data
                    && activeBoardMessage?.data?.length > 0
                    && <div className={styles.preview} dangerouslySetInnerHTML={{ __html: activeBoardMessage?.data }}></div>
                }
                {activeBoardMessage?.data === null
                    && <h2 style={{ margin: '1.5rem' }}>В настоящий момент сообщение отсутствует</h2>
                }
            </div>
        </Modal>
    );
};

export default ActiveMessage;
