import Vehicles from 'modules/TransportWater/Dictionaries/Vehicles';

const dictionariesRoutes = [
    {
        path: '/dictionaries/transport-water/vehicles',
        component: Vehicles,
        exact: true,
    },
];

export default dictionariesRoutes;
