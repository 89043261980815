import React from 'react';
import DateFilter from './DateFilter';
import Loading from '../../../../common/Loading';
import { IconButton, Tooltip } from '@mui/material';
import titles from '../../../../../helpers/constants/titles';

const Item = (props) => {
    const {
        data,
        onClick,
        // onLock,
        // onUnLock,
        loading = false,
        types = {},
        onChangeDate,
        active = null,
        categoryTS
    } = props;

    const {
        id,
        brand_name,
        model_name,
        number,
        type,
        history = false,
        category_id,
        organization
    } = data;

    const currentTS = categoryTS.find(item => item.id === category_id);
    const isActive = active === id; //!!active || false;

    // клик
    const handleClick = () => {
        onClick(data);
    };

    return (
        <div
            className={`layers-sidebar__layer__item${isActive ? ' active' : ''}`}
            style={{ display: 'block' }}
        >
            {/* название */}
            <div className="layers-sidebar__layer__item__content" onClick={handleClick}>
                <div className="description">
                    {'ГРЗ: '}
                    {number}
                    {/*{numberValidator(number) || 'Информация отсутствует'}*/}
                </div>
                <div className="">Марка и модель: {brand_name + ' ' + model_name}</div>
                <div>{titles.TYPE_OF_VEHICLE}: {currentTS?.name || ''}</div>
                <div>Организация: {organization?.title || ''}</div>
            </div>

            {(isActive && history) && (
                <DateFilter
                    // start_date={start_date}
                    // end_date={end_date}
                    onChange={onChangeDate}
                />
            )}

            {/* лоадинг */}
            {loading && <Loading className="absolute bottom fill" />}

        </div>
    );
};

export default Item;
