import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PassportTabs from 'components/pages/Dictionaries/DorisControl/GovernmentContract/PassportPage/PassportTabs';

interface GovernmentContractModalTypes {
    open: boolean,
    onClose: () => void,
    contractId: number,
}

const GovernmentContractModal = ({
    open,
    onClose = () => {},
    contractId,
}: GovernmentContractModalTypes) => {

    return <Modal
        isOpen={open}
        onClose={onClose}
        fullWidth
        buttons={
            <FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                ]}
            />
        }
    >
        <PassportTabs
            id={contractId}
        />
    </Modal>;
};

export default GovernmentContractModal;