import { useEffect, useRef, useState } from 'react';

import { config } from 'config';
import messages from 'helpers/constants/messages';

const Monitoring = ({ currentAip }) => {
    // этот апи удален из конфига
    const apiAipMonitoring = () => config.get('urls').apiAipMonitoring;
    const ref = useRef(null);
    const src = `${apiAipMonitoring()}/d/aip-info/ifnormatsiia-po-aip?orgId=1&var-aip_id=${currentAip.id}`;

    const [frameHeight, setFrameHeight] = useState(0);

    useEffect(() => {
        if (ref.current && ref.current?.clientHeight !== frameHeight) {
            setFrameHeight(ref.current?.clientHeight || 500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current]);

    if (apiAipMonitoring()) {
        return (
            <div
                ref={ref}
                style={{ display: 'flex', height: '100%' }}
            >
                {currentAip && frameHeight
                    ? <iframe
                        title="dashboard"
                        width="100%"
                        height={frameHeight}
                        src={src}
                        frameBorder="0"
                    />
                    : messages.DATA_IS_NOT_FOUND
                }
            </div>
        );
    } else {
        return (
            <div>
                {messages.DATA_IS_NOT_FOUND}
            </div>
        );
    }
};

export default Monitoring;
