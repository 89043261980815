const userTitles = {
    USER: 'Пользователь',
    USERS: 'Пользователи',
    EDIT_USER: 'Редактирование пользователя',
    CREATE_USER: 'Добавить пользователя',
    EDIT_ROLE: 'Изменить роль',
    CREATE_ROLE: 'Добавить роль',
    FIRST_NAME: 'Имя',
    LAST_NAME: 'Фамилия',
    PATRONYMIC: 'Отчество',
    EMPLOYEES: 'Ответственный',
    PERMISSION: 'Доступ',
    ROLES: 'Роли',
    ROLE: 'Роль',
    SELECT_ACCESS_PERMISSIONS: 'Выбрать права доступа',
};

export default userTitles;