// иконка для карты
const iconMap = ({ fill = '#000', innerFill = '#fff' }) => {
    return `
        <svg viewBox="0 0 107.000000 130.000000" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
         <g>
          <g id="svg_trf_1">
           <rect x="-1" y="-1" width="109" height="132" id="canvas_background" fill="none"/>
          </g>
          <g id="svg_trf_2">
           <g fill="${fill}" transform="translate(0,130) scale(0.10000000149011612,-0.10000000149011612) " id="svg_trf_i_1">
            <path d="m440.34884,1286c-100,-19 -206,-75 -281,-150c-104,-104 -154,-228 -154,-381c0,-144 38,-245 149,-392c64,-85 297,-316 348,-345c30,-17 31,-17 71,12c68,49 237,216 302,299c157,200 210,350 181,517c-25,142 -121,287 -241,364c-108,70 -251,98 -375,76z" id="svg_trf_i_2"/>
           </g>
            <g stroke="null" id="svg_trf_7">
            <path stroke="null" fill="${innerFill}" id="svg_trf_6" d="m21.34504,36.48423l3.00762,-9.02854c1.23364,-3.70091 4.70118,-6.1965 8.59807,-6.1965l15.72348,0c3.89688,0 7.3633,2.49559 8.59807,6.1965l2.76407,8.30354l-1.71055,0c-5.46017,0 -10.30862,3.48907 -12.04183,8.67737l-2.57149,7.73713c-0.71367,0.49844 -1.37071,1.06485 -1.93711,1.71055l-15.46293,0l0,3.62501c0,2.00508 -1.62332,3.62501 -3.62501,3.62501l-1.8125,0c-2.00168,0 -3.62498,-1.61993 -3.62498,-3.62501l-0.00003,-14.50004c0,-2.87735 1.6709,-5.35822 4.09513,-6.52502l0,0zm7.87873,-0.725l23.1774,0l-2.00508,-6.01072c-0.24922,-0.73973 -0.94024,-1.2393 -1.72188,-1.2393l-15.72348,0c-0.78164,0 -1.47266,0.49957 -1.72188,1.2393l-2.00508,6.01072zm-2.91134,6.34377c-1.50098,0 -2.71876,1.21211 -2.71876,2.71876c0,1.50664 1.21778,2.71876 2.71876,2.71876c1.50098,0 2.71876,-1.21211 2.71876,-2.71876c0,-1.50664 -1.21778,-2.71876 -2.71876,-2.71876zm32.01336,-2.71876l15.72348,0c3.89688,0 7.3633,2.49219 8.59807,6.1965l3.01329,9.02854c2.4129,1.1668 4.08946,3.64767 4.08946,6.52502l0,14.50004c0,2.00508 -1.61993,3.62501 -3.62501,3.62501l-1.8125,0c-2.00508,0 -3.62501,-1.61993 -3.62501,-3.62501l0,-3.62501l-29.00007,0l0,3.62501c0,2.00508 -1.61993,3.62501 -3.62501,3.62501l-1.8125,0c-2.00508,0 -3.62501,-1.61993 -3.62501,-3.62501l0,-14.50004c0,-2.87735 1.67657,-5.35822 4.08946,-6.52502l3.01329,-9.02854c1.23477,-3.70431 4.70118,-6.1965 8.59807,-6.1965l0,0zm0,7.25002c-0.78164,0 -1.47266,0.49844 -1.72188,1.23477l-2.00508,6.01525l23.1774,0l-2.00508,-6.01525c-0.24922,-0.73633 -0.94024,-1.23477 -1.72188,-1.23477l-15.72348,0zm-6.6383,19.0313c1.50664,0 2.71876,-1.21211 2.71876,-2.71876c0,-1.50664 -1.21211,-2.71876 -2.71876,-2.71876c-1.50664,0 -2.71876,1.21211 -2.71876,2.71876c0,1.50664 1.21211,2.71876 2.71876,2.71876zm29.00007,-5.43751c-1.50664,0 -2.71876,1.21211 -2.71876,2.71876c0,1.50664 1.21211,2.71876 2.71876,2.71876c1.50664,0 2.71876,-1.21211 2.71876,-2.71876c0,-1.50664 -1.21211,-2.71876 -2.71876,-2.71876z"/>
           </g>
          </g>
         </g>
        </svg>
    `;
};

export default iconMap;
