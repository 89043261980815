import CircleStatus from 'components/common/CircleStatus';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import messages from 'helpers/constants/messages';

const Item = ({ item = {}, statuses }) => {
    const { name, desc, state, control_type, lat, lon, status, color } = item;

    return (
        <LSContentItem>
            <LSContentColumn>
                <CircleStatus title={statuses[status]} color={color} />
            </LSContentColumn>
            <LSContentColumn>
                {name || messages.NO_DATA}
            </LSContentColumn>
            <LSContentColumn>
                <CircleStatus title={state?.full_name} color={state?.color} />
            </LSContentColumn>
            <LSContentColumn>
                {control_type?.full_name || messages.NO_DATA}
            </LSContentColumn>
            <LSContentColumn>
                {desc || messages.NO_DATA}
            </LSContentColumn>
            <LSContentColumn>
                {(lat && lon) ? <span>{lat}/{lon}</span> : messages.NO_DATA}
            </LSContentColumn>
        </LSContentItem>
    );
};

export default Item;
