import instance from '../../helpers/axios.config';

import apiUrls from './api.urls';

const ApiTransportSpecialMethods = {
    getSideBar: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getSidebar(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },
    getVehicles: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getVehicles(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },
    getVehicleCategories: async () => {
        const response = await instance.get(apiUrls.getVehicleCategories());
        return response.data;
    },
    createVehicleCategory: async (data) => {
        const response = await instance.post(apiUrls.createCategory(), data);
        return response?.data;
    },
    editVehicleCategory: async (id, data) => {
        const response = await instance.put(apiUrls.editCategory(id), data);
        return response?.data;
    },
    deleteVehicleCategory: async (id) => {
        const response = await instance.delete(apiUrls.deleteCategory(id));
        return response?.data;
    },
    getVehicleCharacteristics: async () => {
        const response = await instance.get(apiUrls.getVehicleCharacteristics());
        return response.data;
    },
    getBrands: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getBrands(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },
    getModels: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getModels(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },
    getRadioTypes: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getRadioTypes(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },
    createVehicle: async (data) => {
        const response = await instance.post(apiUrls.createVehicle(), data);
        return response.data;
    },
    editVehicle: async (id, data) => {
        const response = await instance.put(apiUrls.editVehicle(id), data);
        return response.data;
    },
    deleteVehicle: async (id) => {
        const response = await instance.delete(apiUrls.deleteVehicle(id));
        return response.data;
    },
    createBrand: async (data) => {
        const response = await instance.post(apiUrls.createBrand(), data);
        return response.data;
    },
    editBrand: async (id, data) => {
        const response = await instance.put(apiUrls.editBrand(id), data);
        return response.data;
    },
    deleteBrand: async (id) => {
        const response = await instance.delete(apiUrls.deleteBrand(id));
        return response.data;
    },
    createModel: async (data) => {
        const response = await instance.post(apiUrls.createModel(), data);
        return response.data;
    },
    editModel: async (id, data) => {
        const response = await instance.put(apiUrls.editModel(id), data);
        return response.data;
    },
    deleteModel: async (id) => {
        const response = await instance.delete(apiUrls.deleteModel(id));
        return response.data;
    },
    // getCurrentSpecialReport: async (id, params) => {
    //     const response = await instance.get(apiUrls.getCurrentSpecialReport(id), {
    //         params,
    //         // responseType: 'blob'
    //     });
    //     return response?.data;
    // },
    getVehicleCard: async (id, data) => {
        const response = await instance.get(apiUrls.getVehicleCard(id), data);
        return response?.data;
    },
    postCoords: async (uids) => {
        const response = await instance.get(apiUrls.getCoords(), { uids });
        return response.data;
    },
    getCoords: async () => {
        const response = await instance.get(apiUrls.getCoords());
        return response.data;
    },
    getTypes: async () => {
        const response = await instance.get(apiUrls.getTypes());
        return response.data;
    },
    getReportTS: async (params = {}) => {
        const response = await instance.get(apiUrls.getListTS(), {
            params,
            responseType: 'blob',
        });
        return response?.data;
    },
    getVehicleReportList: async (config = {}) => {
        const response = await instance.get(apiUrls.getVehicleReport(), config);
        return response?.data;
    },
    getHistory: async (vehicleId, params) => {
        const response = await instance.get(apiUrls.getHistory(vehicleId), {
            params
        });
        return response.data;
    },

    /* color speed */
    getColorSpeedList: async (params) => {
        const response = await instance.get(apiUrls.getColorSpeedList(), { params });
        return response?.data;
    },
    addColorSpeed: async (data) => {
        const response = await instance.post(apiUrls.addColorSpeed(), data);
        return response?.data;
    },
    editColorSpeed: async (id, data) => {
        const response = await instance.put(apiUrls.editColorSpeed(id), data);
        return response?.data;
    },
    deleteColorSpeed: async (id) => {
        const response = await instance.delete(apiUrls.deleteColorSpeed(id));
        return response?.data;
    },

    getCategories: async (params) => {
        const response = await instance.get(apiUrls.getCategories(), params);
        return response?.data;
    },

    getTelemetryReceivers: async (params) => {
        const response = await instance.get(apiUrls.getTelemetryReceivers(), { params });
        return response.data;
    },

    addTelemetryReceiver: async (data) => {
        const response = await instance.post(apiUrls.addTelemetryReceiver(), data);
        return response.data;
    },

    editTelemetryReceiver: async (id, data) => {
        const response = await instance.put(apiUrls.editTelemetryReceiver(id), data);
        return response.data;
    },

    deleteTelemetryReceiver: async (id) => {
        const response = await instance.delete(apiUrls.deleteTelemetryReceiver(id));
        return response.data;
    },

    getTelemetryReceiverTypes: async () => {
        const response = await instance.get(apiUrls.getTelemetryReceiverTypes());
        return response.data;
    },

    getVehicleCategoryList: async (params) => {
        const response = await instance.get(apiUrls.getVehicleCategoryList(), { params });
        return response.data;
    },

    getReportByVehicles: async (params) => {
        const response = await instance.get(apiUrls.getReportByVehicles(), { params });
        return response.data;
    },

    loadEgtsTransmitters: async (params) => {
        const response = await instance.get(apiUrls.loadEgtsTransmitters(), { params });
        return response.data;
    },

    addEgtsTransmitter: async (data) => {
        const response = await instance.post(apiUrls.addEgtsTransmitter(), data);
        return response.data;
    },

    editEgtsTransmitter: async (id, data) => {
        const response = await instance.put(apiUrls.editEgtsTransmitter(id), data);
        return response.data;
    },

    deleteEgtsTransmitter: async (id) => {
        const response = await instance.delete(apiUrls.deleteEgtsTransmitter(id));
        return response.data;
    },
};

export default ApiTransportSpecialMethods;
