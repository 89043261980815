import React, { useMemo } from 'react';
import { Doughnut as Component } from 'react-chartjs-2';
import { Container, Item, Name, Value } from '../../List';


const Doughnut = ({
    data = [],
    width = 200,
    height = 200,
    customLegend = false,
    flexStyle = false,
}) => {
    const callbacks = {
        // label: (context) => `${context?.label} ${context?.formattedValue}%`,
        label: (context) => `${context?.label}`,
        title: () => ''
    };

    const flex_style = flexStyle
        ? {
            display: 'flex',
            justifyContent: 'space-around'
        }
        : {};

    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        redraw: false,
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks
            },
            legend: {
                display: !customLegend,
                align: 'top',
                // position: 'left',
                labels: {
                    boxWidth: 20,
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
            }
        },
    };

    const dataG = useMemo(() => {

        const {
            colors,
            labels,
            values,
        } = data?.reduce((res, { color, isHidden = false, items: { 0: { label, value } } }) => {
            if (!isHidden) {
                return {
                    colors: [
                        ...res.colors || [],
                        color,
                    ],
                    labels: [
                        ...res.labels || [],
                        ` ${label} (${value})`,
                    ],
                    values: [
                        ...res.values || [],
                        value,
                    ],
                };
            }
            return res;
        }, {});

        const datasets = {
            data: values,
            maxBarThickness: 80,
            backgroundColor: colors, //.map((color) => hexToRgba(color, .8)),
            // borderColor: colors,
            borderWidth: 0,
        };

        return {
            labels: labels,
            datasets: [datasets],
        };
    }, [data]);

    return (
        <div style={flex_style}>
            <Container style={{ marginBottom: '1rem' }}>
                {data?.map(({ color, items: { 0: { label, value } } }, index) => (
                    <Item className="small" key={index}>
                        <Name>{label}</Name>
                        <Value style={{ color }} >{value}</Value>
                    </Item>
                ))}
            </Container>

            <div>
                <Component
                    width={width}
                    height={height}
                    data={dataG}
                    options={options}
                />
            </div>
        </div>
    );
};

export default Doughnut;
