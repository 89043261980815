import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dorisControlSelectors } from 'redux/DorisControl';
import { load1CNames } from 'redux/DorisControl/actions';
import titles from 'helpers/constants/titles';
import NoData from 'components/common/Information/NoData';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';

import Item from './Item';
import ModalForm from './ModalForm';
import Filter from './Filter';

import type { Meta } from 'types/pagination';
import type { ComplexName } from './types';

const ComplexNames = () => {
    const dispatch = useDispatch();

    const list: ComplexName[] = useSelector(dorisControlSelectors.namesIn1CData);
    const meta: Meta = useSelector(dorisControlSelectors.namesIn1CMeta);
    const loading: boolean = useSelector(dorisControlSelectors.loading1CNames);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openModal, setOpenModal] = useState(false);

    const reloadList = (isDelete = false) => {
        const page = (isDelete && list.length === 1 && meta.last_page > 1)
            ? params.page - 1
            : params.page;

        dispatch(load1CNames(page, limit, params.data));
    };

    useEffect(() => {
        dispatch(load1CNames(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    const renderContent = () => {
        return (
            <>
                {list.length > 0
                    ? (
                        <LSContainer headers={[
                            { title: titles.NAME },
                            { title: titles.ACTIONS, isActions: true }
                        ]}>
                            {list.map((item) => (
                                <Item
                                    key={item.id}
                                    item={item}
                                    reloadList={reloadList}
                                />
                            ))}
                        </LSContainer>
                    )
                    : !loading && <NoData />
                }
            </>
        );
    };

    return (
        <>
            <PageLayout
                header="Стандартные названия КФВФ"
                filters={<Filter setParams={setParams} />}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenModal(true),
                                    },
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>
                    ),
                    total: meta?.total,
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit: limit,
                    setLimit: setLimit,
                }}
            />

            {openModal && (
                <ModalForm
                    isNew
                    onClose={() => setOpenModal(false)}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default ComplexNames;
