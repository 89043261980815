import { useState } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import { loadVehicleCategories } from 'redux/TransportRoad/actions';
import messages from 'helpers/constants/messages';
import useStoreProp from 'helpers/hooks/useStoreProp';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const initialState = {
    number: '',
    category_id: ''
};

const Filter = ({ setParams }) => {
    const vehiclesCategories = useStoreProp(
        loadVehicleCategories,
        'transportRoad',
        'vehicleCategories'
    );

    const [formData, setFormData] = useState(initialState);

    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const search = (filter = formData) => {
        const prepareData = removeEmptyFields(filter, false);

        setParams({ page: 1, data: prepareData });
    };

    const resetSearch = (needRefresh) => {
        setFormData(initialState);
        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }
    };

    const isDisabled = Object.keys(removeEmptyFields(formData)).length === 0;

    const updateFilter = (filters) => {
        if (Object.keys(filters).length > 0) {
            const mergedFilters = { ...initialState, ...filters };

            setFormData(mergedFilters);
            search(mergedFilters);
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetSearch}
            onSearch={search}
            filter={formData}
            setUserFilter={updateFilter}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <TextField
                    variant="outlined"
                    value={formData.number}
                    onChange={(e) => handleChange('number', e.target.value)}
                    label={titles.GRZ}
                    size="small"
                    onSubmit={search}
                />
                <FormControl variant="outlined" size="small">
                    <InputLabel>{titles.TYPE_OF_VEHICLE}</InputLabel>
                    <Select
                        label={titles.TYPE_OF_VEHICLE}
                        value={formData.category_id}
                        onChange={(e) => handleChange('category_id', e.target.value)}
                    >
                        <MenuItem value="">{messages.NOT_CHOSEN}</MenuItem>
                        {vehiclesCategories?.length > 0
                            ? vehiclesCategories.map((item) =>
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            )
                            : <MenuItem value=" ">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
