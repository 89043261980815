export const dadata = (state) => state.dadata;

export const addressAreaslist = (state) => dadata(state).addressAreaslist;
export const addressCitieslist = (state) => dadata(state).addressCitieslist;
export const addressStritslist = (state) => dadata(state).addressStritslist;
export const addressHouseslist = (state) => dadata(state).addressHouseslist;

export const addressList = (state) => dadata(state).addressList;
export const address = (state) => dadata(state).address;
export const loading = (state) => dadata(state).loading;
export const geocodeAddress = (state) => dadata(state).geocodeAddress;
export const loadingGeocodeAddress = (state) => dadata(state).loadingGeocodeAddress;