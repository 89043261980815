import { 
    Collapse, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow 
} from '@mui/material';

import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';

const Info = ({ isOpen, dtpList = [] }) => {
    return (
        <Collapse in={isOpen} timeout={'auto'} className="info">
            <h3>Список ДТП</h3>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>ID ДТП</TableCell>

                            <TableCell>{titles.DATE_TIME_DTP}</TableCell>
                            <TableCell>{titles.DTP_TYPE}</TableCell>
                            <TableCell>{titles.COUNT_DTP_DEAD}</TableCell>
                            <TableCell>{titles.COUNT_DTP_WOUNDED}</TableCell>  
                            <TableCell>{titles.ADDRESS}</TableCell>
                            <TableCell>{titles.DATE_TIME_CREATED}</TableCell>
                            <TableCell>{titles.DATE_TIME_CONFIRMED}</TableCell>
                            <TableCell>{titles.DESCRIPTION}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dtpList
                            .sort((a, b) => new Date(b.dtp_at) - new Date(a.dtp_at))
                            .map((dtp, index) => (
                                <TableRow key={dtp.id}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{dtp.id}</TableCell>
                                    <TableCell>{fullDateTimeWithoutSeconds(dtp.dtp_at) || messages.NO_DATA}</TableCell>
                                    <TableCell>{dtp.dtp_type_name || messages.NO_DATA}</TableCell>
                                    <TableCell>{dtp.dead ?? messages.NO_DATA}</TableCell>
                                    <TableCell>{dtp.wounded ?? messages.NO_DATA}</TableCell>
                                    <TableCell style={{ maxWidth: '350px' }}>{dtp.address_text || messages.NO_DATA}</TableCell>
                                    <TableCell>{fullDateTimeWithoutSeconds(dtp.created_at) || messages.NO_DATA}</TableCell>
                                    <TableCell>{fullDateTimeWithoutSeconds(dtp.confirmed_at) || messages.NO_DATA}</TableCell>
                                    <TableCell>{dtp.description || messages.NO_DATA}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Collapse>
    );
};

export default Info;
