import React, { useState } from 'react';
import {
    Autocomplete,
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import titles from '../../../../../helpers/constants/titles';
import { loadInfrastructure } from '../../../../../redux/RoadNetwork/actions';
import UniversalSelect from '../../../UniversalSelect';

const useStyles = makeStyles({
    input: {
        paddingRight: '54px !important',
    },
    adornment: {
        position: 'absolute',
        right: '6px',
    },
});

const SelectInfrastructure = ({
    multiple = false,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label=titles.SELECT_INFRASTRUCTURE_OBJECT,
    error = false,
    helperText = '',
    filter = {},
}) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);

    const autoCompleteChange = (e, value) => {
        if (e?.key !== 'Enter') onChange(value);
    };

    const getList = (params) => {
        const { page, limit, query } = params;

        dispatch(loadInfrastructure(
            page, 
            limit, 
            {
                ...(query && { query }),
                ...filter,
            }
        ));
    };

    const handleClose = () => setShowList(false);

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    return (
        <>
            <Autocomplete
                multiple={multiple}
                value={selected}
                options={[]}
                disabled={!multiple}
                // filterSelectedOptions
                getOptionLabel={(option) => option?.name || ''}
                onChange={autoCompleteChange}
                freeSolo
                size="small"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={error}
                        size="small"
                        variant="outlined"
                        required={required}
                        name="type"
                        label={label}
                        disabled={!multiple}
                        helperText={helperText}
                        InputProps={{
                            ...params.InputProps,
                            classes: { root: styles.input },
                            endAdornment: (
                                <InputAdornment position={'end'} classes={{ root: styles.adornment }}>
                                    {selected && Object.keys(selected).length > 0 
                                            && <IconButton size="small" onClick={() => onChange(multiple ? [] : {})}>
                                                <i className="far fa-times"/>
                                            </IconButton>
                                    }
                                    <IconButton disabled={disabled} size="small" onClick={() => setShowList(true)}>
                                        <i className="fas fa-search-plus"/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                )}
            />
            {showList 
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName="roadNetworks"
                    storeNameProps="infrastructure"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selected}
                    renderProps={(el) => <div>{el?.name}</div>}
                    onAccept={handleAccept}
                    isOpen={showList}
                    onClose={handleClose}
                    noPadding
                    title={titles.SELECT_INFRASTRUCTURE_OBJECT}
                    small
                />
            }
        </>
    );
};

export default SelectInfrastructure;
