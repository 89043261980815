import Form from '../../../../pages/Dictionaries/RoadNetwork/Infrastructure/Modal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    createInfrastructure,
    editInfrastructure,
    deleteInfrastructure,
    savedInfrastructure,
} from '../../../../../redux/RoadNetwork/actions';
import ConfirmDelete from '../../../../common/ConfirmModal';
import messages from '../../../../../helpers/constants/messages';
import { roadNetworksSelectors } from '../../../../../redux/RoadNetwork';


const useForm = (onSuccess) => {
    const dispatch = useDispatch();

    const [isAdd, setIsAdd] = useState(null);
    const [isEdit, setIsEdit] = useState(null);
    const [isDelete, setIsDelete] = useState(null);
    const saved = useSelector(roadNetworksSelectors.savedInfrastructure);

    // данные изменены / сохранены
    const handleSaved = () => {
        dispatch(savedInfrastructure(true));
    };

    useEffect(() => {
        if (saved === true) {
            dispatch(savedInfrastructure(false));
            onSuccess?.();
        }
    }, [saved]);

    const renderComponent = () => (
        (
            <>
                {/* форма */}
                {(!!isAdd || !!isEdit) &&  (
                    <Form
                        isNew={isAdd}
                        isOpen={!!isAdd || !!isEdit}
                        el={isAdd || isEdit}
                        onClose={() => {
                            setIsAdd(null);
                            setIsEdit(null);
                        }}
                        onAcceptModal={(data) => {
                            console.log('data', data);
                            dispatch(isAdd
                                ? createInfrastructure(data, handleSaved)
                                : editInfrastructure(isEdit.id, data, handleSaved)
                            );
                        }}
                    />
                )}
                {/* удаление */}
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={!!isDelete}
                    onSuccess={() => {
                        dispatch(deleteInfrastructure(isDelete, handleSaved));
                        setIsDelete(null);
                    }}
                    onClose={() => {
                        setIsDelete(null);
                    }}
                />
            </>
        )
    );

    return {
        // генерация html
        renderComponent,
        // форма добавления
        showAddForm: (data= {}) => setIsAdd(data),
        // форма редактирования
        showEditForm: (data = {}) => setIsEdit(data),
        // форма удаления
        showConfirmDelete: (id) => setIsDelete(id),
        // закрыть
        close: () => {
            setIsAdd(null);
            setIsEdit(null);
            setIsDelete(null);
        }
    };

};

export default useForm;