import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import titles from '../../../../../helpers/constants/titles';
import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { useValidation } from '../../../../../helpers/hooks';
import {
    createModel,
    editModel,
    loadBrandsList,
} from '../../../../../redux/TransportRoad/actions';
import { TextField } from '@mui/material';
import MarkSelect from '../../../../common/Autocomplete/MarkSelect';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import FormInfoWrapper from '../../../../common/FormInfoWrapper';

const ModelModal = ({ data, isNew, open, onClose, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initData = {
        brand: data?.brand || {},
        brand_id: data?.brand_id || '',
        name_ru: data?.name_ru || '',
        name_en: data?.name_en || '',
        slug: data?.slug || '',
    };

    const [formData, setFormData] = useState(initData);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            brand_id: formData.brand?.id,
            brand: null,
        }, false);


        const callback = () => {
            reloadList();
            onClose();
        };

        dispatch(isNew
            ? createModel(prepareData, callback)
            : editModel(data.id, prepareData, callback)
        );
    };

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <MarkSelect
                        value={formData.brand?.name_ru || ''}
                        moduleName={'transportRoad'}
                        action={loadBrandsList}
                        selected={formData.brand}
                        required={true}
                        onChange={value => setFormData({ ...formData, brand: value })}
                        error={validation.isKey('brand_id')}
                        helperText={validation.get('brand_id')}
                    />
                </div>

                <FormInfoWrapper error={validation.isKey('name_ru')} helperText={validation.get('name_ru')}>
                    <TextField
                        value={formData.name_ru || ''}
                        name={'name_ru'}
                        onChange={handleChange}
                        className="block"
                        label={'Название модели(Ru)'}
                        variant={'outlined'}
                        size={'small'}
                        required
                        error={validation.isKey('name_ru')}
                        helperText={validation.get('name_ru')}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper error={validation.isKey('name_en')} helperText={validation.get('name_en')}>
                    <TextField
                        value={formData.name_en || ''}
                        name={'name_en'}
                        onChange={handleChange}
                        className="block"
                        label={'Название модели(En)'}
                        variant={'outlined'}
                        size={'small'}
                        required
                        error={validation.isKey('name_en')}
                        helperText={validation.get('name_en')}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper error={validation.isKey('slug')} helperText={validation.get('slug')}>
                    <TextField
                        value={formData.slug || ''}
                        name={'slug'}
                        onChange={handleChange}
                        className="block"
                        label={'Алиас'}
                        variant={'outlined'}
                        size={'small'}
                        required
                        error={validation.isKey('slug')}
                        helperText={validation.get('slug')}
                    />
                </FormInfoWrapper>
            </form>
        </Modal>
    );
};

export default ModelModal;
