import React, { useEffect, useState, useMemo } from 'react';
import { FormControl, TextField, Autocomplete, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '../../../../../../helpers/hooks';
import { clearGeocodeAddress, loadGeocodeAddress } from '../../../../../../redux/Dadata/actions';
import { dadataSelectors } from '../../../../../../redux/Dadata';
import titles from '../../../../../../helpers/constants/titles';

// выбрать улицу из списка
const AddressList = ({
    // функция изменения
    onAdd = () => {},
}) => {
    const dispatch = useDispatch();
    const geocodeAddress = useSelector(dadataSelectors.geocodeAddress);
    const loading = useSelector(dadataSelectors.loadingGeocodeAddress);
    const [query, setQuery] = useState('');

    const fetchList = (query) => dispatch(loadGeocodeAddress(20, query, { source: 'dadata' }));
    const fetchListDebounce = useDebounce(fetchList, 1000);
    const clearList = () => dispatch(clearGeocodeAddress());

    const listConvert = useMemo(() => {
        return geocodeAddress?.data?.reduce((res, item) => {
            const {
                street,
            } = item;

            if (street && !res.includes(street)) {
                return [
                    ...res,
                    street,
                ];
            }
            return res;
        }, []);
    }, [geocodeAddress]);

    const handleChange = (e) => {
        const { value } = e.target;
        setQuery(value);

        if (value.length > 3) {
            fetchListDebounce(value);
        } else {
            fetchListDebounce.clear();
        }
    };

    const handleSelect = (_, street) => {
        if (street) {
            setQuery('');
            onAdd?.(street);
        }
    };

    useEffect(() => {
        return () => {
            clearList();
        };
    }, []);

    return (
        <FormControl fullWidth required className="block" variant="outlined">
            {loading && <LinearProgress />}
            <Autocomplete
                value={query}
                fullWidth
                disableClearable={query?.length === 0}
                noOptionsText={titles.NO_RESULTS}
                options={listConvert}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Улица"
                        size="small"
                        variant="outlined"
                        // value={query}
                        onChange={handleChange}
                        helperText={'Добавьте улицы для телеграм-бота'}
                    />
                )}
                onChange={handleSelect}
            />
        </FormControl>
    );
};

export default AddressList;