import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadWorkCalendar } from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import { Box, Grid, Typography } from '@mui/material';
import Loading from '../../../../common/Loading';
import RenderMonth from './RenderMonth';
import messages from '../../../../../helpers/constants/messages';
import SquareIcon from '@mui/icons-material/Square';
import Filter from './Filter';
import { getYear } from '../../../../../helpers/date.config';
import PageLayout from '../../../../layout/PageLayout';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import HistoryModal from './HistoryModal';

const monthsNames = {
    1: 'Январь',
    2: 'Февраль',
    3: 'Март',
    4: 'Апрель',
    5: 'Май',
    6: 'Июнь',
    7: 'Июль',
    8: 'Август',
    9: 'Сентябрь',
    10: 'Октябрь',
    11: 'Ноябрь',
    12: 'Декабрь',
};

const WorkCalendar = () => {
    const dispatch = useDispatch();

    const data = useSelector(transportPassengerSelectors.workCalendar);
    const loading = useSelector(transportPassengerSelectors.workCalendarLoading);
    const dataArray = useMemo(() => Object.entries(data?.months || {}), [data?.months]);

    const initParams = { year: getYear(new Date()) };
    const [params, setParams] = useState(initParams);
    const [openHistoryModal, setOpenHistoryModal] = useState(false);

    const loadCalendar = useCallback(() => {
        dispatch(loadWorkCalendar(params));
    },[dispatch, params]);

    useEffect(() => {
        loadCalendar();
    }, [loadCalendar]);

    return (
        <PageLayout
            header="Производственный календарь"
            filters={<Filter initParams={initParams} setParams={setParams} params={params}/>}
            informPanelProps={{ ...( dataArray?.length > 0
                && {
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[{
                                    ...buttonsTypes.history,
                                    onClick: () => setOpenHistoryModal(true),
                                }]}
                                positionLeft
                                noPadding
                            />
                        </>)
                })        
            }}
            content={() => (
                <>
                    {loading && <Loading circular={true}/>}
                    {dataArray?.length > 0
                        ? (
                            <>
                                <Box display="flex" alignItems="center" my={2}>
                                    <SquareIcon sx={{ color: 'red' }}/>
                                    <Typography variant="subtitle1">
                                        Выходной или праздничный день.
                                    </Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    {dataArray?.map(([monthNun, monthData]) =>
                                        <RenderMonth
                                            key={monthNun}
                                            year={data.year} 
                                            monthNun={monthNun}
                                            monthData={monthData}
                                            monthName={monthsNames[monthNun]}
                                            loadCalendar={loadCalendar}
                                        />
                                    )}
                                </Grid>
                                {openHistoryModal && <HistoryModal
                                    isOpen={openHistoryModal}
                                    onClose={() => setOpenHistoryModal(false)}
                                    params={params}
                                    monthsNames={monthsNames}
                                />}
                            </>
                        )
                        : !loading && messages.DATA_IS_NOT_FOUND
                    }
                </>
            )}
            customStyles={{ backgroundColor: 'inherit' }}
        />
    );
};

export default WorkCalendar;
