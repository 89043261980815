import { all, put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import api from './api.methods';
import * as types from './types';
import * as actions from './actions';
import { showMessage } from '../Message/actions';
import messageTypes from '../Message/messagetypes';
import { loadedCurrentUser } from './actions';
import messages from '../../helpers/constants/messages';
import { messageHelper } from '../../helpers/messageHelper';

function* getStatuses() {
    const statuses = {
        dtp: yield call(api.getDtpStatus),
        meteo: yield call(api.getMeteoStatus),
        camera: yield call(api.getCameraStatus),
        route: yield call(api.getRouteStatus),
        vehicles: yield call(api.getVehiclesStatus),
    };
    Object.keys(statuses).forEach((key) => {
        statuses[key] = statuses[key].data?.status || 3;
    });
    yield put(actions.saveStatuses(statuses));
}

function* getUsersSaga({ payload }) {
    yield put(actions.loading(true));
    const response = yield call(api.getUsers, payload);
    if (response?.success) {
        yield put(actions.saveUsers(response));
    }
    yield put(actions.loading(false));
}

function* getRolesSaga({ payload }) {
    const { page, limit, name } = payload;
    yield put(actions.loading(true));
    const response = yield call(api.getRoles, page, limit, name);
    if (response) {
        yield put(actions.saveRoles(response));
    }
    yield put(actions.loading(false));
}

function* registerUserSaga({ payload }) {
    yield put(actions.loading(true));
    const response = yield call(api.registerUser, payload);
    if (response?.success) {
        yield put(actions.getUsers());
    }
    yield put(actions.loading(false));
}

function* createUserSaga({ payload, callback }) {
    yield put(actions.loading(true));
    const response = yield call(api.createUser, payload);
    if (response?.success) {
        // yield put(actions.getUsers());
        yield callback();
    }
    yield put(actions.loading(false));
}

function* editUserSaga({ payload, callback }) {
    yield put(actions.loading(true));
    const response = yield call(api.editUser, payload);
    if (response?.success) {
        // yield put(actions.getUsers());
        yield callback();
    }
    yield put(actions.loading(false));
}

function* deleteUserSaga({ payload, callback }) {
    yield put(actions.loading(true));
    const response = yield call(api.deleteUser, payload);
    if (response?.success) {
        // yield put(actions.getUsers());
        yield callback();
    }
    yield put(actions.loading(false));
}

function* getPermissionsSaga({ payload }) {
    yield put(actions.loading(true));
    const response = yield call(api.getPermissions, payload);
    if (response?.success) {
        yield put(actions.savePermissions(response.data));
    }
    yield put(actions.loading(false));
}

function* createRoleSaga({ payload, callback }) {
    yield put(actions.loading(true));
    const response = yield call(api.createRole, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
        yield callback();
    }
    yield put(actions.loading(false));
}

function* editRoleSaga({ payload, callback }) {
    yield put(actions.loading(true));
    const response = yield call(api.editRole, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, 'Данные были успешно изменены');
        yield callback();
    }
    yield put(actions.loading(false));
}

function* deleteRoleSaga({ payload, callback }) {
    yield put(actions.loading(true));
    const response = yield call(api.deleteRole, payload);
    if (response?.success) {
        yield callback();
    }
    yield put(actions.loading(false));
}

function* loadDtpSettingsSaga({ payload }) {
    const { page, limit } = payload;
    yield put(actions.loadingAdminDtpSettings(true));
    const response = yield call(api.getDtpSettings, page, limit);
    if (response?.success) {
        yield put(actions.loadedAdminDtpSettings(response));
    }
    yield put(actions.loadingAdminDtpSettings(false));
}

function* editDtpSettingsSaga({ payload }) {
    const response = yield call(api.editDtpSettings, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, 'Данные были успешно изменены');
        yield put(actions.loadAdminDtpSettings());
    }

}

function* loadCurrentUserSaga({ payload: id }) {
    const currentUser = yield call(api.getCurrentUser, id);
    yield put(actions.loadingCurrentUser(true));
    if (currentUser) {
        yield put(loadedCurrentUser(currentUser.data));
    }
    yield put(actions.loadingCurrentUser(false));
}

function* resetPasswordSaga({ payload }) {
    yield put(actions.resettingPassword(true));
    const response = yield call(api.resetPassword, payload);
    if (response) {
        yield put(actions.resetedPassword(response.data));
    }
    yield put(actions.resettingPassword(false));
}

function* loadSettingsSaga({ payload }) {
    const { page, limit, data } = payload;

    yield put(actions.loadingSettings(true));

    const response = yield call(api.getSettings, page, limit, data);
    if (response?.success) {
        yield put(actions.loadedSettings(response));
    }

    yield put(actions.loadingSettings(false));
}

function* editSettingSaga({ payload, callback }) {
    yield put(actions.loadingSettings(true));
    const response = yield call(api.editSetting, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, 'Данные были успешно изменены');
        yield callback();
    }
    yield put(actions.loadingSettings(false));
}

function* editSettingListSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.editSettingsList, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, 'Данные были успешно изменены');
        yield callback();
    }
    yield put(actions.loadingButton(false));
}

function* loadUserActivitySaga({ payload }) {
    const { page, limit, data } = payload;
    const report = data?.report;
    const config = report
        ? { params: { ...data } }
        : { params: { page, limit, ...data } };
    
    yield put(actions.loadingUserActivity(true));
    const response = yield call((report ? api.getUserActivityReport : api.getUserActivity), config);
    if (response) {
        if (report) {
            yield messageHelper(response);
        } else if (response?.success) {
            yield put(actions.loadedUserActivity(response));
        }
    }
    yield put(actions.loadingUserActivity(false));
}

function* loadMethodsSaga() {
    const response = yield call(api.getMethods);
    if (response?.success) {
        yield put(actions.loadedMethods(response));
    }
}

function* loadMicroservicesSaga() {
    const response = yield call(api.getMicroservices);
    if (response?.success) {
        yield put(actions.loadedMicroservices(response));
    }
}

function* loadActionsSaga() {
    const response = yield call(api.getActions);
    if (response?.success) {
        yield put(actions.loadedActions(response.data));
    }
}

// settings group
function* loadSettingsGroupSaga() {
    yield put(actions.loadingSettingsGroup(true));
    const response = yield call(api.getSettingsGroup);
    if (response?.success) {
        yield put(actions.loadedSettingsGroup(response));
    }
    yield put(actions.loadingSettingsGroup(false));
}

function* editSettingsGroupSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.editSettingsGroup, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.GET_STATUSES, getStatuses),
        takeLatest(types.GET_USERS, getUsersSaga),
        takeLatest(types.GET_ROLES, getRolesSaga),
        takeLatest(types.REGISTER_NEW_USER, registerUserSaga),
        takeLatest(types.CREATE_NEW_USER, createUserSaga),
        takeLatest(types.EDIT_USER, editUserSaga),
        takeLatest(types.DELETE_USER, deleteUserSaga),
        takeLatest(types.GET_PERMISSIONS, getPermissionsSaga),
        takeLatest(types.CREATE_ROLE, createRoleSaga),
        takeLatest(types.EDIT_ROLE, editRoleSaga),
        takeLatest(types.DELETE_ROLE, deleteRoleSaga),
        takeLatest(types.LOAD_DTP_SETTINGS, loadDtpSettingsSaga),
        takeLatest(types.EDIT_DTP_SETTINGS, editDtpSettingsSaga),
        takeEvery(types.LOAD_CURRENT_USER, loadCurrentUserSaga),
        takeEvery(types.RESET_PASSWORD, resetPasswordSaga),
        takeLatest(types.LOAD_SETTINGS, loadSettingsSaga),
        takeLatest(types.EDIT_SETTING, editSettingSaga),
        takeLatest(types.EDIT_SETTINGS_LIST, editSettingListSaga),
        takeLatest(types.LOAD_USER_ACTIVITY, loadUserActivitySaga),
        takeLatest(types.LOAD_METHODS, loadMethodsSaga),
        takeLatest(types.LOAD_MICROSERVICES, loadMicroservicesSaga),
        takeLatest(types.LOAD_ACTIONS, loadActionsSaga),

        takeLatest(types.LOAD_SETTINGS_GROUP, loadSettingsGroupSaga),
        takeLatest(types.EDIT_SETTINGS_GROUP, editSettingsGroupSaga),
    ]);
}
