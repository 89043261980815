import * as types from './types';

const initialState = {
    show: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.SHOW_VERSION:
            return {
                ...state,
                show: payload
            };

        default:
            return state;
    }
}
