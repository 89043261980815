import routes from './routes';
import common from './common';
import transport from './transport';
import company from './company';
import stations from './stations';
import users from './users';
import roadworks from './roadworks';
import dtp from './dtp';
import map from './map';
import camera from './camera';
import login from './login';
import doris from './doris';
import videoanalytics from './videoanalytics';
import address from './address';
import wanted from './wanted';
import situationalplans from './situationalplans';
import trafficlightsTitles from './trafficlights';
import specialsTitles from './specials';
import documents from './documents';
import dates from './dates';
import workorder from './workorder';
import boards from './boards';
import reports from './reports';
import governmentcontract from './governmentcontract';
import trafficSchedule from './trafficSchedule';


const titles = {
    ...dates,
    ...documents,
    ...routes,
    ...common,
    ...transport,
    ...company,
    ...stations,
    ...users,
    ...roadworks,
    ...dtp,
    ...map,
    ...camera,
    ...login,
    ...doris,
    ...videoanalytics,
    ...address,
    ...wanted,
    ...situationalplans,
    ...trafficlightsTitles,
    ...specialsTitles,
    ...workorder,
    ...boards,
    ...reports,
    ...governmentcontract,
    ...trafficSchedule
};

export default titles;
