import jwtDecode from 'jwt-decode';

// удалить лишнее
export const reduceUser = (userObj) => Object.keys(userObj).reduce((result, key) => {
    const value = userObj[key];
    if (value !== null) {
        if (value instanceof Object && value.constructor.toString().indexOf('Array') === -1) {
            result[key] = reduceUser(value);
        } else {
            result[key] = value;
        }
    }
    return result;
}, {});

// конвертировать данные токена
export const parseToken = (user) => {
    const { access_token } = user;
    const decoded = jwtDecode(access_token);
    return reduceUser({ ...user, info: decoded });
};
