import { useEffect } from 'react';

import { config } from 'config';
import useDefectHeatMap from 'helpers/hooks/RoadWorks/useDefectHeatMap';
import messages from 'helpers/constants/messages/common';
import { Map, MapControl } from 'components/MapComponents/leaflet';
import HeatLayer from 'components/MapComponents/leaflet/components/HeatLayer';
import PageLayout from 'components/layout/PageLayout';

import Filter from './Filter';

const RoadworkDangerousArea = () => {
    const dataProvider = useDefectHeatMap();

    useEffect(() => {
        return () => {
            dataProvider.clear();
        };
    }, []);

    const renderContent = () => {
        return (dataProvider.isData
            ? <div style={{ display: 'contents', minHeight: '400px' }}>
                <Map
                    center={config.get('mapCenter')}
                >
                    <MapControl>
                        <HeatLayer
                            json={dataProvider.data}
                        />
                    </MapControl>
                </Map>
            </div>
            : <>{!dataProvider.loading && <div>{messages.NO_DATA_FOR_PERIOD}</div>}</>);
    };

    return (
        <PageLayout
            header={'Тепловая карта по недостаткам дорожной инфраструктуры'}
            filters={<Filter onFilter={(filter) => dataProvider.load(filter)} />}
            loading={dataProvider.loading}
            content={renderContent}
            customStyles={{ overflowY: 'hidden', padding: 0 }}
        />
    );
};

export default RoadworkDangerousArea;
