import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from './Filter';
import * as actions from  '../../../../../redux/RoadWorks/actions';
import { roadworksSelectors } from  '../../../../../redux/RoadWorks';
import Item from './Item';
import messages from '../../../../../helpers/constants/messages';
import { dateWithDashYYYYMMDD, getStartOf, getEndOf } from '../../../../../helpers/date.config';
import ReturnToButton from '../../../../common/Buttons/ReturnToButton';
import { LSContainer } from '../../../../common/List';
import PageLayout from '../../../../layout/PageLayout';
import ModalForm from './ModalForm';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
// import Context from '../../../../../helpers/context';

const SheetsAssessmentRoad = () => {
    const dispatch = useDispatch();
    // const { permissions } = useContext(Context);

    const [page, setPage] = useState(1);
    const [createModal, setCreateModal] = useState(false);

    const data = useSelector(roadworksSelectors.sheetsAssessmentRoadData);
    const meta = useSelector(roadworksSelectors.sheetsAssessmentRoadMeta);
    const loading = useSelector(roadworksSelectors.loadingSheetsAssessmentRoad);

    const [filter, setFilter] = useState({});
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);

    const fetchList = useCallback((params = filter) => {
        const paramsWithoutEmpty = Object
            .entries(params)
            .reduce((res, [key, value]) => {
                if (value) {
                    if (key === 'start_date' || key === 'end_date') {
                        res[key] = dateWithDashYYYYMMDD(value);
                    } else if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value))) {
                        res[key] = value;
                    }
                }
                return res;
            }, {});

        if (Object.keys(paramsWithoutEmpty).length > 0) {
            dispatch(actions.loadSheetsAssessmentRoad({ page, limit, ...paramsWithoutEmpty }));
        }
    }, [filter, page, limit, dispatch]);

    useEffect(() => {
        fetchList();
    }, [fetchList]);

    // обновление списка
    const handleReload = () => {
        fetchList();
    };

    // удаление
    const handleDelete = (id) => {
        dispatch(actions.deleteSheetsAssessmentRoad(id, () => {
            // был 1 элемент на странице и страница не 1
            if (data.length === 1 && page > 1) {
                setPage(page - 1);
            } else {
                // просто обновляем текущую страницу
                handleReload();
            }
        }));
    };

    const handleFilter = (newFilter) => {
        setFilter(newFilter);
        setPage(1);
    };

    const renderContent = () => {
        return data?.length > 0
            ? (
                <LSContainer
                    headers={[
                        {
                            title: 'Название автомобильной дороги',
                            width: '15%'
                        },
                        {
                            title: 'Категория автомобильной дороги',
                            width: '14%'
                        },
                        {
                            title: 'Адрес участка, км',
                            width: '12%'
                        },
                        {
                            title: 'Характерный период',
                            width: '10%'
                        },
                        {
                            title: 'Месяц.Год',
                            width: '10%'
                        },
                        {
                            title: 'Требуемый уровень содержания',
                            width: '15%'
                        },
                        {
                            title: 'Дата регистрации',
                            width: '10%'
                        },
                        {
                            title: 'Файл',
                            width: '10%'
                        },
                        {
                            title: 'Действия',
                            align: 'right',
                            isActions: true
                        }
                    ]}
                >
                    {data?.map((item) =>
                        <Item
                            item={item}
                            key={item.id}
                            onDelete={handleDelete}
                            reloadList={handleReload}
                        />
                    )}
                </LSContainer>
            )
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>);
    };

    return (
        <>
            <PageLayout
                loading={loading}
                header="Итоговые ведомости оценки уровня содержания автомобильной дороги"
                additionalButtons={<ReturnToButton />}
                filters={<Filter setFilter={handleFilter} />}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setCreateModal(true),
                                        // disabled: !permissions?.is_create,
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>
                    )
                }}
                paginationProps={{
                    loadList: (page) => setPage(page),
                    list: meta,
                    limit: limit,
                    setLimit: setLimit,
                }}
                content={renderContent}
            />

            {createModal && (
                <ModalForm
                    isOpen={createModal}
                    onClose={() => setCreateModal(false)}
                    onSuccess={handleReload}
                />
            )}
        </>
    );
};

export default SheetsAssessmentRoad;
