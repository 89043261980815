import moduleName from './module';
const prefix = `${moduleName}`;

export const UPLOAD_FILE_TO_STORAGE = `${prefix}/UPLOAD_FILE_TO_STORAGE`;
export const UPLOADING = `${prefix}/UPLOADING`;
export const CREATE_COMPLEX_BY_UPLOADING_FILE = `${prefix}/CREATE_COMPLEX_BY_UPLOADING_FILE`;
export const UPLOAD_VEHICLE_FILE = `${prefix}/UPLOAD_VEHICLE_FILE`;
export const UPLOAD_FILE_ERROR = `${prefix}/UPLOAD_VEHICLE_FILE_ERROR`;
export const SET_UPLOAD_VEHICLE_FILE = `${prefix}/SET_UPLOAD_VEHICLE_FILE`;
export const UPLOAD_ROADWORK_FILE = `${prefix}/UPLOAD_ROADWORK_FILE`;
export const SET_UPLOAD_ROADWORK_FILE = `${prefix}/SET_UPLOAD_ROADWORK_FILE`;
export const UPLOAD_TRANSPORT_INCIDENT_FILE = `${prefix}/UPLOAD_TRANSPORT_INCIDENT_FILE`;
export const SET_UPLOAD_TRANSPORT_INCIDENT_FILE = `${prefix}/SET_UPLOAD_TRANSPORT_INCIDENT_FILE`;
export const UPLOAD_CSODD_FILE = `${prefix}/UPLOAD_CSODD_FILE`;
export const LOAD_CSODD_FILE = `${prefix}/LOAD_CSODD_FILE`;
export const LOADED_CSODD_FILE = `${prefix}/LOADED_CSODD_FILE`;
export const DELETE_CSODD_FILE = `${prefix}/DELETE_CSODD_FILE`;
export const LOAD_ROAD_LAB_TYPES = `${prefix}/LOAD_ROAD_LAB_TYPES`;
export const LOADED_ROAD_LAB_TYPES = `${prefix}/LOADED_ROAD_LAB_TYPES`;
export const UPLOAD_ROAD_LAB = `${prefix}/UPLOAD_ROAD_LAB`;