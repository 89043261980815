import reducer from './reducers';

export { default as moduleName } from './module';

// сага
export { default as saga } from './sagas';

// мидлвар
export { default as middleware } from './middleware';

export * as authSelectors from './selectors';

export default reducer;