import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
    loadMaterialExportGroup, 
    loadMaterialExportGroupProtocols, 
    loadMaterialExportGroupTypes 
} from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import PageLayout from 'components/layout/PageLayout';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer, LSHeaderColumn, LSHeaderItem } from 'components/common/ListStyle';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';

import Item from './Item';
import ModalForm from './ModalForm';
import Filter from './Filter';

const ExportMaterials = () => {
    const dispatch = useDispatch();

    const data = useSelector(dorisControlSelectors.materialExportGroupData);
    const meta = useSelector(dorisControlSelectors.materialExportGroupMeta);
    const loading = useSelector(dorisControlSelectors.loadingMaterialExportGroup);
    const types = useStoreProp(loadMaterialExportGroupTypes, 'dorisControl', 'materialExportGroupTypes');
    const protocols = useStoreProp(loadMaterialExportGroupProtocols, 'dorisControl', 'materialExportGroupProtocols');

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, query: {} });
    const [openAddModal, setOpenAddModal] = useState(false);

    const loadList = useCallback((shouldGoToPreviousPage = false) => {
        if (shouldGoToPreviousPage) {
            setParams((prev) => ({ ...prev, page: prev.page - 1 }));
        } else {
            dispatch(loadMaterialExportGroup(params.page, limit, params.query));
        }
    }, [dispatch, params, limit]);

    useEffect(() => {
        loadList();
    }, [loadList]);
    
    const renderContent = () => data?.length > 0
        ? (
            <>
                <LSContainer>
                    <LSHeaderItem fullWidth>
                        <LSHeaderColumn width="15%">{titles.NAME}</LSHeaderColumn>
                        {/* <LSHeaderColumn width="20%">{'Назначение'}</LSHeaderColumn> */}
                        <LSHeaderColumn width="20%">{'Тип экспорта'}</LSHeaderColumn>
                        <LSHeaderColumn width="15%">{'Протокол'}</LSHeaderColumn>
                        <LSHeaderColumn width="30%">{'Комплексы'}</LSHeaderColumn>
                        <LSHeaderColumn width="20%">{'Выгрузка'}</LSHeaderColumn>
                        <LSHeaderColumn align="right" isActions>{'Действия'}</LSHeaderColumn>
                    </LSHeaderItem>
                    {data.map((item) => (
                        <Item
                            key={item.id}
                            item={item}
                            loadList={(isDelete) => loadList(isDelete && data.length === 1)}
                            types={types}
                            protocols={protocols}
                        />
                    ))}
                </LSContainer>
            </>
        )
        : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>);

    return (
        <>
            <PageLayout
                header="Экспорт материалов"
                filters={<Filter setParams={setParams}/>}
                informPanelProps={{                
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[{
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenAddModal(true),
                                }]
                                }
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: meta?.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams((prev) => ({ ...prev, page })),
                    list: meta,
                    limit,
                    setLimit
                }}
            />
            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    loadList={loadList}
                    isNew
                />
            )}
        </>
        
    );
};

export default ExportMaterials;
