import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { IconButton, } from '@mui/material';

import { deleteWaybills, getWaybills } from 'redux/TransportPassenger/actions';
import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';
import ConfirmDelete from 'components/common/ConfirmModal';
import {
    LSContentColumn,
    LSContentItem,
} from 'components/common/List';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';
import WaybillsInfo from './WaybillsInfo';

const WaybillsItem = ({ item = {}, licence }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openCollapse, setOpenCollapse] = useState(false);

    const handleOpenDeleteModal = (e) => {
        e.stopPropagation();
        setOpenDeleteModal(true);
    };

    const handleConfirmDelete = () => {
        dispatch(deleteWaybills(item.id));
        setOpenDeleteModal(false);
    };

    const handleOpenCollapse = (e) => {
        e.stopPropagation();
        setOpenCollapse(!openCollapse);
    };

    const handleOpenEditModal = (e) => {
        e.stopPropagation();
        setOpenEditModal(true);
    };

    const handleDownloadWaybill = (e) => {
        e.stopPropagation();
        dispatch(getWaybills(item.id));
    };

    return (
        <>
            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    isNew={false}
                    el={item}
                    licence={licence}
                />
            )}
            {openDeleteModal &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteModal}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
            <LSContentItem className="list_item" onClick={handleOpenCollapse}>
                <LSContentColumn>{item.number || messages.NO_DATA}</LSContentColumn>
                <LSContentColumn>{item.name || messages.NO_DATA}</LSContentColumn>
                <LSContentColumn>{item?.work_order?.traffic_schedule?.route_direct?.num || messages.NO_DATA}</LSContentColumn>
                <LSContentColumn>{fullDateTime(item.date_start) || messages.NO_DATA}</LSContentColumn>
                <LSContentColumn>{fullDateTime(item.date_end) || messages.NO_DATA}</LSContentColumn>
                <LSContentColumn title="Действия" align="right" isActions>
                    <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.reportIcon,
                                name: 'Скачать',
                                onClick: handleDownloadWaybill,
                            },
                            ...(Date.parse(item.date_start) > new Date()
                                ? [{
                                    ...buttonsTypes.editIcon,
                                    onClick: handleOpenEditModal,
                                }]
                                : []
                            ),
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: handleOpenDeleteModal,
                            },
                        ]}
                        noMarginLeft
                        justButton
                    />
                    <IconButton size={'small'}>
                        {openCollapse
                            ? <ExpandLess fontSize={'inherit'} />
                            : <ExpandMore fontSize={'inherit'} />
                        }
                    </IconButton>
                </LSContentColumn>
            </LSContentItem>
            {openCollapse && <WaybillsInfo isOpen={openCollapse} item={item}/>}
        </>
    );
};

export default WaybillsItem;
