const config = {
    name: 'Специальный транспорт',
    slug: 'transport-special',
    mapContextMenu: null,
    mapMarkersColors: {
        default: '#D7DBDD',
        active: '#4b9c4b'
    },
    mapMarkersTypes: {
        dot: 2,
        line: 1,
        polygon: 3
    },
    layerIcon: 'far fa-ambulance',
};

export default config;
