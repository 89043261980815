import React, { useState } from 'react';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import LayoutFilter from '../../../layout/PageLayout/LayoutFilter';
import { TextField } from '@mui/material';
import titles from '../../../../helpers/constants/titles';

const Filter = ({ onFilterChanged }) => {
    const initialState = { name: '' };

    const [filterData, setFilterData] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFilterData(prev => ({ ...prev, [name]: value }));
    };

    const search = () => {
        onFilterChanged(removeEmptyFields(filterData));
    };

    const resetFilter = (needRefresh) => {
        needRefresh && onFilterChanged(removeEmptyFields(initialState));
        setFilterData(initialState);
    };

    const updateFilter = (filters) => {
        const newFilter = { ...initialState, ...filters };
        setFilterData(newFilter);

        if (Object.keys(filters).length > 0) {
            onFilterChanged(removeEmptyFields(newFilter));
        }
    };


    return (
        <LayoutFilter
            filter={filterData}
            onSearch={search}
            onResetFilter={resetFilter}
            setUserFilter={updateFilter}
            disabled={!filterData.name}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={filterData.name}
                    onChange={handleChange}
                    label={titles.NAME}
                    variant="outlined"
                    name="name"
                    type="text"
                    size="small"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;