import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import formatDate from '../../../../../helpers/constants/dateFormat';
import { dateWithDashYYYYMMDD } from '../../../../../helpers/date.config';
import {
    loadPddReportExpanded,
    loadComplexTypeGrouping,
    loadRecentPddReportExpanded,
} from '../../../../../redux/DorisControl/actions';
import DateRange from '../../../../common/Dates/DateRange';
import differenceInDays from 'date-fns/differenceInDays';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import useValidation from '../../../../../helpers/hooks/useValidation';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import SelectComplex from '../../../../common/Autocomplete/DorisControl/Complex';
import GetReports from '../../../../common/GetReports';
import { useStoreProp } from '../../../../../helpers/hooks';
import GovernmentContractList from '../../../../common/Autocomplete/GovernmentContract';
import messages from '../../../../../helpers/constants/messages';

const Filter = () => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        time_start: null,
        time_end: null,
        organization_ids: [],
        complex_ids: [],
        formats:[],
        group_by_type : null,
        state_contract_ids: [],
    };

    // Технический отчета по статьям КОАП по комплексам - pdd_monthly (group by camera = false)
    // Технический отчета по статьям КОАП по камерам - pdd_monthly (group_by_camera = true)
    // Отчёт о последних нарушениях recent_pdd
    const reportTypeList = [
        {
            id: 'byEventsKfvf',
            name: 'Технический отчет по статьям КОАП по комплексам',
            // name: 'Отчет по событиям по КФВФ',
            action: loadPddReportExpanded,
            parameters: { group_by_camera: 0 }
        },
        {
            id: 'byEvents',
            name: 'Технический отчет по статьям КОАП по камерам',
            // name: 'Отчет по событиям по камерам КФВФ',
            // name: 'Отчет по количеству событий',
            action: loadPddReportExpanded,
            parameters: { group_by_camera: 1 }
        },
        {
            id: 'byDates',
            name: 'Отчёт о последних нарушениях',
            // name: 'Отчет по датам событий',
            action: loadRecentPddReportExpanded,
            parameters: {}
        }
    ];

    const [filters, setFilters] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [complexes, setComplexes] = useState([]);

    const [reportType, setReportType] = useState('byEventsKfvf');

    const complex_type_grouping = useStoreProp(loadComplexTypeGrouping, 'dorisControl', 'complex_type_grouping');

    const handleChangeDate = (name, value) => {
        setFilters({ ...filters, [name]: value });
        validation.deleteKey(name);
    };

    const download = () => {
        const newData = Object
            .keys(filters)
            .reduce((res, key) => {
                if (key === 'time_start' || key === 'time_end') {
                    res[key] = dateWithDashYYYYMMDD(filters[key]);
                } else if (key === 'checked' || key === 'isTechMat') {
                    res[key] = filters[key] ? 1 : 0;
                } else {
                    res[key] = filters[key];
                }
                return res;
            }, {});
        newData.organization_ids = companies.map(({ id }) => id);
        newData.complex_ids = complexes.map(({ id }) => id);
        newData.formats = filters.formats?.map(({ id }) => id);
        newData.state_contract_ids = filters.state_contract_ids?.map(({ id }) => id);

        const report = reportTypeList
            ?.find(({ id }) => id === reportType);

        const action = report
            ?.action || null;

        const parameters = report
            ?.parameters || {};

        action && dispatch(action({
            ...newData,
            ...parameters
        }));
    };

    const checkInvalidPeriod = () => {
        return differenceInDays(filters.time_end, filters.time_start) > 31;
    };

    const disabledButton = checkInvalidPeriod()
        || isDisabled
        || !filters.time_start
        || !filters.time_end
        || !filters.formats.length;

    const resetFilter = () => {
        setFilters(initialState);
        setCompanies([]);
        setComplexes([]);
        validation.clear();
    };

    return (
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">
                <DateRange
                    valueStartDate={filters.time_start}
                    valueEndDate={filters.time_end}
                    handleDate={(date) => handleChangeDate('time_start', date)}
                    handleEndDate={(date) => handleChangeDate('time_end', date)}
                    isDisabledHandler={setIsDisabled}
                    pickersFormat={formatDate.DATE_FOR_PICKER}
                    renderError={() => checkInvalidPeriod() || (validation.isKey('time_start') || validation.isKey('time_end'))}
                    errorMessage={() => validation.get('time_start') || validation.get('time_end')}
                    dateOnly={true}
                />
                <FormControl size="small" variant="outlined" fullWidth>
                    <InputLabel>
                        Тип группировки Комплекса
                    </InputLabel>
                    <Select
                        value={filters.group_by_type || ''}
                        required={true}
                        label="Тип группировки Комплекса"
                        onChange={(e) => handleChangeDate(e.target.name, e.target.value)}
                        name="group_by_type"
                    >
                        {Object.keys(complex_type_grouping)?.length
                            ? Object.keys(complex_type_grouping)?.map((key) =>
                                <MenuItem value={key} key={key}>{complex_type_grouping[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </div>
            <div className="filter__wrap filter__wrap__more__field">
                <SelectCompany
                    multiple
                    selected={companies}
                    onChange={setCompanies}
                    label="Балансодержатель"
                    filter={{ withDeleted: 1 }}
                />
                <SelectComplex
                    selected={complexes}
                    onChange={setComplexes}
                    multiple={true}
                />
                <GovernmentContractList
                    label="Госконтракт"
                    multiple
                    selected={filters.state_contract_ids}
                    onChange={(value) => handleChangeDate('state_contract_ids', value)}
                />
            </div>
            <div className="filter__wrap filter__wrap__more__field">
                <FormControl size="small" variant="outlined" fullWidth>
                    <InputLabel>
                        Выбрать отчет
                    </InputLabel>
                    <Select
                        value={reportType || ''}
                        required={true}
                        label="Выбрать отчет"
                        onChange={(e) => setReportType(e.target.value)}
                        name="reportType"
                    >
                        {reportTypeList.map(({ id, name }) => (
                            <MenuItem value={id} key={id}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <GetReports
                    selected={filters.formats}
                    onChange={(formats) => setFilters({ ...filters, formats })}
                    reportId={40}
                />
            </div>
            {checkInvalidPeriod() && <FormHelperText error>Вы выбрали период больше одного месяца</FormHelperText>}
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    noPadding={true}
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilter
                        },
                        {
                            ...buttonsTypes.getReport,
                            onClick: download,
                            disabled: disabledButton
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;