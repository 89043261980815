import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useValidation } from '../../../../helpers/hooks';
import SelectCompany from '../../../common/Autocomplete/Companies';
import SelectUsers from '../../../common/Autocomplete/Users';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import Modal from '../../../common/Modal';

const AppointedModal = ({
    open = false, 
    onClose = () => {}, 
    onSave = () => {}, 
    item = {}, 
    loadList = () => {} 
}) => {
    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const initialState = {
        organization: item.organization || {},
        appointed: item.appointed || {},
    };

    const [formData, setFormData] = useState(initialState);

    const handleSave = () => {
        onSave({ 
            organization_id: formData.organization.id,
            appointed_to: formData.appointed.id 
        });
    };
       
    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
            loadList();
        }
    }, [onClose, loadList, requestStatus, validation]);

    const isDisabled = !formData.organization.id || !formData.appointed.id;

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            noPadding
            title={'Назначить исполнителя'}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled
                    },
                ]}
            />}
        >
            <div className="modal__form">
                <div className={'block'}>
                    <SelectCompany
                        filter={{ withDeleted: 1 }}
                        selected={formData.organization}
                        onChange={(value) => setFormData({ organization: value, appointed: {} })}
                        label="Организация"
                    />
                </div>
                <SelectUsers
                    disabled={!formData.organization.id}
                    selected={formData.appointed}
                    onChange={(value) => setFormData({ ...formData, appointed: value })}
                    label="Исполнитель"
                    filter={{ org_ids: [formData?.organization?.id] }}
                />            
            </div>
        </Modal>
    );
};

export default AppointedModal;
