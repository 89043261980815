import cn from 'classnames';
import { Button, CircularProgress, DialogActions, IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useFilterButtonsByPermissions } from 'helpers/hooks/Permissons';

import styles from './style.module.scss';

const useStyles = makeStyles({
    actions: {
        borderTop: '1px solid #dcdcdcf5',
        background: '#eee',
    },
});

const TooltipWrapper = ({ children, title }) =>
    !!title
        ? <Tooltip title={title}>
            <span>
                {children}
            </span>
        </Tooltip>
        : children
;

const FormButtonsComponent = (props) => {
    const {
        buttons = [],
        beforeTemplate = null,
        afterTemplate = null,
        inModal = false,
        noPadding = false,
        positionLeft = false, // если нужно кнопку прижать влево
        noMarginLeft = false, // если нужно убрать отступ слева
        className = '',
        justButton = false,
        children,
        uri = '',
        showDisabled = true,
        test_id_prefix = '',
    } = props;

    const classes = useStyles();

    const notEmptyButtonList = useFilterButtonsByPermissions(buttons, uri, showDisabled);

    const renderButton = () => (
        <>
            {notEmptyButtonList.length > 0 && notEmptyButtonList.map((button, index) => {
                const { name, icon, tooltipTitle, loading = false, startIcon, endIcon, accessProp, testName, ...buttonProps } = button;

                return (
                    <TooltipWrapper title={icon ? name : tooltipTitle} key={index}>
                        {icon
                            ? <IconButton
                                {...buttonProps}
                                data-testid={`${test_id_prefix}/${testName}/button`}
                                style={{ ...(buttonProps.style || {}), marginLeft: noMarginLeft ? 0 : '1rem' }}
                                accees={accessProp}
                            >
                                {icon}
                            </IconButton>
                            : <Button
                                {...buttonProps}
                                data-testid={`${test_id_prefix}/${testName}/button`}
                                startIcon={startIcon}
                                endIcon={endIcon}
                                disabled={buttonProps?.disabled || loading}
                                className={cn(className, styles.button, { [styles.no_margin_left]: noMarginLeft })}
                                accees={accessProp}
                            >
                                {name}
                                {children}
                                {loading && (
                                    <CircularProgress
                                        disableShrink
                                        size={24}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Button>
                        }
                    </TooltipWrapper>
                );
            })}
        </>
    );

    const renderContent = () => (
        <>
            {!!beforeTemplate && (
                <div className={styles.before}>
                    {beforeTemplate}
                </div>
            )}
            {justButton
                ? renderButton()
                : notEmptyButtonList.length > 0
                    ?<div className={cn(styles['buttons-wrap'], {
                        [styles.nopadding]: noPadding,
                        [styles.positionLeft]: positionLeft,
                    })}>
                        {renderButton()}
                        {!!afterTemplate && (
                            <div className={styles.after}>
                                {afterTemplate}
                            </div>
                        )}
                    </div>
                    : null
            }
        </>
    );

    return (
        <>
            {inModal
                ? <DialogActions  className={classes.actions}>
                    {renderContent()}
                </DialogActions>
                : renderContent()
            }
        </>
    );
};

export default FormButtonsComponent;
