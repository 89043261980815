import { Paper } from '@mui/material';

import { useStoreProp } from 'helpers/hooks';
import colorExtend from 'helpers/mapHelper/colorExtend';
import './style.scss';

const Legends = (props) => {
    const {
        data = {},
    } = props;

    const {
        action = null,
        dataProp = null,
        storeProp = null,
    } = data;

    const legendList = useStoreProp(action, storeProp, dataProp);

    const createSpeedMessage = (speed, prevSpeed, last = false) => {
        return last === false
            ? `${prevSpeed ? `${prevSpeed} км/ч ≤ ` : ''}скорость < ${speed} км/ч`
            : `скорость ≥ ${speed} км/ч`;
    };

    if (!legendList || legendList?.data?.length === 0) return null;

    return (
        <div className="popAbs">
            <Paper
                sx={{
                    marginLeft: '.5rem',
                    marginBottom: '1.5rem',
                    zIndex: 9999,
                    padding: '.8rem'
                }}
            >
                <div className="simple-legends">
                    {legendList?.map((item, index) => {
                        const {
                            color,
                            speed,
                        } = item;

                        const prevSpeed = legendList?.[index - 1]?.speed || null;

                        return (
                            <div key={index} className="item">
                                <i
                                    style={{ background: colorExtend(color) }}
                                    className="image"
                                />
                                <div className="name">
                                    - {createSpeedMessage(speed, prevSpeed)}
                                </div>
                            </div>
                        );
                    })}

                    {/* больше максимального */}
                    <div className="item">
                        <i
                            style={{ background: '#F83411' }}
                            className="image"
                        />
                        <div className="name">
                            - {createSpeedMessage(legendList?.[legendList?.length - 1]?.speed || 0, null, true)}
                        </div>
                    </div>
                </div>
            </Paper>
        </div>

    );
};

export default Legends;
