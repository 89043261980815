import { useState } from 'react';
import { ListItem,  ListItemText,  } from '@mui/material';
import { useDispatch } from 'react-redux';

import { deleteKeyWord } from 'redux/SituationalPlans/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalAddEdit from './ModalAddEdit';
import styles from './keyWords.module.scss';

function Item({ data, reloadList }) {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const onAccept = () => dispatch(deleteKeyWord(data?.id, () => {
        reloadList(true);
        setConfirmOpen(false);
    }));

    return (
        <ListItem className={styles.item}>
            <ConfirmDelete
                onClose={() => setConfirmOpen(false)}
                onSuccess={onAccept}
                open={confirmOpen}
            />
            {modalOpen
                && <ModalAddEdit
                    onClose={() => setModalOpen(false)}
                    isOpen={modalOpen}
                    data={data}
                    reloadList={reloadList}
                />
            }
            <ListItemText>
                <b>{data?.name}</b>
            </ListItemText>
            <FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.editIcon,
                        onClick: () => setModalOpen(true),
                    },
                    {
                        ...buttonsTypes.deleteIcon,
                        onClick: () => setConfirmOpen(true),
                    },
                ]}
                noMarginLeft
                justButton
            />
        </ListItem>
    );
}

export default Item;
