import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({ statuses, states, controlTypes, initialState, setParams }) => {
    const [data, setData] = useState(initialState);

    const onChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const resetFilter = (needRefresh) => {
        setData(initialState);

        if (needRefresh) {
            setParams(initialState);
        }
    };

    const onChangeUuid = (name, newValue) => {
        setData({ ...data, [name]: newValue });
    };

    const updateFilter = (filters) => {
        setData({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            setParams({
                ...initialState,
                ...filters,
            });
        }
    };

    const isDisabled = data.name === '' && data.status === '' && data.state_type_ids === '' && data.control_type_ids === '' && data.uuid.length === 0;

    return(
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={() => setParams(data)}
            filter={data}
            setUserFilter={updateFilter}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <FormControl required  size="small" variant="outlined">
                    <MultipleInput
                        value={data?.uuid}
                        onChange={(e) => onChangeUuid('uuid', e) }
                        label="UUID"
                        guideText="Добавление одного или нескольких элементов, разделяя запятой"
                    />
                </FormControl>
                <FormControl required  size="small" variant="outlined">
                    <TextField
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={data.name || ''}
                        name={'name'}
                        onChange={onChange}
                        type="text"
                    />
                </FormControl>
                <FormControl size="small" variant="outlined" >
                    <InputLabel>{titles.STATUS}</InputLabel>
                    <Select
                        value={data.status || ''}
                        onChange={(e) => setData({ ...data, status: e.target.value })}
                        label={titles.STATUS}
                    >
                        {Object.keys(statuses).map((index) => (
                            <MenuItem key={index} value={index}>{statuses[index]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <FormControl size="small" variant="outlined" >
                    <InputLabel>{titles.STATE}</InputLabel>
                    <Select
                        value={data.state_type_ids}
                        name={'state_type_ids'}
                        onChange={(e) => setData({ ...data, state_type_ids: [e.target.value] })}
                        label={titles.STATE}
                    >
                        {states?.map((state) => (
                            <MenuItem key={state.id} value={state.external_id}>{state.full_name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl size="small" variant="outlined" >
                    <InputLabel>Тип контроля</InputLabel>
                    <Select
                        value={data.control_type_ids}
                        name={'control_type_ids'}
                        onChange={(e) => setData({ ...data, control_type_ids: [e.target.value] })}
                        label="Тип контроля"
                    >
                        {controlTypes?.map((type) => (
                            <MenuItem key={type.id} value={type.external_id}>{type.full_name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
