import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/Efficiency/actions';
import { efficiencySelectors } from '../../../redux/Efficiency';


const useDashboard = () => {
    const dispatch = useDispatch();

    const data = useSelector(efficiencySelectors.dashboard);
    const loading = useSelector(efficiencySelectors.dashboardLoading);

    return {
        load: (props = {}) => {
            dispatch(actions.loadDashboard(props));
        },
        clear: () => {
            dispatch(actions.clearDashboard());
        },
        data,
        loading,
    };
};

export default useDashboard;
