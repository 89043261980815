import { useState } from 'react';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import Modal from '../../../../common/Modal';
import { createCriticalSetting } from '../../../../../redux/Efficiency/actions';
import { efficiencySelectors } from '../../../../../redux/Efficiency';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select, TextField,
} from '@mui/material';

const ModalForm = ({ isOpen, onClose, data, reloadList }) => {
    const dispatch = useDispatch();

    const loadingButton = useSelector(efficiencySelectors.loadingButton);

    const [formData, setFormData] = useState({ setting: null, value: '' });

    const handleSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        dispatch(createCriticalSetting({ key: formData.setting, value: formData.value }, callback));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            title={'Добавление'}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: !formData.value.trim(),
                        loading: loadingButton
                    },
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl
                    className="block"
                    required={true}
                    size="small"
                    variant="outlined"
                >
                    <InputLabel>Выберите настройку</InputLabel>
                    <Select
                        onChange={(e) => setFormData({ setting: e.target.value, value: '' })}
                        label={'Выбрать настройку'}
                    >
                        {Object.keys(data || {}).map((item) => (
                            <MenuItem key={data[item]} value={item}>
                                {data[item]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Значение"
                    size="small"
                    required={true}
                    disabled={formData.setting === null}
                    value={formData.value}
                    variant="outlined"
                    type="text"
                    onChange={e => setFormData({ ...formData, value: e.target.value })}
                />
            </div>
        </Modal>
    );
};


export default ModalForm;