import { useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import { deleteTypeOfMistakesForCheckList } from 'redux/DorisControl/actions';
import ConfirmModal from 'components/common/ConfirmModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import AddEditModal from './AddEditModal';
import type { ItemProps } from './types';

const Item = ({ item, reloadList }: ItemProps) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleDelete = () => {
        dispatch(deleteTypeOfMistakesForCheckList(item.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item?.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openEditModal && (
                <AddEditModal
                    item={item}
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    reloadList={reloadList}
                />
            )}

            {openDeleteModal && (
                <ConfirmModal
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                    onlyClose={false}
                    loadingButton={false}
                />
            )}
        </>
    );
};

export default Item;
