export const transportWater = (state) => state.transportWater;

export const vehicles = (state) => transportWater(state).vehicles;
export const vehiclesList = (state) => vehicles(state).data;
export const vehiclesMeta = (state) => vehicles(state).meta;
export const loadingVehicles = (state) => transportWater(state).loadingVehicles;

export const types = (state) => transportWater(state).vehicleTypes;
export const loadingTypes = (state) => transportWater(state).loadingVehicleTypes;

export const filters = (state) => transportWater(state).filters;
export const active = (state) => transportWater(state).active;
export const dockCurrentShow = (state) => transportWater(state).dockCurrentShow;
export const dockColorRefresh = (state) => transportWater(state).dockColorRefresh;
