import { Grid, Skeleton, useMediaQuery } from '@mui/material';
import Attachments from '../../../../../../common/Upload/Attachments';
import { ModePartTypes } from './types';
import messages from '../../../../../../../helpers/constants/messages';
import VehicleClassesList from './VehicleClassesList';
import { transportPassengerSelectors } from '../../../../../../../redux/TransportPassenger';
import { useSelector } from 'react-redux';
import { memo, useMemo } from 'react';

const ModePart = memo(({ data, vehicleClasses }: ModePartTypes) => {
    const loadingRouteAdditionalParams = useSelector(transportPassengerSelectors.loadingRouteAdditionalParams);

    const isSmallScreen = useMediaQuery('(max-width: auto)'); // Определение маленького экрана
    const tenMarginLeft = useMemo(() => ({ marginLeft: 10 }), []);
    const flexStyle = useMemo(() => ({ display: 'flex', alignItems: 'center' }), []);
    const skeletonWidth = useMemo(() => (isSmallScreen ? '100%' : '50%'), [isSmallScreen]);    

    return (
        <div style={tenMarginLeft}>
            <h2>Параметры маршрута</h2>
            <div style={tenMarginLeft}>
                <div style={flexStyle}>Вид регулярных перевозок: {loadingRouteAdditionalParams 
                    ? <Skeleton width={skeletonWidth}/>
                    : data?.type_of_regular_transportation_text || messages.INFO_IS_NOT_FOUND
                }</div>
                <div style={flexStyle}>Сезонность маршрута: {loadingRouteAdditionalParams 
                    ? <Skeleton width={skeletonWidth}/>
                    : data?.seasonality_text || messages.INFO_IS_NOT_FOUND}</div>
            </div>
            <h2>Используемые классы ТС</h2>
            <div style={tenMarginLeft}>
                {data.vehicle_classes?.length > 0
                    ? loadingRouteAdditionalParams 
                        ? <Skeleton width={skeletonWidth} height={100}/>
                        : <VehicleClassesList
                            data={data.vehicle_classes}
                            selectList={vehicleClasses} 
                            change={false}
                        />
                    : messages.INFO_IS_NOT_FOUND
                }
            </div>
            <h2>Сведения о тарифах</h2>
            <div style={{ marginLeft: 10 }}>
                <div style={flexStyle}>
                    <h2>Классификация для реестра тарифов на проезд: </h2>{' '}
                    {loadingRouteAdditionalParams 
                        ? <Skeleton width={skeletonWidth}/>
                        : data?.type_transportationText || messages.INFO_IS_NOT_FOUND
                    }
                </div>
                <h3>Оплата наличными</h3>
                <div style={{ marginLeft: 10 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={11} md={6}>
                            <div style={flexStyle}>
                            стоимость проезда пассажира: {
                                    loadingRouteAdditionalParams 
                                        ? <Skeleton width={20}/>
                                        : data?.payment_in_cash?.tariff_passenger
                                }
                            </div>
                        </Grid>
                        <Grid item xs={11} md={6}>
                            <div style={flexStyle}>
                            стоимость провоза багажа: {
                                    loadingRouteAdditionalParams 
                                        ? <Skeleton width={20}/>
                                        : data?.payment_in_cash?.tariff_baggage
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <h3>Безналичная оплата</h3>
                <div style={{ marginLeft: 10 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={11} md={6}>
                            <div style={flexStyle}>
                            стоимость проезда пассажира: {
                                    loadingRouteAdditionalParams 
                                        ? <Skeleton width={20}/>
                                        : data?.payment_cashless?.tariff_passenger
                                }
                            </div>
                        </Grid>
                        <Grid item xs={11} md={6}>
                            <div style={flexStyle}>
                            стоимость провоза багажа: {
                                    loadingRouteAdditionalParams 
                                        ? <Skeleton width={20}/>
                                        : data?.payment_cashless?.tariff_baggage
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Attachments
                service="transport-passenger"
                files={(!data?.payment_file || Array.isArray(data?.payment_file)) 
                    ? []
                    : [{ name: data?.payment_file.filename }] 
                }
                isRequired={false} 
                renderContent={undefined}
                readOnly             
            />
            <div style={tenMarginLeft}>
                <h3>Комментарии: </h3>
                <div style={tenMarginLeft}>{data.comment}</div>
            </div>
        </div>
    );
});

export default ModePart;