import { List } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FormButtonsComponent, { buttonsTypes } from '../../../../../../common/FormButtons';
import AddModal from './AddEditModal';
import Item from './Item';

const Vehicles = ({ fields, onChange }) => {

    const [vehicles, setMembers] = useState(fields.vehicles);
    const [openAddModal, setOpenAddModal] = useState(false);    

    const handleAdd = (vehicle) => {
        setMembers([
            ...vehicles,
            vehicle
        ]);
    };

    const handleChange = ( index, vehicle ) => {
        const newItems = [
            ...vehicles.slice(0, index),
            vehicle,
            ...vehicles.slice(index + 1)
        ];
        setMembers(newItems);
    };

    const handleDelete = (index) => {
        if (index >= 0) {
            setMembers([
                ...vehicles.slice(0, index),
                ...vehicles.slice(index + 1),
            ]);
        }
    };

    useEffect(() => (
        onChange && onChange({ vehicles })
    ),[vehicles]);

    return (
        <div className="modal__form">
            <List className="list">
                {vehicles?.map((vehicle, index) => (
                    <Item
                        key={index}
                        index={index}
                        vehicle={vehicle}
                        onChange={handleChange}
                        onDelete={handleDelete}
                    />
                ))}
            </List>            
            {openAddModal 
                && <AddModal
                    isOpen={openAddModal} 
                    onClose={() => setOpenAddModal(false)}
                    onAccept={handleAdd}
                />
            }
            <FormButtonsComponent
                buttons={[
                    {   
                        ...buttonsTypes.add,
                        onClick: () => setOpenAddModal(true)                        
                        
                    }
                ]}
            />
        </div>        
    );    
};

export default Vehicles;
