import * as types from './types';

export const loadAipList = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_LIST,
    payload: { page, limit, query }
});

export const loadingAipList = (bool = false) => ({
    type: types.LOADING_LIST,
    payload: bool
});

export const loadedAipList = (data) => ({
    type: types.LOADED_LIST,
    payload: data
});

export const loadAipEvents = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_AIP_EVENTS,
    payload: { page, limit, query }
});

export const loadingAipEvents = (bool = false) => ({
    type: types.LOADING_AIP_EVENTS,
    payload: bool,
});

export const loadedAipEvents = (data) => ({
    type: types.LOADED_AIP_EVENTS,
    payload: data,
});

export const getForPolygonAip = (polygon, params = {}) => ({
    type: types.GET_POLYGON,
    payload: { polygon, ...params }
});

export const setLoadingPolygon = (bool = false) => ({
    type: types.SET_POLYGON_LOADING,
    payload: bool,
});

export const saveForPolygon = (data) => ({
    type: types.SAVE_POLYGON,
    payload: data,
});

export const clearForPolygon= () => ({
    type: types.SAVE_POLYGON,
    payload: [],
});

export const loadedSidebar = (data, page) => ({
    type: types.LOADED_SIDEBAR,
    payload: {
        data,
        page,
    }
});

export const loadSidebar = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_SIDEBAR,
    payload: {
        page,
        limit,
        ...params
    }
});

export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool
});

export const clearSidebar = () => ({
    type: types.CLEAR_SIDEBAR
});

export const setActive = (item = {}) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const resetActive = () => ({
    type: types.SET_ACTIVE,
    payload: {},
});

export const setFilter = (data = {}) => ({
    type: types.SET_FILTER,
    payload: data,
});

export const clearFilters = () => ({
    type: types.SET_FILTER,
    payload: {},
});

export const createAip = (data) => ({
    type: types.CREATE_AIP,
    payload: data
});

export const editAip = (id, data) => ({
    type: types.EDIT_AIP,
    payload: { id, data }
});

export const deleteAip = (id) => ({
    type: types.DELETE_AIP,
    payload: id
});

export const loadAipTypes = () => ({
    type: types.LOAD_AIP_TYPES
});

export const loadedAipTypes = (data) => ({
    type: types.LOADED_AIP_TYPES,
    payload: data
});

export const loadAipOperators = () => ({
    type: types.LOAD_AIP_OPERATORS
});

export const loadedAipOperators = (data) => ({
    type: types.LOADED_AIP_OPERATORS,
    payload: data
});



