import { useEffect, useMemo, useState,  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@mui/material';

import * as actions from 'redux/TrafficFlow/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import Information from 'components/common/Information';
import Loading from 'components/common/Loading';
import messages from 'helpers/constants/messages';
import { fullDateTime, fullDateTimeWithTimeZone, getDateWithDuration, getStartOf } from 'helpers/date.config';

const Traffic = ({ id }) => {
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const trafficLoading = useSelector(trafficFlowSelectors.trafficLoading);

    const params = useMemo(() => {
        return {
            start_date: fullDateTimeWithTimeZone(getStartOf('hour', getDateWithDuration({ hours: -1 }))),
            end_date: fullDateTimeWithTimeZone(getDateWithDuration({ hours: -1, minutes: 59, seconds: 59 }, getStartOf('hour'))),
        };
    }, []);

    useEffect(() => {
        // получение events по конкретному ПУИД за час
        if (id) {
            dispatch(actions.loadTrafficInfo(id, params, (data) => setData(data)));
        }
    }, [id, params, dispatch]);


    return (
        <div>
            {trafficLoading
                ? <Loading linear={true} />
                : (

                    <Information
                        data={data?.[0] || {}}
                        title={{
                            start_time: {
                                title: 'Начало периода',
                                // value: ({ start_time }) => start_time ? fullDateTime(start_time) : messages.NO_DATA,
                                value: () => fullDateTime(params.start_time),
                            },
                            end_time: {
                                title: 'Конец периода',
                                // value: ({ end_time }) => end_time ? fullDateTime(end_time) : messages.NO_DATA,
                                value: () => fullDateTime(params.end_time),
                            },
                            // created_at: {
                            //     title: 'Дата и время',
                            //     value: ({ created_at }) => created_at ? fullDateTime(created_at) : messages.NO_DATA,
                            // },
                            intensity: {
                                title: 'Интенсивность',
                                value: () => {
                                    if (data?.length > 0) {
                                        return data.map((el, i, arr) => el.intensity
                                            ? <div key={i}>
                                                <div><b>Полоса</b> – {el?.zone_id}</div>
                                                <div><b>Направление</b> – {el.direction_text}: {el.intensity} ед./час</div>
                                                {i !== arr.length - 1 && <Divider/>}
                                            </div> : '');
                                    }

                                    return messages.NO_DATA;
                                },
                            },
                            // count: {
                            //     title: 'Кол-во автомашин'
                            // },
                            speed: {
                                title: 'Средняя скорость',
                                value: ({ speed }) => parseFloat(speed) >= 0 ? `${Math.round(speed * 100) / 100} км/ч` : messages.NO_DATA,
                            },
                            // gap: {
                            //     title: 'Средний интервал движения',
                            // }

                            // бэк пока переделывает пуиды
                            // zones: {
                            //     title: 'Полоса/Зона',
                            //     value: ({ zones }) => zones
                            //         ?.map(({ zone_id, zoneId, direction }) => `${zoneId ?? zone_id}${direction ? ` (направление${direction})`: ''}`)
                            //         ?.join(', ')
                            //         ?? messages.NO_DATA,
                            // },
                        }}
                    />
                )
            }
        </div>
    );
};

export default Traffic;
