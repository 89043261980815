import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { roadNetworksSelectors } from 'redux/RoadNetwork';
import { loadConnectionLines } from 'redux/RoadNetwork/actions';
import PageLayout from 'components/layout/PageLayout';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';
import titles from 'helpers/constants/titles';

import Item from './Item';
import ModalForm from './ModalForm';
import Filter from './Filter';

import type { ConnectionLineItem } from 'types/RoadNetwork';

const ConnectionLine = () => {
    const dispatch = useDispatch();

    const data = useSelector(roadNetworksSelectors.connectionLinesData);
    const meta = useSelector(roadNetworksSelectors.connectionLinesMeta);
    const loading = useSelector(roadNetworksSelectors.loadingConnectionLines);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openAddModal, setOpenAddModal] = useState(false);

    const reloadList = useCallback(() => {
        dispatch(loadConnectionLines(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    return (
        <>
            <PageLayout
                header="Линия связи"

                filters={<Filter setParams={setParams} />}

                informPanelProps={{
                    buttons: (
                        <FormButtons
                            buttons={[{
                                ...buttonsTypes.add,
                                onClick: () => setOpenAddModal(true),
                            }]
                            }
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total
                }}

                loading={loading}

                content={() => data?.length > 0
                    ? (
                        <LSContainer
                            headers={[
                                { title: '', width: '6%' },
                                { title: titles.NAME, width: '30%' },
                                { title: titles.TYPE, width: '20%' },
                                { title: 'Протяженность', width: '20%' },
                                { title: 'Действия', isActions: true },
                            ]}
                        >
                            {data.map((item: ConnectionLineItem) => (
                                <Item
                                    key={item?.id}
                                    item={item}
                                    reloadList={reloadList}
                                />
                            ))}
                        </LSContainer>
                    )
                    : !loading && <NoData/>
                }

                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />

            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default ConnectionLine;
