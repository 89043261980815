import React from 'react';
import { useSelector } from 'react-redux';
import messages from '../../../../../../../helpers/constants/messages';
import { Collapse, List, ListItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import usePDDViolations from '../../../../../../../helpers/hooks/usePddViolations';
import { dorisControlSelectors } from '../../../../../../../redux/DorisControl';
import { renderArticleName } from '../../../../../../common/DorisControl/PddViolation/renderArticleName';

const useStyles = makeStyles({
    violations: {
        display: 'flex',
        flexDirection: 'column',
        '& > span': {
            padding: '5px 0',
        },
    },
    root: {
        margin: 12
    }
});

const CameraDetails = ({ item, isInfoOpen }) => {
    const classes = useStyles();
    const violations = usePDDViolations(item);
    const loading_cameras_pdd_violations = useSelector(dorisControlSelectors.loading_cameras_pdd_violations);

    return (
        <Collapse className="info" in={isInfoOpen} timeout={'auto'}>
            {item?.lat && item?.lon
                && <Typography variant="body2">
                    <strong>Широта: </strong>{item?.lat || messages.NO_DATA}
                    {' '}
                    <strong>Долгота: </strong>{item?.lon || messages.NO_DATA}
                </Typography>
            }
            {Array.isArray(item?.direction) && item?.direction.length > 0
                && <Typography variant="body2">
                    <span><strong>Градус отклонения: </strong>
                        {/* {item?.direction.map((el, index) => ( */}
                        <span key={item?.direction[0].id}>
                            {item?.direction[0]?.degree}
                            {/* {index !== item.direction.length - 1 && index >= 2 && ','} */}
                        </span>
                        {/* )) || messages.NO_DATA} */}
                    </span>
                </Typography>
            }
            {(item?.dir_type || item?.dir_type === 0) && <Typography variant="body2">
                <strong>Направление фиксации: </strong>
                {item?.dir_type === 0 && 'От камеры'}
                {item?.dir_type === 1 && 'К камере'}
                {item?.dir_type === 2 && 'В любую сторону'}
            </Typography>}
            {Array.isArray(item?.direction) && item?.direction.length
                && <Typography variant="body2">
                    <strong>Текстовое описание направления: </strong>
                    {item?.direction.map((el, index) => (
                        <span key={el.id}>
                            {el.direction}
                            {index !== item.direction.length - 1 && index >= 2 && ','}
                        </span>
                    )) || messages.NO_DATA}
                </Typography>
            }
            {item?.comment && <Typography variant="body2">
                <strong>Дополнительная информация: </strong>{item?.comment || messages.NO_DATA}
            </Typography>}
            {item?.pdd_violations && Object.keys(item?.pdd_violations).length > 0
                && <Typography variant="body2" className={classes.violations}>
                    <b>Фиксируемые нарушения:</b>
                    <List>
                        {violations.length > 0
                            ? (violations.map((el) => {
                                return el && (
                                    <ListItem key={el.id}>{renderArticleName(el, '- ')}</ListItem>
                                );
                            }))
                            : (!loading_cameras_pdd_violations[item.id] && <span>{messages.DATA_IS_NOT_FOUND}</span>)
                        }
                    </List>
                </Typography>
            }
        </Collapse>
    );
};

export default CameraDetails;
