import FormButtonsComponent from '../../../../../common/FormButtons';
import RenderSign from '../../Messages/RenderSign';
import RenderCondition from './RenderCondition';

const RenderSigns = ({
    data = [],
    onChange,
    serviceFields = {},
    microservice
}) => {
    const addNewSign = () => {
        onChange([
            ...data, 
            {
                type: 'sign',
                'align-x': 'left',
                'align-y': 'center',
                x: 0,
                y: 0,
                sign: null,
                size: '90'
            }
        ]);
    };

    const onChangeSign = (i, el) => {
        const newData = [
            ...data.slice(0, i),
            el,
            ...data.slice(i + 1)
        ];
        onChange(newData);
    };

    const onDeleteSign = (i) => {
        const newData = [
            ...data.slice(0, i),
            ...data.slice(i + 1)
        ];
        onChange(newData);
    };

    return (
        <>
            <h2>Знаки:</h2>
            {data.map((item, index) =>
                <div 
                    key={index} 
                    style={{ 
                        backgroundColor: index % 2 === 0 ? 'rgba(155,157,176,0.2)' : 'inherit', 
                        padding: '0.5rem' 
                    }}
                >
                    <RenderSign
                        item={item}
                        onChange={(item) => onChangeSign(index, item)}
                        onDelete={() => onDeleteSign(index)}
                    />
                    <RenderCondition
                        textData={item}
                        data={item?.condition || []}
                        onChange={(val) => onChangeSign(index, val)}
                        microservice={microservice}
                    />
                </div>
            )}
            <FormButtonsComponent
                noPadding
                positionLeft
                noMarginLeft
                buttons={[
                    {
                        onClick: addNewSign,
                        name: 'Добавить знак'
                    }
                ]}
            />
        </>
    );
};

export default RenderSigns;