import React, { useState, useEffect } from 'react';
import {
    FeatureGroup,
    Map,
    Marker,
    ToolTip
} from '../../../../MapComponents/leaflet';
import './vfcomplex.module.scss';
import { config } from '../../../../../config';
import L from 'leaflet';
import cpvfCameraIcon from '../../../../common/Icons/cpvfCameraIcon';

const MapForm = (props) => {
    const {
        latlon = [],
        onAccept,
        readOnly = false,
        entities = [],
        innerComponent: InnerComponent = null,
        innerComponentEvent = null,
        permanent = true,
        icon = null,
        offsetToolTip = [0, -40]
    } = props;
    const [mapCenter, setMapCenter] = useState(config.get('mapCenter'));
    const [mapZoom, setMapZoom] = useState(null);
    const [bounds, setBounds] = useState(null);

    const handleMapClick = (e) => {
        const { lat, lng } = e.latlng;
        onAccept([lat,lng]);
    };

    useEffect(()=> {
        if (!latlon[0] || !latlon[1]) {
            setMapCenter(config.get('mapCenter'));
            setMapZoom(15);
        } else {
            setMapCenter(latlon);
            setMapZoom(15);
        }
    },[latlon, entities]);

    // подписываем компонент на изменение зума
    // todo рефактор компонента
    const Inner = (props) => {
        const [zoom, setZoom] = useState(props.map.getZoom());

        props.map.on(innerComponentEvent, () => {
            setZoom(props.map.getZoom());
        });

        return (
            <InnerComponent {...props} zoom={zoom} />
        );
    };

    const createCameraIcon = ({ direction = 0 }) => {
        const backColor = 'background: #c73ee0;';
        const isArrayDirection = Array.isArray(direction) ? direction : [direction];
        return L.divIcon({
            html: `
            <div class="wrap">
                <div class="circle" style="${backColor}">
                    <i class="icon">${cpvfCameraIcon({ angleRotate: isArrayDirection })}</i>
                </div>
            </div>
        `,
            className: 'cpvf-camera-icon',
            iconSize: [20, 20],
            // tooltipAnchor: [0, 0],
            iconAnchor: [10, 10]
        });
    };

    const cameraMarker = (arr) => {
        return Array.isArray(arr) && arr.length
            ? arr.map((item, index) => {
                const lat = parseFloat(item?.lat);
                const lon = parseFloat(item?.lon);
                const degrees = [item?.direction?.[0]?.degree];
        
                return item?.lat && item?.lon ? (
                    <Marker
                        key={item?.id || `camera_${index}`}
                        latlng={[lat, lon]}
                        icon={createCameraIcon({ direction: degrees })}
                    >
                        <ToolTip offset={[0, -8]} direction="top">
                            <div>
                                {item?.serial && (
                                    <span style={{ display: 'flex', padding: 2 }}>
                                        <strong>Серийный номер:&nbsp;</strong>
                                        {item?.serial}
                                    </span>
                                )}
            
                                <span style={{ display: 'flex', padding: 2 }}>
                                    <strong>Градус отклонения камеры:&nbsp;</strong>
                                    {degrees}
                                    {item?.direction?.degree}
                                </span>
                            </div>
                        </ToolTip>
                    </Marker>
                ) : null;
            })
            : null;
    };

    return (
        <div>
            <div className="map-form-wrap__container">
                <Map
                    center={mapCenter}
                    zoom={mapZoom}
                    {...(!readOnly
                        ? { onClick: handleMapClick }
                        : {})
                    }
                    bounds={bounds}
                >
                    <FeatureGroup>
                        {/* маркер комплекса */}
                        {latlon &&  (
                            <Marker
                                latlng={latlon}
                                {...(!readOnly
                                    ? { onDragEnd: (newLatLng) => {
                                        const { lat, lon } = newLatLng;
                                        onAccept([lat,lon]);
                                    } }
                                    : {})
                                }
                                {...(icon ? { icon } : {})}
                            >
                                {/* выводим подсказку сверху с координатами */}
                                <ToolTip
                                    offset={offsetToolTip}
                                    permanent={permanent}
                                    // permanent={true}
                                    direction="top"
                                >
                                    <div>
                                        <span style={{ display: 'flex', padding: 2 }}>
                                            <strong>Широта:&nbsp;</strong>
                                            {latlon[0]}
                                        </span>
                                        <span style={{ display: 'flex', padding: 2 }}>
                                            <strong>Долгота:&nbsp;</strong>
                                            {latlon[1]}
                                        </span>
                                    </div>
                                </ToolTip>
                            </Marker>
                        )}

                        {/* отображаем список камер с типом 4 и 5 */}
                        {cameraMarker(entities?.[4])}
                        {/* {cameraMarker(entities?.[5])} */}

                        {/* импортим компонент */}
                        {!!InnerComponent && <Inner/>}
                    </FeatureGroup>
                </Map>
            </div>
        </div>
    );
};

export default MapForm;
