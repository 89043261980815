import { all, put, call, takeLatest } from 'redux-saga/effects';
import api from './api.methods';
import * as types from './types';
import * as actions from './actions';
import { setValidation } from '../Validation/actions';
import { showMessage } from '../Message/actions';
import messageTypes from '../Message/messagetypes';
import common from '../../helpers/constants/messages/common';
import messages from '../../helpers/constants/messages';

function* loadTelegramIdSaga({ payload, callback }) {
    yield put(actions.setLoading(true));
    const response = yield call(api.loadTelegramId, payload);
    if (response?.success) {
        callback?.(response.data);
    }
    yield put(actions.setLoading(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_TELEGRAM_ID, loadTelegramIdSaga),
    ]);
}
