import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import {
    copyTrafficSchedule,
    createTrafficSchedule,
    loadRoutes,
    loadScheduleTemplateList,
    loadTrafficSchedule
} from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { loadCurrentCompany } from 'redux/Companies/actions';
import { companiesSelectors } from 'redux/Companies';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import formatDate from 'helpers/constants/dateFormat';
import { fullDateTimeWithTimeZone, getDateObjectFromHHMM, getTimeWithoutSeconds, isDateValid } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import DateRange from 'components/common/Dates/DateRange';
import Loading from 'components/common/Loading';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import SelectRoutes from 'components/common/Autocomplete/PassengerTransport/Routes';
import SelectScheduleTemplate from 'components/common/Autocomplete/PassengerTransport/ScheduleTemplate';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import SelectPark from 'components/common/Autocomplete/Transport/Parks/intex';

const ModalForm = ({
    isOpen,
    onClose,
    isNew = false,
    item={},
    types,
    statuses,
    isClone = false,
    currentRoute,
    routeForTrafficSchedule = false,
    test_id_prefix = '',
    reloadList = () => {},
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingTrafficSchedule = useSelector(transportPassengerSelectors.loadingTrafficSchedule);
    const scheduleTemplate = useSelector(transportPassengerSelectors.scheduleTemplate);
    const routes = useSelector(transportPassengerSelectors.routes);
    const [currentItem, setCurrentItem] = useState(item);
    // item у нас урезанный (is_simple) в нем нет многих полей, после загрузки полного объекта
    // положим его сюда чтобы понимать какие поля были у него изначально - иногда они не должны изменяться

    const organizationsById = useSelector(companiesSelectors.currentCompany);

    useEffect(() => {
        if (item.organization_id && !item.organization) {
            if(!organizationsById[item.organization_id]){
                dispatch(loadCurrentCompany(item.organization_id));
            }
        }
    }, [item, dispatch, organizationsById]);

    const initialState = useCallback((el = item) => ({
        name: el?.name || '',
        diagram: el?.diagram || '',
        shift: el?.shift || '',
        route_id_direct: currentRoute?.is_direct ? currentRoute.id : el?.route_id_direct || null,
        route_direct: currentRoute?.is_direct ? currentRoute : el?.route_direct || routes.data?.find(el => el.id === el?.route_id_direct) || null,
        schedule_template_id_direct: el?.schedule_template_id_direct || null,
        schedule_template_direct: el?.schedule_template_direct || scheduleTemplate.data?.find(el => el.id === el?.schedule_template_direct) || null,
        route_id_back: ((currentRoute?.is_direct === false) ? currentRoute.id : el?.route_id_back) || null,
        route_back: (currentRoute?.is_direct === false) ? currentRoute : el?.route_back || routes.data?.find(el => el.id === el?.route_id_back) || null,
        schedule_template_id_back: el?.schedule_template_id_back || null,
        schedule_template_back: el?.schedule_template_back || scheduleTemplate.data?.find(el => el.id === el?.schedule_template_id_back) || null,
        flight_copy_amount: el?.flight_copy_amount || '',
        organization_id: el?.organization_id || '',
        organization: el?.organization || organizationsById[el.organization_id] || {},
        park_id: el?.park_id || '',
        park: el?.park || '',
        starting_mileage_distance: el?.starting_mileage_distance || '',
        // starting_mileage_time: el?.starting_mileage_time || null,
        starting_mileage_time: el?.starting_mileage_time ? getDateObjectFromHHMM(el.starting_mileage_time) : null,
        // interval: el?.interval || null,
        // time_dinner: el?.time_dinner || null,
        interval: el?.interval ? getDateObjectFromHHMM(el.interval) : null,
        time_dinner: el?.time_dinner ? getDateObjectFromHHMM(el.time_dinner) : null,
        start_at: el?.start_at || null,
        end_at: el?.end_at || null,
        type: el?.type || '',
        days_week: el?.days_week || '',
        is_holiday: el?.is_holiday || false,
        status: isNew ? '1' : el?.status,
    }), [currentRoute, isNew, item, organizationsById, routes.data, scheduleTemplate.data]);
    const [data, setData] = useState(initialState());

    useEffect(() => {
        if (Object.keys(item).length > 0 && !item.organization_id) {
            dispatch(loadTrafficSchedule(1, 1, { id: item.id, is_simple: 0 }, ({ data }) =>  {
                setData(initialState(data[0]));
                setCurrentItem(data[0]);
            }));
        }
        const needRoute = () => {
            return [item.route_id_direct, item.route_id_back].reduce((res) => {
                if ((item.route_id_direct && !item.route_direct))
                    res.push(item.route_id_direct);
                if ((item.route_id_back && !item.route_back))
                    res.push(item.route_id_back);
                return res;
            }, []);
        };

        const needScheduleTemplate = () => {
            return [item.schedule_template_id_direct, item.schedule_template_id_back].reduce((res) => {
                if ((item.schedule_template_id_direct && !item.schedule_template_direct))
                    res.push(item.schedule_template_id_direct);
                if ((item.schedule_template_id_back && !item.schedule_template_back))
                    res.push(item.schedule_template_id_back);
                return res;
            }, []);
        };

        if (needRoute().length > 0) {
            dispatch(loadRoutes(1, 10, { id_list : needRoute() }));
        }

        if (needScheduleTemplate().length > 0) {
            dispatch(loadScheduleTemplateList(1, 10, { ids : needScheduleTemplate() }));
        }

    }, [dispatch, item, initialState]);

    const [isMonday, setIsMonday] = useState(false);
    const [isTuesday, setIsTuesday] = useState(false);
    const [isWednesday, setIsWednesday] = useState(false);
    const [isThursday, setIsThursday] = useState(false);
    const [isFriday, setIsFriday] = useState(false);
    const [isSaturday, setIsSaturday] = useState(false);
    const [isSunday, setIsSunday] = useState(false);

    const [replaceDays, setReplaceDays] = useState(false);

    const [isErrorDate, setIsErrorDate] = useState(false);

    const requiredFields = [
        'name',
        'diagram',
        'shift',
        'route_id_direct',
        'schedule_template_id_direct',
        'flight_copy_amount',
        'organization_id',
        'status',
        'type',
        'start_at'
    ];
    const isDisabled = !!requiredFields.find((field) => !data[field])
        || (data.route_id_back && !data.schedule_template_id_back)
        || (!!data.time_dinner && !data.starting_mileage_time)
        || isErrorDate;

    //Прямой маршрут
    const handleSelectRouteIdDirect = (el) => {
        setData({
            ...data,
            route_id_direct: el.id,
            route_direct: el,
            schedule_template_direct: '',
            schedule_template_id_direct: '',
            route_back: '',
            schedule_template_back: ''
        });
    };

    //Шаблон расписания прямого маршрута
    const handleSelectScheduleTemplateIdDirect = (el) => {
        setData({ ...data, schedule_template_id_direct: el.id, schedule_template_direct: el });
    };

    //Обратный маршрут
    const handleSelectRouteIdBack = (el) => {
        setData({
            ...data,
            route_id_back: el.id,
            route_back: el,
            schedule_template_id_back: '',
            schedule_template_back: '',
        });
    };

    //Шаблон расписания обратного маршрута
    const handleSelectScheduleTemplateIdBack = (el) => {
        setData({ ...data, schedule_template_id_back: el.id, schedule_template_back: el });
    };

    //Организации-перевозчики
    const handleSelectOrganization = (organization) => {
        setData({ ...data, organization_id: organization.id, organization });
    };

    //Парки
    const handleSelectPark = (event) => {
        setData({ ...data, park: { ...event }, park_id: event.id });
    };

    const onAcceptModal = () => {
        const correctStartingMileageTime = getTimeWithoutSeconds(data.starting_mileage_time);
        const correctIntervalTime = getTimeWithoutSeconds(data.interval);
        const correctDinnerTime = getTimeWithoutSeconds(data.time_dinner);
        const correctStartAt = fullDateTimeWithTimeZone(data.start_at);
        const correctEndAt = fullDateTimeWithTimeZone(data.end_at);

        const arrayDaysOfWeek = [isMonday, isTuesday, isWednesday, isThursday, isFriday, isSaturday, isSunday];
        const transformArrToStr = arrayDaysOfWeek.map(day => day ? 1 : 0).join('');

        const prepareData = {
            ...data,
            starting_mileage_time : data.starting_mileage_time && correctStartingMileageTime,
            interval : data.interval && correctIntervalTime,
            time_dinner : data.time_dinner && correctDinnerTime,
            start_at: correctStartAt,
            end_at: data.end_at,
            days_week: transformArrToStr,
        };

        const TransformationDataForSend = _.reduce(
            prepareData,
            (res, el, key) => {
                if (el?.hasOwnProperty('id') && el?.id && !Array.isArray(el)) {
                    res[key] = el;
                    return res;
                }
                if (key === 'is_holiday') {
                    res[key] = el;
                    return res;
                }
                if (key === 'end_at') {
                    if (data?.end_at) {
                        res[key] = correctEndAt;
                    }
                    return res;
                }
                if (el) {
                    res[key] = el;
                    return res;
                }
                return res;
            },
            {}
        );

        if (isClone) {
            dispatch(copyTrafficSchedule(item?.id, TransformationDataForSend, () => {
                reloadList();
                onClose();
            }));
        } else {
            dispatch(createTrafficSchedule(TransformationDataForSend, () => {
                reloadList();
                onClose();
            }));
        }
    };

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
        validation.deleteKey(name);
    };

    useEffect(() => {
        if (Number(data.type) === 1 || Number(data.type) === 5) {
            setIsMonday(false);
            setIsTuesday(false);
            setIsWednesday(false);
            setIsThursday(false);
            setIsFriday(false);
            setIsSaturday(false);
            setIsSunday(false);
            setData((prevData) => ({ ...prevData, is_holiday: false }));
        }
        if (Number(data.type) === 2) {
            setIsMonday(true);
            setIsTuesday(true);
            setIsWednesday(true);
            setIsThursday(true);
            setIsFriday(true);
            setIsSaturday(true);
            setIsSunday(true);
            setData((prevData) => ({ ...prevData, is_holiday: true }));
        }
        if (Number(data.type) === 6) {
            setIsMonday(false);
            setIsTuesday(false);
            setIsWednesday(false);
            setIsThursday(false);
            setIsFriday(false);
            setIsSaturday(true);
            setIsSunday(true);
            setData((prevData) => ({ ...prevData, is_holiday: false }));
        }
        if (Number(data.type) === 7) {
            setIsMonday(false);
            setIsTuesday(false);
            setIsWednesday(false);
            setIsThursday(false);
            setIsFriday(false);
            setIsSaturday(false);
            setIsSunday(false);
            setData((prevData) => ({ ...prevData, is_holiday: true }));
        }

    },[data.type, replaceDays]);

    useEffect(() => {
        if (isClone) {
            setReplaceDays(true);
        }
    }, [item, isClone]);

    useEffect(() => {
        if (item?.days_week) {
            const arr = item?.days_week.split('').map(el => el == 1 ? true : false);
            setIsMonday(arr[0]);
            setIsTuesday(arr[1]);
            setIsWednesday(arr[2]);
            setIsThursday(arr[3]);
            setIsFriday(arr[4]);
            setIsSaturday(arr[5]);
            setIsSunday(arr[6]);
        }
        if (item?.is_holiday) {
            setData((prevData) => ({ ...prevData, is_holiday: true }));
        }
    },[item?.days_week, item?.end_at, item?.is_holiday]);

    const checkInvalidDate = useCallback((date) => {
        return date && !isDateValid(date);
    }, []);

    useEffect(() => {
        if (checkInvalidDate(data.start_at)
            || checkInvalidDate(data.end_at)
            || checkInvalidDate(data.time_dinner) || checkInvalidDate(data.interval)
            || checkInvalidDate(data.starting_mileage_time)) {
            setIsErrorDate(true);
        } else {
            setIsErrorDate(false);
        }
    }, [checkInvalidDate, data.start_at, data.end_at, data.time_dinner, data.starting_mileage_time, data.interval]);

    useEffect(() => {
        return () => {
            validation.clear();
        };
    }, []);

    const errorIsTimeDinnerAndStartingMileageTime = data.starting_mileage_time
        && data.time_dinner
        && data.starting_mileage_time > data.time_dinner;

    const errorIsTimeDinnerText = () => errorIsTimeDinnerAndStartingMileageTime
        ? 'В поле Время обеда должна быть дата после или равняться Нулевой пробег, время.'
        : '';

    const getValidationInfo = (start, end) => {
        return {
            message: <>
                <span>{validation.get(start) || ''}</span>
                <span>{validation.get(end) || ''}</span>
            </>,
            bool: validation.isKey(start) || validation.isKey(end)
        };
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title={isClone ? 'Копирование графика' : isNew ? titles.CREATE : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose,
                        'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/buttons/cancel`
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: isClone
                            ? data.diagram === currentItem.diagram || errorIsTimeDinnerAndStartingMileageTime
                            : isDisabled || errorIsTimeDinnerAndStartingMileageTime,
                        'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/buttons/save`
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <FormInfoWrapper
                        error={!data.name || validation.isKey('name')}
                        helperText={validation.get('name')}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/name/input`}
                    >
                        <TextField
                            label={titles.NAME}
                            size="small"
                            value={data.name}
                            variant="outlined"
                            name="name"
                            type="text"
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            required={true}
                            error={!data.name || validation.isKey('name')}
                            InputLabelProps={{ 'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/name/label` }}
                            inputProps={{
                                'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/name/input`
                            }}
                        />
                    </FormInfoWrapper>
                </div>
                <div className="block">
                    <FormInfoWrapper
                        error={!data.name || validation.isKey('name')}
                        helperText={validation.get('name')}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/status`}
                    >
                        <FormControl className="block" size="small" required variant="outlined">
                            <InputLabel data-testid={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/status/label`}>Статус графика</InputLabel>
                            <Select
                                value={data.status}
                                required
                                label="Статус графика"
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                name="status"
                                error={!data.status || !!validation.isKey('status')}
                                inputProps={{
                                    'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/status`
                                }}
                            >
                                {Object.keys(statuses)?.length
                                    ? Object.keys(statuses)?.map((key) =>
                                        <MenuItem value={key} key={key}>{statuses[key]}</MenuItem>
                                    )
                                    : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </FormInfoWrapper>
                </div>
                <div className="row">
                    <div className="row__item">
                        <FormInfoWrapper
                            error={
                                !data.diagram
                                || validation.isKey('diagram')
                                || (isClone && data.diagram === currentItem.diagram)
                            }
                            helperText={
                                validation.get('diagram')
                                || (isClone && data.diagram === currentItem.diagram ? 'Вы должны ввести другой номер' : '')
                            }
                            test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/diagram/input`}
                        >
                            <TextField
                                label={'Номер графика'}
                                size="small"
                                value={data.diagram}
                                variant="outlined"
                                name="diagram"
                                type="text"
                                onChange={(e) => onChange(e.target.name, e.target.value )}
                                required={true}
                                error={
                                    !data.diagram
                                    || validation.isKey('diagram')
                                    || (isClone && data.diagram === currentItem.diagram)
                                }
                                InputLabelProps={{ 'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/diagram/label` }}
                                inputProps={{
                                    'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/diagram/input`
                                }}
                            />
                        </FormInfoWrapper>
                    </div>
                    <div className="row__item">
                        <FormInfoWrapper
                            error={
                                !data.shift
                                || validation.isKey('shift')
                            }
                            helperText={
                                validation.get('shift')
                                || (isClone && data.shift === currentItem.shift ? 'Номер смены совпадает с исходным графиком' : '')
                            }
                            test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/shift/input`}
                        >
                            <TextField
                                label={'Номер смены'}
                                size="small"
                                value={data.shift}
                                variant="outlined"
                                name="shift"
                                type="number"
                                onChange={(e) => onChange(e.target.name, e.target.value < 0
                                    ? e.target.value = 0
                                    : e.target.value )}
                                required={true}
                                error={
                                    !data.shift
                                    || validation.isKey('shift')
                                }
                                InputLabelProps={{ 'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/shift/label` }}
                                inputProps={{
                                    'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/shift/input`
                                }}
                            />
                        </FormInfoWrapper>
                    </div>
                </div>
                <div className="block">
                    <FormInfoWrapper
                        error={validation.isKey('route_id_direct')}
                        helperText={validation.get('route_id_direct')}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/route_direct`}
                    >
                        <SelectRoutes
                            selected={data?.route_direct}
                            onChange={handleSelectRouteIdDirect}
                            multiple={false}
                            required
                            disabled={currentRoute?.is_direct}
                            label={'Прямой маршрут'}
                            filter={{ is_direct: 1, status: [2] }}
                            isClone={isClone}
                            routeForTrafficSchedule={routeForTrafficSchedule}
                            error={validation.isKey('route_id_direct')}
                            test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/route_direct`}
                        />
                    </FormInfoWrapper>
                </div>
                <div className="block">
                    <FormInfoWrapper
                        error={!!validation.isKey('schedule_template_id_direct')}
                        helperText={(!data?.route_direct?.id
                            && 'Выбор шаблона расписания станет доступен после выбора маршрута')
                            || validation.get('schedule_template_id_direct')
                        }
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/schedule_template_direct`}
                    >
                        <SelectScheduleTemplate
                            multiple={false}
                            selected={data?.schedule_template_direct}
                            onChange={handleSelectScheduleTemplateIdDirect}
                            required={!!data?.route_id_direct}
                            disabled={!data?.route_direct?.id}
                            label={'Шаблон расписания прямого маршрута'}
                            error={!data?.schedule_template_direct?.name
                                || !!validation.isKey('schedule_template_id_direct')}
                            filter={{
                                route_id: data?.route_direct?.id
                            }}
                            isClone={isClone}
                            test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/schedule_template_direct`}
                        />
                    </FormInfoWrapper>
                </div>
                <div className="block">
                    <FormInfoWrapper
                        error={!!validation.isKey('route_id_back')}
                        helperText={validation.get('route_id_back')}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/route_back`}
                    >
                        <SelectRoutes
                            selected={data?.route_back}
                            onChange={handleSelectRouteIdBack}
                            multiple={false}
                            required
                            disabled={!!(currentRoute?.is_direct === false)}
                            label={'Обратный маршрут'}
                            filter={{
                                is_direct: 0,
                                status: [2],
                                ...(data.route_direct?.num && { num: data.route_direct?.num }),
                                ...(data.route_direct?.category_id && { category_id: data.route_direct.category_id })
                            }}
                            error={!!validation.isKey('route_id_back')}
                            isClone={isClone}
                            routeForTrafficSchedule={routeForTrafficSchedule}
                            test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/route_back`}
                        />
                    </FormInfoWrapper>
                </div>
                <div className="block">
                    <FormInfoWrapper
                        error={!data?.schedule_template_id_back && !!data?.route_id_back}
                        helperText={(!data?.route_back?.id && 'Выбор шаблона расписания станет доступен после выбора маршрута')
                            || validation.get('schedule_template_id_back')}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/schedule_template_back`}
                    >
                        <SelectScheduleTemplate
                            multiple={false}
                            selected={data?.schedule_template_back}
                            onChange={handleSelectScheduleTemplateIdBack}
                            required={!!data?.route_id_back}
                            disabled={!data?.route_back?.id}
                            label={'Шаблон расписания обратного маршрута'}
                            error={!data?.schedule_template_id_back && !!data?.route_id_back}
                            filter={{
                                route_id: data?.route_back?.id
                            }}
                            isClone={isClone}
                            test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/schedule_template_back`}
                        />
                    </FormInfoWrapper>
                </div>
                <div className="block">
                    <FormInfoWrapper
                        error={!data.flight_copy_amount || validation.isKey('flight_copy_amount')}
                        helperText={validation.get('flight_copy_amount')}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/flight_copy_amount/input`}
                    >
                        <TextField
                            label="Количество копий рейсов"
                            size="small"
                            value={data.flight_copy_amount}
                            variant="outlined"
                            name="flight_copy_amount"
                            type="number"
                            onChange={(e) => onChange(e.target.name, e.target.value < 0
                                ? e.target.value = '0'
                                : e.target.value )}
                            required={true}
                            error={!data.flight_copy_amount || validation.isKey('flight_copy_amount')}
                            disabled={isClone}
                            InputLabelProps={{ 'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/flight_copy_amount/label` }}
                            inputProps={{
                                'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/flight_copy_amount/input`
                            }}
                        />
                    </FormInfoWrapper>
                </div>
                <div className="block">
                    <FormInfoWrapper
                        error={!data?.organization?.title || !!validation.isKey('organization_id')}
                        helperText={validation.get('organization_id')}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/organization`}
                    >
                        <SelectCompany
                            selected={data.organization}
                            onChange={handleSelectOrganization}
                            required
                            error={!data?.organization?.title || !!validation.isKey('organization_id')}
                            filter={{ withDeleted: 1 }}
                            test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/organization`}
                        />
                    </FormInfoWrapper>
                </div>
                <div className="block">
                    <SelectPark
                        error={!!validation.isKey('park_id')}
                        helperText={validation.get('park_id')}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/park/autocomplete`}
                        selected={data?.park || ''}
                        renderLabel={(option) => option?.name || ''}
                        onReset={() => setData({ ...data, park: {}, park_id: '' })}
                        onChange={handleSelectPark}
                        disabled={isClone && !!currentItem?.park_id}
                    />
                </div>
                <h2>Нулевой пробег (от парка до первой остановки):</h2>
                <div className="row">
                    <FormInfoWrapper
                        className="row__item"
                        error={validation.isKey('starting_mileage_distance')}
                        helperText={validation.get('starting_mileage_distance')}
                        test_id_prefix={test_id_prefix}
                    >
                        <TextField
                            label="Расстояние(км)"
                            size="small"
                            value={data.starting_mileage_distance}
                            variant="outlined"
                            name="starting_mileage_distance"
                            type="number"
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            required={false}
                            error={validation.isKey('starting_mileage_distance')}
                            disabled={isClone}
                            inputProps={{
                                min:0,
                                'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/starting_mileage_distance/input`
                            }}
                            InputLabelProps={{ 'data-testid': `${test_id_prefix}:${isNew ? 'add' : 'edit'}/starting_mileage_distance/label` }}
                        />
                    </FormInfoWrapper>

                    <SingleKeyboardDateTimePicker
                        className="row__item"
                        placeholder="ЧЧ:ММ"
                        required={!!data.time_dinner}
                        label="Время (ЧЧ:ММ)"
                        value={data.starting_mileage_time || null}
                        pickerFormat={formatDate.HH_MM}
                        onChange={(event) => setData({ ...data, starting_mileage_time: event })}
                        error={(!!data.time_dinner && !data.starting_mileage_time) || !!validation.isKey('starting_mileage_time')}
                        helperText={(!!data.time_dinner && !data.starting_mileage_time && 'Поле обязательно, если заполнен Обед') || validation.get('starting_mileage_time')}
                        isDisabled={isClone}
                        timeOnly
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/time_dinner`}
                    />
                </div>
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        placeholder="ЧЧ:ММ"
                        label="Межрейсовый отстой (ЧЧ:ММ)"
                        value={data.interval || null}
                        pickerFormat={formatDate.HH_MM}
                        onChange={(event) => setData({ ...data, interval: event })}
                        error={checkInvalidDate(data.interval) || validation.isKey('interval')}
                        helperText={validation.get('interval')}
                        isDisabled={isClone}
                        timeOnly
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/interval`}
                    />
                </div>
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        placeholder="ЧЧ:ММ"
                        label="Обед через (ЧЧ:ММ)"
                        value={data.time_dinner || null}
                        pickerFormat={formatDate.HH_MM}
                        onChange={(event) => setData({ ...data, time_dinner: event || null })}
                        error={
                            checkInvalidDate(data.time_dinner)
                                || validation.isKey('time_dinner')
                                || errorIsTimeDinnerAndStartingMileageTime
                        }
                        helperText={
                            validation.get('time_dinner')
                                || errorIsTimeDinnerText()
                        }
                        isDisabled={isClone}
                        timeOnly
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/time_dinner`}
                    />
                </div>
                <h2>Укажите параметры действия графика:</h2>
                <div className="row">
                    <DateRange
                        handleDate={(e) => onChange('start_at', e)}
                        handleEndDate={(e) => onChange('end_at', e)}
                        valueStartDate={data.start_at}
                        valueEndDate={data.end_at}
                        startLabel="Действует с"
                        endLabel="Действует по"
                        required={true}
                        disableFuture={false}
                        className="row__item"
                        renderError={() => getValidationInfo('start_at', 'end_at').bool}
                        errorMessage={() => getValidationInfo('start_at', 'end_at').message}
                    />
                </div>
                <div className="block">
                    <FormInfoWrapper
                        helperText={validation.get('type')}
                        error={!data.type || !!validation.isKey('type')}
                        test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/type`}
                    >
                        <FormControl size="small" required variant="outlined">
                            <InputLabel style={ data.type ? { color: 'rgba(0, 0, 0, 0.54)' } : { color: '#ff0000' } }>Тип графика</InputLabel>
                            <Select
                                value={data.type}
                                required
                                label="Тип графика"
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                name="type"
                                error={!data.type || !!validation.isKey('type')}
                                test_id_prefix={`${test_id_prefix}:${isNew ? 'add' : 'edit'}/type`}
                            >
                                {Object.keys(types)?.length
                                    ? Object.keys(types)?.map((key) =>
                                        <MenuItem value={key} key={key}>{types[key]}</MenuItem>
                                    )
                                    : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </FormInfoWrapper>
                </div>
                { data.type
                    && <>
                        <div className="row">
                            <FormControlLabel
                                className="row__item"
                                style={{ pointerEvents: 'none' }}
                                control={
                                    <Checkbox
                                        checked={isMonday}
                                        onChange={ (event) => setIsMonday(event.target.checked) }
                                        color="primary"
                                        style={{ pointerEvents: 'auto' }}
                                        disabled={ Number(data.type) === 1 || Number(data.type) === 2 || Number(data.type) === 6 || Number(data.type) === 7}
                                    />
                                }
                                label="Пн"
                            />
                            <FormControlLabel
                                className="row__item"
                                style={{ pointerEvents: 'none' }}
                                control={
                                    <Checkbox
                                        checked={isTuesday}
                                        onChange={ (event) => setIsTuesday(event.target.checked) }
                                        color="primary"
                                        style={{ pointerEvents: 'auto' }}
                                        disabled={ Number(data.type) === 1 || Number(data.type) === 2 || Number(data.type) === 6 || Number(data.type) === 7}
                                    />
                                }
                                label="Вт"
                            />
                            <FormControlLabel
                                className="row__item"
                                style={{ pointerEvents: 'none' }}
                                control={
                                    <Checkbox
                                        checked={isWednesday}
                                        onChange={ (event) => setIsWednesday(event.target.checked) }
                                        color="primary"
                                        style={{ pointerEvents: 'auto' }}
                                        disabled={ Number(data.type) === 1 || Number(data.type) === 2 || Number(data.type) === 6 || Number(data.type) === 7}
                                    />
                                }
                                label="Ср"
                            />
                            <FormControlLabel
                                className="row__item"
                                style={{ pointerEvents: 'none' }}
                                control={
                                    <Checkbox
                                        checked={isThursday}
                                        onChange={ (event) => setIsThursday(event.target.checked) }
                                        color="primary"
                                        style={{ pointerEvents: 'auto' }}
                                        disabled={ Number(data.type) === 1 || Number(data.type) === 2 || Number(data.type) === 6 || Number(data.type) === 7}
                                    />
                                }
                                label="Чт"
                            />
                            <FormControlLabel
                                className="row__item"
                                style={{ pointerEvents: 'none' }}
                                control={
                                    <Checkbox
                                        checked={isFriday}
                                        onChange={ (event) => setIsFriday(event.target.checked) }
                                        color="primary"
                                        style={{ pointerEvents: 'auto' }}
                                        disabled={ Number(data.type) === 1 || Number(data.type) === 2 || Number(data.type) === 6 || Number(data.type) === 7}
                                    />
                                }
                                label="Пт"
                            />
                            <FormControlLabel
                                className="row__item"
                                style={{ pointerEvents: 'none' }}
                                control={
                                    <Checkbox
                                        checked={isSaturday}
                                        onChange={ (event) => setIsSaturday(event.target.checked) }
                                        color="primary"
                                        style={{ pointerEvents: 'auto' }}
                                        disabled={ Number(data.type) === 1 || Number(data.type) === 2 || Number(data.type) === 6 || Number(data.type) === 7}
                                    />
                                }
                                label="Сб"
                            />
                            <FormControlLabel
                                className="row__item"
                                style={{ pointerEvents: 'none' }}
                                control={
                                    <Checkbox
                                        checked={isSunday}
                                        onChange={ (event) => setIsSunday(event.target.checked) }
                                        color="primary"
                                        style={{ pointerEvents: 'auto' }}
                                        disabled={ Number(data.type) === 1 || Number(data.type) === 2 || Number(data.type) === 6 || Number(data.type) === 7}
                                    />
                                }
                                label="Вс"
                            />
                        </div>
                        <div className="block">
                            <FormControlLabel
                                style={{ pointerEvents: 'none' }}
                                control={
                                    <Checkbox
                                        checked={data.is_holiday}
                                        onChange={ (event) => setData({ ...data, is_holiday: event.target.checked }) }
                                        color="primary"
                                        style={{ pointerEvents: 'auto' }}
                                        disabled={ Number(data.type) === 1 || Number(data.type) === 2 || Number(data.type) === 7}
                                    />
                                }
                                label="Праздники"
                            />
                        </div>
                    </>
                }
            </form>

            {loadingTrafficSchedule && <Loading circular={true}/>}
        </Modal>
    );
};

export default ModalForm;

