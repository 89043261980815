import { useEffect, useState } from 'react';
import FormButtons from '../../../../../common/FormButtons';
import TextMessageItem from './TextMessageItem';
import RenderCondition from './RenderCondition';
 
const RenderTextMessage = ({
    data = [],
    onChange,
    board,
    serviceFields = {},
    microservice
}) => {
    const [serviceEventList, setServiceEventList] = useState({});
    
    useEffect(() => {
        setServiceEventList(serviceFields);
    }, [serviceFields]);

    const addNewText = () => {
        onChange([
            ...data, 
            {
                type: 'text',
                x: 0,
                y: 0,
                font: '',
                size: 24,
                text: '',
                color: '#fcff00'
            }
        ]);
    };

    const onChangeText = (i, el) => {
        const newData = [
            ...data.slice(0, i),
            el,
            ...data.slice(i + 1)
        ];
        onChange(newData);
    };

    const onDeleteText = (i) => {
        const newData = [
            ...data.slice(0, i),
            ...data.slice(i + 1)
        ];
        onChange(newData);
    };

    return (
        <>
            <h2>Текстовое сообщение:</h2> 
            {data?.map((item, index) => (
                <div 
                    key={index} 
                    style={{ 
                        backgroundColor: index % 2 === 0 ? 'rgba(155,157,176,0.2)' : 'inherit', 
                        padding: '0.5rem' 
                    }}
                >
                    <TextMessageItem
                        item={item}
                        onChange={(val) => onChangeText(index, val)}
                        onDelete={() => onDeleteText(index)}
                        serviceEventList={serviceEventList}
                        board={board}
                    />
                    <RenderCondition
                        textData={item}
                        data={item?.condition || []}
                        onChange={(val) => onChangeText(index, val)}
                        microservice={microservice}
                    />
                </div>
            ))}
            <FormButtons
                noPadding
                positionLeft
                noMarginLeft
                buttons={[
                    {
                        onClick: addNewText,
                        name: 'Добавить текст'
                    }
                ]}
            />   
        </>
    );
};

export default RenderTextMessage;