import {
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip
} from '@mui/material';
import { useValidation } from '../../../../../../../helpers/hooks';
import React, { useEffect, useState } from 'react';
import messages from '../../../../../../../helpers/constants/messages';
import DeleteIcon from '@mui/icons-material/Delete';
import titles from '../../../../../../../helpers/constants/titles';
import ConfirmDelete from '../../../../../../common/ConfirmModal';
import { loadFlightDefects } from '../../../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../../../redux/TransportPassenger';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from '../../../../../../common/Dates/DateRange';
import { fullDateTimeWithTimeZone } from '../../../../../../../helpers/date.config';

const DowntimeForm = ({ onChangeItem, onDeleteItem, index, item }) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const [isDelete, setIsDelete] = useState(false);

    const flightDefects = useSelector(transportPassengerSelectors.flightDefects);
    
    useEffect(() => {
        dispatch(loadFlightDefects({ defect_group_id: 2 }));
    }, [dispatch]);

    const changeDate = (value, name) => {
        onChangeItem({
            ...item,
            [name]: fullDateTimeWithTimeZone(value)
        });
    };

    const onChangeData = (e) => {
        const { name, value } = e.target;
        onChangeItem({
            ...item,
            [name]: value,
        });
    };

    return (<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ width: '93%' }}>
            <FormControl required className="block" size="small" variant="outlined">
                <InputLabel>Причина простоя</InputLabel>
                <Select
                    value={item.reason}
                    onChange={onChangeData}
                    name="reason"
                    error={validation.isKey('defect_id')}
                    label="Причина простоя"
                >
                    <MenuItem value={'Простой'}>
                        <em>Простой</em>
                    </MenuItem>
                    {flightDefects[2]?.map(({ id, name }) => (
                        <MenuItem key={id} value={name}>{name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="row">
                <DateRange
                    required
                    disableFuture={false}
                    valueStartDate={item.date_start}
                    valueEndDate={item.date_end}
                    handleDate={(date) => {
                        changeDate(date, 'date_start');
                        validation.deleteKey(`downtime.${index}.date_start`);
                    }}
                    handleEndDate={(date) => {
                        changeDate(date,  'date_end');
                        validation.deleteKey(`downtime.${index}.date_end`);
                    }}
                    className="row__item"
                    renderError={() =>
                        validation.isKey(`downtime.${index}.date_start`)
                        || validation.isKey(`downtime.${index}.date_end`)}
                    errorMessage={() => {
                        return (
                            <div>
                                <span>{validation.get(`downtime.${index}.date_start`)} </span>
                                <span>{validation.get(`downtime.${index}.date_end`)}</span>
                            </div>
                        );
                    }}
                />
                {/* <KeyboardTimePicker
                    className="row__item"
                    ampm={false}
                    required={true}
                    label="Время начала простоя"
                    format={formatDate.HH_MM}
                    value={item.date_start || null}
                    name="date_start"
                    cancelLabel={buttons.CANCEL}
                    okLabel={buttons.SAVE}
                    size="small"
                    inputVariant="outlined"
                    onChange={(value) => changeDate(value, 'date_start')}
                    helperText={validation.get('delivery_on_request.start')}
                />
            </div>
            <div className="row">
                <KeyboardTimePicker
                    className="row__item"
                    ampm={false}
                    required={true}
                    label="Время окончания простоя"
                    format={formatDate.HH_MM}
                    value={item.date_end || null}
                    name="date_end"
                    cancelLabel={buttons.CANCEL}
                    okLabel={buttons.SAVE}
                    size="small"
                    inputVariant="outlined"
                    onChange={(value) => changeDate(value, 'date_end')}
                /> */}
            </div>            
        </div>
        <div>
            <Tooltip title={titles.DELETE}>
                <IconButton onClick={() => setIsDelete(true)} size={'small'}>
                    <DeleteIcon fontSize={'inherit'}/>
                </IconButton>
            </Tooltip>
        </div>
        <Divider style={{ margin: '5px 0' }}/>
        { isDelete
            && <ConfirmDelete
                open={isDelete}
                onClose={()=> setIsDelete(false)}
                onSuccess={onDeleteItem}
                message={messages.CONFIRM_DELETE}
            />
        }
    </div>);
};

export default DowntimeForm;