import * as types from './types';

const initialState = {
    loadingTracking: false,
    tracking: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingMoreTracking: false,
    moreTracking: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingDeployedTracking: false,
    deployedTracking: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    trackingStatuses: {},
    loading: false,
    wanted: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    saved: false,
    typesBase: {},
    stoppedWanted: false,
    wantedEvents: {},
    eventLoading: false,
    wantedEventsFilter: {},
    vehicleWanted: {
        brand_and_model: {},
        class_name: [],
        color: []
    },
    eventWantedTrack: {},
    loadingEventWantedTrack: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_TRACKING_LIST:
            return {
                ...state,
                tracking: payload
            };
        case types.LOADING_TRACKING_LIST:
            return {
                ...state,
                loadingTracking: payload
            };
        case types.LOADING_TRACKING_MORE_INFO:
            return {
                ...state,
                loadingMoreTracking: payload
            };
        case types.LOADED_TRACKING_MORE_INFO:
            return {
                ...state,
                moreTracking: payload
            };
        case types.LOADING_TRACKING_DEPLOYED_INFO:
            return {
                ...state,
                loadingDeployedTracking: payload
            };
        case types.LOADED_TRACKING_DEPLOYED_INFO:
            return {
                ...state,
                deployedTracking: payload
            };
        case types.LOADED_TRACKING_STATUSES:
            return {
                ...state,
                trackingStatuses: payload?.data
            };
        case types.SET_LOADING: {
            return {
                ...state,
                loading: payload
            };
        }
        case types.SET_STATUS_WANTED: {
            return {
                ...state,
                saved: payload
            };
        }
        case types.LOADED_WANTED_LIST: {
            return {
                ...state,
                wanted: payload
            };
        }
        case types.LOADED_TYPES_BASE: {
            return {
                ...state,
                typesBase: payload || {}
            };
        }
        case types.STOPPED_WANTED: {
            return {
                ...state,
                stoppedWanted: payload
            };
        }
        case types.LOADING_WANTED_EVENTS: {
            return {
                ...state,
                eventLoading: payload
            };
        }
        case types.LOADED_WANTED_EVENTS: {
            return {
                ...state,
                wantedEvents: payload
            };
        }
        case types.SET_WANTED_EVENTS_REPORT_FILTER: {
            return {
                ...state,
                wantedEventsFilter: payload
            };
        }
        case types.CLEAR_WANTED_EVENTS_REPORT_FILTER: {
            return {
                ...state,
                wantedEventsFilter: {}
            };
        }
        case types.LOADED_VEHICLE_INFO: {
            return {
                ...state,
                vehicleWanted: payload
            };
        }
        case types.LOADED_EVENT_WANTED_TRACK: {
            return {
                ...state,
                eventWantedTrack: payload
            };
        }
        case types.LOADING_EVENT_WANTED_TRACK: {
            return {
                ...state,
                loadingEventWantedTrack: payload
            };
        }
        default:
            return state;
    }
}
