import React, { useEffect, useState } from 'react';
import { List, ListItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loadTagList } from '../../../../../redux/DorisControl/actions';
import messages from '../../../../../helpers/constants/messages';
import CustomPagination from '../../../../common/CustomPagination';
import { buttonsTypes } from '../../../../common/FormButtons';
import cn from 'classnames';
import FormButtons from '../../../../common/FormButtons';
import Item from './Item';
import EditModal from './Modal';
import Search from '../../../../common/Search';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import { dorisControlSelectors } from '../../../../../redux/DorisControl';

const KFVFTags = () => {
    const dispatch = useDispatch();
    const data = useSelector(dorisControlSelectors.tagListData);
    const meta = useSelector(dorisControlSelectors.tagListMeta);
    const [openModal, setOpenModal] = useState(false);

    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [params, setParams] = useState({
        name: '',
        page: meta?.current_page || 1,
        limit: storageLimit
    });

    const reloadList = () => {
        const newParams = removeEmptyFields(params);
        dispatch(loadTagList(newParams));
    };

    useEffect(()=> {
        dispatch(loadTagList(params));
    }, [dispatch, params]);

    return (
        <div >
            <h1>Теги</h1>
            <Search
                setParams={({ data: { name } }) => setParams({ ...params, name }) }
                loadParams={(value) => setParams({ ...params, name: value })}
            />
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenModal(!openModal),
                        }
                    ]}
                />
            </div>
            {openModal
                && <EditModal
                    reloadList={reloadList}
                    isOpen={openModal}
                    isNew
                    onClose={() => setOpenModal(false)}
                />
            }
            {data?.length > 0
                ? (<>
                    <List className="list">
                        {data?.map((item) => (
                            <Item item={item} key={item.id} reloadList={reloadList} />
                        ))}
                    </List>
                    <CustomPagination
                        loadList={(page, limit) => setParams({ ...params, page, limit })}
                        list={meta}
                        limit={params.limit}
                        setLimit={(limit)=> setParams({ ...params, limit })}
                    />
                </>)
                : (<div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </div>
    );
};

export default KFVFTags;
