import React, { useEffect, useMemo, useState } from 'react';
import {
    Map,
    // PolyLine,
    MapControl,
    FeatureGroup,
    GeoJson,
} from '../../../MapComponents/leaflet';
import { config } from '../../../../config';
import types from './types';
import Ws from './Components/Ws';
import History from './Components/History';
import HistoryColor from './Components/HistoryColor';
import Stations from './Components/Stations';
import Legends from './Components/Legends';
import decoratorOptions from './decoratorOptions';
import useBounds from '../../../MapComponents/leaflet/helpers/useBounds';

const ShowMapContent = ({
    historyOption
}) => {
    const { option = [], setBounds: isSetBounds = true, zoom = 0, maxZoom = null } = historyOption;

    const [mapCenter] = useState(config.get('mapCenter'));
    const mapZoom = useMemo(() => zoom ?? 15, [zoom]);

    // объединяем Bounds
    const mapBounds = useBounds();

    const handleBounds = (bounds) => {
        if (isSetBounds) {
            mapBounds.extendBounds(bounds);
        }
    };

    useEffect(() => () => {
        mapBounds.clear();
    }, []);

    return (
        <div style={{ height: '100%', padding: '1rem', position: 'relative' }}>
            {option.map(({ type, ...itemProps }, index) => {
                const key = `${type}_${index}`;

                switch (type) {
                    case types.legends :
                        return (
                            <Legends key={key} {...itemProps}/>
                        );

                    default: return null;
                }
            })}

            <Map
                center={mapCenter}
                zoom={mapZoom}
                maxZoom={maxZoom}
                {...isSetBounds ? { bounds: mapBounds.getBounds ? mapBounds.getBounds : null }: {}}
            >
                <MapControl>
                    <FeatureGroup
                        onAdd={handleBounds}
                    >
                        {option.map(({ type, ...itemProps }, index) => {
                            const key = `${type}_${index}`;

                            switch (type) {
                                case types.historyColor :
                                    return (
                                        <HistoryColor
                                            key={key}
                                            {...itemProps}
                                        />
                                    );

                                case types.route :
                                    return (
                                        <GeoJson
                                            key={key}
                                            {...itemProps}
                                            toolTipTemplate={() => <div>Маршрут</div>}
                                            toolTipOptions={{
                                                direction: 'top',
                                                offset: [0, -5],
                                                sticky: true,
                                            }}
                                            style={{
                                                color: '#02A600',
                                                weight: 5,
                                                opacity: 0.8,
                                            }}
                                            centerAfter={itemProps?.isGoTo}
                                            onAfterBounds={handleBounds}
                                            decoratorOptions={decoratorOptions}
                                        />
                                    );

                                default: return null;
                            }
                        })}
                    </FeatureGroup>

                    <FeatureGroup>
                        {option.map(({ type, ...itemProps }, index) => {
                            const key = `${type}_${index}`;

                            switch (type) {
                                case types.ws :
                                    return (
                                        <Ws
                                            key={key}
                                            {...itemProps}
                                            // toolTipTemplate={() => <div>ТС</div>}
                                            // toolTipOption={{
                                            //     direction: 'top',
                                            //     offset: [0, -5],
                                            //     sticky: true,
                                            // }}
                                        />
                                    );

                                case types.stations :
                                    return (
                                        <Stations
                                            key={key}
                                            {...itemProps}
                                            mapZoom={mapZoom}
                                        />
                                    );

                                case types.history :
                                    return (
                                        <History
                                            key={key}
                                            {...itemProps}
                                        />
                                    );

                                default: return null;
                            }
                        })}
                    </FeatureGroup>
                </MapControl>
            </Map>
        </div>
    );
};

export default ShowMapContent;
