import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import { loadStatus, loadTypes } from 'redux/TrafficFlow/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import { useStoreFromSelector } from 'helpers/hooks';
import titles from 'helpers/constants/titles';


const Form = ({ data, onChange }) => {
    const getValue = (key) => data?.[key] || '';
    const statuses = useStoreFromSelector(loadStatus, trafficFlowSelectors.statuses);
    const types = useStoreFromSelector(loadTypes, trafficFlowSelectors.types);

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({
            [name]: value,
        });
    };

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >

            <div className="block">
                <TextField
                    label={titles.NAME}
                    value={getValue('name') || ''}
                    onChange={handleChange}
                    name="name"
                    fullWidth
                    className="row__item"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {getValue('name').length > 0 ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => onChange({ name: '' })}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>x</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />
            </div>

            <div className="block">
                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    className="row__item"
                >
                    <InputLabel>Статус</InputLabel>
                    <Select
                        displayEmpty
                        fullWidth
                        labelId="status-label"
                        value={data?.status?.id || ''}
                        name="status"
                        // onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                        onChange={({ target: { value } }) => onChange({ status: statuses.find(el => el.id === value) })}
                        label="Статус"
                        variant="outlined"
                    >
                        {statuses?.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <div className="block">
                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    className="row__item"
                >
                    <InputLabel>Тип</InputLabel>
                    <Select
                        displayEmpty
                        fullWidth
                        labelId="create-label"
                        value={data?.type_id?.id || ''}
                        name="status"
                        // onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                        onChange={({ target: { value } }) => onChange({ type_id: { id: value, name: types[value] } })}
                        label="Тип"
                        variant="outlined"
                    >
                        {Object.keys(types).map((id) => (
                            <MenuItem key={id} value={id}>
                                {types[id]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </form>
    );
};

export default Form;
