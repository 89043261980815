import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadCameraPddViolations } from '../../redux/DorisControl/actions';
import { dorisControlSelectors } from '../../redux/DorisControl';

const usePDDViolations = (item = {}) => {
    const dispatch = useDispatch();
    const cameras_pdd_violations = useSelector(dorisControlSelectors.cameras_pdd_violations);
    const ref = useRef([]);

    const currentViolations = Object.keys(item.pdd_violations || {}).reduce((res, key) => {
        if (cameras_pdd_violations?.[key]) {
            res.push(cameras_pdd_violations?.[key]);
        }
        return res;
    }, []);

    useEffect(() => {
        // проверяем загружены ли уже эти пдд
        const pddKeys = Object.keys(item.pdd_violations || {}).reduce((res, key) => {
            const refKey = ref.current.find(el => el === key);
            if (!cameras_pdd_violations?.[key] && !refKey) {
                res.push(key);
                ref.current.push(key);
            }
            return res;
        }, []);
        if (pddKeys.length > 0) {
            dispatch(loadCameraPddViolations(item.id, {
                ids: pddKeys
            }));
        }        
    },[dispatch, item.pdd_violations, item.id, cameras_pdd_violations, ref]);

    useEffect(() => {
        return () => {
            ref.current = [];
        };
    }, []);

    return currentViolations;
};

export default usePDDViolations;