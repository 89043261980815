import { useEffect } from 'react';

// useLeafletEvents((e) => {
//     // setZoom(e.target.getZoom());
//     setShowJson(isShowJson(map.getZoom()));
// }, map, [
//     'zoomend'
// ]);

const useLeafletEvents = (fn, map, events = []) => {
    const execute = (e) => fn(e);

    useEffect(() => {
        // connects
        events.map((item) => map.on(item, execute));

        // disconnects
        return () => events.map((item) => map.off(item, execute));
    }, [events, fn]);

    return null;
};

export default useLeafletEvents;
