export const transportRoad = (state) => state.transportRoad;

export const sidebar = (state) => transportRoad(state).sidebar;
// export const sidebarData = (state) => sidebar(state).data;
// export const sidebarMeta = (state) => sidebar(state).meta;
export const sidebarLoading = (state) => transportRoad(state).sidebarLoading;

export const sidebarParams = (state) => transportRoad(state).sidebarParams;

export const filters = (state) => transportRoad(state).filters;
export const active = (state) => transportRoad(state).active;
export const wsData = (state) => transportRoad(state).wsData;
export const routesHistory = (state) => transportRoad(state).routesHistory;

// export const coords = (state) => transportRoad(state).coords;
export const coordsLoading = (state) => transportRoad(state).coordsLoading;

// export const allVehicle = (state) => transportRoad(state).allVehicle;
export const allVehicleLoading = (state) => transportRoad(state).allVehicleLoading;
// export const types = (state) => transportRoad(state).types;

export const history = (state) => transportRoad(state).history;
export const historyLoading = (state) => transportRoad(state).historyLoading;

// export const colorSpeed = (state) => transportRoad(state).colorSpeed;
// export const colorSpeedList = (state) => transportRoad(state).colorSpeedList;
// export const colorSpeedListLoading = (state) => transportRoad(state).colorSpeedListLoading;
// export const colorSpeedListProps = (state) => transportRoad(state).colorSpeedListProps;
// export const categoriesVehicle = (state) => transportRoad(state).categoriesVehicle;
// export const categoriesVehicleLoaded = (state) => transportRoad(state).categoriesVehicleLoaded;
export const historyModal = (state) => transportRoad(state).historyModal;
// export const historyModalLoading = (state) => transportRoad(state).historyModalLoading;

export const vehicleActive = (state) => transportRoad(state).vehicleActive;
export const findVehicleLoading = (state) => transportRoad(state).findVehicleLoading;

export const showFindVehicles = (state) => transportRoad(state).showFindVehicles;

export const brandsLoading = (state) => transportRoad(state).brandsLoading;
export const brands = (state) => transportRoad(state).brands;
export const brandsData = (state) => brands(state).data;
export const brandsMeta = (state) => brands(state).meta;

export const modelsLoading = (state) => transportRoad(state).modelsLoading;
export const models = (state) => transportRoad(state).models;
export const modelsData = (state) => models(state).data;
export const modelsMeta = (state) => models(state).meta;

// export const companies = (state) => transportRoad(state).companies;
// export const companiesLoading = (state) => transportRoad(state).companiesLoading;

export const vehicles = (state) => transportRoad(state).vehicles;
export const vehiclesData = (state) => vehicles(state).data;
export const vehiclesMeta = (state) => vehicles(state).meta;
export const vehicleLoading = (state) => transportRoad(state).vehicleLoading;

export const vehicleReport = (state) => transportRoad(state).vehicleReport;
export const vehicleReportData = (state) => vehicleReport(state).data;
export const vehicleReportMeta = (state) => vehicleReport(state).meta;
export const loadingVehicleReport = (state) => transportRoad(state).loadingVehicleReport;
export const vehicleReportFilter = (state) => transportRoad(state).vehicleReportFilter;

// export const vehiclesTypes = (state) => transportRoad(state).vehiclesTypes;
// export const vehicleCategories = (state) => transportRoad(state).vehicleCategories;

export const vehicleCategoryList = (state) => transportRoad(state).vehicleCategoryList;
export const loadingVehicleCategoryList = (state) => transportRoad(state).loadingVehicleCategoryList;

// export const employees = (state) => transportRoad(state).employees;
// export const employeesLoading = (state) => transportRoad(state).employeesLoading;
// export const positions = (state) => transportRoad(state).positions;
// export const positionsLoading = (state) => transportRoad(state).positionsLoading;
// export const brigades = (state) => transportRoad(state).brigades;
// export const brigadesLoading = (state) => transportRoad(state).brigadesLoading;

export const parks = (state) => transportRoad(state).parks;
export const parksData = (state) => parks(state).data;
export const parksMeta = (state) => parks(state).meta;
export const loadingParks = (state) => transportRoad(state).loadingParks;

export const vehicleDowntime = (state) => transportRoad(state).vehicleDowntime;
export const vehicleDowntimeData = (state) => vehicleDowntime(state).data;
export const vehicleDowntimeMeta = (state) => vehicleDowntime(state).meta;

export const reportFuel = (state) => transportRoad(state).reportFuel;
export const reportFuelData = (state) => reportFuel(state).data;
export const reportFuelMeta = (state) => reportFuel(state).meta;
// export const loadingReportFuel = (state) => transportRoad(state).loadingReportFuel;

export const routes = (state) => transportRoad(state).routes;
export const routesData = (state) => routes(state).data;
export const routesMeta = (state) => routes(state).meta;
export const loadingRoutes = (state) => transportRoad(state).loadingRoutes;

// export const routeTypes = (state) => transportRoad(state).routeTypes;
// export const routeStatuses = (state) => transportRoad(state).routeStatuses;

export const routeCheckpointsLoading = (state) => transportRoad(state).routeCheckpointsLoading;
export const routeCheckpoints = (state) => transportRoad(state).routeCheckpoints;

export const telemetryReceivers = (state) => transportRoad(state).telemetryReceivers;
export const telemetryReceiversData = (state) => telemetryReceivers(state).data;
export const telemetryReceiversMeta = (state) => telemetryReceivers(state).meta;
// export const telemetryReceiversLoading = (state) => transportRoad(state).telemetryReceiversLoading;

export const telemetryReceiverTypes = (state) => transportRoad(state).telemetryReceiverTypes;

// workorders
export const loadingWorkorders = (state) => transportRoad(state).loadingWorkorders;
export const workorders = (state) => transportRoad(state).workorders;
export const workordersData = (state) => workorders(state).data;
export const workordersMeta = (state) => workorders(state).meta;

export const loadingWorkordersByDate = (state) => transportRoad(state).loadingWorkordersByDate;
export const workordersByDate = (state) => transportRoad(state).workordersByDate;
export const workordersByDateData = (state) => workordersByDate(state).data;
export const workordersByDateMeta = (state) => workordersByDate(state).meta;

export const loadingWorkorderLogs = (state) => transportRoad(state).loadingWorkorderLogs;
export const workorderLogs = (state) => transportRoad(state).workorderLogs;
export const workorderLogsData = (state) => workorderLogs(state).data;
export const workorderLogsMeta = (state) => workorderLogs(state).meta;
export const workorderManualStatusList = (state) => transportRoad(state).workorderManualStatusList;

export const checkPoints = (state) => transportRoad(state).checkPoints;
export const checkPointsData = (state) => checkPoints(state).data;
export const checkPointsMeta = (state) => checkPoints(state).meta;
export const checkPointsLoading = (state) => transportRoad(state).checkPointsLoading;

export const reportVehicleMileage = (state) => transportRoad(state).reportVehicleMileage;
export const loadingReportByVehicles = (state) => transportRoad(state).loadingReportByVehicles;

export const historyOption = (state) => transportRoad(state).historyOption;
export const checkPointsPolygon = (state) => transportRoad(state).checkPointsPolygon;

export const vehicleStatistics = (state) => transportRoad(state).vehicleStatistics;
export const loadingVehicleStatistics = (state) => transportRoad(state).loadingVehicleStatistics;

export const workorderStatistics = (state) => transportRoad(state).workorderStatistics;
export const loadingWorkorderStatistics = (state) => transportRoad(state).loadingWorkorderStatistics;
