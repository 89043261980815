import { useEffect, useMemo, useState } from 'react';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';
import { getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import { paramsWithoutEmpty, renderError, typeOptions } from './CommonFilter/helpers';
import CommonFilter from './CommonFilter';


const Filters = ({
    reportGroups,
    reportTypes,
    onLoad,
    onDisabled,
    onReset,
    initialState
}) => {

    const [parameters, setParameters] = useState([]);
    const [formValues, setFormValues] = useState({
        ...initialState,
    });

    const disableRequestBtn = useMemo(() => {
        const { time_slot, report_type, start_date, end_date, type_id_list } = paramsWithoutEmpty(formValues);

        return !time_slot
            || !start_date
            || !end_date
            || renderError(formValues, 'start_date', 'end_date', parameters, reportTypes)?.error;
    }, [formValues, parameters, reportTypes]);

    const resetFilters = (needRefresh) => {
        if (needRefresh) {
            onReset?.();
        }
        setFormValues(initialState);
        onDisabled?.(true);
    };

    const getFiltered = (filter) => {
        onLoad?.(filter || formValues);
        onDisabled?.(disableRequestBtn);
    };

    const updateFilter = (filters) => {
        const result = {
            ...initialState,
            ...filters,
        };
        if (filters.time_slot) {
            const addDate = typeOptions[filters.time_slot];
            const { count, unit } = addDate;

            result.start_date = getStartOf('day', getDateWithDuration({ [unit]: -count, second: -1 }));
            result.end_date = getEndOf('day');
        }

        setFormValues(result);
        if (Object.keys(filters).length > 0) {
            getFiltered(result);
            onDisabled?.(false);
        }
    };

    const [resetParameter, setResetParameter] = useState(true);

    useEffect(() => {
        if (
            resetParameter
            && reportTypes?.length > 0
            // && parameters?.length === 0
        ) {
            const time_slot = formValues?.time_slot;
            const parametersCurrent = reportTypes?.find(({ value }) => value === time_slot);
            setParameters(parametersCurrent?.parameters || []);
            setResetParameter(false);
        }
    }, [formValues?.time_slot, reportTypes, resetParameter]);

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={getFiltered}
            filter={formValues}
            filterException={['start_date', 'end_date']} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
            disabled={disableRequestBtn}
            isFilterVisible
        >
            <LayoutFilter.Visible>
                <CommonFilter
                    formValues={formValues}
                    setFormValues={setFormValues}
                    parameters={parameters}
                    setParameters={setParameters}
                    reportGroups={reportGroups}
                    reportTypes={reportTypes}
                    showFieldPuid
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;
