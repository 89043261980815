import { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import cn from 'classnames';

import styles from './styles.module.scss';

interface SortingProps {
    options: { field: string };
    onChange: (key: string, direction: 'asc' | 'desc') => void;
}

function Sorting({ onChange, options }: SortingProps) {
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>();

    const handleChangeSort = (direction: 'asc' | 'desc') => {
        setSortDirection(direction);
        onChange(options.field, direction);
    };

    const renderIcon = (direction: 'down' | 'up', isActive: boolean) => {
        const iconClass = `fas fa-long-arrow-alt-${direction}`;
        return <i className={cn(iconClass, styles.sortIcon, { [styles.active]: isActive })} />;
    };

    return (
        <Tooltip title={`Сортировать по ${sortDirection === 'asc' ? 'убыванию' : 'возрастанию'}`}>
            <IconButton
                disableRipple
                onClick={() => handleChangeSort(sortDirection === 'asc' ? 'desc' : 'asc')}
            >
                {renderIcon('up', sortDirection === 'asc')}
                {renderIcon('down', sortDirection === 'desc')}
            </IconButton>
        </Tooltip>
    );
}

export default Sorting;
