import React from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from '../../../redux/Auth';
import { getLocalTZOffset } from '../../../helpers/date.config';
import Clock from '../Clock';

const ProjectTime = ({ onlyProject = false, withTitle = true, className }) => {
    const project = useSelector(authSelectors.project);
    const projectTimezone = project?.timezone; // offset строка вида +03:00
    const browserTimezone = getLocalTZOffset();// offset строка вида +03:00
    return (
        <>
            <div className={ className ?? 'block'}>
                { withTitle && <h3 style={{ margin: 0, padding: 0 }}>Время проекта</h3>}
                <Clock 
                    format={'d MMMM yyyy, HH:mm:ss'} 
                    style={{ fontSize: '0.9rem' }}
                    timezone={projectTimezone}
                />    
            </div>
            {browserTimezone !== projectTimezone && !onlyProject
                && <div className="block">
                    { withTitle && <h3 style={{ margin: 0, padding: 0 }}>Время АРМ</h3> }
                    <Clock 
                        format={'d MMMM yyyy, HH:mm:ss'} 
                        style={{ fontSize: '0.9rem' }}
                        timezone={browserTimezone}
                    />    
                </div>
            }
        </>
    );
};

export default ProjectTime;
