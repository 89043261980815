import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Checkbox,
    FormControlLabel,
    Icon,
    IconButton,
    LinearProgress,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import cn from 'classnames';
import _ from 'lodash';

import messages from 'helpers/constants/messages';
import { useDebounce, usePrevious } from 'helpers/hooks';
import CustomPagination from 'components/common/CustomPagination';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';



import styles from './universalselect.module.scss';

import type { UniversalSelectProps } from './types';


const UniversalSelect = ({
    storeName,
    storeNameProps,
    withSearch,
    multiple,
    keyProp,
    className,
    selected,
    fetchList,
    isSelected,
    searchTitle = 'Найти',
    sortedList, // если не надо делать запрос и у нас есть список
    storeLoadingProps,
    renderProps,
    renderIconProps = null,
    renderKey = null,
    notFoundText = messages.NOTHING_FOUND,
    selectDeletedItem = false, // возможность выбрать в селекте удаленную
    isOpen = false,
    onClose = () => {},
    title = '',
    noPadding = false,
    small = false,
    onAccept,
    buttons = null,
    additionalModalButtons = () => null,
    limitedNumberItems = 0,
    dynamicTitle = false,
    renderSearch = null, // если нужен другой поиск, например дата-пикер
    medium,
    selectAll,
    onSelectAll = null,
    selectAllChecked = false,
    onChange = () => {},
    arrayInItem = '',
    readOnly = false, // если надо просто посмотреть список
    additionalItem,
    test_id_prefix = '',
    disableFunc = () => false
}: UniversalSelectProps) => {
    const [query, setQuery] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const prevData = useRef(selected);
    const [show, setShow] = useState(false);
    const [selectedItems, setSelected] = useState(selected);

    const currentTitle = dynamicTitle
        ? `${title} ${selectedItems.length}/${limitedNumberItems}`
        : title;

    const storeList = useSelector((state: any) => !sortedList?.length ? state?.[storeName]?.[storeNameProps] || {} : {});
    const list = useMemo(() => sortedList || storeList?.data || [], [storeList, sortedList]); // список всегда должен быть массивом, чтобы нам не писать ниже list?.data
    const meta = storeList?.meta || {};

    useEffect(() => {
        // сброс фильтра - selected изменился нужно обновить selectedItems
        if (!_.isEqual(prevData?.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    // если null - не прокинуто
    const listLoading = useSelector((state: any) =>
        storeLoadingProps ? state[storeName][storeLoadingProps] : null
    );
    const listLoadingPrev = usePrevious(listLoading);
    const limited = (limitedNumberItems > 0) ? (selectedItems.length < limitedNumberItems) : true;

    useEffect(() => {
        !sortedList?.length && fetchList && fetchList({ page, limit, ...(query && { query }) });
    }, []);

    useEffect(() => {
        if (!show && (!listLoading || (listLoadingPrev && !listLoading))) {
            setShow(true);
        }
    }, [show, listLoading, listLoadingPrev]);

    const fetchDebounce = useDebounce((props: { page: number; limit: number; query?: string }) => {
        setPage(1);
        fetchList && fetchList(props);
    }, 1000);

    const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setQuery(value);
        fetchList && fetchDebounce({ page: 1, limit, query: value });
    };

    const handleChangeCustomSearch = (value: string = '') => {
        setQuery(value);
        fetchList && fetchDebounce({ page: 1, limit, query: value });
    };

    const checkSelected = (item: { [x: string]: any; }) => {
    // если этот элемент имеет внутри список элементов в массиве = например статьи ПДД с
    // тогда поле arrayInItem будет parts и мы будем проходить по нему и также проверять
    // чекнуты там элменеты или нет
        const comparison = (el: any) => {
            return multiple
                ? selectedItems?.some((selectItem: any) =>
                    keyProp ? el?.[keyProp] === selectItem?.[keyProp] : el === selectItem
                )
                : el?.[keyProp] === selectedItems?.[keyProp];
        };
        const isArray = Array.isArray(item[arrayInItem]);
        return isArray
            ? item[arrayInItem].every((el: any) =>
                el[arrayInItem]
                    ? checkSelected(el)
                    : comparison(el)
            )
            : comparison(item);
    };

    const handleChange = (el: any, index: number | undefined) => {
        if (multiple) {
            // находим индекс элемента в выбранных
            const findIndex = selectedItems.findIndex((item: { [x: string]: any; }) => item?.[keyProp] === el?.[keyProp]);
            if (findIndex < 0 && limited) {
                if (Array.isArray(el)) { // странный код требует изучения как элемент может быть массивом
                    setSelected(el);
                    setCheckedOnCurrentPage(el);
                } else {
                    // мы должны вставить элемент в правильное место в рамках текущей страницы,
                    // а не просто в конец списка
                    // поэтому ищем сначала есть ли еще элементы на этой странице и выстраиваем их в правильном порядке
                    const elementsOnCurrentPage = list.reduce((result: any[], elem: any, i: number) => {
                        if (i === index) {
                            result.push(el);
                        } else if (checkedOnCurrentPage.findIndex((e: any ) => e?.[keyProp] === elem?.[keyProp]) >= 0) {
                            result.push(elem);
                        }
                        return result;
                    }, []);

                    // теперь попробуем поставить elementsOnCurrentPage в нужное место списка , если например
                    // мы сейчас на 2 странице а еще были выбраны элементы на 1 и 3
                    // ищем индекс первого найденного элемента с текущей страницы в selectedItems
                    // и ставим наш массив elementsOnCurrentPage начиная с этого индекса
                    // если это единственный добавленный на этой странице элемент мы вынуждены его поставить в конец списка
                    // т.к. не знаем какие элементы в selectedItems идут до него а какие после
                    const findSartIndex = selectedItems.findIndex((elem: any) => {
                        return elem?.[keyProp] === elementsOnCurrentPage.find((e: any) => e?.[keyProp] === elem?.[keyProp]);
                    });

                    const newSelectedArray = findSartIndex < 0
                        ? [
                            ...selectedItems,
                            el,
                        ]
                        : [
                            ...selectedItems.slice(0, findSartIndex),
                            ...elementsOnCurrentPage,
                            ...selectedItems.slice(findSartIndex + 1),
                        ];

                    setSelected(newSelectedArray);
                    setCheckedOnCurrentPage(elementsOnCurrentPage);
                }
            } else {
                setSelected(selectedItems.filter((item: { [x: string]: any; }) => item?.[keyProp] !== el?.[keyProp]));
                setCheckedOnCurrentPage((prev: any[]) => prev.filter((item) => item?.[keyProp] !== el?.[keyProp]));
            }
        } else {
            setSelected(el);
        }
    };

    const additionalItemHandleChange = (item: { name: string; value: null}) => setSelected(multiple ? [item] : item);

    const renderIcon = (item: any) => {
        const checked = checkSelected(item);
        return (
            <Icon
                component="span"
                className={checked ? 'far fa-check' : 'far fa-plus'}
                sx={{ color: isSelected ? 'green' : 'blue' }}
                data-testid={`${test_id_prefix}/modal/list/icon/${checked ? 'selected' : 'unselected'}`}
            />
        );
    };

    const filterCheckedOnPage = useCallback((arrayForFilter: any[], currentList: any[], onlyCurrent = false) => {
        const filteredArray = arrayForFilter.filter(item => onlyCurrent
            ? currentList.findIndex((el: any) => el?.[keyProp] === item?.[keyProp]) >= 0 // оставляем только элементы с тееущей страницы
            : currentList.findIndex((el: any) => el?.[keyProp] === item?.[keyProp]) < 0 // выкидываем все с текущей страницы
        );

        return filteredArray;
    }, [keyProp]);

    // переменная для отслеживания что мы выбираем на текущей странице - для случая когда есть чекбокс Выбрать все
    const [checkedOnCurrentPage, setCheckedOnCurrentPage] = useState<any[] | []>([]);

    useEffect(() => {
        if (multiple && list.length > 0) { // чтобы не гонять функцию впустую проверяем на текущей странице только когда страница загрузилась
            setCheckedOnCurrentPage(filterCheckedOnPage(selectedItems, list, true));
        }
    }, [filterCheckedOnPage, multiple, selectedItems, list]);

    const handleChoose = () => {
        onAccept && onAccept(selectedItems);
    };

    const handleCheckAll = () => {
        if (onSelectAll) {
            // если у нас есть кастомная функция выбора всех
            onSelectAll();
            return;
        }

        // если мы на текущей страниwе кликнули Выбрать все
        // проверяем
        if (checkedOnCurrentPage.length === limit) {
            // если в чекнутых на данной странице все и так уже выбраны - надо со всех
            // снять отметки на текущей стрнице, оставить только выбранные на других страницах
            setSelected((prev: any[]) => {
                return prev.filter((item: any ) => checkedOnCurrentPage.findIndex((el: any ) => el?.[keyProp] === item?.[keyProp]) < 0);
            });
            // выбранные на текущей странице = []
            setCheckedOnCurrentPage([]);
        } else {
            // если на текущей сттранице выбраны не все
            // мы убираем из selected элементы с текущей странице и заново добавляем
            // уже все элементы текущей страницы
            // т.к. мы не знаем с какой страницы у нас все остальные элементы, то просто добавляем список в конец
            setSelected((prev: any[]) => {
                return [...filterCheckedOnPage(prev, list, false), ...list];
            });
            // выбранные на текущей странице = все элементы текущей страницы
            setCheckedOnCurrentPage(list);
        }
    };

    const additionalButtons = additionalModalButtons(handleChange) || [];

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={currentTitle}
            noPadding={noPadding}
            small={small}
            medium={medium}
            buttons={<FormButtons
                buttons={[
                    ...additionalButtons,
                    {
                        ...(readOnly ? buttonsTypes.close : buttonsTypes.cancel),
                        onClick: onClose,
                        'data-testid': `${test_id_prefix}/modal/button/cancel`
                    },
                    ...(readOnly
                        ? []
                        : [{
                            ...buttonsTypes.select,
                            onClick: handleChoose,
                            disabled: _.isEmpty(selectedItems),
                            'data-testid': `${test_id_prefix}/modal/button/select`
                        }]),
                ]}
            />
            }
        >
            {buttons}
            <div className={cn(styles.wrapper, className)}>
                {withSearch && (
                    <div className="row">
                        {renderSearch
                            ? renderSearch({
                                value: query,
                                onChange: handleChangeCustomSearch,
                                className: 'row__item'
                            })
                            : <div className={`row__item ${styles.search}`}>
                                <input
                                    size={10}
                                    id={'searchId'}
                                    type={'text'}
                                    name={'searchId'}
                                    value={query}
                                    onChange={handleChangeSearch}
                                    placeholder={searchTitle}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }}
                                    data-testid={`${test_id_prefix}/modal/search/input`}
                                />
                            </div>
                        }
                        {query && (
                            <IconButton
                                size="small"
                                onClick={() => handleChangeCustomSearch()}
                                data-testid={`${test_id_prefix}/modal/search/button/reset`}
                            >
                                {/* для сброса поиска используем handleChangeCustomSearch т.к. она и истановит value в пустую стооку
                                и перезапросит список  */}
                                <CloseIcon />
                            </IconButton>
                        )}
                    </div>
                )}
                {listLoading && (
                    <LinearProgress className={styles.loading} />
                )}
                {show
                    && (<>
                        {list.length > 0
                            ? <>
                                {(selectAll && multiple) && (
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={checkedOnCurrentPage.length === limit || selectAllChecked}
                                            onChange={handleCheckAll}
                                        />}
                                        label={checkedOnCurrentPage.length === limit ? 'Снять отметки' : 'Выбрать все'}
                                    />
                                )}
                                <List className="list">
                                    {additionalItem &&(
                                        <ListItemButton
                                            dense
                                            divider
                                            alignItems={arrayInItem ? 'flex-start' : 'center'}
                                            onClick={() => arrayInItem ? {} : additionalItemHandleChange(additionalItem)}
                                        >
                                            <ListItemIcon>
                                                <IconButton
                                                    onClick={() => arrayInItem
                                                        ? onChange(additionalItem)
                                                        : additionalItemHandleChange(additionalItem)
                                                    }
                                                    disabled={readOnly}
                                                >
                                                    {renderIcon(additionalItem)}
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography component="div">
                                                    {additionalItem?.name}
                                                </Typography>
                                            </ListItemText>
                                        </ListItemButton >
                                    )}
                                    {list.map((item: { [x: string]: React.Key | null | undefined; deleted_at: any; }, index: number) => (
                                        <ListItemButton
                                            dense
                                            divider
                                            key={renderKey ? renderKey(item) : item[keyProp]}
                                            disabled={(selectDeletedItem ? false : !!item.deleted_at) || disableFunc(item)}
                                            alignItems={arrayInItem ? 'flex-start' : 'center'}
                                            onClick={() => arrayInItem ? {} : handleChange(item, index)}
                                        >
                                            <ListItemIcon>
                                                <IconButton
                                                    onClick={() => arrayInItem
                                                        ? onChange(item)
                                                        : handleChange(item, index)
                                                    }
                                                    disabled={readOnly}
                                                    data-testid={`${test_id_prefix}/modal/list/icon/button`}
                                                >
                                                    {renderIcon(item)}
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography component="div">
                                                    {renderProps && renderProps(item)}
                                                </Typography>
                                            </ListItemText>
                                            {renderIconProps?.(item)}
                                        </ListItemButton >
                                    ))}
                                </List>
                                {listLoading && (
                                    <LinearProgress className={styles.loading} />
                                )}
                                <CustomPagination
                                    loadList={(page: number, limit: number) => fetchList
                                        ? fetchDebounce({ page, limit, query })
                                        : null
                                    }
                                    list={meta}
                                    limit={limit}
                                    setLimit={setLimit}
                                    isUniversal={true}
                                />
                            </>
                            : (!listLoading && <div>{notFoundText}</div>)
                        }
                    </>)
                }
            </div>
        </Modal>
    );
};

export default UniversalSelect;
