import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import MediaModal from 'components/common/DorisControl/MediaModalList';
import RenderEventInfo from 'components/common/DorisControl/RenderEventInfo';

const ShowActiveEvent = () => {
    const activeEvent = useSelector(dorisControlSelectors.activeEvent);

    const dispatch = useDispatch();

    return (
        <>
            { !!activeEvent
                && <MediaModal
                    isOpen={!!activeEvent}
                    onClose={() => dispatch(actions.setActiveEvent(null))}
                    item={activeEvent}
                    renderLabels={(data) => <RenderEventInfo event={data} />}
                    filter={{ complex_id: [parseInt(activeEvent?.complex_id)] }}
                    readOnly
                />
            }
        </>
    );
};
export default ShowActiveEvent;
