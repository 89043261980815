import L from 'leaflet';
import iconMap from './icons/iconMap';
import config from './config';

// цвет иконки от статуса
export const getColorByStatus = (status) => {
    return config.mapMarkersColors[status] || config.mapMarkersColors.default;
};

// получить иконку маркера
export const createIconMarker = (status) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: iconMap({ fill: getColorByStatus(status) })
    });
};

// export const getFilter = (filter) => {
//     const filters = {
//         ...filter,
//         type_id: filter.type_id ? filter.type_id.id : null,
//     };
//     if (filters.camera) delete filters.camera;
//
//     const paramsWithoutEmpty = reduce(
//         filters,
//         (res, el, key) => {
//             if (el) {
//                 res[key] = el;
//             }
//             return res;
//         },
//         {}
//     );
//
//     return paramsWithoutEmpty;
// };
