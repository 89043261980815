import { useState } from 'react';
import { Popover, } from '@mui/material';
import { makeStyles } from '@mui/styles';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

const useStyles = makeStyles({
    block: {
        display: 'flex',
        minWidth: '130px',
    },
    item: {
        padding: '0.5rem 1rem',

    }
});
// компонент обертка для кнопки, по клику на которрую под ней появляется панель
// в которой мы можем что-то нажать - получить отчет, или загрузить что-то
// для примера см. Пассажирский транспорт - Модули - ТС
const ButtonWithPopover = ({
    actions,
    buttonType,
    buttonName,
    startIcon,
    endIcon,
    test_id_prefix = '',
    testName = '',
}) => {
    const styles = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<>
        <FormButtonsComponent
            noPadding
            noMarginLeft
            buttons={[
                {
                    ...buttonsTypes[buttonType],
                    onClick: handleOpen,
                    ...(buttonName ? { name: buttonName } : {}),
                    ...(startIcon ? { startIcon } : {}),
                    ...(endIcon ? { endIcon } : {}),
                    ...(testName ? { testName } : {}),
                },
            ]}
            test_id_prefix={test_id_prefix}
        />
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            classes={{ paper: styles.block }}
        >
            <div className={styles.item}>
                {/* какой список по клику на элемент происходит какое-то действие + прокидываем в него закрытие popover*/}
                {actions(handleClose)}
            </div>
        </Popover>
    </>);
};

export default ButtonWithPopover;
