import * as types from './types';

export const loadWorkRequestList = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_WORK_REQUEST_LIST,
    payload: { page, limit, query }
});

export const loadedWorkRequestList = (data) => ({
    type: types.LOADED_WORK_REQUEST_LIST,
    payload: data
});

export const loadingWorkRequestList = (bool = false) => ({
    type: types.LOADING_WORK_REQUEST_LIST,
    payload: bool
});

export const createWorkRequest = (data) => ({
    type: types.CREATE_WORK_REQUEST,
    payload: data
});

export const editWorkRequest = (id, data) => ({
    type: types.EDIT_WORK_REQUEST,
    payload: { id, data }
});

export const deleteWorkRequest= (id, callback) => ({
    type: types.DELETE_WORK_REQUEST,
    payload: id,
    callback
});

export const loadWorkRequestStatuses = () => ({
    type: types.LOAD_WORK_REQUEST_STATUSES
});

export const loadedWorkRequestStatuses = (data) => ({
    type: types.LOADED_WORK_REQUEST_STATUSES,
    payload: data
});

export const changeWorkRequestStatus = (id, data) => ({
    type: types.CHANGE_WORK_REQUEST_STATUS,
    payload: { id, data }
});

export const appointWorkRequest = (id, data) => ({
    type: types.APPOINT_WORK_REQUEST,
    payload: { id, data }
});
