import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidation } from 'helpers/hooks';
import { 
    dateWithDashYYYYMMDD, 
    getStartOf,
    getDateWithDuration,
} from 'helpers/date.config';
import { loadThroughput } from 'redux/TrafficFlow/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import DateRange from 'components/common/Dates/DateRange';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';
import AnalysisData from './AnalysisData';

const DatesModal = ({ isOpen, onClose, detectors = [] }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        start_time: dateWithDashYYYYMMDD(getStartOf('day', getDateWithDuration({ month: -1 }))), // месяц назад от текущей даты
        end_time: dateWithDashYYYYMMDD(new Date())
    };

    const throughputLoading = useSelector(trafficFlowSelectors.throughputLoading);
    
    const [formData, setFormData] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [openAnalysis, setOpenAnalysis] = useState(false);

    const handleChange = (value, key) => {
        setFormData({
            ...formData, 
            [key]: value
        });
        
        validation.deleteKey(key);
    };

    const handleSearch = () => {
        const formattedData = {
            start_time: dateWithDashYYYYMMDD(formData.start_time),
            end_time: dateWithDashYYYYMMDD(formData.end_time),
        };
        dispatch(loadThroughput(
            {
                detector_ids: detectors?.map(el => el.id), 
                ...formattedData
            }, 
            () => setOpenAnalysis(true))
        );
    };

    const errorDateRange = () => (
        !formData.start_time
        || !formData.end_time
        || validation.isKey('start_time')
        || validation.isKey('end_time')        
    );

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                medium
                title={detectors?.length > 0 ? 'Анализ пропускной способности' : 'У данного участка работ нет присвоенных детекторов ПУИД'}
                buttons={
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                name: 'Закрыть',
                                onClick: onClose
                            },
                            {
                                ...buttonsTypes.search,
                                onClick: handleSearch,
                                disabled: isDisabled || detectors?.length === 0
                            },
                        ]}
                    />
                }
            >
                {detectors?.length > 0
                   && <>
                       {throughputLoading && <Loading circular />}
                       <h3>Задайте период</h3>
                       <div className="row">
                           <DateRange
                               className="row__item"
                               valueStartDate={formData.start_time}
                               valueEndDate={formData.end_time}
                               handleDate={(value) => handleChange(value, 'start_time')}
                               handleEndDate={(value) => handleChange(value, 'end_time')}
                               isDisabledHandler={(error) => setIsDisabled(error)}
                               renderError={errorDateRange}
                               errorMessage={() => validation.get('start_time') || validation.get('end_time')}
                               required
                               dateOnly
                               disableFuture={false}
                           />
                       </div>
                       {openAnalysis && (
                           <AnalysisData 
                               detectors={detectors}
                           />
                       )}
                   </> 
                }
            </Modal>
        </>   
    );
};

export default DatesModal;
