import { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
    IconButton,
} from '@mui/material';

import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import { loadRouteTypes } from 'redux/TransportRoad/actions';
import { deleteRoute } from 'redux/TransportRoad/actions';
import ConfirmModal from 'components/common/ConfirmModal';
import {
    LSContentColumn,
    LSContentItem,
    ActionsButtons,
} from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import AddEditCopyModal from './AddEditCopyModal';
import EditStatusModal from './EditStatusModal';
import ShowMapModal from './ShowMapModal';

function RouteItem({ data, typeTransportation, onClickItem, selectedId, reloadList, checkRoute, focus }) {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalStatusOpen, setModalStatusOpen] = useState(false);
    const [modalIsNew, setModalIsNew] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const [showMap, setShowMap] = useState(false);

    const transportTypes = useStoreProp(loadRouteTypes, 'transportRoad', 'routeTypes');

    const currentType = useMemo(() =>
        transportTypes.find(type => type.id === data.category_id), [transportTypes, data.category_id]
    );

    const handleOpenModalCopy = (e) => {
        e.stopPropagation();
        setModalIsNew(true);
        setIsCopy(true);
    };

    useEffect(() => {
        if (modalIsNew) {
            setModalOpen(true);
        }
    }, [modalIsNew]);

    const handleCloseStatusModal = () => {
        setModalStatusOpen(false);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setModalIsNew(false);
        setIsCopy(false);
    };

    const handleDelete = () => {
        dispatch(deleteRoute(data?.id, () => {
            reloadList();
            setOpenDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem
                needFocus={focus === data?.id ? true : false}
                onClick={() => onClickItem(data)}
                style={ selectedId ? { backgroundColor: '#d7d7d7' } : { backgroundColor: '' } }
                isMobile={checkRoute}
            >
                <LSContentColumn>
                    {data?.num || messages.INFO_IS_NOT_FOUND}
                </LSContentColumn>
                <LSContentColumn>
                    {data?.name || messages.INFO_IS_NOT_FOUND}
                </LSContentColumn>
                <LSContentColumn>
                    {currentType?.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {data?.statusText || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn align="right" isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.copyIcon,
                                onClick: handleOpenModalCopy,
                            },
                            {
                                ...buttonsTypes.mapIcon,
                                onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowMap(true);
                                },
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setEditModal(true);
                                },
                                disabled: !(data.status === 1)
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setOpenDeleteModal(true);
                                },
                                disabled: !(data.status === 1)
                            },
                        ]}
                    />
                    <IconButton size="small">
                        {selectedId
                            ? <ArrowBackIosIcon fontSize="inherit"/>
                            : <ArrowForwardIosIcon fontSize="inherit"/>
                        }
                    </IconButton>
                </LSContentColumn>
            </LSContentItem>
            {modalOpen
                && <AddEditCopyModal
                    data={data}
                    isNew={modalIsNew}
                    isOpen={modalOpen}
                    onClose={handleCloseModal}
                    typeTransportation={typeTransportation}
                    isCopy={isCopy}
                    reloadList={reloadList}
                />
            }
            {modalStatusOpen
                && <EditStatusModal
                    data={data}
                    isOpen={modalStatusOpen}
                    onClose={handleCloseStatusModal}
                />
            }
            {editModal && (
                <AddEditCopyModal
                    data={data}
                    isOpen={editModal}
                    onClose={setEditModal}
                    typeTransportation={typeTransportation}
                    isNew={false}
                    reloadList={reloadList}
                />
            )}
            {showMap && (
                <ShowMapModal
                    isOpen={showMap}
                    onClose={() => setShowMap(false)}
                    route={data}
                />
            )}
            {openDeleteModal && (
                <ConfirmModal
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                />
            )}
        </>
    );
}

export default RouteItem;
