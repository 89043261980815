import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadBrands } from 'redux/TransportSpecial/actions';
import { transportSpecialSelectors } from 'redux/TransportSpecial';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer  } from 'components/common/List';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import Filters from './Filters';
import ModalForm from './ModalForm';
import Item from './Item';

const Brands = () => {
    const dispatch = useDispatch();

    const list = useSelector(transportSpecialSelectors.brandsData);
    const meta = useSelector(transportSpecialSelectors.brandsMeta);
    const loading = useSelector(transportSpecialSelectors.loadingBrands);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [isOpenAddModal, setIsOpenAddModal] = useState(false);

    const reloadList = (isDelete) => {
        const page = isDelete && list?.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadBrands(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return <>
            {list?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: titles.TYPE_OF_VEHICLE, width: '100px' },
                            { title: titles.NAME, width: '70%' },
                            { title: 'Действия', isActions: true }
                        ]}
                    >
                        {list.map((item) => (
                            <Item
                                key={item.id}
                                item={item}
                                reloadList={reloadList}
                            />
                        ))}
                    </LSContainer>
                )
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>;
    };

    return (
        <>
            <PageLayout
                header={titles.BRAND}
                loading={loading}
                filters={<Filters setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setIsOpenAddModal(true)
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),

                    total: meta?.total
                }}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
                content={renderContent}
            />

            {isOpenAddModal && (
                <ModalForm
                    open={isOpenAddModal}
                    onClose={() => setIsOpenAddModal(false)}
                    isNew={true}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default Brands;
