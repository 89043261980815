import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Collapse, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import messages from '../../../../../../../helpers/constants/messages';
import transportTypes from '../../../../../../../helpers/transport.types';
import GetIcon from '../../../../../../../img/GetIcon';
import FormButtons, { buttonsTypes } from '../../../../../../common/FormButtons';

const useStyles = makeStyles({
    img: {
        padding: 7,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        width: 30,
        height: 30,
        borderRadius: '50%'
    },
});

const Routes = ({ routes = [], }) => {
    const classes = useStyles();
    const types = transportTypes;

    const [showRoutes, setShowRoutes] = useState(false);

    return (
        <div>
            <h2>Обратите внимание, ниже перечислены маршруты, которые может затронуть зона события:</h2>
            {routes?.length > 0
                ? <>
                    <FormButtons
                        noPadding
                        noMarginLeft
                        positionLeft
                        buttons={[
                            {
                                ...buttonsTypes.defaultInfo,
                                onClick: () => setShowRoutes(!showRoutes),
                                name: `${showRoutes ? 'Скрыть' : 'Показать'} маршруты`,
                            }
                        ]}
                    />
                    <Collapse in={showRoutes} timeout={'auto'} unmountOnExit className="info">
                        {routes?.map((route) => <div style={{ borderBottom: '1px solid #d9d9d9' }} key={uuidv4()}>
                            <ListItem>
                                <ListItemIcon>
                                    <div
                                        className={classes.img}
                                        style={{ backgroundColor: types[route?.category_id]?.color }}
                                    >
                                        <GetIcon
                                            iconName={types[route?.category_id]?.iconName}
                                            format="svg"
                                            category="transport"
                                        />
                                    </div>
                                </ListItemIcon>
                                <ListItemText>
                                    <div>
                                        <strong>№{route?.num}</strong> {route?.name} ({route?.is_direct_text})
                                    </div>
                                </ListItemText>
                            </ListItem>
                        </div>)}
                    </Collapse>
                </>
                : messages.NOTHING_FOUND
            }
        </div>
    );
};

export default Routes;
