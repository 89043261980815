import React, { useState } from 'react';
import { TextField } from '@mui/material';
import AddressField from './AddressField';
import removeEmptyFields from '../../../../../../helpers/removeEmptyFields';
import LayoutFilter from '../../../../../layout/PageLayout/LayoutFilter';

interface FiltersProps {
    setParams: React.Dispatch<React.SetStateAction<{page: number, data: {}}>>
}

type InitialType = 'name' | 'address'

const Filters = ({ setParams }: FiltersProps) => {
    const initialState: Record<InitialType, string> = {
        name: '',
        address: ''
    };

    const [data, setData] = useState(initialState);

    const onChange = (name: string, value: string) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const onSearch = (filter = data) => {
        const paramsWithoutEmpty = removeEmptyFields({
            ...filter
        });

        setParams({ page: 1, data: paramsWithoutEmpty });
    };

    const resetFilter = (needRefresh: boolean) => {
        if (needRefresh) { 
            setParams({ page: 1, data: {} });
        }
        setData(initialState);
    };

    const updateFilter = (filters: {}) => {
        const newFilter = { ...initialState, ...filters };
        setData(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };

    const isDisabled = Object.keys(removeEmptyFields(data)).length === 0;

    const handleClickEnter = (e: React.KeyboardEvent) => {
        if (e?.key === 'Enter') onSearch();
    };

    
    return (
        <LayoutFilter
            filter={data}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <TextField
                    label="Название"
                    size="small"
                    value={data.name}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={e => onChange('name', e.target.value)}
                    onKeyPress={handleClickEnter}
                />

                <AddressField
                    value={data.address}
                    onChange={value => onChange('address', value)}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;