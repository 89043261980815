import Information from 'components/common/Information';
import messages from 'helpers/constants/messages';
import { getTimeWithoutSecond } from 'helpers/date.config';
import titles from 'helpers/constants/titles';

const InfoItem = ({ data }) => {
    const wrapperTpl = (key, title, value) => <div className="info__item" key={key} style={{ marginRight: '.5rem' }}>{title}{value}</div>;

    return (
        <div style={{ marginBottom: '1.5rem' }}>
            <Information
                wrapperTpl={wrapperTpl}
                data={data}
                title={{
                    id: {
                        title: 'ID рейса',
                    },
                    route_name: {
                        title: 'Маршрут',
                    },
                    route_is_direct_text: {
                        title: 'Тип маршрута',
                    },
                    employee_list: {
                        title: 'Сотрудники',
                        value: ({ employee_list }) => {
                            const data = (employee_list || []).reduce((res, item) => item ? [...res, item] : res, []).join(', ');
                            return data || null;
                        },
                    },
                }}
            />

            <div style={{ display: 'flex' }}>
                <Information
                    wrapperTpl={wrapperTpl}
                    data={data}
                    title={{
                        vehicle_number: {
                            title: titles.GRZ,
                        },
                        vehicle_mark: {
                            title: 'Марка ТС',
                        },
                        vehicle_model: {
                            title: 'Модель ТС',
                        },
                    }}
                />
            </div>

            <div style={{ display: 'flex' }}>
                <Information
                    wrapperTpl={wrapperTpl}
                    data={data}
                    title={{
                        part_departure_scheduled_time: {
                            title: 'Время выезда из парка план',
                            value: ({ part_departure_scheduled_time }) =>
                                part_departure_scheduled_time
                                    ? getTimeWithoutSecond(part_departure_scheduled_time)
                                    : messages.INFO_IS_NOT_FOUND,
                        },
                        part_departure_actual_time: {
                            title: 'Время выезда из парка факт',
                            value: ({ part_departure_actual_time }) =>
                                part_departure_actual_time
                                    ? getTimeWithoutSecond(part_departure_actual_time)
                                    : messages.INFO_IS_NOT_FOUND,
                        },
                    }}
                />
            </div>

            <div style={{ display: 'flex' }}>
                <Information
                    wrapperTpl={wrapperTpl}
                    data={data}
                    title={{
                        first_station_scheduled_time: {
                            title: 'Начальная остановка план',
                            value: ({ first_station_scheduled_time }) =>
                                first_station_scheduled_time
                                    ? getTimeWithoutSecond(first_station_scheduled_time)
                                    : null,
                        },
                        first_station_actual_time: {
                            title: 'Начальная остановка факт',
                            value: ({ first_station_actual_time }) =>
                                first_station_actual_time
                                    ? getTimeWithoutSecond(first_station_actual_time)
                                    : null,
                        },
                    }}
                />
            </div>

            <div style={{ display: 'flex' }}>
                <Information
                    wrapperTpl={wrapperTpl}
                    data={data}
                    title={{
                        last_station_scheduled_time: {
                            title: 'Конечная остановка план',
                            value: ({ last_station_scheduled_time }) =>
                                last_station_scheduled_time
                                    ? getTimeWithoutSecond(last_station_scheduled_time)
                                    : null,
                        },
                        last_station_actual_time: {
                            title: 'Конечная остановка факт',
                            value: ({ last_station_actual_time }) =>
                                last_station_actual_time
                                    ? getTimeWithoutSecond(last_station_actual_time)
                                    : null,
                        },
                    }}
                />
            </div>

            <div style={{ display: 'flex' }}>
                <Information
                    wrapperTpl={wrapperTpl}
                    hideNoValue
                    data={data}
                    title={{
                        part_arrival_scheduled_time: {
                            title: 'Время заезда в парк план',
                            value: ({ part_arrival_scheduled_time }) =>
                                part_arrival_scheduled_time
                                    ? getTimeWithoutSecond(part_arrival_scheduled_time)
                                    : null,
                        },
                        part_arrival_actual_time: {
                            title: 'Время заезда в парк факт',
                            value: ({ part_arrival_actual_time }) =>
                                part_arrival_actual_time
                                    ? getTimeWithoutSecond(part_arrival_actual_time)
                                    : null,
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default InfoItem;
