import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from '../../redux/Auth';
import { userSignOutSuccess, changeRefreshTime, logout } from '../../redux/Auth/actions';
import Loading from '../common/Loading';

const SignOut = ({ history }) => {
    const dispatch = useDispatch();
    const loading = useSelector(authSelectors.loading);
    const project = useSelector(authSelectors.project);

    React.useEffect(() => {
        const deleteAllTokens = history.location.pathname?.includes('/all');

        const callback = () => {
            dispatch(userSignOutSuccess());
            dispatch(changeRefreshTime(0));
            history.push('/signin');
        };

        if (project.code){
            dispatch(logout(callback, deleteAllTokens));
        }
        
    }, [history, dispatch, project.code]);

    return <>{loading && <Loading circular={true}/>};</>;
};

export default SignOut;
