import { isValue } from 'helpers/checkValue';
import Image from 'components/common/Image';
import { checkBase64 } from 'components/MapComponents/Layers/TransportPassenger/helper';
import { DuploCodesItem } from 'components/pages/Dictionaries/DorisControl/DuploCodes/types';

interface DuploCodeItemProps {
    item: DuploCodesItem
}

const DuploCodeItem = ({ item }: DuploCodeItemProps) => {
    return (
        <div className="row align-items-center">
            <Image
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    margin: 0,
                    width: '40px',
                    height: '40px',
                }}
                src={checkBase64(item?.icon)}
                alt=""
            />&nbsp;
            {isValue(item?.id) && `${item.id} `}
            {item?.gost_code && `${item.gost_code}: `}
            {item?.name && `${item.name} `}
            {item?.koap_name && `(${item.koap_name})`}
        </div>
    );
};

export default DuploCodeItem;
