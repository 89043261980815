const trimCoords = (coords, digits = 4) => {
    const regex = new RegExp(`\\d+\\.\\d{1,${digits}}`);
    const result = regex.exec(`${coords}`) || [];
    return result.length > 0 ? result[0] : coords;
};

const roundCoords = (coords, digits) => {
    return Number(coords).toFixed(digits);
};

// округление координат до нужного кол-ва знаков, либо просто обрезка до нужного кол-ва знаков
const prepareCoords = (coords, digit, needRound) => {
    if (coords === 0) return coords;
    if (needRound) {
        return roundCoords(coords, digit);
    }
    return trimCoords(coords, digit);
};

export default prepareCoords;
