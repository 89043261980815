import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';

import { clearWorkorders, loadWorkorders } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { dateWithDashYYYYMMDD, getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';

import Filters from './Filters';
import Item from './Item';

const WorkordersControl = ({
    // фильтр по-умолчанию, не дает редактировать переданные поля в фильтре
    filters = {},
    disableFilterFields = [],
    // передается из тс на карте для перехода к элементу
    work_order_event_id,
    work_order_id,
}) => {
    const dispatch = useDispatch();

    const initialState = {
        start_date_at: getStartOf('day', getDateWithDuration({ days: -1 })),
        end_date_at: getEndOf('day'),
        traffic_schedule_ids: [],
        route: [],
        grz: [],
        organization_id: [],
        milestone_event_id_list: [],
        with_auto_status_done: false,
        id: '',
        ...filters
    };

    const [selectedItem, setSelectedItem] = useState(work_order_id ? { id: work_order_id } : {});
    const [params, setParams] = useState(initialState);
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);
    const [page, setPage] = useState(1);

    const workordersData = useSelector(transportPassengerSelectors.workordersData);
    const workordersMeta = useSelector(transportPassengerSelectors.workordersMeta);
    const loadingWorkorders = useSelector(transportPassengerSelectors.loadingWorkorders);

    const handleSelectItem = (value) => {
        setSelectedItem(prevState => prevState?.id === value?.id ? {} : value);
    };

    useEffect(() => {
        const paramsWithoutEmpty = removeEmptyFields({
            start_date_at: dateWithDashYYYYMMDD(params?.start_date_at),
            end_date_at: dateWithDashYYYYMMDD(params?.end_date_at),
            traffic_schedule_ids: params?.traffic_schedule_ids?.map(({ id }) => id),
            route: params?.route?.map(({ id }) => id),
            grz: params?.grz?.map(({ number }) => number),
            organization_id: params?.organization_id?.map(({ id }) => id),
            milestone_event_id_list: params?.milestone_event_id_list?.map(({ id }) => id),
            with_auto_status_done: params?.with_auto_status_done,
            id: params?.id
        });
        dispatch(loadWorkorders(
            page,
            limit,
            paramsWithoutEmpty
        ));
    }, [dispatch, params, limit, page]);

    useEffect(() => () => {
        // очистка данных
        dispatch(clearWorkorders());
    }, []);

    const handleChange = (value, name) => {
        setParams({
            ...params,
            [name]: value
        });
    };

    const renderContent = () => {
        return (workordersData?.length > 0
            ? <>
                <LSContainer
                    headers={[
                        { title: '', width: '60px' },
                        { title: 'ID наряда', width: '10%' },
                        { title: titles.DATE, width: '10%' },
                        { title: '№ граф.', width: '10%' },
                        { title: '№ марш.', width: '10%' },
                        { title: titles.TYPE_OF_VEHICLE, width: '10%' },
                        { title: titles.GRZ, width: '10%' },
                        { title: titles.ORGANIZATION, width: 'calc(30% - 60px)' },
                        { title: titles.ACTIONS, isActions: true }
                    ]}
                >
                    {workordersData?.map(item =>
                        <Item
                            key={item.id}
                            data={item}
                            selectedId={selectedItem?.id}
                            setSelect={handleSelectItem}
                            work_order_event_id={work_order_event_id}
                        />
                    )}
                </LSContainer>
            </>
            : (!loadingWorkorders && messages.WORKORDER_IS_NOT_FOUND_FOR_THE_PARAMS)
        );
    };

    return (
        <PageLayout
            header="Контроль нарядов"
            filters={
                <Filters
                    setParams={setParams}
                    params={params}
                    setSelect={setSelectedItem}
                    initialState={initialState}
                    disabledFields={disableFilterFields}
                />
            }
            loading={loadingWorkorders}
            informPanelProps={{
                total: workordersMeta?.total
            }}
            actionPanel={
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={params.with_auto_status_done}
                                onChange={(e) => handleChange(e.target.checked, 'with_auto_status_done')}
                            />
                        }
                        label="Показать выполненные"
                    />
                </>
            }
            content={renderContent}
            paginationProps={{
                loadList: (page) => setPage(page),
                list: workordersMeta,
                limit,
                setLimit
            }}
        />
    );
};

export default WorkordersControl;
