import titles from '../../../../../../../../helpers/constants/titles';
import FileUploader from '../../../../../../../common/Upload/FileUploader';
import { uploadFileToStorage } from '../../../../../../../../redux/Upload/actions';
import Item from '../../../../../../../common/Upload/Attachments/Item';
import { List } from '@mui/material';
import React from 'react';
import UploadImage from './UploadImage';

const TrafficScheme = ({ schema_doc, schema_img, onChangeSchemaDoc, onChangeSchemaImg }) => {
    const openInNewTab = (url) => {
        var newTab = window.open();
        newTab.document.body.innerHTML = `<img src=${url} width="auto" height="auto">`;
    };

    return (
        <>
            <div>
                <div className="row align-items-center">
                    <h2>{titles.ATTACH_SCHEMA_ODD}:</h2>
                    <FileUploader
                        onFileUpload={onChangeSchemaDoc}
                        service="doris-control"
                        title={titles.ATTACH_FILE}
                        action={uploadFileToStorage}
                    />
                </div>
                {schema_doc && (
                    <List className="list">
                        <Item
                            item={schema_doc}
                            onDelete={() => onChangeSchemaDoc(null)}
                        />
                    </List>
                )}
            </div>
            <div>
                <div className="row align-items-center">
                    <h2>{titles.SCHEMA_ODD_FOR_DIT}:</h2>
                    <UploadImage onFileUpload={onChangeSchemaImg}/>
                </div>
                {schema_img && (
                    <Item
                        item={{ schema_img }}
                        onDelete={() => onChangeSchemaImg(null)}
                        renderContent={() => (
                            <img
                                className="link"
                                title="Открыть в новом окне"
                                onClick={() => openInNewTab(schema_img)}
                                style={{ width: 'auto', height: 50, margin: 0 }}
                                src={schema_img}
                                alt=""
                            />
                        )}
                    />
                )}
            </div>
        </>
    );
};

export default TrafficScheme;