const Icon = ({
    outer,
    inner,
}) => `
    <svg enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="m256 491.917c-23.969 0-46.209-12.95-58.04-33.795l-188.759-332.575c-6.019-10.607-9.201-22.658-9.201-34.853 0-38.935 31.676-70.61 70.61-70.61h370.78c38.935 0 70.61 31.675 70.61 70.61 0 12.196-3.182 24.248-9.202 34.854l-188.758 332.574c-11.831 20.845-34.071 33.795-58.04 33.795z" fill="#ff5c5c" style="fill: ${outer}"></path>
            <path d="m441.39 20.083h-185.39v471.833c23.969 0 46.209-12.95 58.04-33.795l188.758-332.574c6.021-10.606 9.202-22.658 9.202-34.854 0-38.934-31.676-70.61-70.61-70.61z" fill="#ff1f3d" style="fill: ${outer}"></path>
            <path d="m256 423.439c-5.398 0-10.38-2.901-13.045-7.596l-177.851-313.355c-2.635-4.644-2.604-10.338.083-14.952s7.624-7.452 12.963-7.452h355.701c5.339 0 10.276 2.838 12.963 7.452s2.718 10.309.082 14.952l-177.851 313.356c-2.664 4.695-7.647 7.595-13.045 7.595z" fill="#f6f9f9" style="fill: ${inner}"></path>
            <path d="m433.851 80.083h-177.851v343.356c5.398 0 10.381-2.901 13.045-7.596l177.851-313.356c2.636-4.644 2.604-10.338-.082-14.952-2.687-4.613-7.625-7.452-12.963-7.452z" fill="#daeaec" style="fill: ${inner}"></path>
        </g>
    </svg>
`;

export default Icon;