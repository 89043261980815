import { NotificationManager } from 'react-notifications';
import messageTypes from './messagetypes';
import { NotificationComponent } from './NotificationComponent';

// вывести сообщение
export const showMessage = (
    type = messageTypes.success,
    title = '',
    message = '',
    callback = null,
    timeout = 6000
) => {
    NotificationManager[type](
        <NotificationComponent
            title={title}
            message={message}
            callback={callback} 
        />,
        '', 
        timeout
    );

    return {
        type: 'messages:SHOW_MESSAGE'
    };
};

export { messageTypes };
