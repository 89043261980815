import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';

import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

import DateRange from '../../../common/Dates/DateRange';
import { downloadOrganizationsReport } from '../../../../redux/Companies/actions';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from '../../../../helpers/date.config';
import GetReports from '../../../common/GetReports';

const Filter = () => {
    const dispatch = useDispatch();

    const initialState = {
        start_at: getStartOf('month'),
        end_at: getEndOf('month'),
        is_hidden_details: false,
        formats: [{ id: 'pdf', name: 'PDF' }]
    };

    const [filters, setFilters] = useState(initialState);

    const handleChange = (name, value) => {
        setFilters({
            ...filters,
            [name]: value
        });
    };

    const resetFilter = () => {
        setFilters(initialState);
    };

    const packageData = {
        start_at: fullDateTimeWithTimeZone(filters?.start_at),
        end_at: fullDateTimeWithTimeZone(filters?.end_at),
        report: 1,
        formats: filters?.formats?.map(({ id }) => id),
        is_hidden_details: filters?.is_hidden_details ? 1 : 0
    };

    const [isDisabled, setIsDisabled] = useState(false);

    const isDisabledHandler = (value) => setIsDisabled(value);

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={() => dispatch(downloadOrganizationsReport(packageData))}
            filter={filters}
            buttonType="getReport"
            filterException={['start_at', 'end_at']}
            isFilterVisible
            disabled={isDisabled || filters?.formats.length === 0}
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(value) => handleChange('start_at', value)}
                    handleEndDate={(value) => handleChange('end_at', value)}
                    valueStartDate={filters?.start_at}
                    valueEndDate={filters?.end_at}
                    isDisabledHandler={isDisabledHandler}
                />
                <GetReports
                    selected={filters?.formats}
                    onChange={(value) => handleChange('formats', value)}
                    reportId={27}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters?.is_hidden_details}
                            onChange={() => handleChange('is_hidden_details', !filters?.is_hidden_details)}
                        />
                    }
                    label="Скрыть подробные данные"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
