import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';

import type { FilterState } from './types';

interface Args {
    checkboxes?: FilterState['checkboxes'],
    cameras?: FilterState['cameras']
    violation_types?: FilterState['violation_types']
    without_violations?: FilterState['without_violations']
    start_date?: FilterState['start_date']
    end_date: FilterState['end_date']
}

// подготовка данных перед отправкой на бэк
export const prepareData = (data: Args): { start_date: string | null, end_date: string | null } => {
    const checkboxes = data.checkboxes?.reduce<{ [key: string]: boolean }>((acc, curr) => {
        acc[curr.key] = true;
        return acc;
    }, {});

    return {
        ...removeEmptyFields(
            {
                ...data,
                camera_id_list: data?.cameras?.map((camera) => camera.id),
                cameras: null,
                violation_type_list: data?.violation_types?.map((item) => Number(item.value)),
                violation_types: null,
                without_violation_type_list: data?.without_violations?.map((item) => Number(item.value)),
                without_violations: null,
                checkboxes: null,
                ...checkboxes,
            },
            false
        ),
        start_date: fullDateTimeWithTimeZone(data.start_date),
        end_date: fullDateTimeWithTimeZone(data.end_date),
    };
};
