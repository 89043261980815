import { List, Typography } from '@mui/material';
import messages from '../../../../../../../helpers/constants/messages';
import { useStoreProp } from '../../../../../../../helpers/hooks';
import { loadDorisExportTypes } from '../../../../../../../redux/DorisControl/actions';

const RenderExportInfo = ({ item = {}, name = '' }) => {

    const ExportTypes = useStoreProp(loadDorisExportTypes, 'dorisControl', 'dorisExportTypes');

    return (
        <List>
            <Typography variant="body2">
                Адрес для выгрузки: {item?.url}
            </Typography>
            <Typography variant="body2">
                Выгрузка включена: {item?.enable ? 'да' : 'нет'}
            </Typography>
            <Typography variant="body2">
                Тип выгрузки: {ExportTypes[item?.type]}
            </Typography>
            <Typography variant="body2">
                Описание выгрузки: {item?.description || messages.NO_DATA}
            </Typography>
        </List>
    );
};

export default RenderExportInfo;
