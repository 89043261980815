import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadStatusesVideoCamera, loadVideoCamerasList } from 'redux/VideoCameras/actions';
import { useStoreProp } from 'helpers/hooks';
import Context from 'helpers/context';
import titles from 'helpers/constants/titles';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';

import ModalForm from './ModalForm';
import Filter from './Filter';
import Item from './Item';
import type { Statuses, StoreProps } from './types';

const CityCameras = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const statuses: Statuses[] = useStoreProp(
        loadStatusesVideoCamera,
        'videoCameras',
        'videoCameraStatuses'
    );

    const {
        videoCameraList: { data: list, meta },
        loadingVideoCameraList: loading
        // @ts-ignore
    }: StoreProps = useSelector(({ videoCameras }) => videoCameras);

    const [isOpenAddModal, setIsOpenAddModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = (isDelete?: boolean) => {
        isDelete && list?.length === 1 && meta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadVideoCamerasList(params.page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadVideoCamerasList(params.page, limit, params.data));
    },[dispatch, limit, params]);
    
    const renderContent = () => (
        <>
            {list?.length > 0
                ? (<LSContainer
                    headers={[
                        { title: '', width: '50px' },
                        { title: titles.NAME, width: '29%' },
                        { title: titles.ADDRESS, width: '61%' },
                        { title: 'Действия', align: 'right', isActions: true }
                    ]}
                >
                    {list.map((item) => (
                        <Item
                            key={item.id}
                            item={item}
                            statuses={statuses}
                            reloadList={reloadList}
                        />)
                    )}
                </LSContainer>)
                : !loading && <NoData/>
            }
        </>
    );

    return (
        <>
            <PageLayout 
                header="Камеры видеонаблюдения" 
                filters={<Filter statuses={statuses} setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    ...(permissions?.is_create
                                        ? [{
                                            ...buttonsTypes.add,
                                            onClick: () => setIsOpenAddModal(true),
                                        }]
                                        : []
                                    ),
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                       
                    total: meta.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />

            {isOpenAddModal && (
                <ModalForm
                    statuses={statuses}
                    isNew={true}
                    isOpen={isOpenAddModal}
                    onClose={() => setIsOpenAddModal(false)}
                    reloadList={() => setParams({ ...params, page: 1 })}
                />
            )}
        </>
    );
};

export default CityCameras;
