import moduleName from './module';
const prefix = `${moduleName}`;

export const LOAD_LIST = `${prefix}/LOAD_LIST`;
export const LOADING_LIST = `${prefix}/LOADING_LIST`;
export const LOADED_LIST = `${prefix}/LOADED_LIST`;

export const LOAD_AIP_EVENTS = `${prefix}/LOAD_AIP_EVENTS`;
export const LOADING_AIP_EVENTS = `${prefix}/LOADING_AIP_EVENTS`;
export const LOADED_AIP_EVENTS = `${prefix}/LOADED_AIP_EVENTS`;

export const GET_POLYGON = `${prefix}/GET_POLYGON`;
export const SAVE_POLYGON = `${prefix}/SAVE_POLYGON`;
export const SET_POLYGON_LOADING = `${prefix}/SET_POLYGON_LOADING`;

export const LOADED_SIDEBAR = `${prefix}/LOADED_SIDEBAR`;
export const LOAD_SIDEBAR = `${prefix}/LOAD_SIDEBAR`;
export const LOADING_SIDEBAR = `${prefix}/LOADING_SIDEBAR`;
export const CLEAR_SIDEBAR = `${prefix}/CLEAR_SIDEBAR`;

export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;
export const SET_FILTER = `${prefix}/SET_FILTER`;

export const CREATE_AIP = `${prefix}/CREATE_AIP`;
export const EDIT_AIP = `${prefix}/EDIT_AIP`;
export const DELETE_AIP = `${prefix}/DELETE_AIP`;

export const LOAD_AIP_TYPES = `${prefix}/LOAD_AIP_TYPES`;
export const LOADED_AIP_TYPES = `${prefix}/LOADED_AIP_TYPES`;

export const LOAD_AIP_OPERATORS = `${prefix}/LOAD_AIP_OPERATORS`;
export const LOADED_AIP_OPERATORS = `${prefix}/LOADED_AIP_OPERATORS`;


