import { useContext, useEffect, useState } from 'react';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from './ModalForm';
import Loading from '../../../../common/Loading';
import Filter from './Filter';
import { loadResponseScenarios } from '../../../../../redux/SituationalPlans/actions';
import CustomPagination from '../../../../common/CustomPagination';
import { List } from '@mui/material';
import messages from '../../../../../helpers/constants/messages';
import Item from './Item';
import Context from '../../../../../helpers/context';
import { SituationalPlansSelectors } from '../../../../../redux/SituationalPlans';

const ResponseScenarios = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const [openModal, setOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const list = useSelector(SituationalPlansSelectors.responseScenariosData);
    const meta = useSelector(SituationalPlansSelectors.responseScenariosMeta);
    const loading = useSelector(SituationalPlansSelectors.loadingResponseScenarios);
    
    const reloadList = (isDelete) => {
        const page = isDelete && list?.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(()=> {
        dispatch(loadResponseScenarios(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    return (
        <>
            <h1>Сценарные планы</h1>
            
            <Filter setParams={setParams}/>
            
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenModal(true),
                            disabled: !permissions?.is_create,
                        }
                    ]}
                />
            </div>
            
            {loading && <Loading linear/>}
            
            {list?.length > 0
                ? (
                    <>
                        <List className="list">
                            {list.map((item) => (
                                <Item
                                    key={item?.id + item?.name}
                                    item={item}
                                    reloadList={reloadList}
                                />
                            ))}
                        </List>
                        <CustomPagination
                            loadList={(page) => setParams({ ...params, page })}
                            list={meta}
                            limit={limit}
                            setLimit={setLimit}
                        />
                    </>
                )
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
            {openModal && (
                <ModalForm
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    isNew={true}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default ResponseScenarios;