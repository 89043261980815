import React, { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

import { loadIncidentOperations } from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import Loading from 'components/common/Loading';
import IncidentOperations  from 'components/pages/Dictionaries/SituationalPlans/TransportIncidents/IncidentOperations';
import { getFullDateTimeWithTextMonth } from 'helpers/date.config';

import config from '../config';

const Information = ({ data, readOnly = false, statusesObject = {} }) => {
    const dispatch = useDispatch();
    const incidentId = data?.id;

    const incidentOperations = useSelector(SituationalPlansSelectors.incidentOperations);
    const loadingIncidentOperations = useSelector(SituationalPlansSelectors.loadingIncidentOperations);

    useEffect(() => {
        if (incidentId && !incidentOperations[incidentId]) {
            dispatch(loadIncidentOperations(incidentId));
        }
    }, [dispatch, incidentOperations, incidentId]);

    const operationsCurrentIncident = incidentOperations[incidentId];

    const fields = {
        registered_at: {
            name: titles.REGISTERED,
            f: ({ registered_at }) => getFullDateTimeWithTextMonth(registered_at) || messages.INFO_IS_NOT_FOUND
        },
        name: {
            name: titles.TITLE,
        },
        address_text: {
            name: titles.ADDRESS
        },
        description: {
            name: titles.MORE_INFO,
        },
        location: {
            name: titles.LOCATION,
        },
        type_text: {
            name: titles.TYPE
        },
        status_text: {
            name: titles.STATUS,
            f: ({ status_id, status_text }) => `<span style="color:${statusesObject?.[status_id]?.color || config.mapMarkersColors.default}">${status_text}</span>`
        },
        threat_level: {
            name: titles.THREAT_LEVEL,
            f: ({ threat_level }) => {
                if (threat_level?.name) {
                    return `<span style="color: ${threat_level?.color}">${threat_level?.name}</span>`;
                }
                return null;
            }
        },
        traffic_control_mode_type: {
            name: 'Режим управления движением',
            f: ({ traffic_control_mode_type }) => {
                switch(traffic_control_mode_type) {
                    case 1: 
                        return 'Штатный';
                    case 2: 
                        return 'Нештатный';
                    default: 
                        return null;
                }
            }
        },
        event_text: {
            name: titles.EVENT
        },
        organization: {
            name: titles.RESPONSIBLE_ORGANIZATION,
            f: ({ organization }) => {
                const title = organization?.title || null;
                return title;
            }
        },
        user: {
            name: titles.WHO_IS_ASSIGNED_TO,
            f: ({ user }) => {
                const first_name = user?.first_name || null;
                const last_name = user?.last_name || null;
                return first_name || last_name ? `${first_name} ${last_name}` : null;
            }
        },
        route_list: {
            name: titles.ROUTES_IN_TI_AREA,
            f: ({ route_list }) => {
                const htmlText = route_list ? route_list.map(({ name, num }) => `<div>${name} №${num}</div>`).join('') : null;
                return htmlText;
            }
        },
        response_scenario: {
            name: titles.REACT_SCENARIO,
            f: ({ response_scenario }) => {
                const name = response_scenario?.name || null;
                return name;
            }
        },
        attachments: {
            name: titles.ATTACHMENTS,
            f: ({ attachments }) => {
                const att = (attachments || []).map((file, index) => `<a href="${file}" target="_blank" download="true">Файл ${index + 1}</a>`).join(', ');
                return att || null;
            }
        },
        vehicles: {
            name: titles.LIST_VEHICLES,
            f: ({ vehicles }) => {
                const renderVehicle = (vehicle) => {
                    return [
                        {
                            key: 'mark',
                            name: titles.BRAND
                        },
                        {
                            key: 'model_name',
                            name: titles.MODEL
                        },
                        {
                            key: 'number',
                            name: titles.REG_NUMBER
                        }
                    ].reduce((res, { key, name }) => {
                        if (vehicle[key]) {
                            return [
                                ...res,
                                `${name}: ${vehicle[key]}`
                            ];
                        }
                        return res;
                    }, []).join(', ');
                };

                const list = (vehicles || [])
                    .map((vehicle, index) => `<div>${index + 1}. ${renderVehicle(vehicle)}</div>`).join(' ');
                return list || null;
            }
        },
    };

    const RenderList = () => {
        if (!data) {
            return <div className="not-found">{messages.INFO_IS_NOT_FOUND}</div>;
        }

        return (
            <div className="information">
                {Object.keys(fields).map((field) => {
                    const f = fields[field].f ? fields[field].f(data) : null;
                    const value = f ? f : data[field];
                    const name = fields[field].name || '';
                    const isValue = Array.isArray(value) ? value.length : value;

                    if (isValue) {
                        return (
                            <div className="info__item" key={`information_${field}`}>
                                <span className="info__item-title">
                                    {name}
                                    {': '}
                                </span>
                                <span
                                    style={{
                                        paddingRight: '10px'
                                    }}
                                    dangerouslySetInnerHTML={{ __html: value || messages.INFO_IS_NOT_FOUND }}
                                >
                                    {/*{value || messages.INFO_IS_NOT_FOUND}*/}
                                </span>
                            </div>
                        );
                    }
                })}

                {loadingIncidentOperations && <Loading className="absolute bottom fill"/>}
                {operationsCurrentIncident?.length > 0 && (
                    <Box sx={{ padding: '15px' }}>
                        <Typography variant="h6">{titles.OPERATIONS}</Typography>
                        <IncidentOperations operations={operationsCurrentIncident} readOnly={readOnly}/>
                    </Box>
                )}
            </div>
        );
    };

    return (
        <div>
            <Scrollbars style={{ height: '240px' }}>
                <RenderList />
            </Scrollbars>
        </div>
    );
};

export default Information;
