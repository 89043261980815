import { Divider, Typography } from '@mui/material';
import React from 'react';

const ExportInfo = ({ title = '', values = [] }) => {
    const isEnable = values.some(el => el.enable);

    return (
        <>
            <Typography variant="body2">
                <b>{`Выгрузка в ${title}:`}</b> {isEnable ? 'Да' : 'Нет'}
            </Typography>
            {isEnable && (
                <>
                    <Typography variant="body2">
                        <b>{`Адреса для выгрузки ${title}:`}</b> 
                    </Typography>
                    {values.map((el, i) => el.enable 
                        ? <Typography variant="body2" key={i}>
                            {`${i+1}. ${el.url}`}
                        </Typography>
                        : null
                    )}
                </>
            )}
            <Divider style={{ margin: '10px 0' }}/>
        </>
    );
};

export default ExportInfo;
