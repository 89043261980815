import React, { useEffect, useState } from 'react';
import Form from './Form';
import Modal from '../../../../common/Modal';
import { useDispatch, useSelector } from 'react-redux';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { useStoreProp, useValidation } from '../../../../../helpers/hooks';
import * as actionsTR from '../../../../../redux/TransportRoad/actions';
import { transportRoadSelectors } from '../../../../../redux/TransportRoad';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import Loading from '../../../../common/Loading';

// формы
const ModalForm = (props) => {
    const {
        latlng = {},
        onClose = () => {},
    } = props;

    const dispatch = useDispatch();
    const vehiclesCategories = useStoreProp(
        actionsTR.loadVehicleCategories,
        'transportRoad',
        'vehicleCategories'
    );

    const findVehicleLoading = useSelector(transportRoadSelectors.findVehicleLoading);

    const validation = useValidation();

    const [fields, setFields] = useState({
        ...latlng,
        radius: 1,
    });

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        dispatch(actionsTR.setActive({}));
    }, []);

    const handleSuccess = () => {
        const filter = {
            ...fields,
            organization_ids: fields?.organization_ids?.map(({ id }) => id) || null,
            category_ids: fields?.category_ids?.map(({ id }) => id) || null,
        };

        dispatch(
            actionsTR
                .loadFindVehicles(
                    removeEmptyFields(filter),
                    () => {
                        onClose();
                    }
                )
        );
    };

    const handleChange = (changeData) => {
        validation.deleteKeys(Object.keys(changeData));

        setFields({
            ...fields,
            ...changeData
        });
    };

    {/* форма редактирования */}
    return (
        <Modal
            isOpen={true}
            onClose={handleClose}
            noPadding
            title="Найти ближайшее ТС"
            maxWidthProp={'sm'}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: handleClose,
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: handleSuccess,
                        }
                    ]}
                />
            }
        >
            {findVehicleLoading && <Loading circular={true} />}
            <Form
                data={fields}
                onChange={handleChange}
                validation={validation}
                vehiclesCategories={vehiclesCategories}
            />
        </Modal>
    );
};

export default ModalForm;
