import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@mui/lab';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, TextField } from '@mui/material';

import { createWork, editWork } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import SelectUnits from 'components/common/Autocomplete/RoadWorks/Units';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import buttons from 'helpers/constants/buttons';
import type { CreateWork, Work } from 'types/RoadWorks';

interface BaseProps {
    onClose: () => void;
    reloadList: () => void;
}

interface AddProps extends BaseProps {
    isNew: true;
    item?: undefined;
}

interface EditProps extends BaseProps {
    isNew: false;
    item: Work;
}

type Props = AddProps | EditProps;

const AddEditModal = ({ onClose, isNew, item, reloadList }: Props) => {
    const dispatch = useDispatch();

    const groups = useSelector(roadworksSelectors.roadWorksGroupsData);
    const loadingButton = useSelector(roadworksSelectors.loadingButton);

    const initialState: CreateWork = {
        name: item?.name || '',
        unit_of_measurement_list: item?.unit_of_measurement_list || [],
        unit_of_measurement_id_list: item?.unit_of_measurement_id_list || [],
        road_work_group: item?.road_work_group || null,
        road_work_group_id: item?.road_work_group_id || null,
    };

    const [data, setData] = useState(initialState);
    const [showInReport, setShowInReport] = useState(item?.show_in_report || false);

    const onChange = <K extends keyof CreateWork>(name: K, value: CreateWork[K]) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const onSave = () => {
        const filterEmpty = removeEmptyFields({
            ...data,
            unit_of_measurement_id_list: data.unit_of_measurement_list.map(item => item?.id),
            road_work_group_id: data.road_work_group?.id,
        });

        const prepareData = {
            ...filterEmpty,
            show_in_report: showInReport
        };
        const callback = () => {
            reloadList();
            onClose();
        };

        dispatch(isNew ? createWork(prepareData, callback) : editWork(item?.id, prepareData, callback));
    };

    const isDisabled = Object.values(removeEmptyFields(data)).length === 0 || loadingButton;

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            noPadding
            isOpen={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.create,
                        ...(isNew ? { name: buttons.CREATE } : { name: buttons.SAVE }),
                        disabled: isDisabled,
                        loading: loadingButton,
                        onClick: onSave
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl required className="block" size="small" variant="outlined">
                    <TextField
                        required
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={data.name}
                        name="name"
                        onChange={(e) => onChange('name', e.target.value)}
                        type="text"
                    />
                </FormControl>
                <FormControl required className="block" size="small" variant="outlined">
                    <SelectUnits
                        multiple
                        selected={data.unit_of_measurement_list}
                        onChange={(value) => onChange('unit_of_measurement_list', value)}
                        required
                    />
                </FormControl>
                <FormControl required className="block" size="small" variant="outlined">
                    <Autocomplete
                        value={data.road_work_group}
                        options={groups}
                        getOptionLabel={(option) => option.name}
                        size="small"
                        onChange={(_, value) => onChange('road_work_group', value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                required
                                variant="outlined"
                                label="Группа дорожных работ"
                            />
                        )}
                    />
                </FormControl>
                <FormControl required className="row" size="small" variant="outlined">
                    <Checkbox
                        checked={showInReport}
                        onClick={()=> setShowInReport(!showInReport)}
                    />
                    <span style={{ alignSelf: 'center' }}>Показывать в сводном отчете</span>
                </FormControl>
            </div>
        </Modal>
    );
};

export default AddEditModal;
