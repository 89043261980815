import React from 'react';
import { withStyles } from '@mui/styles';
import { Tabs , Tab } from '@mui/material';

const TabsStyle = {
    indicator: {
        opacity: 0,
    },
};

const TabStyle = {
    root: {
        borderBottom: '1px rgb(119 119 119 / 55%) solid',
        fontSize: '.9rem',
        minHeight: '48px'
    },
    selected: {
        border: '1px rgb(119 119 119 / 55%) solid',
        borderBottom: 'none',
        borderRadius: '7px 7px 0 0'
    },

};

export const CustomTabs = withStyles(TabsStyle)(Tabs);
export const CustomTab = withStyles(TabStyle)(Tab);