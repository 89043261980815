export const admin = (state) => state.admin;

export const statuses = (state) => admin(state).statuses;

export const users = (state) => admin(state).users;
export const loading = (state) => admin(state).loading;

export const roles = (state) => admin(state).roles;
// export const permissions = (state) => admin(state).permissions;

export const dtpSettings = (state) => admin(state).dtpSettings;
export const dtpSettingsLoading = (state) => admin(state).dtpSettingsLoading;

// export const currentUser = (state) => admin(state).currentUser;
// export const loadingCurrentUser = (state) => admin(state).loadingCurrentUser;

export const newPassword = (state) => admin(state).newPassword;
export const resettingPassword = (state) => admin(state).resettingPassword;

// export const settings = (state) => admin(state).settings;
// export const loadingSettings = (state) => admin(state).loadingSettings;

export const userActivity = (state) => admin(state).userActivity;
export const loadingUserActivity = (state) => admin(state).loadingUserActivity;

// export const methods = (state) => admin(state).methods;
// export const microservices = (state) => admin(state).microservices;
// export const actions = (state) => admin(state).actions;

export const loadingButton = (state) => admin(state).loadingButton;


