import { useState } from 'react';

import { loadDtpLoadTypes } from 'redux/Incidents/actions';
import DateRange from 'components/common/Dates/DateRange';
import { useStoreProp } from 'helpers/hooks';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const Filters = ({ filter = {}, setFilter, onReset, onSearch, tab, initialState, isArea, isGraph }) => {
    const dtpLoadTypes = useStoreProp(loadDtpLoadTypes, 'incidents', 'loadTypes');
    const isDisabledHandler = (value) => setIsDisabled(value);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleChange = (name, value) => {
        const newFiler = {
            ...filter,
            [name]: value,
        };
        setFilter(newFiler);
    };

    const resetFilter = (needRefresh) => {
        if (needRefresh) {
            onReset();
        }
        setFilter(initialState);
    };

    const updateFilter = (filters) => {
        const params = {
            ...initialState,
            ...filters,
        };
        setFilter(params);
        onSearch(params);
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={onSearch} 
            filter={filter} 
            filterException={['start_date', 'end_date']} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            countException={['is_area', 'is_graph']}
            setUserFilter={updateFilter}  // если у пользователя сохранен фильтр для этой страницы, мы его из общего компонента сетаем сюда
            disabled={isDisabled}
            saveButtonFilterNoDisabled={isArea || isGraph}
            tab={tab}
        >
            <LayoutFilter.Visible>
                <div className="row noMargin">
                    <DateRange
                        valueStartDate={filter.start_date}
                        valueEndDate={filter.end_date}
                        handleDate={(value) => handleChange('start_date', value)}
                        handleEndDate={(value) => handleChange('end_date', value)}
                        isDisabledHandler={isDisabledHandler}
                    />
                </div>
                <CommonAutocomplete
                    multiple
                    selected={filter.type_load_list}
                    isOptionEqualToValue={(option, value) => option?.toString() === value?.toString()}
                    options={Object.keys(dtpLoadTypes)}
                    filterSelectedOptions
                    renderLabel={(option) => dtpLoadTypes[option]}
                    onChange={(val) => handleChange('type_load_list', val)}
                    label="Источник создания"
                    inputName="type_load_list"
                    onReset={() => handleChange('type_load_list', [])}
                    limitTags={2}
                    showAsSelect
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;
