import React, { useContext } from 'react';
import { ListItem, ListItemButton } from '@mui/material';

import { ListContext } from '../helper';

import ContentColumn from './ContentColumn';

// строка записи
const ContentItem = ({
    children,
    onClick = null,
    style = {},
    // колонки массивом объектов (или children)
    cols = [], // { content, параметры }
    isMobile = false,
    needFocus = false
}) => {
    // получаем заголовки из контекста
    const context = useContext(ListContext);

    const headers = context?.headers || [];
    const childProps = context?.childProps || {};

    const renderChilds = () => {
        // если передано cols
        if (cols?.length > 0) {
            return cols.map(({ content, ...props }, index) => (
                <ContentColumn
                    {...headers?.[index] || {}}
                    {...childProps}
                    {...props}
                >
                    {content}
                </ContentColumn>
            ));
        }

        // children
        return React
            .Children
            .toArray(children)
            .filter((e) => e?.type)
            .map((child, index) => (
                <child.type
                    key={index}
                    index={index}
                    {...(headers?.[index] || {})}
                    {...childProps}
                    {...child.props}
                />
            ));
    };

    return (<>
        {onClick
            ? <ListItemButton
                autoFocus={needFocus}
                onClick={onClick}
                style={style}
                className={isMobile ? 'open_item' : 'list_item'}
            >
                {renderChilds()}
            </ListItemButton>
            : <ListItem
                style={style}
                className={isMobile ? 'open_item' : 'list_item'}
            >
                {renderChilds()}
            </ListItem>
        }
    </>);
};

export default ContentItem;
