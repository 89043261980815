import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';

import { transportPassengerSelectors } from 'redux/TransportPassenger';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import removeEmptyFields from 'helpers/removeEmptyFields';
import SelectCompany from 'components/common/Autocomplete/Companies';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';

import { vehiclesTransportPassengerURI } from './moduleConfig';

function Filters ({ setParams }) {
    const vehicleCategories = useSelector(transportPassengerSelectors.vehicleCategories);

    const initialState = {
        number: '',
        type: '',
        category_id: '',
        in_work_order: false,
        serial_egts: '',
        organizations: []
    };

    const [data, setData] = useState(initialState);

    const onChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const prepareFilters = (filters) => {
        const { organizations, ...restFilters } = filters;

        return removeEmptyFields({ ...restFilters, organization_id: organizations.map(item => item.id) });
    };

    const onSearch = () => {
        setParams({ page: 1, data: prepareFilters(data) });
    };

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const resetFilter = (needRefresh) => {
        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }
        setData(initialState);
    };

    const updateFilter = (filters) => {
        if (Object.keys(filters).length > 0) {
            const mergedFilters = { ...initialState, ...filters };

            setData(mergedFilters); // обновляем локальный стейт
            setParams({ page: 1, data: prepareFilters(mergedFilters) }); // отправляем в родителя, чтобы произошел запрос
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={onSearch}
            filter={data}
            setUserFilter={updateFilter}  // если у пользователя сохранен фильтр для этой страницы, мы его из общего компонента сетаем сюда
            test_id_prefix={vehiclesTransportPassengerURI}
        >
            <LayoutFilter.Visible>
                <TextField
                    variant="outlined"
                    value={data.number}
                    onChange={onChange}
                    label={titles.GRZ}
                    size="small"
                    name={'number'}
                    inputProps={{ 'data-testid': `${vehiclesTransportPassengerURI}:filter/number/input` }}
                />
                <FormControl variant="outlined" size="small">
                    <InputLabel>{titles.TYPE_OF_VEHICLE}</InputLabel>
                    <Select
                        label={titles.TYPE_OF_VEHICLE}
                        value={data.category_id}
                        onChange={onChange}
                        name={'category_id'}
                        inputProps={{ 'data-testid': `${vehiclesTransportPassengerURI}:filter/category/input` }}
                    >
                        <MenuItem value="">{messages.NOT_CHOSEN}</MenuItem>
                        {vehicleCategories?.length
                            ? vehicleCategories.map((item) =>
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            )
                            : <MenuItem value=" ">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
                <SelectCompany
                    multiple
                    selected={data.organizations}
                    onChange={(value) => handleChange('organizations', value)}
                    filter={{ withDeleted: 1 }}
                    selectDeletedCompanies
                    test_id_prefix={`${vehiclesTransportPassengerURI}:filter/organizations`}
                />
                <TextField
                    variant="outlined"
                    value={data.serial_egts}
                    onChange={onChange}
                    label="Серийный номер для EGTS"
                    size="small"
                    name="serial_egts"
                    inputProps={{ 'data-testid': `${vehiclesTransportPassengerURI}:filter/serial_egts/input` }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data?.in_work_order || false}
                            onChange={(event) =>
                                setData({
                                    ...data,
                                    in_work_order: event.target.checked,
                                })
                            }
                            color="primary"
                            inputProps={{
                                'data-testid': `${vehiclesTransportPassengerURI}:filter/in_work_order/input`
                            }}
                        />
                    }
                    label={
                        <Typography variant="body2">
                            Только в нарядах
                        </Typography>
                    }
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
}

export default Filters;
