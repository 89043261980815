import React from 'react';
import useClipboard from 'react-use-clipboard';
import { showMessage } from '../../../../../redux/Message/actions';
import messagetypes from '../../../../../redux/Message/messagetypes';
import { IconButton, Tooltip } from '@mui/material';

// скопировать текст в буфер обмена
const TextCopyClipboard = ({
    text = '',
}) => {
    const [isCopied, setCopied] = useClipboard(
        text,
        {
            successDuration: 6000,
        }
    );

    const handleCopyToClipboard = (e) => {
        setCopied(e);
        showMessage(messagetypes.success, 'Текст скопирован в буфер обмена');
    };

    return (
        <Tooltip
            title={isCopied
                ? 'Текст скопирован в буфер обмена'
                : 'Cкопировать'
            }
        >
            <IconButton
                onClick={handleCopyToClipboard}
                size="small"
            >
                {isCopied
                    ? <i className="fas fa-copy" />
                    : <i className="far fa-copy" />
                }
            </IconButton>
        </Tooltip>
    );
};

export default TextCopyClipboard;