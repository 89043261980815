import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { incidentsSelectors } from 'redux/Incidents';
import { loadDtpTypes } from 'redux/Incidents/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import Context from 'helpers/context';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';

import DTPTypesItem from './DTPTypesItem';

const DTPTypes = () => {
    const dispatch = useDispatch();

    const { permissions } = useContext(Context);
    const dtp_types = useSelector(incidentsSelectors.dtp_types);
    const loading = useSelector(incidentsSelectors.loadingDtpTypes);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);


    useEffect(() => {
        dispatch(loadDtpTypes(page, limit));
    }, [dispatch, page, limit]);

    return (
        <PageLayout
            header={titles.DTP_TYPES}
            loading={loading}
            content={() => (
                <>
                    {dtp_types?.data?.length > 0
                        ? (
                            <LSContainer
                                headers={[
                                    { title: '', width: '35px' },
                                    { title: titles.NAME },
                                    { title: 'Действия', isActions: true }
                                ]}
                            >
                                {dtp_types?.data?.map((item) => (
                                    <DTPTypesItem key={item.id} item={item} permissions={permissions} />
                                ))}
                            </LSContainer>
                        )
                        : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
                    }
                </>)
            }
            informPanelProps={{                
                total: dtp_types.meta?.total
            }}
            paginationProps={{
                loadList: (page) => setPage(page),
                list: dtp_types.meta,
                limit,
                setLimit
            }}
        />
    );
};

export default DTPTypes;
