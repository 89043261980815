import L from 'leaflet';

import removeEmptyFields from 'helpers/removeEmptyFields';
import colorExtend from 'helpers/mapHelper/colorExtend';
import {
    fullDateTimeWithTimeZone,
    getStartOf,
    getEndOf,
} from 'helpers/date.config';
import { getMarker } from '../../marker/Marker';
import config from './config';

export const getColor = (item) => {
    return item?.color ? colorExtend(item?.color) : config.mapMarkersColors.default;
};

export const createIcon = (color) => {
    return `<i class="${config.layerIcon}" style="color:${color}" />`;
};

// получить иконку маркера
export const createIconMarker = (item) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],

        // iconSize: [20, 30],
        // iconAnchor: [10, 30],
        // popupAnchor: [0, -30],
        html: `
            ${getMarker(getColor(item))}
            <i class="${config.layerIcon}"
               style="width:100%;color:#fff;position:absolute;left:0;top:0;height:calc(100% - 9px);display:flex;justify-content:center;align-items:center;font-size:1rem;"
            />
        `,
    });
};

export const getFilter = (filter) => {
    const filters = {
        ...filter,
        start_at: filter.start_at ? fullDateTimeWithTimeZone(getStartOf('day',filter.start_at)) : null,
        end_at: filter.end_at ? fullDateTimeWithTimeZone(getEndOf('day', filter.end_at)) : null,
        status: filter?.status || null,
        type: filter?.type || null
    };

    return removeEmptyFields(filters, false);
};

// export const getItemToSave = (item) => {
//     const ret = { ...item };
//     if (
//         !ret.hasOwnProperty('dtp_ids')
//         || ret?.dtp_ids?.length === 0
//     ) ret.dtp_ids = ret.dtp_list.map(i => i.id) ?? [];
//
//     // задать имя из id дтп
//     if (ret.name?.length === 0 && ret?.dtp_ids?.length > 0) {
//         const name = [`${ret?.dtp_ids?.[0]}`];
//         if (ret?.dtp_ids.length > 1) name.push(`${ret?.dtp_ids?.[ret?.dtp_ids.length - 1]}`);
//         ret.name = name.join(' - ');
//     }
//
//     return ret;
// };
