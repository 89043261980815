import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';

import { fullDateTime, getDateToFormat } from 'helpers/date.config';
import colorExtend from 'helpers/mapHelper/colorExtend';
import * as actions from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';


const History = (props) => {
    const {
        uuid = null,
        data = null,
        startDate = new Date(),
        endDate = new Date(),
        moveToAfterInit = false,
        ...params
    } = props;

    const polyRef = useRef([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (uuid) {
            dispatch(actions.loadVehicleHistory(uuid, {
                date_start: getDateToFormat(startDate, 'yyyy-MM-dd\'T\'HH:mm:ss'),
                date_end: getDateToFormat(endDate, 'yyyy-MM-dd\'T\'HH:mm:ss'),
            }));
        }
    }, [uuid]);

    useEffect(() => {
        return () => {
            dispatch(actions.clearVehicleHistory());
        };
    }, []);

    const vehicleHistory = useSelector(transportPassengerSelectors.vehicleHistory);

    const currentData = useMemo(() => {
        if (uuid) {
            return vehicleHistory?.reduce((res, { lat, lon, color, speed, created_at }) => {
                return [
                    ...res,
                    { lat, lon, color: colorExtend(color), speed, created_at },
                ];
            }, []);
        }
        return data;
    }, [uuid, data, vehicleHistory]);

    // закостылено на чистых Leaflet компонентах
    useEffect(() => {
        if (currentData.length > 0) {
            const {
                parent,
                map,
            } = params;

            if (moveToAfterInit) {
                const allLine = L.polyline(currentData.map(({ lat, lon }) => ({ lat, lon })));
                const boundce = allLine.getBounds();
                map.fitBounds(boundce);
                allLine.remove();
            }

            Object.keys(currentData).map(index => {
                if (index < currentData.length - 1) {
                    const {
                        lat, lon, color = '#134a0d', speed, created_at
                    } = currentData[index];
                    const nextPoint = currentData[parseInt(index) + 1];

                    const oneLine = L
                        .polyline(
                            [
                                [lat, lon],
                                [nextPoint.lat, nextPoint.lon]
                            ],
                            {
                                color: color,
                                weight: 5,
                                smoothFactor: 1
                            }
                        );

                    const html = `
                        <div><strong>Дата, время: </strong>${created_at ? fullDateTime(created_at) : 'нет данных'}</div>
                        <div><strong>Скорость: </strong>${speed >= 0 ? `${speed} км/ч` : 'нет данных'}</div>
                    `;
                    const toolTip = L.tooltip({
                        sticky: true,
                        direction: 'top',
                        offset: [0, -5],
                    }).setContent(html);
                    oneLine.bindTooltip(toolTip);

                    parent.addLayer(oneLine);

                    polyRef.current.push(oneLine);
                }
            });

            // moveToAfterInit && moveToAfterInit();

            return () => {
                while (polyRef.current.length > 0) {
                    const oneLine = polyRef.current.pop();
                    parent.removeLayer(oneLine);
                }
            };
        }
    }, [currentData]);

    return null;

    // не костыль
    // return (
    //     <>
    //         {currentData?.length > 0 && Object.keys(currentData).map(index => {
    //             if (index < currentData.length - 1) {
    //                 const {
    //                     lat, lon, color, speed, created_at
    //                 } = currentData[index];
    //                 const cD = currentData[parseInt(index) + 1];
    //
    //                 return (
    //                     <PolyLine
    //                         {...params}
    //                         key={index}
    //                         coordinates={[
    //                             [lat, lon],
    //                             [cD.lat, cD.lon]
    //                         ]}
    //                         moveToAfterInit={moveToAfterInit}
    //                         style={{
    //                             color: color,
    //                             weight: 5,
    //                             smoothFactor: 1
    //                         }}
    //                     >
    //                         <ToolTip
    //                             sticky={true}
    //                             direction="top"
    //                             offset={[0, -5]}
    //                         >
    //                             <div>
    //                                 <strong>Дата, время: </strong>{created_at || 'нет данных'}
    //                             </div>
    //                             <div>
    //                                 <strong>Скорость: </strong>{speed || 'нет данных'}
    //                             </div>
    //                         </ToolTip>
    //                     </PolyLine>
    //                 );
    //             }
    //             return null;
    //         })}
    //     </>
    // );
};

export default History;
