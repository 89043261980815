import React, { useEffect, useState } from 'react';
import styles from './mapform.module.scss';
import { CustomControl, Map, MapControl, GeoJson, FeatureGroup } from '../../../index';
import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import { config } from '../../../../../../config';
import MapDragGeoJson from '../MapDragGeoJson';
import MapFinder from '../../../../../common/MapContlols/MapFinder';
import { usePrevious } from '../../../../../../helpers/hooks';
import { isEqual } from 'lodash';

const MapFormGeoJSON = (props) => {
    const {
        geometry = {},
        onClose = () => {
        },
        onAccept = () => {
        },
        readOnly = false,
        showButtons = true,
        mapCenter = config.get('mapCenter'),
        height = '',
        width = '',
        margin = '',
        showFinder = true,
        visibleGeometry = null,
        ...prop
    } = props;

    // const mapCenter = config.get('mapCenter');
    const [mapGeometry, setGeometry] = useState(geometry || {});

    const prevGeometry = usePrevious(geometry);

    useEffect(() => {
        if(!isEqual(prevGeometry, geometry)){
            setGeometry(geometry);
        }        
    }, [geometry, prevGeometry]);

    //добавил для отправки в GeoJSONCoordinatesMapDislocation
    // useEffect(() => {
    //     onAccept(mapGeometry);
    // },[mapGeometry]);

    const acceptMapModal = (e) => {
        e.preventDefault();
        onAccept(mapGeometry);
    };

    const closeMapModal = (e) => {
        e.preventDefault();
        onClose();
    };

    const handleChange = (newGeometry) => {
        setGeometry(newGeometry);
        if(!showButtons){
            onAccept(newGeometry);
        }
    };

    const checkDisabledButton = !mapGeometry || Object.keys(mapGeometry).length === 0;

    const RR = (prop) => {
        if (showFinder) {
            return (
                <CustomControl position="topright" className="msc-wrapper" {...prop}>
                    <MapFinder />
                </CustomControl>
            );
        }
        return null;
    };

    return (
        <>
            <div style={{ height, width, margin }} className={styles.map_container}>
                <Map
                    center={mapCenter}
                >
                    <MapDragGeoJson
                        geometry={mapGeometry}
                        onChange={handleChange}
                        readOnly={readOnly}
                        {...prop}
                    />
                    <FeatureGroup>
                        {visibleGeometry
                            && (
                                <GeoJson
                                    data={visibleGeometry}
                                    centerAfter={checkDisabledButton}
                                />
                            )
                        }
                    </FeatureGroup>
                    {/* подключение поиска на карте */}
                    <RR />
                </Map>
            </div>
            {showButtons
                && <FormButtons
                    inModal
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: closeMapModal,
                        },
                        ...(!props.readOnly ? ([{
                            ...buttonsTypes.apply,
                            onClick: acceptMapModal,
                            disabled: checkDisabledButton
                        }]) : [])
                    ]}
                />}
        </>
    );
};

export default MapFormGeoJSON;
