import { useSelector } from 'react-redux';

import icon from '../icons/icon';
import FrameButton from '../FrameButton';

import Information from './Information';

const PopUp = (props) => {
    const {
        external_id,
    } = props;

    const {
        polygon,
    } = useSelector(({ videoCameras }) => videoCameras);
    const data = polygon.find(({ id }) => external_id === id) || {};

    const status_color = data?.status_color || 0;

    const { name } = data;

    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: status_color }}>
                <div>
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                    <span>
                        {name}
                    </span>
                </div>
                <FrameButton 
                    className="action" 
                    cameraIds={[data.id]}
                    style={{ alignSelf: 'start', marginTop: '0.15rem' }}
                />
            </div>
            <div className="map-popup_body">
                <Information
                    data={data}
                />
            </div>
        </div>
    );
};

export default PopUp;
