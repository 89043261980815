import React, { useEffect, useState } from 'react';
import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import titles from '../../../../../helpers/constants/titles';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, TextField } from '@mui/material';
import * as actions  from '../../../../../redux/SituationalPlans/actions';
import { setStatusDanger } from '../../../../../redux/SituationalPlans/actions';
import { useValidation } from '../../../../../helpers/hooks';

const DangerModal = ({ isOpen, onClose, isNew, el = {}, reloadList }) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const initialState = {
        name: el.name || '',
    };

    const [data, setData] = useState(initialState);

    const onChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleCreate = () => {
        dispatch(actions.createDanger(data, reloadList));
    };

    const handleEdit = (id) => {
        dispatch(actions.editDanger(id, data, reloadList));
    };

    const isDisabled = !data.name;

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            dispatch(setStatusDanger(false));
            validation.clear();
        }
    }, [dispatch, onClose, requestStatus.success, validation]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    isNew
                        ? {
                            ...buttonsTypes.create,
                            onClick: handleCreate,
                            disabled: isDisabled
                        }
                        : {
                            ...buttonsTypes.save,
                            onClick: () => handleEdit(el.id),
                            disabled: isDisabled
                        }
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl required className="block" size="small" variant="outlined">
                    <TextField
                        required
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={data.name || ''}
                        name={'name'}
                        onChange={onChange}
                        type="text"
                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                        error={validation.isKey('name')}
                        helperText={validation.get('name')}
                    />
                </FormControl>
            </div>
        </Modal>
    );
};

export default DangerModal;
