import React, { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import messages from '../../../../../helpers/constants/messages';
import makeStyles from '@mui/styles/makeStyles';
import TAccordionInfo from './TAccordionInfo';
import { loadTariffsList } from '../../../../../redux/TransportPassenger/actions';
import CustomPagination from '../../../../common/CustomPagination';
import { useDispatch } from 'react-redux';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary
} from '@mui/material';

const useStyles = makeStyles({
    preview: {
        display: 'flex',
        flexDirection: 'column'
    },
    collapse: {
        display: 'flex',
        flexDirection: 'column',
        background: '#f5f5f5'
    },
    head: {
        margin: '8px 0',
        boxShadow: 'none',
        borderBottom: '1px solid #d9d9d9',
        '&:before': {
            display: 'none'
        }
    },
    noData: {
        padding: '20px 0'
    }
});

const TAccordion = ({
    tariffs_data,
    tsTypes,
    setTypeQuery,
    params,
    typeQuery
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [expanded, setExpanded] = useState(0);
    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);

    const handleOpenDeployedInfo = (panel, type) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        if (isExpanded) {
            dispatch(loadTariffsList(1, limit, params, { type_transportation: type }));
            setTypeQuery({ type_transportation: type });
        }
    };

    return (
        Object.keys(tsTypes).map((el, index) => (
            <div key={el}>
                {el === '1'
                   && <h2 style={{ padding: '20px 0' }}>Межмуниципальные перевозки: </h2>
                }
                {el === '3'
                    && <h2 style={{ padding: '20px 0' }}>Муниципальные перевозки: </h2>
                }
                <Accordion
                    expanded={expanded === index}
                    onChange={handleOpenDeployedInfo(index, el)}
                    className={classes.head}
                >
                    <AccordionSummary expandIcon={<ExpandLess />}>
                        <div className={classes.preview}>
                            <span><strong>Классификация маршрута:</strong> {tsTypes[el] || messages.NO_DATA}</span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.collapse}>
                        {tariffs_data[el]?.data?.length > 0
                            ? <>
                                {tariffs_data[el]?.data.map((item, i) => (
                                    <TAccordionInfo
                                        key={item.id}
                                        item={item}
                                        i={i}
                                        tsTypes={tsTypes}
                                        typeQuery={typeQuery}
                                    />
                                ))}
                                <CustomPagination
                                    loadList={(page, limit) => dispatch(loadTariffsList(page, limit, { type_transportation: el }))}
                                    list={tariffs_data[el]?.meta}
                                    limit={limit} 
                                    setLimit={setLimit}
                                />
                            </>
                            : <div className={classes.noData}>{messages.DATA_IS_NOT_FOUND}</div>
                        }
                    </AccordionDetails>
                </Accordion>
            </div>
        ))
    );
};

export default TAccordion;