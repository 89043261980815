import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../../../../../../helpers/constants/messages';
import { useValidation } from '../../../../../../../../../helpers/hooks';
import { loadComputerTransferRules, loadComputerTransferTypes } from '../../../../../../../../../redux/DorisControl/actions';
import RenderTransferFields from './RenderTransferFields';
import { dorisControlSelectors } from '../../../../../../../../../redux/DorisControl';

// transfer types для вычислительного модуля
const TransferTypeForm = ({ data, setData, currentComplex }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    useEffect(() => {
        if (data?.transfer_type) {
            dispatch(loadComputerTransferRules(data?.transfer_type));
        }

    },[data?.transfer_type, dispatch]);

    useEffect(() => {
        if (currentComplex.type_id !== undefined || currentComplex.type_id !== null) {
            dispatch(loadComputerTransferTypes(
                { complex_type: currentComplex.type_id }
            ));
        }
    }, [currentComplex.type_id, dispatch]);

    const computerTransferRules = useSelector(dorisControlSelectors.computerTransferRules);
    const computerTransferTypes = useSelector(dorisControlSelectors.computerTransferTypes);

    useEffect(() => {
        setData((prev) => {
            const newData = {};
            computerTransferRules.map((el) => {
                if (!prev[el?.field]) {
                    newData[el?.field] = el.default;
                }
            });
            return {
                ...prev,
                ...newData
            };
        });
    }, [computerTransferRules, setData]);

    const onChangeTransferTypes = (name, value) => {
        const currentData = { 
            ...data,
            [name]: value
        }; // делаем копию даты чтобы поудалять если есть ненужные поля
        computerTransferRules.forEach( el => (
            delete currentData[el.field]
        ));

        setData(currentData);

        validation.deleteKey(name);
    };

    const onChangeFields = (name, value) =>  {
        setData((prev) => ({
            ...prev,
            [name]: value
        }));
        validation.deleteKey(name);
    };

    return (
        <>
            <FormControl 
                className="block" 
                size="small"
                required 
                variant="outlined"
                error={!data?.transfer_type || validation.isKey('transfer_type')}
            >
                <InputLabel
                >
                    Источник получения данных
                </InputLabel>
                <Select
                    value={data.transfer_type || ''}
                    required
                    label="Источник получения данных"
                    onChange={(e) => onChangeTransferTypes(e.target.name, e.target.value)}
                    name="transfer_type"
                    error={!data?.transfer_type || validation.isKey('transfer_type')}
                >
                    {Object.keys(computerTransferTypes)?.length > 0
                        ? Object.keys(computerTransferTypes)?.map((key) =>
                            <MenuItem value={key} key={key}>{computerTransferTypes[key]}</MenuItem>
                        )
                        : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                    }
                </Select>
                {validation.isKey('transfer_type') 
                    && <FormHelperText error>{validation.get('transfer_type')}</FormHelperText>
                }
            </FormControl>
            {computerTransferRules.length > 0
                && computerTransferRules.map((el) => {
                    return <RenderTransferFields
                        key={el.field}
                        element={el} 
                        currentData={data}
                        handleChange={onChangeFields}
                    />;
                })
            }
        </>
    );
};

export default TransferTypeForm;
