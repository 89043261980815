import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiAipMethods = {
    getAipList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getAipList(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    getAipEvents: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getAipEvents(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    getForPolygon: async (params) => {
        const response = await instance.post(
            apiUrls.getAipPolygon(),
            params,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
        return response?.data?.data;
    },

    getSidebar: async (params) => {
        const response = await instance.get(apiUrls.getSidebar(), {
            params,
        });
        return response?.data;
    },

    createAip: async (data) => {
        const response = await instance.post(apiUrls.createAip(), data);
        return response?.data;
    },

    editAip: async (id, data) => {
        const response = await instance.put(apiUrls.editAip(id), data);
        return response?.data;
    },

    deleteAip: async (id) => {
        const response = await instance.delete(apiUrls.deleteAip(id));
        return response?.data;
    },

    getAipTypes: async () => {
        const response = await instance.get(apiUrls.getAipTypes());
        return response?.data;
    },
    
    getAipOperators: async () => {
        const response = await instance.get(apiUrls.getAipOperators());
        return response?.data;
    },

};

export default ApiAipMethods;