import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
    createCompany, deleteFiliation,
    editCompany,
    gotStandardizedCompaniesList,
    loadCurrentFiliationList
} from 'redux/Companies/actions';
import { companiesSelectors } from 'redux/Companies';
import buttons from 'helpers/constants/buttons';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import messages from 'helpers/constants/messages/common';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

import BranchModal from './BranchModal';

const formatObj = (obj = {}, name) => {
    if (Array.isArray(obj)) {
        const newArray = (name === 'emails')
            ? obj.map(el => {
                if (el.data) {
                    return {
                        person: el.person,
                        email: el.data
                    };
                } else {
                    return el;
                };
            })
            : obj;
        return newArray;
    };
    const currentfield = name === 'emails' ? 'email' : 'data';
    return Object.keys(obj).map((key) => {
        return { person: key, [currentfield]: obj[key] };
    });
};

const StandardizedCompanyForm = ({ isNew = false, open, setOpenModal, item={}, isOpenModalForm = () => {}, reloadList }) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);
    const [branchModalOpen, setBranchModalOpen] = useState(false);
    const [editBranchModalOpen, setEditBranchModalOpen] = useState('');
    const [confirmOpen, setConfirmOpen] = useState('');

    const reloadFiliations = () => {
        setConfirmOpen('');
        dispatch(loadCurrentFiliationList(1, 25, { organization_id: item?.id }));
    };

    const deleteFiliationItem = () => {
        dispatch(deleteFiliation(confirmOpen, reloadFiliations));
    };

    const closeForm = () => setOpenModal(false);

    const currentFiolations = useSelector(companiesSelectors.currentFiolations);
    const loadingCurrentFiliations = useSelector(companiesSelectors.loadingCurrentFiliations);

    useEffect(() => {
        if (requestStatus.success) {
            validation.clear();
            setOpenModal(false);
        }
    }, [validation, requestStatus, setOpenModal]);

    useEffect(() => {
        if (item?.id) {
            dispatch(loadCurrentFiliationList(1, 25, { organization_id: item?.id }));
        }
    }, []);

    const [formData, setFormData] = useState({
        juristic_address: {
            city_name: item?.address?.city_name || item?.juristic_address?.city_name || '',
            region: item?.address?.region || item?.juristic_address?.region || '',
            postal_code: item?.address?.postal_code || item?.juristic_address?.postal_code || '',
            street: item?.address?.street || item?.juristic_address?.street || '',
            house: item?.address?.house || item?.juristic_address?.house || '',
            comments: item?.address?.comments || item?.juristic_address?.comments || '',
        },
        mail_address: {
            city_name: item?.address?.city_name || item?.mail_address?.city_name || '',
            region: item?.address?.region || item?.mail_address?.region || '',
            postal_code: item?.address?.postal_code || item?.mail_address?.postal_code || '',
            street: item?.address?.street || item?.mail_address?.street || '',
            house: item?.address?.house || item?.mail_address?.house || '',
            comments: item?.address?.comments || item?.mail_address?.comments || '',
        },
        real_address: {
            city_name: item?.address?.city_name || item?.real_address?.city_name || '',
            region: item?.address?.region || item?.real_address?.region || '',
            postal_code: item?.address?.postal_code || item?.real_address?.postal_code || '',
            street: item?.address?.street || item?.real_address?.street || '',
            house: item?.address?.house || item?.real_address?.house || '',
            comments: item?.address?.comments || item?.real_address?.comments || '',
        },
        title: item?.name?.short_with_opf || item?.title || '',
        full_name: item?.name?.full_with_opf || item?.full_name || '',
        inn: item?.inn || '',
        phones: formatObj(item?.phones, 'phones'),
        emails: formatObj(item?.emails, 'emails'),
    });

    const onChange = (e, obj_name) => {
        const { name, value } = e.target;
        if (obj_name) {
            setFormData({ ...formData, [obj_name]: { ...formData[obj_name], [name]: value } });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const isOpenModalFormHandler = (status) => isOpenModalForm(status);

    const noErrorCallback = () => {
        isOpenModalFormHandler(false);
        dispatch(gotStandardizedCompaniesList([]));
        reloadList();
    };

    const saveForm = () => {
        item?.id
            ? dispatch(editCompany(item?.id, formData, noErrorCallback))
            : dispatch(createCompany(formData, noErrorCallback));
    };

    const handleAddArrItem = (section) => () => {
        setFormData({ ...formData, [section]: [...formData[section], {}] });
    };

    const removeItemFromArray = (section, index) => () => {
        setFormData({
            ...formData,
            [section]: [
                ...formData[section].slice(0, index),
                ...formData[section].slice(index + 1),
            ],
        });
    };


    const handleChangeItem = (section, index) => (event) => {
        const { name, value } = event.target;
        const newArr = [...formData[section]];
        newArr[index] = { ...newArr[index], [name]: value };
        setFormData({ ...formData, [section]: newArr });
    };

    const renderDataArr = (label, name) => {
        return formData[name]?.map(({ person, data, email }, index) => (
            <div key={index} className="row">
                <FormInfoWrapper
                    className="row__item"
                    error={!person || validation.isKey('person')}
                    helperText={validation.get('person')}
                >
                    <TextField
                        value={person || ''}
                        name={'person'}
                        onChange={handleChangeItem(name, index)}
                        className="row__item"
                        label={'Ответственный'}
                        variant={'outlined'}
                        size={'small'}
                        error={!person || validation.isKey('person')}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper
                    className="row__item"
                    error={
                        name === 'emails'
                            ? validation.isKey('email')
                            : validation.isKey('data')
                    }
                    helperText={
                        name === 'emails'
                            ? validation.get('email')
                            : validation.get('data')
                    }
                >
                    <TextField
                        value={name === 'emails' ? email : data}
                        name={name === 'emails' ? 'email' : 'data'}
                        onChange={handleChangeItem(name, index)}
                        label={label}
                        variant={'outlined'}
                        size={'small'}
                        error={
                            name === 'emails'
                                ? !email || validation.isKey('email')
                                : !data || validation.isKey('data')
                        }
                    />
                </FormInfoWrapper>
                <IconButton onClick={removeItemFromArray(name, index)} size={'small'}>
                    <CloseIcon />
                </IconButton>
            </div>
        ));
    };

    const getErrorValidation = (key) => {
        return validation[key] || false;
    };

    return (
        <Modal
            isOpen={open}
            onClose={closeForm}
            noPadding
            title={titles.COMPANY_INFORMATION}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: closeForm
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: saveForm
                    },
                ]}
            />}
        >
            <div>
                <form autoComplete="off" className="modal__form">
                    <FormInfoWrapper
                        className={'block'}
                        error={!formData.full_name || !!getErrorValidation('full_name')}
                        helperText={getErrorValidation('full_name')}
                    >
                        <TextField
                            label={titles.FULL_NAME}
                            size="small"
                            value={formData.full_name}
                            variant="outlined"
                            name="full_name"
                            type="text"
                            onChange={onChange}
                            required
                            disabled={isNew ? false : true}
                            error={!formData.full_name || !!getErrorValidation('full_name')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className={'block'}
                        error={!formData.title || validation.isKey('title')}
                        helperText={validation.get('title')}
                    >
                        <TextField
                            label={titles.SHORT_TITLE}
                            size="small"
                            value={formData.title}
                            variant="outlined"
                            name="title"
                            type="text"
                            onChange={onChange}
                            required
                            error={!formData.title || validation.isKey('title')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className={'block'}
                        error={!formData.inn || validation.isKey('inn')}
                        helperText={validation.get('inn')}
                    >
                        <TextField
                            label={titles.INN}
                            size="small"
                            value={formData?.inn}
                            variant="outlined"
                            name="inn"
                            type="number"
                            onChange={onChange}
                            required
                            disabled={isNew ? false : true}
                            error={!formData.inn || validation.isKey('inn')}
                        />
                    </FormInfoWrapper>

                    <div className="block">
                        <h2>{titles.PHONES}:</h2>
                        {renderDataArr('Номер', 'phones')}
                        <FormButtonsComponent
                            noPadding
                            noMarginLeft
                            positionLeft
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: handleAddArrItem('phones'),
                                }
                            ]}
                        />
                    </div>

                    <div className="block">
                        <h2>{titles.EMAIL}:</h2>
                        {renderDataArr('Адрес электронной почты', 'emails')}
                        <FormButtonsComponent
                            noPadding
                            noMarginLeft
                            positionLeft
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: handleAddArrItem('emails'),
                                }
                            ]}
                        />
                    </div>


                    <h2>{titles.LEGAL_ADDRESS}:</h2>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.juristic_address?.city_name || validation.isKey('city_name')}
                        helperText={validation.get('city_name')}
                    >
                        <TextField
                            label={titles.INPUT_CITY}
                            size="small"
                            value={formData?.juristic_address?.city_name}
                            variant="outlined"
                            name="city_name"
                            type="text"
                            onChange={(e) => onChange(e, 'juristic_address')}
                            required
                            disabled={!item?.address?.city_name && !item.juristic_address?.city_name ? false : true}
                            error={!formData.juristic_address?.city_name || validation.isKey('city_name')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.juristic_address?.region || validation.isKey('region')}
                        helperText={validation.get('region')}
                    >
                        <TextField
                            label={titles.INPUT_REGION}
                            size="small"
                            value={formData?.juristic_address?.region}
                            variant="outlined"
                            name="region"
                            type="text"
                            onChange={(e) => onChange(e, 'juristic_address')}
                            required
                            disabled={!item?.address?.region && !item.juristic_address?.region ? false : true}
                            error={!formData.juristic_address?.region || validation.isKey('region')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.juristic_address?.postal_code || validation.isKey('postal_code')}
                        helperText={validation.get('postal_code')}
                    >
                        <TextField
                            label={titles.INPUT_POSTAL_CODE}
                            size="small"
                            value={formData?.juristic_address?.postal_code}
                            variant="outlined"
                            name="postal_code"
                            type="number"
                            onChange={(e) => onChange(e, 'juristic_address')}
                            required
                            disabled={!item?.address?.postal_code && !item.juristic_address?.postal_code ? false : true}
                            error={!formData.juristic_address?.postal_code || validation.isKey('postal_code')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.juristic_address?.street || validation.isKey('street')}
                        helperText={validation.get('street')}
                    >
                        <TextField
                            label={titles.INPUT_STREET}
                            size="small"
                            value={formData?.juristic_address?.street}
                            variant="outlined"
                            name="street"
                            type="text"
                            onChange={(e) => onChange(e, 'juristic_address')}
                            required
                            disabled={!item?.address?.street && !item?.juristic_address?.street ? false : true}
                            error={!formData.juristic_address?.street || validation.isKey('street')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.juristic_address?.house || validation.isKey('house')}
                        helperText={validation.get('house')}
                    >
                        <TextField
                            label={titles.INPUT_HOUSE}
                            size="small"
                            value={formData?.juristic_address?.house}
                            variant="outlined"
                            name="house"
                            type="text"
                            onChange={(e) => onChange(e, 'juristic_address')}
                            required
                            disabled={!item?.address?.house && !item?.juristic_address?.house ? false : true}
                            error={!formData.juristic_address?.house || validation.isKey('house')}
                        />
                    </FormInfoWrapper>
                    <h2>{'Фактический адрес:'}</h2>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.real_address?.city_name || validation.isKey('city_name')}
                        helperText={validation.get('city_name')}
                    >
                        <TextField
                            label={titles.INPUT_CITY}
                            size="small"
                            value={formData?.real_address?.city_name}
                            variant="outlined"
                            name="city_name"
                            type="text"
                            onChange={(e) => onChange(e, 'real_address')}
                            required
                            error={!formData.real_address?.city_name || validation.isKey('city_name')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.real_address?.region || validation.isKey('region')}
                        helperText={validation.get('region')}
                    >
                        <TextField
                            label={titles.INPUT_REGION}
                            size="small"
                            value={formData?.real_address?.region}
                            variant="outlined"
                            name="region"
                            type="text"
                            onChange={(e) => onChange(e, 'real_address')}
                            required
                            error={!formData.real_address?.region || validation.isKey('region')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.real_address?.postal_code || validation.isKey('postal_code')}
                        helperText={validation.get('postal_code')}
                    >
                        <TextField
                            label={titles.INPUT_POSTAL_CODE}
                            size="small"
                            value={formData?.real_address?.postal_code}
                            variant="outlined"
                            name="postal_code"
                            type="number"
                            onChange={(e) => onChange(e, 'real_address')}
                            required
                            error={!formData.real_address?.postal_code || validation.isKey('postal_code')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.real_address?.street || validation.isKey('street')}
                        helperText={validation.get('street')}
                    >
                        <TextField
                            label={titles.INPUT_STREET}
                            size="small"
                            value={formData?.real_address?.street}
                            variant="outlined"
                            name="street"
                            type="text"
                            onChange={(e) => onChange(e, 'real_address')}
                            required
                            error={!formData.real_address?.street || validation.isKey('street')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.real_address?.house || validation.isKey('house')}
                        helperText={validation.get('house')}
                    >
                        <TextField
                            label={titles.INPUT_HOUSE}
                            size="small"
                            value={formData?.real_address?.house}
                            variant="outlined"
                            name="house"
                            type="text"
                            onChange={(e) => onChange(e, 'real_address')}
                            required
                            error={!formData.real_address?.house || validation.isKey('house')}
                        />
                    </FormInfoWrapper>
                    <TextField
                        className={'block'}
                        label={titles.ADDITIONAL_INFORMATION}
                        size="small"
                        value={formData?.real_address?.comments}
                        variant="outlined"
                        name="comments"
                        type="text"
                        onChange={(e) => onChange(e, 'real_address')}
                    />
                    <h2>{'Почтовый адрес:'}</h2>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.mail_address?.city_name || validation.isKey('city_name')}
                        helperText={validation.get('city_name')}
                    >
                        <TextField
                            label={titles.INPUT_CITY}
                            size="small"
                            value={formData?.mail_address?.city_name}
                            variant="outlined"
                            name="city_name"
                            type="text"
                            onChange={(e) => onChange(e, 'mail_address')}
                            required
                            error={!formData.mail_address?.city_name || validation.isKey('city_name')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.mail_address?.region || validation.isKey('region')}
                        helperText={validation.get('region')}
                    >
                        <TextField
                            label={titles.INPUT_REGION}
                            size="small"
                            value={formData?.mail_address?.region}
                            variant="outlined"
                            name="region"
                            type="text"
                            onChange={(e) => onChange(e, 'mail_address')}
                            required
                            error={!formData.mail_address?.region || validation.isKey('region')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.mail_address?.postal_code || validation.isKey('postal_code')}
                        helperText={validation.get('postal_code')}
                    >
                        <TextField
                            label={titles.INPUT_POSTAL_CODE}
                            size="small"
                            value={formData?.mail_address?.postal_code}
                            variant="outlined"
                            name="postal_code"
                            type="number"
                            onChange={(e) => onChange(e, 'mail_address')}
                            required
                            error={!formData.mail_address?.postal_code || validation.isKey('postal_code')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.mail_address?.street || validation.isKey('street')}
                        helperText={validation.get('street')}
                    >
                        <TextField
                            label={titles.INPUT_STREET}
                            size="small"
                            value={formData?.mail_address?.street}
                            variant="outlined"
                            name="street"
                            type="text"
                            onChange={(e) => onChange(e, 'mail_address')}
                            required
                            error={!formData.mail_address?.street || validation.isKey('street')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="block"
                        error={!formData.mail_address?.house || validation.isKey('house')}
                        helperText={validation.get('house')}
                    >
                        <TextField
                            label={titles.INPUT_HOUSE}
                            size="small"
                            value={formData?.mail_address?.house}
                            variant="outlined"
                            name="house"
                            type="text"
                            onChange={(e) => onChange(e, 'mail_address')}
                            required
                            error={!formData.mail_address?.house || validation.isKey('house')}
                        />
                    </FormInfoWrapper>
                    <TextField
                        className={'block'}
                        label={titles.ADDITIONAL_INFORMATION}
                        size="small"
                        value={formData?.mail_address?.comments}
                        variant="outlined"
                        name="comments"
                        type="text"
                        onChange={(e) => onChange(e, 'mail_address')}
                    />
                    {item?.id
                        && <>
                            <h3 className="block">Филиалы:</h3>
                            <div className="block">
                                {loadingCurrentFiliations && <Loading linear={true}/>}
                                {currentFiolations[item?.id]?.length > 0
                                    ? currentFiolations[item?.id].map((filiation, i) =>
                                        <ListItem key={filiation?.id}>
                                            <ListItemText>
                                                {filiation?.title}
                                            </ListItemText>
                                            <ListItemIcon>
                                                <FormButtonsComponent
                                                    noMarginLeft
                                                    justButton
                                                    buttons={[
                                                        {
                                                            ...buttonsTypes.editIcon,
                                                            onClick: () => setEditBranchModalOpen(i)
                                                        },
                                                        {
                                                            ...buttonsTypes.deleteIcon,
                                                            onClick:() => setConfirmOpen(filiation?.id)
                                                        }
                                                    ]}
                                                />
                                            </ListItemIcon>
                                            {editBranchModalOpen === i
                                                && <BranchModal
                                                    data={filiation}
                                                    organizationId={item?.id}
                                                    isOpen={editBranchModalOpen === i}
                                                    onClose={() => setEditBranchModalOpen('')}
                                                />
                                            }
                                            {confirmOpen === filiation?.id
                                                &&  <ConfirmDelete
                                                    open={confirmOpen === filiation?.id}
                                                    onSuccess={deleteFiliationItem}
                                                    onClose={() => setConfirmOpen('')}
                                                />
                                            }
                                        </ListItem>
                                    )
                                    : (!loadingCurrentFiliations && messages.INFO_IS_NOT_FOUND)
                                }
                            </div>
                            <div className="row">
                                <FormButtonsComponent
                                    noPadding
                                    noMarginLeft
                                    positionLeft
                                    buttons={[
                                        {
                                            onClick: () => setBranchModalOpen(true),
                                            name: 'Добавить филиал'
                                        }
                                    ]}
                                />
                            </div>
                        </>
                    }
                </form>
            </div>
            {branchModalOpen
                && <BranchModal
                    isNew={true}
                    organizationId={item?.id}
                    isOpen={branchModalOpen}
                    onClose={() => setBranchModalOpen(false)}
                />
            }
        </Modal>
    );
};
export default StandardizedCompanyForm;
