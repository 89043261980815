import React from 'react';
import { Collapse } from '@mui/material';
import style from './cameras.module.scss';
import messages from '../../../../../helpers/constants/messages';
import titles from '../../../../../helpers/constants/titles';

const notInfo = messages.INFO_IS_NOT_FOUND;

const CameraInfo = ({ isOpen, item }) => {
    return (
        <Collapse className={style.cameras__collapse} in={isOpen} timeout={'auto'}>
            <span className={style.cameras__float}><strong>{titles.CAMERA_TYPE}: </strong>{item.typeText || notInfo}</span>
            <span className={style.cameras__float}><strong>{titles.COMPLEX_TYPE}: </strong>{item.complex_typeText || notInfo}</span>
            <span className={style.cameras__float}><strong>{titles.CAMERA_VIEW}: </strong>{item.direction || notInfo}</span>
            <span className={style.cameras__float}><strong>{titles.PROTOCOL}: </strong>{item.protoText || notInfo}</span>
            <span className={style.cameras__float}><strong>{titles.CAMERA_DIRECTION}: </strong>{item.dir_typeText || notInfo}</span>
            <span className={style.cameras__float}><strong>{titles.SPEED_LIMIT}: </strong>{item.speed || notInfo}</span>
            <span className={style.cameras__float}><strong>{titles.LAT}: </strong>{item.lat || notInfo}</span>
            <span className={style.cameras__float}><strong>{titles.LON}: </strong>{item.lon || notInfo}</span>
            <span className={style.cameras__float}><strong>{titles.IP_LIST}: </strong>{(item.ips || []).join(', ') || notInfo}</span>
            <span className={style.cameras__float}><strong>{titles.COMMENT}: </strong>{item.description || notInfo}</span>
        </Collapse>
    );
};

export default CameraInfo;
