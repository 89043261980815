const address = {
    ADDRESS: 'Адрес',
    JURISTIC_ADDRESS: 'Юридический адрес',
    INPUT_CITY: 'Введите город',
    INPUT_STREET_HOME: 'Введите улицу, номер дома',
    INPUT_ADDRESS_NOT_FOUND: 'Введенный адрес не найден.',
    SELECT_ADDRESS_FROM_LIST: 'Выберите адрес из списка',
    SEARCH_BY_ADDRESS: 'Найти по адресу',
    INTERSECTION_ADDRESS: 'Улица пересечения',
};

export default address;
