import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { videoAnalyticsSelectors } from 'redux/VideoAnalytics';
import { loadCameras, loadCameraStatuses } from 'redux/VideoAnalytics/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';

import Filter from './Filter';
import Item from './Item';

const VACameras = () => {
    const dispatch = useDispatch();

    const data = useSelector(videoAnalyticsSelectors.camerasData);
    const meta = useSelector(videoAnalyticsSelectors.camerasMeta);
    const loading = useSelector(videoAnalyticsSelectors.loading);

    const statusesOb = useStoreFromSelectorListToObject(loadCameraStatuses, videoAnalyticsSelectors.statuses);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = (isDelete) => {
        const page = isDelete && data?.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadCameras(params.page, limit, params.data));
    }, [params, limit, dispatch]);

    const search = (filters) => {
        const newFilters = {
            ...params.data,
            ...filters
        };
        const prepareData = removeEmptyFields({
            ...newFilters,
            status_list: newFilters.status_list?.map(({ id }) => id) || []
        });

        setParams({ page: 1, data: prepareData });
    };

    const renderContent = () => {
        return (
            data?.length > 0
                ? (<>
                    <LSContainer
                        onSort={(sort) => setParams({ ...params, data: { ...params.data, sort } })}
                        headers={[
                            { title: '', width: '50px' },
                            { title: titles.NAME, width: '20%', options: { sortable: true, field: 'name' } },
                            { title: 'Широта/Долгота', width: '20%' },
                            { title: 'IP адрес', width: '20%' },
                            { title: 'Балансодержатель', width: 'calc(30% - 50px)' },
                            { title: 'Действия', align: 'right', isActions: true }
                        ]}
                    >
                        {data.map((item) => (
                            <Item
                                key={item.id}
                                item={item}
                                reloadList={reloadList}
                                statusesOb={statusesOb}
                            />
                        ))}

                    </LSContainer>
                </>)
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <PageLayout
            header="Справочник видеокамер"
            filters={<Filter setParams={setParams} search={search}/>}
            loading={loading}
            informPanelProps={{
                total: meta?.total
            }}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: meta,
                limit,
                setLimit
            }}
        />
    );
};

export default VACameras;
