import React from 'react';

const Item = (props) => {
    const {
        name
    } = props;

    return (
        <div className="description">
            {name}
        </div>
    );
};

export default Item;
