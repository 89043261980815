import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import Modal from 'components/common/Modal';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';
import FormButtons from 'components/common/FormButtons';
import buttonsTypes from 'components/common/FormButtons/buttonsTypes';
import { moveComputerToOtherComplex } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import type { Complex } from '../../types';

interface MoveEntityModalProps {
    isOpen: boolean;
    currentComplex: Complex;
    onClose: () => void;
    onEdited: () => void;
    entityId: number;
}

const MoveEntityModal = ({
    isOpen,
    onClose,
    currentComplex,
    onEdited,
    entityId,
}: MoveEntityModalProps) => {
    const dispatch = useDispatch();

    const loadingButton = useSelector(dorisControlSelectors.loadingButton);

    const [selected, setSelected] = useState<Complex>(currentComplex);

    const handleSave = () => {
        const callback = () => {
            onEdited();
            onClose();
        };

        dispatch(moveComputerToOtherComplex(entityId, { complex_id: selected.id }, callback));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            buttons={[
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose,
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSave,
                            loading: loadingButton,
                            disabled: isEmpty(selected) || currentComplex.id === selected.id,
                        },
                    ]}
                />,
            ]}
        >
            <h3>Перенос вычислительного модуля в комлпекс</h3>
            {/* @ts-ignore проверить после перевода компоненте на ТС */}
            <SelectComplex
                selected={selected}
                onChange={setSelected}
            />
        </Modal>
    );
};

export default MoveEntityModal;
