import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadAppeal } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import ReportBlock from 'components/common/Report/ReportBlock';

import Filters from './Filters';
import Item from './Item';
import ModalForm from './ModalForm';

const initialFilter = {
    start_date: getStartOf('year'),
    end_date: getEndOf(),
    id: '',
    title: '',
    status_id: '',
    type_id: '',
    city: '',
    organization: {},
    organization_id: null,
    route: {},
    route_id: null,
};

const statusColors = {
    1: '#FF0000',
    2: '#3333cc',
    3: '#33CC33'
};

const Appeal = () => {
    const dispatch = useDispatch();

    const appealsData = useSelector(transportPassengerSelectors.appealsData);
    const appealsMeta = useSelector(transportPassengerSelectors.appealsMeta);
    const loadingAppeals = useSelector(transportPassengerSelectors.loadingAppeals);

    const [openModal, setOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [filter, setFilter] = useState(initialFilter);
    const [params, setParams] = useState({ page: 1, data: prepareData(initialFilter) });

    useEffect(() => {
        dispatch(loadAppeal(params.page, limit, params.data));
    }, [dispatch, limit, params.page, params.data]);

    function prepareData (data) {
        return removeEmptyFields({
            ...data,
            start_date: data.start_date ? fullDateTimeWithTimeZone(data.start_date) : null,
            end_date: data.end_date ? fullDateTimeWithTimeZone(data.end_date) : null,
            organization_id: data.organization?.id,
            route_id: data.route?.id,
            city: data.city?.city_name,
            organization: null,
            route: null
        });
    };

    const onSearch = (newFilter = filter) => {
        setParams({ page: 1, data: prepareData(newFilter) });
    };

    const getReport = (format) => {
        dispatch(loadAppeal(1, 25, { ...prepareData(filter), report: format }));
    };

    return (
        <>
            <PageLayout
                header="Регистрация обращений граждан"
                filters={
                    <Filters
                        initialFilter={initialFilter}
                        filter={filter}
                        setFilter={setFilter}
                        onSearch={onSearch}
                    />
                }
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtons
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenModal(true),
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                            <ReportBlock
                                onGetReport={getReport}
                                disabled={!filter?.start_date || !filter?.end_date}
                            />
                        </>
                    ),
                    total: appealsMeta?.total
                }}
                loading={loadingAppeals}
                content={() => (
                    appealsData?.length > 0
                        ? (
                            <LSContainer
                                headers={[
                                    { title: '', width: '5%' },
                                    { title: '№', width: '8%' },
                                    { title: 'Тип', width: '15%' },
                                    { title: 'Название', width: '20%' },
                                    { title: <span>Факт. дата <br/> создания</span>, width: '20%' },
                                    { title: 'Действия', align: 'right', isActions: true }
                                ]}
                            >
                                {appealsData.map((item) => (
                                    <Item
                                        key={item.id}
                                        item={item}
                                        statusColors={statusColors}
                                    />
                                ))}
                            </LSContainer>
                        )
                        : !loadingAppeals && <NoData/>
                )}
                paginationProps={{
                    loadList: (page) => setParams(prev => ({ ...prev, page })),
                    list: appealsMeta,
                    limit,
                    setLimit
                }}
            />

            {openModal && (
                <ModalForm
                    isNew={true}
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    reloadData={() => dispatch(loadAppeal(params.page, limit, params.data))}
                />
            )}
        </>
    );
};

export default Appeal;
