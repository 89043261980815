import React from 'react';
import './style.scss';
import {
    filterByDate,
} from '../../helper';


const GradientLine = ({
    item = {},
    timeSlider,
}) => {
    const {
        datas = [],
        gradient,
        max = 0,
        min = 0,
        style: styleContent = {},
    } = item;

    const filteredData = filterByDate(timeSlider)(datas);

    const colors = Object
        .keys(gradient || {})
        .sort()
        .reduce((res, key) => [...res, gradient[key]], []);
    // console.log('colors', colors);

    const style = {
        height: '20px',
        width: '100px',
        background: `linear-gradient(to right, ${colors.join(', ')})`,
    };

    return (
        <div
            className="gradient_with_minmax"
            style={{
                display: 'flex',
                margin: '0 auto',
                justifyContent: 'center',
                // width: '170px',
            }}
        >
            <div className="value">{min ?? ''}</div>
            <div
                className="line"
                style={style}
            ></div>
            <div className="value">{max ?? ''}</div>
        </div>
    );
};

export default GradientLine;
