import React, { useState } from 'react';
import { ActionMore, LSContentColumn, LSContentItem } from '../../../../common/List';
import messages from '../../../../../helpers/constants/messages';
import Info from './Info';

const Item = ({ item }) => {
    const [isOpenInfo, setIsOpenInfo] = useState(false);

    return (
        <>
            <LSContentItem
                style={{ cursor: 'pointer ' }}
                onClick={() => setIsOpenInfo((prev) => !prev)}
            >
                <LSContentColumn>{item?.name || messages.NO_DATA}</LSContentColumn>

                <LSContentColumn>{item?.direction || messages.NO_DATA}</LSContentColumn>

                <LSContentColumn isActions>
                    <ActionMore isOpen={isOpenInfo}/>
                </LSContentColumn>
            </LSContentItem>

            <Info isOpen={isOpenInfo} item={item} />
        </>
    );
};

export default Item;
