import * as types from './types';

/**
 * Actions
 **/

//Получение списка светофоров
export const loadList = (page = 1, limit = 25, query, callback) => ({
    type: types.LOAD_LIST,
    payload: { page, limit, ...query },
    callback,
});

export const loadingList = (bool = false) => ({
    type: types.LOADING_LIST,
    payload: bool
});

export const loadedList = (data) => ({
    type: types.LOADED_LIST,
    payload: data,
});
export const setFilterList = (filter = {}) => ({
    type: types.SET_FILTER_LIST,
    payload: filter,
});

// статусы
export const loadStatus = () => ({
    type: types.LOAD_STATUS
});
export const loadedStatus = (data) => ({
    type: types.LOADED_STATUS,
    payload: data,
});

// трафик
export const loadTraffic = (page= 1, limit = 10, params) => ({
    type: types.LOAD_TRAFFIC,
    payload: {
        page,
        limit,
        ...params,
    },
});
export const loadTrafficInfo = (id, params, callback) => ({
    type: types.LOAD_TRAFFIC_INFO,
    payload: {
        id,
        params,
    },
    callback
});
export const loadedTraffic = (data) => ({
    type: types.LOADED_TRAFFIC,
    payload: data,
});
export const loadingTraffic = (bool = false) => ({
    type: types.LOADING_TRAFFIC,
    payload: bool,
});

// типы
export const loadTypes = () => ({
    type: types.LOAD_TYPES,
});
export const loadedTypes = (data) => ({
    type: types.LOADED_TYPES,
    payload: data,
});


/* полигон */
export const getForPolygon = (polygon, params = {}) => ({
    type: types.GET_POLYGON,
    payload: { polygon, ...params },
});
export const saveForPolygon = (data) => ({
    type: types.SAVE_POLYGON,
    payload: data,
});
export const loadingPolygon = (bool = false) => ({
    type: types.LOADING_POLYGON,
    payload: bool,
});
export const clearForPolygon = () => ({
    type: types.SAVE_POLYGON,
    payload: [],
});

export const setPolygonVisible = (data = []) => ({
    type: types.SET_POLYGON_VISIBLE,
    payload: data,
});


// сайдбар
export const loadSidebar = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_SIDEBAR,
    payload: {
        page,
        limit,
        ...query,
    },
});
export const loadedSidebar = (data, replace = true) => ({
    type: types.LOADED_SIDEBAR,
    payload: {
        data,
        replace,
    }
});
export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool
});
export const clearSidebar = () => ({
    type: types.CLEAR_SIDEBAR,
});


// активный
export const setActive = (item) => ({
    type: types.SET_ACTIVE,
    payload: item,
});
export const resetActive = () => ({
    type: types.SET_ACTIVE,
    payload: {},
});

// фильтр
export const setFilters = (filter) => ({
    type: types.SET_FILTER,
    payload: filter,
});
export const clearFilters = () => ({
    type: types.SET_FILTER,
    payload: {},
});

// puid
export const loadReport = (props = {}) => ({
    type: types.LOAD_REPORT,
    payload: props
});
export const loadedReport = (data) => ({
    type: types.LOADED_REPORT,
    payload: data
});
export const clearReport = () => ({
    type: types.LOADED_REPORT,
    payload: []
});

export const loadingReport = (bool = false) => ({
    type: types.LOADING_REPORT,
    payload: bool
});
export const setFilterReport = (filter = {}) => ({
    type: types.SET_FILTER_REPORT,
    payload: filter
});
export const clearFilterReport = () => ({
    type: types.SET_FILTER_REPORT,
    payload: {}
});

export const loadReportGroups = () => ({
    type: types.LOAD_REPORT_GROUPS,
});
export const loadedReportGroups = (data) => ({
    type: types.LOADED_REPORT_GROUPS,
    payload: data
});

export const loadReportTypes = () => ({
    type: types.LOAD_REPORT_TYPES,
});
export const loadedReportTypes = (data) => ({
    type: types.LOADED_REPORT_TYPES,
    payload: data
});

// transport flow
export const loadTransportFlowReport = (params) => ({
    type: types.LOAD_TRANSPORT_FLOW_REPORT,
    payload: params,
});
export const loadedTransportFlowReport = (data) => ({
    type: types.LOADED_TRANSPORT_FLOW_REPORT,
    payload: data,
});
export const clearTransportFlowReport = () => ({
    type: types.LOADED_TRANSPORT_FLOW_REPORT,
    payload: {},
});
export const loadingTransportFlowReport = (bool = false) => ({
    type: types.LOADING_TRANSPORT_FLOW_REPORT,
    payload: bool,
});

export const loadTransportFlowGroups = () => ({
    type: types.LOAD_TRANSPORT_FLOW_GROUPS,
});
export const loadedTransportFlowGroups = (data) => ({
    type: types.LOADED_TRANSPORT_FLOW_GROUPS,
    payload: data
});

// export const loadTransportFlowTypes = () => ({
//     type: types.LOAD_TRANSPORT_FLOW_TYPES,
// });
// export const loadedTransportFlowTypes = (data) => ({
//     type: types.LOADED_TRANSPORT_FLOW_TYPES,
//     payload: data
// });

// Анализ пропускной способности для участков ДР
export const loadThroughput = (props = {}, callback) => ({
    type: types.LOAD_THROUGHPUT,
    payload: props,
    callback
});
export const loadingThroughput = (bool = false) => ({
    type: types.LOADING_THROUGHPUT,
    payload: bool
});
export const loadedThroughput = (data) => ({
    type: types.LOADED_THROUGHPUT,
    payload: data
});

// heat map
export const loadHeatMap = (params) => ({
    type: types.LOAD_HEAT_MAP,
    payload: params,
});
export const clearHeatMap = () => ({
    type: types.LOADED_HEAT_MAP,
    payload: [],
});
export const loadedHeatMap = (data) => ({
    type: types.LOADED_HEAT_MAP,
    payload: data,
});
export const loadingHeatMap = (bool = false) => ({
    type: types.LOADING_HEAT_MAP,
    payload: bool,
});
export const setFilterHeatMap = (filter = {}) => ({
    type: types.FILTER_HEAT_MAP,
    payload: filter,
});

export const loadShowcase = () => ({
    type: types.LOAD_SHOWCASE,
});
export const loadingShowcase = (bool = false) => ({
    type: types.LOADING_SHOWCASE,
    payload: bool
});
export const loadedShowcase = (data) => ({
    type: types.LOADED_SHOWCASE,
    payload: data
});
export const clearShowcase = () => ({
    type: types.LOADED_SHOWCASE,
    payload: {}
});

// отчет
export const loadReportIndicators = (filter = {}) => ({
    type: types.LOAD_REPORT_INDICATORS,
    payload: filter
});
export const loadingReportIndicators = (bool = false) => ({
    type: types.LOADING_REPORT_INDICATORS,
    payload: bool
});
export const loadedReportIndicators = (data) => ({
    type: types.LOADED_REPORT_INDICATORS,
    payload: data
});
export const clearReportIndicators = () => ({
    type: types.LOADED_REPORT_INDICATORS,
    payload: {}
});
export const setFilterReportIndicators = (filter = {}) => ({
    type: types.SET_FILTER_REPORT_INDICATORS,
    payload: filter
});
export const loadTrafficFlowByRadius = (data, callback = () => {}) => ({
    type: types.LOAD_TRANSPORT_FLOW_BY_RADIUS,
    payload: data,
    callback
});

export const loadedTrafficFlowByRadius = (data) => ({
    type: types.LOADED_TRANSPORT_FLOW_BY_RADIUS,
    payload: data
});

// crud
export const createLogicalPuid = (data, callback) => ({
    type: types.CREATE_LOGICAL_PUID,
    payload: data,
    callback,
});
export const editLogicalPuid = (id, data, callback) => ({
    type: types.EDIT_LOGICAL_PUID,
    payload: {
        id,
        data
    },
    callback,
});

export const deleteLogicalPuid = (id, callback) => ({
    type: types.DELETE_LOGICAL_PUID,
    payload: id,
    callback,
});

export const loadingLogicalPuid = (bool = false) => ({
    type: types.LOADING_LOGICAL_PUID,
    payload: bool,
});

export const savedLogicalPuid = (bool = false) => ({
    type: types.SAVED_LOGICAL_PUID,
    payload: bool,
});

export const replacePolygonSidebarLogicalPuid = (item) => ({
    type: types.REPLACE_POLYGON_SIDEBAR_LOGICAL_PUID,
    payload: item,
});

// получение списка пуидов по id
export const loadPuidByIds = (ids = [], filter = {}, callback) => ({
    type: types.LOAD_PUID_BY_IDS,
    payload: {
        ids,
        filter
    },
    callback,
});


export const loadPuidByRadius = (lat, lon, radius, filter = {}) => ({
    type: types.LOAD_PUID_BY_RADIUS,
    payload: {
        lat,
        lon,
        radius,
        ...filter,
    },
});
export const loadedPuidByRadius = (data = []) => ({
    type: types.LOADED_PUID_BY_RADIUS,
    payload: data
});
export const loadingPuidByRadius = (bool = false) => ({
    type: types.LOADING_PUID_BY_RADIUS,
    payload: bool,
});
export const clearPuidByRadius = () => ({
    type: types.CLEAR_PUID_BY_RADIUS,
});

// Settings
export const loadSettings = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_SETTINGS,
    payload: { page, limit, query }
});

export const loadingSettings = (bool = false) => ({
    type: types.LOADING_SETTINGS,
    payload: bool
});

export const loadedSettings = (data) => ({
    type: types.LOADED_SETTINGS,
    payload: data
});

export const editSetting = (id, data) => ({
    type: types.EDIT_SETTING,
    payload: { id, data }
});

export const editSettingsList = (data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_LIST,
    payload: data,
    callback
});

// Settings Group
export const loadSettingsGroup = () => ({
    type: types.LOAD_SETTINGS_GROUP,
});

export const loadingSettingsGroup = (bool) => ({
    type: types.LOADING_SETTINGS_GROUP,
    payload: bool
});

export const loadedSettingsGroup = (data) => ({
    type: types.LOADED_SETTINGS_GROUP,
    payload: data
});

export const editSettingsGroup = (id, data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_GROUP,
    payload: { id, data },
    callback
});

export const loadingButton = (loading = false) => ({
    type: types.LOADING_BUTTON,
    payload: loading
});
export const loadReportStatisticType = () => ({
    type: types.LOAD_REPORT_STATISTIC_TYPES,
});
export const loadedReportStatisticType = (data) => ({
    type: types.LOADED_REPORT_STATISTIC_TYPES,
    payload: data
});

export const loadReportTimeSlot = () => ({
    type: types.LOAD_REPORT_TIME_SLOT,
});
export const loadedReportTimeSlot = (data) => ({
    type: types.LOADED_REPORT_TIME_SLOT,
    payload: data
});
