import Filter from './Filter';

const ReportTechnical = () => {
    return (
        <>
            <h1>Технический отчет по КФВФ по времени работы и статьям КОАП</h1>
            <Filter />
        </>
    );
};

export default ReportTechnical;