import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Divider,
    FormControl,
    InputLabel,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { fullDateTime, fullDateTimeWithTimeZone, dateWithDashYYYYMMDD } from 'helpers/date.config';
import { useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { postSchedule, putSchedule } from 'redux/TransportPassenger/actions';
import SelectRoutes from 'components/common/Autocomplete/PassengerTransport/Routes';
import SelectTrafficSchedule from 'components/common/Autocomplete/PassengerTransport/TrafficSchedule';
import DateRange from 'components/common/Dates/DateRange';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import Modal from 'components/common/Modal';

const useStyles = makeStyles({
    root: {
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red'
        }
    },
    hint: {
        fontSize: 13,
        color: 'gray',
        backgroundColor: '#e4e3e5',
        padding: 5,
        marginTop: -8,
        borderRadius: 4
    },
});

const ModalForm = ({
    isOpen,
    onClose,
    isNew = false,
    item = {},
    statuses,
    categories,
    reloadList,
    currentRoute,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        route: currentRoute ? currentRoute : isNew ? {} : item,
        num_route:  currentRoute?.num || item?.num_route || '',
        name: item?.name || '',
        transport_type: currentRoute?.category_id ? currentRoute.category_id : isNew ? '' : item?.transport_type,
        start_at: item?.start_at || null,
        end_at: item?.end_at || null,
        status: isNew ? '1' : item?.status,
        traffic_schedules: item?.traffic_schedules || [],
    };

    const [data, setData] = useState(initialState);
    const [isDateRangeError, setDateRangeError] = useState(false);

    const onChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });

        validation.deleteKey(name);
    };

    const handleChangeRoute = (value) => {
        setData({
            ...data,
            route: value,
            num_route: value?.num,
            transport_type: value?.category_id,
            traffic_schedules: []
        });

        validation.deleteKey('route');
        validation.deleteKey('transport_type');
    };

    const trafficScheduleHandler = (value, name) => {
        setData({ ...data, [name]: value });
    };

    const onAcceptModal = () => {
        const correctStartAt = fullDateTimeWithTimeZone(data?.start_at);
        const correctEndAt = fullDateTimeWithTimeZone(data?.end_at);

        const prepareData = {
            ...data,
            route: null,
            start_at: correctStartAt,
            end_at: correctEndAt,
        };

        const withoutEmpty = removeEmptyFields(prepareData);

        const callback = () => {
            reloadList();
            onClose();
        };

        isNew
            ? dispatch(postSchedule(withoutEmpty, callback))
            : dispatch(putSchedule(data.route.id, withoutEmpty, callback));
    };

    const renderDaysOfWeek = (str) => {

        const week = {
            0: { name: 'пн' },
            1: { name: 'вт' },
            2: { name: 'ср' },
            3: { name: 'чт' },
            4: { name: 'пт' },
            5: { name: 'сб' },
            6: { name: 'вс' },
        };

        const newArr = [];
        str?.split('').forEach((day, index) => day === '1' ? newArr.push(index) : null);

        const arrOfName = [];
        newArr.forEach((day) => arrOfName.push(week[day]?.name));

        return arrOfName;
    };

    const getValidationInfo = (start, end) => {
        return {
            message: <>
                <span>{validation.get(start) || ''}</span>
                <span>{validation.get(end) || ''}</span>
            </>,
            bool: validation.isKey(start) || validation.isKey(end)
        };
    };

    const isDisabled = !data.num_route
        || !data.name || !data.transport_type || !data.start_at
        || isDateRangeError || !data.end_at || !data.status;

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                noPadding={true}
                title={isNew ? titles.CREATE : titles.EDIT}
                buttons={<FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onAcceptModal,
                            disabled: isDisabled
                        }
                    ]}
                />}
            >
                <form className="modal__form">
                    <div className="block">
                        <SelectRoutes
                            selected={isNew ? data.route : { name: data.num_route }}
                            onChange={handleChangeRoute}
                            required
                            error={_.isEmpty(data.route)}
                            disabled={!isNew}
                        />
                    </div>
                    <div className="block">
                        <FormInfoWrapper
                            helperText={ validation.get('transport_type') || 'Будет установлен исходя из выбранного маршрута'}
                            error={validation.isKey('transport_type')}
                        >
                            <FormControl required size="small" variant="outlined">
                                <InputLabel>Вид транспорта</InputLabel>
                                <Select
                                    value={data.transport_type}
                                    required
                                    onChange={onChange}
                                    label="Вид транспорта"
                                    name="transport_type"
                                    disabled={true}
                                >
                                    {categories?.length > 0
                                        ? categories?.map((el) =>
                                            <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                        )
                                        : <MenuItem value=" ">{messages.NO_DATA}</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </FormInfoWrapper>
                    </div>
                    <div className="row">
                        <DateRange
                            className="row__item"
                            handleDate={(e) => {
                                setData({ ...data, start_at: e, traffic_schedules: [] });
                                validation.deleteKey('start_at');
                            }}
                            handleEndDate={(e) => {
                                setData({ ...data, end_at: e, traffic_schedules: [] });
                                validation.deleteKey('end_at');
                            }}
                            valueStartDate={data.start_at}
                            valueEndDate={data.end_at}
                            isDisabledHandler={(error) => setDateRangeError(error) }
                            required={true}
                            disableFuture={false}
                            renderError={() => getValidationInfo('start_at', 'end_at').bool}
                            errorMessage={() => getValidationInfo('start_at', 'end_at').message}
                        />
                    </div>
                    <div className="block">
                        <FormInfoWrapper
                            error={validation.isKey('name')}
                            helperText={validation.get('name')}
                        >
                            <FormControl required size="small" variant="outlined">
                                <TextField
                                    className={!data?.name ? classes.root : ''}
                                    label={'Название расписания'}
                                    size="small"
                                    value={data.name || ''}
                                    variant="outlined"
                                    name="name"
                                    type="text"
                                    onChange={onChange}
                                    required={true}
                                    error={!data.name || validation.isKey('name')}
                                />
                            </FormControl>
                        </FormInfoWrapper>
                    </div>
                    <div className="block">
                        <FormInfoWrapper
                            error={validation.isKey('status')}
                            helperText={validation.get('status')}
                        >
                            <FormControl required size="small" variant="outlined">
                                <InputLabel style={ data.status ? { color: 'rgba(0, 0, 0, 0.54)' } : { color: '#ff0000' } }>{titles.STATUS}</InputLabel>
                                <Select
                                    value={data.status || null}
                                    required
                                    onChange={onChange}
                                    label={titles.STATUS}
                                    name="status"
                                    error={!data.status || !!validation.isKey('status')}
                                    disabled
                                >
                                    {Object.keys(statuses)?.map((key) => (
                                        <MenuItem key={key} value={key}>{statuses[key]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormInfoWrapper>
                    </div>
                    <FormControl required className="block" size="small" variant="outlined">
                        <SelectTrafficSchedule
                            multiple
                            selected={data.traffic_schedules}
                            onChange={(value) => trafficScheduleHandler(value, 'traffic_schedules')}
                            filter={{
                                route_num: data.route?.num,
                                date_start: dateWithDashYYYYMMDD(data.start_at),
                                date_end: dateWithDashYYYYMMDD(data.end_at)
                            }}
                            isDisabled={!data?.num_route || !data?.start_at || !data?.end_at || isDateRangeError || !isNew}
                            isTakeAllTS={true}
                            is_simple={0}
                        />
                    </FormControl>
                    {data?.traffic_schedules
                        ?.map((elem, index) => <div key={elem?.id}>
                            <ListItem className="list_item">
                                <ListItemText>
                                    {index + 1}. {elem?.name}
                                    <Typography>
                                        <strong>
                                            Номер графика:&nbsp;
                                        </strong>
                                        {elem?.diagram}
                                    </Typography>
                                    <Typography>
                                        <strong>
                                            Период действия:&nbsp;
                                        </strong>
                                        {fullDateTime(elem?.start_at)} - {fullDateTime(elem?.end_at)}
                                    </Typography>
                                    <Typography>
                                        <strong>
                                            Дни недели:&nbsp;
                                        </strong>
                                        {renderDaysOfWeek(elem?.days_week)?.map(render => <span key={render}>{render} </span>)}
                                    </Typography>
                                    <Typography>
                                        <strong>
                                            Праздничные дни:&nbsp;
                                        </strong>
                                        {elem?.is_holiday ? 'да' : 'нет'}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Divider/>
                        </div>)}
                </form>
            </Modal>
        </div>
    );
};

export default ModalForm;