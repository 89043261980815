import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { createBrand, editBrand, loadVehicleCategories } from 'redux/TransportSpecial/actions';
import { transportSpecialSelectors } from 'redux/TransportSpecial';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';

const ModalForm = ({ data, isNew, open, onClose, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const categories = useStoreProp(loadVehicleCategories, 'transportSpecial', 'vehicleCategories');

    const loadingButton = useSelector(transportSpecialSelectors.loadingButton);

    const initialState = {
        name_ru: data?.name_ru || '',
        name_en: data?.name_en || '',
        slug: data?.slug || '',
        category_id: data?.category_id || ''
    };

    const [formData, setFormData] = useState(initialState);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        dispatch(isNew
            ? createBrand(formData, callback)
            : editBrand(data.id, formData, callback)
        );
    };

    const isDisabled = !formData.name_ru || !formData.name_en || !formData.slug;

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled,
                        loading: loadingButton
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <TextField
                    value={formData.name_ru}
                    name={'name_ru'}
                    onChange={handleChange}
                    className="block"
                    label={titles.BRAND_NAME_RU}
                    variant={'outlined'}
                    size={'small'}
                    required={true}
                    error={validation.isKey('name_ru')}
                    helperText={validation.get('name_ru')}
                />
                <TextField
                    value={formData.name_en}
                    name={'name_en'}
                    onChange={handleChange}
                    className="block"
                    label={titles.BRAND_NAME_EN}
                    variant={'outlined'}
                    size={'small'}
                    required={true}
                    error={validation.isKey('name_en')}
                    helperText={validation.get('name_en')}
                />
                <TextField
                    value={formData.slug}
                    name={'slug'}
                    onChange={handleChange}
                    className="block"
                    label={titles.SLUG}
                    variant={'outlined'}
                    size={'small'}
                    required={true}
                    error={validation.isKey('slug')}
                    helperText={validation.get('slug')}
                />
                <FormControl
                    variant="outlined"
                    size="small"
                    className="block"
                    error={!!validation.category_id}
                    required={true}
                >
                    <InputLabel>{titles.CATEGORY_VEHICLE}</InputLabel>
                    <Select
                        label={titles.CATEGORY_VEHICLE}
                        value={formData.category_id}
                        onChange={handleChange}
                        name={'category_id'}
                        required={true}
                    >
                        {categories?.length > 0
                            ? categories.map((el) =>
                                <MenuItem key={el?.id} value={el?.id}>{el?.name}</MenuItem>
                            )
                            : <MenuItem disabled={true} value="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </div>
        </Modal>
    );
};

export default ModalForm;