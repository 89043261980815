import React, { useContext, useEffect, useState } from 'react';
import * as actions from '../../../../../redux/Boards/actions';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from './ModalForm';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Loading from '../../../../common/Loading';
import CustomPagination from '../../../../common/CustomPagination';
import messages from '../../../../../helpers/constants/messages';
import { List } from '@mui/material';
import Item from './Item';
import Filter from './Filter';
import Context from '../../../../../helpers/context';
import { boardsSelectors } from '../../../../../redux/Boards';


const Signs = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context) || {};
    
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [params, setParams] = useState({
        page: 1,
        limit: storageLimit,
        filter: {},
    });

    const [isOpenModalForm, setIsOpenModalForm] = useState(false);

    const templateImageList = useSelector(boardsSelectors.templateImageList);
    const templateImageListLoading = useSelector(boardsSelectors.templateImageListLoading);
    const templateImageLoading = useSelector(boardsSelectors.templateImageLoading);

    const reloadList = (isDelete) => {
        const page = isDelete && templateImageList?.data?.length === 1 && templateImageList?.meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        const {
            page,
            limit,
            filter,
        } = params;
        dispatch(actions.loadListTemplateImage({
            page,
            limit,
            ...filter
        }));
    }, [params, dispatch]);

    return (
        <>
            <h1>Знаки для шаблонов сообщений ДИТ и ЗПИ</h1>
            <Filter
                onChange={(newFilter) => {
                    setParams({
                        ...params,
                        page: 1,
                        filter: newFilter
                    });
                }}
                data={params?.filter}
            />
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setIsOpenModalForm(true),
                            // disabled: !permissions?.is_create
                        },
                    ]}
                />
            </div>

            {(templateImageListLoading || templateImageLoading) && <Loading linear={true}/>}

            {templateImageList?.data?.length > 0
                ? (
                    <>
                        <List className="list">
                            {templateImageList?.data?.map(item => (
                                <Item
                                    data={item}
                                    key={item.id}
                                    reloadList={reloadList}
                                />
                            ))}
                        </List>
                        <CustomPagination
                            loadList={(page) => setParams(old => ({
                                ...old,
                                page,
                            }))}
                            list={templateImageList?.meta || {}}
                            limit={params.limit}
                            setLimit={(limit) => setParams(old => ({
                                ...old,
                                limit,
                            }))}
                        />
                    </>
                )
                : (!templateImageListLoading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }

            {isOpenModalForm && (
                <ModalForm
                    isOpen={isOpenModalForm}
                    onClose={() => {
                        setIsOpenModalForm(false);
                    }}
                    reloadList={() => setParams({ ...params, page: 1 })}
                />
            )}
        </>
    );
};

export default Signs;