import { IconButton, InputAdornment, TextField } from '@mui/material';

const Filter = (props) => {

    const { data, onChange } = props;

    const handleChange = ({ target }) => {
        const { name, value } = target;

        onChange({
            [name]: value
        });
    };

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >

            <div className="block">
                <TextField
                    label="Наименование причала"
                    value={data?.nameAnchor || ''}
                    onChange={handleChange}
                    //helperText="Some important text"
                    name="nameAnchor"
                    fullWidth
                    className="row__item"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {data?.nameAnchor?.length > 0 ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => onChange({ nameAnchor: '' })}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>x</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />
            </div>

            <div className="block">
                <TextField
                    label="Наименование судна"
                    value={data?.nameShip || ''}
                    onChange={handleChange}
                    //helperText="Some important text"
                    name="nameShip"
                    fullWidth
                    className="row__item"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {data?.nameShip?.length > 0 ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => onChange({ nameShip: '' })}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>x</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />
            </div>

        </form>
    );
};

export default Filter;
