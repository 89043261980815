import L from 'leaflet';
import location from '../../../icon/location.png';

const defaultIcon = L.icon({
    iconUrl: location,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
});

export default  defaultIcon;
