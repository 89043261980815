import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadEventObjects, loadEventObjectsTypes } from '../../../../../redux/SituationalPlans/actions';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Loading from '../../../../common/Loading';
import CustomPagination from '../../../../common/CustomPagination';
import { List } from '@mui/material';
import messages from '../../../../../helpers/constants/messages';
import ModalForm from './ModalForm';
import { useStoreProp } from '../../../../../helpers/hooks';
import Item from './Item';
import Filter from './Filter';
import Context from '../../../../../helpers/context';
import { SituationalPlansSelectors } from '../../../../../redux/SituationalPlans';

const EventObjects = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const types = useStoreProp(loadEventObjectsTypes, 'SituationalPlans', 'eventObjectsTypes');

    const data = useSelector(SituationalPlansSelectors.eventObjectsData);
    const meta = useSelector(SituationalPlansSelectors.eventObjectsMeta);
    const loading = useSelector(SituationalPlansSelectors.loadingEventObjects);

    const [openModal, setOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = (isDelete) => {
        const page = isDelete && data?.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadEventObjects(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    return (
        <>
            <h1>Объекты мероприятий</h1>

            <Filter setParams={setParams} types={types} />

            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenModal(true),
                            disabled: !permissions?.is_create,
                        }
                    ]}
                />
            </div>

            {loading && <Loading circular={true} />}

            {data?.length > 0 ? (
                <>
                    <List className="list">
                        {data.map((item) => (
                            <Item
                                key={item.id + item.name}
                                item={item}
                                types={types}
                                reloadList={reloadList}
                            />
                        ))}
                    </List>

                    <CustomPagination
                        loadList={(page) => setParams({ ...params, page })}
                        list={meta}
                        limit={limit}
                        setLimit={setLimit}
                    />
                </>
            ) : (
                !loading && <div>{messages.DATA_IS_NOT_FOUND}</div>
            )}

            {openModal && (
                <ModalForm
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    isNew={true}
                    types={types}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default EventObjects;
