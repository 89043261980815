import React from 'react';
import DateRange from '../../../../common/Dates/DateRange';


const Form = ({ data, onChange }) => {

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="row">
                <DateRange
                    valueStartDate={data.start_at}
                    valueEndDate={data.end_at}
                    handleDate={(e) => onChange({ start_at: e })}
                    handleEndDate={(e) => onChange({ end_at: e })}
                    startLabel="Начало периода"
                    endLabel="Конец периода"
                    dateOnly
                />
            </div>
        </form>
    );
};

export default Form;
