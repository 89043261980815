import React from 'react';
import { Collapse, Grid } from '@mui/material';
import { getHumanDate } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import styles from './styles.module.scss';
import type { Site } from './types';

interface InfoProps {
    isOpen: boolean;
    item: Site;
}

const Info = ({ isOpen, item }: InfoProps) => {
    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit className={styles.info}>
            <Grid spacing={1} container>
                <Grid item>
                    <div><b>Дата окончания плановая: </b></div>
                    <div><b>Дата окончания фактическая: </b></div>
                </Grid>

                <Grid item>
                    <div>
                        {item?.troubleshooting_plan
                            ? getHumanDate(item?.troubleshooting_plan)
                            : messages.NO_DATA}
                    </div>

                    <div>
                        {item?.troubleshooting_fact
                            ? getHumanDate(item?.troubleshooting_fact)
                            : messages.NO_DATA}
                    </div>
                </Grid>
            </Grid>
        </Collapse>
    );
};

export default Info;
