import React from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { Collapse,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { ListItem } from './types';

interface InfoProps {
    isOpen: boolean;
    list: ListItem[];
    currentOption: { format: Function };
}

const Info = ({
    isOpen,
    list,
    currentOption,
}: InfoProps) => {
    return (
        <Collapse
            in={isOpen}
            timeout="auto"
            unmountOnExit
            className="info"
            style={{ backgroundColor: 'transparent' }}
        >
            <div style={{ padding: '10px 0 0' }}>
                {list?.length > 0
                    ? (
                        <TableVirtuoso
                            style={{ height: 400, backgroundColor: 'white' }}
                            data={list}
                            components={{
                                Scroller: React.forwardRef((props, ref) => <TableContainer {...props} ref={ref} />),
                                Table: (props) => <Table {...props} style={{ border: '1px solid lightgrey', borderCollapse: 'separate' }} />,
                                TableHead: TableHead,
                                TableRow: (props) => <TableRow {...props} style={{ backgroundColor: props['data-index'] % 2 ? 'rgba(155,157,176,0.08)' : 'inherit' }}/>,
                                TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
                            }}
                            fixedHeaderContent={() => (
                                <TableRow style={{ backgroundColor: 'white' }}>
                                    <TableCell>Дата/время</TableCell>
                                    <TableCell>Макс. (шт.)</TableCell>
                                    <TableCell>Мин. (шт)</TableCell>
                                </TableRow>
                            )}
                            itemContent={(index, tableItem) => (
                                <>
                                    <TableCell>
                                        {currentOption?.format(tableItem?.date) || ''}
                                    </TableCell>
                                    <TableCell>{tableItem?.min}</TableCell>
                                    <TableCell>{tableItem?.max}</TableCell>
                                </>
                            )}
                        />
                    )
                    : (
                        <div>
                            За указанный период нет данных
                        </div>
                    )}
            </div>
        </Collapse>
    );
};

export default Info;