import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'redux/TrafficLights/actions';
import { trafficLightsSelectors } from 'redux/TrafficLights';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';

import Item from './Item';
import Filter from './Filter';

const TrafficLights = () => {
    const dispatch = useDispatch();

    const initialState = {
        name: '',
        status: '',
        state_type_ids: '',
        control_type_ids: '',
        uuid: [],
    };

    const loadingLightList = useSelector(trafficLightsSelectors.loadingLightList);
    const data = useSelector(trafficLightsSelectors.lightListData);
    const meta = useSelector(trafficLightsSelectors.lightListMeta);

    const statuses = useStoreProp(actions.loadTrafficLightsStatuses, 'trafficLights', 'lightsStatuses');
    const states = useStoreProp(actions.loadTrafficLightsStates, 'trafficLights', 'lightStates');
    const controlTypes = useStoreProp(actions.loadTrafficLightControlTypes, 'trafficLights', 'lightControlTypes');

    const [params, setParams] = useState(initialState);
    const [page, setPage] = useState(1);

    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);

    useEffect(() => {
        const paramsWithoutEmpty = removeEmptyFields(params);
        dispatch(actions.loadTrafficLightsList(page, limit, paramsWithoutEmpty));
    },[dispatch, params, limit, page]);

    const renderContent = () => {
        return (data?.length > 0
            ? <LSContainer
                headers={[
                    { title: '', width: '50px' },
                    { title: titles.NAME, width: '20%' },
                    { title: 'Состояние', width: '110px' },
                    { title: 'Тип контроля', width: '15%' },
                    { title: titles.ADDRESS, width: 'calc(40% - 50px)' },
                    { title: 'Широта/Долгота', width: '21%' },
                ]}
            >
                {data.map((item) => (
                    <Item
                        key={item.id}
                        item={item}
                        statuses={statuses}
                    />
                ))}
            </LSContainer>
            : !loadingLightList && <div>{messages.DATA_IS_NOT_FOUND}</div>
        );
    };

    return (
        <>
            <PageLayout
                header="Светофоры"
                filters={<Filter
                    statuses={statuses}
                    states={states}
                    controlTypes={controlTypes}
                    initialState={initialState}
                    setParams={setParams}
                />}
                loading={loadingLightList}
                informPanelProps={{
                    total: meta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: setPage,
                    list: meta,
                    limit,
                    setLimit
                }}
            />
        </>
    );
};

export default TrafficLights;
