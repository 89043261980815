// import { config } from '../../config';
// const api = () => config.get('urls').versionUrl;

const apiUrls = {

    getVersion: () => '/version',

};

export default apiUrls;
