import { useRef } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { authSelectors } from 'redux/Auth';
import Context from 'helpers/context';
import { WsProvider } from 'helpers/ws/hooks';
import useShowcase from 'helpers/hooks/useShowcase';
import routes from 'components/routes';
import HistoryMap from 'components/common/Transport/TransportHistory/ShowMap';
import RoadHistoryMap from 'components/common/Transport/RoadTransportHistory/ShowMap';

import AppSidebar from './AppSidebar';
import styles from './appLayout.module.scss';
import Header from '../Header';

const AppLayout = () => {
    const location = useLocation();
    const permissionList = useSelector(authSelectors.permissionList || []);
    const isShowCase = useShowcase();

    const headerRef = useRef(null);
    const headerHeight = headerRef?.current?.clientHeight;

    const userPermissions = permissionList.find((el) => location?.pathname === '/map'
        ? location?.state?.layer === el?.layer
        : el?.link === location?.pathname
    ) || {};

    // todo на размышление
    // если нет доступа к странице - редирект на основную страницу
    // но когда витрина - пермишенов нет (они грузятся из меню - меню нет)
    // useEffect(() => {
    //     if (
    //         location?.pathname !== '/'
    //         && permissionList.length > 0
    //         && Object.keys(userPermissions).length === 0
    //     ) {
    //         window?.location?.replace?.('/');
    //     }
    // }, [permissionList, userPermissions, location]);

    return (
        <div className={styles.wrapper}>
            {/* коннект сокетов + подключение глобальных каналов */}
            <WsProvider>
                <Context.Provider value={{
                    permissions: userPermissions.permissions || {},
                    layer: userPermissions.layer || '',
                    link: userPermissions.link || '',
                }}>
                    <Header headerRef={headerRef} />

                    <div
                        className={styles.app_wrapper}
                        style={{ ...(headerHeight && !isShowCase && { marginTop: headerHeight }) }}
                    >
                        {/* сайдбар */}
                        <AppSidebar headerHeight={headerHeight} />

                        {/* контент */}
                        <div className={cn(styles.app_wrapper__content, {
                            [styles.app_wrapper__content__page]: location.pathname !== '/map' && location.pathname !== '/'
                        })}>
                            <Switch>
                                {routes.map(({ component: Component, ...route }) => {
                                    return (
                                        <Route key={ route.path } { ...route }>
                                            <Component />
                                        </Route>
                                    );
                                })}
                            </Switch>
                        </div>
                    </div>
                </Context.Provider>

                {/* отобразить историю */}
                <HistoryMap/>
                <RoadHistoryMap/>
            </WsProvider>
        </div>
    );
};

export default AppLayout;
