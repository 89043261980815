import React, { useState } from 'react';
import messages from '../../../../../helpers/constants/messages';
import { Scrollbars } from 'react-custom-scrollbars';
import TransportIncidentInfoModal from '../../../../pages/Reports/DTP/Incidents/TransportIncidentInfoModal';

const Information = ({ data, list = [], hideEmpty = false }) => {
    const [openTIInfoModal, setOpenTIInfoModal] = useState(false);

    return (
        <>
            <Scrollbars style={{ height: '200px' }}>
                {Object.keys(list).map((key) => {
                    const value = data[key];
                    
                    const result = list[key]?.value 
                        ? list[key]?.modal
                            ? list[key]?.value(value, () => setOpenTIInfoModal(true))
                            : list[key]?.value(value, data)
                        : value || (hideEmpty === false ? messages.INFO_IS_NOT_FOUND : null);

                    if (hideEmpty === true && !result) return null;
                    
                    return (
                        <div className="info__item" key={`information_${key}`}>
                            <span className="info__item-title">
                                {list[key].title || ''}
                                {': '}
                            </span>
                            <span>
                                {result}
                            </span>
                        </div>
                    );
                })}
            </Scrollbars>
            {openTIInfoModal && (
                <TransportIncidentInfoModal
                    transportIncidentId={data?.ti_id}
                    onClose={() => setOpenTIInfoModal(false)}
                />
            )}
        </>
    );
};

export default Information;
