import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';

import { getStageSchedule, loadStagesList } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import messages from 'helpers/constants/messages';
import Loading from 'components/common/Loading';

import Item from './Item';

const WorkByStages = ({ currentOrder }) => {
    const dispatch = useDispatch();
    const stagesData = useSelector(roadworksSelectors.stagesData);
    // const stagesMeta = useSelector(roadworksSelectors.stagesMeta);
    const loadingStages = useSelector(roadworksSelectors.loadingStages);

    useEffect(() => {
        dispatch(loadStagesList(1, 25, { order_ids: [currentOrder.id] }));
    }, [currentOrder, dispatch]);

    const loadStageSchedule = useCallback(() => {
        const uniqWorkIds = [...new Set(stagesData.map(el => el.works.map(({ id }) => id )).flat())];
        uniqWorkIds.length && dispatch(getStageSchedule({ road_work_ids: uniqWorkIds }));
    },[dispatch, stagesData]);

    useEffect(() => {
        loadStageSchedule();
    }, [loadStageSchedule]);

    return (
        <>
            {loadingStages && <Loading circular={true}/>}
            {stagesData?.length > 0
                ?  <List>
                    {stagesData.map(item =>
                        <Item 
                            key={item.id}
                            currentStage={item}
                            currentOrder={currentOrder}
                            reloadStageSchedule={loadStageSchedule}
                        />
                    )}
                </List>
               
                : !loadingStages && <div>{messages.DATA_IS_NOT_FOUND}</div>
            }
        </>

    );
};

export default WorkByStages;