const convertCategories = (categories) => categories
    ?.reduce((res, item) => {
        // if (item?.image || item?.image_with_work_order) {
        return {
            ...res,
            [item.id]: item,
        };
        // }
        return res;
    }, {});

export default convertCategories;
