import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from './Filter';
import * as actions from  '../../../../../redux/TransportRoad/actions';
import { transportRoadSelectors } from  '../../../../../redux/TransportRoad';
import Item from './Item';
import messages from '../../../../../helpers/constants/messages';
import { dateWithDashYYYYMMDD } from '../../../../../helpers/date.config';
import { useStoreProp } from '../../../../../helpers/hooks';
import ReturnToButton from '../../../../common/Buttons/ReturnToButton';
import VehicleListHeader from '../../../../common/Transport/VehicleReportList/VehicleListHeader';
import PageLayout from '../../../../layout/PageLayout';
import ReportBlock from '../../../../common/Report/ReportBlock';
import GetReports from '../../../../common/GetReports';

const Vehicles = () => {
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);

    const initialStateFilter = {
        category_id: '',
        organization_id: [],
        start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1) || null,
        end_date: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59) || null
    };

    const vehicleReportData = useSelector(transportRoadSelectors.vehicleReportData);
    const vehicleReportMeta = useSelector(transportRoadSelectors.vehicleReportMeta);
    const loadingVehicleReport = useSelector(transportRoadSelectors.loadingVehicleReport);

    const [filter, setFilter] = useState(initialStateFilter);
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);
    const [selectedFormats, setSelectedFormats] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    const getReport = useCallback((params = filter) => {
        const paramsWithoutEmpty = Object
            .entries(params)
            .reduce((res, [key, value]) => {
                if (value) {
                    if (key === 'start_date' || key === 'end_date') {
                        res[key] = dateWithDashYYYYMMDD(value);
                    } else if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value))) {
                        res[key] = value;
                    }
                }
                return res;
            }, {});

        if (paramsWithoutEmpty?.organization_id?.length > 0) {
            paramsWithoutEmpty.organization_id = paramsWithoutEmpty.organization_id.map(item => item.id);
        }

        if(params.report){
            dispatch(actions.loadRWVehicleReport(paramsWithoutEmpty));
        } else {
            dispatch(actions.loadRWVehicleReport({ page, limit, ...paramsWithoutEmpty }));
        }

    }, [filter, page, limit, dispatch]);

    useEffect(() => {
        if(!Object.keys(filter).length){
            getReport();
        }
    }, [filter, page, limit]);

    const onResetFilter = (needRefresh) => {
        if (needRefresh) {
            getReport(initialStateFilter);
        }
        setFilter(initialStateFilter);
    };

    const categories = useStoreProp(
        actions.loadVehicleCategories,
        'transportRoad',
        'vehicleCategories'
    );

    const handleGetReport = (formats) => {
        getReport({ ...filter, report: 1, formats });
    };

    const renderContent = () => {
        return vehicleReportData?.length > 0
            ? (<VehicleListHeader>
                {vehicleReportData?.map((item, index) =>
                    <Item
                        item={item}
                        key={index}
                        filter={filter}
                        categories={categories}
                        getReport={getReport}
                    />
                )}
            </VehicleListHeader>)
            : (!loadingVehicleReport && <div>{messages.DATA_IS_NOT_FOUND}</div>);
    };

    return (
        <>
            <PageLayout
                header="Отчет по транспортным средствам"
                additionalButtons={<ReturnToButton />}
                filters={<Filter
                    categories={categories}
                    filter={filter}
                    setFilter={setFilter}
                    getReport={getReport}
                    resetFilter={onResetFilter}
                    setIsDisabled={setIsDisabled}
                    isDisabled={isDisabled}
                    initialStateFilter={initialStateFilter}
                />}
                informPanelProps={{
                    buttons: <ReportBlock
                        customComponents={(onclose) => <GetReports
                            disabled={isDisabled}
                            onDownload={handleGetReport}
                            selected={selectedFormats}
                            onChange={setSelectedFormats}  
                            reportId={24}
                        />
                        } 
                    />,
                    total: vehicleReportMeta?.total
                }}
                paginationProps={{
                    loadList: (page) => setPage(page),
                    list: vehicleReportMeta,
                    limit: limit,
                    setLimit: setLimit,
                }}
                content={renderContent}
            />
        </>
    );
};

export default Vehicles;
