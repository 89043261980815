import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import { trafficFlowSelectors } from 'redux/TrafficFlow';
import * as actions from 'redux/TrafficFlow/actions';
import TrafficFlow from 'components/common/Autocomplete/TrafficFlow';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { useStoreFromSelector } from 'helpers/hooks';
import DateRange from 'components/common/Dates/DateRange';
import dateFormat from 'helpers/constants/dateFormat';
import {
    getDateWithDuration,
    getEndOf,
    getStartOf,
} from 'helpers/date.config';

import { renderError, typeOptions } from './helpers';

// todo взято из компонента '../../../../common/PuidCommonFilter'; - старый формат
// нужно привести к 1
const PuidCommonFilter = ({
    formValues,
    setFormValues,
    parameters,
    setParameters,
    reportGroups,
    reportTypes,
    showFieldPuid,
}) => {

    const types = useStoreFromSelector(actions.loadTypes, trafficFlowSelectors.types);

    const handleChangeStartDate = (value) => {
        const addDate = typeOptions[formValues.time_slot];
        const { count, unit } = addDate;
        setFormValues({
            ...formValues,
            start_date: value,
            end_date: getDateWithDuration({ [unit]: count, second: -1 }, value),
        });
    };

    const handleChangeEndDate = (value) => setFormValues({ ...formValues, end_date: value });

    const handleChangePuid = (values) => {
        setFormValues({
            ...formValues,
            id_list: values,
        });
    };

    const handleChangeSelects = (e) => {
        const { value, name } = e.target;

        if (name === 'time_slot') {
            const addDate = typeOptions[value];
            const { count, unit } = addDate;
            setFormValues({
                ...formValues,
                start_date: getDateWithDuration(
                    { days: 1 },
                    getStartOf('day', getDateWithDuration({ [unit]: -count })),
                ),
                end_date: getEndOf('day'),
                [name]: value,
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    return (
        <>
            {(parameters.includes('start_date') && parameters.includes('end_date')) && (
                <DateRange
                    pickersFormat={dateFormat.DATE_WITH_HOUR_MIN_SECONDS}
                    views={['day', 'hours', 'minutes', 'seconds']}
                    valueStartDate={formValues.start_date}
                    valueEndDate={formValues.end_date}
                    handleDate={handleChangeStartDate}
                    handleEndDate={handleChangeEndDate}
                    renderError={() => renderError(formValues, 'start_date', 'end_date', parameters, reportTypes)?.error}
                    errorMessage={() => renderError(formValues, 'start_date', 'end_date', parameters, reportTypes)?.message}
                />
            )}
            {showFieldPuid
                && <FormControl size="small" variant="outlined">
                    <InputLabel>Тип ПУИД</InputLabel>
                    <Select
                        value={formValues?.type_id_list?.[0] || []}
                        onChange={(e) => {
                            setFormValues({
                                ...formValues,
                                type_id_list: !!e.target.value ? [parseInt(e.target.value)] : e.target.value,
                                id_list: [],
                            });
                        }}
                        label="Тип ПУИД"
                    >
                        <MenuItem value="">{titles.NOT_CHOSEN}</MenuItem>
                        {Object.keys(types).map((index) => (
                            <MenuItem key={index} value={index}>{types[index]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
            <div>
                <TrafficFlow
                    disabled={ !!showFieldPuid && !formValues?.type_id_list}
                    typeId={formValues?.type_id_list || null}
                    multiple
                    selected={formValues?.id_list || []}
                    onChange={handleChangePuid}
                    renderProps={(e) => {
                        const addr = [
                            e?.address?.city_name,
                            e?.address?.street,
                            e?.address?.house,
                        ]
                            .reduce((r, i) => i ? [...r, i] : r, []);
                        return `${e?.name} ${addr.length > 0 ? `(${addr?.join(', ')})` : ''}`;
                    }}
                />
            </div>
            <FormControl variant="outlined" size="small">
                <InputLabel>Вид отчета</InputLabel>
                <Select
                    label="Вид отчета"
                    name="report_type"
                    value={formValues?.report_type || ''}
                    onChange={handleChangeSelects}
                >
                    {reportGroups?.length > 0
                        ? reportGroups.map(item =>
                            <MenuItem key={item.description} value={item.value}>
                                {item.description}
                            </MenuItem>
                        )
                        : <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>
                    }
                </Select>
            </FormControl>
            <FormControl variant="outlined" size="small">
                <InputLabel>Тип отчета</InputLabel>
                <Select
                    label="Тип отчета"
                    name="time_slot"
                    value={formValues?.time_slot || ''}
                    onChange={handleChangeSelects}
                >
                    {reportTypes.length > 0
                        ? reportTypes.map(item => {
                            const { description, hint, value, parameters } = item;
                            return (
                                <MenuItem
                                    onClick={() => setParameters(parameters)}
                                    key={value}
                                    value={value}
                                >
                                    {description}({hint})
                                </MenuItem>
                            );
                        })
                        : <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>
                    }
                </Select>
            </FormControl>
        </>
    );
};

export default PuidCommonFilter;
