import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mapHelper from '../../../../helpers/mapHelper';
import * as actions from '../../../../redux/RoadNetwork/actions';
import config from './config.js';
import { createIconMarker, getColor } from './helper';
import {
    Marker,
    PopUp,
    ToolTip,
    ContextMenu,
    ContextMenuItem,
    GeoJson,
} from '../../leaflet';
import { isEqual } from 'lodash';
import { usePrevious } from '../../../../helpers/hooks';
import { getFilter } from './helper';
import getFilters from '../../helpers/getFilters';
import { roadNetworksSelectors } from '../../../../redux/RoadNetwork';
import useForm from './ModalForm/useForm';
import createPointGJ from '../../leaflet/helpers/createPointGJ';
import L from 'leaflet';
import PopUpComponent from './PopUp';
import useLoadPolygonLayer from '../../../../helpers/hooks/useLoadPolygonLayer';
import getLatLonFromGeometry from '../../helpers/getLatLonFromGeometry';


const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();
 
    const polygonLights = useSelector(roadNetworksSelectors.polygonInfrastructure);
    const active = useSelector(roadNetworksSelectors.activePolygonInfrastructure);
    const filters = useSelector(roadNetworksSelectors.filterPolygonInfrastructure);
    const polygonLoading = useSelector(roadNetworksSelectors.loadingPolygonInfrastructure);
    const prevLoading = usePrevious(polygonLoading);

    const [selectedItem, setSelectedItem] = useState(null);
    const [clickedItem, setClickedItem] = useState(null);

    const activeId = active?.id || null;

    // проверка на маркер
    const isMarker = (item) => item?.geometry?.geometry?.type === 'Point'
        && !item?.geometry?.properties?.radius;

    const createLatLng = (item) => {
        const { lat, lon } = getLatLonFromGeometry(item?.geometry);
        return lat && lon ? [lat, lon] : null;
    };

    const {
        // маркеры
        staticMarkers,
        // гео маркеры
        geoMarkers
    } = useMemo(() => {
        return polygonLights.reduce((res, item) => {
            const latlng = createLatLng(item);

            // нет координат
            if (!latlng) return res;

            if (isMarker(item)) {
                res.staticMarkers.push({
                    ...item,
                    latlng,
                });
            } else if (item.id !== selectedItem?.id) {
                res.geoMarkers.push({
                    ...item,
                    latlng,
                });
            }

            return res;
        }, {
            staticMarkers: [],
            geoMarkers: []
        });
    }, [polygonLights, selectedItem]);

    const prevFilters = usePrevious(filters);
    const filter = getFilters(filters, getFilter);

    const previewId = useRef(0);

    useEffect(() => {
        if (polygonLoading === false && prevLoading === true && previewId.current) {
            const id = previewId.current;
            setTimeout(() => {
                map.fire(`showBy${config.slug}${id}`);
            }, 200);
            previewId.current = 0;
        }
    }, [polygonLoading]);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadPolygonInfrastructure(
            polygon,
            filter
        ));
    };

    // задерживаем одновременные запросы
    const fetchProvider = useLoadPolygonLayer(fetchPolygon);
    const handleFetchPolygon = () => fetchProvider.load();

    const handlePUO = () => {
        fetchProvider.lock();
    };
    const handlePUC = () => {
        fetchProvider.unLock();
    };

    const handleSaved = () => {
        map.closeContextMenu();
        dispatch(actions.setEditForm());
        fetchPolygon();
        setSelectedItem(null);
    };
    const providerForm = useForm(handleSaved);

    const handleClickMap = () => {
        setSelectedItem(null);
        setClickedItem(null);
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            handleFetchPolygon();
            fetchProvider.unLock();
        }

        map
            .on('click', handleClickMap)
            .on('moveend', handleFetchPolygon)
            .on('popupopen', handlePUO)
            .on('popupclose', handlePUC);

        return () => {
            dispatch(actions.setActivePolygonInfrastructure());
            dispatch(actions.clearPolygonInfrastructure());
            map
                .off('click', handleClickMap)
                .off('moveend', handleFetchPolygon)
                .off('popupopen', handlePUO)
                .off('popupclose', handlePUC);
        };
    }, [filters]);

    // добавление нового элемента с карты
    useEffect(() => {
        // добавить новый
        map.on(config.mapContextMenu.event, (e) => {
            const { lat, lng } = e.latlng;
            providerForm.showAddForm({
                lat,
                lon: lng,
                geometry: createPointGJ(lat, lng)
            });
        });

        return () => {
            map.off(config.mapContextMenu.event);
            dispatch(actions.clearPolygonInfrastructure());
        };
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { geometry } = active;
            if (geometry && geometry?.geometry?.coordinates.length > 0) {
                previewId.current = active.id;
                const center = L.geoJSON(geometry)?.getBounds?.().getCenter?.();
                if (center) map.setView(center, 14);
            }

            setClickedItem(active.id);
            fetchProvider.unLock();
        }
    }, [active]);

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProp }) => {
        return (
            <div>
                <ContextMenuItem
                    {...rcmProp}
                    value="Редактировать"
                    onClick={() => {
                        map.fire('context_menu_close');
                        providerForm.showEditForm(item);
                    }}
                />
                <ContextMenuItem
                    {...rcmProp}
                    value="Удалить"
                    onClick={() => {
                        map.fire('context_menu_close');
                        providerForm.showConfirmDelete(item.id);
                    }}
                    className="red"
                />
            </div>
        );
    };

    const handleClick = (item) => () => {
        setClickedItem(item.id);

        if (clickedItem === item.id) {
            setSelectedItem(item);
        }
        map.setView(item.latlng);
    };

    // отображаемый геообъект
    const selectedItemGeo = useMemo(() => selectedItem
        ? {
            ...selectedItem.geometry,
            properties: {
                ...selectedItem.geometry.properties,
                data: selectedItem,
                attribution: {
                    slug: config.slug,
                    color: getColor(selectedItem)
                },
            },
            style: {
                color: getColor(selectedItem),
                weight: 7
            }
        }
        : null
    , [selectedItem]);

    return (
        <>
            {/* статичные маркеры */}
            {staticMarkers.map(item => {
                const currentShow = activeId === item.id;
                return (
                    <Marker
                        {...props}
                        icon={createIconMarker(item)}
                        latlng={item.latlng}
                        attribution={{
                            slug: config.slug,
                            color: getColor(item)
                        }}
                        key={item.id}
                    >
                        <ToolTip
                            direction="top"
                            offset={[0, -40]}
                        >
                            <div>
                                {item.name}
                            </div>
                        </ToolTip>

                        <PopUp show={currentShow}>
                            <PopUpComponent item={item}/>
                        </PopUp>

                        <ContextMenu>
                            <RenderContextMenu item={item}/>
                        </ContextMenu>
                    </Marker>
                );
            })}

            {/* геообъекты маркерами */}
            {geoMarkers.map(item => {
                const currentShow = activeId === item.id;
                return (
                    <Marker
                        {...props}
                        icon={createIconMarker(item)}
                        latlng={item.latlng}
                        attribution={{
                            slug: config.slug,
                            color: getColor(item)
                        }}
                        key={item.id}
                        onClick={handleClick(item)}
                    >
                        <ToolTip
                            direction="top"
                            offset={[0, -40]}
                        >
                            <div>
                                {item.name}
                            </div>
                        </ToolTip>

                        <PopUp show={currentShow}>
                            <PopUpComponent item={item} bottomText={'Кликните, чтобы отобразить объект'}/>
                        </PopUp>

                        <ContextMenu>
                            <RenderContextMenu item={item}/>
                        </ContextMenu>
                    </Marker>
                );
            })}

            {/* геообъект */}
            {selectedItemGeo && (
                <GeoJson
                    {...props}
                    parent={map}
                    data={selectedItemGeo}
                    // если вдруг прилетел маркер
                    icon={item => createIconMarker(item)}
                    centerAfter={true}
                />
            )}

            {/* форма */}
            {providerForm.renderComponent()}
        </>
    );
};

export default Layer;