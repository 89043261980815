import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/Version/actions';
import { useEffect } from 'react';
import {
    Alert,
    IconButton,
    Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';

// стили
const useStyles = makeStyles({
    block_wrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center'
    },
});

// хук проверки версии
const useVersion = (loadTimeout = /*30 **/ 60 * 1000) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const {
        show
    } = useSelector(({ version }) => version);

    const currentVersion = localStorage.getItem('version') || null;
    const newVersion = localStorage.getItem('newVersion') || null;

    // запрос получения версии
    const fetchVersion = () => dispatch(actions.loadVersion());

    useEffect(() => {
        // при загрузке страницы однократная проверка - новая версия
        if (
            currentVersion
            && newVersion
            && currentVersion !== newVersion
        ) {
            localStorage.setItem('version', newVersion);
            localStorage.removeItem('newVersion');
        }
        // если нет версии - подгружаем
        if (!currentVersion) {
            setTimeout(fetchVersion, 5 * 1000);
        }

        // загрузка версии
        setInterval(fetchVersion, loadTimeout);
    }, []);

    // закрыть всплывашку
    const handleClose = () => {
        dispatch(actions.showVersion(false));
    };

    // блок
    if (show) {
        return (
            <div className={classes.block_wrapper}>
                <Alert
                    severity="error"
                    variant="filled"
                    action={
                        <Tooltip title="Закрыть">
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleClose}
                            >
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        </Tooltip>
                    }
                    onClose={() => {
                    }}
                >
                    Вышла новая версия приложения, обновите страницу
                </Alert>
            </div>
        );
    }
    return (<></>);
};

export default useVersion;
