import messages from '../../../../../helpers/constants/messages';

const TabInformation = ({ data, title, history }) => {

    return (
        <div className="scroll">
            {Object.keys(title).map((key) => {
                const value = data[key];

                if (value) {
                    return (
                        <div className="info__item" key={`information_${key}`}>
                            <span className="info__item-title">
                                {title[key].title || ''}
                                {': '}
                            </span>
                            <span>
                                {title[key]['value']
                                    ? title[key].value(data)
                                    : value || messages.INFO_IS_NOT_FOUND}
                            </span>
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default TabInformation;
