import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemText, } from '@mui/material';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { deleteExclusiveFile } from 'redux/DorisControl/actions';
import { fullDateTime } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import { renderArticleNumbers } from 'components/common/DorisControl/PddViolation/renderArticleName';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';

const icons = [
    <DoDisturbAltIcon color="error" />,
    <ErrorOutlineOutlinedIcon color="warning"/>,
    <CheckCircleOutlineIcon color="success"/>,
];

const Item = ({ item, loadList }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return (
        <>
            <ListItem className="list_item">
                <ListItemText>
                    <div><b>Название: </b>{item?.file_name}</div>
                    <div style={{ display: 'flex' }} ><b>Список статей КОАП:&nbsp; </b>{item.pdd_violations?.map((el, i, arr) => (
                        <span key={i}>
                            {renderArticleNumbers(el)}{(i !== arr.length - 1) && ','}&nbsp;
                        </span>
                    ))}
                    </div>
                    <div><b>Дата изменения: </b>{fullDateTime(item?.updated_at)}</div>
                    <div><b>Изменил: </b>{item?.updater?.first_name} {item?.updater?.middle_name} {item?.updater?.last_name}</div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        <b>Статус: </b>
                        {item.status || item.status === 0 ? icons[item.status] : messages.NO_DATA}
                    </div>
                </ListItemText>
                <FormButtonsComponent
                    noMarginLeft
                    justButton
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setOpenEditModal(true)
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setOpenDeleteModal(true)
                        }
                    ]}
                />
                {openEditModal && (
                    <ModalForm
                        isOpen={openEditModal}
                        onClose={() => setOpenEditModal(false)}
                        item={item}
                        loadList={loadList}
                    />
                )}
                {openDeleteModal &&  (
                    <ConfirmDelete
                        open={openDeleteModal}
                        onSuccess={() => dispatch(deleteExclusiveFile(item.id, loadList))}
                        onClose={() => setOpenDeleteModal(false)}
                    />
                )}
            </ListItem>
        </>
    );
};

export default Item;
