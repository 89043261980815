import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { createVehicle, editVehicle } from 'modules/TransportWater/redux/actions';
import SelectVehicleType from 'modules/TransportWater/utils/components/SelectVehicleType';
import buttonsTypes from 'components/common/FormButtons/buttonsTypes';
import FormButtons from 'components/common/FormButtons';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import Modal from 'components/common/Modal';
import InputNumber from 'components/common/Inputs/InputNumber';

import type { Vehicle } from './types';

interface BaseProps {
    isOpen: boolean;
    onClose: () => void;
    reloadList: (isDelete?: boolean) => void;
}

interface AddProps extends BaseProps {
    isNew: true;
    item?: never;
}

interface EditProps extends BaseProps {
    isNew?: false;
    item: Vehicle;
}

function ModalForm({ isOpen, onClose, isNew, item, reloadList }: AddProps | EditProps) {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initState = {
        name: item?.name || '',
        short_name: item?.short_name || '',
        mmsi: item?.mmsi || '',
        type: item?.type || '',
        cargo_type: item?.cargo_type || '',
    };

    const [formState, setFormState] = useState(initState);

    const handleChange = (name: keyof typeof initState, value: any) => {
        validation.deleteKey(name);
        setFormState((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSave = () => {
        const prepareData = removeEmptyFields(formState);

        const callback = () => {
            onClose();
            reloadList();
        };

        isNew
            ? dispatch(createVehicle(prepareData, callback))
            : dispatch(editVehicle(item.id, prepareData, callback));
    };

    const disabled = Object.values(formState).length === 0;

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            isOpen={isOpen}
            onClose={onClose}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose,
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSave,
                            disabled
                        },
                    ]}
                />
            }
        >
            <form className="modal__form">
                <TextField
                    value={formState.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    label={titles.NAME}
                    className="block"
                />

                <TextField
                    value={formState.short_name}
                    onChange={(e) => handleChange('short_name', e.target.value)}
                    label="Позывной"
                    className="block"
                />

                <FormInfoWrapper
                    error={validation.isKey('mmsi') || (formState.mmsi.length > 0 && formState.mmsi.length < 9)}
                    helperText={validation.get('mmsi') || 'Введите 9 цифр'}
                    className="block"
                >
                    <InputNumber
                        value={formState.mmsi}
                        onChange={(e) => handleChange('mmsi', e.target.value)}
                        label="Индивидуальный девятизначный номер судна или морской службы"
                        error={validation.isKey('mmsi')}
                        required
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    error={validation.isKey('type')}
                    helperText={validation.get('type')}
                    className="block"
                >
                    <SelectVehicleType
                        value={formState.type}
                        onChange={(value) => handleChange('type', value)}
                        error={validation.isKey('type')}
                    />
                </FormInfoWrapper>
            </form>
        </Modal>
    );
}

export default ModalForm;
