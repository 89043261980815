import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Item from './Item';
import { ObjectWithId } from './index';

interface SortableItemProps {
  item: ObjectWithId;
  index: number;
  itemsRender: (item: ObjectWithId, index: number) => React.ReactElement;
  withSelected?: boolean;
}

const SortableItem = ({
    item,
    itemsRender,
    index,
    withSelected,
}: SortableItemProps) => {
    const {
        attributes,
        listeners,
        transition,
        transform,
        setNodeRef,
        isDragging,
    } = useSortable({ id: item.id });

    return (
        <Item
            ref={setNodeRef}
            item={item}
            index={index}
            itemsRender={itemsRender}
            attributes={attributes}
            listeners={listeners}
            style={{
                transform: CSS.Translate.toString(transform),
                transition,
                // ...(isDragging && { opacity: 0.5 }),
                ...((withSelected && index % 2 === 0) && { backgroundColor: '#F0F1FA' }),
            }}
        />
    );
};

export default SortableItem;
