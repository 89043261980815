import { useCallback, useState } from 'react';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import DateRange from 'components/common/Dates/DateRange';
import SelectStations from 'components/common/Autocomplete/PassengerTransport/Stations';
import { useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone, getStartOf, getEndOf } from 'helpers/date.config';

const Filter = ({ setParams, check_point }) => {
    const validation = useValidation();

    const initState = {
        date_start: getStartOf(),
        date_end: getEndOf(),
        station_id_list: check_point ? [check_point] : []
    };
    
    const [filterData, setFilterData] = useState(initState);

    const prepareData = useCallback((filter = filterData) => removeEmptyFields({
        ...filter,
        date_start: fullDateTimeWithTimeZone(filter.date_start),
        date_end: fullDateTimeWithTimeZone(filter.date_end),
        station_id_list: filter.station_id_list.map((el) => el.id),
    }), [filterData]);

    const handleChange = (name, value) => {
        setFilterData({ ...filterData, [name]: value });
    };

    const handleResetFilter = (needRefresh) => {
        if (needRefresh) {
            setParams({ page: 1, data: {
                date_start: fullDateTimeWithTimeZone(getStartOf()),
                date_end: fullDateTimeWithTimeZone(getEndOf()),
                station_id_list: check_point ? [check_point?.id] : [],
            } });
        }
        setFilterData(initState);
    };

    const handleSearch = () => {
        setParams({ page: 1, data: prepareData() });
    };

    const updateFilter = (filters) => {
        setFilterData({ ...initState, ...filters });

        if (Object.values(filters).length > 0) {
            setParams({ page: 1, data: prepareData({ ...initState, ...filters }) });
        }
    };

    const isDisabled = (filterData.date_start && filterData.date_end) && (new Date(filterData.date_start) >= new Date(filterData.date_end));

    return (
        <LayoutFilter
            onResetFilter={handleResetFilter}
            onSearch={handleSearch}
            filter={filterData}
            setUserFilter={updateFilter}
            filterException={['date_start', 'date_end']}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <div className="row noMargin">
                    <DateRange
                        valueStartDate={filterData.date_start}
                        valueEndDate={filterData.date_end}
                        handleDate={(date) => handleChange('date_start', date)}
                        handleEndDate={(date) => handleChange('date_end', date)}
                        renderError={() => validation.isKey('date_start') || validation.isKey('date_end')}
                        errorMessage={() => validation.isKey('date_start') || validation.isKey('date_end')}
                        maxDate={new Date()}
                    />
                </div>

                <SelectStations
                    multiple
                    disabled={!!check_point}
                    selected={filterData.station_id_list}
                    onChange={(value) => handleChange('station_id_list', value)}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
