import { useState } from 'react';
import { Chip } from '@mui/material';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import messages from 'helpers/constants/messages';
import PassportTabs from 'components/pages/Dictionaries/DorisControl/VFComplex/PassportPage/PassportTabs.js';

import type { ComplexPropsType } from '../../types';

const ComplexItem = ( { item, i, clickable = true }: ComplexPropsType) => {
    const [isOpenModal, setIsOpenModal] = useState(false);

    return (<>
        <div key={item.id} className="info">
            <div className="row">
                {i + 1}{'. '}
                <div>
                    <div>{item.name || messages.NO_DATA}</div>
                    <div><b>адрес:</b> {item.address_text || messages.NO_DATA}</div>
                    <div><b>серийный номер: </b>
                        {clickable
                            ? <Chip
                                label={item.serial_number}
                                size="small"
                                variant="outlined"
                                onClick={() => setIsOpenModal(true)}
                            />
                            : item.serial_number
                        }
                    </div>
                </div>
            </div>
        </div>
        {isOpenModal && (
            <Modal
                isOpen={isOpenModal}
                onClose={() => setIsOpenModal(false)}
                noPadding={true}
                title={''}
                fullWidth
                showCloseInTitle
                buttons={<FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: () => setIsOpenModal(false)
                        },
                    ]}
                />}
            >
                <PassportTabs
                    id={item.id}
                    item={item}
                />
            </Modal>
        )}
    </>
    );
};

export default ComplexItem;
