import { useContext, useEffect, useMemo, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import * as actions from 'redux/Boards/actions';
import { boardsSelectors } from 'redux/Boards';
import messages from 'helpers/constants/messages';
import { usePrevious, useStoreFromSelector } from 'helpers/hooks';
import Context from 'helpers/context';
import buttons from 'helpers/constants/buttons';
import colorExtend from 'helpers/mapHelper/colorExtend';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import SidebarItem from 'components/MapComponents/SidebarItem';
import getFilters from 'components/MapComponents/helpers/getFilters';
import Loading from 'components/common/Loading';

import config from '../config';
import { getCategoryIcon } from '../helper';

import Item from './Item';
import FilterForm from './Filter';


const SideBar = (props) => {
    const dispatch = useDispatch();
    // const [page, setPage] = useState(1);
    // const [limit, setLimit] = useState(10);
    const { permissions } = useContext(Context);

    const scrollRef = useRef(null);

    const list = useSelector(boardsSelectors.sidebar);
    const loading = useSelector(boardsSelectors.sidebarLoading);
    const active = useSelector(boardsSelectors.active);
    const saved = useSelector(boardsSelectors.saved);
    const filters = useSelector(boardsSelectors.filters);
    const sidebarParams = useSelector(boardsSelectors.sidebarParams);
    const prevFilters = usePrevious(filters);

    const statuses = useStoreFromSelector(actions.loadBoardStatuses, boardsSelectors.boardStatuses);
    const statusesObj = useMemo(() => statuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [statuses]);

    useEffect(() => {
        return () => {
            dispatch(actions.setSidebarParams({
                ...sidebarParams,
                page: 1,
            }));
        };
    }, []);

    const activeId = Object.keys(active).length > 0 ? active.id : 0;

    const scroolTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    const fetchList = () => {
        const filter = getFilters(filters);
        const { page, limit } = sidebarParams;
        dispatch(actions.loadSidebar(
            page,
            limit,
            filter
        ));
    };

    const handleScrollUpdate = (values) => {
        const {
            //current_page = 0,
            last_page = 0
        } = list?.meta;
        if (
            values.top > 0.98
            && loading === false
            && sidebarParams.page < last_page
        ) {
            dispatch(actions.setSidebarParams({
                ...sidebarParams,
                page: sidebarParams.page + 1
            }));
        }
    };

    useEffect(() => {
        if (saved) {
            scroolTop();
            if (sidebarParams.page > 1) {
                dispatch(actions.setSidebarParams({
                    ...sidebarParams,
                    page: 1
                }));
            } else {
                fetchList();
            }
        }
    }, [saved]);

    // отслеживаем изменение проекта
    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            scroolTop();
            dispatch(actions.setSidebarParams({
                ...sidebarParams,
                page: 1
            }));
        }
        fetchList();
    }, [sidebarParams, filters]);

    const renderList = (dataList) => (
        <>
            {dataList?.map((item) => {
                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        icon={`<i class="${getCategoryIcon(item.category_id)}" style="font-size: 1.5rem; color: ${colorExtend(statusesObj[item.status]?.color || '')}"/>`}
                        buttons={{
                            ...(permissions.is_update && { edit: {
                                title: buttons.EDIT,
                                icon: <i className="fas fa-pen" />,
                                onClick: () => {
                                    // редактирование
                                    dispatch(actions.setEditForm(item));
                                }
                            } }),
                            ...(permissions.is_delete && { delete: {
                                title: buttons.DELETE,
                                icon: <i className="fas fa-trash-alt" />,
                                onClick: () => {
                                    // удаление
                                    dispatch(actions.setDeleteForm(item.id));
                                }
                            } }),
                        }}
                        onClick={() => {
                            dispatch(actions.setActive(item));
                        }}
                    >
                        <Item {...item}/>
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={() => dispatch(actions.setSidebarParams({
                    ...sidebarParams,
                    page: 1
                }))}
                onSetFilter={(filter) => dispatch(actions.setFilters(filter))}
                onClearFilter={() => dispatch(actions.clearFilters())}
                content={({ data, onChange }) =>
                    <FilterForm
                        data={data}
                        onChange={onChange}
                    />
                }
                layer={config.slug}
            />

            {loading && list?.data?.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                    ref={scrollRef}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={config.name}
                            list={list}
                        />
                        {
                            list.data.length === 0
                                ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                                : renderList(list?.data)
                        }
                    </div>
                    {loading && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
};

export default SideBar;
