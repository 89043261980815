import dtp from './dtp';
import roadWorks from './roadWorks';
import companies from './companies';
import dorisControl from './dorisControl';
import videoanalytics from './videoanalytics';
import dorisReact from './dorisReact';
import transportPassenger from './transportPassenger';
import transportSpecial from './transportSpecial';
import efficiency from './efficiency';
import puid from './puid';
import trafficFlow from './trafficFlow';

const routes = [
    ...dtp,
    ...roadWorks,
    ...companies,
    ...dorisControl,
    ...videoanalytics,
    ...dorisReact,
    ...transportPassenger,
    ...transportSpecial,
    ...efficiency,
    ...puid,
    ...trafficFlow,
];

export default routes;