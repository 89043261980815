import messages from 'helpers/constants/messages';

// вывести значение в зависимости от типа
export const renderValueByType = (value, type) => {
    switch (type) {
        case 'boolean':
            return parseInt(value) ? messages.YES : messages.NO;
        // case 'integer':
        //     return value;
        case 'percent':
            return `${value ? `${value}%` : '-'}`;
        default:
            return value;
    }
};
