import React from 'react';
import { List } from '@mui/material';
import '../style.scss';

// оболочка списка
const ListContainer = ({
    children,
}) => {
    return (
        <List className="list list_style noPadding">
            {children}
        </List>
    );
};

export default ListContainer;