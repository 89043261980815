import * as types from './types';

const initialState = {
    incidents: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingIncidents: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_INCIDENTS:
            return {
                ...state,
                incidents: payload,
            };

        case types.LOADING_INCIDENTS:
            return {
                ...state,
                loadingIncidents: payload,
            };

        default:
            return state;
    }
}
