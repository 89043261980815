import { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SelectRoadWorks from 'components/common/Autocomplete/RoadWorks/Sites';

const Filter = ({ setParams }) => {
    const initState = {
        name: '',
        road_work_id_list: []
    };
    
    const [data, setData] = useState(initState);

    const prepareData = (filter = data) => removeEmptyFields({
        ...filter,
        road_work_id_list: filter.road_work_id_list.map(({ id }) => id)
    });

    const resetFilter = (needRefresh) => {
        needRefresh && setParams({ page: 1, query: prepareData(initState) });
        setData(initState);
    };

    const updateFilter = (filters) => { // фильтры с бэка
        setData({ ...initState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({
                page: 1,
                query: { 
                    ...removeEmptyFields(initState), 
                    ...prepareData(filters)
                }
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={() => setParams({ page: 1, query: prepareData() })}
            filter={data}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={data?.name}
                    onChange={({ target : { name, value } }) => setData({ ...data, [name]: value })}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    name="name"
                    type="text"
                />

                <SelectRoadWorks
                    multiple
                    label="Участок"
                    roadWorkList={data.road_work_id_list}
                    onRoadWorkChange={(road_work_id_list) => setData({ ...data, road_work_id_list })}
                    renderOption={(option) => option?.name || ''}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
