import RWVehicles from 'components/pages/Dictionaries/RoadWorks/RWVehicles';
import RWBrands from 'components/pages/Dictionaries/RoadWorks/Brands';
import RWModels from 'components/pages/Dictionaries/RoadWorks/Models';
import Orders from 'components/pages/Dictionaries/RoadWorks/Orders';
import ParkSpecialTransport from 'components/pages/Dictionaries/RoadWorks/ParkSpecialTransport';
import Sites from 'components/pages/Dictionaries/RoadWorks/Sites';
import DefectGroups from 'components/pages/Dictionaries/RoadWorks/DefectGroups';
import DefectTypes from 'components/pages/Dictionaries/RoadWorks/DefectTypes';
import Units from 'components/pages/Dictionaries/RoadWorks/Units';
import RoadWorkGroups from 'components/pages/Dictionaries/RoadWorks/RoadWorkGroups';
import Works from 'components/pages/Dictionaries/RoadWorks/Works';
import VehicleTypes from 'components/pages/Dictionaries/RoadWorks/VehicleTypes';
import SpeedColor from 'components/pages/Dictionaries/RoadWorks/SpeedColor';
import Defects from 'components/pages/Dictionaries/RoadWorks/Defects';
import Employees from 'components/pages/Dictionaries/RoadWorks/Employees';
import Positions from 'components/pages/Dictionaries/RoadWorks/Positions';
import Brigades from 'components/pages/Dictionaries/RoadWorks/Brigades';
import Routes from 'components/pages/Dictionaries/RoadWorks/Routes';
import TelemetryReceiversForTR from 'components/pages/Dictionaries/RoadWorks/TelemetryReceiversForRW';
import ElectronicITMS from 'components/pages/Dictionaries/RoadWorks/ElectronicITMS';
import RoadWorkSettings from 'components/pages/Dictionaries/RoadWorks/Settings';
import Workorders from 'components/pages/Dictionaries/RoadWorks/WorkOrders';
import OrderTypes from 'components/pages/Dictionaries/RoadWorks/OrderTypes';
import WorkordersControl from 'components/pages/Dictionaries/RoadWorks/WorkordersControl';
import CheckPoints from 'components/pages/Dictionaries/RoadWorks/CheckPoints';
import RepairObjectIndicators from 'components/pages/Dictionaries/RoadWorks/RepairObjectIndicators';
import RepairObjects from 'components/pages/Dictionaries/RoadWorks/RepairObjects';
import RepeatersTelemetry from 'components/pages/Dictionaries/RoadWorks/RepeatersTelemetry';

const routes = [
    {
        path: '/dictionaries/roadworks/vehicles',
        component: RWVehicles,
        exact: true,
    },
    {
        path: '/dictionaries/roadworks/brands',
        component: RWBrands,
        exact: true,
    },
    {
        path: '/dictionaries/roadworks/models',
        component: RWModels,
        exact: true,
    },
    {
        path: '/dictionaries/roadworks/orders',
        component: Orders,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/parks',
        component: ParkSpecialTransport,
        exact: true
    },
    {
        path: '/dictionaries/roadworks',
        component: Sites,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/defect-groups',
        component: DefectGroups,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/defect-type',
        component: DefectTypes,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/units',
        component: Units,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/roadwork-groups',
        component: RoadWorkGroups,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/works',
        component: Works,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/vehicle-types',
        component: VehicleTypes,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/speed-color',
        component: SpeedColor,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/defects',
        component: Defects,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/employees',
        component: Employees,
        exact: true,
    },
    {
        path: '/dictionaries/roadworks/positions',
        component: Positions,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/brigades',
        component: Brigades,
        exact: true,
    },
    {
        path: '/dictionaries/roadworks/routes',
        component: Routes,
        exact: true,
    },
    {
        path: '/dictionaries/roadworks/telemetry-receivers',
        component: TelemetryReceiversForTR,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/electronicITMS',
        component: ElectronicITMS,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/settings',
        component: RoadWorkSettings,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/workorders',
        component: Workorders,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/order-types',
        component: OrderTypes,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/workorders-control',
        component: WorkordersControl,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/check-points',
        component: CheckPoints,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/repair-object-indicators',
        component: RepairObjectIndicators,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/repair-objects',
        component: RepairObjects,
        exact: true
    },
    {
        path: '/dictionaries/roadworks/repeaters-telemetry',
        component: RepeatersTelemetry,
        exact: true
    },
];

export default routes;
