import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';

import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

const FileUploader = ({
    action, // экшен по которому мы загружаем файл
    onFileUpload, // функция колбэк, которая вернется в родительский компонент и что-то сделает с полученным урлом
    service, // doris-control, dtp etc.
    title, // заголовок на кнопке
    isButton = false,
    noPadding = true,
    noMarginLeft = true,
    multiple = false,
    startIcon,
    endIcon,
    callback,
    buttonType = 'upload',
    test_id_prefix = '', // для авто тестирования элементов на разных страницах
}) => {
    const dispatch = useDispatch();

    const onDrop = useCallback((acceptedFiles) => {
        // acceptedFiles это массив, у нас там будет 1шт поэтому берем нулевой эл-т
        dispatch(action(multiple ? acceptedFiles : acceptedFiles[0], service, onFileUpload));
        callback && callback();
    }, [onFileUpload, action, service, dispatch, multiple, callback]);

    const { getRootProps, getInputProps, open } = useDropzone({ onDrop, multiple });

    return(
        <div {...getRootProps()} onClick={ e => e.stopPropagation() }>
            <input {...getInputProps()} style={{ visibility: 'hidden', width: '0', position: 'absolute' }} data-testid={`${test_id_prefix}/upload/input`} />
            {isButton
                ? <FormButtons
                    noPadding={noPadding}
                    noMarginLeft={noMarginLeft}
                    buttons={[
                        {
                            ...buttonsTypes[buttonType],
                            onClick: open,
                            ...(title ? { name: title } : {}),
                            ...(startIcon ? { startIcon } : {}),
                            ...(endIcon ? { endIcon } : {}),
                            testName: 'upload',
                        }
                    ]}
                    test_id_prefix={test_id_prefix}
                />
                : <Tooltip title={title}>
                    <IconButton size="small" onClick={open} data-testid={`${test_id_prefix}/upload/button`}>
                        <i className="far fa-paperclip" />
                    </IconButton>
                </Tooltip>
            }
        </div>
    );
};

export default FileUploader;
