import { useState } from 'react';
import { useDispatch } from 'react-redux';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

import * as actions from 'redux/TransportRoad/actions';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import SelectDateHistory from 'components/common/Transport/TransportHistory/SelectDateHistory';
import {
    LSContentItem,
    LSContentColumn,
    ActionsButtons,
    ActionMore,
} from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import DowntimeModal from './DowntimeModal';
import FuelDumpModal from './FuelDumpModal';
import Info from './Info';
import ModalForm from './ModalForm';

const Item = ({ item, reloadList, categoryTS, }) => {
    const dispatch = useDispatch();

    const [openInfo, setOpenInfo] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDowntimeModal, setOpenDowntimeModal] = useState(false);
    const [openFuelDumpModal, setOpenFuelDumpModal] = useState(false);
    const [showHistoryRange, setShowHistoryRange] = useState(false);

    const handleOpenFuelDumpModal = (e) => {
        e.stopPropagation();
        setOpenFuelDumpModal(true);
    };

    const handleOpenEditModal = (e) => {
        e.stopPropagation();
        setOpenEditModal(true);
    };

    const handleOpenDeleteModal = (e) => {
        e.stopPropagation();
        setOpenDeleteModal(true);
    };

    const handleOpenDowntimeModal = (e) => {
        e.stopPropagation();
        setOpenDowntimeModal(true);
    };

    const handleDelete = () => {
        dispatch(actions.deletedVehicle(item.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    const category = categoryTS?.find(({ id }) => id === item?.category_id);
    const titleHistory = () => {
        const title = [];
        const categoryName = category?.name || null;
        if (categoryName) {
            title.push(categoryName);
            const garage_number = item?.garage_number || null;
            if (garage_number) {
                title.push(`номер ${garage_number}`);
            }
        } else if (item?.uid) {
            title.push(item?.uid);
        }

        return title.join(', ');
    };

    return (
        <>
            <LSContentItem onClick={() => setOpenInfo(prev => !prev)}>
                <LSContentColumn>
                    {item?.number || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.brand_name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.model_name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {category?.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn align="right" isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <i className="fas fa-garage-car" />,
                                name: 'Простой',
                                onClick: handleOpenDowntimeModal,
                            },
                            {
                                icon: <LocalGasStationIcon />,
                                name: 'Слив топлива',
                                onClick: handleOpenFuelDumpModal,
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: handleOpenEditModal,
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: handleOpenDeleteModal,
                            },
                            {
                                ...buttonsTypes.historyIcon,
                                name: 'История перемещений',
                                onClick: () => setShowHistoryRange(true)
                            },
                        ]}
                    />
                    <ActionMore isOpen={openInfo} />
                </LSContentColumn>
            </LSContentItem>

            {showHistoryRange
                && <SelectDateHistory
                    uuid={item?.id}
                    title={titleHistory()}
                    isIconButton
                    actions={{
                        storeProp: 'transportRoad',
                        history: {
                            action: actions.loadVehicleHistoryModal,
                            clear: actions.clearVehicleHistoryModal,
                            loadingProp: 'historyModalLoading',
                            dataProp: 'historyModal',
                        },
                        speedList: {
                            action: actions.loadColorSpeed,
                            dataProp: 'colorSpeed',
                        }
                    }}
                    needButton={false}
                    isOpen={showHistoryRange}
                    onClose={() => setShowHistoryRange(false)}
                />
            }

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}

            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                />
            )}

            {openDowntimeModal && (
                <DowntimeModal
                    isOpen={openDowntimeModal}
                    onClose={() => setOpenDowntimeModal(false)}
                    item={item}
                />
            )}

            {openInfo && <Info isOpen={openInfo} info={item}/>}

            {openFuelDumpModal && (
                <FuelDumpModal
                    isOpen={openFuelDumpModal}
                    onClose={() => setOpenFuelDumpModal(false)}
                    item={item}
                />
            )}
        </>
    );
};

export default Item;
