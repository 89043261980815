import * as types from './types';

/**
 * Actions
 **/

// типы ТС
// export const loadVehiclesTypes = () => ({
//     type: types.LOAD_TYPES
// });
//
// export const loadedVehiclesTypes = (data) => ({
//     type: types.LOADED_TYPES,
//     payload: data
// });

export const setFilters = (filter) => ({
    type: types.SET_FILTER,
    payload: filter,
});
export const clearFilters = () => ({
    type: types.SET_FILTER,
    payload: {},
});

export const setActive = (item) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const clearActive = () => ({
    type: types.SET_ACTIVE,
    payload: {},
});

export const setFilterWsOrganizations = (
    filterWsOrganization = [],
    isFilterWsOrganization = false
) => ({
    type: types.SET_FILTER_WS_ORGANIZATIONS,
    payload: {
        filterWsOrganization,
        isFilterWsOrganization
    },
});

export const loadSidebar = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_SIDEBAR,
    payload: {
        page,
        limit,
        params,
    },
});
export const loadedSidebar = (data) => ({
    type: types.LOADED_SIDEBAR,
    payload: data,
});
export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool,
});
export const clearSidebar = () => ({
    type: types.CLEAR_SIDEBAR,
});

export const setWsTelemetry = (data) => ({
    type: types.SET_WS_DATA,
    payload: data
});

export const clearWsTelemetry = () => ({
    type: types.CLEAR_WS_DATA,
});

// <coords actions
export const loadCoords = (props = {}) => ({
    type: types.LOAD_COORDS,
    payload: props,
});
export const loadedCoords = (data) => ({
    type: types.LOADED_COORDS,
    payload: data,
});
export const loadingCoords = (bool = false) => ({
    type: types.LOADING_COORDS,
    payload: bool,
});
export const clearCoords = () => ({
    type: types.CLEAR_COORDS,
});
// coords actions>

// get all
export const loadAllVehicles = () => ({
    type: types.LOAD_ALL_VEHICLES,
});
export const loadedAllVehicles = (data) => ({
    type: types.LOADED_ALL_VEHICLES,
    payload: data,
});
export const loadingAllVehicles = (bool = false) => ({
    type: types.LOADING_ALL_VEHICLES,
    payload: bool,
});
export const clearAllVehicles = () => ({
    type: types.CLEAR_ALL_VEHICLES,
});

export const loadTypes = () => ({
    type: types.LOAD_TYPES,
});
export const loadedTypes = (data) => ({
    type: types.LOADED_TYPES,
    payload: data,
});

// load history
export const loadHistoryVehicle = (vehicleId, params = {}) => ({
    type: types.LOAD_HISTORY_VEHICLE,
    payload: {
        vehicleId,
        params,
    }
});
export const loadedHistoryVehicle = (id, data) => ({
    type: types.LOADED_HISTORY_VEHICLE,
    payload: {
        id,
        data
    },
});
export const loadingHistoryVehicle = (bool = false) => ({
    type: types.LOADING_HISTORY_VEHICLE,
    payload: bool,
});
export const clearHistoryVehicle = () => ({
    type: types.CLEAR_HISTORY_VEHICLE,
});

export const setRoutesVehicle = (id, data) => ({
    type: types.SET_ROUTES_VEHICLE,
    payload: {
        id,
        data,
    },
});

export const clearRoutesVehicleById = (id) => ({
    type: types.CLEAR_ROUTES_VEHICLE_BY_ID,
    payload: id,
});
export const clearRoutes = () => ({
    type: types.CLEAR_ROUTES_VEHICLE,
});

export const setMarkers = (data, useFilter = true) => ({
    type: types.SET_MARKERS,
    payload: {
        data,
        useFilter,
    }
});
export const setWsVehicle = (events) => ({
    type: types.SET_VEHICLES_WS,
    payload: events
});
export const setSidebarParams = (params) => ({
    type: types.SET_SIDEBAR_PARAMS,
    payload: params
});

/* color speed page */
export const loadColorSpeed = () => ({
    type: types.LOAD_COLOR_SPEED,
});
export const loadedColorSpeed = (data) => ({
    type: types.LOADED_COLOR_SPEED,
    payload: data
});
export const loadColorSpeedList = (props) => ({
    type: types.LOAD_COLOR_SPEED_LIST,
    payload: props
});
export const loadedColorSpeedList = (data) => ({
    type: types.LOADED_COLOR_SPEED_LIST,
    payload: data
});
export const loadingColorSpeedList = (bool = false) => ({
    type: types.LOADING_COLOR_SPEED_LIST,
    payload: bool
});
export const setColorSpeedListProps = (props) => ({
    type: types.SET_COLOR_SPEED_LIST_PROPS,
    payload: props
});
export const resetColorSpeedListProps = (props) => ({
    type: types.RESET_COLOR_SPEED_LIST_PROPS,
});
export const addColorSpeed = (data, callback) => ({
    type: types.ADD_COLOR_SPEED,
    payload: data,
    callback,
});
export const editColorSpeed = (id, data, callback) => ({
    type: types.EDIT_COLOR_SPEED,
    payload: {
        id,
        data,
    },
    callback,
});
export const deleteColorSpeed = (id, callback) => ({
    type: types.DELETE_COLOR_SPEED,
    payload: id,
    callback,
});

export const loadVehicle = (data) => ({
    type: types.LOAD_VEHICLE,
    payload: data,
});

export const loadedVehicleActive = (data) => ({
    type: types.LOADED_VEHICLE_ACTIVE,
    payload: data,
});

export const loadCategoriesVehicle = (data) => ({
    type: types.LOAD_CATEGORIES_VEHICLE,
    payload: data,
});

export const loadedCategoriesVehicle = (data) => ({
    type: types.LOADED_CATEGORIES_VEHICLE,
    payload: data,
});

export const loadVehicleHistoryModal = (id, params) => ({
    type: types.LOAD_VEHICLE_HISTORY_MODAL,
    payload: { id, params }
});
export const clearVehicleHistoryModal = () => ({
    type: types.LOADED_VEHICLE_HISTORY_MODAL,
    payload: []
});
export const loadedVehicleHistoryModal = (data) => ({
    type: types.LOADED_VEHICLE_HISTORY_MODAL,
    payload: data
});
export const loadingVehicleHistoryModal = (bool = false) => ({
    type: types.LOADING_VEHICLE_HISTORY_MODAL,
    payload: bool
});


export const loadFindVehicles = (params = {}, callback) => ({
    type: types.LOAD_FIND_VEHICLES,
    payload: params,
    callback,
});
export const loadedFindVehicles = (data) => ({
    type: types.LOADED_FIND_VEHICLES,
    payload: data,
});
export const clearFindVehicles = () => ({
    type: types.LOADED_FIND_VEHICLES,
    payload: [],
});
export const loadingFindVehicles = (data) => ({
    type: types.LOADING_FIND_VEHICLES,
    payload: data,
});
export const setShowFindVehicles = (data) => ({
    type: types.SHOW_FIND_VEHICLES,
    payload: data,
});

export const loadBrandsList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_BRANDS,
    payload: {
        page,
        limit,
        query,
    },
});
export const loadedBrandsList = (data) => ({
    type: types.LOADED_BRANDS,
    payload: data,
});
export const loadingBrandsList = (bool = false) => ({
    type: types.LOADING_BRANDS,
    payload: bool,
});

export const createBrand = (data, callback) => ({
    type: types.CREATE_BRAND,
    payload: data,
    callback
});

export const editBrand = (id, data, callback) => ({
    type: types.EDIT_BRAND,
    payload: { id, data },
    callback
});

export const deleteBrand = (id, callback) => ({
    type: types.DELETE_BRAND,
    payload: id,
    callback
});

export const loadModelsList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_MODELS,
    payload: {
        page,
        limit,
        query,
    },
});

export const loadedModelsList = (data) => ({
    type: types.LOADED_MODELS,
    payload: data,
});
export const loadingModelsList = (bool = false) => ({
    type: types.LOADING_MODELS,
    payload: bool,
});

export const createModel = (data, callback) => ({
    type: types.CREATE_MODEL,
    payload: data,
    callback
});

export const editModel = (id, data, callback) => ({
    type: types.EDIT_MODEL,
    payload: { id, data },
    callback
});

export const deleteModel = (id, callback) => ({
    type: types.DELETE_MODEL,
    payload: id,
    callback
});

export const loadCompaniesList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_COMPANIES,
    payload: {
        page,
        limit,
        query,
    },
});

export const loadedCompaniesList = (data) => ({
    type: types.LOADED_COMPANIES,
    payload: data,
});
export const loadingCompaniesList = (bool = false) => ({
    type: types.LOADING_COMPANIES,
    payload: bool,
});

export const createCompany = (params, callback) => ({
    type: types.CREATE_COMPANY,
    payload: params,
    callback,
});

export const editCompany = (params, callback) => ({
    type: types.EDIT_COMPANY,
    payload: params,
    callback,
});

export const deleteCompany = (id) => ({
    type: types.DELETE_COMPANY,
    payload: id,
});

// транспортные средства
export const loadVehicleList = (page = 1, limit = 25, data) => ({
    type: types.LOAD_VEHICLES_LIST,
    payload: {
        page,
        limit,
        data,
    },
});

export const loadedVehicleList = (data) => ({
    type: types.LOADED_VEHICLES_LIST,
    payload: data
});

export const loadingVehicleList = (bool = false) => ({
    type: types.LOADING_VEHICLES_LIST,
    payload: bool
});

export const createdNewVehicle = (data, callback) => ({
    type: types.CREATE_VEHICLE,
    payload: data,
    callback
});

export const editVehicle = (id, data, callback) => ({
    type: types.EDIT_VEHICLE,
    payload: { id, data },
    callback
});

export const deletedVehicle = (id, callback) => ({
    type: types.DELETE_VEHICLE,
    payload: id,
    callback
});

// отчет по транспортным средствам
export const loadRWVehicleReport = (params) => ({
    type: types.LOAD_VEHICLE_REPORT,
    payload: params,
});

export const loadedRWVehicleReport = (data) => ({
    type: types.LOADED_VEHICLE_REPORT,
    payload: data,
});

export const loadingRWVehicleReport = (bool) => ({
    type: types.LOADING_VEHICLE_REPORT,
    payload: bool,
});

export const setRWVehicleReportFilter = (data) => ({
    type: types.SET_VEHICLE_REPORT_FILTER,
    payload: data,
});

// категории ТС
export const loadVehicleCategories = () => ({
    type: types.LOAD_VEHICLES_CATEGORIES
});

export const loadedVehicleCategories = (data) => ({
    type: types.LOADED_VEHICLES_CATEGORIES,
    payload: data
});

export const loadVehicleCategoryList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_VEHICLE_CATEGORY_LIST,
    payload: {
        page,
        limit,
        query,
    },
});

export const loadedVehicleCategoryList = (data) => ({
    type: types.LOADED_VEHICLE_CATEGORY_LIST,
    payload: data
});

export const loadingVehicleCategoryList = (bool = false) => ({
    type: types.LOADING_VEHICLE_CATEGORY_LIST,
    payload: bool
});

export const createVehicleCategory = (data, callback = () => {}) => ({
    type: types.CREATE_VEHICLE_CATEGORY,
    payload: data,
    callback
});

export const editVehicleCategory = (id, data, callback = () => {}) => ({
    type: types.EDIT_VEHICLE_CATEGORY,
    payload: { id, data },
    callback
});

export const deleteVehicleCategory = (id, callback = () => {}) => ({
    type: types.DELETE_VEHICLE_CATEGORY,
    payload: id,
    callback
});

// типы ТС
export const loadVehiclesTypes = () => ({
    type: types.LOAD_VEHICLES_TYPES
});

export const loadedVehiclesTypes = (data) => ({
    type: types.LOADED_VEHICLES_TYPES,
    payload: data
});


// employees
export const loadEmployees = (page = 1, limit = 25, data) => ({
    type: types.LOAD_EMPLOYEES,
    payload: { page, limit, data }
});

export const loadedEmployees = (data) => ({
    type: types.LOADED_EMPLOYEES,
    payload: data
});

export const loadingEmployees = (bool = false) => ({
    type: types.LOADING_EMPLOYEES,
    payload: bool
});

export const editEmployees = (id, data, callback) => ({
    type: types.EDIT_EMPLOYEES,
    payload: { id, data },
    callback,
});

export const addEmployees = (data, callback) => ({
    type: types.ADD_EMPLOYEES,
    payload: data,
    callback,
});

export const deleteEmployees = (id, callback) => ({
    type: types.DELETE_EMPLOYEES,
    payload: id,
    callback,
});

// positions
export const loadPositions = (page = 1, limit = 25, data) => ({
    type: types.LOAD_POSITIONS,
    payload: { page, limit, data }
});

export const loadedPositions = (data) => ({
    type: types.LOADED_POSITIONS,
    payload: data
});

export const loadingPositions = (bool = false) => ({
    type: types.LOADING_POSITIONS,
    payload: bool
});

export const editPositions = (id, data, callback) => ({
    type: types.EDIT_POSITIONS,
    payload: { id, data },
    callback,
});

export const addPositions = (data, callback) => ({
    type: types.ADD_POSITIONS,
    payload: data,
    callback,
});

export const deletePositions = (id, callback) => ({
    type: types.DELETE_POSITIONS,
    payload: id,
    callback,
});

// brigades
export const loadBrigades = (page = 1, limit = 25, data) => ({
    type: types.LOAD_BRIGADES,
    payload: { page, limit, data }
});

export const loadedBrigades = (data) => ({
    type: types.LOADED_BRIGADES,
    payload: data
});

export const loadingBrigades = (bool = false) => ({
    type: types.LOADING_BRIGADES,
    payload: bool
});

export const editBrigades = (id, data, callback) => ({
    type: types.EDIT_BRIGADES,
    payload: { id, data },
    callback,
});

export const addBrigades = (data, callback) => ({
    type: types.ADD_BRIGADES,
    payload: data,
    callback,
});

export const deleteBrigades = (id, callback) => ({
    type: types.DELETE_BRIGADES,
    payload: id,
    callback,
});

export const loadParks = (page = 1, limit = 25, query) => ({
    type: types.LOAD_PARKS,
    payload: { page, limit, query }
});

export const loadingParks = (bool = false) => ({
    type: types.LOADING_PARKS,
    payload: bool
});

export const loadedParks = (data) => ({
    type: types.LOADED_PARKS,
    payload: data
});

export const createPark = (data, callback) => ({
    type: types.CREATE_PARK,
    payload: data,
    callback
});

export const editPark = (id, data, callback) => ({
    type: types.EDIT_PARK,
    payload: { id, data },
    callback
});

export const deletePark = (id, callback) => ({
    type: types.DELETE_PARK,
    payload: id,
    callback
});

//Показ данных по простою
export const loadVehicleDowntime = (id, query, callback ) => ({
    type: types.LOAD_VEHICLE_DOWNTIME,
    payload: { id, query },
    callback
});

export const loadedVehicleDowntime = (data) => ({
    type: types.LOADED_VEHICLE_DOWNTIME,
    payload: data
});

export const getReportFuel = (data, callback) => ({
    type: types.GET_REPORT_FUEL,
    payload: data,
    callback
});

export const loadingReportFuel = (bool = false) => ({
    type: types.LOADING_REPORT_FUEL,
    payload: bool
});

export const loadedReportFuel = (data) => ({
    type: types.LOADED_REPORT_FUEL,
    payload: data
});


export const loadRoutes = (page = 1, limit = 25, data) => ({
    type: types.LOAD_ROUTES,
    payload: { page, limit, data }
});

export const loadingRoutes = (bool = false) => ({
    type: types.LOADING_ROUTES,
    payload: bool
});

export const loadedRoutes = (data) => ({
    type: types.LOADED_ROUTES,
    payload: data
});

export const saveRoute = (data, callback = () => {}) => ({
    type: types.SAVE_ROUTE,
    payload: data,
    callback
});

export const deleteRoute = (id, callback = () => {}) => ({
    type: types.DELETE_ROUTE,
    payload: id,
    callback
});

export const changeRoute = (uuid, data, callback = () => {}) => ({
    type: types.CHANGE_ROUTE,
    payload: { uuid, data },
    callback
});

export const loadRoutesStatuses = () => ({
    type: types.LOAD_ROUTE_STATUSES
});

export const loadedRoutesStatuses = (data) => ({
    type: types.LOADED_ROUTE_STATUSES,
    payload: data
});

export const loadRouteTypes = () => ({
    type: types.LOAD_ROUTE_TYPES,
});

export const loadedRouteTypes = (data) => ({
    type: types.LOADED_ROUTE_TYPES,
    payload: data,
});

// checkpoints of route
export const loadRouteCheckpoints = (id) =>  ({
    type: types.LOAD_ROUTE_CHECKPOINTS,
    payload: id,
});

export const loadingRouteCheckpoints = (bool = false) =>  ({
    type: types.LOADING_ROUTE_CHECKPOINTS,
    payload: bool,
});

export const loadedRouteCheckpoints = (id, data) => ({
    type: types.LOADED_ROUTE_CHECKPOINTS,
    payload: { id, data },
});

export const saveCheckPointsToRoute = (id, checkpoints, callback) => ({
    type: types.SAVE_CHECKPOINTS_TO_ROUTE,
    payload: { id , checkpoints },
    callback
});

export const loadTelemetryReceivers = (data) => ({
    type: types.LOAD_TELEMETRY_RECEIVERS,
    payload: data
});

export const loadedTelemetryReceivers = (data) => ({
    type: types.LOADED_TELEMETRY_RECEIVERS,
    payload: data
});

export const loadingTelemetryReceivers = (bool) => ({
    type: types.LOADING_TELEMETRY_RECEIVERS,
    payload: bool
});

export const addTelemetryReceiver = (data, callback) => ({
    type: types.ADD_TELEMETRY_RECEIVER,
    payload: data,
    callback,
});

export const editTelemetryReceiver = (id, data, callback) => ({
    type: types.EDIT_TELEMETRY_RECEIVER,
    payload: {
        id,
        data,
    },
    callback,
});

export const deleteTelemetryReceiver = (id, callback) => ({
    type: types.DELETE_TELEMETRY_RECEIVER,
    payload: id,
    callback,
});

export const loadTelemetryReceiverTypes = () => ({
    type: types.LOAD_TELEMETRY_RECEIVER_TYPES,
});

export const loadedTelemetryReceiverTypes = (data) => ({
    type: types.LOADED_TELEMETRY_RECEIVER_TYPES,
    payload: data
});

// workorder
export const loadWorkorders = (page = 1, limit = 25, data) => ({
    type: types.LOAD_WORKORDERS,
    payload: { page, limit, data }
});

export const loadedWorkorders = (data) => ({
    type: types.LOADED_WORKORDERS,
    payload: data
});

export const loadingWorkorders = (bool = false) => ({
    type: types.LOADING_WORKORDERS,
    payload: bool
});

export const loadCurrentWorkorder = (page = 1, limit = 25, data, callback) => ({
    type: types.LOAD_CURRENT_WORKORDER,
    payload: { page, limit, data },
    callback,
});

export const loadingCurrentWorkorder = (bool = false) => ({
    type: types.LOADING_CURRENT_WORKORDER,
    payload: bool
});

export const createWorkorder = (data, callback = () => {}) => ({
    type: types.CREATE_WORKORDER,
    payload: data,
    callback
});

export const deleteWorkorder = (id, callback = () => {}) => ({
    type: types.DELETE_WORKORDER,
    payload: id,
    callback
});

export const editWorkorder = (id, data, callback = () => {}) => ({
    type: types.EDIT_WORKORDER,
    payload: { id, data },
    callback
});

export const clearWorkorders = () => ({
    type: types.LOADED_WORKORDERS
});

export const setSelectedWorkorderData = (data) => ({
    type: types.SET_SELECTED_WORKORDER_DATA,
    payload: data
});


export const loadWorkordersByDate = (page = 1, limit = 25, data) => ({
    type: types.LOAD_WORKORDERS_BY_DATE,
    payload: { page, limit, data }
});

export const loadedWorkordersByDate = (data) => ({
    type: types.LOADED_WORKORDERS_BY_DATE,
    payload: data
});

export const loadingWorkordersByDate = (bool = false) => ({
    type: types.LOADING_WORKORDERS_BY_DATE,
    payload: bool
});

export const loadWorkorderStatuses = () => ({
    type: types.LOAD_WORKORDER_STATUSES,
});

export const loadedWorkorderStatuses = (data) => ({
    type: types.LOADED_WORKORDER_STATUSES,
    payload: data,
});

export const clearWorkordersByDate = () => ({
    type: types.CLEAR_WORKORDERS_BY_DATE,
});

export const loadWorkorderLogs = (id) => ({
    type: types.LOAD_WORKORDER_LOGS,
    payload: id
});

export const loadingWorkorderLogs = (bool) => ({
    type: types.LOADING_WORKORDER_LOGS,
    payload: bool
});

export const loadedWorkorderLogs = (data) => ({
    type: types.LOADED_WORKORDER_LOGS,
    payload: data
});

export const loadWorkorderLogManualStatus = () => ({
    type: types.LOAD_WORKORDER_LOG_MANUAL_STATUS
});

export const loadedWorkorderLogManualStatus = (data) => ({
    type: types.LOADED_WORKORDER_LOG_MANUAL_STATUS,
    payload: data
});

export const addWorkorderLogManualStatus = (id, data, callback) => ({
    type: types.ADD_WORKORDER_LOG_MANUAL_STATUS,
    payload: { id, data },
    callback
});

export const editWorkorderLogManualStatus = (id, data, callback) => ({
    type: types.EDIT_WORKORDER_LOG_MANUAL_STATUS,
    payload: { id, data },
    callback
});

export const deleteWorkorderLogManualStatus = (id, callback) => ({
    type: types.DELETE_WORKORDER_LOG_MANUAL_STATUS,
    payload: id,
    callback
});

export const loadCheckPoints = (page = 1, limit = 25, data) => ({
    type: types.LOAD_CHECK_POINTS,
    payload: { page, limit, ...data }
});

export const loadedCheckPoints = (data) => ({
    type: types.LOADED_CHECK_POINTS,
    payload: data
});

export const loadingCheckPoints = (bool) => ({
    type: types.LOADING_CHECK_POINTS,
    payload: bool
});

export const createCheckPoint = (data, callback) => ({
    type: types.CREATE_CHECK_POINT,
    payload: data,
    callback,
});

export const editCheckPoint = (id, data, callback) => ({
    type: types.EDIT_CHECK_POINT,
    payload: { id, data },
    callback,
});

export const deleteCheckPoint = (id, callback) => ({
    type: types.DELETE_CHECK_POINT,
    payload: id,
    callback,
});

export const loadReportByVehicles = (params) => ({
    type: types.LOAD_REPORT_BY_VEHICLES,
    payload: params,
});
export const loadedReportByVehicles = (data) => ({
    type: types.LOADED_REPORT_BY_VEHICLES,
    payload: data,
});
export const clearReportByVehicles = () => ({
    type: types.CLEAR_REPORT_BY_VEHICLES,
});
export const loadingReportByVehicles = (bool = false) => ({
    type: types.LOADING_REPORT_BY_VEHICLES,
    payload: bool,
});

export const setHistoryOption = (title = '', option = [], setBounds = true, zoom, maxZoom) => ({
    type: types.SET_HISTORY_OPTION,
    payload: option.length > 0
        ? {
            title,
            option,
            setBounds,
            zoom,
            maxZoom
        }
        : {},
});

export const loadCheckPointPolygon = (params = {}) => ({
    type: types.LOAD_CHECK_POINT_POLYGON,
    payload: params
});

export const loadedCheckPointPolygon = (data = {}) => ({
    type: types.LOADED_CHECK_POINT_POLYGON,
    payload: data,
});

export const changeRouteStatus = (routeId, statusId, callback) => ({
    type: types.CHANGE_ROUTE_STATUS,
    payload: {
        routeId,
        statusId,
    },
    callback,
});

export const loadingSetRouteStatus = (bool = false) => ({
    type: types.LOADING_CHANGE_ROUTE_STATUS,
    payload: bool,
});

// vehicle showcase
export const loadVehicleStatistics = () => ({
    type: types.LOAD_VEHICLE_STATISTICS,
});

export const loadingVehicleStatistics = (bool = false) => ({
    type: types.LOADING_VEHICLE_STATISTICS,
    payload: bool
});

export const loadedVehicleStatistics = (data) => ({
    type: types.LOADED_VEHICLE_STATISTICS,
    payload: data
});

export const clearVehicleStatistics = () => ({
    type: types.LOADED_VEHICLE_STATISTICS,
    payload: {
        graph: []
    }
});

// workorder showcase
export const loadWorkorderStatistics = () => ({
    type: types.LOAD_WORKORDER_STATISTICS,
});

export const loadingWorkorderStatistics = (bool = false) => ({
    type: types.LOADING_WORKORDER_STATISTICS,
    payload: bool
});

export const loadedWorkorderStatistics = (data) => ({
    type: types.LOADED_WORKORDER_STATISTICS,
    payload: data
});

export const clearWorkorderStatistics = () => ({
    type: types.LOADED_WORKORDER_STATISTICS,
    payload: {
        graph: []
    }
});

// egts transmitters
export const loadEgtsTransmitters = (filter, fnLoading, fnCallback) => ({
    type: types.LOAD_EGTS_TRANSMITTER,
    payload: filter,
    fnLoading,
    fnCallback,
});

// crud
export const addEgtsTransmitter = (data, fnLoading, fnCallback) => ({
    type: types.ADD_EGTS_TRANSMITTER,
    payload: data,
    fnLoading,
    fnCallback,
});

export const editEgtsTransmitter = (id, data, fnLoading, fnCallback) => ({
    type: types.EDIT_EGTS_TRANSMITTER,
    payload: {
        id,
        data,
    },
    fnLoading,
    fnCallback,
});

export const deleteEgtsTransmitter = (id, fnLoading, fnCallback) => ({
    type: types.DELETE_EGTS_TRANSMITTER,
    payload: id,
    fnLoading,
    fnCallback,
});
