import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';

import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import { setReportCategoryFilter } from 'redux/Incidents/actions';
import titles from 'helpers/constants/titles';
import { getEndOf, getStartOf } from 'helpers/date.config';

const Filters = ({ reportCategory }) => {
    const dispatch =  useDispatch();

    const initialState = {
        start_date: getStartOf('year'),
        end_date: getEndOf('day'),
        category_list: [],
    };

    const [filterValues, setFilterValues] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);
    const isDisabledHandler = (value) => setIsDisabled(value);

    useEffect(() => {
        saveFilter();
    }, []);

    const handleDate = (value, keyName) => {
        setFilterValues({
            ...filterValues,
            [keyName]: value
        });
    };

    const resetFilters = (needRefresh) => {
        if (needRefresh) {
            dispatch(setReportCategoryFilter(initialState));
        }
        setFilterValues(initialState);
    };

    const saveFilter = (filters = filterValues) => {
        dispatch(setReportCategoryFilter({ ...filters }));
    };

    const typesList = Object.keys(reportCategory)
        .map((key) => ({
            id: key,
            name: reportCategory[key].name
        }));

    const updateFilter = (filters) => {
        setFilterValues({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            saveFilter({
                ...initialState,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilters} 
            onSearch={saveFilter} 
            filter={filterValues} 
            setUserFilter={updateFilter}
            disabled={!filterValues.start_date || !filterValues.end_date || isDisabled}
            filterException={['start_date', 'end_date',]}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filterValues.start_date}
                    valueEndDate={filterValues.end_date}
                    handleDate={(value) => handleDate(value, 'start_date')}
                    handleEndDate={(value) => handleDate(value, 'end_date')}
                    isDisabledHandler={isDisabledHandler}
                    // errorMessage={() => isErrorDate ? 'Выбранный период более 31 дня' : ''}
                    // renderError={() => isErrorDate}
                />

                <Autocomplete
                    limitTags={1}
                    noOptionsText={titles.NO_RESULTS}
                    multiple
                    value={filterValues.category_list || []}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={typesList}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={ (e, value) => handleDate(value, 'category_list')}
                    size="small"
                    openText="Открыть"
                    closeText="Закрыть"
                    clearText="Очистить"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Категория"
                        />)}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;