import React from 'react';
import { Collapse } from '@mui/material';
import messages from '../../../../helpers/constants/messages';
import { fullDateTime } from '../../../../helpers/date.config';

const ItemInfo = ({ isOpen, item }) => (
    <Collapse in={isOpen} timeout="auto" unmountOnExit className="info">
        <div className="block">
            <div className="row">
                <b>Удостоверение:</b> {item?.identification}
            </div>
            <div className="row">
                <b>Телефон:</b> {item?.phone_number}
            </div>
            <div className="row">
                <b>Метка:</b> {item?.label}
            </div>
            <div className="row">
                <b>Должность:</b> {item?.position?.name}
            </div>
            <div className="row">
                <b>Организация:</b> {item?.organization?.title}
            </div>
            <div className="row">
                <b>Дата проведения предрейсового медицинского осмотра сотрудника: </b>
                {item?.inspection_before
                    ? fullDateTime(item?.inspection_before)
                    : messages.INFO_IS_NOT_FOUND
                }
            </div>
            <div className="row">
                <b>Дата проведения послерейсового медицинского осмотра сотрудника: </b>
                {item?.inspection_after
                    ? fullDateTime(item?.inspection_after)
                    : messages.INFO_IS_NOT_FOUND
                }
            </div>
        </div>
    </Collapse>
);

export default ItemInfo;