import React, { useState } from 'react';
import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { TextField } from '@mui/material';
import formatDate from '../../../../../helpers/constants/dateFormat';
import SingleKeyboardDateTimePicker from '../../../../common/Dates/SingleKeyboardDateTimePicker';
import { useDispatch } from 'react-redux';
import { editIncidentOperations, loadIncidentOperations } from '../../../../../redux/SituationalPlans/actions';
import { useValidation } from '../../../../../helpers/hooks';
import { fullDateTimeWithTimeZone } from '../../../../../helpers/date.config';


function OperationStatusModal({ onClose, data }) {
    const dispatch = useDispatch();
    const validation = useValidation();
    const [formValues, setFormValues] = useState({
        position: data?.position,
        operation_id: data?.operation_id,
        transport_incident_id: data?.transport_incident_id,
        is_done: data?.is_done,
        end_date: data?.end_date || null,
        start_date: data?.start_date || null,
        comment: data?.comment ||  ''
    });

    const handleChange = (value, name) => {
        setFormValues({ ...formValues, [name]: value });
        if (name === 'date') validation.deleteKey('date');
    };

    const reloadData = () => {
        dispatch(loadIncidentOperations(data?.transport_incident_id));
        onClose();
    };

    const onAcceptModal = () => {
        const result = {
            ...formValues,
            start_date: formValues?.start_date ? fullDateTimeWithTimeZone(formValues?.start_date) : null,
            end_date: formValues.end_date ? fullDateTimeWithTimeZone(formValues?.end_date) : null,
            is_done: Boolean(formValues.end_date)
        };

        // const filteredOperations = incidentOperations[data?.transport_incident_id].filter(
        //     ({ operation_id }) => operation_id !== data?.operation_id
        // );
        // const sortedOperations = [...filteredOperations, result].sort((a, b) => a.position - b.position);
        // отправляем дну измененную операцию
        dispatch(editIncidentOperations(
            data?.transport_incident_id,
            [result],
            reloadData
        ));
    };

    const isDisabled = !formValues.start_date;

    return (
        <Modal
            isOpen
            onClose={onClose}
            noPadding={true}
            title="Выполнение этапа"
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">

                <div className="block">
                    <SingleKeyboardDateTimePicker
                        label="Плановая дата/время окончания этапа"
                        value={formValues.start_date}
                        pickerFormat={formatDate.DT_EXCEPT_SECONDS}
                        onChange={(value) => handleChange(value, 'start_date')}
                        error={validation.isKey(`${data?.position}.start_date`)}
                        helperText={validation.get(`${data?.position}.start_date`)}
                    />
                </div>

                <div className="block">
                    <SingleKeyboardDateTimePicker
                        label="Фактическая дата/время окончания этапа"
                        isRequired={formValues.is_done}
                        value={formValues.end_date}
                        pickerFormat={formatDate.DT_EXCEPT_SECONDS}
                        onChange={(value) => handleChange(value, 'end_date')}
                        error={formValues.is_done && (!formValues.end_date || validation.isKey(`${data?.position}.end_date`))}
                        helperText={validation.get(`${data?.position}.end_date`)}
                    />
                </div>
                <div className="block">
                    <TextField
                        onChange={({ target: { name, value } }) => handleChange(value, name)}
                        label="Комментарий"
                        name="comment"
                        multiline
                        rows={3}
                        value={formValues.comment}
                        variant="outlined"
                        error={validation.isKey(`${data?.position}.comment`)}
                        helperText={validation.get(`${data?.position}.comment`)}
                    />
                </div>
            </form>
        </Modal>
    );
}

export default OperationStatusModal;