import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dorisControlSelectors } from 'redux/DorisControl';
import { loadComplexTypes, loadDuploCodes, loadViolationEvents } from 'redux/DorisControl/actions';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import PageLayout from 'components/layout/PageLayout';

import Filter from './Filter';
import OTKTable from './OTKTable';

const OTK = () => {
    const dispatch = useDispatch();

    const data = useSelector(dorisControlSelectors.violationEventsData);
    const meta = useSelector(dorisControlSelectors.violationEventsMeta);
    const loading = useSelector(dorisControlSelectors.loadingViolationEvents);
    // const violationList = useSelector(dorisControlSelectors.pddViolationsData);
    const duploCodes = useSelector(dorisControlSelectors.duploCodesData);
    const typesArray = useStoreProp(loadComplexTypes,'dorisControl','doris_complex_types');

    const filteredDuploCodes = useMemo(() => duploCodes.filter(item => item.id !== 0 && item.id !== 9000), [duploCodes]);
    const complexTypes = useMemo(() => typesArray.reduce((res, el) => ({ ...res, [el.id]: el.name }),{}), [typesArray]);

    const storageLimit = Number(localStorage.getItem('limit') || 25);
    const [limit, setLimit] = useState(storageLimit);
    const [params, setParams] = useState({ page: 1, data: {} });

    useEffect(() => {
        dispatch(loadViolationEvents(params.page, limit, params.data ));
    }, [dispatch, limit, params.page, params.data]);

    useEffect(() => {
        // dispatch(loadPddViolations(null, null, { is_active: 1 }));
        dispatch(loadDuploCodes(null, null, { is_otk: 1 }));
    }, [dispatch]);

    const renderContent = () => {
        return (
            <>
                {data.length > 0 && duploCodes.length > 0
                    ? <OTKTable
                        data={data}
                        duploCodes={filteredDuploCodes}
                        complexTypes={complexTypes}
                    />
                    : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
                }
            </>
        );
    };

    return (
        <PageLayout 
            header="ОТК"
            filters={ <Filter setParams={(param) => setParams({ data: param, page: 1, })}/>}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: meta,
                limit,
                setLimit
            }}
            loading={loading}
            customStyles={{ overflowY: 'hidden', padding: 0 }}
        />
    );
};

export default OTK;
