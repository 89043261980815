import { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { theme } from 'App';

import { clearActiveBoardMessage, fetchActiveBoardMessage } from 'redux/Boards/actions';
import { setSelectedLayer } from 'redux/Menu/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import Context from 'helpers/context';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';
import InfoTabs from 'components/pages/Dictionaries/Boards/BoardAndSigns/InfoTabs';
import ActiveMessage from 'components/pages/Dictionaries/Boards/BoardAndSigns/BasicData/ActiveMessage';
import { signsList } from 'components/pages/Dictionaries/Boards/MessageHelpers/signsList';


const Information = (props) => {
    const { data, history, messages: messageRotation, loadingMessages, permissions, readOnly } = props;
    const dispatch = useDispatch();

    // const messageRotation = boardRotationMessages[data.id] || [];
    const [isShowActiveMessage, setIsShowActiveMessage] = useState(false);

    const [openBoardTab, setOpenBoardTab] = useState(false);

    const closeActiveBoardMessageModalForm = () => {
        setIsShowActiveMessage(false);
        dispatch(clearActiveBoardMessage());
    };

    const count = 20;

    const fields = {
        name: {
            name: titles.NAME,
        },
        category_text: {
            name: 'Категория',
        },
        type_text: {
            name: titles.TYPE,
        },
        serial_number: {
            name: titles.SERIAL_NUMBER,
        },
        status_text:{
            name: titles.STATUS,
        },
        address_text:{
            name: titles.ADDRESS,
        },
    };

    const RenderList = () => {
        if (!data) {
            return <div className="not-found">{messages.INFO_IS_NOT_FOUND}</div>;
        }

        return (
            <div>
                <div className="information">
                    {Object.keys(fields).map((field) => {
                        const f = fields[field].f ? fields[field].f(data[field]) : null;
                        const value = f || data[field] || null;
                        const name = fields[field].name || '';

                        return (
                            <div className="info__item" key={`information_${field}`}>
                                <div className="info__item-title">
                                    {name}
                                    {': '}
                                </div>
                                <div className="info__item-content">
                                    {value || messages.INFO_IS_NOT_FOUND}
                                </div>
                            </div>
                        );
                    })}

                    {/* сообщения */}
                    {messageRotation?.length > 0 && loadingMessages === false ? (
                        <div className="info__item" style={{ alignItems: 'start' }}>
                            <div className="info__item-title" style={{ padding: '1.5rem 0' }}>
                                {titles.MESSAGES}
                                {': '}
                            </div>
                            <div className="info__item-content">
                                <List>
                                    {messageRotation?.slice(0, limit)?.map(({ id, text }, index) => {
                                        return (
                                            <ListItem key={id} disableGutters>
                                                <ListItemIcon style={{ minWidth: '30px' }}>
                                                    {index + 1}
                                                </ListItemIcon>
                                                <ListItemText>
                                                    { text?.length > 0
                                                        ? text?.map((txt, index) => {
                                                            return (
                                                                <span key={index}>
                                                                    {txt.sign
                                                                    && <img
                                                                        style={{ width:'30px', height:'30px', verticalAlign: 'middle' }}
                                                                        src={signsList[txt?.sign]?.img}
                                                                        alt={txt?.sign}
                                                                    />
                                                                    }
                                                                    {txt?.text}{' '}
                                                                </span>
                                                            );
                                                        })
                                                        : <span>{messages.NO_DATA}</span>
                                                    }
                                                </ListItemText>
                                            </ListItem>
                                        );
                                    })}
                                </List>

                                {limit < messageRotation?.length && <Loading />}

                            </div>
                        </div>
                    ) : null}

                    {loadingMessages && <Loading className="absolute bottom fill"/>}
                </div>

            </div>
        );
    };

    //
    const [limit, setLimit] = useState(count);
    const displayLoad = debounce(() => setLimit(limit + count), 100);

    const scrollHandleUpdate = (values) => {
        if (values.top > 0.95 && limit < messageRotation?.length) {
            displayLoad();
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} locale={'ru'}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Context.Provider value={{ permissions }}>
                        <div>
                            <Scrollbars style={{ height: '200px' }} onUpdate={scrollHandleUpdate}>
                                <RenderList />
                            </Scrollbars>

                            {!readOnly && (
                                <div
                                    className="filter__wrap__btn filter__btn__column"
                                    style={{
                                        paddingLeft: '1rem',
                                        paddingRight: '1rem',
                                    }}
                                >
                                    <FormButtons
                                        buttons={[
                                            {
                                                ...buttonsTypes.defaultPrimary,
                                                size: 'small',
                                                name: 'Основные данные',
                                                onClick: () => {
                                                    dispatch(setSelectedLayer());
                                                    // history && history.push(`/dictionaries/board/${data?.id}`);
                                                    setOpenBoardTab('data');
                                                }
                                            },
                                            {
                                                ...buttonsTypes.defaultPrimary,
                                                size: 'small',
                                                name: 'Список сообщений',
                                                onClick: () => {
                                                    setOpenBoardTab('messages');
                                                }
                                            },
                                            {
                                                ...buttonsTypes.defaultSecondary,
                                                size: 'small',
                                                name: 'Активное сообщение',
                                                onClick: () => {
                                                    setIsShowActiveMessage(true);
                                                    dispatch(fetchActiveBoardMessage(data?.id));
                                                }
                                            }
                                        ]}
                                    />
                                </div>
                            )}

                            {!!openBoardTab && (
                                <Modal
                                    isOpen={!!openBoardTab}
                                    onClose={() => setOpenBoardTab(null)}
                                    noPadding={true}
                                    title={data?.name}
                                    fullWidth
                                    showCloseInTitle
                                    buttons={<FormButtons
                                        buttons={[
                                            {
                                                ...buttonsTypes.close,
                                                onClick: () => setOpenBoardTab(null)
                                            },
                                        ]}
                                    />}
                                >
                                    <InfoTabs
                                        selectedItem={data}
                                        tab={openBoardTab}
                                    />
                                </Modal>
                            )}

                            {isShowActiveMessage && (
                                <ActiveMessage
                                    title={data?.name}
                                    onClose={closeActiveBoardMessageModalForm}
                                    isOpen={isShowActiveMessage}
                                />
                            )}
                        </div>
                    </Context.Provider>
                </ThemeProvider>
            </StyledEngineProvider>
        </LocalizationProvider>
    );
};

export default Information;
