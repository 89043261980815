import { useEffect, } from 'react';
import { useDispatch, } from 'react-redux';

import {
    loadAccessTemplate,
} from 'redux/Auth/actions';
import { convertDateToTimestamp } from 'helpers/date.config';
import { config } from 'config';

import type { Access } from 'types';

type Time = {[key: string]: number};

const TTL = 10 * 1000; // time to live, 1 час в милисек.
let refreshTime: Time = {};

let project_code: string | null = null;

type AccessTemplateCache = Record<string, Access>;
const defaultAccesss: Access = {};
let accessTemplates: AccessTemplateCache = {};

// хук - получение прав доступа для конкретной страницы
export const useAccessTemplate = (uri?: string) => {
    const dispatch = useDispatch();

    const project: {[K: string]: any} = config.project ?? {};
    const code = project.code;

    useEffect(() => {
        const currentDate = convertDateToTimestamp();

        if (uri && (!refreshTime[uri] || refreshTime[uri] < currentDate)) {
            refreshTime[uri] = TTL + currentDate;
            dispatch(loadAccessTemplate(uri, (data: Access) => {
                accessTemplates[uri] = data;
            })); // TODO подумать как обрабатывать ошибку и сбрасывать таймер
        }
    }, [uri, dispatch]);

    useEffect(() => {
        if (code !== project_code) {
            project_code = code;
            refreshTime = {};
            accessTemplates = {};
        }
    }, [code, dispatch]);

    return uri ? accessTemplates[uri] ?? defaultAccesss : defaultAccesss;
};
