import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormHelperText,
    Grid,
} from '@mui/material';

import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import {
    changeRoute,
    saveRoute,
    loadRoutesStatuses,
    loadRouteTypes,
    loadRouteCheckpoints,
} from 'redux/TransportRoad/actions';
import { transportRoadSelectors } from 'redux/TransportRoad';
import TransportTypesSelect from 'components/common/Transport/TransportTypesSelect';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import SelectCompany from 'components/common/Autocomplete/Companies';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import StationRoute from 'components/common/Transport/StationRoute';
import { options } from 'components/common/Transport/StationRoute/helper';

import styles from './routes.module.scss';
import RouteCheckPoints from './RouteCheckPoints';
import MapForm from './PassportPage/BasicData/MapForm';

const AddEditCopyModal = ({
    data = {},
    isOpen,
    onClose,
    isNew,
    isCopy,
    reloadList,
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const statuses = useStoreProp(loadRoutesStatuses, 'transportRoad', 'routeStatuses');

    const { id, ...newData } = data;
    const changedData = isNew ? newData : data;

    const routeCheckpoints = useSelector(transportRoadSelectors.routeCheckpoints);
    const routeCheckpointsLoading = useSelector(transportRoadSelectors.routeCheckpointsLoading);

    const [routePoints, setRouteCheckPoints] = useState([]);

    const calculateDuration = () => {
        return routePoints?.reduce((acc, item) => {
            if (typeof item?.interval === 'string') {
                const getHourMin = item?.interval?.split(':');
                const [hour, min] = getHourMin;
                if (item.interval) {
                    return (+hour * 60) + +min + +acc;
                }
                return acc;
            }
            return acc;

        }, 0);
    };

    const calculateLength = () => {
        return  routePoints?.reduce((acc, item) => {
            return Number(+acc + +(item.mileage || 0)).toFixed(2);
        }, 0);
    };

    const [route, setRoute] = useState({
        name: '',
        num: '',
        category_id: '',
        road: {},
        district: '',
        ...changedData,
        duration: data.duration || calculateDuration(),
        length: data.length || calculateLength(),
        status: isNew ? 1 : data?.status,
        enterprises: data?.enterprises || [],
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        validation.deleteKey(name);
        setRoute({
            ...route,
            [name]: value
        });
    };

    const handleSuccess = () => {
        onClose(false);
        reloadList();
    };

    const createNewRoute = () => {
        const routeCurrent = {
            ...route,
            check_points: routePoints
        };
        if (!routeCurrent.category_id) delete routeCurrent.category_id;

        dispatch(saveRoute(routeCurrent, handleSuccess));
        validation.clear();
    };

    const saveChangedRoute = () => {
        const routeCurrent = {
            ...route,
            duration: calculateDuration(),
            length: calculateLength(),
            check_points: routePoints
        };
        if (!routeCurrent.category_id) delete routeCurrent.category_id;

        dispatch(changeRoute(routeCurrent?.id, routeCurrent, handleSuccess));
    };

    const isDisabled = (
        !route.name
        || !Object.keys(route?.road)?.length
        // || !route.category_id
    );
    useEffect(() => {
        const selectedRouteCheckPoints = routeCheckpoints[id] || [];
        setRouteCheckPoints(selectedRouteCheckPoints);
    }, [routeCheckpoints, id]);

    // загружаем контрольные точки и остановки маршрута
    useEffect(()=> {
        if (id) {
            dispatch(loadRouteCheckpoints(id));
        }
    }, [id, dispatch]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose(false)}
            noPadding
            fullWidth
            title={isNew ? (isCopy ? 'Скопировать маршрут' : titles.ADD_ROUTE) : titles.EDIT_ROUTE}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: () => onClose(false)
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isDisabled,
                        onClick: isNew ? createNewRoute : saveChangedRoute
                    },
                ]}
            />}
        >
            {routeCheckpointsLoading && <Loading circular />}
            <form className="modal__form">

                <Grid container direction="row" spacing={2} justify="center" alignItems="flex-start">
                    <Grid item xs={12} md={6}>

                        <div className="row">
                            <TextField
                                label={titles.NAME}
                                required={true}
                                size="small"
                                variant="outlined"
                                name="name"
                                value={route.name}
                                onChange={handleChange}
                                className="row__item"
                            />
                            <TextField
                                label={titles.ROUTE_NUM}
                                required={true}
                                size="small"
                                variant="outlined"
                                name="num"
                                value={route.num}
                                onChange={handleChange}
                                className="row__item"
                            />
                        </div>
                        <FormControl className="block" size="small" variant="outlined">
                            <InputLabel>{titles.STATUS}</InputLabel>
                            <Select
                                value={route.status}
                                required
                                onChange={handleChange}
                                label={titles.STATUS}
                                name="status"
                                disabled={true}
                                error={validation.isKey('id')}
                            >
                                {Object.keys(statuses)?.map((key) => (
                                    <MenuItem key={key} value={key}>{statuses[key]}</MenuItem>
                                ))}
                            </Select>
                            {validation.isKey('id') && (
                                <FormHelperText className={styles.helperText} error>
                                    {validation.get('id')}
                                </FormHelperText>
                            )}
                        </FormControl>


                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('enterprises')}
                            helperText={validation.get('enterprises')}
                        >
                            {/* в бэке поле не обязательно */}
                            <SelectCompany
                                selected={route.enterprises}
                                onChange={(value) => {
                                    setRoute({
                                        ...route,
                                        enterprises: value
                                    });
                                    validation.deleteKey('enterprises');
                                }}
                                // required={true}
                                multiple={true}
                                // disabled
                                label={titles.ENTERPRISES}
                                helperText={validation.get('enterprises')}
                                error={validation.isKey('enterprises')}
                                filter={{ withDeleted: 1 }}
                            />
                        </FormInfoWrapper>

                        <div className="block">
                            <TransportTypesSelect
                                label={titles.TYPE_OF_VEHICLE}
                                value={route.category_id}
                                error={validation.isKey('category_id')}
                                helperText={validation.get('category_id')}
                                // required
                                name="category_id"
                                onChange={handleChange}
                                className="row__item"
                                storeName="transportRoad"
                                keyName="routeTypes"
                                action={loadRouteTypes}
                            />
                        </div>

                        {!isNew && route?.road && (
                            <RouteCheckPoints
                                routePoints={routePoints}
                                canEdit
                                canRemove
                                draggable={false}
                                saveCheckPoints={setRouteCheckPoints}
                                displayType={options.modalForm}
                            />
                        )}

                    </Grid>

                    <Grid item xs={12} md={6}>

                        {/* карта с краткой информацией */}
                        {route?.road && (
                            <MapForm
                                currentRoute={route}
                                checkPoints={routePoints}
                            />
                        )}

                        {/* редактирование маршрута и остановок */}
                        <StationRoute
                            road={route.road}
                            points={routePoints}
                            onChange={(road, points) => {
                                setRoute(old => ({
                                    ...old,
                                    road,
                                    // check_points: points
                                }));
                                setRouteCheckPoints(points);
                            }}
                            validationKeys={[
                                'road',
                                'check_points'
                            ]}
                            service="transportRoad"
                        />

                    </Grid>

                </Grid>

            </form>
        </Modal>
    );
};

export default AddEditCopyModal;
