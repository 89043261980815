import React, { useEffect, useState } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import titles from '../../../../../helpers/constants/titles';
import messages from '../../../../../helpers/constants/messages/common';
import cn from 'classnames';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import DateRange from '../../../../common/Dates/DateRange';
import { fullDateTimeWithTimeZone } from '../../../../../helpers/date.config';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import SelectBoards from '../../../../common/Autocomplete/Boards/BoardList';

const Filter = ({ setParams, priorities, currentBoard = {}, }) => {
    const [wasSearch, setWasSearch] = useState(false);
    const initialState = {
        header: '',
        start_at: null,
        end_at: null,
        priority: '',
        dit_serial_number: '',
        dit_ids: [],
        dits: []
    };
    const [data, setData] = useState(initialState);

    const onChange = (event) => {
        const { name, value } = event.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const search = () => {
        const correctStartDate = fullDateTimeWithTimeZone(data.start_at);
        const correctEndDate = fullDateTimeWithTimeZone(data.end_at);

        const prepareData = {
            ...data,
            start_at: correctStartDate === 'Invalid date' ? null : correctStartDate,
            end_at: correctEndDate === 'Invalid date' ? null : correctEndDate,
            dits: null
        };

        const paramsWithoutEmpty = removeEmptyFields(prepareData, false);
        setWasSearch(true);
        setParams(paramsWithoutEmpty);
    };


    const resetFilter = () => {
        console.log('currentBoard', currentBoard);
        if (wasSearch) {
            setWasSearch(false);
            setParams({ 
                ...(currentBoard 
                    ? { 
                        dit_serial_number: currentBoard?.serial_number,
                        dit_ids: [currentBoard?.id] 
                    } 
                    : {}) 
            });
        }
        setData({ ...initialState, ...(currentBoard ?  { dit_serial_number: currentBoard?.serial_number,
            dit_ids: [currentBoard?.id] } : {}) });
    };

    useEffect(() => {
        if (Object.keys(currentBoard).length > 0) {
            setWasSearch(true);
            setData((prevData) => ({
                ...prevData,
                dit_serial_number: currentBoard?.serial_number,
                dit_ids: [currentBoard.id],
                dits: [currentBoard]
            }));
        }
    },[currentBoard]);


    const isDisabled = !data.start_at
            && !data.header
            && !data.end_at
            && !data.priority
            && (!currentBoard.id && !data.dit_serial_number && !data.dit_ids.length);

    return (
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">
                <TextField
                    label="Название"
                    size="small"
                    value={data.header}
                    variant="outlined"
                    name="header"
                    type="text"
                    onChange={onChange}
                />
                <DateRange
                    valueStartDate={data.start_at}
                    valueEndDate={data.end_at}
                    handleDate={(e) => setData({ ...data, start_at: e })}
                    handleEndDate={(e) => setData({ ...data, end_at: e })}
                />
                <FormControl size="small" variant="outlined">
                    <InputLabel>Приоритет</InputLabel>
                    <Select
                        value={data.priority}
                        required
                        label="Приоритет"
                        onChange={onChange}
                        name="priority"
                    >
                        {Object.keys(priorities)?.length
                            ? Object.keys(priorities)?.map((key) =>
                                <MenuItem value={key} key={key}>{priorities[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
                {!currentBoard.id
                    &&  <>
                        <TextField
                            label={titles.SERIAL_NUMBER}
                            size="small"
                            value={data.dit_serial_number}
                            variant="outlined"
                            name="dit_serial_number"
                            type="text"
                            onChange={onChange}
                            disabled={Object.keys(currentBoard).length > 0}
                        />
                        <SelectBoards 
                            selected={data.dits}
                            filter={{ status: 2 }}
                            onChange={(value) => setData({
                                ...data,
                                dits: value,
                                dit_ids: value.map(({ id }) => id)
                            })}
                            multiple
                            disabled={Object.keys(currentBoard).length > 0}
                        />
                    </>
                } 
            </div>
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilter,
                            disabled: isDisabled
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: search,
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;