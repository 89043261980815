import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import L from 'leaflet';
import makeStyles from '@mui/styles/makeStyles';

import {
    FeatureGroup,
    GeoJson,
    Map,
    MapControl,
    Marker,
    ToolTip
} from 'components/MapComponents/leaflet';
import { config } from 'config';
import { fullDateTime } from 'helpers/date.config';
import titles from 'helpers/constants/titles';

const useStyles = makeStyles({
    mapIcon: {
        backgroundColor: 'inherit'
    }
});

const MapFromModal = ({ 
    formData, 
    setFormData, 
    nearDefects = [], 
    site, 
    setCreateDefect,
    setDeleteDefect,
    height = '500px',
    currentItem,
}) => {
    const styles = useStyles();
    const [mapCenter, setMapCenter] = useState(config.get('mapCenter'));

    useEffect(() => {
        if (currentItem && currentItem?.lat && currentItem?.lon) {
            setMapCenter([currentItem?.lat, currentItem?.lon]);
        }
    }, [currentItem]);

    const createHtml = useCallback((color) => {
        return (
            `<div style="color: ${color}">
            <i style="font-size: 30px" class="fa fa-map-marker" aria-hidden="true"></i>
            </div>`
        );
    }, []);

    const nearDefectMarkers = useMemo(() => {
        return nearDefects.map(item => {
            const isSelected = formData.some(el => el.id === item.id);
            const color = isSelected ? '#4b9c4b' : '#676a679e';
            const icon = L.divIcon({
                className: styles.mapIcon,
                html: createHtml(color)
            });
            return (
                <Marker
                    key={item.id}
                    icon={icon}
                    latlng={[item?.lat, item?.lon]}
                    onClick={() => isSelected ? setDeleteDefect(item.id) : setFormData([ ...formData, item ])}
                >
                    <ToolTip
                        offset={[5, -7]}
                        direction="top"
                        permanent={currentItem && currentItem?.id === item?.id}
                    >
                        <div>
                            <p><b>{titles.NAME}:</b> {item?.name}</p>
                            <p><b>{titles.DATE_TIME_CREATED}:</b> {item.created_at && fullDateTime(item.created_at)}</p>
                            <p><b>Группа:</b> {item?.group?.name}</p>
                            <p><b>{titles.TYPE}:</b> {item?.type?.name}</p>
                        </div>
                    </ToolTip>
                </Marker>
            );
        });
    }, [formData, nearDefects, currentItem]);

    const clickOnMap = (value) => {
        const newDefect = {
            lat: value.latlng.lat,
            lon: value.latlng.lng,
            geometry: {
                geometry: {
                    coordinates: [
                        value.latlng.lng,
                        value.latlng.lat
                    ],
                    type: 'Point'
                },
                type: 'Feature'
            }
        };

        setCreateDefect(newDefect);
    };

    return (
        <>
            <div style={{ minWidth: '60%', height, marginRight: 20 }}>
                <Map
                    center={mapCenter}
                    onClick={clickOnMap}
                >
                    <GeoJson
                        data={site?.data}
                        centerAfter={true}
                    />
                    <MapControl>
                        <FeatureGroup>
                            {nearDefects.length && nearDefectMarkers}
                        </FeatureGroup>
                    </MapControl>
                </Map>
            </div>
        </>
    );
};

export default memo(MapFromModal);