/**
 * Sagas
 * **/
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import api from './api.methods';
import * as types from './types';
import * as actions from './actions';
// import * as actionsMessage from '../Message/actions';


function* loadVersionSaga() {
    const response = yield call(api.getVersion);

    // console.log('response version', response);
    // console.log('current version', localStorage.getItem('version') || null);

    if (response && response?.version) {
        const version = response?.version || '';
        const currentVersion = localStorage.getItem('version') || null;

        // console.log('current != version', currentVersion !== version);

        // текущая версия не сохранена
        if (!currentVersion) {
            localStorage.setItem('version', version);
        } // новая версия
        else if (currentVersion && currentVersion !== version) {
            localStorage.setItem('newVersion', version);
            yield put(actions.showVersion(true));
            // yield put(
            //     actionsMessage.showMessage(
            //         actionsMessage.messageTypes.error,
            //         'Вышла новая версия приложения, обновите страницу',
            //         '',
            //         () => {
            //             // window.location.reload();
            //         },
            //         30 * 60 * 1000
            //     )
            // );
        }
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_VERSION, loadVersionSaga),
    ]);
};
