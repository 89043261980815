import * as types from './types';

export const loadGovernmentContract = (params, callback) => ({
    type: types.LOAD_GOVERNMENT_CONTRACTS,
    payload: params,
    callback
});

export const loadingGovernmentContract = (bool) => ({
    type: types.LOADING_GOVERNMENT_CONTRACTS,
    payload: bool
});

export const loadedGovernmentContacts = (params) => ({
    type: types.LOADED_GOVERNMENT_CONTRACTS,
    payload: params
});

export const loadGovernmentContractById = (id, callback) => ({
    type: types.LOAD_GOVERNMENT_CONTRACT_BY_ID,
    payload: id,
    callback
});

export const loadingGovernmentContractById = (bool) => ({
    type: types.LOADING_GOVERNMENT_CONTRACT_BY_ID,
    payload: bool
});

export const loadedGovernmentContactById = (response) => ({
    type: types.LOADED_GOVERNMENT_CONTRACT_BY_ID,
    payload: response
});

export const createGovernmentContract = (data, callback) => ({
    type: types.CREATE_GOVERNMENT_CONTRACTS,
    payload: data,
    callback
});

export const editGovernmentContract = (id, data, callback) => ({
    type: types.EDIT_GOVERNMENT_CONTRACTS,
    payload: { id, data },
    callback
});

export const deleteGovernmentContract = (id, data, callback) => ({
    type: types.DELETE_GOVERNMENT_CONTRACTS,
    payload: { id, data },
    callback
});

export const loadGovernmentContractHistory = (page, limit, id) => ({
    type: types.LOAD_GOVERNMENT_CONTRACT_HISTORY,
    payload: { page, limit, id }
});

export const loadedGovernmentContactHistory = (data) => ({
    type: types.LOADED_GOVERNMENT_CONTRACT_HISTORY,
    payload: data
});

export const loadingButton = (bool) => ({
    type: types.LOADING_BUTTON,
    payload: bool
});

export const loadContractEntitiesByName = (id, entity_name, callback) => ({
    type: types.LOAD_CONTRACT_ENTITIES_BY_NAME,
    payload: {
        id,
        entity_name
    },
    callback
});

export const createContractEntitiesByName = (id, entity_name, params, callback) => ({
    type: types.CREATE_CONTRACT_ENTITIES_BY_NAME,
    payload: {
        id,
        entity_name,
        params
    },
    callback
});

export const loadingContractEntitiesByName = (bool) => ({
    type: types.LOADING_CONTRACT_ENTITIES_BY_NAME,
    payload: bool
});

export const loadContractEntitiesTypes = (callback) => ({
    type: types.LOAD_CONTRACT_ENTITIES_TYPES,
    callback
});

export const loadedContractEntitiesTypes = (data) => ({
    type: types.LOADED_CONTRACT_ENTITIES_TYPES,
    payload: data
});

export const loadContractLinkObjects = (page, limit, url, params, callback) => ({
    type: types.LOAD_CONTRACT_LINK_OBJECTS,
    payload: {
        url,
        page,
        limit,
        params, 
    },
    callback
});

export const loadingContractLinkObjects = (bool) => ({
    type: types.LOADING_CONTRACT_LINK_OBJECTS,
    payload: bool
});

export const loadedContractLinkObjects = (data) => ({
    type: types.LOADED_CONTRACT_LINK_OBJECTS,
    payload: data,
});

export const clearContractLinkObjects = () => ({
    type: types.LOADED_CONTRACT_LINK_OBJECTS,
    payload: [],
});