const historyTypes = {
    ws: 'ws', // авто с канала
    history: 'history', // история
    historyColor: 'historyColor', // история
    route: 'route', // маршрут
    stations: 'stations', // остановки
    legends: 'legends', // остановки
};

export default historyTypes;
