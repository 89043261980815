import Incidents from 'modules/React/Dictionaries/Incidents';

const dictionariesRoutes = [
    {
        path: '/react/incidents',
        component: Incidents,
        exact: true,
    },
];

export default dictionariesRoutes;
