import { useDispatch } from 'react-redux';
import {
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';

import { setShowReturnTo } from 'redux/Menu/actions';
import notificationsConfig from 'helpers/notifications.config';
import { fullDateTime } from 'helpers/date.config';
import { useWsAlerts } from 'helpers/ws/hooks';

import type { ItemTypes } from './types';

interface ItemProps {
    item: ItemTypes;
}

const Item = ({ item }: ItemProps) => {
    const dispatch = useDispatch();
    const notificationAlert = useWsAlerts();

    const handleOpenPassport = () => {
        if (item?.link) {
            dispatch(
                setShowReturnTo(
                    '/reports/doris-control/operator-log',
                    'Вернуться в журнал оператора'
                )
            );
            notificationAlert
                .onClick(
                    item?.id,
                    item?.link,
                    item?.viewed
                );
        }
    };

    const itemType = notificationsConfig(item?.showAlert?.type);

    return (
        <>
            <ListItem
                className="list_item"
                onClick={handleOpenPassport}
            >
                <ListItemIcon>
                    <i
                        style={{
                            color: itemType?.color,
                            fontSize: 22
                        }}
                        className={itemType?.icon}
                    />
                </ListItemIcon>
                <ListItemText>
                    <div>
                        <strong>Дата и время записи: </strong>
                        {fullDateTime(item?.createdAt || '')}
                    </div>
                    <div>
                        <strong>Информация: </strong>{item?.showAlert?.message || ''}
                    </div>
                </ListItemText>
            </ListItem>
        </>
    );
};

export default Item;
