import React, { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SelectCompany from 'components/common/Autocomplete/Companies';

import type { ValueOf } from 'types/common';

interface FilterProps {
    setParams: (params: { page: number; data: Record<string, any> }) => void;
}

interface FiltersState {
    uuid: string[];
    name: string;
    organization_ids: { id: number }[];
}

const Filters = ({ setParams }: FilterProps) => {
    const initialState: FiltersState = {
        uuid: [],
        name: '',
        organization_ids: [],
    };

    const [filters, setFilters] = useState<FiltersState>(initialState);

    const handleChangeFields = (name: keyof FiltersState,  value: ValueOf<FiltersState>) => {
        setFilters({ ...filters, [name]: value });
    };

    const onSearch = (filter = filters) => {
        const prepareData = removeEmptyFields(filter, false);

        setParams({
            page: 1,
            data: {
                ...prepareData,
                organization_ids: filters?.organization_ids?.map(item => item.id)
            }
        });
    };

    const resetFilter = (needRefresh: boolean) => {
        setFilters(initialState);
        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }
    };

    const updateFilter = (newFilters: Partial<FiltersState>) => {
        const updatedFilters = { ...initialState, ...newFilters };
        setFilters(updatedFilters);
        if (Object.keys(newFilters).length > 0) {
            onSearch(updatedFilters);
        }
    };

    const isDisabled = Object.keys(removeEmptyFields(filters)).length === 0;

    return (
        <LayoutFilter
            filter={filters}
            onSearch={onSearch}
            onResetFilter={resetFilter}
            setUserFilter={updateFilter}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <MultipleInput
                    value={filters.uuid}
                    onChange={(value) => handleChangeFields('uuid', value)}
                    label="UUID"
                    // TODO: guideText этот пропс отсутствует, узнать нужна ли эта строка
                    // guideText="Добавление одного или нескольких элементов, разделяя запятой"
                />
                <TextField
                    value={filters.name}
                    onChange={(e) => handleChangeFields('name', e.target.value)}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                />
                <SelectCompany
                    multiple
                    selected={filters.organization_ids}
                    onChange={(value) => handleChangeFields('organization_ids', value as { id: number }[] | [])}
                    filter={{ withDeleted: 1 }}
                    selectDeletedCompanies
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;
