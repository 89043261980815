import { useState } from 'react';
import Modal from '../../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import { useDispatch, useSelector } from 'react-redux';
import { useValidation } from '../../../../../../helpers/hooks';
import { Checkbox, FormControlLabel, TextField, } from '@mui/material';
import titles from '../../../../../../helpers/constants/titles';
import { createResponseScenario, editResponseScenario, } from '../../../../../../redux/SituationalPlans/actions';
import Attachments from '../../../../../common/Upload/Attachments';
import Threats from '../../../../../common/Autocomplete/DorisReact/Threats';
// import SelectEvents from '../../../../../common/Autocomplete/DorisReact/Events';
// import EventObjects from '../../../../../common/Autocomplete/DorisReact/EventObjects';
import KeyWords from '../../../../../common/Autocomplete/DorisReact/KeyWords';
import removeEmptyFields from '../../../../../../helpers/removeEmptyFields';
import { isNumber } from 'lodash';
import Operations from './Operations';
import { SituationalPlansSelectors } from '../../../../../../redux/SituationalPlans';

const ModalForm = ({
    isOpen,
    onClose,
    isNew = false,
    isCopy = false,
    item = {},
    reloadList,
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingButton = useSelector(SituationalPlansSelectors.loadingButton);

    const initialState = {
        name: isCopy ? '' : item?.name || '',
        operation_list: item?.operation_list || [],
        category: item?.category || {},
        category_id: item?.category_id || null,
        is_typical: item?.is_typical || false,
        // event: item?.event || {},
        // event_id: item?.event_id || null,
        // event_object_list: item?.event_object_list || [],
        // event_object_id_list: item?.event_object_id_list || [],
        keyword_list: item?.keyword_list || [],
        keyword_id_list: item?.keyword_id_list || [],
        attachments: item?.attachments || [],
        comment: item?.comment || '',
    };

    const [formData, setFormData] = useState(initialState);

    const onChange = (name, value) => {
        validation.deleteKey(name);

        setFormData({
            ...formData,
            [name]: value
        });
    };

    const onAcceptModal = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            is_typical: Number(formData.is_typical),
            category_id: formData.category?.id || '',
            // event_id: formData.event?.id || null,
            // event_object_id_list: formData.event_object_list.map(({ id }) => id),
            keyword_id_list: formData.keyword_list.map(({ id }) => id),
            operation_list: formData.operation_list.map(operation => {
                return removeEmptyFields({ ...operation, id: operation?.isNew ? null : operation.id }, false);
            })
        }, true);

        const callback = () => {
            reloadList();
            onClose();
        };

        isNew || isCopy
            ? dispatch(createResponseScenario(prepareData, callback))
            : dispatch(editResponseScenario(item?.id, prepareData, callback));
    };

    const isDisabled = !formData?.name
        || formData?.operation_list.length === 0
        || !formData?.operation_list?.every(el => isNumber(el?.organization_id));

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title={isNew ? titles.CREATE : isCopy ? titles.COPY : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: isDisabled,
                        loading: loadingButton
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <TextField
                        label={titles.NAME}
                        fullWidth={true}
                        size="small"
                        value={formData.name}
                        variant="outlined"
                        name="name"
                        type="text"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        required
                        error={!formData.name || validation.isKey('name')}
                        helperText={validation.get('name')}
                    />
                    {isCopy && (
                        <div style={{ color: '#808080', backgroundColor: '#e4e3e5' }}>
                            Название сценария реагирования должно отличаться от существующих
                        </div>
                    )}
                </div>

                <FormControlLabel
                    style={{ pointerEvents: 'none' }}
                    control={
                        <Checkbox
                            checked={formData.is_typical}
                            onChange={(e) => onChange(e.target.name, e.target.checked)}
                            color="primary"
                            name="is_typical"
                            size={'small'}
                            style={{ pointerEvents: 'auto' }}
                        />
                    }
                    label="Установите флаг, если сценарный план является типовым"
                />

                <div className="block">
                    <Threats
                        selected={formData.category}
                        onChange={(value) => onChange('category', value)}
                    />
                </div>

                {/*<div className="block">*/}
                {/*    <SelectEvents*/}
                {/*        selected={formData.event}*/}
                {/*        onChange={(value) => onChange('event', value)}*/}
                {/*        label={titles.EVENT}*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div className="block">*/}
                {/*    <EventObjects*/}
                {/*        multiple*/}
                {/*        selected={formData.event_object_list}*/}
                {/*        onChange={(value) => onChange('event_object_list', value)}*/}
                {/*    />*/}
                {/*</div>*/}

                <div className="block">
                    <KeyWords
                        multiple
                        selected={formData.keyword_list}
                        onChange={(value) => onChange('keyword_list', value)}
                    />
                </div>

                <div className="block">
                    <TextField
                        label={titles.COMMENT}
                        size="small"
                        value={formData.comment}
                        variant="outlined"
                        name="comment"
                        type="text"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                    />
                </div>

                <Attachments
                    onlyLink
                    onChange={(value) => onChange('attachments', value)}
                    files={formData?.attachments}
                />

                <Operations
                    data={formData}
                    onChange={onChange}
                    isNew={isNew}
                />
            </form>
        </Modal>
    );
};

export default ModalForm;
