import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { loadVehicleCategoryList } from 'redux/TransportPassenger/actions';
import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';
import type { UniversalSelectProps } from 'components/common/UniversalSelect/types';

import type { VehicleType } from 'types/PassengerTransport';

interface SelectVehicleTypeProps extends CommonAutocompleteProps<VehicleType> {
    renderProps?: UniversalSelectProps['renderProps']
}

const SelectVehicleType = ({
    multiple,
    selected,
    onChange,
    required,
    disabled,
    label = titles.TYPE_OF_VEHICLE,
    error,
    helperText,
    renderLabel = (option) => option?.name || '',
    renderProps = (el) => <div>{el.name}</div>,
}: SelectVehicleTypeProps) => {
    const dispatch = useDispatch();

    const prevData = useRef(selected);

    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleClose = () => setShowList(false);

    const handleReset = () => {
        onChange(multiple ? [] : null);
    };

    const getList = (params: { page: number; limit: number; query?: string }) => {
        const { page, limit, query } = params;

        dispatch(loadVehicleCategoryList(page, limit, { name: query }));
    };

    const handleAccept = (data: VehicleType | VehicleType[]) => {
        onChange(data);
        setShowList(false);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                onReset={handleReset}
                required={required}
                disabled={disabled}
                renderLabel={renderLabel}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
            />

            {showList && (
                <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName="transportPassenger"
                    storeNameProps="vehicleCategoryList"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={renderProps}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={label}
                    small
                />
            )}
        </>
    );
};

export default SelectVehicleType;
