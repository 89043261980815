import { ListItemText, Tooltip, ListItem, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const Item = ({
    item,
    onDelete = () => {},
}) => {

    return (
        <>
            <ListItemText>
                {item?.dashboard_item?.name || ''}
            </ListItemText>
            <Tooltip title="Убрать из дашборда">
                <IconButton onClick={onDelete}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </>
    );
    return (
        <ListItem
            disablePadding
            secondaryAction={
                <Tooltip title="Убрать из дашборда">
                    <IconButton onClick={onDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            }
        >
            <ListItemText>
                {item?.dashboard_item?.name || ''}
            </ListItemText>
        </ListItem>
    );
};

export default Item;
