import React, { useContext } from 'react';
import TransportSpeedColor from '../../../../common/Transport/TransportSpeedColor';
import { microservices } from '../../../../../helpers/hooks/TransportSpeedColor';
import Context from '../../../../../helpers/context';


const ColorSpeed = () => {
    const { permissions } = useContext(Context);
    return (
        <TransportSpeedColor
            microservice={microservices.passengerTransport}
            permissions={permissions}
        />
    );
};

export default ColorSpeed;
