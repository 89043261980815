import { Collapse, Divider } from '@mui/material';

import Image from 'components/common/Image';
import messages from 'helpers/constants/messages';

const Info = ({ isOpen, data }) => {
    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            <h2>Cообщениe:</h2>
            <Image 
                style={{ width: 'auto', height: '200px', display: 'inline-block' }} 
                src={data.base64} 
                alt="preview" 
            />

            <Divider style={{ margin: '10px 0' }} />
            <h2>ДИТ с данным сообщением:</h2>
            { data?.boards?.length > 0
                ? data?.boards
                    ?.map((board,index) => <div key={board?.id}>{index+1}.{board?.name}</div>)
                : <div>{messages.NOTHING_FOUND}</div>
            }
        </Collapse>
    );
};

export default Info;