import { Grid, Typography } from '@mui/material';
import RenderMonthForHistory from './RenderMonthForHistory';

const CalendarSection = ({ title = '', calendarData = {}, history = null, monthsNames }) => {
    return (
        <>
            <Typography variant="h5" align="center" m={2}>
                {title}
            </Typography>
            <Grid container spacing={2}>
                {Object.entries(calendarData?.months || {}).map(([monthNun, monthData]) => (
                    <RenderMonthForHistory
                        key={monthNun}
                        year={calendarData?.year}
                        monthNun={monthNun}
                        monthData={monthData}
                        monthName={monthsNames[monthNun]}
                        history={history}
                    />
                ))}
            </Grid>
        </>
    );
};

export default CalendarSection;
