import { createSelector } from 'reselect';

export const map = (state) => state.map;

export const lastMapCenter = (state) => map(state).lastMapCenter;
export const mapCenter = (state) => map(state).mapCenter;

// загрузка
export const mapLoadingObject = (state) => map(state).mapLoadingObject;
export const mapLoading = createSelector(
    mapLoadingObject,
    (mapLoadingObject) => Object.keys(mapLoadingObject).length > 0
);
