import PageLayout from '../../../../layout/PageLayout';
import Filter from './Filter';

const ReportOnWorkOfCPVF = () => {
    return (<PageLayout
        header={'Отчёт по времени работы КФВФ в часах по камерам'}
        filters={<Filter />}
    />);
};

export default ReportOnWorkOfCPVF;