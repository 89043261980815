import { useMemo } from 'react';
import {
    checkBase64,
} from 'components/MapComponents/Layers/TransportPassenger/helper';
import defaultIcon from '../../../../helpers/hooks/Transport/defaultIcon';
import colorExtend from 'helpers/mapHelper/colorExtend';


const Index = ({ category, noImage = null, fromService }) => {

    const categoryWithImages = useMemo(() => {
        const data = category
            ?.reduce(
                (res, item) => item?.image && item?.image_with_work_order
                    ? [ ...res, item]
                    : res
                , []
            ) || [];

        return data?.sort((a, b) => (a.id < b.id && -1) || (a.id > b.id && 1) || 0);
    }, [category]);

    return (
        <div className="sidebar-tp-legend">

            {/* дефолтная иконка */}
            <div className="item">
                <div className="title">
                    Неизвестное ТС
                </div>

                <div className="flex">
                    <div
                        style={{ background: colorExtend(defaultIcon?.color) }}
                        className="image"
                    >
                        {defaultIcon?.image}
                    </div>

                    <div
                        className="small"
                        style={{ 'textTransform': 'lowercase' }}
                    >
                        - {defaultIcon?.name}
                    </div>
                </div>

                {noImage && (
                    <div className="flex">
                        <div
                            style={{
                                background: colorExtend(noImage?.color),
                                padding: '3px'
                            }}
                            className="image"
                        >
                            {noImage?.image}
                        </div>

                        <div
                            className="small"
                            style={{ 'textTransform': 'lowercase' }}
                        >
                            - {noImage?.name}
                        </div>
                    </div>
                )}
            </div>

            {categoryWithImages?.map((item) => {
                const {
                    color,
                    image,
                    image_with_work_order,
                    name,
                    slug,
                } = item;

                return (
                    <div key={slug} className="item">
                        <div className="title">
                            {name}
                        </div>

                        <div className="flex">
                            <div
                                style={{ background: colorExtend(color) }}
                                className="image"
                            >
                                <img src={checkBase64(image)} />
                            </div>

                            {fromService !== 'transportSpecial'
                                && <div className="small">
                                - без наряда
                                </div>
                            }

                        </div>
                        {fromService !== 'transportSpecial'
                            && <div className="flex">
                                <div
                                    style={{ background: colorExtend(color) }}
                                    className="image"
                                >
                                    <img src={checkBase64(image_with_work_order)} />
                                </div>

                                <div className="small">
                                    - с нарядом
                                </div>
                            </div>
                        }
                    </div>
                );
            })}
        </div>
    );
};

export default Index;
