import { fullDateTime } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import prepareCoords from 'helpers/prepareCoords';
import titles from 'helpers/constants/titles';

const RenderEventInfo = ({ event = {} }) => {

    const renderLinks = (value, text) => {
        return (<>
            <a href={value.bpm_device} target="_blank" rel="noreferrer" title="Устройство в Дорис BPM">{text}</a>
            <a style={{ marginLeft: 5 }} target="_blank" rel="noreferrer" href={value.bpm_ticket} title="Создать задачу в Дорис BPM">
                <i className="far fa-clipboard-list-check"></i>
            </a>
        </>);
    };

    const renderLabels = {
        first: {
            complex_serial_number: {
                title: 'Серийный №',
                value: (value) => (event.complex?.bpm_device && event.complex?.bpm_ticket)
                    ? renderLinks(event.complex, value)
                    : value
            },
            camera_duplo_code: {
                title: 'Камера Паутина',
                value: (value) => (event.complex?.bpm_device && event.complex?.bpm_ticket)
                    ? renderLinks(event.complex, value)
                    : value
            },
            direction: {
                title: 'Направление',
                // value: (value) => value?.camera_direction
            },
            monitoring_uuid: {
                title: 'Распознающая камера',
                value: (value) => value ? value : null,
                visible: event?.monitoring_uuid ? true : false,
                // keyName: 'camera'
            },
            line: {
                title: 'Полоса №',
                visible: (value) => value !== null,
                value: (val) => val ?? messages.NO_DATA
            },
        },
        second: {
            lat: {
                title: 'GPS',
                value: () => <span
                    style={{ color: event.is_false_lat_lon ? 'red' : '0000008a' }}
                    title={event.is_false_lat_lon
                        ? 'Есть отклонение по координатам'
                        : ''
                    }>
                    {`${prepareCoords(event?.lat) || messages.NO_DATA}, ${prepareCoords(event?.lon) || messages.NO_DATA}`}
                </span>
            },
            address_text: {
                title: 'Адрес'
            },
            created_at: {
                title: <span>Дата фиксации</span>,
                value: (value) => (value && fullDateTime(value)) || messages.NO_DATA
            },
            saved_at: {
                title: <span>Дата получения данных</span>,
                value: (value) => (value && fullDateTime(value)) || messages.NO_DATA
            },
        },
        third: {
            violation_type_text: {
                title: 'Тип событий',
            },
            grz: {
                title: titles.GRZ,
            },
            vehicle_speed: {
                title: 'Скорость',
                value: (val) => val !== null && val !== undefined ? `${val.toFixed(1)} км/ч` : messages.NO_DATA
            },
        }
    };

    const renderItems = (labels) => (
        <>
            {Object.keys(labels).map(key => {
                const { title, value, visible = true, keyName = 'complex' } = labels[key];

                const field = event?.[key] ?? event?.[keyName]?.[key];
                const isVisible = typeof visible === 'function' ? visible(field) : visible;

                if (!isVisible) return null;

                return (
                    <div key={key} className="row">
                        <div style={{ width: '45%', textAlign: 'right' }}>
                            <strong>{title}:&nbsp;</strong>
                        </div>
                        <div style={{ width: '45%' }}>
                            <span>{typeof value === 'function' ? value(field) : (field || messages.NO_DATA)}</span>
                        </div>
                    </div>
                );
            })}
        </>
    );

    return (
        <div className="row">
            <div style={{ marginRight: '2rem', maxWidth: '33%' }}>{renderItems(renderLabels.first)}</div>
            <div style={{ marginRight: '2rem', maxWidth: '33%' }}>{renderItems(renderLabels.second)}</div>
            <div style={{ marginRight: '2rem', maxWidth: '33%' }}>{renderItems(renderLabels.third)}</div>
        </div>
    );
};

export default RenderEventInfo;
