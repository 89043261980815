import React, { useEffect } from 'react';
import ConfirmDelete from '../../../common/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../helpers/constants/messages';
import titles from '../../../../helpers/constants/titles';
import EditorForm from '../../../pages/Dictionaries/RoadNetwork/RoadSections/EditorForm';
import {
    createRoadSection,
    editRoadSection,
    deleteRoadSection,
    setEditFormRoadSection,
    setDeleteFormRoadSection,
    setSavedRoadSection,
} from '../../../../redux/RoadNetwork/actions';
import { roadNetworksSelectors } from '../../../../redux/RoadNetwork';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();
    const deletedFormRoadSection = useSelector(roadNetworksSelectors.deletedFormRoadSection);
    const editedFormRoadSection = useSelector(roadNetworksSelectors.editedFormRoadSection);
    const savedRoadSection = useSelector(roadNetworksSelectors.savedRoadSection);

    useEffect(() => {
        if (savedRoadSection) {
            dispatch(setSavedRoadSection(false));
        }
    }, [dispatch, savedRoadSection]);

    return (
        <>
            {/* форма редактирования */}
            {editedFormRoadSection && (
                <EditorForm
                    isOpen={!!editedFormRoadSection}
                    noPadding
                    title={
                        editedFormRoadSection?.id
                            ? titles.EDIT
                            : titles.ADD
                    }
                    isNew={!editedFormRoadSection?.id}
                    dataForm={editedFormRoadSection}
                    onClose={() => {
                        dispatch(setEditFormRoadSection());
                    }}
                    onSuccess={(newFields) => {
                        if (!newFields['id']) {
                            dispatch(createRoadSection(newFields, () => {
                                dispatch(setSavedRoadSection(true));
                                dispatch(setEditFormRoadSection());
                            }));
                        } else {
                            dispatch(editRoadSection(newFields.id, newFields, () => {
                                dispatch(setSavedRoadSection(true));
                                dispatch(setEditFormRoadSection());
                            }));
                        }
                    }}
                    inModal
                />
            )}

            {/* удаление */}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!deletedFormRoadSection}
                onSuccess={() => {
                    dispatch(deleteRoadSection(deletedFormRoadSection, () => {
                        dispatch(setSavedRoadSection(true));
                    }));
                    dispatch(setDeleteFormRoadSection());
                }}
                onClose={() => {
                    dispatch(setDeleteFormRoadSection());
                }}
            />
        </>
    );
};

export default CollectorForms;
