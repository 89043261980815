import { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from '@mui/material';


import { loadRouteRequestsList } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import * as actions from 'redux/TransportPassenger/actions';
import { config } from 'config';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import Context from 'helpers/context';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import RRModal from './RRModal';
import RRItem from './RRItem';
import RRFilter from './RRFilter';

const RouteRequests = () => {
    const initialState = {
        name: '',
        inn: '',
        category_id: '',
        type_transportation: '',
        status: '',
        start_date: fullDateTimeWithTimeZone(getStartOf('year')),
        end_date: fullDateTimeWithTimeZone(getEndOf('day'))
    };
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [openModal, setOpenModal] = useState(false);
    const [params, setParams] = useState(initialState);

    const routeRequestsData = useSelector(transportPassengerSelectors.routeRequestsData);
    const routeRequestsMeta = useSelector(transportPassengerSelectors.routeRequestsMeta);
    const loadingRouteRequests = useSelector(transportPassengerSelectors.loadingRouteRequests);

    const statuses = useStoreProp(
        actions.loadStatusesRouteRequests,
        'transportPassenger',
        'statusesRouteRequests'
    );

    const tsTypes = useStoreProp(
        actions.loadRouteTypes,
        'transportPassenger',
        'types'
    );

    const transportTypes = useStoreProp(
        actions.loadRoutesTransportations,
        'transportPassenger',
        'transportations'
    );

    const onSearch = useCallback((filters = params, limit = storageLimit, page = 1) => {
        const paramsWithoutEmpty = removeEmptyFields(filters);
        dispatch(loadRouteRequestsList(page, limit, paramsWithoutEmpty));
    }, [dispatch, params, storageLimit]);

    const renderContent = () => {
        return (<>
            {config?.project?.code === '46_krs'
                && <Link
                    style={{ marginBottom: 15, marginLeft: 15 }}
                    href="https://kyrsk-gov.ru/doc/44179"
                    underline="hover"
                    target="_blank"
                    rel="noopener"
                >
                    Об утверждении Порядка установления,
                    изменения, отмены межмуниципальных маршрутов
                    регулярных перевозок в границах Курской области
                </Link>
            }
            {routeRequestsData?.length > 0
                ? (<>
                    <LSContainer
                        headers={[
                            { title: '', width: '4%' },
                            { title: 'Организация', width: '20%' },
                            { title: 'Дата подачи', width: '20%' },
                            { title: 'Действия', isActions: true }
                        ]}
                    >
                        {routeRequestsData.map(el => <RRItem
                            key={el.id}
                            el={el}
                            statuses={statuses}
                            tsTypes={tsTypes}
                            transportTypes={transportTypes}
                            permissions={permissions}
                        />)}
                    </LSContainer>

                </>)
                : !loadingRouteRequests && <div>{messages.NO_DATA_FOR_YOUR_REQUEST}</div>
            }
        </>);
    };

    return (
        <>
            <PageLayout 
                header={'Заявки на открытие маршрута'}
                filters={<RRFilter
                    statuses={statuses}
                    tsTypes={tsTypes}
                    transportTypes={transportTypes}
                    setParams={setParams}
                    initialState={initialState}
                    params={params}
                    onSearch={onSearch}
                />}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent 
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                    disabled: !permissions?.is_create,
                                }
                            ]}
                            positionLeft
                            noPadding
                        />),
                    total: routeRequestsMeta?.total
                }}
                loading={loadingRouteRequests}
                content={renderContent}
                paginationProps={{
                    loadList: (page, limit) => onSearch(params, limit, page),
                    list: routeRequestsMeta,
                }}
            />
            {openModal && (
                <RRModal
                    isOpen={openModal}
                    isNew={true}
                    onClose={() => setOpenModal(false)}
                    statuses={statuses}
                    tsTypes={tsTypes}
                    transportTypes={transportTypes}
                />
            )}
        </>
    );
};

export default RouteRequests;
