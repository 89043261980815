import React, { useEffect } from 'react';
import * as actions from '../../../../redux/SituationalPlans/actions';
import ConfirmDelete from '../../../common/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../helpers/constants/messages';
import ModalForm from '../../../pages/Dictionaries/SituationalPlans/EventObjects/ModalForm';
import { useStoreProp } from '../../../../helpers/hooks';
import { SituationalPlansSelectors } from '../../../../redux/SituationalPlans';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();

    const deleteFormEvents = useSelector(SituationalPlansSelectors.deleteFormEvents || false);
    const editFormEvents = useSelector(SituationalPlansSelectors.editFormEvents || false);
    const savedEvents = useSelector(SituationalPlansSelectors.savedEvents);

    const types = useStoreProp(actions.loadEventObjectsTypes, 'SituationalPlans', 'eventObjectsTypes');

    useEffect(() => {
        if (savedEvents) {
            dispatch(actions.setSavedEvents());
        }
    }, [savedEvents]);

    return (
        <>
            {/* форма редактирования/добавления */}
            {editFormEvents
                && <ModalForm
                    isOpen={!!editFormEvents}
                    onClose={() => {
                        dispatch(actions.setEditFormEvents());
                    }}
                    isNew={!editFormEvents?.id}
                    item={editFormEvents}
                    types={types}
                />
            }

            {/* удаление */}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!deleteFormEvents}
                onSuccess={() => {
                    dispatch(actions.deleteEventObjects(deleteFormEvents));
                    dispatch(actions.setDeleteFormEvents());
                }}
                onClose={() => {
                    dispatch(actions.setDeleteFormEvents());
                }}
            />
        </>
    );
};

export default CollectorForms;
