import React, { useEffect, useState } from 'react';
import Modal from '../../../common/Modal';
import { useDispatch, useSelector } from 'react-redux';
import titles from '../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import { authSelectors } from '../../../../redux/Auth';
import useClipboard from 'react-use-clipboard';
import { showMessage } from '../../../../redux/Message/actions';
import messagetypes from '../../../../redux/Message/messagetypes';
import { clearValidation } from '../../../../redux/Validation/actions';
import { putUserName } from '../../../../redux/Auth/actions';
import makeStyles from '@mui/styles/makeStyles';
import style from './editProfileUser.module.scss';
import { Avatar, IconButton, TextField, Tooltip } from '@mui/material';
import cn from 'classnames';
import { useValidation } from '../../../../helpers/hooks';

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}));

const ProfileModal = ({
    isOpen,
    onClose,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(authSelectors.authUserInfo);
    const initialState = {
        first_name: user?.first_name || '',
        last_name: user?.last_name || ''
    };
    const [profile, setProfile] = useState(initialState);
    const [isCopied, setCopied] = useClipboard(user.telegram_unique, {
        successDuration: 6000,
    });
    const validation = useValidation();
    useEffect(() => () => {
        validation.clear();
        setProfile(initialState);
    }, []);

    const handleCopyToClipboard = (e) => {
        setCopied(e);
        showMessage(messagetypes.success, 'Текст скопирован в буфер обмена');
    };

    const handleClose = () => {
        onClose?.();
    };

    const handleChangeProfile = (e) => {
        e.preventDefault();
        validation.clear();
        const id = user.id;
        const data = { ...profile, full_name: `${profile.first_name} ${profile.last_name}` };
        dispatch(putUserName(data, id, handleClose));
        // dispatch(getAuthUser());
    };
    // const handleReset = () => {
    //     validation.clear();
    //     setProfile(initialState);
    //     handleClose();
    // };

    useEffect(() => {
        setProfile((state) => ({
            ...state,
            first_name: user.first_name || '',
            last_name: user.last_name || ''
        }));
    }, [user]);

    const isDisabled = !profile.first_name || !profile.last_name;

    const handleChange = (e) => {
        const { name, value } = e.target;
        validation.deleteKey(name);
        setProfile((state) => ({
            ...state,
            [name]: value
        }));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            showCloseInTitle
            noPadding
            title="Редактировать профиль"
            buttons={
                <FormButtons
                    buttons={[
                        // {
                        //     ...buttonsTypes.close,
                        //     onClick: handleReset
                        // },
                        {
                            ...buttonsTypes.save,
                            onClick: handleChangeProfile,
                            disabled: isDisabled
                        }
                    ]}
                />
            }
        >
            <div className={style.edit__wrap}>
                <div className={style.edit}>
                    {/*<h1>Редактировать профиль</h1>*/}
                    <div className={style.edit__profile}>
                        <Avatar className={classes.large}>{user.first_name?.charAt(0)}</Avatar>
                        <div className={style.edit__profile__wrap}>
                            <span>{titles.FIRST_NAME}: <strong>{user.first_name}</strong></span>
                            <span>{titles.LAST_NAME}: <strong>{user.last_name}</strong></span>
                            <span>{titles.EMAIL}: <strong>{user.email}</strong></span>
                            <span>{titles.TELEGRAM_ID}:
                                <strong>{user.telegram_unique}</strong>
                                <Tooltip title={isCopied ? 'Текст скопирован в буфер обмена' : 'Cкопировать'}>
                                    <IconButton onClick={handleCopyToClipboard} size="small">
                                        {isCopied ? <i className="fas fa-copy"/> : <i className="far fa-copy"/>}
                                    </IconButton>
                                </Tooltip>
                            </span>
                        </div>
                    </div>

                    <h3>Изменить Имя и Фамилию</h3>
                    <form
                        className={cn(classes.root, style.edit__form)}
                        noValidate
                        autoComplete="off"
                    >
                        <div className={style.edit__form__wrap}>
                            <div className="block">
                                <TextField
                                    className={style.edit__form__wrap__field}
                                    name="first_name"
                                    id="standard-search"
                                    label={'Имя'}
                                    variant="outlined"
                                    value={profile.first_name}
                                    error={validation.isKey('first_name')}
                                    helperText={validation.get('first_name')}
                                    type="text"
                                    onChange={handleChange}
                                    size="small"
                                />
                            </div>
                            <div className="block">
                                <TextField
                                    className={style.edit__form__wrap__field}
                                    name="last_name"
                                    variant="outlined"
                                    id="standard-search"
                                    label={'Фамилия'}
                                    type="text"
                                    value={profile.last_name}
                                    error={validation.isKey('last_name')}
                                    helperText={validation.get('last_name')}
                                    onChange={handleChange}
                                    size="small"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default ProfileModal;
