import L from 'leaflet';
import React, { useEffect, useRef } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from '../../../../store';
import { v4 as uuid_v4 } from 'uuid';
//import {usePrevious} from '../../../helpers/hooks';
//import { isEqual } from 'lodash';

// меню для карты, маркера, линии полигона
export const ContextMenu = ({
    map,
    parent,
    children,
    minWidth = 120,
    closeButton = false,
    onClick, // (latlng) не выводит
}) => {
    const id = useRef(null);
    const popup = useRef(null);
    let clickEventData = null;

    // меню провайдер
    const contextMenu = (e) => (
        <Provider store={store}>
            {React.isValidElement(children) ? React.Children.map(children, (child, index) =>
                React.cloneElement(child, {
                    e,
                    onCloseMenu: handleContextMenuClose
                })
            ) : children}
        </Provider>
    );

    // закрыть меню
    const handleContextMenuClose = () => {
        if (popup.current) {
            clickEventData = null;
            document.getElementById(id.current) && unmountComponentAtNode(document.getElementById(id.current));
            popup.current.remove();
        }
    };

    // отобразить меню
    const handleContextMenu = (e) => {
        L.DomEvent.stop(e);

        map.fire('context_menu_close');
        parent.fire('context_menu_open');

        if (onClick) {
            onClick(e.latlng);
        } else {
            setTimeout(() => {
                clickEventData = e;
                // закрываем все меню
                popup.current = L.popup({
                    closeButton,
                    minWidth
                })
                    .setLatLng(e.latlng)
                    .setContent(`<div class="contextmenu" id="${id.current}"></div>`)
                    .addTo(map)
                    .openOn(map);

                render(contextMenu(clickEventData), document.getElementById(id.current));
            }, 200);
        }
    };

    useEffect(() => {
        return () => {
            handleContextMenuClose();
        };
    }, []);

    // id меню
    useEffect(() => {
        const newId = uuid_v4();
        id.current = newId;

        return () => {
            document.getElementById(id.current) && unmountComponentAtNode(document.getElementById(id.current));
            id.current = null;
        };
    },[parent]);

    // отслеживаем изменения и подписываемся на события
    useEffect(() => {
        if (id.current) {
            // пришло событие закрыть все меню
            map.on('context_menu_close', handleContextMenuClose);
            parent.on('contextmenu', handleContextMenu);

            return () => {
                map.off('context_menu_close', handleContextMenuClose);
                parent.off('contextmenu', handleContextMenu);
            };
        }
    }, [map, parent, children, id]);

    // мб потребуется
    // отслеживаем изменение потомков
    /*useEffect(() => {
        if (id.current && !isEqual(prevChildren, children) && document.getElementById(id.current)) {
            unmountComponentAtNode(document.getElementById(id.current));
            render(
                contextMenu(clickEventData),
                document.getElementById(id.current)
            );
        }
    }, [children]);*/

    return null;
};

export default ContextMenu;
