import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { trafficLightsSelectors } from 'redux/TrafficLights';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import MapForm from './MapForm';
import { useState } from 'react';
import RenderList from './RenderList';

const SelectLightsModal = ({
    isOpen,
    onClose,
    onSave,
    latlng,
    trafficLights
}) => {
    const [selectedLights, setSelectedLights] = useState(trafficLights);
    const polygon = useSelector(trafficLightsSelectors.polygon);

    const text = polygon.length 
        ? 'Для присвоения светофоров, кликните по карте в нужном месте'
        : 'Не найдено светофоров рядом с событием';

    return (
        <Modal
            title="Светофоры"
            isOpen={isOpen}
            onClose={onClose}
            fullWidth
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: () => onSave(selectedLights)
                    }
                ]}
            />}
        >
            <Grid container direction="row" style={{ height: '100%' }}>
                <Grid item xs={8}>
                    <MapForm
                        latlng={latlng}
                        selectedLights={selectedLights}
                        setSelectedLights={setSelectedLights}
                    />
                </Grid>
                <Grid item xs={4}>
                    <h2>{text}</h2>
                    {selectedLights.length > 0 && (
                        <RenderList
                            items={selectedLights}
                            setItems={setSelectedLights}
                            draggable
                            canRemove
                        />
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default SelectLightsModal;
