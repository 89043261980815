import { Button } from '@mui/material';
import ZoneItem from './ZoneItem';
import { getInitialFields } from '../helper';

const ZoneForm = ({ data,  setData, fields, validation, }) => {
    const zones = data.zones || [];

    const addZone = () => {
        const newZones = [
            ...zones, 
            getInitialFields(fields?.fields)
        ];

        setData({
            ...data,
            zones: newZones
        });
    };

    const onDeleteZone = (index) => {
        setData({
            ...data,
            zones: [
                ...data.zones.slice(0, index),
                ...data.zones.slice(index + 1 ),
            ]
        });
    };

    const onChange = (el, index) => {
        setData({
            ...data,
            zones: [
                ...data.zones.slice(0, index),
                el,
                ...data.zones.slice(index + 1 ),
            ]
        });
    };


    return (
        <>
            <h3 style={{ color: fields.require && zones.length === 0 ? '#d32f2f' : 'inherit' }}>Зоны {fields.require && '*'}</h3>
            <Button variant="text" onClick={() => addZone()}>
                Добавить зону
            </Button>
            {zones?.map((zone, index) => (
                <ZoneItem
                    key={index}
                    index={index}
                    zone={zone} 
                    fields={fields?.fields} 
                    onChange={(el) => onChange(el, index)}
                    onDelete={() => onDeleteZone(index)}
                    validation={validation}
                />
            ))}
        </>
    );
};

export default ZoneForm;