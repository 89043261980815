const clearLayer = (layerRef, layerProps) => () => {
    const {
        parent,
        map,
    } = layerProps;

    if (layerRef.current) {
        parent.removeLayer(layerRef.current);
        layerRef.current.clearLayers();
    }
    //     if (currentIdsRef.current.length) {
    //         while (currentIdsRef.current.length > 0) {
    //             const id = currentIdsRef.current.pop();
    //             map.off(`showBy${idPrefix}${id}`);
    //         }
    //         // currentIdsRef.current.map((id) => map.off(`showBy${idPrefix}${id}`));
    //     }

    parent.off('contextmenu');
};

export default clearLayer;