import { HttpError } from './http.error';
class UploadError extends HttpError {
    constructor( data ) {
        super('Конфликт в данных файла', 409);
        this.className = 'UploadError';
        this.data = data;
        if (Error.captureStackTrace instanceof Function) {
            Error.captureStackTrace(this, UploadError);
        }
    }
}

export { UploadError };