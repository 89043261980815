import React, { useState } from 'react';
import { IconButton, ListItem, ListItemText, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import buttons from '../../../../../helpers/constants/buttons';
import { deleteTracking, loadTrackingMoreInfo } from '../../../../../redux/WantedCars/actions';
import { useDispatch } from 'react-redux';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import EditIcon from '@mui/icons-material/Edit';
import TTModal from './TTModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDelete from '../../../../common/ConfirmModal';
import messages from '../../../../../helpers/constants/messages';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';

const useStyles = makeStyles({
    preview: {
        display: 'flex',
        width: '100%',
        '& > span': {
            maxWidth: 200,
            width: '100%',
        },
        '& > p': {
            maxWidth: 340,
            width: '100%'
        }
    },
    buttonsWrap: {
        '& > *:not(:last-child)': {
            marginRight: 25
        }
    }
});

const List = ({ el, valueId, setValueId, statusList, moreTrackingLimit = 10 }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleVisibleAccordion = (id) => {
        dispatch(loadTrackingMoreInfo(1, moreTrackingLimit, id));
        setValueId(id);
    };

    return (
        <ListItem className={classes.pointer} key={el.id}>
            {openEditModal && (
                <TTModal
                    isNew={false}
                    open={openEditModal}
                    item={el}
                    closeForm={() => setOpenEditModal(false)}
                />
            )}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={openDeleteModal}
                onSuccess={() => {
                    dispatch(deleteTracking(el.id));
                    setOpenDeleteModal(false);
                }}
                onClose={() => setOpenDeleteModal(false)}
            />
            <ListItemText>
                <div className={classes.preview}>
                    <span><strong>Гос номер ТС:</strong> {el.grz}</span>
                    <span><strong>Интервал:</strong> {el.interval}</span>
                    <span><strong>Статус:</strong> {statusList[el.status] || ''}</span>
                </div>
            </ListItemText>
            <div className={classes.buttonsWrap}>
                {valueId !== el.id && el?.status === 3 && (
                    <FormButtonsComponent
                        className={classes.btn}
                        justButton
                        buttons={[
                            {
                                ...buttonsTypes.defaultSecondary,
                                onClick: () => handleVisibleAccordion(el.id),
                                name: buttons.OPEN_MAIN_REPORT
                            }
                        ]}
                    />
                )}
                {el.id === valueId && (
                    <FormButtonsComponent
                        className={classes.btn}
                        justButton
                        buttons={[
                            {
                                ...buttonsTypes.defaultPrimary,
                                onClick: () => setValueId(null),
                                name: buttons.CLOSE
                            }
                        ]}
                    />
                )}
                <IconButton onClick={() => setOpenEditModal(true)} size="small">
                    <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton onClick={() => setOpenDeleteModal(true)} size="small">
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
                <Tooltip
                    title="Получить основной отчет можно когда статус завершен"
                >
                    <IconButton size="small">
                        <ErrorOutline/>
                    </IconButton>
                </Tooltip>
            </div>
        </ListItem>
    );
};

export default List;
