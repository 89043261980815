import { loadRouteHistoryHuman } from 'redux/TransportPassenger/actions';
import HistoryList from 'components/common/HistoryList';

import Info from './Info';

import type { Route } from 'types/Transport';

interface RouteHistoryProps {
    currentRoute: Route;
}

const RouteHistory = ({ currentRoute }: RouteHistoryProps) => {
    return (
        <HistoryList
            storeName="transportPassenger"
            storeProp="routeHistoryHuman"
            loadingProp="loadingRouteHistoryHuman"
            loadAction={(page: number, limit: number, id: number) => loadRouteHistoryHuman(id, { page, limit })}
            id={currentRoute.id}
            ItemInfo={Info}
        />
    );
};

export default RouteHistory;
