import React from 'react';
import titles from '../../../../../helpers/constants/titles';
import {
    IconButton,
    Tooltip,
} from '@mui/material';


const Item = (props) => {
    const {
        data = {},
        isLock = false,
        loading = false,
        onChangeLock = ()=>{},
        onlyName,
    } = props;
    const {
        num,
        name,
        is_direct_text,
    } = data;

    return (
        <>
            {
                onlyName
                    ? <div className="transport-block">
                        <div className="icon"></div>
                        <div className="content">{name}</div>
                        <div className={`locked ${isLock ? 'show' : ''}`}>
                            <Tooltip title={isLock ? titles.ROUTE_UNLOCK : titles.ROUTE_LOCK}>
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onChangeLock(!isLock);
                                    }}
                                    style={{ fontSize: '13px', padding: '5px' }}
                                >
                                    {isLock ? <i className="fas fa-lock" /> : <i className="fas fa-lock-open" />}
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    : <>
                        <div>
                            <strong>Маршрут №:</strong> {num}
                        </div>
                        <div>
                            <strong>Название:</strong> {name}
                        </div>
                    </>
            }
        </>
    );
};

export default Item;
