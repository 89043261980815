import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useValidation } from 'helpers/hooks';
import {
    editInfrastructureStatistics,
} from 'redux/Efficiency/actions';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import ValueField from 'components/common/EfficiencyDevelopment/ValueField';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';

const useStyles = makeStyles({
    item: {
        alignItems: 'center',
        display: 'flex',
    },
    center: {
        justifyContent: 'center',
    }
});

const ModalForm = ({
    isOpen,
    onClose,
    item = {},
    loadList,
    title = 'Редактировать параметр',
}) => {
    const classes = useStyles();

    const {
        is_edit = false,
        name = '',
    } = item;
    const dispatch = useDispatch();
    const validation = useValidation();

    const initState = {
        value: (item?.value || '').toString(),
        is_visible: item?.is_visible ?? true,
    };

    const [data, setData] = useState(initState);

    const isDisabled = useMemo(() => {
        return item.value === data.value && item.is_visible === data.is_visible;
    }, [item, data]);

    const handleChange = (value) => {
        setData((prevData) => ({ ...prevData, value }));
    };

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target;
        setData((prevData) => ({ ...prevData, [name]: checked }));
    };

    const handleSave = () => {
        const callback = () => {
            onClose();
            loadList();
        };

        const prepareData = {
            value: data.value,
            is_visible: data.is_visible ? 1 : 0
        };

        dispatch(editInfrastructureStatistics(item.id, prepareData, callback));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            noPadding
            maxWidthProp={'md'}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={5} className={classes.item}>
                        <div className="block">
                            {name && (
                                <h4>
                                    {name}
                                </h4>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={3} className={cn(classes.item, classes.center)}>
                        <ValueField
                            type={item.type}
                            value={data.value}
                            error={validation.isKey('value')}
                            helperText={validation.get('value')}
                            onChange={handleChange}
                            disabled={!is_edit}
                        />
                    </Grid>
                    <Grid item xs={4} className={cn(classes.item, classes.center)}>
                        <FormControlLabel
                            style={{ pointerEvents: 'none' }}
                            control={
                                <Checkbox
                                    name="is_visible"
                                    checked={data.is_visible}
                                    onChange={handleChangeCheckbox}
                                    color="primary"
                                    style={{ pointerEvents: 'auto' }}
                                />
                            }
                            label="Выводить в дашборде"
                        />
                    </Grid>
                </Grid>
            </form>
        </Modal>
    );
};

export default ModalForm;
