import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiTrafficLightsMethods = {
    getStatuses: async () => {
        const response = await instance.get(apiUrls.getStatuses());
        return response?.data;
    },
    getTypes: async () => {
        const response = await instance.get(apiUrls.getTypes());
        return response?.data;
    },
    getList: async (params) => {
        const response = await instance.get(apiUrls.getList(), { params });
        return response?.data;
    },
    getPolygon: async (params) => {
        const response = await instance.post(
            apiUrls.getPolygon(),
            params,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
        return response?.data;
    },
    getSidebar: async (params) => {
        const response = await instance.get(apiUrls.getSidebar(), {
            params
        });
        return response?.data;
    },
    getTraffic: async (params) => {
        const response = await instance.get(apiUrls.getTraffic(), {
            params
        });
        return response?.data;
    },
    getTrafficInfo: async (id, params) => {
        const response = await instance.get(apiUrls.getTrafficInfo(id), {
            params
        });
        return response?.data;
    },

    getReport: async (params) => {
        const response = await instance.post(apiUrls.getReport(), params);
        return response.data;
    },

    getReportGroups: async () => {
        const response = await instance.get(apiUrls.getReportGroups());
        return response.data;
    },

    getReportTypes: async () => {
        const response = await instance.get(apiUrls.getReportTypes());
        return response.data;
    },
    getTransportFlowReport: async (params) => {
        const response = await instance.post(apiUrls.getTransportFlowReport(), params);
        return response?.data;
    },
    getTransportFlowGroups: async () => {
        const response = await instance.get(apiUrls.getTransportFlowGroups());
        return response?.data;
    },
    // getTransportFlowTypes: async (params) => {
    //     const response = await instance.get(apiUrls.getTransportFlowTypes(), {
    //         params
    //     });
    //     return response?.data;
    // },

    getThroughput: async (params) => {
        const response = await instance.get(apiUrls.getThroughput(), { params });
        return response.data;
    },

    getHeatMap: async (params) => {
        const response = await instance.post(apiUrls.getHeatMap(), params);
        return response?.data;
    },

    loadShowcase: async () => {
        const response = await instance.post(apiUrls.loadShowcase());
        return response?.data;
    },

    loadReportIndicators: async (filter) => {
        const response = await instance.post(apiUrls.loadReportIndicators(), filter);
        return response?.data;
    },
    loadTrafficFlowByRadius: async (params) => {
        const response = await instance.post(apiUrls.loadTrafficFlowByRadius(), params);
        return response?.data;
    },

    createLogicalPuid: async (data) => {
        const response = await instance.post(apiUrls.createLogicalPuid(), data);
        return response?.data;
    },
    editLogicalPuid: async (id, data) => {
        const response = await instance.put(apiUrls.editLogicalPuid(id), data);
        return response?.data;
    },
    deleteLogicalPuid: async (id) => {
        const response = await instance.delete(apiUrls.deleteLogicalPuid(id));
        return response?.data;
    },

    loadPuidByIds: async (params) => {
        const response = await instance.get(apiUrls.loadPuidByIds(), { params });
        return response?.data;
    },

    loadPuidByRadius: async (params) => {
        const response = await instance.post(apiUrls.loadPuidByRadius(), params);
        return response?.data;
    },

    getSettings: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getSettings(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    editSettings: async (id, data) => {
        const response = await instance.put(apiUrls.editSettings(id), data);
        return response?.data;
    },

    editSettingsList: async (data) => {
        const response = await instance.put(apiUrls.editSettingsList(), data);
        return response?.data;
    },

    getSettingsGroup: async () => {
        const response = await instance.get(apiUrls.getSettingsGroup());
        return response?.data;
    },

    editSettingsGroup: async (id, data) => {
        const response = await instance.put(apiUrls.editSettingsGroup(id), data);
        return response?.data;
    },

    getReportStatisticType: async () => {
        const response = await instance.get(apiUrls.getReportStatisticType());
        return response.data;
    },

    getReportTimeSlot: async () => {
        const response = await instance.get(apiUrls.getReportTimeSlot());
        return response.data;
    },
};

export default ApiTrafficLightsMethods;
