import React from 'react';
import messages from '../../../../../../../helpers/constants/messages';
import RenderExportInfo from './RenderExportInfo';

const HistoryItemInfo = ({ item }) => {

    const renderAddress = (el) => (
        <div style={{ marginLeft: 10 }}>
            <span>Город: {el.city_name || messages.NO_DATA}</span><br/>
            <span>Код страны: {el.country_iso_code || messages.NO_DATA}</span><br/>
            <span>Район: {el.district || messages.NO_DATA}</span><br/>
            <span>Широта: {el.geo_lat || messages.NO_DATA}</span><br/>
            <span>Долгота: {el.geo_lon || messages.NO_DATA}</span><br/>
            <span>Дом: {el.house || messages.NO_DATA}</span><br/>
            <span>Улица: {el.street || messages.NO_DATA}</span><br/>
            <span>Регион: {el.region || messages.NO_DATA}</span><br/>
            <span>Почтовый индекс: {el.postal_code || messages.NO_DATA}</span><br/>
        </div>
    );

    const renderType = (type) => {
        const typeForRender = {
            1: 'Получение данных комплекса через API',
            2: 'Получение данных комплекса через SOCKET',
            3: 'Получение данных комплекса через внешний FTP',
            4: 'Получение данных комплекса через Дорис FTP',
            5: 'Получение данных комплекса через SOAP DUPLO',
        };
        return typeForRender[type];
    };

    return (
        <>
            {item.data?.length > 0
                ? item?.data.map((el, index) => (
                    <div key={index}>
                        <h3>{el?.name}: </h3>
                        <div>
                            <i>Новое значение</i>
                            <span>
                                {' - '}
                                {el?.new === true || el?.new === 1
                                    ? messages.YES
                                    : el?.new === false || el?.new === 0
                                        ?  messages.NO
                                        : el?.new?.geo_lat
                                            ? renderAddress(el?.new)
                                            : el?.new?.transfer_type
                                                ? renderType(el?.new?.transfer_type)
                                                : el?.new?.link_fgis
                                                    ? el?.new?.link_fgis || messages.NO_DATA
                                                    : Array.isArray(el?.new) && el?.new?.length === 0
                                                        ? messages.NO_DATA
                                                        : (el?.new?.no_data_interval || el?.new?.two_percent_violations_to_passage || el?.new?.one_percent_violations_to_passage)
                                                            ? <>
                                                                <div>
                                                                    Комплекс не на связи более: {el?.new?.no_data_interval}
                                                                </div>
                                                                <div>
                                                                    Нарушений менее 2% от числа проездов: {el?.new?.two_percent_violations_to_passage}
                                                                </div>
                                                                <div>
                                                                    Нарушений менее 1% от числа проездов {el?.new?.one_percent_violations_to_passage}
                                                                </div></>
                                                            : Array.isArray(el?.new) && el?.new?.length > 0
                                                                ? el?.new.map((item, i) => (
                                                                    <RenderExportInfo
                                                                        item={item}
                                                                        name={el?.name}
                                                                        key={i}
                                                                    />
                                                                ))
                                                                : el?.new || messages.NO_DATA
                                }
                            </span>
                        </div>
                        <div>
                            <i>Старое значение</i>
                            <span>
                                {' - '}
                                {el?.old === true || el?.old === 1
                                    ? messages.YES
                                    : el?.old === false || el?.old === 0
                                        ?  messages.NO
                                        : el?.old?.geo_lat
                                            ? renderAddress(el?.old)
                                            : el?.old?.transfer_type
                                                ? renderType(el?.old?.transfer_type)
                                                : el?.new?.link_fgis
                                                    ? el?.new?.link_fgis || messages.NO_DATA
                                                    : Array.isArray(el?.old) && el?.old?.length === 0
                                                        ? messages.NO_DATA
                                                        : (el?.old?.no_data_interval || el?.old?.two_percent_violations_to_passage || el?.old?.one_percent_violations_to_passage)
                                                            ? <>
                                                                <div>
                                                                    Комплекс не на связи более: {el?.old?.no_data_interval}
                                                                </div>
                                                                <div>
                                                                    Нарушений менее 2% от числа проездов: {el?.old?.two_percent_violations_to_passage}
                                                                </div>
                                                                <div>
                                                                    Нарушений менее 1% от числа проездов {el?.old?.one_percent_violations_to_passage}
                                                                </div></>
                                                            : Array.isArray(el?.old) && el?.old?.length > 0
                                                                ? el?.old.map((item, i) => (
                                                                    <RenderExportInfo
                                                                        item={item}
                                                                        name={el?.name}
                                                                        key={i}
                                                                    />
                                                                ))
                                                                : el?.old || messages.NO_DATA
                                }
                            </span>
                        </div>
                    </div>
                ))
                : <span>{messages.NO_DATA}</span>
            }
        </>
    );
};

export default HistoryItemInfo;
