import React, { useState } from 'react';
import messages from '../../../../helpers/constants/messages';
import { fullDateTime } from '../../../../helpers/date.config';
import { LSContentColumn, LSContentItem } from '../../../common/List';
import MediaModal from './MediaModal';

const Item = ({ item }) => {

    const [detectionOpen, setDetectionOpen] = useState(false);

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    <span>{fullDateTime(item?.created_at) || messages.NO_DATA}</span>
                </LSContentColumn>

                <LSContentColumn>
                    <span>{item?.camera_name || messages.NO_DATA}</span>
                </LSContentColumn>

                <LSContentColumn>
                    <span>{item.class_name_text || messages.NO_DATA}</span>
                </LSContentColumn>

                <LSContentColumn>
                    <span>Широта: <code>{Math.trunc(item.lat * 100) / 100}</code></span>
                    <br/>
                    <span>Долгота: <code>{Math.trunc(item.lon * 100) / 100}</code></span>
                </LSContentColumn>

                <LSContentColumn>
                    {item?.url_to_frame && (
                        <button onClick={() => setDetectionOpen(true)}>
                            <img
                                style={{ margin: 0 }}
                                src={item.url_to_frame}
                                title="Кадр детекции"
                                alt="Кадр детекции"
                            />
                        </button>
                    )}
                </LSContentColumn>
            </LSContentItem>

            {detectionOpen && (
                <MediaModal
                    isOpen={detectionOpen}
                    onClose={() => setDetectionOpen(false)}
                    item={item}
                />
            )}
        </>
    );
};

export default Item;