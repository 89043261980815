import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    loadReportCategoryTable,
    loadedReportCategoryTable,
} from '../../../redux/Incidents/actions';
import { incidentsSelectors } from '../../../redux/Incidents';

// хук
const useReportCategoryTable = () => {
    const dispatch = useDispatch();

    const reportCategoryTable = useSelector(incidentsSelectors.reportCategoryTable);
    const loadingReportCategoryTable = useSelector(incidentsSelectors.loadingReportCategoryTable);
    return {
        data: reportCategoryTable,
        load: (params) => {
            dispatch(loadReportCategoryTable(params));
        },
        clear: () => {
            dispatch(loadedReportCategoryTable([]));
        },
        loading: loadingReportCategoryTable,
    };
};

export default useReportCategoryTable;
