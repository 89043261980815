import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { loadCameraModel } from 'redux/RoadNetwork/actions';
import { loadingCameraModel } from 'redux/RoadNetwork/selectors';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import UniversalSelect from 'components/common/UniversalSelect';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

import type { CameraModel } from 'types/RoadNetwork';

const SelectCameraModel = ({
    multiple,
    selected,
    onChange,
    label = 'Выбрать модель камеры',
    ...props
}: CommonAutocompleteProps<CameraModel>) => {
    const dispatch = useDispatch();

    const loading: boolean = useSelector(loadingCameraModel);

    const prevData = useRef(selected);

    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const getList = (params: { page: number; limit: number; query?: string }) => {
        const { page, limit, query: name } = params;

        dispatch(loadCameraModel(page, limit, { ...(name && { name }) },));
    };

    const handleClose = () => {
        setShowList(false);
    };

    const handleAccept = (data: any) => {
        onChange(data);
        handleClose();
    };

    const onReset = () => {
        const initialState = multiple ? [] : '';
        onChange(initialState);
    };
    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                inputName="type"
                onReset={onReset}
                renderLabel={(option) => option?.name || ''}
                label={label}
                onShowList={() => setShowList(true)}
                loading={loading}
                {...props}
            />

            {showList && (
                <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName="roadNetworks"
                    storeNameProps="cameraModel"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    storeLoadingProps="loadingCameraModel"
                    searchTitle="Поиск по названию"
                    renderProps={(el) => <div>{el.name}</div>}
                    title={label}
                    onAccept={handleAccept}
                    isOpen={showList}
                    onClose={handleClose}
                    noPadding
                    small
                />
            )}
        </>
    );
};

export default SelectCameraModel;
