import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, TextField  } from '@mui/material';
import { createPakModel, editPakModel } from 'redux/RoadNetwork/actions';
import { roadNetworksSelectors } from 'redux/RoadNetwork';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import SelectPakVendor from 'components/common/Autocomplete/RoadNetwork/PakVendor';
import { useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import type { PakModelItem } from 'types/RoadNetwork';

interface ModalFormProps {
    isOpen: boolean
    onClose: () => void
    reloadList: () => void
    isNew?: boolean
    item?: PakModelItem
}

const ModalForm = ({ isOpen, isNew, onClose, reloadList, item }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingButton = useSelector(roadNetworksSelectors.loadingButton);

    const initialState = {
        name: item?.name || '',
        pak_vendor_id: item?.pak_vendor_id || null,
        vendor: item?.vendor || null,
        is_system: item?.is_system || false
    };

    const [data, setData] = useState(initialState);

    const handleChange = (name: keyof PakModelItem, value: unknown) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const onSave = () => {
        const prepareData = {
            ...data,
            pak_vendor_id: data.vendor?.id
        };

        const callback = () => { onClose(); reloadList(); };

        isNew
            ? dispatch(createPakModel(prepareData, callback))
            : dispatch(editPakModel(item?.id, prepareData, callback));
    };

    const isDisabled = !data.name || !data.vendor?.id;

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            noPadding={true}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onSave,
                            loading: loadingButton,
                            disabled: isDisabled
                        }
                    ]}
                />
            }
        >
            <form className="modal__form">
                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                >
                    <TextField
                        value={data.name || ''}
                        name="name"
                        onChange={(e) => handleChange('name', e.target.value)}
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        required={true}
                    />
                </FormInfoWrapper>

                <SelectPakVendor
                    required
                    selected={data.vendor}
                    onChange={(value) => {handleChange('vendor', value);}}
                    error={validation.isKey('pak_vendor_id')}
                    helperText={validation.get('pak_vendor_id')}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            onClick={() => handleChange('is_system', !data.is_system)}
                            checked={!!data.is_system}
                            size="small"
                        />
                    }
                    label="Системный"
                />
            </form>
        </Modal>
    );
};

export default ModalForm;
