import { createSelector } from 'reselect';
import { authSelectors } from '../Auth';

export const ws = (state) => state?.ws || {};

export const active = (state) => ws(state)?.active || {}; // активный
export const availableChannels = (state) => ws(state)?.availableChannels || {}; // каналы пользователя

// каналы по проекту
export const availableChannelsByProject = createSelector(
    availableChannels,
    authSelectors.projectCode,
    (channelsAll = {}, projectCode = null) => {
        return channelsAll?.[projectCode] || {};
    }
);

// объект канала по проекту по channel ключю
export const currentAvailableChannels = (channel) => createSelector(
    availableChannelsByProject,
    (channels) => {
        return channels?.[channel] || {};
    }
);

// название канала (для центрифуги) по проекту и channel ключю
export const currentAvailableChannelName = (channel) => createSelector(
    currentAvailableChannels(channel),
    (currentChannel) => {
        return currentChannel?.name || '';
    }
);
