import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { loadGovernmentContract } from 'redux/GovernmentContracts/actions';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { getHumanDate } from 'helpers/date.config';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import ConfirmModal from 'components/common/ConfirmModal';

import type { GovernmentContractListTypes, Item } from './types';

const GovernmentContractList = ({
    multiple = false,
    selected,
    onChange,
    required = false,
    disabled = false,
    label = titles.SELECT_GOVERNMENT_CONTRACT,
    error = false,
    helperText = '',
    filter = {},
    sortedList,
    renderLabel = (option: Item | null) => String(option?.number || ''),
    limitTags = 1,
    confirmable = false,
    additionalItem,
}: GovernmentContractListTypes<Item> ) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const [confirmData, setConfirmData] = useState({
        data: '',
        openConfirmModal: false
    });

    const prevData = useRef(selected);

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const getList = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query: number } = params;

        dispatch(loadGovernmentContract({
            page,
            limit,
            params: {
                ...(number && { number }),
                is_simple: 1,
                ...filter
            },
        }));
    };

    const handleClose = () => setShowList(false);

    const handleAccept = (data: any) => {
        if (confirmable) {
            setConfirmData({
                data,
                openConfirmModal: true,
            });
        } else {
            onChange(data);
        }
        setShowList(false);
    };

    const onSuccessModal = () => {
        onChange(confirmData.data);
        setConfirmData({
            data: '',
            openConfirmModal: false,
        });
    };


    const onReset = () => {
        const initialState = multiple ? [] : '';
        if (confirmable) {
            setConfirmData({
                data: '',
                openConfirmModal: true,
            });
        } else {
            onChange(initialState);
        }
    };

    const titleConfirm = (confirmData.data && Object.keys(confirmData.data).length > 0)
        ? 'Будет присвоен новый ГК. Продолжить?'
        : 'Присвоенный ГК будет удален. Продолжить?';

    return (
        <>
            <CommonAutocomplete<Item>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="id"
                disabled={disabled}
                onReset={onReset}
                renderLabel={renderLabel}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
                isOpen={showList}
            />

            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={!sortedList && getList}
                    sortedList={sortedList}
                    storeName="govermentContracts"
                    storeNameProps="governmentContractList"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div>
                        <div><b>номер ГК:</b> {el.number}</div>
                        <div><b>дата начала действия ГК:</b> {getHumanDate(el.started_at) || messages.NO_DATA}</div>
                        <div><b>дата окончания действия ГК:</b> {getHumanDate(el.ended_at) || messages.NO_DATA}</div>
                        <div><b>признак "бессрочно":</b> {el.is_forever ? 'Да' : 'Нет'}</div>
                    </div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={titles.SELECT_GOVERNMENT_CONTRACT}
                    additionalItem={additionalItem}
                    storeLoadingProps="governmentContractLoading"
                />
            }
            {confirmData.openConfirmModal
                && <ConfirmModal
                    open={confirmData.openConfirmModal}
                    onClose={() => setConfirmData((prev) => ({ ...prev, openConfirmModal: false }))}
                    onSuccess={onSuccessModal}
                    message={titleConfirm}
                />
            }
        </>
    );
};

export default GovernmentContractList;
