import { IconButton, Link, Tooltip } from '@mui/material';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import titles from '../../../../helpers/constants/titles';

const UploadFileField = ({
    file,
    onChange = () => {},
    title = 'Загрузить файл',
    required,
    error
}) => {
    const onDrop = useCallback(
        (files) => {
            onChange(files[0]);
        },
        [onChange]
    );

    const { getRootProps, getInputProps, open } = useDropzone({ onDrop });

    const getFileUrl = (file) => {
        if (typeof file === 'object' && file !== null) {
            const blobFile = new Blob([file], { type: file.type });
            return URL.createObjectURL(blobFile);
        } else {
            return file;
        }
    };

    return (
        <div className="row align-items-center">
            <h2
                style={{ color: (!file && required) || error ? '#f44336' : '' }}
            >
                {title}{required && '*'}:
            </h2>
            <div {...getRootProps()} onClick={(e) => e.stopPropagation()}>
                <input {...getInputProps()} />
                <Tooltip title={title}>
                    <IconButton size="small" onClick={open}>
                        <i className="far fa-paperclip" />
                    </IconButton>
                </Tooltip>
            </div>
            {file && (
                <Tooltip title={titles.CLICK_TO_OPEN}>
                    <IconButton size="small">
                        <Link href={getFileUrl(file)} target="_blank">
                            <i className="fal fa-file-alt" /> {'Файл.'}
                        </Link>
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

export default UploadFileField;
