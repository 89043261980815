import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, List, ListItem, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { transportPassengerSelectors } from 'redux/TransportPassenger';
import {
    loadScheduleTemplateCheckpoints,
    saveCheckPointsToScheduleTemplate
} from 'redux/TransportPassenger/actions';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';

import StationTimeModal from './StationTimeModal';
import AddStationModal from './AddStationModal';
import StationItem from './StationItem';

const useStyles = makeStyles({
    item_text: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    item: {
        textAlign: 'left',
        marginLeft: '1rem',
        flex: 1,
    }
});

const Stations = ({ template = {}, hasCurrentRoute, reloadList }) => {
    const styles = useStyles();
    const dispatch = useDispatch();

    const [openAddStation, setOpenAddStation] = useState(false);
    const [openEditStation, setOpenEditStation] = useState(false);

    const scheduleTemplateCheckpoints = useSelector(transportPassengerSelectors.scheduleTemplateCheckpoints);

    const [scheduleCheckPoints, setScheduleCheckPoints] = useState([]);

    useEffect(() => {
        const selectedTemplateCheckPoints = scheduleTemplateCheckpoints[template.id] || [];
        setScheduleCheckPoints(selectedTemplateCheckPoints);
    }, [scheduleTemplateCheckpoints, template.id]);

    // загружаем контрольные точки шаблона
    useEffect(() => {
        dispatch(loadScheduleTemplateCheckpoints(template.id));
    }, [template.id, dispatch]);

    const onStationsItemChanged = (newData) => {
        saveCheckPointsToTemplate(newData, () => {
            setOpenEditStation(false);
            dispatch(loadScheduleTemplateCheckpoints(template.id));
        });
    };

    const saveCheckPointsToTemplate = (data, callback) => {
        const result = data.map((item) => {
            return {
                ...item,
                ...((item.station?.id || item.station_id)
                    ? { station_id: item.station?.id || item.station_id }
                    : { check_point_id: item.check_point_id }),
            };
        });
        dispatch(saveCheckPointsToScheduleTemplate(template, result, callback, reloadList));
    };

    const onSavePoints = (points, callback) => {

        saveCheckPointsToTemplate(points, () => {
            callback();
            dispatch(loadScheduleTemplateCheckpoints(template.id));
        });
    };

    const hasNewItems = scheduleCheckPoints.find(el => !el.time_start || !el.time_end);

    return (
        <>
            {openAddStation && (
                <AddStationModal
                    isOpen={openAddStation}
                    onClose={() => setOpenAddStation(false)}
                    selectedItems={scheduleCheckPoints}
                    onSave={onSavePoints}
                    template={template}
                />
            )}
            {openEditStation && (
                <StationTimeModal
                    items={scheduleCheckPoints}
                    isOpen={openEditStation}
                    onClose={() => setOpenEditStation(false)}
                    onSave={onStationsItemChanged}
                />
            )}
            <h2>Информация об остановках и контрольных точках: </h2>
            {hasNewItems && <div className="error">Данные не будут сохранены пока вы не установите время по каждой остановке</div>}
            {scheduleCheckPoints.length > 0
                ? (<List className="list">
                    <ListItem dense>
                        <ListItemText className={styles.item_text} disableTypography>
                            <span style={{ minWidth: 20 }}><strong>{titles.NUM}: </strong></span>
                            <span style={{ wordBreak: 'break-word' }} className={styles.item}><strong>{titles.NAME}: </strong></span>
                            <span className={styles.item}><strong>Время прибытия: </strong></span>
                            <span className={styles.item}><strong>Время убытия: </strong></span>
                            <span className={styles.item}><strong>{titles.MILEAGE_FROM_STATION}: </strong></span>
                        </ListItemText>
                        <IconButton />
                    </ListItem>
                    {scheduleCheckPoints.map((item, index) => (
                        <StationItem
                            key={item.id || item.check_point_id}
                            item={item}
                            index={index}
                            setOpenEditStation={() => setOpenEditStation(true)}
                        />
                    ))}
                </List>)
                : (<div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
            <FormButtons
                positionLeft
                buttons={[
                    {
                        ...buttonsTypes.addDeleteStation,
                        ...(hasCurrentRoute && { name: 'Редактировать остановки/КТ' }),
                        onClick: () => setOpenAddStation(true)
                    },
                    {
                        ...buttonsTypes.addStation,
                        name: 'Редактировать время',
                        onClick: () => setOpenEditStation(true),
                        disabled: scheduleCheckPoints.length === 0
                    }
                ]}
            />
        </>);
};
export default Stations;

