import React, { useEffect } from 'react';
import ConfirmDelete from '../../../common/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../helpers/constants/messages';
import titles from '../../../../helpers/constants/titles';
// import EditorForm from '../../../pages/Dictionaries/Efficiency/RoadSections/EditorForm';
import * as actions from '../../../../redux/Parking/actions';
import { parkingSelectors } from '../../../../redux/Parking';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();
    const deletedForm = useSelector(parkingSelectors.deleteForm);
    const editedForm = useSelector(parkingSelectors.editForm);
    const saved = useSelector(parkingSelectors.saved);

    useEffect(() => {
        if (saved) {
            dispatch(actions.setSaved(false));
        }
    }, [dispatch, saved]);

    return (
        <>
            {/* форма редактирования */}
            {/*{editedForm && (*/}
            {/*    <EditorForm*/}
            {/*        isOpen={!!editedForm}*/}
            {/*        noPadding*/}
            {/*        title={*/}
            {/*            editedForm?.id*/}
            {/*                ? titles.EDIT*/}
            {/*                : titles.ADD*/}
            {/*        }*/}
            {/*        isNew={!editedForm?.id}*/}
            {/*        dataForm={editedForm}*/}
            {/*        onClose={() => {*/}
            {/*            dispatch(actions.setEditForm());*/}
            {/*        }}*/}
            {/*        onSuccess={(newFields) => {*/}
            {/*            if (!newFields['id']) {*/}
            {/*                dispatch(actions.createPark(newFields, () => {*/}
            {/*                    dispatch(actions.setSaved(true));*/}
            {/*                    dispatch(actions.setEditForm());*/}
            {/*                }));*/}
            {/*            } else {*/}
            {/*                dispatch(actions.editPark(newFields.id, newFields, () => {*/}
            {/*                    dispatch(actions.setSaved(true));*/}
            {/*                    dispatch(actions.setEditForm());*/}
            {/*                }));*/}
            {/*            }*/}
            {/*        }}*/}
            {/*        inModal*/}
            {/*    />*/}
            {/*)}*/}

            {/* удаление */}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!deletedForm}
                onSuccess={() => {
                    dispatch(actions.deletePark(deletedForm, () => {
                        dispatch(actions.setSaved(true));
                    }));
                    dispatch(actions.setDeleteForm());
                }}
                onClose={() => {
                    dispatch(actions.setDeleteForm());
                }}
            />
        </>
    );
};

export default CollectorForms;
