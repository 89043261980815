import { Autocomplete, FormControl, TextField } from '@mui/material';
import React, { useState } from 'react';
import messages from '../../../../helpers/constants/messages';
import titles from '../../../../helpers/constants/titles';
import SelectCompany from '../../../common/Autocomplete/Companies';
import SelectUsers from '../../../common/Autocomplete/Users';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';

const Filter = ({ setParams, statuses }) => {
    
    const initialState = {
        name: '',
        status_list: [],
        organization_id_list: [],
        appointed_to_list: [],
    };

    const [filterValues, setFilterValues] = useState(initialState);
    const [isFiltered, setIsFiltered] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilterValues({
            ...filterValues,
            [name]: value
        });
    };

    const handleSearch = () => {
        setIsFiltered(true);
        setParams({
            ...filterValues,
            status_list: filterValues.status_list?.map(({ id }) => id),
            organization_id_list: filterValues?.organization_id_list?.id 
                ? [filterValues?.organization_id_list?.id]
                : [],
            appointed_to_list: filterValues?.appointed_to_list?.map(({ id }) => id)
        });
    };

    const resetFilter = () => {
        setFilterValues(initialState);
        if (isFiltered) {
            setParams({});
            setIsFiltered(false);
        }
    };

    const handleClickEnter = (e) => {
        if (e?.key === 'Enter') handleSearch();
    };

    const isDisabled = !filterValues.name 
        && !filterValues.status_list.length 
        && !filterValues.organization_id_list.id 
        && !filterValues.appointed_to_list.length;

    return (
        <>
            <div className="filter">
                <div className="filter__wrap filter__wrap__more__field">
                    <TextField
                        value={filterValues?.name}
                        onChange={handleChange}
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        name="name"
                        type="text"
                        onKeyPress={handleClickEnter}
                    />
                    <FormControl variant="outlined" size="small">
                        <Autocomplete
                            multiple
                            value={filterValues.status_list || []}
                            options={Object.keys(statuses)?.map(id => ({ id, label: statuses[id] }))}
                            filterSelectedOptions={true}
                            getOptionLabel={(option) => option.label || ''}
                            noOptionsText={messages.NO_DATA}
                            size="small"
                            onChange={(e, newValues) => setFilterValues({
                                ...filterValues,
                                status_list: newValues
                            })}
                            clearText="Очистить"
                            openText="Открыть"
                            closeText="Закрыть"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                    label="Статус"
                                />
                            )}
                        />
                    </FormControl>
                    <SelectCompany
                        selected={filterValues.organization_id_list}
                        onChange={(value) => setFilterValues({ ...filterValues, organization_id_list: value })}
                        label="Организация"
                        filter={{ withDeleted: 1 }}
                        selectDeletedСompanies
                    />
                    <SelectUsers
                        multiple
                        disabled={!filterValues.organization_id_list.id}
                        selected={filterValues.appointed_to_list}
                        onChange={(value) => setFilterValues({ ...filterValues, appointed_to_list: value })}
                        label="Исполнитель"
                        filter={{ org_ids: [filterValues?.organization_id_list?.id] }}
                    />
                </div>
                <div className="filter__wrap__btn filter__btn__column">
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.resetFilter,
                                onClick: resetFilter,
                            },
                            {
                                ...buttonsTypes.search,
                                onClick: handleSearch,
                                disabled: isDisabled
                            }
                        ]}
                    />
                </div>    
            </div>
        </>
    );
};

export default Filter;
