import React from 'react';
import {
    TextField,
} from '@mui/material/';

const RenderInput = ({ field, validation, onChange, value }) => {
    const { required, label, type, disabled, name } = field;

    return (
        <TextField
            className="row__item"
            variant="outlined"
            size="small"
            type={type}
            value={value}
            name={name}
            onChange={onChange}
            error={!!validation}
            disabled={disabled}
            label={label}
            helperText={validation}
            required={required}
        />
    );
};

export default RenderInput;