import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { deleteGovernmentContract, loadGovernmentContractHistory } from 'redux/GovernmentContracts/actions';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { getHumanDate } from 'helpers/date.config';
import CommentAction from 'components/common/CommentAction';
import HistoryList from 'components/common/HistoryList';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';

import ModalForm from './ModalForm';

import type { HistoryInfoTypes, ItemPropTypes } from './types';


const Item = ({ item, reloadList, foundCompanies, contractId, focus, onClickItem, selectedId, checkContract }: ItemPropTypes) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    const buttonsRender = () => (<>
        <IconButton size="small" onClick={() => onClickItem(item)}>
            {selectedId
                ? <ArrowBackIosIcon fontSize="inherit"/>
                : <ArrowForwardIosIcon fontSize="inherit"/>
            }
        </IconButton>
    </>);

    const HistoryInfo = ({ item }: HistoryInfoTypes) => 
        <div>
            {item?.new_values?.name && <>
                <h3>Название ГК: </h3>
                <div><i>Новое значение </i>- {item?.new_values?.name || messages.NO_DATA}</div>
                <div><i>Старое значение </i>- {item?.old_values?.name || messages.NO_DATA}</div>
            </>}
            {item?.new_values?.number && <>
                <h3>Номер ГК: </h3>
                <div><i>Новое значение </i>- { item?.new_values?.number || messages.NO_DATA }</div>
                <div><i>Старое значение </i>- { item?.old_values?.number || messages.NO_DATA }</div>
            </>}
            {(typeof item?.new_values?.is_forever === 'boolean') && <>
                <h3>Бессрочно: </h3>
                <div><i>Новое значение </i>- { item?.new_values?.is_forever ? 'Да' : 'Нет' }</div>
                <div><i>Старое значение </i>- { item?.old_values?.is_forever ? 'Да' : 'Нет' }</div>
            </>}
            {(typeof item?.new_values?.is_deleted === 'boolean') && <>
                <h3>Удален: </h3>
                <div><i>Новое значение </i>- { item?.new_values?.is_deleted ? 'Да' : 'Нет' }</div>
                <div><i>Старое значение </i>- { item?.old_values?.is_deleted ? 'Да' : 'Нет' }</div>
            </>}
            {item?.new_values?._comment && <>
                <div>
                    {item?.event === 'deleted' ? 'Причина удаления' : 'Причина редактирования'}
                    : { item?.new_values?._comment || messages.NO_DATA }
                </div>
            </>}
        </div>; 

    return (
        <>
            
            <LSContentItem
                isMobile={checkContract}
                // @ts-ignore
                needFocus={focus === item.id}
                style={{
                    cursor: 'pointer',
                    backgroundColor: selectedId ? '#0000001f' : ''
                }}
                onClick={() => onClickItem(item)}
            >

                <LSContentColumn>{item.number || messages.NO_DATA}</LSContentColumn>
                <LSContentColumn>{item.name || messages.NO_DATA}</LSContentColumn>
                <LSContentColumn>{item.started_at ? getHumanDate(item.started_at) : messages.NO_DATA}</LSContentColumn>
                <LSContentColumn>{item.ended_at ? getHumanDate(item.ended_at) : messages.NO_DATA}</LSContentColumn>
                <LSContentColumn>{foundCompanies?.[item.customer]?.title || messages.NO_DATA}</LSContentColumn>
                <LSContentColumn>{foundCompanies?.[item.contractor]?.title || messages.NO_DATA}</LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <i className="fas fa-history"/>,
                                name: titles.HISTORY_OF_CHANGES,
                                onClick: () => setOpenHistoryModal(true),
                            },
                            {
                                icon: <Edit fontSize="inherit"/>,
                                name: titles.EDIT,
                                onClick: () => setOpenEditModal(true),
                                disabled: (item.is_deleted && contractId) || item?.external_id
                            },
                            {
                                icon: <Delete fontSize="inherit"/>,
                                name: titles.DELETE,
                                onClick: () => setOpenDeleteModal(true),
                                disabled: item?.external_id
                            }
                        ]}
                    />
                    {buttonsRender()}
                </LSContentColumn>
            </LSContentItem>
            {openEditModal && (
                <ModalForm
                    item={item}
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    reloadList={reloadList}
                    foundCompanies={foundCompanies}
                />
            )}

            {openDeleteModal && (
                <CommentAction
                    title="Удаление ГК"
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onAccept={(_comment: string) => {
                        dispatch(deleteGovernmentContract(item.id, { _comment }, reloadList));
                    }}
                />
            )}
            
            {openHistoryModal && (
                <Modal
                    isOpen={openHistoryModal}
                    title={titles.HISTORY_OF_CHANGES}
                    onClose={() => setOpenHistoryModal(false)}
                    noPadding={true}
                    buttons={<FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: () => setOpenHistoryModal(false)
                            }
                        ]}
                    />}
                >      
                    <div className="modal__form">
                        <HistoryList
                            storeName="govermentContracts"
                            storeProp="governmentContractHistoryList"
                            loadingProp="governmentContractLoading"
                            loadAction={loadGovernmentContractHistory}
                            id={item.id}
                            ItemInfo={HistoryInfo}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default Item;