import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteDanger } from 'redux/SituationalPlans/actions';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';

import DangerModal from './DangerModal';

const DangerItem = ({ el, reloadList }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const onDelete = () => {
        dispatch(deleteDanger(el.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    return (
        <>

            <LSContentItem>
                <LSContentColumn>
                    {el.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openEditModal 
              && <DangerModal
                  el={el}
                  isNew={false}
                  isOpen={openEditModal}
                  onClose={() => setOpenEditModal(false)}
                  reloadList={reloadList}
              />
            }
            {openDeleteModal
              && <ConfirmDelete
                  message={messages.CONFIRM_DELETE}
                  open={openDeleteModal}
                  onSuccess={onDelete}
                  onClose={() => setOpenDeleteModal(false)}
              />
            }
        </>
    );
};

export default DangerItem;
