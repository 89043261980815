import React, { useEffect, useState } from 'react';
import WorksByGroupSelect from '../../../../common/Autocomplete/RoadWorks/WorksByGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip, Grid } from '@mui/material';
import titles from '../../../../../helpers/constants/titles';
import styles from './styles.module.scss';

const Item = ({ item, onChangeKdmList, onClickDelete }) => {

    const initState = {
        group: { id: item.group_id, name: item.group_name },
        work: { id: item.work_id, name: item.work_name },
        units: { id: item.unit_id, short_name: item.unit_name },
        disabled: item.disabled
    };

    const [data, setData] = useState(initState);

    const onChangeData = (value) => {
        setData(value);

        const newItem = {
            disabled: false,
            weather: value.group.id === 16,
            group_id: value.group.id,
            group_name: value.group.name,
            unit_id: value.units.id || '',
            unit_name: value.units.short_name || '',
            work_id: value.work.id || '',
            work_name:  value.work.name || '',
        };

        onChangeKdmList(newItem);
    };

    const handleClickDelete = () => onClickDelete();

    useEffect(()=> {
        setData(initState);
    }, [item]);

    return (
        <Grid container >
            <Grid item xs={11}>
                <div className="form" style={{ padding: 0 }}>
                    <WorksByGroupSelect
                        selectedGroup={data.group} 
                        selectedWorks={data.work}
                        selectedUnits={data.units}
                        onChange={onChangeData}
                        disabledGroup={data.disabled}
                    />
                </div>
            </Grid>
            <Grid item xs={1}>
                <div className={styles.item_delete}>
                    <Tooltip title={titles.DELETE}>
                        <span>
                            <IconButton onClick={handleClickDelete} disabled={data.disabled}>
                                <DeleteIcon fontSize={'inherit'} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            </Grid>
        </Grid>
    );
};

export default Item;