import React, { useMemo, useState } from 'react';
import {
    Checkbox,
    FormControlLabel,
    Typography
} from '@mui/material';
import _ from 'lodash';
import { v4 as uuid_v4 } from 'uuid';

import { loadScheduledReportTemplate } from 'redux/Reports/actions';
import { reportSelectors } from 'redux/Reports';
import { useStoreFromSelector } from 'helpers/hooks';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import UniversalSelect from 'components/common/UniversalSelect';


const ModalForm = ({
    selected, // template_id
    onChange = () => {},
    required = false,
    multiple = false,
    error = false,
    helperText = null,
    label = 'Отчет',
    disabled = false,
}) => {

    // шаблоны
    const templates = useStoreFromSelector(
        loadScheduledReportTemplate,
        reportSelectors.scheduledReportTemplate
    );
    const templatesById = useMemo(() => {
        const listArr = Object.values(templates)?.flat?.() || [];
        return listArr.reduce((r, i) => {
            r[i.template_id] = i;
            return r;
        }, {});
    }, [templates]);

    const [query, setQuery] = useState('');
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(multiple ? [] : {});
    const selectedItemsIds = useMemo(() => multiple
        ? selectedItems?.map(i => i.template_id)
        : [selectedItems?.template_id] || []
    , [multiple, selectedItems]);

    const selectedObj = useMemo(() => {
        if (multiple) {
            return selected?.map(id => templatesById?.[id] || {});
        }
        return templatesById?.[selected] || {};
    }, [selected, templatesById, multiple]);


    const handleChange = (data) => {
        if (multiple) {
            const getAllParts = (item) => item.parts ? item.parts.flatMap(getAllParts) : [item];

            const uniqueIds = {};
            const uniqueArr = getAllParts(data).filter(({ template_id }) => !uniqueIds[template_id] && (uniqueIds[template_id] = true));

            const existingItems = selectedItems.filter((item) =>
                uniqueArr.some((newItem) => newItem.template_id === item.template_id)
            );

            if (existingItems.length === uniqueArr.length) {
                const newItems = selectedItems.filter((item) => !uniqueArr.some((newItem) => newItem.template_id === item.template_id));
                setSelected(newItems);
            } else {
                const newItems = uniqueArr.filter((newItem) => !selectedItems.some((item) => item.template_id === newItem.template_id));
                setSelected([...selectedItems, ...newItems]);
            }
        } else if (!data?.parts) {
            setSelected(data);
        }
    };

    const handleAChange = (value) => {
        onChange(value?.map(i => i.template_id));
    };

    const fetchList = (params) => {
        const { query } = params;
        setQuery(query);
    };

    const listTree = useMemo(() => {
        return Object.keys(templates).reduce((res, key) => {
            const newId = uuid_v4();
            res.push({
                template_id: newId,
                template_text: key,
                parts: templates[key]
            });
            return res;
        }, []);
    }, [templates]);

    // фильтрованный список
    const filteredListTree = useMemo(() => {
        if (query) {
            const queryStr = query.trim().toLowerCase();

            // рекурсивная функция фильтрации
            const findQuery = (arr) => arr
                .reduce((res, el) => {
                    if (Array.isArray(el.parts)) {
                        el.parts = findQuery(el.parts);
                    }
                    if (
                        el?.template_text?.toLowerCase()?.includes(queryStr)
                        || el.parts?.length > 0
                    ) {
                        res.push(el);
                    }
                    return res;
                }, []);

            return findQuery(_.cloneDeep(listTree));
        }
        return listTree;
    }, [listTree, query]);

    const onClose = () => {
        setSelected(selected);
        setShowList(false);
        setQuery('');
    };

    const handleAccept = (data) => {
        onChange(multiple ? data?.map(i => i.template_id) || [] : data?.template_id || '');
        setQuery('');
        setShowList(false);
    };

    const handleShowList = () => {
        setSelected(selectedObj);
        setShowList(true);
    };

    const renderName = (text) => <Typography variant="body2">{text}</Typography>;

    const handleRenderProps = ({ parts, template_text }, isFirst = true) => {
        const partsChecked = parts && parts.every(el => selectedItemsIds.includes(el.template_id));
        const partsIndeterminate = parts && parts.some(el => selectedItemsIds.includes(el.template_id));

        return Array.isArray(parts)
            ?   <>
                {isFirst
                    ? renderName(template_text)
                    : <FormControlLabel
                        sx={{ marginTop: '1rem' }}
                        label={renderName(template_text)}
                        control={
                            <Checkbox
                                indeterminate={!partsChecked && partsIndeterminate}
                                checked={partsChecked}
                                onChange={() => handleChange({ parts })}
                            />
                        }
                    />
                }

                {parts?.map((item) => (
                    <ul style={{ paddingInlineStart: 20, listStyle: 'none' }} key={`${item.id}-${item.template_id}`}>
                        {item?.parts?.length
                            ? handleRenderProps(item, false)
                            : <FormControlLabel
                                sx={{ marginTop: '1rem' }}
                                label={renderName(item.template_text)}
                                control={
                                    <Checkbox
                                        checked={multiple
                                            ? !!selectedItems?.find(el => el.template_id === item.template_id)
                                            : selectedItems?.template_id === item.template_id
                                        }
                                        onChange={() => handleChange(item)}

                                    />
                                }
                            />}
                    </ul>
                ))}
            </>
            : renderName(template_text);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selectedObj}
                onChange={handleAChange}
                error={error}
                helperText = {helperText}
                required={required}
                inputName="contract_id"
                disabled={disabled}
                onReset={disabled ? null : () => onChange(multiple ? [] : '')}
                renderLabel={(option) => Object.keys(option).length > 0
                    ? `${option?.template_text}`
                    : ''
                }
                limitTags={1}
                label={label}
                onShowList={handleShowList}
                isOpen={showList}
            />

            {showList && (
                <UniversalSelect
                    storeName={'report'}
                    keyProp={'template_id'}
                    fetchList={fetchList}
                    withSearch={true}
                    multiple={multiple}
                    isSelected={true}
                    selected={selectedItems}
                    searchTitle="Найти в тексте"
                    renderProps={handleRenderProps}
                    isOpen={showList}
                    onClose={onClose}
                    onAccept={handleAccept}
                    noPadding
                    maxWidthProp={'md'}
                    onChange={handleChange}
                    arrayInItem={'parts'}
                    renderKey={(el) => el.template_id}
                    title={label}
                    sortedList={filteredListTree}
                />
            )}
        </>
    );
};

export default ModalForm;
