import instance from '../../helpers/axios.config';

import apiUrls from './api.urls';

const ApiEcologyMethods = {
    getProjects: async () => {
        const response = await instance.get(apiUrls.getProjects());
        return response?.data;
    },

    getDeviceTypes: async () => {
        const response = await instance.get(apiUrls.getDeviceTypes());
        return response?.data;
    },

    getDeviceList: async (params) => {
        const response = await instance.get(apiUrls.getDeviceList(), { params: { ...params } } );
        return response?.data;
    },

    getDeviceCompanyLink: async (id) => {
        const response = await instance.get(apiUrls.getDeviceCompanyLink(id));
        return response.data;
    },

    createDeviceCompanyLink: async (id, sendData) => {
        const response = await instance.post(apiUrls.postDeviceCompanyLink(id),
            sendData
            // { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } }
        );
        return response.data;
    },

    deleteDeviceCompanyLink: async (id) => {
        const response = await instance.delete(apiUrls.deleteDeviceCompanyLink(id));
        return response.data;
    },

    getForPolygonDevices: async (polygon) => {
        const response = await instance.post(
            apiUrls.getForPolygonDevices(),
            {
                type_device: 2,
                polygon
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
        return response?.data?.data;
    },

    getDeviceData: async (device_external_id) => {
        const response = await instance.post(apiUrls.getDeviceData(), {
            ids: [device_external_id],
            // type_device: 2
        });
        return response?.data?.data;
    },

    getEvents: async (external_id, page, limit) => {
        const response = await instance.get(apiUrls.getEvents(), {
            params: {
                page,
                limit,
                external_id,
                // type_device: 2
            }
        });
        return response?.data;
    },

    getStatuses: async () => {
        const response = await instance.get(apiUrls.getStatuses());
        return response?.data;
    },

    getMeteoSettings: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getMeteoSettings(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    editMeteoSettings: async (id, data) => {
        const response = await instance.put(apiUrls.editMeteoSettings(id), data);
        return response?.data;
    },

    editSettingsList: async (data) => {
        const response = await instance.put(apiUrls.editSettingsList(), data);
        return response?.data;
    },

    getAlerts: async () => {
        const response = await instance.get(apiUrls.getAlerts());
        return response?.data;
    },

    getMeteoOutputParams: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getMeteoOutputParams(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    getMeteoOutputSettings: async () => {
        const response = await instance.get(apiUrls.getMeteoOutputSettings());
        return response?.data;
    },

    editMeteoOutputSettings: async (id, data) => {
        const response = await instance.put(apiUrls.editMeteoOutputSettings(id), data);
        return response?.data;
    },

    deleteMeteoOutputSettings: async (id, data) => {
        const response = await instance.delete(apiUrls.deleteMeteoOutputSettings(id), { data });
        return response?.data;
    },

    getDevicesGroup: async (data) => {
        const response = await instance.get(apiUrls.getDevicesGroup(), {
            params: { data }
        });
        return response?.data;
    },

    createDevicesGroup: async (data) => {
        const response = await instance.post(apiUrls.createDevicesGroup(), data);
        return response?.data;
    },

    editDevicesGroup: async (id, data) => {
        const response = await instance.put(apiUrls.editDevicesGroup(id), data);
        return response?.data;
    },

    deleteDevicesGroup: async (id) => {
        const response = await instance.delete(apiUrls.deleteDevicesGroup(id));
        return response?.data;
    },

    loadShowcase: async () => {
        const response = await instance.post(apiUrls.loadShowcase());
        return response?.data;
    },

    loadMeteoByRadius: async (params) => {
        const response = await instance.post(apiUrls.loadMeteoByRadius(), params);
        return response?.data;
    },

    getSettingsGroup: async () => {
        const response = await instance.get(apiUrls.getSettingsGroup());
        return response?.data;
    },

    editSettingsGroup: async (id, data) => {
        const response = await instance.put(apiUrls.editSettingsGroup(id), data);
        return response?.data;
    },

    getDeviceStatuses: async () => {
        const response = await instance.get(apiUrls.getDeviceStatuses());
        return response?.data;
    },
};

export default ApiEcologyMethods;
