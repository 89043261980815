import { useState } from 'react';
import {
    Badge,
    Grid,
    IconButton,
    Link,
    Tooltip,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { fullDateTime } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import ConfirmDelete from 'components/common/ConfirmModal';

import styles from './styles.module.scss';

const Item = ({ item, onDelete }) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [isActive, setIsActive] = useState('');

    const handleDelete = () => {
        setDeleteModal(false);
        onDelete && onDelete(item?.id);
    };

    return item.files.map(file => {
        let fileUrl;
        try {
            fileUrl = require(`img/icons/file_formats/${file.extension}_128${isActive}.png`);
        } catch (e) {
            fileUrl = require(`img/icons/file_formats/_def_128${isActive}.png`);
        }
        return (
            <Grid key={file.link} item xs={6} sm={4} md={3} xl={2}>

                {deleteModal && (
                    <ConfirmDelete
                        open={deleteModal}
                        onClose={() => setDeleteModal(false)}
                        onSuccess={handleDelete}
                    />
                )}

                <div className={styles.img_wrapper}>
                    <Badge
                        badgeContent={
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setDeleteModal(true);
                                }}
                            >
                                <Tooltip title={titles.DELETE_REPORT}>
                                    <ClearIcon/>
                                </Tooltip>
                            </IconButton>
                        }
                    >
                        <Link href={file.link} target="_blank">
                            <Tooltip title={(
                                <ul className={styles.tooltip_list}>
                                    <li className={styles.tooltip_header}>
                                        {item.header}
                                    </li>
                                    <li>
                                        <b>Модуль: </b> {item?.microservice_text}
                                    </li>
                                    <li>
                                        <b>Статус: </b> {item.status_text}
                                    </li>
                                </ul>
                            )}>
                                {file.extension
                                    ? <img
                                        onMouseEnter={() => setIsActive('_a')}
                                        onMouseLeave={() => setIsActive('')}
                                        src={fileUrl}
                                        alt="icon"
                                    />
                                    : <span>Неизвестный формат</span>
                                }
                            </Tooltip>
                        </Link>
                    </Badge>
                </div>

                <div className={styles.file_description}>
                    <span className={styles.file_name}>
                        {file.name}
                    </span>
                    {<span className={styles.date}>
                        {fullDateTime(item?.updated_at)}
                    </span>}
                </div>

            </Grid>
        );
    });
};

export default Item;
