import { useSelector } from 'react-redux';
import { authSelectors } from '../../redux/Auth';

// хук - витрина
const useShowcase = () => {
    // return false;
    const userInfo = useSelector(authSelectors.authUserInfo || {});
    const isShowcase = userInfo?.is_showcase ?? false;

    return isShowcase;
};

export default useShowcase;
