import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';

import { loadCompanies } from 'redux/Companies/actions';
import { companiesSelectors } from 'redux/Companies';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';

import Item from './Item';
import ModalForm from './ModalForm';
import Filter from './Filter';

const CompaniesComponent = () => {
    const dispatch = useDispatch();

    const initialState = {
        title: '',
        inn: '',
        withDeleted: 0,
        role_ids: []
    };

    const [companiesFormOpen, setCompaniesFormOpen] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: initialState });

    const data = useSelector(companiesSelectors.companiesData);
    const meta = useSelector(companiesSelectors.companiesMeta);
    const loadingCompanies = useSelector(companiesSelectors.loadingCompanies);

    const reloadList = (isDelete) => {
        const page = isDelete && data?.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        const prepareData = removeEmptyFields({
            ...params.data,
            role_ids: params.data?.role_ids?.map(({ id }) => id),
            withDeleted: +params?.data?.withDeleted
        });
        dispatch(loadCompanies(params.page, limit, prepareData));
    }, [dispatch, params, limit]);

    const renderContent = () => {
        return (data?.length > 0
            ? <LSContainer
                headers={[
                    { title: '', width: '50px' },
                    { title: titles.NAME, width: '60%' },
                    { title: 'Действия', isActions: true }
                ]}
            >
                {data?.map((item) => (
                    <Item
                        key={item.id}
                        item={item}
                        reloadList={reloadList}
                    />
                ))}
            </LSContainer>
            : (!loadingCompanies && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <div>
            <PageLayout
                header="Организации"
                filters={<Filter
                    setParams={setParams}
                    initialState={initialState}
                />}
                loading={loadingCompanies}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtons
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setCompaniesFormOpen(true),
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),

                    total: meta?.total
                }}
                actionPanel={
                    <FormControlLabel
                        style={{ pointerEvents: 'none' }}
                        control={
                            <Checkbox
                                checked={!!params.data.withDeleted}
                                onChange={() => setParams(prev => ({ ...prev, data: { ...prev?.data, withDeleted: !prev?.data?.withDeleted } }))}
                                name="withDeleted"
                                color="primary"
                                style={{ pointerEvents: 'auto' }}
                            />
                        }
                        label="Показывать удаленные"
                    />
                }
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />

            {companiesFormOpen && (
                <ModalForm
                    open={companiesFormOpen}
                    setOpenModal={setCompaniesFormOpen}
                    closeModalForm={(status) => setCompaniesFormOpen(status)}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </div>
    );
};

export default CompaniesComponent;
