import React from 'react';
import FileUploader from '../FileUploader';
import { uploadFileToStorage } from '../../../../redux/Upload/actions';
import { FormHelperText, List } from '@mui/material';
import Item from './Item';
import { v4 as uuidv4 } from 'uuid';
import titles from '../../../../helpers/constants/titles';

// загрузка файлов (переписан)
const Attachments = ({
    onChange = (/* array */) => {},
    files = [], // eсли массив файлов
    singleFile = false,
    readOnly = false,
    service='doris-control',
    onlyLink = false, // если нужно вернуть не объект с файлом, а только ссылку
    label = (readOnly ? titles.DOCUMENTS : titles.ADD_DOCUMENTS),
    isRequired = false,
    error = false,
    helperText = '',
    renderContent, // что отрисовать кроме ссылки на документ
    divider = true,
    test_id_prefix = '', // для авто тестирования элементов на разных страницах
}) => {
    const currentFiles = files || [];

    // файл загружен
    const handleFileUpload = (url) => {
        const data = onlyLink ? url.public_link : url;
        singleFile
            ? onChange(data)
            : onChange([ ...currentFiles, data ]);
    };

    // файл удален
    const handleDeleteFile = (indexOfElement) => {
        const newAttachFiles = singleFile
            ? null
            : currentFiles.filter((el, index) => index !== indexOfElement);
        onChange(newAttachFiles);
    };

    return (
        <div>
            {!readOnly
                && (
                    <div className="row align-items-center">
                        <h2
                            style={{ color: (files?.length === 0 && isRequired) || error ? '#f44336' : '' }}
                        >
                            {label}{isRequired && '*'}:
                        </h2>
                        <FileUploader
                            onFileUpload={handleFileUpload}
                            service={service}
                            title={titles.ATTACH_FILE}
                            action={uploadFileToStorage}
                            test_id_prefix={test_id_prefix}
                        />
                    </div>
                )
            }

            {currentFiles?.length > 0
                && (<>
                    {readOnly && <h3>{label}:</h3>}
                    <List className="list">
                        {currentFiles
                            ?.map((file, index) => <Item
                                key={uuidv4()}
                                item={file}
                                index={index}
                                onDelete={handleDeleteFile}
                                readOnly={readOnly}
                                renderContent={renderContent}
                                divider={divider}
                            />)
                        }
                    </List>
                </>)
            }
            {helperText
                && <FormHelperText style={{ color: error ? '#f44336' : '' }}>
                    {helperText}
                </FormHelperText>
            }
        </div>
    );
};

export default Attachments;
