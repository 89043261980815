import React, { useState } from 'react';
import Modal from '../Modal';
import FormButtons, { buttonsTypes } from '../FormButtons';
import { TextField } from '@mui/material';

interface CommentActionProps {
    open: boolean
    onClose: () => void
    onAccept: (comment: string) => void
    title?: string
    inputLabel?: string
    description?: React.ReactElement
    minTextLength?: number
}

const CommentAction: React.FC<CommentActionProps> = ({
    open,
    onClose,
    onAccept,
    title = 'Укажите причину удаления',
    inputLabel = 'Причина удаления',
    description = null,
    minTextLength = 6,
}) => {
    const [comment, setComment] = useState('');

    const onSuccess = () => {
        onAccept(comment);
        onClose();
    };

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            title={title}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose,
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onSuccess,
                            disabled: comment.length < minTextLength,
                        },
                    ]}
                />
            }
        >
            <div className="modal__form">
                <div>{description}</div>

                <TextField
                    label={inputLabel}
                    variant="outlined"
                    className="block"
                    size="small"
                    required
                    value={comment}
                    name="comment"
                    onChange={(e) => setComment(e.target.value)}
                    type="text"
                    multiline
                    InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                    helperText={`Введите не менее ${minTextLength} символов`}
                />
            </div>
        </Modal>
    );
};

export default CommentAction;
