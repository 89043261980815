import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import { delSchedule, fetchScheduleEvents,  } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';
import ConfirmDelete from 'components/common/ConfirmModal';
import {
    ActionMore,
    ActionsButtons,
    LSContentColumn,
    LSContentItem
} from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';
import CircleStatus from 'components/common/CircleStatus';

import ModalForm from './ModalForm';
import StatusForm from './StatusForm';
import Info from './Info';
import PrintTable from './PrintTable';

const renderStatusColor = (status) => {
    const color = {
        1: { color: '#00ae5c' },
        2: { color: '#FF0000' },
        3: { color: '#336633' },
    };
    return color[status];
};

const Item = ({ item = {}, statuses, categories, reloadList }) => {

    const dispatch = useDispatch();

    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isInfo, setIsInfo] = useState(false);
    const [isEditStatus, setIsEditStatus] = useState(false);
    const [isPrintOpen, setOpenPrint] = useState(false);

    const showInfo = () => {
        setIsInfo(!isInfo);
    };

    const setEditMode = (event) => {
        event.stopPropagation();
        setIsEditMode(true);
    };

    const changeStatus = (event) => {
        event.stopPropagation();
        setIsEditStatus(true);
    };

    const deleteElement = (event) => {
        event.stopPropagation();
        dispatch(delSchedule(item?.id, () => {
            reloadList(true);
            setIsDelete(false);
        }));
    };

    const handlePrintOpen = (event) => {
        event.stopPropagation();
        setOpenPrint(true);
    };

    const currentScheduleEvents = useSelector(transportPassengerSelectors.currentScheduleEvents);
    const loadingCurrentScheduleEvents = useSelector(transportPassengerSelectors.loadingCurrentScheduleEvents);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
            @media print {
                .pagebreak {
                    page-break-before: auto;
                }
            }
        `,
        onAfterPrint: () => setOpenPrint(false)
    });

    useEffect(() => {
        if (isPrintOpen) {
            if (currentScheduleEvents[item.id]) {
                handlePrint();
            } else if (!loadingCurrentScheduleEvents) {
                dispatch(fetchScheduleEvents({ id: item.id }));
            }
        }
    }, [isPrintOpen, item.id, dispatch, currentScheduleEvents, handlePrint, loadingCurrentScheduleEvents]);

    const componentRef = useRef();

    const renderTable = () => {
        return (<div ref={componentRef} style={{ padding: '20px' }}>
            <PrintTable item={item} currentScheduleEvents={currentScheduleEvents[item.id]} />
        </div>);
    };

    return (
        <>
            <LSContentItem onClick={showInfo}>
                <LSContentColumn>
                    <CircleStatus
                        title={item?.status_text || statuses[item?.status]}
                        color={renderStatusColor(item?.status)?.color}
                    />
                </LSContentColumn>
                <LSContentColumn>
                    {item?.name}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.num_route || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {(item?.start_at && fullDateTime(item?.start_at)) || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {(item?.end_at && fullDateTime(item?.end_at)) || messages.NO_DATA}
                </LSContentColumn>

                <LSContentColumn align="right" isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: setEditMode,
                                disabled: (item?.status_text.toLowerCase() !== 'проект')
                            },
                            {
                                ...buttonsTypes.printIcon,
                                onClick: handlePrintOpen,
                            },
                            {
                                ...buttonsTypes.changeStatusIcon,
                                onClick: changeStatus,
                            }
                        ]}
                    />
                    <ActionMore
                        isOpen={isInfo}
                        onClick={showInfo}
                    />
                </LSContentColumn>
            </LSContentItem>
            {isEditMode && (
                <ModalForm
                    isOpen={isEditMode}
                    onClose={() => setIsEditMode(false)}
                    isNew={false}
                    item={item}
                    statuses={statuses}
                    categories={categories}
                    reloadList={reloadList}
                />
            )}
            {isEditStatus && (
                <StatusForm
                    isOpen={isEditStatus}
                    onClose={setIsEditStatus}
                    currentStatus={item?.status}
                    statuses={statuses}
                    id={item?.id}
                    reloadList={reloadList}
                />
            )}
            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    onSuccess={deleteElement}
                    message={messages.CONFIRM_DELETE}
                />
            )}
            {isInfo && (
                <Info
                    id={item?.id}
                    isOpen={isInfo}
                    item={item}
                />
            )}
            {isPrintOpen && <div style={{ display: 'none' }}>{renderTable()}</div>}
        </>
    );
};

export default Item;
