import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as transportPassengerActions from 'redux/TransportPassenger/actions';
import * as transportRoadActions from 'redux/TransportRoad/actions';
import { companiesActions } from 'redux/Companies';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';

import Item from './Item';
import Filters from './Filters';
import ModalAddEdit from './ModalAddEdit';

const Brigades = ({ storeName }) => {
    const dispatch = useDispatch();

    const {
        brigades: list,
        brigadesLoading: loading
    } = useSelector((store) => store[storeName]);

    const actionList = {
        transportPassenger: transportPassengerActions,
        transportRoad: transportRoadActions
    };

    const actions = actionList[storeName];

    const [modalOpen, setModalOpen] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [companyList, setCompanyList] = useState([]);

    const reloadList = (isDelete) => {
        isDelete && list?.data?.length === 1 && list?.meta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(actions.loadBrigades(params.page, limit, params.data));
    };

    useEffect(() => {
        dispatch(actions.loadBrigades(params.page, limit, params.data));
    }, [dispatch, limit, params, actions]);

    useEffect(() => {
        const id_list = list.data.reduce((res, item) => {
            if (item.organization_id !== null && item.organization_id !== undefined
                && (!item.organization || Object.keys(item.organization).length === 0)) {
                res.push(item.organization_id);
            }
            return res;
        }, []);
        if (id_list.length > 0) {
            dispatch(companiesActions.loadCompanies(1, 25, { id_list, withDeleted: 1 }, (data) => {
                setCompanyList(data);
            }));
        }
    }, [list, dispatch]);

    const renderContent = () => {
        return (
            <>
                {loading && <Loading circular/>}
                {list?.data?.length > 0
                    ? <LSContainer
                        headers={[
                            { title: '№ Бригады', width: '15%' },
                            { title: titles.NAME, width: '75%' },
                            { title: 'Действия', isActions: true }
                        ]}
                    >
                        {list.data?.map(item =>
                            <Item
                                key={item?.id}
                                item={item}
                                company={(item.organization && Object.keys(item.organization).length > 0)
                                    ? item.organization
                                    : companyList.find(elem => item.organization_id === elem.id) || {}
                                }
                                reloadList={reloadList}
                                actions={actions}
                                loading={loading}
                                storeName={storeName}
                            />
                        )}
                    </LSContainer>
                    : !loading && <div>{messages.DATA_IS_NOT_FOUND}</div>
                }
            </>
        );
    };

    return (
        <>
            <PageLayout
                header="Формирование бригад"
                filters={<Filters setParams={(data) => setParams({ page: 1, data })}/>}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setModalOpen(true),
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: list.meta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: list?.meta,
                    limit,
                    setLimit
                }}
            />

            {modalOpen
                && <ModalAddEdit
                    isNew
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    reloadList={reloadList}
                    actions={actions}
                    loading={loading}
                    storeName={storeName}
                />
            }
        </>
    );
};

export default Brigades;
