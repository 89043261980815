import { FormHelperText } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fullDateTimeWithTimeZone } from '../../../../../helpers/date.config';
import { useValidation } from '../../../../../helpers/hooks';
import { loadVehicleDowntime } from '../../../../../redux/TransportRoad/actions';
import { transportRoadSelectors } from '../../../../../redux/TransportRoad';
import DateRange from '../../../../common/Dates/DateRange';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Loading from '../../../../common/Loading';
import Modal from '../../../../common/Modal';
import DowntimeInfoModal from './DowntimeInfoModal';


const DowntimeModal = ({ isOpen, onClose, item }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        page: 1,
        limit: 10,
        start_date: null,
        end_date: null,
    };

    const vehicleLoading = useSelector(transportRoadSelectors.vehicleLoading);

    const [formData, setFormData] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [openDowntimeInfoModal, setOpenDowntimeInfoModal] = useState(false);

    const handleChange = (value, key) => {
        setFormData({
            ...formData, 
            [key]: value
        });
        
        validation.deleteKey(key);
    };

    const prepareData = {
        ...formData,
        start_date: fullDateTimeWithTimeZone(formData.start_date),
        end_date: fullDateTimeWithTimeZone(formData.end_date)
    };

    const handleSearch = () => {
        dispatch(loadVehicleDowntime(
            item?.id, 
            prepareData, 
            () => setOpenDowntimeInfoModal(true))
        );
    };

    const  isMoreWeek = (formData.end_date - formData.start_date) > 604800000;

    const errorDateRange = () => (
        isMoreWeek
        || !formData.start_date
        || !formData.end_date
        || validation.isKey('start_date')
        || validation.isKey('end_date')
    );

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                title={'Укажите параметры'}
                buttons={
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.cancel,
                                name: 'Отмена',
                                onClick: onClose
                            },
                            {
                                ...buttonsTypes.search,
                                onClick: handleSearch,
                                disabled: isDisabled
                            },
                        ]}
                    />
                }
            >
                {vehicleLoading && <Loading circular/>}

                <div className="row">
                    <DateRange
                        className="row__item"
                        valueStartDate={formData.start_date}
                        valueEndDate={formData.end_date}
                        handleDate={(value) => handleChange(value, 'start_date')}
                        handleEndDate={(value) => handleChange(value, 'end_date')}
                        isDisabledHandler={(error) => setIsDisabled(error)}
                        renderError={errorDateRange}
                        errorMessage={() => validation.get('start_date') || validation.get('end_date')}
                        required
                    />
                </div>
                <FormHelperText error={isMoreWeek}>Максимальный период 1 неделя</FormHelperText>   
            </Modal>
            {openDowntimeInfoModal && (
                <DowntimeInfoModal
                    isOpen={openDowntimeInfoModal}
                    onClose={() => setOpenDowntimeInfoModal(false)}
                    item={item}
                    prepareData={prepareData}
                />
            )}
        </>   
    );
};

export default DowntimeModal;
