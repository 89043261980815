import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    inner: {
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        width: '95%'
    },
    h2: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
});

const ViewTemplate = ({ template }) => {
    const styles = useStyles();

    const wrapper = {
        display: 'grid',
        gridTemplateColumns: `repeat(${template.column},calc(100%/${template.column}))`,
        gridTemplateRows: `repeat(${template.row},calc(100%/${template.row}))`,
        width:  `calc(100%/${template.row/template.column})`,
        height: `calc(100%/${template.column/template.row})`,
        maxHeight: '75%',
        maxWidth: '100%',
        gap: 5,
        margin: 'auto'
    };

    const openInNewWindow = (url) => {
        window.open(url, '_blank').focus();
    };

    return (
        <div style={wrapper} >
            {template.cameras?.map((item) => (
                <div className={styles.inner} key={item.id}>
                    <div className={styles.title}>
                        <h2 
                            className={styles.h2} 
                            title={item.name}
                            onClick={() => openInNewWindow(item.stream_url_full)} 
                        >
                            {item.name}
                        </h2>&nbsp;
                        <i className="far fa-external-link"></i>
                    </div>
                    <iframe 
                        title={item.name}
                        src={item.stream_url}
                        frameBorder="1"
                        allowFullScreen
                        height="100%"
                        width="100%"
                    />
                </div>                        
            ))}
        </div>
    );
};

export default ViewTemplate;
