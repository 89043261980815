import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import Steps from './Steps';
import Map from './Map';
import { fullDateTime, getHumanDate } from '../../../../../../helpers/date.config';
import messages from '../../../../../../helpers/constants/messages';
import RenderValue from './RenderValue';
import styles from '../styles.module.scss';


const Item = ({ data = {}, threatLevels = [] }) => {
    // цвет зеленый, если эта плановый срок меньше либо равен текущей дате,
    const green = new Date(data?.date_plan) <= new Date() && '#4bad50';

    // цвет красный, если плановый срок больше текущей даты, а статус не равен Завершен*
    const red = ((new Date(data?.date_plan) > new Date()) && data?.status !== 4) && '#E02926';

    const planAndStatusColor = data?.date_plan ? (green ? green : red) : null;

    return (
        <Card className={styles.item_card}>
            <CardContent className={styles.card_content}>
                <Typography className={styles.title}>
                    {data?.name || ''}
                </Typography>

                <div className={styles.card_content}>
                    <Grid container spacing={2} className="mb-2">
                        <Grid item xs={8}>
                            <RenderValue
                                title="Уровень угрозы"
                                color={threatLevels.find(({ id }) => id === data?.threat_level?.id)?.color || ''}
                            >
                                {data?.threat_level?.name || messages.NO_DATA}
                            </RenderValue>

                            <RenderValue title="Зарегистрирован">
                                {fullDateTime(data?.registered_at) || messages.NO_DATA}
                            </RenderValue>

                            <RenderValue title="Статус" color={planAndStatusColor}>
                                {data?.status_text || messages.NO_DATA}
                            </RenderValue>

                            <RenderValue title="Плановый срок" color={planAndStatusColor}>
                                {data?.date_plan ? getHumanDate(data?.date_plan) : messages.NO_DATA}
                            </RenderValue>

                            <RenderValue title="Тип события">
                                {data?.type_text || messages.NO_DATA}
                            </RenderValue>
                        </Grid>

                        <Grid item xs={4}>
                            <Map data={data}/>
                        </Grid>

                        <Grid item xs={12}>
                            <RenderValue title="Адрес" width="65px">
                                {data?.address_text || messages.NO_DATA}
                            </RenderValue>

                            <RenderValue
                                title="Описание"
                                width="65px"
                            >
                                <div dangerouslySetInnerHTML={{ __html: data?.description || messages.NO_DATA }} />
                            </RenderValue>
                        </Grid>
                    </Grid>

                    <div style={{ marginTop: '20px' }}>
                        <Steps data={data}/>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default Item;
