import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { getTitle } from './helper';

const Tables = ({
    data,
    currentGroup,
    currentType,
    currentPuids,
    currentOption,
}) => {
    const [itemsToShow, setItemsToShow] = useState(2);
    const [displayKeys, setDisplayKeys] = useState(Object.keys(data).slice(0, itemsToShow));
    const containerRef = useRef(null);

    const loadMoreItems = useCallback(() => {
        setItemsToShow(prevItemsToShow => {
            const newItemsToShow = prevItemsToShow + 2;
            return newItemsToShow > Object.keys(data).length ? prevItemsToShow : newItemsToShow;
        });
    }, [data]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                loadMoreItems();
            }
        }
    }, [loadMoreItems]);

    useEffect(() => {
        setDisplayKeys(Object.keys(data).slice(0, itemsToShow));
    }, [data, itemsToShow]);

    return (
        <>
            <h2 style={{ marginBottom: '1rem' }}>{currentGroup?.description || ''} - {currentType?.description || ''}</h2>

            <div ref={containerRef} onScroll={handleScroll} style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {displayKeys.map(key => {
                    const list = data[key] || [];
                    const puid = currentPuids[key] || {};

                    return (
                        <div key={key} style={{ marginBottom: '2rem' }}>
                            <h3>{getTitle(puid)}</h3>

                            {list.length === 0
                                ? (
                                    <div>
                                        За указанный период нет данных
                                    </div>
                                )
                                : (
                                    <TableContainer style={{ maxWidth: '800px' }}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Дата/время</TableCell>
                                                    <TableCell>Значение</TableCell>
                                                    <TableCell>Единицы измерения</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {list.map((tableItem, tableIndex) => (
                                                    <TableRow key={tableIndex}>
                                                        <TableCell>
                                                            {currentOption?.format(tableItem?.date) || ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            {tableItem?.value}
                                                        </TableCell>
                                                        <TableCell>
                                                            {currentGroup?.unit || ''}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default memo(Tables);
