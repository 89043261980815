import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import * as actions from 'redux/WantedCars/actions';
import PageLayout from 'components/layout/PageLayout';
import ReportBlock from 'components/common/Report/ReportBlock';
import NoData from 'components/common/Information/NoData';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields.js';

import Filter from './Filter.js';
import Map from './Map';
import List from './List';
import Info from './Info';

const initialFilter = {
    start_date: getStartOf('week'), // минус неделя от текущего времени
    end_date: getEndOf(),
    services: [{ id: 'doris-control', name: 'КФВФ' }],
    grz: '',
    // 'M714EE136'
    // 'P065EK190'
    // 'M770PT46'
};

function PassingVehicleCameras() {
    const dispatch = useDispatch();

    const eventWantedTrack = useSelector(({ carsWanted }) => carsWanted.eventWantedTrack);
    const loadingEventWantedTrack = useSelector(({ carsWanted }) => carsWanted.loadingEventWantedTrack);

    const [filter, setFilter] = useState(initialFilter);

    const [selected, setSelected] = useState({});
    const changeSelected = (v) => setSelected(v);

    const onSearch = (newFilter = filter) => {
        const prepareDate = removeEmptyFields({
            ...newFilter,
            services: newFilter.services.map(item => item.id),
            grz: [newFilter.grz],
            start_date: fullDateTimeWithTimeZone(newFilter.start_date),
            end_date: fullDateTimeWithTimeZone(newFilter.end_date),
        });

        dispatch(actions.loadEventWantedTrack(prepareDate));
    };

    const data = useMemo(() => {
        return Object.entries(eventWantedTrack).reduce((res, [tsNumber, val]) => res
            .concat(
                val?.data?.map((i, index) => ({
                    ...i,
                    id: `${tsNumber}:${index}`,
                    number: tsNumber,
                })),
            )
        , []);
    }, [eventWantedTrack]);

    useEffect(() => {
        if (data?.length > 0) {
            setSelected(data?.[0] || {});
        } else {
            setSelected({});
        }
    }, [data]);

    const getReport = (formats) => { 
        onSearch({ ...filter, formats, report: 48 });
    };

    const renderContent = () => {
        return data?.length > 0
            ?  <>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    style={{ marginBottom: '1rem' }}
                >
                    <Grid item xs={12} sm={6}>
                        <Map
                            list={data}
                            selected={selected}
                            onSelected={changeSelected}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <List
                            list={data}
                            selected={selected}
                            onSelected={changeSelected}
                        />
                    </Grid>
                </Grid>

                <Info data={selected}/>
            </>
            : !loadingEventWantedTrack && <NoData/>;
    };

    return (
        <PageLayout
            header="Прохождение ТС по камерам"
            filters={
                <Filter
                    filter={filter} 
                    setFilter={setFilter} 
                    onSearch={onSearch}
                />
            }
            loading={loadingEventWantedTrack}
            informPanelProps={{ 
                buttons: (
                    <ReportBlock
                        onGetReport={getReport} 
                        disabled={!filter.start_date || !filter.end_date || !filter.grz} 
                    />
                )
            }}

            content={renderContent}

            // customStyles={{ padding: 0 }}
        />
    );
}

export default PassingVehicleCameras;
