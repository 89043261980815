import { config } from 'config';

const apiMetroUrl = () => config.get('urls').apiMetroUrl;

const apiUrls = {
    getStationStatuses: () => `${apiMetroUrl()}/station/statuses`,

    loadStationList: () => `${apiMetroUrl()}/station`,

    loadVestibulePolygon: () => `${apiMetroUrl()}/vestibule/polygon`,
};

export default apiUrls;