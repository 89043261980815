import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Modal from '../Modal';
import ConfirmDelete from '../../../../../common/ConfirmModal';
import { editTransorganization } from '../../../../../../redux/TransportPassenger/actions';
import { useDispatch } from 'react-redux';
import removeEmptyFields from '../../../../../../helpers/removeEmptyFields';
import { ActionsButtons, LSContentColumn, LSContentItem } from '../../../../../common/List';
import titles from '../../../../../../helpers/constants/titles';
import messages from '../../../../../../helpers/constants/messages/common';

const Item = ({ item, callback, permissions }) => {
    const dispatch = useDispatch();

    const [openAddModal, setOpenAddModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openRemoveModal, setOpenRemoveModal] = useState(false);

    const handleRemoveOrganization = () => {
        const prepareData = removeEmptyFields({
            project: item?.project,
            trans_name: item?.trans_name,
            external_id: item?.external_id,
            organization_id: null,
            title: null
        });

        dispatch(editTransorganization(item?.id, prepareData, () => {
            setOpenRemoveModal(false);
            callback();
        }));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item?.external_id || '-'}
                </LSContentColumn>
                
                <LSContentColumn>
                    {item?.trans_name  || messages.NO_DATA}
                </LSContentColumn>
                
                <LSContentColumn>
                    {item?.organization_id  || '-'}
                </LSContentColumn>
                
                <LSContentColumn>
                    {item?.title  || messages.NO_DATA}
                </LSContentColumn>
                
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <LinkIcon fontSize="inherit"/>,
                                name: 'Привязать организацию',
                                onClick: () => setOpenAddModal(true),
                                disabled: !!item?.organization_id || !permissions.is_update
                            },
                            {
                                icon: <EditIcon fontSize="inherit"/>,
                                name: 'Изменить связь',
                                onClick: () => setOpenEditModal(true),
                                disabled: !item?.organization_id || !permissions.is_update
                            },
                            {
                                icon: <LinkOffIcon fontSize="inherit"/>,
                                name: 'Отвязать организацию',
                                onClick: () => setOpenRemoveModal(true),
                                disabled: !item?.organization_id || !permissions.is_update
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openAddModal && (
                <Modal
                    open={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    title="Привязать организацию"
                    item={item}
                    callback={() => {
                        setOpenAddModal(false);
                        callback();
                    }}
                    isNew
                />
            )}
            {openEditModal && (
                <Modal
                    open={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    title="Изменить связь"
                    item={item}
                    callback={() => {
                        setOpenEditModal(false);
                        callback();
                    }}
                />
            )}
            {openRemoveModal && (
                <ConfirmDelete
                    open={openRemoveModal}
                    onClose={() => setOpenRemoveModal(false)}
                    onSuccess={handleRemoveOrganization}
                    message="Отвязать организацию?"
                />
            )}
        </>
    );
};

export default Item;
