import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { deleteElectricalOpticalSystems } from 'redux/RoadNetwork/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';
import messages from 'helpers/constants/messages';
import ModalForm from './ModalForm';
import type { ElectricalOpticalSystemItem } from 'types';
interface ItemProps {
    el: ElectricalOpticalSystemItem;
    reloadList: () => void;
}

const Item = ({ el, reloadList }: ItemProps) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    const handleConfirmDelete = useCallback(() => {
        dispatch(deleteElectricalOpticalSystems(el.id, () => {
            reloadList();
            setOpenDeleteModal(false);
        }));
    }, [dispatch, el.id, reloadList]);

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {el.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {el.type || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {el.address_text || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openEditModal && (
                <ModalForm
                    isNew={false}
                    onClose={() => setOpenEditModal(false)}
                    isOpen={openEditModal}
                    item={el}
                    reloadList={reloadList}
                />
            )}

            {openDeleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteModal}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default React.memo(Item);
