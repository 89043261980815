import { config } from '../../config';

const apiTrafficLightsUrl = () => config.get('urls').apiTrafficLightsUrl;

const ApiUrls = {
    //Получение списка светофоров
    getTrafficLightsList: () => `${apiTrafficLightsUrl()}/traffic_light`,
    //Получение статусов светофора
    getTrafficLightsStatuses: () => `${apiTrafficLightsUrl()}/traffic_light/statuses`,
    //Добавление светофора
    postTrafficLight: () => `${apiTrafficLightsUrl()}/traffic_light/`,
    //Редактирование светофора
    editTrafficLight: (id) => `${apiTrafficLightsUrl()}/traffic_light/${id}`,
    //Удаление светофора
    deleteTrafficLights: (id) => `${apiTrafficLightsUrl()}/traffic_light/${id}`,
    //Получение состояний светофора
    getTrafficLightStates: () => `${apiTrafficLightsUrl()}/light_state`,
    //Получение типов контроля светофора
    getTrafficLightControlTypes: () => `${apiTrafficLightsUrl()}/control_type`,
    // светофоры для карты
    getPolygon: () => `${apiTrafficLightsUrl()}/traffic_light/polygon`,
    // список светофоров для сайдбара
    getSidebarList: () => `${apiTrafficLightsUrl()}/traffic_light`,

    getCoordinationGroups: () => `${apiTrafficLightsUrl()}/coordination_groups`,
    getCoordinationGroupsPolygon: () => `${apiTrafficLightsUrl()}/coordination_groups/polygon`,

    getGreenStreets: () => `${apiTrafficLightsUrl()}/green_streets`,

    getGreenStreetsPolygon: () => `${apiTrafficLightsUrl()}/green_streets/polygon`,

    getColorsForLegends: () => `${apiTrafficLightsUrl()}/traffic_light/colors-for-legend`,

    loadShowcase: () => `${apiTrafficLightsUrl()}/showcase`,

    loadTrafficLightByRadius: () => `${apiTrafficLightsUrl()}/traffic_light/radius`,

    editSettingsList: () => `${apiTrafficLightsUrl()}/settings/list`,

    getSettingsGroup: () => `${apiTrafficLightsUrl()}/settings_group`,

    editSettingsGroup: (id) => `${apiTrafficLightsUrl()}/settings_group/${id}/settings/update`,

};

export default ApiUrls;
