import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { loadChecklists } from 'redux/DorisControl/actions';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';
import UniversalSelect from 'components/common/UniversalSelect';

export interface Item {
    name: string;
    id: number,
    title?: string,
}

interface SelectCheckListProps<Item> extends CommonAutocompleteProps<Item> {
    filter?: Record<string, string>;
}

const SelectCheckList = ({
    multiple = false,
    selected,
    onChange,
    required = false,
    disabled = false,
    label = 'Пункты чек-листов',
    error = false,
    helperText = '',
    filter = {},
    renderLabel = (option: Item | null) => option ? option.name : '',
    limitTags = 1,
}: SelectCheckListProps<Item>): React.ReactElement => {
    const dispatch = useDispatch();
    const prevData = useRef<typeof selected>(selected);
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState<typeof selected>(selected);

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected]);

    const getData = (params: { page: number; limit: number; query?: string }) => {
        const { page, limit, query: name } = params;

        dispatch(loadChecklists({
            page, 
            limit,
            params: {
                ...(name && { name }),
                ...filter,
            }
        }));
    };

    const handleAccept = (data: typeof selected) => {
        onChange(data);
        setShowList(false);
    };

    const onReset = () => {
        const initialState = multiple ? [] : '';
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete<Item>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                disabled={disabled}
                onReset={onReset}
                renderLabel={renderLabel}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
                isOpen={showList}
            />
            {showList && (
                <UniversalSelect
                    multiple={multiple}
                    fetchList={getData}
                    storeName="dorisControl"
                    storeNameProps="checklists"
                    storeLoadingProps="loadingChecklist"
                    keyProp="name"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => (
                        <div>
                            {el.name}
                        </div>
                    )}
                    isOpen={showList}
                    onClose={() => setShowList(false)}
                    onAccept={handleAccept}
                    noPadding
                    title="Чек-листы"
                />
            )}
        </>
    );
};

export default SelectCheckList;
