import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemText, } from '@mui/material';
import Chip from '@mui/material/Chip';

import { deleteEventObjects } from 'redux/SituationalPlans/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';

const Item = ({ item, types, reloadList }) => {
    const dispatch = useDispatch();

    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        dispatch(
            deleteEventObjects(item?.id, () => {
                reloadList(true);
                setIsDelete(false);
            })
        );
    };

    return (
        <div className="list_item">
            <ListItem>
                <ListItemText>
                    <div>
                        <div>{item?.name}</div>

                        <div>
                            <strong>{titles.EVENT}:</strong> {item?.event?.name || messages.NO_DATA}
                        </div>

                        <div>
                            <strong>{titles.TYPE_EVENTS}: </strong>
                            {item?.type_name || messages.NO_DATA}
                        </div>

                        <div>
                            <strong>{titles.KEYWORDS}: </strong>
                            {item?.keyword_list?.length > 0
                                ? item?.keyword_list?.map((word) => (
                                    <Chip key={word.id} label={word?.name} size={'small'} />
                                ))
                                : messages.NO_DATA}
                        </div>
                    </div>
                </ListItemText>
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setIsEditMode(true),
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setIsDelete(true),
                        },
                    ]}
                    noMarginLeft
                    justButton
                />
            </ListItem>

            {isEditMode && (
                <ModalForm
                    isOpen={isEditMode}
                    onClose={() => setIsEditMode(false)}
                    isNew={false}
                    item={item}
                    types={types}
                    reloadList={reloadList}
                />
            )}

            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                />
            )}
        </div>
    );
};

export default Item;
