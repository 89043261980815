import { TextField } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { createMaterialStorageGroup, editMaterialStorageGroup } from 'redux/DorisControl/actions';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import InputNumber from 'components/common/Inputs/InputNumber';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const ModalForm = ({ isNew, isOpen, onClose, item, loadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initState = {
        name: item?.name || '',
        violation_file_lifetime_days: item?.violation_file_lifetime_days || '',
        passage_file_lifetime_days: item?.passage_file_lifetime_days || '',
        is_default: item?.is_default || false,
        complex_id_list: item?.complex_id_list || [],
        complexes: item?.complexes || []
    };

    const [data, setData] = useState(initState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSave = () => {
        const prepareData = {
            ...data,
            complex_id_list: data.complexes.map(({ id }) => id)
        };

        const callback = () => {
            onClose();
            loadList();
        };

        isNew
            ? dispatch(createMaterialStorageGroup(prepareData, callback))
            : dispatch(editMaterialStorageGroup(item.id, prepareData, callback));
    };

    const isDisabled = false;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            noPadding
            small
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                >
                    <TextField
                        value={data.name}
                        onChange={handleChange}
                        label={titles.TITLE}
                        required
                        name="name"
                        error={validation.isKey('name')}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('violation_file_lifetime_days')}
                    helperText={validation.get('violation_file_lifetime_days')}
                >
                    <InputNumber
                        value={data.violation_file_lifetime_days}
                        label="Время хранения (с нарушениями)"
                        required
                        onChange={handleChange}
                        name="violation_file_lifetime_days"
                        error={validation.isKey('violation_file_lifetime_days')}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                >
                    <InputNumber
                        value={data.passage_file_lifetime_days}
                        label="Время хранения (с проездами)"
                        required
                        onChange={handleChange}
                        name="passage_file_lifetime_days"
                        error={validation.isKey('passage_file_lifetime_days')}
                    />
                </FormInfoWrapper>

                <SelectComplex
                    multiple
                    selected={data.complexes}
                    disabled={data.is_default}
                    onChange={(complexes) => setData({ ...data, complexes })}
                    searchKey="type_id"
                    searchTitle="Найти по id типа комплекса"
                    filter={{ without_material_group: true }}
                    className="block"
                    error={validation.isKey('complexes')}
                    helperText={validation.get('complexes')}
                />
            </form>
        </Modal>
    );
};

export default ModalForm;
