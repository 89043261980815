import { useDispatch } from 'react-redux';
import audioPlay from '../../audioPleer/audioPlay';
import { showMessage } from '../../../redux/Message/actions';
import {
    changeReadNotification,
    loadCountActiveNotification,
    wsReloadNotification
} from '../../../redux/Notifications/actions';
import { useHistory } from 'react-router-dom';
import { addActiveLayer, setSelectedLayer } from '../../../redux/Menu/actions';
import { wsSetActive } from '../../../redux/WS/actions';
// import messageConfig from '../config/message';
import { useActionTime } from '../../../helpers/hooks';


const useWsAlerts = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // прочитать уведомление
    const handleChangeRead = (id) => {
        if (id) { 
            dispatch(changeReadNotification(id));
        }
    };

    // обновить список
    const handleRefreshCountDebounce = () => {
        dispatch(loadCountActiveNotification());
        dispatch(wsReloadNotification(true));
    };

    const handleRefreshCount = useActionTime(handleRefreshCountDebounce, 30000);

    // обработка клика
    const handleCallBack = (notifyId, link, viewed) => {
        if (notifyId && !viewed) handleChangeRead(notifyId);

        const {
            url = null,
            activeLayer = null,
            isDownload = false,
            active = {},
        } = link || {};

        const urlParams = link?.urlParams || {};

        if (!url) return;

        // скачивание
        if (isDownload) {
            // url для перехода
            const currentUrl = url?.includes('http:/')
                ? url
                : `${window?.location?.origin}${url[0] === '/' ? '' : '/'}${url}`;
            window.open(currentUrl, '_blank');
        } else {
            // для слоя
            if (activeLayer) {
                urlParams['layers[]'] = activeLayer;
            }

            const search = Object
                .keys(urlParams)
                .map((key) => `${key}=${urlParams[key]}`)
                .join('&');

            history.push({
                pathname: url,
                search
            });

            // для слоя
            if (activeLayer) {
                dispatch(addActiveLayer(activeLayer));
                setTimeout(() => {
                    dispatch(setSelectedLayer(activeLayer));
                }, 500);
            }

            // установка активного объекта
            if (active) {
                dispatch(wsSetActive(active || {}));
            }
        }
    };

    // возвращаемая функция
    const mainFunction = (events, channel) => {
        // если прописали для отдельных каналов функцию формирования сообщения
        // const messageFunction = (active, message) => messageConfig?.[channel]?.(active) || message;

        if (events?.length > 0) {
            handleRefreshCount();
        }
        events?.forEach?.((event) => {
            const {
                id = 0,
                playSound = false,
                showAlert = null,
                link = null,
                viewed = false,
                // active = {},
            } = event || {};

            // отобразить всплывашку (для отключения в канале но отображения в колокольчике)
            const echo = showAlert?.echo ?? true;

            if (
                showAlert // объект вывода
                && echo // можем выводить
            ) {
                // воспроизведение звука (если выводим сообщение)
                playSound && audioPlay(playSound).play();

                const {
                    type,
                    title,
                    message,
                    isHtml = false,
                    timeout = 6000,
                } = showAlert;

                // заглушка для создания html
                // const alertMessage = messageFunction(active, message);

                dispatch(
                    showMessage(
                        type,
                        title,
                        isHtml
                            ? <span dangerouslySetInnerHTML={{ __html: message }}></span>
                            : message,
                        () => handleCallBack(id, link, viewed),
                        timeout
                    )
                );

                // handleRefreshCount();
            }
        });
    };

    // обработка клика в списке нотификаций
    mainFunction.onClick = handleCallBack;

    // обновление количества
    mainFunction.onRefreshCount = handleRefreshCount;

    return mainFunction;
};

export default useWsAlerts;