import React from 'react';
import { Button, IconButton, Tooltip, FormControl, TextField } from '@mui/material';
import buttons from '../../../../../../../../../helpers/constants/buttons';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDelete from '../../../../../../../../common/ConfirmModal';
import { useState } from 'react';
import messages from '../../../../../../../../../helpers/constants/messages';
import RenderKoapSelect from '../RenderKoapSelect';


const ZoneItem = ({ zone, index, fields = [], onChange, onDelete, validation }) => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const onChangeItem = (e) => {
        const { name, value } = e.target;
        onChange({ ...zone, [name]: value });
    };

    return (
        <div className="row align-items-center" style={{ backgroundColor: '#ebebeb', padding: '5px' }}>
            <div className="row__item">
                <div className="row align-items-center">
                    {fields.map(el => (
                        el.field === 'koap'
                            ?  null
                            : <FormControl key={el.field} className="row__item" size="small" variant="outlined">
                                <TextField
                                    variant="outlined" 
                                    size="small" 
                                    label={el.name}
                                    required={el.require}
                                    value={zone[el.field]}
                                    name={el.field}
                                    onChange={onChangeItem}
                                    type={el.type}
                                    error={(!zone[el.field] && el.require) || validation.isKey(`zones.${index}.${el.field}`)}
                                    helperText={validation.get(`zones.${index}.${el.field}`)}
                                />
                            </FormControl>
                    ))}                        
                </div>
                <RenderKoapSelect
                    item={zone.koap}
                    onChange={(value) => onChange({ ...zone, koap: value })}
                    field={fields.find(el => el.field === 'koap') || {}}
                    error={validation.isKey(`zones.${index}.koap`)}
                    helperText={validation.get(`zones.${index}.koap`)}
                />
            </div>
            <Tooltip title={'Удалить зону'}>
                <IconButton onClick={() => setShowConfirmDelete(true)} size="small">
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
    
    
            {showConfirmDelete
        && <ConfirmDelete
            open={showConfirmDelete}
            onClose={()=> setShowConfirmDelete(false)}
            onSuccess={()=> {
                setShowConfirmDelete(false);
                onDelete();
            }}
            message={messages.CONFIRM_DELETE}
        />
            }
        </div>
    );
};

export default ZoneItem;