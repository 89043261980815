import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import { Grid } from '@mui/material';
import { usePrevious } from '@dnd-kit/utilities';
import queryString from 'query-string';

import { loadGovernmentContract } from 'redux/GovernmentContracts/actions';
import { companiesSelectors } from 'redux/Companies';
import { govermentContracts } from 'redux/GovernmentContracts';
import { loadOrganization } from 'redux/Companies/actions';
import messages from 'helpers/constants/messages';
import { stringifyParamsForUrl } from 'helpers/doris.control/helper';
import titles from 'helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';

import Item from './Item';
import Filter from './Filter';
import PassportTabs from './PassportPage/PassportTabs';
import ModalForm from './ModalForm';

import type { ItemProps } from './types';

interface GovernmentContractProps {
    contractId?: number;
}

const GovernmentContract: React.FC<GovernmentContractProps> = ({ contractId }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const data = useSelector(govermentContracts.governmentContractListData);
    const meta = useSelector(govermentContracts.governmentContractListMeta);
    const loading = useSelector(govermentContracts.governmentContractLoading);
    const foundCompanies = useSelector(companiesSelectors.company);

    const [params, setParams] = useState({
        page: 1,
        data: {
            is_simple: 1,
        },
    });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit') || 25));
    const [openAddModal, setOpenAddModal] = useState(false);
    const [currentContract, setCurrentContract] = useState<ItemProps | null>(null);
    const [focus, setFocus] = useState<number | null>(null);

    useEffect(() => {
        if (data?.length > 0) {
            const { id } = queryString.parse(location.search, { arrayFormat: 'bracket' });
            const contract = id ? data?.find((item: ItemProps) => item.id === Number(id)) : null;
            setCurrentContract(contract);
        }
    }, [location.search, data]);

    const stringifyUrlFunc = (param = {}) => {
        history.replace({ ...location, search: stringifyParamsForUrl(param) });
    };

    const handleClick = (item: ItemProps) => {
        const { page } = params;
        setFocus(item?.id);
        if (item.id === currentContract?.id) {
            stringifyUrlFunc({ page, limit });
        } else {
            stringifyUrlFunc({ page, limit, id: item.id });
        }
    };

    const reloadList = useCallback((shouldGoToPreviousPage?: boolean) => {
        stringifyUrlFunc({ page: params.page, limit });
        if (shouldGoToPreviousPage) {
            setParams({ page: params.page - 1, data: params.data });
        } else {
            dispatch(loadGovernmentContract({ page: params.page, limit, params: params.data }));
        }
    }, [dispatch, limit, params.data, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    const uniqIds = useMemo(() => [...new Set(
        data.reduce((res: Array<number>, item : ItemProps) => {
            item.customer && res.push(item.customer);
            item.contractor && res.push(item.contractor);
            return res;
        }, [])
    )],[data]);

    const prevIds = usePrevious(uniqIds);

    useEffect(() => {
        const load = uniqIds.some(id => !prevIds?.includes(id));

        if (uniqIds.length > 0 && load){
            dispatch(loadOrganization(uniqIds));
        }
    }, [dispatch, prevIds, uniqIds]);

    const renderList = () => <>
        {data?.length > 0
            ? (
                <LSContainer
                    headers={[
                        { title: '№', width: '15%' },
                        { title: titles.NAME, width: '18%' },
                        { title: 'Дата начала', width: '11%' },
                        { title: 'Дата окончания', width: '11%' },
                        { title: 'Заказчик', width: '20%' },
                        { title: 'Исполнитель', width: '20%' },
                        { title: 'Действия', align: 'right', isActions: true }
                    ]}
                    isMobile={!!currentContract}
                >
                    {data.map((item: ItemProps, i: number, arr: ItemProps[]) =>
                        <Item
                            key={item.id}
                            item={item}
                            reloadList={(isDeleted) => {
                                reloadList(isDeleted && (arr.length === 1 && meta?.last_page > 1));
                            }}
                            foundCompanies={foundCompanies}
                            contractId={contractId}
                            focus={focus}
                            onClickItem={handleClick}
                            selectedId={item?.id === currentContract?.id}
                            checkContract={!!currentContract}
                        />
                    )}
                </LSContainer>
            )
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        }
        {openAddModal && (
            <ModalForm
                isOpen={openAddModal}
                onClose={() => setOpenAddModal(false)}
                isNew={true}
                reloadList={() => setParams({ page: 1, data: { is_simple: 1 } })}
            />
        )}
    </>;

    const renderContent = () => {
        return <div
            style={{ height: '100%' }}
        >
            <Grid container style={{ height: '100%' }}>
                <Grid item xs={ currentContract ? 3 : 12 } style={{ height: '100%' }}>

                    {currentContract
                        ? (
                            <Scrollbars style={{ height: '100%' }}>
                                {renderList()}
                            </Scrollbars>
                        )
                        : (
                            <>
                                {renderList()}
                            </>
                        )
                    }
                </Grid>

                {currentContract && (
                    <Grid item xs={9}>
                        <PassportTabs
                            id={currentContract?.id}
                        />
                    </Grid>
                )}
            </Grid>

        </div>;
    };

    return (
        <PageLayout
            header={contractId ? '' : 'Госконтракты'}
            loading={loading}
            filters={!contractId && <Filter setParams={setParams} />}
            informPanelProps={
                !contractId
                    ? {
                        buttons: <FormButtonsComponent
                            buttons={[{
                                ...buttonsTypes.add,
                                onClick: () => setOpenAddModal(true)
                            }]}
                            positionLeft
                            noPadding
                        />,
                        total: meta?.total
                    }
                    : undefined
            }
            paginationProps={contractId ? undefined : {
                loadList: (page: number) => setParams({ ...params, page }),
                list: meta,
                limit: Number(limit),
                setLimit: setLimit,
            }}
            content={renderContent}
            additionalButtons={<ReturnToButton />}
        />
    );
};

export default GovernmentContract;
