import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadMaterialStorageGroups } from 'redux/DorisControl/actions';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import titles from '../../../../../helpers/constants/titles';
import { LSContainer } from '../../../../common/List';

import Item from './Item';
import ModalForm from './ModalForm';

const CustomSettings = () => {
    const dispatch = useDispatch();

    const {
        materialStorageGroups: { data, meta },
        loadingMaterialStorageGroups: loading
    } = useSelector(({ dorisControl }) => dorisControl);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, query: {} });
    const [openAddModal, setOpenAddModal] = useState(false);

    const loadList = useCallback((deletedLastItem = false) => {
        if (deletedLastItem && params.page > 1) {
            setParams(prevParams => ({ ...prevParams, page: prevParams.page - 1 }));
        } else {
            dispatch(loadMaterialStorageGroups(params.page, limit, params.query));
        }
    }, [dispatch, limit, params.page, params.query]);

    useEffect(() => {
        loadList();
    },[loadList]);

    const renderContent = () => {
        return (
            <>
                {data.length > 0
                    ? (
                        <LSContainer headers={[
                            { title: titles.TITLE, width: '90%' },
                            { title: titles.ACTIONS, isActions: true }
                        ]}>
                            {data.map((item) => (
                                <Item
                                    key={item.id}
                                    item={item}
                                    loadList={(isDelete) => loadList(isDelete && data.length === 1)}
                                />
                            ))}
                        </LSContainer>
                    )
                    : !loading && <NoData/>}
            </>
        );
    };

    return (
        <>
            <PageLayout
                header="Настройки кастомных параметров"
                loading={loading}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            noMarginLeft
                            noPadding
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenAddModal(true),
                                },
                            ]}
                        />
                    ),
                    total: meta?.total,
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit: limit,
                    setLimit: setLimit,
                }}
            />

            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    loadList={loadList}
                    isNew
                />
            )}
        </>
    );
};

export default CustomSettings;
