import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItem, ListItemText } from '@mui/material';

import { loadDtpList, loadDtpTypes } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

const dtpKeys = {
    count_members: { name: titles.COUNT_DTP_MEMBERS },
    wounded: { name: titles.COUNT_DTP_WOUNDED },
    dead: { name: titles.COUNT_DTP_DEAD },
    count_ts: { name: titles.COUNT_DTP_TS },
    km: { name: titles.DTP_KM },
    m: { name: titles.DTP_M },
    description: { name: titles.DTP_DESCRIPTION },
    schema_img_url: { name: titles.SCHEMA_DTP },
    type_load_text: { name: titles.DTP_DATA_SOURCE },
};

const DtpInfoModal = ({ isOpen, setOpen, dtpId, title }) => {
    const dispatch = useDispatch();

    const list = useSelector(incidentsSelectors.list);
    const dtp_types = useSelector(incidentsSelectors.dtp_types);
    const loading = useSelector(incidentsSelectors.loading);
    const dtp = list?.data[0] || null;

    useEffect(() => {
        if (dtp_types?.data?.length === 0) {
            dispatch(loadDtpTypes());
        }
    }, [dispatch, dtp_types]);

    useEffect(() => {
        if (dtpId) dispatch(loadDtpList(1,25, { id: dtpId }));
    }, [dispatch, dtpId]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: () => setOpen(false)
                    }
                ]}
            />}
            title={title || titles.RELATED_DTP}
        >
            <ListItem>
                {loading ? (
                    <div>{messages.LOADING}</div>
                ) : (
                    <>
                        {dtp ? (
                            <ListItemText>
                                <div><b>ID ДТП:</b> {dtpId}</div>
                                <div>
                                    <strong>{titles.STATUS}: </strong>
                                    {dtp?.status_text || messages.NO_DATA}
                                </div>
                                <div>
                                    <strong>{titles.DATE_TIME_DTP}: </strong>&nbsp;
                                    { fullDateTimeWithoutSeconds(dtp?.dtp_at) || messages.INFO_IS_NOT_FOUND }
                                    {/*{ getDateTime(dtp?.created_at) || messages.INFO_IS_NOT_FOUND }*/}
                                </div>
                                {/*<div>*/}
                                {/*    <strong>Дата/время регистрации дтп: </strong>&nbsp;*/}
                                {/*    /!*{ getDateTime(dtp?.dtp_at) || messages.INFO_IS_NOT_FOUND }*!/*/}
                                {/*    { getDateTime(dtp?.created_at) || messages.INFO_IS_NOT_FOUND }*/}
                                {/*</div>*/}
                                <div>
                                    <strong>{titles.ADDRESS}: </strong>&nbsp;
                                    {dtp?.address_text || messages.NO_DATA}
                                </div>
                                <div>
                                    <strong>{titles.TYPE}: </strong>
                                    {dtp_types?.data.find(({ id }) => id === dtp?.dtp_type)?.name || messages.NO_DATA}
                                </div>
                                {Object.keys(dtpKeys).map((key) => {
                                    const { name, value } = dtpKeys[key];
                                    const itemValue = value ? value(dtp[key]) : dtp[key];
                                    return (
                                        <p key={key}>
                                            <b>{name}:</b> {itemValue || messages.INFO_IS_NOT_FOUND}
                                        </p>
                                    );
                                })}
                            </ListItemText>
                        ) : (
                            <div>{messages.NO_DATA}</div>
                        )}
                    </>
                )}
            </ListItem>
        </Modal>
    );
};

export default DtpInfoModal;
