import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { changePassword } from '../../../../redux/Auth/actions.js';
import { clearValidation, setValidation } from '../../../../redux/Validation/actions';
import './style.scss';
import Loading from '../../../common/Loading';
import {
    FormGroup,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormHelperText,
} from '@mui/material';
import FormButtonsComponent from '../../../common/FormButtons/FormButtonsComponent.js';
import buttonsTypes from '../../../common/FormButtons/buttonsTypes.js';
import { authSelectors } from '../../../../redux/Auth/index.js';

const formFields = [
    { label: 'Старый пароль', name: 'old_password', width: 115 },
    { label: 'Новый пароль', name: 'password', width: 110 },
    { label: 'Повторить новый пароль', name: 'password_confirmation', width: 195 },
];

const ChangePassword = () => {
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        old_password: '',
        password: '',
        password_confirmation: '',
        showold_password: false,
        showpassword: false,
        showpassword_confirmation: false,
    });

    const loading = useSelector(authSelectors.loading);
    const validation = useSelector(({ validation }) => validation);

    useEffect(() => {
        dispatch(clearValidation());
    }, []);

    const clearData = () => {
        setValues({
            old_password: '',
            password: '',
            password_confirmation: '',
        });
    };

    const handleSend = (e) => {
        const { old_password, password, password_confirmation } = values;
        e.preventDefault();
        dispatch(clearValidation());
        dispatch(
            changePassword(
                {
                    old_password,
                    password,
                    password_confirmation,
                },
                clearData
            )
        );
    };

    const handleCancel = (e) => {
        e.preventDefault();
        clearData();
        dispatch(clearValidation());
    };

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
        if (validation[name]) delete validation[name];
        dispatch(
            setValidation({
                ...validation,
            })
        );
    };

    const handleChangeVisibility = (name) => () => {
        setValues({ ...values, [name]: !values[name] });
    };

    return (
        <div className={'change-password'}>
            <div className={'form'}>
                <div>
                    <h2>Сменить пароль</h2>
                    <FormGroup>
                        {formFields.map(({ label, name, width }, index) => (
                            <FormControl
                                key={index}
                                className={'mt-1 my-sm-3'}
                                variant={'outlined'}>
                                <InputLabel>{label}</InputLabel>
                                <OutlinedInput
                                    type={values['show' + name] ? 'text' : 'password'}
                                    value={values[name]}
                                    name={name}
                                    onChange={handleChange}
                                    error={!!validation[name]}
                                    endAdornment={
                                        <InputAdornment position={'end'}>
                                            <IconButton
                                                aria-label={'toggle password visibility'}
                                                onClick={handleChangeVisibility('show' + name)}
                                                edge={'end'}
                                                size="large">
                                                {values['show' + name] ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id={'component-error-text'} className={'error'}>
                                    {validation[name]}
                                </FormHelperText>
                            </FormControl>
                        ))}
                    </FormGroup>
                    <div className={'buttons'}>
                        <FormButtonsComponent
                            justButton
                            buttons={[
                                {
                                    ...buttonsTypes.cancel,
                                    onClick: handleCancel,
                                    disabled: Object.keys(validation).length > 0,
                                    fullWidth: true,
                                    size: 'small'
                                },
                                {
                                    ...buttonsTypes.send,
                                    onClick: handleSend,
                                    disabled: Object.keys(validation).length > 0,
                                    fullWidth: true,
                                    size: 'small'
                                }
                            ]}
                        />
                    </div>
                    {loading && <Loading circular={true}/>}
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
