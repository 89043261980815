
import { useEffect, useState } from 'react';
import { FormHelperText } from '@mui/material';

import { config } from 'config';
import createPointGJ from 'components/MapComponents/leaflet/helpers/createPointGJ';
import MapFinder from 'components/common/MapContlols/MapFinder';
import MapMinimize from 'components/common/MapContlols/MapMinimize';
import { useValidation } from 'helpers/hooks';
import {
    CustomControl,
    FeatureGroup,
    Map,
    Marker,
    ToolTip
} from 'components/MapComponents/leaflet';

import Inner from './Inner';


// компонент выбора маркера карте
const MapDragMarker = (props) => {
    const {
        lat = '',
        lon = '',
        onChange = () => {},
        readOnly = false,
        innerComponent = null,
        innerComponentEvent = null,
        permanent = true,
        icon = null,
        offsetToolTip = [0, -40],
        required,
        isHideValidation,
        isSearch,
        returnGeometry = false
    } = props;

    const validation = useValidation();

    const [mapCenter, setMapCenter] = useState(config.get('mapCenter'));
    const [mapZoom, setMapZoom] = useState(null);

    const handleMapClick = (e) => {
        // клик по карте
        if (e?.latlng) {
            const { lat, lng } = e?.latlng;
            onChange({
                lat,
                lon: lng,
                ...(returnGeometry && { geometry: createPointGJ(lat, lng) })
            });
        } else {
            // сдвигание маркера
            const { lat, lon } = e;
            onChange({
                lat,
                lon,
                ...(returnGeometry && { geometry: createPointGJ(lat, lon) })
            });
        }

        validation.deleteKeys(['lat', 'lon']);
    };

    useEffect(()=> {
        if (!lat || !lon) {
            setMapCenter(config.get('mapCenter'));
            setMapZoom(15);
        } else {
            setMapCenter([lat, lon]);
            setMapZoom(15);
        }
    },[lat, lon]);

    const markerProps = {
        latlng: [lat, lon],
    };
    if (icon) {
        markerProps.icon = icon;
    }
    if (!readOnly) {
        markerProps.onDragEnd = handleMapClick;
    }

    const mapProps = {
        center: mapCenter,
        zoom: mapZoom,
    };
    if (!readOnly) {
        mapProps.onClick = handleMapClick;
    }

    const RightSearch = (prop) => {
        if (isSearch) {
            return (
                <CustomControl
                    {...prop}
                    position="topright"
                >
                    <MapMinimize>
                        <MapFinder />
                    </MapMinimize>
                </CustomControl>
            );
        }
        return null;
    };

    return (
        <div>
            <div className="map-form-wrap__container">
                <Map
                    {...mapProps}
                >
                    <FeatureGroup>
                        {/* маркер комплекса */}
                        {lat && lon &&  (
                            <Marker
                                {...markerProps}
                            >
                                {/* выводим подсказку сверху с координатами */}
                                <ToolTip
                                    offset={offsetToolTip}
                                    permanent={permanent}
                                    // permanent={true}
                                    direction="top"
                                >
                                    <div>
                                        <span style={{ display: 'flex', padding: 2 }}>
                                            <strong>Широта:&nbsp;</strong>
                                            {lat}
                                        </span>
                                        <span style={{ display: 'flex', padding: 2 }}>
                                            <strong>Долгота:&nbsp;</strong>
                                            {lon}
                                        </span>
                                    </div>
                                </ToolTip>
                            </Marker>
                        )}

                        {/* импортим компонент */}
                        {!!innerComponent && (
                            <Inner
                                innerComponent={innerComponent}
                                innerComponentEvent={innerComponentEvent}
                            />
                        )}
                    </FeatureGroup>

                    <RightSearch/>
                </Map>
            </div>

            {!isHideValidation
                && (validation.isKey('lat') || validation.isKey('lon'))
                ? (
                    <FormHelperText className="error">
                        Выберите место на карте
                    </FormHelperText>
                )
                : null
            }
        </div>
    );
};

export default MapDragMarker;
