import React from 'react';
import {
    TextField,
} from '@mui/material';
import SingleKeyboardDateTimePicker from '../../../../../common/Dates/SingleKeyboardDateTimePicker';
import Attachments from '../../../../../common/Upload/Attachments';


const Form = ({
    values,
    validation,
    onChange,
}) => {
    const handleChange = (key) => (value) => onChange?.({ [key]: value });
    const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

    return (
        <div className="modal__form">
            <div className="block">
                <TextField
                    className="block"
                    size="small"
                    variant="outlined"
                    label="Название автомобильной дороги"
                    required
                    value={values.road_name}
                    onChange={(event) => {
                        handleChange('road_name')(event.target.value);
                    }}
                    helperText={validation.get('road_name')}
                    error={!values.road_name || validation.isKey('road_name')}
                />
            </div>

            <div className="block">
                <TextField
                    className="block"
                    size="small"
                    variant="outlined"
                    label="Категория автомобильной дороги"
                    required
                    value={values.road_category}
                    onChange={(event) => {
                        handleChange('road_category')(event.target.value);
                    }}
                    helperText={validation.get('road_name')}
                    error={!values.road_category || validation.isKey('road_category')}
                />
            </div>

            <div className="block">
                <TextField
                    className="block"
                    size="small"
                    variant="outlined"
                    label="Адрес участка"
                    required
                    value={values.address_text}
                    onChange={(event) => {
                        handleChange('address_text')(event.target.value);
                    }}
                    helperText={validation.get('address_text')}
                    error={!values.address_text || validation.isKey('address_text')}
                />
            </div>

            <div className="row">
                <TextField
                    className="row__item"
                    size="small"
                    variant="outlined"
                    label="Характерный период"
                    required
                    value={values.characteristic_period}
                    onChange={(event) => {
                        handleChange('characteristic_period')(event.target.value);
                    }}
                    helperText={validation.get('characteristic_period')}
                    error={!values.characteristic_period || validation.isKey('characteristic_period')}
                />

                <TextField
                    className="row__item"
                    size="small"
                    variant="outlined"
                    label="Год"
                    required
                    value={values.year}
                    onChange={(event) => {
                        handleChange('year')(event.target.value);
                    }}
                    helperText={validation.get('year')}
                    error={!values.year || validation.isKey('year')}
                    type="number"
                />

                <TextField
                    className="row__item"
                    size="small"
                    variant="outlined"
                    label="Месяц"
                    required
                    value={values.month}
                    onChange={(event) => {
                        handleChange('month')(clamp(event.target.value, 1,12));
                    }}
                    helperText={validation.get('month')}
                    error={!values.month || validation.isKey('month')}
                    type="number"
                />
            </div>

            <div className="block">
                <TextField
                    className="block"
                    size="small"
                    variant="outlined"
                    label="Требуемый уровень содержания"
                    required
                    value={values.maintenance_level}
                    onChange={(event) => {
                        handleChange('maintenance_level')(event.target.value);
                    }}
                    helperText={validation.get('maintenance_level')}
                    error={!values.maintenance_level || validation.isKey('maintenance_level')}
                />
            </div>

            <div className="block">

                <SingleKeyboardDateTimePicker
                    value={values?.registration_date || null}
                    onChange={handleChange('registration_date')}
                    label="Дата регистрации"
                    dateOnly={true}
                    error={!values?.registration_date || validation.isKey('registration_date')}
                    helperText={validation.get('registration_date')}
                    required
                    className={'row__item'}
                />

            </div>

            <Attachments
                label="Загрузить файл"
                files={
                    values.document_link
                        ? [values.document_link]
                        : []
                }
                onChange={handleChange('document_link')}
                service="road-work"
                error={!values.document_link || validation.isKey('document_link')}
                helperText={validation.get('document_link')}
                singleFile
                isRequired
                onlyLink
            />

        </div>
    );
};

export default Form;