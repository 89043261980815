import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Item from './Item';

const useStyles = makeStyles({
    cells: {
        minHeight: 50,
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '6px 5px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    row: {
        backgroundColor: '#fff',
        '&:nth-child(odd)': {
            backgroundColor: 'rgba(63, 81, 181, 0.08)',
        }
    },
    cellFlex: {
        display: 'flex',
        justifyContent: 'space-around',
        maxWidth: 80,
        alignItems: 'center',
        margin: 'auto'
    }
});

const DataTable = ({ params, reloadData }) => {
    const classes = useStyles();

    const { data, headers } = useSelector(state => state?.transportPassenger?.workordersByDate?.data);

    if (data.length === 0 || !data) { // если пустой массив или null
        return (
            <TableContainer
                className="table_container"
                sx={{ maxHeight: '100%', margin: '0 !important' }}
            >
                <Table size="small" sx={{ background: 'white' }}>
                    <TableHead>
                        <TableRow>
                            {Object.keys(headers)?.map(header => (
                                <TableCell
                                    key={header}
                                    className={classes.cells}
                                >
                                    {headers[header]}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {Object.keys(headers).map(header => (
                                <Item
                                    key={header}
                                    columnTitle={header}
                                    classes={classes}
                                    params={params}
                                    reloadData={reloadData}
                                />
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    return (
        <TableContainer
            className="table_container"
            sx={{ maxHeight: '100%', margin: '0 !important' }}
        >
            <Table
                // прижимаем заголовки сверху
                stickyHeader
                style={{ borderCollapse: 'separate' }}
            >
                {/* заголовки */}
                <TableHead>
                    <TableRow style={{ background: 'white' }}>
                        {Object.keys(headers)?.map(key =>
                            <TableCell
                                className={classes.cells}
                                key={key}
                                // align="left"
                            >
                                {headers[key]}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>

                {/* данные */}
                <TableBody>
                    {data?.map((tableItem, index) => (
                        <TableRow key={index} className={classes.row}>
                            {Object.keys(headers).map(header => (
                                <Item
                                    key={header}
                                    columnTitle={header}
                                    item={tableItem}
                                    classes={classes}
                                    params={params}
                                    reloadData={reloadData}
                                />
                            ))}
                        </TableRow>
                    ))}
                </TableBody>

            </Table>
        </TableContainer>
    );
};

export default DataTable;
