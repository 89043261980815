import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import titles from 'helpers/constants/titles';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import Filter from './Filter';
import Item from './Item';
import ModalForm from './ModalForm';

const VehicleCategories = ({
    list,
    loading,
    loadList,
    createCategory,
    editCategory,
    deleteCategory,
    fromService
}) => {
    const dispatch = useDispatch();

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openModal, setOpenModal] = useState(false);

    const reloadList = useCallback((shouldGoToPreviousPage = false) => {
        if (shouldGoToPreviousPage) {
            setParams({ page: params.page - 1, data: params.data });
        } else {
            dispatch(loadList(params.page, limit, params.data));
        }
    }, [dispatch, limit, loadList, params.data, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    return (
        <>
            <PageLayout
                header="Виды ТС"
                filters={<Filter setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenModal(true)
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: list?.meta?.total
                }}
                loading={loading}
                content={() => (
                    <>
                        {list?.data?.length > 0
                            ? (<>
                                <LSContainer headers={[
                                    { title: '', width: '5%' },
                                    { title: titles.TYPE_OF_VEHICLE },
                                    { title: 'Действия', isActions: true }
                                ]}>
                                    {list.data.map((item, _, arr) => (
                                        <Item
                                            key={item.id}
                                            item={item}
                                            reloadList={(isDelete) => reloadList(isDelete && arr.length === 1)}
                                            editVehicleCategory={editCategory}
                                            deleteVehicleCategory={deleteCategory}
                                            fromService={fromService}
                                        />
                                    ))}
                                </LSContainer>
                            </>)
                            : !loading && <NoData/>
                        }
                    </>
                )}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: list.meta,
                    limit,
                    setLimit
                }}
            />

            {openModal && (
                <ModalForm
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    reloadList={() => setParams({ page: 1, data: {} })}
                    isNew
                    createVehicleCategory={createCategory}
                    fromService={fromService}
                />
            )}
        </>
    );
};

export default VehicleCategories;
