import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useValidation } from '../../../../../helpers/hooks';
import titles from '../../../../../helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import Modal from '../../../../common/Modal';
import {
    FormControl,
    TextField,
    LinearProgress,
    ListItem,
} from '@mui/material';
import * as actions from '../../../../../redux/Boards/actions';
import UploadImageField from '../../../../common/Upload/UploadImageField';
import { boardsSelectors } from '../../../../../redux/Boards';

const notLatinRegx = /[^a-z]/gi;

const ModalForm = ({
    isOpen = false,
    data = {},
    reloadList = () => {},
    onClose = () => {},
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const loading = useSelector(boardsSelectors.templateImageLoading);

    const isNew = !data?.id || false;

    const initialState = {
        name: data?.name || '',
        image: data?.image || '',
        sign: data?.sign || '',
    };

    const [fields, setFields] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFields({
            ...fields,
            [name]: value
        });
        validation.deleteKey(name);
    };

    const handleChangeFile = (name, file) => {
        setFields({
            ...fields,
            [name]: file
        });
        validation.deleteKey(name);
    };

    const handleChangeSign = (e) => {
        const { name, value } = e.target;

        const removedNotLatinLetters = value.replace(notLatinRegx, '');

        setFields({
            ...fields,
            [name]: removedNotLatinLetters
        });
    };

    const onSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        isNew
            ? dispatch(actions.createTemplateImage(fields, callback))
            : dispatch(actions.editTemplateImage(data?.id, fields, callback));
    };

    const isDisabled = !fields.name
        || !fields.image
        || !fields.sign;

    return (
        <Modal
            title={isNew ? titles.CREATE : titles.EDIT}
            onClose={onClose}
            noPadding
            isOpen={isOpen}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isDisabled,
                        onClick: onSave
                    },
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl required className="block" size="small" variant="outlined">
                    <TextField
                        required
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={fields?.name}
                        name="name"
                        onChange={handleChange}
                        type="text"
                        error={!fields.name || validation.isKey('name')}
                        helperText={validation.get('name')}
                    />
                </FormControl>
                <FormControl required className="block" size="small" variant="outlined">
                    <TextField
                        required
                        label={titles.UNIQUE_ALIAS}
                        variant="outlined"
                        size="small"
                        value={fields?.sign}
                        name="sign"
                        onChange={handleChangeSign}
                        type="text"
                        error={!fields.sign || validation.isKey('sign')}
                        helperText={validation.get('sign')}
                    />
                </FormControl>

                <ListItem divider>
                    <UploadImageField
                        label="Изображение"
                        value={fields?.image}
                        name="image"
                        onChange={handleChangeFile}
                        errorText={validation.get('image')}
                        required
                    />
                </ListItem>
            </div>
            {loading && <LinearProgress />}
        </Modal>
    );
};

export default ModalForm;