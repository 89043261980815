import { useMemo, useState } from 'react';
import { TextField } from '@mui/material';

import { loadComplexTypes } from 'redux/DorisControl/actions';
import { loadRegions } from 'redux/Dadata/actions';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useStoreProp } from 'helpers/hooks';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import SelectPddViolation from 'components/common/Autocomplete/DorisControl/PddViolation';
import SimpleSelect from 'components/common/SimpleSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import SelectTags from 'components/common/Autocomplete/DorisControl/Tags';
import GovernmentContractList from 'components/common/Autocomplete/GovernmentContract';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

export const FilterVFComplex = ({
    setParams,
    statuses,
}) => {

    const regionsAll = useStoreProp(loadRegions, 'dadata', 'regions');
    const complexTypes = useStoreProp(loadComplexTypes, 'dorisControl', 'doris_complex_types');

    const initState = {
        name: '',
        ip_route: '',
        status: [],
        serial_number: [],
        pdd_violations: [],
        address_text: '',
        tags: [],
        area: '',
        district: '',
        uuid: [],
        contract_id: [],
        serial_number_tmp: '',  // сохранение введенного в Серийный номер,
        // должно записаться в serial_number только по клику на Найти + даём сохранять на бэк
        complexes_ids: [],
        types_ids: [],
    };
    const [data, setData] = useState(initState);

    const prepareData = (filter = data) => removeEmptyFields({
        ...filter,
        status: filter.status?.map(item => item.id),
        tags: filter.tags?.map(item => item.id),
        pdd_violations: filter.pdd_violations?.map(item => item.id),
        area: filter.area?.name,
        district: filter.district?.name,
        contract_id: filter.contract_id?.map(item => item.id ? item.id : null),
        complexes_ids: filter.complexes_ids?.map(item => item.id),
        types_ids: filter.types_ids?.map(item => item.id),
    }, true);

    const cities = useMemo(() => {
        if (Array.isArray(regionsAll.cities) && data.area) {
            return regionsAll.cities.filter(city => data.area.id === city.parent_id);
        }

        return regionsAll.cities;
    }, [data.area, regionsAll]);

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value,
            ...((name === 'serial_number' && value.length === 0) && { serial_number_tmp: '' })
        });
    };

    const onSearch = (filters = data) => {
        const serialAlreadyInArray = filters.serial_number.find(item => item === filters.serial_number_tmp);
        const expandSerial = {
            serial_number: filters.serial_number_tmp.length > 0 && !serialAlreadyInArray
                ? [...filters.serial_number, filters.serial_number_tmp]
                : filters.serial_number,
            serial_number_tmp: ''
        };

        const filter = {
            ...filters,
            ...expandSerial,
        };
        if (filters.serial_number_tmp.length > 0) {
            setData(filter);
        }
        setParams(prepareData(filter));
    };

    const resetFilter = (needRefresh) => { //  аргумент needRefresh приходит из LayoutFilter
        if (needRefresh) { // если надо перезапросить список
            setParams(prepareData(initState)); // отправляем в родителя, чтобы произошел запрос
        }
        setData(initState); // обновляем локальный стейт
    };

    const updateFilter = (filters) => { // фильтры с бэка
        setData({ // обновляем локальный стейт
            ...initState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {  // если фильтры не пустые
            onSearch({ // делаем запрос
                ...initState, // начальное значение + пришедшие фильтры
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={onSearch}
            filter={data}
            filterException={['start_date', 'end_date',]} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={data?.name}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    name="name"
                    type="text"
                />
                <CommonAutocomplete
                    multiple
                    selected={data.status}
                    onChange={(value) => onChange('status', value)}
                    onReset={() => onChange('status', initState.status)}
                    inputName={titles.STATUS}
                    limitTags={1}
                    label={titles.STATUS}
                    options={statuses}
                    filterSelectedOptions={true}
                    renderLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    showAsSelect
                />
                <GovernmentContractList
                    label="Номер ГК"
                    multiple
                    selected={data.contract_id || []}
                    onChange={(value) => onChange('contract_id', value)}
                    renderLabel={item => item?.name || ''}
                    additionalItem={{ name: 'Нет ГК', value: null }}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <MultipleInput
                    value={data.uuid}
                    onChange={(e) => onChange('uuid', e)}
                    label="UUID"
                    variant="outlined"
                    name="uuid"
                    size="small"
                    type="text"
                />
                <FormInfoWrapper
                    helperText={'После ввода серийного номера поставьте запятую, чтобы добавить.'}
                >
                    <MultipleInput
                        label={titles.SERIAL_NUMBER}
                        value={data.serial_number}
                        onChange={(value) => onChange('serial_number', value)}
                        blurSelect={false}
                        needClearInput={data.serial_number_tmp.length === 0}
                    />
                </FormInfoWrapper>
                <TextField
                    value={data?.ip_route}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    label={titles.IP_ADDRESS}
                    variant="outlined"
                    size="small"
                    name="ip_route"
                    type="text"
                />

                <SelectPddViolation
                    multiple
                    selected={data.pdd_violations}
                    onChange={(value) => onChange('pdd_violations', value)}
                />

                <TextField
                    value={data.address_text}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    label="Адрес"
                    size="small"
                    name="address_text"
                    type="text"
                />
                <SelectTags
                    multiple
                    selected={data.tags}
                    onChange={(val) => onChange('tags', val)}
                    validation={false}
                    required={false}
                    disabled={false}
                    label="Тег"
                />

                {Array.isArray(regionsAll.areas) && regionsAll.areas.length > 0
                    && <SimpleSelect
                        title="Район области"
                        selected={data.area}
                        setSelected={(value) => onChange('area', value)}
                        endpoint={Array.isArray(regionsAll.areas) ? regionsAll.areas : []}
                        optionKey="id"
                        optionLabel="name"
                    />
                }
                {Array.isArray(cities) && cities.length > 0
                    && <SimpleSelect
                        title="Район города"
                        selected={data.district}
                        setSelected={(value) => onChange('district', value)}
                        endpoint={cities}
                        optionKey="id"
                        optionLabel="name"
                    />
                }

                <SelectComplex
                    multiple={true}
                    selected={data.complexes_ids}
                    onChange={(value) => onChange('complexes_ids', value)}
                />

                <CommonAutocomplete
                    multiple={true}
                    selected={data.types_ids}
                    onChange={(value) => onChange('types_ids', value)}
                    onReset={() => onChange('types_ids', initState.types_ids)}
                    inputName={titles.COMPLEX_TYPE}
                    limitTags={1}
                    label={titles.COMPLEX_TYPE}
                    options={complexTypes}
                    filterSelectedOptions={true}
                    renderLabel={(option) => option.name}
                    showAsSelect
                />

            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};
