import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

import type { BrigadesTS, GetListArguments } from 'types';

interface Filters {
    start_at?: string;
    end_at?: string;
    organization_id?: number;
}

interface Props<T> extends Omit<CommonAutocompleteProps<T>, 'onShowList' | 'inputName' | 'initialState'> {
    filter?: Filters;
    loadListAction: (page: GetListArguments['page'], limit: GetListArguments['limit'], query: GetListArguments['query']) => void;
    storeName: string;
    storeNameProps: string;
}

const SelectBrigades = ({
    multiple = false,
    selected = multiple ? [] : null,
    onChange = () => {},
    required = false,
    disabled = false,
    label=titles.SELECT_BRIGADE,
    error = false,
    helperText = '',
    filter = {},
    loadListAction,
    storeName='transportPassenger',
    storeNameProps='brigadesNoWorkorder',
    limitTags = 1,
}: Props<BrigadesTS>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);

    useEffect(() => {
        // например сброс фильтра - selected изменился нужно обновить selectedItems
        if (!_.isEqual(prevData.current, selected)) { 
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleClose = () => setShowList(false);

    const getList = (params: GetListArguments) => {
        const { page, limit, query: name } = params;

        dispatch(loadListAction(page, limit, {
            ...(name && { name }),
            ...filter,
        }));
    };
   
    const handleAccept = (data: BrigadesTS | BrigadesTS[]) => {
        onChange(data);
        setSelected(data);
        setShowList(false);
    };

    const onReset = () => {
        const initialState = multiple ? [] : null;
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete<BrigadesTS>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={(option) => option?.name || ''}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error} 
                helperText={helperText}
                isOpen={showList}
            />
            {showList 
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName={storeName}
                    storeNameProps={storeNameProps}
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div>{el.name}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={label}
                    small
                />
            }
        </>
    );
};

export default SelectBrigades;
