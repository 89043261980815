import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDelete from '../../../../common/ConfirmModal';
import messages from '../../../../../helpers/constants/messages';
import { deleteModel } from '../../../../../redux/TransportSpecial/actions';
import { transportSpecialSelectors } from '../../../../../redux/TransportSpecial';
import ModalForm from './ModalForm';
import ModelListItem from '../../../../common/Transport/ModelList/Item';

const Item = ({ item, reloadList, permissions }) => {
    const dispatch = useDispatch();

    const loadingButton = useSelector(transportSpecialSelectors.loadingButton);

    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    // const [isOpenInfo, setIsOpenInfo] = useState(false);

    const toggleOpenDelete = (e) => {
        e && e.stopPropagation();
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const toggleOpenEdit = (e) => {
        e && e.stopPropagation();
        setIsOpenEditModal(!isOpenEditModal);
    };

    const handleDelete = () => {
        dispatch(deleteModel(item.id, () => {
            reloadList(true);
            setIsOpenDeleteModal(false);
        }));
    };

    return (
        <>
            <ModelListItem
                item={item}
                toggleOpenEdit={toggleOpenEdit}
                toggleOpenDelete={toggleOpenDelete}
                permissions={permissions}
            />
            
            {isOpenEditModal && (
                <ModalForm
                    onClose={toggleOpenEdit}
                    open={isOpenEditModal}
                    item={item}
                    reloadList={reloadList}
                />
            )}
            {isOpenDeleteModal && (
                <ConfirmDelete
                    open={isOpenDeleteModal}
                    message={messages.CONFIRM_DELETE}
                    onClose={toggleOpenDelete}
                    onSuccess={handleDelete}
                    loadingButton={loadingButton}
                />
            )}
            {/* {isOpenInfo && <Info isOpen={isOpenInfo} item={item}/>} */}
        </>
    );
};

export default Item;