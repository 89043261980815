declare module '@mui/material/styles' {
    interface Palette {
        greyButton: Palette['primary'];
        infoButton: Palette['primary'];
    }

    interface PaletteOptions {
        greyButton?: PaletteOptions['primary'];
        infoButton?: PaletteOptions['primary'];
    }
}
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        greyButton: true;
        infoButton: true;
    }
}

const theme = {
    palette: {
        primary: {
            main: '#3f51b5'
        },
        secondary: {
            main: '#9f9f9f',
            contrastText: '#fff',
        },
        greyButton: {
            main: 'rgb(224, 224, 224)',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        infoButton: {
            main: '#3399fe',
            contrastText: '#fff',
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 2000,
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                size: 'small'
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: ({ ownerState }: any) => ({
                    ...(ownerState.size === 'large' && {
                        fontSize: '1.5rem'
                    }),
                    ...(ownerState.size === 'medium' && {
                        fontSize: '1.125rem'
                    }),
                    ...(ownerState.size === 'small' && {
                        fontSize: '1rem'
                    }),
                })
            }
        },
        // Name of the component
        MuiButton: {
            styleOverrides: {
            // Name of the slot
                root: ({ ownerState }: any) => ({
                    boxShadow: 'none',
                    textTransform: 'none',
                    borderRadius: '12px',
                    padding: '6px 16px',
                    margin: '0',
                    '&:hover': {
                        textDecoration: 'none',
                        boxShadow: 'none',
                    },
                    '&:active': {
                        boxShadow: 'none',
                    },
                    ...(ownerState.variant === 'text' && {
                        padding: '6px 0',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }),
                    ...(ownerState.color === 'greyButton' && {
                        color: 'rgba(0, 0, 0, 0.54)',
                        '&:hover': {
                            backgroundColor: 'transparent', //'#f5f5f5',
                            // boxShadow: 'none',
                        },
                    }),
                    ...(ownerState.variant === 'contained' && ownerState.color === 'primary' && {
                        '&:hover': {
                            backgroundColor: '#2c387e',
                            boxShadow: 'none',
                        },
                    }),
                    ...(ownerState.variant === 'contained' && ownerState.color === 'greyButton' && {
                        '&:hover': {
                            backgroundColor: 'transparent', //'#f5f5f5',
                            boxShadow: 'none',
                        },
                    }),
                    ...(ownerState.variant === 'contained' && ownerState.color === 'infoButton' && {
                        '&:hover': {
                            backgroundColor: '#52a8fe',
                            boxShadow: 'none',
                        },
                    }),
                    ...(ownerState.variant === 'text' && ownerState.color === 'infoButton' && {
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }),

                })
            },
        },
    },

};

export default theme;
