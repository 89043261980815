import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import messages from '../../../../../helpers/constants/messages';
import titles from '../../../../../helpers/constants/titles';
import renderAddress from '../../../../../helpers/renderAddress';
import { roadNetworksSelectors } from '../../../../../redux/RoadNetwork';
import { getHumanDate } from '../../../../../helpers/date.config';
import { Scrollbars } from 'react-custom-scrollbars';
import { useStoreProp } from '../../../../../helpers/hooks';
import { loadOverlapSource, loadOverlapTypes } from '../../../../../redux/RoadNetwork/actions';
import { getColor } from '../helper';


const PopUp = (props) => {
    const { uuid = 0 } = props || {};

    const overlapTypes = useStoreProp(
        loadOverlapTypes,
        'roadNetworks',
        'overlapTypes'
    );

    const overlapSource = useStoreProp(
        loadOverlapSource,
        'roadNetworks',
        'overlapSource'
    );

    const polygon = useSelector(roadNetworksSelectors.overlapPolygon);

    // забираем данные из полигона
    const data = useMemo(() => polygon
        ?.find(item => item.id === uuid)
        || {}
    , [uuid, polygon]);

    // выводимые поля
    const fields = [
        {
            key: 'name',
            name: titles.NAME,
            f: ({ name }) => name,
        },
        {
            key: 'address',
            name: 'Адрес дислокации',
            f: ({ address }) => renderAddress(address),
        },
        {
            key: 'type',
            name: 'Категория ограничений',
            f: ({ type }) => overlapTypes[type],
        },
        {
            key: 'source_creation',
            name: 'Источник ограничений',
            f: ({ source_creation }) => overlapSource[source_creation],
        },
        {
            key: 'source',
            name: 'Тип',
            f: ({ source }) => source,
        },
        {
            key: 'created_at',
            name: 'Дата создания',
            f: ({ created_at }) => getHumanDate(created_at),
        },
        {
            key: 'start_at',
            name: 'Дата начала',
            f: ({ start_at }) => getHumanDate(start_at),
        },
        {
            key: 'end_at',
            name: 'Дата конца',
            f: ({ end_at }) => getHumanDate(end_at),
        },
        {
            key: 'link',
            name: 'Первоисточник',
            f: ({ link }) => link ? <a href={link} target="_blank" rel="noreferrer">Перейти</a> : '',
        },
    ];

    return (
        <div className="map-popup">
            <div
                className="map-popup_header"
                style={{
                    backgroundColor: getColor(data.type),
                }}
            >
                <div>
                    <div className="img">
                        {data.type === 1
                            ? <i className="fas fa-do-not-enter"/>
                            : <i className="overlap_img_warning" style={{ width: '20px', height: '15px' }}/>
                        }
                    </div>
                    <span>
                        {data?.name || ''}
                    </span>
                </div>
            </div>
            <div className="map-popup_body">

                <Scrollbars style={{ height: '200px' }}>
                    {/*<div className="scroll">*/}
                    {fields.map(({ key, name, f }) => {
                        const value = f(data);

                        if (value) {
                            return (
                                <div
                                    className="info__item"
                                    key={key}
                                >
                                    <span className="info__item-title">
                                        {name}
                                        {': '}
                                    </span>
                                    <span>
                                        {value || messages.INFO_IS_NOT_FOUND}
                                    </span>
                                </div>
                            );
                        }

                        return null;

                    })}
                    {/*</div>*/}
                </Scrollbars>

            </div>
        </div>
    );
};

export default PopUp;