import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import * as actions from 'redux/TransportPassenger/actions';
import { clearValidation } from 'redux/Validation/actions';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { useStoreProp, useValidation } from 'helpers/hooks';
import ConfirmDelete from 'components/common/ConfirmModal';
import Attachments from 'components/common/Upload/Attachments';
import TextMaskPhone from 'components/common/TextMaskPhone';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';


const useStyles = makeStyles({
    uploadWrap: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        padding: '5px 0'
    },
    uploadTitle: {
        color: '#7E7E7E'
    },
    captcha: {
        width: 'fit-content',
        height: 70
    }
});

const RRModal = (
    {
        isNew,
        isOpen,
        onClose,
        el= {},
        statuses,
        tsTypes,
        transportTypes
    }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const captcha = useStoreProp(
        actions.loadCaptcha,
        'transportPassenger',
        'captcha'
    );

    const initialState = {
        status: isNew ? 1 || '' : el?.status || '',
        name: el?.name || '',
        inn: el?.inn || '',
        mail_address: el?.mail_address || '',
        juristic_address: el?.juristic_address || '',
        email: el?.email || '',
        phone: el?.phone || '',
        category_id: el?.category_id || '',
        type_transportation: el?.type_transportation || '',
        documents: el?.documents || [],
        feasibility_study: el?.feasibility_study || [],
        route_schedule_draft: el?.route_schedule_draft || [],
        route_scheme_draft: el?.route_scheme_draft || [],
        comment: el?.comment || '',
        captcha_value: ''
    };

    const [data, setData] = useState(initialState);

    const [delModalOpen, setDelModalOpen] = useState(false);
    const [indexOfDeletedElement, setIndexOfDeletedElement] = useState('');
    const [nameDocument, setNameDocument] = useState('');

    const onChange = (regReplace) => (e) => {
        const { name, value } = e.target;
        validation.deleteKey(name);
        setData({
            ...data,
            [name]: regReplace ? value?.replaceAll(regReplace, '') : value
        });
    };

    const deleteDocument = () => {
        const newAttach = data?.[nameDocument]
            .filter((el, index) => index !== Number(indexOfDeletedElement));
        setData({
            ...data,
            [nameDocument]: newAttach
        });
        setIndexOfDeletedElement('');
        setDelModalOpen(false);
    };

    const deleteAttachmentsHandler = (index, name) => {
        setIndexOfDeletedElement(index);
        setNameDocument(name);
        setDelModalOpen(true);
    };

    const onUploadFiles = (name, value) => {
        validation.deleteKey(name);
        setData({
            ...data,
            [name]: value
        });
    };

    const handleCreateRouteRequests = () => {
        const result = {
            ...data,
            captcha_key: captcha.key,
            // feasibility_study: data.feasibility_study.map(item => item.public_link),
            // route_schedule_draft: data.route_schedule_draft.map(item => item.public_link),
            // route_scheme_draft: data.route_scheme_draft.map(item => item.public_link),
        };
        dispatch(actions.createRouteRequest(result, (status) => {
            if (status === false) {
                dispatch(actions.loadCaptcha());
            }
        }));
    };

    const handleEditRouteRequests = () => {
        const result = {
            ...data,
            captcha_key: captcha.key
        };
        dispatch(actions.editRouteRequest(el.id, result));
    };

    const isDisabled = !data.name || !data.status || !data.inn || !data.mail_address
        || !data.juristic_address || !data.email  || !data.phone || !data.category_id
        || !data.type_transportation || !data.feasibility_study.length > 0
        || !data.route_schedule_draft.length > 0 || !data.route_scheme_draft.length > 0
        || ((data.status.toString() === '3' || data.status.toString() === '4') && !data.documents.length > 0)
        || ((data.status.toString() === '3' || data.status.toString() === '4') && !data.comment)
        || !data.captcha_value.length > 0;

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
            dispatch(clearValidation());
        }
    }, [dispatch, onClose, requestStatus, validation]);

    const renderFields = (label, name, row, type, required, inputComponent, reg) => {
        return (
            <FormInfoWrapper
                error={validation.isKey(name)}
                helperText={validation.get(name)}
                className={row ? 'row__item' : 'block'}
            >
                <FormControl
                    required={required}
                    size="small"
                    variant="outlined"
                >
                    <TextField
                        required={required}
                        label={label}
                        variant="outlined"
                        size="small"
                        name={name}
                        value={data?.[name]}
                        type={type}
                        onChange={onChange(reg)}
                        error={validation.isKey(name)}
                        InputProps={{
                            inputComponent,
                        }}
                    />
                </FormControl>
            </FormInfoWrapper>
        );
    };

    const renderUploadFields = (h2, name) => {
        return <>
            <h2>{h2}</h2>
            <Attachments
                service="transport-passenger"
                onChange={(url) => onUploadFiles(name, url)}
                files={data?.[name] || []}
                onlyLink
                isRequired
                error={validation.isKey(name)}
                helperText={validation.get(name)}
            />
        </>;
    };

    return (
        <>
            <Modal
                title={isNew ? titles.ADD_ROUTE_REQUEST: titles.EDIT_ROUTE_REQUEST}
                onClose={onClose}
                maxWidthProp={'sm'}
                noPadding={true}
                isOpen={isOpen}
                buttons={<FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        isNew
                            ? {
                                ...buttonsTypes.create,
                                onClick: handleCreateRouteRequests,
                                disabled: isDisabled
                            }
                            : {
                                ...buttonsTypes.save,
                                onClick: handleEditRouteRequests
                            }
                    ]}
                />}
            >
                <div className="modal__form">
                    <FormInfoWrapper
                        error={validation.isKey('status')}
                        helperText={validation.get('status')}
                        className="block"
                    >
                        <FormControl
                            variant="outlined"
                            size="small"
                        >
                            <InputLabel>{titles.SELECT_STATUS}</InputLabel>
                            <Select
                                label={titles.SELECT_STATUS}
                                value={isNew ? 1 || '' : data.status}
                                onChange={onChange()}
                                disabled={isNew}
                                name="status"
                            >
                                {isNew
                                    ? <MenuItem key={1} value={1}>{statuses[1]}</MenuItem>
                                    : (Object.keys(statuses).length > 0
                                        ? Object.keys(statuses).map((key) => (
                                            <MenuItem key={key} value={key}>
                                                {statuses[key]}
                                            </MenuItem>))
                                        : (<MenuItem disabled={true}>{messages.NO_DATA}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        error={validation.isKey('category_id')}
                        helperText={validation.get('category_id')}
                        className="block"
                    >
                        <FormControl
                            variant="outlined"
                            size="small"
                            required
                        >
                            <InputLabel required error={validation.isKey('category_id')}>{titles.SELECT_TYPE_TS}</InputLabel>
                            <Select
                                label={titles.SELECT_TYPE_TS}
                                value={data.category_id}
                                onChange={onChange()}
                                name="category_id"
                                error={validation.isKey('category_id')}
                            >
                                {tsTypes.length > 0
                                    ? tsTypes.map((item) => (
                                        <MenuItem key={item.id} value={item.id.toString()}>
                                            {item.name}
                                        </MenuItem>))
                                    : (<MenuItem disabled={true}>{messages.NO_DATA}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        error={validation.isKey('type_transportation')}
                        helperText={validation.get('type_transportation')}
                        className="block"
                    >
                        <FormControl
                            variant="outlined"
                            size="small"
                            required
                        >
                            <InputLabel required error={validation.isKey('type_transportation')}>{titles.SELECT_TYPE_TRANSPORTATION}</InputLabel>
                            <Select
                                label={titles.SELECT_TYPE_TRANSPORTATION}
                                value={data.type_transportation}
                                onChange={onChange()}
                                name="type_transportation"
                                error={validation.isKey('type_transportation')}
                            >
                                {Object.keys(transportTypes).length > 0
                                    ? Object.keys(transportTypes).map((key) => (
                                        <MenuItem key={key} value={key}>
                                            {transportTypes[key]}
                                        </MenuItem>))
                                    : (<MenuItem disabled={true}>{messages.NO_DATA}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </FormInfoWrapper>
                    {renderFields(
                        'Наименование организации (ИП)',
                        'name',
                        false,
                        'text',
                        true
                    )}
                    {renderFields(
                        titles.INN,
                        'inn',
                        false,
                        'text',
                        true,
                        null,
                        /[^0-9]+/g
                    )}
                    {renderFields(titles.MAIL_ADDRESS, 'mail_address',false, 'text', true)}
                    {renderFields(
                        'Юридический адрес',
                        'juristic_address',
                        false,
                        'text',
                        true
                    )}
                    <div className="row">
                        {renderFields(titles.EMAIL, 'email',true, 'text', true)}
                        {renderFields(
                            titles.PHONE,
                            'phone',
                            true,
                            'text',
                            true,
                            TextMaskPhone
                        )}
                    </div>

                    <FormInfoWrapper
                        error={validation.isKey('comment')}
                        helperText={validation.get('comment')}
                    >
                        <TextField
                            multiline
                            value={data.comment}
                            label={titles.COMMENT}
                            onChange={(e) => setData({ ...data, comment: e.target.value })}
                            required={data.status.toString() === '3' || data.status.toString() === '4'}
                            variant="outlined"
                            size="small"
                            name="comment"
                            error={validation.isKey('comment')}
                        />
                    </FormInfoWrapper>

                    {(data.status.toString() === '3' || data.status.toString() === '4') && (
                        renderUploadFields('Документы-основания', 'documents')
                    )}
                    {renderUploadFields('Технико-экономическое обоснование *', 'feasibility_study')}
                    {renderUploadFields('Проект расписания маршрута *', 'route_schedule_draft')}
                    {renderUploadFields(
                        'Проект схемы маршрута с указанием остановочных пунктов *',
                        'route_scheme_draft'
                    )}
                    {isNew && (
                        <div className="block">
                            <div style={{ alignItems: 'center' }} className="row">
                                <img className={classes.captcha} src={`${captcha?.img || ''}`}  alt="Captcha"/>
                                <Tooltip title="Обновить картинку">
                                    <IconButton
                                        size="medium"
                                        onClick={() => dispatch(actions.loadCaptcha())}
                                    >
                                        <i className="far fa-redo"/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            {renderFields(
                                'Введите текст с картинки',
                                'captcha_value',
                                false,
                                'text',
                                true
                            )}
                        </div>
                    )}
                </div>
            </Modal>
            {delModalOpen && (
                <ConfirmDelete
                    open={delModalOpen}
                    onClose={() => setDelModalOpen(false)}
                    onSuccess={() => deleteDocument()}
                    message={messages.CONFIRM_DELETE}
                />
            )}
        </>
    );
};

export default RRModal;
