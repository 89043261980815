import iconCluster from './icons/iconCluster';

const config = {
    name: 'Эко мониторинг',
    slug: 'ecology',
    mapContextMenu: false,
    clusterIcon: iconCluster,
    layerIcon: 'fas fa-leaf',
};
// добавление на карте
export default config;
