import React from 'react';
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
} from '@mui/material';

import renderAddress from '../../../../../helpers/renderAddress';
import { getHumanDate } from '../../../../../helpers/date.config';
import titles from '../../../../../helpers/constants/titles';
import messages from '../../../../../helpers/constants/messages';
import Loading from '../../../../common/Loading';


const Item = (props) => {
    const {
        category,
        type,
        fixated_at,
        address = {},
        name,
        status,
        categories = {},
        types = {},
        statuses = {},
        organization = {},
        isShowObjects = false,
        setIsShowObjects,
        isLoading = false,
    } = props;

    return (
        <>
            <div>
                <strong>Название элемента сети:</strong> {name}
            </div>
            <div>
                <strong>{titles.STATUS}:</strong> {statuses?.[status]?.name || messages.INFO_IS_NOT_FOUND}
            </div>
            <div>
                <strong>{titles.CATEGORY}:</strong> {categories?.[category] || messages.INFO_IS_NOT_FOUND}
            </div>
            <div>
                <strong>{titles.TYPE}:</strong> {types?.[type] || messages.INFO_IS_NOT_FOUND}
            </div>
            <div>
                <strong>{titles.ORGANIZATION}:</strong> {organization?.title || messages.INFO_IS_NOT_FOUND}
            </div>
            <div>
                <strong>{'Дата регистрации'}:</strong> {getHumanDate(new Date(fixated_at))}
            </div>
            {address && (
                <div>
                    <strong>{'Адрес дислокации'}:</strong> {renderAddress(address)}
                </div>
            )}
            <div>
                <FormControlLabel
                    checked={isShowObjects}
                    control={<Checkbox/>}
                    label="Показать объекты"
                    onClick={setIsShowObjects}
                    disabled={isLoading}
                />
            </div>
            {isLoading && <Loading circular/>}
        </>
    );
};

export default Item;
