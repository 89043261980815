import {
    Autocomplete,
    TextField,
    // FormControl,
    // InputLabel,
    // Select,
    // MenuItem,
} from '@mui/material';

import { loadCameraStatuses } from 'redux/VideoAnalytics/actions';
import { videoAnalyticsSelectors } from 'redux/VideoAnalytics';
import titles from 'helpers/constants/titles';
import { useStoreFromSelector } from 'helpers/hooks';
import SelectCameras from 'components/common/Autocomplete/CameraVideoanalytics';

// import useStoreProp from '../../../../../../helpers/hooks/useStoreProp';
// import { loadCameraInfo, loadCameraViolations } from '../../../../../../redux/VideoAnalytics/actions';
// import DateRange from '../../../../../common/Dates/DateRange';
// import { Autocomplete } from '@mui/material';
// import messages from '../../../../../../helpers/constants/messages';
// import MultipleInput from '../../../../../common/MultipleInput';
// import dateFormat from '../../../../../../helpers/constants/dateFormat';

const Form = (props) => {
    const { data, onChange } = props;
    const getValue = (key, def = '') => data[key] || def;

    // const violations = useStoreProp(loadCameraViolations, 'videoAnalytics', 'violations');
    // const infoList = useStoreProp(loadCameraInfo, 'videoAnalytics', 'infoList');
    const statuses = useStoreFromSelector(loadCameraStatuses, videoAnalyticsSelectors.statuses);

    // const checkboxes = [
    //     // {
    //     //     name: 'Искать авто с нераспознанным номером',
    //     //     id: 'without_grz'
    //     // },
    //     {
    //         name: 'Показать авто, у которых есть марка, модель и цвет',
    //         id: 'is_car'
    //     },
    //     {
    //         name: 'Показать события, в которых есть изображения',
    //         id: 'has_thumb'
    //     }
    // ];

    // const vehicle_brand = infoList.brand_and_model
    //     ? Object.keys(infoList.brand_and_model)
    //     : [];
    // const vehicle_model = data.vehicle_brand
    //     ? infoList.brand_and_model[data.vehicle_brand]
    //     : null;
    // const vehicle_color = infoList.color ? infoList.color : [];

    // const class_names = infoList.class_name ? infoList?.class_name : [];
    // const classNames = useMemo(() => class_names
    //     ?.reduce((res, itemObj) => [
    //         ...res,
    //         ...Object.keys(itemObj).reduce((r, key) => [
    //             ...r,
    //             {
    //                 id: key,
    //                 name: itemObj[key],
    //             },
    //         ], []),
    //     ], [])
    // , [class_names]);

    // убираем дефолтное значение
    // const eventTypes = Object
    //     .keys(violations)
    //     ?.filter((key) => key)
    //     .map(key => ({
    //         id: key,
    //         name: violations[key]
    //     }));

    // const vehicleColor = vehicle_color.map((color) => {
    //     return ({ id: color, name: color });
    //     // return ({ value: color,  label: color });
    // });

    // const handleEvent = (name) => (_, newValue) => {
    //     onChange({ [name]: newValue });
    // };

    // const handleTsNums = (newValue) => {
    //     onChange({ grz_list: newValue });
    // };

    // const handleChangeCheckboxes = (_, newValue) => {
    //     onChange({
    //         checkboxes: newValue
    //     });
    // };

    const handleChangeCamera = (values) => {
        onChange({ camera_id_list: values });
    };

    // const handleChangeSelects = (e) => {
    //     const { value, name } = e.target;
    //     onChange({ [name]: value });
    // };

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >

            {/* Камеры */}
            <div className="block">
                <SelectCameras
                    multiple
                    selected={data.camera_id_list || []}
                    onChange={handleChangeCamera}
                />
            </div>

            {/* Статус */}
            <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    multiple
                    value={getValue('status_list', [])}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={statuses}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, status_list) => onChange({ status_list })}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Статус"
                        />
                    )}
                />
            </div>

            {/* Тип событий */}
            {/* <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    multiple
                    value={getValue('violation_type_list', [])}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={eventTypes}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEvent('violation_type_list')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Тип событий"//{titles.TYPE_EVENTS}
                        />
                    )}
                />
            </div> */}

            {/* Исключить тип событий */}
            {/*<div className="block">*/}
            {/*    <Autocomplete*/}
            {/*        multiple*/}
            {/*        value={getValue('without_violation_type_list', [])}*/}
            {/*        options={eventTypes}*/}
            {/*        filterSelectedOptions={true}*/}
            {/*        getOptionLabel={(option) => option.name}*/}
            {/*        freeSolo={true}*/}
            {/*        size="small"*/}
            {/*        onChange={handleEvent('without_violation_type_list')}*/}
            {/*        renderInput={(params) => (*/}
            {/*            <TextField*/}
            {/*                {...params}*/}
            {/*                size="small"*/}
            {/*                variant="outlined"*/}
            {/*                label="Исключить тип событий"*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    />*/}
            {/*</div>*/}

            {/* Гос номер */}

            {/* <MultipleInput
                label="Гос номер ТС"
                value={getValue('grz_list', [])}
                onChange={handleTsNums}
                guideText={titles.WRITE_TRANSPORT_NUMBER}
            /> */}

            {/* Марка тс */}
            {/* <div className="block">
                <FormControl variant="outlined" size="small">
                    <InputLabel>Марка ТС</InputLabel>
                    <Select
                        label="Марка ТС"
                        name="vehicle_brand"
                        value={getValue('vehicle_brand', '')}
                        onChange={handleChangeSelects}
                    >
                        {vehicle_brand?.length ? vehicle_brand.map(key =>
                            <MenuItem key={key} value={key}>{key}</MenuItem>
                        ) : <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>}
                    </Select>
                </FormControl>
            </div>

            {data?.vehicle_brand && (
                <div className="block">
                    <FormControl variant="outlined" size="small">
                        <InputLabel>Модель ТС</InputLabel>
                        <Select
                            label="Модель ТС"
                            name="vehicle_model"
                            value={getValue('vehicle_model', '')}
                            onChange={handleChangeSelects}
                        >
                            {vehicle_model ? vehicle_model.map(item =>
                                <MenuItem key={item} value={item}>{item}</MenuItem>
                            ) : <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>}
                        </Select>
                    </FormControl>
                </div>
            )} */}

            {/* Класс */}
            {/* <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={getValue('class_name', null)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={classNames}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEvent('class_name')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Класс"
                        />
                    )}
                />
            </div> */}

            {/* Цвет */}
            {/* <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={getValue('vehicle_color', null)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={vehicleColor}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEvent('vehicle_color')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.COLOR}
                        />
                    )}
                />
            </div> */}

            {/*Начало периода*/}
            {/*Конец периода*/}
            {/* <div className="row">
                <DateRange
                    pickersFormat={dateFormat.DATE_WITH_HOUR_MIN_SECONDS}
                    views={['day', 'hours', 'minutes', 'seconds']}
                    className="row__item"
                    handleDate={(newDate) => onChange({ start_date: newDate })}
                    handleEndDate={(newDate) => onChange({ end_date: newDate })}
                    valueStartDate={getValue('start_date', null)}
                    valueEndDate={getValue('end_date', null)}
                    // startLabel={titles.START_PERIOD}
                    // endLabel={titles.END_PERIOD}
                />
            </div> */}

            {/*Доп опции*/}
            {/* <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    multiple
                    value={getValue('checkboxes', [])}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={checkboxes}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={handleChangeCheckboxes}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Дополнительные опции"
                        />
                    )}
                />
            </div> */}

        </form>
    );
};

export default Form;
