import React from 'react';
import PlatformPopUp from './PlatformPopUp';
import {
    getRouteColor
} from './helper';
import {
    PopUp,
    ToolTip,
    CircleMarker,
} from '../../leaflet';

const RouteMarkers = ({ item, handleBounds, hideStationsPopup = false, ...props }) => {
    const { category_id, num, stations } = item;
    const id = `${num}_${category_id}`;
    const color = getRouteColor(category_id);

    return (
        <>
            {stations && stations.map((station, index) => {
                const { id: stationId, name, check_point: { lat, lon } } = station;

                if (!lat || !lon) return null;

                return (
                    <CircleMarker
                        {...props}
                        key={`circlemarker_${id}_${stationId}_${index}`}
                        latlng={[lat, lon]}
                        radius={6}
                        weight={1}
                        color={color.outline}
                        fillColor={color.fill}
                        fillOpacity={1}
                    >
                        <CircleMarker
                            latlng={[lat, lon]}
                            radius={3}
                            weight={1}
                            color={color.outline}
                            fillColor="#fff"
                            fillOpacity={1}
                        >
                            {!hideStationsPopup && (
                                <PopUp>
                                    {/* Информация об остановке */}
                                    <PlatformPopUp
                                        map={props.map}
                                        station={station}
                                        route={num}
                                        category_id={category_id}
                                    />
                                </PopUp>
                            )}

                            <ToolTip
                                offset={[0, -5]}
                                //permanent={true}
                                direction="top"
                                //sticky={true}
                            >
                                <div>Остановка: {name}</div>
                            </ToolTip>
                        </CircleMarker>
                    </CircleMarker>
                );
            })}
        </>
    );
};

export default RouteMarkers;
