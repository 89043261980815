import { Chip, TableCell, TableRow } from '@mui/material';

import { loadRecognitionCameraDirTypes } from 'redux/DorisControl/actions';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';

import RenderMaterialRow from './RenderMaterialRow';

const TableItem = ({ item, duploCodes, complexTypes }) => {
    const dirTypes = useStoreProp(
        loadRecognitionCameraDirTypes,
        'dorisControl',
        'recognitionCameraDirTypes'
    );

    return (
        <>
            <TableRow>
                <TableCell colSpan={8} style={{
                    backgroundColor: '#ffffff',
                    left: 0,
                    position: 'sticky',
                }}>
                    <strong>
                        {item?.complex
                            ? <>
                                {item.complex?.name}{' '}
                                ({complexTypes[item?.complex?.type_id]}){' '}
                                <Chip label={item.complex?.serial_number} size={'small'}/>
                            </>
                            : messages.INFO_IS_NOT_FOUND
                        }
                    </strong>
                </TableCell>
                {/* отдельно стоящие пустые ячейки чтобы обеспечить статическую позицию ячейке с названием кфвф */}
                <TableCell colSpan={duploCodes.length} style={{ backgroundColor: '#ffffff', }}></TableCell>
            </TableRow>
            {item.data?.map((elem) => {
                return (
                    Object.entries(elem.events).map(([key, event], i) => {
                        const direction = elem.direction.find(el => Number(el.id) === Number(key))?.direction || dirTypes[key] || 'Не указано';
                        return (
                            <TableRow key={i}>
                                <TableCell style={{
                                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: '#f5f5f5',
                                    left: 0,
                                    position: 'sticky',
                                }}>
                                    {direction}{' '}
                                    {elem.code}
                                </TableCell>

                                <RenderMaterialRow
                                    event={event}
                                    direction={direction}
                                    item={elem.item}
                                    duploCodes={duploCodes}
                                />
                            </TableRow>
                        );
                    })
                );
            })}
        </>
    );
};

export default TableItem;
