import * as types from './types';

const initialState = {
    sidebar: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    sidebarLoading: false,
    sidebarParams: {
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25
    },
    filters: {},
    active: {},
    wsData: {},
    routesHistory: {},
    coords: [],
    coordsLoading: false,
    allVehicle: [],
    allVehicleLoading: false,
    types: {},
    history: {},
    historyLoading: false,

    /* color speed */
    colorSpeed: [],
    colorSpeedList: {
        data: []
    },
    colorSpeedListLoading: false,
    colorSpeedListProps: {
        page: 1,
        // limit: 2
        limit: Number(localStorage.getItem('limit')) || 25,
    },
    categoriesVehicle: [],
    categoriesVehicleLoaded: false,
    historyModal: [],
    historyModalLoading: false,
    vehicleActive: {},

    findVehicleLoading: false,
    showFindVehicles: null,

    brands: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    brandsLoading: false,

    models: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    modelsLoading: false,

    companies: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    companiesLoading: false,

    vehicles: {
        list: {
            data: [],
            meta: {
                last_page: 0
            }
        }
    },
    vehicleLoading: false,

    vehicleReport: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingVehicleReport: false,
    vehicleReportFilter: {},

    vehiclesTypes:{},

    vehicleCategories:[],

    vehicleCategoryList: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingVehicleCategoryList: false,

    employees: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    employeesLoading: false,

    positions: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    positionsLoading: false,

    brigades: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    brigadesLoading: false,

    parks: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingParks: false,
    vehicleDowntime: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    reportFuel: {},
    loadingReportFuel: false,
    routes: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingRoutes: false,
    routeTypes: [],
    routeStatuses: {},
    routeCheckpointsLoading: false,
    routeCheckpoints: {},
    telemetryReceivers: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    telemetryReceiversLoading: false,
    telemetryReceiverTypes: {},
    workorders: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingWorkorders: false,
    workordersByDate: {
        data: {
            data: [],
            headers: {}
        },
        meta: {
            last_page: 0,
        }
    },
    loadingWorkordersByDate: false,
    workorderEventStatuses: {},
    // currentWorkorders: {},
    currentWorkordersLoading: false,

    workorderLogs: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingWorkorderLogs: false,

    workorderManualStatusList: {},
    checkPoints: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    checkPointsLoading: false,
    reportVehicleMileage: [],
    loadingReportByVehicles: false,
    historyOption: {},
    checkPointsPolygon: [],
    // vehicle showcase
    vehicleStatistics: {
        graph: []
    },
    loadingVehicleStatistics: false,
    // workorder showcase
    workorderStatistics: {
        graph: []
    },
    loadingWorkorderStatistics: false,
    // устанавливаем фильтровать по организации из профиля
    filterWsOrganization: [],
    isFilterWsOrganization: false,
};

// фильтрация машин по сокетам
const filteredMarkers = (filters, markers, isFilterWsOrganization, filterWsOrganization) => {
    if (
        filters?.organization_id?.length > 0
        || filters?.number?.length > 0
        || filters?.category_id
        || filters?.brand_id_list?.length > 0
        || filters?.model_id_list?.length > 0
        || filters?.in_registry
        || filters?.in_work_order
        || isFilterWsOrganization
    ) {
        const organizations = filters?.organization_id?.map(({ id }) => parseInt(id)) || [];
        const grz = filters?.number?.toUpperCase().trim() || null;
        const categoryIdFilter = filters?.category_id?.id || null;
        const brands = filters?.brand_id_list?.map(({ id }) => parseInt(id)) || [];
        const models = filters?.model_id_list?.map(({ id }) => parseInt(id)) || [];
        const in_registry = filters?.in_registry?.id || null;
        const in_work_order = filters?.in_work_order?.id || null;

        // фильтруем
        return Object.keys(markers).reduce((res, key) => {
            const item = markers[key];
            const organization_id = parseInt(item?.vehicle?.organization_id) ?? null;
            const number = item?.vehicle?.number?.toUpperCase() || null;
            const categoryId = item?.vehicle?.category_id || null;
            const brandId = item?.vehicle?.brand_id || item?.brand?.id || null;
            const modelId = item?.vehicle?.model_id || item?.model?.id || null;

            // в реестре
            const isInRegistry = in_registry
                ? item?.in_registry === in_registry
                : true;

            // наряд
            const isInWorkOrder = in_work_order
                ? item?.in_work_order === in_work_order
                : true;

            // проверка по номеру
            const isInGrz = grz
                ? number && number?.includes(grz)
                : true;

            // проверка по организации
            const isInOrganizations = organizations.length
                ? organization_id && organizations.includes(parseInt(organization_id))
                : true;

            // проверка на соответствие организации
            const isInOrganizationsProfile = isFilterWsOrganization
                ? filterWsOrganization?.includes(parseInt(organization_id))
                : true;

            const isCategoryId = categoryIdFilter
                ? categoryId && `${categoryIdFilter}` === `${categoryId}`
                : true;

            const isBrands = brands.length
                ? brandId && brands.includes(parseInt(brandId))
                : true;

            const isModels = models.length
                ? modelId && models.includes(parseInt(modelId))
                : true;

            if (
                isInOrganizations
                && isInGrz
                && isCategoryId
                && isBrands
                && isModels
                && isInRegistry
                && isInWorkOrder
                && isInOrganizationsProfile
            ) {
                return {
                    ...res,
                    [key]: item,
                };
            }

            return res;
        }, {});
    }

    return null;
};

export default function reducer (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.LOADED_SIDEBAR:
            const page = payload.meta?.current_page || 1;

            if (page === 1) {
                return {
                    ...state,
                    sidebar: payload,
                };
            }

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...payload,
                    data: [
                        ...state?.sidebar?.data,
                        ...payload?.data
                    ]
                },
            };

        case types.CLEAR_SIDEBAR:
            return {
                ...state,
                sidebar: initialState.sidebar,
            };

        case types.LOADING_SIDEBAR:
            return {
                ...state,
                sidebarLoading: payload,
            };

        case types.SET_FILTER: {
            const newFilter = { ...payload };
            if (
                !newFilter?.brand_id_list
                || (newFilter?.brand_id_list && newFilter?.brand_id_list?.length === 0)
            ) {
                delete newFilter?.brand_id_list;
                delete newFilter?.model_id_list;
            }

            // фильтруем машины если есть по чему
            const wsData = filteredMarkers(
                newFilter,
                state.wsData,
                state.isFilterWsOrganization,
                state.filterWsOrganization
            );

            if (wsData) {
                return {
                    ...state,
                    filters: newFilter,
                    wsData
                };
            }
            return {
                ...state,
                filters: newFilter,
                sidebarParams: {
                    ...state.sidebarParams,
                    page: 1
                }
            };
        }

        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }

        case types.LOADED_COORDS:
            return {
                ...state,
                coords: payload,
            };

        case types.LOADING_COORDS:
            return {
                ...state,
                coordsLoading: payload,
            };

        case types.SET_WS_DATA: {
            if (Object.keys(payload).length) {
                // const diff = difference(Object.keys(payload), Object.keys(state.wsData));
                // const diffMarkers = diff.reduce((res, key) => ({ ...res, [key]: payload[key] }), {});

                // if (diff.length) {
                const markers = filteredMarkers(
                    state.filters,
                    // payload // diffMarkers
                    {
                        ...state.wsData,
                        ...payload
                    },
                    state.isFilterWsOrganization,
                    state.filterWsOrganization
                );

                if (markers) {
                    return {
                        ...state,
                        wsData: markers,
                        // wsData: {
                        //     ...state.wsData,
                        //     ...markers,
                        // },
                    };
                }

                return {
                    ...state,
                    wsData: {
                        ...state.wsData,
                        ...payload //...diffMarkers,
                    },
                };
            }

            return state;
        }

        case types.CLEAR_WS_DATA: {
            return {
                ...state,
                wsData: initialState.wsData,
            };
        }

        case types.LOADED_ALL_VEHICLES: {
            return {
                ...state,
                allVehicle: payload,
            };
        }

        case types.LOADING_ALL_VEHICLES: {
            return {
                ...state,
                allVehicleLoading: payload,
            };
        }

        case types.CLEAR_ALL_VEHICLES: {
            return {
                ...state,
                allVehicle: initialState.allVehicle,
            };
        }

        case types.LOADED_TYPES: {
            return {
                ...state,
                types: payload,
            };
        }

        case types.LOADED_HISTORY_VEHICLE: {
            const {
                data,
                id
            } = payload;
            return {
                ...state,
                history: {
                    ...state.history,
                    [id]: data
                },
            };
        }

        case types.CLEAR_HISTORY_VEHICLE: {
            return {
                ...state,
                history: initialState.history,
            };
        }

        case types.LOADING_HISTORY_VEHICLE: {
            return {
                ...state,
                historyLoading: payload,
            };
        }

        case types.SET_ROUTES_VEHICLE: {
            const {
                id,
                data,
            } = payload;

            return {
                ...state,
                routesHistory: {
                    ...state.routesHistory,
                    [id]: data,
                },
            };
        }

        case types.CLEAR_ROUTES_VEHICLE: {
            return {
                ...state,
                routesHistory: initialState.routesHistory,
            };
        }

        case types.CLEAR_ROUTES_VEHICLE_BY_ID: {
            const newRoutes = {
                ...state.routesHistory,
            };

            delete newRoutes[payload];

            return {
                ...state,
                routesHistory: newRoutes,
            };
        }

        case types.CLEAR_COORDS: {
            return {
                ...state,
                coords: initialState.coords,
            };
        }

        // установка маркеров
        case types.SET_MARKERS: {
            const {
                data, useFilter
            } = payload;
            const { filters } = state;

            const newMarkers = data.reduce((res, item) => {
                if (!res.hasOwnProperty(item.external_id)) {
                    return {
                        ...res,
                        [item.external_id]: item,
                    };
                }
                return res;
            }, state.wsData);

            if (useFilter) {
                const markers = filteredMarkers(
                    filters,
                    newMarkers,
                    state.isFilterWsOrganization,
                    state.filterWsOrganization
                );

                if (markers) {
                    return {
                        ...state,
                        wsData: {
                            // ...state.markers,
                            ...markers,
                        },
                    };
                }
            }

            return {
                ...state,
                wsData: newMarkers,
            };
        }

        case types.SET_SIDEBAR_PARAMS: {
            return {
                ...state,
                sidebarParams: {
                    ...state.sidebarParams,
                    ...payload,
                }
            };
        }

        case types.SET_VEHICLES_WS: {
            return {
                ...state,
                sidebarParams: {
                    ...state.sidebarParams,
                    page: 1,
                }
            };
        }

        /* color speed */
        case types.LOADED_COLOR_SPEED: {
            return {
                ...state,
                colorSpeed: payload
            };
        }
        case types.LOADED_COLOR_SPEED_LIST: {
            return {
                ...state,
                colorSpeedList: payload
            };
        }
        case types.LOADING_COLOR_SPEED_LIST: {
            return {
                ...state,
                colorSpeedListLoading: payload
            };
        }
        case types.SET_COLOR_SPEED_LIST_PROPS: {
            return {
                ...state,
                colorSpeedListProps: payload
            };
        }
        case types.RESET_COLOR_SPEED_LIST_PROPS: {
            return {
                ...state,
                colorSpeedListProps: initialState.colorSpeedListProps
            };
        }
        case types.LOADED_CATEGORIES_VEHICLE: {
            return {
                ...state,
                categoriesVehicle: payload,
                categoriesVehicleLoaded: true,
            };
        }
        case types.LOADED_VEHICLE_ACTIVE: {
            return {
                ...state,
                vehicleActive: {
                    ...state.active,
                    brand_name: payload?.brand_name,
                    model_name: payload?.model_name,
                    category_id: payload?.category_id,
                }
            };
        }

        case types.LOADED_VEHICLE_HISTORY_MODAL: {
            return {
                ...state,
                historyModal: payload
            };
        }
        case types.LOADING_VEHICLE_HISTORY_MODAL: {
            return {
                ...state,
                historyModalLoading: payload
            };
        }

        case types.LOADED_FIND_VEHICLES: {
            if (Object.keys(payload).length > 0 && payload?.external_id) {
                const external_id = payload.external_id;
                return {
                    ...state,
                    wsData: {
                        ...state.wsData,
                        [external_id]: {
                            ...payload
                        },
                    }
                };
            }

            return {
                ...state,
            };
        }
        case types.LOADING_FIND_VEHICLES: {
            return {
                ...state,
                findVehicleLoading: payload
            };
        }

        case types.SHOW_FIND_VEHICLES: {
            return {
                ...state,
                showFindVehicles: payload
            };
        }




        case types.LOADED_BRANDS:
            return {
                ...state,
                brands: payload,
            };
        case types.LOADING_BRANDS:
            return {
                ...state,
                brandsLoading: payload,
            };
        case types.LOADED_MODELS:
            return {
                ...state,
                models: payload,
            };

        case types.LOADING_MODELS:
            return {
                ...state,
                modelsLoading: payload,
            };
        case types.LOADED_COMPANIES:
            return {
                ...state,
                companies: payload,
            };
        case types.LOADING_COMPANIES:
            return {
                ...state,
                companiesLoading: payload,
            };
        case types.LOADING_VEHICLES_LIST:
            return {
                ...state,
                vehicleLoading: payload,
            };
        case types.LOADED_VEHICLES_LIST:
            return {
                ...state,
                vehicles: payload,
            };
        case types.LOADED_VEHICLE_REPORT:{
            return {
                ...state,
                vehicleReport: payload
            };
        }
        case types.LOADING_VEHICLE_REPORT:{
            return {
                ...state,
                loadingVehicleReport: payload
            };
        }
        case types.SET_VEHICLE_REPORT_FILTER:{
            return {
                ...state,
                vehicleReportFilter: payload
            };
        }
        case types.LOADED_VEHICLES_CATEGORIES:
            return {
                ...state,
                vehicleCategories: payload.data
            };
        case types.LOADED_VEHICLE_CATEGORY_LIST:
            return {
                ...state,
                vehicleCategoryList: payload
            };
        case types.LOADING_VEHICLE_CATEGORY_LIST:
            return {
                ...state,
                loadingVehicleCategoryList: payload
            };
        case types.LOADED_VEHICLES_TYPES:
            return {
                ...state,
                vehiclesTypes: payload.data
            };
        case types.LOADED_EMPLOYEES: {
            return {
                ...state,
                employees: payload
            };
        }
        case types.LOADING_EMPLOYEES: {
            return {
                ...state,
                employeesLoading: payload
            };
        }

        case types.LOADED_POSITIONS: {
            return {
                ...state,
                positions: payload
            };
        }
        case types.LOADING_POSITIONS: {
            return {
                ...state,
                positionsLoading: payload
            };
        }
        case types.LOADED_BRIGADES: {
            return {
                ...state,
                brigades: payload
            };
        }
        case types.LOADING_BRIGADES: {
            return {
                ...state,
                brigadesLoading: payload
            };
        }

        case types.LOADING_PARKS:
            return {
                ...state,
                loadingParks: payload
            };
        case types.LOADED_PARKS: {
            return {
                ...state,
                parks: payload
            };
        }

        case types.LOADED_VEHICLE_DOWNTIME:{
            return {
                ...state,
                vehicleDowntime: payload
            };
        }

        case types.LOADING_REPORT_FUEL: {
            return {
                ...state,
                loadingReportFuel: payload,
            };
        }

        case types.LOADED_REPORT_FUEL: {
            return {
                ...state,
                reportFuel: payload,
            };
        }

        case types.LOADED_ROUTES: {
            return {
                ...state,
                routes: payload
            };
        }
        case types.LOADING_ROUTES: {
            return {
                ...state,
                loadingRoutes: payload
            };
        }
        case types.LOADED_ROUTE_TYPES: {
            return {
                ...state,
                routeTypes: payload,
            };
        }
        case types.LOADED_ROUTE_STATUSES: {
            return {
                ...state,
                routeStatuses: payload.data
            };
        }
        case types.LOADING_ROUTE_CHECKPOINTS:
            return {
                ...state,
                routeCheckpointsLoading: payload
            };
        case types.LOADED_ROUTE_CHECKPOINTS:
            return {
                ...state,
                routeCheckpoints: {
                    ...state.routeCheckpoints,
                    [payload.id]: payload.data
                }
            };

        case types.LOADED_TELEMETRY_RECEIVERS:
            return {
                ...state,
                telemetryReceivers: payload,
            };

        case types.LOADING_TELEMETRY_RECEIVERS:
            return {
                ...state,
                telemetryReceiversLoading: payload,
            };

        case types.LOADED_TELEMETRY_RECEIVER_TYPES: {
            return {
                ...state,
                telemetryReceiverTypes: payload,
            };
        }

        // workorders
        case types.LOADED_WORKORDERS: {
            return {
                ...state,
                workorders: payload
            };
        }
        case types.LOADING_WORKORDERS: {
            return {
                ...state,
                loadingWorkorders: payload
            };
        }
        case types.LOADED_WORKORDERS_BY_DATE: {
            return {
                ...state,
                workordersByDate: payload
            };
        }
        case types.CLEAR_WORKORDERS_BY_DATE: {
            return {
                ...state,
                workordersByDate: {
                    data: {
                        data: [],
                        headers: {}
                    },
                    meta: {
                        last_page: 0,
                    }
                },
            };
        }
        case types.LOADING_WORKORDERS_BY_DATE: {
            return {
                ...state,
                loadingWorkordersByDate: payload
            };
        }
        case types.LOADED_WORKORDER_STATUSES: {
            return {
                ...state,
                workorderStatuses: payload
            };
        }
        case types.LOADED_WORKORDER_LOGS: {
            return {
                ...state,
                workorderLogs: payload
            };
        }
        case types.LOADING_WORKORDER_LOGS: {
            return {
                ...state,
                loadingWorkorderLogs: payload
            };
        }
        case types.LOADED_WORKORDER_LOG_MANUAL_STATUS: {
            return {
                ...state,
                workorderManualStatusList: payload
            };
        }
        case types.LOADED_CHECK_POINTS:
            return {
                ...state,
                checkPoints: payload,
            };

        case types.LOADING_CHECK_POINTS:
            return {
                ...state,
                checkPointsLoading: payload,
            };

        case types.LOADED_REPORT_BY_VEHICLES: {
            return {
                ...state,
                reportVehicleMileage: payload,
            };
        }
        case types.CLEAR_REPORT_BY_VEHICLES: {
            return {
                ...state,
                reportVehicleMileage: initialState.reportVehicleMileage,
            };
        }
        case types.LOADING_REPORT_BY_VEHICLES: {
            return {
                ...state,
                loadingReportByVehicles: payload,
            };
        }

        case types.SET_HISTORY_OPTION: {
            return {
                ...state,
                historyOption: payload,
            };
        }

        case types.LOADED_CHECK_POINT_POLYGON:
            return {
                ...state,
                checkPointsPolygon: payload,
            };
        case types.LOADING_CHANGE_ROUTE_STATUS: {
            return {
                ...state,
                loading: payload,
            };
        }

        case types.LOADED_VEHICLE_STATISTICS: {
            return {
                ...state,
                vehicleStatistics: payload
            };
        }

        case types.LOADING_VEHICLE_STATISTICS: {
            return {
                ...state,
                loadingVehicleStatistics: payload
            };
        }

        case types.LOADED_WORKORDER_STATISTICS: {
            return {
                ...state,
                workorderStatistics: payload
            };
        }

        case types.LOADING_WORKORDER_STATISTICS: {
            return {
                ...state,
                loadingWorkorderStatistics: payload
            };
        }
        case types.SET_FILTER_WS_ORGANIZATIONS: {
            const {
                filterWsOrganization,
                isFilterWsOrganization
            } = payload;

            // фильтруем если был транспорт
            const wsData = filteredMarkers(
                state.filters,
                state.wsData,
                isFilterWsOrganization,
                filterWsOrganization
            );

            if (wsData) {
                return {
                    ...state,
                    wsData,
                    filterWsOrganization,
                    isFilterWsOrganization,
                };
            }

            return {
                ...state,
                filterWsOrganization,
                isFilterWsOrganization
            };
        }

        default:
            return state;
    }
};
