import L from 'leaflet';
import { Marker, ToolTip } from '../../../leaflet';
import messages from '../../../../../helpers/constants/messages';
import {
    getCoords,
    createHtml,
    iconClasses,
} from './helper';
import { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    mapIcon: {
        backgroundColor: 'inherit',
    },
});

const Relations = ({
    list,
    title = '',
    ...props
}) => {
    const styles = useStyles();

    const markers = useMemo(() => {
        return list?.map((el, index) => {

            const item = {
                ...el,
                ...el?.entity_data,
            };

            const icon = L.divIcon({
                className: styles.mapIcon,
                html: createHtml('#0000FF', iconClasses[item.entity_type])
            });

            const coords = getCoords(item);

            if (coords) {
                return (
                    <Marker
                        key={index}
                        icon={icon}
                        latlng={coords}
                        attribution={{
                            color: '#0000FF'
                        }}
                        {...props}
                    >
                        <ToolTip
                            offset={[5, -7]}
                            direction="top"
                        >
                            <div>
                                <b>{title}</b> - <span>{item?.name || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                        </ToolTip>
                    </Marker>
                );
            }

            return null;
        });
    }, []);

    return markers;
};

export default Relations;