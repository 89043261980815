import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { mapSelectors } from 'redux/Map';

// позиционирование
const useStyles = makeStyles({
    loading: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9999
    }
});

// прилоадер для слоев карт
const MapLoading = ({ loading = false }) => {
    const classes = useStyles();

    const isLoading = useSelector(mapSelectors.mapLoading);

    if (loading || isLoading) return <LinearProgress className={classes.loading}/>;

    return null;
};

export default MapLoading;
