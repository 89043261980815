import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, TextField } from '@mui/material';

import { createDVR, editDVR } from 'redux/RoadNetwork/actions';
import { roadNetworksSelectors } from 'redux/RoadNetwork';
import { useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import buttons from 'helpers/constants/buttons';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import TextMaskIP from 'components/common/TextMaskIP';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import FieldsModal from 'components/common/Location/FieldsModal';
import type { DVRItem, } from 'types/RoadNetwork';

interface PointGeometry {
    type: 'Feature';
    geometry: {
        type: 'Point';
        coordinates: [number, number];
    };
}

interface ModalFormProps {
    isOpen: boolean;
    isNew?: boolean;
    onClose: () => void;
    reloadList: () => void;
    item?: DVRItem;
}

const ModalForm = ({ isOpen, isNew, onClose, reloadList, item }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingButton = useSelector(roadNetworksSelectors.loadingDVR);

    const initialState = {
        name: item?.name || '',
        address: item?.address || '',
        address_text: item?.address_text || '',
        lat: item?.lat || '',
        lon: item?.lon || '',
        ip_route: item?.ip_route || '',
        geometry: item?.geometry || '',
        vendor_id: 1,
        vendor_model_id: 1,
    };

    const [data, setData] = useState(initialState);

    const handleChange = (name: string, value: string) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const onChangeCoordinates = (params: { lat: string; lon: string }) => {
        const geometry = {
            type: 'Feature',
            geometry: {
                coordinates: [
                    Number(params.lon),
                    Number(params.lat)
                ],
                type: 'Point'
            }
        };

        setData((prevData) => ({
            ...prevData,
            ...params,
            ...(params.lat && params.lon ? { geometry: geometry } : {})
        }));
    };

    const handleChangeGeoJson = (name: keyof DVRItem, value: PointGeometry) => {
        const { geometry: { coordinates } } = value;

        setData(prev => ({
            ...prev,
            [name]: value,
            lat: String(coordinates[1]),
            lon: String(coordinates[0])
        }));
    };

    const onSave = useCallback(() => {
        const prepareData = removeEmptyFields(data);
        const callback = () => {
            onClose();
            reloadList();
        };

        if (isNew) {
            dispatch(createDVR(prepareData, callback));
        } else {
            dispatch(editDVR(item?.id, prepareData, callback));
        }
    }, [data, dispatch, isNew, item?.id, onClose, reloadList]);

    const isDisabled = !data.name || !data.address_text || !data.ip_route || !data.lat || !data.lon;

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            noPadding
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onSave,
                            loading: loadingButton,
                            disabled: isDisabled
                        }
                    ]}
                />
            }
        >
            <form className="modal__form">
                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                >
                    <TextField
                        value={data.name}
                        name="name"
                        onChange={(e) => handleChange('name', e.target.value)}
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        required
                        error={validation.isKey('name')}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('ip_route')}
                    helperText={validation.get('ip_route')}
                >
                    <TextField
                        value={data.ip_route}
                        name={'ip_route'}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        className="block"
                        label={titles.IP_ADDRESS}
                        variant={'outlined'}
                        size={'small'}
                        required
                        error={validation.isKey('ip_route')}
                        InputProps={{
                            inputComponent: TextMaskIP as any,
                        }}
                    />
                </FormInfoWrapper>
                <FormControl className="block" variant="outlined">
                    <LatLonCoordinates
                        {...data}
                        onChange={onChangeCoordinates}
                        required
                    />
                </FormControl>
                <FormControl className="block" variant="outlined">
                    <LoadAddressByCoords
                        {...data}
                        onChange={onChangeCoordinates}
                        isLoadOnLatLon
                    >
                        <AddressList required returnGeometry={undefined} />
                    </LoadAddressByCoords>
                </FormControl>
                <FieldsModal
                    title="Выбрать область на карте"
                    fields={{ geometry: data.geometry || {} }}
                    buttonText={buttons.SELECT_ON_MAP}
                    onChange={({ geometry }: { geometry: PointGeometry; }) => handleChangeGeoJson('geometry', geometry)}
                    fullWidth={false}
                    iconButton={null}
                    buttonVariant="contained"
                >
                    <MapGeoJson
                        polyline={false}
                        circle={false}
                        polygon={false}
                        marker={true}
                        isHideValidation
                    />
                </FieldsModal>
            </form>
        </Modal>
    );
};

export default memo(ModalForm);
