import React, { memo, useState } from 'react';
import { Map, MapControl, Marker, FeatureGroup, PolyLine } from '../../../../MapComponents/leaflet';
import L from 'leaflet';
import makeStyles from '@mui/styles/makeStyles';
import cameraIcon from '../../../../../img/icons/map/video-white.svg';
import { config } from '../../../../../config';

const useStyles = makeStyles({
    mapIcon: {
        backgroundColor: 'inherit'
    },
    mapIconDiv: {
        border: '3px solid white',
        borderRadius: '50%',
        width: 30,
        height: 30,
        position: 'relative'
    },
    img: {
        padding: 4,
        position: 'absolute',
        top: 0,
        left: -8,
        right: 0,
        bottom: 0,
        margin: 'auto'
    }
});

const CameraModal = ({ videoCameraList, formData, unselectCamera, camerasForGroup, isNew }) => {

    const styles = useStyles();
    const [mapCenter] = useState(config.get('mapCenter'));

    const createHtml = (isSelect) => {
        const color = isSelect ? '#FF2D00' : '#444';
        return (
            `<div class="${styles.mapIconDiv}" style="background-color: ${color}">
               <img class="${styles.img}" src="${cameraIcon}" alt="videoCamera" />
            </div>`
        );
    };

    const coordinates = formData.cameras?.map(el => [el.lat, el.lon]);

    const styleOutLine = {
        color: '',
        weight: 0,
        smoothFactor: 0
    };

    const markers = videoCameraList?.data.map(item => {
        const isSelect = formData.cameras.find(camera => camera.id === item.id);

        const icon = L.divIcon({
            className: styles.mapIcon,
            html: createHtml(isSelect)
        });
        return <Marker 
            key={item.id} 
            icon={icon} 
            latlng={[item?.lat, item?.lon]}
            onClick={() => {
                if(isSelect) {
                    return unselectCamera(item.id);
                }
                camerasForGroup(item);
            }} 
        />;
    });

    return(
        <div style={{ minWidth: '50%' }}>
            <Map
                center={mapCenter}
            >
                <MapControl>
                    <FeatureGroup mooveMapAfter={isNew}>
                        {markers}
                        {!isNew 
                        && <PolyLine
                            coordinates={coordinates}
                            style={styleOutLine}
                            moveToAfterInit={!isNew}
                        />}
                    </FeatureGroup>
                    
                </MapControl>
            </Map>
        </div>
    );
};

export default memo(CameraModal);