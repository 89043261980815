import React from 'react';
import { useSelector } from 'react-redux';
import {
    loadVehicleCategoryList,
    deleteVehicleCategory,
    createVehicleCategory,
    editVehicleCategory,
} from '../../../../../redux/TransportSpecial/actions';
import { transportSpecialSelectors } from '../../../../../redux/TransportSpecial';
import VehicleCategories from '../../../../common/Transport/VehicleCategories';

const VehicleTypes = () => {

    const list = useSelector(transportSpecialSelectors.vehicleCategoryList);
    const loading = useSelector(transportSpecialSelectors.loadingVehicleCategoryList);

    return (
        <>
            <VehicleCategories 
                list={list}
                loading={loading}
                loadList={loadVehicleCategoryList}
                createCategory={createVehicleCategory}
                editCategory={editVehicleCategory}
                deleteCategory={deleteVehicleCategory}
                fromService={'transportSpecial'}
            />
        </>
    );
};

export default VehicleTypes;
