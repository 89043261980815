import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'redux/WantedCars/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer, LSHeaderColumn, LSHeaderItem } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';

import Filter from './Filter.js';
import Item from './Item';
import ModalForm from './ModalForm';
import './style.scss';

const initialState = {
    grz: '',
    start_date: null,
    end_date: null,
};

function Wanted() {
    const dispatch = useDispatch();

    const {
        wanted: list,
        loading,
    } = useSelector(({ carsWanted }) => carsWanted);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: initialState });

    const reloadList = useCallback((shouldGoToPreviousPage = false) => {
        const result = removeEmptyFields(params.data, false);
        if (shouldGoToPreviousPage) {
            setParams({ ...result, page: params.page - 1 });
        } else {
            dispatch(actions.loadWantedList(params.page, limit, result));
        }
    }, [dispatch, limit, params]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    const renderContent = () => {
        return (list.data.length > 0
            ? (<LSContainer
                headers={[
                    { title: titles.NUMBER, width: '40%' },
                    { title: titles.BASIS_DEPOSIT, width: '50%' },
                    { isActions: true },
                ]}
            >
                {list.data.map((item) => (
                    <Item
                        key={item.id}
                        item={item}
                        reloadList={(isDelete) => reloadList(isDelete && (list.data.length === 1 && list.meta.last_page > 1))}
                    />
                ))
                }
            </LSContainer>)
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <div>
            <PageLayout
                header="Розыск"
                filters={<Filter setParams={setParams} loading={loading} initialState={initialState} />}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: list.meta,
                    limit,
                    setLimit
                }}
                content={renderContent}
                loading={loading}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtons
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setIsOpenModal(true)
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: list.meta.total
                }}
            />

            {isOpenModal && (
                <ModalForm
                    isNew={true}
                    isOpen={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </div>
    );
}

export default Wanted;
