import React, { useEffect } from 'react';
import {
    loadStationScheduleByDate,
    clearStationScheduleByDate,
} from '../../../../../../redux/TransportPassenger/actions';
import { useDispatch, useSelector } from 'react-redux';
import { dateWithDashYYYYMMDD } from '../../../../../../helpers/date.config';
import Loading from '../../../../../common/Loading';
import List from './List';
import { transportPassengerSelectors } from '../../../../../../redux/TransportPassenger';
import messages from '../../../../../../helpers/constants/messages';

const Stations = ({ id, route_num, vehicle_type }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const props = {};
        if (route_num) props.route_num = route_num;
        if (vehicle_type) props.vehicle_type = vehicle_type;
        dispatch(loadStationScheduleByDate(id, dateWithDashYYYYMMDD(new Date()), props));

        return () => {
            dispatch(clearStationScheduleByDate());
        };
    },[id, route_num, vehicle_type]);

    const stationByDate = useSelector(transportPassengerSelectors.stationByDate);
    const loadingStationByDate = useSelector(transportPassengerSelectors.loadingStationByDate);

    return (
        <div style={{ minHeight: '60px' }}>
            {loadingStationByDate && <Loading circular />}
            {
                loadingStationByDate === false
                && stationByDate?.length === 0
                && (
                    <div style={{ textAlign: 'center', padding: '1rem' }}>{messages.DATA_IS_NOT_FOUND}</div>
                )
            }
            {
                loadingStationByDate === false
                && stationByDate?.length > 0
                && (
                    <List list={stationByDate} />
                )
            }
        </div>
    );
};

export default Stations;
