import apiRwUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiRoadWorksMethods = {
    getList: async (params) => {
        const response = await instance.get(apiRwUrls.getList(), { params });
        return response?.data;
    },

    getFormats: async (params) => {
        const response = await instance.get(apiRwUrls.getFormats(), { params });
        return response?.data;
    },

    getFormatsNew: async (params) => {
        const response = await instance.get(apiRwUrls.getFormatsNew(params?.id));
        return response?.data;
    },

    getTemplates: async (config) => {
        const response = await instance.get(apiRwUrls.getTemplates(), config);
        return response?.data;
    },

    deleteReport: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteReport(id));
        return response?.data;
    },

    deleteAllReports: async (id) => {
        const response = await instance.post(apiRwUrls.deleteAllReports(), { user_id: id });
        return response?.data;
    },

    loadScheduledReport: async (params) => {
        const response = await instance.get(apiRwUrls.loadScheduledReport(), { params });
        return response?.data;
    },

    createScheduledReport: async (data) => {
        const response = await instance.post(apiRwUrls.createScheduledReport(), data);
        return response?.data;
    },

    editScheduledReport: async (id, data) => {
        const response = await instance.put(apiRwUrls.editScheduledReport(id), data);
        return response?.data;
    },

    deleteScheduledReport: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteScheduledReport(id));
        return response?.data;
    },

    loadScheduledReportTemplate: async () => {
        const response = await instance.get(apiRwUrls.loadScheduledReportTemplate());
        return response?.data;
    },

    loadPuidTFIndicators: async (params) => {
        const response = await instance.post(apiRwUrls.loadPuidTFIndicators(), params);
        return response?.data;
    },

    loadPuidTFIndicatorsConsolidate: async (params) => {
        const response = await instance.post(apiRwUrls.loadPuidTFIndicatorsConsolidate(), params);
        return response?.data;
    },

    loadReportTemplateFrontendLinks: async () => {
        const response = await instance.get(apiRwUrls.loadReportTemplateFrontendLinks());
        return response?.data;
    },

    loadApiReportTemplateFrontendLinkSaga: async (url, filter) => {
        const response = await instance.post(url, filter);
        return response?.data;
    },

};

export default ApiRoadWorksMethods;
