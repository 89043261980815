import titles from '../../../../helpers/constants/titles';
import { LSContainer } from '../../List';

const ModelListHeader = ({ children }) => {
    return (
        <LSContainer
            headers={[
                { title: titles.BRAND, width: '15%' },
                { title: titles.MODEL, width: '60%' },
                { title: 'Действия', isActions: true }
            ]}
        >
            {children}
        </LSContainer>
    );
};

export default ModelListHeader;
