import React, { useState } from 'react';
import CompanyRole from '../../../../common/Autocomplete/CompanyRole';
import { IconButton, TextField, Tooltip } from '@mui/material';
import titles from '../../../../../helpers/constants/titles';
import CloseIcon from '@mui/icons-material/Close';
import DateRange from '../../../../common/Dates/DateRange';
import { useValidation } from '../../../../../helpers/hooks';
import Attachments from '../../../../common/Upload/Attachments';
import ConfirmDelete from '../../../../common/ConfirmModal';
import messages from '../../../../../helpers/constants/messages';
import { dateWithDashYYYYMMDD } from '../../../../../helpers/date.config';

const Item = ({ item, onChange, onDelete }) => {
    const validation = useValidation();

    const [isDeleteItem, setIsDeleteItem] = useState(false);

    const changeRole = (role) => {
        const newItem = { ...item, role };
        onChange(newItem);
    };

    const confirmDeleteItem = () => {
        onDelete();
        setIsDeleteItem(false);
    };

    return (
        <div className="row align-items-center" style={{ backgroundColor: '#ebebeb', padding: '0.5rem' }}>
            <div className="row__item">
                <div className="block">
                    <CompanyRole
                        selected={item.role}
                        onChange={changeRole}
                        label="Роль"
                        required
                        error={!item.role?.id || validation.isKey('role')}
                        helperText={validation.get('role_id')}
                    />
                </div>
                <TextField
                    className="block"
                    variant="outlined"
                    value={item.contract_number || ''}
                    onChange={(e) => onChange({ ...item, [e.target.name]: e.target.value })}
                    label="Договор №"
                    size="small"
                    name="contract_number"
                    error={validation.isKey('contract_number')}
                    helperText={validation.get('contract_number')}
                />
                <div className="row">
                    <DateRange
                        dateOnly
                        valueStartDate={item.start_date}
                        valueEndDate={item.end_date}
                        handleDate={(e) => {
                            onChange({ ...item, start_date: e });
                            validation.deleteKey('start_date');
                        }}
                        handleEndDate={(e) => {
                            onChange({ ...item, end_date: e });
                            validation.deleteKey('end_date');
                        }}
                        startLabel="Начало действия"
                        endLabel="Конец действия"
                        disableFuture={false}
                        // renderError={() => dateWithDashYYYYMMDD(item.end_date) > dateWithDashYYYYMMDD(new Date())}
                    />
                </div>
                <div className="block">
                    <Attachments
                        onChange={(attachments) => onChange({ ...item, attachments })}
                        files={item.attachments || []}
                    />
                </div>
            </div>
            <div>
                <Tooltip title={titles.DELETE}>
                    <IconButton onClick={() => setIsDeleteItem(true)} size={'small'}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </div>
            {isDeleteItem && (
                <ConfirmDelete
                    open={isDeleteItem}
                    onClose={() => setIsDeleteItem(false)}
                    onSuccess={confirmDeleteItem}
                    message={messages.CONFIRM_DELETE}
                />
            )}
        </div>
    );
};

export default Item;
