import * as types from './types';

export const wsSetActive = (active = {}) => ({
    type: types.SET_ACTIVE,
    payload: active,
});

// грузим доступные пользователю каналы центрифуги
export const loadWsAvailableChannels = () => ({
    type: types.LOAD_WS_AVAILABLE_CHANNELS,
});

export const loadedWsAvailableChannels = (data) => ({
    type: types.LOADED_WS_AVAILABLE_CHANNELS,
    payload: data,
});

export const clearWsAvailableChannels = () => ({
    type: types.LOADED_WS_AVAILABLE_CHANNELS,
    payload: {},
});