import { useCallback, useMemo } from 'react';
import { loadScheduledReportTemplate } from '../../../redux/Reports/actions';
import { reportSelectors } from '../../../redux/Reports';
import useStoreFromSelector from '../useStoreFromSelector';

/*
    // провайдер шаблонов
    const templatesProvider = useReportTemplates();

    // объект шаблон по текущему url страницы
    templatesProvider.templateByUrl

        // получить название шаблона
        templatesProvider.getTemplateByUrl.templateName

        // получить id шаблона
        templatesProvider.getTemplateByUrl.templateId

    // все шаблононы
    templatesProvider.templates

    // все шаблононы массивом
    templatesProvider.convertTemplatesToList

    // шаблон по его id
    templatesProvider.getTemplateByTemplateId(templateId)
* */

// хук - шаблоны отчетов
const useReportTemplates = () => {
    // текушая страница
    const currentUrl = window?.location?.pathname || '';

    // объект шаблонов
    const templatesObject = useStoreFromSelector(
        loadScheduledReportTemplate,
        reportSelectors.scheduledReportTemplate
    );

    // все шаблоны в 1 массиве
    const getTemplatesList = useMemo(() => Object
        .values(templatesObject)?.flat?.() || []
    , [templatesObject]);

    // расширение шаблона
    const templateExtends = (value) => ({
        // все данные шаблона
        ...value,
        // для быстрого доступа
        get templateId() { return value?.template_id || null; },
        get templateName() { return value?.template_text || ''; },
    });

    // шаблон по url
    const getTemplateByUrl = useCallback(() => {
        const currentTemplate = getTemplatesList
            .find(el => el?.frontend_url === currentUrl) || {};
        return templateExtends(currentTemplate);
    }, [currentUrl, getTemplatesList]);

    return {

        // объект шаблонов как есть
        get templates() { return templatesObject; },

        // список шаблонов
        get convertTemplatesToList() { return getTemplatesList; },

        // получить шаблон по текущему url
        get templateByUrl() { return getTemplateByUrl(); },

        // получить шаблон по id шаблона
        getTemplateByTemplateId: (templateId) => {
            const currentTemplate = getTemplatesList()
                .find(el => el.template_id === templateId) || {};
            return templateExtends(currentTemplate);
        },

    };
};

export default useReportTemplates;
