const roadWorkTitles = {
    START_WORK: 'Начало работ',
    END_WORK: 'Окончание работ',
    ROADWORKS_VEHICLES: 'Дорожная техника',
    ROAD_WORKS: 'Дорожные работы',
    INFRASTRUCTURE: 'Инфраструктура',
    SELECT_INFRASTRUCTURE_OBJECT: 'Выберите объекты инфраструктуры',
    ADD_STAGE: 'Добавить этап',
    EDIT_STAGE: 'Редактировать этап'
};

export default roadWorkTitles;