import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadSidebar,
    setActive
} from 'redux/TrafficFlow/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import { clearFilters, setFilters } from 'redux/TrafficFlow/actions';
import messages from 'helpers/constants/messages';
import removeEmptyFields from 'helpers/removeEmptyFields';
import buttons from 'helpers/constants/buttons';
import colorExtend from 'helpers/mapHelper/colorExtend';
import Loading from 'components/common/Loading';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import getFilters from 'components/MapComponents/helpers/getFilters';
import SidebarItem from 'components/MapComponents/SidebarItem';

import icon from '../icons/icon';
import useForm from '../Form/useForm';
import config from '../config';

import Item from './Item';
import Form from './Form';

const SideBar = (props) => {
    const dispatch = useDispatch();
    const [params, setParams] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
    });

    const sidebar = useSelector(trafficFlowSelectors.sidebar);
    const data = useSelector(trafficFlowSelectors.sidebarData);
    const meta = useSelector(trafficFlowSelectors.sidebarMeta);
    const sidebarLoading = useSelector(trafficFlowSelectors.sidebarLoading);
    const active = useSelector(trafficFlowSelectors.active);
    const filters = useSelector(trafficFlowSelectors.filters);

    const activeId = active?.id || 0;

    const handleSuccess = () => {
        setParams(old => ({
            ...old,
            page: 1
        }));
    };
    const logicalPuidForm = useForm(handleSuccess);

    const fetchList = () => {
        const filter = removeEmptyFields(getFilters(filters));
        dispatch(loadSidebar(params.page, params.limit, filter));
    };

    const handleScrollUpdate = (values) => {
        const last_page = meta?.last_page || 0;
        if (
            values.top > 0.98
            && sidebarLoading === false
            && params.page < last_page
        ) {
            setParams(old => ({
                ...old,
                page: params.page + 1,
            }));
        }
    };

    // отслеживаем изменение проекта
    useEffect(() => {
        fetchList();
    }, [params, filters]);

    const renderList = (dataList) => (
        <>
            {dataList.map((item) => {
                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        icon={icon({ fill: colorExtend(item.color) })}
                        buttons={item.type_id === 4
                            ? {
                                edit: {
                                    title: buttons.EDIT,
                                    icon: <i className="fas fa-pen"/>,
                                    onClick: () => {
                                        logicalPuidForm.showEditForm(item);
                                    }
                                },
                                delete: {
                                    title: buttons.DELETE,
                                    icon: <i className="fas fa-trash-alt"/>,
                                    onClick: () => {
                                        logicalPuidForm.showConfirmDelete(item.id);
                                    }
                                }
                            }
                            : {}
                        }
                        // onClick={() => {
                        //     dispatch(setActiveMeteo(item));
                        // }}
                    >
                        <Item
                            {...item}
                            onClick={() => {
                                dispatch(setActive(item));
                            }}
                        />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                onSetFilter={(filter) => dispatch(setFilters({ ...filter }))}
                resetListPage={() => setParams(old => ({ ...old, page: 1 }))}
                onClearFilter={() => {
                    dispatch(clearFilters());
                    setParams(old => ({ ...old, page: 1 }));
                }}
                content={({ data, onChange }) => <Form data={data} onChange={onChange} />}
                layer={config.slug}
            />


            {sidebarLoading && data?.length === 0 ? (
                <Loading className="absolute bottom fill"/>
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal"/>)}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={config.name}
                            list={sidebar}
                        />
                        {data.length === 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList(data)}
                    </div>
                    {sidebarLoading && <Loading className="center"/>}
                </Scrollbars>
            )}

            {logicalPuidForm.renderComponent()}
        </div>
    );
};

export default SideBar;
