import React, { useMemo } from 'react';
import { Bar as GBar } from 'react-chartjs-2';
import { union } from 'lodash';

const Bar = ({
    data = [],
    legendPosition = 'bottom',
    horizontal = false,
    isStacked = false,
    displayLabels = true,
}) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: legendPosition,
                display: !isStacked,
            },
        },
        scales: {
            x: {
                stacked: isStacked,
                ticks: {
                    font: {
                        size: 10,
                    }
                },
                display: displayLabels
            },
            y: {
                stacked: isStacked,
            },
        },
        maintainAspectRatio: false,
    };

    if (horizontal) {
        options.indexAxis = 'y';
    }

    const dataG = useMemo(() => {
        const labels = data.reduce((res, { items }) => {
            const labelsItem = items.map(({ label }) => label);
            return union(res, labelsItem);
        }, []);

        const datasets = data?.map(({ items, color, name }) => {
            return {
                label: name,
                // borderColor: color,
                backgroundColor: color,
                data: labels.map(name => items.find(({ label }) => label === name)?.value || 0),
                // maxBarThickness: 80,
            };
        });

        return {
            labels,
            datasets
        };
    }, [data]);

    return <GBar options={options} data={dataG} style={{ maxHeight: '300px' }} />;
};

export default Bar;
