import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, isEqual } from 'lodash';

import * as actions from 'redux/VideoAnalytics/actions';
import { videoAnalyticsSelectors } from 'redux/VideoAnalytics';
import {
    Marker,
    PopUp,
    ToolTip,
} from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import MapLegends from 'components/common/Transport/MapLegends';
import mapHelper from 'helpers/mapHelper';
import { useDebounce, usePrevious, useStoreFromSelectorListToObject } from 'helpers/hooks';
import { useWsActive, useWsSubscribe } from 'helpers/ws/hooks';
import colorExtend from 'helpers/mapHelper/colorExtend';

import VideoAnalyticsPopup from './PopUp';
import config from './config.js';
import {
    createIconMarker,
    getFilter
} from './helper';
import iconMap from './icons/iconMap';
import Legend from './Legend';


const Layer = (props) => {
    const { map, visibleLegend = false } = props;
    const dispatch = useDispatch();

    const polygon = useSelector(videoAnalyticsSelectors.polygon);
    const active = useSelector(videoAnalyticsSelectors.active);
    const filters = useSelector(videoAnalyticsSelectors.filters);

    const activeId = Object.keys(active || {}).length > 0 ? active.id : 0;
    const prevFilters = usePrevious(filters);

    const statusesOb = useStoreFromSelectorListToObject(actions.loadCameraStatuses, videoAnalyticsSelectors.statuses);

    // подключение к каналу
    //// useWs(['video-analytics_camera']);
    useWsSubscribe('video-analytics_camera_update_model_v2', (events) => {
        const startDate = new Date(Date.now() - 5 * 60 * 1000);
        const updatedList = events
            ?.reduce((res, item) => {
                const updated_at = item?.updated_at || startDate;
                const updatedAt = new Date(updated_at);
                if (updatedAt > startDate) {
                    return [
                        ...res,
                        item,
                    ];
                }
                return  res;
            }, []);
        if (updatedList?.length > 0) {
            dispatch(actions.wsReplaceData(updatedList));
        }
    });

    useWsActive((activeData) => {
        dispatch(actions.setActive(activeData));
    });

    // отключение прогрузки полигона
    const loadedPolygon = useRef(true);
    const truedLoaded = useDebounce(() => { loadedPolygon.current = true; }, 1000);
    const disabledLoaded = () => {
        loadedPolygon.current = false;
        truedLoaded();
    };

    // грузим полигон
    const fetchPolygon = () => {
        if (loadedPolygon.current) {
            const polygon = mapHelper.getPolygon(map);
            const filter = getFilters(filters, getFilter);

            // оставляем только выбранные параметры
            // const smallFilter = pick(filter, ['camera_id_list']);
            dispatch(actions.getForPolygonVideoAnalytic(polygon, filter));
        }
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = debounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();

    useEffect(() => {
        // сдвигаем карту и зум
        if (active?.lat && active?.lon) {
            const { lat, lon } = active;
            if (Math.abs(lat) && Math.abs(lon)) {
                map.setView([lat, lon], 15);
            }
        }
    }, [active]);

    useEffect(() => {
        if (!isEqual(filters, prevFilters) || polygon.length === 0) {
            fetchPolygon();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters]);

    return (
        <>
            {polygon.map((item) => {
                const { id, lat, lon, status_color, status } = item;
                const color = status_color
                    ? colorExtend(status_color)
                    : colorExtend(statusesOb.get(status)?.color);

                const componentProps = {
                    ...props,
                    key: `item_${id}`,
                    attribution: {
                        slug: config.slug,
                        color
                    },
                    onClick: (latlng) => {
                        map.setView(latlng);
                        dispatch(actions.resetActive());
                        disabledLoaded();
                    },
                };

                const currentShow = activeId === id;
                if (currentShow) disabledLoaded();

                return (
                    <Marker
                        {...componentProps}
                        latlng={[lat, lon]}
                        icon={createIconMarker(iconMap({ fill: color }))}
                    >
                        <PopUp
                            minWidth={900}
                            show={currentShow}
                            onClose={() => {
                                loadedPolygon.current = true;
                                if (currentShow) {
                                    dispatch(actions.resetActive());
                                }
                            }}
                        >
                            <VideoAnalyticsPopup
                                // data={item}
                                uuid={id}
                            />
                        </PopUp>
                        <ToolTip
                            offset={[0, -40]}
                            direction="top"
                        >
                            <div>
                                {item.name}
                            </div>
                        </ToolTip>
                    </Marker>
                );
            })}

            <MapLegends
                layer="videoanalytics"
                visibleRequired={visibleLegend}
            >
                <Legend/>
            </MapLegends>
        </>
    );
};

export default Layer;
