import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import DtpTable from './DtpTable';
import { Grid } from '@mui/material';
import { Map, Marker, PopUp, ToolTip } from '../../../leaflet';
import { incidentsSelectors } from '../../../../../redux/Incidents';
import { useSelector } from 'react-redux';
import { createIconMarker, getColor } from '../../Incidents/helper';
import PopUpDtp from '../../Incidents/PopUp';
import { useMemo } from 'react';
import { fullDateTimeWithoutSeconds } from '../../../../../helpers/date.config';
import renderAddress from '../../../../../helpers/renderAddress';

const DtpListModal = ({ isOpen, onClose, data }) => {
    const types = useSelector(incidentsSelectors.dtpTypesData);

    const dtpMarkers = useMemo(() => {
        const color = '#676a679e';

        return (data.dtp_list || []).map((item) => (
            <Marker
                key={item.id}
                icon={createIconMarker(getColor(item, types))}
                latlng={[item.lat, item.lon]}
                attribution={{ color }}
            >
                <ToolTip
                    direction="top"
                    offset={[0, -40]}
                >
                    <div><b>ID:</b> {item.id}</div>
                    <div><b>Дата/время:</b> {fullDateTimeWithoutSeconds(item.created_at)}</div>
                    <div><b>Тип:</b> {item.dtp_type_name}</div>
                    <div><b>Количество погибших:</b> {item.dead?.toString?.() || '0'}</div>
                    <div><b>Количество раненых:</b> {item.wounded?.toString?.() || '0'}</div>
                    <div><b>Адрес:</b> {item.address_text || Object.keys(item.address || {}).length > 0
                        ? item.address_text || renderAddress(item.address)
                        : 'Информация отсутствует'}
                    </div>
                </ToolTip>
                <PopUp>
                    <PopUpDtp
                        dtpData={item}
                        readOnly
                    />
                </PopUp>
            </Marker>
        ),[]);
    }, [data.dtp_list, types]);

    return (
        <Modal
            title="Присвоенные ДТП"
            onClose={onClose}
            fullWidth
            isOpen={isOpen}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        }
                    ]}
                />
            }
        >
            <Grid container sx={{ height: '100%' }} spacing={3}>
                <Grid item xs={6}>
                    <div className="map-form-wrap__container" style={{ height: '100%' }}>
                        <Map
                            center={[data.lat, data.lon]}
                            zoom={15}
                        >
                            {dtpMarkers}
                        </Map>
                    </div>
                </Grid>
                <Grid item xs={6} >
                    <DtpTable
                        list={data.dtp_list || []}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default DtpListModal;
