import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import DateRange from 'components/common/Dates/DateRange';
import SelectRoutes from 'components/common/Autocomplete/PassengerTransport/Routes';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({ onSearch, statuses, categories, currentRoute }) => {
    const initialState = {
        start_at: null,
        end_at: null,
        status: '',
        // route: currentRoute || {},
        num_route: currentRoute ? currentRoute?.num : '',
        transport_type: currentRoute ? currentRoute?.category_id : '',
        name: ''
    };

    const [data, setData] = useState(initialState);

    const onChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    const handleChangeRoute = (value) => {
        setData({
            ...data,
            route: value,
            num_route: value.num,
            transport_type: value.category_id,
        });
    };

    const resetFilter = (needRefresh) => {
        setData(initialState);

        if (needRefresh) {
            search(initialState);
        }
    };

    const search = (filter = data) => {
        const correctStartDate = fullDateTimeWithTimeZone(filter.start_at);
        const correctEndDate = fullDateTimeWithTimeZone(filter.end_at);

        const prepareData = {
            ...filter,
            route: null,
            start_at: correctStartDate === 'Invalid date' ? null : correctStartDate,
            end_at: correctEndDate === 'Invalid date' ? null : correctEndDate,
        };

        const paramsWithoutEmpty = removeEmptyFields(prepareData, false);
        onSearch(paramsWithoutEmpty);
    };

    const updateFilter = (filters) => {
        setData({
            ...initialState,
            ...filters,
        });

        if (Object.keys(filters).length > 0) {
            search({
                ...initialState,
                ...filters,
            });
        }
    };

    const isDisabledResetBtn = !data.start_at
        && !data.end_at
        && !data.num_route
        && !data.priority
        && !data.status
        && !data.transport_type
        && !data.name;

    return (
        <div>
            <LayoutFilter
                filter={data}
                onSearch={search}
                onResetFilter={resetFilter}
                setUserFilter={updateFilter}
                disabled={isDisabledResetBtn}
                filterException={['start_at', 'end_at']}
            >
                <LayoutFilter.Visible>
                    <DateRange
                        handleDate={(e) => setData({ ...data, start_at: e })}
                        handleEndDate={(e) => setData({ ...data, end_at: e })}
                        valueStartDate={data.start_at}
                        valueEndDate={data.end_at}
                        disableFuture={false}
                    />
                    <FormControl size="small" variant="outlined">
                        <InputLabel>{titles.STATUS}</InputLabel>
                        <Select
                            value={data.status}
                            required
                            label={titles.STATUS}
                            onChange={onChange}
                            name="status"
                        >
                            {Object.keys(statuses)?.length
                                ? Object.keys(statuses)?.map((key) =>
                                    <MenuItem value={key} key={key}>{statuses[key]}</MenuItem>
                                )
                                : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </LayoutFilter.Visible>
                <LayoutFilter.Invisible>
                    {!currentRoute && <>
                        <SelectRoutes
                            selected={data.route}
                            onChange={handleChangeRoute}
                            label="Номер маршрута"
                        />
                        <FormControl size="small" variant="outlined">
                            <InputLabel>Вид транспорта</InputLabel>
                            <Select
                                value={data.transport_type}
                                onChange={onChange}
                                label="Вид транспорта"
                                name="transport_type"
                                disabled={!!data.num_route}
                            >
                                {categories?.length > 0
                                    ? categories?.map((el) =>
                                        <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                    )
                                    : <MenuItem value=" ">{messages.NO_DATA}</MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </>}
                    <TextField
                        variant="outlined"
                        value={data.name}
                        name="name"
                        onChange={onChange}
                        label={titles.NAME}
                        size="small"
                    />
                </LayoutFilter.Invisible>
            </LayoutFilter>
        </div>
    );
};

export default Filter;
