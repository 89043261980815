import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import titles from '../../../../../helpers/constants/titles';
import Modal from '../../../../common/Modal';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import messages from '../../../../../helpers/constants/messages';
import { useStoreProp, useValidation } from '../../../../../helpers/hooks';
import {
    createBrand,
    editBrand,
    loadVehicleCategories,
} from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';

function ModalForm({ open, onClose, item, isNew = false, reloadList }) {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingButton = useSelector(transportPassengerSelectors.loadingButton);

    const categories = useStoreProp(loadVehicleCategories, 'transportPassenger', 'vehicleCategories');

    const initialState = {
        name_ru: item?.name_ru || '',
        name_en: item?.name_en || '',
        slug: item?.slug || '',
        category_id: item?.category_id || categories?.[0]?.id
    };

    const [formData, setFormData] = useState(initialState);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        dispatch(isNew
            ? createBrand(formData, callback)
            : editBrand(item.id, formData, callback)
        );
    };

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: !formData.name_ru || !formData.name_en || !formData.slug,
                        loading: loadingButton
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <TextField
                    value={formData.name_ru}
                    name={'name_ru'}
                    onChange={handleChange}
                    className="block"
                    label={titles.BRAND_NAME_RU}
                    variant={'outlined'}
                    size={'small'}
                    required={true}
                    error={validation.isKey('name_ru')}
                    helperText={validation.get('name_ru')}
                />
                <TextField
                    value={formData.name_en}
                    name={'name_en'}
                    onChange={handleChange}
                    className="block"
                    label={titles.BRAND_NAME_EN}
                    variant={'outlined'}
                    size={'small'}
                    required={true}
                    error={validation.isKey('name_en')}
                    helperText={validation.get('name_en')}
                />
                <TextField
                    value={formData.slug}
                    name={'slug'}
                    onChange={handleChange}
                    className="block"
                    label={titles.SLUG}
                    variant={'outlined'}
                    size={'small'}
                    required={true}
                    error={validation.isKey('slug')}
                    helperText={validation.get('slug')}
                />
                <FormControl
                    variant="outlined"
                    size="small"
                    className="block"
                    error={!!validation.category_id}
                    required={true}
                >
                    <InputLabel>{titles.TYPE_OF_VEHICLE}</InputLabel>
                    <Select
                        label={titles.TYPE_OF_VEHICLE}
                        value={formData.category_id}
                        onChange={handleChange}
                        name={'category_id'}
                        required={true}
                    >
                        {categories?.length > 0
                            ? categories.map((el) =>
                                <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                            )
                            : <MenuItem disabled={true} value="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </div>
        </Modal>
    );
}

export default ModalForm;
