import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import queryString from 'query-string';
import { Grid } from '@mui/material';

import {
    addGroupToLoadingGroups,
    loadRoutes,
    loadRoutesGrouped,
    loadRouteSortDirections,
    loadRoutesTransportations,
    loadRouteWithCheckPoints,
    removeGroupFromLoadingGroups
} from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import PassportTabs from 'components/common/PassportTabs';
import type { PassportTabsRef } from 'components/common/PassportTabs';

import SortCheckbox from '../../RoadWorks/Orders/SortCheckbox';
import ScheduleTemplates from '../ScheduleTemplates';
import TrafficSchedule from '../TrafficSchedule';
import Schedule from '../Schedule';

import RouteGroupItem from './RouteGroupItem';
import AddEditCopyModal from './AddEditCopyModal';
import Filters from './Filters';
import { paramsToString } from './helper';
import RouteInfo from './PassportPage/BasicData';
import ExtraOptions from './PassportPage/ExtraOptions';
import RouteHistory from './PassportPage/BasicData/RouteHistory';
import { passengerTransportRouteURI } from './moduleConfig';

import type { Route } from 'types/Transport';
import type { Group } from './types';
import type { TrafficSchedule as ITrafficSchedule } from 'types';

type Page  = number;
interface Params {
    num_list?: [];
    status_list?: number[];
    category_id_list?: [];
    organization_id_list?: [];
}

function Routes() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const test_id_prefix = passengerTransportRouteURI;

    const typeTransportation = useStoreProp(
        loadRoutesTransportations,
        'transportPassenger',
        'transportations'
    );

    const orderDirections = useStoreProp(
        loadRouteSortDirections,
        'transportPassenger',
        'routeSortDirections'
    );

    const clickedGroup : { current : Group | null } = useRef(null);
    const tabsRef = useRef<PassportTabsRef>(null);
    const routesData: Route[] = useSelector(transportPassengerSelectors.routesData);
    const routesGroupedData: Group[] = useSelector(transportPassengerSelectors.routesGroupedData);
    const routesGroupedMeta = useSelector(transportPassengerSelectors.routesGroupedMeta);
    const loadingRoutesGrouped = useSelector(transportPassengerSelectors.loadingRoutesGrouped);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [modalOpen, setModalOpen] = useState(false);
    const [page, setPage] = useState<Page>(1);
    const [params, setParams] = useState<Params>({ status_list: [1, 2] });
    const [currentRoute, setCurrentRoute] = useState<Route | null>(null);
    const [openedGroups, setOpenedGroups] = useState<Group[]>([]);
    const [cachedRoutes, setCachedRoutes] = useState(routesData);
    const [sort, setSort] = useState<unknown[]>([]);
    const [focus, setFocus] = useState<number | null>(null);
    const [currentTemplate, setCurrentTemplate] = useState({});
    const [currentTrafficSchedule, setCurrentTrafficSchedule] = useState({});

    useEffect(() => () => {
        clickedGroup.current = null;
    }, []);

    const addParamsToUrl = useCallback((params: {}) => history.replace({
        pathname: '/dictionaries/passenger-transport/routes',
        search: paramsToString(params)
    }), [history]);

    const {
        page: queryPage,
        limit: queryLimit,
        group_order,
        group_num,
        category_id,
        route_id
    } = useMemo(() => queryString.parse(location.search, { arrayFormat: 'bracket' }), [location.search]);

    const loadRoutesAndCheckpoints = useCallback((group: Group) => {
        dispatch(addGroupToLoadingGroups(group));

        dispatch(loadRouteWithCheckPoints({ num: group.num, category_id: group.category_id }, () => {
            dispatch(removeGroupFromLoadingGroups(group));
        }));

        const id_list = group.routes?.map(({ id }) => id);
        const routesLength = group.routes?.length;
        dispatch(loadRoutes(1, routesLength, { id_list }));
    }, [dispatch]);

    const handleClickGroup = (group: Group) => {
        clickedGroup.current = group;
        const isOpen = openedGroups?.find(el => el.num === group.num && el.category_id === group.category_id && el.order === group.order);

        if (isOpen) {
            // если группа по которой произошел клик уже открыта, то закрываем ее
            setOpenedGroups(openedGroups.filter(el => el.order !== group.order ));
            const isOpenRouteInCurrentGroup = group_num === group.num && category_id === String(group.category_id) && group_order === String(group.order) && route_id;
            // если еще и маршрут внутри этой группы открыт, то убираем данные из url и закрываем маршрут
            if (isOpenRouteInCurrentGroup) {
                addParamsToUrl({ page, limit });
                setCurrentRoute(null);
            }
        } else {
            // если кликнутая группа закрыта, то открываем ее
            setOpenedGroups(prev => [...prev, group]);

            const loadedRoutes = cachedRoutes.filter(el => el.num === group.num && el.category_id === group.category_id && el.name === group.name);
            // если не нашли ни одного маршрута среди кэшированных, то загружаем их
            if (loadedRoutes.length === 0) {
                loadRoutesAndCheckpoints(group);
            }
        }
    };

    const handleClickRoute = (route: Route, group: Group) => {
        const { num, category_id, order } = group;

        setFocus(route.id);
        if (route.id === currentRoute?.id) {
            addParamsToUrl({ page, limit });
            setCurrentRoute(null);
        } else {
            addParamsToUrl({ page, limit, group_order: order, group_num: num, category_id, route_id: route.id });
            setCurrentRoute(route);
        }
    };

    const handleClickSTIcon = (scheduleTemplate: ITrafficSchedule) => {
        tabsRef.current?.changeTab('trafficSchedule');
        setCurrentTemplate(scheduleTemplate);
    };

    const switchToSchedule = (value: ITrafficSchedule) => {
        console.log(value);
        setCurrentTrafficSchedule(value);
        tabsRef.current?.changeTab('schedule');
    };

    const handleChangePage = (page: number, limit?: number) => {
        addParamsToUrl({ page, limit });
        setCurrentRoute(null);
    };

    // эффект следит за изменениями page и limit в url params
    useEffect(() => {
        if (!queryPage || !queryLimit) {
            // если в url нет полей page и limit, добавляем их туда
            addParamsToUrl({ page, limit });
        } else {
            const urlPage = Number(queryPage);
            const urlLimit = Number(queryLimit);

            if (page !== urlPage) {
                const lastPage = routesGroupedMeta?.last_page;

                if (lastPage !== 0) {
                    if (urlPage > lastPage || urlPage < 1) {
                        // если page содержит некорректное значение, сбрасывам до 1й страницы
                        addParamsToUrl({ page: 1, limit });
                    } else {
                        // если все нормально, обновляем стейт
                        setPage(urlPage);
                    }
                }
            }

            if (limit !== urlLimit) {
                if (urlLimit === 10 || urlLimit === 25 || urlLimit === 50 || urlLimit === 100) {
                    // если limit корректный, обновляем стейт
                    setLimit(urlLimit);
                } else {
                    // если limit содержит некорректное значение, ставим актуальное значение
                    addParamsToUrl({ page: urlPage, limit });
                }
            }
        }
    }, [addParamsToUrl, limit, page, queryLimit, queryPage, routesGroupedMeta]);

    useEffect(() => {
        // в случае если мы перешли с урла или обновился список
        if (category_id && group_num && route_id && group_order && routesGroupedData?.length > 0) {
            // найти открытую группу
            const group = routesGroupedData?.find(item => item.num === group_num && String(item.category_id) === category_id && String(item.order) === group_order);
            // найти открытый маршрут
            const route = cachedRoutes?.find(item => item.id === Number(route_id));

            if (group) {
                // открыть группу, если она еще не открыта
                setOpenedGroups(prev => {
                    const find = prev.find(el => el.num === group.num && group.category_id === el.category_id);
                    if (find) return prev;
                    return [...prev, group];
                });
            }

            if (route) {
                // открыть маршрут
                setCurrentRoute(route);
            } else if (group) {
                // если маршрут не найден, загружаем маршруты
                loadRoutesAndCheckpoints(group);
            }
        } else {
            setCurrentRoute(null);
        }
    }, [cachedRoutes, category_id, group_num, loadRoutesAndCheckpoints, route_id, routesGroupedData, group_order]);

    // эффект кэширует список роутов
    useEffect(() => {
        // setCachedRoutes(routes => [...routes, ...routesData]);
        setCachedRoutes(routesData);
    }, [routesData]);

    const reloadGroups = useCallback((data = params) => {
        dispatch(loadRoutesGrouped(page, limit, { ...data, sort }));
    }, [dispatch, limit, page, params, sort]);

    const reloadRoutes = useCallback(() => {
        // обновление самих роутов
        if (currentRoute) {
            const group = openedGroups?.find(el => currentRoute?.num === el?.num && currentRoute?.category_id === el?.category_id && currentRoute?.name === el.name);
            if (group) loadRoutesAndCheckpoints(group);
        } else if (clickedGroup.current) {
            loadRoutesAndCheckpoints(clickedGroup.current);
        }
    }, [currentRoute]);

    const reloadList = () => {
        reloadGroups();
        reloadRoutes();
    };

    useEffect(() => {
        reloadGroups();
    }, [reloadGroups]);


    const groupRouteList = () => {
        return (
            <LSContainer
                headers={[
                    { title: titles.TYPE_OF_VEHICLE, width: '10%' },
                    { title: titles.ROUTE_NUM, width: '10%' },
                    { title: titles.NAME, width: '60%' },
                    { title: 'Действия', align: 'right', isActions: true }
                ]}
                isMobile={!!currentRoute}
            >
                {routesGroupedData?.map((group) => (
                    <RouteGroupItem
                        key={`${group?.num}${group.category_id}${group.order}`}
                        data={group}
                        currentRoute={currentRoute}
                        onClickRoute={(route: Route, data: Group) => handleClickRoute(route, data)}
                        isOpenGroup={openedGroups.find(el => group?.num === el?.num && group?.category_id === el?.category_id && group?.name === el?.name)}
                        onClickGroup={() => handleClickGroup(group)}
                        typeTransportation={typeTransportation}
                        reloadList={reloadList}
                        focus={focus}
                        test_id_prefix={test_id_prefix}
                    />
                ))}
            </LSContainer>
        );
    };

    const renderContent = () =>
        <div style={{
            minWidth: '60%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            {routesGroupedData?.length > 0
                ? <>
                    <Grid container style={currentRoute ? { height: '100%' } : {}}>
                        <Grid item xs={ currentRoute ? 3 : 12 } style={{ height: '100%' }}>
                            {currentRoute
                                ? (
                                    <Scrollbars style={{ height: '100%' }} >
                                        {groupRouteList()}
                                    </Scrollbars>
                                )
                                : groupRouteList()
                            }
                        </Grid>
                        {currentRoute && (
                            <Grid item xs={9}>
                                <PassportTabs ref={tabsRef} tabs={[
                                    {
                                        value: 'data',
                                        label: titles.BASIC_DATA,
                                        icon: <i className="fal fa-info-square"/>,
                                        contentComponent: <RouteInfo
                                            currentRoute={currentRoute}
                                            typeTransportation={typeTransportation}
                                            reloadList={reloadList}
                                        />
                                    },
                                    {
                                        value: 'extraOptions',
                                        label: 'Дополнительные параметры',
                                        icon: <i className="far fa-list-alt"/>,
                                        contentComponent: <ExtraOptions
                                            currentRoute={currentRoute}
                                            typeTransportation={typeTransportation}
                                        />
                                    },
                                    {
                                        value: 'history',
                                        label: titles.HISTORY_OF_CHANGES,
                                        icon: <i className="fal fa-history"/>,
                                        contentComponent: <RouteHistory currentRoute={currentRoute} />,
                                    },
                                    {
                                        value: 'scheduleTemplates',
                                        label: 'Шаблоны расписаний',
                                        icon: <i className="fal fa-table"/>,
                                        contentComponent: <ScheduleTemplates
                                            currentRoute={currentRoute}
                                            handleClickSTIcon={handleClickSTIcon}
                                            reloadRoutesList={reloadList}
                                        />
                                    },
                                    {
                                        value: 'trafficSchedule',
                                        label: 'Графики движения',
                                        icon: <i className="fal fa-code-branch"/>,
                                        contentComponent: <TrafficSchedule
                                            currentRoute={currentRoute}
                                            currentTemplate={currentTemplate}
                                            switchToSchedule={switchToSchedule}
                                        />
                                    },
                                    {
                                        value: 'schedule',
                                        label: 'Расписания',
                                        icon: <i className="far fa-list-alt"/>,
                                        contentComponent: <Schedule
                                            currentRoute={currentRoute}
                                            currentTrafficSchedule={currentTrafficSchedule}
                                        />,
                                    },
                                ]}/>
                            </Grid>
                        )}
                    </Grid>
                </>
                : (!loadingRoutesGrouped && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </div>;

    return (
        <div style={{
            ...(currentRoute ? {} : {
                display: 'flex',
                flexDirection: 'column',
            }),
            height: '100%'
        }}>
            <PageLayout
                header={titles.ROUTES}
                filters={
                    <Filters
                        loadParams={(newFilter: Params) => {
                            setParams(newFilter);
                            if (page === 1) {
                                reloadGroups(newFilter);
                            }
                            // меняем урл, чтобы сбросить выбранный маршрут
                            addParamsToUrl({ page: 1, limit });
                        }}
                        test_id_prefix={test_id_prefix}
                    />
                }
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setModalOpen(true),
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: routesGroupedMeta?.total,
                }}
                actionPanel={
                    <SortCheckbox
                        data={sort}
                        sortedList={orderDirections}
                        onChange={(value: unknown[]) => setSort(value)}
                        test_id_prefix={test_id_prefix}
                    />
                }
                loading={loadingRoutesGrouped}
                content={renderContent}
                paginationProps={{
                    loadList: handleChangePage,
                    list: routesGroupedMeta,
                    limit: limit,
                    setLimit: (limit) => setLimit(limit),
                }}
                customStyles={{ ...(currentRoute && { padding: 0 }) }}
            />
            {modalOpen && (
                <AddEditCopyModal
                    isOpen={modalOpen}
                    onClose={setModalOpen}
                    typeTransportation={typeTransportation}
                    isNew
                    reloadList={reloadList}
                    isCopy={false}
                />
            )}
        </div>
    );
}

export default Routes;
