import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { loadSpeedViolationsReport } from 'redux/TransportPassenger/actions';
import PageLayout from 'components/layout/PageLayout';
import ReportBlock from 'components/common/Report/ReportBlock';
import { LSContainer } from 'components/common/List';
import messages from 'helpers/constants/messages';
import { dateWithDashYYYYMMDD, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';

import Filter from './Filter';
import Item from './Item';

const SpeedViolations = () => {
    const dispatch = useDispatch();
    const speedViolationsReportData = useSelector(transportPassengerSelectors.speedViolationsReportData);
    const meta = useSelector(transportPassengerSelectors.speedViolationsReportMeta);
    const loadingSpeedViolationsReport = useSelector(transportPassengerSelectors.loadingSpeedViolationsReport);

    const initialFilter = {
        grz : '',
        date_start : getStartOf('year'),
        date_end: getEndOf('day'),
        route_num : ''
    };

    const [filter, setFilter] = useState(initialFilter);
    const [params, setParams] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
        query: initialFilter
    });

    const getFilter = (data) => {
        const newFilter = {
            ...data,
            grz : data.grz?.number?.toLowerCase(),
            date_start: dateWithDashYYYYMMDD(data.date_start),
            date_end: dateWithDashYYYYMMDD(data.date_end),
            route_num: data?.route_num?.num,
        };

        return removeEmptyFields(newFilter);
    };

    const onSearch = (newFilter = filter) => {
        setParams(prev => ({
            ...prev,
            page: 1,
            query: newFilter
        }));
    };

    const getReport = (format) => {
        dispatch(loadSpeedViolationsReport(
            1,
            params.limit,
            { ...getFilter(filter), report: format }
        ));
    };

    useEffect(() => {
        const { page, limit, query } = params;
        dispatch(loadSpeedViolationsReport(page, limit, getFilter(query)));
    }, [dispatch, params]);

    const renderContent = () => {
        return (speedViolationsReportData?.length > 0
            ? <>
                <LSContainer
                    headers={[
                        { title: '№', width: '4%' },
                        { title: 'Дата', width: '12%' },
                        { title: '№ марш.', width: '8%' },
                        { title: titles.GRZ, width: '8%' },
                        { title: 'Скорость', width: '10%' },
                        { title: 'Ограничение', width: '12%' },
                        { title: <span>Время<br/>начала</span>, width: '10%' },
                        { title: '№ наряда', width: '10%' },
                        { title: <span>Широта/<br/>Долгота</span>, width: '12%' },
                    ]}
                >
                    {speedViolationsReportData?.map((item, index) => (
                        <Item
                            key={index}
                            item={item}
                            index={index}
                        />
                    ))}
                </LSContainer>
            </>
            : !loadingSpeedViolationsReport && <div>{messages.DATA_IS_NOT_FOUND}</div>
        );
    };

    return (
        <PageLayout
            header="Нарушения скоростного режима"

            filters={
                <Filter
                    filter={filter}
                    setFilter={setFilter}
                    onSearch={onSearch}
                />
            }

            loading={loadingSpeedViolationsReport}

            informPanelProps={{
                buttons: (
                    <ReportBlock
                        onGetReport={getReport}
                        disabled={!filter.date_start || !filter.date_start}
                    />
                ),
                total: meta?.total
            }}

            content={renderContent}

            paginationProps={{
                loadList: (page) => setParams(prev => ({ ...prev, page })),
                list: meta,
                limit: params.limit,
                setLimit: (limit) => setParams(prev => ({ ...prev, limit })),
            }}
        />
    );
};

export default SpeedViolations;
