import iconCluster from './icons/iconCluster';
import { clearFilters } from '../../../../redux/RoadWorks/actions';

const config = {
    name: 'Участки',
    slug: 'roadworks',
    mapContextMenu: {
        title: 'Добавить участок',
        event: 'add_road_work',
        // className: styles.button,
        minWidth: 210,
    },
    // маркеров нет - только область и круг
    clusterIcon: iconCluster,
    onAfterActivateAction: () => clearFilters(),
    layerIcon: 'fas fa-digging',
};
// добавление на карте
export default config;
