import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import GradientLine from './GradientLine';
import colorsCB from './colorsCB';

// слои тепловой карты
const HeadSelectedList = (props) => {
    const {
        data = [],
        value = {},
        onChange = (obj) => {},
        timeSlider,
    } = props;

    useEffect(() => {
        if (
            Object.keys(value).length === 0
            && data.length > 0
        ) {
            const obj = data?.reduce((res, { id }) =>({ ...res, [id]: id === 1 }), {});
            onChange(obj);
        }
    }, [data, value]);

    const handleChange = (e) => {
        const {
            name,
            checked,
        } = e?.target;

        onChange({ [name]: checked });
    };
    return (
        <div className="row" style={{ marginBottom: '.5rem' }}>
            {data?.map((item) => (
                <div
                    key={item?.id}
                    className="row__item"
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <FormControlLabel
                        style={{
                            pointerEvents: 'none',
                        }}
                        control={
                            <Checkbox
                                checked={value[item?.id] || false}
                                onChange={handleChange}
                                name={item?.id.toString()}
                                color="primary"
                                style={{
                                    pointerEvents: 'auto',
                                    color: colorsCB[item?.id] || '',
                                }}
                            />
                        }
                        label={item?.title || ''}
                    />

                    <GradientLine
                        item={item}
                        timeSlider={timeSlider}
                    />
                </div>
            ))}
        </div>
    );

};

export default HeadSelectedList;