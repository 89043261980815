import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dorisControlSelectors } from 'redux/DorisControl';
import { delete1CName } from 'redux/DorisControl/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';

import ModalForm from './ModalForm';

import type { ComplexName } from './types';

interface ItemProps {
    item: ComplexName;
    reloadList: (isDelete?: boolean) => void;
}

const Item = ({ item, reloadList }: ItemProps) => {
    const dispatch = useDispatch();

    const loadingBtn: boolean = useSelector(dorisControlSelectors.loadingButton);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleDelete = () => {
        dispatch(delete1CName(item.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn >
                    {item?.name || ''}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openEditModal && (
                <ModalForm
                    item={item}
                    onClose={() => setOpenEditModal(false)}
                    reloadList={reloadList}
                />
            )}

            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onSuccess={handleDelete}
                    loadingButton={loadingBtn}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
