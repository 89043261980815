import Modal from '../Modal';

interface VideoModalProps {
    isOpen: boolean
    onClose: () => void
    link: string
    title: string
}

const FrameModal = ({ isOpen, onClose, link, title }: VideoModalProps) => {
    // const mockLink = 'http://200.14.1.57:86/webview/video?auto_logon=&close_current_session=&command=show_monitor&monitor_id=1&camera_list=128&time=&login=web&password=web&transport=4&language=1&layout=1&geometry=&mode=0&stream_role=&bounding_boxes=';
    return (
        <Modal
            title={title}
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            medium
            showCloseInTitle
        >
            <div style={{ height: 500 }}>
                {link && (
                    <iframe
                        id="webview"
                        src={link}
                        title="camera"
                        name="output_frame"
                        height="100%"
                        width="100%"
                        // sandbox="allow-same-origin allow-scripts allow-forms"
                    >
                    </iframe>
                )}  
            </div>
        </Modal>
    );
};

export default FrameModal;
