import EditProfileUser from '../pages/Profile/EditProfileUser';
import ChangePassword from '../pages/Profile/ChangePassword';
import MyReports from '../pages/Profile/MyReports';
import DashboardsSetting from '../pages/Dictionaries/Efficiency/DashboardsSetting';
import { MobileApp } from '../pages/Reports/MobileApp';
import ScheduledReports from '../pages/Profile/ScheduledReports';

const routes = [
    {
        path: '/profile/edit',
        component: EditProfileUser,
        exact: true,
    },
    {
        path: '/profile/change-password',
        component: ChangePassword,
        exact: true,
    },
    {
        path: '/my-reports',
        component: MyReports,
        exact: true,
    },
    
    {
        path: '/mobile-app',
        component: MobileApp,
        exact: true,
    },
    {
        path: '/dashboards-setting',
        component: DashboardsSetting,
        exact: true,
    },

    {
        path: '/scheduled-reports',
        component: ScheduledReports,
        exact: true,
    },

];

export default routes;
