import * as types from './types';

/**
 * Actions
 **/

export const setDadataLoading = (bool = false) => ({
    type: types.SET_DADATA_LOADING,
    payload: bool,
});


// список компаний
// todo вынесен в отдельный модуль - переписать на этот
export const loadDadataCompaniesList = (page = 1, limit = 10, query) => ({
    type: types.LOAD_DADATA_COMPANIES,
    payload: {
        page,
        limit,
        query,
    },
});

export const loadedDadataCompaniesList = (data) => ({
    type: types.LOADED_DADATA_COMPANIES,
    payload: data,
});


// список адресов поиск
export const loadDadataAddressList = (limit = 10, query) => ({
    type: types.LOAD_DADATA_ADDRESS_LIST,
    payload: {
        limit,
        query,
    },
});

export const loadedDadataAddressList = (data) => ({
    type: types.LOADED_DADATA_ADDRESS_LIST,
    payload: data,
});

export const clearDadataAddressList = () => ({
    type: types.CLEAR_DADATA_ADDRESS_LIST,
});

export const loadDadataAddress = (query, options = {}) => ({
    type: types.LOAD_DADATA_ADDRESS,
    payload: {
        query,
        ...options
    }
});
// как работает limit ??? всегда возвращается один адрес
// export const loadDadataAddress = (limit = 10, query) => ({
//     type: types.LOAD_DADATA_ADDRESS,
//     payload: {
//         limit,
//         query
//     }
// });

export const loadedDadataAddress = (data = {}) => ({
    type: types.LOADED_DADATA_ADDRESS,
    payload: data,
});

export const loadAutoCompleteAddress = (params) => ({
    type: types.LOAD_AUTOCOMPLETE_ADDRESS,
    payload: params
});

export const loadedAutoCompleteAddress = (data) => ({
    type: types.LOADED_AUTOCOMPLETE_ADDRESS,
    payload: data
});

export const loadingAutoCompleteAddress = (bool = false) => ({
    type: types.LOADING_AUTOCOMPLETE_ADDRESS,
    payload: bool
});

export const clearDadataAddress = () => ({
    type: types.LOADED_DADATA_ADDRESS,
    payload: {},
});

// export const loadingDadataAddress = (bool = false) => ({
//     type: types.LOADING_DADATA_ADDRESS,
//     payload: bool
// });

// адрес по координатам
export const loadDadataAddressByCoordinates = (lat, lon) => ({
    type: types.LOAD_DADATA_ADDRESS_BY_COORDINATES,
    payload: {
        lat,
        lon
    },
});

export const loadedDadataAddressByCoordinates = (data) => ({
    type: types.LOADED_DADATA_ADDRESS_BY_COORDINATES,
    payload: data,
});

export const clearDadataAddressByCoordinates = () => ({
    type: types.LOADED_DADATA_ADDRESS_BY_COORDINATES,
    payload: {},
});


// export const loadCompany = (page = 1, limit = 10, query) => ({
//     type: types.LOAD_COMPANY,
//     payload: {
//         page,
//         limit,
//         query,
//     },
// });
//
// export const loadedCompany = (data) => ({
//     type: types.LOADED_COMPANY,
//     payload: data
// });

//UAPTS-1519
export const loadGeocodeAddress = (limit = 20, query, filter = {}) => ({
    type: types.LOAD_GEOCODE_ADDRESS,
    payload: {
        limit,
        query,
        ...filter,
    },
});
export const loadedGeocodeAddress = (data) => ({
    type: types.LOADED_GEOCODE_ADDRESS,
    payload: data,
});
export const clearGeocodeAddress = () => ({
    type: types.CLEAR_GEOCODE_ADDRESS,
});

export const loadingGeocodeAddress = (bool = false) => ({
    type: types.LOADING_GEOCODE_ADDRESS,
    payload: bool
});



//
export const loadRegions = (props = {}) => ({
    type: types.LOAD_REGIONS,
    payload: props
});
export const loadingRegions = (bool = false) => ({
    type: types.LOADING_REGIONS,
    payload: bool
});
export const loadedRegions = (data) => ({
    type: types.LOADED_REGIONS,
    payload: data
});


export const loadGeocodeAddressItem = (query, callback) => ({
    type: types.LOAD_GEOCODE_ADDRESS_ITEM,
    payload: { query },
    callback
});
// export const loadedGeocodeAddressItem = (data) => ({
//     type: types.LOADED_GEOCODE_ADDRESS_ITEM,
//     payload: data
// });

export const loadAddressAreasAutocomplete = (query, callback) => ({
    type: types.LOAD_ADDRESS_AREAS_AUTOCOMPLETE,
    payload: { query },
    callback
});

export const loadedAddressAreasAutocomplete = (data) => ({
    type: types.LOADED_ADDRESS_AREAS_AUTOCOMPLETE,
    payload: data
});

export const loadedAddressCitiesAutocomplete = (data) => ({
    type: types.LOADED_ADDRESS_CITIES_AUTOCOMPLETE,
    payload: data
});

export const loadedAddressStreetsAutocomplete = (data) => ({
    type: types.LOADED_ADDRESS_STREETS_AUTOCOMPLETE,
    payload: data
});

export const loadedAddressHousesAutocomplete = (data) => ({
    type: types.LOADED_ADDRESS_HOUSES_AUTOCOMPLETE,
    payload: data
});