import { config } from '../../config';

const apiWorkRequestsUrl = () => config.get('urls').apiWorkRequestsUrl;

const apiUrls = {

    getWorkRequestList: () => `${apiWorkRequestsUrl()}/request/`,

    createWorkRequest: () => `${apiWorkRequestsUrl()}/request`,

    editWorkRequest: (id) => `${apiWorkRequestsUrl()}/request/${id}`,

    deleteWorkRequest: (id) => `${apiWorkRequestsUrl()}/request/${id}`,

    getWorkRequestStatuses: () => `${apiWorkRequestsUrl()}/request/statuses`,

    changeWorkRequestStatus: (id) => `${apiWorkRequestsUrl()}/request/${id}/status`,

    appointWorkRequest: (id) => `${apiWorkRequestsUrl()}/request/${id}/appoint`,
        
};

export default apiUrls;