import React from 'react';
import {
    FormControl, FormHelperText,
    Grid,
    InputLabel, MenuItem, Select,
    TextField,
} from '@mui/material';
import L from 'leaflet';
import titles from 'helpers/constants/titles';
import useStoreProp from 'helpers/hooks/useStoreProp';
import DefectGroups from 'components/common/Autocomplete/RoadWorks/DefectGroups';
import DefectTypes from 'components/common/Autocomplete/RoadWorks/DefectTypes';
import Companies from 'components/common/Autocomplete/Companies';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import Attachments from 'components/common/Upload/Attachments';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import { loadDefectStatuses } from 'redux/RoadWorks/actions';


const EditorForm = (props) => {
    const { data, validation, onChange } = props;
    const defectStatuses = useStoreProp(loadDefectStatuses, 'roadworks', 'defectStatuses');

    const handleChangeAddress = (value) => {
        const { address, address_text, lat, lon } = value;
        validation.deleteKey('address');
        validation.deleteKey('address_text');
        validation.deleteKey('lat');
        validation.deleteKey('lon');

        if(lat === undefined || lon === undefined){
            onChange({
                address,
                address_text
            });
        }else{
            onChange({
                address_text
            });
        }
    };
    
    // const handleChangeGeometry = (newGeometry) => {
    //     if (Object.keys(newGeometry).length > 0) {
    //         const geoJson = new L.geoJSON(newGeometry);
    //         const centerGeo = geoJson.getBounds().getCenter();
    //         const { lat, lng } = centerGeo;
    //         onChange({
    //             geometry: newGeometry,
    //             lat: lat,
    //             lon: lng,
    //             address_text: ''
    //         });
    //     } else {
    //         onChange({
    //             geometry: {},
    //             lat: '',
    //             lon: ''
    //         });
    //     }
    // };

    return (
        <div className="modal__form">
            <Grid container direction="row" spacing={2}>
                <Grid item  xs={6}>
                    <div className="block">
                        <TextField
                            className="block"
                            size="small"
                            variant="outlined"
                            label="Название"
                            required
                            value={data?.name || ''}
                            onChange={(event) => {
                                onChange({ name: event.target.value });
                                validation.deleteKey('name');
                            }}
                            helperText={validation.get('name')}
                            error={validation.isKey('name')}
                        />
                    </div>

                    <div className="block">
                        <TextField
                            className="block"
                            size="small"
                            variant="outlined"
                            label={titles.DESCRIPTION}
                            value={data?.description || ''}
                            onChange={(event) => {
                                onChange({ description: event.target.value });
                                validation.deleteKey('description');
                            }}
                            helperText={validation.get('description')}
                            error={validation.isKey('description')}
                        />
                    </div>

                    <FormControl className="block" required={true} size="small" variant="outlined">
                        <InputLabel>{titles.SELECT_STATUS}</InputLabel>
                        <Select
                            value={data.status}
                            onChange={(event) => {
                                onChange({ status: event.target.value });
                            }}
                            label={titles.SELECT_STATUS}
                        >
                            {Object.keys(defectStatuses).map((id) => (
                                <MenuItem key={defectStatuses[id].status_id} value={defectStatuses[id].status_id}>
                                    {defectStatuses[id].name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/*<div className="block">*/}
                    {/*    <GeoJSONCoordinates*/}
                    {/*        value={data?.geometry || {}}*/}
                    {/*        onChange={(geometry) => {*/}
                    {/*            onChange({ geometry });*/}
                    {/*            validation.deleteKey('geometry');*/}
                    {/*        }}*/}
                    {/*        circle={true}*/}
                    {/*        polygon={true}*/}
                    {/*        polyline={true}*/}
                    {/*        marker={true}*/}
                    {/*        showFinder={false}*/}
                    {/*    />*/}

                    {/*    {validation.isKey('geometry') ? (*/}
                    {/*        <div className="error">*/}
                    {/*            {validation.get('geometry')}*/}
                    {/*        </div>*/}
                    {/*    ) : null}*/}
                    {/*</div>*/}

                    <div className="block">
                        <DefectGroups
                            selected={data.group}
                            onChange={(value) => {
                                onChange({ group: value, type: {} });
                                validation.deleteKey('group_id');
                            }}
                            error={validation.isKey('group_id')}
                            helperText={validation.get('group_id')}
                            required={true}
                        />
                    </div>

                    <div className="block">
                        <DefectTypes
                            selected={data.type}
                            onChange={(value) => {
                                onChange({ type: value });
                                validation.deleteKey('type_id');
                            }}
                            error={validation.isKey('type_id')}
                            helperText={validation.get('type_id')}
                            filter={{ group_ids: data?.group ? [data?.group?.id] : [] }}
                            disabled={!data?.group?.id || false}
                            required={true}
                        />
                    </div>

                    <div className="block">
                        <Companies
                            label="Организация"
                            filter={{ withDeleted: 1 }}
                            selected={data.organization}
                            onChange={(value) => {
                                onChange({
                                    organization: value,
                                    organization_id: value.id,
                                });
                                validation.deleteKey('organization_id');
                            }}
                        />
                    </div>

                    <div className="block">
                        <SingleKeyboardDateTimePicker
                            value={data?.registration_at || null}
                            onChange={(newDate) => {
                                onChange({ registration_at: newDate });
                                validation.deleteKey('registration_at');
                            }}
                            label="Дата регистрации"
                            required
                            className="row__item"
                            helperText={validation.get('registration_at')}
                            error={validation.isKey('registration_at')}
                        />
                    </div>

                    <div className="block">
                        <SingleKeyboardDateTimePicker
                            value={data?.liquidated_at || null}
                            onChange={(newDate) => onChange({ liquidated_at: newDate })}
                            label="Фактическая дата устранения"
                            dateOnly={true}
                            className="row__item"
                        />
                    </div>

                    <div className="block">
                        <TextField
                            className="block"
                            size="small"
                            variant="outlined"
                            label={titles.COMMENT}
                            value={data?.comment || ''}
                            onChange={(event) => {
                                onChange({ comment: event.target.value });
                                validation.deleteKey('comment');
                            }}
                            helperText={validation.get('comment')}
                            error={validation.isKey('comment')}
                        />
                    </div>

                    <>
                        <Attachments
                            label={'Документы'}
                            files={data?.files || []}
                            onChange={(url) => onChange({ files: url })}
                            onlyLink
                        />
                        {validation.isKey('files')
                            && (
                                <FormHelperText error>{validation.get('files')}</FormHelperText>
                            )
                        }
                    </>
                </Grid>
                <Grid item xs={6}>

                    {/* перепроверить */}
                    <div className="block">
                        <MapGeoJson
                            {...data}
                            onChange={(e) => {
                                console.log('e', e);
                                onChange(e);
                            }}
                            returnLatLon={true}
                            required
                        />
                    </div>

                    <div className="block">
                        <LoadAddressByCoords
                            {...data}
                            onChange={handleChangeAddress}
                        >
                            <AddressList required />
                        </LoadAddressByCoords>
                    </div>

                    {data?.address && (
                        <div className="block">
                            <TextField
                                className="block"
                                size="small"
                                variant="outlined"
                                label="Район"
                                disabled={true}
                                value={data?.address?.area || data?.address?.district || ''}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>         
        </div>
    );
};

export default EditorForm;
