import React from 'react';
import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import GetIcon from '../../../../../img/GetIcon';
import transportTypes from '../../../../../helpers/transport.types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    img: {
        padding: 7,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        width: 30,
        height: 30,
        borderRadius: '50%'
    },
});

const Routes = ({ item, isOpen }) => {
    const classes = useStyles();
    const types = transportTypes;
    return (
        <Collapse in={isOpen} className="info" timeout="auto" >
            <List style={{ overflowY: 'auto', maxHeight: '60vh' }} className="list">
                {item?.route_list
                    ?.map(route =>
                        <ListItem key={route?.id} className="list_item">
                            <ListItemIcon>
                                <div
                                    className={classes.img}
                                    style={{ backgroundColor: types[route?.category_id]?.color }}
                                >
                                    <GetIcon
                                        iconName={types[route?.category_id]?.iconName}
                                        format="svg"
                                        category="transport"
                                    />
                                </div>
                            </ListItemIcon>
                            <ListItemText>
                                №{route.num} {route.name}({route.is_direct_text})
                                <br/>
                                Маршрут: {route?.from} - {route?.to}
                            </ListItemText>
                        </ListItem>
                    )
                }
            </List>
        </Collapse>
    );
};

export default Routes;