const icon = (props = {}) => {
    const {
        angle = 45,
        angleRotate = [],
    } = props;
    
    const propsPatch = {
        d: 'M115,115 L115,5 A110,110 1 0,1 190,35 z',
        fill: '#c73ee0',
        'transform-origin': '50% 50%',
    };

    const currentProps = angleRotate.map(item => ({
        ...propsPatch,
        transform: `rotate(${item - angle / 2})`
    }));

    return `
        <svg className="pie" viewBox="0 0 230 230" opacity=".6">
            <circle cx="115" cy="115" r="110" fill="transparent"></circle>
            ${currentProps.map(item => `<path ${Object.keys(item).map((key) => `${key}="${item[key]}"`).join(' ')}></path>`)}
        </svg>
    `;
};

export default icon;