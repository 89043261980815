import React from 'react';
import renderAddress from '../../../../../helpers/renderAddress';
import { getFullDateTimeWithTextMonth } from '../../../../../helpers/date.config';


const Item = (props) => {
    const {
        description,
        status,
        dtp_at,
        address,
        address_text,
    } = props;

    return (
        <>
            <div className="description">
                {description && description.length > 0 ? description : address_text || renderAddress(address)}
            </div>
            <div className="date">
                {dtp_at ? getFullDateTimeWithTextMonth(dtp_at) : ''}
            </div>
        </>
    );
};

export default Item;
