import BoxContainer from '../../../common/Showcase/BoxContainer';
import { HeaderPageVisible } from '../../../common/Showcase/HeaderPage';
import HeaderPanel from '../../../common/Showcase/HeaderPanel';
import Page from './Page';

// страница
const TransportRoad = () => (
    <BoxContainer>

        {/* шапка */}
        <HeaderPageVisible/>

        <HeaderPanel title="Статистика Дорожная техника"/>

        {/* содержимое страницы */}
        <Page/>

    </BoxContainer>
);

export default TransportRoad;