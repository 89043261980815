import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import { loadDuploCodes } from 'redux/DorisControl/actions';
import DuploCodeItem from 'components/common/DorisControl/RenderDuploCodesItem';
import type { DuploCodesItem } from 'components/pages/Dictionaries/DorisControl/DuploCodes/types';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

interface SelectDuploCodesProps<T> extends CommonAutocompleteProps<T>{
    filter?: object
}

const SelectDuploCodes = ({
    multiple = false,
    selected,
    onChange,
    required = false,
    disabled = false,
    label = 'Коды ДУПЛО',
    error = false,
    helperText = '',
    renderLabel = (option: DuploCodesItem | null) => option?.name || '',
    limitTags = 1,
    filter = {}
}: SelectDuploCodesProps<DuploCodesItem>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);

    const prevData = useRef(selected);

    useEffect(() => {
        if (!isEqual(prevData.current, selected)) { 
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleAccept = (data: DuploCodesItem | DuploCodesItem[]) => {        
        onChange(data);
        setShowList(false);
    };

    const handleClose = () => setShowList(false);

    const getList = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query } = params;
        
        dispatch(loadDuploCodes(page, limit, { name: query, ...filter }));
    };

    const onReset = () => {
        const initialState = multiple ? [] : '';
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete<DuploCodesItem>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={renderLabel}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error} 
                helperText={helperText}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName="dorisControl"
                    storeNameProps="duploCodes"
                    storeLoadingProps="loadingDuploCodes"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <DuploCodeItem item={el}/>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={'Коды ДУПЛО'}
                    medium
                />
            }
        </>
    );
};

export default SelectDuploCodes;
