import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { IconButton, Typography, Collapse, Tooltip } from '@mui/material';

import { deleteStage } from 'redux/RoadWorks/actions';
import { loadCurrentCompany } from 'redux/Companies/actions';
import { boardsSelectors } from 'redux/Boards';
import { getHumanDate } from 'helpers/date.config';
import colorStatus from 'helpers/workorder.config';
import messages from 'helpers/constants/messages';
import useLoadBoardsList from 'helpers/hooks/Boards/useLoadBoardList';
import titles from 'helpers/constants/titles';
import ConfirmDelete from 'components/common/ConfirmModal';
import Attachments from 'components/common/Upload/Attachments';
import BoardsList from 'components/common/Boards/BoardLinkList';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import OrderStageSequencesModal from './OrderStageSequencesModal';
import EditOrderStatusModal from './EditOrderStageStatus';
import RoadWorkSites from '../../SitesArea/RoadWorkSites';
import OrderStageVehicles from './OrderStageVehicles';
import OrderStageModal from './OrderStageModal';
import BoardListModal from 'components/common/Boards/BoardsListModal';

const OrderStageInfo = ({
    item,
    statuses,
    currentOrder,
    currentStages,
    reloadList,
    vehicle
}) => {
    const hasVehicle = !!vehicle && !!item?.vehicles?.find((el) => el.garage_number === vehicle?.garage_number);
    const dispatch = useDispatch();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [openSequencesStage, setOpenSequencesStage] = useState(false);
    const [editStageModal, setEditStageModal] = useState(false);
    const [isOpen, setIsOpen] = useState(hasVehicle || false); // открываем этап
    const toggleOpenInfo = () => setIsOpen(!isOpen);
    const [isOpenVehicles, setIsOpenVehicles] = useState(hasVehicle || false); // открываем список ТС в этапе

    const [isOpenBoardListModal, setIsOpenBoardListModal] = useState(false);
    const [isNeedUpdate, setIsNeedUpdate] = useState(false);

    const [isOpenDIT, setIsOpenDIT] = useState(false); // открываем список ДИТ в этапе
    const [editStatus, setEditStatus] = useState(false);

    const handleDelete = () => {
        dispatch(deleteStage(item.id, () => {
            reloadList();
            setConfirmOpen(false);
        }));
    };

    const dit_list = useLoadBoardsList(item.id, item.entity_name, 'stagesOfRoadWorks', isNeedUpdate);
    const ditLoading = useSelector(boardsSelectors.loadingDitLink);

    useEffect(() => {
        setIsNeedUpdate(false);
    }, [dit_list]);
    
    // const organizations = currentOrder?.organizations.filter(elem => elem?.id === item?.organization_id); // временно не используем
    const [currentCompany, setCurrentCompany] = useState(null);
    useEffect(() => {
        // есть есть айди огранизации, загружаем ее чтобы отобразить в таблице
        const companyInOrder = currentOrder?.organizations?.find(elem => elem?.id === item?.organization_id);
        if (companyInOrder) {
            setCurrentCompany(companyInOrder);
        } else if (item.organization_id && !currentCompany) {
            dispatch(loadCurrentCompany(item.organization_id,
                (organization = {}) => {
                    setCurrentCompany(organization);
                }
            ));
        }
    }, [item.organization_id, currentCompany, currentOrder, dispatch]);

    return (
        <>
            <LSContentItem onClick={toggleOpenInfo}>
                <LSContentColumn>
                    <Tooltip title={statuses[item?.status]}>
                        <div style={{ backgroundColor: colorStatus(item?.status), width: 20, height: 20, borderRadius: '50%' }}></div>
                    </Tooltip>
                </LSContentColumn>

                <LSContentColumn>
                    {item?.name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {/* {organizations && organizations?.length > 0 ? <span>{organizations[0].title}</span> : messages.NO_VALUE} */}
                    {currentCompany?.title || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {getHumanDate(item?.start_date_plan) || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {getHumanDate(item?.end_date_plan) || messages.NO_VALUE }
                </LSContentColumn>

                <LSContentColumn>
                    {getHumanDate(item?.end_at)|| messages.NO_VALUE }
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <i className="fas fa-list-ol"/>,
                                name: 'Изменить последовательность',
                                onClick: () => setOpenSequencesStage(true),
                            },
                            {
                                ...buttonsTypes.changeStatusIcon,
                                onClick: () => setEditStatus(true),
                                accessProp: 'is_read' // временно пока не придумали как добавить отдельно права в таб
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setEditStageModal(true),
                                accessProp: 'is_read' // временно пока не придумали как добавить отдельно права в таб
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setConfirmOpen(true),
                                accessProp: 'is_read' // временно пока не придумали как добавить отдельно права в таб
                            }
                        ]}
                    />

                    <ActionMore
                        isOpen={isOpen}
                        onClick={toggleOpenInfo}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isOpen && <Collapse in={isOpen} timeout={'auto'} unmountOnExit className="info">
                <Typography variant="body2">
                    {item?.description
                        ? <><strong>{titles.DESCRIPTION}: </strong> {item.description}</>
                        : null}
                </Typography>

                <RoadWorkSites list={item?.works} collapsedList />
                <Typography variant="body2">
                    <strong>Транспорт</strong>
                    <IconButton size="small" onClick={()=> setIsOpenVehicles(!isOpenVehicles)}>
                        {isOpenVehicles
                            ? <ExpandLess fontSize="inherit" />
                            : <ExpandMore fontSize="inherit" />
                        }
                    </IconButton>
                </Typography>
                <OrderStageVehicles item={item} isOpen={isOpenVehicles}/>
                <Typography variant="body2">
                    <strong>Связанные ДИТ</strong>
                    <IconButton size="small" onClick={()=> setIsOpenDIT(!isOpenDIT)}>
                        {isOpenDIT
                            ? <ExpandLess fontSize="inherit" />
                            : <ExpandMore fontSize="inherit" />
                        }
                    </IconButton>
                </Typography>
                <Collapse in={isOpenDIT} timeout={'auto'} unmountOnExit>
                    <BoardsList
                        dit_list={dit_list}
                        onClick={() => setIsOpenBoardListModal(true)}
                        loading={ditLoading}
                    />
                </Collapse>
                <Attachments
                    readOnly
                    onlyLink
                    files={item?.attachments}
                />
            </Collapse>}

            {isOpenBoardListModal && (
                <BoardListModal
                    id={item.id}
                    name={item.name}
                    entity_name={item.entity_name}
                    isOpen={isOpenBoardListModal}
                    microservice="stagesOfRoadWorks"
                    onClose={() => {
                        setIsOpenBoardListModal(false);
                        setIsNeedUpdate(true);
                    }}
                />
            )}

            {editStatus && (
                <EditOrderStatusModal
                    data={item}
                    isOpen={editStatus}
                    onClose={() => setEditStatus(false)}
                    reloadList={reloadList}
                />
            )}

            {openSequencesStage && (
                <OrderStageSequencesModal
                    isOpen={openSequencesStage}
                    onClose={() => setOpenSequencesStage(false)}
                    currentStages={currentStages}
                    reloadList={reloadList}
                />
            )}

            {editStageModal && (
                <OrderStageModal
                    isOpen={editStageModal}
                    isNew={false}
                    item={item}
                    currentOrder={currentOrder}
                    onClose={() => setEditStageModal(false)}
                    statuses={statuses}
                    reloadList={reloadList}
                />
            )}

            {confirmOpen &&  (
                <ConfirmDelete
                    open={confirmOpen}
                    onSuccess={handleDelete}
                    onClose={() => setConfirmOpen(false)}
                />
            )}
        </>
    );
};

export default OrderStageInfo;
