import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import cn from 'classnames';
import { pick } from 'lodash';

import SimpleSelect from 'components/common/SimpleSelect';
import useReportTemplatesFrontendLinks from 'helpers/hooks/Report/useReportTemplatesFrontendLinks';
import titles from 'helpers/constants/titles';

import styles from './getReport.module.scss';

// поля для выбора типа отчета и форматов
// (список отчетов берется из метода)
const PageReportFields = ({
    reportType = null, // тип - объект { id, name, url }
    onReportType,
    reportFormats = [], // форматы - массив объектов [{ id, name }]
    onReportFormat,
    urlTemplate = null, // ключ для шаблонов
    isOneTypeAuto = false, // автовыбор если отчет 1 и автоскрытие поля
    required = false,
    error = false,
    test_id_prefix = ''
}) => {
    // провайдер для отчетов
    const templateProvider = useReportTemplatesFrontendLinks();
    // шаблоны
    const templates = templateProvider.getTemplatesByUrl(urlTemplate);

    // отображение типа
    const [visibleType, setVisibleType] = useState(true);

    // список типов
    const typesList = useMemo(() => templates
        ?.map((item) => ({
            ...pick(item, ['id', 'name', 'url'])
        }))
        || []
    , [templates]);

    useEffect(() => {
        // если 1 шаблон - выбираем его и скрываем поле
        if (isOneTypeAuto && typesList?.length === 1) {
            onReportType(typesList?.[0] || null);
            onReportFormat([]);
            setVisibleType(false);

            return () => setVisibleType(true);
        }
    }, [isOneTypeAuto, typesList]);

    const handleSetType = (idType) => {
        const type = templates?.find(({ id }) => id === idType) || null;
        onReportType(type);
        onReportFormat([]);
    };

    // список форматов по выбранному типу
    const formatListByType = useMemo(() => templates
        ?.find(({ id }) => id === reportType?.id)
        ?.formats
        ?.map(key => ({ id: key, name: key }))
        || []
    ,[reportType, templates]);

    // блок с полями
    return (
        <>
            {visibleType && (
                <SimpleSelect
                    selected={reportType?.id || ''}
                    endpoint={typesList}
                    setSelected={handleSetType}
                    optionLabel="name"
                    optionKey="id"
                    optionValue="id"
                    title="Тип отчета"
                    test_id_prefix={test_id_prefix}
                />
            )}

            <Autocomplete
                noOptionsText={titles.NO_RESULTS}
                disabled={!reportType}
                multiple={true}
                value={reportFormats}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={formatListByType}
                filterSelectedOptions
                getOptionLabel={(option) => option?.name || ''}
                onChange={(e, values) => onReportFormat(values)}
                size="small"
                openText={'Открыть'}
                closeText={'Закрыть'}
                clearText={'Очистить'}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required={required}
                        error={error}
                        size="small"
                        variant="outlined"
                        label={titles.FORMAT}
                        InputProps={{
                            ...params.InputProps,
                            'data-testid': `${test_id_prefix}/formats/input`
                        }}
                    />
                )}
                className={cn(styles.item, 'row__item')}
            />
        </>
    );
};

export default PageReportFields;
