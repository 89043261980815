import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import AddressSearch from './AddressSearch';
import CoordsSearch from './CoordsSearch';
import Dialog from './Dialog';
import L from 'leaflet';
import FormButtonsComponent from '../../FormButtons';

function MapSearch(props) {
    const { map } = props;

    const [showAddress, setShowAddress] = useState(false);
    const [showCoords, setShowCoords] = useState(false);

    const fg = useRef(null);

    const handleMapClick = () => {
        fg.current.clearLayers();
    };

    useEffect(() => {
        map.on('click ', handleMapClick);
        const featureGroup = new L.FeatureGroup();
        map.addLayer(featureGroup);
        fg.current = featureGroup;

        return () => {
            map.off('click ', handleMapClick);
            featureGroup.clearLayers();
            map.removeLayer(featureGroup);
        };
    }, []);

    const handleChange = (latlon) => {
        map.flyTo(latlon, 15);
        fg.current.clearLayers();

        const circle = L.circle(latlon, 20,{
            fillColor: '#f5f5f5',
        });

        circle.addTo(fg.current);

        setShowAddress(false);
        setShowCoords(false);
    };

    return (
        <div className="map-finder-control">
            <div>
                <FormButtonsComponent
                    justButton
                    buttons={[
                        {
                            fullWidth: true,
                            size: 'small',
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowAddress(true);
                                setShowCoords(false);
                            },
                            name: 'Найти по адресу'
                        }
                    ]}
                />
            </div>
            <div>
                <FormButtonsComponent
                    justButton
                    buttons={[
                        {
                            fullWidth: true,
                            size: 'small',
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowCoords(true);
                                setShowAddress(false);
                            },
                            name: 'Найти по координатам'
                        }
                    ]}
                />
            </div>
            <Dialog open={showAddress}>
                <AddressSearch onChange={handleChange}/>
            </Dialog>
            <Dialog open={showCoords}>
                <CoordsSearch onChange={handleChange}/>
            </Dialog>
        </div>
    );
}

export default MapSearch;
