import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createContractEntitiesByName, loadContractEntitiesByName } from 'redux/GovernmentContracts/actions';
import { govermentContracts } from 'redux/GovernmentContracts';
import messages from 'helpers/constants/messages';
import InformPanel from 'components/layout/PageLayout/InformPanel';
import Loading from 'components/common/Loading';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import EntityListModal from '../EntityListModal';

import ComplexItem from './ComplexItem';

import type { Entities, ItemProps, } from '../../types';

interface InfoProps {
    id: number;
    currentContract: ItemProps;
    entityObject: any;
}

const CommonEntityPage = ({ id, currentContract, entityObject }: InfoProps) => {
    const dispatch = useDispatch();

    const loading = useSelector(govermentContracts.loadingContractByName);


    const [openModal, setOpenModal] = useState(false);
    const [entities, setEntities] = useState<Entities[]>([]);
    
    useEffect(() => {
        dispatch(loadContractEntitiesByName(id, entityObject.key, setEntities));
    }, []);


    const handleAccept = (value: Entities[]) => {
        dispatch(createContractEntitiesByName(
            id,
            entityObject?.key,
            {
                id_list: value.map(el => el.id)
            },
            () => dispatch(loadContractEntitiesByName(id, entityObject.key, setEntities))
        ));
    };

    return (
        <div style={{ padding: '1.5rem' }}>
            <strong>{entityObject.name}: </strong>
            <InformPanel
                buttons={
                    <FormButtonsComponent
                        noPadding
                        noMarginLeft
                        buttons={[
                            {
                                ...buttonsTypes.change,
                                color: 'infoButton',
                                onClick: () => setOpenModal(true),
                                disabled: !!currentContract?.external_id
                            },
                        ]}
                    />
                }
            />
            {loading && <Loading linear />}
            <div className="info">
                {entities?.length
                    ? entities.map((el: Entities, i) =>
                        (entityObject.key === 'doris-control'
                            ? <ComplexItem key={el.id} item={el} i={i} />
                            : <div key={el.name + i}>
                                {i+1}. <strong>Название: </strong>{el.name};
                            </div>
                        )
                    )
                    : !loading && messages.NO_DATA
                } 
            </div>
            {openModal && (
                <EntityListModal
                    isOpen={openModal}
                    onChange={handleAccept}
                    onClose={() => setOpenModal(false)}
                    selected={entities}
                    url={`${entityObject?.service}${entityObject?.api_list}`}
                    entityName={entityObject.name}
                    idGC={id}
                />
            )}
        </div>
    );
};

export default CommonEntityPage;
