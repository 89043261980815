import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import { isEqual } from 'lodash';
import queryString from 'query-string';

import {
    loadConcentrationAreaStatuses,
    loadConcentrationAreaTypes,
    setMKDTPFilterParams
} from 'redux/Incidents/actions';
import titles from 'helpers/constants/titles';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import { useStoreProp } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { TypeLoad, Types } from 'components/common/Autocomplete/DTP';
import DateRange from 'components/common/Dates/DateRange';
import SimpleSelect from 'components/common/SimpleSelect';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({ setParams }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = queryString.parse(location.search);

    const initState = {
        start_date: getStartOf('year'),
        end_date: getEndOf('year'),
        type: '',
        status: '',
        dtp_type: [],
        dtp_type_load: [],
        with_dtp_list: 1,
        id_list: []
    };

    const types = useStoreProp(loadConcentrationAreaTypes, 'incidents', 'concentrationAreaTypes');
    const statuses = useStoreProp(loadConcentrationAreaStatuses, 'incidents', 'concentrationAreaStatuses');

    const [data, setData] = useState({
        ...initState,
        ...(id
            ? {
                id_list: id ? [id] : [],
                start_date: '',
                end_date: '',
            }
            : {}
        )
    });

    const getParams = (filters = data) => {
        const prepareData = {
            ...filters,
            dtp_type: filters.dtp_type?.map(({ id }) => id),
            dtp_type_load: filters.dtp_type_load?.map(({ id }) => id),
            start_date: fullDateTimeWithTimeZone(filters.start_date),
            end_date: fullDateTimeWithTimeZone(filters.end_date),
        };
        return removeEmptyFields(prepareData);
    };

    const handleSearch = (filters = data) => {
        const paramsWithoutEmpty = getParams(filters);

        dispatch(setMKDTPFilterParams({
            data,
            isSearch: true,
            params: { page: 1, query: paramsWithoutEmpty }
        }));

        setParams({ page: 1, query: paramsWithoutEmpty });
    };

    const handleResetFilter = (needRefresh) => {
        setData(initState);

        if(needRefresh){
            dispatch(setMKDTPFilterParams({}));
            setParams(({ page: 1, query: getParams(initState) }));
        }
    };

    const isDisabled = isEqual(initState, data);

    const updateFilter = (filters) => {
        setData({
            ...initState,
            ...filters
        });
        if (Object.keys(filters).length) {
            handleSearch({
                ...initState,
                ...filters
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={handleResetFilter}
            onSearch={handleSearch}
            filter={data}
            setUserFilter={updateFilter}  // если у пользователя сохранен фильтр для этой страницы, мы его из общего компонента сетаем сюда
            disabled={isDisabled}
            filterException={['start_date', 'end_date',]}
        >
            <LayoutFilter.Visible>
                <DateRange
                    // dateOnly
                    valueStartDate={data.start_date}
                    valueEndDate={data.end_date}
                    handleDate={(date) => setData({ ...data, start_date: date })}
                    handleEndDate={(date) => setData({ ...data, end_date: date })}
                />

                <Autocomplete
                    multiple
                    value={data?.id_list}
                    freeSolo
                    autoSelect
                    options={[]}
                    onChange={(_, value) => setData({ ...data, id_list: value })}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="ID"
                        />
                    )}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <SimpleSelect
                    selected={data?.type || ''}
                    endpoint={types}
                    setSelected={(type) => setData({ ...data, type })}
                    title={'Вид'}
                    emptyItem={false}
                />
                <SimpleSelect
                    selected={data?.status || ''}
                    endpoint={statuses}
                    setSelected={(status) => setData({ ...data, status: [status] })}
                    title={titles.STATUS}
                    emptyItem={false}
                />
                <Types
                    multiple
                    selected={data?.dtp_type || []}
                    onChange={(dtp_type) => setData({ ...data, dtp_type })}
                />

                <TypeLoad
                    selected={data?.dtp_type_load || []}
                    onChange={(dtp_type_load) => setData({ ...data, dtp_type_load })}
                    openOnFocus
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
