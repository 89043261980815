import React, { useEffect, useMemo, useState } from 'react';
import {
    Slider as SliderLine,
    Box,
} from '@mui/material';
import {
    getDateToFormat,
} from '../../../../../helpers/date.config';
import './style.scss';


const Slider = (props) => {
    const {
        heatmap = {},
        value = '',
        onChange = () => {},
        filterValue = {},
    } = props;

    const curretYear = new Date().getFullYear();

    const [current, setCurrent] = useState(1);

    // метки
    const marks = useMemo(() => {
        return heatmap
            ?.[0]
            ?.datas
            ?.map(({ date }, index) => ({
                value: index + 1,
                label: date,
            })) || [];
    }, [heatmap]);

    // заменяем значение
    useEffect(() => {
        if (value) {
            const val = marks?.find(({ label }) => label === value)?.value || null;
            if (val && val !== current) {
                setCurrent(val);
            }
        }
        // else {
        //     const val = marks?.[0]?.label || '';
        //     onChange && onChange(val);
        // }
    } , [value]);

    // изменения
    useEffect(() => {
        if (
            filterValue?.year?.toString() === curretYear?.toString()
            && !filterValue?.month
            && marks?.length > 0
        ) {
            const current = marks?.[marks?.length - 1];
            onChange && onChange(current?.label || '');
        } else {
            const val = marks?.[0]?.label || '';
            onChange && onChange(val);
        }
    }, [marks]);

    // параметры для слайдера
    const sliderProps = useMemo(() => {
        const marksLength = marks?.length;

        return {
            marks: marks,
            max: marksLength + 1,
            onChange: (e, value) => {
                const val = Math.min(marksLength, Math.max(1, value));
                setCurrent(val);
                onChange && onChange(marks?.find(({ value }) => value === val)?.label || null);
            },
        };
    }, [marks]);

    if (marks?.length > 0) {
        return (
            <Box>
                <SliderLine
                    aria-label="Custom marks"
                    defaultValue={1}
                    step={1}
                    max={5}
                    value={current}
                    track={false}
                    aria-labelledby="track-false-slider"
                    {...sliderProps}
                />
            </Box>
        );
    }

    return null;
};

export default Slider;
