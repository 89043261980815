import Settings from '../../../../common/Settings';
import {
    editSettingsGroup,
    editSettingsList,
    loadSettingsGroup,
} from '../../../../../redux/SituationalPlans/actions';

const SituationalPlansSettings = () => {
    return (
        <Settings
            title="Настройки – ДОРИС-Реакт"
            storeProp="SituationalPlans"
            listName="settingsGroup"
            loadingName="loadingSettingsGroup"
            loadSettingsGroupAction={loadSettingsGroup}
            editSettingsGroupAction={editSettingsGroup}
            editSettingsListAction={editSettingsList}
        />
    );
};

export default SituationalPlansSettings;
