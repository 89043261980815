const convertValue = (value) => {
    return value;
    // const intValue = parseInt(value);
    // return isNaN(intValue) ? value : intValue;
};

const getItem = (item) => {
    if (Array.isArray(item)) {
        return item?.length > 0 ? item.map((item) => convertValue(item.hasOwnProperty('id') ? item?.id || 0 : item || 0)) : null;
    } else if (item.hasOwnProperty('id')) {
        return convertValue(item.id) || null;
    }
    return convertValue(item) || null;
};

// конвертировать фильтр (для всех слоев)
const getFilters = (filter, adventure = null) => {
    const newFilter = Object.keys(filter).reduce((res, key) => {
        const item = filter[key];
        const value = getItem(item);
        if (value) {
            return {
                ...res,
                [key]: getItem(item)
            };
        }
        return res;
    }, {});

    return adventure ? adventure(newFilter) : newFilter;
};

export default getFilters;
