import React from 'react';
import { FormControl, TextField } from '@mui/material';
import formatDate from '../../../../../../helpers/constants/dateFormat';
import Loading from '../../../../../common/Loading';
import SingleKeyboardDateTimePicker from '../../../../../common/Dates/SingleKeyboardDateTimePicker';
import LatLonCoordinates from '../../../../../common/Location/LatLonCoordinates';
import FieldsModal from '../../../../../common/Location/FieldsModal';
import buttons from '../../../../../../helpers/constants/buttons';
import MapDragMarker from '../../../../../common/Location/MapDragMarker';
import LoadAddressByCoords from '../../../../../common/Location/LoadAddressByCoords';
import AddressOne from '../../../../../common/Location/AddressOne';


// todo добавить загрузку фото по дтп, вынести в константы названия полей
const Form = (props) => {
    const {
        data = {},
        onChange,
        requiredFields,
        validation,
        loading,
    } = props;

    const handleEvent = (key) => (newValue) => {
        const { target: { value } } = newValue;

        onChange({
            [key]: value
        });
    };

    // const handleLocationChange = (props) => {
    //     onChange({
    //         ...props
    //     });
    // };

    const handleDateChange = (key, value) => {
        onChange({
            [key]: value
        });
    };

    const getValue = (key, def = '') => data[key] ? data[key] : def;

    const isErrorField = (key) => {
        return (requiredFields.includes(key) && !data[key]) || validation.isKey(key);
    };

    const getErrorValidation = (key) => validation.get(key);

    const onLocationChange = (props) => {
        onChange({
            ...props
        });
        // validation.deleteKeys(Object.keys(props));
    };

    return <>
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="block">
                <FormControl className="block" variant="outlined">
                    <LatLonCoordinates
                        lat={data?.lat}
                        lon={data?.lon}
                        onChange={onLocationChange}
                        required
                    />
                </FormControl>

                <FieldsModal
                    title="Поставьте/передвиньте метку на карте"
                    buttonText={buttons.SELECT_ON_MAP}
                    fields={data}
                    onChange={onLocationChange}
                >
                    <MapDragMarker
                        required
                    />
                </FieldsModal>

                <FormControl className="block" variant="outlined">
                    <LoadAddressByCoords
                        {...data}
                        onChange={onLocationChange}
                    >
                        <AddressOne
                            required
                        />
                    </LoadAddressByCoords>
                </FormControl>

            </div>

            <div className="block">
                <SingleKeyboardDateTimePicker
                    label="Дата регистрации"
                    onChange={(value) => handleDateChange('registered_at', value)}
                    value={getValue('registered_at', null)}
                    error={isErrorField('registered_at')}
                    helperText={getErrorValidation('registered_at')}
                    pickerFormat={formatDate.DT_EXCEPT_SECONDS}
                />
            </div>

            <div className="block">
                <TextField
                    size="small"
                    label="Принадлежность"
                    variant="outlined"
                    value={getValue('accessory')}
                    onChange={handleEvent('accessory')}
                    error={isErrorField('accessory')}
                    helperText={getErrorValidation('accessory')}
                />
            </div>

            {loading && <Loading linear={true}/>}
        </form>
    </>;
};

export default Form;
