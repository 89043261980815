import moduleName from './module';
const prefix = `${moduleName}`;

export const LOAD_WORK_REQUEST_LIST = `${prefix}/LOAD_WORK_REQUEST_LIST`;
export const LOADED_WORK_REQUEST_LIST = `${prefix}/LOADED_WORK_REQUEST_LIST`;
export const LOADING_WORK_REQUEST_LIST = `${prefix}/LOADING_WORK_REQUEST_LIST`;

export const CREATE_WORK_REQUEST = `${prefix}/CREATE_WORK_REQUEST`;
export const EDIT_WORK_REQUEST = `${prefix}/EDIT_WORK_REQUEST`;
export const DELETE_WORK_REQUEST = `${prefix}/DELETE_WORK_REQUEST`;

export const LOAD_WORK_REQUEST_STATUSES = `${prefix}/LOAD_WORK_REQUEST_STATUSES`;
export const LOADED_WORK_REQUEST_STATUSES = `${prefix}/LOADED_WORK_REQUEST_STATUSES`;

export const CHANGE_WORK_REQUEST_STATUS = `${prefix}/CHANGE_WORK_REQUEST_STATUS`;

export const APPOINT_WORK_REQUEST = `${prefix}/APPOINT_WORK_REQUEST`;