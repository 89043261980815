import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useValidation } from '../../../../../../helpers/hooks';
import titles from '../../../../../../helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from '../../../../../common/FormButtons';
import Modal from '../../../../../common/Modal';
import { Divider } from '@mui/material';
import {
    createMessage,
    createMessageTemplate,
    editMessage,
    editMessageTemplate,
    loadMessageTemplates,
    loadMessagePriorities,
} from '../../../../../../redux/Boards/actions';
import { fullDateTimeWithTimeZone } from '../../../../../../helpers/date.config';
import ConfirmDelete from '../../../../../common/ConfirmModal';
import PreviewMessage from '../../MessageHelpers/PreviewMessage';
import removeEmptyFields from '../../../../../../helpers/removeEmptyFields';
import WeekDays from './WeekDays';

import {
    CountShow,
    Header,
    PrioritySelect,
    Signs,
    TextMsg,
    StartTime,
    EndTime,
    TimeShow,
} from './Fields';
import useStoreProp from '../../../../../../helpers/hooks/useStoreProp';
import { Image } from './Fields/Image';

const ModalForm = ({
    isNew = false,
    isCopy = false,
    onClose = () => {},
    isOpen = false,
    item = {},
    // priorities = {},
    boardsProp = [],
    template = false,
    reloadList,
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const [isDateRangeError, setDateRangeError] = useState(false);
    const [isShowPreviewMessage, setIsShowPreviewMessage] = useState(false);
    const [delModalOpen, setDelModalOpen] = useState(false);
    const [indexOfDeletedElement, setIndexOfDeletedElement] = useState('');
    
    const priorities = useStoreProp(
        loadMessagePriorities,
        'boards',
        'messagePriorities'
    );
        
    const initialState = {
        priority: item?.priority || '',
        service: item?.service || '',
        header: item?.header || '',
        start_at: item?.start_at || null,
        end_at: item?.end_at || null,
        text: item?.text || [],
        boards: item?.boards || boardsProp,
        count: item?.count || 0,
        duration: item?.duration || '',
        base64: item?.base64 || null,
        schedule: item?.schedule || [],
    };
        
    const [data, setData] = useState(initialState);
    const [errorWeek, setErrorWeek] = useState(false);
    const [boardForPreview, setBoardForPreview] = useState(data?.boards[0]); // для шаблонов

    const filteredMessages = (array) => {
        // фильтруем сообщения на 3 массива - бэкграйнд с картинкой, знаки и текст, 
        // чтобы было удобнее с ними работать 
        // messageIndex - оставляем "родной" индекс чтобы правильно вывести валидацию
        const newData = array.reduce((acc, el, index) => {
            if (!el.type) {
                // костыль на случай старых сообщений когда не было еще type
                el.type = el.text ? 'text' : el.sign ? 'sign' : 'background';
            }
            acc[el.type] = [...acc[el.type], { ...el, messageIndex: index }];
            return acc;
        }, { background: [], sign: [], text: [] });
        if (newData.background.length === 0) {
            newData.background = [{
                type: 'background',
                'align-x': 'center',
                'align-y': 'top',
                x: 0,
                y: 0,
                color: '#000000',
                image: null,
                messageIndex: array.length
            }];
        }
        return newData;
    };
    const [messages, setMessages] = useState(filteredMessages(data.text));
    // TODO: Правильно заколбечить onChange & validation, для предотвращения лишних рендеров в дочерних компонентах
    const onChange = (e) => {
        const { name, value } = e.target;

        setData({
            ...data,
            [name]: value
        });
        validation.deleteKey(name);
    };

    const reloadData = () => {
        dispatch(loadMessageTemplates({ page: 1, limit: 25 }));
        onClose();
    };

    const convertMessageToText = (obj) =>{
        // преобразуем объект с сообщениями в один плоский массив
        return Object.entries(obj).reduce((acc, [key, el]) => {
            acc = acc.concat(el);
            return acc;
        }, []);
    };

    const onSave = () => {
        const correctStartAt = fullDateTimeWithTimeZone(data.start_at);
        const correctEndAt = fullDateTimeWithTimeZone(data.end_at);
        const prepareData = {
            ...data,
            count: Number(data.count),
            duration: Number(data.duration),
            ...(!template && {
                start_at: correctStartAt,
                board_id: data.boards[0].id,
            }),
            text: convertMessageToText(messages),
        };
        const paramsWithoutEmpty = removeEmptyFields(prepareData, false ,'count');
        const paramsWithoutEmptyRequired = {
            ...paramsWithoutEmpty,
            ...(!template && {
                end_at: correctEndAt, // поле не обязательно
            }),
        };

        if (template) {
            isNew
                ? dispatch(createMessageTemplate(paramsWithoutEmptyRequired, reloadData))
                : dispatch(editMessageTemplate(item.id, paramsWithoutEmptyRequired, reloadData));
        } else {
            isNew || isCopy
                ? dispatch(createMessage(paramsWithoutEmptyRequired, () => {reloadList(); onClose();}))
                : dispatch(editMessage(item.id, paramsWithoutEmptyRequired, () => {reloadList(); onClose();}));
        }
    };
   
    const deleteBoard = () => {
        const newBoards = data.boards.filter((el, index) => index !== Number(indexOfDeletedElement));
        setData({
            ...data,
            'boards': newBoards
        });
        setIndexOfDeletedElement('');
        setDelModalOpen(false);
    };

    const handleChangeMessage = (key, value) => {
        const newMessages = {
            ...messages,
            [key]: value,
        };
        const text = convertMessageToText(newMessages);
        setMessages(filteredMessages(text)); // чтобы правильно поставить индексы если добавились новые объекты
        setData({ ...data, text });
    };

    const handleStartTime = (start_at) => {
        setData({ ...data, start_at });
        validation.deleteKey('start_at');
    };
    const handleEndTime = (end_at) => {
        setData({ ...data, end_at });
        validation.deleteKey('end_at');
    };

    const startTimeIsDisabledHandler = (error) => setDateRangeError(error);

    const allMsgFieldsFilled = () => Object.entries(messages).reduce((res, [key, elem]) => {
        elem.forEach(el => {
            if (key === 'sign') {
                res = res && !!el.sign && !!el.size;
            }
            if (key === 'text') {
                res = res && !!el.text && !!el.font && !!el.size;
            }
        });
        return res;
    }, true);

    const isDisabled = !data.priority
        || !data.header
        || (!template && !data.start_at)
        || !data.duration
        || !allMsgFieldsFilled()
        || isDateRangeError
        || errorWeek;

    const isDisabledPreview = template 
        ?  !allMsgFieldsFilled()
        : (data?.boards.length === 0 || !allMsgFieldsFilled());


    return (
        <Modal
            title={isNew ? titles.CREATE : isCopy ? titles.COPY : titles.EDIT}
            onClose={onClose}
            noPadding
            maxWidthProp={'md'}
            isOpen={isOpen}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.preview,
                        disabled: isDisabledPreview,
                        onClick: () => setIsShowPreviewMessage(true)
                    },
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isDisabled,
                        onClick: onSave
                    },
                ]}
            />}
        >
            <div className="modal__form">
                <Header 
                    value={data.header} 
                    onChange={onChange} 
                    validation={validation}
                />
                <PrioritySelect 
                    value={data.priority} 
                    priorities={priorities} 
                    onChange={onChange} 
                    validation={validation}
                />
                {!template && (
                    <>
                        <StartTime
                            value={data.start_at}
                            onChange={handleStartTime}
                            validation={validation}
                            isDisabledHandler={startTimeIsDisabledHandler}
                        />
                        <EndTime
                            value={data.end_at}
                            onChange={handleEndTime}
                            validation={validation}
                            isDisabledHandler={startTimeIsDisabledHandler}
                            error={
                                data.end_at
                                && data.start_at
                                && new Date(data.end_at) <= new Date(data.start_at)
                            }
                            helperText={
                                data.end_at
                                && data.start_at
                                && new Date(data.end_at) <= new Date(data.start_at)
                                    ? 'Поле "Время окончания показа" должно быть меньше поля "Время начала показа"'
                                    : ''
                            }
                        />
                    </>
                )}

                <div className="row">
                    <CountShow value={data.count} onChange={onChange} validation={validation}/>
                    <TimeShow value={data.duration} onChange={onChange} validation={validation}/>
                </div>

                <Divider style={{ margin: '1rem 0' }} />

                <Image 
                    data={messages?.background}
                    onChange={(newData) => handleChangeMessage('background', newData)}
                />
                <Signs
                    data={messages?.sign}
                    onChange={(newData) => handleChangeMessage('sign', newData)}
                    // selectedSigns={currentSigns}
                />
                <TextMsg
                    data={messages?.text}
                    board={data?.boards[0]}
                    onChange={(newData) => handleChangeMessage('text', newData)}
                />

                {/* дни недели со временем */}
                <WeekDays
                    value={data.schedule || []}
                    onChange={schedule => setData(old => ({ ...old, schedule }))}
                    setError={setErrorWeek}
                />

            </div>
            {delModalOpen && (
                <ConfirmDelete
                    open={delModalOpen}
                    onClose={() => setDelModalOpen(false)}
                    onSuccess={deleteBoard}
                />
            )}
            
            {isShowPreviewMessage && (
                <PreviewMessage
                    isOpen={isShowPreviewMessage}
                    onClose={() => setIsShowPreviewMessage(false)}
                    // imgPreview={data?.base64}
                    template={template}
                    boardForPreview={boardForPreview}
                    setBoardForPreview={setBoardForPreview}
                    text={convertMessageToText(messages)}
                />
            )}
        </Modal>
    );
};

export default ModalForm;