import React, { useEffect, useState } from 'react';
import Form from './Form';
import Modal from '../../../../common/Modal';
import { useDispatch, useSelector } from 'react-redux';
import titles from '../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import * as actions from '../../../../../redux/RoadWorks/actions';
import { roadworksSelectors } from '../../../../../redux/RoadWorks';
import { useValidation } from '../../../../../helpers/hooks';
import Loading from '../../../../common/Loading';
import { dateWithDashYYYYMMDD, fullDateTimeWithTimeZone } from '../../../../../helpers/date.config';
// import removeEmptyFields from '../../../../../helpers/removeEmptyFields';


const initialState = {
    name: '',
    description: '',
    comment: '',
    geometry: null,
    group_id: '',
    type_id: '',
    address_text: '',
    address: {},
    registration_at: null,
    liquidated_at: null,
    organization_id: null,
    status: 1,
    lat: null,
    lon: null,
    files: [],
};

// формы
const ModalForm = (props) => {
    const {
        data = null,
        onClose = () => {},
        open = false,
        loadList = () => {}
    } = props;
    
    const dispatch = useDispatch();

    const loadingDefects = useSelector(roadworksSelectors.loadingDefects);

    const validation = useValidation();

    const [fields, setFields] = useState(initialState);
    const [isOpen, setIsOpen] = useState(open);

    const requiredFields = [
        'name',
        'status',
        'group',
        'type',
        'registration_at',
        'address',
        'address_text'
    ];
    
    // данные в форме изменились
    useEffect(() => {
        if (!!data) {
            const newData = {
                ...initialState,
                ...data,
            };
            setFields(newData);
            setIsOpen(true);
        }
    }, [data]);

    const isNew = !fields?.id;
    const title = isNew ? titles.ADD : titles.EDIT;

    const handleChange = (changeData) => {
        setFields({
            ...fields,
            ...changeData
        });
    };

    const handleClose = () => {
        onClose();
        setIsOpen(false);
    };

    const reloadList = () => {
        loadList();
        handleClose();
    };

    const handleSuccess = () => {
        const newFields = {
            ...fields,
            group_id: fields.group?.id || null,
            type_id: fields.type?.id || null,
            registration_at: fields.registration_at ? fullDateTimeWithTimeZone(fields.registration_at) : null,
            liquidated_at: fields.liquidated_at ? dateWithDashYYYYMMDD(fields.liquidated_at) : null,
        };

        // const filteredFields = removeEmptyFields(newFields);

        isNew
            ? dispatch(actions.createDefect(newFields, (status) => status && reloadList()))
            : dispatch(actions.editDefect(newFields.id, newFields, (status) => status && reloadList()));
    };

    const isDisabled = requiredFields.filter(item => fields[item]).length !== requiredFields.length || validation.isValidation();

    // форма редактирования 
    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            noPadding
            maxWidthProp={'md'}
            title={title}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: handleClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSuccess,
                            disabled: isDisabled
                        }
                    ]}
                />
            }
        >
            {loadingDefects ? <Loading linear /> : null}
            <Form
                data={fields}
                requiredFields={requiredFields}
                onChange={handleChange}
                validation={validation}
            />
        </Modal>
    );
};

export default ModalForm;
