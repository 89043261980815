import React, { useEffect } from 'react';
import FormButtons from '../../../../common/FormButtons';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import messages from '../../../../../helpers/constants/messages';
import { loadCSODDFile, uploadCSODDFile, uploadFileToStorage } from '../../../../../redux/Upload/actions';
import Item from './Item';
import { uploadSelectors } from '../../../../../redux/Upload';
import FileUploader from '../../../../common/Upload/FileUploader';
import { makeStyles } from '@mui/styles';
import Loading from '../../../../common/Loading';

const useStyles = makeStyles({
    item: {
        backgroundColor: '#e4edef',
        padding: '1rem',
        borderRadius: 5,
        margin: '20px 0',
    },
});

interface FileObject<T> {
    [key: string]: T;
}

const ElectronicITMS = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const data = useSelector(uploadSelectors.CSODDFile);
    const loading = useSelector(uploadSelectors.uploading);

    const loadList = () => dispatch(loadCSODDFile());

    useEffect(() => {
        dispatch(loadCSODDFile());
    },[dispatch]);
    
    const handleSave = (data: FileObject<string>) => dispatch(uploadCSODDFile(data, loadList));

    const handleChange = (value: FileObject<string>) => {
        const obj = {
            link: value?.public_link,
            filename: value?.name
        };
        handleSave(obj);
    };

    return (
        <>
            <h1>Электронный КСОДД</h1>
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    afterTemplate={
                        <FileUploader
                            title="Загрузить файл"
                            action={uploadFileToStorage}
                            isButton={true}
                            onFileUpload={(value: FileObject<string>) => handleChange(value)}
                            service="road-work"
                            startIcon={<i className="fal fa-plus" style={{ fontSize: '1rem' }} />}
                            endIcon={null}
                            callback={null}
                            buttonType="upload"
                        />
                    }
                />
            </div>
            {loading && <Loading linear />}
            {data
                ? (
                    <div className={classes.item}>
                        <Item
                            item={data}
                            loadList={loadList}
                        />
                    </div>
                )
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>
    );
};

export default ElectronicITMS;