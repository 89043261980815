import { useState } from 'react';
import { TextField } from '@mui/material';

import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';

import type { FilterProps } from './types';

const Filter = ({ setParams }: FilterProps) => {

    const initialState = {
        query: '',
        start_date: null,
        end_date: null,
    };
    const [formData, setFormData] = useState(initialState);

    const handleChange = (name: string, value: string | Date | null) => {
        setFormData({ ...formData, [name]: value });
    };

    const search = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            start_date: fullDateTimeWithTimeZone(formData.start_date),
            end_date: fullDateTimeWithTimeZone(formData.end_date),
        }, false);

        setParams(prepareData);
    };

    const resetFilter = (needRefresh: boolean) => {
        if (needRefresh) setParams({});
        setFormData(initialState);
    };

    const updateFilter = (filters: { [key: string]: any }) => {
        setFormData({ ...initialState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({ ...initialState, ...filters });
        }
    };

    const isDisabled = Object.keys(removeEmptyFields(formData)).length === 0;

    return (
        <LayoutFilter
            filter={formData}
            onResetFilter={resetFilter}
            onSearch={search}
            setUserFilter={updateFilter}
            filterException={['start_date', 'end_date']}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={formData.query}
                    onChange={(e) => handleChange('query', e.target.value)}
                    label="Название"
                    variant="outlined"
                    size="small"
                />
                <DateRange
                    valueStartDate={formData.start_date}
                    valueEndDate={formData.end_date}
                    handleDate={(value) => handleChange('start_date', value)}
                    handleEndDate={(value) => handleChange('end_date', value)}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;