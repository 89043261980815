import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setMapLoading } from 'redux/Map/actions';
import { showMessage } from 'redux/Message/actions';
import messageTypes from 'redux/Message/messagetypes';
import messages from 'helpers/constants/messages';

import * as actions from './actions';
import * as types from './types';
import api from './api.methods';

function* loadVehiclesSaga({ payload, fnLoading, fnCallback }) {
    const { page, limit, params } = payload;
    yield fnLoading
        ? fnLoading(true)
        : put(actions.loadingVehicles(true));
    const response = yield call(api.getVehicles, page, limit, params);
    if (response?.success) {
        yield fnCallback
            ? fnCallback(response)
            : put(actions.loadedVehicles(response));
    }
    yield fnLoading
        ? fnLoading(false)
        : put(actions.loadingVehicles(false));
}

function* loadVehicleTypesSaga() {
    yield put(actions.loadingVehicleTypes(true));
    const response = yield call(api.getVehicleTypes);
    if (response?.success) {
        yield put(actions.loadedVehicleTypes(response.data));
    }
    yield put(actions.loadingVehicleTypes(false));
}

function* createVehicleSaga({ payload, callback }) {
    const response = yield call(api.createVehicle, payload);
    if (response?.success) {
        yield callback && callback();
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
    }
}

function* editVehicleSaga({ payload, callback }) {
    const { id, data } = payload;

    const response = yield call(api.editVehicle, id, data);
    if (response?.success) {
        yield callback && callback();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
}

function* deleteVehicleSaga({ payload, callback }) {
    const { id } = payload;
    const response = yield call(api.deleteVehicle, id);
    if (response?.success) {
        yield callback && callback();
        yield showMessage(messageTypes.success, messages.DELETE_SUCCESS);
    }
}

function* loadDockSidebarSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getDockSidebar, payload);
    if (response?.success) {
        yield fnCallback?.(response);
    }
    yield fnLoading?.(false);
}

function* loadDockPolygonSaga({ payload, fnLoading, fnCallback }) {
    yield put(setMapLoading('transportWater', true));
    yield fnLoading?.(true);
    const response = yield call(api.getDockPolygon, payload);
    if (response?.success) {
        yield fnCallback?.(response);
    }
    yield fnLoading?.(false);
    yield put(setMapLoading('transportWater', false));
}

function* loadDockCoordsSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getDockPolygon, payload);
    if (response?.success) {
        yield fnCallback?.(response);
    }
    yield fnLoading?.(false);
}

function* loadDockEventsSaga({ payload, fnLoading, fnCallback }) {
    const {
        id,
        filter,
    } = payload;
    yield fnLoading?.(true);
    const response = yield call(api.getDockEvents, id, filter);
    if (response?.success) {
        yield fnCallback?.(response);
    }
    yield fnLoading?.(false);
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_VEHICLES, loadVehiclesSaga),
        takeLatest(types.CREATE_VEHICLE, createVehicleSaga),
        takeLatest(types.EDIT_VEHICLE, editVehicleSaga),
        takeLatest(types.DELETE_VEHICLE, deleteVehicleSaga),
        takeLatest(types.LOAD_VEHICLE_TYPES, loadVehicleTypesSaga),

        takeLatest(types.LOAD_DOCK_SIDEBAR, loadDockSidebarSaga),
        takeLatest(types.LOAD_DOCK_POLYGON, loadDockPolygonSaga),
        takeLatest(types.LOAD_DOCK_COORDS, loadDockCoordsSaga),
        takeLatest(types.LOAD_DOCK_EVENTS, loadDockEventsSaga)
    ]);
}
