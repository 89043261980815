import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trafficLightsSelectors } from '../../../../../../../../redux/TrafficLights';
import mapHelper from '../../../../../../../../helpers/mapHelper';
import { getForPolygon } from '../../../../../../../../redux/TrafficLights/actions';
import { Marker, ToolTip } from '../../../../../../../MapComponents/leaflet';
import { createIconMarker } from '../../../../../../../MapComponents/Layers/TrafficLights/helper';
import { useDebounce } from '../../../../../../../../helpers/hooks';


const LightsPolygon = (props) => {
    const { map, selectedLights, setSelectedLights } = props;
    const dispatch = useDispatch();

    const polygon = useSelector(trafficLightsSelectors.polygon);

    const fetchPolygon = () => {
        const polygon = mapHelper.getPolygon(map);
        dispatch(getForPolygon(polygon));
    };

    const debounceFetchPolygon = useDebounce(fetchPolygon, 500);
    
    useEffect(() => {
        debounceFetchPolygon();

        map
            .on('moveend', debounceFetchPolygon)
            .on('zoomend', debounceFetchPolygon);

        return () => {
            map
                .off('moveend', debounceFetchPolygon)
                .off('zoomend', debounceFetchPolygon);
        };
    }, []);

    const selectedLightsIds = useMemo(() => selectedLights.reduce((res, item) => {
        res[item.id] = true;
        return res;
    },{}),[selectedLights]);

    return (
        <div>
            {selectedLights.map((item) => {
                const { id, lat, lon } = item;

                return (
                    <Marker
                        {...props}
                        attribution={{ color: '#4b9c4b' }}
                        key={`item_${id}`}
                        onClick={() => setSelectedLights(selectedLights.filter(el => el.id !== id))}
                        latlng={[lat, lon]}
                        icon={createIconMarker('#4b9c4b', id)}
                    >
                        <ToolTip
                            offset={[0, -38]}
                            direction="top"
                        >
                            <div>
                                <b>Светофор:</b>&nbsp;{item.name}
                            </div>
                        </ToolTip>

                    </Marker>
                );
            })}

            {polygon.reduce((res, item) => {
                const { id, lat, lon, color } = item;

                if (!selectedLightsIds[item.id]){
                    res.push((
                        <Marker
                            {...props}
                            attribution={{ color }}
                            key={`item_${id}`}
                            onClick={() => setSelectedLights([...selectedLights, item])}
                            latlng={[lat, lon]}
                            icon={createIconMarker(color, id)}
                        >
                            <ToolTip
                                offset={[0, -38]}
                                direction="top"
                            >
                                <div>
                                    <b>Светофор:</b>&nbsp;{item.name}
                                </div>
                            </ToolTip>

                        </Marker>
                    ));
                }
                
                return res;
            },[])}
        </div>
    );
};

export default LightsPolygon;
