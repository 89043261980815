import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Checkbox,
    FormControlLabel,
    List,
    TextField
} from '@mui/material';

import { createGovernmentContract, editGovernmentContract, loadGovernmentContractById } from 'redux/GovernmentContracts/actions';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import DateRange from 'components/common/Dates/DateRange';
import Attachments from 'components/common/Upload/Attachments';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import Loading from 'components/common/Loading';
import { dateWithDashYYYYMMDD, fullDateTimeWithTimeZone } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useValidation } from 'helpers/hooks';

import ComplexItem from '../PassportPage/CommonEntitiesPage/ComplexItem';

import ComplexListModal from './ComplexListModal';

import type { CompanyType, ComplexItemType, ItemProps, ModalFormPropTypes } from '../types';


const ModalForm = ({ isOpen, isNew, onClose, reloadList, item, foundCompanies }: ModalFormPropTypes) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    // @ts-ignore
    const { loadingButton } = useSelector(({ dorisControl }) => dorisControl);
    const [loading, setLoading] = useState(false);

    const initialState = {
        name: item?.name || '',
        number: item?.number || '',
        terms: item?.terms || '',
        customer: item?.customer ? foundCompanies?.[item.customer] : {},
        contractor: item?.contractor ? foundCompanies?.[item.contractor] : {},
        started_at: item?.started_at || null,
        ended_at: item?.ended_at || null,
        is_forever: item?.is_forever || false,
        procurement_link: item?.procurement_link || '',
        complexes: item?.complexes || [],
        _comment: '',
        signed_at: item?.signed_at || null,
        files: item?.files || [],
        srm_guarantee: item?.srm_guarantee || null,
        equipment_guarantee: item?.equipment_guarantee || null,
    };

    const [data, setData] = useState(initialState);
    const [showList, setShowList] = useState(false);

    useEffect(() => {
        if (item?.id) {
            setLoading(true);

            dispatch(loadGovernmentContractById(item.id, (el: ItemProps) => {
                setLoading(false);
                setData((prev) => ({
                    ...prev,
                    complexes: el.complexes
                }));
            }));
        }
    }, [dispatch, item]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name, checked } = e.target;

        if (name === 'is_forever') {
            return setData({
                ...data,
                is_forever: checked,
                started_at: null,
                ended_at: null,
            });
        }

        setData({
            ...data,
            [name]: value
        });

        validation.deleteKey('name');
    };


    // const deleteComplex = (id: number) => {
    //     setData({
    //         ...data,
    //         complexes: data.complexes.filter(el => el.id !== id)
    //     });
    // };

    const onAcceptModal = () => {
        const result = removeEmptyFields({
            ...data,
            started_at: fullDateTimeWithTimeZone(data.started_at),
            ended_at: fullDateTimeWithTimeZone(data.ended_at),
            complexes: data?.complexes.map(item => ({ id: item.id })),
            customer: data.customer?.id,
            contractor: data.contractor?.id,
            signed_at: dateWithDashYYYYMMDD(data.signed_at),
            srm_guarantee: dateWithDashYYYYMMDD(data.srm_guarantee),
            equipment_guarantee:dateWithDashYYYYMMDD(data.equipment_guarantee),
        });

        const callback = () => {
            reloadList(false);
            onClose();
            validation.clear();
        };

        dispatch(isNew
            ? createGovernmentContract({ ...result, is_forever: data.is_forever }, callback)
            : editGovernmentContract(item?.id, { ...result, is_forever: data.is_forever }, callback));
    };

    const helperText = (data._comment.length > 0 && data._comment.trim().length < 6)
        ? 'Количество символов должно быть не менее 6'
        : '';

    const isDisabled = !data.name.trim()
        || !data.number.trim()
        || !data.terms.trim()
        || !data.customer?.id
        || !data.contractor?.id
        || !data.procurement_link.trim()
        || (!data.is_forever ? (!data.ended_at && !data.started_at) : false)
        || (!isNew && data._comment.trim().length < 6);

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? 'Добавить ГК' : 'Редактировать ГК'}
            onClose={onClose}
            noPadding={true}
            buttons={
                <>
                    {!isNew && (
                        <TextField
                            label="Причина редактирования"
                            size="small"
                            value={data._comment}
                            variant="outlined"
                            name="_comment"
                            type="text"
                            style={{ marginLeft: 16, minWidth: '40%' }}
                            InputProps={{
                                style: { background: 'white' }
                            }}
                            onChange={handleChange}
                            required
                            error={data._comment.trim().length < 6}
                            helperText={helperText}
                        />
                    )}

                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: onClose
                            },
                            {
                                ...buttonsTypes.save,
                                onClick: onAcceptModal,
                                loading: loadingButton,
                                disabled: isDisabled
                            }
                        ]}
                    />
                </>
            }
        >
            <form className="modal__form">
                <div className="block">
                    <TextField
                        label={'Название Госконтракта'}
                        size="small"
                        value={data.name}
                        variant="outlined"
                        name="name"
                        type="text"
                        onChange={handleChange}
                        required
                        error={validation.isKey('name')}
                        helperText={validation.get('name')}
                    />
                </div>
                <div className="block">
                    <TextField
                        label={'Номер Госконтракта'}
                        size="small"
                        value={data.number}
                        variant="outlined"
                        name="number"
                        type="text"
                        onChange={handleChange}
                        required
                        error={validation.isKey('number')}
                        helperText={validation.get('number')}
                    />
                </div>
                <div className="block">
                    <TextField
                        label="Условия"
                        size="small"
                        value={data.terms}
                        variant="outlined"
                        name="terms"
                        type="text"
                        onChange={handleChange}
                        required
                        error={validation.isKey('terms')}
                        helperText={validation.get('terms')}
                    />
                </div>
                <div className="block">
                    <SelectCompany
                        label={'Заказчик по контракту'}
                        required
                        selected={data?.customer}
                        // @ts-ignore: error message
                        onChange={(customer: CompanyType) => setData({ ...data, customer })}
                        error={validation.isKey('customer')}
                        helperText={validation.get('customer')}
                    />
                </div>
                <div className="block">
                    <SelectCompany
                        label={'Исполнитель контракта'}
                        required
                        selected={data?.contractor}
                        // @ts-ignore: error message
                        onChange={(contractor: CompanyType) => setData({ ...data, contractor })}
                        error={validation.isKey('contractor')}
                        helperText={validation.get('contractor')}
                    />
                </div>
                <div className="row">
                    <DateRange
                        startLabel="Дата начала оказания услуг"
                        endLabel="Дата окончания оказания услуг"
                        className="row__item"
                        handleDate={(e: Date | null) => setData({ ...data, started_at: e })}
                        handleEndDate={(e: Date | null) => setData({ ...data, ended_at: e })}
                        valueStartDate={data.started_at}
                        valueEndDate={data.ended_at}
                        dateOnly
                        minDate={{ year: 2000 }}
                    />
                </div>
                <div className="row">
                    <SingleKeyboardDateTimePicker
                        value={data.srm_guarantee}
                        onChange={(e: any) => setData({ ...data, srm_guarantee: e })}
                        label="Гарантия СМР"
                        className="row__item"
                        dateOnly
                    />
                    <SingleKeyboardDateTimePicker
                        value={data.equipment_guarantee}
                        onChange={(e: any) => setData({ ...data, equipment_guarantee: e })}
                        label="Гарантия оборудование"
                        className="row__item"
                        dateOnly
                    />
                </div>
                <SingleKeyboardDateTimePicker
                    value={data.signed_at}
                    onChange={(e: Date | null) => setData({ ...data, signed_at: e })}
                    label="Дата подписания ГК"
                    className="block"
                    dateOnly
                    minDate={{ year: 2000 }}
                />

                <div className="block">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={data.is_forever}
                                onChange={handleChange}
                                name="is_forever"
                            />
                        }
                        label="Бессрочно"
                    />
                </div>
                <div className="block">
                    <TextField
                        label={'Ссылка на ГК'}
                        size="small"
                        value={data.procurement_link}
                        variant="outlined"
                        name="procurement_link"
                        type="text"
                        onChange={handleChange}
                        required
                        error={validation.isKey('procurement_link')}
                        helperText={validation.get('procurement_link')}
                    />
                </div>
                <Attachments
                    label={'Добавить файл'}
                    files={data.files}
                    // @ts-ignore
                    onChange={(files: any[]) => setData({ ...data, files })}
                />
                <div className="block">
                    <h2>Присвоенные КФВФ:</h2>
                    {loading && <Loading linear />}
                    {data.complexes?.length
                        ? <List className="list">
                            {data.complexes?.map((el: ComplexItemType, i) =>
                                <ComplexItem key={el.id} item={el} i={i} clickable={false} />
                            )}
                        </List>
                        : <p style={{ padding: '1rem' }}>{messages.NO_DATA}</p>
                    }
                    <FormButtons
                        buttons={[
                            {
                                variant: 'contained',
                                color: 'primary',
                                type: 'button',
                                name: 'Присвоить КФВФ',
                                onClick: () => setShowList(true),
                            },
                        ]}
                    />
                </div>
            </form>

            {showList && (
                <ComplexListModal
                    isOpen={showList}
                    item={item}
                    onChange={(value) => setData({ ...data, complexes: value })}
                    onClose={() => setShowList(false)}
                    selected={data.complexes}
                />
            )}
        </Modal>
    );
};

export default ModalForm;
