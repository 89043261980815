import { List } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../helpers/constants/messages';
import { loadCameraTemplateList } from '../../../../redux/VideoCameras/actions';
import CustomPagination from '../../../common/CustomPagination';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import Loading from '../../../common/Loading';
import Item from './Item';
import ModalForm from './ModalForm';

const CamerasTemplates = () => {
    const dispatch = useDispatch();
    
    const [page, setPage] = useState(1);
    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);
    const [openAddModal, setOpenAddModal] = useState(false);

    const { 
        cameraTemplateList: { data, meta }, 
        loadingCameraTemplateList: loading 
    } = useSelector(({ videoCameras }) => videoCameras);

    const loadList = (isDelete) => {
        isDelete && data?.length === 1 && meta?.last_page > 1
            ? setPage(page - 1)
            : dispatch(loadCameraTemplateList(page, limit));
    };

    useEffect(() => {
        dispatch(loadCameraTemplateList(page, limit));
    },[dispatch, limit, page]);

    return (
        <>
            <h1>Пользовательские шаблоны камер</h1>
            <div className="filter__wrap__btn">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenAddModal(true),
                        }
                    ]}
                />
            </div>
            {loading && <Loading circular={true}/>}
            {data?.length > 0
                ? (<>
                    <List className="list">
                        {data?.map(item =>
                            <Item
                                key={item.id}
                                item={item}
                                loadList={loadList}
                            />         
                        )}
                    </List>
                    <CustomPagination
                        loadList={(page) => setPage(page)}
                        list={meta}
                        limit={limit} 
                        setLimit={setLimit}
                    />
                </>)
                : !loading && <div>{messages.DATA_IS_NOT_FOUND}</div>
            }
            {openAddModal
                && <ModalForm
                    isNew={true}
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    loadList={loadList}
                />
            }
        </>
    );
};

export default CamerasTemplates;
