import SignIn from '../pages/SignIn';
import SignOut from '../pages/SignOut';
import ResetPassword from '../pages/ResetPassword';
import AppLayout from '../layout/AppLayout';

const pageRoutes = [
    {
        path: '/signin',
        component: SignIn,
        exact: true,
    },
    {
        path: '/logout/:all?',
        component: SignOut,
        exact: true,
    },
    // {
    //     path: '/reset-password',
    //     component: ResetPassword,
    //     exact: true,
    // },

    {
        component: AppLayout,
        exact: true,
        restricted: true
    },
];

export default pageRoutes;
