export const trafficFlow = (state) => state.trafficFlow;

export const list = (state) => trafficFlow(state).list;
// export const listData = (state) => list(state).data;
// export const listMeta = (state) => list(state).meta;
export const listLoading = (state) => trafficFlow(state).listLoading;
export const listFilter = (state) => trafficFlow(state).listFilter;

export const sidebar = (state) => trafficFlow(state).sidebar;
export const sidebarData = (state) => sidebar(state).data;
export const sidebarMeta = (state) => sidebar(state).meta;
export const sidebarLoading = (state) => trafficFlow(state).sidebarLoading;

export const polygon = (state) => trafficFlow(state).polygon;
export const polygonLoading = (state) => trafficFlow(state).polygonLoading;
export const polygonVisible = (state) => trafficFlow(state).polygonVisible;
export const filters = (state) => trafficFlow(state).filters;
export const active = (state) => trafficFlow(state).active;
export const statuses = (state) => trafficFlow(state).statuses;
export const types = (state) => trafficFlow(state).types;

export const traffic = (state) => trafficFlow(state).traffic;
// export const trafficData = (state) => traffic(state).data;
export const trafficLoading = (state) => trafficFlow(state).trafficLoading;

export const report = (state) => trafficFlow(state).report;
export const loadingReport = (state) => trafficFlow(state).loadingReport;
export const filterReport = (state) => trafficFlow(state).filterReport;
// export const reportGroups = (state) => trafficFlow(state).reportGroups;
// export const reportTypes = (state) => trafficFlow(state).reportTypes;

export const transportFlow = (state) => trafficFlow(state).transportFlow;
export const loadingTransportFlow = (state) => trafficFlow(state).loadingTransportFlow;
export const transportFlowGroups = (state) => trafficFlow(state).transportFlowGroups;
export const throughputLoading = (state) => trafficFlow(state).throughputLoading;
export const throughputData = (state) => trafficFlow(state).throughputData;

export const heatMap = (state) => trafficFlow(state).heatMap;
export const heatMapLoading = (state) => trafficFlow(state).heatMapLoading;
export const heatMapFilter = (state) => trafficFlow(state).heatMapFilter;

export const showcase = (state) => trafficFlow(state).showcase;
export const loadingShowcase = (state) => trafficFlow(state).loadingShowcase;

export const reportIndicators = (state) => trafficFlow(state).reportIndicators;
export const loadingReportIndicators = (state) => trafficFlow(state).loadingReportIndicators;
export const filterReportIndicators = (state) => trafficFlow(state).filterReportIndicators;

export const loadingLogicalPuid = (state) => trafficFlow(state).loadingLogicalPuid;
export const savedLogicalPuid = (state) => trafficFlow(state).savedLogicalPuid;

export const ppuidByIds = (state) => trafficFlow(state).ppuidByIds;
export const loadingPpuidByIds = (state) => trafficFlow(state).loadingPpuidByIds;

export const puidByRadius = (state) => trafficFlow(state).puidByRadius;
export const loadingPpuidByRadius = (state) => trafficFlow(state).loadingPpuidByRadius;

export const settings = (state) => trafficFlow(state).settings;
export const loadingSettings = (state) => trafficFlow(state).loadingSettings;

export const loadingButton = (state) => trafficFlow(state).loadingButton;

export const reportStatisticType = (state) => trafficFlow(state).reportStatisticType;
export const reportTimeSlot = (state) => trafficFlow(state).reportTimeSlot;
