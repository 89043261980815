import usePrevious from './usePrevious';
import { isEqual } from 'lodash';

// изменилось значение
const useChangeValue = (value) => {
    const prevValue = usePrevious(value || null);
    return !isEqual(value, prevValue);
};

export default useChangeValue;
