import Stations from 'components/pages/Dictionaries/PassengerTransport/Stations';
import Brands from 'components/pages/Dictionaries/PassengerTransport/Brands';
import Vehicles from 'components/pages/Dictionaries/PassengerTransport/Vehicles';
import Models from 'components/pages/Dictionaries/PassengerTransport/Models';
import Routes from 'components/pages/Dictionaries/PassengerTransport/Routes';
import VehicleToRoute from 'components/pages/Dictionaries/PassengerTransport/VehicleToRoute';
import Positions from 'components/pages/Dictionaries/PassengerTransport/Positions';
import ScheduleEvents from 'components/pages/Dictionaries/PassengerTransport/Events';
import Employees from 'components/pages/Dictionaries/PassengerTransport/Employees';
import Brigades from 'components/pages/Dictionaries/PassengerTransport/Brigades';
import ScheduleTemplates from 'components/pages/Dictionaries/PassengerTransport/ScheduleTemplates';
import Parks from 'components/pages/Dictionaries/PassengerTransport/Parks';
import Workorders from 'components/pages/Dictionaries/PassengerTransport/Workorders';
import WorkordersCalendar from 'components/pages/Dictionaries/PassengerTransport/WorkordersCalendar';
import Appeal from 'components/pages/Dictionaries/PassengerTransport/AppealCitizens';
import Equipment from 'components/pages/Dictionaries/PassengerTransport/Equipment';
import TrafficSchedule from 'components/pages/Dictionaries/PassengerTransport/TrafficSchedule';
import WorkordersControl from 'components/pages/Dictionaries/PassengerTransport/WorkordersControl';
import RouteRequests from 'components/pages/Dictionaries/PassengerTransport/RouteRequests';
import Schedule from 'components/pages/Dictionaries/PassengerTransport/Schedule';
import TicketPoints from 'components/pages/Dictionaries/PassengerTransport/TicketPoints';
import Tariffs from 'components/pages/Dictionaries/PassengerTransport/Tariffs';
import Waybills from 'components/pages/Dictionaries/PassengerTransport/Waybills';
import TelemetryStatistics from 'components/pages/Dictionaries/PassengerTransport/TelemetryStatistics';
import TransportSettings from 'components/pages/Dictionaries/PassengerTransport/Settings';
import VehicleTypes from 'components/pages/Dictionaries/PassengerTransport/VehicleTypes';
import SpeedColor from 'components/pages/Dictionaries/PassengerTransport/SpeedColor';
import TelemetryReceiversForPT from 'components/pages/Dictionaries/PassengerTransport/TelemetryReceiversForPT';
import WorkCalendar from 'components/pages/Dictionaries/PassengerTransport/WorkCalendar';
import RepeatersTelemetry from 'components/pages/Dictionaries/PassengerTransport/RepeatersTelemetry';

const routes = [
    {
        path: '/dictionaries/passenger-transport/stations',
        component: Stations,
        exact: true,
    },
    {
        path: '/dictionaries/passenger-transport/brands',
        component: Brands,
        exact: true,
    },
    {
        path: '/dictionaries/passenger-transport/vehicles',
        component: Vehicles,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/models',
        component: Models,
        exact: true,
    },
    {
        path: '/dictionaries/passenger-transport/routes',
        component: Routes,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/vehicle-to-route',
        component: VehicleToRoute,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/positions',
        component: Positions,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/events',
        component: ScheduleEvents,
        exact: true,
    },
    {
        path: '/dictionaries/passenger-transport/employees',
        component: Employees,
        exact: true,
    },
    {
        path: '/dictionaries/passenger-transport/brigades',
        component: Brigades,
        exact: true,
    },
    {
        path: '/dictionaries/passenger-transport/schedule-templates/',
        component: ScheduleTemplates,
    },
    {
        path: '/dictionaries/passenger-transport/parks',
        component: Parks,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/workorders',
        component: Workorders,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/workorders-calendar',
        component: WorkordersCalendar,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/appeals',
        component: Appeal,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/equipment',
        component: Equipment,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/traffic-schedule/:route_id?',
        component: TrafficSchedule,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/workorders-control/:schedule_id?',
        component: WorkordersControl,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/route-requests',
        component: RouteRequests,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/schedule',
        component: Schedule,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/ticket-points',
        component: TicketPoints,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/tariff',
        component: Tariffs,
    },
    {
        path: '/dictionaries/passenger-transport/waybills',
        component: Waybills,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/telemetry-statistics',
        component: TelemetryStatistics,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/settings',
        component: TransportSettings,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/vehicle-types',
        component: VehicleTypes,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/speed-color',
        component: SpeedColor,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/telemetry-receivers',
        component: TelemetryReceiversForPT,
        exact: true
    },
    {
        path: '/dictionaries/passenger-transport/work-calendar',
        component: WorkCalendar,
        exact: true
    },

    {
        path: '/dictionaries/passenger-transport/repeaters-telemetry',
        component: RepeatersTelemetry,
        exact: true
    },

];

export default routes;
