class ConnectionError extends Error {
    constructor() {
        super('Неполадки с интернет соединением');
        this.className = 'ConnectionError';
        if (Error.captureStackTrace instanceof Function) {
            Error.captureStackTrace(this, ConnectionError);
        }
    }
}

export { ConnectionError };
