import React from 'react';
import messages from '../../../../../../../helpers/constants/messages';
import titles from '../../../../../../../helpers/constants/titles';
import {
    List,
    ListItem,
} from '@mui/material';

const ServiceCompaniesList = ({ currentRoute }) => {

    return (
        <>
            <h2>Обслуживающие предприятия: </h2>
            <List>
                {currentRoute?.enterprises?.length !== 0
                    ? currentRoute?.enterprises?.map(item => {
                        return (
                            <ListItem  key={item?.id}>
                                <span>
                                    <b>{titles.NAME}: </b>
                                    {item?.title || messages.INFO_IS_NOT_FOUND}
                                </span>
                            </ListItem>
                        );
                    })
                    : <div>{messages.INFO_IS_NOT_FOUND}</div>
                }
            </List>
        </>
    );
}; 

export default ServiceCompaniesList;