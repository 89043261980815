import type {
    Views,
    PlaceholderKeys,
    FormatKeys,
    PatternConfig,
    CustomPatternFields,
    ViewsElement,
} from '../../../types/Dates';

const splitterRegex = /([^a-zA-Z])/;

export const formatKeys: FormatKeys = { // соответствие полей для плейсхолдера даапикеров
    day: 'dd',
    month: 'MM',
    year: 'yyyy',
    hours: 'HH',
    minutes: 'mm',
    seconds: 'ss',
};

export const placeholderKeys: PlaceholderKeys = {
    day: 'ДД',
    month: 'ММ',
    year: 'ГГГГ',
    hours: 'ЧЧ',
    minutes: 'ММ',
    seconds: 'СС',
};

export const patternConfig: PatternConfig = [
    {
        value: 5, // для вычисления startOf какая минимальная единица даты
        minValue: 'year',// например для метода getStartOf
        mask: '____',
        placeholder: placeholderKeys.year,
        pickerFormat: formatKeys.year,
        divider: ' ',
        views: 'year', // как значение указано в массиве views
    },
    {
        value: 4, // для вычисления startOf какая минимальная единица даты
        minValue: 'month',// например для метода getStartOf
        mask: '__',
        placeholder: placeholderKeys.month,
        pickerFormat: formatKeys.month,
        divider: '.',
        views: 'month', // как значение указано в массиве views
    },
    {
        value: 3, // для вычисления startOf какая минимальная единица даты
        minValue: 'day',// например для метода getStartOf
        mask: '__',
        placeholder: placeholderKeys.day,
        pickerFormat: formatKeys.day,
        divider: '.',
        views: 'day', // как значение указано в массиве views
    },
    {
        value: 2, // для вычисления startOf какая минимальная единица даты
        minValue: 'hour',// например для метода getStartOf
        mask: '__',
        placeholder: placeholderKeys.hours,
        pickerFormat: formatKeys.hours,
        divider: ':',
        views: 'hours', // как значение указано в массиве views
    },
    {
        value: 1, // для вычисления startOf какая минимальная единица даты
        minValue: 'minute',// например для метода getStartOf
        mask: '__',
        placeholder: placeholderKeys.minutes,
        pickerFormat: formatKeys.minutes,
        divider: ':',
        views: 'minutes', // как значение указано в массиве views
    },
    {
        value: 0, // для вычисления startOf какая минимальная единица даты
        minValue: 'second', // например для метода getStartOf
        mask: '__',
        placeholder: placeholderKeys.seconds,
        pickerFormat: formatKeys.seconds,
        divider: '',
        views: 'seconds', // как значение указано в массиве views
    },
];

const TIME = `${formatKeys.hours}:${formatKeys.minutes}`;
const DATE =  `${formatKeys.day}.${formatKeys.month}.${formatKeys.year}`;
const DATE_TIME = `${DATE} ${TIME}`;

export const defaultPatterns = {
    dateOnly: {
        mask: '__.__.____',
        placeholder: 'ДД.ММ.ГГГГ',
        pickerFormat: DATE,
        minValue: 'day', // для вычисления startOf какая минимальная единица даты
    },
    timeOnly: {
        mask: '__:__',
        placeholder: 'ЧЧ:ММ',
        pickerFormat: TIME,
        minValue: 'minute', // для вычисления startOf какая минимальная единица даты
    },
    dateTime: {
        mask: '__.__.____ __:__',
        placeholder: 'ДД.ММ.ГГГГ ЧЧ:ММ',
        pickerFormat: DATE_TIME,
        minValue: 'minute', // для вычисления startOf какая минимальная единица даты
    },
};

const createPattern = (views: Views, pickerFormat: string | undefined): CustomPatternFields | null => {
    // если у нас есть pickerFormat но нет views мы преобразуем pickerFormat
    // в массив строк как в views чтобы сделать маску

    if (pickerFormat) {
        const pickerFormatArray = pickerFormat.split(splitterRegex);

        return pickerFormatArray.reduce((result: CustomPatternFields, el: string) => {
            const findItem = patternConfig.find((elem) => elem.pickerFormat === el);
            result.mask = `${result.mask}${findItem ? findItem.mask : el}`;
            result.placeholder = `${result.placeholder}${findItem ? findItem.placeholder : el}`;
            if (findItem && findItem.value < result.value) {
                result.value = findItem.value;
                result.minValue = findItem.minValue;
            }
            return result;
        }, { mask: '', placeholder: '', pickerFormat, value: 5, minValue: 'year' });

    } else if (views && views.length > 0) {
        const extendView = [...views];
        if (extendView.includes('day') && !extendView.includes('month') && !extendView.includes('year')) {
            extendView.splice(extendView.indexOf('day') + 1, 0, 'month', 'year');
        }

        return extendView.reduce((result: CustomPatternFields, el: ViewsElement, index: number) => {
            const findItem = patternConfig.find((elem) => elem.views === el);
            if (findItem) {
                result.mask = `${result.mask}${findItem.mask}${index !== extendView.length -1 ? findItem.divider : ''}`;
                result.placeholder = `${result.placeholder}${findItem.placeholder}${index !== extendView.length -1 ? findItem.divider : ''}`;
                result.pickerFormat = `${result.pickerFormat}${findItem.pickerFormat}${index !== extendView.length -1 ? findItem.divider : ''}`;
                if (findItem.value < result.value) {
                    result.value = findItem.value;
                    result.minValue = findItem.minValue;
                }
            }
            return result;
        }, { mask: '', placeholder: '', pickerFormat: '', value: 5, minValue: 'year' });
    }
    return null;
};

export const customPattern = (views: Views,  pickerFormat?: string | undefined, dateOnly?: boolean | undefined, timeOnly?: boolean | undefined) => {
    let pattern = null;
    if ((views && views.length > 0) || pickerFormat) {
        pattern = createPattern(views, pickerFormat);
    }

    return pattern !== null
        ? pattern
        : dateOnly
            ? defaultPatterns.dateOnly
            : timeOnly
                ? defaultPatterns.timeOnly
                : defaultPatterns.dateTime;
};
