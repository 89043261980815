import { useState } from 'react';
import { TextField } from '@mui/material';
import titles from '../../../../helpers/constants/titles';
import CompanyRole from '../../../common/Autocomplete/CompanyRole';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({ setParams, initialState }) => {
    
    const [data, setData] = useState(initialState);

    const onChange = (e) => {
        const { name, value, checked } = e.target;
        setData({
            ...data,
            [name]: name === 'inn' ? value.replaceAll(/[^\d]/g, '') : name === 'withDeleted' ? checked ? 1 : 0 : value
        });
    };

    const search = (filters = data) => {
        setParams({ page: 1, data: filters });
    };

    const resetFilter = (needRefresh) => {
        setData(initialState);
        if (needRefresh) {
            setParams({ page: 1, data: initialState });
        }
    };

    const updateFilter = (filters) => {
        setData({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            search({
                ...initialState,
                ...filters,
            });
        }
    };

    const isDisabled = !data.title && !data.inn && !data.withDeleted && data.role_ids.length <= 0;

    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={search} 
            filter={data} 
            setUserFilter={updateFilter}
            disabled={isDisabled}
            filterException={['date_start', 'date_end',]}
        >
            <LayoutFilter.Visible>
                <TextField
                    variant="outlined"
                    value={data.title}
                    onChange={onChange}
                    label={titles.NAME}
                    size="small"
                    name="title"
                />
                <TextField
                    variant="outlined"
                    value={data.inn}
                    onChange={onChange}
                    label={titles.INN}
                    size="small"
                    name="inn"
                    type="text"
                    inputProps={{ pattern: '[0-9]{1,15}' }}
                />
                <CompanyRole
                    selected={data.role_ids}
                    multiple
                    onChange={(role_ids) => setData({ ...data, role_ids })}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
