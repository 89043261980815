import React, { useState } from 'react';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import Modal from '../../../common/Modal';
import { editCompany } from '../../../../redux/Companies/actions';
import { useDispatch } from 'react-redux';
import DocumentItem from './DocumentItem';
import FormButtonsComponent from '../../../common/FormButtons';

const Documents = ({ isOpen, onClose, item = {}, }) => {
    const dispatch = useDispatch();

    const [data, setData] = useState(item.attachments || []);
    const [isError, setIsError] = useState({});

    const noErrorCallback = () => {
        onClose();
    };

    const saveForm = () => {
        dispatch(editCompany(item?.id, { attachments: data }, noErrorCallback));
    };

    const handleError = (index, error) => {
        if (!isError[index] && error) {
            setIsError((prev) => ({
                ...prev,
                [index]: error,
            }));
        }
        if (isError[index] && !error) {
            const newErrorObj = { ...isError };
            delete newErrorObj[index];
            setIsError(newErrorObj);
        }
    };

    const handleAddArrItem = () => {
        setData([...data, {}]);
    };

    const handleChangeItem = (index, item) => {
        setData([
            ...data.slice(0, index),
            item,
            ...data.slice(index + 1)
        ]);
    };

    const handleRemoveItemFromArray = (index) => {
        setData([
            ...data.slice(0, index),
            ...data.slice(index + 1)
        ]);
    };

    const isDisabled = !data?.every(element => element.file && element.title)
        || Object.keys(isError).length > 0
        || !data.length;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            title="Документы на перевозку"
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isDisabled,
                        onClick: saveForm
                    },
                ]}
            />}
        >
            <div className="modal__form">
                {data?.length > 0
                    && data?.map((element, index) =>
                        <DocumentItem
                            key={index}
                            item={element}
                            handleChangeItem={(el) => handleChangeItem(index, el)}
                            // handleChangeDocuments={handleChangeDocuments}
                            // handleChangeDate={handleChangeDate}
                            // handleRemoveDocument={handleRemoveDocument}
                            handleRemoveItemFromArray={() => handleRemoveItemFromArray(index)}
                            onSetError={(error) => handleError(index, error)}
                        />
                    )
                }
                <FormButtonsComponent
                    noPadding
                    positionLeft
                    noMarginLeft
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: handleAddArrItem,
                            name: 'Добавить документ'
                        }
                    ]}
                />
            </div>

        </Modal>
    );
};

export default Documents;