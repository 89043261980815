import { useState } from 'react';
import { TextField } from '@mui/material';

import removeEmptyFields from 'helpers/removeEmptyFields';
import {
    fullDateTimeWithTimeZone,
    getDateWithDuration,
    getEndOf,
    getStartOf,
} from 'helpers/date.config';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const initialState = {
    date_start: getStartOf('day', getDateWithDuration({ day: -2 })),
    date_end: getEndOf(),
    complex_name: '',
    complex_serial: '',
};

const Filter = ({ setParams }) => {
    const[data, setData] = useState(initialState);

    const onChange = (e) => {
        const { name, value } = e.target;
        setData(prev => ({ ...prev, [name]: value }));
    };

    const onSearch = (params = data) => {
        const prepareData = removeEmptyFields({
            ...params,
            date_start: fullDateTimeWithTimeZone(params.date_start),
            date_end: fullDateTimeWithTimeZone(params.date_end),
        });
        setParams(prepareData);
    };
    
    const resetFilter = (needRefresh) => {
        needRefresh && onSearch(initialState);
        setData(initialState);
    };
    
    const updateFilter = (filters) => {
        const newFilter = { ...initialState, ...filters };
        setData(newFilter);
        onSearch(newFilter);
    };

    const isDisabled = Object.keys(removeEmptyFields(data)).length === 0;

    return (
        <LayoutFilter
            filter={data}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            filterException={['date_start', 'date_end']}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={data.date_start}
                    valueEndDate={data.date_end}
                    handleDate={(value) => onChange({ target: { name: 'date_start', value } })}
                    handleEndDate={(value) => onChange({ target: { name: 'date_end', value } })}
                />
                <TextField
                    value={data?.complex_name || ''}
                    onChange={onChange}
                    label={'Название комплекса'}
                    variant="outlined"
                    size="small"
                    name="complex_name"
                    type="text"
                />
                <TextField
                    value={data?.complex_serial || ''}
                    onChange={onChange}
                    label="Серийный номер комплекса"
                    variant="outlined"
                    size="small"
                    name="complex_serial"
                    type="text"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>

    );
};

export default Filter;
