import React from 'react';
import {
    TextField,
} from '@mui/material';

export const PaddingPosition = ({ x, y, handleChange, required, helperTextY, helperTextX, }) => {
    return (
        <div  className="row">
            <TextField
                value={x}
                onChange={handleChange}
                label="Отступ от левого края (px)"
                name="x"
                error={required && x === undefined}
                required={required}
                className="row__item"
                type="number"
                inputProps={{ min: 0 }}
                size="small"
                helperText={helperTextX}
            />
            <TextField
                value={y}
                onChange={handleChange}
                label="Отступ от верхнего края (px)"
                name="y"
                error={required && y === undefined}
                required={required}
                className="row__item"
                type="number"
                inputProps={{ min: 0 }}
                size="small"
                helperText={helperTextY}
            />
        </div>
    );
};