import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { boardsSelectors } from 'redux/Boards';
import BoardsListModal from 'components/common/Boards/BoardsListModal';
import BoardLinkList from 'components/common/Boards/BoardLinkList';
import useLoadBoardsList from 'helpers/hooks/Boards/useLoadBoardList';

const Boards = ({ item }) => {
    const loadingDitLink = useSelector(boardsSelectors.loadingDitLink);
    const loadingBoardsFromLink = useSelector(boardsSelectors.loadingBoardsFromLink);

    const [isOpenBoardListModal, setIsOpenBoardListModal] = useState(false);
    const [isNeedUpdate, setIsNeedUpdate] = useState(false);

    const list = useLoadBoardsList(item?.id,  item?.entity_name, 'meteo', isNeedUpdate);

    const isLoading = loadingDitLink || loadingBoardsFromLink;

    useEffect(() => {
        setIsNeedUpdate(false);
    }, [list]);

    return (
        <>
            <h2>{item?.name} Связанные ДИТ:</h2>

            <BoardLinkList
                loading={isLoading}
                dit_list={list}
                onClick={() => setIsOpenBoardListModal(true)}
            />

            {isOpenBoardListModal && (
                <BoardsListModal
                    id={item.id}
                    name={item.name}
                    entity_name={item.entity_name}
                    isOpen={isOpenBoardListModal}
                    microservice="meteo"
                    onClose={() => {
                        setIsOpenBoardListModal(false);
                        setIsNeedUpdate(true);
                    }}
                />
            )}
        </>
    );
};

export default Boards;
