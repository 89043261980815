import React, { useEffect, useState } from 'react';
import SidebarItem from '../../../SidebarItem';
import SidebarFilter from '../../../SidebarFilter';
import { Scrollbars } from 'react-custom-scrollbars';
import * as actions from '../../../../../redux/RoadNetwork/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../common/Loading';
import Item from './Item';
import { getFilter, createIcon, getColor } from '../helper';
import buttons from '../../../../../helpers/constants/buttons';
import messages from '../../../../../helpers/constants/messages';
import FilterForm from './Filter';
import { isEqual } from 'lodash';
import { usePrevious, useStoreProp } from '../../../../../helpers/hooks';
import SidebarTitle from '../../../SidebarTitle';
import getFilters from '../../../helpers/getFilters';
import config from '../config';
import { roadNetworksSelectors } from '../../../../../redux/RoadNetwork';
import {
    Icon,
} from '@mui/material';
import { loadConcentrationAreaStatuses, loadConcentrationAreaTypes } from '../../../../../redux/Incidents/actions';
import useForm from '../ModalForm/useForm';


const SideBar = (props) => {
    const dispatch = useDispatch();
    const [params, setParams] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25
    });
    const sidebar = useSelector(roadNetworksSelectors.sidebarInfrastructure);
    const sidebarLoading = useSelector(roadNetworksSelectors.loadingSidebarInfrastructure);
    const active = useSelector(roadNetworksSelectors.activePolygonInfrastructure);
    const filters = useSelector(roadNetworksSelectors.filterPolygonInfrastructure);

    const handleSaved = () => {
        setParams(old => ({
            ...old,
        }));
    };
    const providerForm = useForm(handleSaved);

    const prevFilters = usePrevious(filters);

    const activeId = Object.keys(active).length > 0 ? active.id : 0;

    const filter = getFilters(filters, getFilter);

    const types = useStoreProp(loadConcentrationAreaTypes, 'incidents', 'concentrationAreaTypes');
    const statuses = useStoreProp(loadConcentrationAreaStatuses, 'incidents', 'concentrationAreaStatuses');

    const fetchList = () => {
        dispatch(actions.loadInfrastructureSidebar({
            page: params.page,
            limit: params.limit,
            ...filter,
        }));
    };

    const handleScrollUpdate = (values) => {
        const last_page = sidebar?.meta?.last_page || 0;
        if (
            values.top > 0.98
            && sidebarLoading === false
            && params.page < last_page
        ) {
            setParams({
                ...params,
                page: params.page + 1
            });
        }
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            setParams(old => ({
                ...old,
                page: 1,
            }));
        }
        fetchList();
    }, [params, filters]);

    const renderList = (dataList) => (
        <>
            {dataList.map((item) => {
                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        iconComponent={<Icon color="primary" />}
                        icon={createIcon(getColor(item))}
                        // icon={iconCircle(getColor(item),createIcon(item))}
                        buttons={{
                            edit: {
                                title: buttons.EDIT,
                                icon: <i className="fas fa-pen" />,
                                onClick: () => {
                                    // редактирование
                                    // dispatch(actions.setEditForm(item));
                                    providerForm.showEditForm(item);
                                }
                            },
                            delete: {
                                title: buttons.DELETE,
                                icon: <i className="fas fa-trash-alt" />,
                                onClick: () => {
                                    // удаление
                                    // dispatch(actions.setDeleteForm(item.id));
                                    providerForm.showConfirmDelete(item.id);
                                }
                            }
                        }}
                        onClick={() => {
                            dispatch(actions.setActivePolygonInfrastructure(item));
                        }}
                    >
                        <Item {...item} types={types} statuses={statuses} />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={() => setParams(old => ({ ...old, page: 1 }))}
                onSetFilter={(filter) => dispatch(actions.setFilterPolygonInfrastructure(filter))}
                onClearFilter={() => dispatch(actions.setFilterPolygonInfrastructure())}
                content={({ data, onChange }) => <FilterForm data={data} onChange={onChange} />}
                layer={config.slug}
            />

            {sidebarLoading && sidebar?.data?.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <>
                    <Scrollbars
                        onUpdate={handleScrollUpdate}
                        renderTrackHorizontal={(props) => (
                            <div {...props} className="track-horizontal" />
                        )}
                    >
                        <div className="layers-sidebar__layer">
                            <SidebarTitle
                                title={config.name}
                                list={sidebar}
                            />
                            {sidebar?.data?.length === 0
                                ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                                : renderList(sidebar?.data)}
                        </div>
                        {sidebarLoading && <Loading className="center" />}
                    </Scrollbars>
                </>
            )}

            {providerForm.renderComponent()}
        </div>
    );
};

export default SideBar;