import { all, put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import api from './api.methods';
import * as types from './types';
import * as actions from './actions';
import { setValidation } from '../Validation/actions';
import { showMessage } from '../Message/actions';
import messagetypes from '../Message/messagetypes';
import messages from '../../helpers/constants/messages';
import { messageHelper } from '../../helpers/messageHelper';

function* loadCompaniesSaga({ payload, callback }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingCompanies(true));
    const companies = yield call(api.getCompanies, page, limit, query);
    if (companies) {
        callback 
            ? callback(companies?.data)
            : yield put(actions.loadedCompanies(companies));
    }
    yield put(actions.loadingCompanies(false));
}

function* loadCompaniesForAllRolesSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingCompanies(true));
    const companies = yield call(api.getCompaniesForAllRoles, page, limit, query);
    if (companies) {
        yield put(actions.loadedCompanies(companies));
    }
    yield put(actions.loadingCompanies(false));
}

function* getStandardizedCompaniesListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingCompanies(true));
    const companies = yield call(api.getStandardizedCompanies, page, limit, query);
    if (companies) {
        yield put(actions.gotStandardizedCompaniesList(companies));
    }
    yield put(actions.loadingCompanies(false));
}

function* createCompanySaga({ payload, callback }) {
    yield put(actions.loadingCompanies(true));
    const response = yield call(api.createCompany, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        callback && callback();
    }
    yield put(actions.loadingCompanies(false));
}

function* editCompanySaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingCompanies(true));
    const response = yield call(api.editCompany, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        callback && callback();
    }
    yield put(actions.loadingCompanies(false));
}

function* deleteCompanySaga({ payload, callback }) {
    const response = yield call(api.deleteCompany, payload);
    if (response?.success) {
        callback && callback();
    }
}

function* loadCurrentCompanySaga({ payload: id, callback }) {
    const currentCompany = yield call(api.getCurrentCompany, id);
    if (currentCompany) {
        if (callback) {
            callback(currentCompany.data);
        } else {
            yield put(actions.loadedCurrentCompany(currentCompany.data));
        }
    }
}

function* loadOrganizationSaga ({ payload }) {
    const { ids, filter } = payload;

    const id_list = Array.isArray(ids) ? ids : [ids];
    yield put(actions.loadingOrganization(true));
    const response = yield call(api.getCompanies, 1, 25, { id_list, ...filter, withDeleted: 1 });
    if (response) {
        const companies = response.data?.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
        yield put(actions.loadedOrganization(companies));
    }
    yield put(actions.loadingOrganization(false));
}

function* loadCurrentFiliationListSaga ({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingCurrentFiliationList(true));
    const response = yield call(api.getFiliationList, page, limit, data);
    if (response) {
        yield put(actions.loadedCurrentFiliationList({ [data?.organization_id]: response?.data }));
    }
    yield put(actions.loadingCurrentFiliationList(false));
}

function* createFiliationSaga ({ payload, callback }) {
    const response = yield call(api.createFiliation, payload);
    if (response) {
        callback();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

function* editFiliationSaga ({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editFiliation, id, data);
    if (response) {
        callback();
        yield put(showMessage(messagetypes.success, messages.EDIT_SUCCESS));
    }
}

function* deleteFiliationSaga ({ payload, callback }) {
    const response = yield call(api.deleteFiliation, payload);
    if (response) {
        callback();
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
    }
}



function* downloadOrganizationsReportSaga({ payload = {} }) {
    const response = yield call(api.getOrganizationsReport, payload);

    if (response) {
        yield messageHelper(response);
    };
}

function* loadCompanyRoleListSaga ({ payload }) {
    yield put(actions.loadingCompanyRoleList(true));
    const response = yield call(api.getCompanyRoleList, payload);
    if (response) {
        yield put(actions.loadedCompanyRoleList(response));
    }
    yield put(actions.loadingCompanyRoleList(false));
}

function* createCompanyRoleSaga ({ payload, callback }) {
    const response = yield call(api.createCompanyRole, payload);
    if (response) {
        callback();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

function* editCompanyRoleSaga ({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editCompanyRole, id, data);
    if (response) {
        callback();
        yield put(showMessage(messagetypes.success, messages.EDIT_SUCCESS));
    }
}

function* deleteCompanyRoleSaga ({ payload, callback }) {
    const response = yield call(api.deleteCompanyRole, payload);
    if (response) {
        callback();
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
    }
}

function* createRolesContractsSaga ({ payload, callback }) {
    const response = yield call(api.createRolesContracts, payload);
    if (response) {
        callback();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

function* changeRolesContractsSaga ({ payload, callback }) {
    const response = yield call(api.changeRolesContracts, payload);
    if (response) {
        callback();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

function* deleteRolesContractsSaga ({ payload, callback }) {
    const response = yield call(api.deleteRolesContracts, payload);
    if (response) {
        callback();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

function* loadShowCaseSaga() {
    yield put(actions.loadingShowcase(true));
    const response = yield call(api.loadShowCase);
    if (response?.success) {
        yield put(actions.loadedShowcase(response?.data || []));
    }
    yield put(actions.loadingShowcase(false));
}

export default function* saga() {
    yield all([
        takeEvery(types.LOAD_COMPANIES, loadCompaniesSaga),
        takeLatest(types.LOAD_COMPANIES_FOR_ALL_ROLES, loadCompaniesForAllRolesSaga),
        takeLatest(types.GET_STANDARDIZED_COMPANIES_LIST, getStandardizedCompaniesListSaga),
        takeLatest(types.CREATE_COMPANY, createCompanySaga),
        takeLatest(types.EDIT_COMPANY, editCompanySaga),
        takeLatest(types.DELETE_COMPANY, deleteCompanySaga),
        takeEvery(types.LOAD_CURRENT_COMPANY, loadCurrentCompanySaga),
        takeLatest(types.LOAD_ORGANIZATION, loadOrganizationSaga),
        takeLatest(types.DOWNLOAD_ORGANIZATIONS_REPORT, downloadOrganizationsReportSaga),
        takeLatest(types.LOAD_CURRENT_FILIATION_LIST, loadCurrentFiliationListSaga),
        takeLatest(types.DELETE_FILIATION, deleteFiliationSaga),
        takeLatest(types.EDIT_FILIATION, editFiliationSaga),
        takeLatest(types.CREATE_FILIATION, createFiliationSaga),
        takeLatest(types.LOAD_COMPANY_ROLE_LIST, loadCompanyRoleListSaga),
        takeLatest(types.CREATE_COMPANY_ROLE, createCompanyRoleSaga),
        takeLatest(types.EDIT_COMPANY_ROLE, editCompanyRoleSaga),
        takeLatest(types.DELETE_COMPANY_ROLE, deleteCompanyRoleSaga),
        takeLatest(types.CREATE_ROLES_CONTRACTS, createRolesContractsSaga),
        takeLatest(types.CHANGE_ROLES_CONTRACTS, changeRolesContractsSaga),
        takeLatest(types.DELETE_ROLES_CONTRACTS, deleteRolesContractsSaga),
        takeLatest(types.LOAD_SHOWCASE, loadShowCaseSaga),
    ]);
}
