import methods from '../../../redux/WS/api.methods';

// токен для соединения
export async function getWsToken() {
    const wsToken = await methods.getWsToken();
    return wsToken?.data?.token;
};

// токен для канала
export async function getWsTokenSubscribe(ctx) {
    const wsToken = await methods.getWsTokenSubscribe(ctx.channel);
    return wsToken?.data?.token;
};
