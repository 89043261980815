import { Grid } from '@mui/material';

const Template = ({ dashboards, columns }) => {
    const inner = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid black',
        width: '100%',
        aspectRatio: `3/${columns}`,
        textAlign: 'center',
        fontSize: 14,
        padding: 4,
        overflowWrap: 'anywhere'
        
    };

    return (
        <Grid container spacing={1}>
            {dashboards?.map((item) => (
                <Grid item xs={12/columns} key={item.id}>
                    <div style={inner}>
                        <p>{item.name}</p>
                    </div>
                </Grid>
                
            ))}
        </Grid>
    );
};

export default Template;