import OutputSettings from '../../../../common/OutputSettings';

const MeteoOutputSettings = () => {
    return (
        <OutputSettings
            title="Настройки вывода параметров Метео"
            storeName="meteo"
        />
    );
};

export default MeteoOutputSettings;
