import * as types from './types';

const initialState = {
    list: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loading: false,

    statuses: [],
    loadingStatuses: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LIST_LOADED: {
            return {
                ...state,
                list: payload,
            };
        }

        case types.LIST_LOADING : {
            return {
                ...state,
                loading: payload
            };
        }

        case types.LOADED_STATUSES: {
            return {
                ...state,
                statuses: payload,
            };
        }

        case types.LOADING_STATUSES : {
            return {
                ...state,
                loadingStatuses: payload
            };
        }

        default:
            return state;
    }
}
