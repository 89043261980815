import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

import DateRange from 'components/common/Dates/DateRange';
import SelectRoutesRW from 'components/common/Autocomplete/RoadWorks/RoutesRW';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SelectOrdersRW from 'components/common/Autocomplete/RoadWorks/Orders';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filters = ({
    setParams,
    initialState,
    currentOrder,
    tab,
}) => {
    const [isDateError, setIsDateError] = useState(false);
    const [formValues, setFormValues] = useState(initialState);
    const handleChange = (value, name) => {
        setFormValues({ ...formValues, [name]: value });
    };

    useEffect(() => {
        if (initialState.order_id) {
            setFormValues(initialState);
        }
    }, [initialState]);

    const onReset = (needRefresh) => {
        if (needRefresh) {
            setParams(initialState);
        }
        setFormValues(initialState);
    };

    const updateFilter = (filters) => {
        setFormValues({
            ...initialState,
            ...filters,
        });

        if (Object.keys(filters).length > 0) {
            setParams({
                ...initialState,
                ...filters
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={onReset}
            onSearch={() => setParams(formValues)}
            filter={formValues}
            filterException={['start_date_at', 'end_date_at']}
            countException={tab ? ['order_id', 'order'] : ['order']} // поля которые не считаем
            disabled={isDateError}
            setUserFilter={updateFilter}
            tab={tab}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={formValues.start_date_at}
                    valueEndDate={formValues.end_date_at}
                    handleDate={(value) => handleChange(value, 'start_date_at')}
                    handleEndDate={(value) => handleChange(value, 'end_date_at')}
                    dateOnly
                    isDisabledHandler={(error) => setIsDateError(error)}
                />

                <SelectRoutesRW
                    selected={formValues.route_ids}
                    onChange={(value) => handleChange(value, 'route_ids')}
                    multiple
                />

                <TextField
                    value={formValues.id}
                    onChange={({ target : { value, name } }) => handleChange(value, name)}
                    name="id"
                    label="ID наряда"
                    variant="outlined"
                    size="small"
                    type="number"
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <SelectCompany
                    selected={formValues.organizations || []}
                    onChange={(value) => handleChange(value, 'organizations')}
                    multiple
                    sortedList={currentOrder ? currentOrder.organizations : null}
                />
                <TextField
                    label="ГРЗ"
                    variant="outlined"
                    size="small"
                    name="grz"
                    value={formValues.grz}
                    onChange={(e) => handleChange(e.target.value, 'grz')}
                />
                {!currentOrder && !tab
                    && <SelectOrdersRW
                        selected={formValues.order || {}}
                        onChange={(value) => setFormValues({ ...formValues, order: value || {}, order_id: value.id ?? '' })}
                    />
                }
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filters;
