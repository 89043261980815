import React from 'react';
import renderAddress from '../../../../../helpers/renderAddress';
import { getHumanDate } from '../../../../../helpers/date.config';


const Item = (props) => {
    const {
        address = {},
        name,
        source,
        created_at,
        start_at,
        end_at
    } = props;

    return (
        <>
            <div>
                <strong>Название:</strong> {name}
            </div>
            <div>
                <strong>Тип:</strong> {source}
            </div>
            {address && (
                <div>
                    <strong>Адрес дислокации:</strong> {renderAddress(address)}
                </div>
            )}

            {created_at && (
                <div>
                    <strong>Дата создания:</strong> {getHumanDate(created_at)}
                </div>
            )}

            {start_at && (
                <div>
                    <strong>Дата начала:</strong> {getHumanDate(start_at)}
                </div>
            )}

            {end_at && (
                <div>
                    <strong>Дата конца:</strong> {getHumanDate(end_at)}
                </div>
            )}
        </>
    );
};

export default Item;
