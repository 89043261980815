import WorkRequests from '../../pages/Dictionaries/WorkRequests';

const routes = [
    {
        path: '/dictionaries/work-requests',
        component: WorkRequests,
        exact: true,
    }
];

export default routes;