import React from 'react';
import { useState, useEffect } from 'react';
import PageLayout from '../../../layout/PageLayout';
import { Filter } from './Filter';
import { RatingComponent } from '../../../common/RatingComponent';
import styles from './mobile-app.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { loadVoteStatistics } from '../../../../redux/Votes/actions';
import messages from '../../../../helpers/constants/messages';
import Loading from '../../../common/Loading';
import ButtonsComponent from './ButtonsComponent';

export const MobileApp = () => {
    const dispatch = useDispatch();

    const { statistics, loading } = useSelector(({ votes }) => votes);

    const [ params, setParams ] = useState({});

    useEffect(() => {
        dispatch(loadVoteStatistics(params));
    }, [dispatch, params]);

    const renderContent = () => {
        return (<>
            {loading && <Loading circular={true} />}
            {statistics.length > 0
                ? (statistics.map((stata, index) => 
                    (<div className={styles.container} key={index} >
                        <div className={styles.wrap} >
                            <div className={styles.ratingBlock}>
                                {stata?.data.numeric.map(({ title, value }) =>
                                    <RatingComponent
                                        text={title}
                                        value={value}
                                        key={title}
                                    />
                                )}
                            </div>
                            <div className={styles.ratingBlock}>
                                {stata?.data.text.map(({ title, data }) =>
                                    <ButtonsComponent
                                        title={title}
                                        data={data}
                                        key={title}
                                    />
                                )}
                            </div>
                        </div>
                        
                    </div>))
                )
                : (!loading && <div style={{ marginTop: 20 }}>{messages.DATA_IS_NOT_FOUND}</div>)}
            
        </>);
    };

    return (
        <PageLayout
            header="Мобильное приложение - статистика"
            filters={<Filter setParams={setParams} />}
            content={renderContent}
            customStyles={{ backgroundColor: '#f5f5f5', overflowY: 'auto' }}
        />
    );
};
