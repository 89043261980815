import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { saveStatus } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import messages from 'helpers/constants/messages';
import renderAddress from 'helpers/renderAddress';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import CircleStatus from 'components/common/CircleStatus';

import styles from '../vfcomplex.module.scss';

import CertificateIcon from './CertificateIcon';


const Item = (props) => {
    const {
        item,
        // status,
        complexStatusesObj,
        selectedItem,
        onClickItem,
        currentComplex,
        focus
    } = props;

    const saved = useSelector(dorisControlSelectors.saved);

    const dispatch = useDispatch();

    useEffect(() => {
        if (saved) {
            dispatch(saveStatus());
        }}, [saved, dispatch]);

    const statusRender = () => (item?.status
        ? <Tooltip title={complexStatusesObj[item?.status]?.name}>
            <div style={{ backgroundColor: complexStatusesObj[item?.status]?.color, width: 20, height: 20, borderRadius: '50%' }}></div>
        </Tooltip>
        : <Tooltip title={complexStatusesObj[5]?.name}>
            <div style={{ display: 'inline-block', margin: '8px 8px 0 0', backgroundColor: complexStatusesObj[5]?.color, width: 20, height: 20, borderRadius: '50%' }}></div>
        </Tooltip>
    );

    const buttonsRender = () => (<>
        <IconButton size="small" onClick={onClickItem}>
            {selectedItem
                ? <ArrowBackIosIcon fontSize="inherit"/>
                : <ArrowForwardIosIcon fontSize="inherit"/>
            }
        </IconButton>
    </>);

    return (
        <>
            {currentComplex
                ? <LSContentItem
                    needFocus={focus === item?.id ? true : false}
                    onClick={onClickItem}
                    style={{
                        cursor: 'pointer',
                        backgroundColor: selectedItem ? '#0000001f' : ''
                    }}
                >
                    <LSContentColumn>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 16
                        }}>
                            <div>
                                <div>
                                    <div style={{ display: 'inline-block', marginRight: 8, marginBottom: '-0.25rem' }}>
                                        {item?.status
                                            ? <CircleStatus title={complexStatusesObj[item?.status]?.name} color={complexStatusesObj[item?.status]?.color} />
                                            : <CircleStatus title={complexStatusesObj[5]?.name} color={complexStatusesObj[5]?.color} />
                                        }
                                    </div>
                                    {item?.name || messages.INFO_IS_NOT_FOUND}
                                </div>
                                <div>
                                    <div className={styles.iconWrapper}>
                                        <CertificateIcon complex={item}/>
                                    </div>
                                    <b>Серийный №: </b><span>{item?.serial_number}</span>
                                </div>
                            </div>

                            <div className="flex-center">
                                {buttonsRender()}
                            </div>
                        </div>

                    </LSContentColumn>
                </LSContentItem>
                : <LSContentItem needFocus={focus === item?.id ? true : false} onClick={onClickItem} style={{ cursor: 'pointer' }}>
                    <LSContentColumn align="center">
                        <div style={{ display: 'inline-block', marginRight: 8, marginBottom: '-4px' }}>
                            {item?.status
                                ? <CircleStatus title={complexStatusesObj[item?.status]?.name} color={complexStatusesObj[item?.status]?.color} />
                                : <CircleStatus title={complexStatusesObj[5]?.name} color={complexStatusesObj[5]?.color} />
                            }
                        </div>
                    </LSContentColumn>
                    <LSContentColumn>
                        <div className="flex-center">
                            <CertificateIcon complex={item}/>
                        </div>
                    </LSContentColumn>

                    <LSContentColumn>
                        {item?.name || messages.NO_DATA}
                    </LSContentColumn>

                    <LSContentColumn>
                        {item?.serial_number || messages.NO_DATA}
                    </LSContentColumn>

                    <LSContentColumn>
                        {item?.address_text || renderAddress(item?.address, [
                            'area',
                            'city_name',
                            'district',
                            'street',
                            'house'
                        ]) || messages.NO_DATA}
                    </LSContentColumn>

                    <LSContentColumn isActions >
                        {buttonsRender()}
                    </LSContentColumn>
                </LSContentItem>
            }
        </>
    );
};

export default Item;
