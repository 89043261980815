import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { reactSelectors } from 'modules/React';
import { loadIncidents } from 'modules/React/redux/actions';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import {
    LSContainer,
    LSContentColumn,
    LSContentItem
} from 'components/common/List';

import type { Meta } from 'types/pagination';

interface Incident {
    id: number
    name?: string
}

const Incidents = () => {
    const dispatch = useDispatch();

    const data: Incident[] = useSelector(reactSelectors.incidentsList);
    const meta: Meta = useSelector(reactSelectors.incidentsMeta);
    const loading = useSelector(reactSelectors.loadingIncidents);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    useEffect(() => {
        dispatch(loadIncidents(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    const renderContent = () => {
        return (
            data?.length > 0
                ? <LSContainer headers={[{ title: titles.NAME }]}>
                    {data.map((item) => (
                        <LSContentItem key={item?.id}>
                            <LSContentColumn>
                                {item?.name || messages.NO_VALUE}
                            </LSContentColumn>
                        </LSContentItem>
                    ))}
                </LSContainer>
                : (!loading && <NoData/>)
        );
    };

    return (
        <PageLayout
            header="Инциденты"
            informPanelProps={{
                total: meta?.total || 0,
            }}
            loading={loading}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setParams((prev) => ({ page, data: prev.data })),
                list: meta,
                limit,
                setLimit,
            }}
        />
    );
};

export default Incidents;
