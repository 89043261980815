import { useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    TextField,
    InputAdornment, 
    IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import RenderArray from './RenderArray';
import { useValidation } from '../../../../../../../../../helpers/hooks';

const RenderTransferFields = ({ element, currentData, handleChange }) => {
    const validation = useValidation();
    const [showPassword, setShowPassword] = useState(false);
    
    if (element?.type === 'array' && element?.content?.length > 0) {
        return (
            <RenderArray 
                current={currentData?.[element?.field] || []}
                element={element}
                handleChange={handleChange}
            />
        );
        
    } else if (element?.type === 'boolean') {
        return (
            <FormControl className="block" key={element?.field}>
                <FormControlLabel
                    size="small"
                    control={
                        <Checkbox
                            checked={currentData?.[element?.field] || false}
                            onChange={(e) => handleChange(e.target.name, e.target.checked)}
                            name={element?.field}
                            color="primary"
                            style={{ pointerEvents: 'auto' }}
                        />
                    }
                    label={element?.name}
                />
            </FormControl>
        );
    } else {
        return (
            <FormControl
                className="block"
                variant="outlined"
                required={element?.require ?? false}
                key={element?.field}
            >
                <TextField
                    label={element?.name}
                    size="small"
                    value={currentData?.[element?.field] || ''}
                    variant="outlined"
                    name={element?.field}
                    type={showPassword ? 'text' : element?.type}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    required={element?.require}
                    error={(!currentData?.[element?.field] && element?.require) || validation.isKey(`data.${[element?.field]}`)}
                    helperText={validation.get(`data.${[element?.field]}`)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                { element?.type === 'password' && (
                                    <IconButton size="small" onClick={ () => setShowPassword(!showPassword) }>
                                        { showPassword ? <Visibility/> : <VisibilityOff/> }
                                    </IconButton>)
                                }
                            </InputAdornment>
                        )
                    }}
                />
            </FormControl>
        );
    }
};

export default RenderTransferFields;