import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiCompaniesMethods = {

    getCompanies: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getCompanies(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    getCompaniesForAllRoles: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getCompaniesForAllRoles(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    getStandardizedCompanies: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getStandardizedCompanies(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    createCompany: async (data) => {
        const response = await instance.post(apiUrls.createCompany(), data);
        return response?.data;
    },

    editCompany: async (id, data) => {
        const response = await instance.put(apiUrls.editCompany(id), data);
        return response?.data;
    },

    deleteCompany: async (id) => {
        const response = await instance.delete(apiUrls.deleteCompany(id));
        return response?.data;
    },

    getCurrentCompany: async (id) => {
        const response = await instance.get(apiUrls.getCurrentCompany(id));
        return response?.data;
    },

    getOrganization: async (id) => {
        const response = await instance.get(apiUrls.getOrganization(id));
        return response?.data;
    },

    getOrganizationsReport: async (params = {}) => {
        const response = await instance.get(apiUrls.getOrganizationsReport(), { params });
        return response?.data;
    },

    getFiliationList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getFiliationList(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    createFiliation: async (data) => {
        const response = await instance.post(apiUrls.createFiliationList(), data);
        return response?.data;
    },

    editFiliation: async (id, data) => {
        const response = await instance.put(apiUrls.editFiliation(id), data);
        return response?.data;
    },

    deleteFiliation: async (id) => {
        const response = await instance.delete(apiUrls.editFiliation(id));
        return response?.data;
    },

    getCompanyRoleList: async (params) => {
        const response = await instance.get(apiUrls.getCompanyRoleList(), { params });
        return response?.data;
    },

    createCompanyRole: async (data) => {
        const response = await instance.post(apiUrls.createCompanyRole(), data);
        return response?.data;
    },

    editCompanyRole: async (id, data) => {
        const response = await instance.put(apiUrls.editCompanyRole(id), data);
        return response?.data;
    },

    deleteCompanyRole: async (id) => {
        const response = await instance.delete(apiUrls.deleteCompanyRole(id));
        return response?.data;
    },

    createRolesContracts: async (data) => {
        const response = await instance.post(apiUrls.createRolesContracts(), data);
        return response?.data;
    },
    
    changeRolesContracts: async (data) => {
        const response = await instance.put(apiUrls.changeRolesContracts(), data);
        return response?.data;
    },

    deleteRolesContracts: async (params) => {
        const response = await instance.delete(apiUrls.deleteRolesContracts(), { params });
        return response?.data;
    },

    loadShowCase: async () => {
        const response = await instance.post(apiUrls.loadShowCase());
        return response?.data;
    },
};

export default ApiCompaniesMethods;
