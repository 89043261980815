import { useEffect, useRef } from 'react';

import { dateNow, getDateWithDuration } from '../../date.config';

const useDorisCompare = (callback, keyInEvent = 'grz', timeout = 3000) => {
    const instance = useRef({});
    const timerIds = useRef(null);
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const createInterval = () => {
        if (!timerIds.current) {
            timerIds.current = setInterval(() => {
                const data = Object.values(instance.current);
                if (data.length >= 25) {
                    instance.current = {};
                    callbackRef.current && callbackRef.current(data);
                }
            }, timeout);
        }
    };

    const removeInterval = () => {
        if (timerIds.current) {
            clearInterval(timerIds.current);
            timerIds.current = null;
            instance.current = {};
        }
    };

    useEffect(() => {
        createInterval();

        return () => {
            removeInterval();
        };
    }, []);

    const checkDate = (created_at) => {
        // за последние сутки
        const isLastDay = new Date(getDateWithDuration({ days: -1 }, dateNow()));
        const current = new Date(created_at);

        return isLastDay < current;
    };

    return (events) => {
        if (Object.keys(instance.current).length < 30) {
            events.forEach((item) => {
                const key = item[keyInEvent];
                if (!instance.current[key] && checkDate(item?.created_at)) {
                    instance.current[key] = item;
                }
            });
        }
    };
};

export default useDorisCompare;

/*
import {
    addActiveLayer,
    setSelectedLayer,
} from '../../Menu/actions';
import {
    loadedDorisCameraEvents,
    setActive as setActiveVF,
    setActiveEvent,
    setWs as setWsDoris,
} from '../actions';

let cameraEventsData = [];
let cameraEventsAction = null;

setInterval(() => {
    if (cameraEventsData.length >= 25) {
        cameraEventsAction && cameraEventsAction(cameraEventsData);
    }
}, 3000);

const wsConfig = ({
    dispatch,
    history,
    onChangeRead,
    onRefreshList,
}) => {
    cameraEventsAction = (data) => {
        data.shift();
        dispatch(setWsDoris(data));
        dispatch(loadedDorisCameraEvents(data));
    };

    const wsCallback = (data) => {
        history.push({
            pathname: '/map',
            search: 'layers[]=camera',
        });

        dispatch(addActiveLayer('camera'));
        setTimeout(() => {
            dispatch(setSelectedLayer('camera'));
        }, 500);

        dispatch(setActiveVF({
            ...data,
            // tab: dorisControlTab,
            id: data.complex_id
        }));

        dispatch(setActiveEvent(data));
    };

    const wsListen = (events) => {
        const addEvents = events?.reduce((res, item) => {
            if (
                ![
                    ...cameraEventsData,
                    ...res
                ].find(e => e.grz === item.grz)
            ) {
                return [
                    ...res,
                    item,
                ];
            }
            return res;
        }, []);

        if (cameraEventsData.length < 30 && addEvents.length > 0) {
            cameraEventsData = [
                ...cameraEventsData,
                ...addEvents
            ];
        }
    };

    return {
        'doris-control_camera_events': {
            listen: wsListen,
            callback: wsCallback,
        },
    };
};

export default wsConfig;
 */
