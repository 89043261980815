import L from 'leaflet';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import iconSvg from './icons/iconSvg';
import {
    fullDateTimeWithTimeZone,
} from '../../../../helpers/date.config';

export const getColor = (item) => {
    // 1: "Предочаг", 2: "Очаг"
    return item?.type === 2 ? '#ff0000' : '#454545';
};

export const createIcon = (item) => {
    // внутр цвет (очаг / предочаг)
    const inner = getColor(item);
    // внешн цвет (подтвержден / не подтвержден) confirmed_at
    const outer = item?.status === 3 ? '#00cb00' : '#ffe81c';

    return iconSvg({ inner, outer });
};

// получить иконку маркера
export const createIconMarker = (item) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [0, -30],
        html: `
            ${createIcon(item)}
        `,
    });
};

export const getFilter = (filter) => {
    const filters = {
        ...filter,
        start_date: filter.start_date ? fullDateTimeWithTimeZone(filter.start_date) : null,
        end_date: filter.end_date ? fullDateTimeWithTimeZone(filter.end_date) : null,
        status: filter?.status || null,
        type: filter?.type || null
    };

    return removeEmptyFields(filters, false);
};

export const getItemToSave = (item) => {
    const ret = { ...item };
    if (
        !ret.hasOwnProperty('dtp_ids')
        || ret?.dtp_ids?.length === 0
    ) ret.dtp_ids = ret.dtp_list.map(i => i.id) ?? [];

    // задать имя из id дтп
    if (ret.name?.length === 0 && ret?.dtp_ids?.length > 0) {
        const name = [`${ret?.dtp_ids?.[0]}`];
        if (ret?.dtp_ids.length > 1) name.push(`${ret?.dtp_ids?.[ret?.dtp_ids.length - 1]}`);
        ret.name = name.join(' - ');
    }

    return ret;
};