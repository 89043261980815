const graphTypes = {
    LINE: 1, // Линия
    PIE: 2, // Круговой
    VERTICAL_BAR: 3, // рафик бар(вертикальный)
    HORIZONTAL_BAR: 4, // График бар(горизонтальный)
    TABLE: 5, // Таблица
    GRID: 6, // Грид
    DOUGHNUT: 7, // пончик
    FILTER: 8, // фильтр
};
export default graphTypes;