import React, { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import ColorPicker from '../../ColorPicker';

interface ColorFieldProps {
    color: string
    onChange: (value: string) => void
}

const ColorField = ({ color, onChange }: ColorFieldProps) => {
    const [isOpenColorPicker, setIsOpenColorPiker] = useState(false);

    return (
        <div>
            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <IconButton
                    onClick={() => setIsOpenColorPiker(true)}
                    sx={{ backgroundColor: color, width: 40, height: 40 }}
                />

                <Typography>
                    {color}
                </Typography>
            </div>

            {isOpenColorPicker && (
                <ColorPicker
                    color={color}
                    open={isOpenColorPicker}
                    onClose={() => setIsOpenColorPiker(false)}
                    onChangeColor={onChange}
                />
            )}
        </div>
    );
};

export default ColorField;
