import { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { TextField } from '@mui/material';

import { transportRoadSelectors } from 'redux/TransportRoad';
import {
    loadBrigades,
} from 'redux/TransportRoad/actions';
import { loadRoadWorkOrders } from 'redux/RoadWorks/actions';
import titles from 'helpers/constants/titles';
import {
    dateWithDashYYYYMMDD,
    getConfigForMinMaxDate,
    getEndOf,
    getStartOf,
    getTimeWithoutSeconds
} from 'helpers/date.config';
import {
    // useCheckUserOrganizations,
    useValidation,
} from 'helpers/hooks';
import useOrganizationsByOrderId from 'helpers/hooks/Transport/useOrganizationsByOrderId';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SelectOrdersRW from 'components/common/Autocomplete/RoadWorks/Orders';
import SelectRoutesRW from 'components/common/Autocomplete/RoadWorks/RoutesRW';
import SelectVehicleRW from 'components/common/Autocomplete/RoadWorks/VehicleRW';
import SelectBrigades from 'components/common/Autocomplete/Transport/Brigades';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';

const AddEdit = ({
    data = {},
    isNew = false,
    onClose = () => {},
    isOpen,
    onSave,
    currentOrder = null,
}) => {
    const validation = useValidation();
    const dispatch = useDispatch();

    // список организаций по заявке
    const orderOrganizations = useOrganizationsByOrderId(currentOrder?.id);

    const [formValues, setFormValues] = useState({
        name: data?.name || '',
        date_at: data?.date_at ? new Date(data?.date_at) : '',
        start_at: data?.start_at || getStartOf(),
        end_at: data?.end_at || getEndOf(),
        vehicle: data?.vehicle || {},
        brigades: data?.brigades || [],
        organization: data?.organization || {},
        route: data?.route || {},
        count: data?.count || 1,
        order: data?.order || {},
        order_id: data?.order_id || null
    });

    const loading = useSelector(transportRoadSelectors.loadingWorkorders);

    useEffect(() => {
        // есть есть айди заявки, загружаем ее полностью, чтобы отобразить в поле ввода
        if (data.order_id && !data.order) {
            dispatch(loadRoadWorkOrders(1, 1, { ids: [data.order_id] },
                (orders = []) => setFormValues((prev) => ({
                    ...prev,
                    order: orders[0],
                }))
            ));
        }
    }, [data, dispatch]);


    const onChange = (name, value) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    // const hasEachBrigadeShift = !!formValues.brigades.shift;

    const areAllFieldsFilled = formValues.organization?.id !== null && formValues.organization?.id !== undefined
        && !!formValues.vehicle?.id;
        // && hasEachBrigadeShift;

    const submit = () => {
        const result = {
            ...formValues,
            date_at: dateWithDashYYYYMMDD(formValues.date_at),
            start_at: getTimeWithoutSeconds(formValues.start_at),
            end_at: getTimeWithoutSeconds(formValues.end_at),
            organization_id: formValues.organization?.id,
            vehicle_id: formValues.vehicle?.id,
            brigades: formValues.brigades.length > 0 ? formValues.brigades : null,
            route_id: formValues.route?.id,
            name: formValues.name || formValues?.route.name,
            order_id: formValues.order.id
        };
        onSave(result);
    };

    return (
        <Modal
            title={isNew ? titles.ADD_WORKORDER : titles.EDIT_WORKORDER}
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: !areAllFieldsFilled,
                        onClick: submit
                    },
                ]}
            />}
        >
            {loading && <Loading linear />}
            <form className="modal__form">
                <div className="block">
                    <TextField
                        className="row__item"
                        value={formValues.name || ''}
                        onChange={(e) => onChange('name',  e.target.value)}
                        variant="outlined"
                        size="small"
                        label={titles.NAME}
                    />
                </div>
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        onChange={(value) => onChange('date_at', value)}
                        label={'Дата выполнения'}
                        value={formValues.date_at}
                        error={validation.isKey('date_at')}
                        helperText={validation.get('date_at')}
                        dateOnly={true}
                        minDate={getConfigForMinMaxDate()}
                        required
                    />
                </div>
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        onChange={(value) => onChange('start_at', value)}
                        label={'Время начала выполнения'}
                        value={formValues.start_at}
                        error={validation.isKey('start_at')}
                        helperText={validation.get('start_at')}
                        timeOnly
                        required
                    />
                </div>
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        onChange={(value) => onChange('end_at', value)}
                        label={'Время окончания выполнения'}
                        value={formValues.end_at}
                        error={validation.isKey('end_at')}
                        helperText={validation.get('end_at')}
                        timeOnly
                        required
                    />
                </div>
                <div className="block">
                    <SelectCompany
                        selected={formValues.organization}
                        onChange={(value) => onChange('organization', value)}
                        helperText={validation.get('organization_id')}
                        error={validation.isKey('organization_id')}
                        required
                        sortedList={isNew ? null : orderOrganizations}
                        // sortedList={currentOrder ? currentOrder?.organizations : null}
                    />
                </div>
                <div className="block">
                    <SelectRoutesRW
                        selected={formValues.route}
                        onChange={(value) => onChange('route', value)}
                        helperText={validation.get('route_id')}
                        error={validation.isKey('route_id')}
                        required
                    />
                </div>
                <div className="block">
                    <TextField
                        className="row__item"
                        value={formValues.count || 1}
                        onChange={(e) => onChange('count', e.target.value)}
                        variant="outlined"
                        size="small"
                        type="number"
                        label={'Планируемое количество проездов КТ'}
                        required
                    />
                </div>
                <div className="block">
                    <SelectOrdersRW
                        selected={formValues.order}
                        onChange={(value) => onChange('order', value)}
                        filter={{
                            organization_ids: [formValues.organization?.id],
                            status: [1,2]
                        }}
                        disabled={!!currentOrder  || !formValues.organization?.id}
                        helperText={!currentOrder && !formValues.organization?.id && 'Выберите сначала организацию'}
                    />
                </div>
                <div className="block">
                    <SelectVehicleRW
                        selected={formValues.vehicle}
                        onChange={(value) => onChange('vehicle', value)}
                        required
                        filter={
                            formValues.organization?.id
                                ? { organization_id: [formValues.organization?.id] }
                                : {}
                        }
                        disabled={formValues.organization?.id === null || formValues.organization?.id === undefined}
                        helperText={!formValues.organization?.id ? 'Выберите сначала Организацию' : ''}
                    />
                </div>
                <div className="block">
                    <SelectBrigades
                        selected={formValues.brigades[0]} // т.к. не multiple но массив
                        loadListAction={loadBrigades}
                        storeName="transportRoad"
                        storeNameProps="brigades"
                        disabled={formValues.organization?.id === null || formValues.organization?.id === undefined}
                        helperText={!areAllFieldsFilled ? 'Выберите сначала Организацию и ТС' : ''}
                        onChange={(value) =>{
                            const brigades = value?.shift ? value : { ...value, shift: 1 };
                            onChange('brigades', [brigades]);
                        }}
                        filter={{ organization_id: formValues.organization?.id }}
                    />
                </div>
            </form>
        </Modal>
    );
};

export default AddEdit;
