import L from 'leaflet';
import { useEffect } from 'react';

// окружность
const Circle = ({
    latlng,
    parent,
    radius,
    color,
    fillOpacity = .2,
}) => {
    useEffect(() => {
        const newCircle = L.circle(latlng, {
            radius,
            color,
            fillOpacity,
        }).addTo(parent);

        return () => {
            parent.removeLayer(newCircle);
            newCircle.remove();
        };
    }, []);

    return null;
};

export default Circle;
