import React, { useState } from 'react';
import { loadTariffsList } from '../../../../../redux/TransportPassenger/actions';
import cn from 'classnames';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { useDispatch } from 'react-redux';
import SelectRoutes from '../../../../common/Autocomplete/PassengerTransport/Routes';
import _ from 'lodash';

const TFilter = ({ typeQuery, setParams }) => {
    const dispatch = useDispatch();

    const [route, setRoute] = useState({});
    const [clicked, setClicked] = useState(false);

    const handleChangeRoute = (value) => {
        setRoute(value);
    };

    const handleResetFilter = () => {
        setRoute({});

        if (clicked) {
            setClicked(false);
            setParams({});
            dispatch(loadTariffsList(1, 25, {}, typeQuery));
        }
    };

    const handleSearch = () => {
        setParams(route.num);

        dispatch(loadTariffsList(1, 25, { num_route: route.num }, typeQuery));

        if (!clicked) {
            setClicked(true);
        }
    };

    return (
        <div className="filter">
            <SelectRoutes
                selected={route}
                onChange={handleChangeRoute}
                filter={{ type_transportation: typeQuery.type_transportation }}
            />
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: handleResetFilter,
                            disabled: _.isEmpty(route)
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: handleSearch,
                            disabled: _.isEmpty(route)
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default TFilter;