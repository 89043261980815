import { useState } from 'react';
import SelectRWGroups from '../RWGroups';
import SelectWorks from '../Works';
import UnitsOfMeausurement from '../UnitsOfMeasurement';

const WorksByGroupSelect = ({
    multiple = false,
    selectedGroup = {},
    selectedWorks = multiple ? [] : {},
    selectedUnits = multiple ? [] : {},
    disabledGroup = false,
    onChange,
    groupError = false,
    workError = false,
    unitsError = false,
    test_id_prefix = ''
}) => {
    const [unitsSortList, setUnitsSortList] = useState([]);
    const changeGroup = (newGroup) => {
        //  если поменяли группу надо сбросить и работу
        onChange({
            group: newGroup,
            work: multiple ? [] : {},
            units: multiple ? [] : {},
        });
    };

    const changeWork = (newWork) => {
        onChange({
            group: selectedGroup,
            work: newWork,
            units: multiple ? [] : {},
        });
        setUnitsSortList(newWork.unit_of_measurement_list);
    };

    const changeUnits = (newUnits) => {
        onChange({
            group: selectedGroup,
            work: selectedWorks,
            units: newUnits
        });
    };

    return (
        <div className="row">
            <div className="row__item">
                <SelectRWGroups
                    selected={selectedGroup || {}}
                    onChange={changeGroup}
                    error={groupError}
                    disabled={disabledGroup}
                    test_id_prefix={`${test_id_prefix}/group`}
                />
            </div>
            <div className="row__item">
                <SelectWorks
                    multiple={multiple}
                    selected={selectedWorks}
                    disabled={!selectedGroup?.id}
                    onChange={changeWork}
                    filter={{ road_work_group_id_list: [selectedGroup?.id] }}
                    error={workError}
                    test_id_prefix={`${test_id_prefix}/work`}
                />
            </div>
            <div className="row__item">
                <UnitsOfMeausurement
                    multiple={multiple}
                    selected={selectedUnits}
                    onChange={changeUnits}
                    filter={selectedWorks}
                    disabled={!selectedWorks?.id}
                    error={unitsError}
                    sortedList={unitsSortList}
                    test_id_prefix={`${test_id_prefix}/units`}
                />
            </div>
        </div>
    );
};

export default WorksByGroupSelect;
