import {
    editSettingsGroup,
    editSettingsList,
    loadSettingsGroup,
} from '../../../../../redux/DorisControl/actions';
import Settings from '../../../../common/Settings';

const DorisControlSettings = () => {
    return (
        <Settings
            title="Настройки – ДОРИС-Контроль"
            storeProp="dorisControl"
            listName="settingsGroup"
            loadingName="loadingSettingsGroup"
            loadSettingsGroupAction={loadSettingsGroup}
            editSettingsListAction={editSettingsList}
            editSettingsGroupAction={editSettingsGroup}
        />
    );
};

export default DorisControlSettings;
