import titles from '../../../../../helpers/constants/titles';
import messages from '../../../../../helpers/constants/messages';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import DateRange from '../../../../common/Dates/DateRange';
import { fullDateTimeWithTimeZone } from '../../../../../helpers/date.config';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';


const RRFilter = ({ statuses, tsTypes, transportTypes, setParams, initialState, params, onSearch }) => {

    const onChange = (e) => {
        const { name, value } = e.target;
        setParams((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleChange = (value, name) => {
        setParams((prev) => ({
            ...prev,
            [name]: fullDateTimeWithTimeZone(value)
        }));
    };

    const resetFilters = (needRefresh) => {
        if (needRefresh) {
            onSearch(initialState);
        }
        setParams(initialState);
    };

    const updateFilter = (filters) => {
        setParams({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0 || Object.keys(params).length > 0) {
            onSearch({
                ...initialState,
                ...filters,
            });
        }
    };

    const isDisabled = !params?.name 
        && !params?.inn
        && !params?.status 
        && !params?.category_id
        && !params?.type_transportation
        && !params?.start_date
        && !params?.end_date;

    return (
        <LayoutFilter
            onResetFilter={resetFilters} 
            onSearch={onSearch} 
            filter={params} 
            setUserFilter={updateFilter}
            disabled={isDisabled}
            filterException={['start_date', 'end_date']}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={params.start_date}
                    valueEndDate={params.end_date}
                    handleDate={(data) => handleChange(data, 'start_date')}
                    handleEndDate={(data) => handleChange(data, 'end_date')}
                    dateOnly
                />
                <TextField
                    value={params.name}
                    onChange={onChange}
                    name="name"
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                />
                <TextField
                    value={params.inn}
                    onChange={onChange}
                    name="inn"
                    label={titles.INN}
                    variant="outlined"
                    size="small"
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <FormControl
                    variant="outlined"
                    size="small"
                >
                    <InputLabel>{titles.STATUS}</InputLabel>
                    <Select
                        label={titles.STATUS}
                        value={params.status}
                        onChange={onChange}
                        name="status"
                    >
                        {(Object.keys(statuses).length > 0
                            ? Object.keys(statuses).map((key) => (
                                <MenuItem key={key} value={key}>
                                    {statuses[key]}
                                </MenuItem>))
                            : (<MenuItem disabled={true}>{messages.NO_DATA}</MenuItem>))}
                    </Select>
                </FormControl>
                <FormControl
                    variant="outlined"
                    size="small"
                >
                    <InputLabel>{titles.TRANSPORT_TYPE}</InputLabel>
                    <Select
                        label={titles.TRANSPORT_TYPE}
                        value={params.category_id}
                        onChange={onChange}
                        name="category_id"
                    >
                        {tsTypes?.length > 0
                            ? tsTypes.map((item) => (
                                <MenuItem key={item.id} value={item.id.toString()}>
                                    {item.name}
                                </MenuItem>))
                            : (<MenuItem disabled={true}>{messages.NO_DATA}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <FormControl
                    variant="outlined"
                    size="small"
                >
                    <InputLabel>{titles.TRANSPORTATION_TYPE}</InputLabel>
                    <Select
                        label={titles.TRANSPORTATION_TYPE}
                        value={params.type_transportation}
                        onChange={onChange}
                        name="type_transportation"
                    >
                        {Object.keys(transportTypes).length > 0
                            ? Object.keys(transportTypes).map((key) => (
                                <MenuItem key={key} value={key}>
                                    {transportTypes[key]}
                                </MenuItem>))
                            : (<MenuItem disabled={true}>{messages.NO_DATA}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </ LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default RRFilter;
