import React, { useContext, useState } from 'react';
import { TextField } from '@mui/material';
import { useValidation } from '../../../../../helpers/hooks';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import EditModal from './EditModal';
import Context from '../../../../../helpers/context';

function Filters({ setParams, params, search }) {
    const validation = useValidation();
    const { permissions } = useContext(Context);

    const [modalOpen, setModalOpen] = useState(false);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setParams({
            ...params,
            [name]: value
        });
        validation.clear();
    };

    const resetSearch = () => {
        setParams({});
        search({});
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') search(params);
    };

    const toggleOpen = (e) => {
        e && e.stopPropagation();
        setModalOpen(!modalOpen);
    };

    return (
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">
                <TextField
                    value={params?.long_name || ''}
                    name="long_name"
                    onChange={handleChange}
                    label="Полное название ЕИ"
                    variant="outlined"
                    size="small"
                    inputProps={{ onKeyDown: handleEnter }}
                    error={!!validation.isKey('long_name')}
                />
                <TextField
                    value={params?.short_name || ''}
                    onChange={handleChange}
                    label="Короткое название ЕИ"
                    name="short_name"
                    variant="outlined"
                    size="small"
                    inputProps={{ onKeyDown: handleEnter }}
                    error={!!validation.isKey('short_name')}
                />
                <div className="filter__wrap__btn" >
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.resetFilter,
                                onClick: resetSearch,
                            },
                            {
                                ...buttonsTypes.search,
                                onClick: () => search(params),
                            }
                        ]}
                    />
                </div>
            </div>
            <div className="filter__wrap__btn" >
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setModalOpen(true),
                            disabled: !permissions?.is_create,
                        }
                    ]}
                />
            </div>
            {modalOpen 
                && <EditModal
                    isNew={true} 
                    toggleOpen={toggleOpen} 
                    open={modalOpen} 
                />
            }
        </div>
    );
}

export default Filters;