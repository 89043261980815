import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { Card } from '@mui/material';
import AppBar from '@mui/material/AppBar';

import { loadContractEntitiesTypes, loadGovernmentContractById } from 'redux/GovernmentContracts/actions';
import { govermentContracts } from 'redux/GovernmentContracts';
import titles from 'helpers/constants/titles';
import { CustomTab, CustomTabs } from 'components/common/Tabs';

import BasicData from './BasicData';
import CommonEntityPage from './CommonEntitiesPage';

const PassportTabs = ({
    id, // id гос контракта
    tab = 'data',
}) => {
    const dispatch = useDispatch();
    const scrollRef = useRef(null);

    const сontractById = useSelector(govermentContracts.governmentContractById);

    const [entityTypes, setEntityTypes] = useState([]);

    const getEntityType = (entity_name) => {
        return entityTypes.find(el => el.key === entity_name);
    };

    const tabPanelProps = (tabName) => ({
        role: 'tabpanel',
        id: `scrollable-force-tabpanel-${tabName}`,
        'aria-labelledby': `scrollable-force-tab-${tabName}`,
        key: tabName,
    });

    const tabProps = (tabName) => ({
        id: `scrollable-force-tab-${tabName}`,
        'aria-controls': `scrollable-force-tabpanel-${tabName}`,
    });

    const [value, setValue] = useState( tab || 'data');

    const scrollTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        scrollTop();
    };

    useEffect(() => {
        if (id) {
            scrollTop();
            setValue('data');
        }
    }, [id]);


    useEffect(() => {
        // есть id - загружаем полный объект комплекса
        if (id) {
            dispatch(loadGovernmentContractById(id));
        }
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(loadContractEntitiesTypes(setEntityTypes));
    }, [dispatch]);

    return (
        <Card variant="elevation" style={{ height: '100%', display: 'flex', flexDirection: 'column'/*, overflowY:'auto'*/ }} >
            <AppBar position="sticky" color="default" style={{ zIndex: 2 }}>
                <CustomTabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    variant="scrollable"
                    aria-label="scrollable force tabs example"
                >
                    <CustomTab
                        icon={<i className="fal fa-info-square"/>}
                        iconPosition="start"
                        value="data"
                        label={titles.BASIC_DATA}
                        {...tabProps('data')}
                    />
                    {сontractById?.additional_entities
                        && Object.keys(сontractById?.additional_entities).map(entity => (
                            <CustomTab
                                key={entity}
                                icon={<i className="fas fa-camera-alt"/>}
                                iconPosition="start"
                                value={entity}
                                label={сontractById?.additional_entities[entity]}
                                {...tabProps(`${entity}`)}
                            />
                        ))

                    }
                </CustomTabs>
            </AppBar>
            <div style={{ height: '100%', display: 'flex' }}>
                <Scrollbars style={{ height: '100%' }} ref={scrollRef} >
                    {value === 'data'
                        && <div {...tabPanelProps('data')}>
                            <BasicData
                                id={id}
                                currentContract={сontractById}
                            />
                        </div>
                    }
                    {сontractById?.additional_entities
                        && Object.keys(сontractById?.additional_entities).map(entity =>
                            (value === entity
                                && <div {...tabPanelProps(entity)}>
                                    <CommonEntityPage
                                        id={id}
                                        currentContract={сontractById}
                                        entityObject={getEntityType(entity)}
                                    />
                                </div>
                            )
                        )

                    }
                </Scrollbars>
            </div>
        </Card>
    );
};

export default PassportTabs;
