import ClearIcon from '@mui/icons-material/Clear';
import { Button, FormHelperText, IconButton, Tooltip } from '@mui/material';
import {
    areIntervalsOverlapping,
} from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    fullDateTimeWithTimeZone,
    getDateWithDuration,
    getEndOf,
    getStartOf
} from '../../../../../../../helpers/date.config';
import { efficiencySelectors } from '../../../../../../../redux/Efficiency';
import * as actions from '../../../../../../../redux/Efficiency/actions';
import DateRange from '../../../../../../common/Dates/DateRange';
import FormButtons, { buttonsTypes } from '../../../../../../common/FormButtons';

const Filter = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const filterEfficiencyDtp = useSelector(efficiencySelectors.filterEfficiencyDtp);
    const loadingEfficiencyDtp = useSelector(efficiencySelectors.loadingEfficiencyDtp);

    const prevMonth = getDateWithDuration({ month: -1 });

    const initialState = {
        dates: [
            {
                start_date: fullDateTimeWithTimeZone(getStartOf('month', prevMonth)),
                end_date: fullDateTimeWithTimeZone(getEndOf('month', prevMonth))
            },
            {
                start_date: fullDateTimeWithTimeZone(getStartOf('month')),
                end_date: fullDateTimeWithTimeZone(getEndOf('day'))
            }
        ]
    };

    const [fields, setFields] = useState(initialState);
    const [isCleared, setIsCleared] = useState(true);

    useEffect(() => {
        const filter = {
            ...initialState,
            ...filterEfficiencyDtp,
        };

        setFields(filter);
        if (Object.keys(filterEfficiencyDtp).length === 0) {
            dispatch(actions.setFilterEfficiencyDtp(filter));
        } else {
            setIsCleared(false);
        }
    }, []);

    const handleChangeDate = (date, key, index) => {
        const newFields = {
            ...fields,
            dates: [
                ...fields?.dates?.slice(0, index),
                {
                    ...fields?.dates?.[index],
                    [key]: fullDateTimeWithTimeZone(date),
                },
                ...fields?.dates?.slice(index + 1),
            ],
        };
        setFields(newFields);
        setIsCleared(false);
    };

    const resetFilters = () => {
        setFields(initialState);
        dispatch(actions.setFilterEfficiencyDtp(initialState));
        setIsCleared(true);
    };
    const handleChange = () => {
        dispatch(actions.setFilterEfficiencyDtp(fields));
    };

    const isDisabled = useMemo(() => {
        return fields
            ?.dates
            ?.reduce((
                res,
                { start_date = '', end_date = '' }
            ) => res || !start_date || !end_date || (start_date && end_date && new Date(start_date) > new Date(end_date))
            , false);
    }, [fields]);

    const start_date = fields?.dates?.[0]?.start_date || null;
    const end_date = fields?.dates?.[0]?.end_date || null;
    // const errorDate = () => !start_date || !end_date || start_date === 'Invalid date' || end_date === 'Invalid date';

    // пересечение периодов
    const compareD = () => {
        if (isDisabled) return false;
        let error = false;
        for (let i = 0; i < fields?.dates?.length - 1; i++) {
            const r1 = {
                start: new Date(fields?.dates[i]?.start_date),
                end: new Date(fields?.dates[i]?.end_date),
            };
            const r2 = {
                start: new Date(fields?.dates[i + 1]?.start_date),
                end: new Date(fields?.dates[i + 1]?.end_date),
            };

            error = error || areIntervalsOverlapping(r1, r2, { inclusive: true });
        }
        return error;
    };

    // const handleLink = () => {
    //     dispatch(setDtpByAreaReportFilter({
    //         filterValues: {
    //             start_date,
    //             end_date,
    //             type_load_list: ['1', '2'],
    //             is_area: false // районы города
    //         },
    //         requestGraph : true, // показать график
    //         requestByArea : false,
    //         returnToEfficiency: true,
    //     }));
    //     // переход
    //     history.push('/reports/dtp-districts');
    // };

    return (
        <div className="filter">
            <div>
                <div className="filter__wrap" style={{ textAlign: 'left', display: 'block', width: 'auto' }}>
                    {fields?.dates?.map((item, index) => {
                        const {
                            start_date,
                            end_date,
                        } = item;

                        return (
                            <div
                                key={index}
                                style={{
                                    marginBottom: '1rem',
                                    display: 'flex'
                                }}
                            >
                                <DateRange
                                    required
                                    valueStartDate={start_date}
                                    valueEndDate={end_date}
                                    handleDate={(date) => handleChangeDate(date, 'start_date', index)}
                                    handleEndDate={(date) => handleChangeDate(date, 'end_date', index)}
                                    dateOnly

                                />
                                {fields?.dates?.length > 1 && (
                                    <IconButton
                                        onClick={() => {
                                            setFields({
                                                ...fields,
                                                dates: [
                                                    ...fields?.dates?.slice(0, index),
                                                    ...fields?.dates?.slice(index + 1),
                                                ],
                                            });
                                        }}
                                    >
                                        <Tooltip title="Удалить">
                                            <ClearIcon />
                                        </Tooltip>
                                    </IconButton>
                                )}

                            </div>
                        );
                    })}
                </div>

                {compareD()
                    ? <FormHelperText error>Периоды не должны пересекаться</FormHelperText>
                    : null
                }

                {fields?.dates?.length < 3 && (
                    <Button
                        type="text"
                        onClick={() => {
                            setFields({
                                ...fields,
                                dates: [
                                    ...fields?.dates,
                                    {}
                                ],
                            });
                            setIsCleared(false);
                        }}
                    >
                        Добавить период
                    </Button>
                )}
            </div>

            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        // {
                        //     onClick: handleLink,
                        //     disabled: errorDate(),
                        //     variant: 'text',
                        //     name: 'Посмотреть отчет по районам',
                        // },
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilters,
                            disabled: isCleared,
                        },
                        {
                            ...buttonsTypes.find,
                            onClick: handleChange,
                            disabled: isDisabled || loadingEfficiencyDtp || compareD(),
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;
