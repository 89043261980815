import type React from 'react';
import { useEffect, useState } from 'react';

interface VisibleProps {
    children: React.ReactNode,
    variant: 'mobile' | 'desktop'
    isMobile?: boolean
}

const Visible = ({
    variant,
    children,
    isMobile
}: VisibleProps) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const width = document.documentElement.clientWidth;

            const isVisible = variant === 'mobile'
                ? width <= 900
                : width > 900 && !isMobile;

            setVisible(isVisible);
        };

        window.addEventListener('resize', handleResize, true);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile, variant]);

    return visible
        ? children
        : null;
};

export default Visible;
