import React, { memo, useState } from 'react';
import { TextField } from '@mui/material';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';
import titles from '../../../../../helpers/constants/titles';
import keyboardKeys from '../../../../../helpers/constants/keyboardKeys';

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{ page: number; data: { query?: string } }>>;
}

const Filter = memo(({ setParams }: FilterProps) => {
    const initState = { query: '' };

    const [filter, setFilter] = useState(initState);

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    const resetFilter = (needRefresh: boolean) => {
        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }

        setFilter(initState);
    };

    const search = () => {
        setParams({ page: 1, data: filter });
    };

    const updateFilter = (filters: { [key: string]: any }) => {
        setFilter({ ...initState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({ page: 1, data: { ...initState, ...filters } });
        }
    };

    const handleClickEnter = (e: React.KeyboardEvent) => {
        if (e?.key === keyboardKeys.ENTER) search();
    };

    return (
        <LayoutFilter
            filter={filter}
            onResetFilter={resetFilter}
            onSearch={search}
            setUserFilter={updateFilter}
            disabled={!filter?.query}
        >
            <LayoutFilter.Visible>
                <TextField
                    label={titles.NAME}
                    value={filter.query}
                    onChange={onChange}
                    name="query"
                    variant="outlined"
                    size="small"
                    onKeyPress={handleClickEnter}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
});

export default Filter;