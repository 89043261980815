import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';


const Line = ({
    data = [],
    xText,
    yText,
    height = 400,
    title
}) => {
    const callbacks = {
        label: (context) => ` ${context?.dataset?.label}: ${context?.formattedValue}${yText || ''}`,
        // title: () => ''
    };

    const options = {
        maintainAspectRatio: false,
        // indexAxis: 'y',
        redraw: false,
        scales: {
            ...xText
                ? {
                    x: {
                        title: {
                            display: true,
                            text: xText
                        }
                    },
                }
                : {},
            ...yText
                ? {
                    y: {
                        title: {
                            display: true,
                            text: yText
                        }
                    },
                }
                : {},
        },
        plugins: {
            tooltip: {
                callbacks
            },
            legend: {
                // display: !customLegend,
                align: 'center',
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
            }
        }
    };

    const dataGraph = useMemo(() => {
        return Object.keys(data).reduce((res, datasetLabel) => {
            const item = data[datasetLabel];

            const labels = item.data.map(({ name }) => name);

            const dataset = {
                label: datasetLabel,
                data: item.data.map(({ count = 0 }) => count),
                maxBarThickness: 80,
                backgroundColor: item.color,
                // borderColor: '#fff',
            };

            return {
                labels: res.labels.length > 0 ? res.labels : labels,
                datasets: [
                    ...res.datasets,
                    dataset
                ]
            };
        }, {
            labels: [],
            datasets: []
        });
    }, [data]);

    return (<>
        <h3>{title}</h3>
        <div>
            <Bar options={options} data={dataGraph} height={height} />
        </div>
    </>
    );
};

export default Line;
