import files from './files/index';
import { debounce } from 'lodash';

const prevAudioObj = {};

// воспроизведение аудио файла
const audioPlay = (
    // тип файла (alert, danger, info)
    type,
    // остановить воспроизведение предыдущего файла
    stopPrevPlayFile = false
) => {

    const play = () => {
        const audioFile = files[type] || '';
        // const audio = new Audio();
        // audio.src = audioFile;

        const audio = new Audio(audioFile);
        if (audio) {
            // остановить предыдуший файл
            if (stopPrevPlayFile && prevAudioObj?.pleer) {
                prevAudioObj?.pleer?.pause();
                delete prevAudioObj?.pleer;
            }

            prevAudioObj.pleer = audio;
            audio.play();
        }
    };

    return {
        // воспроизвести
        play: debounce(play, 200),
    };
};

export default audioPlay;