import React from 'react';
import Page from './Page';
import { HeaderPageVisible } from '../../../common/Showcase/HeaderPage';
import BoxContainer from '../../../common/Showcase/BoxContainer';
import HeaderPanel from '../../../common/Showcase/HeaderPanel';

// страница
const DorisControl = () => (
    <BoxContainer>

        {/* шапка */}
        <HeaderPageVisible/>

        <HeaderPanel title="Контроль КФВФ"/>

        {/* содержимое страницы */}
        <Page/>

    </BoxContainer>
);

export default DorisControl;