import React from 'react';
import { Collapse } from '@mui/material';
import messages from '../../../../../helpers/constants/messages';
import titles from '../../../../../helpers/constants/titles';
import Attachments from '../../../../common/Upload/Attachments';

function Info({ info, isOpen }) {
    const num = info?.route && info?.route?.num ? info?.route?.num : null;
    const name = info?.route && info?.route?.name ? info?.route?.name : null;
    const organization_title = info?.organization && info?.organization?.title ? info?.organization?.title : null;

    const { address } = info;

    const displayInfo = (value) => {
        if (typeof value === 'object') {
            const createAddress = (item) => {
                return `${item.city_name || item.settlement || value.region || ''} ${item.street || ''} ${item.house || ''}`;
            };

            return createAddress(value) || messages.DATA_IS_NOT_FOUND;
        }

        return value || messages.DATA_IS_NOT_FOUND;
    };
    
    return (<>
        {isOpen
            &&  <Collapse in={isOpen} timeout="auto" unmountOnExit className="info">
                <div>
                    <strong>{titles.ADDRESS}: </strong>
                    <span>{displayInfo(address)}</span>
                </div>
                <div>
                    <strong>{titles.COMPANY}: </strong>
                    <span>{organization_title || messages.DATA_IS_NOT_FOUND}</span>
                </div>
                <div>
                    <strong>{titles.USER}: </strong>
                    <span>{info?.user?.first_name} {info?.user?.last_name || messages.DATA_IS_NOT_FOUND}</span>
                </div>
                <div>
                    <strong>ФИО заявителя: </strong>
                    <span>{info?.applicant || messages.DATA_IS_NOT_FOUND}</span>
                </div>
                <div>
                    <strong>{titles.ROUTE_NUM}: </strong>
                    <span>{num || messages.DATA_IS_NOT_FOUND}</span>
                </div>
                <div>
                    <strong>{titles.ROUTE}: </strong>
                    <span>{name || messages.DATA_IS_NOT_FOUND}</span>
                </div>
                <div>
                    <strong>{titles.TEXT}: </strong>
                    <span>{info?.text || messages.DATA_IS_NOT_FOUND}</span>
                </div>
                <Attachments
                    files={info?.documents}
                    readOnly
                />
            </Collapse>
        }
    </>
    );
}

export default Info;
