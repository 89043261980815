import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    ListItemIcon,
    Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const useStyles = makeStyles({
    root: {
        minWidth: '30px',
        fontSize: '18px',
    },
});

const TooltipWhiteBC = withStyles({
    tooltip: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0 3px 5px #666',
    }
})(Tooltip);

// иконка с информацией
const MenuItemInfo = ({
    // селектор
    selector,
    // экшен для загрузки данных
    action,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const data = useSelector(selector || {});

    // клик по иконке -> грузим данные
    const handleLoad = useCallback((event) => {
        event.stopPropagation();
        if (action) {
            dispatch(action());
        }
    }, [dispatch, action]);

    // форматирование элемента
    const GetItem = ({ name, value }) => (
        <div>{name + ': ' + value}</div>
    );

    // сформированные данные для отображения
    const tooltipTitle = useMemo(() => {
        // массив
        if (Array.isArray(data)) {
            return data
                .map((item, index) => <GetItem key={index} name={item.name} value={item.count}/>);
        }
        // объект
        return Object.entries(data)
            ?.map((el, index) => <GetItem key={index} name={el[0]} value={el[1]}/>);
    }, [data]);

    return (
        <TooltipWhiteBC
            title={
                tooltipTitle.length > 0
                    ? <span>{tooltipTitle}</span>
                    : <span>Нажмите для запроса статистики</span>
            }
        >
            <ListItemIcon onClick={handleLoad} classes={{ root: classes.root }}>
                <i className="far fa-info-circle"/>
            </ListItemIcon>
        </TooltipWhiteBC>
    );
};

export default MenuItemInfo;
