import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';

import { transportRoadSelectors } from 'redux/TransportRoad';
import { loadedWorkorderLogs, loadWorkorderLogs } from 'redux/TransportRoad/actions';
import messages from 'helpers/constants/messages';
import Loading from 'components/common/Loading';

import TableHeader from './TableHeader';
import EventItem from './EventItem';

import type { EventContent } from '../types';

interface WorkorderEventsProps {
    workOrderId: number;
}

const WorkorderEvents = ({ workOrderId }: WorkorderEventsProps) => {
    const dispatch = useDispatch();

    const loadingWorkorderEvents = useSelector(transportRoadSelectors.loadingWorkorderLogs);
    const workorderEvents = useSelector(transportRoadSelectors.workorderLogs);

    useEffect(() => {
        if (workOrderId) {
            dispatch(loadWorkorderLogs(workOrderId));
        }
    }, [workOrderId, dispatch]);

    useEffect(() => {
        dispatch(loadedWorkorderLogs({ data: [] }));
    }, [dispatch]);

    return (
        <>
            {loadingWorkorderEvents && <Loading linear={true}/>}

            {workorderEvents?.length > 0
                ? <>
                    <List className="list">
                        <TableHeader/>
                        {workorderEvents?.map((item: EventContent, i: number) =>
                            <EventItem
                                item={item}
                                index={i}
                                key={item.id}
                            />
                        )}
                    </List>
                </>
                : (!loadingWorkorderEvents && messages.WORKORDER_EVENTS_ARE_NOT_FOUND)
            }
        </>
    );
};

export default WorkorderEvents;
