import { memo } from 'react';
import { FormControl, TextField } from '@mui/material';
import titles from '../../../../../../../helpers/constants/titles';

export const Header = memo((props) => {
    const { value, onChange, validation } = props;

    return (
        <FormControl required className="block" size="small" variant="outlined">
            <TextField
                required
                label={titles.NAME}
                variant="outlined"
                size="small"
                value={value}
                name="header"
                onChange={onChange}
                type="text"
                error={!value || !!validation.isKey('header')}
                helperText={validation.get('header')}
            />
        </FormControl>
    );
});