import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadScheduleEvent } from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import ModalAddEdit from './ModalAddEdit';
import Loading from '../../../../common/Loading';
import CustomPagination from '../../../../common/CustomPagination';
import { List } from '@mui/material';
import messages from '../../../../../helpers/constants/messages';
import Item from './Item';
import Search from '../../../../common/Search';
import Context from '../../../../../helpers/context';

function ScheduleEvents() {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);
    const [modalOpen, setModalOpen] = useState(false);

    const scheduleEventsData = useSelector(transportPassengerSelectors.scheduleEventsData);
    const scheduleEventsMeta = useSelector(transportPassengerSelectors.scheduleEventsMeta);
    const scheduleEventsLoading = useSelector(transportPassengerSelectors.scheduleEventsLoading);

    const [params, setParams] = useState({});
    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);

    const getParams = (value) => {
        value ? setParams({ name: value })
            : setParams({});
    };

    useEffect(() => {
        dispatch(loadScheduleEvent(1, limit));
    }, []);

    return (
        <div>
            <h1>События</h1>
            <Search action={loadScheduleEvent} loadParams={getParams}/>
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setModalOpen(true),
                            disabled: !permissions?.is_create
                        },
                    ]}
                />
            </div>
            {modalOpen
                && <ModalAddEdit
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    isNew={true}
                />
            }
            {scheduleEventsLoading && <Loading circular={true}/>}
            {scheduleEventsData?.length > 0
                ? (<>
                    <List className="list">
                        {scheduleEventsData?.map(item => <Item key={item?.id} data={item}/>)}
                    </List>
                    <CustomPagination
                        loadList={(page, limit) => dispatch(loadScheduleEvent(page, limit, params))}
                        list={scheduleEventsMeta}
                        limit={limit} 
                        setLimit={setLimit}
                    />
                </>)
                : (!scheduleEventsLoading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </div>
    );
}

export default ScheduleEvents;