import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setMapLoading } from 'redux/Map/actions';

import { moduleName } from '../module-config';

import api from './api.methods';
import * as actions from './actions';
import * as types from './types';

function* loadStationStatusesSaga() {
    const response = yield call(api.getStationStatuses);
    if (response?.success) {
        yield put(actions.loadedStationStatuses(response.data));
    }
}

function* loadStationListSaga({ payload }) {
    yield put(actions.loadingStationList(true));
    const response = yield call(api.loadStationList, payload);
    if (response?.success) {
        yield put(actions.loadedStationList(response));
    }
    yield put(actions.loadingStationList(false));
}

function* loadStationSidebarSaga({ payload }) {
    const { page } = payload;
    yield put(actions.loadingStationSidebar(true));
    const response = yield call(api.loadStationSidebar, payload);
    if (response?.success) {
        yield put(actions.loadedStationSidebar(response, page === 1));
    }
    yield put(actions.loadingStationSidebar(false));
}

function* loadVestibulePolygonSaga({ payload }) {
    yield put(actions.loadingVestibulePolygon(true));
    yield put(setMapLoading(moduleName, true));
    const response = yield call(api.loadVestibulePolygon, payload);
    if (response?.success) {
        yield put(actions.loadedVestibulePolygon(response.data));
    }
    yield put(setMapLoading(moduleName, false));
    yield put(actions.loadingVestibulePolygon(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_STATION_STATUSES, loadStationStatusesSaga),
        takeLatest(types.LOAD_STATION_LIST, loadStationListSaga),
        takeLatest(types.LOAD_STATION_SIDEBAR, loadStationSidebarSaga),
        takeLatest(types.LOAD_VESTIBULE_POLYGON, loadVestibulePolygonSaga),
    ]);
}
