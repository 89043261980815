
import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiGovernmentContractMethods = {
    getGovernmentContracts: async (page, limit, params) => {
        const response = await instance.get(apiUrls.getGovernmentContracts(),{
            params: { page, limit, ...params }
        });
        return response?.data;
    },

    getGovernmentContractById: async (id) => {
        const response = await instance.get(apiUrls.getGovernmentContractById(id));
        return response?.data;
    },

    createGovernmentContract: async (params) => {
        const response = await instance.post(apiUrls.createGovernmentContract(), params);
        return response?.data;
    },

    editGovernmentContract: async (id, params) => {
        const response = await instance.put(apiUrls.editGovernmentContract(id), params);
        return response?.data;
    },

    deleteGovernmentContract: async (id, data) => {
        const response = await instance.delete(apiUrls.deleteGovernmentContract(id), { data });
        return response?.data;
    },

    getGovernmentContractHistory: async (id, page, limit) => {
        const response = await instance.get(apiUrls.getGovernmentContractHistory(id), { 
            params: { page, limit } 
        });
        return response?.data;
    },

    getContractEntitiesByName: async (id, entity_name) => {
        const response = await instance.get(apiUrls.getContractEntitiesByName(id, entity_name));
        return response?.data;
    },

    createContractEntitiesByName: async (id, entity_name, params) => {
        const response = await instance.post(apiUrls.getContractEntitiesByName(id, entity_name), params);
        return response?.data;
    },

    getContractEntitiesTypes: async () => {
        const response = await instance.get(apiUrls.getContractEntitiesTypes());
        return response?.data;
    },

    getContractLinkObjects: async (url, page, limit, params) => {
        const response = await instance.get(apiUrls.getContractLinkObjects(url), {
            params: { page, limit, ...params }
        });
        return response?.data;
    },

};

export default ApiGovernmentContractMethods;