import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStoreProp, useValidation } from '../../../../../../../helpers/hooks';
import { loadStagesStatuses } from '../../../../../../../redux/RoadWorks/actions';
import Modal from '../../../../../../common/Modal';
import titles from '../../../../../../../helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from '../../../../../../common/FormButtons';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormHelperText
} from '@mui/material';
import {
    editStage,
} from '../../../../../../../redux/RoadWorks/actions';

const EditOrderStatusModal = ({ data = {}, isOpen, onClose, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const [orderStage, setOrderStage] = useState(data);

    const statuses = useStoreProp(loadStagesStatuses, 'roadworks', 'stagesStatuses');

    const handleChange = (value, name) => {
        setOrderStage({
            ...orderStage,
            [name]: value
        });
    };

    const isDisabled = false;

    const submit = () => {
        dispatch(editStage(orderStage?.id, orderStage, () => {
            reloadList();
            onClose();
        }));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            title={titles.EDIT_STATUS}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: !orderStage?.id || isDisabled,
                        onClick: submit
                    },
                ]}
            />}
        >
            <form className="modal__form">
                <FormControl
                    required={true}
                    className="row__item"
                    size="small"
                    variant="outlined"
                    error={validation.isKey('status')}
                >
                    <InputLabel>{titles.STATUS}</InputLabel>
                    <Select
                        value={orderStage.status}
                        onChange={(event) => {
                            handleChange(parseInt(event.target.value), 'status');
                        }}
                        label={titles.STATUS}
                    >
                        {Object.keys(statuses).map((key) => (
                            <MenuItem key={key} value={key}>
                                {statuses[key]}
                            </MenuItem>
                        ))}
                    </Select>
                    {validation.isKey('name')
                    && <FormHelperText error={validation.isKey('status')}>
                        {validation.get('status')}
                    </FormHelperText>
                    }
                </FormControl>
            </form>
        </Modal>
    );
};

export default EditOrderStatusModal;