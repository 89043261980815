import React, { useState } from 'react';

import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import UniversalSelect from 'components/common/UniversalSelect';

import type { AlertTypes, Item } from './types';

const Alerts = ({
    multiple = false,
    selected,
    onChange = () => {},
    required = false,
    disabled = false,
    label='События',
    error = false,
    helperText = '',
    alerts = [],
    className = 'block',
    limitTags = 1
}: AlertTypes<Item>) => {
    const [showList, setShowList] = useState(false);
    const [sortedList, setSortedList] = useState([]);

    const getAlerts = (params: { page: number; limit: number; query?: string; }) => {
        const { query } = params;

        const filterAlerts = query
            // @ts-ignore
            ? alerts?.filter(item => item?.title?.toLowerCase().includes(query?.toLowerCase()))
            : alerts;

        setSortedList(filterAlerts);
    };

    // @ts-ignore
    const selectedAlerts = alerts?.filter(({ alarm_type }) => selected?.includes(alarm_type));

    const handleClose = () => setShowList(false);

    const handleAccept = (data: Item[] | Item) => {
        // @ts-ignore
        onChange(data.map(({ alarm_type }) => alarm_type));
        setShowList(false);
    };

    const onReset = () => {
        const initialState = multiple ? [] : '';
        onChange(initialState);
    };

    return (
        <>
            <div className={className}>
                <CommonAutocomplete<Item>
                    multiple={multiple}
                    selected={selectedAlerts}
                    onChange={onChange}
                    required={required}
                    inputName="type"
                    disabled={disabled}
                    onReset={onReset}
                    renderLabel={(option) => option?.title || ''}
                    limitTags={limitTags}
                    label={label}
                    onShowList={() => setShowList(true)}
                    error={error} 
                    helperText={helperText}
                    isOpen={showList}
                />
            </div>
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getAlerts}
                    sortedList={sortedList}
                    keyProp="alarm_type"
                    withSearch
                    isSelected
                    selected={selectedAlerts}
                    renderProps={(el) => <div>{el?.title || ''}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    small
                    title={'Выберите событие'}
                    storeName="companies"
                    storeNameProps="alerts"
                />
            }
        </>)
    ;
};

export default Alerts;
