// иконка для списка
const icon = ({ fill = '#fff' }) => {
    return `
        <svg fill="${fill}" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="car-bump" class="svg-inline--fa fa-car-bump fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M157.56 410.15l233.93-105.9 13.63 28.39c11.28 23.48 40.13 33.68 64.31 22.73l14.62-6.62c24.19-10.95 34.69-38.96 23.41-62.44L446.1 158.56c-15.37-32-50.16-48.79-84.38-44.08L306.3 56.86c-23.84-24.78-61.39-31.85-93.04-17.52L63.1 107.32c-31.65 14.33-50.21 46.8-45.98 80.46l9.84 78.25C1.24 288.46-7.87 325.13 7.5 357.12l61.36 127.75c11.28 23.48 40.13 33.68 64.31 22.73l14.62-6.62c24.19-10.95 34.69-38.96 23.41-62.44l-13.64-28.39zm320.67-110.61c3.76 7.83.25 17.17-7.8 20.81l-14.62 6.62c-8.05 3.65-17.68.26-21.44-7.57l-13.63-28.39 43.86-19.86 13.63 28.39zM76.73 135.71L226.9 67.73c19.04-8.62 41.48-4.4 55.82 10.51l45.49 47.29L57.22 248.21l-8.08-64.22c-2.54-20.26 8.55-39.66 27.59-48.28zm57.43 336.88l-14.62 6.62c-8.05 3.65-17.68.26-21.44-7.58l-13.64-28.39 43.86-19.86 13.63 28.39c3.77 7.84.26 17.18-7.79 20.82zm-63.33-57.73l-34.09-70.97c-11.28-23.48-.78-51.49 23.41-62.44l292.41-132.38c24.19-10.95 53.04-.75 64.31 22.73l20.45 42.58 13.63 28.39-43.86 19.86-29.24 13.24L70.83 414.86zm327.25-217.32c-9.87-20.54-34.24-27.65-57.96-16.91-22.02 9.97-40.54 43.45-30.02 65.35 5.1 10.63 19.01 26.03 54.84 9.81l9.22-4.28c23.73-10.74 33.78-33.43 23.92-53.97zm-34.14 32.68c-2.85 1.29-6.01 2.83-9.22 4.28-9.62 4.36-19.63 7.92-22.69 1.55-4.08-8.49 6.91-28.97 18.31-34.13 11.4-5.16 21.72-2.95 25.8 5.54s-.8 17.6-12.2 22.76zm-270.9 62.27c-23.72 10.74-33.78 33.44-23.91 53.97 9.87 20.54 34.24 27.65 57.96 16.91l9.3-4.11c35.83-16.22 32.61-36.47 27.51-47.09-10.52-21.9-48.83-29.65-70.86-19.68zm33.13 45.48c-3.21 1.45-6.46 2.81-9.3 4.11-11.4 5.16-21.72 2.94-25.8-5.55-4.08-8.49.81-17.59 12.21-22.75s34.62-.17 38.7 8.32c3.05 6.36-6.19 11.52-15.81 15.87zm449.49 154.78c-10.72-51.84-57.56-89.47-111.34-89.47S363.72 440.91 353 492.75c-1.81 8.66 3.75 17.12 12.41 18.92 8.5 1.73 17.09-3.77 18.91-12.42 7.69-37.06 41.31-63.97 80-63.97s72.34 26.91 80.03 63.97c1.56 7.55 8.22 12.75 15.63 12.75 1.09 0 2.19-.11 3.28-.33 8.65-1.79 14.21-10.26 12.4-18.92z"></path>
        </svg>
    `;
};

export default icon;
