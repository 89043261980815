import replaceForWsInRedux from 'helpers/replaceForWsInRedux';

import * as types from './types';

const initialState = {
    statuses: {},
    history: [],
    list: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    polygon: [],
    loadingPolygon: false,
    loading: false,
    saved: false,
    activeRW: {},
    filters: {},
    editRW: {},

    // данные для удаления
    deleteForm: false,
    // данные для редактирования
    editForm: false,
    filter: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    orders: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    listOrders: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    ordersReportFilter: {},
    loadingOrders: false,
    orderStatuses: [],
    orderDirections: {},
    defectGroup: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    loadingDefectTypes: false,
    defectTypes: {
        data: [],
        meta: {
            last_page: 0,
        } },
    stagesStatuses: [],
    loadingStages: false,
    stages: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    stageCargoTypes: {},
    typeOfRoadDefections: {
        data: []
    },
    loadingTypeOfRoadDefections: false,
    typeCreation: {},
    unitList: {
        data: [],
        meta: {},
    },
    loadingUnits: false,
    roadWorksGroups: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingRoadWorksGroups: false,
    works: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingWorks: false,
    sidebarParams: {
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25
    },
    road_works: [],
    loading_stage_schedule: false,
    stage_schedule: [],
    KDM_works: [],
    planFactReport: [],
    loadingPlanFactReport: false,
    planFactReportTypes: {},

    // слой "Недостатки Дороги"
    defects: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    defectsSidebar: {
        data: []
    },
    defectsSidebarLoading: false,
    defectsFilters: {},
    defectsPolygon: [],
    defectsPolygonLoading: false,
    defectsActive: {},

    defectsSaved: false,
    loadingDefects: false,

    defectsDeleteForm: false,
    defectsEditForm: false,

    defectHeatMap: [],
    defectHeatMapLoading: false,
    defectHeatMapFilter: {},

    defectStatuses: [],
    nearDefects: [],

    loadingButton: false,

    settings: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingSettings: false,
    showcase: {},
    loadingShowcase: false,

    settingsGroup: { data: [] },
    loadingSettingsGroup: false,
    orderTypes: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    orderTypesLoading: false,
    nearRoadWorks: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingNearRoadWorks: false,
    repairObjectIndicators: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingRepairObjectIndicators: false,
    repairObjects: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingRepairObjects: false,
    repairFrequency: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingRepairFrequency: false,
    repairTypes: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingRepairTypes: false,

    sheetsAssessmentRoad: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingSheetsAssessmentRoad: false,
    loadingSheetsAssessmentRoadForm: false,
    roadWorksColors: [],

    showcaseOrders: {},
    loadingShowcaseOrders: false,

    showcaseOrdersReport: {},
    loadingShowcaseOrdersReport: false,

    roadWorkReportOrders: {
        data: [],
        meta: {}
    },
    loadingRoadWorkReportOrders: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.ORDERS_LOADED: {
            return {
                ...state,
                listOrders: payload,
            };
        }

        case types.GET_ORDERS_REPORT_FILTER: {
            return {
                ...state,
                ordersReportFilter: payload,
            };
        }

        case types.CLEAR_ORDERS_REPORT_FILTER: {
            return {
                ...state,
                ordersReportFilter: {},
                listOrders: initialState.listOrders,
            };
        }

        case types.STATUSES_LOADED: {
            return {
                ...state,
                statuses: payload,
            };
        }

        case types.LOADING_ROADWORKS: {
            return {
                ...state,
                loading: payload,
            };
        }

        case types.LOADED_ROADWORKS: {
            const {
                list,
                replaceStore
            } = payload;

            if (replaceStore) {
                return {
                    ...state,
                    list,
                };
            }
            return {
                ...state,
                list: {
                    ...state.list,
                    ...list,
                    data: [
                        ...state.list.data,
                        ...list.data
                    ],
                    // data: values(merge(
                    //     keyBy(state?.list?.data  || [], 'id'),
                    //     keyBy(list?.data || [], 'id')
                    // )),
                    meta: {
                        ...state.list.meta,
                        ...list.meta
                    },
                },
            };
        }

        case types.CHANHE_LIST_ITEM : {
            const newItem = action.payload;
            const indexList = state.list?.data?.findIndex(({ id }) => id === newItem.id);
            const indexPolygon = state.polygon?.findIndex(({ id }) => id === newItem.id);

            if (indexList >= 0 || indexPolygon) {
                const newList = {
                    ...state,
                };
                if (indexList >= 0) {
                    newList.list = {
                        ...state.list,
                        data: [
                            ...state.list.data.slice(0, indexList),
                            newItem,
                            ...state.list.data.slice(indexList + 1)
                        ]
                    };
                }
                if (indexPolygon >= 0) {
                    newList.polygon = [
                        ...state.polygon?.slice(0, indexPolygon),
                        newItem,
                        ...state.polygon?.slice(indexPolygon + 1)
                    ];
                }
                return newList;
            }

            return {
                ...state
            };
        }

        case types.POLYGON_LOADED: {
            return {
                ...state,
                polygon: payload,
            };
        }

        case types.LOADING_POLYGON: {
            return {
                ...state,
                loadingPolygon: payload,
            };
        }

        case types.SAVED: {
            return {
                ...state,
                saved: payload,
            };
        }

        case types.HISTORY_LOADED: {
            return {
                ...state,
                history: payload,
            };
        }

        case types.SET_ACTIVE: {
            return {
                ...state,
                activeRW: payload,
            };
        }

        case types.RESET_ACTIVE: {
            return {
                ...state,
                activeRW: payload,
            };
        }

        case types.SET_FILTER: {
            return {
                ...state,
                filters: {
                    //...state.filters,
                    ...payload
                },
            };
        }

        case types.RESET_FILTER: {
            return {
                ...state,
                filters: {},
            };
        }

        case types.SET_EDIT: {
            return {
                ...state,
                editRW: payload,
            };
        }

        case types.SET_DELETE_FORM: {
            return {
                ...state,
                deleteForm: payload,
            };
        }

        case types.SET_EDIT_FORM: {
            return {
                ...state,
                editForm: payload,
            };
        }

        case types.GET_FILTERED_LIST: {
            return {
                ...state,
                filter: payload,
            };
        }

        case types.LOADED_ROADWORK_ORDERS: {
            return {
                ...state,
                orders: payload,
            };
        }

        case types.LOADING_ROADWORK_ORDERS: {
            return {
                ...state,
                loadingOrders: payload,
            };
        }

        case types.CLEAR_ROADWORK_ORDERS: {
            return {
                ...state,
                orders: {
                    data: [],
                    meta: {
                        last_page: 0,
                    },
                },
            };
        }

        case types.LOADED_ORDER_STATUSES: {
            return {
                ...state,
                orderStatuses: payload
            };
        }

        case types.LOADED_ORDER_DIRECTIONS: {
            return {
                ...state,
                orderDirections: payload,
            };
        }

        case types.LOADED_STAGES_STATUSES: {
            return {
                ...state,
                stagesStatuses: payload
            };
        }

        case types.LOADED_STAGE_CARGO_TYPES: {
            return {
                ...state,
                stageCargoTypes: payload
            };
        }

        case types.LOADING_STAGES_LIST:{
            return {
                ...state,
                loadingStages: payload
            };
        }

        case types.LOADED_STAGES_LIST:{
            return {
                ...state,
                stages: payload
            };
        }

        case types.LOADED_DEFECT_GROUP: {
            return {
                ...state,
                defectGroup: payload
            };
        }

        case types.LOADING_DEFECT_TYPES: {
            return {
                ...state,
                loadingDefectTypes: payload
            };
        }

        case types.LOADED_DEFECT_TYPES: {
            return {
                ...state,
                defectTypes: payload
            };
        }

        case types.LOADED_TYPE_OF_ROAD_DEFECTIONS:{
            return {
                ...state,
                typeOfRoadDefections: payload
            };
        }

        case types.LOADING_TYPE_OF_ROAD_DEFECTIONS:{
            return {
                ...state,
                loadingTypeOfRoadDefections: payload
            };
        }

        case types.LOADED_TYPE_CREATION:{
            return {
                ...state,
                typeCreation: payload
            };
        }

        case types.LOADED_UNITS:{
            return {
                ...state,
                unitList: payload
            };
        }

        case types.LOADING_UNITS:{
            return {
                ...state,
                loadingUnits: payload
            };
        }

        case types.LOADING_ROADWORKS_GROUPS: {
            return {
                ...state,
                loadingRoadWorksGroups: payload
            };
        }

        case types.LOADED_ROADWORKS_GROUPS: {
            return {
                ...state,
                roadWorksGroups: payload
            };
        }

        case types.LOADING_WORKS: {
            return {
                ...state,
                loadingWorks: payload
            };
        }

        case types.LOADED_WORKS: {
            return {
                ...state,
                works: payload
            };
        }

        case types.SET_ROADWORKS_WS: {
            const { payload } = action;
            const newPolygon = payload
                ?.reduce(
                    (res, item) => replaceForWsInRedux(res || [], item),
                    [ ...state.polygon ]
                );

            return {
                ...state,
                polygon: newPolygon,
                // polygon: replaceForWsInRedux(state.polygon, payload),
                sidebarParams: {
                    ...state.sidebarParams,
                    page: 1,
                }
                // list: {
                //     ...state.list,
                //     data: replaceForWsInRedux(state.list?.data, payload),
                // },
            };
        }

        case types.SET_SIDEBAR_PARAMS: {
            const { payload } = action;
            return {
                ...state,
                sidebarParams: {
                    ...payload
                },
            };
        }

        case types.STAGE_SYNC_ROADWORK_ARRAY: {
            const { payload } = action;
            return {
                ...state,
                road_works: payload
            };
        }

        case types.LOADING_STAGE_SCHEDULE: {
            const { payload } = action;
            return {
                ...state,
                loading_stage_schedule: payload
            };
        }

        case types.LOADED_STAGE_SCHEDULE: {
            const { payload } = action;
            return {
                ...state,
                stage_schedule: payload
            };
        }

        case types.LOADED_KDM: {
            const { payload } = action;
            return {
                ...state,
                KDM_works: payload.works
            };
        }

        case types.LOADED_PLAN_FACT_REPORT: {
            return {
                ...state,
                planFactReport: payload
            };
        }

        case types.LOADING_PLAN_FACT_REPORT:{

            return {
                ...state,
                loadingPlanFactReport: payload
            };
        }

        case types.LOADED_PLAN_FACT_REPORT_TYPES: {
            return {
                ...state,
                planFactReportTypes: payload
            };
        }

        case types.LOADED_DEFECTS:{
            return {
                ...state,
                defects: payload
            };
        }

        case types.LOADED_DEFECTS_SIDEBAR: {
            const {
                list,
                replaceStore,
                refresh
            } = payload;

            // просто замена данных
            // if (refresh) {
            //     return {
            //         ...state,
            //         defectsSidebar: {
            //             ...state.list,
            //             data: list.data
            //         },
            //     };
            // }

            if (replaceStore) {
                return {
                    ...state,
                    defectsSidebar: list,
                };
            }
            return {
                ...state,
                defectsSidebar: {
                    ...state.defectsSidebar,
                    ...list,
                    data: [
                        ...state.defectsSidebar.data,
                        ...list.data
                    ],
                    meta: {
                        ...state.defectsSidebar.meta,
                        ...list.meta
                    },
                },
            };
        }

        case types.LOADING_DEFECTS_SIDEBAR: {
            return {
                ...state,
                defectsSidebarLoading: payload,
            };
        }

        case types.SET_DEFECTS_ACTIVE: {
            return {
                ...state,
                defectsActive: payload,
            };
        }

        case types.LOADED_DEFECTS_POLYGON: {
            return {
                ...state,
                defectsPolygon: payload,
            };
        }

        case types.CLEAR_DEFECTS_POLYGON: {
            return {
                ...state,
                defectsPolygon: initialState.defectsPolygon,
            };
        }

        case types.LOADING_DEFECTS_POLYGON: {
            return {
                ...state,
                defectsPolygonLoading: payload,
            };
        }

        case types.SET_EDIT_FORM_DEFECTS: {
            return {
                ...state,
                defectsEditForm: payload,
            };
        }

        case types.SET_DELETE_FORM_DEFECTS: {
            return {
                ...state,
                defectsDeleteForm: payload,
            };
        }

        case types.DEFECTS_SAVED: {
            return {
                ...state,
                defectsSaved: payload,
            };
        }

        case types.LOADING_DEFECTS: {
            return {
                ...state,
                loadingDefects: payload,
            };
        }

        case types.SET_DEFECTS_FILTER: {
            return {
                ...state,
                defectsFilters: payload,
            };
        }

        case types.CLEAR_DEFECTS_FILTER: {
            return {
                ...state,
                defectsFilters: initialState.defectsFilters,
            };
        }

        case types.LOADED_DEFECT_HEAT_MAP: {
            return {
                ...state,
                defectHeatMap: payload,
            };
        }

        case types.LOADING_DEFECT_HEAT_MAP: {
            return {
                ...state,
                defectHeatMapLoading: payload,
            };
        }

        case types.FILTER_DEFECT_HEAT_MAP: {
            return {
                ...state,
                defectHeatMapFilter: payload,
            };
        }

        case types.LOADED_DEFECT_STATUSES: {
            return {
                ...state,
                defectStatuses: payload,
            };
        }

        case types.LOADED_NEAR_DEFECTS: {
            return {
                ...state,
                nearDefects: payload,
            };
        }

        case types.LOADING_BUTTON: {
            return {
                ...state,
                loadingButton: payload
            };
        }

        case types.LOADED_SETTINGS: {
            return {
                ...state,
                settings: payload
            };
        }

        case types.LOADING_SETTINGS: {
            return {
                ...state,
                loadingSettings: payload
            };
        }

        case types.LOADED_SHOWCASE: {
            return {
                ...state,
                showcase: payload
            };
        }

        case types.LOADING_SHOWCASE: {
            return {
                ...state,
                loadingShowcase: payload
            };
        }

        case types.LOADED_SETTINGS_GROUP: {
            return {
                ...state,
                settingsGroup: payload
            };
        }

        case types.LOADING_SETTINGS_GROUP: {
            return {
                ...state,
                loadingSettingsGroup: payload
            };
        }

        case types.LOADING_ORDER_TYPES: {
            return {
                ...state,
                orderTypesLoading: payload
            };
        }

        case types.LOADED_ORDER_TYPES: {
            return {
                ...state,
                orderTypes: payload
            };
        }

        case types.LOADED_NEAR_ROAD_WORKS: {
            return {
                ...state,
                nearRoadWorks: payload,
            };
        }

        case types.LOADING_NEAR_ROAD_WORKS: {
            return {
                ...state,
                loadingNearRoadWorks: payload,
            };
        }

        case types.LOADING_REPAIR_OBJECT_INDICATORS: {
            return {
                ...state,
                loadingRepairObjectIndicators: payload
            };
        }

        case types.LOADED_REPAIR_OBJECT_INDICATORS: {
            return {
                ...state,
                repairObjectIndicators: payload
            };
        }

        case types.LOADED_REPAIR_OBJECTS: {
            return {
                ...state,
                repairObjects: payload
            };
        }

        case types.LOADING_REPAIR_OBJECTS: {
            return {
                ...state,
                loadingRepairObjects: payload
            };
        }

        case types.LOADED_REPAIR_FREQUENCY: {
            return {
                ...state,
                repairFrequency: payload
            };
        }

        case types.LOADING_REPAIR_FREQUENCY: {
            return {
                ...state,
                loadingRepairFrequency: payload
            };
        }

        case types.LOADED_REPAIR_TYPES: {
            return {
                ...state,
                repairTypes: payload
            };
        }

        case types.LOADING_REPAIR_TYPES: {
            return {
                ...state,
                loadingRepairTypes: payload
            };
        }

        case types.LOADING_SHEETS_ASSESSMENT_ROAD: {
            return {
                ...state,
                loadingSheetsAssessmentRoad: payload,
            };
        }

        case types.LOADED_SHEETS_ASSESSMENT_ROAD: {
            return {
                ...state,
                sheetsAssessmentRoad: payload,
            };
        }

        case types.LOADING_SHEETS_ASSESSMENT_ROAD_FORM: {
            return {
                ...state,
                loadingSheetsAssessmentRoadForm: payload,
            };
        }

        case types.LOADED_ROAD_WORKS_COLORS: {
            return {
                ...state,
                roadWorksColors: payload,
            };
        }

        case types.LOADED_SHOWCASE_ORDERS: {
            return {
                ...state,
                showcaseOrders: payload,
            };
        }
        case types.LOADING_SHOWCASE_ORDERS: {
            return {
                ...state,
                loadingShowcaseOrders: payload,
            };
        }

        case types.LOADED_SHOWCASE_ORDERS_REPORT: {
            return {
                ...state,
                showcaseOrdersReport: payload,
            };
        }
        case types.LOADING_SHOWCASE_ORDERS_REPORT: {
            return {
                ...state,
                loadingShowcaseOrdersReport: payload,
            };
        }

        case types.LOADED_ROAR_WORKS_REPORT_ORDERS: {
            return {
                ...state,
                roadWorkReportOrders: payload,
            };
        }

        case types.LOADING_ROAR_WORKS_REPORT_ORDERS: {
            return {
                ...state,
                loadingRoadWorkReportOrders: payload,
            };
        }

        default:
            return state;
    }
}
