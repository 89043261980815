import React, { useState } from 'react';
import messages from '../../../../../helpers/constants/messages';
import makeStyles from '@mui/styles/makeStyles';
import TModal from './TModal';
import ConfirmDelete from '../../../../common/ConfirmModal';
import { useDispatch } from 'react-redux';
import { deleteTariff } from '../../../../../redux/TransportPassenger/actions';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import handleLabelTypes from '../../../../../helpers/traffic.titles';
import buttons from 'helpers/constants/buttons';

const useStyles = makeStyles({
    collapseCard: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    collapseItem: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
    },
    collapseInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 30,
    },
    collapseIndex: {
        fontSize: 15,
        fontWeight: 'bold'
    }
});

const TAccordionInfo = ({
    item = {},
    i,
    tsTypes,
    typeQuery
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleConfirmDelete = () => {
        dispatch(deleteTariff(item.id, typeQuery));
        setOpenDeleteModal(false);
    };

    return (
        <>
            {openEditModal && (
                <TModal
                    isOpen={openEditModal}
                    isNew={false}
                    onClose={() => setOpenEditModal(false)}
                    el={item}
                    tsTypes={tsTypes}
                    typeQuery={typeQuery}
                />
            )}
            {openDeleteModal &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteModal}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
            <div className={classes.collapseCard}>
                <div className={classes.collapseItem}>
                    <span className={classes.collapseIndex}>{i + 1}</span>
                    <div className={classes.collapseInfo}>
                        <span><strong>Название маршрута: </strong> {item.name_route || messages.NO_DATA}</span>
                        <span><strong>Номер маршрута: </strong> {item.num_route || messages.NO_DATA}</span>
                        <h3>Тариф на перевозки: </h3>
                        <span>
                            {`Пассажиров - ${Number(item.tariff_passenger).toFixed(2)}руб за `
                                + handleLabelTypes(item.type_transportation).passenger
                                || messages.INFO_IS_NOT_FOUND}
                        </span>
                        <span>
                            {`Багажа - ${Number(item.tariff_baggage).toFixed(2)}руб за `
                                + handleLabelTypes(item.type_transportation).baggage
                                || messages.INFO_IS_NOT_FOUND}
                        </span>
                    </div>
                </div>
                <div>
                    <Tooltip
                        title={`${buttons.EDIT} тариф`}
                    >
                        <IconButton onClick={() => setOpenEditModal(true)} size="small">
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={`${buttons.DELETE} тариф`}
                    >
                        <IconButton onClick={() => setOpenDeleteModal(true)} size="small">
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </>
    );
};

export default TAccordionInfo;