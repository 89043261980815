import { useState } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import { getEndOf, getStartOf, } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SelectRoutes from 'components/common/Autocomplete/PassengerTransport/Routes';
import SelectWorkorders from 'components/common/Autocomplete/PassengerTransport/Workorders';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const WaybillsFilter = ({ params, licence, setParams }) => {

    const initDate = {
        date_start: getStartOf('year'),
        date_end: getEndOf('minute'),
    };

    const initialState = {
        ...initDate,
        route_id: '',
        organization_id: {},
        work_order_id: {},
        licence_card_id: ''
    };

    const [data, setData] = useState(initialState);

    const onChange = (value, name) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const onReset = (needRefresh) => {
        setData(initialState);

        if (needRefresh) {
            setParams({ page: 1, data: initDate });
        }
    };

    const onSearch = () => {
        setParams({ page: 1, data });
    };

    const updateFilter = (filters) => {
        const newData = {
            ...params.data,
            ...filters
        };

        setData((prev) => ({
            ...prev,
            ...filters
        }));

        if (Object.keys(filters).length > 0) {
            setParams((prev) => ({
                ...prev,
                data: newData
            }));
        }
    };

    return (
        <LayoutFilter
            onResetFilter={onReset}
            onSearch={onSearch}
            filter={data}
            filterException={['date_start', 'date_end']}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={data.date_start}
                    valueEndDate={data.date_end}
                    handleDate={(e) => setData({ ...data, date_start: e })}
                    handleEndDate={(e) => setData({ ...data, date_end: e })}
                    startLabel="Начало действия"
                    endLabel="Конец действия"
                    disableFuture={false}
                />

                <SelectRoutes
                    selected={data.route_id}
                    onChange={(value) => onChange(value, 'route_id')}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <SelectCompany
                    selected={data.organization_id}
                    onChange={(value) => onChange(value, 'organization_id')}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                />

                <SelectWorkorders
                    selected={data.work_order_id}
                    onChange={(value) => onChange(value, 'work_order_id')}
                    label="Наряд"
                />

                <FormControl variant="outlined" size="small">
                    <InputLabel>Лицензионная карточка</InputLabel>
                    <Select
                        label="Лицензионная карточка"
                        value={data.licence_card_id}
                        onChange={(e) => setData({
                            ...data,
                            licence_card_id: e.target.value
                        })}
                    >
                        <MenuItem value="">{messages.NOT_CHOSEN}</MenuItem>
                        {Object.keys(licence)?.length
                            && Object.keys(licence)?.map(key =>
                                <MenuItem key={key} value={key}>{licence[key]}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default WaybillsFilter;
