import React, { useContext, useEffect, useState } from 'react';
import Filter from './Filter';
import { List } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../helpers/constants/messages';
import { loadStatusesVideoCamera, loadCameraGroupList } from '../../../../redux/VideoCameras/actions';
import CustomPagination from '../../../common/CustomPagination';
import { useStoreProp } from '../../../../helpers/hooks';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import Loading from '../../../common/Loading';
import ModalForm from './ModalForm';
import Item from './Item';
import Context from '../../../../helpers/context';

const CameraGroup = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openAddModalWindow, setOpenAddModalWindow] = useState(false);

    const statuses = useStoreProp(loadStatusesVideoCamera, 'videoCameras', 'videoCameraStatuses');

    const { cameraGroup, loadingCameraGroup } = useSelector(({ videoCameras }) => videoCameras);

    useEffect(() => {
        dispatch(loadCameraGroupList(params.page, limit, params.data));
    },[dispatch, limit, params]);

    return (
        <>
            <h1>Справочник группы видеокамер</h1>
            <Filter setParams={setParams} />
            <div className="filter__wrap__btn">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenAddModalWindow(true),
                            disabled: !permissions?.is_create
                        }
                    ]}
                />
            </div>
            {openAddModalWindow
            && <ModalForm
                isNew={true}
                isOpen={openAddModalWindow}
                onClose={() => setOpenAddModalWindow(false)}
            />
            }
            {loadingCameraGroup && <Loading linear={true}/>}
            <List className="list">
                {cameraGroup?.data?.length > 0
                    ? (<>
                        {cameraGroup?.data.map(el => (
                            <Item key={el.id} el={el} statuses={statuses}/>
                        )
                        )}
                        <CustomPagination
                            loadList={(page) => setParams({ ...params, page })}
                            list={cameraGroup.meta}
                            limit={limit} 
                            setLimit={setLimit}
                        />
                    </>)
                    : !loadingCameraGroup && <div>{messages.DATA_IS_NOT_FOUND}</div>
                }
            </List>
        </>
    );
};

export default CameraGroup;
