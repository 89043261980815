import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mapHelper from '../../../../helpers/mapHelper';
import * as actions from '../../../../redux/Aip/actions';
import AipPopup from './PopUp';
import config from './config.js';
import {
    createIconMarker,
    getColorByStatus,
} from './helper';
import {
    Marker,
    PopUp,
} from '../../leaflet';
import { debounce } from 'lodash';


const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();

    const {
        polygon,
        active,
    } = useSelector(({ aip }) => aip);

    const activeId = Object.keys(active || {}).length > 0 ? active.id : 0;

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getPolygon(map);
        dispatch(actions.getForPolygonAip(polygon));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = debounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();

    
    
    // сдвинуться к маркеру
    const setMapToMarker = () => {
        if (Object.keys(active).length > 0) {
            const { lat, lon } = active;
            if (Math.abs(lat) && Math.abs(lon)) {
                setTimeout(() => {
                    map.setView([lat, lon]);
                }, 200);
            }
        }
    };

    
    useEffect(() => {

        fetchPolygon();

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        setMapToMarker();

        return () => {
            dispatch(actions.resetActive());
            dispatch(actions.clearForPolygon());
            map.closeContextMenu();
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 15);
            }
        }
    }, [active]);

 
    return (
        <>
            {polygon.map((item) => {
                const { id, lat, lon } = item;
                const componentProps = {
                    ...props,
                    key: `item_${id}`,
                    attribution: {
                        slug: config.slug,
                        color: getColorByStatus(item.status)
                    },
                    onClick: (latlng) => {
                        map.setView(latlng);
                        dispatch(actions.resetActive());
                    },
                };

                const currentShow = activeId === id;

                return (
                    <Marker
                        {...componentProps}
                        latlng={[lat, lon]}
                        icon={createIconMarker(item.status)}
                    >
                        <PopUp
                            minWidth={700}
                            show={currentShow}
                            onClose={() => {
                                if (currentShow) {
                                    dispatch(actions.resetActive());
                                }
                            }}
                        >
                            <AipPopup
                                data={item}
                            />
                        </PopUp>
                    </Marker>
                );
            })}
        </>
    );
};

export default Layer;