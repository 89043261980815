import React, { useEffect, useState, } from 'react';
import { Virtuoso } from 'react-virtuoso';
import {
    Checkbox,
    FormControlLabel,
    Icon,
    IconButton,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import cn from 'classnames';
import _ from 'lodash';

import messages from 'helpers/constants/messages';
import { useDebounce } from 'helpers/hooks';
import useWindowDimensions from 'helpers/hooks/useWindowDimensions';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import InformPanel from 'components/layout/PageLayout/InformPanel';
import CustomPagination from 'components/common/CustomPagination';

import styles from './universalselect.module.scss';

import type { UniversalSelectProps } from './types-new';

const UniversalSelectNew = ({
    list,
    withSearch,
    multiple,
    keyProp,
    className,
    selected,
    fetchList,
    searchTitle = 'Найти',
    sortedList, // если не надо делать запрос и у нас есть список
    loading,
    renderProps,
    renderIconProps = null,
    renderKey = null, // если у элемента нет уникального ключа, функция собирает его из частей - например в статьях КОАП
    notFoundText = messages.NOTHING_FOUND,
    selectDeletedItem = false, // возможность выбрать в селекте удаленную
    isOpen = false,
    onClose = () => {},
    title = '',
    noPadding = false,
    onAccept,
    buttons = null, // доп кнопки - например создать сущность прямо из универсального селекта
    limitedNumberItems = 0, // если можно выбрать не больше определенного числа элементов (например см. Пользовательские шаблоны камер)
    dynamicTitle = false,
    renderSearch = null, // если нужен другой поиск, например дата-пикер
    size = '50%',
    canSelectAll = true, // показать чекбокс Выбрать все, скрыть пагинацию
    onSelectAll = null, // кастомная функция Выбраь все в неплоских списках типа статей КОАП
    selectAllChecked = false, // кастомный флаг что Выбрано все (прим. статьи КОАП)
    onChangeArrayInItem = () => {},// кастомная функция onChange в неплоских списках типа статей КОАП
    arrayInItem = '', // ключ по которому мы проверяем элемент в неплоском списке - например parts в статьях КОАП
    readOnly = false, // если надо просто посмотреть список
    meta, // если у нас должна быть пагинация
}: UniversalSelectProps) => {
    const { height, } = useWindowDimensions();

    const [query, setQuery] = useState('');
    // const prevData = useRef(selected);
    const [initialListLength, setInitialListLength] = useState(false);

    useEffect(() => {
        if (list?.length > 0) {
            setInitialListLength(true);
        }
    }, [list?.length]);
    
    const [selectedItems, setSelected] = useState(selected); // массив или 1 элемент, храним выбранное до нажатия кнопки Готово

    const [checkedAll, setCheckedAll] = useState(false);

    useEffect(() => {
        setCheckedAll(selectAllChecked);
    }, [selectAllChecked]);

    const currentTitle = dynamicTitle
        ? `${title} ${Object.keys(selectedItems).length}/${limitedNumberItems}`
        : title;


    // useEffect(() => {
    //     // сброс фильтра - selected изменился нужно обновить selectedItems
    //     if (!_.isEqual(prevData?.current, selected)) {
    //         setSelected(selected);
    //         prevData.current = selected;
    //     }
    // }, [selected, prevData]);

    const limited = (limitedNumberItems > 0) ? (Object.keys(selectedItems).length < limitedNumberItems) : true;

    const fetchDebounce = useDebounce((props: { query?: string }) => {
        fetch(props);
    }, 1000);

    useEffect(() => {
        // начальная загрузка списка
        !sortedList?.length && fetch({ query });
    }, []);

    const fetch = (props: { query?: string }) => {
        fetchList && fetchList(props);
        if (meta && checkedAll) {
            handleCheckAll();
        }
    };


    const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setQuery(value);
        fetchList && fetchDebounce({ query: value });
    };

    const handleChangeCustomSearch = (value: string = '') => {
        setQuery(value);
        fetchList && fetchDebounce({ query: value });
    };

    const checkSelected = (item: { [x: string]: any; }) => {
    // если этот элемент имеет внутри список элементов в массиве = например статьи ПДД с
    // тогда поле arrayInItem будет parts и мы будем проходить по нему и также проверять
    // чекнуты там элменеты или нет
        const comparison = (el: any) => {
            return multiple
                ? selectedItems?.some((selectItem: any) =>
                    keyProp ? el?.[keyProp] === selectItem?.[keyProp] : el === selectItem
                )
                : el?.[keyProp] === selectedItems?.[keyProp];
        };
        const isArray = arrayInItem && Array.isArray(item[arrayInItem]);
        return isArray
            ? item[arrayInItem].every((el: any) =>
                el[arrayInItem]
                    ? checkSelected(el)
                    : comparison(el)
            )
            : comparison(item);
    };

    const handleChange = (el: any, index: number) => {
        if (multiple) {
            // находим индекс элемента в выбранных
            const findIndex = selectedItems.findIndex((item: { [x: string]: any; }) => item?.[keyProp] === el?.[keyProp]);
            // если элемента в списке нет
            if (findIndex < 0 && limited) {
                // мы должны вставить элемент в правильное место в selectedItems,
                // пройдем по selectedItems и найдем элемент стоящий в общем списке сразу за выбранным

                const findNextSelectedIndex = selectedItems.findIndex((elem: any) => {
                    const indexInList = list.findIndex((e: any) => e?.[keyProp] === elem?.[keyProp]);
                    return indexInList > index;
                });

                const newSelectedArray = findNextSelectedIndex < 0
                    ? [
                        ...selectedItems,
                        el,
                    ]
                    : [
                        ...selectedItems.slice(0, findNextSelectedIndex),
                        el,
                        ...selectedItems.slice(findNextSelectedIndex),
                    ];

                setSelected(newSelectedArray);
            } else {
                // если элемент есть в выбранных, убираем его
                setSelected(selectedItems.filter((item: { [x: string]: any; }) => item?.[keyProp] !== el?.[keyProp]));
            }
        } else {
            setSelected(el);
        }
    };

    const renderIcon = (item: any) => {
        return (
            <Icon
                component="span"
                className={checkSelected(item) ? 'far fa-check' : 'far fa-plus'}
                sx={{ color: 'green' }}
            />
        );
    };

    const handleChoose = () => {
        onAccept && onAccept(selectedItems);
    };

    const handleCheckAll = () => {
        if (onSelectAll) {
            // если у нас есть кастомная функция выбора всех (например статьи КОАП)
            onSelectAll();
            return;
        }

        if (checkedAll) {
            setSelected([]);
            setCheckedAll(false);
        } else {
            setSelected(list);
            setCheckedAll(true);
        }
    };

    return (
        <Modal
            noOverflowY
            isOpen={isOpen}
            onClose={onClose}
            title={currentTitle}
            noPadding={noPadding}
            size={size}
            buttons={<FormButtons
                buttons={[
                    {
                        ...(readOnly ? buttonsTypes.close : buttonsTypes.cancel),
                        onClick: onClose
                    },
                    ...(readOnly
                        ? []
                        : [{
                            ...buttonsTypes.select,
                            onClick: handleChoose,
                            disabled: _.isEmpty(selectedItems)
                        }]),
                ]}
            />
            }
        >
            <div className={cn(styles.wrapper, className)}>
                {withSearch && initialListLength && (
                    <div className="row noMargin">
                        {renderSearch
                            ? renderSearch({
                                value: query,
                                onChange: handleChangeCustomSearch,
                                className: 'row__item'
                            })
                            : <div className={`row__item ${styles.search}`}>
                                <input
                                    size={10}
                                    id={'searchId'}
                                    type={'text'}
                                    name={'searchId'}
                                    value={query}
                                    onChange={handleChangeSearch}
                                    placeholder={searchTitle}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        }
                        {query && (
                            <IconButton size="small" onClick={() => {
                                handleChangeCustomSearch();
                                setCheckedAll(false);
                            }}>
                                {/* для сброса поиска используем handleChangeCustomSearch т.к. она установит value в пустую стооку
                                и перезапросит список  */}
                                <CloseIcon />
                            </IconButton>
                        )}
                    </div>
                )}
                {loading && <Loading linear />}
                {list.length > 0 && canSelectAll
                    && <InformPanel
                        {...(!meta
                            ? { total: list.length }
                            : {}
                        )}
                        buttons={<>
                            {multiple && (
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={checkedAll}
                                        onChange={handleCheckAll}
                                        size="small"
                                        sx={{
                                            padding: 0,
                                            paddingRight: '0.5rem'
                                        }}
                                    />}
                                    label={meta ? 'Выбрать все на текущей странице' : 'Выбрать все'}
                                />
                            )}
                            {buttons}
                        </>}
                    />
                }
                <>
                    {list.length > 0
                        ? <Virtuoso
                            style={{ height: height - 230 - 100, flex: '1 1 auto' }} // 230 - 100 - отступы сверху и снизу
                            className="list"
                            data={list}
                            itemContent={(index: number, item: { [x: string]: React.Key | null | undefined; deleted_at: any; }) => (
                                <ListItemButton
                                    dense
                                    divider
                                    disableGutters
                                    key={renderKey ? renderKey(item) : item[keyProp]}
                                    disabled={selectDeletedItem ? false : !!item.deleted_at}
                                    alignItems={arrayInItem ? 'flex-start' : 'center'}
                                    onClick={() => arrayInItem ? {} : handleChange(item, index)}
                                >
                                    <ListItemIcon>
                                        <IconButton
                                            onClick={() => arrayInItem
                                                ? onChangeArrayInItem(item)
                                                : handleChange(item, index)
                                            }
                                            disabled={readOnly}
                                        >
                                            {renderIcon(item)}
                                        </IconButton>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography component="div">
                                            {renderProps && renderProps(item)}
                                        </Typography>
                                    </ListItemText>
                                    {renderIconProps?.(item)}
                                </ListItemButton >
                            )}
                        />
                        : (!loading && <div>{notFoundText}</div>)
                    }
                </>
                {meta && list.length > 0
                    && <CustomPagination
                        loadList={(page: number, limit: number) => fetchList
                            ? fetchDebounce({ page, limit, query })
                            : null
                        }
                        list={meta}
                        isUniversal={true}
                    />
                }
            </div>
        </Modal>
    );
};

export default UniversalSelectNew;
