import { useState } from 'react';
import { TableCell, Tooltip, IconButton } from '@mui/material';
import Delete from '@mui/icons-material/Delete';

import { getConfigForMinMaxDate } from 'helpers/date.config';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import ConfirmDelete from 'components/common/ConfirmModal';

const TableDateItems = ({ item, handleDelete, onChange, stage }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return (
        <TableCell align="center" sx={{ width: 200 }}>
            <Tooltip title={'Удалить столбец'}>
                <IconButton
                    onClick={() => setOpenDeleteModal(true)}
                    size="small"
                >
                    <Delete fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <SingleKeyboardDateTimePicker
                onChange={onChange}
                value={item}
                dateOnly
                maxDate={getConfigForMinMaxDate(stage?.end_date_plan)}
                minDate={getConfigForMinMaxDate(stage?.start_date_plan)}
            />
            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onSuccess={handleDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </TableCell>
    );
};

export default TableDateItems;