import { Collapse } from '@mui/material';
import messages from '../../../../../helpers/constants/messages';
import Chip from '@mui/material/Chip';
import titles from '../../../../../helpers/constants/titles';
import Attachments from '../../../../common/Upload/Attachments';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { loadCompanies } from '../../../../../redux/Companies/actions';
import { companiesSelectors } from '../../../../../redux/Companies';

const notInfo = messages.INFO_IS_NOT_FOUND;

const Info = ({ isOpen, item }) => {

    const dispatch = useDispatch();

    const companies = useSelector(companiesSelectors.companies);

    const filteredOperationList = useMemo(() => item?.operation_list?.filter(el => el.organization === null), [item]);

    useEffect(() => {
        if (filteredOperationList.length > 0) {
            const id_list = filteredOperationList.map(elem => elem?.organization_id);

            dispatch(loadCompanies(1, 100, { id_list, withDeleted: 1 }));
        }
    }, [dispatch]);

    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            <div><strong>{titles.THREAT_CATEGORY}: </strong>{item?.category?.name || notInfo}</div>
            
            <div>
                <strong>{titles.KEYWORDS}: </strong> 
                {item?.keyword_list?.length > 0
                    ? item?.keyword_list?.map(word => (
                        <Chip 
                            key={word.id} 
                            label={word?.name} 
                            size={'small'} 
                            style={{ marginRight: '2px' }}
                        />
                    ))
                    : notInfo}
            </div>
            
            <div>
                <strong>{titles.OPERATIONS}: </strong>
                {item?.operation_list.length > 0
                    ? item?.operation_list?.map(el => {
                        const company = companies?.data?.find(elem => elem?.id === el?.organization_id);

                        return (
                            <div key={el?.id}>
                                {el?.position}. {el?.name}, компания: {el?.organization?.title || company?.title}
                            </div>
                        );
                    })
                    : notInfo }
            </div>

            {/*<div><strong>{titles.EVENT}: </strong>{item?.event?.name || notInfo}</div>*/}
            
            {/*<div>*/}
            {/*    <strong>{titles.EVENT_OBJECTS}: </strong>*/}
            {/*    {item?.event_object_list.length > 0*/}
            {/*        ? item?.event_object_list?.map(elem => <div key={elem?.id}>{elem?.name}</div>)*/}
            {/*        : notInfo }*/}
            {/*</div>*/}

            <div><strong>{titles.COMMENT}: </strong>{item?.comment || notInfo}</div>

            <Attachments
                readOnly
                files={item?.attachments}
            />
        </Collapse>
    );
};

export default Info;
