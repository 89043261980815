import { useContext } from 'react';
import { useSelector } from 'react-redux';

import Context from 'helpers/context';
import { authSelectors } from 'redux/Auth';

import { useAccessTemplate } from './useAccessTemplate';
import type { Permisson } from 'types';

// хук - получение прав доступа (из меню) для конкретной страницы
export const usePermissions = (uri?: string) => {
    const permissionList = useSelector(authSelectors.permissionList || []);
    const context = useContext(Context);

    const userPermissions = uri
        ? permissionList.find((el: Permisson) => el?.link === uri)?.permissions || {}
        : context?.permissions;

    // console.log('uri: ', uri, 'userPermissions: ', userPermissions);

    const access = useAccessTemplate(uri);

    const permission = {
        ...access,
        actions: {
            ...userPermissions, // права из меню
            ...access.actions, // права для страницы - в приоритете
        }
    };
    return permission || {};
};
