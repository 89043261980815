import React, { useState } from 'react';
import { useDebounce } from '../../../../../../../helpers/hooks';
import {
    TextField,
    LinearProgress,
} from '@mui/material';
import useCheckFgis from '../../../../../../../helpers/hooks/useCheckFgis';
import ConfirmModal from '../../../../../../common/ConfirmModal';

const FgisField = ({
    label,
    value,
    name,
    require = false,
    onChange,
    onChangeFields,
}) => {
    const [error, setError] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const checkFgis = useCheckFgis((data, isData) => {
        if (isData) {
            // onChange?.(data || {});
            onChangeFields(data || {});
        } else {
            setShowConfirm(true);
        }
    });
    const checkDebounce = useDebounce(checkFgis);

    const handleChange = (e) => {
        const value = e?.target?.value;
        if (value.length > 0) {
            checkDebounce(value);
        } else {
            checkDebounce.clear();
        }
        setError(false);
        onChange?.(value);
        // onChange?.({ [name]: value });
    };

    const handleConfirmSuccess = () => {
        setShowConfirm(false);
    };

    const handleConfirmClose = () => {
        setShowConfirm(false);
        setError(true);
    };

    return (
        <>
            <TextField
                label={label}
                value={value}
                name={name}
                required={require}

                size="small"
                variant="outlined"
                type="text"
                onChange={handleChange}
                error={error}
            />
            {/*{checkFgis.loading && <LinearProgress/>}*/}

            <ConfirmModal
                open={showConfirm}
                onClose={handleConfirmClose}
                onSuccess={handleConfirmSuccess}
                message={'Указанная ссылка ведет не на сайт ФГИС "АРШИН" https://fgis.gost.ru/fundmetrology/. Вы уверены, что указали верную ссылку?'}
            />
        </>
    );
};

export default FgisField;