import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roadNetworksSelectors } from 'redux/RoadNetwork';
import { deletePakVendor } from 'redux/RoadNetwork/actions';
import {
    ActionsButtons,
    LSContentColumn,
    LSContentItem
} from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import messages from 'helpers/constants/messages';
import type { PakVendorItem } from 'types/RoadNetwork';
import ModalForm from './ModalForm';

interface ItemProps {
    item: PakVendorItem
    reloadList: () => void
}

const Item = ({ item, reloadList }: ItemProps) => {
    const dispatch = useDispatch();
    const  loadingButton  = useSelector(roadNetworksSelectors.loadingButton);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        const callback = () => {
            setIsDelete(false);
            reloadList();
        };

        dispatch(deletePakVendor(item.id, callback));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item.is_system ? 'Да' : 'Нет'}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setIsDelete(true),
                                disabled: item.is_system,
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    onClose={()=> setIsDelete(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                    loadingButton={loadingButton}
                />
            )}

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}

        </>
    );
};

export default Item;
