import React, { useEffect } from 'react';
import * as actions from '../../../../redux/FocusIncidents/actions';
import ConfirmDelete from '../../../common/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../helpers/constants/messages';
import { focusIncidentsSelectors } from '../../../../redux/FocusIncidents';
import ModalForm from '../../../pages/Dictionaries/Incidents/DtpConcentrationArea/ModalForm';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();

    const deleteForm = useSelector(focusIncidentsSelectors.deleteForm);
    const editForm = useSelector(focusIncidentsSelectors.editForm);
    const saved = useSelector(focusIncidentsSelectors.saved);

    useEffect(() => {
        saved && dispatch(actions.saveDtpFocus(false));
    }, [saved]);

    return (
        <>
            {/* форма редактирования */}
            {!!editForm && (
                <ModalForm
                    isOpen={!!editForm}
                    isNew={!editForm?.id}
                    item={editForm}
                    onClose={() => {
                        dispatch(actions.setEditForm());
                    }}
                    loadList={() => {
                        dispatch(actions.saveDtpFocus(true));
                    }}
                />
            )}

            {/* удаление */}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!deleteForm}
                onSuccess={() => {
                    dispatch(actions.deleteDtpFocus(deleteForm));
                    dispatch(actions.setDeleteForm());
                }}
                onClose={() => {
                    dispatch(actions.setDeleteForm());
                }}
            />
        </>
    );
};

export default CollectorForms;
