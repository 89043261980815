import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import * as actions from 'redux/RoadNetwork/actions';
import { roadNetworksSelectors } from 'redux/RoadNetwork';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import renderAddress from 'helpers/renderAddress';
import { getHumanDate } from 'helpers/date.config';
import colorExtend from 'helpers/mapHelper/colorExtend';
import Information from 'components/common/Information';
import RoadSectionRelationList from 'components/common/RoadSectionRelationList';

import config from '../config';
import { getIcon } from '../helper';


const PopUp = (props) => {
    const { id = 0 } = props?.data || {};

    const polygonRoadSection = useSelector(roadNetworksSelectors.polygonRoadSection);
    const types = useStoreProp(actions.loadRoadSectionTypes, 'roadNetworks', 'roadSectionTypes');
    const categories = useStoreProp(actions.loadRoadSectionCategories, 'roadNetworks', 'roadSectionCategories');
    const statuses = useStoreProp(actions.loadStatusesRoadSection, 'roadNetworks', 'statusesRoadSection');

    // забираем данные из полигона
    const data = useMemo(() => polygonRoadSection
        ?.find(item => item.id === id)
        || {}
    , [id, polygonRoadSection]);

    // статус объекта
    const currentStatus = useMemo(() => statuses
        ?.find(({ id }) => id === data?.status)
        || {}
    , [statuses, data?.status]);

    // выводимые поля
    const fieldsObj = {
        name: {
            title: titles.NAME,
            value: ({ name }) => name || messages.INFO_IS_NOT_FOUND,
        },
        status: {
            title: titles.STATUS,
            value: () => currentStatus?.name || messages.INFO_IS_NOT_FOUND,
        },
        category: {
            title: titles.CATEGORY,
            value: ({ category }) => categories[category] || messages.INFO_IS_NOT_FOUND,
        },
        type: {
            title: titles.TYPE,
            value: ({ type }) => types[type],
        },
        organization: {
            title: titles.ORGANIZATION,
            value: ({ organization }) => organization?.title,
        },
        address: {
            title: 'Адрес дислокации',
            value: ({ address }) => renderAddress(address),
        },
        created_at: {
            title: 'Дата создания',
            value: ({ created_at }) => getHumanDate(created_at),
        },
        fixated_at: {
            title: 'Дата регистрации',
            value: ({ fixated_at }) => getHumanDate(fixated_at),
        },
        description: {
            title: 'Описание',
            value: ({ description }) => description,
        },
        // перечень присвоенных объектов с указанием подзаголовков (типов объектов)
    };

    return (
        <div className="map-popup">
            <div
                className="map-popup_header"
                style={{
                    backgroundColor: data?.color
                        ? colorExtend(data?.color)
                        : config.mapMarkersColors.default,
                }}
            >
                <div>
                    <div className="img" dangerouslySetInnerHTML={{ __html: getIcon(data.category) }}>
                        {/*<i className="far fa-road"/>*/}
                    </div>
                    <span>
                        {data?.name || ''}
                    </span>
                </div>
            </div>
            <div className="map-popup_body">
                <Scrollbars
                    style={{ height: '200px' }}
                >
                    <Information
                        title={fieldsObj}
                        data={data}
                        returnNull
                        defaultValue={messages.INFO_IS_NOT_FOUND}
                    />
                    <RoadSectionRelationList roadSectionId={data?.id} isMap/>
                </Scrollbars>
            </div>
        </div>
    );
};

export default PopUp;
