import React, { useState } from 'react';
import {
    IconButton,
    TextField,
    Tooltip
} from '@mui/material';
import SingleKeyboardDateTimePicker from '../../../common/Dates/SingleKeyboardDateTimePicker';
import Attachments from '../../../common/Upload/Attachments';
import titles from '../../../../helpers/constants/titles';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmDelete from '../../../common/ConfirmModal';
import messages from '../../../../helpers/constants/messages';
import { fullDateTimeWithTimeZone } from '../../../../helpers/date.config';

const DocumentItem = (
    {
        item,
        handleChangeItem,
        onSetError,
        handleRemoveItemFromArray,
    }) => {
    const { title, date, author, file } = item;
    const [isDeleteItem, setIsDeleteItem] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        handleChangeItem({
            ...item,
            [name]: value
        });
    };

    const handleChangeDate = (value) => {
        handleChangeItem({
            ...item,
            date: value ? fullDateTimeWithTimeZone(value) : null
        });
    };

    const handleChangeDocuments = (value) => {
        handleChangeItem({
            ...item,
            file: value
        });
    };

    const confirmDeleteItem = () => {
        handleRemoveItemFromArray();
        setIsDeleteItem(false);
    };

    return (
        <div className="row align-items-center" style={{ backgroundColor: '#d9d9d982', padding: '1rem' }}>
            <div className="block row__item">
                <div className="row">
                    <TextField
                        required
                        value={title || ''}
                        name="title"
                        onChange={handleChange}
                        className="row__item"
                        label="Название документа"
                        variant="outlined"
                        size="small"
                        error={!title}
                    />
                </div>
                <div className="row">
                    <TextField
                        value={author || ''}
                        name={'author'}
                        onChange={handleChange}
                        className="row__item"
                        label="Автор"
                        variant="outlined"
                        size="small"
                    />
                    <SingleKeyboardDateTimePicker
                        label="Дата"
                        onChange={handleChangeDate}
                        value={date || null}
                        isDisabledHandler={onSetError}
                        dateOnly={true}
                    />
                </div>
                <div>
                    <Attachments
                        singleFile={true}
                        onChange={handleChangeDocuments}
                        service="transport-passenger"
                        onlyLink={false}
                        isRequired
                        files={file ? [file] : []}
                        label={titles.ATTACH_FILE}
                    />
                </div>
            </div>
            <Tooltip title={titles.DELETE_DOCUMENT}>
                <IconButton onClick={() => setIsDeleteItem(true)} size="small">
                    <CloseIcon />
                </IconButton>
            </Tooltip>
            {isDeleteItem && (
                <ConfirmDelete
                    open={isDeleteItem}
                    onClose={()=> setIsDeleteItem(false)}
                    onSuccess={confirmDeleteItem}
                    message={messages.CONFIRM_DELETE}
                />
            )}
        </div>
    );
};

export default DocumentItem;