import apiAdminUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiAdminMethods = {
    getDtpStatus: async () => {
        const response = await instance.get(apiAdminUrls.getDtpStatus());
        return response.data;
    },
    getMeteoStatus: async () => {
        const response = await instance.get(apiAdminUrls.getMeteoStatus());
        return response.data;
    },
    getCameraStatus: async () => {
        const response = await instance.get(apiAdminUrls.getCameraStatus());
        return response.data;
    },
    getRouteStatus: async () => {
        const response = await instance.get(apiAdminUrls.getRouteStatus());
        return response.data;
    },
    getVehiclesStatus: async () => {
        const response = await instance.get(apiAdminUrls.getVehiclesStatus());
        return response.data;
    },

    getUsers: async (params) => {
        const response = await instance.get(apiAdminUrls.getUsers(), { params });
        return response?.data;
    },

    getRoles: async (page, limit, name) => {
        const response = await instance.get(apiAdminUrls.getRoles(), { params: { page, limit, ...name } } );
        return response?.data;
    },

    registerUser: async (params) => {
        const response = await instance.post(apiAdminUrls.registerUser(), params);
        return response?.data;
    },

    createUser: async (params) => {
        const response = await instance.post(apiAdminUrls.createUser(), params);
        return response?.data;
    },

    editUser: async (params) => {
        const response = await instance.put(apiAdminUrls.editUser(params.id), params);
        return response?.data;
    },

    deleteUser: async (id) => {
        const response = await instance.delete(apiAdminUrls.deleteUser(id));
        return response?.data;
    },

    getPermissions: async (params) => {
        const response = await instance.get(apiAdminUrls.getPermissions(), { params: params });
        return response?.data;
    },

    createRole: async (params) => {
        const response = await instance.post(apiAdminUrls.createRole(), params);
        return response?.data;
    },

    editRole: async (params) => {
        const response = await instance.put(apiAdminUrls.editRole(params.id), params);
        return response?.data;
    },

    deleteRole: async (id) => {
        const response = await instance.delete(apiAdminUrls.deleteRole(id));
        return response?.data;
    },

    getDtpSettings: async (page, limit) => {
        const response = await instance.get(apiAdminUrls.getDtpSettings(), {
            params: { page, limit }
        });
        return response.data;
    },

    editDtpSettings: async (data) => {
        const response = await instance.put(apiAdminUrls.editDtpSettings(), data);
        return response.data;
    },

    getCurrentUser: async (id) => {
        const response = await instance.get(apiAdminUrls.getCurrentUser(id));
        return response?.data;
    },
    
    resetPassword: async (params) => {
        const response = await instance.post(apiAdminUrls.resetPassword(), params);
        return response?.data;
    },

    getSettings: async (page, limit, data) => {
        const response = await instance.get(apiAdminUrls.getSettings(), {
            params: { page, limit, ...data }
        });
        return response.data;
    },

    editSetting: async ({ id, params }) => {
        const response = await instance.put(apiAdminUrls.editSetting(id), params);
        return response?.data;
    },

    editSettingsList: async (data) => {
        const response = await instance.put(apiAdminUrls.editSettingsList(), data);
        return response?.data;
    },

    getUserActivity: async ({ params }) => {
        const response = await instance.get(apiAdminUrls.getUserActivity(), { params });
        return response?.data;
    },

    getUserActivityReport: async ({ params }) => {
        const response = await instance.get(apiAdminUrls.getUserActivityReport(), { params });
        return response?.data;
    },

    getMethods: async () => {
        const response = await instance.get(apiAdminUrls.getMethods());
        return response?.data;
    },

    getMicroservices: async () => {
        const response = await instance.get(apiAdminUrls.getMicroservices());
        return response?.data;
    },

    getActions: async () => {
        const response = await instance.get(apiAdminUrls.getActions());
        return response?.data;
    },

    getSettingsGroup: async () => {
        const response = await instance.get(apiAdminUrls.getSettingsGroup());
        return response?.data;
    },

    editSettingsGroup: async (id, data) => {
        const response = await instance.put(apiAdminUrls.editSettingsGroup(id), data);
        return response?.data;
    },
};

export default ApiAdminMethods;
