import React, { useState } from 'react';
import {
    InputAdornment,
    IconButton,
    TextField,
} from '@mui/material/';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import titles from '../../../../helpers/constants/titles';

const RenderPasswords = ({ password = '', password_confirmation = '', onChange, validation = {} }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

    return (
        <div className="row">
            <TextField
                className="row__item"
                size="small"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                value={password}
                name="password"
                onChange={onChange}
                error={!!validation.get('password')}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={'end'}>
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge={'end'} size="large">
                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                label={titles.PASSWORD}
                helperText={'Пароль должен быть от 9 до 30 символов в нем должны содержаться минимум символ, цифра, большие и маленькие буквы.'}
            />
            <TextField
                className="row__item"
                size="small"
                variant="outlined"
                type={showPasswordConfirmation ? 'text' : 'password'}
                value={password_confirmation}
                name={'password_confirmation'}
                onChange={onChange}
                error={!!validation.get('password_confirmation')}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={'end'}>
                            <IconButton
                                onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                                edge={'end'}
                                size="large">
                                {showPasswordConfirmation ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                label={titles.REPEAT_PASSWORD}
                helperText={validation.get('password_confirmation')}
            />
        </div>
    );
};

export default RenderPasswords;