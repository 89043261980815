import { config } from '../../config';

const apiCompanies = () => config.get('urls').apiCompanies;

const apiDadataUrl = () => config.get('urls').apiDadataUrl;

const apiUrls = {

    getCompanies: () => `${apiCompanies()}/organization`,

    getCompaniesForAllRoles: () => `${apiCompanies()}/organizations`,

    getOrganization: (id) => `${apiCompanies()}/organization/${id}`,

    getStandardizedCompanies: () => `${apiDadataUrl()}/its/companies`,

    createCompany: () => `${apiCompanies()}/organization`,

    editCompany: (id) => `${apiCompanies()}/organization/${id}`,

    deleteCompany: (id) => `${apiCompanies()}/organization/${id}`,

    getCurrentCompany: (id) => `${apiCompanies()}/organization/${id}`,

    getOrganizationsReport: () => `${apiCompanies()}/organization`,

    getFiliationList: () => `${apiCompanies()}/filiation`,

    createFiliationList: () => `${apiCompanies()}/filiation`,

    editFiliation: (id) => `${apiCompanies()}/filiation/${id}`,

    getCompanyRoleList: () => `${apiCompanies()}/role/`,

    createCompanyRole: () => `${apiCompanies()}/role`,

    editCompanyRole: (id) => `${apiCompanies()}/role/${id}`,

    deleteCompanyRole: (id) => `${apiCompanies()}/role/${id}`,

    createRolesContracts: () => `${apiCompanies()}/role/contracts/upload`,

    changeRolesContracts: () => `${apiCompanies()}/role/contracts/multiple-update`,

    deleteRolesContracts: () => `${apiCompanies()}/role/contracts/by_organization`,

    loadShowCase: () => `${apiCompanies()}/showcase`,
};

export default apiUrls;
