import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, FormHelperText } from '@mui/material';
import titles from '../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../FormButtons';
import Modal from '../../../Modal';
import { createTag, loadTagList } from '../../../../../redux/DorisControl/actions';

const AddNewTag = ({
    isOpen,
    onClose,
}) => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [isLength, setIsLength] = useState(false);

    const postNewTag = () => {
        dispatch(createTag({ name }, () => dispatch(loadTagList({ page: 1, limit: 10 }))));
        onClose();
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setIsLength(value.length < 3 && value.length > 0);
        setName(value);
    };

    const isDisabled = (!name.trim() || isLength);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title={titles.ADD}
            small={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.add,
                        onClick: postNewTag,
                        disabled: isDisabled
                    },
                ]}
            />}
        >
            <form className="modal__form">
                <TextField
                    label={titles.TAG}
                    size="small"
                    value={name}
                    variant="outlined"
                    type="text"
                    onChange={handleChange}
                    required
                />
                {isLength
                    ? <FormHelperText error>
                        {'Должно быть больше 3 символов'}
                    </FormHelperText>
                    : ''}
            </form>
        </Modal>
    );
};

export default AddNewTag;
