export const trafficLights = (state) => state.trafficLights;

// export const lightsStatuses = (state) => trafficLights(state).lightsStatuses;
export const loadingLightList = (state) => trafficLights(state).loadingLightList;
// export const lightStates = (state) => trafficLights(state).lightStates;
// export const lightControlTypes = (state) => trafficLights(state).lightControlTypes;

export const lightList = (state) => trafficLights(state).lightList;
export const lightListData = (state) => lightList(state).data;
export const lightListMeta = (state) => lightList(state).meta;

export const sidebar = (state) => trafficLights(state).sidebar;
export const sidebarLoading = (state) => trafficLights(state).sidebarLoading;
export const filters = (state) => trafficLights(state).filters;
export const polygon = (state) => trafficLights(state).polygon;
export const saved = (state) => trafficLights(state).saved;
export const active = (state) => trafficLights(state).active;
export const deleteForm = (state) => trafficLights(state).deleteForm;
export const editForm = (state) => trafficLights(state).editForm;
// export const coordinationGroups = (state) => trafficLights(state).coordinationGroups;
// export const coordinationGroupsLoading = (state) => trafficLights(state).coordinationGroupsLoading;
// export const coordinationGroupsPolygon = (state) => trafficLights(state).coordinationGroupsPolygon;
// export const greenStreets = (state) => trafficLights(state).greenStreets;
// export const greenStreetsLoading = (state) => trafficLights(state).greenStreetsLoading;
// export const greenStreetsPolygon = (state) => trafficLights(state).greenStreetsPolygon;
export const showGSAndCG = (state) => trafficLights(state).showGSAndCG;
// export const wsFilter = (state) => trafficLights(state).wsFilter;
export const wsData = (state) => trafficLights(state).wsData;
// export const colorsForLegends = (state) => trafficLights(state).colorsForLegends;

export const showcase = (state) => trafficLights(state).showcase;
export const loadingShowcase = (state) => trafficLights(state).loadingShowcase;

export const loadingButton = (state) => trafficLights(state).loadingButton;