import { useMemo, memo } from 'react';
import colorExtend from 'helpers/mapHelper/colorExtend';

const LegendsList = ({ category }) => {

    const list = useMemo(() => (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
            }}
        >
            {category.map(({ name, color, status }) => (
                <div
                    key={status}
                    className="flex"
                    style={{
                        width: '200px',
                        alignItems: 'start',
                    }}
                >
                    <div
                        className="image"
                        style={{
                            height: 'auto',
                            width: 'auto',
                            alignItems: 'top',
                        }}
                    >
                        <i
                            className="fas fa-digging"
                            style={{
                                fontSize: '1rem',
                                color: colorExtend(color)
                            }}
                        />
                    </div>
                    <div className="small">
                        - {name}
                    </div>
                </div>
            ))}
        </div>
    ), [category]);

    return (
        <div className="sidebar-tp-legend">
            <div className="item">
                <div className="title">
                    Статусы
                </div>
                {list}
            </div>
        </div>
    );
};

export default memo(LegendsList);
