import React from 'react';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeIcon from '@mui/icons-material/Home';

const DistrictSwitch = ({ 
    value, 
    onChange, 
    title1 = 'По районам города', 
    title2 = 'По районам области', 
    disabled  
}) => {
    return (
        <ButtonGroup disableElevation variant="contained" size="small">
            <Tooltip title={title1}>
                <Button  onClick={() => onChange(false)} color={value ? 'inherit' : 'primary'} disabled={disabled} >
                    <ApartmentIcon />
                </Button>
            </Tooltip>
            <Tooltip title={title2}>
                <Button  onClick={() => onChange(true)} color={value ? 'primary' : 'inherit'} disabled={disabled}>
                    <HomeIcon />
                </Button>
            </Tooltip>
        </ButtonGroup>
    );
};

export default DistrictSwitch;