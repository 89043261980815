import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../redux/Reports/actions';
import { Grid } from '@mui/material';
import Item from './Item';
import messages from '../../../../helpers/constants/messages';
import styles from './styles.module.scss';
import FormButtonsComponent, { buttonsTypes } from '../../../common/FormButtons';
import PageLayout from '../../../layout/PageLayout';
import buttons from '../../../../helpers/constants/buttons';
import ConfirmModal from '../../../common/ConfirmModal';
import { authSelectors } from '../../../../redux/Auth';
import Loading from '../../../common/Loading';

const MyReports = () => {
    const dispatch = useDispatch();

    const user = useSelector(authSelectors.authUserInfo);
    const { list, loading, loadingButton } = useSelector(({ reports }) => reports);

    const [openDeleteAllModal, setOpenDeleteAllModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1 });

    const handleDelete = (id) => {
        dispatch(actions.deleteReportById(id, () => {
            const listMoo = list?.data?.length === 1;
            const newPage = params?.page > 1 && listMoo ? params?.page - 1 : params?.page;
            setParams(old => ({
                ...old,
                page: newPage,
            }));
            (newPage === params?.page) && reloadList();
        }));
    };

    const handleDeleteAll = () => {
        dispatch(actions.deleteAllReports(user?.id, () => {
            reloadList();
            setOpenDeleteAllModal(false);
        }));
    };

    const reloadList = useCallback(() => {
        dispatch(actions.loadList(params.page, limit));
    }, [dispatch, limit, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    const renderContent = () => {

        if (loading) return <Loading circular/>;

        return list?.data?.length
            ? (
                <Grid
                    className={styles.container}
                    container
                    rowSpacing={3}
                    columnSpacing={1}
                >
                    {list.data.map(item => (
                        <Item
                            key={item.id}
                            item={item}
                            onDelete={handleDelete}
                        />
                    ))}
                </Grid>
            )
            : !loading && (
                <div className="flex-center" style={{ height: '100%' }}>
                    {messages.DATA_IS_NOT_FOUND}
                </div>
            );
    };
    
    return (
        <>
            <PageLayout
                header="Мои отчеты"
                content={() => renderContent()}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[
                                    {
                                        ...buttonsTypes.deleteAll,
                                        color: 'infoButton',
                                        onClick: () => setOpenDeleteAllModal(true),
                                        name: `✕ ${buttons.DELETE_ALL}`,
                                        disabled: loading || list?.meta?.total === 0
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: list?.meta?.total
                }}
                paginationProps={{
                    loadList: (page) => setParams(old => ({ ...old, page })),
                    list: list?.meta,
                    limit,
                    setLimit,
                }}
            />

            {openDeleteAllModal && (
                <ConfirmModal
                    open={openDeleteAllModal}
                    onClose={() => setOpenDeleteAllModal(false)}
                    onSuccess={handleDeleteAll}
                    loadingButton={loadingButton}
                    message="Вы действительно хотите удалить все отчеты?"
                />
            )}
        </>
    );
};

export default MyReports;
