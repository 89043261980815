import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';
import L from 'leaflet';

import * as actions from 'redux/Boards/actions';
import { boardsSelectors } from 'redux/Boards';
import Context from 'helpers/context';
import mapHelper from 'helpers/mapHelper';
import { useDebounce, usePrevious, useStoreFromSelector } from 'helpers/hooks';
import { useWsSubscribe } from 'helpers/ws/hooks';
import colorExtend from 'helpers/mapHelper/colorExtend';
import MapLegends from 'components/common/Transport/MapLegends';
import {
    ContextMenuItem,
    GeoJson,
} from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';

import config from './config.js';
import { createIconMarker } from './helper';
import CPopup from './PopUp';
import CollectorForms from './CollectorForms';
import Legend from './Legend';


const Layer = (props) => {
    const { map, readOnly = false, visibleLegend = false } = props;
    const dispatch = useDispatch();
    const polygon = useSelector(boardsSelectors.polygon);
    const polygonLoading = useSelector(boardsSelectors.polygonLoading);
    const active = useSelector(boardsSelectors.active);
    const saved = useSelector(boardsSelectors.saved);
    const filters = useSelector(boardsSelectors.filters);

    const statuses = useStoreFromSelector(actions.loadBoardStatuses, boardsSelectors.boardStatuses);
    const statusesObj = useMemo(() => statuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [statuses]);

    useWsSubscribe('dit_board_update_model_v2', (events) => {
        // todo
        // console.log('dit_board_update', events);
        dispatch(actions.setWsDit(events));
    });

    const { permissions } = useContext(Context);

    const [geoJson, setGeoJson] = useState([]);
    const showPopUp = useRef(false);
    const prevFilters = usePrevious(filters) || {};
    const prevPolygonLoading = usePrevious(polygonLoading) || false;
    const idPrefix = 'board';
    const loadingPolygon = useRef(true);

    const history = useHistory();

    useEffect(() => {
        if (saved) {
            map.closeContextMenu();
            fetchPolygon();
        }
    }, [saved]);

    // грузим полигон
    const fetchPolygon = () => {
        if (loadingPolygon.current) {
            const polygon = mapHelper.getPolygon(map);
            const filter = getFilters(filters);
            dispatch(actions.getForPolygon(polygon, filter));
        }
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 200);
    const handleFetchPolygon = () => loadingPolygon.current && debounceFetchPolygon();

    // добавить новый
    const handleAdd = ({ lat, lng }) => {
        dispatch(actions.setEditForm({
            // формируем по кликнутой области объект
            lat,
            lon: lng,
            geometry: {
                type: 'Point',
                coordinates: [
                    lng,
                    lat
                ]
            }
        }));
    };

    useEffect(() => {
        fetchPolygon();

        return () => {
            dispatch(actions.resetActive());
        };
    }, []);

    useEffect(() => {
        if (polygonLoading === false && prevPolygonLoading === true && showPopUp.current) {
            const id = showPopUp.current;
            setTimeout(() => {
                map.fire(`showBy${idPrefix}${id}`);
            }, 100);

            showPopUp.current = false;
        }

    }, [polygonLoading, prevPolygonLoading]);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        // setMapToMarker();

        return () => {
            dispatch(actions.setActive({}));
            dispatch(actions.clearForPolygon());
            map.closeContextMenu();
            //map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters, prevFilters]);

    // добавление нового элемента с карты
    useEffect(() => {
        dispatch(actions.setSaved());
        map.on(config.mapContextMenu.event, (e) => {
            //map.fire('context_menu_close');
            handleAdd(e.latlng);
        });

        return () => {
            map.off(config.mapContextMenu.event);
        };
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { geometry } = active;

            if (geometry) {
                showPopUp.current = active.id;
                const b = L.geoJSON(geometry).getBounds();
                map.fitBounds(b);
            }

            // setTimeout(() => map.fire(`showBy${active.id}`), 500);
        }
    }, [active]);

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProp }) => {
        return (
            <div>
                {permissions.is_update
                    && <ContextMenuItem
                        {...rcmProp}
                        value="Редактировать"
                        onClick={() => {
                            //map.fire('context_menu_close');
                            dispatch(actions.setEditForm(item));
                        }}
                    />
                }
                {permissions.is_delete
                    && <ContextMenuItem
                        {...rcmProp}
                        value="Удалить"
                        onClick={() => {
                        //map.fire('context_menu_close');
                            dispatch(actions.setDeleteForm(item.id));
                        }}
                        className="red"
                    />
                }
            </div>
        );
    };

    useEffect(() => {
        const geoJson = polygon.reduce((res, { geometry, ...props }) => {
            const color = colorExtend(statusesObj[props.status]?.color || '');
            return [
                ...res,
                {
                    ...geometry,
                    properties: {
                        ...geometry.properties,
                        data: {
                            ...props,
                            geometry,
                            id: props.id,
                            statusColor: color,
                        },
                        attribution: {
                            slug: config.slug,
                            color
                        },
                    },
                    style: {
                        color,
                        weight: 7
                    }
                }
            ];
        }, []);

        setGeoJson(geoJson);
    }, [polygon]);

    return (
        <>
            <GeoJson
                {...props}
                data={geoJson}
                icon={({ statusColor, category_id }) => createIconMarker(statusColor, category_id)}
                popUpTemplate={({ id }) => <CPopup id={id} history={history} permissions={permissions} readOnly={readOnly} />}
                {...(!readOnly
                    ? {
                        contextMenuTemplate: (item, data) => <RenderContextMenu item={item} {...data} />
                    }
                    : {})}
                toolTipTemplate={({ name }) => <div>{name}</div>}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, 0],
                    sticky: true,
                }}
                onClosePopup={() => {
                    dispatch(actions.resetActive());
                }}
                idPrefix={idPrefix}
                centerByClick
                centerByClickBefore={() => {
                    loadingPolygon.current = false;
                }}
                centerByClickAfter={() => {
                    setTimeout(() => {
                        loadingPolygon.current = true;
                    }, 800);
                }}
            />
            <CollectorForms />

            <MapLegends
                layer={config.slug}
                visibleRequired={visibleLegend}
            >
                <Legend/>
            </MapLegends>
        </>
    );
};

export default Layer;
