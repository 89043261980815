import { useRef, useState } from 'react';

// debounce для запросов
const useDebounce = (fn, wait = 1000) => {
    const time = useRef(null);

    // метод очистить
    const clear = () => {
        if (time.current) {
            clearTimeout(time.current);
            time.current = null;
        }
    };

    const execute = (props) => {
        if (fn) fn(props);
    };

    const mainFunction = (props) => {
        clear();
        time.current = setTimeout(() => {
            execute(props);
            time.current = null;
        }, wait);
    };

    mainFunction.clear = () => clear();

    return mainFunction;
};

// const useDebounce = (fn, wait = 1000) => {
//     const [time, setTime] = useState(null);
//
//     const clear = () => {
//         if (time) {
//             clearTimeout(time);
//             setTime(null);
//         }
//     };
//
//     const execute = (props) => {
//         if (fn) fn(props);
//     };
//
//     const mainFunction = (props) => {
//         clear();
//         setTime(setTimeout(() => {
//             execute(props);
//             setTime(null);
//         }, wait));
//     };
//
//     mainFunction.clear = () => clear();
//
//     return mainFunction;
// };

export default useDebounce;
