import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';

import { createNewRW, getStatusesRW, loadAllSites } from 'redux/RoadWorks/actions';
import { useStoreProp } from 'helpers/hooks';
import buttons from 'helpers/constants/buttons';
import EditorForm from 'components/pages/Dictionaries/RoadWorks/Sites/EditorForm';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import UniversalSelect from 'components/common/UniversalSelect/index-new';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import Item from './Item';


const SelectRoadWorks = ({
    roadWorkList = [],
    onRoadWorkChange = () => {},
    error = false,
    helperText = '',
    multiple = false,
    required = false,
    disabled = false,
    label = 'Участки работ',
    withCreate = false,
    searchField = 'address',
    loadListAction,
    storeName = 'roadworks',
    storeLoadingProps = 'loading',
    withSearch = true, 
    filter = {},
    renderOption,
    limitTags = 1, // количество элементов в строке при свернутом автокомплите
    readOnly = false,
}) => {
    const dispatch = useDispatch();

    const initialState = multiple ? [] : {}; // TODO переделать на массив всегда
    const [showList, setShowList] = useState(false);
    const [addRWOpen, setAddRWOpen] = useState(false);
    const [currentItemGeometry, setCurrentItemGeometry] = useState(null);
    const [list, setList] = useState([]);
    const listLoading = useSelector((state) =>
        storeLoadingProps ? state[storeName][storeLoadingProps] : null
    );

    const statuses = useStoreProp(getStatusesRW, 'roadworks', 'statuses');

    const getRoadWorksList = (params) => {
        const query = params?.query;
        const loadList = loadListAction || loadAllSites;
        dispatch(loadList({ 
            ...(query ? { [searchField]: query } : {}),
            ...filter 
        }, (data) => setList(data)));
    };

    const reloadRwList = () => {
        setAddRWOpen(false);
        getRoadWorksList();
    };

    const handleShowMap = (item) => {
        setCurrentItemGeometry(currentItemGeometry ? null : item?.data?.geometry);
    };

    const handleClose = () => {
        setShowList(false);
        setList([]);
    };

    const handleAccept = (data) => {
        onRoadWorkChange(data);
        setShowList(false);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={roadWorkList}
                onChange={onRoadWorkChange}
                error={error}
                helperText = {helperText}
                required={required}
                inputName="works"
                disabled={disabled}
                onReset={() => onRoadWorkChange(initialState)}
                renderLabel={(option) => ((renderOption && renderOption(option))) || option?.address_text || ''}
                limitTags={limitTags}
                label={label}
                onShowList={() => !disabled && setShowList(true)}
                readOnly={readOnly}
                isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                }
                filterSelectedOptions
                isOpen={showList}
            />
            
            {showList
                && <UniversalSelect
                    list={list}
                    multiple={multiple}
                    searchTitle="Найти по адресу"
                    fetchList={getRoadWorksList}
                    loading={listLoading}
                    keyProp="id"
                    withSearch={withSearch}
                    selected={roadWorkList}
                    renderProps={(item) => <Item item={item} status={statuses[item.status]}/>}
                    renderIconProps={(item) => (
                        <Tooltip title={buttons.SHOW_ON_MAP}>
                            <IconButton onClick={() => handleShowMap(item)} size="small">
                                <i className="fas fa-map-marked"/>
                            </IconButton>
                        </Tooltip>
                    )}
                    buttons={
                        withCreate 
                            && <FormButtons
                                positionLeft
                                noPadding
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setAddRWOpen(true),
                                        title: 'Добавить новый участок работ',
                                    }
                                ]}
                            />
                    }
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title="Выберите участок работ"
                />
            }
            {addRWOpen && withCreate
                && <EditorForm
                    isOpen={addRWOpen}
                    isNew
                    onSuccess={(newFields) =>
                        dispatch(createNewRW(newFields, reloadRwList))
                    }
                    inModal
                    onClose={() => setAddRWOpen(false)}
                />
            }
            {currentItemGeometry && (
                <Modal
                    isOpen={!!currentItemGeometry}
                    onClose={handleShowMap}
                    noPadding
                    title="Участки работ"
                    buttons={
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: handleShowMap
                                },
                            ]}
                        />
                    }
                >
                    <MapGeoJson
                        geometry={currentItemGeometry}
                        readOnly
                    />
                </Modal>
            )}
        </>
    );
};

export default SelectRoadWorks;
