import att from '../../../../../img/icons/board/att.png';
import childs from '../../../../../img/icons/board/childs.png';
import cloud from '../../../../../img/icons/board/cloud.png';
import cloudsun from '../../../../../img/icons/board/cloudsun.png';
import overtaking from '../../../../../img/icons/board/overtaking.png';
import pedestrian from '../../../../../img/icons/board/pedestrian.png';
import rain from '../../../../../img/icons/board/rain.png';
import roadworks from '../../../../../img/icons/board/roadworks.png';
import slippy from '../../../../../img/icons/board/slippy.png';
import snow from '../../../../../img/icons/board/snow.png';
import snowrain from '../../../../../img/icons/board/snowrain.png';
import speed20 from '../../../../../img/icons/board/speed20.png';
import speed30 from '../../../../../img/icons/board/speed30.png';
import speed40 from '../../../../../img/icons/board/speed40.png';
import speed50 from '../../../../../img/icons/board/speed50.png';
import speed60 from '../../../../../img/icons/board/speed60.png';
import speed70 from '../../../../../img/icons/board/speed70.png';
import speed80 from '../../../../../img/icons/board/speed80.png';
import speed90 from '../../../../../img/icons/board/speed90.png';
import speed100 from '../../../../../img/icons/board/speed100.png';
import speed110 from '../../../../../img/icons/board/speed110.png';
import storm from '../../../../../img/icons/board/storm.png';
import sun from '../../../../../img/icons/board/sun.png';

export const signsList = {
    att: {
        img: att
    },
    childs: {
        img: childs
    },
    cloud: {
        img: cloud
    },
    cloudsun: {
        img: cloudsun
    },
    overtaking: {
        img: overtaking
    },
    pedestrian: {
        img: pedestrian
    },
    rain: {
        img: rain
    },
    roadworks: {
        img: roadworks
    },
    slippy: {
        img: slippy
    },
    snow: {
        img: snow
    },
    snowrain: {
        img: snowrain
    },
    speed20: {
        img: speed20
    },
    speed30: {
        img: speed30
    },
    speed40: {
        img: speed40
    },
    speed50: {
        img: speed50
    },
    speed60: {
        img: speed60
    },
    speed70: {
        img: speed70
    },
    speed80: {
        img: speed80
    },
    speed90: {
        img: speed90
    },
    speed100: {
        img: speed100
    },
    speed110: {
        img: speed110
    },
    storm: {
        img: storm
    },
    sun: {
        img: sun
    },
};