import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadAllComplexes } from 'redux/DorisControl/actions';
import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect/index-new';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const SelectComplex = ({
    multiple = false,
    selected = multiple ? [] : {},
    onChange = (value) => {},
    error = false,
    helperText = '',
    required = false,
    disabled = false,
    label = 'Комплексы',
    filter = {},
    searchKey = 'name',
    searchTitle = '',
    initialState = multiple ? [] : {},
    limitTags = 1, // количество элементов в строке при свернутом автокомплите
    sortedList = [],
    canReset = true, // на случай если нам надо отобразить в инпуте список без крестика - то false
    readOnly = false, // если надо просто посмотрет список
    withSearch = true,
    className = '',
}) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [list, setList] = useState(sortedList);
    const listLoading = useSelector(({ dorisControl }) =>
        dorisControl.loading_complexes
    );

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    const getComplex = (params) => {
        const { query } = params;

        dispatch(loadAllComplexes({
            ...(query && { [searchKey]: query }),
            ...filter,
            simple: 1
        }, (data) => setList(data)));
    };

    return <>
        <CommonAutocomplete
            multiple={multiple}
            selected={selected}
            onChange={onChange}
            error={error}
            helperText = {helperText}
            required={required}
            inputName="complex_id"
            disabled={disabled}
            onReset={canReset ? () => onChange(initialState) : undefined}
            renderLabel={(option) => option?.serial_number || ''}
            limitTags={limitTags}
            label={readOnly ? '' : label}
            onShowList={() => setShowList(true)}
            readOnly={readOnly}
            className={className}
            isOpen={showList}
        />

        {showList
            && <UniversalSelect
                list={list}
                multiple={multiple}
                fetchList={getComplex}
                sortedList={sortedList}
                loading={listLoading}
                keyProp="id"
                withSearch={withSearch}
                selected={selected}
                renderProps={(el) => <div>
                    Название: {el.name},<br/>
                    Серийный номер: {el.serial_number},<br/>
                    Адрес: {el.address_text}
                </div>}
                isOpen={showList}
                onClose={() => {
                    setShowList(false);
                    setList([]);
                }}
                onAccept={handleAccept}
                noPadding
                title={label || titles.SELECT_COMPLEX}
                searchTitle={searchTitle}
                readOnly={readOnly}
            />
        }
    </>;
};

export default SelectComplex;
