import React from 'react';
import {
    TextField,
} from '@mui/material';

export const LineSpacing = ({ lineSpacing, handleChange, required, error, helperText }) => {
    return (
        <TextField
            value={lineSpacing}
            onChange={handleChange}
            label="Межстрочный интервал (pt)"
            name="line-spacing"
            error={!lineSpacing || error}
            required={required}
            className="row__item"
            type="number"
            size="small"
            helperText={helperText}
        />
    );
};