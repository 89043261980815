import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';
import { centrifugeResponse } from './helper';

const ApiRequestMethods = {
    // список каналов
    getWsChannels: async () => {
        const response = await instance.get(apiUrls.getWsChannels());
        return response?.data;
    },

    // токен для соединения
    getWsToken: async () => {
        const response = await instance.get(apiUrls.getWsToken());
        if (response?.status === 200 && response.data.success) {
            return response.data;
        }
        centrifugeResponse(response);
    },

    // токен для канала
    getWsTokenSubscribe: async (channel) => {
        const response = await instance.get(apiUrls.getWsTokenSubscribe(channel));
        if (response?.status === 200 && response.data.success) {
            return response?.data;
        }
        centrifugeResponse(response);
    },
};

export default ApiRequestMethods;
