import React from 'react';
import './style.scss';

// контейнер списка
const Container = ({
    children,
    style = {},
}) => (
    <div className="list-statistic-container" style={style}>
        {children}
    </div>
);

export default Container;