import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { IconButton, Tooltip } from '@mui/material';
import titles from '../../../../../../../../helpers/constants/titles';

interface UploadImageProps {
    onFileUpload: (base64: string) => void
}

const UploadImage = ({ onFileUpload }: UploadImageProps) => {
    const onDrop = useCallback((files) => {
        if (files.length) {
            const { type } = files[0];

            const reader = new FileReader();
            const blobFile = new Blob(files, { type });

            reader.onloadend = () => {
                const base64 = reader.result;

                if (base64 && typeof base64 === 'string') {
                    onFileUpload(base64);
                }
            };

            reader.readAsDataURL(blobFile);
        }
    }, [onFileUpload]);

    const dropzoneParams = { onDrop, multiple: false, accept: ['.png', '.jpg'] };

    const { getRootProps, getInputProps, open } = useDropzone(dropzoneParams);

    return (
        <div {...getRootProps()} onClick={e => e.stopPropagation()}>
            <input {...getInputProps()} />
            <Tooltip title={titles.ATTACH_IMG_FILE}>
                <IconButton size="small" onClick={open}>
                    <i className="far fa-image"/>
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default UploadImage;
