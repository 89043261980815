import React, { useState } from 'react';
import { Collapse } from '@mui/material';
import messages from '../../../../../helpers/constants/messages';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import CurrentRouteVehicle from './CurrentRouteVehicle';
import Loading from '../../../../common/Loading';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';

const useStyles = makeStyles({
    wrap: {
        padding: '7px 70px 35px 70px',
        maxHeight: 200,
        flexDirection: 'column',
        display: 'flex',
        flexWrap: 'wrap',
        width: 'fit-content'
    },
    noData: {
        padding: '12px 70px 35px 70px'
    }
});

const RouteVehicleList = ({ isOpen, itemId, currentVehiclesList }) => {
    const classes = useStyles();
    const currentVehiclesListLoading = useSelector( transportPassengerSelectors.currentVehiclesListLoading);

    const [openWindow, setOpenWindow] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleShowInfo = (event, i) => {
        setAnchorEl(event.currentTarget);
        if (!openWindow) {
            setOpenWindow(i);
        }
        if (openWindow) {
            setOpenWindow(false);
        }
    };

    const onCloseInfo = () => {
        setOpenWindow(false);
        setAnchorEl(null);
    };

    const id = openWindow ? 'opened' : undefined;

    return (
        <Collapse in={isOpen}>
            {!currentVehiclesList && currentVehiclesListLoading && <Loading linear={true}/>}
            {currentVehiclesList?.length > 0
                ? (<div className={classes.wrap} onMouseLeave={onCloseInfo}>
                    {currentVehiclesList.map((vehicle, index) => (
                        <CurrentRouteVehicle
                            itemId={itemId}
                            id={id}
                            key={vehicle.uuid}
                            vehicle={vehicle}
                            openWindow={openWindow === index}
                            anchorEl={anchorEl}
                            handleShowInfo={(e) => handleShowInfo(e, index)}
                        />
                    ))}
                </div>)
                : (!currentVehiclesListLoading && <div className={classes.noData}>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </Collapse>
    );
};

export default RouteVehicleList;
