import { Collapse } from '@mui/material';
import RenderDuploCodesItem from '../../../../common/DorisControl/RenderDuploCodesItem';
import { InfoProps } from './types';



const Info = ({ isOpen, item }: InfoProps) => {
    const { duplo_codes = [] } = item;
    return (
        <Collapse in={isOpen} timeout={'auto'} className="info" >
            {duplo_codes.map((item) => (
                <RenderDuploCodesItem
                    key={item.id}
                    item={item}
                />
            ))}
        </Collapse>
    );
};

export default Info;
