import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import messages from 'helpers/constants/messages';
import styles from './vaGraph.module.scss';

// рендер таблички с макс и мин значениями по направлениям
const RenderHeader = ({ el, group, type, renderColor }) => {
    if (el.directions?.length > 0) {

        const findMaxValue = (values = []) => {
            const key = group?.findMaxKey;
            const valArray = values.map(item =>  item[key]);
            const max = Math.max(...valArray);
            return max;
        };
        const findMinValue = (values = []) => {
            const key = group?.findMinKey;
            const valArray = values.map(item =>  item[key]);
            const min = Math.min(...valArray);
            return min;
        };
        const findDate = (data, val) => {
            const obj = data.find(item => {
                // приводим всё к типу string
                const newVals = item.values?.map(el => el.toString());
                return newVals?.includes(val);
            }) || {};
            return obj?.date ? type?.format(obj?.date) : messages.NO_DATA;
        };

        return(
            <>
                <TableContainer className={styles.table}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Направление</TableCell>
                                <TableCell align="right">{group?.maxTitle}</TableCell>
                                <TableCell className={styles.tableBorder} align="right">Зафиксировано</TableCell>
                                <TableCell align="right">{group?.minTitle}</TableCell>
                                <TableCell className={styles.tableBorder} align="right">Зафиксировано</TableCell>
                                <TableCell align="right">{group?.totalTitle}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {el.directions?.map((direction, i) => {
                                const maxValue = findMaxValue(direction.values);
                                const minValue = findMinValue(direction.values);
                                const maxDate = findDate(direction.fullData, maxValue.toString());
                                const minDate = findDate(direction.fullData, minValue.toString());
                                return (
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">
                                            {direction.label || 'Не указано'}
                                        </TableCell>
                                        <TableCell align="right">
                                            {maxValue} {direction.units}
                                        </TableCell>
                                        <TableCell className={styles.tableBorder} align="right">
                                            <span style={{ color: renderColor(maxDate) }}>{maxDate}</span>
                                        </TableCell>
                                        <TableCell align="right">
                                            {minValue} {direction.units}
                                        </TableCell>
                                        <TableCell className={styles.tableBorder} align="right">
                                            <span style={{ color: renderColor(minDate) }}>{minDate}</span>
                                        </TableCell>
                                        <TableCell align="right">
                                            {direction.all} {direction.units}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }
    return <div className={styles.title}>По указанным параметрам данные отсутствуют</div>;
};

export default RenderHeader;
