import * as types from './types';

const initialState = {
    loadingVideoCameraList: false,
    videoCameraList: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    videoCameraStatuses: [],
    videoCameraTypes: {},
    sidebar: {
        data: [],
    },
    sidebarLoading: false,
    // полигон
    polygon: [],
    // фильтры
    filters: {},
    filtersGroup: {},
    // активный
    active: {},
    // данные для удаления
    deleteForm: false,
    // данные для редактирования
    editForm: false,
    videoCameraFrame: {},
    cameraGroup: { data: [] },
    loadingCameraGroup: false,
    loadingFrame: false,
    activeFrames: [],
    saved: false,
    savedCamera: false,
    cameraTemplateList: {},
    loadingCameraTemplateList: false,
    videocamerasByRadius: []
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADING_VIDEO_CAMERAS_LIST:
            return {
                ...state,
                loadingVideoCameraList: payload
            };
        case types.LOADED_VIDEO_CAMERAS_LIST:
            return {
                ...state,
                videoCameraList: payload
            };
        case types.LOADED_STATUSES_VIDEO_CAMERA:
            return {
                ...state,
                videoCameraStatuses: payload
            };
        case types.LOADED_TYPES_VIDEO_CAMERA:
            return {
                ...state,
                videoCameraTypes: payload
            };
        case types.SAVE_POLYGON: {
            const newData = Object.keys(payload || {}).reduce((res, item) => ([
                ...res,
                payload[item],
            ]), []);
            return {
                ...state,
                polygon: newData
            };
        }
        case types.LOADED_SIDEBAR: {
            const {
                data,
                replace,
            } = payload;

            if (replace) {
                return {
                    ...state,
                    sidebar: data,
                };
            }

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...data,
                    data: [
                        ...state.sidebar.data,
                        ...data.data,
                    ],
                },
            };
        }

        case types.LOADING_SIDEBAR:
            return {
                ...state,
                sidebarLoading: payload,
            };
        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }
        case types.SET_FILTER: {
            return {
                ...state,
                filters: payload,
            };
        }
        case types.SET_FILTER_GROUP: {
            return {
                ...state,
                filtersGroup: payload,
            };
        }
        case types.SET_DELETE_FORM: {
            return {
                ...state,
                deleteForm: payload,
            };
        }
        case types.SET_EDIT_FORM: {
            return {
                ...state,
                editForm: payload,
            };
        }
        case types.SAVED: {
            return {
                ...state,
                saved: payload,
            };
        }
        case types.SAVED_CAMERA: {
            return {
                ...state,
                savedCamera: payload,
            };
        }
        case types.LOADING_VIDEO_CAMERA_FRAME: {
            return {
                ...state,
                loadingFrame: payload
            };
        }
        case types.LOADED_VIDEO_CAMERA_FRAME: {
            // добавляем недостающие ссылки на урлы в активных фреймах
            const updateActiveFrames = state.activeFrames.map((item) => {
                if (payload[item.id]?.link && !item.link) {
                    return {
                        ...item,
                        link: payload[item.id]?.link,
                    };
                }
                return item;
            });
            
            return {
                ...state,
                videoCameraFrame: {
                    ...state.videoCameraFrame,
                    ...payload,
                },
                activeFrames: updateActiveFrames
            };
        }
        case types.LOAD_ACTIVE_FRAME: {
            return {
                ...state,
                activeFrames: payload
            };
        }
        case types.LOADING_CAMERA_GROUP_LIST:
            return {
                ...state,
                loadingCameraGroup: payload
            };
        case types.LOADED_CAMERA_GROUP_LIST:
            const {
                data,
                replace,
            } = payload;

            if (replace) {
                return {
                    ...state,
                    cameraGroup: data,
                };
            }
            return {
                ...state,
                cameraGroup:  {
                    ...state.cameraGroup,
                    ...data,
                    data: [
                        ...state.cameraGroup.data,
                        ...data.data,
                    ],
                },
            };
        case types.LOADED_CAMERA_TEMPLATE_LIST: {
            return {
                ...state,
                cameraTemplateList: payload
            };
        }
        case types.LOADING_CAMERA_TEMPLATE_LIST: {
            return {
                ...state,
                loadingCameraTemplateList: payload
            };
        }
        case types.LOADED_VIDEOCAMERAS_BY_RADIUS: {
            return {
                ...state,
                videocamerasByRadius: payload
            };
        }
        default:
            return state;
    }
}
