import Attachments from '../../../../../../common/Upload/Attachments';


const Materials = ({ fields, onChange }) => {

    const files = fields.materials.map(item => item.storage);

    const handleChange = ([file]) => {
        const currentfile = file ? [{ storage: file }] : [];
        onChange({ materials: currentfile });
    };

    return (
        <div className="modal__form">
            <div className="block">
                <Attachments
                    label={'Документы'}
                    files={files || []}
                    onChange={handleChange}
                    service={'dtp'}
                />
            </div>
        </div>
    );    
};

export default Materials;
