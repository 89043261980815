import React, { useState } from 'react';
import { TextField } from '@mui/material';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import titles from 'helpers/constants/titles';
import { FilterProps, FilterState } from './types';
import removeEmptyFields from 'helpers/removeEmptyFields';


const Filter  = ({ setParams }: FilterProps) => {
    const initState: FilterState = { name: '', simple: 1 };
    const [filter, setFilter] = useState<FilterState>(initState);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFilter(prev => ({ ...prev, [name]: value }));
    };

    const resetFilter = (needRefresh: boolean) => {
        if (needRefresh) {
            setParams({ page: 1, data: { simple: 1 } });
        }
        setFilter(initState);
    };

    const search = () => {
        setParams({ page: 1, data: removeEmptyFields(filter) });
    };

    const updateFilter = (filters: FilterState) => {
        const newFilter = { ...initState, ...filters };
        setFilter(newFilter);
        if (Object.keys(filters).length > 0) {
            setParams({ page: 1, data: newFilter });
        }
    };

    return (
        <LayoutFilter
            filter={filter}
            onResetFilter={resetFilter}
            onSearch={search}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    label={titles.NAME}
                    value={filter.name}
                    onChange={onChange}
                    name="name"
                    variant="outlined"
                    size="small"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
