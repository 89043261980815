import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiMethods = {
    getPolygon: async (polygon, filter) => {
        const response = await instance.post(apiUrls.getPolygon(), {
            polygon,
            ...filter,
        });
        return response.data;
    },
    getSidebar: async (params) => {
        const response = await instance.get(apiUrls.getSidebar(), {
            params
        });
        return response?.data;
    },

    createPark: async (data) => {
        const response = await instance.post(apiUrls.createPark(), data);
        return response?.data;
    },

    editPark: async (id, data) => {
        const response = await instance.put(apiUrls.editPark(id), data);
        return response?.data;
    },

    deletePark: async (id) => {
        const response = await instance.delete(apiUrls.deletePark(id));
        return response?.data;
    },

    getStatuses: async () => {
        const response = await instance.get(apiUrls.getStatuses());
        return response?.data;
    },

    getTypes: async () => {
        const response = await instance.get(apiUrls.getTypes());
        return response?.data;
    },

    getZones: async () => {
        const response = await instance.get(apiUrls.getZones());
        return response?.data;
    },

    getStatusStatistic: async () => {
        const response = await instance.get(apiUrls.getStatusStatistic());
        return response?.data;
    },

    getCategory: async (params) => {
        const response = await instance.get(apiUrls.getCategory(), {
            params
        });
        return response?.data;
    },

    getTariff: async (params) => {
        const response = await instance.get(apiUrls.getTariff(), {
            params
        });
        return response?.data;
    },
};

export default ApiMethods;
