import React from 'react';
import { useSelector } from 'react-redux';
import Filter from './Filter';
import { dorisControlSelectors } from 'redux/DorisControl';
import PageLayout from 'components/layout/PageLayout/index';


const ComplexNames = () => {
    const loading: boolean = useSelector(dorisControlSelectors.report1CNamesLoading);

    return (
        <PageLayout
            header={'Ведомость по комплексам'}
            filters={<Filter />}
            loading={loading}
        />
    );
};

export default ComplexNames;
