import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiTransportPassengerMethods = {
    getStationList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getStationList(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    getStationSchedule: async (data) => {
        const response = await instance.get(apiUrls.getStationSchedule(), {
            params: { ...data },
        });
        return response.data;
    },

    getRouteTypes: async () => {
        const response = await instance.get(apiUrls.getRouteTypes());
        return response.data;
    },

    getBrandList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getBrandList(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    createBrand: async (params) => {
        const response = await instance.post(apiUrls.createBrand(), params);
        return response?.data;
    },

    editBrand: async (id, data) => {
        const response = await instance.put(apiUrls.editBrand(id), data);
        return response?.data;
    },

    deleteBrand: async (id) => {
        const response = await instance.delete(apiUrls.editBrand(id));
        return response?.data;
    },

    getVehicleList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getVehicleList(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    getVehiclesNoWorkorder: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getVehiclesNoWorkorder(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    editVehicle: async (id, data) => {
        const response = await instance.put(apiUrls.editVehicle(id), data);
        return response?.data;
    },

    deleteVehicle: async (id) => {
        const response = await instance.delete(apiUrls.deleteVehicle(id));
        return response?.data;
    },

    createNewVehicle: async (data) => {
        const response = await instance.post(apiUrls.createNewVehicle(), data);
        return response?.data;
    },

    getCategories: async () => {
        const response = await instance.get(apiUrls.getCategories());
        return response.data;
    },

    getVehicleCategoryList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getCategories(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    createVehicleCategory: async (data) => {
        const response = await instance.post(apiUrls.createCategory(), data);
        return response?.data;
    },

    editVehicleCategory: async (id, data) => {
        const response = await instance.put(apiUrls.editCategory(id), data);
        return response?.data;
    },

    deleteVehicleCategory: async (id) => {
        const response = await instance.delete(apiUrls.deleteCategory(id));
        return response?.data;
    },

    getTypes: async () => {
        const response = await instance.get(apiUrls.getTypes());
        return response.data;
    },

    getModelList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getModelList(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    createModel: async (payload) => {
        const response = await instance.post(apiUrls.createModel(), payload);
        return response?.data;
    },

    editModel: async (id, data) => {
        const response = await instance.put(apiUrls.editModel(id), data);
        return response?.data;
    },

    deleteModel: async (id) => {
        const response = await instance.delete(apiUrls.editModel(id));
        return response?.data;
    },

    getCompanyList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getCompanyList(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    createCompany: async (params) => {
        const response = await instance.post(apiUrls.createCompany(), params);
        return response?.data;
    },

    editCompany: async (params) => {
        const response = await instance.put(apiUrls.editCompany(params.uuid), params);
        return response?.data;
    },

    deleteCompany: async (id) => {
        const response = await instance.delete(apiUrls.editCompany(id));
        return response?.data;
    },

    getCompanies: async (page, limit, data) => { // TODO сервис dadata вынести в отдельный модуль
        const response = await instance.get(apiUrls.getCompanies(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    getRouteGroup: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getRouteGroup(), {
            params: { page, limit, ...data }
        });
        return response.data;
    },

    getRouteNums: async () => {
        const response = await instance.get(apiUrls.getRouteNums());
        return response.data;
    },

    getRouteStatuses: async () => {
        const response = await instance.get(apiUrls.getRouteStatuses());
        return response?.data;
    },

    getRouteTransportations: async () => {
        const response = await instance.get(apiUrls.getRouteTransportations());
        return response.data;
    },

    saveRoutes: async (data) => {
        const response = await instance.post(apiUrls.saveRoutes(), data);
        return response.data;
    },

    createRoute: async (data) => {
        const response = await instance.post(apiUrls.createRoute(), data);
        return response.data;
    },

    changeRoute: async (uuid, data) => {
        const response = await instance.put(apiUrls.changeRoute(uuid), data);
        return response.data;
    },

    createRouteV2: async (data) => {
        const response = await instance.post(apiUrls.createRouteV2(), data);
        return response.data;
    },

    changeRouteV2: async (uuid, data) => {
        const response = await instance.put(apiUrls.changeRouteV2(uuid), data);
        return response.data;
    },

    getRouteSchedule: async (data) => {
        const response = await instance.get(apiUrls.getRouteSchedule(), {
            params: { ...data }
        });
        return response.data;
    },

    getRoutes: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getRoutes(), {
            params: { page, limit, ...data }
        });
        return response.data;
    },

    getScheduleTypes: async  () => {
        const response = await instance.get(apiUrls.getScheduleTypes());
        return response.data;
    },

    editStation: async (id, data) => {
        const response = await instance.put(apiUrls.editStation(id), data);
        return response.data;
    },

    createNewStation: async (data) => {
        const response = await instance.post(apiUrls.createNewStation(), data);
        return response.data;
    },

    addSchedule: async (data) => {
        const response = await instance.post(apiUrls.addSchedule(), data);
        return response.data;
    },

    editSchedule: async (data) => {
        const response = await instance.put(apiUrls.editSchedule(), data);
        return response.data;
    },

    deleteSchedule: async (data) => {
        const response = await instance.delete(apiUrls.editSchedule(), {
            data
        });
        return response.data;
    },

    getOrganizationReport: async (params = {}) => {
        const response = await instance.get(apiUrls.getOrganizationReport(), {
            params,
            responseType: 'blob',
        });
        return response?.data;
    },

    getVehicleReport: async (params = {}) => {
        const response = await instance.get(apiUrls.getVehicleReport(), {
            params,
            responseType: 'blob'
        });
        return response?.data;
    },

    getVehicleReportList: async (config = {}) => {
        const response = await instance.get(apiUrls.getVehicleReport(), config);
        return response?.data;
    },

    // getCurrentPassengerReport: async (id, params) => {
    //     const response = await instance.get(apiUrls.getCurrentPassengerReport(id), {
    //         params,
    //         // responseType: 'blob'
    //     });
    //     return response?.data;
    // },

    getCurrentSpecialReport: async (uid, params) => {
        const response = await instance.get(apiUrls.getCurrentSpecialReport(uid), {
            params,
            responseType: 'blob'
        });
        return response?.data;
    },

    getNextStation: async (data) => {
        const response = await instance.get(apiUrls.getNextStation(), {
            params: { ...data }
        });
        return response.data;
    },

    getVehiclePopup: async (data) => {
        const response = await instance.get(apiUrls.getVehiclePopup(), {
            params: { ...data }
        });
        return response.data;
    },

    getVehicleHistory: async ({ id, params }) => {
        const response = await instance.get(apiUrls.getVehicleHistory(id), {
            params: { ...params }
        });
        return response.data;
    },

    getVehicleFlightHistory: async ({ id, params }) => {
        const response = await instance.get(apiUrls.getVehicleFlightHistory(id), { params });
        return response.data;
    },

    getCurrentVehicles: async (id) => {
        const response = await instance.get(apiUrls.getCurrentVehicles(id));
        return response?.data;
    },

    getCurrentVehiclesType: async (page, limit, query, category_id) => {
        const response = await instance.get(apiUrls.getCurrentVehiclesType(), {
            params: { page, limit, query, category_id }
        });
        return response?.data;
    },

    saveSelectedVehicleToRoute: async (data) => {
        const response = await instance.put(apiUrls.saveSelectedVehicleToRoute(), data);
        return response?.data;
    },

    getRoutes_: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getRoutes(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    getRouteStation: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getRouteStation(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    getPositions: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getPositions(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    addPositions: async (data) => {
        const response = await instance.post(apiUrls.addPositions(), data);
        return response?.data;
    },

    editPositions: async (id, data) => {
        const response = await instance.put(apiUrls.editPositions(id), data);
        return response?.data;
    },

    deletePositions: async (id) => {
        const response = await instance.delete(apiUrls.deletePositions(id));
        return response?.data;
    },

    getScheduleEvent: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getScheduleEvent(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    addScheduleEvent: async (data) => {
        const response = await instance.post(apiUrls.addScheduleEvent(), data);
        return response?.data;
    },

    editScheduleEvent: async (id, data) => {
        const response = await instance.put(apiUrls.editScheduleEvent(id), data);
        return response?.data;
    },

    deleteScheduleEvent: async (id) => {
        const response = await instance.delete(apiUrls.deleteScheduleEvent(id));
        return response?.data;
    },

    getEmployees: async (config = {}) => {
        const response = await instance.get(apiUrls.getEmployees(), config);
        return response?.data;
    },

    addEmployees: async (data) => {
        const response = await instance.post(apiUrls.addEmployees(), data);
        return response?.data;
    },

    editEmployees: async (id, data) => {
        const response = await instance.put(apiUrls.editEmployees(id), data);
        return response?.data;
    },

    deleteEmployees: async (id) => {
        const response = await instance.delete(apiUrls.deleteEmployees(id));
        return response?.data;
    },

    getBrigades: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getBrigades(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    addBrigades: async (data) => {
        const response = await instance.post(apiUrls.addBrigades(), data);
        return response?.data;
    },

    editBrigades: async (id, data) => {
        const response = await instance.put(apiUrls.editBrigades(id), data);
        return response?.data;
    },

    deleteBrigades: async (id) => {
        const response = await instance.delete(apiUrls.deleteBrigades(id));
        return response?.data;
    },

    getBrigadesNoWorkorder: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getBrigadesNoWorkorder(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },
    // Equipments
    getEquipments: async () => {
        const response = await instance.get(apiUrls.getEquipments());
        return response?.data;
    },


    getParks: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getParks(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    postPark: async (data) => {
        const response = await instance.post(apiUrls.postPark(), data);
        return response?.data;
    },

    putPark: async (id, data) => {
        const response = await instance.put(apiUrls.putPark(id), data);
        return response?.data;
    },

    deletePark: async (id) => {
        const response = await instance.delete(apiUrls.deletePark(id));
        return response?.data;
    },

    getStationTypes: async () => {
        const response = await instance.get(apiUrls.getStationTypes());
        return response?.data;
    },

    deleteStation: async (id) => {
        const response = await instance.delete(apiUrls.deleteStation(id));
        return response?.data;
    },

    // SCHEDULE_TEMPLATE
    getScheduleTemplateList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getScheduleTemplateList(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    getScheduleTemplateStatuses: async () => {
        const response = await instance.get(apiUrls.getScheduleTemplateStatuses());
        return response?.data;
    },

    createScheduleTemplate: async (data) => {
        const response = await instance.post(apiUrls.createScheduleTemplate(), data);
        return response?.data;
    },

    editScheduleTemplate: async (id, data) => {
        const response = await instance.put(apiUrls.editScheduleTemplate(id), data);
        return response?.data;
    },

    deleteScheduleTemplate: async (id) => {
        const response = await instance.delete(apiUrls.deleteScheduleTemplate(id));
        return response?.data;
    },

    saveRouteStations: async (data) => {
        const response = await instance.post(apiUrls.saveRouteStations(), data);
        return response?.data;
    },

    getIntersectionRoutes: async (data) => {
        const response = await instance.post(apiUrls.getIntersectionRoutes(), data);
        return response?.data;
    },

    getWorkorders: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getWorkorders(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    getWorkorderStatuses: async () => {
        const response = await instance.get(apiUrls.getWorkorderStatuses());
        return response?.data;
    },

    getWorkordersByDate: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getWorkordersByDate(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    getScheduleTemplateStationsList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getScheduleTemplateStationsList(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    createScheduleTemplateStation: async (data) => {
        const response = await instance.post(apiUrls.createScheduleTemplateStation(), data);
        return response?.data;
    },

    saveWorkorder: async (data) => {
        const response = await instance.post(apiUrls.getWorkorders(), data);
        return response?.data;
    },

    editWorkorder: async (id, data) => {
        const response = await instance.put(apiUrls.editWorkorders(id), data);
        return response?.data;
    },

    editWorkorderStatus: async (id, data) => {
        const response = await instance.put(apiUrls.editWorkorderStatus(id), data);
        return response?.data;
    },

    deleteWorkorder: async (id) => {
        const response = await instance.delete(apiUrls.editWorkorders(id));
        return response?.data;
    },

    getTrafficSchedule: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getTrafficSchedule(), {
            params: {
                ...(page ? { page } : {}), // на случай запроса без пагинации
                ...(limit ? { limit } : {}),
                ...data,
                is_simple: data.is_simple ?? 1
            },
        });
        return response?.data;
    },

    getTrafficScheduleNoWorkorder: async (data) => {
        const response = await instance.get(apiUrls.getTrafficScheduleNoWorkorder(), {
            params: { ...data }
        });
        return response?.data;
    },

    deleteScheduleTemplateStation: async (id) => {
        const response = await instance.delete(apiUrls.deleteScheduleTemplateStation(id));
        return response?.data;
    },

    editScheduleTemplateStation: async (id, data) => {
        const response = await instance.put(apiUrls.editScheduleTemplateStation(id), data);
        return response?.data;
    },

    getTrafficScheduleStatuses: async () => {
        const response = await instance.get(apiUrls.getTrafficScheduleStatuses());
        return response?.data;
    },

    createTrafficSchedule: async (data) => {
        const response = await instance.post(apiUrls.createTrafficSchedule(), data);
        return response?.data;
    },

    copyTrafficSchedule: async ({ id, data }) => {
        const response = await instance.post(apiUrls.copyTrafficSchedule(id), data);
        return response?.data;
    },

    getTrafficScheduleTypes: async () => {
        const response = await instance.get(apiUrls.getTrafficScheduleTypes());
        return response?.data;
    },

    getCurrentTrafficSchedule: async (id) => {
        const response = await instance.get(apiUrls.getCurrentTrafficSchedule(id));
        return response?.data;
    },

    getAppeal: async (config) => {
        const response = await instance.get(apiUrls.getAppeal(), config);
        return response.data;
    },

    createAppeal: async (params) => {
        const response = await instance.post(apiUrls.createAppeal(), params);
        return response?.data;
    },

    editAppeal: async (id, data) => {
        const response = await instance.put(apiUrls.editAppeal(id), data);
        return response?.data;
    },

    deleteAppeal: async (id) => {
        const response = await instance.delete(apiUrls.deleteAppeal(id));
        return response?.data;
    },

    getAppealStatus: async () => {
        const response = await instance.get(apiUrls.getAppealStatus());
        return response?.data;
    },

    getAppealType: async () => {
        const response = await instance.get(apiUrls.getAppealType());
        return response?.data;
    },

    getRouteCheckpoints: async (id) => {
        const response = await instance.get(apiUrls.getRouteCheckpoints(id));
        return response?.data;
    },

    getNearStation: async (data) => {
        const response = await instance.post(apiUrls.getNearStation(), { ...data });
        return response?.data;
    },

    saveRouteCheckpoints: async (id, params) => {
        const response = await instance.post(apiUrls.saveRouteCheckpoints(id), params);
        return response?.data;
    },
    loadRouteWithCheckPoints: async (config) => {
        const response = await instance.get(apiUrls.loadRouteWithCheckPoints(), config );
        return response?.data;
    },

    getWorkorderEvents: async (id) => {
        const response = await instance.get(apiUrls.getWorkorderEvents(id));
        return response?.data;
    },

    getWorkorderEventStatuses: async () => {
        const response = await instance.get(apiUrls.getWorkorderEventStatuses());
        return response?.data;
    },

    getRouteRequestsList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getRouteRequestsList(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    getStatusesRouteRequests: async () => {
        const response = await instance.get(apiUrls.getStatusesRouteRequests());
        return response?.data;
    },

    getCaptcha: async () => {
        const response = await instance.get(apiUrls.getCaptcha());
        return response?.data;
    },

    createRouteRequest: async (data) => {
        const response = await instance.post(apiUrls.createRouteRequest(), data);
        return response?.data;
    },

    editRouteRequest: async (id, data) => {
        const response = await instance.put(apiUrls.editRouteRequest(id), data);
        return response?.data;
    },

    deleteRouteRequest: async (id) => {
        const response = await instance.delete(apiUrls.deleteRouteRequest(id));
        return response?.data;
    },

    loadStatusesRouteRequests: async () => {
        const response = await instance.get(apiUrls.loadStatusesRouteRequests());
        return response?.data;
    },

    putTrafficScheduleStatus: async (id, data) => {
        const response = await instance.put(apiUrls.putTrafficScheduleStatus(id), data);
        return response?.data;
    },

    fetchSchedule: async (page, limit, data) => {
        const response = await instance.get(apiUrls.fetchSchedule(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    postSchedule: async (data) => {
        const response = await instance.post(apiUrls.postSchedule(), data);
        return response?.data;
    },

    fetchScheduleStatuses: async () => {
        const response = await instance.get(apiUrls.fetchScheduleStatuses());
        return response?.data;
    },

    putSchedule: async (id, data) => {
        const response = await instance.put(apiUrls.putSchedule(id), data);
        return response?.data;
    },

    delSchedule: async (id) => {
        const response = await instance.delete(apiUrls.delSchedule(id));
        return response?.data;
    },

    fetchScheduleEvents: async (id, data) => {
        const response = await instance.get(apiUrls.fetchScheduleEvents(id), data);
        return response?.data;
    },

    putScheduleStatus: async (id, statusId) => {
        const response = await instance.put(apiUrls.putScheduleStatus(id, statusId));
        return response?.data;
    },

    getScheduleTemplateCheckpoints: async (id) => {
        const response = await instance.get(apiUrls.getScheduleTemplateCheckpoints(id));
        return response?.data;
    },

    saveScheduleTemplateCheckpoints: async (id, params) => {
        const response = await instance.post(apiUrls.saveScheduleTemplateCheckpoints(id), params);
        return response?.data;
    },


    saveTransportationTariff: async (id, data) => {
        const response = await instance.post(apiUrls.saveTransportationTariff(id), data);
        return response?.data;
    },

    getTicketPoints: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getTicketPoints(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    createTicketPoint: async (data) => {
        const response = await instance.post(apiUrls.getTicketPoints(), data);
        return response?.data;
    },

    editTicketPoint: async (id, data) => {
        const response = await instance.put(apiUrls.editTicketPoint(id), data);
        return response?.data;
    },

    deleteTicketPoint: async (id) => {
        const response = await instance.delete(apiUrls.editTicketPoint(id));
        return response?.data;
    },

    getTicketPointTypes: async () => {
        const response = await instance.get(apiUrls.getTicketPointTypes());
        return response?.data;
    },

    getWaybillsList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getWaybillsList(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    postWaybills: async (data) => {
        const response = await instance.post(apiUrls.postWaybills(), data);
        return response?.data;
    },

    editWaybill: async (id, data) => {
        const response = await instance.put(apiUrls.editWaybill(id), data);
        return response?.data;
    },

    deleteWaybill: async (id) => {
        const response = await instance.delete(apiUrls.deleteWaybill(id));
        return response?.data;
    },

    getWaybill: async (id) => {
        const response = await instance.get(apiUrls.getWaybill(id),{
            responseType: 'blob'
        });
        return response?.data;
    },

    getStationAttributes: async () => {
        const response = await instance.get(apiUrls.getStationAttributes());
        return response?.data;
    },

    getTariffs: async (page, limit, query, type) => {
        const response = await instance.get(apiUrls.getTariffs(), {
            params: { page, limit, ...query, ...type }
        });
        return response?.data;
    },

    postTariff: async (data) => {
        const response = await instance.post(apiUrls.postTariff(), data);
        return response?.data;
    },

    putTariff: async (id, data) => {
        const response = await instance.put(apiUrls.putTariff(id), data);
        return response?.data;
    },

    deleteTariff: async (id) => {
        const response = await instance.delete(apiUrls.deleteTariff(id));
        return response?.data;
    },

    getVehicleCard: async (id, data) => {
        const response = await instance.get(apiUrls.getVehicleCard(id), data);
        return response?.data;
    },

    getWaybillsLicence: async () => {
        const response = await instance.get(apiUrls.getWaybillsLicence());
        return response?.data;
    },

    getTrafficScheduleListOfEvents: async () => {
        const response = await instance.get(apiUrls.getTrafficScheduleListOfEvents());
        return response?.data;
    },

    editTrafficSchedule: async (id, data) => {
        const response = await instance.put(apiUrls.editTrafficSchedule(id), data, { timeout: 60000 });
        return response?.data;
    },

    getCoords: async (params) => {
        const response = await instance.get(apiUrls.getCoords(), { params });
        return response?.data;
    },

    getVehicle: async (egtsYandex) => {
        const response = await instance.get(apiUrls.getVehicle(), {
            params: {
                serial_egts: egtsYandex,
                serial_yandex: egtsYandex,
                page: 1,
                limit: 1
            }
        });
        return response?.data;
    },

    getReportSummarySchedule: async (id, data) => {
        const response = await instance.get(apiUrls.getReportSummarySchedule(id), data);
        return response?.data;
    },

    loadOrganizationInformation: async (config) => {
        const response = await instance.get(apiUrls.loadOrganizationInformation(), config);
        return response?.data;
    },

    getVehicleTelemetryStatistic: async () => {
        const response = await instance.get(apiUrls.getVehicleTelemetryStatistic());
        return response?.data;
    },

    getTelemetryStatistic: async () => {
        const response = await instance.get(apiUrls.getTelemetryStatistic());
        return response?.data;
    },

    getWorkordersReport: async (params) => {
        const response = await instance.get(apiUrls.getWorkordersReport(), params);
        return response?.data;
    },

    getWoRequisiteChartererTypes: async (params) => {
        const response = await instance.get(apiUrls.getWoRequisiteChartererTypes(), { params });
        return response?.data;
    },

    getWoRequisiteList: async (params) => {
        const response = await instance.get(apiUrls.getWoRequisiteList(), { params });
        return response?.data;
    },

    createWoRequisite: async (data) => {
        const response = await instance.post(apiUrls.createWoRequisite(), data);
        return response?.data;
    },

    editWoRequisite: async (id, data) => {
        const response = await instance.put(apiUrls.editWoRequisite(id), data);
        return response?.data;
    },

    deleteWoRequisite: async (id) => {
        const response = await instance.delete(apiUrls.deleteWoRequisite(id) );
        return response?.data;
    },

    getRadioType: async (params) => {
        const response = await instance.get(apiUrls.getRadioType(), { params });
        return response?.data;
    },

    getWorkorderEventManualStatus: async () => {
        const response = await instance.get(apiUrls.getWorkorderEventManualStatus());
        return response?.data;
    },

    getWorkorderEventFlightManualStatus: async () => {
        const response = await instance.get(apiUrls.getWorkorderEventFlightManualStatus());
        return response?.data;
    },

    createWorkorderEventStatus: async (id, data) => {
        const response = await instance.post(apiUrls.createWorkorderEventStatus(id), data);
        return response?.data;
    },

    updateWorkorderEventStatus: async (id, data) => {
        const response = await instance.put(apiUrls.updateWorkorderEventStatus(id), data);
        return response?.data;
    },

    deleteWorkorderEventStatus: async (id) => {
        const response = await instance.delete(apiUrls.deleteWorkorderEventStatus(id));
        return response?.data;
    },

    getFlightDefectGroup: async () => {
        const response = await instance.get(apiUrls.getFlightDefectGroup());
        return response?.data;
    },

    getFlightDefects: async (params) => {
        const response = await instance.get(apiUrls.getFlightDefects(), { params });
        return response?.data;
    },

    getReportByFlights: async (config) => {
        const response = await instance.get(apiUrls.getReportByFlights(), config);
        return response?.data;
    },

    getReportByVehicles: async (config) => {
        const response = await instance.post(apiUrls.getReportByVehicles(), config);
        return response?.data;
    },
    getEmployeesTransportationReport: async (params) => {
        const response = await instance.get(apiUrls.getEmployeesTransportationReport(), params);
        return response?.data;
    },
    copyScheduleTemplate: async ({ template, data }) => {
        const response = await instance.post(apiUrls.copyScheduleTemplate(template.id), { name: data.name });
        return response?.data;
    },

    getFlightTypes: async () => {
        const response = await instance.get(apiUrls.getFlightTypes());
        return response.data;
    },

    editFlightType: async (id, data) => {
        const response = await instance.put(apiUrls.editFlightType(id), data);
        return response.data;
    },

    getSpeedViolationsReport: async (config) => {
        const response = await instance.get(apiUrls.getSpeedViolationsReport(), config);
        return response?.data;
    },

    getSettingsGroup: async () => {
        const response = await instance.get(apiUrls.getSettingsGroup());
        return response?.data;
    },

    editSettingsGroup: async (id, data) => {
        const response = await instance.put(apiUrls.editSettingsGroup(id), data);
        return response?.data;
    },

    getTransportSettings: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getTransportSettings(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    editTransportSetting: async (id, data) => {
        const response = await instance.put(apiUrls.editTransportSetting(id), data);
        return response?.data;
    },

    editSettingsList: async (data) => {
        const response = await instance.put(apiUrls.editSettingsList(), data);
        return response?.data;
    },

    getPassengersCount: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getPassengersCount(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    getSidebar: async (params) => {
        const response = await instance.get(apiUrls.getSidebar(), {
            params,
        });
        return response?.data;
    },

    getTemplate: async (config = {}) => {
        const response = await instance.get(apiUrls.getTemplate(), config);
        return response?.data;
    },

    getTransorganizations: async (page, limit, params) => {
        const response = await instance.get(apiUrls.getTransorganizations(), {
            params: { page, limit, ...params }
        });
        return response?.data;
    },

    editTransorganization: async (id, data) => {
        const response = await instance.put(apiUrls.editTransorganization(id), data);
        return response?.data;
    },

    /* color speed */
    getColorSpeedList: async (params) => {
        const response = await instance.get(apiUrls.getColorSpeedList(), { params });
        return response?.data;
    },
    addColorSpeed: async (data) => {
        const response = await instance.post(apiUrls.addColorSpeed(), data);
        return response?.data;
    },
    editColorSpeed: async (id, data) => {
        const response = await instance.put(apiUrls.editColorSpeed(id), data);
        return response?.data;
    },
    deleteColorSpeed: async (id) => {
        const response = await instance.delete(apiUrls.deleteColorSpeed(id));
        return response?.data;
    },

    getMilestoneEvents: async () => {
        const response = await instance.get(apiUrls.getMilestoneEvents());
        return response.data;
    },

    loadStationPolygon: async (params) => {
        const response = await instance.post(apiUrls.loadStationPolygon(), { ...params });
        return response.data;
    },

    loadStationScheduleByDate: async (id, params) => {
        const response = await instance.get(apiUrls.loadStationScheduleByDate(id), { params });
        return response.data;
    },

    getTelemetryReceivers: async (params) => {
        const response = await instance.get(apiUrls.getTelemetryReceivers(), { params });
        return response.data;
    },

    addTelemetryReceiver: async (data) => {
        const response = await instance.post(apiUrls.addTelemetryReceiver(), data);
        return response.data;
    },

    editTelemetryReceiver: async (id, data) => {
        const response = await instance.put(apiUrls.editTelemetryReceiver(id), data);
        return response.data;
    },

    deleteTelemetryReceiver: async (id) => {
        const response = await instance.delete(apiUrls.deleteTelemetryReceiver(id));
        return response.data;
    },

    getTelemetryReceiverTypes: async () => {
        const response = await instance.get(apiUrls.getTelemetryReceiverTypes());
        return response.data;
    },

    changeRouteStatus: async (id, status) => {
        const response = await instance.patch(apiUrls.changeRouteStatus(id, status));
        return response.data;
    },

    getWorkCalendar: async (params) => {
        const response = await instance.get(apiUrls.getWorkCalendar(), { params });
        return response.data;
    },

    editWorkCalendar: async (data) => {
        const response = await instance.put(apiUrls.editWorkCalendar(), data);
        return response.data;
    },

    getWorkCalendarDayTypes: async (params) => {
        const response = await instance.get(apiUrls.getWorkCalendarDayTypes(), { params });
        return response.data;
    },

    getWorkCalendarHistory: async (params) => {
        const response = await instance.get(apiUrls.getWorkCalendarHistory(), { params });
        return response.data;
    },

    deleteRoute: async (id) => {
        const response = await instance.delete(apiUrls.deleteRoute(id));
        return response?.data;
    },

    getVehicleClasses: async (params) => {
        const response = await instance.get(apiUrls.getVehicleClasses(), { params });
        return response?.data;
    },

    createVehicleClass: async (data) => {
        const response = await instance.post(apiUrls.createVehicleClass(), data);
        return response?.data;
    },

    editVehicleClass: async (id, data) => {
        const response = await instance.put(apiUrls.editVehicleClass(id), data);
        return response?.data;
    },

    deleteVehicleClass: async (id) => {
        const response = await instance.delete(apiUrls.deleteVehicleClass(id));
        return response?.data;
    },

    getRegularTransportations: async () => {
        const response = await instance.get(apiUrls.getRegularTransportations());
        return response?.data;
    },

    getSeasonality: async () => {
        const response = await instance.get(apiUrls.getSeasonality());
        return response?.data;
    },

    getRouteSortDirections: async () => {
        const response = await instance.get(apiUrls.getRouteSortDirections());
        return response?.data;
    },

    editRouteAdditionalParams: async (id, params) => {
        const response = await instance.post(apiUrls.editRouteAdditionalParams(id), params);
        return response?.data;
    },

    getRouteHistoryHuman: async (id, params) => {
        const response = await instance.get(apiUrls.getRouteHistoryHuman(id), { params });
        return response?.data;
    },

    loadShowcase: async () => {
        const response = await instance.get(apiUrls.loadShowcase());
        return response?.data;
    },

    getSRoutesByNCCallback: async (params) => {
        const response = await instance.get(apiUrls.getSRoutesByNCCallback(), {
            params,
        });
        return response?.data;
    },

    loadEgtsTransmitters: async (params) => {
        const response = await instance.get(apiUrls.loadEgtsTransmitters(), { params });
        return response.data;
    },

    addEgtsTransmitter: async (data) => {
        const response = await instance.post(apiUrls.addEgtsTransmitter(), data);
        return response.data;
    },

    editEgtsTransmitter: async (id, data) => {
        const response = await instance.put(apiUrls.editEgtsTransmitter(id), data);
        return response.data;
    },

    deleteEgtsTransmitter: async (id) => {
        const response = await instance.delete(apiUrls.deleteEgtsTransmitter(id));
        return response.data;
    },

};

export default ApiTransportPassengerMethods;
