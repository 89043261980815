import React from 'react';
import styles from '../../styles.module.scss';

const NoMap = () => {
    return (
        <div className={styles.no_map_wrapper}>
            <h4>Невозможно отобразить карту</h4>
        </div>
    );
};

export default NoMap;