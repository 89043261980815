/**
 * Sagas
 * **/
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { messageTypes, showMessage } from 'redux/Message/actions';
import { setMapLoading } from 'redux/Map/actions';
import { getReport } from 'helpers/getReport';
import { getHumanDate } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import common from 'helpers/constants/messages/common';
import { messageHelper } from 'helpers/messageHelper';


import * as actions from './actions';
import api from './api.methods';
import * as types from './types';

function* loadSidebarSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingSidebar(true));
    const stationsList = yield call(api.getSideBar, page, limit, params);
    if (stationsList) {
        yield put(actions.loadedSidebar(stationsList));
    }
    yield put(actions.loadingSidebar(false));
}

function* loadVehiclesSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingVehicles(true));
    const response = yield call(api.getVehicles, page, limit, params);
    if (response?.success) {
        yield put(actions.loadedVehicles(response));
    }
    yield put(actions.loadingVehicles(false));
}

function* createVehicleSaga({ payload, callback }) {
    const { data } = payload;
    yield put(actions.loadingVehicles(true));
    const response = yield call(api.createVehicle, data);
    if (response?.success) {
        callback();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
    yield put(actions.loadingVehicles(false));
}

function* editVehicleSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingVehicles(true));
    const response = yield call(api.editVehicle, id, data);
    if (response?.success) {
        callback();
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
    }
    yield put(actions.loadingVehicles(false));
}

function* deleteVehicleSaga({ payload, callback }) {
    const { id } = payload;
    yield put(actions.loadingVehicles(true));
    const response = yield call(api.deleteVehicle, id);
    if (response?.success) {
        callback();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
    yield put(actions.loadingVehicles(false));
}

function* loadVehicleCategoriesSaga() {
    const response = yield call(api.getVehicleCategories);
    if (response?.success) {
        yield put(actions.loadedVehicleCategories(response));
    }
}

function* createVehicleCategorySaga({ payload, callback }) {
    const response = yield call(api.createVehicleCategory, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
    }
}

function* editVehicleCategorySaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editVehicleCategory, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* deleteVehicleCategorySaga({ payload, callback }) {
    const response = yield call(api.deleteVehicleCategory, payload);
    if (response) {
        yield callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* loadVehicleCharacteristicsSaga() {
    const response = yield call(api.getVehicleCharacteristics);
    if (response?.success) {
        yield put(actions.loadedVehicleCharacteristics(response));
    }
}

function* loadBrandsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingBrands(true));
    const response = yield call(api.getBrands, page, limit, params);
    if (response?.success) {
        yield put(actions.loadedBrands(response));
    }
    yield put(actions.loadingBrands(false));
}

function* loadModelsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingModels(true));
    const response = yield call(api.getModels, page, limit, params);
    if (response?.success) {
        yield put(actions.loadedModels(response));
    }
    yield put(actions.loadingModels(false));
}

function* loadRadioTypesSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingRadioTypes(true));
    const response = yield call(api.getRadioTypes, page, limit, params);
    if (response?.success) {
        yield put(actions.loadedRadioTypes(response));
    }
    yield put(actions.loadingRadioTypes(false));
}

function* createBrandSaga({ payload, callback }) {
    const { data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.createBrand, data);
    if (response?.success) {
        callback();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
    yield put(actions.loadingButton(false));
}

function* editBrandSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.editBrand, id, data);
    if (response?.success) {
        callback();
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
    }
    yield put(actions.loadingButton(false));
}

function* deleteBrandSaga({ payload, callback }) {
    const { id } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.deleteBrand, id);
    if (response?.success) {
        callback();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
    yield put(actions.loadingButton(false));
}

function* createModelSaga({ payload, callback }) {
    const { data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.createModel, data);
    if (response?.success) {
        callback();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
    yield put(actions.loadingButton(false));
}

function* editModelSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.editModel, id, data);
    if (response?.success) {
        callback();
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
    }
    yield put(actions.loadingButton(false));
}

function* deleteModelSaga({ payload, callback }) {
    const { id } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.deleteModel, id);
    if (response?.success) {
        callback();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
    yield put(actions.loadingButton(false));
}

// function* getCurrentSpecialReportSaga({ payload = {}, callbackClose }) {
//     const id = payload.id;
//     const response = yield call(api.getCurrentSpecialReport, id, payload.params);
//     if (response) {
//         yield messageHelper(response);
//         yield callbackClose();
//     }
// }

function* loadVehicleCardSaga({ payload }) {
    const { id, data } = payload;
    const report = data?.report;
    const config = report
        ? { params: { ...data }, responseType: 'blob' }
        : { params: { ...data } };

    yield put(actions.loadingVehicleCard(true));
    const response = yield call(api.getVehicleCard, id, config);
    if (response) {
        report
            ? getReport(response, report)
            : yield put(actions.loadedVehicleCard(response?.data));
    }
    yield put(actions.loadingVehicleCard(false));
}

function* loadCoordsSaga() {
    yield put(actions.loadingCoords(true));
    yield put(setMapLoading('ts_coords', true));
    const response = yield call(api.getCoords);
    if (response?.success) {
        yield put(actions.loadedCoords(response?.data));
    }
    yield put(actions.loadingCoords(false));
    yield put(setMapLoading('ts_coords', false));
}

function* loadVehicleTypesSaga() {
    yield put(actions.loadingVehicleTypes(true));

    const response = yield call(api.getTypes);

    if (response) {
        yield put(actions.loadedVehicleTypes(response.data || {}));
    }

    yield put(actions.loadingVehicleTypes(false));
}

function* getReportTS({ payload = {} }) {
    const { report, date_start = '', date_end = '' } = payload;
    let period = date_start !== '' ? `_from_${getHumanDate(date_start)}` : '';
    period += date_end !== '' ? `_to_${getHumanDate(date_end)}` : '';

    const response = yield call(api.getReportTS, payload);

    if (response) {
        getReport(response, report, period);
    }
}

function* getFilteredListSaga({ payload, callback }) {
    const report = payload?.report || false;
    const config = { params: payload };

    yield put(actions.loadingFilteredTSList(true));
    const response = yield call(api.getVehicleReportList, config);
    if (response) {
        report
            ? yield messageHelper(response)
            : yield put(actions.loadedFilteredTSList(response));
    }
    yield callback?.();
    yield put(actions.loadingFilteredTSList(false));
}

function* loadHistorySaga({ payload }) {
    const { vehicleId, params } = payload;
    yield put(actions.loadingHistoryVehicle(true));
    const request = yield call(api.getHistory, vehicleId, params);
    if (request) {
        yield put(actions.loadedHistoryVehicle(vehicleId, request.data));
    }
    yield put(actions.loadingHistoryVehicle(false));
}

function* loadColorSpeedSaga() {
    const response = yield call(api.getColorSpeedList, {});
    if (response?.success) {
        yield put(actions.loadedColorSpeed(response?.data || []));
    }
}

function* loadColorSpeedListSaga({ payload }) {
    yield put(actions.loadingColorSpeedList(true));
    const response = yield call(api.getColorSpeedList, payload);
    if (response?.success) {
        yield put(actions.loadedColorSpeedList(response));
    }
    yield put(actions.loadingColorSpeedList(false));
}

function* addColorSpeedSaga({ payload, callback }) {
    // yield put(actions.loadingColorSpeedList(true));
    const response = yield call(api.addColorSpeed, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    callback && callback(response?.success);
    // yield put(actions.loadingColorSpeedList(false));
}
function* editColorSpeedSaga({ payload, callback }) {
    const { id, data } = payload;
    // yield put(actions.loadingColorSpeedList(true));
    const response = yield call(api.editColorSpeed, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    callback && callback(response?.success);
    // yield put(actions.loadingColorSpeedList(false));
}
function* deleteColorSpeedSaga({ payload, callback }) {
    // yield put(actions.loadingColorSpeedList(true));
    const response = yield call(api.deleteColorSpeed, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
    callback && callback(response?.success);
    // yield put(actions.loadingColorSpeedList(false));
}

function* loadVehicleHistoryModalSaga({ payload }) {
    const { id, params } = payload;
    yield put(actions.loadingVehicleHistoryModal(true));
    const request = yield call(api.getHistory, id, params);
    if (request) {
        const data = request?.data || [];
        if (data.length === 0) {
            yield put(showMessage(messageTypes.success, 'История ТС не найдена', ''));
        }
        yield put(actions.loadedVehicleHistoryModal(request.data || []));
    }
    yield put(actions.loadingVehicleHistoryModal(false));
}

// function* loadCategoriesVehicleSaga({ payload }) {
//     const response = yield call(api.getCategories, payload);
//     if (response?.success) {
//         yield put(actions.loadedCategoriesVehicle(response?.data || []));
//     }
// }

function* loadTelemetryReceiversSaga({ payload }) {
    yield put(actions.loadingTelemetryReceivers(true));
    const response = yield call(api.getTelemetryReceivers, payload);
    if (response?.success) {
        yield put(actions.loadedTelemetryReceivers(response));
    }
    yield put(actions.loadingTelemetryReceivers(false));
}

function* addTelemetryReceiverSaga({ payload, callback }) {
    const response = yield call(api.addTelemetryReceiver, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        callback && callback();
    }
}

function* editTelemetryReceiverSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editTelemetryReceiver, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        callback && callback();
    }
}

function* deleteTelemetryReceiverSaga({ payload, callback }) {
    const response = yield call(api.deleteTelemetryReceiver, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        callback && callback();
    }
}

function* loadTelemetryReceiverTypesSaga({ payload }) {
    const response = yield call(api.getTelemetryReceiverTypes, payload);
    if (response?.success) {
        yield put(actions.loadedTelemetryReceiverTypes(response.data));
    }
}

function* loadVehicleCategoryListSaga({ payload }) {
    yield put(actions.loadingVehicleCategoryList(true));
    const response = yield call(api.getVehicleCategoryList, payload);
    if (response) {
        yield put(actions.loadedVehicleCategoryList(response));
    }
    yield put(actions.loadingVehicleCategoryList(false));
}

function* loadReportByVehiclesSaga({ payload }) {
    yield put(actions.loadingReportByVehicles(true));
    const response = yield call(api.getReportByVehicles, payload);
    if (response) {
        yield put(actions.loadedReportByVehicles(response));
    }
    yield put(actions.loadingReportByVehicles(false));
}

function* loadEgtsTransmittersSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.loadEgtsTransmitters, payload);
    if (response?.success) {
        yield fnCallback?.(response);
    }
    yield fnLoading?.(false);
}

function* addEgtsTransmitterSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.addEgtsTransmitter, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        yield fnCallback?.();
    }
    yield fnLoading?.(false);
}

function* editEgtsTransmitterSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const { id, data } = payload;
    const response = yield call(api.editEgtsTransmitter, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        yield fnCallback?.();
    }
    yield fnLoading?.(false);
}

function* deleteEgtsTransmitterSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.deleteEgtsTransmitter, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        yield fnCallback?.();
    }
    yield fnLoading?.(false);
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_SIDEBAR, loadSidebarSaga),

        takeLatest(types.LOAD_VEHICLES, loadVehiclesSaga),
        takeLatest(types.CREATE_VEHICLE, createVehicleSaga),
        takeLatest(types.EDIT_VEHICLE, editVehicleSaga),
        takeLatest(types.DELETE_VEHICLE, deleteVehicleSaga),
        // takeLatest(types.GET_CURRENT_SPECIAL_REPORT, getCurrentSpecialReportSaga),

        takeLatest(types.LOAD_VEHICLE_CATEGORIES, loadVehicleCategoriesSaga),
        takeLatest(types.CREATE_VEHICLE_CATEGORY, createVehicleCategorySaga),
        takeLatest(types.EDIT_VEHICLE_CATEGORY, editVehicleCategorySaga),
        takeLatest(types.DELETE_VEHICLE_CATEGORY, deleteVehicleCategorySaga),

        takeLatest(types.LOAD_VEHICLE_CHARACTERISTICS, loadVehicleCharacteristicsSaga),

        takeLatest(types.LOAD_BRANDS, loadBrandsSaga),
        takeLatest(types.CREATE_BRAND, createBrandSaga),
        takeLatest(types.EDIT_BRAND, editBrandSaga),
        takeLatest(types.DELETE_BRAND, deleteBrandSaga),

        takeLatest(types.LOAD_MODELS, loadModelsSaga),
        takeLatest(types.CREATE_MODEL, createModelSaga),
        takeLatest(types.EDIT_MODEL, editModelSaga),
        takeLatest(types.DELETE_MODEL, deleteModelSaga),

        takeLatest(types.LOAD_RADIO_TYPES, loadRadioTypesSaga),

        takeLatest(types.LOAD_VEHICLE_CARD, loadVehicleCardSaga),

        takeLatest(types.LOAD_VEHICLE_TYPES, loadVehicleTypesSaga),

        takeLatest(types.LOAD_COORDS, loadCoordsSaga),

        takeLatest(types.GET_REPORT, getReportTS),

        takeLatest(types.GET_FILTERED_LIST, getFilteredListSaga),

        takeLatest(types.LOAD_HISTORY_VEHICLE, loadHistorySaga),

        /* color speed */
        takeLatest(types.LOAD_COLOR_SPEED, loadColorSpeedSaga),
        takeLatest(types.LOAD_COLOR_SPEED_LIST, loadColorSpeedListSaga),
        takeLatest(types.ADD_COLOR_SPEED, addColorSpeedSaga),
        takeLatest(types.EDIT_COLOR_SPEED, editColorSpeedSaga),
        takeLatest(types.DELETE_COLOR_SPEED, deleteColorSpeedSaga),

        takeLatest(types.LOAD_VEHICLE_HISTORY_MODAL, loadVehicleHistoryModalSaga),

        // takeLatest(types.LOAD_CATEGORIES_VEHICLE, loadCategoriesVehicleSaga),
        takeLatest(types.LOAD_TELEMETRY_RECEIVERS, loadTelemetryReceiversSaga),
        takeLatest(types.ADD_TELEMETRY_RECEIVER, addTelemetryReceiverSaga),
        takeLatest(types.EDIT_TELEMETRY_RECEIVER, editTelemetryReceiverSaga),
        takeLatest(types.DELETE_TELEMETRY_RECEIVER, deleteTelemetryReceiverSaga),
        takeLatest(types.LOAD_TELEMETRY_RECEIVER_TYPES, loadTelemetryReceiverTypesSaga),

        takeLatest(types.LOAD_VEHICLE_CATEGORY_LIST, loadVehicleCategoryListSaga),

        takeLatest(types.LOAD_REPORT_BY_VEHICLES, loadReportByVehiclesSaga),

        takeLatest(types.LOAD_EGTS_TRANSMITTER, loadEgtsTransmittersSaga),
        takeLatest(types.ADD_EGTS_TRANSMITTER, addEgtsTransmitterSaga),
        takeLatest(types.EDIT_EGTS_TRANSMITTER, editEgtsTransmitterSaga),
        takeLatest(types.DELETE_EGTS_TRANSMITTER, deleteEgtsTransmitterSaga),
    ]);
};
