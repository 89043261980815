import { getEventsFilters } from 'redux/VideoAnalytics/actions';
import { useStoreProp } from 'helpers/hooks';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

import type { EventFilter } from '../types';

const SelectCheckboxes = ({ selected, onChange, className }: CommonAutocompleteProps<EventFilter>) => {
    const eventsFilters: EventFilter[] = useStoreProp(
        getEventsFilters,
        'videoAnalytics',
        'eventsFilters'
    );

    return (
        <CommonAutocomplete<EventFilter>
            multiple
            selected={selected}
            onChange={onChange}
            label="Дополнительные опции"
            options={eventsFilters}
            renderLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, value) => option?.key === value?.key}
            limitTags={1}
            className={className}
            onReset={() => onChange([])}
        />
    );
};

export default SelectCheckboxes;
