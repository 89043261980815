import React, { useEffect, useState } from 'react';
import * as actions from '../../../../redux/DorisControl/actions';
import ConfirmDelete from '../../../common/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../helpers/constants/messages';
import CommentAction from '../../../common/CommentAction';
import { clearValidation } from '../../../../redux/Validation/actions';
import BasicDataForm from '../../../../components/pages/Dictionaries/DorisControl/VFComplex/PassportPage/BasicData/BasicDataForm';
import titles from '../../../../helpers/constants/titles';
import { dorisControlSelectors } from '../../../../redux/DorisControl';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();
    const deleteForm = useSelector(dorisControlSelectors.deleteForm);
    const editForm = useSelector(dorisControlSelectors.editForm);

    useEffect(() => {
        dispatch(clearValidation());
    }, []);

    const [commentBeforeDelete, setCommentBeforeDelete] = useState(false);
    const [idBeforeDelete, setIdBeforeDelete] = useState(0);

    return (
        <>
            {/* форма редактирования */}
            {editForm
                && <BasicDataForm
                    isOpen={!!editForm}
                    onClose={() => {
                        dispatch(actions.setEditForm());
                    }}
                    item={editForm}
                    isNew={!editForm?.id}
                    id={editForm?.id}
                    isLoadModel={true}
                />
            }

            {/* удаление */}
            {commentBeforeDelete && (
                <CommentAction
                    open={commentBeforeDelete}
                    title="Укажите причину удаления"
                    inputLabel={titles.STATE_REASON}
                    onClose={() => {
                        setIdBeforeDelete(0);
                        setCommentBeforeDelete(false);
                    }}
                    onAccept={(_comment) => {
                        dispatch(actions.deleteComplex(idBeforeDelete, { _comment }));
                    }}
                />
            )}

            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!deleteForm}
                onSuccess={() => {
                    setIdBeforeDelete(deleteForm || 0);
                    setCommentBeforeDelete(true);
                    dispatch(actions.setDeleteForm());
                }}
                onClose={() => {
                    dispatch(actions.setDeleteForm());
                }}
            />
        </>
    );
};

export default CollectorForms;
