import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl, FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import { companiesSelectors } from 'redux/Companies';
import { loadOrganization } from 'redux/Companies/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import {
    createRoadWorkOrder,
    editRoadWorkOrder,
    loadOrderStatuses
} from 'redux/RoadWorks/actions';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import AutocompleteCompanies from 'components/common/Autocomplete/Companies';
import OrderTypesRWSelect from 'components/common/Autocomplete/OrderTypesRW';
import AutocompleteRoadWorks from 'components/common/Autocomplete/RoadWorks/Sites';
import DateRange from 'components/common/Dates/DateRange';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import Attachments from 'components/common/Upload/Attachments';

function AddEditModal({ data, isNew, isOpen, onClose, reloadList }) {
    const dispatch = useDispatch();
    const validation = useValidation();

    const orderStatuses = useStoreProp(loadOrderStatuses, 'roadworks', 'orderStatuses');
    const orderTypes = useSelector(roadworksSelectors.orderTypesData);

    const company = useSelector(companiesSelectors.company);

    const [formValues, setFormValues] = useState({
        organizations: data?.organizations || [],
        organization_ids: data?.organization_ids,
        name: data?.name || '',
        works: data?.works || [],
        files: data?.files || [],
        status: isNew ? 1 : data?.status,
        description: data?.description || '',
        start_date_plan: data?.start_date_plan || null,
        end_date_plan: data?.end_date_plan || null,
        end_at: data?.end_at || null,
        register_at: data?.register_at || fullDateTimeWithTimeZone(new Date()),
        typeObject: orderTypes?.filter(el => el.id === data?.type)[0] || {},
        type: data?.type || null
    });

    const handleChange = (value, key, isMulti = false) => {
        if (isMulti) {
            const hasValue = formValues[key]?.find(({ id }) => id === value?.id);
            if (hasValue) return;
        }
        setFormValues({
            ...formValues, [key]: isMulti
                ? [...formValues[key], value]
                : value
        });
        validation.deleteKey(key);
    };

    const handleChangeDocument = (files) => {
        setFormValues({
            ...formValues,
            files
        });
    };

    const result = {
        ...formValues,
        organization_ids: formValues.organizations.map(el => el?.id),
        works: formValues.works,
        register_at: fullDateTimeWithTimeZone(formValues.register_at)
    };

    const submit = () => {
        const callback = () => {
            reloadList();
            onClose();
            validation.clear();
        };

        isNew
            ? dispatch(createRoadWorkOrder(result, callback))
            : dispatch(editRoadWorkOrder(data?.id, result, callback));
    };

    const [isDisabled, setIsDisabled] = useState(false);

    const hasDeleteOrganizations = useMemo(() => data?.organizations?.includes(null), [data?.organizations]);

    // массив удаленных id, которые не еще подгружены
    const ids = useMemo(() => {
        if (hasDeleteOrganizations) {
            return data?.organization_ids?.filter(id => !company[id]) || [];
        }

        return [];
    }, [company, data?.organization_ids, hasDeleteOrganizations]);

    useEffect(() => {
        if (ids.length > 0) {
            dispatch(loadOrganization(ids, { withDeleted: 1 }));
        }
    }, [dispatch, ids]);

    const newCompanyList = useMemo(() => {
        return data?.organization_ids?.reduce((result, id) => {
            // если организация уже подгружена и она из удаленных
            if (company[id] && !data?.organizations.find(item => item?.id === id)) {
                result.push(company[id]);
            }
            return result;
        }, []);
    }, [company, data?.organization_ids, data?.organizations]);

    useEffect(() => {
        // защита, чтобы эффект не срабатывал до загрузки удаленных организаций
        if (hasDeleteOrganizations && ids.length === 0) {
            setFormValues((prev) => ({
                ...prev,
                organizations: [
                    ...prev.organizations.filter(Boolean),
                    ...newCompanyList
                ]
            }));
        }
    }, [hasDeleteOrganizations, ids, newCompanyList]);

    const areButtonDisabled = !result.organizations.length > 0
        || isDisabled
        || !result.name
        || !result.works.length
        || !result.status
        || !result.description
        || !result.type
        || (result.status === 4 ? !result.end_at : false);

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose(false)}
            noPadding
            title={isNew ? 'Добавить заявку' : 'Редактировать заявку'}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: () => onClose(false)
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: areButtonDisabled,
                        onClick: submit
                    },
                ]}
            />}
        >
            <form className="modal__form">
                <div className="row">
                    <TextField
                        variant="outlined"
                        size="small"
                        value={formValues.name}
                        className="row__item"
                        label="Название заявки"
                        onChange={({ target: { value } }) => handleChange(value, 'name')}
                        helperText={validation.get('name')}
                        error={validation.isKey('name')}
                        required={true}
                    />
                    <FormControl
                        required={true}
                        className="row__item"
                        size="small"
                        variant="outlined"
                        error={validation.isKey('status')}
                    >
                        <InputLabel>{titles.STATUS}</InputLabel>
                        <Select
                            value={formValues.status}
                            onChange={(event) => {
                                handleChange(parseInt(event.target.value), 'status');
                            }}
                            disabled={isNew}
                            label={titles.STATUS}
                        >
                            {Object.values(orderStatuses).map((el) => (
                                <MenuItem key={el.status_id} value={el.status_id}>
                                    {el.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {validation.isKey('name')
                        && <FormHelperText error={validation.isKey('status')}>
                            {validation.get('status')}
                        </FormHelperText>
                        }
                    </FormControl>
                </div>
                <div className="block">
                    <OrderTypesRWSelect
                        sortedList={orderTypes}
                        onChange={(value) => setFormValues({ ...formValues, type: value.id, typeObject: value })}
                        selected={formValues.typeObject}
                        helperText={validation.get('type')}
                        error={validation.isKey('type')}
                        required
                    />
                </div>
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        onChange={(value) => handleChange(value, 'register_at')}
                        value={formValues.register_at}
                        isDisabledHandler={setIsDisabled}
                        dateOnly={true}
                        label="Дата регистрации"
                        required
                        error={validation.isKey('register_at')}
                        helperText={validation.get('register_at')}
                    />
                </div>
                <div className="block">
                    <AutocompleteCompanies
                        filter={{ withDeleted: 1 }}
                        onChange={(value) => setFormValues({ ...formValues, organizations: value })}
                        selected={formValues.organizations}
                        helperText={validation.get('organization_id_list')}
                        error={validation.isKey('organization_id_list')}
                        multiple
                        required
                    />
                </div>
                <div className="block">
                    <AutocompleteRoadWorks
                        onRoadWorkChange={(value) => setFormValues({ ...formValues, 'works': value })}
                        roadWorkList={formValues.works}
                        error={validation.isKey('works')}
                        helperText={validation.get('works')}
                        multiple
                        required
                        withCreate
                    />
                </div>
                <div className="block">
                    <TextField
                        value={formValues.description}
                        onChange={({ target: { value } }) => handleChange(value, 'description')}
                        variant="outlined"
                        size="small"
                        multiline={true}
                        required={true}
                        rows={5}
                        label={titles.DESCRIPTION}
                        error={validation.isKey('description')}
                        helperText={validation.get('description')}
                    />
                </div>
                {formValues.status === 4 && (
                    <div className="block">
                        <SingleKeyboardDateTimePicker
                            onChange={(value) => handleChange(value, 'end_at')}
                            value={formValues.end_at}
                            isDisabledHandler={setIsDisabled}
                            dateOnly={true}
                            label={'Фактическая дата выполнения'}
                            required
                            error={validation.isKey('end_at')}
                            helperText={validation.get('end_at')}
                        />
                    </div>
                )}
                <div className="block">
                    <DateRange
                        handleDate={(value) => handleChange(fullDateTimeWithTimeZone(value), 'start_date_plan')}
                        handleEndDate={(value) => handleChange(fullDateTimeWithTimeZone(value), 'end_date_plan')}
                        valueStartDate={formValues.start_date_plan}
                        valueEndDate={formValues.end_date_plan}
                        isDisabledHandler={setIsDisabled}
                        startLabel={'Плановая дата начала выполнения'}
                        endLabel={'Плановая дата окончания выполнения'}
                        required
                        dateOnly
                    />
                </div>
                <Attachments
                    onChange={handleChangeDocument}
                    files={formValues?.files}
                />
            </form>
        </Modal>
    );
}

export default AddEditModal;
