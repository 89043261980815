import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    TableCell,
} from '@mui/material';

import {
    createWorkorder,
    deleteWorkorder,
    editWorkorder,
} from 'redux/TransportPassenger/actions';
import { getDateObjectFromFormat, getStartOf, isDateValid } from 'helpers/date.config';
import dateFormat from 'helpers/constants/dateFormat';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import WorkOrderItem from './WorkOrderItem';
import AddEditModal from '../Workorders/AddEditModal';

const Item = ({
    item,
    columnTitle,
    classes,
    reloadData,
}) => {
    const dispatch  = useDispatch();
    const value = item?.[columnTitle];
    // приводим дату к формату
    const currentDate = getDateObjectFromFormat(columnTitle, dateFormat.DATE_FOR_PICKER);
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [currentElement, setCurrentElement] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const deleteItem = (id) => {
        dispatch(deleteWorkorder(id, () => {
            reloadData();
        }));
    };

    const openAddModal = () => {
        setIsOpenAdd(true);
        setCurrentElement({
            date_at: currentDate,
            vehicle_id: item?.vehicle_id,
            organization_id: item?.organization_id,
        });
    };
    const closeAddModal = () => {
        setIsOpenAdd(false);
        setCurrentElement(null);
    };

    const onSave = (data) => {
        dispatch(createWorkorder(data, () => {
            reloadData();
            setIsOpenAdd(false);
        }));
    };

    const onEdit = (id, data, callback) => {
        dispatch(editWorkorder(id, data, () => {
            reloadData();
            callback();
        }));
    };

    // если это ячейка не наряд а инфо по ТС
    if (!isDateValid(currentDate)) {
        return (
            <TableCell
                className={classes.cells}
                // align="left"
            >
                {value}
            </TableCell>
        );
    }
    // если дата в заголовке меньше чем сегодня
    const isPreviousDate = currentDate < getStartOf('day');

    return (
        <>
            <TableCell
                className={classes.cells}
                onMouseLeave={closeMenu}
            >
                <div className={classes.cellFlex} >
                    {value?.length > 0
                        && <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            {value?.map(el => (
                                <WorkOrderItem
                                    el={{
                                        ...el,
                                        vehicle_id: item?.vehicle_id,
                                        organization_id: item?.organization_id,
                                    }}
                                    onSave={onEdit}
                                    onDelete={() => deleteItem(el.id)}
                                    openMenu={openMenu}
                                    closeMenu={closeMenu}
                                    anchorEl={anchorEl}
                                    key={el.id}
                                />
                            ))}
                        </div>
                    }
                    <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.addIcon,
                                onClick: openAddModal,
                                disabled: isPreviousDate,
                                ...(isPreviousDate ? { name: 'Нельзя создать на прошедший день' } : {})
                            }
                        ]}
                        noMarginLeft
                        justButton
                    />
                </div>



                {isOpenAdd
                    && <AddEditModal
                        reloadData={reloadData}
                        isNew={true}
                        data={currentElement}
                        onClose={closeAddModal}
                        isOpen={isOpenAdd}
                        onSave={onSave}
                        isCalendar
                        disablePeriod
                    />
                }
            </TableCell>
        </>
    );
};

export default Item;
