import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    Badge, Box, Container
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import style from '../notification.module.scss';
import titles from '../../../../helpers/constants/titles';
import buttons from '../../../../helpers/constants/buttons';
import { useNotifications, usePrevious } from '../../../../helpers/hooks';
import List from '../List';
import { useSelector } from 'react-redux';
import {
    Tab,
    Tabs,
    Typography,
    LinearProgress,
} from '@mui/material';
import Filter from '../Filter/index';
import FormButtonsComponent, { buttonsTypes } from '../../FormButtons';
import { Scrollbars } from 'react-custom-scrollbars';
import { debounce } from 'lodash';
// import useWsCall from '../../../../helpers/hooks/ws/useWsCall';
import useWsAlerts from '../../../../helpers/ws/hooks/useWsAlerts';
import { notificationsSelectors } from '../../../../redux/Notifications';

const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 600,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    popover: {
        height: '500px',
    },
    icon: {
        color: 'rgba(0,0,0, 0.54)',
    },
    paper: {
        overflow: 'hidden',
    },
}));

export const Page = ({ onClose }) => {
    const classes = useStyles();

    // хук для нотификаций
    const notifications = useNotifications();
    const alertsProvider = useWsAlerts();

    const loading = useSelector(notificationsSelectors.loading);
    const listData = useSelector(notificationsSelectors.listData);
    const listMeta = useSelector(notificationsSelectors.listMeta);
    const countActive = useSelector(notificationsSelectors.countActive);
    const wsReload = useSelector(notificationsSelectors.wsReload);

    // если открыта - перезагрузит
    useEffect(() => {
        if (wsReload) {
            const { filter, props } = params;

            notifications.reloadList({
                ...filter,
                ...props
            });

            notifications.wsReloadList(false);
        }
    }, [wsReload]);

    useEffect(() => {
        return () => {
            notifications.clearList();
        };
    },[]);

    const [tab, setTab] = useState(0);
    const [showTotal, setShowTotal] = useState(false);
    const prevLoading = usePrevious(loading);
    const scrollRef = useRef(null);
    const total = listMeta?.total || 0;

    useEffect(() => {
        notifications.clearList();
    }, [tab]);

    useEffect(() => {
        const filterCount = Object.keys(params.filter).length || 0;
        if (filterCount > 0 && tab === 1 && loading === false && prevLoading === true) {
            setShowTotal(true);
        }
        if (tab === 0 || filterCount === 0) {
            setShowTotal(false);
        }
    }, [loading, prevLoading, tab]);

    const countNotView = useMemo(() => {
        if (showTotal) {
            return total;
        }

        return countActive;
    }, [total, showTotal, countActive]);

    const tabList = [
        {
            header: {
                label: 'Все',
            },
            filter: {
            }
        },
        {
            header: {
                label: 'Новые',
                icon: <Badge
                    className={style.notify__tab}
                    color="primary"
                    max={1000}
                    badgeContent={countNotView}
                    // showZero
                />,
                className: style.notify__tab__wrap
            },
            filter: {
                viewed: 0
            },
            reload: true,
            clearList: true,
        }
    ];

    const initialParams = {
        page: 1,
        limit: 25,
        filter: {
        },
        props: {
            ...tabList[tab].filter
        },
    };

    const [params, setParams] = useState(initialParams);

    const fetchList = (page = 1, limit, params) => {
        notifications.loadList(page, limit, params);
        // notifications.loadCountActive();
    };

    const handleChangeFilter = (newFilter) => {
        setParams((old) => ({
            ...initialParams,
            ...old,
            page: 1,
            filter: {
                // ...old.filter,
                ...newFilter,
            },
        }));
    };

    const handleLoadNextPage = () => {
        if (
            !loading
            && params.page < listMeta?.last_page
        ) {
            setParams((old) => ({
                ...old,
                page: old.page + 1,
            }));
        }
    };

    useEffect(() => {
        const { page, limit, filter, props } = params;
        fetchList(page, limit, { ...filter, ...props });
    }, [params]);

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);

        const filter = tabList[newTab].filter;
        scrollTop();

        setParams((old) => ({
            ...old,
            page: 1,
            props: {
                ...filter,
            }
        }));
    };

    const doReloadList = () => {
        const { filter, props } = params;

        notifications.reloadList({
            ...filter,
            ...props
        });

        // notifications.loadCountActive();
    };
    const reloadListDebounce = debounce(doReloadList, 500);

    const reloadList = () => {
        if (tabList[tab].reload) {
            reloadListDebounce();
        }
    };

    const handleClickItem = (data) => {
        const {
            id,
            link = null,
            viewed = false,
        } = data;
        // notifications.changeRead(id);
        alertsProvider.onClick(id, link, viewed);

        onClose();

        reloadList();
    };

    const handleClickItemRead = (id) => {
        notifications.changeRead(id);
        reloadList();
    };

    const handleAllIsMarked = (e) => {
        e.preventDefault();
        e.stopPropagation();
        notifications.changeReadAll();

        if (tabList[tab]?.clearList) {
            notifications.clearList();
        }
        // reloadList();
    };

    const handleScrollUpdate = (values) => {
        if (values.top > 0.88) {
            handleLoadNextPage();
        }
    };

    const scrollTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    return (
        <div className={classes.root}>
            <Typography className={style.notify__title}>{titles.NOTIFICATIONS}</Typography>

            <Filter
                // value={params?.filter?.query || ''}
                onChage={handleChangeFilter}
            />

            <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs"
            >
                {tabList.map((item, index) => (
                    <Tab
                        key={`tab_header_${index}`}
                        {...item.header}
                        {...a11yProps(index)}
                    />
                ))}

            </Tabs>

            {listData?.length > 0 && notifications.loading && (
                <LinearProgress />
            )}

            <Container className={style.container}>
                <Box variant="body2" className={style.box}>
                    <Scrollbars
                        style={{ height: '100%' }}
                        onUpdate={handleScrollUpdate}
                        renderTrackHorizontal={(props) => (
                            <div {...props} className="track-horizontal" />
                        )}
                        ref={scrollRef}
                    >
                        <List
                            list={listData}
                            onClickItemRead={handleClickItemRead}
                            onClickItem={handleClickItem}
                            // onLoadNextPage={handleLoadNextPage}
                            loading={loading}
                        />
                    </Scrollbars>
                </Box>
            </Container>

            {countActive > 0 && (
                <FormButtonsComponent
                    noPadding
                    // className={style.notify__button}
                    buttons={[
                        {
                            ...buttonsTypes.defaultPrimary,
                            onClick: handleAllIsMarked,
                            name: buttons.MARK_AS_READ,
                            fullWidth: true,
                            style: { margin: 0 }
                        }
                    ]}
                />
            )}
        </div>
    );
};

export default Page;