import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import titles from 'helpers/constants/titles';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import ModalForm from './ModalForm';
import { renderValueByType } from 'components/common/EfficiencyDevelopment/helper';
import { Tooltip } from '@mui/material';

const Item = ({ item, loadList }) => {
    const [openEditModal, setOpenEditModal] = useState(false);

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item.name || '-'}
                </LSContentColumn>
                <LSContentColumn>
                    {renderValueByType(item.value, item.type) || '-'}
                </LSContentColumn>
                <LSContentColumn>
                    <Tooltip
                        title={
                            item.is_visible
                                ? 'Показывать в дашборде Витрины'
                                : 'Не показывать в дашборде Витрины'
                        }
                    >
                        <i
                            className={item.is_visible
                                ? 'fa fa-check'
                                : 'fa fa-times'
                            }
                            style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                        ></i>
                    </Tooltip>
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <EditIcon fontSize="inherit"/>,
                                name: titles.EDIT,
                                onClick: () => setOpenEditModal(true),
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openEditModal
                && <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    loadList={loadList}
                    item={item}
                />
            }
        </>
    );
};

export default Item;
