import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';

import { boardsSelectors } from 'redux/Boards';
import { loadMessagePriorities, loadMessages } from 'redux/Boards/actions';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import removeEmptyFields from 'helpers/removeEmptyFields';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import CustomPagination from 'components/common/CustomPagination';

import ModalForm from './ModalForm';
import SelectTemplate from './SelectTemplate';
import Item from './Item';
import Filter from './Filter';

const BoardMessages = ({ board: currentBoard = {}, fromTabs = false }) => {
    const dispatch = useDispatch();

    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);
    const [page, setPage] = useState(1);
    const currentBoardId = currentBoard?.id;

    const [params, setParams] = useState({
        dit_serial_number: '',
        dit_ids: []
    });
    const [isOpenModalForm, setIsOpenModalForm] = useState(false);
    const [isOpenTempAutoCom, setIsOpenTempAutoCom] = useState(false);
    const [currentTemplate, setCurrentTemplate] = useState({});

    const boardMessages = useSelector(boardsSelectors.boardMessages);
    const loadingMessages = useSelector(boardsSelectors.loadingMessages);

    const messagePriorities = useStoreProp(
        loadMessagePriorities,
        'boards',
        'messagePriorities'
    );

    useEffect(() => {
        if (currentBoardId) {
            setParams((prevParams) => ({
                ...prevParams,
                dit_serial_number: currentBoard?.serial_number,
                dit_ids: [currentBoard?.id]
            }));
        }
    },[currentBoardId, currentBoard]);

    const reloadList = () => dispatch(loadMessages({ page, limit, ...removeEmptyFields(params) }));
    useEffect(() => {
        if ((currentBoardId && params.dit_serial_number === currentBoard?.serial_number) || !currentBoardId) {
            dispatch(loadMessages({ page, limit, ...removeEmptyFields(params) }));
        }
    }, [currentBoardId, currentBoard, page, limit, dispatch, params]);

    useEffect(() => {
        if(Object.keys(currentTemplate).length) {
            setIsOpenModalForm(true);
            setIsOpenTempAutoCom(false);
        }
    }, [currentTemplate]);

    return (
        <div className="tab__container">
            {isOpenModalForm && (
                <ModalForm
                    isOpen={isOpenModalForm}
                    onClose={() => {
                        setIsOpenModalForm(false);
                        setCurrentTemplate({});
                    }}
                    isNew
                    // priorities={messagePriorities}
                    boardsProp={currentBoard && [currentBoard]}
                    item={currentTemplate && currentTemplate}
                    reloadList={reloadList}
                />
            )}
            {isOpenTempAutoCom
                && <SelectTemplate
                    isOpen={isOpenTempAutoCom}
                    data={currentTemplate}
                    setData={setCurrentTemplate}
                    onClose={() => {
                        setCurrentTemplate({});
                        setIsOpenTempAutoCom(false);
                    }}
                />
            }
            {!fromTabs && <h1>Сообщения для подсистемы ДИТ и ЗПИ</h1>}
            <Filter
                setParams={setParams}
                priorities={messagePriorities}
                currentBoard={currentBoard}
            />
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setIsOpenModalForm(true),
                        },
                        {
                            ...buttonsTypes.add,
                            name: 'Создать из шаблона',
                            onClick: () => setIsOpenTempAutoCom(true)
                        }
                    ]}
                />
            </div>
            {currentBoardId && <h2>Список сообщений - {currentBoard?.name}</h2>}
            {loadingMessages && <Loading linear />}
            {boardMessages.data?.length > 0
                ? (<>
                    <List className="list">
                        {boardMessages?.data?.map(el => <Item
                            message={el}
                            key={el.id}
                            // priorities={messagePriorities}
                            reloadList={reloadList}
                            currentBoard={currentBoard}
                        />)}
                    </List>
                    <CustomPagination
                        loadList={setPage}
                        list={boardMessages.meta}
                        limit={limit}
                        setLimit={setLimit}
                    />
                </>)
                : (!loadingMessages && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </div>
    );
};

export default BoardMessages;
