import React, { memo } from 'react';
import { FormControl, TextField } from '@mui/material';

interface CountShowProps {
    value: string
    onChange: (arg: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    validation: any
}

export const CountShow = memo((props: CountShowProps) => {
    const { value, onChange, validation } = props;

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = e;
        const target = e.target as HTMLInputElement;

        if (key === 'e' || key === '+' || key === '.' || key === ',') {
            e.preventDefault();
        }
        if (target.value.length === 0 && key === '-') {
            target.value = '';
        }
        if (value.length > 0 && key === '-') {
            e.preventDefault();
        }
    };

    return (
        <FormControl className="row__item" size="small" variant="outlined">
            <TextField
                label="Кол-во показов"
                variant="outlined"
                size="small"
                value={value}
                name="count"
                onKeyPress={handleKeyPress}
                onPaste={e => e.preventDefault()}
                onChange={(e) => Number(e.target.value) > -2 && onChange(e)}
                InputProps={{ inputProps: { min: -1 } }}
                type="number"
                required
                error={validation.isKey('count')}
            />
        </FormControl>
    );
});