import { useState } from 'react';

import { useValidation } from 'helpers/hooks';
import formatDate from 'helpers/constants/dateFormat';
import { getDateObjectFromFormat, getTimeWithoutSeconds } from 'helpers/date.config';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';

const TemplateTimeModal = (
    {
        el = {},
        onClose = () => {},
        isOpen = false,
        onSave = () => {}
    }) => {
    const validation = useValidation();

    const stationStopTime = el?.station_stop_time || '00:00';

    const [fieldInput, setFieldInput] = useState(getDateObjectFromFormat(stationStopTime, 'HH:mm'));

    const handleSaveField = () => {
        const result = { station_stop_time: getTimeWithoutSeconds(fieldInput) };
        onSave(result);
    };


    return (
        <Modal
            title="Редактировать количество минут на остановке"
            onClose={onClose}
            noPadding
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSaveField
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <SingleKeyboardDateTimePicker
                    className="block"
                    timeOnly
                    label="Количество минут на остановке"
                    pickerFormat={formatDate.HH_MM}
                    value={fieldInput}
                    onChange={setFieldInput}
                    error={validation.isKey('station_stop_time')}
                    helperText={validation.get('station_stop_time') || 'Укажите время в формате ЧЧ:ММ (часы:минуты)'}
                />
            </div>
        </Modal>
    );
};

export default TemplateTimeModal;
