const config = {
    name: 'Пассажирский транспорт',
    slug: 'routes',
    mapContextMenu: null,
    mapMarkersColors: {
        // от статуса маркера
        default: '#D7DBDD',
        1: '#4b9c4b',
        2: '#0fa3b0',
        3: '#da573e',
        4: '#0979e2'
    },
    mapMarkersTypes: {
        dot: 2,
        line: 1,
        polygon: 3
    },
    layerIcon: 'fas fa-bus-alt',
};

export default config;
