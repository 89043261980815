import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearBoards, clearDitLink, createDitLink } from 'redux/Boards/actions';
import { boardsSelectors } from 'redux/Boards';
import LinkModal from 'components/common/LinkModal';
import useLoadBoardsList from 'helpers/hooks/Boards/useLoadBoardList';
import BoardUnivrsalSelect from 'components/common/Autocomplete/Boards/BoardList/BoardUniversalSelect';

import BoardLinkList from '../BoardLinkList';

const BoardListModal = (
    {
        id,
        name,
        entity_name,
        isOpen,
        onClose,
        microservice,
        dit_list, // список дитов из табов, где мы их уже к этому моменту загрузили
        callback
    }) => {
    const dispatch = useDispatch();
    const loadingDitLink = useSelector(boardsSelectors.loadingDitLink);
    const loadingBoardsFromLink = useSelector(boardsSelectors.loadingBoardsFromLink);
    const [ditLinks, setDitLinks] = useState(dit_list || []);
    const [showList, setShowList] = useState(false); // показать универсальный селект
    const isLoading = loadingDitLink || loadingBoardsFromLink;
    const dits = useLoadBoardsList(id,  entity_name, microservice, false, dit_list); // загрузка списка дитов

    useEffect(() => {
        if (!dit_list && !isLoading && dits.length > 0) {
            setDitLinks(dits);
        }
    }, [dits, dit_list, isLoading]);

    const openBoardsList = () => {
        setShowList(true);
    };

    const closeBoardsList = () => {
        onClear();
        setShowList(false);
    };

    const onClear = () => {
        dispatch(clearDitLink());
        dispatch(clearBoards());
    };


    const noErrorCallback = () => {
        onClear();
        onClose();
    };

    const saveBoardsLinks = () => {
        const dataForSend = {
            entity_id: id?.toString(),
            entity_name: entity_name,
            dit_ids: ditLinks.map(el => el.id)
        };

        dispatch(createDitLink(dataForSend, noErrorCallback, microservice, callback));
    };

    return (
        <>
            <LinkModal
                onClose={noErrorCallback}
                onSave={saveBoardsLinks}
                onChange={openBoardsList}
                name={name}
                isOpen={isOpen}
                linkObject={'ДИТ'}
                disabled={isLoading}
            >
                {/* список текущих связей дит */}
                <BoardLinkList
                    onChangeList={setDitLinks}
                    loading={isLoading && !showList}
                    dit_list={ditLinks}
                    disabled={false}
                    inModal
                />
                {/* список дит для выбора новых */}
                {showList
                    && <BoardUnivrsalSelect
                        selected={ditLinks}
                        multiple
                        onChange={setDitLinks}
                        showList={showList}
                        handleClose={closeBoardsList}
                    />
                }
            </LinkModal>
        </>
    );
};

export default BoardListModal;
