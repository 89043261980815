import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import { loadPakModels } from 'redux/RoadNetwork/actions';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import UniversalSelect from 'components/common/UniversalSelect';

import type { PakModelItem } from 'types/RoadNetwork';

const SelectPakModel = ({
    multiple = false,
    className,
    selected,
    onChange,
    required = false,
    disabled = false,
    label = 'Модель оборудования ПАК',
    error = false,
    helperText = '',
    renderLabel = (option: PakModelItem | null) => option?.name || '',
    limitTags = 1,
}: CommonAutocompleteProps<PakModelItem>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);

    const prevData = useRef(selected);

    useEffect(() => {
        if (!isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleAccept = (data: PakModelItem | PakModelItem[]) => {
        onChange(data);
        setShowList(false);
    };

    const handleClose = () => setShowList(false);

    const getList = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query } = params;

        dispatch(loadPakModels(page, limit, { name: query }));
    };

    const onReset = () => {
        const initialState = multiple ? [] : '';
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete<PakModelItem>
                multiple={multiple}
                className={className}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="model"
                disabled={disabled}
                onReset={onReset}
                renderLabel={renderLabel}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName="roadNetworks"
                    storeNameProps="pakModels"
                    storeLoadingProps="loadingPakModels"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div>{el.name}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={'Модель оборудования ПАК'}
                />
            }
        </>
    );
};

export default SelectPakModel;
