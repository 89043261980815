import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/Notifications/actions';
import { notificationsSelectors } from '../../redux/Notifications';

// хук нотификаций
const useNotifications = (props_query = {}) => {
    const dispatch = useDispatch();

    // const notifications = useSelector(notificationsSelectors.notifications);
    const listMeta = useSelector(notificationsSelectors.listMeta);

    return {
        // ...notifications,

        // получить список
        loadList: (page = 1, limit = 10, params = {}) => {
            dispatch(actions.loadListNotification(page, limit, { ...props_query, ...params }));
            dispatch(actions.setLastPageNotification(page));
        },

        reloadList: (params = {}) => {
            const page = listMeta?.current_page || 1;
            const perPage = listMeta?.per_page || 0;

            const limit = parseInt(page) * parseInt(perPage);
            if (limit) { 
                dispatch(actions.reloadListNotification({
                    ...params,
                    page: 1,
                    limit,
                    ...props_query,
                }));
            }
        },

        // получить список по конкретному сервису
        loadServiceList: (page = 1, limit = 10, query) => {
            dispatch(actions.loadServiceListNotification(page, limit, { ...props_query, ...query }));
        },

        // получить количество активных
        loadCountActive: () => {
            dispatch(actions.loadCountActiveNotification());
        },

        // прочитано
        changeRead: (id) => {
            dispatch(actions.changeReadNotification(id));
        },

        // прочитано все
        changeReadAll: () => {
            dispatch(actions.changeReadAllNotification());
        },

        // установить последнюю загруженную страницу
        setLastPage: (page) => {
            dispatch(actions.setLastPageNotification(page));
        },

        // // добавить новое уведомление
        // addNew: (data) => {
        //     dispatch(actions.addNewNotification(data));
        // },

        wsReloadList: (bool) => {
            dispatch(actions.wsReloadNotification(bool));
        },

        clearList: () => {
            dispatch(actions.clearList());
        },
    };
};

export default useNotifications;
