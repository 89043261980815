import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setValidation } from 'redux/Validation/actions';
import { showMessage } from 'redux/Message/actions';
import messageTypes from 'redux/Message/messagetypes';
import { setMapLoading } from 'redux/Map/actions';
import common from 'helpers/constants/messages/common';
import messages from 'helpers/constants/messages';
import { messageHelper } from 'helpers/messageHelper';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

function* getStatusesRW() {
    const response = yield call(api.getStatusesRW);
    if (response?.data) {
        yield put(actions.saveStatusesRW(response.data));
    }
}

function* getOrdersRWSaga({ payload }) {
    yield put(actions.loadingRoadWorks(true));
    const response = yield call(api.getOrdersRW, payload);
    if (response) {
        yield put(actions.saveOrdersRW(response));
    }
    yield put(actions.loadingRoadWorks(false));
}

function* getOrdersReportSaga({ payload }) {
    const config = { params:  payload };
    const response = yield call(api.getOrdersReport, config);
    if (response?.success) {
        yield messageHelper(response);
    }
}

function* loadRoadWorksSaga({ payload, callback }) {
    const { params, replaceStore } = payload;
    yield put(actions.loadingRoadWorks(true));
    const response = yield call(api.getListRW, params);
    if (response?.success) {
        if (callback) {
            callback(response.data);
        } else {
            yield put(actions.loadedRoadWorks(response, replaceStore));
        }
    }
    yield put(actions.loadingRoadWorks(false));
}

function* getForPolygonRW({ payload }) {
    const { polygon, params } = payload;
    yield put(actions.loadingPolygonRW(true));
    yield put(setMapLoading('rw_polygon', true));
    const response = yield call(api.getForPolygonRW, polygon, params);
    if (response) {
        yield put(actions.saveForPolygonRW(response));
    }
    yield put(actions.loadingPolygonRW(false));
    yield put(setMapLoading('rw_polygon', false));
}

function* editRW({ payload: { id, data }, callback }) {
    try {
        yield put(actions.loadingRoadWorks(true));
        const response = yield call(api.editRW, id, data);
        if (response?.success) {
            yield put(actions.saveRW(response.success));
            yield put(actions.changeListItem(response.data));
            yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS, ''));
            callback();
        }
        yield put(actions.loadingRoadWorks(false));
    } catch (e) {
        yield put(showMessage(messageTypes.error, messages.SOMETHING_WENT_WRONG, ''));
        yield put(actions.loadingRoadWorks(false));
    }
}

function* createNewRW({ payload, callback }) {
    yield put(actions.loadingRoadWorks(true));
    const response = yield call(api.createNewRW, payload);
    if (response?.success) {
        yield put(actions.saveRW(response.success));
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS, ''));
        callback?.();
    } else {
        yield put(showMessage(messageTypes.error, messages.SOMETHING_WENT_WRONG, ''));
    }
    yield put(actions.loadingRoadWorks(false));
}

function* deleteRW({ payload, callback }) {
    yield put(actions.loadingRoadWorks(true));
    const response = yield call(api.deleteRW, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Дорожная работа удалена', ''));
        callback?.();
    }
    yield put(actions.loadingRoadWorks(false));
}

function* getHistoryRW({ payload: { id, page, limit, query } }) {
    yield put(actions.loadingRoadWorks(true));
    const data = yield call(api.getHistoryRW, id, page, limit, query);
    if (data) {
        yield put(actions.saveHistoryRW({ data }));
    }
    yield put(actions.loadingRoadWorks(false));
}

function* getReportRW({ payload = {} }) {
    const response = yield call(api.getReportRW, payload);

    if (response) {
        yield put(showMessage(messageTypes.success, response?.data?.join('')));
    }
}

function* loadRwOrdersSaga({ payload, callback }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingRoadWorkOrders(true));
    const response = yield call(api.getRwOrders, page, limit, params);
    if (response) {
        if (callback) {
            callback(response.data);
        } else {
            yield put(actions.loadedRoadWorkOrders(response));
        }
    }
    yield put(actions.loadingRoadWorkOrders(false));
}

function* loadRwOrderStatusesSaga() {
    const response = yield call(api.getRwOrderStatuses);
    if (response?.success) {
        yield put(actions.loadedOrderStatuses(response?.data));
    }
}

function* loadOrderDirectionsSaga() {
    const response = yield call(api.getOrderDirections);
    if (response?.success) {
        yield put(actions.loadedOrderDirections(response?.data));
    }
}

function* createRwOrderSaga({ payload, callback }) {
    const response = yield call(api.createRwOrder, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Данные были успешно добавлены'));
        callback?.();
    }
}

function* editRwOrderSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editRwOrder, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Данные были успешно изменены'));
        callback?.();
    }
}

function* deleteRwOrderSaga({ payload, callback }) {
    const response = yield call(api.deleteRwOrder, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Данные были успешно удалены'));
        callback?.();
    }
}

//STAGES
function* loadStagesStatusesSaga() {
    const response = yield call(api.getStagesStatuses);
    if (response?.success) {
        yield put(actions.loadedStagesStatuses(response?.data));
    }
}

function* loadStageCargoTypesSaga() {
    const response = yield call(api.getStageCargoTypes);
    if (response?.success) {
        yield put(actions.loadedStageCargoTypes(response?.data));
    }
}

function* loadStagesListSaga({ payload }) {
    const { page, limit , params } = payload;
    yield put(actions.loadingStagesList(true));
    const response = yield call(api.getStagesList, page, limit, params);
    if (response?.success) {
        yield put(actions.loadedStagesList(response));
    }
    yield put(actions.loadingStagesList(false));
}

function* createStageSaga({ payload, callback }) {
    const response = yield call(api.createStage, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        yield callback(response?.data);
    }
}

function* editStageSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.putStage, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        callback?.();
    }
}

function* deleteStageSaga({ payload, callback }) {
    const response = yield call(api.deleteStage, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        callback?.();
    }
}

function* editSequenceStagesSaga({ payload, callback }) {
    const response = yield call(api.putSequenceStages, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        callback?.();
    }
}

// DEFECT_GROUP
function* loadDefectGroupListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingRoadWorks(true));
    const roadISList = yield call(api.getDefectGroupList, page, limit, query);
    if (roadISList) {
        yield put(actions.loadedDefectGroupList(roadISList));
    }
    yield put(actions.loadingRoadWorks(false));
}

function* createDefectGroupSaga({ payload, callback }) {
    const response = yield call(api.createDefectGroup, payload);
    if (response?.success) {
        callback?.();
        yield showMessage(messageTypes.success, common.SAVED);
    }
}

function* editDefectGroupSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editDefectGroup, id, data);
    if (response?.success) {
        callback?.();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* deleteDefectGroupSaga({ payload, callback }) {
    const response = yield call(api.deleteDefectGroup, payload);
    if (response?.success) {
        callback?.();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

// DEFECT_TYPES
function* loadDefectTypesSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingDefectTypes(true));
    const response = yield call(api.loadDefectTypes, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedDefectTypes(response));
    }
    yield put(actions.loadingDefectTypes(false));
}

function* createDefectTypesSaga({ payload, callback }) {
    const response = yield call(api.createDefectTypes, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        callback?.();
    }
}

function* editDefectTypesSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editDefectTypes, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        callback?.();
    }
}

function* deleteDefectTypesSaga({ payload, callback }) {
    const response = yield call(api.deleteDefectTypes, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        callback?.();
    }
}

function* getTypeOfRoadDefectionsSaga({ payload }) {
    yield put(actions.loadingTypeOfRoadDefections(true));
    const response = yield call(api.getTypeOfRoadDefections, payload);
    if (response?.success) {
        yield put(actions.loadedTypeOfRoadDefections(response));
    }
    yield put(actions.loadingTypeOfRoadDefections(false));
}

function* getTypeCreationSaga() {
    const response = yield call(api.getTypeCreation);
    if (response?.success) {
        yield put(actions.loadedTypeCreation(response.data));
    }
}

function* createUnitSaga({ payload, callback }) {
    const response = yield call(api.createUnit, payload);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        yield put(actions.loadUnits());
    }
}

function* getUnitsSaga({ payload, callback }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingUnits(true));
    const response = yield call(api.getUnits, page, limit, query);
    if (response?.success) {
        if (callback) {
            callback(response);
        } else {
            yield put(actions.loadedUnits(response));
        }
    }
    yield put(actions.loadingUnits(false));
}

function* editUnitSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editUnit, id, data);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        yield put(actions.loadUnits());
    }
}

function* deleteUnitSaga({ payload }) {
    const response = yield call(api.deleteUnit, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        yield put(actions.loadUnits());
    }
}

function* loadRoadWorksGroupsSaga({ payload }) {
    yield put(actions.loadingRoadWorksGroups(true));

    const response = yield call(api.getGroups, payload);

    if (response?.success) {
        yield put(actions.loadedRoadWorksGroups(response));
    }

    yield put(actions.loadingRoadWorksGroups(false));
}

function* createRoadWorksGroupSaga ({ payload, callback }) {
    const response = yield call(api.createGroup, payload);

    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        callback();
    }
}

function* editRoadWorksGroupSaga({ payload, callback }) {
    const { id, data } = payload;

    const response = yield call(api.editGroup, id, data);

    if (response?.success) {
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        callback();
    }
}

function* deleteRoadWorksGroupSaga({ payload, callback }) {
    const response = yield call(api.deleteGroup, payload);

    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        callback();
    }
}

function* loadWorksSaga({ payload: params, callback }) {
    yield put(actions.loadingWorks(true));
    const response = yield call(api.getWorks, params);
    if (response?.success) {
        if (callback) {
            callback(response.data);
        } else {
            yield put(actions.loadedWorks(response));
        }
    }

    yield put(actions.loadingWorks(false));
}

function* createWorkSaga ({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.createWork, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        yield callback?.();
    }

    yield put(actions.loadingButton(false));
}

function* editWorkSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));

    const response = yield call(api.editWork, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        yield callback?.();
    }

    yield put(actions.loadingButton(false));
}

function* deleteWorkSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.deleteWork, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        yield callback?.();
    }

    yield put(actions.loadingButton(false));
}

function* createStageSyncRoadWorkSaga({ payload }) {
    const { id, orderId, data } = payload;
    const response = yield call(api.createStageSyncRoadWork, id, { road_works: data } );

    if (response?.success) {
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        yield put(actions.loadStagesList(1, 25, { order_ids: [orderId] }));
        // yield put(actions.createSyncArray(data));
    }
}

function* getConsolidateReportSaga({ payload }) {
    const config = { params:  payload };
    const response = yield call(api.getConsolidateReport, config);
    if (response?.success) {
        yield messageHelper(response);
    }
}

function* getStageScheduleSaga({ payload, callback }) {
    yield put(actions.loadingStageSchedule(true));
    const response = yield call(api.getStageSchedule, payload);
    if (response?.success) {
        yield put(actions.loadedStageSchedule(response.data));
        callback && callback();
    }
    yield put(actions.loadingStageSchedule(false));
}

function* createStageScheduleSaga({ payload, callback }) {
    yield put(actions.loadingStageSchedule(true));
    const response = yield call(api.createStageSchedule, payload);
    if (response?.success) {
        callback && callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
    yield put(actions.loadingStageSchedule(false));
}

function* getKDMSaga({ payload }) {
    const response = yield call(api.getKDM, payload);
    if (response?.success) {
        yield put(actions.loadedKDM(response.data));
    }
}

function* getKDMReportSaga({ payload }) {
    const response = yield call(api.getKDMReport, payload);

    if (response?.success) {
        yield messageHelper(response);
    }
}

function* loadPlanFactReportSaga({ payload }) {
    const report = payload?.report;

    yield put(actions.loadingPlanFactReport(true));
    const response = yield call(api.getPlanFactReport, payload);

    if (response?.success) {
        if (report) {
            yield messageHelper(response);
        } else {
            yield put(actions.loadedPlanFactReport(response.data));
        }
    }

    yield put(actions.loadingPlanFactReport(false));
}

function* loadPlanFactReportTypesSaga() {
    const response = yield call(api.getPlanFactReportTypes);

    if (response?.success) {
        yield put(actions.loadedPlanFactReportTypes(response.data));
    }
}

function* loadDefectsSaga({ payload }) {
    yield put(actions.loadingDefects(true));
    const response = yield call(api.getDefects, payload);
    if (response) {
        yield put(actions.loadedDefects(response));
    }
    yield put(actions.loadingDefects(false));
}

function* loadDefectsSidebarSaga({ payload, refresh }) {
    const page = payload?.page || 1;
    yield put(actions.loadingDefectsSidebar(true));
    const response = yield call(api.getDefectsSidebar, payload);
    if (response) {
        yield put(actions.loadedDefectsSidebar(response, page === 1, refresh || false));
    }
    yield put(actions.loadingDefectsSidebar(false));
}
function* loadDefectsPolygonSaga({ payload }) {
    yield put(actions.loadingDefectsPolygon(true));
    yield put(setMapLoading('rw_defects', true));
    const polygon = yield call(api.getDefectsPolygon, payload);
    if (polygon) {
        yield put(actions.loadedDefectsPolygon(polygon.data || []));
    }
    yield put(actions.loadingDefectsPolygon(false));
    yield put(setMapLoading('rw_defects', false));
}

function* createDefectSaga({ payload, callback }) {
    yield put(actions.loadingDefects(true));
    const request = yield call(api.createDefect, payload);
    yield put(actions.savedDefects(request?.success || false));
    callback && callback(request?.success || false);
    if (request?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    yield put(actions.loadingDefects(false));
}
function* editDefectSaga({ payload: { id, data }, callback }) {
    yield put(actions.loadingDefects(true));
    const request = yield call(api.editDefect, id, data);
    yield put(actions.savedDefects(request?.success || false));
    callback && callback(request?.success || false);
    if (request?.success) {
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
    yield put(actions.loadingDefects(false));
}
function* deleteDefectSaga({ payload, callback }) {
    yield put(actions.loadingDefects(true));
    const request = yield call(api.deleteDefect, payload);
    yield put(actions.savedDefects(request?.success || false));
    callback && callback(request?.success || false);
    if (request?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
    yield put(actions.loadingDefects(false));
}

function* loadDefectHeatMapSaga({ payload }) {
    yield put(actions.loadingDefectHeatMap(true));
    const response = yield call(api.defectHeatMap, payload);
    if (response?.success) {
        yield put(actions.loadedDefectHeatMap(response.data || []));
    }
    yield put(actions.loadingDefectHeatMap(false));
}

function* defectStatusesSaga() {
    const request = yield call(api.defectStatuses);
    yield put(actions.loadedDefectStatuses(request.data || []));
}

function* loadNearDefectsSaga({ payload }) {
    const response = yield call(api.getNearDefects, payload);
    if (response?.success) {
        yield put(actions.loadedNearDefects(response.data));
    }
}

function* loadSettingsSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingSettings(true));
    const response = yield call(api.getSettings, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedSettings(response));
    }
    yield put(actions.loadingSettings(false));
}

function* editSettingSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editSetting, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        callback?.();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* editSettingsListSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.editSettingsList, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }

    yield put(actions.loadingButton(false));
}

function* loadShowcaseSaga() {
    yield put(actions.loadingShowcase(true));
    const response = yield call(api.loadShowcase);
    if (response?.success) {
        yield put(actions.loadedShowcase(response.data));
    }
    yield put(actions.loadingShowcase(false));
}

function* loadSettingsGroupSaga() {
    yield put(actions.loadingSettingsGroup(true));
    const response = yield call(api.getSettingsGroup);
    if (response?.success) {
        yield put(actions.loadedSettingsGroup(response));
    }
    yield put(actions.loadingSettingsGroup(false));
}

function* editSettingsGroupSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editSettingsGroup, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* loadOrderTypesSaga({ payload }) {
    yield put(actions.loadingOrderTypes(true));
    const response = yield call(api.getOrderTypes, payload);
    if (response?.success) {
        yield put(actions.loadedOrderTypes(response));
    }

    yield put(actions.loadingOrderTypes(false));
}

function* createOrderTypeSaga({ payload, callback }) {
    const response = yield call(api.createOrderType, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        yield callback?.();
    }
}

function* editOrderTypeSaga({ payload, callback }) {
    const { id, data } = payload;

    const response = yield call(api.editOrderType, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        yield callback?.();
    }
}

function* deleteOrderTypeSaga({ payload, callback }) {
    const response = yield call(api.deleteOrderType, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        yield callback?.();
    }
}

function* loadNearRoadWorksSaga({ payload, callback }) {
    yield put(actions.loadingNearRoadWorks(true));
    const response = yield call(api.getNearRoadWorks, payload);
    if (response?.success) {
        if (callback) {
            callback(response.data);
        } else {
            yield put(actions.loadedNearRoadWorks(response));
        }
    }
    yield put(actions.loadingNearRoadWorks(false));
}

function* loadRepairObjectIndicatorsSaga({ payload }) {
    yield put(actions.loadingRepairObjectIndicators(true));
    const response = yield call(api.getRepairObjectIndicators, payload);
    if (response?.success) {
        yield put(actions.loadedRepairObjectIndicators(response));
    }

    yield put(actions.loadingRepairObjectIndicators(false));
}

function* createRepairObjectIndicatorSaga({ payload, callback }) {
    const response = yield call(api.createRepairObjectIndicator, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
        yield callback?.();
    }
}

function* editRepairObjectIndicatorSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editRepairObjectIndicator, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
        yield callback?.();
    }
}

function* deleteRepairObjectIndicatorSaga({ payload, callback }) {
    const response = yield call(api.deleteRepairObjectIndicator, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.DELETE_SUCCESS);
        yield callback?.();
    }
}

function* loadRepairObjectsSaga({ payload }) {
    yield put(actions.loadingRepairObjects(true));
    const response = yield call(api.getRepairObjects, payload);
    if (response?.success) {
        yield put(actions.loadedRepairObjects(response));
    }

    yield put(actions.loadingRepairObjects(false));
}

function* createRepairObjectSaga({ payload, callback }) {
    const response = yield call(api.createRepairObject, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
        yield callback?.();
    }
}

function* editRepairObjectSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editRepairObject, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
        yield callback?.();
    }
}

function* deleteRepairObjectSaga({ payload, callback }) {
    const response = yield call(api.deleteRepairObject, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.DELETE_SUCCESS);
        yield callback?.();
    }
}

function* loadRepairFrequencySaga({ payload }) {
    yield put(actions.loadingRepairFrequency(true));
    const response = yield call(api.getRepairFrequency, payload);
    if (response?.success) {
        yield put(actions.loadedRepairFrequency(response));
    }
    yield put(actions.loadingRepairFrequency(false));
}

function* loadRepairTypesSaga({ payload }) {
    yield put(actions.loadingRepairTypes(true));
    const response = yield call(api.getRepairTypes, payload);
    if (response?.success) {
        yield put(actions.loadedRepairTypes(response));
    }
    yield put(actions.loadingRepairTypes(false));
}

function* loadSheetsAssessmentRoadSaga({ payload }) {
    yield put(actions.loadingSheetsAssessmentRoad(true));
    const response = yield call(api.getSheetsAssessmentRoad, payload);
    if (response?.success) {
        yield put(actions.loadedSheetsAssessmentRoad(response));
    }
    yield put(actions.loadingSheetsAssessmentRoad(false));
}

function* createSheetsAssessmentRoadSaga({ payload, callback }) {
    yield put(actions.loadingSheetsAssessmentRoadForm(true));
    const response = yield call(api.createSheetsAssessmentRoad, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        yield callback?.();
    }
    yield put(actions.loadingSheetsAssessmentRoadForm(false));
}

function* editSheetsAssessmentRoadSaga({ payload, callback }) {
    yield put(actions.loadingSheetsAssessmentRoadForm(true));
    const { id, data } = payload;

    const response = yield call(api.editSheetsAssessmentRoad, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        yield callback?.();
    }
    yield put(actions.loadingSheetsAssessmentRoadForm(false));
}

function* deleteSheetsAssessmentRoadSaga({ payload, callback }) {
    yield put(actions.loadingSheetsAssessmentRoadForm(true));
    const response = yield call(api.deleteSheetsAssessmentRoad, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        yield callback?.();
    }
    yield put(actions.loadingSheetsAssessmentRoadForm(false));
}

function* roadWorksColorsSaga() {
    const response = yield call(api.roadWorksColors);
    if (response?.success) {
        yield put(actions.loadedRoadWorksColors(response.data));
    }
}

function* loadShowcaseOrdersSaga() {
    yield put(actions.loadingShowcaseOrders(true));
    const response = yield call(api.loadShowcaseOrders);
    if (response?.success) {
        yield put(actions.loadedShowcaseOrders(response.data));
    }
    yield put(actions.loadingShowcaseOrders(false));
}

function* loadShowcaseOrdersReportSaga() {
    yield put(actions.loadingShowcaseOrdersReport(true));
    const response = yield call(api.loadShowcaseOrdersReport);
    if (response?.success) {
        yield put(actions.loadedShowcaseOrdersReport(response.data));
    }
    yield put(actions.loadingShowcaseOrdersReport(false));
}

function* loadRoadWorkReportOrdersSaga({ payload }) {
    yield put(actions.loadingRoadWorkReportOrders(true));
    const response = yield call(api.loadRoadWorkReportOrders, payload);
    if (response?.success) {
        yield put(actions.loadedRoadWorkReportOrders(response));
    }
    yield put(actions.loadingRoadWorkReportOrders(false));
}

function* loadRoadWorkOrderOrganizationSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.loadOrganizationsByOrderId, payload);
    if (response?.success) {
        yield fnCallback?.(response.data);
    }
    yield fnLoading?.(false);
}


export default function* saga() {
    yield all([
        takeLatest(types.GET_STATUSES, getStatusesRW),
        takeLatest(types.LOAD_ORDERS_REPORT, getOrdersReportSaga),
        takeLatest(types.GET_ORDERS, getOrdersRWSaga),
        takeLatest(types.LOAD_ROADWORKS, loadRoadWorksSaga),
        takeLatest(types.GET_POLYGON, getForPolygonRW),
        takeLatest(types.EDIT_ROADWORK, editRW),
        takeLatest(types.CREATE_ROADWORK, createNewRW),
        takeLatest(types.DELETE_ROADWORK, deleteRW),
        takeLatest(types.GET_HISTORY, getHistoryRW),
        takeLatest(types.GET_REPORT, getReportRW),

        takeLatest(types.LOAD_ROADWORK_ORDERS, loadRwOrdersSaga),
        takeLatest(types.LOAD_ORDER_STATUSES, loadRwOrderStatusesSaga),
        takeLatest(types.LOAD_ORDER_DIRECTIONS, loadOrderDirectionsSaga),
        takeLatest(types.CREATE_ROADWORK_ORDER, createRwOrderSaga),
        takeLatest(types.EDIT_ROADWORK_ORDER, editRwOrderSaga),
        takeLatest(types.DELETE_ROADWORK_ORDER, deleteRwOrderSaga),
        takeLatest(types.LOAD_STAGES_STATUSES, loadStagesStatusesSaga),
        takeLatest(types.LOAD_STAGE_CARGO_TYPES, loadStageCargoTypesSaga),
        takeLatest(types.LOAD_STAGES_LIST, loadStagesListSaga),
        takeLatest(types.CREATE_STAGE, createStageSaga),
        takeLatest(types.EDIT_STAGE, editStageSaga),
        takeLatest(types.DELETE_STAGE, deleteStageSaga),
        takeLatest(types.EDIT_SEQUENCE_STAGES, editSequenceStagesSaga),

        takeLatest(types.LOAD_DEFECT_GROUP, loadDefectGroupListSaga),
        takeLatest(types.CREATE_DEFECT_GROUP, createDefectGroupSaga),
        takeLatest(types.EDIT_DEFECT_GROUP, editDefectGroupSaga),
        takeLatest(types.DELETE_DEFECT_GROUP, deleteDefectGroupSaga),

        takeLatest(types.LOAD_DEFECT_TYPES, loadDefectTypesSaga),
        takeLatest(types.CREATE_DEFECT_TYPES, createDefectTypesSaga),
        takeLatest(types.EDIT_DEFECT_TYPES, editDefectTypesSaga),
        takeLatest(types.DELETE_DEFECT_TYPES, deleteDefectTypesSaga),

        takeLatest(types.LOAD_TYPE_OF_ROAD_DEFECTIONS, getTypeOfRoadDefectionsSaga),
        takeLatest(types.LOAD_TYPE_CREATION, getTypeCreationSaga),

        takeLatest(types.CREATE_UNIT, createUnitSaga),
        takeLatest(types.LOAD_UNITS, getUnitsSaga),
        takeLatest(types.EDIT_UNIT, editUnitSaga),
        takeLatest(types.DELETE_UNIT, deleteUnitSaga),

        takeLatest(types.LOAD_ROADWORKS_GROUPS, loadRoadWorksGroupsSaga),
        takeLatest(types.CREATE_ROADWORKS_GROUP, createRoadWorksGroupSaga),
        takeLatest(types.EDIT_ROADWORKS_GROUP, editRoadWorksGroupSaga),
        takeLatest(types.DELETE_ROADWORKS_GROUP, deleteRoadWorksGroupSaga),

        takeLatest(types.LOAD_WORKS, loadWorksSaga),
        takeLatest(types.CREATE_WORK, createWorkSaga),
        takeLatest(types.EDIT_WORK, editWorkSaga),
        takeLatest(types.DELETE_WORK, deleteWorkSaga),

        takeLatest(types.STAGE_SYNC_ROADWORK, createStageSyncRoadWorkSaga),

        takeLatest(types.GET_CONSOLIDATE_REPORT, getConsolidateReportSaga),

        takeLatest(types.GET_STAGE_SCHEDULE, getStageScheduleSaga),
        takeLatest(types.CREATE_STAGE_SCHEDULE, createStageScheduleSaga),

        takeLatest(types.GET_KDM, getKDMSaga),
        takeLatest(types.GET_KDM_REPORT, getKDMReportSaga),
        takeLatest(types.LOAD_PLAN_FACT_REPORT, loadPlanFactReportSaga),
        takeLatest(types.LOAD_PLAN_FACT_REPORT_TYPES, loadPlanFactReportTypesSaga),

        // слой дефекрты
        takeLatest(types.LOAD_DEFECTS, loadDefectsSaga),
        takeLatest(types.LOAD_DEFECTS_SIDEBAR, loadDefectsSidebarSaga),
        takeLatest(types.LOAD_DEFECTS_POLYGON, loadDefectsPolygonSaga),

        // ced дефекта
        takeLatest(types.CREATE_DEFECT, createDefectSaga),
        takeLatest(types.EDIT_DEFECT, editDefectSaga),
        takeLatest(types.DELETE_DEFECT, deleteDefectSaga),

        takeLatest(types.LOAD_DEFECT_HEAT_MAP, loadDefectHeatMapSaga),
        // emploeeys
        takeLatest(types.LOAD_DEFECT_STATUSES, defectStatusesSaga),
        takeLatest(types.LOAD_NEAR_DEFECTS, loadNearDefectsSaga),

        takeLatest(types.LOAD_SETTINGS, loadSettingsSaga),
        takeLatest(types.EDIT_SETTING, editSettingSaga),
        takeLatest(types.EDIT_SETTINGS_LIST, editSettingsListSaga),

        takeLatest(types.LOAD_SHOWCASE, loadShowcaseSaga),

        // settings group
        takeLatest(types.LOAD_SETTINGS_GROUP, loadSettingsGroupSaga),
        takeLatest(types.EDIT_SETTINGS_GROUP, editSettingsGroupSaga),

        // order types
        takeLatest(types.LOAD_ORDER_TYPES, loadOrderTypesSaga),
        takeLatest(types.CREATE_ORDER_TYPE, createOrderTypeSaga),
        takeLatest(types.EDIT_ORDER_TYPE, editOrderTypeSaga),
        takeLatest(types.DELETE_ORDER_TYPE, deleteOrderTypeSaga),

        takeLatest(types.LOAD_NEAR_ROAD_WORKS, loadNearRoadWorksSaga),

        // repair object indicators
        takeLatest(types.LOAD_REPAIR_OBJECT_INDICATORS, loadRepairObjectIndicatorsSaga),
        takeLatest(types.CREATE_REPAIR_OBJECT_INDICATOR, createRepairObjectIndicatorSaga),
        takeLatest(types.EDIT_REPAIR_OBJECT_INDICATOR, editRepairObjectIndicatorSaga),
        takeLatest(types.DELETE_REPAIR_OBJECT_INDICATOR, deleteRepairObjectIndicatorSaga),

        // repair objects
        takeLatest(types.LOAD_REPAIR_OBJECTS, loadRepairObjectsSaga),
        takeLatest(types.CREATE_REPAIR_OBJECT, createRepairObjectSaga),
        takeLatest(types.EDIT_REPAIR_OBJECT, editRepairObjectSaga),
        takeLatest(types.DELETE_REPAIR_OBJECT, deleteRepairObjectSaga),

        // repair frequency
        takeLatest(types.LOAD_REPAIR_FREQUENCY, loadRepairFrequencySaga),

        // repair types
        takeLatest(types.LOAD_REPAIR_TYPES, loadRepairTypesSaga),

        takeLatest(types.LOAD_SHEETS_ASSESSMENT_ROAD, loadSheetsAssessmentRoadSaga),
        takeLatest(types.CREATE_SHEETS_ASSESSMENT_ROAD, createSheetsAssessmentRoadSaga),
        takeLatest(types.EDIT_SHEETS_ASSESSMENT_ROAD, editSheetsAssessmentRoadSaga),
        takeLatest(types.DELETE_SHEETS_ASSESSMENT_ROAD, deleteSheetsAssessmentRoadSaga),

        takeLatest(types.LOAD_ROAD_WORKS_COLORS, roadWorksColorsSaga),

        takeLatest(types.LOAD_SHOWCASE_ORDERS, loadShowcaseOrdersSaga),
        takeLatest(types.LOAD_SHOWCASE_ORDERS_REPORT, loadShowcaseOrdersReportSaga),

        takeLatest(types.LOAD_ROAR_WORKS_REPORT_ORDERS, loadRoadWorkReportOrdersSaga),
        takeLatest(types.LOAD_RW_ORGANIZATIONS_BY_ORDER_ID, loadRoadWorkOrderOrganizationSaga),
    ]);
}
