import React, { useEffect, useState } from 'react';
import { getReportRW, getStatusesRW } from '../../../../../redux/RoadWorks/actions';
import { roadworksSelectors } from '../../../../../redux/RoadWorks';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DateRange from '../../../../common/Dates/DateRange';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import cn from 'classnames';
import titles from '../../../../../helpers/constants/titles';
import { fullDateTimeWithTimeZone } from '../../../../../helpers/date.config';
import GetReports from '../../../../common/GetReports';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';


const Filter = () => {
    const dispatch = useDispatch();

    const statuses = useSelector (roadworksSelectors.statuses);

    const [status, setStatus] = useState('');
    const [selectedStartDate, handleStartDateChange] = useState(null);
    const [selectedEndDate, handleEndDateChange] = useState(null);
    const [selectedReportFormats, setSelectedReportFormats] = useState([]);

    useEffect(() => {
        dispatch(getStatusesRW());
    }, [dispatch]);

    const resetFilter = () => {
        setStatus('');
        setSelectedReportFormats([]);
        handleEndDateChange(null);
        handleStartDateChange(null);
    };

    const packageData = {
        report: 13,
        formats: selectedReportFormats.map(({ id }) => id),
        status: status,
        date_start: fullDateTimeWithTimeZone(selectedStartDate),
        date_end: fullDateTimeWithTimeZone(selectedEndDate)
    };

    const handleDownloadReport = () => {
        const paramsWithoutEmpty = removeEmptyFields(packageData);
        dispatch(getReportRW(paramsWithoutEmpty));
    };
    const handleChangeStatus = (event) => setStatus(event.target.value);

    const [isDisabled, setIsDisabled] = useState(false);
    const isDisabledHandler = (value) => {
        setIsDisabled(value);
    };

    return (
        <div className="filter">
            <div className={cn('filter__wrap', 'filter__wrap__more__field')}>
                <FormControl size="small" variant="outlined">
                    <InputLabel>{titles.SELECT_TYPE}</InputLabel>
                    <Select
                        value={status || ''}
                        onChange={handleChangeStatus}
                        label={titles.SELECT_TYPE}
                    >
                        <MenuItem value="">
                            {titles.NOT_CHOSEN}
                        </MenuItem>
                        {Object.keys(statuses)?.map((item, index) => (
                            <MenuItem key={index} value={item}>{statuses[item]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <GetReports
                    selected={selectedReportFormats}
                    onChange={setSelectedReportFormats}
                    reportId={13}                    
                />
                <DateRange
                    handleDate={handleStartDateChange}
                    handleEndDate={handleEndDateChange}
                    valueStartDate={selectedStartDate}
                    valueEndDate={selectedEndDate}
                    isDisabledHandler={isDisabledHandler}
                />
            </div>
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilter
                        },
                        {
                            ...buttonsTypes.getReport,
                            onClick: handleDownloadReport,
                            disabled: isDisabled || !selectedReportFormats.length
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;
