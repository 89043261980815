import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from './api.methods';
import * as actions from './actions';
import * as types from './types';

function* loadIncidentsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingIncidents(true));
    const response = yield call(api.getIncidents, page, limit, params);
    if (response?.success) {
        yield put(actions.loadedIncidents(response.data));
    }
    yield put(actions.loadingIncidents(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_INCIDENTS, loadIncidentsSaga),
    ]);
}
