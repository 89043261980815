import {
    useEffect,
    useState
} from 'react';
import titles from '../../../../../helpers/constants/titles';
import FormButtons, {
    buttonsTypes
} from '../../../../common/FormButtons';
import Modal from '../../../../common/Modal';
import { useValidation } from '../../../../../helpers/hooks';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import { clearValidation } from '../../../../../redux/Validation/actions';
import {
    createDefectTypes,
    editDefectTypes,
} from '../../../../../redux/RoadWorks/actions';
import {
    Button,
    FormGroup,
    TextField
} from '@mui/material';
import ColorPicker from '../../../../common/ColorPicker';
import DefectGroups from '../../../../common/Autocomplete/RoadWorks/DefectGroups';
import FormInfoWrapper from '../../../../common/FormInfoWrapper';

const DTModal = ( { onClose, isNew, isOpen, elem = {}, reloadList } ) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);
    const initialState = {
        name: elem?.name || '',
        group_id: elem?.group_id || '',
        color: elem?.color || ''
    };

    const [data, setData] = useState(initialState);
    const [selectedDefectGroup, setSelectedDefectGroup] = useState(isNew ? '' : elem.group);
    const [openColorPicker, setOpenColorPicker] = useState(false);
    
    const onChange = (e) => {
        const { name, value } = e.target;
        validation.deleteKey(name);
        setData({ ...data, [name]: value });
    };

    const handleCreateDefectTypes = () => {
        dispatch(createDefectTypes(data, () => {
            reloadList();
            onClose();
        }));
    };

    const handleEditDefectTypes = () => {
        dispatch(editDefectTypes(elem.id, data, () => {
            reloadList();
            onClose();
        }));
    };

    const handleChange = (key) => (value) => {
        setData({ ...data, [key]: value });
    }; 

    const toggleOpenColorPicker = () => setOpenColorPicker(!openColorPicker);

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
            dispatch(clearValidation());
        }
    }, [dispatch, onClose, requestStatus, validation]);

    return (
        <Modal
            title={isNew ? 'Добавить тип недостатков инфраструктуры': 'Изменить тип недостатков инфраструктуры'}
            onClose={onClose}
            maxWidthProp={'sm'}
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    isNew
                        ? {
                            ...buttonsTypes.create,
                            onClick: handleCreateDefectTypes,
                            disabled: !data.name || !data.group_id || !data.color
                        }
                        : {
                            ...buttonsTypes.save,
                            onClick: handleEditDefectTypes,
                            disabled: !data.name || !data.group_id || !data.color
                        }
                ]}
            />}
        >
            <div className="modal__form">
                <FormInfoWrapper className="block" error={validation.isKey('name')} helperText={validation.get('name')}>
                    <TextField
                        required
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={data.name}
                        onChange={onChange}
                        name="name"
                        error={validation.isKey('name')}
                    />
                </FormInfoWrapper>

                <div className="block">
                    <DefectGroups
                        onChange={(item) => {
                            setSelectedDefectGroup(item);
                            setData({ ...data, group_id: item.id });
                            // setIsOpenDefectGroup(false);
                        }}
                        selected={selectedDefectGroup}
                    />
                </div>

                <FormInfoWrapper className="form__button" error={validation.isKey('color')} helperText={validation.get('color')}>
                    <FormGroup >
                        <Button
                            variant={'contained'}
                            color={'inherit'}
                            onClick={toggleOpenColorPicker}
                        >
                            <span>Цвет:</span>
                            <div
                                style={{
                                    height: 20,
                                    width: 120,
                                    backgroundColor: data.color,
                                    borderRadius: 2,
                                    marginLeft: 10
                                }}
                            />
                        </Button>

                        <ColorPicker
                            color={data.color}
                            open={openColorPicker}
                            onClose={toggleOpenColorPicker}
                            onChangeColor={handleChange('color')}
                        />
                    </FormGroup>
                </FormInfoWrapper>                
            </div>
        </Modal>
    );
};

export default DTModal;