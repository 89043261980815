import React from 'react';
import {
    Paper,
} from '@mui/material';


const ServiceLevel = ({
    data = {},
}) => {
    const {
        title = '',
        datas = {},
    } = data || {};

    const {
        level: levelData = '',
        title: titleData = '',
        value: valueData = '',
        units: unitsData = '',
        color: colorData = '#ff0000',
    } = datas?.total || {};

    if (Object.keys(datas).length > 0) {
        return (
            <Paper
                variant="outlined"
                square
                style={{
                    padding: '1rem',
                    textAlign: 'center',
                    width: '49.5%'
                }}
            >
                {title}
                <div>
                    <span style={{ fontSize: '2rem', color: colorData }}>{levelData ?? ''}</span> {/*titleData ?? ''*/}
                    {/*<span style={{ whiteSpace: 'nowrap' }}>*/}
                    {/*    <span style={{ fontSize: '2rem', color: colorData }}> {valueData ?? ''}</span> {unitsData ?? ''}*/}
                    {/*</span>*/}
                </div>
            </Paper>
        );
    }

    return null;
};

export default ServiceLevel;