import { useMemo } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import L from 'leaflet';

import { loadComplexStatuses } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import { useStoreFromSelector } from 'helpers/hooks';
import { Map, MapControl, Marker, PolyLine, ToolTip } from 'components/MapComponents/leaflet';

import videoCameraIcon from '../../../../img/icons/map/video-white.svg';

import styles from './videoFixation.module.scss';

function MapModal({ open, onClose, data }) {

    const complexStatuses = useStoreFromSelector(loadComplexStatuses, dorisControlSelectors.doris_complex_statuses);
    const complexStatusesObj = useMemo(() => complexStatuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [complexStatuses]);

    const dataWithoutNull = data?.filter(item => item.lat || item.lon !== null );

    const createHtml = (status) => {
        const color = complexStatusesObj[status]?.color;
        return (
            `<div class="${styles.mapIconDiv}" style="background-color: ${color?.color}">
               <img src="${videoCameraIcon}" alt="videoCamera" />
            </div>`
        );
    };

    const markers = dataWithoutNull?.map(item => {
        const icon = L.divIcon({
            className: styles.mapIcon,
            html: createHtml(item.status)
        });
        const { lat, lon } = item;
        return (
            <Marker
                key={item.id}
                icon={icon}
                latlng={[lat, lon]}
            >
                <ToolTip direction="top">
                    <div>
                        <b>Камера</b>: {item.name}
                    </div>
                </ToolTip>
            </Marker>
        );
    });

    const styleLine = {
        weight: 0,
        smoothFactor: 0
    };

    const coordinates = dataWithoutNull?.map(item => [item.lat, item.lon]);

    return (
        <Dialog open={open} fullScreen>
            <DialogTitle>
                <Tooltip title="Закрыть">
                    <IconButton onClick={onClose} size="large">
                        <Clear/>
                    </IconButton>
                </Tooltip>
                <span>
                    Фиксация нарушений ПДД
                </span>
            </DialogTitle>
            <DialogContent>
                {dataWithoutNull?.length
                    ? <Map>
                        <MapControl>
                            {markers}
                            <PolyLine
                                style={styleLine}
                                coordinates={coordinates}
                                moveToAfterInit={true}
                            />
                        </MapControl>
                    </Map>
                    : <div>
                        Нет камер с координатами
                    </div>
                }
            </DialogContent>
        </Dialog>
    );
}

export default MapModal;
