import { useDispatch } from 'react-redux';

import { loadedSummarySchedule } from 'redux/TransportPassenger/actions';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import formatDate from 'helpers/constants/dateFormat';
import SelectStations from 'components/common/Autocomplete/PassengerTransport/Stations';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({ initialFilter, filter, setFilter, onSearch }) => {
    const dispatch = useDispatch();

    const resetFilter = (needRefresh) => {
        setFilter(initialFilter);
        //мы не можем делать запрос без фильтра, поэтому просто очищаем список в сторе
        needRefresh && dispatch(loadedSummarySchedule([]));
    };

    const updateFilter = (filters) => {
        if (Object.keys(filters).length > 0) {
            setFilter(filters);
            onSearch(filters);
        }
    };

    return (
        <LayoutFilter
            filter={filter}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            disabled={!filter?.station_id || !filter?.date}
        >
            <LayoutFilter.Visible>
                <SelectStations
                    selected={filter?.station_id || {}}
                    onChange={(station_id) => setFilter(prev => ({ ...prev, station_id }))}
                />
                
                <SingleKeyboardDateTimePicker
                    label={'Дата'}
                    value={filter?.date || null}
                    onChange={(date) => setFilter(prev => ({ ...prev, date }))}
                    pickerFormat={formatDate.DATE_FOR_PICKER}
                    dateOnly={true}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
