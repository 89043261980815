import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';

import RenderTable from './RenderTable';

const PrintTable = ({ currentScheduleEvents, item }) => {

    const noDataMessage = <span style={{ color: 'red' }}>{messages.INFO_IS_NOT_FOUND}</span>;

    return (
        <>
            <div>
                <div>
                    <strong>
                        {item?.transport_type_text || messages.NO_DATA}
                    </strong>{' '}
                    <strong>
                        №:
                    </strong>&nbsp;
                    {item?.num_route || messages.NO_DATA}
                </div>
                <h2>{item?.name}</h2>
                <div>
                    <strong>
                        Статус:
                    </strong>&nbsp;
                    {item?.status_text || messages.NO_DATA}
                </div>
                <div>
                    <strong>
                        Начало:
                    </strong>&nbsp;
                    {(item?.start_at && fullDateTime(item?.start_at)) || messages.NO_DATA}
                    {' '}
                    <strong>
                        Окончание:
                    </strong>&nbsp;
                    {(item?.end_at && fullDateTime(item?.end_at)) || messages.NO_DATA}
                </div>
            </div>

            <RenderTable currentScheduleEvents={currentScheduleEvents} />
            {/* <TableContainer>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.firstCell} align="center">
                                {'График движения'}
                            </TableCell>
                            <TableCell className={classes.cells} align="center">
                                {'Остановки'}
                                <p>
                                    (Прибытие/Отправление)
                                </p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentScheduleEvents?.map((item) =>
                            <TableRow key={item.id}>
                                <TableCell className={classes.firstCell} align="center">
                                    {item.name|| noDataMessage}
                                </TableCell>
                                <TableCell className={classes.cells} align="center" >
                                    {item.entity_list.map((element) =>
                                        (<div key={element.entity_id} className={classes.stationCells}>
                                            {element?.entity_list?.map((el,ind) => (
                                                <div key={ind} className={classes.timeCells}>
                                                    <div>
                                                        {el?.name}
                                                    </div>
                                                    <div>
                                                        {el?.time_start?.split('+')[0]} - {el?.time_end?.split('+')[0]}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>)
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer> */}
        </>
    );
};

export default PrintTable;