import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { config } from '../../../config';
import {
    loadWsAvailableChannels,
    clearWsAvailableChannels,
} from '../../../redux/WS/actions';
import useWsAlerts from './useWsAlerts';
import { wsSelectors } from 'redux/WS';
import { authSelectors } from 'redux/Auth';
import { Centrifuge } from 'centrifuge';
import WsContext from '../helper/WsContext';
import useStoreFromSelector from 'helpers/hooks/useStoreFromSelector';
import { getWsToken, getWsTokenSubscribe } from '../helper/getToken';
import { createSelector } from 'reselect';

// провайдер для centrifuge
const WsProvider = ({
    children = null
}) => {
    const dispatch = useDispatch();
    // уведомления
    const alertsEvents = useWsAlerts();

    // пользователь авторизован
    const identifier = useSelector(createSelector(authSelectors.authUserInfo, (user) => user?.identifier || null));
    // проект
    const projectCode = useSelector(authSelectors.projectCode);
    // витрина
    const is_showcase = useSelector(createSelector(authSelectors.authUserInfo, (user) => user?.is_showcase || false));
    // каналы по проекту
    const channelsByProject = useStoreFromSelector(loadWsAvailableChannels, wsSelectors.availableChannelsByProject);
    // сам объект
    const [centrifuge, setCentrifuge] = useState(null);

    // отключение от каналов
    const unsubscribeChannels = (centrifuge) => {
        if (centrifuge) {
            Object
                .values(centrifuge?.subscriptions?.() || {})
                .forEach((subscribe) => {
                    subscribe?.unsubscribe?.();
                    subscribe?.removeAllListeners?.();
                    centrifuge?.removeSubscription?.(subscribe);
                });
        }
    };

    /*-- действия на закрытие и обновление страницы */
    const handleWindowBeforeUnload = useCallback(() => {
        // отключение каналов
        unsubscribeChannels(centrifuge);
        // отключение центрифуги
        centrifuge?.disconnect?.();
        // centrifuge?.closed?.();
        setCentrifuge(null);
    }, [centrifuge]);

    // обновление и закрытие страницы
    useEffect(() => {
        window.addEventListener('beforeunload', handleWindowBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleWindowBeforeUnload);
        };
    }, [handleWindowBeforeUnload]);
    /* --*/

    useEffect(() => () => {
        // осичтка списка каналов
        dispatch(clearWsAvailableChannels());
    }, []);

    // обязательные каналы
    const channelsRequired = useMemo(() => {
        return is_showcase === false // не витрина (для витрины скрываем уведомления)
            ? Object
                .values(channelsByProject)
                .reduce((r, channel) => {
                    const { name, required = false } = channel;
                    return required ? [...r, name] : r;
                }, [])
            : [];
    }, [channelsByProject, is_showcase]);

    // создание центрифуги
    useEffect(() => {
        if (!!identifier && projectCode) {
            // url
            const apiWsCentrifuge = config.get('webSocket')?.api || '';
            if (apiWsCentrifuge) {
                // create
                const centrifugeObj = new Centrifuge(
                    apiWsCentrifuge,
                    { getToken: getWsToken }
                );

                centrifugeObj
                    .on('connected', () => setCentrifuge(centrifugeObj))
                    .on('disconnected', () => setCentrifuge(null));

                centrifugeObj.connect();

                return () => {
                    unsubscribeChannels(centrifugeObj);
                    centrifugeObj?.disconnect?.();
                    // centrifugeObj?.closed?.();
                    setCentrifuge(null);
                };
            }
        }
    }, [identifier, projectCode]);

    // подключение обязательных каналов - уведомлений
    useEffect(() => {
        if (!!centrifuge && channelsRequired.length > 0) {
            // подключение каналов
            channelsRequired?.forEach((channelName) => {
                if (centrifuge.getSubscription(channelName) === null) {
                    const newChannel = centrifuge.newSubscription(
                        channelName,
                        {
                            getToken: getWsTokenSubscribe,
                            // joinLeave: true
                        }
                    );
                    newChannel.on('publication', (events) => alertsEvents(events?.data || []));
                    newChannel.subscribe();
                }
            });
            // отключение каналов
            return () => unsubscribeChannels(centrifuge);
        }
    }, [centrifuge, channelsRequired]);

    return (
        <WsContext.Provider value={{ centrifuge }}>
            {children}
        </WsContext.Provider>
    );
};

export default WsProvider;
