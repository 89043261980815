import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { loadOrderTypes } from 'redux/RoadWorks/actions';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import type { Item, OrderTypesRWTypes } from './types';

const OrderTypesRWSelect = ({
    multiple = false,
    selected,
    onChange,
    required = false,
    disabled = false,
    label = 'Типы заявок',
    error = false,
    helperText = '',
    sortedList,
    renderLabel = (option: Item | null) => String(option?.name || ''),
    limitTags = 1,
}: OrderTypesRWTypes<Item> ) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    
    const prevData = useRef(selected);
    
    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const getList = (params: { page: number; limit: number; }) => {
        const { page, limit } = params;
        
        dispatch(loadOrderTypes(page, limit));
    };

    const handleClose = () => setShowList(false);

    const handleAccept = (data: any) => {
        onChange(data);
        setShowList(false);
    };
    
    const onReset = () => {
        const initialState = multiple ? [] : '';
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete<Item>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={renderLabel}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error} 
                helperText={helperText}
                isOpen={showList}
            />
           
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={!sortedList && getList}
                    sortedList={sortedList}
                    storeName="roadworks"
                    storeNameProps="orderTypes"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div>{el.name}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title="Выбрать тип заявки"
                />
            }
        </>
    );
};

export default OrderTypesRWSelect;
