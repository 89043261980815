import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { loadMapLayerList } from '../../../../redux/Auth/actions';
import { authSelectors } from '../../../../redux/Auth';
import useStoreFromSelector from '../../../../helpers/hooks/useStoreFromSelector';
import defaultLayer from './defaultLayer';

// хук текущий слой
const useMapCurrentLayer = () => {
    // запрос слоев
    const mapLayers = useStoreFromSelector(loadMapLayerList, authSelectors.mapLayers);
    // id выбранного слоя
    const userLayerId = useSelector(authSelectors.userMapLayer);

    const {
        layerByDefault = null,
        layersObj = {},
    } = useMemo(() => mapLayers
        ?.reduce((r, layer) => ({
            layerByDefault: !r.layerByDefault && layer.default
                ? layer
                : r.layerByDefault,
            layersObj: {
                ...r.layersObj,
                [layer.id]: layer
            }
        }), {
            layerByDefault: null,
            layersObj: {}
        })
    , [mapLayers]);

    // текущий слой
    const currentLayer = useMemo(() => {
        return layersObj[userLayerId] // выбранный пользователем
            || layerByDefault // дефортный по параметру (default)
            || defaultLayer; // дефолтный зашитый объект (2gis) если уже совсем ничего нет
    }, [layerByDefault, layersObj, userLayerId]);

    return currentLayer;
};

export default useMapCurrentLayer;
