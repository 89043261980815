import React, { useState } from 'react';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Modal from '../../../../common/Modal';
import { loadDtpStatuses, loadingEditStatus } from '../../../../../redux/Incidents/actions';
import messages from '../../../../../helpers/constants/messages';
import { useStoreProp, useValidation } from '../../../../../helpers/hooks';
import Loading from '../../../../common/Loading';

const ModalForm = ({ open, onClose, onSave, initialStatus = '' }) => {
    const validation = useValidation();

    const statuses = useStoreProp(loadDtpStatuses, 'incidents', 'statuses');
    const loadingStatuses = useStoreProp(loadingEditStatus, 'incidents', 'loadingStatuses');

    const [status, setStatus] = useState(initialStatus);

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            noPadding
            title={'Изменение статуса'}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: () => onSave(status),
                        disabled: !status
                    },
                ]}
            />}
        >
            {loadingStatuses && <Loading linear={true}/>}
            <form autoComplete="off" className="modal__form">
                <FormControl 
                    className="block" 
                    size="small" 
                    required 
                    variant="outlined"
                    error={validation.isValidation()}
                >
                    <InputLabel>Статус</InputLabel>
                    <Select
                        value={status}
                        required
                        label="Статус"
                        onChange={(e) => setStatus(e.target.value)}
                        name="status"
                    >
                        {Object.keys(statuses)?.length > 0
                            ? Object.keys(statuses)?.map((key) =>
                                <MenuItem value={key} key={key}>{statuses[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                    {validation.isValidation()
                            && Object.keys(validation.data).map(key => 
                                <FormHelperText key={key}>
                                    {validation.data[key]}
                                </FormHelperText> 
                            )
                    }
                </FormControl>
            </form>
        </Modal>
    );
};

export default ModalForm;