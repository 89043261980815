import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';

import { messageTypes, showMessage } from 'redux/Message/actions';
import { loadComplexStatuses, setDcWs } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import * as actions from 'redux/DorisControl/actions';
import mapHelper from 'helpers/mapHelper';
import { useWsActive, useWsSubscribe } from 'helpers/ws/hooks';
import { useDebounce, usePrevious, useStoreFromSelector } from 'helpers/hooks';
import { fullDateTime } from 'helpers/date.config';
import colorExtend from 'helpers/mapHelper/colorExtend';
import {
    ContextMenu,
    ContextMenuItem,
    Marker,
    PopUp,
    ToolTip,
} from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import MapLegends from 'components/common/Transport/MapLegends';

import CPVFPopup from './PopUp';
import config from './config.js';
import { createIconMarker } from './helper';
import Legend from './Legend';
import CollectorForms from './CollectorForms';
import CameraEventsFrame from './CameraEventsFrame';
import ShowActiveEvent from './ShowActiveEvent';

// камеры по дорис контролю
const Layer = (props) => {
    const { map, readOnly = false, visibleLegend = false } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const complexStatuses = useStoreFromSelector(loadComplexStatuses, dorisControlSelectors.doris_complex_statuses);
    const complexStatusesObj = useMemo(() => complexStatuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [complexStatuses]);

    const polygon = useSelector(dorisControlSelectors.polygon);
    const polygonLoading = useSelector(dorisControlSelectors.polygonLoading);
    const active = useSelector(dorisControlSelectors.active);
    const filters = useSelector(dorisControlSelectors.filters);
    const coordinationHistory = useSelector(dorisControlSelectors.coordinationHistory);
    const loadingCoordinationHistory = useSelector(dorisControlSelectors.loadingCoordinationHistory);
    const coordinationHistoryDates = useSelector(dorisControlSelectors.coordinationHistoryDates);

    const activeId = active?.id || 0;

    const prevFilters = usePrevious(filters);
    const prevLoadingCoordinationHistory = usePrevious(loadingCoordinationHistory);
    const prevPolygonLoading = usePrevious(polygonLoading);

    useWsActive((activeData) => {
        dispatch(actions.setActive(activeData));
    });

    // обновление комплекса
    useWsSubscribe('doris-control_complex_update_model_v2', (events) => {
        dispatch(setDcWs(events));
    });

    // грузим полигон
    const fetchPolygon = () => {
        const filter = getFilters(filters);
        const polygon = mapHelper.getPolygon(map);
        dispatch(actions.getForPolygon(polygon, filter));
    };

    // грузить полигон
    const loadedPolygon = useRef(true);
    const truedLoaded = useDebounce(() => { loadedPolygon.current = true; }, 1000);
    const disabledLoaded = () => {
        loadedPolygon.current = false;
        truedLoaded();
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 800);
    const handleFetchPolygon = (e) => {
        loadedPolygon.current && debounceFetchPolygon();
    };

    // добавить новый
    const handleAdd = ({ lat, lng }) => {
        dispatch(actions.setEditForm({
            lat,
            lon: lng,
        }));
    };

    useEffect(() => {
        fetchPolygon();
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
        }
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(actions.resetActive());
            dispatch(actions.clearForPolygon());
            map.closeContextMenu();
            //map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters, prevFilters]);

    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, (e) => {
            //map.fire('context_menu_close');
            handleAdd(e.latlng);
        });

        return () => {
            map.off(config.mapContextMenu.event);
            dispatch(actions.setCoordinationHistoryDates({}));
            dispatch(actions.loadedCoordinationHistory([]));
        };
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (active?.lat && active?.lon) {
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 15);
                // map.fire(`showPopup_${id}`);
                // showPopUp.current = active.id;
            }
        }
    }, [active]);

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProp }) => {
        return (
            <div>
                <ContextMenuItem
                    {...rcmProp}
                    value="Редактировать"
                    onClick={() => {
                        //map.fire('context_menu_close');
                        dispatch(actions.setEditForm(item));
                    }}
                />
                <ContextMenuItem
                    {...rcmProp}
                    value="Удалить"
                    onClick={() => {
                        //map.fire('context_menu_close');
                        dispatch(actions.setDeleteForm(item.id));
                    }}
                    className="red"
                />
            </div>
        );
    };

    const idsCoordination = Object.keys(coordinationHistoryDates).map((key) => parseInt(key));
    const newPolygon = polygon.filter(({ id }) => idsCoordination?.length === 0 || idsCoordination?.includes(id));

    const [isLoadedPolygon, setLoadedPolygon] = useState(false);
    const [isLoadedHistory, setLoadedHistory] = useState(false);


    // полигон и история прогружены
    useEffect(() => {
        if (
            Object.keys(coordinationHistoryDates).length > 0
            && isLoadedHistory
            && isLoadedPolygon
        ) {
            setLoadedHistory(false);
            setLoadedPolygon(false);

            // произошла загрузка
            if (coordinationHistory.length) {
                map.fire('close_popup_all');
                map.fitBounds(
                    [
                        ...newPolygon,
                        ...coordinationHistory
                    ].map(({ lat, lon }) => ([ lat, lon ]))
                );
            } else {
                // переход к комплексу
                map.fitBounds(
                    [
                        ...newPolygon,
                    ].map(({ lat, lon }) => ([ lat, lon ]))
                );
                dispatch(showMessage(messageTypes.warning, '','История по комплесксу не найдена'));
            }
        }
    }, [
        isLoadedHistory,
        isLoadedPolygon,
        coordinationHistoryDates,
    ]);

    // полигон загружен
    useEffect(() => {
        if (
            Object.keys(coordinationHistoryDates).length > 0
            && prevPolygonLoading === true
            && polygonLoading === false
        ) {
            setLoadedPolygon(true);
        }
    }, [prevPolygonLoading, polygonLoading, coordinationHistoryDates]);

    // история загружена
    useEffect(() => {
        if (
            Object.keys(coordinationHistoryDates).length > 0
            && loadingCoordinationHistory === false
            && prevLoadingCoordinationHistory === true
        ) {
            setLoadedHistory(true);
        }
    }, [loadingCoordinationHistory, prevLoadingCoordinationHistory, coordinationHistoryDates]);

    return (
        <>
            {newPolygon.map((item) => {
                const {
                    id,
                    lat,
                    lon,
                    is_not_valid_verification,
                } = item;
                const componentProps = {
                    ...props,
                    key: `item_${id}`,
                    attribution: {
                        slug: config.slug,
                        color: colorExtend(complexStatusesObj[item.status]?.color)
                    },
                    onClick: (latlng) => {
                        map.setView(latlng);
                        dispatch(actions.resetActive());
                        disabledLoaded();
                        // loadedPolygon.current = false;
                    },
                };

                const currentShow = activeId === id;
                if (currentShow) disabledLoaded();

                return (
                    <Marker
                        {...componentProps}
                        latlng={[lat, lon]}
                        icon={createIconMarker(colorExtend(complexStatusesObj[item.status]?.color || ''), is_not_valid_verification)}
                    >
                        <PopUp
                            minWidth={500}
                            show={currentShow}
                            onClose={() => {
                                loadedPolygon.current = true;
                                if (currentShow) {
                                    dispatch(actions.resetActive());
                                }
                            }}
                            offset={[0, 200]}
                            hideArrow
                        >
                            <CPVFPopup
                                external_id={id}
                                history={history}
                                showButtons={!readOnly}
                            />
                        </PopUp>

                        <ToolTip
                            offset={[0, -40]}
                            direction="top"
                        >
                            <div>
                                <b>Комплекс:</b>&nbsp;{item.name}
                            </div>
                            {item?.serial_number && (
                                <div>
                                    <b>Серийный номер:</b>&nbsp;{item.serial_number}
                                </div>
                            )}
                        </ToolTip>

                        {readOnly === false && (
                            <ContextMenu
                                //minWidth={155}
                            >
                                <RenderContextMenu item={item} />
                            </ContextMenu>
                        )}
                    </Marker>
                );
            })}

            {/* история комплекса */}
            {coordinationHistory.map((item) => {
                const { id, lat, lon } = item;
                return (
                    <Marker
                        key={`item_history_${id}`}
                        {...props}
                        attribution={{
                            slug: config.slug,
                            color: colorExtend(complexStatusesObj[item.status]?.color || '')
                        }}
                        latlng={[lat, lon]}
                        icon={createIconMarker(colorExtend(complexStatusesObj[item.status]?.color || ''))}
                    >
                        <ToolTip
                            offset={[0, -40]}
                            direction="top"
                            permanent={true}
                        >
                            <div>
                                <div><b>Комплекс:</b>&nbsp;{item.name}</div>
                                <div>на {fullDateTime(coordinationHistoryDates[id])}</div>
                            </div>
                        </ToolTip>

                        <PopUp
                            minWidth={500}
                            offset={[0, 150]}
                            hideArrow
                        >
                            <CPVFPopup
                                external_id={id}
                                history={history}
                                prop="coordinationHistory"
                                showButtons={false}
                            />
                        </PopUp>
                    </Marker>
                );
            })}

            <CollectorForms />

            {/* события */}
            <CameraEventsFrame />

            {/* модалка с активным event */}
            <ShowActiveEvent />

            <MapLegends
                layer={config.slug}
                visibleRequired={visibleLegend}
            >
                <Legend/>
            </MapLegends>
        </>
    );
};

export default Layer;
