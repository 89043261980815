import React from 'react';
import NotifyItem from './notifyItem';
import messages from '../../../helpers/constants/messages';
import Loading from '../../common/Loading';

const List = (props) => {

    const {
        list = [],
        // onLoadNextPage,
        loading,
        ...params
    } = props;

    const count = list.length || 0;

    if (count > 0) {
        return (
            <>
                {list.map((item) => (
                    <NotifyItem
                        key={item.id}
                        item={item}
                        {...params}
                    />
                ))}

                {/*{loading && (*/}
                {/*    <LinearProgress />*/}
                {/*)}*/}
            </>
        );
    } else {
        return (
            <div style={{
                padding: '1rem',
            }}
            >
                {loading ? (
                    <Loading className="absolute center" />
                ) : messages.DATA_IS_NOT_FOUND}
            </div>
        );
    }
};

export default List;
