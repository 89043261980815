import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { changePassword } from '../../../../redux/Auth/actions.js';
import { clearValidation, setValidation } from '../../../../redux/Validation/actions';
import Loading from '../../../common/Loading';
import {
    FormGroup,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
} from '@mui/material';
import FormButtonsComponent from '../../../common/FormButtons/FormButtonsComponent.js';
import buttonsTypes from '../../../common/FormButtons/buttonsTypes.js';
import { authSelectors } from '../../../../redux/Auth/index.js';
import Modal from '../../../common/Modal/index.js';
import FormInfoWrapper from '../../../common/FormInfoWrapper';

const formFields = [
    { label: 'Старый пароль', name: 'old_password' },
    { label: 'Новый пароль', name: 'password' },
    { label: 'Повторить новый пароль', name: 'password_confirmation' },
];

const ChangePasswordModal = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        old_password: '',
        password: '',
        password_confirmation: '',
        showold_password: false,
        showpassword: false,
        showpassword_confirmation: false,
    });

    const loading = useSelector(authSelectors.loading);
    const validation = useSelector(({ validation }) => validation);

    useEffect(() => {
        dispatch(clearValidation());
    }, [dispatch]);

    const clearData = () => {
        setValues({
            old_password: '',
            password: '',
            password_confirmation: '',
        });
    };

    const handleSend = (e) => {
        const { old_password, password, password_confirmation } = values;
        e.preventDefault();
        dispatch(clearValidation());
        dispatch(
            changePassword(
                {
                    old_password,
                    password,
                    password_confirmation,
                },
                () => {
                    clearData();
                    onClose();
                }
            )
        );
    };

    const handleCancel = (e) => {
        e.preventDefault();
        clearData();
        dispatch(clearValidation());
        onClose();
    };

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
        if (validation[name]) delete validation[name];
        dispatch(
            setValidation({
                ...validation,
            })
        );
    };

    const handleChangeVisibility = (name) => () => {
        setValues({ ...values, [name]: !values[name] });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Сменить пароль"
            showCloseInTitle
            buttons={
                <FormButtonsComponent
                    buttons={[
                        // {
                        //     ...buttonsTypes.close,
                        //     onClick: handleCancel
                        // },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSend,
                            disabled: Object.keys(validation).length > 0
                        }
                    ]}
                />
            }
        >
            <FormGroup>
                {formFields.map(({ label, name }, index) => (
                    <FormInfoWrapper
                        error={!!validation[name]}
                        helperText={validation[name]}
                        key={index}
                    >
                        <FormControl
                            className={'block'}
                            variant={'outlined'}>
                            <InputLabel>{label}</InputLabel>
                            <OutlinedInput
                                type={values['show' + name] ? 'text' : 'password'}
                                value={values[name]}
                                name={name}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position={'end'}>
                                        <IconButton
                                            aria-label={'toggle password visibility'}
                                            onClick={handleChangeVisibility('show' + name)}
                                            edge={'end'}
                                            size="large">
                                            {values['show' + name] ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </FormInfoWrapper>
                ))}
            </FormGroup>
            {loading && <Loading circular={true}/>}
        </Modal>
    );
};

export default ChangePasswordModal;
