import Incidents from 'components/pages/Reports/DTP/Incidents';
import VideoFixation from 'components/pages/Reports/VideoFixation';
import DtpGraph from 'components/pages/Reports/DTP/DtpByArea';
import DtpReportTypes from 'components/pages/Reports/DTP/DtpReportTypes';
import DtpReportCategories from 'components/pages/Reports/DTP/DtpReportCategories';
import DangersArea from 'components/pages/Reports/DTP/DangersArea';
import DtpFocus from 'components/pages/Reports/DTP/DtpFocus';
import DtpDistrictsByMonth from 'components/pages/Reports/DTP/DtpDistrictsByMonth';
import HeatMap from 'components/pages/Reports/DTP/HeatMap';
import AVK from 'components/pages/Reports/DTP/AVK';
import HeatMapConcentration from 'components/pages/Reports/DTP/HeatMapConcentration';
import EventPlans from 'components/pages/Reports/DTP/EventPlans';
import DtpByTimePeriod from 'components/pages/Reports/DTP/DtpByTimePeriod';

const routes = [
    {
        path:'/reports/dtp',
        component: Incidents,
        exact: true,
    },
    {
        path: '/reports/videofixation',
        component: VideoFixation,
        exact: true,
    },
    {
        path: '/reports/dtp-districts',
        component: DtpGraph,
        exact: true
    },
    {
        path: '/reports/dtp-types',
        component: DtpReportTypes,
        exact: true
    },
    {
        path: '/reports/dtp-categories',
        component: DtpReportCategories,
        exact: true
    },
    {
        path: '/reports/dtp-heat-map',
        component: DangersArea,
        exact: true
    },
    {
        path: '/reports/dtp-focus',
        component: DtpFocus,
        exact: true
    },
    {
        path: '/reports/dtp-districts-by-month',
        component: DtpDistrictsByMonth,
        exact: true
    },
    {
        path: '/reports/dtp/heat-map',
        component: HeatMap,
        exact: true
    },
    {
        path: '/reports/dtp-avk',
        component: AVK,
        exact: true
    },
    {
        path: '/reports/dtp/heat-map-concentration',
        component: HeatMapConcentration,
        exact: true
    },
    {
        path: '/reports/dtp/event-plans',
        component: EventPlans,
        exact: true
    },
    {
        path: '/reports/dtp/by-time',
        component: DtpByTimePeriod,
        exact: true
    },
];

export default routes;