import { useState } from 'react';

import { loadCameraViolations } from 'redux/VideoAnalytics/actions';
import messages from 'helpers/constants/messages';
import { humanDateTimeWithoutTZ } from 'helpers/date.config';
import Image from 'components/common/Image';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import { useStoreProp } from 'helpers/hooks';

import RenderEventInfo from './RenderEventInfo';
import styles from './videoAnalytic.module.scss';

function Item({ data, renderMediaModal }) {
    const violations = useStoreProp(loadCameraViolations, 'videoAnalytics', 'violations');

    const [isOpenDetectionModal, setIsOpenDetectionModal] = useState(false);

    const violation_type_text = violations[data?.violation_type];
    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {humanDateTimeWithoutTZ(data?.created_at) || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {data?.camera_name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    <span>{violation_type_text || messages.NO_VALUE}</span>
                </LSContentColumn>

                <LSContentColumn>
                    {data.grz || 'Не распознан'}
                </LSContentColumn>

                <LSContentColumn>
                    {data?.vehicle_brand || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {data?.vehicle_model || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {data?.vehicle_color || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {data.photo_ts && (
                        <button
                            onClick={() => setIsOpenDetectionModal(true)}
                            className={styles.picture}
                        >
                            <Image src={data.photo_ts} alt="Кадр детекции" />
                        </button>
                    )}
                </LSContentColumn>
            </LSContentItem>

            {isOpenDetectionModal
                && renderMediaModal({
                    item: data,
                    isOpen: isOpenDetectionModal,
                    onClose: () => setIsOpenDetectionModal(false),
                    renderLabels: (data) => <RenderEventInfo data={data} />,
                })}
        </>
    );
}

export default Item;
