import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import titles from '../../../../../helpers/constants/titles';
import { useStoreProp } from '../../../../../helpers/hooks';
import { loadRouteTypes, loadRoutesStatuses } from '../../../../../redux/TransportRoad/actions';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import FormInfoWrapper from '../../../../common/FormInfoWrapper';
import { useValidation } from '../../../../../helpers/hooks';


function Filters({ setParams }) {

    const statuses = useStoreProp(loadRoutesStatuses, 'transportRoad', 'routeStatuses');
    const transportTypes = useStoreProp(loadRouteTypes, 'transportRoad', 'routeTypes');
    const lockUpdate = useRef(false);
    const validation = useValidation();

    useEffect(() => () => {
        lockUpdate.current = false;
    }, []);

    const statusesList = useMemo(() => Object
        .keys(statuses)
        ?.map(id => ({
            id,
            label: statuses[id],
        }))
    , [statuses]);

    useEffect(() => {
        if (statusesList?.length > 0 && lockUpdate.current === false) {
            setFilterValues(prev => ({
                ...prev,
                status_id_list: statusesList?.filter(({ id }) => [1, 2].includes(Number(id)))
            }));
        }
    }, [statusesList, lockUpdate.current]);

    const initialState = {
        num: '',
        category_id: '',
        status_id_list: [],
        organization_id_list: [],
    };

    const [filterValues, setFilterValues] = useState(initialState);

    const handleChange = (key, value) => {
        setFilterValues({
            ...filterValues,
            [key]: value
        });
        validation.deleteKey(key);
    };

    const onSearch = (filter = filterValues) => {
        setParams(removeEmptyFields({
            ...filter,
            status_id_list: filter.status_id_list.map(({ id }) => id),
            organization_id_list: filter.organization_id_list.map(({ id }) => id),
        }));
    };

    const onReset = (needRefresh) => {
        setFilterValues(initialState);

        if (needRefresh) {
            setParams({});
        }
    };

    const updateFilter = (filters) => {
        if (Object.keys(filters).length > 0) {
            setFilterValues({ // обновляем локальный стейт
                ...initialState,
                ...filters,
            });

            onSearch({
                ...initialState,
                ...filters,
            });
            lockUpdate.current = true;
        }
    };

    return (
        <LayoutFilter
            onResetFilter={onReset}
            onSearch={() => onSearch()}
            filter={filterValues}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <FormInfoWrapper
                    helperText={validation.get('num')} 
                    error={validation.isKey('num')}
                >
                    <TextField
                        label="Номер"
                        value={filterValues.num}
                        onChange={(e) => handleChange('num', e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <FormInfoWrapper>
                    <Autocomplete
                        blurOnSelect={true}
                        limitTags={1}
                        freeSolo
                        multiple
                        value={filterValues?.status_id_list}
                        options={statusesList}
                        noOptionsText={titles.NO_RESULTS}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        filterSelectedOptions={true}
                        getOptionLabel={(option) => option.label || ''}
                        onChange={(_, value) => handleChange('status_id_list', value)}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label="Статус маршрута"
                            />
                        )}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    helperText={validation.get('category_id')} 
                    error={validation.isKey('category_id')}
                >
                    <FormControl variant="outlined" size="small">
                        <InputLabel id="transport-road-type-select-label">Тип</InputLabel>
                        <Select
                            label="Тип"
                            labelId="transport-road-type-select-label"
                            value={filterValues?.category_id}
                            onChange={(e) => handleChange('category_id', e.target.value)}
                            title="Тип"
                            size={'small'}
                        >
                            <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                            {transportTypes?.map((item) => (
                                <MenuItem key={item?.id} value={item?.id}>
                                    {item?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormInfoWrapper>

                <FormInfoWrapper
                    helperText={validation.get('organization_id_list')} 
                    error={validation.isKey('organization_id_list')}
                >
                    <SelectCompany
                        multiple
                        selected={filterValues.organization_id_list}
                        onChange={(value) => handleChange('organization_id_list', value)}
                        filter={{ withDeleted: 1 }}
                        selectDeletedСompanies
                    />
                </FormInfoWrapper>

            </LayoutFilter.Visible>
        </LayoutFilter>
    );
}

export default Filters;
