import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mapHelper from '../../../../helpers/mapHelper';
import { useDebounce, usePrevious } from '../../../../helpers/hooks';
import { useWsSubscribe } from '../../../../helpers/ws/hooks';
import * as actions from '../../../../redux/SituationalPlans/actions';
import config from './config.js';
import { getColorByStatus, createIconMarker } from './helper';
import {
    ContextMenuItem,
    GeoJson,
} from '../../leaflet';
import getFilters from '../../helpers/getFilters';
import { debounce, isEqual } from 'lodash';
import L from 'leaflet';
import CPopup from './PopUp';
import CollectorForms from './CollectorForms';
import useTransportCategory, { transportCategoryHelper } from '../../../../helpers/hooks/Transport/useTransportCategory';
import { loadVehicleCategories } from '../../../../redux/TransportPassenger/actions';
import { setWsEO } from '../../../../redux/SituationalPlans/actions';
import createPointGJ from '../../leaflet/helpers/createPointGJ';
import { SituationalPlansSelectors } from '../../../../redux/SituationalPlans';


const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();

    const polygon = useSelector(SituationalPlansSelectors.polygonEvents);
    const polygonLoading = useSelector(SituationalPlansSelectors.polygonEventsLoading);
    const active = useSelector(SituationalPlansSelectors.activeEvents);
    const savedEvents = useSelector(SituationalPlansSelectors.savedEvents);
    const filters = useSelector(SituationalPlansSelectors.filtersEvents);

    const getTransportCategory = useTransportCategory(
        loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    );

    const tCHelper = transportCategoryHelper(getTransportCategory);

    const [geoJson, setGeoJson] = useState([]);
    const [routesJson, setRoutesJson] = useState([]);
    const showPopUp = useRef(false);
    const prevFilters = usePrevious(filters) || {};
    const prevPolygonLoading = usePrevious(polygonLoading) || false;

    useWsSubscribe('situational-plans_event_object_update_model_v2', (events) => {
        // todo
        // console.log('situational-plans_event_object_update', events);
        dispatch(setWsEO(events));
    });

    useEffect(() => {
        if (savedEvents) {
            // console.log('cccc');
            map.closeContextMenu();
            fetchPolygon();
            dispatch(actions.setEditFormEvents());
            dispatch(actions.setSavedEvents(false));
        }
    }, [savedEvents]);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getPolygon(map);
        const filter = getFilters(filters);
        dispatch(actions.loadForPolygonEvents(polygon, filter));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();

    // добавить новый
    const handleAdd = ({ lat, lng }) => {
        dispatch(actions.setEditFormEvents({
            lat,
            lon: lng,
            geometry: createPointGJ(lat, lng),
        }));
    };

    // сдвинуться к маркеру
    // const setMapToMarker = () => {
    //     if (Object.keys(active).length > 0) {
    //         const { lat, lon } = active;
    //         if (Math.abs(lat) && Math.abs(lon)) {
    //             setTimeout(() => {
    //                 map.setView([lat, lon]);
    //             }, 200);
    //         }
    //     }
    // };

    useEffect(() => {
        fetchPolygon();
    }, []);

    useEffect(() => {
        if (polygonLoading === false && prevPolygonLoading === true && showPopUp.current) {
            // console.log('222 4444', active.id);
            const id = showPopUp.current;
            setTimeout(() => {
                map.fire(`showBy${config.slug}${id}`);
            }, 200);

            showPopUp.current = false;
        }

    }, [polygonLoading, prevPolygonLoading]);


    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
        }

        map
            // .on('movestart', handleMoveStart)
            // .on('moveend', handleMoveEnd)
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        // setMapToMarker();

        return () => {
            dispatch(actions.setActiveEvents({}));
            dispatch(actions.clearForPolygonEvents());
            map.closeContextMenu();
            //map.fire('context_menu_close');
            map
                // .on('movestart', handleMoveStart)
                // .on('moveend', handleMoveEnd)
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters, prevFilters]);

    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, (e) => {
            //map.fire('context_menu_close');
            handleAdd(e.latlng);
        });

        return () => {
            map.off(config.mapContextMenu.event);
        };
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { geometry, route = null, isClick = false } = active;

            if (geometry && !isClick) {
                showPopUp.current = active.id;
                const b = L.geoJSON(geometry).getBounds();
                map.fitBounds(b);
            }

            if (route) {
                setRoutesJson([
                    ...(Object.keys(route).length > 0 && Object.keys(route?.road)
                        ? [{
                            ...route?.road,
                            properties: {
                                data: route
                            }
                        }] : []
                    )
                ]);
            } else {
                setRoutesJson([]);
            }

            // setTimeout(() => map.fire(`showBy${active.id}`), 500);
        } else {
            setRoutesJson([]);
        }
    }, [active]);

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProp }) => {
        return (
            <div>
                <ContextMenuItem
                    {...rcmProp}
                    value="Редактировать"
                    onClick={() => {
                        //map.fire('context_menu_close');
                        dispatch(actions.setEditFormEvents(item));
                    }}
                />
                <ContextMenuItem
                    {...rcmProp}
                    value="Удалить"
                    onClick={() => {
                        //map.fire('context_menu_close');
                        dispatch(actions.setDeleteFormEvents(item.id));
                    }}
                    className="red"
                />
            </div>
        );
    };

    useEffect(() => {
        const geo = polygon.reduce((res, { geometry, route, ...props }) => ([
            ...res,
            {
                ...geometry,
                properties: {
                    ...geometry.properties,
                    data: {
                        ...props,
                        geometry
                    },
                    attribution: {
                        // type: props.type_id,
                        slug: config.slug,
                        color: getColorByStatus(props.type_id)
                    },
                },
                style: {
                    color: getColorByStatus(props.type_id),
                    weight: 7
                }
                // style: {
                //     color: '#ff0000',
                //     fill: '#ff0000',
                //     strokeWidth: 3,
                //     fillOpacity: 0.6
                // },
            }
        ]), []);

        setGeoJson(geo);
    }, [polygon]);

    return (
        <>
            <GeoJson
                {...props}
                data={geoJson}
                icon={({ type_id }) => createIconMarker(type_id)}
                popUpTemplate={(data) => <CPopup {...data} />}
                contextMenuTemplate={(item, data) => <RenderContextMenu item={item} {...data} />}
                // onClick={() => {
                //     dispatch(actions.resetActiveEvents());
                // }}
                toolTipTemplate={({ name }) => <div>{name}</div>}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, 0],
                    sticky: true,
                }}
                onClosePopup={() => {
                    dispatch(actions.resetActiveEvents());
                }}
                idPrefix={config.slug}
            />

            {/* маршруты */}
            <GeoJson
                {...props}
                data={routesJson}
                toolTipTemplate={({ name, num, category_id }) => <div>
                    <div>{name}</div>
                    <div>{tCHelper.getName(category_id)} №{num}</div>
                </div>}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, 0],
                    sticky: true,
                }}
            />

            <CollectorForms />
        </>
    );
};

export default Layer;
