import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { AppBar, Card } from '@mui/material';

import { CustomTab, CustomTabs } from 'components/common/Tabs';
import titles from 'helpers/constants/titles';

import BoardMessages from '../Messages';

import BasicData from './BasicData';
import BoardSettings from './Settings';

function InfoTabs({ selectedItem, tab = 'data', reloadList, categories }) {
    const scrollRef = useRef(null);
    
    const [value, setValue] = useState(tab);

    const scrollTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        scrollTop();
    };

    useEffect(() => {
        if (selectedItem?.id) {
            scrollTop();
        }
    }, [selectedItem?.id]);

    return (
        <Card variant="elevation" style={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
            <AppBar position="sticky" color="default">
                <CustomTabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <CustomTab
                        style={{ fontSize: '12px' }}
                        icon={<i className="fal fa-info-square"/>}
                        iconPosition="start"
                        value="data"
                        label={titles.BASIC_DATA}
                    />
                    <CustomTab
                        style={{ fontSize: '12px' }}
                        icon={<i className="fal fa-cog"/>}
                        iconPosition="start"
                        value="settings"
                        label={titles.SETTINGS}
                    />
                    <CustomTab
                        style={{ fontSize: '12px' }}
                        icon={<i className="fal fa-comment-dots"/>}
                        iconPosition="start"
                        value="messages"
                        label={titles.MESSAGES}
                    />
                    {/* <CustomTab
                        style={{ fontSize: '12px' }}
                        icon={<i className="fab fa-sourcetree" />}
                        iconPosition="start"
                        value="msgsource"
                        label={'Источники сообщений'}
                    /> */}
                </CustomTabs>
            </AppBar>
            <div style={{ height: '100%', display: 'flex' }}>
                <Scrollbars style={{ height: '100%' }} ref={scrollRef} >
                    {value === 'data'
                    && <BasicData
                        reloadList={reloadList}
                        data={selectedItem}
                        categories={categories}
                    />
                    }
                    {value === 'settings'
                    && <BoardSettings
                        data={selectedItem}
                        reloadList={reloadList}
                    />
                    }
                    {value === 'messages'
                    && <BoardMessages
                        fromTabs={true}
                        board={selectedItem}
                    />
                    }
                    {/* {value === 'msgsource'
                    && <MsgSource
                        fromTabs={true}
                        board={selectedItem}
                    />
                } */}
                </Scrollbars>
            </div>
        </Card>
    );
}

export default InfoTabs;