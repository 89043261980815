import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'redux/TrafficFlow/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import messages from 'helpers/constants/messages';
import { fullDateTimeWithoutSeconds, fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import Loading from 'components/common/Loading';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import NoData from 'components/common/Information/NoData';

import EventFilters from './Filters';

const Events = (props) => {
    const {
        id,
        isOpen, 
        onClose
    } = props;

    const dispatch = useDispatch();

    const events = useSelector(trafficFlowSelectors.traffic);
    const trafficLoading = useSelector(trafficFlowSelectors.trafficLoading);
    const [params, setParams] = useState({
        start_date: fullDateTimeWithTimeZone(getStartOf()),
        end_date: fullDateTimeWithTimeZone(getEndOf()),
    });

    useEffect(() => {
        // получение events по конкретному ПУИД за сутки
        if (id) {
            // dispatch(actions.loadTraffic(1, 1, { model_id: id, ...params }));
            dispatch(actions.loadTrafficInfo(id, params));
        }
    }, [id, params, dispatch]);

    const labels = {
        start_time: {
            title: 'Начало периода',
            value: (start_time) => start_time ? fullDateTimeWithoutSeconds(start_time) : messages.NO_DATA,
        },
        end_time: {
            title: 'Конец периода',
            value: (end_time) => end_time ? fullDateTimeWithoutSeconds(end_time) : messages.NO_DATA,
        },
        direction_text: {
            title: 'Направление',
            value: (direction) => direction ?? messages.NO_DATA,
        },
        // count: {
        //     title: 'Кол-во автомашин'
        // },
        // gap: {
        //     title: 'Средний интервал движения',
        // }
                
        zone_id: {
            title: 'Полоса',
            value: (zone_id) => zone_id ?? messages.NO_DATA,
        },
        speed: {
            title: 'Средняя скорость',
            value: (speed) => parseFloat(speed) >= 0 ? `${Math.round(speed * 100) / 100} км/ч` : messages.NO_DATA,
        },
        intensity: {
            title: 'Интенсивность',
            value: (intensity) => intensity ? `${intensity} ед./час` : messages.NO_DATA,
        },
    };

    return (
        <Modal
            medium
            isOpen={isOpen}
            onClose={onClose}
            title="Проезды"
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose,

                        }
                    ]}
                />
            }
        >
            <EventFilters setParams={setParams} trafficId={id}/>
            {trafficLoading
                ? <Loading linear={true} />
                : events?.length > 0
                    ? (
                        <table className="table-information">
                            <thead>
                                <tr>
                                    {Object.keys(labels).map((key) => {
                                        const { title } = labels[key];
                                        return (
                                            <th key={`title_${key}`}>{title}</th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {events?.map((item, index) => (
                                    <tr key={`tr_${item.uuid}_${index}`}>
                                        {Object.keys(labels).map((key) => {
                                            const { value } = labels[key];
                                            const val = item[key];

                                            return (
                                                <td key={`${item.uuid}_${key}_${index}`}>
                                                    {(value && value(val)) || val}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
                    : <NoData/>
            }
        </Modal>
    );
};

export default Events;
