import React from 'react';
import { useSelector } from 'react-redux';
import { loadVehicleCategoryList, deleteVehicleCategory, createVehicleCategory, editVehicleCategory } from '../../../../../redux/TransportPassenger/actions';
import VehicleCategories from '../../../../common/Transport/VehicleCategories';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';

const VehicleTypes = () => {
    const list = useSelector(transportPassengerSelectors.vehicleCategoryList);
    const loading = useSelector(transportPassengerSelectors.loadingVehicleCategoryList);

    return (
        <>
            <VehicleCategories 
                list={list}
                loading={loading}
                loadList={loadVehicleCategoryList}
                createCategory={createVehicleCategory}
                editCategory={editVehicleCategory}
                deleteCategory={deleteVehicleCategory}
                fromService={'passengerTransport'}
            />
        </>
    );
};

export default VehicleTypes;
