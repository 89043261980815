import { clearFilters } from 'redux/VideoCameras/actions';
import iconCluster from './icons/iconCluster';

// добавление на карте

const config = {
    name: 'Городские видеокамеры',
    slug: 'city-cameras',
    mapContextMenu: {
        title: 'Добавить городскую видеокамеру',
        event: 'add_city_camera',
        // className: styles.button,
        minWidth: 210,
    },
    clusterIcon: iconCluster,
    onAfterActivateAction: () => clearFilters(),
    layerIcon: 'far fa-cctv',
};

export default config;
