const situationalPlansTitles = {
    KEYWORD: 'Ключевое слово',
    KEYWORDS: 'Ключевые слова',
    THREAT_LEVEL: 'Уровень угрозы',
    TRANSPORT_INCIDENT_TYPE: 'Тип события',
    RESPONSE_SCENARIO: 'Сценарный план',
    DATE_AND_TIME_OF_INCIDENT: 'Дата/время события',
    THREAT_CATEGORY: 'Риски',
    EVENT_OBJECTS: 'Объекты мероприятий',
    OPERATION_NAME: 'Название операции',
    OPERATIONS: 'Операции',
    ATTACHMENTS: 'Вложения',
    CHANGE_ORGANIZATION: 'Сменить организацию',
    TYPE_CREATION_TI: 'Способ создания события',
    RELATED_DTP: 'Связанное ДТП',
    SELECT_EVENT: 'Выберите мероприятие',
    REGISTERED: 'Зарегистрировано',
    RESPONSIBLE_ORGANIZATION: 'Ответственная организация',
    WHO_IS_ASSIGNED_TO: 'На кого назначено',
    ROUTES_IN_TI_AREA: 'Маршруты в районе события',
    REACT_SCENARIO: 'Сценарий реагирования'
};

export default situationalPlansTitles;