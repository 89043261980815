import { HttpError } from './http.error';

class HttpAuthError extends HttpError {
    constructor() {
        super('Ошибка авторизации', 401);
        this.className = 'HttpAuthError';
        if (Error.captureStackTrace instanceof Function) {
            Error.captureStackTrace(this, HttpAuthError);
        }
    }
}

export { HttpAuthError };