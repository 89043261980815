import { useCallback, useContext, useEffect, useState } from 'react';
import PageLayout from '../../../../layout/PageLayout';
import { roadworksSelectors } from '../../../../../redux/RoadWorks';
import FormButtonsComponent, { buttonsTypes }  from '../../../../common/FormButtons';
import { useDispatch, useSelector } from 'react-redux';
import { LSContainer } from '../../../../common/List';
import titles from '../../../../../helpers/constants/titles';
import Item from './Item';
import messages from '../../../../../helpers/constants/messages';
import { ElemProps } from './types';
import { loadOrderTypes } from '../../../../../redux/RoadWorks/actions';
import AddEditModal from './AddEditModal';
import Context from 'helpers/context';

const OrderTypes = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const data = useSelector(roadworksSelectors.orderTypesData);
    const meta = useSelector(roadworksSelectors.orderTypesMeta);
    const loading = useSelector(roadworksSelectors.orderTypesLoading);

    const [openModal, setModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit') || 25));

    useEffect(() => {
        dispatch(loadOrderTypes(page, limit));
    }, [dispatch, limit, page]);

    const reloadList = useCallback((shouldGoToPreviousPage = false) => {        
        if (shouldGoToPreviousPage) {            
            setPage(page - 1);
        } else {            
            dispatch(loadOrderTypes(page, limit));
        }
    }, [dispatch, page, limit]);

    const renderContent = () => {
        return (
            data?.length > 0
                ? <LSContainer
                    headers={[
                        { title: titles.NAME, width: '20%' },
                        { title: titles.DESCRIPTION, width: '50%' },
                        { title: 'Действия', isActions: true }
                    ]}
                >
                    {data.map((elem: ElemProps) => (
                        <Item
                            key={elem.id} 
                            item={elem}
                            reloadList={
                                (isDelete) => reloadList(isDelete && data.length === 1)
                            }
                            permissions={permissions}
                        />
                    ))}
                </LSContainer>
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (<>
        <PageLayout
            header={'Типы заявок'}
            loading={loading}
            informPanelProps={{
                buttons: (
                    <>
                        <FormButtonsComponent
                            buttons={[
                                ...(permissions?.is_create
                                    ? [{
                                        ...buttonsTypes.add,
                                        onClick: () => setModalOpen(true),
                                    }]
                                    : []
                                ),
                            ]}
                            positionLeft
                            noPadding
                        /> 
                    </>),
                
                total: meta?.total
            }}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setPage(page),
                list: meta,
                limit,
                setLimit
            }}
        />
        {openModal
            && <AddEditModal
                isNew
                isOpen={openModal}
                onClose={() => setModalOpen(false)}
                reloadList={reloadList}
            />
        }
    </>);
};

export default OrderTypes;