import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { Button, Grid, Tooltip } from '@mui/material';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import { humanDateWithoutTZ } from 'helpers/date.config';
import colorExtend from 'helpers/mapHelper/colorExtend';
import Map from 'components/pages/Dictionaries/RoadWorks/Sites/EditorForm/Defects/Map';
import DefectList from 'components/pages/Dictionaries/RoadWorks/Sites/EditorForm/Defects/DefectList';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import { loadTypeCreation } from 'redux/RoadWorks/actions';
import { setSelectedLayer, setShowReturnTo } from 'redux/Menu/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import TabInformation from './TabInformation';
import icon from '../icons/icon';


const PopUp = (props) => {
    const hideEmpty = props?.hideEmpty || false;
    // забираем данные из полигона
    const polygon = useSelector(roadworksSelectors.polygon);
    const data = polygon?.find(({ id }) => props.uuid === id) || {};
    const { color } = data;
    const history = props.history;
    const typeCreation = useStoreProp(loadTypeCreation, 'roadworks', 'typeCreation');
    const dispatch = useDispatch();

    const [isOpenDefects, setIsOpenDefects] = useState(false);
    const [showItem, setShowItem] = useState({});

    const handleCloseDefects = () => {
        setIsOpenDefects(false);
    };

    return (
        <div className="map-popup">
            <div
                className="map-popup_header"
                style={{
                    backgroundColor: colorExtend(color)
                }}
            >
                <div>
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                    <span>Участок</span>
                </div>
            </div>
            <div className="map-popup_body">

                <TabInformation
                    hideEmpty={hideEmpty}
                    data={data}
                    title={{
                        name: {
                            title: 'Название участка',
                        },
                        status: {
                            title: titles.STATUS,
                            value: ({ status }) =>
                                status ? (
                                    <span>{props?.statuses[status]}</span>
                                ) : hideEmpty ? null : messages.INFO_IS_NOT_FOUND,
                        },
                        address: {
                            title: 'Адрес',
                            value: ({ address }) => {
                                if (address) {
                                    const { city_name, street, house } = address;
                                    return [city_name, street, house]
                                        .reduce((res, item) => item ? [...res, item] : res , [])
                                        .join(', ');
                                }
                                return null;
                            }
                        },
                        address_area: {
                            title: 'Район',
                            value: ({ address }) => {
                                if (address) {
                                    const { area } = address;
                                    return area;
                                }
                                return null;
                            }
                        },
                        organization: {
                            title: titles.ORGANIZATION,
                            value: ({ organization }) => organization?.title
                        },
                        fixated_at: {
                            title: 'Дата регистрации',
                            value: ({ fixated_at }) =>
                                fixated_at
                                    ? humanDateWithoutTZ(fixated_at)
                                    : hideEmpty ? null : messages.INFO_IS_NOT_FOUND,
                        },
                        //todo брать из запроса
                        type_creation: {
                            title: 'Способ создания',
                            value: ({ type_creation }) => {
                                return typeCreation[type_creation] || null;
                            },
                        },
                        troubleshooting_plan: {
                            title: 'Дата окончания плановая',
                            value: ({ troubleshooting_plan }) =>
                                troubleshooting_plan
                                    ? humanDateWithoutTZ(troubleshooting_plan)
                                    : hideEmpty ? null : messages.INFO_IS_NOT_FOUND,
                        },
                        troubleshooting_fact: {
                            title: 'Дата окончания фактическая',
                            value: ({ troubleshooting_fact }) =>
                                troubleshooting_fact
                                    ? humanDateWithoutTZ(troubleshooting_fact)
                                    : hideEmpty ? null : messages.INFO_IS_NOT_FOUND,
                        },
                        description: {
                            title: titles.DESCRIPTION,
                        },
                        infrastructures: {
                            title: titles.INFRASTRUCTURE,
                            value: ({ infrastructures }) => {
                                const infra = (infrastructures || []).map(({ name }) => name).join(', ');
                                return infra || null;
                            }
                        },
                        detectors: {
                            title: 'ПУИД',
                            value: ({ detectors }) => {
                                const detects = (detectors || []).map(({ name }) => name).join(', ');
                                return detects || null;
                            }
                        },
                        order_id: {
                            title: 'Номер заявки на дорожные работы',
                            value: ({ orders }) => {
                                if (orders?.length > 0) {
                                    return (
                                        <>
                                            {orders?.map(({ name, id }, index) => (
                                                <>
                                                    <Tooltip title="Перейти в справочник">
                                                        <Button
                                                            type="text"
                                                            size="small"
                                                            onClick={() => {
                                                                dispatch(
                                                                    setShowReturnTo(
                                                                        history?.location?.pathname || '/map',
                                                                        'Назад к Участкам',
                                                                    )
                                                                );
                                                                history && history.push({
                                                                    pathname: '/dictionaries/roadworks/orders',
                                                                    state: { id }
                                                                });
                                                                dispatch(setSelectedLayer());
                                                            }}
                                                        >
                                                            {name}({id})
                                                        </Button>
                                                    </Tooltip>

                                                    {index + 1 < orders?.length ? ',' : ''}
                                                </>
                                            ))}
                                        </>
                                    );
                                }
                                return null;
                            }
                        },
                        defections: {
                            title: 'Недостатки',
                            value: ({ defections }) => {
                                const defects = (defections || []).map(({ name }) => name).join(', ');
                                if (defects) {
                                    return (
                                        <>
                                            <div>
                                                {defects}
                                            </div>
                                            <Button
                                                size="small"
                                                onClick={() => setIsOpenDefects(true)}
                                            >
                                                Посмотреть на карте
                                            </Button>
                                        </>
                                    );
                                }
                                return null;
                            }
                        },

                        // grz: {
                        //     title: 'Номер автолаборатории',
                        // },
                        // attachments: {
                        //     title: messages.ATTACHMENTS,
                        //     value: (value) => {
                        //         const infra = (value || []).map(({ name }) => name).join(', ');
                        //
                        //         return infra || null;
                        //     }
                        // },
                    }}
                />

                {isOpenDefects && (
                    <Modal
                        title="Недостатки"
                        onClose={handleCloseDefects}
                        fullWidth
                        noPadding
                        isOpen={isOpenDefects}
                        buttons={<FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: handleCloseDefects,
                                },
                            ]}
                        />}
                    >
                        <Grid container direction="row" alignItems="stretch" height="100%">
                            <Grid item xs={8}>
                                <Map
                                    formData={data?.defections}
                                    nearDefects={data?.defections}
                                    site={data}
                                    setCreateDefect={()=>{}}
                                    setDeleteDefect={()=>{}}
                                    height="100%"
                                    currentItem={showItem}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Scrollbars style={{ height: '100%' }}>
                                    <DefectList
                                        items={data?.defections}
                                        onClick={(item) => {
                                            if (showItem?.id === item?.id) {
                                                setShowItem({});
                                            } else {
                                                setShowItem(item);
                                            }
                                        }}
                                        currentItem={showItem}
                                    />
                                </Scrollbars>
                            </Grid>
                        </Grid>
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default PopUp;
