import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import messages from 'helpers/constants/messages';
import { transportSpecialSelectors } from 'redux/TransportSpecial';
import { loadVehicleCategoryList } from 'redux/TransportSpecial/actions';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';

import Filter from './Filter';
import Item from './Item';

const VehicleMileage = () => {
    const dispatch = useDispatch();
    const reportVehicleMileage = useSelector(transportSpecialSelectors.reportVehicleMileage);
    const loadingReportByVehicles = useSelector(transportSpecialSelectors.loadingReportByVehicles);
    const vehicleCategoryList = useSelector(transportSpecialSelectors.vehicleCategoryList);

    useEffect(() => {
        dispatch(loadVehicleCategoryList());
    }, [dispatch]);

    const memoizedCategoryList = useMemo(() => {
        return vehicleCategoryList;
    }, [vehicleCategoryList]);

    const renderContent = () => {
        return reportVehicleMileage.data?.vehicle_mileage?.length > 0 
            ? <LSContainer
                headers={[
                    { title: 'Дата', width: '10%' },
                    { title: 'ГРЗ', width: '10%' },
                    { title: 'Вид ТС', width: '10%' },
                    { title: 'Модель', width: '10%' },
                    { title: 'Марка', width: '10%' },
                    { title: 'Пробег, км', width: '10%' },
                ]}
            >
                <Virtuoso
                    data={reportVehicleMileage.data?.vehicle_mileage}
                    itemContent={(index, item) => <Item key={index} item={item} index={index} category={memoizedCategoryList} />}
                />
            </LSContainer>
            : !loadingReportByVehicles && <div>{messages.DATA_IS_NOT_FOUND}</div>;
        
    };
   
    return (
        <PageLayout
            header="Отчёт по пробегу"
            filters={<Filter />}
            loading={loadingReportByVehicles}
            actionPanel={<strong>Общий пробег, км: {reportVehicleMileage.data?.total?.toFixed(2)}</strong>}
            content={renderContent}
            customStyles={{ padding: 0, display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
        />
    );
};

export default VehicleMileage;