import instance from '../../helpers/axios.config';

import apiRwUrls from './api.urls';

const ApiRoadWorksMethods = {
    getStatusesRW: async () => {
        const response = await instance.get(apiRwUrls.getStatusesRW());
        return response?.data;
    },

    getOrdersRW: async (payload) => {
        const response = await instance.get(apiRwUrls.getRwOrders(), { params: payload });
        return response?.data;
    },

    getOrdersReport: async (config) => {
        const response = await instance.get(apiRwUrls.getOrdersReport(), config);
        return response?.data;
    },

    getForPolygonRW: async (polygon, params = {}) => {
        const response = await instance.post(
            apiRwUrls.getForPolygonRW(),
            {
                polygon,
                ...params
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
        return response?.data?.data;
    },

    getHistoryRW: async (id, page, limit, data) => {
        const response = await instance.get(apiRwUrls.getHistoryRW(id), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    getListRW: async (params = {}) => {
        const response = await instance.get(apiRwUrls.getListRW(), {
            params
        });
        return response?.data;
    },

    getReportRW: async (params = {}) => {
        const response = await instance.get(apiRwUrls.getListRW(), {
            params
        });
        return response?.data;
    },

    editRW: async (id, data) => {
        const response = await instance.put(apiRwUrls.editRW(id), data);
        return response?.data;
    },

    createNewRW: async (data) => {
        const response = await instance.post(apiRwUrls.createNewRW(), data);
        return response?.data;
    },

    deleteRW: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteRW(id));
        return response?.data;
    },

    getRwOrders: async (page, limit, data) => {
        const response = await instance.get(apiRwUrls.getRwOrders(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    createRwOrder: async (data) => {
        const response = await instance.post(apiRwUrls.getRwOrders(), data);
        return response?.data;
    },

    editRwOrder: async (id, data) => {
        const response = await instance.put(apiRwUrls.editRwOrder(id), data);
        return response?.data;
    },

    deleteRwOrder: async (id) => {
        const response = await instance.delete(apiRwUrls.editRwOrder(id));
        return response?.data;
    },

    getRwOrderStatuses: async () => {
        const response = await instance.get(apiRwUrls.getRwOrderStatuses());
        return response?.data;
    },

    getOrderDirections: async () => {
        const response = await instance.get(apiRwUrls.getOrderDirections());
        return response?.data;
    },

    getStagesStatuses: async () => {
        const response = await instance.get(apiRwUrls.getStagesStatuses());
        return response?.data;
    },
    getStageCargoTypes: async () => {
        const response = await instance.get(apiRwUrls.getStageCargoTypes());
        return response?.data;
    },
    getStagesList: async (page, limit, params) => {
        const response = await instance.get(apiRwUrls.getStagesList(), {
            params: { page, limit, ...params }
        });
        return response?.data;
    },

    createStage: async (data) => {
        const response = await instance.post(apiRwUrls.createStage(), data);
        return response?.data;
    },

    putStage: async (id, data) => {
        const response = await instance.put(apiRwUrls.putStage(id), data);
        return response.data;
    },

    deleteStage: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteStage(id));
        return response?.data;
    },

    putSequenceStages: async (data) => {
        const response = await instance.put(apiRwUrls.putSequenceStages(), data);
        return response?.data;
    },

    getDefectGroupList: async (page, limit, data) => {
        const response = await instance.get(apiRwUrls.getDefectGroupList(), {
            params: { page, limit, ...data },
        });
        return response.data;
    },

    createDefectGroup: async (params) => {
        const response = await instance.post(apiRwUrls.createDefectGroup(), params);
        return response?.data;
    },

    editDefectGroup: async (id, data) => {
        const response = await instance.put(apiRwUrls.editDefectGroup(id), data);
        return response?.data;
    },

    deleteDefectGroup: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteDefectGroup(id));
        return response?.data;
    },

    loadDefectTypes: async (page, limit, query) => {
        const response = await instance.get(apiRwUrls.loadDefectTypes(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    createDefectTypes: async (data) => {
        const response = await instance.post(apiRwUrls.createDefectTypes(), data);
        return response?.data;
    },

    editDefectTypes: async (id, data) => {
        const response = await instance.put(apiRwUrls.editDefectTypes(id), data);
        return response?.data;
    },

    deleteDefectTypes: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteDefectTypes(id));
        return response?.data;
    },

    getTypeOfRoadDefections: async (params) => {
        const response = await instance.get(apiRwUrls.getTypeOfRoadDefections(), { params });
        return response?.data;
    },

    getTypeCreation: async () => {
        const response = await instance.get(apiRwUrls.getTypeCreation());
        return response?.data;
    },

    createUnit: async (data) => {
        const response = await instance.post(apiRwUrls.createUnit(), data);
        return response?.data;
    },

    getUnits: async (page, limit, query) => {
        const response = await instance.get(apiRwUrls.getUnits(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    editUnit: async (id, data) => {
        const response = await instance.put(apiRwUrls.editUnit(id), data);
        return response?.data;
    },

    deleteUnit: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteUnit(id));
        return response?.data;
    },

    getGroups: async ({ page, limit, params  }) => {
        const response = await instance.get(apiRwUrls.getGroups(), { params: {
            page, limit, ...params
        } });
        return response?.data;
    },

    createGroup: async (data) => {
        const response = await instance.post(apiRwUrls.createGroup(), data);
        return response?.data;
    },

    editGroup: async (id, data) => {
        const response = await instance.put(apiRwUrls.editGroup(id), data);
        return response?.data;
    },

    deleteGroup: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteGroup(id));
        return response?.data;
    },

    getWorks: async (params) => {
        const response = await instance.get(apiRwUrls.getWorks(), { params });
        return response?.data;
    },

    createWork: async (data) => {
        const response = await instance.post(apiRwUrls.createWork(), data);
        return response?.data;
    },

    editWork: async (id, data) => {
        const response = await instance.put(apiRwUrls.editWork(id), data);
        return response?.data;
    },

    deleteWork: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteWork(id));
        return response?.data;
    },

    createStageSyncRoadWork: async (id, data) => {
        const response = await instance.post(apiRwUrls.createStageSyncRoadWork(id), data);
        return response?.data;
    },

    getConsolidateReport: async (config = {}) => {
        const response = await instance.get(apiRwUrls.getConsolidateReport(), config);
        return response?.data;
    },

    getStageSchedule: async (params) => {
        const response = await instance.get(apiRwUrls.getStageSchedule(), { params });
        return response?.data;
    },

    createStageSchedule: async (params) => {
        const response = await instance.post(apiRwUrls.createStageSchedule(), params);
        return response?.data;
    },

    getKDM: async (params = {}) => {
        const response = await instance.get(apiRwUrls.getKDM(), params);
        return response?.data;
    },

    getKDMReport: async (params = {}) => {
        const response = await instance.post(apiRwUrls.postKDM(), params);
        return response?.data;
    },

    getPlanFactReport: async (params) => {
        const response = await instance.post(apiRwUrls.getPlanFactReport(), params);
        return response?.data;
    },

    getPlanFactReportTypes: async () => {
        const response = await instance.get(apiRwUrls.getPlanFactReportTypes());
        return response?.data;
    },

    getDefects: async (params) => {
        const response = await instance.get(apiRwUrls.getDefects(), { params });
        return response?.data;
    },

    getDefectsSidebar: async (params) => {
        const response = await instance.get(apiRwUrls.getDefectsSidebar(), { params });
        return response?.data;
    },

    getDefectsPolygon: async (params) => {
        const response = await instance.post(apiRwUrls.getDefectsPolygon(), { ...params });
        return response?.data;
    },

    getDefectsGroups: async (params) => {
        const response = await instance.get(apiRwUrls.getDefectsGroups(), { params });
        return response?.data;
    },

    getDefectsTypes: async (params) => {
        const response = await instance.get(apiRwUrls.getDefectsTypes(), { params });
        return response?.data;
    },

    createDefect: async (params) => {
        const response = await instance.post(apiRwUrls.createDefect(), params);
        return response?.data;
    },

    editDefect: async (id, params) => {
        const response = await instance.put(apiRwUrls.editDefect(id), params);
        return response?.data;
    },

    deleteDefect: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteDefect(id));
        return response?.data;
    },

    defectHeatMap: async (params) => {
        const response = await instance.get(apiRwUrls.defectHeatMap(), { params });
        return response?.data;
    },

    defectStatuses: async () => {
        const response = await instance.get(apiRwUrls.defectStatuses());
        return response?.data;
    },

    getNearDefects: async (params) => {
        const response = await instance.post(apiRwUrls.getNearDefects(), params);
        return response?.data;
    },

    getSettings: async (page, limit, query) => {
        const response = await instance.get(apiRwUrls.getSettings(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    editSetting: async (id, data) => {
        const response = await instance.put(apiRwUrls.editSetting(id), data);
        return response?.data;
    },

    editSettingsList: async (data) => {
        const response = await instance.put(apiRwUrls.editSettingsList(), data);
        return response?.data;
    },

    loadShowcase: async () => {
        const response = await instance.post(apiRwUrls.loadShowcase());
        return response?.data;
    },

    getSettingsGroup: async () => {
        const response = await instance.get(apiRwUrls.getSettingsGroup());
        return response?.data;
    },

    editSettingsGroup: async (id, data) => {
        const response = await instance.put(apiRwUrls.editSettingsGroup(id), data);
        return response?.data;
    },

    getOrderTypes: async ({ page, limit, params  }) => {
        const response = await instance.get(apiRwUrls.getOrderTypes(), { params: {
            page, limit, ...params
        } });
        return response?.data;
    },

    createOrderType: async (data) => {
        const response = await instance.post(apiRwUrls.createOrderType(), data);
        return response?.data;
    },

    editOrderType: async (id, data) => {
        const response = await instance.put(apiRwUrls.editOrderType(id), data);
        return response?.data;
    },

    deleteOrderType: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteOrderType(id));
        return response?.data;
    },

    getNearRoadWorks: async (params) => {
        const response = await instance.post(apiRwUrls.getNearRoadWorks(), params);
        return response?.data;
    },

    getRepairObjectIndicators: async (params) => {
        const response = await instance.get(apiRwUrls.getRepairObjectIndicators(), { params });
        return response?.data;
    },

    createRepairObjectIndicator: async (data) => {
        const response = await instance.post(apiRwUrls.createRepairObjectIndicator(), data);
        return response?.data;
    },

    editRepairObjectIndicator: async (id, data) => {
        const response = await instance.put(apiRwUrls.editRepairObjectIndicator(id), data);
        return response?.data;
    },

    deleteRepairObjectIndicator: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteRepairObjectIndicator(id));
        return response?.data;
    },

    getRepairObjects: async (params) => {
        const response = await instance.get(apiRwUrls.getRepairObjects(), { params });
        return response?.data;
    },

    createRepairObject: async (data) => {
        const response = await instance.post(apiRwUrls.createRepairObject(), data);
        return response?.data;
    },

    editRepairObject: async (id, data) => {
        const response = await instance.put(apiRwUrls.editRepairObject(id), data);
        return response?.data;
    },

    deleteRepairObject: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteRepairObject(id));
        return response?.data;
    },

    getRepairFrequency: async (params) => {
        const response = await instance.get(apiRwUrls.getRepairFrequency(), { params });
        return response?.data;
    },

    getRepairTypes: async (params) => {
        const response = await instance.get(apiRwUrls.getRepairTypes(), { params });
        return response?.data;
    },

    getSheetsAssessmentRoad: async (params) => {
        const response = await instance.get(apiRwUrls.getSheetsAssessmentRoad(), { params });
        return response?.data;
    },

    createSheetsAssessmentRoad: async (data) => {
        const response = await instance.post(apiRwUrls.createSheetsAssessmentRoad(), data);
        return response?.data;
    },

    editSheetsAssessmentRoad: async (id, data) => {
        const response = await instance.put(apiRwUrls.editSheetsAssessmentRoad(id), data);
        return response?.data;
    },

    deleteSheetsAssessmentRoad: async (id) => {
        const response = await instance.delete(apiRwUrls.deleteSheetsAssessmentRoad(id));
        return response?.data;
    },

    roadWorksColors: async () => {
        const response = await instance.get(apiRwUrls.roadWorksColors());
        return response?.data;
    },

    loadShowcaseOrders: async () => {
        const response = await instance.get(apiRwUrls.loadShowcaseOrders());
        return response?.data;
    },

    loadShowcaseOrdersReport: async () => {
        const response = await instance.get(apiRwUrls.loadShowcaseOrdersReport());
        return response?.data;
    },

    loadRoadWorkReportOrders: async (params) => {
        const response = await instance.post(apiRwUrls.loadRoadWorkReportOrders(), params);
        return response?.data;
    },

    loadOrganizationsByOrderId: async (orderId) => {
        const response = await instance.get(apiRwUrls.loadOrganizationsByOrderId(orderId));
        return response?.data;
    },
};

export default ApiRoadWorksMethods;
