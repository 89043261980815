import axios from 'axios';
import { store } from 'store';

import { actionForError } from 'redux/Errors/actions';
import { tryParseJSON } from 'helpers/try.parse.json';


const instance = axios.create({ timeout: '300000', headers: { service: 'eputs' } });

const UNKNOWN_ERROR = {
    error: 'Sorry, something went wrong.',
    error_description: 'Error, try again later.',
    message: 'Error, try again later.'
};

// Объект для хранения CancelTokenSource по URL
const activeRequests = {};
// Функция для создания нового CancelTokenSource
const createCancelTokenSource = () => axios.CancelToken.source();

//проверяем корректность API в конфиге
instance.interceptors.request.use(
    (config)  => {
        const { url, ignoreCancel, method } = config;

        const api = url.split('/', 1).join();
        if (api === 'undefined') {
            throw new Error('api not detected');
        }

        // Если есть активный запрос по данному URL и он не должен быть проигнорирован, отменяем его
        // Если надо игнорировать запрос, то передаем ignoreCancel: true в аргументах запроса
        if (!ignoreCancel && activeRequests[`${url}_${method}`]) {
            activeRequests[`${url}_${method}`].cancel('Request canceled');
        }

        // Создаем новый CancelTokenSource и сохраняем его как активный для данного URL
        activeRequests[`${url}_${method}`] = createCancelTokenSource();

        // Присваиваем cancelToken текущему запросу
        config.cancelToken = activeRequests[`${url}_${method}`].token;

        return config;
    },
);

// глобальная обработка ошибок и статуса отправки данных на сервер
instance.interceptors.response.use(
    (response) => {
        // Удаляем CancelTokenSource для данного URL после завершения запроса
        const url = response.config?.url;
        const method = response.config?.method;
        delete activeRequests[`${url}_${method}`];

        return response;
    },
    async (error) => {
        // Удаляем CancelTokenSource для данного URL после завершения запроса
        const url = error.config?.url;
        const method = error.config?.method;
        delete activeRequests[`${url}_${method}`];

        const data = error?.response?.data;
        // check if blob and try to parse it to error
        if (data && data instanceof Blob) {
            const responseObj = await data.text();
            error.response.data = tryParseJSON(responseObj, UNKNOWN_ERROR);
        }
        if(error.message === 'api not detected' || axios.isCancel(error)){
            console.log(error.message);
        } else {
            store.dispatch(actionForError(error));
        }
        return error;
    },
);

// установить токен
export const setAuthToken = (access_token) => {
    if (access_token) {
        // Apply to every request
        instance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        // instance.defaults.headers.common['project'] = config.project; // TODO удалить когда будем получать с бэка
    } else {
        // 'Delete auth header'
        delete instance.defaults.headers.common.Authorization;
    }
};

//instance.defaults.headers.common['project'] = '46_krs';
export const setProject = (project) => {
    if (project) {
        // Apply to every request
        instance.defaults.headers.common['project'] = project;
    } else {
        // 'Delete project from header'
        delete instance.defaults.headers.common.project;
    }
};

export default instance;
