import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
    Cluster,
    ContextMenu,
    ContextMenuItem,
    FeatureGroup,
} from 'components/MapComponents/leaflet';
import layersLazyLoad from 'components/MapComponents/helpers/layersLazyLoad';
import { iconCreateFunction } from 'components/MapComponents/helpers/iconCreateFunction';
import MapLineLoading from 'components/MapComponents/MapLoading';
// import HistoryMap from '../../common/Transport/TransportHistory/ShowMap';

const MapContainer = (props) => {
    const { map } = props;
    const layersList = layersLazyLoad();
    const { activeLayers } = useSelector((state) => state.menu);

    useEffect(() => {
        // при изменении активных слоев закрываем меню на карте
        map.fire('context_menu_close');
    }, [activeLayers]);

    // собираем активные слои и меню для вывода
    const { layers, layersNoCluster, menuList, minWidth } = activeLayers.reduce((res, layer) => {
        if (Object.keys(layersList).includes(layer)) {

            const menuList = layersList[layer].menu
                ? [
                    ...res.menuList,
                    {
                        ...layersList[layer].menu,
                        layer
                    }
                ]
                : res.menuList;

            // кластер или нет
            return layersList[layer].clusterIcon
                ? {
                    ...res,
                    layers: [
                        ...res.layers,
                        {
                            Component: layersList[layer].Component,
                            layer
                        }
                    ],
                    menuList,
                    minWidth: layersList[layer].menu && layersList[layer].menu.minWidth
                        ? Math.max(res.minWidth, layersList[layer].menu.minWidth)
                        : res.minWidth
                } : {
                    ...res,
                    layersNoCluster: [
                        ...res.layersNoCluster,
                        {
                            Component: layersList[layer].Component,
                            layer
                        }
                    ],
                    menuList,
                    minWidth: layersList[layer].menu && layersList[layer].menu.minWidth
                        ? Math.max(res.minWidth, layersList[layer].menu.minWidth)
                        : res.minWidth
                };
        }
        return res;
    }, { layers: [], layersNoCluster: [], menuList: [], minWidth: 100 });

    // список меню карты
    const RenderLayersMenu = (props) => {
        return (
            <>
                {menuList.map(({ title, event, className = '', layer }) => {
                    return (
                        <ContextMenuItem
                            {...props}
                            key={`${layer}_menu_item`}
                            // добавление записи в слое
                            onClick={(e) => map.fire(event, e)}
                            className={className}
                            value={title}
                        />
                    );
                })}
            </>
        );
    };

    return (
        <>
            {/* лоадинг для всех слоев */}
            <MapLineLoading/>

            {/* кластеризатор маркеров */}
            <Cluster
                {...props}
                iconCreateFunction={iconCreateFunction(layersList)}
                maxClusterRadius={40}
            >
                {/* активные слои */}
                {layers.map(({ layer, Component }) => <Component key={layer} {...props} />)}
            </Cluster>

            {/* маркеры */}
            <FeatureGroup {...props}>
                {layersNoCluster.map(({ layer, Component }) => <Component key={layer} {...props} />)}
            </FeatureGroup>

            {/* общее меню карты */}
            {menuList.length > 0 && (
                <ContextMenu {...props} minWidth={minWidth}>
                    <RenderLayersMenu />
                </ContextMenu>
            )}

            {/* отобразить историю */}
            {/*<HistoryMap />*/}
        </>
    );
};

export default MapContainer;
