import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { range } from 'lodash';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import Loading from 'components/common/Loading';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SelectDashboard from 'components/common/Autocomplete/Efficiency/Dashboard';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import BoxContainer from 'components/common/Showcase/BoxContainer';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useValidation } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import { useDashboard } from 'helpers/hooks/Efficiency';
import useShowcase from 'helpers/hooks/useShowcase';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import { setVAReportFilter } from 'redux/VideoAnalytics/actions';
// import { setCameraReportFilter } from 'redux/VideoAnalytics/actions';
import {
    setShowReturnTo,
    setSelectedLayer,
    saveActiveLayer,
} from 'redux/Menu/actions';
import { editUserDashboard, setFilterEfficiencyDtp } from 'redux/Efficiency/actions';
import { setFilterReport } from 'redux/TrafficFlow/actions';
import { getOrdersRWReportFilter } from 'redux/RoadWorks/actions';
import { setFilterDistrictsByMonth } from 'redux/SituationalPlans/actions';

import Settings from './Settings';
import Item from './Item';
import './customStyles.scss';
import styles from './styles.module.scss';

const ReactGridLayout = WidthProvider(RGL);

const Dashboard = () => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const history = useHistory();
    const dashboardProvider = useDashboard();
    const isShowCase = useShowcase();

    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [isEditId, setIsEditId] = useState(0);

    useEffect(() => {
        dashboardProvider.load();

        return () => {
            dashboardProvider.clear();
        };
    }, []);

    const generateLayout = (dashboards = [], column_count, checkLayout = true) => (
        dashboards?.map((dashboard, index)=> (
            checkLayout && dashboard.layout?.i
                ? dashboard
                : {
                    ...dashboard,
                    layout : {
                        i: dashboard.id,
                        x: index % column_count,
                        y: Math.floor(index / column_count),
                        w: 1,
                        h: 1,
                    }
                }))
    );

    useEffect(() => {
        const { dashboards, column_count } = dashboardProvider?.data;

        const newData = {
            column_count,
            dashboards: generateLayout(dashboards, column_count)
        };

        setData(newData);
    }, [dashboardProvider?.data]);

    const handleApply = () => {
        const prepareData = {
            column_count: data.column_count,
            dashboards: data.dashboards.map(({ id, layout }) => ({ id, layout }))
        };

        const callback = () => {
            setEditMode(false);
            dashboardProvider.load();
        };

        const paramsWithoutEmpty = removeEmptyFields(prepareData);

        dispatch(editUserDashboard(paramsWithoutEmpty, callback));
    };

    const onDelete = (index) => {
        const dashboards = [
            ...data.dashboards.slice(0, index),
            ...data.dashboards.slice(index + 1)
        ];

        setData({
            ...data,
            dashboards: generateLayout(dashboards, data.column_count, false)
        });
    };

    const handleMoreInfo = (pagelink, filters = [], layer) => {
        // преобразуем фильтры в объект вида ключ-значение
        const reportFilters = {};
        filters.forEach(({ name, value }) => reportFilters[name] = value);
        // для показа кнопки возврата в дашборд
        !isShowCase && dispatch(
            setShowReturnTo(
                '/reports/efficiency/dashboard',
                'Вернуться в инфопанель'
            )
        );

        switch (pagelink) {
            case '/reports/efficiency/videoanalytics':
                dispatch(setVAReportFilter(reportFilters));
                break;

            case '/reports/efficiency/dtp':
                const filtersObjs = {};
                filters.forEach(({ name, value }) => filtersObjs[name] = fullDateTimeWithTimeZone(new Date(value)));

                dispatch(setFilterEfficiencyDtp({ dates: [filtersObjs] }));
                break;

            case '/reports/efficiency/puid':
                dispatch(setFilterReport(reportFilters));
                break;

            case '/reports/roadworks/orders':
                dispatch(getOrdersRWReportFilter(reportFilters));
                break;

            case '/reports/transport-incidents/districts-by-month':
                dispatch(setFilterDistrictsByMonth(reportFilters));
                break;

            // переход к слоям
            case '/map':
                setTimeout(() => {
                    dispatch(saveActiveLayer([ layer ]));
                    dispatch(setSelectedLayer(layer));
                }, 800);
                break;

                // case '/reports/videoanalytics-charts':
                //     dispatch(setCameraReportFilter({
                //         formValues: reportFilters,
                //         parameters: ['start_date', 'end_date']
                //     }));
                //     break;

            default:
                break;
        }

        history.push(pagelink);
    };

    const onLayoutChange = (layout) => {
        const dashboards = layout.map(({ i, x, y, w, h }, index) => ({
            ...data.dashboards[index],
            layout: { i, x, y, w, h }
        }));

        setData({ ...data, dashboards });
    };

    return (
        <BoxContainer>
            {!isShowCase && (
                <div className={styles.headerWrap}>
                    <h1>{editMode ? 'Настройки дашбордов' : 'Сводная статистика'}</h1>
                    {editMode
                        ? (
                            <FormButtons
                                noPadding
                                buttons={[
                                    {
                                        ...buttonsTypes.cancel,
                                        onClick: () => {setEditMode(false); setData(dashboardProvider?.data);}
                                    },
                                    {
                                        ...buttonsTypes.apply,
                                        onClick: handleApply
                                    }
                                ]}
                            />
                        ) : data?.dashboards?.length > 0 && (
                            <Tooltip title="Настройки">
                                <IconButton
                                    className={styles.settingsIcon}
                                    onClick={() => setEditMode(true)}
                                >
                                    <i className="far fa-cog"/>
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </div>
            )}
            {editMode && (
                <div className="filter__wrap filter__wrap__more__field">
                    <FormInfoWrapper
                        helperText={validation.get('column_count')}
                        error={validation.isKey('column_count')}
                    >
                        <FormControl
                            className="block"
                            size="small"
                            variant="outlined"
                            error={validation.isKey('column_count')}
                        >
                            <InputLabel>Количество карточек в строке</InputLabel>
                            <Select
                                value={data.column_count || 4}
                                label="Количество карточек в строке"
                                name={'column_count'}
                                onChange={({ target: { value, name } }) => {
                                    validation.deleteKey(name);
                                    setData({
                                        [name]: value,
                                        dashboards: generateLayout(data.dashboards, value, false)
                                    });
                                }}
                            >
                                {range(1, 7).map((el) => (
                                    <MenuItem key={el} value={el}>{el}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </FormInfoWrapper>

                    <SelectDashboard
                        selected={data.dashboards}
                        onChange={(val) => {
                            validation.deleteKey('dashboards');
                            setData({
                                ...data,
                                dashboards: generateLayout(val, data.column_count)
                            });
                        }}
                        multiple
                        helperText={validation.get('dashboards')}
                        error={validation.isKey('dashboards')}
                        limitTags={2}
                    />
                </div>
            )}
            {dashboardProvider.loading && <Loading circular={true} />}
            {data?.dashboards?.length > 0
                ? (
                    <ReactGridLayout
                        className="layout"
                        onLayoutChange={onLayoutChange}
                        cols={data?.column_count}
                        // rowHeight={300} // Высота строки
                        rowHeight={410} // Высота строки
                        // autoSize
                        isResizable={editMode}
                        isDraggable={editMode}
                        // onResizeStop={(l,oI,newItem) => console.log(newItem)}
                        // onDragStop={(l,oI,newItem )=> console.log(newItem)}
                    >
                        {data?.dashboards?.map((item, index) => (
                            <div key={item.id} data-grid={item.layout}>
                                <Item
                                    {...item}
                                    handleMoreInfo={handleMoreInfo}
                                    editMode={editMode}
                                    onDelete={() => onDelete(index)}
                                    onEdit={(id) => setIsEditId(id)}
                                />
                            </div>
                        ))}
                    </ReactGridLayout>
                    // <Grid container spacing={3}>
                    //     <DndContext
                    //         onDragEnd={handleDragEnd}
                    //         collisionDetection={closestCenter}
                    //     >
                    //         <SortableContext items={data?.dashboards}>
                    //             {data?.dashboards?.map((item, index) => (
                    //                 <Grid
                    //                     key={`item_${index}`}
                    //                     item
                    //                     xs={12/data?.column_count || 3}
                    //                 >
                    //                     <Item
                    //                         {...item}
                    //                         handleMoreInfo={handleMoreInfo}
                    //                         editMode={editMode}
                    //                         onDelete={() => onDelete(index)}
                    //                         // {...item?.pagelink === '/reports/efficiency/dtp' ? { title: 'График' } : {}}
                    //                         // {...item?.pagelink === '/reports/efficiency/puid' ? { title: 'Отчет' } : {}}
                    //                     />
                    //                 </Grid>
                    //             ))}
                    //         </SortableContext>
                    //     </DndContext>
                    // </Grid>
                ) : (!dashboardProvider.loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }

            {/* редактирование настройки дашборда */}
            {!!isEditId && (
                <Settings
                    dashboard_id={isEditId}
                    onClose={() => setIsEditId(0)}
                    onReload={() => {
                        dashboardProvider.load();
                    }}
                />
            )}
        </BoxContainer>
    );
};

export default Dashboard;
