import TransportTracking from '../../pages/Reports/DorisControl/TransportTracking';
import ComplexEvents from '../../pages/Reports/DorisControl/ComplexEvents';
import WantedCarsEvents from '../../pages/Reports/DorisControl/WantedCarsEvents';
import OperatorLog from '../../pages/Reports/DorisControl/OperatorLog';
import MonthlyReportCPVF from '../../pages/Reports/DorisControl/MonthlyReportCPVF';
import ReportCPVFWokrByPeriod from '../../pages/Reports/DorisControl/ReportCPVFWokrByPeriod';
import ReportDocumentsLeave from '../../pages/Reports/DorisControl/ReportDocumentsLeave';
import ComplexNames from '../../pages/Reports/DorisControl/ComplexNames';
import ReportOnWorkOfCPVF from '../../pages/Reports/DorisControl/ReportOnWorkOfCPVF';
import ReportTechnical from '../../pages/Reports/DorisControl/ReportTechnical';
import ReportCPVFByComplex from '../../pages/Reports/DorisControl/ReportCPVFByComplex';
import ReportViolationComplex from '../../pages/Reports/DorisControl/ReportViolationComplex';

const routes = [
    {
        path: '/reports/doris-control/transport-tracking',
        component: TransportTracking,
        exact: true
    },
    {
        path: '/reports/doris-control/complex-events',
        component: ComplexEvents,
        exact: true
    },
    {
        path: '/reports/doris-control/wanted-events',
        component: WantedCarsEvents,
        exact: true
    },
    {
        path: '/reports/doris-control/operator-log',
        component: OperatorLog,
        exact: true
    },
    {
        path: '/reports/doris-control/monthly-report',
        component: MonthlyReportCPVF,
        exact: true
    },
    {
        path: '/reports/doris-control/report-work-by-period',
        component: ReportCPVFWokrByPeriod,
        exact: true
    },
    {
        path: '/reports/doris-control/report-inspection-certificate',
        component: ReportDocumentsLeave,
        exact: true
    },
    {
        path: '/reports/doris-control/names',
        component: ComplexNames,
        exact: true
    },
    {
        path: '/reports/doris-control/monthly/report/expanded',
        component: ReportOnWorkOfCPVF,
        exact: true
    },
    {
        path: '/reports/doris-control/monthly/report/by-complex',
        component: ReportCPVFByComplex,
        exact: true
    },
    {
        // никто не видит в меню - для Саратова только по линку
        path: '/reports/doris-control/technical_report',
        component: ReportTechnical,
        exact: true
    },
    {
        path: '/reports/doris-control/violations',
        component: ReportViolationComplex,
        exact: true
    },
];

export default routes;