import { Button, Tooltip } from '@mui/material';
import cn from 'classnames';
import savefilterImg from '../../../../img/icons/savefilter.png';
import disabledFilterImg from '../../../../img/icons/filter_disabled.png';
import styles from './styles.module.scss';

interface FilterButtonProps { 
    disabled?: boolean, // если в фильтре есть ошибки, например в датах
    // tab?: string | null, // если фильтр внутри таба 
    onSave?: () => void,
    testIdPrefix?: string
};

const SaveFilterButton = ({ disabled, onSave, testIdPrefix }: FilterButtonProps) => {
    return (
        <Tooltip title="Сохранить фильтр">
            <span>
                <Button
                    disabled={disabled}
                    onClick={onSave}
                    color="infoButton" 
                    className={styles.icon}
                    startIcon={disabled
                        ? <img src={disabledFilterImg} alt="icon" className={styles.saveIcon} />
                        : <img src={savefilterImg} alt="icon" className={styles.saveIcon} />
                    }
                    { ...{ 'data-testid': `${testIdPrefix}/save/button` } }
                />
            </span>
        </Tooltip>
    );
};

export default SaveFilterButton;