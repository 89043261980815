import { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';

import { loadUserAvailableDashboardList } from 'redux/Efficiency/actions';
import { useStoreProp } from 'helpers/hooks';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import UniversalSelect from 'components/common/UniversalSelect';

const SelectDashboard = ({
    multiple = false,
    selected = multiple ? [] : {},
    initialState = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label = 'Дашборды',
    error = false,
    helperText = '',
    limitTags
}) => {
    const dashboards = useStoreProp(loadUserAvailableDashboardList, 'efficiency', 'userAvailableDashboardList');

    const [sortedList, setSortedList] = useState([]);
    const [selectedItems, setSelected] = useState(selected);
    const [showList, setShowList] = useState(false);

    const prevData = useRef(selected);

    useEffect(() => {
        if (!isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    const getDashboards = (params) => {
        const { query } = params;
        const dashboardsArray = Object.keys(dashboards).map((key) => ({ id: Number(key), name: dashboards[key] }));
        const filteredDashboards = query 
            ? dashboardsArray.filter(item => item?.name?.toLowerCase().includes(query?.toLowerCase())) 
            : dashboardsArray;
        setSortedList(filteredDashboards);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                error={error}
                helperText = {helperText}
                required={required}
                disabled={disabled}
                onReset={() => onChange(initialState)}
                renderLabel={(option) => option?.name || ''}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple
                    sortedList={sortedList}
                    fetchList={getDashboards}
                    keyProp={'id'}
                    withSearch
                    isSelected
                    isOpen={showList}
                    onClose={() => setShowList(false)}
                    onAccept={handleAccept}
                    selected={selectedItems}
                    renderProps={(el) => <div>{el.name}</div>}
                    noPadding
                    small
                    title={'Выберите дашборд'}
                />
            }
        </>
    );
};

export default SelectDashboard;
