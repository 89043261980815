import * as types from './types';

const initialState = {
    formats: {},
    templates: {},
    list: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loading: false,
    loadingButton: false,
    scheduledReport: [],
    loadingScheduledReport: false,
    loadingScheduledReportForm: false,
    scheduledReportTemplate: {},
    loadingPuid: false,

    reportTemplateFrontendLinks: {},
    loadingReportTemplateFrontendLinks: false,
    loadingApiReportTemplateFrontendLinks: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.LOADED_LIST:{
            return {
                ...state,
                list: payload
            };
        }

        case types.LOADED_FORMATS:{
            return {
                ...state,
                formats: payload
            };
        }

        case types.LOADED_TEMPLATES:{
            return {
                ...state,
                templates: payload
            };
        }

        case types.LOADING_LIST:{
            return {
                ...state,
                loading: payload
            };
        }

        case types.LOADING_BUTTON:{
            return {
                ...state,
                loadingButton: payload
            };
        }

        case types.LOADED_SCHEDULE_REPORT:{
            return {
                ...state,
                scheduledReport: payload
            };
        }

        case types.LOADING_SCHEDULE_REPORT:{
            return {
                ...state,
                loadingScheduledReport: payload
            };
        }

        case types.LOADING_SCHEDULE_REPORT_FORM:{
            return {
                ...state,
                loadingScheduledReportForm: payload
            };
        }

        case types.LOADED_SCHEDULE_REPORT_TEMPLATE:{
            return {
                ...state,
                scheduledReportTemplate: payload
            };
        }

        case types.LOADING_PUID:{
            return {
                ...state,
                loadingPuid: payload
            };
        }

        case types.LOADED_REPORT_TEMPLATE_FRONTEND_LINKS:{
            return {
                ...state,
                reportTemplateFrontendLinks: payload
            };
        }

        case types.LOADING_REPORT_TEMPLATE_FRONTEND_LINKS:{
            return {
                ...state,
                loadingReportTemplateFrontendLinks: payload
            };
        }

        case types.LOADING_API_REPORT_TEMPLATE_FRONTEND_LINK:{
            return {
                ...state,
                loadingApiReportTemplateFrontendLinks: payload
            };
        }

        default:
            return state;
    }
}