import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import { useValidation } from '../../../../../../helpers/hooks';
import { roadworksSelectors } from '../../../../../../redux/RoadWorks';
import {
    dateWithDashYYYYMMDD,
    // getDateToFormat
    dateNow,
} from '../../../../../../helpers/date.config';
import Modal from '../../../../../common/Modal';
import Form from './Form';
import * as actions from '../../../../../../redux/RoadWorks/actions';
import { uploadFileToStorage } from '../../../../../../redux/Upload/actions';


const EditorForm = ({
    dataForm = {},
    isOpen = false,
    isNew = true,
    onClose,
    onSuccess,
}) => {
    const dispatch = useDispatch();
    const loading = useSelector(roadworksSelectors.loadingSheetsAssessmentRoadForm);
    const validation = useValidation();

    const initialFields = {
        ...dataForm,
        road_name: dataForm?.road_name || '',
        road_category: dataForm?.road_category || '',
        address_text: dataForm?.address_text || '',
        characteristic_period: dataForm?.characteristic_period || '',
        year: dataForm?.year || '',
        month: dataForm?.month || '',
        maintenance_level: dataForm?.maintenance_level || '',
        registration_date: dataForm?.registration_date ? new Date(dataForm?.registration_date) : dateNow(),
        document_link: dataForm?.document_link || '',
    };

    const [fields, setFields] = useState(initialFields);

    const handleClose = () => {
        onClose?.();
    };

    const handleSuccess = () => {
        onSuccess?.();
        onClose?.();
    };

    const handleSave = () => {
        const data = {
            ...fields,
            registration_date: fields.registration_date ? dateWithDashYYYYMMDD(fields.registration_date) : null,
            // registration_date: fields.registration_date ? getDateToFormat(fields.registration_date, 'yyyy-MM-dd') : null,
        };

        dispatch(
            isNew
                ? actions.createSheetsAssessmentRoad(data, handleSuccess)
                : actions.editSheetsAssessmentRoad(data.id, data, handleSuccess)
        );
    };

    const handleChange = (ob) => {
        validation.deleteKeys(Object.keys(ob));
        setFields(old => ({
            ...old,
            ...ob,
        }));
    };

    const isDisabled = !fields?.road_name
        || !fields?.road_category
        || !fields?.address_text
        || !fields?.characteristic_period
        || !fields?.year
        || !fields?.month
        || !fields?.maintenance_level
        || !fields?.registration_date
        || !fields?.document_link;

    const loadFile = (file) => {
        dispatch(uploadFileToStorage(
            file,
            'doris-control',
            (url) => {
                handleChange({ document_link: url.public_link });
            }
        ));
    };

    return (
        <Modal
            isOpen={isOpen}
            noPadding
            title={isNew ? 'Добавление ведомости' : 'Редактирование ведомости'}
            maxWidthProp={'md'}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: handleClose,
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled,
                        loading,
                    }
                ]}
            />}
        >
            <Form
                values={fields}
                validation={validation}
                onChange={handleChange}
                loadFile={loadFile}
            />
        </Modal>
    );
};

export default EditorForm;
