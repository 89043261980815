import replaceForWsInRedux from 'helpers/replaceForWsInRedux';
import { getEndOf, getStartOf } from 'helpers/date.config';

import * as types from './types';

const initialState = {
    transportIncidentTypes: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingTransportIncidentTypes: false,
    events: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingEvents: false,
    keyWords: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    keyWordsLoading: false,
    dangersList: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    statusValidation: false,
    loadingDangersList: false,
    threatLevels: {},
    loadingThreatLevels: false,
    typicalOperations: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingTypicalOperations: false,
    responseScenarios: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingResponseScenarios: false,
    eventObjects: {
        data: [],
        meta: {
            last_page:0
        }
    },
    loadingEventObjects: false,
    eventObjectsTypes: [],
    currentEvent: {},
    currentKeyword: {},
    transportIncidents: {
        data:[],
        meta: {
            last_page:0
        }
    },
    transportIncident: {},
    loadingTransportIncident: false,
    currentCategory: {},
    loadingTransportIncidents: false,
    transportIncidentStatuses: [],
    currentResponseScenario: {},
    currentTransportIncidentType: {},
    currentThreatLevel: {},
    loadingCurrentThreatLevel: false,
    loadingCurrentTransportIncidentType: false,
    loadingCurrentResponseScenario: false,
    loadingCurrentEvent: false,
    currentEventObject: {},
    arrayOfKeywords: [],
    reportTransportIncidents: {
        data: [],
        meta: {
            last_page:0
        }
    },
    loadingReportTransportIncidents: false,
    reportTransportIncidentsFilter:{},
    sidebar: {
        data: [],
    },
    sidebarLoading: false,
    sidebarParams: {
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25
    },
    // полигон
    polygon: [],
    polygonLoading: false,
    // фильтры
    filters: {
        start_date: getStartOf('year'),
        end_date: getEndOf(),
    },
    // активный
    active: {},
    // данные для удаления
    deleteForm: false,
    // данные для редактирования
    editForm: false,
    reportTransportIncidentsResolutionTime: {
        data: [],
        meta: {
            last_page:0
        }
    },
    loadingReportTransportIncidentsResolutionTime: false,
    reportTransportIncidentsRTFilter: {},
    sidebarEvents: {
        data: [],
        meta: {
            last_page: 1
        }
    },
    sidebarLoadingEvents: false,
    // полигон
    polygonEvents: [],
    polygonEventsLoading: false,
    eventsProps: {
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25
    },
    // фильтры
    filtersEvents: {},
    // активный
    activeEvents: {},
    // данные для удаления
    deleteFormEvents: false,
    // данные для редактирования
    editFormEvents: false,
    savedEvents: false,
    incidentOperations: {},
    loadingIncidentOperations: false,
    loadingTransportIncidentHistory: false,
    transportIncidentHistory: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    transportIncidentTypeCreation: {},
    infopanel: {},
    loadingInfopanel: false,

    districtsByMonth: {},
    loadingDistrictsByMonth: false,
    filterDistrictsByMonth: {},
    loadingButton: false,
    transportIncidentModal: {},
    loadingTransportIncidentModal: false,
    tiModalData: null,
    tiFilterParams: {},

    settingsGroup: { data: [] },
    loadingSettingsGroup: false,

    showcase: {},
    loadingShowcase: false,

    imagesLegends: [],

    reportByEventPlans: {
        data:[],
        meta: { last_page: 0 }
    },
    loadingReportByEventPlans: false
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_TRANSPORT_INCIDENT_TYPES:
            return {
                ...state,
                transportIncidentTypes: payload
            };
        case types.LOADING_TRANSPORT_INCIDENT_TYPES:
            return {
                ...state,
                loadingTransportIncidentTypes: payload
            };
        case types.LOADED_EVENTS:
            return {
                ...state,
                events: payload
            };
        case types.LOADING_EVENTS:
            return {
                ...state,
                loadingEvents: payload
            };
        case types.LOADED_KEY_WORD_LIST:
            return {
                ...state,
                keyWords: payload
            };
        case types.LOADING_KEY_WORD_LIST:
            return {
                ...state,
                keyWordsLoading: payload
            };
        case types.LOADING_DANGERS_LIST:
            return {
                ...state,
                loadingDangersList: payload
            };
        case types.LOADED_DANGERS_LIST:
            return {
                ...state,
                dangersList: payload
            };
        case types.SET_STATUS_DANGER:
            return {
                ...state,
                statusValidation: payload
            };
        case types.LOADED_THREAT_LEVELS:
            return {
                ...state,
                threatLevels: payload
            };
        case types.LOADING_THREAT_LEVELS:
            return {
                ...state,
                loadingThreatLevels: payload
            };
        case types.LOADED_TYPICAL_OPERATIONS:
            return {
                ...state,
                typicalOperations: payload
            };
        case types.LOADING_TYPICAL_OPERATIONS:
            return {
                ...state,
                loadingTypicalOperations: payload
            };
        case types.LOADED_RESPONSE_SCENARIOS:
            return {
                ...state,
                responseScenarios: payload
            };
        case types.LOADING_RESPONSE_SCENARIOS:
            return {
                ...state,
                loadingResponseScenarios: payload
            };
        case types.LOADED_EVENT_OBJECTS:
            return {
                ...state,
                eventObjects: payload
            };
        case types.LOADING_EVENT_OBJECTS:
            return {
                ...state,
                loadingEventObjects: payload
            };
        case types.LOADED_EVENT_OBJECTS_TYPES:
            return {
                ...state,
                eventObjectsTypes: payload
            };
        case types.LOADED_CURRENT_EVENT:
            return {
                ...state,
                currentEvent: { ...state.currentEvent, [payload.id]: payload }
            };
        case types.LOADED_CURRENT_KEYWORD:
            return {
                ...state,
                currentKeyword: { ...state.currentKeyword, [payload.id]: payload }
            };
        case types.LOADED_CURRENT_CATEGORY:
            return {
                ...state,
                currentCategory: { ...state.currentCategory, [payload.id]: payload }
            };
        case types.LOADED_TRANSPORT_INCIDENTS:
            return {
                ...state,
                transportIncidents: payload
            };
        case types.LOADING_TRANSPORT_INCIDENTS:
            return {
                ...state,
                loadingTransportIncidents: payload
            };
        case types.LOADED_TRANSPORT_INCIDENT:
            return {
                ...state,
                transportIncident: payload
            };
        case types.LOADING_TRANSPORT_INCIDENT:
            return {
                ...state,
                loadingTransportIncident: payload
            };
        case types.LOADED_TRANSPORT_INCIDENT_STATUSES:
            return {
                ...state,
                transportIncidentStatuses: payload
            };
        case types.LOADED_CURRENT_RESPONSE_SCENARIO:
            return {
                ...state,
                currentResponseScenario: { ...state.currentResponseScenario, [payload.id]: payload }
            };
        case types.LOADED_CURRENT_TRANSPORT_INCIDENT_TYPE:
            return {
                ...state,
                currentTransportIncidentType: { ...state.currentTransportIncidentType, [payload.id]: payload }
            };
        case types.LOADED_CURRENT_THREAT_LEVEL:
            return {
                ...state,
                currentThreatLevel: { ...state.currentThreatLevel, [payload.id]: payload }
            };
        case types.LOADING_CURRENT_THREAT_LEVEL:
            return {
                ...state,
                loadingCurrentThreatLevel: payload
            };
        case types.LOADING_CURRENT_RESPONSE_SCENARIO:
            return {
                ...state,
                loadingCurrentResponseScenario: payload
            };
        case types.LOADING_CURRENT_EVENT:
            return {
                ...state,
                loadingCurrentEvent: payload
            };
        case types.LOADING_CURRENT_TRANSPORT_INCIDENT_TYPE:
            return {
                ...state,
                loadingCurrentTransportIncidentType: payload
            };
        case types.LOADED_CURRENT_EVENT_OBJECT:
            return {
                ...state,
                currentEventObject: { ...state.currentEventObject, [payload.id]: payload }
            };
        case types.LOADED_ARRAY_OF_KEYWORDS:
            return {
                ...state,
                arrayOfKeywords: payload
            };
        case types.LOADING_REPORT_TRANSPORT_INCIDENTS:
            return {
                ...state,
                loadingReportTransportIncidents: payload
            };
        case types.LOADED_REPORT_TRANSPORT_INCIDENTS:
            return {
                ...state,
                reportTransportIncidents: payload
            };
        case types.SET_REPORT_TRANSPORT_INCIDENTS_FILTER:
            return {
                ...state,
                reportTransportIncidentsFilter: payload
            };
        case types.CLEAR_REPORT_TRANSPORT_INCIDENTS_FILTER:
            return {
                ...state,
                reportTransportIncidentsFilter: {}
            };
        case types.SAVE_POLYGON: {
            return {
                ...state,
                polygon: payload
            };
        }
        case types.LOADED_SIDEBAR: {
            const {
                data,
                replace,
            } = payload;

            if (replace) {
                return {
                    ...state,
                    sidebar: data,
                };
            }

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...data,
                    data: [
                        ...state.sidebar.data,
                        ...data.data,
                    ],
                },
            };
        }

        case types.LOADING_SIDEBAR:
            return {
                ...state,
                sidebarLoading: payload,
            };
        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }
        case types.SET_FILTER: {
            return {
                ...state,
                filters: payload,
            };
        }
        case types.SET_DELETE_FORM: {
            return {
                ...state,
                deleteForm: payload,
            };
        }
        case types.SET_EDIT_FORM: {
            const editForm = payload;

            return {
                ...state,
                editForm,
            };
        }
        case types.SAVED: {
            return {
                ...state,
                saved: payload,
            };
        }
        case types.LOADING_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME:
            return {
                ...state,
                loadingReportTransportIncidentsResolutionTime: payload
            };
        case types.LOADED_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME:
            return {
                ...state,
                reportTransportIncidentsResolutionTime: payload
            };
        case types.SET_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME_FILTER:
            return {
                ...state,
                reportTransportIncidentsRTFilter: payload
            };
        case types.CLEAR_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME_FILTER:
            return {
                ...state,
                reportTransportIncidentsRTFilter: {}
            };

        /* events object map */
        case types.LOADING_SIDEBAR_EVENTS:
            return {
                ...state,
                sidebarLoadingEvents: payload,
            };
        case types.SET_ACTIVE_EVENTS: {
            return {
                ...state,
                activeEvents: payload,
            };
        }
        case types.SET_FILTER_EVENTS: {
            return {
                ...state,
                filtersEvents: payload,
            };
        }
        case types.SET_DELETE_FORM_EVENTS: {
            return {
                ...state,
                deleteFormEvents: payload,
            };
        }
        case types.SET_EDIT_FORM_EVENTS: {
            return {
                ...state,
                editFormEvents: payload,
            };
        }

        case types.LOADED_SIDEBAR_EVENTS: {
            const {
                data,
                replace,
            } = payload;

            if (replace) {
                return {
                    ...state,
                    sidebarEvents: data,
                };
            }

            return {
                ...state,
                sidebarEvents: {
                    ...state.sidebarEvents,
                    ...data,
                    data: [
                        ...state.sidebarEvents.data,
                        ...data.data,
                    ],
                },
            };
        }

        case types.SAVE_POLYGON_EVENTS: {
            return {
                ...state,
                polygonEvents: payload,
            };
        }

        case types.LOADING_POLYGON: {
            return {
                ...state,
                polygonLoading: payload,
            };
        }

        case types.LOADING_POLYGON_EVENTS: {
            return {
                ...state,
                polygonEventsLoading: payload,
            };
        }

        case types.SET_SAVED_EVENTS: {
            return {
                ...state,
                savedEvents: payload,
            };
        }

        case types.LOADED_INCIDENT_OPERATIONS: {
            return {
                ...state,
                incidentOperations: { ...state.incidentOperations, ...payload },
            };
        }

        case types.LOADING_INCIDENT_OPERATIONS: {
            return {
                ...state,
                loadingIncidentOperations: payload
            };
        }
        case types.LOADING_TRANSPORT_INCIDENT_HISTORY: {
            return {
                ...state,
                loadingTransportIncidentHistory: payload
            };
        }
        case types.LOADED_TRANSPORT_INCIDENT_HISTORY: {
            return {
                ...state,
                transportIncidentHistory: payload
            };
        }
        case types.LOADED_TRANSPORT_INCIDENT_TYPE_CREATION: {
            return {
                ...state,
                transportIncidentTypeCreation: payload
            };
        }

        case types.LOADED_INFOPANEL: {
            return {
                ...state,
                infopanel: payload
            };
        }
        case types.LOADING_INFOPANEL: {
            return {
                ...state,
                loadingInfopanel: payload
            };
        }
        case types.LOADED_DISTRICTS_BY_MONTH: {
            return {
                ...state,
                districtsByMonth: payload
            };
        }
        case types.LOADING_DISTRICTS_BY_MONTH: {
            return {
                ...state,
                loadingDistrictsByMonth: payload
            };
        }
        case types.SET_FILTER_DISTRICTS_BY_MONTH: {
            return {
                ...state,
                filterDistrictsByMonth: payload
            };
        }

        case types.SET_WS_TRANSPORT_INCIDENT: {
            const newPolygon = payload
                ?.reduce(
                    (res, item) => replaceForWsInRedux(res || [], item),
                    state.polygon
                );

            return {
                ...state,
                polygon: newPolygon,
                // polygon: replaceForWsInRedux(state.polygon || [], payload),
                sidebarParams: {
                    ...state.sidebarParams,
                    page: 1,
                },
                // sidebar: {
                //     ...state.sidebar,
                //     data: replaceForWsInRedux(state.sidebar?.data || [], payload, 'id', false),
                // },
            };
        }
        case types.SET_SIDEBAR_PARAMS: {
            return {
                ...state,
                sidebarParams: payload,
            };
        }

        case types.SET_WS_EVENTS_OBJECTS: {
            const newPolygon = payload
                ?.reduce(
                    (res, item) => replaceForWsInRedux(res || [], item),
                    state.polygonEvents
                );

            return {
                ...state,
                polygonEvents: newPolygon,
                // polygonEvents: replaceForWsInRedux(state.polygonEvents || [], payload),
                eventsProps: {
                    ...state.eventsProps,
                    page: 1
                }
                // sidebarEvents: {
                //     ...state.sidebarEvents,
                //     data: replaceForWsInRedux(state.sidebarEvents?.data || [], payload, 'id', false),
                // },
            };
        }
        case types.SET_EVENTS_PROPS: {
            return {
                ...state,
                eventsParams: payload,
            };
        }

        case types.LOADING_BUTTON: {
            return {
                ...state,
                loadingButton: payload,
            };
        }

        case types.LOADED_TRANSPORT_INCIDENT_MODAL:
            return {
                ...state,
                transportIncidentModal: payload
            };
        case types.LOADING_TRANSPORT_INCIDENT_MODAL:
            return {
                ...state,
                loadingTransportIncidentModal: payload
            };

        case types.SET_TI_MODAL_DATA: {
            return {
                ...state,
                tiModalData: payload,
            };
        }

        case types.SET_TI_FILTER_PARAMS: {
            return {
                ...state,
                tiFilterParams: payload,
            };
        }

        case types.LOADED_SETTINGS_GROUP:
            return {
                ...state,
                settingsGroup: payload
            };

        case types.LOADING_SETTINGS_GROUP:
            return {
                ...state,
                loadingSettingsGroup: payload
            };

        case types.LOADED_SHOWCASE: {
            return {
                ...state,
                showcase: payload
            };
        }

        case types.LOADING_SHOWCASE: {
            return {
                ...state,
                loadingShowcase: payload
            };
        }

        case types.LOADED_IMAGES_LEGENDS: {
            return {
                ...state,
                imagesLegends: payload,
            };
        }

        case types.LOADED_REPORT_BY_EVENT_PLANS: {
            return {
                ...state,
                reportByEventPlans: payload,
            };
        }

        case types.LOADING_REPORT_BY_EVENT_PLANS: {
            return {
                ...state,
                loadingReportByEventPlans: payload,
            };
        }

        default:
            return state;
    }
}
