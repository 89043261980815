import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';

import titles from '../../../../../../helpers/constants/titles';
import ConfirmDelete from '../../../../../common/ConfirmModal';
import DragAndDrop from '../../../../../common/DragAndDrop';
import ModalForm from '../../../../../MapComponents/Layers/Incidents/ModalForm';
import { editDtp } from '../../../../../../redux/Incidents/actions';

import DtpListItem from './DtpListItem';

export const useStyles = makeStyles({
    item_text: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    item: {
        textAlign: 'left',
        marginLeft: '1rem',
        flex: 1,
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 13,
        padding: '0 8px'
    }
});

const DtpList = ({ 
    items, 
    setItems,
    draggable = false,
    canEdit = false, 
    canRemove = false,
}) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const [editedDtp, setEditedDtp] = useState(null);
    const [deletedDtp, setDeletedDtp] = useState(null);

    const changeDtp = (dtp) => {
        const newData = items.map((item) => item.id === dtp.id ? dtp : item );
        setItems(newData);
    };

    const deleteDtp = (id) => {
        const newData = items.filter(item => item.id !== id);
        setItems(newData);
    };

    const listRender = (item, index) => {
        return (
            <DtpListItem
                onDeleteItem={() => setDeletedDtp(item)}
                onChangeItem={() => setEditedDtp(item)}
                item={item}
                index={index}
                draggable={draggable}
                canEdit={canEdit}
                canRemove={canRemove}
            />
        );
    };

    const listHeader = (
        <ListItem className={styles.item_text} divider>
            {draggable && <ListItemIcon style={{ minWidth: 38 }}/>}
            <ListItemText disableTypography className={styles.row}>
                <span style={{ width: 16 }}>{titles.NUM}</span>
                <span className={styles.item}>ID ДТП</span>
                <span className={styles.item}>{titles.DATE_TIME_DTP}</span>
                <span className={styles.item}>{titles.DTP_TYPE}</span>
                <span className={styles.item}>{titles.ADDRESS}</span>
            </ListItemText>
            {(canEdit || canRemove) && <ListItemIcon />}
        </ListItem>
    );

    return (<>
        {draggable
            ? <DragAndDrop
                list={items}
                itemsRender={listRender}
                onChange={(items) => setItems(items)}
                listHeader={listHeader}
            />
            : (
                <List>
                    {listHeader}
                    {items.map((item, index) =>
                        <DtpListItem
                            key={item.id}
                            item={item}
                            index={index}
                        />
                    )}
                </List>
            )
        }
        {!!deletedDtp
            && <ConfirmDelete
                open={!!deletedDtp}
                onSuccess={() => {
                    deleteDtp(deletedDtp.id);
                    setDeletedDtp(null);
                }}
                onClose={() => setDeletedDtp(null)}
            />
        }
        {!!editedDtp
            && <ModalForm
                isOpen={!!editedDtp}
                onClose={() => setEditedDtp(null)}
                onAccept={(dtp) => {
                    dispatch(editDtp(dtp.id, dtp, () => {
                        changeDtp(dtp); 
                        setEditedDtp(null); 
                    }));
                }}
                data={editedDtp}
            />
        }
    </>
    );
};

export default DtpList;