import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Pie } from 'react-chartjs-2';
import { incidentsSelectors } from '../../../../../redux/Incidents';

function Graph() {
    const dtpReportByPercent = useSelector(incidentsSelectors.dtpReportByPercent);

    const data = {
        data: dtpReportByPercent?.map(({ percent }) => percent),
        maxBarThickness: 80,
        label: dtpReportByPercent?.map(({ dtp_type_name }) => dtp_type_name),
        backgroundColor: dtpReportByPercent?.map(({ dtp_type_color }) => dtp_type_color || '#000'),
        borderColor: '#fff',
    };

    const getLabel = (context) => {
        const label = context?.formattedValue || '';
        const count = dtpReportByPercent?.[context?.dataIndex]?.count || '';
        return ` ${label}${label && '%'} (${count}${count && 'шт.'})`;
    };

    const toolTipTitle = (tooltipItems) => {
        return tooltipItems[0]?.dataset.label[tooltipItems[0]?.dataIndex];
    };

    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        redraw: false,
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks: {
                    label: getLabel,
                    title: toolTipTitle
                }
            },
            legend: {
                align: 'center',
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                }
            }
        },
    };

    return (
        <div>
            <Pie
                width={'400px'}
                height={'400px'}
                data={{
                    labels: dtpReportByPercent?.map(({ dtp_type_name }) => dtp_type_name),
                    datasets: [data],
                }}
                options={options}
                type="Pie"
            />
        </div>
    );
}

export default memo(Graph);