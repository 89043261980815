import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';

import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { loadEmployeesTransportationReport } from 'redux/TransportPassenger/actions';
import { REPORT_FORMATS_SMALL } from 'helpers/report.formats';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import Loading from 'components/common/Loading';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import ReportBlock from 'components/common/Report/ReportBlock';
import GetReport from 'components/common/GetReport';
import NoData from 'components/common/Information/NoData';

import EmployeesTable from './EmployeesTable';
import Filter from './Filter';


const EmployeesTransportation = () => {
    const data = useSelector(transportPassengerSelectors.employeesTransportationData);
    const loadingEmployeesTransportation = useSelector(transportPassengerSelectors.loadingEmployeesTransportation);
    const formats = REPORT_FORMATS_SMALL;
    const dispatch = useDispatch();
    const componentRef = useRef();

    const [params, setParams] = useState({});

    const printImg = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
            @page {
                size: landscape;
            }

            @media print {
                .pagebreak {
                    page-break-before: none;
                },
            }
        `,
    });

    const RenderList = ({ data, loading }) => (
        data?.length > 0
            ? (<LSContainer 
                headers={[
                    { width: '3%' }, // Статус
                    { title: 'ФИО', width: '10%', align: 'center' },
                    { title: 'Бригада', width: '10%', align: 'center' },
                    { title: 'Дата', width: '11%', align: 'center' },
                    { title: 'Наряд', width: '10%', align: 'center' },
                    { title: 'Рейс', width: '10%', align: 'center' },
                    { title: 'Коэф. выполнения',  tooltipTitle: 'пройдено пунктов/всего пунктов', width: '10%', align: 'center' },
                    { title: 'ГРЗ', width: '10%', align: 'center' },
                    { title: 'Время план', width: '10%', align: 'center' },
                    { title: 'Время факт', width: '10%', align: 'center' },
                    { title: 'Пробег план (км)', width: '10%', align: 'center' },
                    { title: 'Пробег факт (км)', width: '10%', align: 'center' },
                    { title: 'Нарушения скорости', width: '10%', align: 'center' },
                ]}
            >
                <div ref={componentRef}>
                    <EmployeesTable data={data} />
                </div>
            </LSContainer>) 
            : !loading && <NoData/>
    );

    const renderContent = () => (
        loadingEmployeesTransportation
            ? <Loading circular={true} />
            : <RenderList
                data={data}
                loading={loadingEmployeesTransportation}
            />
    );

    const filters = (value) => ({
        start_date: dateWithDashYYYYMMDD(value.selectedStartDate),
        end_date: dateWithDashYYYYMMDD(value.selectedEndDate),
        organization_id: value.selectedCompany.id || 0
    });

    const handleDownload = (format) => {
        dispatch(loadEmployeesTransportationReport({ page: 1, limit: 25, ...filters(params), report: format }));
    };

    const isDisabled = !params.selectedStartDate 
    || !params.selectedEndDate 
    || Object.keys(params.selectedCompany).length === 0;

    return (
        <>
            <PageLayout
                header="Перевозки сотрудников за период"
                filters={<Filter setParams={setParams} filters={filters}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtons
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: printImg,
                                        name: 'Распечатать',
                                        disabled: !data?.length,
                                    },
                                ]}
                                positionLeft
                                noPadding
                            />
                            <ReportBlock
                                customComponents={(onclose) => 
                                    <GetReport
                                        handleDownload={(formats) => {
                                            handleDownload(formats);
                                            onclose();
                                        }}
                                        formats={formats}
                                        disabled={isDisabled}
                                    />
                                } 
                            />
                        </>

                    ),
                }}
                loading={loadingEmployeesTransportation}
                content={renderContent}
            />
        </>
    );
};

export default EmployeesTransportation;