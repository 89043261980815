import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadParksList } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';

import Filter from './Filter';
import Item from './Item';
import ModalForm from './ModalForm';

const Parks = () => {
    const dispatch = useDispatch();

    const parks = useSelector(transportPassengerSelectors.parks);
    const loadingParks = useSelector(transportPassengerSelectors.loadingParks);

    const [openAddModal, setOpenAddModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = useCallback((shouldGoToPreviousPage = false) => {
        if (shouldGoToPreviousPage) {
            setParams({ ...params, page: params.page - 1 });
        } else {
            dispatch(loadParksList(params.page, limit, params.data));
        }
    }, [dispatch, limit, params]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    const renderContent = () => {
        return (
            <>
                {parks.data?.length > 0
                    ? <LSContainer
                        headers={[
                            { title: titles.NAME },
                            { title: titles.ACTIONS, isActions: true },
                        ]}
                    >
                        {parks.data?.map(item => (
                            <Item
                                key={item.id}
                                item={item}
                                reloadList={(isDelete) => reloadList(isDelete && (parks.data?.length === 1 && parks.meta?.last_page > 1))}
                            />
                        ))}
                    </LSContainer>
                    : !loadingParks && <div>{messages.DATA_IS_NOT_FOUND}</div>
                }
            </>
        );
    };

    return (
        <>
            <PageLayout
                header="Парки ТС"
                filters={<Filter setParams={setParams} />}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenAddModal(true),
                                },
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: parks.meta?.total,
                }}
                loading={loadingParks}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: parks.meta,
                    limit,
                    setLimit
                }}
            />

            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default Parks;
