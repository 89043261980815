// иконка для списка
const iconCluster = (props) => {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 107.000000 107.000000">
            <g>
                <rect x="-1" y="-1" width="109" height="109" id="canvas_background" fill="none"/>
            </g>
            <g>
                <g transform="translate(0,107) scale(0.10000000149011612,-0.10000000149011612) " fill="${props?.fill || '#e7e7e7'}" id="svg_battery_1">
                    <path d="m404.52236,1047.19878c-227.70224,-55.17014 -397.22506,-272.84146 -397.22506,-511.57773c0,-285.88168 240.74246,-525.62105 526.62414,-525.62105c285.88167,0 526.62414,240.74246 526.62414,526.62414c0,237.73318 -172.5321,457.41068 -402.24054,511.57774c-79.24439,18.05568 -175.54138,18.05568 -253.78268,-1.0031z" id="svg_battery_2" stroke="null"/>
                </g>
                <g fill="${props?.innerFill || 'rgba(0, 0, 0, 0.54)'}" stroke="null" id="svg_battery_7">
                    <path stroke="null" id="svg_battery_6" d="m79.695628,42.701725l0,7.19876l3.59938,0l0,7.19876l-3.59938,0l0,7.19876l-53.990703,0l0,-21.596281l53.990703,0m1.79969,-7.19876l-57.590083,0c-2.981862,0 -5.39907,2.417209 -5.39907,5.39907l0,25.195661c0,2.981862 2.417209,5.39907 5.39907,5.39907l57.590083,0c2.981862,0 5.39907,-2.417209 5.39907,-5.39907l0,-1.79969l0.899845,0c1.490931,0 2.699535,-1.208604 2.699535,-2.699535l0,-16.197211c0,-1.490931 -1.208604,-2.699535 -2.699535,-2.699535l-0.899845,0l0,-1.79969c0,-2.981862 -2.417209,-5.39907 -5.39907,-5.39907zm-5.39907,10.798141l-46.791943,0l0,14.397521l46.791943,0l0,-14.397521z"/>
                </g>
            </g>
        </svg>
    `;
};

export default iconCluster;
