import { useState } from 'react';
import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { useDispatch } from 'react-redux';
import { useValidation } from '../../../../../helpers/hooks';
import { TextField } from '@mui/material';
import titles from '../../../../../helpers/constants/titles';
import FormInfoWrapper from '../../../../common/FormInfoWrapper';
import { createRepairObject, editRepairObject } from '../../../../../redux/RoadWorks/actions';

const ModalForm = ({ item = {}, isNew, open, onClose, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        ...item,
        name: item?.name || '',
        type_id: 1
    };

    const [data, setData] = useState(initialState);

    const onChange = (e) => {
        const { name, value } = e.target;
        setData(prev => ({
            ...prev,
            [name] : value
        }));

        validation.deleteKey(name);
    };

    const handleSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        isNew
            ? dispatch(createRepairObject(data, callback))
            : dispatch(editRepairObject(data.id, data, callback));
    };

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            isOpen={open}
            onClose={onClose}
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: !data?.name
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormInfoWrapper
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                >
                    <TextField
                        value={data.name}
                        required
                        onChange={onChange}
                        className="block"
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        name="name"
                    />
                </FormInfoWrapper>
            </div>
        </Modal>
    );
};

export default ModalForm;