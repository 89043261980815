import React from 'react';

import { getStatusesRW } from 'redux/RoadWorks/actions';
import useStoreProp from 'helpers/hooks/useStoreProp';
import MapGeoJson from 'components/common/Location/MapGeoJson';

import GroupAndWorks from '../PassportPage/WorkByStages/GroupAndWorks';


const WorkSiteArea = ({ 
    site, 
    groupWorks = [], 
    onSave,
    stage
}) => {
    // WorkSiteArea это компонент относящийся к одному участку работ (site)
    // тут мы показываем информацию по участку, его на карте, а также
    // список групп и работ этого участка (GroupAndWorks)
    
    const roadWork_statuses = useStoreProp(getStatusesRW, 'roadworks', 'statuses');
  
    return (     
        <>
            <div className="row">
                <div className="row__item">
                    <div className="row align-items-center">
                        <h3>Статус:</h3><span className="row__item">&nbsp;{roadWork_statuses[site.status]}</span>
                    </div>
                    <div className="row align-items-center">
                        <h3>Адрес:</h3><span className="row__item">&nbsp;{site.address_text}</span>
                    </div>
                </div>
                <div className="row__item">
                    <MapGeoJson
                        geometry={site?.data?.geometry}
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="row">
                <div className="row__item">
                    <GroupAndWorks
                        works={groupWorks}
                        handleClickSave={onSave}
                        stage={stage}
                    />
                </div>
                
            </div>
        </>
    );
};

export default WorkSiteArea;