import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import {
    Marker,
    PolyLine,
    ToolTip,
} from '../../../../MapComponents/leaflet';
import decoratorOptions from '../decoratorOptions';
import HistoryPoints from './HistoryPoints';
import useTransportCategory from '../../../../../helpers/hooks/Transport/useTransportCategory';
import { createTransportIcon } from './helper';
// import { fullDateTimeWithTimeZone } from '../../../../../helpers/date.config';


const History = (props) => {
    const {
        data = null,
        // uuid = null,
        // startDate = null,
        // endDate = null,
        id = null,
        flight_id = null,
        work_order_id = null,
        category_id = null,
        moveToAfterInit = false,
        ...params
    } = props;

    const dispatch = useDispatch();

    const getTransportCategory = useTransportCategory(
        actions.loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    );

    useEffect(() => {
        // if (uuid) {
        //     dispatch(actions.loadVehicleHistory(uuid, {
        //         date_start: fullDateTimeWithTimeZone(startDate),
        //         date_end: fullDateTimeWithTimeZone(endDate),
        //     }));
        // }
        if (id) {
            dispatch(actions.loadVehicleFlightHistory(id, {
                flight_id,
                work_order_id,
            }));
        }
    }, [dispatch, id, flight_id, work_order_id]);

    useEffect(() => {
        return () => {
            // dispatch(actions.clearVehicleHistory());
            dispatch(actions.clearVehicleFlightHistory());
        };
    }, []);

    // const vehicleHistory = useSelector(transportPassengerSelectors.vehicleHistory);
    const vehicleFlightHistory = useSelector(transportPassengerSelectors.vehicleFlightHistory);
    const { points, vehicle_last_position } = vehicleFlightHistory;

    // const currentData = uuid
    //     ? vehicleHistory?.reduce((res, { lat, lon }) => {
    //         return [
    //             ...res,
    //             [lat, lon],
    //         ];
    //     }, [])
    //     : data;

    const currentData = points?.features?.length > 0
        ? Object.values(points?.features)?.map((item) => {
            return item.geometry.coordinates.reverse();
        })
        : data;

    return (
        <>
            {currentData?.length > 0 && (
                <>
                    <PolyLine
                        {...params}
                        coordinates={currentData}
                        moveToAfterInit={moveToAfterInit}
                        style={{
                            color: '#FF0000',
                            weight: 5,
                            smoothFactor: 1
                        }}
                        decoratorOption={decoratorOptions}
                    >
                        <ToolTip
                            sticky={true}
                            direction="top"
                            offset={[0, -5]}
                        >
                            История перемещений
                        </ToolTip>
                    </PolyLine>
                    <HistoryPoints
                        {...params}
                        data={vehicleFlightHistory?.points?.features || []}
                    />
                    {category_id && vehicle_last_position?.geometry?.coordinates?.length && (
                        <Marker
                            {...params}
                            latlng={vehicle_last_position?.geometry?.coordinates?.reverse()}
                            icon={createTransportIcon(category_id, true, getTransportCategory)}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default History;
