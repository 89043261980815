import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, ListItem, ListItemText, Tooltip, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { deleteAip } from 'redux/Aip/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';

const Item = ({
    item,
    selectedItem,
    onClickItem,
}) => {
    const dispatch = useDispatch();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return <>
        <ListItem
            disableGutters
            className="list_item"
            style={ selectedItem ? { backgroundColor: '#f5f5f5' } : { backgroundColor: '' } }
        >
            <ListItemText onClick={onClickItem}>
                <Typography variant="subtitle2">
                    <strong>{titles.NAME}: </strong>
                    <span>{item?.name || messages.INFO_IS_NOT_FOUND}</span>
                </Typography>
                <Typography variant="body2">
                    <strong>{titles.SERIAL_NUMBER}: </strong>
                    <span>{item?.serial_number || messages.INFO_IS_NOT_FOUND}</span>
                </Typography>
                <Typography variant="body2">
                    <strong>{titles.ADDRESS}: </strong>
                    <span>{item?.address_text || messages.INFO_IS_NOT_FOUND}</span>
                </Typography>
            </ListItemText>
            <FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.editIcon,
                        onClick: (e) => {e.stopPropagation(); setOpenEditModal(true);},
                    },
                    {
                        ...buttonsTypes.deleteIcon,
                        onClick: (e) => {e.stopPropagation(); setOpenDeleteModal(true);},
                    },
                ]}
                noMarginLeft
                justButton
            />

            <IconButton size="small" onClick={onClickItem}>
                {selectedItem
                    ? <ArrowBackIosIcon fontSize="inherit"/>
                    : <ArrowForwardIosIcon fontSize="inherit"/>
                }
            </IconButton>
        </ListItem>

        {openDeleteModal
        && <ConfirmDelete
            open={openDeleteModal}
            onSuccess={() => {
                dispatch(deleteAip(item.id));
                setOpenDeleteModal(false);
            }}
            onClose={() => setOpenDeleteModal(false)}
            message={'АИП будет удален, Вы уверены?'}
        />}

        {openEditModal
        && <ModalForm
            isOpen={openEditModal}
            onClose={() => setOpenEditModal(false)}
            item={item}
        />}
    </>;
};

export default Item;
