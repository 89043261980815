import { useStoreProp } from '../../index';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import convertCategories from './function/convertCategories';
import transportCategoryHelper from './function/transportCategoryHelper';

// хук категории по транспорту
const useTransportCategory = (
    action,
    storeName,
    storeProp
) => {
    const [categoriesObj, setCategoriesObj] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    const categories = useStoreProp(
        action,
        storeName,
        storeProp
    );
    // данные загружены
    const loaded = useSelector(({ [storeName]: service }) => service[`${storeProp}Loaded`]) || false;

    // если что то не так с запросом
    useEffect(() => {
        setTimeout(() => {
            if (isLoaded === false) setIsLoaded(true);
        }, 10000);
    }, []);

    useEffect(() => {
        if (loaded) {
            const categoryObject = convertCategories(categories);
            setCategoriesObj(categoryObject);
            setIsLoaded(true);

            return () => {
                setCategoriesObj({});
                setIsLoaded(false);
            };

        }
    }, [categories, loaded]);

    const data = {
        isLoaded,
        // категории массивом
        categories,
        // категории объектом
        categoriesObj,
    };

    return {
        // данные (выше)
        ...data,
        // transportCategoryHelper
        getHelper: transportCategoryHelper(data),
    };
};

export default useTransportCategory;
