import * as types from './types';

const initialState = {
    list: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingList: false,
    aipEvents: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    loadingAipEvents: false,
    polygon: [],
    polygonLoading: false,
    sidebar: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    sidebarLoading: false,
    active: {},
    filters: {},
    types: {},
    operators: {},
    
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.LOADED_LIST:
            return {
                ...state,
                list: payload
            };

        case types.LOADING_LIST:
            return {
                ...state,
                loadingList: payload
            };

        case types.LOADED_AIP_EVENTS: 
            return {
                ...state,
                aipEvents: payload,
            };

        case types.LOADING_AIP_EVENTS: 
            return {
                ...state,
                loadingAipEvents: payload,
            };

        case types.SAVE_POLYGON: {
            return {
                ...state,
                polygon: payload,
            };
        }

        case types.SET_POLYGON_LOADING: {
            return {
                ...state,
                polygonLoading: payload
            };
        }

        case types.LOADED_SIDEBAR: {
            const {
                data,
                page,
            } = payload;

            if (page === 1) {
                return {
                    ...state,
                    sidebar: data,
                };
            }

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...data,
                    data: [
                        ...state?.sidebar?.data,
                        ...data?.data
                    ],
                },
            };
        }

        case types.LOADING_SIDEBAR: {
            return {
                ...state,
                sidebarLoading: payload,
            };
        }
        
        case types.CLEAR_SIDEBAR: {
            return {
                ...state,
                sidebar: initialState.sidebar,
            };
        }
        

        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }

        case types.SET_FILTER: {
            return {
                ...state,
                filters: payload,
            };
        }

        case types.LOADED_AIP_TYPES:
            return {
                ...state,
                types: payload
            };

        case types.LOADED_AIP_OPERATORS:
            return {
                ...state,
                operators: payload
            };

        default:
            return state;
    }
}