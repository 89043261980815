import React, { useEffect, useState } from 'react';
import Modal from '../../../../common/Modal';
import titles from '../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { FormControl, TextField } from '@mui/material';
import DateRange from '../../../../common/Dates/DateRange';
import { useDispatch, useSelector } from 'react-redux';
import { createTracking, editTracking } from '../../../../../redux/WantedCars/actions';
import { useValidation } from '../../../../../helpers/hooks';

const TTModal = ({ open, closeForm, isNew, item = {} }) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const formLabels = {
        grz: 'Гос номер ТС (ГРЗ)',
        interval: 'Интервал (мин.)'
    };

    const [formData, setFormData] = useState({
        grz: item.grz || '',
        period_start: item.period_start || null,
        period_end: item.period_end || null,
        interval: item.interval || null
    });

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const onAcceptModal = () => dispatch(
        isNew
            ? createTracking(formData)
            : editTracking(item.id, formData)
    );

    const disabled = formData.grz && formData.period_start && formData.period_end && formData.interval;

    useEffect(() => {
        if (requestStatus.success) {
            closeForm();
            validation.clear();
        }
    }, [validation, closeForm, requestStatus]);

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={closeForm}
            maxWidthProp={'sm'}
            noPadding={true}
            isOpen={open}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: closeForm
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: !disabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label={formLabels.grz}
                        variant="outlined"
                        size="small"
                        value={formData.grz || ''}
                        name={'grz'}
                        onChange={onChange}
                        type="text"
                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                        error={validation.isKey('grz')}
                        helperText={validation.get('grz')}
                    />
                </FormControl>
                <div className="row">
                    <DateRange
                        valueStartDate={formData.period_start}
                        valueEndDate={formData.period_end}
                        handleDate={(e) => setFormData({ ...formData, period_start: e })}
                        handleEndDate={(e) => setFormData({ ...formData, period_end: e })}
                        startLabel={titles.START_PERIOD}
                        endLabel={titles.END_PERIOD}
                        required={true}
                    />
                </div>
                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        label={formLabels.interval}
                        variant="outlined"
                        size="small"
                        value={formData.interval || ''}
                        name={'interval'}
                        onChange={onChange}
                        type="number"
                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                        error={validation.isKey('interval')}
                        helperText={validation.get('interval')}
                    />
                </FormControl>
            </form>
        </Modal>
    );
};

export default TTModal;
