import { List } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../../helpers/constants/messages';
import { loadUnits } from '../../../../../redux/RoadWorks/actions';
import { roadworksSelectors } from '../../../../../redux/RoadWorks';
import CustomPagination from '../../../../common/CustomPagination';
import Item from './Item';
import Filters from './Filters';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';

function Units() {
    const dispatch = useDispatch();

    const [params, setParams] = useState({});
    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);

    const unitList = useSelector(roadworksSelectors.unitList);
    const loadingUnits = useSelector(roadworksSelectors.loadingUnits);

    const search = (formValue) => {
        const paramsWithoutEmpty = removeEmptyFields(formValue);
        dispatch(loadUnits(1, limit, paramsWithoutEmpty));
    };

    useEffect(() => {
        dispatch(loadUnits(1, limit));
    }, [dispatch]);

    return (<>
        <h1>Единицы измерения</h1>
        <Filters 
            params={params}
            setParams={setParams}
            search={search}
        />
        {unitList?.data?.length > 0
            ? (<>
                <List className="list">
                    {unitList?.data.map((item) => (
                        <Item
                            key={item.id}
                            item={item} 
                        />
                    ))}
                </List>
                <CustomPagination
                    loadList={(page, limit) => dispatch(loadUnits(page, limit, { query: params }))}
                    list={unitList.meta}
                    limit={limit} 
                    setLimit={setLimit}
                />
            </>)
            : (!loadingUnits && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        }
    </>);
}

export default Units;