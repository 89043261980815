import { IconButton, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import messages from '../../../../../../../helpers/constants/messages';
import titles from '../../../../../../../helpers/constants/titles';
import { Delete } from '@mui/icons-material';
import { useStyles } from './RenderList';

const RenderListItem = ({
    item,
    index,
    draggable,
    canRemove,
    onDeleteItem
}) => {
    const styles = useStyles();
    
    const renderItemText = () => (
        <ListItemText disableTypography className={styles.row}>
            <span style={{ minWidth: 16 }}>
                {index + 1}
            </span>
            <span className={styles.item}>
                {item?.name}
            </span>
            <span className={styles.item}>
                {item?.state?.full_name || messages.NO_DATA}
            </span>
            <span className={styles.item}>
                {item?.desc || messages.NO_DATA}
            </span>
        </ListItemText>
    );

    return (
        <>
            {draggable
                ? renderItemText()
                : (
                    <ListItem
                        className={styles.item_text} 
                        selected={index % 2 === 0} 
                    >
                        {renderItemText()}
                    </ListItem>
                )
            }
            {canRemove && (
                <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                    {canRemove && (
                        <Tooltip title={titles.DELETE}>
                            <IconButton onClick={onDeleteItem} size="small">
                                <Delete fontSize="inherit" style={{ color: '#f44336' }}/>
                            </IconButton>
                        </Tooltip>
                    )}
                </ListItemIcon>
            )}
        </>
    );
};

export default RenderListItem;
