import type { CSSProperties } from 'react';
import { Tooltip } from '@mui/material';

import buttons from 'helpers/constants/buttons';
import Image from 'components/common/Image';

interface ImagePreviewProps {
    src: string;
    alt?: string;
    style?: CSSProperties;
    tooltipTitle?: string;
    showPreview?: boolean;
}

const ImagePreview = ({ 
    src, 
    alt,
    style = {},
    tooltipTitle = buttons.OPEN_IN_NEW_WINDOW,
    showPreview = true
}: ImagePreviewProps) => {
    // const [openPreview, setOpenPreview] = useState(false);

    const openImageInNewTab = () => {
        const win = window.open();
        win?.document.write(`<img src="${src}">`);
        win?.document.close();
    };

    return (
        <>
            <Tooltip title={showPreview ? tooltipTitle : ''}>
                <Image
                    src={src}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        margin: 0,
                        cursor: showPreview ? 'pointer' : 'default',
                        ...style
                    }}
                    alt={alt}
                    {...(showPreview && { onClick: openImageInNewTab })}
                    // {...(showPreview && { onClick: () => setOpenPreview(true) })}
                />
            </Tooltip>

            {/* {openPreview && (
                <Modal
                    isOpen={openPreview}
                    title={title}
                    onClose={() => setOpenPreview(false)}
                    noPadding
                    fullWidth
                    size={'auto'}
                    buttons={<FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: () => setOpenPreview(false)
                            }
                        ]}
                    />}
                >
                    <Image
                        src={src}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 0,
                        }}
                    />
                </Modal>
            )} */}
        </>
    );
};

export default ImagePreview;