import * as types from './types';

export const getOrdersRW = (page = 1, limit = 25, params) => ({
    type: types.GET_ORDERS,
    payload: {
        page,
        limit,
        ...params
    },
});

export const loadOrdersReport = (params) => ({
    type: types.LOAD_ORDERS_REPORT,
    payload: params,
});

export const saveOrdersRW = (params) => ({
    type: types.ORDERS_LOADED,
    payload: params,
});

export const getOrdersRWReportFilter = (data) => ({
    type: types.GET_ORDERS_REPORT_FILTER,
    payload: data,
});

export const clearOrdersRWReportFilter = () => ({
    type: types.CLEAR_ORDERS_REPORT_FILTER,
});

export const getStatusesRW = () => ({
    type: types.GET_STATUSES
});

export const saveStatusesRW = (statusesRw) => ({
    type: types.STATUSES_LOADED,
    payload: statusesRw,
});

export const loadRoadWorks = (params = {}, replaceStore = true, callback) => ({
    type: types.LOAD_ROADWORKS,
    payload: {
        params,
        replaceStore
    }
});

export const loadAllSites = (params = {}, callback) => ({
    type: types.LOAD_ROADWORKS,
    payload: {
        params,
    },
    callback,
});

export const loadingRoadWorks = (isLoading) => ({
    type: types.LOADING_ROADWORKS,
    payload: isLoading,
});

export const loadedRoadWorks = (list, replaceStore = true) => ({
    type: types.LOADED_ROADWORKS,
    payload: { list, replaceStore }
});

export const getForPolygonRW = (polygon, params = {}) => ({
    type: types.GET_POLYGON,
    payload: {
        polygon,
        params
    }
});
export const loadingPolygonRW = (bool = false) => ({
    type: types.LOADING_POLYGON,
    payload: bool,
});

export const saveForPolygonRW = (data) => ({
    type: types.POLYGON_LOADED,
    payload: data,
});

export const clearForPolygonRW = () => ({
    type: types.POLYGON_LOADED,
    payload: [],
});

export const editRW = (id, data, callback = () => {}) => ({
    type: types.EDIT_ROADWORK,
    payload: { id, data },
    callback
});

export const saveRW = (saved) => ({
    type: types.SAVED,
    payload: saved,
});

export const createNewRW = (data, callback = () => {}) => ({
    type: types.CREATE_ROADWORK,
    payload: data,
    callback
});

export const deleteRW = (id, callback) => ({
    type: types.DELETE_ROADWORK,
    payload: id,
    callback
});

export const getHistoryRW = (id, page, limit, query) => ({
    type: types.GET_HISTORY,
    payload: { id, page, limit, query },
});

export const saveHistoryRW = (data) => ({
    type: types.HISTORY_LOADED,
    payload: data,
});

export const getReportRW = (params) => ({
    type: types.GET_REPORT,
    payload: params,
});

export const saveStatusRW = (bool = false) => ({
    type: types.SAVED,
    payload: bool,
});

export const setActiveRW = (item = {}) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const resetActiveRW = () => ({
    type: types.RESET_ACTIVE,
    payload: {},
});

export const setFilter = (filter) => ({
    type: types.SET_FILTER,
    payload: filter,
});

export const clearFilters = () => ({
    type: types.RESET_FILTER,
});

export const setEditRW = (data = {}) => ({
    type: types.SET_EDIT,
    payload: data,
});

export const getFilteredRWList = (page = 1, limit = 25, filter) => ({
    type: types.GET_FILTERED_LIST,
    payload: { page, limit, filter }
});

export const setDeleteForm = (data = false) => ({
    type: types.SET_DELETE_FORM,
    payload: data
});

export const setEditForm = (data = false) => ({
    type: types.SET_EDIT_FORM,
    payload: data
});

export const changeListItem = (newItem) => ({
    type: types.CHANHE_LIST_ITEM,
    payload: newItem
});
// заявки на ДР
export const loadRoadWorkOrders = (page = 1, limit = 25, params, callback) => ({
    type: types.LOAD_ROADWORK_ORDERS,
    payload: { page, limit, params },
    callback,
});

export const createRoadWorkOrder = (data, callback = () => {}) => ({
    type: types.CREATE_ROADWORK_ORDER,
    payload: data,
    callback
});

export const editRoadWorkOrder = (id, data, callback = () => {}) => ({
    type: types.EDIT_ROADWORK_ORDER,
    payload: { id, data },
    callback
});


export const deleteRoadWorkOrder = (id, callback) => ({
    type: types.DELETE_ROADWORK_ORDER,
    payload: id,
    callback
});

export const loadedRoadWorkOrders = (data) => ({
    type: types.LOADED_ROADWORK_ORDERS,
    payload: data
});

export const loadingRoadWorkOrders = (bool = false) => ({
    type: types.LOADING_ROADWORK_ORDERS,
    payload: bool
});

export const clearRoadWorkOrders = () => ({
    type: types.CLEAR_ROADWORK_ORDERS,
});

export const loadOrderStatuses = () => ({
    type: types.LOAD_ORDER_STATUSES
});

export const loadedOrderStatuses = (data) => ({
    type: types.LOADED_ORDER_STATUSES,
    payload: data
});

export const loadOrderDirections = () => ({
    type: types.LOAD_ORDER_DIRECTIONS
});

export const loadedOrderDirections = (data) => ({
    type: types.LOADED_ORDER_DIRECTIONS,
    payload: data
});

//STAGES
export const loadStagesStatuses = () => ({
    type: types.LOAD_STAGES_STATUSES
});

export const loadedStagesStatuses = (data) => ({
    type: types.LOADED_STAGES_STATUSES,
    payload: data
});

export const loadStageCargoTypes = () => ({
    type: types.LOAD_STAGE_CARGO_TYPES
});

export const loadedStageCargoTypes = (data) => ({
    type: types.LOADED_STAGE_CARGO_TYPES,
    payload: data
});

export const loadStagesList = (page, limit, params) => ({
    type: types.LOAD_STAGES_LIST,
    payload: {
        page,
        limit,
        params
    }
});

export const loadingStagesList = (bool = false) => ({
    type: types.LOADING_STAGES_LIST,
    payload: bool
});

export const loadedStagesList = (data) => ({
    type: types.LOADED_STAGES_LIST,
    payload: data
});

export const createStage = (data, callback) => ({
    type: types.CREATE_STAGE,
    payload: data,
    callback
});

export const editStage = (id, data, callback) => ({
    type: types.EDIT_STAGE,
    payload: { id, data },
    callback
});

export const deleteStage = (id, callback) => ({
    type: types.DELETE_STAGE,
    payload: id,
    callback
});

export const editSequenceStages = (data, callback) => ({
    type: types.EDIT_SEQUENCE_STAGES,
    payload: data,
    callback
});

export const loadDefectGroup = (page = 1, limit = 25, query) => ({
    type: types.LOAD_DEFECT_GROUP,
    payload: {
        page,
        limit,
        query,
    },
});

export const loadedDefectGroupList = (data) => ({
    type: types.LOADED_DEFECT_GROUP,
    payload: data,
});

export const createDefectGroup = (data, callback) => ({
    type: types.CREATE_DEFECT_GROUP,
    payload: data,
    callback
});

export const editDefectGroup = (id, data, callback) => ({
    type: types.EDIT_DEFECT_GROUP,
    payload: { id, data },
    callback
});

export const deleteDefectGroup = (id, callback) => ({
    type: types.DELETE_DEFECT_GROUP,
    payload: id,
    callback
});

export const loadDefectTypes = (page = 1, limit = 25, query) => ({
    type: types.LOAD_DEFECT_TYPES,
    payload: { page, limit, query }
});

export const loadingDefectTypes = (bool = false) => ({
    type: types.LOADING_DEFECT_TYPES,
    payload: bool
});

export const loadedDefectTypes = (data) => ({
    type: types.LOADED_DEFECT_TYPES,
    payload: data
});

export const createDefectTypes = (data, callback = () => {}) => ({
    type: types.CREATE_DEFECT_TYPES,
    payload: data,
    callback
});

export const editDefectTypes = (id, data, callback = () => {}) => ({
    type: types.EDIT_DEFECT_TYPES,
    payload: { id, data },
    callback
});

export const deleteDefectTypes = (id, callback = () => {}) => ({
    type: types.DELETE_DEFECT_TYPES,
    payload: id,
    callback
});

// типы с пагинацией
export const loadTypeOfRoadDefections = (page = 1, limit = 25, data = {}) => ({
    type: types.LOAD_TYPE_OF_ROAD_DEFECTIONS,
    payload: {
        page,
        limit,
        ...data,
    }
});
export const loadedTypeOfRoadDefections = (data) => ({
    type: types.LOADED_TYPE_OF_ROAD_DEFECTIONS,
    payload: data
});
export const loadingTypeOfRoadDefections = (bool = false) => ({
    type: types.LOADING_TYPE_OF_ROAD_DEFECTIONS,
    payload: bool
});

export const loadTypeCreation = () => ({
    type: types.LOAD_TYPE_CREATION,
});
export const loadedTypeCreation = (data) => ({
    type: types.LOADED_TYPE_CREATION,
    payload: data
});

//UNITS
export const createUnit = (data, callback) => ({
    type: types.CREATE_UNIT,
    payload: data,
    callback
});

export const loadUnits = (page = 1, limit = 25, query = {}, callback) => ({
    type: types.LOAD_UNITS,
    payload: {
        page,
        limit,
        query
    },
    callback
});

export const loadedUnits = (data) => ({
    type: types.LOADED_UNITS,
    payload: data
});

export const loadingUnits = (bool = false) => ({
    type: types.LOADING_UNITS,
    payload: bool
});

export const editUnit = (id, data, callback) => ({
    type: types.EDIT_UNIT,
    payload: { id, data },
    callback
});

export const deleteUnit = (id) => ({
    type: types.DELETE_UNIT,
    payload: id
});

// RWGroups
export const loadRoadWorksGroups = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_ROADWORKS_GROUPS,
    payload: { page, limit, params }
});

export const loadingRoadWorksGroups = (bool) => ({
    type: types.LOADING_ROADWORKS_GROUPS,
    payload: bool
});

export const loadedRoadWorksGroups = (response) => ({
    type: types.LOADED_ROADWORKS_GROUPS,
    payload: response
});

export const createRoadWorksGroup = (data, callback) => ({
    type: types.CREATE_ROADWORKS_GROUP,
    payload: data,
    callback
});

export const editRoadWorksGroup = (id, data, callback) => ({
    type: types.EDIT_ROADWORKS_GROUP,
    payload: { id, data },
    callback
});

export const deleteRoadWorksGroup = (id, callback) => ({
    type: types.DELETE_ROADWORKS_GROUP,
    payload: id,
    callback
});

// Works
export const loadWorks = (params, callback) => ({
    type: types.LOAD_WORKS,
    payload: params,
    callback
});

export const loadingWorks = (bool) => ({
    type: types.LOADING_WORKS,
    payload: bool
});

export const loadedWorks = (response) => ({
    type: types.LOADED_WORKS,
    payload: response
});

export const createWork = (data, callback) => ({
    type: types.CREATE_WORK,
    payload: data,
    callback
});

export const editWork = (id, data, callback) => ({
    type: types.EDIT_WORK,
    payload: { id, data },
    callback
});

export const deleteWork = (id, callback) => ({
    type: types.DELETE_WORK,
    payload: id,
    callback
});

export const setRoadWorksWS = (events) => ({
    type: types.SET_ROADWORKS_WS,
    payload: events,
});

export const setSidebarParamsRW = (params) => ({
    type: types.SET_SIDEBAR_PARAMS,
    payload: params,
});

export const createStageSyncRoadWork = (id, orderId, data) => ({
    type: types.STAGE_SYNC_ROADWORK,
    payload: { id, orderId, data }
});

export const createSyncArray = (data) => ({
    type: types.STAGE_SYNC_ROADWORK_ARRAY,
    payload: data
});

export const getConsolidateReport = (params) => ({
    type: types.GET_CONSOLIDATE_REPORT,
    payload: params,
});

export const getStageSchedule = (params, callback) => ({
    type: types.GET_STAGE_SCHEDULE,
    payload: params,
    callback,
});

export const createStageSchedule = (params, callback) => ({
    type: types.CREATE_STAGE_SCHEDULE,
    payload: params,
    callback
});

export const loadingStageSchedule = (bool = false) => ({
    type: types.LOADING_STAGE_SCHEDULE,
    payload: bool,
});

export const loadedStageSchedule = (params) => ({
    type: types.LOADED_STAGE_SCHEDULE,
    payload: params,
});

export const getKDM = (params = {}) => ({
    type: types.GET_KDM,
    payload: params,
});

export const loadedKDM = (params) => ({
    type: types.LOADED_KDM,
    payload: params,
});

export const loadKDMReport = (params) => ({
    type: types.GET_KDM_REPORT,
    payload: params,
});

export const loadPlanFactReport = (params) => ({
    type: types.LOAD_PLAN_FACT_REPORT,
    payload: params,
});

export const loadedPlanFactReport = (data) => ({
    type: types.LOADED_PLAN_FACT_REPORT,
    payload: data,
});

export const loadingPlanFactReport = (bool) => ({
    type: types.LOADING_PLAN_FACT_REPORT,
    payload: bool,
});

export const loadPlanFactReportTypes = () => ({
    type: types.LOAD_PLAN_FACT_REPORT_TYPES,
});

export const loadedPlanFactReportTypes = (data) => ({
    type: types.LOADED_PLAN_FACT_REPORT_TYPES,
    payload: data,
});



export const loadDefects = (params, refresh = false) => ({
    type: types.LOAD_DEFECTS,
    payload: params,
    refresh
});

export const loadedDefects = (data) => ({
    type: types.LOADED_DEFECTS,
    payload: data,
});

export const loadDefectsSidebar = (params, refresh = false) => ({
    type: types.LOAD_DEFECTS_SIDEBAR,
    payload: params,
    refresh
});
export const loadedDefectsSidebar = (list, replaceStore, refresh) => ({
    type: types.LOADED_DEFECTS_SIDEBAR,
    payload: {
        list,
        replaceStore,
        refresh
    },
});
export const loadingDefectsSidebar = (bool = false) => ({
    type: types.LOADING_DEFECTS_SIDEBAR,
    payload: bool,
});

export const loadDefectsPolygon = (polygon, params = {}) => ({
    type: types.LOAD_DEFECTS_POLYGON,
    payload: {
        polygon,
        ...params,
    },
});
export const loadingDefectsPolygon = (bool = false) => ({
    type: types.LOADING_DEFECTS_POLYGON,
    payload: bool,
});
export const loadedDefectsPolygon = (data) => ({
    type: types.LOADED_DEFECTS_POLYGON,
    payload: data,
});

export const clearDefectsPolygon = (data) => ({
    type: types.CLEAR_DEFECTS_POLYGON,
});

export const createDefect = (data, callback) => ({
    type: types.CREATE_DEFECT,
    payload: data,
    callback
});

export const editDefect = (id, data, callback) => ({
    type: types.EDIT_DEFECT,
    payload: {
        id,
        data
    },
    callback
});
export const deleteDefect = (id, callback) => ({
    type: types.DELETE_DEFECT,
    payload: id,
    callback
});

export const setDefectsFilter = (filter = {}) => ({
    type: types.SET_DEFECTS_FILTER,
    payload: filter
});
export const clearDefectsFilter = () => ({
    type: types.CLEAR_DEFECTS_FILTER,
});

export const setDefectsActive = (active = {}) => ({
    type: types.SET_DEFECTS_ACTIVE,
    payload: active
});
export const clearDefectsActive = () => ({
    type: types.SET_DEFECTS_ACTIVE,
    payload: {}
});

export const setDeleteFormDefects = (data = false) => ({
    type: types.SET_DELETE_FORM_DEFECTS,
    payload: data
});

export const setEditFormDefects = (data = false) => ({
    type: types.SET_EDIT_FORM_DEFECTS,
    payload: data
});

export const savedDefects = (bool = false) => ({
    type: types.DEFECTS_SAVED,
    payload: bool
});
export const loadingDefects = (bool = false) => ({
    type: types.LOADING_DEFECTS,
    payload: bool
});


// heat map
export const loadDefectHeatMap = (params) => ({
    type: types.LOAD_DEFECT_HEAT_MAP,
    payload: params,
});
export const clearDefectHeatMap = () => ({
    type: types.LOADED_DEFECT_HEAT_MAP,
    payload: [],
});
export const loadedDefectHeatMap = (data) => ({
    type: types.LOADED_DEFECT_HEAT_MAP,
    payload: data,
});
export const loadingDefectHeatMap = (bool = false) => ({
    type: types.LOADING_DEFECT_HEAT_MAP,
    payload: bool,
});
export const filterDefectHeatMap = (filter = {}) => ({
    type: types.FILTER_DEFECT_HEAT_MAP,
    payload: filter,
});

export const loadDefectStatuses = () => ({
    type: types.LOAD_DEFECT_STATUSES,
});
export const loadedDefectStatuses = (data) => ({
    type: types.LOADED_DEFECT_STATUSES,
    payload: data,
});

export const loadNearDefects = (params) => ({
    type: types.LOAD_NEAR_DEFECTS,
    payload: params
});

export const loadedNearDefects = (data) => ({
    type: types.LOADED_NEAR_DEFECTS,
    payload: data,
});

export const loadingButton = (bool) => ({
    type: types.LOADING_BUTTON,
    payload: bool
});

//TRANSPORT_SETTINGS
export const loadSettings = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_SETTINGS,
    payload: { page, limit, query }
});

export const loadingSettings = (bool = false) => ({
    type: types.LOADING_SETTINGS,
    payload: bool
});

export const loadedSettings = (data) => ({
    type: types.LOADED_SETTINGS,
    payload: data
});

export const editSetting = (id, data, callback) => ({
    type: types.EDIT_SETTING,
    payload: { id, data },
    callback
});

export const editSettingsList = (data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_LIST,
    payload: data,
    callback
});

export const loadShowcase = () => ({
    type: types.LOAD_SHOWCASE,
});
export const loadingShowcase = (bool = false) => ({
    type: types.LOADING_SHOWCASE,
    payload: bool
});
export const loadedShowcase = (data) => ({
    type: types.LOADED_SHOWCASE,
    payload: data
});
export const clearShowcase = () => ({
    type: types.LOADED_SHOWCASE,
    payload: {}
});

// Settings Group
export const loadSettingsGroup = () => ({
    type: types.LOAD_SETTINGS_GROUP,
});

export const loadingSettingsGroup = (bool) => ({
    type: types.LOADING_SETTINGS_GROUP,
    payload: bool
});

export const loadedSettingsGroup = (data) => ({
    type: types.LOADED_SETTINGS_GROUP,
    payload: data
});

export const editSettingsGroup = (id, data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_GROUP,
    payload: { id, data },
    callback
});

// order types
export const loadOrderTypes = (page, limit, params) => ({
    type: types.LOAD_ORDER_TYPES,
    payload: { page, limit, params }
});

export const loadingOrderTypes = (bool) => ({
    type: types.LOADING_ORDER_TYPES,
    payload: bool
});

export const loadedOrderTypes = (response) => ({
    type: types.LOADED_ORDER_TYPES,
    payload: response
});

export const createOrderType = (data, callback) => ({
    type: types.CREATE_ORDER_TYPE,
    payload: data,
    callback
});

export const editOrderType = (id, data, callback) => ({
    type: types.EDIT_ORDER_TYPE,
    payload: { id, data },
    callback
});

export const deleteOrderType = (id, callback) => ({
    type: types.DELETE_ORDER_TYPE,
    payload: id,
    callback
});

export const loadNearRoadWorks = (params, callback) => ({
    type: types.LOAD_NEAR_ROAD_WORKS,
    payload: params,
    callback,
});

export const loadingNearRoadWorks = (data) => ({
    type: types.LOADING_NEAR_ROAD_WORKS,
    payload: data,
});

export const loadedNearRoadWorks = (data) => ({
    type: types.LOADED_NEAR_ROAD_WORKS,
    payload: data,
});

// repair object indicators
export const loadRepairObjectIndicators = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_REPAIR_OBJECT_INDICATORS,
    payload: { page, limit, ...query }
});

export const loadingRepairObjectIndicators = (bool) => ({
    type: types.LOADING_REPAIR_OBJECT_INDICATORS,
    payload: bool
});

export const loadedRepairObjectIndicators = (response) => ({
    type: types.LOADED_REPAIR_OBJECT_INDICATORS,
    payload: response
});

export const createRepairObjectIndicator = (data, callback) => ({
    type: types.CREATE_REPAIR_OBJECT_INDICATOR,
    payload: data,
    callback
});

export const editRepairObjectIndicator = (id, data, callback) => ({
    type: types.EDIT_REPAIR_OBJECT_INDICATOR,
    payload: { id, data },
    callback
});

export const deleteRepairObjectIndicator = (id, callback) => ({
    type: types.DELETE_REPAIR_OBJECT_INDICATOR,
    payload: id,
    callback
});


// repair objects
export const loadRepairObjects = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_REPAIR_OBJECTS,
    payload: { page, limit, ...query }
});

export const loadingRepairObjects = (bool) => ({
    type: types.LOADING_REPAIR_OBJECTS,
    payload: bool
});

export const loadedRepairObjects = (response) => ({
    type: types.LOADED_REPAIR_OBJECTS,
    payload: response
});

export const createRepairObject = (data, callback) => ({
    type: types.CREATE_REPAIR_OBJECT,
    payload: data,
    callback
});

export const editRepairObject = (id, data, callback) => ({
    type: types.EDIT_REPAIR_OBJECT,
    payload: { id, data },
    callback
});

export const deleteRepairObject = (id, callback) => ({
    type: types.DELETE_REPAIR_OBJECT,
    payload: id,
    callback
});

// repair frequency
export const loadRepairFrequency = (params) => ({
    type: types.LOAD_REPAIR_FREQUENCY,
    payload: params
});

export const loadingRepairFrequency = (bool) => ({
    type: types.LOADING_REPAIR_FREQUENCY,
    payload: bool
});

export const loadedRepairFrequency = (data) => ({
    type: types.LOADED_REPAIR_FREQUENCY,
    payload: data
});

// repair types
export const loadRepairTypes = (params) => ({
    type: types.LOAD_REPAIR_TYPES,
    payload: params
});

export const loadingRepairTypes = (bool) => ({
    type: types.LOADING_REPAIR_TYPES,
    payload: bool
});

export const loadedRepairTypes = (data) => ({
    type: types.LOADED_REPAIR_TYPES,
    payload: data
});
// sheets-assessment-road
export const loadSheetsAssessmentRoad = (params = {}) => ({
    type: types.LOAD_SHEETS_ASSESSMENT_ROAD,
    payload: params
});

export const loadingSheetsAssessmentRoad = (bool) => ({
    type: types.LOADING_SHEETS_ASSESSMENT_ROAD,
    payload: bool
});

export const loadedSheetsAssessmentRoad = (response) => ({
    type: types.LOADED_SHEETS_ASSESSMENT_ROAD,
    payload: response
});

export const loadingSheetsAssessmentRoadForm = (bool) => ({
    type: types.LOADING_SHEETS_ASSESSMENT_ROAD_FORM,
    payload: bool
});

export const createSheetsAssessmentRoad = (data, callback) => ({
    type: types.CREATE_SHEETS_ASSESSMENT_ROAD,
    payload: data,
    callback
});

export const editSheetsAssessmentRoad = (id, data, callback) => ({
    type: types.EDIT_SHEETS_ASSESSMENT_ROAD,
    payload: { id, data },
    callback
});

export const deleteSheetsAssessmentRoad = (id, callback) => ({
    type: types.DELETE_SHEETS_ASSESSMENT_ROAD,
    payload: id,
    callback
});

export const loadRoadWorksColors = () => ({
    type: types.LOAD_ROAD_WORKS_COLORS,
});
export const loadedRoadWorksColors = (data) => ({
    type: types.LOADED_ROAD_WORKS_COLORS,
    payload: data
});

export const loadShowcaseOrders = () => ({
    type: types.LOAD_SHOWCASE_ORDERS,
});
export const loadingShowcaseOrders = (bool = false) => ({
    type: types.LOADING_SHOWCASE_ORDERS,
    payload: bool
});
export const loadedShowcaseOrders = (data) => ({
    type: types.LOADED_SHOWCASE_ORDERS,
    payload: data
});
export const clearShowcaseOrders = () => ({
    type: types.LOADED_SHOWCASE_ORDERS,
    payload: {}
});

export const loadShowcaseOrdersReport = () => ({
    type: types.LOAD_SHOWCASE_ORDERS_REPORT,
});
export const loadingShowcaseOrdersReport = (bool = false) => ({
    type: types.LOADING_SHOWCASE_ORDERS_REPORT,
    payload: bool
});
export const loadedShowcaseOrdersReport = (data) => ({
    type: types.LOADED_SHOWCASE_ORDERS_REPORT,
    payload: data
});
export const clearShowcaseOrdersReport = () => ({
    type: types.LOADED_SHOWCASE_ORDERS_REPORT,
    payload: {}
});

export const loadRoadWorkReportOrders = (page, limit, filter = {}) => ({
    type: types.LOAD_ROAR_WORKS_REPORT_ORDERS,
    payload: {
        page,
        limit,
        ...filter
    }
});

export const loadedRoadWorkReportOrders = (data) => ({
    type: types.LOADED_ROAR_WORKS_REPORT_ORDERS,
    payload: data
});

export const loadingRoadWorkReportOrders = (bool = false) => ({
    type: types.LOADING_ROAR_WORKS_REPORT_ORDERS,
    payload: bool
});

export const loadOrganizationsByOrderId = (orderId, fnLoading, fnCallback) => ({
    type: types.LOAD_RW_ORGANIZATIONS_BY_ORDER_ID,
    payload: orderId,
    fnLoading,
    fnCallback,
});
