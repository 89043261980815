import React from 'react';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import messages from '../../../../../helpers/constants/messages';

const WorksInfo = ({ data }) => {
    if (data?.length === 0) {
        return (
            <div>
                {messages.DATA_IS_NOT_FOUND}
            </div>
        );
    }

    const type = JSON.parse(localStorage.getItem('planFactFilter'))?.type;

    return (
        <TableContainer
            component={Paper}
            sx={{
                boxShadow: '0',
                // marginBottom: '1rem',
            }}
        >
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Группа работ</TableCell>
                        <TableCell align="center">Вид работ</TableCell>
                        {type !== 'fact' && <TableCell align="center">Планируемый объем</TableCell>}
                        {type !== 'plan' && <TableCell align="center">Выполненный объем</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((item, index) => (
                        <TableRow
                            key={index}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0
                                }
                            }}
                        >
                            <TableCell align="center">
                                {item?.group_name || messages.INFO_IS_NOT_FOUND}
                            </TableCell>
                            <TableCell align="center">
                                {item?.name || messages.INFO_IS_NOT_FOUND}
                            </TableCell>
                            {type !== 'fact' && <TableCell align="center">
                                {item?.amount_plan ?? messages.INFO_IS_NOT_FOUND}
                            </TableCell>}
                            {type !== 'plan' && <TableCell align="center">
                                {item?.amount ?? messages.INFO_IS_NOT_FOUND}
                            </TableCell>}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default WorksInfo;
