import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

import { createConnectionPoints, editConnectionPoints, loadConnectionPointTypes } from 'redux/RoadNetwork/actions';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import { ConnectionPointItem, } from 'types';

interface ModalFormProps {
    isNew: boolean;
    onClose: () => void;
    isOpen: boolean;
    el?: ConnectionPointItem;
    reloadList: () => void;
}

const ModalForm = ({
    isNew,
    onClose,
    isOpen,
    el,
    reloadList
}: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const connectionPointTypes = useStoreProp(loadConnectionPointTypes, 'roadNetworks', 'connectionPointTypes');

    const initialState: ConnectionPointItem = {
        name: el?.name || '',
        address: el?.address || '',
        address_text: el?.address_text || '',
        lat: el?.lat || '',
        lon: el?.lon || '',
        type_id: el?.type_id || '',
    };

    const [data, setData] = useState<ConnectionPointItem>(initialState);

    const handleCreate = () => {
        dispatch(createConnectionPoints(data, () => {
            reloadList();
            onClose();
        }));
    };

    const handleEdit = () => {
        dispatch(editConnectionPoints(el?.id, data, () => {
            reloadList();
            onClose();
        }));
    };

    const onChangeCoordinates = (params: { lat: string; lon: string }) => {
        setData((prevData: ConnectionPointItem) => ({
            ...prevData,
            ...params
        }));
        validation.deleteKeys(Object.keys(params));
    };

    const handleChange = (e: SelectChangeEvent<any>) => {
        const value = e.target.value;

        setData((prevData: ConnectionPointItem) => ({
            ...prevData,
            type_id: value,
        }));
    };

    const isDisabled = !data.name || !data.address || !data.lat || !data.lon || !data.type_id;

    return (
        <Modal
            title={isNew ? titles.ADD_CONNECTION_POINT: titles.EDIT_CONNECTION_POINT}
            onClose={onClose}
            noPadding
            maxWidthProp={'md'}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    isNew
                        ? {
                            ...buttonsTypes.create,
                            onClick: handleCreate,
                            disabled: isDisabled
                        }
                        : {
                            ...buttonsTypes.save,
                            onClick: handleEdit,
                            disabled: isDisabled
                        }
                ]}
            />}
        >
            <div className="modal__form">
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={6}>
                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('name')}
                            helperText={validation.get('name')}
                        >
                            <TextField
                                required={true}
                                label={titles.NAME}
                                variant="outlined"
                                size="small"
                                value={data.name}
                                onChange={
                                    (e) => setData({ ...data, name: e.target.value })
                                }
                                error={validation.isKey('name')}
                            />
                        </FormInfoWrapper>
                        <FormInfoWrapper
                            helperText={validation.get('type_id')}
                            error={validation.isKey('type_id')}
                        >
                            <FormControl size={'small'} variant="outlined" className="block" required>
                                <InputLabel>Тип</InputLabel>
                                <Select
                                    value={data.type_id}
                                    onChange={handleChange}
                                    label="Тип"
                                    name="type_id"
                                    required
                                >
                                    <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                                    {Object?.keys(connectionPointTypes)?.map((key) => (
                                        <MenuItem key={key} value={key}>{connectionPointTypes[key]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormInfoWrapper>
                        <FormControl className="block" variant="outlined">
                            <LatLonCoordinates
                                {...data}
                                onChange={onChangeCoordinates}
                                required
                            />
                        </FormControl>
                        <FormControl className="block" variant="outlined">
                            <LoadAddressByCoords
                                {...data}
                                onChange={onChangeCoordinates}
                                isLoadOnLatLon
                            >
                                <AddressList required returnGeometry={undefined} />
                            </LoadAddressByCoords>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl className="block" variant="outlined">
                            <MapDragMarker
                                {...data}
                                onChange={onChangeCoordinates}
                                required
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};

export default ModalForm;
