import React, { memo, useCallback, useState } from 'react';
import Modal from '../../../../../../common/Modal';
import { TextField } from '@mui/material';
import FormButtonsComponent, { buttonsTypes } from '../../../../../../common/FormButtons';
import { ModalTCType } from './types';
import FormSelect from './FormSelect';


const ModalTC = memo(({ data, open, selectTC, onClose, onSave }: ModalTCType) => {

    const [classData, setClassData] = useState({
        class_id: 0,
        count: 0
    });

    const list = selectTC.list.filter((item: { id: number; }) => 
        !data.vehicle_classes.some((el: { class_id: number; }) => item.id === el.class_id));

    const handleChange = useCallback(({ target: { name, value } }) => {        
        setClassData({
            ...classData,
            [name]: Number(value)
        });
    }, [classData]);

    const isDisabled = !(classData.count && classData.class_id);    
    
    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            noPadding
            title="Добавить класс ТС"
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isDisabled,
                        onClick: () => onSave(classData)
                    },
                ]}
            />}
        >
            <div className="modal__form">
                <div className="block">
                    <FormSelect
                        data={classData}
                        param={{
                            ...selectTC,
                            list: list
                        }}
                        callback={handleChange}
                    />
                </div>
                <div className="block">
                    <TextField
                        label="Kоличество ТС"
                        size="small"
                        value={classData.count}
                        variant="outlined"
                        name="count"
                        type="number"
                        onChange={handleChange}
                        inputProps={{ min: 0 }}
                    />
                </div>
            </div>
        </Modal>
    );
});

export default ModalTC;