import Vehicles from '../../pages/Reports/TransportSpecial/Vehicles';
import VehicleMileage from '../../pages/Reports/TransportSpecial/VehicleMileage';

const routes = [
    {
        path: '/reports/transport-special/vehicles',
        component: Vehicles,
        exact: true,
    },
    {
        path: '/reports/transport-special/vehicle-mileage',
        component: VehicleMileage,
        exact: true,
    },
];

export default routes;