import { Fragment } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { TextField } from '@mui/material';
import cn from 'classnames';

import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import CustomSwitch from 'components/common/CustomSwitch';
import TypesChecklistMistakes from 'components/common/Autocomplete/TypesChecklistMistakes';

import styles from './checkListModal.module.scss';

const CheckList = ({ checkList = [], setCheckList, check = null }) => {

    const handleSwitchChange = (e, index) => {
        const { name, checked } = e.target;

        setCheckList((prevList) => [
            ...prevList.slice(0, index),
            { ...prevList[index], [name]: !checked },
            ...prevList.slice(index + 1),
        ]);
    };

    const handleCommentChange = (e, index) => {
        const { name, value } = e.target;

        setCheckList((prevList) => [
            ...prevList.slice(0, index),
            { ...prevList[index], [name]: value },
            ...prevList.slice(index + 1),
        ]);
    };

    const handleMistakeTypeChange = (value, index) => {
        setCheckList((prevList) => [
            ...prevList.slice(0, index),
            { ...prevList[index], error_name: value.name, error_id: value.id },
            ...prevList.slice(index + 1),
        ]);
    };

    const isChecked = !!check?.id;

    return (
        <Scrollbars
            style={{ width: '55%', height: '100%' }}
            renderView={(props) => <div style={{ paddingRight: '16px', ...props?.style }} />}
        >
            <h2>Чек-лист</h2>
            {check && <div>{`Проверил: ${fullDateTimeWithoutSeconds(check.created_at)}  ${check.first_name} ${check.last_name}`}</div>}
            {checkList.length > 0
                ? checkList.map((item, index) => (
                    <Fragment key={item.check_id}>
                        <div className="row align-items-center">
                            <div className="row__item row">
                                <CustomSwitch
                                    name={'check_answer'}
                                    size="small"
                                    checked={!item.check_answer}
                                    onChange={(e) => handleSwitchChange(e, index)}
                                    title={item.check_answer ? 'Есть ошибки' : 'Нет ошибок' }
                                    disabled={isChecked}
                                />
                                <span>{item.name}</span>
                            </div>
                            <div className="row__item">
                                <div className="block">
                                    <TypesChecklistMistakes
                                        label="Типы ошибок"
                                        selected={item.error_name ? { id: item.error_id, name: item.error_name } : {}}
                                        onChange={(value) => {
                                            handleMistakeTypeChange(value, index);
                                        }}
                                        disabled={isChecked}
                                    />
                                </div>
                                <div className="block">
                                    <TextField
                                        className={cn('row__item', styles.input)}
                                        name={'check_comment'}
                                        size="small"
                                        label="Комментарий"
                                        variant="outlined"
                                        value={item.check_comment || ''}
                                        onChange={(e) => handleCommentChange(e, index)}
                                        disabled={isChecked}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                    </Fragment>
                ))
                : <div>чек-лист отсутствует</div>
            }
        </Scrollbars>
    );
};

export default CheckList;
