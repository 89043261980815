import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import queryString from 'query-string';
import { Scrollbars } from 'react-custom-scrollbars';

import { clearCompanyLink, getDeviceListEcology } from 'redux/Ecology/actions';
import { ecologySelectors } from 'redux/Ecology';
import NoData from 'components/common/Information/NoData';
import PageLayout from 'components/layout/PageLayout';
import PassportTabs from 'components/common/PassportTabs';
import { LSContainer } from 'components/common/List';
import Organizations from 'components/common/CompanyLink/Organizations';
import titles from 'helpers/constants/titles';

import Item from './Item';
import Filters from './Filters';
import BasicData from './PassportPage/BasicData';
import Boards from './PassportPage/Boards';

import type { EcologyDevice } from 'types/Ecology';


const Devices = () => {
    const dispatch = useDispatch();
    const scrollRef = useRef<Scrollbars>(null);
    const history = useHistory();
    const location = useLocation();

    const list = useSelector(ecologySelectors.list);
    const listLoading = useSelector(ecologySelectors.listLoading);

    const [limit, setLimit] = useState<number>(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState<{ page: number; data: Record<string, unknown> }>({ page: 1, data: {} });
    const [selectedItem, setSelectedItem] = useState<EcologyDevice | null>(null);

    const stringifyUrlFunc = (param: Record<string, unknown> = {}) => {
        const stringifyUrl = queryString.stringify(param, { arrayFormat: 'bracket' });
        history.replace({ pathname: '/dictionaries/ecology/devices', search: stringifyUrl });
    };

    const onChangePage = (page: number) => {
        setParams({ ...params, page });
        stringifyUrlFunc({ page, limit });
    };

    const onClickItem = (item: EcologyDevice) => {
        if (item.id === selectedItem?.id) {
            stringifyUrlFunc({ page: params.page, limit });
        } else {
            stringifyUrlFunc({ page: params.page, limit, device_id: item.id });
            dispatch(clearCompanyLink());
        }
    };

    useEffect(() => {
        const { page, limit } = queryString.parse(location.search, { arrayFormat: 'bracket' });
        if (page || limit) {
            setParams((params) => ({
                ...params,
                ...(page && { page: Number(page) }),
                ...(limit && { limit: Number(limit) }),
            }));
        }
    }, [location.search]);

    useEffect(() => {
        if (list?.data?.length > 0) {
            const { device_id } = queryString.parse(location.search, { arrayFormat: 'bracket' });
            const item = device_id ? list.data.find((item: EcologyDevice) => item.id === Number(device_id)) : null;
            setSelectedItem(item || null);
        }
    }, [list?.data, location.search]);

    useEffect(() => {
        dispatch(getDeviceListEcology({ page: params.page, limit, ...params.data }));
    }, [dispatch, limit, params]);

    const RenderList: React.FC = () => (
        <LSContainer headers={[
            { title: titles.NAME, width: 'auto' },
            { title: titles.ACTIONS, isActions: true }
        ]}>
            {list.data.map((item: EcologyDevice) => (
                <Item
                    key={item.id}
                    onClickItem={() => onClickItem(item)}
                    item={item}
                    selectedItem={selectedItem?.id === item.id}
                />
            ))}
        </LSContainer>
    );

    return (
        <PageLayout
            header="Экологический мониторинг"
            filters={<Filters setParams={setParams} />}
            informPanelProps={{ total: list?.meta?.total }}
            loading={!!listLoading}
            content={() => (
                list?.data?.length > 0
                    ? (
                        <Grid container style={selectedItem?.id ? { height: '100%' } : {}}>
                            <Grid item xs={selectedItem?.id ? 3 : 12} style={{ height: '100%' }}>
                                {selectedItem?.id
                                    ? (
                                        <Scrollbars style={{ height: '100%' }} ref={scrollRef}>
                                            <RenderList />
                                        </Scrollbars>
                                    )
                                    : (
                                        <RenderList />
                                    )
                                }
                            </Grid>
                            {selectedItem?.id && (
                                <Grid item xs={9}>
                                    <PassportTabs tabs={[
                                        {
                                            value: 'data',
                                            label: titles.BASIC_DATA,
                                            icon: <i className="fal fa-info-square"/>,
                                            contentComponent: <BasicData item={selectedItem}/>,
                                        },
                                        {
                                            value: 'organizations',
                                            label: titles.ORGANIZATIONS,
                                            icon: <i className="fal fa-file-alt" />,
                                            contentComponent: <Organizations item={selectedItem} microservice="ecology"/>,
                                        },
                                        {
                                            value: 'boards',
                                            label: 'ДИТ',
                                            icon: <i className="fas fa-border-none"/>,
                                            contentComponent: <Boards item={selectedItem}/>,
                                        }
                                    ]}/>
                                </Grid>
                            )}
                        </Grid>
                    )
                    : !listLoading && <NoData />
            )}
            paginationProps={{
                loadList: onChangePage,
                list: list.meta,
                limit: limit,
                setLimit: setLimit,
            }}
        />
    );
};

export default Devices;
