import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadWorks } from 'redux/RoadWorks/actions';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const SelectWorks = ({
    multiple,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label= 'Дорожные работы',
    error = false,
    helperText = '',
    filter = {},
    test_id_prefix,
}) => {
    const dispatch = useDispatch();

    const [showList, setShowList] = useState(false);

    const autocompleteChange = (e, value) => {
        if (e?.key !== 'Enter') onChange(value);
    };

    const getWorks = (params) => {
        const { page, limit, query: name } = params;

        dispatch(loadWorks({
            page,
            limit,
            ...( name && { name }),
            ...filter,
        }
        ));
    };

    const handleClose = () => setShowList(false);

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={autocompleteChange}
                error={error}
                helperText={helperText}
                required={required}
                disabled={disabled}
                onReset={() => onChange(multiple ? [] : {})}
                renderLabel={(option) => option?.name || ''}
                inputName="work"
                label={label}
                onShowList={() => setShowList(true)}
                test_id_prefix={test_id_prefix}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getWorks}
                    storeName="roadworks"
                    storeNameProps="works"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selected}
                    renderProps={(el) => <div>{el?.name}</div>}
                    onAccept={handleAccept}
                    isOpen={showList}
                    onClose={handleClose}
                    noPadding
                    title={'Добавление'}
                    small
                />
            }
        </>
    );
};

export default SelectWorks;
