import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemText, Divider } from '@mui/material';

import messages from 'helpers/constants/messages';
import { deleteTag } from 'redux/DorisControl/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import EditModal from './Modal';

const Item = ({ item, reloadList }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return (
        <>
            <ListItem className="list_item">
                <ListItemText>
                    <span>{item.name}</span>
                </ListItemText>
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setOpenEditModal(true),
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setOpenDeleteModal(true),
                        },
                    ]}
                    noMarginLeft
                    justButton
                />

                <Divider/>
            </ListItem>
            {openEditModal
            && <EditModal
                reloadList={reloadList}
                isOpen={openEditModal}
                item={item}
                onClose={() => setOpenEditModal(false)}
            />}
            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    message={messages.CONFIRM_DELETE}
                    onSuccess={() => {
                        dispatch(deleteTag(item.id, reloadList));
                        setOpenDeleteModal(false);
                    }}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
