import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadStagesList, loadStagesStatuses } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import { LSContainer } from 'components/common/List';

import OrderStageInfo from './OrderStageInfo';
import OrderStageModal from './OrderStageModal';

const OrderStage = ({ currentOrder = {}, vehicle = null }) => {
    const dispatch = useDispatch();
    const stagesData = useSelector(roadworksSelectors.stagesData);
    const stagesMeta = useSelector(roadworksSelectors.stagesMeta);
    const loadingStages = useSelector(roadworksSelectors.loadingStages);
    const statuses = useStoreProp(loadStagesStatuses, 'roadworks', 'stagesStatuses');

    const [createStageModal, setCreateStageModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = () => dispatch(loadStagesList(params.page, limit, {
        ...params.data, ...(!!currentOrder ? { order_ids: [currentOrder.id] } : {})
    }));

    useEffect(() => {
        if (currentOrder.id) {
            dispatch(loadStagesList(params.page, limit, {
                ...params.data,
                order_ids: [currentOrder.id]
            }));
        }
    }, [currentOrder.id, dispatch, limit, params]);

    return (
        <>
            <PageLayout
                informPanelProps={{
                    buttons: <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.add,
                                onClick: () => setCreateStageModal(true),
                                accessProp: 'is_read',// костыль пока для нестандартных прав когда на всю страницу нет прав
                            }
                        ]}
                        positionLeft
                        noPadding
                    />,
                    total: stagesMeta?.total
                }}

                loading={loadingStages}

                content={() => stagesData?.length > 0
                    ? (
                        <LSContainer
                            headers={[
                                { title: '', width: '5%' },
                                { title: titles.NAME, width: '17%' },
                                { title: titles.ORGANIZATION, width: '26%' },
                                {
                                    title: <span>План. дата <br/> начала</span>,
                                    align:'center',
                                    width: '14%' },
                                {
                                    title: <span>План. дата <br/> окончания</span>,
                                    align:'center',
                                    width: '14%' },
                                {
                                    title: <span>Факт. дата <br/>&nbsp;</span>,
                                    align:'center',
                                    width: '14%'
                                },
                                {  title: 'Действия',  isActions: true }
                            ]}
                        >
                            {stagesData.sort((a, b) => a.number - b.number).map((item) =>
                                <OrderStageInfo
                                    key={item?.id}
                                    item={item}
                                    currentOrder={currentOrder}
                                    statuses={statuses}
                                    currentStages={stagesData}
                                    reloadList={reloadList}
                                    vehicle={vehicle}
                                />
                            )}
                        </LSContainer>
                    )
                    : !loadingStages && <NoData/>
                }

                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: stagesMeta,
                    limit,
                    setLimit
                }}
            />

            {createStageModal && (
                <OrderStageModal
                    isOpen={createStageModal}
                    isNew={true}
                    onClose={() => setCreateStageModal(false)}
                    statuses={statuses}
                    currentOrder={currentOrder}
                    reloadList={() => setParams({ page: 1, data: { order_ids: [currentOrder.id] } })}
                />
            )}
        </>
    );
};

export default OrderStage;
