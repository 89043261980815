import React, { useState } from 'react';
import { IconButton, ListItem, ListItemText } from '@mui/material';
import messages from '../../../../helpers/constants/messages';
import titles from '../../../../helpers/constants/titles';
import Info from './Info';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { fullDateTimeWithoutSeconds } from '../../../../helpers/date.config';


const Item = ({ item }) => {
    const [infoOpen, setInfoOpen] = useState(false);

    const handleInfo = () => {
        setInfoOpen(!infoOpen);
    };

    return (
        <>
            <ListItem className="list_item">
                <ListItemText onClick={handleInfo}>
                    <div>
                        <strong>
                            Название события:
                        </strong>&nbsp;
                        {' '}
                        {item?.name || messages.INFO_IS_NOT_FOUND }
                    </div>
                    <div>
                        <strong>
                            Дата/время события:
                        </strong>&nbsp;
                        {fullDateTimeWithoutSeconds(item.registered_at) || messages.INFO_IS_NOT_FOUND }
                        {' '}
                        <strong>
                            Дата/время регистрации:
                        </strong>&nbsp;
                        {fullDateTimeWithoutSeconds(item?.created_at) || messages.INFO_IS_NOT_FOUND}
                    </div>
                    <div>
                        <strong>{titles.ADDRESS}:</strong>
                        {' '}
                        {item?.address || messages.INFO_IS_NOT_FOUND },
                    </div>
                    <div>
                        <strong>{titles.TYPE}:</strong> {item?.type_id || messages.INFO_IS_NOT_FOUND }
                    </div>
                    <div>
                        <strong>{titles.STATUS}:</strong> {item?.status_id || messages.INFO_IS_NOT_FOUND }
                        {' '}
                        <strong>{titles.THREAT_LEVEL}:</strong>
                        {' '}
                        <span style={{ color: item?.threat_color }}>
                            {item?.threat_level_id || messages.INFO_IS_NOT_FOUND }
                        </span>
                    </div>
                </ListItemText>
                <IconButton onClick={handleInfo} size={'small'}>
                    {infoOpen ? (
                        <ExpandLess fontSize={'inherit'}/>
                    ) : (
                        <ExpandMore fontSize={'inherit'}/>
                    )}
                </IconButton>
            </ListItem>
            {infoOpen
                && <Info
                    isOpen={infoOpen}
                    item={item}
                />
            }
        </>
    );
};

export default Item;