import React from 'react';
import { ListItemSecondaryAction, ListItemText } from '@mui/material';

// колонка записи
const ContentColumn = ({
    isActions = false,
    width = 'auto',
    align = 'left',
    title = '',
    children,
    style = {},
}) => (
    <>
        {isActions
            ? (
                <ListItemSecondaryAction className="item no-padding" style={style}>
                    {children}
                </ListItemSecondaryAction>
            )
            : (
                <ListItemText
                    style={{
                        width,
                        maxWidth: width,
                        textAlign: align,
                        ...style
                    }}
                    className="item cell"
                >
                    {!!title && (
                        <strong className="mobile-only">{title}: </strong>
                    )}
                    {children}
                </ListItemText>
            )
        }
    </>
);

export default ContentColumn;