import { fullDateTime } from 'helpers/date.config';
import messages from 'helpers/constants/messages';

const labels = {
    first: {
        created_at: {
            title: 'Въезд в зону фиксации',
            value: (value) => fullDateTime(value)
        },
        stop_at: {
            title: 'Выезд из зоны фиксации',
            value: (value) => fullDateTime(value)
        },
        camera_name: {
            title: 'Камера',
            value: (value) => value || messages.NO_DATA
        },
        line: {
            title: 'Полоса движения',
            value: (value) => value ?? <span>{messages.NO_DATA}</span>
        },
        vehicle_direction_stop: {
            title: 'Направление движения'
        },
        violation_type_text: {
            title: 'Тип события',
            value: (data) => <span>{data || messages.NO_DATA} </span>
        },
    },
    second: {
        // vehicle_direction_start: {
        //     title: 'Направление въезда'
        // },
        grz: {
            title: 'ГРЗ',
            value: (value) => value || <span>Не распознан</span>
        },
        vehicle_brand: {
            title: 'Марка',
        },
        vehicle_model: {
            title: 'Модель'
        },
        vehicle_color: {
            title: 'Цвет'
        },
        class_name_ru: {
            title: 'Класс ТС',
            value: (value) => value || messages.NO_DATA
        },
        vehicle_speed: {
            title: 'Скорость',
            value: (value) => typeof value === 'number' ? `${value} км/ч` : messages.NO_DATA
        },
    }
};

export default labels;
