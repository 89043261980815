import { useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import { getHumanDate } from 'helpers/date.config';
import { useStoreProp } from 'helpers/hooks';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';
import CircleStatus from 'components/common/CircleStatus';
import ConfirmModal from 'components/common/ConfirmModal';
import { cancelMaterialExport } from 'redux/DorisControl/actions';
import { loadOrderStatuses } from 'redux/RoadWorks/actions';

import ModalForm from './ModalForm';
import Info from './Info';
import type { ItemProps } from './types';


const Item = ({ item, loadList, types }: ItemProps) => {
    const dispatch = useDispatch();
    const orderStatuses = useStoreProp(loadOrderStatuses, 'roadworks', 'orderStatuses');
    
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);

    const callback = () => {
        loadList(true);
        setOpenCancelModal(false);
    };

    return (
        <>
            <LSContentItem onClick={() => setOpenInfo((prev: boolean) => !prev)}>
                <LSContentColumn>
                    <CircleStatus title={item.status_text} color={orderStatuses?.[item?.status]?.color} />
                </LSContentColumn>
                <LSContentColumn>
                    {item.destination || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.types.map((id, i, arr) => `${types[id] || id}${arr.length - 1 === i ? '' : ', '}`)}
                </LSContentColumn>
                <LSContentColumn>
                    {getHumanDate(item.start_date) || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {getHumanDate(item.end_date) || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {`${item.created?.first_name || ''} ${item.created?.last_name || ''}`.trim() || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {getHumanDate(item.created_at) || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.percent}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                                disabled: item.status !== 1
                            },
                            {
                                ...buttonsTypes.cancelIcon,
                                onClick: () => setOpenCancelModal(true),
                            },
                        ]}
                    />
                    <ActionMore isOpen={openInfo} />
                </LSContentColumn>
            </LSContentItem>

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    loadList={loadList}
                    item={item}
                    isNew={false}
                />
            )}

            {openCancelModal && (
                <ConfirmModal
                    open={openCancelModal}
                    onSuccess={() => dispatch(cancelMaterialExport(item.id, callback))}
                    onClose={() => setOpenCancelModal(false)}
                    message={messages.CONFIRM_CANCEL}
                />
            )}

            {openInfo && (
                <Info
                    isOpen={openInfo}
                    info={item}
                />
            )}
        </>
    );
};

export default Item;
