import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiMethods = {
    getOsrmCar: async (coords) => {
        const response = await instance.get(apiUrls.getOsrmCar(coords), {
            params: {
                // overview: false
                // steps: true
                geometries: 'geojson'
            },
        });
        return response.data;
    },
};

export default ApiMethods;
