import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import messages from '../../../../../helpers/constants/messages';
import titles from '../../../../../helpers/constants/titles';
import renderAddress from '../../../../../helpers/renderAddress';
import { parkingSelectors } from '../../../../../redux/Parking';
import config from '../config';
import { getHumanDate } from '../../../../../helpers/date.config';
import Information from '../../../../common/Information';
import useStoreProp from '../../../../../helpers/hooks/useStoreProp';
import { loadTypes } from '../../../../../redux/Parking/actions';


const PopUp = ({ id = 0 }) => {

    const polygon = useSelector(parkingSelectors.polygon);

    const types = useStoreProp(
        loadTypes,
        'parking',
        'types'
    );

    // забираем данные из полигона
    const data = useMemo(() => polygon
        ?.find(item => item.id === id)
        || {}
    , [id, polygon]);

    const isNumber = (value) => typeof value === 'number';

    const fields = {
        name: {
            title: titles.NAME,
            value: ({ name }) => name || messages.INFO_IS_NOT_FOUND,
        },
        address_text: {
            title: 'Адрес дислокации',
            value: ({ address, address_text }) => address_text || renderAddress(address),
        },
        category_id: {
            title: 'Категория',
            value: ({ category_id }) => types?.[category_id],
        },
        created_at: {
            title: 'Дата создания',
            value: ({ created_at }) => getHumanDate(created_at),
        },
        // fixated_at: {
        //     title: 'Дата регистрации',
        //     value: ({ fixated_at }) => getHumanDate(fixated_at),
        // },
        // description: {
        //     title: 'Описание',
        //     value: ({ description }) => description || messages.INFO_IS_NOT_FOUND,
        // },

        spaces_total: {
            title: 'Мест всего',
            value: ({ spaces }) => isNumber(spaces?.total)
                ? spaces?.total?.toString()
                : '0',
        },
        spaces_common: {
            title: 'Общих',
            value: ({ spaces }) => isNumber(spaces?.common)
                ? spaces?.common?.toString()
                : '0',
        },
        spaces_handicapped: {
            title: 'Для инвалидов',
            value: ({ spaces }) => isNumber(spaces?.handicapped)
                ? spaces?.handicapped?.toString()
                : '0',
        },
        spaces_fill: {
            title: 'Занято',
            value: ({ spaces, free_spaces }) => isNumber(spaces?.total) && spaces?.total > 0
                ? (spaces?.total - free_spaces || 0)?.toString()
                : '0',
        },
        free_spaces: {
            title: 'Свободно',
            value: ({ free_spaces }) => isNumber(free_spaces)
                ? free_spaces?.toString()
                : '0',
        },
    };

    return (
        <div className="map-popup">
            <div
                className="map-popup_header"
                style={{ backgroundColor: config.mapMarkersColors.default }}
            >
                <div>
                    <div className="img">
                        <i className={config.layerIcon}/>
                    </div>
                    <span>
                        {data?.name || ''}
                    </span>
                </div>
            </div>
            <div className="map-popup_body">
                <div className="scroll">
                    <Information
                        data={data}
                        title={fields}
                        returnNull
                    />
                </div>
            </div>
        </div>
    );
};

export default PopUp;
