import { useState } from 'react';
import LayoutFilter from '../../../layout/PageLayout/LayoutFilter';
import { TextField } from '@mui/material';
import titles from '../../../../helpers/constants/titles';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';

const Filter = ({ onFilterChanged }) => {
    const initialState = { name: '' };
    
    const [data, setData] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setData(prev => ({ ...prev, [name]: value }));
    };

    const resetFilter = (needRefresh) => {
        needRefresh && onFilterChanged(removeEmptyFields(initialState));
        setData(initialState);
    };

    const updateFilter = (filters) => {
        const newFilter = { ...initialState, ...filters };
        setData(newFilter);

        if (Object.keys(filters).length > 0) {
            onFilterChanged(removeEmptyFields(newFilter));
        }
    };

    return (
        <LayoutFilter
            filter={data}
            onSearch={() => onFilterChanged(removeEmptyFields(data))}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            disabled={!data.name}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={data?.name}
                    onChange={handleChange}
                    label={titles.NAME}
                    variant="outlined"
                    name="name"
                    type="text"
                    size="small"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;