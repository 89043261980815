import * as types from './types';

const initialState = {
    list: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    listLoading: false,
    statuses: {},
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.LOADED_WORK_REQUEST_LIST:
            return {
                ...state,
                list: payload
            };
        
        case types.LOADING_WORK_REQUEST_LIST:
            return {
                ...state,
                listLoading: payload
            };
        
        case types.LOADED_WORK_REQUEST_STATUSES:
            return {
                ...state,
                statuses: payload
            };

        default:
            return state;
    }
}