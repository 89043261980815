import {
    checkBase64,
} from '../../../../../components/MapComponents/Layers/TransportPassenger/helper';
import defaultIcon from '../../defaultIcon';
import { renderToString } from 'react-dom/server';
import colorExtend from 'helpers/mapHelper/colorExtend';

// функции для хука по категориям
const transportCategoryHelper = (
    // useTransportCategory
    transportCategoryObject,
) => {
    const categories = transportCategoryObject?.categoriesObj || {};

    return {
        /*getCategoryElement: (category_id) => {
            const imageObj = categories?.[category_id] || null;
            return {
                getName: () => imageObj?.name,
                getColor: () => imageObj?.color || defaultIcon.color,

                getImage: (in_work_order) => in_work_order
                    ? imageObj?.image_with_work_order
                    : imageObj?.image,
            };
        },*/

        // получить карегорию ТС
        getCategory: (category_id, in_registry, in_work_order) => {
            // категория (если в реестре)
            const imageObj = in_registry
                ? categories?.[category_id] || null
                : null;

            // цвет вокруг иконки
            const backColor = colorExtend(imageObj?.color || defaultIcon.color);

            // иконка (в наряде или нет)
            const imageData = in_work_order
                ? imageObj?.image_with_work_order
                : imageObj?.image;

            const getImageTpl = (className = '', defaultIconItem = null) => in_registry
                ? (imageData
                    ? <img className={className} src={checkBase64(imageData)} />
                    : defaultIconItem || defaultIcon?.image
                )
                : defaultIcon?.image || defaultIconItem;

            return {
                // картинка
                image: imageData ? checkBase64(imageData) : null,

                // название
                name: imageObj?.name,

                // цвет
                color: backColor,

                // получить шаблон иконки
                // todo подумать с дефолтной иконкой
                getImageTpl: (className = '', defaultIconItem = null) => in_registry
                    ? (imageData
                        ? <img className={className} src={checkBase64(imageData)} />
                        : defaultIconItem || defaultIcon?.image
                    )
                    : defaultIcon?.image || defaultIconItem,

                getImageTplHtml: (className = '', defaultIconItem = null) => renderToString(getImageTpl(className, defaultIconItem))
            };
        },

        // получить карегорию ТС по пассажирке
        // https://yt.formattwo.ru/issue/UAPTS-7106
        getCategoryPassenger: (category_id, in_registry, in_work_order) => {
            // категория
            // todo подумать categories?.[0] ||
            const imageObj = categories?.[category_id] || null;

            // цвет вокруг иконки
            const backColor = colorExtend((in_registry ? imageObj?.color : imageObj?.color_not_in_registry) || defaultIcon.color);

            // иконка (в наряде или нет)
            const imageData = in_work_order ? imageObj?.image_with_work_order : imageObj?.image;

            const getImageTpl = (className = '', defaultIconItem = null) => category_id
                ? (
                    imageData
                        ? <img className={className} src={checkBase64(imageData)} />
                        : defaultIconItem || defaultIcon?.image
                )
                : defaultIcon?.image;

            return {
                // картинка
                // image: imageData ? checkBase64(imageData) : null,

                // название
                name: imageObj?.name,

                // цвет
                color: backColor,

                // получить шаблон иконки
                // todo подумать с дефолтной иконкой
                getImageTpl,
                getImageTplHtml: (className = '', defaultIconItem = null) => renderToString(getImageTpl(className, defaultIconItem))
            };
        },

        // получить название
        getName: (category_id, defaultValue = '') => {
            return categories?.[category_id]?.name || defaultIcon.name || defaultValue;
        },

        // получить цвет
        getColor: (category_id) => {
            return colorExtend(categories?.[category_id]?.color || defaultIcon.color);
        },
    };
};

export default transportCategoryHelper;
