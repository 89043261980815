import React from 'react';
import {
    Typography,
} from '@mui/material';

import messages from 'helpers/constants/messages';
import WorksInfo from './WorksInfo';

const ListInfo = ({ data = [] }) => (
    <>
        {data?.map((item, index) => (
            <div
                key={index}
                style={{ padding: '0px 15px 15px' }}
            >
                <Typography variant="subtitle2">
                    <strong>
                        Район выполнения работ:
                    </strong>&nbsp;
                    {item.address || messages.INFO_IS_NOT_FOUND}
                </Typography>

                <WorksInfo
                    data={item.work_list || []}
                />
            </div>
        ))}
    </>
);

export default ListInfo;
