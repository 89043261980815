import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RestrictedRoute = ({
    component: Component,
    ...rest
}) => {
    const authUser = useSelector(({ auth }) => auth?.authUser);
    const projectCode = useSelector(({ auth }) => auth?.project?.code);

    const getComponent = (props) => {
        if (authUser && projectCode) {
            return <Component {...props} />;
        }
        if (!authUser) {
            return (
                <Redirect to="/signin" />
            );
        }
    };
    return <Route {...rest} render={getComponent} />;
};

export default RestrictedRoute;
