import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemText, Typography } from '@mui/material';

import { deleteEvent } from 'redux/SituationalPlans/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';

const Item = ({ item }) => {
    const dispatch = useDispatch();

    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        dispatch(deleteEvent(item?.id));
        setIsDelete(false);
    };

    return (
        <div className="list_item">
            <ListItem>
                <ListItemText>
                    <Typography>
                        { item?.name }
                    </Typography>
                    <Typography>
                        <strong>{ titles.START_DATE }: </strong>{ fullDateTimeWithoutSeconds(item?.date_start) }&nbsp;
                        <strong>{ titles.END_DATE }: </strong>{ fullDateTimeWithoutSeconds(item?.date_end) }
                    </Typography>
                </ListItemText>
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setIsEditMode(true),
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setIsDelete(true),
                        },
                    ]}
                    noMarginLeft
                    justButton
                />
            </ListItem>
            { isEditMode
            && <ModalForm
                isOpen={isEditMode}
                onClose={setIsEditMode}
                item={item}
                isNew={false}
            />}
            { isDelete
            && <ConfirmDelete
                open={isDelete}
                onClose={()=> setIsDelete(false)}
                onSuccess={handleDelete}
                message={messages.CONFIRM_DELETE}
            />}
        </div>
    );
};

export default Item;
