import L from 'leaflet';

import removeEmptyFields from 'helpers/removeEmptyFields';

import iconMap from './icons/iconMap';

// иконка маркера
export const createIconMarker = (color) => L.divIcon({
    className: 'marker',
    iconSize: [28, 36],
    iconAnchor: [15, 36],
    popupAnchor: [0, -36],
    html: iconMap({ fill: color }),
});

export const getFilter = (filter) => {
    const filters = {
        ...filter,
    };

    return removeEmptyFields(filters, false);
};