import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import {
    TextField,
    InputAdornment,
    IconButton,
    Autocomplete, FormControlLabel, Checkbox, Typography,
} from '@mui/material';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import * as actions from 'redux/TransportRoad/actions';
import AutocompleteCompanies from 'components/common/Autocomplete/Companies';
import MarkModelComponent from 'components/common/Transport/MarkModelComponent';


const Form = (props) => {
    const { data, onChange, onDelete } = props;
    const getValue = (key) => data[key] || '';
    const [brandmodel, setBrandModel] = useState({
        brand: data.brand_id_list?.[0] || null,
        model: data.model_id_list?.[0] || null,
    });

    const changeBM = (data) => {
        setBrandModel((old) => {
            const retData = (typeof data === 'function')
                ? data(old)
                : data;

            if (!isEqual(old?.brand, retData?.brand)) {
                return {
                    ...retData,
                    model: null
                };
            }
            return retData;
        });
    };

    useEffect(() => {
        // console.log('bm', bm);
        onChange({
            brand_id_list: brandmodel.brand ? [{
                ...brandmodel.brand,
                name: brandmodel.brand.name_ru
            }] : null
            ,
            model_id_list: brandmodel.model ? [{
                ...brandmodel.model,
                name: brandmodel.model.name_ru
            }] : null,
        });
    }, [brandmodel]);

    // const types = useStoreProp(actions.loadTypes, 'transportRoad', 'types');
    // const typesList = Object.keys(types).map((id) => ({ id, name: types[id] }));
    const vehiclesCategories = useStoreProp(
        actions.loadVehicleCategories,
        'transportRoad',
        'vehicleCategories'
    );

    const handleEventAC = (key) => (_, newValue) => {
        onChange({
            [key]: newValue
        });
    };

    /*todo
    - заявка (выбор из справочника, номер заявки на дорожные работы)
    - наряд (выбор из справочника, номер наряда)
    - участок (выбор из справочника, номер участка)
    */

    // const categoryList = useMemo(() => {
    //     console.log(vehiclesCategories);
    //     return vehiclesCategories;
    // }, [vehiclesCategories]);

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <TextField
                size="small"
                fullWidth
                className="block"
                label={titles.VEHICLE_NUMBER}
                id="outlined-margin-dense"
                value={getValue('number')}
                onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                //helperText="Some important text"
                variant="outlined"
                name="number"
                InputProps={{
                    endAdornment: (
                        <>
                            {getValue('number').length > 0 ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            onChange({ number: '' });
                                        }}
                                        edge="end"
                                        size="large">
                                        <span style={{ fontSize: '1rem' }}>x</span>
                                    </IconButton>
                                </InputAdornment>
                            ) : ''}
                        </>
                    )
                }}
            />

            {/*<div className="block">*/}
            {/*    <Autocomplete*/}
            {/*        noOptionsText={titles.NO_RESULTS}*/}
            {/*        value={getValue('type') || null}*/}
            {/*        getOptionSelected={(option, value) => option.name === value.name}*/}
            {/*        options={typesList}*/}
            {/*        filterSelectedOptions={true}*/}
            {/*        getOptionLabel={(option) => option?.name || ''}*/}
            {/*        onChange={handleEventAC('type')}*/}
            {/*        size="small"*/}
            {/*        renderInput={(params) => (*/}
            {/*            <TextField*/}
            {/*                {...params}*/}
            {/*                size="small"*/}
            {/*                variant="outlined"*/}
            {/*                label={titles.TYPE}*/}
            {/*                // error={isErrorField('type') || params?.error}*/}
            {/*                // helperText={getErrorValidation('type') || params?.helperText}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    />*/}
            {/*</div>*/}

            {/*<div>*/}
            {/*    <MarkAutocomplete*/}
            {/*        value={data.brand_id_list || []}*/}
            {/*        onChange={(values) => {*/}
            {/*            console.log('brands', values);*/}
            {/*            onChange({*/}
            {/*                brand_id_list: values.map(item => ({ ...item, name: item?.name_en })),*/}
            {/*                model_id_list: []*/}
            {/*            });*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <ModelAutocomplete*/}
            {/*        value={data.model_id_list || []}*/}
            {/*        onChange={(values) => {*/}
            {/*            console.log('models', values);*/}
            {/*            onChange({*/}
            {/*                model_id_list: values.map(item => ({ ...item, name: item?.name_en })),*/}
            {/*            });*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</div>*/}

            <div className="block">
                <MarkModelComponent
                    formData={brandmodel}
                    setFormData={changeBM}
                    useModalFull
                    styleName="block"
                    markLabel="Марка"
                    modelLabel="Модель"
                />
            </div>

            <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={data.category_id || ''}
                    // getOptionSelected={(option, value) => option?.name === value?.name}
                    options={vehiclesCategories}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option?.name || ''}
                    onChange={handleEventAC('category_id')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.TYPE_OF_VEHICLE}
                        />
                    )}
                />
            </div>

            <AutocompleteCompanies
                selectDeletedСompanies
                filter={{ withDeleted: 1 }}
                onChange={(value) => {
                    onChange({ 'organization_id': value.map((item) => ({ ...item, name: item.title })) });
                }}
                selected={data?.organization_id || []}
                multiple
                label={titles.ORGANIZATION}
            />

            <div className="row">

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data?.in_registry?.id || false}
                            onChange={(event) =>
                                (event.target.checked)
                                    ? onChange({
                                        in_registry: {
                                            id: event.target.checked || false,
                                            name: 'ТС в реестре'
                                        }
                                    })
                                    : onDelete('in_registry')
                            }
                            color="primary"
                        />
                    }
                    label={
                        <Typography variant="body2">
                            Показать ТС в реестре
                        </Typography>
                    }
                />

                {/*<FormControlLabel*/}
                {/*    control={*/}
                {/*        <Checkbox*/}
                {/*            checked={data?.in_work_order?.id || false}*/}
                {/*            onChange={(event) =>*/}
                {/*                (event.target.checked)*/}
                {/*                    ? onChange({*/}
                {/*                        in_work_order: {*/}
                {/*                            id: event.target.checked || false,*/}
                {/*                            name: 'ТС в нарядах'*/}
                {/*                        }*/}
                {/*                    })*/}
                {/*                    : onDelete('in_work_order')*/}
                {/*            }*/}
                {/*            color="primary"*/}
                {/*        />*/}
                {/*    }*/}
                {/*    label={*/}
                {/*        <Typography variant="body2">*/}
                {/*            Показать ТС в нарядах*/}
                {/*        </Typography>*/}
                {/*    }*/}
                {/*/>*/}

            </div>

        </form>
    );
};

export default Form;
