import React, { useState, useEffect } from 'react';
import { config } from '../../../../../../../config'; 
import {
    Map,
    FeatureGroup,
    MapControl,
    CircleMarker,
    ToolTip,
} from '../../../../../../MapComponents/leaflet';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../../../../helpers/constants/messages/common';
import titles from '../../../../../../../helpers/constants/titles';
import RouteJson from '../../../../../../MapComponents/Layers/TransportPassenger/RouteJson';
import { loadVehicleCategoryList } from '../../../../../../../redux/TransportRoad/actions';
import { transportRoadSelectors } from '../../../../../../../redux/TransportRoad';
import { getColorByRoadTransportCategory } from '../../../../../../MapComponents/Layers/RoadworkTransport/helper';

const MapForm  = ({
    currentRoute = [],
    checkPoints = []
}) => {
    const dispatch = useDispatch();
    const mapCenter = config.get('mapCenter');
    const [mapZoom, setMapZoom] = useState(null);
    const [bounds, setBounds] = useState(null);
    const routeCheckpoints = useSelector(transportRoadSelectors.routeCheckpoints);
    const vehicleCategoryList = useSelector(transportRoadSelectors.vehicleCategoryList);

    const color = getColorByRoadTransportCategory(currentRoute?.category_id, vehicleCategoryList?.data);
    
    useEffect(() => {
        dispatch(loadVehicleCategoryList());
    }, []);

    const stations = checkPoints; //routeCheckpoints[currentRoute?.id] || [];

    const checkPointsMarkers = stations?.reduce((r, { check_point = {} }) => {
        if (check_point && check_point?.lat && check_point?.lon) {
            const { lat, lon, id, name = '' } = check_point;
            r.push(
                <CircleMarker
                    key={id}
                    latlng={[lat, lon]}
                    radius={8}
                    weight={2}
                    fillColor={color}
                    fillOpacity={1}
                >
                    <CircleMarker
                        latlng={[lat, lon]}
                        radius={4}
                        weight={2}
                        fillColor="#fff"
                        fillOpacity={1}
                    >
                        <ToolTip
                            offset={[0, -5]}
                            direction="top"
                        >
                            <div>
                                <div><b>{titles.NAME}:</b> {name || messages.INFO_IS_NOT_FOUND}</div>
                            </div>
                        </ToolTip>
                    </CircleMarker>
                </CircleMarker>
            );
        }

        return r;
    }, []);

    return (
        <div className="map-form-wrap__container">
            <Map
                center={mapCenter}
                zoom={mapZoom}
                bounds={bounds}
            >
                <MapControl>
                    <FeatureGroup>
                        {checkPointsMarkers}
                    </FeatureGroup>
                    <RouteJson
                        onMap={false}
                        centerAfter={true}
                        item={currentRoute}
                        key={currentRoute.id}
                        disabled
                    />
                </MapControl>
            </Map>
        </div>
    );
};

export default MapForm;
