import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { getStatuses } from '../../../../redux/Admin/actions';
import icons from './img';
import styles from './admin.module.scss';
import title from '../../../../helpers/constants/titles';
import { adminSelectors } from '../../../../redux/Admin';

const titles = {
    dtp: title.DTP,
    meteo: title.METEO,
    camera: title.VIDEOFIXATION,
    route: title.PASSENGER_TRANSPORT,
    vehicles: title.ROADWORKS_VEHICLES,
};

const ServiceStatus = () => {
    const dispatch = useDispatch();
    const statuses = useSelector(adminSelectors.statuses);

    useEffect(() => {
        dispatch(getStatuses());
    }, [dispatch]);

    return (
        <div className={styles.wrapper}>
            <h1>Статус сервисов</h1>
            <div className={styles.unit}>
                <section className={styles.body}>
                    {Object.keys(statuses).map((key) => (
                        <Paper key={key} className={styles.status}>
                            <img src={icons[key + statuses[key]]} alt={key} />
                            {titles[key]}
                        </Paper>
                    ))}
                </section>
            </div>
        </div>
    );
};

export default ServiceStatus;
