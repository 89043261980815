import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setValidation } from 'redux/Validation/actions';
import { messageTypes, showMessage } from 'redux/Message/actions';
import { setMapLoading } from 'redux/Map/actions';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

function* loadAipListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingAipList(true));
    const response = yield call(api.getAipList, page, limit, query);
    if (response) {
        yield put(actions.loadedAipList(response));
    }
    yield put(actions.loadingAipList(false));
}

function* loadAipEventsSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingAipEvents(true));
    const response = yield call(api.getAipEvents, page, limit, query);
    if (response) {
        yield put(actions.loadedAipEvents(response));
    }
    yield put(actions.loadingAipEvents(false));
}

function* getForPolygonSaga({ payload: params }) {
    yield put(actions.setLoadingPolygon(true));
    yield put(setMapLoading('aip', true));
    const response = yield call(api.getForPolygon, params);
    if (response) {
        yield put(actions.saveForPolygon(response));
    }
    yield put(actions.setLoadingPolygon(false));
    yield put(setMapLoading('aip', false));
}

function* loadSidebarSaga({ payload }) {
    const params = payload;
    const page = params?.page || 1;
    yield put(actions.loadingSidebar(true));
    const complexesList = yield call(api.getSidebar, params);
    if (complexesList) {
        yield put(actions.loadedSidebar(complexesList, page));
    }
    yield put(actions.loadingSidebar(false));
}

function* createAipSaga({ payload: data }) {
    yield put(actions.loadingAipList(true));
    const response = yield call(api.createAip, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, 'АИП успешно создан');
    }
    yield put(actions.loadingAipList(false));
}

function* editAipSaga({ payload: { id, data } }) {
    yield put(actions.loadingAipList(true));
    const response = yield call(api.editAip, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, 'АИП успешно изменен');
    }
    yield put(actions.loadingAipList(false));
}

function* deleteAipSaga({ payload:  id }) {
    yield put(actions.loadingAipList(true));
    const response = yield call(api.deleteAip, id);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, 'АИП успешно удален');
    }
    yield put(actions.loadingAipList(false));
}

function* loadAipTypesSaga() {
    const response = yield call(api.getAipTypes);
    if (response?.success) {
        yield put(actions.loadedAipTypes(response.data));
    }
}

function* loadAipOperatorsSaga() {
    const response = yield call(api.getAipOperators);
    if (response?.success) {
        yield put(actions.loadedAipOperators(response.data));
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_LIST, loadAipListSaga),
        takeLatest(types.LOAD_AIP_EVENTS, loadAipEventsSaga),
        takeLatest(types.GET_POLYGON, getForPolygonSaga),
        takeLatest(types.LOAD_SIDEBAR, loadSidebarSaga),
        takeLatest(types.CREATE_AIP, createAipSaga),
        takeLatest(types.EDIT_AIP, editAipSaga),
        takeLatest(types.DELETE_AIP, deleteAipSaga),
        takeLatest(types.LOAD_AIP_TYPES, loadAipTypesSaga),
        takeLatest(types.LOAD_AIP_OPERATORS, loadAipOperatorsSaga),
    ]);
}
