import SummarySchedule from '../../pages/Reports/SummarySchedule';
import WorkordersReport from '../../pages/Reports/WorkordersReport';
import WorkordersByFlights from '../../pages/Reports/WorkordersByFlights';
import WorkordersByVehicles from '../../pages/Reports/WorkordersByVehicles';
import EmployeesTransportation from '../../pages/Reports/EmployeesTransportation';
import SpeedViolations from '../../pages/Reports/SpeedViolations';
import Vehicles from '../../pages/Reports/PassengerTransport/Vehicles';
import PassengersCount from '../../pages/Reports/PassengerTransport/PassengersCount';

const routes = [
    {
        path: '/reports/passenger-transport/workorders',
        component: WorkordersReport,
        exact: true
    },
    {
        path: '/reports/passenger-transport/summary-schedule',
        component: SummarySchedule,
        exact: true
    },
    {
        path: '/reports/passenger-transport/workorders-by-flights',
        component: WorkordersByFlights,
        exact: true
    },
    {
        path: '/reports/passenger-transport/workorder-by-vehicles',
        component: WorkordersByVehicles,
        exact: true
    },
    {
        path: '/reports/passenger-transport/employees-transportation',
        component: EmployeesTransportation,
        exact: true
    },
    {
        path: '/reports/passenger-transport/speed-violations',
        component: SpeedViolations,
        exact: true
    },
    {
        path: '/reports/passenger-transport/vehicles',
        component: Vehicles,
        exact: true
    },
    {
        path: '/reports/passenger-transport/report-by-passengers',
        component: PassengersCount,
        exact: true
    },

];

export default routes;