import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { getCompaniesForAllRoles, loadCompanies } from 'redux/Companies/actions';
import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import type { CompanyTypes, Item } from './types';

const SelectCompany = ({
    multiple = false,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label = titles.ORGANIZATION,
    withRole = true,
    error = false,
    helperText = '',
    filter = {},
    sortedList = null,
    selectDeletedCompanies = false, // возможность выбрать в селекте удаленную компанию
    limitTags = 1,
    test_id_prefix = ''
}: CompanyTypes<Item>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const getList = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query: title } = params;
        if (withRole) {
            dispatch(loadCompanies(page, limit, {
                ...(title && { title }),
                ...filter,
            }));
        } else {
            dispatch(getCompaniesForAllRoles(page, limit, {
                ...(title && { title }),
                ...filter,
            }));
        }
    };

    const handleClose = () => setShowList(false);

    const handleAccept = (data: any) => {
        onChange(data);
        setShowList(false);
    };

    const onReset = () => {
        const initialState = multiple ? [] : {};
        // @ts-ignore - строка выше верна, а типизация CommonAutocomplete нет, поэтому вынужден добавить ts-ignore
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete<Item>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={(option) => option?.title || ''}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
                test_id_prefix={test_id_prefix}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={!sortedList && getList}
                    sortedList={sortedList}
                    storeName="companies"
                    storeNameProps="companies"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div>{el.title}</div>}
                    selectDeletedItem={selectDeletedCompanies}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    small
                    title={titles.SELECT_ORGANIZATION}
                    test_id_prefix={test_id_prefix}
                />
            }
        </>
    );
};

export default SelectCompany;
