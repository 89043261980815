import { useState } from 'react';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import messages from 'helpers/constants/messages';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import DTPTypeModal from './DTPTypeModal';

const DTPTypesItem = ({ item, permissions }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            {openModal && <DTPTypeModal onClose={() => setOpenModal(false)} isOpen={openModal} item={item}/>}
            <LSContentItem>
                <LSContentColumn>
                    <DirectionsCarIcon style={{ color: item.color }}/>
                </LSContentColumn>
                <LSContentColumn>
                    {item.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            ...(item.id !== 'default'
                                ? [{
                                    ...buttonsTypes.editIcon,
                                    onClick: () => setOpenModal(true),
                                }]
                                : []
                            ),
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
        </>
    );
};

export default DTPTypesItem;
