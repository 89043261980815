import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';

import {
    loadDistrictsByMonth,
    loadTransportIncidentStatuses,
    loadTransportIncidentTypeCreation,
    setFilterDistrictsByMonth,
} from 'redux/SituationalPlans/actions';
import titles from 'helpers/constants/titles';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import { useStoreProp } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import SelectThreadLevel from 'components/common/Autocomplete/DorisReact/SelectThreadLevel';
import SelectTransportIncidentsTypes from 'components/common/Autocomplete/DorisReact/TransportIncidentsTypes';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filters = ({ setFilterValues, filterValues, initialState, onChange }) => {
    const dispatch =  useDispatch();

    const statuses = useStoreProp(loadTransportIncidentStatuses, 'SituationalPlans', 'transportIncidentStatuses');
    const typeCreation = useStoreProp(loadTransportIncidentTypeCreation, 'SituationalPlans', 'transportIncidentTypeCreation');

    const autoCompleteChange = (name) => (e, value) => {
        if (e?.key !== 'Enter') {
            setFilterValues({ ...filterValues, [name]: value });
        }
    };

    const getFiltered = (filter) => {
        const currentFilter = filter || filterValues;
        dispatch(setFilterDistrictsByMonth(currentFilter));

        const result = {
            start_date: dateWithDashYYYYMMDD(currentFilter.start_date),
            end_date: dateWithDashYYYYMMDD(currentFilter.end_date),
            type: currentFilter?.type?.id || null,
            status: currentFilter?.status?.id || null,
            threat_level: currentFilter?.threat_level?.id || null,
            type_creation: currentFilter?.type_creation?.id || null,
            is_area: Number(currentFilter?.is_area) || 0,
        };

        dispatch(loadDistrictsByMonth(removeEmptyFields(result)));
    };

    const resetFilters = (needRefresh) => {
        if (needRefresh) {
            getFiltered(initialState);
        }
        setFilterValues(initialState);
    };

    const [isDisabled, setIsDisabled] = useState(false);
    const isDisabledHandler = (value) => setIsDisabled(value);

    const handleChangeIncidentTypes = (e) => {
        setFilterValues((old) => ({
            ...old,
            type: e,
        }));
    };

    const updateFilter = (filters) => {
        const result = {
            ...initialState,
            ...filters,
        };
        setFilterValues(result);
        getFiltered(result);
    };

    const typeCreationList = useMemo(() => Object.keys(typeCreation)?.map(id => ({ id, name: typeCreation[id] })), [typeCreation]);

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={() => getFiltered(filterValues)}
            filter={filterValues} 
            filterException={['start_date', 'end_date']} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
            disabled={!filterValues.start_date || !filterValues.end_date || isDisabled}
        >
            <LayoutFilter.Visible>

                {/*дата начала периода, по умолчанию первое число текущего месяца (для даты регистрации ТИ)*/}
                {/*дата окончания периода, по умолчанию текущая дата (для даты регистрации ТИ)*/}
                <DateRange
                    dateOnly={true}
                    valueStartDate={filterValues.start_date}
                    valueEndDate={filterValues.end_date}
                    handleDate={(value) => onChange(value, 'start_date')}
                    handleEndDate={(value) => onChange(value, 'end_date')}
                    isDisabledHandler={isDisabledHandler}
                    renderError={() =>
                        new Date(filterValues.start_date).setHours(0,0,0,0) >= new Date(filterValues.end_date).setHours(0,0,0,0)
                        || !filterValues.start_date
                        || !filterValues.end_date
                    }
                    errorMessage={() =>
                        (!filterValues.start_date || !filterValues.end_date)
                            ? 'Выберите период'
                            : 'Выбранный период менее 1 дня'
                    }
                />

                {/*тип ТИ, необязательное, выбор из справочника*/}
                <SelectTransportIncidentsTypes
                    selected={filterValues.type || {}}
                    onChange={handleChangeIncidentTypes}
                    label="Тип"
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                {/*уровень угрозы, необязательное, выбор из справочника*/}
                <SelectThreadLevel
                    selected={filterValues?.threat_level}
                    onChange={(value) => onChange(value, 'threat_level')}
                />
                {/*статус ТИ, необязательное, выбор из справочника*/}
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={filterValues.status || null}
                    // multiple
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={statuses}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={autoCompleteChange('status')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.STATUS}
                        />
                    )}
                />

                {/*способ создания ТИ, необязательное, выбор из справочника*/}
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={filterValues.type_creation || null}
                    // multiple
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={typeCreationList}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={autoCompleteChange('type_creation')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Способ создания"
                        />
                    )}
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filters;
