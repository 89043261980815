import colorExtend from 'helpers/mapHelper/colorExtend';

const LegendsList = ({ category }) => {

    const getTitle = (key) => {
        switch (key) {
            case 'status': return 'Статусы состояний';
            case 'type': return 'Статусы типов';
            default: return '';
        }
    };

    const renderList = (keys) => {
        const res = keys?.reduce((r, d) => ({
            ...r,
            ...category[d],
        }), {});

        return (
            <div className="item">
                <div className="title">
                    {keys?.map((state) => getTitle(state))}
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    {Object.keys(res).map((name) => (
                        <div
                            key={name}
                            className="flex"
                            style={{
                                width: '200px',
                                alignItems: 'start',
                            }}
                        >
                            <div
                                className="image"
                                style={{
                                    height: 'auto',
                                    width: 'auto',
                                    alignItems: 'top',
                                }}
                            >
                                <i
                                    className="far fa-traffic-light-stop"
                                    style={{
                                        fontSize: '1rem',
                                        color: colorExtend(res?.[name] || '')
                                    }}
                                />
                            </div>

                            <div className="small">
                                - {name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="sidebar-tp-legend">
            {renderList(['status', 'state'])}
            {renderList(['type'])}

            {/*{Object.keys(category)?.map((keyGroup) => {*/}
            {/*    return (*/}
            {/*        <div key={keyGroup} className="item">*/}
            {/*            <div className="title">*/}
            {/*                {getTitle(keyGroup)}*/}
            {/*            </div>*/}

            {/*            {Object.keys(category[keyGroup]).map((key) => (*/}
            {/*                <div key={key} className="flex">*/}
            {/*                    <div*/}
            {/*                        className="image"*/}
            {/*                        style={{*/}
            {/*                            height: 'auto',*/}
            {/*                            width: 'auto',*/}
            {/*                        }}*/}
            {/*                    >*/}
            {/*                        <i*/}
            {/*                            className="far fa-traffic-light-stop"*/}
            {/*                            style={{*/}
            {/*                                fontSize: '1rem',*/}
            {/*                                color: category?.[keyGroup]?.[key] || ''*/}
            {/*                            }}*/}
            {/*                        />*/}
            {/*                    </div>*/}

            {/*                    <div className="small">*/}
            {/*                        - {key}*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            ))}*/}

            {/*        </div>*/}
            {/*    );*/}
            {/*})}*/}
        </div>
    );
};

export default LegendsList;
