import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import {
    Checkbox,
    FormControlLabel,
    Grid,
    List,
} from '@mui/material';

import {
    clearComplexes,
    clearComplexesTree,
    loadComplexes,
    loadComplexesTree,
    loadComplexReport,
    loadComplexStatuses,
    loadIncorrectComplexReport,
} from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import messages from 'helpers/constants/messages';
import { useStoreFromSelector } from 'helpers/hooks';
import {
    filterFunc,
    findElement,
    getExpandArray,
    parseParamsFromUrl,
    stringifyParamsForUrl,
} from 'helpers/doris.control/helper';
import buttons from 'helpers/constants/buttons';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import Loading from 'components/common/Loading';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import ReportBlock from 'components/common/Report/ReportBlock';
import GetReport from 'components/common/DorisControl/FiltersCPVF/getReport';
import { LSContainer } from 'components/common/List';

import { FilterVFComplex } from './FilterVFComplex';
import BasicDataForm from './PassportPage/BasicData/BasicDataForm';
import Item from './Item';
import PassportTabs from './PassportPage/PassportTabs';
import TreePage from './Tree';

const VFComplex = () => {
    const dispatch = useDispatch();
    const scrollRef = useRef(null);

    // список комплексов
    const complexes = useSelector(dorisControlSelectors.complexes);
    const loading_complexes = useSelector(dorisControlSelectors.loading_complexes);

    // комплексы в виде дерева
    const complexesTree = useSelector(dorisControlSelectors.complexesTree);
    const loadingComplexesTree = useSelector(dorisControlSelectors?.loadingComplexesTree);

    const reportTypeList  = [
        { key: 11, name: 'Отчет о состоянии комплексов' },
        { key: 12, name: 'Отчет о неисправных комплексах' }
    ];

    const history = useHistory();
    const location = useLocation();

    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;

    const [showDeletedComplexes, setShowDeletedComplexes] = useState(false);
    const [focus, setFocus] = useState(null);


    const [params, setParams] = useState({
        page: 1,
        limit: storageLimit,
        filter: {
            simple: 1,
        },
    });

    const [openModal, setOpenModal] = useState(false);
    const [currentComplex, setCurrentComplex] = useState(null);

    const [showTree, setShowTree] = useState(!!localStorage?.getItem('is_tree_complex'));
    // раскрыть пункты в дереве
    const [expanded, setExpanded] = useState([]);
    // выбранный пункт
    const [selected, setSelected] = useState(null);

    const getDeletedComplexes = (value) => {
        setShowDeletedComplexes(value);
        setCurrentComplex(null);
    };

    // подгружаем списки
    const complexStatuses = useStoreFromSelector(loadComplexStatuses, dorisControlSelectors.doris_complex_statuses);
    const complexStatusesObj = useMemo(() => complexStatuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [complexStatuses]);

    // превращем params в search и добавляем в урл
    const stringifyUrlFunc = (param = {}) => {
        // const stringifyUrl = (queryString.stringify(filterFunc(param), { arrayFormat: 'bracket' }));
        history.replace({ ...location, search: stringifyParamsForUrl(param) });
    };

    const handleClick = (item) => {
        const { page, limit } = params;
        // сперва проверяем мы кликнули по тому же комплексу в списке? тогда просто его удаляем из урла
        setFocus(item?.id);
        if (item.id === currentComplex?.id) {
            stringifyUrlFunc({ page, limit });
        } else {
            stringifyUrlFunc({ page, limit, complex_id: item.id });
        }
    };

    // при первой загрузке проверяем параметры урла, и если есть - устанавливаем их в локальный стейт
    useEffect(()=> {
        const { page, limit } = parseParamsFromUrl(location.search);
        if (page || limit) {
            const filterParams = filterFunc({ page: Number(page) || 1, limit: Number(limit) || storageLimit });
            setParams((prev)=> ({
                ...prev,
                ...filterParams
            }));
        }
    }, []);


    useEffect(() => {
        // в случае если мы перешли с урла или обновился список
        // нам нужно обновить сам currentComplex новыми данными
        const { complex_id } = parseParamsFromUrl(location.search);

        if (showTree && complexesTree?.length > 0) {

            const {
                complex = null,
                patch = [],
            } = complex_id
                ? findElement(complex_id, complexesTree)
                : {};

            setCurrentComplex(complex);

            if (patch.length === 3) {
                setExpanded(getExpandArray(patch.slice(0, 2)));
                setSelected(patch?.join('_') || null);
            }
        } else if (!showTree && complexes?.data?.length > 0) {
            const complex = complex_id ? complexes.data?.find(item => item.id === Number(complex_id)) : null;
            setCurrentComplex(complex);
        }
    },[complexes?.data, complexesTree, location.search, showTree]);

    const requestProps = useCallback(() => {
        return ({
            ...removeEmptyFields(params.filter),
            isDelete: showDeletedComplexes
                ? 1
                : 0,

        });
    }, [params.filter, showDeletedComplexes]);

    // загрузка списка
    useEffect(() => {
        if (showTree) {
            dispatch(loadComplexesTree(requestProps()));
            dispatch(clearComplexes());
        } else {
            dispatch(loadComplexes(
                params.page,
                params.limit,
                requestProps()
            ));
            dispatch(clearComplexesTree());
        }

    }, [params.page, params.limit, dispatch, showTree, requestProps]);

    useEffect(() => () => {
        dispatch(clearComplexes());
        dispatch(clearComplexesTree());
    }, []);

    const handleAdded = () => {
        if (showTree) {
            dispatch(loadComplexesTree(requestProps()));
            dispatch(clearComplexes());
        } else {
            dispatch(loadComplexes(
                params.page,
                params.limit,
                requestProps()
            ));
            dispatch(clearComplexesTree());
        }
        stringifyUrlFunc({ page: params.page, limit: params.limit });
        setSelected(null);
        scrollTop();
    };

    const handleEdited = () => {
        if (showTree) {
            // props.is_tree = true;
            dispatch(loadComplexesTree(requestProps()));
            dispatch(clearComplexes());
        } else {
            dispatch(loadComplexes(
                params.page,
                params.limit,
                requestProps()
            ));
            dispatch(clearComplexesTree());
        }
    };

    const handleDeleted = () => {
        const page = complexes?.data?.length === 1 && complexes?.meta?.last_page > 1
            ? params.page - 1
            : params.page;

        if (showTree) {
            dispatch(loadComplexesTree(requestProps()));
            dispatch(clearComplexes());
        } else {
            dispatch(loadComplexes(
                page,
                params.limit,
                requestProps()
            ));
            dispatch(clearComplexesTree());
        }

        // меняем урл чтобы сбросить выбранный комплекс
        stringifyUrlFunc({ page: params.page, limit: params.limit });
        scrollTop();
        setSelected(null);
    };

    const scrollTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    const changePage = (page, limit) => {
        setParams({
            ...params,
            page,
            limit
        });
        stringifyUrlFunc({ page, limit });
        scrollTop();
    };

    const itemTemplate = (item, propsList = []) => {
        const selectedItem = item?.id === currentComplex?.id;
        const props = propsList?.reduce((r, key) => ({ ...r, [key]: true }), {});

        return (
            <Item
                key={item?.id}
                selectedItem={selectedItem}
                onClickItem={() => handleClick(item)}
                item={item}
                complexStatusesObj={complexStatusesObj}
                // status={complex_statuses}
                readOnly={showDeletedComplexes || item.external_id}
                currentComplex={currentComplex}
                onDeleted={handleDeleted}
                focus={focus}
                {...props}
            />
        );
    };

    const renderListHeader = (complexList = complexes?.data) => {
        return <>
            {currentComplex
                ? <List className="list" disablePadding={true}>
                    {complexList?.map((item) => itemTemplate(item))}
                </List>
                : <LSContainer
                    headers={[
                        { title: '', width: '60px' },
                        { title: '', width: '60px' },
                        { title: titles.NAME, width: 'calc(50% - 120px)' },
                        { title: 'Серийный №', width: '15%' },
                        { title: titles.ADDRESS, width: '25%' },
                        { title: '', isActions: true }
                    ]}
                >
                    {complexList?.map((item) => itemTemplate(item))}
                </LSContainer>
            }
        </>;
    };

    const page = () => {
        // дерево
        if (showTree) return (
            <TreePage
                list={complexesTree}
                expanded={expanded}
                selected={selected}
                onNodeSelect={(e, id) => {
                    const idList = id.split('_');
                    const newExpandArr = idList?.slice(0, 2)?.reduce((r, i) => i ? [...r, i] : r, []);

                    // элемент уже открыт (1,2 уровень)
                    if (idList?.length <= 2 && expanded.includes(newExpandArr.join('_'))) {
                        setExpanded(getExpandArray(newExpandArr.slice(0, newExpandArr.length - 1 || 0)));
                        // setSelected(null);
                        // setCurrentComplex(null);
                    } else {
                        setExpanded(getExpandArray(newExpandArr));

                        // кликнули по комплексу
                        if (idList.length === 3) {
                            setSelected(selected === id ? null : id);
                        }
                    }
                }}
                itemComponent={renderListHeader}
            />
        );

        // список
        return renderListHeader();
    };

    const renderContent = (contentRef) => {
        const blockWidth = contentRef?.clientWidth;

        return (
            <>
                {(loading_complexes || loadingComplexesTree) && <Loading circular={true}/>}
                {openModal && (
                    <BasicDataForm
                        isOpen={openModal}
                        onClose={() => setOpenModal(false)}
                        // произошло добавление
                        onAdded={handleAdded}
                    />
                )}

                {complexes?.data?.length > 0
                || complexesTree?.length > 0
                    ? <div
                        style={{ height: '100%' }}
                    >
                        <Grid container style={{ height: '100%' }}>
                            <Grid item xs={ currentComplex ? 3 : 12 } style={{ height: '100%' }}>

                                {currentComplex
                                    ? (
                                        <Scrollbars style={{ height: '100%' }} ref={scrollRef}>
                                            {page()}
                                        </Scrollbars>
                                    )
                                    : (
                                        <>
                                            {page()}
                                        </>
                                    )
                                }
                            </Grid>

                            {currentComplex && (
                                <Grid item xs={9}>
                                    <PassportTabs
                                        // params={params}
                                        // id={currentComplex?.id}
                                        readOnly={showDeletedComplexes || currentComplex.external_id}
                                        item={currentComplex}

                                        // произошло редактирование
                                        onEdited={handleEdited}
                                        onDeleted={handleDeleted}
                                        // переход на гк
                                        isButtonToGk
                                    />
                                </Grid>
                            )}
                        </Grid>

                    </div>
                    : (!loading_complexes && !loadingComplexesTree) && <div>{messages.DATA_IS_NOT_FOUND}</div>
                }
            </>
        );
    };

    const handleDownload = (formats, type) => {
        type === 11
            ? dispatch(loadComplexReport({
                report: type,
                ...params.filter,
                formats
            }))
            : dispatch(loadIncorrectComplexReport({
                report: type,
                ...params.filter,
                formats
            }));
    };

    return (
        <PageLayout
            header="Комплексы фото-видео фиксации"
            filters={
                <FilterVFComplex
                    setParams={(newFilter) => {
                        setParams((prev) => ({
                            ...prev,
                            filter: {
                                simple: 1,
                                ...newFilter,
                            },
                            page: 1
                        }));
                        // меняем урл, чтобы сбросить выбранный комплекс
                        stringifyUrlFunc({ page: 1, limit: params.limit });
                    }}
                    statuses={complexStatuses}
                    setOpenModal={setOpenModal}
                />
            }
            actionPanel={<>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showTree}
                            onChange={(event) => {
                                const value = event?.target?.checked || false;

                                setSelected(null);
                                setCurrentComplex(null);
                                setExpanded([]);

                                setShowTree(value);
                                if (value) {
                                    localStorage?.setItem('is_tree_complex', 'true');
                                } else {
                                    localStorage?.removeItem('is_tree_complex');
                                }
                            }}
                            color="primary"
                        />
                    }
                    label="Показать список КФВФ в виде дерева"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showDeletedComplexes}
                            onChange={(event) => {
                                const value = event?.target?.checked || false;
                                setSelected(null);
                                setCurrentComplex(null);
                                setExpanded([]);
                                getDeletedComplexes(value);
                            }}
                            color="primary"
                        />
                    }
                    label={buttons.SHOW_DELETED}
                />
            </>}
            informPanelProps={{
                buttons: (
                    <>
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                        <ReportBlock
                            customComponents={(onclose) => <GetReport
                                handleDownload={(formats, selectedType) => {
                                    handleDownload(formats, selectedType);
                                    onclose();
                                }}
                                typeList={reportTypeList} />
                            }
                        />
                    </>),
                total: complexes?.meta?.total
            }}
            paginationProps={{
                loadList: changePage,
                list: complexes?.meta || {},
                limit: Number(params.limit),
                setLimit: (limit) => setParams({ ...params, limit }),
            }}
            content={renderContent}
            customStyles={{ 
                overflowY: currentComplex ? 'hidden' : 'scroll',
                ...(currentComplex && { padding: 0 })
            }}
        />
    );
};

export default VFComplex;
