import {
    TextField,
    Autocomplete,
} from '@mui/material';
import titles from 'helpers/constants/titles';
import AutocompleteCompanies from 'components/common/Autocomplete/Companies';


const Form = ({
    data,
    vehiclesCategories,
    onChange,
    validation,
}) => (
    <form
        className="modal__form"
        onSubmit={(e) => {
            e.preventDefault();
        }}
    >
        <AutocompleteCompanies
            onChange={(values) => {
                onChange({
                    'organization_ids': values,
                });
            }}
            selected={data?.organization_ids || []}
            multiple
            error={validation.isKey('organization_ids')}
            helperText={validation.get('organization_ids')}
            filter={{ withDeleted: 1 }}
            selectDeletedСompanies
        />

        <div className="block">
            <Autocomplete
                noOptionsText={titles.NO_RESULTS}
                multiple
                value={data?.category_ids || []}
                // getOptionSelected={(option, value) => option?.name === value?.name}
                options={vehiclesCategories}
                filterSelectedOptions={true}
                getOptionLabel={(option) => option?.name || ''}
                onChange={(e, value) => onChange({ category_ids: value })}
                size="small"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label={titles.TYPE_OF_VEHICLE}
                        error={validation.isKey('category_ids')}
                        helperText={validation.get('category_ids')}
                    />)}
            />
        </div>

        <TextField
            size="small"
            fullWidth
            className="block"
            label="Радиус поиска (км)"
            id="outlined-margin-dense"
            value={data?.radius || ''}
            onChange={(e) => onChange({ [e.target.name]: e.target.value })}
            variant="outlined"
            name="radius"
            type="number"
            error={validation.isKey('radius')}
            helperText={validation.get('radius')}
            // required
        />
    </form>
);

export default Form;
