import React  from 'react';
import Filter from './Filter';

const RoadWorks = () => {
    return (
        <>
            <h1>Отчет по участкам</h1>
            <Filter/>
        </>
    );
};

export default RoadWorks;
