import RoadWorks from '../../pages/Reports/RoadWorks/Works';
import RoadWorksOrders from '../../pages/Reports/RoadWorks/Orders';
import Vehicles from '../../pages/Reports/RoadWorks/Vehicles';
import DangersArea from '../../pages/Reports/RoadWorks/DangersArea';
import ConsolidateReport from '../../pages/Reports/RoadWorks/ConsolidateReport';
import DepartureKDM from '../../pages/Reports/RoadWorks/DepartureKDM';
import PlanFact from '../../pages/Reports/RoadWorks/PlanFact';
import VehicleMileage from '../../pages/Reports/RoadWorks/VehicleMileage';
import SheetsAssessmentRoad from '../../pages/Reports/RoadWorks/SheetsAssessmentRoad';

const routes = [
    {
        path: '/reports/roadworks/works',
        component: RoadWorks,
        exact: true
    },
    {
        path: '/reports/roadworks/orders',
        component: RoadWorksOrders,
        exact: true
    },
    {
        path: '/reports/roadworks/vehicles',
        component: Vehicles,
        exact: true
    },
    {
        path: '/reports/roadworks/heat-map',
        component: DangersArea,
        exact: true
    },
    {
        path: '/reports/roadworks/consolidate-report',
        component: ConsolidateReport,
        exact: true
    },
    {
        path: '/reports/roadworks/kdm',
        component: DepartureKDM,
        exact: true
    },
    {
        path: '/reports/roadworks/plan-fact',
        component: PlanFact,
        exact: true
    },
    {
        path: '/reports/roadworks/vehicle-mileage',
        component: VehicleMileage,
        exact: true
    },

    {
        path: '/reports/roadworks/sheets-assessment-road',
        component: SheetsAssessmentRoad,
        exact: true
    },
];

export default routes;