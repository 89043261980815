import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadRepairObjectIndicators } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import PageLayout from 'components/layout/PageLayout';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer, LSHeaderColumn, LSHeaderItem } from 'components/common/ListStyle';

import Item from './Item';
import ModalForm from './ModalForm';
import Filter from './Filter';

const RepairObjectIndicators = () => {
    const dispatch = useDispatch();

    const data = useSelector(roadworksSelectors.repairObjectIndicatorsData);
    const meta = useSelector(roadworksSelectors.repairObjectIndicatorsMeta);
    const loading = useSelector(roadworksSelectors.loadingRepairObjectIndicators);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, query: {} });
    const [openAddModal, setOpenAddModal] = useState(false);

    const reloadList = (isDelete) => {
        const page = isDelete && data?.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadRepairObjectIndicators(params.page, limit, params.query));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return (
            data?.length > 0
                ? (
                    <LSContainer>
                        <LSHeaderItem fullWidth>
                            <LSHeaderColumn width="20%">{titles.NAME}</LSHeaderColumn>
                            <LSHeaderColumn width="20%">Единица измерения</LSHeaderColumn>
                            <LSHeaderColumn align="right" isActions>Действия</LSHeaderColumn>
                        </LSHeaderItem>

                        {data?.map((item) => (
                            <Item
                                key={item.id}
                                item={item}
                                reloadList={reloadList}
                            />
                        ))}
                    </LSContainer>
                )
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <>
            <PageLayout
                header="Показатели объектов ремонта"
                filters={<Filter setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <FormButtons 
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenAddModal(true)
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />
            {openAddModal && (
                <ModalForm
                    open={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default RepairObjectIndicators;
