import { FormGroup, TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import useLoadByLatLon from 'helpers/hooks/Location/useLoadByLatLon';

// координаты lat lon
const LatLonCoordinates = ({
    lat = '',
    lon = '',
    onChange = (props) => {},
    required,
    disabled = false,
    // изменяем адрес от lat lon
    isChangeAddressByLatLon = false,
}) => {
    const validation = useValidation(false);

    const loadByLatLon = useLoadByLatLon((props) => {
        onChange(props);
        validation.deleteKeys(Object.keys(props));
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({
            lat,
            lon,
            [name]: value,
        });

        if (isChangeAddressByLatLon) {
            const dada = {
                lat,
                lon,
                [name]: value,
            };
            loadByLatLon.load(dada.lat, dada.lon);
        }

        validation.deleteKey(name);
    };

    return (
        <FormGroup className="block">
            <h2>{titles.COORDINATES}</h2>

            <div className="row">
                <TextField
                    className="row__item"
                    label={titles.LAT}
                    variant="outlined"
                    size="small"
                    value={lat}
                    name="lat"
                    onChange={handleChange}
                    error={validation.isKey('lat') || (required && !lat)}
                    helperText={validation.get('lat')}
                    type="number"
                    required={required}
                    disabled={disabled}
                />
                <TextField
                    className="row__item"
                    label={titles.LON}
                    variant="outlined"
                    size="small"
                    value={lon}
                    name="lon"
                    onChange={handleChange}
                    error={validation.isKey('lon') || (required && !lon)}
                    helperText={validation.get('lon')}
                    type="number"
                    required={required}
                    disabled={disabled}
                />
            </div>
        </FormGroup>
    );
};

export default LatLonCoordinates;
