import { useDispatch } from 'react-redux';
import { fullDateTimeWithoutTimeZone } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect';
import { loadComplexes } from 'redux/DorisControl/actions';
import type { ComplexItemType, ItemProps } from '../types';

interface ComplexListModalProps {
    isOpen: boolean
    onClose: () => void
    onChange: (arg: ComplexItemType[]) => void
    item?: ItemProps
    selected: ComplexItemType[]
}

const ComplexListModal = ({ isOpen, onClose, item, selected, onChange }: ComplexListModalProps) => {
    const dispatch = useDispatch();

    const getComplex = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query: name } = params;
        const currentParams = {
            name,
            contract_id: item?.id ? [item.id, ''] : [''],
            simple: 1,
        };
        dispatch(loadComplexes(page, limit, currentParams));
    };

    const handleAccept = (value: ComplexItemType[]) => {
        onChange(value);
        onClose();
    };

    return (
        <UniversalSelect
            multiple
            fetchList={getComplex}
            storeName="dorisControl"
            storeNameProps="complexes"
            keyProp="id"
            withSearch
            isSelected
            medium
            selected={selected}
            renderProps={(el) => <div>
                <b>Название: </b>{el.name},<br/>
                <b>Серийный номер: </b>{el.serial_number},<br/>
                <b>Адрес: </b>{el.address_text},<br/>
                <b>Дата и время создания: </b>{fullDateTimeWithoutTimeZone(el.created_at)}, <br/>
            </div>}
            isOpen={isOpen}
            onClose={onClose}
            onAccept={handleAccept}
            noPadding
            title={titles.SELECT_COMPLEX}
            selectAll
        />
    );
};

export default ComplexListModal;