import React, { useMemo } from 'react';
import { Bar as GBar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// const plugin = {
//     id: 'custom_canvas_background_color',
//     beforeDraw: (chart, args, options) => {
//
//         var margin = 46;
//         var margin_end = 5;
//         console.log(chart.scales);
//
//         if (chart?.scales['x']?.bottom && chart?.scales['x']?.top /*chart.config.options.title.text == 'chart_one'*/) {
//             var diff = chart.scales['x'].bottom - chart.scales['x'].top;
//             // var diff = chart.scales['x'].end - chart.scales['x'].start;
//             var val = 10 - chart.scales['x'].start;
//             const ctx = chart.ctx;
//             const x = (val / diff) * (chart.width - (margin + margin_end)) + margin;
//             const topY = chart.scales['y'].top;
//             const bottomY = chart.scales['y'].bottom;
//             ctx.save();
//             ctx.beginPath();
//             ctx.moveTo(x, topY);
//             ctx.lineTo(x, bottomY);
//             ctx.lineWidth = 2;
//             ctx.strokeStyle = '#ff0000';
//             ctx.stroke();
//         }
//
//         //
//         //
//         // console.log(chart);
//         // console.log(args);
//         // console.log(options);
//         // console.log('');
//         //
//         // const { ctx } = chart;
//         // ctx.save();
//         // ctx.globalCompositeOperation = 'destination-over';
//         // ctx.fillStyle = options.color;
//         // ctx.fillRect(0, 0, chart.width, chart.height);
//         // ctx.restore();
//     },
//     defaults: {
//         color: 'lightGreen'
//     }
// };

// линейный график
const Bar = ({
    data = [],
    legendPosition = 'bottom',
    horizontal = false,
}) => {

    // useEffect(() => {
    //     Chart.register(plugin);
    // }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: legendPosition,
            },
            datalabels: {
                color: function(context) {
                    return context.dataset.backgroundColor;
                    // return context.active ? 'white' : context.dataset.backgroundColor;
                },
                display: function(context) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    size: '16',
                    weight: 'bold'
                },
                // formatter: Math.round,
                align: 'start',
                anchor: 'end',
                offset: -24,
                // clamp: true
            },
            // title: {
            //     display: true, text: 'Chart.js Line Chart',
            // },
        },
        layout: {
            padding: {
                top: 20
            }
        },
        // scales: {
        //     yAxis: {
        //         min: 0,
        //         max: 10,
        //     },
        // }
    };

    if (horizontal) {
        options.indexAxis = 'y';
    }

    const dataG = useMemo(() => {
        const labels = data[0]?.items?.map(({ label = '' }) => label) || [];

        const datasets = data.map(({ items = [], color, name }) => {
            return {
                label: name,
                // borderColor: color,
                backgroundColor: color,
                data: items.map(({ value }) => value),
                maxBarThickness: 80
            };
        });

        return {
            labels,
            datasets
        };
    }, [data]);

    return <GBar
        options={options}
        data={dataG}
        plugins={[ChartDataLabels]}
        style={{ maxHeight: '300px' }}
    />;
};

export default Bar;
