import { getTimeUnitValue, getDateWithDuration, setDateTime } from '../../../../helpers/date.config';


export const filterByDate = (filteredDate) => (item) => {
    return item?.find(({ date }) => date === filteredDate) || {};
};

// отфильтровать по дате
export const getByDate = (
    data,
    filteredDate
) => {
    const filter = filterByDate(filteredDate);

    return Array.isArray(data)
        ? data?.map(item => ({
            ...item,
            datas: filter(item?.datas) // item?.datas?.find(({ date }) => date === filteredDate) || {}
        }))
        : {
            ...data,
            datas: filter(data?.datas) // data?.datas?.find(({ date }) => date === filteredDate) || {},
        };
};

// отфильтровать по региону
export const filterByRegion = (region) => (item) => {
    return region && Object.keys(region).length > 0
        ? item?.data?.find(({ id, name }) => id === region?.id || name === region?.name)
        : item?.total;
};

// отфильтровать по региону
export const getByRegion = (
    data, // данные {} или []
    region // регион {}
) => {
    const filter = filterByRegion(region);

    return {
        ...data,
        datas: Array.isArray(data?.datas)
            ? data?.datas?.map(item => filter(item))
            : filter(data?.datas)
    };
};

// получить массив списока годов от startYear до текущего
// asc - сортировать в порядке возрастания
export const getRangeYears = (startYear = 2021, asc = false) => {
    const currentYear = new Date().getUTCFullYear();
    const yearsList = Array(currentYear - startYear + 1)
        .fill('')
        .map((v, x) => currentYear - x);

    return asc ? yearsList.reverse() : yearsList;
};

// получить массив списока месяцев от начала года до текущего (для этого года)
// или от начала года до конца года (для другого года)
export const getRangeMonths = (year) => {
    const currentYear = getTimeUnitValue('year');
    // месяц цифрой, если текущий год, то до текущего месяца, иначе весь год
    const counttMonth = currentYear === year ? getTimeUnitValue('month') : 12; 
    // для какого года устанавливаем месяцы
    const currentDate = setDateTime('', { year, month: 1 });
    // месяц текстом
    const literalMonth= (index) => {
        const dateForMonth = getDateWithDuration({ months: +index }, currentDate);
        return getTimeUnitValue('month', dateForMonth, true); 
    };
   
    const monthList = [...Array(counttMonth)]
        .reduce((result, el, index)=> {
            result[index + 1] = literalMonth(index);
            return result;
        }, {});

    return monthList;
};