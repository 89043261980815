import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as transportPassengerActions from 'redux/TransportPassenger/actions';
import * as transportRoadActions from 'redux/TransportRoad/actions';
import * as transportSpecialActions from 'redux/TransportSpecial/actions';
import PageLayout from 'components/layout/PageLayout';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';

import Filter from './Filter';
import Item from './Item';
import ModalForm from './ModalForm';
import type { EgtsTransmitter } from './types';

type storeNameTypes = 'transportPassenger' | 'transportRoad' | 'transportSpecial';

interface TransmittersEgtsProps {
    storeName: storeNameTypes
}

const RepeatersTelemetry = ({ storeName }: TransmittersEgtsProps) => {
    const dispatch = useDispatch();

    const actionList: { [key: string]: any } = {
        transportPassenger: transportPassengerActions,
        transportRoad: transportRoadActions,
        transportSpecial: transportSpecialActions
    };
    const actions = actionList[storeName];

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit') || 25));
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openAddModal, setOpenAddModal] = useState(false);

    const initDefault = {
        data: [],
        meta: { last_page: 0, total: 0 }
    };

    const [loading, setLoading] = useState(false);
    const [currentData, setCurrentData] = useState(initDefault);

    const { data, meta } = currentData;

    const reloadList = (isDelete?: boolean) => {
        isDelete && data?.length === 1 && meta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(actions.loadEgtsTransmitters({ page: params.page, limit }, setLoading, setCurrentData));
    };

    useEffect(() => {
        dispatch(actions.loadEgtsTransmitters({ page: params.page, limit, ...params.data }, setLoading, setCurrentData));
    }, [actions, dispatch, limit, params.data, params.page]);

    return (
        <>
            <PageLayout
                header="Ретрансляторы"
                filters={<Filter setParams={setParams} />}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenAddModal(true)
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: meta?.total
                }}
                loading={loading}
                content={() => (
                    <>
                        {data?.length > 0
                            ? (
                                <LSContainer
                                    headers={[
                                        { title: 'Ретранслятор', width: '40%' },
                                        { title: 'Порт', width: '25%' },
                                        { title: 'IP', width: '25%' },
                                        { title: 'Действия', align: 'right', isActions: true },
                                    ]}
                                >
                                    {data?.map((item: EgtsTransmitter) => (
                                        <Item
                                            key={item.id}
                                            item={item}
                                            actions={actions}
                                            reloadList={reloadList}
                                        />
                                    ))}
                                </LSContainer>
                            )
                            : !loading && <NoData/>
                        }
                    </>
                )}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    // @ts-ignore
                    list: meta,
                    limit,
                    setLimit
                }}
            />

            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={() => setParams({ page: 1, data: {} })}
                    actions={actions}
                />
            )}
        </>
    );
};

export default RepeatersTelemetry;
