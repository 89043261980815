import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
    Collapse,
    IconButton,
    Link,
    List,
    Tooltip,
    ListItemText,
    ListItem,
    ListItemIcon
} from '@mui/material';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { loadIncidentOperations } from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import Loading from 'components/common/Loading';
import IncidentOperations from './IncidentOperations';
import Routes from './Routes';

const notInfo = messages.INFO_IS_NOT_FOUND;

const Info = ({ isOpen, item = {} }) => {
    const dispatch = useDispatch();
    const [isShowRoutes, setIsShowRoutes] = useState(false);

    const showRoutes = () => {
        setIsShowRoutes(!isShowRoutes);
    };

    const incidentOperations = useSelector(SituationalPlansSelectors.incidentOperations);
    const loadingIncidentOperations = useSelector(SituationalPlansSelectors.loadingIncidentOperations);

    useEffect(() => {
        if (!incidentOperations[item?.id]) {
            dispatch(loadIncidentOperations(item?.id));
        }
    }, [dispatch, incidentOperations, item?.id]);

    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            {item?.event?.name && <div><h3>{titles.EVENT}: </h3>{item?.event?.name}</div>}
            {item?.organization?.title && <div><h3>{titles.COMPANY}: </h3>{item?.organization?.title}</div>}
            {(item?.user?.first_name || item?.user?.last_name)
                && <div><h3>{titles.EMPLOYEES}: </h3>{item?.user?.first_name || ''} {item?.user?.last_name || ''}</div>
            }
            {item?.response_scenario?.name && <div><h3>{titles.RESPONSE_SCENARIO}: </h3>{item?.response_scenario?.name}</div>}
            {incidentOperations[item?.id]
                && <div>
                    <h3>{titles.OPERATIONS}: </h3>
                    {loadingIncidentOperations && <Loading linear={true}/>}
                    {incidentOperations[item?.id]?.length > 0
                        ? <IncidentOperations operations={incidentOperations[item?.id]}/>
                        : (!loadingIncidentOperations && notInfo)
                    }
                </div>
            }
            {item?.description && <div><h3>{titles.DESCRIPTION}: </h3><div dangerouslySetInnerHTML={{ __html: item?.description }}/></div>}
            {item?.vehicles?.length > 0 && (
                <>
                    <h3>Список ТС: </h3>
                    <List className="list">
                        {item?.vehicles
                            ?.map((el,i) =>
                                <ListItem className="list_item" key={el?.id}>
                                    <ListItemIcon>
                                        {i + 1}.
                                    </ListItemIcon>
                                    <ListItemText>
                                        Марка: {el?.brand_name}, Модель: {el?.model_name}, ГРЗ: {el?.number}
                                    </ListItemText>
                                </ListItem>
                            )
                        }
                    </List>
                </>
            )}
            {item?.attachments?.length > 0
            && (
                <>
                    <h3>{titles.DOCUMENTS}:</h3>
                    <List className="list">
                        {item?.attachments
                            ?.map((el,i) =>
                                <ListItem key={i} className="list_item">
                                    <ListItemIcon>
                                        {i + 1}.
                                    </ListItemIcon>
                                    <ListItemText>
                                        { typeof el === 'object' && el !== null
                                            ? <Link href={el.public_link} target="_blank">{el.name}</Link>
                                            : <Tooltip title={titles.CLICK_TO_OPEN}>
                                                <IconButton size={'small'}>
                                                    <Link href={el} target="_blank">{titles.DOCUMENT}: <i className="fal fa-file-alt"/> </Link>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </ListItemText>
                                </ListItem>
                            )}

                    </List>
                </>
            )}
            {item?.route_list.length > 0 && (
                <div onClick={showRoutes}>
                    <h3>
                        Маршруты, попадающие в зону инцидента:
                        <IconButton size="small">
                            {isShowRoutes
                                ? <ExpandLess fontSize="inherit"/>
                                : <ExpandMore fontSize="inherit"/>
                            }
                        </IconButton>
                    </h3>

                    {isShowRoutes && <Routes item={item} isOpen={isShowRoutes}/>}
                </div>
            )}
        </Collapse>
    );
};

export default Info;
