import { config } from '../../config';

const apiVoteUrl = () => config.get('urls').apiVoteUrl;

const apiUrls = {
    getStatistic: () => `${apiVoteUrl()}/vote/statistics`,

};

export default apiUrls;
