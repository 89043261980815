import { useState } from 'react';
import { ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DragAndDrop from '../../../../common/DragAndDrop';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';

import SelectModal from './SelectModal';

const useStyles = makeStyles({
    item: {
        textAlign: 'left',
        marginLeft: '1rem',
        flex: 1,
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 13
    }
});

const SelectedCameras = ({ selected, onChange, sumCameras }) => {
    const styles = useStyles();

    const [openSelectModal, setOpenSelectModal] = useState(false);

    const listRender = (item, index) => (
        <ListItemText disableTypography className={styles.row}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <b>{index + 1}</b>
            </span>
            <span className={styles.item}>{item.name}</span>
        </ListItemText>
    );

    return (
        <>
            <h3>Список выбранных камер :</h3>

            {selected.length > 0 && (
                <DragAndDrop
                    list={selected}
                    onChange={onChange}
                    itemsRender={listRender}
                    withSelected
                />
            )}

            <FormButtons
                noPadding
                positionLeft
                noMarginLeft
                buttons={[selected.length > 0
                    ? {
                        ...buttonsTypes.change,
                        color: 'infoButton',
                        onClick: () => setOpenSelectModal(true)
                    }
                    : {
                        ...buttonsTypes.select,
                        name: 'Выбрать камеры',
                        size: 'small',
                        color: 'infoButton',
                        onClick: () => setOpenSelectModal(true)
                    }
                ]}
            />
            {openSelectModal
                && <SelectModal
                    isOpen={openSelectModal}
                    onClose={() => setOpenSelectModal(false)}
                    onChange={onChange}
                    selected={selected}
                    sumCameras={sumCameras}
                />
            }
        </>
    );
};

export default SelectedCameras;
