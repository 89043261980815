export const getInitialFields = (fields = [], complex_id) => {
    return fields.reduce((res, el) => {  
        if (el.field === 'complex_id') {
            return { ...res, complex_id };
        }
        if (el.type === 'array') {
            return { ...res, [el.field]: el.fields ? [getInitialFields(el.fields, complex_id)] : [] };
        }
        if (el.type === 'boolean') {
            return { ...res, [el.field]: false };
        } 
    
        return { ...res, [el.field]: '' };
    },{});
};