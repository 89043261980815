import React, { memo, useCallback } from 'react';
  
interface CalculateRoutesProps {
    routePoints: {
        interval: string;
        mileage: number | null;
    }[] | null;
}

const CalculateRoutes = ({ routePoints }: CalculateRoutesProps) => {

    const calculateDuration = useCallback(() => {
        if (!routePoints) return 0;

        return routePoints.reduce((acc: number, item: { interval: string }) => {
            if (typeof item.interval === 'string') {
                const [hour, min] = item.interval.split(':');
                return acc + (+hour * 60) + +min;
            }
            return acc;
        }, 0);
    }, [routePoints]);

    const calculateLength = useCallback(() => {
        if (!routePoints) return 0;

        return routePoints.reduce((acc: number, item: { mileage: any }) => {
            return acc + Number(item.mileage || 0);
        }, 0).toFixed(2);
    }, [routePoints]);

    return (
        <div className="form">
            <div className="block">
                <p>
                    <b>Общий пробег:</b> {calculateLength()} км
                </p>
                <p>
                    <b>Продолжительность маршрута:</b>  {calculateDuration()} мин
                </p>
            </div>
        </div>
    );
};

export default memo(CalculateRoutes);