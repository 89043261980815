import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import {
    LSContainer,
    LSContentColumn,
    LSContentItem
} from 'components/common/List';
import { metroSelectors } from 'modules/Metro';
import { loadStationList } from 'modules/Metro/redux/actions';

const Stations = () => {
    const dispatch = useDispatch();

    const data = useSelector(metroSelectors.stationListData);
    const meta = useSelector(metroSelectors.stationListMeta);
    const loading = useSelector(metroSelectors.loadingStationList);

    const [params, setParams] = useState({ page: 1, limit: 25 });

    useEffect(() => {
        const { page, limit } = params;
        dispatch(loadStationList(page, limit));
    }, [dispatch, params]);

    const renderContent = () => (
        data?.length > 0
            ? <LSContainer
                headers={[
                    { title: titles.NAME, width: '20%' },
                ]}
            >
                {data.map((item : { id: number; name: string; }) => (
                    <LSContentItem key={item?.id}>
                        <LSContentColumn>
                            {item?.name || messages.NO_VALUE}
                        </LSContentColumn>
                    </LSContentItem>
                ))}
            </LSContainer>
            : (!loading && <NoData/>)
    );

    return (
        <PageLayout
            header="Список станций"
            informPanelProps={{
                total: meta?.total
            }}
            loading={loading}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setParams(old => ({ ...old, page })),
                list: meta,
                limit: params.limit,
                setLimit: (limit) => setParams(old => ({ ...old, limit }))
            }}
        />
    );
};

export default Stations;
