import * as types from './types';

export const loadCompanies = (page = 1, limit = 25, query, callback) => ({
    type: types.LOAD_COMPANIES,
    payload: {
        page,
        limit,
        query,
    },
    callback
});

export const getCompaniesForAllRoles = (page = 1, limit = 25, query) => ({
    type: types.LOAD_COMPANIES_FOR_ALL_ROLES,
    payload: {
        page,
        limit,
        query,
    },
});

export const loadingCompanies = (bool = false) => ({
    type: types.LOADING_COMPANIES,
    payload: bool,
});

export const loadedCompanies = (data) => ({
    type: types.LOADED_COMPANIES,
    payload: data,
});

export const getStandardizedCompaniesList = (page = 1, limit = 25, query) => ({
    type: types.GET_STANDARDIZED_COMPANIES_LIST,
    payload: {
        page,
        limit,
        query,
    },
});

export const clearStandardizedCompaniesList = () => ({
    type: types.CLEAR_STANDARDIZED_COMPANIES_LIST,
});


export const gotStandardizedCompaniesList = (data) => ({
    type: types.GOT_STANDARDIZED_COMPANIES_LIST,
    payload: data,
});

export const createCompany = (data, callback) => ({
    type: types.CREATE_COMPANY,
    payload: data,
    callback
});

export const editCompany = (id, data, callback) => ({
    type: types.EDIT_COMPANY,
    payload: { id, data },
    callback
});

export const deleteCompany = (id, callback) => ({
    type: types.DELETE_COMPANY,
    payload: id,
    callback
});

export const loadCurrentCompany = (id, callback) => ({
    type: types.LOAD_CURRENT_COMPANY,
    payload: id,
    callback
});

export const loadedCurrentCompany = (data) => ({
    type: types.LOADED_CURRENT_COMPANY,
    payload: data,
});

export const loadOrganization = (ids, filter = {}) => ({
    type: types.LOAD_ORGANIZATION,
    payload: { ids, filter }
});

export const loadedOrganization = (data) => ({
    type: types.LOADED_ORGANIZATION,
    payload: data
});

export const loadingOrganization = (bool = false) => ({
    type: types.LOADING_ORGANIZATION,
    payload: bool
});

export const downloadOrganizationsReport = (query) => ({
    type: types.DOWNLOAD_ORGANIZATIONS_REPORT,
    payload: query
});

export const loadCurrentFiliationList = (page = 1, limit = 25, data) => ({
    type: types.LOAD_CURRENT_FILIATION_LIST,
    payload: { page, limit, data }
});

export const loadingCurrentFiliationList = (bool = false) => ({
    type: types.LOADING_CURRENT_FILIATION_LIST,
    payload: bool
});

export const loadedCurrentFiliationList = (data) => ({
    type: types.LOADED_CURRENT_FILIATION_LIST,
    payload: data
});

export const createFiliation = (data, callback = () => {}) => ({
    type: types.CREATE_FILIATION,
    payload: data,
    callback
});

export const editFiliation = (id, data, callback = () => {}) => ({
    type: types.EDIT_FILIATION,
    payload: { id, data },
    callback
});

export const deleteFiliation = (id, callback = () => {}) => ({
    type: types.DELETE_FILIATION,
    payload: id,
    callback
});

export const loadCompanyRoleList = (page = 1, limit = 25, data) => ({
    type: types.LOAD_COMPANY_ROLE_LIST,
    payload: { page, limit, ...data }
});

export const loadingCompanyRoleList = (bool = false) => ({
    type: types.LOADING_COMPANY_ROLE_LIST,
    payload: bool
});

export const loadedCompanyRoleList = (data) => ({
    type: types.LOADED_COMPANY_ROLE_LIST,
    payload: data
});

export const createCompanyRole = (data, callback = () => {}) => ({
    type: types.CREATE_COMPANY_ROLE,
    payload: data,
    callback
});

export const editCompanyRole = (id, data, callback = () => {}) => ({
    type: types.EDIT_COMPANY_ROLE,
    payload: { id, data },
    callback
});

export const deleteCompanyRole = (id, callback = () => {}) => ({
    type: types.DELETE_COMPANY_ROLE,
    payload: id,
    callback
});

export const createRolesContracts = (data, callback = () => {}) => ({
    type: types.CREATE_ROLES_CONTRACTS,
    payload: data,
    callback
});

export const changeRolesContracts = (data, callback = () => {}) => ({
    type: types.CHANGE_ROLES_CONTRACTS,
    payload: data,
    callback
});

export const deleteRolesContracts = (data, callback = () => {}) => ({
    type: types.DELETE_ROLES_CONTRACTS,
    payload: data,
    callback
});

export const loadShowcase = () => ({
    type: types.LOAD_SHOWCASE,
});
export const loadingShowcase = (bool = false) => ({
    type: types.LOADING_SHOWCASE,
    payload: bool
});
export const loadedShowcase = (data) => ({
    type: types.LOADED_SHOWCASE,
    payload: data
});
export const clearShowcase = () => ({
    type: types.LOADED_SHOWCASE,
    payload: []
});