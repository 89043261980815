import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../Modal';
import FormButtons, { buttonsTypes } from '../../FormButtons';
import * as actions from '../../../../redux/TransportPassenger/actions';
import { transportRoadSelectors } from '../../../../redux/TransportRoad';
import ShowMapContent from './ShowMapContent';

const ShowMap = () => {
    const dispatch = useDispatch();

    const historyOption = useSelector(transportRoadSelectors.historyOption);
    const { title } = historyOption;
    const [showMap, setShowMap] = useState(false);

    useEffect(() => {
        return () => {

            if (historyOption.dispose) {
                historyOption.dispose.map((action) => dispatch((action)));
            }
            dispatch(actions.setHistoryOption());
        };
    }, []);

    useEffect(() => {
        if (Object.keys(historyOption).length > 0) {
            setShowMap(true);
        }
    }, [historyOption]);

    const closeMap = () => {
        setShowMap(false);
        dispatch(actions.setHistoryOption());
    };

    return (
        <>
            {/* карта истории */}
            <Modal
                noPadding
                title={title || ''}
                fullWidth={true}
                small
                isOpen={showMap}
                onClose={closeMap}
                buttons={
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: closeMap,
                            },
                        ]}
                    />
                }
            >
                {showMap
                    ? <ShowMapContent
                        historyOption={historyOption}
                    />
                    : null
                }
            </Modal>
        </>
    );
};

export default ShowMap;
