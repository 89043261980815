import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import {
    createBrand,
    editBrand,
    loadVehicleCategories
} from 'redux/TransportRoad/actions';
import titles from 'helpers/constants/titles';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { useStoreProp, useValidation } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const BrandModal = ({ data, isNew, open, onClose, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    
    const vehiclesCategories = useStoreProp(loadVehicleCategories, 'transportRoad', 'vehicleCategories');

    const [formData, setFormData] = useState({ ...data });
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };
        
        dispatch(isNew
            ? createBrand(formData, callback)
            : editBrand(data.id, formData, callback)
        );
    };

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <FormInfoWrapper className="block" error={validation.isKey('name_ru')} helperText={validation.get('name_ru')}>
                    <TextField
                        value={formData.name_ru || ''}
                        name={'name_ru'}
                        onChange={handleChange}
                        label={'Название марки(Ru)'}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={validation.isKey('name_ru')}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper className="block" error={validation.isKey('name_en')} helperText={validation.get('name_en')}>
                    <TextField
                        value={formData.name_en || ''}
                        name={'name_en'}
                        onChange={handleChange}
                        label={'Название марки(En)'}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={validation.isKey('name_en')}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper className="block" error={validation.isKey('slug')} helperText={validation.get('slug')}>
                    <TextField
                        value={formData.slug || ''}
                        name={'slug'}
                        onChange={handleChange}
                        label={'Алиас'}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={validation.isKey('slug')}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper className="block" error={validation.isKey('category_id')} helperText={validation.get('category_id')}>
                    <FormControl
                        variant="outlined"
                        size="small"
                        error={validation.isKey('category_id')}
                        required={true}
                    >
                        <InputLabel>{titles.TYPE_OF_VEHICLE}</InputLabel>
                        <Select
                            label={titles.TYPE_OF_VEHICLE}
                            value={formData.category_id}
                            onChange={handleChange}
                            name={'category_id'}
                            required={true}
                        >
                            {vehiclesCategories?.length > 0
                                ? vehiclesCategories.map((el) =>
                                    <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                )
                                : <MenuItem disabled={true} value="">{messages.NO_DATA}</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </FormInfoWrapper>
            </form>
        </Modal>
    );
};

export default BrandModal;
