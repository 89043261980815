import React from 'react';
import {
    Card,
    CardContent,
    CardActions,
    Button,
    CardHeader,
    Typography,
} from '@mui/material';


const CardWidget = ({
    children,
    title = '',
    description = '',
    buttons = [],
}) => (
    <Card sx={{ marginBottom: '1.5rem' }} variant="outlined">
        <CardContent>
            <Typography variant="h5" component="div" style={{ marginBottom: '1rem' }}>
                {title}
            </Typography>

            {children}

            {/*<Typography gutterBottom variant="h5" component="div">*/}
            {/*    Lizard*/}
            {/*</Typography>*/}
            {/*<Typography variant="body2" color="text.secondary">*/}
            {/*    Lizards are a widespread group of squamate reptiles, with over 6,000*/}
            {/*    species, ranging across all continents except Antarctica*/}
            {/*</Typography>*/}
            <Typography variant="body2" color="text.secondary">
                {description}
            </Typography>
        </CardContent>

        <CardActions>
            {buttons.map((item, index) => (
                <Button size="small" key={index} {...item}>{item.name}</Button>
            ))}
        </CardActions>
    </Card>
);

export default CardWidget;
