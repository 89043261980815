/**
 * Sagas
 * **/
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';


import { showMessage } from 'redux/Message/actions';
import messageTypes from 'redux/Message/messagetypes';
import { setValidation } from 'redux/Validation/actions';
import { setMapLoading } from 'redux/Map/actions';
import { messageHelper } from 'helpers/messageHelper';
import { getReport } from 'helpers/getReport';
import common from 'helpers/constants/messages/common';

import * as actions from './actions';
import api from './api.methods';
import * as types from './types';

function* loadSidebarSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingSidebar(true));
    const stationsList = yield call(api.getSideBar, page, limit, params);
    if (stationsList) {
        yield put(actions.loadedSidebar(stationsList));
    }
    yield put(actions.loadingSidebar(false));
}

function* loadCoordsSaga({ payload }) {
    yield put(actions.loadingCoords(true));
    yield put(setMapLoading('tr_coords', true));
    const response = yield call(api.getCoords, payload);
    if (response?.success) {
        yield put(actions.loadedCoords(response?.data));
    }
    yield put(actions.loadingCoords(false));
    yield put(setMapLoading('tr_coords', false));
}

function* loadAllVehiclesSaga({ }) {
    yield put(actions.loadingAllVehicles(true));
    const stationsList = yield call(api.getAllVehicles);
    if (stationsList) {
        yield put(actions.loadedAllVehicles(stationsList));
    }
    yield put(actions.loadingAllVehicles(false));
}

function* loadTypesSaga({ }) {
    const typesList = yield call(api.getTypes);
    if (typesList) {
        yield put(actions.loadedTypes(typesList?.data || {}));
    }
}

function* loadHistorySaga({ payload }) {
    const { vehicleId, params } = payload;
    yield put(actions.loadingHistoryVehicle(true));
    const request = yield call(api.getHistory, vehicleId, params);
    if (request) {
        yield put(actions.loadedHistoryVehicle(vehicleId, request.data));
    }
    yield put(actions.loadingHistoryVehicle(false));
}

function* loadColorSpeedSaga() {
    const response = yield call(api.getColorSpeedList, {});
    if (response?.success) {
        yield put(actions.loadedColorSpeed(response?.data || []));
    }
}

function* loadColorSpeedListSaga({ payload }) {
    yield put(actions.loadingColorSpeedList(true));
    const response = yield call(api.getColorSpeedList, payload);
    if (response?.success) {
        yield put(actions.loadedColorSpeedList(response));
    }
    yield put(actions.loadingColorSpeedList(false));
}

function* addColorSpeedSaga({ payload, callback }) {
    // yield put(actions.loadingColorSpeedList(true));
    const response = yield call(api.addColorSpeed, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    callback && callback(response?.success);
    // yield put(actions.loadingColorSpeedList(false));
}
function* editColorSpeedSaga({ payload, callback }) {
    const { id, data } = payload;
    // yield put(actions.loadingColorSpeedList(true));
    const response = yield call(api.editColorSpeed, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    callback && callback(response?.success);
    // yield put(actions.loadingColorSpeedList(false));
}
function* deleteColorSpeedSaga({ payload, callback }) {
    // yield put(actions.loadingColorSpeedList(true));
    const response = yield call(api.deleteColorSpeed, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
    callback && callback(response?.success);
    // yield put(actions.loadingColorSpeedList(false));
}

function* loadCategoriesVehicleSaga({ payload }) {
    const response = yield call(api.getCategories, payload);
    if (response?.success) {
        yield put(actions.loadedCategoriesVehicle(response?.data));
    }
}

function* loadVehicleSaga({ payload: { page, limit, serial_egts } }) {
    const response = yield call(api.getSideBar, page, limit, { serial_egts });
    if (response?.success) {
        const [item] = response.data;
        yield put(actions.loadedVehicleActive(item));
    }
}
function* loadVehicleHistoryModalSaga({ payload }) {
    const { id, params } = payload;
    yield put(actions.loadingVehicleHistoryModal(true));
    const request = yield call(api.getHistory, id, params);
    if (request) {
        const data = request?.data || [];
        if (data.length === 0) {
            yield put(showMessage(messageTypes.success, 'История ТС не найдена', ''));
        }
        yield put(actions.loadedVehicleHistoryModal(data));
    }
    yield put(actions.loadingVehicleHistoryModal(false));
}

function* findVehiclesSaga({ payload, callback }) {
    yield put(actions.loadingFindVehicles(true));
    const request = yield call(api.findVehicles, payload);

    if (request) {
        const isFind = Object.keys(request?.data || {}).length > 0;

        if (!isFind) {
            yield put(showMessage(messageTypes.success, 'Ближайшего ТС не найдено', ''));
        }
        yield put(actions.loadedFindVehicles(request?.data || {}));
        if (callback && request?.success && isFind) {
            callback();
        }

        if (isFind) {
            yield delay(1000);
            yield put(actions.setActive({
                lat: request?.data?.lat,
                lon: request?.data?.lon,
                ...request?.data?.vehicle || {},
                findVehicle: true,
            }));
        }
    }
    yield put(actions.loadingFindVehicles(false));
}



function* loadBrandsListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingBrandsList(true));
    const brandsList = yield call(api.getBrandList, page, limit, query);
    if (brandsList) {
        yield put(actions.loadedBrandsList(brandsList));
    }
    yield put(actions.loadingBrandsList(false));
}

function* createBrandSaga({ payload, callback }) {
    const response = yield call(api.createBrand, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
        callback?.();
    }
}

function* editBrandSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editBrand, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        callback?.();
    }
}

function* deleteBrandSaga({ payload, callback }) {
    const response = yield call(api.deleteBrand, payload);
    if (response) {
        callback?.();
    }
}

function* loadModelsListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingModelsList(true));
    const modelsList = yield call(api.getModelList, page, limit, query);
    if (modelsList) {
        yield put(actions.loadedModelsList(modelsList));
    }
    yield put(actions.loadingModelsList(false));
}

function* createModelSaga({ payload, callback }) {
    const response = yield call(api.createModel, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
        callback?.();
    }
}

function* editModelSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editModel, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        callback?.();
    }
}

function* deleteModelSaga({ payload, callback }) {
    const response = yield call(api.deleteModel, payload);
    if (response) {
        callback?.();
    }
}

function* loadCompaniesListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingCompaniesList(true));
    const companiesList = yield call(api.getCompanyList, page, limit, query);
    if (companiesList) {
        yield put(actions.loadedCompaniesList(companiesList));
    }
    yield put(actions.loadingCompaniesList(false));
}

function* createCompanySaga({ payload, callback }) {
    const response = yield call(api.createCompany, payload);
    if (response?.success) {
        yield callback();
    }
}

function* editCompanySaga({ payload, callback }) {
    const response = yield call(api.editCompany, payload);
    if (response?.success) {
        yield callback();
    }
}

function* deleteCompanySaga({ payload }) {
    const response = yield call(api.deleteCompany, payload);
    if (response) {
        yield put(actions.loadCompaniesList());
    }
}

function* loadVehiclesSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingVehicleList(true));
    const response = yield call(api.getVehicleList, page, limit, data);
    if (response) {
        yield put(actions.loadedVehicleList(response));
    }
    yield put(actions.loadingVehicleList(false));
}

function* loadVehicleCategoriesSaga() {
    const response = yield call(api.getCategories);
    if (response) {
        yield put(actions.loadedVehicleCategories(response));
    }
}

function* loadVehicleCategoryListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingVehicleCategoryList(true));
    const response = yield call(api.getVehicleCategoryList, page, limit, query);
    if (response) {
        yield put(actions.loadedVehicleCategoryList(response));
    }
    yield put(actions.loadingVehicleCategoryList(false));
}

function* createVehicleCategorySaga({ payload, callback }) {
    const response = yield call(api.createVehicleCategory, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
        yield callback();
    }
}

function* editVehicleCategorySaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editVehicleCategory, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        yield callback();
    }
}

function* deleteVehicleCategorySaga({ payload, callback }) {
    const response = yield call(api.deleteVehicleCategory, payload);
    if (response) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        yield callback();
    }
}

function* loadVehiclesTypesSaga() {
    const response = yield call(api.getTypes);
    if (response) {
        yield put(actions.loadedVehiclesTypes(response));
    }
}

function* deleteVehicleSaga({ payload, callback }) {
    const response = yield call(api.deleteVehicle, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        yield callback?.();
    }
}

function* createNewVehicleSaga({ payload, callback }) {
    const response = yield call(api.createNewVehicle, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Данные были успешно добавлены'));
        yield callback?.();
    }
}

function* editVehicleSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editVehicle, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Данные были успешно изменены'));
        yield callback?.();
    }
}

function* loadVehicleReportSaga({ payload }) {
    const report = payload?.report || false;

    yield put(actions.loadingRWVehicleReport(true));
    const response = yield call(api.getVehicleReport, payload);

    if (response?.success) {
        if (report) {
            yield messageHelper(response);
        } else {
            yield put(actions.loadedRWVehicleReport(response));
        }
    }

    yield put(actions.loadingRWVehicleReport(false));
}

// TODO проверить - вроде нигде не используется
function* loadEmployeesSaga ({ payload }) {
    const { page, limit, data } = payload;
    const report = data?.report;
    const config = report
        ? { params: { ...data }, responseType: 'blob' }
        : { params: { page, limit, ...data } };

    yield put(actions.loadingEmployees(true));
    const response = yield call(api.getEmployees, config);
    if (response) {
        report
            ? getReport(response, report)
            : yield put(actions.loadedEmployees(response));
    }
    yield put(actions.loadingEmployees(false));
}

function* addEmployeesSaga ({ payload, callback }) {
    const response = yield call(api.addEmployees, payload);
    yield put(actions.loadingEmployees(true));
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    yield put(actions.loadingEmployees(false));
}

function* editEmployeesSaga ({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingEmployees(true));
    const response = yield call(api.editEmployees, id, data);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
    yield put(actions.loadingEmployees(false));
}

function* deleteEmployeesSaga ({ payload, callback }) {
    const response = yield call(api.deleteEmployees, payload);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

// positions
function* loadPositionsSaga ({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingPositions(true));
    const response = yield call(api.getPositions, { page, limit, ...data });
    if (response) {
        yield put(actions.loadedPositions(response));
    }
    yield put(actions.loadingPositions(false));
}

function* addPositionsSaga ({ payload, callback }) {
    yield put(actions.loadingPositions(true));
    const response = yield call(api.addPositions, payload);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    yield put(actions.loadingPositions(false));
}

function* editPositionsSaga ({ payload, callback }) {
    yield put(actions.loadingPositions(true));
    const { id, data } = payload;
    const response = yield call(api.editPositions, id, data);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
    yield put(actions.loadingPositions(false));
}

function* deletePositionsSaga ({ payload, callback }) {
    yield put(actions.loadingPositions(true));
    const response = yield call(api.deletePositions, payload);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
    yield put(actions.loadingPositions(false));
}
// brigades
function* loadBrigadesSaga ({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingBrigades(true));
    const response = yield call(api.getBrigades, page, limit, data);
    if (response) {
        yield put(actions.loadedBrigades(response));
    }
    yield put(actions.loadingBrigades(false));
}

function* addBrigadesSaga ({ payload, callback }) {
    yield put(actions.loadingBrigades(true));
    const response = yield call(api.addBrigades, payload);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    yield put(actions.loadingBrigades(false));
}

function* editBrigadesSaga ({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingBrigades(true));
    const response = yield call(api.editBrigades, id, data);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
    yield put(actions.loadingBrigades(false));
}

function* deleteBrigadesSaga ({ payload, callback }) {
    const response = yield call(api.deleteBrigades, payload);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* loadParksSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingParks(true));
    const response = yield call(api.loadParks, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedParks(response));
    }
    yield put(actions.loadingParks(false));
}

function* createParkSaga({ payload, callback }) {
    const response = yield call(api.createPark, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        callback?.();
    }
}

function* editParkSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editPark, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        callback?.();
    }
}

function* deleteParkSaga({ payload, callback }) {
    const response = yield call(api.deletePark, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        callback?.();
    }
}

function* loadVehicleDowntimeSaga({ payload, callback }) {
    const { id, query } = payload;
    yield put(actions.loadingVehicleList(true));
    const response = yield call(api.getVehicleDowntime, id, query);
    if (response?.success) {
        yield put(actions.loadedVehicleDowntime(response));
        yield callback?.();
    }
    yield put(actions.loadingVehicleList(false));
}

// Модули - Дорожная техника - слив топлива
function* getReportFuelSaga({ payload, callback }) {
    const response = yield call(api.getReportFuel, payload);
    if (response?.success) {
        yield put(actions.loadedReportFuel(response));
        yield callback?.();
    }
}

function* loadRoutesSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingRoutes(true));
    const response = yield call(api.getRoutes, page, limit, data);
    if (response?.success) {
        yield put(actions.loadedRoutes(response));
    }
    yield put(actions.loadingRoutes(false));
}

function* createRouteSaga({ payload, callback }) {
    const response = yield call(api.createRoute, payload);
    if (response?.success) {
        callback(response?.data);
        yield showMessage(messageTypes.success, 'Маршрут был успешно сохранен');
    }
}

function* deleteRouteSaga({ payload, callback }) {
    const response = yield call(api.deleteRoute, payload);
    if (response?.success) {
        callback(response?.data);
        yield showMessage(messageTypes.success, 'Маршрут был успешно удален');
    }
}

function* changeRouteSaga({ payload, callback }) {
    const { uuid, data } = payload;
    const response = yield call(api.changeRoute, uuid, data);
    if (response?.success) {
        callback && callback();
        // yield put(actions.loadRoutes()); // обновляем список через callback
        // yield showMessage(messageTypes.success, 'Успешно сохранено');

        // yield put(actions.changeRoutesAfter(response?.data || {}));
    }
    // yield put(actions.setChangeRoutesStatus(response?.success || false));
}

function* loadRoutesStatusesSaga() {
    const response = yield call(api.getRouteStatuses);
    if (response?.success) {
        yield put(actions.loadedRoutesStatuses(response));
    }
}

function* loadRouteTypesSaga() {
    const routeTypes = yield call(api.getRouteTypes);
    if (routeTypes) {
        yield put(actions.loadedRouteTypes(routeTypes.data));
    }
}

function* getRouteCheckpointsSaga({ payload: id, callback }) {
    yield put(actions.loadingRouteCheckpoints(true));
    const response = yield call(api.getRouteCheckpoints, id);
    if (response) {
        yield put(actions.loadedRouteCheckpoints(id, response.data));
    }
    yield put(actions.loadingRouteCheckpoints(false));
}
function* saveRouteCheckpointsSaga({ payload, callback }) {
    const { id, checkpoints } = payload;
    yield put(actions.loadingRouteCheckpoints(true));
    const response = yield call(api.saveRouteCheckpoints, id, checkpoints);
    if (response?.success) {
        callback && callback();
        yield put(actions.loadRouteCheckpoints(id));
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
    }
    yield put(actions.loadingRouteCheckpoints(false));
}

function* loadTelemetryReceiversSaga({ payload }) {
    yield put(actions.loadingTelemetryReceivers(true));
    const response = yield call(api.getTelemetryReceivers, payload);
    if (response?.success) {
        yield put(actions.loadedTelemetryReceivers(response));
    }
    yield put(actions.loadingTelemetryReceivers(false));
}

function* addTelemetryReceiverSaga({ payload, callback }) {
    const response = yield call(api.addTelemetryReceiver, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        callback && callback();
    }
}

function* editTelemetryReceiverSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editTelemetryReceiver, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        callback && callback();
    }
}

function* deleteTelemetryReceiverSaga({ payload, callback }) {
    const response = yield call(api.deleteTelemetryReceiver, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        callback && callback();
    }
}

function* loadTelemetryReceiverTypesSaga({ payload }) {
    const response = yield call(api.getTelemetryReceiverTypes, payload);
    if (response?.success) {
        yield put(actions.loadedTelemetryReceiverTypes(response.data));
    }
}

// workorders
function* loadWorkordersSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingWorkorders(true));
    const response = yield call(api.getWorkorders, page, limit, data);
    if (response) {
        yield put(actions.loadedWorkorders(response));
    }
    yield put(actions.loadingWorkorders(false));
}

function* loadCurrentWorkorderSaga({ payload, callback }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingCurrentWorkorder(true));
    const response = yield call(api.getWorkorders, page, limit, data);
    if (response) {
        // yield put(actions.loadedCurrentWorkorder({ [data?.id]: response?.data[0] }));
        callback && callback(response?.data[0]);
    }
    yield put(actions.loadingCurrentWorkorder(false));
}

function* loadWorkordersByDateSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingWorkordersByDate(true));
    const response = yield call(api.getWorkordersByDate, page, limit, data);
    if (response) {
        yield put(actions.loadedWorkordersByDate(response));
    }
    yield put(actions.loadingWorkordersByDate(false));
}

function* addWorkorderSaga ({ payload, callback }) {
    const response = yield call(api.saveWorkorder, payload);
    if (response) {
        callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* editWorkorderSaga ({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editWorkorder, id, data);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* loadWorkorderStatusesSaga () {
    const response = yield call(api.getWorkorderStatuses);
    if (response) {
        yield put(actions.loadedWorkorderStatuses(response?.data));
    }
}

function* deleteWorkorderSaga ({ payload, callback }) {
    const response = yield call(api.deleteWorkorder, payload);
    if (response) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        callback && callback();
    }
}

function* loadWorkorderLogsSaga({ payload }) {
    yield put(actions.loadingWorkorderLogs(true));

    const response = yield call(api.getWorkordersLogs, payload);
    if (response) {
        yield put(actions.loadedWorkorderLogs(response?.data));
    }

    yield put(actions.loadingWorkorderLogs(false));
}

function* loadWorkorderLogManualStatusSaga() {
    const response = yield call(api.getWorkorderLogManualStatus);
    if (response) {
        yield put(actions.loadedWorkorderLogManualStatus(response?.data));
    }
}

function* addWorkorderLogManualStatusSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.addWorkorderLogManualStatus, id, data);
    if (response.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        callback && callback();
    }
}

function* editWorkorderLogManualStatusSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editWorkorderLogManualStatus, id, data);
    if (response.success) {
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        callback && callback();
    }
}

function* deleteWorkorderLogManualStatusSaga({ payload, callback }) {
    const response = yield call(api.deleteWorkorderLogManualStatus, payload);
    if (response.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        callback && callback();
    }
}

function* loadCheckPointsSaga({ payload }) {
    yield put(actions.loadingCheckPoints(true));
    const response = yield call(api.getCheckPoints, payload);
    if (response?.success) {
        yield put(actions.loadedCheckPoints(response));
    }
    yield put(actions.loadingCheckPoints(false));
}

function* createCheckPointSaga({ payload, callback }) {
    const response = yield call(api.createCheckPoint, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        callback && callback();
    }
}

function* editCheckPointSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editCheckPoint, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        callback && callback();
    }
}

function* deleteCheckPointSaga({ payload, callback }) {
    const response = yield call(api.deleteCheckPoint, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        callback && callback();
    }
}

function* loadReportByVehiclesSaga({ payload }) {
    yield put(actions.loadingReportByVehicles(true));
    const response = yield call(api.getReportByVehicles, payload);
    if (response) {
        yield put(actions.loadedReportByVehicles(response));
    }
    yield put(actions.loadingReportByVehicles(false));
}

function* loadCheckPointPolygonSaga({ payload }) {
    const response = yield call(api.loadCheckPointPolygon, payload);
    if (response?.success) {
        yield put(actions.loadedCheckPointPolygon(response.data));
    }
}

function* changeRouteStatusSaga ({ payload, callback }) {
    const {
        routeId,
        statusId,
    } = payload;
    yield put(actions.loadingSetRouteStatus(true));

    const response = yield call(api.changeRouteStatus, routeId, statusId);

    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }

    yield put(actions.loadingSetRouteStatus(false));
}

function* loadVehicleStatisticsSaga() {
    yield put(actions.loadingVehicleStatistics(true));
    const response = yield call(api.loadVehicleStatistics);
    if (response?.success) {
        yield put(actions.loadedVehicleStatistics(response.data));
    }
    yield put(actions.loadingVehicleStatistics(false));
}

function* loadWorkorderStatisticsSaga() {
    yield put(actions.loadingWorkorderStatistics(true));
    const response = yield call(api.loadWorkorderStatistics);
    if (response?.success) {
        yield put(actions.loadedWorkorderStatistics(response.data));
    }
    yield put(actions.loadingWorkorderStatistics(false));
}

function* loadEgtsTransmittersSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.loadEgtsTransmitters, payload);
    if (response?.success) {
        yield fnCallback?.(response);
    }
    yield fnLoading?.(false);
}

function* addEgtsTransmitterSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.addEgtsTransmitter, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        yield fnCallback?.();
    }
    yield fnLoading?.(false);
}

function* editEgtsTransmitterSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const { id, data } = payload;
    const response = yield call(api.editEgtsTransmitter, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        yield fnCallback?.();
    }
    yield fnLoading?.(false);
}

function* deleteEgtsTransmitterSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.deleteEgtsTransmitter, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        yield fnCallback?.();
    }
    yield fnLoading?.(false);
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_SIDEBAR, loadSidebarSaga),
        takeLatest(types.LOAD_COORDS, loadCoordsSaga),
        takeLatest(types.LOAD_ALL_VEHICLES, loadAllVehiclesSaga),
        takeLatest(types.LOAD_TYPES, loadTypesSaga),
        takeLatest(types.LOAD_HISTORY_VEHICLE, loadHistorySaga),

        /* color speed */
        takeLatest(types.LOAD_COLOR_SPEED, loadColorSpeedSaga),
        takeLatest(types.LOAD_COLOR_SPEED_LIST, loadColorSpeedListSaga),
        takeLatest(types.ADD_COLOR_SPEED, addColorSpeedSaga),
        takeLatest(types.EDIT_COLOR_SPEED, editColorSpeedSaga),
        takeLatest(types.DELETE_COLOR_SPEED, deleteColorSpeedSaga),

        takeLatest(types.LOAD_VEHICLE, loadVehicleSaga),
        takeLatest(types.LOAD_CATEGORIES_VEHICLE, loadCategoriesVehicleSaga),

        takeLatest(types.LOAD_VEHICLE_HISTORY_MODAL, loadVehicleHistoryModalSaga),

        takeLatest(types.LOAD_FIND_VEHICLES, findVehiclesSaga),

        takeLatest(types.LOAD_BRANDS, loadBrandsListSaga),
        takeLatest(types.CREATE_BRAND, createBrandSaga),
        takeLatest(types.EDIT_BRAND, editBrandSaga),
        takeLatest(types.DELETE_BRAND, deleteBrandSaga),

        takeLatest(types.LOAD_MODELS, loadModelsListSaga),
        takeLatest(types.CREATE_MODEL, createModelSaga),
        takeLatest(types.EDIT_MODEL, editModelSaga),
        takeLatest(types.DELETE_MODEL, deleteModelSaga),

        takeLatest(types.LOAD_COMPANIES, loadCompaniesListSaga),
        takeLatest(types.CREATE_COMPANY, createCompanySaga),
        takeLatest(types.EDIT_COMPANY, editCompanySaga),
        takeLatest(types.DELETE_COMPANY, deleteCompanySaga),

        takeLatest(types.LOAD_VEHICLES_LIST, loadVehiclesSaga),

        takeLatest(types.LOAD_VEHICLES_CATEGORIES, loadVehicleCategoriesSaga),
        takeLatest(types.LOAD_VEHICLE_CATEGORY_LIST, loadVehicleCategoryListSaga),

        takeLatest(types.CREATE_VEHICLE_CATEGORY, createVehicleCategorySaga),
        takeLatest(types.EDIT_VEHICLE_CATEGORY, editVehicleCategorySaga),
        takeLatest(types.DELETE_VEHICLE_CATEGORY, deleteVehicleCategorySaga),

        takeLatest(types.LOAD_VEHICLES_TYPES, loadVehiclesTypesSaga),
        takeLatest(types.DELETE_VEHICLE, deleteVehicleSaga),
        takeLatest(types.CREATE_VEHICLE, createNewVehicleSaga),
        takeLatest(types.EDIT_VEHICLE, editVehicleSaga),
        takeLatest(types.LOAD_VEHICLE_REPORT, loadVehicleReportSaga),
        //
        takeLatest(types.LOAD_EMPLOYEES, loadEmployeesSaga),
        takeLatest(types.EDIT_EMPLOYEES, editEmployeesSaga),
        takeLatest(types.ADD_EMPLOYEES, addEmployeesSaga),
        takeLatest(types.DELETE_EMPLOYEES, deleteEmployeesSaga),
        // positions
        takeLatest(types.LOAD_POSITIONS, loadPositionsSaga),
        takeLatest(types.EDIT_POSITIONS, editPositionsSaga),
        takeLatest(types.ADD_POSITIONS, addPositionsSaga),
        takeLatest(types.DELETE_POSITIONS, deletePositionsSaga),
        // brigades
        takeLatest(types.LOAD_BRIGADES, loadBrigadesSaga),
        takeLatest(types.EDIT_BRIGADES, editBrigadesSaga),
        takeLatest(types.ADD_BRIGADES, addBrigadesSaga),
        takeLatest(types.DELETE_BRIGADES, deleteBrigadesSaga),

        takeLatest(types.LOAD_PARKS, loadParksSaga),
        takeLatest(types.CREATE_PARK, createParkSaga),
        takeLatest(types.EDIT_PARK, editParkSaga),
        takeLatest(types.DELETE_PARK, deleteParkSaga),

        takeLatest(types.LOAD_VEHICLE_DOWNTIME, loadVehicleDowntimeSaga),

        takeLatest(types.GET_REPORT_FUEL, getReportFuelSaga),

        takeLatest(types.LOAD_ROUTES, loadRoutesSaga),
        takeLatest(types.SAVE_ROUTE, createRouteSaga),
        takeLatest(types.DELETE_ROUTE, deleteRouteSaga),
        takeLatest(types.CHANGE_ROUTE, changeRouteSaga),
        takeLatest(types.LOAD_ROUTE_STATUSES, loadRoutesStatusesSaga),
        takeLatest(types.LOAD_ROUTE_TYPES, loadRouteTypesSaga),

        takeLatest(types.LOAD_ROUTE_CHECKPOINTS, getRouteCheckpointsSaga),
        takeLatest(types.SAVE_CHECKPOINTS_TO_ROUTE, saveRouteCheckpointsSaga),

        takeLatest(types.LOAD_TELEMETRY_RECEIVERS, loadTelemetryReceiversSaga),
        takeLatest(types.ADD_TELEMETRY_RECEIVER, addTelemetryReceiverSaga),
        takeLatest(types.EDIT_TELEMETRY_RECEIVER, editTelemetryReceiverSaga),
        takeLatest(types.DELETE_TELEMETRY_RECEIVER, deleteTelemetryReceiverSaga),
        takeLatest(types.LOAD_TELEMETRY_RECEIVER_TYPES, loadTelemetryReceiverTypesSaga),

        // workorders
        takeLatest(types.LOAD_WORKORDERS, loadWorkordersSaga),
        takeLatest(types.LOAD_WORKORDERS_BY_DATE, loadWorkordersByDateSaga),
        takeLatest(types.CREATE_WORKORDER, addWorkorderSaga),
        takeLatest(types.DELETE_WORKORDER, deleteWorkorderSaga),
        takeLatest(types.EDIT_WORKORDER, editWorkorderSaga),
        takeLatest(types.LOAD_WORKORDER_STATUSES, loadWorkorderStatusesSaga),
        takeLatest(types.LOAD_CURRENT_WORKORDER, loadCurrentWorkorderSaga),

        takeLatest(types.LOAD_WORKORDER_LOGS, loadWorkorderLogsSaga),
        takeLatest(types.LOAD_WORKORDER_LOG_MANUAL_STATUS, loadWorkorderLogManualStatusSaga),
        takeLatest(types.ADD_WORKORDER_LOG_MANUAL_STATUS, addWorkorderLogManualStatusSaga),
        takeLatest(types.EDIT_WORKORDER_LOG_MANUAL_STATUS, editWorkorderLogManualStatusSaga),
        takeLatest(types.DELETE_WORKORDER_LOG_MANUAL_STATUS, deleteWorkorderLogManualStatusSaga),

        takeLatest(types.LOAD_CHECK_POINTS, loadCheckPointsSaga),
        takeLatest(types.CREATE_CHECK_POINT, createCheckPointSaga),
        takeLatest(types.EDIT_CHECK_POINT, editCheckPointSaga),
        takeLatest(types.DELETE_CHECK_POINT, deleteCheckPointSaga),

        takeLatest(types.LOAD_REPORT_BY_VEHICLES, loadReportByVehiclesSaga),

        takeLatest(types.LOAD_CHECK_POINT_POLYGON, loadCheckPointPolygonSaga),

        takeLatest(types.CHANGE_ROUTE_STATUS, changeRouteStatusSaga),
        takeLatest(types.LOAD_VEHICLE_STATISTICS, loadVehicleStatisticsSaga),
        takeLatest(types.LOAD_WORKORDER_STATISTICS, loadWorkorderStatisticsSaga),

        takeLatest(types.LOAD_EGTS_TRANSMITTER, loadEgtsTransmittersSaga),
        takeLatest(types.ADD_EGTS_TRANSMITTER, addEgtsTransmitterSaga),
        takeLatest(types.EDIT_EGTS_TRANSMITTER, editEgtsTransmitterSaga),
        takeLatest(types.DELETE_EGTS_TRANSMITTER, deleteEgtsTransmitterSaga),
    ]);
};
