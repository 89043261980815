import * as types from './types';

export const getList = (params = {}) => ({
    type: types.GET_LIST,
    payload: params,
});

export const loadingList = (response) => ({
    type: types.LIST_LOADING,
    payload: response,
});

export const loadedList = (response) => ({
    type: types.LIST_LOADED,
    payload: response,
});

export const loadStatuses = () => ({
    type: types.LOAD_STATUSES,
});

export const loadingStatuses = (isLoading) => ({
    type: types.LOADING_STATUSES,
    payload: isLoading,
});

export const loadedStatuses = (response) => ({
    type: types.LOADED_STATUSES,
    payload: response,
});
