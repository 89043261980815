import React, { useEffect, useState } from 'react';
import {
    TextField,
} from '@mui/material';
import cn from 'classnames';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';


const Filter = ({ onChange, data = {}, }) => {
    const [wasSearch, setWasSearch] = useState(false);
    const initialState = {
        name: '',
    };
    const [fields, setFields] = useState(initialState);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields({
            ...fields,
            [name]: value
        });
    };

    const search = () => {
        const paramsWithoutEmpty = removeEmptyFields(fields, false);
        setWasSearch(Object.keys(paramsWithoutEmpty).length > 0);
        onChange(paramsWithoutEmpty);
    };

    const resetFilter = () => {
        if (wasSearch) {
            setWasSearch(false);
            onChange({});
        }
        setFields(initialState);
    };

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setWasSearch(true);
            setFields(data);
        }
    },[data]);

    return (
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">
                <TextField
                    label="Название"
                    size="small"
                    value={fields?.name}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={handleChange}
                />
            </div>
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilter,
                            disabled: !wasSearch && !fields?.name
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: search,
                            disabled: !fields?.name
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;