import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import LeaveArriveForm from './LeaveArriveForm';

const LeaveArriveItem = ({ data, onChange, shiftList }) => {

    const handleChangeItem = (index, el) => {
        const newData = [
            ...data.slice(0, index),
            el,
            ...data.slice(index + 1)
        ];
        onChange(newData);
    };

    const handlDeleteItem = (index) => {
        const newData = [
            ...data.slice(0, index),
            ...data.slice(index + 1)
        ];
        onChange(newData);
    };

    const addItem = () => {
        const newData = [
            ...data,
            {
                shift: '',
                departure_plan: null,
                return_plan: null,
                departure_fact: null,
                return_fact: null,
            },
        ];
        onChange(newData);
    };

    return (<>
        <h2>Выезд и возвращение: </h2>
        {data.map((item, index) =>{
            return (
                <LeaveArriveForm 
                    key={index}
                    index={index}
                    item={item}
                    onChangeItem={(el) => handleChangeItem(index, el)}
                    onDeleteItem={() => handlDeleteItem(index)}
                    shiftList={shiftList}
                    // isNew={isNew}
                    isClear={false}
                />
            );
        })}
        <FormButtonsComponent
            positionLeft
            buttons={[
                {
                    ...buttonsTypes.add,
                    onClick: addItem,
                    name: 'Добавить'
                }
            ]}
        /> 
        
    </>);
};

export default LeaveArriveItem;
