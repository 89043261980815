import titles from '../../../../../helpers/constants/titles';
import { IconButton, Link, Tooltip } from '@mui/material';

// показать линк
const ShowLink = ({
    link,
    defaultValue = null,
}) => {
    return link
        ? (
            <Tooltip title={titles.CLICK_TO_OPEN}>
                <IconButton size="small">
                    <Link href={link} target="_blank">
                        <i className="fal fa-file-alt"/> {titles.DOCUMENT}
                    </Link>
                </IconButton>
            </Tooltip>
        )
        : defaultValue;
};

export default ShowLink;