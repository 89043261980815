import HomePage from '../pages/Homepage';
// import Test from './Test';

const routes = [
    {
        path: '/map',
        component: HomePage,
        exact: true,
    },
    // {
    //     path: '/test',
    //     component: Test,
    //     exact: true,
    // }
];

export default routes;