import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { trafficFlowSelectors } from 'redux/TrafficFlow';
import * as actions from 'redux/TrafficFlow/actions';
import mapHelper from 'helpers/mapHelper';
import { useStoreFromSelector } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import colorExtend from 'helpers/mapHelper/colorExtend';
import {
    ContextMenu,
    ContextMenuItem,
    Marker,
    PopUp,
    ToolTip,
} from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import MapLegends from 'components/common/Transport/MapLegends';

import useForm from './Form/useForm';
import CPopup from './PopUp';
import { createIconMarker } from './helper';
import Legend from './Legend';
import config from './config.js';

const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();
    const polygon = useSelector(trafficFlowSelectors.polygon);
    const polygonLoading = useSelector(trafficFlowSelectors.polygonLoading);
    const polygonVisible = useSelector(trafficFlowSelectors.polygonVisible);
    const active = useSelector(trafficFlowSelectors.active);
    const filters = useSelector(trafficFlowSelectors.filters);
    const activeId = active.id || 0;
    const [isPopUp, setIsPopUp] = useState(false);
    const [isFirst, setIsFirst] = useState(true);

    const statuses = useStoreFromSelector(actions.loadStatus, trafficFlowSelectors.statuses);
    const types = useStoreFromSelector(actions.loadTypes, trafficFlowSelectors.types);

    const handleSuccess = () => {
        fetchPolygon();
    };
    const logicalPuidForm = useForm(handleSuccess);

    // активный элемент есть в полигоне
    const issetActive = polygonVisible.findIndex(({ id }) => id === active?.id) >= 0;
    const previewId = useRef(0);

    // грузим полигон
    const fetchPolygon = () => {
        const filter = removeEmptyFields(getFilters(filters));
        const polygon = mapHelper.getPolygon(map);
        dispatch(actions.getForPolygon(polygon, filter));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = debounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();

    const handlePUO = () => {
        setIsPopUp(true);
    };
    const handlePUC = () => {
        setIsPopUp(false);
    };

    // добавить новый
    const handleAdd = ({ lat, lng }) => {
        logicalPuidForm.showAddForm({
            lat,
            lon: lng,
        });
    };

    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, (e) => {
            handleAdd(e.latlng);
        });

        return () => {
            map.off(config.mapContextMenu.event);
        };
    }, []);

    useEffect(() => {
        if (
            (
                isPopUp === false
                || (Object.keys(active).length > 0 && issetActive === false && isFirst)
            )
            && polygonLoading === false
        ) {
            if (Object.keys(active).length > 0) setIsFirst(false);
            dispatch(actions.setPolygonVisible(polygon));
        }
    }, [polygon, isPopUp, active, isFirst, issetActive, polygonLoading]);

    // сдвинуться к маркеру
    const setMapToMarker = () => {
        if (Object.keys(active).length > 0) {
            const { lat, lon } = active;
            if (Math.abs(lat) && Math.abs(lon)) {
                setTimeout(() => {
                    map.setView([lat, lon]);
                }, 200);
            }
        }
    };

    useEffect(() => {
        fetchPolygon();

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon)
            .on('popupopen', handlePUO)
            .on('popupclose', handlePUC);

        setMapToMarker();

        return () => {
            dispatch(actions.resetActive());
            dispatch(actions.clearForPolygon());
            map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon)
                .off('popupopen', handlePUO)
                .off('popupclose', handlePUC);
        };
    }, [filters]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            previewId.current = active?.id;
            setIsFirst(true);

            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 15);
            }
        }
    }, [active]);

    const clickPopUp = (id) => () => {
        if (previewId.current === id) {
            dispatch(actions.resetActive());
        }
    };

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProps }) => {
        return (
            <div>
                <ContextMenuItem
                    {...rcmProps}
                    value="Редактировать"
                    onClick={() => {
                        map.fire('context_menu_close');
                        logicalPuidForm.showEditForm(item);
                    }}
                />
                <ContextMenuItem
                    {...rcmProps}
                    value="Удалить"
                    onClick={() => {
                        map.fire('context_menu_close');
                        logicalPuidForm.showConfirmDelete(item.id);
                    }}
                    className="red"
                />
            </div>
        );
    };

    const polygonData = useMemo(() => {
        return polygonVisible.map((item) => {
            const { id, lat, lon } = item;
            const componentProps = {
                ...props,
                key: id,
                attribution: {
                    slug: config.slug,
                    color: colorExtend(item.color),
                },
                onClick: (latlng) => {
                    map.setView(latlng);
                    dispatch(actions.resetActive());
                },
            };

            const currentShow = activeId === id;

            return (
                <Marker
                    {...componentProps}
                    latlng={[lat, lon]}
                    icon={createIconMarker(colorExtend(item.color))}
                >
                    <ToolTip
                        offset={[0, -40]}
                        direction="top"
                    >
                        <div>{item.name}</div>
                    </ToolTip>
                    <PopUp
                        minWidth={500}
                        show={currentShow}
                        onClose={clickPopUp(id)}
                        // onClose={() => {
                        //     if (currentShow) {
                        //         dispatch(actions.resetActive());
                        //     }
                        // }}
                    >
                        <CPopup
                            uuid={id}
                            statuses={statuses}
                            types={types}
                        />
                    </PopUp>

                    {item.type_id === 4 && (
                        <ContextMenu>
                            <RenderContextMenu item={item} />
                        </ContextMenu>
                    )}
                </Marker>
            );
        });
    }, [polygonVisible]);

    return (
        <>
            {polygonData}

            {logicalPuidForm.renderComponent()}

            <MapLegends layer={config.slug}>
                <Legend/>
            </MapLegends>
        </>
    );
};

export default Layer;
