import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useValidation } from '../../../../../helpers/hooks';
import Modal from '../../../../common/Modal';
import titles from '../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { TextField } from '@mui/material';
import { createRoadWorksGroup, editRoadWorksGroup } from '../../../../../redux/RoadWorks/actions';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const AddEditModal = ({ isNew, isOpen, onClose, item, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        name: item?.name || '',
    };

    const [formData, setFormData] = useState(initialState);

    const onChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleAccept = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        dispatch(isNew
            ? createRoadWorksGroup(formData, callback)
            : editRoadWorksGroup(item.id, formData, callback));
    };

    const isDisabled = formData.name.trim().length === 0;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isDisabled,
                        onClick: handleAccept
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormInfoWrapper error={validation.isKey('name')} helperText={validation.get('name')}>
                    <TextField
                        className="block"
                        label="Название"
                        variant="outlined"
                        size="small"
                        value={formData.name}
                        onChange={onChange}
                        name="name"
                        error={validation.isKey('name')}
                    />
                </FormInfoWrapper>
            </div>
        </Modal>
    );
};

export default AddEditModal;