import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, Avatar } from '@mui/material';

import { deleteBrand, loadVehicleCategories } from 'redux/TransportRoad/actions';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import { checkBase64 } from 'helpers/transport.types';
import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import BrandModal from './BrandModal';

const BrandItem = ({ item, reloadList, permissions }) => {
    const dispatch = useDispatch();

    const categories = useStoreProp(loadVehicleCategories, 'transportRoad', 'vehicleCategories');

    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const toggleOpen = (e) => {
        e && e.stopPropagation();
        setModalOpen(!modalOpen);
    };

    const toggleDeleteOpen = (e) => {
        e && e.stopPropagation();
        setDeleteModalOpen(!deleteModalOpen);
    };

    const handleDelete = () => {
        dispatch(deleteBrand(item.id, () => {
            reloadList(true);
            toggleDeleteOpen();
        }));
    };

    const category = categories?.find((el) => item.category_id === el.id);

    const imgUrl = checkBase64(category?.image_with_work_order);

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {imgUrl && (
                        <Tooltip title={category?.name || messages.NO_DATA}>
                            <Avatar sx={{ background: category?.color }}>
                                <img
                                    style={{ width: 30, height: 30, margin: 0 }}
                                    src={imgUrl}
                                    alt={category?.name || ''}
                                />
                            </Avatar>
                        </Tooltip>
                    )}
                </LSContentColumn>
                <LSContentColumn>
                    {item.name_en || messages.NO_DATA}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: toggleOpen,
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: toggleDeleteOpen,
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {modalOpen && (
                <BrandModal
                    onClose={toggleOpen}
                    open={modalOpen}
                    data={item}
                    reloadList={reloadList}
                />
            )}
            {deleteModalOpen && (
                <ConfirmDelete
                    open={deleteModalOpen}
                    message={messages.CONFIRM_DELETE}
                    onClose={toggleDeleteOpen}
                    onSuccess={handleDelete}
                />
            )}
        </>
    );
};

export default BrandItem;
