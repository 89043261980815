import React from 'react';
import { IconButton, Tooltip, Divider } from '@mui/material';
import titles from '../../../../../../../../../helpers/constants/titles';
import DeleteIcon from '@mui/icons-material/Delete';
import RenderTransferFields from './RenderTransferFields';
import buttons from '../../../../../../../../../helpers/constants/buttons';
import FormButtonsComponent from '../../../../../../../../common/FormButtons';

// TODO дублирование кода из комплексов, вынести в общие компоненты
const RenderArrayItem = ({ content, item, onChange, onDelete  }) => {
    return (<div style={{ backgroundColor: '#ebebeb', padding: '5px' }}>
        <div className="row align-items-center">
            <div className="row__item">
                {content?.map(el => 
                    <RenderTransferFields
                        key={el.field} 
                        element={el} 
                        currentData={item}
                        handleChange={onChange}
                    />
                )}
            </div>
            <Tooltip title={titles.DELETE}>
                <IconButton size="small" onClick={onDelete}>
                    <DeleteIcon fontSize={'inherit'}/>
                </IconButton>
            </Tooltip>
        </div>
        <Divider style={{ marginBottom: '5px' }}/>
    </div>);
};

const RenderArray = ({ current, element, handleChange }) => {
    const onAdd = () => {
        const newData = [
            ...current,
            {}
        ];
            // setDuploObj(newData);
        handleChange(element?.field, newData);
    };
    
    const onDelete = (index) => {
        const newData = [
            ...current.slice(0, index),
            ...current.slice(index + 1)
        ];
        handleChange(element?.field, newData);
    };

    const onChange = (index, name, value) => {
        const newData = current.map((item, i) => i === index 
            ? {
                ...item,
                [name]: value
            }
            : item
        );
        handleChange(element?.field, newData);
    };

    return (<>
        <h2>{element?.name}</h2>
        {current?.map((item, i) => 
            <RenderArrayItem 
                key={i} 
                content={element?.content}
                onChange={(name, val) => onChange(i, name, val)}
                onDelete={() => onDelete(i)}
                item={item}
            />
        )}
        <FormButtonsComponent
            noPadding
            noMarginLeft
            positionLeft
            buttons={[
                {
                    onClick: onAdd,
                    name: buttons.ADD
                }
            ]}
        />
    </>);
};

export default RenderArray;