import React, { useMemo, useState } from 'react';
import { config } from '../../../../../../../config'; 
import {
    Map,
    FeatureGroup,
    MapControl,
    CircleMarker,
    ToolTip,
} from '../../../../../../MapComponents/leaflet';
import messages from '../../../../../../../helpers/constants/messages/common';
import titles from '../../../../../../../helpers/constants/titles';
import RouteJson from '../../../../../../MapComponents/Layers/TransportPassenger/RouteJson';
import { getRouteColor } from '../../../../../../MapComponents/Layers/TransportPassenger/helper';


const MapForm  = ({
    currentRoute = {},
    checkPoints = []
}) => {
    const mapCenter = config.get('mapCenter');
    const [mapZoom, setMapZoom] = useState(null);
    const [bounds, setBounds] = useState(null);
    const color = getRouteColor(currentRoute?.category_id);

    const stations = checkPoints || [];
    // const stations = checkPoints?.filter((item) => item.station) || [];

    const checkPointsMarkers = useMemo(() => {
        return stations?.map((item, index) => {
            const name = item?.station?.name || item.check_point?.name || '';
            const direction = item?.station?.direction;
            const isStation = !!item?.station;
            const key = item?.id || item?.station?.id || item?.check_point_id || index + 1;

            const { lat, lon } = item?.station?.check_point || item?.check_point;

            return (
                <CircleMarker
                    key={key}
                    latlng={[lat, lon]}
                    radius={8}
                    weight={2}
                    fillColor={color.checkPoint}
                    fillOpacity={1}
                >
                    <CircleMarker
                        latlng={[lat, lon]}
                        radius={4}
                        weight={2}
                        fillColor="#fff"
                        fillOpacity={1}
                    >
                        <ToolTip
                            offset={[0, -5]}
                            direction="top"
                        >
                            <div>
                                <div><b>{/*titles.NAME*/}{isStation ? titles.STATION : titles.CHECK_POINT}:</b> {name || messages.INFO_IS_NOT_FOUND}</div>
                                {isStation && (<div><b>{titles.DIRECTION}:</b> {direction || messages.INFO_IS_NOT_FOUND}</div>)}
                            </div>
                        </ToolTip>
                    </CircleMarker>
                </CircleMarker>
            );
        });
    }, [stations]);

    return (
        <div className="map-form-wrap__container">
            <Map
                center={mapCenter}
                zoom={mapZoom}
                bounds={bounds}
            >
                <MapControl>
                    <FeatureGroup>
                        {checkPointsMarkers}
                    </FeatureGroup>
                    <RouteJson
                        onMap={false}
                        centerAfter={true}
                        item={currentRoute}
                        key={currentRoute.id}
                        disabled
                    />
                </MapControl>
            </Map>
        </div>
    );
};

export default MapForm;
