import moduleName from './module';
const prefix = `${moduleName}`;

export const GET_LIST = `${prefix}/GET_LIST`;
export const LIST_LOADED = `${prefix}/LIST_LOADED`;
export const LIST_LOADING = `${prefix}/LIST_LOADING`;

export const LOAD_STATUSES = `${prefix}/LOAD_STATUSES`;
export const LOADED_STATUSES = `${prefix}/LOADED_STATUSES`;
export const LOADING_STATUSES = `${prefix}/LOADING_STATUSES`;
