import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadInfrastructure, createInfrastructure } from '../../../../../redux/RoadNetwork/actions';
import { roadNetworksSelectors } from '../../../../../redux/RoadNetwork';
import { LSContainer } from '../../../../common/List';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Modal from './Modal';
import Item from './Item';
import Filter from './Filter';
import Context from '../../../../../helpers/context';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import titles from '../../../../../helpers/constants/titles';
import PageLayout from '../../../../layout/PageLayout';
import NoData from '../../../../common/Information/NoData';

const Infrastructure = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const infrastructureData = useSelector(roadNetworksSelectors.infrastructureData);
    const infrastructureMeta = useSelector(roadNetworksSelectors.infrastructureMeta);
    const loadingInfrastructure = useSelector(roadNetworksSelectors.loadingInfrastructure);

    const [openAddModal, setOpenAddModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = () => dispatch(loadInfrastructure(params.page, limit, params.data));

    useEffect(() => {
        dispatch(loadInfrastructure(params.page, limit, params.data));
    }, [params, limit, dispatch]);

    const onSaveData = (data) => {
        const newData = removeEmptyFields(data);
        dispatch(createInfrastructure(newData, () => {
            setParams({ page: 1, data: {} });
            setOpenAddModal(false);
        }));
    };

    const renderContent = () => {
        return (
            <>
                {infrastructureData?.length > 0 ? (
                    <LSContainer headers={[
                        {
                            title: titles.NAME,
                            width: '20%'
                        },
                        {
                            title: titles.TYPE,
                            width: '20%'
                        },
                        {
                            title: titles.ORGANIZATION,
                            width: '20%'
                        },
                        {
                            title: titles.DESCRIPTION,
                            width: '20%'
                        },
                        {
                            title: 'Действия',
                            align: 'right',
                            isActions: true
                        }
                    ]}>
                        {infrastructureData.map((el) => (
                            <Item el={el} key={el.id} reloadList={reloadList} />
                        ))}
                    </LSContainer>
                ) : (
                    !loadingInfrastructure && <NoData/>
                )}
            </>
        );
    };

    return (
        <>
            <PageLayout
                header="Объекты инфраструктуры"
                filters={<Filter setParams={setParams} />}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenAddModal(true),
                                    disabled: !permissions?.is_create,
                                },
                            ]}
                            noPadding
                        />
                    ),
                    total: infrastructureMeta?.total,
                }}
                loading={loadingInfrastructure}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: infrastructureMeta,
                    limit,
                    setLimit,
                }}
            />

            {openAddModal && (
                <Modal
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew
                    onAcceptModal={onSaveData}
                />
            )}
        </>
    );
};

export default Infrastructure;
