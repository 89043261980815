import { useMemo } from 'react';

import { loadVehicleCategories } from 'redux/TransportPassenger/actions';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';

import type { ClassNameMap } from '@mui/styles';
import type { Checkpoint, TransportType } from '../types';

interface RenderCategoryProps {
    item: Checkpoint;
    styles: ClassNameMap<'item' | 'item_text' | 'row'>;
}

const Category = ({ item, styles }: RenderCategoryProps) => {
    const transportTypes: TransportType[] = useStoreProp(
        loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    );

    const currentCategory = useMemo(
        () => transportTypes.find((type) => type.id === item.route?.category_id),
        [item.route?.category_id, transportTypes]
    );

    return (
        <span className={styles.item} style={{ wordBreak: 'break-word' }}>
            {currentCategory?.name || messages.NO_VALUE}
        </span>
    );
};

export default Category;
