import moduleName from './module';
const prefix = `${moduleName}`;

// Constants

export const LOAD_GOVERNMENT_CONTRACTS = `${prefix}/LOAD_GOVERNMENT_CONTRACTS`;
export const LOADED_GOVERNMENT_CONTRACTS = `${prefix}/LOADED_GOVERNMENT_CONTRACTS`;
export const LOADING_GOVERNMENT_CONTRACTS = `${prefix}/LOADING_GOVERNMENT_CONTRACTS`;
export const LOAD_GOVERNMENT_CONTRACT_BY_ID = `${prefix}/LOAD_GOVERNMENT_CONTRACT_BY_ID`;
export const LOADED_GOVERNMENT_CONTRACT_BY_ID = `${prefix}/LOADED_GOVERNMENT_CONTRACT_BY_ID`;
export const LOADING_GOVERNMENT_CONTRACT_BY_ID = `${prefix}/LOADING_GOVERNMENT_CONTRACT_BY_ID`;
export const CREATE_GOVERNMENT_CONTRACTS = `${prefix}/CREATE_GOVERNMENT_CONTRACTS`;
export const EDIT_GOVERNMENT_CONTRACTS = `${prefix}/EDIT_GOVERNMENT_CONTRACTS`;
export const DELETE_GOVERNMENT_CONTRACTS = `${prefix}/DELETE_GOVERNMENT_CONTRACTS`;
export const LOAD_GOVERNMENT_CONTRACT_HISTORY = `${prefix}/LOAD_GOVERNMENT_CONTRACT_HISTORY`;
export const LOADED_GOVERNMENT_CONTRACT_HISTORY = `${prefix}/LOADED_GOVERNMENT_CONTRACT_HISTORY`;
export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;

export const CREATE_CONTRACT_ENTITIES_BY_NAME = `${prefix}/CREATE_CONTRACT_ENTITIES_BY_NAME`;
export const LOAD_CONTRACT_ENTITIES_BY_NAME = `${prefix}/LOAD_CONTRACT_ENTITIES_BY_NAME`;
export const LOADING_CONTRACT_ENTITIES_BY_NAME = `${prefix}/LOADING_CONTRACT_ENTITIES_BY_NAME`;
export const LOAD_CONTRACT_ENTITIES_TYPES = `${prefix}/LOAD_CONTRACT_ENTITIES_TYPES`;
export const LOADED_CONTRACT_ENTITIES_TYPES = `${prefix}/LOADED_CONTRACT_ENTITIES_TYPES`;

export const LOAD_CONTRACT_LINK_OBJECTS = `${prefix}/LOAD_CONTRACT_LINK_OBJECTS`;
export const LOADING_CONTRACT_LINK_OBJECTS = `${prefix}/LOADING_CONTRACT_LINK_OBJECTS`;
export const LOADED_CONTRACT_LINK_OBJECTS = `${prefix}/LOADED_CONTRACT_LINK_OBJECTS`;
