import { useState } from 'react';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { Check } from '@mui/icons-material';
import { theme } from 'App';

import { humanDateTimeWithoutTZ } from 'helpers/date.config';
import { usePermissions } from 'helpers/hooks/Permissons';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import { transportIncidentsURI } from './moduleConfig';
import OperationStatusModal from './OperationStatusModal';

interface Operation {
    operation_id: number
    position: number
    start_date?: string
    end_date?: string
    comment?: string
    is_done: boolean
    operation: {
        name: string
    }
    organization?: {
        title: string
    }
}

interface IncidentOperationsProps {
    operations: Operation[]
    readOnly: Boolean
}

const IncidentOperations = ({ operations, readOnly = false }: IncidentOperationsProps) => {
    const access = usePermissions().actions;
    const [modalOpen, setModalOpen] = useState<null | number>(null);

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} locale={'ru'}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>№</TableCell>
                                <TableCell>Операция</TableCell>
                                <TableCell>Ответственный</TableCell>
                                <TableCell>Плановое окончание</TableCell>
                                <TableCell>Фактическое окончание</TableCell>
                                <TableCell>Комментарий</TableCell>
                                {!readOnly && <TableCell>Статус</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {operations.map(({
                                position,
                                operation_id,
                                operation,
                                organization,
                                start_date,
                                end_date,
                                comment,
                                is_done
                            }) => (
                                <TableRow
                                    hover
                                    key={operation_id}
                                >
                                    <TableCell>{position + (!operations[0]?.position ? 1 : 0)}</TableCell>
                                    <TableCell>{operation?.name}</TableCell>
                                    <TableCell>{organization?.title}</TableCell>
                                    <TableCell>{start_date && humanDateTimeWithoutTZ(start_date)}</TableCell>
                                    <TableCell>{end_date && humanDateTimeWithoutTZ(end_date)}</TableCell>
                                    <TableCell>{comment}</TableCell>
                                    <TableCell>
                                        {readOnly
                                            ? is_done
                                                ? <Tooltip title="Выполнен"><Check color="secondary"/></Tooltip>
                                                : null
                                            : <FormButtonsComponent
                                                buttons={[
                                                    ...(is_done
                                                        ? [
                                                            {
                                                                icon: <Check color="secondary"/>,
                                                                name: 'Выполнен',
                                                                onClick: () => setModalOpen(position - 1),
                                                                disabled: !access['is_update']
                                                            },
                                                        ]
                                                        : [
                                                            {
                                                                ...buttonsTypes.editIcon,
                                                                name: 'Отметка о выполнении',
                                                                onClick: () => setModalOpen(position - 1),
                                                                style: { fontSize: '0.875rem' }
                                                            },
                                                        ]
                                                    )
                                                ]}
                                                uri={transportIncidentsURI}
                                                noMarginLeft
                                                justButton
                                                showDisabled
                                            />
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {modalOpen !== null && (
                        <OperationStatusModal
                            onClose={() => setModalOpen(null)}
                            data={operations[modalOpen]}
                        />
                    )}
                </ThemeProvider>
            </StyledEngineProvider>

        </LocalizationProvider>
    );
};

export default IncidentOperations;
