import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { incidentsSelectors } from 'redux/Incidents';
import { loadReportMonthAreas } from 'redux/Incidents/actions';
import PageLayout from 'components/layout/PageLayout';
import { dateWithDashYYYYMMDD, getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import messages from 'helpers/constants/messages/common';

import Filters from './Filters';
import Graph from './Graph';
import styles from './dtpGraph.module.scss';

const DtpGraph = () => {
    const dispatch =  useDispatch();

    const reportMonthAreas = useSelector(incidentsSelectors.reportMonthAreas);
    const loadingReportMonthAreas = useSelector(incidentsSelectors.loadingReportMonthAreas);

    const initialFilter = {
        start_date: getDateWithDuration({ month: -6 }, getStartOf('day')),
        end_date: getEndOf('day'),
        type_load: ['2']
    };

    const [filterValues, setFilterValues] = useState(initialFilter);

    const onSearch = (filter = filterValues) => {
        const result =  removeEmptyFields({
            ...filter,
            start_date: dateWithDashYYYYMMDD(filter?.start_date),
            end_date: dateWithDashYYYYMMDD(filter?.end_date),
        });

        dispatch(loadReportMonthAreas(result));
    };

    return (
        <PageLayout
            header="Отчет по районам (по месяцам)"
            filters={<Filters
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                initialFilter={initialFilter}
                onSearch={onSearch}
            />}
            content={() => (<div className={styles.dtp_graph}>
                {Object.keys(reportMonthAreas).length !== 0 && <Graph data={reportMonthAreas} />}
                {(!loadingReportMonthAreas && Object.keys(reportMonthAreas).length === 0)
                        && <div>{messages.NO_DATA_FOR_PERIOD}</div>
                }
            </div>)}
            loading={loadingReportMonthAreas}
        />
    );
};

export default DtpGraph;
