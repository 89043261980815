import React from 'react';
import { CircularProgress, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import img from './img/loading.svg';
import messages from '../../../helpers/constants/messages';
import './style.scss';

const useStyles = makeStyles({
    linear: {
        padding: '0'
    },
    circular: {
        zIndex: 100,
        width: '40px', // 2%
        height: '40px', // 3%
        margin: 'auto',
        position: 'absolute',
        top: 0, left: 0, bottom: 0, right: 0,
    }
});

// прелоадер
const Loading = ({ size = 40, className = '', linear = false, circular = false }) => {
    const classes = useStyles();
    return (
        <div className={`loading_content ${className}`}>
            {linear || circular
                ? (<>
                    {linear && <div className={classes.linear}><LinearProgress/></div>}
                    {circular && <div className={classes.circular}><CircularProgress/></div>}
                </>)
                : (<img
                    src={img}
                    alt={messages.LOADING}
                    className="loading_svg"
                    style={{ width: `${size}px` }}
                />)
            }
        </div>
    );
};

export default Loading;
