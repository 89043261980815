import React, { useEffect, useRef, useState } from 'react';
import SidebarItem from '../../../SidebarItem';
import { Scrollbars } from 'react-custom-scrollbars';
import * as actions from '../../../../../redux/SituationalPlans/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../common/Loading';
import Item from './Item';
import titles from '../../../../../helpers/constants/titles';
import messages from '../../../../../helpers/constants/messages';
import SidebarTitle from '../../../SidebarTitle';
import Icon from '../icons/icon';
import { getColorByStatus } from '../helper';
import buttons from '../../../../../helpers/constants/buttons';
import SidebarFilter from '../../../SidebarFilter';
import FilterForm from './Filter';
import getFilters from '../../../helpers/getFilters';
import { getFilter } from '../../Videoanalytics/helper';
import { isEqual } from 'lodash';
import { usePrevious } from '../../../../../helpers/hooks';
import { SituationalPlansSelectors } from '../../../../../redux/SituationalPlans';
import config from '../config';


const SideBar = (props) => {
    const dispatch = useDispatch();
    // const [page, setPage] = useState(1);
    // const [limit, setLimit] = useState(10);

    const scrollRef = useRef(null);

    const list = useSelector(SituationalPlansSelectors.sidebarEvents);
    const loading = useSelector(SituationalPlansSelectors.sidebarLoadingEvents);
    const active = useSelector(SituationalPlansSelectors.activeEvents);
    const saved = useSelector(SituationalPlansSelectors.savedEvents);
    const filtersEvents = useSelector(SituationalPlansSelectors.filtersEvents);
    const eventsProps = useSelector(SituationalPlansSelectors.eventsProps);

    useEffect(() => {
        return () => {
            dispatch(actions.setEventsProps({
                ...eventsProps,
                page: 1,
            }));
        };
    }, []);

    const prevFiltersEvents = usePrevious(filtersEvents);

    const activeId = Object.keys(active).length > 0 ? active.id : 0;

    const scroolTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    const fetchList = () => {
        const filter = getFilters(filtersEvents, getFilter);
        const { page, limit } = eventsProps;
        dispatch(actions.loadSidebarEvents(
            page,
            limit,
            filter
        ));
    };

    const handleScrollUpdate = (values) => {
        const {
            //current_page = 0,
            last_page = 0
        } = list?.meta;
        if (
            values.top > 0.98
            && loading === false
            && eventsProps.page < last_page
        ) {
            dispatch(actions.setEventsProps({
                ...eventsProps,
                page: eventsProps.page + 1
            }));
        }
    };

    useEffect(() => {
        if (saved) {
            scroolTop();
            if (eventsProps.page > 1) {
                dispatch(actions.setEventsProps({
                    ...eventsProps,
                    page: 1
                }));
            } else {
                fetchList();
            }
        }
    }, [saved]);

    // отслеживаем изменение проекта
    useEffect(() => {
        if (!isEqual(filtersEvents, prevFiltersEvents)) {
            scroolTop();
            dispatch(actions.setEventsProps({
                ...eventsProps,
                page: 1
            }));
        }
        fetchList();
    }, [eventsProps, filtersEvents]);


    const renderList = (dataList) => (
        <>
            {dataList?.map((item) => {
                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        icon={
                            Icon({
                                fill: getColorByStatus(item.type_id)
                            })
                        }
                        buttons={{
                            edit: {
                                title: buttons.EDIT,
                                icon: <i className="fas fa-pen" />,
                                onClick: () => {
                                    // редактирование
                                    dispatch(actions.setEditFormEvents(item));
                                }
                            },
                            delete: {
                                title: buttons.DELETE,
                                icon: <i className="fas fa-trash-alt" />,
                                onClick: () => {
                                    // удаление
                                    dispatch(actions.setDeleteFormEvents(item.id));
                                }
                            }
                        }}
                        onClick={() => {
                            dispatch(actions.setActiveEvents(item));
                        }}
                    >
                        <Item
                            {...item}
                        />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filtersEvents}
                resetListPage={() => dispatch(actions.setEventsProps({
                    ...eventsProps,
                    page: 1
                }))}
                onSetFilter={(filter) => dispatch(actions.setFiltersEvents(filter))}
                onClearFilter={() => dispatch(actions.clearFiltersEvents())}
                content={({ data, onChange }) =>
                    <FilterForm
                        data={data}
                        onChange={onChange}
                    />
                }
                layer={config.slug}
            />

            {loading && list?.data?.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                    ref={scrollRef}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            // title={titles.TRANSPORT_INCIDENTS}
                            title={'Объекты мероприятий'}
                            list={list}
                        />
                        {
                            list.data.length === 0
                                ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                                : renderList(list?.data)
                        }
                    </div>
                    {loading && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
};

export default SideBar;
