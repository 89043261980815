import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { loadWaybillsLicence, loadWaybillsList } from 'redux/TransportPassenger/actions';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import removeEmptyFields from 'helpers/removeEmptyFields';

import WaybillsFilter from './WaybillsFilter';
import WaybillsItem from './WaybillsItem';
import ModalForm from './ModalForm';

const Waybills = () => {
    const dispatch = useDispatch();

    const waybillsData = useSelector(transportPassengerSelectors.waybillsData);
    const waybillsMeta = useSelector(transportPassengerSelectors.waybillsMeta);
    const loadingWaybills = useSelector(transportPassengerSelectors.loadingWaybills);

    const licence = useStoreProp(loadWaybillsLicence, 'transportPassenger', 'waybills_licence');

    const [params, setParams] = useState({
        page: 1,
        data: {
            date_start: getStartOf('year'),
            date_end: getEndOf('minute')
        }
    });
    const [openAddModal, setOpenAddModal] = useState(false);
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);

    useEffect(() => {
        const paramsWithoutEmpty = removeEmptyFields({
            ...params.data,
            date_start: fullDateTimeWithTimeZone( params.data.date_start),
            date_end: fullDateTimeWithTimeZone( params.data.date_end),
            route_id:  params.data.route_id?.id || '',
            organization_id:  params.data.organization_id?.id || '',
            work_order_id:  params.data.work_order_id?.id || ''
        }, false);
        dispatch(loadWaybillsList(params.page, limit, paramsWithoutEmpty));
    }, [dispatch, limit, params.page, params.data]);

    const renderContent = () => {
        return waybillsData?.length > 0
            ? (<>
                <LSContainer
                    headers={[
                        { title: '№', width: '17%' },
                        { title: 'Название', width: '17%' },
                        { title: '№ маршрута', width: '17%' },
                        { title: 'Дата начала', width: '17%' },
                        { title: 'Дата окончания', width: '17%' },
                        { title: 'Действия', align: 'right', isActions: true }
                    ]}
                >
                    {waybillsData?.map((item) =>
                        <WaybillsItem
                            key={item.id}
                            item={item}
                            licence={licence}
                        />)
                    }
                </LSContainer>
            </>)
            : (!loadingWaybills && <div>{messages.DATA_IS_NOT_FOUND_NEW}</div>);

    };

    return (
        <>
            {openAddModal && (
                <ModalForm
                    isNew={true}
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    licence={licence}
                />
            )}
            <PageLayout
                header="Путевые листы"
                filters={<WaybillsFilter
                    params={params}
                    licence={licence}
                    setParams={setParams}
                />}
                loading={loadingWaybills}
                content={renderContent}
                paginationProps={{
                    loadList: (page, limit) => dispatch(loadWaybillsList(page, limit, params)),
                    list: waybillsMeta,
                    limit,
                    setLimit: setLimit,
                }}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtons
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenAddModal(true),
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>
                    ),
                    total: waybillsMeta?.total
                }}
            />
        </>
    );
};

export default Waybills;
