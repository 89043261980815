import { useEffect } from 'react';
import { Collapse, Link, List, ListItem, ListItemText,  } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import titles from 'helpers/constants/titles';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import messages from 'helpers/constants/messages/common';
import { stringifyRenderData } from 'helpers/stringifyRenderData';
import Loading from 'components/common/Loading';
import Attachments from 'components/common/Upload/Attachments';
import { loadCurrentFiliationList } from 'redux/Companies/actions';
import { companiesSelectors } from 'redux/Companies';

const Info = ({ isOpen, item }) => {
    const dispatch = useDispatch();

    const currentFiolations = useSelector(companiesSelectors.currentFiolations);
    const loadingCurrentFiliations = useSelector(companiesSelectors.loadingCurrentFiliations);

    const classes = ['postal_code', 'region', 'city_name', 'street', 'house'];
    const getAddress = (address) => {
        return classes.reduce((acc, cur, idx, src) => {
            if (!address[cur]) return acc;
            const end = src.length - 1 === idx ? '' : ', ';
            return acc + address[cur] + end;
        }, '');
    };

    const phones = stringifyRenderData(item?.phones);
    const emails = stringifyRenderData(item?.emails);
    const juristic_address = getAddress(item.juristic_address || {});
    const mail_address = getAddress(item?.mail_address || {});
    const real_address = getAddress(item?.real_address || {});

    useEffect(() => {
        dispatch(loadCurrentFiliationList(1, 25, { organization_id: item?.id }));
    }, []);

    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit className="info">
            {item?.inn && <div><b>{titles.INN}</b>: {item?.inn}</div>}
            {item?.phones && phones && <div><b>{titles.PHONES}</b>: {phones}</div>}
            {item?.emails && emails && <div><b>{titles.EMAIL}</b>: {emails}</div>}
            {juristic_address && <div><b>{titles.LEGAL_ADDRESS}</b>: {juristic_address}</div>}
            {mail_address && <div><b>{titles.MAIL_ADDRESS}</b>: {mail_address}</div>}
            {real_address && <div><b>{titles.REAL_ADDRESS}</b>: {real_address}</div>}
            {item?.attachments?.length > 0
                && <Attachments
                    readOnly
                    files={item?.attachments}
                    renderContent={(el) => (
                        <div style={{ fontSize: '1rem' }}>
                            {el?.title && <div><b>Название: </b>{el?.title}</div>}
                            {el?.author && <div><b>Автор: </b>{el?.author} </div>}
                            {el?.date && <div><b>Дата создания: </b>{dateWithDashYYYYMMDD(el?.date)}</div>}
                            <Link href={el?.file?.public_link} target="_blank"><i className="fal fa-file-alt"/> {el?.file?.name}</Link>
                        </div>
                    )}
                />
            }
            <b>{titles.BRANCHES}: </b>
            {loadingCurrentFiliations && <Loading linear={true}/>}
            <List className="list">
                {currentFiolations[item?.id]?.length > 0
                    ? currentFiolations[item?.id].map(filiation =>
                        <ListItem className="list_item" key={filiation?.id}>
                            <ListItemText>
                                <div>
                                    <b>{titles.NAME}: </b> {filiation?.title}
                                </div>
                                <div>
                                    <b>{titles.ADDRESS}: </b> {getAddress(filiation?.address || {})}
                                </div>
                                <div>
                                    <b>{titles.PHONES}: </b>
                                    {stringifyRenderData(filiation?.phones)}
                                </div>
                                <div>
                                    <b>{titles.EMAIL}: </b>
                                    {stringifyRenderData(filiation?.emails)}
                                </div>
                                <div>
                                    <Link href={filiation?.appointment} target="_blank">
                                        <i className="fal fa-file-alt"/>  Доверенность
                                    </Link>
                                </div>
                            </ListItemText>
                        </ListItem>
                    )
                    : (!loadingCurrentFiliations && messages.INFO_IS_NOT_FOUND)
                }
            </List>
        </Collapse>
    );
};

export default Info;
