import React from 'react';
import { Collapse, List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import messages from '../../../../../helpers/constants/messages';
import { useStoreProp } from '../../../../../helpers/hooks';
import {
    loadStageCargoTypes,
    loadStagesStatuses
} from '../../../../../redux/RoadWorks/actions';
import StageOrdersInfo from './StageOrdersInfo';

const useStyles = makeStyles({
    wrap: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    btn: {
        marginTop: 20
    }
});

const StageOrders = ({ isOpen, currentStages = [] }) => {
    const classes = useStyles();

    const statuses = useStoreProp(loadStagesStatuses, 'roadworks', 'stagesStatuses');
    const stageCargoTypes = useStoreProp(loadStageCargoTypes, 'roadworks', 'stageCargoTypes');

    return (
        <>
            <Collapse in={isOpen} timeout={'auto'} unmountOnExit className="info">
                <div className={classes.wrap}>
                    <h2>Этапы выполнения: </h2>
                    {currentStages.length > 0
                        ? <List className="list">
                            {currentStages.sort((a, b) => a.number - b.number).map((item) =>
                                <StageOrdersInfo
                                    key={item?.id}
                                    item={item}
                                    statuses={statuses}
                                    stageCargoTypes={stageCargoTypes}
                                    currentStages={currentStages}
                                />)}
                        </List>
                        : messages.DATA_IS_NOT_FOUND
                    }
                </div>
            </Collapse>
        </>
    );
};

export default StageOrders;