import { useSelector } from 'react-redux';
import { IReports } from './types';
import { ReportItem } from './ReportItem';
import titles from '../../../../../helpers/constants/titles';
import { videoAnalyticsSelectors } from '../../../../../redux/VideoAnalytics';

export const Reports = () => {
    const { inReport, outReport } = useSelector<any, IReports>(videoAnalyticsSelectors.inOutCamerasReport);

    return (
        <>
            {inReport
                && <ReportItem
                    report={inReport}
                    label={titles.CAMERAS_IN}
                />
            }

            {outReport
                && <ReportItem
                    report={outReport}
                    label={titles.CAMERAS_OUT}
                />
            }
        </>
    );
};