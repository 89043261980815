import { useState } from 'react';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import SelectTemplate from './SelectTemplate';

const Filter = ({ setCurrentTemplate }) => {
    const [data, setData] = useState({});

    const handleReset = () => {
        setData({});
        setCurrentTemplate({});
    }; 

    return (
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">
                <SelectTemplate
                    selected={data}
                    onChange={setData}
                    label="Шаблоны"
                />
            </div>
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.reset,
                            onClick: handleReset
                        },
                        {
                            ...buttonsTypes.show,
                            onClick: () => setCurrentTemplate(data)
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;
