import Filter from './Filter';

const MonthlyReportCPVF = () => {
    return (
        <>
            <h1>Отчет по времени работы КФВФ за месяц (в часах)</h1>
            <Filter />
        </>
    );
};

export default MonthlyReportCPVF;