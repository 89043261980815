import L from 'leaflet';
import { useRef, useState } from 'react';

// хук для сборки bounds
const useBounds = () => {
    const points = useRef([]);
    const [change, setChange] = useState(0);

    return {
        index: change,
        getBounds: points.current.length > 0 ? new L.LatLngBounds(points.current) : null,
        extendBounds: (bounds) => {
            points.current.push(bounds.getSouthWest());
            points.current.push(bounds.getNorthEast());
            setChange(i => i < 100 ? i + 1 : 0);
        },
        extendPoint: (point) => {
            points.current.push(point);
        },
        clear: () => points.current = [],

    };
    
};

export default useBounds;