import cn from 'classnames';

import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';

import PageReportFields from './PageReportFields';
import style from './getReport.module.scss';

import { useReportTemplatesFrontendLinks } from './index';

// новый компонент для вывода иконки и формы формирования отчета на странице по url
// (список отчетов берется из метода)
const PageReportBlock = ({
    filter = {}, // фильтр отчета
    urlTemplate = null, // url ключ шаблонов (иначе текущий url)
    onClose = null, // закрытие (для модалки)
    isOneTypeAuto = false,
    noPadding = false,
    test_id_prefix = '',
}) => {
    const templateProvider = useReportTemplatesFrontendLinks();
    console.log('templateProvider', templateProvider);
    const reportFilter = templateProvider.filter;

    const handleClose = () => {
        // обнуление
        reportFilter.clear();
        // закрыть модалку
        onClose?.();
    };

    const handleDownload = () => {
        const {
            url,
            formats,
        } = reportFilter.getToApi();

        if (url && formats?.length > 0) {
            // запрос
            // todo обдумать закрытие по выполнению метода
            templateProvider.loadReport(
                url,
                formats,
                filter
            );

            handleClose();
        }
    };

    return (
        <>
            {templateProvider.loading
                && (
                    <Loading className="fill"/>
                )
            }
            <div
                className={cn({
                    [style.block]: true,
                    [style.noPadding]: noPadding,
                })}
            >
                <div className="row noMargin">
                    <PageReportFields
                        reportType={reportFilter.type}
                        reportFormats={reportFilter.formats}
                        onReportType={reportFilter.setType}
                        onReportFormat={reportFilter.setFormats}
                        urlTemplate={urlTemplate}
                        isOneTypeAuto={isOneTypeAuto}
                        test_id_prefix={test_id_prefix}
                    />

                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.getReport,
                                onClick: handleDownload,
                                disabled: reportFilter.isDisabled,
                            }
                        ]}
                        positionLeft
                        noPadding
                        noMarginLeft
                        justButton
                    />
                </div>
            </div>
        </>
    );
};

export default PageReportBlock;
