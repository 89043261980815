import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadUnits, loadWorks } from 'redux/RoadWorks/actions';
import UniversalSelectNew from 'components/common/UniversalSelect/index-new';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const UnitsOfMeausurement = ({
    multiple = true,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label = 'Единицы измерения',
    error = false,
    helperText = '',
    filter = {},
    sortedList,
    test_id_prefix
}) => {
    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const [meta, setMeta] = useState();
    const [showList, setShowList] = useState(false);

    const autocompleteChange = (e, value) => {
        if (e?.key !== 'Enter') onChange(value);
    };

    useEffect(() => {
        if (sortedList?.length > 0) {
            setList(sortedList);
        }
    }, [sortedList]);

    const getUnits = (params) => {
        const { page, limit, query } = params;
        if (filter.id) {
            dispatch(loadWorks({
                page,
                limit,
                id_list: [filter.id],
                ...(query && { query })
            },
            (value) => {
                const newlist = value[0]?.unit_of_measurement_list || [];
                setList(newlist);
            }
            ));
        } else {
            dispatch(loadUnits(
                page,
                limit,
                { ...(query && { query }) },
                ({ data, meta }) => {
                    setList(data);
                    setMeta(meta);
                }
            ));
        }
    };

    const handleClose = () => setShowList(false);

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={autocompleteChange}
                error={error}
                helperText={helperText}
                required={required}
                disabled={disabled}
                onReset={() => onChange(multiple ? [] : {})}
                renderLabel={(option) => option?.short_name || ''}
                inputName="units"
                label={label}
                onShowList={() => setShowList(true)}
                test_id_prefix={test_id_prefix}
                isOpen={showList}
            />
            {showList
                && <UniversalSelectNew
                    list={list}
                    multiple={multiple}
                    fetchList={sortedList.length === 0 && getUnits}
                    sortedList={sortedList}
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selected}
                    renderProps={(el) => <div>{el?.short_name}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    size="30%"
                    title={label}
                    meta={meta}
                />
            }
        </>
    );
};

export default UnitsOfMeausurement;
