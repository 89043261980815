import React from 'react';
import { useSelector } from 'react-redux';
import { incidentsSelectors } from '../../../../../redux/Incidents';

const TypeValue = (props) => {

    const types = useSelector(incidentsSelectors.dtpTypesData);

    const id = props.id || 0;

    const getValue = () => {
        const currentItem = types.find(({ id: itemId }) => itemId === id);
        return currentItem ? currentItem.name : '';
    };

    return (
        <>
            {getValue()}
        </>
    );
};

export default TypeValue;
