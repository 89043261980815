// import { clearFiltersRoadSection } from '../../../../redux/RoadNetwork/actions';

const config = {
    name: 'Перекрытие движения',
    slug: 'overlap',
    // onAfterActivateAction: () => clearFiltersRoadSection(),
    layerIcon: 'fas fa-do-not-enter',
};
// добавление на карте
export default config;
