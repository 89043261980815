import React, { useState } from 'react';
import {
    Map,
    FeatureGroup,
    GeoJson,
} from '../../../../../../MapComponents/leaflet';
import { config } from '../../../../../../../config';
import NoMap from './NoMap';

const MapComponent = ({ data = {} }) => {
    const { geometry } = data;

    const [mapCenter] = useState(config.get('mapCenter'));

    if (!geometry.type) {
        return <NoMap />;
    }

    return (
        <Map zoom={18} center={mapCenter} className="map-container small">
            <FeatureGroup>
                {Object.keys(geometry).length && (
                    <GeoJson data={geometry} centerAfter={true}/>
                )}
            </FeatureGroup>
        </Map>
    );
};

export default MapComponent;
