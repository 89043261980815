import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';

import { deleteRouteRequest } from 'redux/TransportPassenger/actions';
import { fullDateTime } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import {
    ActionMore,
    ActionsButtons,
    LSContentColumn,
    LSContentItem
} from 'components/common/List';

import RRItemInfo from './RRItemInfo';
import RRModal from './RRModal';



const RRItem = (
    {
        el,
        statuses,
        tsTypes,
        transportTypes,
        permissions
    }) => {
    const dispatch = useDispatch();

    const [isOpenCollapse, setOpenCollapse] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleConfirmDelete = () => {
        dispatch(deleteRouteRequest(el.id));
        setOpenDeleteModal(false);
    };

    const handleOpenEditModal = (e) => {
        e.stopPropagation();
        setOpenEditModal(true);
    };

    const handleOpenDeleteModal = (e) => {
        e.stopPropagation();
        setOpenDeleteModal(true);
    };

    const handleOpenCollapse = (e) => {
        e.stopPropagation();
        setOpenCollapse(!isOpenCollapse);
    };

    const getStatusColor = (status) => {
        switch(status) {
            case 1:
                return '#ff66ff';
            case 2:
                return '#3333cc';
            case 3:
                return '#33CC33';
            case 4:
                return '#FF0000';
            default:
                return '';
        }
    };
    
    const circleStyle = {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        backgroundColor: getStatusColor(el.status),
    };

    return (
        <>
            <LSContentItem
                onClick={handleOpenCollapse}
            >
                <LSContentColumn>
                    {
                        statuses[el.status]
                            ? <Tooltip title={statuses[el.status]}>
                                <div style={circleStyle}></div>
                            </Tooltip> 
                            : titles.NO_DATA
                    }
                </LSContentColumn>
                <LSContentColumn>
                    {el.name || titles.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {fullDateTime(el.created_at) || titles.NO_DATA}
                </LSContentColumn>
                <LSContentColumn align="right" isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: handleOpenEditModal,
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: handleOpenDeleteModal,
                            },
                        ]}
                    />
                    <ActionMore
                        isOpen={isOpenCollapse}
                        onClick={handleOpenCollapse}
                    />
                </LSContentColumn>
            </LSContentItem>
            <RRItemInfo isOpen={isOpenCollapse} el={el}/>

            {openEditModal
            && (<RRModal
                isNew={false}
                onClose={() => setOpenEditModal(false)}
                isOpen={openEditModal}
                el={el}
                statuses={statuses}
                tsTypes={tsTypes}
                transportTypes={transportTypes}
            />)}

            {openDeleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteModal}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default RRItem;
