import { useEffect, useRef, useState } from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { isEqual } from 'lodash';
import {
    IconButton,
    Tooltip,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import makeStyles from '@mui/styles/makeStyles';

import { videoAnalyticsSelectors } from 'redux/VideoAnalytics';
import {
    clearEventsPopup,
    getEventsPopup,
    loadCameraStatuses,
} from 'redux/VideoAnalytics/actions';
import Loading from 'components/common/Loading';
import getFilters from 'components/MapComponents/helpers/getFilters';
import FrameModal from 'components/common/FrameVideoModal/FrameModal';
import colorExtend from 'helpers/mapHelper/colorExtend';
import { usePrevious, useStoreFromSelectorListToObject } from 'helpers/hooks';
import messages from 'helpers/constants/messages';

import icon from '../icons/icon';
import { getFilter } from '../helper';

import Events from './Events';

const useStyles = makeStyles((theme) => ({
    icon: {
        fill: '#fff'
    },
}));

const PopUp = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const loading = useSelector(videoAnalyticsSelectors.loadingPopup);
    const eventsPopup = useSelector(videoAnalyticsSelectors.eventsPopup);
    const filters = useSelector(videoAnalyticsSelectors.filters);
    const polygon = useSelector(videoAnalyticsSelectors.polygon);

    const data = polygon.find(({ id }) => props.uuid === id) || {};
    const { name, status_color, status, id, url_player } = data;
    const [isOpen, setIsOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(40);
    const [openFrameModal, setOpenFrameModal] = useState(false);
    const prevLoading = usePrevious(loading);
    const scrollRef = useRef(null);
    // фильтр
    const filter = getFilters(filters, getFilter);
    const prevFilter = usePrevious(filter);

    const statusesOb = useStoreFromSelectorListToObject(loadCameraStatuses, videoAnalyticsSelectors.statuses);

    // useEffect(() => {
    //     if (page > 1) {
    //         dispatch(getEventsPopup(page, limit, { camera_id: id, ...filter }, true));
    //     }
    // }, [page]);

    useEffect(() => {
        dispatch(getEventsPopup(page, limit, { ...filter, camera_id_list: [id], is_map: true }, true));
        return () => {
            dispatch(clearEventsPopup());
        };
    }, []);

    useEffect(() => {
        if (prevLoading === true && loading === false) {
            setIsOpen(true);
        }
    }, [loading]);

    useEffect(() => {

        if (!isEqual(filter, prevFilter)) {
            if (scrollRef.current) scrollRef.current.scrollTop(0);
            setPage(1);
            dispatch(getEventsPopup(1, limit, { ...filter, camera_id_list: [id], is_map: true }));
        }
    }, [filter, id, dispatch, limit, prevFilter]);

    const total = (eventsPopup.meta && eventsPopup.meta.last_page) || 0;

    const scrollHandleUpdate = (values) => {
        if (values.top > 0.85 && loading === false) {
            if (total > 0 && page < total) {
                setPage(page + 1);
                dispatch(getEventsPopup(page + 1, limit, { ...filter, camera_id_list: [id], is_map: true }, true));
            }
        }
    };

    const handleRefresh = (e) => {
        e.stopPropagation();
        setPage(1);
        setIsOpen(false);
        dispatch(getEventsPopup(1, limit, { ...filter, camera_id_list: [id], is_map: true }));
    };

    const renderBody = () => (
        <div>
            {loading && page === 1 && <Loading size={50} className="preloader center" />}

            {isOpen && (
                <div>
                    {eventsPopup.data.length > 0 ? (
                        <Scrollbars
                            style={{ height: 240 }}
                            onUpdate={scrollHandleUpdate}
                            ref={scrollRef}
                        >

                            {isOpen && (
                                <Events
                                    list={eventsPopup}
                                />
                            )}

                            {(total > 0 && loading) && (
                                <div>
                                    <Loading className="preloader center" />
                                </div>
                            )}
                        </Scrollbars>
                    ) : (
                        <div className="not-found">{messages.EVENTS_IS_NOT_FOUND}</div>
                    )}
                </div>
            )}
        </div>
    );

    return (
        <div className="map-popup">
            <div
                className="map-popup_header"
                style={{
                    backgroundColor: status_color
                        ? colorExtend(status_color)
                        : colorExtend(statusesOb.get(status)?.color)
                }}
            >
                <div>
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                    <span>
                        {name}
                    </span>
                </div>
                {loading === false && (<>
                    <Tooltip title="Просмотр видео" className="action">
                        <IconButton
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpenFrameModal(true);
                            }}
                            style={{
                                ...(!url_player
                                    ? {
                                        pointerEvents: 'none',
                                        opacity: '0.5'
                                    }
                                    : {}
                                ),
                                fontSize: 13,
                                marginRight: 12
                            }}
                            size="large"
                        >
                            <i className="far fa-play-circle"></i>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Обновить данные" className="action">
                        <IconButton onClick={(e) => handleRefresh(e)} size="large">
                            <RefreshIcon fontSize="small" className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                </>)}
            </div>
            <div className="map-popup_body">
                {renderBody()}
            </div>
            {openFrameModal && (
                <FrameModal
                    isOpen={openFrameModal}
                    onClose={() => setOpenFrameModal(false)}
                    link={url_player}
                    title={name}
                />
            )}
        </div>
    );
};

export default PopUp;
