import React from 'react';
import RoutePopUp from './RoutePopUp';
import {
    getRouteColor
} from './helper';
import {
    GeoJson,
} from '../../leaflet';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from '../../../../App';

const RouteJson = ({ item, onMap = true, disabled = false, permissions, readOnly = false, ...props }) => {
    const { category_id, road } = item;
    const color = getRouteColor(category_id);

    if(Object.keys(road).length === 0) return null;

    return (
        <>
            <GeoJson
                {...props}
                map={onMap ? props.parent : props.map}
                data={{
                    ...road,
                    style: {
                        color: color.fill,
                        weight: 3
                    },
                    properties: {
                        ...road.properties,
                        data: {
                            num: item?.num
                            // ...item
                        }
                    }
                }}
                toolTipTemplate={({ num }) => <div>Маршрут №{num}</div>}
                {...(!disabled && { popUpTemplate: () =>
                    <LocalizationProvider dateAdapter={AdapterLuxon} locale={'ru'}>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                                <RoutePopUp
                                    // map={props.map}
                                    id={item.id}
                                    permissions={permissions}
                                    // route={item}
                                    readOnly={readOnly}
                                />
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </LocalizationProvider>
                })}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, 0],
                    sticky: true,
                }}
                refreshTooltip={true}
            />
        </>
    );
};

export default RouteJson;
