
const Item = (props) => {
    const {
        name,
        status,
    } = props;

    return (
        <>
            <div className="description">{name}</div>
            <div>{status}</div>
        </>
    );
};

export default Item;
