import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    LinearProgress,
} from '@mui/material';
import {
    loadPuidByRadius,
    clearPuidByRadius,
} from '../../../../redux/TrafficFlow/actions';
import FormButtons, { buttonsTypes } from '../../FormButtons';
import buttons from '../../../../helpers/constants/buttons';
import {
    Map,
    MapControl,
    FeatureGroup,
    Marker,
    ToolTip,
    Circle,
} from '../../../MapComponents/leaflet';
import Modal from '../../Modal';
import {
    trafficFlowSelectors
} from '../../../../redux/TrafficFlow';
import { createIconMarker } from '../../../MapComponents/Layers/TrafficFlow/helper';
import { config } from '../../../../config';


// модалка карта с пут по радиусу
const MapComponent = ({
    multiple = false,
    selected = multiple ? [] : {},
    selectedList = [],
    lat,
    lon,
    radius = 1000,
    onChange,
    onClose
}) => {
    const dispatch = useDispatch();

    const [currentSelected, setCurrentSelected] = useState(selected);
    const [isChanged, setIsChanged] = useState(false);

    const isSelected = (item) => multiple
        ? currentSelected?.findIndex(({ id }) => id === item?.id) >= 0
        : currentSelected?.id === item.id;
    const inList = (item) => selectedList?.findIndex(({ id }) => id === item?.id) >= 0;

    const isAll = (item) => isSelected(item) || inList(item);

    const puidByRadius = useSelector(trafficFlowSelectors.puidByRadius);
    const loadingPpuidByRadius = useSelector(trafficFlowSelectors.loadingPpuidByRadius);

    const load = useCallback(() => {
        dispatch(
            loadPuidByRadius(
                lat,
                lon,
                radius,
                { includeLogicPuid: false }
            )
        );
    }, [lat, lon, radius]);

    useEffect(() => {
        load();
    }, [dispatch, load]);

    useEffect(() => () => {
        dispatch(clearPuidByRadius());
    }, []);

    const handleMarkerClick = (item) => () => {
        if (!isAll(item)) {
            setCurrentSelected(old => {
                if (multiple) {
                    const index = old?.findIndex(({ id }) => id === item?.id);
                    return index >= 0
                        ? [
                            ...old.slice(0, index),
                            ...old.slice(index + 1),
                        ]
                        : [
                            ...old,
                            item
                        ];
                } else {
                    return currentSelected?.id === item?.id
                        ? {}
                        : item;
                }
            });
            setIsChanged(true);
        }
    };


    const handleClose = () => {
        onClose?.();
        setIsChanged(false);
    };

    const handleAccept = () => {
        onChange?.(currentSelected);
        onClose?.();
        setIsChanged(false);
    };

    return (
        <Modal
            isOpen={true}
            onClose={onClose}
            medium
            title={'Добавление/Редактирование ПУИД'}
            buttons={
                <FormButtons
                    buttons={
                        [
                            {
                                ...buttonsTypes.cancel,
                                name: buttons.CANCEL,
                                onClick: handleClose
                            },
                            {
                                ...buttonsTypes.apply,
                                onClick: handleAccept,
                                disabled: !isChanged
                            },
                        ]
                    }
                />
            }
        >
            {loadingPpuidByRadius && <LinearProgress />}
            <div style={{ height: '400px' }}>
                <Map
                    center={lat && lon ? [lat, lon] : config.get('mapCenter')}
                >
                    <MapControl>
                        <FeatureGroup>
                            {puidByRadius?.map(item => (
                                <Marker
                                    key={item.id}
                                    latlng={[item.lat, item.lon]}
                                    onClick={handleMarkerClick(item)}
                                    icon={createIconMarker(isAll(item) ? '#51a351' : '#7e7e7e')}
                                >
                                    <ToolTip>
                                        <div>
                                            {item.name}
                                        </div>
                                    </ToolTip>
                                </Marker>
                            ))}

                            {lat && lon && radius && (
                                <Circle
                                    radius={radius}
                                    latlng={[lat, lon]}
                                />
                            )}
                        </FeatureGroup>
                    </MapControl>
                </Map>
            </div>

        </Modal>
    );
};

export default MapComponent;
