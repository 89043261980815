import React from 'react';
import PropTypes from 'prop-types';

function GetIcon({ iconName, format, category }) {

    const importAll = (r) =>  {
        const keys = r.keys().filter(item => item === `./${category}/${iconName}.${format}`);
        return keys.map(r);
    };

    const icons = importAll(require.context('./icons', true, /\.(png|svg)$/));

    return icons.map(item =>
        <img
            style={{ width: '100%' }}
            key={item.default}
            src={item.default}
            alt={iconName}
        />
    );
}

GetIcon.propTypes = {
    iconName: PropTypes.any.isRequired,
    format: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired
};

export default GetIcon;