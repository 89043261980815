import L from 'leaflet';
import { useEffect } from 'react';
import { renderToString } from 'react-dom/server';

// подсказка
export const ToolTip = ({
    map,
    parent,
    children,
    direction, // с какой стороны
    sticky, // следовать за маркером
    offset, // сдфинуть
    permanent, // открывать постоянно?
    opacity, // непрозрачность
    className
}) => {
    let toolTip = null;

    useEffect(() => {
        if (children) {
            // собираем настройки
            const options = {};
            if (direction) options.direction = direction;
            if (sticky) options.sticky = sticky;
            if (offset) options.offset = offset;
            if (permanent) options.permanent = permanent;
            if (opacity) options.opacity = opacity;
            if (className) options.className = className;

            const content = renderToString(children);

            toolTip = L.tooltip(options).setContent(content);
            parent.bindTooltip(toolTip);

            return () => {
                parent.unbindTooltip();
                toolTip.remove();
                toolTip = null;
            };
        }
    }, [parent, children]);

    useEffect(() => {
        if (children && toolTip) {
            const content = renderToString(children);
            toolTip.setTooltipContent(content);
        }
    }, [children]);

    return null;
};

export default ToolTip;
