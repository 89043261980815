import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadVehicleList } from 'redux/TransportRoad/actions';
import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

import type { GetListArguments, VehicleRW } from 'types';

interface Filters {
    organization_id?: number;
}

export interface SelectVehicleRWTypes<T> extends Omit<CommonAutocompleteProps<T>, 'onShowList' | 'inputName' | 'initialState'> {
    filter?: Filters,
}

const SelectVehicleRW = ({
    multiple = false,
    selected = multiple ? [] : null,
    onChange = () => {},
    required = false,
    disabled = false,
    label=titles.SELECT_VEHICLE,
    error = false,
    helperText = '',
    limitTags = 1,
    filter = {},
}: SelectVehicleRWTypes<VehicleRW>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);

    const getList = (params: GetListArguments) => {
        const { page, limit, query } = params;

        dispatch(loadVehicleList(
            page,
            limit,
            {
                ...(query && { number: query }),
                ...filter,
            }
        ));
    };

    const handleClose = () => setShowList(false);

    const handleAccept = (data: VehicleRW | VehicleRW[]) => {
        onChange(data);
        setShowList(false);
    };

    const onReset = () => {
        const initialState = multiple ? [] : null;
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete<VehicleRW>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={(option) => `${option?.brand_name  && option?.model_name ? `Марка: ${option?.brand_name}, Модель: ${option?.model_name}` : option?.number || ''}`}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName="transportRoad"
                    storeNameProps="vehicles"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selected}
                    renderProps={(value) => <span><b>Марка:</b> {value.brand_name}, <b>Модель:</b> {value.model_name} <b> ГРЗ:</b> {value.number}</span>}
                    onAccept={handleAccept}
                    isOpen={showList}
                    onClose={handleClose}
                    noPadding
                    title={titles.VEHICLE}
                    small
                />
            }
        </>
    );
};

export default SelectVehicleRW;
