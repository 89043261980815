import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import L from 'leaflet';

import {
    ContextMenuItem,
    GeoJson,
} from 'components/MapComponents/leaflet';
import MapLegends from 'components/common/Transport/MapLegends';
import getFilters from 'components/MapComponents/helpers/getFilters';
import mapHelper from 'helpers/mapHelper';
import { useDebounce, usePrevious } from 'helpers/hooks';
import { useWsSubscribe } from 'helpers/ws/hooks';
import { useWsActive } from 'helpers/ws/hooks';
import useStoreFromSelector from 'helpers/hooks/useStoreFromSelector';
import getStatusesObject from 'helpers/getStatusesObject';
import getImagesObject from 'helpers/getImagesObject';
import colorExtend from 'helpers/mapHelper/colorExtend';
import * as actions from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';

import CPopup from './PopUp';
import config from './config.js';
import { createIconMarker } from './helper';
import LegendsList from './LegendsList';
import Tooltip from './Tooltip';
import CollectorForms from './CollectorForms';


const Layer = (props) => {
    const { map, readOnly = false, minPopUp = false, showcaseFilters = {} } = props;
    const dispatch = useDispatch();

    const polygon = useSelector(SituationalPlansSelectors.polygon);
    const active = useSelector(SituationalPlansSelectors.active);
    const saved = useSelector(SituationalPlansSelectors.saved);
    const filters = useSelector(SituationalPlansSelectors.filters);
    const polygonLoading = useSelector(SituationalPlansSelectors.polygonLoading || false);

    // иконки
    const imagesLegends = useStoreFromSelector(
        actions.loadImagesLegends,
        SituationalPlansSelectors.imagesLegends
    );
    const images = getImagesObject(imagesLegends);

    // статусы
    const statuses = useStoreFromSelector(
        actions.loadTransportIncidentStatuses,
        SituationalPlansSelectors.transportIncidentStatuses
    );
    const statusesObject = getStatusesObject(statuses);

    const getColorByStatus = (status_id) => {
        return colorExtend(statusesObject?.[status_id]?.color || config.mapMarkersColors.default);
    };

    // создание иконки
    const createIcon = useCallback(({ status_id = 0, type_id = 0 }) => {

        const imageB64 = images?.[type_id] // иконка по типу
            || images?.[0] // иконка по умолчанию из даных
            || null; // нет иконки
        const color = getColorByStatus(status_id);

        return createIconMarker(color, imageB64);
    }, [images, statusesObject]);

    useWsSubscribe('situational-plans_transport_incident_update_model_v2', (events) => {
        // console.log('update', events);
        dispatch(actions.setWsTI(events));
    });

    const [geoJson, setGeoJson] = useState([]);
    const showPopUp = useRef(false);
    const prevFilters = usePrevious(filters);
    const prevPolygonLoading = usePrevious(polygonLoading);

    useWsActive((activeData) => {
        dispatch(actions.setActive({
            ...activeData,
            id: activeData.external_id,
        }));
    });

    useEffect(() => {
        if (saved) {
            map.closeContextMenu();
            // map.closePopup();
            fetchPolygon();
            dispatch(actions.setEditForm());
            dispatch(actions.saveStatus(false));
        }
    }, [saved]);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getPolygon(map);
        const filter = getFilters(filters);
        dispatch(actions.loadForPolygon(polygon, { ...filter, ...showcaseFilters }));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();

    // добавить новый
    const handleAdd = ({ lat, lng }) => {
        dispatch(actions.setEditForm({
            geometry: {
                type: 'Feature',
                geometry: {
                    // geometry: {
                    type: 'Point',
                    coordinates: [lng, lat]
                }
            },
            lat,
            lon: lng,
        }));
    };

    // сдвинуться к маркеру
    // const setMapToMarker = () => {
    //     if (active?.lat && active?.lon) {
    //         const { lat, lon } = active;
    //         if (Math.abs(lat) && Math.abs(lon)) {
    //             setTimeout(() => {
    //                 map.setView([lat, lon]);
    //             }, 200);
    //         }
    //     }
    // };

    useEffect(() => {
        fetchPolygon();
        dispatch(actions.loadThreatLevels());
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        // setMapToMarker();

        return () => {
            dispatch(actions.setActive({}));
            dispatch(actions.clearForPolygon());
            map.closeContextMenu();
            //map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters]);

    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, (e) => {
            //map.fire('context_menu_close');
            handleAdd(e.latlng);
        });

        return () => {
            map.off(config.mapContextMenu.event);
        };
    }, []);

    useEffect(() => {
        if (polygonLoading === false && prevPolygonLoading === true && showPopUp.current) {
            const id = showPopUp.current;
            setTimeout(() => {
                map.fire(`showBy${config.slug}${id}`);
            }, 100);

            showPopUp.current = false;
        }
    }, [polygonLoading, prevPolygonLoading]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (active?.geometry) {
            const { geometry } = active;

            // if (geometry) {
            //     const b = L.geoJSON(geometry).getBounds();
            //     map.fitBounds(b);
            // }
            // map.fire(`showBy${active.id}`);

            if (geometry) {
                showPopUp.current = active.id;
                const b = L.geoJSON(geometry).getBounds();
                map.fitBounds(b);
            }
        }
    }, [active]);

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProp }) => {
        return (
            <div>
                <ContextMenuItem
                    {...rcmProp}
                    value="Редактировать"
                    onClick={() => {
                        //map.fire('context_menu_close');
                        dispatch(actions.setEditForm(item));
                    }}
                />
                <ContextMenuItem
                    {...rcmProp}
                    value="Удалить"
                    onClick={() => {
                        //map.fire('context_menu_close');
                        dispatch(actions.setDeleteForm(item.id));
                    }}
                    className="red"
                />
            </div>
        );
    };

    useEffect(() => {
        const geoJson = polygon.reduce((res, { geometry, ...props }) => [
            ...res,
            {
                ...geometry,
                properties: {
                    ...geometry.properties,
                    data: {
                        ...props,
                        geometry
                    },
                    attribution: {
                        slug: config.slug,
                        color: getColorByStatus(props.status_id)
                    },
                },
                style: {
                    color: getColorByStatus(props.status_id),
                    weight: 7
                }
            }
        ], []);

        setGeoJson(geoJson);
    }, [polygon, statusesObject]);

    return (
        <>
            <GeoJson
                {...props}
                data={geoJson}
                icon={createIcon}
                toolTipTemplate={(props) => <Tooltip {...props} statusesObject={statusesObject}/>}
                // toolTipTemplate={({ name }) => <div>{name}</div>}
                popUpTemplate={(data) => <CPopup {...data} readOnly={readOnly} statusesObject={statusesObject} images={images} />}
                {...(!readOnly
                    ? {
                        contextMenuTemplate: (item, data) => <RenderContextMenu item={item} {...data} />
                    }
                    : {}
                )}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, -5],
                    sticky: true,
                }}
                popUpOptions={{
                    minWidth: minPopUp ? '400' : '900'
                }}
                onClosePopup={() => {
                    dispatch(actions.resetActive());
                }}
                idPrefix={config.slug}
            />

            <CollectorForms />

            <MapLegends
                layer="transport-incidents"
                isVisible={imagesLegends?.length > 0}
                visibleRequired={readOnly}
            >
                <LegendsList
                    category={imagesLegends}
                    statuses={statuses}
                />
            </MapLegends>
        </>
    );
};

export default Layer;
