import React from 'react';
import messages from '../../../../../helpers/constants/messages';
import getAddressStringFromObject from '../../../../common/Location/LoadAddressByCoords/getAddressStringFromObject';

const Item = (props) => {
    const {
        name,
        address_text = '',
        address = {},
        onClick,
        type_text,
    } = props;

    return (
        <div onClick={onClick}>
            <div>
                <strong>{name}</strong>
            </div>

            <div>
                <strong>Адрес: </strong>
                <span>{address_text || getAddressStringFromObject(address) || messages.NO_DATA}</span>
            </div>

            <div>
                <strong>Тип: </strong>
                <span>{type_text || messages.NO_DATA}</span>
            </div>
        </div>
    );
};

export default Item;
