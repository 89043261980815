import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete, TextField } from '@mui/material';
import { isEqual } from 'lodash';

import { loadDtpLoadTypes } from 'redux/Incidents/actions';
import { loadStreetList } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import useStoreProp from 'helpers/hooks/useStoreProp';
import titles from 'helpers/constants/titles';
import { useDebounce } from 'helpers/hooks';
import DateRange from 'components/common/Dates/DateRange';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const Filter = (props) => {
    const dispatch = useDispatch();

    const { data, onChange } = props;

    const getValue = (key, def = '') => data[key] || def;

    const loadTypes = useStoreProp(loadDtpLoadTypes, 'incidents', 'loadTypes');

    const [street, setStreet] = useState([]);

    const types = useSelector(incidentsSelectors.dtpTypesData);
    const streetList = useSelector(incidentsSelectors.streetList);

    const streetListOptions = streetList.length > 0
        ? streetList.map((item) => ({
            id: item,
            name: item
        }))
        : [{
            id: '',
            name: titles.NO_RESULTS
        }];

    const handleLoadStreetList = (query) => {
        dispatch(loadStreetList(query));
    };
    const loadStreetListDebounce = useDebounce(handleLoadStreetList, 800);

    const loadOptions = Object
        .keys(loadTypes)
        .map((key) => ({
            id: key,
            name: loadTypes[key]
        }));

    // убираем дефолтное значение
    const typesList = types
        ?.filter(({ id }) => id && id !== 'default')
        .map(({ id, name }) => ({
            id: id,
            name
        }));

    const handleEvent = (name) => (_, newValue) => {
        onChange({ [name]: newValue });
    };

    const disableOption = (option) => {
        const hasValue = (data.query || []).find(item => item.value === option.value);
        return !!hasValue || !option.id;
    };

    const handleStreet = (e, newValue) => {
        const options = newValue.reduce((res, item) => {
            if (typeof item === 'string') {
                return res;
            }
            return [
                ...res,
                item
            ];
        }, []);

        onChange({
            'street': options
        });

        setStreet([
            ...options
        ]);
    };

    const handleAddressChange = (e) => {
        const { target: { value } } = e;
        if (value?.length > 3) {
            loadStreetListDebounce(value);
        } else {
            loadStreetListDebounce.clear();
        }
    };

    useEffect(() => {
        if (data.street && !isEqual(data.street || [], street)) {
            setStreet(data.street);
        }
    }, [data]);

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >

            <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    multiple
                    value={getValue('dtp_type', [])}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={typesList}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEvent('dtp_type')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.TYPE_EVENTS}
                        />
                    )}
                />
            </div>

            {/* поиск по адресу */}
            <FormInfoWrapper
                helperText={'Введите улицу и выберите нужную из предложенного списка'}
                className={'block'}
            >
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    multiple
                    value={street}
                    options={streetListOptions}
                    onChange={handleStreet}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    freeSolo={true}
                    getOptionDisabled={disableOption}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Улица"
                        />
                    )}
                    onInputChange={handleAddressChange}
                />
            </FormInfoWrapper>

            <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={getValue('type_load', [])}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={loadOptions}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEvent('type_load')}
                    size="small"
                    multiple
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Источник создания"
                        />
                    )}
                />
            </div>

            <div className="row">
                <DateRange
                    className="row__item"
                    handleDate={(newDate) => onChange({ start_date: newDate })}
                    handleEndDate={(newDate) => onChange({ end_date: newDate })}
                    valueStartDate={getValue('start_date', null) && new Date(getValue('start_date', null))}
                    valueEndDate={getValue('end_date', null) && new Date(getValue('end_date', null))}
                    startLabel={titles.START_PERIOD}
                    endLabel={titles.END_PERIOD}
                />
            </div>

        </form>
    );
};

export default Filter;
