import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';

import { loadAipEvents } from 'redux/Aip/actions';
import Loading from 'components/common/Loading';
import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';

const Specifications = ({ currentAip }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadAipEvents( 1, 1, { aip_id: [currentAip.id] } ));
    }, [dispatch, currentAip]);

    const {
        aipEvents : { data },
        loadingAipEvents,
    } = useSelector(({ aip }) => aip);

    return (
        <>
            {loadingAipEvents && <Loading linear={true}/>}

            <Grid container direction="row" spacing={1} justify="center" alignItems="flex-start">
                {Object.keys(data).length > 0 && currentAip
                    ? <Grid item xs={12}>
                        <Typography variant="body2">
                            <strong>Заряд АКБ(%): </strong>
                            <span>{data[0]?.battery_current}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Напряжение АКБ(Вольты): </strong>
                            <span>{data[0]?.battery_voltage}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Напряжение нагрузки(Вольты): </strong>
                            <span>{data[0]?.load_voltage}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Ток нагрузки(Амперы): </strong>
                            <span>{data[0]?.load_current}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Мощность нагрузки(Ватты): </strong>
                            <span>{data[0]?.load_power}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Напряжение солнечных панелей(Вольты): </strong>
                            <span>{data[0]?.array_voltage}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Ток солнечных панелей(Амперы или мА): </strong>
                            <span>{data[0]?.array_current}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Мощность солнечных панелей(Ватты): </strong>
                            <span>{data[0]?.array_power}</span>
                        </Typography>
                        <Typography variant="body2">
                            <strong>Кто и когда создал: </strong>
                            <span>{fullDateTime(data[0]?.created_at)}</span>
                        </Typography>
                    </Grid>
                    : !loadingAipEvents && <div>{messages.DATA_IS_NOT_FOUND}</div>
                }
            </Grid>
        </>
    );
};

export default Specifications;
