import React, { useState } from 'react';
import FormButtons from '../../../common/FormButtons';
import { buttonsTypes } from '../../../common/FormButtons';
import cn from 'classnames';
import { TextField } from '@mui/material';

const Filter = ({ setParams }) => {

    const [clicked, setClicked] = useState(false);

    const [data, setData] = useState({
        name: '',
        status: '',
        camera_name: ''
    });

    const onChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleFilteredList = () => {
        const paramsWithoutEmpty = Object.keys(data).reduce((res, key) => {
            if (data[key]) {
                res[key] = data[key];
            }
            return res;
        }, {});
        setClicked(true);
        setParams({ page: 1, data: paramsWithoutEmpty });
    };

    return(
        <div className="filter">
            <div className={cn('filter__wrap', 'filter__wrap__more__field')}>
                <TextField
                    label="Название"
                    variant="outlined"
                    size="small"
                    value={data.name || ''}
                    name={'name'}
                    onChange={onChange}
                    type="text"
                    InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                />
                <TextField
                    label="Название камеры"
                    variant="outlined"
                    size="small"
                    value={data.camera_name || ''}
                    name={'camera_name'}
                    onChange={onChange}
                    type="text"
                    InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                />
            </div>
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: () => {
                                setData({});
                                if (clicked) {
                                    setClicked(false);
                                    setParams({ page: 1, data: {} });
                                }
                            },
                            disabled: !data.name && !data.status
                        },
                        {
                            ...buttonsTypes.find,
                            onClick: handleFilteredList,
                            disabled: !data.name && !data.status
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;
