import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import * as actions from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import messages from 'helpers/constants/messages';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import Context from 'helpers/context';
import GroupItem from './Item';
import AddEditModal from './AddEditModal';
import Filter from './Filter';
import type { Item, ParamsState } from './types';
import NoData from 'components/common/Information/NoData';


const CheckListGroup = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);
    
    const data = useSelector(dorisControlSelectors.checkListGrouptData);
    const meta = useSelector(dorisControlSelectors.checkListGroupMeta);
    const loading = useSelector(dorisControlSelectors.loadingCheckListGroup);
    
    const [showModal, setShowModal] = useState(false);
    const [params, setParams] = useState<ParamsState>({ page: 1, data: { simple: 1, name: '' } });
    const storageLimit = Number(localStorage.getItem('limit') || 25);
    const [limit, setLimit] = useState(storageLimit);

    useEffect(() => {
        dispatch(actions.loadCheckListGroup({ page: params.page, limit, params: removeEmptyFields(params.data) }));
    }, [dispatch, limit, params]);

    const reloadList = () => dispatch(actions.loadCheckListGroup({ page: params.page, limit, params: removeEmptyFields(params.data) }));

    const renderContent = () => {
        return data.length > 0
            ?  <LSContainer 
                headers={[
                    { title: titles.NAME, width: '50%' },
                    { title: 'Действия', align: 'right', isActions: true }
                ]}>
                {data.map((el: Item) => (
                    <GroupItem 
                        key={el.id} 
                        item={el}
                        reloadList={reloadList}
                    />
                ))}
            </LSContainer>
            : !loading && <NoData />;
    };

    return <>
        <PageLayout
            header="Чек-листы"
            filters={<Filter
                setParams={setParams}
            />}
            loading={loading}
            content={() => renderContent()}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: meta,
                limit,
                setLimit: setLimit,
            }}
            informPanelProps={{                
                buttons: (
                    <FormButtonsComponent 
                        buttons={[
                            ...(permissions?.is_create
                                ? [{
                                    ...buttonsTypes.add,
                                    onClick: () => setShowModal(true),
                                    size: 'small',
                                }]
                                : []
                            ),
                        ]}
                        positionLeft
                        noPadding
                    />
                ),
                total: meta?.total
            }}
        />
        {showModal
            && <AddEditModal
                isNew
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                reloadList={reloadList}
            />
        }
    </>;
};

export default CheckListGroup;