import { useState } from 'react';
import { FormHelperText, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { useValidation } from 'helpers/hooks';
import ColorPicker from 'components/common/ColorPicker';
import UploadImageField from 'components/common/Upload/UploadImageField';


const VehicleCategoriesFields = ({ data, onChange, setIsDisabled, fromService }) => {
    const validation = useValidation();
    const [openColorPickerModal, setOpenColorPickerModal] = useState(false);
    const [openColorPickerNotRegistryModal, setOpenColorPickerNotRegistryModal] = useState(false);

    return (
        <>
            <h2>Изображение и цвет для обозначения ТС на карте</h2>
            <FormHelperText>Максимальный размер изображения 70х70 пикселей</FormHelperText>
            <List>
                <ListItem divider>
                    <ListItemIcon>
                        <IconButton
                            variant={'contained'}
                            onClick={() => setOpenColorPickerModal(true)}
                            sx={{ background: data.color, border: '1px solid #999', width: '42px', height: '42px' }}
                        />
                    </ListItemIcon>
                    <ListItemText>
                        Цвет подложки ТС в реестре
                    </ListItemText>
                </ListItem>
                {fromService === 'transportSpecial'
                    ? <ListItem divider>
                        <UploadImageField
                            value={data.image}
                            name="image"
                            color={data.color}
                            onChange={onChange}
                            label="Изображение ТС"
                            setIsDisabled={setIsDisabled}
                            isSelectBorder
                            imageSize={40}
                            acceptFiles=".png"
                            helperText="Размер изображения не должен превышать 70х70 пикселей"
                            imageErrorValidation={(img) => img.width > 70 || img.height > 70}
                            errorText={validation.get('image')}
                        />
                    </ListItem>
                    : <>
                        <ListItem divider>
                            <UploadImageField
                                value={data.image}
                                name="image"
                                color={data.color}
                                onChange={onChange}
                                label="Изображение ТС не в наряде"
                                setIsDisabled={setIsDisabled}
                                isSelectBorder
                                imageSize={40}
                                acceptFiles=".png"
                                helperText="Размер изображения не должен превышать 70х70 пикселей"
                                imageErrorValidation={(img) => img.width > 70 || img.height > 70}
                                errorText={validation.get('image')}
                            />
                        </ListItem>
                        <ListItem divider>
                            <UploadImageField
                                value={data.image_with_work_order}
                                name="image_with_work_order"
                                color={data.color}
                                onChange={onChange}
                                label="Изображение ТС в наряде"
                                setIsDisabled={setIsDisabled}
                                isSelectBorder
                                imageSize={40}
                                acceptFiles=".png"
                                helperText="Размер изображения не должен превышать 70х70 пикселей"
                                imageErrorValidation={(img) => img.width > 70 || img.height > 70}
                                errorText={validation.get('image_with_work_order')}
                            />
                        </ListItem>
                    </>
                }

                {fromService === 'passengerTransport'
                    && <ListItem divider>
                        <ListItemIcon>
                            <IconButton
                                variant={'contained'}
                                onClick={() => setOpenColorPickerNotRegistryModal(true)}
                                sx={{ background: data.color_not_in_registry, border: '1px solid #999', width: '42px', height: '42px' }}
                            />
                        </ListItemIcon>
                        <ListItemText>
                            Цвет подложки ТС не в реестре
                        </ListItemText>
                    </ListItem>
                }
            </List>

            {openColorPickerModal && (
                <ColorPicker
                    open={openColorPickerModal}
                    onClose={() => setOpenColorPickerModal(false)}
                    color={data.color}
                    onChangeColor={(value) => onChange('color', value)}
                />
            )}

            {openColorPickerNotRegistryModal && (
                <ColorPicker
                    open={openColorPickerNotRegistryModal}
                    onClose={() => setOpenColorPickerNotRegistryModal(false)}
                    color={data.color_not_in_registry}
                    onChangeColor={(value) => onChange('color_not_in_registry', value)}
                />
            )}
        </>
    );
};

export default VehicleCategoriesFields;
