import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';

import { loadBoards, loadMessageTemplates } from 'redux/Boards/actions';
import { useValidation } from 'helpers/hooks';
import UniversalSelect from 'components/common/UniversalSelect';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import SelectBoards from 'components/common/Autocomplete/Boards/BoardList';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const BoardForm = ({ data, setData }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const { dit_ids, dit_template_id, dit_duration } = data;

    const [openSelectTemplate, setOpenSelectTemplate] = useState(false);
    const [selectedBoards, setSelectedBoards] = useState((dit_ids || []).map(id => ({ id })));
    const [selectedTemplate, setSelectedTemplate] = useState({});

    const getTemplates = (params) => {
        const { page, limit, query } = params;
        dispatch(loadMessageTemplates({ page, limit, header: query }));
    };

    useEffect(() => {
        if(dit_ids?.length > 0){
            dispatch(loadBoards({ ids: dit_ids }, (data) => setSelectedBoards(data)));

            const callback = (templates) => {
                const fondTemplate = templates.find(el => el.id === dit_template_id);
                setSelectedTemplate(fondTemplate || { id: dit_template_id });
            };
            dispatch(loadMessageTemplates({ ids: dit_template_id }, callback ));
        }
    }, []);

    const onChangeBoards = (boards) => {
        setSelectedBoards(boards);
        !boards.length && setSelectedTemplate({});
        
        setData((prev) => ({ 
            ...prev,
            dit_ids: boards.map(({ id }) => id),
            ...(!boards.length && { dit_duration: null }),
            ...(!boards.length && { dit_template_id: null })
        }));
    };

    return (
        <>
            <SelectBoards
                multiple
                label={'ДИТ'}
                selected={selectedBoards}
                onChange={onChangeBoards}
            />

            <div className="filter__wrap filter__wrap__more__field">
                <CommonAutocomplete
                    selected={selectedTemplate}
                    error={validation.isKey('dit_template_id')}
                    helperText={validation.get('dit_template_id')}
                    required={!!dit_ids.length}
                    inputName="dit_template_id"
                    renderLabel={(option) => option?.header || (option?.id && String(option?.id)) || ''}
                    label={'Шаблон'}
                    onShowList={() => setOpenSelectTemplate(true)}
                    filterSelectedOptions={true}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    isOpen={openSelectTemplate}
                />

                <FormInfoWrapper
                    error={validation.isKey('dit_duration')}
                    helperText={validation.get('dit_duration')}
                >
                    <TextField
                        label="Время показа в секундах"
                        variant="outlined"
                        size="small"
                        value={dit_duration || ''}
                        name="dit_duration"
                        onChange={({ target: { value, name } }) => setData((prev) => ({ ...prev, [name]: value }))}
                        type="number"
                        inputProps={{ min: 0 }}
                        required={!!dit_ids.length}
                        error={!!dit_ids.length && !dit_duration}
                    />
                </FormInfoWrapper>
            </div>
            
            {openSelectTemplate && (
                <UniversalSelect
                    fetchList={getTemplates}
                    storeName={'boards'}
                    storeNameProps={'boardMessageTemplates'}
                    keyProp={'id'}
                    withSearch={true}
                    isSelected={true}
                    selected={selectedTemplate}
                    renderProps={(el) => <div>{el.header}</div>}
                    onAccept={(template) => {
                        setData((prev) => ({ ...prev, dit_template_id: template.id }));
                        setOpenSelectTemplate(false);
                        setSelectedTemplate(template);
                    }}
                    isOpen={openSelectTemplate}
                    onClose={() => setOpenSelectTemplate(false)}
                    noPadding={true}
                    title="Выберите шаблон"
                />
            )}
        </>
    );
};

export default BoardForm;
