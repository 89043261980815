import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import ConfirmDelete from 'components/common/ConfirmModal';
import { deletePark } from 'redux/TransportPassenger/actions';

import ModalForm from './ModalForm';

const Item = ({ item, reloadList, permissions }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openMapModal, setOpenMapModal] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const handleDelete = () => {
        dispatch(deletePark(item.id, () => {
            reloadList(true);
            setConfirmOpen(false);
        }));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>{item.name || messages.NO_DATA}</LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <i className="fas fa-map-marked" />,
                                name: titles.STOP_AREA,
                                onClick: () => setOpenMapModal(true),
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setConfirmOpen(true),
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    isNew={false}
                    el={item}
                    reloadList={reloadList}
                />
            )}

            {openMapModal && (
                <Modal
                    isOpen={openMapModal}
                    onClose={() => setOpenMapModal(false)}
                    title={titles.STOP_AREA}
                    buttons={
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: () => setOpenMapModal(false),
                                },
                            ]}
                        />
                    }
                >
                    <MapGeoJson geometry={item?.check_point?.geometry || {}} readOnly={true} />
                </Modal>
            )}

            {confirmOpen
                && <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={confirmOpen}
                    onSuccess={handleDelete}
                    onClose={() => setConfirmOpen(false)}
                />
            }
        </>
    );
};

export default Item;
