import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import FormButtonsComponent from '../../FormButtons';


function List(props) {
    const {
        list,
        onClick = () => {},
    } = props;

    return (
        <>
            {(list.length > 0) && (
                <div className="map-search-control_list">
                    <Scrollbars
                        style={{ height: '210px' }}
                    >
                        {list.map((listItem, index) => {
                            const {
                                title,
                                fias,
                                geo_lat,
                                geo_lon
                            } = listItem;

                            return (
                                <div
                                    key={`${fias}_${index}`}
                                    className="map-search-control_list_item"
                                >
                                    <FormButtonsComponent
                                        justButton
                                        buttons={[
                                            {
                                                onClick: () => onClick({ geo_lat, geo_lon, title }),
                                                name: title
                                            }
                                        ]}
                                    />
                                </div>
                            );
                        })}
                    </Scrollbars>
                </div>
            )}
        </>
    );
};

export default List;
