import React from 'react';
import {
    FormControlLabel,
    Checkbox,
    Typography,
} from '@mui/material';


const Form = ({ data, onChange, onDelete }) => {
    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="row">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data?.free_spaces?.id === 1 || false}
                            onChange={(event) =>
                                (event.target.checked)
                                    ? onChange({
                                        free_spaces: {
                                            id: event.target.checked ? 1: 0,
                                            name: 'Свободные места'
                                        }
                                    })
                                    : onDelete('free_spaces')
                            }
                            color="primary"
                        />
                    }
                    label={
                        <Typography variant="body2">
                            Свободные места
                        </Typography>
                    }
                />
            </div>
        </form>
    );
};

export default Form;
