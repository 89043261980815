import L from 'leaflet';

export const setDragLangRu = () => {
    const langRu = {
        draw: {
            handlers: {
                circle: {
                    radius: 'Радиус',
                    tooltip: {
                        start: 'Щелкните и перетащите, чтобы нарисовать круг.'
                    }
                },
                circlemarker: {
                    tooltip: {
                        start: 'Щелкните карту, чтобы разместить маркер круга.'
                    },
                },
                marker: {
                    tooltip: {
                        start: 'Кликните для установки метки'
                    }
                },
                polygon: {
                    tooltip: {
                        cont: 'Щелкните, чтобы продолжить рисование формы.',
                        end: 'Щелкните первую точку, чтобы закрыть эту форму.',
                        start: 'Кликните для установки первой метки'
                    }
                },
                polyline: {
                    error: '<strong>Ошибка:</strong> края не могут пересекаться!',
                    tooltip: {
                        cont: 'Нажмите, чтобы продолжить рисование линии.',
                        end: 'Щелкните последнюю точку, чтобы закончить линию.',
                        start: 'Кликните для установки первой метки'
                    }
                },
                rectangle: {
                    tooltip: {
                        start: 'Щелкните и перетащите, чтобы нарисовать прямоугольник.'
                    }
                },
                simpleshape: {
                    tooltip: {
                        end: 'Отпустите кнопку мыши, чтобы закончить рисование.'
                    }
                }
            },
            toolbar: {
                actions: {
                    text: 'Отмена',
                    title: 'Отменить рисование'
                },
                buttons: {
                    circle: 'Круг',
                    circlemarker: 'Круговой маркер',
                    marker: 'Маркер',
                    polygon: 'Полигон',
                    polyline: 'Линия',
                    rectangle: 'Прямоугольник',
                },
                finish: {
                    text: 'Завершить',
                    title: 'Закончить движение'
                },
                undo: {
                    text: 'Удалить последнюю метку',
                    title: 'Удалить последнюю точку'
                }
            }

        },
        edit: {
            handlers: {
                edit: {
                    tooltip: {
                        subtext: 'Нажмите «Отмена», чтобы отменить изменения.',
                        text: 'Перетащите точки или маркеры, чтобы редактировать объекты.'
                    }
                },
                remove: {
                    tooltip: {
                        text: 'Щелкните объект, который нужно удалить.'
                    }
                }
            },
            toolbar: {
                actions: {
                    cancel: {
                        text: 'Отмена',
                        title: 'Отменить редактирование, отменить все изменения'
                    },
                    clearAll: {
                        text: 'Очистить все',
                        title: 'Очистить все слои'
                    },
                    save: {
                        text: 'Сохранить',
                        title: 'Сохранить изменения'
                    }
                },
                buttons: {
                    edit: 'Редактировать слои',
                    editDisabled: 'Нет слоев для редактирования',
                    remove: 'Удалить слои',
                    removeDisabled: 'Нет слоев для удаления'
                }
            }
        }
    };

    L.drawLocal = Object.assign(L.drawLocal, langRu);
};
