import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';

import { useStoreProp } from '../../../../../helpers/hooks';
import { incidentsSelectors } from '../../../../../redux/Incidents';
import { loadReportCategory } from '../../../../../redux/Incidents/actions';
import PageLayout from '../../../../layout/PageLayout';

import Filters from './Filters';
import DtpMap from './DtpMap';
import Legend from './Legend';

const DtpReportTypes = () => {
    const loadingReportByCategory = useSelector(incidentsSelectors.loadingReportByCategory);

    const reportCategory = useStoreProp(loadReportCategory, 'incidents', 'reportCategory');

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        }}>
            <PageLayout
                header="Отчет по категориям ДТП"
                filters={<Filters reportCategory={reportCategory} />}
                content={() => (<>
                    <Legend reportCategory={reportCategory} />
                    <div style={{ height: '100%', position: 'relative' }}>
                        {loadingReportByCategory && (
                            <LinearProgress
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    zIndex: 99
                                }}
                            />
                        )}
                        <DtpMap />
                    </div>
                </>)
                }
            />
        </div>
    );
};

export default DtpReportTypes;
