import { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import SelectCompany from 'components/common/Autocomplete/Companies';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const initialState = {
    name: '',
    number: '',
    organization: {}
};

const Filters = ({ setParams }) => {
    const [formValues, setFormValues] = useState(initialState);

    const handleChangeFields = ({ target: { name, value } }) => {
        setFormValues({ ...formValues, [name]: (name === 'number' && value < 0) ? 0 : value });
    };

    const resetFilters = (needRefresh) => {
        if (needRefresh) {
            setParams({});
        }
        setFormValues(initialState);
    };

    const prepareFilters = (filters) => {
        const { organization, ...restFields } = filters;

        return removeEmptyFields({
            ...restFields,
            organization_id: organization.id,
        });
    };

    const search = () => {
        setParams(prepareFilters(formValues));
    };

    const updateFilter = (filters) => { // полученные с сервера сохраненные фильтры
        if (Object.keys(filters).length > 0) {
            const mergedFilters = { ...initialState, ...filters };

            setFormValues(mergedFilters); // обновляем локальный стейт
            setParams(prepareFilters(mergedFilters)); // отправляем в родителя, чтобы произошел запрос
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={() => search(formValues)}
            filter={formValues}
            setUserFilter={updateFilter}  // если у пользователя сохранен фильтр для этой страницы, мы его из общего компонента сетаем сюда
        >
            <LayoutFilter.Visible>
                <TextField
                    value={formValues.name}
                    onChange={handleChangeFields}
                    name="name"
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                />
                <TextField
                    value={formValues.number}
                    onChange={handleChangeFields}
                    name="number"
                    label={titles.NUMBER}
                    variant="outlined"
                    size="small"
                    type="number"
                    inputProps={{ min: 0 }}
                />
                <SelectCompany
                    selected={formValues.organization}
                    onChange={organization => setFormValues({ ...formValues, organization })}
                    selectDeletedСompanies
                    filter={{ withDeleted: 1 }}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;
