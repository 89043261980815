import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadBrandsList } from 'redux/TransportRoad/actions';
import { transportRoadSelectors } from 'redux/TransportRoad';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import Context from 'helpers/context';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';

import BrandModal from './BrandModal';
import BrandItem from './BrandItem';
import Filters from './Filters';

const RWBrands = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const listData = useSelector(transportRoadSelectors.brandsData);
    const listMeta = useSelector(transportRoadSelectors.brandsMeta);
    const loading = useSelector(transportRoadSelectors.brandsLoading);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openModal, setOpenModal] = useState(false);

    const reloadList = (isDelete) => {
        isDelete && listData?.length === 1 && listMeta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadBrandsList(params.page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadBrandsList(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return <>
            {listData?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: titles.TYPE_OF_VEHICLE, width: '80px' },
                            { title: titles.NAME, width: '70%' },
                            { title: 'Действия', isActions: true }
                        ]}
                    >
                        {listData.map((item) => (
                            <BrandItem
                                key={item.id}
                                item={item}
                                reloadList={reloadList}
                                permissions={permissions}
                            />
                        ))}
                    </LSContainer>
                )
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>;
    };

    return (
        <>
            <PageLayout
                header="Марки"
                loading={loading}
                filters={<Filters setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenModal(true),
                                        disabled: !permissions?.is_create,
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    
                    total: listMeta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: listMeta,
                    limit,
                    setLimit
                }}
            />

            {openModal && (
                <BrandModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    isNew
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default RWBrands;
