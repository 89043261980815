import {
    all, put, takeEvery
} from 'redux-saga/effects';
import * as types from './types';
import { userSignOutSuccess } from '../Auth/actions';
import { setValidation } from '../Validation/actions';
import { showMessage, messageTypes } from '../Message/actions';
import createError from './helpers/create.error';
import { setUploadFileError } from '../Upload/actions';

// обработка ошибок
export function* actionForErrorSaga({ error }) {
    const newError = createError(error);
    const key = newError.className;
    // костыль с кривым сообщением от бэка
    if (newError.message === 'project doesn\'t belong') {
        newError.message = 'Не выбран регион';
    }
    switch (key) {
        case 'ValidationError': {
            yield put(setValidation(newError.validationMessage));
            yield put(showMessage(messageTypes.error, newError.message));
            break;
        }

        case 'HttpAuthError': {
            yield put(userSignOutSuccess(newError));
            break;
        }
        
        case 'UploadError': {
            yield put(setUploadFileError(newError.data));
            break;
        }

        /*case 'ConnectionError': {
            yield put(userSignOutSuccess(newError));
            break;
        }*/

        default: {
            yield put(showMessage(messageTypes.error, newError.message));
        }
    }
}

export default function* saga() {
    yield all([
        takeEvery(types.ACTION_FOR_ERROR, actionForErrorSaga)
    ]);
}
