import React from 'react';
import EditorForm from '../../../pages/Dictionaries/RoadWorks/Sites/EditorForm';
// import Modal from '../../../common/Modal';
import * as actions from '../../../../redux/RoadWorks/actions';
import ConfirmDelete from '../../../common/ConfirmModal';
import { roadworksSelectors } from '../../../../redux/RoadWorks';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../helpers/constants/messages';
import titles from '../../../../helpers/constants/titles';
// import { getListRW } from '../../../../redux/RoadWorks/actions';
// import getFilters from '../../helpers/getFilters';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();

    const deleteForm = useSelector(roadworksSelectors.deleteForm || false);
    const editForm = useSelector(roadworksSelectors.editForm || false);
    // const filters = useSelector(roadworksSelectors.filters);

    // закрыть форму
    const handleCloseEditForm = () => {
        dispatch(actions.setEditForm());
        // dispatch(getListRW({
        //     page: 1,
        //     limit: 25,
        //     ...getFilters(filters),
        // }, true));
    };
    const isNew = !editForm.id;

    // const title = isNew ? titles.ADD : titles.EDIT;

    return (
        <>
            {/* форма редактирования */}
            {/*<Modal isOpen={Boolean(editForm)} onClose={handleCloseEditForm} noPadding title={title}>*/}
            {/*    <EditorForm*/}
            {/*        isNew={isNew}*/}
            {/*        inModal*/}
            {/*        dataForm={editForm}*/}
            {/*        onClose={handleCloseEditForm}*/}
            {/*        onSuccess={(newFields) => {*/}
            {/*            if (!newFields['id']) {*/}
            {/*                dispatch(actions.createNewRW(newFields, handleCloseEditForm));*/}
            {/*            } else {*/}
            {/*                dispatch(actions.editRW(newFields.id, newFields, handleCloseEditForm));*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Modal>*/}
            {Boolean(editForm) && (
                <EditorForm
                    isOpen={Boolean(editForm)}
                    isNew={isNew}
                    inModal
                    dataForm={editForm}
                    onClose={handleCloseEditForm}
                    onSuccess={(newFields) => {
                        if (!newFields['id']) {
                            dispatch(actions.createNewRW(newFields, handleCloseEditForm));
                        } else {
                            dispatch(actions.editRW(newFields.id, newFields, handleCloseEditForm));
                        }
                    }}
                />
            )}

            {/* удаление */}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!deleteForm}
                onSuccess={() => {
                    dispatch(actions.deleteRW(deleteForm));
                    dispatch(actions.setDeleteForm());
                }}
                onClose={() => {
                    dispatch(actions.setDeleteForm());
                }}
            />
        </>
    );
};

export default CollectorForms;
