const colorStatus = (type) => {
    const statuses = {
        1: '#2e4ac4',
        2: '#31bebe',
        3: '#00ae5c',
        4: '#ad1818',
        5: '#daa655',
        default: '#000'
    };
    const defaultConfig = statuses.default;
    return statuses[type] || defaultConfig;
};

export default colorStatus;