import React from 'react';
import { Box, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SelectSigns from '../../../../../common/Autocomplete/Boards/Signs';
import { loadAlertTemplateFileds } from '../../../../../../redux/Boards/actions';
import { useStoreProp } from '../../../../../../helpers/hooks';


const ConditionItem = ({ item, type, onChange, onDelete, microservice }) => {
    const { data, param, value, operator } = item;

    const alertFields = useStoreProp(
        loadAlertTemplateFileds,
        'boards',
        'alertTemplateFields'
    );

    const operators = {
        '=' : 'Равно',
        '>' : 'Больше',
        '<' : 'Меньше',
        '>=' : 'Больше или Равно',
        '<=' : 'Меньше или Равно',
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({
            ...item, 
            [name]: name === 'data'
                ? { [type]: value }
                : value,
            ...(name === 'param' && !microservice 
                && { microservice : !!alertFields.ecologe[value] ? 'ecologe' : 'meteologe' }
            )
        });
    };

    return (
        <>
            <div className="row align-items-center">
                <div className="row__item" style={{ width: '90%' }}>
                    <div className="row align-items-center">
                        <Box width={'7%'}>Если:</Box>
                        <FormControl
                            className="block row__item"
                            size="small"
                            variant="outlined"
                        >
                            <InputLabel>Параметр</InputLabel>
                            <Select
                                value={param}
                                onChange={handleChange}
                                label="Параметр"
                                name="param"
                            >
                                {microservice !== 'meteologe' && <MenuItem disabled divider>Экология</MenuItem>}
                                {microservice !== 'meteologe' && (
                                    Object.keys(alertFields.ecologe || {}).map(key => (
                                        <MenuItem key={key} value={key}>
                                            {alertFields.ecologe[key]}
                                        </MenuItem>
                                    ))
                                )}
                                {microservice !== 'ecologe' && <MenuItem disabled divider>Метео</MenuItem>}
                                {microservice !== 'ecologe' && (
                                    Object.keys(alertFields.meteologe || {}).map(key => (
                                        <MenuItem key={key} value={key}>
                                            {alertFields.meteologe[key]}
                                        </MenuItem>
                                    ))
                                )}
                                
                            </Select>
                        </FormControl>
                        <FormControl
                            className="block row__item"
                            size="small"
                            variant="outlined"
                        >
                            <InputLabel>Условие</InputLabel>
                            <Select
                                value={operator}
                                onChange={handleChange}
                                label="Условие"
                                name="operator"
                            >
                                {Object.keys(operators)?.map(key => (
                                    <MenuItem key={key} value={key}>
                                        {operators[key]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            className="block row__item"
                            sx={{ width: '20%' }}
                            value={value}
                            name="value"
                            onChange={handleChange}
                            label="Значение"
                            size="small"
                            variant="outlined"
                            type="number"
                        />
                    </div>
                    <div className="row align-items-center">
                        <Box width={'7%'}>То:</Box>
                        <div className="block" style={{ width: '100%' }}>
                            {type === 'text'
                                ? <TextField
                                    value={data?.text || ''}
                                    name="data"
                                    onChange={handleChange}
                                    label="Текст сообщения"
                                    size="small"
                                    variant="outlined"
                                    type="text"
                                />
                                : <SelectSigns
                                    selected={data?.sign || ''}
                                    onChange={(value) => handleChange({ target:  { name: 'data', value } })}
                                />
                            }
                        </div>
                    </div>
                </div>

                <IconButton onClick={onDelete} size="small">
                    <CloseIcon />
                </IconButton>
            </div>
            <Divider/>
        </>
        
    );
};

export default ConditionItem;
