import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import OrderStageSequencesModal from '../../../Dictionaries/RoadWorks/Orders/PassportPage/OrderStage/OrderStageSequencesModal';
import OrderStageModal from '../../../Dictionaries/RoadWorks/Orders/PassportPage/OrderStage/OrderStageModal';
import ConfirmDelete from '../../../../common/ConfirmModal';
import messages from '../../../../../helpers/constants/messages';
import { deleteStage } from '../../../../../redux/RoadWorks/actions';
import { ListItem, ListItemText } from '@mui/material';
import titles from '../../../../../helpers/constants/titles';
import makeStyles from '@mui/styles/makeStyles';
import { fullDateTime } from '../../../../../helpers/date.config';

const useStyles = makeStyles({
    wrap: {
        '& > span': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    title: {
        marginRight: 15
    }
});

const StageOrdersInfo = ({
    item,
    statuses,
    el,
    currentStages,
    stageCargoTypes
}) => {

    // const dispatch = useDispatch();
    const classes = useStyles();
    // const [confirmOpen, setConfirmOpen] = useState(false);
    // const [openSequencesStage, setOpenSequencesStage] = useState(false);
    // const [editStageModal, setEditStageModal] = useState(false);

    return (
        <>
            {/* {openSequencesStage && (
                <OrderStageSequencesModal
                    isOpen={openSequencesStage}
                    onClose={() => setOpenSequencesStage(false)}
                    currentStages={currentStages}
                />
            )} */}
            {/* {editStageModal && (
                <OrderStageModal
                    isOpen={editStageModal}
                    isNew={false}
                    item={item}
                    el={el}
                    onClose={() => setEditStageModal(false)}
                    statuses={statuses}
                    stageCargoTypes={stageCargoTypes}
                />
            )} */}
            {/* {confirmOpen &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={confirmOpen}
                    onSuccess={() => dispatch(deleteStage(item.id))}
                    onClose={() => setConfirmOpen(false)}
                />
            )} */}
            <ListItem className="list_item">
                <h2 style={{ marginRight: 20 }}>{item?.number}</h2>
                <ListItemText className={classes.wrap}>
                    <span className={classes.title}>
                        <strong>{titles.NAME}:</strong>&nbsp;
                        {item?.name || messages.INFO_IS_NOT_FOUND}
                    </span>
                    <span className={classes.title}>
                        <strong>Статус этапа:</strong>&nbsp;
                        {statuses[item?.status] || messages.INFO_IS_NOT_FOUND}
                    </span>
                    <span className={classes.title}>
                        <strong>Плановая дата начала выполнения:</strong>&nbsp;
                        {item?.start_date_plan ? fullDateTime(item?.start_date_plan) : messages.INFO_IS_NOT_FOUND}
                    </span>
                    <span className={classes.title}>
                        <strong>Плановая дата окончания выполнения:</strong>&nbsp;
                        {item?.end_date_plan ? fullDateTime(item?.end_date_plan) : messages.INFO_IS_NOT_FOUND}
                    </span>
                    <span className={classes.title}>
                        <strong>Фактическая дата выполнения:</strong>&nbsp;
                        {item?.end_at ? fullDateTime(item?.end_at) : messages.INFO_IS_NOT_FOUND}
                    </span>
                </ListItemText>
            </ListItem>
        </>
    );
};

export default StageOrdersInfo;