import React from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

// поле ввода в зависимости от типа
const ValueField = ({
    value,
    onChange,
    type,
    error,
    helperText,
    disabled,
}) => {

    const handleChange = (fn = v => v) => (e) => {
        const { value } = e.target;
        onChange(fn(value).toString());
    };

    const handleChangeCheckbox = (e) => {
        const { checked } = e.target;
        onChange(checked ? '1' : '0');
    };

    const int = (value) => Math.max(0, parseInt(value) || 0);

    const percent = (value) => {
        const v = parseInt(value) || 0;
        return Math.max(0, Math.min(100, v));
    };

    switch (type) {

        // да/нет
        case 'boolean':
            return (
                <FormControlLabel
                    style={{ pointerEvents: 'none' }}
                    control={
                        <Checkbox
                            checked={parseInt(value) ? true : false}
                            onChange={handleChangeCheckbox}
                            color="primary"
                            style={{ pointerEvents: 'auto' }}
                        />
                    }
                    label="Да/Нет"
                />
            );

            // число
            // case 'integer':
            //     return (
            //         <FormInfoWrapper
            //             error={error}
            //             helperText={helperText || 'Введите число'}
            //         >
            //             <TextField
            //                 value={value}
            //                 onChange={handleChange(int)}
            //                 variant="outlined"
            //                 size="small"
            //                 required
            //                 error={error || !value}
            //                 type="number"
            //                 disabled={disabled}
            //             />
            //         </FormInfoWrapper>
            //     );

        // процент
        case 'percent':
            return (
                <FormInfoWrapper
                    error={error}
                    helperText={helperText || (!disabled && 'Введите число от 0(%) до 100(%)')}
                >
                    <TextField
                        value={value}
                        onChange={handleChange(percent)}
                        variant="outlined"
                        size="small"
                        required
                        error={error || !value}
                        type="number"
                        disabled={disabled}
                    />
                </FormInfoWrapper>
            );

        // число
        default:
            return (
                <FormInfoWrapper
                    error={error}
                    helperText={helperText || 'Введите число'}
                >
                    <TextField
                        value={value}
                        onChange={handleChange(int)}
                        variant="outlined"
                        size="small"
                        required
                        error={error || !value}
                        type="number"
                        disabled={disabled}
                    />
                </FormInfoWrapper>
            );
            // return (
            //     <FormInfoWrapper
            //         error={error}
            //         helperText={helperText}
            //     >
            //         <TextField
            //             value={value}
            //             onChange={handleChange()}
            //             variant="outlined"
            //             size="small"
            //             disabled={disabled}
            //             required
            //             error={error || !value}
            //         />
            //     </FormInfoWrapper>
            // );
    }
};

export default ValueField;
