import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import { loadParksList } from 'redux/TransportPassenger/actions';
import UniversalSelect from 'components/common/UniversalSelect';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import titles from 'helpers/constants/titles';

import type { GetListArguments } from 'types/Autocomplete';

interface SelectParkProps<T> extends CommonAutocompleteProps<T> {
    filter?: Object;
}

interface Item {
    check_point?: Record<string, any>;
    check_point_id?: number;
    comment?: string;
    id?: number; // у нового парка нет айди
    name?: string;
    organization_ids?: number[];
    organizations:   Record<string, any>[];

}

const SelectPark = ({
    multiple = false,
    selected,
    onChange = () => {},
    required = false,
    disabled = false,
    label=titles.PARKS,
    className = '',
    error = false,
    helperText = '',
    renderLabel = (option: Item | null) => option?.name || '',
    limitTags = 1,
    filter = {},
    test_id_prefix = '',
}: SelectParkProps<Item>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);

    const prevData = useRef(selected);

    useEffect(() => {
        if (!isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const getList = (params: GetListArguments) => {
        const { page, limit, query: name } = params;
        dispatch(loadParksList(page, limit, {
            ...(name && { name }),
            ...filter,
        }));
    };

    const handleAccept = (data: Item | Item[]) => {
        onChange(data);
        setShowList(false);
    };

    const handleClose = () => {
        setShowList(false);
    };

    const onReset = () => {
        const initialState = multiple ? [] : {};
        onChange(initialState as Item);
    };

    return (
        <>
            <CommonAutocomplete<Item>
                multiple={multiple}
                className={className}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="park"
                disabled={disabled}
                onReset={onReset}
                label={label}
                renderLabel={renderLabel}
                limitTags={limitTags}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
                test_id_prefix={test_id_prefix}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    title={label}
                    fetchList={getList}
                    storeName="transportPassenger"
                    storeNameProps="parks"
                    storeLoadingProps="loadingParks"
                    keyProp="id"
                    searchTitle="Поиск по названию"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    renderProps={(el) => <div><span>{el.name}</span></div>}
                />
            }
        </>
    );
};

export default SelectPark;
