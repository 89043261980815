import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material';

import { incidentsSelectors } from 'redux/Incidents';
import { loadDtpStreets } from 'redux/Incidents/actions';
import DateRange from 'components/common/Dates/DateRange';
import { TypeLoad } from 'components/common/Autocomplete/DTP';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import titles from 'helpers/constants/titles';


const Filter = ({ dtpTypes, setParams, initialState, prepareData }) => {
    const dispatch = useDispatch();

    const dtpStreets = useSelector(incidentsSelectors.dtpStreets);
    const dtpStreetsLoading = useSelector(incidentsSelectors.dtpStreetsLoading);

    const [data, setData] = useState(initialState);

    const handleChangeData = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const handleGetStreets = (e) => dispatch(loadDtpStreets(e.target.value));

    const search = (filters = data) => {
        const paramsWithoutEmpty = prepareData(filters);
        setParams(paramsWithoutEmpty);
    };

    const resetFilter = (needRefresh) => {
        setData(initialState);
        if (needRefresh) {
            setParams(initialState);
        }
    };

    const updateFilter = (filters) => {
        setData({ // обновляем локальный стейт
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            setParams({ // отправляем в родителя, чтобы произошел запрос
                ...initialState,
                ...filters,
            });
        }
    };

    const isDisabled = (data.start_date && data.end_date) && (new Date(data.start_date) >= new Date(data.end_date));

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={search}
            filter={data}
            setUserFilter={updateFilter}
            disabled={isDisabled}
            filterException={['date_start', 'date_end',]}
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(value) => handleChangeData('start_date', value)}
                    handleEndDate={(value) => handleChangeData('end_date', value)}
                    valueStartDate={data.start_date}
                    valueEndDate={data.end_date}
                />
                <CommonAutocomplete
                    multiple
                    selected={data.selectedTypes || []}
                    options={dtpTypes}
                    onChange={(value) => handleChangeData('selectedTypes', value)}
                    filterSelectedOptions
                    renderLabel={(option) => option.name}
                    limitTags={1}
                    label={titles.DTP_TYPE}
                    openOnFocus
                    showAsSelect
                />
                <TypeLoad
                    multiple
                    selected={data.selectedTypeLoad}
                    onChange={(value) => handleChangeData('selectedTypeLoad', value)}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <Autocomplete
                    multiple
                    value={data.street || []}
                    options={dtpStreets}
                    onChange={(e, value) => handleChangeData('street', value)}
                    filterSelectedOptions
                    getOptionLabel={(option) => option}
                    freeSolo
                    loading={dtpStreetsLoading}
                    loadingText="Загрузка..."
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Улица"
                            onChange={handleGetStreets}
                        />
                    )}
                />
                <TextField
                    variant="outlined"
                    value={data.house}
                    onChange={(e) => handleChangeData('house', e.target.value)}
                    label="Дом"
                    size="small"
                />
                <FormControlLabel
                    control={<Checkbox
                        checked={data.with_wounded}
                        onChange={(e) => handleChangeData('with_wounded', e.target.checked)}
                    />}
                    label="Наличие раненых"
                />

                <FormControlLabel
                    control={<Checkbox
                        checked={data.with_dead}
                        onChange={(e) => handleChangeData('with_dead', e.target.checked)}
                    />}
                    label="Наличие погибших"
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
