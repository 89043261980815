interface Identifiable {
    id: number;
}

/**
 * Функция бинарного поиска
 * @param sortedArray - Отсортированный массив объектов, у которых есть поле id
 * @param targetId - id элемента, который нужно найти
 * @returns Найденный объект или null, если элемент не найден
 */
function binarySearch<T extends Identifiable>(sortedArray: T[], targetId: number): T | null {
    let left = 0;
    let right = sortedArray.length - 1;

    while (left <= right) {
        const middle = Math.floor((left + right) / 2);

        if (sortedArray[middle].id === targetId) {
            return sortedArray[middle];
        } else if (sortedArray[middle].id < targetId) {
            left = middle + 1;
        } else {
            right = middle - 1;
        }
    }

    return null;
}

export default binarySearch;
