import React from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { incidentsSelectors } from 'redux/Incidents';
import messages from 'helpers/constants/messages/common';
import titles from 'helpers/constants/titles';

const useStyles = makeStyles({
    cell: {
        borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
});

function TableData() {
    const classes = useStyles();

    const dtpReportByArea = useSelector(incidentsSelectors.dtpReportByArea);

    return (
        <TableContainer className="table_container">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.cell} align="left">Название района</TableCell>
                        <TableCell className={classes.cell} align="left">{titles.DTP_TYPE}</TableCell>
                        <TableCell className={classes.cell} align="left">Раненные</TableCell>
                        <TableCell align="left">Погибшие</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dtpReportByArea?.map((item, i) => {
                        return (
                            <TableRow key={i}>
                                <TableCell className={classes.cell}>
                                    {item?.area || messages.INFO_IS_NOT_FOUND}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {item?.dtp_type_name || messages.INFO_IS_NOT_FOUND}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {item?.wounded ?? messages.NO_DATA}
                                </TableCell>
                                <TableCell>
                                    {item?.dead ?? messages.NO_DATA}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TableData;