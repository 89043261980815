import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Collapse,
    IconButton,
    ListItemText,
    ListItem,
    List,
} from '@mui/material/';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import { deleteRole } from 'redux/Admin/actions';
import titles from 'helpers/constants/titles';
import { getHumanDate } from 'helpers/date.config';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import styles from './roles.module.scss';
import Form from './EditorForm';

const ListItemCollapse = ({ isOpen, role }) => {
    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit className={styles.list_info}>
            <div className={styles.details}>
                {role.created_at && <div><strong>{titles.CREATED_AT}</strong>: {getHumanDate(role.created_at)}</div>}
                {role.service_name && <div><strong>{titles.SERVICE}</strong>: {role.service_name}</div>}
                <div>
                    <h3>{titles.PERMISSION}:</h3>
                    <List>
                        {role.permissions.map((el) => <ListItem dense key={el.id}>{'- '}{el.description}</ListItem>)}
                    </List>
                </div>
            </div>
        </Collapse>
    );
};

const RoleItem = ({ role, reloadList }) => {
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false);
    const [modalVisible, showModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const clickEdit = (e) => {
        e.stopPropagation();
        showModal(true);
    };

    const clickDelete = (e) => {
        e.stopPropagation();
        setOpenDeleteModal(true);
    };

    return (
        <>
            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onSuccess={() => {
                        dispatch(deleteRole(role.id, () => {
                            reloadList();
                            setOpenDeleteModal(false);
                        }));
                    }}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
            {/* модальное окно редактирования элемента */}
            {modalVisible
                && <Form
                    isOpen={modalVisible}
                    role={role}
                    onClose={() => showModal(false)}
                    reloadList={reloadList}
                />
            }
            <ListItem className="list_item" onClick={() => setCollapsed(!collapsed)}>
                <ListItemText>
                    <div>{role.description}</div>
                </ListItemText>
                {!role.is_system && (
                    <>
                        <FormButtonsComponent
                            noMarginLeft
                            justButton
                            buttons={[
                                {
                                    ...buttonsTypes.editIcon,
                                    onClick: clickEdit
                                },
                                {
                                    ...buttonsTypes.deleteIcon,
                                    onClick:clickDelete
                                }
                            ]}
                        />
                    </>
                )}
                <IconButton size={'small'}>
                    {collapsed
                        ? (
                            <ExpandLess fontSize={'inherit'} />
                        )
                        : (
                            <ExpandMore fontSize={'inherit'} />
                        )
                    }
                </IconButton>
            </ListItem>
            <ListItemCollapse isOpen={collapsed} role={role} />
        </>
    );
};

export default RoleItem;
