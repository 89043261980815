import React from 'react';
import { useSelector } from 'react-redux';
import {
    loadVehicleCategoryList,
    createVehicleCategory,
    editVehicleCategory,
    deleteVehicleCategory,
} from '../../../../../redux/TransportRoad/actions';
import { transportRoadSelectors } from '../../../../../redux/TransportRoad';
import VehicleCategories from '../../../../common/Transport/VehicleCategories';

const VehicleTypes = () => {

    const list = useSelector(transportRoadSelectors.vehicleCategoryList);
    const loading = useSelector(transportRoadSelectors.loadingVehicleCategoryList);

    return (
        <>
            <VehicleCategories 
                list={list}
                loading={loading}
                loadList={loadVehicleCategoryList}
                createCategory={createVehicleCategory}
                editCategory={editVehicleCategory}
                deleteCategory={deleteVehicleCategory}
                fromService={'roadWorks'}
            />
            
        </>
    );
};

export default VehicleTypes;
