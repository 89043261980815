import useShowcase from 'helpers/hooks/useShowcase';
import PageTitle from 'components/common/PageTitle';

// заголовок витрины
const HeaderTitle = ({
    title,
}) => {
    // витрина
    const isShowcase = useShowcase();

    if (isShowcase === false && title) {
        return <PageTitle title={title} />;
    }

    return null;
};

export default HeaderTitle;