import { useCallback, useEffect, useState } from 'react';
import { FormControl } from '@mui/material';
import { add, getHours, getMinutes, sub } from 'date-fns';

import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import formatDate from 'helpers/constants/dateFormat';
import { 
    fromDateToFormat, 
    getDateObjectFromFormat, 
    getFormatFromDate, 
    getTimeWithoutSeconds,
    isDateValid,
} from 'helpers/date.config';

const EditEventTimeModal = ({
    isOpen, 
    onClose, 
    data, 
    setData,
    temporaryData,
    setTemporaryData,
    indexOfElement,
    recalculation,
}) => {
    const editItem = () => {
        const array = [ ...temporaryData ];
        array.splice(indexOfElement, 1, {
            ...data,
            time_start: getTimeWithoutSeconds(data.time_start),
            time_end: getTimeWithoutSeconds(data.time_end),
            duration: getTimeWithoutSeconds(data.duration),
            is_new: true,
        });
        const newArray = recalculation(array);
        setTemporaryData(newArray);
        onClose();
    };

    //блок работы с датой
    const [isErrorDate, setIsErrorDate] = useState(false); //для сохранения ошибок в дате
    
    const checkInvalidDate = useCallback((date) => {
        return date && !isDateValid(date);
    }, []);

    useEffect(() => {
        setIsErrorDate(!!checkInvalidDate(data?.duration));

    }, [checkInvalidDate, data?.duration]);

    const getDuration = (date, durationDate) => {
        // одна дата минус другая получаем разницу
        return sub(date, {
            hours: getHours(durationDate),
            minutes: getMinutes(durationDate),
        });
    };

    const getDateWithDuration = (date, durationDate = '00:00') => {
        // дата плюс интервал получаем вторую дату
        const [hours, minutes] = durationDate.split(':');
        return add(date, {
            hours,
            minutes
        });
    };

    const convertToTime = (date) => {
        // получаем duration независимо от зоны проекта в формате 'HH:mm'
        return fromDateToFormat(date, 'HH:mm');
    };

    const setDuration = (date, dateKey) => {
        const duration = getDuration(date, data[dateKey]);
        return getDateObjectFromFormat(convertToTime(duration), 'HH:mm');
    };

    const setDateWithDuration = (date, durationDate) => {
        // получаем duration в зоне проекта в формате 'HH:mm'
        const duration = getFormatFromDate(durationDate, 'HH:mm'); 
        return getDateWithDuration(date, duration);
    };

    const isDisabled = !data?.duration
        || data?.duration === 'Invalid Date'
        || !data?.duration
        || !data?.time_end
        || data?.time_end === 'Invalid Date'
        || isErrorDate;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title="Редактировать событие"
            small={true}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: editItem,
                            disabled: isDisabled
                        },
                    ]}
                />
            }
        >
            <form className="modal__form">
                <FormControl className="block" size="small" variant="outlined">
                    <SingleKeyboardDateTimePicker
                        timeOnly
                        isDisabled
                        label="Время начала (ЧЧ:ММ)"
                        pickerFormat={formatDate.HH_MM}
                        value={data?.time_start || null}
                    />
                </FormControl>
                <FormControl className="block" size="small" variant="outlined">
                    <SingleKeyboardDateTimePicker
                        timeOnly
                        label="Продолжительность (ЧЧ:ММ)"
                        pickerFormat={formatDate.HH_MM}
                        value={data?.duration || null}
                        onChange={(e) => setData({
                            ...data,
                            duration: e,
                            time_end: setDateWithDuration(data.time_start, e)
                        })}
                        error={checkInvalidDate(data?.duration) || !data?.duration}
                    />
                </FormControl>
                <FormControl className="block" size="small" variant="outlined">
                    <SingleKeyboardDateTimePicker
                        timeOnly
                        label="Время окончания (ЧЧ:ММ)"
                        pickerFormat={formatDate.HH_MM}
                        value={data?.time_end || null}
                        onChange={(e) => {
                            setData({
                                ...data,
                                time_end: e,
                                duration: setDuration(e, 'time_start')
                            });
                        }}
                        error={checkInvalidDate(data?.time_end) || !data?.time_end}
                    />
                </FormControl>
            </form>
        </Modal>
    );
};

export default EditEventTimeModal;
