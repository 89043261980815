import React, { useEffect, useState } from 'react';
import { config } from '../../../../../config';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { Button, Typography, LinearProgress } from '@mui/material';
import Modal from '../../../../common/Modal';
import AddressList from './AddressList';
// import AddressSearch from './AddressSearch';
import TextCopyClipboard from './TextCopyClipboard';
import FormButtonsComponent from '../../../../common/FormButtons';
import { useDispatch, useSelector } from 'react-redux';
import { loadTelegramId } from '../../../../../redux/Telegram/actions';
import { telegramSelectors } from '../../../../../redux/Telegram';
import { roadNetworksSelectors } from '../../../../../redux/RoadNetwork';
import StreetSearch from './StreetSearch';
import { loadStreetList, clearStreetList } from '../../../../../redux/RoadNetwork/actions';

const Subscribe = ({
    show = false,
    onClose = () => {},
    latlon = null,
}) => {
    const dispatch = useDispatch();

    const loading = useSelector(telegramSelectors.loading);
    const streetList = useSelector(roadNetworksSelectors.streetList);
    const loadingStreetList = useSelector(roadNetworksSelectors.loadingStreetList);

    const [changedAddress, setChangedAddress] = useState(false);
    const [address, setAddress] = useState([]);
    const [telegramId, setTelegramId] = useState('');

    const telegramLink = config.get('overlapSubscribeLink') || null;

    const goToTelegram = () => {
        onClose?.();
        window.open(`${telegramLink}${telegramId ? `?start=subs_code=${telegramId}` : ''}`,'_blank');
    };

    useEffect(() => () => {
        setAddress([]);
        setChangedAddress(false);
    }, []);

    // загрузка списка
    useEffect(() => {
        if (latlon) {
            const { lat, lng } = latlon;
            dispatch(loadStreetList(lat, lng, 500));
            setChangedAddress(true);

            return () => {
                dispatch(clearStreetList());
            };
        }
    }, [latlon]);

    // применить список
    useEffect(() => {
        if (
            latlon
            && loadingStreetList === false
            && streetList.length > 0
        ) {
            setAddress(streetList.map(({ street }) => street));
            dispatch(clearStreetList());
        }
    }, [latlon, loadingStreetList, streetList]);

    const handleDelete = (index) => {
        setAddress(old => ([
            ...old.slice(0, index),
            ...old.slice(index + 1)
        ]));
        setChangedAddress(true);
        setTelegramId('');
    };

    const handleGetId = () => {
        // load
        const addressList = address.map((street) => street);
        dispatch(loadTelegramId(addressList, ({ hash }) => {
            if (hash) {
                setTelegramId(hash);
                setChangedAddress(false);
            }
        }));
    };

    return (
        <Modal
            // heightMedium
            isOpen={show}
            onClose={onClose}
            title="Подписка"
            small={true}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                    ]}
                    beforeTemplate={
                        <FormButtonsComponent
                            noPadding
                            justButton
                            buttons={[
                                {
                                    onClick: goToTelegram,
                                    name: 'Перейти в Телеграм',
                                }
                            ]}
                        />
                    }
                />
            }
        >

            {/* поиск - добавление */}
            {/*<AddressSearch*/}
            {/*    onAdd={(address) => {*/}
            {/*        setAddress(old => ([*/}
            {/*            ...old,*/}
            {/*            address,*/}
            {/*        ]));*/}
            {/*        setChangedAddress(true);*/}
            {/*    }}*/}
            {/*/>*/}

            {!latlon && (
                <StreetSearch
                    onAdd={(street) => {
                        setAddress(old => ([
                            ...old,
                            street,
                        ]));
                        setChangedAddress(true);
                    }}
                />
            )}

            {loadingStreetList && <LinearProgress/>}

            {/* список адресов */}
            <AddressList
                list={address}
                onDelete={handleDelete}
            />

            {loading && <LinearProgress/>}

            {changedAddress && address.length > 0 && (
                <div>
                    <Button onClick={handleGetId}>
                        Получить код для телеграм-бота
                    </Button>
                </div>
            )}

            {telegramId && !changedAddress && (
                <>
                    <Typography>
                        <span><strong>Код: </strong>{telegramId} </span>
                        <TextCopyClipboard
                            text={telegramId}
                        />
                    </Typography>
                    <Typography>
                        Введите данный код в телеграм-бот для подписки
                    </Typography>
                </>
            )}
        </Modal>
    );
};

export default Subscribe;