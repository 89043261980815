import { Provider } from 'react-redux';
import { store } from 'store';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { theme } from '../../../App';

const MapProviders = ({ children }) => (
    <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterLuxon} locale={'ru'}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    {children}
                </ThemeProvider>
            </StyledEngineProvider>
        </LocalizationProvider>
    </Provider>
);

export default MapProviders;
