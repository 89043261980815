import titles from '../../../helpers/constants/titles';

const SidebarTitle = (props) => {
    const {
        title,
        list = null,
        count = null, // количество вместо листа
        titleTotal = titles.TOTAL_RECORDS,
        buttons = null,
        infoPanel = null,
    } = props;

    const total = list?.meta?.total || count;

    const style = !!buttons
        ? { display: 'flex', justifyContent: 'space-between' }
        : {};

    return (
        <div className="layers-sidebar__title" style={style}>
            <div>
                <div className="title_container">
                    <div className="title">
                        {title}
                    </div>
                    {infoPanel}
                </div>
                {total !== null
                    ? (
                        <div className="total">({titleTotal}: {total})</div>
                    )
                    : ''
                }
            </div>
            {buttons ? <div>{buttons}</div> : null}
        </div>
    );
};

export default SidebarTitle;
