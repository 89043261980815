const commonTitles = {
    METEO: 'Метео',
    ECOLOGY: 'Экология',
    FILTER: 'Фильтр',
    ADD: 'Добавить',
    CREATE: 'Создать',
    COPY: 'Копировать',
    EDIT: 'Редактировать',
    CREATED_AT: 'Создан',
    UPDATED_AT: 'Изменён',
    DELETED_AT: 'Удален',
    CREATED_BY: 'Создано',
    UPDATED_BY: 'Изменено',
    DELETED_BY: 'Удалено',
    PHONES: 'Телефоны',
    PHONE: 'Телефон',
    PHONE_NUMBER: 'Номер телефона',
    NUMBER: 'Номер',
    EMAIL: 'E-mail',
    ID: 'ID',
    KIND: 'Вид',
    TELEGRAM_ID: 'Telegram ID',
    SERVICE: 'Сервис',
    MICROSERVICES: 'Микросервисы',
    DESCRIPTION: 'Описание',
    ACTIVE: 'активен',
    INACTIVE: 'не активен',
    ICON: 'Иконка',
    STATUS: 'Статус',
    SELECT_STATUS: 'Выберите статус',
    PROJECT_NAME: 'ДОРИС',
    NOTIFICATIONS: 'Уведомления',
    INFORMATION: 'Информация',
    EVENTS: 'События',
    EVENT: 'Мероприятие',
    NOT_CHOSEN: 'Не выбрано',
    SELECT_TYPE: 'Выберите тип',
    SELECT_FORMAT: 'Выберите формат',
    FORMAT: 'Формат',
    TOTAL_RECORDS: 'Всего записей',
    TOTAL: 'Всего',
    BACK_TO_MENU: 'Вернуться к меню',
    COMMENT: 'Комментарий',
    LINK: 'Ссылка',
    REQUIRED: 'Обязательное поле',
    NAME: 'Название',
    TITLE: 'Наименование',
    SHORT_TITLE: 'Краткое наименование',
    NO_RESULTS: 'Нет результатов',
    AUTHOR: 'Автор',
    COMMENT_DELETE: 'Напишите комментарий перед удалением',
    EVENT_REPORT: 'Отчет по событиям',
    PUSH_FOR_BACK: 'Нажмите, чтобы вернуться',
    HISTORY_OF_CHANGES: 'История изменений',
    EXTERNAL_ID: 'Внешний идентификатор',
    DELETE: 'Удалить',
    NOT_INDICATED: 'не указано',
    OLD_VALUE: 'Старое значение',
    NEW_VALUE: 'Новое значение',
    START_DATE: 'Дата начала',
    END_DATE: 'Дата окончания',
    SELECT_STATE: 'Выберите состояние',
    STATE: 'Состояние',
    CLICK_TO_SELECT: 'Нажмите, чтобы выбрать',
    CLICK_TO_OPEN: 'Нажмите, чтобы открыть',
    CLEAR_FIELD: 'Очистить поле',
    ATTACHMENTS: 'Вложения',
    SHOW_ON_MAP: 'Посмотреть на карте',
    SHOW: 'Показать',
    TYPE: 'Тип',
    CATEGORY: 'Категория',
    ATTACH_FILE: 'Прикрепить файл',
    ATTACH_IMG_FILE: 'Прикрепить изображение',
    ATTACH_SCHEMA_ODD: 'Добавить схему ОДД',
    SCHEMA_ODD_FOR_DIT: 'Схема ОДД для вывода на ДИТ',
    FILES: 'Файлы',
    POSITION: 'Должность',
    SELECT_POSITION: 'Выбрать должность',
    EMPLOYEES: 'Сотрудники',
    IDENTIFICATION: 'Удостоверение',
    PERSONNEL_NUMBER: 'Табельный номер',
    LABEL: 'Метка',
    ENTERPRISES: 'Обслуживающие предприятия',
    INTERNATIONAL_NAME: 'Международное наименование',
    SHIFT: 'Смена',
    START: 'Начало',
    END: 'Окончание',
    DATA_SAVED_SUCCESSFULLY: 'Данные успешно сохранены',
    TEXT: 'Текст',
    SELECTED_OPTIONS: 'Выбранные параметры',
    AMOUNT: 'Количество',
    PERCENTS: 'Проценты',
    MESSAGES: 'Сообщения',
    MODE: 'Режим работы',
    CLEAR: 'Очистить',
    RESPONSIBLE: 'Ответственный',
    SELECT_ERRORS: 'Выбрать ошибки',
    SETTINGS: 'Настройки',
    ADD_STATUS: 'Добавить статус',
    EDIT_STATUS: 'Изменить статус',
    IMAGE: 'Изображение',
    MORE_INFO: 'Дополнительная информация',
    UNIQUE_ALIAS: 'Уникальный псевдоним',
    COUNT: 'Количество',
    POPULATION: 'Население',
    ACTION: 'Действие',
    ACTIONS: 'Действия',
    START_TIME: 'Время начала',
    END_TIME: 'Время окончания'
};

export default commonTitles;
