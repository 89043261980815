export const focusIncidents = (state) => state.focusIncidents;

export const sidebar = (state) => focusIncidents(state).sidebar;
export const sidebarData = (state) => sidebar(state).data;
export const sidebarMeta = (state) => sidebar(state).meta;
export const sidebarLoading = (state) => focusIncidents(state).sidebarLoading;

export const polygon = (state) => focusIncidents(state).polygon;
export const polygonLoading = (state) => focusIncidents(state).polygonLoading;
export const polygonVisible = (state) => focusIncidents(state).polygonVisible;
export const active = (state) => focusIncidents(state).active;

export const deleteForm = (state) => focusIncidents(state).deleteForm;
export const editForm = (state) => focusIncidents(state).editForm;
export const saved = (state) => focusIncidents(state).saved;

export const filters = (state) => focusIncidents(state).filters;

export const loading = (state) => focusIncidents(state).loading;

export const dtpFocusList = (state) => focusIncidents(state).dtpFocusList;
export const dtpFocusLoading = (state) => focusIncidents(state).dtpFocusLoading;

export const statistic = (state) => focusIncidents(state).statistic;
export const statisticLoading = (state) => focusIncidents(state).statisticLoading;
export const heatMap = (state) => focusIncidents(state).heatMap;
export const heatMapFilter = (state) => focusIncidents(state).heatMapFilter;
export const loadingHeatMap = (state) => focusIncidents(state).loadingHeatMap;
