import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadBrandsList } from 'redux/TransportRoad/actions';
import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import type { Item, MarkTypes } from './types';

const MarkSelect = ({
    title = 'name_en',
    selected = {},
    onChange = () => {},
    required = false,
    label = titles.SELECT_MARK,
    error = false,
    helperText = '',
    moduleName = '',
    action = loadBrandsList, // по умолчанию roadworks
    multiple = false,
    disabled = false,
    limitTags = 1,
    className = '',
}: MarkTypes<Item>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);

    const fetchList = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query } = params;

        dispatch(action(page, limit, { ...(query && { query }) }));
    };

    const handleAccept = (data: any) => {
        onChange(data);
        setShowList(false);
    };

    const handleClose = () => setShowList(false);

    const onReset = () => {
        const initialState = multiple ? [] : '';
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete<Item>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={(option) => option?.[title] || ''}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
                className={className}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    storeName={moduleName}
                    storeNameProps={'brands'}
                    keyProp={'id'}
                    fetchList={fetchList}
                    withSearch={true}
                    isSelected={true}
                    selected={selected}
                    renderProps={(el) => <div><span>{el.name_en}</span></div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    title={titles.SELECT_MARK}
                />
            }
        </>)
    ;
};

export default MarkSelect;
