import React, { useMemo, useState } from 'react';
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import TypeComponent from '../TypeComponent';

// фильтр
const Filter = ({
    data = [],
    filter = {},
}) => {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleRadioChange = (event) => setSelectedIndex(event.target.value);

    const renderFilter = useMemo(() => {
        const {
            label = '',
            data = [],
        } = filter || {};

        if (data.length > 0) {

            return (
                <FormControl
                    className="row"
                    style={{
                        alignItems: 'center',
                        marginTop: '0'
                    }}
                >
                    {!!label && <FormLabel>{label}</FormLabel>}
                    <RadioGroup
                        row
                        value={selectedIndex}
                        onChange={handleRadioChange}
                    >
                        {data?.map((name, index) => (
                            <FormControlLabel
                                key={index}
                                value={index}
                                control={<Radio/>}
                                label={name}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            );
        }

        return null;
    }, [filter, selectedIndex]);

    const currentData = useMemo(() => data?.[selectedIndex] || {}, [selectedIndex, data]);

    const st = {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
    };

    return (
        <div style={st}>
            {renderFilter}
            <div style={st}>
                <TypeComponent
                    {...currentData}
                />
            </div>
        </div>
    );
};

export default Filter;
