import React, { useState, ChangeEvent, useCallback } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

import { loadVideoCamerasDictionaryElectricalOptionalSystem } from 'redux/RoadNetwork/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

import type { FilterProps, FilterState } from './types';

const initialState: FilterState = {
    name: '',
    type_id: '',
};

const Filter = ({ setParams }: FilterProps) => {
    const types = useStoreProp(
        loadVideoCamerasDictionaryElectricalOptionalSystem,
        'roadNetworks',
        'videoCamerasDictionaryElectricalOptionalSystem'
    );

    const [data, setData] = useState<FilterState>(initialState);

    const handleChange = (name: keyof FilterState, value: string) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const onSearch = useCallback((filters: FilterState = data) => {
        if(data.name || data.type_id) setParams({ page: 1, data: removeEmptyFields(filters) });
    }, [data, setParams]);

    const resetFilter = useCallback((needRefresh: boolean) => {
        if (needRefresh) {
            onSearch(initialState);
        }
        setData(initialState);
    }, [onSearch]);

    const updateFilter = useCallback((filters: Partial<FilterState>) => {
        const newFilter = { ...initialState, ...filters };
        setData(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    }, [onSearch]);


    return (
        <LayoutFilter
            filter={data}
            onSearch={() => onSearch()}
            onResetFilter={resetFilter}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={data.name}
                    name="name"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('name', e.target.value)}
                    label={titles.NAME}
                    size="small"
                    variant="outlined"
                />

                <FormControl size="small" variant="outlined">
                    <InputLabel>{titles.TYPE}</InputLabel>
                    <Select
                        value={data.type_id}
                        name="type_id"
                        onChange={(e: SelectChangeEvent<string>) => handleChange('type_id', e.target.value)}
                        label={titles.TYPE}
                    >
                        <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                        {types && Object.keys(types).map((key) => (
                            <MenuItem key={key} value={key}>{types[key]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default React.memo(Filter);
