import classNames from 'classnames';
import { Badge, Button } from '@mui/material';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SaveFilterButton from './SaveFilterButton';
import styles from './styles.module.scss';
interface ActionButtonsPanelProps {
    showInvisible?: boolean,
    invisibleHidden?: boolean,
    count?: number,
    onShowVertical?: () => void,
    onShowHorizontal?: () => void
    onResetMode?: () => void
    onResetFilter?: () => void
    onSaveFilter?: () => void
    canSave?: boolean
    disabled?: boolean
    noDisabled?: boolean
    testIdPrefix?: string
}

const ActionButtonsPanel = ({
    showInvisible,
    invisibleHidden,
    count = 0,
    onShowVertical,
    onShowHorizontal,
    onResetMode,
    onResetFilter,
    onSaveFilter,
    canSave,
    disabled,
    noDisabled,
    testIdPrefix
}: ActionButtonsPanelProps) => {
    return (
        <div className={styles.buttonsWrap}>
            {showInvisible
                && <div>
                    {invisibleHidden
                        && <>
                            <Button
                                variant="text"
                                color="infoButton"
                                className={styles.icon}
                                onClick={onShowVertical}
                                { ...{ 'data-testid': `${testIdPrefix}/show-horizontal/button` } }
                            >
                                <i className="far fa-angle-left" />
                            </Button>
                            <span className={styles.openText}>Все параметры</span>
                        </>
                    }
                    <Button
                        variant="text"
                        color="infoButton"
                        className={styles.icon}
                        onClick={invisibleHidden ? onShowHorizontal : onResetMode}
                        { ...{ 'data-testid': `${testIdPrefix}/${invisibleHidden ? 'all-parameters' : 'hide-parameters'}/button` } }
                    >
                        {invisibleHidden
                            ? <i className={classNames(styles.icon, 'far fa-angle-down')} />
                            : <>
                                <span className={styles.openText}>Свернуть</span>
                                <i className={classNames(styles.icon, 'far fa-angle-up')} />
                            </>
                        }
                    </Button>
                </div>
            }
            {count > 0
                && <Badge
                    badgeContent={count}
                    color="error"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    classes={{ badge: styles.badge }}
                >
                    <div className={styles.badgeBlock}></div>
                </Badge>
            }
            <div>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.reset,
                            onClick: onResetFilter,
                            'data-testid': `${testIdPrefix}/reset/button`
                        },
                    ]}
                    positionLeft
                    noPadding
                    showDisabled
                />
            </div>
            {canSave
                && <SaveFilterButton
                    disabled={noDisabled ? false : (count === 0 || disabled)}
                    onSave={onSaveFilter}
                    testIdPrefix={testIdPrefix}
                />
            }
        </div>
    );
};

export default ActionButtonsPanel;
