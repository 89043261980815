import { useState } from 'react';

import ConfirmDelete from 'components/common/ConfirmModal';
import {
    LSContentItem,
    LSContentColumn, ActionsButtons
} from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';
import { getHumanDate } from 'helpers/date.config';
import messages from 'helpers/constants/messages';

import ModalForm from './ModalForm';

const Item = ({ item, onEdited, onDeleted, userMail, permissions }) => {
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const handleDelete = () => {
        setIsDelete(false);
        onDeleted && onDeleted(item?.id);
    };

    const {
        email,
        date_start,
        // template_id,
        template = {},
    } = item;

    return (
        <LSContentItem>
            <LSContentColumn>
                {template?.template_text || messages.NO_DATA}
            </LSContentColumn>

            <LSContentColumn>
                {email || userMail || messages.NO_DATA}
            </LSContentColumn>

            <LSContentColumn>
                {date_start ? getHumanDate(date_start) : messages.NO_DATA}
            </LSContentColumn>

            <LSContentColumn isActions>
                <ActionsButtons
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setIsEdit(true),
                            // disabled: !permissions.is_update
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setIsDelete(true),
                            // disabled: !permissions.is_delete
                        },
                    ]}
                />

                {isEdit && (
                    <ModalForm
                        item={item}
                        isOpen={isEdit}
                        onSuccess={onEdited}
                        onClose={() => setIsEdit(false)}
                    />
                )}

                {isDelete && (
                    <ConfirmDelete
                        open={isDelete}
                        onClose={() => setIsDelete(false)}
                        onSuccess={handleDelete}
                    />
                )}
            </LSContentColumn>
        </LSContentItem>
    );
};

export default Item;
