import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createRole, editRole, getPermissions } from '../../../../redux/Admin/actions';
import styles from './roles.module.scss';
import FormButtons, { buttonsTypes } from '../../../common/FormButtons';
import { config } from '../../../../config';
import { Autocomplete } from '@mui/material';
import { FormGroup, FormControl, FormHelperText, TextField, InputAdornment, IconButton } from '@mui/material';
import Modal from '../../../common/Modal';
import titles from '../../../../helpers/constants/titles';
import useValidation from '../../../../helpers/hooks/useValidation';
import useStoreProp from '../../../../helpers/hooks/useStoreProp';
import UniversalSelect from '../../../common/UniversalSelect';

const Form = ({
    role = {},
    onClose = () => {},
    isOpen = false,
    reloadList
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const [isOpenUniversalSelect, setIsOpenUniversalSelect] = useState(false);

    const permissions = useStoreProp(
        getPermissions,
        'admin',
        'permissions'
    );

    const initialState = {
        service_name: config.get('systemName'),
        name: '',
        description: '',
        ...role,
        permission_id_list: role.permissions || [],
    };

    const [formData, setFormData] = useState(initialState);

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        if (validation[name]) validation.deleteKey(name);
    };

    const handleChangePermission = (_, value) => {
        if (validation['permission_id_list']) validation.deleteKey('permission_id_list');
        setFormData({ ...formData, permission_id_list: value });
    };

    const handleUniversalChangePermission = (el) => {
        setFormData({
            ...formData,
            permission_id_list: el
        });
        setIsOpenUniversalSelect(false);
    };

    const onSuccess = () => {
        const saveFn = role.id ? editRole : createRole;
        const callback = () => {
            reloadList();
            onClose();
        };

        const data = formData?.permission_id_list?.length
            ? {
                ...formData,
                permission_id_list: formData?.permission_id_list.map(item => item.id)
            }
            : formData;

        dispatch(saveFn(data, callback));
    };

    const searchPermission = (value) => {
        const { query: name } = value;
        dispatch(getPermissions({ name }));
    };

    const inputSettings = {
        name: {
            label: 'Название',
            required: true,
            disabled: !!formData.id
        },
        service_name: {
            label: 'Сервис',
            required: true,
            disabled: true
        },
        description: {
            label: 'Описание',
            required: false
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            title={role.id ? titles.EDIT_ROLE : titles.CREATE_ROLE}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onSuccess,
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormGroup>
                    {Object.keys(inputSettings).map((key) => {
                        const { required, label, disabled } = inputSettings[key];
                        return (
                            <FormControl className="block" variant={'outlined'} key={key}>
                                <TextField
                                    type="text"
                                    value={formData[key] || ''}
                                    name={key}
                                    onChange={handleChange}
                                    error={!!validation.get(key)}
                                    disabled={disabled}
                                    required={required}
                                    label={label}
                                    variant="outlined"
                                    size="small"
                                />
                                <FormHelperText className={styles.error}>{validation.get(key)}</FormHelperText>
                            </FormControl>);
                    })}
                </FormGroup>
                <div className="block">
                    <Autocomplete
                        multiple
                        value={formData.permission_id_list}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={[]}
                        filterSelectedOptions
                        getOptionLabel={(option) => option.description}
                        onChange={handleChangePermission}
                        freeSolo
                        size="small"
                        name="permission_id_list"
                        className={styles.autocomplete}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={!!validation.get('permission_id_list')}
                                size="small"
                                variant="outlined"
                                label="Доступы"
                                helperText={validation.get('permission_id_list')}
                                InputProps={{
                                    ...params.InputProps,
                                    classes: { root: styles.input },
                                    endAdornment: (
                                        <InputAdornment position={'end'} classes={{ root: styles.adornment }}>
                                            {formData.permission_id_list 
                                                && Object.keys(formData.permission_id_list).length > 0 
                                                    && <IconButton size="small" onClick={() => handleUniversalChangePermission([])}>
                                                        <i className="far fa-times"/>
                                                    </IconButton>
                                            }
                                            <IconButton size="small" onClick={() => setIsOpenUniversalSelect(true)}>
                                                <i className="fas fa-search-plus"/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                    {isOpenUniversalSelect
                        && <UniversalSelect
                            keyProp={'id'}
                            isSelected
                            withSearch
                            multiple
                            fetchList={searchPermission}
                            onChange={handleUniversalChangePermission}
                            onAccept={handleUniversalChangePermission}
                            selected={formData.permission_id_list}
                            renderProps={(el) => <div>{el.description}</div>}
                            sortedList={permissions}
                            isOpen={isOpenUniversalSelect}
                            onClose={() => setIsOpenUniversalSelect(false)}
                            noPadding
                            title={titles.SELECT_ACCESS_PERMISSIONS}
                            small
                        />
                    }
                </div>
            </div>
        </Modal>
    );
};

export default Form;
