import { config } from 'config';

const apiTransportWaterUrl = () => config.get('urls').apiTransportWaterUrl;

const apiUrls = {
    getVehicles: () => `${apiTransportWaterUrl()}/vehicle/list`,

    getVehicleTypes: () => `${apiTransportWaterUrl()}/vehicle/types`,

    createVehicle: () => `${apiTransportWaterUrl()}/vehicle`,

    editVehicle: (id) => `${apiTransportWaterUrl()}/vehicle/${id}`,

    deleteVehicle: (id) => `${apiTransportWaterUrl()}/vehicle/${id}`,

    getDockSidebar: () => `${apiTransportWaterUrl()}/dock/list`,

    getDockPolygon: () => `${apiTransportWaterUrl()}/dock/polygon`,

    getDockCoords: () => `${apiTransportWaterUrl()}/dock/coords`,

    getDockEvents: (id) => `${apiTransportWaterUrl()}/dock/${id}/events`,

};

export default apiUrls;
