import React from 'react';
import { ListItem, Checkbox, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';

const useStyles = makeStyles({
    item_text: {
        fontSize: '1rem'
    },    
});

const DefectItem = ({
    item,
    index,
    checked,
    showCheckbox,
    handleCheck = () => {},
    onClick = () => {},
    currentItem = null,
}) => {
    
    const styles = useStyles();
    const onCheck = (e) => {
        const { checked } = e.target;
        handleCheck(checked, item);
    };

    return (
        <>
            <ListItem
                selected={index % 2 === 0}
                style={{
                    background: currentItem?.id === item?.id ? '#ececec' : '',
                    cursor: currentItem ? 'pointer' : 'default',
                }}
            >
                {showCheckbox
                && <Checkbox
                    checked={checked}
                    onChange={onCheck}
                    color="primary"
                />}
                <ListItemText onClick={() => onClick(item)} disableTypography className={styles.item_text}>
                    <div><strong>{titles.NAME}:</strong> {item?.name || messages.NO_DATA}</div>
                    <div><strong>{'Группа'}:</strong> {item?.group?.name || messages.NO_DATA}</div>
                    <div><strong>{'Тип'}:</strong> {item?.type?.name || messages.NO_DATA}</div>
                </ListItemText>
            </ListItem>
        </>
    );
};

export default DefectItem;