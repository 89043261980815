import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { Card } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { CoPresentOutlined } from '@mui/icons-material';

import { loadComplexById } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import { CustomTab, CustomTabs } from 'components/common/Tabs';
import titles from 'helpers/constants/titles';

import Documents from './Documents/';
import Entities from './Entities';
import BasicData from './BasicData/';
import Unloading from './Unloading/';
import NotificationSettings from './NotificationSettings';
import History from './History';
import Events from './Events';
import Boards from './Boards';

const PassportTabs = ({
    item = {}, // объект комплекса
    id, // id комплекса
    tab = 'data',
    readOnly = false,
    onEdited = null, // изменение комплекса
    onDeleted = () => {},
    isButtonToGk = false,
    history = null,
    callback = null, // если переходим с карты, в некоторых случаях надо закрывать модалку с паспортом
}) => {
    const dispatch = useDispatch();
    const scrollRef = useRef(null);

    const loading = useSelector(dorisControlSelectors.loading_complexes);
    const loadingComplexById = useSelector(dorisControlSelectors.loadingComplexById);

    const tabPanelProps = (tabName) => ({
        role: 'tabpanel',
        id: `scrollable-force-tabpanel-${tabName}`,
        'aria-labelledby': `scrollable-force-tab-${tabName}`
    });

    const tabProps = (tabName) => ({
        id: `scrollable-force-tab-${tabName}`,
        'aria-controls': `scrollable-force-tabpanel-${tabName}`,
    });

    const [value, setValue] = useState( tab || 'data');

    const scrollTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        scrollTop();
    };

    // изменился комплекс - скролим вверх
    useEffect(() => {
        if (id) {
            scrollTop();
        }
    }, [id]);

    const [currentComplex, setCurrentComplex] = useState(item);
    const complex_id = item.id || id;

    useEffect(() => {
        if (item?.status && item?.status !== currentComplex?.status) {
            setCurrentComplex(item);
        }
    }, [currentComplex?.status, item]);

    useEffect(() => {
        // есть id - загружаем полный объект комплекса
        if (complex_id) {
            dispatch(loadComplexById(complex_id, (fullComplex) => {
                setCurrentComplex(fullComplex);
            }));
        }
    }, [complex_id, dispatch]);


    const handleEdited = () => {
        if (onEdited) {
            onEdited();
            dispatch(loadComplexById(complex_id, (fullComplex) => {
                setCurrentComplex(fullComplex);
            }));
        }
    };

    return (
        <Card variant="elevation" style={{ height: '100%', display: 'flex', flexDirection: 'column'/*, overflowY:'auto'*/ }} >
            <AppBar position="sticky" color="default" style={{ zIndex: 2 }}>
                <CustomTabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    variant="scrollable"
                    aria-label="scrollable force tabs example"
                >
                    <CustomTab
                        icon={<i className="fal fa-info-square"/>}
                        iconPosition="start"
                        value="data"
                        label={titles.BASIC_DATA}
                        {...tabProps('data')}
                    />
                    <CustomTab
                        icon={<i className="fal fa-file-alt" />}
                        iconPosition="start"
                        value="documents"
                        label={titles.DOCUMENTS}
                        {...tabProps('documents')}
                    />
                    <CustomTab
                        icon={<i className="fal fa-file-chart-line"/>}
                        iconPosition="start"
                        value="report"
                        label={titles.EVENT_REPORT}
                        {...tabProps('report')}
                    />
                    <CustomTab
                        icon={<i className="fal fa-router"/>}
                        iconPosition="start"
                        value="cameras"
                        label={'Оборудование'}
                        {...tabProps('cameras')}
                    />
                    {/*<CustomTab*/}
                    {/*    icon={<i className="fal fa-file-download"/>}*/}
                    {/*    iconPosition="start"*/}
                    {/*    value="unloading" label={titles.UNCOMING_FILTERS}*/}
                    {/*    {...tabProps('unloading')}*/}
                    {/*/>*/}
                    <CustomTab
                        icon={<i className="fal fa-history"/>}
                        iconPosition="start"
                        value="history"
                        label={titles.HISTORY_OF_CHANGES}
                        {...tabProps('history')}
                    />
                    {/* <CustomTab
                        icon={<i className="far fa-monitor-heart-rate"/>}
                        iconPosition="start"
                        value="monitoring"
                        label={titles.STATE_MONITORING}
                        {...tabProps('monitoring')}
                    /> */}
                    {/*<CustomTab*/}
                    {/*    icon={<i className="far fa-bell-on"/>}*/}
                    {/*    iconPosition="start"*/}
                    {/*    value="notifications"*/}
                    {/*    label={titles.NOTIFICATION_SETTINGS}*/}
                    {/*    {...tabProps('notifications')}*/}
                    {/*/>*/}
                    {/*<CustomTab*/}
                    {/*    icon={<i className="fas fa-border-none"/>}*/}
                    {/*    iconPosition="start"*/}
                    {/*    value="boards"*/}
                    {/*    label="ДИТ"*/}
                    {/*    {...tabProps('boards')}*/}
                    {/*/>*/}
                </CustomTabs>
            </AppBar>
            <div style={{ height: '100%', display: 'flex' }}>
                <Scrollbars style={{ height: '100%' }} ref={scrollRef} >
                    {value === 'data'
                        && <div {...tabPanelProps('data')}>
                            <BasicData
                                complex_id={complex_id}
                                readOnly={readOnly}
                                currentComplex={currentComplex}
                                loading={loading || loadingComplexById}
                                // комплекс отредактирован
                                onEdited={handleEdited}
                                onDeleted={onDeleted}
                                isButtonToGk={isButtonToGk}
                                history={history}
                                callback={callback}
                            />
                        </div>}
                    {value === 'documents'
                        && <div {...tabPanelProps('documents')}>
                            <Documents
                                complex_id={complex_id}
                                readOnly={readOnly}
                                // документы изменены
                                onChange={handleEdited}
                            />
                        </div>}
                    {value === 'cameras'
                        && <div {...tabPanelProps('cameras')}>
                            <Entities
                                currentComplex={currentComplex}
                                complex_id={complex_id}
                                readOnly={readOnly}
                                // камеры изменены
                                onChange={handleEdited}
                                tab="cameras"
                            />
                        </div>}
                    {value === 'unloading'
                        && <div {...tabPanelProps('unloading')}>
                            <Unloading
                                complex_id={complex_id}
                                readOnly={readOnly}
                                // произошло изменение
                                onChange={handleEdited}
                                itemComplex={currentComplex}
                            />
                        </div>}
                    {value === 'report'
                        && <div {...tabPanelProps('report')}>
                            <Events
                                complex_id={complex_id}
                                currentComplex={currentComplex}
                                tab="report"
                            />
                        </div>}
                    {value === 'history'
                        && <div {...tabPanelProps('history')}>
                            <History
                                complex_id={complex_id}
                                // изменение истории
                                onChange={handleEdited}
                            />
                        </div>}
                    {/* {value === 'monitoring' && (
                        <div
                            style={{ height: '100%', padding: '1.5rem' }}
                            {...tabPanelProps('monitoring')}
                        >
                            <Monitoring
                                complex_id={complex_id}
                                currentComplex={currentComplex}
                            />
                        </div>
                    )} */}
                    {value === 'notifications'
                        && <div {...tabPanelProps('notifications')}>
                            <NotificationSettings
                                complex_id={complex_id}
                                currentComplex={currentComplex}
                                // изменение настроек
                                onChange={handleEdited}
                            />
                        </div>}
                    {value === 'boards'
                        && <div {...tabPanelProps('boards')}>
                            <Boards
                                readOnly={readOnly}
                                name={item.name}
                                complex_id={complex_id}
                            />
                        </div>
                    }
                </Scrollbars>
            </div>
        </Card>
    );
};

export default PassportTabs;
