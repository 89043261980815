import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { govermentContracts } from 'redux/GovernmentContracts';
import { companiesSelectors } from 'redux/Companies';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { getHumanDate } from 'helpers/date.config';
import Loading from 'components/common/Loading';
import Attachments from 'components/common/Upload/Attachments';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import InfoBlock from 'components/common/InfoBlock';

import LinkObjectsModal from './LinkObjectsModal';

import type { ItemProps } from '../../types';

interface InfoProps {
    id: number;
    currentContract: ItemProps;
}

const BasicData = ({ id, currentContract }: InfoProps) => {
    const loadingContractById = useSelector(govermentContracts.loadingGovernmentContractById);
    const foundCompanies = useSelector(companiesSelectors.company);

    const [showLinkObjects, setShowLinkObjects] = useState(false);

    return (
        <div style={{ padding: '1.5rem' }}>
            <div className="block">
                {loadingContractById && <Loading linear />}

                <FormButtonsComponent
                    buttons={[{
                        ...buttonsTypes.add,
                        onClick: () => setShowLinkObjects(true),
                        name: 'Добавить связь',
                        disabled: currentContract?.external_id
                    }]}
                    positionLeft
                    noPadding
                />

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <div className="row align-items-center">
                            <strong>№: </strong>
                            <span>{currentContract.number || messages.NO_DATA}</span>
                        </div>

                        <div className="row align-items-center">
                            <strong>Название: </strong>
                            <span>{currentContract.name || messages.NO_DATA}</span>
                        </div>

                        <div className="row align-items-center">
                            <strong>Заказчик: </strong>
                            <span>{foundCompanies?.[currentContract.customer]?.title || messages.NO_DATA}</span>
                        </div>

                        <div className="row align-items-center">
                            <strong>Исполнитель: </strong>
                            <span>{foundCompanies?.[currentContract.contractor]?.title || messages.NO_DATA}</span>
                        </div>

                        <InfoBlock label="Даты" mt="1rem">
                            <div className="row align-items-center">
                                <strong>Дата начала: </strong>
                                <span>{currentContract.started_at ? getHumanDate(currentContract.started_at) : messages.NO_DATA}</span>
                            </div>

                            <div className="row align-items-center">
                                <strong>Дата окончания: </strong>
                                <span>{currentContract.ended_at ? getHumanDate(currentContract.ended_at) : messages.NO_DATA}</span>
                            </div>

                            <div className="row align-items-center">
                                <strong>Гарантия СМР: </strong>
                                <span>{currentContract.srm_guarantee
                                    ? getHumanDate(currentContract.srm_guarantee)
                                    : messages.NO_DATA}</span>
                            </div>

                            <div className="row align-items-center">
                                <strong>Гарантия оборудования: </strong>
                                <span>{currentContract.equipment_guarantee
                                    ? getHumanDate(currentContract.equipment_guarantee)
                                    : messages.NO_DATA}</span>
                            </div>

                            <div className="row align-items-center">
                                <strong>Дата подписания ГК: </strong>
                                <span>{currentContract.signed_at
                                    ? getHumanDate(currentContract.signed_at)
                                    : messages.NO_DATA}</span>
                            </div>
                        </InfoBlock>
                    </Grid>

                    <Grid item xs={6}>
                        <div className="row align-items-center">
                            <strong>Ссылка на госзакупки:</strong>
                            {currentContract?.procurement_link
                                ? <a href={currentContract?.procurement_link} target="_blank"
                                    rel="noreferrer">{currentContract?.procurement_link}</a>
                                : messages.NO_DATA
                            }
                        </div>

                        <div className="row align-items-center">
                            {currentContract?.files?.length
                                ? <Attachments
                                    label={'ссылки на файлы ГК'}
                                    files={currentContract?.files}
                                    readOnly
                                    renderContent={null}
                                    divider={false}
                                />
                                : <><strong>Ссылки на файлы ГК:</strong>&nbsp;{messages.NO_DATA}</>
                            }
                        </div>

                        <div>
                            <strong>Условия: </strong>{currentContract?.terms || messages.NO_DATA}<br/>
                            <strong>Признак "бессрочно": </strong>{currentContract?.is_forever ? 'Да' : 'Нет'}<br/>
                            <strong>Признак "удален": </strong>{currentContract?.is_deleted ? 'Да' : 'Нет'}<br/>
                            <strong>{titles.EXTERNAL_ID}: </strong>{currentContract?.external_id ? 'Есть' : 'Нет'}
                        </div>
                    </Grid>
                </Grid>
            </div>

            {showLinkObjects && (
                <LinkObjectsModal
                    contractEntities={currentContract.additional_entities}
                    isOpen={showLinkObjects}
                    onClose={() => setShowLinkObjects(false)}
                    idGC={id}
                    nameGC={currentContract.name}
                />
            )}
        </div>
    );
};

export default BasicData;
