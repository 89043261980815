import * as types from './types';

const initialState = {
    vehicles: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingVehicles: false,

    vehicleTypes: {},
    loadingVehicleTypes: false,

    filters: {},
    active: {},

    dockCurrentShow: {},
    dockColorRefresh: {},
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.LOADING_VEHICLES:
            return {
                ...state,
                loadingVehicles: payload,
            };

        case types.LOADED_VEHICLES:
            return {
                ...state,
                vehicles: payload,
            };

        case types.LOADING_VEHICLE_TYPES:
            return {
                ...state,
                loadingVehicleTypes: payload,
            };

        case types.LOADED_VEHICLE_TYPES:
            return {
                ...state,
                vehicleTypes: payload,
            };

        case types.SET_FILTERS:
            return {
                ...state,
                filters: payload,
            };

        case types.SET_ACTIVE:
            return {
                ...state,
                active: payload,
            };

        case types.SET_DOCK_CURRENT_SHOW:
            return {
                ...state,
                dockCurrentShow: payload,
            };

        case types.SET_DOCK_COLOR_REFRESH:
            return {
                ...state,
                dockColorRefresh: payload,
            };

        default:
            return state;
    }
}
