import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as meteoActions from 'redux/Meteo/actions';
import * as ecoActions from 'redux/Ecology/actions';
import { meteoSelectors } from 'redux/Meteo';
import { ecologySelectors } from 'redux/Ecology';
import PageLayout from 'components/layout/PageLayout';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';
import Item from './Item';
import type { DeviceGroup } from './types';

interface OutputSettingsProps {
    title: string;
    storeName: 'meteo' | 'ecology';
}

const OutputSettings = ({ title = 'Настройки', storeName }: OutputSettingsProps) => {
    const dispatch = useDispatch();

    const actions = useMemo(
        () => ({
            loadParams:
                storeName === 'meteo'
                    ? meteoActions.loadMeteoOutputParams
                    : ecoActions.loadEcologyOutputParams,
            loadDevices:
                storeName === 'meteo'
                    ? meteoActions.getDeviceListMeteo
                    : ecoActions.getDeviceListEcology,
            load:
                storeName === 'meteo'
                    ? meteoActions.loadDevicesGroup
                    : ecoActions.loadDevicesGroup,
            add:
                storeName === 'meteo'
                    ? meteoActions.createDevicesGroup
                    : ecoActions.createDevicesGroup,
            edit:
                storeName === 'meteo'
                    ? meteoActions.editDevicesGroup
                    : ecoActions.editDevicesGroup,
            delete:
                storeName === 'meteo'
                    ? meteoActions.deleteDevicesGroup
                    : ecoActions.deleteDevicesGroup,
        }),
        [storeName]
    );

    const devicesGroup: DeviceGroup[] = useSelector(storeName === 'meteo' ? meteoSelectors.devicesGroup : ecologySelectors.devicesGroup);
    const loadingDevicesGroup: boolean = useSelector(storeName === 'meteo' ? meteoSelectors.loadingDevicesGroup : ecologySelectors.loadingDevicesGroup);

    const [isOpenAddModal, setIsOpenAddModal] = useState(false);

    const reloadList = useCallback(() => {
        dispatch(actions.load());
    }, [actions, dispatch]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    return (
        <>
            <PageLayout 
                header={title}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setIsOpenAddModal(true),
                                    },
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    
                    ...(devicesGroup?.length > 0 && { total: devicesGroup.length })
                }}
                loading={loadingDevicesGroup}
                content={() => (
                    <>
                        {devicesGroup?.length > 0
                            && devicesGroup.map((item) => (
                                <Item
                                    key={item.id}
                                    item={item}
                                    actions={actions}
                                    reloadList={reloadList}
                                    storeName={storeName}
                                    loadParams={actions.loadParams}
                                />
                            ))}
                    </>
                )}
            />

            {isOpenAddModal && (
                <ModalForm
                    isOpen={isOpenAddModal}
                    onClose={() => setIsOpenAddModal(false)}
                    loadParams={actions.loadParams}
                    isNew={true}
                    reloadList={reloadList}
                    actions={actions}
                    storeName={storeName}
                />
            )}
        </>
    );
};

export default OutputSettings;
