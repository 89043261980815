import React from 'react';
import Loading from '../../Loading';
import { List } from '@mui/material';
import messages from '../../../../helpers/constants/messages';
import LinkItem from './Item';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

// компонент для отрисовки списка связанных ДИТ
const BoardsList = (
    {
        onChangeList,
        disabled = true,
        dit_list = [], 
        loading = false,
        onClick,
        inModal = false,
        needButton = true,
    }) => {

    const deleteBoard = (i) => {
        const newBoards = dit_list.filter((el, index) => index !== Number(i));
        onChangeList(newBoards);
    };

    return (
        <>
            {loading && <Loading linear />}
            {dit_list?.length > 0
                ? <List className="list">
                    {dit_list?.map((dit, index) => {
                        return <LinkItem
                            key={dit?.id}
                            item={dit}
                            index={index}
                            {...(!disabled && { onDelete: () => deleteBoard(index) })}
                        />;
                    })}
                </List>
                : (!loading && <p style={{ padding: '1rem' }}>{messages.NO_DATA}</p>)
            }
            {(!inModal && needButton)
                && <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.change,
                            onClick,
                            disabled: loading
                        },
                    ]}
                />
            }
        </>
    );
};

export default BoardsList;