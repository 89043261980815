import Stations from '../Dictionaries/Stations';

const dictionariesRoutes = [
    {
        path: '/metro/dictionaries/stations',
        component: Stations,
        exact: true,
    },
];

export default dictionariesRoutes;