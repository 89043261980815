import React from 'react';

import titles from 'helpers/constants/titles';

import styles from './panel.module.scss';

export interface InformPanelProps {
    buttons?: React.ReactNode;
    total?: number;
}

const InformPanel = ({
    buttons,
    total,
}: InformPanelProps) => {

    return (
        <div className={`${styles.wrap} ${buttons ? '' : styles.singleElement}`}>
            {buttons
                && <div className={styles.buttonsWrap}>
                    {buttons}
                </div>
            }
            {(!!total || total === 0) && <span>{titles.TOTAL}: {total}</span>}
        </div>
    );
};

export default InformPanel;
