import messages from 'helpers/constants/messages';
import CheckPointList from 'components/common/Transport/StationRoute/CheckPointList';
import { options } from 'components/common/Transport/StationRoute/helper';

import CalculateRoutes from './CalculateRoutes';

const RouteCheckPoints = ({ routePoints, canEdit, canRemove, draggable, saveCheckPoints }) => {
    return (
        <div className="form">
            <CalculateRoutes routePoints={routePoints} />
            <h2>Остановки и контрольные точки:</h2>
            {routePoints.length > 0
                ? <CheckPointList
                    items={routePoints}
                    setItems={saveCheckPoints}
                    draggable={draggable}
                    canEdit={canEdit}
                    canRemove={canRemove}
                    displayType={options.basicData}
                />
                : <>{messages.NO_DATA}</>
            }
        </div>
    );
};

export default RouteCheckPoints;
