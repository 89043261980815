import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';

import SidebarItem from 'components/MapComponents/SidebarItem';
import Loading from 'components/common/Loading';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import getFilters from 'components/MapComponents/helpers/getFilters';
import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { usePrevious } from 'helpers/hooks';
import getStatusesObject from 'helpers/getStatusesObject';
import useStoreFromSelector from 'helpers/hooks/useStoreFromSelector';
import getImagesObject from 'helpers/getImagesObject';
import colorExtend from 'helpers/mapHelper/colorExtend';
import * as actions from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import { checkBase64 } from '../../TransportPassenger/helper';
import FilterForm from './Filter/index';
import config from '../config';
import icon from '../icons/icon';
import Item from './Item';


const SideBar = (props) => {
    const dispatch = useDispatch();
    // const [page, setPage] = useState(1);
    // const limit = 10;

    const scrollRef = useRef(null);

    const list = useSelector(SituationalPlansSelectors.sidebar);
    const loading = useSelector(SituationalPlansSelectors.sidebarLoading);
    const active = useSelector(SituationalPlansSelectors.active);
    const saved = useSelector(SituationalPlansSelectors.saved);
    const filters = useSelector(SituationalPlansSelectors.filters);
    const threatLevels = useSelector(SituationalPlansSelectors.threatLevels);
    const sidebarParams = useSelector(SituationalPlansSelectors.sidebarParams);

    useEffect(() => {
        return () => {
            dispatch(actions.setSidebarParams({
                ...sidebarParams,
                page: 1,
            }));
        };
    }, []);

    // статусы
    const statuses = useStoreFromSelector(
        actions.loadTransportIncidentStatuses,
        SituationalPlansSelectors.transportIncidentStatuses
    );
    const statusesObject = getStatusesObject(statuses);

    // иконки
    const imagesLegends = useStoreFromSelector(
        actions.loadImagesLegends,
        SituationalPlansSelectors.imagesLegends
    );
    const images = getImagesObject(imagesLegends);

    // создание иконки
    const createIcon = useCallback(({ status_id = 0, type_id = 0 }) => {
        const imageB64 = images?.[type_id] // иконка по типу
            || images?.[0] // иконка по умолчанию из даных
            || null; // нет иконки
        const color = colorExtend(statusesObject?.[status_id]?.color || config.mapMarkersColors.default);

        return `
            <div class="transport-icon" style="background:${color};align-items:center; padding:3px;">
                ${imageB64 ? `<img class="image-inner" src="${checkBase64(imageB64)}">` : `${icon({ fill: '#000' })}`}
            </div>
        `;
    }, [images, statusesObject]);

    const prevFilters = usePrevious(filters);
    const activeId = Object.keys(active).length > 0 ? active.id : 0;

    const scroolTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    const fetchList = () => {
        const filter = getFilters(filters);
        const {
            page, limit
        } = sidebarParams;
        dispatch(actions.loadSidebar(
            page,
            limit,
            filter
        ));
    };

    const handleScrollUpdate = (values) => {
        const last_page = list?.meta?.last_page || 0;
        if (
            values.top > 0.98
            && loading === false
            && sidebarParams.page < last_page
        ) {
            dispatch(actions.setSidebarParams({
                ...sidebarParams,
                page: sidebarParams.page + 1
            }));
        }
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            scroolTop();
            dispatch(actions.setSidebarParams({
                ...sidebarParams,
                page: 1
            }));
        }
        fetchList();
    }, [sidebarParams, filters]);

    useEffect(() => {
        if (threatLevels?.data?.length === 0) {
            dispatch(actions.loadThreatLevels());
        }

        return () => {
            dispatch(actions.clearSidebar());
        };
    }, []);

    useEffect(() => {
        if (saved) {
            scroolTop();
            const { page } = sidebarParams;
            if (page > 1) {
                dispatch(actions.setSidebarParams({
                    ...sidebarParams,
                    page: 1
                }));
            } else {
                fetchList();
            }
        }
    }, [saved]);

    const renderList = (dataList) => (
        <>
            {dataList?.map((item) => {
                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        icon={createIcon(item)}
                        // icon={
                        //     Icon({
                        //         fill: getColorByStatus(item.status_id)
                        //     })
                        // }
                        buttons={{
                            edit: {
                                title: buttons.EDIT,
                                icon: <i className="fas fa-pen" />,
                                onClick: () => {
                                    // редактирование
                                    dispatch(actions.setEditForm(item));
                                }
                            },
                            delete: {
                                title: buttons.DELETE,
                                icon: <i className="fas fa-trash-alt" />,
                                onClick: () => {
                                    // удаление
                                    dispatch(actions.setDeleteForm(item.id));
                                }
                            }
                        }}
                        onClick={() => {
                            dispatch(actions.setActive(item));
                        }}
                    >
                        <Item
                            {...item}
                            statuses={statusesObject}
                        />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={() => dispatch(actions.setSidebarParams({
                    ...sidebarParams,
                    page: 1
                }))}
                onSetFilter={(filter) => dispatch(actions.setFilters(filter))}
                onClearFilter={() => dispatch(actions.clearFilters())}
                content={({ data, onChange }) =>
                    <FilterForm
                        data={data}
                        onChange={onChange}
                        threatLevels={threatLevels}
                        statuses={statuses}
                    />
                }
                layer={config.slug}
            />

            {loading && list.data.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                    ref={scrollRef}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={titles.TRANSPORT_INCIDENTS}
                            list={list}
                        />
                        {
                            list.data.length === 0
                                ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                                : renderList(list?.data)
                        }
                    </div>
                    {loading && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
};

export default SideBar;
