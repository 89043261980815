import React, { useState } from 'react';
import { IconButton, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import ConfirmDelete from '../../ConfirmModal';
import CloseIcon from '@mui/icons-material/Close';
import messages from '../../../../helpers/constants/messages';

const LinkItem = ({ item, index, onDelete }) => {
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    return (
        <>
            <ListItem className="list_item">
                <ListItemIcon>
                    {index + 1}.
                </ListItemIcon>
                <ListItemText>
                    {item?.name}, серийный номер: {item?.serial_number}
                </ListItemText>
                {onDelete && <Tooltip title="Удалить связь">
                    <IconButton onClick={() => setIsOpenDeleteModal(true)} size="small">
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
                }
            </ListItem>
            {isOpenDeleteModal && (
                <ConfirmDelete
                    open={isOpenDeleteModal}
                    onClose={() => setIsOpenDeleteModal(false)}
                    onSuccess={onDelete}
                    message={messages.CONFIRM_DELETE}
                />
            )}
        </>
    );
};

export default LinkItem;