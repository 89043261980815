import * as types from './types';

export const loadVoteStatistics = (params = {}) => ({
    type: types.LOAD_VOTE_STATISTIC,
    payload: params,
});

export const loadedVoteStatistics = (data) => ({
    type: types.LOADED_VOTE_STATISTIC,
    payload: data,
});

export const loadingVoteStatistics = (bool) => ({
    type: types.LOADING_VOTE_STATISTIC,
    payload: bool,
});