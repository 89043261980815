import { useState } from 'react';
import { TextField } from '@mui/material';

import titles from '../../../../helpers/constants/titles';
import SelectCompany from '../../Autocomplete/Companies';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import LayoutFilter from '../../../layout/PageLayout/LayoutFilter';

function Filters({ setFilter }) {
    const initialState = {
        first_name: '',
        last_name: '',
        patronymic: '',
        identification: '',
        organization_id: null
    };

    const [data, setData] = useState(initialState);

    const prepareData = (filter) => removeEmptyFields({
        ...filter,
        organization_id: filter.organization_id?.id,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    const onSearch = (filters = data) => {
        setFilter(prepareData(filters));
    };

    const resetFilter = (needRefresh) => {
        if (needRefresh) { 
            setFilter(prepareData(initialState));
        }
        setData(initialState);
    };

    const updateFilter = (filters) => {
        setData({
            ...initialState,
            ...filters,
        });

        if (Object.keys(filters).length > 0) {
            setFilter(prepareData({
                ...initialState,
                ...filters,
            }));
        }
    };

    const isDisabled = Object.keys(removeEmptyFields(data, false)).length === 0;

    return (
        <LayoutFilter
            filter={data}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={data.first_name}
                    onChange={handleChange}
                    name="first_name"
                    label={titles.FIRST_NAME}
                    variant="outlined"
                    size="small"
                />
                <TextField
                    value={data.last_name}
                    onChange={handleChange}
                    name="last_name"
                    label={titles.LAST_NAME}
                    variant="outlined"
                    size="small"
                />
                <TextField
                    value={data.patronymic}
                    onChange={handleChange}
                    name="patronymic"
                    label={titles.PATRONYMIC}
                    variant="outlined"
                    size="small"
                />
                
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <TextField
                    value={data.identification}
                    onChange={handleChange}
                    name="identification"
                    label={titles.IDENTIFICATION}
                    variant="outlined"
                    size="small"
                />
                <div>
                    <SelectCompany
                        selected={data.organization_id || {}}
                        onChange={(value) => setData({
                            ...data, 
                            organization_id: value,
                        })}
                        formData={{ withDeleted: 1 }}
                        selectDeletedСompanies
                        label={titles.ORGANIZATION}
                    />
                </div>
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
}

export default Filters;