import { useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { wsSelectors } from 'redux/WS';
import WsContext from '../helper/WsContext';
import { getWsTokenSubscribe } from '../helper/getToken';

// хук подключения канала центрифуги
const useWsSubscribe = (
    // канал string (полный с версией если есть)
    channel,
    // функция обработки данных callback(eventData)
    callBack = () => {}
) => {
    // центрифуга из провайдера WsProvider
    const {
        centrifuge = null,
    } = useContext(WsContext) || {};

    // получаем канал для подключения
    const channelName = useSelector(wsSelectors.currentAvailableChannelName(`${channel}`));

    // пришли данные в канале
    const handleCallback = (event) => callBack(event?.data || []);

    useEffect(() => {
        // подключение к каналу
        if (
            centrifuge // центрицуга есть
            && channelName // канал есть
            && centrifuge?.getSubscription?.(channelName) === null // канал не подключен
        ) {
            const newChannel = centrifuge.newSubscription(
                channelName,
                {
                    getToken: getWsTokenSubscribe,
                    // joinLeave: true
                }
            );
            newChannel.on('publication', handleCallback);
            newChannel.subscribe();

            return () => {
                // отключение от канала
                newChannel?.unsubscribe?.();
                newChannel?.removeAllListeners?.();
                centrifuge?.removeSubscription?.(newChannel);
            };
        }
    }, [centrifuge, channelName]);
};

export default useWsSubscribe;
