import * as types from './types';

export const loadTelegramId = (addressList, callback) => ({
    type: types.LOAD_TELEGRAM_ID,
    payload: {
        params: addressList
    },
    callback
});

export const setLoading = (bool = false) => ({
    type: types.SET_LOADING,
    payload: bool
});