import { useEffect, useState } from 'react';

const useMediaFiles = (event = {}) => {
    const [allMedia, setAllMedia] = useState([]);

    useEffect(() => {
        const {
            photo_list = [],
            video_extra = [],
            photo_grz = '',
            photo_ts = '',
        } = event;

        const createMediaList = (list, type) =>
            list
                ? list.map((el) => {
                    return{ src: el, type, };
                })
                : [];


        const photos = photo_list?.length > 0 ? photo_list : (photo_ts || photo_grz) ? [photo_ts, photo_grz,] : [];

        const allMedia = [...createMediaList(photos, 'image'), ...createMediaList(video_extra, 'video')];

        // if (Object.keys(files).length > 0) {

        //     const images = files?.images;
        //     const videos = files?.videos || files?.video;

        //     const imgFiles = images
        //         ? Object.keys(images)?.reduce((acc, key) => {
        //             const media = images[key]?.map((item, i) => ({ src: item, violation: key, type: 'image', id: i + 1 }));
        //             return [...acc, ...media];
        //         }, [])
        //         : [];

        //     const videoFiles = videos
        //         ? Object.keys(videos)?.reduce((acc, key) => {
        //             const media = videos[key]?.map((item, i) => (
        //                 { src: item, violation: key, type: 'video', id: imgFiles.length + i + 1 }
        //             ));
        //             return [...acc, ...media];
        //         }, [])
        //         : [];

        //     const mediaViolations = Object.keys(files);

        //     const mediaFiles = !(images || videos)
        //         ? mediaViolations?.reduce((acc, key) => {
        //             const img = files[key].images
        //                 ? files[key].images?.map((item, i) => (
        //                     { src: item, violation: key, type: 'image', id: i + 1 }
        //                 )) : [];
        //             const video = (files[key].videos || files[key].video)
        //                 ? (files[key].videos || files[key].video)?.map((item, i) => (
        //                     { src: item, violation: key, type: 'video', id: img.length + i + 1 }
        //                 )) : [];
        //             return [...acc, ...img, ...video];
        //         },
        //         [])
        //         : [];

        //     const allMedia = [...imgFiles, ...videoFiles, ...mediaFiles];

        setAllMedia(allMedia);
        // }
    }, [event]);

    return allMedia;
};

export default useMediaFiles;
