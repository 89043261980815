import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';

import { clearEventWantedTrack } from 'redux/WantedCars/actions';
import DateRange from 'components/common/Dates/DateRange';
import titles from 'helpers/constants/titles';
import { compareDatesByParams, getDateWithDuration } from 'helpers/date.config';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const sourceList = [
    {
        id: 'doris-control',
        name: 'КФВФ',
    },
    {
        id: 'video-analytics',
        name: 'Видеоаналитика',
    }
];

const Filter = ({ filter, setFilter, onSearch }) => {
    const dispatch = useDispatch();
    const initialFilter = useRef(filter);

    const period = { value: { week: 1 }, minusValue: { week: -1 },  name: '1 неделя' };

    const resetFilter = (needRefresh) => {
        setFilter(initialFilter.current);
        needRefresh && dispatch(clearEventWantedTrack());
    };

    const updateFilter = (filters) => {
        const newFilter = { ...initialFilter.current, ...filters };
        setFilter(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };

    const handleChange = (name, value) => {
        if (name === 'services' && value?.includes?.('all')) {
            setFilter({
                ...filter,
                [name] : sourceList.map(item => item.id),
            });
        } else {
            setFilter({
                ...filter,
                [name]: value,
            });
        }
    };

    const calculateDifference = (end, start) => {
        const startDate = getDateWithDuration(period.value, start);
        return compareDatesByParams(end, startDate);
    };

    // дата начала
    const handleStartDate = (newValue) => {
        const compare = calculateDifference(filter.end_date, newValue);
        // если start больше недели , чем end
        setFilter({
            ...filter,
            start_date: newValue,
            ...(compare.milliseconds > 0 ? { end_date: getDateWithDuration(period.value, newValue) } : {})
        });
    };

    // дата конца
    const handleEndDate = (newValue) => {
        const compare = calculateDifference(newValue, filter.start_date);
        // если start больше недели , чем end
        setFilter({
            ...filter,
            end_date: newValue,
            ...(compare.milliseconds > 0 ? { start_date: getDateWithDuration(period.minusValue, newValue) } : {})
        });
    };

    const renderError = () => {
        const compare = calculateDifference(filter.end_date, filter.start_date);
        // если разница start и end больше периода
        if (compare.milliseconds > 0) {
            return {
                error: true,
                message: `Разница между датой и временем конца и начала периода должна быть ${period.name}`
            };
        }
    };

    const isDisabled = !filter.start_date
        || !filter.end_date
        || !filter?.grz
        || filter?.services?.length === 0;

    return (
        <LayoutFilter
            filter={filter}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            disabled={isDisabled}
            filterException={['start_date', 'end_date']}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filter.start_date || null}
                    valueEndDate={filter.end_date || null}
                    handleDate={handleStartDate}
                    handleEndDate={handleEndDate}
                    startLabel={titles.START_PERIOD}
                    endLabel={titles.END_PERIOD}
                    disableFuture={false}
                    required
                    renderError={() => renderError()?.error}
                    errorMessage={() => renderError()?.message}
                    helperText={`Максимальный период ${period.name}`}
                />
                <TextField
                    label={titles.GRZ}
                    value={filter?.grz || ''}
                    onChange={({ target: { value } }) => handleChange('grz', value)}
                    name="grz"
                    size="small"
                    variant="outlined"
                    required
                    error={!filter.grz}
                />
                <CommonAutocomplete
                    multiple={true}
                    required={true}
                    selected={filter?.services || []}
                    onChange={(value) => handleChange('services', value)}
                    onReset={() => handleChange('services', [])}
                    renderLabel={(option) => option?.name || ''}
                    limitTags={1}
                    label={titles.DATA_SOURCE}
                    error={filter?.services?.length === 0}
                    options={sourceList}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    filterSelectedOptions={true}
                    showAsSelect
                    inputName={'services'}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
