import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { loadOrganizationsByOrderId } from '../../../redux/RoadWorks/actions';

// хук загрузить огранизации по заявке
const useOrganizationsByOrderId = (orderId) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const load = (orderId) => {
        if (orderId) {
            dispatch(loadOrganizationsByOrderId(orderId, setLoading, setData));
        }
    };

    const clear = () => {
        setLoading(false);
        setData([]);
    };

    useEffect(() => {
        load(orderId);
    }, [orderId]);

    const returnData = data;

    returnData.reload = () => load(orderId);
    returnData.loadById = load;
    returnData.clear = clear;

    returnData.loading = loading;

    return returnData;
};

export default useOrganizationsByOrderId;
