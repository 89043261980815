import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayMove } from '@dnd-kit/sortable';
import { Alert, Grid } from '@mui/material';

import {
    editTrafficSchedule,
    loadCurrentTrafficScheduleEvents,
} from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import Loading from 'components/common/Loading';
import titles from 'helpers/constants/titles';
import {
    compareDatesByParams,
    convertEventTimesToDates,
    durationFromObjectToFormat,
    fullDateTimeWithTimeZone,
    getDateObjectFromFormat,
    getDateWithDuration,
    getEventEndTime,
    getTimeWithoutSeconds,
} from 'helpers/date.config';
import { useValidation } from 'helpers/hooks';

import EventTable from './EventTable';
import AddEventItemModal from './AddEventItemModal';
import EditEventTimeModal from './EditEventTimeModal';

// На потом - предусмотреть проверку с предупреждением, что время распланированных событий 
// в графике не должно превышать время окончания графика. - Денис UAPTS-4709
const EditTrafficScheduleTable = (
    {
        isOpen,
        onClose,
        tableData = [], // список эвентов
        title,
        id,
        loading,
        routeDirectNum = null,
        routeBackNum = null,
        trafficScheduleStartAt = null
    }) => {
    const validation = useValidation();
    const dispatch = useDispatch();

    const loadingTrafficSchedule = useSelector(transportPassengerSelectors.loadingTrafficSchedule);

    const [temporaryData, setTemporaryData] = useState(); //храним данные таблицы в локальном стейте
    const [indexOfElement, setIndexOfElement] = useState(null); //индекс элемента, относительно которого будет добавляться элемент
    const [isOpenAddEventItemModal, setIsOpenAddEventItemModal] = useState(false); // открытие модалки создания
    const [isOpenEditEventTimeModal, setIsOpenEditEventTimeModal] = useState(false); // открытие модалки редактирования

    const initialState = {
        duration: null,
        time_end: null, //нужно только для редактирования, перезатрется в момент пересчета
        time_start: null, //нужно только для редактирования, перезатрется в момент пересчета
        entity_id: 0,
        entity_list: [],
        entity_type: '',
        event_id: '',
        event_text: '',
        is_direct: false,
        mileage: '',
        name: '',
        traffic_schedule_id: id,
    };

    const [data, setData] = useState(initialState);
    
    useEffect(() => {
        if (tableData.length > 0) {
            setTemporaryData([ ...tableData ]);
        }
    },[tableData]);

    const resetTemporaryDataToInitial = () => {
        setTemporaryData([ ...tableData ]);
        validation.clear();
    };

    // метод для конвертации времени в даты, чтобы правильно происходил переход через полночь
    const converTimesToDates = () => {
        let startTime = fullDateTimeWithTimeZone(getDateObjectFromFormat(temporaryData[0].time_start, 'HH:mm'));
        let endTime = null; // время окончания для рейса
        return temporaryData.map((el) => {
            const item = {
                ...el,
                time_start: startTime,
                entity_list: el.entity_type === 'App\\Models\\Flight' && el.entity_list.length > 0
                    ? el.entity_list?.map((station, ind) => {
                        //сдвигаем на интервал перед остановкой
                        startTime = convertEventTimesToDates(startTime, station?.interval);
                        //сдвигаем на время стоянки на остановке
                        const stationEndTime = convertEventTimesToDates(startTime, station.duration);
                        const child = {
                            ...station,
                            time_start: startTime,
                            time_end: stationEndTime,
                        };
                        startTime = stationEndTime;
                        endTime = stationEndTime;
                        return child;
                    })
                    : [],
                time_end: el.entity_type === 'App\\Models\\Flight' && el.entity_list.length > 0
                    ? endTime
                    : convertEventTimesToDates(startTime, el.duration),
            };
            startTime = item.time_end;
        
            return item;
        
        });
    };
    
    const saveDataTrafficSchedule = () => {
        const callback = () => {
            dispatch(loadCurrentTrafficScheduleEvents(id));
            onClose();
        };
       
        const converted = converTimesToDates();

        dispatch(editTrafficSchedule(id, { name: title, entity_list: converted }, callback));
    };

    const handleAdd = (index) => {
        setIsOpenAddEventItemModal(true);
        setIndexOfElement(index);
    };

    const closeAddItemModalForm = () => {
        setData(initialState);
        setIndexOfElement(null);
        setIsOpenAddEventItemModal(false);
    };

    const handleEdit = (element, index) => {
        const timeStart = getDateObjectFromFormat(element?.time_start, 'HH:mm');
        const timeEnd = getDateObjectFromFormat(element?.time_end, 'HH:mm');
        const duration = getDateObjectFromFormat(element?.duration, 'HH:mm');

        setData({
            ...element,
            time_start: timeStart,
            time_end: timeEnd,
            duration: duration,
        });
        setIsOpenEditEventTimeModal(true);
        setIndexOfElement(index);
    };

    const closeEditItemModalForm = () => {
        setData(initialState);
        setIndexOfElement(null);
        setIsOpenEditEventTimeModal(false);
    };

    const handleDelete = (index) => {
        const newArray = recalculation(temporaryData.filter((el, i) => i !== Number(index)));
        setTemporaryData(newArray);
    };

    const [activeId, setActiveId] = useState();

    const handleDragStart = (event) => {
        setActiveId(event.active.id);
    };

    const handleDragCancel = () => {
        setActiveId(null);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = temporaryData.findIndex(el => el.number === active.id);
            const newIndex = temporaryData.indexOf(temporaryData.find(el => el.number === over.id));

            const newArray = recalculation(arrayMove(temporaryData, oldIndex, newIndex));

            setTemporaryData(newArray);
        }

        setActiveId(null);
    };

    const getDuration = (start, end) => {
        const timeStart = getDateObjectFromFormat(start, 'HH:mm');
        const timeEnd = getDateObjectFromFormat(end, 'HH:mm');
        // сравниваем начало и конец
        let diff = compareDatesByParams(timeEnd, timeStart);
        // если начало раньше конца, значит оно было на день раньше - сдвиг на -1 день
        if (diff.milliseconds < 0) {
            const newTimeStart = getDateWithDuration({ days: -1 }, timeStart); // сдвиг даты на день назад
            diff = compareDatesByParams(timeEnd, newTimeStart);
        }
        return durationFromObjectToFormat(diff,'hh:mm');
    };

    //пересчет времени
    const recalculation = (data) => {

        let startTime = getTimeWithoutSeconds(trafficScheduleStartAt); //формат HH:MM:SS с таймзоной
        let number = 1; // порядковый номер
        let entityType = ''; //в даной переменной записан тип элемента, находящегося ПЕРЕД текущим элементом
        let numberOfStations = 0; //количество остановок
        let endTime = null; // время окончания для рейса
        return data?.map((el, i) => {
            const item = {
                ...el,
                time_start: startTime,
                entity_list: el.entity_type === 'App\\Models\\Flight' && el.entity_list.length > 0
                    ? el.entity_list?.map((station, ind) => {
                        // правка (сдвигаем на интервал перед остановкой)
                        startTime = getEventEndTime(startTime, station?.interval);
                        const child = {
                            ...station,
                            time_start: startTime,
                            time_end: getEventEndTime(startTime, station?.duration),
                            number: entityType === 'App\\Models\\Flight'
                                ? number + numberOfStations + 2 + ind
                                : entityType.length > 0
                                    ? number + 2 + ind
                                    : number + 1 + ind
                        };
                        // правка (сдвигаем на время стоянки на остановке)
                        startTime = getEventEndTime(startTime, station?.duration);
                        // startTime = getEventEndTime(startTime, station?.duration, station?.interval);
                        endTime = child.time_end;
                        return child;
                    })
                    : [],
                time_end: el.entity_type === 'App\\Models\\Flight' && el.entity_list.length > 0
                    ? endTime
                    : getEventEndTime(startTime, el?.duration),
                number: entityType === 'App\\Models\\Flight'
                    ? number + numberOfStations + 1
                    : i === 0
                        ? number
                        : number + 1,
            };
            item.duration = item.entity_type === 'App\\Models\\Flight' && el.entity_list.length > 0
                ? getDuration(item.time_start, item.time_end)
                : item?.duration;
            startTime = item?.time_end;
            number = item?.number;
            entityType = item?.entity_type;
            numberOfStations = item?.entity_list?.length;
            return item;
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            fullWidth={true}
            title={`${titles.EDIT} графика движения ${title}`}
            buttons={<Grid container>
                {validation.isKey('route_id_direct')
                && <Grid item xs={12}>
                    <FormButtons
                        beforeTemplate={
                            <Alert severity="error" variant="filled">
                                {validation.get('route_id_direct')}
                            </Alert>
                        }
                    />
                </Grid>}
                <Grid item xs={6}>
                    <FormButtons
                        positionLeft
                        buttons={[
                            {
                                ...buttonsTypes.reset,
                                onClick: resetTemporaryDataToInitial,
                                disabled: loadingTrafficSchedule,
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: onClose,
                                disabled: loadingTrafficSchedule
                            },
                            {
                                ...buttonsTypes.save,
                                onClick: saveDataTrafficSchedule,
                                disabled: loadingTrafficSchedule
                            },
                        ]}
                    />
                </Grid>
            </Grid>}
        >
            <div>
                {loadingTrafficSchedule && <Loading circular={true}/>}
                {loading
                    ? <Loading
                        circular={true}
                    />
                    : <EventTable
                        id={id}
                        data={temporaryData}
                        handleDelete={handleDelete}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        hideIcons={loadingTrafficSchedule}
                        //onSortEnd={onSortEnd}
                        handleDragEnd={handleDragEnd}
                        handleDragStart={handleDragStart}
                        handleDragCancel={handleDragCancel}
                        activeId={activeId}
                    />}
                {isOpenAddEventItemModal && (
                    <AddEventItemModal
                        isOpen={isOpenAddEventItemModal}
                        onClose={closeAddItemModalForm}
                        data={data}
                        setData={setData}
                        temporaryData={temporaryData}
                        setTemporaryData={setTemporaryData}
                        indexOfElement={indexOfElement}
                        setIndexOfElement={setIndexOfElement}
                        initialState={initialState}
                        recalculation={recalculation}
                        routeDirectNum={routeDirectNum}
                        routeBackNum={routeBackNum}
                    />
                )}
                {isOpenEditEventTimeModal && (
                    <EditEventTimeModal
                        isOpen={isOpenEditEventTimeModal}
                        onClose={closeEditItemModalForm}
                        data={data}
                        setData={setData}
                        temporaryData={temporaryData}
                        setTemporaryData={setTemporaryData}
                        indexOfElement={indexOfElement}
                        recalculation={recalculation}
                    />
                )}
            </div>
        </Modal>
    );
};

export default EditTrafficScheduleTable;