// иконка для списка
const iconCluster = (props) => {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 107.000000 107.000000">
            <g>
                <rect fill="none" id="canvas_background" height="132" width="109" y="-1" x="-1"/>
            </g>
            <g>
                <g id="svg_ecology_1" fill="${props?.fill || '#e7e7e7'}" transform="translate(0,107) scale(0.10000000149011612,-0.10000000149011612)">
                    <path stroke="null" id="svg_ecology_2" d="m404.522364,1047.198783c-227.702247,-55.170148 -397.225066,-272.841459 -397.225066,-511.577736c0,-285.881676 240.742464,-525.621047 526.62414,-525.621047c285.881676,0 526.62414,240.742464 526.62414,526.62414c0,237.733183 -172.532099,457.410682 -402.240534,511.577736c-79.244394,18.055685 -175.54138,18.055685 -253.782681,-1.003094z"/>
                </g>
                <g fill="${props?.innerFill || 'rgba(0, 0, 0, 0.54)'}" stroke="null" id="svg_ecology_7">
                    <path stroke="null" id="svg_ecology_6" d="m81.408035,22.769469c-0.643456,-1.436287 -2.481903,-1.493738 -3.251753,-0.137884c-3.561991,6.193268 -9.939103,10.054006 -17.223949,10.054006l-9.192234,0c-12.17971,0 -22.061362,9.881652 -22.061362,22.061362c0,0.80432 0.091922,1.57417 0.172354,2.35551c7.330807,-5.251064 17.913366,-9.709297 32.919689,-9.709297c1.011146,0 1.838447,0.827301 1.838447,1.838447s-0.827301,1.838447 -1.838447,1.838447c-28.886596,0 -41.135248,17.706541 -43.846957,24.35942c-0.758359,1.872918 0.137884,4.010112 2.010801,4.779962c1.884408,0.78134 4.021602,-0.126393 4.802942,-1.987821c0.172354,-0.413651 2.401471,-5.50385 8.26152,-10.410205c3.722855,5.044238 10.800875,9.858671 20.096522,8.870506c18.039759,-1.309893 30.736533,-17.488225 30.736533,-37.29749c0,-5.768127 -1.240952,-11.743079 -3.424107,-16.614963z"/>
                </g>
            </g>
        </svg>
    `;
};

export default iconCluster;
