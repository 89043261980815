import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { Autocomplete, Chip, IconButton, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Map from './Map';

const useStyles = makeStyles({
    input: {
        paddingRight: '54px !important',
    },
    adornment: {
        position: 'absolute',
        right: '6px',
    },
});


// выбор пуидов на карте с радиусом и координатами
const TrafficFlowMapByRadius = ({
    multiple = false,
    selected = multiple ? [] : {},
    selectedList = [],
    onChange = () => {},
    required = false,
    disabled = false,
    label= 'ПУИД',
    error = false,
    helperText = '',
    hideClear = false,

    // координаты и радиус
    lat,
    lon,
    radius = 100,
}) => {
    const styles = useStyles();
    const [show, setShow] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);

    useEffect(() => {
        if (!isEqual(prevData.current, selected)) { 
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const autoCompleteChange = (e, value) => {
        if (e?.key !== 'Enter') onChange?.(value);
    };

    return (
        <>
            <Autocomplete
                multiple={multiple}
                value={selectedItems}
                options={[]}
                disabled={!multiple || disabled}
                getOptionLabel={(option) => option ? option?.name : ''}
                onChange={autoCompleteChange}
                freeSolo
                size="small"
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip title={option.name} label={option.name} {...getTagProps({ index })}/>
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={error}
                        size="small"
                        variant="outlined"
                        required={required}
                        label={label}
                        helperText={helperText}
                        disabled={!multiple || disabled}
                        InputProps={{
                            ...params.InputProps,
                            classes: { root: styles.input },
                            title: params.inputProps.value,
                            endAdornment: (
                                <InputAdornment position={'end'} classes={{ root: styles.adornment }}>
                                    {selected && Object.keys(selected).length > 0 && hideClear === false && (
                                        <IconButton
                                            size="small"
                                            onClick={() => onChange(multiple ? [] : {})}
                                        >
                                            <i className="far fa-times"/>
                                        </IconButton>
                                    )}

                                    <IconButton
                                        disabled={disabled}
                                        size="small"
                                        onClick={() => setShow(true)}
                                    >
                                        <i className="fas fa-search-plus"/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                )}
            />

            {show && (
                <Map
                    lat={lat}
                    lon={lon}
                    radius={radius}
                    selected={selectedItems}
                    selectedList={selectedList}
                    multiple={multiple}
                    onChange={(newData) => {
                        setSelected(newData);
                        onChange?.(newData);
                    }}
                    onClose={() => {
                        setShow(false);
                    }}
                />
            )}

        </>)
    ;
};

export default TrafficFlowMapByRadius;
