import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import formatDate from '../../../../../helpers/constants/dateFormat';
import { dateWithDashYYYYMMDD, getEndOf, getStartOf } from '../../../../../helpers/date.config';
import { 
    loadComplexTypeGrouping, 
    loadMonthlyReportExpanded 
} from '../../../../../redux/DorisControl/actions';
import DateRange from '../../../../common/Dates/DateRange';
import useValidation from '../../../../../helpers/hooks/useValidation';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import SelectComplex from '../../../../common/Autocomplete/DorisControl/Complex';
import GetReports from '../../../../common/GetReports';
import { useStoreProp } from '../../../../../helpers/hooks';
import GovernmentContractList from '../../../../common/Autocomplete/GovernmentContract';
import messages from '../../../../../helpers/constants/messages';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';

const Filter = () => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        start_date: getStartOf('month'),
        end_date: getEndOf('month'),
        organization_id_list: [],
        complex_id_list: [],
        formats:[],
        type_grouping : null,
        contract_id_list: [],
        checked_only: false,
        without_tech_material: false
    };

    const [filters, setFilters] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);

    const complex_type_grouping = useStoreProp(loadComplexTypeGrouping, 'dorisControl', 'complex_type_grouping');

    const handleChange = (name, value) => {
        setFilters({ ...filters, [name]: value });
        validation.deleteKey(name);
    };

    const download = () => {
        const newData = Object.keys(filters).reduce((res, key) => {
            if (key === 'start_date' || key === 'end_date') {
                res[key] = dateWithDashYYYYMMDD(filters[key]);
            } else {
                res[key] = filters[key];
            }
            return res;
        }, {});
        
        newData.organization_id_list = filters.organization_id_list.map(({ id }) => id);
        newData.complex_id_list = filters.complex_id_list.map(({ id }) => id);
        newData.formats = filters.formats?.map(({ id }) => id);
        newData.contract_id_list = filters.contract_id_list?.map(({ id }) => id);
        const dataForRequest = removeEmptyFields(newData, false);
        dispatch(loadMonthlyReportExpanded(dataForRequest));
    };

    const disabledButton = isDisabled
        || !filters.start_date
        || !filters.end_date
        || !filters.formats.length;

    const resetFilter = () => {
        setFilters(initialState);
        validation.clear();
    };

    const updateFilter = (filter) => {
        setFilters({
            ...initialState,
            ...filter
        });
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            buttonType="getReport"
            onSearch={download}
            filter={filters}
            filterException={['start_date', 'end_date']} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
            disabled={disabledButton}
            isFilterVisible
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filters.start_date}
                    valueEndDate={filters.end_date}
                    handleDate={(date) => handleChange('start_date', date)}
                    handleEndDate={(date) => handleChange('end_date', date)}
                    isDisabledHandler={setIsDisabled}
                    pickersFormat={formatDate.DATE_FOR_PICKER}
                    renderError={() => (validation.isKey('start_date') || validation.isKey('end_date'))}
                    errorMessage={() => validation.get('start_date') || validation.get('end_date')}
                    dateOnly={true}
                />
                <GetReports
                    selected={filters.formats}
                    onChange={(formats) => setFilters({ ...filters, formats })}
                    reportId={1}
                    required
                    newApi
                    helperText={validation.get('formats.0')}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <SelectCompany
                    multiple
                    selected={filters.organization_id_list}
                    onChange={(value) => handleChange('organization_id_list', value)}
                    label="Балансодержатель"
                    filter={{ withDeleted: 1 }}
                />
                <SelectComplex
                    multiple
                    selected={filters.complex_id_list}
                    onChange={(value) => handleChange('complex_id_list', value)}
                />
                <GovernmentContractList
                    label="Госконтракт"
                    multiple
                    selected={filters.contract_id_list}
                    onChange={(value) => handleChange('contract_id_list', value)}
                />
                <FormControl size="small" variant="outlined" fullWidth>
                    <InputLabel>
                        Тип группировки Комплекса
                    </InputLabel>
                    <Select
                        value={filters.type_grouping  || ''}
                        required={true}
                        label="Тип группировки Комплекса"
                        onChange={(e) => handleChange(e.target.name, Number(e.target.value))}
                        name="type_grouping"
                    >
                        {Object.keys(complex_type_grouping)?.length
                            ? Object.keys(complex_type_grouping)?.map((key) =>
                                <MenuItem value={key} key={key}>{complex_type_grouping[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters?.checked_only}
                            onChange={(e) => handleChange(e.target.name, !filters?.checked_only)}
                            name="checked_only"
                        />
                    }
                    label="Принятые в эксплуатацию"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters?.without_tech_material}
                            onChange={(e) => handleChange(e.target.name, !filters?.without_tech_material)}
                            name="without_tech_material"
                        />
                    }
                    label="Не включать в отчет ТМ"
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
