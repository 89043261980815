import replaceForWsInRedux from '../../helpers/replaceForWsInRedux';

import * as types from './types';

const initialState = {
    cameras: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    violations: {},
    types: {},
    events: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    eventsLoading: false,
    eventsGroup: [],
    complex_types: {},
    proto: {},
    dir_types: [],
    video_types: {},
    data_types: {},
    loading: false,
    report_list: [],
    loading_complexes: false,
    complexes: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    doris_complex_types: [],
    doris_complex_unit_types: {},
    doris_complex_model_types: [],
    loading_entities: false,
    // entities: {
    //     data: [],
    //     meta: {
    //         last_page: 0
    //     }
    // },
    entities: {},
    loading_entities_types: false,
    entities_types: {},
    doris_complex_document_types: {},
    doris_complex_statuses: [],
    loading_documents: false,
    documents: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loading_pdd_violations: false,
    pdd_violations: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    violationsById: {},
    cameras_pdd_violations: {},
    loading_cameras_pdd_violations: false,
    complex_history: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loading_complex_history: false,
    polygon: [],
    polygonLoading: false,
    active: {},
    // данные для удаления
    deleteForm: false,
    // данные для редактирования
    editForm: false,
    saved: false,
    complex_events: {
        data:[],
        meta: {
            last_page: 0,
            additional_parameters: {
                reports: []
            }
        }
    },
    complexCurrentEvent: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingEvent: false,
    sidebar: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingComplexEvents: false,
    sidebarLoading: false,
    sidebarParams: {
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25
    },
    eventErrors: [],
    loadingEditEventErrors: false,
    loadingFormattedComplexHistory: false,
    formattedComplexHistory: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    complexRules: [],
    loadingComplexRules: false,
    // currentComplex: {},
    complexTransferTypes: {},
    complexTransferRules: [],
    complexStatistic: {},
    comparativeStatistics: {
        result: []
    },
    loadingComparativeStatistics: false,
    currentComplexTransferTypes: {},
    showDorisCameraEvents: false,
    activeEvent: null,
    coordinationHistory: [],
    loadingCoordinationHistory: false,
    coordinationHistoryDates: {},
    filters: {},
    complexInaccuracy: {},
    dorisControlSettings: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingDorisControlSettings: false,
    tsCategories: {},
    loadingInspectionCertificate: false,
    loadingFile: false,
    namesIn1C: {
        data: []
    },
    loading1CNames: false,
    report1CNamesLoading: false,
    complexEventsReportFilter: {},
    operatorLogReportFilter: {},
    complexById: {},
    loadingComplexById: false,
    tagList: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    complex_type_grouping: {},
    complex_measuring: {},
    recognitionCameraDirTypes: {},
    computerTransferTypes: {},
    computerTransferRules: [],
    dorisExportTypes: [],
    loadingExclusiveFiles: false,
    exclusiveFiles: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    exclusiveFileHistoryList: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingExclusiveFileHistory: false,
    checklists: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingChecklist: false,
    materialStorageGroups: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingMaterialStorageGroups: false,
    violationEvents: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingViolationEvents: false,
    loadingButton: false,
    complexesTree: [],
    loadingComplexesTree: false,
    eventCheckList: {},
    loadingEventCheckList: false,
    exclusiveFilesHistoryList: {
        data: [],
        meta: {},
    },
    loadingExclusiveFilesHistoryList: false,
    loadingExclusiveFilesLogList: false,
    exclusiveFilesLogList: {
        data: [],
        meta: {},
    },
    loadingDocumentsHistory: false,
    documentsHistory: {
        data: [],
        meta: {},
    },
    loadingCheckListGroup: false,
    checkListGroup: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    eventsStatistic: {},
    loadingEventsStatistic: false,
    complexesByRadius: [],
    violationsTree: [],
    loadingViolationsTree: false,
    materialExportGroup: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    typeOfMistakesForChecklist: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingMaterialExportGroup: false,
    materialExportGroupTypes: {},
    materialExportGroupProtocols: {},
    loadingTypeOfMistakesForChecklist: false,
    settingsGroup: { data: [] },
    loadingSettingsGroup: false,
    loadingEventPdds: false,
    showCaseComplexStatus: {},
    loadingShowCaseComplexStatus: false,
    showCaseDocumentsVerification: {},
    loadingShowCaseDocumentsVerification: false,
    duploCodes: {
        data:[],
        meta: { last_page: 0 }
    },
    loadingDuploCodes: false,

    materials: {
        data:[],
        meta: { last_page: 0 }
    },
    loadingMaterials: false,
    materialExport: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingMaterialExport: false,
    materialExportTypes: {},
    materialExportProtocols: {},
    materialExportStatuses: {},
    complexConditions: {},
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_CAMERAS:
            return {
                ...state,
                cameras: payload
            };
        case types.LOADING_CAMERAS:
            return {
                ...state,
                loading: payload
            };
        case types.LOADED_CAMERA_TYPES:
            return {
                ...state,
                types: payload
            };
        case types.LOADED_CAMERA_COMPLEX_TYPES:
            return {
                ...state,
                complex_types: payload
            };
        case types.LOADED_CAMERA_PROTO_TEXT:
            return {
                ...state,
                proto: payload
            };
        case types.LOADED_CAMERA_DIR_TYPE:
            return {
                ...state,
                dir_types: payload
            };
        case types.LOADED_CAMERA_VIDEO_TYPE:
            return {
                ...state,
                video_types: payload
            };
        case types.LOADED_CAMERA_DATA_TYPE:
            return {
                ...state,
                data_types: payload
            };
        case types.LOADED_CAMERA_VIOLATIONS:
            return {
                ...state,
                violations: payload?.data
            };
        case types.LOADING_CAMERA_EVENTS:
            return {
                ...state,
                eventsLoading: payload
            };
        case types.LOADED_CAMERA_EVENTS:
            return {
                ...state,
                events: payload
            };
        case types.LOADED_CAMERA_EVENTS_GROUP:
            return {
                ...state,
                eventsGroup: payload.data
            };
        case types.LOADED_CAMERA_REPORT_LIST:
            return {
                ...state,
                report_list: payload
            };
        case types.LOADING_COMPLEXES:
            return {
                ...state,
                loading_complexes: payload
            };
        case types.LOADED_COMPLEXES:
            return {
                ...state,
                complexes: payload
            };
        case types.LOADED_COMPLEX_TYPES:
            return {
                ...state,
                doris_complex_types: payload
            };
        case types.LOADED_COMPLEX_UNIT_TYPES:
            return {
                ...state,
                doris_complex_unit_types: payload
            };
        case types.LOADED_COMPLEX_MODEL_TYPES:
            return {
                ...state,
                doris_complex_model_types: payload
            };
        case types.LOADING_COMPLEX_ENTITIES:
            return {
                ...state,
                loading_entities: payload
            };
        case types.LOADED_COMPLEX_ENTITIES:
            return {
                ...state,
                entities: payload
            };
        case types.LOADING_COMPLEX_ENTITY_TYPES:
            return {
                ...state,
                loading_entities_types: payload
            };
        case types.LOADED_COMPLEX_ENTITY_TYPES:
            return {
                ...state,
                entities_types: payload.data
            };
        case types.LOADING_DOCUMENTS:
            return {
                ...state,
                loading_documents: payload
            };
        case types.LOADED_DOCUMENTS:
            return {
                ...state,
                documents: payload
            };
        case types.LOADED_DOCUMENT_TYPES:
            return {
                ...state,
                doris_complex_document_types: payload
            };
        case types.LOADED_COMPLEX_STATUSES:
            return {
                ...state,
                doris_complex_statuses: payload
            };
        case types.LOADING_PDD_VIOLATIONS:
            return {
                ...state,
                loading_pdd_violations: payload
            };
        case types.LOADED_PDD_VIOLATIONS:
            return {
                ...state,
                pdd_violations: payload
            };
        case types.LOADED_FILTERED_PDD_VIOLATIONS:
            return {
                ...state,
                violationsById: {
                    ...state.violationsById,
                    ...payload.reduce((res, el) => {
                        if (el.id) {
                            res[el.id] = el;
                        }  else if (el.parts) {
                            el.parts.forEach(item => {
                                res[item.id] = item;
                            });
                        }
                        return res;
                    },{})
                }
            };
        case types.LOADING_CAMERAS_PDD_VIOLATIONS:
            return {
                ...state,
                loading_cameras_pdd_violations: payload
            };
        case types.LOADED_CAMERAS_PDD_VIOLATIONS: {
            const violations = payload.reduce((result, el) => {
                result[el.id] = el;
                return result;
            }, {});
            return {
                ...state,
                cameras_pdd_violations: {
                    ...state.cameras_pdd_violations,
                    ...violations
                }
            };
        }
        case types.LOADING_COMPLEX_HISTORY:
            return {
                ...state,
                loading_complex_history: payload
            };
        case types.LOADED_COMPLEX_HISTORY:
            return {
                ...state,
                complex_history: payload
            };
        case types.SAVE_POLYGON: {
            return {
                ...state,
                polygon: payload
            };
        }
        case types.SET_POLYGON_LOADING: {
            return {
                ...state,
                polygonLoading: payload
            };
        }
        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload
            };
        }
        case types.SET_DELETE_FORM: {
            return {
                ...state,
                deleteForm: payload,
            };
        }
        case types.SET_EDIT_FORM: {
            return {
                ...state,
                editForm: payload,
            };
        }
        case types.SAVED: {
            return {
                ...state,
                saved: payload,
            };
        }
        case types.LOADING_COMPLEX_EVENTS: {
            return {
                ...state,
                loadingComplexEvents: payload,
            };
        }
        case types.LOADED_COMPLEX_EVENTS: {
            return {
                ...state,
                complex_events: payload,
            };
        }
        case types.CLEAR_COMPLEX_EVENTS: {
            return {
                ...state,
                complex_events: initialState.complex_events,
            };
        }
        case types.LOADED_CURRENT_EVENT: {
            return {
                ...state,
                complexCurrentEvent: payload,
            };
        }
        case types.LOADING_EVENT: {
            return {
                ...state,
                loadingEvent: payload,
            };
        }
        case types.SET_COMPLEX_EVENTS_REPORT_FILTER: {
            return {
                ...state,
                complexEventsReportFilter: payload,
            };
        }
        case types.CLEAR_COMPLEX_EVENTS_REPORT_FILTER: {
            return {
                ...state,
                complexEventsReportFilter: {},
            };
        }
        case types.LOADED_SIDEBAR: {
            const {
                data,
                page,
            } = payload;

            if (page === 1) {
                return {
                    ...state,
                    sidebar: data,
                };
            }

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...data,
                    data: [
                        ...state?.sidebar?.data,
                        ...data?.data
                    ],
                    // data: values(merge(
                    //     keyBy(state?.sidebar?.data  || [], 'id'),
                    //     keyBy(data?.data || [], 'id')
                    // ))
                },
            };
        }
        case types.CLEAR_SIDEBAR: {
            return {
                ...state,
                sidebar: initialState.sidebar,
            };
        }
        case types.LOADING_SIDEBAR: {
            return {
                ...state,
                sidebarLoading: payload,
            };
        }
        case types.LOADED_EVENT_ERRORS: {
            return {
                ...state,
                eventErrors: payload,
            };
        }
        case types.EDITING_EVENT_ERRORS: {
            return {
                ...state,
                loadingEditEventErrors: payload,
            };
        }
        case types.LOADING_FORMATTED_COMPLEX_HISTORY: {
            return {
                ...state,
                loadingFormattedComplexHistory: payload
            };
        }
        case types.LOADED_FORMATTED_COMPLEX_HISTORY: {
            return {
                ...state,
                formattedComplexHistory: payload
            };
        }
        case types.LOADING_COMPLEX_RULES:
            return {
                ...state,
                loadingComplexRules: payload
            };
        case types.LOADED_COMPLEX_RULES:
            return {
                ...state,
                complexRules: payload
            };
        case types.CLEAR_COMPLEX_RULES:
            return {
                ...state,
                complexRules: []
            };
        // case types.LOADED_CURRENT_COMPLEX:
        //     return {
        //         ...state,
        //         //currentComplex: { [payload.id]: payload }
        //         currentComplex: payload
        //     };
        case types.LOADED_COMPLEX_TRANSFER_TYPES:
            return {
                ...state,
                complexTransferTypes: payload
            };
        case types.LOADED_COMPLEX_TRANSFER_RULES:
            return {
                ...state,
                complexTransferRules: payload
            };
        case types.CLEAR_COMPLEX_TRANSFER_RULES:
            return {
                ...state,
                complexTransferRules: []
            };
        case types.LOADED_COMPLEX_STATISTIC:
            return {
                ...state,
                complexStatistic: payload
            };
        case types.LOADED_COMPARATIVE_STATISTICS:
            return {
                ...state,
                comparativeStatistics: payload
            };
        case types.LOADING_COMPARATIVE_STATISTICS:
            return {
                ...state,
                loadingComparativeStatistics: payload
            };
        case types.LOADED_CURRENT_COMPLEX_TRANSFER_TYPES:
            return {
                ...state,
                currentComplexTransferTypes: payload
            };
        case types.SET_SHOW_DORIS_CAMERA_EVENTS:
            return {
                ...state,
                showDorisCameraEvents: payload
            };
        case types.SET_ACTIVE_EVENT:
            return {
                ...state,
                activeEvent: payload
            };

        case types.LOADED_COORDINATION_HISTORY: {
            return {
                ...state,
                coordinationHistory: payload
            };
        }

        case types.LOADING_COORDINATION_HISTORY: {
            return {
                ...state,
                loadingCoordinationHistory: payload
            };
        }

        case types.SET_COORDINATION_HISTORY_DATES: {
            return {
                ...state,
                coordinationHistoryDates: payload
            };
        }

        case types.SET_FILTERS:
            return {
                ...state,
                filters: payload
            };

        case types.CLEAR_FILTERS:
            return {
                ...state,
                filters: initialState.filters
            };
        case types.LOADED_INACCURACY:
            return {
                ...state,
                complexInaccuracy: payload
            };
        case types.LOADED_DORIS_CONTROL_SETTINGS:
            return {
                ...state,
                dorisControlSettings: payload
            };
        case types.LOADING_DORIS_CONTROL_SETTINGS:
            return {
                ...state,
                loadingDorisControlSettings: payload
            };
        case types.LOADED_TS_CATEGORIES:
            return {
                ...state,
                tsCategories: payload
            };
        case types.LOADING_INSPECTION_CERTIFICATE:
            return {
                ...state,
                loadingInspectionCertificate: payload
            };
        case types.LOADING_FILE:
            return {
                ...state,
                loadingFile: payload
            };
        case types.LOADING_1C_NAMES:
            return {
                ...state,
                loading1CNames: payload
            };
        case types.LOADED_1C_NAMES:
            return {
                ...state,
                namesIn1C: payload
            };
        case types.CLEAR_1C_NAMES:
            return {
                ...state,
                namesIn1C: initialState.namesIn1C
            };
        case types.LOADING_REPORT_BY_1C_NAMES:
            return {
                ...state,
                report1CNamesLoading: payload
            };
        case types.SET_OPERATOR_LOG_REPORT_FILTER: {
            return {
                ...state,
                operatorLogReportFilter: payload
            };
        }
        case types.CLEAR_OPERATOR_LOG_REPORT_FILTER: {
            return {
                ...state,
                operatorLogReportFilter: {}
            };
        }
        case types.SET_DORIS_CONTROL_WS: {
            const { payload } = action;
            const newPolygon = payload
                ?.reduce(
                    (res, item) => replaceForWsInRedux(res || [], item),
                    state.polygon
                );

            return {
                ...state,
                polygon: newPolygon,
                // polygon: replaceForWsInRedux(state.polygon, payload),
                // sidebar: {
                //     ...state.sidebar,
                //     data: replaceForWsInRedux(state.sidebar?.data, payload),
                // },
                sidebarParams: {
                    ...state.sidebarParams,
                    page: 1
                }
            };
        }
        case types.SET_SIDEBAR_PARAMS: {
            const { payload } = action;
            return {
                ...state,
                sidebarParams: {
                    ...state.sidebarParams,
                    ...payload,
                }
            };
        }
        case types.LOADED_COMPLEX_BY_ID: {
            const { payload } = action;
            return {
                ...state,
                complexById: payload
            };
        }
        case types.LOADING_COMPLEX_BY_ID: {
            const { payload } = action;
            return {
                ...state,
                loadingComplexById: payload
            };
        }
        case types.LOADED_TAG_LIST: {
            const { payload } = action;
            return {
                ...state,
                tagList: payload
            };
        }
        case types.LOADED_COMPLEX_TYPE_GROUPING: {
            const { payload } = action;
            return {
                ...state,
                complex_type_grouping: payload
            };
        }
        case types.LOADED_COMPLEX_MEASURING: {
            const { payload } = action;
            return {
                ...state,
                complex_measuring: payload
            };
        }
        case types.LOADED_RECOGNITION_CAMERA_DIR_TYPES:
            return {
                ...state,
                recognitionCameraDirTypes: payload
            };

        case types.LOADED_COMPUTER_TRANSFER_TYPES:
            return {
                ...state,
                computerTransferTypes: payload
            };
        case types.LOADED_COMPUTER_TRANSFER_RULES:
            return {
                ...state,
                computerTransferRules: payload
            };
        case types.CLEAR_COMPUTER_TRANSFER_RULES:
            return {
                ...state,
                computerTransferRules: []
            };
        case types.LOADED_DORIS_EXPORT_TYPES:
            return {
                ...state,
                dorisExportTypes: payload
            };
        case types.LOADING_EXCLUSIVE_FILES: {
            return {
                ...state,
                loadingExclusiveFiles: payload,
            };
        }
        case types.LOADED_EXCLUSIVE_FILES: {
            const { data, storeField } = payload;

            return {
                ...state,
                ...(storeField ? { [storeField]: data } : { exclusiveFiles: data }),
            };
        }
        case types.LOADED_EXCLUSIVE_FILE_HISTORY:
            return {
                ...state,
                exclusiveFileHistoryList: payload
            };

        case types.LOADING_EXCLUSIVE_FILE_HISTORY:
            return {
                ...state,
                loadingExclusiveFileHistory: payload,
            };


        case types.LOADED_CHECKLISTS:
            return {
                ...state,
                checklists: payload,
            };

        case types.LOADING_CHECKLISTS:
            return {
                ...state,
                loadingChecklist: payload,
            };

        case types.LOADED_MATERIAL_STORAGE_GROUPS:
            return {
                ...state,
                materialStorageGroups: payload
            };

        case types.LOADING_MATERIAL_STORAGE_GROUPS:
            return {
                ...state,
                loadingMaterialStorageGroups: payload,
            };
        case types.LOADED_VIOLATION_EVENTS:
            return {
                ...state,
                violationEvents: payload
            };
        case types.LOADING_VIOLATION_EVENTS:
            return {
                ...state,
                loadingViolationEvents: payload
            };
        case types.LOADING_BUTTON:
            return {
                ...state,
                loadingButton: payload,
            };
        case types.LOADED_COMPLEXES_TREE: {
            return {
                ...state,
                complexesTree: payload,
            };
        }
        case types.LOADING_COMPLEXES_TREE: {
            return {
                ...state,
                loadingComplexesTree: payload,
            };
        }
        case types.LOADED_EVENT_CHECK_LIST:
            return {
                ...state,
                eventCheckList: payload
            };
        case types.LOADING_EVENT_CHECK_LIST:
            return {
                ...state,
                loadingEventCheckList: payload
            };

        case types.LOADED_EXCLUSIVE_FILES_HISTORY_LIST: {
            return {
                ...state,
                exclusiveFilesHistoryList: payload,
            };
        }
        case types.LOADING_EXCLUSIVE_FILES_HISTORY_LIST: {
            return {
                ...state,
                loadingExclusiveFilesHistoryList: payload,
            };
        }
        case types.LOADED_EXCLUSIVE_FILES_LOG_LIST: {
            return {
                ...state,
                exclusiveFilesLogList: payload,
            };
        }
        case types.LOADING_EXCLUSIVE_FILES_LOG_LIST: {
            return {
                ...state,
                loadingExclusiveFilesLogList: payload,
            };
        }
        case types.LOADED_DOCUMENTS_HISTORY: {
            return {
                ...state,
                documentsHistory: payload,
            };
        }
        case types.LOADING_DOCUMENTS_HISTORY: {
            return {
                ...state,
                loadingDocumentsHistory: payload,
            };
        }
        case types.LOADING_CHECKLIST_GROUP: {
            return {
                ...state,
                loadingCheckListGroup: payload,
            };
        }
        case types.LOADED_CHECKLIST_GROUP: {
            return {
                ...state,
                checkListGroup: payload,
            };

        }
        case types.LOADED_EVENTS_STATISTICS:
            return {
                ...state,
                eventsStatistic: payload,
            };
        case types.LOADING_EVENTS_STATISTICS:
            return {
                ...state,
                loadingEventsStatistic: payload,
            };

        case types.LOADED_COMPLEX_BY_RADIUS:
            return {
                ...state,
                complexesByRadius: payload
            };

        case types.LOADED_CAMERA_VIOLATIONS_TREE:
            return {
                ...state,
                violationsTree: payload?.data
            };

        case types.LOADING_CAMERA_VIOLATIONS_TREE:
            return {
                ...state,
                loadingViolationsTree: payload
            };
        case types.LOADED_MATERIAL_EXPORT_GROUP:
            return {
                ...state,
                materialExportGroup: payload
            };

        case types.LOADING_MATERIAL_EXPORT_GROUP:
            return {
                ...state,
                loadingMaterialExportGroup: payload,
            };

        case types.LOADED_MATERIAL_EXPORT_GROUP_TYPES:
            return {
                ...state,
                materialExportGroupTypes: payload
            };

        case types.LOADED_MATERIAL_EXPORT_GROUP_PROTOCOLS:
            return {
                ...state,
                materialExportGroupProtocols: payload
            };

        case types.LOADED_TYPE_OF_MISTAKES_FOR_CHECKLIST:
            return {
                ...state,
                typeOfMistakesForChecklist: payload
            };

        case types.LOADING_TYPE_OF_MISTAKES_FOR_CHECKLIST:
            return {
                ...state,
                loadingTypeOfMistakesForChecklist: payload
            };

        case types.LOADED_SETTINGS_GROUP:
            return {
                ...state,
                settingsGroup: payload
            };

        case types.LOADING_SETTINGS_GROUP:
            return {
                ...state,
                loadingSettingsGroup: payload
            };
        case types.LOADING_EVENT_PDDS:
            return {
                ...state,
                loadingEventPdds: payload
            };

        case types.LOADED_SHOW_CASE_COMPLEX_STATUS:
            return {
                ...state,
                showCaseComplexStatus: payload
            };
        case types.LOADING_SHOW_CASE_COMPLEX_STATUS:
            return {
                ...state,
                loadingShowCaseComplexStatus: payload
            };

        case types.LOADED_SHOW_CASE_DOCUMENTS_VERIFICATION:
            return {
                ...state,
                showCaseDocumentsVerification: payload
            };
        case types.LOADING_SHOW_CASE_DOCUMENTS_VERIFICATION:
            return {
                ...state,
                loadingShowCaseDocumentsVerification: payload
            };

        case types.LOADED_DUPLO_CODES:
            return {
                ...state,
                duploCodes: payload
            };

        case types.LOADING_DUPLO_CODES:
            return {
                ...state,
                loadingDuploCodes: payload
            };

        case types.LOADED_MATERIALS:
            return {
                ...state,
                materials: payload
            };

        case types.LOADING_MATERIALS:
            return {
                ...state,
                loadingMaterials: payload
            };

        case types.LOADED_MATERIAL_EXPORT:
            return {
                ...state,
                materialExport: payload
            };

        case types.LOADING_MATERIAL_EXPORT:
            return {
                ...state,
                loadingMaterialExport: payload
            };

        case types.LOADED_MATERIAL_EXPORT_TYPES:
            return {
                ...state,
                materialExportTypes: payload
            };

        case types.LOADED_MATERIAL_EXPORT_PROTOCOLS:
            return {
                ...state,
                materialExportProtocols: payload
            };

        case types.LOADED_MATERIAL_EXPORT_STATUSES:
            return {
                ...state,
                materialExportStatuses: payload
            };

        case types.LOADED_COMPLEX_CONDITIONS:
            return {
                ...state,
                complexConditions: payload
            };

        default:
            return state;
    }
}
