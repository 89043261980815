import VACameras from '../../pages/Dictionaries/VideoAnalytics/VACameras';

const routes = [
    {
        path: '/dictionaries/video-analytics/cameras',
        component: VACameras,
        exact: true
    },
];

export default routes;