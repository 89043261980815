import { TextField } from '@mui/material';
import React from 'react';
import titles from '../../../../../../helpers/constants/titles';

const Filter = ({ data, onChange }) => {
    
    return <>
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="block">
                <TextField
                    value={data.name || ''}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    name="name"
                    type="text"
                    onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                />
            </div>
            <div className="block">
                <TextField
                    value={data.ip_route || ''}
                    label={titles.IP_ADDRESS}
                    variant="outlined"
                    size="small"
                    name="ip_route"
                    type="text"
                    onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                />
            </div>                  
        </form>    
    </>;
};

export default Filter;
