import React from 'react';
import SelectScenarios from 'components/common/Autocomplete/DorisReact/Scenarios';
import RadioCheckScenario from './RadioCheckScenario';

const Scenarios = ({ selected_scenarios = [], onChange }) => {
    const handleChangeScenarios = (value) => {
        const defaultItem = value.find(el => el.default);
        if (!defaultItem && value.length > 0) {
            value[0].default = true;
        }
        onChange(value );
    };

    const handleCheck = (id) => {
        const changeDefault = selected_scenarios.map(el => ({ ...el, default:  el.id === id }));

        onChange(changeDefault);
    };

    return (
        <>
            <SelectScenarios 
                onChange={handleChangeScenarios}
                selected={selected_scenarios}
                multiple={true}
            />
            {selected_scenarios?.length > 0 
                && <RadioCheckScenario
                    data={selected_scenarios}
                    setSelected={handleCheck}
                    selectedItem={selected_scenarios.find(el => el.default)}                                                                    
                />                               
            }   
        </>
    );
};

export default Scenarios;