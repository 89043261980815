import React, { useEffect, useState } from 'react';

// подписываем компонент на изменение зума
// todo рефактор компонента
const Inner = ({
    innerComponent: InnerComponent = null,
    innerComponentEvent = null,
    ...props
}) => {
    const [zoom, setZoom] = useState(props?.map?.getZoom());

    const handleListener = () => {
        setZoom(props?.map?.getZoom());
    };

    useEffect(() => {
        if (innerComponentEvent) {
            props?.map?.on(innerComponentEvent, handleListener);

            return () => {
                props?.map?.off(innerComponentEvent, handleListener);
            };
        }
    }, [innerComponentEvent]);

    return (
        <InnerComponent {...props} zoom={zoom} />
    );
};

export default Inner;
