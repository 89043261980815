import { getFullDateTimeWithTextMonth } from 'helpers/date.config';

const Item = (props) => {
    const {
        name,
        address_text,
        updated_at,
        checkValue,
        cameras
    } = props;

    return (
        <>
            <div className="description">
                <div>{name}</div>
                <div>{address_text}</div>
            </div>

            <div className="date">
                <span>
                    Последние данные от: <br/>
                    {updated_at ? getFullDateTimeWithTextMonth(updated_at) : ''}
                </span>
            </div>
            
            {checkValue === 'group' && (
                <>
                    <h3>Камеры:</h3>
                    {cameras.map( item => (
                        <div className="item" key={item.id}>
                            {item.name}
                        </div>
                    ))}
                </>
            )}
        </>
    );
};

export default Item;
