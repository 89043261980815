import { TextField, Autocomplete } from '@mui/material';
import { useState } from 'react';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';

interface FilterParams {
    page: number;
    query: Record<string, any>;
}
  
interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<FilterParams>>;
}
  
interface DataState {
    violation: string;
    article: {
        title: string;
        is_active?: number;
    };
}

const Filter = ({ setParams }: FilterProps) => {
    const [data, setData] = useState<DataState>({
        violation: '',
        article: { title: 'Все' },
    });

    const onChange = (e: { target: { name: string, value: object | string | null }; }) => {

        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const onSearch = (filters = data) => {
        const prepareData = removeEmptyFields({
            violation: filters?.violation,
            is_active: filters?.article?.is_active
        });

        setParams((prevParams) => ({ ...prevParams, query: prepareData }));
    };

    const resetFilter = (needRefresh: boolean) => {
        setData({
            violation: '',
            article: { title: 'Все' }
        });
        needRefresh && setParams((prevParams) => ({ ...prevParams, query: {} }));
    };

    const updateFilter = (filters: DataState) => {
        setData(filters);

        if (Object.keys(filters).length > 0) {
            onSearch(filters);
        }
    };

    const options = [{ title: 'Все' }, { title: 'Активные', is_active: 1 }, { title: 'Пассивные', is_active: 0 }];

    return (
        <LayoutFilter
            filter={data}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            disabled={!data.violation && !data.article}
        >
            <LayoutFilter.Visible>
                <TextField
                    label={'Номер или название'}
                    value={data?.violation || ''}
                    onChange={onChange}
                    name="violation"
                    variant="outlined"
                    size="small"
                />
                <Autocomplete
                    value={data?.article}
                    options={options}
                    isOptionEqualToValue={(option, value) => option?.title === value?.title}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.title || ''}
                    onChange={(_, value) => onChange({ target: { name: 'article', value } })}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Показать статьи"
                        />
                    )}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
