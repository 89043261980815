import { config } from '../../config';

const apiTransportUrl = () => config.get('urls').apiTransportPassengerUrl;
const apiDadataUrl = () => config.get('urls').apiDadataUrl;
const apiItsTelemetryUrl = () => config.get('urls').apiItsTelemetryUrl;
const apiCaptcha = () => config.get('urls').apiCaptcha;

const apiUrls = {
    getStationList: () => `${apiTransportUrl()}/station`,

    getStationSchedule: () => `${apiTransportUrl()}/schedule/station`,

    getRouteTypes: () => `${apiTransportUrl()}/route/types`,

    getBrandList: () => `${apiTransportUrl()}/brand`,

    createBrand: () => `${apiTransportUrl()}/brand`,

    editBrand: (id) => `${apiTransportUrl()}/brand/${id}`,

    getVehicleList: () => `${apiTransportUrl()}/vehicle`,

    getVehiclesNoWorkorder: () => `${apiTransportUrl()}/vehicle/no_work_order`,

    deleteVehicle: (id) => `${apiTransportUrl()}/vehicle/${id}`,

    editVehicle: (id) => `${apiTransportUrl()}/vehicle/${id}`,

    createNewVehicle: () => `${apiTransportUrl()}/vehicle`,

    getCategories: () => `${apiTransportUrl()}/vehicle/categories`,

    createCategory: () => `${apiTransportUrl()}/vehicle_types`,

    editCategory: (id) => `${apiTransportUrl()}/vehicle_types/${id}`,

    deleteCategory: (id) => `${apiTransportUrl()}/vehicle_types/${id}`,

    getTypes: () => `${apiTransportUrl()}/vehicle/types`,

    getModelList: () => `${apiTransportUrl()}/model`,

    createModel: () => `${apiTransportUrl()}/model`,

    editModel: (id) => `${apiTransportUrl()}/model/${id}`,

    getCompanyList: () => `${apiTransportUrl()}/organization`,

    createCompany: () => `${apiTransportUrl()}/organization/`,

    editCompany: (id) => `${apiTransportUrl()}/organization/${id}`,

    getCompanies: () => `${apiDadataUrl()}/its/companies`, // TODO сервис dadata вынести в отдельный модуль

    getRouteGroup: () => `${apiTransportUrl()}/route/grouped`,

    getRouteNums: () => `${apiTransportUrl()}/route/nums`,

    getRouteStatuses: () => `${apiTransportUrl()}/route/statuses`,

    getRouteTransportations: () => `${apiTransportUrl()}/route/transportations`,

    saveRoutes: () => `${apiTransportUrl()}/route/multi`,

    createRoute: () => `${apiTransportUrl()}/route`,

    changeRoute: (uuid) => `${apiTransportUrl()}/route/${uuid}`,

    createRouteV2: () => `${apiTransportUrl()}/v2/route`,

    changeRouteV2: (uuid) => `${apiTransportUrl()}/v2/route/${uuid}`,

    getRouteSchedule: () => `${apiTransportUrl()}/schedule/route`,

    getRoutes: () => `${apiTransportUrl()}/route`,

    getRouteStation: () => `${apiTransportUrl()}/route/station`,

    getScheduleTypes: () => `${apiTransportUrl()}/schedule/types`,

    editStation: (id) => `${apiTransportUrl()}/station/${id}`,

    createNewStation: () => `${apiTransportUrl()}/station/`,

    addSchedule: () => `${apiTransportUrl()}/schedule/pack`,

    editSchedule: () => `${apiTransportUrl()}/schedule`,

    getOrganizationReport: () => `${apiTransportUrl()}/organization`,

    getVehicleReport: () => `${apiTransportUrl()}/vehicle/report`,

    // getCurrentPassengerReport: (id) => `${apiTransportUrl()}/vehicle/history/${id}`,

    // нет метода (/vehicle/history/special)
    // todo проверить
    getCurrentSpecialReport: (uid) => `${apiTransportUrl()}/vehicle/history/${uid}`,

    getNextStation: () => `${apiItsTelemetryUrl()}/route/next`,

    getVehiclePopup: () => `${apiTransportUrl()}/vehicle`,

    getVehicleHistory: (id) => `${apiTransportUrl()}/vehicle/history/${id}`,

    getVehicleFlightHistory: (id) => `${apiTransportUrl()}/vehicle/${id}/flight-history`,

    getCurrentVehicles: (id) => `${apiTransportUrl()}/vehicle/?route=${id}`,

    getCurrentVehiclesType: () => `${apiTransportUrl()}/vehicle`,

    saveSelectedVehicleToRoute: () => `${apiTransportUrl()}/vehicle/routes`,

    getPositions: () => `${apiTransportUrl()}/positions`,

    addPositions: () => `${apiTransportUrl()}/positions`,

    editPositions: (id) => `${apiTransportUrl()}/positions/${id}`,

    deletePositions: (id) => `${apiTransportUrl()}/positions/${id}`,

    getScheduleEvent: () => `${apiTransportUrl()}/schedule_event`,

    addScheduleEvent: () => `${apiTransportUrl()}/schedule_event`,

    editScheduleEvent: (id) => `${apiTransportUrl()}/schedule_event/${id}`,

    deleteScheduleEvent: (id) => `${apiTransportUrl()}/schedule_event/${id}`,

    getEmployees: () => `${apiTransportUrl()}/employees`,

    addEmployees: () => `${apiTransportUrl()}/employees`,

    editEmployees: (id) => `${apiTransportUrl()}/employees/${id}`,

    deleteEmployees: (id) => `${apiTransportUrl()}/employees/${id}`,

    getOrganization: (id) => `${apiTransportUrl()}/organization/${id}`,

    getEquipments: () => `${apiTransportUrl()}/vehicle/characteristics`,

    getBrigades: () => `${apiTransportUrl()}/brigades`,

    getBrigadesNoWorkorder: () => `${apiTransportUrl()}/brigades/no_work_order`,

    addBrigades: () => `${apiTransportUrl()}/brigades`,

    editBrigades: (id) => `${apiTransportUrl()}/brigades/${id}`,

    deleteBrigades: (id) => `${apiTransportUrl()}/brigades/${id}`,

    getParks: () => `${apiTransportUrl()}/park`,

    postPark: () => `${apiTransportUrl()}/park/`,

    putPark: (id) => `${apiTransportUrl()}/park/${id}`,

    deletePark: (id) => `${apiTransportUrl()}/park/${id}`,

    getStationTypes: () => `${apiTransportUrl()}/station/types`,

    deleteStation: (id) => `${apiTransportUrl()}/station/${id}`,

    getScheduleTemplateList: () => `${apiTransportUrl()}/schedule_template`,

    getScheduleTemplateStatuses: () => `${apiTransportUrl()}/schedule_template/statuses`,

    createScheduleTemplate: () => `${apiTransportUrl()}/schedule_template/`,

    editScheduleTemplate: (id) => `${apiTransportUrl()}/schedule_template/${id}`,

    deleteScheduleTemplate: (id) => `${apiTransportUrl()}/schedule_template/${id}`,

    saveRouteStations: () => `${apiTransportUrl()}/route/station/sync`,

    getWorkorders: () => `${apiTransportUrl()}/workorder`,

    getWorkorderStatuses: () => `${apiTransportUrl()}/workorder/statuses`,

    getWorkordersByDate: () => `${apiTransportUrl()}/workorder/bydate`,

    editWorkorders: (id) => `${apiTransportUrl()}/workorder/${id}`,

    editWorkorderStatus: (id) => `${apiTransportUrl()}/workorder/${id}/status`,

    getTrafficSchedule: () => `${apiTransportUrl()}/traffic_schedule`,

    getTrafficScheduleNoWorkorder: () => `${apiTransportUrl()}/traffic_schedule/no_work_order`,

    getIntersectionRoutes: () => `${apiTransportUrl()}/route/intersection`,

    getScheduleTemplateStationsList: () => `${apiTransportUrl()}/schedule_template/station`,

    deleteScheduleTemplateStation: (id) => `${apiTransportUrl()}/schedule_template/station/${id}`,

    editScheduleTemplateStation: (id) => `${apiTransportUrl()}/schedule_template/station/${id}`,

    createScheduleTemplateStation: () => `${apiTransportUrl()}/schedule_template/station/multi`,

    getTrafficScheduleStatuses: () => `${apiTransportUrl()}/traffic_schedule/statuses`,

    createTrafficSchedule: () => `${apiTransportUrl()}/traffic_schedule/`,

    copyTrafficSchedule: (id) => `${apiTransportUrl()}/traffic_schedule/${id}/copy`,

    getTrafficScheduleTypes: () => `${apiTransportUrl()}/traffic_schedule/types`,

    getCurrentTrafficSchedule: (id) => `${apiTransportUrl()}/traffic_schedule/${id}/events`,

    getAppeal: () => `${apiTransportUrl()}/appeal`,

    createAppeal: () => `${apiTransportUrl()}/appeal`,

    editAppeal: (id) => `${apiTransportUrl()}/appeal/${id}`,

    deleteAppeal: (id) => `${apiTransportUrl()}/appeal/${id}`,

    getAppealStatus: () => `${apiTransportUrl()}/appeal/statuses`,

    getAppealType: () => `${apiTransportUrl()}/appeal/types`,

    getRouteCheckpoints: (id) => `${apiTransportUrl()}/route/${id}/check-points`,

    getNearStation: () => `${apiTransportUrl()}/station/near-to-geojson`,

    saveRouteCheckpoints: (id) => `${apiTransportUrl()}/route/${id}/check-points`,

    loadRouteWithCheckPoints: () => `${apiTransportUrl()}/v2/route/by-num`,

    getWorkorderEvents: (id) => `${apiTransportUrl()}/workorder/${id}/events`,

    getWorkorderEventStatuses: () => `${apiTransportUrl()}/work-order-event/statuses`,

    getRouteRequestsList: () => `${apiTransportUrl()}/route-request`,

    getStatusesRouteRequests: () => `${apiTransportUrl()}/route-request/statuses`,

    loadRouteRequestsList: () => `${apiTransportUrl()}/route-request`,

    loadStatusesRouteRequests: () => `${apiTransportUrl()}/route-request/statuses`,

    createRouteRequest: () => `${apiTransportUrl()}/route-request`,

    editRouteRequest: (id) => `${apiTransportUrl()}/route-request/${id}`,

    deleteRouteRequest: (id) => `${apiTransportUrl()}/route-request/${id}`,

    getCaptcha: () => `${apiCaptcha()}/captcha`,

    putTrafficScheduleStatus: (id) => `${apiTransportUrl()}/traffic_schedule/status/${id}`,

    fetchSchedule: () => `${apiTransportUrl()}/schedule`,

    postSchedule: () => `${apiTransportUrl()}/schedule/`,

    fetchScheduleStatuses: () => `${apiTransportUrl()}/schedule/statuses`,

    putSchedule: (id) => `${apiTransportUrl()}/schedule/${id}`,

    delSchedule: (id) => `${apiTransportUrl()}/schedule/${id}`,

    // fetchScheduleEvents: (id) => `${apiTransportUrl()}/schedule/${id}/events`,
    fetchScheduleEvents: (id) => `${apiTransportUrl()}/v2/schedule/${id}/events`,

    putScheduleStatus: (id, statusId) => `${apiTransportUrl()}/schedule/${id}/${statusId}`,

    getScheduleTemplateCheckpoints: (id) => `${apiTransportUrl()}/schedule_template/${id}/check-points`,

    saveScheduleTemplateCheckpoints: (id) => `${apiTransportUrl()}/schedule_template/${id}/check-points`,

    saveTransportationTariff: (id) => `${apiTransportUrl()}/route/${id}/tariff`,

    getTicketPoints: () => `${apiTransportUrl()}/point/ticket`,

    editTicketPoint: (id) => `${apiTransportUrl()}/point/ticket/${id}`,

    getTicketPointTypes: () => `${apiTransportUrl()}/point/ticket/types`,

    getWaybillsList: () => `${apiTransportUrl()}/waybill`,

    postWaybills: () => `${apiTransportUrl()}/waybill/`,

    editWaybill: (id) => `${apiTransportUrl()}/waybill/${id}`,

    deleteWaybill: (id) => `${apiTransportUrl()}/waybill/${id}`,

    getWaybill: (id) => `${apiTransportUrl()}/waybill/excel/${id}`,

    getStationAttributes: () => `${apiTransportUrl()}/station/attributes`,

    getTariffs: () => `${apiTransportUrl()}/tariff`,

    postTariff: () => `${apiTransportUrl()}/tariff/`,

    putTariff: (id) => `${apiTransportUrl()}/tariff/${id}`,

    deleteTariff: (id) => `${apiTransportUrl()}/tariff/${id}`,

    getVehicleCard: (id) => `${apiTransportUrl()}/vehicle/${id}/card`,

    getWaybillsLicence: () => `${apiTransportUrl()}/waybill/licence_cards`,

    getTrafficScheduleListOfEvents: () => `${apiTransportUrl()}/traffic_schedule/events`, // справочник видов событий

    editTrafficSchedule: (id) => `${apiTransportUrl()}/traffic_schedule/${id}`,

    getCoords: () => `${apiTransportUrl()}/vehicle/coords`,

    getVehicle: () => `${apiTransportUrl()}/vehicle`,

    getReportSummarySchedule: (id) => `${apiTransportUrl()}/station/${id}/schedule-by-date`,

    loadOrganizationInformation: () => `${apiTransportUrl()}/vehicle/organization_information`,

    getVehicleTelemetryStatistic: () => `${apiTransportUrl()}/vehicle/statistics/vehicles`,

    getTelemetryStatistic: () => `${apiTransportUrl()}/vehicle/statistics/telemetry`,

    getWorkordersReport: () => `${apiTransportUrl()}/report/work-orders`,

    getWoRequisiteChartererTypes: () => `${apiTransportUrl()}/wo_requisite/charterer_types`,

    getWoRequisiteList: () => `${apiTransportUrl()}/wo_requisite`,

    createWoRequisite: () => `${apiTransportUrl()}/wo_requisite`,

    editWoRequisite: (id) => `${apiTransportUrl()}/wo_requisite/${id}`,

    deleteWoRequisite: (id) => `${apiTransportUrl()}/wo_requisite/${id}`,

    getRadioType: () => `${apiTransportUrl()}/radio_type`,

    getWorkorderEventManualStatus: () => `${apiTransportUrl()}/work-order-event/manual-statuses`,

    getWorkorderEventFlightManualStatus: () => `${apiTransportUrl()}/work-order-event/manual-flight-statuses`,

    createWorkorderEventStatus: (id) => `${apiTransportUrl()}/work-order-event/${id}/status`,

    updateWorkorderEventStatus: (id) => `${apiTransportUrl()}/work-order-event/${id}/status`,

    deleteWorkorderEventStatus: (id) => `${apiTransportUrl()}/work-order-event/${id}/status`,

    getFlightDefectGroup: () => `${apiTransportUrl()}/defect_group`,

    getFlightDefects: () => `${apiTransportUrl()}/flight_defect`,

    getReportByFlights: () => `${apiTransportUrl()}/report/flights`,

    getReportByVehicles: () => `${apiTransportUrl()}/v2/report/vehicles`,

    getEmployeesTransportationReport: () => `${apiTransportUrl()}/report/employers`,

    getFlightTypes: () => `${apiTransportUrl()}/flight/types`,

    editFlightType: (id) => `${apiTransportUrl()}/flight/${id}/type`,

    copyScheduleTemplate: (id) => `${apiTransportUrl()}/schedule_template/${id}`,

    getSpeedViolationsReport: () => `${apiTransportUrl()}/vehicle/overspeed/report`,

    getSettingsGroup: () => `${apiTransportUrl()}/settings_group`,

    editSettingsGroup: (id) => `${apiTransportUrl()}/settings_group/${id}/settings/update`,

    getTransportSettings: () => `${apiTransportUrl()}/settings`,

    editTransportSetting: (id) => `${apiTransportUrl()}/settings/${id}`,

    editSettingsList: () => `${apiTransportUrl()}/settings/list`,

    getPassengersCount: () => `${apiTransportUrl()}/station/passenger-traffic`,

    getSidebar: () => `${apiTransportUrl()}/route/grouped`,

    getTemplate: () => `${apiTransportUrl()}/vehicle?to_xls=true`,

    getTransorganizations: () => `${apiTransportUrl()}/trans-organization`,

    editTransorganization: (id) => `${apiTransportUrl()}/trans-organization/${id}`,

    /* color speed */
    getColorSpeedList: () => `${apiTransportUrl()}/color-speed`,

    addColorSpeed: () => `${apiTransportUrl()}/color-speed`,

    editColorSpeed: (id) => `${apiTransportUrl()}/color-speed/${id}`,

    deleteColorSpeed: (id) => `${apiTransportUrl()}/color-speed/${id}`,

    getMilestoneEvents: () => `${apiTransportUrl()}/milestone-event`,

    loadStationPolygon: () => `${apiTransportUrl()}/station/polygon`,

    loadStationScheduleByDate: (id) => `${apiTransportUrl()}/station/${id}/schedule-by-date`,

    getTelemetryReceivers: () => `${apiTransportUrl()}/egts-receiver`,

    addTelemetryReceiver: () => `${apiTransportUrl()}/egts-receiver`,

    editTelemetryReceiver: (id) => `${apiTransportUrl()}/egts-receiver/${id}`,

    deleteTelemetryReceiver: (id) => `${apiTransportUrl()}/egts-receiver/${id}`,

    getTelemetryReceiverTypes: () => `${apiTransportUrl()}//egts-receiver/types`,

    changeRouteStatus: (id, status) => `${apiTransportUrl()}/route/${id}/change-status/${status}`,

    getWorkCalendar: () => `${apiTransportUrl()}/work_calendar`,

    editWorkCalendar: () => `${apiTransportUrl()}/work_calendar/`,

    getWorkCalendarDayTypes: () => `${apiTransportUrl()}/work_calendar/day/types`,

    getWorkCalendarHistory: () => `${apiTransportUrl()}/work_calendar/history/human`,

    deleteRoute: (id) => `${apiTransportUrl()}/route/${id}`,

    getVehicleClasses: () => `${apiTransportUrl()}/vehicle/classes`,

    createVehicleClass: () => `${apiTransportUrl()}/vehicle_classes`,

    editVehicleClass: (id) => `${apiTransportUrl()}/vehicle_classes/${id}`,

    deleteVehicleClass: (id) => `${apiTransportUrl()}/vehicle_classes/${id}`,

    getRegularTransportations: () => `${apiTransportUrl()}/route/regular/transportations`,

    getSeasonality: () => `${apiTransportUrl()}/route/seasonality`,

    getRouteSortDirections: () => `${apiTransportUrl()}/route/sort-directions`,

    editRouteAdditionalParams: (id) => `${apiTransportUrl()}/route/${id}/additional_params`,

    getRouteHistoryHuman: (id) => `${apiTransportUrl()}/route/${id}/history/human`,

    loadShowcase: () => `${apiTransportUrl()}/showcase`,

    getSRoutesByNCCallback: () => `${apiTransportUrl()}/v2/route/by-num`,

    loadEgtsTransmitters: () => `${apiTransportUrl()}/egts-transmitter`,

    addEgtsTransmitter: () => `${apiTransportUrl()}/egts-transmitter`,

    editEgtsTransmitter: (id) => `${apiTransportUrl()}/egts-transmitter/${id}`,

    deleteEgtsTransmitter: (id) => `${apiTransportUrl()}/egts-transmitter/${id}`,
};

export default apiUrls;
