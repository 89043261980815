import React, { useEffect } from 'react';
import L from 'leaflet';
import { FormHelperText } from '@mui/material';
import classNames from 'classnames';
import styles from 'components/MapComponents/leaflet/components/geoJSON/MapFormGeoJSON/mapform.module.scss';
import MapDragGeoJson from 'components/MapComponents/leaflet/components/geoJSON/MapDragGeoJson';
import { CustomControl, FeatureGroup, GeoJson, Map } from 'components/MapComponents/leaflet';
import { useValidation } from 'helpers/hooks';
import { config } from '../../../../config';
import MapFinder from '../../MapContlols/MapFinder';
import MapMinimize from '../../MapContlols/MapMinimize';

// карта с редактированием геообъектов
const MapGeoJson = (props) => {
    const initial = {
        circle: true,
        visibleGeometry: null,
        isSearch: true,
        returnLatLon: false,
        validationKey: 'geometry',
        fullHeight: false,
    };
    const {
        geometry: inputGeometry = {},
        onChange = ({ geometry }) => {},
        readOnly = false,

        // circle = false,
        // rectangle = false,
        // polyline = true,
        // polygon = true,
        // marker = true,

        visibleGeometry,
        isSearch,
        // setValidation,
        required,
        returnLatLon,
        validationKey,
        fullHeight,
        isHideValidation,
        ...prop
    } = { ...initial, ...props };

    useEffect(() => {
        if (inputGeometry?.properties?.radius === 0) {
            onChange({
                geometry: {
                    ...inputGeometry,
                    properties: {}
                }
            });
        }
    }, []);

    // useEffect(() => {
    //     setValidation && setValidation({
    //         [validationKey]: !!required
    //         // geometry: !!required,
    //     });
    // }, []);

    const validation = useValidation(false);
    const geometry = inputGeometry || {};
    const mapCenter = config.get('mapCenter');
    const checkDisabledButton = !geometry || Object.keys(geometry).length === 0;

    const RightSearch = (prop) => {
        if (isSearch) {
            return (
                <CustomControl
                    {...prop}
                    position="topright"
                >
                    <MapMinimize>
                        <MapFinder />
                    </MapMinimize>
                </CustomControl>
            );
        }
        return null;
    };

    const handleChange = (geometry) => {
        const data = { geometry: geometry || {} };

        if (returnLatLon && Object.keys(geometry).length > 0) {
            const geoJson = new L.geoJSON(geometry);
            const centerGeo = geoJson.getBounds().getCenter();
            const { lat, lng } = centerGeo;
            data.lat = lat || '';
            data.lon = lng || '';
            // data.address_text = '';
        }

        onChange(data);
        validation.deleteKey(validationKey);
        // validation.deleteKeys(Object.keys(data));
    };

    const getStyle = () => {
        const style = {
            margin: 0,
        };
        if (fullHeight) {
            style.height = '100%';
        }

        return style;
    };

    return (
        <>
            <div
                className={styles.map_container}
                style={getStyle()}
            >
                <Map
                    center={mapCenter}
                >
                    <MapDragGeoJson
                        geometry={geometry}
                        onChange={handleChange}
                        readOnly={readOnly}

                        {...prop}
                    />

                    <FeatureGroup>
                        {visibleGeometry
                            && (
                                <GeoJson
                                    data={visibleGeometry}
                                    centerAfter={checkDisabledButton}
                                />
                            )
                        }
                    </FeatureGroup>

                    <RightSearch/>
                </Map>
            </div>

            {/* валидация */}
            {!isHideValidation
                && (
                    validation.isKey(validationKey)
                    // || validation.isKey('geometry')
                )
                ? (
                    <FormHelperText className="error">
                        {validation.get(validationKey, 'Выберите место на карте')}
                        {/*{validation.get('geometry', 'Выберите место на карте')}*/}
                    </FormHelperText>
                )
                : null
            }
        </>
    );
};

export default MapGeoJson;
