import { useState } from 'react';
import { TableCell } from '@mui/material';
import _ from 'lodash';

import CheckListModal from './CheckListModal';
import RenderTableCell from './RenderTableCell';

const RenderMaterialRow = ({ event = [], item = {}, duploCodes, direction }) => {
    const violationsRenderList = [
        {
            violationCode: [0],
            title: 'Проезды',
        },
        {
            violationCode: [9000],
            title: 'Технический материал',
        },
        ...duploCodes.map((code) => ({
            violationCode: [code.id],
            title: code?.koap_name || '',
        }))
    ];

    const [openCheckListModal, setOpenCheckListModal] = useState(null);

    const foundEventFunc = (violationCode) => event.find((eventItem) => {
        // есть пересечения по кодам нарушений в эвенте и ячейке КОАП
        const intersection = _.intersection(eventItem.violation_type, violationCode);
        return intersection.length > 0;
    }) || {};

    return (<>
        {violationsRenderList.map((violation, index) => {
            const cellevent = foundEventFunc(violation.violationCode);

            return (<TableCell
                key={`${violation.title}_${index}`}
                align="center"
                style={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}
            >
                <RenderTableCell
                    violationCode={violation.violationCode}
                    title={violation.title}
                    event={cellevent}
                    item={item}
                    setOpenCheckListModal={() => setOpenCheckListModal(index)}
                />
            </TableCell>);
        }
        )}
        {openCheckListModal !== null  && (
            <CheckListModal
                isOpen={openCheckListModal !== null}
                onClose={() => setOpenCheckListModal(null)}
                item={item}
                itemIndex={openCheckListModal}
                violationsRenderList={violationsRenderList}
                foundEventFunc={foundEventFunc}
                duploCodes={duploCodes}
                direction={direction}
            />
        )}
    </>);
};

export default RenderMaterialRow;
