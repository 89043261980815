import { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import * as actions from 'redux/TrafficFlow/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import { useStoreFromSelector } from 'helpers/hooks';
import { getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import dateFormat from 'helpers/constants/dateFormat';
import messages from 'helpers/constants/messages';
import { paramsWithoutEmpty, renderError, typeOptions } from 'components/common/PuidCommonFilter/helpers';
import TrafficFlow from 'components/common/Autocomplete/TrafficFlow';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

import { initialState } from './helpers';

function Filters({ setParams }) {
    const reportGroups = useStoreFromSelector(actions.loadReportStatisticType, trafficFlowSelectors.reportStatisticType);
    const reportTypes = useStoreFromSelector(actions.loadReportTimeSlot, trafficFlowSelectors.reportTimeSlot);

    const [formValues, setFormValues] = useState(initialState);
    const [parameters, setParameters] = useState([]);

    const handleChangePuid = (values) => {
        setFormValues({
            ...formValues,
            id_list: values,
        });
    };

    const handleChangeSelects = (e) => {
        const { value, name } = e.target;

        if (name === 'time_slot') {
            const addDate = typeOptions[value];
            const { count, unit } = addDate;

            const start_date = getDateWithDuration(
                { days: 1 },
                getStartOf('day', getDateWithDuration({ [unit]: -count }))
            );

            setFormValues({
                ...formValues,
                start_date: start_date,
                end_date: getEndOf('day'),
                [name]: value,
            });

        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    const handleChangeStartDate = (value) => {
        const addDate = typeOptions[formValues.time_slot];
        const { count, unit } = addDate;

        setFormValues({
            ...formValues,
            start_date: value,
            end_date: getDateWithDuration({ [unit]: count, second: -1 }, value),
        });
    };

    const handleChangeEndDate = (value) => {
        setFormValues({
            ...formValues,
            end_date: value
        });
    };

    const reset = (needRefresh) => {
        setFormValues(initialState);
        const time_slot = initialState?.time_slot;
        const parametersCurrent = reportTypes?.find(({ value }) => value === time_slot);
        setParameters(parametersCurrent?.parameters || []);

        if (needRefresh) {
            setParams(paramsWithoutEmpty(initialState));
        }
    };

    const search = () => {
        setParams(formValues);
    };

    const update = (filters) => {
        setFormValues({ ...initialState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({ ...initialState, ...filters });
        }
    };

    useEffect(() => {
        if (parameters.length === 0 && reportTypes?.length > 0) {
            const parametersCurrent = reportTypes?.find(({ value }) => value === formValues.time_slot);
            setParameters(parametersCurrent?.parameters || []);
        }
    }, [formValues.time_slot, parameters.length, reportTypes]);

    const disableRequestBtn = () => {
        const { time_slot, report_type, start_date, end_date } = paramsWithoutEmpty(formValues);
        return !time_slot || !report_type || !start_date || !end_date || renderError(formValues, 'start_date', 'end_date', parameters, reportTypes)?.error;
    };

    return (
        <LayoutFilter
            filter={formValues}
            onSearch={search}
            onResetFilter={reset}
            setUserFilter={update}
            filterException={['start_date', 'end_date']}
            disabled={disableRequestBtn()}
        >
            <LayoutFilter.Visible>
                <TrafficFlow
                    multiple
                    selected={formValues?.id_list || []}
                    onChange={handleChangePuid}
                    typeId={formValues?.type_id || null}
                />

                <FormControl variant="outlined" size="small">
                    <InputLabel>Вид отчета</InputLabel>
                    <Select
                        label="Вид отчета"
                        name="report_type"
                        value={formValues.report_type || ''}
                        onChange={handleChangeSelects}
                    >
                        {reportGroups?.length > 0
                            ? reportGroups.map(item =>
                                <MenuItem key={item.value} value={item.value}>
                                    {item.description}
                                </MenuItem>
                            )
                            : <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>
                        }
                    </Select>
                </FormControl>

                <FormControl variant="outlined" size="small">
                    <InputLabel>Тип отчета</InputLabel>
                    <Select
                        label="Тип отчета"
                        name="time_slot"
                        value={formValues?.time_slot || ''}
                        onChange={handleChangeSelects}
                    >
                        {reportTypes.length > 0
                            ? reportTypes.map(item => {
                                const { description, hint, value, parameters } = item;
                                return (
                                    <MenuItem
                                        onClick={() => setParameters(parameters)}
                                        key={value}
                                        value={value}
                                    >
                                        {description}({hint})
                                    </MenuItem>
                                );
                            })
                            : <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </LayoutFilter.Visible>

            <LayoutFilter.Invisible>
                {(parameters.includes('start_date') && parameters.includes('end_date')) && (
                    <DateRange
                        pickersFormat={dateFormat.DATE_WITH_HOUR_MIN_SECONDS}
                        views={['day', 'hours', 'minutes', 'seconds']}
                        valueStartDate={formValues.start_date}
                        valueEndDate={formValues.end_date}
                        handleDate={handleChangeStartDate}
                        handleEndDate={handleChangeEndDate}
                        renderError={() => renderError(formValues, 'start_date', 'end_date', parameters, reportTypes)?.error}
                        errorMessage={() => renderError(formValues, 'start_date', 'end_date', parameters, reportTypes)?.message}
                    />
                )}
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
}

export default Filters;
