import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadUserActivity } from 'redux/Admin/actions';
import { adminSelectors } from 'redux/Admin';
import titles from 'helpers/constants/titles';
import { fullDateTimeWithTimeZone, getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';
import GetReports from 'components/common/GetReports';
import ReportBlock from 'components/common/Report/ReportBlock';

import Item from './Item';
import Filter from './Filter';
import type { Params } from './types';
import type { UserActivityItem } from 'types/Admin';

const UserActivity = () => {
    const dispatch = useDispatch();

    const list = useSelector(adminSelectors.userActivity);
    const loading = useSelector(adminSelectors.loadingUserActivity);

    const [limit, setLimit] = useState<number>(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState<Params>({
        page: 1,
        data: {
            start_date: fullDateTimeWithTimeZone(getStartOf('day', getDateWithDuration({ weeks: -1 }))),
            end_date: fullDateTimeWithTimeZone(getEndOf()),
        },
    });
    const [selectedFormats, setSelectedFormats] = useState<string[]>([]);

    useEffect(() => {
        dispatch(loadUserActivity(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    const getReport = (formats: string[]) => {
        dispatch(loadUserActivity(params.page, limit, { ...params.data, report: 1, formats }));
    };

    const renderContent = () => (
        <>
            {list?.data?.length > 0 ? (
                <LSContainer
                    headers={[
                        { title: titles.DATE, width: '20%' },
                        { title: titles.USER, width: '20%' },
                        { title: titles.SERVICE, width: '30%' },
                        { title: 'Действие', align: 'right', isActions: true },
                    ]}
                >
                    {list.data.map((item: UserActivityItem, index: number) => (
                        <Item key={`${item.user_id}${index}`} item={item} />
                    ))}
                </LSContainer>
            ) : (
                !loading && <NoData />
            )}
        </>
    );

    return (
        <PageLayout
            header="Действия пользователей"
            filters={<Filter setParams={setParams} />}
            informPanelProps={{
                buttons: (
                    <ReportBlock
                        customComponents={() => (
                            <GetReports
                                onDownload={getReport}
                                selected={selectedFormats}
                                onChange={setSelectedFormats}
                                reportId={25}
                                helperText={undefined}
                            />
                        )} 
                    />
                ),
                total: list?.meta?.total,
            }}
            loading={loading}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: list.meta,
                limit,
                setLimit,
            }}
        />
    );
};

export default UserActivity;
