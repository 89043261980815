import { useState } from 'react';
import BoardUniversalSelect from '../../../../../../common/Autocomplete/Boards/BoardList/BoardUniversalSelect';
import FormButtonsComponent from '../../../../../../common/FormButtons';

export const SelectBoard = ({ boardForPreview, setBoardForPreview, sortedList }) => {
    const [isOpenBoardUniversalSelect, setIsOpenBoardUniversalSelect] = useState(false);

    return (
        <>
            <h4>Для предпросмотра сообщения выберите ДИТ</h4>
            <FormButtonsComponent
                noPadding
                positionLeft
                noMarginLeft
                buttons={[
                    {
                        onClick: () => setIsOpenBoardUniversalSelect(true),
                        name: 'Выбрать ДИТ'
                    }
                ]}
            />
            {boardForPreview
                && <div>
                    <h4>{boardForPreview?.name}</h4>
                    {/* <p>
                        <strong>
                            Серийный номер:
                        </strong>
                        { boardForPreview?.serial_number}
                    </p>
                    <p>
                        <strong>
                            Адрес:
                        </strong>
                        { boardForPreview?.address_text}
                    </p> */}
                </div>
            }
            {isOpenBoardUniversalSelect && (
                <BoardUniversalSelect
                    sortedList={sortedList}
                    selected={boardForPreview}
                    onChange={setBoardForPreview}
                    showList={isOpenBoardUniversalSelect}
                    handleClose={() => setIsOpenBoardUniversalSelect(false)}
                />
            )}
        </>
    );
};