import React, { useState } from 'react';
import Modal from '../../../../common/Modal';
import titles from '../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { createDefectGroup, editDefectGroup } from '../../../../../redux/RoadWorks/actions';
import { useValidation } from '../../../../../helpers/hooks';
import FormInfoWrapper from '../../../../common/FormInfoWrapper';

const AddModal = ({ isNew, open, toggleOpen, data, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const [formData, setFormData] = useState({ ...data });

    const handleChange = (e) => {
        const { name, value } = e.target;
        validation.deleteKey(name);
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        dispatch(isNew
            ? createDefectGroup(formData, reloadList)
            : editDefectGroup(data.id, formData, reloadList)
        );
    };

    return (
        <Modal
            isOpen={open}
            onClose={toggleOpen}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: toggleOpen
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormInfoWrapper error={validation.isKey('name')} helperText={validation.get('name')} >
                    <TextField
                        value={formData.name || ''}
                        name={'name'}
                        onChange={handleChange}
                        className="block"
                        label={'Название группы'}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={validation.isKey('name')}
                    />
                </FormInfoWrapper>
            </div>
        </Modal>
    );
};

export default AddModal;