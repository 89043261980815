import { useEffect, useRef, useState } from 'react';
import { useDispatch, } from 'react-redux';
import UniversalSelect from '../../../UniversalSelect';
import { loadBoards } from '../../../../../redux/Boards/actions';
import titles from '../../../../../helpers/constants/titles';
import _ from 'lodash';
import { UniversalSelectProps } from '../../../UniversalSelect/types';

export interface BoardUnivrsalSelectTypes extends UniversalSelectProps {
    showList?: boolean
    handleClose?: () => void,
    filter?: Object,
}

const BoardUnivrsalSelect = ({
    multiple = false,
    selected,
    sortedList,
    onChange = () => {},
    showList = false,
    handleClose = () => {},
    filter = {}
}: BoardUnivrsalSelectTypes) => {
    const dispatch = useDispatch();
    const prevData = useRef(selected);
    const [selectedItems, setSelected] = useState(selected);

    const getList = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query: name } = params;

        dispatch(loadBoards({
            page, 
            limit,
            is_simple: 1, // фильтр is_simple для упрощённого ответа.
            ...(name && { name }),
            ...filter,
        }));
    };

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleAccept = (data: any) => {
        onChange(data);
        handleClose();
    };

    return (
        <UniversalSelect
            multiple={multiple}
            sortedList={sortedList}
            fetchList={getList}
            storeName={'boards'}
            storeNameProps={'boards'}
            keyProp={'id'}
            withSearch
            isSelected
            selected={selectedItems}
            storeLoadingProps={'loading'}
            searchTitle="поиск по названию"
            renderProps={(el) => <div>
                {el.name}
                <div>
                    <strong>
                            серийный номер:&nbsp;
                    </strong>
                    {el.serial_number}
                </div>
                <strong>
                        адрес:&nbsp;
                </strong>
                {el.address_text}
            </div>}
            onAccept={handleAccept}
            isOpen={showList}
            onClose={handleClose}
            noPadding={true}
            title={titles.SELECT_BOARD}
            small
        />
    );
};

export default BoardUnivrsalSelect;