import React, { useEffect } from 'react';
import * as actions from '../../../../redux/SituationalPlans/actions';
import ConfirmDelete from '../../../common/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../helpers/constants/messages';
import ModalForm from '../../../../../src/components/pages/Dictionaries/SituationalPlans/TransportIncidents/ModalForm';
import { useStoreProp } from '../../../../helpers/hooks';
import { SituationalPlansSelectors } from '../../../../redux/SituationalPlans';
import useStoreFromSelector from '../../../../helpers/hooks/useStoreFromSelector';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();

    const deleteForm = useSelector(SituationalPlansSelectors.deleteForm || false);
    const editForm = useSelector(SituationalPlansSelectors.editForm || false);
    const saved = useSelector(SituationalPlansSelectors.saved);
    const threatLevels = useSelector(SituationalPlansSelectors.threatLevels);

    const statuses = useStoreFromSelector(
        actions.loadTransportIncidentStatuses,
        SituationalPlansSelectors.transportIncidentStatuses
    );

    // const statuses = useStoreProp(actions.loadTransportIncidentStatuses, 'SituationalPlans', 'transportIncidentStatuses');

    useEffect(() => {
        if (saved) {
            dispatch(actions.saveStatus());
        }
    }, [saved]);

    useEffect(() => {
        if (threatLevels?.data?.length === 0) {
            dispatch(actions.loadThreatLevels());
        }
    }, []);

    return (
        <>
            {/* форма редактирования */}
            {editForm
                && (
                    <ModalForm
                        isOpen={!!editForm}
                        onClose={() => {
                            dispatch(actions.setEditForm());
                        }}
                        isNew={!editForm?.id}
                        statuses={statuses}
                        item={editForm}
                    />
                )
            }

            {/*<ModalForm*/}
            {/*    data={editForm}*/}
            {/*    onAccept={(newFields) => {*/}
            {/*        if (!newFields['id']) {*/}
            {/*            dispatch(actions.createTransportIncident(newFields));*/}
            {/*        } else {*/}
            {/*            dispatch(actions.editTransportIncident(newFields.id, newFields));*/}
            {/*        }*/}
            {/*    }}*/}
            {/*    onClose={() => {*/}
            {/*        dispatch(actions.setEditForm());*/}
            {/*    }}*/}
            {/*/>*/}

            {/* удаление */}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!deleteForm}
                onSuccess={() => {
                    dispatch(actions.deleteTransportIncident(deleteForm));
                    dispatch(actions.setDeleteForm());
                }}
                onClose={() => {
                    dispatch(actions.setDeleteForm());
                }}
            />
        </>
    );
};

export default CollectorForms;
