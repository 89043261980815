import React from 'react';

import renderAddress from '../../../../../helpers/renderAddress';
import messages from '../../../../../helpers/constants/messages';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import titles from '../../../../../helpers/constants/titles';
import { fullDateTime } from '../../../../../helpers/date.config';

import TypeValue from './TypeValue';

const numberValue = (value) =>
    typeof value === 'number'
        ? value || '0'
        : 'Информация отсутствует';

const tabsList = [
    {
        key: 'information',
        title: 'Информация',
        data: {
            status_text: {
                title: titles.STATUS
            },
            dtp_type: {
                title: titles.DTP_TYPE,
                value: (value) => <TypeValue id={value} />
            },
            manual_edit: {
                title: 'Ручное редактирование',
                value: (value) => value ? 'Да' : 'Нет'
            },
            dtp_at: {
                title: titles.DATE_TIME_DTP,
                value: (value) => fullDateTime(value)
            },
            address: {
                title: titles.ADDRESS,
                value: (address, dtp) => dtp?.address_text || Object.keys(address || {}).length > 0
                    ? dtp?.address_text || renderAddress(address)
                    : 'Информация отсутствует'
            },
            intersection: {
                title: titles.INTERSECTION_STREET,
            },
            km: {
                title: titles.DTP_KM,
                value: numberValue
            },
            m: {
                title: titles.DTP_M,
                value: numberValue
            },
            description: {
                title: titles.DTP_DESCRIPTION
            },
            schema_img_url: {
                title: titles.SCHEMA_DTP,
                value: (value) =>
                    value && value.length > 0 ? (
                        <img
                            alt="img"
                            style={{
                                width: '200px',
                                maxWidth: '100%',
                                padding: '10px 0'
                            }}
                            src={value}
                        />
                    ) : (
                        'Информация отсутствует'
                    )
            },
            type_load_text: {
                title: titles.DTP_DATA_SOURCE
            },
            ti_id: {
                title: titles.RELATIVE_EVENT,
                value: (value, action) => {
                    if (value) {
                        return <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.show,
                                    variant: 'default',
                                    onClick: action,
                                },
                            ]}
                        />;
                    }
                    return messages.NO_DATA;
                },
                modal: true,
            },
            count_members: {
                title: titles.COUNT_DTP_MEMBERS,
                value: numberValue
            },
            dead: {
                title: titles.COUNT_DTP_DEAD,
                value: numberValue
            },
            wounded: {
                title: titles.COUNT_DTP_WOUNDED,
                value: numberValue
            },
            dead_child: {
                title: titles.COUNT_DTP_DEAD_KIDS,
                value: numberValue
            },
            wounded_child: {
                title: titles.COUNT_DTP_WOUNDED_KIDS,
                value: numberValue
            },
            count_ts: {
                title: titles.COUNT_DTP_TS,
                value: numberValue
            },
            
        },
    },
    {
        key: 'members',
        title: 'Участники ДТП',
    },
    {
        key: 'vehicles',
        title: 'Транспортные средства',
    },
    {
        key: 'situation',
        title: 'Обстановка',
        data: {
            road: {
                title: titles.ROAD
            },
            road_value: {
                title: titles.ROAD_VALUE
            },
            road_category: {
                title: titles.ROAD_CATEGORY
            },
            road_condition: {
                title: titles.ROAD_CONDITION
            },
            street_category: {
                title: titles.STREET_CATEGORY
            },
            weather: {
                title: titles.WEATHER
            },
            lighting: {
                title: titles.LIGHTING
            },
            changes_driving: {
                title: titles.CHANGES_DRIVING
            },
            device_traffic_light_crossroads: {
                title: 'Объекты УДС на месте (светофор на перекрестке)'
            },
            device_traffic_light_crosswalk: {
                title: 'Объекты УДС на месте (светофор на пешеходном переходе)'
            },
        }
    }
];

export default tabsList;