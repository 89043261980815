import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiSituationalPlansMethods = {

    getTransportIncidentTypes: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getTransportIncidentTypes(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    createTransportIncidentType: async (data) => {
        const response = await instance.post(apiUrls.createTransportIncidentType(), data);
        return response?.data;
    },

    editTransportIncidentType: async (id, data) => {
        const response = await instance.put(apiUrls.editTransportIncidentType(id), data);
        return response?.data;
    },

    deleteTransportIncidentType: async (id) => {
        const response = await instance.delete(apiUrls.deleteTransportIncidentType(id));
        return response?.data;
    },

    getEvents: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getEvents(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    createEvent: async (data) => {
        const response = await instance.post(apiUrls.createEvent(), data);
        return response?.data;
    },

    editEvent: async (id, data) => {
        const response = await instance.put(apiUrls.editEvent(id), data);
        return response?.data;
    },

    deleteEvent: async (id) => {
        const response = await instance.delete(apiUrls.deleteEvent(id));
        return response?.data;
    },

    getKeywordList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getKeyWordList(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    editKeyWord: async (id, data) => {
        const response = await instance.put(apiUrls.editKeyWord(id), data);
        return response?.data;
    },

    addKeyWord: async (data) => {
        const response = await instance.post(apiUrls.addKeyWord(), data);
        return response?.data;
    },

    deleteKeyWord: async (id) => {
        const response = await instance.delete(apiUrls.deleteKeyWord(id));
        return response?.data;
    },

    //Получение списка угроз и рисков
    getDangersList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getDangersList(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },
    //Создание сущности угроз и риска
    postDanger: async (data) => {
        const response = await instance.post(apiUrls.postDanger(), data);
        return response?.data;
    },
    //Редактирование сущности угроз и риска
    editDanger: async (id, data) => {
        const response = await instance.put(apiUrls.editDanger(id), data);
        return response?.data;
    },
    //Удаление сущности угроз и риска
    deleteDanger: async (id) => {
        const response = await instance.delete(apiUrls.deleteDanger(id));
        return response?.data;
    },

    getThreatLevels: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getThreatLevels(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    createThreatLevel: async (data) => {
        const response = await instance.post(apiUrls.createThreatLevel(), data);
        return response?.data;
    },

    editThreatLevel: async (id, data) => {
        const response = await instance.put(apiUrls.editThreatLevel(id), data);
        return response?.data;
    },

    deleteThreatLevel: async (id) => {
        const response = await instance.delete(apiUrls.deleteThreatLevel(id));
        return response?.data;
    },

    getTypicalOperations: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getTypicalOperations(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    createTypicalOperation: async (data) => {
        const response = await instance.post(apiUrls.createTypicalOperation(), data);
        return response?.data;
    },

    editTypicalOperation: async (id, data) => {
        const response = await instance.put(apiUrls.editTypicalOperation(id), data);
        return response?.data;
    },

    deleteTypicalOperation: async (id) => {
        const response = await instance.delete(apiUrls.deleteTypicalOperation(id));
        return response?.data;
    },

    getResponseScenarios: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getResponseScenarios(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    getEventObjects: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getEventObjects(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    createResponseScenario: async (data) => {
        const response = await instance.post(apiUrls.createResponseScenario(), data);
        return response?.data;
    },

    editResponseScenario: async (id, data) => {
        const response = await instance.put(apiUrls.editResponseScenario(id), data);
        return response?.data;
    },

    deleteResponseScenario: async (id) => {
        const response = await instance.delete(apiUrls.deleteResponseScenario(id));
        return response?.data;
    },

    createEventObjects: async (data) => {
        const response = await instance.post(apiUrls.createEventObjects(), data);
        return response?.data;
    },

    editEventObjects: async (id, data) => {
        const response = await instance.put(apiUrls.editEventObjects(id), data);
        return response?.data;
    },

    deleteEventObjects: async (id) => {
        const response = await instance.delete(apiUrls.deleteEventObjects(id));
        return response?.data;
    },

    getEventObjectsTypes: async () => {
        const response = await instance.get(apiUrls.getEventObjectsTypes());
        return response?.data;
    },

    getCurrentEvent: async (id) => {
        const response = await instance.get(apiUrls.getCurrentEvent(id));
        return response?.data;
    },

    getCurrentKeyword: async (id) => {
        const response = await instance.get(apiUrls.getCurrentKeyword(id));
        return response?.data;
    },

    getTransportIncidents: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getTransportIncidents(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    createTransportIncident: async (data) => {
        const response = await instance.post(apiUrls.createTransportIncident(), data);
        return response?.data;
    },

    editTransportIncident: async (id, data) => {
        const response = await instance.put(apiUrls.editTransportIncident(id), data);
        return response?.data;
    },

    deleteTransportIncident: async (id) => {
        const response = await instance.delete(apiUrls.deleteTransportIncident(id));
        return response?.data;
    },

    getTransportIncidentStatuses: async () => {
        const response = await instance.get(apiUrls.getTransportIncidentStatuses());
        return response?.data;
    },

    getCurrentCategory: async (id) => {
        const response = await instance.get(apiUrls.getCurrentCategory(id));
        return response?.data;
    },

    getCurrentResponseScenario: async (id) => {
        const response = await instance.get(apiUrls.getCurrentResponseScenario(id));
        return response?.data;
    },

    getCurrentTransportIncidentType: async (id) => {
        const response = await instance.get(apiUrls.getCurrentTransportIncidentType(id));
        return response?.data;
    },

    getCurrentThreatLevel: async (id) => {
        const response = await instance.get(apiUrls.getCurrentThreatLevel(id));
        return response?.data;
    },

    getCurrentEventObject: async (id) => {
        const response = await instance.get(apiUrls.getCurrentEventObject(id));
        return response?.data;
    },

    getArrayOfKeyword: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getArrayOfKeyword(),{
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    // получить полигон
    getForPolygon: async (params) => {
        const response = await instance.post(
            apiUrls.getPolygon(),
            params,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
        return response?.data?.data;
    },

    // получить список для сайдбара
    getSidebarList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getSidebarList(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    getReportTransportIncidents: async (data) => {
        const response = await instance.get(apiUrls.getReportTransportIncidents(), { params: { ...data } });
        return response?.data;
    },

    getReportTransportIncidentsResolutionTime: async (config) => {
        const response = await instance.get(apiUrls.getReportTransportIncidentsResolutionTime(), config );
        return response?.data;
    },

    getForPolygonEvents: async (params) => {
        const response = await instance.post(
            apiUrls.getPolygonEvents(),
            params,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
        return response?.data?.data;
    },

    getIncidentOperations: async (id) => {
        const response = await instance.get(apiUrls.getIncidentOperations(id));
        return response?.data;
    },

    editIncidentOperations: async (id, data) => {
        const response = await instance.put(apiUrls.getIncidentOperations(id), data);
        return response?.data;
    },

    getTransportIncidentHistory: async (page, limit, id) => {
        const response = await instance.get(apiUrls.getTransportIncidentHistory(id), {
            params: { page, limit }
        });
        return response?.data;
    },

    getTransportIncidentTypeCreation: async () => {
        const response = await instance.get(apiUrls.getTransportIncidentTypeCreation());
        return response?.data;
    },

    getTransportIncident: async (id) => {
        const response = await instance.get(apiUrls.getTransportIncident(id));
        return response?.data;
    },

    loadInfopanel: async (params) => {
        const response = await instance.get(apiUrls.loadInfopanel(), { params });
        return response?.data;
    },

    loadDistictsByMonth: async (params) => {
        const response = await instance.get(apiUrls.loadDistictsByMonth(), { params });
        return response?.data;
    },

    getTransportIncidentModal: async (id) => {
        const response = await instance.get(apiUrls.getTransportIncidentModal(id));
        return response?.data;
    },

    editSettingsList: async (data) => {
        const response = await instance.put(apiUrls.editSettingsList(), data);
        return response?.data;
    },

    getSettingsGroup: async () => {
        const response = await instance.get(apiUrls.getSettingsGroup());
        return response?.data;
    },

    editSettingsGroup: async (id, data) => {
        const response = await instance.put(apiUrls.editSettingsGroup(id), data);
        return response?.data;
    },

    loadShowcase: async () => {
        const response = await instance.get(apiUrls.loadShowcase());
        return response?.data;
    },

    loadImagesLegendsSaga: async () => {
        const response = await instance.get(apiUrls.loadImagesLegendsSaga());
        return response?.data;
    },

    loadReportByEventPlans: async (params) => {
        const response = await instance.post(apiUrls.loadReportByEventPlans(), params);
        return response?.data;
    },
};

export default ApiSituationalPlansMethods;
