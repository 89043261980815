import React, { useEffect } from 'react';
import EditorForm from './ModalForm';
// import Modal from '../../../common/Modal';
import * as actions from '../../../../redux/RoadWorks/actions';
import { roadworksSelectors } from '../../../../redux/RoadWorks';
import ConfirmDelete from '../../../common/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../helpers/constants/messages';
// import titles from '../../../../helpers/constants/titles';
// import { getListRW } from '../../../../redux/RoadWorks/actions';
// import getFilters from '../../helpers/getFilters';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();

    const defectsDeleteForm = useSelector(roadworksSelectors.defectsDeleteForm || false);
    const defectsEditForm = useSelector(roadworksSelectors.defectsEditForm || false);
    const defectsSaved = useSelector(roadworksSelectors.defectsSaved);

    useEffect(() => {
        if (defectsSaved) {
            dispatch(actions.savedDefects(false));
        }
    }, [defectsSaved]);

    // закрыть форму
    const handleCloseEditForm = () => {
        dispatch(actions.setEditFormDefects());
    };
    const isNew = !defectsEditForm.id;

    return (
        <>
            {/* форма редактирования */}
            {Boolean(defectsEditForm) && (
                <EditorForm
                    open={Boolean(defectsEditForm)}
                    isNew={isNew}
                    inModal
                    data={defectsEditForm}
                    onClose={handleCloseEditForm}
                />
            )}

            {/* удаление */}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!defectsDeleteForm}
                onSuccess={() => {
                    dispatch(actions.deleteDefect(defectsDeleteForm, (status) => status && dispatch(actions.setDeleteFormDefects())));
                    dispatch(actions.setDeleteFormDefects());
                }}
                onClose={() => {
                    dispatch(actions.setDeleteFormDefects());
                }}
            />
        </>
    );
};

export default CollectorForms;
