import React, { useState } from 'react';
import { ListItem, ListItemText, Tooltip, IconButton } from '@mui/material';
import { fullDateTimeWithoutSeconds } from '../../../../../helpers/date.config';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Modal from '../../../../common/Modal';
import MapDragMarker from '../../../../common/Location/MapDragMarker';
import buttons from '../../../../../helpers/constants/buttons';

const DowntimeInfoModalItem = ({ el, item }) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            {isOpen 
            && <Modal
                isOpen={isOpen}
                onClose={()=> setOpen(false)}
                title={`Детекция простоя ТС - ГРЗ ${item?.number} на ${fullDateTimeWithoutSeconds(el.created_at)}`}
                buttons={
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: ()=> setOpen(false)
                            }
                        ]}
                    />
                }
            >
                <MapDragMarker
                    {...el}
                    readOnly
                />
                {/*<CoordinatesAndDislocation*/}
                {/*    required*/}
                {/*    showCoordinates={false}*/}
                {/*    showAddress={false}*/}
                {/*    lat={el?.lat || ''}*/}
                {/*    lon={el?.lon || ''}*/}
                {/*    readOnly*/}
                {/*/>*/}
            </Modal>}
            <ListItem divider>
                <ListItemText>
                    <p>
                        <strong>Дата и время:</strong>&nbsp;
                        {el?.created_at && fullDateTimeWithoutSeconds(el.created_at)}
                    </p>
                    <p>
                        <strong>Широта:</strong>&nbsp;{el?.lat}{' '}
                        <strong>Долгота:</strong>&nbsp;{el?.lon}
                    </p>
                    <p>
                        <strong>Скорость:</strong>&nbsp;
                        {el?.speed}
                    </p>
                </ListItemText>
                <Tooltip onClick={() => setOpen(true)} title={buttons.SHOW_ON_MAP}>
                    <IconButton size="small">
                        <i className="fas fa-map-marked"/>
                    </IconButton>
                </Tooltip>
            </ListItem>
        </>
    );
};

export default DowntimeInfoModalItem;