import React, { useMemo, useState } from 'react';

import titles from 'helpers/constants/titles';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import useValidation from 'helpers/hooks/useValidation';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import { PageReportFields, useReportTemplatesFrontendLinks } from 'components/common/PageReport';

const Filter = () => {
    const validation = useValidation();

    const templateProvider = useReportTemplatesFrontendLinks();
    const reportFilter = templateProvider.filter;

    const initialState = {
        date: null,
    };

    const [filters, setFilters] = useState(initialState);

    const handleChange = (value, key) => {
        setFilters({
            ...filters,
            [key]: value
        });
        validation.deleteKey(key);
    };

    const download = () => {
        const filter = {
            date: dateWithDashYYYYMMDD(filters.date),
        };

        const {
            url,
            formats,
        } = reportFilter.getToApi();

        templateProvider.loadReport(
            url,
            formats,
            filter
        );
    };

    const resetFilter = () => {
        setFilters(initialState);
        reportFilter.setFormats([]);
        validation.clear();
    };

    const updateFilter = (filter) => {
        setFilters({
            ...initialState,
            ...filter
        });
    };

    const disabled = useMemo(() => {
        return !filters?.date
            || filters?.formats?.length === 0
            || reportFilter.isDisabled;
    }, [filters, reportFilter]);

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            disabled={disabled}
            filter={filters}
            filterException={['date']} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
            buttonType="getReport"
            onSearch={download}
            isFilterVisible={true}
        >
            <LayoutFilter.Visible>
                <SingleKeyboardDateTimePicker
                    value={filters.date}
                    onChange={(value)=> handleChange(value, 'date')}
                    label={titles.DATE}
                    dateOnly={true}
                    error={!filters.date || validation.isKey('date')}
                    helperText={validation.get('date')}
                />

                <PageReportFields
                    reportType={reportFilter.type}
                    reportFormats={reportFilter.formats}
                    onReportType={reportFilter.setType}
                    onReportFormat={reportFilter.setFormats}
                    isOneTypeAuto
                />

            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
