import { useEffect, useState } from 'react';

import { dateWithDashYYYYMMDD, getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import useDefectHeatMap from 'helpers/hooks/RoadWorks/useDefectHeatMap';
import DateRange from 'components/common/Dates/DateRange';
import DefectGroups from 'components/common/Autocomplete/RoadWorks/DefectGroups';
import DefectTypes from 'components/common/Autocomplete/RoadWorks/DefectTypes';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const Filter = ({ onFilter }) => {
    const dataProvider = useDefectHeatMap();

    const [disabledButton, setDisabledButton] = useState(false);

    const initialState = {
        startDate: getStartOf('day', getDateWithDuration({ month: -1 })),
        endDate: getEndOf('day'),
        group: {},
        types: []
    };

    const [data, setData] = useState({
        ...initialState,
        ...dataProvider.filter,
    });

    const handleChangeData = (name, value) => {
        if (name === 'group') {
            return setData({ 
                ...data, 
                [name]: value,
                types: []
            });
        }
        setData({ ...data, [name]: value });
    };

    const filter = (filters) => {
        return removeEmptyFields({
            start_date: filters.startDate ? dateWithDashYYYYMMDD(filters.startDate) : null,
            end_date: filters.endDate ? dateWithDashYYYYMMDD(filters.endDate) : null,
            group_id: filters?.group?.id,
            type_ids: filters?.types?.map(({ id }) => id)
        });
    };

    const search = (filters = data) => {
        dataProvider.setFilter(filter(filters));
        onFilter(filter(filters));
    };

    useEffect(() => {
        search();
    }, []);

    const resetSearch = (needRefresh) => {
        setData(initialState);
        const { start_date, end_date } = initialState;
        dataProvider.setFilter({ start_date, end_date });

        if (needRefresh) {
            dataProvider.load({ start_date, end_date });
        }
    };

    const updateFilter = (filters) => {

        setData({
            ...initialState,
            ...filters,
        });

        if (Object.keys(filters).length > 0) {
            search({
                ...initialState,
                ...filters
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetSearch} 
            onSearch={search}
            filter={data}
            filterException={['startDate', 'endDate']}
            disabled={Object.values(filter(data)).length === 0 || disabledButton}
            setUserFilter={updateFilter}
            isFilterVisible
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(value) => handleChangeData('startDate', value)}
                    handleEndDate={(value) => handleChangeData('endDate', value)}
                    valueStartDate={data.startDate}
                    valueEndDate={data.endDate}
                    dateOnly={true}
                    isDisabledHandler={setDisabledButton}
                />
                <DefectGroups
                    selected={data.group || {}}
                    onChange={(value) => handleChangeData('group', value)}
                />
                <FormInfoWrapper
                    helperText={data.group?.id ? '' : 'Сначала выберите Группу недостатков'}
                >
                    <DefectTypes
                        selected={data.types || []}
                        multiple
                        onChange={(value) => handleChangeData('types', value)}
                        filter={{ group_ids: [data?.group?.id] }}
                        disabled={!data.group?.id}
                    />
                </FormInfoWrapper>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
