import AllReports from 'components/pages/Reports/Efficiency/AllReports';
import Dashboard from 'components/pages/Reports/Efficiency/Dashboard';
import VideoAnalytics from 'components/pages/Reports/VAReports/VideoAnalytics';
import EntryExitStatistics from 'components/pages/Reports/VAReports/EntryExitStatistics/';
import DTPReport from 'components/pages/Reports/Efficiency/DTPReport';
import PUIDReport from 'components/pages/Reports/Efficiency/PUIDReport';
import GraphReports from 'components/pages/Reports/Efficiency/GraphReports';
import HeatGraphEfficiency from 'components/pages/Showcase/HeatGraphEfficiency';
import ForecastReports from 'components/pages/Reports/Efficiency/ForecastReports';
import ItsDevelopmentLevels from 'components/pages/Reports/Efficiency/ItsDevelopmentLevels';
import InfrastructureStatistics from 'components/pages/Reports/Efficiency/InfrastructureStatistics';

const routes = [
    {
        path: '/reports/efficiency/all-reports',
        component: AllReports,
        exact: true
    },
    {
        path: '/reports/efficiency/dashboard',
        component: Dashboard,
        exact: true
    },
    {
        path: '/reports/efficiency/videoanalytics',
        component: VideoAnalytics,
        exact: true
    },
    {
        path: '/reports/efficiency/entry-exit-statistics',
        component: EntryExitStatistics,
        exact: true
    },
    {
        path: '/reports/efficiency/dtp',
        component: DTPReport,
        exact: true
    },
    {
        path: '/reports/efficiency/puid',
        component: PUIDReport,
        exact: true
    },
    {
        path: '/reports/efficiency/reports',
        component: GraphReports,
        exact: true
    },
    {
        path: '/reports/efficiency/target-indicators',
        component: HeatGraphEfficiency,
        exact: true
    },
    {
        path: '/reports/efficiency/forecast',
        component: ForecastReports,
        exact: true
    },
    {
        path: '/reports/efficiency/its-development-levels',
        component: ItsDevelopmentLevels,
        exact: true
    },
    {
        path: '/reports/efficiency/infrastructure-statistics',
        component: InfrastructureStatistics,
        exact: true
    },
];

export default routes;
