import { useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import { deleteDefectTypes } from 'redux/RoadWorks/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import {
    LSContentItem,
    LSContentColumn,
    ActionsButtons,
} from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import DTModal from './DTModal';

const DTItem = ({ elem, reloadList, permissions }) => {
    const dispatch = useDispatch();
    const [item, setItem] = useState('');
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleConfirmDelete = () => {
        dispatch(deleteDefectTypes(item.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    const handleClickEdit = (elem) => {
        setItem(elem);
        setOpenEditModal(true);
    };

    const handleClickDelete = (elem) => {
        setItem(elem);
        setOpenDeleteModal(true);
    };

    return (
        <>
            {openEditModal
                && (<DTModal
                    isNew={false}
                    onClose={() => setOpenEditModal(false)}
                    isOpen={openEditModal}
                    elem={item}
                    reloadList={reloadList}
                />)
            }
            {openDeleteModal
                && (
                    <ConfirmDelete
                        message={messages.CONFIRM_DELETE}
                        open={openDeleteModal}
                        onSuccess={handleConfirmDelete}
                        onClose={() => setOpenDeleteModal(false)}
                    />
                )
            }
            <LSContentItem>
                <LSContentColumn>
                    {elem.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {elem.group?.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: ()=> handleClickEdit(elem),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: ()=> handleClickDelete(elem),
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
        </>
    );
};

export default DTItem;
