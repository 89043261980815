import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';

import {
    changeStatus,
    loadComplexStatuses
} from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import titles from 'helpers/constants/titles';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import { useStoreFromSelector, useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import formatDate from 'helpers/constants/dateFormat';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SelectUsers from 'components/common/Autocomplete/Users';
import SelectComplexEntities from 'components/common/Autocomplete/DorisControl/Entities';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';


const StatusForm = ({
    isOpen,
    onClose,
    currentStatus,
    complex_id,
    currentComplex,
    onEdited
}) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const entities = useSelector(dorisControlSelectors.entities);

    const complexStatuses = useStoreFromSelector(loadComplexStatuses, dorisControlSelectors.doris_complex_statuses);

    // список всех entities у комплекса
    const dataList = Object.entries(entities).reduce((res, [entity_id, el]) => {
        return res.concat(el.map(elem => ({ ...elem, entity_id })));
    }, []);

    // const types = useStoreProp(loadComplexEntityTypes, 'dorisControl', 'entities_types');

    const [data, setData] = useState({
        _comment: '',
        status: currentStatus,
        complex_entities: [],
        plan: currentComplex?.plan || '',
        date: currentComplex?.date || null,
        organizations: currentComplex?.organizations || [],
        users: currentComplex?.users || []
    });

    const onAcceptModal = () => {
        const result = removeEmptyFields({
            ...data,
            date: fullDateTimeWithTimeZone(data.date) || '',
            complex_entities: data.complex_entities.map((el) => ({ id: el.id }))
        });

        dispatch(changeStatus(complex_id, result, () => {
            onEdited();
            onClose();
        }));
    };

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
        validation.deleteKey(name);
    };

    // const autoComChange = (e, value, name) => {
    //     if (e?.key !== 'Enter') setData({ ...data, [name]: value });
    //     validation.deleteKey(name);
    // };

    // const renderEntityOption = (item) => {
    //     return  `${types?.[item.entity_id]?.name}${item?.serial ? `, Серийный номер: ${item?.serial}` : ''}${item?.name ? `, Название: ${item?.name}` : ''}`;
    // };

    const currentStatusOb = useMemo(() => complexStatuses.find(i => i.id === data.status) || {}, [complexStatuses, data.status]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: data?._comment?.length < 6
                    }
                ]}
            />}

        >
            <form className="modal__form">
                <FormInfoWrapper
                    helperText={validation.get('status')}
                    error={validation.isKey('status')}
                >
                    <Autocomplete
                        className="block"
                        noOptionsText={titles.NO_RESULTS}
                        openText={'Открыть'}
                        closeText={'Закрыть'}
                        value={currentStatusOb}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={complexStatuses}
                        filterSelectedOptions={true}
                        getOptionLabel={(option) => option.name}
                        onChange={(_, e) => onChange('status', e?.id)}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label={titles.SELECT_STATUS}
                            />
                        )}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    helperText={validation.get('_comment') || 'Введите не менее 6 символов'}
                    error={validation.isKey('_comment')}
                >
                    <TextField
                        className="block"
                        required
                        label={'Укажите причину изменения статуса'}
                        variant="outlined"
                        size="small"
                        value={data._comment}
                        name="_comment"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        type="text"
                        error={validation.isKey('_comment')}
                    />
                </FormInfoWrapper>
                {Number(data.status) === 4 && (
                    <>
                        <FormInfoWrapper
                            helperText={validation.get('complex_entities')}
                            error={validation.isKey('complex_entities')}
                        >
                            <div className="block">
                                <SelectComplexEntities
                                    multiple
                                    selected={data.complex_entities}
                                    sortedList={dataList}
                                    onChange={(val) => onChange('complex_entities', val)}
                                    error={validation.isKey('complex_entities')}
                                />
                            </div>
                        </FormInfoWrapper>

                        {/* <Autocomplete
                            className="block"
                            noOptionsText={titles.NO_RESULTS}
                            multiple
                            value={data.complex_entities}
                            options={dataList}
                            filterSelectedOptions={true}
                            getOptionLabel={(option) => renderEntityOption(option)}
                            size="small"
                            onChange={(e, value) =>
                                autoComChange(e, value, 'complex_entities')
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Неисправное оборудование"
                                    size="small"
                                    variant="outlined"
                                    error={validation.isKey('complex_entities')}
                                />
                            )}
                        />
                        {validation.isKey('complex_entities') && (
                            <FormHelperText error>{validation.get('complex_entities')}</FormHelperText>
                        )} */}
                        <FormInfoWrapper
                            helperText={validation.get('plan')}
                            error={validation.isKey('plan')}
                        >
                            <TextField
                                className="block"
                                label="План мероприятий"
                                variant="outlined"
                                size="small"
                                value={data.plan}
                                name="plan"
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                type="text"
                                error={validation.isKey('plan')}
                            />
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            helperText={validation.get('date')}
                            error={validation.isKey('date')}
                        >
                            <div className="block">
                                <SingleKeyboardDateTimePicker
                                    label="Срок исполнения"
                                    onChange={(e) => setData({ ...data, date: e })}
                                    value={data.date}
                                    pickerFormat={formatDate.DT_EXCEPT_SECONDS}
                                    err
                                />
                            </div>
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            helperText={validation.get('users')}
                            error={validation.isKey('users')}
                        >
                            <div className="block">
                                <SelectUsers
                                    multiple
                                    selected={data.users}
                                    onChange={(val) => onChange('users', val)}
                                    error={validation.isKey('users')}
                                    label="Выберите ответственного"
                                />
                            </div>
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            helperText={validation.get('organizations')}
                            error={validation.isKey('organizations')}
                        >
                            <div className="block">
                                <SelectCompany
                                    multiple
                                    selected={data.organizations}
                                    onChange={(value) => setData({
                                        ...data,
                                        organizations: value
                                    })}
                                    error={validation.isKey('organizations')}
                                    filter={{ withDeleted: 1 }}
                                />
                            </div>
                        </FormInfoWrapper>

                    </>
                )}
            </form>
        </Modal>
    );
};

export default StatusForm;
