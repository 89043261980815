import Filter from './Filter';
import Loading from '../../../../../../common/Loading';
import Graphic from './Graphic';
import GraphicListBar from './GraphicListBar';
import React, { useEffect, useMemo, useState } from 'react';
import { groupBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../../../redux/Efficiency/actions';
import { efficiencySelectors } from '../../../../../../../redux/Efficiency';
import { dateWithDashYYYYMMDD } from '../../../../../../../helpers/date.config';

const DtpGraph = () => {
    const dispatch = useDispatch();
    const efficiencyDtp = useSelector(efficiencySelectors.efficiencyDtp);
    const loadingEfficiencyDtp = useSelector(efficiencySelectors.loadingEfficiencyDtp);
    const filterEfficiencyDtp = useSelector(efficiencySelectors.filterEfficiencyDtp);
    const loadingGraphPeriodList = useSelector(efficiencySelectors.loadingGraphPeriodList);
    const graphPeriodList = useSelector(efficiencySelectors.graphPeriodList);

    const gruppedData = useMemo(() => groupBy(graphPeriodList, 'title') || {}, [graphPeriodList]);

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const filter = filterEfficiencyDtp || {};
        delete filter?.goToDashboard;

        if (Object.keys(filter).length > 0) {
            dispatch(actions.loadEfficiencyDtp(filter));
            dispatch(actions.loadGraphPeriodList({
                dates: filter?.dates?.map(({ start_date, end_date }) => ({
                    start_date: dateWithDashYYYYMMDD(start_date),
                    end_date: dateWithDashYYYYMMDD(end_date),
                }))
            }));
            setIsLoaded(true);
        }
        return () => {
            dispatch(actions.loadedGraphList([]));
        };
    }, [filterEfficiencyDtp, dispatch]);

    useEffect(() => {
        return () => {
            // dispatch(actions.clearFilterEfficiencyDtp());
            // dispatch(actions.clearEfficiencyDtp());
            // dispatch(setShowReturnTo());
            setIsLoaded(false);
        };
    }, []);

    return (
        <>
            <Filter />
            {loadingEfficiencyDtp && <Loading circular={true} />}
            <div className="block">
                {efficiencyDtp?.length > 0
                    ? <Graphic data={efficiencyDtp} />
                    : (!loadingEfficiencyDtp && isLoaded && <div>К сожалению, данные по вашему запросу отсутствуют. Попробуйте поменять параметры вывода с помощь фильтра.</div>)
                }
            </div>
            <div className="block">
                {loadingGraphPeriodList && <Loading circular={true}/>}
                {Object.keys(gruppedData).length > 0
                    ? Object.keys(gruppedData).map((key) => (
                        <GraphicListBar
                            key={key}
                            title={key}
                            list={gruppedData[key]}
                        />
                    ))
                    : (!loadingGraphPeriodList && <div>К сожалению, данные по вашему запросу отсутствуют. Попробуйте поменять параметры вывода с помощь фильтра.</div>)
                }
            </div>
        </>
    );
};

export default DtpGraph;