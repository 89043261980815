import {
    ListItem,
    ListItemText,
} from '@mui/material';

import ItemContent from './EventItemContent';

import type { EventContent } from '../types';

interface EventItemProps {
    item: EventContent;
    index: number;
}

const EventItem = ({ item, index }: EventItemProps) => {

    return (
        <>
            <ListItem
                divider
                dense
            >
                <ListItemText disableTypography>
                    <ItemContent
                        item={item}
                        index={index}
                    />
                </ListItemText>
            </ListItem>
        </>
    );
};

export default EventItem;
