import React, { useContext, useEffect, useState } from 'react';
import Filter from './Filter';
import { useDispatch, useSelector } from 'react-redux';
import CustomPagination from '../../../../common/CustomPagination';
import { loadTrackingList, loadTrackingMoreInfo, loadTrackingStatuses } from '../../../../../redux/WantedCars/actions';
import messages from '../../../../../helpers/constants/messages';
import makeStyles from '@mui/styles/makeStyles';
import ListAccordion from './ListAccordion';
import List from './List';
import { useStoreProp } from '../../../../../helpers/hooks';
import TTModal from './TTModal';
import cn from 'classnames';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Loading from '../../../../common/Loading';
import Context from '../../../../../helpers/context';

const useStyles = makeStyles({
    noData: {
        margin: '0 40px'
    }
});

const TransportTracking = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const { tracking, loadingTracking, moreTracking, loadingMoreTracking } = useSelector(({ carsWanted }) => carsWanted);

    const status = useStoreProp(loadTrackingStatuses, 'carsWanted', 'trackingStatuses');

    const [valueId, setValueId] = useState(null);
    const [openModalWindow, setOpenModalWindow] = useState(false);
    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    
    const [trackingLimit, setTrackingLimit] = useState(storageLimit);
    const [moreTrackingLimit, setMoreTrackingLimit] = useState(storageLimit);

    useEffect(() => {
        if (tracking?.data.length === 0) {
            dispatch(loadTrackingList(1, trackingLimit));
        }
    },[dispatch, tracking?.data.length]);

    return (
        <>
            <h1>Анализ слежки за ТС</h1>
            <Filter status={status} />
            <div className={cn('filter__wrap__btn')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenModalWindow(true),
                            disabled: !permissions?.is_create,
                        }
                    ]}
                />
            </div>
            {openModalWindow && (
                <TTModal
                    isNew={true}
                    open={openModalWindow}
                    closeForm={() => setOpenModalWindow(false)}
                />
            )}
            {loadingTracking && <Loading linear={true}/>}
            {tracking?.data.length > 0
                ? (<>
                    {tracking?.data.map((el) => (
                        <div key={el.id}>
                            <List
                                el={el}
                                valueId={valueId}
                                setValueId={setValueId}
                                statusList={status}
                                moreTrackingLimit={moreTrackingLimit}
                            />
                            {el.id === valueId && (<>
                                {loadingMoreTracking && <Loading linear={true}/>}
                                {moreTracking?.data.length > 0
                                    ? (<>
                                        <ListAccordion
                                            list={moreTracking?.data}
                                            id_grz={el.id}
                                        />
                                        <CustomPagination
                                            loadList={(page, limit) => dispatch(loadTrackingMoreInfo(page, limit, valueId))}
                                            list={moreTracking.meta}
                                            limit={moreTrackingLimit}
                                            setLimit={setMoreTrackingLimit}
                                        />
                                    </>)
                                    : (!loadingMoreTracking && <div className={classes.noData}>{messages.DATA_IS_NOT_FOUND}</div>)
                                }
                            </>)}
                        </div>
                    ))}
                    <CustomPagination
                        loadList={(page, limit) => dispatch(loadTrackingList(page, limit))}
                        list={tracking.meta}
                        limit={trackingLimit}
                        setLimit={setTrackingLimit}
                    />
                </>)
                : (!loadingTracking && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>
    );
};

export default TransportTracking;

