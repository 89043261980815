import { config } from '../../config';

const apiEfficiencyUrl = () => config.get('urls').apiEfficiencyUrl;


const apiUrls = {

    getSettings: () => `${apiEfficiencyUrl()}/settings`,

    editSettings: (id) => `${apiEfficiencyUrl()}/settings/${id}`,

    editSettingsList: () => `${apiEfficiencyUrl()}/settings/list`,

    getGraphList: () => `${apiEfficiencyUrl()}/efficiency/main`,

    // getOneGraph: () => `${apiEfficiencyUrl()}/settings/list`,

    getDashboard: () => `${apiEfficiencyUrl()}/efficiency/summary`,

    getEfficiencyDtp: () => `${apiEfficiencyUrl()}/reports/efficiency/dtp`,

    getFullGraphList: () => `${apiEfficiencyUrl()}/reports/efficiency/dtp/by-day`,

    getFullGraphPeriodList: () => `${apiEfficiencyUrl()}/reports/efficiency/dtp/by-periods`,

    getEfficiencyTraffic: () => `${apiEfficiencyUrl()}/v2/efficiency/traffic/report`,

    getUserAvailableDashboardList: () => `${apiEfficiencyUrl()}/user-dashboard/available`,

    getUserDashboard: () => `${apiEfficiencyUrl()}/user-dashboard`,

    editUserDashboard: () => `${apiEfficiencyUrl()}/user-dashboard`,

    getTrafficForecast: () => `${apiEfficiencyUrl()}/v2/efficiency/traffic/forecast`,

    getCriticalSettings: () => `${apiEfficiencyUrl()}/critical-setting`,

    editCriticalSettings: () => `${apiEfficiencyUrl()}/critical-setting`,

    deleteCriticalSettings: (id) => `${apiEfficiencyUrl()}/critical-setting/${id}`,

    createCriticalSetting: () => `${apiEfficiencyUrl()}/critical-setting`,

    getCriticalSettingsKeys: () => `${apiEfficiencyUrl()}/critical-setting/keys`,

    reportsEfficiencyDtpReport: (id) => `${apiEfficiencyUrl()}/reports/efficiency/dtp/report`,

    loadShowcase: () => `${apiEfficiencyUrl()}/showcase`,

    getSettingsGroup: () => `${apiEfficiencyUrl()}/settings_group`,

    editSettingsGroup: (id) => `${apiEfficiencyUrl()}/settings_group/${id}/settings/update`,

    loadItsDevelopmentLevels: () => `${apiEfficiencyUrl()}/its_development_levels`,

    editItsDevelopmentLevels: (id) => `${apiEfficiencyUrl()}/its_development_levels/${id}`,

    loadInfrastructureStatistics: () => `${apiEfficiencyUrl()}/infrastructure_statistics`,

    editInfrastructureStatistics: (id) => `${apiEfficiencyUrl()}/infrastructure_statistics/${id}`,

    getDashboards: () => `${apiEfficiencyUrl()}/dashboards`,

    getDashboardsItem: () => `${apiEfficiencyUrl()}/dashboard/item`,

    getDashboardItemUser: () => `${apiEfficiencyUrl()}/dashboard/item/user`,

    createDashboardItemUser: () => `${apiEfficiencyUrl()}/dashboard/item/user`,

    editDashboardItemUser: (id) => `${apiEfficiencyUrl()}/dashboard/item/user/${id}`,

    deleteDashboardItemUser: (id) => `${apiEfficiencyUrl()}/dashboard/item/user/${id}`,

    editDashboardItemUserMultiply: () => `${apiEfficiencyUrl()}/dashboard/item/user/multiple`,
};

export default apiUrls;
