import React from 'react';
import Settings from '../../../../common/Settings';
import {
    editSettingsGroup,
    editSettingsList,
    loadSettingsGroup,
} from '../../../../../redux/TrafficLights/actions';

const TrafficLightsSettings = () => {
    return (
        <Settings
            title="Настройки – Светофоры"
            storeProp="trafficLights"
            listName="settingsGroup"
            loadingName="loadingSettingsGroup"
            loadSettingsGroupAction={loadSettingsGroup}
            editSettingsListAction={editSettingsList}
            editSettingsGroupAction={editSettingsGroup}
        />
    );
};

export default TrafficLightsSettings;
