import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    uploadVehicleFile, 
    setUploadVehicleFile, 
    setUploadFileError 
} from '../../../../../redux/Upload/actions';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Modal from '../../../../common/Modal';

const UploadModal = ({ isOpen, onClose, data, validationData }) => {
    const dispatch = useDispatch();
    const { uploadedVehicleFile } = useSelector(({ upload }) => upload);

    const handleCloseModal = () => {
        dispatch(setUploadFileError(null));
        dispatch(setUploadVehicleFile(null));
        onClose();
    };

    const handleAccept = () => {        
        dispatch(setUploadFileError(null));
        dispatch(uploadVehicleFile(uploadedVehicleFile.file, 1));
        onClose();
    };

    return (
        <Modal
            title={data
                ? 'Указанные записи уже есть в базе и будут перезаписаны:'
                : 'В файле ошибки заполнения:'
            }
            onClose={handleCloseModal}
            isOpen={isOpen}
            maxWidth="md"
            buttons={<FormButtons
                buttons={data 
                    ?[
                        {
                            ...buttonsTypes.save,
                            disabled: !data,
                            onClick: handleAccept
                        },
                        {
                            ...buttonsTypes.cancel,
                            onClick: handleCloseModal
                        }
                    ]                                        
                    :[
                        {
                            ...buttonsTypes.ready,
                            onClick: handleCloseModal
                        }
                    ]
                }
            />}
        >
            <div className="modal__form">
                {data && data?.map((el, i ) => 
                    <span key={i}>
                        {el}
                    </span> )
                }
                {validationData && Object.keys(validationData)?.map((key) => 
                    <span key={key}>
                        {`Ячейка ${key}: ${validationData[key]}`}
                    </span> )
                }
            </div>
        </Modal>
    );
};

export default UploadModal;
