import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    clearCameraReport,
    loadCameraReport,
} from 'redux/VideoAnalytics/actions';
import { videoAnalyticsSelectors } from 'redux/VideoAnalytics';
import messages from 'helpers/constants/messages/common';
import Loading from 'components/common/Loading';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import PageLayout from 'components/layout/PageLayout';
import GetReports from 'components/common/GetReports';
import ReportBlock from 'components/common/Report/ReportBlock';

import Filters from './Filters';
import styles from './vaGraph.module.scss';
import Graph from './Graph';

function VAGraph() {
    const dispatch = useDispatch();
    // const reportData = useSelector(videoAnalyticsSelectors.reportData);
    const [data, setData] = useState({
        reportData: {},
        graphParams: {}
    });
    const reportLoading = useSelector(videoAnalyticsSelectors.reportLoading);
    const [params, setParams] = useState({});
    const [reportId, setReportId] = useState(null);
    const [selectedFormats,  setSelectedFormats] = useState([]);

    const getReport = (formats) => {
        dispatch(loadCameraReport({
            ...params,
            report: 1,
            formats,
        }));
    };

    useEffect(() => {
        if (Object.keys(params).length > 0) {
            const newParams = {
                ...params
            };
            delete newParams.cameras;
            delete newParams.parameters;
            dispatch(loadCameraReport(newParams, (value) => setData({
                graphParams: params,
                reportData: value
            })));
        }
    }, [params, dispatch]);

    const resetParams = () => {
        setParams({});
        setData({
            graphParams: {},
            reportData: {}
        });
    };

    return (
        <div className={styles.va_graph}>
            <ReturnToButton
                onClick={() => {
                    // очищаем фиьтр и данные
                    dispatch(clearCameraReport());
                }}
            />
            <PageLayout
                header="Отчет по видеоаналитике (графики)"
                filters={
                    <Filters
                        setParams={setParams}
                        setReportId={setReportId}
                        resetParams={resetParams}
                    />
                }
                content={() => (
                    <>
                        {reportLoading
                            ? <Loading circular={true}/>

                            : Object.keys(data.reportData)?.length > 0
                                ? <Graph
                                    reportData={data.reportData}
                                    params={data.graphParams}
                                />
                                : (!reportLoading && params.type && <div>{messages.NO_DATA_FOR_PERIOD}</div>)
                        }
                    </>
                )}
                informPanelProps={{
                    buttons: (
                        <>
                            <ReportBlock
                                customComponents={() => <GetReports
                                    onDownload={getReport}
                                    // disabled={disableRequestBtn()}
                                    selected={selectedFormats}
                                    onChange={setSelectedFormats}
                                    helperText="Для получения отчета заполните все поля!"
                                    reportId={reportId} />
                                }
                            />
                        </>
                    ),
                }}
            />
        </div>
    );
}

export default VAGraph;
