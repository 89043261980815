import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import titles from 'helpers/constants/titles';

export interface Item {
    name?: string;
    id: number;
    color?: string;
}

interface Status<Item> extends CommonAutocompleteProps<Item> {
    filter?: Record<string, string>;
}
const StatusesSelect = ({
    multiple = false,
    selected,
    options = [],
    onChange,
    required = false,
    disabled = false,
    label = titles.STATUS,
    error = false,
    helperText = '',
    renderLabel = (option) => option?.name || '',
    limitTags = 1,
    onReset,
    filter = {},
    isOptionEqualToValue = (opt, val) => opt?.id === val?.id,
    filterSelectedOptions = true,
    test_id_prefix = '',
    inputName = 'status'
}: Status<Item>): React.ReactElement => {
    return (
        <CommonAutocomplete<Item>
            multiple={multiple}
            selected={selected}
            onChange={onChange}
            required={required}
            disabled={disabled}
            onReset={onReset}
            renderLabel={renderLabel}
            limitTags={limitTags}
            label={label}
            error={error}
            helperText={helperText}
            options={options}
            isOptionEqualToValue={isOptionEqualToValue}
            filterSelectedOptions={filterSelectedOptions}
            showAsSelect
            test_id_prefix={test_id_prefix}
            inputName={inputName}
        />
    );
};

export default StatusesSelect;
