import CamerasGroup from '../../pages/Dictionaries/CamerasGroup';
import CamerasTemplates from '../../pages/Dictionaries/CamerasTemplates';
import CamerasView from '../../pages/Dictionaries/CamerasView';
import CityCameras from '../../pages/Dictionaries/CityCameras';

const routes = [
    {
        path: '/dictionaries/camera-group',
        component: CamerasGroup,
        exact: true,
    },
    {
        path: '/dictionaries/city-cameras',
        component: CityCameras,
        exact: true
    },
    {
        path: '/dictionaries/camera-templates',
        component: CamerasTemplates,
        exact: true,
    },
    {
        path: '/dictionaries/camera-view',
        component: CamerasView,
        exact: true,
    },
];

export default routes;