import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const apiVideoCamerasMethods = {
    getVideoCamerasList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getVideoCamerasList(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },
    postVideoCamera: async (data) => {
        const response = await instance.post(apiUrls.postVideoCamera(), data);
        return response?.data;
    },
    putVideoCamera: async (id, data) => {
        const response = await instance.put(apiUrls.putVideoCamera(id), data);
        return response?.data;
    },
    deleteVideoCamera: async (id) => {
        const response = await instance.delete(apiUrls.deleteVideoCamera(id));
        return response?.data;
    },
    getStatusesVideoCamera: async () => {
        const response = await instance.get(apiUrls.getStatusesVideoCamera());
        return response?.data;
    },
    changeVideoCameraStatus: async (id, data) => {
        const response = await instance.put(apiUrls.changeVideoCameraStatus(id), data);
        return response?.data;
    },
    getTypesVideoCamera: async () => {
        const response = await instance.get(apiUrls.getTypesVideoCamera());
        return response?.data;
    },
    getForPolygon: async (params) => {
        const response = await instance.post(
            apiUrls.getPolygon(),
            params,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
        return response?.data?.data;
    },
    getSidebarList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getVideoCamerasList(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    getVideoCamerasFrame: async (id) => {
        const response = await instance.get(apiUrls.getVideoCameraFrame(id));
        return response?.data;
    },

    postCameraGroup: async (data) => {
        const response = await instance.post(apiUrls.postCameraGroup(), data);
        return response?.data;
    },

    getCameraGroupList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getCameraGroup(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    putCameraGroup: async (id, data) => {
        const response = await instance.put(apiUrls.putCameraGroup(id), data);
        return response?.data;
    },
    
    deleteCameraGroup: async (id) => {
        const response = await instance.delete(apiUrls.deleteCameraGroup(id));
        return response?.data;
    },

    getCameraTemplateList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getCameraTemplateList(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    postCameraTemplate: async (data) => {
        const response = await instance.post(apiUrls.postCameraTemplate(), data);
        return response?.data;
    },

    putCameraTemplate: async (id, data) => {
        const response = await instance.put(apiUrls.putCameraTemplate(id), data);
        return response?.data;
    },
    
    deleteCameraTemplate: async (id) => {
        const response = await instance.delete(apiUrls.deleteCameraTemplate(id));
        return response?.data;
    },

    getVideoCamerasByRadius: async (params) => {
        const response = await instance.post(apiUrls.getVideoCamerasByRadius(), params);
        return response?.data;
    },

};

export default apiVideoCamerasMethods;
