import L from 'leaflet';
import config from './config';
import { dateWithDashYYYYMMDD } from '../../../../helpers/date.config';
import { getMarker } from '../../marker/Marker';
import { renderToString } from 'react-dom/server';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';

export const getColor = () => {
    return config.mapMarkersColors.default;
};

// иконка маркера
export const createIconMarker = (color) => L.divIcon({
    className: 'marker',
    iconSize: [30, 36],
    iconAnchor: [15, 36],
    popupAnchor: [0, -36],
    html: `
        ${getMarker(color)}
        <i class="${config.layerIcon}"
           style="width:100%;color:#fff;position:absolute;left:0;top:0;height:calc(100% - 9px);display:flex;justify-content:center;align-items:center;font-size:1rem;"
        />
    `,
});

export const getFilter = (filter) => {
    const filters = {
        ...filter,
        // category_list: filter.category_list ? [filter?.category_list] : null,
        // type_list: filter.type_list ? [filter?.type_list] : null,
        // start_at: filter.start_at ? dateWithDashYYYYMMDD(filter.start_at) : null,
        // end_at: filter.end_at ? dateWithDashYYYYMMDD(filter.end_at) : null,
    };

    return removeEmptyFields(filters, false);
};
