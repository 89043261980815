import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadRouteTypes } from '../../redux/TransportPassenger/actions';

// получить поле из стора
const useTransportTypes = (
    storeName = 'transportPassenger',
    keyName = 'types',
    action = loadRouteTypes
) => {
    const dispatch = useDispatch();
    const types = useSelector((state) => state[storeName][keyName]);
    const [data, setData] = useState(types);

    useEffect(() => {
        if (!types || Object.keys(types).length === 0) {
            dispatch(action());
        } else {
            setData(types);
        }
    }, [dispatch, types]);

    return data;
};

export default useTransportTypes;
