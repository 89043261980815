import { config } from '../../config';

const apiAipUrl = () => config.get('urls').apiAipUrl;

const apiUrls = {

    getAipList: () => `${apiAipUrl()}/aip`,

    getAipEvents: () => `${apiAipUrl()}/aip/events`,

    getAipPolygon: () => `${apiAipUrl()}/aip/polygon`,

    getSidebar: () => `${apiAipUrl()}/aip`,

    createAip: () => `${apiAipUrl()}/aip`,

    editAip: (id) => `${apiAipUrl()}/aip/${id}`,

    deleteAip: (id) => `${apiAipUrl()}/aip/${id}`,

    getAipTypes: () => `${apiAipUrl()}/aip/types`,

    getAipOperators: () => `${apiAipUrl()}/aip/operators`,
        
};

export default apiUrls;