import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, ListItemIcon } from '@mui/material';

import { clearValidation } from 'redux/Validation/actions';
import * as actions from 'redux/TransportPassenger/actions';
import { config } from 'config';
import messages from 'helpers/constants/messages';
import { checkBase64 } from 'helpers/transport.types';
import { useStoreProp, } from 'helpers/hooks';
import ConfirmDelete from 'components/common/ConfirmModal';
import ReportModal from 'components/common/Transport/ReportModal/ReportModal';
import SelectDateHistory from 'components/common/Transport/TransportHistory/SelectDateHistory';
import {
    ActionMore,
    ActionsButtons,
    LSContentColumn,
    LSContentItem
} from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import RegistrationCard from './RegistrationCard';
import VehiclesModal from './VehiclesModal';
import VehiclesInfo from './VehiclesInfo';
import { vehiclesTransportPassengerURI } from './moduleConfig';

function VehiclesItem ({ data, reloadList }) {
    const dispatch = useDispatch();
    const categories = useStoreProp(actions.loadVehicleCategories, 'transportPassenger', 'vehicleCategories');


    const [open, setOpen] = useState(false);
    const [delModalOpen, setDelModalOpen] = useState(false);
    const [openAddVehicleModal, setOpenAddVehicleModal] = useState(false);
    const [isOpenRegistrationCard, setIsOpenRegistrationCard] = useState(false);
    const [openReportModal, setOpenReportModal] = useState(false);
    const [showHistoryRange, setShowHistoryRange] = useState(false);

    const openModal = (e) => {
        e.stopPropagation();
        setOpenAddVehicleModal(!openAddVehicleModal);
    };

    const closeModal = () => {
        dispatch(clearValidation());
        setOpenAddVehicleModal(false);
    };
    const toggleOpenDelModal = (e) => {
        e.stopPropagation();
        setDelModalOpen(!delModalOpen);
    };
    const deleteVehicle = () => {
        dispatch(actions.deletedVehicle(data.id, reloadList));
        setDelModalOpen(false);
    };
    const openRegistrationCard = (e) => {
        e.stopPropagation();
        setIsOpenRegistrationCard(true);
    };
    const closeRegistrationCard = () => {
        setIsOpenRegistrationCard(false);
    };

    const category = categories?.find(({ id }) => id === data?.category_id);

    const titleHistory = () => {
        const title = [];
        const categoryName = category?.name || null;
        if (categoryName) {
            title.push(categoryName);
            const garage_number = data?.garage_number || null;
            if (garage_number) {
                title.push(`номер ${garage_number}`);
            }
        } else if (data?.uid) {
            title.push(data?.uid);
        }

        return title.join(', ');
    };

    const imgUrl = checkBase64(category?.image_with_work_order);

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {imgUrl && (
                        <ListItemIcon>
                            <Avatar  sx={{ background: category?.color }}>
                                <img
                                    style={{ width: 30, height: 30, margin: 0 }}
                                    src={imgUrl}
                                    alt=""
                                />
                            </Avatar>
                        </ListItemIcon>
                    )}
                </LSContentColumn>
                <LSContentColumn>
                    {data?.number || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {data?.brand_name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {data?.model_name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {category?.name || messages.NO_DATA}
                </LSContentColumn>
                {config?.project?.code === '34_vlg_vlz' && (
                    <LSContentColumn>
                        {data?.characteristics?.fort_monitor_name || messages.NO_DATA}
                    </LSContentColumn>
                )}
                <LSContentColumn align="right" isActions>
                    <ActionsButtons
                        test_id_prefix={`${vehiclesTransportPassengerURI}:item`}
                        buttons={[
                            {
                                ...buttonsTypes.reportIcon,
                                onClick: () => setOpenReportModal(true),
                                testName: 'report'
                            },
                            {
                                icon: <i className="fas fa-clipboard"/>,
                                name: 'Учетная карточка ТС',
                                onClick: openRegistrationCard,
                                testName: 'card'
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: openModal,
                                testName: 'edit'
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: toggleOpenDelModal,
                                testName: 'delete'
                            },
                            {
                                ...buttonsTypes.historyIcon,
                                name: 'История перемещений',
                                onClick: () => setShowHistoryRange(true),
                                testName: 'history'
                            },
                        ]}
                    />
                    <ActionMore
                        isOpen={open}
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(prev => !prev);
                        }}
                        test_id_prefix={`${vehiclesTransportPassengerURI}:item`}
                    />
                </LSContentColumn>
            </LSContentItem>

            {showHistoryRange
                && <SelectDateHistory
                    uuid={data?.id}
                    title={titleHistory()}
                    isIconButton
                    actions={{
                        storeProp: 'transportPassenger',
                        history: {
                            action: actions.loadVehicleHistory,
                            clear: actions.clearVehicleHistory,
                            loadingProp: 'loadingVehicleHistory',
                            dataProp: 'vehicleHistory',
                        },
                        speedList: {
                            action: actions.loadColorSpeed,
                            dataProp: 'colorSpeed',
                        }
                    }}
                    needButton={false}
                    isOpen={showHistoryRange}
                    onClose={() => setShowHistoryRange(false)}
                    test_id_prefix={`${vehiclesTransportPassengerURI}:history-modal`}
                />
            }

            {openAddVehicleModal && (
                <VehiclesModal
                    isOpen={openAddVehicleModal}
                    onClose={closeModal}
                    data={data}
                    isNew={false}
                    reloadList={reloadList}
                    test_id_prefix={vehiclesTransportPassengerURI}
                />
            )}
            {delModalOpen && (
                <ConfirmDelete
                    open={delModalOpen}
                    onClose={toggleOpenDelModal}
                    onSuccess={deleteVehicle}
                    message={messages.CONFIRM_DELETE}
                    test_id_prefix={`${vehiclesTransportPassengerURI}:delete`}
                />
            )}
            {openReportModal && (
                <ReportModal
                    onClose={() => setOpenReportModal(false)}
                    open={openReportModal}
                    item={data}
                    urlTemplate="/dictionaries/passenger-transport/vehicles"
                    test_id_prefix={`${vehiclesTransportPassengerURI}:report-modal`}
                />
            )}
            {open && (
                <VehiclesInfo
                    isOpen={open}
                    info={data}
                />
            )}
            {isOpenRegistrationCard && (
                <RegistrationCard
                    isOpen={isOpenRegistrationCard}
                    onClose={closeRegistrationCard}
                    item={data}
                    categories={categories}
                    test_id_prefix={`${vehiclesTransportPassengerURI}:registration-card-modal`}
                />
            )}
        </>
    );
}

export default VehiclesItem;
