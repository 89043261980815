import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { TextField, TextFieldProps } from '@mui/material';
import {
    DateTimePicker,
    TimePicker,
    DatePicker,
    DatePickerProps,
    TimePickerProps,
    DateTimePickerProps,
    StaticDateTimePicker,
    StaticTimePicker,
    StaticDatePicker,
} from '@mui/lab';
import PickersModalDialog from '@mui/lab/internal/pickers/PickersModalDialog';
import { TimePickerView } from '@mui/lab/TimePicker/shared';

import { config } from 'config';
import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import { isDateValid, getMinMaxDate, } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import type { SingleKeyboardDateTimePickerProps } from 'types/Dates';
import { useValidation } from 'helpers/hooks';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

import { renderDay } from './renderDay';
import { customPattern } from '../helpers';


const SingleKeyboardDateTimePicker = ({
    views,
    value = null,
    error = false,
    onChange = () => {},
    label = titles.DATE,
    helperText = '',
    isDisabledHandler = () => {},
    isDisabled = false,
    required = false,
    pickerFormat,
    // onKeyPress = null,
    timeOnly = false,
    dateOnly = false,
    maxDate,
    minDate,
    maxTime,
    minTime,
    disableFuture = false,
    className = '',
    placeholder = '',
    test_id_prefix = '',
}: SingleKeyboardDateTimePickerProps) => {
    const zone = config.getTimeZone();

    const validation = useValidation();

    const pattern = customPattern(views, pickerFormat, dateOnly, timeOnly);

    const mask = pattern.mask;
    const customPickerFormat = pickerFormat || pattern.pickerFormat;
    const customPlaceHolder = placeholder || pattern.placeholder;

    // для глобального учета таймзоны проекта мы все даты превращаем в объекты luxon
    // т.к. в его настройках глобально мы указали таймзону проекта
    const minDateLuxon = getMinMaxDate(minDate, true);
    const minTimeLuxon = getMinMaxDate(minTime, true);
    const maxDateLuxon = getMinMaxDate(maxDate);
    const maxTimeLuxon = getMinMaxDate(maxTime);


    const [currentValue, setCurrentValue] = useState<Date | string | null>('');
    const [tempValue, setTempValue] = useState<Date | null>(null); // (3)
    const [open, setOpen] = useState(false); // (4)

    const setLuxonTempValue = (luxonDate: DateTime | Date | string | null) => {
        // когда выбираем дату из календаря
        const val = () => {
            if (luxonDate) {
                if (luxonDate instanceof Date) {
                    return DateTime.fromJSDate(luxonDate);
                } else if (luxonDate instanceof DateTime && luxonDate?.isValid) {
                    return luxonDate;
                } else if (typeof luxonDate === 'string') {
                    return DateTime.fromFormat(
                        luxonDate,
                        customPickerFormat,
                        { zone }
                    );
                }
            }
            return DateTime.local({ zone });
        };

        setTempValue(val()?.toJSDate());
    };

    useEffect(() => {
        const convertValue = () => {
            if (value) {
                if (typeof value === 'string') {
                    const fromISO = DateTime.fromISO(value, { zone });
                    return fromISO.isValid
                        ? fromISO?.toJSDate()
                        : DateTime.fromFormat(value, customPickerFormat, {
                            zone
                        })?.toJSDate();
                }
                if (value instanceof Date) {
                    return DateTime.fromJSDate(value, { zone })?.toJSDate();
                }
            }
            return value;
        };
        setCurrentValue(convertValue());
    }, [value, customPickerFormat, zone]);

    const [invalidDate, setInvalidDate] = useState<boolean>(currentValue ? isDateValid(currentValue) : false);

    const setLuxonOnchangeValue = (luxonDate: DateTime | null, keyboardInputValue?: string | undefined) => {
        // когда руками пишем дату в инпуте пикера
        setInvalidDate(!luxonDate?.isValid);
        isDisabledHandler(!luxonDate?.isValid);
        setTempValue(luxonDate ? luxonDate.toJSDate() : null);
        onChange(luxonDate ? luxonDate.toJSDate() : null);
        validation.clear();
    };

    const picker = timeOnly
        ? <TimePicker
            open={false}
            onOpen={() => {
                setLuxonTempValue(currentValue);
                setOpen(true);
            }}
            mask={mask}
            inputFormat={customPickerFormat}
            disabled={isDisabled}
            ampm={false}
            views={views as TimePickerView[]}
            value={currentValue}
            onChange={setLuxonOnchangeValue}
            label={label}
            renderInput={(params: TextFieldProps) => (
                <TextField
                    {...params}
                    size="small"
                    required={required}
                    error={error || invalidDate}
                    InputLabelProps={{ style: { paddingRight: 30 } }}
                    style={{ minWidth: 185 }}
                    inputProps={{
                        ...params.inputProps,
                        placeholder: customPlaceHolder,
                        'data-testid': `${test_id_prefix}/input`,
                    }}
                    // InputProps={{ // временно убираем, неправильно работает выбор даты
                    //     ...params.InputProps,
                    //     endAdornment: (
                    //         <InputAdornment position={'end'}>
                    //             <IconButton
                    //                 onMouseDown={(e) => {
                    //                     e.stopPropagation(); // чтобы клик не фокусировался в инпуте
                    //                     setLuxonTempValue(currentValue);
                    //                     setOpen(true);
                    //                 }}
                    //                 data-testid={`${test_id_prefix}/input/button`}
                    //                 style={{  width: '1rem', height: '1rem', fontSize: '20px' }}
                    //             >
                    //                 <i className="far fa-calendar-day" />
                    //             </IconButton>

                    //         </InputAdornment>
                    //     ),
                    // }}
                />
            )}
            maxTime={maxTimeLuxon}
            minTime={minTimeLuxon}
            showToolbar={false}
        />
        : dateOnly
            ? <DatePicker
                open={false}
                onOpen={() => {
                    setLuxonTempValue(currentValue);
                    setOpen(true);
                }}
                mask={mask}
                inputFormat={customPickerFormat}
                disabled={isDisabled}
                value={currentValue}
                onChange={setLuxonOnchangeValue}
                label={label}
                renderDay={renderDay}
                views={views as DatePickerProps['views']}
                renderInput={(params: TextFieldProps) => {
                    return (
                        <TextField
                            {...params}
                            size="small"
                            error={error || invalidDate}
                            required={required}
                            InputLabelProps={{ style: { paddingRight: 30 } }}
                            style={{ minWidth: 185 }}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: customPlaceHolder,
                                'data-testid': `${test_id_prefix}/input`,
                                type: 'text'
                            }}
                            // InputProps={{ // временно убираем, неправильно работает выбор даты
                            //     ...params.InputProps,
                            // endAdornment: (
                            //     <InputAdornment position={'end'}>
                            //         <IconButton
                            //             onMouseDown={(e) => {
                            //                 e.stopPropagation(); // чтобы клик не фокусировался в инпуте
                            //                 setLuxonTempValue(currentValue);
                            //                 setOpen(true);
                            //             }}
                            //             data-testid={`${test_id_prefix}/input/button`}
                            //             style={{  width: '1rem', height: '1rem', fontSize: '20px' }}
                            //         >
                            //             <i className="far fa-calendar-day" />
                            //         </IconButton>

                            //     </InputAdornment>
                            // ),
                            // }}
                        />
                    );}}
                maxDate={maxDateLuxon}
                minDate={minDateLuxon}
            />
            : <DateTimePicker
                open={false}
                onOpen={() => {
                    setLuxonTempValue(currentValue);
                    setOpen(true);
                }}
                mask={mask}
                inputFormat={customPickerFormat}
                disabled={isDisabled}
                ampm={false}
                views={views as DateTimePickerProps['views']}
                value={currentValue}
                onChange={setLuxonOnchangeValue}
                label={label}
                renderInput={(params: TextFieldProps) => (
                    <TextField
                        {...params}
                        size="small"
                        error={error || invalidDate}
                        required={required}
                        InputLabelProps={{
                            style: { paddingRight: 30 },
                        }}
                        style={{ minWidth: 185 }}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: customPlaceHolder,
                            'data-testid': `${test_id_prefix}/input`,
                        }}
                        // InputProps={{ // временно убираем, неправильно работает выбор даты
                        //     ...params.InputProps,
                        //     endAdornment: (
                        //         <InputAdornment position={'end'}>
                        //             <IconButton
                        //                 onMouseDown={(e) => {
                        //                     e.stopPropagation(); // чтобы клик не фокусировался в инпуте
                        //                     setLuxonTempValue(currentValue);
                        //                     setOpen(true);
                        //                 }}
                        //                 data-testid={`${test_id_prefix}/input/button`}
                        //                 style={{  width: '1rem', height: '1rem', fontSize: '20px' }}
                        //             >
                        //                 <i className="far fa-calendar-day" />
                        //             </IconButton>

                        //         </InputAdornment>
                        //     ),
                        // }}
                    />
                )}
                renderDay={renderDay}
                maxDate={maxDateLuxon}
                minDate={minDateLuxon}
            />;

    return (
        <>
            <FormInfoWrapper
                error={error || invalidDate}
                helperText={helperText || ((!!currentValue || !!tempValue) && invalidDate ? messages.INVALID_DATE_FORMAT : '')}
                className={className}
                test_id_prefix={`${test_id_prefix}/input`}
            >
                {picker}
            </FormInfoWrapper>
            <PickersModalDialog
                open={open}
                onAccept={() => {
                    onChange(tempValue);
                    validation.clear();
                    setInvalidDate(!isDateValid(tempValue));
                    isDisabledHandler(!isDateValid(tempValue));
                    setTempValue(null);
                    setOpen(false);
                }}
                onDismiss={() => {
                    setTempValue(null);
                    setOpen(false);
                }}
                onClear={() => setOpen(false)}
                onSetToday={() => {
                    setLuxonTempValue(new Date());
                    // setOpen(false);
                }}
                cancelText={buttons.CANCEL}
                showTodayButton
                todayText={buttons.TODAY}
            >
                {timeOnly
                    ? <StaticTimePicker
                        displayStaticWrapperAs="mobile"
                        value={tempValue}
                        onChange={setLuxonTempValue}
                        renderInput={(params: TextFieldProps) => <TextField {...params} />}
                        showToolbar={false}
                        views={views as TimePickerProps['views']}
                        maxTime={maxTimeLuxon}
                        minTime={minTimeLuxon}
                    />
                    : dateOnly
                        ? <StaticDatePicker
                            leftArrowButtonText={buttons.PREV_MONTH}
                            rightArrowButtonText={buttons.NEXT_MONTH}
                            displayStaticWrapperAs="mobile"
                            value={tempValue}
                            onChange={setLuxonTempValue}
                            renderInput={(params: TextFieldProps) => <TextField {...params} />}
                            showToolbar={false}
                            renderDay={renderDay}
                            views={views as DatePickerProps['views']}
                            disableFuture={disableFuture}
                            maxDate={maxDateLuxon}
                            minDate={minDateLuxon}
                        />
                        :   <StaticDateTimePicker
                            leftArrowButtonText={buttons.PREV_MONTH}
                            rightArrowButtonText={buttons.NEXT_MONTH}
                            displayStaticWrapperAs="mobile"
                            value={tempValue}
                            onChange={setLuxonTempValue}
                            renderInput={(params: TextFieldProps) => <TextField {...params} />}
                            showToolbar={false}
                            renderDay={renderDay}
                            views={views as DateTimePickerProps['views']}
                            disableFuture={disableFuture}
                            maxTime={maxTimeLuxon}
                            maxDate={maxDateLuxon}
                            minDate={minDateLuxon}
                        />
                }
            </PickersModalDialog>
        </>
    );
};

export default SingleKeyboardDateTimePicker;
