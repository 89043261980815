import React, { useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import cn from 'classnames';
import DateRange from '../../../../common/Dates/DateRange';
import titles from '../../../../../helpers/constants/titles';
import FormButtons from '../../../../common/FormButtons';
import { buttonsTypes } from '../../../../common/FormButtons';
import { useDispatch } from 'react-redux';
import { loadEvents } from '../../../../../redux/SituationalPlans/actions';
import { fullDateTimeWithTimeZone } from '../../../../../helpers/date.config';


const Filter = ({ loadParams }) => {
    const dispatch = useDispatch();
    const [wasSearch, setWasSearch] = useState(false);
    const paramsHandler = (value) => loadParams(value);

    const [data, setData] = useState({
        name: '',
        date_start: null,
        date_end: null,
    });

    const handleResetFilter = () => {
        paramsHandler();
        setData({
            name: '',
            date_start: null,
            date_end: null,
        });
        if (wasSearch) {
            setWasSearch(false);
            dispatch(loadEvents(1, 25));
        }
    };

    const isDisabled = !data.name && !data.date_start && !data.date_end;

    const handleSearch = () => {

        const correctStartDate = fullDateTimeWithTimeZone(data.date_start);
        const correctEndDate = fullDateTimeWithTimeZone(data.date_end);
        setWasSearch(true);

        const resultData = {
            ...data,
            date_start: correctStartDate === 'Invalid date' ? null : correctStartDate,
            date_end: correctEndDate === 'Invalid date' ? null : correctEndDate
        };

        const paramsWithoutEmpty = Object.keys(resultData).reduce((res, key) => {
            if (resultData[key]) {
                res[key] = resultData[key];
            }
            return res;
        }, {});

        paramsHandler(paramsWithoutEmpty);

        dispatch(loadEvents(1, 25, paramsWithoutEmpty));
    };

    const handleClickEnter = (e) => {
        if (e?.key === 'Enter') handleSearch();
    };

    return (
        <div className="filter">
            <div className={cn('filter__wrap', 'filter__wrap__more__field')}>
                <FormControl size="small" variant="outlined">
                    <TextField
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={data.name}
                        name={'name'}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                        type="text"
                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                        onKeyPress={handleClickEnter}
                    />
                </FormControl>
                <DateRange
                    valueStartDate={data.date_start}
                    valueEndDate={data.date_end}
                    handleDate={(e) => setData({ ...data, date_start: e })}
                    handleEndDate={(e) => setData({ ...data, date_end: e })}
                    startLabel={titles.START_DATE}
                    endLabel={titles.END_DATE}
                    disableFuture={false}
                />
            </div>
            <div className={cn('filter__wrap__btn', 'filter__btn__column')}>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: handleResetFilter,
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: handleSearch,
                            disabled: isDisabled,
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filter;
