import React, { useState } from 'react';
import { IconButton, ListItem, ListItemText } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import StandardizedCompanyForm from './StandardizedCompanyForm';
import titles from '../../../../helpers/constants/titles';

const StandardizedCompanyItem = ({ item, isOpenModalForm = () => {}, reloadList }) => {

    const [openForm, setOpenForm] = useState(false);

    return (
        <div className="list_item">
            <ListItem className="list_item">
                <ListItemText onClick={() => setOpenForm(true) }>
                    <div>
                        <span><strong>{ titles.INN }:</strong> {item?.inn}</span>
                    </div>
                    <div>
                        <span><strong>{ titles.NAME }:</strong> {item?.name?.full_with_opf}</span>
                    </div>
                </ListItemText>
            </ListItem>
            { openForm
                && <StandardizedCompanyForm
                    open={openForm}
                    setOpenModal={setOpenForm}
                    item={item}
                    isOpenModalForm={(status) => isOpenModalForm(status)}
                    reloadList={reloadList}
                />
            }
        </div>
    );
};

export default StandardizedCompanyItem;
