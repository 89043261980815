import React, { useEffect, useRef } from 'react';
// import {
//     usePrevious,
//     // useSelectorByLink
// } from '../../../../../../helpers/hooks';
import { useSelector } from 'react-redux';

const useUsmFirstLoad = (fetch, optionProp, callback) => {

    const {
        storeName, //: 'transportPassenger',
        storeNameProps, //: 'radioType',
        storeLoadingProps,
    } = optionProp;

    const isLoadRef = useRef(false);

    const list = useSelector((state) =>
        state[storeName][storeNameProps]
    );
    // если null - не прокинуто
    const listLoading = useSelector((state) =>
        storeLoadingProps ? state[storeName][storeLoadingProps] : null
    );

    useEffect(() => {
        if (
            list?.data?.length > 0
            && isLoadRef.current
        ) {
            isLoadRef.current = false;
            callback && callback(list?.data[0]);
        }
    }, [list]);

    return {
        load: (ids) => {
            isLoadRef.current = true;
            fetch({
                page: 1,
                limit: 1,
                ids,
            });
        },
        loading: listLoading,
    };
};

export default useUsmFirstLoad;
