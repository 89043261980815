import { useEffect } from 'react';

const TestNotify = () => {

    function createNotification (title, options = {}) {
        const notification = new Notification(title, options);
        // function clickFunc() {window.location.href = '';}
        // notification.onclick = clickFunc;
    };

    function sendNotification (title, options = {}) {
        // Проверим, поддерживает ли браузер HTML5 Notifications
        if (!('Notification' in window)) {
            alert('Ваш браузер не поддерживает HTML Notifications, его необходимо обновить.');
        }

        // Проверим, есть ли права на отправку уведомлений
        else if (Notification.permission === 'granted') {
            // Если права есть, отправим уведомление
            createNotification(title, options);
        }

        // Если прав нет, пытаемся их получить
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission(function (permission) {
                // Если права успешно получены, отправляем уведомление
                if (permission === 'granted') {
                    createNotification(title, options);
                } else {
                    alert('Вы запретили показывать уведомления');
                }
            });
        } else {
            // Пользователь ранее отклонил наш запрос на показ уведомлений
        }
    };

    useEffect(() => {
        if (window?.location?.search?.includes?.('testPushNotification')) {
            console.log('ready push notification');

            setInterval(() => {
                console.log('send notification');
                sendNotification('Внимание');
            }, 10000);
        }
    }, []);

    return null;
};

export default TestNotify;
