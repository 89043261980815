import { memo } from 'react';
import { Bar } from 'react-chartjs-2';
import { union } from 'lodash';

import getColor from 'helpers/getColor';
import getMonth from 'helpers/getMonth';


function Graph(props) {
    const { data: dataObj } = props;
    const regions = Object.keys(dataObj);

    const alldate = Object.keys(dataObj).reduce((res, key) =>
        union(res, Object.keys(dataObj[key])), []).sort();

    const data = {
        // labels: alldate.map((item) => getDateToFormat(item, 'MMMM yyyy')),
        labels: alldate.map((item) => {
            const dAr = item.split('-');
            const month = parseInt(dAr[1]) - 1;
            return `${getMonth(month)} ${dAr[0]}`;
        }),
        datasets: regions.reduce((res, key, index) => ([
            ...res,
            {
                label: key || 'не указано',
                data: alldate.map((k) => {
                    const title = dataObj[key] || {};
                    const time = title[k] || {};
                    return time?.count || 0;
                }),
                borderColor: getColor(key),
                backgroundColor: getColor(key),
                maxBarThickness: 15,
            }
        ]), []),
    };

    // const getLabel = (context) => {
    //     const label = context?.formattedValue || '';
    //     return `Количество ДТП: ${label}`;
    // };
    //
    // const toolTipTitle = (tooltipItems) => {
    //     return tooltipItems[0]?.dataset.label;
    // };
    //     plugins: {
    //         tooltip: {
    //             mode: 'point',
    //             usePointStyle: true,
    //             callbacks: {
    //                 label: getLabel,
    //                 title: toolTipTitle
    //             }
    //         },
    //         legend: {
    //             align: 'center',
    //             position: 'bottom',
    //             labels: {
    //                 boxWidth: 20,
    //             }
    //         }
    //     },

    const options = {
        // responsive: true,
        plugins: {
            legend: {
                align: 'left',
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                }
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Количество'
                }
            },
        }
    };

    return (
        <div>
            <Bar
                options={options}
                data={data}
            />
        </div>
    );
}

export default memo(Graph);