import React, { useMemo } from 'react';
import { Line as GLine } from 'react-chartjs-2';
import hexToRgba from '../helpers/hexToRgba';

// линейный график
const Line = ({
    data = [],
    fill = false,
    legendPosition = 'bottom',
    max = 0,
}) => {

    const options = {
        // responsive: true,
        plugins: {
            legend: {
                position: legendPosition,
            },
            // title: {
            //     display: true, text: 'Chart.js Line Chart',
            // },
        },
        // scales: {
        //     yAxis: {
        //         min: 0,
        //         max: 10,
        //     },
        // }

    };

    const dataG = useMemo(() => {
        const labels = data[0].items.map(({ label }) => label);

        const datasets = data.map(({ items, color, name }) => {
            return {
                fill,
                label: name,
                borderColor: color,
                backgroundColor: fill ? hexToRgba(color, .2) : color,
                data: items.map(({ value }) => value)
            };
        });

        // if (max) {
        //     datasets.push({
        //         fill: true,
        //         label: 'Ограничение',
        //         borderColor: '#ff0000',
        //         backgroundColor: hexToRgba('#ff0000', .2),
        //         data: labels.map(() => max)
        //     });
        // }

        return {
            labels,
            datasets
        };
    }, [data, fill]);

    return <GLine options={options} data={dataG} style={{ maxHeight: '300px' }} />;
};

export default Line;
