import { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Chip, IconButton, Popper, Tooltip, } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { isEqual } from 'lodash';

import Modal from '../../common/Modal';
import FormButtons, { buttonsTypes } from '../../common/FormButtons';
import titles from '../../../helpers/constants/titles';
import buttons from '../../../helpers/constants/buttons';
import { useDebounce } from '../../../helpers/hooks';
import { fullDateTime } from '../../../helpers/date.config';
import removeEmptyFields from '../../../helpers/removeEmptyFields';
import saveFilterIcon from '../../../img/icons/savefilter.png';
import saveFilterIconDisabled from '../../../img/icons/filter_disabled.png';
import { authSelectors } from '../../../redux/Auth';
import { deleteUserFilters, saveUserFilters } from '../../../redux/Auth/actions';


// обертка для поля с фильтром на сайдбаре
const SidebarFilter = ({
    resetListPage = () => {},
    filters = {},
    content = () => {},
    onSetFilter = () => {},
    onClearFilter = () => {},
    className = 'border-bottom',
    renderButtons = null,
    layer, // слой для сохранений фильтра на бэк
    filterException = [] // поля которые не надо сохранять пользователю на бэк
}) => {
    const dispatch = useDispatch();

    const userFilters = useSelector((state) => authSelectors.currentPageFilter(state, {
        page: '/map',
        tab: null,
        layer,
    }));
    const loadingUserFilters = useSelector(authSelectors.loadingUserFilters);

    const [show, setShow] = useState(false); // открыть фильтр
    const [filter, setFilter] = useState(filters); // фильтр
    const [isChange, setIsChange] = useState(false);
    const [fields, setFields] = useState([]); // фильтр массивом { id, key, name }
    const [anchorEl, setAnchorEl] = useState(null); // открытие popup
    const savedUserFilter = useRef(false); // сохраняем предыдущее значение для случая сохраненного фильтра на бэке

    const isDisabled = Object.keys(removeEmptyFields(filter, false) || {}).length === 0 && isChange === false;

    // конвертирование фильтра
    const convertFilter = (filter) => {
        const convertedFields = Object.keys(filter).reduce((res, key) => {
            const item = filter[key];

            const _getItem = (item) => {
                const _item = (key, id, name) => {
                    return {
                        id, key, name,
                    };
                };

                if (Array.isArray(item)) {
                    return item.map((item) => {
                        return item.hasOwnProperty('id') && item?.name
                            ? _item(key, item.id, item.name) // объект
                            : _item(key, item, item); // строка
                    });
                } else if (typeof item === 'object' && item.hasOwnProperty('id')) {
                    return [_item(key, item.id, item.name)];
                } else if (item instanceof Date || key === 'start_date' || key === 'end_date') {

                    return [_item(key, item, fullDateTime(item))];
                } else if (item === item.toString() || Number.isFinite(item)) {
                    return [_item(key, item, item)];
                }
            };

            const items = _getItem(item);

            return [
                ...res,
                ...items
            ];

        }, []);

        setFields(convertedFields);
        // если открыт popup но отображать нечего
        if (anchorEl && convertedFields.length === 0) {
            setAnchorEl(null);
        }
    };

    useEffect(() => {
        if (Object.keys(userFilters?.filter || {}).length && !savedUserFilter.current && !loadingUserFilters) {
            savedUserFilter.current = true;
            onSetFilter(userFilters?.filter);
        }
    }, [userFilters?.filter, loadingUserFilters]);

    useEffect(() => {
        convertFilter(filters);
        return () => { savedUserFilter.current = false; };
    }, []);

    useEffect(() => {
        // при изменении глобального фильтра проверяем что локальный не совпадает с ним
        if (!isEqual(filters, filter)) {
            setFilter(filters);
            convertFilter(filters);
        }
    }, [filters]);

    const onChangeFilter = (data) => {
        const newFilter = {
            ...filter
        };
        const keys = Object.keys(data);
        const newData = keys.reduce((res, key) => {
            const dataKey = data[key];

            if (
                // массив данных
                (Array.isArray(dataKey) && dataKey?.length > 0)
                // объект данных
                || (typeof dataKey === 'object' && dataKey?.hasOwnProperty('id') && dataKey?.id && dataKey?.id?.toString().length > 0)
                // строка
                || (dataKey === dataKey?.toString() && dataKey?.length > 0)
                // дата
                || (dataKey?.hasOwnProperty('id') === false && dataKey?.toString().length > 0 && Date.parse(dataKey))
            ) {
                return {
                    ...res,
                    [key]: data[key]
                };
            }

            delete newFilter[key];
            return res;
        }, {});

        setFilter({
            ...newFilter,
            ...newData,
        });
    };

    const onDeleteFilter = (key) => {
        if (key) {
            const newFilter = {
                ...filter
            };

            delete newFilter[key];
            setFilter(newFilter);

            setIsChange(true);
        }
    };

    const handleCloseModal = () => {
        setShow(false);
        setIsChange(false);
    };
    const handleShowModal = () => {
        setAnchorEl(null);
        setShow(true);
    };

    // применить фильтр
    const handleFilterApply = () => {
        onSetFilter(filter);
        convertFilter(filter);
        handleCloseModal();
        setIsChange(false);
    };

    // сохранение фильтра на бэк
    const saveUserFilter = () => {
        const filterWithoutException = Object.entries(filter).reduce((res, [key, value]) => {
            if (!filterException.includes(key)) {
                res[key] = value;
            }
            return res;
        }, {});

        if (Object.keys(filterWithoutException).length > 0) {
            const newFilters = {
                link: '/map',
                filter: removeEmptyFields(filterWithoutException, false, true),
                layer
            };
            savedUserFilter.current = true;
            dispatch(saveUserFilters(newFilters));
        }
    };

    // удаление фильтра с бэка
    const removeUserFilter = () => {
        if (Object.keys(userFilters?.filter || {}).length > 0) {
            const newFilters = {
                link: '/map',
                layer
            };
            savedUserFilter.current = true;
            dispatch(deleteUserFilters(newFilters));
        }
    };

    // сбросить фильтр
    const handleFilterReset = () => {
        setFilter({});
        setFields([]);
        onClearFilter();
        resetListPage();
        // handleCloseModal();
        setAnchorEl(null);
        setIsChange(false);
        removeUserFilter();
    };

    // удалене
    const handleDelete = (key, id) => {
        const filterItem = filter[key];
        if (filterItem) {
            const newFilter = {
                ...filter
            };

            if (Array.isArray(filterItem)) {
                // array
                newFilter[key] = filterItem.reduce((res, item) => {
                    // item строка или объект
                    if (id !== item?.id && id !== item) {
                        return [
                            ...res,
                            item
                        ];
                    }
                    return res;
                }, []);
            } else {
                delete newFilter[key];
            }

            setFilter({
                ...newFilter
            });

            onSetFilter(newFilter);
            convertFilter(newFilter);
        }
    };

    const handleClosePopUp = () => setAnchorEl(null);
    const handleClosePopUpWait = useDebounce(handleClosePopUp, 1000);
    const handleShowPopUp = (e) => {
        if (fields.length > 0) {
            handleClosePopUpWait.clear();
            setAnchorEl(e.currentTarget);
        }
    };

    const renderSaveButton = () => (
        <div className="flex-end" style={{ margin: '0 20px -20px 0' }}>
            <Button 
                disabled={isDisabled}
                onClick={saveUserFilter}
                color="infoButton"
                startIcon={
                    <img
                        src={isDisabled ? saveFilterIconDisabled : saveFilterIcon}
                        alt="icon"
                        style={{ width: '22px', height: 'auto', margin: -4 }}
                    />
                }
            >
                Сохранить фильтр
            </Button>
        </div>
    );

    // рендер попапа фильтра
    const renderPopUp = () => (
        <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClosePopUp}
            onMouseEnter={() => handleClosePopUpWait.clear()}
            onMouseLeave={() => handleClosePopUpWait()}
            style={{ zIndex: 2 }}
        >
            <div className="layers-sidebar__popup">
                <div className="title">{titles.SELECTED_OPTIONS}</div>

                <Scrollbars style={{ height: '200px' }}>
                    <div className="selected" style={{ maxWidth: '100%' }}>
                        {/* список параметров с удалением */}
                        {fields.map(({ id, name, key }, index) => {
                            return (
                                <Chip
                                    key={`${key}_${id}`}
                                    label={name || ''}
                                    onDelete={() => {
                                        handleDelete(key, id);
                                    }}
                                    className="item"
                                />
                            );
                        })}
                    </div>
                </Scrollbars>

                <FormButtons
                    noPadding={true}
                    positionLeft={true}
                    buttons={[
                        {
                            ...buttonsTypes.reset,
                            onClick: handleFilterReset,
                            name: buttons.RESET,
                            size: 'small'
                        },
                        {
                            ...buttonsTypes.close,
                            onClick: () => setAnchorEl(null),
                            name: buttons.CLOSE,
                            size: 'small'
                        },
                    ]}
                />
            </div>
        </Popper>
    );

    // модалка фильтра
    const renderModal = () => (
        <Modal
            isOpen={show}
            onClose={handleCloseModal}
            title={titles.FILTER}
            noPadding
            showCloseInTitle
            onBackdropClick={handleCloseModal}
            buttons={
                <FormButtons
                    beforeTemplate={
                        <FormButtons
                            noPadding
                            positionLeft
                            buttons={[
                                {
                                    ...buttonsTypes.reset,
                                    disabled: isDisabled,
                                    onClick: handleFilterReset
                                },
                            ]}
                        />
                    }
                    buttons={[
                        // {
                        //     ...buttonsTypes.close,
                        //     onClick: handleCloseModal
                        // },
                        {
                            ...buttonsTypes.show,
                            disabled: isDisabled,
                            onClick: handleFilterApply
                        }
                    ]}
                />
            }
        >
            {!!layer && renderSaveButton()}

            {content({
                data: filter,
                onChange: onChangeFilter,
                onDelete: onDeleteFilter,
            })}
        </Modal>
    );

    const renderFilterButton = () => (
        <Badge badgeContent={fields.length} color="primary">
            <IconButton
                size="small"
                onMouseLeave={() => handleClosePopUpWait()}
                onClick={handleShowModal}
                onMouseEnter={handleShowPopUp}
            >
                <i className="fas fa-filter"/>
            </IconButton>
        </Badge>
    );

    return (
        <div className={`layers-sidebar__filter ${className}`}>

            {fields.length > 0
                ?  <>
                    {/* кнопка фильтра */}
                    {renderFilterButton()}

                    {/* иконка очистки фильтра */}
                    <Tooltip title={buttons.RESET_FILTER}>
                        <IconButton
                            onClick={handleFilterReset}
                            size="small"
                        >
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </>
                : <Tooltip title={titles.FILTER}>
                    {renderFilterButton()}
                </Tooltip>
            }
            {renderButtons}

            {renderPopUp()}
            {renderModal()}

        </div>
    );
};

export default SidebarFilter;
