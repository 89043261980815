import React, { useState } from 'react';
import {
    TextField
} from '@mui/material';

import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import titles from 'helpers/constants/titles';
import formatDate from 'helpers/constants/dateFormat';
import { getTimeWithoutSeconds } from 'helpers/date.config';
import { useValidation } from 'helpers/hooks';

import styles from './routes.module.scss';

const IntervalMileageModal = ({ isOpen, onClose, onAccept, item = {} }) => {
    const validation = useValidation();

    const getInterval = (interval) => {
        const current = new Date();
        current.setHours(0, 0);
        if (typeof interval === 'string') {
            const getHourMin = interval?.split(':');
            const [hour, min] = getHourMin;
            current.setHours(hour, min);
        }
        return current;
    };

    const isStation = item.station?.id;
    const newField = isStation
        ? {}
        : {
            check_point: {
                ...item?.check_point,
                name: item?.check_point?.name || ''
            },
            number: item.number
        };

    const [fields, setFields] = useState({
        ...newField,
        mileage: +item?.mileage || 0,
        interval: getInterval(item?.interval),
    });

    const handleChangeFields = ({ target: { value, name } }) => {
        setFields({ ...fields, [name]: value });
    };

    const handleChangeInterval = (value) => {
        setFields({ ...fields, interval: value });
    };

    const handleChangeCheckName = (e) => setFields({
        ...fields,
        check_point: {
            ...item.check_point,
            name: e.target.value
        }
    });

    const onAcceptModal = () => {
        const resultFields = {
            ...item,
            ...fields,
            mileage: +fields.mileage,
            interval: fields.interval && getTimeWithoutSeconds(fields.interval),
        };

        onAccept(resultFields);
    };

    return (
        <div className="modal__form">
            <Modal
                isOpen={isOpen}
                fu
                onClose={onClose}
                title={`${titles.EDIT} ${isStation ? 'остановки' : 'контрольной точки'}`}
                buttons={<FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onAcceptModal,
                        },
                    ]}
                />
                }
            >
                <form className="modal__form">
                    {!isStation
                        && <FormInfoWrapper
                            error={validation.isKey('name')}
                            helperText={validation.get('name')}
                            className="block"
                        >
                            <TextField
                                onChange={handleChangeCheckName}
                                value={fields.check_point.name}
                                type="text"
                                className={styles.textFiledWithoutArrows}
                                label={titles.NAME}
                                name="name"
                                variant="outlined"
                                size="small"
                            />
                        </FormInfoWrapper>
                    }
                    <FormInfoWrapper
                        error={validation.isKey('mileage')}
                        helperText={validation.get('mileage')}
                        className="block"
                    >
                        <TextField
                            onChange={handleChangeFields}
                            value={fields.mileage}
                            type="number"
                            className={styles.textFiledWithoutArrows}
                            label={`${titles.MILEAGE_FROM_STATION} (км)`}
                            name="mileage"
                            variant="outlined"
                            size="small"
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        error={validation.isKey('interval')}
                        helperText={validation.get('interval') || 'Укажите время в формате ЧЧ:ММ (часы:минуты)'}
                        className="block"
                    >
                        <SingleKeyboardDateTimePicker
                            timeOnly
                            label={`${titles.INTERVAL_FROM_STATION} (часы, мин)`}
                            pickerFormat={formatDate.HH_MM}
                            value={fields.interval || null}
                            onChange={handleChangeInterval}
                            minTime={{ hour: 0, minute: 0, second: 0 }}
                        />
                    </FormInfoWrapper>
                </form>
            </Modal>
        </div>
    );
};

export default IntervalMileageModal;

