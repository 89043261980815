import React from 'react';
import { useValidation } from '../../../../../../helpers/hooks';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SelectBoardFonts from '../../../../../common/Autocomplete/Boards/Fonts';
import { FontSize, PaddingPosition, RenderColorPicker } from '../../Messages/ModalForm/Fields';
import MentionEditor from './MentionEditor';

const TextMessageItem = ({ item, onChange, onDelete, board, serviceEventList }) => {
    const { 
        text = '', 
        color = '#fcff00', 
        font = null, 
        size = 24,
        x = 0,
        y = 0,
    } = item;
    
    const validation = useValidation();
    
    const handleChange = (e) => {
        const { name, value, type } = e.target;
        onChange({ 
            ...item, 
            [name]: type === 'number'
                ? value < 0 ? 0 : Number(value)
                : value 
        });
    };

    return (
        <div className="row align-items-center">
            <div className="block row__item">
                <div className="block">
                    <MentionEditor
                        text={text}
                        onChange={(value) => handleChange({ target : { name: 'text', value } })}
                        serviceEventList={serviceEventList}
                    />
                </div>
                <div className="row">
                    <SelectBoardFonts
                        label={'Шрифт'}
                        selected={font}
                        filter={{ board_type: board?.type }}
                        onChange={(value) => handleChange({ target : { name: 'font', value } })}
                        required
                        className="row__item"
                        sortedList={board?.fonts}
                        error={!font || validation.isKey(`text.${item.messageIndex}.font`)}
                        helperText={validation.get(`text.${item.messageIndex}.font`)}
                    />
                </div>
                <div className="row">
                    <RenderColorPicker color={color} onChange={handleChange} />
                    <FontSize
                        required
                        handleChange={handleChange}
                        size={size}
                        error={(size === undefined) || validation.isKey(`text.${item.messageIndex}.size`)}
                        helperText={validation.get(`text.${item.messageIndex}.size`)}
                    />
                </div>             
                <PaddingPosition
                    x={x} 
                    y={y} 
                    handleChange={handleChange} 
                    helperTextX={validation.get(`text.${item.messageIndex}.x`)}
                    helperTextY={validation.get(`text.${item.messageIndex}.y`)}
                />            
            </div>

            <IconButton onClick={onDelete} size="small">
                <CloseIcon />
            </IconButton>
        </div>
    );
};

export default TextMessageItem;