import React, { useState } from 'react';
import CommonModal from '../../../../common/Modal';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import { useDispatch } from 'react-redux';
import { editTransorganization } from '../../../../../redux/TransportPassenger/actions';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';

const Modal = ({ open, onClose, title, callback, item = {}, isNew }) => {
    const dispatch = useDispatch();

    const initialState = isNew 
        ? {} 
        : {
            id: item?.organization_id,
            title: item?.title
        };
    
    const [selectedCompany, setSelectedCompany] = useState(initialState);

    const save = () => {
        const prepareData = removeEmptyFields({
            project: item?.project,
            trans_name: item?.trans_name,
            external_id: item?.external_id,
            organization_id: selectedCompany?.id,
            title: selectedCompany?.title
        });

        dispatch(editTransorganization(item?.id, prepareData, callback));
    };

    return (
        <CommonModal
            title={title}
            isOpen={open}
            onClose={onClose}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: save,
                        disabled: !selectedCompany?.id
                    },
                ]}
            />}
        >
            <SelectCompany
                filter={{ withDeleted: 1 }}
                selected={selectedCompany}
                onChange={(value) => setSelectedCompany(value)}
            />
        </CommonModal>
    );
};

export default Modal;