import L from 'leaflet';
import config from './config';
import { dateWithDashYYYYMMDD } from '../../../../helpers/date.config';
import { getMarker } from '../../marker/Marker';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import iconSvg from './icons/icon';

// иконка
export const getIcon = (category, color) => {
    switch (parseInt(category)) {
        case 2: return `<i class = "far fa-dot-circle" style="color:${color}"/>`;
        case 3: return iconSvg(color);
        default: return `<i class="${config.layerIcon}" style="color:${color}"/>`;
    }
};

// иконка маркера
export const createMarker = ({ color = '#fff', category }) => {
    let props = {};

    switch (parseInt(category)) {
        // Перекресток
        case 2:
            props = {
                iconSize: [28, 28],
                iconAnchor: [14, 14],
                popupAnchor: [0, -14],
                html: `<i class = "far fa-dot-circle"
                    style="font-size:28px;width:100%;color:${color};position:absolute;left:0;top:0;height:calc(100%);display:flex;justify-content:center;align-items:center;"
                />`,
            };
            break;

        // Точка технического присоединения
        case 3:
            props = {
                iconSize: [28, 28],
                iconAnchor: [14, 14],
                popupAnchor: [0, -14],
                html: iconSvg(color),
            };
            break;

        // остальное (если будет)
        default:
            props = {
                iconSize: [30, 36],
                iconAnchor: [15, 36],
                popupAnchor: [0, -36],
                html: `
                    ${getMarker(color)}
                    <i class="${config.layerIcon}"
                        style="width:100%;color:#fff;position:absolute;left:0;top:0;height:calc(100% - 9px);display:flex;justify-content:center;align-items:center"
                    />
                `
            };
    }

    return L.divIcon({
        className: 'marker',
        ...props
    });
};

export const getFilter = (filter) => {
    const filters = {
        ...filter,
        start_at: filter.start_at ? dateWithDashYYYYMMDD(filter.start_at) : null,
        end_at: filter.end_at ? dateWithDashYYYYMMDD(filter.end_at) : null,
    };

    return removeEmptyFields(filters, false);
};
