import { useEffect, useState } from 'react';
import useDebounce from './useDebounce';

// хук для загрудки полигона в слоях
const useLoadPolygonLayer = (
    // функция загрузки
    fn,
    // время ожидания
    waitTime = 500,
    isLoadDefault = true,
    isLockDefault = false,
) => {
    // нужно загрузить данные
    const [isLoad, setIsLoad] = useState(isLoadDefault);
    // можем ли сейчас загрузить
    const [isLock, setIsLock] = useState(isLockDefault);

    // const doExecute = () => fn?.();
    const debounceDoExecute = useDebounce(fn, 100);

    useEffect(() => {
        if (isLoad === true && isLock === false) {
            setIsLoad(false);
            debounceDoExecute();
        }
    } ,[debounceDoExecute, isLoad, isLock]);

    const doLoad = () => setIsLoad(true);
    const debounceDoLoad = useDebounce(doLoad, waitTime);

    const result = {
        // грузить данные (очередь)
        load: (isWait = true) => isWait ? debounceDoLoad() : doLoad(),
        // отменить загрузку данных (очередь)
        clearLoad: () => setIsLoad(false),

        // заблокировать загрузку
        lock: () => {
            debounceDoExecute.clear();
            setIsLock(true);
        },
        // разблокировать загрузку
        unLock: () => setIsLock(false),
        // блокировка
        setLock: (isLock) => setIsLock(isLock),
    };

    // методы
    return result;
};

export default useLoadPolygonLayer;