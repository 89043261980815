import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

import VehicleCategoriesFields from './VehicleCategoriesFields';

const ModalForm = ({ isOpen, onClose, isNew, item, reloadList, createVehicleCategory, editVehicleCategory, fromService }) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const [isDisabled, setIsDisabled] = useState();

    const [formData, setFormData] = useState({
        name: item?.name || '',
        slug: item?.slug || '',
        color: item?.color || '#cccccc',
        color_not_in_registry: item?.color_not_in_registry || '#ffffff',
        image: item?.image || '',
        image_with_work_order: item?.image_with_work_order || '',
        station_name: item?.station_name || ''
    });

    const onChange = (name, value) => {
        validation.deleteKey(name);
        setFormData({ ...formData, [name]: value });
    };

    const onSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        dispatch(isNew
            ? createVehicleCategory(formData, callback)
            : editVehicleCategory(item?.id, formData, callback)
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onSave,
                        disabled: isDisabled
                    }
                ]}
            />}
            noPadding
        >
            <form className="modal__form">
                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                >
                    <TextField
                        value={formData.name}
                        name="name"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        label="Название"
                        variant="outlined"
                        size="small"
                        required
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('slug')}
                    helperText={validation.get('slug')}
                >
                    <TextField
                        value={formData.slug}
                        name="slug"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        label="Название на английском"
                        variant="outlined"
                        size="small"
                        required
                    />
                </FormInfoWrapper>
                {fromService === 'passengerTransport'
                    && <FormInfoWrapper
                        className="block"
                        error={validation.isKey('station_name')}
                        helperText={validation.get('station_name')}
                    >
                        <TextField
                            value={formData.station_name}
                            name="station_name"
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            label="Тип остановки"
                            variant="outlined"
                            size="small"
                            required
                            placeholder="например «Автобусная»"
                        />
                    </FormInfoWrapper>
                }


                <VehicleCategoriesFields data={formData} onChange={onChange} setIsDisabled={setIsDisabled} fromService={fromService} />
            </form>
        </Modal>
    );
};

export default ModalForm;
