import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

const LegendsList = ({ types = [] }) => {

    return (
        <div className="sidebar-tp-legend">
            <div className="item">
                <div className="title">Типы ДТП</div>
                <div className="item">
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}
                    >
                        {types.map(({ name, color }, index) => (
                            <div
                                key={index}
                                className="flex"
                                style={{
                                    width: '150px',
                                    alignItems: 'start',
                                }}
                            >
                                <div
                                    className="image"
                                    style={{
                                        alignItems: 'top',
                                    }}
                                >
                                    <DirectionsCarIcon style={{ color: color }}/>
                                </div>
                                <div className="small">
                                    - {name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LegendsList;
