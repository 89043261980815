import { TextField } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import titles from '../../../../../helpers/constants/titles';
import { useValidation } from '../../../../../helpers/hooks';
import { createExclusiveFile, editExclusiveFile } from '../../../../../redux/DorisControl/actions';
import SelectPddViolation from '../../../../common/Autocomplete/DorisControl/PddViolation';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import Modal from '../../../../common/Modal';
import UploadFileField from '../../../../common/Upload/UploadFileField';

const ModalForm = ({ isNew, isOpen, onClose, item, loadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initState = {
        pdd_violations: [],
        pdd_ids: [],
        file: null
    };

    const [data, setData] = useState(isNew ? initState : item);

    const handleSave = () => {
        const prepareData = {
            file: data.file,
            pdd_ids: data?.pdd_violations.map(({ id }) => id)
        };

        const callback = () => {
            onClose();
            loadList();
        };

        isNew
            ? dispatch(createExclusiveFile(prepareData, callback))
            : dispatch(editExclusiveFile(item.id, prepareData, callback));
    };

    const isDisabled = !data?.pdd_violations.length || !(data.file || data.file_url);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            noPadding
            small
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <UploadFileField
                    file={data?.file || data?.file_url}
                    onChange={(file) => setData((prevData) => ({ ...prevData, file }))}
                    required
                />
                <SelectPddViolation
                    multiple
                    required
                    selected={data?.pdd_violations || []}
                    onChange={(value) => setData((prevData) => ({ ...prevData, pdd_violations: value }))}
                    error={validation.isKey('pdd_ids') || !data?.pdd_violations.length}
                    helperText={validation.get('pdd_ids')}
                />
            </form>
        </Modal>
    );
};

export default ModalForm;
