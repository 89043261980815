import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import {
    createElectricalOpticalSystems,
    editElectricalOpticalSystems,
    loadVideoCamerasDictionaryElectricalOptionalSystem
} from 'redux/RoadNetwork/actions';
import { roadNetworksSelectors } from 'redux/RoadNetwork';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import MapDragMarker from 'components/common/Location/MapDragMarker';

import type { ElectricalOpticalSystemItem } from 'types';

export interface ModalFormProps {
    isOpen: boolean
    onClose: () => void
    reloadList: () => void
    isNew?: boolean
    item?: ElectricalOpticalSystemItem
}
const ModalForm = ({ isOpen, isNew, onClose, reloadList, item }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingButton = useSelector(roadNetworksSelectors.loadingElectricalOpticalSystems);
    const types = useStoreProp(
        loadVideoCamerasDictionaryElectricalOptionalSystem,
        'roadNetworks',
        'videoCamerasDictionaryElectricalOptionalSystem'
    );

    const initialState: ElectricalOpticalSystemItem = {
        name: item?.name || '',
        type_id: item?.type_id,
        address: item?.address || {},
        address_text: item?.address_text || '',
        lat: item?.lat || '',
        lon: item?.lon || '',
    };

    const [data, setData] = useState(initialState);

    const handleChange = (name: string, value: string) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const onChangeCoordinates = (params: { lat: string; lon: string }) => {
        setData((prevData: ElectricalOpticalSystemItem) => ({
            ...prevData,
            ...params
        }));
    };

    const onSave = () => {
        const prepareData = removeEmptyFields(data);
        const callback = () => {
            onClose();
            reloadList();
        };

        if (isNew) {
            dispatch(createElectricalOpticalSystems(prepareData, callback));
        } else {
            dispatch(editElectricalOpticalSystems(item?.id, prepareData, callback));
        }
    };

    const isDisabled = !data.name || !data.type_id;

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            noPadding
            maxWidthProp={'md'}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onSave,
                            loading: loadingButton,
                            disabled: isDisabled
                        }
                    ]}
                />
            }
        >
            <form className="modal__form">
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={6}>
                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('name')}
                            helperText={validation.get('name')}
                        >
                            <TextField
                                value={data.name}
                                name="name"
                                onChange={(e) => handleChange('name', e.target.value)}
                                label={titles.NAME}
                                variant="outlined"
                                size="small"
                                required={true}
                            />
                        </FormInfoWrapper>
                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('type_id')}
                            helperText={validation.get('type_id')}
                        >
                            <FormControl
                                size="small"
                                variant="outlined"
                                required
                            >
                                <InputLabel>{titles.TYPE}</InputLabel>
                                <Select
                                    value={data.type_id || ''}
                                    name="type_id"
                                    onChange={(e) => handleChange('type_id', e.target.value)}
                                    label={titles.TYPE}
                                >
                                    {Object.keys(types).map((key) => (
                                        <MenuItem key={key} value={key}>{types[key]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormInfoWrapper>
                        <FormControl className="block" variant="outlined">
                            <LatLonCoordinates
                                {...data}
                                onChange={onChangeCoordinates}
                                required
                            />
                        </FormControl>
                        <FormControl className="block" variant="outlined">
                            <LoadAddressByCoords
                                {...data}
                                onChange={onChangeCoordinates}
                                isLoadOnLatLon
                            >
                                <AddressList required returnGeometry={undefined} />
                            </LoadAddressByCoords>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl className="block" variant="outlined">
                            <MapDragMarker
                                {...data}
                                onChange={onChangeCoordinates}
                                required
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </Modal>
    );
};

export default memo(ModalForm);
