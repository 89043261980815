import { Children } from 'react';
import { LSContainer } from '../../List';
import titles from 'helpers/constants/titles';

const VehicleListHeader = ({ children }) => {
    const arrayChildren = Children.toArray(children);
    return (
        <LSContainer
            headers={[
                { title: '№', width: '15%' },
                { title: 'Марка', width: '15%' },
                { title: 'Модель', width: '20%' },
                { title: 'Средняя скорость', width: '20%' },
                { title: titles.TYPE_OF_VEHICLE, width: '20%' },
                { title: 'Действия', align: 'right', isActions: true }
            ]}>

            {arrayChildren}
        </LSContainer>
    );
};

export default VehicleListHeader;
