export const reports = (state) => state.reports;

export const scheduledReport = (state) => reports(state).scheduledReport;
export const loadingScheduledReport = (state) => reports(state).loadingScheduledReport;
export const loadingScheduledReportForm = (state) => reports(state).loadingScheduledReportForm;
export const scheduledReportTemplate = (state) => reports(state).scheduledReportTemplate;

export const loadingPuid = (state) => reports(state).loadingPuid;

export const reportTemplateFrontendLinks = (state) => reports(state).reportTemplateFrontendLinks;
export const loadingReportTemplateFrontendLinks = (state) => reports(state).loadingReportTemplateFrontendLinks;
export const loadingApiReportTemplateFrontendLinks = (state) => reports(state).loadingApiReportTemplateFrontendLinks;