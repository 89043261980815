import React from 'react';
import GetStoreProp from '../../../../../helpers/hooks/GetStoreProp';
import { getStatusesRW } from '../../../../../redux/RoadWorks/actions';
import messages from '../../../../../helpers/constants/messages';
import { humanDateWithoutTZ } from '../../../../../helpers/date.config';

const Item = (props) => {
    const {
        name,
        fixated_at,
        troubleshooting_plan,
        status,
    } = props;

    return (
        <>
            <div className="description">
                {name}
            </div>
            {status
                && <div className="status">
                    <GetStoreProp
                        fetchAction={getStatusesRW}
                        storeName="roadworks"
                        storeProp="statuses"
                        value={status}
                    />
                </div>
            }
            <div className="date">
                <div>
                    <div><b>Дата регистрации</b></div>
                    {fixated_at ? humanDateWithoutTZ(fixated_at) : messages.NO_DATA}
                </div>

                <div>
                    <div><b>Дата окончания плановое</b></div>
                    {troubleshooting_plan ? humanDateWithoutTZ(troubleshooting_plan) : messages.NO_DATA}
                </div>
            </div>
        </>
    );
};

export default Item;
