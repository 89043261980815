import { TextField } from '@mui/material';
import React, { useState } from 'react';
import FormButtons,{ buttonsTypes } from '../../../../../../common/FormButtons';
import Modal from '../../../../../../common/Modal';

const AddEditModal = ({
    isOpen = false, 
    onClose = () => {},
    vehicle,
    index,
    onAccept   
}) => {

    const initialState = {
        grn: vehicle?.grn || '',
        brand: vehicle?.brand || '',
        model: vehicle?.model || '',
        comment: vehicle?.comment || '',
    };

    const [data, setData] = useState(initialState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleSuccess = () => {
        onAccept && (vehicle ? onAccept(index, data) : onAccept(data));
        onClose();
        
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            small
            title={vehicle ? 'Редактировать ТС' : 'Добавить ТС'}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSuccess,
                            disabled: !data.grn || !data.brand || !data.model
                        }
                    ]}
                />
            }
        >
            <div className="modal__form">
                <div className="block">
                    <TextField
                        label="ГРЗ"
                        variant="outlined"
                        size="small"
                        className="block"
                        name="grn"
                        value={data.grn}
                        onChange={handleChange}
                        error={!data.grn}
                    />
                </div>
                <div className="block">
                    <TextField
                        label="Марка"
                        variant="outlined"
                        size="small"
                        className="block"
                        name="brand"
                        value={data.brand}
                        onChange={handleChange}
                        error={!data.brand}
                    />
                </div>
                <div className="block">
                    <TextField
                        label="Модель"
                        variant="outlined"
                        size="small"
                        className="block"
                        name="model"
                        value={data.model}
                        onChange={handleChange}
                        error={!data.model}
                    />
                </div>
                <div className="block">
                    <TextField
                        label="Комментарий"
                        variant="outlined"
                        size="small"
                        className="block"
                        name="comment"
                        value={data.comment}
                        onChange={handleChange}
                        // error={!data.comment}
                    />
                </div>
            </div>     
        </Modal>
    );
};

export default AddEditModal;
