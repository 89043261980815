import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse } from '@mui/material';

import { loadVehicleCategories } from 'redux/TransportRoad/actions';
import { loadEquipmentsList } from 'redux/TransportPassenger/actions';
import { loadCurrentCompany } from 'redux/Companies/actions';
import { companiesSelectors } from 'redux/Companies';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import useStoreProp from 'helpers/hooks/useStoreProp';
import { stringifyRenderData } from 'helpers/stringifyRenderData';

function Info ({ info, isOpen }) {
    const dispatch = useDispatch();
    const vehiclesCategories = useStoreProp(loadVehicleCategories, 'transportRoad', 'vehicleCategories');
    const characteristics = useStoreProp(loadEquipmentsList, 'transportPassenger', 'equipments');
    const currentCompany = useSelector(companiesSelectors.currentCompany);

    useEffect(() => {
        if (!currentCompany[info.organization_id]) {
            dispatch(loadCurrentCompany(info.organization_id));
        }
    }, [dispatch, currentCompany, info.organization_id]);

    const company = currentCompany[info.organization_id];
    const category = vehiclesCategories.find(el => el.id === info.category_id);

    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit className="info">
            <div>
                <b>{titles.GRZ}:</b> {info.number}
                <div><b>{titles.GARAGE_NUM}:</b> {info.garage_number}</div>
                <div><b>{titles.TYPE_OF_VEHICLE}:</b> {category?.name || ''}</div>
                <div>
                    <b>{titles.OPTIONAL_EQUIPMENT}:</b>
                    {Object.keys(info.characteristics)?.length > 0
                        ? (Object.keys(info.characteristics).map((key, index) => {
                            const equipments = typeof info.characteristics[key] == 'boolean'
                                ? info.characteristics[key] ? 'Да' : 'Нет' || messages.INFO_IS_NOT_FOUND
                                : info.characteristics[key] || messages.INFO_IS_NOT_FOUND;
                            return <div key={index}>{characteristics[key]}: {equipments}</div>;
                        }))
                        : <div>{messages.INFO_IS_NOT_FOUND}</div>
                    }
                </div>
            </div>
            {company
                && <div>
                    <h3>Организация:</h3>
                    <div className="info">
                        <p><b>{company.title}</b></p>
                        <p><b>{titles.INN}:</b> {company.inn}</p>
                        <p>
                            <b>{titles.PHONES}:</b> {stringifyRenderData(company.phones)}
                        </p>
                        <p>
                            <b>{titles.EMAIL}:</b> {stringifyRenderData(company.emails)}
                        </p>
                    </div>
                </div>
            }
        </Collapse>
    );
}

export default Info;
