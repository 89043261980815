import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadWorkorders } from 'redux/TransportPassenger/actions';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import UniversalSelect from 'components/common/UniversalSelect';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import type { Item, Workorders } from './types';


const SelectWorkorders = ({
    multiple,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label = 'Наряды',
    error = false,
    helperText = '',
    filter = {},
    renderProps,
    renderOption,
    searchParam,
    limitTags = 1
}: Workorders<Item>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);

    const handleClose = () => {
        setShowList(false);
    };

    const handleFetchList = (params: { page: number; query?: string; }) => {
        const { page, query } = params;

        dispatch(loadWorkorders(page, 10, {
            ...(searchParam 
                ? { [searchParam]: query } 
                : { date_at: dateWithDashYYYYMMDD(query) }
            ),
            ...filter,
        }));
    };

    const renderOptionLabel = (el: Item | null) => `Наряд на ${el?.date_at || ''} для ТС ${el?.vehicle?.number || ''} по маршруту №`
        + `${el?.traffic_schedule?.route_direct?.num || ''} `
        + `${el?.traffic_schedule?.route_direct?.name || ''}`;

    const renderOptionDefault = (option: Item | null) => (renderOption && renderOption(option)) 
        // @ts-ignore
        || Object.keys(selected)?.length > 0 ? renderOptionLabel(option) : '';

    const renderPropDefault = (el: Item) => (renderProps && renderProps(el)) || renderOptionLabel(el);

    const handleAccept = (data: Item | Item[]) => {
        onChange(data);
        setShowList(false);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                error={error}
                helperText={helperText}
                required={required}
                inputName="schedule_template"
                disabled={disabled}
                onReset={() => onChange(multiple ? [] : {})}
                renderLabel={(option) => renderOptionDefault(option)}
                limitTags={multiple ? limitTags : -1}
                label={label}
                onShowList={() => setShowList(true)}
                isOpen={showList}
            />
            {showList && (
                <UniversalSelect
                    multiple={multiple}
                    storeName="transportPassenger"
                    storeNameProps="workorders"
                    storeLoadingProps="loadingWorkorders"
                    // visibleProp="date_at"
                    renderProps={renderPropDefault}
                    fetchList={handleFetchList}
                    onAccept={handleAccept}
                    keyProp={'id'}
                    withSearch
                    {...(!searchParam && { renderSearch: (props) => (
                        <SingleKeyboardDateTimePicker
                            dateOnly
                            {...props}
                            value={new Date(props.value)}
                            onChange={(value) => props.onChange(value)}
                        />) 
                    })}
                    isSelected
                    selected={selected}
                    title={label}
                    onClose={handleClose}
                    isOpen={showList}
                    noPadding
                    small
                />
            )}
        </>
    );
};

export default SelectWorkorders;