import {
    editSettingsGroup,
    editSettingsList,
    loadSettingsGroup,
} from '../../../../../redux/TransportPassenger/actions';
import Settings from '../../../../common/Settings';

const TransportSettings = () => {
    return (
        <Settings
            title="Настройки – Пассажирский транспорт"
            storeProp="transportPassenger"
            listName="settingsGroup"
            loadingName="loadingSettingsGroup"
            loadSettingsGroupAction={loadSettingsGroup}
            editSettingsGroupAction={editSettingsGroup}
            editSettingsListAction={editSettingsList}
        />
    );
};

export default TransportSettings;