import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
    TextField,
    InputAdornment,
    IconButton,
} from '@mui/material/';
import { Autocomplete } from '@mui/material';
import { loadComplexEntityTypes, 
    loadComplexEntities } from '../../../../../redux/DorisControl/actions';
import FormButtons, { buttonsTypes } from '../../../FormButtons';
import UniversalSelect from '../../../UniversalSelect';
import Modal from '../../../Modal';
import titles from '../../../../../helpers/constants/titles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import useStoreProp from '../../../../../helpers/hooks/useStoreProp';
import messages from '../../../../../helpers/constants/messages';


const useStyles = makeStyles({
    input: {
        paddingRight: '60px !important',
    },
    adornment: {
        position: 'absolute',
        right: '6px',
    },
});

const SelectComplexEntities = ({
    multiple = false,
    selected = multiple ? [] : '',
    onChange = () => {},
    error = false,
    helperText = '',
    required = false,
    disabled = false,
    label = 'Неисправное оборудование',
    filter = {},
    sortedList,
}) => {
    const dispatch = useDispatch();
    const styles = useStyles();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);

    const types = useStoreProp(loadComplexEntityTypes, 'dorisControl', 'entities_types');

    useEffect(() => {
        // например сброс фильтра - selected изменился нужно обновить selectedItems
        if (!_.isEqual(prevData.current, selected)) { 
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleClose = () => {
        setSelected(selected);
        setShowList(false);
    };

    const handleAccept = () => {
        onChange(selectedItems);
        setShowList(false);
    };

    const autoCompleteChange = (e, value) => {
        if (e?.key !== 'Enter') onChange(value);
    };


    const getList = (params) => {
        const { page, limit, query: name } = params;
        dispatch(loadComplexEntities(page, limit, {
            ...(name && { name }),
            ...filter,
        }));
    };

    const handleChange = (el) => {
        if(multiple) {
            const findIndex = selectedItems.findIndex((item) => item.id === el.id);
            if (findIndex < 0) {
                setSelected([
                    ...selectedItems,
                    el
                ]);
            } else {
                setSelected(selectedItems.filter((item) => item.id !== el.id));
            }
        } else {
            setSelected(el);
        }
    };
    const renderEntityOption = (item) => {
        return  `${types?.[item.entity_id]?.name}${item?.serial ? `, Серийный номер: ${item?.serial}` : ''}${item?.name ? `, Название: ${item?.name}` : ''}`;
    };
    return <>
        <Autocomplete
            multiple={multiple}
            value={selected}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={[]}
            disabled={!multiple}
            filterSelectedOptions
            getOptionLabel={(option) => renderEntityOption(option)}
            onChange={autoCompleteChange}
            freeSolo
            size="small"
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    helperText = {helperText}
                    size="small"
                    variant="outlined"
                    required={required}
                    name="entities"
                    label={label}
                    disabled={!multiple}
                    InputProps={{
                        ...params.InputProps,
                        classes: { root: styles.input },
                        endAdornment: (
                            <InputAdornment position={'end'} classes={{ root: styles.adornment }}>
                                {selected && Object.keys(selected).length > 0 
                                        && <IconButton size="small" onClick={() => onChange(multiple ? [] : {})}>
                                            <i className="far fa-times"/>
                                        </IconButton>
                                }
                                <IconButton disabled={disabled} size="small" onClick={() => setShowList(true)}>
                                    <i className="fas fa-search-plus"/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            )}
        />
        {showList
        && <Modal
            isOpen={showList}
            onClose={() => showList(false)}
            noPadding
            title={titles.SELECT_COMPLEX}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: handleClose
                        },
                        {
                            ...buttonsTypes.select,
                            onClick: handleAccept
                        },
                    ]}
                />
            }
        >
            <UniversalSelect
                multiple={multiple}
                fetchList={!sortedList && getList}
                sortedList={sortedList}
                onChange={handleChange}
                storeName="dorisControl"
                storeNameProps="entities"
                keyProp="id"
                withSearch={!sortedList}
                isSelected
                selected={selectedItems}
                renderProps={(el) => <div>
                    <div>
                        <strong>Тип:</strong> {types?.[el.entity_id]?.name}
                    </div>
                    <div>
                        <strong>Название:</strong> {el.name || messages.NO_DATA}
                    </div>
                    <div>
                        <strong>Серийный номер:</strong> {el?.serial || messages.NO_DATA}
                    </div>
                </div>}
            />
        </Modal>
        }
    </>;
};

export default SelectComplexEntities;