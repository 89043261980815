const dorisTitles = {
    NOTIFICATION_SETTINGS: 'Настройки уведомлений',
    UNCOMING_FILTERS: 'Исходящие фильтры',
    BASIC_DATA: 'Основные данные',
    UNLOADING: 'Выгрузка',
    STATE_MONITORING: 'Мониторинг состояния',
    TAG: 'Тег',
    ADD_TAG: 'Добавить тег',
    DATA_SOURCE: 'Источник данных',
};

export default dorisTitles;
