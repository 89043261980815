import { config } from '../../config';

const apiSituationalPlans = () => config.get('urls').apiSituationalPlans;

const apiUrls = {

    getTransportIncidentTypes:  () => `${apiSituationalPlans()}/transport-incident/type/`,

    createTransportIncidentType:  () => `${apiSituationalPlans()}/transport-incident/type/`,

    editTransportIncidentType:  (id) => `${apiSituationalPlans()}/transport-incident/type/${id}`,

    deleteTransportIncidentType:  (id) => `${apiSituationalPlans()}/transport-incident/type/${id}`,

    getEvents:  () => `${apiSituationalPlans()}/event/`,

    createEvent:  () => `${apiSituationalPlans()}/event/`,

    editEvent:  (id) => `${apiSituationalPlans()}/event/${id}`,

    deleteEvent:  (id) => `${apiSituationalPlans()}/event/${id}`,

    getKeyWordList: () => `${apiSituationalPlans()}/keyword`,

    editKeyWord: (id) => `${apiSituationalPlans()}/keyword/${id}`,

    addKeyWord: () => `${apiSituationalPlans()}/keyword`,

    deleteKeyWord: (id) => `${apiSituationalPlans()}/keyword/${id}`,

    //Получение списка угроз и рисков
    getDangersList: () => `${apiSituationalPlans()}/category`,
    //Создание сущности угроз и риска
    postDanger: () => `${apiSituationalPlans()}/category/`,
    //Редактирование сущности угроз и риска
    editDanger: (id) => `${apiSituationalPlans()}/category/${id}`,
    //Удаление сущности угроз и риска
    deleteDanger: (id) => `${apiSituationalPlans()}/category/${id}`,

    getThreatLevels:  () => `${apiSituationalPlans()}/transport-incident/threat-level/`,

    createThreatLevel:  () => `${apiSituationalPlans()}/transport-incident/threat-level/`,

    editThreatLevel:  (id) => `${apiSituationalPlans()}/transport-incident/threat-level/${id}`,

    deleteThreatLevel:  (id) => `${apiSituationalPlans()}/transport-incident/threat-level/${id}`,

    getTypicalOperations:  () => `${apiSituationalPlans()}/operation/typical`,

    createTypicalOperation:  () => `${apiSituationalPlans()}/operation/typical`,

    editTypicalOperation:  (id) => `${apiSituationalPlans()}/operation/${id}`,

    deleteTypicalOperation:  (id) => `${apiSituationalPlans()}/operation/${id}`,

    getResponseScenarios:  () => `${apiSituationalPlans()}/response-scenario/`,

    getIncidentOperations:  (id) => `${apiSituationalPlans()}/transport-incident/${id}/operations`,

    createResponseScenario:  () => `${apiSituationalPlans()}/response-scenario`,

    editResponseScenario:  (id) => `${apiSituationalPlans()}/response-scenario/${id}`,

    deleteResponseScenario:  (id) => `${apiSituationalPlans()}/response-scenario/${id}`,

    getEventObjects:  () => `${apiSituationalPlans()}/event-object/`,

    createEventObjects:  () => `${apiSituationalPlans()}/event-object/`,

    editEventObjects:  (id) => `${apiSituationalPlans()}/event-object/${id}`,

    deleteEventObjects:  (id) => `${apiSituationalPlans()}/event-object/${id}`,

    getEventObjectsTypes: () => `${apiSituationalPlans()}/event-object/type/`,

    getCurrentEvent: (id) => `${apiSituationalPlans()}/event/${id}`,

    getCurrentKeyword: (id) => `${apiSituationalPlans()}/keyword/${id}`,

    getTransportIncidents:  () => `${apiSituationalPlans()}/transport-incident/`,

    createTransportIncident:  () => `${apiSituationalPlans()}/transport-incident`,

    editTransportIncident:  (id) => `${apiSituationalPlans()}/transport-incident/${id}`,

    deleteTransportIncident:  (id) => `${apiSituationalPlans()}/transport-incident/${id}`,

    getTransportIncidentStatuses: () => `${apiSituationalPlans()}/transport-incident/status/`,

    getCurrentResponseScenario: (id) => `${apiSituationalPlans()}/response-scenario/${id}`,

    getCurrentTransportIncidentType: (id) => `${apiSituationalPlans()}/transport-incident/type/${id}`,

    getCurrentThreatLevel: (id) => `${apiSituationalPlans()}/transport-incident/threat-level/${id}`,

    getCurrentCategory: (id) => `${apiSituationalPlans()}/category/${id}`,

    getCurrentEventObject: (id) => `${apiSituationalPlans()}/event-object/${id}`,

    getArrayOfKeyword: () => `${apiSituationalPlans()}/keyword/`,

    getPolygon: () => `${apiSituationalPlans()}/transport-incident/polygon`,

    getSidebarList: () => `${apiSituationalPlans()}/transport-incident`,

    getReportTransportIncidents:  () => `${apiSituationalPlans()}/transport-incident/report`,

    getReportTransportIncidentsResolutionTime:  () => `${apiSituationalPlans()}/transport-incident/report_resolve`,

    getPolygonEvents: () => `${apiSituationalPlans()}/event-object/polygon`,

    getTransportIncidentHistory: (id) => `${apiSituationalPlans()}/transport-incident/${id}/history/human`,

    getTransportIncidentTypeCreation: () => `${apiSituationalPlans()}/transport-incident/type_creation`,

    getTransportIncident:  (id) => `${apiSituationalPlans()}/transport-incident/${id}`,

    loadInfopanel: (id) => `${apiSituationalPlans()}/transport-incident/info_panel`,

    loadDistictsByMonth: (id) => `${apiSituationalPlans()}/transport-incident/month-areas`,

    getTransportIncidentModal:  (id) => `${apiSituationalPlans()}/transport-incident/${id}`,

    editSettingsList: () => `${apiSituationalPlans()}/settings/list`,

    getSettingsGroup: () => `${apiSituationalPlans()}/settings_group`,

    editSettingsGroup: (id) => `${apiSituationalPlans()}/settings_group/${id}/settings/update`,

    loadShowcase: () => `${apiSituationalPlans()}/showcase`,

    loadImagesLegendsSaga: () => `${apiSituationalPlans()}/transport-incident/type`,

    loadReportByEventPlans: () => `${apiSituationalPlans()}/transport-incident/report-by-event-plans`,
};

export default apiUrls;
