import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setValidation } from 'redux/Validation/actions';
import { setMapLoading } from 'redux/Map/actions';
import { showMessage } from 'redux/Message/actions';
import messagetypes from 'redux/Message/messagetypes';
import messages from 'helpers/constants/messages';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

// данные
function* getDeviceDataEcologySaga({ payload: params }) {
    yield put(actions.loading(true));
    const response = yield call(api.getDeviceData, params);
    if (response) {
        yield put(actions.saveDeviceDataEcology(response));
    }
    yield put(actions.loading(false));
}

// события
function* getEventsEcologySaga({ payload, replaceStore }) {
    const { external_id, page, limit } = payload;
    yield put(actions.loading(true));
    const response = yield call(api.getEvents, external_id, page, limit);
    if (response) {
        yield put(actions.saveEventsEcology(response, replaceStore));
    }
    yield put(actions.loading(false));
}

// проекты
function* getProjectsEcologySaga() {
    const response = yield call(api.getProjects);
    if (response?.data) {
        yield put(actions.saveProjectsEcology(response.data));
    }
}

// подгрузить типы
function* getDeviceTypesEcologySaga() {
    const response = yield call(api.getDeviceTypes);
    if (response?.data) {
        yield put(actions.saveDeviceTypesEcology(response.data));
    }
}

// подгрузить статусы
function* getStatusesEcology() {
    const response = yield call(api.getStatuses);
    if (response?.data) {
        yield put(actions.saveStatusesEcology(response.data));
    }
}

// подгрузить полигон
function* getForPolygonEcologySaga({ payload: params }) {
    yield put(setMapLoading('ecology', true));
    const response = yield call(api.getForPolygonDevices, params);
    if (response) {
        yield put(actions.saveForPolygonEcology(response));
    }
    yield put(setMapLoading('ecology', false));
}

function* getDeviceListEcologySaga({ payload, isReplace }) {
    yield put(actions.deviceListLoading(true));

    const response = yield call(api.getDeviceList, payload);
    if (response) {
        yield put(actions.saveDeviceListEcology(response, isReplace));
    }
    yield put(actions.deviceListLoading(false));
}

// настройки
function* loadEcologySettingsSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingEcologySettings(true));
    const response = yield call(api.getEcologySettings, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedEcologySettings(response));
    }
    yield put(actions.loadingEcologySettings(false));
}

function* editEcologySettingsSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.editEcologySettings, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield put(actions.loadEcologySettings());
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

function* editSettingsListSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.editSettingsList, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }

    yield put(actions.loadingButton(false));
}

// связи с организациями
function* getDeviceCompanyLinkSaga({ payload }) {
    yield put(actions.loading(true));
    const response = yield call(api.getDeviceCompanyLink, payload);
    if (response) {
        yield put(actions.saveDeviceCompanyLink(response.data));
    }
    yield put(actions.loading(false));
}

function* createDeviceCompanyLinkSaga({ payload }) {
    const { id, sendData, callback } = payload;
    const response = yield call(api.createDeviceCompanyLink, id, sendData);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

function* deleteDeviceCompanyLinkSaga({ payload }) {
    const { id, callback } = payload;
    const response = yield call(api.deleteDeviceCompanyLink, id);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
    }
}

// подгрузить оповещении
function* loadEcologyAlertsSaga() {
    const response = yield call(api.getAlerts);
    if (response?.data) {
        yield put(actions.loadedEcologyAlerts(response.data));
    }
}

// настройки вывода параметров
function* loadEcologyOutputParamsSaga() {
    const response = yield call(api.getEcologyOutputParams);
    if (response?.data) {
        yield put(actions.loadedEcologyOutputParams(response));
    }
}

function* loadEcologyOutputSettingsSaga() {
    yield put(actions.loadingEcologyOutputSettings(true));
    const response = yield call(api.getEcologyOutputSettings);
    if (response?.data) {
        yield put(actions.loadedEcologyOutputSettings(response.data));
    }
    yield put(actions.loadingEcologyOutputSettings(false));
}

function* editEcologyOutputSettingsSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editEcologyOutputSettings, id, data);
    if (response?.success) {
        callback?.();
        yield put(actions.loadEcologyOutputSettings());
        yield put(showMessage(messagetypes.success, messages.EDIT_SUCCESS));
    }
}

function* deleteEcologyOutputSettingsSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.deleteEcologyOutputSettings, id, data);
    if (response?.success) {
        callback?.();
        yield put(actions.loadEcologyOutputSettings());
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
    }
}

function* loadDevicesGroupSaga({ payload }) {
    yield put(actions.loadingDevicesGroup(true));
    const response = yield call(api.getDevicesGroup, payload);
    if (response?.success) {
        yield put(actions.loadedDevicesGroup(response.data));
    }
    yield put(actions.loadingDevicesGroup(false));
}

function* createDevicesGroupSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createDevicesGroup, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.SAVE_SUCCESS));
        yield callback();
    }
    yield put(actions.loadingButton(false));
}

function* editDevicesGroupSaga({ payload, callback }) {
    const { id, params } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.editDevicesGroup, id, params);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.EDIT_SUCCESS));
        yield callback();
    }
    yield put(actions.loadingButton(false));
}

function* deleteDevicesGroupSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deleteDevicesGroup, payload);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, messages.DELETE_SUCCESS));
        yield callback();
    }
    yield put(actions.loadingButton(false));
}

function* loadShowcaseSaga() {
    yield put(actions.loadingShowcase(true));
    const response = yield call(api.loadShowcase);
    if (response?.success) {
        yield put(actions.loadedShowcase(response.data));
    }
    yield put(actions.loadingShowcase(false));
}

function* loadEcoByRadiusSaga({ payload, callback }) {
    const response = yield call(api.loadEcoByRadius, payload);
    if (response?.success) {
        yield put(actions.loadedEcoByRadius(response.data));
        callback(response.data);
    }
}

// settings group
function* loadSettingsGroupSaga() {
    yield put(actions.loadingSettingsGroup(true));
    const response = yield call(api.getSettingsGroup);
    if (response?.success) {
        yield put(actions.loadedSettingsGroup(response));
    }
    yield put(actions.loadingSettingsGroup(false));
}

function* editSettingsGroupSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.editSettingsGroup, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}


function* getDeviceStatusesSaga() {
    const response = yield call(api.getDeviceStatuses);

    if (response?.success) {
        yield put(actions.loadedDeviceStatuses(response.data));
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.GET_DEVICE_DATA, getDeviceDataEcologySaga),
        takeLatest(types.GET_EVENTS, getEventsEcologySaga),
        takeLatest(types.GET_PROJECTS, getProjectsEcologySaga),
        takeLatest(types.GET_TYPES, getDeviceTypesEcologySaga),
        takeLatest(types.GET_STATUSES, getStatusesEcology),
        takeLatest(types.GET_POLYGON, getForPolygonEcologySaga),
        takeLatest(types.GET_LIST, getDeviceListEcologySaga),
        takeLatest(types.LOAD_ECOLOGY_SETTINGS, loadEcologySettingsSaga),
        takeLatest(types.EDIT_ECOLOGY_SETTINGS, editEcologySettingsSaga),
        takeLatest(types.EDIT_SETTINGS_LIST, editSettingsListSaga),
        takeLatest(types.GET_DEVICE_COMPANY_LINK, getDeviceCompanyLinkSaga),
        takeLatest(types.CREATE_DEVICE_COMPANY_LINK, createDeviceCompanyLinkSaga),
        takeLatest(types.DELETE_DEVICE_COMPANY_LINK, deleteDeviceCompanyLinkSaga),
        takeLatest(types.LOAD_ECOLOGY_ALERTS, loadEcologyAlertsSaga),
        takeLatest(types.LOAD_ECOLOGY_OUTPUT_PARAMS, loadEcologyOutputParamsSaga),
        takeLatest(types.LOAD_ECOLOGY_OUTPUT_SETTINGS, loadEcologyOutputSettingsSaga),
        takeLatest(types.EDIT_ECOLOGY_OUTPUT_SETTINGS, editEcologyOutputSettingsSaga),
        takeLatest(types.DELETE_ECOLOGY_OUTPUT_SETTINGS, deleteEcologyOutputSettingsSaga),

        takeLatest(types.LOAD_DEVICES_GROUP, loadDevicesGroupSaga),
        takeLatest(types.CREATE_DEVICES_GROUP, createDevicesGroupSaga),
        takeLatest(types.EDIT_DEVICES_GROUP, editDevicesGroupSaga),
        takeLatest(types.DELETE_DEVICES_GROUP, deleteDevicesGroupSaga),
        takeLatest(types.LOAD_SHOWCASE, loadShowcaseSaga),

        takeLatest(types.LOAD_ECO_BY_RADIUS, loadEcoByRadiusSaga),

        takeLatest(types.LOAD_SETTINGS_GROUP, loadSettingsGroupSaga),
        takeLatest(types.EDIT_SETTINGS_GROUP, editSettingsGroupSaga),

        takeLatest(types.LOAD_DEVICE_STATUSES, getDeviceStatusesSaga),
    ]);
}
