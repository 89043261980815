import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import config from '../../../../../MapComponents/Layers/Incidents/config';
import { Marker, PopUp } from '../../../../../MapComponents/leaflet';
import mapHelper from '../../../../../../helpers/mapHelper';
import { useDebounce, usePrevious } from '../../../../../../helpers/hooks';
import ClusterInfo from './ClusterInfo';
import createIcon from './icon/CreateIcon';
import useReportByCategory from '../../../../../../helpers/hooks/Incidents/useReportByCategory';
import { fullDateTimeWithTimeZone } from '../../../../../../helpers/date.config';
import removeEmptyFields from '../../../../../../helpers/removeEmptyFields';
import { isEqual } from 'lodash';
import { incidentsSelectors } from '../../../../../../redux/Incidents';


const DtpMap = (props) => {
    const { map } = props;

    const reportCategoryFilter = useSelector(incidentsSelectors.reportCategoryFilter);
    const prevFilters = usePrevious(reportCategoryFilter);
    const reportProvider = useReportByCategory();

    useEffect(() => {
        if (reportProvider.isNewData) {
            map.fire('refreshClusters');
        }
    }, [reportProvider.data]);

    // грузим данные
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);

        const result = {
            start_date: fullDateTimeWithTimeZone(reportCategoryFilter.start_date),
            end_date: fullDateTimeWithTimeZone(reportCategoryFilter.end_date),
            category_list: reportCategoryFilter.category_list.map(({ id }) => id),
        };

        reportProvider.load({
            polygon,
            ...removeEmptyFields(result)
        });
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();

    useEffect(() => {
        handleFetchPolygon();

        return () => {
            reportProvider.clear();
        };
    }, []);

    useEffect(() => {
        // if (!isEqual(reportCategoryFilter, prevFilters)) {
        debounceFetchPolygon();
        // }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [reportCategoryFilter]);

    useEffect(() => {
        if (!isEqual(reportCategoryFilter, prevFilters)) {
            map.fire('close_popup_all');
        }
    }, [reportCategoryFilter, prevFilters]);

    return (
        <div>
            {
                reportProvider?.data?.map((item) => {
                    const { id, lat, lon } = item;

                    return item?.categories?.map((category) => {
                        const {
                            dtp_category,
                            count,
                            color,
                        } = category;

                        // для проверки маркера
                        // if (color !== '#FF0000') return null;

                        const componentProps = {
                            ...props,
                            key: `${config.slug}_${id}_${dtp_category}`,
                            // для кластера
                            attribution: {
                                id,
                                color: color,
                                count,
                            },
                            onClick: (latlng) => {
                                map.setView(latlng);
                            },
                        };

                        const popUpProps = {
                            minWidth: 750
                        };

                        return (
                            <Marker
                                {...componentProps}
                                latlng={[lat, lon]}
                                icon={createIcon(1, { [color]: 100 })}
                                onClick={(latlng) => {
                                    map.setView(latlng);
                                }}
                            >
                                <PopUp {...popUpProps}>
                                    <ClusterInfo
                                        id={id}
                                    />
                                </PopUp>
                            </Marker>
                        );
                    });
                })
            }
        </div>
    );
};

export default memo(DtpMap);