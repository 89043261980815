import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const apiCardWantedMethods = {
    getTrackingList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getTrackingList(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },
    getTrackingMoreInfo: async (page, limit, id) => {
        const response = await instance.get(apiUrls.getTrackingMoreInfo(id),{
            params: { page, limit }
        });
        return response?.data;
    },
    getTrackingDeployedInfo: async (id, grz) => {
        const response = await instance.get(apiUrls.getTrackingDeployedInfo(id, grz));
        return response?.data;
    },
    getTrackingStatuses: async () => {
        const response = await instance.get(apiUrls.getTrackingStatuses());
        return response?.data;
    },
    loadWantedList: async (params) => {
        const response = await instance.get(apiUrls.loadWantedList(), {
            params
        });
        return response?.data;
    },
    createWanted: async (data) => {
        const response = await instance.post(apiUrls.createWanted(), data);
        return response?.data;
    },
    editWanted: async (id, data) => {
        const response = await instance.put(apiUrls.editWanted(id), data);
        return response?.data;
    },
    deleteWanted: async (id) => {
        const response = await instance.delete(apiUrls.deleteWanted(id));
        return response?.data;
    },
    loadTypesBase: async () => {
        const response = await instance.get(apiUrls.loadTypesBase());
        return response?.data;
    },
    createTracking: async (data) => {
        const response = await instance.post(apiUrls.createTracking(), data);
        return response?.data;
    },
    editTracking: async (id, data) => {
        const response = await instance.put(apiUrls.editTracking(id), data);
        return response?.data;
    },
    deleteTracking: async (id) => {
        const response = await instance.delete(apiUrls.deleteTracking(id));
        return response?.data;
    },
    stopWanted: async (id, reason_stop) => {
        const response = await instance.post(apiUrls.stopWanted(id), { reason_stop });
        return response?.data;
    },
    loadWantedEvents: async (data) => {
        const response = await instance.get(apiUrls.loadWantedEvents(), {
            params: { ...data }
        });
        return response?.data;
    },
    getVehicleInfo: async () => {
        const response = await instance.get(apiUrls.getVehicleInfo());
        return response?.data;
    },
    eventWantedTrack: async (params = {}) => {
        const response = await instance.post(apiUrls.eventWantedTrack(), params);
        return response?.data;
    },
};

export default apiCardWantedMethods;
