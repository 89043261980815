import MapGeoJson from '../../../../../../../../common/Location/MapGeoJson';
import FieldsModal from '../../../../../../../../common/Location/FieldsModal';
import { ChangedItem } from '../../../../types';

interface MapProps {
    type: 'new' | 'old'
    item: ChangedItem;
}

const ChangedRouteOnMap = ({ type, item }: MapProps) => {
    return (
        <FieldsModal
            title={type === 'new' ? 'Новое значение' : 'Старое значение'}
            buttonText="Посмотреть на карте"
            fields={{ geometry: item?.[type]?.geometry || {} }}
            onChange={() => {}}
            fullWidth={false}
            iconButton={false}
            hideButtonAccept={true}
        >
            <MapGeoJson readOnly/>
        </FieldsModal>
    );
};

export default ChangedRouteOnMap;