import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roadNetworksSelectors } from 'redux/RoadNetwork';
import { loadElectricalOpticalSystems } from 'redux/RoadNetwork/actions';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import type { ElectricalOpticalSystemItem } from 'types';
import Filter from './Filter';
import ModalForm from './ModalForm';
import Item from './Item';
import { FilterParams } from './types';

const ElectricalOpticalSystems = () => {
    const dispatch = useDispatch();

    const data = useSelector(roadNetworksSelectors.electricalOpticalSystemsData);
    const meta = useSelector(roadNetworksSelectors.electricalOpticalSystemsMeta);
    const loading = useSelector(roadNetworksSelectors.loadingElectricalOpticalSystems);

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [limit, setLimit] = useState<number>(Number(localStorage.getItem('limit')) || 25);

    const [params, setParams] = useState<FilterParams>({
        page: 1,
        data: {}
    });

    const reloadList = useCallback(() => {
        dispatch(loadElectricalOpticalSystems(params.page, limit, params.data));
    }, [dispatch, params, limit]);

    useEffect(() => {
        reloadList();
    }, [params, limit, reloadList]);

    const renderContent = useCallback(() => {
        if (data?.length > 0) {
            return (
                <LSContainer
                    headers={[
                        { title: titles.NAME, width: '20%' },
                        { title: titles.TYPE, width: '20%' },
                        { title: 'Адрес', width: '40%' },
                        { title: 'Действия', isActions: true }
                    ]}
                >
                    {data.map((el: ElectricalOpticalSystemItem ) =>
                        <Item
                            key={el.id}
                            el={el}
                            reloadList={reloadList}
                        />
                    )}
                </LSContainer>
            );
        } else {
            return !loading && <div>{messages.DATA_IS_NOT_FOUND}</div>;
        }
    }, [data, loading, reloadList]);

    return (
        <>
            <PageLayout
                header="Компоненты электрических и оптических систем"
                filters={<Filter setParams={setParams}/>}
                loading={loading}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page: number) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />
            {openModal && (
                <ModalForm
                    isNew
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default React.memo(ElectricalOpticalSystems);
