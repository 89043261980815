import React, { useEffect, useMemo, useState } from 'react';
import {
    Fade,
    IconButton,
    Paper,
    Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';
import { useSelector } from 'react-redux';

import { menuSelectors } from 'redux/Menu';


const MapLegends = ({
    layer,
    isVisible = true,
    visibleRequired = false,
    children,
}) => {
    const currentLayer = useSelector(menuSelectors.selectedLayer || '');

    const [showLegends, setShowLegends] = useState(false);
    const [visibleLegends, setVisibleLegends] = useState(false);

    useEffect(() => {
        if ((layer === currentLayer || visibleRequired) && isVisible) {
            setTimeout(() => setVisibleLegends(true), 1000);
            return () => setVisibleLegends(false);
        }
    }, [layer, currentLayer, isVisible, visibleRequired]);

    return (
        <div
            style={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                maxWidth: '70%',
                zIndex: 9999,
            }}
        >
            <Fade in={visibleLegends} timeout={500}>
                <div>
                    {showLegends === false ? (
                        <Tooltip title="Показать легенду" placement="right">
                            <IconButton
                                onClick={() => setShowLegends(true)}
                            >
                                <InfoIcon fontSize="medium" style={{ color: '#3f51b5' }} />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Paper
                            sx={{
                                marginLeft: '1rem',
                                marginBottom: '1rem',
                                zIndex: 999999
                            }}
                        >
                            <div className="close-legend-icon">
                                <Tooltip title="Скрыть легенду" placement="right">
                                    <IconButton
                                        size="small"
                                        onClick={() => setShowLegends(false)}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            {children}
                        </Paper>
                    )}
                </div>
            </Fade>
        </div>
    );
};

export default MapLegends;
