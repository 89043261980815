import messages from '../../../../../../../../../helpers/constants/messages';
import { useStoreProp } from '../../../../../../../../../helpers/hooks';
import { loadVehicleClasses } from '../../../../../../../../../redux/TransportPassenger/actions';
import { RouteHistory } from '../../../../types';
import ChangedRouteOnMap from './ChangedRouteOnMap';

interface InfoProps {
    item: RouteHistory;
}

const Info = ({ item }: InfoProps) => {
    
    const renderValue = (value: { filename: string; }) => Array.isArray(value) 
        ? messages.NO_DATA 
        : <span>{value?.filename || messages.NO_DATA}</span>;

    const renderClassNames = (classNames: any[]) => classNames?.map((elem, index) => (
        <div style={{ marginLeft: 10 }} key={`${elem.class_name}${index}`}>
            {elem?.class_name}: {elem?.count}
        </div>
    )) || messages.NO_DATA;
    
    const renderPaymentDetails = (details: { tariff_passenger: string; tariff_baggage: string; }) => (
        <>
            <div style={{ marginLeft: 10 }}>
            стоимость проезда пассажира: {details?.tariff_passenger || messages.NO_DATA}
            </div>
            <div style={{ marginLeft: 10 }}>
            стоимость провоза багажа: {details?.tariff_baggage || messages.NO_DATA}
            </div>
        </>
    );
    
    return (
        <div>
            {Array.isArray(item?.data) && item?.data?.length > 0  && item?.data.map((el, index) => {

                if (el?.new?.geometry || el?.old?.geometry) {
                    return (
                        <div key={`${el.name}${index}`}>
                            <h3>{el?.name}: </h3>
                            <div><i>Новое значение </i>-
                                <ChangedRouteOnMap type="new" item={el}/>
                            </div>
                            <div><i>Старое значение </i>-
                                <ChangedRouteOnMap type="old" item={el}/>
                            </div>
                        </div>
                    );
                }

                if ((Array.isArray(el?.new) || Array.isArray(el?.old)) && el.name === 'Используемые классы ТС') {
                    return (
                        <div key={`${el.name}${index}`}>
                            <h3>{el?.name}: </h3>
                            <div><i>Новое значение </i>: {renderClassNames(el?.new)}</div>
                            <div><i>Старое значение </i>: {renderClassNames(el?.old)}</div>
                        </div>
                    );
                } else if (el.name === 'Оплата наличными' || el.name === 'Безналичная оплата') {
                    return (
                        <div key={`${el.name}${index}`}>
                            <h3>{el?.name}: </h3>
                            <div><i>Новое значение </i>: {el?.new ? renderPaymentDetails(el?.new) : messages.NO_DATA}</div>
                            <div><i>Старое значение </i>: {el?.old ? renderPaymentDetails(el?.old) : messages.NO_DATA}</div>
                        </div>
                    );
                } else if (el.name === 'Документы') {
                    return (
                        <div key={`${el.name}${index}`}>
                            <h3>{el?.name}: </h3>
                            <div><i>Новое значение </i>: {renderValue(el?.new)}</div>
                            <div><i>Старое значение </i>: {renderValue(el?.old)}</div>
                        </div>
                    );
                }

                return (
                    <div key={`${el?.name}${index}`}>
                        <h3>{el?.name}: </h3>
                        <div><i>Новое значение </i>- {el?.new}</div>
                        <div><i>Старое значение </i>- {el?.old === false ? 'Нет' : el?.old}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default Info;