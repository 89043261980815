import DateRange from '../../../../common/Dates/DateRange';
import titles from '../../../../../helpers/constants/titles';
import React, { useState } from 'react';
import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import {
    IconButton,
    Tooltip,
} from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ClearIcon from '@mui/icons-material/Clear';
import { fullDateTime } from '../../../../../helpers/date.config';


const DateFilter = (props) => {
    const {
        // start_date = null,
        // end_date = null,
        onChange,
    } = props;

    const [show, setShow] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [startD, setStartD] = useState(null);
    const [endD, setEndD] = useState(null);

    const handleClose = () => {
        setShow(false);

        // setStartD(startDate);
        // setEndD(endDate);
    };
    const handleApply = () => {
        // handleClose();
        setShow(false);
        setStartDate(startD);
        setEndDate(endD);

        onChange(startD, endD);
    };

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        // setStartD(startDate);
        // setEndD(endDate);

        setShow(true);
    };

    const handleClear = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setStartDate(null);
        setEndDate(null);
        setStartD(null);
        setEndD(null);

        onChange(null, null);
    };

    return (
        <>
            <div style={{ fontWeight: 'bold', marginTop: '.5rem' }} onClick={handleClick} >
                {/*{titles.SELECT_PERIOD}*/}
                Показать маршрут по дате и времени
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginTop: '.5rem' }}>

                <Tooltip title={titles.SELECT_PERIOD}>
                    <IconButton
                        size="small"
                        onClick={handleClick}
                    >
                        <DateRangeIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>

                {startDate && endDate && (
                    <Tooltip title={titles.CLEAR}>
                        <IconButton
                            size="small"
                            onClick={handleClear}
                        >
                            <ClearIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                )}

                {startDate && endDate ? (
                    <div style={{ marginLeft: '10px' }}>
                        {fullDateTime(startDate)} - {fullDateTime(endDate)}
                    </div>
                ) : (
                    <div style={{ marginLeft: '10px' }} onClick={handleClick} >
                        {titles.SELECT_PERIOD}
                    </div>
                )}

            </div>

            <Modal
                // noPadding
                title={titles.SELECT_PERIOD}
                maxWidthProp={'md'}
                small
                isOpen={show}
                onClose={handleClose}
                buttons={
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.apply,
                                onClick: handleApply,
                            },
                            {
                                ...buttonsTypes.close,
                                onClick: handleClose,
                            },
                        ]}
                    />
                }
            >
                <div style={{ display: 'flex' }}>
                    <DateRange
                        valueStartDate={startD}
                        valueEndDate={endD}
                        handleDate={(newValue) => setStartD(newValue)}
                        handleEndDate={(newValue) => setEndD(newValue)}
                        startLabel={titles.START_PERIOD}
                        endLabel={titles.END_PERIOD}
                    />
                </div>
            </Modal>
        </>
    );
};

export default DateFilter;



