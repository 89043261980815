import { useState } from 'react';
import { useStoreProp } from '../../../../../helpers/hooks';
import { loadUserAvailableDashboardList } from '../../../../../redux/Efficiency/actions';
import UniversalSelect from '../../../../common/UniversalSelect';

const SelectDashboard = ({ isOpen, onChange, onClose, selected }) => {
    const [sortedList, setSortedList] = useState([]);

    const dashboards = useStoreProp(loadUserAvailableDashboardList, 'efficiency', 'userAvailableDashboardList');

    const handleAccept = (data) => {
        onChange(data);
        onClose();
    };

    const getDashboards = (params) => {
        const { query } = params;
        const dashboardsArray = Object.keys(dashboards).map((key) => ({ id: Number(key), name: dashboards[key] }));
        const filteredDashboards = query 
            ? dashboardsArray.filter(item => item?.name?.toLowerCase().includes(query?.toLowerCase())) 
            : dashboardsArray;
        setSortedList(filteredDashboards);
    };

    return (
        <UniversalSelect
            multiple
            sortedList={sortedList}
            fetchList={getDashboards}
            keyProp={'id'}
            withSearch
            isSelected
            onAccept={handleAccept}
            onClose={onClose}
            selected={selected}
            renderProps={(el) => <div>{el.name}</div>}
            isOpen={isOpen}
            noPadding
            title="Дашборды"
        />
    );
};

export default SelectDashboard;