import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import DateRange from 'components/common/Dates/DateRange';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import ModalComponent from 'components/common/Modal';
import GetReports from 'components/common/GetReports';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useValidation } from 'helpers/hooks';

import * as actions from 'redux/TransportSpecial/actions';


const Modal = ({ handleCloseModal, data, open, filter }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        report: 1,
        formats: [],
        date_start: filter.date_start || null,
        date_end: filter.date_end || null,
        grz: [data.garage_number],
    };

    const [boxFilters, setBoxFilters] = useState(initialState);

    const handleResetFilter = () => {
        setBoxFilters({
            ...boxFilters,
            date_start: null,
            date_end: null
        });
    };

    const handleDownloadReport = () => {
        const paramsWithoutEmpty = removeEmptyFields({
            ...boxFilters,
            date_start: fullDateTimeWithTimeZone(boxFilters.date_start),
            date_end: fullDateTimeWithTimeZone(boxFilters.date_end),
            formats: boxFilters.formats.map(i => i.id)
        }, false);

        // todo что было != пассажирка и дорожные работы
        // dispatch(getCurrentSpecialReport(uid, paramsWithoutEmpty, () => handleCloseModal()));

        dispatch(actions.getFilteredTSReport(paramsWithoutEmpty, () => handleCloseModal()));
    };

    const isDisabled = !boxFilters.date_start || !boxFilters.date_end || boxFilters.formats.length === 0;

    return (
        <ModalComponent
            onClose={handleCloseModal}
            isOpen={open}
            title={titles.CURRENT_TRANSPORT_REPORT}
            maxWidth="md"
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: handleCloseModal
                    },
                    {
                        ...buttonsTypes.resetFilter,
                        onClick: handleResetFilter
                    },
                    {
                        ...buttonsTypes.getReport,
                        onClick: () => handleDownloadReport(),
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <div><span><strong>Номер ТС: </strong>{data.number || messages.NO_DATA}</span></div>
                <div><span><strong>Марка ТС: </strong>{data.brand_name || messages.NO_DATA}</span></div>
                <div><span><strong>Модель ТС: </strong>{data.model_name || messages.NO_DATA}</span></div>
                <div><span><strong>Средняя скорость: </strong>{data.speed || messages.NO_DATA}</span></div>
                <div style={{ marginTop: 25 }} className="row">
                    <DateRange
                        className="row__item"
                        handleDate={ (newDate) => {
                            setBoxFilters({
                                ...boxFilters,
                                date_start: newDate
                            });
                        }}
                        handleEndDate={ (newDate) => {
                            setBoxFilters({
                                ...boxFilters,
                                date_end: newDate
                            });
                        }}
                        valueStartDate={boxFilters.date_start}
                        valueEndDate={boxFilters.date_end}
                        required={true}
                        errorMessage={validation.get('date_start') || validation.get('date_end')}
                    />
                </div>

                <GetReports
                    reportId={24}
                    // reportId={54}
                    selected={boxFilters.formats}
                    onChange={(format) => {
                        setBoxFilters({ ...boxFilters, formats: format });
                    }}
                />
            </div>
        </ModalComponent>
    );
};

export default Modal;
