import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';

import messages from 'helpers/constants/messages';

const MsgSource = ({ board = {}, }) => {
    return (
        <div className="container">
            <h2>Источники сообщений</h2>
            {board.links?.length > 0
                ? <List>
                    {board.links?.map((el) =>
                        <ListItem key={el?.entity_id} dense>
                            <ListItemText>
                                <List
                                    disablePadding
                                    subheader={<h3>{el?.entity?.service_text || messages.NO_DATA}:</h3>}
                                >
                                    {el?.entity?.name
                                        && <ListItem dense>
                                            <div>Название: {el?.entity?.name}</div>
                                        </ListItem>
                                    }
                                    {el?.entity?.lat
                                        && <ListItem dense>
                                            <div>Широта: {el?.entity?.lat}</div>
                                        </ListItem>
                                    }
                                    {el?.entity?.lon
                                        && <ListItem dense>
                                            <div>Долгота: {el?.entity?.lon}</div>
                                        </ListItem>
                                    }
                                </List>
                            </ListItemText>
                        </ListItem>
                    )
                    }
                </List>
                : <div>{messages.NOTHING_FOUND}</div>
            }
        </div>
    );
};

export default MsgSource;
