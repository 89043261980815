import { Checkbox, FormControlLabel, List } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStoreProp } from '../../../../../helpers/hooks';
import { parkingSelectors } from '../../../../../redux/Parking';
import { loadCategories, loadParkingList, loadTariffList } from '../../../../../redux/Parking/actions';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import PageLayout from '../../../../layout/PageLayout';
import Item from './Item';
import ModalForm from './ModalForm';
import { ItemTypes } from './types';

const Parking = () => {
    const dispatch = useDispatch();

    const categories = useStoreProp(
        loadCategories,
        'parking',
        'categories'
    );

    const tariffs = useStoreProp(loadTariffList, 'parking', 'tariffList');

    const [free_spaces, setFreeSpaces] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [page, setPage] = useState<Number>(1);
    const list = useSelector(parkingSelectors.parkingListData);
    const meta = useSelector(parkingSelectors.parkingListMeta);
    const loading = useSelector(parkingSelectors.parkingListLoading);

    const [openModal, setOpenModal] = useState(false);

    const RenderList = () => (
        <List className="list">
            {list.map((item: ItemTypes) => (
                <Item
                    key={item.id}
                    item={item}
                    tariffs={tariffs}
                    categories={categories}
                    reloadList={() => onSearch(free_spaces)}
                />
            ))}
        </List>
    );

    useEffect(() => {
        dispatch(loadParkingList(page, limit));
    }, [dispatch]);

    const onSearch = (freeSpaces: boolean) => {
        const params = !!freeSpaces ? { free_spaces: +freeSpaces } : {};
        dispatch(loadParkingList(page, limit, params));
    };

    const handleCheck = (checked: boolean) => {
        setFreeSpaces(checked);
        onSearch(checked);
    };

    return <>
        <PageLayout
            header="Парковки"
            actionPanel={<FormControlLabel
                control={
                    <Checkbox
                        //@ts-ignore
                        onClick={({ target }) => handleCheck(target.checked)}
                        checked={free_spaces}
                        size="small"
                    />
                }
                label="Свободные места"
            />}
            informPanelProps={{
                buttons: (
                    <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.add,
                                onClick: () => setOpenModal(true),
                                // disabled: !permissions?.is_create,
                            }
                        ]}
                    />
                ),
                total: meta?.total
            }}
            loading={!!loading}
            content={RenderList}
            paginationProps={{
                loadList: (page) => setPage(page),
                list: meta,
                limit: limit,
                setLimit: setLimit,
            }}
        />
        {openModal 
            && <ModalForm
                isOpen={openModal}
                onClose={() => setOpenModal(false)}
                reloadList={() => onSearch(free_spaces)}
                tariffs={tariffs}
                categories={categories}
                isNew
            />
        }
    </>;
};

export default Parking;