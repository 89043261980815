import TSVehicles from 'components/pages/Dictionaries/TransportSpecial/Vehicles';
import Brands from 'components/pages/Dictionaries/TransportSpecial/Brands';
import Models from 'components/pages/Dictionaries/TransportSpecial/Models';
import SpeedColor from 'components/pages/Dictionaries/TransportSpecial/SpeedColor';
import VehicleCategories from 'components/pages/Dictionaries/TransportSpecial/VehicleCategories';
import TelemetryReceiversForSP from 'components/pages/Dictionaries/TransportSpecial/TelemetryReceiversForSP';
import RepeatersTelemetry from 'components/pages/Dictionaries/TransportSpecial/RepeatersTelemetry';

const routes = [
    {
        path: '/dictionaries/transport-special/vehicles',
        component: TSVehicles,
        exact: true,
    },
    {
        path: '/dictionaries/transport-special/brands',
        component: Brands,
        exact: true
    },
    {
        path: '/dictionaries/transport-special/models',
        component: Models,
        exact: true
    },
    {
        path: '/dictionaries/transport-special/speed-color',
        component: SpeedColor,
        exact: true
    },
    {
        path: '/dictionaries/transport-special/categories',
        component: VehicleCategories,
        exact: true
    },
    {
        path: '/dictionaries/transport-special/telemetry-receivers',
        component: TelemetryReceiversForSP,
        exact: true
    },
    {
        path: '/dictionaries/transport-special/repeaters-telemetry',
        component: RepeatersTelemetry,
        exact: true
    },
];

export default routes;
