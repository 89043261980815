import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';

import removeEmptyFields from 'helpers/removeEmptyFields';
import { useValidation } from 'helpers/hooks';
import SelectCompany from 'components/common/Autocomplete/Companies';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import type { TelemetryReceiver } from './types';

export interface ModalFormProps {
    isOpen: boolean
    onClose: () => void
    reloadList: (isDelete?: boolean) => void
    actions: { [key: string]: any }
    isNew?: boolean
    item?: TelemetryReceiver
    types: { [key: string]: string }
}

const ModalForm = ({ isOpen, isNew, reloadList, onClose, item, actions, types }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        name: item?.name || '',
        port: item?.port || 0,
        ip: item?.ip || '',
        types: item?.types.map(id => ({ id, name: types[id] || String(id) })) || [],
        organization: item?.organization || null,
        organization_id: item?.organization_id || null
    };

    const [data, setData] = useState(initialState);
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        setData({
            ...data,
            [name]: value
        });
    };

    const onAcceptModal = () => {
        const result = removeEmptyFields({
            ...data,
            types: data.types.map(({ id }) => id),
            organization_id: data.organization?.id || null
        });

        const callback = () => {
            reloadList();
            onClose();
        };

        dispatch(isNew 
            ? actions.addTelemetryReceiver(result , callback)
            : actions.editTelemetryReceiver(item?.id, result , callback));
    };

    const isDisabled = !data.name.trim()
        || !data.port
        || !data.ip.trim()
        || !data.types.length;

    return (
        <Modal
            isOpen={isOpen}
            title={
                isNew 
                    ? 'Добавить приемник / организацию телеметрии' 
                    : 'Редактировать приемник / организацию телеметрии'
            }
            onClose={onClose}
            noPadding={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <FormInfoWrapper
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                    className="block"
                >
                    <TextField
                        label={'Приемник/организация телеметрии'}
                        size="small"
                        value={data.name}
                        variant="outlined"
                        name="name"
                        type="text"
                        onChange={handleChange}
                        required
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    error={validation.isKey('port')}
                    helperText={validation.get('port')}
                    className="block"
                >
                    <TextField
                        label={'Порт'}
                        size="small"
                        value={data.port}
                        variant="outlined"
                        name="port"
                        type="number"
                        onChange={handleChange}
                        required
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    error={validation.isKey('ip')}
                    helperText={validation.get('ip')}
                    className="block"
                >
                    <TextField
                        label={'IP'}
                        size="small"
                        value={data.ip}
                        variant="outlined"
                        name="ip"
                        type="text"
                        onChange={handleChange}
                        required
                    />
                </FormInfoWrapper>

                <CommonAutocomplete<{ id: number, name: string }>
                    className="block"
                    label={'Тип'}
                    multiple={true}
                    selected={data?.types}
                    onChange={(types: any) => setData({ ...data, types })}
                    options={Object.keys(types).map((key) => ({ id: +key, name: types[key] }))}
                    required={true}
                    inputName="types"
                    renderLabel={(option) => option?.name || ''}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    filterSelectedOptions
                    onReset={() => setData({ ...data, types: [] })}
                    error={validation.isKey('types')} 
                    helperText={validation.get('types')}
                />

                <SelectCompany
                    className="block"
                    selected={data.organization}
                    onChange={(organization: any) => setData({ ...data, organization })}
                    error={validation.isKey('organization_id')} 
                    helperText={validation.get('organization_id')}
                />
            </form>
        </Modal>
    );
};

export default ModalForm;
