import React, { useState } from 'react';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import PassengersCount from '../../../../pages/Reports/PassengerTransport/PassengersCount';
import Modal from '../../../../common/Modal';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import FormButtonsComponent from '../../../../common/FormButtons';
// import PlatformData from './PlatformData';
import Stations from './Stations';
import { theme } from '../../../../../App';

const PlatformPopUp = (props) => {
    const {
        station,
        isStation = true,
        route = null,
        category_id = null
    } = props;

    const [open, setOpen] = useState(false);
    const [openStations, setOpenStations] = useState(false);

    // забираем данные из полигона
    const { name } = station;

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} locale={'ru'}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <div className="map-popup passanger-transport">
                        <div className="map-popup_header">
                            <div>
                                <span className="name">{isStation ? 'Остановка' : 'Контрольная точка'}: {name}</span>
                            </div>
                        </div>
                        <div className="map-popup_body">
                            {isStation && (
                                <div className="filter__wrap__btn filter__btn__column">
                                    <FormButtonsComponent
                                        positionLeft
                                        buttons={[
                                            {
                                                onClick: () => setOpen(true),
                                                name: 'Пассажиропоток'
                                            },
                                            {
                                                onClick: () => setOpenStations(true),
                                                name: 'Расписание'
                                            }
                                        ]}
                                    />
                                </div>
                            )}
                        </div>

                        {open
                            && (
                                <Modal
                                    isOpen={open}
                                    onClose={() => setOpen(false)}
                                    noPadding={true}
                                    fullWidth
                                    buttons={
                                        <FormButtons
                                            buttons={[
                                                {
                                                    ...buttonsTypes.close,
                                                    onClick: () =>setOpen(false)
                                                },
                                            ]}
                                        />
                                    }
                                >
                                    <div className="modal__form">
                                        <PassengersCount
                                            check_point={station}
                                            title={station.name}
                                        />
                                    </div>
                                </Modal>)
                        }

                        {openStations
                            && (
                                <Modal
                                    isOpen={openStations}
                                    onClose={() => setOpenStations(false)}
                                    noPadding={true}
                                    title={`Расписание: ${name}`}
                                    medium
                                    // maxWidthProp
                                    buttons={
                                        <FormButtons
                                            buttons={[
                                                {
                                                    ...buttonsTypes.close,
                                                    onClick: () =>setOpenStations(false)
                                                },
                                            ]}
                                        />
                                    }
                                >
                                    <Stations
                                        id={station?.id}
                                        route_num={route}
                                        vehicle_type={category_id}
                                    />
                                </Modal>)
                        }
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        </LocalizationProvider>
    );
};

export default PlatformPopUp;
