import React from 'react';
// import { List, ListItem, ListItemText } from '@mui/material';
import { Container, Item, Name, Value } from '../../List';

const Grid = ({ data = {} }) => {
    const cells = data.cells || [];

    // original from efficiency
    // return (
    //     <List>
    //         {cells?.map(({ name, value, threshold, color }, index) => (
    //             <ListItem dense disableGutters key={index} style={{ paddingLeft: '26px' }}>
    //                 <ListItemText style={{ marginRight: '10px' }}>
    //                     {name}
    //                 </ListItemText>
    //                 <div style={{ color:`${threshold ? 'red' : 'inherit'}` }}>{value}</div>
    //             </ListItem>
    //         ))}
    //     </List>
    // );

    // custom from 'showcase/List'
    return (
        <Container>
            {cells
                ?.map(({ name, value, threshold }, index) => (
                    <Item key={index} className="small">
                        <Name>{name}</Name>
                        <Value style={threshold ? { color: 'red' } : {}}>{value}</Value>
                    </Item>
                ))}
        </Container>
    );
};

export default Grid;