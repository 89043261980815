import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Divider } from '@mui/material';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';

const Members = ({ data }) => {
    return (
        <Scrollbars style={{ height: '200px' }}>
            {data?.length
                ? data?.map((item) => {
                    return (
                        <React.Fragment key={item.id}>
                            <div className="info__item">
                                <span>ФИО участника:</span>
                                <span>{item.fio}</span>
                            </div>
                            <div className="info__item">
                                <span>Год рождения:</span>
                                <span>{item.birth_year}</span>
                            </div>
                            <div className="info__item">
                                <span>{titles.SEVERITY_AFTER_DTP}:</span>
                                <span>{item.severity_text}</span>
                            </div>
                            <Divider/>
                        </React.Fragment>
                    );
                })
                : <div className="info__item">
                    {messages.INFO_IS_NOT_FOUND}
                </div>
            }
        </Scrollbars>
    );
};

export default Members;
