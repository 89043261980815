import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import messages from 'helpers/constants/messages';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import PageLayout from 'components/layout/PageLayout';
import {
    PageReportModal,
    PageReportBlock,
} from 'components/common/PageReport';
import { loadPlanFactReport } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import Filter from './Filter';
import List from './List';


const PlanFact = () => {
    const dispatch = useDispatch();

    const data = useSelector(roadworksSelectors.planFactReport);
    const loading = useSelector(roadworksSelectors.loadingPlanFactReport);

    const initialState = {
        date: new Date(),
        type: 'plan_fact',
        organization: {}
    };

    const [params, setParams] = useState(initialState);

    const paramsWithoutEmpty = useCallback((filter) => ({
        type: filter?.type,
        date: dateWithDashYYYYMMDD(filter.date),
        organization_id: filter?.organization?.id,
    }), []);

    const onSearch = (filter = params) => {
        const filters = paramsWithoutEmpty(filter);
        dispatch(loadPlanFactReport(filters));
    };

    const onChangeFilter = (newData) => {
        setParams((prev) => ({ ...prev, ...newData }));
    };

    const resetFilter = () => {
        onSearch(initialState);
    };

    useEffect(()=> {
        localStorage.setItem('planFactFilter', JSON.stringify(params));

        return () => localStorage.removeItem('planFactFilter');
    },[params]);

    const [companies, setCompanies] = useState([]);

    // useEffect(() => {
    //     // собираем все айди организаций списка и запрашиваем одним запросом массив организаций
    //     if (Object.keys(data)?.length > 0) {
    //         dispatch(loadCompanies(
    //             1,
    //             Object.keys(data)?.length,
    //             { id_list: Object.keys(data), withDeleted: 1 },
    //             (organizations = []) => {
    //                 setCompanies(organizations);
    //             }));
    //     }
    // }, [data, dispatch]);

    return (
        <>
            <PageLayout
                header="Отчет план-факт"
                filters={
                    <Filter
                        filter={params}
                        onChangeFilter={onChangeFilter}
                        onReset={resetFilter}
                        onSearch={onSearch}
                    />
                }
                informPanelProps={{
                    buttons: (
                        <PageReportModal>
                            <PageReportBlock
                                filter={paramsWithoutEmpty(params)}
                            />
                        </PageReportModal>
                    )
                }}
                loading={loading}
                content={() => (
                    Object.keys(data)?.length > 0
                        ? <List data={data} companies={companies} />
                        :!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>
                )}

            />
        </>
    );
};

export default PlanFact;
