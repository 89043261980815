import Grid from '@mui/material/Grid';
import {
    Collapse,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';

import type { DeviceGroup } from './types';

interface InfoProps {
    isOpen: boolean;
    item: DeviceGroup;
}

const Info = ({ isOpen, item }: InfoProps) => {
    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" component="div">
                        Параметры
                    </Typography>
                    <List className="list">
                        {item.params.map((param, i) => (
                            <ListItem key={param.id}>
                                <ListItemAvatar>{i + 1}</ListItemAvatar>
                                <ListItemText>{param.name}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" component="div">
                        Станции
                    </Typography>
                    <List>
                        {item.devices.map((device, i) => (
                            <ListItem key={device.id}>
                                <ListItemAvatar>{i + 1}</ListItemAvatar>
                                <ListItemText>{device.name}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Collapse>
    );
};

export default Info;
