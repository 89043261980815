import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { incidentsSelectors } from 'redux/Incidents';
import {
    loadConcentrationArea,
    loadConcentrationAreaStatuses,
    loadConcentrationAreaTypes,
    setMKDTPFilterParams,
    setMKDTPModalData
} from 'redux/Incidents/actions';
import { loadTransportIncidentStatuses } from 'redux/SituationalPlans/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import Loading from 'components/common/Loading';
import PageLayout from 'components/layout/PageLayout';

import Filter from './Filter';
import Item from './Item';
import ModalForm from './ModalForm';

const DtpConcentrationArea = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const data = useSelector(incidentsSelectors.concentrationAreaData);
    const meta = useSelector(incidentsSelectors.concentrationAreaMeta);
    const loading = useSelector(incidentsSelectors.loadingConcentrationArea);
    const mkdtpModalData = useSelector(incidentsSelectors.mkdtpModalData);
    const mkdtpFilterParams = useSelector(incidentsSelectors.mkdtpFilterParams);
    const types = useStoreProp(loadConcentrationAreaTypes, 'incidents', 'concentrationAreaTypes');
    const statuses = useStoreProp(loadConcentrationAreaStatuses, 'incidents', 'concentrationAreaStatuses');
    const statusesTI = useStoreProp(loadTransportIncidentStatuses, 'SituationalPlans', 'transportIncidentStatuses');

    const { id } = queryString.parse(location.search);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({
        page: 1,
        query: {},
    });
    const [openAddModal, setOpenAddModal] = useState(false);

    const loadList = useCallback((shouldGoToPreviousPage = false) => {
        if (Object.keys(params?.query).length > 0) {
            if (shouldGoToPreviousPage) {
                setParams((prev) => ({ ...prev, page: prev.page - 1 }));
            } else {
                dispatch(loadConcentrationArea(params.page, limit, params.query));
            }
        }
    }, [dispatch, params, limit]);

    useEffect(() => {
        loadList();
    }, [loadList]);

    const renderContent = () => {
        return <>
            {loading && <Loading circular/>}
            {data?.length > 0
                ? (
                    <>
                        <LSContainer
                            headers={[
                                { title: '', width: '50px' },
                                { title: titles.ID, width: '6%' },
                                { title: titles.NAME, width: '20%' },
                                { title: titles.KIND, width: '7%' },
                                { title: titles.ADDRESS, width: '45%' },
                                { title: <>Связанное <br /> событие</>, width: '12%' },
                                { title: titles.ACTIONS, isActions: true }
                            ]}
                        >
                            {data.map((item) => (
                                <Item
                                    key={item.id}
                                    item={item}
                                    types={types}
                                    statuses={statuses}
                                    loadList={(isDelete) => loadList(isDelete && data.length === 1)}
                                    statusesTI={statusesTI}
                                />
                            ))}
                        </LSContainer>
                    </>
                )
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>;
    };

    return (
        <>
            <PageLayout
                additionalButtons={id && <ReturnToButton />}
                header={'Места концентрации ДТП'}
                filters={<Filter setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={[{
                                ...buttonsTypes.add,
                                onClick: () => setOpenAddModal(true),
                                size: 'small',
                            }]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => {
                        setParams((prev) => ({ ...prev, page }));
                        dispatch(setMKDTPFilterParams({ ...mkdtpFilterParams, params: { ...params, page } }));
                    },
                    list: meta,
                    limit,
                    setLimit
                }}
            />
            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    loadList={loadList}
                    isNew
                />
            )}
            {!!mkdtpModalData && (
                <ModalForm
                    isOpen={!!mkdtpModalData}
                    onClose={() => dispatch(setMKDTPModalData(null))}
                    loadList={loadList}
                    item={mkdtpModalData}
                />
            )}
        </>
    );
};

export default DtpConcentrationArea;
