import { useState } from 'react';
import Modal from './Modal';
import styles from './vehicles.module.scss';
import VehicleListItem from '../../../../common/Transport/VehicleReportList/VehicleListItem';

const Item = ({ item, filter, categories, getReport }) => {
    const [openModal, setOpenModal] = useState(false);

    const category = item?.category_id && categories.find(category => category.id === item.category_id);

    return (
        <>
            {openModal && <Modal
                handleCloseModal={() => setOpenModal(false)}
                open={openModal}
                data={item}
                filter={filter}
                getReport={getReport}
            />}
            <VehicleListItem
                item={item}
                category={category}
                setOpenModal={setOpenModal}
                styles={styles}
            />
        </>
    );
};

export default Item;
