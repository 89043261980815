import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import mapHelper from 'helpers/mapHelper';
import { useWsSubscribe } from 'helpers/ws/hooks';
import useStoreProp from 'helpers/hooks/useStoreProp';
import colorExtend from 'helpers/mapHelper/colorExtend';
import {
    Marker,
    PopUp,
    ToolTip,
    GeoJson,
} from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import MapLegends from 'components/common/Transport/MapLegends';
import useLeafletEvents from 'components/MapComponents/leaflet/components/hooks/useLeafletEvents';
import * as actions from 'redux/TrafficLights/actions';
import {
    wsReplaceData as wsReplaceTrafficLights,
    wsReplaceDataList,
} from 'redux/TrafficLights/actions';
import { trafficLightsSelectors } from 'redux/TrafficLights';
import LegendsList from './LegendsList';
import Phases from './Phase/Phases';
import Hint from './Phase/Hint';
import {
    createIconMarker,
    isShowPhases,
} from './helper';
import CCPopup from './PopUp';
import config from './config.js';
import './style.scss';


const Layer = (props) => {
    const { map, readOnly = false, hideLegend = false } = props;
    const dispatch = useDispatch();
    const polygon = useSelector(trafficLightsSelectors.polygon);
    const showGSAndCG = useSelector(trafficLightsSelectors.showGSAndCG);
    const active = useSelector(trafficLightsSelectors.active);
    const filters = useSelector(trafficLightsSelectors.filters);
    const wsData = useSelector(trafficLightsSelectors.wsData);
    const saved = useSelector(trafficLightsSelectors.saved);

    const getColorForLegends = useStoreProp(
        actions.loadColorsForLegends,
        'trafficLights',
        'colorsForLegends'
    );

    const [showPhases, setShowPhases] = useState(isShowPhases(map.getZoom()));

    // кодключение к карте
    useLeafletEvents((e) => {
        // setZoom(e.target.getZoom());
        setShowPhases(isShowPhases(map.getZoom()));
    }, map, [
        'zoomend'
    ]);

    const [groups, setGroups] = useState([]);
    const [green, setGreen] = useState([]);
    const activeId = Object.keys(active || {}).length > 0 ? active.id : 0;

    // каналы для светофоров
    // фазы
    useWsSubscribe('lights_traffic_light_telemetry', (events) => {
        dispatch(wsReplaceTrafficLights(events));
    });
    // модель
    useWsSubscribe('lights_traffic_light_model_v2',(events) => {
        dispatch(wsReplaceDataList(events));
    });

    const styles = {
        group: {
            color: '#0c4d60',
            weight: 5,
            opacity: 0.8,
        },
        green: {
            color: '#0e990e',
            weight: 5,
            opacity: 0.8,
        },
    };

    const filterList = (list, ids) => {
        return list.reduce((res, item) => {
            if (showGSAndCG || ids.includes(item.id)) {
                return {
                    ...res,
                    [item.id]: item,
                };
            }
            return res;
        }, {});
    };

    useEffect(() => {
        const cg_ids = filters.cg_ids?.map(({ id }) => id) || [];
        const gs_ids = filters.gs_ids?.map(({ id }) => id) || [];

        if (
            cg_ids.length > 0
            || gs_ids.length > 0
            || showGSAndCG
        ) {
            const {
                groupsList,
                greenList
            } = polygon
                .reduce((res, {
                    coordination_groups,
                    green_streets,
                }) => {
                    const groupIterate = filterList(coordination_groups, cg_ids);
                    const groupsList = {
                        ...res.groupsList,
                        ...groupIterate
                    };

                    const greenIterate = filterList(green_streets, gs_ids);
                    const greenList = {
                        ...res.greenList,
                        ...greenIterate
                    };

                    return {
                        groupsList,
                        greenList,
                    };

                }, {
                    groupsList: {},
                    greenList: {},
                });

            setGroups(groupsList);
            setGreen(greenList);
        } else {
            setGroups([]);
            setGreen([]);
        }
    }, [polygon, filters, showGSAndCG]);

    // обработка после сохранения
    useEffect(() => {
        if (saved) {
            map.closeContextMenu();
            // map.closePopup();
            fetchPolygon();
            dispatch(actions.setEditForm());
            dispatch(actions.saveStatus(false));
        }
    }, [saved]);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getPolygon(map);
        dispatch(actions.getForPolygon(polygon, getFilters(filters)));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = debounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();

    // добавить новый
    // const handleAdd = ({ lat, lng }) => {
    //     dispatch(actions.setEditForm({
    //         lat,
    //         lon: lng,
    //     }));
    // };

    // сдвинуться к маркеру
    // const setMapToMarker = () => {
    //     if (Object.keys(active).length > 0) {
    //         const { lat, lon } = active;
    //         if (Math.abs(lat) && Math.abs(lon)) {
    //             setTimeout(() => {
    //                 map.setView([lat, lon]);
    //             }, 200);
    //         }
    //     }
    // };

    useEffect(() => {
        fetchPolygon();
    }, [filters]);

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        // setMapToMarker();

        return () => {
            dispatch(actions.setActive({}));
            dispatch(actions.clearForPolygon());
            map.closeContextMenu();
            //map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters]);

    // добавление нового элемента с карты
    // useEffect(() => {
    //     map.on(config.mapContextMenu.event, (e) => {
    //         //map.fire('context_menu_close');
    //         handleAdd(e.latlng);
    //     });
    //
    //     return () => {
    //         map.off(config.mapContextMenu.event);
    //     };
    // }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 18);
            }
        }
    }, [active]);

    useEffect(() => {
        if (showPhases) {
            const ids = polygon.map(({ id }) => id);
            dispatch(actions.wsSetFilter(ids));
        } else {
            dispatch(actions.wsSetFilter());
        }
    }, [showPhases, polygon]);

    // меню маркера, линии, полигона при клике
    // const RenderContextMenu = ({ item, ...rcmProp }) => {
    //     return (
    //         <div>
    //             <ContextMenuItem
    //                 {...rcmProp}
    //                 value="Редактировать"
    //                 onClick={() => {
    //                     //map.fire('context_menu_close');
    //                     dispatch(actions.setEditForm(item));
    //                 }}
    //             />
    //             <ContextMenuItem
    //                 {...rcmProp}
    //                 value="Удалить"
    //                 onClick={() => {
    //                     //map.fire('context_menu_close');
    //                     dispatch(actions.setDeleteForm(item.id));
    //                 }}
    //                 className="red"
    //             />
    //         </div>
    //     );
    // };
    //
    // const tooltipOptions = {
    //     offset: [0, -1],
    //     // sticky: true
    //     permanent: true
    // };
    // todo продумать отображение всплывашки для зеленой учицы и группы

    return (
        <>
            {/* вывести фазы */}
            {showPhases && (
                <Phases
                    {...props}
                    wsData={wsData}
                    polygon={polygon}
                />
            )}

            {/* группы */}
            {Object.keys(groups).map((key) => {
                const { id, geo_data_direct, geo_data_back, name } = groups[key];

                return (
                    <div key={'gs' + id}>
                        <GeoJson
                            {...props}
                            data={geo_data_direct.geometry}
                            style={styles.group}
                            // toolTipTemplate={( ) => `<div><b>Группа:</b> ${name || ''}</div>`}
                            // tooltipOptions={tooltipOptions}
                        />
                        <GeoJson
                            {...props}
                            data={geo_data_back.geometry}
                            style={styles.group}
                            // toolTipTemplate={() => `<div><b>Группа:</b> ${name || ''}</div>`}
                            // tooltipOptions={tooltipOptions}
                        />
                    </div>
                );
            })}

            {/* Зеленая улица */}
            {Object.keys(green).map((key) => {
                const { id, geo_data_direct, geo_data_back, name } = green[key];
                return (
                    <div key={id}>
                        <GeoJson
                            {...props}
                            data={geo_data_direct.geometry}
                            style={styles.green}
                            // toolTipTemplate={() => <div><b>Зеленая улица:</b> {name}</div>}
                            // tooltipOptions={tooltipOptions}
                        />
                        <GeoJson
                            {...props}
                            data={geo_data_back.geometry}
                            style={styles.green}
                            // toolTipTemplate={() => <div><b>Зеленая улица:</b> {name}</div>}
                            // tooltipOptions={tooltipOptions}
                        />
                    </div>
                );
            })}

            {/* выводим светофоры */}
            {polygon.map((item) => {
                const { id, lat, lon, color } = item;

                const componentProps = {
                    ...props,
                    key: `item_${id}`,
                    attribution: {
                        slug: config.slug,
                        color: colorExtend(color)
                    },
                    onClick: (latlng) => {
                        map.setView(latlng);
                        dispatch(actions.resetActive());
                    },
                };

                const currentShow = activeId === id;

                return (
                    <Marker
                        {...componentProps}
                        latlng={[lat, lon]}
                        // иконка сфетофора (+круг)
                        icon={createIconMarker(colorExtend(color), id, showPhases)}
                        //todo подумать
                        // clusterColor={getColorByStatus(item.status)}
                        // clusterSlug={config.slug}
                    >
                        <PopUp
                            // minWidth={600}
                            show={currentShow}
                            onClose={() => {
                                if (currentShow) {
                                    dispatch(actions.resetActive());
                                }
                            }}
                        >
                            <CCPopup
                                external_id={id}
                            />
                        </PopUp>

                        {showPhases ? (
                            <ToolTip
                                offset={[0, -52]}
                                direction="top"
                                permanent={true}
                            >
                                <Hint id={id} wsData={wsData} />
                            </ToolTip>
                        ) : (
                            <ToolTip
                                offset={[0, -38]}
                                direction="top"
                            >
                                <div>
                                    <b>Светофор:</b>&nbsp;{item.name}
                                </div>
                            </ToolTip>
                        )}

                        {/*<ContextMenu*/}
                        {/*    //minWidth={155}*/}
                        {/*>*/}
                        {/*    <RenderContextMenu item={item} />*/}
                        {/*</ContextMenu>*/}
                    </Marker>
                );
            })}

            {/* легенда */}
            {hideLegend === false && (
                <MapLegends
                    layer="traffic-lights"
                    isVisible={Object.keys(getColorForLegends).length > 0}
                    visibleRequired={readOnly}
                >
                    <LegendsList
                        category={getColorForLegends}
                    />
                </MapLegends>
            )}
            {/*<CollectorForms />*/}
        </>
    );
};

export default Layer;
