import React, { useEffect, useState } from 'react';
import Modal from '../../../../common/Modal';
import { FeatureGroup, Map, Marker, ToolTip } from '../../../../MapComponents/leaflet';
import { config } from '../../../../../config';

function TicketPointMapModal({ onClose, isOpen, latlon, address_text }) {
    const [mapCenter, setMapCenter] = useState(config.get('mapCenter'));

    useEffect(()=> {
        if (!latlon[0] && !latlon[1]) {
            setMapCenter(config.get('mapCenter'));
        } else {
            setMapCenter(latlon);
        }
    },[latlon]);


    return (
        <Modal
            title="Местонахождение пункта продажи билетов"
            onClose={onClose}
            noPadding={true}
            isOpen={isOpen}
            showCloseInTitle
        >
            <form className="modal__form">
                <div style={{ height: 400 }}>
                    <Map center={mapCenter}>
                        <FeatureGroup>
                            {latlon
                            &&  <Marker latlng={latlon}>
                                <ToolTip
                                    offset={[0, -40]}
                                    permanent={true}
                                    direction="top"
                                >
                                    <div>
                                        <span style={{ display: 'flex', padding: 2 }}>
                                            <strong>Широта:&nbsp;</strong>
                                            {latlon[0]}
                                        </span>
                                        <span style={{ display: 'flex', padding: 2 }}>
                                            <strong>Долгота:&nbsp;</strong>
                                            {latlon[1]}
                                        </span>
                                        {!!address_text
                                            && <span style={{ display: 'flex', padding: 2 }}>
                                                <strong>Адрес:&nbsp;</strong>
                                                {address_text}
                                            </span>
                                        }
                                    </div>
                                </ToolTip>
                            </Marker>
                            }
                        </FeatureGroup>
                    </Map>
                </div>
            </form>
        </Modal>
    );
}

export default TicketPointMapModal;