import React from 'react';
import { Chip } from '@mui/material';

const AddressList = ({
    list = [],
    onDelete
}) => {
    const handleDelete = (index) => () => onDelete?.(index);

    return (
        <div style={{ marginBottom:' .5rem' }}>
            {list?.map((street, index) => (
                <Chip
                    key={index}
                    label={street}
                    onDelete={handleDelete(index)}
                    style={{ marginRight: '.5rem', marginBottom: '.5rem' }}
                />
            ))}
        </div>
    );
};

export default AddressList;