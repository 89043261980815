import * as types from './types';

export const uploading = (bool = false) => ({
    type: types.UPLOADING,
    payload: bool,
});

export const uploadFileToStorage = (file, service, callback) => ({
    type: types.UPLOAD_FILE_TO_STORAGE,
    payload: { file, service },
    callback
});

export const createComplexByUploadingFile = (file) => ({
    type: types.CREATE_COMPLEX_BY_UPLOADING_FILE,
    payload: { file }
});

export const uploadVehicleFile = (file, overwrite) => ({
    type: types.UPLOAD_VEHICLE_FILE,
    payload: { file, overwrite }
});

export const setUploadVehicleFile = (file) => ({
    type: types.SET_UPLOAD_VEHICLE_FILE,
    payload: file
});

export const setUploadFileError = (data) => ({
    type: types.UPLOAD_FILE_ERROR,
    payload: data 
});

export const uploadRoadWorkFile = (file, overwrite = 0) => ({
    type: types.UPLOAD_ROADWORK_FILE,
    payload: { file, overwrite }
});

export const setUploadRoadWorkFile = (file) => ({
    type: types.SET_UPLOAD_ROADWORK_FILE,
    payload: file
});

export const uploadTransportIncidentFile = (file, overwrite) => ({
    type: types.UPLOAD_TRANSPORT_INCIDENT_FILE,
    payload: { file, overwrite }
});

export const setUploadTransportIncidentFile = (file) => ({
    type: types.SET_UPLOAD_TRANSPORT_INCIDENT_FILE,
    payload: file
});

export const uploadCSODDFile = (file, callback) => ({
    type: types.UPLOAD_CSODD_FILE,
    payload: file,
    callback
});

export const loadCSODDFile = () => ({
    type: types.LOAD_CSODD_FILE,
});

export const loadedCSODDFile = (file) => ({
    type: types.LOADED_CSODD_FILE,
    payload: file,
});

export const deleteCSODDFile = (id, callback) => ({
    type: types.DELETE_CSODD_FILE,
    payload: id,
    callback
});

export const loadRoadLabTypes = () => ({
    type: types.LOAD_ROAD_LAB_TYPES,
});

export const loadedRoadLabTypes = (data) => ({
    type: types.LOADED_ROAD_LAB_TYPES,
    payload: data
});

export const uploadRoadLab = (file, data) => ({
    type: types.UPLOAD_ROAD_LAB,
    payload: { file, data }
});