import { useState } from 'react';
import { Collapse, IconButton, ListItem, ListItemText } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { fullDateTime } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import historyStatus from './historyStatus';

const Item = ({ item = {}, children }) => {
    const [isOpenInfo, setIsOpenInfo] = useState(false);

    const openInfo = () => {
        children && setIsOpenInfo(!isOpenInfo);
    };

    const userName = item?.user?.first_name || item?.user?.middle_name || item?.user?.last_name
        ? `${item?.user?.first_name ?? ''} ${item?.user?.middle_name ?? ''} ${item?.user?.last_name ?? ''}`
        : messages.NO_DATA;

    return (
        <>
            <ListItem
                onClick={openInfo}
                className="list_item"
            >
                <ListItemText>
                    {item?.created_at && (
                        <span>&nbsp;
                            {fullDateTime(item?.created_at)}
                        </span>
                    )}
                    <span>&nbsp;
                        {historyStatus(item.event)}: {userName}
                    </span>
                </ListItemText>
                {children && (
                    <IconButton size="small">
                        {isOpenInfo ? (
                            <ExpandLess fontSize="inherit" />
                        ) : (
                            <ExpandMore fontSize="inherit" />
                        )}
                    </IconButton>
                )}

            </ListItem>
            {isOpenInfo && (
                <Collapse in={isOpenInfo} timeout={'auto'} className="info">
                    {children}
                </Collapse>
            )}
        </>
    );
};

export default Item;
