import FormButtonsComponent, { buttonsTypes } from '../../../../../../common/FormButtons';
import DowntimeForm from './DowntimeForm';

const DowntimeItem = ({ data, onChange }) => {

    const handleChangeItem = (index, el) => {
        const newData = [
            ...data.slice(0, index),
            el,
            ...data.slice(index + 1)
        ];
        onChange(newData);
    };

    const handlDeleteItem = (index) => {
        const newData = [
            ...data.slice(0, index),
            ...data.slice(index + 1)
        ];
        onChange(newData);
    };

    const addItem = () => {
        const newData = [
            ...data,
            {
                reason: '',
                date_start: null,
                date_end: null,
            },
        ];
        onChange(newData);
    };

    return (<>
        <h2>Простой: </h2>
        {data.map((item, index) =>{
            return (
                <DowntimeForm 
                    key={index}
                    index={index}
                    item={item}
                    onChangeItem={(el) => handleChangeItem(index, el)}
                    onDeleteItem={() => handlDeleteItem(index)}
                    // isNew={isNew}
                />
            );
        })}
        <FormButtonsComponent
            positionLeft
            buttons={[
                {
                    ...buttonsTypes.add,
                    onClick: addItem,
                    name: 'Добавить'
                }
            ]}
        />        
    </>);
};

export default DowntimeItem;
