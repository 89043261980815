import DorisControl from '../pages/Showcase/DorisControl';
import PassengerTransport from '../pages/Showcase/PassengerTransport';
import MeteoMonitoring from '../pages/Showcase/MeteoMonitoring';
import Board from '../pages/Showcase/Board';
import TrafficLights from '../pages/Showcase/TrafficLights';
import TrafficFlow from '../pages/Showcase/TrafficFlow';
import HeatGraphEfficiency from '../pages/Showcase/HeatGraphEfficiency';
import EcologyMonitoring from '../pages/Showcase/EcologyMonitoring';
import DTP from '../pages/Showcase/DTP';
import RoadWorks from '../pages/Showcase/RoadWorks';
import VideoAnalytics from '../pages/Showcase/VideoAnalytics';
import TransportRoad from '../pages/Showcase/TransportRoad';
import TransportIncidents from '../pages/Showcase/TransportIncidents';

// страницы витрины
const routes = [
    {
        path: '/doris-control',
        component: DorisControl,
        exact: true,
    },
    {
        path: '/passenger-transport',
        component: PassengerTransport,
        exact: true,
    },
    {
        path: '/meteo',
        component: MeteoMonitoring,
        exact: true,
    },
    {
        path: '/ecology',
        component: EcologyMonitoring,
        exact: true,
    },
    {
        path: '/board',
        component: Board,
        exact: true,
    },
    {
        path: '/traffic-lights',
        component: TrafficLights,
        exact: true,
    },
    {
        path: '/puid',
        component: TrafficFlow,
        exact: true,
    },
    {
        path: '/efficiency',
        component: HeatGraphEfficiency,
        exact: true
    },
    {
        path: '/incidets',
        component: DTP,
        exact: true
    },
    {
        path: '/roadworks',
        component: RoadWorks,
        exact: true
    },
    {
        path: '/video-analytics',
        component: VideoAnalytics,
        exact: true
    },
    {
        path: '/roadwork-transport',
        component: TransportRoad,
        exact: true
    },
    {
        path: '/transport-incidents',
        component: TransportIncidents,
        exact: true
    },
];

export default routes;