import React, { useEffect, useMemo, useState } from 'react';
import { config } from '../../../../../../config';
import { Map, FeatureGroup, GeoJsonNew, MapControl } from '../../../../../MapComponents/leaflet';
import createPointGJ from '../../../../../MapComponents/leaflet/helpers/createPointGJ';
import L from 'leaflet';

const MapComponent = ({
    list = [],
    selected = {},
    onSelected = () => {},
}) => {
    const [center, setCenter] = useState(true);
    useEffect(() => {
        if (list?.length > 0) {
            setCenter(true);
            // setTimeout(() => setCenter(false), 1000);
        }
    }, [list]);

    const createIconMarker = (color = '#fff') => L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: `
            <i style="width: 30px; height: 30px; background: ${color}; display: block; border-radius: 50%; ">
                <i class="fas fa-camera-alt" style="width: 100%; color: #fff; position: absolute; left: 0; top: 0; height: calc(100% - 7px);display: flex; justify-content: center; align-items: center; font-size: 17px;"/>
            </i>
            `
    });

    const handleSelect = (item) => {
        const res = Object.keys(item.items)[0];
        onSelected?.(item.items[res]);
    };

    const markers = useMemo(() => {
        // на случай одной и той же камеры с раными датами
        // чтобы не рисовать несколько маркеров друг на друге 
        // собираем их в один маркер и собираем все айди событий и сами события 
        const listWithUniqCoords = list.reduce((res, item) => {
            res[`${item.lat}${item.lon}`] = {
                lat: item.lat, 
                lon: item.lon,
                ids: [
                    ...(res[`${item.lat}${item.lon}`]?.ids || []),
                    item.id
                ],
                items: { // на бyдущее, если понадобится выводить тултип с информацией по камере
                    ...(res[`${item.lat}${item.lon}`]?.items || {}),
                    [item.id]: item
                },
            };
            return res;
        }, {});

        return Object.values(listWithUniqCoords).reduce((res, item) => {
            const newPoint = createPointGJ(item.lat, item.lon, {
                ...item,
                color: item.ids.includes(selected?.id) ? '#37a815' : '#a2a2a2',
            });
            if (newPoint) {
                res.push(newPoint);
            }
            return res;
        }, []);
    }, [list, selected]);

    const geoMarkers = useMemo(() => {
        if (Object.keys(markers).length > 0) {
            return (
                <GeoJsonNew
                    icon={({ color }) => createIconMarker(color)}
                    // данные
                    data={markers}
                    // название региона
                    // toolTipTemplate={(properties) => `${properties?.name || messages.NO_DATA}`}
                    // параметры информации
                    toolTipOptions={{
                        direction: 'top',
                        offset: [0, -5],
                        sticky: true,
                    }}
                    // выбранный регион (как параметры { id })
                    // selected={selected}
                    // стили
                    // style={{
                    //     opacity: 1,
                    //     fillOpacity: .1,
                    //     color: '#006699',
                    //     fillColor: '#ffffff',
                    // }}
                    selected={selected}
                    // кликнули
                    onClick={handleSelect}
                    // центруем
                    centerAfter={center}
                    // selectedStyle={{
                    //     color: '#bababa'
                    // }}
                />
            );
        }
        return null;
    }, [markers, selected]);

    return (
        <div
            style={{
                height: '400px',
            }}
        >
            <Map center={config.get('mapCenter')}>
                <MapControl>
                    <FeatureGroup>
                        {geoMarkers}
                    </FeatureGroup>
                </MapControl>
            </Map>
        </div>
    );
};

export default MapComponent;