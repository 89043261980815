import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { roadNetworksSelectors } from 'redux/RoadNetwork';
import { deleteConnectionLine } from 'redux/RoadNetwork/actions';
import {
    ActionMore,
    ActionsButtons,
    LSContentColumn,
    LSContentItem
} from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import ModalForm from './ModalForm';
import Info from './Info';
import type { ConnectionLineItem } from 'types/RoadNetwork';

interface ItemProps {
    item: ConnectionLineItem
    reloadList: () => void
}

const Item = ({ item, reloadList }: ItemProps) => {
    const dispatch = useDispatch();
    const  loadingButton  = useSelector(roadNetworksSelectors.loadingButton);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [openMap, setOpenMap] = useState(false);
    const [isInfo, setIsInfo] = useState(false);

    const handleInfo = () => setIsInfo(!isInfo);

    const handleDelete = () => {
        const callback = () => {
            setIsDelete(false);
            reloadList();
        };

        dispatch(deleteConnectionLine(item.id, callback));
    };

    return (
        <>
            <LSContentItem
                //@ts-ignore
                onClick={handleInfo}
            >
                <LSContentColumn>
                    {item.custom_icon && <img
                        className="flex-center"
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 0,
                        }}
                        src={item.custom_icon}
                        alt="custom_icon"
                    />}
                </LSContentColumn>

                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item.type || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item.length ? `${item.length} м` : messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <i className="fas fa-map-marked-alt"/>,
                                name: titles.SHOW_ON_MAP,
                                onClick: () => setOpenMap(true),
                            },
                            {
                                icon: <Edit fontSize="inherit"/>,
                                name: titles.EDIT,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                icon: <Delete fontSize="inherit"/>,
                                name: titles.DELETE,
                                onClick: () => setIsDelete(true),
                            }
                        ]}
                    />

                    <ActionMore
                        isOpen={isInfo}
                        onClick={handleInfo}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isInfo && (
                <Info
                    isOpen={isInfo}
                    data={item}
                />
            )}

            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    onClose={()=> setIsDelete(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                    loadingButton={loadingButton}
                />
            )}

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}

            {openMap && (
                <Modal
                    isOpen={openMap}
                    onClose={() => setOpenMap(false)}
                    title={item.name || ''}
                    buttons={
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: () => setOpenMap(false)
                                },
                            ]}
                        />
                    }
                >
                    <MapGeoJson
                        geometry={item.geometry || {}}
                        readOnly={true}
                    />
                </Modal>
            )}
        </>
    );
};

export default Item;
