import DtpByPeriod from './DtpByPeriod';
import DtpByRegions from './DtpByRegions';
import DtpGraph from './DtpGraph';

const tabComponents = [
    {
        tab: 'period',
        label: 'Показатели ДТП, анализ по периодам',
        component: <DtpByPeriod tab="period"/>,
    },
    {
        tab: 'region',
        label: 'Отчет - ДТП по районам',
        component: <DtpByRegions tab="region" />,
    },
    {
        tab: 'graphic',
        label: 'Отчет по ДТП (график)',
        component: <DtpGraph tab="graphic"/>,
    },
];

export default tabComponents;