// иконка для карты
const iconMap = ({ fill = '#000', innerFill = '#fff' }) => {

    return `
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 107.000000 130.000000">
            <g>
                <g id="svg_rw_3">
                    <rect fill="none" id="canvas_background" height="132" width="109" y="-1" x="-1"/>
                </g>
                <g fill="${fill}" id="svg_rw_1" transform="translate(0,130) scale(0.10000000149011612,-0.10000000149011612) ">
                    <path id="svg_rw_2" d="m445,1288.5c-100,-19 -206,-75 -281,-150c-104,-104 -154,-228 -154,-381c0,-144 38,-245 149,-392c64,-85 297,-316 348,-345c30,-17 31,-17 71,12c68,49 237,216 302,299c157,200 210,350 181,517c-25,142 -121,287 -241,364c-108,70 -251,98 -375,76z"/>
                </g>
                <path fill="${innerFill}" stroke="null" id="svg_rw_8" d="m53.320411,28.983695a5.433852,5.842096 0 1 0 -5.433843,-5.842096a5.433852,5.842096 0 0 0 5.433843,5.842096zm-7.045886,31.810239q-4.38104,-3.137695 -9.005477,-5.853048l-8.100961,-4.746715l-6.50024,24.463795a3.622568,3.894731 0 1 0 6.968905,2.140893l4.143309,-15.598411l6.861371,4.918321l0,9.601735a3.622568,3.894731 0 0 0 7.245136,0l0,-11.684202a3.622568,3.894731 0 0 0 -1.612044,-3.242367zm11.468811,7.137098a3.622568,3.894731 0 0 0 -3.436902,2.663031l-2.797306,9.021172l36.225663,0l-11.539004,-25.111291c-1.27809,-2.749442 -4.875743,-2.897929 -6.347418,-0.261677l-3.606712,6.60766l-7.392302,-4.335325l-2.71579,-14.751304a14.691766,15.79558 0 0 0 -7.892671,-11.099998c-0.1132,-0.060849 -0.238862,-0.080327 -0.352062,-0.137526a3.509359,3.773023 0 0 0 -0.817344,-0.32497c-1.736565,-0.742435 -3.572756,-1.217108 -5.441771,-1.217108l-8.233421,0a3.622568,3.894731 0 0 0 -2.72485,1.329082l-6.33949,7.789471a2.930887,3.151079 0 0 0 -0.260365,0.384602c-0.999606,1.716117 -0.339619,3.999404 1.31544,4.97187l38.104869,22.431235l-1.115069,2.041078l-4.633496,0z"/>
            </g>
        </svg>
    `;
    /*        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 107.000000 130.000000">
            <g>
                <rect x="-1" y="-1" width="109" height="132" id="canvas_background" fill="none"/>
            </g>
            <g>
                <g fill="${fill}" transform="translate(0,130) scale(0.10000000149011612,-0.10000000149011612)" id="svg_rw_1">
                    <path d="m445,1288.5c-100,-19 -206,-75 -281,-150c-104,-104 -154,-228 -154,-381c0,-144 38,-245 149,-392c64,-85 297,-316 348,-345c30,-17 31,-17 71,12c68,49 237,216 302,299c157,200 210,350 181,517c-25,142 -121,287 -241,364c-108,70 -251,98 -375,76z" id="svg_rw_2"/>
                </g>
                <g fill="${innerFill}" stroke="null" id="svg_rw_14">
                    <path stroke="null" d="m31.197816,62.647449c-1.45882,0 -2.63245,1.17363 -2.63245,2.632441s1.173639,2.63245 2.63245,2.63245s2.63245,-1.17363 2.63245,-2.63245s-1.173639,-2.632441 -2.63245,-2.632441zm8.774825,0c-1.458811,0 -2.632441,1.17363 -2.632441,2.632441s1.17363,2.63245 2.632441,2.63245s2.63245,-1.17363 2.63245,-2.63245s-1.17363,-2.632441 -2.63245,-2.632441zm8.774834,0c-1.45882,0 -2.63245,1.17363 -2.63245,2.632441s1.17363,2.63245 2.63245,2.63245s2.63245,-1.17363 2.63245,-2.63245s-1.173639,-2.632441 -2.63245,-2.632441zm8.774825,0c-1.458811,0 -2.63245,1.17363 -2.63245,2.632441s1.173639,2.63245 2.63245,2.63245s2.63245,-1.17363 2.63245,-2.63245s-1.17363,-2.632441 -2.63245,-2.632441zm26.170928,5.418457c-1.590436,-1.590445 -2.478894,-3.740277 -2.478894,-5.988828l0,-11.144026c0,-2.248551 0.888459,-4.398383 2.478894,-5.988819l4.025449,-4.025458c0.680056,-0.680046 0.680056,-1.798839 0,-2.478885l-2.478885,-2.478894c-0.680056,-0.680046 -1.798839,-0.680046 -2.478894,0l-4.025449,4.025458c-2.906666,2.906656 -4.540973,6.844361 -4.540973,10.957563l0,2.051113l-7.019868,0l0,-5.582978c0,-0.954271 -0.197428,-1.886592 -0.570362,-2.764075l-8.588363,-20.050485c-0.833612,-1.941429 -2.731169,-3.191847 -4.848095,-3.191847l-15.827599,0c-2.906656,0 -5.264891,2.358235 -5.264891,5.264901l0,12.284759l-1.754967,0c-2.906666,0 -5.264901,2.358235 -5.264901,5.264901l0,10.003302c-4.135133,1.974335 -7.019858,6.164315 -7.019858,11.056282c0,6.789524 5.495235,12.284759 12.284759,12.284759l28.079451,0c6.789524,0 12.284759,-5.495235 12.284759,-12.284759c0,-1.897557 -0.460678,-3.66349 -1.228477,-5.264891l4.738411,0l0,2.051113c0,4.113202 1.634308,8.050907 4.540973,10.957563l4.025449,4.025458c0.680056,0.680046 1.798839,0.680046 2.478894,0l2.478885,-2.478894c0.680056,-0.680046 0.680056,-1.798839 0,-2.478885l-4.025449,-4.025458l0,0.00001zm-44.598071,-39.640292l12.920943,0l7.524407,17.549659l-13.425482,0l-7.019868,-7.019868l0,-10.529792z" id="svg_rw_13"/>
                </g>
            </g>
        </svg>*/
};

export default iconMap;
