import {
    TextField,
    FormControl,
    InputLabel,
    InputAdornment,
    IconButton,
    Select,
    MenuItem
} from '@mui/material';

import { getStatusesRW, loadTypeCreation } from 'redux/RoadWorks/actions';

import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import DefectGroups from 'components/common/Autocomplete/RoadWorks/DefectGroups';
import DefectTypes from 'components/common/Autocomplete/RoadWorks/DefectTypes';
import Companies from 'components/common/Autocomplete/Companies';


const Form = ({ data, onChange }) => {
    const getValue = (key) => data?.[key] || '';
    const statuses = useStoreProp(getStatusesRW, 'roadworks', 'statuses');
    const typeCreation = useStoreProp(loadTypeCreation, 'roadworks', 'typeCreation');

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({
            [name]: value,
        });
    };

    const handleChangeGroup = (value) => {
        // id выбранных груп
        const groupIds = value.map(({ id }) => id) || [];
        // фильтруем типы от груп
        const newTypes = data
            ?.types_of_defections_ids
            ?.reduce((res, item) => groupIds.includes(item.group_id) ? [...res, item] : res, []);

        onChange({
            groups_of_defections_ids: value,
            types_of_defections_ids: newTypes,
        });
    };

    const handleChangeType = (value) => {
        onChange({
            types_of_defections_ids: value,
        });
    };

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >

            <div className="block">
                <TextField
                    label="Название"
                    value={data?.name || ''}
                    onChange={handleChange}
                    //helperText="Some important text"
                    name="name"
                    fullWidth
                    className="row__item"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {getValue('query').length > 0 ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => onChange({ name: '' })}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>x</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />
            </div>

            <div className="block">
                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    className="row__item"
                >
                    <InputLabel>Статус</InputLabel>
                    <Select
                        displayEmpty
                        fullWidth
                        labelId="status-label"
                        value={data?.status?.id || ''}
                        name="status"
                        // onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                        onChange={({ target: { value } }) => onChange({ status: { id: value, name: statuses[value] } })}
                        label="Статус"
                        variant="outlined"
                    >
                        {Object.keys(statuses).map((id) => (
                            <MenuItem key={id} value={id}>
                                {statuses[id]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <div className="block">
                <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    className="row__item"
                >
                    <InputLabel>Способ создания</InputLabel>
                    <Select
                        displayEmpty
                        fullWidth
                        labelId="create-label"
                        value={data?.type_creation?.id || ''}
                        name="status"
                        // onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                        onChange={({ target: { value } }) => onChange({ type_creation: { id: value, name: typeCreation[value] } })}
                        label="Способ создания"
                        variant="outlined"
                    >
                        {Object.keys(typeCreation).map((id) => (
                            <MenuItem key={id} value={id}>
                                {typeCreation[id]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <div className="block">
                <TextField
                    label="Адрес"
                    value={data?.address || ''}
                    onChange={handleChange}
                    //helperText="Some important text"
                    name="address"
                    fullWidth
                    className="row__item"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {getValue('address') ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => onChange({ address: '' })}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>x</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />
            </div>

            <div className="block">
                <TextField
                    label="Район"
                    value={data?.area || ''}
                    onChange={handleChange}
                    //helperText="Some important text"
                    name="area"
                    fullWidth
                    className="row__item"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {getValue('area').length > 0 ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => onChange({ area: '' })}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>x</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />
            </div>

            <div className="row">
                <div className="row__item" style={{ width: '45%' }}>
                    <DefectGroups
                        label="Группа недостатков"
                        selected={data?.groups_of_defections_ids || []}
                        multiple
                        onChange={handleChangeGroup}
                    />
                </div>

                <div className="row__item" style={{ width: '45%' }}>
                    <DefectTypes
                        selected={data?.types_of_defections_ids || []}
                        multiple
                        onChange={handleChangeType}
                        filter={{ group_ids: data?.groups_of_defections_ids?.map(({ id }) => id) || [] }}
                    />
                </div>
            </div>

            <div className="block">
                <Companies
                    selectDeletedСompanies
                    filter={{ withDeleted: 1 }}
                    label={titles.ORGANIZATION}
                    selected={data.organization_id}
                    onChange={(value) => {
                        onChange({
                            organization_id: {
                                ...value,
                                name: value.title
                            },
                        });
                    }}
                />
            </div>

        </form>
    );
};

export default Form;
