import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { isEqual } from 'lodash';

import * as actions from 'modules/Metro/redux/actions';
import { metroSelectors } from 'modules/Metro';
import Loading from 'components/common/Loading';
import SidebarItem from 'components/MapComponents/SidebarItem';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { usePrevious } from 'helpers/hooks';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import messages from 'helpers/constants/messages';
import renderAddress from 'helpers/renderAddress';

import config from '../config';
import { getFilter } from '../helper';
import iconCluster from '../icons/iconCluster';

import FilterForm from './FilterForm';

const SideBar = () => {
    const dispatch = useDispatch();
    const [params, setParams] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
    });

    const sidebar = useSelector(metroSelectors.stationSidebar);
    const loadingSidebar = useSelector(metroSelectors.loadingStationSidebar);
    const filters = useSelector(metroSelectors.filters);
    const active = useSelector(metroSelectors.active);

    const prevFilters = usePrevious(filters);

    const activeId = active?.id ?? 0;

    const fetchList = () => {
        const filter = getFilters(filters, getFilter);
        dispatch(actions.loadStationSidebar(
            params.page,
            params.limit,
            filter
        ));
    };

    const handleScrollUpdate = (values) => {
        const {
            last_page = 0
        } = sidebar?.meta || {};
        if (
            values.top > 0.95
            && loadingSidebar === false
            && params.page < last_page
        ) {
            setParams(old => ({
                ...old,
                page: old.page + 1,
            }));
        }
    };

    const resetParams = () => {
        setParams(prev => ({ ...prev, page: 1 }));
    };

    useEffect(() => () => {
        dispatch(actions.clearStationSidebar());
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            resetParams();
        } else {
            fetchList();
        }
    }, [params, filters]);

    const renderList = (dataList) => (
        dataList.map((item) => {
            return (
                <SidebarItem
                    key={item.id}
                    active={activeId === item.id}
                    icon={iconCluster({ fill: item?.color })}
                    onClick={() => { dispatch(actions.setActive(item)); }}
                >
                    <div style={{ color: item?.branch?.color }}>
                        <strong>{item.name}</strong>
                    </div>
                    <div>{item.address_text || renderAddress(item.address) || ''}</div>
                </SidebarItem>
            );
        })
    );


    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={resetParams}
                onSetFilter={(filter) => dispatch(actions.setFilters(filter))}
                onClearFilter={() => dispatch(actions.clearFilters())}
                layer={config.slug}
                content={({ data, onChange }) => (
                    <FilterForm
                        data={data}
                        onChange={onChange}
                    />
                )}
            />

            {loadingSidebar && sidebar?.data?.length === 0
                ? <Loading className="absolute bottom fill" />
                : <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={config.name}
                            list={sidebar}
                        />
                        {sidebar?.data?.length === 0
                            ? <div className="layers-sidebar__empty">
                                {messages.REQUEST_DATA_IS_NOT_FOUND}
                            </div>
                            : renderList(sidebar?.data || [])}
                    </div>
                    {loadingSidebar && <Loading className="center" />}
                </Scrollbars>
            }
        </div>
    );
};

export default SideBar;
