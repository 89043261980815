import * as types from './types';

/**
 * Actions
 **/
export const loadDtpTypes = (page = 1, limit = 25, data = {}) => ({
    type: types.LOAD_DTP_TYPES,
    payload: {
        page,
        limit,
        ...data,
    },
});

export const loadingDtpTypes = (bool = false) => ({
    type: types.LOADING_DTP_TYPES,
    payload: bool,
});

export const loadedDtpTypes = (data) => ({
    type: types.LOADED_DTP_TYPES,
    payload: data,
});

export const editDtpType = (params) => ({
    type: types.EDIT_DTP_TYPE,
    payload: params,
});

export const loading = (bool = false) => ({
    type: types.LOADING,
    payload: bool,
});

export const loadDtpList = (page = 1, limit = 25, data, replaceStore = true) => ({
    type: types.LOAD_DTP_LIST,
    payload: {
        page,
        limit,
        data,
        replaceStore
    },
});

export const loadedDtpList = (data, replaceStore = true) => ({
    type: types.LOADED_DTP_LIST,
    payload: {
        data,
        replaceStore,
    }
});

export const setDtpReportFilter = (data) => ({
    type: types.SET_DTP_REPORT_FILTER,
    payload: data
});

export const loadDtpLoadTypes = () => ({
    type: types.LOAD_DTP_LOAD_TYPES,
});

export const loadedDtpLoadTypes = (data) => ({
    type: types.LOADED_DTP_LOAD_TYPES,
    payload: data,
});

export const loadDtpSettings = (page = 1, limit = 100) => ({
    type: types.LOAD_DTP_SETTINGS,
    payload: {
        page,
        limit,
    },
});


export const loadingDtpSettings = (isLoading) => ({
    type: types.LOADING_DTP_SETTINGS,
    payload: isLoading
});

export const loadedDtpSettings = (data) => ({
    type: types.LOADED_DTP_SETTINGS,
    payload: data,
});

export const loadDtpSettingById = (id) => ({
    type: types.LOAD_DTP_SETTING_BY_ID,
    payload: id,
});

export const loadedDtpSettingById = (data) => ({
    type: types.LOADED_DTP_SETTING_BY_ID,
    payload: data,
});

export const editDtpSetting = (id, data) => ({
    type: types.EDIT_DTP_SETTING,
    payload: { id, data },
});

export const editSettingsList = (data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_LIST,
    payload: data,
    callback
});

export const loadDtpGraph = (params) => ({
    type: types.LOAD_DTP_GRAPH,
    payload: params,
});

export const loadedDtpGraph = (data) => ({
    type: types.LOADED_DTP_GRAPH,
    payload: data,
});

export const loadingDtpGraph = (bool = false) => ({
    type: types.LOADING_DTP_GRAPH,
    payload: bool,
});

export const clearDtpGraph = () => ({
    type: types.CLEAR_DTP_GRAPH
});

export const loadDtpStreets = (data) => ({
    type: types.LOAD_DTP_STREETS,
    payload: data
});

export const loadedDtpStreets = (data) => ({
    type: types.LOADED_DTP_STREETS,
    payload: data
});

export const loadingDtpStreets = (bool = false) => ({
    type: types.LOADING_DTP_STREETS,
    payload: bool
});

export const loadDtpPolygon = (polygon, params = {}) => ({
    type: types.LOAD_DTP_POLYGON,
    payload: {
        polygon,
        params
    }
});

export const loadedDtpPolygon = (data) => ({
    type: types.LOADED_DTP_POLYGON,
    payload: data
});
export const loadingDtpPolygon = (bool = false) => ({
    type: types.LOADING_DTP_POLYGON,
    payload: bool
});

export const clearDtpPolygon = () => ({
    type: types.LOADED_DTP_POLYGON,
    payload: []
});

export const setDtpPolygonVisible = (data = []) => ({
    type: types.SET_DTP_POLYGON_VISIBLE,
    payload: data
});

export const setDtpActive = (item = {}) => ({
    type: types.SET_DTP_ACTIVE,
    payload: item,
});

export const resetDtpActive = () => ({
    type: types.RESET_DTP_ACTIVE,
});


export const setDeleteForm = (data = null) => ({
    type: types.SET_DELETE_FORM,
    payload: data,
});

export const setEditForm = (data = null) => ({
    type: types.SET_EDIT_FORM,
    payload: data,
});


export const createNewDtp = (data, callback) => ({
    type: types.CREATE_NEW_DTP,
    payload: {
        data,
        callback
    },
});

export const editDtp = (id, data, callback) => ({
    type: types.EDIT_DTP,
    payload: {
        id,
        data,
        callback
    },
});

export const deleteDtp = (id) => ({
    type: types.DELETE_DTP,
    payload: {
        id,
    },
});

export const loadDtpById = (id) => ({
    type: types.LOAD_DTP_BY_ID,
    payload: id,
});

export const loadedDtpById = (id) => ({
    type: types.LOADED_DTP_BY_ID,
    payload: id,
});

export const saveDtp = (bool = false) => ({
    type: types.SAVE_DTP,
    payload: bool,
});

export const setFilters = (data = {}) => ({
    type: types.SET_FILTERS,
    payload: data,
});

export const clearFilters = () => ({
    type: types.SET_FILTERS,
    payload: {},
});

export const loadStreetList = (query) => ({
    type: types.LOAD_DTP_STREET_LIST,
    payload: {
        query,
        page: 1,
        limit: 100
    },
});

export const loadedStreetList = (data) => ({
    type: types.LOADED_DTP_STREET_LIST,
    payload: data,
});

// список дат для фильтра
export const loadDtpDates = () => ({
    type: types.LOAD_DTP_DATES,
});

export const loadedDtpDates = (data) => ({
    type: types.LOADED_DTP_DATES,
    payload: data,
});

export const loadDtpStatuses = () => ({
    type: types.LOAD_DTP_STATUSES,
});

export const loadedDtpStatuses = (data) => ({
    type: types.LOADED_DTP_STATUSES,
    payload: data
});

export const loadDtpSeverity = () => ({
    type: types.LOAD_DTP_SEVERITY,
});

export const loadedDtpSeverity = (data) => ({
    type: types.LOADED_DTP_SEVERITY,
    payload: data
});

export const loadDtpByArea = (params) => ({
    type: types.LOAD_DTP_BY_AREA,
    payload: params,
});

export const loadedDtpByArea = (data) => ({
    type: types.LOADED_DTP_BY_AREA,
    payload: data,
});

export const loadingDtpByArea = (bool = false) => ({
    type: types.LOADING_DTP_BY_AREA,
    payload: bool,
});

export const setDtpByAreaReportFilter = (data) => ({
    type: types.SET_DTP_BY_AREA_REPORT_FILTER,
    payload: data,
});

export const loadDtpByPercent = (params) => ({
    type: types.LOAD_DTP_BY_PERCENT,
    payload: params,
});

export const loadedDtpByPercent = (data) => ({
    type: types.LOADED_DTP_BY_PERCENT,
    payload: data,
});

export const loadingDtpByPercent = (bool = false) => ({
    type: types.LOADING_DTP_BY_PERCENT,
    payload: bool,
});

export const setDtpByPercentReportFilter = (data) => ({
    type: types.SET_DTP_BY_PERCENT_REPORT_FILTER,
    payload: data,
});


/**/
export const loadDangersArea = (params) => ({
    type: types.LOAD_DANGERS_AREA,
    payload: params,
});

export const loadedDangersArea = (data) => ({
    type: types.LOADED_DANGERS_AREA,
    payload: data,
});

export const loadingDangersArea = (bool = false) => ({
    type: types.LOADING_DANGERS_AREA,
    payload: bool,
});

export const clearDangersArea = () => ({
    type: types.CLEAR_DANGERS_AREA,
});

export const setDangersAreaReportFilter = (data) => ({
    type: types.SET_DANGERS_AREA_REPORT_FILTER,
    payload: data,
});

export const loadDtpFile = (file, service, callback) => ({
    type: types.LOAD_DTP_FILE,
    payload: file,
    callback,
});
export const loadingDtpFile = (bool = false) => ({
    type: types.LOADING_DTP_FILE,
    payload: bool,
});



export const loadReportMonthAreas = (params) => ({
    type: types.LOAD_DTP_REPORT_MONTH_AREAS,
    payload: params,
});
export const loadedReportMonthAreas = (data) => ({
    type: types.LOADED_DTP_REPORT_MONTH_AREAS,
    payload: data,
});
export const clearReportMonthAreas = () => ({
    type: types.CLEAR_DTP_REPORT_MONTH_AREAS,
});
export const loadingReportMonthAreas = (bool = false) => ({
    type: types.LOADING_DTP_REPORT_MONTH_AREAS,
    payload: bool,
});


export const loadReportStatistic = (ids) => ({
    type: types.LOAD_DTP_REPORT_STATISTIC,
    payload: { ids },
});
export const loadedReportStatistic = (data) => ({
    type: types.LOADED_DTP_REPORT_STATISTIC,
    payload: data,
});
export const clearReportStatistic = () => ({
    type: types.CLEAR_DTP_REPORT_STATISTIC,
});
export const loadingReportStatistic = (bool = false) => ({
    type: types.LOADING_DTP_REPORT_STATISTIC,
    payload: bool,
});


export const loadReportByCategory = (params) => ({
    type: types.LOAD_DTP_REPORT_BY_CATEGORY,
    payload: params,
});
export const loadedReportByCategory = (data) => ({
    type: types.LOADED_DTP_REPORT_BY_CATEGORY,
    payload: data,
});
export const clearReportByCategory = () => ({
    type: types.CLEAR_DTP_REPORT_BY_CATEGORY,
});
export const loadingReportByCategory = (bool = false) => ({
    type: types.LOADING_DTP_REPORT_BY_CATEGORY,
    payload: bool,
});
export const setReportCategoryFilter = (filter = {}) => ({
    type: types.SET_REPORT_CATEGORY_FILTER,
    payload: filter,
});

export const loadReportCategory = (params) => ({
    type: types.LOAD_DTP_REPORT_CATEGORY,
    payload: params,
});

export const loadedReportCategory = (data) => ({
    type: types.LOADED_DTP_REPORT_CATEGORY,
    payload: data,
});

export const loadReportCategoryTable = (params) => ({
    type: types.LOAD_DTP_REPORT_CATEGORY_TABLE,
    payload: params,
});

export const loadedReportCategoryTable = (data = []) => ({
    type: types.LOADED_DTP_REPORT_CATEGORY_TABLE,
    payload: data,
});

export const loadingReportCategoryTable = (bool = false) => ({
    type: types.LOADING_DTP_REPORT_CATEGORY_TABLE,
    payload: bool,
});

export const editDTPStatuses = (params, callback = () => {}) => ({
    type: types.EDIT_DTP_STATUSES,
    payload: params,
    callback
});

export const loadingEditStatus = (bool = false) => ({
    type: types.LOADING_EDIT_STATUS,
    payload: bool,
});


//
export const loadNewMap = (params) => ({
    type: types.LOAD_NEW_MAP,
    payload: params,
});
export const clearNewMap = () => ({
    type: types.LOADED_NEW_MAP,
    payload: [],
});
export const loadedNewMap = (data) => ({
    type: types.LOADED_NEW_MAP,
    payload: data,
});
export const loadingNewMap = (bool = false) => ({
    type: types.LOADING_NEW_MAP,
    payload: bool,
});


export const loadPopulation = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_POPULATION,
    payload: { page, limit, ...query }
});

export const loadingPopulation = (data) => ({
    type: types.LOADING_POPULATION,
    payload: data
});

export const loadedPopulation = (data) => ({
    type: types.LOADED_POPULATION,
    payload: data
});

export const createPopulation = (data, callback = () => {}) => ({
    type: types.CREATE_POPULATION,
    payload: data,
    callback
});

export const editPopulation = (id, data, callback = () => {}) => ({
    type: types.EDIT_POPULATION,
    payload: { id, data },
    callback
});

export const deletePopulation = (id, callback = () => {}) => ({
    type: types.DELETE_POPULATION,
    payload: id,
    callback
});


export const loadConcentrationArea = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_CONCENTRATION_AREA,
    payload: { page, limit, ...query }
});

export const loadingConcentrationArea = (data) => ({
    type: types.LOADING_CONCENTRATION_AREA,
    payload: data
});

export const loadedConcentrationArea = (data) => ({
    type: types.LOADED_CONCENTRATION_AREA,
    payload: data
});

export const createConcentrationArea = (data, callback = () => {}) => ({
    type: types.CREATE_CONCENTRATION_AREA,
    payload: data,
    callback
});

export const editConcentrationArea = (id, data, callback = () => {}) => ({
    type: types.EDIT_CONCENTRATION_AREA,
    payload: { id, data },
    callback
});

export const deleteConcentrationArea = (id, callback = () => {}) => ({
    type: types.DELETE_CONCENTRATION_AREA,
    payload: id,
    callback
});
export const loadingCEDConcentrationArea = (bool = false) => ({
    type: types.LOADING_CED_CONCENTRATION_AREA,
    payload: bool,
});

export const loadConcentrationAreaStats = (query = {}) => ({
    type: types.LOAD_CONCENTRATION_AREA_STATS,
    payload: query
});

export const loadedConcentrationAreaStats = (data) => ({
    type: types.LOADED_CONCENTRATION_AREA_STATS,
    payload: data
});

export const loadConcentrationAreaTypes = () => ({
    type: types.LOAD_CONCENTRATION_AREA_TYPES
});

export const loadedConcentrationAreaTypes = (data) => ({
    type: types.LOADED_CONCENTRATION_AREA_TYPES,
    payload: data
});

export const loadConcentrationAreaStatuses = () => ({
    type: types.LOAD_CONCENTRATION_AREA_STATUSES
});

export const loadedConcentrationAreaStatuses = (data) => ({
    type: types.LOADED_CONCENTRATION_AREA_STATUSES,
    payload: data
});

export const loadDtpOnRadius = (params) => ({
    type: types.LOAD_DTP_ON_RADIUS,
    payload: params
});

export const loadedDtpOnRadius = (data) => ({
    type: types.LOADED_DTP_ON_RADIUS,
    payload: data
});

export const loadingDtpOnRadius = (data) => ({
    type: types.LOADING_DTP_ON_RADIUS,
    payload: data
});

// showcase
export const loadShowcase = () => ({
    type: types.LOAD_SHOWCASE,
});

export const loadingShowcase = (bool = false) => ({
    type: types.LOADING_SHOWCASE,
    payload: bool
});

export const loadedShowcase = (data) => ({
    type: types.LOADED_SHOWCASE,
    payload: data
});

export const clearShowcase = () => ({
    type: types.LOADED_SHOWCASE,
});

export const loadDtpSettingByName = (name) => ({
    type: types.LOAD_DTP_SETTING_BY_NAME,
    payload: name
});
export const loadedDtpSettingByName = (data) => ({
    type: types.LOADED_DTP_SETTING_BY_NAME,
    payload: data
});
export const clearDtpSettingByName = () => ({
    type: types.LOADED_DTP_SETTING_BY_NAME,
    payload: {}
});

export const setMKDTPModalData = (data) => ({
    type: types.SET_MKDTP_MODAL_DATA,
    payload: data
});

export const setMKDTPFilterParams = (data) => ({
    type: types.SET_MKDTP_FILTER_PARAMS,
    payload: data
});

// Settings Group
export const loadSettingsGroup = () => ({
    type: types.LOAD_SETTINGS_GROUP,
});

export const loadingSettingsGroup = (bool) => ({
    type: types.LOADING_SETTINGS_GROUP,
    payload: bool
});

export const loadedSettingsGroup = (data) => ({
    type: types.LOADED_SETTINGS_GROUP,
    payload: data
});

export const editSettingsGroup = (id, data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_GROUP,
    payload: { id, data },
    callback
});

// loading button
export const loadingButton = (bool) => ({
    type: types.LOADING_BUTTON,
    payload: bool
});

// dtp by time report
export const loadDtpByTime = (data) => ({
    type: types.LOAD_DTP_BY_TIME,
    payload: data
});

export const loadingDtpByTime = (bool) => ({
    type: types.LOADING_DTP_BY_TIME,
    payload: bool
});

export const loadedDtpByTime = (data) => ({
    type: types.LOADED_DTP_BY_TIME,
    payload: data
});

export const clearDtpByTime = () => ({
    type: types.LOADED_DTP_BY_TIME,
    payload: []
});
