class HttpError extends Error {
    constructor(message, status) {
        super(message || '');
        this.className = 'HttpError';
        this.status = status;
        if (Error.captureStackTrace instanceof Function) {
            Error.captureStackTrace(this, HttpError);
        }
    }
}

export { HttpError };
