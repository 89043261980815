import queryString from 'query-string';

// сгенерировать массив записей
export const getExpandArray = (arr) => arr
    ?.reduce((res, value) => {
        if (value) {
            return [
                ...res,
                res.length > 0
                    ? res.join('_') + '_' + value.toString()
                    : value.toString()
            ];
        }

        return res;
    },[]);

export const findElement = (id, arrStructure) => {
    const init = {
        complex: null,
        patch: [],
    };

    return arrStructure?.reduce((res, { data }, regionIndex) => {
        const innerRes = data?.reduce((r, { complexes }, contractIndex) => {
            const finded = complexes?.find(item => item.id === Number(id)) || null;
            if (finded) {
                return {
                    complex: r.complex || finded,
                    patch: [
                        contractIndex + 1 || 'zero',
                        finded.id
                    ],
                };
            }
            return r;
        }, init);

        if (innerRes.complex) {
            return {
                ...res,
                ...innerRes,
                patch: innerRes.patch.length > 0
                    ? [
                        regionIndex + 1 || 'zero',
                        ...innerRes.patch
                    ]
                    : innerRes.patch,
            };
        }
        return res;
    }, init);
};

export const filterFunc = (params) => {
    const asArray = Object.entries(params).filter(([key, value]) => value);
    return Object.fromEntries(asArray);
};

// разбираем урл в виде строки на часть path и часть query
export const parseUrlString = (str) => {
    const splitedString = str.split('?');
    return {
        path: splitedString[0],
        query: splitedString[1]
    };
};

// превращем params в search, чтобы добавить в урл
export const stringifyParamsForUrl= (param = {},) => {
    return queryString.stringify(filterFunc(param), { arrayFormat: 'bracket' });
};
// достаем params из search в виде объекта
export const parseParamsFromUrl= (serachStr = '') => {
    return queryString.parse(serachStr, { arrayFormat: 'bracket' });
};
export const compareQueryStrings = (query1, query2) => {
    const { page: page1, limit: limit1, ...querySaved } = parseParamsFromUrl(query1);
    const { page: page2, limit: limit2, ...queryCurrent } = parseParamsFromUrl(query2);
    const linkContainsSearchParams = Object.entries(querySaved).reduce((res, [key, val]) => {
        if (!queryCurrent[key] || queryCurrent[key] !== val) {
            res = res && false;
        }
        return res;
    }, true);
    return linkContainsSearchParams;
};
