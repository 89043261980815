import React, { useMemo, useState } from 'react';
import FormButtons from '../../FormButtons';
import MapModalForm from './MapModalForm';
import { FormHelperText } from '@mui/material';
import { useValidation } from '../../../../helpers/hooks';
import { serviceList } from './helper';

// остановки кт и маршрут
const SationRoute = ({
    // маршрут
    road,
    // остановки
    points = [],
    onChange, // (route, points)
    // 'transportPassenger'
    // 'transportRoad'
    service = serviceList.transportPassenger,
    // валидация полей
    validationKeys = [],
}) => {
    const validation = useValidation(false);

    const [open, setOpen] = useState(false);
    const isAdd = useMemo(() => {
        return Object.keys(road).length === 0 && points.length === 0;
    }, [road, points]);

    const handleChange = (route, points) => {
        validation.deleteKeys(validationKeys);
        onChange?.(route, points);
    };

    return (
        <div>

            {/* кнопка */}
            <div className="form__button">
                <FormButtons
                    buttons={[
                        {
                            name: `${isAdd ? 'Добавить' : 'Изменить'} маршрут и 
                                ${service === serviceList.transportPassenger ? 'остановки' : 'контрольные точки'}
                            `,
                            onClick: () => setOpen(true),
                        },
                    ]}
                    noMarginLeft
                    noPadding
                    positionLeft
                />
            </div>

            {/* ошибки валидации */}
            {validationKeys.map(key => (
                <FormHelperText error key={key}>
                    {validation.get(key)}
                </FormHelperText>
            ))}

            {/* модалка редактирования */}
            {open && (
                <MapModalForm
                    road={road}
                    points={points}
                    isOpen={open}
                    onClose={() => setOpen(false)}
                    onChange={handleChange}
                    service={service}
                />
            )}

        </div>
    );
};

export default SationRoute;