import React, { useEffect, useState } from 'react';
import Filters from './Filters';
import Item from './Item';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../../helpers/constants/messages';
import CustomPagination from '../../../../common/CustomPagination';
import { clearVAReportFilter, loadCameraEvents } from '../../../../../redux/VideoAnalytics/actions';
import styles from './videoAnalytic.module.scss';
import Loading from '../../../../common/Loading';
import { useHistory } from 'react-router-dom';
import { videoAnalyticsSelectors } from '../../../../../redux/VideoAnalytics';

const TransportToStopReport = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const events = useSelector(videoAnalyticsSelectors.events);
    const eventsLoading = useSelector(videoAnalyticsSelectors.eventsLoading);

    // const { goToDashboard } = useSelector(({ videoAnalytics }) => videoAnalytics?.videoAnalyticsReportFilter);

    // const [isGoToDashboard, setIsGoToDashboard] = useState(goToDashboard);
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState({});
    const storageLimit = !!localStorage.getItem('limit') 
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);

    // useEffect(() => {
    //     // загрузка списка, если поменялся фильтр - если он пустой, не загружаем
    //     if (Object.keys(params).length > 0) {
    //         dispatch(loadCameraEvents(1, limit, params));
    //     }
    // }, [limit, params, dispatch]);

    useEffect(() => {
        startLoading();
    }, []);

    const startLoading = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    };

    return (
        <div className={styles.videoAnalytic}>
            <h1>Отчет по выезду ТС на остановку</h1>
            <Filters setFilter={startLoading} />
            {loading && <Loading circular={true}/>}
            {events.data?.length > 0
                ?  (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
                // ? <>
                //     <List>
                //         {events.data.map((item, i) => <Item params={params} key={i} data={item} />)}
                //     </List>
                //     <CustomPagination
                //         loadList={(page) => dispatch(loadCameraEvents(page, limit, params))}
                //         list={events.meta}
                //         limit={limit} 
                //         setLimit={setLimit}
                //     />
                // </>
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </div>
    );
};

export default TransportToStopReport;
