import React, { useState } from 'react';
import { TextField } from '@mui/material';
import LayoutFilter from '../../layout/PageLayout/LayoutFilter';
import titles from '../../../helpers/constants/titles';

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{ page: number; data: { name?: string } }>>;
}

const Filter = ({ setParams }: FilterProps) => {
    const initState = { name: '' };

    const [filter, setFilter] = useState(initState);

    const onChange = (name: string, value: string) => {
        setFilter({ ...filter, [name]: value });
    };

    const resetFilter = (needRefresh: boolean) => {
        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }

        setFilter(initState);
    };

    const search = () => {
        setParams({ page: 1, data: filter });
    };

    const updateFilter = (filters: { [key: string]: any }) => {
        setFilter({ ...initState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({ page: 1, data: { ...initState, ...filters } });
        }
    };

    return (
        <LayoutFilter
            filter={filter}
            onResetFilter={resetFilter}
            onSearch={search}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    label={titles.NAME}
                    value={filter.name}
                    onChange={(e) => onChange('name', e.target.value)}
                    name="name"
                    variant="outlined"
                    size="small"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
