import React from 'react';
import messages from '../../../../../helpers/constants/messages';
import config from '../config';
import { getFullDateTimeWithTextMonth } from '../../../../../helpers/date.config';


// всплывашка при наведении
const Tooltip = (props) => {
    const statusesObject = props?.statusesObject || {};

    const fields = {
        registered_at: {
            name: 'Зарегистрировано',
            f: ({ registered_at }) => getFullDateTimeWithTextMonth(registered_at) || messages.INFO_IS_NOT_FOUND
        },
        name: {
            name: 'Наименование',
        },
        type_text: {
            name: 'Тип'
        },
        status_text: {
            name: 'Статус',
            f: ({ status_id, status_text }) => `<span style="color:${statusesObject?.[status_id]?.color || config.mapMarkersColors.default}">${status_text}</span>`
        },
        threat_level: {
            name: 'Уровень угрозы',
            f: ({ threat_level }) => {
                if (threat_level?.name) {
                    return `<span style="color: ${threat_level?.color}">${threat_level?.name}</span>`;
                }
                return messages.INFO_IS_NOT_FOUND;
            }
        },
    };

    return (
        <div>
            {Object.keys(fields).map((field) => {
                const f = fields[field].f ? fields[field].f(props) : null;
                const value = f || props[field] || null;
                const name = fields[field].name || '';

                return (
                    <div key={`information_${field}`}>
                        <strong>
                            {name}
                            {': '}
                        </strong>
                        <span
                            dangerouslySetInnerHTML={{ __html: value || messages.INFO_IS_NOT_FOUND }}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default Tooltip;
