import React, { memo, useCallback, useMemo, useState } from 'react';
import ChangePart from './ChangePart';
import ModePart from './ModePart';
import FormButtons, { buttonsTypes } from '../../../../../../common/FormButtons';
import { DataChangeTypes, ExtraOptionsTypes } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { editRouteAdditionalParams, loadRegularTransportations, loadSeasonality, loadVehicleClasses } from '../../../../../../../redux/TransportPassenger/actions';
import { useStoreProp } from '../../../../../../../helpers/hooks';
import ConfirmModal from '../../../../../../common/ConfirmModal';
import messages from '../../../../../../../helpers/constants/messages';
import { transportPassengerSelectors } from '../../../../../../../redux/TransportPassenger';

const ExtraOptions = memo(({ typeTransportation, currentRoute }: ExtraOptionsTypes) => {    
    const dispatch = useDispatch();
    const loadingButton = useSelector(transportPassengerSelectors.loadingButton);    

    const vehicleClasses = useStoreProp(
        loadVehicleClasses,
        'transportPassenger',
        'vehicleClasses'
    );

    const regularTransportations = useStoreProp(
        loadRegularTransportations,
        'transportPassenger',
        'regularTransportationsList'
    );

    const routeSeasonality = useStoreProp(
        loadSeasonality,
        'transportPassenger',
        'routeSeasonality'
    );
                    
    const initState = useMemo(() => ({
        payment_in_cash: currentRoute.payment_in_cash || {
            tariff_passenger: 0,
            tariff_baggage: 0
        },
        payment_cashless: currentRoute.payment_cashless || {
            tariff_passenger: 0,
            tariff_baggage: 0
        },
        comment: currentRoute?.comment || '',
        payment_file: currentRoute?.payment_file || null,
        vehicle_classes: currentRoute?.vehicle_classes || [],
        type_of_regular_transportation: currentRoute?.type_of_regular_transportation || '',
        type_transportation: currentRoute?.type_transportation || '',
        seasonality: currentRoute?.seasonality || '',
    }), [currentRoute?.comment, 
        currentRoute.payment_cashless, 
        currentRoute?.payment_file, 
        currentRoute.payment_in_cash, 
        currentRoute?.seasonality, 
        currentRoute?.type_of_regular_transportation, 
        currentRoute?.type_transportation, 
        currentRoute?.vehicle_classes]);
    
    const [edit, setEdit] = useState(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
    const [data, setData] = useState<DataChangeTypes>(initState);
    
    const onSaveExtraOptions = useCallback(() => {
        dispatch(editRouteAdditionalParams(currentRoute.id, data));
        setEdit(false);
        setIsOpenConfirmModal(false);
    }, [currentRoute.id, data, dispatch]);

    const onClose = useCallback(() => {
        setData(initState);
        setEdit(false);
    }, [initState]);

    const isDisabled = !data.type_of_regular_transportation 
        || !data.seasonality 
        || !data.payment_cashless.tariff_passenger
        || !data.payment_cashless.tariff_baggage
        || !data.payment_in_cash.tariff_passenger
        || !data.payment_in_cash.tariff_baggage;

    return (
        <>
            {edit 
                ? <>
                    <ChangePart 
                        data={data}
                        setData={setData}
                        typeTransportation={typeTransportation}
                        vehicleClasses={vehicleClasses}
                        regularTransportations={regularTransportations}
                        routeSeasonality={routeSeasonality}
                    />
                    <div className="filter filter__wrap__btn">
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.save,
                                    size: 'small',
                                    onClick: () => setIsOpenConfirmModal(true),
                                    disabled: isDisabled
                                },
                                {
                                    ...buttonsTypes.cancel,
                                    onClick: onClose
                                },
                            ]}
                        />
                    </div>
                </>
                : <>
                    <ModePart
                        data={currentRoute}
                        vehicleClasses={vehicleClasses}
                    />
                    <div className="filter filter__wrap__btn" style={{ marginTop: 20 }}>
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.edit,
                                    size: 'small',
                                    onClick: () => setEdit(true),
                                },
                            ]}
                        />
                    </div>
                </> 
            }
            {isOpenConfirmModal && (
                <ConfirmModal
                    open={isOpenConfirmModal}
                    onClose={() => setIsOpenConfirmModal(false)}
                    onSuccess={onSaveExtraOptions}
                    message={messages.CONFIRM_SAVE}
                    loadingButton={loadingButton} 
                    onlyClose={false}                
                />
            )}   
        </>
    );
});

export default ExtraOptions;
