import PageLayout from 'components/layout/PageLayout';
import Filter from './Filter';

const Report = () => {
    return (
        <PageLayout
            header="Отчет по времени работы Камер"
            filters={<Filter />}
        />
    );
};

export default Report;
