import L from 'leaflet';
import 'leaflet.marker.slideto';
// import { v4 as uuidv4 } from 'uuid';

// circle icon
export const TransportSmallIcon = L.DivIcon.extend({
    options: {
        color: '',
        iconSize: [10, 10]
    },

    createIcon: function (oldIcon) {
        this._wrap = L.DivIcon.prototype.createIcon.call(this, oldIcon);
        const options = this.options;
        if (options.id) {
            this._wrap.id = options.id;
        }
        this._wrap.innerHTML = `<div class="circle" style="background:${options.color}"></div>`;
        return this._wrap;
    },

    setColor: function (color) {
        const element = this._wrap.querySelector('.circle');
        if (this.options.color !== color && element) {
            this.options.color = color;
            element.style['background'] = color;
        }
    }
});

// transport icon
export const TransportBigIcon = L.DivIcon.extend({
    // default value
    options: {
        id: '',
        direction: 0,
        iconSize: [30, 30],
        bgIcon: '',
        img: '',
        delta: 0,
        route: '',
        color: '',
    },

    // очистка
    // empty: function (el) {
    //     while (el.firstChild) {
    //         el.removeChild(el.firstChild);
    //     }
    // },

    // проверить изменилось ли значение
    isNew: function(key, value) {
        return this.options[key] !== value;
    },

    // текст слева
    isLeft: function(direction) {
        const d = direction || this.options.direction || 0;
        return (0 <= d && d <= 180) || (-360 < d && d < -180);
    },

    createIcon: function (oldIcon) {
        this._wrap = L.DivIcon.prototype.createIcon.call(this, oldIcon);
        const options = this.options;
        if (options.id) {
            this._wrap.id = options.id;
        }
        this._wrap.innerHTML = `
            <div class="image">
                <div class="bg" style="transform: rotate(${options.direction}deg)">
                    ${options.bgIcon(options.color)}
                </div>
                <div class="icon">
                    ${options.img}
                </div>
            </div>
        `;
        this.renderRoute();
        this.renderDelta();
        return this._wrap;
    },

    // отобразить маршрут
    renderRoute: function() {
        const textElement = this._wrap.querySelector('.text');

        if (!this.options.route && textElement) {
            // удаление
            textElement.remove();
        } else if (this.options.route && !textElement) {
            // добавление
            const text = document.createElement('div');
            text.classList.add('text');
            const isLeft = this.isLeft(this.options.direction);
            if (isLeft) text.classList.add('to-left');
            text.innerHTML = `<span class="route" style="color: ${this.options.color}">${this.options.route}</span>`;
            this._wrap.append(text);
        } else if (textElement) {
            // изменение
            const routeElement = this._wrap.querySelector('.route');
            routeElement.innerText = this.options.route;
        }
    },

    // маршрут
    setRoute: function(route) {
        // есть маршрут
        this.options.route = route;
        this.renderRoute();
    },

    // установить направление
    // direction угол в градусах
    setDirection: function (direction) {
        if (this.isNew('direction', direction)) {
            this.options.direction = direction;

            const bgElement = this._wrap.querySelector('.bg');
            if (bgElement) bgElement.style['transform'] = `rotate(${direction}deg)`;

            const textElement = this._wrap.querySelector('.text');
            const isLeft = this.isLeft(direction);

            if (textElement) {
                if (isLeft && !textElement.classList.contains('to-left')) {
                    textElement.classList.add('to-left');
                } else if (!isLeft && textElement.classList.contains('to-left')) {
                    textElement.classList.remove('to-left');
                }
            }
        }
    },

    // установить цвет
    setColor: function(color) {
        if (this.isNew('color', color)) {
            this.options.color = color;

            const routeElement = this._wrap.querySelector('.route');
            if (routeElement) {
                routeElement.style['color'] = color;
            }

            const bgElement = this._wrap.querySelector('.bg');
            if (bgElement) {
                bgElement.innerHTML = this.options.bgIcon(color);
            }
        }
    },

    // фоновая иконка
    // setBgIcon: function(icon) {
    //     if (this.isNew('bgIcon', icon)) {
    //         this.options.bgIcon = icon;
    //
    //         const bgElement = this._wrap.querySelector('.bg');
    //         if (bgElement) {
    //             bgElement.innerHTML = icon;
    //         }
    //     }
    // },

    // иконка транспорта
    setImg: function(img) {
        if (this.isNew('img', img)) {
            this.options.img = img;

            const iconElement = this._wrap.querySelector('.icon');
            if (iconElement) {
                iconElement.innerHTML = img;
            }
        }
    },

    renderDelta: function() {
        const delta = this.options.delta;
        // красная стрелка вверх в случае опережения прихода ТС на последнюю КТ;
        // красная стрелка вниз в случае опоздания прихода ТС на последнюю КТ;

        const getIconClass = () => `fa-long-arrow-${delta > 0 ? 'up' : 'down'}`;

        // ищем стрелку в иконке
        const deltaElement = this._wrap.querySelector('.delta');

        if (delta === 0 && deltaElement) {
            // удаление
            deltaElement.remove();
        } else if (delta !== 0 && !deltaElement) {
            // добавление
            const textElement = this._wrap.querySelector('.text');
            if (textElement) {
                const text = document.createElement('div');
                text.classList.add('delta', 'fa', getIconClass());
                textElement.appendChild(text);
            }
        } else if (deltaElement) {
            // изменение
            deltaElement.classList.remove('fa-long-arrow-up', 'fa-long-arrow-down');
            deltaElement.classList.add(getIconClass());
        }
    },

    // установить опережение
    setDelta: function(delta) {
        if (this.isNew('delta', delta)) {
            this.options.delta = delta;
            this.renderDelta();
        }
    },
});

// transport marker
export const TransportMarker = L.Marker.extend({
    // создание
    // icons - объект иконок по зуму { зум: иконка }
    initialize: function (latlng, options, icons = {}) {
        L.Marker.prototype.initialize.call(this, latlng, options);
        this._oldLatLng = latlng;
        this._icons = icons;
        this._angle = 0;
        this._zoomKey = 0;
        this._isMooved = false;
        this._delta = 0;
        this._event = 0;
        this._color = null;
        this._img = null;
        // this._id = options.id || uuidv4();
    },

    // получить угол
    _getAngle: function (A, B) {
        const radToDeg = (radian) => radian * 180 / Math.PI;
        const { 0: latA, 1: lonA } = A;
        const { 0: latB, 1: lonB } = B;
        if (lonA === lonB && latA > latB) {
            return radToDeg(Math.PI);
        } else if (lonA === lonB && latA < latB) {
            return radToDeg(0);
        } else if (lonA > lonB && latA === latB) {
            return radToDeg(-Math.PI / 2);
        } else if (lonA < lonB && latA === latB) {
            return radToDeg(Math.PI / 2);
        }
        const x1 = latA * Math.pow(10,12);
        const x2 = latB * Math.pow(10,12);
        const y1 = lonA * Math.pow(10,12);
        const y2 = lonB * Math.pow(10,12);

        return radToDeg(Math.atan2(y2 - y1,x2 - x1));
    },

    // получить ключ зума
    _getZoomKey: function(listZoom, zoom) {
        if (listZoom.length > 0) {
            const a = listZoom.pop();
            if (zoom >= a) return a;
            return this._getZoomKey(listZoom, zoom);
        }
        return listZoom[0] || 0;
    },

    // обработка изменения зума
    _changeZoom: function(ev) {
        // текущий зум
        const zoom = this._map.getZoom();
        const zoomKey = this._getZoomKey(Object.keys(this._icons), zoom);

        if (this._zoomKey !== zoomKey) {
            this._zoomKey = zoomKey;

            const iconOption = {};
            if (this._angle) iconOption.direction = this._angle;
            if (this._delta) iconOption.delta = this._delta;
            if (this._color) iconOption.color = this._color;
            if (this._img) iconOption.img = this._img;

            this.setIcon(this._icons[zoomKey]?.(iconOption));

            this.update();
        }

        if (this._tootlip) this.closeTooltip();
    },

    // tooltipopen: function () {
    //     const currentId = document.getElementById(this._id);
    //     if (currentId) {
    //         currentId.innerHTML = this.options.tooltip;
    //     }
    // },
    //
    // tooltipclose: function () {
    //     const currentId = document.getElementById(this._id);
    //     if (currentId) currentId.remove();
    // },

    getTooltip: function() {
        return [
            this.options.tooltip,
            this.options.deltaTooltip(this._delta, this._event)
        ].join('');
    },

    changeTooltip: function () {
        if (!this._tootlip && this.options.tooltip) {
            const toolTip = L.tooltip({
                offset: [0, -15],
                direction: 'top'
            });
            toolTip.setContent(this.getTooltip());
            this.bindTooltip(toolTip);
            this._tootlip = toolTip;
        } else if (this._tootlip && !this.options.tooltip) {
            this.unbindTooltip();
            this._tootlip.remove();
            this._tootlip = null;
        } else if (this._tootlip) {
            this._tootlip.setContent(this.getTooltip());
        }
        this.update();
        // this.on('tooltipopen', this.tooltipopen, this);
        // this.on('tooltipclose', this.tooltipclose, this);
    },

    removeTooltip: function () {
        if (this._tootlip) {
            this.unbindTooltip();
            this._tootlip.remove();
            this._tootlip = null;
        }
        // this.off('tooltipopen', this.tooltipopen, this);
        // this.off('tooltipclose', this.tooltipclose, this);
    },

    setTooltip: function (tooltip) {
        this.options.tooltip = tooltip;
        this.changeTooltip();
    },

    // removeById: function(id) {
    //     const currentId = document.getElementById(id);
    //     if (currentId) currentId.remove();
    // },
    // createPopup: function () {
    //     if (this.options.popupTemplate) {
    //         const getId = `popup-${this._id}`;
    //
    //         const popUp = L
    //             .popup({
    //                 minWidth: 600,
    //                 closeOnClick: true,
    //                 className: 'custom-popup-wrapper',
    //                 offset: [0, -10],
    //             })
    //             .setContent(`<div class="popup-route" id="${getId}"></div>`);
    //
    //         const onClosePopUp = () => {
    //             this.isPopupOpen() && this.closePopup();
    //         };
    //         this.bindPopup(popUp);
    //
    //         const popupclose = () => {
    //             const currentId = document.getElementById(getId);
    //             if (currentId) unmountComponentAtNode(currentId);
    //         };
    //
    //         //newMarker.on('click', handleShowPopup(newId, key, [lat,lon]));
    //         // this.on('click', handleShowPopup(newId, key, [lat,lon], onClosePopUp, data[key]));
    //         this.on('click', this.options.popupTemplate(getId, key, [lat,lon], onClosePopUp, data[key]));
    //
    //         this.on('popupclose', popupclose, this);
    //
    //         this._popUp = popUp;
    //     }
    // },
    // removePopup: function () {
    //     if (this._popUp) {
    //         this.unbindPopup();
    //         this._popUp.remove();
    //         this._popUp = null;
    //
    //         this.off('click', this.options.popupTemplate, this);
    //         this.off('popupclose', this.popupclose, this);
    //     }
    //     // this.off('tooltipopen', this.tooltipopen, this);
    //     // this.off('tooltipclose', this.tooltipclose, this);
    // },

    movestart: function () {
        this._isMooved = true;
    },
    moveend: function () {
        this._isMooved = false;
    },

    onAdd: function (map) {
        L.Marker.prototype.onAdd.call(this, map);
        map.on('zoomend', this._changeZoom, this);
        this.on('movestart', this.movestart, this);
        this.on('moveend', this.moveend, this);

        this._changeZoom();
        this.changeTooltip();
        this.update();
    },

    onRemove: function(map) {
        map.off('zoomend', this._changeZoom, this);
        map.off('movestart', this.movestart, this);
        map.off('moveend', this.moveend, this);

        this.removeTooltip();
        L.Marker.prototype.onRemove.call(this, map);
    },

    // направление
    _setDirection: function (direction) {
        this.options.icon.setDirection?.(direction);
    },

    setRoute: function (route) {
        this.options.icon.setRoute?.(route);
    },

    _setColor: function (color) {
        this.options.icon.setColor?.(color);
    },
    // цвет
    setColor: function (color) {
        this._color = color;
        this._setColor(color);
    },

    _setDelta: function (delta) {
        this.options.icon.setDelta?.(delta);
    },
    // опережение
    setDelta: function (delta) {
        this._delta = delta;
        this._setDelta(delta);
        this.changeTooltip();
    },

    // сдвиг на координаты
    setNewLatLon: function (latlng) {
        if (this._isMooved) {
            this.slideCancel();
            this.slideTo(this._oldLatLng, {
                duration: 1000,
                // keepAtCenter
            });
        }

        // получение угла
        this._angle = this._getAngle(this._oldLatLng, latlng);

        // установка направления
        this._setDirection(this._angle);

        this._oldLatLng = latlng;

        this.slideTo(latlng, {
            duration: 5000,
            // keepAtCenter
        });
    },

    _setImg: function (img) {
        this.options.icon.setImg?.(img);
    },

    // иконка категории
    setImg: function (img) {
        this._img = img;
        this._setImg(img);
    },

    setEvent: function (event) {
        this._event = event;
        this.changeTooltip();
    },
});
