import { useState } from 'react';

import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

import config from '../config';

const EventFilters = ({ setParams, trafficId }) => {
    const initialFilter = {
        start_date: getStartOf(),
        end_date: getEndOf()
    };
    
    const [data, setData] = useState(initialFilter);

    const onSearch = (filter = data) => {
        setParams({
            start_date: fullDateTimeWithTimeZone(filter.start_date),
            end_date: fullDateTimeWithTimeZone(filter.end_date),
        });
    }; 

    const resetFilter = (needRefresh) => {
        needRefresh && onSearch(initialFilter);
        setData(initialFilter);
    };

    const updateFilter = (filters) => {
        const newFilter = { ...initialFilter, ...filters };
        setData(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };
    
    return (
        <div style={{ marginTop: '1.2rem' }}>
            <LayoutFilter
                filter={data}
                onSearch={onSearch}
                onResetFilter={resetFilter} 
                setUserFilter={updateFilter}
                isFilterVisible
                filterException={['start_date', 'end_date']}
                layer={config.slug + trafficId}
            >
                <LayoutFilter.Visible>
                    <DateRange 
                        valueStartDate={data.start_date}
                        valueEndDate={data.end_date}
                        handleDate={(start_date) => setData({ ...data, start_date })}
                        handleEndDate={(end_date) => setData({ ...data, end_date })}
                    />
                </LayoutFilter.Visible>
            </LayoutFilter>
        </div>
    );
};

export default EventFilters;