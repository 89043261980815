import React, { useEffect, useState } from 'react';
import Form from './Form';
import Modal from '../../../../common/Modal';
import { useDispatch, useSelector } from 'react-redux';
import titles from '../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { saveDtpFocus } from '../../../../../redux/FocusIncidents/actions';
import { useValidation } from '../../../../../helpers/hooks';
import { fullDateTimeWithTimeZone } from '../../../../../helpers/date.config';
import { focusIncidentsSelectors } from '../../../../../redux/FocusIncidents';


const initialState = {
    id: null,
    registered_at: new Date(),
    accessory: '',
    address: {},
    address_text: '',
    lat: '',
    lon: '',
};

// формы
const ModalForm = (props) => {
    const {
        data = {},
        onAccept = () => {},
        onClose = () => {},
        open = false
    } = props;

    const dispatch = useDispatch();

    const saved = useSelector(focusIncidentsSelectors.saved);
    const loading = useSelector(focusIncidentsSelectors.loading);

    const validation = useValidation();

    const [fields, setFields] = useState(initialState);
    const [isOpen, setIsOpen] = useState(open);

    const requiredFields = [
        'lat',
        'lon',
        'registered_at',
    ];

    // новые данные сохранили
    useEffect(() => {
        if (saved) {
            setIsOpen(false);
            dispatch(saveDtpFocus(false));
        }
    },[saved]);

    // данные в форме изменились
    useEffect(() => {
        if (!!data) {
            const newData = {
                ...initialState,
                ...data,
            };
            setFields(newData);
            setIsOpen(true);
        }
    }, [data]);

    const handleClose = () => {
        onClose();
        setIsOpen(false);
    };

    const handleSuccess = () => {
        const newFields = {
            ...fields,
            registered_at: fields.registered_at ? fullDateTimeWithTimeZone(fields.registered_at) : null
        };

        onAccept(newFields);
        onClose();
    };

    const isNew = !fields?.id;
    const title = isNew ? titles.ADD : titles.EDIT;

    const handleChange = (changeData) => {
        validation.deleteKeys(Object.keys(changeData));

        setFields({
            ...fields,
            ...changeData
        });
    };

    const isFormValid = requiredFields.reduce((res, key) => {
        return res && fields[key];
    }, true) && validation.isValidation() === false;

    // форма редактирования 
    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            noPadding
            title={title}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: handleClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSuccess,
                            disabled: !isFormValid
                        }
                    ]}
                />
            }
        >
            <Form
                data={fields}
                requiredFields={requiredFields}
                onChange={handleChange}
                validation={validation}
                loading={loading}
            />
        </Modal>
    );
};

export default ModalForm;
