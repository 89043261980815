import PageLayout from 'components/layout/PageLayout';

import Filter from './Filter';

const Companies = () => {
    return (
        <PageLayout
            header="Отчет по организациям"
            filters={<Filter />}
        />
    );
};

export default Companies;
