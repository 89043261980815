import titles from '../../../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../../../common/FormButtons';
import { TextField } from '@mui/material';
import Modal from '../../../../../../common/Modal';
import { useState } from 'react';

const NoneTypicalOperationsModal = ({ isOpen, onClose, onChange, data }) => {

    const [name, setName] = useState('');

    const handleSave = () => {
        onChange('operation_list', [
            ...data.operation_list,
            {
                id: Date.now(), // временно добавляем ID, чтобы DnD работал
                name: name,
                isNew: true
            }
        ]);

        setName('');
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title={titles.ADD}
            small={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.create,
                        onClick: handleSave,
                        disabled: name.trim().length === 0
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <TextField
                    label="нетиповая операция"
                    size="small"
                    value={name}
                    variant="outlined"
                    name="operation_list"
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </form>
        </Modal>
    );
};

export default NoneTypicalOperationsModal;