import React from 'react';
import { CircleMarker, Marker, ToolTip } from '../../../../MapComponents/leaflet';
import { getTimeWithoutSeconds } from '../../../../../helpers/date.config';
import messages from '../../../../../helpers/constants/messages';
// import L from 'leaflet';
// import { createIcon } from '../../../../MapComponents/Layers/DtpFocus/helper';

const HistoryPoints = (props) => {
    const {
        data = [],
        ...params
    } = props;

    // const markerIcon = L.divIcon({
    //     className: 'marker',
    //     iconSize: [23, 30],
    //     iconAnchor: [12, 30],
    //     popupAnchor: [0, -30],
    //     tooltipAnchor: [0, -33],
    //     html: '<i class="fas fa-map-marker-alt" style="font-size:30px;color:#ff0000;"/>',
    // });

    return (
        <>
            {data.map((item, index) => {
                const {
                    geometry,
                    properties: { time, is_nearest = false }
                } = item || {};

                if (geometry?.coordinates && time) {
                    return (
                        <Marker
                            {...params}
                            key={`circlemarker_${index}`}
                            latlng={geometry?.coordinates}
                            // color={'#00442C'}
                            // icon={markerIcon}
                        >
                            <ToolTip
                                direction="top"
                                offset={[0, -38]}
                                // offset={[-13, 0]}
                                // direction="left"
                                // permanent={is_nearest}
                                opacity={'0.7'}
                                className={'tooltipBorderRed'}
                            >
                                <strong>{getTimeWithoutSeconds(time) || messages.NO_DATA}</strong>
                            </ToolTip>
                        </Marker>
                    );
                    // return (
                    //     <CircleMarker
                    //         {...params}
                    //         key={`circlemarker_${index}`}
                    //         latlng={geometry?.coordinates}
                    //         radius={12}
                    //         weight={1}
                    //         color={'#00442C'}
                    //         fillColor={'#FF0000'}
                    //         fillOpacity={1}
                    //     >
                    //         <CircleMarker
                    //             latlng={geometry?.coordinates}
                    //             radius={6}
                    //             weight={1}
                    //             color={'#00442C'}
                    //             fillColor="#fff"
                    //             fillOpacity={1}
                    //         >
                    //             <ToolTip
                    //                 offset={[-13, 0]}
                    //                 direction="left"
                    //                 // permanent={is_nearest}
                    //                 opacity={'0.7'}
                    //                 className={'tooltipBorderRed'}
                    //             >
                    //                 <strong>{getTimeWithoutSeconds(time) || messages.NO_DATA}</strong>
                    //             </ToolTip>
                    //         </CircleMarker>
                    //     </CircleMarker>
                    // );
                }
                return null;
            })}
        </>
    );
};

export default HistoryPoints;
