import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    TextField,
} from '@mui/material';

import { loadCurrentCompany } from 'redux/Companies/actions';
import { companiesSelectors } from 'redux/Companies';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import buttons from 'helpers/constants/buttons';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SelectInfrastructureTypes from 'components/common/Autocomplete/RoadNetwork/InfrastructureTypes';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import TitleValidator from 'components/common/Location/TitleValidator';
import FieldsModal from 'components/common/Location/FieldsModal';
import getLatLonFromGeometry from 'components/MapComponents/helpers/getLatLonFromGeometry';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import AddressList from 'components/common/Location/AddressList';

import RepairObjects from './RepairObjects';

const AddEditModal = ({
    isNew = false,
    onClose = () => {},
    onAcceptModal = () => {},
    el= {},
    isOpen = false
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);
    const currentCompany = useSelector(companiesSelectors.currentCompany);
    const initialState = {
        address: el?.address || {},
        address_text: el?.address_text || '',
        lat: el?.lat || '',
        lon: el?.lon || '',
        name: el?.name || '',
        description: el?.description || '',
        geometry: el?.geometry || {},
        type_id: el?.type_id || '',
        organization_id: el?.organization_id || '',
        repair_objects: el?.repair_objects || []
    };

    const [data, setData] = useState(initialState);
    const [selectedType, setSelectedType] = useState(el?.type_id ? el.type : {});
    const [selectedOrganization, setSelectedOrganization] = useState(el?.organization_id
        ? el.organization || {}
        : {}
    );

    const [dependence, setDependence] = useState(true);

    useEffect(() => {
        const { lat, lon } = el || {};
        if (el?.geometry && !lat && !lon) {
            const { lat, lon } = getLatLonFromGeometry(el?.geometry);
            if (lat && lon) {
                setData(old => ({
                    ...old,
                    lat,
                    lon
                }));
            }
        }
    }, []);

    const onChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    useEffect(() => {
        if (el?.organization_id && !el?.organization && !currentCompany[el?.organization_id]) {
            dispatch(loadCurrentCompany(el?.organization_id));
        }
    }, [dispatch, el?.organization_id, el?.organization, currentCompany]);

    useEffect(() => {
        if (currentCompany[el?.organization_id]) {
            setSelectedOrganization(currentCompany[el?.organization_id]);
        }
    }, [currentCompany, el?.organization_id]);

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
        }
    }, [validation, onClose, requestStatus]);

    const isDisabled = !data.name || !data.type_id || !data.geometry;

    const onSave = () => {
        onAcceptModal(data);
    };

    const onChangeGeoJsonCoordinates = (params) => {
        setData((prevData) => ({
            ...prevData,
            ...params,
        }));
        validation.deleteKeys(Object.keys(params || {}));
    };

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            noPadding
            maxWidthProp={'md'}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isDisabled,
                        onClick: onSave
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl required className="block" size="small" variant="outlined">
                    <TextField
                        required
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={data.name}
                        name="name"
                        onChange={onChange}
                        type="text"
                    />
                </FormControl>
                <div className="block">
                    <SelectInfrastructureTypes
                        onChange={(type) => {
                            setSelectedType(type);
                            setData({ ...data, type_id: type.id });
                        }}
                        selected={selectedType}
                        required
                        error={validation.isKey('type_id')}
                        helperText={validation.get('type_id')}
                    />
                </div>

                <FormControlLabel
                    style={{ pointerEvents: 'none' }}
                    control={
                        <Checkbox
                            checked={dependence}
                            onChange={(event) => setDependence(event.target.checked)}
                            color="primary"
                            style={{ pointerEvents: 'auto' }}
                        />
                    }
                    label="Адрес зависит от координат"
                />

                <FormControl className="block" variant="outlined">
                    {/* lat lon */}
                    <LoadAddressByCoords
                        {...data}
                        isLoadOnLatLon={false}
                        isLoadOnChange={dependence}
                        onChange={onChangeGeoJsonCoordinates}
                    >
                        <LatLonCoordinates required/>
                    </LoadAddressByCoords>
                </FormControl>

                <FormControl className="block" variant="outlined">
                    <LoadAddressByCoords
                        {...data}
                        onChange={onChangeGeoJsonCoordinates}
                        isLoadOnLatLon={dependence}
                        isLatLonColumnView={true}
                    >
                        <AddressList required />
                    </LoadAddressByCoords>
                </FormControl>

                <div className="block">

                    <TitleValidator
                        fields={data}
                        validationFields={['geometry']}
                    >
                        {titles.LOCATION} *:
                    </TitleValidator>

                    {validation.isKey('geometry')
                        ? (
                            <FormHelperText className="error">
                                {validation.get('geometry')}
                            </FormHelperText>
                        )
                        : null
                    }

                    <FieldsModal
                        title="Выбрать область на карте"
                        fields={{ geometry: data?.geometry || {} }}
                        buttonText={buttons.SELECT_ON_MAP}
                        buttonType={buttonsTypes.selectOnMap}
                        buttonVariant="contained"
                        onChange={data => {
                            setData(old => ({
                                ...old,
                                ...data,
                            }));
                        }}
                    >
                        <MapGeoJson
                            circle={true}
                            polygon={true}
                            polyline={true}
                            marker={true}
                            required
                            isHideValidation
                            returnLatLon={dependence}
                        />
                    </FieldsModal>
                </div>
                <div className="block">
                    <SelectCompany
                        filter={{ withDeleted: 1 }}
                        selected={selectedOrganization}
                        onChange={(org) => {
                            setSelectedOrganization(org);
                            setData({ ...data, organization_id: org?.id || null });
                        }}
                    />
                </div>
                <TextField
                    className="block"
                    label={titles.DESCRIPTION}
                    multiline
                    rows={3}
                    variant="outlined"
                    value={data.description}
                    name="description"
                    onChange={onChange}
                    error={validation.isKey('description')}
                    helperText={validation.get('description')}
                />

                <RepairObjects
                    repairObjects={data.repair_objects}
                    setData={setData}
                />
            </div>
        </Modal>
    );
};

export default AddEditModal;
