import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { loadCameraInfo } from 'redux/VideoAnalytics/actions';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';

import type { InfoList } from '../types';

interface SelectVehicleModelProps {
    value: string
    onChange: (value: string) => void
    disabled: boolean
    vehicleBrand: string
}

const SelectVehicleModel = ({ value, onChange, disabled, vehicleBrand }: SelectVehicleModelProps) => {
    const infoList: InfoList = useStoreProp(loadCameraInfo, 'videoAnalytics', 'infoList');

    const vehicle_models = vehicleBrand ? infoList?.brand_and_model?.[vehicleBrand] : null;

    return (
        <FormControl variant="outlined" size="small">
            <InputLabel>Модель ТС</InputLabel>
            <Select
                label="Модель ТС"
                name="vehicle_model"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
            >
                {vehicle_models ? (
                    vehicle_models.map((item) => (
                        <MenuItem key={item} value={item}>
                            {item}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default SelectVehicleModel;
