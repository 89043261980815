import React, { useState, ChangeEvent } from 'react';
import { TextField } from '@mui/material';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import SelectCompany from 'components/common/Autocomplete/Companies';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

interface Organization {
    id: number;
    name: string;
}

interface FilterProps {
    setParams: (params: { page: number; data: FiltersState | {} }) => void;
}

interface FiltersState {
    uuid: string[];
    name: string;
    organization_ids: Organization[];
}

const Filters = ({ setParams }: FilterProps) => {
    const initialState: FiltersState = {
        uuid: [],
        name: '',
        organization_ids: [],
    };

    const [filters, setFilters] = useState<FiltersState>(initialState);

    const handleChangeFields = (name: keyof FiltersState, value: string | string[] | Organization[]) => {
        setFilters({ ...filters, [name]: value });
    };

    const search = () => {
        const prepareData = removeEmptyFields(filters, false);

        setParams({
            page: 1,
            data: {
                ...prepareData,
                organization_ids: filters.organization_ids.map(item => item.id)
            }
        });
    };

    const resetFilter = (needRefresh: boolean) => {
        setFilters(initialState);
        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }
    };

    const updateFilter = (newFilters: Partial<FiltersState>) => {
        const updatedFilters = { ...initialState, ...newFilters };
        setFilters(updatedFilters);

        if (Object.keys(newFilters).length > 0) {
            const prepareData = removeEmptyFields(updatedFilters, false);
            setParams({
                page: 1,
                data: {
                    ...prepareData,
                    organization_ids: updatedFilters.organization_ids.map(item => item.id)
                }
            });
        }
    };

    const isDisabled = Object.keys(removeEmptyFields(filters)).length === 0;

    return (
        <LayoutFilter
            filter={filters}
            onSearch={search}
            onResetFilter={resetFilter}
            setUserFilter={updateFilter}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <MultipleInput
                    value={filters.uuid}
                    onChange={(e) => handleChangeFields('uuid', e)}
                    label="UUID"
                    // guideText="Добавление одного или нескольких элементов, разделяя запятой"
                />
                <TextField
                    value={filters.name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeFields('name', e.target.value)}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                />
                <SelectCompany
                    multiple
                    selected={filters.organization_ids}
                    onChange={(value) => handleChangeFields('organization_ids', value as Organization[])}
                    filter={{ withDeleted: 1 }}
                    selectDeletedCompanies
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;
