import { IconButton, Tooltip } from '@mui/material';
import React, { memo } from 'react';
import { LSContainer, LSContentColumn, LSContentItem, LSHeaderColumn, LSHeaderItem } from '../../../../../../common/ListStyle';
import { Delete } from '@mui/icons-material';
import titles from '../../../../../../../helpers/constants/titles';
import { VehicleClassesListType } from './types';

const VehicleClassesList = memo(({ data, selectList, onDelete = () => {}, change }: VehicleClassesListType) => {

    const list = Array.isArray(data) && data.length > 0
        ? data.map((item: { class_id: string | number; }) => ({
            ...item,
            ...(selectList.find((element: { id: number; }) => element.id === item.class_id) || {})
        }))
        : [];
    
    return (
        <LSContainer>
            <LSHeaderItem fullWidth>
                <LSHeaderColumn >
                    {titles.TITLE}
                </LSHeaderColumn>
                <LSHeaderColumn >
                    {titles.COUNT}
                </LSHeaderColumn>
                <LSHeaderColumn align="right" isActions>
                    {change ? 'Действия' : ''}
                </LSHeaderColumn>
            </LSHeaderItem>
            {list?.map((item: {
                class_id: number
                count: string; 
                name: string
            }) => (
                <LSContentItem key={item.class_id} fullWidth style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <LSContentColumn title={''} >
                        {item?.name}
                    </LSContentColumn>
                    <LSContentColumn title={''} >
                        {item?.count}
                    </LSContentColumn>
                    <LSContentColumn title={''} align="right">
                        { change && <Tooltip title={titles.DELETE}>
                            <IconButton
                                onClick={() => onDelete(item.class_id)}
                                size="small"
                            >
                                <Delete fontSize="inherit" />
                            </IconButton>
                        </Tooltip>}
                    </LSContentColumn>
                </LSContentItem>
            ))}
        </LSContainer>
    );
});

export default VehicleClassesList;