import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { Icon } from '@mui/material';

import * as actions from 'redux/RoadNetwork/actions';
import { roadNetworksSelectors } from 'redux/RoadNetwork';
import Loading from 'components/common/Loading';
import messages from 'helpers/constants/messages';

import SidebarTitle from '../../../SidebarTitle';
import SidebarItem from '../../../SidebarItem';
import { createIcon, getColor } from '../helper';
import config from '../config';
import Item from './Item';

const SideBar = (props) => {
    const dispatch = useDispatch();
    const [params, setParams] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25
    });
    const sidebar = useSelector(roadNetworksSelectors.infrastructureList);
    const sidebarLoading = useSelector(roadNetworksSelectors.loadingInfrastructureList);
    const active = useSelector(roadNetworksSelectors.activeInfrastructureLayer);

    const activeId = Object.keys(active).length > 0 ? active.id : 0;

    const fetchList = () => {
        dispatch(actions.loadInfrastructureList({
            page: params.page,
            limit: params.limit,
        }));
    };

    useEffect(() => {
        fetchList();
    }, [params]);

    const handleScrollUpdate = (values) => {
        const last_page = sidebar?.meta?.last_page || 0;
        if (
            values.top > 0.98
            && sidebarLoading === false
            && params.page < last_page
        ) {
            setParams({
                ...params,
                page: params.page + 1
            });
        }
    };

    const renderList = (dataList) => (
        <>
            {dataList.map((item) => {
                return (
                    <SidebarItem
                        key={`${item.type_text}_${item.id}`}
                        active={activeId === item.id && item.type_text === active?.type_text}
                        iconComponent={<Icon color="primary" />}
                        icon={createIcon(getColor(item))}
                        // icon={iconCircle(getColor(item),createIcon(item))}
                        // buttons={{
                        //     edit: {
                        //         title: buttons.EDIT,
                        //         icon: <i className="fas fa-pen" />,
                        //         onClick: () => {
                        //             // редактирование
                        //             // dispatch(actions.setEditForm(item));
                        //             providerForm.showEditForm(item);
                        //         }
                        //     },
                        //     delete: {
                        //         title: buttons.DELETE,
                        //         icon: <i className="fas fa-trash-alt" />,
                        //         onClick: () => {
                        //             // удаление
                        //             // dispatch(actions.setDeleteForm(item.id));
                        //             providerForm.showConfirmDelete(item.id);
                        //         }
                        //     }
                        // }}
                        onClick={() => {
                            dispatch(actions.setActiveInfrastructureLayer(item));
                        }}
                    >
                        <Item {...item}  />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            {/* <SidebarFilter
                resetListPage={() => setParams(old => ({ ...old, page: 1 }))}
                onSetFilter={(filter) => dispatch(actions.setFilterPolygonInfrastructure(filter))}
                onClearFilter={() => dispatch(actions.setFilterPolygonInfrastructure())}
                content={({ data, onChange }) => <FilterForm data={data} onChange={onChange} />}
                layer={config.slug}
            /> */}

            {sidebarLoading && sidebar?.data?.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <>
                    <Scrollbars
                        onUpdate={handleScrollUpdate}
                        renderTrackHorizontal={(props) => (
                            <div {...props} className="track-horizontal" />
                        )}
                    >
                        <div className="layers-sidebar__layer">
                            <SidebarTitle
                                title={config.name}
                                list={sidebar}
                            />
                            {sidebar?.data?.length === 0
                                ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                                : renderList(sidebar?.data)}
                        </div>
                        {sidebarLoading && <Loading className="center" />}
                    </Scrollbars>
                </>
            )}

        </div>
    );
};

export default SideBar;