import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TextField } from '@mui/material';

import { loadOrderStatuses } from 'redux/RoadWorks/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import DateRange from 'components/common/Dates/DateRange';
import SelectCompany from 'components/common/Autocomplete/Companies';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SelectWorkordersRW from 'components/common/Autocomplete/RoadWorks/Workorders';
import OrderTypesRWSelect from 'components/common/Autocomplete/OrderTypesRW';

const initialState = {
    start_date: null,
    end_date: null,
    organization_ids: [],
    name: '',
    status: [],
    workorder_id: '',
    type: {},
    statuses: [], // тут будут полные объекты с name и тд
};

const Filters = ({ order = {}, setParams }) => {
    const orderStatuses = useStoreProp(loadOrderStatuses, 'roadworks', 'orderStatuses');
    const { state } = useLocation();
    const history = useHistory();

    const [filterValues, setFilterValues] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);

    const prepareData = (filter = filterValues) => removeEmptyFields({
        ...filter,
        organization_ids: filter.organization_ids?.map(item => item.id),
        workorder_id: filter.workorder_id?.id,
        type: filter?.type?.id,
        statuses: [],
        start_date: fullDateTimeWithTimeZone(filter?.start_date),
        end_date: fullDateTimeWithTimeZone(filter?.end_date),
    }, false);

    useEffect(() => {
        if (order && Object.keys(order).length > 0) {
            setFilterValues( {
                ...initialState,
                name: order?.name || '',
                status: order?.status ? [order?.status] : [],
                statuses: order?.status ? orderStatuses.filter(el => el.id === order?.status) : [],
                organization_ids: order?.organizations || [],
                start_date: order?.created_at || null,
            });
        }
    }, [order, orderStatuses]);

    const handleChange = (value, key) => {
        const fields = filterValues;
        delete fields.ids;
        setFilterValues({
            ...fields,
            [key]: value,
        });
    };

    const resetFilter = (needRefresh) => {
        if (needRefresh) {
            setParams(prepareData(initialState));
        }
        setFilterValues(initialState);

        if (state?.id) {
            history.replace({ state: {} });
        }
    };

    const onSearch = () => {
        setParams(prepareData(filterValues));
    };

    const updateFilter = (filters = {}) => {
        setFilterValues({
            ...initialState,
            ...filters,
        });

        if (Object.keys(filters).length > 0) {
            setParams({
                ...prepareData(initialState),
                ...prepareData(filters),
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={onSearch}
            filter={filterValues}
            filterException={['start_date', 'end_date']}
            countException={['statuses']} // поля которые не надо считать
            disabled={isDisabled}
            wasSearchAtStart={!!state?.id}
            {...(!state?.id && { setUserFilter: updateFilter })}
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(value) => handleChange(value, 'start_date')}
                    handleEndDate={(value) => handleChange(value, 'end_date')}
                    valueStartDate={filterValues.start_date}
                    valueEndDate={filterValues.end_date}
                    isDisabledHandler={setIsDisabled}
                />
                <TextField
                    variant="outlined"
                    size="small"
                    value={filterValues.name}
                    label="Название"
                    onChange={({ target: { value } }) => handleChange(value, 'name')}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <OrderTypesRWSelect
                    onChange={(value) => setFilterValues({ ...filterValues, type: value })}
                    selected={filterValues.type}
                />
                <CommonAutocomplete
                    multiple
                    selected={filterValues.statuses}
                    options={orderStatuses}
                    renderLabel={(option) => option.name}
                    onChange={(value) =>setFilterValues((prev) => ({
                        ...prev,
                        statuses: value,
                        status: value.map(el =>  el.status_id)
                    }))}
                    filterSelectedOptions
                    isOptionEqualToValue={(opt, val) => opt.status_id === val.status_id}
                    onReset={() =>setFilterValues((prev) => ({
                        ...prev,
                        statuses: [],
                        status: []
                    }))}
                    label={titles.STATUS}
                    showAsSelect
                />

                <SelectCompany
                    multiple={true}
                    selected={filterValues.organization_ids}
                    onChange={(value) => handleChange(value, 'organization_ids')}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                />
                <SelectWorkordersRW
                    selected={filterValues.workorder_id}
                    onChange={(value) => handleChange(value, 'workorder_id')}
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filters;
