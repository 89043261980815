import Filter from './Filter';
import { useSelector } from 'react-redux';
import PageLayout from '../../../../layout/PageLayout';
import { dorisControlSelectors } from '../../../../../redux/DorisControl';


const ReportDocumentsLeave = () => {
    const loading = useSelector(dorisControlSelectors.loadingInspectionCertificate);

    return (<PageLayout
        header={'Отчет об истечении сроков документов'}
        filters={<Filter />}
        loading={loading}
    />);
};

export default ReportDocumentsLeave;
