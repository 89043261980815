import React, { useEffect } from 'react';
import {
    Typography,
    Checkbox,
    FormHelperText,
    FormControlLabel,
} from '@mui/material';
import SingleKeyboardDateTimePicker from '../../../../../common/Dates/SingleKeyboardDateTimePicker';
import formatDate from '../../../../../../helpers/constants/dateFormat';
import { useValidation } from '../../../../../../helpers/hooks';
import { makeStyles } from '@mui/styles';
import {
    getTimeWithoutSeconds,
} from '../../../../../../helpers/date.config';
import { parse } from 'date-fns';

const useStyles = makeStyles({
    w100: {
        width: '100%'
    }
});

const WeekDays = ({
    value = [], // or null
    onChange,
    validationKey = 'schedule',
    setError = () => {},
}) => {

    const classes = useStyles();
    const errorCheck = (start_at, end_at) => parse(start_at, formatDate.HH_MM, new Date()) > parse(end_at, formatDate.HH_MM, new Date());

    const validation = useValidation(false);
    const getKeyValidation = (index, key) => validation.get(`${validationKey}.${index}.${key}`);
    const deleteKeyValidation = (index, key) => validation.deleteKey(`${validationKey}.${index}.${key}`);

    // дни недели
    const days = {
        Monday: 'Понедельник',
        Tuesday: 'Вторник',
        Wednesday: 'Среда',
        Thursday: 'Четверг',
        Friday: 'Пятница',
        Saturday: 'Суббота',
        Sunday: 'Воскресенье',
    };

    const timeDefault = {
        start_at: '00:00',
        end_at: '23:59',
    };

    useEffect(() => {
        if (
            !value
            || value?.length === 0
            || value?.length < Object.keys(days)
        ) {
            // преобразуем значения к объекту
            const valueObj = value
                .reduce((res, item) => ({
                    ...res,
                    [item?.day]: item,
                }), {});

            // заполняем данными по умолчанию
            onChange(
                Object
                    .keys(days)
                    .map(key => ({
                        day: key,
                        active: true,
                        ...timeDefault,
                        ...(valueObj?.[key] || {}),
                    }))
            );
        }
    }, []);

    useEffect(() => {
        if (setError) {
            const error = value?.reduce((res, { start_at, end_at }) => {
                return res || errorCheck(start_at, end_at);
            }, false);

            setError(error);
        }
    }, [setError, value]);

    const handleChange = (index, key, newValue) => {
        deleteKeyValidation(index, key);

        if (key === 'active' && newValue === false) {
            deleteKeyValidation(index, 'start_at');
            deleteKeyValidation(index, 'end_at');
        }

        onChange([
            ...value?.slice(0, index),
            {
                ...value?.[index],
                [key]: newValue,
                // сбрасываем время по дефолту
                ...(key === 'active' && newValue === false ? timeDefault : {})
            },
            ...value?.slice(index + 1),
        ]);
    };

    return (
        <div>
            <Typography>
                <strong>
                    Дни недели:&nbsp;
                </strong>
            </Typography>

            <div>
                {Object.keys(days).map((dayKey) => {
                    const dayName = days[dayKey];
                    // индекс в value
                    const currentObjIndex = value?.findIndex(({ day }) => day === dayKey);
                    // объект
                    const currentObjValue = value?.[currentObjIndex] || {};

                    const {
                        active = false,
                        start_at = '',
                        end_at = '',
                    } = currentObjValue;

                    const error = errorCheck(start_at, end_at);

                    return (
                        <div key={dayKey}>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={active || false}
                                            onChange={e => handleChange(
                                                currentObjIndex,
                                                'active',
                                                e.target.checked
                                            )}
                                            color="primary"
                                            size="small"
                                        />
                                    }
                                    label={<strong>{dayName}</strong>}
                                    className={classes.root}
                                />
                            </div>

                            <div className="row">
                                <div className="row__item">
                                    <SingleKeyboardDateTimePicker
                                        className={classes.w100}
                                        timeOnly={true}
                                        label={'С'}
                                        onChange={e => handleChange(
                                            currentObjIndex,
                                            'start_at',
                                            getTimeWithoutSeconds(e)
                                        )}
                                        value={start_at}
                                        pickerFormat={formatDate.HH_MM}
                                        required
                                        isDisabled={!active}
                                        error={!start_at || getKeyValidation(currentObjIndex,'start_at') || error}
                                    />
                                    {getKeyValidation(currentObjIndex,'start_at')
                                        && (
                                            <FormHelperText error>
                                                {getKeyValidation(currentObjIndex,'start_at')}
                                            </FormHelperText>
                                        )}
                                </div>

                                <div className="row__item">
                                    <SingleKeyboardDateTimePicker
                                        className={classes.w100}
                                        timeOnly={true}
                                        label={'По'}
                                        onChange={e => handleChange(
                                            currentObjIndex,
                                            'end_at',
                                            getTimeWithoutSeconds(e)
                                        )}
                                        value={end_at}
                                        pickerFormat={formatDate.HH_MM}
                                        required
                                        isDisabled={!active}
                                        error={!end_at || getKeyValidation(currentObjIndex,'end_at') || error}
                                    />
                                    {getKeyValidation(currentObjIndex,'end_at')
                                        && (
                                            <FormHelperText error>
                                                {getKeyValidation(currentObjIndex,'end_at')}
                                            </FormHelperText>
                                        )}
                                </div>
                            </div>

                            {error && (
                                <FormHelperText error>
                                    Время в поле "с" должно быть меньше, либо равно времени в поле "по"
                                </FormHelperText>
                            )}

                            {getKeyValidation(currentObjIndex,'day') && (
                                <FormHelperText error>
                                    {getKeyValidation(currentObjIndex,'day')}
                                </FormHelperText>
                            )}

                        </div>
                    );
                })}
            </div>

        </div>
    );
};

export default WeekDays;