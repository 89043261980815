import { TextField } from '@mui/material';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import removeEmptyFields from 'helpers/removeEmptyFields';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const Filter = ({ setParams }) => {

    const initState = {
        name: '',
    };

    const [data, setData] = useState(initState);

    const handleSearch = (filters = data) => {
        const paramsWithoutEmpty = removeEmptyFields(filters);
        setParams({ filter: paramsWithoutEmpty, page: 1 });
    };

    const handleResetFilter = (needRefresh) => {
        setData(initState);
        if (needRefresh) {
            setParams({ filter: removeEmptyFields(initState), page: 1 });
        }
    };

    const isDisabled = isEqual(initState, data);

    const updateFilter = (filters) => {
        setData({
            ...initState,
            ...filters
        });

        if (Object.keys(filters).length) {
            handleSearch({
                ...initState,
                ...filters
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(old => ({
            ...old,
            [name]: value
        }));
    };

    return (
        <LayoutFilter
            onResetFilter={handleResetFilter}
            onSearch={handleSearch}
            filter={data}
            setUserFilter={updateFilter}  // если у пользователя сохранен фильтр для этой страницы, мы его из общего компонента сетаем сюда
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <FormInfoWrapper>
                    <TextField
                        value={data?.name}
                        variant="outlined"
                        size="small"
                        label="Название"
                        name="name"
                        onChange={handleChange}
                    />
                </FormInfoWrapper>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
