import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import messages from '../../../../../helpers/constants/messages';
import { loadTrackingDeployedInfo } from '../../../../../redux/WantedCars/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../common/Loading';
import { fullDateTimeWithoutSeconds } from '../../../../../helpers/date.config';

const useStyles = makeStyles({
    preview: {
        display: 'flex',
        width: '100%',
        '& > span': {
            maxWidth: 250,
            width: '100%',
        }
    },
    accordion: {
        background: 'rgba(0, 0, 0, 0.05)',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        padding: '8px 16px 16px 0'
    },
    noData: {
        margin: '0 25px'
    },
    item: {
        display: 'flex',
        width: '100%',
        margin: '10px 20px',
        '& > span': {
            width: '100%',
            '&:nth-child(1)': {
                maxWidth: 245
            },
            '&:nth-child(2)': {
                maxWidth: 250
            },
            '&:nth-child(3)': {
                maxWidth: 250
            }
        }
    },
});

const ListAccordion = ({ list, id_grz }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { loadingDeployedTracking, deployedTracking } = useSelector(({ carsWanted }) => carsWanted);

    const [expanded, setExpanded] = useState(false);

    const handleOpenDeployedInfo = (panel, grz) => (event, isExpanded) => {
        dispatch(loadTrackingDeployedInfo(id_grz, grz));
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        setExpanded(false);
    }, [list]);

    return (
        <>
            {list?.map((info, index) => (
                <Accordion className={classes.accordion} key={index} square expanded={expanded === index} onChange={handleOpenDeployedInfo(index, info.grz)}>
                    <AccordionSummary
                        expandIcon={<ExpandLess />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <div className={classes.preview}>
                            <span><strong>Гос номер ТС:</strong> {info.grz || messages.NO_DATA}</span>
                            <span><strong>Бренд:</strong> {info.vehicle_brand || messages.NO_DATA}</span>
                            <span><strong>Модель:</strong> {info.vehicle_model || messages.NO_DATA}</span>
                            <span><strong>Количество фиксаций:</strong> {info.count || messages.NO_DATA}</span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        {loadingDeployedTracking && <Loading linear={true}/>}
                        {deployedTracking?.data.length > 0
                            ? (deployedTracking?.data.map((item) => (
                                <div key={item.uuid} className={classes.item}>
                                    <span><strong>Цвет:</strong> {item.vehicle_color || messages.NO_DATA}</span>
                                    <span><strong>Широта:</strong> {item.lat === 0 ? '0' : item.lat || messages.NO_DATA}</span>
                                    <span><strong>Долгота:</strong> {item.lon === 0 ? '0' : item.lat || messages.NO_DATA}</span>
                                    <span><strong>Дата и время фиксации:</strong> {fullDateTimeWithoutSeconds(item.saved_at) || messages.NO_DATA}</span>
                                </div>
                            )))
                            : (!loadingDeployedTracking && <div className={classes.noData}>{messages.DATA_IS_NOT_FOUND}</div>)
                        }
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};

export default ListAccordion;
