import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadDtpByTime } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import {
    dateWithDashYYYYMMDD,
    getDateWithDuration,
    getEndOf,
    getStartOf,
    getTimeWithoutSeconds
} from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import messages from 'helpers/constants/messages';

import Filters from './Filters';
import Item from './Item';

import type { DtpItem, FilterValues } from './types';
import titles from 'helpers/constants/titles';


const DtpByTimePeriod = () => {
    const dispatch = useDispatch();

    const data: DtpItem[] = useSelector(incidentsSelectors.dtpByTime);
    const loading = useSelector(incidentsSelectors.loadingDtpByTime);

    const initialState = {
        start_date: getStartOf('day', getDateWithDuration({ day: - 1 })),
        end_date: getEndOf('day'),
        start_time: null,
        end_time: null,
    };

    const [params, setParams] = useState<FilterValues>(initialState);
    const [searchedTime, setSearchedTime] = useState<Record<string, string | null>>({});

    const paramsWithoutEmpty = (filters = params) => removeEmptyFields({
        start_date: filters.start_date && dateWithDashYYYYMMDD(filters.start_date),
        end_date: filters.end_date && dateWithDashYYYYMMDD(filters.end_date),
        start_time: filters.start_time && getTimeWithoutSeconds(filters.start_time),
        end_time: filters.end_time && getTimeWithoutSeconds(filters.end_time),

    });

    const onSearch = (data = params) => {
        dispatch(loadDtpByTime(paramsWithoutEmpty(data)));
        setSearchedTime((data?.start_time && data?.end_time)
            ? { start: getTimeWithoutSeconds(data?.start_time), end: getTimeWithoutSeconds(data?.end_time) }
            : {}
        );
    };

    const resetTime = () => {
        setSearchedTime({});
    };

    const renderContent = () => {
        return (data?.length > 0
            ? <LSContainer
                headers={[
                    { title: titles.DATE, width: '15%' },
                    { title: titles.DTP_COUNT, width: '17%' },
                    { title: 'Погибло', width: '17%' },
                    { title: 'Ранено', width: '17%' },
                    { title: 'Погибло детей', width: '17%' },
                    { title: 'Ранено детей', width: '17%' },
                ]}
            >
                {data.map((item, i) => (
                    <Item
                        key={i}
                        item={item}
                    />
                ))}
            </LSContainer>
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <PageLayout
            loading={loading}
            header="Отчет ДТП по времени"
            filters={<Filters
                initialState={initialState}
                setParams={setParams}
                onSearch={onSearch}
                params={params}
                onReset={resetTime}
            />}
            content={renderContent}
            actionPanel={Object.keys(searchedTime).length > 0
                ? <h3>Период времени с {searchedTime.start} по {searchedTime.end}</h3>
                : null
            }
        />
    );
};

export default DtpByTimePeriod;
