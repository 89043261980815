import React, { useEffect, useState } from 'react';
import styles from './email.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { editAdminDtpSettings, loadAdminDtpSettings } from '../../../../redux/Admin/actions';
import { Checkbox, IconButton, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Clear from '@mui/icons-material/Clear';
import ConfirmDelete from '../../../common/ConfirmModal';
import Loading from '../../../common/Loading';
import FormButtonsComponent, { buttonsTypes } from '../../../common/FormButtons';
import { adminSelectors } from '../../../../redux/Admin';

const useStyles = makeStyles({
    field: {
        width: '100%'
    },
    mailBlock: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10
    }
});

function EmailSettings() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const dtpSettings = useSelector(adminSelectors.dtpSettings);
    const dtpSettingsLoading = useSelector(adminSelectors.dtpSettingsLoading);

    const [formValue, setFormValue] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState('');

    const handleChange = (id, value, name, i) => {
        const newValue = formValue.map(item => {
            if (item.id === id) {
                if (item.name === name) {
                    return {
                        ...item,
                        value: item.value.map((email, index) => i === index ? value : email),
                        isEdited: true
                    };
                }
                return { ...item, value, isEdited: true };
            }
            return item;
        });
        setFormValue(newValue);
    };

    const addEmail = (id) => {
        const newValue = formValue.map(item => {
            if (item.id === id) {
                return { ...item, value: [...item.value, ''] };
            }
            return item;
        });
        setFormValue(newValue);
    };

    const deleteEmail = (id, index) => {
        const newValue = formValue.map(item => {
            if (item.id === id) {
                return { ...item, value: item.value.filter((_, i) => index !== i), isEdited: true };
            }
            return item;
        });
        setFormValue(newValue);
        setConfirmOpen('');
    };

    const dataValues = dtpSettings?.map(item => item.value);
    const formValues = formValue?.map(item => item.value);
    const resetChange = () => setFormValue(dtpSettings);
    const isChanged = JSON.stringify(formValues) !== JSON.stringify(dataValues);
    const changedValues = formValue.filter(item => item?.isEdited === true);
    const result = {
        list: changedValues?.map(({ id, value }) => ({ id, value }))
    };

    const send = () => {
        if (result.list.length !== 0) {
            dispatch(editAdminDtpSettings(result));
        }
    };

    const checkIsEmail = (value) =>
        (value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g)
            ? false
            : 'Поле E-Mail адрес должно быть действительным электронным адресом.');

    const getType = (name) => {
        if (name === 'smtpHost' || name === 'smtpLogin' || name === 'smtpPassword') return 'text';
        else if (name === 'smtpFrom' || name === 'smtpTo') return 'email';
        else if (name === 'smtpPort') return 'number';
    };

    useEffect(() => {
        if (formValue.length === 0) {
            setFormValue(dtpSettings);
        }
    }, [dtpSettings, formValue.length]);

    useEffect(() => {
        if (dtpSettings.length === 0) {
            dispatch(loadAdminDtpSettings());
        }
    }, [dispatch, dtpSettings.length]);

    return (
        <div className={styles.settings}>
            {!dtpSettingsLoading && dtpSettings.length === 0 && <div>Нет данных</div>}
            {dtpSettingsLoading && <Loading circular={true}/>}
            {formValue.length !== 0 && <div>
                <Typography className={styles.section} variant="h5">
                    Настройки email уведомлений о ДТП
                </Typography>
                {formValue.map(item => {
                    if (item.name === 'emailNotificationEnabled') {
                        return (
                            <div className={styles.section} key={item.id}>
                                <Checkbox
                                    disabled={!item?.is_visible}
                                    checked={item.value}
                                    color="primary"
                                    onChange={() => handleChange(item.id, !item.value)}
                                />
                                {item.description} ({item?.measurement})
                            </div>
                        );
                    } else if (item.name === 'smtpTo') {
                        return (
                            <div className={styles.section} key={item.id}>
                                <Typography className={classes.section} variant="subtitle1">
                                    {item.description} {item?.measurement && `(${item?.measurement})`}
                                </Typography>
                                {item.value.map((email, i) => {
                                    const checkValidEmail = !!email.length
                                        && checkIsEmail(email);

                                    return (
                                        <div className={classes.mailBlock} key={i}>
                                            <TextField
                                                type={getType(item.name)}
                                                className={classes.field}
                                                value={email}
                                                disabled={!item?.is_visible}
                                                size="small"
                                                variant="outlined"
                                                error={!!checkValidEmail}
                                                helperText={checkValidEmail}
                                                onChange={(e) =>
                                                    handleChange(item.id, e.target.value, 'smtpTo', i)
                                                }
                                            />
                                            <IconButton size="small" onClick={() => setConfirmOpen(i)}>
                                                <Clear />
                                            </IconButton>
                                            <ConfirmDelete
                                                open={i === confirmOpen}
                                                message={'Вы действительно хотите удалить?'}
                                                onClose={setConfirmOpen}
                                                onSuccess={() => deleteEmail(item.id, i)}
                                            />
                                        </div>
                                    );
                                })}
                                <FormButtonsComponent
                                    noPadding
                                    positionLeft
                                    noMarginLeft
                                    buttons={[
                                        {
                                            onClick: () => addEmail(item.id),
                                            name: 'Добавить Email'
                                        }
                                    ]}
                                />
                            </div>
                        );
                    }
                    const checkValidEmail = item.name === 'smtpFrom'
                        && !!item.value.length
                        && checkIsEmail(item.value);

                    return (
                        <div className={styles.section} key={item.id}>
                            <TextField
                                type={getType(item.name)}
                                className={classes.field}
                                size="small"
                                disabled={!item?.is_visible}
                                variant="outlined"
                                value={item.value}
                                error={!!checkValidEmail}
                                helperText={checkValidEmail}
                                onChange={(e) => {
                                    if (getType(item.name) === 'number') {
                                        return handleChange(item.id, Number(e.target.value));
                                    }
                                    handleChange(item.id, e.target.value);
                                }}
                                label={`${item.description} ${item?.measurement && `(${item?.measurement})`}`}
                            />
                        </div>
                    );
                })}
                <div>
                    <FormButtonsComponent
                        noPadding
                        positionLeft
                        buttons={[
                            {
                                ...buttonsTypes.cancel,
                                onClick: resetChange,
                                size: 'small',
                                disabled: !isChanged
                            },
                            {
                                ...buttonsTypes.save,
                                onClick: send,
                                size: 'small'
                            }
                        ]}
                    />
                </div>
            </div>
            }
        </div>
    );
}

export default EmailSettings;
