import { IconButton } from '@mui/material';

import { fullDateTimeWithoutSeconds } from 'helpers/date.config';

import useStyles from './useStyles';

const EventsList = (props) => {
    const {
        events = [],
        onClick = () => {},
    } = props;

    const classes = useStyles();
    const handleClick = (item) => () => onClick(item);

    return (
        <>
            {events?.map((item, index) => {
                return (
                    <div
                        key={index}
                        // key={item?.uid}
                        onClick={handleClick(item)}
                        className={classes.frameItem}
                    >
                        <b>
                            <span className={classes.imgHeader}>
                                {item?.complex?.name || item?.complex_name || ''}, {item?.address_text}
                            </span>
                            Время фиксации события: {fullDateTimeWithoutSeconds(item?.created_at)}
                        </b>
                        <IconButton size="small">
                            <img
                                className={classes.imgStyle}
                                // костыль
                                src={item?.photo_ts || ''}
                                alt={item?.uid || ''}
                            />
                        </IconButton>
                    </div>
                );
            })}
        </>
    );
};

export default EventsList;
