import React, { useEffect, useState } from 'react';
import {
    Popover,
    Typography,
} from '@mui/material';

// вывод текста по ширине контейнера со всплывание полного текста при наведении
const TextWrap = ({
    title,
    style = {},
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
    const handlePopoverClose = () => setAnchorEl(null);

    useEffect(() => {
        return () => {
            setAnchorEl(null);
        };
    }, []);

    const defaultStyle = {
        // стиль для длинного текста
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    return (
        <>
            {/*  */}
            <span
                style={{
                    ...defaultStyle,
                    ...style,
                }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {title}
            </span>

            {/* всплывашка */}
            <Popover
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography
                    variant="subtitle2"
                    sx={{ p: 1 }}
                >
                    {title}
                </Typography>
            </Popover>
        </>
    );
};

export default TextWrap;
