import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import messages from 'helpers/constants/messages';
import { useStoreFromSelector } from 'helpers/hooks';
import ModalForm from 'components/pages/Dictionaries/CityCameras/ModalForm';
import ConfirmDelete from 'components/common/ConfirmModal';
import * as actions from 'redux/VideoCameras/actions';
import { videoCamerasSelectors } from 'redux/VideoCameras';

import CameraFrame from './CameraFrame';

// формы
const CollectorForms = ({ reloadList }) => {
    const dispatch = useDispatch();
    const {
        deleteForm = false,
        editForm = false,
        savedCamera,
    } = useSelector(({ videoCameras }) => videoCameras);

    const statuses = useStoreFromSelector(actions.loadStatusesVideoCamera, videoCamerasSelectors.videoCameraStatuses);

    useEffect(() => {
        if (savedCamera) {
            dispatch(actions.saveStatusCamera());
        }
    }, [savedCamera]);

    return (
        <>
            {/* форма редактирования */}
            {editForm
                && <ModalForm
                    item={editForm}
                    statuses={statuses}
                    isNew={!editForm.id}
                    isOpen={!!editForm}
                    onClose={() => dispatch(actions.setEditForm())}
                    reloadList={reloadList}
                />
            }

            {/* удаление */}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!deleteForm}
                onSuccess={() => {
                    dispatch(actions.deleteVideoCamera(deleteForm));
                    dispatch(actions.setDeleteForm());
                }}
                onClose={() => {
                    dispatch(actions.setDeleteForm());
                }}
            />
            <CameraFrame />
        </>
    );
};

export default CollectorForms;
