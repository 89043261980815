import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import titles from '../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../FormButtons';
import Modal from '../../../Modal';
import { addKeyWord } from '../../../../../redux/SituationalPlans/actions';

const AddNewKeyWord = ({
    isOpen,
    onClose
}) => {
    const dispatch = useDispatch();
    const [newKeyword, setNewKeyword] = useState('');

    const postNewKeyword = () => {
        dispatch(addKeyWord({ name: newKeyword }, onClose));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title={titles.ADD}
            small={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.add,
                        onClick: postNewKeyword,
                        disabled: !newKeyword.length
                    },
                ]}
            />}
        >
            <form className="modal__form">
                <TextField
                    label={titles.KEYWORD}
                    size="small"
                    value={newKeyword}
                    variant="outlined"
                    type="text"
                    onChange={(e) => setNewKeyword(e.target.value)}
                    required
                />
            </form>
        </Modal>
    );
};

export default AddNewKeyWord;
