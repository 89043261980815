import dtp1 from './dtp1.svg';
import dtp2 from './dtp2.svg';
import dtp3 from './dtp3.svg';
import meteo1 from './meteo1.svg';
import meteo2 from './meteo2.svg';
import meteo3 from './meteo3.svg';
import camera1 from './camera1.svg';
import camera2 from './camera2.svg';
import camera3 from './camera3.svg';
import route1 from './route1.svg';
import route2 from './route2.svg';
import route3 from './route3.svg';
import vehicles1 from './vehicles1.svg';
import vehicles2 from './vehicles2.svg';
import vehicles3 from './vehicles3.svg';

const icons = {
    dtp1,
    dtp2,
    dtp3,
    meteo1,
    meteo2,
    meteo3,
    camera1,
    camera2,
    camera3,
    route1,
    route2,
    route3,
    vehicles1,
    vehicles2,
    vehicles3,
};

export default icons;
