import CameraStatus from '../../pages/Reports/CameraStatus';
import VideoAnalytics from '../../pages/Reports/VAReports/VideoAnalytics';
import VAGraph from '../../pages/Reports/VAReports/VAGraph';
import TransportToStopReport from '../../pages/Reports/VAReports/TransportToStopReport';
import PassengerTraffic from '../../pages/Reports/VAReports/PassengerTraffic';
import ItemsLeftAtTheBusStop from '../../pages/Reports/ItemsLeftAtTheBusStop';
import ByCamera from '../../pages/Reports/VAReports/ByCamera';

const routes = [
    {
        path: '/reports/camera-status',
        component: CameraStatus,
        exact: true
    },
    {
        path: '/reports/videoanalytics',
        component: VideoAnalytics,
        exact: true
    },
    {
        path: '/reports/videoanalytics-charts',
        component: VAGraph,
        exact: true
    },
    {
        path: '/reports/videoanalytics/transport-to-stop',
        component: TransportToStopReport,
        exact: true
    },
    {
        path: '/reports/videoanalytics/bus-stop-left-items',
        component: ItemsLeftAtTheBusStop,
        exact: true
    },
    {
        path: '/reports/videoanalytics/passenger-traffic',
        component: PassengerTraffic,
        exact: true
    },
    {
        path: '/reports/videoanalytics/by-camera',
        component: ByCamera,
        exact: true
    },
];

export default routes;
