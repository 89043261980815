import * as types from './types';

// Транспортные средства
export const loadVehicles = (page, limit, params) => ({
    type: types.LOAD_VEHICLES,
    payload: { page, limit, params },
});
export const loadVehiclesCB = (page, limit, params, fnLoading, fnCallback) => ({
    type: types.LOAD_VEHICLES,
    payload: { page, limit, params },
    fnLoading,
    fnCallback
});

export const loadingVehicles = (loading) => ({
    type: types.LOADING_VEHICLES,
    payload: loading ,
});

export const loadedVehicles = (response) => ({
    type: types.LOADED_VEHICLES,
    payload: response,
});

export const createVehicle = (data, callback) => ({
    type: types.CREATE_VEHICLE,
    payload: data,
    callback
});

export const editVehicle = (id, data, callback) => ({
    type: types.EDIT_VEHICLE,
    payload: { id, data },
    callback
});

export const deleteVehicle = (id, callback) => ({
    type: types.DELETE_VEHICLE,
    payload: { id },
    callback
});

// Типы транспортных средств
export const loadVehicleTypes = () => ({
    type: types.LOAD_VEHICLE_TYPES,
});

export const loadingVehicleTypes = (loading) => ({
    type: types.LOADING_VEHICLE_TYPES,
    payload: loading ,
});

export const loadedVehicleTypes = (response) => ({
    type: types.LOADED_VEHICLE_TYPES,
    payload: response,
});

export const loadDockSidebar = (filter, fnLoading, fnCallback) => ({
    type: types.LOAD_DOCK_SIDEBAR,
    payload: filter,
    fnLoading,
    fnCallback
});

export const loadDockPolygon = (filter, fnLoading, fnCallback) => ({
    type: types.LOAD_DOCK_POLYGON,
    payload: filter,
    fnLoading,
    fnCallback
});

export const setFilters = (filter = {}) => ({
    type: types.SET_FILTERS,
    payload: filter,
});

export const setActive = (active = {}) => ({
    type: types.SET_ACTIVE,
    payload: active,
});

export const loadDockCoords = (filter, fnCallback) => ({
    type: types.LOAD_DOCK_COORDS,
    payload: filter,
    fnCallback
});

export const setDockCurrentShow = (marker = {}) => ({
    type: types.SET_DOCK_CURRENT_SHOW,
    payload: marker,
});

export const loadDockEvents = (id, filter, fnLoading, fnCallback) => ({
    type: types.LOAD_DOCK_EVENTS,
    payload: {
        id,
        filter,
    },
    fnLoading,
    fnCallback,
});

export const setDockColorRefresh = (obj = {}) => ({
    type: types.SET_DOCK_COLOR_REFRESH,
    payload: obj,
});
