import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteMaterialStorageGroup } from 'redux/DorisControl/actions';
import messages from 'helpers/constants/messages';
import ConfirmModal from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import {
    ActionMore,
    ActionsButtons,
    LSContentColumn,
    LSContentItem,
} from 'components/common/List';

import ModalForm from './ModalForm';
import Info from './Info';

const Item = ({ item, loadList }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);

    return (
        <>
            <LSContentItem onClick={() => setOpenInfo(!openInfo)}>
                <LSContentColumn>{item?.name || messages.NO_VALUE}</LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    setOpenEditModal(true);
                                },
                            },
                            ...(!item.is_default
                                ? [
                                    {
                                        ...buttonsTypes.deleteIcon,
                                        onClick: (e) => {
                                            e.stopPropagation();
                                            setOpenDeleteModal(true);
                                        },
                                    },
                                ]
                                : []),
                        ]}
                    />
                    <ActionMore isOpen={openInfo} onClick={() => setOpenInfo(!openInfo)} />
                </LSContentColumn>
            </LSContentItem>

            {openInfo && (
                <Info
                    isOpen={openInfo}
                    item={item}
                />
            )}

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    loadList={loadList}
                />
            )}

            {openDeleteModal && (
                <ConfirmModal
                    open={openDeleteModal}
                    onSuccess={() => dispatch(deleteMaterialStorageGroup(item.id, () => loadList(true)))}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
