import * as types from './types';

const initialState = {
    // список
    sidebar: {
        data: [],
    },
    sidebarLoading: false,
    // полигон
    polygon: [],
    polygonLoading: false,
    // активный
    active: {},
    // фильтры
    filters: {},
    // данные для удаления
    deleteForm: false,
    // данные для редактирования
    editForm: false,
    saved: false,
    loading: false,
    statuses: {},
    types: {},
    zones: {},
    statusStatistic: [],
    parkingList: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    parkingLoading: false,
    tariffList: [],
    categories: [],
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.LOADED_SIDEBAR: {
            const {
                data,
                isReplace,
            } = payload;

            if (isReplace) {
                return {
                    ...state,
                    sidebar: data,
                };
            }

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...data,
                    data: [
                        ...state.sidebar.data,
                        ...data.data,
                    ],
                },
            };
        }

        case types.LOADING_SIDEBAR:
            return {
                ...state,
                sidebarLoading: payload,
            };

        case types.LOADED_POLYGON:
            return  {
                ...state,
                polygon: payload
            };
        case types.LOADING_POLYGON:
            return {
                ...state,
                polygonLoading: payload,
            };

        case types.SET_ACTIVE:
            return {
                ...state,
                active: payload,
            };

        case types.SET_FILTERS:
            return {
                ...state,
                filters: payload
            };

        case types.SET_DELETE_FORM:
            return {
                ...state,
                deleteForm: payload
            };

        case types.SET_EDIT_FORM:
            return {
                ...state,
                editForm: payload
            };

        case types.SET_LOADING:
            return {
                ...state,
                loading: payload
            };

        case types.LOADED_STATUSES:
            return {
                ...state,
                statuses: payload
            };

        case types.LOADED_TYPES:
            return {
                ...state,
                types: payload
            };

        case types.LOADED_ZONES:
            return {
                ...state,
                zones: payload
            };

        case types.LOADED_STATUS_STATISTIC:
            return {
                ...state,
                statusStatistic: payload
            };
        case types.LOADING_PARKING_LIST:
            return {
                ...state,
                parkingLoading: payload,
            };

        case types.LOADED_PARKING_LIST:
            return  {
                ...state,
                parkingList: payload
            };
        case types.LOADED_TARIFF_LIST:
            return  {
                ...state,
                tariffList: payload
            };
        case types.LOADED_CATEGORIES:
            return  {
                ...state,
                categories: payload
            };
        default:
            return state;
    }
}
