import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadKeyWordList } from '../../../../../redux/SituationalPlans/actions';
import FormButtonsComponent, { buttonsTypes } from '../../../../common/FormButtons';
import ModalAddEdit from './ModalAddEdit';
import { List } from '@mui/material';
import CustomPagination from '../../../../common/CustomPagination';
import messages from '../../../../../helpers/constants/messages';
import styles from './keyWords.module.scss';
import Item from './Item';
import Search from '../../../../common/Search';
import Loading from '../../../../common/Loading';
import Context from '../../../../../helpers/context';
import { SituationalPlansSelectors } from '../../../../../redux/SituationalPlans';

function KeyWords() {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const [modalOpen, setModalOpen] = useState(false);

    const keyWords = useSelector(SituationalPlansSelectors.keyWords);
    const keyWordsLoading = useSelector(SituationalPlansSelectors.keyWordsLoading);

    const [params, setParams] = useState({ page: 1, data: {} });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const reloadList = (isDelete) => {
        const page = isDelete && keyWords?.data?.length === 1 && keyWords?.meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadKeyWordList(params.page, limit, params.data));
    },[dispatch, limit, params]);

    return (
        <div className={styles.keyWords}>
            <h1>Ключевые слова</h1>
            <Search setParams={setParams}/>
            {modalOpen
               && <ModalAddEdit
                   onClose={() => setModalOpen(false)}
                   isOpen={modalOpen}
                   isNew={true}
                   reloadList={reloadList}
               />
            }
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setModalOpen(true),
                            disabled: !permissions?.is_create,
                        },
                    ]}
                />
            </div>
            {keyWordsLoading && <Loading circular={true}/>}
            {keyWords?.data?.length > 0
                ? (<>
                    <List>
                        {keyWords.data?.map(item => <Item key={item?.id} data={item} reloadList={reloadList}/>)}
                    </List>
                    <CustomPagination
                        loadList={(page) => setParams({ ...params, page })}
                        list={keyWords.meta}
                        limit={limit} 
                        setLimit={setLimit}
                    />
                </>)
                : (!keyWordsLoading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </div>
    );
}

export default KeyWords;
