import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadCompanies } from 'redux/Companies/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import { loadRoadWorkReportOrders } from 'redux/RoadWorks/actions';
import messages from 'helpers/constants/messages/common';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import { PageReportBlock, PageReportModal } from 'components/common/PageReport';

import Filter from './Filter';
import Item from './Item';

const RoadWorksOrders = () => {
    const dispatch = useDispatch();

    const listOrdersData = useSelector(roadworksSelectors.roadWorkReportOrdersData);
    const listOrdersMeta = useSelector(roadworksSelectors.roadWorkReportOrdersMeta);
    const loading = useSelector(roadworksSelectors.loadingRoadWorkReportOrders);

    const initState = {
        start_date: null,
        end_date: null,
        plan_start_date: null,
        plan_end_date: null,
        fact_start_date: null,
        fact_end_date: null,
        name: '',
        status: {},
        cargo_type: '',
        type: {},
    };

    const [filterValues, setFilterValues] = useState({ page: 1, data: initState });
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        // собираем все айди организаций списка и запрашиваем одним запросом массив организаций
        if (listOrdersData.length > 0) {
            const id_list = Array.from(new Set(listOrdersData.reduce((result, data) => {
                if (data?.organization_ids?.length > 0) {
                    // проверяем, если id есть в заявке, но объекта этой компании нет
                    const ids = data.organization_ids?.reduce((res, id) => {
                        if (data.organizations?.findIndex(el => el?.id === id) < 0) {
                            res.push(id);
                        }
                        return res;
                    }, []);
                    if (ids.length > 0) {
                        result = result.concat(ids);
                    }
                }
                return result;
            }, [])));

            if (id_list.length > 0) {
                dispatch(loadCompanies(
                    1,
                    id_list.length,
                    { id_list, withDeleted: 1 },
                    (organizations = []) => {
                        setCompanies(organizations);
                    }
                ));
            }
        }
    }, [listOrdersData, dispatch]);

    const filterFunc = (filter) => {
        console.trace();
        const prepareParams = {
            ...filter,
            type: filter?.type?.id,
            status: filter.status.id,
            start_date: filter.start_date ? dateWithDashYYYYMMDD(filter.start_date) : null,
            end_date: filter.end_date ? dateWithDashYYYYMMDD(filter.end_date) : null,
            plan_start_date: filter.plan_start_date ? dateWithDashYYYYMMDD(filter.plan_start_date) : null,
            plan_end_date: filter.plan_end_date ? dateWithDashYYYYMMDD(filter.plan_end_date) : null,
            fact_start_date: filter.fact_start_date ? dateWithDashYYYYMMDD(filter.fact_start_date) : null,
            fact_end_date: filter.fact_end_date ? dateWithDashYYYYMMDD(filter.fact_end_date) : null,
        };
        return removeEmptyFields(prepareParams, false);
    };

    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;

    const [limit, setLimit] = useState(storageLimit);

    const onSearch = useCallback(() => {
        const filterParams = filterFunc(filterValues.data);
        dispatch(loadRoadWorkReportOrders(filterValues.page, limit, filterParams));
    }, [dispatch, filterValues, limit]);

    useEffect(() => {
        onSearch();
    }, [onSearch]);

    const renderContent = () => {
        return (listOrdersData?.length > 0
            ? (<LSContainer
                headers={[
                    { title: '',  width: '50px' },
                    { title: '№', width: '5%' },
                    { title: titles.NAME, width: '12%' },
                    { title: 'Дата регистрации', width: '14%' },
                    { title: <span>Дата план.<br/>нач. выполнения</span>, width: '15%' },
                    { title: <span>Дата план.<br/>окон. выполнения</span>, width: '15%' },
                    { title: <span>Дата факт.<br/>выполнения</span>, width: '15%' },
                    { title: 'Организации', width: '18%' },
                    { title: 'Действия', align: 'right', isActions: true }
                ]}
            >
                {listOrdersData?.map(item => <Item
                    key={item?.id}
                    data={item}
                    companies={item.organizations?.length === 0
                        ? companies.filter((el) => item.organization_ids.includes(el.id))
                        : item.organizations
                    }
                />)}
            </LSContainer>)
            : (!loading && <div>{messages.NO_DATA_FOR_YOUR_REQUEST}</div>)
        );
    };

    return (
        <PageLayout
            header={'Отчет по заявкам на дорожные работы'}
            loading={loading}
            additionalButtons={<ReturnToButton/>}
            filters={
                <Filter
                    setFilterValues={(data) => setFilterValues({ page: 1, data })}
                    initState={initState}
                />
            }
            informPanelProps={{
                buttons: (
                    <PageReportModal>
                        <PageReportBlock
                            filter={filterFunc(filterValues.data)}
                        />
                    </PageReportModal>
                ),
                total: listOrdersMeta?.total
            }}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setFilterValues(prev => ({ ...prev, page })),
                list: listOrdersMeta,
                limit,
                setLimit
            }}
        />
    );
};

export default RoadWorksOrders;
