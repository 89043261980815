import { IconButton } from '@mui/material';

import { ActionMore, LSContentColumn, LSContentItem } from 'components/common/List';
import type { EcologyDevice } from 'types/Ecology';

interface ItemProps {
    item: EcologyDevice;
    onClickItem: () => void;
    selectedItem: boolean;
}

const Item = ({ item, onClickItem, selectedItem }: ItemProps) => {
    return (
        <LSContentItem onClick={onClickItem}>
            <LSContentColumn>
                {item.name}
            </LSContentColumn>
            <LSContentColumn isActions>
                <ActionMore isOpen={selectedItem} onClick={onClickItem} isPassport />
            </LSContentColumn>
        </LSContentItem>
    );
};

export default Item;

