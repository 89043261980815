import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiMethods = {
    loadInfrastructureTypes: async (page, limit, query) => {
        const response = await instance.get(apiUrls.loadInfrastructureTypes(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    loadInfrastructure: async (page, limit, query) => {
        const response = await instance.get(apiUrls.loadInfrastructure(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    createInfrastructure: async (data) => {
        const response = await instance.post(apiUrls.createInfrastructure(), data);
        return response?.data;
    },

    editInfrastructure: async (id, data) => {
        const response = await instance.put(apiUrls.editInfrastructure(id), data);
        return response?.data;
    },

    deleteInfrastructure: async (id) => {
        const response = await instance.delete(apiUrls.deleteInfrastructure(id));
        return response?.data;
    },

    createInfrastructureTypes: async (data) => {
        const response = await instance.post(apiUrls.createInfrastructureTypes(), data);
        return response?.data;
    },

    editInfrastructureTypes: async (id, data) => {
        const response = await instance.put(apiUrls.editInfrastructureTypes(id), data);
        return response?.data;
    },

    deleteInfrastructureTypes: async (id) => {
        const response = await instance.delete(apiUrls.deleteInfrastructureTypes(id));
        return response?.data;
    },

    getRoadSectionTypes: async (params) => {
        const response = await instance.get(apiUrls.getRoadSectionTypes(), { params });
        return response.data;
    },

    getRoadSectionCategories: async (params) => {
        const response = await instance.get(apiUrls.getRoadSectionCategories(), { params });
        return response.data;
    },

    getRoadSections: async (params) => {
        const response = await instance.get(apiUrls.getRoadSections(), { params });
        return response.data;
    },

    createRoadSection: async (data) => {
        const response = await instance.post(apiUrls.createRoadSection(), data);
        return response.data;
    },

    editRoadSection: async (id, data) => {
        const response = await instance.put(apiUrls.editRoadSection(id), data);
        return response.data;
    },

    deleteRoadSection: async (id, data) => {
        const response = await instance.delete(apiUrls.deleteRoadSection(id), { data });
        return response.data;
    },

    loadPolygonRoadSection: async (params) => {
        const response = await instance.post(apiUrls.loadPolygonRoadSection(), params);
        return response.data;
    },

    loadSideBarRoadSection: async (params) => {
        const response = await instance.get(apiUrls.loadSideBarRoadSection(), { params });
        return response.data;
    },

    loadStatusesRoadSection: async () => {
        const response = await instance.get(apiUrls.loadStatusesRoadSection());
        return response.data;
    },

    loadRoadSectionRelationTypes: async () => {
        const response = await instance.get(apiUrls.loadRoadSectionRelationTypes());
        return response.data;
    },

    loadRoadSectionRelation: async (params) => {
        const response = await instance.get(apiUrls.loadRoadSectionRelation(), { params });
        return response.data;
    },

    createRoadSectionRelation: async (data) => {
        const response = await instance.post(apiUrls.createRoadSectionRelation(), data);
        return response.data;
    },

    createMultipleRoadSectionRelation: async (relations, road_section_id) => {
        const response = await instance.post(apiUrls.createMultipleRoadSectionRelation(road_section_id), { relations });
        return response.data;
    },

    deleteRoadSectionRelation: async (id) => {
        const response = await instance.delete(apiUrls.deleteRoadSectionRelation(id));
        return response.data;
    },

    loadRoadSectionByRadius: async (params) => {
        const response = await instance.post(apiUrls.loadRoadSectionByRadius(), params);
        return response?.data;
    },

    loadInfrastructureByRadius: async (params) => {
        const response = await instance.post(apiUrls.loadInfrastructureByRadius(), params);
        return response?.data;
    },

    loadOverlapPolygon: async (params) => {
        const response = await instance.post(apiUrls.loadOverlapPolygon(), params);
        return response?.data;
    },

    loadOverlapList: async (params) => {
        const response = await instance.get(apiUrls.loadOverlapList(), { params });
        return response?.data;
    },

    loadPolygonInfrastructure: async (params) => {
        const response = await instance.post(apiUrls.loadPolygonInfrastructure(), params);
        return response?.data;
    },

    loadInfrastructureSidebar: async (params) => {
        const response = await instance.get(apiUrls.loadInfrastructureSidebar(), { params });
        return response?.data;
    },
    loadOverlapTypes: async () => {
        const response = await instance.get(apiUrls.loadOverlapTypes());
        return response?.data;
    },

    loadOverlapSource: async () => {
        const response = await instance.get(apiUrls.loadOverlapSource());
        return response?.data;
    },

    loadStreetList: async (params) => {
        const response = await instance.post(apiUrls.loadStreetList(), params);
        return response.data;
    },

    loadCategoryTypes: async () => {
        const response = await instance.get(apiUrls.loadCategoryTypes());
        return response.data;
    },

    // Словари для городских видеокамер
    loadVideoCamerasDictionaryInfrastructureTypes: async () => {
        const response = await instance.get(apiUrls.loadVideoCamerasDictionaryInfrastructureTypes());
        return response.data;
    },

    loadVideoCamerasDictionaryCommutator: async () => {
        const response = await instance.get(apiUrls.loadVideoCamerasDictionaryCommutator());
        return response.data;
    },

    loadVideoCamerasDictionaryConnectionLine: async () => {
        const response = await instance.get(apiUrls.loadVideoCamerasDictionaryConnectionLine());
        return response.data;
    },

    loadVideoCamerasDictionaryConnection: async () => {
        const response = await instance.get(apiUrls.loadVideoCamerasDictionaryConnection());
        return response.data;
    },

    loadVideoCamerasDictionaryElectricalOptionalSystem: async () => {
        const response = await instance.get(apiUrls.loadVideoCamerasDictionaryElectricalOptionalSystem());
        return response.data;
    },

    loadVideoCamerasDictionaryDocument: async () => {
        const response = await instance.get(apiUrls.loadVideoCamerasDictionaryDocument());
        return response.data;
    },

    loadVideoCamerasDictionaryPower: async () => {
        const response = await instance.get(apiUrls.loadVideoCamerasDictionaryPower());
        return response.data;
    },

    loadVideoCamerasDictionaryReliance: async () => {
        const response = await instance.get(apiUrls.loadVideoCamerasDictionaryReliance());
        return response.data;
    },

    loadCommutators: async (params) => {
        const response = await instance.get(apiUrls.loadCommutators(), { params });
        return response.data;
    },

    createCommutator: async (params) => {
        const response = await instance.post(apiUrls.createCommutator(), params);
        return response.data;
    },

    editCommutator: async (id, data) => {
        const response = await instance.put(apiUrls.editCommutator(id), data);
        return response.data;
    },

    deleteCommutator: async (id) => {
        const response = await instance.delete(apiUrls.deleteCommutator(id));
        return response.data;
    },

    loadCommutatorTypes: async () => {
        const response = await instance.get(apiUrls.loadCommutatorTypes());
        return response.data;
    },

    getConnectionLines: async (params) => {
        const response = await instance.get(apiUrls.getConnectionLines(), { params });
        return response.data;
    },

    createConnectionLine: async (data) => {
        const response = await instance.post(apiUrls.createConnectionLine(), data);
        return response.data;
    },

    editConnectionLine: async (id, data) => {
        const response = await instance.put(apiUrls.editConnectionLine(id), data);
        return response.data;
    },

    deleteConnectionLine: async (id) => {
        const response = await instance.delete(apiUrls.deleteConnectionLine(id));
        return response.data;
    },

    loadCameras: async ({ page, limit, params }) => {
        const response = await instance.get(apiUrls.loadCameras(), {
            params: { page, limit, ...params }
        });
        return response?.data;
    },

    createCamera: async (data) => {
        const response = await instance.post(apiUrls.createCamera(), data);
        return response.data;
    },

    editCamera: async (id, data) => {
        const response = await instance.put(apiUrls.editCamera(id), data);
        return response?.data;
    },

    deleteCamera: async (id) => {
        const response = await instance.delete(apiUrls.deleteCamera(id));
        return response?.data;
    },

    loadCameraTypes: async () => {
        const response = await instance.get(apiUrls.loadCameraTypes());
        return response.data;
    },

    // Модель оборудования Камера
    loadCameraModel: async ({ page, limit, params }) => {
        const response = await instance.get(apiUrls.loadCameraModel(), {
            params: { page, limit, ...params }
        });
        return response?.data;
    },

    createCameraModel: async (data) => {
        const response = await instance.post(apiUrls.createCameraModel(), data);
        return response.data;
    },

    editCameraModel: async (id, data) => {
        const response = await instance.put(apiUrls.editCameraModel(id), data);
        return response?.data;
    },

    deleteCameraModel: async (id) => {
        const response = await instance.delete(apiUrls.deleteCameraModel(id));
        return response?.data;
    },

    // Вендор оборудования Камера
    loadCameraVendor: async ({ page, limit, params }) => {
        const response = await instance.get(apiUrls.loadCameraVendor(), {
            params: { page, limit, ...params }
        });
        return response?.data;
    },

    createCameraVendor: async (data) => {
        const response = await instance.post(apiUrls.createCameraVendor(), data);
        return response.data;
    },

    editCameraVendor: async (id, data) => {
        const response = await instance.put(apiUrls.editCameraVendor(id), data);
        return response?.data;
    },

    deleteCameraVendor: async (id) => {
        const response = await instance.delete(apiUrls.deleteCameraVendor(id));
        return response?.data;
    },

    loadConnectionPoints: async (params) => {
        const response = await instance.get(apiUrls.loadConnectionPoints(), { params });
        return response.data;
    },

    createConnectionPoint: async (data) => {
        const response = await instance.post(apiUrls.createConnectionPoint(), data);
        return response.data;
    },

    editConnectionPoint: async (id, data) => {
        const response = await instance.put(apiUrls.editConnectionPoint(id), data);
        return response.data;
    },

    deleteConnectionPoint: async (id) => {
        const response = await instance.delete(apiUrls.deleteConnectionPoint(id));
        return response.data;
    },

    loadConnectionPointTypes: async () => {
        const response = await instance.get(apiUrls.loadConnectionPointTypes());
        return response.data;
    },
    getPillars: async (params) => {
        const response = await instance.get(apiUrls.getPillars(), { params });
        return response.data;
    },

    createPillar: async (data) => {
        const response = await instance.post(apiUrls.createPillar(), data);
        return response.data;
    },

    editPillar: async (id, data) => {
        const response = await instance.put(apiUrls.editPillar(id), data);
        return response.data;
    },

    deletePillar: async (id) => {
        const response = await instance.delete(apiUrls.deletePillar(id));
        return response.data;
    },
    getElectricalOpticalSystems: async (params) => {
        const response = await instance.get(apiUrls.getElectricalOpticalSystems(), { params });
        return response.data;
    },

    createElectricalOpticalSystems: async (data) => {
        const response = await instance.post(apiUrls.createElectricalOpticalSystems(), data);
        return response.data;
    },

    editElectricalOpticalSystems: async (id, data) => {
        const response = await instance.put(apiUrls.editElectricalOpticalSystems(id), data);
        return response.data;
    },

    deleteElectricalOpticalSystems: async (id) => {
        const response = await instance.delete(apiUrls.deleteElectricalOpticalSystems(id));
        return response.data;
    },

    getPakVendors: async (params) => {
        const response = await instance.get(apiUrls.getPakVendors(), { params });
        return response.data;
    },

    createPakVendor: async (data) => {
        const response = await instance.post(apiUrls.createPakVendor(), data);
        return response.data;
    },

    editPakVendor: async (id, data) => {
        const response = await instance.put(apiUrls.editPakVendor(id), data);
        return response.data;
    },

    deletePakVendor: async (id) => {
        const response = await instance.delete(apiUrls.deletePakVendor(id));
        return response.data;
    },

    getPakModels: async (params) => {
        const response = await instance.get(apiUrls.getPakModels(), { params });
        return response.data;
    },

    createPakModel: async (data) => {
        const response = await instance.post(apiUrls.createPakModel(), data);
        return response.data;
    },

    editPakModel: async (id, data) => {
        const response = await instance.put(apiUrls.editPakModel(id), data);
        return response.data;
    },

    deletePakModel: async (id) => {
        const response = await instance.delete(apiUrls.deletePakModel(id));
        return response.data;
    },

    getPAKList: async (params) => {
        const response = await instance.get(apiUrls.getPAKList(), { params });
        return response.data;
    },

    createPAK: async (data) => {
        const response = await instance.post(apiUrls.createPAK(), data);
        return response.data;
    },

    editPAK: async (id, data) => {
        const response = await instance.put(apiUrls.editPAK(id), data);
        return response.data;
    },

    deletePAK: async (id) => {
        const response = await instance.delete(apiUrls.deletePAK(id));
        return response.data;
    },

    getDVR: async (params) => {
        const response = await instance.get(apiUrls.getDVR(), { params });
        return response.data;
    },

    createDVR: async (data) => {
        const response = await instance.post(apiUrls.createDVR(), data);
        return response.data;
    },

    editDVR: async (id, data) => {
        const response = await instance.put(apiUrls.editDVR(id), data);
        return response.data;
    },

    deleteDVR: async (id) => {
        const response = await instance.delete(apiUrls.deleteDVR(id));
        return response.data;
    },

    loadInfrastructureByPolygon: async (params) => {
        const response = await instance.post(apiUrls.getInfrastructureByPolygon(), params);
        return response.data;
    },

    loadInfrastructureList: async (params) => {
        const response = await instance.get(apiUrls.getInfrastructure(), params);
        return response.data;
    },
};

export default ApiMethods;
