// 🍂class Marker
import L from 'leaflet';

L.Marker.include({
    // новые параметры
    //todo подумать
    // _clusterColor: null,
    // _clusterSlug: null,

    setAttribute: function setAttribute(attribution) {
        this.options.attribution = attribution;
        this.update();
        // обновить кластеры
        // console.log('ddd', this);
        // if (map) {
        //     map.fire('refreshClusters');
        // } else if (this._map) {
        //     this._map.fire('refreshClusters');
        // }
        return this;
    },

    //todo подумать
    // цвет для кластера
    // getClusterColor: function getClusterColor() {
    //     return this._clusterColor;
    // },
    // setClusterColor: function setClusterColor(color) {
    //     this._clusterColor = color;
    //     this.update();
    //     if (this._map) this._map.fire('refreshClusters');
    //     return this;
    // },
    // // слаг для кластера
    // getClusterSlug: function getClusterSlug() {
    //     return this._clusterSlug;
    // },
    // setClusterSlug: function setClusterSlug(slug) {
    //     this._clusterSlug = slug;
    //     this.update();
    //     if (this._map) this._map.fire('refreshClusters');
    //     return this;
    // }
});

// L.Marker.addInitHook(function(){
//     this.on('move', this.slideCancel, this);
// });
