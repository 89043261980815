import * as types from './types';

export const saveLastMapCenter = (target = null) => ({
    type: types.SAVE_LAST_MAP_CENTER,
    payload: target,
});

// export const changeMapLayers = (layer) => ({
//     type: types.CHANGE_MAP_LAYERS,
//     payload: layer,
// });

export const changeMapCenter = (latlng = null, zoom = null) => ({
    type: types.CHANGE_MAP_CENTER,
    payload: {
        coord: latlng,
        zoom
    },
});

// лоадинг для слоев карты
// key - ключ слоя, isLoading - лоадинг
export const setMapLoading = (key = '', isLoading) => ({
    type: types.SET_MAP_LOADING,
    payload: {
        key,
        loading: isLoading,
    },
});
