import React, { useEffect, useMemo } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import Layer from 'components/MapComponents/Layers/Videoanalytics/layer';
import CardWidget from 'components/common/Showcase/CardWidget';
import TypeComponent from 'components/common/Showcase/TypeComponent';
import CardMap from 'components/common/Showcase/CardMap';
import layerConfig from 'components/MapComponents/Layers/Videoanalytics/config';
import { videoAnalyticsSelectors } from 'redux/VideoAnalytics';
import { clearShowcase, loadShowcase, saveExcludedKeys } from 'redux/VideoAnalytics/actions';

const Page = () => {
    const dispatch = useDispatch();
    const showCase = useSelector(videoAnalyticsSelectors.showcase);
    const loadingShowcase = useSelector(videoAnalyticsSelectors.loadingShowcase);
    const {
        graph = [],
        table = [],
    } = showCase;

    const optimizedTable = useMemo(() => {
        return table.map((item: { name: string; data: { cells: { name: string; }[]; }; }) => item);
    }, [table]);

    const list = [...(Array.isArray(optimizedTable) ? optimizedTable : [optimizedTable]), ...(Array.isArray(graph) ? graph : [graph])];
    const isList = list?.length > 0;

    useEffect((): () => void => {
        dispatch(loadShowcase());
        return () => dispatch(clearShowcase());
    } ,[]);

    const excludedKeysForEventsLabels = ['line', 'grz'];

    useEffect(() => {
        dispatch(saveExcludedKeys(excludedKeysForEventsLabels));
    });

    return (
        <Grid container spacing={2} sx={{ height: '100%' }}>
            {isList && (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    className="showcase_grid_flex-column"
                >
                    {loadingShowcase && <LinearProgress/>}

                    {list.map((item, index) => (
                        <CardWidget
                            key={index}
                            icon={layerConfig.layerIcon}
                            title={item.name}
                            marginBottom={index < list.length - 1}
                            fillOthers
                            onClick={undefined}
                            renderContent={undefined}
                            small
                        >
                            <TypeComponent
                                {...item}
                            />
                        </CardWidget>
                    ))}
                </Grid>
            )}
            <Grid
                item
                xs={12}
                { ...true ? { sm: 6, md: 8 } : {}}
                className="showcase_grid_flex-column"
            >
                {/* @ts-ignore */}
                <CardMap
                    fillOthers
                    isCluster
                >
                    <Layer readOnly visibleLegend/>
                </CardMap>
            </Grid>
        </Grid>
    );
};

export default Page;
