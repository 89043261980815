import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiMethods = {

    loadTelegramId: async (params) => {
        const response = await instance.post(apiUrls.loadTelegramId(), params);
        return response?.data;
    },
};

export default ApiMethods;
