import React from 'react';
import {
    Paper,
} from '@mui/material';


const LengthRoad = ({
    data = {},
}) => {
    const {
        title = '',
        datas = {}
    } = data || {};

    const {
        value = '0',
        units = '',
    } = datas?.total || {};

    if (Object.keys(datas).length > 0) {
        return (
            <Paper
                variant="outlined"
                square
                style={{
                    padding: '1rem',
                    textAlign: 'center',
                    width: '49.5%'
                }}
            >
                {title} <span style={{ whiteSpace: 'nowrap' }}>= <span style={{ fontSize: '1.5rem', color: '#ff0000' }}>{value ?? ''}</span> {units ?? ''}</span>
            </Paper>
        );
    }
    return null;
};

export default LengthRoad;