import {
    loadComplexModelTypes,
} from 'redux/DorisControl/actions';
import { useStoreProp } from 'helpers/hooks';
import { fullDateTime } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import prepareCoords from 'helpers/prepareCoords';
import { usePermissions } from 'helpers/hooks/Permissons';
import titles from 'helpers/constants/titles';
import { complexEventsURI } from 'components/pages/Reports/DorisControl/ComplexEvents/permissionConfig';

const RenderEventInfo = ({ event }) => {
    const access = usePermissions(complexEventsURI);

    const accessColumns = access?.columns?.filter(el => !el.includes('thumb'));
    // в правах в поле columns список доступных полей для отображения

    const columns =  accessColumns || [
        ['name', 'species_name', 'complex_species_name', 'complex_serial_number', 'camera_duplo_code'],
        ['grz', 'violation_type_text', 'created_at', 'saved_at', 'vehicle_speed'],
        ['camera_direction', 'line', 'lat', 'lon', 'address_text'],
    ];


    const renderLabels = {
        complex_name: {
            title: 'Комплекс'
        },
        complex_species_name: {
            title: 'Вид',
            value: (value) => value ?? event?.complex?.species_name
        },
        complex_model_name: {
            title: 'Модель',
            // value: (value) => value && model_types?.find(item => item.id === value)?.description
        },
        complex_serial_number: {
            title: 'Серийный №',
        },
        camera_duplo_code: {
            title: 'Камера Паутина',
        },
        grz: {
            title: titles.GRZ,
            // value: (value) => value && numberValidator(value)
        },
        violation_type_text: {
            title: 'Тип событий',
            // value: (value) =>
            //     Array.isArray(value) && value?.length
            //         ? value.map(item => <span key={item}>{item} </span>)
            //         : value?.length > 0
            //             ? <span>{value} </span>
            //             : ''
        },
        created_at: {
            title: <span><i className="far fa-calendar-alt" /> фиксации</span>,
            value: (value) => value && fullDateTime(value)
        },
        saved_at: {
            title: <span><i className="far fa-calendar-alt" /> получения данных</span>,
            value: (value) => value && fullDateTime(value)
        },
        vehicle_speed: {
            title: 'Скорость',
            value: (value) => (value !== null && value !== undefined) ? `${value.toFixed(1)} км/ч` : null,
        },
        camera_direction: {
            title: 'Направление',
            value: (value) => value?.camera_direction
        },
        line: {
            title: 'Полоса №',
            visible: (value) => value !== null,
            value: (value) => (value !== null && value !== undefined) ? value?.toString() : messages.NO_DATA
        },
        lat: {
            title: 'GPS',
            value: () => <span
                style={{ color: event.is_false_lat_lon ? (access?.additional_style?.color || 'red') : '0000008a' }}
                title={event.is_false_lat_lon && access?.additional_style?.fields.includes('lat')
                    ? 'Есть отклонение по координатам'
                    : ''
                }>
                {`${prepareCoords(Number(event?.lat), 4)}, ${prepareCoords(Number(event?.lon), 4)}`}
            </span>
        },
        address_text: {
            title: 'Адрес'
        },
    };

    const renderItems = (labels) => (
        <>
            {labels.map(key => {
                if (key === 'lon') return null;
                const title = renderLabels[key]?.title;
                const value = renderLabels[key]?.value;
                const visible = renderLabels[key]?.visible || true;

                const field = event?.[key] !== null && event?.[key] !== undefined
                    ? event?.[key]
                    : event?.complex?.[key];

                const isVisible = typeof visible === 'function' ? visible(field) : visible;

                if (!isVisible) return null;

                return (
                    <div key={key} className="row">
                        <strong>{title}:&nbsp;</strong>
                        <span>{(value && value(field)) || field || messages.NO_DATA}</span>
                    </div>
                );
            })}
        </>
    );

    return (
        <div className="row">
            {columns.map((el) => (
                <div key={el} style={{ marginRight: '2rem', maxWidth: '33%' }}>{renderItems(el)}</div>)
            )}
            {/* <div style={{ marginRight: '2rem', maxWidth: '33%' }}>{renderItems(renderLabels.first)}</div>
            <div style={{ marginRight: '2rem', maxWidth: '33%' }}>{renderItems(renderLabels.second)}</div>
            <div style={{ marginRight: '2rem', maxWidth: '33%' }}>{renderItems(renderLabels.third)}</div> */}
        </div>
    );
};

export default RenderEventInfo;
