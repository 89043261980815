import { useMemo } from 'react';
import { FormControl } from '@mui/material/';
import { InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import useStoreProp from 'helpers/hooks/useStoreProp';
import { loadVehicleTypes } from 'modules/TransportWater/redux/actions';

interface SelectVehicleTypeProps {
    value: string;
    onChange: (value: string) => void;
    error?: boolean;
}

function SelectVehicleType({ value, onChange, error }: SelectVehicleTypeProps) {
    const types: { [key: string]: string } = useStoreProp(
        loadVehicleTypes,
        'transportWater',
        'vehicleTypes'
    );

    const options = useMemo(() => {
        return Object.keys(types).map((type) => ({ label: types[type], value: type }));
    }, [types]);

    if (isNaN(Number(value))) {
        const current = options.find((option) => option.label === value);

        if (current?.value) {
            onChange(current?.value);
        }
    }

    return (
        <FormControl size="small">
            <InputLabel error={error}>Тип судна</InputLabel>
            <Select
                value={value}
                label="Тип судна"
                onChange={(e) => onChange(e.target.value)}
                error={error}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SelectVehicleType;
