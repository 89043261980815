import { config } from 'config';

const apiReactUrl = () => config.get('urls').apiReactUrl;

const apiUrls = {
    getIncidents: () => `${apiReactUrl()}/incident`,

};

export default apiUrls;
