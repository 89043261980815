import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';


import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { clearReportByFlights, loadReportByFlights } from 'redux/TransportPassenger/actions';
import { config } from 'config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import messages from 'helpers/constants/messages';
import { getDateWithDuration, getEndOf, getHumanDate, getStartOf } from 'helpers/date.config';
import PageLayout from 'components/layout/PageLayout';
import ReportBlock from 'components/common/Report/ReportBlock';

import Item from './Item';
import Filter from './Filter';

const WorkordersByFlight = () => {
    const dispatch = useDispatch();

    const reportByFlightsData = useSelector(transportPassengerSelectors.reportByFlightsData);
    const reportByFlightsMeta = useSelector(transportPassengerSelectors.reportByFlightsMeta);
    const loadingReportByFlights = useSelector(transportPassengerSelectors.loadingReportByFlights);

    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;

    const initialParams = {
        start_date : getStartOf('day', getDateWithDuration({ days: -5 })),
        end_date: getEndOf('day'),
        page: 1,
        limit: storageLimit,
    };

    const [params, setParams] = useState(initialParams);

    const timezone = config.project?.timezone;

    const getFilter = (params) => {
        const newFilter = {
            ...params,
            start_date: formatInTimeZone(new Date(params.start_date), timezone, 'yyyy-MM-dd'),
            end_date: formatInTimeZone(new Date(params.end_date), timezone, 'yyyy-MM-dd'),
            organization_id_list: params.organization_id_list?.map(({ id }) => id) || null,
            traffic_schedule_id_list: params.traffic_schedule_id_list?.map(({ id }) => id) || null,
            route_id_list: params.route_id_list?.id ? [params.route_id_list?.id] : null,
        };

        return removeEmptyFields(newFilter);
    };

    useEffect(() => {
        dispatch(loadReportByFlights(getFilter(params)));
    }, [params]);

    const renderContent = () => {
        return (reportByFlightsData?.length > 0
            ? (
                <>
                    <List className="list">
                        {reportByFlightsData?.map((item, index) => (
                            <Item
                                key={index}
                                data={item}
                                period={`${getHumanDate(params.start_date)} - ${getHumanDate(params.end_date)}`}
                            />
                        ))}
                    </List>
                </>
            )
            : (!loadingReportByFlights && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    const onAccept = (params) => {
        setParams(old => ({
            ...old,
            ...params,
            page: 1
        }));
    };

    const getReport = (format) => {
        dispatch(loadReportByFlights({ ...getFilter(params), report: format }));
    };

    return (
        <PageLayout
            header="Отчёт о выполненных рейсах"
            filters={<Filter
                data={params}
                initialData={initialParams}
                onAccept={onAccept}
                onClear={() => {
                    setParams(initialParams);
                    dispatch(clearReportByFlights());
                }}
            />}
            loading={loadingReportByFlights}
            informPanelProps={{
                buttons: (
                    <ReportBlock onGetReport={getReport} />
                ),
                total: reportByFlightsMeta?.total
            }}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setParams(old => ({ ...old, page })),
                list: reportByFlightsMeta,
                limit: params.limit,
                setLimit: (limit) => setParams(old => ({ ...old, page: 1, limit }))
            }}
        />
    );

};

export default WorkordersByFlight;