import HeatMap from '../../pages/Reports/TrafficFlow/HeatMap';

const routes = [
    {
        path: '/reports/puid/heat-map',
        component: HeatMap,
        exact: true
    },
];

export default routes;