import L from 'leaflet';
import { config } from '../../config';
import brightImg from '../../img/layer0.png';
import cartoImg from '../../img/layer1.png';
import dgisImg from '../../img/layer2.png';

class mapHelper {
    createMap(id, mapCenter) {
        const currMapCenter = this.getCurrMapCenter();
        const _center = mapCenter ? [mapCenter.coord[0], mapCenter.coord[1]] : currMapCenter;
        const _zoom = mapCenter ? mapCenter.zoom : 10;
        const map = L.map(id, {
            center: _center,
            zoom: _zoom,
        });
        const mainLayer = 'DGIS';

        ///////////////////////////////////////////////////////////////
        //CREATE LAYERS FOR CONTROL
        const OSMBright = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        });
        const OSMCarto = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        });
        const DGIS = L.tileLayer('http://tile3.maps.2gis.com/tiles?x={x}&y={y}&z={z}');

        const brightSpan = `<span class="layer-control__item" onclick="L.changeLayerToStore('Bright')"><img src="${brightImg}" /> OSM Bright</span>`;
        const cartoSpan = `<span class="layer-control__item" onclick="L.changeLayerToStore('Carto')"><img src="${cartoImg}" /> OSM Carto</span>`;
        const dgisSpan = `<span class="layer-control__item" onclick="L.changeLayerToStore('DGIS')"><img src="${dgisImg}" /> 2GIS</span>`;

        const layersContol = L.control.layers({
            [brightSpan]: mainLayer === 'Bright' ? OSMBright.addTo(map) : OSMBright,
            [cartoSpan]: mainLayer === 'Carto' ? OSMCarto.addTo(map) : OSMCarto,
            [dgisSpan]: mainLayer === 'DGIS' ? DGIS.addTo(map) : DGIS,
        });

        map.zoomControl.setPosition('topright').getContainer();

        //устанавливаем на карту контрол для слоев
        //////////////////////////////////////////////
        layersContol.addTo(map).getContainer().classList.add('map-control__layer');

        L.changeLayerToStore = (type) => {
            //store.dispatch(changeMapLayers(type));
        };

        L.control
            .scale({
                position: 'bottomright',
                imperial: false,
            })
            .addTo(map)
            .getContainer()
            .classList.add('map-control__layer');

        return map;
    }

    getCurrMapCenter = () => {
        return config.get('mapCenter') || [];
    };

    getPolygon = (map) => {
        const bounds = map.getBounds();
        const topLeft = bounds.getNorthWest();
        const topRight = bounds._northEast;
        const bottomRight = bounds.getSouthEast();
        const bottomLeft = bounds._southWest;

        const polygon = [{ ...topLeft }, { ...topRight }, { ...bottomRight }, { ...bottomLeft }];
        return polygon.map(({ lat, lng }) => ({ lat, lon: lng }));
    };

    getGeometry = (map) => {
        const bounds = map.getBounds();
        const topLeft = bounds.getNorthWest();
        const topRight = bounds._northEast;
        const bottomRight = bounds.getSouthEast();
        const bottomLeft = bounds._southWest;
        const polygon = [{ ...topLeft }, { ...topRight }, { ...bottomRight }, { ...bottomLeft }];

        return {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: polygon.map(({ lat, lng }) => ([ lng, lat ]))
            }
        };
    };

    getGeometryPolygon = (map) => {
        const bounds = map.getBounds();
        const topLeft = bounds.getNorthWest();
        const topRight = bounds._northEast;
        const bottomRight = bounds.getSouthEast();
        const bottomLeft = bounds._southWest;
        const polygon = [{ ...topLeft }, { ...topRight }, { ...bottomRight }, { ...bottomLeft }, { ...topLeft }];

        return {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [polygon.map(({ lat, lng }) => ([ lng, lat ]))]
            }
        };
    };
}
const helper = new mapHelper();

export default helper;
