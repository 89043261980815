import React, { useMemo, useState } from 'react';
import { List } from '@mui/material';

import { ListContext } from '../helper';

import HeaderItem from './HeaderItem';
import HeaderColumnList from './HeaderColumnList';

import '../style.scss';
import type { Sort } from 'types/common';
import type { Header } from '../types';


interface ListContainerProps {
    children: React.ReactNode;
    headers: Header[]; // [ { name, width, ...column_props } ]
    fill?: boolean; // заполнить автоматом по ширине
    isMobile?: boolean;
    onSort?: (value: Sort[]) => void;
    hideHeader?: boolean; // если надо спрятать строку с заголовокм но контент выводить колонками
}

const ListContainer = ({
    children,
    headers,
    fill = false,
    isMobile = false,
    hideHeader = false,
    onSort,
}: ListContainerProps) => {
    const filteredHeaders = useMemo(() => headers.filter(({ isHide }) => !isHide), [headers]);

    // заголовок Действия по умолчанию скрыт
    const [hideActionsHeader, setHideActionsHeader] = useState(true);

    const childProps = useMemo(() => {
        if (fill) {
            const isActions = filteredHeaders?.findIndex?.(item => item?.hasOwnProperty?.('isActions')) >= 0;

            // максимальная ширина (если не задана колонка действия)
            const widthAll = isActions
                ? 90
                : 100;

            // высчитываем на сколько колонок делить
            const headersCount = filteredHeaders?.length || 0;
            const count = Math.max(0, headersCount - (isActions ? 1 : 0));

            return count > 0
                ? { width: `${widthAll / count}%` }
                : {};
        }

        return {};
    }, [fill, filteredHeaders]);

    return (
        <List className="custom_list_style">
            {(filteredHeaders.length > 0 && !hideHeader) && (
                <HeaderItem isMobile={isMobile}>
                    <HeaderColumnList
                        headers={filteredHeaders}
                        onSort={onSort}
                        hideActions={hideActionsHeader}
                        childProps={childProps}
                    />
                </HeaderItem>
            )}

            {/* содержимое */}
            <ListContext.Provider value={{ setHideActionsHeader, headers: filteredHeaders, childProps }}>
                {children}
            </ListContext.Provider>
        </List>
    );
};

export default ListContainer;
