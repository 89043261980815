import useStoreProp from './useStoreProp';

// получить поле из стора
const GetStoreProp = ({
    // запрос на получение данных
    fetchAction,
    // раздел стора
    storeName,
    // нужное поле
    storeProp,
    // ключ
    value = null,
    // дефолтный текст
    defaultText = 'Информация отсутствует'
}) => {
    // список значений
    const data = useStoreProp(fetchAction, storeName, storeProp);

    if (data === null) return '';

    if (Array.isArray(data)) {
        return data.find((el) => el.id === value)?.name || defaultText;
    }

    return data[value] || defaultText;
};

export default GetStoreProp;
