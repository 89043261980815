import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    IconButton,
    TextField,
    Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

import { loadRegions } from 'redux/Dadata/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import GovernmentContractList from 'components/common/Autocomplete/GovernmentContract';
import SimpleSelect from 'components/common/SimpleSelect';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';

// import styles from "../../../../../pages/Reports/VideoAnalytics/videoAnalytic.module.scss";

const useStyles = makeStyles((theme) => ({
    btn: {
        marginLeft: '10px',
        width: '40px',
        height: '40px',
        alignSelf: 'center'
    },
}));

// function TextMaskCustom(props) {
//     const { inputRef, ...other } = props;
//
//     return (
//         <MaskedInput
//             {...other}
//             ref={(ref) => {
//                 inputRef(ref ? ref.inputElement : null);
//             }}
//             mask={[/[0-9]{1,3}/, '.', /[0-9]{1,3}/, '.', /[0-9]{1,3}/, '.', /[0-9]{1,3}/]}
//             // mask={[/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/]}
//         />
//     );
// };

const Filter = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { data, onChange, complexStatuses } = props;

    const regionsAll = useStoreProp(loadRegions, 'dadata', 'regions');

    const getValue = (key, def = '') => data[key] || def;


    const handleEvent = (name) => (_, newValue) => {
        onChange({ [name]: newValue });
    };

    // useEffect(() => {
    //     if (data.street && !isEqual(data.street || [], street)) {
    //         setStreet(data.street);
    //     }
    // }, [data]);

    const cities = useMemo(() => {
        if (data?.area) {
            const area = regionsAll?.areas?.find(item => data?.area === item.name);
            return regionsAll?.cities?.filter(city => area?.id === city.parent_id);
        }

        return regionsAll?.cities || [];
    }, [data?.area, regionsAll]);

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >

            <div className="block">
                <TextField
                    label={titles.NAME}
                    size="small"
                    value={data.name || ''}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                />
            </div>

            {/*<div className="row">*/}
            {/*    <Autocomplete*/}
            {/*        style={{ width: '100%' }}*/}
            {/*        multiple*/}
            {/*        value={getValue('name', [])}*/}
            {/*        options={[]}*/}
            {/*        filterSelectedOptions={true}*/}
            {/*        freeSolo={true}*/}
            {/*        size="small"*/}
            {/*        onChange={handleEvent('name')}*/}
            {/*        renderInput={(params) => (*/}
            {/*            <TextField*/}
            {/*                {...params}*/}
            {/*                size="small"*/}
            {/*                variant="outlined"*/}
            {/*                label="Название комплекса"*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    />*/}
            {/*    <Tooltip*/}
            {/*        // title={titles.WRITE_TRANSPORT_NUMBER}*/}
            {/*        title={'Ввод названия целиком или частично (после ввода нажмите Enter чтобы добавить)'}*/}
            {/*        style={{ marginLeft: '.5rem', marginTop: '.2rem' }}*/}
            {/*    >*/}
            {/*        <IconButton size="small">*/}
            {/*            <ErrorOutline/>*/}
            {/*        </IconButton>*/}
            {/*    </Tooltip>*/}
            {/*</div>*/}


            <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    openText={'Открыть'}
                    closeText={'Закрыть'}
                    multiple
                    value={getValue('status', [])}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={complexStatuses}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEvent('status')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.STATUS}
                        />
                    )}
                />
            </div>

            <div className="row">
                <Autocomplete
                    style={{ width: '100%' }}
                    multiple
                    value={getValue('serial_number', [])}
                    options={[]}
                    filterSelectedOptions={true}
                    freeSolo={true}
                    size="small"
                    // className={styles.field}
                    // onChange={handleTsNums}
                    onChange={handleEvent('serial_number')}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Серийный номер"
                        />
                    )}
                />
                <Tooltip
                    // title={titles.WRITE_TRANSPORT_NUMBER}
                    title={'Ввод серийного номера целиком (после ввода нажмите Enter чтобы добавить)'}
                    style={{ marginLeft: '.5rem', marginTop: '.2rem' }}
                >
                    <IconButton size="small">
                        <ErrorOutline/>
                    </IconButton>
                </Tooltip>
            </div>

            <div className="block">
                <TextField
                    label={'IP-адрес'}
                    // label={titles.NAME}
                    size="small"
                    value={data.ip_route || ''}
                    variant="outlined"
                    name="ip_route"
                    type="text"
                    onChange={(e) => onChange({ [e.target.name]: e?.target?.value?.trim() })}
                    // InputProps={{
                    //     inputComponent: TextMaskCustom,
                    // }}
                />
            </div>

            {/*<div className="block">*/}
            {/*    <TextField*/}
            {/*        label={'Маска подсети'}*/}
            {/*        // label={titles.NAME}*/}
            {/*        size="small"*/}
            {/*        value={data.mask || ''}*/}
            {/*        variant="outlined"*/}
            {/*        name="mask"*/}
            {/*        type="text"*/}
            {/*        onChange={(e) => onChange({ [e.target.name]: e?.target?.value?.trim() })}*/}
            {/*        // InputProps={{*/}
            {/*        //     inputComponent: TextMaskCustom,*/}
            {/*        // }}*/}
            {/*    />*/}
            {/*</div>*/}

            <div className="block">
                <GovernmentContractList
                    label="Госконтракт"
                    multiple
                    selected={data.contract_id || []}
                    onChange={(value) => onChange({ contract_id: value })}
                    renderLabel={item => item?.name || ''}
                    limitTags={4}
                />
            </div>

            <SelectComplex
                multiple={true}
                selected={data.complexes_ids}
                onChange={(value) => onChange({ complexes_ids: value })}
                className="block"
            />

            <div className="block">
                <SimpleSelect
                    title="Район области"
                    selected={data?.area || ''}
                    setSelected={(value) => onChange({ area: value, district: '' })}
                    endpoint={Array.isArray(regionsAll.areas) ? regionsAll.areas : []}
                    optionKey="id"
                    optionValue="name"
                    optionLabel="name"
                    withoutStyles
                />
            </div>

            <div className="block">
                <SimpleSelect
                    title="Район города"
                    selected={data?.district || ''}
                    setSelected={(value) => onChange({ district: value })}
                    endpoint={Array.isArray(cities) ? cities : []}
                    optionKey="id"
                    optionValue="name"
                    optionLabel="name"
                    withoutStyles
                />
            </div>
        </form>
    );
};

export default Filter;
