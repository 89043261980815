// иконка для карты
const iconMap = ({ fill = '#000', innerFill = '#fff' }) => {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 107.000000 130.000000">
            <g>
                <rect x="-1" y="-1" width="109" height="132" id="canvas_background" fill="none"/>
            </g>
            <g>
                <g fill="${fill}" transform="translate(0,130) scale(0.10000000149011612,-0.10000000149011612) " id="svg_ecology_1">
                    <path d="m445,1286c-100,-19 -206,-75 -281,-150c-104,-104 -154,-228 -154,-381c0,-144 38,-245 149,-392c64,-85 297,-316 348,-345c30,-17 31,-17 71,12c68,49 237,216 302,299c157,200 210,350 181,517c-25,142 -121,287 -241,364c-108,70 -251,98 -375,76z" id="svg_ecology_2"/>
                </g>
                <g fill="${innerFill}" stroke="null" id="svg_ecology_7">
                    <path stroke="null" fill="#ffffff" d="m81.325927,24.735781c-0.615842,-1.374648 -2.375392,-1.429634 -3.112204,-0.131966c-3.409128,5.927483 -9.512566,9.622538 -16.484782,9.622538l-8.797748,0c-11.657017,0 -21.114597,9.45758 -21.114597,21.114597c0,0.769803 0.087977,1.506614 0.164958,2.254423c7.016205,-5.025714 17.144613,-9.292622 31.506938,-9.292622c0.967753,0 1.75955,0.791797 1.75955,1.75955s-0.791797,1.75955 -1.75955,1.75955c-27.646926,0 -39.369925,16.946664 -41.965262,23.314034c-0.725814,1.792541 0.131966,3.838018 1.924508,4.57483c1.803538,0.747808 3.849015,-0.120969 4.596823,-1.902513c0.164958,-0.395898 2.298412,-5.267652 7.906977,-9.963451c3.563088,4.827765 10.337354,9.435585 19.234078,8.489827c17.265582,-1.253679 29.417472,-16.737717 29.417472,-35.696866c0,-5.520587 -1.187696,-11.239124 -3.277162,-15.901931z" id="svg_ecology_6"/>
                </g>
            </g>
        </svg>
    `;
};

export default iconMap;
