import React from 'react';
// import { getFullDateTimeWithTextMonth } from '../../../../../helpers/date.config';
// import lightConfig from '../../../../../helpers/light.config';

const Item = (props) => {
    const {
        name,
        desc,
    } = props;

    return (
        <>
            <div className="description">
                <div>{name}</div>
            </div>
            <div>{desc}</div>

            {/*<div className="state">*/}
            {/*    <strong>Состояние:</strong> <span*/}
            {/*        style={*/}
            {/*            state === 4*/}
            {/*                ? { animation: 'flicker 1s linear infinite' }*/}
            {/*                : { color: lightConfig(state).color }*/}
            {/*        }*/}
            {/*    >*/}
            {/*        {states[state]}*/}
            {/*    </span>*/}
            {/*</div>*/}
            {/*<div className="date">*/}
            {/*    {updated_at ? getFullDateTimeWithTextMonth(updated_at) : ''}*/}
            {/*</div>*/}
        </>
    );
};

export default Item;
