import useShowcase from 'helpers/hooks/useShowcase';
import HeaderPage from './HeaderPage';

// верхняя шапка ветрины с условием отображения
const HeaderPageVisible = ({ hideMain = false }) => {
    // витрина
    const isShowcase = useShowcase();

    if (isShowcase) {
        return (
            <HeaderPage hideMain={hideMain} />
        );
    }

    return null;
};

export default HeaderPageVisible;
