import { useState } from 'react';
import { FormHelperText } from '@mui/material';
import { REPORT_FORMATS_SMALL } from 'helpers/report.formats';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SimpleSelect from 'components/common/SimpleSelect';

const GetReport = ({
    formats = REPORT_FORMATS_SMALL,
    handleDownload,
    disabled = false,
    optionLabel = '',
    optionKey = '',
    optionValue = '',
    helperText = 'Для получения отчета заполните все поля!',
    label,
    buttonType = 'getReport',
    emptyItem = false,
    onChange = null
}) => {
    const [ selectedFormat,  setSelectedFormat ] = useState('');

    const disabledBnt = () => {
        return (!selectedFormat || disabled);
    };

    const onSetSelected = (val) => {
        setSelectedFormat(val);
        onChange && onChange(val);
    };

    return (
        <div className="row noMargin">
            <SimpleSelect
                selected={selectedFormat}
                endpoint={formats}
                setSelected={onSetSelected}
                optionLabel={optionLabel}
                optionKey={optionKey}
                optionValue={optionValue}
                title={label}
                emptyItem={emptyItem}
            />
            <FormButtons
                buttons={[
                    {
                        ...buttonsTypes[buttonType],
                        onClick: () => {handleDownload(selectedFormat);setSelectedFormat('');},
                        disabled: disabledBnt()
                    }
                ]}
                noPadding
                noMarginLeft
                positionLeft
            />
            {(disabled && selectedFormat) && (
                <FormHelperText error style={{ flex: '1 0 100%' }}>{helperText}</FormHelperText>
            )}
        </div>
    );
};

export default GetReport;
