import { CHANGE_REFRESH_TIME } from './types';
import { refreshToken, userSignOutSuccess, getAuthUser } from './actions';
import { setAuthToken } from '../../helpers/axios.config';
import { moduleName } from './index';
import { config } from '../../config';
import cookie from 'react-cookies';
// import { changeMapCenter } from '../MapModal/actions';
import instance from '../../helpers/axios.config';

const authMiddleware = () => {
    // идентификатор таймера
    let authTimerId = null;
    // проверить 1 раз
    let first = true;

    // очистить таймер
    const clearCurrentTimeOut = () => {
        if (authTimerId) {
            clearTimeout(authTimerId);
            authTimerId = null;
        }
    };

    // создание таймера для обновления токена
    const createTimer = (store, refreshTime) => {
        authTimerId = setTimeout(() => {
            clearCurrentTimeOut();

            // если есть в куках данные - обновляем - иначе сбрасываем
            // const authUser = cookie.load('authUser') || null;
            const authUser = JSON.parse(localStorage.getItem('authUser'));
            const access_token = authUser?.access_token || null;
            const refresh_token = authUser?.refresh_token || null;

            // если токен в куках есть
            if (refresh_token && access_token) {
                // перезапрос токена
                store.dispatch(
                    refreshToken({
                        refresh_token
                    })
                );
            } else {
                // пользователь out
                store.dispatch(userSignOutSuccess());
            }
        }, Math.min(2147483647, refreshTime));
    };

    return (store) => (next) => (action) => {
        if (first) {
            first = false;
            async function main() {
                const { data } = await instance.get('/config');
                config.setConfig(data);

                // если пользователь сохранил свои данные
                // const needRefresh = cookie.load('needRefresh', config.getCookieOptions()) || null;
                const needRefresh = localStorage.getItem('needRefresh');
                const access_token = store.getState()[moduleName]?.authUser?.access_token || null;
                if (needRefresh && access_token) {
                    // если токен еще живой
                    if (needRefresh > new Date().getTime()) {
                        const timeRefresh = needRefresh - Date.now();
                        setAuthToken(access_token);
                        // токен еще рабочий - перезапрос токена через timeRefresh
                        createTimer(store, timeRefresh);
                        // запрашиваем данные по пользователю
                        store.dispatch(getAuthUser());
                    } else {
                        // время токена вышло - удаляем его
                        store.dispatch(userSignOutSuccess());
                    }
                };
            }
            main();
        }

        const { type, payload } = action;
        switch (type) {
            case CHANGE_REFRESH_TIME: {
                // пересоздаем таймер обработки
                clearCurrentTimeOut();
                payload > 0 && createTimer(store, payload);
                break;
            }

            default: {
                return next(action);
            }
        }
    };
};

export default authMiddleware();
