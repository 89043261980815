const config = {
    name: 'Инфраструктура',
    slug: 'infrastructure',
    // от статуса маркера
    mapMarkersColors: {
        default: '#a5a5a5',
    },
    // ширина линии
    lineWidth: {
        default: 4,
        thin: 4,
        normal: 7,
        thick: 11,
    },
    layerIcon: 'far fa-city',
};
// добавление на карте
export default config;
