// иконка для списка
const iconCluster = (props) => {
    return `
        <svg viewBox="0 0 107.000000 107.000000" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
         <g>
          <g id="svg_trf_1">
           <rect fill="none" id="canvas_background" height="41.62962" width="41.62962" y="-1" x="-1"/>
          </g>
          <g id="svg_trf_2">
           <g fill="${props?.fill || '#e7e7e7'}" id="svg_trf_meteo_1" transform="translate(0,107) scale(0.10000000149011612,-0.10000000149011612)">
            <path stroke="null" id="svg_trf_meteo_2" d="m404.52236,1047.19878c-227.70224,-55.17014 -397.22506,-272.84146 -397.22506,-511.57773c0,-285.88168 240.74246,-525.62105 526.62414,-525.62105c285.88167,0 526.62414,240.74246 526.62414,526.62414c0,237.73318 -172.5321,457.41068 -402.24054,511.57774c-79.24439,18.05568 -175.54138,18.05568 -253.78268,-1.0031z"/>
           </g>
          </g>
          <g fill="${props?.innerFill || 'rgba(0, 0, 0, 0.54)'}" stroke="null" id="svg_trf_13">
           <path stroke="null" id="svg_trf_12" d="m21.77836,37.9187l2.91681,-8.75591c1.19638,-3.58915 4.55923,-6.00939 8.33844,-6.00939l15.24869,0c3.77921,0 7.14096,2.42023 8.33844,6.00939l2.6806,8.0528l-1.6589,0c-5.29529,0 -9.99734,3.38371 -11.67821,8.41534l-2.49384,7.5035c-0.69212,0.48339 -1.32932,1.03269 -1.87862,1.6589l-14.99601,0l0,3.51555c0,1.94454 -1.57431,3.51555 -3.51555,3.51555l-1.75777,0c-1.94124,0 -3.51551,-1.57101 -3.51551,-3.51555l-0.00003,-14.06219c0,-2.79047 1.62045,-5.19642 3.97147,-6.32799l0,0zm7.64082,-0.70311l22.47753,0l-1.94454,-5.82922c-0.24169,-0.71739 -0.91185,-1.20188 -1.66988,-1.20188l-15.24869,0c-0.75804,0 -1.42819,0.48449 -1.66988,1.20188l-1.94454,5.82922zm-2.82342,6.15221c-1.45566,0 -2.63666,1.17551 -2.63666,2.63666c0,1.46115 1.181,2.63666 2.63666,2.63666c1.45566,0 2.63666,-1.17551 2.63666,-2.63666c0,-1.46115 -1.181,-2.63666 -2.63666,-2.63666zm31.04668,-2.63666l15.24869,0c3.77921,0 7.14096,2.41694 8.33844,6.00939l2.9223,8.75591c2.34004,1.13157 3.96598,3.53752 3.96598,6.32799l0,14.06219c0,1.94454 -1.57101,3.51555 -3.51555,3.51555l-1.75777,0c-1.94454,0 -3.51555,-1.57101 -3.51555,-3.51555l0,-3.51555l-28.12438,0l0,3.51555c0,1.94454 -1.57101,3.51555 -3.51555,3.51555l-1.75777,0c-1.94454,0 -3.51555,-1.57101 -3.51555,-3.51555l0,-14.06219c0,-2.79047 1.62594,-5.19642 3.96598,-6.32799l2.9223,-8.75591c1.19748,-3.59245 4.55923,-6.00939 8.33844,-6.00939l0,0zm0,7.03109c-0.75804,0 -1.42819,0.48339 -1.66988,1.19748l-1.94454,5.83361l22.47753,0l-1.94454,-5.83361c-0.24169,-0.7141 -0.91185,-1.19748 -1.66988,-1.19748l-15.24869,0zm-6.43785,18.45662c1.46115,0 2.63666,-1.17551 2.63666,-2.63666c0,-1.46115 -1.17551,-2.63666 -2.63666,-2.63666c-1.46115,0 -2.63666,1.17551 -2.63666,2.63666c0,1.46115 1.17551,2.63666 2.63666,2.63666zm28.12438,-5.27332c-1.46115,0 -2.63666,1.17551 -2.63666,2.63666c0,1.46115 1.17551,2.63666 2.63666,2.63666c1.46115,0 2.63666,-1.17551 2.63666,-2.63666c0,-1.46115 -1.17551,-2.63666 -2.63666,-2.63666z"/>
          </g>
         </g>
        </svg>
    `;
};

export default iconCluster;
