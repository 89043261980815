import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { editScheduleTemplate } from 'redux/TransportPassenger/actions';
import messages from 'helpers/constants/messages';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import TemplateTimeModal from './TemplateTimeModal';
import Stations from './Stations';

const Info = ({ item, hasCurrentRoute, reloadList }) => {
    const dispatch = useDispatch();

    const [isOpenTimeModal, setIsOpenTimeModal] = useState(false);

    const editTemplate = (data) => {
        const result = {
            ...item,
            ...data
        };
        dispatch(editScheduleTemplate(item, result, () => {
            setIsOpenTimeModal(false);
            reloadList();
        }));
        // duration && mileage
    };


    return (
        <div className="info">
            {isOpenTimeModal && ( // модалка изменения времени на остановке в шаблоне
                <TemplateTimeModal
                    el={item}
                    isOpen={isOpenTimeModal}
                    onClose={() => setIsOpenTimeModal(false)}
                    onSave={editTemplate}
                />
            )}
            <div>
                <span>
                    <strong>Количество минут на остановке: </strong>
                    {item.station_stop_time}
                </span>
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setIsOpenTimeModal(true),
                            style: { fontSize: '0.875rem' }
                        },
                    ]}
                    noMarginLeft
                    justButton
                />
            </div>
            <div><strong>Продолжительность: </strong>{item.duration || messages.NO_DATA}</div>
            <div><strong>Общий пробег: </strong>{item.mileage ? `${Number(item.mileage).toFixed(2)} км` : messages.NO_DATA}</div>
            <Stations
                template={item}
                onChangeTmplData={editTemplate}
                hasCurrentRoute={hasCurrentRoute}
                reloadList={reloadList}
            />
        </div>
    );
};
export default Info;
