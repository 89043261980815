import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, ListItem, ListItemText, } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import {
    deleteAlertTemplate,
} from 'redux/Boards/actions';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './AddEdit/ModalForm';
import Info from './Info';

const Item = ({ el = {}, reloadData }) => {
    const dispatch = useDispatch();
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);

    const handleInfo = () => setIsInfoVisible(!isInfoVisible);

    const handleConfirmDelete = () => {
        dispatch(deleteAlertTemplate(el.id, reloadData));
        setIsDelete(false);
    };

    return (
        <>
            <ListItem className="list_item">
                <ListItemText onClick={handleInfo}>
                    <h2>
                        {el?.header || messages.INFO_IS_NOT_FOUND}
                    </h2>
                    <div>
                        <strong>
                            Кол-во показов:
                        </strong>&nbsp;
                        {el?.count ?? messages.NO_DATA}
                        {' '}
                        <strong>
                            Время показа в секундах:
                        </strong>&nbsp;
                        {el?.duration ?? messages.NO_DATA}
                    </div>
                    <div>
                        <strong>
                            Приоритет:
                        </strong>&nbsp;
                        {el.priority_text || messages.NO_DATA}
                    </div>
                </ListItemText>
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: (e) => setIsEditMode(true),
                            disabled: el?.service,
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: (e) => setIsDelete(true),
                        },
                    ]}
                    noMarginLeft
                    justButton
                />

                <IconButton size="small" onClick={handleInfo}>
                    {isInfoVisible
                        ? <ExpandLess fontSize="inherit" />
                        : <ExpandMore fontSize="inherit" />
                    }
                </IconButton>
            </ListItem>
            {isInfoVisible && (
                <Info
                    isOpen={isInfoVisible}
                    data={el}
                />
            )}
            {isDelete &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={isDelete}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setIsDelete(false)}
                />
            )}
            {isEditMode && (
                <ModalForm
                    isOpen={isEditMode}
                    onClose={() => setIsEditMode(false)}
                    item={el}
                    reloadData={reloadData}
                />
            )}
        </>
    );
};

export default Item;
