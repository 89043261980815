import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Checkbox, FormControlLabel } from '@mui/material';

import { editDTPStatuses, loadDtpList, loadDtpTypes } from 'redux/Incidents/actions';
import { createNewDtp } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import messages from 'helpers/constants/messages';
import Context from 'helpers/context';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import ModalForm from 'components/MapComponents/Layers/Incidents/ModalForm';
import ModalFormPopUp from 'components/MapComponents/Layers/Incidents/PopUp/ModalForm';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FormButtonsComponent from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import ReportBlock from 'components/common/Report/ReportBlock';
import PageLayout from 'components/layout/PageLayout';

import Item from './Item';
import Filter from './Filter';

const Incidents = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { permissions } = useContext(Context);

    const loading = useSelector(incidentsSelectors.loading);
    const data = useSelector(incidentsSelectors.listData);
    const meta = useSelector(incidentsSelectors.listMeta);
    const dtp_types = useSelector(incidentsSelectors.dtp_types);
    const editForm = useSelector(incidentsSelectors.editForm);
    const storageLimit = Number(localStorage.getItem('limit')) || 25;

    const initialState = {
        street: [],
        house: '',
        selectedTypes: [],
        selectedTypeLoad: [],
        start_date: getStartOf('year'),
        end_date: getEndOf('day'),
        with_wounded: false,
        with_dead: false,
    };

    const [limit, setLimit] = useState(storageLimit);
    const [params, setParams] = useState(initialState);
    const [openModal, setOpenModal] = useState(false);
    const [openStatusModal, setOpenStatusModal] = useState(false);

    // чекбоксы
    const [checkedAll, setCheckedAll] = useState(false);
    const [checkedElements, setCheckedElements] = useState([]);

    const paramsWithoutEmpty = (filters = params) => removeEmptyFields({
        ...filters,
        dtp_type: filters.selectedTypes?.map(item => item?.id),
        type_load: filters.selectedTypeLoad?.map(item => item.id),
        start_date: filters.start_date && fullDateTimeWithTimeZone(filters.start_date),
        end_date: filters.end_date && fullDateTimeWithTimeZone(filters.end_date),
    });

    const handleCheckAll = () => {
        if (checkedAll) {
            setCheckedElements([]);
        } else {
            const selectedIds = data?.map(item => item?.id);
            setCheckedElements(selectedIds);
        }
        setCheckedAll(!checkedAll);
    };

    const handleCheckFalse = () => {
        setCheckedElements([]);
        setCheckedAll(false);
    };

    const handleCheckboxCheck = (e, id) => {
        const index = checkedElements.findIndex(el => el === id);
        if (index < 0) {
            setCheckedElements([
                ...checkedElements,
                id
            ]);
        } else {
            setCheckedElements([
                ...checkedElements.slice(0, index),
                ...checkedElements.slice(index + 1)
            ]);
            setCheckedAll(false);
        }
    };

    useEffect(() => {
        if (!params) {
            const queryObject = queryString.parse(location.search, { arrayFormat: 'bracket' });
            // queryObject.start_date = queryObject.start_date
            //     ? getFullDateWithZone(new Date(queryObject.start_date))
            //     : getFullDateWithZone(new Date(new Date().setHours(0, 0, 0, 0)));
            setParams((previous) => ({
                ...previous,
                ...queryObject
            }));
        }
    }, [location]);

    const toggleOpenModal = () => setOpenModal(!openModal);

    const reloadList = (closeModal) => {
        const { report, formats, ...newObj } = params;
        dispatch(loadDtpList(meta?.current_page || 1, limit, newObj));
        typeof closeModal === 'function' && closeModal(false);
        handleCheckFalse();
    };

    useEffect(() => {
        dispatch(loadDtpList(1, limit, paramsWithoutEmpty()));
    }, [dispatch, params]);

    useEffect(() => {
        dispatch(loadDtpTypes());
    }, [dispatch]);

    const renderContent = () => {
        return (data?.length > 0
            ? (<>
                <LSContainer
                    headers={[
                        { title: '', width: '5%' },
                        { title: 'Дата', width: '12%' },
                        { title: 'Тип', width: '13%' },
                        { title: 'Адрес', width: '30%' },
                        { title: 'Улица пересечения', width: '15%' },
                        { title: 'Событие', width: '10%' },
                        { title: 'Статус', width: '10%' },
                        { title: 'Действия', isActions: true }
                    ]}
                >
                    {data.map((item) => (
                        <Item
                            key={item.id}
                            data={item}
                            types={dtp_types.data}
                            reloadList={reloadList}
                            onCheck={handleCheckboxCheck}
                            checked={checkedElements.includes(item?.id)}
                        />
                    ))}
                </LSContainer>
            </>)
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    const getReport = (formats) => {
        dispatch(loadDtpList(1, limit, { ...paramsWithoutEmpty(), report: 1, formats }));
    };

    return (
        <div>
            <PageLayout
                header="Отчет по ДТП"
                filters={<Filter
                    dtpTypes={dtp_types.data}
                    setParams={setParams}
                    initialState={initialState}
                    prepareData={paramsWithoutEmpty}
                />}
                content={renderContent}
                loading={loading}
                actionPanel={<>
                    <FormControlLabel
                        control={<Checkbox
                            checked={checkedAll}
                            onChange={handleCheckAll}
                        />}
                        label={checkedElements.length === limit ? 'Снять отметки' : 'Выбрать все'}
                    />
                </>}
                informPanelProps={{
                    buttons: [
                        <FormButtons
                            key={'add_button'}
                            positionLeft
                            noPadding
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: toggleOpenModal,
                                    disabled: !permissions?.is_create,
                                }
                            ]}
                        />,
                        <ReportBlock key={'report_block'} onGetReport={getReport} reportId={22}/>,
                        <FormButtonsComponent
                            key={'change_status'}
                            positionLeft
                            buttons={[
                                {
                                    ...buttonsTypes.defaultInfo,
                                    onClick: () => setOpenStatusModal(true),
                                    disabled: checkedElements.length === 0,
                                    name: 'Изменить статус',
                                    size: 'small'
                                }
                            ]}
                        />
                    ]
                    
                }}
                paginationProps={{
                    loadList: (page, limit) => dispatch(loadDtpList(page, limit, paramsWithoutEmpty())),
                    list: meta,
                    limit,
                    setLimit
                }}
            />
            {openModal
                && <ModalForm
                    data={editForm}
                    onAccept={(newFields) => {
                        dispatch(createNewDtp(newFields, reloadList));
                    }}
                    open={openModal}
                    onClose = {toggleOpenModal}
                />
            }
            {openStatusModal && (
                <ModalFormPopUp
                    open={openStatusModal}
                    onClose={() => setOpenStatusModal(false)}
                    onSave={(status) => dispatch(editDTPStatuses({ ids: checkedElements, new_status: status }, () => reloadList(setOpenStatusModal)))}
                />
            )}
        </div>
    );
};

export default Incidents;
