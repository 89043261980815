const cameraTitles = {
    VIDEOFIXATION: 'Видеофиксация',
    CAMERA: 'Камера',
    CAMERAS: 'Камеры',
    CAMERAS_COMPLEX: 'Камеры комплекса',
    CAMERA_STATUS: 'Статус камеры',
    CAMERA_TYPE: 'Тип камеры',
    COMPLEX_TYPE: 'Тип комплекса',
    VIDEO_STREAM_PROTOCOL_TYPE: 'Тип протокола получения потокового видео',
    TYPE_URL: 'Url для выбранного типа',
    TYPE_PORT: 'Порт для выбранного типа',
    LOGIN_VIDEO_ACCESS: 'Логин для доступа к видео',
    CAMERA_VIEW: 'Градус обзора камеры',
    PROTOCOL: 'Протокол FTP/API',
    CAMERA_DIRECTION: 'Направление действия камеры',
    SPEED_LIMIT: 'Ограничение скорости',
    IP_LIST: 'Список IP',
    ENTER_IP: 'Введите ip',
    ALL_COMPLEX: 'Всего комплексов',
    ONLINE_COMPLEX: 'Комплексов онлайн',
    OFFLINE_COMPLEX: 'Комплексов офлайн',
    IP_ADDRESS: 'IP-адрес',
    MASK: 'маска подсети',
    ENTER_MASK: 'введите маску подсети',
    NAME_GROUP: 'Название группы',
    CPVF: 'КФВФ',
    CITY_CAMERAS: 'Камеры видеонаблюдения',
    SELECT_COMPLEX: 'Выберите комплекс',
    SERIAL_NUMBER: 'Серийный номер',
    CITY_CAMERAS_GROUP: 'Группы видеокамер',
    DIRECTION: 'Направление',
    DIRECTIONS: 'Направления',
    NAME_CAMERA: 'Название камеры',
    SELECT_TAGS: 'Выберите тег',
    CAMERAS_IN: 'Камеры на въезд',
    CAMERAS_OUT: 'Камеры на выезд',
    EVENT_TYPE: 'Тип события',
};

export default cameraTitles;
