import { config } from '../../config';

const apiMainUrl = () => config.getGeneral('urls').apiMain;

const apiUrls = {
    fetchList: () => `${apiMainUrl()}/v2/notification`,

    setRead: (id) => `${apiMainUrl()}/v2/notification/${id}`,

    setReadAll: () => `${apiMainUrl()}/v2/notification/all`,

    fetchCountActive: () => `${apiMainUrl()}/v2/notification/count`,

    loadFreeSpaceServer: () =>  `${apiMainUrl()}/server/free-space`
};

export default apiUrls;
