import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, LinearProgress } from '@mui/material';

import {
    loadShowcase,
    clearShowcase,
} from 'redux/Companies/actions';
import { companiesSelectors } from 'redux/Companies';
import {
    loadShowcase as loadShowcaseSP,
    clearShowcase as clearShowcaseSP,
} from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import Layer from 'components/MapComponents/Layers/TransportIncidents/layer';
import layerConfig from 'components/MapComponents/Layers/TransportIncidents/config';
import CardWidget from 'components/common/Showcase/CardWidget';
import TypeComponent from 'components/common/Showcase/TypeComponent';
import CardMap from 'components/common/Showcase/CardMap';
import useShowcase from 'helpers/hooks/useShowcase';
import messages from 'helpers/constants/messages';

import Header from './Header';

// страница
const Page = () => {
    const dispatch = useDispatch();
    const isShowCase = useShowcase();

    const showCaseC = useSelector(companiesSelectors.showcase);
    const loadingShowcaseC = useSelector(companiesSelectors.loadingShowcase);

    const showCaseSP = useSelector(SituationalPlansSelectors.showcase);
    const loadingShowcaseSP = useSelector(SituationalPlansSelectors.loadingShowcase);

    useEffect(() => {
        dispatch(loadShowcase());
        dispatch(loadShowcaseSP());
        return () => {
            dispatch(clearShowcase());
            dispatch(clearShowcaseSP());
        };
    }, []);

    const dataCompanies = useMemo(() => {
        const list = showCaseC?.reduce((r, company) => {
            const title = company?.title || '';
            const phones = company?.phones || [];
            phones?.forEach(({ person, data }) => {
                r.push({
                    name: `${person || ''}${title ? ` (${title})` : ''}`,
                    value: data || '',
                    threshold: false
                });
            });
            return r;
        }, [])?.slice(0, 5)
        || [];

        if (list?.length > 0) {
            return {
                name: 'Связь с оперативными службами',
                type: 6,
                data: {
                    cells: list
                }
            };
        }

        return null;
    }, [showCaseC]);

    return (
        <Grid
            container
            spacing={2}
            sx={{ height: '100%' }}
        >
            {(loadingShowcaseC || loadingShowcaseSP) && <LinearProgress/>}
            <Grid
                item
                xs={12}
                md={8}
                className="showcase_grid_flex-column"
            >
                <CardWidget
                    icon={layerConfig.layerIcon}
                    title="События и уровни угрозы"
                    marginBottom
                    small
                    fillOthers
                >
                    <Header/>
                </CardWidget>

                {Object.keys(showCaseSP).length > 0 && (
                    <CardWidget
                        icon={layerConfig.layerIcon}
                        title={showCaseSP?.name}
                        // marginBottom
                        small
                        fillOthers
                    >
                        <div>
                            <TypeComponent
                                {...showCaseSP}
                            />
                        </div>
                    </CardWidget>
                )}
            </Grid>

            <Grid
                item
                xs={12}
                md={4}
                className="showcase_grid_flex-column"
            >
                <CardMap
                    fillOthers
                    isCluster
                >
                    <Layer readOnly={isShowCase} minPopUp={true} showcaseFilters={{  is_statistics: 1 }}/>
                </CardMap>

                <CardWidget
                    icon={layerConfig.layerIcon}
                    title="Связь с оперативными службами"
                    // marginBottom
                    small
                    fillOthers
                >
                    {dataCompanies ? (
                        <TypeComponent
                            {...dataCompanies}
                        />
                    ) : (
                        <div>{messages.NO_DATA}</div>
                    )}
                </CardWidget>
            </Grid>
        </Grid>
    );
};

export default Page;
