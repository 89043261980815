import { useState } from 'react';
import {
    Divider,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import messages from '../../../../../../../../../helpers/constants/messages/common';
import ConfirmDelete from '../../../../../../../../common/ConfirmModal';
import { IconButton, Tooltip } from '@mui/material';
import titles from '../../../../../../../../../helpers/constants/titles';
import { useStoreProp, useValidation } from '../../../../../../../../../helpers/hooks';
import { loadTsCategories } from '../../../../../../../../../redux/DorisControl/actions';

const SpeedLimitItem = ({
    item,
    onChangeItem,
    onDeleteItem,
    field,
    error, 
    helperText,
}) => {
    const transType = useStoreProp(
        loadTsCategories,
        'dorisControl',
        'tsCategories'
    );

    const { type, speed } = item;

    const [isDelete, setIsDelete] = useState(false);
    const limitError = speed < 0 || speed % 10 !== 0;

    const onChangeData = (e) => {
        const { name, value } = e.target;
        onChangeItem({
            ...item,
            [name]: value,
        });
    };

    const fieldType = field.fields?.find(el => el.field === 'type') || {};
    const fieldSpeed = field.fields?.find(el => el.field === 'speed') || {};

    return (<>
        <div className="row align-items-center">
            <div className="row__item">
                <div className="row">
                    <FormControl
                        className="row__item"
                        size="small"
                        variant="outlined"
                        required={fieldType.require}
                        error={(!type && fieldType.require) || error('type')}
                    >
                        <InputLabel htmlFor="type">{fieldType.name}</InputLabel>
                        <Select
                            value={type || ''}
                            onChange={onChangeData}
                            name={fieldType.field}
                            label={fieldType.name}
                        >
                            {Object.keys(transType)?.map((key) =>
                                <MenuItem value={key} key={key}>{transType[key]}</MenuItem>
                            )}

                        </Select>
                        {helperText('type') && <FormHelperText error>{helperText('type')}</FormHelperText>}
                    </FormControl>
                    <FormControl className="row__item" size="small" variant="outlined">
                        <TextField
                            label={fieldSpeed.name}
                            variant="outlined"
                            size="small"
                            value={speed || ''}
                            name={fieldSpeed.field}
                            onChange={onChangeData}
                            inputProps={{
                                step: 10,
                                min: 0
                            }}
                            type={fieldSpeed.type}
                            required={fieldType.require}
                            error={(!speed && fieldType.require) || error('speed') || limitError}
                            helperText={(limitError && 'Выбранное значение должно быть больше 0 и кратно 10.') || helperText('speed')}
                        />
                    </FormControl>
                </div>
            </div>
            <Tooltip title={titles.DELETE}>
                <IconButton onClick={() => setIsDelete(true)} size="small">
                    <i className="far fa-times"></i>
                </IconButton>
            </Tooltip>
        </div>
        <Divider style={{ marginBottom: '5px' }}/>
        {isDelete
            && <ConfirmDelete
                open={isDelete}
                onClose={()=> setIsDelete(false)}
                onSuccess={()=> {
                    setIsDelete(false);
                    onDeleteItem();
                }}
                message={messages.CONFIRM_DELETE}
            />
        }
    </>);
};

export default SpeedLimitItem;