import React from 'react';
import messages from '../../../../helpers/constants/messages';


const InformationList = (props) => {
    const {
        data = {},
        fields = {},
        templateItem: TemplateItem = ({ fieldKey, name, value }) => (
            <div key={fieldKey}>
                <b>{name}: </b>
                {value || messages.INFO_IS_NOT_FOUND}
            </div>
        ),
        showNotFound = false,
    } = props;

    return (
        <>
            {Object.keys(fields).map((fieldKey) => {
                const {
                    name = '',
                    f = null,
                } = fields[fieldKey] || {};
                const value = f ? f(data) : data[fieldKey] || null;
                if (value || showNotFound) {
                    return (
                        <TemplateItem
                            key={fieldKey}
                            fieldKey={fieldKey}
                            name={name}
                            value={value}
                        />
                    );
                }
            })}
        </>
    );
};

export default InformationList;
