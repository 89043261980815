import { useEffect, useRef, useState } from 'react';
import { Autocomplete, IconButton, InputAdornment, TextField, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import titles from '../../../../../helpers/constants/titles';
import { loadKeyWordList } from '../../../../../redux/SituationalPlans/actions';
import FormButtons from '../../../FormButtons';
import UniversalSelect from '../../../UniversalSelect';
import AddNewKeyWord from './AddKeyWord';
import _ from 'lodash';

const useStyles = makeStyles({
    input: {
        paddingRight: '54px !important',
    },
    adornment: {
        position: 'absolute',
        right: '6px',
    },
});

const KeyWords = ({
    multiple = false,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label=titles.KEYWORDS,
    error = false,
    helperText = '',
    filter = {},
}) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);
    const [isOpenAddKeyWord, setIsOpenAddKeyWord] = useState(false);
    const [limit, setLimit] = useState(25);

    useEffect(() => {
        // например сброс фильтра - selected изменился нужно обновить selectedItems
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleClose = () => setShowList(false);

    const autoCompleteChange = (e, value) => {
        if (e?.key !== 'Enter') onChange(value);
    };

    const getList = (params) => {
        const { page, limit, query: name } = params;
        setLimit(limit);

        dispatch(loadKeyWordList(page, limit, {
            ...(name && { name }),
            ...filter,
        }));
    };

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    const onClose = () => {
        dispatch(loadKeyWordList(1, limit));
        setIsOpenAddKeyWord(false);
    };

    return (
        <>
            <Autocomplete
                multiple={multiple}
                value={selected}
                options={[]}
                disabled={!multiple}
                // filterSelectedOptions
                getOptionLabel={(option) => option?.name || ''}
                onChange={autoCompleteChange}
                freeSolo
                size="small"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={error}
                        size="small"
                        variant="outlined"
                        required={required}
                        name="type"
                        label={label}
                        disabled={!multiple}
                        helperText={helperText}
                        InputProps={{
                            ...params.InputProps,
                            classes: { root: styles.input },
                            endAdornment: (
                                <InputAdornment position={'end'} classes={{ root: styles.adornment }}>
                                    {selected && Object.keys(selected).length > 0 
                                            && <IconButton disabled={disabled} size="small" onClick={() => onChange(multiple ? [] : {})}>
                                                <i className="far fa-times"/>
                                            </IconButton>
                                    }
                                    <IconButton disabled={disabled} size="small" onClick={() => setShowList(true)}>
                                        <i className="fas fa-search-plus"/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                )}
            />
            {showList 
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName={'SituationalPlans'}
                    storeNameProps={'keyWords'}
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div>{el.name}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={titles.KEYWORDS}
                    small
                    buttons={
                        <div style={{ paddingLeft: '1rem' }}>
                            <FormButtons
                                noPadding
                                noMarginLeft
                                positionLeft
                                justButton
                                buttons={[
                                    {
                                        onClick: () => setIsOpenAddKeyWord(true),
                                        name: 'Добавить новое ключевое слово'
                                    }
                                ]}
                            />
                        </div>
                    }
                />
            }
            {isOpenAddKeyWord && <AddNewKeyWord isOpen={isOpenAddKeyWord} onClose={onClose} />}
        </>
    );
};

export default KeyWords;
