import React, { useState } from 'react';
import removeEmptyFields from '../../../../helpers/removeEmptyFields';
import LayoutFilter from '../../../layout/PageLayout/LayoutFilter';
import ScheduledReportsTemplatesModal from './ScheduledReportsTemplatesModal';


const Filter = ({ setParams }) => {
    const initialState = {
        templateIds: [],
    };

    const [data, setData] = useState(initialState);

    const handleResetFilter = (needRefresh) => {
        setData(initialState);

        if (needRefresh) {
            setParams({});
        }
    };

    const handleSearch = () => {
        const prepareData = removeEmptyFields(data, false);
        setParams(prepareData);
    };

    const updateFilter = (filters) => {
        setData({ // обновляем локальный стейт
            ...initialState,
            ...filters,
        });

        if (Object.keys(filters).length > 0) {
            setParams(removeEmptyFields({ // отправляем в родителя, чтобы произошел запрос
                ...initialState,
                ...filters,
            }));
        }
    };

    const handleChange = (key) => (value) => setData(old => ({
        ...old,
        [key]: value
    }));

    return (
        <LayoutFilter
            onResetFilter={handleResetFilter}
            onSearch={handleSearch}
            filter={data}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <ScheduledReportsTemplatesModal
                    selected={data.templateIds}
                    onChange={handleChange('templateIds')}
                    multiple
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
