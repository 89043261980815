import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, InputAdornment, TextField, } from '@mui/material/';
import { Autocomplete } from '@mui/material';
import _ from 'lodash';
import { loadSigns } from '../../../../../redux/Boards/actions';
import titles from '../../../../../helpers/constants/titles';
import UniversalSelect from '../../../UniversalSelect';
import FormButtons, { buttonsTypes } from '../../../FormButtons';
import { loadListTemplateImage } from '../../../../../redux/Boards/actions';
import { boardsSelectors } from '../../../../../redux/Boards';


const SelectSigns = ({
    multiple = false,
    onChange = () => {},
    required = false,
    disabled = false,
    label= titles.SELECT_SIGN,
    error = false,
    helperText = '',
    filter = {},
    sortedList, 
    selected = multiple ? [] : '',
    // selected значения тип string, по которым мы загрузим полные объекты для отрисовки,
    // чтобы не хранить эти объекты в компоненте-родителе
}) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const boardSigns = useSelector(boardsSelectors.boardSigns);
    const boardSignsLoading = useSelector(boardsSelectors.boardSignsLoading);
    const [loaded, setLoaded] = useState(false);
    
    const needLoadData = useCallback(() => {
        if (multiple) {
            return selected.length > 0
                ? selected.reduce((acc, el) => {
                    if (!boardSigns[el]) {
                        acc.push(el);
                    }
                    return acc;
                }, [])
                : [];
        }
        return selected && !boardSigns[selected] ? [selected] : [];
    },[boardSigns, selected, multiple]);

    useEffect(() => {
        const dataForLoad = needLoadData();
        if (dataForLoad.length > 0 && !loaded && !boardSignsLoading) {
            dispatch(loadSigns({ 
                page: 1, 
                limit: 100,
                signs: dataForLoad 
            }, () => setLoaded(true)));
        }
    }, [needLoadData, dispatch, loaded, setLoaded, boardSignsLoading]);

    useEffect(() => {
        // первая установка selectedItems если мы загрузили данные для selected
        if (selected ) {
            const fullData =  multiple 
                ? selected?.map((el) => boardSigns[el] || { sign: el })
                : (boardSigns[selected] || {});
            if (!_.isEqual(selectedItems, fullData)) {
                setSelected(fullData);
            }
        }
    }, [multiple, boardSigns, selected, selectedItems]);

    const autoCompleteChange = (e, value) => {
        if (e?.key !== 'Enter') onChange(value);
    };

    const onClearAutocomplete = () => {
        setSelected(multiple ? [] : {});
        onChange(multiple ? [] : '');
    };

    const getList = (params) => {
        const { page, limit, query: name } = params;
        dispatch(loadListTemplateImage({
            page, 
            limit, 
            ...(name && { name }),
            ...filter,
        }));
    };

    const handleAccept = (data) => {
        const signNames = multiple ? data.map(el => el.sign) : data.sign;
        onChange(signNames);
        setShowList(false);
    };

    return (
        <>
            <Autocomplete
                required={required}
                multiple={multiple}
                value={selectedItems}
                options={[]}
                disabled={!multiple || disabled}
                filterSelectedOptions
                getOptionLabel={(option) => option?.name || ''}
                onChange={autoCompleteChange}
                freeSolo
                size="small"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        disabled={disabled}
                        error={error}
                        size="small"
                        variant="outlined"
                        required={required}
                        label={label}
                        helperText={helperText}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position={'end'}>
                                    {selected && Object.keys(selected).length > 0 
                                            && <IconButton size="small" onClick={onClearAutocomplete}>
                                                <i className="far fa-times"/>
                                            </IconButton>
                                    }
                                    <IconButton disabled={disabled} size="small" onClick={() => setShowList(true)}>
                                        <i className="fas fa-search-plus"/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                multiple
                                    ? null
                                    : <InputAdornment position={'start'}>
                                        <img src={selectedItems?.image}  alt=""  style={{ width: 'auto', height: '30px' }} /> 
                                    </InputAdornment>
                            )
                        }}
                    />
                )}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName="boards"
                    storeNameProps="templateImageList"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div className="row">
                        <img src={el.image} style={{ width: 'auto', height: '30px' }} alt=""/>
                        <span className="row__item">{el.name}</span>
                    </div>}
                    isOpen={showList}
                    onClose={() => setShowList(false)}
                    title={label}
                    small
                    noPadding
                    onAccept={handleAccept}
                />
            }
        </>)
    ;
};

export default SelectSigns;