import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import { loadRepairTypes } from 'redux/RoadWorks/actions';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const SelectRepairTypes = ({
    multiple = false,
    selected = multiple ? [] : '',
    onChange = () => {},
    required = false,
    disabled = false,
    label = 'Тип ремонта',
    error = false,
    helperText = '',
}) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);

    useEffect(() => {
        if (!isEqual(prevData.current, selected)) { 
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    const getList = ({ page, limit, query: name }) => {
        dispatch(loadRepairTypes({ page, limit, name }));
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                error={error}
                helperText={helperText}
                required={required}
                disabled={disabled}
                onReset={() => onChange(multiple ? [] : {})}
                renderLabel={(option) => option?.name || ''}
                limitTags={2}
                label={label}
                onShowList={() => setShowList(true)}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    title={'Тип ремонта'}
                    storeName={'roadworks'}
                    storeNameProps={'repairTypes'}
                    storeLoadingProps={'loadingRepairTypes'}
                    keyProp={'id'}
                    fetchList={getList}
                    withSearch={true}
                    multiple={multiple}
                    isSelected={true}
                    selected={selectedItems}
                    renderProps={({ name }) => <span>{name}</span>}
                    isOpen={showList}
                    onClose={() => setShowList(false)}
                    onAccept={handleAccept}
                    noPadding
                    small
                />
            }
        </>
    );
};

export default SelectRepairTypes;