import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadNearDefects } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import ModalForm from 'components/MapComponents/Layers/Defects/ModalForm';
import DefectList from './DefectList';
import Map from './Map';

const DefectsModalForm = ({
    isOpen,
    onClose,
    site,
    onChange
}) => {
    const dispatch = useDispatch();
    
    const [formData, setFormData] = useState(site.defections);
    const [createDefect, setCreateDefect] = useState(null);

    const nearDefects = useSelector(roadworksSelectors.nearDefects);

    const loadDefects = useCallback(() => {
        dispatch(loadNearDefects({ geometry: site.data }));
    }, [dispatch, site.data]);

    useEffect(() => {
        loadDefects();
    }, [loadDefects]);

    return (
        <Modal
            title="Недостатки"
            onClose={onClose}
            fullWidth
            noPadding
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: () => {
                            onChange(formData);
                            onClose();
                        }
                    }
                ]}
            />}
        >
            <Grid container direction="row" alignItems="stretch">
                <Grid item xs={8}>
                    <Map
                        formData={formData}
                        setFormData={setFormData}
                        nearDefects={nearDefects}
                        site={site}
                        setCreateDefect={setCreateDefect}
                        setDeleteDefect={(value) => setFormData(formData.filter(item => item.id !== value))}
                    />
                </Grid>
                <Grid item xs={4} >
                    <h2>Для добавления недостатка, кликните по карте в нужном месте</h2>
                    {nearDefects?.length > 0
                        && <DefectList
                            showCheckbox
                            items={nearDefects}
                            checkboxes={formData}
                            setItems={setFormData}
                        />
                    }
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.create,
                                name: 'Создать недостаток',
                                onClick: () => setCreateDefect({}),
                                size: 'small',
                                variant: 'outlined',
                            }
                        ]}
                    />
                    
                </Grid>
            </Grid>
            {createDefect
                && <ModalForm
                    open={!!createDefect}
                    onClose={() => setCreateDefect(null)}
                    data={createDefect}
                    loadList={loadDefects}
                />
            }
        </Modal>
    );
};

export default DefectsModalForm;
