import React, { useEffect, useMemo } from 'react';
import { Bar as GBar } from 'react-chartjs-2';
import { Chart } from 'chart.js';

// const plugin = {
//     id: 'custom_canvas_background_color',
//     beforeDraw: (chart, args, options) => {
//
//         var margin = 46;
//         var margin_end = 5;
//         console.log(chart.scales);
//
//         if (chart?.scales['x']?.bottom && chart?.scales['x']?.top /*chart.config.options.title.text == 'chart_one'*/) {
//             var diff = chart.scales['x'].bottom - chart.scales['x'].top;
//             // var diff = chart.scales['x'].end - chart.scales['x'].start;
//             var val = 10 - chart.scales['x'].start;
//             const ctx = chart.ctx;
//             const x = (val / diff) * (chart.width - (margin + margin_end)) + margin;
//             const topY = chart.scales['y'].top;
//             const bottomY = chart.scales['y'].bottom;
//             ctx.save();
//             ctx.beginPath();
//             ctx.moveTo(x, topY);
//             ctx.lineTo(x, bottomY);
//             ctx.lineWidth = 2;
//             ctx.strokeStyle = '#ff0000';
//             ctx.stroke();
//         }
//
//         //
//         //
//         // console.log(chart);
//         // console.log(args);
//         // console.log(options);
//         // console.log('');
//         //
//         // const { ctx } = chart;
//         // ctx.save();
//         // ctx.globalCompositeOperation = 'destination-over';
//         // ctx.fillStyle = options.color;
//         // ctx.fillRect(0, 0, chart.width, chart.height);
//         // ctx.restore();
//     },
//     defaults: {
//         color: 'lightGreen'
//     }
// };

// линейный график
const Bar = ({
    data = [],
    legendPosition = 'bottom',
    horizontal = false,
}) => {

    // useEffect(() => {
    //     Chart.register(plugin);
    // }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: legendPosition,
            },
            // title: {
            //     display: true, text: 'Chart.js Line Chart',
            // },
        },
        // scales: {
        //     yAxis: {
        //         min: 0,
        //         max: 10,
        //     },
        // }
    };

    if (horizontal) {
        options.indexAxis = 'y';
    }

    const dataG = useMemo(() => {
        const labels = data[0].items.map(({ label }) => label);

        const datasets = data.map(({ items, color, name }) => {
            return {
                label: name,
                // borderColor: color,
                backgroundColor: color,
                data: items.map(({ value }) => value)
            };
        });

        return {
            labels,
            datasets
        };
    }, [data]);

    return <GBar options={options} data={dataG} style={{ maxHeight: '300px' }} />;
};

export default Bar;
