const compayTitles = {
    COMPANY: 'Компания',
    COMPANIES: 'Компании',
    INN: 'ИНН',
    ADD_ORGANIZATION: 'Добавление организации',
    SELECT_ORGANIZATION: 'Выбрать организацию',
    EDIT_ORGANIZATION: 'Редактировать организацию',
    EDIT_BRANCH: 'Редактировать филиал',
    ADD_BRANCH: 'Добавить филиал',
    LEGAL_ADDRESS: 'Юридический адрес',
    MAIL_ADDRESS: 'Почтовый адрес',
    REAL_ADDRESS: 'Фактический адрес',
    COMPANY_SEARCH: 'Поиск организации',
    SEARCH_BY_TITLE_OR_INN: 'Найти по ИНН или наименованию',
    COMPANY_INFORMATION: 'Информация об организации',
    INPUT_REGION: 'Введите регион',
    INPUT_POSTAL_CODE: 'Введите индекс',
    INPUT_STREET: 'Введите улицу',
    INPUT_HOUSE: 'Введите дом',
    ADDITIONAL_INFORMATION: 'Введите дополнительную информацию',
    FULL_NAME: 'Полное наименование',
    ORGANIZATION: 'Организация',
    ORGANIZATIONS: 'Организации',
    ORG_ID: 'ID орг.',
    BRANCHES: 'Филиалы'
};

export default compayTitles;