import OutputSettings from '../../../../common/OutputSettings';

const EcologyOutputSettings = () => {
    return (
        <OutputSettings
            title="Настройки вывода параметров"
            storeName="ecology"
        />
    );
};

export default EcologyOutputSettings;
