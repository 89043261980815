const filterList = {
    // ip_route: {
    //     key: 'complex_ip_route',
    //     f: (filter /* строка */) => (value /* строка */) => value?.includes(filter)
    // },
    mask: {
        key: 'complex_mask',
        f: (filter /* строка */) => (value /* строка */) => value?.includes(filter)
    },
    name: {
        key: 'complex_name',
        f: (filter /* строка */) => (value /* строка */) => value?.toLowerCase()?.includes(filter.toLowerCase())
    },
    serial_number: {
        key: 'complex_serial_number',
        f: (filter /* массив */) => (value /* строка */) => filter?.includes(value) // filter - массив
    },
    complexes_ids: {
        key: 'complex_id',
        f: (filter) => (value) => filter.map(({ id }) => id).includes(value)
        
    }
};

export default filterList;
