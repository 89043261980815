import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';

import { loadActiveFrame, loadVideoCameraFrame } from 'redux/VideoCameras/actions';

const FrameButton = ({ cameraIds = [], style = {}, className = '' }) => {
    const dispatch = useDispatch();
    const { activeFrames } = useSelector(({ videoCameras }) => videoCameras);

    const currentFrame = activeFrames?.filter(item => cameraIds.includes(item.id));

    const handleChecked = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (currentFrame.length === 0){
            const mapActive = activeFrames;
            cameraIds.forEach(id => {
                mapActive.push({
                    link: '',
                    id: id,
                    checked: true
                });
                dispatch(loadVideoCameraFrame(id));
            });
            dispatch(loadActiveFrame(mapActive));
        } else {
            const mapActive = activeFrames.filter(item => !cameraIds.includes(item.id));
            dispatch(loadActiveFrame(mapActive));
        }
    };

    const isPlaying = currentFrame.length > 0 && cameraIds.length === currentFrame.length;

    return (
        <Tooltip title={isPlaying ? 'Остановить просмотр' : 'Просмотр видео'} className={className}>
            <IconButton
                onClick={handleChecked}
                style={{
                    fontSize: '1rem',
                    padding: 5,
                    ...style
                }}
            >
                <i className={`fas fa-${isPlaying? 'stop' : 'play'}-circle`}></i>
            </IconButton>
        </Tooltip>
    );
};

export default FrameButton;