import * as types from './types';

/**
 * Actions
 **/

export const setWsTelemetry = (data) => ({
    type: types.SET_WS_TELEMETRY,
    payload: data
});
export const clearWsTelemetry = () => ({
    type: types.CLEAR_WS_TELEMETRY,
});

export const setWsVehicle = (events) => ({
    type: types.SET_WS_VEHICLE,
    payload: events
});


export const setFilters = (filter) => ({
    type: types.SET_FILTER,
    payload: filter,
});
export const clearFilters = () => ({
    type: types.SET_FILTER,
    payload: {},
});

export const setActive = (item) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const clearActive = () => ({
    type: types.SET_ACTIVE,
    payload: {},
});


export const loadSidebar = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_SIDEBAR,
    payload: {
        page,
        limit,
        params,
    },
});

export const loadedSidebar = (data) => ({
    type: types.LOADED_SIDEBAR,
    payload: data,
});

export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool,
});

export const clearSidebar = () => ({
    type: types.CLEAR_SIDEBAR,
});

// VEHICLES
export const loadVehicleList = (page = 1, limit = 25, params) => ({
    type: types.LOAD_VEHICLES,
    payload: { page, limit, params },
});

export const loadingVehicles = (bool) => ({
    type: types.LOADING_VEHICLES,
    payload: bool,
});

export const loadedVehicles = (response) => ({
    type: types.LOADED_VEHICLES,
    payload: response
});

export const createVehicle = (data, callback = () => {}) => ({
    type: types.CREATE_VEHICLE,
    payload: { data },
    callback
});

export const editVehicle = (id, data, callback = () => {}) => ({
    type: types.EDIT_VEHICLE,
    payload: { id, data },
    callback
});

export const deleteVehicle = (id, callback = () => {}) => ({
    type: types.DELETE_VEHICLE,
    payload: { id },
    callback
});

// VEHICLE CATEGORIES
export const loadVehicleCategories = () => ({
    type: types.LOAD_VEHICLE_CATEGORIES,
});
export const loadingVehicleCategories = (bool) => ({
    type: types.LOADING_VEHICLE_CATEGORIES,
    payload: bool,
});
export const loadedVehicleCategories = (response) => ({
    type: types.LOADED_VEHICLE_CATEGORIES,
    payload: response
});

export const createVehicleCategory = (data, callback = () => {}) => ({
    type: types.CREATE_VEHICLE_CATEGORY,
    payload: data,
    callback
});

export const editVehicleCategory = (id, data, callback = () => {}) => ({
    type: types.EDIT_VEHICLE_CATEGORY,
    payload: { id, data },
    callback
});

export const deleteVehicleCategory = (id, callback = () => {}) => ({
    type: types.DELETE_VEHICLE_CATEGORY,
    payload: id,
    callback
});

// VEHICLE CHARACTERISTICS
export const loadVehicleCharacteristics = () => ({
    type: types.LOAD_VEHICLE_CHARACTERISTICS,
});

export const loadedVehicleCharacteristics = (response) => ({
    type: types.LOADED_VEHICLE_CHARACTERISTICS,
    payload: response
});

// BRANDS
export const loadBrands = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_BRANDS,
    payload: { page, limit, params },
});

export const loadingBrands = (bool) => ({
    type: types.LOADING_BRANDS,
    payload: bool,
});

export const loadedBrands = (response) => ({
    type: types.LOADED_BRANDS,
    payload: response
});

export const createBrand = (data, callback = () => {}) => ({
    type: types.CREATE_BRAND,
    payload: { data },
    callback
});

export const editBrand = (id, data, callback = () => {}) => ({
    type: types.EDIT_BRAND,
    payload: { id, data },
    callback
});

export const deleteBrand = (id, callback = () => {}) => ({
    type: types.DELETE_BRAND,
    payload: { id },
    callback
});

// MODELS
export const loadModels = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_MODELS,
    payload: { page, limit, params },
});

export const loadingModels = (bool) => ({
    type: types.LOADING_MODELS,
    payload: bool,
});

export const loadedModels = (response) => ({
    type: types.LOADED_MODELS,
    payload: response
});

export const createModel = (data, callback = () => {}) => ({
    type: types.CREATE_MODEL,
    payload: { data },
    callback
});

export const editModel = (id, data, callback = () => {}) => ({
    type: types.EDIT_MODEL,
    payload: { id, data },
    callback
});

export const deleteModel = (id, callback = () => {}) => ({
    type: types.DELETE_MODEL,
    payload: { id },
    callback
});

// RADIO TYPES
export const loadRadioTypes = (page, limit, params) => ({
    type: types.LOAD_RADIO_TYPES,
    payload: { page, limit, params },
});

export const loadingRadioTypes = (bool) => ({
    type: types.LOADING_RADIO_TYPES,
    payload: bool,
});

export const loadedRadioTypes = (response) => ({
    type: types.LOADED_RADIO_TYPES,
    payload: response
});


// VEHICLE CARD
export const loadVehicleCard = (id, data) => ({
    type: types.LOAD_VEHICLE_CARD,
    payload: { id, data }
});

export const loadingVehicleCard = (bool = false) => ({
    type: types.LOADING_VEHICLE_CARD,
    payload: bool
});

export const loadedVehicleCard = (data) => ({
    type: types.LOADED_VEHICLE_CARD,
    payload: data
});

export const clearVehicleCard = () => ({
    type: types.CLEAR_VEHICLE_CARD
});

// VEHICLE TYPES
export const loadVehicleTypes = () => ({
    type: types.LOAD_VEHICLE_TYPES
});

export const loadingVehicleTypes = (bool) => ({
    type: types.LOADING_VEHICLE_TYPES,
    payload: bool
});

export const loadedVehicleTypes = (data) => ({
    type: types.LOADED_VEHICLE_TYPES,
    payload: data
});

// OTHER
// export const getCurrentSpecialReport = (id, params = {}, callbackClose) => ({
//     type: types.GET_CURRENT_SPECIAL_REPORT,
//     payload:  { id, params },
//     callbackClose
// });

// <coords actions
export const loadCoords = (ids) => ({
    type: types.LOAD_COORDS,
    payload: ids,
});
export const loadedCoords = (data) => ({
    type: types.LOADED_COORDS,
    payload: data,
});
export const loadingCoords = (bool = false) => ({
    type: types.LOADING_COORDS,
    payload: bool,
});
export const clearCoords = () => ({
    type: types.CLEAR_COORDS,
});
// coords actions>

export const setRoutesVehicle = (id, data) => ({
    type: types.SET_ROUTES_VEHICLE,
    payload: {
        id,
        data,
    },
});
export const clearRoutesVehicleById = (id) => ({
    type: types.CLEAR_ROUTES_VEHICLE_BY_ID,
    payload: id,
});
export const clearRoutes = () => ({
    type: types.CLEAR_ROUTES_VEHICLE,
});

export const setMarkers = (data, useFilter = true) => ({
    type: types.SET_MARKERS,
    payload: {
        data,
        useFilter,
    }
});

export const setSidebarParams = (params) => ({
    type: types.SET_SIDEBAR_PARAMS,
    payload: params
});

export const getReportTS = (params) => ({
    type: types.GET_REPORT,
    payload: params,
});

export const getFilteredTSList = (page = 1, limit = 25, filter) => ({
    type: types.GET_FILTERED_LIST,
    payload: { page, limit, ...filter }
});

export const getFilteredTSReport = (filter, callback) => ({
    type: types.GET_FILTERED_LIST,
    payload: filter,
    callback
});

export const loadedFilteredTSList = (data) => ({
    type: types.LOADED_FILTERED_LIST,
    payload: data
});

export const loadingFilteredTSList = (bool = false) => ({
    type: types.LOADING_FILTERED_LIST,
    payload: bool
});

export const setFilteredTSListFilter = (data) => ({
    type: types.SET_FILTERED_LIST_FILTER,
    payload: data
});

export const clearFilteredTSListFilter = (data) => ({
    type: types.CLEAR_FILTERED_LIST_FILTER,
    payload: data
});

/* color speed page */
export const loadColorSpeed = () => ({
    type: types.LOAD_COLOR_SPEED,
});
export const loadedColorSpeed = (data) => ({
    type: types.LOADED_COLOR_SPEED,
    payload: data
});
export const loadColorSpeedList = (props) => ({
    type: types.LOAD_COLOR_SPEED_LIST,
    payload: props
});
export const loadedColorSpeedList = (data) => ({
    type: types.LOADED_COLOR_SPEED_LIST,
    payload: data
});
export const loadingColorSpeedList = (bool = false) => ({
    type: types.LOADING_COLOR_SPEED_LIST,
    payload: bool
});
export const setColorSpeedListProps = (props) => ({
    type: types.SET_COLOR_SPEED_LIST_PROPS,
    payload: props
});
export const resetColorSpeedListProps = (props) => ({
    type: types.RESET_COLOR_SPEED_LIST_PROPS,
});
export const addColorSpeed = (data, callback) => ({
    type: types.ADD_COLOR_SPEED,
    payload: data,
    callback,
});
export const editColorSpeed = (id, data, callback) => ({
    type: types.EDIT_COLOR_SPEED,
    payload: {
        id,
        data,
    },
    callback,
});
export const deleteColorSpeed = (id, callback) => ({
    type: types.DELETE_COLOR_SPEED,
    payload: id,
    callback,
});

// history
export const loadHistoryVehicle = (vehicleId, params = {}) => ({
    type: types.LOAD_HISTORY_VEHICLE,
    payload: {
        vehicleId,
        params,
    }
});
export const loadedHistoryVehicle = (id, data) => ({
    type: types.LOADED_HISTORY_VEHICLE,
    payload: {
        id,
        data
    },
});
export const loadingHistoryVehicle = (bool = false) => ({
    type: types.LOADING_HISTORY_VEHICLE,
    payload: bool,
});
export const clearHistoryVehicle = () => ({
    type: types.CLEAR_HISTORY_VEHICLE,
});

export const loadVehicleHistoryModal = (id, params) => ({
    type: types.LOAD_VEHICLE_HISTORY_MODAL,
    payload: { id, params }
});
export const clearVehicleHistoryModal = () => ({
    type: types.LOADED_VEHICLE_HISTORY_MODAL,
    payload: []
});
export const loadedVehicleHistoryModal = (data) => ({
    type: types.LOADED_VEHICLE_HISTORY_MODAL,
    payload: data
});
export const loadingVehicleHistoryModal = (bool = false) => ({
    type: types.LOADING_VEHICLE_HISTORY_MODAL,
    payload: bool
});

// todo перевел на vehicleCategories
// export const loadCategoriesVehicle = (data) => ({
//     type: types.LOAD_CATEGORIES_VEHICLE,
//     payload: data,
// });
// export const loadedCategoriesVehicle = (data) => ({
//     type: types.LOADED_CATEGORIES_VEHICLE,
//     payload: data,
// });

export const loadingButton = (bool) => ({
    type: types.LOADING_BUTTON,
    payload: bool,
});

export const loadTelemetryReceivers = (data) => ({
    type: types.LOAD_TELEMETRY_RECEIVERS,
    payload: data
});

export const loadedTelemetryReceivers = (data) => ({
    type: types.LOADED_TELEMETRY_RECEIVERS,
    payload: data
});

export const loadingTelemetryReceivers = (bool) => ({
    type: types.LOADING_TELEMETRY_RECEIVERS,
    payload: bool
});

export const addTelemetryReceiver = (data, callback) => ({
    type: types.ADD_TELEMETRY_RECEIVER,
    payload: data,
    callback,
});

export const editTelemetryReceiver = (id, data, callback) => ({
    type: types.EDIT_TELEMETRY_RECEIVER,
    payload: {
        id,
        data,
    },
    callback,
});

export const deleteTelemetryReceiver = (id, callback) => ({
    type: types.DELETE_TELEMETRY_RECEIVER,
    payload: id,
    callback,
});

export const loadTelemetryReceiverTypes = () => ({
    type: types.LOAD_TELEMETRY_RECEIVER_TYPES,
});

export const loadedTelemetryReceiverTypes = (data) => ({
    type: types.LOADED_TELEMETRY_RECEIVER_TYPES,
    payload: data
});

export const loadVehicleCategoryList = (page, limit, query) => ({
    type: types.LOAD_VEHICLE_CATEGORY_LIST,
    payload: {
        page,
        limit,
        ...query,
    },
});

export const loadedVehicleCategoryList = (data) => ({
    type: types.LOADED_VEHICLE_CATEGORY_LIST,
    payload: data
});

export const loadingVehicleCategoryList = (bool = false) => ({
    type: types.LOADING_VEHICLE_CATEGORY_LIST,
    payload: bool
});

export const loadReportByVehicles = (params) => ({
    type: types.LOAD_REPORT_BY_VEHICLES,
    payload:  params,
});

export const loadedReportByVehicles = (data) => ({
    type: types.LOADED_REPORT_BY_VEHICLES,
    payload:  data,
});

export const clearReportByVehicles = (data) => ({
    type: types.LOADED_REPORT_BY_VEHICLES,
    payload: [],
});

export const loadingReportByVehicles = (bool = false) => ({
    type: types.LOADING_REPORT_BY_VEHICLES,
    payload:  bool,
});

// egts transmitters
export const loadEgtsTransmitters = (filter, fnLoading, fnCallback) => ({
    type: types.LOAD_EGTS_TRANSMITTER,
    payload: filter,
    fnLoading,
    fnCallback,
});

// crud
export const addEgtsTransmitter = (data, fnLoading, fnCallback) => ({
    type: types.ADD_EGTS_TRANSMITTER,
    payload: data,
    fnLoading,
    fnCallback,
});

export const editEgtsTransmitter = (id, data, fnLoading, fnCallback) => ({
    type: types.EDIT_EGTS_TRANSMITTER,
    payload: {
        id,
        data,
    },
    fnLoading,
    fnCallback,
});

export const deleteEgtsTransmitter = (id, fnLoading, fnCallback) => ({
    type: types.DELETE_EGTS_TRANSMITTER,
    payload: id,
    fnLoading,
    fnCallback,
});
