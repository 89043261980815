import { useState } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';

import { loadTypes } from 'redux/TrafficFlow/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import formatDate from 'helpers/constants/dateFormat';
import { dateWithDashYYYYMMDD, getEndOf, getStartOf } from 'helpers/date.config';
import { useStoreFromSelector } from 'helpers/hooks';
import useValidation from 'helpers/hooks/useValidation';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import titles from 'helpers/constants/titles';
import TrafficFlow from 'components/common/Autocomplete/TrafficFlow';
import { PageReportFields, useReportTemplatesFrontendLinks } from 'components/common/PageReport';


const Filter = () => {
    const validation = useValidation();

    const initialState = {
        id_list: [],
        type_id_list: [],
        start_date: getStartOf('month'),
        end_date: getEndOf('month'),
    };

    const templateProvider = useReportTemplatesFrontendLinks();
    const reportFilter = templateProvider.filter;

    const [filters, setFilters] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);

    const types = useStoreFromSelector(loadTypes, trafficFlowSelectors.types);

    const handleChange = (name, value) => {
        setFilters({ ...filters, [name]: value });
        validation.deleteKey(name);
    };

    const download = () => {
        const filter = {
            id_list: filters.id_list?.map(({ id }) => id),
            type_id_list: filters.type_id_list,
            start_date: dateWithDashYYYYMMDD(filters.start_date),
            end_date: dateWithDashYYYYMMDD(filters.end_date)
        };

        const {
            url,
            formats,
        } = reportFilter.getToApi();

        templateProvider.loadReport(
            url,
            formats,
            filter
        );
    };

    const disabledButton = isDisabled
        || !filters.start_date
        || !filters.end_date
        || reportFilter.isDisabled;

    const resetFilter = () => {
        setFilters(initialState);
        reportFilter.setFormats([]);
        validation.clear();
    };

    const updateFilter = (filter) => {
        setFilters({
            ...initialState,
            ...filter
        });
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            buttonType="getReport"
            onSearch={download}
            filter={filters}
            filterException={['start_date', 'end_date']} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
            disabled={disabledButton}
            isFilterVisible={true}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filters.start_date}
                    valueEndDate={filters.end_date}
                    handleDate={(date) => handleChange('start_date', date)}
                    handleEndDate={(date) => handleChange('end_date', date)}
                    isDisabledHandler={setIsDisabled}
                    pickersFormat={formatDate.DATE_FOR_PICKER}
                    renderError={() => (validation.isKey('start_date') || validation.isKey('end_date'))}
                    errorMessage={() => validation.get('start_date') || validation.get('end_date')}
                    dateOnly={true}
                />
                <FormControl size="small" variant="outlined">
                    <InputLabel>Тип ПУИД</InputLabel>
                    <Select
                        value={filters?.type_id_list?.[0] || []}
                        onChange={(e) => {
                            setFilters({
                                ...filters,
                                type_id_list: [parseInt(e.target.value)],
                                id_list: [],
                            });
                        }}
                        label="Тип ПУИД"
                    >
                        <MenuItem value="">{titles.NOT_CHOSEN}</MenuItem>
                        {Object.keys(types).map((index) => (
                            <MenuItem key={index} value={index}>{types[index]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div>
                    <TrafficFlow
                        disabled={!filters?.type_id_list}
                        typeId={filters?.type_id_list || null}
                        multiple
                        selected={filters?.id_list || []}
                        onChange={(values) => {
                            setFilters({
                                ...filters,
                                id_list: values,
                            });
                        }}
                        renderProps={(e) => {
                            const addr = [
                                e?.address?.city_name,
                                e?.address?.street,
                                e?.address?.house,
                            ]
                                .reduce((r, i) => i ? [...r, i] : r, []);
                            return `${e?.name} ${addr.length > 0 ? `(${addr?.join(', ')})` : ''}`;
                        }}
                    />
                </div>
                <PageReportFields
                    reportType={reportFilter.type}
                    reportFormats={reportFilter.formats}
                    onReportType={reportFilter.setType}
                    onReportFormat={reportFilter.setFormats}
                    isOneTypeAuto
                    error={!reportFilter?.formats?.length}
                    required
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
