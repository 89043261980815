import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/Validation/actions';
import { useEffect } from 'react';

// хук валидации
const useValidation = (isClear = true) => {
    const dispatch = useDispatch();
    const validation = useSelector(({ validation }) => validation);

    useEffect(() => {
        isClear && dispatch(actions.clearValidation());
        return () => {
            isClear && dispatch(actions.clearValidation());
        };
    },[]);

    // данные валидации
    const current = (fieldKey, defaultValue = '') => {
        return validation[fieldKey] || defaultValue;
    };

    current.data = validation;
    // валидация не пустая
    current.isValidation = () => {
        return Object.keys(validation).length > 0;
    };

    // получить поле
    current.get = (fieldKey, defaultValue = '') => {
        return validation[fieldKey] || defaultValue;
    };

    //
    current.isKey = (fieldKey) => {
        return !!validation[fieldKey] || false;
    };

    current.isKeys = (fieldsKey) => {
        return fieldsKey?.reduce((res, key) => res || current.isKey(key), false);
    };

    // очистить
    current.clear = () => {
        dispatch(actions.clearValidation());
    };

    // удалить ключ
    current.deleteKey = (keyField) => {
        if (keyField && current.isKey(keyField)) dispatch(actions.deleteValidation(keyField));
    };

    // удалить ключи
    current.deleteKeys = (keysField) => {
        if (keysField && current.isKeys(keysField)) dispatch(actions.deleteValidationArr(keysField));
    };

    // записать валидацию
    // current.set = (data, message) => {
    //     if (data) dispatch(actions.setValidation(data, message));
    // };

    return current;
};

export default useValidation;
