import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';

const Steps = ({ data }) => {
    const stepList = ['Регистрация', 'Ответственный', 'Сценарий', 'Завершено'];

    const activeStep = data?.status_id === 4
        ? 4
        : data?.response_scenario_id
            ? 3
            : data?.organization_id && data?.user_id
                ? 2
                : 1;

    return (
        <Stepper activeStep={activeStep} alternativeLabel>
            {stepList.map((step, index) => (
                <Step key={index} active={false}>
                    <StepLabel>{step}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

export default Steps;