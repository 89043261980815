import React from 'react';
import { Paper, Typography } from '@mui/material';

import styles from './infoBlock.module.scss';

interface InfoBlockProps {
    label?: string;
    mt?: React.CSSProperties['marginTop']
    children: React.ReactNode;
}

// Блок для отрисовки информации с заголовком и border.
function InfoBlock({ label, mt, children }: InfoBlockProps) {
    return (
        <Paper className={styles.paper} style={{ marginTop: mt }} variant="outlined">
            <Typography className={styles.label}>
                {label}
            </Typography>

            <div>{children}</div>
        </Paper>
    );
}

export default InfoBlock;
