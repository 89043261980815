import messages from 'helpers/constants/messages';
import getAddressStringFromObject from 'components/common/Location/LoadAddressByCoords/getAddressStringFromObject';

const Item = (props) => {
    const {
        name,
        type_text,
        lat,
        lon,
        address_text,
        organization,
        address,
    } = props;

    return (
        <>
            <div>
                <strong>Название: </strong>{name || messages.NO_DATA}
            </div>
            <div>
                <strong>Тип: </strong>{type_text || messages.NO_DATA}
            </div>
            {(address_text || Object.keys(address || {}).length > 0) && (
                <div
                    // className="description"
                >
                    <strong>Адрес: </strong>{address_text || getAddressStringFromObject(address || {}) || messages.NO_DATA}
                </div>
            )}
            {(lat || lon) && (
                <div>
                    <strong>Координаты: </strong>{lat || lon ? `${lat} / ${lon}` : messages.NO_DATA}
                </div>
            )}
            <div>
                <strong>Организация: </strong>{organization?.title || messages.NO_DATA}
            </div>
        </>
    );
};

export default Item;
