import { useEffect, useMemo, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Button, Checkbox, Tooltip } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { dorisControlSelectors } from 'redux/DorisControl';
import { loadEventPDDS, saveEventCheckList } from 'redux/DorisControl/actions';
import Image from 'components/common/Image';
import binarySearch from 'helpers/binarySearch';
import useMediaFiles from 'helpers/hooks/useMediaFiles';
import messages from 'helpers/constants/messages';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import ConfirmModal from 'components/common/ConfirmModal';

import styles from './checkListModal.module.scss';
import MediaContent from './MediaContent';
import CheckList from './CheckList';
import WarningsInfo from './WarningsInfo';
import RenderEventInfo from './renderEventInfo';

const CheckListModal = ({
    isOpen,
    onClose,
    item,
    itemIndex,
    violationsRenderList,
    foundEventFunc,
    duploCodes,
    direction,
}) => {
    const dispatch = useDispatch();
    const [elementNumber, setElementNumber] = useState(itemIndex);
    const loading = useSelector(dorisControlSelectors.loadingEventCheckList);
    const loadingPdds = useSelector(dorisControlSelectors.loadingEventPdds);
    const [eventCheckList, setEventCheckList] = useState({});
    const [activeCollage, setaAtiveCollage] = useState(null);
    const [checkList, setCheckList] = useState([]);
    const [eventCollage, setEventCollage] = useState({});
    const [withVideo, setWithVideo] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [check, setCheck] = useState(null);
    const { violation_type: violationType, uid } = eventCheckList;
    const { title } = violationsRenderList[elementNumber];

    const getEventFound = (index) => {
        return foundEventFunc(violationsRenderList[index].violationCode);
    };

    const disableForwardArrow = () => {
        if (elementNumber + 1 === violationsRenderList.length) return true;
        else if (Object.keys(getEventFound(elementNumber + 1)).length === 0) return true;
        return false;
    };

    const disableBackArrow = () => {
        if (elementNumber === 0) return true;
        else if (Object.keys(getEventFound(elementNumber - 1)).length === 0) return true;
        return false;
    };

    // TODO неправильно работает переход между событиями,
    // не нужен переход по индексу, т.к. следующее событи еможет быть пустое с warning
    // надо искать следующий не пустой эвент (у которого есть uid)
    const setEvent = (num) => {
        const res = foundEventFunc(violationsRenderList[num].violationCode);
        setEventCheckList(res);
        setElementNumber(num);
    };

    useEffect(() => {
        const res = foundEventFunc(violationsRenderList[itemIndex].violationCode);
        setEventCheckList(res);
    }, [itemIndex, foundEventFunc, violationsRenderList]);



    const setInformation = (data = {}) => {
        const {
            checkBoxes: check_boxes = [],
            image = null,
            with_video = false,
            check = null
        } = data?.pdds?.[0] || {};

        setEventCollage({ src: image, violation: 'Коллаж', type: 'image' });

        setWithVideo(with_video);
        check && setCheck(check);

        if (check_boxes?.length > 0) {
            const newCheckList = check_boxes.map((el) => {
                const checkedEl = check && check?.answers?.find(el => el.check_id === el.id);
                return {
                    name: el.name,
                    check_id: el.id,
                    check_answer: checkedEl ? checkedEl.check_answer : true,
                    check_comment: checkedEl?.check_comment || '',
                    error_name: checkedEl?.error_name || '',
                    error_id: checkedEl?.error_id || null
                };
            });

            setCheckList(newCheckList);
        }
    };

    useEffect(() => {
        if (!!violationType) {
            dispatch(loadEventPDDS({
                violation_type: violationType,
                camera_id: item.id,
                computer_id: item.computer_id,
                complex_id: Number(item.complex_id),
            }, setInformation));
        }
    }, [dispatch, violationType, item]);

    const allMedia = useMediaFiles(eventCheckList);

    const handleSave = () => {
        const prepareData = {
            // ...(uuid ? { uuid } : {}),
            uid,
            // base_name,
            camera_id: item.id,
            computer_id: item.computer_id,
            complex_id: Number(item.complex_id),
            answers: checkList.map(el => ({
                check_id: el.check_id,
                check_answer: el.check_answer,
                check_comment: el.check_comment,
                ...(el.check_answer ? { error_name: el.error_name, error_id: el.error_id } : {})
            })),
            violation_type: violationType,
        };

        dispatch(saveEventCheckList(prepareData, () => onClose()));

        setOpenConfirmModal(false);
    };

    const saveBtnDisable = checkList.filter(el => (el.check_answer && !el.error_name) && el );

    // duploCodes – отсортированный по id массив, поэтому можем искать там элемент бинарным поиском
    const code = useMemo(() => binarySearch(duploCodes, violationType?.[0]), [duploCodes, violationType]);
    // TODO violationType === duplo code
    const activeTitle = code
        ? `Материалы по коду ДУПЛО ${code?.id} (${code?.gost_code}: ${code?.name})`
        : `Материалы по статье ${title || messages.INFO_IS_NOT_FOUND}`;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={loading ? '' : activeTitle}
            fullWidth
            noPadding
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: () => setOpenConfirmModal(true),
                            disabled: saveBtnDisable.length > 0 || !!check
                        }
                    ]}
                />
            }
        >
            <div style={{ display: 'flex', height: '100%' }} >
                <div style={{ alignSelf: 'center' }}>
                    <Tooltip title={disableBackArrow() ? 'Предыдущего материала нет' : 'Предыдущий материал'} >
                        {/* чтобы tooltip показывался при дизейбле кнопки, обернут в span */}
                        <span>
                            <Button
                                disabled={loadingPdds || disableBackArrow()}
                                onClick={() => setEvent(elementNumber - 1)}
                            >
                                <ArrowBackIosIcon />
                            </Button>
                        </span>
                    </Tooltip>
                </div>


                {loading
                    ? <Loading circular={true}/>
                    : <div className={styles.modalContainer}>
                        <Scrollbars
                            renderView={(props) => <div className={styles.content} style={props?.style} />}
                        >
                            <MediaContent
                                allMedia={allMedia}
                                collage={activeCollage}
                                setaAtiveCollage={setaAtiveCollage}
                            />

                            <div className={styles.info}>
                                <div className="row align-items-center noMargin">
                                    <div className={cn(styles.info__content, styles.collage)}>
                                        {eventCollage.src
                                            ? <div
                                                className="row align-items-center noMargin"
                                                onClick={() => {
                                                    setaAtiveCollage(eventCollage);
                                                }}
                                            >
                                                <Image
                                                    className={styles.media}
                                                    src={eventCollage.src}
                                                    alt={eventCollage.violation}
                                                />
                                                <span>Коллаж</span>
                                            </div>
                                            : <span>Коллаж отсутствует</span>
                                        }
                                    </div>
                                    <div className={styles.info__content}>
                                        <Checkbox
                                            checked={withVideo}
                                            disabled
                                            onClick={(e) => e.preventDefault()}
                                            icon={<i className="fal fa-times-square" color="red"></i>}
                                        />
                                        Наличие видео
                                    </div>
                                </div>
                                <div className="row noMargin" style={{ height: '100%' }}>
                                    {loadingPdds
                                        ? <div style={{ width: '55%' }}><Loading circular={true}/></div>
                                        : <CheckList
                                            checkList={checkList}
                                            setCheckList={setCheckList}
                                            check={check}
                                        />
                                    }

                                    <WarningsInfo
                                        warnings={eventCheckList.warnings}
                                    />
                                </div>
                            </div>
                        </Scrollbars>
                        <div className={styles.footer}>
                            <RenderEventInfo event={{ ...item, ...eventCheckList, direction }} />
                        </div>
                        {openConfirmModal && (
                            <ConfirmModal
                                open={openConfirmModal}
                                onClose={() => setOpenConfirmModal(false)}
                                onSuccess={handleSave}
                                message={'Вы уверены, что все проверки проведены?'}
                            />
                        )}
                    </div>
                }
                <div style={{ alignSelf: 'center' }}>
                    <Tooltip title={disableForwardArrow() ? 'Следующего материала нет' : 'Следующий материал'} >
                        {/* чтобы tooltip показывался при дизейбле кнопки, обернут в span */}
                        <span>
                            <Button
                                disabled={loadingPdds || disableForwardArrow()}
                                onClick={() => setEvent(elementNumber + 1)}
                            >
                                <ArrowForwardIosIcon />
                            </Button>
                        </span>
                    </Tooltip>
                </div>
            </div>
        </Modal>
    );
};

export default CheckListModal;
