import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import * as transportPassengerActions from 'redux/TransportPassenger/actions';
import * as transportRoadActions from 'redux/TransportRoad/actions';
import messages from 'helpers/constants/messages';
import Context from 'helpers/context';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import ReportBlock from 'components/common/Report/ReportBlock';

import Filters from './Filters';
import ModalAddEdit from './ModalAddEdit';
import Item from './Item';

const Employees = ({ storeName }) => {
    const dispatch = useDispatch();

    const actionList = {
        transportPassenger: transportPassengerActions,
        transportRoad: transportRoadActions
    };
    const {
        employees: list,
        employeesLoading: loading
    } = useSelector((store) => store[storeName]);

    const actions = actionList[storeName];
    const { permissions } = useContext(Context);
    const [modalOpen, setModalOpen] = useState(false);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, filter: {} });
    
    const reloadList = (isDelete) => {
        isDelete && list?.data?.length === 1 && list?.meta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(actions.loadEmployees(params.page, limit, params.filter));
    };

    useEffect(() => {
        dispatch(actions.loadEmployees(params.page, limit, params.filter));
    }, [dispatch, limit, params, actions]);

    const getReport = (format) => {
        dispatch(actions.loadEmployees(
            params.page,   
            limit, 
            { ...params.filter, report: format }
        ));
    };

    const renderContent = () => {
        return (
            list?.data?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: 'Фамилия', width: '20%' },
                            { title: 'Имя', width: '20%' },
                            { title: 'Отчество', width: '20%' },
                            { title: '№ Удостоверения', width: '20%' },
                            { title: 'Действия', align: 'right', isActions: true }
                        ]}>

                        {list.data?.map((item) => (
                            <Item
                                key={item?.id}
                                item={item}
                                reloadList={reloadList}
                                actions={actions}
                                loading={loading}
                                permissions={permissions}
                                storeName={storeName}
                            />
                        ))}
                    </LSContainer>
                ) 
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <>
            <PageLayout
                header="Сотрудники"
                filters={
                    <Filters setFilter={(filter) => setParams({ page: 1, filter })} />
                }
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtons
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setModalOpen(true),
                                        disabled: !permissions?.is_create 
                                    },
                                ]}
                                positionLeft
                                noPadding
                            />

                            <ReportBlock onGetReport={getReport} />
                        </>
                    ),
                    total: list?.meta?.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: list?.meta,
                    limit,
                    setLimit
                }}
            />
        
            {modalOpen && (
                <ModalAddEdit
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    isNew
                    reloadList={reloadList}
                    actions={actions}
                    loading={loading}
                    storeName={storeName}
                />
            )}
        </>
    );
};

export default Employees;