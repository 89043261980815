import React, { useMemo } from 'react';
import { ListSubheader } from '@mui/material';
import Visible from './Visible';
import {
    renderChilds,
} from '../helper';

// шапка
const HeaderItem = ({
    children,
    fullWidth = false,
    isMobile = false,
    top = '0', // ноль чтобы при прокрутке заголовок не убегал
}) => {
    const childProps = useMemo(() => {
        const widthOne = `${100/React.Children.count(children)}%`;
        return fullWidth ? { width: widthOne } : {};
    }, [children, fullWidth]);

    const {
        elements,
        actions,
    } = useMemo(() => {
        const items = Array.isArray(children) ? children : [children];

        return items.reduce((r, item) => {

            if (!item?.props?.isActions) {
                r.elements.push(item);
            } else {
                r.actions.push(item);
            }

            return r;
        }, {
            elements: [],
            actions: [],
        });
    }, [children]);

    if (isMobile) return null;

    // во всю ширину
    if (fullWidth) {
        return (
            <Visible show="desktop">
                <ListSubheader style={{ top }}>
                    <div className="row noMargin justify-content-between">
                        {renderChilds(elements, childProps)}
                        {renderChilds(actions, childProps)}
                    </div>
                </ListSubheader>
            </Visible>
        );
    }

    return (
        <Visible show="desktop">
            <ListSubheader style={{ top }} sx={{ borderRadius: '4px' }}>
                <div className="row noMargin justify-content-between">
                    <div className="row row__item noMargin">
                        {renderChilds(elements, childProps)}
                    </div>
                    <div>
                        {renderChilds(actions, childProps)}
                    </div>
                </div>
            </ListSubheader>
        </Visible>
    );
};

export default HeaderItem;