import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/Efficiency/actions';
import { efficiencySelectors } from '../../../redux/Efficiency';


const useGroupGraph = (option) => {
    const dispatch = useDispatch();

    const data = useSelector(efficiencySelectors.groupGraph);
    const loading = useSelector(efficiencySelectors.loadingGroupGraph);

    return {
        load: (props = {}) => {
            dispatch(actions.loadGroupGraph(props));
        },
        clear: (props = {}) => {
            dispatch(actions.clearGroupGraph(props));
        },
        data,
        loading,
    };
};

export default useGroupGraph;
