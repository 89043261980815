import { useState } from 'react';
import { IconButton } from '@mui/material';

import buttons from 'helpers/constants/buttons';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

// модалка
const FieldsModal = ({
    // поля формы
    fields: data = {},
    // изменения в модалке
    onChange, //(objectChanges) => {},
    // заголовок модалки
    title,
    // во всю ширину
    fullWidth,
    // подпись кнопки
    buttonText = buttons.SELECT,
    buttonVariant = 'text',
    buttonType = null, // пример: buttonsType.add
    children,
    iconButton,
    hideButtonAccept = false,
}) => {
    const [fields, setFields] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isChange, setIsChange] = useState(false);

    const handleCloseModal = () => {
        setIsOpen(false);
        setFields({});
    };

    const handleShowModal = () => {
        setFields(data);
        setIsOpen(true);
    };

    const handleAccept = () => {
        onChange && onChange(fields);
        setIsOpen(false);
        setFields({});
    };

    const handleChange = (obj) => {
        setFields((old) => ({
            ...old,
            ...obj,
        }));
        setIsChange(true);
    };

    const buttonsList = () => {
        const buttons = [
            {
                ...buttonsTypes.close,
                onClick: handleCloseModal,
            },
        ];
        if (!hideButtonAccept) {
            buttons.push({
                ...buttonsTypes.apply,
                onClick: () => {
                    handleAccept();
                },
                disabled: !isChange,
                // disabled: validationErrorModal,
            });
        }
        return buttons;
    };

    const components = children?.length ? children : [children];

    return (
        <>
            {/* кнопка */}
            {iconButton ? (
                <IconButton
                    size="small"
                    onClick={handleShowModal}
                >
                    {iconButton}
                </IconButton>
            ) : (
                <FormButtons
                    className="form__button"
                    justButton
                    buttons={[
                        {
                            ...(buttonType && { ...buttonType }),
                            onClick: handleShowModal,
                            variant: buttonVariant,
                            color: 'infoButton',
                            name: buttonText // true ? buttons.EDIT_ON_MAP : buttons.SELECT_ON_MAP
                        }
                    ]}
                />
            )}

            {/* модалка */}
            {isOpen && (
                <Modal
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                    // noPadding
                    title={title || ''}
                    fullWidth={fullWidth}
                    buttons={
                        <FormButtons
                            buttons={buttonsList()}
                        />
                    }
                >
                    {components
                        ?.map((child, index) => child?.type ? (
                            <child.type
                                key={index}
                                {...child.props}
                                {...fields}
                                onChange={handleChange}
                            />
                        ) : child)
                    }
                </Modal>
            )}
        </>
    );
};

export default FieldsModal;
