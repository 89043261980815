import CardWidget from './CardWidget';
import ComponentByType from './ComponentByType';

const Item = ({
    type,
    name,
    data,
    max = 50,
    pagelink,
    pageFilters,
    layer = '',
    handleMoreInfo,
    title = 'Подробнее',
    icon,
    editMode,
    onDelete = () => {},
    displayLabels = true,
    onEdit,
    id,
}) => {
    return (
        <CardWidget
            title={name}
            {...(pagelink ? { onClick: () => handleMoreInfo(pagelink, pageFilters, layer) }
                : {} //{ onClick: () => history.push('/reports/efficiency/reports') }
            )}
            icon={icon}
            // buttons={pagelink
            //     ? [{
            //         name: title,
            //         onClick: () => handleMoreInfo(pagelink, pageFilters),
            //     }]
            //     : []
            // }
            editMode={editMode}
            onDelete={onDelete}
            onEdit={() => onEdit(id)}
            // onEdit={() => setIsEdit(true)}
        >
            <ComponentByType
                type={type}
                data={data}
                max={max}
                displayLabels={displayLabels}
            />
        </CardWidget>
    );
};

export default Item;
