import React, { useState } from 'react';
import { ListItemIcon, Avatar } from '@mui/material';

import messages from 'helpers/constants/messages';
import { useDispatch } from 'react-redux';
import { checkBase64 } from 'helpers/transport.types';
import ConfirmDelete from 'components/common/ConfirmModal';
import { LSContentItem, LSContentColumn, ActionsButtons } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';
import Image from 'components/common/Image';

import ModalForm from './ModalForm';

const Item = ({ item, reloadList, editVehicleCategory, deleteVehicleCategory, fromService }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleDelete = () => {
        dispatch(deleteVehicleCategory(item.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };
    const imgUrl = fromService === 'transportSpecial'
        ? checkBase64(item?.image)
        : checkBase64(item?.image_with_work_order);

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    <ListItemIcon>
                        <Avatar sx={{ background: item?.color }}>
                            <Image
                                style={{ width: 30, height: 30, margin: 0 }}
                                src={imgUrl}
                                alt=""
                            />
                        </Avatar>
                    </ListItemIcon>
                </LSContentColumn>

                <LSContentColumn>
                    <div>{item.name}</div>
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            },
                        ]}
                    />
                </LSContentColumn>

            </LSContentItem>

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                    editVehicleCategory={editVehicleCategory}
                    fromService={fromService}
                />
            )}

            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    message={messages.CONFIRM_DELETE}
                    onClose={() => setOpenDeleteModal(false)}
                    onSuccess={handleDelete}
                />
            )}
        </>
    );
};

export default Item;
