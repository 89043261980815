import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { menuSelectors } from 'redux/Menu';
import { transportSpecialSelectors } from 'redux/TransportSpecial';
import * as actions from 'redux/TransportSpecial/actions';
import messages from 'helpers/constants/messages';
import { fullDateTimeWithoutTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import useStoreProp from 'helpers/hooks/useStoreProp';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import ReportBlock from 'components/common/Report/ReportBlock';
import VehicleListHeader from 'components/common/Transport/VehicleReportList/VehicleListHeader';
import PageLayout from 'components/layout/PageLayout';

import Filter from './Filter';
import Item from './Item';

const Vehicles = () => {
    const dispatch = useDispatch();

    const categories = useStoreProp(
        actions.loadVehicleCategories,
        'transportSpecial',
        'vehicleCategories'
    );

    const vehiclesData = useSelector(transportSpecialSelectors.vehicleFilteredListData);
    const vehiclesMeta = useSelector(transportSpecialSelectors.vehicleFilteredListMeta);
    const loadingVehicles = useSelector(transportSpecialSelectors.loadingVehicleFilteredList);

    const initialStateFilter = {
        category_id: '',
        organization_id: [],
        date_start: getStartOf('month'),
        date_end: getEndOf('month'),
        grz: []
    };

    const [filter, setFilter] = useState(initialStateFilter);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const paramsWithoutEmpty = (params) => Object
        .entries(params)
        .reduce((res, [key, value]) => {
            if (value) {
                if (key === 'date_start' || key === 'date_end') {
                    res[key] = fullDateTimeWithoutTimeZone(value);
                } else if (key === 'grz' && value.length > 0) {
                    // item - object в фильтре и string в модалке
                    res[key] = value.map((item) => item?.garage_number || item);
                } else if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value))) {
                    res[key] = value;
                }
            }
            return res;
        }, {});

    const getReport = useCallback((params = filter) => {
        const filtersNoEmpty = paramsWithoutEmpty(params);

        if (filtersNoEmpty?.organization_id?.length > 0) {
            filtersNoEmpty.organization_id = filtersNoEmpty.organization_id.map(item => item.id);
        }

        dispatch(actions.getFilteredTSList(page, limit, filtersNoEmpty));
    }, [filter, page, limit, dispatch]);

    useEffect(() => {
        onSearch(filter);
    }, []);

    const resetFilter = (needRefresh) => {
        if (needRefresh) {
            onSearch(initialStateFilter);
        }
        setFilter(initialStateFilter);
    };

    const renderContent = () => (<>
        {vehiclesData?.length > 0
            ? (<VehicleListHeader>
                {vehiclesData.map((item, index) =>
                    <Item
                        item={item}
                        key={index}
                        filter={filter}
                        categories={categories?.data || []}
                    />
                )}
            </VehicleListHeader>)
            : (!loadingVehicles && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        }
    </>);

    const handleGetReport = (formats) => {
        getReport({ ...filter, report: 1, formats });
    };

    const onSearch = (filters = filter) => {
        const filtersWihtoutEmpty = paramsWithoutEmpty(filters);
        dispatch(actions.getFilteredTSList(page, limit, filtersWihtoutEmpty));
    };

    return (
        <PageLayout
            header="Отчет по транспортным средствам"
            filters={<Filter
                categories={categories || []}
                filter={filter}
                setFilter={setFilter}
                resetFilter={resetFilter}
                onSearch={onSearch}
                initialStateFilter={initialStateFilter}
            />}
            informPanelProps={{
                buttons: <ReportBlock onGetReport={handleGetReport} reportId={24} />,
                total: vehiclesMeta?.total
            }}
            paginationProps={{
                loadList: (page) => setPage(page),
                list: vehiclesMeta,
                limit: limit,
                setLimit: setLimit,
            }}
            content={renderContent}
            additionalButtons={<ReturnToButton />}
            loading={loadingVehicles}
        />
    );
};

export default Vehicles;
