import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearDadataAddressList, loadDadataAddressList } from 'redux/Dadata/actions';
import { IconButton, InputAdornment, LinearProgress, TextField } from '@mui/material';
import Clear from '@mui/icons-material/Clear';

import { useDebounce, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

import AddressList from './AddressList';

const AddressField = ({ value, onChange, test_id_prefix }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const [query, setQuery] = useState(
        `${value.city_name || value.settlement || value.region || ''} ${value.street || ''} ${value.house || ''}`
    );

    const { addressList, loading, } = useSelector(({ dadata }) => dadata);

    const fetchList = (query) => dispatch(loadDadataAddressList(10, query));
    const fetchListDebounce = useDebounce(fetchList, 800);
    const clearList = () => dispatch(clearDadataAddressList());

    const handleChange = (e) => {
        const { value } = e.target;
        setQuery(value);

        if (value.length > 3) {
            fetchListDebounce(query);
        } else {
            fetchListDebounce.clear();
        }

        clearList();
    };

    const resetSearch = () => {
        setQuery('');
        fetchListDebounce.clear();
        clearList();
        onChange({});
    };

    const adornment = () => {
        const adornment = {};

        if (query.length > 0) {
            adornment.endAdornment = (
                <InputAdornment position="end">
                    <IconButton
                        onClick={resetSearch}
                        size="small"
                        title={titles.CLEAR_FIELD}
                    >
                        <Clear />
                    </IconButton>
                </InputAdornment>
            );
        }

        return adornment;
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') fetchList(query);
    };

    return (
        <div style={{ position: 'relative' }}>
            <FormInfoWrapper
                error={validation.isKey('address')}
                helperText={validation.get('address')}
            >
                <TextField
                    value={query}
                    fullWidth
                    required
                    onChange={handleChange}
                    label={'Адрес'}
                    variant="outlined"
                    size="small"
                    InputProps={adornment()}
                    inputProps={{ onKeyDown: handleEnter, 'data-testid': `${test_id_prefix}/address/input` }}
                    error={validation.isKey('address')}
                    InputLabelProps={{ 'data-testid': `${test_id_prefix}/address/label` }}
                />
            </FormInfoWrapper>


            {loading && <div className="loading"><LinearProgress /></div>}

            {addressList?.data?.length > 0 && (
                <AddressList
                    list={addressList.data}
                    onClick={(item, address) => {
                        setQuery(address);
                        onChange(item);
                        clearList();
                    }}
                />
            )}
        </div>
    );
};

export default AddressField;
