import iconCluster from './icons/iconCluster';

const config = {
    name: 'Видеоаналитика',
    slug: 'videoanalytics',
    mapContextMenu: false,
    mapMarkersColors: { // от статуса маркера
        // новые цвета
        // 1: '#13153b', // Включена
        // 2: '#daa755', // Имеются неполадки
        // 3: '#000000', // Выключена
        // 4: '#3b1338', // "В Сервисном обслуживании",
        // 5: '#ad1818', // "Нет данных"
        // 6: '#00ae5c', // "Запущена аналитика"
        // default: '#31bebe'
    },
    clusterIcon: iconCluster,
    layerIcon: 'fas fa-cctv',
};
// добавление на карте
export default config;
