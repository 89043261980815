// удалить пустые данные из объекта
const removeEmptyFields = (obj, returnNullValue = true, acceptFalse = false) => // checkKeyIfZero = ''
    Object.entries(obj).reduce((res, [key, value]) => {
        if (value || value === 0) {
            if (Array.isArray(value)) { // if (value || (value === 0 && key === checkKeyIfZero))
                if (value?.length > 0) {
                    res[key] = value;
                }
                return res;
            } else if (typeof value === 'object') {
                if (Object.keys(value).length > 0 || (value instanceof Date && !isNaN(value))) {
                    res[key] = value;
                }
                return res;
            }
            res[key] = value;
        } else if (returnNullValue && value === null) { // когда нам нужно удалить элемент при редактировании, мы ему присываиваем null
            res[key] = value;
        } else if (acceptFalse && value === false) { // когда нам нужно сохранить значение флага false
            res[key] = value;
        }
        return res;
    }, {});

export default removeEmptyFields;
