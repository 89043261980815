import iconCluster from './icons/iconCluster';

const config = {
    name: 'Объекты мероприятий',
    slug: 'event-object',
    mapContextMenu: {
        title: 'Добавить объект мероприятия',
        event: 'add_event_object',
        // className: styles.button,
        minWidth: 240,
    },
    mapMarkersColors: { // от статуса маркера
        default: '#d00909',
        1: '#14e5d8',// #d4a323
        2: '#066980',// #d4a323
        3: '#1936a0',
        4: '#7141b1',
        5: '#88239f',
        6: '#37dd80',
        7: '#b7a90f',
        8: '#674848',
    },
    clusterIcon: iconCluster,
    layerIcon: 'far fa-calendar-star',
};

// добавление на карте
export default config;
