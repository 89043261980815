import { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import {
    Pagination,
    Select,
    MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';

import titles from 'helpers/constants/titles';

const useStyles = makeStyles({
    wrap: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#e4edef',
        padding: '0.5rem 1rem',
        borderRadius: 4,
        margin: '8px 0', // 8px 0 35px 0
        fontSize: '0.8rem'
    },
    countBlock: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& .MuiPaginationItem-root': {
            '&.Mui-selected': {
                backgroundColor: '#6d777d',
                color: '#ffffff',
            },
            fontSize: '0.8rem'
        },
    },
    selectComponent: {
        marginLeft: 10,
        fontSize: '0.8rem'
    },
});

const selectStyle = {
    select: {
        padding: 5,
    }
};

const CustomSelect = withStyles(selectStyle)(Select);

const CustomPagination = ({
    list,
    loadList,
    limit = 25,
    setLimit,
    isWrap,
    isUniversal,
}) => {
    const classes = useStyles();
    const last_page = (list && list.last_page) || 0;
    const [page, setPage] = useState(null);
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [currentLimit, setCurrentLimit] = useState(storageLimit);

    const wrapStyle = isWrap
        ? { justifyContent: 'flex-start' }
        : {};

    const handleChange = (_, value) => {
        loadList(value, currentLimit);
    };

    const handleChangeLimit = (value) => {
        localStorage.setItem('limit', value.toString());
        setCurrentLimit(Number(value));
        setLimit?.(Number(value));
        loadList(1, Number(value));
    };
    useEffect(() => {
        return () => {
            setPage(null);
        };
    }, []);

    useEffect(() => {
        if (list.current_page && !page) {
            setPage(list.current_page);
        }
    }, [list.current_page, page]);

    return (
        <>
            {last_page > 0 && (
                <div className={classes.wrap}>
                    <span>{titles.TOTAL}: {list.total}</span>
                    <div className={classes.countBlock} style={wrapStyle}>
                        {!isUniversal
                            && <div className={classes.countBlock} style={wrapStyle}>
                                <span>
                                    На странице:
                                </span>
                                <CustomSelect
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={currentLimit}
                                    onChange={(e) => handleChangeLimit(e.target.value)}
                                    className={classes.selectComponent}
                                    size="small"
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </CustomSelect>
                            </div>
                        }
                        {last_page > 1 && list.current_page && (
                            <Pagination
                                variant="outlined"
                                shape="rounded"
                                page={list.current_page}
                                count={last_page}
                                onChange={handleChange}
                                className={classes.selectComponent}
                                size="small"
                            />)
                        }
                    </div>

                </div>
            )}
        </>
    );
};

export default CustomPagination;

CustomPagination.propTypes = {
    loadList: PropTypes.func,
    list: PropTypes.object,
    limit: PropTypes.number,
    setLimit: PropTypes.func,
    isUniversal: PropTypes.bool,
    isWrap: PropTypes.bool,
};

CustomPagination.defaultProps = {
    loadList: () => {},
    list: {},
    isUniversal: false,
    isWrap: false,
};
