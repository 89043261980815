import Page from './Page';
import { HeaderPageVisible } from '../../../common/Showcase/HeaderPage';
import BoxContainer from '../../../common/Showcase/BoxContainer';
import HeaderPanel from '../../../common/Showcase/HeaderPanel';

// страница "Дорожные работы"
const RoadWorks = () => (
    <BoxContainer>

        {/* шапка */}
        <HeaderPageVisible/>

        <HeaderPanel title="Дорожные работы" />

        {/* содержимое страницы */}
        <Page />

    </BoxContainer>
);

export default RoadWorks;