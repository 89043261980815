import { memo } from 'react';
import { FormHelperText } from '@mui/material';
import SingleKeyboardDateTimePicker from '../../../../../../common/Dates/SingleKeyboardDateTimePicker';
import formatDate from '../../../../../../../helpers/constants/dateFormat';

export const StartTime = memo((props) => {
    const { value, onChange, validation, isDisabledHandler } = props;

    return (
        <div className="block">
            <SingleKeyboardDateTimePicker
                label={'Время начала показа'}
                onChange={onChange}
                value={value}
                pickerFormat={formatDate.DT_EXCEPT_SECONDS}
                required
                isDisabledHandler={isDisabledHandler}
                error={!value || validation.isKey('start_at')}
            />
            {validation.isKey('start_at') && <FormHelperText error>{validation.get('start_at')}</FormHelperText>}
        </div>
    );
});