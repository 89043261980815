import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from './Filter';
import * as tsActions from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import Item from './Item';
import messages from '../../../../../helpers/constants/messages';
import useStoreProp from '../../../../../helpers/hooks/useStoreProp';
import ReturnToButton from '../../../../common/Buttons/ReturnToButton';
import VehicleListHeader from '../../../../common/Transport/VehicleReportList/VehicleListHeader';
import PageLayout from '../../../../layout/PageLayout';
import ReportBlock from '../../../../common/Report/ReportBlock';
import { getEndOf, getStartOf, fullDateTimeWithTimeZone } from '../../../../../helpers/date.config';

const Vehicles = () => {
    const dispatch = useDispatch();
    const initialStateFilter = {
        category_id: '',
        organization_id: [],
        start_at: getStartOf('month'),
        end_at: getEndOf('month'),
        grz: []
    };
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;

    const [limit, setLimit] = useState(storageLimit);

    const [params, setParams] = useState({
        page: 1,
        data: initialStateFilter
    });
    
    const vehiclesData = useSelector(transportPassengerSelectors.vehicleFilteredListData);
    const vehiclesMeta = useSelector(transportPassengerSelectors.vehicleFilteredListMeta);
    const vehicleLoading = useSelector(transportPassengerSelectors.loadingVehicleFilteredList);

    const paramsWithoutEmpty = useCallback((param) => Object
        .entries(param)
        .reduce((res, [key, value]) => {
            if (value) {
                if (key === 'start_at' || key === 'end_at') {
                    res[key] = fullDateTimeWithTimeZone(value);
                } else if (key === 'grz' && value.length > 0) {
                    // item - object в фильтре и string в модалке
                    res[key] = value.map((item) => item?.garage_number || item);
                } else if (key === 'organization_id' &&  value.length > 0) {
                    res[key] = value.map(item => item.id);
                } else if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value))) {
                    res[key] = value;
                }
            }
            return res;
        }, {}), []);

    const onSearch = (filter = params) => {
        console.log(filter);
        // setParams((prev) => ({ ...prev, page: 1, data: filter }));
        const filters = paramsWithoutEmpty(filter?.data);
        dispatch(tsActions.getFilteredTSList({ page: filter.page || 1, limit, ...filters }));      
    };

    useEffect(() => {
        onSearch();
    }, [params, limit]);

    const categories = useStoreProp(
        tsActions.loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    ); 

    const getReport = (data) => {
        const filter = paramsWithoutEmpty(data);
        dispatch(tsActions.getFilteredTSList(filter));
    };

    const renderContent = () => <>
        {vehiclesData?.length > 0
            ? (<VehicleListHeader>
                {vehiclesData.map((item, index) =>
                    <Item
                        item={item}
                        key={index}
                        filter={params.data}
                        categories={categories}
                        getReport={getReport}
                    />
                )}
            </VehicleListHeader>)
            : (!vehicleLoading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        }
    </>;

    const handleGetReport = (formats) => {
        getReport({ ...params.data, report: 1, formats });
    };

    return (
        <PageLayout
            header="Отчет по транспортным средствам"
            filters={<Filter
                categories={categories}
                setParams={setParams}
                initialStateFilter={initialStateFilter}
            />}
            informPanelProps={{
                buttons: <ReportBlock onGetReport={handleGetReport} reportId={24} />,
                total: vehiclesMeta?.total
            }}
            paginationProps={{
                loadList: (page) => setParams({
                    data: params.data,
                    page,
                }),
                list: vehiclesMeta,
                limit,
                setLimit,
            }}
            content={renderContent} 
            additionalButtons={<ReturnToButton />} 
            loading={vehicleLoading}
        />
    );
};

export default Vehicles;
