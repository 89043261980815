import React, { useEffect, useRef, useState } from 'react';
import SidebarItem from '../../../SidebarItem';
import { Scrollbars } from 'react-custom-scrollbars';
import * as actions from '../../../../../redux/Aip/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../../common/Loading';
import Item from './Item';
import messages from '../../../../../helpers/constants/messages';
import SidebarTitle from '../../../SidebarTitle';
import { usePrevious } from '../../../../../helpers/hooks';
import getFilters from '../../../helpers/getFilters';
import { isEqual } from 'lodash';
import SidebarFilter from '../../../SidebarFilter';
import Filter from './Filter';
import icon from '../icons/icon';
import { getColorByStatus } from '../helper';
import config from '../config';


const SideBar = (props) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const {
        sidebar: list,
        sidebarLoading: loading,
        active,
        filters,
    } = useSelector(({ aip }) => aip);

    const scrollRef = useRef(null);
    const prevFilters = usePrevious(filters);

    const scroolTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    const activeId = Object.keys(active).length > 0 ? active.id : 0;

    const fetchList = (page = 1) => {
        const filter = getFilters(filters);
        dispatch(actions.loadSidebar(
            page,
            limit,
            filter
        ));
        setPage(page);
    };

    const handleScrollUpdate = (values) => {
        const {
            last_page = 0
        } = list.meta;
        if (
            values.top > 0.90
            && loading === false
            && page < last_page
        ) {
            fetchList(page + 1);
        }
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            scroolTop();
            fetchList(1);
        }
    }, [filters]);
    
    useEffect(() => {
        fetchList(1);
        return () => {
            dispatch(actions.clearSidebar());
        };
    }, []);

    const renderList = (dataList) => (
        <>
            {dataList.map((item) => {
                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        icon={
                            icon({
                                fill: getColorByStatus(item.status)
                            })
                        }
                    >
                        <Item
                            item={item}
                            onClick={() => {
                                dispatch(actions.setActive(item));
                            }}
                        />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={() => setPage(1)}
                onSetFilter={(filter) => dispatch(actions.setFilter(filter))}
                onClearFilter={() => dispatch(actions.clearFilters())}
                content={({ data, onChange }) => <Filter data={data}  onChange={onChange} />}
                layer={config.slug}
            />
            
            {loading && list.data.length === 0 
                ? <Loading className="absolute bottom fill" />
                : <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={'Справочник АИП'}
                            list={list}
                        />
                        {list.data.length === 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList(list.data)}
                    </div>
                    {loading && <Loading className="center" />}
                </Scrollbars>
            }
        </div>
    );
};

export default SideBar;
