import L from 'leaflet';
import { render } from 'react-dom';
import StoreProvider from '../StoreProvider';
// import refs from '../refs';
import handleCloseContextMenu from './handleCloseContextMenu';


const handleShowContextMenu = (layerProps, layer, data, newId, layerRef, contextMenuRef) => (e) => {
    const {
        map,
        contextMenuTemplate,
    } = layerProps;

    L.DomEvent.stop(e);

    map.fire('context_menu_close');
    layer.fire('context_menu_open');

    setTimeout(() => {
        if (newId) {
            // закрываем все меню
            const popup = L.popup({
                closeButton: true,
                minWidth: '200px',
                className: 'geo_json_menu'
            })
                .setLatLng(e.latlng)
                .setContent(`<div class="contextmenu" id="${newId}"></div>`)
                .addTo(map)
                .openOn(map);
                // .addTo(layerRef.current)
                // .openOn(layerRef.current);
            contextMenuRef.current = popup;
            // refs.set('popup', popup);

            render(
                <StoreProvider>
                    {contextMenuTemplate(
                        data,
                        {
                            e,
                            onCloseMenu: handleCloseContextMenu(newId, contextMenuRef)
                        }
                    )}
                </StoreProvider>,
                document.getElementById(newId)
            );
        }
    }, 200);
};

export default handleShowContextMenu;