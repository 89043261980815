import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress, } from '@mui/material';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import * as actions from 'redux/TransportPassenger/actions';
import titles from 'helpers/constants/titles';
import {
    compareDatesByParams,
    dateNow,
    fullDateTimeWithTimeZone,
    getDateWithDuration,
    setDateTime,
} from 'helpers/date.config';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import DateRange from 'components/common/Dates/DateRange';
import types from 'components/common/Transport/TransportHistory/types';

const FULL_DAY = 86400000; // сутки в милисекундах
const SelectDateHistory = ({
    title,
    uuid,
    actions: {
        storeProp,
        history: {
            action,
            clear,
            loadingProp,
            dataProp,
        },
        speedList
        //     : {
        //     // action,
        //     // loadingProp,
        //     // dataProp,
        // }
    },
    isIconButton,
    needButton = true,
    isOpen,
    onClose,
    addButtons = [],
    test_id_prefix = '',
}) => {
    const dispatch = useDispatch();

    const currentUse = useRef(false);
    const [showHistoryRange, setShowHistoryRange] = useState(false);

    const current = useMemo(() => setDateTime(dateNow(), { seconds: '00' }), []);

    const [end, setEnd] = useState(current);
    const [start, setStart] = useState(getDateWithDuration({ day: -1 }, current));

    const store = useSelector((store) => store[storeProp]);

    const data = store[dataProp];
    const loading = store[loadingProp];

    // useEffect(() => {
    //     dispatch(actions.loadColorSpeedList());
    // }, []);

    useEffect(() => {
        return () => {
            clear && dispatch(clear());
        };
    }, []);

    const acceptHistory = () => {
        currentUse.current = true;
        dispatch(action(uuid?.toString()?.trim(), {
            date_start: start ? fullDateTimeWithTimeZone(start) : '',
            date_end: end ? fullDateTimeWithTimeZone(end) : '',
        }));
    };

    const handleClose = () => {
        onClose
            ? onClose()
            : setShowHistoryRange(false);
    };

    useEffect(() => {
        if (
            currentUse.current
            && data?.length > 0
            && loading === false
        ) {
            const setHistoryOption = () => {
                dispatch(actions.setHistoryOption(`История ТС (${title})`, [
                    {
                        type: types.historyColor,
                        data: data,
                        moveToAfterInit: true
                    },
                    {
                        type: types.legends,
                        data: {
                            ...speedList,
                            storeProp,
                        },
                    },
                ],
                false
                ));
            };
            setHistoryOption();
            setShowHistoryRange(false);
            currentUse.current = false;
        }
    }, [dispatch, data, loading, currentUse, speedList, storeProp, title]);

    const dataError = () => {
        // старая валидация
        // const compare = compareDatesByParams(end, start);
        // return compare.milliseconds > FULL_DAY;
        return end <= start;
    };

    const dtPickerError = () => {
        const compare = compareDatesByParams(end, start);
        return compare.milliseconds > FULL_DAY;
    };

    // дата начала
    const handleDate = (newValue) => {
        setStart(newValue);
        const compare = compareDatesByParams(end, newValue);
        // если start больше или равно end , либо разница больше суток
        if (Math.abs(compare.milliseconds) > FULL_DAY) {
            setEnd(getDateWithDuration({ day: 1 }, newValue));
        }
    };

    // дата конца
    const handleEndDate = (newValue) => {
        setEnd(newValue);
        const compare = compareDatesByParams(newValue, start);
        // если start больше или равно end , либо разница больше 1 дня
        if (Math.abs(compare.milliseconds) > FULL_DAY) {
            setStart(getDateWithDuration({ day: -1 }, newValue));
        }
    };

    return (
        <>
            {needButton
                && (isIconButton
                    ? (
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.historyIcon,
                                    name: 'История перемещений',
                                    onClick: () => setShowHistoryRange(true),
                                },
                            ]}
                            noMarginLeft
                            justButton
                        />
                    )
                    : (
                        <FormButtons
                            buttons={[
                                ...addButtons,
                                {
                                    ...buttonsTypes.apply,
                                    name: 'История перемещений',
                                    onClick: () => { setShowHistoryRange(true); },
                                },
                            ]}
                        />
                    )
                )
            }

            {/* выбор даты и времени */}
            {(showHistoryRange || isOpen) && (
                <Modal
                    // noPadding
                    title={!!uuid ? titles.SELECT_PERIOD : 'Нет истории перемещений для данного ТС'}
                    maxWidthProp={'md'}
                    // small
                    isOpen={(showHistoryRange || isOpen)}
                    onClose={handleClose}
                    buttons={
                        <FormButtons
                            buttons={!!uuid
                                ? [
                                    {
                                        ...buttonsTypes.apply,
                                        onClick: acceptHistory,
                                        disabled: dataError(),
                                        name: 'Показать',
                                        testName: 'apply',
                                    },
                                    {
                                        ...buttonsTypes.close,
                                        onClick: handleClose,
                                        testName: 'close',
                                    },
                                ]
                                : [
                                    {
                                        ...buttonsTypes.close,
                                        onClick: handleClose,
                                        testName: 'close',
                                    },
                                ]
                            }
                            test_id_prefix={test_id_prefix}
                        />
                    }
                >
                    {!!uuid && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <LocalizationProvider dateAdapter={AdapterLuxon} locale={'ru'}>
                                    <DateRange
                                        valueStartDate={start}
                                        valueEndDate={end}
                                        handleDate={handleDate}
                                        handleEndDate={handleEndDate}
                                        startLabel={titles.START_PERIOD}
                                        endLabel={titles.END_PERIOD}
                                        renderError={dtPickerError}
                                        errorMessage={() => 'Выбранный период более 1 дня'}
                                        test_id_prefix={test_id_prefix}
                                    />
                                </LocalizationProvider>
                            </div>
                            {loading && <LinearProgress style={{ marginTop: '.5rem' }}/>}
                        </>
                    )}
                </Modal>
            )}
        </>
    );
};

export default SelectDateHistory;
