import { useState } from 'react';
import { useDispatch } from 'react-redux';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { deleteMaterialExportGroup } from 'redux/DorisControl/actions';
import ConfirmModal from 'components/common/ConfirmModal';
import { LSContentColumn, LSContentItem } from 'components/common/ListStyle';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';

const Item = ({ item, loadList, types, protocols }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return (
        <>
            <LSContentItem fullWidth>
                <LSContentColumn width="15%" title={titles.NAME}>
                    {item.name || messages.NO_DATA}
                </LSContentColumn>
                {/* <LSContentColumn width="20%" title="Назначение">
                    {item.destination || messages.NO_DATA}
                </LSContentColumn> */}
                <LSContentColumn width="20%" title="Тип экспорта">
                    {item.types.map((id, i, arr) => `${types[id] || id}${arr.length - 1 === i ? '' : ', '}`)}
                </LSContentColumn>
                <LSContentColumn width="15%" title="Протокол">
                    {protocols[item.protocol] || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn width="30%" title="Комплексы">
                    <SelectComplex
                        multiple
                        sortedList={item.complexes}
                        onChange={(v) => {}}
                        selected={item.complexes}
                        canReset={false}
                        withSearch={false}
                        readOnly
                    />
                    {/* {item.complexes.map(({ serial_number }, i, arr) => `${serial_number}${arr.length - 1 === i ? '' : ', '}`)} */}
                </LSContentColumn>
                <LSContentColumn width="20%" title="Выгрузка">
                    {item.enable ? 'Включена' : 'Выключена' }
                </LSContentColumn>
                <LSContentColumn align="right" isActions>
                    <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            },
                        ]}
                        noMarginLeft
                        justButton
                    />
                </LSContentColumn>
            </LSContentItem>

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    loadList={loadList}
                    item={item}
                />
            )}
            {openDeleteModal && (
                <ConfirmModal
                    open={openDeleteModal}
                    onSuccess={() => dispatch(deleteMaterialExportGroup(item.id, () => loadList(true)))}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
