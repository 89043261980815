import React, { useMemo, useState } from 'react';
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { DragHandle, ExpandLess, ExpandMore } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { getTimeWithoutSecond } from 'helpers/date.config';
import { useValidation } from 'helpers/hooks';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import EditFlightType from './EditFlightType';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    warning: {
        display: 'block',
        fontSize: 12,
        color: '#ab0909'
    }
});

const EventItem = ({
    row,
    handleDelete,
    hideIcons,
    hideChangeTypeIcon,
    handleAdd,
    lastElement,
    handleEdit,
    indexElement
}) => {

    const validation = useValidation();

    const [open, setOpen] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [openEditFlightType, setOpenEditFlightType] = useState(false);
    const classes = useRowStyles();

    const showStations = () => {
        setOpen(!open);
    };

    const {
        setNodeRef,
        attributes,
        listeners,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: row.number,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        backgroundColor: row?.is_new ? '#87ff9b' : '',
    };

    const handleEditFlightType = (e) => {
        e && e.stopPropagation();
        setOpenEditFlightType(!openEditFlightType);
    };
    const handleDeleteItem = () => {
        handleDelete(indexElement);
        setIsOpenDeleteModal(false);
    };

    const validationMessages = useMemo(() => {
        const keys = Object.keys(row || {}).map(el => `entity_list.${indexElement}.${el}`);

        return keys.reduce((acc, curr) => {
            if (validation.isKey(curr)) {
                return [...acc, validation.get(curr)];
            }
            return acc;
        }, []);
    }, [indexElement, row, validation]);

    return (
        <>
            <TableRow
                ref={setNodeRef}
                style={style}
                onClick={row?.entity_type === 'App\\Models\\Flight'
                    ? showStations
                    : null}
            >
                <TableCell align="center">
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {!hideIcons && <DragHandle style={{ cursor: isDragging ? 'grabbing' : 'grab', outline: 'none' }} {...attributes} {...listeners}/>}
                        {row?.number}
                    </div>
                </TableCell>
                <TableCell style={{ cursor: row?.entity_type === 'App\\Models\\Flight' ? 'pointer' : 'auto' }}>
                    <span>
                        {(row.event_text && row?.name) ? `${row.event_text}: ${row?.name}` : (row?.name || row.event_text)}
                        {row?.entity_type === 'App\\Models\\Flight' && (
                            <IconButton aria-label="expand row" size="small" onClick={showStations}>
                                {open
                                    ? <ExpandLess fontSize="inherit"/>
                                    : <ExpandMore fontSize="inherit"/>
                                }
                            </IconButton>
                        )}
                    </span>

                    {validationMessages.length > 0 && validationMessages.map((item, i) => (
                        <span className={classes.warning} key={item}>
                            <span>{i + 1}. </span>
                            <span>{item}</span>
                        </span>
                    ))}
                </TableCell>
                <TableCell align="center">{row?.entity_type === 'App\\Models\\Flight' ? row?.is_direct ? 'прямой' : 'обратный' : ''}</TableCell>
                {/* меняем тип рейса */}
                <TableCell align="center">
                    {row.flight_type_text && (
                        <div>
                            {row.flight_type_text}
                            <FormButtonsComponent
                                noMarginLeft
                                justButton
                                buttons={[
                                    ...(!hideChangeTypeIcon
                                        ? [{
                                            ...buttonsTypes.editIcon,
                                            style: { fontSize: '0.9rem' },
                                            onClick: handleEditFlightType,
                                        }]
                                        : []
                                    ),
                                ]}
                            />
                        </div>
                    )}
                </TableCell>
                <TableCell align="center">{getTimeWithoutSecond(row?.time_start)}</TableCell>
                <TableCell align="center">{getTimeWithoutSecond(row?.time_end)}</TableCell>
                <TableCell align="center">{row?.mileage ? Number(row?.mileage).toFixed(3) : 0}</TableCell>
                <TableCell align="center">{getTimeWithoutSecond(row?.duration) ?? 0}</TableCell>
                <TableCell align="center">
                    {!hideIcons && (
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            {row?.entity_type !== 'App\\Models\\Flight' && (
                                <FormButtonsComponent
                                    noMarginLeft
                                    justButton
                                    buttons={[
                                        {
                                            ...buttonsTypes.editIcon,
                                            style: { fontSize: '0.9rem' },
                                            onClick: (e) => {
                                                e.stopPropagation();
                                                handleEdit(row, indexElement);
                                            },
                                        },
                                        {
                                            ...buttonsTypes.addIcon,
                                            onClick: (e) => {
                                                e.stopPropagation();
                                                handleAdd(indexElement);
                                            },
                                        },
                                        {
                                            ...buttonsTypes.deleteIcon,
                                            style: { fontSize: '0.875rem' },
                                            onClick: (e) => {
                                                e.stopPropagation();
                                                setIsOpenDeleteModal(true);
                                            },
                                            disabled: lastElement
                                        },
                                    ]}
                                />
                            )}
                        </div>
                    )}
                </TableCell>
            </TableRow>
            <TableRow className={classes.root}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8} className="list">
                    <Collapse in={open} timeout="auto" unmountOnExit className="info">
                        <h2>Остановки и контрольные точки:</h2>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">№</TableCell>
                                    <TableCell align="center">Название</TableCell>
                                    <TableCell align="center">Начало</TableCell>
                                    <TableCell align="center">Окончание</TableCell>
                                    <TableCell align="center">Пробег от предыдущей остановки, км</TableCell>
                                    <TableCell align="center">Время на остановке, чч:мм</TableCell>
                                    <TableCell align="center">Время от предыдущей остановки, чч:мм</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row?.entity_list?.map((entity) => (
                                    <TableRow key={entity?.number}>
                                        <TableCell align="center">{entity?.number}</TableCell>
                                        <TableCell align="center">{entity?.name}</TableCell>
                                        <TableCell align="center">{getTimeWithoutSecond(entity?.time_start)}</TableCell>
                                        <TableCell align="center">{getTimeWithoutSecond(entity?.time_end)}</TableCell>
                                        <TableCell align="center">{entity?.mileage ? Number(entity?.mileage).toFixed(3) : 0}</TableCell>
                                        <TableCell align="center">{getTimeWithoutSecond(entity?.duration)}</TableCell>
                                        <TableCell align="center">{getTimeWithoutSecond(entity?.interval)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
            {openEditFlightType && <EditFlightType
                row={row}
                isOpen={openEditFlightType}
                onClose={handleEditFlightType}
            />}
            {isOpenDeleteModal && (
                <ConfirmDelete
                    open={isOpenDeleteModal}
                    onClose={() => setIsOpenDeleteModal(false)}
                    onSuccess={handleDeleteItem}
                />
            )}
        </>
    );
};

export default EventItem;
