import { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import SidebarItem from 'components/MapComponents/SidebarItem';
import Loading from 'components/common/Loading';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import getFilters from 'components/MapComponents/helpers/getFilters';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import colorExtend from 'helpers/mapHelper/colorExtend';
import { usePrevious, useStoreProp } from 'helpers/hooks';
import * as actions from 'redux/TrafficLights/actions';
import { trafficLightsSelectors } from 'redux/TrafficLights';
import config from '../config';
import CheckBox from './CheckBox';
import Icon from '../icons/icon';
import Item from './Item';
import FilterForm from './Filter/index';

const SideBar = (props) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const scrollRef = useRef(null);
    const list = useSelector(trafficLightsSelectors.sidebar);
    const loading = useSelector(trafficLightsSelectors.sidebarLoading);
    const active = useSelector(trafficLightsSelectors.active);
    const filters = useSelector(trafficLightsSelectors.filters);
    const saved = useSelector(trafficLightsSelectors.saved);
    // const statuses = useStoreProp(actions.loadTrafficLightsStatuses, 'trafficLights', 'lightsStatuses');
    const states = useStoreProp(actions.loadTrafficLightsStates, 'trafficLights', 'lightStates');
    const prevFilters = usePrevious(filters);
    const activeId = Object.keys(active).length > 0 ? active.id : 0;

    const scroolTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    const fetchList = () => {
        dispatch(actions.loadSidebar(
            page,
            limit,
            getFilters(filters)
        ));
    };

    const handleScrollUpdate = (values) => {
        const last_page = list?.meta?.last_page || 0;
        if (
            values.top > 0.98
            && loading === false
            && page < last_page
        ) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            scroolTop();
            setPage(1);
        }
        fetchList();
    }, [page, limit, filters]);

    useEffect(() => {
        // dispatch(actions.loadComplexStatuses());
        return () => {
            dispatch(actions.clearSidebar());
        };
    }, []);

    useEffect(() => {
        if (saved) {
            scroolTop();
            if (page > 1) {
                setPage(1);
            } else {
                fetchList();
            }
        }
    }, [saved]);

    const renderList = (dataList) => (
        <>
            {dataList?.map((item) => {
                return (
                    <SidebarItem
                        key={item?.id}
                        active={activeId === item?.id}
                        icon={
                            Icon({
                                fill: colorExtend(item?.color)
                            })
                        }
                        // buttons={{
                        //     edit: {
                        //         title: buttons.EDIT,
                        //         icon: <i className="fas fa-pen" />,
                        //         onClick: () => {
                        //             // редактирование
                        //             dispatch(actions.setEditForm(item));
                        //         }
                        //     },
                        //     delete: {
                        //         title: buttons.DELETE,
                        //         icon: <i className="fas fa-trash-alt" />,
                        //         onClick: () => {
                        //             // удаление
                        //             dispatch(actions.setDeleteForm(item.id));
                        //         }
                        //     }
                        // }}
                        onClick={() => {
                            dispatch(actions.setActive(item));
                        }}
                    >
                        <Item
                            {...item}
                            // statuses={statuses}
                            states={states}
                        />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={() => setPage(1)}
                onSetFilter={(filter) => dispatch(actions.setFilters(filter))}
                onClearFilter={() => dispatch(actions.clearFilters())}
                content={({ data, onChange }) => <FilterForm data={data} onChange={onChange} />}
                layer={config.slug}
            />

            {/*<CheckBox />*/}

            {loading && list?.data?.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                    ref={scrollRef}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={titles.TRAFFIC_LIGHTS}
                            list={list}
                        />

                        <CheckBox />

                        {
                            list.data.length === 0
                                ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                                : renderList(list?.data)
                        }
                    </div>
                    {loading && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
    return null;
};

export default SideBar;
