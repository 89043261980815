import { useState } from 'react';
import Modal from '../../../../../../common/Modal';
import titles from '../../../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../../../common/FormButtons';
import { useValidation } from '../../../../../../../helpers/hooks';
import SelectRepairFrequency from '../../../../../../common/Autocomplete/RoadWorks/RepairFrequency';
import SelectRepairTypes from '../../../../../../common/Autocomplete/RoadWorks/RepairTypes';

const AddEditModal = ({ isOpen, onClose, onAccept, index, item }) => {
    const validation = useValidation();

    const initialState = {
        repair_frequency: item?.repair_frequency || {},
        repair_type: item?.repair_type || {},
        type_id: item?.type_id || '',
        frequency_id: item?.frequency_id || '',
        // labor: item?.labor || '',
        // downtime: item?.downtime || ''
    };

    const [data, setData] = useState(initialState);

    // const onChange = (e) => {
    //     const { name, value } = e.target;
    //     setData({
    //         ...data,
    //         [name]: value
    //     });
    //     validation.clear();
    // };

    const handleSuccess = () => {
        item 
            ? onAccept(index, data, onClose) 
            : onAccept(data, onClose);
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            small
            title={item ? titles.EDIT : titles.ADD}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSuccess,
                            disabled: !data?.type_id || !data?.frequency_id
                        }
                    ]}
                />
            }
        >
            <div className="modal__form">
                <div className="block">
                    <SelectRepairTypes
                        label="Тип ремонта"
                        required
                        selected={data?.repair_type}
                        onChange={(type) => {
                            setData({ 
                                ...data, 
                                repair_type: type, 
                                type_id: type.id, 
                            });
                            validation.clear();
                        }}
                        error={validation.isKey(`types.${index}.type_id`)}
                        helperText={validation.get(`types.${index}.type_id`)}
                    />
                </div>

                <div className="block">
                    <SelectRepairFrequency
                        label="Частота проведения"
                        required
                        selected={data?.repair_frequency}
                        onChange={(frequency) => {
                            setData({ 
                                ...data, 
                                repair_frequency: frequency, 
                                frequency_id: frequency.id, 
                            });
                            validation.clear();
                        }}
                        error={validation.isKey(`types.${index}.frequency_id`)}
                        helperText={validation.get(`types.${index}.frequency_id`)}
                    />
                </div>
                
                
                {/* <FormInfoWrapper
                    className="block"
                    error={validation.isKey(`indicators.${index}.labor`)}
                    helperText={validation.get(`indicators.${index}.labor`)}
                >
                    <TextField
                        label="Трудоемкость"
                        variant="outlined"
                        size="small"
                        required
                        name="labor"
                        value={data?.labor || ''}
                        type="number"
                        onChange={onChange}
                    />
                </FormInfoWrapper> */}

                {/* <FormInfoWrapper
                    className="block"
                    error={validation.isKey(`indicators.${index}.downtime`)}
                    helperText={validation.get(`indicators.${index}.downtime`)}
                >
                    <TextField
                        label="Простой оборудования"
                        variant="outlined"
                        size="small"
                        required
                        name="downtime"
                        value={data?.downtime || ''}
                        type="number"
                        onChange={onChange}
                    />
                </FormInfoWrapper> */}
            </div>     
        </Modal>
    );
};

export default AddEditModal;