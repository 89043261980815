import moduleName from './module';

const prefix = `${moduleName}`;

export const LOAD_TRANSPORT_INCIDENT_TYPES = `${prefix}/LOAD_TRANSPORT_INCIDENT_TYPES`;
export const LOADING_TRANSPORT_INCIDENT_TYPES = `${prefix}/LOADING_TRANSPORT_INCIDENT_TYPES`;
export const LOADED_TRANSPORT_INCIDENT_TYPES = `${prefix}/LOADED_TRANSPORT_INCIDENT_TYPES`;
export const CREATE_TRANSPORT_INCIDENT_TYPE = `${prefix}/CREATE_TRANSPORT_INCIDENT_TYPE`;
export const EDIT_TRANSPORT_INCIDENT_TYPE = `${prefix}/EDIT_TRANSPORT_INCIDENT_TYPE`;
export const DELETE_TRANSPORT_INCIDENT_TYPE = `${prefix}/DELETE_TRANSPORT_INCIDENT_TYPE`;
export const LOAD_EVENTS = `${prefix}/LOAD_EVENTS`;
export const LOADING_EVENTS = `${prefix}/LOADING_EVENTS`;
export const LOADED_EVENTS = `${prefix}/LOADED_EVENTS`;
export const CREATE_EVENT = `${prefix}/CREATE_EVENT`;
export const EDIT_EVENT = `${prefix}/EDIT_EVENT`;
export const DELETE_EVENT = `${prefix}/DELETE_EVENT`;
export const LOAD_KEY_WORD_LIST = `${prefix}/LOAD/KEY/WORD/LIST`;
export const LOADED_KEY_WORD_LIST = `${prefix}/LOADED/KEY/WORD/LIST`;
export const LOADING_KEY_WORD_LIST = `${prefix}/LOADING/KEY/WORD/LIST`;
export const EDIT_KEY_WORD = `${prefix}/EDIT/KEY/WORD`;
export const ADD_KEY_WORD = `${prefix}/ADD/KEY/WORD`;
export const DELETE_KEY_WORD = `${prefix}/DELETE/KEY/WORD`;
//Получение списка угроз и рисков
export const LOAD_DANGERS_LIST = `${prefix}/LOAD/DANGERS/LIST`;
export const LOADING_DANGERS_LIST = `${prefix}/LOADING/DANGERS/LIST`;
export const LOADED_DANGERS_LIST = `${prefix}/LOADED/DANGERS/LIST`;
export const SET_STATUS_DANGER = `${prefix}/SET/STATUS/DANGER`;
//Создание сущности угроз и риска
export const CREATE_DANGER = `${prefix}/CREATE/DANGER`;
//Редактирование сущности угроз и риска
export const EDIT_DANGER = `${prefix}/EDIT/DANGER`;
//Удаление сущности угроз и риска
export const DELETE_DANGER = `${prefix}/DELETE/DANGER`;
export const LOAD_THREAT_LEVELS = `${prefix}/LOAD_THREATS_LEVEL`;
export const LOADING_THREAT_LEVELS = `${prefix}/LOADING_THREAT_LEVELS`;
export const LOADED_THREAT_LEVELS = `${prefix}/LOADED_THREAT_LEVELS`;
export const CREATE_THREAT_LEVEL = `${prefix}/CREATE_THREAT_LEVEL`;
export const EDIT_THREAT_LEVEL = `${prefix}/EDIT_THREAT_LEVEL`;
export const DELETE_THREAT_LEVEL = `${prefix}/DELETE_THREAT_LEVEL`;
export const LOAD_TYPICAL_OPERATIONS = `${prefix}/LOAD_TYPICAL_OPERATIONS`;
export const LOADING_TYPICAL_OPERATIONS = `${prefix}/LOADING_TYPICAL_OPERATIONS`;
export const LOADED_TYPICAL_OPERATIONS = `${prefix}/LOADED_TYPICAL_OPERATIONS`;
export const CREATE_TYPICAL_OPERATION = `${prefix}/CREATE_TYPICAL_OPERATION`;
export const EDIT_TYPICAL_OPERATION = `${prefix}/EDIT_TYPICAL_OPERATION`;
export const DELETE_TYPICAL_OPERATION = `${prefix}/DELETE_TYPICAL_OPERATION`;
export const LOAD_INCIDENT_OPERATIONS = `${prefix}/LOAD_INCIDENT_OPERATIONS`;
export const LOADING_INCIDENT_OPERATIONS = `${prefix}/LOADING_INCIDENT_OPERATIONS`;
export const LOADED_INCIDENT_OPERATIONS = `${prefix}/LOADED_INCIDENT_OPERATIONS`;
export const EDIT_INCIDENT_OPERATIONS = `${prefix}/EDIT_INCIDENT_OPERATIONS`;
//Сценарии реагирования
export const LOAD_RESPONSE_SCENARIOS = `${prefix}/LOAD_RESPONSE_SCENARIOS`;
export const LOADING_RESPONSE_SCENARIOS = `${prefix}/LOADING_RESPONSE_SCENARIOS`;
export const LOADED_RESPONSE_SCENARIOS = `${prefix}/LOADED_RESPONSE_SCENARIOS`;
export const CREATE_RESPONSE_SCENARIO = `${prefix}/CREATE_RESPONSE_SCENARIO`;
export const EDIT_RESPONSE_SCENARIO = `${prefix}/EDIT_RESPONSE_SCENARIO`;
export const DELETE_RESPONSE_SCENARIO = `${prefix}/DELETE_RESPONSE_SCENARIO`;
export const LOAD_CURRENT_RESPONSE_SCENARIO = `${prefix}/LOAD_CURRENT_RESPONSE_SCENARIO`;
export const LOADED_CURRENT_RESPONSE_SCENARIO = `${prefix}/LOADED_CURRENT_RESPONSE_SCENARIO`;
export const LOAD_EVENT_OBJECTS = `${prefix}/LOAD_EVENT_OBJECTS`;
export const LOADING_EVENT_OBJECTS = `${prefix}/LOADING_EVENT_OBJECTS`;
export const LOADED_EVENT_OBJECTS = `${prefix}/LOADED_EVENT_OBJECTS`;
export const CREATE_EVENT_OBJECTS = `${prefix}/CREATE_EVENT_OBJECTS`;
export const EDIT_EVENT_OBJECTS = `${prefix}/EDIT_EVENT_OBJECTS`;
export const DELETE_EVENT_OBJECTS = `${prefix}/DELETE_EVENT_OBJECTS`;
export const LOAD_EVENT_OBJECTS_TYPES = `${prefix}/LOAD_EVENT_OBJECTS_TYPES`;
export const LOADED_EVENT_OBJECTS_TYPES = `${prefix}/LOADED_EVENT_OBJECTS_TYPES`;
export const LOAD_CURRENT_EVENT = `${prefix}/LOAD_CURRENT_EVENT`;
export const LOADING_CURRENT_EVENT = `${prefix}/LOADING_CURRENT_EVENT`;
export const LOADED_CURRENT_EVENT = `${prefix}/LOADED_CURRENT_EVENT`;
export const LOAD_CURRENT_KEYWORD = `${prefix}/LOAD_CURRENT_KEYWORD`;
export const LOADED_CURRENT_KEYWORD = `${prefix}/LOADED_CURRENT_KEYWORD`;
export const LOAD_TRANSPORT_INCIDENTS = `${prefix}/LOAD_TRANSPORT_INCIDENTS`;
export const LOADING_TRANSPORT_INCIDENTS = `${prefix}/LOADING_TRANSPORT_INCIDENTS`;
export const LOADED_TRANSPORT_INCIDENTS = `${prefix}/LOADED_TRANSPORT_INCIDENTS`;
export const LOAD_TRANSPORT_INCIDENT = `${prefix}/LOAD_TRANSPORT_INCIDENT`;
export const LOADING_TRANSPORT_INCIDENT = `${prefix}/LOADING_TRANSPORT_INCIDENT`;
export const LOADED_TRANSPORT_INCIDENT = `${prefix}/LOADED_TRANSPORT_INCIDENT`;
export const CREATE_TRANSPORT_INCIDENT = `${prefix}/CREATE_TRANSPORT_INCIDENT`;
export const EDIT_TRANSPORT_INCIDENT = `${prefix}/EDIT_TRANSPORT_INCIDENT`;
export const DELETE_TRANSPORT_INCIDENT = `${prefix}/DELETE_TRANSPORT_INCIDENT`;
export const LOAD_TRANSPORT_INCIDENT_STATUSES = `${prefix}/LOAD_TRANSPORT_INCIDENT_STATUSES`;
export const LOADED_TRANSPORT_INCIDENT_STATUSES = `${prefix}/LOADED_TRANSPORT_INCIDENT_STATUSES`;
export const LOADING_CURRENT_RESPONSE_SCENARIO = `${prefix}/LOADING_CURRENT_RESPONSE_SCENARIO`;
export const LOAD_CURRENT_TRANSPORT_INCIDENT_TYPE = `${prefix}/LOAD_CURRENT_TRANSPORT_INCIDENT_TYPE`;
export const LOADING_CURRENT_TRANSPORT_INCIDENT_TYPE = `${prefix}/LOADING_CURRENT_TRANSPORT_INCIDENT_TYPE`;
export const LOADED_CURRENT_TRANSPORT_INCIDENT_TYPE = `${prefix}/LOADED_CURRENT_TRANSPORT_INCIDENT_TYPE`;
export const LOAD_CURRENT_THREAT_LEVEL = `${prefix}/LOAD_CURRENT_THREAT_LEVEL`;
export const LOADING_CURRENT_THREAT_LEVEL = `${prefix}/LOADING_CURRENT_THREAT_LEVEL`;
export const LOADED_CURRENT_THREAT_LEVEL = `${prefix}/LOADED_CURRENT_THREAT_LEVEL`;
export const LOAD_CURRENT_CATEGORY = `${prefix}/LOAD_CURRENT_CATEGORY`;
export const LOADED_CURRENT_CATEGORY = `${prefix}/LOADED_CURRENT_CATEGORY`;
export const LOAD_CURRENT_EVENT_OBJECT = `${prefix}/LOAD_CURRENT_EVENT_OBJECT`;
export const LOADED_CURRENT_EVENT_OBJECT = `${prefix}/LOADED_CURRENT_EVENT_OBJECT`;
export const LOAD_ARRAY_OF_KEYWORDS = `${prefix}/LOAD_ARRAY_OF_KEYWORDS`;
export const LOADED_ARRAY_OF_KEYWORDS = `${prefix}/LOADED_ARRAY_OF_KEYWORDS`;
// map
export const LOAD_POLYGON = `${prefix}/LOAD_POLYGON`;
export const SAVE_POLYGON = `${prefix}/SAVE/POLYGON`;
export const LOADING_SIDEBAR = `${prefix}/LOADING/SIDEBAR`;
export const LOADED_SIDEBAR = `${prefix}/LOADED/SIDEBAR`;
export const LOAD_SIDEBAR = `${prefix}/LOAD/SIDEBAR`;
export const SET_ACTIVE = `${prefix}/SET/ACTIVE`;
export const SET_DELETE_FORM = `${prefix}/SET/DELETE/FORM`;
export const SET_EDIT_FORM = `${prefix}/SET/EDIT/FORM`;
export const SAVED = `${prefix}/SAVED`;
export const SET_FILTER = `${prefix}/SET/FILTER`;

export const LOAD_REPORT_TRANSPORT_INCIDENTS = `${prefix}/LOAD_REPORT_TRANSPORT_INCIDENTS`;
export const LOADING_REPORT_TRANSPORT_INCIDENTS = `${prefix}/LOADING_REPORT_TRANSPORT_INCIDENTS`;
export const LOADED_REPORT_TRANSPORT_INCIDENTS = `${prefix}/LOADED_REPORT_TRANSPORT_INCIDENTS`;
export const GET_REPORT_TRANSPORT_INCIDENTS = `${prefix}/GET_REPORT_TRANSPORT_INCIDENTS`;
export const SET_REPORT_TRANSPORT_INCIDENTS_FILTER = `${prefix}/SET_REPORT_TRANSPORT_INCIDENTS_FILTER`;
export const CLEAR_REPORT_TRANSPORT_INCIDENTS_FILTER = `${prefix}/CLEAR_REPORT_TRANSPORT_INCIDENTS_FILTER`;
export const LOAD_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME = `${prefix}/LOAD_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME`;
export const LOADING_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME = `${prefix}/LOADING_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME`;
export const LOADED_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME = `${prefix}/LOADED_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME`;
export const GET_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME = `${prefix}/GET_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME`;
export const SET_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME_FILTER = `${prefix}/SET_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME_FILTER`;
export const CLEAR_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME_FILTER = `${prefix}/CLEAR_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME_FILTER`;

export const LOADED_SIDEBAR_EVENTS = `${prefix}/LOADED_SIDEBAR_EVENTS`;
export const LOADING_SIDEBAR_EVENTS = `${prefix}/LOADING_SIDEBAR_EVENTS`;
export const LOAD_SIDEBAR_EVENTS = `${prefix}/LOAD_SIDEBAR_EVENTS`;
export const SET_ACTIVE_EVENTS = `${prefix}/SET_ACTIVE_EVENTS`;
export const SET_DELETE_FORM_EVENTS = `${prefix}/SET_DELETE_FORM_EVENTS`;
export const SET_EDIT_FORM_EVENTS = `${prefix}/SET_EDIT_FORM_EVENTS`;
export const SET_FILTER_EVENTS = `${prefix}/SET_FILTER_EVENTS`;
export const LOAD_POLYGON_EVENTS = `${prefix}/LOAD_POLYGON_EVENTS`;
export const SAVE_POLYGON_EVENTS = `${prefix}/SAVE_POLYGON_EVENTS`;
export const SET_SAVED_EVENTS = `${prefix}/SET_SAVED_EVENTS`;
export const LOADING_POLYGON_EVENTS = `${prefix}/LOADING_POLYGON_EVENTS`;
export const LOADING_POLYGON = `${prefix}/LOADING_POLYGON`;

export const LOAD_TRANSPORT_INCIDENT_HISTORY = `${prefix}/LOAD_TRANSPORT_INCIDENT_HISTORY`;
export const LOADING_TRANSPORT_INCIDENT_HISTORY = `${prefix}/LOADING_TRANSPORT_INCIDENT_HISTORY`;
export const LOADED_TRANSPORT_INCIDENT_HISTORY = `${prefix}/LOADED_TRANSPORT_INCIDENT_HISTORY`;

export const LOAD_TRANSPORT_INCIDENT_TYPE_CREATION = `${prefix}LOAD/TRANSPORT/INCIDENT/TYPE/CREATION`;
export const LOADED_TRANSPORT_INCIDENT_TYPE_CREATION = `${prefix}LOADED/TRANSPORT/INCIDENT/TYPE/CREATION`;

export const LOAD_INFOPANEL = `${prefix}/LOAD_INFOPANEL`;
export const LOADING_INFOPANEL = `${prefix}/LOADING_INFOPANEL`;
export const LOADED_INFOPANEL = `${prefix}/LOADED_INFOPANEL`;

export const LOAD_DISTRICTS_BY_MONTH = `${prefix}/LOAD_DISTRICTS_BY_MONTH`;
export const LOADING_DISTRICTS_BY_MONTH = `${prefix}/LOADING_DISTRICTS_BY_MONTH`;
export const LOADED_DISTRICTS_BY_MONTH = `${prefix}/LOADED_DISTRICTS_BY_MONTH`;
export const SET_FILTER_DISTRICTS_BY_MONTH = `${prefix}/SET_FILTER_DISTRICTS_BY_MONTH`;

export const SET_WS_EVENTS_OBJECTS = `${prefix}/SET_WS_EVENTS_OBJECTS`;
export const SET_EVENTS_PROPS = `${prefix}/SET_EVENTS_PROPS`;

export const SET_WS_TRANSPORT_INCIDENT = `${prefix}/SET_WS_TRANSPORT_INCIDENT`;
export const SET_SIDEBAR_PARAMS = `${prefix}/SET_SIDEBAR_PARAMS`;

export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;

export const LOAD_TRANSPORT_INCIDENT_MODAL = `${prefix}/LOAD_TRANSPORT_INCIDENT_MODAL`;
export const LOADING_TRANSPORT_INCIDENT_MODAL = `${prefix}/LOADING_TRANSPORT_INCIDENT_MODAL`;
export const LOADED_TRANSPORT_INCIDENT_MODAL = `${prefix}/LOADED_TRANSPORT_INCIDENT_MODAL`;

export const SET_TI_MODAL_DATA = `${prefix}/SET_TI_MODAL_DATA`;
export const SET_TI_FILTER_PARAMS = `${prefix}/SET_TI_FILTER_PARAMS`;

export const EDIT_SETTINGS_LIST = `${prefix}/EDIT_SETTINGS_LIST`;

export const LOAD_SETTINGS_GROUP = `${prefix}/LOAD_SETTINGS_GROUP`;
export const LOADING_SETTINGS_GROUP = `${prefix}/LOADING_SETTINGS_GROUP`;
export const LOADED_SETTINGS_GROUP = `${prefix}/LOADED_SETTINGS_GROUP`;
export const EDIT_SETTINGS_GROUP = `${prefix}/EDIT_SETTINGS_GROUP`;

export const LOAD_SHOWCASE = `${prefix}/LOAD_SHOWCASE`;
export const LOADING_SHOWCASE = `${prefix}/LOADING_SHOWCASE`;
export const LOADED_SHOWCASE = `${prefix}/LOADED_SHOWCASE`;

export const LOAD_IMAGES_LEGENDS = `${prefix}/LOAD_IMAGES_LEGENDS`;
export const LOADED_IMAGES_LEGENDS = `${prefix}/LOADED_IMAGES_LEGENDS`;

export const LOAD_REPORT_BY_EVENT_PLANS = `${prefix}LOAD_REPORT_BY_EVENT_PLANS`;
export const LOADED_REPORT_BY_EVENT_PLANS = `${prefix}LOADED_REPORT_BY_EVENT_PLANS`;
export const LOADING_REPORT_BY_EVENT_PLANS = `${prefix}LOADING_REPORT_BY_EVENT_PLANS`;
