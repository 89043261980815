import { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';

import mapHelper from 'helpers/mapHelper';
import { usePrevious } from 'helpers/hooks';
import useLoadPolygonLayer from 'helpers/hooks/useLoadPolygonLayer';
import { roadNetworksSelectors } from 'redux/RoadNetwork';
import * as actions from 'redux/RoadNetwork/actions';
import {
    Marker,
    PopUp,
    ToolTip,
    GeoJson,
} from 'components/MapComponents/leaflet';
import getLatLonFromGeometry from 'components/MapComponents/helpers/getLatLonFromGeometry';

import { createIconMarker, getColor } from './helper';
import PopUpComponent from './PopUp';
import config from './config.js';
import { checkBase64 } from 'helpers/transport.types';

const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();
 
    const polygon = useSelector(roadNetworksSelectors.infrastructureByPolygon);
    const active = useSelector(roadNetworksSelectors.activeInfrastructureLayer);
    const polygonLoading = useSelector(roadNetworksSelectors.loadingInfrastructureByPolygon);
    const prevLoading = usePrevious(polygonLoading);

    const [selectedItem, setSelectedItem] = useState(null);
    const [clickedItem, setClickedItem] = useState(null);

    const activeId = active?.id || null;

    // проверка на маркер
    const isMarker = (item) => item?.geometry?.geometry?.type === 'Point'
        && !item?.geometry?.properties?.radius;

    const createLatLng = (item) => {
        const { lat, lon } = getLatLonFromGeometry(item?.geometry);
        return lat && lon ? [lat, lon] : null;
    };

    const {
        // маркеры
        staticMarkers,
        // гео маркеры
        geoMarkers
    } = useMemo(() => {
        return polygon.reduce((res, item) => {
            const latlng = createLatLng(item);

            // нет координат
            if (!latlng) return res;

            if (isMarker(item)) {
                res.staticMarkers.push({
                    ...item,
                    latlng,
                });
            } else if (item.id !== selectedItem?.id) {
                res.geoMarkers.push({
                    ...item,
                    latlng,
                });
            }

            return res;
        }, {
            staticMarkers: [],
            geoMarkers: []
        });
    }, [polygon, selectedItem]);

    const previewId = useRef(0);

    useEffect(() => {
        if (polygonLoading === false && prevLoading === true && previewId.current) {
            const id = previewId.current;
            setTimeout(() => {
                map.fire(`showBy${config.slug}${id}`);
            }, 200);
            previewId.current = 0;
        }
    }, [polygonLoading]);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadInfrastructureByPolygon(
            polygon,
        ));
    };

    // задерживаем одновременные запросы
    const fetchProvider = useLoadPolygonLayer(fetchPolygon);
    const handleFetchPolygon = () => fetchProvider.load();

    const handlePUO = () => {
        fetchProvider.lock();
    };
    const handlePUC = () => {
        fetchProvider.unLock();
    };

    const handleClickMap = () => {
        setSelectedItem(null);
        setClickedItem(null);
    };

    useEffect(() => {

        map
            .on('click', handleClickMap)
            .on('moveend', handleFetchPolygon)
            .on('popupopen', handlePUO)
            .on('popupclose', handlePUC);

        return () => {
            dispatch(actions.setActiveInfrastructureLayer());
            dispatch(actions.clearInfrastructureByPolygon());
            map
                .off('click', handleClickMap)
                .off('moveend', handleFetchPolygon)
                .off('popupopen', handlePUO)
                .off('popupclose', handlePUC);
        };
    }, []);

    console.log(active);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { geometry } = active;
            if (geometry && geometry?.geometry?.coordinates.length > 0) {
                previewId.current = active.id;
                const center = L.geoJSON(geometry)?.getBounds?.().getCenter?.();
                if (center) map.setView(center, 14);
            }

            setClickedItem(active.id);
            fetchProvider.unLock();
        }
    }, [active]);

    const handleClick = (item) => () => {
        setClickedItem(item.id);

        if (clickedItem === item.id) {
            setSelectedItem(item);
        }
        map.setView(item.latlng);
    };

    // отображаемый геообъект
    const selectedItemGeo = useMemo(() => selectedItem
        ? {
            ...selectedItem.geometry,
            properties: {
                ...selectedItem.geometry.properties,
                data: selectedItem,
                attribution: {
                    slug: config.slug,
                    color: getColor(selectedItem)
                },
            },
            style: {
                color: getColor(selectedItem),
                weight: 7
            }
        }
        : null
    , [selectedItem]);

    return (
        <>
            {/* статичные маркеры */}
            {staticMarkers.map(item => {
                const currentShow = activeId === item.id && item.type_text === active?.type_text;
                return (
                    <Marker
                        {...props}
                        icon={selectedItem?.custom_icon ? checkBase64(selectedItem?.custom_icon) : createIconMarker(item)}
                        latlng={item.latlng}
                        attribution={{
                            slug: config.slug,
                            color: getColor(item)
                        }}
                        key={item.id}
                    >
                        <ToolTip
                            direction="top"
                            offset={[0, -40]}
                        >
                            <div>
                                {item.name}
                            </div>
                        </ToolTip>

                        <PopUp show={currentShow}>
                            <PopUpComponent item={item}/>
                        </PopUp>
                    </Marker>
                );
            })}

            {/* геообъекты маркерами */}
            {geoMarkers.map(item => {
                const currentShow = activeId === item.id && item.type_text === active?.type_text;
                return (
                    <Marker
                        {...props}
                        icon={selectedItem?.custom_icon ? checkBase64(selectedItem?.custom_icon) : createIconMarker(item)}
                        latlng={item.latlng}
                        attribution={{
                            slug: config.slug,
                            color: getColor(item)
                        }}
                        key={item.id}
                        onClick={handleClick(item)}
                    >
                        <ToolTip
                            direction="top"
                            offset={[0, -40]}
                        >
                            <div>
                                {item.name}
                            </div>
                        </ToolTip>

                        <PopUp show={currentShow}>
                            <PopUpComponent item={item} bottomText={'Кликните, чтобы отобразить объект'}/>
                        </PopUp>
                    </Marker>
                );
            })}

            {/* геообъект */}
            {selectedItemGeo && (
                <GeoJson
                    {...props}
                    parent={map}
                    data={selectedItemGeo}
                    // если вдруг прилетел маркер
                    icon={item => selectedItem?.custom_icon ? checkBase64(selectedItem?.custom_icon) : createIconMarker(item)}
                    centerAfter={true}
                    idPrefix={config.slug}
                    onClosePopup={() => dispatch(actions.setActiveInfrastructureLayer())}
                />
            )}
        </>
    );
};

export default Layer;