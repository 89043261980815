import React, { useEffect, useState } from 'react';
import titles from '../../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import Modal from '../../../../../common/Modal';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useStoreProp, useValidation } from '../../../../../../helpers/hooks';
import {
    loadWorkorderEventManualStatus,
    loadWorkorderEventFlightManualStatus,
    loadFlightDefectGroup,
    loadFlightDefects,
    loadWorkorderEvents,
    createWorkorderEventStatus,
    editWorkorderEventStatus,
} from '../../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../../redux/TransportPassenger';

const StatusesModal = ({
    isNew,
    onClose,
    isOpen,
    item,
    isFlight = false,
}) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const initialState = {
        status_id: item?.manual_status_id || '',
        comment: item?.comment || '',
        defect_id: item?.flight_defect?.id || ''
    };

    const [defectGroup, setDefectGroup] = useState(item?.defect_group?.id || '');
    const [formData, setFormData] = useState(initialState);
    const flightDefects = useSelector(transportPassengerSelectors.flightDefects);

    const manualStatuses = useStoreProp(
        loadWorkorderEventManualStatus,
        'transportPassenger',
        'eventManualStatus'
    );

    const flightStatuses = useStoreProp(
        loadWorkorderEventFlightManualStatus,
        'transportPassenger',
        'eventManualFlightStatus'
    );

    const flightDefectGroup = useStoreProp(
        loadFlightDefectGroup,
        'transportPassenger',
        'flightDefectGroup'
    );

    useEffect(() => {
        if (defectGroup !== '' && !flightDefects[defectGroup]) {
            dispatch(loadFlightDefects({ defect_group_id: defectGroup }));
        }
    }, [defectGroup, dispatch, flightDefects]);

    const statuses = isFlight ? flightStatuses : manualStatuses;

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        validation.deleteKeys(Object.keys(name));
    };

    const onSave = () => {
        if (isNew) {
            dispatch(createWorkorderEventStatus(item.id, formData));
        } else {
            dispatch(editWorkorderEventStatus(item.id, formData));
        }
    };

    const onChangeStatus = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
            defect_id: ''
        });
        setDefectGroup('');
        validation.deleteKeys(Object.keys(name));
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
            dispatch(loadWorkorderEvents(item.work_order_id));
        }
    }, [validation, onClose, requestStatus, dispatch, item]);

    return (
        <Modal
            title={isNew ? titles.ADD_STATUS : titles.EDIT_STATUS}
            onClose={onClose}
            maxWidthProp={'sm'}
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onSave,
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl required className="block" size="small" variant="outlined">
                    <InputLabel>Выберите статус от диспетчера</InputLabel>
                    <Select
                        value={formData.status_id || ''}
                        required
                        onChange={onChangeStatus}
                        // label={titles.STATUS}
                        name="status_id"
                        error={validation.isKey('status_id')}
                        label="Выберите статус от диспетчера"
                    >
                        {Object.keys(statuses)?.map((key) => (
                            <MenuItem key={key} value={key}>{statuses[key]}</MenuItem>
                        ))}
                    </Select>
                    {validation.isKey('status_id')
                        && <FormHelperText error>{validation.get('status_id')}</FormHelperText>
                    }
                </FormControl>
                {isFlight && formData.status_id && Number(formData.status_id) !== 1
                    && <FormControl className="block" size="small" variant="outlined">
                        <InputLabel>Причина</InputLabel>
                        <Select
                            value={defectGroup}
                            onChange={(e) => setDefectGroup(e.target.value)}
                            // label={titles.STATUS}
                            name="defectGroup"
                            label="Причина"
                        >
                            {flightDefectGroup?.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>{name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
                {defectGroup
                    && <FormControl className="block" size="small" variant="outlined">
                        <InputLabel>Брак рейса</InputLabel>
                        <Select
                            value={formData.defect_id}
                            onChange={onChange}
                            // label={titles.STATUS}
                            name="defect_id"
                            error={validation.isKey('defect_id')}
                            label="Брак рейса"
                        >
                            {flightDefects[defectGroup]?.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>{name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
                <FormControl
                    required={true}
                    className="block"
                    size="small"
                    variant="outlined"
                >
                    <TextField
                        label="Комментарий"
                        variant="outlined"
                        size="small"
                        name="comment"
                        value={formData.comment}
                        onChange={onChange}
                        error={validation.isKey('comment')}
                        helperText={validation.get('comment')}
                    />
                </FormControl>
            </div>
        </Modal>
    );
};

export default StatusesModal;