import React from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import App from './App';
import './css/index.scss';
// import reportWebVitals from './reportWebVitals';
import { Provider, ReactReduxContext } from 'react-redux';
import { history, store } from './store';
import Message from './components/layout/Message';
import CssBaseline from '@mui/material/CssBaseline';

render(
    <Provider store={store} context={ReactReduxContext}>
        <ConnectedRouter history={history}>
            <CssBaseline />
            <Switch>
                <Route path="*" render={(props: any) => <App {...props} />} />
            </Switch>

            {/* всплывающие алерты */}
            <Message />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
