import {
    Grid,
    LinearProgress,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { transportRoadSelectors } from '../../../../redux/TransportRoad';
import {
    clearVehicleStatistics,
    clearWorkorderStatistics,
    loadVehicleStatistics,
    loadWorkorderStatistics,
} from '../../../../redux/TransportRoad/actions';
import layerConfig from '../../../MapComponents/Layers/RoadworkTransport/config';
import Layer from '../../../MapComponents/Layers/RoadworkTransport/layer';
import CardWidget from '../../../common/Showcase/CardWidget';
import TypeComponent from '../../../common/Showcase/TypeComponent';
import useShowcase from '../../../../helpers/hooks/useShowcase';
import CardMap from '../../../common/Showcase/CardMap';

// страница
const Page = () => {
    const dispatch = useDispatch();
    const isShowcase = useShowcase();

    const vehicleStatistics = useSelector(transportRoadSelectors.vehicleStatistics);
    const loadingVehicleStatistics = useSelector(transportRoadSelectors.loadingVehicleStatistics);

    const workorderStatistics = useSelector(transportRoadSelectors.workorderStatistics);
    const loadingWorkorderStatistics = useSelector(transportRoadSelectors.loadingWorkorderStatistics);

    const list = [ ...vehicleStatistics?.graph, ...workorderStatistics?.graph];
    const isList = list?.length > 0;


    useEffect(() => {
        dispatch(loadVehicleStatistics());
        dispatch(loadWorkorderStatistics());
        return () => {
            dispatch(clearVehicleStatistics());
            dispatch(clearWorkorderStatistics());
        };
    }, []);

    return (
        <>
            <Grid container spacing={2} sx={{ height: '100%' }}>
                {isList && (
                    <Grid item xs={12} sm={6} md={4} className="showcase_grid_flex-column">
                        {(loadingVehicleStatistics || loadingWorkorderStatistics) && <LinearProgress/>}

                        {list.map((item, index) => (
                            <CardWidget
                                key={index}
                                icon={layerConfig.layerIcon}
                                title={item.name}
                                marginBottom={index < list.length - 1}
                                fillOthers
                                small
                            >
                                <TypeComponent
                                    {...item}
                                />
                            </CardWidget>
                        ))}
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    {...isList ? { sm: 6, md: 8 } : {}}
                    className="showcase_grid_flex-column"
                >
                    <CardMap
                        fillOthers
                        isCluster
                    >
                        <Layer readOnly={isShowcase} />
                    </CardMap>
                </Grid>

            </Grid>
        </>
    );
};

export default Page;