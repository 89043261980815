import * as types from './types';

export const loadTrackingList = (page = 1, limit = 25, data) => ({
    type: types.LOAD_TRACKING_LIST,
    payload: { page, limit, data }
});

export const loadingTrackingList = (bool = false) => ({
    type: types.LOADING_TRACKING_LIST,
    payload: bool
});

export const loadedTrackingList = (data) => ({
    type: types.LOADED_TRACKING_LIST,
    payload: data
});

export const loadTrackingMoreInfo = (page = 1, limit = 10, id) => ({
    type: types.LOAD_TRACKING_MORE_INFO,
    payload: { page, limit, id }
});

export const loadingTrackingMoreInfo = (bool = false) => ({
    type: types.LOADING_TRACKING_MORE_INFO,
    payload: bool
});

export const loadedTrackingMoreInfo = (data) => ({
    type: types.LOADED_TRACKING_MORE_INFO,
    payload: data
});

export const loadTrackingDeployedInfo = (id, grz) => ({
    type: types.LOAD_TRACKING_DEPLOYED_INFO,
    payload: { id, grz }
});

export const loadingTrackingDeployedInfo = (bool = false) => ({
    type: types.LOADING_TRACKING_DEPLOYED_INFO,
    payload: bool
});

export const loadedTrackingDeployedInfo = (data) => ({
    type: types.LOADED_TRACKING_DEPLOYED_INFO,
    payload: data
});

export const loadTrackingStatuses = () => ({
    type: types.LOAD_TRACKING_STATUSES
});

export const loadedTrackingStatuses = (data) => ({
    type: types.LOADED_TRACKING_STATUSES,
    payload: data
});

export const setLoading = (bool = false) => ({
    type: types.SET_LOADING,
    payload: bool
});

export const createWanted = (data, callback) => ({
    type: types.CREATE_WANTED,
    payload: data,
    callback
});

export const editWanted = (id, data, callback) => ({
    type: types.EDIT_WANTED,
    payload: {
        id,
        data
    },
    callback
});

export const deleteWanted = (id, callback) => ({
    type: types.DELETE_WANTED,
    payload: id,
    callback
});

export const setStatusWanted = (status = false) => ({
    type: types.SET_STATUS_WANTED,
    payload: status
});

export const loadWantedList = (page = 1, limit = 10, params= {}) => ({
    type: types.LOAD_WANTED_LIST,
    payload: {
        page,
        limit,
        ...params
    }
});

export const loadedWantedList = (data) => ({
    type: types.LOADED_WANTED_LIST,
    payload: data
});

export const clearWantedList = () => ({
    type: types.LOADED_WANTED_LIST,
    payload: {
        data: [],
        meta: {
            last_page: 0,
        },
    }
});

export const stopWanted = (id, comment, callback) => ({
    type: types.STOP_WANTED,
    payload: {
        id,
        comment,
    },
    callback,
});

export const stoppedWanted = (bool = false) => ({
    type: types.STOPPED_WANTED,
    payload: bool
});

export const loadTypesBase = () => ({
    type: types.LOAD_TYPES_BASE,
    payload: {}
});

export const loadedTypesBase = (data) => ({
    type: types.LOADED_TYPES_BASE,
    payload: data
});

export const createTracking = (data) => ({
    type: types.CREATE_TRACKING,
    payload: data
});

export const editTracking = (id, data) => ({
    type: types.EDIT_TRACKING,
    payload: { id, data }
});

export const deleteTracking = (id) => ({
    type: types.DELETE_TRACKING,
    payload: id
});

// events
export const loadingWantedEvents = (bool = false) => ({
    type: types.LOADING_WANTED_EVENTS,
    payload: bool
});
export const loadWantedEvents = (page = 1, limit = 10, data = {}) => ({
    type: types.LOAD_WANTED_EVENTS,
    payload: { page, limit, data }
});
export const loadedWantedEvents = (data) => ({
    type: types.LOADED_WANTED_EVENTS,
    payload: data
});

export const setWantedEventsReportFilter = (data) => ({
    type: types.SET_WANTED_EVENTS_REPORT_FILTER,
    payload: data
});

export const clearWantedEventsReportFilter = () => ({
    type: types.CLEAR_WANTED_EVENTS_REPORT_FILTER,
});

export const loadVehicleInfo = () => ({
    type: types.LOAD_VEHICLE_INFO,
});

export const loadedVehicleInfo = (data) => ({
    type: types.LOADED_VEHICLE_INFO,
    payload: data
});

export const loadEventWantedTrack = (props = {}) => ({
    type: types.LOAD_EVENT_WANTED_TRACK,
    payload: props,
});
export const loadedEventWantedTrack = (data) => ({
    type: types.LOADED_EVENT_WANTED_TRACK,
    payload: data,
});
export const clearEventWantedTrack = () => ({
    type: types.LOADED_EVENT_WANTED_TRACK,
    payload: {},
});
export const loadingEventWantedTrack = (bool = false) => ({
    type: types.LOADING_EVENT_WANTED_TRACK,
    payload: bool,
});