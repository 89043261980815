import React from 'react';
import messages from '../../../../../helpers/constants/messages';
import { Scrollbars } from 'react-custom-scrollbars';
// import Defections from './Defections';

const TabInformation = ({ data, title, hideEmpty }) => {
    return (
        <Scrollbars style={{ height: '200px' }}>
            {Object.keys(title).map((key) => {
                const titleObject = title[key] || {};
                const value = titleObject?.value
                    ? titleObject.value(data)
                    : data[key];

                if (hideEmpty && !value) return null;

                return (
                    <div
                        className="info__item"
                        key={`information_${key}`}
                    >
                        <span className="info__item-title">
                            {titleObject?.title || ''}
                            {': '}
                        </span>
                        <span>
                            {value || messages.INFO_IS_NOT_FOUND}
                        </span>
                    </div>
                );
            })}

            {/* {data?.defections?.length > 0 ? (
                <>
                    <div>
                        <strong style={{ fontSize: '14px', padding: '1rem' }}>
                            Недостатки:
                        </strong>
                    </div>
                    <Defections data={data?.defections || []} />
                </>
            ) : ''} */}
        </Scrollbars>
    );
};

export default TabInformation;
