import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'redux/VideoAnalytics/actions';
import { videoAnalyticsSelectors } from 'redux/VideoAnalytics';
import titles from 'helpers/constants/titles';
import { useWsActive } from 'helpers/ws/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import {
    fullDateTimeWithTimeZone,
    getDateWithDuration,
    getEndOf,
    getStartOf,
} from 'helpers/date.config';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import { LSContainer } from 'components/common/List';
import MediaModalList from 'components/common/DorisControl/MediaModalList';
import { PageReportBlock, PageReportModal } from 'components/common/PageReport';

import { prepareData } from './helpers';
import Filter from './Filter/index';
import Item from './Item';

import type { Event, Params, Response } from './types';

function VideoAnalytics() {
    const dispatch = useDispatch();

    const events: Response = useSelector(videoAnalyticsSelectors.events);
    const eventsLoading: boolean = useSelector(videoAnalyticsSelectors.eventsLoading);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState<Params>({
        page: 1,
        data: {
            start_date: fullDateTimeWithTimeZone(getStartOf('day', getDateWithDuration({ day: -30 }))),
            end_date: fullDateTimeWithTimeZone(getEndOf('day')),
            with_brand_and_model: true,
            with_color: true,
        },
    });

    useWsActive((activeData: Event) => {
        const result = {
            camera_id_list: activeData?.complex_id && [activeData?.complex_id],
            violation_type_list: activeData?.violation_type,
            grz_list: activeData?.grz && [activeData?.grz],
            model_type_id: activeData?.model_type_id,
            vehicle_brand: activeData?.vehicle_brand,
            vehicle_color: activeData?.vehicle_color,
            vehicle_model: activeData?.vehicle_model,
            vehicle_speed: activeData?.vehicle_speed,
        };

        setParams({
            page: 1,
            data: {
                ...removeEmptyFields(result),
                start_date: fullDateTimeWithTimeZone(new Date(activeData?.created_at)),
                end_date: fullDateTimeWithTimeZone(new Date(activeData?.stop_at)),
            },
        });
    });

    useEffect(() => {
        dispatch(actions.loadCameraEvents(params.page, limit, params.data));
    }, [limit, params.page, dispatch, params.data]);

    const renderContent = () => (
        <>
            {events.data?.length > 0 ? (
                <>
                    <LSContainer
                        headers={[
                            { title: 'Въезд в зону фиксации', width: '16%' },
                            { title: titles.CAMERA, width: '12%' },
                            { title: titles.EVENT_TYPE, width: '12%' },
                            { title: titles.GRZ, width: '12%' },
                            { title: titles.BRAND, width: '12%' },
                            { title: titles.MODEL, width: '12%' },
                            { title: titles.COLOR, width: '12%' },
                            { title: 'Детекция', width: '12%' },
                        ]}
                    >
                        {events.data.map((item, i) => (
                            <Item
                                key={i}
                                data={item}
                                renderMediaModal={(props: any) => (
                                    <MediaModalList
                                        initialList={events.data}
                                        limit={limit}
                                        meta={events.meta}
                                        filter={params.data}
                                        loadListAction={actions.loadCurrentCameraEvents}
                                        saveArchiveAction={false}
                                        loadingProps={eventsLoading}
                                        itemNum={i}
                                        {...props}
                                    />
                                )}
                            />
                        ))}
                    </LSContainer>
                </>
            ) : !eventsLoading && <NoData />}
        </>
    );

    return (
        <PageLayout
            additionalButtons={<ReturnToButton />}
            header="Отчет по видеоаналитике"
            filters={<Filter setParams={setParams} />}
            informPanelProps={{
                total: events.meta?.total,
                buttons: (
                    <PageReportModal>
                        <PageReportBlock filter={prepareData(params.data)}/>
                    </PageReportModal>
                ),
            }}
            loading={eventsLoading}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: events?.meta,
                limit,
                setLimit,
            }}
        />
    );
}

export default VideoAnalytics;
