import * as types from './types';

const initialState = {
    dataDevice: [],
    companyLink: {},
    eventsDevice: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    projects: {},
    statuses: {},
    types: [],
    list: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    listLoading: false,
    polygon: [],
    loading: false,
    active: {},
    filters: {},
    ecologySettings: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingEcologySettings: false,
    alerts: {},
    ecologyOutputParams: [],
    ecologyOutputSettings: {},
    loadingEcologyOutputSettings: false,
    devicesGroup: [],
    loadingDevicesGroup: false,
    loadingButton: false,
    showcase: {},
    loadingShowcase: false,
    listByRadius: [],

    settingsGroup: { data: [] },
    loadingSettingsGroup: false,

    deviceStatuses: [],
};

export default function reducer(state = initialState, action) {
    const { type, payload, replaceStore } = action;
    switch (type) {

        case types.DEVICE_COMPANY_LINK_LOADED: {
            return {
                ...state,
                companyLink: payload,
            };
        }

        case types.CLEAR_COMPANY_LINK: {
            return {
                ...state,
                companyLink: payload,
            };
        }

        case types.DEVICE_DATA_LOADED: {
            return {
                ...state,
                dataDevice: payload,
            };
        }

        case types.CLEAR_DEVICE_DATA: {
            return {
                ...state,
                dataDevice: initialState.dataDevice,
            };
        }

        case types.EVENTS_LOADED: {
            const { replaceStore } = action;
            if (replaceStore) {
                return {
                    ...state,
                    //eventsDevice: payload
                    eventsDevice: {
                        data: [],
                        meta: {
                            last_page: 0
                        },
                        ...payload
                    },
                };
            }
            return {
                ...state,
                eventsDevice: {
                    ...payload,
                    data: [
                        ...state.eventsDevice.data,
                        ...payload.data
                    ]
                },
            };
        }

        case types.CLEAR_EVENTS: {
            return {
                ...state,
                eventsDevice: {
                    ...initialState.eventsDevice
                },
            };
        }

        case types.PROJECTS_LOADED: {
            return {
                ...state,
                projects: payload,
            };
        }

        case types.STATUSES_LOADED: {
            return {
                ...state,
                statuses: payload,
            };
        }

        case types.TYPES_LOADED: {
            return {
                ...state,
                types: payload,
            };
        }

        case types.LIST_LOADED: {
            if (replaceStore) {
                return {
                    ...state,
                    list: payload,
                };
            }
            return {
                ...state,
                list: {
                    ...state.list,
                    ...payload,
                    data: [
                        ...state.list.data,
                        ...payload.data
                    ],
                    meta: {
                        ...state.list.meta,
                        ...payload.meta
                    },
                },
            };
        }

        case types.LIST_LOADING : {
            return {
                ...state,
                listLoading: payload
            };
        }

        case types.POLYGON_LOADED: {
            return {
                ...state,
                polygon: payload,
            };
        }

        case types.LOADING: {
            return {
                ...state,
                loading: payload,
            };
        }

        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }

        case types.SET_FILTER: {
            return {
                ...state,
                filter: payload,
            };
        }

        // телеметрия
        case types.SET_WS_DATA: {
            let isReplace = false;
            const devices = payload?.reduce((res, item) => {
                const index = res?.findIndex(({ device_id = 0 }) => device_id === item?.device_id);
                if (index >= 0) {
                    isReplace = true;
                    return [
                        ...res.slice(0, index),
                        {
                            ...item
                        },
                        ...res.slice(index + 1),
                    ];
                }
                return res;
            }, state.dataDevice || []);
            if (isReplace) {
                return {
                    ...state,
                    dataDevice: devices,
                };
            }
            return state;
            // const id = payload?.id || null;
            // if (id) {
            //     const index = state?.dataDevice?.findIndex(({ device_id }) => id === device_id);
            //
            //     if (index >= 0) {
            //         return {
            //             ...state,
            //             dataDevice: [
            //                 ...state.dataDevice.slice(0, index),
            //                 {
            //                     ...payload
            //                 },
            //                 ...state.dataDevice.slice(index + 1),
            //             ]
            //         };
            //     }
            // }
            // return state;
        }
        case types.LOADED_ECOLOGY_SETTINGS: {
            return {
                ...state,
                ecologySettings: payload
            };
        }
        case types.LOADING_ECOLOGY_SETTINGS: {
            return {
                ...state,
                loadingEcologySettings: payload
            };
        }

        case types.LOADED_ECOLOGY_ALERTS: {
            return {
                ...state,
                alerts: payload,
            };
        }

        case types.LOADED_ECOLOGY_OUTPUT_PARAMS: {
            return {
                ...state,
                ecologyOutputParams: payload,
            };
        }

        case types.LOADED_ECOLOGY_OUTPUT_SETTINGS: {
            return {
                ...state,
                ecologyOutputSettings: payload
            };
        }

        case types.LOADING_ECOLOGY_OUTPUT_SETTINGS: {
            return {
                ...state,
                loadingEcologyOutputSettings: payload
            };
        }

        case types.LOADED_DEVICES_GROUP: {
            return {
                ...state,
                devicesGroup: payload
            };
        }

        case types.LOADING_DEVICES_GROUP: {
            return {
                ...state,
                loadingDevicesGroup: payload
            };
        }

        case types.LOADING_BUTTON: {
            return {
                ...state,
                loadingButton: payload
            };
        }

        case types.LOADED_SHOWCASE: {
            return {
                ...state,
                showcase: payload
            };
        }

        case types.LOADING_SHOWCASE: {
            return {
                ...state,
                loadingShowcase: payload
            };
        }

        case types.LOADED_ECO_BY_RADIUS: {
            return {
                ...state,
                listByRadius: payload
            };
        }

        case types.LOADED_SETTINGS_GROUP:
            return {
                ...state,
                settingsGroup: payload
            };

        case types.LOADING_SETTINGS_GROUP:
            return {
                ...state,
                loadingSettingsGroup: payload
            };

        case types.LOADED_DEVICE_STATUSES:
            return {
                ...state,
                deviceStatuses: payload
            };

        default:
            return state;
    }
}
