import { useDispatch, useSelector } from 'react-redux';
import { loadMenuData, saveActiveLayer, addAttachLayers, setSelectedLayer } from '../../redux/Menu/actions';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { config } from '../../config';
import { authSelectors } from '../../redux/Auth';
import { menuSelectors } from '../../redux/Menu';

// хук работы со слоями меню
const useMenu = () => {
    const currentProject = config?.project?.code;
    const systemName = config.getGeneral('systemName');
    const dispatch = useDispatch();
    const location = useLocation();
    const activeLayers = useSelector(menuSelectors.activeLayers);
    const attachList = useSelector(menuSelectors.attachList);
    const authUser = useSelector(authSelectors.authUser);
    const userLastProject = authUser?.info?.service_last?.[systemName]?.project;
    // загружаем меню при старте приложения
    useEffect(() => {
        // если у юзера есть сохраненный проект и он сопадает с проектом приложения
        if (currentProject === userLastProject) {
            dispatch(loadMenuData());
        }
    }, [currentProject, userLastProject, dispatch]);

    const findUniqElements = (array1, array2) => array1.reduce((res, el) => {
        if (!res.includes(el)) {
            res.push(el);
        }
        return res;
    }, array2);

    useEffect(() => {
        // слушаем location
        if (location.pathname === '/map') {
            // если это карта, разбираем урл и всё что лежит в layers кладем в стор в attachLayers
            const ulrQueryObject = queryString.parse(location.search, { arrayFormat: 'bracket' });
            const ulrQueryObjectLayers = ulrQueryObject?.layers || [];
            // собираем уникальные из урла + закрепленные
            const uniqQueryLayers = findUniqElements(ulrQueryObjectLayers, attachList);
            // собираем уникальные из закрепленных и активных
            const uniqActiveLayers = findUniqElements(uniqQueryLayers, activeLayers);
            // location?.state?.layer - последний открытый слой, 
            // после перезагрузки страницы он сохраняется в location
            // если он есть, но при этом его нет в uniqActiveLayers - добавляем его в активные и делаем его selected
            if (location?.state?.layer && !uniqActiveLayers.includes(location?.state?.layer)) {
                uniqActiveLayers.push(location?.state?.layer);
            }
            dispatch(setSelectedLayer(location?.state?.layer || null));
            // параметры из урла всегда идут в закрепленные
            dispatch(addAttachLayers(uniqQueryLayers));
            // параметры из урла + незакрепленные слои идут в активные
            dispatch(saveActiveLayer(uniqActiveLayers));
        }
    }, [location, attachList, activeLayers, dispatch]);  
};

export default useMenu;
