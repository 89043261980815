const dateFormat = {
    FULL_DATE: 'DD.MM.YYYY HH:mm:ss',
    DATE_WITH_HOUR_MIN_SECONDS: 'dd.MM.yyyy HH:mm:ss',
    DATE_WITH_HOUR_MIN: 'DD.MM.YYYY HH:mm',
    EXCEPT_SECONDS: 'DD.MM.YYYY HH:mm',
    FULL_DATE_WITH_DASH: 'yyyy-MM-DD HH:mm:ss',
    FULL_DATE_WITH_UTC: 'YYYY-MM-DDTHH:mm:ssZZ',
    FULL_DATE_WITH_COLON: 'YYYY-MM-DDTHH:mm:ssZ',
    FULL_DATE_WITH_UTC_MODIFIED: 'YYYY-MM-DDTHH:mm:ss-HH:MM',
    FULL_DATE_WITH_COL: 'yyyy-MM-dd HH:mm:ss',
    DT_EXCEPT_SECONDS: 'dd.MM.yyyy HH:mm',
    DATE: 'DD.MM.YYYY',
    DATE_FOR_PICKER: 'dd.MM.yyyy',
    Y_M_D_WITH_DOT: 'YYYY.MM.DD',
    MONTH_FOR_PICKER: 'MM.yyyy',
    DATE_WITH_DASH: 'YYYY-MM-DD',
    LLL: 'LLL',
    YEAR: 'YYYY',
    MONTH_YEAR: 'MMMM.YYYY',
    YEAR_MONTH: 'YYYY-MM',
    HH_MM: 'HH:mm',
    TIME_EXCEPT_SECONDS: 'HH:mm',
    HH_MM_SS: 'HH:mm:ss',
    HH_MM_SS_Z: 'HH:mm:ssZ',
};

export default dateFormat;
