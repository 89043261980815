import { Children } from 'react';

const renderChild = (
    children,
    params = {}
) => Children
    // конвертим в массив
    .toArray(children)
    // оставляем нужные
    .reduce((r, child, index) => child?.type
        ? [
            ...r,
            <child.type
                key={index}
                {...child.props}
                {...params}
            />
        ]
        : r
    ,[]);

export default renderChild;