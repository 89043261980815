import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';

import DateRange from '../../../../common/Dates/DateRange';
import titles from '../../../../../helpers/constants/titles';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';

const Filter = ({
    categories,
    setFilter,
    resetFilter,
    filter,
    getReport,
    setIsDisabled,
    isDisabled,
    initialStateFilter
}) => {

    const updateFilter = (filters) => {
        setFilter({
            ...initialStateFilter,
            ...filters,
        });
        if (Object.keys(filter).length > 0) {
            getReport({
                ...initialStateFilter,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={getReport}
            disabled={isDisabled}
            filter={filter} 
            filterException={['start_date', 'end_date',]} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <FormControl size="small" variant="outlined">
                    <InputLabel>{titles.CATEGORY_VEHICLE}</InputLabel>

                    <Select
                        value={filter.category_id}
                        onChange={ (e) => {
                            setFilter({ ...filter, category_id: e.target.value });
                        }}
                        label={titles.CATEGORY_VEHICLE}
                    >
                        <MenuItem value={''}>
                            {titles.NOT_CHOSEN}
                        </MenuItem>
                        {categories.map((item, index) => (
                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <DateRange
                    handleDate={(newDate) => {
                        setFilter({ ...filter, start_date: newDate });
                    }}
                    handleEndDate={(newDate) => {
                        setFilter({ ...filter, end_date: newDate });
                    }}
                    valueStartDate={filter.start_date}
                    valueEndDate={filter.end_date}
                    isDisabledHandler={(value) => setIsDisabled(value)}
                    dateOnly
                />
                <SelectCompany
                    multiple
                    selected={filter.organization_id}
                    onChange={(value) => setFilter({ ...filter, organization_id: value })}
                    filter={{ withDeleted: 1 }}
                    selectDeletedCompanies
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
