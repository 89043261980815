import React, { useEffect, useMemo } from 'react';
import Filters from './Filters';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../../helpers/constants/messages';
import * as actions from '../../../../../redux/VideoAnalytics/actions';
import { useStoreProp } from '../../../../../helpers/hooks';
import Item from './Item';
import { typeOptions } from './helper';
import Loading from '../../../../common/Loading';
import { videoAnalyticsSelectors } from '../../../../../redux/VideoAnalytics';
import { LSContainer, LSHeaderColumn, LSHeaderItem } from '../../../../common/ListStyle';
import PageLayout from '../../../../layout/PageLayout';
import NoData from '../../../../common/Information/NoData';

const PassengerTraffic = () => {
    const dispatch = useDispatch();

    const { data, success } = useSelector(videoAnalyticsSelectors.passengerTrafficList);
    const filter = useSelector(videoAnalyticsSelectors.passengerTrafficFilter);
    const loading = useSelector(videoAnalyticsSelectors.passengerTrafficLoading);

    const typesList = useStoreProp(actions.loadPassengerTrafficTypes, 'videoAnalytics', 'passengerTrafficTypesList');

    const list = useMemo(() => Object.keys(data || {}), [data]);

    const currentType = useMemo(() => {
        return typesList?.find(({ value }) => value === filter?.type) || {};
    }, [filter?.type, typesList]);

    const currentOption = useMemo(() => {
        return typeOptions[currentType?.value] || typeOptions?.default;
    }, [currentType]);

    const currentStation = useMemo(() => {
        return filter
            ?.station_id
            ?.reduce((res, item) => ({
                ...res,
                [item?.id]: item,
            }), {});
    }, [filter?.station_id]);

    const handleShowTable = (filter) => {
        dispatch(actions.clearPassengerTraffic());
        dispatch(actions.loadPassengerTraffic(filter));
    };

    const handleClear = () => {
        dispatch(actions.clearPassengerTraffic());
    };

    useEffect(() => {
        return () => {
            dispatch(actions.clearPassengerTraffic());
        };
    }, []);

    return (
        <>
            <PageLayout
                header="Отчет по Пассажиропотоку"
                filters={<Filters
                    typesList={typesList}
                    onTable={handleShowTable}
                    onClear={handleClear}
                />}
                content={() => (
                    <>
                        {loading && <Loading circular={true}/>}

                        {list.length > 0
                            ? (<>
                                <LSContainer>
                                    <LSHeaderItem>
                                        <LSHeaderColumn>
                                            {`Пассажиропоток - ${currentType?.description}` || ''}
                                        </LSHeaderColumn>
                                        <LSHeaderColumn align="right" isActions>
                                                Действия
                                        </LSHeaderColumn>
                                    </LSHeaderItem>

                                    {list.map(key => (
                                        <Item
                                            key={key}
                                            list={data[key]}
                                            station={currentStation[key]}
                                            currentType={currentType}
                                            currentOption={currentOption}
                                        />
                                    )
                                    )}
                                </LSContainer>
                            </>)
                            : ((!loading && success) && <NoData message={messages.DATA_IS_NOT_FOUND_NEW}/>)
                        }
                    </>
                )}
            />
        </>
    );
};

export default PassengerTraffic;
