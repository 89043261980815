import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { loadComplexStatuses } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import { useStoreFromSelector } from 'helpers/hooks';
import useComplexById from 'helpers/hooks/DorisControl/useComplexById';
import colorExtend from 'helpers/mapHelper/colorExtend';
import Loading from 'components/common/Loading';
import TextWrap from 'components/MapComponents/helpers/TextWrap';

import icon from '../icons/icon';

import Information from './Information';

const PopUp = (props) => {
    const {
        external_id,
        history,
        prop = 'polygon',
        showButtons = true,
    } = props;

    const complexByIdProvider = useComplexById();

    useEffect(() => {
        if (external_id && prop === 'polygon') {
            complexByIdProvider.load(external_id);
        }
    }, [external_id, prop]);

    useEffect(() => {
        return () => {
            complexByIdProvider.clear();
        };
    }, []);

    const stateData = useSelector(({ dorisControl }) => dorisControl[prop]);
    const data = stateData.find(({ id }) => external_id === id) || {};

    const complexStatuses = useStoreFromSelector(loadComplexStatuses, dorisControlSelectors.doris_complex_statuses);
    const complexStatusesObj = useMemo(() => complexStatuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [complexStatuses]);

    const status = data?.status || 0;
    const { name } = data;

    const title = `${name} ${complexStatusesObj[status] ? `(${complexStatusesObj[status]?.name || ''})` : ''}`;
    const notValid = data?.is_not_valid_verification;

    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: colorExtend(complexStatusesObj[status]?.color || '') }}>
                <div
                    className="img"
                    dangerouslySetInnerHTML={{
                        __html: notValid === null
                            ? '<i class="far fa-file-certificate" style="color: #aaaaaa; font-size: 16px;"/>'
                            : notValid
                                ? '<i class="fad fa-file-certificate" style="--fa-primary-color: #d32f2f; --fa-secondary-color: #aaaaaa; font-size: 16px;"/>'
                                : icon({ fill: '#fff' })
                    }}
                />
                <TextWrap
                    title={title}
                    style={{
                        marginLeft: '1rem',
                        color: 'white',
                        fontWeight: 'bold',
                        lineHeight: '1',
                        fontSize: '1rem',
                    }}
                />
            </div>
            <div className="map-popup_body">
                {complexByIdProvider.loading && <Loading linear={true} />}

                <Information
                    data={{
                        ...complexByIdProvider.data,
                        ...data
                    }}
                    history={history}
                    showButtons={showButtons}
                    disabledButtons={complexByIdProvider.loading}
                    onEdited={() => {
                        // редактирование комплекса
                        external_id && complexByIdProvider.load(external_id);
                    }}
                />

            </div>
        </div>
    );
};

export default PopUp;
