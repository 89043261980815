import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editCameraSettings, loadCameraSettings } from '../../../../redux/VideoAnalytics/actions';
import styles from './cameraSettings.module.scss';
import { TextField } from '@mui/material';
import Loading from '../../../common/Loading';
import FormButtonsComponent, { buttonsTypes } from '../../../common/FormButtons';
import { videoAnalyticsSelectors } from '../../../../redux/VideoAnalytics';

function CameraSettings() {
    const dispatch = useDispatch();
    
    const settings = useSelector(videoAnalyticsSelectors.settings);
    const settingsLoading = useSelector(videoAnalyticsSelectors.settingsLoading);

    const [value, setValue] = useState([]);

    const handleChange = (id, e) => {
        const newValue = value.map(item => {
            if (id === item.id) {
                return { ...item, value: e.target.value };
            }
            return item;
        });
        setValue(newValue);
    };

    const handleCancel = (id) => {
        const prevValue = settings.find(item => item.id === id).value;
        const newValue = value.map(item => {
            if (id === item.id) {
                return { ...item, value: prevValue };
            }
            return item;
        });
        setValue(newValue);
    };

    const handleSend = (id) => {
        const item = value.find(item => item.id === id);
        dispatch(editCameraSettings(
            id,
            { value: Number(item.value) }
        ));
    };

    useEffect(() => {
        if (settings.length === 0) {
            dispatch(loadCameraSettings());
        }
    }, [dispatch, settings.length]);

    useEffect(() => {
        if (settings.length !== 0 && value.length === 0) {
            setValue(settings);
        }
    }, [settings, value.length]);

    return (
        <div>
            {!settingsLoading && settings.length === 0 && <div>Нет данных</div>}
            {settingsLoading
                ? <Loading circular={true}/>
                : value?.map(item =>
                    <div key={item.id} className={styles.camera_settings}>
                        <div className={styles.title}>
                            <div className={styles[item?.name]}>
                                {item?.description}:
                            </div>
                        </div>
                        <div className={styles.form_block}>
                            <div className={styles.form}>
                                <TextField
                                    type="number"
                                    disabled={!item.is_visible}
                                    size="small"
                                    variant="outlined"
                                    value={item?.value}
                                    onChange={(e) => handleChange(item.id, e)}
                                />
                            </div>
                            <div className={styles.form}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    value={item?.measurement}
                                    disabled
                                />
                            </div>
                            <div className={styles.btn}>
                                <FormButtonsComponent
                                    noPadding
                                    buttons={[
                                        {
                                            ...buttonsTypes.cancel,
                                            disabled: item.value === settings.find(setting => setting.id === item.id).value,
                                            onClick: () => handleCancel(item.id),
                                            size: 'small'
                                        },
                                        {
                                            ...buttonsTypes.save,
                                            onClick: () => handleSend(item.id),
                                            size: 'small'
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default CameraSettings;
