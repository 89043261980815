import { fullDateTime, getHumanDate } from '../../../../../helpers/date.config';
import messages from '../../../../../helpers/constants/messages';

const Item = ({ item = {}, status }) => {
    const { id, name, address_text, created_at, troubleshooting_plan, troubleshooting_fact } = item;

    return (
        <div key={id} className="block">
            <span>Название: {name || messages.NO_DATA}</span>
            <span>Адрес: {address_text || messages.NO_DATA}</span>
            <span>Дата создания: {fullDateTime(created_at) || messages.NO_DATA}</span>
            <span>Статус: {status || messages.NO_DATA}</span>
            <span>Дата окончания
                плановая: {getHumanDate(troubleshooting_plan) || messages.NO_DATA}</span>
            <span>Дата окончания
                фактическая: {getHumanDate(troubleshooting_fact) || messages.NO_DATA}</span>
        </div>
    );
};

export default Item;