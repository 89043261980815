import { useState } from 'react';

import { ActionMore, LSContentColumn, LSContentItem } from 'components/common/List';
import messages from 'helpers/constants/messages';

import InfoList from './InfoList';

const Item = ({ item, index }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <LSContentItem style={{ cursor: 'pointer', backgroundColor: index % 2 === 0 ? '#fff' : '#f0f1fa' }} onClick={() => setOpen(!open)}>
                <LSContentColumn>{item.id || messages.NO_VALUE}</LSContentColumn>

                <LSContentColumn isActions>                    
                    <ActionMore isOpen={open} />
                </LSContentColumn>
            </LSContentItem>
            <InfoList isOpen={open} list={item?.flight_list} />
        </>
    );
};

export default Item;
