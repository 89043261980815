import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { getUsers } from 'redux/Admin/actions';
import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import type { GetListParams, UserItem } from './types';
import type { CommonAutocompleteProps } from '../CommonAutocomplete';

interface SelectUsersProps<UserItem> extends CommonAutocompleteProps<UserItem> {
  filter?: any,
}

const SelectUsers = ({
    multiple = false,
    selected = multiple ? [] : {},
    onChange = (e) => {},
    required = false,
    disabled = false,
    label=titles.USER,
    error = false,
    helperText = '',
    limitTags = 1,
    filter = {},
} : SelectUsersProps<UserItem>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);

    const getList = (params: GetListParams) => {
        const { page, limit, query: name } = params;

        dispatch(getUsers({
            page, 
            limit, 
            ...(name && { name }),
            ...filter,
        }));
    };

    const onReset = () => {
        const initialState = multiple ? [] : '';
        onChange(initialState);
    };

    const handleClose = () => setShowList(false);

    const handleAccept = (data: any) => {
        onChange(data);
        setShowList(false);
    };

    return (
        <>
            <CommonAutocomplete<UserItem>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                error={error}
                helperText={helperText}
                required={required}
                disabled={disabled}
                renderLabel={(option) => option?.first_name ? `${option?.first_name} ${option?.middle_name ? option?.middle_name : ''} ${option?.last_name}` : ''}
                limitTags={limitTags}
                label={label}
                onReset={onReset}
                onShowList={() => setShowList(true)}
            />
            {showList 
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName={'admin'}
                    storeNameProps={'users'}
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selected}
                    renderProps={({ first_name, middle_name, last_name, id }) =>
                        <span key={id}>
                            {first_name || ''} {middle_name || ''} {last_name || ''}
                        </span>
                    }
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={titles.USER}
                    small
                />
            }
        </>
    );
};

export default SelectUsers;
