import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import { usePrevious } from '../../../../../helpers/hooks';
import { isEqual } from 'lodash';
import { decoratorOptions } from '../helper';


const PhaseGeoJson = (props) => {
    const {
        map,
        // parent,
        data,
    } = props;

    const prevData = usePrevious(data);
    const ref = useRef(null);
    const decorations = useRef([]);

    const style = {
        color: '#3B9A58',
        weight: 3,
        opacity: 1.0,
        smoothFactor: 1
    };

    const onEachFeature = (feature, layer) => {
        const polylineArrow = new L.polylineDecorator(layer, decoratorOptions());

        polylineArrow.addTo(map);
        decorations.current = [
            ...decorations.current,
            polylineArrow
        ];
    };

    const clear = () => {
        if (ref.current) {
            ref.current.clearLayers();
            map.removeLayer(ref.current);
            ref.current.remove();

            decorations.current.map((item) => {
                map.removeLayer(item);
            });
            decorations.current = [];
        }
    };

    useEffect(() => {
        if (!isEqual(prevData, data)) {
            clear();

            const json = L.geoJSON(data, {
                style: (feature) => {
                    return {
                        ...(style || {}),
                    };
                },
                /*style: function (feature) {
                    return { color: feature.properties.color };
                },*/
                onEachFeature
            });

            json.addTo(map);

            ref.current = json;
        }
    }, [data, map, prevData]);

    useEffect(() => {
        return clear;
    }, []);

    return null;
};

export default PhaseGeoJson;