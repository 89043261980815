import { List, ListItem, ListItemText, FormControl, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../../helpers/constants/messages';
import titles from '../../../../../helpers/constants/titles';
import { loadStatusesVideoCamera, loadVideoCamerasList } from '../../../../../redux/VideoCameras/actions';
import CustomPagination from '../../../../common/CustomPagination';
import { useDebounce } from '../../../../../helpers/hooks';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';

const useStyles = makeStyles({
    sideBar: {
        flex: 1,
        overflowY: 'scroll',
        maxHeight: 500,
    }
});

const ModalSideBar = ({ camerasForGroup, formData }) => {
    const dispatch = useDispatch();
    const styles = useStyles();
    const {
        videoCameraList,
        loadingVideoCameraList,
        statuses
    } = useSelector(({ videoCameras }) => videoCameras);

    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);
    const [query, setQuery] = useState('');
    const isSelect = (id) => formData.cameras.find(camera => camera.id === id);

    const load = (page, limit) => dispatch(loadVideoCamerasList(page, limit, { name: query }));

    const debounceProvider = useDebounce(({ page, limit }) => load(page, limit), 500);

    useEffect(() => {
        debounceProvider({ page: 1, limit });
    },[dispatch, query]);

    useEffect(() => {
        dispatch(loadStatusesVideoCamera());
    }, [dispatch]);

    return (
        <div style={{ width: '100%' }}>
            <FormControl required className="block" size="small" variant="outlined">
                <TextField
                    label="Поиск по названию камеры"
                    variant="outlined"
                    size="small"
                    value={query || ''}
                    name={'name'}
                    onChange={(e) => setQuery(e.target.value)}
                    type="text"
                    InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                />
            </FormControl>

            <List className={cn('list', styles.sideBar)}>
                {videoCameraList.data.length > 0
                    ? (<>
                        {videoCameraList.data.map(el => (
                            <ListItem
                                className="list_item"
                                key={el.id}
                                el={el}
                                statuses={statuses}
                                onClick={() => camerasForGroup(el)}
                                disabled={!!isSelect(el.id)}
                            >
                                <ListItemText>
                                    <div>
                                        <span><strong>{titles.CAMERA}:</strong> {el.name || messages.NO_DATA}</span>
                                    </div>
                                </ListItemText>
                            </ListItem>
                        ))}
                        <CustomPagination
                            loadList={(page, limit) => load(page, limit)}
                            limit={limit}
                            setLimit={setLimit}
                            list={videoCameraList.meta}
                            isWrap
                        />
                    </>)
                    : !loadingVideoCameraList && <div>{messages.DATA_IS_NOT_FOUND}</div>
                }
            </List>
        </div>
    );
};

export default ModalSideBar;