import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { Grid } from '@mui/material';

import { loadRepairObjects } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import PageLayout from 'components/layout/PageLayout';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer, LSHeaderColumn, LSHeaderItem } from 'components/common/ListStyle';
import PassportTabs from 'components/common/PassportTabs';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';

import Filter from './Filter';
import ModalForm from './ModalForm';
import Item from './Item';
import Indicators from './PassportPage/Indicators';
import Repairs from './PassportPage/Repairs';

const RepairObjects = () => {
    const dispatch = useDispatch();

    const data = useSelector(roadworksSelectors.repairObjectsData);
    const meta = useSelector(roadworksSelectors.repairObjectsMeta);
    const loading = useSelector(roadworksSelectors.loadingRepairObjects);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, query: {} });
    const [openAddModal, setOpenAddModal] = useState(false);
    const [currentObject, setCurrentObject] = useState(null);

    const reloadList = (isDelete) => {
        const page = isDelete && data?.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadRepairObjects(params.page, limit, params.query));
    }, [dispatch, limit, params]);

    const onClickItem = (item) => {
        const isEqual = item.id === currentObject?.id;
        setCurrentObject(isEqual ? null : item);
    };

    const renderContent = () => {
        const renderList = () => (
            <LSContainer>
                <LSHeaderItem isMobile={!!currentObject} fullWidth>
                    <LSHeaderColumn width="30%">{titles.NAME}</LSHeaderColumn>
                    <LSHeaderColumn align="right" isActions>Действия</LSHeaderColumn>
                </LSHeaderItem>
                {data?.map(item => (
                    <Item
                        key={item?.id}
                        item={item}
                        onClickItem={() => onClickItem(item)}
                        selectedItem={item?.id === currentObject?.id}
                        isMobile={!!currentObject}
                        reloadList={reloadList}
                    />
                ))}
            </LSContainer>
        );

        return (
            <div style={{
                minWidth: '60%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                zIndex: '1',
            }}>
                {data?.length > 0
                    ? (
                        <Grid container style={currentObject ? { height: '100%' } : {}}>
                            <Grid item xs={ currentObject ? 3 : 12 } style={{ height: '100%' }}>
                                {currentObject
                                    ? (
                                        <Scrollbars style={{ height: '100%' }} >
                                            {renderList(currentObject)}
                                        </Scrollbars>
                                    )
                                    : renderList(currentObject)
                                }
                            </Grid>
                            {currentObject && (
                                <Grid item xs={9}>
                                    <PassportTabs tabs={[
                                        {
                                            value: 'indicators',
                                            label: 'Плановые показатели',
                                            icon: <i className="far fa-pencil-ruler"/>,
                                            contentComponent: <Indicators currentObject={currentObject} reloadList={reloadList}/>,
                                        },
                                        {
                                            value: 'repairs',
                                            label: 'Нормативные ремонты',
                                            icon: <i className="far fa-hammer"/>,
                                            contentComponent: <Repairs currentObject={currentObject} reloadList={reloadList}/>
                                        },
                                    ]}/>
                                </Grid>
                            )}
                        </Grid>
                    )
                    : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
                }
            </div>
        );
    };

    return (
        <>
            <PageLayout
                header="Типовые объекты ремонта"
                filters={<Filter setParams={setParams} setCurrentObject={setCurrentObject}/>}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenAddModal(true)
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => {setCurrentObject(null); setParams({ ...params, page });},
                    list: meta,
                    limit,
                    setLimit
                }}
                customStyles={{
                    overflowY: currentObject ? 'hidden' : 'scroll',
                    ...(currentObject && { padding: 0 })
                }}
            />

            {openAddModal && (
                <ModalForm
                    open={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default RepairObjects;
