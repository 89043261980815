export default [
    // {
    //     key: 'station_name',
    //     label: 'Остановка',
    // },
    {
        key: 'time_start',
        label: 'Время',
    },
    {
        key: 'vehicle_type_name',
        label: 'Тип ТС',
    },
    {
        key: 'route_num',
        label: 'Номер маршрута',
    },
    {
        key: 'route_name',
        label: 'Название маршрута',
    },
    {
        key: 'route_type',
        label: 'Тип маршрута',
    },
];
