export const incidents = (state) => state.incidents;

export const list = (state) => incidents(state).list;
export const listData = (state) => list(state).data;
export const listMeta = (state) => list(state).meta;

export const dtp_types = (state) => incidents(state).dtp_types;
export const dtpTypesData = (state) => dtp_types(state).data;
export const dtpTypesMeta = (state) => dtp_types(state).meta;
export const loadingDtpTypes = (state) => incidents(state).loadingDtpTypes;
export const loadingDtpFile = (state) => incidents(state).loadingDtpFile;

export const loading = (state) => incidents(state).loading;

export const dtpReportFilter = (state) => incidents(state).dtpReportFilter;
export const polygon = (state) => incidents(state).polygon;
export const polygonLoading = (state) => incidents(state).polygonLoading;
export const dtpById = (state) => incidents(state).dtpById;
export const polygonVisible = (state) => incidents(state).polygonVisible;
export const filters = (state) => incidents(state).filters;
export const streetList = (state) => incidents(state).streetList;
export const active = (state) => incidents(state).active;
// export const severity = (state) => incidents(state).severity;
// export const loadTypes = (state) => incidents(state).loadTypes;

export const settings = (state) => incidents(state).settings;
// export const settingsData = (state) => settings(state).data;
// export const settingsMeta = (state) => settings(state).meta;
// export const loadingDtpSettings = (state) => incidents(state).loadingDtpSettings;
export const settingById = (state) => incidents(state).settingById;
export const settingByName = (state) => incidents(state).settingByName;


export const dtpGraph = (state) => incidents(state).dtpGraph;
// export const dtpGraphLoading = (state) => incidents(state).dtpGraphLoading;

export const dtpStreets = (state) => incidents(state).dtpStreets;
export const dtpStreetsLoading = (state) => incidents(state).dtpStreetsLoading;

export const deleteForm = (state) => incidents(state).deleteForm;
export const editForm = (state) => incidents(state).editForm;
export const saved = (state) => incidents(state).saved;

// export const statuses = (state) => incidents(state).statuses;
// export const loadingStatuses = (state) => incidents(state).loadingStatuses;

export const dtpReportByArea = (state) => incidents(state).dtpReportByArea;
// export const loadingDtpReportByArea = (state) => incidents(state).loadingDtpReportByArea;
export const dtpByAreaReportFilter = (state) => incidents(state).dtpByAreaReportFilter;

export const dtpReportByPercent = (state) => incidents(state).dtpReportByPercent;
export const loadingDtpReportByPercent = (state) => incidents(state).loadingDtpReportByPercent;
export const dtpByPercentReportFilter = (state) => incidents(state).dtpByPercentReportFilter;

export const dangersArea = (state) => incidents(state).dangersArea;
export const loadingDangersArea = (state) => incidents(state).loadingDangersArea;
export const dangersAreaReportFilter = (state) => incidents(state).dangersAreaReportFilter;

export const reportMonthAreas = (state) => incidents(state).reportMonthAreas;
export const loadingReportMonthAreas = (state) => incidents(state).loadingReportMonthAreas;

export const reportStatistic = (state) => incidents(state).reportStatistic;
export const loadingReportStatistic = (state) => incidents(state).loadingReportStatistic;

export const reportByCategory = (state) => incidents(state).reportByCategory;
export const loadingReportByCategory = (state) => incidents(state).loadingReportByCategory;
export const reportCategoryFilter = (state) => incidents(state).reportCategoryFilter;
// export const reportCategory = (state) => incidents(state).reportCategory;
// export const reportCategoryColor = (state) => incidents(state).reportCategoryColor;
export const reportCategoryTable = (state) => incidents(state).reportCategoryTable;
export const loadingReportCategoryTable = (state) => incidents(state).loadingReportCategoryTable;

export const newMap = (state) => incidents(state).newMap;
export const newMapLoading = (state) => incidents(state).newMapLoading;

export const population = (state) => incidents(state).population;
export const populationData = (state) => population(state).data;
export const populationMeta = (state) => population(state).meta;
export const loadingPopulation = (state) => incidents(state).loadingPopulation;

export const concentrationArea = (state) => incidents(state).concentrationArea;
export const concentrationAreaData = (state) => concentrationArea(state).data;
export const concentrationAreaMeta = (state) => concentrationArea(state).meta;
export const loadingConcentrationArea = (state) => incidents(state).loadingConcentrationArea;
export const loadingCedConcentrationArea = (state) => incidents(state).loadingCedConcentrationArea;

export const concentrationAreaStats = (state) => incidents(state).concentrationAreaStats;
export const concentrationAreaTypes = (state) => incidents(state).concentrationAreaTypes;
export const concentrationAreaStatuses = (state) => incidents(state).concentrationAreaStatuses;
export const dtpOnRadius = (state) => incidents(state).dtpOnRadius;
export const loadingDtpOnRadius = (state) => incidents(state).loadingDtpOnRadius;

export const showcase = (state) => incidents(state).showcase;
export const loadingShowcase = (state) => incidents(state).loadingShowcase;

export const mkdtpModalData = (state) => incidents(state).mkdtpModalData;
export const mkdtpFilterParams = (state) => incidents(state).mkdtpFilterParams;

export const dtpByTime = (state) => incidents(state).dtpByTime;
export const loadingDtpByTime = (state) => incidents(state).loadingDtpByTime;
