import * as types from './types';

const initialState = {
    uploading: false,
    uploadFileError: null,
    uploadedVehicleFile: null,
    uploadedRoadWorkFile: null,
    uploadedTransportIncidentFile: null,
    CSODDFile: null,
    roadLabTypeList: []
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.UPLOADING:
            return {
                ...state,
                uploading: payload,
            };

        case types.UPLOAD_FILE_ERROR:
            return {
                ...state,
                uploadFileError: payload,
            };
            
        case types.SET_UPLOAD_VEHICLE_FILE:
            return {
                ...state,
                uploadedVehicleFile: payload,
            };

        case types.SET_UPLOAD_ROADWORK_FILE:
            return {
                ...state,
                uploadedRoadWorkFile: payload,
            };
        
        case types.SET_UPLOAD_TRANSPORT_INCIDENT_FILE:
            return {
                ...state,
                uploadedTransportIncidentFile: payload,
            };

        case types.LOADED_CSODD_FILE:
            return {
                ...state,
                CSODDFile: payload,
            };

        case types.LOADED_ROAD_LAB_TYPES:
            return {
                ...state,
                roadLabTypeList: payload,
            };
        default:
            return state;
    }
}
