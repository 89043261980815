import {
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
} from '@mui/material';

// список кнопок как в поповерах или меню на Действиях
const ActionList = ({ test_id_prefix, handleClose, list = [], }) => {
    return (<>
        {list.map(({
            onClick = () => {},
            icon,
            name,
            accessProp,
            testName,
            ...buttonProps
        }, index) => (
            <MenuItem
                key={index}
                onClick={(e) => {
                    // обработка клика
                    onClick?.(e);
                    handleClose(e);
                }}
                size="small"
                data-testid={`${test_id_prefix}/${testName}/button`}
            >
                {icon && (
                    <ListItemIcon>
                        <IconButton
                            {...buttonProps}
                            size="small"
                        >
                            {icon}
                        </IconButton>
                    </ListItemIcon>
                )}
                {name && (
                    <ListItemText data-testid={`${test_id_prefix}/${testName}/label`} primary={name} />
                )}
            </MenuItem>
        ))}
    </>
    );
};

export default ActionList;
