import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadEquipmentsList } from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import messages from '../../../../../helpers/constants/messages';
import Loading from '../../../../common/Loading';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    wrap: {
        display: 'flex',
        flexDirection: 'column'
    }
});

const Equipment = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const equipments = useSelector(transportPassengerSelectors.equipments);
    const loadingEquipments = useSelector(transportPassengerSelectors.loadingEquipments);

    useEffect(() => {
        dispatch(loadEquipmentsList());
    },[dispatch]);

    return (
        <>
            <h1>Справочник дополнительного оборудования транспортных средств</h1>
            {loadingEquipments && <Loading linear={true}/>}
            {Object.keys(equipments.data)?.length > 0
                ? (<div className={classes.wrap}>
                    <strong>{equipments.data.bicycle_racks}</strong>
                    <strong>{equipments.data.caption}</strong>
                    <strong>{equipments.data.carnum}</strong>
                    <strong>{equipments.data.conditioning}</strong>
                    <strong>{equipments.data.low_floor}</strong>
                    <strong>{equipments.data.model}</strong>
                    <strong>{equipments.data.name}</strong>
                    <strong>{equipments.data.phone}</strong>
                    <strong>{equipments.data.places_for_strollers}</strong>
                    <strong>{equipments.data.serial}</strong>
                    <strong>{equipments.data.serial_egts}</strong>
                    <strong>{equipments.data.serial_yandex}</strong>
                    <strong>{equipments.data.uid}</strong>
                    <strong>{equipments.data.vin}</strong>
                    <strong>{equipments.data.wc}</strong>
                </div>)
                : !loadingEquipments && <div>{messages.DATA_IS_NOT_FOUND}</div>
            }
        </>
    );
};

export default Equipment;
