import L from 'leaflet';

import { getMarker } from 'components/MapComponents/marker/Marker';

import config from './config';

// получить иконку маркера
export const createIconMarker = (statusColor, category_id) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: `
        ${getMarker(statusColor)}
        <i class="${getCategoryIcon(category_id)}"
           style="width:100%;color:#fff;position:absolute;left:0;top:0;height:calc(100% - 5px);display:flex;justify-content:center;align-items:center;font-size:1.2rem;"
        />
    `,
    });
};

// получить иконку категории
export const getCategoryIcon = (category_id) => config.categoryIcon?.[category_id] || '';
