import React from 'react';

const TabInformation = ({ data, title }) => {
    return (
        <div className="scroll">
            {Object.keys(title).map((key) => {
                const titleObject = title[key] || {};
                const value = titleObject?.value ? titleObject.value(data /*data[key]*/) : data[key];

                if (value) {
                    return (
                        <div className="info__item" key={`information_${key}`}>
                            <span className="info__item-title">
                                {titleObject?.title || ''}
                                {': '}
                            </span>
                            <span>
                                {value}
                            </span>
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default TabInformation;
