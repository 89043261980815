import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemText, } from '@mui/material';

import { deleteDtpFocus, editDtpFocus } from 'redux/FocusIncidents/actions';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import ModalForm from 'components/MapComponents/Layers/DtpFocus/ModalForm';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

function Item({ data }) {
    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const toggleOpenModal = (e) => {
        e && e.stopPropagation();
        setOpenModal(!openModal);
    };

    const toggleOpenDeleteModal = (e) => {
        e && e.stopPropagation();
        setDeleteModal(!deleteModal);
    };

    const handleDelete = () => {
        dispatch(deleteDtpFocus(data.id));
        toggleOpenDeleteModal();
    };

    return (
        <>
            <ListItem className="list_item">
                <ListItemText>
                    <span><b>Адрес дислокации: </b>{data.address_text || '-'} </span>
                    <span><b>Принадлежность: </b>{data.accessory || '-'} </span>
                    <span><b>Дата регистрации: </b>{fullDateTimeWithoutSeconds(data.registered_at)}</span>
                </ListItemText>
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: toggleOpenModal,
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: toggleOpenDeleteModal,
                        },
                    ]}
                    noMarginLeft
                    justButton
                />

            </ListItem>
            {openModal
                && <ModalForm
                    data={data}
                    onAccept={(newFields) => {
                        dispatch(editDtpFocus(newFields.id, newFields));
                    }}
                    open={openModal}
                    onClose = {() => setOpenModal(false)}
                />
            }
            {deleteModal
                && <ConfirmDelete
                    open={deleteModal}
                    onClose={toggleOpenDeleteModal}
                    onSuccess={handleDelete}
                />
            }
        </>
    );
}

export default Item;
