import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ModelModal from './ModelModal';
import ConfirmDelete from '../../../../common/ConfirmModal';
import { deleteModel } from '../../../../../redux/TransportRoad/actions';
import messages from '../../../../../helpers/constants/messages';
import ModelListItem from '../../../../common/Transport/ModelList/Item';

const ModelItem = ({ item, reloadList, permissions }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    // const [openInfo, setOpenInfo] = useState(false);

    const toggleOpenEdit = (e) => {
        e && e.stopPropagation();
        setOpenEditModal(!openEditModal);
    };

    const toggleOpenDelete = (e) => {
        e && e.stopPropagation();
        setOpenDeleteModal(!openDeleteModal);
    };

    const handleDelete = () => {
        dispatch(deleteModel(item.id, () => {
            reloadList(true);
            toggleOpenDelete();
        }));
    };

    return (
        <>
            <ModelListItem
                item={item}
                toggleOpenEdit={toggleOpenEdit}
                toggleOpenDelete={toggleOpenDelete}
                permissions={permissions}
            />
            {openEditModal && (
                <ModelModal
                    onClose={toggleOpenEdit}
                    open={openEditModal}
                    data={item}
                    reloadList={reloadList}
                />
            )}
            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    message={messages.CONFIRM_DELETE}
                    onClose={toggleOpenDelete}
                    onSuccess={handleDelete}
                />
            )}
            {/* <ModelInfo isOpen={open} item={item} /> */}
        </>
    );
};

export default ModelItem;
