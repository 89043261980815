import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { loadCameraInfo } from 'redux/VideoAnalytics/actions';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';

import type { InfoList } from '../types';

interface SelectClassProps {
    value: string
    onChange: (value: string) => void
}

const SelectClass = ({ value, onChange }: SelectClassProps) => {
    const infoList: InfoList = useStoreProp(loadCameraInfo, 'videoAnalytics', 'infoList');

    const class_names = infoList?.class_name;

    return (
        <FormControl variant="outlined" size="small">
            <InputLabel>Класс</InputLabel>
            <Select
                value={value}
                onChange={(e) => onChange(e.target.value)}
                label="Класс"
                name="class_name"
            >
                {class_names?.length ? (
                    class_names?.map((item) => {
                        const value = Object.keys(item)?.map((key) => ({
                            label: item[key],
                            value: key,
                        }));
                        return (
                            <MenuItem value={value[0].value} key={value[0].value}>
                                {value[0].label}
                            </MenuItem>
                        );
                    })
                ) : (
                    <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default SelectClass;
