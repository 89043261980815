import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../redux/Dadata/actions';
import { useDebounce, usePrevious, useValidation } from '../../../../helpers/hooks';
import types from '../../../../helpers/constants/titles';
import Loading from '../../Loading';

// компонент возвращает полный объект адреса (address) и строку адреса (address_text)
const AddressOne = ({
    address_text: inputValue = '',
    onChange = () => {},
    required,
    disabled,
    isLoaded = true,
    // возвращать lat и lon
    isChangeLatLon = true,
    ...prop
}) => {
    const dispatch = useDispatch();
    const prevValue = usePrevious(inputValue);

    const [query, setQuery] = useState(inputValue || '');

    useEffect(() => {
        if (inputValue !== prevValue) {
            setQuery(inputValue);
        }
    }, [inputValue, prevValue]);

    const {
        address,
        loading,
    } = useSelector(({ dadata }) => dadata);

    const validation = useValidation(false);

    // запрос
    const handleFetch = (value) => {
        dispatch(actions.loadDadataAddress(value));
    };
    // задержка запроса
    const debounceFetch = useDebounce(handleFetch, 800);

    // Запрашиваем адрес с задержкой в секунду и сохраняем очередь setTimeOut в массив
    const handleChange = ({ target: { value } }) => {
        setQuery(value);
        if (isLoaded) {
            if (value.length > 0) {
                debounceFetch(value);
            } else {
                debounceFetch.clear();
            }
        }

        onChange({
            address_text: value,
            ...(isLoaded ? { address: {} } : {})
        });
        validation.deleteKey('address_text');
        isLoaded && validation.deleteKey('address');
    };

    useEffect(() => {
        if (Object.keys(address).length > 0) {
            const data = {
                address,
            };
            if (isChangeLatLon) {
                data.lat = address.geo_lat;
                data.lon = address.geo_lon;

                validation.deleteKeys(['lat', 'lon']);
            }
            onChange(data);
            dispatch(actions.clearDadataAddress());
        }
    }, [address, dispatch, onChange, isChangeLatLon]);

    const loader = {
        endAdornment:
            <InputAdornment position="end">
                <Loading circular={true} size={25} />
            </InputAdornment>
    };

    return (
        <TextField
            variant="outlined"
            label="Адрес дислокации"
            size="small"
            disabled={disabled}
            {...prop}
            value={query}
            onChange={handleChange}
            error={required && (validation.isKey('address_text') || query.length === 0)}
            helperText={validation.get('address_text') && types.INPUT_ADDRESS_NOT_FOUND}
            InputProps={loading ? loader : {}}
        />
    );
};

export default AddressOne;