/**
 * Sagas
 * **/
import {
    all, call, put, takeLatest
} from 'redux-saga/effects';
import { loading, loadedMenuData } from './actions';
import { setPermissions } from '../Auth/actions';
import api from './api.methods';
import * as types from './types';

function* loadMenuSaga() {
    yield put(loading(true));
    const response = yield call(api.getMenus);
    if (response?.success) {
        yield put(loadedMenuData(response.data));
        const createPermissionArray = (arr = []) => {
            return arr.reduce((
                res, 
                { link, layer, is_create, is_delete, is_read, is_update, children }
            ) => {
                if (link) {
                    res.push({
                        link,
                        layer,
                        permissions: {
                            is_create,
                            is_delete,
                            is_read,
                            is_update,
                        }
                    });
                } else if (children?.length > 0) {
                    return res.concat(createPermissionArray(children));
                }
                return res;
            }, []);
        };
        const permissions = createPermissionArray(response.data);
        yield put(setPermissions(permissions));
    }
    yield put(loading(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_MENU, loadMenuSaga)
    ]);
}
