import React, { useCallback, useState } from 'react';

import titles from 'helpers/constants/titles';
import {
    fullDateTimeWithTimeZone,
    getDateWithDuration,
    getEndOf,
    getStartOf
} from 'helpers/date.config';
import dateFormat from 'helpers/constants/dateFormat';
import removeEmptyFields from 'helpers/removeEmptyFields';
import DateRange from 'components/common/Dates/DateRange';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import SelectCameras from 'components/common/Autocomplete/CameraVideoanalytics';

const Filters = ({ setFilter }) => {
    const [cameras, setCameras] = useState([]);

    const initialState = {
        grz_list: [],
        start_date: getStartOf('day', getDateWithDuration({ months: -1 })),
        end_date: getEndOf('day'),
    };

    const [formValues, setFormValues] = useState(initialState);

    const paramsWithoutEmpty = useCallback((data) => {
        const result = {
            camera_id_list: cameras?.map(item => item.id),
            start_date: fullDateTimeWithTimeZone(data.start_date),
            end_date: fullDateTimeWithTimeZone(data.end_date),
        };

        return removeEmptyFields(result, false);
    }, [cameras]);

    const handleTsNums = (newValue) => {
        setFormValues({ ...formValues, grz_list: newValue });
    };

    const resetFilters = () => {
        setFormValues(initialState);
        setCameras([]);
        setFilter();
    };

    const handleChangeCamera = (values) => {
        setCameras(values);
    };

    const handleChangeDate = (value, name) => setFormValues({ ...formValues, [name]: value });

    const send = () => {
        setFilter(paramsWithoutEmpty(formValues));
    };

    return (
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">
                <DateRange
                    views={['day', 'hours', 'minutes', 'seconds']}
                    pickersFormat={dateFormat.DATE_WITH_HOUR_MIN_SECONDS}
                    valueStartDate={formValues.start_date}
                    valueEndDate={formValues.end_date}
                    handleDate={(date) => handleChangeDate(date, 'start_date')}
                    handleEndDate={(date) => handleChangeDate(date, 'end_date')}
                />
                <SelectCameras
                    multiple
                    selected={cameras}
                    onChange={handleChangeCamera}
                />
                <MultipleInput
                    label="Гос номер ТС"
                    value={formValues.grz_list}
                    onChange={handleTsNums}
                    guideText={titles.WRITE_TRANSPORT_NUMBER}
                />
            </div>
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetFilters
                        },
                        {
                            ...buttonsTypes.find,
                            onClick: send,
                            disabled: !formValues.start_date || !formValues.end_date
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default Filters;
