import React from 'react';
import { Box } from '@mui/material';
import styles from './styles.module.scss';

// контейнер витрины
const BoxContainer = ({
    children,
    style = {},
}) => (
    <Box className={styles.boxContainer} style={style}>
        {children}
    </Box>
);
export default BoxContainer;