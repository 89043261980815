import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemText, Typography } from '@mui/material';

import { deletePark } from 'redux/Parking/actions';
import { getHumanDate } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';
import type { ItemProps } from './types';

const Item = ({ item, tariffs, categories, reloadList }: ItemProps) => {
    const dispatch = useDispatch();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const currentCategory = categories.find(el => el.id === item?.category_id);

    return (<>
        <ListItem
            // disableGutters
            className="list_item"
            // style={ selectedItem ? { backgroundColor: '#f5f5f5' } : { backgroundColor: '' } }
        >
            <ListItemText>
                <Typography variant="subtitle2">
                    <strong>{titles.NAME}: </strong>
                    <span>{item?.name || messages.INFO_IS_NOT_FOUND}</span>
                </Typography>
                <Typography variant="subtitle2">
                    <strong>{'Категория'}: </strong>
                    <span>{currentCategory ? currentCategory.name_ru : messages.INFO_IS_NOT_FOUND}</span>
                </Typography>
                <Typography variant="body2">
                    <strong>{titles.CREATED_AT}: </strong>
                    <span>{getHumanDate(item?.created_at) || messages.INFO_IS_NOT_FOUND}</span>
                </Typography>
                <Typography variant="body2">
                    <strong>{titles.ADDRESS}: </strong>
                    <span>{item?.address_text || messages.INFO_IS_NOT_FOUND}</span>
                </Typography>
                <Typography variant="body2">
                    <strong>{'Количество свободных мест'}: </strong>
                    <span>{item?.free_spaces || messages.INFO_IS_NOT_FOUND}</span>
                </Typography>
                <Typography variant="body2">
                    <strong>{'Общее количество мест'}: </strong>
                    <span>{item?.spaces?.total || messages.INFO_IS_NOT_FOUND}</span>
                </Typography>
            </ListItemText>
            <FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.editIcon,
                        onClick: () => setOpenEditModal(true),
                    },
                    {
                        ...buttonsTypes.deleteIcon,
                        onClick: () => setOpenDeleteModal(true),
                    },
                ]}
                noMarginLeft
                justButton
            />
        </ListItem>

        {openDeleteModal
            && <ConfirmDelete
                open={openDeleteModal}
                onSuccess={() => {
                    dispatch(deletePark(item.id, reloadList));
                    setOpenDeleteModal(false);
                }}
                onClose={() => setOpenDeleteModal(false)}
            />
        }

        {openEditModal
            && <ModalForm
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
                item={item}
                tariffs={tariffs}
                reloadList={reloadList}
                categories={categories}
            />
        }
    </>);
};

export default Item;
