import apiUrls from './api.urls';
import instance from '../../helpers/axios.config';

const ApiWorkRequestMethods = {
    getWorkRequestList: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getWorkRequestList(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    createWorkRequest: async (data) => {
        const response = await instance.post(apiUrls.createWorkRequest(), data);
        return response?.data;
    },

    editWorkRequest: async (id, data) => {
        const response = await instance.put(apiUrls.editWorkRequest(id), data);
        return response?.data;
    },

    deleteWorkRequest: async (id) => {
        const response = await instance.delete(apiUrls.deleteWorkRequest(id));
        return response?.data;
    },

    getWorkRequestStatuses: async () => {
        const response = await instance.get(apiUrls.getWorkRequestStatuses());
        return response?.data;
    },
    
    changeWorkRequestStatus: async (id, data) => {
        const response = await instance.put(apiUrls.changeWorkRequestStatus(id), data);
        return response?.data;
    },
    
    appointWorkRequest: async (id, data) => {
        const response = await instance.put(apiUrls.appointWorkRequest(id), data);
        return response?.data;
    },
    
};

export default ApiWorkRequestMethods;