import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import messages from '../../../../../helpers/constants/messages';

const Vehicles = ({ data }) => {

    return (
        <Scrollbars style={{ height: '200px' }}>
            {data?.length
                ? data?.map((item) => {
                    return (
                        <div key={item.id}>
                            <div className="info__item">
                                <span className="info__item-title">ГРЗ:</span>
                                <span>{item.grn}</span>
                            </div>
                            <div className="info__item">
                                <span className="info__item-title">Марка:</span>
                                <span>{item.brand}</span>
                            </div>
                            <div className="info__item">
                                <span className="info__item-title">Модель:</span>
                                <span>{item.model}</span>
                            </div>
                            <div className="info__item">
                                <span className="info__item-title">Комментарий</span>
                                <span>{item.comment}</span>
                            </div>
                        </div>
                    );
                })
                : <div className="info__item">
                    {messages.INFO_IS_NOT_FOUND}
                </div>
            }
        </Scrollbars>
    );
};

export default Vehicles;
