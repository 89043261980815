// index [0,11]
// получить месяц на русском в именительном падеже
const monthsList = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
];

const getMonth = (index) => {
    return monthsList[index] || '';
};
export const getMonths = monthsList;

export default getMonth;