import { useState } from 'react';

import titles from 'helpers/constants/titles';
import DateRange from 'components/common/Dates/DateRange';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import WantedCars from 'components/common/Autocomplete/WantedCars';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';


const Filters = ({ setParams, initialFilter }) => {
    const [data, setData] = useState(initialFilter);

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const handleEventWanted = (newValue) => {
        const { brand, model, color } = newValue;
        setData({
            ...data,
            vehicle_brand: brand,
            vehicle_model: model,
            vehicle_color: color
        });
    };

    const onSearch = (filters = data) => {
        setParams({ page: 1, filter: filters });
    };

    const resetFilter = (needRefresh) => {
        needRefresh && onSearch(initialFilter);
        setData(initialFilter);
    };

    const updateFilter = (filters) => {
        const newFilters = { ...initialFilter, ...filters };
        setData(newFilters);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilters);
        }
    };

    return (
        <LayoutFilter
            filter={data}
            onSearch={onSearch}
            onResetFilter={resetFilter}
            setUserFilter={updateFilter}
            filterException={['start_date', 'end_date']}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={data.start_date}
                    valueEndDate={data.end_date}
                    handleDate={(date) => handleChange('start_date', date)}
                    handleEndDate={(date) => handleChange('end_date', date)}
                />
                <MultipleInput
                    label="Гос номер ТС"
                    value={data.query}
                    onChange={(value) => handleChange('query', value)}
                    guideText={titles.WRITE_TRANSPORT_NUMBER}
                />
            </LayoutFilter.Visible>

            <LayoutFilter.Invisible>
                <div className="grid-column-1-3">
                    <WantedCars
                        selectedBrand={{ brand: data.vehicle_brand }}
                        selectedModel={{ model: data.vehicle_model }}
                        selectedColor={{ color: data.vehicle_color }}
                        onChange={handleEventWanted}
                    />
                </div>
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filters;
