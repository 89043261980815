import PageLayout from 'components/layout/PageLayout';
import Filter from './Filter'; 

const ReportViolationComplex = () => {
    return (<PageLayout
        header={'Отчёт по формированию нарушений камерами комплекса'}
        filters={<Filter />}
    />);
};

export default ReportViolationComplex;