import React from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import titles from 'helpers/constants/titles';
import SelectTransportIncidentsTypes from 'components/common/Autocomplete/DorisReact/TransportIncidentsTypes';
import SelectScenarios from 'components/common/Autocomplete/DorisReact/Scenarios';
import DateRange from 'components/common/Dates/DateRange';

const Filter = (props) => {
    const {
        data,
        onChange,
        threatLevels,
        statuses
    } = props;

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({
            [name]: value,
        });
    };

    const handleSelectChange = (key, value) => {
        const { id, name } = value;

        onChange({
            [key]: { id, name }
        });
    };

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >

            <div
                className="row"
                style={{ marginBottom: '1rem' }}
            >
                <DateRange
                    valueStartDate={data?.start_date}
                    valueEndDate={data?.end_date}
                    handleDate={(value) => onChange({ start_date: value })}
                    handleEndDate={(value) => onChange({ end_date: value })}
                    startLabel={'Начало периода'}
                    endLabel={'Конец периода'}
                />
            </div>

            <FormControl className="block" size="small" variant="outlined">
                <TextField
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    value={data.name || ''}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                />
            </FormControl>

            <FormControl className="block" size="small" variant="outlined">
                <TextField
                    label={titles.ADDRESS}
                    variant="outlined"
                    size="small"
                    value={data.address_text || ''}
                    name="address_text"
                    onChange={handleChange}
                    type="text"
                    InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                />
            </FormControl>

            <FormControl className="block" size="small" variant="outlined">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={data?.threat_level_id || null}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    options={threatLevels?.data}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option?.name}
                    onChange={(_, threat_level_id) => onChange({ threat_level_id })}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.THREAT_LEVEL}
                        />
                    )}
                />
            </FormControl>

            <FormControl className="block" variant="outlined" size="small">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    multiple
                    value={data?.status_id_list || []}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    options={statuses}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option?.name}
                    onChange={(_, status_id_list) => onChange({ status_id_list })}
                    size="small"

                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.STATUS}
                        />
                    )}
                />
            </FormControl>

            <FormControl className="block" size="small" variant="outlined">
                <SelectTransportIncidentsTypes
                    selected={data.type_id}
                    onChange={(value) => handleSelectChange('type_id', value)}
                />
            </FormControl>

            <FormControl className="block" size="small" variant="outlined">
                <SelectScenarios
                    selected={data.response_scenario_id}
                    onChange={(value) => handleSelectChange('response_scenario_id', value)}
                />
            </FormControl>



        </form>
    );
};

export default Filter;
