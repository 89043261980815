import React, { useState } from 'react';
import { IconButton, ListItem, ListItemText, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import styles from './videoAnalytic.module.scss';
import messages from '../../../../../helpers/constants/messages';
import { fullDateTime } from '../../../../../helpers/date.config';
import MediaModal from '../../../../common/MediaModal';
import { videoAnalyticsSelectors } from '../../../../../redux/VideoAnalytics';

function Item({ data, params }) {
    const violations = useSelector(videoAnalyticsSelectors.violations);
    
    const [detectionOpen, setDetectionOpen] = useState(false);
    const [mapPointsOpen, setMapPointsOpen] = useState(false);

    const labels = {
        first: {
            created_at: {
                title: 'Въезд в зону фиксации',
                value: (value) => fullDateTime(value)
            },
            stop_at: {
                title: 'Выезд из зоны фиксации',
                value: (value) => fullDateTime(value)
            },
            camera_id: {
                title: 'Камера',
                value: () => data.camera_name || messages.NO_DATA
            },
            line: {
                title: 'Полоса движения',
                value: (value) => value || <span>{messages.NO_DATA}</span>
            },
            vehicle_direction_stop: {
                title: 'Направление движения ТС'
            },
            violation_type: {
                title: 'Тип события',
                value: (value) => value?.map(item =>
                    <span key={item}>{violations[item] || messages.NO_DATA} </span>
                ) || null
            },
        },
        second: {
            // vehicle_direction_start: {
            //     title: 'Направление въезда'
            // },
            grz: {
                title: 'Номер ТС',
            },
            vehicle_brand: {
                title: 'Марка ТС',
            },
            vehicle_model: {
                title: 'Модель ТС'
            },
            vehicle_color: {
                title: 'Цвет ТС'
            },
            class_name_ru: {
                title: 'Тип ТС'
            },
            vehicle_speed: {
                title: 'Скорость ТС',
                value: (value) => typeof value === 'number' ? `${value} км/ч` : messages.NO_DATA
            },          
        }
    };

    const renderItems = (labels) => (
        <>
            {Object.keys(labels).map(key => {
                const { title, value } = labels[key];
                return (
                    <p key={key} className="row">
                        <strong>{title}:&nbsp;</strong>
                        <span>{(value && value(data[key])) || data[key] || messages.NO_DATA}</span>
                    </p>
                );
            })}
        </>
    );

    const renderEventInfo = () => (
        <div className="row"> 
            <div style={{ marginRight: '3rem' }}>{renderItems(labels.first)}</div>
            <div>{renderItems(labels.second)}</div>
        </div>
    );

    return (
        <>
            <ListItem divider>
                <ListItemText disableTypography>
                    {renderEventInfo()}
                </ListItemText>
                {data.thumb && (
                    <button
                        onClick={() => setDetectionOpen(true)}
                    >
                        <div className={styles.picture}>
                            <img
                                src={data.thumb}
                                title="Кадр детекции"
                                alt="Кадр детекции"
                            />
                        </div>
                    </button>
                )}
                {/* {data?.url_to_frame_start
                        && <Tooltip title="Кадр детекции въезд">
                            <IconButton onClick={() => openDetection('Кадр детекции въезд', data?.url_to_frame_start)}>
                                <i className="fal fa-image"/>
                            </IconButton>
                        </Tooltip>
                    }
                    {data?.url_to_frame_stop
                        && <Tooltip title="Кадр детекции выезд">
                            <IconButton onClick={() => openDetection('Кадр детекции въезд', data?.url_to_frame_stop)}>
                                <i className="fal fa-image"/>
                            </IconButton>
                        </Tooltip>
                    } */}
                <Tooltip title="Показать историю движения на карте">
                    <IconButton onClick={() => setMapPointsOpen(true)} size="large">
                        <i className="fal fa-map-marked-alt"/>
                    </IconButton>
                </Tooltip>
            </ListItem>
            {detectionOpen 
                && <MediaModal
                    data={data}
                    isOpen={detectionOpen}
                    onClose={() => setDetectionOpen(false)}
                    // renderLabels={{ ...labels.first, ...labels.second }}
                    renderLabels={renderEventInfo}
                />
            }
        </>
    );
}

export default Item;