import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadTrafficSchedule,
    loadTrafficScheduleStatuses,
    loadTrafficScheduleTypes,
} from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';

import Item from './Item';
import ModalForm from './ModalForm';
import Filter from './Filter';
import { passengerTransportTrafficScheduleURI } from './moduleConfig';

const TrafficSchedule = ({ currentRoute, currentTemplate, switchToSchedule }) => {
    const dispatch = useDispatch();
    const test_id_prefix = passengerTransportTrafficScheduleURI;
    const statuses = useStoreProp(loadTrafficScheduleStatuses, 'transportPassenger', 'trafficScheduleStatuses');
    const types = useStoreProp(loadTrafficScheduleTypes, 'transportPassenger', 'trafficScheduleTypes');

    const trafficScheduleData = useSelector(transportPassengerSelectors.trafficScheduleData);
    const trafficScheduleMeta = useSelector(transportPassengerSelectors.trafficScheduleMeta);
    const loadingTrafficSchedule = useSelector(transportPassengerSelectors.loadingTrafficSchedule);

    const [params, setParams] = useState({ page: 1, data: {} });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const reloadList = (isOnlyItemOnLastPage = false) => {
        dispatch(loadTrafficSchedule(
            isOnlyItemOnLastPage ? params.page - 1 : params.page,
            limit,
            {
                ...params.data,
                ...(currentRoute?.id && { route_id: currentRoute.id }),
                ...(currentTemplate?.id && { schedule_template_id_list: [currentTemplate.id]
                })
            }));
    };

    useEffect(() => {
        dispatch(loadTrafficSchedule(params.page, limit, {
            ...params.data,
            ...(currentRoute?.id && { route_id: currentRoute.id }),
            ...(currentTemplate?.id && { schedule_template_id_list: [currentTemplate.id] })
        }));
    }, [dispatch, limit, params, currentRoute?.id, currentTemplate?.id]);

    const renderContent = () => {
        return <>
            {trafficScheduleData?.length > 0
                ? (<>
                    <LSContainer
                        // fill
                        headers={[
                            {
                                title: '',
                                width: '50px'
                            },
                            {

                                title: titles.NAME,
                                width: '34%'
                            },
                            {
                                title: '№',
                                width: '8%'
                            },
                            {
                                title: titles.START_DATE,
                                width: '11%'
                            },
                            {
                                title: titles.END_DATE,
                                width: '11%'
                            },
                            {
                                title: titles.TYPE,
                                width: '14%'
                            },
                            {
                                title: titles.ACTIONS,
                                isActions: true
                            }
                        ]}
                    >
                        {trafficScheduleData.map((item, index, arr) => (
                            <Item
                                key={item.id}
                                item={item}
                                statuses={statuses}
                                types={types}
                                currentData={currentRoute}
                                switchToSchedule={switchToSchedule}
                                reloadList={(isDelete) => reloadList(isDelete && !arr.at(index + 1))}
                            />
                        ))}
                    </LSContainer>
                </>)
                : (!loadingTrafficSchedule && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>;
    };

    return (
        <>
            <PageLayout
                header={titles.TRAFFICS_SCHEDULE}
                filters={<Filter
                    currentRoute={currentRoute}
                    currentTemplate={currentTemplate}
                    setParams={setParams}
                    test_id_prefix={test_id_prefix}
                />}
                loading={loadingTrafficSchedule}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={[{
                                ...buttonsTypes.add,
                                onClick: () => setIsOpenModal(true),
                                'data-testid': `${test_id_prefix}:buttons/save`
                            }]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: trafficScheduleMeta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: trafficScheduleMeta,
                    limit,
                    setLimit
                }}
            />

            {isOpenModal && (
                <ModalForm
                    isOpen={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    isNew={true}
                    statuses={statuses}
                    types={types}
                    currentRoute={currentRoute}
                    routeForTrafficSchedule={true}
                    reloadList={() => setParams( { page: 1, data: {
                        ...(currentRoute?.id && { route_id: currentRoute.id }),
                        ...(currentTemplate?.id && { schedule_template_id_list: [currentTemplate.id] })
                    } })}
                    test_id_prefix={test_id_prefix}
                />
            )}
        </>
    );
};

export default TrafficSchedule;
