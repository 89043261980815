import React, { useState } from 'react';
import { TextField } from '@mui/material';
import titles from '../../../../../helpers/constants/titles';
import SelectCompany from '../../../../common/Autocomplete/Companies';
import LayoutFilter from '../../../../layout/PageLayout/LayoutFilter';

const Filter = ({ setParams }) => {

    const initialState = { organization: {}, trans_name: '' };

    const [formData, setFormData] = useState(initialState);

    const onChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const search = (filters = formData) => {
        setParams(prev => ({
            ...prev,
            ...filters,
            organization_id: filters.organization?.id,
            organization: null,
        }));
    };
    
    const resetForm = (needRefresh) => {
        setFormData(initialState);
        if (needRefresh) {
            setParams(prev => ({ ...prev, ...initialState, organization_id: null }));
        }
    };

    const updateFilter = (filters) => {
        setFormData({ // обновляем локальный стейт
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            search({ // отправляем в родителя, чтобы произошел запрос
                ...initialState,
                ...filters,
            });
        }
    };

    const isDisabledSearchButton = formData.organization?.id || formData.trans_name.trim();

    return (
        <LayoutFilter
            onResetFilter={resetForm}
            onSearch={search} 
            filter={formData} 
            setUserFilter={updateFilter}
            disabled={!isDisabledSearchButton}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={formData.trans_name}
                    onChange={(e) => onChange('trans_name', e.target.value)}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                />
                <SelectCompany
                    selected={formData.organization}
                    onChange={(value) => onChange('organization', value)}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;