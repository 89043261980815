import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import {
    Checkbox,
    TextField,
} from '@mui/material';
import { property } from 'lodash';

import {
    loadComplexConditions,
    loadComplexModelTypes,
    loadComplexTypes,
} from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import { usePermissions } from 'helpers/hooks/Permissons';
import useStoreFromSelector from 'helpers/hooks/useStoreFromSelector';
import { useValidation } from 'helpers/hooks';
import DateRange from 'components/common/Dates/DateRange';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';
import ViolationTree from 'components/common/Autocomplete/DorisControl/ViolationTree';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SimpleSelect from 'components/common/SimpleSelect';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import GovernmentContractList from 'components/common/Autocomplete/GovernmentContract';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
// import ModelDependentTypeSelect from 'components/common/Transport/ModelDependentTypeSelect';
import { complexEventsURI } from 'components/pages/Reports/DorisControl/ComplexEvents/permissionConfig';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import styles from './filtersCPVF.module.scss';
import RenderGroup from './Group/RenderGroup';


const FiltersCPVF = ({
    filter = {},
    onFilterChanged,
    onReset,
    onSearch,
    tab = null,
    onUpdate,
}) => {
    const access = usePermissions(complexEventsURI).filter;
    const validation = useValidation(false);

    const complexConditions = useStoreFromSelector(loadComplexConditions, dorisControlSelectors.complexConditions);
    const complexConditionsList = useMemo(() => Object
        .keys(complexConditions)
        .map(id => ({
            id,
            // todo если потребуется с селекте названия текстом
            name: id // complexConditions[key],
        }))
    , [complexConditions]);

    const baseList = [
        {
            name: 'Основной',
            value: 0,
        },
        {
            name:'Сервисной',
            value: 1,
        }
    ];

    const complex_types = useStoreProp(
        loadComplexTypes,
        'dorisControl',
        'doris_complex_types'
    );
    const model_types = useStoreProp(
        loadComplexModelTypes,
        'dorisControl',
        'doris_complex_model_types'
    );

    const [isDisabled, setDisabled] = useState(false);

    const handleChange = (e) => {
        const { value, name } = e.target;
        onFilterChanged({ ...filter, [name]: value });
    };

    const handleChangeFilter = (name, value) => {
        onFilterChanged({ ...filter, [name]: value });
    };

    // фильтрованные модели от выбранных типов
    const modelsTypes = useMemo(() => {
        // выбранные типы
        const typeIds = filter.type_id_list.map(({ id }) => id);
        // выбранные модели
        const modelIds = filter.model_id_list.map(({ id }) => id);
        // фильтруем все
        return model_types.filter(({ id, type }) => typeIds.includes(type) && !modelIds.includes(id));
    }, [
        filter.type_id_list,
        filter.model_id_list,
        model_types
    ]);

    return (
        <LayoutFilter
            onResetFilter={onReset}
            onSearch={onSearch}
            disabled={isDisabled}
            filter={filter}
            filterException={['start_date', 'end_date',]} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={onUpdate}
            tab={tab}
            countException={tab ? ['complex_id', 'selectedComplexes'] : ['selectedComplexes']}
        >
            <LayoutFilter.Visible>
                {(!access || (access && access.start_date && access.end_date))
                    && <DateRange
                        filterId="start_date" // TODO в LayoutFilter проходить по children и сравнивать ключи в access.filters с filterId
                        valueStartDate={filter.start_date}
                        valueEndDate={filter.end_date}
                        handleDate={(date) => handleChangeFilter('start_date', date)}
                        handleEndDate={(date) => handleChangeFilter('end_date', date)}
                        isDisabledHandler={setDisabled}
                        className="daterange"
                    />
                }
                {!tab && (!access || access.complex_id_list)
                    && <SelectComplex
                        filterId="complex_id"
                        selected={filter.selectedComplexes}
                        onChange={(val) => {
                            onFilterChanged({
                                ...filter,
                                selectedComplexes: val,
                                complex_id_list: val.map(e => e.id),
                            });
                        }}
                        multiple={true}
                        required={false}
                    />
                }
                <SimpleSelect
                    endpoint={baseList}
                    selected={Number(filter.from_service_table)}
                    setSelected={(val) => handleChangeFilter('from_service_table', !!val)}
                    title="События из базы"
                    emptyItem={false}
                    withoutStyles
                    optionLabel="name"
                    optionValue="value"
                    optionKey="value"
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                {!tab
                    && <>
                        {(!access || access.address)
                            && <TextField
                                value={filter.address}
                                onChange={handleChange}
                                className={styles.field}
                                size="small"
                                type="text"
                                variant="outlined"
                                label="Адрес дислокации"
                                name="address"
                                // onKeyPress={handleClickEnter}
                            />
                        }
                        {(!access || access.contract_id_list)
                            && <GovernmentContractList
                                label="Госконтракт"
                                multiple
                                selected={filter.contract_id_list}
                                onChange={(value) => handleChangeFilter('contract_id_list', value)}
                            />
                        }
                        {(!access || access.model_id_list) && (
                            <>
                                <CommonAutocomplete
                                    multiple
                                    limitTags={1}
                                    filterSelectedOptions
                                    label="Тип комплекса"
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    selected={filter.type_id_list}
                                    options={complex_types}
                                    renderLabel={(option) => option.name || ''}
                                    onChange={(values) => {
                                        const selectedTypesId = values?.map(({ id }) => id) || [];
                                        onFilterChanged({
                                            ...filter,
                                            type_id_list: values,
                                            // отфильровываем модели от типов
                                            model_id_list: filter
                                                ?.model_id_list
                                                ?.filter(({ type }) => selectedTypesId.includes(type))
                                        });
                                    }}
                                    onReset={() => {
                                        onFilterChanged({
                                            ...filter,
                                            type_id_list: [],
                                            model_id_list: []
                                        });
                                    }}
                                    showAsSelect
                                />
                                <CommonAutocomplete
                                    multiple
                                    limitTags={1}
                                    label="Модель"
                                    disabled={filter.type_id_list?.length === 0}
                                    helperText={
                                        (
                                            filter.type_id_list?.length === 0
                                            || validation.isKey('model_id_list')
                                        )
                                        && 'Выбор модели доступен, если выбран Тип комплекса'
                                    }
                                    error={validation.isKey('model_id_list')}
                                    selected={filter.model_id_list}
                                    options={modelsTypes}
                                    filterSelectedOptions
                                    groupBy={(option) => option.type_text}
                                    renderLabel={(option) => option.description}
                                    renderGroup={RenderGroup}
                                    onChange={(values) => {
                                        onFilterChanged({
                                            ...filter,
                                            model_id_list: values
                                        });
                                    }}
                                    onReset={() => {
                                        onFilterChanged({
                                            ...filter,
                                            model_id_list: []
                                        });
                                    }}
                                    showAsSelect
                                />
                            </>
                        )}
                    </>
                }
                {(!access || access.camera_code_list)
                    && <MultipleInput
                        label="Камера Паутина"
                        value={filter.camera_code_list}
                        onChange={(value) => handleChangeFilter('camera_code_list', value)}
                        guideText={titles.WRITE_TRANSPORT_NUMBER}
                        limitTags={1}
                    />
                }
                {(!access || access.grz_list)
                    && <MultipleInput
                        label="Гос номер ТС"
                        value={filter.grz_list}
                        onChange={(value) => {
                            const newValue = (value || []).filter(el => !!el);
                            handleChangeFilter('grz_list', newValue);
                        }}
                        guideText={titles.WRITE_TRANSPORT_NUMBER}
                        limitTags={1}
                    />
                }
                {(!access || access.speed)
                    && (
                        <div className="row noMargin">
                            <SimpleSelect
                                withoutStyles
                                withAutoMinStyles
                                selected={filter?.speed_condition}
                                endpoint={complexConditionsList}
                                setSelected={value => handleChangeFilter('speed_condition', value)}
                                optionLabel="name"
                                optionKey="id"
                                optionValue="id"
                                title="Условие"
                            />
                            <FormInfoWrapper
                                // helperText="Найти все ТС со скоростью выше либо равно введенному значению"
                            >
                                <TextField
                                    value={filter.speed}
                                    onChange={handleChange}
                                    size="small"
                                    type="number"
                                    variant="outlined"
                                    label="Скорость (км/ч)"
                                    name="speed"
                                    // onKeyPress={handleClickEnter}
                                    className={styles.textFiledWithoutArrows}
                                />
                            </FormInfoWrapper>
                        </div>
                    )
                }
                {(!access || access.without_grz)
                    && <div className={cn(styles.filterItem, 'grid-column-1-3')}>
                        <Checkbox
                            onChange={(e) => handleChangeFilter('without_grz', e.target.checked)}
                            checked={filter.without_grz}
                        />
                        <div>
                            Искать авто с нераспознанным номером
                        </div>
                    </div>
                }
                {(!access || access.violation_type_list)
                    && <div className={tab ? '' : 'grid-column-1-2'}>
                        <ViolationTree
                            multiple
                            selected={filter.violation_type_list}
                            onChange={value => handleChangeFilter('violation_type_list', value)}
                            label="Тип событий"
                        />
                    </div>
                }
                {(!access || access.exclude_violation_type_list)
                    && <div className={tab ? '' : 'grid-column-3-4'}>
                        <ViolationTree
                            multiple
                            selected={filter.exclude_violation_type_list}
                            onChange={value => handleChangeFilter('exclude_violation_type_list', value)}
                            label="Исключить тип событий"
                        />
                    </div>
                }
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default FiltersCPVF;
