import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadCompanyRoleList } from 'redux/Companies/actions';
import { companiesSelectors } from 'redux/Companies';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import Context from 'helpers/context';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';

import Item from './Item';
import ModalForm from './ModalForm';
import Filter from './Filter';

const CompaniesRoles = () => {
    const dispatch = useDispatch();

    const { permissions } = useContext(Context);

    const [params, setParams] = useState({ 
        page: 1, 
        query: {} 
    });
    const [openAddModal, setOpenAddModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit') || 25));

    const data = useSelector(companiesSelectors.companyRolesData || []);
    const meta = useSelector(companiesSelectors.companyRolesMeta);
    const loading = useSelector(companiesSelectors.loadingCompanyRoles);

    const loadList = useCallback((shouldGoToPreviousPage = false) => {        
        if (shouldGoToPreviousPage) {            
            setParams((prev) => ({ ...prev, page: prev.page - 1 }));
        } else {            
            dispatch(loadCompanyRoleList(params.page, limit, params.query));
        }
    }, [dispatch, params, limit]);

    useEffect(() => {
        loadList();
    }, [loadList]);

    const renderContent = () => {
        return (data.length > 0
            ? <LSContainer
                headers={[
                    { title: titles.NAME, width: '80%' },
                    { title: 'Действия', isActions: true }
                ]}
            >
                {data?.map(item => (
                    <Item
                        key={item.id}
                        item={item} 
                        loadList={(isDelete) => loadList(isDelete && data.length === 1)}
                        permissions={permissions}
                    />
                ))
                }
            </LSContainer>
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };
    
    return (
        <>
            <PageLayout
                header="Справочник ролей организаций"
                filters={<Filter setParams={setParams}/>}
                loading={loading}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtons 
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenAddModal(true),
                                        disabled: !permissions?.is_create
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    
                    total: meta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit: limit,
                    setLimit: setLimit
                }}
            />
            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    loadList={loadList}
                    isNew
                />
            )}
        </>
    );
};

export default CompaniesRoles;