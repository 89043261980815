import React from 'react';
import { Container, Item, Name, Value } from '../List';
import messages from '../../../../helpers/constants/messages';

// простой вывод списка (компонент "Showcase/List")
const ListSimple = ({
    data = {}, // { [name]: value, ... }
    itemClass = '',
    separator = '',
}) => {
    const list = Object.entries(data);

    if (list?.length === 0) {
        return messages.NO_DATA;
    }

    return (
        <Container>
            {list
                ?.map((el, index) => (
                    <Item key={index} className={itemClass}>
                        <Name separator={separator}>{el[0]}</Name>
                        <Value>{el[1]}</Value>
                    </Item>
                ))}
        </Container>
    );
};

export default ListSimple;