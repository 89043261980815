import * as types from './types';

const initialState = {
    lastMapCenter: null,
    // mapLayer: null,
    mapCenter: null,
    // лоадинг для слоев
    mapLoadingObject: {},
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.SAVE_LAST_MAP_CENTER:
            return {
                ...state,
                lastMapCenter: payload,
            };
        // case types.CHANGE_MAP_LAYERS:
        //     return {
        //         ...state,
        //         mapLayer: payload,
        //     };
        case types.CHANGE_MAP_CENTER:
            return {
                ...state,
                mapCenter: payload,
            };

        case types.SET_MAP_LOADING:
            const {
                key,
                loading,
            } = payload;

            const mapLoadingObject = { ...state.mapLoadingObject };

            if (
                mapLoadingObject?.hasOwnProperty?.(key)
                && loading === false
            ) {
                delete mapLoadingObject[key];
            } else if (loading) {
                mapLoadingObject[key] = true;
            }

            return {
                ...state,
                mapLoadingObject,
            };

        default:
            return state;
    }
}
