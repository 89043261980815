export const notifications = (state) => state.notifications;

export const list = (state) => notifications(state).list;
export const listData = (state) => list(state).data;
export const listMeta = (state) => list(state).meta;

export const loading = (state) => notifications(state).loading;
export const countActive = (state) => notifications(state).countActive;
// export const lastPage = (state) => notifications(state).lastPage;
export const serviceList = (state) => notifications(state).serviceList;
export const freeSpaceServer = (state) => notifications(state).freeSpaceServer;
export const wsReload = (state) => notifications(state).wsReload;