import {
    loadDadataAddressByCoordinates,
    clearDadataAddressByCoordinates,
} from '../../../redux/Dadata/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useDebounce from '../useDebounce';
import getAddressStringFromObject from '../../../components/common/Location/LoadAddressByCoords/getAddressStringFromObject';

// получить адрес по lat lon
const useLoadByLatLon = (callback) => {
    const dispatch = useDispatch();
    const addressByCoordinates = useSelector(({ dadata }) => dadata?.addressByCoordinates);
    const [doAction, setDoAction] = useState(false);

    const fetch = ({ lat, lon }) => {
        if (lat && lon) {
            setDoAction(true);
            dispatch(loadDadataAddressByCoordinates(lat, lon));
        }
    };

    const fetchDebounce = useDebounce(fetch, 800);

    useEffect(() => {
        if (doAction && callback) {
            setDoAction(false);
            const data = {
                ...addressByCoordinates,
            };
            callback({
                address: data,
                address_text: getAddressStringFromObject(data),
            });
            dispatch(clearDadataAddressByCoordinates());
        }
    }, [addressByCoordinates, doAction, callback]);

    return {
        // грузим данные
        load: (lat, lon) => fetchDebounce({ lat, lon }),
    };
};
export default useLoadByLatLon;