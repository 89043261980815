import React, { useState } from 'react';
import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import DateRange from '../../../../common/Dates/DateRange';
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import removeEmptyFields from '../../../../../helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone } from '../../../../../helpers/date.config';
import FuelReportModal from './FuelReportModal';
import { useValidation } from '../../../../../helpers/hooks';
import { differenceInWeeks, roundToNearestMinutes } from 'date-fns';
import { getReportFuel } from '../../../../../redux/TransportRoad/actions';
import Loading from '../../../../common/Loading';

const FuelDumpModal = ({ isOpen, onClose, item }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        start_at: null,
        end_at: null,
        is_all: 1,
        difference: '0.0',
    };

    const [formData, setFormData] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [openReportFuelModal, setOpenReportFuelModal] = useState(false);

    const onChange = (name, value) => {
        setFormData({ 
            ...formData, 
            [name]: value,
            ...((name === 'is_all' && value === 1) && { difference: '0.0' } )
        });
    };

    const onSearch = () => {
        setLoading(true);
        const prepareData = removeEmptyFields({
            ...formData,
            start_at: fullDateTimeWithTimeZone(roundToNearestMinutes(formData.start_at)),
            end_at: fullDateTimeWithTimeZone(roundToNearestMinutes(formData.end_at)),
            is_all: Boolean(formData.is_all),
            vehicle_id: item?.id,
            difference: Number(formData.difference),
        }, false);

        dispatch(getReportFuel(prepareData, () => {
            setOpenReportFuelModal(true);
            setLoading(false);
        }));
    };

    const isMoreThenWeek = differenceInWeeks(formData.end_at, formData.start_at) > 0;
    const isDisabled = !(formData.start_at && formData.end_at) || isMoreThenWeek;

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                title="Укажите параметры"
                buttons={
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.cancel,
                                onClick: onClose
                            },
                            {
                                ...buttonsTypes.search,
                                disabled: isDisabled,
                                onClick: onSearch
                            },
                        ]}
                    />
                }
            >
                <div className="block">
                    <div className="row">
                        <DateRange
                            handleDate={(value) => onChange('start_at', value)}
                            handleEndDate={(value) => onChange('end_at', value)}
                            valueStartDate={formData.start_at}
                            valueEndDate={formData.end_at}
                            className="row__item"
                            required
                            renderError={() => (validation.isKey('start_at') && validation.isKey('end_at')) || isMoreThenWeek}
                            errorMessage={() => validation.get('start_at') || validation.get('end_at')}
                        />
                    </div>
                    <FormHelperText error={isMoreThenWeek}>Максимальный период 1 неделя</FormHelperText>
                </div>

                <div className="block">
                    <FormControl>
                        <RadioGroup
                            value={formData.is_all}
                            onChange={(e) => onChange('is_all', Number(e.target.value))}
                            className="row"
                        >
                            <FormControlLabel
                                value={1}
                                control={<Radio size="small"/>}
                                label="Показать все"
                                className="row__item"
                            />
                            <FormControlLabel
                                value={0}
                                control={<Radio size="small"/>}
                                label="Показать только данные с разницей уровня топлива"
                                className="row__item"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>

                {formData.is_all === 0 && (
                    <div className="block">
                        <TextField
                            value={formData.difference}
                            variant="outlined"
                            onChange={(e) => {
                                onChange('difference', e.target.value < 0
                                    ? (e.target.value = '0')
                                    : e.target.value);
                            }}
                            label="Разница уровня топлива, л."
                            size="small"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                        <FormHelperText error={false}>
                            Показать только данные, у которых разница межу данными уровня топлива больше
                            или равно указанному значению (л.)
                        </FormHelperText>
                    </div>
                )}
                {loading && <Loading className="center" />}
            </Modal>
            {openReportFuelModal && (
                <FuelReportModal
                    isOpen={openReportFuelModal}
                    onClose={() => setOpenReportFuelModal(false)}
                    item={item}
                />
            )}
        </>
    );
};

export default FuelDumpModal;