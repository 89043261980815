import { IconButton, Tooltip } from '@mui/material';
import buttons from '../../../../helpers/constants/buttons';
import messages from '../../../../helpers/constants/messages';
import { LSContentColumn, LSContentItem } from '../../List';

const VehicleListItem = ({ 
    item, 
    category, 
    styles, 
    setOpenModal, 
    isSpecTransport = false, 
    setCurrentData = () => {}
}) => {
    const uid = item?.characteristics?.uid || item?.characteristics?.serial_egts;

    const tooltipTitle = uid 
        ? buttons.GET_REPORT 
        : <span>Невозможно получить отчет но данному ТС</span>;

    return (
        <LSContentItem>
            <LSContentColumn>{isSpecTransport ? item.number : item.garage_number || messages.NO_DATA}</LSContentColumn>
            <LSContentColumn>{item.mark || messages.NO_DATA}</LSContentColumn>
            <LSContentColumn>{item.model || messages.NO_DATA}</LSContentColumn>
            <LSContentColumn>{item.speed ? `${Number(item.speed).toFixed(2)} км/ч` : messages.NO_DATA}</LSContentColumn>
            <LSContentColumn>{category?.name || messages.NO_DATA}</LSContentColumn>
            <LSContentColumn isActions>
                <Tooltip title={isSpecTransport ? tooltipTitle : buttons.GET_REPORT}>
                    <IconButton
                        onClick={() => {
                            isSpecTransport && setCurrentData(item);
                            setOpenModal(true);
                        }}
                        className={styles.icon_button}
                        disabled={isSpecTransport && !uid}
                        size="small"
                    >
                        <i className="far fa-file-download"/>
                    </IconButton>
                </Tooltip>
            </LSContentColumn>
        </LSContentItem>
    );
};

export default VehicleListItem;
