import { useState } from 'react';

import {
    LSContentItem,
    LSContentColumn,
    ActionsButtons,
} from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import messages from 'helpers/constants/messages';
import { humanDateWithoutTZ } from 'helpers/date.config';

import ModalForm from './ModalForm';
import ShowLink from './ShowLink';

const Item = ({ item, onDelete, reloadList }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        onDelete?.(item.id);
        setIsDelete(false);
    };

    return (
        <LSContentItem>
            <LSContentColumn>
                {item.road_name || messages.DATA_IS_NOT_FOUND}
            </LSContentColumn>

            <LSContentColumn>
                {item.road_category || messages.DATA_IS_NOT_FOUND}
            </LSContentColumn>

            <LSContentColumn>
                {item.address_text || messages.DATA_IS_NOT_FOUND}
            </LSContentColumn>

            <LSContentColumn>
                {item.characteristic_period || messages.DATA_IS_NOT_FOUND}
            </LSContentColumn>

            <LSContentColumn>
                {item.month || item.year
                    ? `${item.month}.${item.year}` : messages.DATA_IS_NOT_FOUND}
            </LSContentColumn>

            <LSContentColumn>
                {item.maintenance_level || messages.DATA_IS_NOT_FOUND}
            </LSContentColumn>

            <LSContentColumn>
                {humanDateWithoutTZ(item.registration_date) || messages.DATA_IS_NOT_FOUND}
            </LSContentColumn>

            <LSContentColumn>
                <ShowLink link={item.document_link} defaultValue={messages.DATA_IS_NOT_FOUND} />
            </LSContentColumn>

            <LSContentColumn isActions>
                <ActionsButtons
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setIsEdit(true),
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setIsDelete(true),
                        }
                    ]}
                />
            </LSContentColumn>

            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    message={messages.CONFIRM_DELETE}
                    onClose={() => setIsDelete(false)}
                    onSuccess={handleDelete}
                />
            )}

            {isEdit && (
                <ModalForm
                    isOpen={isEdit}
                    isNew={false}
                    dataForm={item}
                    onClose={() => setIsEdit(null)}
                    onSuccess={reloadList}
                />
            )}

        </LSContentItem>
    );
};

export default Item;
