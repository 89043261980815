import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import titles from '../../../../../helpers/constants/titles';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import { FormControl, TextField, FormHelperText } from '@mui/material';
import { createTag, editTag } from '../../../../../redux/DorisControl/actions';
import CommonModal from '../../../../common/Modal';

const EditModal = ({ isNew, onClose, item = {}, reloadList }) => {
    const dispatch = useDispatch();

    const [name, setName] = useState(item.name || '');
    const [isLength, setIsLength] = useState(false);

    const onSave = (id) => {
        const callback = ()=> { reloadList(); onClose(); };
        dispatch((isNew 
            ? createTag({ name }, callback)
            : editTag( { name }, id, callback)
        ));
        setName('');
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setIsLength(value.length < 3 && value.length > 0);
        setName(value);
    };

    const isDisabled = (!name.trim() || isLength);

    return (
        <CommonModal
            isOpen
            title={isNew ? titles.ADD : titles.EDIT}
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    isNew
                        ? {
                            ...buttonsTypes.create,
                            onClick: onSave,
                            disabled: isDisabled
                        }
                        : {
                            ...buttonsTypes.save,
                            onClick: () => onSave(item.id),
                            disabled: isDisabled
                        }
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl required className="block" size="small" variant="outlined">
                    <TextField
                        required
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={name}
                        name={'name'}
                        onChange={handleChange}
                        type="text"
                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                    />
                </FormControl>
                {isLength
                    ? <FormHelperText error>
                        {'Должно быть больше 3 символов'}
                    </FormHelperText>
                    : ''}
            </div>
        </CommonModal>
    );
};

export default EditModal;