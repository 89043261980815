import React, { useState } from 'react';
import {
    TextField,
    InputAdornment,
    IconButton,
} from '@mui/material';
import useStoreProp from '../../../../../../helpers/hooks/useStoreProp';
import titles from '../../../../../../helpers/constants/titles';
import UniversalSelect from '../../../../../common/UniversalSelect';
import {
    loadEventObjectsTypes,
    loadEvents, loadKeyWordList,
} from '../../../../../../redux/SituationalPlans/actions';
import { useDispatch } from 'react-redux';
import { Autocomplete } from '@mui/material';


const Filter = (props) => {
    const {
        data,
        onChange,
    } = props;
    const dispatch = useDispatch();

    const [showList, setShowList] = useState(false);
    const [isOpenKeywordsUniversalSelect, setIsOpenKeywordsUniversalSelect] = useState(false);

    const handleSelect = (value) => {
        onChange({ event_id: { id: value.id, name: value.name } });
        setShowList(false);
    };

    const types = useStoreProp(loadEventObjectsTypes, 'SituationalPlans', 'eventObjectsTypes');

    const handleEvent = (name) => (_, newValue) => {
        onChange({ [name]: newValue });
    };

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >

            <div className="block">
                <TextField
                    label={titles.NAME}
                    size="small"
                    value={data.name || ''}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                />
            </div>
            <div className="block">
                <Autocomplete
                    value={data?.event_id?.name || ''}
                    options={[]}
                    getOptionLabel={(option) => option || ''}
                    freeSolo
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.EVENTS}
                            disabled
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end"
                                        style={{
                                            position: 'absolute',
                                            right: 13
                                        }}>
                                        {data?.event_id?.name && Object.keys(data?.event_id?.name).length > 0 
                                                && <IconButton size="small" onClick={() => handleSelect('')}>
                                                    <i className="far fa-times"/>
                                                </IconButton>
                                        }
                                        <IconButton size="small" onClick={() => setShowList(true)}>
                                            <i className="fas fa-search-plus"/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
            </div>
            {showList
                && <UniversalSelect
                    storeName={'SituationalPlans'}
                    storeNameProps={'events'}
                    keyProp={'id'}
                    fetchList={({ page, limit, query }) => dispatch(loadEvents(page, limit, { name: query }))}
                    withSearch={true}
                    isSelected={true}
                    onAccept={handleSelect}
                    selected={data.event_id || {}}
                    renderProps={(el) => <div><span>{el.name}</span></div>}
                    isOpen={showList}
                    onClose={() => setShowList(false)}
                    noPadding
                    title={titles.EVENTS}
                    small
                />
            }
            <div className="block">
                <Autocomplete
                    value={data?.type_id || null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={types}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={handleEvent('type_id')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={'Тип *'}
                        />
                    )}
                />
            </div>
            <div className="block">
                <Autocomplete
                    multiple={true}
                    value={data?.keyword_id_list || []}
                    options={[]}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option?.name || ''}
                    onChange={handleEvent('keyword_id_list')}
                    // freeSolo={true}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            required={true}
                            variant="outlined"
                            label={titles.KEYWORDS}
                            disabled={true}
                            name="keyword_id_list"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{
                                            position: 'absolute',
                                            right: 13
                                        }}
                                    >
                                        {data?.keyword_id_list && Object.keys(data?.keyword_id_list).length > 0 
                                            && <IconButton size="small" onClick={() => onChange({ keyword_id_list: [] })}>
                                                <i className="far fa-times"/>
                                            </IconButton>
                                        }
                                        <IconButton size="small" onClick={() => setIsOpenKeywordsUniversalSelect(true)}>
                                            <i className="fas fa-search-plus"/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                />
            </div>
            {isOpenKeywordsUniversalSelect
                && <UniversalSelect
                    fetchList={({ page, limit, query }) => {
                        dispatch(loadKeyWordList(page, limit, { name: query }));
                    }}
                    storeName={'SituationalPlans'}
                    storeNameProps={'keyWords'}
                    keyProp={'id'}
                    withSearch={true}
                    isSelected={true}
                    multiple={true}
                    onAccept={(selectedItem) => {
                        onChange({ keyword_id_list: selectedItem });
                        setIsOpenKeywordsUniversalSelect(false);
                    }}
                    selected={data?.keyword_id_list || []}
                    renderProps={(el) => 
                        <div>
                            <span>{el.name}</span>
                        </div>
                    }
                    isOpen={isOpenKeywordsUniversalSelect}
                    onClose={() => setIsOpenKeywordsUniversalSelect(false)}
                    noPadding={true}
                    title={titles.KEYWORDS}
                    small={true}
                />
            }


            {/*<div className="block">*/}
            {/*    <TextField*/}
            {/*        value={data?.route_id?.name || ''}*/}
            {/*        label={titles.ROUTE}*/}
            {/*        variant={'outlined'}*/}
            {/*        size={'small'}*/}
            {/*        disabled={true}*/}
            {/*        fullWidth={true}*/}
            {/*        InputProps={{*/}
            {/*            endAdornment: (*/}
            {/*                <InputAdornment position={'end'}>*/}
            {/*                    <Tooltip title={titles.CLICK_TO_SELECT}>*/}
            {/*                        <IconButton size="small" onClick={() => setIsOpenRoutesUniversalSelect(true)}>*/}
            {/*                            <i className="fas fa-search-plus"/>*/}
            {/*                        </IconButton>*/}
            {/*                    </Tooltip>*/}
            {/*                </InputAdornment>*/}
            {/*            )*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*{isOpenRoutesUniversalSelect*/}
            {/*    && <Modal*/}
            {/*        isOpen={isOpenRoutesUniversalSelect}*/}
            {/*        onClose={() => setIsOpenRoutesUniversalSelect(false)}*/}
            {/*        noPadding={true}*/}
            {/*        title={titles.ROUTE}*/}
            {/*        small={true}*/}
            {/*        buttons={*/}
            {/*            <FormButtons*/}
            {/*                buttons={[*/}
            {/*                    {*/}
            {/*                        ...buttonsTypes.close,*/}
            {/*                        onClick: () => setIsOpenRoutesUniversalSelect(false)*/}
            {/*                    },*/}
            {/*                ]}*/}
            {/*            />*/}
            {/*        }*/}
            {/*    >*/}
            {/*        <UniversalSelect*/}
            {/*            fetchList={({ page, limit, query }) => dispatch(loadRoutes(page, limit, { name: query, }))}*/}
            {/*            storeName={'transportPassenger'}*/}
            {/*            storeNameProps={'routes'}*/}
            {/*            keyProp={'id'}*/}
            {/*            withSearch={true}*/}
            {/*            isSelected={true}*/}
            {/*            onChange={handleSelect('route_id')}*/}
            {/*            selected={data?.route_id || {}}*/}
            {/*        />*/}
            {/*    </Modal>*/}
            {/*}*/}


        </form>
    );
};

export default Filter;
