import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import Modal from '../../../../../common/Modal';
import { loadExclusiveFilesLogList } from '../../../../../../redux/DorisControl/actions';
import { dorisControlSelectors } from '../../../../../../redux/DorisControl';
import List from './List';
import { LinearProgress } from '@mui/material';
import CustomPagination from '../../../../../common/CustomPagination';
import messages from '../../../../../../helpers/constants/messages';
import makeStyles from '@mui/styles/makeStyles';
import Filter from './Filter';

const useStyles = makeStyles({
    container: {
        margin: '1rem 1rem -1rem',
    },
    noData: {
        margin: '1rem',
    },
    list: {
        overflow: 'auto',
    }
});

const Logs = ({
    isOpen,
    onClose,
}) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const loading = useSelector(dorisControlSelectors.loadingExclusiveFilesLogList);
    const listData = useSelector(dorisControlSelectors.exclusiveFilesLogListData);
    const listMeta = useSelector(dorisControlSelectors.exclusiveFilesLogListMeta);

    useEffect(() => {
        dispatch(loadExclusiveFilesLogList(params.page, limit, params.data));
    } ,[dispatch, limit, params]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Журнал загрузок"
            noPadding
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        }
                    ]}
                />
            }
            size="80%"
        >
            {loading && <LinearProgress/>}
            {listData?.length
                ? (
                    <div className={classes.container}>

                        <Filter setParams={setParams}/>

                        <div className={classes.list}>
                            <List
                                data={listData}
                            />
                        </div>

                        <CustomPagination
                            loadList={(page) => setParams({ ...params, page })}
                            list={listMeta}
                            limit={limit}
                            setLimit={setLimit}
                        />
                    </div>
                )
                : (
                    <div className={classes.noData}>
                        {loading === false && <h2>{messages.NO_DATA}</h2>}
                    </div>
                )
            }
        </Modal>
    );
};

export default Logs;