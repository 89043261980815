import React, { useState } from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import removeEmptyFields from 'helpers/removeEmptyFields';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import { loadVehicleCategories } from 'redux/TransportSpecial/actions';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({ setParams }) => {

    const categories = useStoreProp(loadVehicleCategories, 'transportSpecial', 'vehicleCategories');

    const initialState = {
        number: '',
        category_id: '',
        in_work_order: false
    };

    const [data, setData] = useState(initialState);

    const onChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const onSearch = (filters = data) => {
        const prepareData = ( { ...removeEmptyFields(filters, false), category_id: filters.category_id });
        setParams({ page: 1, filter: prepareData });
    };

    const onReset = (needRefresh) => {
        setData(initialState);

        if (needRefresh) {
            setParams({ page: 1, filter: {} });
        }
    };

    const updateFilter = (filters) => {
        setData({ // обновляем локальный стейт
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            onSearch({ // отправляем в родителя, чтобы произошел запрос
                ...initialState,
                ...filters,
            });
        }
    };

    const isDisabledButtons = Object.values(removeEmptyFields(data)).length === 0;

    return (
        <LayoutFilter
            onResetFilter={onReset}
            onSearch={onSearch}
            filter={data}
            setUserFilter={updateFilter}  // если у пользователя сохранен фильтр для этой страницы, мы его из общего компонента сетаем сюда
            disabled={isDisabledButtons}
            filterException={['date_start', 'date_end',]}
        >
            <LayoutFilter.Visible>
                <TextField
                    variant="outlined"
                    value={data.number}
                    onChange={onChange}
                    label={titles.GRZ}
                    size="small"
                    name="number"
                />
                <FormControl variant="outlined" size="small">
                    <InputLabel>{titles.TYPE_OF_VEHICLE}</InputLabel>
                    <Select
                        label={titles.TYPE_OF_VEHICLE}
                        value={data.category_id}
                        onChange={onChange}
                        name={'category_id'}
                    >
                        {categories?.length
                            ? categories?.map((item) => 
                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                            )
                            : <MenuItem value="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
