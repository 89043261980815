import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { union, isEqual } from 'lodash';

import { usePrevious } from 'helpers/hooks';
import Loading from 'components/common/Loading';


function Graph({ data: dataObj }) {
    const prevData = usePrevious(dataObj);
    const refChart = useRef(null);
    const [isHidesFirst, setIsHidesFirst] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const months = [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь',
    ];
    const regions = Object.keys(dataObj);
    const colors = useMemo(() => {
        let generatedColors = [];
        while (regions.length !== generatedColors.length) {
            const color = '#' + ((1<<24)*Math.random() | 0).toString(16);
            if (!generatedColors.includes(color)) {
                generatedColors = [...generatedColors, color];
            }
        }
        return generatedColors;
    }, [regions.length]);

    const alldate = Object.keys(dataObj).reduce((res, key) =>
        union(res, Object.keys(dataObj[key])), []).sort();

    const data = {
        // labels: alldate.map((item) => getDateToFormat(item, 'MMMM yyyy')),
        labels: alldate.map((item) => {
            const dAr = item.split('-');
            const month = parseInt(dAr[1]) - 1;
            return `${months[month]} ${dAr?.[0]}`;
        }),
        datasets: regions.reduce((res, key, index) => ([
            ...res,
            {
                label: key,
                data: alldate.map((k) => {
                    const title = dataObj[key] || {};
                    const time = title[k] || {};
                    const count = time?.count || 0;
                    return count;
                }),
                borderColor: colors[index],
                backgroundColor: colors[index],
                maxBarThickness: 50,
            }
        ]), []),
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                align: 'left',
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                }
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Количество'
                },
                beginAtZero: true,
            }
        }
    };

    useEffect(() => {
        // сброз закрытия регионов
        if (isEqual(dataObj, prevData)) {
            setIsHidesFirst(false);
            setIsVisible(false);
        }
    } ,[dataObj, prevData]);

    useEffect(() => {
        const lengs = data?.datasets?.length;
        // скрываем элементы поштучно
        if (isHidesFirst === false && lengs) {
            if (lengs > 2) {
                for (let i = 2; i < lengs; i++) {
                    refChart.current?.hide?.(i);
                }
                // показываем график после скрытия
                setTimeout(() => setIsVisible(true), 1000);
            } else {
                setIsVisible(true);
            }
            setIsHidesFirst(true);
        }
    }, [refChart.current, data, isHidesFirst]);

    return (
        <div>
            <Bar
                ref={refChart}
                options={options}
                data={data}
                style={{ visibility: isVisible ? 'visible' : 'hidden' }}
            />
            {(isVisible === false && isHidesFirst === true)
                && <Loading className="absolute bottom fill" />
            }
        </div>
    );
}

export default memo(Graph);
