import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, sortBy, values } from 'lodash';
import { Card, Grid } from '@mui/material';

import { getYear, getStartOf, getEndOf, fullDateTimeWithTimeZone } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import {
    loadTransportIncidents,
    loadThreatLevels,
    loadInfopanel
} from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import Loading from 'components/common/Loading';

import PieItem from './Pie';
import Item from './Item';


const Events = () => {
    const dispatch = useDispatch();

    const dataList = useSelector(SituationalPlansSelectors.transportIncidents);
    const loading = useSelector(SituationalPlansSelectors.loadingTransportIncidents);
    const threatLevels = useSelector(SituationalPlansSelectors.threatLevels);
    const infopanel = useSelector(SituationalPlansSelectors.infopanel);
    const loadingInfopanel = useSelector(SituationalPlansSelectors.loadingInfopanel);

    const [visibleId,  setVisibleId] = useState({});
    const visibleRef = useRef({});

    useEffect(() => {
        dispatch(loadTransportIncidents(1, 100, {
            existence_order: ['organization_id', 'user_id', 'response_scenario_id'],
            status_order: [1, 2, 4, 5],
            is_statistics: 1
        }));

        const filter = {
            start_date: fullDateTimeWithTimeZone(getStartOf('year')),
            end_date: fullDateTimeWithTimeZone(getEndOf('day')),
        };
        dispatch(loadThreatLevels()); // Уровни угроз
        dispatch(loadInfopanel(filter)); // Данные для шапки
    }, [dispatch]);

    const dataListFilter = useMemo(() => dataList
        ?.data
        ?.filter(({ threat_level_id }) => {
            const threadLevel = threat_level_id || undefined;
            return threadLevel
                ? visibleId[threadLevel] ?? true
                : true;
        })
    , [dataList, visibleId]);

    const handleVisibleDebounce = debounce(() => {
        const values = {
            ...visibleRef.current
        };
        visibleRef.current = {};
        setVisibleId(old => ({
            ...old,
            ...values
        }));
    },500);


    const pie1Data = useMemo(() => {
        const valuesObj = values(infopanel?.data)
            .reduce((res, item) => typeof item === 'object'
                ? {
                    ...res,
                    [item.threat_level_id]: item.total || 0
                }
                : res
            , {});
        return sortBy(threatLevels?.data, ({ id }) => id)
            .map(({ id = 0, name, color }) => ({
                id,
                name,
                value: valuesObj[id],
                color,
            }));
    },[infopanel?.data, threatLevels?.data]);

    const pie2Data = useMemo(() => {
        const labels = [
            { name: 'Всего событий', key: 'total', color: '#cc0066' },
            { name: 'Решено', key: 'done', color: '#3333cc' },
            { name: 'В работе', key: 'in_work', color: '#0099cc' },
            { name: 'Просрочено', key: 'overdue', color: '#FF0000' },
            { name: 'Сегодня', key: 'today', color: '#33cc33' },
        ];
        return labels
            .map(({ key, name, color }) => ({
                name,
                value: infopanel?.data?.[key],
                color,
            }));
    },[infopanel?.data]);

    return (
        <div style={{
            height: '100%',
            overflowY: 'auto'
        }}>
            <h1>Инфопанель - данные за {getYear(getStartOf())} г</h1>

            <Card style={{ padding: '1.5rem 1rem 2rem' }}>
                <Grid container spacing={1}>
                    {loadingInfopanel
                        ? <Grid item xs={12}><Loading circular={true}/></Grid>
                        : (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                >
                                    <PieItem
                                        data={pie1Data}
                                        title="Уровень угрозы"
                                        onChangeVisible={(labelId, visible) => {
                                            // собираем клики и изменения
                                            visibleRef.current = {
                                                ...visibleRef.current,
                                                [labelId]: visible
                                            };
                                            handleVisibleDebounce();
                                        }}
                                        style={{ width: '350px', margin: '0 auto' }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                >
                                    <PieItem
                                        data={pie2Data}
                                        title="События"
                                        style={{ width: '350px', margin: '0 auto' }}
                                    />
                                </Grid>
                            </>
                        )
                    }
                </Grid>
            </Card>

            {loading && <Loading circular={true}/>}

            {dataListFilter?.length > 0
                ? (
                    <Grid container spacing={4} style={{ marginTop: 10 }}>
                        {dataListFilter?.map((item) => (
                            <Grid
                                key={item?.id}
                                item
                                xs={12}
                                md={6}
                                xxl={4}
                            >
                                <Item
                                    data={item}
                                    threatLevels={threatLevels?.data || []}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </div>
    );
};

export default Events;
