import { ExpandLess, ExpandMore, Clear } from '@mui/icons-material';
import { Divider, IconButton, Tooltip } from '@mui/material';
import Item from './Item';
import React, { useMemo } from 'react';
import SidebarItem from '../../../SidebarItem';
import icons from '../icons/icons';
import { renderToString } from 'react-dom/server';
import { transportCategoryHelper } from '../../../../../helpers/hooks/Transport/useTransportCategory';


const GroupItem = ({
    elem,
    handleSetActive,
    handleSetActiveGroup,
    handleClearActive,
    changeLock,
    activeById,
    providerRouteCheckPoint,
    transportCategory,
    onClearRouteByVehicle
}) => {
    const isShow = useMemo(() => elem
        ?.routes
        ?.reduce((r, i) => r || !!activeById(i.id), false)
    , [elem, activeById]);

    const handleInfo = () => {
        if (!isShow) {
            // разворачиваем - делаем первый активный
            handleSetActiveGroup(
                elem.grouped
                    ? elem?.routes // все маршруты
                    : elem?.routes.slice(0, 1), // 1 маршрут
                elem.grouped
            );
        } else {
            // сворачиваем - сбрасываем
            handleClearActive(elem?.routes);
        }
    };

    const {
        getImageTpl,
        color,
    } = transportCategoryHelper(transportCategory)
        ?.getCategoryPassenger(
            elem.category_id,
            true,
            true
        );

    const categoryImage = (
        <div className="transport-icon" style={{ background: color }}>
            {getImageTpl('image-inner', icons({ fill: '#fff' }))}
        </div>
    );

    return (
        <>
            <SidebarItem
                active={isShow}
                icon={renderToString(categoryImage)}
                // icon={`<div class="transport-icon icon${elem.category_id}">${icons({}, parseInt(elem.category_id))}</div>`}
                // buttons={{
                //     ...(item.status !== 3 ? {
                //         edit: {
                //             title: buttons.EDIT,
                //             icon: <i className="fas fa-pen"/>,
                //             onClick: () => {
                //                 dispatch(setEditForm(item));
                //             }
                //         },
                //     } : {}),
                //     delete: {
                //         title: buttons.DELETE,
                //         icon: <i className="fas fa-trash-alt" />,
                //         onClick: () => {
                //             dispatch(setDeleteForm(item.id));
                //         }
                //     }
                // }}
                onClick={handleInfo}
            >
                <div className="row noMargin">
                    <div className="row__item">
                        <Item
                            data={elem}
                        />
                    </div>
                    {onClearRouteByVehicle && (
                        <Tooltip title="Сбросить выбранный маршрут">
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onClearRouteByVehicle();
                                }}
                            >
                                <Clear fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    )}
                    <IconButton size="small" onClick={() => handleInfo()}>
                        {isShow
                            ? <ExpandLess fontSize="inherit" />
                            : <ExpandMore fontSize="inherit" />
                        }
                    </IconButton>
                </div>
            </SidebarItem>
            <Divider />
            {(isShow)
               && <>
                   {elem?.routes?.map((item) => {
                       const activeElement = activeById(item.id);
                       const isLock = activeElement?.lock;
                       return (
                           <SidebarItem
                               key={`gr_${item.id}`}
                               active={!!activeElement}
                               loading={providerRouteCheckPoint.loading && providerRouteCheckPoint.id === item.id}
                               // icon={`<div class="transport-icon icon${item.category_id}">${icons({}, parseInt(item.category_id))}</div>`}
                               // buttons={{
                               //     ...(item.status !== 3 ? {
                               //         edit: {
                               //             title: buttons.EDIT,
                               //             icon: <i className="fas fa-pen"/>,
                               //             onClick: () => {
                               //                 dispatch(setEditForm(item));
                               //             }
                               //         },
                               //     } : {}),
                               //     delete: {
                               //         title: buttons.DELETE,
                               //         icon: <i className="fas fa-trash-alt" />,
                               //         onClick: () => {
                               //             dispatch(setDeleteForm(item.id));
                               //         }
                               //     }
                               // }}
                               onClick={() => {
                                   handleSetActive(item);
                               }}
                           >
                               <Item
                                   data={item}
                                   isLock={isLock}
                                   onChangeLock={(isLock) => {
                                       changeLock(item, isLock);
                                   }}
                                   onlyName
                               />
                           </SidebarItem>
                       );
                   })}
               </>
            }
        </>
    );
};

export default GroupItem;
