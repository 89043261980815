import React from 'react';
import { useSelector } from 'react-redux';
import { Bar as GBar } from 'react-chartjs-2';
import { getHumanDate } from 'helpers/date.config';
import { trafficFlowSelectors } from 'redux/TrafficFlow';

const AnalysisData = ({ 
    isOpen,
    onClose,
    detectors,
}) => {
    const throughputData = useSelector(trafficFlowSelectors.throughputData);
   
    const data = Object.entries(throughputData).reduce((accum, [detector_id, el]) => {
        const detector = detectors.find((item) => item.id === Number(detector_id)) || {};
        
        const dataByDetector = el.reduce((res, dt) => {
            accum.labels = Array.from(new Set([
                ...accum.labels,
                dt.date
            ])).sort();
            res[detector_id] = {
                backgroundColor: dt.color,
                label: detector.name || 'Не указано',
                borderColor: dt.color,
                maxBarThickness: 80,
                units: 'шт.',
                fullData: [
                    ...(res[detector_id]?.fullData || []),
                    dt,
                ]
            };
                
            return res;
        }, {});

        accum.data = {
            ...accum.data,
            ...dataByDetector
        };
            
        return accum;
    }, { data: {}, labels: [] } );
  
    const dataForBar = (obj) => {
        if (obj.labels?.length > 0) {
            return ({
                labels: obj.labels.map(el => getHumanDate(el)),
                datasets: Object.values(obj.data).map((element) => {
                    return { 
                        type: 'bar',
                        ...element,
                        data: obj.labels.map((date) => {
                            return element.fullData.find((el) => el.date === date)?.count;
                        }),
                    };
                }),
            });
        }
        return null;
    };

    const getLabel = (context) => {
        const value = context.formattedValue || '';
        const label = context.label || '';
        return `${label} - ${value} шт.`;
    };

    const toolTipTitle = (context) => {
        const datasetLabel = context?.[0]?.dataset?.label;
        return datasetLabel;
    };

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks: {
                    label: getLabel,
                    title: toolTipTitle,
                }
            },
            legend: {
                position: 'bottom',
            },
        },
        scales: {
            x: {
                // stacked: true,
            },
            y: {
                // stacked: true,
                title: {
                    display: true,
                    text: 'шт.'
                }
            },
        },
    };

    const dataSet = dataForBar(data);
    return (
        <>
            {dataSet !== null
                ? <GBar
                    options={options}
                    data={dataSet}
                    style={{ maxHeight: '400px' }}
                />
                : <div>Нет данных для выбранных параметров</div>
            }
        </>
    );
};

export default AnalysisData;