import { Typography } from '@mui/material';
import messages from 'helpers/constants/messages/common';
import Loading from 'components/common/Loading';
import RoutesTable from './RoutesTable';
import titles from 'helpers/constants/titles';

const RegistationCardInfo = ({ categoryName, item, company, dates, loading }) => {
    return (
        <>
            <div>
                <Typography>
                    <strong>{titles.TYPE_OF_VEHICLE}: </strong>{categoryName || messages.NO_DATA}
                </Typography>
                <Typography>
                    <strong>Марка: </strong>{item?.brand_name || messages.NO_DATA}
                </Typography>
                <Typography>
                    <strong>Модель: </strong>{item?.model_name || messages.NO_DATA}
                </Typography>
                <Typography>
                    <strong>ГРЗ: </strong>{item?.number || messages.NO_DATA}
                </Typography>
                <Typography>
                    <strong>Гаражный номер: </strong>{item?.garage_number || messages.NO_DATA}
                </Typography>
                <Typography>
                    <strong>Регион: </strong>{company?.real_address?.region || company?.juristic_address?.region || messages.NO_DATA}
                </Typography>
                <Typography>
                    <strong>Организация: </strong>{company?.title || messages.NO_DATA}
                </Typography>
                <Typography>
                    <strong>ИНН: </strong>{company?.inn || messages.NO_DATA}
                </Typography>
            </div>
            {loading && <Loading circular={true}/>}
            {dates && (
                <RoutesTable data={dates}/>
            )}
        </>
    );
};

export default RegistationCardInfo;
