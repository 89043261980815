import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItem, ListItemText } from '@mui/material';

import { transportRoadSelectors } from 'redux/TransportRoad';
import {
    clearReportByVehicles,
    loadReportByVehicles,
    loadVehicleCategoryList
} from 'redux/TransportRoad/actions';
import messages from 'helpers/constants/messages';
import Loading from 'components/common/Loading';
import { LSContainer, LSHeaderColumn, LSHeaderItem } from 'components/common/ListStyle';
import PageLayout from 'components/layout/PageLayout';

import Filter from './Filter';
import Item from './Item';

import type { ItemTypes } from './types';


const VehicleMileage = () => {
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);
    const reportVehicleMileage = useSelector(transportRoadSelectors.reportVehicleMileage);
    const loadingReportByVehicles = useSelector(transportRoadSelectors.loadingReportByVehicles);
    const vehicleCategoryList = useSelector(transportRoadSelectors.vehicleCategoryList);

    useEffect(() => {
        dispatch(loadVehicleCategoryList());
    }, [dispatch]);

    const memoizedCategoryList = useMemo(() => {
        return vehicleCategoryList;
    }, [vehicleCategoryList]);

    const renderContent = () => (
        <>
            {loadingReportByVehicles && <Loading circular/>}
            {reportVehicleMileage?.data?.vehicle_mileage?.length > 0
                ? <LSContainer>
                    <LSHeaderItem>
                        <LSHeaderColumn width="200px">Дата</LSHeaderColumn>
                        <LSHeaderColumn width="200px">ГРЗ</LSHeaderColumn>
                        <LSHeaderColumn width="200px">Вид ТС</LSHeaderColumn>
                        <LSHeaderColumn width="200px">Модель</LSHeaderColumn>
                        <LSHeaderColumn width="200px">Марка</LSHeaderColumn>
                        <LSHeaderColumn width="200px"/*align="right" isActions*/>Пробег, км</LSHeaderColumn>
                    </LSHeaderItem>
                    {reportVehicleMileage?.data?.vehicle_mileage.map((item: ItemTypes, index: React.Key | null | undefined) => (
                        <Item key={index} item={item} category={memoizedCategoryList} />
                    ))}
                    <ListItem>
                        <ListItemText>
                            <strong>Общий пробег, км: {reportVehicleMileage?.data?.total}</strong>
                        </ListItemText>
                    </ListItem>
                </LSContainer>
                : !loadingReportByVehicles && isLoaded && <div>{messages.DATA_IS_NOT_FOUND}</div>
            }
        </>
    );
   
    return (
        <>
            <PageLayout
                header="Отчёт по пробегу"
                filters={
                    <Filter
                        setParams={(params : any) => {
                            dispatch(loadReportByVehicles(params));
                            setIsLoaded(true);
                        }}
                        setReset={() => {
                            dispatch(clearReportByVehicles());
                            setIsLoaded(false);
                        }}
                    />}
                content={renderContent}
            />
        </>
    );
};

export default VehicleMileage;