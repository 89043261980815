import React from 'react';
import { config } from '../../../../../../config';
import {
    Map,
    MapControl,
    FeatureGroup,
} from '../../../../../MapComponents/leaflet';

const RegionsMap = ({
    regionComponent = null,
}) => {

    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                marginBottom: '.5rem',
            }}
        >
            <div
                style={{
                    display: 'contents',
                    minHeight: '400px',
                }}
            >
                <Map
                    center={config.get('mapCenter')}
                >
                    <MapControl>

                        <FeatureGroup>
                            {/* регионы */}
                            {regionComponent}
                        </FeatureGroup>

                    </MapControl>
                </Map>
            </div>
        </div>
    );
};

export default RegionsMap;