import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { loadDangersList } from 'redux/SituationalPlans/actions';
import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

interface Threat {
    id: number
    name: string
}

const Threats = ({
    multiple,
    selected,
    onChange,
    required,
    disabled,
    label = titles.THREAT_CATEGORY,
    error,
    helperText,
}: CommonAutocompleteProps<Threat>) => {
    const dispatch = useDispatch();

    const prevData = useRef(selected);

    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleClose = () => setShowList(false);

    const getList = (params: { page: number; limit: number; query?: string }) => {
        const { page, limit, query } = params;

        dispatch(loadDangersList(page, limit, { name: query }));
    };

    const handleAccept = (data: Threat | Threat[]) => {
        onChange(data);
        setShowList(false);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                disabled={disabled}
                renderLabel={(option: Threat | null) => option?.name || ''}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
            />

            {showList && (
                <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName="SituationalPlans"
                    storeNameProps="dangersList"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div>{el.name}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={label}
                    small
                />
            )}
        </>
    );
};

export default Threats;
